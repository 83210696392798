import * as React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button } from 'antd';
import { connect } from 'react-redux';
import { requestPasswordReset } from '../../thunks/session_thunks';
import Result from 'ant-design-pro/lib/Result';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter, Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import BackButton from '../back_button/BackButton';

const style = require('./SupplierForgotPasswordForm.less');
const FormItem = Form.Item;

interface ForgotPasswordProps {
	isAuthenticated: boolean;
	requestPasswordReset: any;
	loading: boolean;
	username: string;
}

interface ForgotPasswordFormProps extends FormComponentProps {
	isAuthenticated: boolean;
	requestPasswordReset: any;
	loading: boolean;
	username: string;
}

class SupplierForgotPasswordForm extends React.Component<ForgotPasswordFormProps, any> {
	state = {
		resetRequested: false,
		recipientEmailAddress: '',
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { requestPasswordReset, form } = this.props;
		form.validateFields((err, values) => {
			if (!err) {
				requestPasswordReset(values.username).then(() => {
					this.setState({
						recipientEmailAddress: values.username,
						resetRequested: true,
					});
				});
			}
		});
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { isAuthenticated, loading } = this.props;
		const { resetRequested, recipientEmailAddress } = this.state;
		return isAuthenticated ? (
			<Redirect
				to={{
					pathname: '/dashboard',
					state: { from: '/forgotPassword' },
				}}
			/>
		) : resetRequested ? (
			<Result
				type="success"
				title={<div>Password reset email sent!</div>}
				description={
					<div>
						<p className="signupForm__successExplanation">
							We've sent a password reset link to{' '}
							<span style={{ fontWeight: 'bold' }}>{recipientEmailAddress}</span>.
						</p>
						<p className="signupForm__successExplanation">
							Check your email, follow the instructions to reset your password and you'll be back
							into your account in no time.
						</p>
					</div>
				}
				actions={
					<div style={{ marginBottom: 24 }}>
						<Link to="/supplier/login">
							<Button type="primary">Back to log in</Button>
						</Link>
					</div>
				}
			/>
		) : (
			<Form onSubmit={this.handleSubmit} className="forgotPasswordForm">
				<FormItem>
					{getFieldDecorator('username', {
						rules: [{ required: true, message: 'Please input your email!' }],
					})(
						<Input
							size="large"
							prefix={<UserOutlined style={{ fontSize: 13 }} />}
							placeholder="Email"
						/>
					)}
				</FormItem>
				<FormItem>
					<Button type="primary" htmlType="submit" loading={loading} size="large">
						Get reset link
					</Button>
					<span style={{ marginLeft: 8 }}>
						<BackButton size="large" buttonText="Go back" />
					</span>
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.session.isAuthenticated,
	loading: state.session.loading,
});

const mapDispatchToProps = (dispatch) => ({
	requestPasswordReset: (username) => dispatch(requestPasswordReset('supplier')(username)),
});

export default withRouter<any, any>(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<ForgotPasswordFormProps>()(SupplierForgotPasswordForm))
);

import { STOPLIGHT_COLORS } from '../../utils/DataFormatterUtils';

const POPipeLineFilters = [
	{
		displayName: 'New',
		name: 'new',
		filter: { status: 'new' },
		icon: 'icons8-edit',
		iconStyle: { color: '#4a1e87' },
	},
	{
		displayName: 'Ordered',
		name: 'ordered',
		filter: { status: 'ordered' },
		icon: 'icons8-wrench',
		iconStyle: { color: STOPLIGHT_COLORS.pureYellow },
	},
	{
		displayName: 'Partially Received',
		name: 'partially_received',
		filter: { status: 'partially_received' },
		icon: 'icons8-pause-squared',
		iconStyle: { color: STOPLIGHT_COLORS.pureYellow },
	},
	{
		displayName: 'Received',
		name: 'received',
		filter: { status: 'received' },
		icon: 'icons8-green-check-mark',
		iconStyle: { color: STOPLIGHT_COLORS.pureGreen },
	},
	{
		displayName: 'Cancelled',
		name: 'cancelled',
		filter: { status: 'cancelled' },
		icon: 'icons8-cancel',
		iconStyle: { color: STOPLIGHT_COLORS.pureRed },
	},
	{
		displayName: 'Closed',
		name: 'closed',
		filter: { status: 'closed' },
		icon: 'icons8-closed-window',
		iconStyle: { color: STOPLIGHT_COLORS.pureRed },
	},
];

export default POPipeLineFilters;

import { Button, Form, Modal } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { purchaseOrderReceiptsRestCrudThunksForSupplier } from '../../thunks/purchase_order_receipt_thunks';
import { getEntityById, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { useForm } from 'antd/lib/form/Form';
import ReceiptFormFields from '../purchase_orders_detail_details_page/ReceiptFormFields';

const EditReceiptModal = ({
	onCancel,
	onSuccess,
	receipt,
	updateReceipt,
	purchaseOrder,
}): React.ReactElement => {
	const [form] = useForm();

	const [saving, setSaving] = useState(false);

	const onSubmit = useCallback(
		(values) => {
			setSaving(true);
			updateReceipt({
				...receipt,
				...values,
				pricePerQuantity: values.price,
				...(values.invoiceTotal && { invoiceTotal: `${values.invoiceTotal}` }),
				...(values.exchangeRate && { exchangeRate: `${values.exchangeRate}` }),
			})
				.then(() => onSuccess && onSuccess(values.quantity))
				.finally(() => setSaving(false));
		},
		[updateReceipt, receipt, onSuccess]
	);

	const lineItem = useMemo(() => {
		const allLineItems = [
			...nullSafeGetOrElse('partSupplierPurchaseOrderLineItems', purchaseOrder, []),
			...nullSafeGetOrElse('equipmentSupplierPurchaseOrderLineItems', purchaseOrder, []),
		];

		return allLineItems.find((_) => _.id === nullSafeGet('purchaseOrderLineItemId', receipt));
	}, [purchaseOrder, receipt]);

	const lineItemCurrencyId = useMemo(() => {
		return lineItem
			? lineItem.isEquipmentLine
				? nullSafeGet('equipmentCurrencyId', lineItem)
				: nullSafeGet('partCurrencyId', lineItem)
			: undefined;
	}, [lineItem]);

	const initialValues = useMemo(
		() => ({
			...receipt,
			quantity: nullSafeGet('receivedQuantity', receipt),
			price: nullSafeGet('pricePerQuantity', receipt),
			invoiceCurrency: nullSafeGetOrElse('invoiceCurrency', receipt, lineItemCurrencyId),
		}),
		[lineItemCurrencyId, receipt]
	);

	return (
		<Modal
			visible={true}
			width={600}
			title={`Update Receipt`}
			closable
			onCancel={onCancel}
			forceRender
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button
					type="primary"
					size="large"
					style={{ marginLeft: '16px' }}
					key="submit"
					htmlType="submit"
					form="receive-po-line-item-form"
					loading={saving}
				>
					Update
				</Button>,
			]}
		>
			<Form
				form={form}
				id="receive-po-line-item-form"
				layout="vertical"
				requiredMark={false}
				initialValues={initialValues}
				onFinish={onSubmit}
			>
				<ReceiptFormFields
					disableQuantity
					form={form}
					defaultCurrencyId={nullSafeGet('invoiceCurrency', initialValues)}
				/>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state, ownProps) => ({
	purchaseOrder: getEntityById(ownProps.match.params.id, state.purchase_orders),
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	updateReceipt: (entity) =>
		dispatch(purchaseOrderReceiptsRestCrudThunksForSupplier.update(entity)),
});

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(EditReceiptModal));

import * as React from 'react';

import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Select, Input, Button, Upload, message, Typography, Modal, Row, Col } from 'antd';
import { connect } from 'react-redux';
import {
	associateSupplierContactWithCompany,
	createSupplierCompany,
	getSuggestionsForSupplierCompanies,
	suppliersRestCrudThunksForSupplier,
} from '../../thunks/suppliers_thunks';
import { CURRENCIES, ROLE_TYPES } from '../../utils/DataConstants';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { getObjectValues, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import { spendCategoriesRestCrudThunksForSupplier } from '../../thunks/spend_categories_thunks';
import { supplierFacilitiesRestCrudThunksForSupplier } from '../../thunks/supplier_facilities_thunks';
import { LocationAutocomplete } from '../location_autocomplete/LocationAutocomplete';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { checkUserTypeToken, retrieveCachedUserDetails } from '../../thunks/session_thunks';
import { WORK_ORDERS_CRUD_ACTION_CREATORS } from '../../actions/work_orders_actions';
import { imageUploadValidation } from '../../utils/ImageUtils';
import PhoneNumberComponent from '../PhoneNumberComponent/PhoneNumberComponentAntdV3';
import { capitalize } from '../../utils/DataFormatterUtils';

const style = require('./SupplierCompanyForm.less');
const FormItem = Form.Item;

interface SupplierCompanyFormProps extends FormComponentProps {
	history: any;
	creating: boolean;
	updating: boolean;
	minimal: boolean;
	onSubmit: any;
	spendCategories: any;
	formData: any;
	fetchSpendCategories: any;
	fetchMultipleSpendCategories: any;
	refreshCurrentUser: any;
	newAccountSetupMode: boolean;
	currentUser: any;
	associateSupplierContactWithFacility: any;
	getSupplierFacilities: any;
	create: any;
	update: any;
	getSuggestions: any;
	createSupplier: any;
}

class SupplierCompanyForm extends React.Component<SupplierCompanyFormProps, any> {
	state = {
		uploadLoading: false,
		logoURL: null,
		error: null,
		suggestions: [],
	};

	componentDidMount() {
		const { formData } = this.props;
		this.setState({ logoURL: formData.logoURL });
	}

	handleSubmit = (e) => {
		const {
			create,
			update,
			minimal,
			spendCategories,
			newAccountSetupMode,
			refreshCurrentUser,
			onSubmit,
			getSuggestions,
			createSupplier,
		} = this.props;
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const isUpdate = values.id !== undefined;
				const submitAction = isUpdate ? update : create;
				const primaryAddress = Object.keys(values)
					.filter((k) => k.indexOf('primaryAddress') > -1)
					.reduce((acc, k) => {
						const [_, x] = k.split('-');
						acc[x] = values[k];
						return acc;
					}, {});

				const spendCategoriesRecordsMap = spendCategories.records;
				const categoryIds = nullSafeGetOrElse('spendCategories', values, []);
				const categoryNames = nullSafeGetOrElse('spendCategories', values, []).map(
					(id) => spendCategoriesRecordsMap[id].name
				);
				let supplier;
				if (minimal) {
					supplier = {
						...values,
						displayName: values.displayName || values.name,
						primaryContactEmail: values.primaryContactEmail,
						poEmail: values.previouslyClaimed
							? values.poEmail || values.primaryContactEmail
							: values.primaryContactEmail,
						primaryAddress,
						categoryIds,
						categoryNames,
					};
				} else {
					supplier = {
						...values,
						primaryAddress,
						categoryIds,
						categoryNames,
					};
				}
				if (newAccountSetupMode) {
					getSuggestions(supplier)
						.then(({ data: companies }) => {
							if (companies && companies.length) {
								this.setState({ suggestions: companies });
							} else {
								createSupplier(supplier).then(() => {
									refreshCurrentUser().then(() => {
										onSubmit([]);
									});
								});
							}
						})
						.catch((err) => {
							this.setState({ error: err });
						});
				} else {
					submitAction(supplier)
						.then(() => {})
						.catch((err) => {
							this.setState({ error: err });
						});
				}
			}
		});
	};

	handleUploadChange = (info) => {
		if (info.file.status === 'uploading') {
			this.setState({ uploadLoading: true });
			return;
		}
		if (info.file.status === 'done') {
			const companyLogoURL = nullSafeGet('file.response.data', info);
			this.props.form.setFieldsValue({ logoURL: companyLogoURL });
			this.setState({
				logoURL: companyLogoURL,
				uploadLoading: false,
			});
		}
	};

	handleLocationAutocompleteSelect = (addr, lonLat, parsedAddress) => {
		const { form } = this.props;
		form.setFieldsValue({
			'primaryAddress-streetAddress1': parsedAddress.streetAddress1,
			'primaryAddress-city': parsedAddress.city,
			'primaryAddress-region': parsedAddress.region,
			'primaryAddress-postcode': parsedAddress.postcode,
			'primaryAddress-country': parsedAddress.country,
		});
		form.setFieldsValue({
			longitude: lonLat.lng,
			latitude: lonLat.lat,
		});
	};

	render() {
		const BACKEND_URI = getBackendUri();

		const {
			creating,
			fetchSpendCategories,
			newAccountSetupMode,
			fetchMultipleSpendCategories,
			currentUser,
			updating,
			form,
			formData,
			minimal,
			spendCategories,
			associateSupplierContactWithFacility,
			refreshCurrentUser,
			createSupplier,
			onSubmit,
		} = this.props;
		const { logoURL, error, suggestions } = this.state;

		const { getFieldDecorator } = form;
		const uploadHeaders = { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token };
		const spendCategoriesRecordsList = getObjectValues(spendCategories.records);
		const spendCategoriesRecordsMap = spendCategories.records;
		const currencyRecordsList = getObjectValues(CURRENCIES);
		const currencyRecordsMap = getObjectValues(CURRENCIES);

		getFieldDecorator(`id`, { initialValue: nullSafeGet('supplierCompanyId', formData) });
		getFieldDecorator(`operatingHours`, { initialValue: nullSafeGet('operatingHours', formData) });
		getFieldDecorator(`isWomenOwned`, {
			initialValue: nullSafeGetOrElse('isWomenOwned', formData, false),
		});
		getFieldDecorator(`isMinorityOwned`, {
			initialValue: nullSafeGetOrElse('isMinorityOwned', formData, false),
		});
		getFieldDecorator(`isVeteranOwned`, {
			initialValue: nullSafeGetOrElse('isVeteranOwned', formData, false),
		});
		getFieldDecorator(`isLgbtOwned`, {
			initialValue: nullSafeGetOrElse('isLgbtOwned', formData, false),
		});
		getFieldDecorator(`isSmallBusinessEnterprise`, {
			initialValue: nullSafeGetOrElse('isSmallBusinessEnterprise', formData, false),
		});
		getFieldDecorator(`verifications`, {
			initialValue: nullSafeGetOrElse('verification', formData, []),
		});
		getFieldDecorator(`faqs`, { initialValue: nullSafeGetOrElse('faqs', formData, []) });
		getFieldDecorator(`licenses`, {
			initialValue: nullSafeGetOrElse('licenses', formData, []),
		});
		getFieldDecorator(`certifications`, {
			initialValue: nullSafeGetOrElse('certifications', formData, []),
		});
		getFieldDecorator(`insurances`, {
			initialValue: nullSafeGetOrElse('insurances', formData, []),
		});
		getFieldDecorator(`socialMedia`, {
			initialValue: nullSafeGetOrElse('socialMedia', formData, {}),
		});
		getFieldDecorator(`additionalDetails`, {
			initialValue: nullSafeGetOrElse('additionalDetails', formData, {}),
		});
		getFieldDecorator(`longitude`, { initialValue: nullSafeGet('longitude', formData) });
		getFieldDecorator(`latitude`, { initialValue: nullSafeGet('latitude', formData) });
		getFieldDecorator(`primaryAddress-streetAddress1`, {
			initialValue: nullSafeGet('primaryAddress.streetAddress1', formData),
		});
		getFieldDecorator(`primaryAddress-streetAddress2`, {
			initialValue: nullSafeGet('primaryAddress.streetAddress2', formData),
		});
		getFieldDecorator(`primaryAddress-city`, {
			initialValue: nullSafeGet('primaryAddress.city', formData),
		});
		getFieldDecorator(`primaryAddress-region`, {
			initialValue: nullSafeGet('primaryAddress.region', formData),
		});
		getFieldDecorator(`primaryAddress-postcode`, {
			initialValue: nullSafeGet('primaryAddress.postcode', formData),
		});
		getFieldDecorator(`primaryAddress-country`, {
			initialValue: nullSafeGet('primaryAddress.country', formData),
		});
		getFieldDecorator('status', { initialValue: nullSafeGetOrElse('status', formData, 'active') });
		getFieldDecorator('logoURL', { initialValue: logoURL });
		getFieldDecorator('primaryContactEmail', {
			initialValue: nullSafeGet('primaryContactEmail', formData),
		});
		getFieldDecorator('poEmail', { initialValue: nullSafeGet('poEmail', formData) });
		getFieldDecorator('previouslyClaimed', {
			initialValue: nullSafeGetOrElse('isClaimed', formData, false),
		});
		getFieldDecorator('primaryPhone', { initialValue: nullSafeGet('primaryPhone', formData) });
		getFieldDecorator('corporateURL', { initialValue: nullSafeGet('corporateURL', formData) });
		getFieldDecorator('storefrontURL', { initialValue: nullSafeGet('storefrontURL', formData) });

		const isOpenWrenchPrimaryContact = nullSafeGetOrElse(
			'primaryContactEmail',
			formData,
			''
		).includes('@useopenwrench.com');

		const createRawAddress = (addr) => {
			if (!addr || addr === undefined) {
				return '';
			}
			return `${addr.streetAddress1}  ${addr.city}, ${addr.region}, ${addr.postcode}, ${addr.country}`;
		};
		const rawAddress = createRawAddress(formData.primaryAddress);
		const uploadButton = (
			<div>
				<LegacyIcon type={this.state.uploadLoading ? 'loading' : 'plus'} />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		const isLoading = creating || updating;
		const lon = form.getFieldValue('longitude');
		const lat = form.getFieldValue('latitude');

		return (
			<Form
				hideRequiredMark={minimal}
				layout="vertical"
				onSubmit={this.handleSubmit}
				className="supplierForm"
			>
				{(suggestions.length && (
					<Modal
						title={
							<div>
								<span>
									{suggestions.length > 1
										? 'Did you mean any of the following companies?'
										: 'Did you mean this company?'}
								</span>
							</div>
						}
						footer={
							<div>
								<div>
									<Button
										onClick={() => {
											const values = form.getFieldsValue();
											const primaryAddress = Object.keys(values)
												.filter((k) => k.indexOf('primaryAddress') > -1)
												.reduce((acc, k) => {
													const [_, x] = k.split('-');
													acc[x] = values[k];
													return acc;
												}, {});

											const spendCategoriesRecordsMap = spendCategories.records;
											const categoryIds = nullSafeGetOrElse('spendCategories', values, []);
											const categoryNames = nullSafeGetOrElse('spendCategories', values, []).map(
												(id) => spendCategoriesRecordsMap[id].name
											);

											const supplierData = {
												...values,
												displayName: values.displayName || values.name,
												primaryContactEmail: values.primaryContactEmail,
												poEmail: values.previouslyClaimed
													? values.poEmail || values.primaryContactEmail
													: values.primaryContactEmail,
												primaryAddress,
												categoryIds,
												categoryNames,
											};
											createSupplier(supplierData).then((newSupplier) => {
												refreshCurrentUser().then(() => {
													onSubmit([]);
												});
											});
										}}
										type="primary"
									>
										No, Create a new one
									</Button>
								</div>
							</div>
						}
						bodyStyle={{ overflowY: 'scroll', padding: 0 }}
						closable={false}
						visible
					>
						<div className="flex flex-col  ">
							{suggestions.map((supplier, i) => (
								<div className=" flex cursor-pointer items-center justify-between p-7 text-base hover:bg-gray-100">
									<div className="flex flex-col">
										<span className="mb-2 font-semibold">{supplier.displayName}</span>
										<span className="text-sm">
											{[
												supplier.primaryAddress.streetAddress1,
												supplier.primaryAddress.city,
												supplier.primaryAddress.region,
											]
												.filter((item) => item)
												.join(',')}
										</span>
									</div>
									<Button
										className="bg-indigo-100"
										type="default"
										onClick={() => {
											associateSupplierContactWithFacility(supplier.id, supplier).then(() => {
												refreshCurrentUser().then(() => {
													onSubmit([]);
												});
											});
										}}
									>
										Yes, this one
									</Button>
								</div>
							))}
						</div>
					</Modal>
				)) ||
					null}
				<FormItem label="Company name">
					{getFieldDecorator('name', {
						rules: [{ required: true, message: 'Your company needs a name!' }],
						initialValue: nullSafeGet('name', formData),
					})(
						<Input
							placeholder={minimal ? 'Acme Plumbing, Inc.' : ''}
							size={minimal ? 'large' : undefined}
							style={{ maxWidth: 500 }}
						/>
					)}
				</FormItem>
				{minimal ? null : (
					<FormItem label="Describe what your company does.">
						{getFieldDecorator('about', {
							initialValue: nullSafeGet('about', formData),
						})(<Input.TextArea rows={4} />)}
					</FormItem>
				)}
				{minimal ? null : (
					<FormItem label="What name do your customers call your company?">
						{getFieldDecorator('displayName', {
							rules: [
								{
									required: true,
									message:
										"Please input your company's name as used in advertising and sales materials!",
								},
							],
						})(<Input style={{ maxWidth: 500 }} />)}
					</FormItem>
				)}
				{minimal ? null : (
					<FormItem label="List the services you provide.">
						{getFieldDecorator('spendCategories', {
							initialValue: nullSafeGet('categoryIds', formData),
							rules: [
								{
									required: true,
									message:
										'Please select one or two categories that describe the services your provide!',
								},
							],
						})(
							<OWAsyncSelect
								style={{ width: 320 }}
								mode="multiple"
								stateSlice={spendCategories}
								targetCollectionName="COMPANY_FORM_SPEND_CATEGORIES_AUTOCOMPLETE"
								fetchMultiple={(ids, targetCollectionName) => {
									fetchMultipleSpendCategories(ids, targetCollectionName);
								}}
								fetchData={(searchText, targetCollectionName) => {
									fetchSpendCategories({ name: searchText }, targetCollectionName);
								}}
								renderRecord={(category) => (
									<Select.Option key={category.id} value={category.id}>
										{category.name}
									</Select.Option>
								)}
							/>
						)}
					</FormItem>
				)}
				<Form.Item required={true} label="Address">
					<LocationAutocomplete
						lon={lon}
						lat={lat}
						size={minimal ? 'large' : undefined}
						initialValue={rawAddress}
						placeholder="9035 Village Dr, Yosemite Valley, CA 95389"
						onSelect={this.handleLocationAutocompleteSelect}
					/>
				</Form.Item>
				{minimal ? null : (
					<FormItem label="Upload your company's logo. (Square 300x300 pixel images look best.)">
						<Upload
							name="file"
							listType="picture-card"
							className="avatar-uploader"
							showUploadList={false}
							headers={uploadHeaders}
							action={`${BACKEND_URI}/api/v1/supplier/file/public/company_logo/upload`}
							beforeUpload={imageUploadValidation}
							onChange={this.handleUploadChange}
						>
							{logoURL ? <img src={logoURL} style={{ maxHeight: 104 }} alt="" /> : uploadButton}
						</Upload>
					</FormItem>
				)}
				<PhoneNumberComponent
					form={form}
					label={'Company Phone Number'}
					fieldProps={{
						name: 'primaryPhone',
					}}
					size={minimal ? 'large' : undefined}
					style={{ maxWidth: 350 }}
					placeholder="(209) 372-0200"
				/>
				<FormItem label="Primary email">
					{getFieldDecorator('primaryContactEmail', {
						rules: [{ required: true, message: "Please input your primary contact's email!" }],
						initialValue: isOpenWrenchPrimaryContact
							? currentUser.email
							: nullSafeGetOrElse('primaryContactEmail', formData, currentUser.email),
					})(
						<Input
							placeholder="elon.musk@acmeplumbing.com"
							style={{ maxWidth: 350 }}
							size={minimal ? 'large' : undefined}
						/>
					)}
				</FormItem>
				<Form.Item label="Currency">
					{form.getFieldDecorator('currencyId', {
						rules: [{ required: true, message: 'Please select a currency for this event.' }],
						initialValue: formData.currencyId || 'USD',
					})(
						<Select
							showSearch={true}
							style={{ width: 300 }}
							optionFilterProp="children"
							filterOption={(input, option) =>
								currencyRecordsMap[option.props.value].displayNamePlural
									.toLowerCase()
									.indexOf(input.toLowerCase()) >= 0
							}
							placeholder="Currency"
						>
							{currencyRecordsList.map((currency) => (
								<Select.Option key={currency.id} value={currency.id}>
									{capitalize(currency.displayNamePlural)} ({currency.id})
								</Select.Option>
							))}
						</Select>
					)}
				</Form.Item>
				{minimal ? null : (
					<FormItem label="What is the email where you prefer to receive purchase orders?">
						{getFieldDecorator('poEmail', {
							rules: [
								{
									required: true,
									message: 'Please input best contact email for purchase orders!',
								},
							],
						})(<Input style={{ maxWidth: 350 }} />)}
					</FormItem>
				)}
				{minimal ? null : (
					<FormItem label="Add a link to your company's website">
						{getFieldDecorator('corporateURL', {
							rules: [{ required: true, message: "Please input your company's website!" }],
							initialValue: nullSafeGet('corporateURL', formData),
						})(<Input style={{ maxWidth: 400 }} />)}
					</FormItem>
				)}
				<FormItem>
					<Button
						type="primary"
						htmlType="submit"
						loading={isLoading}
						className="supplierForm__button"
					>
						{newAccountSetupMode ? 'Create my company' : 'Create supplier'}
					</Button>
				</FormItem>
				{error && (
					<Typography.Text type="danger">
						Oops! some error occured! Error: "{error}"
					</Typography.Text>
				)}
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	currentUser: state.session.currentUser,
	minimal: ownProps.minimal,
	spendCategories: state.spend_categories,
	formData: ownProps.formData,
	creating: state.suppliers.creating,
	updating: state.suppliers.updating,
	onSubmit: ownProps.onSubmit,
});

const mapDispatchToProps = (dispatch) => ({
	saveFormData: (formData) => dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.saveFormData(formData)),
	getSupplierFacilities: (supplierCompanyId) =>
		dispatch(supplierFacilitiesRestCrudThunksForSupplier.read({ supplierCompanyId })),
	fetchSpendCategories: (params, targetCollectionName) =>
		dispatch(spendCategoriesRestCrudThunksForSupplier.read(params, targetCollectionName)),
	fetchMultipleSpendCategories: (ids, targetCollectionName) =>
		dispatch(spendCategoriesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	create: (entity) => dispatch(suppliersRestCrudThunksForSupplier.create(entity)),
	createSupplier: (entity) => dispatch(createSupplierCompany(entity)),
	update: (entity) => dispatch(suppliersRestCrudThunksForSupplier.update(entity)),
	getSuggestions: (entity) => dispatch(getSuggestionsForSupplierCompanies(entity)),
	associateSupplierContactWithFacility: (id, entity) =>
		dispatch(associateSupplierContactWithCompany(id, entity)),
	refreshCurrentUser: () => dispatch(checkUserTypeToken(ROLE_TYPES.SUPPLIER)()),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<SupplierCompanyFormProps>()(SupplierCompanyForm))
);

import * as React from 'react';
import { Provider } from 'react-redux';
import App from '../App';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import configureStore from '../../store/store';
import { ConfigProvider } from 'antd';
import { Auth0Provider } from '@auth0/auth0-react';
// @ts-ignore
import { currencies as mockCurrencies } from '../../mock_data/mocks';
import IntercomService from '../../utils/IntercomService';
import AppStoreRedirection from '../app_store_element';
import posthog from 'posthog-js';
import { getConfig } from '../../utils/Auth0Utils';
import LoginWrapper from '../login_wrapper';

const style = require('../../index.less');
const enUS = require('antd/lib/locale-provider/en_US');
const antdStyle = require('antd/dist/antd.less');

function resultsArrayToObject(objArr) {
	return objArr.reduce((acc, el) => {
		acc[el.id] = el;
		return acc;
	}, {});
}

const preloadedState = {
	currencies: {
		fetching: false,
		fetchErrors: [],
		creating: false,
		createErrors: [],
		deleting: false,
		deleteErrors: [],
		records: resultsArrayToObject(mockCurrencies),
	},
};
if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
	Sentry.init({
		dsn: 'https://3caa87e8bbc545a9afa9ad79756cea11@o475993.ingest.sentry.io/6611349',
		integrations: [new BrowserTracing()],
		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 0.5,

		// https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/16
		// disabling these types of errors because this will need a lot of handling on apis , since this is asynchronous ,
		//  not breaking any user flow but indicate that these calls need to be handled properly with .catch at every promise
		// to be fixed once we solve all higher priority bugs.
		// FIXME: @arnav

		// TLDR; ResizeObserver error is just an event sent by chrome browser and is not needed to be fixed .
		// https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402/4
		ignoreErrors: ['Non-Error promise rejection captured', 'ResizeObserver loop limit exceeded'],
	});
}

posthog.init(
	// new
	'phc_mbelib77jLLIGafTlfFiDTZSKmWbkI7kjxCNybo3NvB',
	{ api_host: 'https://app.posthog.com' }
);

const store = configureStore(preloadedState);

export default class Root extends React.Component {
	componentWillMount() {
		const now = new Date();
		IntercomService.init();
	}

	render() {
		const { history } = this.props;
		const config = getConfig();
		const onRedirectCallback = (appState) => {
			console.log('appState', appState);
			// history &&
			// 	history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
		};
		const providerConfig = {
			domain: config.domain,
			clientId: config.clientId,
			onRedirectCallback,
			authorizationParams: {
				redirect_uri: window.location.origin,
				...(config.audience ? { audience: config.audience } : null),
			},
		};
		return (
			<Provider store={store}>
				<BrowserRouter>
					<Auth0Provider {...providerConfig}>
						<ConfigProvider locale={enUS}>
							<AppStoreRedirection>
								<LoginWrapper store={store}>
									<App />
								</LoginWrapper>
							</AppStoreRedirection>
						</ConfigProvider>
					</Auth0Provider>
				</BrowserRouter>
			</Provider>
		);
	}
}

import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Card } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import FileUploader from '../file_uploader/FileUploader';
import { assetModelsRestCrudThunksForBuyer } from '../../thunks/asset_models_thunks';
import { TwoLineList } from '../two_line_list/TwoLineList';
import { EmptyState } from '../empty_state/EmptyState';

const style = require('./AssetModelDetail.less');

interface AssetModelProps {
	creating: boolean;
	updating: boolean;
	formData: any;
	history: any;
	redirectForwardUrl?: string;
	onSuccess: any;
	onCancel: any;
	assetTypeId: any;
	createErrors: any[];
	updateErrors: any[];
	currentUser: any;
	create: any;
	update: any;
	assetModel: any;
}

interface AssetModelDetailProps extends FormComponentProps {
	creating: boolean;
	updating: boolean;
	formData: any;
	history: any;
	redirectForwardUrl?: string;
	onSuccess: any;
	onCancel: any;
	assetTypeId: any;
	createErrors: any[];
	updateErrors: any[];
	currentUser: any;
	create: any;
	update: any;
	assetModel: any;
}

class AssetModelDetail extends React.Component<AssetModelDetailProps, any> {
	handleFileManualUpload = (newAttachments) => {
		const { form, formData, update } = this.props;
		const assetModel = {
			...formData,
			manuals: newAttachments,
		};
		update(assetModel);
	};

	render() {
		const { formData } = this.props;
		return (
			<div>
				<h6
					style={{
						margin: 16,
						fontWeight: 400,
						color: '#6027b0',
					}}
				>
					{formData.modelName}
				</h6>
				<div
					style={{
						marginBottom: 24,
						justifyContent: 'space-between',
					}}
				>
					<Card title="Specs" className="materialCard" bodyStyle={{ padding: 0 }}>
						<div className="materialCard__body">
							{nullSafeGetOrElse('specs.length', formData, 0) > 0 ? (
								<TwoLineList
									listItems={nullSafeGet('specs', formData)}
									lineOneRender={(record) => record.value}
									lineTwoRender={(record) => record.key}
								/>
							) : (
								<EmptyState
									height={120}
									headline={'No specs found'}
									body={
										<div style={{ maxWidth: 488, textAlign: 'center' }}>
											We don't see any specifications associated with this model
										</div>
									}
								/>
							)}
						</div>
					</Card>
				</div>
				<div>
					<Card title="Manuals" className="materialCard" bodyStyle={{ padding: 0 }}>
						<div className="materialCard__body">
							<FileUploader
								key={formData.manuals.map((manual) => manual.fileId).join(',') + 'detail'}
								roleType="buyer"
								defaultFileList={formData.manuals || []}
								handleUploadSuccess={this.handleFileManualUpload}
								uploaderType={'readAndRemove'}
							/>
						</div>
					</Card>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	formData: ownProps.formData,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,
	onCancel: ownProps.onCancel,
	assetTypeId: ownProps.assetTypeId,

	assetModel: state.asset_models.detail,
	createErrors: state.asset_models.createErrors,
	updateErrors: state.asset_models.updateErrors,
	creating: state.asset_models.creating,
	updating: state.asset_models.updating,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	update: (entity) => dispatch(assetModelsRestCrudThunksForBuyer.update(entity)),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<AssetModelDetailProps>()(AssetModelDetail))
);

import * as React from 'react';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { connect } from 'react-redux';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { getFileLink, getDefaultFileList } from '../../utils/FileAccessUtils';
import { EmptyState } from '../empty_state/EmptyState';
import { retrieveCachedUserDetails } from '../../thunks/session_thunks';
import OWUpload from '../OWUpload';

require('./FileUploader.less');

export function fileUploadValidation(file, fileMimeType) {
	const fileMimeTypes = [fileMimeType];
	const isFileType = fileMimeTypes.indexOf(file.type) !== -1;
	if (!isFileType) {
		message.error(`Uploaded file is not of the expected type: ${fileMimeType}`);
	}
	return isFileType;
}

class FileUploader extends React.Component<any, any> {
	constructor(props) {
		super(props);
		const defaultFileList = getDefaultFileList(props.defaultFileList, props.userType);
		this.state = {
			uploadLoading: false,
			fileList: defaultFileList,
		};
	}

	handleUploadChange = (info) => {
		const { handleUploadSuccess, handleFileListChange, userType } = this.props;

		let fileList = handleFileListChange ? handleFileListChange(info).fileList : info.fileList;

		fileList = fileList.map((file) => {
			if (file.response) {
				// Component will show file.url as link
				const fileId = nullSafeGetOrElse('response.data.fileId', file, '');
				const fileName = nullSafeGetOrElse('response.data.fileName', file, '');
				const fileUrl = getFileLink(fileId, fileName, userType);
				file.url = fileUrl;
			}
			return file;
		});

		fileList = fileList.filter((file) => {
			if (file.response) {
				return file.status === 'done';
			}
			return true;
		});

		this.setState({ fileList });

		const basicFileList = fileList.map((file) => {
			const fileId = nullSafeGetOrElse('response.data.fileId', file, '');
			const fileName = nullSafeGetOrElse('response.data.fileName', file, '');
			return {
				fileId,
				fileName,
			};
		});

		if (basicFileList.filter((_) => !_.fileId).length === 0) {
			handleUploadSuccess(basicFileList);
		}
	};

	render() {
		const { uploaderType, emptyState, multiple, beforeUpload, userType, buttonType, accept } =
			this.props;
		const { fileList } = this.state;

		const BACKEND_URI = getBackendUri();
		const token = retrieveCachedUserDetails(['token']).token;
		const uploadProps = {
			action: `${BACKEND_URI}/api/v1/${userType}/file/upload`,
			onChange: this.handleUploadChange,
			fileList: fileList,
			multiple: multiple,
			beforeUpload: beforeUpload,
			showUploadList: { showPreviewIcon: true, showRemoveIcon: true },
			headers: { 'X-Auth-Token': token },
			...(accept && { accept }),
		};

		const uploadReadOnlyProps = {
			action: `${BACKEND_URI}/api/v1/${userType}/file/upload`,
			onChange: this.handleUploadChange,
			fileList: fileList,
			multiple: multiple,
			beforeUpload: beforeUpload,
			showUploadList: { showPreviewIcon: true, showRemoveIcon: false },
			headers: { 'X-Auth-Token': token },
			...(accept && { accept }),
		};

		const uploadReadAndRemoveProps = {
			action: `${BACKEND_URI}/api/v1/${userType}/file/upload`,
			onChange: this.handleUploadChange,
			fileList: fileList,
			multiple: multiple,
			beforeUpload: beforeUpload,
			showUploadList: { showPreviewIcon: true, showRemoveIcon: true },
			headers: { 'X-Auth-Token': token },
			...(accept && { accept }),
		};

		return uploaderType === 'dragger' ? (
			<OWUpload uploaderType="dragger" {...uploadProps} multiple>
				<div style={{ marginTop: 32, height: 180 }}>
					<p className="ant-upload-drag-icon">
						<InboxOutlined translate="" />
					</p>
					<p className="ant-upload-text">Click or drag files to this area to upload</p>
					<p className="ant-upload-hint">Support for single or bulk upload.</p>
				</div>
			</OWUpload>
		) : uploaderType === 'readOnly' ? (
			<OWUpload {...uploadReadOnlyProps}>
				{fileList && fileList.length === 0 ? (
					emptyState ? (
						emptyState
					) : (
						<EmptyState
							height={160}
							graphic={
								<img
									style={{ marginBottom: 8 }}
									src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
									alt="Nothing's here."
								/>
							}
							headline={"Nothing's here."}
							body={
								<div style={{ textAlign: 'center' }}>
									<div style={{ maxWidth: 488, marginBottom: 16 }}>
										No files have been uploaded yet.
									</div>
								</div>
							}
						/>
					)
				) : null}
			</OWUpload>
		) : uploaderType === 'readAndRemove' ? (
			<OWUpload {...uploadReadAndRemoveProps}></OWUpload>
		) : (
			<OWUpload {...uploadProps}>
				<Button type={buttonType}>
					<UploadOutlined translate="" /> Click to Upload
				</Button>
			</OWUpload>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	userType: state.session.userType,
	emptyState: ownProps.emptyState,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FileUploader);

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Card } from 'antd';
import SiteSurveyForm from '../site_survey_form/SiteSurveyForm';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;

class NewSiteSurveyPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
	}

	render() {
		const { history } = this.props;
		return (
			<Content>
				<LogOnMountWithStandardEventProperties eventType="visited buyer new site survey page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<Card>
							<div>
								<h5 style={{ marginBottom: 24 }}>New Site Surveys</h5>
								<SiteSurveyForm
									formData={{}}
									onSuccess={(rec) => history.push(`/buyer/siteSurveys/overview/all`)}
								/>
							</div>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	siteSurveyTemplates: state.site_survey_templates.detail,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewSiteSurveyPage));

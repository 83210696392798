import React, { FC, useMemo } from 'react';
import { Form, Input, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { locationsRestCrudThunksForSupplier } from '../../thunks/locations_thunks';
import TransferToLocationFormFields from './TransferToLocationFormFields';
import TextArea from 'antd/lib/input/TextArea';

const BulkTransferFromStockLocationForm: FC<any> = ({
	FORM_ID,
	onTransferToSL,
	onTransferToLocation,
	isPartsTransfer,
	isTransfer,
	stockLocationId,
	assetNumber,
	assetTypeId,
	isSingleEpsl,
	stockLocations,
	fetchStockLocations,
	fetchMultipleStockLocations,
	locations,
	fetchLocations,
	fetchMultipleLocations,
	locationType,
	companyConfig,
	equipmentTypeModelName,
}): React.ReactElement => {
	const [form] = useForm();

	const isStockLocation = useMemo(
		() => isPartsTransfer || locationType === 'stockLocation',
		[isPartsTransfer, locationType]
	);

	const transferNoteRequired = useMemo(
		() => nullSafeGetOrElse('inventoryConfig.transferNoteMandatory', companyConfig, false),
		[companyConfig]
	);

	return (
		<Form
			form={form}
			id={FORM_ID}
			layout="vertical"
			onFinish={isStockLocation ? onTransferToSL : onTransferToLocation}
		>
			{!isTransfer && !isPartsTransfer && (
				<Form.Item
					label="Transfer to"
					initialValue={locationType}
					name="locationType"
					hidden
				></Form.Item>
			)}
			{isPartsTransfer || (!isTransfer && isStockLocation) ? (
				<Form.Item
					name="toStockLocationId"
					label="To Stock Location"
					rules={[
						{
							required: true,
							message: 'Please select a stock location!',
						},
					]}
				>
					<OWAsyncSelect
						stateSlice={stockLocations}
						valueAccessor={(el) => el.id}
						targetCollectionName="stockLocationDropdown"
						fetchData={(
							name,
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						) =>
							fetchStockLocations(
								{
									name: name || undefined,
								},
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							)
						}
						fetchMultiple={(ids, targetCollectionName) => {
							fetchMultipleStockLocations(ids, targetCollectionName);
						}}
						renderRecord={(sl) => (
							<Select.Option
								key={sl.id}
								value={sl.id}
								disabled={`${stockLocationId}` === `${sl.id}`}
							>
								<Row align="top" justify="space-between">
									<div style={{ flex: 1 }}>{nullSafeGet('name', sl)}</div>
								</Row>
							</Select.Option>
						)}
						sortBy={{ sort_by: 'availableQuantity', order: 'ascend' }}
					/>
				</Form.Item>
			) : (
				<Form.Item
					name="locationId"
					label="Location"
					rules={[{ required: true, message: 'Please assign this asset to a location.' }]}
				>
					<OWAsyncSelect
						style={{ width: '100%' }}
						stateSlice={locations}
						valueAccessor={(el) => el.id}
						targetCollectionName="locationsIndex"
						fetchData={(
							name,
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						) =>
							fetchLocations(
								{
									name: name || undefined,
								},
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							)
						}
						fetchMultiple={(ids, targetCollectionName) => {
							fetchMultipleLocations(ids, targetCollectionName);
						}}
						renderRecord={(loc) => (
							<Select.Option key={loc.id} value={loc.id}>
								{loc.name}
							</Select.Option>
						)}
					/>
				</Form.Item>
			)}
			{isSingleEpsl && !isStockLocation ? (
				<TransferToLocationFormFields
					equipmentTypeModelName={equipmentTypeModelName}
					form={form}
					initialAssetNumber={assetNumber}
					initialAssetTypeId={assetTypeId}
					showTransferDate
				/>
			) : null}
			{!isPartsTransfer ? (
				<Form.Item
					name="transferNote"
					label="Transfer Note"
					rules={[{ required: transferNoteRequired, message: 'Please enter transfer note.' }]}
				>
					<TextArea />
				</Form.Item>
			) : null}
		</Form>
	);
};

const mapStateToProps = (state) => ({
	stockLocations: state.stock_locations,
	locations: state.locations,
	saving: state.parts_per_stock_locations.updating || state.equipment_per_stock_locations.updating,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	fetchMultipleStockLocations: (ids, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchStockLocations: (params, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.read(params, targetCollectionName)),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchLocations: (params, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForSupplier.read(params, targetCollectionName)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(BulkTransferFromStockLocationForm)
);

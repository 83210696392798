import { Modal, List } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	attentionDashboardsRestCrudThunksForBuyer,
	attentionDashboardsRestCrudThunksForSupplier,
} from '../../thunks/attention_dashboard_thunks';
import {
	savedFilterViewsRestCrudThunksForBuyer,
	savedFilterViewsRestCrudThunksForSupplier,
} from '../../thunks/saved_filter_views_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';
import ADSavedViewEmptyState from './ADSavedViewEmptyState';
import ADSavedViewRowDisplay from './ADSavedViewRowDisplay';

const ADAddWidgetPopup = ({
	fetchSavedFilterViewsForBuyer,
	fetchSavedFilterViewsForSupplier,
	filterIds,
	roleType,
	onCancel,
	attentionDashboard,
	saveAttentionDashboardForBuyer,
	saveAttentionDashboardForSupplier,
}): React.ReactElement => {
	const [views, setViews] = useState([]);
	const [loading, setLoading] = useState(true);

	const fetchSavedFilterViews = useMemo(
		() =>
			roleType === ROLE_TYPES.SUPPLIER
				? fetchSavedFilterViewsForSupplier
				: fetchSavedFilterViewsForBuyer,
		[roleType, fetchSavedFilterViewsForBuyer, fetchSavedFilterViewsForSupplier]
	);

	const saveAttentionDashboard = useMemo(
		() =>
			roleType === ROLE_TYPES.SUPPLIER
				? saveAttentionDashboardForSupplier
				: saveAttentionDashboardForBuyer,
		[roleType, saveAttentionDashboardForBuyer, saveAttentionDashboardForSupplier]
	);

	const refreshList = useCallback(() => {
		setLoading(true);
		const _ = undefined;
		fetchSavedFilterViews(
			_,
			_,
			{
				current: 1,
				pageSize: 50,
			},
			{
				sort_by: 'isPublic',
			},
			_
		).then((result) => {
			setLoading(false);
			setViews(result.filter((_) => (filterIds ? !filterIds.includes(_.id) : true)));
		});
	}, [fetchSavedFilterViews, filterIds]);

	useEffect(() => {
		refreshList();
	}, [refreshList, fetchSavedFilterViews]);

	const addWidget = useCallback(
		(id) => () => {
			setLoading(true);
			saveAttentionDashboard({
				...attentionDashboard,
				savedFilterViewIds: [id].concat(attentionDashboard.savedFilterViewIds),
			}).then(() => onCancel());
		},
		[attentionDashboard, onCancel, saveAttentionDashboard]
	);

	return (
		<Modal visible={true} title="Add View" footer={null} onCancel={onCancel} closable={true}>
			{!loading && views.length < 1 && <ADSavedViewEmptyState roleType={roleType} />}
			<List
				dataSource={views}
				loading={loading}
				renderItem={(view) => (
					<List.Item
						key={view.id}
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
						}}
					>
						<ADSavedViewRowDisplay record={view} onAddWidget={addWidget(view.id)} />
					</List.Item>
				)}
			/>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
	attentionDashboard: state.attention_dashboards.detail,
});

const mapDispatchToProps = (dispatch) => ({
	fetchSavedFilterViewsForBuyer: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			savedFilterViewsRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchSavedFilterViewsForSupplier: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			savedFilterViewsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	saveAttentionDashboardForBuyer: (entity) =>
		dispatch(attentionDashboardsRestCrudThunksForBuyer.update(entity)),
	saveAttentionDashboardForSupplier: (entity) =>
		dispatch(attentionDashboardsRestCrudThunksForSupplier.update(entity)),
});

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(ADAddWidgetPopup));

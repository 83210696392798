import { SUPPLIER_CONTACTS_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/supplier_contacts_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const SUPPLIER_CONTACT_PRIMARY_KEY = 'email';

const initialState: any = standardInitialStateGenerator([
	'eventBuyerTeamFormSearchResults',
	'dispatcherSupplierContacts',
	'ccListSupplierContacts',
]);
export default standardReducerGenerator(
	CRUD_ACTION_CONSTANTS,
	initialState,
	SUPPLIER_CONTACT_PRIMARY_KEY
);

export const FETCH_SUPPLIER_USERS = 'FETCH_SUPPLIER_USERS';
export function fetchSupplierUsers() {
	return {
		type: FETCH_SUPPLIER_USERS,
	};
}

export const FETCH_SUPPLIER_USERS_SUCCESS = 'FETCH_SUPPLIER_USERS_SUCCESS';
export function fetchSupplierUsersSuccess(records) {
	return {
		type: FETCH_SUPPLIER_USERS_SUCCESS,
		records,
	};
}

export const FETCH_SUPPLIER_USERS_FAILURE = 'FETCH_SUPPLIER_USERS_FAILURE';
export function fetchSupplierUsersFailure(errors) {
	return {
		type: FETCH_SUPPLIER_USERS_FAILURE,
		errors,
	};
}

export const DISABLE_SUPPLIER_USER_START = 'DISABLE_SUPPLIER_USER_START';
export function disableSupplierUserStart(userName) {
	return {
		type: DISABLE_SUPPLIER_USER_START,
		userName,
	};
}

export const DISABLE_SUPPLIER_USER_SUCCESS = 'DISABLE_SUPPLIER_USER_SUCCESS';
export function disableSupplierUserSuccess(userName) {
	return {
		type: DISABLE_SUPPLIER_USER_SUCCESS,
		userName,
	};
}

export const DISABLE_SUPPLIER_USER_FAILURE = 'DISABLE_SUPPLIER_USER_FAILURE';
export function disableSupplierUserFailure(userName, errors) {
	return {
		type: DISABLE_SUPPLIER_USER_FAILURE,
		userName,
		errors,
	};
}

export const REINVITE_SUPPLIER_USER_START = 'REINVITE_SUPPLIER_USER_START';
export function reinviteSupplierUserStart(userName) {
	return {
		type: REINVITE_SUPPLIER_USER_START,
		userName,
	};
}

export const REINVITE_SUPPLIER_USER_SUCCESS = 'REINVITE_SUPPLIER_USER_SUCCESS';
export function reinviteSupplierUserSuccess(userName) {
	return {
		type: REINVITE_SUPPLIER_USER_SUCCESS,
		userName,
	};
}

export const REINVITE_SUPPLIER_USER_FAILURE = 'REINVITE_SUPPLIER_USER_FAILURE';
export function reinviteSupplierUserFailure(userName, errors) {
	return {
		type: REINVITE_SUPPLIER_USER_FAILURE,
		userName,
		errors,
	};
}

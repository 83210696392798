import React, { useMemo } from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import HyperLink, { ENTITY_TYPE } from '../common/HyperLink';

require('./PurchaseRequestEquipmentLineItemRowDisplay.less');

export const PurchaseRequestEquipmentLineItemRowDisplay: React.FC<any> = ({
	titleLength = 40,
	purchaseRequestEquipmentLineItem,
	...props
}) => {
	const hasWorkOrder = useMemo(
		() =>
			nullSafeGetOrElse('supplierPurchaseRequestLineItems', purchaseRequestEquipmentLineItem, [])
				.map((_) => _.supplierPurchaseRequest)
				.some((_) => !!_.workOrderId),
		[purchaseRequestEquipmentLineItem]
	);

	const allWorkOrderIds = useMemo(() => {
		const workOrderIds = nullSafeGetOrElse(
			'supplierPurchaseRequestLineItems',
			purchaseRequestEquipmentLineItem,
			[]
		)
			.map((_) => nullSafeGet('supplierPurchaseRequest.workOrderId', _))
			.filter((_) => !!_);
		return Array.from(new Set(workOrderIds));
	}, [purchaseRequestEquipmentLineItem]);

	const allAssets = useMemo(() => {
		const assets = nullSafeGetOrElse(
			'supplierPurchaseRequestLineItems',
			purchaseRequestEquipmentLineItem,
			[]
		)
			.map((_) => nullSafeGet('supplierPurchaseRequest.asset', _))
			.filter((_) => !!_);
		const ids = assets.map((_) => _.id);
		const uniqueIds = Array.from(new Set(ids));
		return uniqueIds.map((id) => assets.find((_) => _.id === id)).filter((_) => !!_);
	}, [purchaseRequestEquipmentLineItem]);

	const hasAsset = useMemo(() => allAssets.length > 0, [allAssets.length]);

	return (
		<div
			className="purchaseRequestEquipmentLineItemRowDisplay"
			style={{ justifyContent: 'space-between' }}
			{...props}
		>
			<div className="purchaseRequestEquipmentLineItemRowDisplay">
				<div>
					<div className="purchaseRequestEquipmentLineItemRowDisplay__rowTitle">
						<Ellipsis tooltip={true} length={titleLength}>
							{nullSafeGet('equipmentType.modelName', purchaseRequestEquipmentLineItem)}
						</Ellipsis>
					</div>
					{hasAsset && (
						<div className="purchaseRequestEquipmentLineItemRowDisplay__rowSubtitle">
							Asset Serial #
							{allAssets.map((asset, idx) => (
								<HyperLink
									key={`asset-${idx}`}
									text={asset.serialNumber || asset.assetNumber}
									entityType={ENTITY_TYPE.ASSET_DETAIL}
									entityId={asset.id}
								/>
							))}
						</div>
					)}
					{hasWorkOrder && (
						<div className="purchaseRequestEquipmentLineItemRowDisplay__rowSubtitle">
							Work Order #
							{allWorkOrderIds.map((id) => (
								<HyperLink
									key={`WO-${id}`}
									text={id}
									entityType={ENTITY_TYPE.WORK_ORDER}
									entityId={id}
								/>
							))}
						</div>
					)}
				</div>
			</div>
			<div className="purchaseRequestEquipmentLineItemRowDisplay__rightSide"></div>
		</div>
	);
};

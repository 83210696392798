import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout } from 'antd';
import PlannedMaintenanceTemplateForm from '../planned_maintenance_template_form/PlannedMaintenanceTemplateForm';
import { NewPage } from '../new_page/NewPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;

class NewPlannedMaintenanceTemplatePage extends React.Component<any, any> {
	render() {
		const { history, userType } = this.props;
		return [
			<LogOnMountWithStandardEventProperties
				eventType={`visited ${userType} new pm template page`}
			/>,
			<NewPage
				title="New Planned Maintenance Template"
				formComponentProps={{
					formData: {},
					onSuccess: (rec) =>
						history.push(`/${userType}/plannedMaintenance/templates/detail/${rec.id}`),
				}}
				fetching={false}
				FormComponent={PlannedMaintenanceTemplateForm}
			/>,
		];
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(NewPlannedMaintenanceTemplatePage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import React, { FC, useCallback, useMemo } from 'react';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { EmptyState } from '../empty_state/EmptyState';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { PARTS_EQUIPMENTS_WORK_ORDERS_CRUD_ACTION_CREATORS } from '../../actions/parts_equipments_work_orders_actions';
import { partsEquipmentsWorkOrdersRestCrudThunksForSupplier } from '../../thunks/parts_equipments_work_orders_thunks';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import { WorkOrderRowDisplay } from '../work_order_row_display/WorkOrderRowDisplay';

const TC_NAME = 'partsEquipmentsWorkordersIndex';

const PartsEquipmentWorkOrdersList: FC<any> = ({
	backText,
	preAppliedFilters,
	updateFilters,
	clearAndUpdateFilters,
	workOrdersPartsEquipment,
	history,
	location,
	showQuantity,
}): React.ReactElement => {
	const columns = useMemo(
		() => [
			{
				title: 'Work Order',
				dataIndex: 'workOrder',
				render: (_) => <WorkOrderRowDisplay workOrder={_} simpler />,
			},
			...(showQuantity
				? [
						{
							title: 'Quantity',
							dataIndex: 'partsQuantity',
						},
				  ]
				: []),
		],
		[showQuantity]
	);

	const onRow = useCallback(
		(record) => ({
			onClick: () =>
				history.push(
					getLinkWIthBackLinkParams(
						location,
						backText,
						`/supplier/workOrders/detail/${record.workOrderId}/charges`
					)
				),
		}),
		[backText, history, location]
	);

	return (
		<PaginatedReduxTableWithHeader
			targetCollectionName={TC_NAME}
			updateFilters={updateFilters}
			stateSlice={workOrdersPartsEquipment}
			clearAndUpdateFilters={clearAndUpdateFilters}
			filterConfig={[]}
			entityCollectionName="work_orders_parts_equipment"
			tableColumns={columns}
			fetchData={partsEquipmentsWorkOrdersRestCrudThunksForSupplier.read}
			rightActions={null}
			showHeader={showQuantity}
			hideFilters
			onTableRow={onRow}
			preAppliedFilters={preAppliedFilters}
			emptyState={
				<EmptyState
					graphic={
						<img
							alt="No Data"
							style={{ marginBottom: 8 }}
							src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
						/>
					}
					headline={'No Work Orders found'}
					body={null}
				/>
			}
		/>
	);
};

const mapStateToProps = (state) => ({
	workOrdersPartsEquipment: state.work_orders_parts_equipment,
});

const mapDispatchToProps = (dispatch) => ({
	updateFilters: (filters, targetCollection) =>
		dispatch(
			PARTS_EQUIPMENTS_WORK_ORDERS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)
		),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			PARTS_EQUIPMENTS_WORK_ORDERS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(
				filters,
				targetCollectionName
			)
		),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(PartsEquipmentWorkOrdersList)
);

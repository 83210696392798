import { Alert, Button, Modal, Row, Spin } from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
	activateWorkspace,
	cancelChangeWorkspace,
	changeWorkspace,
	impersonationDeactivate,
	initiateChangeWorkspace,
	syncWorkspace,
} from '../../thunks/session_thunks';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';

const mapStateToProps = (state) => ({
	workspaceId: state.session.workspaceId,
	switchingWorkspace: state.session.switchingWorkspace,
	workSpaceError: state.session.workSpaceError,
	confirmWorkspaceChange: state.session.confirmWorkspaceChange,
	userType: state.session.userType,
});

const mapDispatchToProps = (dispatch) => ({
	activateWorkspace: (id) => dispatch(activateWorkspace(id)),
	changeWorkspace: (id, userType) => dispatch(changeWorkspace(id, userType)),
	syncWorkspace: () => dispatch(syncWorkspace()),
	cancelChangeWorkspace: () => dispatch(cancelChangeWorkspace()),
	initiateChangeWorkspace: (id) => dispatch(initiateChangeWorkspace(id)),
	impersonationDeactivate: (userType) => dispatch(impersonationDeactivate(userType)),
});

// Functionality of this hoc
// 1. User can land on website with
// 1. no query param of workspace : flows works as usual, pick primary facilityId and use it as to operate from there
// 2. &workspace={workspaceId} : store this in localStorage at app mount , Root ⇒ componentWillMount
// 2. Initializing App :
// 1. User is already logged In : pick workspaceId from localStorage if exists || pick primary workspace Id from userdetails and set it as default
// 2. User wants to sign-up : post signup, while logging in do the above same step.
// 3. User logs in via loginpage : post login , do the same as above.
// 3. on App Mount :use [https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API](https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API) to observe when tab/window is not visible to user, if its visible to user again, do the step 1 again to re-sync all tabs with same workspace.
// 4. If app-state(redux store) workspaceId !== localStorage workspaceId , change the worskpace id in app-state
// 5. User switches : call an api to set the current workspace so backend is in sync with what facility/workspaceid to work with.
// 6. On logout/401 : reset and delete localstorage key.
// 7. Inviting workspace : need a API to parse invite and respond with workspaceId and user details. set this workspaceId in localstorage
// 8. Creating workspace : Similar as creating an account, need new API for this  that works on auth token to map user with that workspace.

export const WorkspacesHoc = (WrappedComponent) => {
	const HocComponent = ({ ...props }) => {
		useEffect(() => {
			// logic for handling when tab is changed.
			let hidden;
			let visibilityChange;
			if (typeof document.hidden !== 'undefined') {
				// Opera 12.10 and Firefox 18 and later support
				hidden = 'hidden';
				visibilityChange = 'visibilitychange';
			} else if (typeof document.msHidden !== 'undefined') {
				hidden = 'msHidden';
				visibilityChange = 'msvisibilitychange';
			} else if (typeof document.webkitHidden !== 'undefined') {
				hidden = 'webkitHidden';
				visibilityChange = 'webkitvisibilitychange';
			}
			const handleVisibilityChange = () => {
				if (!document[hidden]) {
					props.syncWorkspace();
				}
			};
			document.addEventListener(visibilityChange, handleVisibilityChange, false);

			return () => {
				document.removeEventListener(visibilityChange, handleVisibilityChange, false);
			};
		}, []);
		const impersonationEmail = localStorage.getItem('impersonation');
		return (
			<>
				{impersonationEmail && (
					<Alert
						action={
							<Button
								onClick={() => {
									props.impersonationDeactivate(props.userType).then(() => {
										window.location.reload();
									});
								}}
								type="ghost"
							>
								Stop impersonating
							</Button>
						}
						message={`Hey you are viewing as ${impersonationEmail} in a read-only mode. No create/update function will be allowed.`}
						type="warning"
						showIcon
					/>
				)}
				<WrappedComponent {...props} />
				{(props.switchingWorkspace && (
					<Modal centered footer={null} visible>
						<Row justify="center">
							<PageLoadingPlaceholder style={{ minHeight: 'auto' }} />
						</Row>
						<Row justify="center">
							<span>Updating to your selected facility....</span>
						</Row>
					</Modal>
				)) ||
					null}
				{(props.confirmWorkspaceChange && (
					<Modal centered footer={null} visible afterClose={props.cancelChangeWorkspace}>
						<Row justify="center">
							<h6>Do you want to switch to the newly created Facility? </h6>
						</Row>
						<Row justify="space-evenly" style={{ marginTop: 32 }}>
							<Button
								type="primary"
								onClick={() => props.changeWorkspace(props.confirmWorkspaceChange)}
							>
								Yes, Go ahead
							</Button>
							<Button type="ghost" onClick={props.cancelChangeWorkspace}>
								No
							</Button>
						</Row>
					</Modal>
				)) ||
					null}
			</>
		);
	};

	return HocComponent;
};

export default (WrapperComponent) =>
	connect(mapStateToProps, mapDispatchToProps)(WorkspacesHoc(WrapperComponent));

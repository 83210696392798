import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { troubleshootingRestCrudThunksForBuyer } from '../../thunks/troubleshooting_thunks';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

const TroubleshootingDetailPage: FC<any> = ({
	companyConfig,
	fetching,
	childRoutes,
	history,
	match,
	getTroubleshooting,
	userType,
	troubleshootingAttribute,
}): React.ReactElement => {
	const tabs = useMemo(
		() => [
			{
				key: 'details',
				name: 'Overview',
			},
		],
		[]
	);

	const tsId = useMemo(() => match.params.id, [match.params.id]);

	useEffect(() => tsId && getTroubleshooting(tsId), [tsId, getTroubleshooting]);

	const basePath = useMemo(() => `/${userType}/admin/troubleshooting/detail`, [userType]);

	const handleTabChange = useCallback(
		(key) => history.push(`${basePath}/${tsId}/${key}`),
		[basePath, history, tsId]
	);

	const checkTabActive = useCallback(
		(key) => {
			const pathname = history.location.pathname;
			return pathname.indexOf(key) !== -1;
		},
		[history.location.pathname]
	);

	const title = useMemo(
		() => nullSafeGetOrElse('name', troubleshootingAttribute, 'Troubleshooting Attribute'),
		[troubleshootingAttribute]
	);

	return (
		<DetailPageHeader
			exactPath={`${basePath}/:id`}
			redirectPath={`${basePath}/${tsId}/details`}
			backLinkText={'Back to Troubleshooting'}
			backLinkTo={`/${userType}/admin/troubleshooting`}
			title={fetching ? null : title}
			subtitle={null}
			actions={[
				<Link
					to={`/${userType}/admin/troubleshooting/edit/${tsId}`}
					key={1}
					style={{ marginRight: 16 }}
				>
					<Button ghost={true}>Edit</Button>
				</Link>,
			]}
			tabs={tabs}
			backgroundColor={nullSafeGet('detail.config.theme.navblock', companyConfig)}
			handleTabChange={handleTabChange}
			checkTabActive={checkTabActive}
			childRoutes={childRoutes}
		/>
	);
};

const mapStateToProps = (state) => ({
	companyConfig: state.company_config,
	fetching: state.troubleshooting.fetching,
	troubleshootingAttribute: state.troubleshooting.detail,
});

const mapDispatchToProps = (dispatch) => ({
	getTroubleshooting: (id) => dispatch(troubleshootingRestCrudThunksForBuyer.readOne(id)),
});

const ComponentWithoutUserType = withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(TroubleshootingDetailPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

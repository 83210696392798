import React, { useEffect } from 'react';
import { Layout, Row, Col, Card, Alert, Space, Button, Rate, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { buyerSettingsRestCrudThunksForBuyer } from '../../thunks/buyer_settings_thunks';
import {
	BUYER_NOTIFICATION_APPROVALS_DISPLAY_NAMES,
	BUYER_NOTIFICATION_NOTES_DISPLAY_NAMES,
	BUYER_NOTIFICATION_RFP_STATUS_DISPLAY_NAMES,
	BUYER_NOTIFICATION_WORK_ORDER_STATUS_DISPLAY_NAMES,
	WORK_ORDER_STATUS_DISPLAY_NAMES,
} from '../../constants/WorkOrderStatuses';
import EditableCellTable from '../editable_cell_table/EditableCellTable';

const { Content } = Layout;

require('./BuyerNotificationsSettingsPage.less');

const DISABLED_TYPES = [
	'WorkOrderNotesMentions',
	'WorkOrderApproval',
	'InvoiceApproval',
	'ProposalApproval',
];

const getNotificationsArray = (notifications, displayNamesMap) =>
	Object.keys(notifications).map((k) => ({
		...notifications[k],
		key: k,
		displayName: displayNamesMap[k],
	}));

const getDefaultNotifications = (displayNamesMap) => {
	const defaultNotifications = {};
	Object.keys(displayNamesMap).forEach((key) => {
		defaultNotifications[key] = { email: true, inApp: true };
	});
	return defaultNotifications;
};

const BuyerNotificationsSettingsPage = (props) => {
	useEffect(() => {
		const { getBuyerSettings, currentUser } = props;
		getBuyerSettings(encodeURIComponent(currentUser.email));
	}, []);

	const { buyerSettingsFetching, buyerSettings, updateBuyerSettings } = props;

	const workOrderNotifications = {
		...getDefaultNotifications(BUYER_NOTIFICATION_WORK_ORDER_STATUS_DISPLAY_NAMES),
		...buyerSettings.workOrderNotifications,
	};
	const workOrderNotificationsArray = getNotificationsArray(
		workOrderNotifications,
		BUYER_NOTIFICATION_WORK_ORDER_STATUS_DISPLAY_NAMES
	);

	const rfpNotifications = {
		...getDefaultNotifications(BUYER_NOTIFICATION_RFP_STATUS_DISPLAY_NAMES),
		...buyerSettings.rfpNotifications,
	};
	const rfpNotificationsArray = getNotificationsArray(
		rfpNotifications,
		BUYER_NOTIFICATION_RFP_STATUS_DISPLAY_NAMES
	);

	const approvalNotifications = {
		...getDefaultNotifications(BUYER_NOTIFICATION_APPROVALS_DISPLAY_NAMES),
		...buyerSettings.approvalNotifications,
	};
	const approvalNotificationsArray = getNotificationsArray(
		approvalNotifications,
		BUYER_NOTIFICATION_APPROVALS_DISPLAY_NAMES
	);

	const notesNotifications = {
		...getDefaultNotifications(BUYER_NOTIFICATION_NOTES_DISPLAY_NAMES),
		...buyerSettings.notesNotifications,
	};
	const notesNotificationsArray = getNotificationsArray(
		notesNotifications,
		BUYER_NOTIFICATION_NOTES_DISPLAY_NAMES
	);

	const setEmailsForImportantOnly = () => {
		const NOTIFICATION_CATEGORIES = {
			approvalNotifications: approvalNotifications,
			notesNotifications: notesNotifications,
			workOrderNotifications: workOrderNotifications,
			rfpNotifications: rfpNotifications,
		};
		const newBuyerSettings = {
			...buyerSettings,
		};
		Object.keys(NOTIFICATION_CATEGORIES).forEach((category) => {
			const newCategory = {};
			Object.keys(NOTIFICATION_CATEGORIES[category]).forEach((key) => {
				newCategory[key] = {
					...NOTIFICATION_CATEGORIES[category][key],
					email: !!NOTIFICATION_CATEGORIES[category][key].important,
				};
			});
			newBuyerSettings[category] = newCategory;
		});

		updateBuyerSettings(newBuyerSettings);
	};
	const setMobilePushForImportantOnly = () => {
		const NOTIFICATION_CATEGORIES = {
			approvalNotifications: approvalNotifications,
			notesNotifications: notesNotifications,
			workOrderNotifications: workOrderNotifications,
			rfpNotifications: rfpNotifications,
		};
		const newBuyerSettings = {
			...buyerSettings,
		};
		Object.keys(NOTIFICATION_CATEGORIES).forEach((category) => {
			const newCategory = {};
			Object.keys(NOTIFICATION_CATEGORIES[category]).forEach((key) => {
				newCategory[key] = {
					...NOTIFICATION_CATEGORIES[category][key],
					inApp: !!NOTIFICATION_CATEGORIES[category][key].important,
				};
			});
			newBuyerSettings[category] = newCategory;
		});

		updateBuyerSettings(newBuyerSettings);
	};

	const updateWorkOrderNotificationsSettings = (newSetting) => {
		const newBuyerSettings = {
			...buyerSettings,
			workOrderNotifications: {
				...buyerSettings.workOrderNotifications,
				[newSetting.key]: {
					sms: typeof newSetting.sms === 'undefined' ? false : newSetting.sms,
					email: newSetting.email,
					inApp: newSetting.inApp,
					important: newSetting.important,
				},
			},
		};
		updateBuyerSettings(newBuyerSettings);
	};

	const updateRFPNotificationsSettings = (newSetting) => {
		const newBuyerSettings = {
			...buyerSettings,
			rfpNotifications: {
				...buyerSettings.rfpNotifications,
				[newSetting.key]: {
					sms: typeof newSetting.sms === 'undefined' ? false : newSetting.sms,
					email: newSetting.email,
					inApp: newSetting.inApp,
					important: newSetting.important,
				},
			},
		};
		updateBuyerSettings(newBuyerSettings);
	};

	const updateNotesNotificationsSettings = (newSetting) => {
		const newBuyerSettings = {
			...buyerSettings,
			notesNotifications: {
				...buyerSettings.notesNotifications,
				[newSetting.key]: {
					sms: typeof newSetting.sms === 'undefined' ? false : newSetting.sms,
					email: newSetting.email,
					inApp: newSetting.inApp,
					important: newSetting.important,
				},
			},
		};
		updateBuyerSettings(newBuyerSettings);
	};

	const updateApprovalNotificationsSettings = (newSetting) => {
		const newBuyerSettings = {
			...buyerSettings,
			approvalNotifications: {
				...buyerSettings.approvalNotifications,
				[newSetting.key]: {
					sms: typeof newSetting.sms === 'undefined' ? false : newSetting.sms,
					email: newSetting.email,
					inApp: newSetting.inApp,
					important: newSetting.important,
				},
			},
		};
		updateBuyerSettings(newBuyerSettings);
	};

	const getColumns = (displayName, handleSave) => [
		{
			title: 'Important',
			dataIndex: 'important',
			width: '100px',
			render: (important, record) => (
				<Rate
					count={1}
					value={important ? 1 : 0}
					onChange={(value) => handleSave({ ...record, important: value === 1 })}
				/>
			),
		},
		{
			title: displayName,
			dataIndex: 'displayName',
			width: '640px',
			render: (text) => <span style={{ fontSize: 16 }}>{text}</span>,
		},
		{
			title: 'Email',
			dataIndex: 'email',
			width: '80px',
			editable: true,
			defaultValue: true,
		},
		{
			title: 'Mobile Push Notification',
			dataIndex: 'inApp',
			width: '210px',
			editable: true,
			defaultValue: true,
		},
	];

	return buyerSettingsFetching ? (
		<PageLoadingPlaceholder />
	) : (
		<Content className="buyerNotificationsSettingsPage">
			<Row className="rowSpacing">
				<Col style={{ maxWidth: 960, margin: 'auto' }} span={24}>
					<Alert
						style={{ borderRadius: 5 }}
						message="Marking a notification as important will make it appear in the Important section on the dashboard and inbox "
						description={
							<>
								You can click here to only send Important notifications to your{' '}
								<Button size="small" className="mt-3" onClick={setEmailsForImportantOnly}>
									Emails
								</Button>{' '}
								or{' '}
								<Button size="small" onClick={setMobilePushForImportantOnly}>
									Push Notification
								</Button>
								.
							</>
						}
						type="info"
						closable
					/>
				</Col>
			</Row>

			<Row className="rowSpacing">
				<Col style={{ maxWidth: 960, margin: 'auto' }} span={24}>
					<Card>
						<EditableCellTable
							rowClassName={(record) => (DISABLED_TYPES.includes(record.key) ? 'disabled-row' : '')}
							showHeader={true}
							handleSave={updateNotesNotificationsSettings}
							columns={getColumns('Notes Notifications', updateNotesNotificationsSettings)}
							data={notesNotificationsArray}
						/>
					</Card>
				</Col>
			</Row>
			<Row className="rowSpacing">
				<Col style={{ maxWidth: 960, margin: 'auto' }} span={24}>
					<Card>
						<EditableCellTable
							rowClassName={(record) => (DISABLED_TYPES.includes(record.key) ? 'disabled-row' : '')}
							showHeader={true}
							handleSave={updateWorkOrderNotificationsSettings}
							columns={getColumns('Work Order Notifications', updateWorkOrderNotificationsSettings)}
							data={workOrderNotificationsArray}
						/>
					</Card>
				</Col>
			</Row>
			<Row className="rowSpacing">
				<Col style={{ maxWidth: 960, margin: 'auto' }} span={24}>
					<Card>
						<EditableCellTable
							rowClassName={(record) => (DISABLED_TYPES.includes(record.key) ? 'disabled-row' : '')}
							showHeader={true}
							handleSave={updateRFPNotificationsSettings}
							columns={getColumns(
								'Request for Proposal Notifications',
								updateRFPNotificationsSettings
							)}
							data={rfpNotificationsArray}
						/>
					</Card>
				</Col>
			</Row>
			{/* <Row className="rowSpacing">
				<Col style={{ maxWidth: 960, margin: 'auto' }} span={24}>
					<Card>
						<EditableCellTable
							rowClassName={(record) => (DISABLED_TYPES.includes(record.key) ? 'disabled-row' : '')}
							showHeader={true}
							handleSave={updateApprovalNotificationsSettings}
							columns={getColumns('Approval Notifications', updateApprovalNotificationsSettings)}
							data={approvalNotificationsArray}
						/>
					</Card>
				</Col>
			</Row> */}
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	currentUser: state.session.currentUser,

	buyerSettings: state.buyer_settings.detail,
	buyerSettingsFetching: state.buyer_settings.fetching,
});

const mapDispatchToProps = (dispatch) => ({
	getBuyerSettings: (email) => dispatch(buyerSettingsRestCrudThunksForBuyer.readOne(email)),
	updateBuyerSettings: (settings) => dispatch(buyerSettingsRestCrudThunksForBuyer.update(settings)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(BuyerNotificationsSettingsPage)
);

import * as React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import InvoicesTable from '../InvoicesTable';
import queryString from 'querystring';

class ProjectsInvoicesPage extends React.Component<any, any> {
	initialPagination: any = { current: 1, pageSize: 5 };
	initialFilters: any;
	initialSorting: any;

	constructor(props) {
		super(props);
		const { location, history } = props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		const { current, pageSize, sort_by, order, ...workOrderFilters } = queryParams;
		this.initialPagination = { current: current ? current : 1, pageSize: pageSize ? pageSize : 5 };
		this.initialSorting = {
			sort_by: sort_by ? sort_by : 'createdAt',
			order: order ? order : 'desc',
		};
		this.state = {
			displayMode: 'list',
		};
		this.initialFilters =
			workOrderFilters && Object.keys(workOrderFilters).length > 0
				? {
						...workOrderFilters,
				  }
				: {};
	}
	render() {
		const { match } = this.props;
		return (
			<div style={{ marginTop: '-0.5em', padding: '0em 1em' }}>
				<InvoicesTable
					handleQueryParams={true}
					preAppliedFilters={{ buyerProjectId: match.params.id }}
					initialFilters={this.initialFilters}
					initialPagination={this.initialPagination}
					initialSorting={this.initialSorting}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	match: ownProps.match,
	history: ownProps.history,
	currentUser: state.session.currentUser,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectsInvoicesPage));

import React, { FC, useCallback, useMemo, useState } from 'react';
import { isTextElementEmpty, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { Button, Card, Divider, Drawer } from 'antd';
import { AddressDisplay } from '../address_display/AddressDisplay';
import Mapbox from '../mapbox/Mapbox';
import SupplierLocationPageDisplay from '../supplier_location_page_display/SupplierLocationPageDisplay';
import { CloseOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { getProtectedImageUriForSupplier } from '../../utils/FileAccessUtils';
import { connect } from 'react-redux';

const SupplierLocationCardDisplay: FC<any> = ({
	location,
	mapHeight = 240,
	mapWidth = '100%',
	currentUser,
	onViewLocationPage,
}): React.ReactElement => {
	const [locationDrawerVisible, setLocationDrawerVisible] = useState(false);

	const handleLocationDrawerOpen = useCallback(() => setLocationDrawerVisible(true), []);

	const handleLocationDrawerClose = useCallback(() => setLocationDrawerVisible(false), []);

	const photoUris = useMemo(
		() =>
			nullSafeGetOrElse('images', location, []).map((locImg) => {
				const [imgFileId, imgFileName] = locImg.split('/');
				return getProtectedImageUriForSupplier(imgFileId, imgFileName, 1200, 'auto');
			}),
		[location]
	);

	return (
		<div>
			<Drawer
				width={1000}
				placement="right"
				closable={false}
				onClose={handleLocationDrawerClose}
				visible={locationDrawerVisible}
				bodyStyle={{ paddingBottom: 0 }}
			>
				<div
					//Header
					className={'absolute top-0 right-0 z-40 flex flex-row'}
				>
					<Button onClick={handleLocationDrawerClose} type={'text'} style={{ padding: '8px' }}>
						<CloseOutlined translate="" style={{ fontSize: '24px', color: 'white' }} />
					</Button>
				</div>
				{/* <SupplierLocationsDetailDetailsPage record={location} /> */}
				<Content className="supplierLocationPageDisplay">
					<div
						className={
							(photoUris.length && photoUris.length > 0) || !location.isActive
								? 'imageLayout'
								: 'noImageLayout'
						}
					>
						{!location.isActive ? (
							<div className="inactiveBanner">
								<div className="inactiveLabel">Inactive</div>
							</div>
						) : null}
						{photoUris.map((photoUri, idx) => (
							<div
								key={idx}
								className={`imageTile imageTile${idx}`}
								style={{ backgroundImage: `url(${photoUri})` }}
							/>
						))}
						<div
							className={`layout ${
								(photoUris.length && photoUris.length > 0) || !location.isActive
									? 'imageLayout__gradientOverlay'
									: 'noImageLayout__gradientOverlay'
							}`}
						>
							<div className="textContainer">
								<div className="leftItems">
									<div className="locationPageDisplay__overtitle">
										{nullSafeGet('locationType.name', location)} {nullSafeGet('storeId', location)}
									</div>
									<h4 className="locationPageDisplay__title">{location.name}</h4>
								</div>
								<div className="rightItems">
									{nullSafeGet('facility.supplierCompany.privateBuyerCompanyId', currentUser) ? (
										<Button style={{ color: 'white' }} type="ghost" onClick={onViewLocationPage}>
											View Location Page
										</Button>
									) : null}
								</div>
							</div>
						</div>
					</div>
					<SupplierLocationPageDisplay record={location} locationsFetching={false} />
				</Content>
			</Drawer>
			<Card
				className="materialCard"
				bodyStyle={{ padding: 0 }}
				hoverable={true}
				onClick={handleLocationDrawerOpen}
			>
				<div
					style={{
						height: mapHeight,
						width: mapWidth,
						position: 'absolute',
						top: 0,
						left: 0,
						right: 0,
					}}
				>
					<Mapbox
						longitude={nullSafeGet('buyerFacility.longitude', location)}
						latitude={nullSafeGet('buyerFacility.latitude', location)}
						baseLayerStyle="light"
						zoomLevel={7}
					/>
				</div>
				<div style={{ height: 240, position: 'relative' }} />
				<div className="materialCard__body">
					<div className="materialCard__primaryTitle">
						{nullSafeGet('brand.logoURL', location) ? (
							<img
								src={nullSafeGet('brand.logoURL', location)}
								alt=""
								className="h-auto w-20 object-contain"
							/>
						) : null}
						<div className="materialCard__title">{nullSafeGet('name', location)}</div>
						<div className="materialCard__subtitle">
							{nullSafeGet('locationType.name', location)} #{nullSafeGet('storeId', location)}
						</div>
					</div>
					<AddressDisplay
						hidePostcode={true}
						hideCountry={true}
						className="materialCard__supportingText"
						address={nullSafeGet('buyerFacility.primaryAddress', location)}
					/>
				</div>
				{!isTextElementEmpty(location.notes) ? (
					<div style={{ padding: 24, paddingTop: 0 }}>
						<Divider style={{ marginTop: 0 }} />
						<div className="richTextWrapper" dangerouslySetInnerHTML={{ __html: location.notes }} />
					</div>
				) : null}
			</Card>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => ({
	currentUser: state.session.currentUser,
});

export default connect(mapStateToProps)(SupplierLocationCardDisplay);

import React, { FC } from 'react';
import { connect } from 'react-redux';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import TroubleshootingForm from './TroubleshootingForm';

const NewTroubleshootingPage: FC<any> = ({ history, userType }): React.ReactElement => {
	return (
		<>
			<LogOnMountWithStandardEventProperties eventType="visited new troubleshooting page" />,
			<EditPage
				title="New Troubleshooting"
				formComponentProps={{
					formData: {},
					onSuccess: (rec) => history.push(`/${userType}/admin/troubleshooting/detail/${rec.id}`),
				}}
				FormComponent={TroubleshootingForm}
			/>
		</>
	);
};

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(NewTroubleshootingPage);

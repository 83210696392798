import { CAPEX_ASSOCIATION_CRUD_ACTION_CREATORS } from '../actions/capex_association_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { logoutSuccess } from '../actions/session_actions';
import FetchUtils, { parseJSON } from './fetch_utils';

const fetchUtils = new FetchUtils();

export const capexAssociationRestCrudThunksForBuyer = new RestCrudThunks(
	'capex_category',
	'capex_category',
	'capex_category',
	'capex_category',
	CAPEX_ASSOCIATION_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'associations/'
);

export const capexAssociationRestCrudThunksForSupplier = new RestCrudThunks(
	'capex_category',
	'capex_category',
	'capex_category',
	'capex_category',
	CAPEX_ASSOCIATION_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'associations/'
);

const deleteCapexCategoryAssociation = (roleType) => (id) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/associations/capex_category/${id}`;

	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.delete(
					baseUrl + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const deleteCapexCategoryAssociationForBuyer = deleteCapexCategoryAssociation(
	ROLE_TYPES.BUYER
);
export const deleteCapexCategoryAssociationForSupplier = deleteCapexCategoryAssociation(
	ROLE_TYPES.SUPPLIER
);

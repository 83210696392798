import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Select, Alert, DatePicker } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { connect } from 'react-redux';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import moment from 'moment';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { fieldTechsRestCrudThunksForSupplier } from '../../thunks/field_techs_thunks';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import { dispatchersRestCrudThunksForSupplier } from '../../thunks/dispatchers_thunks';

const FormItem = Form.Item;
export const DATE_FORMAT = 'MMMM D, YYYY h:mm a';

interface ScheduleServiceCallFormProps extends FormComponentProps {
	onSubmit: any;
	onCancel: any;
	visible: boolean;
	createErrors: any;
	updateErrors: any;

	isReschedule?: boolean;
	fetchMultipleFieldTechs: any;
	fetchFieldTechs: any;
	fieldTechs: any;
	fetchMultipleStockLocations: any;
	fetchStockLocations: any;
	stockLocations: any;
	creating: boolean;
	updating: boolean;
	formData: any;
	fetchSupplierDispatcher: any;
	currentUser: any;
}

const disabledDate = (current) => {
	// Can not select days before today and today
	return current < moment().startOf('day');
};

class ScheduleServiceCallModalForm extends React.Component<ScheduleServiceCallFormProps, any> {
	componentDidMount(): void {
		const { form, fetchStockLocations, formData } = this.props;

		const locationId = nullSafeGet('workOrder.locationId', formData);
		locationId &&
			fetchStockLocations({
				locationId,
			}).then((stockLocations) => {
				stockLocations &&
					stockLocations.length > 0 &&
					form.setFieldsValue &&
					form.setFieldsValue({ stockLocationIds: stockLocations.map((_) => _.id) });
			});
	}

	render() {
		const {
			visible,
			isReschedule,
			fieldTechs,
			fetchMultipleFieldTechs,
			fetchFieldTechs,
			fetchMultipleStockLocations,
			fetchStockLocations,
			stockLocations,
			onCancel,
			onSubmit,
			form,
			formData,
			createErrors,
			updateErrors,
			currentUser,
			creating,
			updating,
		} = this.props;
		const { getFieldDecorator, getFieldValue } = form;
		const okText = isReschedule ? 'Reschedule' : 'Schedule';
		const titleText = isReschedule ? 'Reschedule Service' : 'Schedule Service';
		getFieldDecorator(`id`, { initialValue: formData.id });
		getFieldDecorator(`workOrderId`, { initialValue: nullSafeGet('workOrder.id', formData) });
		getFieldDecorator(`supplierFacilityId`, {
			initialValue:
				formData.supplierFacilityId || nullSafeGet('workOrder.supplierFacilityId', formData),
		});

		const isPrivateSupplier = nullSafeGet('facility.status', currentUser) === 'private';

		const differentAssignedSupplier = isPrivateSupplier
			? nullSafeGet('facility.id', currentUser) !==
			  nullSafeGet('workOrder.supplierFacilityId', formData)
			: false;

		const leadTechnicianEmail = getFieldValue('leadTechnicianEmail');
		const additionalTechnicianEmails = getFieldValue('additionalTechnicianEmails');

		const scheduleTimeRequired =
			!leadTechnicianEmail && !nullSafeGet('0', additionalTechnicianEmails);

		return (
			<Modal
				visible={visible}
				width={600}
				title={titleText}
				okText={okText}
				onCancel={onCancel}
				onOk={onSubmit}
				closable={false}
				confirmLoading={creating || updating}
			>
				<Form layout="vertical" className="supplierForm">
					{createErrors.length > 0 ? (
						<FormItem>
							<Alert message={createErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					{updateErrors.length > 0 ? (
						<FormItem>
							<Alert message={updateErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					{differentAssignedSupplier ? (
						getFieldDecorator('leadTechnicianEmail', {
							initialValue: formData.leadTechnicianEmail,
						})
					) : (
						<Form.Item label="Lead Technician">
							{getFieldDecorator('leadTechnicianEmail', {
								initialValue:
									formData.leadTechnicianEmail ||
									nullSafeGet('workOrder.assignedPrimaryTechContact.email', formData),
							})(
								<OWAsyncSelect
									stateSlice={fieldTechs}
									valueAccessor={(el) => el.email}
									allowClear={true}
									targetCollectionName="scheduleServiceCallDropdown"
									fetchData={(
										searchText,
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									) => {
										fetchFieldTechs(
											{ search: searchText && searchText.trim() },
											targetCollectionName,
											pagination,
											sorting,
											filters,
											addToTargetCollection
										);
									}}
									fetchMultiple={(ids, targetCollectionName) => {
										fetchMultipleFieldTechs(ids, targetCollectionName);
									}}
									renderRecord={(ft) => (
										<Select.Option key={ft.email} value={ft.email}>
											{nullSafeGet('supplierContact.nameGiven', ft)}{' '}
											{nullSafeGet('supplierContact.nameFamily', ft)} (
											{nullSafeGet('supplierContact.email', ft)})
										</Select.Option>
									)}
									sortBy={{
										sort_by: 'name',
										order: 'ascend',
									}}
								/>
							)}
						</Form.Item>
					)}
					{differentAssignedSupplier ? (
						getFieldDecorator('additionalTechnicianEmails', {
							initialValue: formData.additionalTechnicianEmails,
						})
					) : (
						<Form.Item label="Additional Technicians">
							{getFieldDecorator('additionalTechnicianEmails', {
								initialValue: formData.additionalTechnicianEmails,
							})(
								<OWAsyncSelect
									mode="multiple"
									stateSlice={fieldTechs}
									valueAccessor={(el) => el.email}
									targetCollectionName="scheduleServiceCallAdditionalTechsDropdown"
									fetchData={(
										searchText,
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									) => {
										fetchFieldTechs(
											{ search: searchText && searchText.trim() },
											targetCollectionName,
											pagination,
											sorting,
											filters,
											addToTargetCollection
										);
									}}
									fetchMultiple={(ids, targetCollectionName) => {
										fetchMultipleFieldTechs(ids, targetCollectionName);
									}}
									renderRecord={(ft) => (
										<Select.Option key={ft.email} value={ft.email}>
											{nullSafeGet('supplierContact.nameGiven', ft)}{' '}
											{nullSafeGet('supplierContact.nameFamily', ft)} (
											{nullSafeGet('supplierContact.email', ft)})
										</Select.Option>
									)}
									sortBy={{
										sort_by: 'name',
										order: 'ascend',
									}}
								/>
							)}
						</Form.Item>
					)}
					<FormItem label="Scheduled Arrival Time">
						{getFieldDecorator(`serviceScheduledAt`, {
							rules: [
								{
									type: 'object',
									required: scheduleTimeRequired,
									message: 'Please select an estimated arrival time.',
								},
							],
							initialValue: formData.serviceScheduledAt
								? moment(formData.serviceScheduledAt)
								: undefined,
						})(
							<DatePicker
								disabledDate={disabledDate}
								style={{ width: '100%' }}
								showTime={true}
								format={DATE_FORMAT}
							/>
						)}
					</FormItem>
					{differentAssignedSupplier ? (
						getFieldDecorator('stockLocationIds', {
							initialValue: formData.stockLocationIds,
						})
					) : (
						<Form.Item label="Stock Locations">
							{getFieldDecorator('stockLocationIds', {
								initialValue: formData.stockLocationIds,
							})(
								<OWAsyncSelect
									mode="multiple"
									stateSlice={stockLocations}
									valueAccessor={(el) => el.id}
									targetCollectionName="scheduleServiceCallStockLocationDropdown"
									fetchData={(
										searchText,
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									) => {
										fetchStockLocations(
											{ name: searchText },
											targetCollectionName,
											pagination,
											sorting,
											filters,
											addToTargetCollection
										);
									}}
									fetchMultiple={(ids, targetCollectionName) => {
										fetchMultipleStockLocations(ids, targetCollectionName);
									}}
									renderRecord={(sl) => (
										<Select.Option key={sl.id} value={sl.id}>
											{nullSafeGet('name', sl)}
										</Select.Option>
									)}
									sortBy={{
										sort_by: 'name',
										order: 'ascend',
									}}
								/>
							)}
						</Form.Item>
					)}
				</Form>
			</Modal>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	isReschedule: ownProps.isReschedule,
	createErrors: state.buyer_contacts.createErrors,
	updateErrors: state.buyer_contacts.updateErrors,
	creating: state.work_orders.creating,
	updating: state.work_orders.updating,
	currentUser: state.session.currentUser,
	fieldTechs: state.field_techs,
	stockLocations: state.stock_locations,
});

const mapDispatchToProps = (dispatch) => ({
	fetchMultipleFieldTechs: (ids, targetCollectionName) =>
		dispatch(fieldTechsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchFieldTechs: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			fieldTechsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleStockLocations: (ids, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchStockLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			stockLocationsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchSupplierDispatcher: (params) => dispatch(dispatchersRestCrudThunksForSupplier.read(params)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create<ScheduleServiceCallFormProps>()(ScheduleServiceCallModalForm));

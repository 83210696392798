import React, { FC } from 'react';

import '@ant-design/compatible/assets/index.css';
import { Modal, Form, Input, Row } from 'antd';
import { connect } from 'react-redux';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { withRouter } from 'react-router';

const FormItem = Form.Item;

const SelfManagedSupplierInvitationForm: FC<any> = ({
	supplierFacility = {},
	visible,
	onCancel,
	onSubmit,
	loading,
}): React.ReactElement => {
	const [form] = Form.useForm();

	return (
		<Modal
			visible={visible}
			width={600}
			okText="Invite Supplier"
			onCancel={onCancel}
			onOk={form.submit}
			confirmLoading={loading}
			closable={false}
		>
			<Form
				form={form}
				layout="vertical"
				className="supplierForm"
				initialValues={{
					...(supplierFacility || {}),
					allProblemTypes: false,
					problemTypeIds: nullSafeGetOrElse('problemTypeIds', supplierFacility, []),
					allLocations: false,
					locationIds: nullSafeGetOrElse('locationIds', supplierFacility, []),
				}}
				onFinish={onSubmit}
			>
				<FormItem
					label="Supplier Contact Email Address"
					name="primaryContactEmail"
					initialValue={nullSafeGetOrElse(
						'supplierFacility.primaryContactEmail',
						supplierFacility,
						''
					)}
					rules={[
						{ required: true, message: "Please input your primary contact's email!" },
						{ type: 'email', message: 'This is not a valid email!' },
					]}
				>
					<Input placeholder="John.Doe@doeplumbing.com" />
				</FormItem>
				<Row style={{ flexWrap: 'nowrap', gap: '10px' }}>
					<FormItem
						label="Contact First Name"
						name="primaryContactFirstName"
						initialValue=""
						rules={[{ required: true, message: "Please input your primary contact's first name!" }]}
					>
						<Input placeholder="John" />
					</FormItem>
					<FormItem
						label="Contact Last Name"
						name="primaryContactLastName"
						initialValue=""
						rules={[{ required: true, message: "Please input your primary contact's first name!" }]}
					>
						<Input placeholder="Doe" />
					</FormItem>
				</Row>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps)(SelfManagedSupplierInvitationForm)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import * as React from 'react';
import { useMemo, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
	approvalRulesRestCrudThunksForBuyer,
	approvalsRestCrudThunksForBuyer,
	deleteApprovalAssociation,
	editBuyerCompanyApprovalAssociations,
	fetchApprovalRules,
	updateApprovalAssociationRanks,
	updateBuyerCompanyApprovalAssociations,
} from '../../thunks/company_approval_thunks';
import { Button, Card, Col, List, message, Popover, Row } from 'antd';
import { getObjectValues, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { TextButton } from '../text_button/TextButton';
import ApprovalHierarchyRowDisplay from '../approval_hierarchy_row_display/ApprovalHierarchyRowDisplay';
import ReactDragListView from 'react-drag-listview';
import ApprovalRuleModal from '../approval_rule_modal/ApprovalRuleModal';
import { PlusOutlined } from '@ant-design/icons';
import { EmptyState } from '../empty_state/EmptyState';
import {
	mappingApprovalRulesURLForTargetCollection,
	mappingApprovalRulesURLForTypes,
	mappingURLParamsToAddApprovalButton,
} from '../../utils/DataMappingUtils';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { problemTypesHeirarchicalForBuyer } from '../../thunks/problem_types_thunks';

const style = require('../../styles/rowDisplay.less');

function ApprovalRulesIndexPage({
	fetchBuyerCompanyApprovalSettings,
	createBuyerCompanyApprovalSettings,
	editBuyerCompanyApprovalSettings,
	history,
	approvalRules,
	approvalHierarchies,
	deleteApprovalRule,
	updateRank,
	match,
	fetchProblemTypes,
	companySettings,
}) {
	const type = mappingApprovalRulesURLForTypes(match);
	const targetCollection = mappingApprovalRulesURLForTargetCollection(match);
	const [loading, setLoading] = useState(true);
	const [sequenceCopy, setSequenceCopy] = useState([]);
	const [approvalRuleModal, setApprovalRuleModal] = useState(false);
	const [selectedRule, setSelectedRule] = useState({});
	const [isDefault, setIsDefault] = useState(false);
	const records = getRecordsForTargetCollection(
		approvalRules,
		`approvalRulesIndex${targetCollection}`
	);
	const companyApprovalConfig = nullSafeGet('config.approvalConfig', companySettings);
	const autoApproveServiceRequests = companyApprovalConfig.autoApproveServiceRequests;

	useMemo(
		() =>
			fetchBuyerCompanyApprovalSettings(type, `approvalRulesIndex${targetCollection}`)
				.then((results) => {
					setLoading(false);
				})
				.catch(() => setLoading(false)),
		[type]
	);
	useMemo(() => {
		fetchProblemTypes({});
	}, []);

	const hideModal = () => {
		setApprovalRuleModal(false);
		setSelectedRule({});
	};
	const editRule = (record, isDefault = false) => {
		setIsDefault(isDefault);
		setSelectedRule(record || {});
		setApprovalRuleModal(true);
	};
	const rules = useMemo(
		() => (loading ? [] : getObjectValues(records).filter((r) => !r.isDefault)),
		[approvalRules, loading]
	);

	const defaultRule = useMemo(
		() => (loading ? [] : getObjectValues(records).find((r) => r.isDefault)),
		[approvalRules, loading]
	);

	const rows = useMemo(
		() =>
			rules
				.sort((a, b) => {
					return a.rank - b.rank;
				})
				.map((record) => (
					<ApprovalHierarchyRowDisplay
						record={record}
						type={type}
						autoApproveServiceRequests={autoApproveServiceRequests}
						edit={() => editRule(record)}
						delete={() => deleteApprovalRule(record)}
					/>
				)),
		[rules]
	);
	const defaultApprovalHierarchy = (
		<ApprovalHierarchyRowDisplay
			isDefault={true}
			type={type}
			autoApproveServiceRequests={autoApproveServiceRequests}
			record={defaultRule}
			rules={rules}
			edit={() => editRule(defaultRule, true)}
			delete={() => deleteApprovalRule(defaultRule)}
		/>
	);

	useMemo(() => {
		setSequenceCopy(
			JSON.parse(
				JSON.stringify(
					rules.sort((a, b) => {
						return a.rank - b.rank;
					})
				)
			)
		);
	}, [rules]);
	const hasApprovalHierarchies = nullSafeGetOrElse('recordIds.length', approvalHierarchies, 1) > 0;
	return loading ? (
		<PageLoadingPlaceholder />
	) : (
		<Col className={'gap-2 px-2'}>
			<ApprovalRuleModal
				isDefault={isDefault}
				visible={approvalRuleModal}
				hideModal={hideModal}
				record={selectedRule}
				existingRules={rules}
				approvalHierarchyType={type}
				editSuccess={(entity) => editBuyerCompanyApprovalSettings(entity)}
				createSuccess={(entity) =>
					createBuyerCompanyApprovalSettings(entity, `approvalRulesIndex${targetCollection}`)
				}
				type={type}
			/>
			<Row className={'mt-1'}>
				{defaultRule === undefined && type !== 'workOrder' ? (
					<Card className={'flex w-full flex-col'}>
						{hasApprovalHierarchies ? (
							<EmptyState
								graphic={
									<img
										style={{ marginBottom: 8 }}
										src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
										alt="It's a little lonely in here."
									/>
								}
								headline={"It's a little lonely in here."}
								body={
									<div style={{ textAlign: 'center' }}>
										<div style={{ maxWidth: 440, marginBottom: 16 }}>
											Looks like your team has not created approval rules yet.
										</div>
										<Button type={'primary'} onClick={() => editRule({}, true)}>
											Setup Default Approvals
										</Button>
									</div>
								}
							/>
						) : (
							<EmptyState
								graphic={
									<img
										style={{ marginBottom: 8 }}
										src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
										alt="It's a little lonely in here."
									/>
								}
								headline={'Hang on a sec.'}
								body={
									<div style={{ textAlign: 'center' }}>
										<div style={{ maxWidth: 440, marginBottom: 16 }}>
											Looks like your team has not created your first approval hierarchy yet.
										</div>
										<Button
											type={'primary'}
											onClick={() => history.push('/buyer/approvalHierarchies/overview/templates')}
										>
											Take me to the approval hierarchy page
										</Button>
									</div>
								}
							/>
						)}
					</Card>
				) : (
					<Card className={'flex w-full flex-col'}>
						<List loading={loading} className="draggable">
							<ReactDragListView
								nodeSelector="div.draggable-item"
								lineClassName="dragLine"
								onDragEnd={(fromIndex, toIndex) => {
									const originalOrder = sequenceCopy;
									const reorder = (list, startIndex, endIndex) => {
										const result = Array.from(list);
										const [removed] = result.splice(startIndex, 1);
										result.splice(endIndex, 0, removed);
										return result;
									};
									const newSequence = reorder(sequenceCopy, fromIndex, toIndex);
									setSequenceCopy(
										newSequence.map((item: any, index) => ({
											...item,
											rank: index + 1,
										}))
									);
									const changeOrder = () => {
										const seq = newSequence.map((item: any, index) => {
											return { id: item.id, rank: index + 1 };
										});
										updateRank(seq);
									};

									const changeOrderTimeout = setTimeout(changeOrder, 3000);
									const cancelCall = () => clearTimeout(changeOrderTimeout);

									const hideUndo = message.success(
										<span>
											Updating the rank of the approval rules
											<TextButton
												className="ml-2 cursor-pointer text-buyer underline"
												onClick={() => {
													cancelCall();
													hideUndo();
													setSequenceCopy(originalOrder);
												}}
											>
												Undo
											</TextButton>
										</span>
									);
								}}
							>
								{rows}
							</ReactDragListView>
						</List>
						{defaultApprovalHierarchy}
						<Row>
							<Button
								icon={<PlusOutlined />}
								className={'mt-7'}
								type={'text'}
								onClick={() => editRule({})}
							>
								Add Rule
							</Button>
						</Row>
					</Card>
				)}
			</Row>
		</Col>
	);
}
const mapStateToProps = (state, ownProps) => ({
	approvalHierarchies: state.approval_hierarchies,
	approvalRules: state.approval_rules,
	history: ownProps.history,
	match: ownProps.match,
	companySettings: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	updateRank: (entities) => dispatch(updateApprovalAssociationRanks(entities)()),
	deleteApprovalRule: (record) => dispatch(deleteApprovalAssociation(record, record.id)()),
	fetchBuyerCompanyApprovalSettings: (type, targetCollection) =>
		dispatch(
			approvalRulesRestCrudThunksForBuyer.read({ approvalHierarchyType: type }, targetCollection)
		),
	editBuyerCompanyApprovalSettings: (entity) =>
		dispatch(editBuyerCompanyApprovalAssociations(entity)()),
	createBuyerCompanyApprovalSettings: (entity, targetCollectionName) =>
		dispatch(updateBuyerCompanyApprovalAssociations(entity, targetCollectionName)()),
	fetchProblemTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			problemTypesHeirarchicalForBuyer(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApprovalRulesIndexPage));

import * as React from 'react';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Checkbox, TimePicker, Alert, Button } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { supplierFacilitiesRestCrudThunksForSupplier } from '../../thunks/supplier_facilities_thunks';
import { DAYS_OF_THE_WEEK } from '../../constants/times';
import { nullSafeGet } from '../../utils/DataAccessUtils';

const FormItem = Form.Item;
const style = require('./EditSupplierOperatingHoursForm.less');

interface EditSupplierOperatingHoursProps {
	loading: boolean;
	operatingHours: any;
	updateSupplier: any;
	successCallback: any;
	cancelCallback: any;
	supplierFacility: any;

	errors: string[];
}

interface EditSupplierOperatingHoursFormProps extends FormComponentProps {
	loading: boolean;
	operatingHours: any;
	updateSupplier: any;
	successCallback: any;
	cancelCallback: any;
	supplierFacility: any;

	errors: string[];
}

class EditSupplierOperatingHoursForm extends React.Component<
	EditSupplierOperatingHoursFormProps,
	any
> {
	handleSubmit = (e) => {
		const { updateSupplier, successCallback, supplierFacility, form } = this.props;
		e.preventDefault();
		form.validateFields((err, values) => {
			const operatingHours = DAYS_OF_THE_WEEK.reduce((acc, day) => {
				if (values[`${day}-from`] && values[`${day}-to`]) {
					acc[day] = {
						from: values[`${day}-from`].format('h:mm a'),
						to: values[`${day}-to`].format('h:mm a'),
					};
				}
				return acc;
			}, {});
			if (!err) {
				const updatedSupplier = {
					...supplierFacility,
					operatingHours,
				};
				updateSupplier(updatedSupplier).then((resp) => successCallback(resp));
			}
		});
	};

	render() {
		const { getFieldDecorator, getFieldValue } = this.props.form;
		const { loading, errors, cancelCallback, operatingHours } = this.props;
		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
				sm: { span: 4 },
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 20 },
			},
		};
		return (
			<Form onSubmit={this.handleSubmit} className="editSupplierOperatingHoursForm">
				{errors && errors.length > 0
					? errors.map((msg, idx) => (
							<FormItem key={idx}>
								<Alert message={msg} type="error" />
							</FormItem>
					  ))
					: null}
				<table style={{ marginBottom: 16 }}>
					{DAYS_OF_THE_WEEK.map((day) => (
						<tr key={day} style={{ lineHeight: '48px' }}>
							<td>
								<span style={{ fontSize: 16 }}>{day}</span>
							</td>
							<td style={{ paddingRight: 4, paddingLeft: 16 }}>
								{getFieldDecorator(`${day}-isOpen`, {
									valuePropName: 'checked',
									initialValue: nullSafeGet(`${day}.from`, operatingHours) !== undefined,
								})(<Checkbox />)}
							</td>
							{getFieldValue(`${day}-isOpen`) ? (
								[
									<td key={1}>
										{getFieldDecorator(`${day}-from`, {
											initialValue: nullSafeGet(`${day}.from`, operatingHours)
												? moment(nullSafeGet(`${day}.from`, operatingHours), 'h:mm a')
												: undefined,
										})(
											<TimePicker
												style={{ width: 100 }}
												use12Hours={true}
												format="h:mm a"
												minuteStep={15}
											/>
										)}
									</td>,
									<td key={2} style={{ paddingRight: 8, paddingLeft: 8 }}>
										to
									</td>,
									<td key={3}>
										{getFieldDecorator(`${day}-to`, {
											initialValue: nullSafeGet(`${day}.to`, operatingHours)
												? moment(nullSafeGet(`${day}.to`, operatingHours), 'h:mm a')
												: undefined,
										})(
											<TimePicker
												style={{ width: 100 }}
												use12Hours={true}
												format="h:mm a"
												minuteStep={15}
											/>
										)}
									</td>,
								]
							) : (
								<td>Closed</td>
							)}
						</tr>
					))}
				</table>
				<FormItem>
					<Button type="primary" htmlType="submit" loading={loading}>
						Update operating hours
					</Button>
					<Button style={{ marginLeft: 16 }} onClick={cancelCallback}>
						Cancel
					</Button>
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	loading: state.supplier_facilities.loading,
	errors: state.supplier_facilities.updateErrors,
	operatingHours: ownProps.operatingHours,
	successCallback: ownProps.successCallback,
	cancelCallback: ownProps.cancelCallback,
	supplierFacility: ownProps.supplierFacility,
});

const mapDispatchToProps = (dispatch) => ({
	updateSupplier: (supplierFacility) =>
		dispatch(supplierFacilitiesRestCrudThunksForSupplier.update(supplierFacility)),
});

export default withRouter<any, any>(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<EditSupplierOperatingHoursFormProps>()(EditSupplierOperatingHoursForm))
);

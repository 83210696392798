import * as React from 'react';
import { Button } from 'antd';
import { withRouter } from 'react-router';
const style = require('./PermissionDeniedPage.less');

class PermissionDeniedPage extends React.Component<any, any> {
	render() {
		const { history } = this.props;
		const goBack = () => history.goBack();
		return (
			<div className="permissionDeniedPage">
				<img src="https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg" />
				<div className="permissionDeniedPage__textWrapper">
					<h3>Sorry! You don't have permission to view this content.</h3>
					<p>
						Click the button below to get back where you came from. If you need access to this
						resource, please contact an admin and request they grant you permission.
					</p>
					<Button type="primary" size="large" onClick={goBack}>
						Go back
					</Button>
				</div>
			</div>
		);
	}
}

export default withRouter(PermissionDeniedPage);

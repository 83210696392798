import { Button, Modal, List } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	savedFilterViewsRestCrudThunksForBuyer,
	savedFilterViewsRestCrudThunksForSupplier,
} from '../../thunks/saved_filter_views_thunks';
import { parseFilterValueToAdvancedFilters } from '../../utils/DataAccessUtils';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { getDefaultWorkOrderTab } from '../../utils/HistoryUtils';
import { BUYER_ADMIN_ONLY, SUPPLIER_ADMIN_ONLY } from '../roles/roles';
import SavedFilterViewEmptyState from './SavedFilterViewEmptyState';
import SavedFilterViewsRowDisplay from './SavedFilterViewsRowDisplay';

const DEFAULT_PAGE_LIMIT = 5;

const routeMap = {
	work_orders: 'workOrders',
	invoices: 'invoices',
	quotes: 'quotes',
};

const SavedFilterViews = ({
	fetchSavedFilterViewsForBuyer,
	fetchSavedFilterViewsForSupplier,
	entityType,
	roleType,
	onApplyFilter,
	onCancel,
	currentUser,
	deleteFilterViewForBuyer,
	deleteFilterViewForSupplier,
	history,
}): React.ReactElement => {
	const [views, setViews] = useState([]);
	const [loading, setLoading] = useState(true);

	const isAdmin = useMemo(() => {
		const roleList = roleType === ROLE_TYPES.SUPPLIER ? SUPPLIER_ADMIN_ONLY : BUYER_ADMIN_ONLY;
		return currentUser.roles.some((role) => roleList.has(role));
	}, [roleType, currentUser]);

	const fetchSavedFilterViews = useMemo(
		() =>
			roleType === ROLE_TYPES.SUPPLIER
				? fetchSavedFilterViewsForSupplier
				: fetchSavedFilterViewsForBuyer,
		[roleType, fetchSavedFilterViewsForBuyer, fetchSavedFilterViewsForSupplier]
	);

	const deleteSavedFilterView = useMemo(
		() =>
			roleType === ROLE_TYPES.SUPPLIER ? deleteFilterViewForSupplier : deleteFilterViewForBuyer,
		[roleType, deleteFilterViewForSupplier, deleteFilterViewForBuyer]
	);

	const refreshList = useCallback(() => {
		setLoading(true);
		const _ = undefined;
		fetchSavedFilterViews(
			_,
			_,
			{
				current: 1,
				pageSize: DEFAULT_PAGE_LIMIT,
			},
			{
				sort_by: 'isPublic',
				order: 'ascend',
			},
			{
				entityType,
			}
		).then((result) => {
			setLoading(false);
			setViews(result);
		});
	}, [entityType, fetchSavedFilterViews]);

	useEffect(() => {
		refreshList();
	}, [entityType, refreshList, fetchSavedFilterViews]);

	const onApplyView = useCallback(
		(view) => {
			switch (entityType) {
				case 'quotes':
					onApplyFilter && onApplyFilter(parseFilterValueToAdvancedFilters(view.filterValue));
					break;
				case 'invoices':
					onApplyFilter && onApplyFilter(parseFilterValueToAdvancedFilters(view.filterValue));
					break;
				case 'work_orders':
				default:
					const defaultTab = getDefaultWorkOrderTab(roleType, view.filterValue);
					history.location.pathname.includes(defaultTab)
						? onApplyFilter && onApplyFilter(parseFilterValueToAdvancedFilters(view.filterValue))
						: history.push(
								`/${roleType.toLowerCase()}/workOrders/overview/${defaultTab}?${view.filterQuery}`
						  );
			}
		},
		[roleType, entityType, history, onApplyFilter]
	);

	const openAllViews = useCallback(() => {
		history.push(`/${roleType.toLowerCase()}/${routeMap[entityType]}/savedViews/overview/all`);
	}, [roleType, entityType, history]);

	const openAllViewsButton = useMemo(
		() =>
			!loading && views.length >= DEFAULT_PAGE_LIMIT ? (
				<div
					style={{
						textAlign: 'center',
						height: 32,
						lineHeight: '32px',
					}}
				>
					<Button onClick={openAllViews} type="link">
						SEE ALL
					</Button>
				</div>
			) : null,
		[loading, views, openAllViews]
	);

	const onDelete = useCallback(
		(view) => {
			setLoading(true);
			deleteSavedFilterView(view)
				.then(() => refreshList())
				.catch(() => setLoading(false));
		},
		[deleteSavedFilterView, refreshList]
	);

	return (
		<Modal
			visible={true}
			title="Open Saved View"
			onCancel={onCancel}
			footer={openAllViewsButton}
			closable={true}
		>
			{!loading && views.length < 1 && <SavedFilterViewEmptyState entityType={entityType} />}
			<List
				dataSource={views}
				loading={loading}
				renderItem={(view) => (
					<List.Item
						key={view.id}
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
						}}
					>
						<SavedFilterViewsRowDisplay
							record={view}
							isAdmin={isAdmin}
							onOpenView={onApplyView}
							onDelete={onDelete}
						/>
					</List.Item>
				)}
			/>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	fetchSavedFilterViewsForBuyer: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			savedFilterViewsRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchSavedFilterViewsForSupplier: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			savedFilterViewsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	deleteFilterViewForBuyer: (entity) =>
		dispatch(savedFilterViewsRestCrudThunksForBuyer.delete(entity)),
	deleteFilterViewForSupplier: (entity) =>
		dispatch(savedFilterViewsRestCrudThunksForSupplier.delete(entity)),
});

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(SavedFilterViews));

import * as React from 'react';
import { Steps } from 'antd';

const { Step } = Steps;

export default class ModalHeaderSteps extends React.Component<any, any> {
	constructor(props) {
		super(props);
		let current = this.props.steps
			.map((step) => {
				return step.isActive;
			})
			.indexOf(true);
		this.state = { current: current };
	}
	componentDidMount() {}

	render() {
		const { current } = this.state;
		return (
			<Steps size="small" current={current} style={{ width: '95%' }}>
				{this.props.steps.map((step) => {
					return <Step title={step.title}></Step>;
				})}
			</Steps>
		);
	}
}

import { WarningOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import React, { Component } from 'react';

interface OfflineHandlerProps {
	children: any;
}

export default (WrappedComponent) => {
	class OfflineHandler extends Component<OfflineHandlerProps, any> {
		constructor(props) {
			super(props);
			this.state = {
				isOffline: false,
			};
		}
		handleOffline = () => {
			this.setState({ isOffline: !window.navigator.onLine });
		};
		componentDidMount() {
			window.addEventListener('offline', this.handleOffline);
			window.addEventListener('online', this.handleOffline);
		}
		componentWillUnmount() {
			window.removeEventListener('offline', this.handleOffline);
			window.removeEventListener('online', this.handleOffline);
		}
		render() {
			const { isOffline } = this.state;
			return (
				<>
					{' '}
					{isOffline && (
						<Alert
							icon={
								<WarningOutlined style={{ color: 'white', position: 'static', marginRight: 16 }} />
							}
							style={{
								background: '#ff4d4f',
								color: 'white',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
							message="You are Offline. Please check your connection."
							type="error"
							banner
						/>
					)}
					<div style={{ opacity: isOffline ? 0.7 : 1 }}>
						<WrappedComponent {...this.props} />
					</div>
				</>
			);
		}
	}
	return OfflineHandler;
};

import React, { useCallback, useMemo } from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { EmptorTag } from '../emptor_tag/EmptorTag';
import WarrantyDisplay from '../common/WarrantyDisplay';
import {
	checkIsWarrantyActive,
	getWarrantyStatus,
	nullSafeGet,
	nullSafeGetOrElse,
	stopDefaultEvents,
} from '../../utils/DataAccessUtils';
import { Button, Popover, Typography } from 'antd';
import OfflineTag from '../common/OfflineTag';
import QRCode from 'qrcode.react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { BUYER_ONLY_NO_STORE_ASSOCIATE } from '../roles/roles';
import AssetIdDisplay from './AssetIdDisplay';
import { CopyOutlined, EditOutlined } from '@ant-design/icons';

require('./AssetRowDisplay.less');
const { Text } = Typography;

const DEFAULT_BACK_LINK_TEXT = 'Back to Assets';

const HiddenQRCode = () => (
	<div className="invisible">
		<QRCode size={64} value={''} />
	</div>
);

const AssetWithQRCodeDisplay: React.FC<any> = ({
	asset,
	companyConfig,
	userType,
	currentUser,
	history,
	location,
	disableActions,
}) => {
	const warrantyStatus = getWarrantyStatus(nullSafeGet('warranties.0', asset));
	const partWarranty = nullSafeGet('partWarranty', asset);

	const removeAssetNumberToQRCodeAssociation = useMemo(
		() =>
			userType === ROLE_TYPES.SUPPLIER
				? nullSafeGetOrElse(
						'facility.privateBuyerCompanySettings.config.assetConfig.removeAssetNumberToQRCodeAssociation',
						currentUser,
						false
				  )
				: nullSafeGetOrElse(
						'config.assetConfig.removeAssetNumberToQRCodeAssociation',
						companyConfig,
						false
				  ),
		[companyConfig, currentUser, userType]
	);

	const newWorkOrderLink =
		userType === ROLE_TYPES.SUPPLIER
			? 'newWorkOrder'
			: currentUser.roles &&
			  currentUser.roles.some((role) => BUYER_ONLY_NO_STORE_ASSOCIATE.has(role))
			? 'newWorkOrder'
			: 'requestService';

	const redirectTo = useCallback(
		(path) => (e) => {
			stopDefaultEvents(e);
			history.push(getLinkWIthBackLinkParams(location, DEFAULT_BACK_LINK_TEXT, path));
		},
		[history, location]
	);

	return (
		<div className="assetRowDisplay group" style={{ padding: '24px 16px' }}>
			<div className="flex flex-col gap-3">
				<div className="assetRowDisplay__rowTitle flex flex-row items-center">
					<Ellipsis tooltip={true} length={56}>
						{asset.name}
					</Ellipsis>
				</div>
				<AssetIdDisplay asset={asset} />
				<div className="flex text-gray-500">
					<div className="mr-4">
						<span className="rowGrayChip">
							<i className="icons8-font icons8-location-marker mr- mr-1" />
							<span>{nullSafeGet('location.name', asset)}</span>
						</span>
					</div>
					{asset.downtimeStatus === 'offline' ? (
						<div className="mr-4">
							<OfflineTag />
						</div>
					) : null}
					{checkIsWarrantyActive(nullSafeGet('warranties.0', asset)) ? (
						<div className="mr-4">{WarrantyDisplay(warrantyStatus, 'Service')}</div>
					) : null}
					{checkIsWarrantyActive(partWarranty) ? (
						<div className="mr-4">{WarrantyDisplay(getWarrantyStatus(partWarranty), 'Part')}</div>
					) : null}
					{asset.manufacturer ? <div className="mr-4">{asset.manufacturer}</div> : null}
					{asset.modelNumber ? <div className="mr-4">{asset.modelNumber}</div> : null}
					{asset.isActive ? null : (
						<div className="mr-4">
							<Text className={'mr-1'} type={'danger'}>
								Inactive
							</Text>
						</div>
					)}
				</div>
			</div>
			<div className="flex flex-row items-center group-hover:hidden">
				<div className="flex flex-col items-end gap-3">
					{asset.assetNumber ? (
						<div className="flex text-gray-500 ">{`Asset Number ${asset.assetNumber}`}</div>
					) : (
						<div className="flex text-gray-500 opacity-0">No</div>
					)}
					<div className="uppercase text-gray-400">{nullSafeGet('assetType.name', asset)}</div>
					<EmptorTag className="assetRowDisplay__pill">
						{asset.totalNotClosedWOCount > 0
							? asset.totalNotClosedWOCount + ' Open WOs'
							: 'No Open WOs'}
					</EmptorTag>
				</div>
				<div className="ml-5">
					{removeAssetNumberToQRCodeAssociation ? (
						asset.isUserAssignedUUID && asset.assetUUID ? (
							<QRCode size={64} value={asset.assetUUID} />
						) : (
							<HiddenQRCode />
						)
					) : asset.assetUUID ? (
						<QRCode size={64} value={asset.assetUUID} />
					) : (
						<HiddenQRCode />
					)}
				</div>
			</div>
			{!disableActions ? (
				<div className="no-wrap flex hidden flex-row items-center group-hover:flex">
					<div className="flex flex-col items-end gap-3">
						<div>&nbsp;</div>
						<div className="flex flex-row items-center">
							<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_ASSETS}>
								<Button type="ghost" onClick={redirectTo(`/${userType}/assets/edit/${asset.id}`)}>
									Edit
								</Button>
							</AccessPermissionChecker>
							<Button
								className="ml-5"
								type="primary"
								onClick={redirectTo(`/${userType}/assets/${asset.id}/${newWorkOrderLink}`)}
							>
								Request Service
							</Button>
						</div>
						<div>&nbsp;</div>
					</div>
					<HiddenQRCode />
				</div>
			) : null}
		</div>
	);
};

const mapStateToProps = (state) => ({
	companyConfig: state.company_config.detail,
	currentUser: state.session.currentUser,
});

const ComponentWithRouter = withRouter<any, any>(connect(mapStateToProps)(AssetWithQRCodeDisplay));

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithRouter);

import { WORK_ORDERS_CRUD_ACTION_CREATORS } from '../actions/work_orders_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import FetchUtils, { parseJSON } from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { logoutSuccess } from '../actions/session_actions';
import { message } from 'antd';

export const workOrdersRestCrudThunksForBuyer = new RestCrudThunks(
	'work_orders',
	'work_order',
	'workOrder',
	'workOrders',
	WORK_ORDERS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'work_order/'
);
export const workOrdersRestCrudThunksForSupplier = new RestCrudThunks(
	'work_orders',
	'work_order',
	'workOrder',
	'workOrders',
	WORK_ORDERS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'work_order/'
);
const fetchUtils = new FetchUtils();

const updateWorkOrderStatus = (workOrderStatus) => (roleType) => (entity) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/work_order/work_orders/status_update/${workOrderStatus}`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.post(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: entity,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

const planWorkOrder = updateWorkOrderStatus('plan');
export const planWorkOrderForBuyer = planWorkOrder(ROLE_TYPES.BUYER);
export const planWorkOrderForSupplier = planWorkOrder(ROLE_TYPES.SUPPLIER);

const startWorkOrder = updateWorkOrderStatus('start_work');
export const startWorkOrderForBuyer = startWorkOrder(ROLE_TYPES.BUYER);
export const startWorkOrderForSupplier = startWorkOrder(ROLE_TYPES.SUPPLIER);

const finishWorkOrder = updateWorkOrderStatus('finish_work');
export const finishWorkOrderForBuyer = finishWorkOrder(ROLE_TYPES.BUYER);
export const finishWorkOrderForSupplier = finishWorkOrder(ROLE_TYPES.SUPPLIER);

const cancelWorkOrder = updateWorkOrderStatus('cancelled');
export const cancelWorkOrderForBuyer = cancelWorkOrder(ROLE_TYPES.BUYER);
export const cancelWorkOrderForSupplier = cancelWorkOrder(ROLE_TYPES.SUPPLIER);

const reopenWorkOrder = updateWorkOrderStatus('reopen');
export const reopenWorkOrderForBuyer = reopenWorkOrder(ROLE_TYPES.BUYER);
export const reopenWorkOrderForSupplier = reopenWorkOrder(ROLE_TYPES.SUPPLIER);

const deferWorkOrder = updateWorkOrderStatus('deferred_for_budget');
export const deferWorkOrderForBuyer = deferWorkOrder(ROLE_TYPES.BUYER);
export const deferWorkOrderForSupplier = deferWorkOrder(ROLE_TYPES.SUPPLIER);

const snoozeWorkOrder = updateWorkOrderStatus('snoozed');
export const snoozeWorkOrderForBuyer = snoozeWorkOrder(ROLE_TYPES.BUYER);
export const snoozeWorkOrderForSupplier = snoozeWorkOrder(ROLE_TYPES.SUPPLIER);

const unsnoozeWorkOrder = updateWorkOrderStatus('undeferred');
export const unsnoozeWorkOrderForBuyer = unsnoozeWorkOrder(ROLE_TYPES.BUYER);
export const unsnoozeWorkOrderForSupplier = unsnoozeWorkOrder(ROLE_TYPES.SUPPLIER);

const reviewWorkOrderAndMarkComplete = updateWorkOrderStatus('work_reviewed_and_completed');
export const reviewWorkOrderAndMarkCompleteForBuyer = reviewWorkOrderAndMarkComplete(
	ROLE_TYPES.BUYER
);
export const reviewWorkOrderAndMarkCompleteForSupplier = reviewWorkOrderAndMarkComplete(
	ROLE_TYPES.SUPPLIER
);

const reviewWorkOrderAndMarkUnsatisfactory = updateWorkOrderStatus('work_unsatisfactory');
export const reviewWorkOrderAndMarkUnsatisfactoryForBuyer = reviewWorkOrderAndMarkUnsatisfactory(
	ROLE_TYPES.BUYER
);
export const reviewWorkOrderAndMarkUnsatisfactoryForSupplier = reviewWorkOrderAndMarkUnsatisfactory(
	ROLE_TYPES.SUPPLIER
);

const reviewInternalTechWorkOrderAndMarkComplete = updateWorkOrderStatus(
	'internal_tech_work_reviewed_and_completed'
);
export const reviewInternalTechWorkOrderAndMarkCompleteForBuyer =
	reviewInternalTechWorkOrderAndMarkComplete(ROLE_TYPES.BUYER);
export const reviewInternalTechWorkOrderAndMarkCompleteForSupplier =
	reviewInternalTechWorkOrderAndMarkComplete(ROLE_TYPES.SUPPLIER);

const markCompletedByThirdParty = updateWorkOrderStatus('mark_completed_by_supplier');
export const markCompletedByThirdPartyForSupplier = markCompletedByThirdParty(ROLE_TYPES.SUPPLIER);

const reviewInternalWorkOrderAndMarkUnresolved = updateWorkOrderStatus(
	'internal_tech_work_unresolved'
);
export const reviewInternalWorkOrderAndMarkUnresolvedForBuyer =
	reviewInternalWorkOrderAndMarkUnresolved(ROLE_TYPES.BUYER);

const downloadWorkOrderCSV =
	(roleType) =>
	(params, filters = null) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/work_order/work_orders/csv_report_generator`;

		// if sorting present, fix order naming and add to query params

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.message);
							message.error(d.data);
						} else {
							resolve(d.data);
							const hide = message.loading(d.data, 0);
							setTimeout(hide, 7000);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};

export const downloadWorkOrderCSVForBuyer = downloadWorkOrderCSV(ROLE_TYPES.BUYER);
export const downloadWorkOrderCSVForSupplier = downloadWorkOrderCSV(ROLE_TYPES.SUPPLIER);

// entity must include id and notes fields
const updateWorkOrderNotes =
	(roleType) =>
	(entity, primaryKey = 'id') => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/work_order/work_orders/update_notes`;
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));

				fetchUtils
					.patch(baseUrl + apiUrl, {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entity,
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.createError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};

export const updateWorkOrderNotesForBuyer = updateWorkOrderNotes(ROLE_TYPES.BUYER);
export const updateWorkOrderNotesForSupplier = updateWorkOrderNotes(ROLE_TYPES.SUPPLIER);

// entity must include id and notes fields
const appendWorkOrderNotes =
	(roleType) =>
	(entity, primaryKey = 'id') => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/work_order/work_orders/append_notes`;
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));

				fetchUtils
					.patch(baseUrl + apiUrl, {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entity,
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.createError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};

export const appendWorkOrderNotesForBuyer = appendWorkOrderNotes(ROLE_TYPES.BUYER);
export const appendWorkOrderNotesForSupplier = appendWorkOrderNotes(ROLE_TYPES.SUPPLIER);

const archiveWorkOrder =
	(roleType) =>
	(entity, primaryKey = 'id', showUndo = false) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/work_order/work_orders/archive`;
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));

				const archiveRecord = () =>
					fetchUtils
						.post(baseUrl + apiUrl, {
							headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
							data: entity,
						})
						.then(parseJSON)
						.then(({ json, status, ok }) => {
							const d = json;
							if (status === 401) {
								dispatch(logoutSuccess());
							}
							if (d.status === 'error') {
								dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
								reject(d.message);
							} else {
								dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
								resolve(d.data);
							}
						})
						.catch((d) => {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.createError(d.message, entity));
							reject(d.message);
						});
			});
		};
	};

export const archiveWorkOrderForBuyer = archiveWorkOrder(ROLE_TYPES.BUYER);
export const archiveWorkOrderForSupplier = archiveWorkOrder(ROLE_TYPES.SUPPLIER);

const unarchiveWorkOrder =
	(roleType) =>
	(entity, primaryKey = 'id') => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/work_order/work_orders/unarchive`;
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));

				fetchUtils
					.post(baseUrl + apiUrl, {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entity,
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.createError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};

export const unarchiveWorkOrderForBuyer = unarchiveWorkOrder(ROLE_TYPES.BUYER);
export const unarchiveWorkOrderForSupplier = unarchiveWorkOrder(ROLE_TYPES.SUPPLIER);

const confirmWorkOrder =
	() =>
	(entity, primaryKey = 'id') => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/supplier/work_order/work_orders/status_update/confirm`;
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));

				fetchUtils
					.post(baseUrl + apiUrl, {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entity,
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};

const declineWorkOrder =
	() =>
	(entity, primaryKey = 'id') => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/supplier/work_order/work_orders/status_update/decline`;
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.deleteStart(entity));

				fetchUtils
					.post(baseUrl + apiUrl, {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entity,
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.deleteError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.deleteSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.deleteError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};

const declineWorkOrderPrivate =
	() =>
	(entity, primaryKey = 'id') => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/supplier/work_order/work_orders/status_update/internal_tech/decline`;
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));

				fetchUtils
					.post(baseUrl + apiUrl, {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entity,
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};

const transferWorkOrder =
	() =>
	(entity, primaryKey = 'id') => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/supplier/work_order/work_orders/status_update/transfer`;
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));

				fetchUtils
					.post(baseUrl + apiUrl, {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entity,
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};

const transferWorkOrderPrivateSupplier =
	() =>
	(entity, primaryKey = 'id') => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/supplier/work_order/work_orders/status_update/transfer`;
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));

				fetchUtils
					.post(baseUrl + apiUrl, {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entity,
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.deleteError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};

const partsOrderedWorkOrder =
	(roleType) =>
	(entity, primaryKey = 'id') => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/work_order/work_orders/status_update/parts_ordered`;
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));

				fetchUtils
					.post(baseUrl + apiUrl, {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entity,
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};

const partsReceivedWorkOrder =
	(roleType) =>
	(entity, primaryKey = 'id') => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/work_order/work_orders/status_update/parts_received`;
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));

				fetchUtils
					.post(baseUrl + apiUrl, {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entity,
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};

const partsRequestedWorkOrder =
	() =>
	(entity, primaryKey = 'id') => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/supplier/work_order/work_orders/status_update/parts_requested`;
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));

				fetchUtils
					.post(baseUrl + apiUrl, {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entity,
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};

export const acceptWorkOrderForSupplier = confirmWorkOrder();
export const declineWorkOrderForSupplier = declineWorkOrder();
export const declineWorkOrderForPrivateSupplier = declineWorkOrderPrivate();
export const transferWorkOrderForSupplier = transferWorkOrder();

export const transferWorkOrderForPrivateSupplier = transferWorkOrderPrivateSupplier();

export const partsOrderedWorkOrderForBuyer = partsOrderedWorkOrder(ROLE_TYPES.BUYER);
export const partsOrderedWorkOrderForSupplier = partsOrderedWorkOrder(ROLE_TYPES.SUPPLIER);

export const partsReceivedWorkOrderForBuyer = partsReceivedWorkOrder(ROLE_TYPES.BUYER);
export const partsReceivedWorkOrderForSupplier = partsReceivedWorkOrder(ROLE_TYPES.SUPPLIER);

export const partsRequestedWorkOrderForSupplier = partsRequestedWorkOrder();

const scheduleServiceCall =
	(roleType) =>
	(entity, primaryKey = 'id') => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/work_order/service_calls/status_update/tech_scheduled`;
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));

				fetchUtils
					.post(baseUrl + apiUrl, {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entity,
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};
export const scheduleServiceCallForSupplier = scheduleServiceCall(ROLE_TYPES.SUPPLIER);

const rescheduleServiceCall =
	(roleType) =>
	(entity, primaryKey = 'id') => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/work_order/service_calls/status_update/tech_rescheduled`;
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));

				fetchUtils
					.post(baseUrl + apiUrl, {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entity,
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};
export const rescheduleServiceCallForSupplier = rescheduleServiceCall(ROLE_TYPES.SUPPLIER);

export const manualCheckInServiceCallForSupplier = (entity, primaryKey = 'id') => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/supplier/work_order/service_calls/status_update/remote_check_in`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));
			fetchUtils
				.post(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: entity,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

export const manualCheckOutServiceCallForSupplier = (entity, primaryKey = 'id') => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/supplier/work_order/service_calls/status_update/remote_check_out`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.post(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: entity,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

export const getDailyApikey = () => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/buyer/videocalls/get_api_key`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(baseUrl + apiUrl, {})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

const getMeetingToken = (userType) => (roomName) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${userType}/live/meeting_id/` + roomName;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(baseUrl + apiUrl, {})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(window.btoa(d.data));
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const getBuyerMeetingToken = (roomName) => getMeetingToken(ROLE_TYPES.BUYER)(roomName);
export const getSupplierMeetingToken = (roomName) => getMeetingToken(ROLE_TYPES.SUPPLIER)(roomName);

const owShortenedLink = (userType) => (longUrl) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${userType}/utility/shorten_url`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.post(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: {
						longUrl: longUrl,
					},
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 200) {
						resolve(d.data);
					} else {
						resolve(longUrl);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const getBuyerOWShortenedLink = (longUrl) => owShortenedLink(ROLE_TYPES.BUYER)(longUrl);
export const getSupplierOWShortenedLink = (longUrl) =>
	owShortenedLink(ROLE_TYPES.SUPPLIER)(longUrl);

const owLiveShortenedLink = (userType) => (longUrl) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${userType}/utility/shorten_live_url`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.post(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: {
						longUrl: longUrl,
					},
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 200) {
						resolve(d.data);
					} else {
						resolve(longUrl);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const getBuyerOWLiveShortenedLink = (longUrl) =>
	owLiveShortenedLink(ROLE_TYPES.BUYER)(longUrl);
export const getSupplierOWLiveShortenedLink = (longUrl) =>
	owLiveShortenedLink(ROLE_TYPES.SUPPLIER)(longUrl);

const markNotificationSeen =
	(userType) =>
	(ids = []) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${userType}/notification/user_notifications/has_seen/${ids.join(',')}`;
		return (dispatch) => {
			return new Promise<void>((resolve, reject) => {
				fetchUtils
					.patch(baseUrl + apiUrl, {})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 200) {
							resolve(d.data);
						} else {
							resolve();
						}
					})
					.catch((d) => {
						console.log('d', d);
						reject(d.message);
					});
			});
		};
	};

export const buyerMarkNotificationSeen = (ids) => markNotificationSeen(ROLE_TYPES.BUYER)(ids);
export const supplierMarkNotificationSeen = (ids) => markNotificationSeen(ROLE_TYPES.SUPPLIER)(ids);

const getCountByDisplayStatus = (userType) => (params) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${userType}/work_order/work_orders/count_by_display_status`;
	return new Promise<void>((resolve, reject) => {
		fetchUtils
			.get(
				baseUrl + apiUrl,
				Object.assign(
					{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
					params
				)
			)
			.then(parseJSON)
			.then(({ json, status, ok }) => {
				const d = json;
				if (status === 200) {
					resolve(d.data);
				} else {
					resolve();
				}
			})
			.catch((d) => {
				console.log('d', d);
				reject(d.message);
			});
	});
};

export const getCountByDisplayStatusForBuyer = getCountByDisplayStatus(ROLE_TYPES.BUYER);
export const getCountByDisplayStatusForSupplier = getCountByDisplayStatus(ROLE_TYPES.SUPPLIER);

const getCountByStatus = (userType) => (params) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${userType}/work_order/work_orders/count_by_status`;
	return new Promise<void>((resolve, reject) => {
		fetchUtils
			.get(
				baseUrl + apiUrl,
				Object.assign(
					{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
					params
				)
			)
			.then(parseJSON)
			.then(({ json, status, ok }) => {
				const d = json;
				if (status === 200) {
					resolve(d.data);
				} else {
					resolve();
				}
			})
			.catch((d) => {
				console.log('d', d);
				reject(d.message);
			});
	});
};

export const getCountByStatusForBuyer = getCountByStatus(ROLE_TYPES.BUYER);
export const getCountByStatusForSupplier = getCountByStatus(ROLE_TYPES.SUPPLIER);

const backDataWorkOrder = (userType) => (entity, reported_at) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${userType}/work_order/work_orders/${entity.id}/modify_reported_at?reportedAt=${reported_at}`;
	return (dispatch) => {
		return new Promise<void>((resolve, reject) => {
			dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));
			fetchUtils
				.patch(baseUrl + apiUrl, {})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.createError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

export const backDataWorkOrderForSupplier = (id, reported_at) =>
	backDataWorkOrder(ROLE_TYPES.SUPPLIER)(id, reported_at);

const approveWorkOrderStatus = (workOrderStatus) => (roleType) => (entity) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/work_order/work_orders/status_update/${workOrderStatus}`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			console.log(entity);
			dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));
			fetchUtils
				.put(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: entity,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};
const approveWorkOrder = approveWorkOrderStatus('approved');
export const approveWorkOrderForBuyer = approveWorkOrder(ROLE_TYPES.BUYER);
export const approveWorkOrderForSupplier = approveWorkOrder(ROLE_TYPES.SUPPLIER);

export const getQuestionnaire =
	(userType) =>
	(id = []) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${userType}/work_order/review_questionnaire`;
		return (dispatch) => {
			return new Promise<void>((resolve, reject) => {
				fetchUtils
					.get(baseUrl + apiUrl, {})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 200) {
							resolve(d.data);
						} else {
							resolve();
						}
					})
					.catch((d) => {
						console.log('d', d);
						reject(d.message);
					});
			});
		};
	};

export const buyerGetQuestionnaire = (id) => getQuestionnaire(ROLE_TYPES.BUYER)(id);
export const supplierGetQuestionnaire = (id) => getQuestionnaire(ROLE_TYPES.SUPPLIER)(id);

export const submitQuestionnaire = (userType) => (entity) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${userType}/work_order/review_question_answer`;
	return (dispatch) => {
		return new Promise<void>((resolve, reject) => {
			fetchUtils
				.post(baseUrl + apiUrl, { data: entity })
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 200) {
						resolve(d.data);
					} else {
						resolve();
					}
				})
				.catch((d) => {
					console.log('d', d);
					reject(d.message);
				});
		});
	};
};

export const buyerSubmitQuestionnaire = (id) => submitQuestionnaire(ROLE_TYPES.BUYER)(id);
export const supplierSubmitQuestionnaire = (id) => submitQuestionnaire(ROLE_TYPES.SUPPLIER)(id);

export const getQuestionnaireByWorkOrder =
	(userType) =>
	(id = []) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${userType}/work_order/review_question_answer?workOrderId=${id}`;
		return (dispatch) => {
			return new Promise<void>((resolve, reject) => {
				fetchUtils
					.get(baseUrl + apiUrl, {})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 200) {
							resolve(d.data);
						} else {
							resolve();
						}
					})
					.catch((d) => {
						console.log('d', d);
						reject(d.message);
					});
			});
		};
	};

export const buyerGetQuestionnaireByWorkOrder = (id) =>
	getQuestionnaireByWorkOrder(ROLE_TYPES.BUYER)(id);
export const supplierGetQuestionnaireByWorkOrder = (id) =>
	getQuestionnaireByWorkOrder(ROLE_TYPES.SUPPLIER)(id);

export const syncWorkOrderApprovalHierarchy = (userType) => (entity, status) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));

			const baseUrl = getBackendUri();
			fetchUtils
				.patch(
					baseUrl +
						`/api/v1/${userType}/work_order/work_orders/approval_hierarchy_update/${entity.id}/status/${status}`,
					{}
				)
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};
export const syncWorkOrderApprovalHierarchyForBuyer = syncWorkOrderApprovalHierarchy('buyer');
export const syncWorkOrderApprovalHierarchyForSupplier = syncWorkOrderApprovalHierarchy('supplier');

export const bulkArchiveWorkOrders = (userType) => (filters) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			const baseUrl = getBackendUri();
			fetchUtils
				.post(baseUrl + `/api/v1/${userType}/work_order/work_orders/archive/bulk`, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: filters,
				})
				.then((response) => response.json())
				.then((d) => {
					const count = d && d.data;
					if (d.status === 'error') {
						reject(d.message);
						message.error(`${count} Failed to archive`);
					} else {
						resolve(d.data);
						message.success(`${count} Work Orders Archived`);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

import * as enquire from 'enquire.js';
export function enquireScreen(cb) {
	if (!enquire) {
		return;
	}
	/* eslint-disable no-unused-expressions */
	// and (min-width: 320px)
	enquire.register('only screen and (max-width: 768px)', {
		match: () => {
			cb && cb(true);
		},
		unmatch: () => {
			cb && cb();
		},
	});
	/* eslint-enable no-unused-expressions */
}

import * as React from 'react';
import { Layout, Row, Col, Card, Button, Divider } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { getTimeDurationFromSeconds } from '../../utils/DataFormatterUtils';
import {
	compareStrings,
	getCurrency,
	getEntityById,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { EmptyState } from '../empty_state/EmptyState';
import {
	proposalsRestCrudThunksForSupplier,
	proposalStatusChangeForInternalSupplier,
	syncProposalApprovalHierarchy,
} from '../../thunks/proposals_thunks';
import {
	appendWorkOrderNotesForSupplier,
	workOrdersRestCrudThunksForSupplier,
} from '../../thunks/work_orders_thunks';
import { Link } from 'react-router-dom';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import ApprovalHierarchyDisplayQuote from '../approval_hierarchy_display_quote/ApprovalHierarchyDisplayQuote';
import { quotesRestCrudThunksForSupplier } from '../../thunks/quotes_thunks';
import QuoteUploader from '../quote_uploader/QuoteUploader';
import QuotesAdditionalDetails from '../quotes_components/QuotesAdditionalDetails';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { isInternalTech, PERMISSION_NAMES } from '../../utils/AuthUtils';
import CapexSwitch from '../capex_switch/CapexSwitch';
import QuoteEditButton from '../quote_edit_button/QuoteEditButton';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { SUPER_ADMIN, SUPPLIER_ADMIN } from '../roles/roles';

const { Content } = Layout;

const style = require('./SupplierWorkOrdersDetailQuotesPage.less');

class SupplierWorkOrderQuotesDetailPage extends React.Component<any, any> {
	refreshQuote = () => {
		const { getQuote, quote } = this.props;
		getQuote(quote.id);
	};

	componentDidMount() {
		const { getQuote, getQuotes, getWorkOrder, match, workOrder } = this.props;
		const workOrderId = match.params.id;
		const getQuoteDetails = (workOrder) => {
			getQuotes(
				{ workOrderId, supplierFacilityId: workOrder.supplierFacilityId },
				'workOrderAssociatedQuotes'
			).then((quotes) => (nullSafeGet('0.id', quotes) ? getQuote(quotes[0].id) : null));
		};
		if (workOrder && workOrder.id) {
			getQuoteDetails(workOrder);
		} else {
			getWorkOrder(workOrderId).then((data) => {
				getQuoteDetails(data);
			});
		}
	}
	handleFileAttachmentUploadChange = (newAttachments) => {
		const { updateQuote, quote } = this.props;
		const newQuote = {
			...quote,
			attachments: newAttachments,
		};
		updateQuote(newQuote);
	};

	handleQuotePDFUploadChange = (quoteFileUrl) => {
		const { updateQuote, getQuote, quote } = this.props;

		const newQuote = {
			...quote,
			proposalPdfLink: quoteFileUrl,
		};

		updateQuote(newQuote).then(() => {
			getQuote(quote.id);
		});
	};

	render() {
		const {
			proposals,
			proposalsFetching,
			history,
			match,
			currentUser,
			approveQuote,
			declineQuote,
			appendWorkOrderNotes,
			workOrder,
			syncQuote,
			userType,
		} = this.props;
		const workOrderId = match.params.id;
		const isInternalTech = nullSafeGetOrElse('facility.status', currentUser, '') === 'private';
		const isAdmin =
			currentUser.roles.includes(SUPPLIER_ADMIN) || currentUser.roles.includes(SUPER_ADMIN);
		const approvalAllowed = nullSafeGetOrElse(
			'facility.internalTechConfig.allowedToApproveProposal',
			currentUser,
			false
		);

		const CreateQuoteButton = (
			<div style={{ textAlign: 'center' }}>
				<div style={{ maxWidth: 440, marginBottom: 16 }}>
					Need an increase on not-to-exceed (NTE) amount?
				</div>
				{isInternalTech ? (
					<AccessPermissionChecker name={PERMISSION_NAMES.CAN_UPLOAD_QUOTES_FOR_SUPPLIER}>
						<Link to={`/supplier/workOrders/detail/${workOrderId}/newProposal`}>
							<Button type="primary">Create a quote</Button>
						</Link>
					</AccessPermissionChecker>
				) : (
					<Link to={`/supplier/workOrders/detail/${workOrderId}/newProposal`}>
						<Button type="primary">Create a quote</Button>
					</Link>
				)}
			</div>
		);

		const associatedQuotes = getRecordsForTargetCollection(proposals, 'workOrderAssociatedQuotes');
		return proposalsFetching ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="quotesDetailDetailsPage">
				<LogOnMountWithStandardEventProperties eventType="visited supplier work order quotes page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				{associatedQuotes.length > 0 ? (
					<div>
						{associatedQuotes.map((quote) => {
							const currency = getCurrency({ workOrder: quote.workOrder });
							const initialAccruedLineItem = nullSafeGetOrElse(`miscLineItems`, quote, []).find(
								(lineItem) => compareStrings(lineItem.description, 'accrued cost')
							);
							const initialMiscLineItem = nullSafeGetOrElse(`miscLineItems`, quote, []).find(
								(lineItem) => compareStrings(lineItem.description, 'miscellaneous')
							);
							const hasPDF = quote.proposalPdfLink && quote.proposalPdfLink.length > 0;
							const pdfElement = hasPDF ? (
								<p className={'mb-1'}>
									<a href={quote.proposalPdfLink} target="_blank" rel="noreferrer">
										Quote PDF
									</a>
									{!isInternalTech ? (
										<Button type="link" onClick={() => this.handleQuotePDFUploadChange(undefined)}>
											<i className="icons8-font icons8-trash-can"></i>
										</Button>
									) : null}
								</p>
							) : null;
							const uploadQuote =
								isInternalTech || isAdmin ? (
									<QuoteUploader
										roleType="supplier"
										quoteId={quote && quote.id}
										defaultFileList={[]}
										multiple={false}
										handleUploadSuccess={this.handleQuotePDFUploadChange}
									/>
								) : null;
							const initialAccruedCost = nullSafeGetOrElse('amount', initialAccruedLineItem, 0);
							const initialMiscCost = nullSafeGetOrElse('amount', initialMiscLineItem, 0);
							return (
								<Row style={{ margin: '16px 8px' }} gutter={12} key={quote.id}>
									<Col span={24}>
										<Card>
											<Row justify={'space-between'} className={'rowSpacing'}>
												<Col />
												<Col>
													<Button
														onClick={() => {
															history.push(`/${userType}/quotes/detail/${quote.id}`);
														}}
													>
														View Quote Page
													</Button>
													<QuoteEditButton
														quote={quote}
														style={{ marginLeft: 12 }}
														onClick={() => {
															history.push(
																`/${userType}/workOrders/detail/${quote.workOrder.id}/editProposal/${quote.id}`
															);
														}}
													/>
												</Col>
											</Row>
											<Row className={'justify-between'}>
												<ApprovalHierarchyDisplayQuote
													workOrder={workOrder}
													quote={quote}
													currentUser={currentUser}
													approveQuote={approveQuote}
													declineQuote={declineQuote}
													syncQuote={syncQuote}
													appendWorkOrderNotes={appendWorkOrderNotes}
													style={{}}
													refresh={this.refreshQuote}
													allowedToApprove={isInternalTech && approvalAllowed}
													history={history}
												/>
												{isInternalTech && (
													<CapexSwitch
														quote={quote}
														refresh={this.refreshQuote}
														companyConfig={nullSafeGetOrElse(
															'workOrder.buyerCompany.buyerCompanySettings',
															quote,
															{}
														)}
													/>
												)}
											</Row>
											<div className={'mt-5 flex flex-row justify-between'}>
												<QuotesAdditionalDetails
													quote={quote}
													passthroughItems={[
														pdfElement || uploadQuote
															? {
																	key: 'File',
																	value: (
																		<div>
																			{pdfElement}
																			{uploadQuote}
																		</div>
																	),
																	className: 'detailRow__item firstRow lastCol',
															  }
															: {},
													]}
												/>
											</div>
											<Divider />
											<div className="sectionHeaderWrapper">
												<span className="sectionHeaderText">Incurred Cost</span>
												<span className="sectionHeaderText2">
													{currency.format(
														parseFloat(nullSafeGet(`incurredCostTotalBeforeTax`, quote)) || 0
													)}
												</span>
											</div>
											<Divider />
											<div className="sectionHeaderWrapper" style={{ marginBottom: 8 }}>
												<span className="sectionHeaderText">Labor</span>
												<span className="sectionHeaderText2">
													{currency.format(quote.laborTotalBeforeTax)}
												</span>
											</div>
											{nullSafeGetOrElse('laborLineItems', quote, []).map((lli, idx) => (
												<div
													className="sectionHeaderWrapper2"
													style={{
														marginBottom:
															idx === nullSafeGetOrElse('laborLineItems', quote, []).length - 1
																? 16
																: 8,
													}}
												>
													<span className="lineItemDetailText">
														{lli.numberOfTech} {lli.roleType} *{' '}
														{getTimeDurationFromSeconds(lli.seconds)} @{' '}
														{currency.format(parseFloat(lli.hourlyRate))} / hour
													</span>
													<span className="lineItemDetailText">
														{currency.format(parseFloat(lli.amount))}
													</span>
												</div>
											))}
											<Divider />

											<div className="sectionHeaderWrapper" style={{ marginBottom: 8 }}>
												<span className="sectionHeaderText">Materials</span>
												<span className="sectionHeaderText2">
													{currency.format(quote.materialTotalBeforeTax)}
												</span>
											</div>
											{nullSafeGetOrElse('materialLineItems', quote, []).map((mli, idx) => (
												<div
													className="sectionHeaderWrapper2"
													style={{
														marginBottom:
															idx === nullSafeGetOrElse('laborLineItems', quote, []).length - 1
																? 16
																: 8,
													}}
												>
													<span className="lineItemDetailText">
														{mli.quantity} {mli.unitType} of {mli.description}
														{mli.partNumber ? ` (${mli.partNumber})` : null} @{' '}
														{currency.format(parseFloat(mli.unitPrice))} / {mli.unitType}
													</span>
													<span className="lineItemDetailText">
														{currency.format(parseFloat(mli.amount))}
													</span>
												</div>
											))}
											<Divider />
											<div className="sectionHeaderWrapper">
												<span className="sectionHeaderText">Travel</span>
												<span className="sectionHeaderText2">
													{currency.format(parseFloat(quote.travelTotalBeforeTax) || 0)}
												</span>
											</div>
											<Divider />
											<div className="sectionHeaderWrapper">
												<span className="sectionHeaderText">Freight</span>
												<span className="sectionHeaderText2">
													{currency.format(parseFloat(quote.freightTotalBeforeTax) || 0)}
												</span>
											</div>
											<Divider />
											<div className="sectionHeaderWrapper">
												<span className="sectionHeaderText">Miscellaneous</span>
												<span className="sectionHeaderText2">
													{currency.format(
														parseFloat(nullSafeGet(`miscTotalBeforeTax`, quote)) || 0
													)}
												</span>
											</div>
											<Divider />
											<div className="sectionHeaderWrapper">
												<span className="sectionHeaderText">Total Before Tax</span>
												<span className="sectionHeaderText2">
													{currency.format(quote.totalBeforeTax)}
												</span>
											</div>
											<Divider />
											<div className="sectionHeaderWrapper">
												<span className="sectionHeaderText">Tax</span>
												<span className="sectionHeaderText2">{currency.format(quote.tax)}</span>
											</div>
											<Divider />
											<div className="sectionHeaderWrapper">
												<span className="sectionHeaderText" style={{ fontWeight: 600 }}>
													Total After Tax
												</span>
												<span className="sectionHeaderText2" style={{ fontWeight: 600 }}>
													{currency.format(quote.totalAfterTax)}
												</span>
											</div>
										</Card>
									</Col>
								</Row>
							);
						})}
					</div>
				) : (
					<EmptyState
						graphic={
							<img
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/letter+owl.svg"
								alt=""
							/>
						}
						headline={
							isInternalTech
								? "You haven't received a quote for this work order yet."
								: "You haven't submitted a proposal yet."
						}
						body={
							isInternalTech ? (
								<AccessPermissionChecker
									name={PERMISSION_NAMES.CAN_UPLOAD_QUOTES_FOR_SUPPLIER}
									exclude3rdPartySuppliers
								>
									{CreateQuoteButton}
								</AccessPermissionChecker>
							) : (
								CreateQuoteButton
							)
						}
					/>
				)}
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	proposalsFetching: state.proposals.fetching,
	proposals: state.proposals,
	quote: state.proposals.detail,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
	workOrder: getEntityById(ownProps.match.params.id, state.work_orders),
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	appendWorkOrderNotes: (entity) => dispatch(appendWorkOrderNotesForSupplier(entity)),
	approveQuote: (entity) => dispatch(proposalStatusChangeForInternalSupplier(entity, 'awarded')),
	declineQuote: (entity) => dispatch(proposalStatusChangeForInternalSupplier(entity, 'declined')),
	syncQuote: (entity) => dispatch(syncProposalApprovalHierarchy(entity)),
	getWorkOrder: (id) => dispatch(workOrdersRestCrudThunksForSupplier.readOne(id)),
	getQuotes: (params, targetCollectionName) =>
		dispatch(proposalsRestCrudThunksForSupplier.read(params, targetCollectionName)),
	getQuote: (id) => dispatch(quotesRestCrudThunksForSupplier.readOne(id)),
	updateQuote: (entity) => dispatch(quotesRestCrudThunksForSupplier.update(entity)),
});

const ComponentWithoutUserType = withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(SupplierWorkOrderQuotesDetailPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import React, { FC, useState, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { locationsRestCrudThunksForSupplier } from '../../thunks/locations_thunks';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import UploadWithSampleModal from '../parts_index_page/UploadWithSampleModal';
import { ExportToCsv } from 'export-to-csv';
import { CSV_EXPORT_DEFAULTS } from '../../utils/DataConstants';
import { Button, Dropdown, Menu, Spin, message } from 'antd';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import moment from 'moment';
import {
	assetsRestCrudThunksForSupplier,
	createAssetFromEquipmentPerStockLocationBulkForSupplier,
	getAllAssetsForBulkUpdateForSupplier,
} from '../../thunks/assets_thunks';
import { bulkTransferEquipmentsPerStockLocactionForSupplier } from '../../thunks/equipment_per_stock_locations_thunks';
import { equipmentTypesRestCrudThunksForSupplier } from '../../thunks/equipment_types_thunks';
import {
	TRANSFER_TO_LOCATION_EQUIPMENT_FLATFILE_HEADER,
	TRANSFER_TO_STOCK_LOCATION_EQUIPMENT_FLATFILE_HEADER,
} from '../stock_locations_detail_details_page/stock_location_constants';
import { assetModelsRestCrudThunksForSupplier } from '../../thunks/asset_models_thunks';
import { assetTypesRestCrudThunksForSupplier } from '../../thunks/asset_types_thunks';

const BE_DATE_FORMAT = 'MM/DD/yyyy';

const BulkTransferSLButton: FC<any> = ({
	fetchAllLocations,
	fetchAllStockLocations,
	bulkTransferEquipments,
	onSuccess,
	bulkTransferEquipmentsToLocations,
	fetchAssetTypes,
	fetchAssetModels,
	fetchAllAssets,
	companyConfig,
}): React.ReactElement => {
	const [transferToStockLocationPopupVisible, setTransferToStockLocationPopupVisible] =
		useState(false);
	const [transferToLocationPopupVisible, setTransferToLocationPopupVisible] = useState(false);
	const [initiatingTransfer, setInitiatingTransfer] = useState(false);
	const [allLocations, setAllLocations] = useState([]);
	const [allStockLocations, setAllStockLocations] = useState([]);
	const [allAssetTypes, setAllAssetTypes] = useState([]);
	const [allAssets, setAllAssets] = useState([]);
	const [allAssetModels, setAllAssetModels] = useState([]);

	const transferNoteRequired = useMemo(
		() => nullSafeGetOrElse('inventoryConfig.transferNoteMandatory', companyConfig, false),
		[companyConfig]
	);

	const _showTransferToLocationPopup = useCallback(() => {
		setInitiatingTransfer(true);
		const promises = [fetchAssetTypes(), fetchAllLocations(), fetchAssetModels(), fetchAllAssets()];
		Promise.allSettled(promises)
			.then((res) => {
				const [
					{ value: assetTypes = [] },
					{ value: locations = [] },
					{ value: assetModels = [] },
					{ value: assets = [] },
				] = res;
				setAllAssetTypes(assetTypes);
				setAllLocations(locations);
				setAllAssetModels(assetModels);
				setAllAssets(assets);
				setTransferToLocationPopupVisible(true);
			})
			.catch((err) => message.error(err))
			.finally(() => setInitiatingTransfer(false));
	}, [fetchAllLocations, fetchAssetModels, fetchAssetTypes]);

	const _hideTransferToLocationPopup = () => setTransferToLocationPopupVisible(false);

	const _showTransferToSLPopup = useCallback(() => {
		setInitiatingTransfer(true);
		fetchAllStockLocations()
			.then((res) => {
				setAllStockLocations(res);
				setTransferToStockLocationPopupVisible(true);
			})
			.catch((err) => message.error(err))
			.finally(() => setInitiatingTransfer(false));
	}, [fetchAllStockLocations]);

	const _hideTransferToSLPopup = () => setTransferToStockLocationPopupVisible(false);

	const bulkTransferMenu = useMemo(
		() => (
			<Menu>
				<Menu.Item onClick={_showTransferToSLPopup}>To Stock Location</Menu.Item>
				<Menu.Item onClick={_showTransferToLocationPopup}>To Location</Menu.Item>
			</Menu>
		),
		[_showTransferToLocationPopup, _showTransferToSLPopup]
	);

	const onTransferToStockLocation = useCallback(
		(results) => {
			return new Promise((resolve, reject) => {
				bulkTransferEquipments(
					results.validData.map((obj) => ({
						...obj,
						id: `${obj.id}`,
						transferDate: obj.transferDate ? obj.transferDate : undefined,
					}))
				)
					.then(() => {
						const msg = 'Transfer initiated successfully!';
						resolve(msg);
						setTimeout(() => {
							_hideTransferToSLPopup();
							message.success(msg);
							onSuccess && onSuccess();
						}, 0);
					})
					.catch((err) => {
						reject(err || 'Unable to transfer! Please contact support!');
					});
			});
		},
		[bulkTransferEquipments, onSuccess]
	);

	const onTransferToLocation = useCallback(
		(results) => {
			return new Promise((resolve, reject) => {
				bulkTransferEquipmentsToLocations(
					results.validData.map((obj) => ({
						...obj,
						equipmentPerStockLocationId: parseInt(obj.id),
						id: undefined,
						transferDate: obj.transferDate ? obj.transferDate : undefined,
					}))
				)
					.then(() => {
						const msg = 'Transfer initiated successfully!';
						resolve(msg);
						setTimeout(() => {
							_hideTransferToLocationPopup();
							message.success(msg);
							onSuccess && onSuccess();
						}, 0);
					})
					.catch((err) => {
						reject(err || 'Unable to transfer! Please contact support!');
					});
			});
		},
		[bulkTransferEquipmentsToLocations, onSuccess]
	);

	const allLocationNames = useMemo(
		() =>
			allLocations.map((_) => ({
				label: _.name,
				value: _.id,
			})),
		[allLocations]
	);

	const allStockLocationNames = useMemo(
		() =>
			allStockLocations.map((_) => ({
				label: _.name,
				value: _.id,
			})),
		[allStockLocations]
	);

	const allAssetTypesNames = useMemo(
		() =>
			allAssetTypes.map((_) => ({
				label: _.name,
				value: _.id,
			})),
		[allAssetTypes]
	);

	const checkForUniqueSerialNumberAndLocation = (record = {}) => {
		const asset = allAssets.find(
			(asset) => asset.assetNumber == record.assetNumber && asset.locationId == record.locationId
		);
		if (asset) {
			return 'Asset Already Exists in the location with this Asset Number!';
		}
		return null;
	};

	const flatfileConfig = useMemo(
		() =>
			TRANSFER_TO_LOCATION_EQUIPMENT_FLATFILE_HEADER(
				allLocationNames,
				allAssetTypesNames,
				allAssetModels.map((_) => ({ label: _.modelName, value: _.id })),
				transferNoteRequired
			),
		[allLocationNames, allAssetTypesNames, allAssetModels, transferNoteRequired]
	);

	const onRecordChange = (record) => {
		const validation = checkForUniqueSerialNumberAndLocation(record);

		if (validation) {
			return { ...record, assetNumber: { info: [{ level: 'error', message: validation }] } };
		}
		return record;
	};

	const onDownloadLocationSample = useCallback(() => {
		const csvTitle = `bulk_transfer_equipment_to_location_sample`;
		const csvExporter = new ExportToCsv({
			...CSV_EXPORT_DEFAULTS,
			filename: csvTitle,
			title: csvTitle,
			headers: flatfileConfig.map((_) => _.label),
		});

		const sl1 = nullSafeGetOrElse('0.name', allStockLocations, 'Location - 1');
		const sl2 = nullSafeGetOrElse('1.name', allStockLocations, 'Location - 2');

		const data = [
			{
				id: '1234',
				locationId: sl1,
				assetTypeId: nullSafeGetOrElse('0.id', allAssetTypes, 'Asset Type - 1'),
				assetNumber: nullSafeGetOrElse('0.assetNumber', allAssets, 'Asset - 1'),
				assetModelId: nullSafeGetOrElse('0.id', allAssetModels, 'Asset Model - 1'),
				transferNote: '',
				transferDate: moment().subtract(1, 'months').endOf('month').format(BE_DATE_FORMAT),
			},
			{
				id: '5678',
				locationId: sl2,
				assetTypeId: nullSafeGetOrElse('0.id', allAssetTypes, 'Asset Type - 2'),
				assetNumber: nullSafeGetOrElse('0.assetNumber', allAssets, 'Asset - 1'),
				assetModelId: nullSafeGetOrElse('0.id', allAssetModels, 'Asset Model - 1'),
				transferNote: 'This is requested by Mr. XXX',
				transferDate: '',
			},
		];

		csvExporter.generateCsv(data);
	}, [flatfileConfig, allStockLocations, allAssetTypes, allAssets, allAssetModels]);

	const onDownloadSLSample = useCallback(() => {
		const csvTitle = `bulk_transfer_equipment_to_stock_location_sample`;
		const csvExporter = new ExportToCsv({
			...CSV_EXPORT_DEFAULTS,
			filename: csvTitle,
			title: csvTitle,
			headers: TRANSFER_TO_STOCK_LOCATION_EQUIPMENT_FLATFILE_HEADER(
				allStockLocationNames,
				transferNoteRequired
			).map((_) => _.label),
		});

		const loc1 = nullSafeGetOrElse('0.name', allStockLocations, 'Stock location - 1');
		const loc2 = nullSafeGetOrElse('1.name', allStockLocations, 'Stock location - 2');

		const data = [
			{
				equipmentPerStockLocationId: '1234',
				stockLocationId: loc1,
				tranferNOte: 'Transfer Requested by Mr. XXX',
				transferDate: moment().subtract(1, 'months').endOf('month').format(BE_DATE_FORMAT),
			},
			{
				equipmentPerStockLocationId: '5678',
				stockLocationId: loc2,
				...(transferNoteRequired && { tranferNOte: 'Transfer Requested by Mr. XXX' }),
				transferDate: '',
			},
		];

		csvExporter.generateCsv(data);
	}, [allStockLocationNames, allStockLocations, transferNoteRequired]);

	return (
		<>
			<Dropdown overlay={bulkTransferMenu}>
				<Button key={1} ghost={true}>
					<div className="flex flex-row items-center">
						<span>Bulk Transfer</span>
						<div className="ml-2">
							<DownOutlined translate="" />
						</div>
						{initiatingTransfer ? (
							<div className="ml-2">
								<Spin
									indicator={
										<LoadingOutlined translate="" style={{ fontSize: 16, color: '#fff' }} spin />
									}
								/>
							</div>
						) : null}
					</div>
				</Button>
			</Dropdown>
			{transferToStockLocationPopupVisible && (
				<UploadWithSampleModal
					type="Equipments"
					title="Transfer Equipments to Stock location"
					downloadText="Do you want to download sample file?"
					sampleFileText="Download Sample file"
					onCancel={_hideTransferToSLPopup}
					onDownloadSample={onDownloadSLSample}
					fields={TRANSFER_TO_STOCK_LOCATION_EQUIPMENT_FLATFILE_HEADER(
						allStockLocationNames,
						transferNoteRequired
					)}
					onDataLoad={onTransferToStockLocation}
				/>
			)}
			{transferToLocationPopupVisible && (
				<UploadWithSampleModal
					type="Equipments"
					title="Transfer Equipments to location"
					downloadText="Do you want to download sample file?"
					sampleFileText="Download Sample file"
					onCancel={_hideTransferToLocationPopup}
					onDownloadSample={onDownloadLocationSample}
					fields={flatfileConfig}
					onDataLoad={onTransferToLocation}
					onRecordInit={onRecordChange}
					onRecordChange={onRecordChange}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	fetchAllLocations: () =>
		dispatch(locationsRestCrudThunksForSupplier.readLite({ no_pagination: true })),
	fetchAllAssets: (params) => dispatch(getAllAssetsForBulkUpdateForSupplier(params)),
	fetchAssetModels: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			assetModelsRestCrudThunksForSupplier.read(
				{ ...params, no_pagination: true },
				'bulkEditAssetsAssetModels',
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchAssetTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			assetTypesRestCrudThunksForSupplier.readLite(
				{ ...params, no_pagination: true },
				'bulkEditAssetsAssetTypes',
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchAllStockLocations: () =>
		dispatch(stockLocationsRestCrudThunksForSupplier.readLite({ no_pagination: true })),
	fetchAllEquipmentTypes: () =>
		dispatch(equipmentTypesRestCrudThunksForSupplier.readLite({ no_pagination: true })),
	bulkTransferEquipments: (entities) =>
		dispatch(bulkTransferEquipmentsPerStockLocactionForSupplier(entities)),
	bulkTransferEquipmentsToLocations: (entities) =>
		dispatch(createAssetFromEquipmentPerStockLocationBulkForSupplier(entities)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkTransferSLButton);

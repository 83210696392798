import * as React from 'react';
import { Layout, Row, Col, Card, Button } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	activatePlannedMaintenanceScheduleForBuyer,
	activatePlannedMaintenanceScheduleForSupplier,
	deactivatePlannedMaintenanceScheduleForBuyer,
	deactivatePlannedMaintenanceScheduleForSupplier,
	pausePlannedMaintenanceScheduleForBuyer,
	pausePlannedMaintenanceScheduleForSupplier,
	plannedMaintenanceSchedulesRestCrudThunksForBuyer,
	plannedMaintenanceSchedulesRestCrudThunksForSupplier,
	plannedMaintenanceSkipScheduleForBuyer,
	plannedMaintenanceSkipScheduleForSupplier,
} from '../../thunks/planned_maintenance_schedules_thunks';
import {
	changeFilterValueToArrayOfIds,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { EmptyState } from '../empty_state/EmptyState';
import { PlannedMaintenanceScheduleRowDisplay } from '../planned_maintenance_schedule_row_display/PlannedMaintenanceScheduleRowDisplay';
import { plannedMaintenanceSchedulesStatusIndicatorFormatter } from '../../utils/DataFormatterUtils';
import {
	plannedMaintenanceTemplatesRestCrudThunksForBuyer,
	plannedMaintenanceTemplatesRestCrudThunksForSupplier,
} from '../../thunks/planned_maintenance_templates_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import SubnavBar from '../subnav_bar/SubnavBar';
import { AdvancedFilters } from '../advanced_filters/AdvancedFilters';
import { Form } from '@ant-design/compatible';
import RegionsLocationsTreeSelect from '../regions_tree_select/BuyerRegionsLocationsTreeSelect';
import { PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS } from '../../actions/planned_maintenance_schedules_actions';
import OWAsyncTreeSelect from '../ow_async_tree_select/OWAsyncTreeSelect';
import OWRadioGroup from '../ow_radio_group/OWRadioGroup';
import {
	locationsRestCrudThunksForBuyer,
	locationsRestCrudThunksForSupplier,
} from '../../thunks/locations_thunks';
import {
	regionsRestCrudThunksForBuyer,
	regionsRestCrudThunksForSupplier,
} from '../../thunks/regions_thunks';
import {
	problemTypesRestCrudThunksForBuyer,
	problemTypesRestCrudThunksForSupplier,
} from '../../thunks/problem_types_thunks';
import {
	assetTypesRestCrudThunksForBuyer,
	assetTypesRestCrudThunksForSupplier,
} from '../../thunks/asset_types_thunks';
import {
	assetsRestCrudThunksForBuyer,
	assetsRestCrudThunksForSupplier,
	fetchAssetsGroupedByLocationForBuyer,
	fetchAssetsGroupedByLocationForSupplier,
} from '../../thunks/assets_thunks';
import {
	fetchSupplierFacilitiesInPrivateNetworkForBuyer,
	fetchSupplierFacilitiesInPrivateNetworkForSupplier,
	supplierFacilitiesRestCrudThunksForBuyer,
	supplierFacilitiesRestCrudThunksForSupplier,
} from '../../thunks/supplier_facilities_thunks';
import SelectedFilters from '../selected_filters_pills/SelectedFilters';
import LocationsTreeSelect from '../locations_tree_select/LocationsTreeSelect';
import { FILTER_CONFIG_NAMES, FILTER_FIELD_TYPE, ROLE_TYPES } from '../../utils/DataConstants';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';

const PLANNED_MAINTENANCE_SCHEDULE_DETAIL_COLLECTION_NAME = 'PLANNED_MAINTENANCE_SCHEDULE_DETAIL';

const { Content } = Layout;

require('./PlannedMaintenanceSchedulesDetailDetailsPage.less');
const queryString = require('qs');

class PlannedMaintenanceSchedulesDetailPage extends React.Component<any, any> {
	initialPagination: any = { current: 1, pageSize: 10 };
	initialFilters: any = {};
	preAppliedFilters: any = {};

	constructor(props) {
		super(props);
		const { location, updatePlannedMaintenanceFilters, history, match } = this.props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		const { title, frequency, plannedMaintenanceTemplateId, basedOn } = match.params;
		const { current, pageSize, backlinkParams, ...plannedMaintenanceFilters } = queryParams;
		const {
			current: backCurrent,
			pageSize: backPageSize,
			sort_by: backSortBy,
			order: backOrder,
			locationIds: locationIdFilter,
			assetIds: assetIdFilter,
			assetTypeIds: assetTypeIdFilter,
			...passThroughFilters
		} = queryString.parse(backlinkParams);

		this.initialPagination = {
			...this.initialPagination,
			current: current ? current : this.initialPagination.current,
			pageSize: pageSize ? pageSize : this.initialPagination.pageSize,
		};
		this.preAppliedFilters = {
			title: decodeURIComponent(title),
			frequency,
			plannedMaintenanceTemplateId,
			basedOn,
		};
		this.initialFilters = {
			...plannedMaintenanceFilters,
			locationIds: locationIdFilter,
			assetIds: assetIdFilter,
			assetTypeIds: assetTypeIdFilter,
		};
		updatePlannedMaintenanceFilters(
			{ ...this.initialFilters, ...this.preAppliedFilters },
			PLANNED_MAINTENANCE_SCHEDULE_DETAIL_COLLECTION_NAME
		);
	}

	componentDidMount() {
		const { match, getPlannedMaintenanceTemplate } = this.props;
		const plannedMaintenanceTemplateId = match.params.plannedMaintenanceTemplateId;
		getPlannedMaintenanceTemplate(plannedMaintenanceTemplateId);
	}

	handleEdit = (plannedMaintenanceSchedule, e) => {
		const { updatePlannedMaintenanceSchedule, history, userType } = this.props;
		history.push(
			`/${userType}/plannedMaintenance/edit/${nullSafeGet('id', plannedMaintenanceSchedule)}`
		);
	};

	handlePause = (plannedMaintenanceSchedule, e) => {
		const { pause } = this.props;
		pause(plannedMaintenanceSchedule);
	};

	handleSkip = (plannedMaintenanceSchedule, e) => {
		const { skip } = this.props;
		skip(plannedMaintenanceSchedule);
	};

	handleDelete = (plannedMaintenanceSchedule, e) => {
		const { deletePlannedMaintenanceSchedule } = this.props;
		deletePlannedMaintenanceSchedule(plannedMaintenanceSchedule);
	};

	handleDeactivate = (plannedMaintenanceSchedule, e) => {
		const { deactivate } = this.props;
		deactivate(plannedMaintenanceSchedule);
	};

	handleActivate = (plannedMaintenanceSchedule, e) => {
		const { activate } = this.props;
		activate(plannedMaintenanceSchedule);
	};

	render() {
		const {
			assets,
			assetTypes,
			locations,
			regions,
			activeFilters,

			fetchAssets,
			fetchAssetTypes,
			fetchMultipleAssetTypes,
			fetchLocations,
			fetchMultipleLocations,
			fetchRegions,
			fetchMultipleRegions,
			fetchAssetsByLocation,

			updatePlannedMaintenanceFilters,
			clearAndUpdateFilters,

			plannedMaintenanceSchedule,
			plannedMaintenanceTemplatesFetching,
			plannedMaintenanceTemplate,
			match,
			userType,
		} = this.props;
		const plannedMaintenanceScheduleTitle = match.params.title;
		const plannedMaintenanceScheduleFrequency = match.params.frequency;
		const plannedMaintenanceTemplateId = match.params.plannedMaintenanceTemplateId;
		const basedOn = match.params.basedOn;

		const getPlannedMaintenanceScheduleActions = (plannedMaintenanceSchedule) => {
			const edit = (
				<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PM}>
					<Button
						style={{ marginRight: 8 }}
						key="pause"
						onClick={(e) => this.handleEdit(plannedMaintenanceSchedule, e)}
					>
						<i className="icons8-font icons8-edit"></i> Edit
					</Button>
				</AccessPermissionChecker>
			);
			const pause = (
				<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PM}>
					<Button
						style={{ marginRight: 8 }}
						key="pause"
						onClick={(e) => this.handlePause(plannedMaintenanceSchedule, e)}
					>
						<i className="icons8-font icons8-pause"></i> Pause
					</Button>
				</AccessPermissionChecker>
			);
			const activate = (
				<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PM}>
					<Button
						style={{ marginRight: 8 }}
						key="activate"
						onClick={(e) => this.handleActivate(plannedMaintenanceSchedule, e)}
					>
						<i className="icons8-font icons8-plus"></i> Activate
					</Button>
				</AccessPermissionChecker>
			);
			const deactivate = (
				<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PM}>
					<Button
						style={{ marginRight: 8 }}
						key="deactivate"
						onClick={(e) => this.handleDeactivate(plannedMaintenanceSchedule, e)}
					>
						<i className="icons8-font icons8-cancel"></i> Deactivate
					</Button>
				</AccessPermissionChecker>
			);
			const skip = (
				<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PM}>
					<Button
						style={{ marginRight: 8 }}
						key="skip"
						onClick={(e) => this.handleSkip(plannedMaintenanceSchedule, e)}
					>
						<i className="icons8-font icons8-clock"></i> Skip
					</Button>
				</AccessPermissionChecker>
			);
			const deleteSchedule = (
				<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PM}>
					<Button
						style={{ marginRight: 8 }}
						key="delete"
						onClick={(e) => this.handleDelete(plannedMaintenanceSchedule, e)}
					>
						<i className="icons8-font icons8-trash-can"></i> Delete
					</Button>
				</AccessPermissionChecker>
			);
			switch (plannedMaintenanceSchedule.status) {
				case 'active':
					return [edit, pause, deactivate, skip, deleteSchedule];
				case 'inactive':
					return [edit, activate, deleteSchedule];
				case 'expired':
					return [edit, activate, deleteSchedule];
				case 'paused':
					return [edit, activate, deactivate, deleteSchedule];
				default:
					return [edit, deleteSchedule];
			}
		};

		const { locationIds, assetTypeIds, assetIds } = nullSafeGet(
			`${PLANNED_MAINTENANCE_SCHEDULE_DETAIL_COLLECTION_NAME}.filters`,
			plannedMaintenanceSchedule
		);
		const filterConfig = [
			{
				label: 'Assets',
				fieldName: FILTER_CONFIG_NAMES.ASSET,
				mode: 'multiple',
				hideFilter: basedOn !== 'asset',
				stateSlice: assets,
				filtersValueAccessor: (filtersStateSlice) => {
					const ids = nullSafeGet(
						`${PLANNED_MAINTENANCE_SCHEDULE_DETAIL_COLLECTION_NAME}.filters.assetIds`,
						filtersStateSlice
					);
					return changeFilterValueToArrayOfIds(ids);
				},
				keyAccessor: (asset) => asset.id,
				valueAccessor: (asset) => asset.id,
				labelAccessor: (asset) => asset.name,
				handleChange: (ids) => {
					updatePlannedMaintenanceFilters(
						{
							assetIds: ids.join(',') || undefined,
							basedOn: nullSafeGetOrElse('length', ids, 0) > 0 ? 'asset' : undefined,
						},
						PLANNED_MAINTENANCE_SCHEDULE_DETAIL_COLLECTION_NAME
					);
				},
				targetCollectionName: 'locationAssociatedAssets',
				fetch: fetchAssetsByLocation,
				fetchMultiple: () => {},
				renderItem: (asset) => asset.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
				component: LocationsTreeSelect,
			},
			{
				label: 'Asset Types',
				fieldName: FILTER_CONFIG_NAMES.ASSET_TYPE,
				hideFilter: basedOn !== 'assetType',
				mode: 'multiple',
				stateSlice: assetTypes,
				filtersValueAccessor: (filtersStateSlice) => {
					const assetTypeIds = nullSafeGet(
						`${PLANNED_MAINTENANCE_SCHEDULE_DETAIL_COLLECTION_NAME}.filters.assetTypeIds`,
						filtersStateSlice
					);
					return changeFilterValueToArrayOfIds(assetTypeIds);
				},
				keyAccessor: (assetType) => assetType.id,
				valueAccessor: (assetType) => assetType.id,
				handleChange: (ids) => {
					updatePlannedMaintenanceFilters(
						{
							assetTypeIds: ids.join(',') || undefined,
							basedOn: nullSafeGetOrElse('length', ids, 0) > 0 ? 'assetType' : undefined,
						},
						PLANNED_MAINTENANCE_SCHEDULE_DETAIL_COLLECTION_NAME
					);
				},
				targetCollectionName: 'plannedMaintenanceAdvancedFilters',
				fetch: fetchAssetTypes,
				fetchMultiple: fetchMultipleAssetTypes,
				renderItem: (assetType) => assetType.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
				component: OWAsyncTreeSelect,
			},
			{
				label: 'Location',
				fieldName: FILTER_CONFIG_NAMES.LOCATION,
				mode: 'multiple',
				stateSlice: locations,
				hideFilter: true,
				filtersValueAccessor: (filtersStateSlice) => {
					const ids = nullSafeGet(
						`${PLANNED_MAINTENANCE_SCHEDULE_DETAIL_COLLECTION_NAME}.filters.locationIds`,
						filtersStateSlice
					);
					return changeFilterValueToArrayOfIds(ids);
				},
				keyAccessor: (loc) => loc.id,
				valueAccessor: (loc) => loc.id,
				handleChange: (ids) => {
					updatePlannedMaintenanceFilters(
						{
							locationIds: ids.join(',') || undefined,
							basedOn: nullSafeGetOrElse('length', ids, 0) > 0 ? 'location' : undefined,
						},
						PLANNED_MAINTENANCE_SCHEDULE_DETAIL_COLLECTION_NAME
					);
				},
				targetCollectionName: 'plannedMaintenanceAdvancedFilters',
				fetch: fetchLocations,
				fetchMultiple: fetchMultipleLocations,
				renderItem: (loc) => loc.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
			},
			{
				fieldName: 'Based On',
				hideFilter: true,
				type: 'radioGroup',
				items: [
					{
						label: 'Location',
						value: undefined,
					},
					{
						label: 'Asset',
						value: 'false',
					},
					{
						label: 'Asset Type',
						value: 'true',
					},
				],
				filtersValueAccessor: (filtersStateSlice) => {
					const val = filtersStateSlice.isCapex;
					return typeof val === 'undefined' ? undefined : `${val}`;
				},
				keyAccessor: (item) => item.value,
				valueAccessor: (item) => item.value,
				handleChange: (basedOn) => {
					const val = basedOn ? basedOn === 'true' : undefined;
					updatePlannedMaintenanceFilters({ basedOn: val });
				},
				targetCollectionName: 'plannedMaintenanceAdvancedFilters',
				renderRecord: (item) => item.label,
				component: OWRadioGroup,
			},
			{
				label: 'Region',
				mode: 'multiple',
				stateSlice: regions,
				hideFilter: true,
				filtersValueAccessor: (filtersStateSlice) => {
					const ids = nullSafeGet(
						`${PLANNED_MAINTENANCE_SCHEDULE_DETAIL_COLLECTION_NAME}.filters.regionIds`,
						filtersStateSlice
					);
					return changeFilterValueToArrayOfIds(ids);
				},
				keyAccessor: (region) => region.id,
				valueAccessor: (region) => region.id,
				handleChange: (ids) => {
					updatePlannedMaintenanceFilters(
						{
							regionIds: ids.join(',') || undefined,
						},
						PLANNED_MAINTENANCE_SCHEDULE_DETAIL_COLLECTION_NAME
					);
				},
				targetCollectionName: 'plannedMaintenanceAdvancedFilters',
				fetch: fetchRegions,
				fetchMultiple: fetchMultipleRegions,
				renderItem: (region) => region.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
			},
		];

		const plannedMaintenanceScheduleColumns = [
			{
				title: 'title',
				dataIndex: 'title',
				render: (text, record) => (
					<PlannedMaintenanceScheduleRowDisplay
						plannedMaintenanceSchedule={record}
						userType={userType}
					/>
				),
			},
			{
				title: 'status',
				dataIndex: 'status',
				render: (text, record) => (
					<div className="plannedMaintenanceSchedulesRowDisplay">
						<div className="plannedMaintenanceSchedulesRowDisplay__rowInlineGroup">
							{plannedMaintenanceSchedulesStatusIndicatorFormatter(text)}
						</div>
					</div>
				),
			},
			{
				title: 'id',
				dataIndex: 'id',
				render: (text, record) => getPlannedMaintenanceScheduleActions(record),
			},
		];

		return (
			<Content
				className="plannedMaintenanceSchedulesDetailDetailsPage"
				style={{ marginTop: '-8px' }}
			>
				<LogOnMountWithStandardEventProperties
					eventType={`visited ${userType} pm schedule detail page`}
				/>
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '0em 10px' }}>
					<Col span={24}>
						<SubnavBar
							left={
								<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
									<AdvancedFilters
										showSearch={false}
										updateFilters={updatePlannedMaintenanceFilters}
										filterConfig={filterConfig}
										filtersTargetCollectionName={
											PLANNED_MAINTENANCE_SCHEDULE_DETAIL_COLLECTION_NAME
										}
										clearAndUpdateFilters={clearAndUpdateFilters}
										filtersStateSlice={plannedMaintenanceSchedule}
										preAppliedFilters={this.preAppliedFilters}
										initialFilters={this.initialFilters}
										inputParamName={'name'}
									>
										{basedOn === 'location' ? (
											<Form.Item label="Location">
												<RegionsLocationsTreeSelect
													mode="multiple"
													filtersSlice={plannedMaintenanceSchedule}
													filtersValueAccessor={(filtersStateSlice) => {
														const locationIds = nullSafeGet(
															`${PLANNED_MAINTENANCE_SCHEDULE_DETAIL_COLLECTION_NAME}.filters.locationIds`,
															filtersStateSlice
														);
														const finalIds = nullSafeGetOrElse(
															`${PLANNED_MAINTENANCE_SCHEDULE_DETAIL_COLLECTION_NAME}.filters.regionIds`,
															filtersStateSlice,
															locationIds
														);
														return changeFilterValueToArrayOfIds(finalIds);
													}}
													onChange={(ids) => {
														fetchAssets(
															{ locationIds: ids.join(',') || undefined },
															'plannedMaintenanceAdvancedFilters'
														);
														updatePlannedMaintenanceFilters(
															{
																locationIds: ids.join(',') || undefined,
																regionIds: undefined,
																basedOn:
																	nullSafeGetOrElse('length', ids, 0) > 0 ? 'location' : undefined,
															},
															PLANNED_MAINTENANCE_SCHEDULE_DETAIL_COLLECTION_NAME
														);
													}}
													targetCollectionName={'plannedMaintenanceAdvancedFilters'}
												/>
											</Form.Item>
										) : null}
									</AdvancedFilters>
								</div>
							}
							right={
								<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PM}>
									<Button
										size="large"
										type="primary"
										onClick={() =>
											this.props.history.push(
												`/${userType}/plannedMaintenance/${encodeURIComponent(
													plannedMaintenanceScheduleTitle
												)}/${plannedMaintenanceScheduleFrequency}/${plannedMaintenanceTemplateId}/${basedOn}/new`
											)
										}
									>
										Add Schedule
									</Button>
								</AccessPermissionChecker>
							}
						/>
					</Col>
				</Row>
				<Row style={{ margin: '7px 10px' }}>
					<SelectedFilters
						updateFilters={(filters) =>
							updatePlannedMaintenanceFilters(
								filters,
								PLANNED_MAINTENANCE_SCHEDULE_DETAIL_COLLECTION_NAME
							)
						}
						filterValues={activeFilters}
						filterConfig={filterConfig}
					/>
				</Row>
				<Row style={{ margin: '7px 4px 0' }} gutter={12}>
					<Col span={24}>
						<div className="rowSpacing">
							<Card
								title={
									basedOn === 'asset'
										? 'Scheduled Assets'
										: basedOn === 'location'
										? 'Scheduled Locations'
										: 'Scheduled Asset Types'
								}
							>
								<PaginatedReduxTable
									showHeader={false}
									emptyState={
										<EmptyState
											graphic={
												<img
													style={{ marginBottom: 8 }}
													src="https://s3.amazonaws.com/mock-data-assets/categories/images/ficus.svg"
												/>
											}
											headline={"It's lonely in here."}
											body={
												<div style={{ textAlign: 'center' }}>
													<div style={{ maxWidth: 440, marginBottom: 16 }}>
														You haven't assigned any locations to this PM schedule yet.
													</div>
												</div>
											}
										/>
									}
									collectionName="planned_maintenance_schedules"
									targetCollectionName={PLANNED_MAINTENANCE_SCHEDULE_DETAIL_COLLECTION_NAME}
									columns={plannedMaintenanceScheduleColumns}
									keyAccessor={(el) => el.id}
									initialFilters={{ ...this.initialFilters, ...this.preAppliedFilters }}
									initialPagination={{ current: 1, pageSize: 10 }}
									fetchData={
										userType === ROLE_TYPES.SUPPLIER
											? plannedMaintenanceSchedulesRestCrudThunksForSupplier.read
											: plannedMaintenanceSchedulesRestCrudThunksForBuyer.read
									}
								/>
							</Card>
						</div>
					</Col>
				</Row>
				{plannedMaintenanceTemplatesFetching ? null : (
					<Row style={{ margin: '0 4px 16px' }} gutter={12}>
						<Col span={24}>
							<div className="rowSpacing">
								<Card bodyStyle={{ padding: 0 }}>
									<div className="materialCard__body">
										<div className="materialCard__primaryTitle">
											<div className="materialCard__title">{plannedMaintenanceTemplate.title}</div>
										</div>
										<div
											style={{ fontSize: 16 }}
											className="richTextWrapper"
											dangerouslySetInnerHTML={{
												__html: nullSafeGet('description', plannedMaintenanceTemplate),
											}}
										/>
									</div>
								</Card>
							</div>
						</Col>
					</Row>
				)}
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	plannedMaintenanceSchedulesFetching: state.planned_maintenance_schedules.fetching,
	plannedMaintenanceSchedule: state.planned_maintenance_schedules,
	plannedMaintenanceTemplate: state.planned_maintenance_templates.detail,
	plannedMaintenanceTemplatesFetching: state.planned_maintenance_templates.fetching,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
	activeFilters:
		state.planned_maintenance_schedules[PLANNED_MAINTENANCE_SCHEDULE_DETAIL_COLLECTION_NAME]
			.filters,

	regions: state.regions,
	locations: state.locations,
	assets: state.assets,
	assetTypes: state.asset_types,
	problemTypes: state.problem_types,
	supplierFacilities: state.supplier_facilities,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	updatePlannedMaintenanceFilters: (filters, targetCollection) =>
		dispatch(
			PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)
		),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS.clearAndUpdateFilters(
				filters,
				targetCollectionName
			)
		),
	deactivate: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? deactivatePlannedMaintenanceScheduleForSupplier(entity)
				: deactivatePlannedMaintenanceScheduleForBuyer(entity)
		),
	activate: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? activatePlannedMaintenanceScheduleForSupplier(entity)
				: activatePlannedMaintenanceScheduleForBuyer(entity)
		),
	pause: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? pausePlannedMaintenanceScheduleForSupplier(entity)
				: pausePlannedMaintenanceScheduleForBuyer(entity)
		),
	skip: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? plannedMaintenanceSkipScheduleForSupplier(entity)
				: plannedMaintenanceSkipScheduleForBuyer(entity)
		),
	updatePlannedMaintenanceSchedule: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? plannedMaintenanceSchedulesRestCrudThunksForSupplier.update(entity)
				: plannedMaintenanceSchedulesRestCrudThunksForBuyer.update(entity)
		),
	deletePlannedMaintenanceSchedule: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? plannedMaintenanceSchedulesRestCrudThunksForSupplier.delete(entity, 'id', null, true)
				: plannedMaintenanceSchedulesRestCrudThunksForBuyer.delete(entity, 'id', null, true)
		),
	getPlannedMaintenanceTemplate: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? plannedMaintenanceTemplatesRestCrudThunksForSupplier.readOne(id)
				: plannedMaintenanceTemplatesRestCrudThunksForBuyer.readOne(id)
		),

	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: locationsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: locationsRestCrudThunksForBuyer.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchRegions: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? regionsRestCrudThunksForSupplier.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: regionsRestCrudThunksForBuyer.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultipleRegions: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? regionsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: regionsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchMultipleProblemTypes: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? problemTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: problemTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchProblemTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? problemTypesRestCrudThunksForSupplier.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: problemTypesRestCrudThunksForBuyer.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultipleAssetTypes: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: assetTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchAssetTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetTypesRestCrudThunksForSupplier.readLite(
						{ ...params, no_pagination: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: assetTypesRestCrudThunksForBuyer.readLite(
						{ ...params, no_pagination: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultipleAssets: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: assetsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchAssets: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetsRestCrudThunksForSupplier.readLite(
						{ ...params },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: assetsRestCrudThunksForBuyer.readLite(
						{ ...params },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultipleSupplierFacilities: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? supplierFacilitiesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: supplierFacilitiesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchSupplierFacilities: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? fetchSupplierFacilitiesInPrivateNetworkForSupplier(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: fetchSupplierFacilitiesInPrivateNetworkForBuyer(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchAssetsByLocation: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? fetchAssetsGroupedByLocationForSupplier(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: fetchAssetsGroupedByLocationForBuyer(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(PlannedMaintenanceSchedulesDetailPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

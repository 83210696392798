import React, { FC, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Select, message } from 'antd';
import {
	bulkApprovePurchaseRequestForSupplier,
	purchaseRequestsRestCrudThunksForSupplier,
	refreshPRCountsForSupplier,
} from '../../thunks/purchase_requests_thunks';
import { EmptyState } from '../empty_state/EmptyState';
import { PurchaseRequestRowDisplay } from '../purchase_request_row_display/PurchaseRequestRowDisplay';
import { PURCHASE_REQUESTS_CRUD_ACTION_CREATORS } from '../../actions/purchase_requests_actions';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import { usersRestCrudThunksForSupplier } from '../../thunks/users_thunks';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import PRPipeLineFilters from './PRPipelineFilters';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import PRSorters from './PRSorters';
import { getContactName } from '../../utils/DataFormatterUtils';
import {
	FILTER_CONFIG_NAMES,
	FILTER_FIELD_TYPE,
	FILTER_VALUE_TYPE,
} from '../../utils/DataConstants';
import { partsRestCrudThunksForSupplier } from '../../thunks/parts_thunks';
import { equipmentTypesRestCrudThunksForSupplier } from '../../thunks/equipment_types_thunks';
import {
	fetchWithDifferntSearchName,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { locationsRestCrudThunksForSupplier } from '../../thunks/locations_thunks';
import { isSupplierInventoryConfigEnabled } from '../common/SupplierInventoryConfigPermissionChecker';
import { CONFIG_NAMES } from '../../utils/AuthUtils';
import { EXCLUDE_PR_STATUSES_FOR_PO_DISABLED } from '../../constants/PurchaseRequestStatuses';
import { renderEquipmenTypeSelect } from '../purchase_order_form/ReplaceEquipmentLineItemModal';
import ReduxDataTable from '../redux_data_table/ReduxDataTable';

require('./PurchaseRequestsIndexPage.less');

const PR_INDEX_TARGET_COLLECTION = 'purchaseRequestsIndex';
const PR_ADVANCED_FILTERS = 'purchaseRequestsAdvancedFilters';

const PurchaseRequestsIndexPage: FC<any> = ({
	location,
	history,
	purchaseRequests,
	clearAndUpdateFilters,
	updatePRFilters,
	updatePRSorters,
	users,
	fetchUsers,
	fetchMultipleUsers,
	stockLocations,
	fetchStockLocations,
	fetchMultipleStockLocations,
	locations,
	fetchLocations,
	fetchMultipleLocations,
	refreshCounts,
	equipmentTypes,
	fetchEquipmentTypes,
	fetchMultipleEquipments,
	parts,
	fetchParts,
	fetchMultipleParts,
	bulkApprove,
	fetchPurchaseRequests,
	companyConfig,
}) => {
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [selectedRecords, setSelectedRecords] = useState([]);
	const [approving, setApproving] = useState(false);

	const getLink = useCallback(
		(path) => getLinkWIthBackLinkParams(location, 'Back to Purchase Requests', path),
		[location]
	);

	const onRow = useCallback(
		(record) => ({
			onClick: () => {
				const path = `/supplier/purchaseRequests/detail/${nullSafeGet('id', record)}/details`;
				history.push(
					getLinkWIthBackLinkParams(location, 'Back to Purchase Requests', path, {
						preserve: true,
						targetCollectionName: PR_INDEX_TARGET_COLLECTION,
					})
				);
			},
		}),
		[history, location]
	);

	const columns = useMemo(
		() => [
			{
				title: 'Name',
				dataIndex: 'name',
				sorter: true,
				render: (_, record) => <PurchaseRequestRowDisplay purchaseRequest={record} />,
			},
		],
		[]
	);

	const onSelectChange = useCallback((newSelectedRowKeys: React.Key[], selectedRows) => {
		setSelectedRowKeys(newSelectedRowKeys);
		setSelectedRecords(selectedRows);
	}, []);

	const rowSelection = useMemo(
		() => ({
			selectedRowKeys,
			preserveSelectedRowKeys: true,
			onChange: onSelectChange,
			getCheckboxProps: (record) => ({
				disabled: record.status !== 'requested',
			}),
		}),
		[onSelectChange, selectedRowKeys]
	);

	const poDisabled = useMemo(
		() => isSupplierInventoryConfigEnabled(companyConfig, CONFIG_NAMES.DISABLE_PURCHASE_ORDERS),
		[companyConfig]
	);

	const pipeLineFilters = useMemo(
		() =>
			poDisabled
				? PRPipeLineFilters.filter((_) => !EXCLUDE_PR_STATUSES_FOR_PO_DISABLED.includes(_.name))
				: PRPipeLineFilters,
		[poDisabled]
	);

	const filterConfig = useMemo(
		() => [
			{
				label: 'Stock Location',
				fieldName: 'stockLocationIds',
				mode: 'multiple',
				stateSlice: stockLocations,
				targetCollectionName: PR_ADVANCED_FILTERS,
				fetch: fetchStockLocations,
				fetchMultiple: fetchMultipleStockLocations,
			},
			{
				label: 'Location',
				fieldName: 'locationIds',
				mode: 'multiple',
				stateSlice: locations,
				targetCollectionName: PR_ADVANCED_FILTERS,
				fetch: fetchLocations,
				fetchMultiple: fetchMultipleLocations,
			},
			{
				label: 'Part',
				fieldName: 'partId',
				stateSlice: parts,
				targetCollectionName: PR_ADVANCED_FILTERS,
				fetch: fetchParts,
				fetchMultiple: fetchMultipleParts,
				renderRecord: (part) => (
					<Select.Option key={part.id} value={part.id}>
						<div className="flex flex-row items-center">
							<div>{part.name}</div>
							<div>
								{part.partNumber && (
									<span className="text-gray-400">&nbsp;{`- #${part.partNumber}`}</span>
								)}
							</div>
						</div>
					</Select.Option>
				),
				component: OWAsyncSelect,
			},
			{
				label: 'Equipment Type',
				fieldName: 'equipmentTypeId',
				stateSlice: equipmentTypes,
				targetCollectionName: PR_ADVANCED_FILTERS,
				fetch: fetchWithDifferntSearchName(fetchEquipmentTypes, 'search'),
				fetchMultiple: fetchMultipleEquipments,
				renderRecord: renderEquipmenTypeSelect,
				component: OWAsyncSelect,
			},
			{
				label: 'Requested by',
				fieldName: 'createdByEmails',
				mode: 'multiple',
				valueType: FILTER_VALUE_TYPE.STRING,
				stateSlice: users,
				valueAccessor: (user) => nullSafeGet('contact.email', user),
				targetCollectionName: PR_ADVANCED_FILTERS,
				fetch: fetchUsers,
				fetchMultiple: fetchMultipleUsers,
				renderItem: (user) => getContactName(user.contact),
			},
			{
				label: 'Approved by',
				fieldName: 'approvedByEmails',
				mode: 'multiple',
				valueType: FILTER_VALUE_TYPE.STRING,
				stateSlice: users,
				valueAccessor: (user) => nullSafeGet('contact.email', user),
				targetCollectionName: PR_ADVANCED_FILTERS,
				fetch: fetchUsers,
				fetchMultiple: fetchMultipleUsers,
				renderItem: (user) => getContactName(user.contact),
			},
			{
				label: 'Requested Between',
				labelPrefix: 'Requested',
				fieldName: FILTER_CONFIG_NAMES.CREATED_AT,
				type: FILTER_FIELD_TYPE.DATE_RANGE,
			},
			{
				label: 'Approved Between',
				labelPrefix: 'Approved',
				fieldName: FILTER_CONFIG_NAMES.APPROVED_AT,
				type: FILTER_FIELD_TYPE.DATE_RANGE,
			},
		],
		[
			equipmentTypes,
			fetchEquipmentTypes,
			fetchLocations,
			fetchMultipleEquipments,
			fetchMultipleLocations,
			fetchMultipleParts,
			fetchMultipleStockLocations,
			fetchMultipleUsers,
			fetchParts,
			fetchStockLocations,
			fetchUsers,
			locations,
			parts,
			stockLocations,
			users,
		]
	);

	const getStatSliceProp = useCallback(
		(propName) =>
			nullSafeGetOrElse(`${PR_INDEX_TARGET_COLLECTION}.${propName}`, purchaseRequests, {}),
		[purchaseRequests]
	);

	const refreshPRs = useCallback(() => {
		fetchPurchaseRequests(
			{},
			PR_INDEX_TARGET_COLLECTION,
			getStatSliceProp('pagination'),
			getStatSliceProp('sorting'),
			getStatSliceProp('filters')
		);
		refreshCounts(getStatSliceProp('filters'));
	}, [fetchPurchaseRequests, getStatSliceProp, refreshCounts]);

	const onSelectCancel = useCallback(() => {
		setSelectedRowKeys([]);
		setSelectedRecords([]);
	}, []);

	const onBulkApprove = useCallback(() => {
		const ids = selectedRecords.map((_) => _.id);
		setApproving(true);
		bulkApprove(ids)
			.then(() => {
				message.success('Approved successfully!');
				onSelectCancel();
				refreshPRs();
			})
			.catch((err) => {
				message.error('Unable to approve at this moment!');
			})
			.finally(() => setApproving(false));
	}, [bulkApprove, onSelectCancel, refreshPRs, selectedRecords]);

	const recordsSelected = useMemo(() => nullSafeGet('0', selectedRowKeys), [selectedRowKeys]);

	return (
		<ReduxDataTable
			targetCollectionName={PR_INDEX_TARGET_COLLECTION}
			updateFilters={updatePRFilters}
			stateSlice={purchaseRequests}
			refreshCounts={refreshCounts}
			pipeLineFilters={pipeLineFilters}
			updateSorters={updatePRSorters}
			clearAndUpdateFilters={clearAndUpdateFilters}
			sorters={PRSorters}
			filterConfig={filterConfig}
			entityCollectionName="purchase_requests"
			tableColumns={columns}
			onTableRow={!recordsSelected ? onRow : undefined}
			fetchData={purchaseRequestsRestCrudThunksForSupplier}
			rowSelection={rowSelection}
			handleQueryParams
			rightActions={
				recordsSelected ? (
					<div className="flex flex-row items-center">
						<Button key="bulk-cancel" type="ghost" size="large" onClick={onSelectCancel}>
							Cancel
						</Button>
						<Button
							key="bulk-approve"
							className="ml-2"
							type="primary"
							size="large"
							loading={approving}
							onClick={onBulkApprove}
						>
							Approve Selected PRs
						</Button>
					</div>
				) : (
					<div>
						<Button
							key="new-pr"
							style={{ marginLeft: 16 }}
							type="primary"
							size="large"
							onClick={() => history.push(getLink('/supplier/purchaseRequests/overview/new'))}
						>
							New Purchase Request
						</Button>
					</div>
				)
			}
			emptyState={
				<EmptyState
					graphic={
						<img
							alt="No purchase requests found"
							style={{ marginBottom: 8 }}
							src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
						/>
					}
					headline={'No purchase requests found'}
					body={
						<div style={{ textAlign: 'center' }}>
							<Button
								type="primary"
								onClick={() => history.push(getLink('/supplier/purchaseRequests/overview/new'))}
							>
								Create purchase request
							</Button>
						</div>
					}
				/>
			}
		/>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	purchaseRequests: state.purchase_requests,
	supplierUsers: state.supplier_users,
	locations: state.locations,
	stockLocations: state.stock_locations,
	users: state.users,
	parts: state.parts,
	equipmentTypes: state.equipment_types,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	fetchPurchaseRequests: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			purchaseRequestsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	refreshCounts: (filters) => dispatch(refreshPRCountsForSupplier(filters)),
	updatePRFilters: (filters, targetCollection) =>
		dispatch(PURCHASE_REQUESTS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	updatePRSorters: (sortBy, order, targetCollection) =>
		dispatch(PURCHASE_REQUESTS_CRUD_ACTION_CREATORS.updateSorting(sortBy, order, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			PURCHASE_REQUESTS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)
		),
	fetchMultipleStockLocations: (ids, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchStockLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			stockLocationsRestCrudThunksForSupplier.readLite(
				{ ...params, no_pagination: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			locationsRestCrudThunksForSupplier.readLite(
				{ ...params, no_pagination: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleUsers: (ids, targetCollectionName) =>
		dispatch(usersRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchUsers: (params, targetCollectionName, pagination, sorting, filters, addToTargetCollection) =>
		dispatch(
			usersRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleParts: (ids, targetCollectionName) =>
		dispatch(partsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchParts: (params, targetCollectionName, pagination, sorting, filters, addToTargetCollection) =>
		dispatch(
			partsRestCrudThunksForSupplier.readLite(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleEquipments: (ids, targetCollectionName) =>
		dispatch(equipmentTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchEquipmentTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			equipmentTypesRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	bulkApprove: (ids) => dispatch(bulkApprovePurchaseRequestForSupplier(ids)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(PurchaseRequestsIndexPage)
);

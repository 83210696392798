import React, { FC, useMemo } from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { nullSafeGet } from '../../utils/DataAccessUtils';

const PartWithIDDisplay: FC<any> = ({
	part,
	numberMaxChars,
	nameLengthPercentage,
}): React.ReactElement => {
	const ellipsisProps = useMemo(
		() => ({
			...(numberMaxChars ? { length: numberMaxChars } : { lines: 1 }),
		}),
		[numberMaxChars]
	);

	const partNumberText = useMemo(
		() =>
			`${nullSafeGet('partNumber', part) ? `Part #${part.partNumber} ` : ''}${
				nullSafeGet('aliasPartId', part) ? `Alias Part #${part.aliasPartId}` : ''
			}`,
		[part]
	);

	return (
		<div
			className="flex flex-col"
			style={{
				...(nameLengthPercentage && { width: `${nameLengthPercentage}%` }),
			}}
		>
			<Ellipsis tooltip={true} lines={1}>
				{part.name}
			</Ellipsis>
			<Ellipsis tooltip={true} className="text-gray-400" {...ellipsisProps}>
				{partNumberText}
			</Ellipsis>
		</div>
	);
};

export default PartWithIDDisplay;

import React, { FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Card } from 'antd';
import BuyerInvoiceForm from '../buyer_invoice_form/BuyerInvoiceForm';
import {
	createInvoiceForSupplierBasedOnWorkOrderForBuyer,
	createInvoiceForSupplierBasedOnWorkOrderForSupplier,
} from '../../thunks/invoices_thunks';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { ROLE_TYPES } from '../../utils/DataConstants';

const queryString = require('qs');
const { Content } = Layout;

const NewBuyerInvoicePage: FC<any> = ({
	createInvoiceWithWorkOrderId,
	match,
	location,
	history,
	userType,
}): React.ReactElement => {
	const [invoiceFormData, setInvoiceFormData] = useState<any>({});
	const [invoiceFormDataLoading, setInvoiceFormDataLoading] = useState(false);

	useEffect(() => {
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}

		const queryParams = queryString.parse(searchString);
		const workOrderId = queryParams.workOrderId || match.params.id;

		setInvoiceFormDataLoading(true);
		createInvoiceWithWorkOrderId(workOrderId, {}).then((invoice) => {
			setInvoiceFormData({ ...invoice });
			setInvoiceFormDataLoading(false);
		});
	}, [location.search, match.params.id]);

	const onSuccess = useCallback(
		(rec) => {
			history.push(`/${userType}/invoices/detail/${rec.id}`);
		},
		[history, userType]
	);

	return invoiceFormDataLoading ? (
		<PageLoadingPlaceholder />
	) : (
		<Content>
			<LogOnMountWithStandardEventProperties eventType={`visited ${userType} new invoice page`} />
			<Row style={{ margin: '0.5em -8px' }} gutter={16}>
				<Col span={24}>
					<Card>
						<div>
							<h5 style={{ marginBottom: 24 }}>Create Invoice</h5>
							<BuyerInvoiceForm formData={invoiceFormData} onSuccess={onSuccess} />
						</div>
					</Card>
				</Col>
			</Row>
		</Content>
	);
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	createInvoiceWithWorkOrderId: (workOrderId, entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? createInvoiceForSupplierBasedOnWorkOrderForSupplier(entity, workOrderId)
				: createInvoiceForSupplierBasedOnWorkOrderForBuyer(entity, workOrderId)
		),
});

const componentWithoutUserType = withRouter<any, any>(
	connect(null, mapDispatchToProps)(NewBuyerInvoicePage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(componentWithoutUserType);

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Button, InputNumber, Input, Alert, Popover, Modal, Row, Checkbox } from 'antd';
import { CaretDownOutlined, CaretUpOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { getCurrency, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { useEffect, useMemo, useState } from 'react';

const queryString = require('qs');
const { Content } = Layout;

const style = require('./ApprovalTiersComponent.less');

const ASSETS_OVERVIEW_TARGET_COLLECTION = 'assetsIndex';

function ApprovalTiersComponent({
	approvalModalVisible,
	modalHeaderSteps,
	backToApprovalModal,
	pendingTiers,
	showUsersModal,
	backToNameModal,

	showOutOfSequence,
	checkOutOfSequence,
	tiers,
	updateLevels,

	currentUser,
}) {
	const [maxAmount, setMaxAmount] = useState(0);
	const [outOfSequence, setOutOfSequence] = useState([]);

	let Timer;

	const getMaxAmount = (tiers) => {
		let tierList = tiers.slice(0, -1);
		let amounts = tierList.map((t, i) => {
			return t.amount;
		});
		return Math.max(...amounts, 0);
	};
	useMemo(() => setMaxAmount(getMaxAmount(tiers)), []);

	const updateTiers = (tierList) => {
		delete tierList[tierList.length - 1].amount;
		let newLevs = tierList.map((lev, i) => {
			return { ...lev, num: i + 1, users: lev.users };
		});
		if (showOutOfSequence) {
			checkOutOfSequence(tierList);
		}
		setMaxAmount(getMaxAmount(newLevs));
		updateLevels(newLevs);
	};
	const Timeout = (tierList) => {
		updateTiers(tierList);
	};
	const handleStep = (t, step) => {
		let tierList = tiers.slice();
		tierList.splice(t.num - 1, 1, {
			...t,
			amount: step,
			showSearch: false,
		});
		updateLevels(tierList);
	};
	const handleChangeValue = (tier, value) => {};
	const handleInputBlur = (tier, e) => {
		let value = parseFloat(e.target.value.replace(new RegExp(/\$\s?|(,*)/g), ''));
		let levs = tiers.slice();
		levs.splice(tier.num - 1, 1, { ...tier, amount: value });
		updateTiers(levs);
	};
	const handleEscalationStep = (t, step) => {
		let tierList = tiers.slice();
		tierList.splice(t.num - 1, 1, {
			...t,
			daysAfterEscalation: step,
			showSearch: false,
		});
		updateLevels(tierList);
	};
	const handleEscalationCheck = (t, e) => {
		let tierList = tiers.slice();
		tierList.splice(t.num - 1, 1, {
			...t,
			showSearch: false,
			escalationVisible: e.target.checked,
			daysAfterEscalation: e.target.checked ? 0 : undefined,
		});
		updateLevels(tierList);
	};
	const handleEscalationInputBlur = (tier, e) => {
		let value = parseFloat(e.target.value.replace(new RegExp(/\$\s?|(,*)/g), ''));
		let levs = tiers.slice();
		levs.splice(tier.num - 1, 1, { ...tier, daysAfterEscalation: value });
		updateTiers(levs);
	};
	const handleNameInputBlur = (tier, e) => {
		tiers.splice(tier.num - 1, 1, {
			...tier,
			name: e.target.value || tier.name,
		});
		updateTiers(tiers);
	};
	const moveTierUp = (i, e) => {
		let tierList = [...tiers];
		let swapLev = tierList[i - 1];
		tierList[i - 1] = tierList[i];
		tierList[i] = swapLev;
		if (tierList[i - 1].amount === undefined) {
			tierList[i - 1].amount = swapLev.amount;
			tierList[i].amount = undefined;
		}
		let newTiers = tierList.map((lev, i) => {
			return { ...lev, num: i + 1 };
		});
		setMaxAmount(getMaxAmount(newTiers));
		updateTiers(newTiers);
	};
	const moveTierDown = (i, e) => {
		let tierList = [...tiers];
		let swapLev = tierList[i];
		tierList[i] = tierList[i + 1];
		tierList[i + 1] = swapLev;
		if (tierList[i].amount === undefined) {
			tierList[i].amount = swapLev.amount;
			tierList[i + 1].amount = undefined;
		}
		let newTiers = tierList.map((lev, i) => {
			return { ...lev, num: i + 1 };
		});
		setMaxAmount(getMaxAmount(newTiers));
		updateTiers(newTiers);
	};
	const removeApprovalTier = (i, e) => {
		let tierList = tiers.slice();
		tierList.splice(i, 1);
		updateTiers(tierList);
	};
	const addApprovalTier = (e) => {
		let tierList = tiers.slice();
		let ids, nextAmount, nextid, nextNum, rank;
		if (tierList.length > 0) {
			ids = tierList.map((lev) => {
				return lev.id;
			});
			nextAmount = getMaxAmount(tierList) * 5;
			nextid = Math.max(...ids) + 1;
			nextNum = tierList[tierList.length - 1]['num'] + 1;
			tierList[tierList.length - 1]['amount'] = nextAmount;
		}
		tierList.push({
			id: nextid,
			num: nextNum,
			amount: nextAmount,
			daysAfterEscalation: 0,
			name: '',
			users: [],
			showSearch: false,
		});
		updateTiers(tierList);
	};
	let lastAmount;
	let previousAmounts = [];
	let warnings = [];
	const currency = getCurrency({ currentUser });

	return (
		<Modal
			visible={approvalModalVisible}
			width={840}
			title={modalHeaderSteps}
			destroyOnClose={true}
			bodyStyle={{ overflow: 'hidden', padding: '12px 24px' }}
			onCancel={backToApprovalModal}
			onOk={(e) => {
				if (checkOutOfSequence(pendingTiers)) {
				} else {
					showUsersModal(e);
				}
			}}
			footer={
				<Row>
					<div style={{ marginLeft: 'auto' }}>
						<Button onClick={backToNameModal}>Back</Button>
						<Button
							type={'primary'}
							style={{ marginLeft: '8px' }}
							onClick={(e) => {
								if (checkOutOfSequence(pendingTiers)) {
								} else {
									showUsersModal(e);
								}
							}}
						>
							Next
						</Button>
					</div>
				</Row>
			}
			closable={false}
			maskClosable={false}
		>
			<div className="approvalTiersComponent">
				<Alert
					type={'error'}
					style={{
						display: showOutOfSequence ? 'flex' : 'none',
						flexDirection: 'row',
						marginBottom: 12,
						transitionDuration: '0.5s',
						zIndex: 999,
					}}
					message={'Each tier must have an amount greater than or equal to the previous tier!'}
					showIcon
				/>
				<div className="ApprovalTierHeader">
					<div className="ApprovalUpDownWrapperHeader"></div>
					<div className="ApprovalTierCenter">
						<div className="ApprovalTierIDHeader">Order</div>
						<div className="ApprovalTierLabelHeader">Name</div>
					</div>
					<div className="ApprovalTierInputWrapper">
						<div className="ApprovalTierInputHeader">Amounts</div>
						<div className="ApprovalTierEscalationHeader"></div>
						<div className="xHeader"></div>
					</div>
				</div>
				<div className="ApprovalTierBody">
					{tiers.map((level, i, levels) => {
						const isNotLast = tiers.length - 1 !== i;
						lastAmount =
							i === 0
								? 0
								: levels[i - 1].amount === level.amount
								? lastAmount
								: levels[i - 1].amount;
						const warning = isNotLast
							? previousAmounts.some((amount) => amount > level.amount)
							: false;
						if (warning) {
							warnings.push(level);
						}
						const hasEscalation =
							level.escalationVisible || level.daysAfterEscalation !== undefined;
						previousAmounts.push(level.amount);
						return (
							<div
								className="ApprovalTier"
								key={level.num.toString()}
								style={{
									backgroundColor: warning ? 'rgba(255,77,79,0.12)' : null,
								}}
							>
								<div style={{ lineHeight: '16px' }} className={'ApprovalUpDownWrapper'}>
									<Popover
										content={'Click to move this tier up.'}
										placement={'left'}
										style={{ lineHeight: '16px' }}
										disabled={i === 0}
									>
										<Button
											onClick={moveTierUp.bind(this, i)}
											icon={<CaretUpOutlined style={{ fontSize: '12px' }} />}
											type={'text'}
											disabled={i === 0}
										></Button>
									</Popover>
									<Popover
										content={'Click to move this tier down.'}
										placement={'left'}
										style={{ lineHeight: '16px' }}
										disabled={!isNotLast}
									>
										<Button
											onClick={moveTierDown.bind(this, i)}
											icon={<CaretDownOutlined style={{ fontSize: '12px' }} />}
											type={'text'}
											disabled={!isNotLast}
										></Button>
									</Popover>
								</div>
								<div className="ApprovalTierCenter">
									<div className="ApprovalTierID">{i + 1}</div>
									<div className="ApprovalTierLabel">
										<Input
											key={level.name}
											defaultValue={level.name}
											placeholder={'Tier '.concat(level.num)}
											onBlur={handleNameInputBlur.bind(this, level)}
										/>
									</div>
								</div>
								{isNotLast ? (
									<div className="ApprovalTierInputWrapper">
										<div className="ApprovalTierPrefix">
											<span>Up to</span>
											<InputNumber
												style={{ width: 160 }}
												step={100}
												value={level.amount}
												onStep={handleStep.bind(this, level)}
												onChange={handleChangeValue.bind(this, level)}
												onBlur={handleInputBlur.bind(this, level)}
												min={0}
												addonAfter={currency.id}
												formatter={(value) =>
													`$ ${value}`.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ',')
												}
												parser={(value) => value.replace(new RegExp(/\$\s?|(,*)/g), '')}
											/>
										</div>
										<div className="ApprovalTierPrefix" style={{ marginLeft: 44 }}>
											{hasEscalation ? null : <span>Escalate?</span>}
											<Checkbox
												checked={hasEscalation}
												onChange={handleEscalationCheck.bind(this, level)}
											/>
											{hasEscalation ? (
												<InputNumber
													style={{ width: 140 }}
													step={1}
													value={level.daysAfterEscalation}
													onStep={handleEscalationStep.bind(this, level)}
													onChange={handleChangeValue.bind(this, level)}
													onBlur={handleEscalationInputBlur.bind(this, level)}
													min={0}
													addonAfter={'days'}
												/>
											) : null}
										</div>
									</div>
								) : (
									<div className="ApprovalTierInputWrapper">
										<div className="ApprovalTierPrefix">
											<span>Greater than {currency.format(lastAmount)}</span>
										</div>
									</div>
								)}
								{isNotLast ? (
									<Button className="x" onClick={removeApprovalTier.bind(null, i)}>
										<MinusCircleOutlined className="dynamic-delete-button" />
									</Button>
								) : (
									false
								)}
							</div>
						);
					})}
					<div className="ApprovalTierBottom">
						<Button
							style={{ marginTop: '0px' }}
							onClick={addApprovalTier}
							className="SearchButton"
							size={'large'}
						>
							Add Tier
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	);
}

const mapStateToProps = (state, ownProps) => ({
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(ApprovalTiersComponent)
);

import React, { FC, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button } from 'antd';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import { EmptyState } from '../empty_state/EmptyState';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { STOCK_LOCATIONS_CRUD_ACTION_CREATORS } from '../../actions/stock_locations_actions';
import { FILTER_FIELD_TYPE } from '../../utils/DataConstants';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import LocationNameWithIconDisplay from '../common/LocationNameWithIconDisplay';
import { AddressDisplay } from '../address_display/AddressDisplay';

const TC_NAME = 'stockLocationsIndex';

const StockLocationsIndexPage: FC<any> = ({
	stockLocations,
	history,
	updateFilters,
	clearAndUpdateFilters,
	location,
}): React.ReactElement => {
	const filterConfig = useMemo(
		() => [
			{
				fieldName: 'isMobile',
				type: FILTER_FIELD_TYPE.RADIO_GROUP,
				items: [
					{
						label: 'All',
						value: 'undefined',
					},
					{
						label: 'Is Mobile',
						value: 'true',
					},
				],
				defaultValue: undefined,
				showOutsideDropdown: true,
			},
		],
		[]
	);

	const columns = useMemo(
		() => [
			{
				dataIndex: 'id',
				render: (_, record) => (
					<div className="flex flex-row justify-between">
						<div className="flex flex-col">
							<div className="text-xl dark:text-gray-600">{record.name}</div>
							{record.description ? (
								<div className="flex flex-row items-center justify-start">
									<div className="text-gray-400">
										<Ellipsis tooltip={true} length={56}>
											{record.description}
										</Ellipsis>
									</div>
								</div>
							) : null}
							{record.warehouseId ? (
								<div className="text-gray-400">{`Warehouse #${record.warehouseId}`}</div>
							) : null}
						</div>
					</div>
				),
			},
			{
				render: (_, record) => (
					<div className="flex flex-col">
						{record.location ? (
							<div className="flex">
								<LocationNameWithIconDisplay location={record.location} />
							</div>
						) : null}
						{record.isMobile ? (
							'Mobile'
						) : (
							<AddressDisplay
								address={
									nullSafeGet('address', record) ||
									nullSafeGet('location.buyerFacility.primaryAddress', record)
								}
							/>
						)}
					</div>
				),
			},
		],
		[]
	);

	const redirectTo = useCallback(
		(path) => history.push(getLinkWIthBackLinkParams(location, 'Back to Stock Locations', path)),
		[history, location]
	);

	const onRow = useCallback(
		(record) => ({
			onClick: () => redirectTo(`/supplier/stockLocations/detail/${record.id}`),
		}),
		[redirectTo]
	);

	const onNewStockLocation = useCallback(
		() => redirectTo('/supplier/stockLocations/overview/new'),
		[redirectTo]
	);

	const createNewSLButton = useMemo(
		() => (
			<Button style={{ marginLeft: 16 }} type="primary" size="large" onClick={onNewStockLocation}>
				New Stock Location
			</Button>
		),
		[onNewStockLocation]
	);

	return (
		<PaginatedReduxTableWithHeader
			targetCollectionName={TC_NAME}
			updateFilters={updateFilters}
			stateSlice={stockLocations}
			clearAndUpdateFilters={clearAndUpdateFilters}
			filterConfig={filterConfig}
			entityCollectionName="stock_locations"
			tableColumns={columns}
			onTableRow={onRow}
			fetchData={stockLocationsRestCrudThunksForSupplier.read}
			rightActions={createNewSLButton}
			emptyState={
				<EmptyState
					graphic={
						<img
							alt="No Stock Location"
							style={{ marginBottom: 8 }}
							src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
						/>
					}
					headline={'No Stock Locations found'}
					body={<div style={{ textAlign: 'center' }}>{createNewSLButton}</div>}
				/>
			}
		/>
	);
};

const mapStateToProps = (state) => ({
	stockLocations: state.stock_locations,
});

const mapDispatchToProps = (dispatch) => ({
	updateFilters: (filters, targetCollection) =>
		dispatch(STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			STOCK_LOCATIONS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)
		),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StockLocationsIndexPage));

import React, { FC } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Radio, Select, DatePicker } from 'antd';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';

export const TaskList: FC<any> = (props) => {
	const { tasks } = props;

	const dateFormat = 'MM-DD-YYYY';

	const getTaskItem = (task, idx) => {
		let inputField = <Input.TextArea disabled={true} placeholder="" />;
		if (task.questionType === 'yesNo') {
			inputField = (
				<Radio.Group>
					<Radio value="yes" disabled={true}>
						Yes
					</Radio>
					<Radio value="no" disabled={true}>
						No
					</Radio>
				</Radio.Group>
			);
		} else if (
			task.questionType === 'multipleChoice' &&
			nullSafeGetOrElse('multipleChoices.length', task, 0) != 0
		) {
			if (task.multipleChoices.length > 4) {
				inputField = (
					<Select>
						{task.multipleChoices.map((choice, idx) => (
							<Select.Option disabled={true} key={`mc-${idx}`} value={choice}>
								{choice}
							</Select.Option>
						))}
					</Select>
				);
			} else {
				inputField = (
					<Radio.Group>
						{task.multipleChoices.map((choice, idx) => (
							<Radio value={choice} key={`rg-${idx}`} disabled={true}>
								{choice}
							</Radio>
						))}
					</Radio.Group>
				);
			}
		} else if (
			task.questionType === 'multipleChoice' &&
			nullSafeGetOrElse('multipleChoicesWithLabel.length', task, 0) != 0
		) {
			if (task.multipleChoicesWithLabel.length > 4) {
				inputField = (
					<Select>
						{task.multipleChoicesWithLabel.map((choice, idx) =>
							choice.description ? (
								<Select.Option disabled={true} key={`mc-${idx}`} value={choice.value}>
									{choice.value} ({choice.description})
								</Select.Option>
							) : (
								<Select.Option disabled={true} key={`mc-${idx}`} value={choice.value}>
									{choice.value}s
								</Select.Option>
							)
						)}
					</Select>
				);
			} else {
				inputField = (
					<Radio.Group>
						{task.multipleChoicesWithLabel.map((choice, idx) =>
							choice.description ? (
								<Radio value={choice.value} key={`rg-${idx}`} disabled={true}>
									{choice.value} ({choice.description})
								</Radio>
							) : (
								<Radio value={choice.value} key={`rg-${idx}`} disabled={true}>
									{choice.value}
								</Radio>
							)
						)}
					</Radio.Group>
				);
			}
		} else if (task.questionType === 'dateTime') {
			inputField = <DatePicker disabled={true} format={dateFormat} />;
		}
		return (
			<Form.Item key={`form-${idx}`} label="">
				<span style={{ display: 'block', fontSize: 16, lineHeight: 2, marginTop: -8 }}>
					{task.title}
				</span>
				<span style={{ display: 'block', lineHeight: 2, color: 'rgba(0,0,0,0.45)' }}>
					{task.description}
				</span>
				{inputField}
			</Form.Item>
		);
	};

	return (
		<Form layout="vertical" style={{ padding: '0 16px' }}>
			{tasks.map((task, idx) => getTaskItem(task, idx))}
		</Form>
	);
};

import React, { FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FlatfileImporter } from '@flatfile/react';
import { FLAT_FILE_LICENSE_KEY } from '../../utils/DataConstants';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { PART_FLATFILE_HEADER, getFlatFileRecordForPartLevel } from './stock_location_constants';

const EditPartsBulk: FC<any> = ({
	currentUser,
	ppsls,
	onSuccess,
	onDataUpload,
}): React.ReactElement => {
	const importer = useMemo(
		() =>
			new FlatfileImporter(FLAT_FILE_LICENSE_KEY, {
				type: 'Import type',
				fields: PART_FLATFILE_HEADER,
			}),
		[]
	);

	importer.setCustomer({
		companyId: nullSafeGet('facility.buyerCompanyId', currentUser),
		companyName: nullSafeGet('facility.name', currentUser),
		userId: currentUser.email,
	});

	useEffect(() => {
		ppsls &&
			ppsls.length > 0 &&
			importer
				.requestDataFromUser({
					source: ppsls.map((_) => ({
						data: getFlatFileRecordForPartLevel(_),
					})),
				})
				.then((results) => {
					onDataUpload(results)
						.then(() => {
							importer.displaySuccess('Parts level updated Successfully');
							onSuccess && onSuccess();
						})
						.catch((err) => importer.displayError(err));
					importer.displayLoader('Updating parts level.');
				});
	}, [importer, onDataUpload, onSuccess, ppsls]);

	return <></>;
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

export default withRouter<any, any>(connect(mapStateToProps)(EditPartsBulk));

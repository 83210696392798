import React, { FC, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { stopDefaultEvents } from '../../utils/DataAccessUtils';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';

export const ENTITY_TYPE = {
	STOCK_LOCATION: 'stockLocation',
	SL_SHIPMENTS: 'slShipments',
	LOCATION: 'location',
	WORK_ORDER: 'workOrder',
	WORK_ORDER_PARTS_AND_EQUPIPMENTS: 'workOrderPartsAndEquipments',
	PURCHASE_REQUEST: 'purchaseRequest',
	PURCHASE_ORDER: 'purchaseOrder',
	VENDOR: 'vendor',
	PART: 'part',
	PPSL: 'ppsl',
	EQUIPMENT: 'equipment',
	EPSL: 'epsl',
	ASSET_DETAIL: 'assetDetail',
};

const HyperLink: FC<any> = ({
	location,
	entityType,
	entityId,
	onClick,
	text,
	userType,
	newTabPath,
	backLinkText = 'Go Back',
}): React.ReactElement => {
	const path: any = useMemo(() => {
		switch (entityType) {
			case ENTITY_TYPE.WORK_ORDER:
				return `/${userType}/workOrders/detail/${entityId}/details`;
			case ENTITY_TYPE.WORK_ORDER_PARTS_AND_EQUPIPMENTS:
				return `/${userType}/workOrders/detail/${entityId}/charges`;
			case ENTITY_TYPE.STOCK_LOCATION:
				return `/${userType}/stockLocations/detail/${entityId}/parts`;
			case ENTITY_TYPE.SL_SHIPMENTS:
				return `/${userType}/stockLocations/detail/${entityId}/shipments`;
			case ENTITY_TYPE.LOCATION:
				return `/${userType}/locations/detail/${entityId}/details`;
			case ENTITY_TYPE.PURCHASE_REQUEST:
				return `/${userType}/purchaseRequests/detail/${entityId}/details`;
			case ENTITY_TYPE.PURCHASE_ORDER:
				return `/${userType}/purchaseOrders/detail/${entityId}/details`;
			case ENTITY_TYPE.VENDOR:
				return `/${userType}/vendors/detail/${entityId}/details`;
			case ENTITY_TYPE.PART:
				return `/${userType}/parts/detail/${entityId}/details`;
			case ENTITY_TYPE.PPSL:
				return `/${userType}/parts/partsPerStockLocation/detail/${entityId}/details`;
			case ENTITY_TYPE.EQUIPMENT:
				return `/${userType}/equipments/detail/${entityId}/details`;
			case ENTITY_TYPE.EPSL:
				return `/${userType}/equipments/equipmentPerStockLocation/detail/${entityId}/details`;
			case ENTITY_TYPE.ASSET_DETAIL:
				return `/${userType}/assets/detail/${entityId}/details`;
			default:
				return;
		}
	}, [entityId, entityType, userType]);

	const canOpenInNewTab = useMemo(() => path || newTabPath, [newTabPath, path]);

	const completePath = useMemo(
		() =>
			canOpenInNewTab ? getLinkWIthBackLinkParams(location, backLinkText, path || newTabPath) : '',
		[backLinkText, canOpenInNewTab, location, newTabPath, path]
	);

	const openInANewTabLink = useCallback(() => {
		window.open(completePath, '_blank', 'noreferrer');
	}, [completePath]);

	const onLinkClick = useCallback(
		(e) => {
			stopDefaultEvents(e);
			if (canOpenInNewTab) {
				openInANewTabLink();
			} else onClick(e);
		},
		[canOpenInNewTab, onClick, openInANewTabLink]
	);

	return (
		<div onClick={onLinkClick} className="flex flex-row items-center">
			<span className="inline items-center hover:cursor-pointer">
				<span className="text-blue-600 underline visited:text-purple-600 hover:text-blue-800 ">
					{text}
				</span>
				{canOpenInNewTab && (
					<span className="ml-2">
						<i className="icons8-font icons8-external-link" />
					</span>
				)}
			</span>
		</div>
	);
};

const component = withRouter<any, any>(HyperLink);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(component);

import * as React from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Select, Input, Alert, DatePicker, InputNumber } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { connect } from 'react-redux';
import moment from 'moment';
import { imageUploadValidation, normFile } from '../../utils/ImageUtils';
import { getProtectedImageUriForSupplier } from '../../utils/FileAccessUtils';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import {
	getDisabledDateTime,
	getImageCountErrorRules,
	getObjectValues,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import { WORK_ORDER_STATUSES } from '../../constants/WorkOrderStatuses';
import { retrieveCachedUserDetails } from '../../thunks/session_thunks';
import { resolutionTypesRestCrudThunksForSupplier } from '../../thunks/resolution_types_thunks';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import OWUpload from '../OWUpload';

const FormItem = Form.Item;
export const DATE_FORMAT = 'MMMM D, YYYY h:mm a';

interface ManualCheckOutModalFormProps extends FormComponentProps {
	onSubmit: any;
	onCancel: any;
	currentUser: any;
	companyConfig: any;
	createErrors: any;
	updateErrors: any;
	visible: boolean;
	creating: boolean;
	updating: boolean;

	formData: any;
	privateSupplier: boolean;
	resolutionTypes: any;
	fetchResolutionTypes: any;
	fetchMultipleResolutionTypes: any;
}

const disabledDate = (checkInTime) => (current) => {
	// Can not select days before today and today
	return current < moment(checkInTime) || current > moment().endOf('day');
};

const CONTRACTOR_CHECK_OUT_OPTIONS = [
	{
		displayName: 'Work Complete',
		value: WORK_ORDER_STATUSES.waitingForReview,
	},
	{
		displayName: 'Quote Required',
		value: WORK_ORDER_STATUSES.waitingForQuote,
	},
	{
		displayName: 'Delayed by Third Party',
		value: WORK_ORDER_STATUSES.delayedByThirdParty,
	},
	{
		displayName: 'Work Incomplete With Reason',
		value: WORK_ORDER_STATUSES.workIncompleteWithReason,
	},
];

const INTERNAL_TECH_CHECK_OUT_OPTIONS = [
	{
		displayName: 'Work Complete',
		value: WORK_ORDER_STATUSES.internalTechWorkComplete,
	},
	{
		displayName: 'Delayed by Third Party',
		value: WORK_ORDER_STATUSES.delayedByThirdParty,
	},
	{
		displayName: 'Work Incomplete With Reason',
		value: WORK_ORDER_STATUSES.workIncompleteWithReason,
	},
];

const PARTS_REQUESTED_CHECK_OUT_OPTION = [
	{
		displayName: 'Parts Requested',
		value: WORK_ORDER_STATUSES.partsRequested,
	},
];

const PARTS_ON_ORDER_CHECK_OUT_OPTION = [
	{
		displayName: 'Parts on Order',
		value: WORK_ORDER_STATUSES.partsOnOrder,
	},
];

class ManualCheckOutModalForm extends React.Component<ManualCheckOutModalFormProps, any> {
	constructor(props) {
		super(props);
		this.state = {
			previewVisible: false,
			uploadLoading: false,
			previewImage: false,
			photoInfo: [],
			allPhotos: [],
		};
	}

	handlePreview = (obj) => {
		let thumbUrl = null;
		let fullUrl = null;
		let photoInfo = null;
		if (obj.response.data) {
			const imgFileId = obj.response.data.fileId;
			const imgFileName = obj.response.data.fileName;
			thumbUrl = getProtectedImageUriForSupplier(imgFileId, imgFileName, 100, 100);
			fullUrl = getProtectedImageUriForSupplier(imgFileId, imgFileName, 400, 400);
			photoInfo = {
				uid: imgFileId,
				size: 1,
				name: imgFileName,
				filename: imgFileName,
				url: fullUrl,
				status: 'done',
				thumbUrl: thumbUrl,
				response: { data: { fileName: imgFileName, fileId: imgFileId } },
				type: 'image/jpeg',
			};
		}

		this.setState({
			photoInfo: [photoInfo],
			previewImage: fullUrl || thumbUrl,
			previewVisible: true,
		});
	};

	handleImageUpdate = (info) => {
		if (info.file.status === 'done') {
			this.setState({ allPhotos: info.fileList });
		} else if (info.file.status === undefined) {
			info.fileList = this.state.allPhotos;
		}
	};

	handleRemove = (file) => {
		const allPhotos = nullSafeGetOrElse('state.allPhotos', this, []);
		this.setState({
			allPhotos: allPhotos.filter((_) => _.uid !== file.uid),
		});
	};

	handleCancel = () => this.setState({ previewVisible: false });

	render() {
		const {
			visible,
			currentUser,
			onCancel,
			onSubmit,
			form,
			formData,
			createErrors,
			updateErrors,
			privateSupplier,
			companyConfig,
			creating,
			updating,
			resolutionTypes,
			fetchResolutionTypes,
			fetchMultipleResolutionTypes,
		} = this.props;
		const { previewVisible, previewImage } = this.state;
		const { getFieldDecorator } = form;
		const okText = 'Check Out';
		const titleText = 'Remote Check Out';
		const resolutionsFetching = nullSafeGet('fetching', resolutionTypes);
		const hasResolutionTypes = resolutionsFetching
			? true
			: getObjectValues(nullSafeGetOrElse('records', resolutionTypes, {})).length > 0;
		const photoStrings = this.state.allPhotos.map((p) => {
			const fileName = nullSafeGetOrElse('response.data.fileName', p, '');
			const fileId = nullSafeGetOrElse('response.data.fileId', p, '');
			return `${fileId}/${fileName}`;
		});

		const BACKEND_URI = getBackendUri();
		const uploadHeaders = { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token };
		getFieldDecorator(`id`, { initialValue: formData.id });
		getFieldDecorator(`checkOutByEmail`, { initialValue: currentUser.email });
		getFieldDecorator(`checkOutImages`, { initialValue: photoStrings });

		const checkOutNotesMandatory = nullSafeGetOrElse(
			'facility.internalTechConfig.checkOutNotesMandatory',
			currentUser,
			false
		);
		const numImagesRequired = nullSafeGetOrElse(
			'facility.internalTechConfig.numImagesRequiredOnCheckOut',
			currentUser,
			0
		);
		const imageCountErrorMessage = `You need to upload at least ${numImagesRequired} photo${
			numImagesRequired > 1 ? 's' : ''
		}`;

		const ADDITIONAL_CHECK_OUT_OPTIONS = nullSafeGetOrElse(
			'detail.config.partsOrderingConfig.needPartsRequisitionBeforeOrdering',
			companyConfig,
			false
		)
			? PARTS_REQUESTED_CHECK_OUT_OPTION
			: PARTS_ON_ORDER_CHECK_OUT_OPTION;

		const checkOutOptions = privateSupplier ? (
			<Select style={{ maxWidth: 300 }}>
				{INTERNAL_TECH_CHECK_OUT_OPTIONS.concat(ADDITIONAL_CHECK_OUT_OPTIONS).map((d) => (
					<Select.Option key={d.value} value={d.value}>
						{d.displayName}
					</Select.Option>
				))}
			</Select>
		) : (
			<Select style={{ maxWidth: 300 }}>
				{CONTRACTOR_CHECK_OUT_OPTIONS.concat(ADDITIONAL_CHECK_OUT_OPTIONS).map((d) => (
					<Select.Option key={d.value} value={d.value}>
						{d.displayName}
					</Select.Option>
				))}
			</Select>
		);

		return (
			<Modal
				visible={visible}
				width={600}
				title={titleText}
				okText={okText}
				onCancel={onCancel}
				onOk={onSubmit}
				closable={false}
				confirmLoading={creating || updating}
			>
				<Form layout="vertical" className="supplierForm">
					{createErrors.length > 0 ? (
						<FormItem>
							<Alert message={createErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					{updateErrors.length > 0 ? (
						<FormItem>
							<Alert message={updateErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					<FormItem label="Check Out Status">
						{getFieldDecorator('checkOutStatus', {
							rules: [
								{
									required: true,
									message: 'Please select a status',
								},
							],
							initialValue:
								formData.checkOutStatus || privateSupplier
									? INTERNAL_TECH_CHECK_OUT_OPTIONS[0].value
									: CONTRACTOR_CHECK_OUT_OPTIONS[0].value,
						})(checkOutOptions)}
					</FormItem>
					{(form.getFieldsValue(['checkOutStatus']).checkOutStatus !==
						WORK_ORDER_STATUSES.waitingForReview && (
						<FormItem label="Estimated completion date ">
							{getFieldDecorator(`estimatedCompletionDate`, {
								initialValue: formData.estimatedCompletionDate
									? moment(formData.estimatedCompletionDate)
									: undefined,
							})(<DatePicker style={{ width: '100%' }} showTime={false} format={'MMMM D, YYYY'} />)}
						</FormItem>
					)) ||
						null}
					<FormItem label="Number of Techs">
						{getFieldDecorator('numberOfTechs', {
							rules: [
								{
									required: true,
									message: 'Please enter the number of techs involved',
								},
							],
							initialValue: 1 + nullSafeGetOrElse('additionalTechnicianEmails.length', formData, 0),
						})(<InputNumber style={{ maxWidth: 100 }} placeholder="techs" />)}
					</FormItem>
					<FormItem label="Checked Out At">
						{getFieldDecorator(`checkOutTime`, {
							rules: [
								{
									type: 'object',
									required: true,
									message: 'Please select the time technician(s) finished work.',
								},
							],
							initialValue: formData.checkOutTime ? moment(formData.checkOutTime) : undefined,
						})(
							<DatePicker
								disabledTime={getDisabledDateTime(formData.checkInTime)}
								disabledDate={disabledDate(formData.checkInTime)}
								style={{ width: '100%' }}
								showTime={true}
								format={DATE_FORMAT}
							/>
						)}
					</FormItem>
					<FormItem label="Add After Photos">
						{getFieldDecorator(`images`, {
							valuePropName: 'fileList',
							getValueFromEvent: normFile,
							initialValue: this.state.allPhotos,
							rules: getImageCountErrorRules(numImagesRequired, imageCountErrorMessage),
						})(
							<OWUpload
								accept="image/*"
								name="file"
								listType="picture-card"
								className="servicecall-image-uploader"
								multiple={true}
								headers={uploadHeaders}
								onPreview={this.handlePreview}
								action={`${BACKEND_URI}/api/v1/supplier/file/upload`}
								beforeUpload={imageUploadValidation}
								onChange={this.handleImageUpdate}
								onRemove={this.handleRemove}
							>
								<div style={{ display: 'inline' }}>
									<LegacyIcon type={this.state.uploadLoading ? 'loading' : 'plus'} />
									<div className="ant-upload-text">Upload</div>
								</div>
								<Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
									<img alt="example" style={{ width: '100%' }} src={previewImage} />
								</Modal>
							</OWUpload>
						)}
					</FormItem>
					<FormItem label="Notes">
						{getFieldDecorator('checkOutNotes', {
							initialValue: formData.checkOutNotes,
							rules: [
								{
									required: checkOutNotesMandatory,
									message: 'Check out notes is mandatory',
								},
							],
						})(<Input.TextArea style={{ maxWidth: 300 }} onClick={(e) => e.stopPropagation()} />)}
					</FormItem>
					{hasResolutionTypes ? (
						<Form.Item label="Resolution Type">
							{getFieldDecorator('resolutionTypeId', {
								initialValue: formData.resolutionTypeId,
							})(
								<OWAsyncSelect
									style={{ maxWidth: 800 }}
									stateSlice={resolutionTypes}
									targetCollectionName={'RESOLUTION_TYPES_AUTOCOMPLETE'}
									fetchMultiple={(ids, targetCollectionName) => {
										fetchMultipleResolutionTypes(ids, targetCollectionName);
									}}
									fetchData={(
										searchText,
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									) => {
										fetchResolutionTypes(
											{ name: searchText },
											targetCollectionName,
											pagination,
											sorting,
											filters,
											addToTargetCollection
										);
									}}
									renderRecord={(resolutionType) => (
										<Select.Option key={resolutionType.id} value={resolutionType.id}>
											{resolutionType.resolutionCode ? `${resolutionType.resolutionCode} - ` : null}
											{resolutionType.resolutionType}
										</Select.Option>
									)}
									sortBy={{ sort_by: 'name', order: 'ascend' }}
									allowClear
								/>
							)}
						</Form.Item>
					) : null}
				</Form>
			</Modal>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	currentUser: state.session.currentUser,
	companyConfig: state.company_config,
	createErrors: state.buyer_contacts.createErrors,
	updateErrors: state.buyer_contacts.updateErrors,
	creating: state.work_orders.creating,
	updating: state.work_orders.updating,

	privateSupplier: ownProps.privateSupplier,
	resolutionTypes: state.resolution_types,
});

const mapDispatchToProps = (dispatch) => ({
	fetchMultipleResolutionTypes: (ids, targetCollectionName) =>
		dispatch(resolutionTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchResolutionTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			resolutionTypesRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create<ManualCheckOutModalFormProps>()(ManualCheckOutModalForm));

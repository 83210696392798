import { BIDDERS_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/bidders_actions';
import {
	compositeInitialStateGenerator,
	compositeReducerGenerator,
} from './standard_reducer_utils';

const initialState: any = compositeInitialStateGenerator(
	['biddersIndex', 'RFP_DETAIL_BIDDERS'],
	['requestForProposalId', 'supplierFacilityId']
);
export default compositeReducerGenerator(CRUD_ACTION_CONSTANTS, initialState, [
	'requestForProposalId',
	'supplierFacilityId',
]);

import * as React from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Upload, message, Select } from 'antd';
import { connect } from 'react-redux';
import { CURRENCIES, ROLE_TYPES } from '../../utils/DataConstants';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { getObjectValues, nullSafeGet } from '../../utils/DataAccessUtils';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import { buyersRestCrudThunksForBuyer } from '../../thunks/buyers_thunks';
import { buyerFacilitiesRestCrudThunksForBuyer } from '../../thunks/buyer_facilities_thunks';
import { manuallyAssociateBuyerContactWithFacility } from '../../thunks/buyer_contacts_thunks';
import { LocationAutocomplete } from '../location_autocomplete/LocationAutocomplete';
import { checkUserTypeToken, retrieveCachedUserDetails } from '../../thunks/session_thunks';
import { imageUploadValidation } from '../../utils/ImageUtils';
import { capitalize } from '../../utils/DataFormatterUtils';

const style = require('./BuyerCompanyForm.less');
const FormItem = Form.Item;

interface BuyerCompanyFormProps extends FormComponentProps {
	creating: boolean;
	updating: boolean;
	newAccountSetupMode: boolean;
	spendCategories: any;
	history: any;
	refreshCurrentUser: any;
	currentUser: any;
	minimal?: boolean;
	associateBuyerContactWithFacility: any;
	getBuyerFacilities: any;
	fetchSpendCategories: any;
	create: any;
	update: any;
}

class BuyerCompanyForm extends React.Component<BuyerCompanyFormProps, any> {
	handleSubmit = (e) => {
		const {
			create,
			history,
			refreshCurrentUser,
			getBuyerFacilities,
			minimal,
			newAccountSetupMode,
			associateBuyerContactWithFacility,
			currentUser,
		} = this.props;
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const primaryAddress = Object.keys(values)
					.filter((k) => k.indexOf('primaryAddress') > -1)
					.reduce((acc, k) => {
						const [_, x] = k.split('-');
						acc[x] = values[k];
						return acc;
					}, {});
				let buyer;
				if (minimal) {
					buyer = {
						...values,
						displayName: values.displayName || values.name,
						primaryContactEmail: values.primaryContactEmail || currentUser.email,
						currencyId: values.currencyId || 'USD',
						primaryAddress,
					};
				} else {
					buyer = {
						...values,
						primaryAddress,
						currencyId: values.currencyId || 'USD',
					};
				}

				if (newAccountSetupMode) {
					create(buyer).then((buyerCompany) => {
						getBuyerFacilities(buyerCompany.name).then((buyerFacilities) => {
							const newBuyerFacility = buyerFacilities.find(
								(facility) => buyerCompany.name === facility.name
							);
							associateBuyerContactWithFacility(currentUser, newBuyerFacility.id).then(() => {
								refreshCurrentUser().then(() => {
									history.push('/buyer/newAccountSetup/profile');
								});
							});
						});
					});
				} else {
					create(buyer);
				}
			}
		});
	};

	state = {
		uploadLoading: false,
		logoURL: null,
	};

	handleUploadChange = (info) => {
		if (info.file.status === 'uploading') {
			this.setState({ uploadLoading: true });
			return;
		}
		if (info.file.status === 'done') {
			const companyLogoURL = nullSafeGet('file.response.data', info);
			this.props.form.setFieldsValue({ logoURL: companyLogoURL });
			this.setState({
				logoURL: companyLogoURL,
				uploadLoading: false,
			});
		}
	};

	handleLocationAutocompleteSelect = (addr, lonLat, parsedAddress) => {
		const { form } = this.props;
		form.setFieldsValue({
			'primaryAddress-streetAddress1': parsedAddress.streetAddress1,
			'primaryAddress-city': parsedAddress.city,
			'primaryAddress-region': parsedAddress.region,
			'primaryAddress-postcode': parsedAddress.postcode,
			'primaryAddress-country': parsedAddress.country,
		});
		form.setFieldsValue({
			longitude: lonLat.lng,
			latitude: lonLat.lat,
		});
	};

	render() {
		const BACKEND_URI = getBackendUri();
		const { creating, updating, form, minimal, newAccountSetupMode } = this.props;
		const { logoURL } = this.state;
		const submitText = newAccountSetupMode ? 'Create company' : 'Create company';
		const { getFieldDecorator, setFieldsValue } = form;
		const uploadHeaders = { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token };
		const currencyRecordsList = getObjectValues(CURRENCIES);
		const currencyRecordsMap = getObjectValues(CURRENCIES);

		getFieldDecorator(`longitude`);
		getFieldDecorator(`latitude`);
		getFieldDecorator(`primaryAddress-streetAddress1`);
		getFieldDecorator(`primaryAddress-streetAddress2`);
		getFieldDecorator(`primaryAddress-city`);
		getFieldDecorator(`primaryAddress-region`);
		getFieldDecorator(`primaryAddress-postcode`);
		getFieldDecorator(`primaryAddress-country`);
		getFieldDecorator('status', { initialValue: 'active' });
		getFieldDecorator('logoURL', {
			initialValue: null,
		});
		const uploadButton = (
			<div>
				<LegacyIcon type={this.state.uploadLoading ? 'loading' : 'plus'} />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		const isLoading = creating || updating;

		return (
			<Form layout="vertical" onSubmit={this.handleSubmit} className="buyerCompanyForm">
				<FormItem label="Company name">
					{getFieldDecorator('name', {
						rules: [{ required: true, message: "Please input your company's legal entity name!" }],
					})(<Input style={{ maxWidth: 500 }} />)}
				</FormItem>
				<Form.Item label="Currency">
					{form.getFieldDecorator('currencyId', {
						rules: [{ required: true, message: 'Please select a currency for this event.' }],
						initialValue: 'USD',
					})(
						<Select
							showSearch={true}
							style={{ width: 300 }}
							optionFilterProp="children"
							filterOption={(input, option) =>
								currencyRecordsMap[option.props.value].displayNamePlural
									.toLowerCase()
									.indexOf(input.toLowerCase()) >= 0
							}
							placeholder="Currency"
						>
							{currencyRecordsList.map((currency) => (
								<Select.Option key={currency.id} value={currency.id}>
									{capitalize(currency.displayNamePlural)} ({currency.id})
								</Select.Option>
							))}
						</Select>
					)}
				</Form.Item>
				{minimal
					? null
					: [
							<FormItem key={0} label="What name do your customers call your company?">
								{getFieldDecorator('displayName', {
									rules: [
										{
											required: true,
											message:
												"Please input your company's name as used in advertising and sales materials!",
										},
									],
								})(<Input style={{ maxWidth: 500 }} />)}
							</FormItem>,
							<FormItem
								label="Upload your company's logo. Square 300x300 pixel images look best."
								key={1}
							>
								<Upload
									name="file"
									listType="picture-card"
									className="avatar-uploader"
									showUploadList={false}
									headers={uploadHeaders}
									action={`${BACKEND_URI}/api/v1/buyer/file/public/company_logo/upload`}
									beforeUpload={imageUploadValidation}
									onChange={this.handleUploadChange}
								>
									{logoURL ? <img src={logoURL} style={{ maxHeight: 104 }} alt="" /> : uploadButton}
								</Upload>
							</FormItem>,
							<FormItem label="What is the best email to reach you?" key={2}>
								{getFieldDecorator('primaryContactEmail', {
									rules: [
										{ required: true, message: "Please input your primary contact's email!" },
									],
								})(<Input style={{ maxWidth: 350 }} />)}
							</FormItem>,
							<FormItem label="Add a link to your company's website" key={3}>
								{getFieldDecorator('corporateURL', {
									rules: [{ required: true, message: "Please input your company's website!" }],
								})(<Input style={{ maxWidth: 400 }} />)}
							</FormItem>,
					  ]}
				<Form.Item label="Primary Address">
					<LocationAutocomplete
						initialValue={''}
						placeholder="Where is your company\'s primary location?"
						onSelect={this.handleLocationAutocompleteSelect}
					/>
				</Form.Item>
				<FormItem>
					<Button
						type="primary"
						htmlType="submit"
						loading={isLoading}
						className="supplierForm__button"
					>
						{submitText}
					</Button>
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	currentUser: state.session.currentUser,
	creating: state.buyers.creating,
	minimal: ownProps.minimal,
	updating: state.buyers.updating,
	newAccountSetupMode: ownProps.newAccountSetupMode,
});

const mapDispatchToProps = (dispatch) => ({
	getBuyerFacilities: (name) => dispatch(buyerFacilitiesRestCrudThunksForBuyer.read({ name })),
	create: (entity) => dispatch(buyersRestCrudThunksForBuyer.create(entity)),
	update: (entity) => dispatch(buyersRestCrudThunksForBuyer.update(entity)),
	associateBuyerContactWithFacility: (contact, facilityId) =>
		dispatch(manuallyAssociateBuyerContactWithFacility(contact, facilityId)),
	refreshCurrentUser: () => dispatch(checkUserTypeToken(ROLE_TYPES.BUYER)()),
});

export default withRouter<any, any>(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<BuyerCompanyFormProps>()(BuyerCompanyForm))
);

import { LOCATION_TYPES_CRUD_ACTION_CREATORS } from '../actions/location_types_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';

export const locationTypesRestCrudThunksForBuyer = new RestCrudThunks(
	'location_types',
	'location_type',
	'locationType',
	'locationTypes',
	LOCATION_TYPES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'location/'
);
export const locationTypesRestCrudThunksForSupplier = new RestCrudThunks(
	'location_types',
	'location_type',
	'locationType',
	'locationTypes',
	LOCATION_TYPES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'location/'
);

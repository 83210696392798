import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Card } from 'antd';
import FieldTechForm from '../field_tech_form/FieldTechForm';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;

class NewFieldTechPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
	}

	render() {
		const { history } = this.props;
		return (
			<Content>
				<LogOnMountWithStandardEventProperties eventType="visited supplier new field tech page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<Card>
							<div>
								<h5 style={{ marginBottom: 24 }}>Add Field Technician</h5>
								<FieldTechForm
									formData={{}}
									onSuccess={(rec) => history.push(`/supplier/fieldTechs/detail/${rec.email}`)}
								/>
							</div>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(NewFieldTechPage));

import * as React from 'react';
import { Input } from 'antd';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { debounce } from '../../utils/PerformanceUtils';
import { ReactNode } from 'react';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';

const style = require('./VisualCheckboxInput.less');

interface OWAsyncRadioProps {
	stateSlice: any;
	targetCollectionName: string;
	placeholder?: string;
	valueAccessor?: any;
	additionalFilters?: any;
	outerStyle?: any;
	optionStyle?: any;
	initialPagination?: any;
	size?: 'default' | 'large' | 'small';
	style?: any;
	mode?: 'default' | 'multiple' | 'tags' | 'combobox';
	fieldName: any;
	value?: any;
	onChange?: any;
	onClick?: any;
	loading?: boolean;
	disabled?: boolean;
	emptyWhenDisabled?: boolean;
	sortBy?: any;
	keepFetchedOrder?: boolean;

	fetchMultiple(ids: string[] | number[], targetCollectionName?: string): void;

	renderRecord(data: any): ReactNode;

	fetchData(
		searchText: string,
		targetCollectionName: string,
		pagination?: any,
		sorting?: any,
		filters?: any,
		addToTargetCollection?: boolean
	): any;
}

const defaultPagination = { current: 1, pageSize: 250 };

export default class OWAsyncCheckbox extends React.Component<OWAsyncRadioProps, any> {
	constructor(props) {
		super(props);
		const value = props.value;
		this.state = {
			value: new Set(value),
			loading: true,
		};
	}

	componentWillReceiveProps(nextProps) {
		// Should be a controlled component.
		if ('value' in nextProps) {
			this.setState({ value: new Set(nextProps.value) });
		}
		return null;
	}

	handleLoadMore = debounce(() => {
		const { stateSlice, loading, targetCollectionName } = this.props;
		if (loading) {
			return;
		}
		let pagination = targetCollectionName
			? nullSafeGet(`${targetCollectionName}.pagination`, stateSlice)
			: nullSafeGet(`pagination`, stateSlice);
		const newCurrent = pagination.current + 1;
		const newPagination = { current: newCurrent, pageSize: pagination.pageSize };
		const count = nullSafeGet(`${this.props.targetCollectionName}.count`, this.props.stateSlice);
		if ((newCurrent - 1) * pagination.pageSize <= count) {
			this.props.fetchData(
				this.state.searchText && this.state.searchText,
				this.props.targetCollectionName,
				newPagination,
				this.props.sortBy,
				this.props.additionalFilters,
				true
			);
			this.setState({ pagination: newPagination });
		}
	}, 500);

	componentDidMount() {
		const { value } = this.state;
		const { targetCollectionName } = this.props;
		if (value) {
			this.props.fetchMultiple(value instanceof Set ? Array.from(value) : value);
			this.props
				.fetchData(
					'',
					targetCollectionName,
					this.props.initialPagination || defaultPagination,
					this.props.sortBy,
					this.props.additionalFilters,
					false
				)
				.then(() => {
					this.setState({ loading: false });
				});
		} else {
			this.props
				.fetchData(
					'',
					targetCollectionName,
					this.props.initialPagination || defaultPagination,
					this.props.sortBy,
					this.props.additionalFilters,
					false
				)
				.then(() => {
					this.setState({ loading: false });
				});
		}
	}

	handleSameValueClicked = (e) => {
		const { onClick } = this.props;
		if (nullSafeGet('target.value', e) == this.state.value && onClick) {
			onClick(e.target.value);
		}
	};

	handleValueChange = (e) => {
		const nextVal = e.target.value;
		if (this.state.value.has(nextVal)) {
			this.state.value.delete(nextVal);
			this.triggerChange(Array.from(this.state.value));
		} else if (!isNaN(parseInt(nextVal)) && this.state.value.has(parseInt(nextVal))) {
			this.state.value.delete(parseInt(nextVal));
			this.triggerChange(Array.from(this.state.value));
		} else {
			if (!('value' in this.props)) {
				this.setState({ value: this.state.value.add(nextVal) });
			}
			this.triggerChange(Array.from(this.state.value.add(e.target.value)));
		}
	};

	triggerChange = (changedValue) => {
		// Should provide an event to pass value to Form.
		const { onChange } = this.props;
		if (onChange) {
			onChange(changedValue);
		}
	};

	handleSearchDebounced = (e) => {
		const searchText = e.target.value && e.target.value.trim();
		this.handleSearch(searchText);
	};

	handleSearch = debounce(
		(searchText) => {
			this.props.fetchData(
				searchText && searchText.trim(),
				this.props.targetCollectionName,
				this.props.initialPagination || defaultPagination,
				this.props.sortBy,
				this.props.additionalFilters,
				false
			);
			this.setState({
				searchText: searchText && searchText.trim(),
				pagination: this.props.initialPagination || defaultPagination,
			});
		},
		200,
		false
	);

	render() {
		const { value } = this.state;
		const {
			disabled,
			keepFetchedOrder,
			fieldName,
			emptyWhenDisabled,
			valueAccessor = (el) => el.id,
			stateSlice,
			targetCollectionName,
			mode,
			placeholder,
			size,
			style,
			renderRecord,
			outerStyle = {},
			optionStyle = { height: 228 },
		} = this.props;
		const selectedVals = value instanceof Set ? Array.from(value) : value;
		const selectedRecords = selectedVals
			.map((v) => stateSlice.records[v])
			.filter((r) => r && valueAccessor(r));
		const dropdownRecords = getRecordsForTargetCollection(stateSlice, targetCollectionName);
		const visibleRecords = selectedRecords.concat(
			dropdownRecords.filter((r) => !selectedVals.some((val) => valueAccessor(r) == val))
		);
		const sortedVisibleRecords = keepFetchedOrder
			? visibleRecords
			: visibleRecords.sort((a, b) => (a.name > b.name ? 1 : -1));
		const { loading } = this.state;
		return (
			<div>
				{sortedVisibleRecords.length <= 8 &&
				(!this.state.searchText || this.state.searchText.length === 0) ? null : (
					<div style={{ paddingBottom: 16, textAlign: 'center' }}>
						<Input.Search
							size="large"
							style={{ width: 320 }}
							onChange={this.handleSearchDebounced}
						/>
					</div>
				)}
				{loading ? (
					<PageLoadingPlaceholder />
				) : sortedVisibleRecords.some((option) => option.icon) ? (
					<div className="radio-container  radio-container--center state-in-box">
						{sortedVisibleRecords.map((option) => (
							<div key={valueAccessor(option)} className={option.disabled ? `disabled` : ''}>
								<div
									className={`radio-outer ${
										this.state.value.has(valueAccessor(option)) ||
										this.state.value.has(valueAccessor(option).toString())
											? 'checked'
											: ''
									} ${option.disabled ? 'disabled' : ''}`}
									style={optionStyle}
								>
									<input
										key={valueAccessor(option)}
										type="checkbox"
										name={fieldName}
										id={valueAccessor(option)}
										required={option.required}
										disabled={option.disabled}
										onClick={this.handleSameValueClicked}
										checked={
											this.state.value.has(valueAccessor(option)) ||
											this.state.value.has(valueAccessor(option).toString())
										}
										onChange={this.handleValueChange}
										value={valueAccessor(option)}
									/>
									<label htmlFor={valueAccessor(option)}>
										<span data-pop-class="radio-pop">
											<div>
												<div className="state-in-box-inner">
													<div className="state-map">
														<i className={`icons8-font ${option.icon}`} />
													</div>
													<p>{renderRecord(option)}</p>
												</div>
											</div>
										</span>
									</label>
									<br />
									<div className="radio-box">
										<div className="radio-round"></div>
									</div>
								</div>
								<span className="feature-list"></span>
							</div>
						))}
					</div>
				) : (
					<div className="radio-container radio-container--center">
						{sortedVisibleRecords.map((option) => (
							<div key={valueAccessor(option)} className={option.disabled ? `disabled` : ''}>
								<div
									className={`radio-outer ${
										this.state.value.has(valueAccessor(option)) ||
										this.state.value.has(valueAccessor(option).toString())
											? 'checked'
											: ''
									} ${option.disabled ? 'disabled' : ''}`}
									style={optionStyle}
								>
									<input
										key={valueAccessor(option)}
										type="checkbox"
										name={fieldName}
										id={valueAccessor(option)}
										required={option.required}
										disabled={option.disabled}
										onClick={this.handleSameValueClicked}
										checked={
											this.state.value.has(valueAccessor(option)) ||
											this.state.value.has(valueAccessor(option).toString())
										}
										onChange={this.handleValueChange}
										value={valueAccessor(option)}
									/>
									<label htmlFor={valueAccessor(option)}>
										<span data-pop-class="radio-pop">
											<p>{renderRecord(option)}</p>
										</span>
									</label>
									<br />
									<div className="radio-box">
										<div className="radio-round"></div>
									</div>
								</div>
								<span className="feature-list"></span>
							</div>
						))}
					</div>
				)}
			</div>
		);
	}
}

import * as React from 'react';
import { Table, Pagination, Skeleton, ConfigProvider } from 'antd';
import { connect } from 'react-redux';
import { PaginationProps } from 'antd/es/pagination';
import { getEntitiesById, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { isEqual } from 'lodash';
import MapboxGallery from '../mapboxgallery/MapboxGallery';
import { withRouter } from 'react-router';
import { DEFAULT_SORTER, GLOBAL_DEBOUNCE_TIME } from '../../utils/DataConstants';

const queryString = require('qs');
require('./PaginatedReduxTable.less');
const debounce = require('debounce-promise');

interface ReduxTableProps {
	location: any;
	history: any;
	match: any;
	collectionName: string;
	targetCollectionName: string;
	data: any[];
	columns: any[];
	showHeader: boolean | null;
	mode?: 'list' | 'map';
	rowSelection?: any;
	rowClassName?: any;
	tableLayoutFixed?: boolean;
	additionalParams: any;
	updateQueryParams?: boolean;
	expandedRowRender: any;
	longitudeAccessor?: any;
	latitudeAccessor?: any;
	popupRenderFunc?: any;
	enableMultiSelect?: Boolean;
	filters: any;
	sorting: any;
	emptyState?: any;
	onRow?: any;
	initialPagination: PaginationProps;
	hiddenPagination: any;
	hidePagination: any;
	initialFilters: any;
	initialSorting: any;
	pagination: any;
	fetching: boolean;
	components?: any;
	keyAccessor: (record: any) => any;
	fetchData: (
		params: any,
		targetCollectionName: string | null,
		pagination: PaginationProps,
		sorting: { sort_by: string; order: string } | {},
		filters: any
	) => any;
}

class ReduxTable extends React.Component<ReduxTableProps, any> {
	constructor(props) {
		super(props);
		this.handleTableChange = this.handleTableChange.bind(this);
		this.state = {
			initialLoading: true,
		};
	}

	getDefaultSortOrder(columns) {
		const defaultSortCol = columns.find((col) => col.hasOwnProperty('defaultSortOrder'));
		return defaultSortCol
			? {
					sort_by: defaultSortCol.dataIndex,
					order: defaultSortCol.defaultSortOrder,
			  }
			: DEFAULT_SORTER;
	}
	getDefaultPagination() {
		return {
			current: 1,
			pageSize: 10,
			pageSizeOptions: ['5', '10', '25', '50'],
			showSizeChanger: true,
		};
	}

	debouncedFetchData = debounce(this.props.fetchData, GLOBAL_DEBOUNCE_TIME);

	handleTableChange(tablePagination, tableFilters, tableSorting) {
		const {
			history,
			location,
			updateQueryParams,
			targetCollectionName,
			pagination,
			filters,
			sorting,
			hiddenPagination,
			additionalParams = {},
		} = this.props;
		let newSorting;
		if (tableSorting.columnKey && tableSorting.order) {
			newSorting = { ...sorting, sort_by: tableSorting.columnKey, order: tableSorting.order };
		} else {
			if (!isEqual(sorting, {})) {
				newSorting = { ...sorting };
			} else {
				newSorting = { ...this.getDefaultSortOrder(this.props.columns) };
			}
		}
		if (updateQueryParams) {
			const queryParams = queryString.stringify({
				current: tablePagination.current,
				pageSize: tablePagination.pageSize,
				...newSorting,
				...filters,
				...additionalParams,
			});
			history.replace(`${location.pathname}?${queryParams}`);
		}

		const newPagination2 = {
			...pagination,
			...hiddenPagination,
			current: tablePagination.current == undefined ? 1 : tablePagination.current,
			pageSize: tablePagination.pageSize == undefined ? '5 / page' : tablePagination.pageSize,
		};
		this.debouncedFetchData(
			additionalParams,
			targetCollectionName,
			newPagination2,
			newSorting,
			filters
		);
	}

	componentDidMount() {
		const {
			targetCollectionName,
			initialPagination,
			initialSorting,
			columns,
			initialFilters,
			additionalParams,
		} = this.props;
		const defaultSort = this.getDefaultSortOrder(columns);
		const sortProps = defaultSort
			? {
					...defaultSort,
					...(nullSafeGet('sort_by', initialSorting) && { sort_by: initialSorting.sort_by }),
					...(nullSafeGet('order', initialSorting) && { order: initialSorting.order }),
			  }
			: {};

		let pagination = this.getDefaultPagination();
		if (initialPagination) {
			pagination.current = parseInt(
				nullSafeGetOrElse('current', initialPagination, pagination.current)
			);
			pagination.pageSize = parseInt(
				nullSafeGetOrElse('pageSize', initialPagination, pagination.pageSize)
			);
			pagination.pageSizeOptions = nullSafeGetOrElse(
				'pageSizeOptions',
				initialPagination,
				pagination.pageSizeOptions
			);
		}
		this.debouncedFetchData(
			additionalParams || {},
			targetCollectionName,
			pagination,
			sortProps,
			initialFilters
		).then(() => {
			this.setState({ initialLoading: false });
		});
	}

	componentWillReceiveProps(nextProps) {
		const {
			updateQueryParams,
			history,
			location,
			fetching,
			targetCollectionName,
			additionalParams,
			filters,
			pagination,
			sorting,
		} = this.props;
		const { initialLoading } = this.state;

		if (!initialLoading) {
			const filtersChanged = !isEqual(nextProps.filters, filters);
			let nextPropsPagination = nextProps.pagination;
			if (nextProps.hiddenPagination) {
				nextPropsPagination = {
					...nextProps.pagination,
					...nextProps.hiddenPagination,
				};
			}
			const paginationChanged = !isEqual(
				{ current: nextPropsPagination.current, pageSize: nextPropsPagination.pageSize },
				{ current: pagination.current, pageSize: pagination.pageSize }
			);
			const sortingChanged = !isEqual(nextProps.sorting, sorting);
			const additionalParamsChanged = !isEqual(nextProps.additionalParams, additionalParams);
			const propsChanged =
				filtersChanged || paginationChanged || sortingChanged || additionalParamsChanged;

			if (nextPropsPagination.total !== pagination.total || sortingChanged || filtersChanged) {
				nextPropsPagination = { ...pagination, current: 1 };
				if (pagination.hasOwnProperty('handleSizeChange')) {
					pagination.handleSizeChange(nextPropsPagination.current, nextPropsPagination.pageSize);
				}
			}
			if (propsChanged && !fetching) {
				if (updateQueryParams) {
					const queryParams = queryString.stringify({
						...{
							current: nextPropsPagination.current,
							pageSize: nextPropsPagination.pageSize,
						},
						...nextProps.sorting,
						...nextProps.filters,
						...nextProps.additionalParams,
					});
					history.replace(`${location.pathname}?${queryParams}`);
				}
				this.debouncedFetchData(
					nextProps.additionalParams || {},
					targetCollectionName,
					{ ...nextPropsPagination, showSizeChanger: true },
					nextProps.sorting,
					nextProps.filters
				);
			}
		}
	}

	render() {
		const {
			columns,
			mode = 'list',
			onRow,
			showHeader = true,
			emptyState,
			keyAccessor,
			popupRenderFunc,
			data,
			rowSelection,
			rowClassName = 'clickable-paginated-index-row',
			fetching,
			tableLayoutFixed = false,
			filters,
			sorting,
			expandedRowRender,
			longitudeAccessor,
			latitudeAccessor,
			pagination,
			components,
			hidePagination,
		} = this.props;
		const getSkeletonData = () => {
			if (pagination && pagination.pageSize) {
				let numRows = pagination.pageSize;
				let skeletonRows = [];
				for (let i = 0; i < numRows; i++) {
					skeletonRows.push({ id: i });
				}
				return skeletonRows;
			} else {
				return [{ id: 1 }, { id: 2 }, { id: 3 }];
			}
		};

		return this.state.initialLoading ? (
			<Table
				rowClassName={rowClassName}
				columns={columns.map((col) => ({
					...col,
					render: (text) => <Skeleton paragraph={{ rows: 2 }} title={false} />,
				}))}
				dataSource={getSkeletonData()}
				showHeader={showHeader}
				pagination={false}
			/>
		) : // : data.length === 0 && emptyState && !fetching ? (
		// 	emptyState
		mode === 'map' ? (
			<div>
				<MapboxGallery
					popupRenderFunc={popupRenderFunc}
					geolocatedEntities={data}
					hideSearchWithinArea={true}
					baseLayerStyle="light"
					longitudeAccessor={longitudeAccessor}
					latitudeAccessor={latitudeAccessor}
				/>
				<Pagination
					style={{ position: 'absolute', zIndex: 2, bottom: 24, right: 32 }}
					{...pagination}
					onChange={(current, pageSize) =>
						this.handleTableChange(
							{
								current,
								pageSize,
							},
							filters,
							sorting
						)
					}
				/>
			</div>
		) : (
			<ConfigProvider renderEmpty={() => emptyState || undefined}>
				<Table
					className={tableLayoutFixed ? 'table-fixed-100' : 'PaginatedReduxTable'}
					rowClassName={rowClassName}
					columns={columns}
					rowKey={keyAccessor}
					dataSource={data}
					showHeader={showHeader}
					pagination={hidePagination ? false : pagination}
					rowSelection={rowSelection}
					expandedRowRender={expandedRowRender}
					loading={fetching}
					onRow={onRow}
					onChange={this.handleTableChange}
					components={components}
				/>
			</ConfigProvider>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const { targetCollectionName, collectionName } = ownProps;
	const relevantState = state[collectionName];
	if (!relevantState) {
		throw new Error(
			'collection not found in root reducer. please check name for typos or add reducer for collection'
		);
	}

	if (targetCollectionName && !relevantState[targetCollectionName]) {
		throw new Error(
			'target collection not found in root reducer. please check name for typos or add reducer for collection'
		);
	}

	let mappedProps = {
		emptyState: ownProps.emptyState,
		collectionName: ownProps.collectionName,
		targetCollectionName: ownProps.targetCollectionName,
		showHeader: ownProps.showHeader,
		onRow: ownProps.onRow,
		popupRenderFunc: ownProps.popupRenderFunc,
		keyAccessor: ownProps.keyAccessor,
		rowSelection: ownProps.rowSelection,
		mode: ownProps.mode,
		updateQueryParams: ownProps.updateQueryParams,
		data: null,
		pagination: null,
		filters: null,
		sorting: null,
		fetching: null,
	};

	let relevantRecords;
	if (ownProps.useDistinctCollection) {
		relevantRecords = relevantState.distinctRecords;
	} else {
		relevantRecords = relevantState.records;
	}
	if (targetCollectionName) {
		mappedProps.data = getEntitiesById(
			relevantState[ownProps.targetCollectionName].recordsIds,
			relevantRecords
		);
		mappedProps.pagination = relevantState[ownProps.targetCollectionName].pagination;
		mappedProps.filters = relevantState[ownProps.targetCollectionName].filters;
		mappedProps.sorting = relevantState[ownProps.targetCollectionName].sorting;
		mappedProps.fetching = relevantState[ownProps.targetCollectionName].fetching;
	} else {
		mappedProps.data = getEntitiesById(relevantState.recordsIds, relevantRecords);
		mappedProps.pagination = relevantState.pagination;
		mappedProps.filters = relevantState.filters;
		mappedProps.sorting = relevantState.sorting;
		mappedProps.fetching = relevantState.fetching;
	}
	return mappedProps;
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchData: (params, targetCollectionName, pagination, sorting, filters) =>
		dispatch(ownProps.fetchData(params, targetCollectionName, pagination, sorting, filters)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReduxTable));

export const PAYMENTS_START = 'PAYMENTS_START';
export function paymentsStart() {
	return {
		type: PAYMENTS_START,
	};
}

export const PAYMENTS_SUCCESS = 'PAYMENTS_SUCCESS';
export function paymentsSuccess(paymentToken, customerId, email) {
	return {
		type: PAYMENTS_SUCCESS,
		paymentToken,
		customerId,
		email,
	};
}

export const PAYMENTS_ERROR = 'PAYMENTS_ERROR';
export function paymentsError(error) {
	return {
		type: PAYMENTS_ERROR,
		error,
	};
}

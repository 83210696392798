import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { PART_CATALOGS_CRUD_ACTION_CREATORS } from '../actions/part_catalogs_actions';
import FetchUtils, { parseJSON } from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { logoutSuccess } from '../actions/session_actions';
import { message } from 'antd';

const fetchUtils = new FetchUtils();
const BASE_URL = getBackendUri();

export const partCatalogsRestCrudThunksForSupplier = new RestCrudThunks(
	'part_catalogs',
	'part_catalog',
	'partCatalog',
	'partCatalogs',
	PART_CATALOGS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'inventory/'
);

export const replacePartVendorsForSupplier = (partId, entities) => {
	const apiUrl = `/api/v1/supplier/inventory/part_catalogs/replace_vendors_without_cost_update`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.patch(BASE_URL + apiUrl + `/${partId}`, {
					data: entities,
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
				})
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(PART_CATALOGS_CRUD_ACTION_CREATORS.updateError(d.message, partId));
						reject(d.message);
					} else {
						dispatch(PART_CATALOGS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(PART_CATALOGS_CRUD_ACTION_CREATORS.updateError(d.message, partId));
					reject(d.message);
				});
		});
	};
};

export const getPartCatalogByPartId = (partId) => {
	const apiUrl = `/api/v1/supplier/inventory/part_catalogs/by_part_id/${partId}`;
	return new Promise((resolve, reject) => {
		fetchUtils
			.get(BASE_URL + apiUrl, {
				headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
			})
			.then(parseJSON)
			.then((d) => {
				if (d.status === 'error') {
					reject(d.message);
				} else {
					resolve(d.json.data);
				}
			})
			.catch((d) => {
				reject(d.message);
			});
	});
};

const updatePartCatalogsListPrice = (roleType) => (data) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/inventory/part_catalogs/update_list_prices`;

	// if sorting present, fix order naming and add to query params

	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(PART_CATALOGS_CRUD_ACTION_CREATORS.updateStart({}));

			fetchUtils
				.put(
					baseUrl + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data,
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(PART_CATALOGS_CRUD_ACTION_CREATORS.updateError(d.message, {}));
						reject(d);
					} else {
						dispatch(PART_CATALOGS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(PART_CATALOGS_CRUD_ACTION_CREATORS.updateError(d.message, {}));
					reject(d);
				});
		});
	};
};

export const updatePartCatalogsListPriceForBuyer = updatePartCatalogsListPrice(ROLE_TYPES.BUYER);
export const updatePartCatalogsListPriceForSupplier = updatePartCatalogsListPrice(
	ROLE_TYPES.SUPPLIER
);

const downloadPartCatalogPriceCSV =
	(roleType) =>
	(params, filters = null) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/inventory/part_catalogs/csv_report_generator`;

		// if sorting present, fix order naming and add to query params

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.message);
							message.error(d.data);
						} else {
							resolve(d.data);
							const hide = message.loading(d.data, 0);
							setTimeout(hide, 7000);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};

export const downloadPartCatalogPriceCSVForSupplier = downloadPartCatalogPriceCSV(
	ROLE_TYPES.SUPPLIER
);

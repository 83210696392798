import * as React from 'react';
import { BLACKS_AND_WHITES, STOPLIGHT_COLORS } from '../../utils/DataFormatterUtils';
import { WORK_ORDER_STATUS_DISPLAY_NAMES } from '../../constants/WorkOrderStatuses';

export default ({
	displayStatus,
	status,
	darkMode = false,
	style = {},
	textStyle = {},
	...props
}) => {
	let statusColor;
	let displayStatusDisplayName, statusDisplayName;

	switch (displayStatus) {
		case 'Pending':
			statusColor = STOPLIGHT_COLORS.pureRed;
			displayStatusDisplayName = 'Requested';
			break;
		case 'Open':
			statusColor = STOPLIGHT_COLORS.pureRed;
			displayStatusDisplayName = 'Open';
			break;
		case 'Deferred':
			statusColor = 'rgb(213, 125, 5)';
			displayStatusDisplayName = 'Deferred';
			break;
		case 'InProgress':
			statusColor = STOPLIGHT_COLORS.yellowGreen;
			displayStatusDisplayName = 'In Progress';
			break;
		case 'OnHold':
			statusColor = STOPLIGHT_COLORS.pureYellow;
			displayStatusDisplayName = 'On Hold';
			break;
		case 'Completed':
			statusColor = STOPLIGHT_COLORS.pureGreen;
			displayStatusDisplayName = 'Completed';
			break;
		case 'Cancelled':
			statusColor = BLACKS_AND_WHITES.blackTertiary;
			displayStatusDisplayName = 'Cancelled';
			break;
		default:
			statusColor = BLACKS_AND_WHITES.blackPrimary;
			displayStatusDisplayName = 'Unknown';
	}

	if (darkMode) {
		statusColor = BLACKS_AND_WHITES.whiteSecondary;
	}

	statusDisplayName = WORK_ORDER_STATUS_DISPLAY_NAMES[status] || 'Unknown';

	return (
		<div style={style} {...props}>
			<span
				style={{
					color: statusColor,
					...textStyle,
				}}
			>
				{displayStatusDisplayName} - {statusDisplayName}
			</span>
		</div>
	);
};

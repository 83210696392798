import * as React from 'react';
import { Avatar } from 'antd';
import { nullSafeGet } from '../../utils/DataAccessUtils';

const style = require('./UserNotificationAvatar.less');

const notificationTypeConfig = {
	WorkOrder: {
		icon: 'icons8-wrench-filled',
		backgroundColor: 'rgb(83, 41, 142)',
	},
	WorkOrderNotes: {
		icon: 'icons8-chat-bubble',
		backgroundColor: 'rgb(130, 131, 38)',
	},

	PlannedMaintenanceWorkOrder: {
		icon: 'icons8-planner-2',
		backgroundColor: 'rgb(44, 38, 131)',
	},
	SupplierInitiatedWorkOrder: {
		icon: 'icons8-wrench-filled',
		backgroundColor: 'rgb(38, 111, 131)',
	},
	RFP: {
		icon: 'icons8-auction-2',
		backgroundColor: 'rgb(131, 38, 38)',
	},
	Invoice: {
		icon: 'icons8-bill-2',
		backgroundColor: 'rgb(38, 131, 41)',
	},
	Review: {
		icon: 'icons8-popular-topic',
		backgroundColor: 'rgb(130, 131, 38)',
	},

	WorkOrderNotesMentions: {
		icon: 'icons8-chat-bubble',
		backgroundColor: 'rgb(130, 131, 38)',
	},

	Unassigned: {
		icon: 'icons8-wrench-filled',
		backgroundColor: 'rgb(83, 41, 142)',
	},
	AssignedToInternalTech: {
		icon: 'icons8-wrench-filled',
		backgroundColor: 'rgb(83, 41, 142)',
	},
	InternalTechWorkComplete: {
		icon: 'icons8-wrench-filled',
		backgroundColor: 'rgb(83, 41, 142)',
	},
	InternalTechWorkReviewedAndCompleted: {
		icon: 'icons8-wrench-filled',
		backgroundColor: 'rgb(83, 41, 142)',
	},
	PendingConfirmationByServiceProvider: {
		icon: 'icons8-wrench-filled',
		backgroundColor: 'rgb(83, 41, 142)',
	},
	ConfirmedByServiceProvider: {
		icon: 'icons8-wrench-filled',
		backgroundColor: 'rgb(83, 41, 142)',
	},
	TechAssigned: {
		icon: 'icons8-wrench-filled',
		backgroundColor: 'rgb(83, 41, 142)',
	},
	TechScheduled: {
		icon: 'icons8-wrench-filled',
		backgroundColor: 'rgb(83, 41, 142)',
	},
	TechRescheduled: {
		icon: 'icons8-wrench-filled',
		backgroundColor: 'rgb(83, 41, 142)',
	},
	TechWorkingOnSite: {
		icon: 'icons8-wrench-filled',
		backgroundColor: 'rgb(83, 41, 142)',
	},
	WorkIncompleteWithReason: {
		icon: 'icons8-wrench-filled',
		backgroundColor: 'rgb(83, 41, 142)',
	},
	PartsOnOrder: {
		icon: 'icons8-wrench-filled',
		backgroundColor: 'rgb(83, 41, 142)',
	},
	PartsReceived: {
		icon: 'icons8-wrench-filled',
		backgroundColor: 'rgb(83, 41, 142)',
	},
	PartsRequested: {
		icon: 'icons8-wrench-filled',
		backgroundColor: 'rgb(83, 41, 142)',
	},
	DelayedByThirdParty: {
		icon: 'icons8-wrench-filled',
		backgroundColor: 'rgb(83, 41, 142)',
	},
	CancelledWithReason: {
		icon: 'icons8-wrench-filled',
		backgroundColor: 'rgb(83, 41, 142)',
	},
	WorkOrderApproval: {
		icon: 'icons8-wrench-filled',
		backgroundColor: 'rgb(83, 41, 142)',
	},
	WorkOrderApprovalReminder: {
		icon: 'icons8-wrench-filled',
		backgroundColor: 'rgb(83, 41, 142)',
	},
	PendingApproval: {
		icon: 'icons8-wrench-filled',
		backgroundColor: 'rgb(83, 41, 142)',
	},
	WaitingForReview: {
		icon: 'icons8-wrench-filled',
		backgroundColor: 'rgb(83, 41, 142)',
	},
	SupplierInitiatedPendingApproval: {
		icon: 'icons8-wrench-filled',
		backgroundColor: 'rgb(83, 41, 142)',
	},

	Invoiced: {
		icon: 'icons8-bill-2',
		backgroundColor: 'rgb(38, 131, 41)',
	},
	InvoiceApproval: {
		icon: 'icons8-bill-2',
		backgroundColor: 'rgb(38, 131, 41)',
	},
	InvoiceApprovalReminder: {
		icon: 'icons8-bill-2',
		backgroundColor: 'rgb(38, 131, 41)',
	},
	ProposalApproval: {
		icon: 'icons8-billing-machine',
		backgroundColor: 'rgb(38, 131, 41)',
	},
	ProposalApprovalReminder: {
		icon: 'icons8-billing-machine',
		backgroundColor: 'rgb(38, 131, 41)',
	},
	RFP_RequestProposal: {
		icon: 'icons8-billing-machine',
		backgroundColor: 'rgb(38, 131, 41)',
	},
	RFP_Participating: {
		icon: 'icons8-billing-machine',
		backgroundColor: 'rgb(38, 131, 41)',
	},
	RFP_NotParticipating: {
		icon: 'icons8-billing-machine',
		backgroundColor: 'rgb(38, 131, 41)',
	},
	RFP_Awarded: {
		icon: 'icons8-billing-machine',
		backgroundColor: 'rgb(38, 131, 41)',
	},
	RFP_Declined: {
		icon: 'icons8-billing-machine',
		backgroundColor: 'rgb(38, 131, 41)',
	},
	RFP_RequestForProposalEnded: {
		icon: 'icons8-billing-machine',
		backgroundColor: 'rgb(38, 131, 41)',
	},
	WaitingForQuote: {
		icon: 'icons8-billing-machine',
		backgroundColor: 'rgb(38, 131, 41)',
	},
	Proposal: {
		icon: 'icons8-billing-machine',
		backgroundColor: 'rgb(38, 131, 41)',
	},
	ProposalAwarded: {
		icon: 'icons8-billing-machine',
		backgroundColor: 'rgb(38, 131, 41)',
	},
	ProposalDeclined: {
		icon: 'icons8-billing-machine',
		backgroundColor: 'rgb(38, 131, 41)',
	},
};

export const UserNotificationAvatar = ({ notification, userType }) => {
	const notificationType = nullSafeGet(
		`${userType}PushNotification.notificationType`,
		notification
	);
	const notificationConfig = notificationTypeConfig[notificationType] || {};
	return (
		<Avatar
			className="userNotificationAvatar"
			style={{ backgroundColor: notificationConfig.backgroundColor }}
		>
			<i className={`icons8-font  ${notificationConfig.icon}`} />
		</Avatar>
	);
};

import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	locationTypesRestCrudThunksForBuyer,
	locationTypesRestCrudThunksForSupplier,
} from '../../thunks/location_types_thunks';
import LocationTypeForm from '../location_type_form/LocationTypeForm';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { ROLE_TYPES } from '../../utils/DataConstants';

require('./EditLocationTypePage.less');

const EditLocationTypePage: FC<any> = ({
	userType,
	getLocationType,
	match,
	history,
	locationType,
	fetching,
}): React.ReactElement => {
	const locationTemplateId = useMemo(() => match.params.id, [match.params.id]);

	useEffect(() => {
		getLocationType(locationTemplateId);
	}, [getLocationType, locationTemplateId]);

	const editOnSuccess = useCallback(
		(rec) => {
			history.push(`/${userType}/locations/templates/detail/${rec.id}`);
		},
		[history, userType]
	);

	return (
		<>
			<LogOnMountWithStandardEventProperties
				eventType={`visited ${userType} edit location type page`}
			/>
			,
			<EditPage
				title="Edit Location Type"
				formComponentProps={{
					formData: locationType,
					onSuccess: editOnSuccess,
				}}
				fetching={fetching}
				FormComponent={LocationTypeForm}
			/>
			,
		</>
	);
};

const mapStateToProps = (state, ownProps) => {
	return {
		history: ownProps.history,
		locationType: state.location_types.detail,
		fetching: state.location_types.fetching,
		match: ownProps.match,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	getLocationType: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationTypesRestCrudThunksForSupplier.readOne(id)
				: locationTypesRestCrudThunksForBuyer.readOne(id)
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(EditLocationTypePage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

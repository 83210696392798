import * as React from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Alert, Button } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { getProtectedImageUriForSupplier } from '../../utils/FileAccessUtils';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import { addContactToEventSupplierTeamFormData } from '../../actions/event_supplier_teams_actions';
import {
	supplierContactsRestCrudThunksForSupplier,
	supplierSignUpInvite,
} from '../../thunks/supplier_contacts_thunks';
import { retrieveCachedUserDetails } from '../../thunks/session_thunks';
import { imageUploadValidation } from '../../utils/ImageUtils';
import OWUpload from '../OWUpload';

const style = require('./SupplierContactForm.less');
const FormItem = Form.Item;

interface SupplierContactProps {
	creating: boolean;
	updating: boolean;
	minimal: boolean;
	sourcingEventId: number;
	formData: any;
	addTeamMember?: any;
	history: any;
	newAccountSetupMode: any;
	redirectForwardUrl?: string;
	goPrev?: any;
	inviteNewUser: any;
	addContactToEventSupplierTeamFormData: any;
	createAndAddToEventSupplierTeamFormData: any;
	createErrors: any[];
	updateErrors: any[];
	currentUser: any;
	create: any;
	update: any;
}

interface SupplierContactFormProps extends FormComponentProps {
	creating: boolean;
	updating: boolean;
	minimal: boolean;
	sourcingEventId: number;
	formData: any;
	addTeamMember?: any;
	history: any;
	newAccountSetupMode: any;
	redirectForwardUrl?: string;
	goPrev?: any;
	inviteNewUser: any;
	addContactToEventSupplierTeamFormData: any;
	createAndAddToEventSupplierTeamFormData: any;
	createErrors: any[];
	updateErrors: any[];
	currentUser: any;
	create: any;
	update: any;
}

class SupplierContactForm extends React.Component<SupplierContactFormProps, any> {
	handleSubmit = (e, submitAction) => {
		const { history, newAccountSetupMode, redirectForwardUrl } = this.props;
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				let contact = {
					...values,
				};

				if (newAccountSetupMode) {
					submitAction(contact).then(() => {
						history.push('/supplier/company/me');
					});
				} else {
					submitAction(contact).then(() => {
						if (redirectForwardUrl) {
							history.push(redirectForwardUrl);
						}
					});
				}
			}
		});
	};

	componentDidMount() {
		const { formData } = this.props;
		this.setState({ photoLink: formData.photoLink });
	}

	state = {
		uploadLoading: false,
		photoLink: null,
	};

	createAndAddToEventSupplierTeamFormData = (contact) => {
		const { inviteNewUser, addTeamMember, sourcingEventId } = this.props;
		return inviteNewUser(contact).then((completeContact) => {
			addTeamMember(completeContact, 'watcher');
		});
	};

	handleUploadChange = (info) => {
		if (info.file.status === 'uploading') {
			this.setState({ uploadLoading: true });
			return;
		}
		if (info.file.status === 'done') {
			const fileName = nullSafeGetOrElse('file.response.data.fileName', info, '');
			const fileId = nullSafeGetOrElse('file.response.data.fileId', info, '');
			this.setState({
				uploadLoading: false,
				photoLink: `${fileId}/${fileName}`,
			});
			this.props.form.setFieldsValue({ photoLink: `${fileId}/${fileName}` });
		}
	};

	render() {
		const BACKEND_URI = getBackendUri();

		const {
			updating,
			sourcingEventId,
			inviteNewUser,
			creating,
			form,
			update,
			create,
			createErrors,
			formData,
			updateErrors,
			currentUser,
			minimal,
			goPrev,
		} = this.props;
		const { photoLink } = this.state;
		const { getFieldDecorator, setFieldsValue } = form;
		const uploadHeaders = { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token };

		const MINIMAL_FORM_FIELDS_REQUIRED = ['email'];
		const FULL_FORM_FIELDS_REQUIRED = MINIMAL_FORM_FIELDS_REQUIRED;
		const minimalFieldsRequired = new Set(MINIMAL_FORM_FIELDS_REQUIRED);
		const fullFieldsRequired = new Set(FULL_FORM_FIELDS_REQUIRED);

		const fieldsRequired = minimal ? minimalFieldsRequired : fullFieldsRequired;

		const isUpdate = formData && formData.email !== undefined;
		let submitAction = null;
		let submitText = '';
		if (sourcingEventId) {
			submitAction = this.createAndAddToEventSupplierTeamFormData;
			submitText = 'Invite user';
		} else if (minimal && !sourcingEventId) {
			submitAction = inviteNewUser;
			submitText = 'Invite user';
		} else {
			submitAction = isUpdate ? update : create;
			submitText = isUpdate ? 'Update contact' : 'Create contact';
		}

		const isLoading = updating || creating;
		getFieldDecorator('photoLink', { initialValue: formData.photoLink });
		getFieldDecorator('supplierFacilityId', {
			initialValue:
				formData.supplierFacilityId ||
				nullSafeGet('facility.id', formData) ||
				nullSafeGet('facility.id', currentUser),
		});
		const uploadButton = (
			<div>
				<LegacyIcon type={this.state.uploadLoading ? 'loading' : 'plus'} />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		let imgFileId, imgFileName, photoUri;
		if (photoLink) {
			[imgFileId, imgFileName] = photoLink.split('/');
			photoUri = getProtectedImageUriForSupplier(imgFileId, imgFileName, 'auto', 240);
		}

		return (
			<Form
				layout="vertical"
				onSubmit={(e) => this.handleSubmit(e, submitAction)}
				className="supplierForm"
			>
				{createErrors.length > 0 ? (
					<FormItem>
						<Alert message={createErrors.join(' ')} type="error" />
					</FormItem>
				) : null}
				{updateErrors.length > 0 ? (
					<FormItem>
						<Alert message={updateErrors.join(' ')} type="error" />
					</FormItem>
				) : null}
				<FormItem label="Email">
					{getFieldDecorator('email', {
						initialValue: formData.email,
					})(<Input style={{ maxWidth: 400 }} disabled={isUpdate} />)}
				</FormItem>
				<FormItem label="Name" required={true}>
					{getFieldDecorator('nameGiven', {
						rules: [
							{
								required: fieldsRequired.has('nameGiven'),
								message: 'Please input name.',
							},
						],
						initialValue: formData.nameGiven,
					})(<Input placeholder="Elon" style={{ maxWidth: 200 }} />)}
					{getFieldDecorator('nameFamily', {
						rules: [
							{
								required: fieldsRequired.has('nameFamily'),
								message: 'Please input name.',
							},
						],
						initialValue: formData.nameFamily,
					})(<Input placeholder="Musk" style={{ maxWidth: 200, marginLeft: 8 }} />)}
					{getFieldDecorator('nameSuffix', {
						initialValue: formData.nameSuffix,
					})(<Input placeholder="Suffix" style={{ maxWidth: 65, marginLeft: 8 }} />)}
				</FormItem>
				{minimal ? null : (
					<div>
						<FormItem label="Department">
							{getFieldDecorator('department', {
								rules: [
									{
										required: fieldsRequired.has('department'),
										message: 'Please input department.',
									},
								],
								initialValue: formData.department,
							})(<Input placeholder="Department" style={{ maxWidth: 250 }} />)}
						</FormItem>
						<FormItem label="Title">
							{getFieldDecorator('title', {
								rules: [
									{
										required: fieldsRequired.has('title'),
										message: 'Please input title.',
									},
								],
								initialValue: formData.title,
							})(<Input placeholder="Job title" style={{ maxWidth: 250 }} />)}
						</FormItem>
						<FormItem label="Work phone">
							{getFieldDecorator('phoneWork', {
								rules: [
									{
										required: fieldsRequired.has('phoneWork'),
										message: 'Please input a work phone number.',
									},
								],
								initialValue: formData.phoneWork,
							})(<Input style={{ maxWidth: 300 }} />)}
						</FormItem>
						<FormItem label="Mobile phone">
							{getFieldDecorator('phoneMobile', {
								rules: [
									{
										required: fieldsRequired.has('phoneMobile'),
										message: 'Please input a mobile phone number.',
									},
								],
								initialValue: formData.phoneMobile,
							})(<Input style={{ maxWidth: 300 }} />)}
						</FormItem>
						<FormItem label="Profile photo">
							<OWUpload
								name="file"
								listType="picture-card"
								className="avatar-uploader"
								showUploadList={false}
								headers={uploadHeaders}
								action={`${BACKEND_URI}/api/v1/supplier/file/upload`}
								beforeUpload={imageUploadValidation}
								onChange={this.handleUploadChange}
							>
								{photoLink ? (
									<img src={photoUri} style={{ maxHeight: 104 }} alt="" />
								) : (
									uploadButton
								)}
							</OWUpload>
						</FormItem>
					</div>
				)}
				<FormItem>
					{goPrev ? (
						<Button style={{ opacity: 1, marginRight: 16 }} size="large" onClick={goPrev}>
							Back
						</Button>
					) : null}
					<Button
						size="large"
						type="primary"
						htmlType="submit"
						loading={isLoading}
						className="contactForm__button"
					>
						{submitText}
					</Button>
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	minimal: ownProps.minimal,
	sourcingEventId: ownProps.sourcingEventId,

	formData: ownProps.formData,
	addTeamMember: ownProps.addTeamMember,
	newAccountSetupMode: ownProps.newAccountSetupMode,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	goPrev: ownProps.goPrev,
	createErrors: state.supplier_contacts.createErrors,
	updateErrors: state.supplier_contacts.updateErrors,
	creating: state.supplier_contacts.creating,
	updating: state.supplier_contacts.updating,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	update: (entity) => dispatch(supplierContactsRestCrudThunksForSupplier.update(entity)),
	create: (entity) => dispatch(supplierContactsRestCrudThunksForSupplier.create(entity)),
	inviteNewUser: (contact) => dispatch(supplierSignUpInvite(contact)),
	addContactToEventSupplierTeamFormData: (contact, sourcingEventId) => {
		const newInternalTeamMember = {
			sourcingEventId: sourcingEventId,
			contactEmail: contact.email,
			contact: contact,
			eventRole: 'watcher',
			supplierFacilityId: contact.supplierFacilityId,
			isNdaSigned: false,
		};
		dispatch(addContactToEventSupplierTeamFormData(newInternalTeamMember));
	},
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<SupplierContactFormProps>()(SupplierContactForm))
);

import * as React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Alert, Button } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import BackButton from '../back_button/BackButton';
import { siteSurveyTemplatesRestCrudThunksForBuyer } from '../../thunks/site_survey_templates_thunks';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { taskListsRestCrudThunksForBuyer } from '../../thunks/task_lists_thunks';
import AddTask from '../common/AddTask';

const style = require('./SiteSurveyTemplateForm.less');
const FormItem = Form.Item;

interface SiteSurveyTemplateProps {
	creating: boolean;
	updating: boolean;
	formData: any;
	history: any;
	redirectForwardUrl?: string;
	onSuccess?: any;
	createErrors: any[];
	updateErrors: any[];
	currentUser: any;
	create: any;
	update: any;
	createTaskList: any;
	updateTaskList: any;
}

interface SiteSurveyTemplateFormProps extends FormComponentProps {
	creating: boolean;
	updating: boolean;
	formData: any;
	history: any;
	redirectForwardUrl?: string;
	onSuccess?: any;
	createErrors: any[];
	updateErrors: any[];
	currentUser: any;
	create: any;
	update: any;
	createTaskList: any;
	updateTaskList: any;
}

let taskUuid = 1;
let choiceUuid = 2;

class SiteSurveyTemplateForm extends React.Component<SiteSurveyTemplateFormProps, any> {
	handleSubmit = (e, submitAction) => {
		const { form, history, onSuccess, redirectForwardUrl, createTaskList, updateTaskList } =
			this.props;
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const taskKeys = form.getFieldValue('taskKeys');
				const tasks = taskKeys
					.map((taskKey) => {
						let task = {
							title: form.getFieldValue(`tasks-${taskKey}-title`),
							description: form.getFieldValue(`tasks-${taskKey}-description`),
							groupTitle: form.getFieldValue(`tasks-${taskKey}-groupTitle`),
							questionType: form.getFieldValue(`tasks-${taskKey}-type`),
							multipleChoicesWithLabel: [],
							orderingId: form.getFieldValue(`tasks-${taskKey}-orderingId`),
						};
						if (task.questionType === 'multipleChoice') {
							form.getFieldValue(`tasks-${taskKey}-choiceKeys`).map((choiceKey) => {
								const choice = {
									description: form.getFieldValue(
										`tasks-${taskKey}-choices-${choiceKey}-description`
									),
									value: form.getFieldValue(`tasks-${taskKey}-choices-${choiceKey}-value`),
								};
								task.multipleChoicesWithLabel.push(choice);
							});
						}
						return task;
					})
					.filter((el) => el.title !== undefined);

				const newTaskList = {
					id: values.taskListId,
					title: values.title,
					buyerCompanyId: values.buyerCompanyId,
					tasks,
				};

				let siteSurveyTemplate = {
					id: values.id,
					title: values.title,
					description: values.description,
				};

				values.taskListId
					? updateTaskList(newTaskList).then((taskList) => {
							submitAction({
								...siteSurveyTemplate,
								taskListId: taskList.id,
							}).then((record) => {
								if (redirectForwardUrl) {
									history.push(redirectForwardUrl);
								}
								if (onSuccess) {
									onSuccess(record);
								}
							});
					  })
					: createTaskList(newTaskList).then((taskList) => {
							submitAction({
								...siteSurveyTemplate,
								taskListId: taskList.id,
							}).then((record) => {
								if (redirectForwardUrl) {
									history.push(redirectForwardUrl);
								}
								if (onSuccess) {
									onSuccess(record);
								}
							});
					  });
			}
		});
	};

	removeTask = (k) => {
		const { form } = this.props;
		// can use data-binding to get
		const taskKeys = form.getFieldValue('taskKeys');
		// We need at least one task
		if (taskKeys.length === 1) {
			return;
		}

		// can use data-binding to set
		form.setFieldsValue({
			taskKeys: taskKeys.filter((key) => key !== k),
		});
	};

	removeChoice = (taskId, choiceId) => {
		const { form } = this.props;
		// can use data-binding to get
		const choices = form.getFieldValue(`tasks-${taskId}-choiceKeys`);
		// We need at least one choice for multiple choices tasks
		if (choices.length === 1) {
			return;
		}

		// can use data-binding to set
		form.setFieldsValue({
			[`tasks-${taskId}-choiceKeys`]: choices.filter((choice) => choice !== choiceId),
		});
	};

	addTask = () => {
		const { form } = this.props;
		// can use data-binding to get
		const taskKeys = form.getFieldValue('taskKeys');
		const nextKeys = taskKeys.concat(taskUuid);
		// can use data-binding to set
		// important! notify form to detect changes
		form.setFieldsValue({
			taskKeys: nextKeys,
		});
		taskUuid++;
	};

	addChoice = (taskId) => {
		const { form } = this.props;
		// can use data-binding to get
		const choices = form.getFieldValue(`tasks-${taskId}-choiceKeys`);
		const nextChoices = choices.concat(choiceUuid);
		// can use data-binding to set
		// important! notify form to detect changes
		form.setFieldsValue({
			[`tasks-${taskId}-choiceKeys`]: nextChoices,
		});
		choiceUuid++;
	};

	updateChoiceKeys = (taskId, taskType, optionElement) => {
		const { form } = this.props;
		const choiceKeys = form.getFieldValue(`tasks-${taskId}-choiceKeys`);
		if (taskType === 'multipleChoice') {
			if (choiceKeys.length == 0) form.setFieldsValue({ [`tasks-${taskId}-choiceKeys`]: [0, 1] });
		} else {
			form.setFieldsValue({ [`tasks-${taskId}-choiceKeys`]: [] });
		}
	};

	render() {
		const BACKEND_URI = getBackendUri();
		const {
			updating,
			creating,
			form,
			update,
			create,
			createErrors,
			formData,
			updateErrors,
			currentUser,
		} = this.props;
		const { getFieldDecorator, setFieldsValue } = form;
		const isUpdate = formData && formData.id !== undefined;
		const submitAction = isUpdate ? update : create;
		const submitText = isUpdate ? 'Update site survey template' : 'Create site survey template';
		const isLoading = updating || creating;
		getFieldDecorator(`id`, { initialValue: formData.id });
		getFieldDecorator(`taskListId`, { initialValue: formData.taskListId });
		getFieldDecorator('buyerCompanyId', {
			initialValue: nullSafeGet('facility.buyerCompanyId', currentUser),
		});

		const taskKeysInitialValue =
			formData.taskKeys ||
			(formData.tasks && formData.tasks.length > 0 ? formData.tasks.map((el, idx) => idx) : [0]);
		form.getFieldDecorator('taskKeys', { initialValue: taskKeysInitialValue });

		const taskKeys = form.getFieldValue('taskKeys');

		taskKeys.forEach((k, index) => {
			const taskType = nullSafeGet(`tasks.${k}.taskType`, formData);
			const taskChoices = nullSafeGet(`tasks.${k}.multipleChoicesWithLabel`, formData);
			const multipleChoiceDefaultKeys =
				taskChoices && taskChoices.length > 0 ? taskChoices.map((el, idx) => idx) : [0, 1];
			const defaultChoiceKeys = taskType === 'multipleChoice' ? multipleChoiceDefaultKeys : [];
			form.getFieldDecorator(`tasks-${k}-choiceKeys`, {
				initialValue: nullSafeGetOrElse(`tasks-${k}-choiceKeys`, formData, defaultChoiceKeys),
			});
		});

		const formItems = taskKeys.map((k, taskIndex) => {
			return (
				<AddTask
					key={k}
					taskKeys={taskKeys}
					taskKey={k}
					taskIndex={taskIndex}
					form={form}
					formData={formData}
					removeTask={this.removeTask}
					updateChoiceKeys={this.updateChoiceKeys}
					addChoice={this.addChoice}
					removeChoice={this.removeChoice}
				/>
			);
		});

		return (
			<Form
				layout="vertical"
				onSubmit={(e) => this.handleSubmit(e, submitAction)}
				className="supplierForm"
			>
				{createErrors.length > 0 ? (
					<FormItem>
						<Alert message={createErrors.join(' ')} type="error" />
					</FormItem>
				) : null}
				{updateErrors.length > 0 ? (
					<FormItem>
						<Alert message={updateErrors.join(' ')} type="error" />
					</FormItem>
				) : null}
				<FormItem label="Name">
					{getFieldDecorator('title', {
						initialValue: formData.title,
						rules: [
							{
								required: true,
								message: 'Please enter a title',
							},
						],
					})(<Input style={{ maxWidth: 300 }} disabled={isUpdate} />)}
				</FormItem>
				<FormItem label="Description">
					{getFieldDecorator('description', {
						initialValue: formData.description,
					})(<Input.TextArea style={{ maxWidth: 640 }} />)}
				</FormItem>
				{formItems}
				<Form.Item label="">
					<Button type="dashed" onClick={this.addTask} style={{ width: '100%', height: 217 }}>
						<PlusOutlined translate="" /> Add task
					</Button>
				</Form.Item>
				<FormItem>
					<Button
						type="primary"
						htmlType="submit"
						loading={isLoading}
						className="contactForm__button"
					>
						{submitText}
					</Button>
					<span style={{ marginLeft: '16px' }}>
						<BackButton buttonText="Cancel" />
					</span>
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	formData: ownProps.formData,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,

	createErrors: state.site_survey_templates.createErrors,
	updateErrors: state.site_survey_templates.updateErrors,
	creating: state.site_survey_templates.creating,
	updating: state.site_survey_templates.updating,
	currentUser: state.session.currentUser,
	taskLists: state.task_lists,
});

const mapDispatchToProps = (dispatch) => ({
	update: (entity) => dispatch(siteSurveyTemplatesRestCrudThunksForBuyer.update(entity)),
	create: (entity) => dispatch(siteSurveyTemplatesRestCrudThunksForBuyer.create(entity)),
	createTaskList: (entity) => dispatch(taskListsRestCrudThunksForBuyer.create(entity)),
	updateTaskList: (entity) => dispatch(taskListsRestCrudThunksForBuyer.update(entity)),
});

export default withRouter<any, any>(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<SiteSurveyTemplateFormProps>()(SiteSurveyTemplateForm))
);

import * as React from 'react';
import { Layout } from 'antd';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import ApprovalHierarchyComponent from '../approval_hierarchy/ApprovalHierarchyComponent';

const { Content } = Layout;

const style = require('./BuyerAdminApprovalPage.less');

function BuyerAdminApprovalPage({ match }) {
	return <ApprovalHierarchyComponent id={match.params.id} />;
}

const mapStateToProps = (state, ownProps) => ({
	match: ownProps.match,
	history: ownProps.history,
	currentUser: state.session.currentUser,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(BuyerAdminApprovalPage)
);

import React, { FC } from 'react';
import { Button } from 'antd';
import { withRouter } from 'react-router';

const BackButton: FC<any> = ({ history, buttonText, ...props }) => (
	<Button {...props} onClick={() => history.goBack()}>
		{buttonText}
	</Button>
);

export default withRouter<any, any>(BackButton);

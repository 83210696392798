import * as React from 'react';
import { Redirect, Route, withRouter } from 'react-router';
import { Col, Layout, Row } from 'antd';
// import {withLastLocation} from 'react-router-last-location';
import SubnavTabs from '../subnav_tabs/SubnavTabs';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';
import { connect } from 'react-redux';
import { clearNavbarState, updateNavbarState } from '../../actions/navbar_actions';
import { getBackLinkStates } from '../../utils/HistoryUtils';

const Color = require('color');
const style = require('./DetailPageHeader.less');

class DetailPageHeader extends React.Component<any, any> {
	componentDidMount() {
		const {
			updateNavbar,
			backLinkText,
			backLinkTo,
			backgroundColor = '#6237a0',
			location,
		} = this.props;

		const { backLinkText: text, backLinkTo: link } = getBackLinkStates(location, backLinkTo) as any;

		updateNavbar(
			text || backLinkText,
			link || backLinkTo,
			Color(backgroundColor).darken(0.2).toString()
		);
	}

	componentWillReceiveProps(nextProps) {
		const { updateNavbar, backLinkText, backLinkTo, backgroundColor = '#6237a0' } = nextProps;

		if (
			backLinkText !== this.props.backLinkText ||
			backLinkTo !== this.props.backLinkTo ||
			Color(backgroundColor).hex() !== Color(this.props.backgroundColor).hex()
		) {
			updateNavbar(backLinkText, backLinkTo, Color(backgroundColor).darken(0.2).toString());
		}
	}

	componentWillUnmount() {
		const { clearNavbar } = this.props;
		clearNavbar();
	}

	render() {
		const {
			exactPath,
			redirectPath,
			lastLocation,
			backLinkText,
			backLinkTo,
			avatar = null,
			title = null,
			subtitle = null,
			actions = null,
			backgroundColor = '#6237a0',
			tabs = [],
			handleTabChange,
			checkTabActive,
			childRoutes = [],
			subtext,
			tabsRightActions,
			...otherProps
		} = this.props;
		return (
			<div {...otherProps}>
				{redirectPath ? (
					<Route
						path={exactPath}
						exact={true}
						render={(props) => (
							<Redirect
								to={{
									pathname: redirectPath,
								}}
							/>
						)}
					/>
				) : null}
				<Layout.Content className="detailPage">
					{/*<ScrollToTopOnMount/>*/}
					{/*<BackTop/>*/}
					<div
						style={{
							padding: '16px 40px 24px',
							paddingBottom: 0,
							background: backgroundColor,
							marginBottom: 16,
						}}
					>
						<Row gutter={16} style={{ marginBottom: 8 }}>
							<Col
								lg={24}
								xl={16}
								xxl={18}
								style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}
							>
								{avatar ? avatar : null}
								<div style={{ width: '100%' }}>
									<h4 className="detailPage__title">{title}</h4>
									<div className="detailPage__subtitle">
										{subtitle ? <div className="h6 detailPage__subtitle">{subtitle}</div> : null}
										{subtext ? <div className="h5 detailPage__subtitle">{subtext}</div> : null}
									</div>
								</div>
							</Col>
							<Col
								lg={24}
								xl={8}
								xxl={6}
								style={{
									marginBottom: 16,
									flexWrap: 'wrap',
									display: 'flex',
									justifyContent: 'end',
									alignItems: 'center',
									alignContent: 'center',
								}}
							>
								{actions}
							</Col>
						</Row>
						<SubnavTabs
							tabsRightActions={tabsRightActions}
							tabs={tabs}
							onChange={handleTabChange}
							isActive={checkTabActive}
						/>
					</div>
					{childRoutes.map((r, i) => (
						<RouteWithSubRoutes key={i} {...r} />
					))}
				</Layout.Content>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({});
const mapDispatchToProps = (dispatch) => ({
	updateNavbar: (backlinkTitle, backlinkTo, navbarBackgroundColor) =>
		dispatch(updateNavbarState(backlinkTitle, backlinkTo, navbarBackgroundColor)),
	clearNavbar: () => dispatch(clearNavbarState()),
});
export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(DetailPageHeader));

import { getBackendUri } from '../utils/EnvConfigUtils';
import FetchUtils, { parseJSON } from './fetch_utils';
import { logoutSuccess } from '../actions/session_actions';
import { paymentsError, paymentsStart, paymentsSuccess } from '../actions/payments_actions';

const baseUrl = getBackendUri();
const apiUrl = '/api/v1/supplier/setup_payments';
const fetchUtils = new FetchUtils();

export function savePaymentMethod(email: string, sourceToken: string, cardId: string) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(paymentsStart());

			fetchUtils
				.post(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: { email, sourceToken, cardId },
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					const d = json;
					if (d.status === 'error') {
						dispatch(paymentsError(d.message));
						reject(d.message);
					} else {
						dispatch(paymentsSuccess(d.data.sourceToken, d.data.customerId, d.data.email));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(paymentsError(d.message));
					reject(d.message);
				});
		});
	};
}

export function updatePaymentMethod(email: string, sourceToken: string, cardId: string) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(paymentsStart());

			fetchUtils
				.post(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: { email, sourceToken, cardId },
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					const d = json;
					if (d.status === 'error') {
						dispatch(paymentsError(d.message));
						reject(d.message);
					} else {
						dispatch(paymentsSuccess(d.data.sourceToken, d.data.customerId, d.data.email));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(paymentsError(d.message));
					reject(d.message);
				});
		});
	};
}

export function getPaymentMethod(email: string) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(paymentsStart());

			fetchUtils
				.get(
					baseUrl + apiUrl + `/${encodeURIComponent(email)}`,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					const d = json;
					if (d.status === 'error') {
						dispatch(paymentsError(d.message));
						reject(d.message);
					} else {
						dispatch(paymentsSuccess(d.data.sourceToken, d.data.customerId, d.data.email));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(paymentsError(d.message));
					reject(d.message);
				});
		});
	};
}

export function unlockSourcingEvent(id: string) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(paymentsStart());

			fetchUtils
				.patch(
					baseUrl + `/api/v1/supplier/sourcing_events/${id}/use_credits_to_unlock`,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					const d = json;
					if (d.status === 'error') {
						dispatch(paymentsError(d.message));
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(paymentsError(d.message));
					reject(d.message);
				});
		});
	};
}

import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MinusCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Alert, Button, DatePicker, Select, Row, Col, Table, List } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import {
	requestsForProposalRestCrudThunksForBuyer,
	requestsForProposalRestCrudThunksForSupplier,
} from '../../thunks/requests_for_proposal_thunks';
import moment from 'moment';
import { getObjectValues, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import FileUploader from '../file_uploader/FileUploader';
import { debounce } from '../../utils/PerformanceUtils';
import { capitalize, capitalizeEachWord } from '../../utils/DataFormatterUtils';
import {
	workOrdersRestCrudThunksForBuyer,
	workOrdersRestCrudThunksForSupplier,
} from '../../thunks/work_orders_thunks';
import { WorkOrderRowDisplay } from '../work_order_row_display/WorkOrderRowDisplay';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { Link } from 'react-router-dom';
import { FlexibleImageAvatar } from '../flexible-image-avatar/FlexibleImageAvatar';
import ClientShowMoreList from '../ClientShowMoreList/ClientShowMoreList';
import { SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS } from '../../actions/supplier_private_networks_actions';
import {
	supplierPrivateNetworksV2WithSupplierFacilityContactsForBuyer,
	supplierPrivateNetworksV2WithSupplierFacilityContactsForSupplier,
} from '../../thunks/supplier_private_networks_thunks';
import { BIDDERS_CRUD_ACTION_CREATORS } from '../../actions/bidders_actions';
import {
	biddersRestCrudThunksForBuyer,
	biddersRestCrudThunksForSupplier,
} from '../../thunks/bidders_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';
import OWRichTextEditor from '../../rich_text_editor/OWRichTextEditor';

require('./RequestForProposalForm.less');
const SUPPLIER_PRIVATE_NETWORKS_HYDRATED_INDEX = 'supplier_private_networks';
const SUPPLIER_PRIVATE_NETWORKS_INDEX_TARGET_COLLECTION = 'supplierPrivateNetworksIndex';

const defaultPagination = { current: 1, pageSize: 4 };

interface RequestForProposalFormProps extends FormComponentProps {
	formData: any;
	creating: boolean;
	updating: boolean;
	workOrder: any;
	createErrors: string[];
	updateErrors: string[];
	redirectForwardUrl: string;
	currentUser: any;
	currencies: any;
	history: any;
	create: any;
	update: any;
	userType: string;
	createBidder: any;
	updateBidder: any;
	destroyBidder: any;
	getWorkOrder: any;
	updateSupplierFacilitiesFilters: any;
	saveFormData: any;
	fetchSupplierPrivateNetworkWithContacts: any;
}

const DATE_FORMAT = 'MM-DD-YYYY';

const RequestForProposalForm: FC<RequestForProposalFormProps> = ({
	form,
	getWorkOrder,
	formData,
	history,
	saveFormData,
	createBidder,
	updateBidder,
	destroyBidder,
	userType,
	fetchSupplierPrivateNetworkWithContacts,
	workOrder,
	creating,
	updating,
	update,
	create,
	createErrors,
	updateErrors,
	currencies,
}): React.ReactElement => {
	const [bidders, setBidders] = useState([]);

	const { setFieldsValue } = form;

	const editorRef = useRef(null);

	useEffect(() => {
		if (formData.workOrderId) {
			getWorkOrder(formData.workOrderId).then((workOrder) => {
				setFieldsValue({ workOrderId: workOrder.id, locationId: workOrder.locationId });
			});
		}
	}, []);

	useEffect(() => {
		setBidders(formData.bidders || []);
	}, [formData.bidders]);

	const handleFileAttachmentUploadChange = useCallback(
		(newAttachments) => {
			form.setFieldsValue({ attachments: newAttachments });
		},
		[form]
	);

	const getDescription = useCallback(
		() => nullSafeGet('current', editorRef) && editorRef.current.getContent(),
		[]
	);

	const handleSubmit = useCallback(
		(e, submitAction) => {
			if (e) {
				e.preventDefault();
			}

			form.validateFields((err, values) => {
				if (err) {
					return;
				}

				const isUpdate = values.id !== undefined;
				let requestForProposal = {
					...values,
					description: getDescription(),
					workOrderId: parseInt(formData.workOrderId, 10),
				};

				const existingBidders = bidders.filter((el) => el.hasOwnProperty('createdAt'));
				const newBidders = bidders.filter((el) => !el.hasOwnProperty('createdAt'));
				const removedBidders = formData.removedBidders || [];
				saveFormData(formData);
				const updates = existingBidders.map((el) => updateBidder(el));
				const deletes = removedBidders.map((el) =>
					destroyBidder(el, 'requestForProposalId', 'supplierFacilityId')
				);

				if (isUpdate) {
					const newBiddersUpdate = newBidders.map((bidder) => {
						return {
							...bidder,
							requestForProposalId: values.id,
							workOrderId: parseInt(formData.workOrderId, 10),
						};
					});
					const creates = newBiddersUpdate.map((el) => createBidder(el));
					const allActions = [...creates, ...updates, ...deletes];
					return submitAction(requestForProposal, (rfp) => {
						Promise.all(allActions)
							.then((vals) => {
								history.push(`/${userType}/requestsForProposal/detail/${rfp.id}`);
							})
							.catch((err) => {
								console.log(err);
							});
					});
				} else {
					return submitAction(requestForProposal, (rfp) => {
						const newBiddersUpdate = newBidders.map((bidder) => ({
							...bidder,
							requestForProposalId: rfp.id,
							workOrderId: parseInt(formData.workOrderId, 10),
						}));
						const creates = newBiddersUpdate.map((el) => createBidder(el));
						const allActions = [...creates, ...updates, ...deletes];
						Promise.all(allActions)
							.then((vals) => {
								history.push(`/${userType}/requestsForProposal/detail/${rfp.id}`);
							})
							.catch((err) => {
								console.log(err);
							});
					});
				}
			});
		},
		[
			bidders,
			createBidder,
			destroyBidder,
			form,
			formData,
			getDescription,
			history,
			saveFormData,
			updateBidder,
			userType,
		]
	);

	const debouncedSearchCallback = debounce(
		(searchText) => {
			fetchSupplierPrivateNetworkWithContacts(
				{ supplierFacilityAlias: searchText && searchText.trim() },
				SUPPLIER_PRIVATE_NETWORKS_INDEX_TARGET_COLLECTION,
				defaultPagination
			);
		},
		500,
		false
	);

	const handleSearchDebounced = useCallback(
		(e) => {
			const searchText = e.target.value;
			debouncedSearchCallback(searchText);
		},
		[debouncedSearchCallback]
	);

	const isUpdate = useMemo(
		() => formData.hasOwnProperty('id') && formData.id !== undefined,
		[formData]
	);
	const submitText = useMemo(() => (isUpdate ? 'Update event' : 'Create event'), [isUpdate]);
	const submitAction = useMemo(() => (isUpdate ? update : create), [create, isUpdate, update]);
	const submitting = useMemo(() => creating || updating, [creating, updating]);

	const currencyRecordsList = useMemo(
		() => getObjectValues(currencies.records),
		[currencies.records]
	);
	const currencyRecordsMap = useMemo(() => currencies.records, [currencies.records]);

	const dueDateValidator = useCallback((rule, value, callback) => {
		const now = moment();
		if (value && value < now) {
			callback('Due date must be in the future.');
		}
		callback();
	}, []);

	const disabledDate = useCallback((current) => {
		// Can not select days before today and today
		return current && current < moment().endOf('day');
	}, []);

	const errorMessagesText = useMemo(
		() => createErrors.concat(updateErrors).join('; '),
		[createErrors, updateErrors]
	);

	const addBidder = useCallback(
		(supplierFacility, requestForProposalRoutedTo) => {
			const bidder = bidders.find((bidder) => bidder.supplierFacilityId === supplierFacility.id);
			const otherBidders = bidders.filter(
				(bidder) => bidder.supplierFacilityId !== supplierFacility.id
			);
			if (bidder) {
				const newBidder = {
					...bidder,
					primaryEmails: bidder.primaryEmails || [],
					primaryContacts: bidder.primaryContacts,
				};
				setBidders([...otherBidders, newBidder]);
			} else {
				const newBidder = {
					supplierFacilityId: supplierFacility.id,
					supplierFacility: supplierFacility,
					requestForProposalRoutedTo,
					primaryEmails: [],
					primaryContacts: [],
					bidderStatus: 'noResponse',
					awardStatus: 'pending',
				};
				setBidders([...otherBidders, newBidder]);
			}
		},
		[bidders]
	);

	const removeBidder = useCallback(
		(bidderAssoc) => {
			const { bidder, otherBidders } = bidders.reduce(
				(acc, b) => {
					return b.supplierFacilityId === bidderAssoc.supplierFacilityId
						? { ...acc, bidder: b }
						: { ...acc, otherBidders: [...acc.otherBidders, b] };
				},
				{
					bidder: undefined,
					otherBidders: [],
				}
			);
			const newBidder = {
				...bidder,
				primaryEmails: bidderAssoc.primaryEmails || [],
				primaryContacts: bidderAssoc.primaryContacts || [],
			};
			if (newBidder.hasOwnProperty('createdAt')) {
				if (!formData.removedBidders) {
					formData.removedBidders = [];
				}
				formData.removedBidders.push(newBidder);
			}
			setBidders(otherBidders);
			saveFormData(formData);
		},
		[bidders, formData, saveFormData]
	);

	const hasBidder = useCallback(
		(supplierFacility) => {
			return bidders && bidders.some((el) => el.supplierFacilityId === supplierFacility.id);
		},
		[bidders]
	);

	const columns = useMemo(
		() => [
			{
				title: 'Name',
				dataIndex: 'supplierFacilityId',
				render: (_, record) => (
					<div>
						<Link to={`/${userType}/suppliers/detail/${record.supplierFacilityId}`} target="_blank">
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ marginRight: 24 }}>
									<FlexibleImageAvatar
										displayName={nullSafeGetOrElse('supplierFacility.displayName', record, '')}
										showPopover={false}
										popoverText={nullSafeGetOrElse('supplierFacility.displayName', record, '')}
										width="48px"
										height="48px"
										imageUrl={record.supplierFacility.logoURL}
									/>
								</div>
								<div>
									<div
										style={{
											fontSize: 18,
											wordBreak: 'break-word',
										}}
									>
										{record.supplierFacility.displayName}
									</div>
								</div>
							</div>
						</Link>
						<div style={{ marginTop: 16, marginLeft: 72 }}>
							<ClientShowMoreList
								dataSource={[record]}
								itemLayout="horizontal"
								showAll={true}
								initialNumVisibleRecords={3}
								renderItem={(item) => (
									<List.Item
										actions={[
											<Button
												type="primary"
												disabled={hasBidder(record.supplierFacility)}
												onClick={() =>
													addBidder(record.supplierFacility, record.requestForProposalRoutedTo)
												}
											>
												{hasBidder(record) ? 'Invited' : 'Invite'}
											</Button>,
										]}
										extra=""
									>
										<List.Item.Meta
											avatar={null}
											title={null}
											description={nullSafeGetOrElse('requestForProposalRoutedTo', record, []).join(
												', '
											)}
										/>
										<div></div>
									</List.Item>
								)}
							/>
						</div>
					</div>
				),
			},
		],
		[addBidder, hasBidder, userType]
	);

	const columns2 = useMemo(
		() => [
			{
				title: 'Name',
				dataIndex: 'displayName',
				render: (_, record) => (
					<div>
						<div className="flex flex-row items-center justify-between">
							<Link to={`/${userType}/suppliers/detail/${record.id}`} target="_blank">
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={{ marginRight: 24 }}>
										<FlexibleImageAvatar
											displayName={nullSafeGetOrElse('supplierFacility.displayName', record, '')}
											showPopover={false}
											popoverText={nullSafeGetOrElse('supplierFacility.displayName', record, '')}
											width="48px"
											height="48px"
											imageUrl={record.supplierFacility.logoURL}
										/>
									</div>
									<div>
										<div
											style={{
												fontSize: 18,
												wordBreak: 'break-word',
											}}
										>
											{record.supplierFacility.displayName}
										</div>
									</div>
								</div>
							</Link>
							<MinusCircleOutlined
								translate=""
								className="dynamic-delete-button"
								onClick={() => removeBidder(record)}
							/>
						</div>
						<div style={{ marginTop: 16, marginLeft: 72 }}>
							<ClientShowMoreList
								dataSource={[record]}
								itemLayout="horizontal"
								showAll={true}
								initialNumVisibleRecords={3}
								renderItem={(item) => (
									<List.Item extra="">
										<List.Item.Meta
											avatar={null}
											title={
												<span>
													{item.nameGiven && item.nameFamily
														? capitalizeEachWord(
																[item.nameGiven, item.nameFamily, item.suffix].join(' ')
														  )
														: item.email}
												</span>
											}
											description={nullSafeGetOrElse('requestForProposalRoutedTo', record, []).join(
												', '
											)}
										/>
										<div></div>
									</List.Item>
								)}
							/>
						</div>
					</div>
				),
			},
		],
		[removeBidder, userType]
	);

	return (
		<Form className="requestForProposalForm" layout="vertical">
			{form.getFieldDecorator('id', { initialValue: formData.id })}
			{form.getFieldDecorator('status', { initialValue: formData.status || 'draft' })}

			<div style={{ marginBottom: 32 }}>
				<WorkOrderRowDisplay workOrder={workOrder} />
			</div>
			{createErrors.length > 0 || updateErrors.length > 0 ? (
				<Form.Item>
					<Alert message={errorMessagesText} type="error" />
				</Form.Item>
			) : null}
			<Form.Item label="Title">
				{form.getFieldDecorator('title', {
					initialValue: formData.title,
					rules: [{ required: true, message: 'Please give your request for proposal a title.' }],
				})(<Input placeholder="Give your request for proposal a title." />)}
			</Form.Item>
			<Form.Item label="Currency">
				{form.getFieldDecorator('currencyId', {
					rules: [{ required: true, message: 'Please select a currency for this event.' }],
					initialValue: formData.currencyId || 'USD',
				})(
					<Select
						showSearch={true}
						style={{ width: 300 }}
						optionFilterProp="children"
						filterOption={(input, option) =>
							currencyRecordsMap[option.props.value].displayNamePlural
								.toLowerCase()
								.indexOf(input.toLowerCase()) >= 0
						}
						placeholder="Currency"
					>
						{currencyRecordsList.map((currency) => (
							<Select.Option
								key={currency.id}
								value={currency.id}
								disabled={
									currency.abbreviation.toLowerCase() !== 'usd' &&
									currency.abbreviation.toLowerCase() !== 'cad'
								}
							>
								{capitalize(currency.displayNamePlural)} ({currency.abbreviation})
							</Select.Option>
						))}
					</Select>
				)}
			</Form.Item>
			<Form.Item label="Due Date">
				{form.getFieldDecorator('dueDate', {
					rules: [
						{
							type: 'object',
							required: true,
							message: 'Please select a due date for RFP responses!',
						},
						{ validator: dueDateValidator },
					],
					initialValue: formData.dueDate ? moment(formData.dueDate) : undefined,
				})(<DatePicker disabledDate={disabledDate} format={DATE_FORMAT} />)}
			</Form.Item>
			<Form.Item label="Additional details">
				<OWRichTextEditor initialEditorState={formData.description} editorRef={editorRef} />
			</Form.Item>
			<Form.Item label="File Attachments" style={{ width: 400 }}>
				{form.getFieldDecorator('attachments', { initialValue: formData.attachments || [] })(
					<FileUploader
						defaultFileList={formData.attachments || []}
						roleType={userType}
						handleUploadSuccess={handleFileAttachmentUploadChange}
						showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
						uploaderType="dragger"
					/>
				)}
			</Form.Item>
			<Form.Item label="Bidders" style={{ width: 1000 }}>
				<Row style={{ margin: 0 }} gutter={16}>
					<Col span={12}>
						<div style={{ width: '100%' }}>
							{createErrors.length > 0 || updateErrors.length > 0 ? (
								<div style={{ marginBottom: 24 }}>
									<Alert message={errorMessagesText} type="error" />
								</div>
							) : null}
							{bidders && bidders.length > 0 ? (
								<div style={{ minHeight: '473px' }}>
									<Table
										columns={columns2}
										showHeader={false}
										rowKey={(el) => el['supplierFacilityId']}
										dataSource={bidders}
									/>
								</div>
							) : (
								<div style={{ minHeight: '473px' }}>
									<div style={{ fontSize: '16px', margin: '16px 0' }}>
										You haven't added any bidders to this event yet.
									</div>
									<div style={{ display: 'flex', justifyContent: 'center' }}>
										<img
											style={{ margin: '32px 0', maxWidth: '400px' }}
											width="100%"
											height="auto"
											src="https://gw.alipayobjects.com/zos/rmsportal/KpnpchXsobRgLElEozzI.svg"
											alt="You haven't added any bidders to this event yet."
										/>
									</div>
								</div>
							)}
						</div>
					</Col>
					<Col span={12} style={{ padding: '0 0 0 40px' }}>
						<Input.Search
							placeholder="Search for more suppliers..."
							style={{ width: 300, marginTop: 8, marginBottom: 16 }}
							size="large"
							onChange={handleSearchDebounced}
						/>
						<PaginatedReduxTable
							collectionName={SUPPLIER_PRIVATE_NETWORKS_HYDRATED_INDEX}
							useDistinctCollection={true}
							showHeader={false}
							targetCollectionName={SUPPLIER_PRIVATE_NETWORKS_INDEX_TARGET_COLLECTION}
							columns={columns}
							keyAccessor={(el) => el.supplierFacilityId}
							initialPagination={defaultPagination}
							additionalParams={{
								spendCategoryId: nullSafeGet('workOrder.spendCategoryId', formData),
								locationId: nullSafeGet('workOrder.locationId', formData),
							}}
							fetchData={
								userType === ROLE_TYPES.SUPPLIER
									? supplierPrivateNetworksV2WithSupplierFacilityContactsForSupplier
									: supplierPrivateNetworksV2WithSupplierFacilityContactsForBuyer
							}
						/>
					</Col>
				</Row>
			</Form.Item>
			<Form.Item label="">
				<Button loading={submitting} type="primary" onClick={(e) => handleSubmit(e, submitAction)}>
					{submitText}
				</Button>
				<Button className="ml-2" loading={submitting} onClick={() => history.goBack()}>
					Cancel
				</Button>
			</Form.Item>
		</Form>
	);
};

const mapStateToProps = (state, ownProps) => ({
	formData: ownProps.formData,
	creating: state.requests_for_proposal.creating,
	updating: state.requests_for_proposal.updating,
	createErrors: state.requests_for_proposal.createErrors,
	updateErrors: state.requests_for_proposal.updateErrors,
	currentUser: state.session.currentUser,
	currencies: state.currencies,
	workOrder: state.work_orders.detail,
	history: ownProps.history,
	location: ownProps.location,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	saveFormData: (formData) => dispatch(BIDDERS_CRUD_ACTION_CREATORS.saveFormData(formData)),
	createBidder: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? biddersRestCrudThunksForSupplier.create(entity)
				: biddersRestCrudThunksForBuyer.create(entity)
		),
	updateBidder: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? biddersRestCrudThunksForSupplier.update(entity)
				: biddersRestCrudThunksForBuyer.update(entity)
		),
	destroyBidder: (entity, primaryKeyName, secondPrimaryKeyName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? biddersRestCrudThunksForSupplier.delete(entity, primaryKeyName, secondPrimaryKeyName)
				: biddersRestCrudThunksForBuyer.delete(entity, primaryKeyName, secondPrimaryKeyName)
		),
	create: (entity, onSuccess = null, onFailure = null) => {
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? requestsForProposalRestCrudThunksForSupplier.create(entity)
				: requestsForProposalRestCrudThunksForBuyer.create(entity)
		)
			.then((resp) => {
				if (onSuccess) onSuccess(resp);
			})
			.catch((resp) => {
				if (onFailure) onFailure(resp);
			});
	},
	update: (entity, onSuccess = null, onFailure = null) => {
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? requestsForProposalRestCrudThunksForSupplier.update(entity)
				: requestsForProposalRestCrudThunksForBuyer.update(entity)
		)
			.then((resp) => {
				if (onSuccess) onSuccess(resp);
			})
			.catch((resp) => {
				if (onFailure) onFailure(resp);
			});
	},
	getWorkOrder: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? workOrdersRestCrudThunksForSupplier.readOne(id)
				: workOrdersRestCrudThunksForBuyer.readOne(id)
		),
	fetchSupplierPrivateNetworkWithContacts: (params, tc, pagination) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? supplierPrivateNetworksV2WithSupplierFacilityContactsForSupplier(params, tc, pagination)
				: supplierPrivateNetworksV2WithSupplierFacilityContactsForBuyer(params, tc, pagination)
		),
	updateSupplierFacilitiesFilters: (filters, targetCollectionName) => {
		dispatch(
			SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollectionName)
		);
	},
});

const RFPFOrm = withRouter<any, any>(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<RequestForProposalFormProps>()(RequestForProposalForm))
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(RFPFOrm);

import * as React from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import moment from 'moment';

const style = require('./MeterRowDisplay.less');

export const MeterRowDisplay: React.FC<any> = ({ meter, ...props }) => {
	const latestReading: number = nullSafeGet('lastReading.value', meter);
	const latestReadingTime = nullSafeGet('lastReading.recordedAt', meter);
	const unitOfMeasure = nullSafeGet('valueUnit', meter) || '';

	return (
		<div className="meterRowDisplay" {...props}>
			<div>
				<div className="meterRowDisplay__rowTitle">
					<Ellipsis tooltip={true} length={64}>
						{nullSafeGet('name', meter)}
					</Ellipsis>
				</div>
			</div>
			<div className="meterRowDisplay__rightSide">
				<div className="meterRowDisplay__rowTitle">
					{latestReading ? latestReading + ' ' + unitOfMeasure : null}
				</div>
				<div className="meterRowDisplay__rowSubtitle">
					{latestReadingTime ? moment(latestReadingTime).fromNow() : null}
				</div>
				{/*<div className="meterRowDisplay__extra1">{meter.meterType.name}</div>*/}
			</div>
		</div>
	);
};

import * as React from 'react';
import { Button, Card, Col, Row, Select, Table } from 'antd';
import { getCurrency, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { dateFormatter, transformTypesTracked } from '../../utils/DataFormatterUtils';
import { budgetsRestCrudThunksForBuyer } from '../../thunks/budget_thunks';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { useMemo } from 'react';
import { KeyValueDisplay } from '../key_value_display/KeyValueDisplay';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import BudgetHeaderDisplayRow from '../budget_header_display_row/BudgetHeaderDisplayRow';
import { generateBackLink } from '../../utils/HistoryUtils';
import { updateNavbarState } from '../../actions/navbar_actions';
import BudgetCycleComponent from '../budget_cycle_component/BudgetCycleComponent';
import { BudgetDetailChart } from '../charts/BudgetDetailChart';
import moment from 'moment';
import DateTimeHover from '../date_time_component/DateTime';

const queryString = require('qs');

function BudgetDetailDetailsPage({
	budget,

	currentUser,
	match,
	history,
	location,
	loading,

	getBudget,
	deleteBudget,
	updateNavbar,
}) {
	const budgetId = match.params.id;
	useMemo(() => {
		updateNavbar(`Back to Budgets`, generateBackLink(`/buyer/admin/budgets`, location));
	}, [budgetId]);
	const currency = getCurrency({ currentUser });
	let searchString = location.search;
	if (searchString[0] === '?') {
		searchString = searchString.slice(1);
	}
	const queryParams = queryString.parse(searchString);

	const getPeriod = (months) => {
		switch (months) {
			case 1:
				return 'Monthly';
			case 3:
				return 'Quarterly';
			case 12:
			default:
				return 'Yearly';
		}
	};
	const selectedTypesTracked = nullSafeGetOrElse('accrualTypesTracked', budget, []);
	const typesTracked = [
		'pending_workorders',
		'approved_workorders',
		'pending_quotes',
		'approved_quotes',
		'pending_invoices',
		'approved_invoices',
	];
	const typesTrackedContent = typesTracked
		.filter((type) => selectedTypesTracked.includes(type))
		.map((type) => <div>{transformTypesTracked(type)}</div>);
	const problemTypesTracked = nullSafeGetOrElse('problemTypes', budget, []).map((type) => (
		<div>{nullSafeGet('name', type)}</div>
	));
	const suppliersTracked = nullSafeGetOrElse('supplierFacilities', budget, []).map((type) => (
		<div>{nullSafeGet('name', type)}</div>
	));
	let budgetDetails = useMemo(
		() =>
			loading
				? {}
				: {
						'Warning Threshold': `${parseFloat(nullSafeGet('thresholdPercentage', budget)).toFixed(
							0
						)}`.concat('%'),
						Created: (
							<DateTimeHover timestamp={nullSafeGet('createdAt', budget)} showTime={false} />
						),
						'Start Date': (
							<DateTimeHover timestamp={nullSafeGet('startDate', budget)} showTime={false} />
						),
						...(nullSafeGet('endDate', budget)
							? {
									'End Date': (
										<DateTimeHover timestamp={nullSafeGet('endDate', budget)} showTime={false} />
									),
							  }
							: {}),
						...(budget.trackOnlyCompletedWorkOrders
							? { 'Track Completed Work Orders': 'Yes' }
							: {}),
						...(nullSafeGet('months', budget)
							? {
									Period: getPeriod(nullSafeGet('months', budget)),
							  }
							: {}),
						...(budget.isCapex !== undefined
							? {
									'Expense Type': nullSafeGet('isCapex', budget) ? 'CapEx' : 'Repair & Maintenance',
							  }
							: {}),
						...(budget.isPM !== undefined
							? { 'Work Order Type': nullSafeGet('isPM', budget) ? 'Planned' : 'Reactive' }
							: {}),
				  },
		[budget]
	);

	const { budgetPerLocations } = budget;
	const isSharedLocationsBudget =
		nullSafeGet('sharedBudget', budget) && nullSafeGet('budgetPerLocations', budget);
	const locationTableData = loading
		? []
		: budgetPerLocations
		? budgetPerLocations.map((budgetLocation) => ({
				...budgetLocation,
				budgetRemaining:
					parseFloat(budgetLocation.budget) - parseFloat(budgetLocation.budgetConsumed),
		  }))
		: [
				{
					name: 'Company Wide',
					budget: nullSafeGetOrElse('sharedBudget', budget, 0),
					budgetAccrued: nullSafeGetOrElse('budgetAccrued', budget, 0),
					budgetConsumed: nullSafeGetOrElse('budgetConsumed', budget, 0),
					budgetSpent: nullSafeGetOrElse('budgetSpent', budget, 0),
					budgetRemaining:
						parseFloat(nullSafeGetOrElse('sharedBudget', budget, 0)) -
						parseFloat(nullSafeGetOrElse('budgetConsumed', budget, 0)),
				},
		  ];

	const columns = [
		{
			title: 'Location',
			dataIndex: 'name',
			width: 400,
			render: (text, record) => text,
			sorter: (a, b) => a.name.localeCompare(b.name),
		},
		...(!isSharedLocationsBudget
			? [
					{
						title: 'Budget Total',
						dataIndex: 'budget',
						width: 160,
						render: (text, record) => currency.format(text),
						sorter: (a, b) => a.budget - b.budget,
					},
					{
						title: 'Remaining',
						dataIndex: 'budgetRemaining',
						width: 160,
						render: (text, record) => currency.format(text),
						sorter: (a, b) => a.budgetRemaining - b.budgetRemaining,
					},
			  ]
			: []),
		{
			title: 'Accrued',
			dataIndex: 'budgetAccrued',
			width: 160,
			render: (text, record) => currency.format(text),
			sorter: (a, b) => a.budgetAccrued - b.budgetAccrued,
		},
		{
			title: 'Spent',
			dataIndex: 'budgetSpent',
			width: 160,
			render: (text, record) => currency.format(text),
			sorter: (a, b) => a.budgetSpent - b.budgetSpent,
		},
		{
			title: '',
			dataIndex: '',
			width: 100,
			render: (text, record) => <Button>Show Details</Button>,
		},
	];
	const budgetSpent = parseFloat(nullSafeGetOrElse('budgetSpent', budget, 0));
	const sharedBudget = nullSafeGetOrElse('sharedBudget', budget, 0);
	const budgetData = {
		budget: sharedBudget || 0,
		budgetAccrued: 0,
		budgetConsumed: 0,
		budgetSpent: 0,
	};
	locationTableData.map((item) => {
		if (!sharedBudget) {
			budgetData['budget'] = budgetData['budget'] + parseFloat(item['budget']);
		}
		budgetData['budgetAccrued'] = budgetData['budgetAccrued'] + parseFloat(item['budgetAccrued']);
		budgetData['budgetConsumed'] =
			budgetData['budgetConsumed'] + parseFloat(item['budgetConsumed']);
		budgetData['budgetSpent'] = budgetData['budgetSpent'] + parseFloat(item['budgetSpent']);
	});
	const budgetLimit = nullSafeGetOrElse(
		'budget',
		budgetData,
		nullSafeGetOrElse('sharedBudget', budget, 0)
	);
	const budgetMax = Math.max(budgetLimit, budgetSpent);
	const data =
		(budget &&
			budget.budgetSpentTimeSeriesData &&
			budget.budgetSpentTimeSeriesData.map((d, i) => ({
				day: d.dayNumber,
				dayKey: `${d.date}`,
				consumedAmount: 0,
				totalConsumedAmount: moment().diff(moment(d.date)) >= 0 ? parseFloat(d.total) : undefined,
			}))) ||
		[];
	const budgetChartConfig = {
		xAxisName: 'dayKey',
		yAxisName: 'totalConsumedAmount',
		position: 'dayKey*totalConsumedAmount',
		fillArea: false,
		cols: {
			dayKey: { alias: 'Day' },
			totalConsumedAmount: {
				min: 0,
				max: budgetMax,
				alias: 'Budget Spent',
			},
		},
		yAxisLabel: {
			textStyle: {
				fontSize: '12',
				fontFamily: 'Roboto',
				fill: 'rgba(0,0,0,0.85)',
			},
			formatter: (val, item) => {
				return currency.format(val);
			},
		},
		xAxisLabel: {
			textStyle: {
				fontSize: '12',
				fontFamily: 'Roboto',
				fill: 'rgba(0,0,0,0.85)',
			},
		},
	};

	return loading ? (
		<PageLoadingPlaceholder />
	) : (
		<Row style={{ margin: '8px 4px' }} gutter={8}>
			<Col className={'rowSpacing'} span={24}>
				<Row className={'items-baseline justify-between'}>
					<BudgetCycleComponent budget={budget} />
				</Row>
			</Col>
			<Col span={19}>
				<Row>
					<Col className={'rowSpacing'} span={24}>
						<BudgetHeaderDisplayRow
							budgetPerLocations={locationTableData}
							currency={currency}
							sharedBudget={nullSafeGetOrElse('sharedBudget', budget, 0)}
						/>
					</Col>
					<Col className={'rowSpacing'} span={24}>
						<Card>
							<BudgetDetailChart
								height={500}
								chartPadding={[40, 40, 40, 40]}
								data={data || []}
								xAxisName={nullSafeGet('xAxisName', budgetChartConfig)}
								yAxisName={nullSafeGet('yAxisName', budgetChartConfig)}
								yAxisLabel={nullSafeGet('yAxisLabel', budgetChartConfig)}
								fillArea={nullSafeGet('fillArea', budgetChartConfig)}
								cols={nullSafeGet('cols', budgetChartConfig)}
								position={nullSafeGet('position', budgetChartConfig)}
								currency={currency}
								budget={budgetData}
							/>
						</Card>
					</Col>
					<Col className={'rowSpacing'} span={24}>
						<Card>
							<Table
								onRow={(record, rowIndex) => {
									return {
										onClick: (event) => {
											history.push(
												`/buyer/budgets/detail/${budgetId}/locations/${
													record.locationId || 0
												}?${new URLSearchParams(queryParams)}`
											);
										},
									};
								}}
								dataSource={locationTableData}
								columns={columns}
								rowClassName={'cursor-pointer'}
							/>
						</Card>
					</Col>
				</Row>
			</Col>
			<Col span={5}>
				<Row>
					<Col span={24} className="rowSpacing">
						<Card title="Budget Details">
							<div style={{ padding: 0 }} className="materialCard__body">
								<div className="materialCard__supportingText">
									<KeyValueDisplay
										horizontal={false}
										keyWidth={120}
										keyValueStore={budgetDetails}
									/>
								</div>
							</div>
						</Card>
					</Col>
					<Col className={'rowSpacing'} span={24}>
						<Card title={'Accrual Types Tracked'}>
							{typesTrackedContent.length > 0 ? typesTrackedContent : 'No accrual types tracked'}
						</Card>
					</Col>
					{problemTypesTracked.length > 0 ? (
						<Col className={'rowSpacing'} span={24}>
							<Card title={'Problem Types'}>{problemTypesTracked}</Card>
						</Col>
					) : null}
					{suppliersTracked.length > 0 ? (
						<Col className={'rowSpacing'} span={24}>
							<Card title={'Suppliers'}>{suppliersTracked}</Card>
						</Col>
					) : null}
				</Row>
			</Col>
		</Row>
	);
}
const mapStateToProps = (state, ownProps) => ({
	budget: state.budgets.detail,
	loading: state.budgets.fetching,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
	location: ownProps.location,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	getBudget: (id) => dispatch(budgetsRestCrudThunksForBuyer.readOne(id)),
	deleteBudget: (budget) => dispatch(budgetsRestCrudThunksForBuyer.delete(budget)),
	updateNavbar: (backlinkTitle, backlinkTo, navbarBackgroundColor) =>
		dispatch(updateNavbarState(backlinkTitle, backlinkTo, navbarBackgroundColor)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BudgetDetailDetailsPage));

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	Layout,
	Row,
	Col,
	Card,
	Table,
	List,
	Tabs,
	Radio,
	Button,
	Checkbox,
	notification,
} from 'antd';
import { EmptyState } from '../empty_state/EmptyState';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import UserNotificationRowDisplay from '../user_notification_row_display/UserNotificationRowDisplay';
import { GLOBAL_DEBOUNCE_TIME, ROLE_TYPES } from '../../utils/DataConstants';
import {
	markAllUserNotificationsReadForBuyer,
	markAllUserNotificationsReadForSupplier,
	userNotificationsRestCrudThunksForBuyer,
	userNotificationsRestCrudThunksForSupplier,
} from '../../thunks/user_notifications_thunks';
import { USER_NOTIFICATIONS_CRUD_ACTION_CREATORS } from '../../actions/user_notifications_actions';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { debounce } from '../../utils/PerformanceUtils';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';

const { Content } = Layout;

const style = require('./NotificationsIndexPage.less');

const NOTIFICATIONS_OVERVIEW_TARGET_COLLECTION = 'notificationsIndex';

function NotificationsIndexPage(props) {
	const {
		userNotifications,
		userType,
		showFilters = true,
		showUnreadFilter = true,
		emptyState,
		preAppliedFilters = {},
		typeFilters = [],
		targetCollectionName,
		onNotificationClick,
		extraFilters,
		markAllReadForBuyer,
		markAllReadForSupplier,
	} = props;

	const [isNoTypeFilter, setIsNoTypeFilter] = React.useState(false);
	const [markinAllReadLoading, setMarkinAllReadLoading] = React.useState(false);

	const debouncedUpdateUserNotificationFilters = React.useCallback(
		debounce((filters, targetCollection) => {
			props.updateUserNotificationFilters(filters, targetCollection);
		}, GLOBAL_DEBOUNCE_TIME),
		[]
	);

	const targetCollection = targetCollectionName || NOTIFICATIONS_OVERVIEW_TARGET_COLLECTION;
	const activeFilters = nullSafeGetOrElse(`${targetCollection}.filters`, userNotifications, {});

	const prepareIntialFilters = () => {
		return typeFilters
			.map((filter) => {
				if (filter.children && filter.children.length > 0) {
					return filter.children.map((child) => child.key);
				}
				return filter.key;
			})
			.flat();
	};
	const handleMarkAllRead = () => {
		const unreadNotifications = getRecordsForTargetCollection(userNotifications, targetCollection);
		const markAllRead =
			userType === ROLE_TYPES.BUYER ? markAllReadForBuyer : markAllReadForSupplier;
		if (unreadNotifications && unreadNotifications.length > 0) {
			const latestUnreadNotification = unreadNotifications[0];
			setMarkinAllReadLoading(true);
			markAllRead({
				id: latestUnreadNotification.id,
				...activeFilters,
				hasSeen: undefined,
			}).finally(() => {
				setMarkinAllReadLoading(false);
			});
		}
	};

	const [selectedFilters, setSelectedFilters] = React.useState(prepareIntialFilters());

	const notificationsFetching = userNotifications.fetching;

	const formatNotifications = (notification) => ({
		...notification,
		title:
			userType === ROLE_TYPES.SUPPLIER
				? nullSafeGet(`supplierPushNotification.webAppMessage`, notification)
				: nullSafeGet(`buyerPushNotification.webAppMessage`, notification),
		datetime: notification.createdAt,
		contact: notification.userContact,
	});

	return (
		<Content
			className="buyerNotificationsIndexPage"
			style={{ padding: '0 0.5em' }}
			key={targetCollectionName}
		>
			<Row style={{ margin: '0.5em -8px' }}>
				<Col span={24}>
					<div className="mx-2 mt-4 mb-4  flex flex-row flex-wrap  items-center justify-between">
						<div className=" flex basis-1/2 gap-x-4 gap-y-2 ">
							{showUnreadFilter && (
								<Radio.Group
									onChange={(e) => {
										debouncedUpdateUserNotificationFilters(
											{ hasSeen: e.target.value },
											targetCollection
										);
									}}
									value={activeFilters.hasSeen}
								>
									{[
										{ label: 'All', value: undefined },
										{ label: 'Unread', value: false },
									].map((item, idx) => (
										<Radio.Button
											style={{ flexWrap: 'nowrap', height: 40, lineHeight: '40px' }}
											value={item.value}
											key={item.label}
										>
											{item.label}
										</Radio.Button>
									))}
								</Radio.Group>
							)}
							{(showFilters && [
								<Radio.Group
									onChange={(e) => {
										debouncedUpdateUserNotificationFilters(
											{ isEmergency: e.target.value },
											targetCollection
										);
									}}
									value={activeFilters.isEmergency}
								>
									{[
										{ label: 'All', value: undefined },
										{ label: 'Emergency ', value: true },
									].map((item, idx) => (
										<Radio.Button
											style={{ flexWrap: 'nowrap', height: 40, lineHeight: '40px' }}
											value={item.value}
											key={item.label}
										>
											{item.label}
										</Radio.Button>
									))}
								</Radio.Group>,
							]) ||
								null}

							{extraFilters &&
								extraFilters({
									updateFilters: debouncedUpdateUserNotificationFilters,
									targetCollection,
									activeFilters,
								})}
						</div>
						{(typeFilters.length && (
							<div className="flex basis-1/2 flex-row flex-wrap justify-end gap-x-4 gap-y-2">
								{typeFilters.map((filter) => (
									<Checkbox
										onChange={(e) => {
											let updatedFilters = [];
											if (e.target.checked) {
												if (filter.children && filter.children.length > 0) {
													updatedFilters = [
														...selectedFilters,
														...filter.children.map((child) => child.key),
													];
												} else {
													updatedFilters = [...selectedFilters, filter.key];
												}
											} else {
												if (filter.children && filter.children.length > 0) {
													updatedFilters = selectedFilters.filter(
														(selectedFilter) =>
															!filter.children.map((child) => child.key).includes(selectedFilter)
													);
												} else {
													updatedFilters = selectedFilters.filter(
														(selectedFilter) => selectedFilter !== filter.key
													);
												}
											}
											if (updatedFilters.length === 0) {
												setIsNoTypeFilter(true);
											} else {
												setIsNoTypeFilter(false);
											}
											setSelectedFilters(updatedFilters);
											debouncedUpdateUserNotificationFilters(
												{ notificationTypes: updatedFilters },
												targetCollection
											);
										}}
										checked={(
											(filter.children &&
												filter.children.length &&
												filter.children.map((child) => child.key)) || [filter.key] ||
											[]
										).every((val) => selectedFilters.includes(val))}
										key={filter.key}
									>
										{filter.label}
									</Checkbox>
								))}
							</div>
						)) ||
							null}
						<div className="mx-2 flex grow  flex-row flex-wrap items-end justify-end">
							{showUnreadFilter && (
								<Button onClick={handleMarkAllRead} type="ghost">
									Mark all read
								</Button>
							)}
						</div>
					</div>
				</Col>
				<Col span={24}>
					{(markinAllReadLoading && <PageLoadingPlaceholder />) || (
						<Card>
							{(isNoTypeFilter && (
								<EmptyState
									graphic={
										<img
											style={{ marginBottom: 8 }}
											src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
											alt="404"
										/>
									}
									headline={'Please select a notification type to view notifications'}
									body={null}
								/>
							)) || (
								<PaginatedReduxTable
									loading={notificationsFetching}
									targetCollectionName={targetCollection}
									showHeader={false}
									columns={[
										{
											title: '',
											dataIndex: 'title',
											key: 'title',
											render: (title, notification) => (
												<UserNotificationRowDisplay
													showTemplate
													notification={formatNotifications(notification)}
													userType={props.userType}
													onUpdate={onNotificationClick}
												/>
											),
										},
									]}
									collectionName={'user_notifications'}
									fetchData={
										props.userType === ROLE_TYPES.SUPPLIER
											? userNotificationsRestCrudThunksForSupplier.read
											: userNotificationsRestCrudThunksForBuyer.read
									}
									emptyState={
										emptyState ? (
											emptyState
										) : (
											<EmptyState
												graphic={
													<img
														style={{ marginBottom: 8 }}
														src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
														alt="404"
													/>
												}
												headline={'No Notifications Found'}
												body={null}
											/>
										)
									}
									initialFilters={{
										...preAppliedFilters,
										notificationTypes:
											(selectedFilters && selectedFilters.length > 0 && selectedFilters) ||
											undefined,
									}}
								/>
							)}
						</Card>
					)}
				</Col>
			</Row>
		</Content>
	);
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	userNotifications: state.user_notifications,
	userType: state.session.userType,
});

const mapDispatchToProps = (dispatch) => ({
	updateUserNotificationFilters: (filters, targetCollection) =>
		dispatch(USER_NOTIFICATIONS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	markAllReadForBuyer: (entity) => dispatch(markAllUserNotificationsReadForBuyer(entity)),
	markAllReadForSupplier: (entity) => dispatch(markAllUserNotificationsReadForSupplier(entity)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(NotificationsIndexPage)
);

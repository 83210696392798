import { EditOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Modal, Row, Select, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getObjectValues, nullSafeGet } from '../../utils/DataAccessUtils';
import { vendorsRestCrudThunksForSupplier } from '../../thunks/vendors_thunks';
import { capitalize } from '../../utils/DataFormatterUtils';

const FORM_NAME = 'update-vendor-currency-form';

const ChangeVendorCurrencyComponent: FC<any> = ({
	vendorId,
	fetchVendor,
	updateVendor,
	fetchingVendor,
	updatingVendor,
	vendor,
	onSuccess,
	currencies,
}): React.ReactElement => {
	const [form] = useForm();

	const [currencyPopupVisible, setCurrencyPopupVisible] = useState(false);

	useEffect(() => fetchVendor(vendorId), [fetchVendor, vendorId]);

	const onChangeCurrency = useCallback(() => setCurrencyPopupVisible(true), []);
	const onCancel = useCallback(() => setCurrencyPopupVisible(false), []);

	const currencyRecordsList = getObjectValues(currencies.records);
	const currencyRecordsMap = currencies.records;

	const onSubmit = useCallback(
		(values) => {
			updateVendor({
				...vendor,
				...values,
			})
				.then((updatedVendor) => {
					onSuccess && onSuccess(updatedVendor);
				})
				.finally(() => onCancel());
		},
		[onCancel, onSuccess, updateVendor, vendor]
	);

	return (
		<>
			<Button
				type="link"
				icon={<EditOutlined translate="" />}
				size="small"
				title="Change Currency"
				onClick={onChangeCurrency}
			>
				Change Currency
			</Button>
			{currencyPopupVisible ? (
				<Modal
					visible={true}
					width={600}
					title={'Update Vendor Currency'}
					closable
					onCancel={onCancel}
					forceRender
					footer={[
						<Button onClick={onCancel} size="large">
							Cancel
						</Button>,
						<Button
							type="primary"
							size="large"
							style={{ marginLeft: '16px' }}
							key="submit"
							htmlType="submit"
							form={FORM_NAME}
							loading={updatingVendor}
						>
							Update Currency
						</Button>,
					]}
				>
					{' '}
					{fetchingVendor ? (
						<Spin />
					) : (
						<Form
							form={form}
							id={FORM_NAME}
							layout="vertical"
							requiredMark={false}
							initialValues={{ currencyId: nullSafeGet('currencyId', vendor) }}
							onFinish={onSubmit}
						>
							<Row className={'mb-4'}>
								<Alert
									showIcon
									type={'warning'}
									message={`You are updating the currency associated with the vendor ${nullSafeGet(
										'name',
										vendor
									)}`}
								/>
							</Row>
							<Form.Item
								name="currencyId"
								label="Currency"
								rules={[
									{
										required: true,
										message: `Please select a currency`,
									},
								]}
							>
								<Select
									style={{ width: 320 }}
									optionFilterProp="children"
									filterOption={(input, option) =>
										currencyRecordsMap[option.props.value].displayNamePlural
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}
									placeholder="Currency"
								>
									{currencyRecordsList.map((currency) => (
										<Select.Option key={currency.id} value={currency.id}>
											{capitalize(currency.displayNamePlural)} ({currency.abbreviation})
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Form>
					)}
				</Modal>
			) : null}
		</>
	);
};

const mapStateToProps = (state) => ({
	vendor: state.vendors.detail,
	fetchingVendor: state.vendors.fetching,
	updatingVendor: state.vendors.updating,
	currencies: state.currencies,
});

const mapDispatchToProps = (dispatch) => ({
	fetchVendor: (id) => dispatch(vendorsRestCrudThunksForSupplier.readOne(id)),
	updateVendor: (entity) => dispatch(vendorsRestCrudThunksForSupplier.update(entity)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(ChangeVendorCurrencyComponent)
);

import React, { FC, useCallback, useMemo, useState } from 'react';
import { Card, Button, Tooltip, message, Popconfirm } from 'antd';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import AddOrEditAreaModal from './AddOrEditAreaModal';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import {
	areasRestCrudThunksForBuyer,
	areasRestCrudThunksForSupplier,
} from '../../thunks/areas_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { connect } from 'react-redux';

const LOCATION_ASSOCIATED_AREAS_TARGET_COLLECTION = 'locationAssociatedAreas';

const AreasDisplay: FC<any> = ({ location, deleteArea, userType }): React.ReactElement => {
	const [deletingArea, setDeletingArea] = useState(null);

	const locationId = useMemo(() => nullSafeGet('id', location), [location]);

	const [areaModalVisible, setAreaModalVisible] = useState(false);
	const [currentEditingArea, setCurrentEditingArea] = useState(null);

	const _showAreaModal = useCallback(() => setAreaModalVisible(true), []);
	const _hideAreaModal = useCallback(() => {
		setCurrentEditingArea(null);
		setAreaModalVisible(false);
	}, []);

	const onAreaEdit = useCallback(
		(area) => () => {
			setCurrentEditingArea(area);
			_showAreaModal();
		},
		[_showAreaModal]
	);

	const onDeleteArea = useCallback(
		(area) => () => {
			setDeletingArea(area.id);
			deleteArea(area)
				.then(() => message.success(`Area deleted successfully!`))
				.catch((err) => message.error(err))
				.finally(() => setDeletingArea(null));
		},
		[deleteArea]
	);

	const isDeletingRecord = useCallback(
		(record) => deletingArea === nullSafeGet('id', record),
		[deletingArea]
	);

	const areaColumns = useMemo(
		() => [
			{
				title: 'Name',
				dataIndex: 'name',
			},
			{
				title: '',
				dataIndex: 'id',
				render: (_, record) => (
					<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_AREAS}>
						<div className={`${isDeletingRecord(record) ? '' : 'show-on-tr-hover'}`}>
							<Tooltip mouseEnterDelay={0.25} trigger="hover" title="Edit">
								<Button onClick={onAreaEdit(record)} size="small" type="link">
									<i className="icons8-font icons8-edit"></i>
								</Button>
							</Tooltip>
							<Popconfirm
								title="Are you sure you want to delete this area?"
								onConfirm={onDeleteArea(record)}
								okText="Delete"
								cancelText="Cancel"
							>
								<Button size="small" type="link" loading={isDeletingRecord(record)}>
									<i className="icons8-font icons8-trash-can"></i>
								</Button>
							</Popconfirm>
						</div>
					</AccessPermissionChecker>
				),
			},
		],
		[isDeletingRecord, onAreaEdit, onDeleteArea]
	);

	return (
		<Card
			title="Areas"
			extra={
				<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_AREAS}>
					<Button type="primary" onClick={_showAreaModal}>
						Add Area
					</Button>
				</AccessPermissionChecker>
			}
		>
			{areaModalVisible ? (
				<AddOrEditAreaModal
					area={currentEditingArea}
					location={location}
					onCancel={_hideAreaModal}
					targetCollectionName={LOCATION_ASSOCIATED_AREAS_TARGET_COLLECTION}
				/>
			) : null}
			<PaginatedReduxTable
				tableLayoutFixed={true}
				showHeader={false}
				emptyState={null}
				collectionName="areas"
				targetCollectionName={LOCATION_ASSOCIATED_AREAS_TARGET_COLLECTION}
				columns={areaColumns}
				keyAccessor={(el) => el.id}
				initialFilters={{ locationId }}
				initialPagination={{
					current: 1,
					pageSize: 3,
					pageSizeOptions: ['3', '5', '10', '25', '50'],
				}}
				fetchData={
					userType === ROLE_TYPES.SUPPLIER
						? areasRestCrudThunksForSupplier.read
						: areasRestCrudThunksForBuyer.read
				}
			/>
		</Card>
	);
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	deleteArea: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? areasRestCrudThunksForSupplier.delete(entity, 'id', null, true)
				: areasRestCrudThunksForBuyer.delete(entity, 'id', null, true)
		),
});

const ComponentWithoutUserType = connect(null, mapDispatchToProps)(AreasDisplay);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import { crudActionCreatorsGenerator } from './crud_action_creators_generator';
import { crudActionConstantsGenerator } from './crud_action_constants_generator';

export const PERFORMANCE_MILESTONE_PAYMENTS_CRUD_ACTION_CONSTANTS = crudActionConstantsGenerator(
	'PERFORMANCE_MILESTONE_PAYMENTS'
);
export const PERFORMANCE_MILESTONE_PAYMENTS_CRUD_ACTION_CREATORS = crudActionCreatorsGenerator(
	PERFORMANCE_MILESTONE_PAYMENTS_CRUD_ACTION_CONSTANTS
);

export const SCHEDULE_MILESTONE_PAYMENTS_CRUD_ACTION_CONSTANTS = crudActionConstantsGenerator(
	'SCHEDULE_MILESTONE_PAYMENTS'
);
export const SCHEDULE_MILESTONE_PAYMENTS_CRUD_ACTION_CREATORS = crudActionCreatorsGenerator(
	SCHEDULE_MILESTONE_PAYMENTS_CRUD_ACTION_CONSTANTS
);

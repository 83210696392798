import * as React from 'react';

import { UserOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Input, Button, Alert } from 'antd';
import { connect } from 'react-redux';
import { requestPasswordReset } from '../../thunks/session_thunks';
import Result from 'ant-design-pro/lib/Result';

import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter, Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import BackButton from '../back_button/BackButton';
import { validateDomain } from '../../utils/AuthUtils';
import { SSODomainForBuyer } from '../../thunks/domain_thunks';

const style = require('./BuyerForgotPasswordForm.less');
const FormItem = Form.Item;

interface ForgotPasswordProps {
	isAuthenticated: boolean;
	requestPasswordReset: any;
	loading: boolean;
	username: string;
}

interface ForgotPasswordFormProps extends FormComponentProps {
	isAuthenticated: boolean;
	requestPasswordReset: any;
	getAndValidateDomain: any;
	loading: boolean;
	username: string;
}

class BuyerForgotPasswordForm extends React.Component<ForgotPasswordFormProps, any> {
	state = {
		resetRequested: false,
		recipientEmailAddress: '',
		shouldLoginViaSSO: false,
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { requestPasswordReset, form, getAndValidateDomain } = this.props;
		form.validateFields((err, values) => {
			getAndValidateDomain(values.username.trim())
				.then(() => {
					if (!err) {
						requestPasswordReset(values.username).then(() => {
							this.setState({
								resetRequested: true,
								recipientEmailAddress: values.username,
							});
						});
					}
				})
				.catch((err) => {
					this.setState({ shouldLoginViaSSO: err });
				});
		});
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { isAuthenticated, loading } = this.props;
		const { resetRequested, recipientEmailAddress, shouldLoginViaSSO } = this.state;
		return isAuthenticated ? (
			<Redirect
				to={{
					pathname: '/workOrders',
					state: { from: '/forgotPassword' },
				}}
			/>
		) : resetRequested ? (
			<Result
				type="success"
				title={<div>Password reset email sent!</div>}
				description={
					<div>
						<p className="signupForm__successExplanation">
							We've sent a password reset link to{' '}
							<span style={{ fontWeight: 'bold' }}>{recipientEmailAddress}</span>.
						</p>
						<p className="signupForm__successExplanation">
							Check your email, follow the instructions to reset your password and you'll be back
							into your account in no time.
						</p>
					</div>
				}
				actions={
					<div style={{ marginBottom: 24 }}>
						<Link to="/buyer/login">
							<Button size="large" type="primary">
								Back to log in
							</Button>
						</Link>
					</div>
				}
			/>
		) : (
			<Form onSubmit={this.handleSubmit} className="forgotPasswordForm">
				{shouldLoginViaSSO ? (
					<FormItem key={'sso'}>
						<Alert
							action={
								<Link to="/buyer/login">
									<Button size="small" type="ghost">
										Go back
									</Button>
								</Link>
							}
							message={shouldLoginViaSSO}
							type="warning"
						/>
					</FormItem>
				) : null}
				<FormItem>
					{getFieldDecorator('username', {
						rules: [{ required: true, message: 'Please input your email!' }],
					})(
						<Input
							size="large"
							prefix={<UserOutlined translate="" style={{ fontSize: 13 }} />}
							placeholder="Email"
						/>
					)}
				</FormItem>
				<FormItem>
					<Button type="primary" htmlType="submit" size="large" loading={loading}>
						Get reset link
					</Button>
					<span style={{ marginLeft: 8 }}>
						<BackButton size="large" buttonText="Go back" />
					</span>
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.session.isAuthenticated,
	loading: state.session.loading,
});

const mapDispatchToProps = (dispatch) => ({
	requestPasswordReset: (username) => dispatch(requestPasswordReset('buyer')(username)),
	getAndValidateDomain: (username) => dispatch(SSODomainForBuyer(username)),
});

export default withRouter<any, any>(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<ForgotPasswordFormProps>()(BuyerForgotPasswordForm))
);

import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Alert, Button, Select, DatePicker } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import BackButton from '../back_button/BackButton';
import { assetsRestCrudThunksForBuyer } from '../../thunks/assets_thunks';
import { warrantiesRestCrudThunksForBuyer } from '../../thunks/warranties_thunks';

const dateFormat = 'MMMM DD, YYYY';

interface WarrantyFormProps extends FormComponentProps {
	creating: boolean;
	updating: boolean;
	create: any;
	update: any;
	updatingAsset: boolean;
	updateAsset: any;
	fetchAsset: any;
	assetsFetching: any;
	asset: any;
	formData: any;
	history: any;
	match: any;
	redirectForwardUrl?: string;
	onSuccess?: any;
	createErrors: any[];
	updateErrors: any[];
	currentUser: any;
}

class WarrantyForm extends React.Component<WarrantyFormProps, any> {
	componentDidMount() {
		const { fetchAsset, match } = this.props;
		const assetId = match.params.id;
		fetchAsset(assetId);
	}

	handleSubmit = (e, submitAction) => {
		const { form, asset, updateAsset, history, onSuccess, redirectForwardUrl } = this.props;
		e.preventDefault();
		form.validateFields((err, values) => {
			if (!err) {
				//     issueDate: Option[DateTime],
				//     expirationDate: Option[DateTime],
				//     NTEAmount: Option[BigDecimal],
				//     priority: Option[Int],
				//     category: Option[String],
				//     provider: Option[Provider],
				//     expiration: Option[Expiration],
				//     buyerCompanyId: Int,
				let warranty = {
					id: values.id,
					issueDate: values.issueDate,
					provider: {
						name: values.providerName,
						value: values.providerName,
						providerType: values.providerType,
					},
					expirationDate: values.expirationDate,
					buyerCompanyId: values.buyerCompanyId,
				};

				submitAction(warranty).then((record) => {
					const newAsset = {
						...asset,
						warrantyId: record.id,
					};
					updateAsset(newAsset).then(() => {
						if (redirectForwardUrl) {
							history.push(redirectForwardUrl);
						}
						if (onSuccess) {
							onSuccess(newAsset);
						}
					});
				});
			}
		});
	};

	render() {
		const {
			updating,
			creating,
			updatingAsset,
			form,
			update,
			create,
			createErrors,
			match,
			formData,
			updateErrors,
			currentUser,
		} = this.props;
		const { getFieldDecorator } = form;
		const isUpdate = formData && formData.id !== undefined;
		const submitAction = isUpdate ? update : create;
		const submitText = isUpdate ? 'Update warranty' : 'Create warranty';
		const isLoading = updating || creating || updatingAsset;

		const assetId = parseInt(match.params.id, 10);

		getFieldDecorator('id', { initialValue: formData.id });
		getFieldDecorator('buyerCompanyId', {
			initialValue: formData.buyerCompanyId || nullSafeGet('facility.buyerCompanyId', currentUser),
		});
		getFieldDecorator('assetId', { initialValue: formData.assetId || assetId });

		return (
			<Form
				layout="vertical"
				onSubmit={(e) => this.handleSubmit(e, submitAction)}
				className="supplierForm"
			>
				{createErrors.length > 0 ? (
					<Form.Item>
						<Alert message={createErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				{updateErrors.length > 0 ? (
					<Form.Item>
						<Alert message={updateErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				<Form.Item label="Start date">
					{getFieldDecorator('issueDate', {
						initialValue: formData.issueDate,
					})(<DatePicker format={dateFormat} />)}
				</Form.Item>
				<Form.Item label="End date">
					{getFieldDecorator('expirationDate', {
						initialValue: formData.expirationDate,
					})(<DatePicker format={dateFormat} />)}
				</Form.Item>
				<Form.Item label="Warranty issued by">
					{getFieldDecorator('providerName', {
						initialValue: nullSafeGet('provider.name', formData),
					})(<Input style={{ maxWidth: 320 }} />)}
				</Form.Item>
				<Form.Item label="Warranty issuer type">
					{getFieldDecorator('providerType', {
						initialValue: nullSafeGet('provider.providerType', formData),
					})(
						<Select
							style={{ width: 240 }}
							allowClear={true}
							placeholder="Select warranty issuer type"
						>
							<Select.Option key="manufacturer" value="manufacturer">
								Original Equipment Manufacturer
							</Select.Option>
							<Select.Option key="distributor" value="distributor">
								Distributor
							</Select.Option>
							<Select.Option key="serviceProvider" value="serviceProvider">
								Service Provider
							</Select.Option>
						</Select>
					)}
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						loading={isLoading}
						className="contactForm__button"
					>
						{submitText}
					</Button>
					<span style={{ marginLeft: '16px' }}>
						<BackButton buttonText="Cancel" />
					</span>
				</Form.Item>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	match: ownProps.match,
	formData: ownProps.formData,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,
	assetsFetching: state.assets.fetching,
	asset: state.assets.detail,
	createErrors: state.warranties.createErrors,
	updateErrors: state.warranties.updateErrors,
	creating: state.warranties.creating,
	updating: state.warranties.updating,
	updatingAsset: state.assets.updating,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	update: (entity) => dispatch(warrantiesRestCrudThunksForBuyer.update(entity)),
	create: (entity) => dispatch(warrantiesRestCrudThunksForBuyer.create(entity)),
	updateAsset: (entity) => dispatch(assetsRestCrudThunksForBuyer.update(entity)),
	fetchAsset: (id) => dispatch(assetsRestCrudThunksForBuyer.readOne(id)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Form.create<WarrantyFormProps>()(WarrantyForm))
);

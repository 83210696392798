import * as React from 'react';
import { Spin, TreeSelect } from 'antd';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { ReactNode } from 'react';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { isEqual } from 'lodash';

const find = (array, key) => {
	var object;

	array.some(function f(a) {
		if (a.value === key) {
			object = a;
			return true;
		}
		if (Array.isArray(a.children) && a.children.length) {
			return a.children.some(f);
		}
	});
	return object;
};

const isValidValue = (treeData, val) => {
	const record = find(treeData, val);
	return record && nullSafeGetOrElse('children', record, []).length === 0;
};

const getCleanedValues = (treeData = [], value, mode) => {
	if (mode === 'multiple') {
		const allValues = value || [];
		return allValues.filter((_) => isValidValue(treeData, _));
	} else if (value) {
		return isValidValue(treeData, value) ? value : undefined;
	}
};

interface ProblemTypeTreeSelectProps {
	stateSlice: any;
	targetCollectionName: string;
	placeholder?: string;
	disabledPlaceholder?: string;
	valueAccessor?: any;
	additionalFilters?: any;
	initialPagination?: any;
	allowClear?: boolean;
	size?: 'large' | 'small';
	style?: any;
	mode?: 'default' | 'multiple' | 'tags' | 'combobox';
	value?: any;
	onChange?: any;
	loading?: boolean;
	disabled?: boolean;
	emptyWhenDisabled?: boolean;
	sortBy?: any;

	fetchMultiple?: { (ids: string[] | number[], targetCollectionName?: string): void };

	renderRecord?: { (data: any): ReactNode };

	fetchData(
		searchText: string,
		targetCollectionName: string,
		pagination?: any,
		sorting?: any,
		filters?: any,
		addToTargetCollection?: boolean
	): Promise<any[]>;
}

const defaultPagination = { current: 1, pageSize: 50 };
const { SHOW_PARENT } = TreeSelect;
const arrDataToTreeData = (arrData, mode) => {
	let mapData = {};
	let childIds = [];
	const transformRecord = (el) => ({
		value: el.id,
		key: el.id,
		title: el.name,
		hierarchyName: el.hierarchyName,
		selectable:
			mode === 'multiple' || (el.id && !(Array.isArray(el.children) && el.children.length)),
		children:
			Array.isArray(el.children) && el.children.length > 0
				? arrDataToTreeData(el.children, mode)
				: [],
	});
	const recs = arrData.map((el) => transformRecord(el));
	return recs.filter((rec) => {
		return !childIds.includes(rec.value);
	});
};

export default class ProblemTypeTreeSelect extends React.Component<
	ProblemTypeTreeSelectProps,
	any
> {
	constructor(props) {
		super(props);
		const value = props.value;
		this.state = {
			value: value,
			treeData: [],
		};
	}

	componentWillReceiveProps(nextProps) {
		// Should be a controlled component.
		const { mode } = this.props;
		if ('value' in nextProps) {
			const value =
				nullSafeGetOrElse('state.treeData', this, []).length > 0
					? getCleanedValues(this.state.treeData, nextProps.value, mode)
					: nextProps.value;
			this.setState({ value });
		}
		if (!isEqual(nextProps.additionalFilters, this.props.additionalFilters)) {
			this.props
				.fetchData(
					this.state.searchText && this.state.searchText.trim(),
					nextProps.targetCollectionName,
					this.props.initialPagination || defaultPagination,
					nextProps.sortBy,
					nextProps.additionalFilters,
					false
				)
				.then((arrData) => {
					const treeData = arrDataToTreeData(arrData, nextProps.mode);
					this.setState({ treeData });
				});
		}

		return null;
	}

	componentDidMount() {
		const { value } = this.state;
		const { targetCollectionName, mode } = this.props;
		this.props
			.fetchData(
				'',
				targetCollectionName,
				this.props.initialPagination || defaultPagination,
				this.props.sortBy,
				this.props.additionalFilters,
				false
			)
			.then((arrData) => {
				const treeData = arrDataToTreeData(arrData, this.props.mode);
				const cleanedValues = getCleanedValues(treeData, value, mode);
				this.setState({ treeData, value: cleanedValues });
			});
	}

	handleValueChange = (values) => {
		const { mode } = this.props;
		const valueSet = new Set();

		function addAllChildren(array) {
			array.map(function f(a) {
				if (Array.isArray(a.children) && a.children.length) {
					a.children.map(f);
				} else {
					valueSet.add(a.value);
				}
			});
		}

		if (mode === 'multiple') {
			values.map((key) => {
				const entity = find(this.state.treeData, key);
				if (entity) {
					if (entity.children && entity.children.length) {
						addAllChildren(entity.children);
					} else {
						valueSet.add(entity.value);
					}
				}
			});
		}
		const updatedValue = mode === 'multiple' ? Array.from(valueSet) : values;
		if (!('value' in this.props)) {
			this.setState({ value: updatedValue });
		}
		this.triggerChange(updatedValue);
	};

	triggerChange = (changedValue) => {
		// Should provide an event to pass value to Form.
		const onChange = this.props.onChange;
		if (onChange) {
			onChange(changedValue);
		}
	};

	render() {
		const { value } = this.state;
		const {
			disabled,
			allowClear = false,
			loading,
			emptyWhenDisabled,
			valueAccessor = (el) => el.id,
			stateSlice,
			targetCollectionName,
			mode,
			placeholder,
			disabledPlaceholder,
			size,
			style,
		} = this.props;
		const selectedVals = Array.isArray(value) ? value : [value];
		const selectedRecords = selectedVals
			.map((v) => stateSlice.records[v])
			.filter((r) => r && valueAccessor(r));
		const dropdownRecords = getRecordsForTargetCollection(stateSlice, targetCollectionName);
		const visibleRecords = selectedRecords.concat(
			dropdownRecords.filter((r) => !selectedVals.some((val) => valueAccessor(r) == val))
		);
		const fetching = stateSlice.fetching;
		const noVisibleRecordsWithoutSearch =
			nullSafeGetOrElse('searchText.length', this.state, 0) === 0 && visibleRecords.length === 0;

		return (
			<TreeSelect
				multiple={mode === 'multiple'}
				treeCheckable={mode === 'multiple'}
				notFoundContent={fetching ? <Spin size="small" /> : null}
				placeholder={disabled || noVisibleRecordsWithoutSearch ? disabledPlaceholder : placeholder}
				loading={loading}
				showSearch={true}
				dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
				treeNodeFilterProp="title"
				onChange={this.handleValueChange}
				treeData={disabled && emptyWhenDisabled ? [] : this.state.treeData}
				size={size}
				style={style}
				allowClear={allowClear}
				value={disabled && emptyWhenDisabled ? [] : value}
				disabled={disabled || noVisibleRecordsWithoutSearch}
				treeNodeLabelProp="hierarchyName"
				showCheckedStrategy={'SHOW_PARENT'}
				treeDefaultExpandedKeys={[`${value}`]}
			/>
		);
	}
}

import { Button, Form, Input, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { FC, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { depreciationClassesRestCrudThunksForSupplier } from '../../thunks/depreciation_class_thunks';
import { DEPRECIATION_METHODS } from '../../utils/DataConstants';
import DepreciationClassCommonFormItems from './DepreciationClassCommonFormItems';

const FORM_NAME = 'depreciation-class-form';

const DepreciationClassModal: FC<any> = ({
	depreciationClass,
	onCancel,
	saving,
	update,
	create,
	onSuccess,
}): React.ReactElement => {
	const [form] = useForm();

	const isUpdate = useMemo(() => !!depreciationClass, [depreciationClass]);

	const initialValues = useMemo(
		() => ({
			name: nullSafeGet('name', depreciationClass),
			depreciationMethod: nullSafeGet('depreciationMethod', depreciationClass),
			depreciationRate: parseFloat(nullSafeGetOrElse('depreciationRate', depreciationClass, 0)),
			depreciationPeriod: 'monthly',
			usefulLifeInMonths: nullSafeGet('usefulLifeInMonths', depreciationClass),
			salvageValue: parseFloat(nullSafeGetOrElse('salvageValue', depreciationClass, 0)),
			isVariable: !!nullSafeGet('isVariable', depreciationClass),
			variableType: 'locations_end_of_lease',
		}),
		[depreciationClass]
	);

	const getValueForKey = useCallback((key, values, method, type = 'string') => {
		if (values.depreciationMethod === method) {
			switch (type) {
				case 'int':
					return { [key]: parseInt(values[key]) };
				case 'bool':
					return { [key]: values[key] };
				default:
					return { [key]: `${values[key]}` };
			}
		} else {
			return {};
		}
	}, []);

	const getEntityFromValues = useCallback(
		(values) => ({
			name: values.name,
			depreciationMethod: values.depreciationMethod,
			isVariable: !!values.isVariable,
			...getValueForKey('depreciationRate', values, DEPRECIATION_METHODS.DECLINING),
			...getValueForKey('depreciationPeriod', values, DEPRECIATION_METHODS.DECLINING),
			...getValueForKey('depreciationPeriod', values, DEPRECIATION_METHODS.STRAIGHT_LINE),
			...getValueForKey('usefulLifeInMonths', values, DEPRECIATION_METHODS.STRAIGHT_LINE, 'int'),
			...getValueForKey('variableType', values, DEPRECIATION_METHODS.STRAIGHT_LINE),
			...getValueForKey('salvageValue', values, DEPRECIATION_METHODS.STRAIGHT_LINE),
		}),
		[getValueForKey]
	);

	const onSubmit = useCallback(
		(values) => {
			const methodToCall = isUpdate ? update : create;
			methodToCall({
				...(depreciationClass || {}),
				...getEntityFromValues(values),
			}).then(() => onSuccess && onSuccess());
		},
		[create, depreciationClass, getEntityFromValues, isUpdate, onSuccess, update]
	);

	return (
		<Modal
			visible={true}
			width={600}
			title={`${isUpdate ? 'Update' : 'Create'} depreciation class`}
			closable
			onCancel={onCancel}
			forceRender
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button
					type="primary"
					size="large"
					style={{ marginLeft: '16px' }}
					key="submit"
					htmlType="submit"
					form={FORM_NAME}
					loading={saving}
				>
					{isUpdate ? 'Update' : 'Save'}
				</Button>,
			]}
		>
			<Form
				form={form}
				id={FORM_NAME}
				layout="vertical"
				requiredMark={false}
				initialValues={initialValues}
				onFinish={onSubmit}
			>
				<Form.Item
					name="name"
					label="Name"
					rules={[
						{
							required: true,
							message: `Please enter a name`,
						},
					]}
				>
					<Input style={{ width: '100%' }} max={255} />
				</Form.Item>
				<DepreciationClassCommonFormItems form={form} />
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	saving: state.depreciation_classes.creating || state.depreciation_classes.updating,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	update: (entity) => dispatch(depreciationClassesRestCrudThunksForSupplier.update(entity)),
	create: (entity) => dispatch(depreciationClassesRestCrudThunksForSupplier.create(entity)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(DepreciationClassModal)
);

import * as React from 'react';

const style = require('./FAQRowDisplay.less');

export const FAQRowDisplay: React.FC<any> = ({ faq, ...props }) => (
	<div className="faqRowDisplay" {...props}>
		<div className="faqRowDisplay__question">
			<span style={{ marginRight: 16, fontFamily: 'emptorserif' }}>Q</span>
			{faq.question}
		</div>
		<div className="faqRowDisplay__answer">
			<span style={{ marginRight: 16, fontFamily: 'emptorserif' }}>A</span>
			{faq.answer}
		</div>
	</div>
);

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Layout, Row, Col, Card, Button, Table, message, Drawer, Popover } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import {
	getCurrency,
	getCurrencyIdIFSamePRLineItemCurrency,
	getEntityById,
	getPurchaseRequestId,
	nullSafeGet,
	nullSafeGetOrElse,
	renderCurrencyByCurrencyId,
	roundOffToDecimal,
	stopDefaultEvents,
} from '../../utils/DataAccessUtils';
import {
	purchaseRequestsRestCrudThunksForSupplier,
	purchaseRequestStatusChangeForSupplier,
} from '../../thunks/purchase_requests_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { ContactRowDisplay } from '../contact_row_display/ContactRowDisplay';
import { AddressDisplay } from '../address_display/AddressDisplay';
import { HorizontalKeyValueDisplay } from '../horizontal_key_value_display/HorizontalKeyValueDisplay';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import HyperLink, { ENTITY_TYPE } from '../common/HyperLink';
import PartLineItemRowDisplay from '../parts_line_item_row_display/PartLineItemRowDisplay';
import EquipmentLineItemRowDisplay from '../equipment_line_item_row_display/EquipmentLineItemRowDisplay';
import {
	EDITABLE_PR_STATUSES,
	PURCHASE_REQUEST_STATUSES,
} from '../../constants/PurchaseRequestStatuses';
import PRWarrantyAssetDetail from './PRWarrantyAssetDetail';
import PurchaseRequestStatusActions from './PurchaseRequestStatusActions';
import { CONFIG_NAMES } from '../../utils/AuthUtils';
import { isSupplierInventoryConfigEnabled } from '../common/SupplierInventoryConfigPermissionChecker';
import { EditOutlined } from '@ant-design/icons';
import PartForm from '../part_form/PartForm';
import EquipmentForm from '../equipment_form/EquipmentForm';
import DateTimeHover from '../date_time_component/DateTime';
import ReplacePartLineItemModal from '../purchase_order_form/ReplacePartLineItemModal';
import ReplaceEquipmentLineItemModal from '../purchase_order_form/ReplaceEquipmentLineItemModal';

const { Content } = Layout;

require('./PurchaseRequestsDetailDetailsPage.less');
const DEFAULT_BACK_LINK_TEXT = 'Back to purchase request';

const PurchaseRequestsDetailPage: FC<any> = ({
	getPurchaseRequest,
	match,
	approvePurchaseRequest,
	rejectPurchaseRequest,
	purchaseRequest,
	history,
	purchaseRequestsFetching,
	location,
	currentUser,
	companyConfig,
}) => {
	const [approving, setApproving] = useState(false);
	const [rejecting, setRejecting] = useState(false);
	const [currentlyEditingPart, setCurrentlyEditingPart] = useState(null);
	const [partEditDrawerVisible, setPartEditDrawerVisible] = useState(false);
	const [currentlyEditingEquipment, setCurrentlyEditingEquipment] = useState(null);
	const [equipmentEditDrawerVisible, setEquipmentEditDrawerVisible] = useState(false);
	const [replacingLineItem, setReplacingLineItem] = useState(null);
	const [replacePartModalVisible, setReplacePartModalVisible] = useState(false);
	const [replaceEquipmentModalVisible, setReplaceEquipmentModalVisible] = useState(false);

	const fetchPurchaseRequest = useCallback(
		() => getPurchaseRequest(match.params.id),
		[getPurchaseRequest, match]
	);

	useEffect(() => {
		if (!nullSafeGet('id', purchaseRequest)) {
			fetchPurchaseRequest();
		}
	}, []);

	const shipToLocation = useMemo(() => !!purchaseRequest.locationId, [purchaseRequest.locationId]);

	const canEdit = useMemo(
		() => EDITABLE_PR_STATUSES.includes(purchaseRequest.status),
		[purchaseRequest.status]
	);

	const prCurrencyId = useMemo(
		() => getCurrencyIdIFSamePRLineItemCurrency(purchaseRequest),
		[purchaseRequest]
	);

	const handleApprovePurchaseRequest = useCallback(() => {
		setApproving(true);
		approvePurchaseRequest(purchaseRequest.id)
			.then(() => {
				fetchPurchaseRequest();
				message.success(`Purchase request approved.`);
			})
			.finally(() => setApproving(false));
	}, [approvePurchaseRequest, fetchPurchaseRequest, purchaseRequest.id]);

	const handleRejectPurchaseRequest = useCallback(() => {
		setRejecting(true);
		rejectPurchaseRequest(purchaseRequest.id)
			.then(() => {
				fetchPurchaseRequest();
				message.success(`Purchase request rejected.`);
			})
			.finally(() => setRejecting(false));
	}, [rejectPurchaseRequest, purchaseRequest.id, fetchPurchaseRequest]);

	const purchaseRequestDetails = useMemo(
		() => ({
			'PR #': getPurchaseRequestId(purchaseRequest),
			'Requested by': (
				<ContactRowDisplay
					contact={purchaseRequest.createdByContact}
					userType={ROLE_TYPES.SUPPLIER}
				/>
			),
			...(purchaseRequest.workOrder && {
				'Work Order ID': (
					<HyperLink
						text={`# ${purchaseRequest.workOrder.id}`}
						entityType={ENTITY_TYPE.WORK_ORDER}
						entityId={nullSafeGet('workOrder.id', purchaseRequest)}
						backLinkText={DEFAULT_BACK_LINK_TEXT}
					/>
				),
			}),
			'Ship to': (
				<HyperLink
					text={nullSafeGet(
						`${shipToLocation ? 'location' : 'stockLocation'}.name`,
						purchaseRequest
					)}
					entityType={shipToLocation ? ENTITY_TYPE.LOCATION : ENTITY_TYPE.STOCK_LOCATION}
					entityId={nullSafeGet(
						`${shipToLocation ? 'locationId' : 'stockLocationId'}`,
						purchaseRequest
					)}
					backLinkText={DEFAULT_BACK_LINK_TEXT}
				/>
			),
			'Shipping address': (
				<AddressDisplay
					address={
						nullSafeGet('location.buyerFacility.primaryAddress', purchaseRequest) ||
						nullSafeGet('stockLocation.address', purchaseRequest) ||
						nullSafeGet('stockLocation.location.buyerFacility.primaryAddress', purchaseRequest)
					}
				/>
			),
			...(prCurrencyId && {
				'Total cost': renderCurrencyByCurrencyId(prCurrencyId)(purchaseRequest.totalCost),
			}),
			'Requested at': <DateTimeHover timestamp={nullSafeGet('createdAt', purchaseRequest)} />,
			...(purchaseRequest.warrantyAssetId && {
				'Warranty Asset': (
					<PRWarrantyAssetDetail
						id={purchaseRequest.warrantyAssetId}
						asset={purchaseRequest.warrantyAsset}
						backLinkText={DEFAULT_BACK_LINK_TEXT}
					/>
				),
			}),
			...(!purchaseRequest.warrantyAssetId &&
				nullSafeGet('workOrder.assetId', purchaseRequest) && {
					'WO Asset': (
						<HyperLink
							text={nullSafeGetOrElse(
								'workOrder.asset.name',
								purchaseRequest,
								`# ${nullSafeGet('workOrder.assetId', purchaseRequest)}`
							)}
							entityType={ENTITY_TYPE.ASSET_DETAIL}
							entityId={nullSafeGet('workOrder.assetId', purchaseRequest)}
							backLinkText={DEFAULT_BACK_LINK_TEXT}
						/>
					),
				}),
			...(purchaseRequest.approvedByEmail && {
				'Approved by': (
					<ContactRowDisplay
						contact={purchaseRequest.approvedByContact}
						userType={ROLE_TYPES.SUPPLIER}
					/>
				),
			}),
		}),
		[purchaseRequest, shipToLocation, prCurrencyId]
	);

	const onEditPRLineItem = useCallback(
		(e) => {
			stopDefaultEvents(e);
			history.push(`/supplier/purchaseRequests/edit/${purchaseRequest.id}`);
		},
		[history, purchaseRequest.id]
	);

	const userCurrencyId = useMemo(
		() => nullSafeGet('id', getCurrency({ currentUser })),
		[currentUser]
	);

	const editPart = useCallback(
		(lineItem) => (e) => {
			stopDefaultEvents(e);
			setCurrentlyEditingPart(lineItem.part);
			setReplacingLineItem(lineItem);
			setPartEditDrawerVisible(true);
		},
		[]
	);

	const onPartEditClose = useCallback(() => {
		setPartEditDrawerVisible(false);
		setCurrentlyEditingPart(null);
		setReplacingLineItem(null);
	}, []);

	const onPartEditSuccess = useCallback(() => {
		onPartEditClose();
		fetchPurchaseRequest();
	}, [fetchPurchaseRequest, onPartEditClose]);

	const onReplacePartLineItemSuccess = useCallback(() => {
		_hideReplacePartModal();
		onPartEditClose();
		fetchPurchaseRequest();
	}, [onPartEditClose, fetchPurchaseRequest]);

	const partsColumns = useMemo(
		() => [
			{
				title: 'Part Number',
				dataIndex: ['part'],
				render: (part, record) => (
					<div className="flex flex-row items-center">
						<div>{nullSafeGet('partNumber', part) || '--'}</div>
						<Button type="link" icon={<EditOutlined translate="" />} onClick={editPart(record)} />
					</div>
				),
			},
			{
				title: 'Part',
				dataIndex: 'partId',
				render: (_, record) => <PartLineItemRowDisplay partLineItem={record} />,
			},
			{
				title: 'Vendor Name',
				render: (_, record) =>
					nullSafeGetOrElse('partCatalog.partEquipmentVendor.name', record, '--'),
			},
			{
				title: 'Quantity',
				dataIndex: 'partQuantity',
			},
			{
				title: 'Unit Cost',
				dataIndex: 'partCost',
				render: (_, record) => {
					const unitCost =
						_ && record.partQuantity ? roundOffToDecimal(_ / record.partQuantity) : undefined;
					const currencyId = nullSafeGetOrElse(
						'partCatalog.partEquipmentVendor.currencyId',
						record,
						userCurrencyId
					);
					return renderCurrencyByCurrencyId(currencyId)(unitCost);
				},
			},
			{
				title: 'Total Cost',
				dataIndex: 'partCost',
				render: (_, record) => {
					const currencyId = nullSafeGetOrElse(
						'partCatalog.partEquipmentVendor.currencyId',
						record,
						userCurrencyId
					);
					return renderCurrencyByCurrencyId(currencyId)(_);
				},
			},
			{
				render: (_, record) => (
					<Button type="ghost" onClick={onEditPRLineItem} disabled={!canEdit}>
						Edit
					</Button>
				),
			},
		],
		[canEdit, editPart, onEditPRLineItem, userCurrencyId]
	);

	const editEquipment = useCallback(
		(lineItem) => (e) => {
			stopDefaultEvents(e);
			setCurrentlyEditingEquipment(lineItem.equipmentType);
			setReplacingLineItem(lineItem);
			setEquipmentEditDrawerVisible(true);
		},
		[]
	);

	const onEquipmentEditClose = useCallback(() => {
		setEquipmentEditDrawerVisible(false);
		setCurrentlyEditingEquipment(null);
		setReplacingLineItem(null);
	}, []);

	const onEquipmentEditSuccess = useCallback(() => {
		onEquipmentEditClose();
		fetchPurchaseRequest();
	}, [fetchPurchaseRequest, onEquipmentEditClose]);

	const onReplaceEquipmentLineItemSuccess = useCallback(() => {
		_hideReplaceEquipmentModal();
		onEquipmentEditClose();
		fetchPurchaseRequest();
	}, [onEquipmentEditClose, fetchPurchaseRequest]);

	const equipmentColumns = useMemo(
		() => [
			{
				title: 'Equipment Type #',
				dataIndex: ['equipmentType'],
				render: (equipment, record) => (
					<div className="flex flex-row items-center">
						<div>{nullSafeGet('equipmentTypeNumber', equipment) || '--'}</div>
						<Button
							type="link"
							icon={<EditOutlined translate="" />}
							onClick={editEquipment(record)}
						/>
					</div>
				),
			},
			{
				title: 'Equipment',
				dataIndex: 'equipmentTypeNumber',
				render: (_, record) => <EquipmentLineItemRowDisplay equipmentLineItem={record} />,
			},
			{
				title: 'Vendor Name',
				render: (_, record) =>
					nullSafeGetOrElse('equipmentCatalog.partEquipmentVendor.name', record, '--'),
			},
			{
				title: 'Quantity',
				dataIndex: 'equipmentQuantity',
			},
			{
				title: 'Unit Cost',
				dataIndex: 'equipmentCost',
				render: (_, record) => {
					const unitCost =
						_ && record.equipmentQuantity
							? roundOffToDecimal(_ / record.equipmentQuantity)
							: undefined;
					const currencyId = nullSafeGetOrElse(
						'equipmentCatalog.partEquipmentVendor.currencyId',
						record,
						userCurrencyId
					);
					return renderCurrencyByCurrencyId(currencyId)(unitCost);
				},
			},
			{
				title: 'Total Cost',
				dataIndex: 'equipmentCost',
				render: (_, record) => {
					const currencyId = nullSafeGetOrElse(
						'equipmentCatalog.partEquipmentVendor.currencyId',
						record,
						userCurrencyId
					);
					return renderCurrencyByCurrencyId(currencyId)(_);
				},
			},
			{
				render: () => (
					<Button type="ghost" onClick={onEditPRLineItem} disabled={!canEdit}>
						Edit
					</Button>
				),
			},
		],
		[canEdit, editEquipment, onEditPRLineItem, userCurrencyId]
	);

	const openEquipmentDetail = useCallback(
		(record) => {
			const path = `/supplier/equipments/detail/${record.equipmentCatalog.equipmentType.id}`;
			history.push(getLinkWIthBackLinkParams(location, DEFAULT_BACK_LINK_TEXT, path));
		},
		[history, location]
	);

	const equipmentOnRow = (record) => ({
		onClick: () => openEquipmentDetail(record),
	});

	const openPartDetail = useCallback(
		(record) => {
			const path = `/supplier/parts/detail/${record.part.id}`;
			history.push(getLinkWIthBackLinkParams(location, DEFAULT_BACK_LINK_TEXT, path));
		},
		[history, location]
	);

	const partsOnRow = (record) => ({
		onClick: () => openPartDetail(record),
	});

	const replacePartLineItem = () => setReplacePartModalVisible(true);
	const _hideReplacePartModal = () => setReplacePartModalVisible(false);

	const replaceEquipmentLineItem = () => setReplaceEquipmentModalVisible(true);
	const _hideReplaceEquipmentModal = () => setReplaceEquipmentModalVisible(false);

	const fetching = purchaseRequestsFetching && !nullSafeGet('id', purchaseRequest);
	return fetching ? (
		<PageLoadingPlaceholder />
	) : (
		<Content className="purchaseRequestsDetailDetailsPage">
			<LogOnMountWithStandardEventProperties eventType="visited supplier purchase requests detail page" />
			<Row style={{ margin: '16px 4px' }} gutter={12}>
				<Col span={24} className="rowSpacing">
					{isSupplierInventoryConfigEnabled(companyConfig, CONFIG_NAMES.DISABLE_PURCHASE_ORDERS) ? (
						<PurchaseRequestStatusActions
							purchaseRequest={purchaseRequest}
							onSuccess={fetchPurchaseRequest}
						/>
					) : purchaseRequest.status === PURCHASE_REQUEST_STATUSES.requested ? (
						<Card>
							<h5>This purchase request is on hold pending your approval.</h5>
							<div style={{ fontSize: 16, maxWidth: 640 }}>
								Please review the details below and either approve the purchase request or reject
								it.
							</div>
							<div style={{ marginTop: 16 }}>
								<Button
									type="primary"
									key="approve"
									loading={approving}
									onClick={handleApprovePurchaseRequest}
								>
									Approve
								</Button>
								<Button
									key="reject"
									style={{ marginLeft: 16 }}
									onClick={handleRejectPurchaseRequest}
									loading={rejecting}
								>
									Reject
								</Button>
							</div>
						</Card>
					) : null}
				</Col>
				<Col span={24} className="rowSpacing">
					<Card>
						<HorizontalKeyValueDisplay
							rowStyle={{ minWidth: '25%' }}
							keyValueStore={purchaseRequestDetails}
						/>
					</Card>
				</Col>
				{nullSafeGetOrElse('partSupplierPurchaseRequestLineItems', purchaseRequest, []).length >
				0 ? (
					<Col span={24} className="rowSpacing">
						<Card title={`Parts`}>
							<Table
								columns={partsColumns}
								rowKey={(partLineItem) => partLineItem['partCatalogId']}
								dataSource={purchaseRequest.partSupplierPurchaseRequestLineItems}
								showHeader
								loading={false}
								onRow={partsOnRow}
							/>
						</Card>
					</Col>
				) : null}
				{nullSafeGetOrElse('equipmentSupplierPurchaseRequestLineItems', purchaseRequest, [])
					.length > 0 ? (
					<Col span={24} className="rowSpacing">
						<Card title={`Equipment`}>
							<Table
								columns={equipmentColumns}
								rowKey={(equipment) => equipment['id']}
								dataSource={purchaseRequest.equipmentSupplierPurchaseRequestLineItems}
								showHeader
								loading={false}
								onRow={equipmentOnRow}
							/>
						</Card>
					</Col>
				) : null}
			</Row>
			{partEditDrawerVisible ? (
				<Drawer
					width="50%"
					placement="right"
					closable={true}
					onClose={onPartEditClose}
					visible={true}
					extra={
						<Popover
							content={
								EDITABLE_PR_STATUSES.includes(nullSafeGet('status', replacingLineItem))
									? ''
									: 'Ordered parts cannot be replaced'
							}
							trigger="hover"
						>
							<Button
								type="link"
								disabled={!EDITABLE_PR_STATUSES.includes(nullSafeGet('status', replacingLineItem))}
								onClick={replacePartLineItem}
							>
								Replace Part
							</Button>
						</Popover>
					}
				>
					<PartForm
						onCancel={onPartEditClose}
						formData={currentlyEditingPart}
						onSuccess={onPartEditSuccess}
					/>
				</Drawer>
			) : null}
			{equipmentEditDrawerVisible ? (
				<Drawer
					width="50%"
					placement="right"
					closable={true}
					onClose={onEquipmentEditClose}
					visible={true}
					extra={
						<Popover
							content={
								EDITABLE_PR_STATUSES.includes(nullSafeGet('status', replacingLineItem))
									? ''
									: 'Ordered equipment cannot be replaced'
							}
							trigger="hover"
						>
							<Button
								type="link"
								disabled={!EDITABLE_PR_STATUSES.includes(nullSafeGet('status', replacingLineItem))}
								onClick={replaceEquipmentLineItem}
							>
								Replace Equipment
							</Button>
						</Popover>
					}
				>
					<EquipmentForm
						onCancel={onEquipmentEditClose}
						formData={currentlyEditingEquipment}
						onSuccess={onEquipmentEditSuccess}
					/>
				</Drawer>
			) : null}
			{replacePartModalVisible ? (
				<ReplacePartLineItemModal
					isPRLineItem
					lineItem={replacingLineItem}
					vendorId={nullSafeGet('partCatalog.partEquipmentVendorId', replacingLineItem)}
					onCancel={_hideReplacePartModal}
					onSuccess={onReplacePartLineItemSuccess}
				/>
			) : null}
			{replaceEquipmentModalVisible ? (
				<ReplaceEquipmentLineItemModal
					isPRLineItem
					lineItem={replacingLineItem}
					vendorId={nullSafeGet('equipmentCatalog.partEquipmentVendorId', replacingLineItem)}
					onCancel={_hideReplaceEquipmentModal}
					onSuccess={onReplaceEquipmentLineItemSuccess}
				/>
			) : null}
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	purchaseRequestsFetching: state.purchase_requests.fetching,
	purchaseRequest: getEntityById(ownProps.match.params.id, state.purchase_requests),
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
	location: ownProps.location,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	getPurchaseRequest: (id) => dispatch(purchaseRequestsRestCrudThunksForSupplier.readOne(id)),
	approvePurchaseRequest: (id) => dispatch(purchaseRequestStatusChangeForSupplier(id, 'approved')),
	rejectPurchaseRequest: (id) => dispatch(purchaseRequestStatusChangeForSupplier(id, 'denied')),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(PurchaseRequestsDetailPage)
);

import React, { FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import RequestForProposalForm from '../request_for_proposal_form/RequestForProposalForm';
import { readBiddersForBuyer, readBiddersForSupplier } from '../../thunks/bidders_thunks';
import { BIDDERS_CRUD_ACTION_CREATORS } from '../../actions/bidders_actions';
import {
	requestsForProposalRestCrudThunksForBuyer,
	requestsForProposalRestCrudThunksForSupplier,
} from '../../thunks/requests_for_proposal_thunks';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { ROLE_TYPES } from '../../utils/DataConstants';

const EditRequestForProposalPage: FC<any> = ({
	match,
	saveFormData,
	getBiddersForRequestForProposal,
	getRequestForProposal,
	clearBidders,
	history,
	biddersFetching,
	requestsForProposalFetching,
	biddersFormData,
	userType,
}): React.ReactElement => {
	useEffect(() => {
		const requestForProposalId = match.params.id;
		getRequestForProposal(requestForProposalId).then((rfp) => {
			getBiddersForRequestForProposal(requestForProposalId).then((bidders) => {
				const formData = {
					...rfp,
					bidders: bidders.map((sesa) => ({
						...sesa,
						supplierFacilityId: sesa.supplierFacility.id,
					})),
				};
				saveFormData(formData);
			});
		});

		return () => clearBidders();
	}, [
		clearBidders,
		getBiddersForRequestForProposal,
		getRequestForProposal,
		match.params.id,
		saveFormData,
	]);

	const fetching = useMemo(
		() => biddersFetching || requestsForProposalFetching,
		[biddersFetching, requestsForProposalFetching]
	);

	return (
		<>
			<LogOnMountWithStandardEventProperties eventType={`visited ${userType} edit rfp page`} />,
			<EditPage
				title="Edit Request For Proposal"
				formComponentProps={{
					formData: biddersFormData,
					onSuccess: (rec) => history.push(`/${userType}/requestsForProposal/detail/${rec.id}`),
				}}
				fetching={fetching}
				FormComponent={RequestForProposalForm}
			/>
			,
		</>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	biddersFormData: state.bidders.formData,
	biddersFetching: state.bidders.fetching,
	requestsForProposalFetching: state.requests_for_proposal.fetching,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	saveFormData: (formData) => dispatch(BIDDERS_CRUD_ACTION_CREATORS.saveFormData(formData)),
	clearBidders: () => dispatch(BIDDERS_CRUD_ACTION_CREATORS.clearFormData()),
	getRequestForProposal: (rfpId) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? requestsForProposalRestCrudThunksForSupplier.readOne(rfpId)
				: requestsForProposalRestCrudThunksForBuyer.readOne(rfpId)
		),
	getBiddersForRequestForProposal: (rfpId) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? readBiddersForSupplier(rfpId)
				: readBiddersForBuyer(rfpId)
		),
});

const EditRFPPage = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(EditRequestForProposalPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(EditRFPPage);

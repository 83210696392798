import * as React from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, Input, Button, Upload, message } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { getObjectValues, nullSafeGet } from '../../utils/DataAccessUtils';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import { spendCategoriesRestCrudThunksForSupplier } from '../../thunks/spend_categories_thunks';
import { supplierFacilitiesRestCrudThunksForSupplier } from '../../thunks/supplier_facilities_thunks';
import { manuallyAssociateSupplierContactWithFacility } from '../../thunks/supplier_contacts_thunks';
import { LocationAutocomplete } from '../location_autocomplete/LocationAutocomplete';
import BackButton from '../back_button/BackButton';
import { retrieveCachedUserDetails } from '../../thunks/session_thunks';
import { imageUploadValidation } from '../../utils/ImageUtils';
import { capitalize } from '../../utils/DataFormatterUtils';
import { CURRENCIES } from '../../utils/DataConstants';

const style = require('./SupplierFacilityForm.less');
const FormItem = Form.Item;

interface SupplierFacilityProps {
	history: any;
	creating: boolean;
	updating: boolean;
	spendCategories: any;
	formData: any;
	redirectForwardUrl: string;
	fetchSpendCategories: any;
	newAccountSetupMode: boolean;
	currentUser: any;
	supplierCompanyId: number;
	associateSupplierContactWithFacility: any;
	getSupplierFacilities: any;
	create: any;
	update: any;
}

interface SupplierFacilityFormProps extends FormComponentProps {
	history: any;
	creating: boolean;
	updating: boolean;
	spendCategories: any;
	formData: any;
	redirectForwardUrl: string;
	fetchSpendCategories: any;
	newAccountSetupMode: boolean;
	currentUser: any;
	supplierCompanyId: number;
	associateSupplierContactWithFacility: any;
	getSupplierFacilities: any;
	create: any;
	update: any;
}

class SupplierFacilityForm extends React.Component<SupplierFacilityFormProps, any> {
	state = {
		uploadLoading: false,
		logoURL: null,
	};

	componentDidMount() {
		const { fetchSpendCategories, formData } = this.props;
		fetchSpendCategories({});
		this.setState({ logoURL: formData.logoURL });
		this.props.form.setFieldsValue({ logoURL: formData.logoURL });
		this.props.form.setFieldsValue({
			'primaryAddress-streetAddress1': nullSafeGet('primaryAddress.streetAddress1', formData),
			'primaryAddress-city': nullSafeGet('primaryAddress.city', formData),
			'primaryAddress-region': nullSafeGet('primaryAddress.region', formData),
			'primaryAddress-postcode': nullSafeGet('primaryAddress.postcode', formData),
			'primaryAddress-country': nullSafeGet('primaryAddress.country', formData),
		});
	}

	handleSubmit = (e) => {
		const {
			create,
			update,
			spendCategories,
			redirectForwardUrl,
			formData,
			history,
			getSupplierFacilities,
			currentUser,
			supplierCompanyId,
		} = this.props;
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const isUpdate = formData.id !== undefined;
				const submitAction = isUpdate ? update : create;
				const primaryAddress = Object.keys(values)
					.filter((k) => k.indexOf('primaryAddress') > -1)
					.reduce((acc, k) => {
						const [_, x] = k.split('-');
						acc[x] = values[k];
						return acc;
					}, {});
				const operatingHours = {};
				const isWomenOwned = false;
				const isMinorityOwned = false;
				const isVeteranOwned = false;
				const isDisabledOwned = false;
				const isLgbtOwned = false;
				const isSmallBusinessEnterprise = false;
				const verifications = [];
				const faqs = [];
				const licenses = [];
				const certifications = [];
				const insurances = [];
				const socialMedia = {};
				const spendCategoriesRecordsMap = spendCategories.records;
				const categoryIds = values.spendCategories;
				const currencyId = values.currencyId;
				const categoryNames = values.spendCategories.map(
					(id) => spendCategoriesRecordsMap[id].name
				);
				let additionalDetails = {};
				const supplier = {
					...formData,
					operatingHours,
					isWomenOwned,
					isDisabledOwned,
					isMinorityOwned,
					isVeteranOwned,
					isLgbtOwned,
					isSmallBusinessEnterprise,
					verifications,
					faqs,
					additionalDetails,
					licenses,
					certifications,
					insurances,
					socialMedia,
					...values,
					primaryAddress,
					categoryIds,
					categoryNames,
					supplierCompanyId,
					currencyId,
				};
				submitAction(supplier).then(() => {
					if (redirectForwardUrl) {
						history.push(redirectForwardUrl);
					}
					message.success(`Facility ${isUpdate ? 'updated' : 'created'}.`);
				});
			}
		});
	};

	handleUploadChange = (info) => {
		if (info.file.status === 'uploading') {
			this.setState({ uploadLoading: true });
			return;
		}
		if (info.file.status === 'done') {
			const companyLogoURL = nullSafeGet('file.response.data', info);
			this.props.form.setFieldsValue({ logoURL: companyLogoURL });
			this.setState({
				logoURL: companyLogoURL,
				uploadLoading: false,
			});
		}
	};

	handleLocationAutocompleteSelect = (addr, lonLat, parsedAddress) => {
		const { form } = this.props;
		form.setFieldsValue({
			'primaryAddress-streetAddress1': parsedAddress.streetAddress1,
			'primaryAddress-city': parsedAddress.city,
			'primaryAddress-region': parsedAddress.region,
			'primaryAddress-postcode': parsedAddress.postcode,
			'primaryAddress-country': parsedAddress.country,
		});
		form.setFieldsValue({
			longitude: lonLat.lng,
			latitude: lonLat.lat,
		});
	};

	render() {
		const BACKEND_URI = getBackendUri();

		const { creating, updating, form, formData, spendCategories } = this.props;
		const { logoURL } = this.state;

		const { getFieldDecorator, setFieldsValue } = form;
		const uploadHeaders = { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token };
		const spendCategoriesRecordsList = getObjectValues(spendCategories.records);
		const spendCategoriesRecordsMap = spendCategories.records;
		const currencyRecordsList = getObjectValues(CURRENCIES);
		const currencyRecordsMap = getObjectValues(CURRENCIES);

		getFieldDecorator(`longitude`);
		getFieldDecorator(`latitude`);
		getFieldDecorator(`primaryAddress-streetAddress1`);
		getFieldDecorator(`primaryAddress-streetAddress2`);
		getFieldDecorator(`primaryAddress-city`);
		getFieldDecorator(`primaryAddress-region`);
		getFieldDecorator(`primaryAddress-postcode`);
		getFieldDecorator(`primaryAddress-country`);
		getFieldDecorator('status', { initialValue: 'active' });
		getFieldDecorator('logoURL', {
			initialValue: null,
			rules: [{ required: true, message: "Please upload your company's logo." }],
		});
		const createRawAddress = (addr) => {
			if (!addr || addr === undefined) {
				return '';
			}
			return `${addr.streetAddress1}  ${addr.city}, ${addr.region}, ${addr.postcode}, ${addr.country}`;
		};
		const rawAddress = createRawAddress(formData.primaryAddress);
		const uploadButton = (
			<div>
				<LegacyIcon type={this.state.uploadLoading ? 'loading' : 'plus'} />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		const isLoading = creating || updating;

		return (
			<Form layout="vertical" onSubmit={this.handleSubmit} className="supplierForm">
				<FormItem label="What is the company name you put on legal documents?">
					{getFieldDecorator('name', {
						rules: [{ required: true, message: "Please input your company's legal entity name!" }],
						initialValue: formData.name,
					})(<Input style={{ maxWidth: 500 }} />)}
				</FormItem>
				<FormItem label="What name do your customers call your company?">
					{getFieldDecorator('displayName', {
						rules: [
							{
								required: true,
								message:
									"Please input your company's name as used in advertising and sales materials!",
							},
						],
						initialValue: formData.displayName,
					})(<Input style={{ maxWidth: 500 }} />)}
				</FormItem>
				<FormItem
					label="Upload your company's logo. Square 300x300 pixel images look best."
					required={true}
				>
					<Upload
						name="file"
						listType="picture-card"
						className="avatar-uploader"
						showUploadList={false}
						headers={uploadHeaders}
						action={`${BACKEND_URI}/api/v1/supplier/file/public/company_logo/upload`}
						beforeUpload={imageUploadValidation}
						onChange={this.handleUploadChange}
					>
						{logoURL ? <img src={logoURL} style={{ maxHeight: 104 }} alt="" /> : uploadButton}
					</Upload>
				</FormItem>
				<Form.Item label="Currency">
					{form.getFieldDecorator('currencyId', {
						rules: [{ required: true, message: 'Please select a currency for this event.' }],
						initialValue: formData.currencyId || 'USD',
					})(
						<Select
							showSearch={true}
							style={{ width: 300 }}
							optionFilterProp="children"
							filterOption={(input, option) =>
								currencyRecordsMap[option.props.value].displayNamePlural
									.toLowerCase()
									.indexOf(input.toLowerCase()) >= 0
							}
							placeholder="Currency"
						>
							{currencyRecordsList.map((currency) => (
								<Select.Option key={currency.id} value={currency.id}>
									{capitalize(currency.displayNamePlural)} ({currency.id})
								</Select.Option>
							))}
						</Select>
					)}
				</Form.Item>
				<FormItem label="Select the categories of services your company provides.">
					{getFieldDecorator('spendCategories', {
						rules: [
							{
								required: true,
								message:
									'Please select one or two categories that describe the services your provide!',
							},
						],
						initialValue: formData.categoryIds,
					})(
						<Select
							showSearch={true}
							style={{ width: 240 }}
							mode="multiple"
							optionFilterProp="children"
							filterOption={(input, option) =>
								spendCategoriesRecordsMap[option.props.value].name
									.toLowerCase()
									.indexOf(input.toLowerCase()) >= 0
							}
						>
							{spendCategoriesRecordsList.map((category) => (
								<Select.Option key={category.id} value={category.id}>
									{category.name}
								</Select.Option>
							))}
						</Select>
					)}
				</FormItem>
				<FormItem label="Tell us a bit about what your company does.">
					{getFieldDecorator('about', {
						rules: [
							{
								required: true,
								message: 'Please give a brief description of what your company does!',
							},
						],
						initialValue: formData.about,
					})(<Input.TextArea rows={4} />)}
				</FormItem>
				<FormItem label="What is the best email for customers to reach you?">
					{getFieldDecorator('primaryContactEmail', {
						rules: [{ required: true, message: "Please input your primary contact's email!" }],
						initialValue: formData.primaryContactEmail,
					})(<Input style={{ maxWidth: 350 }} />)}
				</FormItem>
				<FormItem label="What is the email where you prefer to receive purchase orders?">
					{getFieldDecorator('poEmail', {
						rules: [
							{ required: true, message: 'Please input best contact email for purchase orders!' },
						],
						initialValue: formData.poEmail,
					})(<Input style={{ maxWidth: 350 }} />)}
				</FormItem>
				<FormItem label="Add a link to your company's website">
					{getFieldDecorator('corporateURL', {
						initialValue: formData.corporateURL,
					})(<Input style={{ maxWidth: 400 }} />)}
				</FormItem>
				<Form.Item label="Primary Address">
					<LocationAutocomplete
						initialValue={rawAddress}
						placeholder="Where is your company's primary place of business?"
						onSelect={this.handleLocationAutocompleteSelect}
					/>
				</Form.Item>
				<FormItem>
					<Button
						type="primary"
						htmlType="submit"
						loading={isLoading}
						style={{ marginRight: 16 }}
						className="supplierForm__button"
					>
						Update facility
					</Button>
					<BackButton buttonText="Cancel" />
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	supplierCompanyId: ownProps.supplierCompanyId,

	currentUser: state.session.currentUser,
	spendCategories: state.spend_categories,
	formData: ownProps.formData,
	creating: state.suppliers.creating,
	updating: state.suppliers.updating,
});

const mapDispatchToProps = (dispatch) => ({
	getSupplierFacilities: (name) =>
		dispatch(supplierFacilitiesRestCrudThunksForSupplier.read({ name })),
	fetchSpendCategories: (params) => dispatch(spendCategoriesRestCrudThunksForSupplier.read(params)),
	create: (entity) => dispatch(supplierFacilitiesRestCrudThunksForSupplier.create(entity)),
	update: (entity) => dispatch(supplierFacilitiesRestCrudThunksForSupplier.update(entity)),
	associateSupplierContactWithFacility: (contact, facilityId) =>
		dispatch(manuallyAssociateSupplierContactWithFacility(contact, facilityId)),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<SupplierFacilityFormProps>()(SupplierFacilityForm))
);

import * as React from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { FlexibleImageAvatar } from '../flexible-image-avatar/FlexibleImageAvatar';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { getProtectedImageUriForSupplier } from '../../utils/FileAccessUtils';

const style = require('./PartCatalogRowDisplay.less');

export const PartCatalogRowDisplay: React.FC<any> = ({
	partCatalog,
	titleLength = 56,
	showPhoto,
	...props
}) => {
	const previewImage =
		nullSafeGet('part.images', partCatalog) && partCatalog.part.images.length > 0
			? partCatalog.part.images.map((locImg) => {
					const [imgFileId, imgFileName] = locImg.split('/');
					return getProtectedImageUriForSupplier(imgFileId, imgFileName, 1200, 800);
			  })[0]
			: null;

	return (
		<div className="partCatalogRowDisplay" style={{ justifyContent: 'space-between' }} {...props}>
			<div className="partCatalogRowDisplay">
				<div style={{ marginRight: 24 }}>
					{showPhoto ? (
						previewImage ? (
							<FlexibleImageAvatar
								displayName={nullSafeGet('part.name', partCatalog)}
								showPopover={false}
								popoverText={nullSafeGet('part.name', partCatalog)}
								squareMode={true}
								width="120px"
								height="80px"
								imageUrl={previewImage}
							/>
						) : (
							<FlexibleImageAvatar
								displayName={nullSafeGet('part.name', partCatalog)}
								showPopover={false}
								popoverText={nullSafeGet('part.name', partCatalog)}
								squareMode={true}
								width="120px"
								height="80px"
								imageUrl="https://s3.amazonaws.com/emptor-data-assets/images/empty-state/icons8-screw-filled-100.png"
							/>
						)
					) : null}
				</div>
				<div>
					<div className="partCatalogRowDisplay__rowTitle">
						<Ellipsis tooltip={true} length={titleLength}>
							{nullSafeGet('part.name', partCatalog)}
						</Ellipsis>
					</div>
					<div className="partCatalogRowDisplay__rowSubtitle">
						{nullSafeGet('part.partNumber', partCatalog) ? (
							<div className="partCatalogRowDisplay__rowInlineGroup">
								Part #{nullSafeGet('part.partNumber', partCatalog)}
							</div>
						) : null}
					</div>
				</div>
			</div>
			<div className="partCatalogRowDisplay__rightSide"></div>
		</div>
	);
};

import { VENDORS_CRUD_ACTION_CREATORS } from '../actions/vendors_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { getParamsWithPaginationAndSorting } from '../utils/DataAccessUtils';
import FetchUtils, { parseJSON } from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { logoutSuccess } from '../actions/session_actions';

const fetchUtils = new FetchUtils();

export const vendorsRestCrudThunksForBuyer = new RestCrudThunks(
	'part_equipment_vendors',
	'part_equipment_vendor',
	'partEquipmentVendor',
	'partEquipmentVendors',
	VENDORS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'inventory/'
);

export const vendorsRestCrudThunksForSupplier = new RestCrudThunks(
	'part_equipment_vendors',
	'part_equipment_vendor',
	'partEquipmentVendor',
	'partEquipmentVendors',
	VENDORS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'inventory/'
);

const fetchVendorsWithPurchaseRequestLineItemCount =
	(roleType) =>
	(
		params,
		targetCollectionName = null,
		pagination = null,
		sorting = null,
		filters = null,
		addToTargetCollection = false
	) => {
		params = getParamsWithPaginationAndSorting(params, pagination, sorting, filters);

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.get(
						getBackendUri() +
							`/api/v1/${roleType}/inventory/part_equipment_vendors/with_purchase_request_line_item_count`,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.message);
						} else {
							dispatch(
								VENDORS_CRUD_ACTION_CREATORS.fetchSuccess(
									d.data,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters,
									addToTargetCollection
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};

export const fetchVendorsWithPurchaseRequestLineItemCountForSupplier =
	fetchVendorsWithPurchaseRequestLineItemCount(ROLE_TYPES.SUPPLIER);

import { FC, useCallback, useMemo } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Button, Popover } from 'antd';
import * as React from 'react';
import { isInternalTech } from '../../utils/AuthUtils';
import { isAdminUser, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { INVOICE_STATUSES } from '../../constants/InvoiceStatuses';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { BUYER_ADMIN, SUPER_ADMIN, SUPPLIER_ADMIN } from '../roles/roles';

const InvoiceEditButton: FC<any> = ({
	userType,
	currentUser,
	history,

	ghost = false,
	invoice,
}) => {
	const workOrder = useMemo(() => nullSafeGet('workOrder', invoice), [invoice]);
	const isAdmin = useMemo(() => isAdminUser(currentUser), [currentUser]);

	const isBuyerInvoice = useMemo(() => invoice.isBuyerUploaded || false, [invoice]);
	const isBuyerOrInternalTech = useMemo(
		() => userType === ROLE_TYPES.BUYER || isInternalTech(currentUser),
		[userType, currentUser]
	);
	const onClick = useCallback(
		() =>
			nullSafeGetOrElse('location.pathname', history, '').includes('/workOrders/detail/')
				? history.push(
						`/${userType}/workOrders/detail/${nullSafeGet('id', workOrder)}/invoices/${
							invoice.id
						}/edit?btwo=true`
				  )
				: history.push(`/${userType}/invoices/edit/${invoice.id}`),
		[history, invoice]
	);
	const style = { marginLeft: 12 };

	const buttonProps = { style, onClick, ghost };

	return useMemo(() => {
		switch (invoice.status) {
			case INVOICE_STATUSES.draft:
				//External Supplier can edit
				return isBuyerOrInternalTech ? null : <Button {...buttonProps}>Edit</Button>;

			case INVOICE_STATUSES.pending:
				if (isBuyerOrInternalTech) {
					if (isBuyerInvoice) {
						if (isAdmin) {
							return <Button {...buttonProps}>Edit</Button>;
						} else {
							return null;
						}
					} else {
						return (
							<Popover
								content={'You can only edit invoices submitted by your company.'}
								placement={'left'}
							>
								<Button {...buttonProps} disabled={true}>
									Edit
								</Button>
							</Popover>
						);
					}
				} else {
					return (
						<Popover
							content={'Invoice must be moved back to draft status to edit it.'}
							placement={'left'}
						>
							<Button {...buttonProps} disabled={true}>
								Edit
							</Button>
						</Popover>
					);
				}
			case INVOICE_STATUSES.disputed:
				if (isBuyerOrInternalTech) {
					if (isBuyerInvoice) {
						if (isAdmin) {
							return <Button {...buttonProps}>Edit</Button>;
						} else {
							return null;
						}
					} else {
						return (
							<Popover
								content={'You can only edit invoices submitted by your company.'}
								placement={'left'}
							>
								<Button {...buttonProps} disabled={true}>
									Edit
								</Button>
							</Popover>
						);
					}
				} else {
					return <Button {...buttonProps}>Edit</Button>;
				}
			default:
				if (isBuyerOrInternalTech) {
					if (isBuyerInvoice) {
						if (isAdmin) {
							return (
								<Popover
									content={'Move this invoice back to pending status to edit it.'}
									placement={'left'}
									trigger={'hover'}
								>
									<Button {...buttonProps} disabled={true}>
										Edit
									</Button>
								</Popover>
							);
						} else {
							return null;
						}
					} else {
						return (
							<Popover
								content={'You can only edit invoices submitted by your company.'}
								placement={'left'}
							>
								<Button {...buttonProps} disabled={true}>
									Edit
								</Button>
							</Popover>
						);
					}
				} else {
					return (
						<Popover
							content={'Invoice must be moved back to draft status to edit it.'}
							placement={'left'}
						>
							<Button {...buttonProps} disabled={true}>
								Edit
							</Button>
						</Popover>
					);
				}
		}
	}, [isBuyerInvoice, isBuyerOrInternalTech, isAdmin, invoice]);
};

const mapStateToProps = (state, ownProps) => ({
	currentUser: state.session.currentUser,
	history: ownProps.history,
});

const ComponentWithoutUserType = withRouter(connect(mapStateToProps, {})(InvoiceEditButton));

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import { WORK_ORDERS_CRUD_ACTION_CREATORS } from '../actions/work_orders_actions';
import {
	morePipelineFiltersBuyer,
	pipelineFiltersBuyer,
	morePipelineFiltersSupplier,
	pipelineFiltersSupplier,
} from '../components/WorkOrderTable/config/pipelineFilters/pipelineFilters';
import {
	DEFAULT_SORTER,
	GLOBAL_DEBOUNCE_TIME,
	ROLE_TYPES,
	FETCH_DATA_PREFIX_FOR_TARGET_COLLECTION,
} from '../utils/DataConstants';
import {
	downloadWorkOrderCSVForBuyer,
	downloadWorkOrderCSVForSupplier,
	getCountByDisplayStatusForBuyer,
	getCountByDisplayStatusForSupplier,
	getCountByStatusForBuyer,
	getCountByStatusForSupplier,
	workOrdersRestCrudThunksForBuyer,
	workOrdersRestCrudThunksForSupplier,
} from './work_orders_thunks';
const queryString = require('qs');
const defaultPagination = { current: 1, pageSize: 5 };
const debounce = require('debounce-promise');

const getMethodByRole = (userType) =>
	userType === ROLE_TYPES.SUPPLIER
		? workOrdersRestCrudThunksForSupplier
		: workOrdersRestCrudThunksForBuyer;
export const parseURL = (
	handleQueryParams,
	history,
	location,
	preAppliedFilters,
	initialPagination,
	initialSorting,
	initialFilters = {}
) => {
	let pagination = initialPagination || defaultPagination;
	let sorting = initialSorting || DEFAULT_SORTER;
	let filters = { ...preAppliedFilters, ...initialFilters };
	if (handleQueryParams && location) {
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);

		const {
			current = pagination.current,
			pageSize = pagination.pageSize,
			sort_by = sorting.sort_by,
			order = sorting.order,
			...workOrderFilters
		} = queryParams;

		pagination = { current: parseInt(current), pageSize: parseInt(pageSize) };
		sorting = { sort_by, order };
		filters = {
			...(initialFilters || {}),
			...(workOrderFilters || {}),
			...preAppliedFilters,
		};
	}
	return { pagination, sorting, filters };
};
export const fetchWorkOrders =
	(userType) =>
	(
		handleQueryParams,
		history,
		location,
		targetCollectionName,
		preAppliedFilters = {},
		initialPagination,
		initialSorting,
		initialFilters,
		ignoreRefreshCounts
	) =>
	(dispatch, getState) => {
		const { pagination, sorting, filters } = parseURL(
			handleQueryParams,
			history,
			location,
			preAppliedFilters,
			initialPagination,
			initialSorting,
			initialFilters
		);
		dispatch(
			fetchData(userType)(
				handleQueryParams,
				history,
				null,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				ignoreRefreshCounts
			)
		);
	};
export const updatePaginationWorkOrders =
	(userType) =>
	(handleQueryParams, history, targetCollectionName, pagination, ignoreRefreshCounts) =>
	(dispatch, getState) => {
		const { sorting, filters } = getState().work_orders[targetCollectionName];
		return dispatch(
			fetchData(userType)(
				handleQueryParams,
				history,
				null,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				ignoreRefreshCounts
			)
		);
	};

export const updateFiltersWorkOrders =
	(userType) =>
	(handleQueryParams, history, targetCollectionName, filters, ignoreRefreshCounts) =>
	(dispatch, getState) => {
		const {
			sorting,
			filters: storeFilters,
			pagination,
		} = getState().work_orders[targetCollectionName];
		dispatch(
			fetchData(userType)(
				handleQueryParams,
				history,
				null,
				targetCollectionName,
				{ ...pagination, current: 1 },
				sorting,
				{
					...storeFilters,
					...filters,
				},
				ignoreRefreshCounts
			)
		);
	};

export const replaceFiltersAndSortingWorkOrders =
	(userType) =>
	(handleQueryParams, history, targetCollectionName, filters, sorting, ignoreRefreshCounts) =>
	(dispatch, getState) => {
		const { pagination } = getState().work_orders[targetCollectionName];
		dispatch(
			fetchData(userType)(
				handleQueryParams,
				history,
				null,
				targetCollectionName,
				{ ...pagination, current: 1 },
				sorting,
				filters,
				ignoreRefreshCounts
			)
		);
	};

export const clearFiltersWorkOrders =
	(userType) =>
	(handleQueryParams, history, targetCollectionName, preAppliedFilters, ignoreRefreshCounts) =>
	(dispatch, getState) => {
		const { pagination } = getState().work_orders[targetCollectionName];
		dispatch(
			fetchData(userType)(
				handleQueryParams,
				history,
				null,
				targetCollectionName,
				{ ...pagination, current: 1 },
				{ ...DEFAULT_SORTER },
				{
					...preAppliedFilters,
				},
				ignoreRefreshCounts
			)
		);
	};

export const updateSortingWorkOrders =
	(userType) =>
	(handleQueryParams, history, targetCollectionName, sorting, ignoreRefreshCounts) =>
	(dispatch, getState) => {
		const { filters, pagination } = getState().work_orders[targetCollectionName];
		dispatch(
			fetchData(userType)(
				handleQueryParams,
				history,
				null,
				targetCollectionName,
				{ ...pagination, current: 1 },
				sorting,
				filters,
				ignoreRefreshCounts
			)
		);
	};
export const downloadWorkOrderCSV = (userType) => (params, filters) => {
	const downloadFn =
		userType === ROLE_TYPES.SUPPLIER
			? downloadWorkOrderCSVForSupplier
			: downloadWorkOrderCSVForBuyer;
	return downloadFn(params, filters);
};
export const refreshCounts =
	(userType) => (targetCollectionName, filters) => (dispatch, getState) => {
		const finalPipelineFilters =
			userType === ROLE_TYPES.SUPPLIER ? pipelineFiltersSupplier(true) : pipelineFiltersBuyer(true);
		const finalMorePipelineFilters =
			userType === ROLE_TYPES.SUPPLIER
				? morePipelineFiltersSupplier(true)
				: morePipelineFiltersBuyer(true);

		const countByDisplayStatusMethod =
			userType === ROLE_TYPES.SUPPLIER
				? getCountByDisplayStatusForSupplier
				: getCountByDisplayStatusForBuyer;

		countByDisplayStatusMethod({
			...filters,
			status: undefined,
			displayStatuses: finalPipelineFilters.map((_) => _.filter.displayStatus).join(','),
			statuses: undefined,
		}).then((res: any) => {
			if (res && res.length > 0) {
				res.map((category) =>
					dispatch(
						WORK_ORDERS_CRUD_ACTION_CREATORS.countBySuccess(
							category.count,
							category.categoryName,
							targetCollectionName
						)
					)
				);
			}
		});

		const countByStatusMethod =
			userType === ROLE_TYPES.SUPPLIER ? getCountByStatusForSupplier : getCountByStatusForBuyer;

		const getSubFilterStatuses = () => {
			const allDisplayStatuses = (filters.displayStatuses || filters.displayStatus || '').split(
				','
			);
			return allDisplayStatuses
				.reduce((acc, dps) => {
					return [
						...acc,
						...(dps !== undefined && finalMorePipelineFilters[dps] !== undefined
							? finalMorePipelineFilters[dps]
							: []),
					];
				}, [])
				.map((_) => _.filter.status);
		};

		if (filters.displayStatuses) {
			countByStatusMethod({
				...filters,
				status: undefined,
				statuses: getSubFilterStatuses(),
				displayStatus: undefined,
				displayStatuses: undefined,
			}).then((res: any) => {
				if (res && res.length > 0) {
					res.map((category) =>
						dispatch(
							WORK_ORDERS_CRUD_ACTION_CREATORS.countBySuccess(
								category.count,
								category.categoryName,
								targetCollectionName
							)
						)
					);
				}
			});
		}
	};

const fetchDataFromServer = (props) => {
	const {
		dispatch,
		userType,
		addtionalParams,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		ignoreRefreshCounts,
	} = props;
	!ignoreRefreshCounts && dispatch(refreshCounts(userType)(targetCollectionName, filters));
	return dispatch(
		getMethodByRole(userType).read(
			addtionalParams,
			targetCollectionName,
			pagination,
			sorting,
			filters
		)
	);
};

const debouncedFetchData = debounce(fetchDataFromServer, GLOBAL_DEBOUNCE_TIME);

const fetchData =
	(userType) =>
	(
		handleQueryParams,
		history,
		addtionalParams,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		ignoreRefreshCounts
	) =>
	(dispatch) => {
		const props = {
			dispatch,
			userType,
			addtionalParams,
			targetCollectionName,
			pagination,
			sorting,
			filters,
			ignoreRefreshCounts,
		};
		if (handleQueryParams) {
			const queryParams = queryString.stringify({
				...{
					current: pagination.current,
					pageSize: pagination.pageSize,
				},
				...sorting,
				...filters,
				...addtionalParams,
			});
			history.replace(`${window.location.pathname}?${queryParams}`);
		}
		if (
			targetCollectionName &&
			targetCollectionName.includes(FETCH_DATA_PREFIX_FOR_TARGET_COLLECTION)
		) {
			return fetchDataFromServer(props);
		} else {
			dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollectionName));
			return debouncedFetchData(props);
		}
	};

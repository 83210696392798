import React, { FC, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
	HIGHER_ORDER_PR_STATUSES,
	PURCHASE_REQUEST_STATUSES,
	PURCHASE_REQUEST_STATUS_DISPLAY_NAMES,
} from '../../constants/PurchaseRequestStatuses';
import { Button, Card, Popover, Steps, message } from 'antd';
import { purchaseRequestStatusChangeForSupplier } from '../../thunks/purchase_requests_thunks';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { createMultipleEpslAsDeletedForSupplier } from '../../thunks/equipment_per_stock_locations_thunks';
import { createMultipleAssetsAsDeletedForSupplier } from '../../thunks/assets_thunks';
import { withRouter } from 'react-router';
import UpdateSerialAmdAssetNumbersModal from '../purchase_orders_detail_details_page/UpdateSerialAmdAssetNumbersModal';

const PR_ACTIONS = {
	APPROVE: 'approve',
	REJECT: 'reject',
	ORDER: 'ordered',
	ORDER_IN_PROGRESS: 'orderInProgress',
	FULFILL: 'fulfilled',
};

const PurchaseRequestStatusActions: FC<any> = ({
	purchaseRequest,
	updatePurchaseRequestStatus,
	onSuccess,
	createEpslsBulkAsDeleted,
	createAssetsBulkAsDeleted,
	match,
}): React.ReactElement => {
	const [currentAction, setCurrentAction] = useState(null);

	const [equipmentSerialNumberVisible, setEquipmentSerialNumberVisible] = useState(false);
	const [createdEntities, setCreatedEntities] = useState([]);
	const [receivingEntityType, setReceivingEntityType] = useState(null);

	const prId = useMemo(() => match.params.id, [match.params.id]);

	const _hideSerialNumberUpdate = useCallback(() => setEquipmentSerialNumberVisible(false), []);

	const canShowActions = useMemo(
		() => HIGHER_ORDER_PR_STATUSES.includes(purchaseRequest.status),
		[purchaseRequest.status]
	);

	const currentPRIndex = useMemo(
		() => HIGHER_ORDER_PR_STATUSES.indexOf(purchaseRequest.status),
		[purchaseRequest.status]
	);

	const isLocationPR = useMemo(() => nullSafeGet('locationId', purchaseRequest), [purchaseRequest]);

	const eqipmentLineItems = useMemo(
		() => nullSafeGetOrElse('equipmentSupplierPurchaseRequestLineItems', purchaseRequest, []),
		[purchaseRequest]
	);

	const markFulfilledDisabledWithAssetTypeId = useMemo(() => {
		return isLocationPR && nullSafeGet('0', eqipmentLineItems)
			? !!eqipmentLineItems.find((_) => !nullSafeGet('equipmentType.assetTypeId', _))
			: false;
	}, [eqipmentLineItems, isLocationPR]);

	const messageHeader = useMemo(() => {
		switch (purchaseRequest.status) {
			case PURCHASE_REQUEST_STATUSES.requested:
				return 'This purchase request is on hold pending your approval.';
			case PURCHASE_REQUEST_STATUSES.approved:
				return 'This purchase request is approved.';
			case PURCHASE_REQUEST_STATUSES.orderInProgress:
				return 'Order is in progress for this purchase request.';
			case PURCHASE_REQUEST_STATUSES.ordered:
				return 'This purchase request is ordered.';
			case PURCHASE_REQUEST_STATUSES.fulfilled:
				return 'This purchase request is fulfilled.';
			default:
				return '';
		}
	}, [purchaseRequest.status]);

	const messageDescriptin = useMemo(() => {
		switch (purchaseRequest.status) {
			case PURCHASE_REQUEST_STATUSES.requested:
				return 'Please review the details below and either approve the purchase request or reject it.';
			case PURCHASE_REQUEST_STATUSES.approved:
				return 'Do you want to mark this ordered?';
			case PURCHASE_REQUEST_STATUSES.orderInProgress:
				return 'Do you want to mark this ordered or has this been fulfilled?';
			case PURCHASE_REQUEST_STATUSES.ordered:
				return 'Is this purchase request fulfilled?';
			default:
				return '';
		}
	}, [purchaseRequest.status]);

	const onApprove = useCallback(() => {
		setCurrentAction(PR_ACTIONS.APPROVE);
		updatePurchaseRequestStatus(prId, PURCHASE_REQUEST_STATUSES.approved)
			.then(() => onSuccess && onSuccess())
			.catch((err) => message.error(err))
			.finally(() => setCurrentAction(null));
	}, [onSuccess, prId, updatePurchaseRequestStatus]);

	const onReject = useCallback(() => {
		setCurrentAction(PR_ACTIONS.REJECT);
		updatePurchaseRequestStatus(prId, PURCHASE_REQUEST_STATUSES.denied)
			.then(() => onSuccess && onSuccess())
			.catch((err) => message.error(err))
			.finally(() => setCurrentAction(null));
	}, [onSuccess, prId, updatePurchaseRequestStatus]);

	const onOrdered = useCallback(() => {
		setCurrentAction(PR_ACTIONS.ORDER);
		updatePurchaseRequestStatus(prId, PURCHASE_REQUEST_STATUSES.ordered)
			.then(() => onSuccess && onSuccess())
			.catch((err) => message.error(err))
			.finally(() => setCurrentAction(null));
	}, [onSuccess, prId, updatePurchaseRequestStatus]);

	const createDeletedAssetsBulk = useCallback(() => {
		const allAssetEntities = eqipmentLineItems.flatMap((lineItem) =>
			Array.from(Array(lineItem.equipmentQuantity).keys()).map((_) => ({
				locationId: nullSafeGet('locationId', purchaseRequest),
				name: nullSafeGetOrElse('equipmentType.modelName', lineItem, ''),
				isActive: true,
				images: [],
				...(nullSafeGet('equipmentType.assetTypeId', lineItem) && {
					assetTypeId: nullSafeGet('equipmentType.assetTypeId', lineItem),
				}),
				assetTypeDetails: [],
				isLeased: false,
				buyerCompanyId: nullSafeGet('location.buyerCompanyId', purchaseRequest),
			}))
		);

		return createAssetsBulkAsDeleted(allAssetEntities);
	}, [createAssetsBulkAsDeleted, eqipmentLineItems, purchaseRequest]);

	const createDeletedEpslsBulk = useCallback(() => {
		const allEpslEntities = eqipmentLineItems.flatMap((lineItem) =>
			Array.from(Array(lineItem.equipmentQuantity).keys()).map((_) => ({
				stockLocationId: nullSafeGet('stockLocationId', purchaseRequest),
				equipmentTypeId: lineItem.equipmentTypeId,
				name: nullSafeGetOrElse('equipmentType.modelName', lineItem, ''),
				isRental: false,
				vendorId: nullSafeGet('equipmentCatalog.partEquipmentVendorId', lineItem) || undefined,
				images: [],
				supplierFacilityId: lineItem.supplierFacilityId,
				supplierCompanyId: lineItem.supplierCompanyId,
			}))
		);

		return createEpslsBulkAsDeleted(allEpslEntities);
	}, [createEpslsBulkAsDeleted, eqipmentLineItems, purchaseRequest]);

	const onUpdateSerialNumberSuccess = useCallback(() => {
		setCreatedEntities([]);
		setEquipmentSerialNumberVisible(false);
		onSuccess();
	}, [onSuccess]);

	const onReceiptValuesSuccessForPR = useCallback(
		(equipmentReceiptValues) =>
			updatePurchaseRequestStatus(
				prId,
				PURCHASE_REQUEST_STATUSES.fulfilled,
				equipmentReceiptValues
			),
		[prId, updatePurchaseRequestStatus]
	);

	const onFulfilled = useCallback(() => {
		setCurrentAction(PR_ACTIONS.FULFILL);

		if (eqipmentLineItems.length > 0) {
			const promiseToCall = isLocationPR ? createDeletedAssetsBulk : createDeletedEpslsBulk;
			promiseToCall()
				.then((res) => {
					setCreatedEntities(res);
					setReceivingEntityType(isLocationPR ? 'asset' : 'equipment');
					setEquipmentSerialNumberVisible(true);
				})
				.catch((err) => message.error(err))
				.finally(() => setCurrentAction(null));
		} else {
			updatePurchaseRequestStatus(prId, PURCHASE_REQUEST_STATUSES.fulfilled)
				.then((res) => onSuccess && onSuccess(res))
				.catch((err) => message.error(err))
				.finally(() => setCurrentAction(null));
		}
	}, [
		createDeletedAssetsBulk,
		createDeletedEpslsBulk,
		eqipmentLineItems.length,
		isLocationPR,
		onSuccess,
		prId,
		updatePurchaseRequestStatus,
	]);

	const fulfilledButton = useMemo(
		() => (
			<Popover
				content={
					markFulfilledDisabledWithAssetTypeId
						? 'Please associate asset type with every equipment type in this purchase request!'
						: null
				}
				trigger="hover"
			>
				<Button
					key="reject"
					disabled={markFulfilledDisabledWithAssetTypeId}
					style={{ marginLeft: 16 }}
					loading={currentAction === PR_ACTIONS.FULFILL}
					onClick={onFulfilled}
				>
					Mark this fulfilled
				</Button>
			</Popover>
		),
		[currentAction, markFulfilledDisabledWithAssetTypeId, onFulfilled]
	);

	const prActions = useMemo(() => {
		switch (purchaseRequest.status) {
			case PURCHASE_REQUEST_STATUSES.requested:
				return (
					<div style={{ marginTop: 16 }}>
						<Button
							type="primary"
							key="approve"
							loading={currentAction === PR_ACTIONS.APPROVE}
							onClick={onApprove}
						>
							Approve
						</Button>
						<Button
							key="reject"
							style={{ marginLeft: 16 }}
							onClick={onReject}
							loading={currentAction === PR_ACTIONS.REJECT}
						>
							Reject
						</Button>
					</div>
				);
			case PURCHASE_REQUEST_STATUSES.approved:
				return (
					<div style={{ marginTop: 16 }}>
						<Button
							type="primary"
							key="approve"
							loading={currentAction === PR_ACTIONS.ORDER}
							onClick={onOrdered}
						>
							Mark this ordered
						</Button>
						{fulfilledButton}
					</div>
				);
			case PURCHASE_REQUEST_STATUSES.ordered:
				return <div style={{ marginTop: 16 }}>{fulfilledButton}</div>;
			default:
				return null;
		}
	}, [currentAction, fulfilledButton, onApprove, onOrdered, onReject, purchaseRequest.status]);

	return canShowActions ? (
		<Card>
			<div className="flex flex-col">
				<div className="mb-4">
					<Steps current={currentPRIndex}>
						{HIGHER_ORDER_PR_STATUSES.map((status) => (
							<Steps.Step key={status} title={PURCHASE_REQUEST_STATUS_DISPLAY_NAMES[status]} />
						))}
					</Steps>
				</div>
				<h5>{messageHeader}</h5>
				<div style={{ fontSize: 16, maxWidth: 640 }}>{messageDescriptin}</div>
				<div>{prActions}</div>
			</div>
			{equipmentSerialNumberVisible && (
				<UpdateSerialAmdAssetNumbersModal
					createdEntities={createdEntities}
					entityType={receivingEntityType}
					onSuccess={onUpdateSerialNumberSuccess}
					onCancel={_hideSerialNumberUpdate}
					prLineItems={eqipmentLineItems}
					onReceiptValuesSuccessForPR={onReceiptValuesSuccessForPR}
				/>
			)}
		</Card>
	) : null;
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	updatePurchaseRequestStatus: (id, status, equipmentReceiptValues = undefined) =>
		dispatch(purchaseRequestStatusChangeForSupplier(id, status, equipmentReceiptValues)),
	createEpslsBulkAsDeleted: (entities) =>
		dispatch(createMultipleEpslAsDeletedForSupplier(entities)),
	createAssetsBulkAsDeleted: (entities) =>
		dispatch(createMultipleAssetsAsDeletedForSupplier(entities)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(PurchaseRequestStatusActions)
);

import {
	FETCH_SOURCING_EVENT_GROUP_INBOX_CONVERSATIONS_SUCCESS,
	FETCH_SOURCING_EVENT_GROUP_MESSAGE_THREAD_SUCCESS,
	FETCH_SOURCING_EVENT_GROUP_SENT_CONVERSATIONS_SUCCESS,
	SOURCING_EVENT_GROUP_MESSAGES_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS,
} from '../actions/sourcing_event_group_messages_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = {
	...standardInitialStateGenerator(['associatedSourcingEventGroupMessages']),
	sentConversations: [],
	inboxConversations: [],
	activeThread: [],
};

const extendedReducerFunction = (oldState, action) => {
	switch (action.type) {
		case FETCH_SOURCING_EVENT_GROUP_INBOX_CONVERSATIONS_SUCCESS:
			return {
				...oldState,
				inboxConversations: action.conversations,
				fetching: false,
			};
		case FETCH_SOURCING_EVENT_GROUP_SENT_CONVERSATIONS_SUCCESS:
			return {
				...oldState,
				sentConversations: action.conversations,
				fetching: false,
			};
		case FETCH_SOURCING_EVENT_GROUP_MESSAGE_THREAD_SUCCESS:
			return {
				...oldState,
				activeThread: action.thread,
				fetching: false,
			};
		default:
			return oldState;
	}
};

export default standardReducerGenerator(
	CRUD_ACTION_CONSTANTS,
	initialState,
	'id',
	extendedReducerFunction
);

import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Card, Table } from 'antd';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { KeyValueDisplay } from '../key_value_display/KeyValueDisplay';
import { groupBy } from 'lodash';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { connect } from 'react-redux';
import { ProtectedImageThumbnails } from '../protected_image_thumbnails/ProtectedImageThumbnails';
import DateTimeHover from '../date_time_component/DateTime';

const sanitizeTaskAnswerTitle = (groupedAnswers) =>
	Object.keys(groupedAnswers).reduce((acc, key) => {
		acc[key] = (groupedAnswers[key] || []).map((answer, idx) =>
			answer.titleId ? answer : { ...answer, titleId: `${key}-${answer.title}-${idx}` }
		);
		return acc;
	}, {});

const PrintableWalkthrough: FC<any> = ({
	walkthrough,
	userType,
	onPrintInitiate,
	onPrintStart,
}): React.ReactElement => {
	const componentRef = useRef();

	const walkthroughDetails = useMemo(
		() => ({
			'Walkthrough ID': `# ${nullSafeGet('id', walkthrough)}`,
			//Status: capitalize(walkthrough.status),
			Location: nullSafeGet('location.name', walkthrough),
			Created: <DateTimeHover showDate timestamp={walkthrough.createdAt} />,
			Due: <DateTimeHover showDate timestamp={walkthrough.dueAt} />,
			...(walkthrough.status === 'complete' && walkthrough.completedAt
				? {
						Completed: <DateTimeHover showDate timestamp={walkthrough.completedAt} />,
				  }
				: {}),
			...(walkthrough.completedByContact
				? {
						'Completed By': `${nullSafeGetOrElse(
							'completedByContact.nameGiven',
							walkthrough,
							''
						)} ${nullSafeGetOrElse('completedByContact.nameFamily', walkthrough, '')}`,
				  }
				: {}),
		}),
		[walkthrough]
	);

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		onBeforePrint: onPrintStart,
		documentTitle: `Openwrench - Walkthrough:#${walkthrough.id} `,
	});

	const startPrint = useCallback(() => {
		onPrintInitiate();
		handlePrint();
	}, [handlePrint, onPrintInitiate]);

	const filteredWalkthroughDetails = useMemo(
		() =>
			Object.keys(walkthroughDetails).reduce((acc, el) => {
				const val = walkthroughDetails[el];
				if (val !== null && val !== 'undefined' && val !== undefined && val !== '') {
					acc[el] = walkthroughDetails[el];
				}
				return acc;
			}, {}),
		[walkthroughDetails]
	);

	const groupedFilteredTaskAnswers =
		walkthrough.taskAnswers &&
		sanitizeTaskAnswerTitle(groupBy(walkthrough.taskAnswers, 'groupTitle'));

	const TaskAnswerDetails = ({ record }) => {
		const photoStrings = record.attachments.map((p) => {
			const fileName = nullSafeGetOrElse('fileName', p, '');
			const fileId = nullSafeGetOrElse('fileId', p, '');
			return `${fileId}/${fileName}`;
		});
		return (
			<div className={'taskAnswerDetails'}>
				{record.comments && record.comments.length > 0 ? (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<div className="commentsBlurb">{record.comments}</div>
					</div>
				) : null}
				{photoStrings && photoStrings.length > 0 ? (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<ProtectedImageThumbnails
							style={{ marginTop: 4, cursor: 'pointer' }}
							imageWidth={120}
							imageHeight={120}
							photoStrings={photoStrings}
							role={userType}
						/>
					</div>
				) : null}
			</div>
		);
	};

	const walkthroughColumns = [
		{
			title: 'Question',
			dataIndex: 'task',
			render: (text, record) => (
				<div className="flex flex-col">
					<div>
						<div className="walkthroughRowDisplay__rowTitle text-gray-500">
							<Ellipsis
								style={{ wordBreak: 'normal' }}
								tooltip={true}
								length={64}
								className="text-xl"
							>
								{nullSafeGet('title', record)}
							</Ellipsis>
						</div>
						{nullSafeGet('description', record) && (
							<div className="walkthroughRowDisplay__rowSubtitle text-gray-400">
								{record.description}
							</div>
						)}
						{nullSafeGet('questionType', record) !== 'multipleChoice' && (
							<div className="walkthroughRowDisplay__rowTitle mt-2 text-gray-600">
								<div className="walkthroughRowDisplay__rowInlineGroup">
									<Ellipsis tooltip={true} style={{ fontSize: 14, display: 'inline' }} length={500}>
										{nullSafeGetOrElse('answer', record, '--')}
									</Ellipsis>
								</div>
							</div>
						)}
					</div>
					<TaskAnswerDetails record={record} />
				</div>
			),
		},
		{
			title: 'Answer',
			dataIndex: 'task',
			render: (text, record) => (
				<div className="flex flex-row items-center justify-end">
					{nullSafeGet('questionType', record) === 'multipleChoice' && (
						<div className="walkthroughRowDisplay__rowSubtitle ml-2 text-gray-600">
							<div className="walkthroughRowDisplay__rowInlineGroup text-sm">
								<Ellipsis
									tooltip={true}
									style={{ wordBreak: 'normal', fontSize: 14, display: 'inline' }}
									length={500}
								>
									{nullSafeGetOrElse('answer', record, '--')}
								</Ellipsis>
							</div>
						</div>
					)}
				</div>
			),
		},
	];

	return (
		<div>
			<div onClick={startPrint}>Export PDF</div>

			<div className=" hidden h-0 overflow-hidden" id="printdiv">
				<div id="printable-walkthrough" ref={componentRef}>
					<Card title="Walkthrough Details" className="rowSpacing">
						<div style={{ padding: 0 }} className="materialCard__body">
							<div className="materialCard__supportingText">
								<KeyValueDisplay
									horizontal={true}
									keyWidth={120}
									keyValueStore={filteredWalkthroughDetails}
								/>
							</div>
						</div>
					</Card>
					<div>
						{walkthrough.taskAnswers &&
						walkthrough.taskAnswers.filter((t) => t.answer).length > 0 ? (
							Object.keys(groupedFilteredTaskAnswers).map((gtaKey) => {
								return (
									<div key={gtaKey} className="rowSpacing">
										<Card title={gtaKey !== 'undefined' ? gtaKey : null}>
											<Table
												showHeader={false}
												pagination={false}
												columns={walkthroughColumns}
												rowKey={(el) => el.titleId}
												dataSource={groupedFilteredTaskAnswers[gtaKey]}
											/>
										</Card>
									</div>
								);
							})
						) : (
							<div style={{ maxWidth: 488, textAlign: 'center' }}>
								Walkthrough has not been conducted yet
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(PrintableWalkthrough);

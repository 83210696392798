import { crudActionCreatorsGenerator } from './crud_action_creators_generator';
import { crudActionConstantsGenerator } from './crud_action_constants_generator';

export const PERFORMANCE_MILESTONES_CRUD_ACTION_CONSTANTS =
	crudActionConstantsGenerator('PERFORMANCE_MILESTONES');
export const PERFORMANCE_MILESTONES_CRUD_ACTION_CREATORS = crudActionCreatorsGenerator(
	PERFORMANCE_MILESTONES_CRUD_ACTION_CONSTANTS
);

export const SCHEDULE_MILESTONES_CRUD_ACTION_CONSTANTS =
	crudActionConstantsGenerator('SCHEDULE_MILESTONES');
export const SCHEDULE_MILESTONES_CRUD_ACTION_CREATORS = crudActionCreatorsGenerator(
	SCHEDULE_MILESTONES_CRUD_ACTION_CONSTANTS
);

import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Alert } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { connect } from 'react-redux';

const FormItem = Form.Item;

interface SupplierChangeStatusWithNoteProps {
	onSubmit: any;
	onCancel: any;
	visible: boolean;
	createErrors: any;
	updateErrors: any;
	creating: boolean;
	updating: boolean;
	workOrderId: number;
	currentUser: any;
	formTitle: any;
	formText: any;
}

interface SupplierChangeStatusWithNoteFormProps extends FormComponentProps {
	onSubmit: any;
	onCancel: any;
	visible: boolean;
	createErrors: any;
	updateErrors: any;
	creating: boolean;
	updating: boolean;
	workOrderId: number;
	currentUser: any;
	formTitle: any;
	formText: any;
}

class SupplierChangeStatusWithNoteWorkForm extends React.Component<
	SupplierChangeStatusWithNoteFormProps,
	any
> {
	render() {
		const {
			visible,
			onCancel,
			onSubmit,
			form,
			currentUser,
			workOrderId,
			createErrors,
			updateErrors,
			formTitle,
			formText,
		} = this.props;
		const { getFieldDecorator } = form;
		getFieldDecorator('workOrderId', { initialValue: workOrderId });
		return (
			<Modal
				visible={visible}
				width={600}
				title={formTitle}
				okText={formText}
				onCancel={onCancel}
				onOk={onSubmit}
				closable={false}
			>
				<Form layout="vertical" className="workForm">
					{createErrors.length > 0 ? (
						<FormItem>
							<Alert message={createErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					{updateErrors.length > 0 ? (
						<FormItem>
							<Alert message={updateErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					<FormItem label="Add a note" required={true}>
						{getFieldDecorator('note', {})(<Input.TextArea />)}
					</FormItem>
				</Form>
			</Modal>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	createErrors: state.supplier_contacts.createErrors,
	updateErrors: state.supplier_contacts.updateErrors,
	creating: state.supplier_contacts.creating,
	updating: state.supplier_contacts.updating,
	currentUser: state.session.currentUser,
	formTitle: ownProps.formTitle,
	formText: ownProps.formText,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create<SupplierChangeStatusWithNoteFormProps>()(SupplierChangeStatusWithNoteWorkForm));

import * as React from 'react';

import {
	CloseOutlined,
	CopyOutlined,
	EnvironmentOutlined,
	LinkOutlined,
	PhoneOutlined,
	ShareAltOutlined,
	StarOutlined,
} from '@ant-design/icons';

import '@ant-design/compatible/assets/index.css';

import {
	Avatar,
	Layout,
	Row,
	Col,
	Popover,
	message,
	Input,
	Tooltip,
	Rate,
	Card,
	Divider,
	Tag,
	Button,
	List,
} from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { KeyValueDisplay } from '../key_value_display/KeyValueDisplay';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { dateFormatter, optionalFilter, capitalizeEachWord } from '../../utils/DataFormatterUtils';
import { Link } from 'react-router-dom';
import Mapbox from '../mapbox/Mapbox';
import { SocialMediaBadge } from '../social_media_badge/SocialMediaBadge';
import { AddressDisplay } from '../address_display/AddressDisplay';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { supplierFacilitiesRestCrudThunksPublic } from '../../thunks/supplier_facilities_thunks';
import { supplierFacilityReviewsRestCrudThunksForPublic } from '../../thunks/supplier_facility_reviews_thunks';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { FlexibleImageAvatar } from '../flexible-image-avatar/FlexibleImageAvatar';
import { EmptyState } from '../empty_state/EmptyState';
import { SOCIAL_MEDIA_TYPES } from '../../constants/social_media';
import { enquireScreen } from '../../utils/ResponsiveUtils';
import { EmptorTag } from '../emptor_tag/EmptorTag';
import LeadForm from '../lead_form/LeadForm';
import { genericQuestions, questionsMap } from '../../mock_data/mocks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const ListItem = List.Item;
const ListItemMeta = ListItem.Meta;
const { Content } = Layout;

const style = require('./SuppliersPublicDetailPage.less');

const DAYS_OF_THE_WEEK = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
];

class SuppliersPublicDetailPage extends React.Component<any, any> {
	formRefs: any;
	shareInputRef: any;

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			deviceType: 'desktop',
			leadFormModalVisible: false,
			sharePopoverVisible: false,
		};
		this.shareInputRef = null;
		this.formRefs = {
			leadForm: null,
		};
		this.handleLeadFormCancel = this.handleLeadFormCancel.bind(this);
		this.handleLeadFormSubmit = this.handleLeadFormSubmit.bind(this);
		this.showModal = this.showModal.bind(this);
	}

	componentDidMount() {
		const { fetchSupplierFacility, fetchSupplierFacilityReviews, match } = this.props;
		const supplierFacilityId = match.params.id;
		fetchSupplierFacility(supplierFacilityId).then(() => this.setState({ loading: false }));
		fetchSupplierFacilityReviews(supplierFacilityId);
		// Check screen
		enquireScreen((b) => {
			this.setState({ deviceType: b ? 'mobile' : 'desktop' });
		});
	}

	showModal() {
		this.setState({ leadFormModalVisible: true });
	}

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	handleStartReviewWithRating = (rating) => {
		const { supplierFacility, history } = this.props;
		history.push(`/writeAReview/${supplierFacility.id}?initialRating=${rating}`);
	};

	handleLeadFormSubmit() {
		const { submitLeadForm, match } = this.props;
		const form = this.formRefs['leadForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			let i, address, lat, lng;
			let questionnaireArray = [];
			for (i = 1; i <= values[`questions`] - 1; i++) {
				if (values[`question-type-${i}`] === 'address') {
					address = values[`answer-${i}`].parsedAddress;
					lat = values[`answer-${i}`].latitude;
					lng = values[`answer-${i}`].longitude;
				} else if (values[`answer-${i}`] !== undefined) {
					if (
						values[`question-type-${i}`] === 'multipleChoiceMultiSelect' &&
						Array.isArray(values[`answer-${i}`]) &&
						values[`answer-${i}`].indexOf('other') > 0
					) {
						const idx = values[`answer-${i}`].indexOf('other');
						let arrVal = values[`answer-${i}`];
						arrVal[idx] = values[`other-value-${i}`];
						values[`answer-${i}`] = arrVal;
					}
					if (
						values[`question-type-${i}`] === 'multipleChoice' &&
						values[`answer-${i}`] === 'Choose date(s)'
					) {
						values[`answer-${i}`] = 'between ' + values[`dates-available`].join(' and ');
					}
					questionnaireArray.push({
						questionId: `${i}`,
						questionType: values[`question-type-${i}`],
						questionTitle: values[`question-${i}`],
						answer: Array.isArray(values[`answer-${i}`])
							? values[`answer-${i}`].join(' or ')
							: values[`answer-${i}`],
						imageLinks:
							values[`question-type-${i}`] === 'pictureYesNo' && values[`answer-${i}`] === 'yes'
								? values[`pictureFiles`].map((f) => nullSafeGet('response.data', f))
								: [],
					});
				}
			}

			const lead = {
				email: values[`answer-${values[`questions`]}`],
				spendCategoryId: parseInt(match.params.id, 10),
				questionnaire: questionnaireArray,
				address: address,
				latitude: lat,
				longitude: lng,
			};

			submitLeadForm(lead).then(() => {
				this.setState({ leadFormModalVisible: false });
				message.success(`Thanks for sharing project details. We'll be in touch soon!`);
			});
		});
	}

	handleSharePopoverClose = (e) => {
		this.setState({ sharePopoverVisible: false });
	};

	handleSharePopoverToggle = (e) => {
		this.setState({ sharePopoverVisible: !this.state.sharePopoverVisible });
	};

	handleLeadFormCancel(e) {
		this.setState({ leadFormModalVisible: false });
	}

	copyToClipboard = (e) => {
		this.shareInputRef.input.select();
		document.execCommand('copy');
		e.target.focus();
		message.success('Link copied to clipboard.');
	};

	render() {
		const { supplierFacility, supplierFacilityReviews } = this.props;

		const createRawAddress = (addr) => {
			if (!addr || addr === undefined) {
				return '';
			}
			return `${addr.streetAddress1} ${addr.city}, ${addr.region}, ${addr.postcode}, ${addr.country}`;
		};

		const { loading, deviceType } = this.state;
		const contactInfo = loading
			? {}
			: {
					Address: (
						<div>
							<a
								target="_blank"
								href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
									createRawAddress(supplierFacility.primaryAddress)
								)}`}
								rel="noreferrer"
							>
								Get directions
							</a>
							<AddressDisplay address={supplierFacility.primaryAddress} />
						</div>
					),
					Phone: optionalFilter(supplierFacility.primaryPhone),
					// 'Email': optionalFilter(supplierFacility.primaryContactEmail),
					Website: supplierFacility.website ? (
						<a href={'http://www.' + supplierFacility.website} target="_blank" rel="noreferrer">
							{supplierFacility.website}
						</a>
					) : (
						'--'
					),
			  };
		const contactInfoKeyAliases = {
			Address: <EnvironmentOutlined />,
			// 'Email': <Icon type="mail"/>,
			Phone: <PhoneOutlined />,
			Website: <LinkOutlined />,
		};

		const questions =
			questionsMap[supplierFacility.categoryIds ? supplierFacility.categoryIds[0] : 0] ||
			genericQuestions;
		// will need to be changed once paginating reviews due to increased numbers, but for now this should be ok
		const reviews = getRecordsForTargetCollection(supplierFacilityReviews, 'supplierDetailReviews');
		const numOfReviews = reviews.length;
		const averageRating =
			reviews.length > 0
				? (reviews.reduce((acc, r) => acc + r.rating, 0) / numOfReviews).toFixed(1)
				: '0';
		const roundedRatings = reviews.map((r) => Math.round(r.rating));
		const ratingsByStar = [1, 2, 3, 4, 5].reduce((acc, el) => {
			acc[el] = roundedRatings.filter((r) => r === el).length;
			return acc;
		}, {});

		const operatingHours = supplierFacility.operatingHours || {};

		return (
			<Content
				className="sourcingDetailPage"
				style={{ position: 'relative', padding: '64px 0.5em 0' }}
			>
				<LogOnMountWithStandardEventProperties eventType="visited supplier public detail page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<LeadForm
					wrappedComponentRef={this.saveFormRef('leadForm')}
					visible={this.state.leadFormModalVisible}
					questions={questions}
					onCancel={this.handleLeadFormCancel}
					onSubmit={this.handleLeadFormSubmit}
				/>
				{loading ? (
					<PageLoadingPlaceholder />
				) : (
					<div style={{ maxWidth: 1200, margin: 'auto', paddingTop: 40 }}>
						<div
							style={{
								position: 'absolute',
								left: 0,
								right: 0,
								top: 0,
								height: 210,
								background: '#4B4DAE',
							}}
						/>
						<Row style={{ margin: '0.5em -8px' }} gutter={16}>
							<Col span={24}>
								<Card>
									{deviceType === 'desktop' ? (
										<div className="suppliersDetailPage__header">
											<div style={{ float: 'left', display: 'flex' }}>
												<div style={{ marginRight: 32 }}>
													{supplierFacility.logoURL && supplierFacility.logoURL !== 'NULL' ? (
														<FlexibleImageAvatar
															displayName={supplierFacility.name}
															showPopover={false}
															squareMode={true}
															popoverText={supplierFacility.name}
															width="120px"
															height="80px"
															imageUrl={supplierFacility.logoURL}
														/>
													) : (
														<FlexibleImageAvatar
															displayName={supplierFacility.name}
															showPopover={false}
															squareMode={true}
															popoverText={supplierFacility.name}
															width="120px"
															height="80px"
															imageUrl="https://s3.amazonaws.com/emptor-data-assets/images/empty-state/icons8-city-filled-100.png"
														/>
													)}
												</div>
												<div>
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
															marginBottom: 4,
														}}
													>
														<h1
															style={{
																minWidth: 560,
																fontSize: 32,
																marginRight: 8,
																marginBottom: 0,
															}}
														>
															{supplierFacility.displayName}
														</h1>
													</div>
													<div
														style={{
															// display: 'flex',
															alignItems: 'center',
															marginBottom: 4,
															display: 'none',
														}}
													>
														<Rate
															style={{ minWidth: 140 }}
															disabled={true}
															allowHalf={true}
															value={Math.round(parseFloat(averageRating) * 2) / 2}
														/>
														<span style={{ fontSize: 16, marginLeft: 8 }}>
															{numOfReviews} customer reviews
														</span>
													</div>
													<div style={{ marginBottom: 8, fontSize: 16, display: 'flex' }}>
														{supplierFacility.categoryNames
															.split(',')
															.slice(0, 3)
															.map((categoryName, idx) => (
																<span
																	style={{
																		display: 'inline-block',
																		lineHeight: '32px',
																		marginRight: 8,
																		fontFamily: 'Roboto',
																		fontSize: 16,
																		color: 'rgba(0, 0, 0, 0.65)',
																	}}
																>
																	{capitalizeEachWord(categoryName)}
																	{idx ===
																		supplierFacility.categoryNames.split(',').slice(0, 3).length -
																			1 && supplierFacility.categoryNames.split(',').length <= 3
																		? null
																		: ','}
																</span>
															))}
														{supplierFacility.categoryNames.split(',').length > 3 ? (
															<span
																style={{
																	display: 'inline-block',
																	lineHeight: '32px',
																	marginRight: 8,
																	fontFamily: 'Roboto',
																	fontSize: 16,
																	color: 'rgba(0, 0, 0, 0.65)',
																}}
															>
																more
															</span>
														) : null}
													</div>
													<div style={{ display: 'flex', alignItems: 'center' }}>
														<Link
															style={{ display: 'none' }}
															to={`/writeAReview/${supplierFacility.id}`}
														>
															<Button
																style={{
																	height: 40,
																	marginRight: 8,
																	position: 'relative',
																}}
																type="primary"
															>
																<StarOutlined /> Write a Review
															</Button>
														</Link>
														<Popover
															content={
																<div>
																	<h3>Share link to {supplierFacility.name}'s profile</h3>
																	<Input
																		style={{ width: 440 }}
																		ref={(input) => (this.shareInputRef = input)}
																		addonAfter={
																			<Tooltip title="Copy to Clipboard">
																				<CopyOutlined onClick={this.copyToClipboard} />
																			</Tooltip>
																		}
																		defaultValue={`https://www.useopenwrench.com/public/suppliers/detail/${supplierFacility.id}`}
																	/>
																	<div
																		style={{
																			marginLeft: 16,
																			display: 'inline-block',
																			lineHeight: '32px',
																			height: 32,
																		}}
																		onClick={this.handleSharePopoverClose}
																	>
																		<CloseOutlined />
																	</div>
																</div>
															}
															trigger="click"
															visible={this.state.sharePopoverVisible}
														>
															<Button
																onClick={this.handleSharePopoverToggle}
																style={{ marginRight: 8, position: 'relative' }}
															>
																<ShareAltOutlined /> Share
															</Button>
														</Popover>
													</div>
												</div>
											</div>
											<div
												style={{
													float: 'right',
													width: '60%',
													display: 'flex',
													justifyContent: 'flex-end',
													maxWidth: 400,
												}}
											>
												{SOCIAL_MEDIA_TYPES.every(
													(sm) =>
														supplierFacility.socialMedia === undefined ||
														supplierFacility.socialMedia[sm.key] === undefined
												)
													? null
													: SOCIAL_MEDIA_TYPES.map((sm) =>
															supplierFacility.socialMedia[sm.key] !== undefined ? (
																<div style={{ marginLeft: 8 }}>
																	<SocialMediaBadge
																		key={sm.type}
																		type={sm.type}
																		handle={supplierFacility.socialMedia[sm.key]}
																	/>
																</div>
															) : null
													  )}
											</div>

											<div style={{ height: 30, position: 'relative', textAlign: 'center' }}></div>
										</div>
									) : (
										<div style={{ textAlign: 'center' }}>
											<div
												style={{
													display: 'flex',
													justifyContent: 'center',
													marginBottom: 16,
												}}
											>
												<FlexibleImageAvatar
													displayName={supplierFacility.displayName}
													showPopover={false}
													popoverText={supplierFacility.displayName}
													width="160px"
													height="160px"
													imageUrl={supplierFacility.logoURL}
												/>
											</div>
											<h1 style={{ fontSize: 32, marginBottom: 8 }}>
												{supplierFacility.displayName}
											</h1>
											<Rate
												style={{ minWidth: 140, marginBottom: 8 }}
												disabled={true}
												allowHalf={true}
												value={Math.round(parseFloat(averageRating) * 2) / 2}
											/>
											<div style={{ fontSize: 16, marginBottom: 16 }}>
												{numOfReviews} customer reviews
											</div>
											<div style={{ marginBottom: 16 }}>
												{supplierFacility.categoryNames.split(',').map((categoryName) => (
													<Tag key={categoryName}>{capitalizeEachWord(categoryName)}</Tag>
												))}
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'center',
													maxWidth: 400,
													marginBottom: 24,
												}}
											>
												{SOCIAL_MEDIA_TYPES.every(
													(sm) =>
														supplierFacility.socialMedia === undefined ||
														supplierFacility.socialMedia[sm.key] === undefined
												)
													? null
													: SOCIAL_MEDIA_TYPES.map((sm) =>
															supplierFacility.socialMedia[sm.key] !== undefined ? (
																<div style={{ marginLeft: 8 }}>
																	<SocialMediaBadge
																		key={sm.type}
																		type={sm.type}
																		handle={supplierFacility.socialMedia[sm.key]}
																	/>
																</div>
															) : null
													  )}
											</div>

											<div
												style={{
													height: 30,
													marginBottom: 16,
													position: 'relative',
												}}
											>
												<Link to={`/supplier/signup`}>
													<Button style={{ height: 40, position: 'relative' }} type="primary">
														Claim your profile now
													</Button>
												</Link>
											</div>
										</div>
									)}
								</Card>
							</Col>
						</Row>

						<Row style={{ margin: '-0.5em -8px 0.5em -8px' }} gutter={16}>
							<Col sm={24} md={16}>
								<Row style={{ margin: '0.5em 0' }} gutter={16}>
									<Card bodyStyle={{ padding: 0 }} style={{ position: 'relative', padding: 0 }}>
										<div style={{ height: 240, position: 'relative' }}>
											<Mapbox
												longitude={supplierFacility.longitude}
												latitude={supplierFacility.latitude}
												baseLayerStyle="light"
												zoomLevel={7}
											/>
										</div>
										<div style={{ position: 'absolute', zIndex: 2, top: 8, left: 8 }}>
											<Card bodyStyle={{ padding: 16 }} style={{ padding: 0 }}>
												<KeyValueDisplay
													keyWidth="32px"
													keyAliases={contactInfoKeyAliases}
													keyValueStore={contactInfo}
												/>
											</Card>
										</div>
									</Card>
								</Row>
								<Row style={{ margin: '0.5em -8px' }} gutter={16}>
									<Col span={24}>
										<Card title="About this supplier">
											{supplierFacility.about && supplierFacility.about.length > 0 ? (
												<div
													style={{ fontSize: 16 }}
													dangerouslySetInnerHTML={{ __html: supplierFacility.about }}
												></div>
											) : (
												<EmptyState
													height={120}
													headline={"This supplier hasn't added an about us description yet."}
													body={
														<div style={{ maxWidth: 488, textAlign: 'center' }}>
															{/*Our supplier network quality is constantly improving. We've*/}
															{/*reached out to this supplier and will be adding this*/}
															{/*information soon.*/}
														</div>
													}
												/>
											)}
										</Card>
									</Col>
								</Row>
								{supplierFacility.faqs && supplierFacility.faqs.length > 0 ? (
									<Row style={{ margin: '0.5em -8px' }} gutter={16}>
										<Col span={24}>
											<Card title="FAQ">
												{supplierFacility.faqs.map((faq, idx) => (
													<div key={idx}>
														<h2>{faq.question}</h2>
														<p style={{ fontSize: 16 }}>{faq.answer}</p>
													</div>
												))}
											</Card>
										</Col>
									</Row>
								) : null}
								<Row style={{ margin: '0.5em -8px' }} gutter={16}>
									<Col span={24}>
										<Card title="Services Provided">
											<div style={{ marginBottom: 0, fontSize: 16 }}>
												{supplierFacility.categoryNames.split(',').map((categoryName) => (
													<EmptorTag style={{ marginBottom: 8 }} key={categoryName}>
														{capitalizeEachWord(categoryName)}
													</EmptorTag>
												))}
											</div>
										</Card>
									</Col>
								</Row>
								{reviews.length > 0 ? (
									<Row style={{ margin: '0.5em -8px' }} gutter={16}>
										<Col span={24}>
											<Card title={`Reviews for ${supplierFacility.displayName}`}>
												<div style={{ display: 'none' }}>
													<div style={{ marginRight: 24, display: 'flex' }}>
														<div>
															<Avatar
																src="https://s3.amazonaws.com/emptor-data-assets/images/empty-state/icons8-user-filled-100.png"
																style={{
																	height: 56,
																	width: 56,
																	borderRadius: 28,
																	marginRight: 16,
																}}
															/>
														</div>
														<div style={{ marginLeft: 8 }}>
															<div
																style={{
																	width: 120,
																	marginTop: 4,
																	marginBottom: 8,
																	height: 8,
																	background: 'lightgray',
																}}
															/>
															<div
																style={{
																	width: 100,
																	height: 8,
																	background: 'lightgray',
																}}
															/>
														</div>
													</div>
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															alignItems: 'center',
															background: '#f6f6f6',
															padding: '16px 24px',
															borderRadius: 10,
														}}
													>
														<Rate
															style={{ minWidth: 120, fontSize: 24 }}
															onChange={this.handleStartReviewWithRating}
															allowHalf={true}
														/>
														<Divider style={{ marginTop: 16, marginBottom: 16 }} />
														<Link
															style={{ fontSize: 16 }}
															to={`/writeAReview/${supplierFacility.id}`}
														>
															Start your review of {supplierFacility.displayName}.
														</Link>
													</div>
												</div>
												{reviews.map((review) => [
													<Divider />,
													<div style={{ display: 'flex' }}>
														<div
															style={{
																padding: '16px 0',
																marginRight: 24,
																display: 'flex',
															}}
														>
															<div>
																<Avatar
																	src="https://s3.amazonaws.com/emptor-data-assets/images/empty-state/icons8-user-filled-100.png"
																	style={{
																		height: 56,
																		width: 56,
																		borderRadius: 28,
																		marginRight: 16,
																	}}
																/>
															</div>
															<div style={{ width: 120, marginLeft: 8 }}>
																<div
																	style={{
																		fontWeight: 'bold',
																		fontSize: 16,
																	}}
																>
																	{review.buyerContact.nameGiven}{' '}
																	{review.buyerContact.nameFamily
																		? review.buyerContact.nameFamily[0].toUpperCase() + '.'
																		: null}
																</div>
																<div>
																	{review.buyerContact.title
																		? capitalizeEachWord(review.buyerContact.title)
																		: null}
																</div>
															</div>
														</div>
														<div
															style={{
																display: 'flex',
																flexDirection: 'column',
																padding: '16px 0',
															}}
														>
															<div
																style={{
																	marginBottom: 16,
																	minWidth: 120,
																	display: 'flex',
																	alignItems: 'center',
																}}
															>
																<Rate
																	style={{
																		lineHeight: '24px',
																		marginRight: 8,
																		fontSize: 24,
																	}}
																	disabled={true}
																	allowHalf={true}
																	key={review.rating}
																	defaultValue={review.rating}
																/>
																<span style={{ color: 'rgba(0,0,0,0.45)' }}>
																	{dateFormatter(review.createdAt, 'M/D/YYYY')}
																</span>
															</div>
															<div style={{ fontSize: 16 }}>{review.review}</div>
														</div>
													</div>,
												])}
											</Card>
										</Col>
									</Row>
								) : null}
								<Row style={{ margin: '0.5em -8px' }} gutter={16}>
									<Col span={24}>
										<Card title="Business hours">
											<div style={{ fontSize: 16 }}>
												<div style={{ color: 'rgba(0,0,0,0.45)', marginBottom: 16 }}>
													{operatingHours.timeZoneName}
												</div>
												<table>
													{DAYS_OF_THE_WEEK.map((dayOfTheWeek, idx) => {
														const fromTime = nullSafeGet(`${dayOfTheWeek}.from`, operatingHours);
														const toTime = nullSafeGet(`${dayOfTheWeek}.to`, operatingHours);
														const hasHours = fromTime && toTime;

														return (
															<tr key={idx} style={{ lineHeight: '32px' }}>
																<td>{dayOfTheWeek}</td>
																<td style={{ paddingLeft: 32 }}>
																	{hasHours ? `${fromTime} to ${toTime}` : 'No hours available'}
																</td>
															</tr>
														);
													})}
												</table>
											</div>
										</Card>
									</Col>
								</Row>
							</Col>

							<Col sm={24} md={8}>
								<Row style={{ margin: '0.5em -8px' }} gutter={16}>
									<Col span={24}>
										<Row style={{ margin: '0 -8px 0.5em' }} gutter={16}>
											<Col span={24}>
												<Card>
													<h2 style={{ fontSize: 24 }}>
														Ready to start assembling your maintenance all-star team?
													</h2>
													<p style={{ fontSize: 16 }}>
														OpenWrench's Contractor Management system can help you find, prequalify
														and manage your own private, world-class commercial contractor network.
													</p>
													<Link to="/contact-us">
														<Button type="primary">Request More Info</Button>
													</Link>
												</Card>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				)}
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	supplierFacilityReviews: state.supplier_facility_reviews,
	supplierFacilitiesFetching: state.supplier_facilities.fetching,
	supplierFacility: state.supplier_facilities.detail,
	buyerContacts: state.buyer_contacts,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	fetchSupplierFacility: (id) => dispatch(supplierFacilitiesRestCrudThunksPublic.readOne(id, '')),
	fetchSupplierFacilityReviews: (supplierFacilityId) =>
		dispatch(
			supplierFacilityReviewsRestCrudThunksForPublic.read(
				{ supplierFacilityId },
				'',
				'supplierDetailReviews'
			)
		),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(SuppliersPublicDetailPage)
);

import { PARTS_PER_STOCK_LOCATIONS_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/parts_per_stock_locations_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = standardInitialStateGenerator([
	'PARTS_PER_STOCK_LOCATIONS_FOR_PART',
	'partsPerStockLocationsIndex',
	'stockLocationAssociatedPartsPerStockLocations',
	'serviceCallPartsPerStockLocationsDropdown',
	'destinationPartsPerStockLocationIndex',
]);
export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState);

import { logoutSuccess } from '../actions/session_actions';
import { default as FetchUtils, parseJSON } from './fetch_utils';
import { SUPPLIER_FACILITIES_SEARCH_ACTION_CREATORS } from '../actions/supplier_facilities_actions';

const fetchUtils = new FetchUtils();

export class SearchThunksGenerator {
	baseURL: string;
	appId: string;
	apiKey: string;
	searchActionCreators: any;

	constructor(searchActionCreators, appId, apiKey, indexName) {
		this.baseURL = `https://${appId}-dsn.algolia.net/1/indexes/${indexName}`;
		this.appId = appId;
		this.apiKey = apiKey;
		this.searchActionCreators = searchActionCreators;
		this.search = this.search.bind(this);
	}

	searchFacet(facetName, params, pagination = null, sorting = null, filters = null) {
		// if pagination present, transform into limit/offset format and add to query params
		let paramsWithoutGeoloc = {};

		if (pagination) {
			const paginationQuery = {
				page: pagination.current - 1,
				hitsPerPage: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		// not yet supporting sorting for search -- indexes will need to be replicated in algolia
		// if (sorting) {
		//     const sortingQuery = {...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc'};
		//     params = {
		//         ...params,
		//         ...sortingQuery
		//     };
		// }

		function hasWhiteSpace(s) {
			return /\s/g.test(s);
		}

		if (filters) {
			const sanitizedFilters = Object.keys(filters).reduce((acc, k) => {
				const v = filters[k];
				if (
					k !== 'query' &&
					k !== 'facetQuery' &&
					k !== 'maxFacetHits' &&
					k !== 'insideBoundingBox' &&
					k !== '_tags' &&
					v !== undefined
				) {
					acc[k] = v;
				}
				return acc;
			}, {});
			let formattedFilterStrings = Object.keys(sanitizedFilters).reduce((acc, k) => {
				const v = sanitizedFilters[k];
				const formattedV = hasWhiteSpace(v) ? `'${v}'` : v;
				acc.push(`${k}: ${formattedV}`);
				return acc;
			}, []);

			const tagsFilters = filters._tags;
			let tagsFiltersQueryString = '';

			if (tagsFilters && tagsFilters.length > 0) {
				tagsFiltersQueryString =
					'(' +
					tagsFilters.map((tag) => (hasWhiteSpace(tag) ? `'${tag}'` : tag)).join(' OR ') +
					')';
				formattedFilterStrings.push(tagsFiltersQueryString);
			}

			const filterQueryString = formattedFilterStrings.join(' AND ');

			paramsWithoutGeoloc = {
				...params,
				query: filters.query,
				facetQuery: params.facetQuery,
				maxFacetHits: params.maxFacetHits,
				filters:
					filterQueryString.length > 0
						? filterQueryString + ' AND isNationalSupplier:true'
						: 'isNationalSupplier:true',
			};

			params = {
				...params,
				query: filters.query,
				facetQuery: params.facetQuery,
				maxFacetHits: params.maxFacetHits,
				insideBoundingBox: filters.insideBoundingBox,
				filters: filterQueryString,
			};
		}

		if (!paramsWithoutGeoloc['filters']) {
			paramsWithoutGeoloc['filters'] = 'isNationalSupplier:true';
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				const nationalResultsPromise = fetchUtils
					.algoliaRequest(
						this.baseURL + `/facets/${facetName}/query`,
						{
							data: paramsWithoutGeoloc,
							method: 'POST',
						},
						this.appId,
						this.apiKey
					)
					.then(parseJSON);

				const localResultsPromise = fetchUtils
					.algoliaRequest(
						this.baseURL + `/facets/${facetName}/query`,
						{
							data: params,
							method: 'POST',
						},
						this.appId,
						this.apiKey
					)
					.then(parseJSON);

				const allResultsPromise = [nationalResultsPromise, localResultsPromise];

				Promise.all(allResultsPromise)
					.then(([nationalRes, localRes]) => {
						const nd = nationalRes.json;
						const ns = nationalRes.status;
						const ld = localRes.json;
						const ls = localRes.status;
						if (ls === 401 || ns === 401) {
							dispatch(logoutSuccess());
						}
						if (nd.status === 'error' || ld.status === 'error') {
							const msg = ld.message + '; ' + nd.message;
							dispatch(this.searchActionCreators.searchError(msg));
							reject(msg);
						} else {
							let combinedResults = {};
							ld.facetHits.forEach((h) => (combinedResults[h.value] = h));
							nd.facetHits.forEach((h) => {
								if (combinedResults[h.value]) {
									combinedResults[h.value].count = combinedResults[h.value].count + h.count;
								} else {
									combinedResults[h.value] = h;
								}
							});
							let combinedResultsArray = Object.keys(combinedResults)
								.reduce((acc, el) => {
									acc.push(combinedResults[el]);
									return acc;
								}, [])
								.sort((a, b) => (a.count > b.count ? -1 : 1));
							dispatch(
								this.searchActionCreators.receiveFacetSearchResults(facetName, combinedResultsArray)
							);
							resolve(combinedResultsArray);
						}
					})
					.catch((d) => {
						dispatch(this.searchActionCreators.searchError(d.message));
						reject(d.message);
					});
			});
		};
	}

	search(params, pagination = null, sorting = null, filters = null) {
		// if pagination present, transform into limit/offset format and add to query params
		let paramsWithoutGeoloc = {};

		if (pagination) {
			const paginationQuery = {
				page: pagination.current - 1,
				hitsPerPage: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		// not yet supporting sorting for search -- indexes will need to be replicated in algolia
		// if (sorting) {
		//     const sortingQuery = {...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc'};
		//     params = {
		//         ...params,
		//         ...sortingQuery
		//     };
		// }

		function hasWhiteSpace(s) {
			return /\s/g.test(s);
		}

		if (filters) {
			const sanitizedFilters = Object.keys(filters).reduce((acc, k) => {
				const v = filters[k];
				if (k !== 'query' && k !== 'insideBoundingBox' && k !== '_tags' && v !== undefined) {
					acc[k] = v;
				}
				return acc;
			}, {});
			let formattedFilterStrings = Object.keys(sanitizedFilters).reduce((acc, k) => {
				const v = sanitizedFilters[k];
				const formattedV = hasWhiteSpace(v) ? `'${v}'` : v;
				acc.push(`${k}: ${formattedV}`);
				return acc;
			}, []);

			const tagsFilters = filters._tags;
			let tagsFiltersQueryString = '';

			if (tagsFilters && tagsFilters.length > 0) {
				tagsFiltersQueryString =
					'(' +
					tagsFilters.map((tag) => (hasWhiteSpace(tag) ? `'${tag}'` : tag)).join(' OR ') +
					')';
				formattedFilterStrings.push(tagsFiltersQueryString);
			}

			const filterQueryString = formattedFilterStrings.join(' AND ');

			paramsWithoutGeoloc = {
				...params,
				query: filters.query,
				filters:
					filterQueryString.length > 0
						? filterQueryString + ' AND isNationalSupplier:true'
						: 'isNationalSupplier:true',
			};

			params = {
				...params,
				query: filters.query,
				insideBoundingBox: filters.insideBoundingBox,
				filters: filterQueryString,
			};
		}

		if (!paramsWithoutGeoloc['filters']) {
			paramsWithoutGeoloc['filters'] = 'isNationalSupplier:true';
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(this.searchActionCreators.searchStart());

				fetchUtils
					.algoliaRequest(
						this.baseURL + '/query',
						{
							data: paramsWithoutGeoloc,
							method: 'POST',
						},
						this.appId,
						this.apiKey
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						dispatch(this.searchActionCreators.receiveNationalSearchResults(d.hits));
					});

				fetchUtils
					.algoliaRequest(
						this.baseURL + '/query',
						{
							data: params,
							method: 'POST',
						},
						this.appId,
						this.apiKey
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(this.searchActionCreators.searchError(d.message));
							reject(d.message);
						} else {
							dispatch(
								this.searchActionCreators.searchSuccess(
									d.hits,
									d.nbHits > 1000 ? 1000 : d.nbHits,
									{
										...pagination,
										total: d.nbHits > 1000 ? 1000 : d.nbHits,
									},
									sorting,
									filters
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(this.searchActionCreators.searchError(d.message));
						reject(d.message);
					});
			});
		};
	}
}

// generate thunks using Algolia API search key, app ID, and index name
export const supplierFacilitiesSearchThunks = new SearchThunksGenerator(
	SUPPLIER_FACILITIES_SEARCH_ACTION_CREATORS,
	'WOAMK4LRL4',
	'cd7d454b5810708184e875e9b34df895',
	'Suppliers'
);

import React, { FC, useMemo } from 'react';
import { Layout } from 'antd';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PPSLOrAssetHistoryComponent from './PPSLOrAssetHistoryComponent';

const { Content } = Layout;

const AssetHistoryDetailPage: FC<any> = ({ match }): React.ReactElement => {
	const assetId = useMemo(() => match.params.id, [match.params.id]);

	return (
		<Content style={{ padding: '0 0.5em' }}>
			<LogOnMountWithStandardEventProperties eventType={`Visited asset history detail page`} />

			<PPSLOrAssetHistoryComponent filter={{ assetId }} />
		</Content>
	);
};

export default withRouter<any, any>(connect()(AssetHistoryDetailPage));

import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { ATTENTION_DASHBOARD_CRUD_ACTION_CREATORS } from '../actions/attention_dashboard_actions';

export const attentionDashboardsRestCrudThunksForBuyer = new RestCrudThunks(
	'attention_dashboards',
	'attention_dashboard',
	'attentinoDashboard',
	'attentionDashboards',
	ATTENTION_DASHBOARD_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'dashboard/'
);

export const attentionDashboardsRestCrudThunksForSupplier = new RestCrudThunks(
	'attention_dashboards',
	'attention_dashboard',
	'attentinoDashboard',
	'attentionDashboards',
	ATTENTION_DASHBOARD_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'dashboard/'
);

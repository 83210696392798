import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PlannedMaintenanceScheduleForm from '../planned_maintenance_schedule_form/PlannedMaintenanceScheduleForm';
import {
	getGroupedMaintenanceScheduleForBuyer,
	getGroupedMaintenanceScheduleForSupplier,
} from '../../thunks/planned_maintenance_schedules_thunks';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { ROLE_TYPES } from '../../utils/DataConstants';

require('./EditPlannedMaintenanceSchedulePage.less');

class EditPlannedMaintenanceSchedulePage extends React.Component<any, any> {
	state = {
		initialLoading: true,
		pmSchedule: {},
	};

	componentDidMount() {
		const { getPlannedMaintenanceSchedule, match } = this.props;
		const title = match.params.title;
		const frequency = match.params.frequency;
		const plannedMaintenanceTemplateId = match.params.plannedMaintenanceTemplateId;
		const basedOn = match.params.basedOn;
		getPlannedMaintenanceSchedule(title, frequency, plannedMaintenanceTemplateId, basedOn).then(
			(pmGroup) => {
				this.setState({ pmSchedule: pmGroup, initialLoading: false });
			}
		);
	}

	render() {
		const { history, userType } = this.props;

		const { initialLoading, pmSchedule } = this.state;

		//noinspection TypeScriptUnresolvedVariable
		return [
			<LogOnMountWithStandardEventProperties
				eventType={`visited ${userType} edit pm schedule page`}
			/>,
			<EditPage
				title="Edit Planned Maintenance Schedule"
				formComponentProps={{
					formData: pmSchedule,
					onSuccess: (rec, basedOn) =>
						history.push(
							`/${userType}/plannedMaintenance/detail/${encodeURIComponent(rec.title)}/${
								rec.frequency
							}/${rec.plannedMaintenanceTemplateId}/${basedOn}`
						),
				}}
				fetching={initialLoading}
				FormComponent={PlannedMaintenanceScheduleForm}
			/>,
		];
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		history: ownProps.history,
		match: ownProps.match,
		plannedMaintenanceSchedules: state.planned_maintenance_schedules.detail,
		fetching: state.planned_maintenance_schedules.fetching,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	getPlannedMaintenanceSchedule: (title, frequency, plannedMaintenanceScheduleId, basedOn) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? getGroupedMaintenanceScheduleForSupplier(
						title,
						frequency,
						plannedMaintenanceScheduleId,
						basedOn
				  )
				: getGroupedMaintenanceScheduleForBuyer(
						title,
						frequency,
						plannedMaintenanceScheduleId,
						basedOn
				  )
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(EditPlannedMaintenanceSchedulePage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

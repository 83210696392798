import React, { FC, useMemo } from 'react';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import EachTroubleshootingGroupDisplay from './EachTroubleshootingGroupDisplay';

const TroubleshootingDetailDisplay: FC<any> = ({ workOrder }): React.ReactElement => {
	const troubleshootingAnswers = useMemo(
		() => nullSafeGetOrElse('troubleshootingAnswers', workOrder, []),
		[workOrder]
	);

	const allTroubleshootingAnswers = useMemo(
		() =>
			troubleshootingAnswers.reduce((acc, tsGroupAnswer) => {
				return [...acc, ...nullSafeGetOrElse('troubleshootingAnswers', tsGroupAnswer, [])];
			}, []),
		[troubleshootingAnswers]
	);

	return allTroubleshootingAnswers.length > 0 ? (
		<EachTroubleshootingGroupDisplay key={`ts-ans-group`} answers={allTroubleshootingAnswers} />
	) : null;
};

export default TroubleshootingDetailDisplay;

import { message } from 'antd';
import FetchUtils from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { capitalize } from '../utils/DataFormatterUtils';
import { parseJSON } from './fetch_utils';
import { logoutSuccess } from '../actions/session_actions';
import * as React from 'react';
import { TextButton } from '../components/text_button/TextButton';
import { createTakeLatest } from '../utils/TakeLatestUtils';

const fetchUtils = new FetchUtils();

export default class RestCrudThunks {
	allTakeLatest: any[];
	baseUrl: string;
	apiUrl: string;
	crudActionCreators: {
		fetchStart: any;
		fetchSuccess: any;
		fetchOneSuccess: any;
		fetchOneDeltaSuccess: any;
		fetchError: any;
		createStart: any;
		createSuccess: any;
		createBulkSuccess: any;
		updateBulkSuccess: any;
		createError: any;
		countByStart: any;
		countBySuccess: any;
		countByError: any;
		updateStart: any;
		updateSuccess: any;
		updateError: any;
		archiveStart: any;
		archiveSuccess: any;
		archiveError: any;
		unarchiveStart: any;
		unarchiveSuccess: any;
		unarchiveError: any;
		deleteStart: any;
		deleteSuccess: any;
		deleteError: any;
		saveFormData: any;
		updateSelections: any;
	};
	roleType: string;
	singularCamelCaseEntityName: string;
	pluralCamelCaseEntityName: string;
	singularSnakeCaseEntityName: string;
	pluralSnakeCaseEntityName: string;
	singularHumanReadableEntityName: string;
	showMessages: boolean;

	constructor(
		pluralSnakeCaseEntityName: string,
		singularSnakeCaseEntityName: string,
		singularCamelCaseEntityName: string,
		pluralCamelCaseEntityName: string,
		crudActionCreators: any,
		baseUrl: string = null,
		roleType: string = '',
		showMessages: boolean = false,
		routePrefix: string = ''
	) {
		this.baseUrl = baseUrl ? baseUrl : getBackendUri();
		this.apiUrl =
			roleType.length > 0
				? `/api/v1/${roleType}/${routePrefix}${pluralSnakeCaseEntityName}`
				: `/api/v1/${routePrefix}${pluralSnakeCaseEntityName}`;
		this.crudActionCreators = crudActionCreators;
		this.singularSnakeCaseEntityName = singularSnakeCaseEntityName;
		this.pluralSnakeCaseEntityName = pluralSnakeCaseEntityName;
		this.singularCamelCaseEntityName = singularCamelCaseEntityName;
		this.pluralCamelCaseEntityName = pluralCamelCaseEntityName;
		this.singularHumanReadableEntityName = this.singularSnakeCaseEntityName.split('_').join(' ');
		this.roleType = roleType;
		this.showMessages = showMessages;
		this.create = this.create.bind(this);
		this.countBy = this.countBy.bind(this);
		this.read = this.read.bind(this);
		this.readHydrated = this.readHydrated.bind(this);
		this.readDelta = this.readDelta.bind(this);
		this.readLite = this.readLite.bind(this);
		this.update = this.update.bind(this);
		this.delete = this.delete.bind(this);
		this.archive = this.archive.bind(this);
		this.unarchive = this.unarchive.bind(this);
		this.allTakeLatest = [];
	}

	getTakeLatest = (targetCollectionName) => {
		targetCollectionName = !!targetCollectionName ? targetCollectionName : '__default__take_latest';
		if (!this.allTakeLatest[targetCollectionName]) {
			this.allTakeLatest[targetCollectionName] = createTakeLatest();
		}

		return this.allTakeLatest[targetCollectionName];
	};

	create(entity, primaryKey = 'id', targetCollectionName = null) {
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(this.crudActionCreators.createStart(entity));

				fetchUtils
					.post(this.baseUrl + this.apiUrl, {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entity,
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(this.crudActionCreators.createError(d.message, entity));
							reject(d.message);
							if (this.showMessages) {
								message.error(`Failed to create ${this.singularHumanReadableEntityName}.`);
							}
						} else {
							dispatch(this.crudActionCreators.createSuccess(d.data, targetCollectionName));
							dispatch(this.crudActionCreators.saveFormData({ [primaryKey]: d.data[primaryKey] }));
							resolve(d.data);
							if (this.showMessages) {
								message.success(`${capitalize(this.singularHumanReadableEntityName)} created.`);
							}
						}
					})
					.catch((d) => {
						dispatch(this.crudActionCreators.createError(d.message, entity));
						reject(d.message);
						if (this.showMessages) {
							message.error(`Failed to create ${this.singularHumanReadableEntityName}.`);
						}
					});
			});
		};
	}

	createBulk(entities, primaryKey = 'id') {
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(this.crudActionCreators.createStart(entities));
				fetchUtils
					.post(this.baseUrl + this.apiUrl + '/bulk', {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entities,
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(this.crudActionCreators.createError(d.message, entities));
							reject(d.message);
							if (this.showMessages) {
								message.error(`Failed to create ${this.singularHumanReadableEntityName}.`);
							}
						} else {
							dispatch(this.crudActionCreators.createBulkSuccess(d.data));
							dispatch(this.crudActionCreators.createSuccess(d.data));
							dispatch(this.crudActionCreators.saveFormData({ [primaryKey]: d.data[primaryKey] }));
							resolve(d.data);
							if (this.showMessages) {
								message.success(`${capitalize(this.singularHumanReadableEntityName)} created.`);
							}
						}
					})
					.catch((d) => {
						dispatch(this.crudActionCreators.createError(d.message, entities));
						reject(d.message);
						if (this.showMessages) {
							message.error(`Failed to create ${this.singularHumanReadableEntityName}.`);
						}
					});
			});
		};
	}

	countBy(params, countName) {
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(this.crudActionCreators.countByStart(countName));

				fetchUtils
					.get(
						this.baseUrl + this.apiUrl + '/count_by',
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						const d = json;
						if (d.status === 'error') {
							dispatch(this.crudActionCreators.countByError(d.message, countName));
							reject(d.message);
						} else {
							dispatch(this.crudActionCreators.countBySuccess(d.data, countName));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(this.crudActionCreators.countByError(d.message, countName));
						reject(d.message);
					});
			});
		};
	}

	read(
		params,
		targetCollectionName = null,
		pagination = null,
		sorting = null,
		filters = null,
		addToTargetCollection = false
	) {
		// if pagination present, transform into limit/offset format and add to query params
		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}
		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(this.crudActionCreators.fetchStart(targetCollectionName));

				const request = fetchUtils.get(
					this.baseUrl + this.apiUrl,
					Object.assign(
						{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
						params
					)
				);

				this.getTakeLatest(targetCollectionName)(request)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(this.crudActionCreators.fetchError(d.message));
							reject(d.message);
						} else {
							dispatch(
								this.crudActionCreators.fetchSuccess(
									d.data,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters,
									addToTargetCollection
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						if (d.isCancelled) {
							console.log(`Cancelled Promise....${targetCollectionName}`);
						} else {
							dispatch(this.crudActionCreators.fetchError(d.message, d.data, targetCollectionName));
							reject(d.message);
						}
					});
			});
		};
	}

	readLite(
		params,
		targetCollectionName = null,
		pagination = null,
		sorting = null,
		filters = null,
		addToTargetCollection = false,
		skipUpdatingReduxStore = false
	) {
		// if pagination present, transform into limit/offset format and add to query params
		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}
		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (storeDispatch) => {
			const dispatch = skipUpdatingReduxStore ? () => {} : storeDispatch;
			return new Promise((resolve, reject) => {
				dispatch(this.crudActionCreators.fetchStart(targetCollectionName));

				const request = fetchUtils.get(
					this.baseUrl + this.apiUrl + '/lite',
					Object.assign(
						{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
						params
					)
				);

				this.getTakeLatest(targetCollectionName)(request)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(this.crudActionCreators.fetchError(d.message));
							reject(d.message);
						} else {
							dispatch(
								this.crudActionCreators.fetchSuccess(
									d.data,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters,
									addToTargetCollection
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						if (d.isCancelled) {
							console.log(`Cancelled Promise....${targetCollectionName}`);
						} else {
							dispatch(this.crudActionCreators.fetchError(d.message, d.data, targetCollectionName));
							reject(d.message);
						}
					});
			});
		};
	}

	readHydrated(
		params,
		targetCollectionName = null,
		pagination = null,
		sorting = null,
		filters = null
	) {
		// if pagination present, transform into limit/offset format and add to query params
		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(this.crudActionCreators.fetchStart(targetCollectionName));

				fetchUtils
					.get(
						this.baseUrl + this.apiUrl + '/hydrated',
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(this.crudActionCreators.fetchError(d.message));
							reject(d.message);
						} else {
							dispatch(
								this.crudActionCreators.fetchSuccess(
									d.data,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(this.crudActionCreators.fetchError(d.message, d.data, targetCollectionName));
						reject(d.message);
					});
			});
		};
	}
	readDelta(id, params = {}, targetCollectionName = null) {
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(this.crudActionCreators.fetchStart());

				fetchUtils
					.get(
						this.baseUrl + this.apiUrl + `/${id}/delta`,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(this.crudActionCreators.fetchError(d.message, id));
							reject(d.message);
						} else {
							dispatch(this.crudActionCreators.fetchOneDeltaSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(this.crudActionCreators.fetchError(d.message, id, targetCollectionName));
						reject(d.message);
					});
			});
		};
	}

	readMultiple(
		ids: string[] | number[],
		targetCollectionName: string = null,
		params: any = {},
		sorting: any = null,
		filters: any = null,
		addToTargetCollection = false
	) {
		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}
		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(this.crudActionCreators.fetchStart(targetCollectionName));

				if (ids && ids.length > 0) {
					fetchUtils
						.get(
							this.baseUrl + this.apiUrl + `/multiple/${ids.join(',')}`,
							Object.assign(
								{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
								params
							)
						)
						.then(parseJSON)
						.then(({ json, status, ok }) => {
							const d = json;
							if (status === 401) {
								dispatch(logoutSuccess());
							}
							if (d.status === 'error') {
								dispatch(this.crudActionCreators.fetchError(d.message));
								reject(d.message);
							} else {
								dispatch(
									this.crudActionCreators.fetchSuccess(
										d.data,
										d.count,
										targetCollectionName,
										{
											total: d.count,
										},
										sorting,
										filters,
										addToTargetCollection
									)
								);
								resolve(d.data);
							}
						})
						.catch((d) => {
							dispatch(this.crudActionCreators.fetchError(d.message, d.data, targetCollectionName));
							reject(d.message);
						});
				} else {
					dispatch(
						this.crudActionCreators.fetchSuccess(
							[],
							0,
							targetCollectionName,
							{
								total: 0,
							},
							sorting,
							filters
						)
					);
					resolve([]);
				}
			});
		};
	}

	readOne(id, params = {}, targetCollectionName = null) {
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(this.crudActionCreators.fetchStart());

				fetchUtils
					.get(
						this.baseUrl + this.apiUrl + `/${id}`,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(this.crudActionCreators.fetchError(d.message, id));
							reject(d.message);
						} else {
							dispatch(this.crudActionCreators.fetchOneSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(this.crudActionCreators.fetchError(d.message, id, targetCollectionName));
						reject(d.message);
					});
			});
		};
	}

	update(entity) {
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(this.crudActionCreators.updateStart(entity));

				fetchUtils
					.put(this.baseUrl + this.apiUrl, {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entity,
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(this.crudActionCreators.updateError(d.message, entity));
							reject(d.message);
							if (this.showMessages) {
								message.error(`Failed to update ${this.singularHumanReadableEntityName}.`);
							}
						} else {
							dispatch(this.crudActionCreators.updateSuccess(d.data));
							resolve(d.data);
							if (this.showMessages) {
								message.success(`${capitalize(this.singularHumanReadableEntityName)} updated.`);
							}
						}
					})
					.catch((d) => {
						dispatch(this.crudActionCreators.updateError(d.message, entity));
						reject(d.message);
						if (this.showMessages) {
							message.error(`Failed to update ${this.singularHumanReadableEntityName}.`);
						}
					});
			});
		};
	}

	delete(entity, primaryKeyName = 'id', secondPrimaryKeyName = null, showUndo = false) {
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(this.crudActionCreators.deleteStart(entity));
				const deleteUri = secondPrimaryKeyName
					? this.baseUrl +
					  this.apiUrl +
					  '/' +
					  entity[primaryKeyName] +
					  '/' +
					  entity[secondPrimaryKeyName]
					: this.baseUrl + this.apiUrl + '/' + entity[primaryKeyName];
				const deleteRecord = () =>
					fetchUtils
						.delete(deleteUri, {
							headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						})
						.then(parseJSON)
						.then(({ json, status, ok }) => {
							const d = json;
							if (status === 401) {
								dispatch(logoutSuccess());
							}
							if (d.status === 'error') {
								dispatch(this.crudActionCreators.deleteError(d.message, entity));
								reject(d.message);
							} else {
								dispatch(this.crudActionCreators.deleteSuccess(d.data));
								resolve(d.data);
							}
						})
						.catch((d) => {
							dispatch(this.crudActionCreators.deleteError(d.message, entity));
							reject(d.message);
							if (this.showMessages) {
								message.error(`Failed to delete ${this.singularHumanReadableEntityName}.`);
							}
						});
				const deleteTimout = setTimeout(deleteRecord, 4000);
				const cancelDelete = () => clearTimeout(deleteTimout);
				if (showUndo) {
					const hideUndo = message.success(
						<span>
							{capitalize(this.singularHumanReadableEntityName)} deleted.{' '}
							<TextButton
								onClick={() => {
									cancelDelete();
									hideUndo();
								}}
							>
								Undo
							</TextButton>
						</span>
					);
				}
			});
		};
	}

	archive(entity, primaryKeyName = 'id', showUndo = false) {
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(this.crudActionCreators.archiveStart(entity));

				const archiveRecord = () =>
					fetchUtils
						.post(this.baseUrl + this.apiUrl + '/archive', {
							headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
							data: entity,
						})
						.then(parseJSON)
						.then(({ json, status, ok }) => {
							const d = json;
							if (status === 401) {
								dispatch(logoutSuccess());
							}
							if (d.status === 'error') {
								dispatch(this.crudActionCreators.archiveError(d.message, entity));
								reject(d.message);
								if (this.showMessages) {
									message.error(`Failed to archive ${this.singularHumanReadableEntityName}.`);
								}
							} else {
								dispatch(this.crudActionCreators.archiveSuccess(d.data));
								resolve(d.data);
								if (this.showMessages) {
									message.success(`${capitalize(this.singularHumanReadableEntityName)} archived.`);
								}
							}
						})
						.catch((d) => {
							dispatch(this.crudActionCreators.archiveError(d.message, entity));
							reject(d.message);
							if (this.showMessages) {
								message.error(`Failed to archive ${this.singularHumanReadableEntityName}.`);
							}
						});
				const archiveTimeout = setTimeout(archiveRecord, 4000);
				const cancelArchive = () => clearTimeout(archiveTimeout);
				if (showUndo) {
					const hideUndo = message.success(
						<span>
							{capitalize(this.singularHumanReadableEntityName)} archived.{' '}
							<TextButton
								onClick={() => {
									cancelArchive();
									hideUndo();
								}}
							>
								Undo
							</TextButton>
						</span>
					);
				}
			});
		};
	}

	unarchive(entity, primaryKeyName = 'id', showUndo = false) {
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(this.crudActionCreators.unarchiveStart(entity));

				const unarchiveRecord = () =>
					fetchUtils
						.post(this.baseUrl + this.apiUrl + '/unarchive', {
							headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
							data: entity,
						})
						.then(parseJSON)
						.then(({ json, status, ok }) => {
							const d = json;
							if (status === 401) {
								dispatch(logoutSuccess());
							}
							if (d.status === 'error') {
								dispatch(this.crudActionCreators.unarchiveError(d.message, entity));
								reject(d.message);
								if (this.showMessages) {
									message.error(`Failed to unarchive ${this.singularHumanReadableEntityName}.`);
								}
							} else {
								dispatch(this.crudActionCreators.unarchiveSuccess(d.data));
								resolve(d.data);
								if (this.showMessages) {
									message.success(
										`${capitalize(this.singularHumanReadableEntityName)} unarchived.`
									);
								}
							}
						})
						.catch((d) => {
							dispatch(this.crudActionCreators.unarchiveError(d.message, entity));
							reject(d.message);
							if (this.showMessages) {
								message.error(`Failed to unarchive ${this.singularHumanReadableEntityName}.`);
							}
						});
				const unarchiveTimeout = setTimeout(unarchiveRecord, 4000);
				const cancelUnarchive = () => clearTimeout(unarchiveTimeout);
				if (showUndo) {
					const hideUndo = message.success(
						<span>
							{capitalize(this.singularHumanReadableEntityName)} unarchived.{' '}
							<TextButton
								onClick={() => {
									cancelUnarchive();
									hideUndo();
								}}
							>
								Undo
							</TextButton>
						</span>
					);
				}
			});
		};
	}
}

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Popover, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import { Link } from 'react-router-dom';

import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import { getBackLinkStates } from '../../utils/HistoryUtils';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';

require('./StockLocationsDetailPage.less');
const DEFAULT_BACK_LINK = '/supplier/stockLocations';
const DEFAULT_BACK_LINK_TEXT = 'Back to stock locations';

const StockLocationsDetailPage: FC<any> = ({
	location,
	match,
	getStockLocation,
	history,
	stockLocation,
	deleteStockLocation,
	stockLocations,
	companyConfig,
	childRoutes,
	stockLocationsFetching,
}): React.ReactElement => {
	const [deleting, setDeleting] = useState(false);
	const [backLinkStates, setBackLinkStates] = useState<any>({
		backLinkText: DEFAULT_BACK_LINK_TEXT,
		backLinkTo: DEFAULT_BACK_LINK,
	});

	const stockLocationId = match.params.id;

	useEffect(() => {
		const { backLinkText, backLinkTo } = getBackLinkStates(location, DEFAULT_BACK_LINK) as any;
		setBackLinkStates((exisiting) => ({
			...exisiting,
			...(backLinkText && { backLinkText }),
			...(backLinkTo && { backLinkTo }),
		}));
		getStockLocation(stockLocationId);
	}, [getStockLocation, location, stockLocationId]);

	const handleTabChange = useCallback(
		(key) => history.push(`/supplier/stockLocations/detail/${stockLocationId}/${key}`),
		[history, stockLocationId]
	);

	const checkTabActive = useCallback(
		(key) => {
			const pathname = history.location.pathname;
			return pathname.indexOf(key) !== -1;
		},
		[history.location.pathname]
	);

	const handleDelete = useCallback(() => {
		setDeleting(true);
		deleteStockLocation(stockLocation)
			.then(() => {
				history.push('/supplier/stockLocations/overview/all');
			})
			.catch((err) => message.error(err))
			.finally(() => setDeleting(false));
	}, [deleteStockLocation, history, stockLocation]);

	const currentStockLocation = useMemo(
		() =>
			`${nullSafeGet('id', stockLocation)}` === `${stockLocationId}`
				? stockLocation
				: nullSafeGet(`records.${stockLocationId}`, stockLocations),
		[stockLocation, stockLocationId, stockLocations]
	);

	const tabs = useMemo(
		() => [
			{
				key: 'parts',
				name: 'Parts Level',
			},
			{
				key: 'equipment',
				name: 'Equipment Level',
			},
			{
				key: 'receivedEquipment',
				name: 'Stocked Equipment',
			},
			{
				key: 'disbursedEquipments',
				name: 'Disbursed Equipment',
			},
			{
				key: 'shipments',
				name: 'Pending Shipments',
			},
			{
				key: 'shipped',
				name: 'Shipped',
			},
			{
				key: 'backOrders',
				name: 'Backorders',
			},
		],
		[]
	);

	return (
		<DetailPageHeader
			exactPath={'/supplier/stockLocations/detail/:id'}
			redirectPath={`/supplier/stockLocations/detail/${stockLocationId}/parts`}
			backLinkText={backLinkStates.backLinkText}
			backLinkTo={backLinkStates.backLinkTo}
			title={nullSafeGetOrElse('name', currentStockLocation, '')}
			subtitle={nullSafeGetOrElse('description', currentStockLocation, '')}
			actions={[
				<Link
					key={1}
					to={`/supplier/stockLocations/edit/${stockLocationId}`}
					style={{ marginRight: 16 }}
				>
					<Button ghost={true} loading={deleting}>
						Edit
					</Button>
				</Link>,
				<AccessPermissionChecker name={PERMISSION_NAMES.DELETE_STOCK_LOCATIONS}>
					<Popover
						content={
							stockLocation.hasAssociatedEntities
								? `Stock location cannot be deleted as it has Parts/Equipment`
								: ''
						}
						trigger="hover"
					>
						<Button
							key={2}
							ghost={true}
							onClick={handleDelete}
							disabled={stockLocationsFetching || stockLocation.hasAssociatedEntities}
						>
							Delete
						</Button>
					</Popover>
				</AccessPermissionChecker>,
			]}
			tabs={tabs}
			backgroundColor={nullSafeGet('detail.config.theme.navblock', companyConfig)}
			handleTabChange={handleTabChange}
			checkTabActive={checkTabActive}
			childRoutes={childRoutes}
		/>
	);
};

const mapStateToProps = (state) => ({
	stockLocationsFetching: state.stock_locations.fetching,
	stockLocations: state.stock_locations,
	stockLocation: state.stock_locations.detail,
	companyConfig: state.company_config,
});

const mapDispatchToProps = (dispatch) => ({
	getStockLocation: (id) => dispatch(stockLocationsRestCrudThunksForSupplier.readOne(id)),
	deleteStockLocation: (entity) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.delete(entity, 'id', null, true)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StockLocationsDetailPage));

import React, { FC, useMemo } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PartsEquipmentWorkOrdersList from '../parts_equipment_work_orders_list/PartsEquipmentWorkOrdersList';

const EquipmentWorkOrdersPage: FC<any> = ({ match }): React.ReactElement => {
	const equipmentTypeId = useMemo(() => match.params.id, [match.params.id]);

	return (
		<PartsEquipmentWorkOrdersList preAppliedFilters={{ equipmentTypeId }} backText="Back to Part" />
	);
};

export default withRouter<any, any>(connect()(EquipmentWorkOrdersPage));

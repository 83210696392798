import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { CheckOutlined } from '@ant-design/icons';
import { Layout, Row, Col, BackTop, Button, Popconfirm, message } from 'antd';
import { withRouter } from 'react-router';
import {
	requestsForProposalRestCrudThunksForBuyer,
	requestsForProposalRestCrudThunksForSupplier,
} from '../../thunks/requests_for_proposal_thunks';
import { getRecordsForCompositeTargetCollection } from '../../reducers/standard_reducer_utils';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { FlexibleImageAvatar } from '../flexible-image-avatar/FlexibleImageAvatar';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import {
	biddersRestCrudThunksForBuyer,
	biddersRestCrudThunksForSupplier,
	changeBidderAwardStatusForBuyer,
	changeBidderAwardStatusForSupplier,
} from '../../thunks/bidders_thunks';
import {
	proposalsRestCrudThunksForBuyer,
	proposalsRestCrudThunksForSupplier,
} from '../../thunks/proposals_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { ROLE_TYPES } from '../../utils/DataConstants';

const RFP_DETAIL_BIDDERS = 'RFP_DETAIL_BIDDERS';
const RFP_DETAIL_PROPOSALS = 'RFP_DETAIL_PROPOSALS';

require('./RFPDetailBidsPage.less');
const { Content } = Layout;

const RFPDetailBidsPage: FC<any> = ({
	getRequestForProposal,
	getBidders,
	getProposals,
	match,
	updateBidderAwardStatus,
	requestForProposal,
	bidders,
	currentUser,
	requestsForProposalFetching,
	biddersFetching,
	proposalsFetching,
	userType,
}): React.ReactElement => {
	const [lowestBidderSupplierFacilityId, setLowestBidderSupplierFacilityId] = useState(-1);

	useEffect(() => {
		const requestForProposalId = match.params.id;
		getRequestForProposal(requestForProposalId);
		getBidders({ requestForProposalId }, RFP_DETAIL_BIDDERS);
		getProposals({ requestForProposalId }, RFP_DETAIL_PROPOSALS);
	}, [getBidders, getProposals, getRequestForProposal, match.params.id]);

	const handleAwardStatusChange = useCallback(
		(requestForProposalId, supplierFacilityId, value) => {
			updateBidderAwardStatus(requestForProposalId, supplierFacilityId, value).then(() => {
				message.success(`Bidder status updated`);
				getBidders({ requestForProposalId }, RFP_DETAIL_BIDDERS);
			});
		},
		[getBidders, updateBidderAwardStatus]
	);

	const associatedBidders = useMemo(
		() => getRecordsForCompositeTargetCollection(bidders, RFP_DETAIL_BIDDERS),
		[bidders]
	);
	const loading = useMemo(
		() => requestsForProposalFetching || biddersFetching || proposalsFetching,
		[biddersFetching, proposalsFetching, requestsForProposalFetching]
	);
	const actualBidders = useMemo(
		() => associatedBidders.filter((bidder) => bidder.proposalId),
		[associatedBidders]
	);

	useEffect(() => {
		let bid = Infinity;
		let facilityId = -1;
		actualBidders.forEach((bidder) => {
			const totalAfterTax = nullSafeGet('proposal.totalAfterTax', bidder);
			if (totalAfterTax && totalAfterTax < bid && totalAfterTax !== 0) {
				bid = totalAfterTax;
				facilityId = bidder.supplierFacilityId;
			}
		});
		setLowestBidderSupplierFacilityId(facilityId);
	}, [actualBidders]);

	const bidderAwarded = useMemo(
		() => (actualBidders.filter((b) => b.awardStatus === 'awarded').length > 0 ? true : false),
		[actualBidders]
	);

	const allowInternalTechToAwardQuote = nullSafeGetOrElse(
		'facility.privateBuyerCompanySettings.config.approvalConfig.allowToApproveProposalByInternalTech',
		currentUser,
		false
	);

	return loading ? (
		<PageLoadingPlaceholder />
	) : (
		<Content className="compareBidsPage" style={{ padding: '0 0.5em' }}>
			<LogOnMountWithStandardEventProperties
				eventType={`visited ${userType} rfp detail bids page`}
			/>
			<BackTop />

			<Row style={{ margin: '0.5em -8px' }} gutter={16}>
				<Col span={24}>
					<div style={{ padding: '24px 0', maxWidth: '100%', overflow: 'auto' }}>
						<div>
							<Row>
								<table className="bidComparisonTable">
									<tbody style={{ background: 'white' }}>
										<tr>
											{actualBidders.map((bidder) => (
												<td
													key={bidder.supplierFacility.displayName}
													style={{ padding: 0, minWidth: '320px', width: '25%' }}
												>
													<div className="colBoxShadow" />
													<div
														style={{
															padding: '20px 20px 0 20px',
															display: 'flex',
															alignItems: 'center',
														}}
													>
														<span style={{ marginRight: 16 }}>
															<FlexibleImageAvatar
																displayName={bidder.supplierFacility.displayName}
																showPopover={false}
																popoverText={bidder.supplierFacility.displayName}
																imageUrl={bidder.supplierFacility.logoURL}
																width="40px"
																height="40px"
															/>
														</span>
														<span style={{ fontSize: 18 }}>
															{bidder.supplierFacility.displayName}
														</span>
													</div>
												</td>
											))}
											<td style={{ background: '#f0f2f5' }}></td>
										</tr>
										<tr>
											{actualBidders.map((bidder) => (
												<td key={bidder.supplierFacilityId}>
													<div style={{ margin: '16px 20px' }} className="flex flex-row">
														{`${nullSafeGetOrElse('proposal.scope', bidder, '--')}`}
													</div>
													<div
														style={{ padding: '16px 20px 0' }}
														className="sectionTextWrapperWithBid"
													>
														<div style={{ fontSize: 24 }}>
															${nullSafeGet('proposal.totalAfterTax', bidder)}
														</div>
														<div>
															{bidder.awardStatus === 'awarded' ? (
																<div style={{ fontSize: 16 }}>Awarded</div>
															) : bidder.awardStatus === 'declined' ? (
																<div style={{ fontSize: 16 }}>Declined</div>
															) : bidderAwarded ? (
																<Popconfirm
																	onConfirm={() =>
																		handleAwardStatusChange(
																			requestForProposal.id,
																			bidder.supplierFacilityId,
																			'declined'
																		)
																	}
																	placement="topLeft"
																	title="Send declined note to the supplier?"
																	okText="Confirm decline"
																	cancelText="Cancel"
																>
																	<Button type="primary" style={{ width: 125 }}>
																		Decline
																	</Button>
																</Popconfirm>
															) : (
																<Popconfirm
																	onConfirm={() =>
																		handleAwardStatusChange(
																			requestForProposal.id,
																			bidder.supplierFacilityId,
																			'awarded'
																		)
																	}
																	placement="topLeft"
																	title="Confirm award and send award note to the supplier?"
																	okText="Confirm award"
																	cancelText="Cancel"
																>
																	<Button
																		disabled={!allowInternalTechToAwardQuote}
																		type="primary"
																		style={{ width: 125 }}
																	>
																		Award
																	</Button>
																</Popconfirm>
															)}
														</div>
													</div>
													<div style={{ margin: '16px 20px' }} className="bidSummary_lowBidder">
														{bidder.supplierFacilityId === lowestBidderSupplierFacilityId ? (
															<span>
																<CheckOutlined translate="" /> Apparent Low Bidder
															</span>
														) : null}
													</div>
												</td>
											))}
											<td style={{ background: '#f0f2f5' }}></td>
										</tr>

										<tr>
											{actualBidders.map((bidder) => {
												return (
													<td key={bidder.id}>
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
																margin: '0 20px',
																borderBottom: '1px solid #e9e9e9',
																fontSize: 13,
																justifyContent: 'space-between',
																padding: '16px 0',
															}}
														>
															<div className="sectionTextMain">Labor</div>
															<div className="sectionTextSecondary">
																${nullSafeGet('proposal.laborTotalBeforeTax', bidder)}
															</div>
														</div>
													</td>
												);
											})}
										</tr>
										<tr>
											{actualBidders.map((bidder) => {
												return (
													<td key={bidder.id}>
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
																margin: '0 20px',
																borderBottom: '1px solid #e9e9e9',
																fontSize: 13,
																justifyContent: 'space-between',
																padding: '16px 0',
															}}
														>
															<div className="sectionTextMain">Materials</div>
															<div className="sectionTextSecondary">
																${nullSafeGet('proposal.materialTotalBeforeTax', bidder)}
															</div>
														</div>
													</td>
												);
											})}
										</tr>
										<tr>
											{actualBidders.map((bidder) => {
												return (
													<td key={bidder.id}>
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
																margin: '0 20px',
																borderBottom: '1px solid #e9e9e9',
																fontSize: 13,
																justifyContent: 'space-between',
																padding: '16px 0',
															}}
														>
															<div className="sectionTextMain">Travel</div>
															<div className="sectionTextSecondary">
																${nullSafeGet('proposal.travelTotalBeforeTax', bidder)}
															</div>
														</div>
													</td>
												);
											})}
										</tr>
										<tr>
											{actualBidders.map((bidder) => {
												return (
													<td key={bidder.id}>
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
																margin: '0 20px',
																borderBottom: '1px solid #e9e9e9',
																fontSize: 13,
																justifyContent: 'space-between',
																padding: '16px 0',
															}}
														>
															<div className="sectionTextMain">Freight</div>
															<div className="sectionTextSecondary">
																${nullSafeGet('proposal.freightTotalBeforeTax', bidder)}
															</div>
														</div>
													</td>
												);
											})}
										</tr>
										<tr>
											{actualBidders.map((bidder) => {
												return (
													<td key={bidder.id}>
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
																margin: '0 20px',
																borderBottom: '1px solid #e9e9e9',
																fontSize: 13,
																justifyContent: 'space-between',
																padding: '16px 0',
															}}
														>
															<div className="sectionTextMain">Miscellaneous</div>
															<div className="sectionTextSecondary">
																${nullSafeGet('proposal.miscTotalBeforeTax', bidder)}
															</div>
														</div>
													</td>
												);
											})}
										</tr>
										<tr>
											{actualBidders.map((bidder) => {
												return (
													<td key={bidder.id}>
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
																margin: '0 20px',
																borderBottom: '1px solid #e9e9e9',
																fontSize: 13,
																justifyContent: 'space-between',
																padding: '16px 0',
															}}
														>
															<div className="sectionTextMain">Total Before Tax</div>
															<div className="sectionTextSecondary">
																${nullSafeGet('proposal.totalBeforeTax', bidder)}
															</div>
														</div>
													</td>
												);
											})}
										</tr>
										<tr>
											{actualBidders.map((bidder) => {
												return (
													<td key={bidder.id}>
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
																margin: '0 20px',
																borderBottom: '1px solid #e9e9e9',
																fontSize: 13,
																justifyContent: 'space-between',
																padding: '16px 0',
															}}
														>
															<div className="sectionTextMain">Tax</div>
															<div className="sectionTextSecondary">
																${nullSafeGet('proposal.tax', bidder)}
															</div>
														</div>
													</td>
												);
											})}
										</tr>
										<tr>
											{actualBidders.map((bidder) => {
												return (
													<td key={bidder.id}>
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
																margin: '0 20px',
																borderBottom: '1px solid #e9e9e9',
																fontSize: 13,
																justifyContent: 'space-between',
																padding: '16px 0',
															}}
														>
															<div className="sectionTextMain">Grand Total</div>
															<div className="sectionTextSecondary">
																${nullSafeGet('proposal.totalAfterTax', bidder)}
															</div>
														</div>
													</td>
												);
											})}
										</tr>
									</tbody>
								</table>
							</Row>
						</div>
					</div>
				</Col>
			</Row>
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	requestForProposal: state.requests_for_proposal.detail,
	requestForProposalsFetching: state.requests_for_proposal.fetching,
	bidders: state.bidders,
	biddersFetching: state.bidders.fetching,
	proposals: state.proposals,
	proposalsFetching: state.proposals.fetching,
	match: ownProps.match,
	history: ownProps.history,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getRequestForProposal: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? requestsForProposalRestCrudThunksForSupplier.readOne(id)
				: requestsForProposalRestCrudThunksForBuyer.readOne(id)
		),
	updateBidderAwardStatus: (rfpId, supplierFacilityId, status) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? changeBidderAwardStatusForSupplier(rfpId, supplierFacilityId, status)
				: changeBidderAwardStatusForBuyer(rfpId, supplierFacilityId, status)
		),
	getBidders: (params, targetCollectionName, pagination, sorting, filters) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? biddersRestCrudThunksForSupplier.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters
				  )
				: biddersRestCrudThunksForBuyer.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters
				  )
		),
	getProposals: (params, targetCollectionName, pagination, sorting, filters) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? proposalsRestCrudThunksForSupplier.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters
				  )
				: proposalsRestCrudThunksForBuyer.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters
				  )
		),
});

const RFPCompareBidderPage = withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(RFPDetailBidsPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(RFPCompareBidderPage);

import * as React from 'react';
const style = require('./EmptorTag.less');

export class EmptorTag extends React.Component<any, any> {
	render() {
		const classNames = this.props.className
			? this.props.className + ' ' + 'emptorTag'
			: 'emptorTag';
		return (
			<span {...this.props} className={classNames}>
				{this.props.children}
			</span>
		);
	}
}

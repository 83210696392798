import {
	EVENT_BUYER_TEAMS_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS,
	ADD_CONTACT_TO_EVENT_BUYER_TEAM_FORM_DATA,
} from '../actions/event_buyer_teams_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';
// FIXME: Need to make standard reducer generator composible so this can be replaced with generated code

const initialState: any = standardInitialStateGenerator([
	'associatedInternalTeam',
	'associatedEventBuyerTeamForSourcingEvent',
	'associatedEventBuyerTeamForSourcingEventAndCurrentUser',
]);
const eventBuyerTeamsReducerCrudLogic = standardReducerGenerator(
	CRUD_ACTION_CONSTANTS,
	initialState
);

export default (oldState = initialState, action): any => {
	Object.freeze(oldState);
	switch (action.type) {
		case ADD_CONTACT_TO_EVENT_BUYER_TEAM_FORM_DATA:
			return Object.assign({}, oldState, {
				formData: {
					...oldState.formData,
					internalTeam: oldState.formData.internalTeam
						? [...oldState.formData.internalTeam, action.eventBuyerTeam]
						: [action.eventBuyerTeam],
				},
			});
		default:
			return eventBuyerTeamsReducerCrudLogic(oldState, action);
	}
};

import { useMemo } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { INVOICE_STATUSES } from '../../constants/InvoiceStatuses';
import InvoiceUploader from '../invoice_uploader/InvoiceUploader';
import { Button, Popover } from 'antd';
import { isInternalTech } from '../../utils/AuthUtils';
import * as React from 'react';
import { ROLE_TYPES } from '../../utils/DataConstants';

const InvoiceUploadButton = ({
	currentUser,
	handleInvoicePDFUploadChange,
	invoice,
	generatingPDF,
	generateInvoicePDF,
	userType,
}) => {
	const isBuyerInvoice = useMemo(() => invoice.isBuyerUploaded || false, [invoice]);
	const isBuyerOrInternalTech = useMemo(
		() => userType === ROLE_TYPES.BUYER || isInternalTech(currentUser),
		[userType, currentUser]
	);
	const hasPDF = useMemo(
		() => invoice.invoicePDFLink && invoice.invoicePDFLink.length > 0,
		[invoice]
	);
	const pdfElement = useMemo(
		() =>
			hasPDF ? (
				<p className={'mb-1'}>
					<a href={invoice.invoicePDFLink} target="_blank" rel="noreferrer">
						Invoice PDF
					</a>
				</p>
			) : null,
		[invoice]
	);
	const uploadButton = useMemo(() => {
		switch (invoice.status) {
			case INVOICE_STATUSES.draft:
				//Anyone can upload
				return (
					<InvoiceUploader
						roleType={userType}
						invoiceId={invoice.id}
						defaultFileList={[]}
						multiple={false}
						handleUploadSuccess={handleInvoicePDFUploadChange(invoice)}
					/>
				);
			case INVOICE_STATUSES.pending:
				if (isBuyerInvoice && isBuyerOrInternalTech) {
					return (
						<InvoiceUploader
							roleType={userType}
							invoiceId={invoice.id}
							defaultFileList={[]}
							multiple={false}
							handleUploadSuccess={handleInvoicePDFUploadChange(invoice)}
						/>
					);
				} else {
					return (
						<Popover
							content={
								isBuyerOrInternalTech
									? 'Invoices must be disputed status to allow upload of a pdf.'
									: 'Invoice must be moved back to draft status to allow upload of a pdf.'
							}
							placement={'left'}
						>
							<div>
								<InvoiceUploader
									disabled={true}
									roleType={userType}
									invoiceId={invoice.id}
									defaultFileList={[]}
									multiple={false}
									handleUploadSuccess={handleInvoicePDFUploadChange(invoice)}
								/>
							</div>
						</Popover>
					);
				}
			case INVOICE_STATUSES.disputed:
				//Anyone can upload
				return (
					<InvoiceUploader
						roleType={userType}
						invoiceId={invoice.id}
						defaultFileList={[]}
						multiple={false}
						handleUploadSuccess={handleInvoicePDFUploadChange(invoice)}
					/>
				);
		}
	}, [userType, invoice, handleInvoicePDFUploadChange, isBuyerInvoice, isBuyerOrInternalTech]);

	const uploadInvoice = [INVOICE_STATUSES.draft, INVOICE_STATUSES.disputed].some(
		(status) => status === invoice.status
	) ? (
		<InvoiceUploader
			roleType={userType}
			invoiceId={invoice.id}
			defaultFileList={[]}
			multiple={false}
			handleUploadSuccess={handleInvoicePDFUploadChange(invoice)}
		/>
	) : invoice.status === INVOICE_STATUSES.pending ? (
		<Popover
			content={'Invoice must be moved back to draft status to allow upload of a pdf.'}
			placement={'left'}
		>
			<div>
				<InvoiceUploader
					disabled={true}
					roleType={userType}
					invoiceId={invoice.id}
					defaultFileList={[]}
					multiple={false}
					handleUploadSuccess={handleInvoicePDFUploadChange(invoice)}
				/>
			</div>
		</Popover>
	) : null;
	const generateInvoice = !isInternalTech ? (
		<p style={{ marginBottom: 0, marginTop: 0 }}>
			Don't have an invoice file?{' '}
			<Button
				loading={generatingPDF}
				type="link"
				onClick={() => generateInvoicePDF(invoice.id)}
				style={{ marginBottom: 0, marginTop: 0 }}
			>
				Generate PDF
			</Button>
		</p>
	) : null;

	return pdfElement || uploadInvoice ? (
		<div>
			{pdfElement}
			{uploadButton}
			{generateInvoice}
		</div>
	) : null;
};
const mapStateToProps = (state, ownProps) => ({
	currentUser: state.session.currentUser,
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, () => {})(InvoiceUploadButton)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import React, { FC, useCallback, useMemo, useState } from 'react';
import { EmptyState } from '../empty_state/EmptyState';
import { Button, Card, Table, Tag } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { DATE_ONLY_FORMAT, dateFormatter } from '../../utils/DataFormatterUtils';
import { renderCurrency } from '../../utils/DataAccessUtils';
import moment from 'moment';
import EditListPriceModal from './EditListPriceModal';

const ListPriceHistory: FC<any> = ({
	currentUser,
	loading,
	listPrices,
	onUpdate,
	refreshEntity,
}) => {
	const [editModalVisible, setEditModalVisible] = useState(false);
	const [currentlyEditingPrice, setCurrentlyEditingPrice] = useState({});

	const showEditModal = useCallback(
		(record) => () => {
			setCurrentlyEditingPrice(record);
			setEditModalVisible(true);
		},
		[]
	);

	const hideEditModal = useCallback(() => {
		setCurrentlyEditingPrice({});
		setEditModalVisible(false);
	}, []);

	const sortedListPrices = useMemo(
		() =>
			[...listPrices].sort((a, b) => moment(b.effectiveDate).diff(moment(a.effectiveDate), 'days')),
		[listPrices]
	);

	const currentPriceDate = useMemo(() => {
		const allPastDates = sortedListPrices
			.map((_) => moment(_.effectiveDate))
			.filter((_) => moment().isAfter(_));
		const currentPrice = allPastDates.length > 0 ? allPastDates[0] : moment();
		return currentPrice;
	}, [sortedListPrices]);

	const onPriceUpdateSuccess = useCallback(() => {
		hideEditModal();
		refreshEntity();
	}, [hideEditModal, refreshEntity]);

	const columns = useMemo(
		() => [
			{
				title: 'Effective Date',
				dataIndex: 'effectiveDateString',
				render: (_) => (_ ? dateFormatter(_, DATE_ONLY_FORMAT) : '--'),
			},
			{
				title: 'List Price',
				dataIndex: 'price',
				render: renderCurrency(currentUser),
			},
			{
				dataIndex: 'effectiveDateString',
				render: (_, record) => {
					const currentPriceTag =
						_ && moment(_).isSame(currentPriceDate, 'day') ? <Tag>Current Price</Tag> : null;
					const editButton =
						_ && moment(_).isAfter(moment()) ? (
							<div className="mr-2">
								<Button type="ghost" onClick={showEditModal(record)}>
									Edit
								</Button>
							</div>
						) : null;
					return (
						<div className="flex flex-row items-center">
							{editButton}
							{currentPriceTag}
						</div>
					);
				},
			},
		],
		[currentPriceDate, currentUser, showEditModal]
	);

	return (
		<div className="mt-4">
			<Card loading={loading} title="List Prices">
				{sortedListPrices.length > 0 ? (
					<Table columns={columns} dataSource={sortedListPrices} />
				) : (
					<EmptyState
						graphic={
							<img
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
								alt="Looks like there is no list price history to display!"
							/>
						}
						headline={"Nothing's here!"}
						body={
							<div style={{ textAlign: 'center' }}>
								<div style={{ maxWidth: 440, marginBottom: 16 }}>
									Looks like there is no list price history to display!
								</div>
							</div>
						}
					/>
				)}
			</Card>
			{editModalVisible && (
				<EditListPriceModal
					onCancel={hideEditModal}
					listPrice={currentlyEditingPrice}
					onSave={onUpdate}
					onSuccess={onPriceUpdateSuccess}
				/>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

const ComponentWithoutUserType = withRouter<any, any>(connect(mapStateToProps)(ListPriceHistory));

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import React, { useMemo } from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import HyperLink, { ENTITY_TYPE } from '../common/HyperLink';
import AssetWithPartWarrantyDisplay from './AssetWithPartWarrantyDisplay';

require('./PurchaseRequestPartLineItemRowDisplay.less');

export const PurchaseRequestPartLineItemRowDisplay: React.FC<any> = ({
	titleLength = 40,
	purchaseRequestPartLineItem,
	...props
}) => {
	const hasWorkOrder = useMemo(
		() =>
			nullSafeGetOrElse('supplierPurchaseRequestLineItems', purchaseRequestPartLineItem, [])
				.map((_) => _.supplierPurchaseRequest)
				.some((_) => !!_.workOrderId),
		[purchaseRequestPartLineItem]
	);

	const allWorkOrderIds = useMemo(() => {
		const workOrderIds = nullSafeGetOrElse(
			'supplierPurchaseRequestLineItems',
			purchaseRequestPartLineItem,
			[]
		)
			.map((_) => nullSafeGet('supplierPurchaseRequest.workOrderId', _))
			.filter((_) => !!_);
		return Array.from(new Set(workOrderIds));
	}, [purchaseRequestPartLineItem]);

	const allAssets = useMemo(() => {
		const assets = nullSafeGetOrElse(
			'supplierPurchaseRequestLineItems',
			purchaseRequestPartLineItem,
			[]
		)
			.map((_) => nullSafeGet('supplierPurchaseRequest.asset', _))
			.filter((_) => !!_);
		const ids = assets.map((_) => _.id);
		const uniqueIds = Array.from(new Set(ids));
		return uniqueIds.map((id) => assets.find((_) => _.id === id)).filter((_) => !!_);
	}, [purchaseRequestPartLineItem]);

	const hasAsset = useMemo(() => allAssets.length > 0, [allAssets.length]);

	return (
		<div
			className="purchaseRequestPartLineItemRowDisplay"
			style={{ justifyContent: 'space-between' }}
			{...props}
		>
			<div className="purchaseRequestPartLineItemRowDisplay">
				<div>
					<div className="purchaseRequestPartLineItemRowDisplay__rowTitle">
						<Ellipsis tooltip={true} length={titleLength} className={'pb-0.5'}>
							{nullSafeGet('part.name', purchaseRequestPartLineItem)}
						</Ellipsis>
					</div>
					{hasAsset && (
						<div className="purchaseRequestEquipmentLineItemRowDisplay__rowSubtitle">
							{`Asset${allAssets.length > 1 ? 's' : ''}`}
							{allAssets.map((asset, idx) => (
								<AssetWithPartWarrantyDisplay key={`asset-${asset.id}`} asset={asset} />
							))}
						</div>
					)}
					{hasWorkOrder && (
						<div className="partRowDisplay__rowSubtitle">
							Work Order #
							{allWorkOrderIds.map((id) => (
								<HyperLink
									key={`WO-${id}`}
									text={id}
									entityType={ENTITY_TYPE.WORK_ORDER}
									entityId={id}
								/>
							))}
						</div>
					)}
				</div>
			</div>
			<div className="purchaseRequestPartLineItemRowDisplay__rightSide"></div>
		</div>
	);
};

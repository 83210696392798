import React, { useMemo } from 'react';
import moment from 'moment';
import { Popover } from 'antd';

const DateTimeHover = ({
	timestamp,
	showTime = true,
	showYear = true,
	showDate = false,
	parseZone = true,
	keepLocalTime = false,
	dateFormat = undefined,
}) => {
	const now = moment();
	const dateTime = useMemo(
		() => (parseZone ? moment.parseZone(timestamp) : moment(timestamp)),
		[parseZone, timestamp]
	);
	const fullDateTime = useMemo(
		() =>
			dateFormat
				? dateTime.format(dateFormat)
				: dateTime.format(
						showTime
							? `MMM D${showYear ? ', YYYY' : ''} [at] h:mm a`
							: `MMM D${showYear ? ', YYYY' : ''}`
				  ),
		[dateFormat, showTime, showYear, dateTime]
	);

	const secondsDiff = useMemo(() => now.diff(dateTime, 'seconds'), [now, dateTime]);
	const minutesDiff = useMemo(
		() => now.startOf('minute').diff(dateTime.clone().startOf('minute'), 'minutes'),
		[now, dateTime]
	);
	const hoursDiff = useMemo(() => now.diff(dateTime, 'hours'), [now, dateTime]);
	const daysDiff = useMemo(
		() => now.startOf('day').diff(dateTime.clone().local(keepLocalTime).startOf('day'), 'days'),
		[now, dateTime]
	);

	const hoverContent = <span>{dateTime.format('MMMM D, YYYY [at] h:mm a')}</span>;
	let showHover = true;

	let displayText;
	const formatText = (diff, unit) =>
		`${Math.abs(diff) === 1 ? 'a' : Math.abs(diff)} ${unit}${Math.abs(diff) > 1 ? 's' : ''}`;

	if (daysDiff < -7 || showDate) {
		displayText = fullDateTime;
		showHover = false;
	} else if (daysDiff < 0) {
		displayText = `in ${formatText(-daysDiff, 'day')} ${
			showTime ? dateTime.format(`[at] h:mm a`) : ''
		}`;
	} else if (hoursDiff < 0) {
		displayText = `in ${formatText(-hoursDiff, 'hour')}`;
	} else if (minutesDiff < 0) {
		displayText = `in ${formatText(-minutesDiff, 'minute')}`;
	} else if (secondsDiff < 0) {
		displayText = `in ${formatText(-secondsDiff, 'second')}`;
	} else if (secondsDiff < 5) {
		displayText = `just now`;
	} else if (secondsDiff < 60) {
		displayText = `${formatText(secondsDiff, 'second')} ago`;
	} else if (minutesDiff < 60) {
		displayText = `${formatText(minutesDiff, 'minute')} ago`;
	} else if (hoursDiff < 24) {
		displayText = `${formatText(-hoursDiff, 'hour')} ago`;
	} else if (daysDiff < 7) {
		displayText = `${formatText(daysDiff, 'day')} ago ${
			showTime ? dateTime.format(`[at] h:mm a`) : ''
		}`;
	} else {
		displayText = fullDateTime;
		showHover = false;
	}

	return showHover ? (
		<Popover content={hoverContent}>
			<span>{displayText}</span>
		</Popover>
	) : (
		<span>{displayText}</span>
	);
};

export default DateTimeHover;

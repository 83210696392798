export const INVOICE_STATUSES = {
	draft: 'draft',
	pending: 'pending',
	approved: 'approved',
	disputed: 'disputed',
	paid: 'paid',
	transferred: 'transferred',
	processing: 'processing',
	pastdue: 'pastdue',
	void: 'void',
};

export const INVOICE_STATUS_DISPLAY_NAMES = {
	[INVOICE_STATUSES.draft]: 'Draft',
	[INVOICE_STATUSES.pending]: 'Pending',
	[INVOICE_STATUSES.approved]: 'Approved',
	[INVOICE_STATUSES.disputed]: 'Disputed',
	[INVOICE_STATUSES.paid]: 'Paid',
	[INVOICE_STATUSES.processing]: 'Processing',
	[INVOICE_STATUSES.transferred]: 'Transferred',
	[INVOICE_STATUSES.pastdue]: 'Past Due',
	[INVOICE_STATUSES.void]: 'Voided',
};

import { getBackendUri } from '../utils/EnvConfigUtils';
import FetchUtils, { parseJSON } from './fetch_utils';
import { logoutSuccess } from '../actions/session_actions';
import {
	fetchAllMonthlyTechKpisFailure,
	fetchAllMonthlyTechKpisStart,
	fetchAllMonthlyTechKpisSuccess,
	fetchAssetDownTimeTechKPIByMonthFailure,
	fetchAssetDownTimeTechKPIByMonthStart,
	fetchAssetDownTimeTechKPIByMonthSuccess,
	fetchPlannedMaintenanceRateTechKPIByMonthFailure,
	fetchPlannedMaintenanceRateTechKPIByMonthStart,
	fetchPlannedMaintenanceRateTechKPIByMonthSuccess,
	fetchCorrectiveMaintenanceRateTechKPIByMonthFailure,
	fetchCorrectiveMaintenanceRateTechKPIByMonthStart,
	fetchCorrectiveMaintenanceRateTechKPIByMonthSuccess,
	fetchCheckInRateTechKPIByMonthFailure,
	fetchCheckInRateTechKPIByMonthStart,
	fetchCheckInRateTechKPIByMonthSuccess,
	fetchNewWorkOrdersTechKPIByMonthFailure,
	fetchNewWorkOrdersTechKPIByMonthStart,
	fetchNewWorkOrdersTechKPIByMonthSuccess,
	fetchCompletedWorkOrdersTechKPIByMonthFailure,
	fetchCompletedWorkOrdersTechKPIByMonthStart,
	fetchCompletedWorkOrdersTechKPIByMonthSuccess,
	fetchFirstTimeResolutionTechKPIByMonthFailure,
	fetchFirstTimeResolutionTechKPIByMonthStart,
	fetchFirstTimeResolutionTechKPIByMonthSuccess,
	fetchResponseTimeTechKPIByMonthFailure,
	fetchResponseTimeTechKPIByMonthStart,
	fetchResponseTimeTechKPIByMonthSuccess,
	fetchStaleWorkOrdersTechKPIByMonthStart,
	fetchStaleWorkOrdersTechKPIByMonthSuccess,
	fetchStaleWorkOrdersTechKPIByMonthFailure,
	fetchCompletionTimeTechKPIByMonthStart,
	fetchCompletionTimeTechKPIByMonthSuccess,
	fetchCompletionTimeTechKPIByMonthFailure,
	fetchNewWorkOrdersTechKPITotalStart,
	fetchNewWorkOrdersTechKPITotalSuccess,
	fetchNewWorkOrdersTechKPITotalFailure,
	fetchNewCreatedByTechWorkOrdersTechKPITotalStart,
	fetchNewCreatedByTechWorkOrdersTechKPITotalSuccess,
	fetchNewCreatedByTechWorkOrdersTechKPITotalFailure,
	fetchCompletedWorkOrdersTechKPITotalStart,
	fetchCompletedWorkOrdersTechKPITotalSuccess,
	fetchCompletedWorkOrdersTechKPITotalFailure,
	fetchPMCompletedWorkOrdersTechKPITotalStart,
	fetchPMCompletedWorkOrdersTechKPITotalSuccess,
	fetchPMCompletedWorkOrdersTechKPITotalFailure,
	fetchStaleWorkOrdersTechKPITotalStart,
	fetchStaleWorkOrdersTechKPITotalSuccess,
	fetchStaleWorkOrdersTechKPITotalFailure,
	fetchPlannedMaintenanceRateTechKPITotalStart,
	fetchPlannedMaintenanceRateTechKPITotalSuccess,
	fetchPlannedMaintenanceRateTechKPITotalFailure,
	fetchCorrectiveMaintenanceRateTechKPITotalStart,
	fetchCorrectiveMaintenanceRateTechKPITotalSuccess,
	fetchCorrectiveMaintenanceRateTechKPITotalFailure,
	fetchCheckInRateTechKPITotalStart,
	fetchCheckInRateTechKPITotalSuccess,
	fetchCheckInRateTechKPITotalFailure,
	fetchFirstTimeResolutionTechKPITotalStart,
	fetchFirstTimeResolutionTechKPITotalSuccess,
	fetchFirstTimeResolutionTechKPITotalFailure,
	fetchAssetDownTimeTechKPITotalStart,
	fetchAssetDownTimeTechKPITotalSuccess,
	fetchAssetDownTimeTechKPITotalFailure,
	fetchResponseTimeTechKPITotalStart,
	fetchResponseTimeTechKPITotalSuccess,
	fetchResponseTimeTechKPITotalFailure,
	fetchCompletionTimeTechKPITotalStart,
	fetchCompletionTimeTechKPITotalSuccess,
	fetchCompletionTimeTechKPITotalFailure,
	fetchAllTotalTechKpisStart,
	fetchAllTotalTechKpisFailure,
	fetchAllTotalTechKpisSuccess,
	fetchAverageSatisfactionRatingTechKPITotalStart,
	fetchAverageSatisfactionRatingTechKPITotalSuccess,
	fetchAverageSatisfactionRatingTechKPITotalFailure,
	fetchPMWorkOrdersTechKPITotalStart,
	fetchPMWorkOrdersTechKPITotalSuccess,
	fetchPMWorkOrdersTechKPITotalFailure,
} from '../actions/tech_kpi_analytics_actions';

const fetchUtils = new FetchUtils();
const baseUrl = getBackendUri();
const apiUrl = `/api/v1/supplier/kpi/tech_kpi_reportings`;

const fetchTechKPIByMonth =
	(startAction, successAction, failureAction, apiSubstring) =>
	(p, filters = null, pagination = null, sorting = null) => {
		let params = Object.assign({}, p);
		// if pagination present, transform into limit/offset format and add to query params
		const techEmail = params.techEmail;
		const month = params.month;
		const year = params.year;

		if (!techEmail || !month || !year) {
			return (dispatch) => {
				return new Promise((resolve, reject) => {
					resolve([]);
				});
			};
		}

		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		let queryUri = baseUrl + apiUrl + '/' + apiSubstring;

		if (techEmail) {
			queryUri += `/${techEmail}`;
		}

		if (month) {
			queryUri += `/${month}`;
		}

		if (year) {
			queryUri += `/${year}`;
		}

		delete params.techEmail;
		delete params.month;
		delete params.year;

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(startAction());

				fetchUtils
					.get(
						queryUri,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (!ok || d.status === 'error') {
							dispatch(failureAction([d.message]));
							reject(d.message);
						} else {
							dispatch(successAction(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(failureAction([d.message]));
						reject(d.message);
					});
			});
		};
	};

export const fetchNewWorkOrdersTechKPIByMonth = fetchTechKPIByMonth(
	fetchNewWorkOrdersTechKPIByMonthStart,
	fetchNewWorkOrdersTechKPIByMonthSuccess,
	fetchNewWorkOrdersTechKPIByMonthFailure,
	'monthly_new_work_order_kpis'
);
export const fetchCompletedWorkOrdersTechKPIByMonth = fetchTechKPIByMonth(
	fetchCompletedWorkOrdersTechKPIByMonthStart,
	fetchCompletedWorkOrdersTechKPIByMonthSuccess,
	fetchCompletedWorkOrdersTechKPIByMonthFailure,
	'monthly_completed_work_order_kpis'
);
export const fetchStaleWorkOrdersTechKPIByMonth = fetchTechKPIByMonth(
	fetchStaleWorkOrdersTechKPIByMonthStart,
	fetchStaleWorkOrdersTechKPIByMonthSuccess,
	fetchStaleWorkOrdersTechKPIByMonthFailure,
	'monthly_stale_work_order_kpis'
);
export const fetchPlannedMaintenanceRateTechKPIByMonth = fetchTechKPIByMonth(
	fetchPlannedMaintenanceRateTechKPIByMonthStart,
	fetchPlannedMaintenanceRateTechKPIByMonthSuccess,
	fetchPlannedMaintenanceRateTechKPIByMonthFailure,
	'monthly_planned_maintenance_rate_kpis'
);
export const fetchCorrectiveMaintenanceRateTechKPIByMonth = fetchTechKPIByMonth(
	fetchCorrectiveMaintenanceRateTechKPIByMonthStart,
	fetchCorrectiveMaintenanceRateTechKPIByMonthSuccess,
	fetchCorrectiveMaintenanceRateTechKPIByMonthFailure,
	'monthly_corrective_maintenance_rate_kpis'
);
export const fetchCheckInRateTechKPIByMonth = fetchTechKPIByMonth(
	fetchCheckInRateTechKPIByMonthStart,
	fetchCheckInRateTechKPIByMonthSuccess,
	fetchCheckInRateTechKPIByMonthFailure,
	'monthly_check_in_rate_kpis'
);
export const fetchFirstTimeResolutionTechKPIByMonth = fetchTechKPIByMonth(
	fetchFirstTimeResolutionTechKPIByMonthStart,
	fetchFirstTimeResolutionTechKPIByMonthSuccess,
	fetchFirstTimeResolutionTechKPIByMonthFailure,
	'monthly_first_time_resolution_rate_kpis'
);
export const fetchAssetDownTimeTechKPIByMonth = fetchTechKPIByMonth(
	fetchAssetDownTimeTechKPIByMonthStart,
	fetchAssetDownTimeTechKPIByMonthSuccess,
	fetchAssetDownTimeTechKPIByMonthFailure,
	'monthly_average_down_time_kpis'
);
export const fetchResponseTimeTechKPIByMonth = fetchTechKPIByMonth(
	fetchResponseTimeTechKPIByMonthStart,
	fetchResponseTimeTechKPIByMonthSuccess,
	fetchResponseTimeTechKPIByMonthFailure,
	'monthly_average_response_time_kpis'
);
export const fetchCompletionTimeTechKPIByMonth = fetchTechKPIByMonth(
	fetchCompletionTimeTechKPIByMonthStart,
	fetchCompletionTimeTechKPIByMonthSuccess,
	fetchCompletionTimeTechKPIByMonthFailure,
	'monthly_average_completion_time_kpis'
);

export const fetchAllTechKPIs = (p, filters = null, pagination = null, sorting = null) => {
	let params = Object.assign({}, p);
	const techEmail = params.techEmail;
	// if pagination present, transform into limit/offset format and add to query params
	if (pagination) {
		const paginationQuery = {
			offset: (pagination.current - 1) * pagination.pageSize,
			limit: pagination.pageSize,
		};
		params = {
			...params,
			...paginationQuery,
		};
	}

	// if sorting present, fix order naming and add to query params
	if (sorting) {
		const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
		params = {
			...params,
			...sortingQuery,
		};
	}

	if (filters) {
		params = {
			...params,
			...filters,
		};
	}

	let queryUri = baseUrl + apiUrl + '/monthly_kpis';

	if (techEmail) {
		queryUri += `/${techEmail}`;
	}

	delete params.startDate;
	delete params.endDate;

	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(fetchAllMonthlyTechKpisStart());

			fetchUtils
				.get(
					queryUri,
					Object.assign(
						{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
						params
					)
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (!ok || d.status === 'error') {
						dispatch(fetchAllMonthlyTechKpisFailure([d.message]));
						reject(d.message);
					} else {
						dispatch(fetchAllMonthlyTechKpisSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(fetchAllMonthlyTechKpisFailure([d.message]));
					reject(d.message);
				});
		});
	};
};

const fetchTechKPITotal =
	(startAction, successAction, failureAction, apiSubstring) =>
	(p, filters = null, pagination = null, sorting = null) => {
		let params = Object.assign({}, p);
		// if pagination present, transform into limit/offset format and add to query params
		const techEmail = params.techEmail;
		const startDate = params.startDate;
		const endDate = params.endDate;

		if (!techEmail || !startDate || !endDate) {
			return (dispatch) => {
				return new Promise((resolve, reject) => {
					resolve([]);
				});
			};
		}

		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		let queryUri = baseUrl + apiUrl + '/' + apiSubstring;

		if (techEmail) {
			queryUri += `/${techEmail}`;
		}

		if (startDate) {
			queryUri += `/${startDate}`;
		}

		if (endDate) {
			queryUri += `/${endDate}`;
		}

		delete params.techEmail;
		delete params.startDate;
		delete params.endDate;

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(startAction());

				fetchUtils
					.get(
						queryUri,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (!ok || d.status === 'error') {
							dispatch(failureAction([d.message]));
							reject(d.message);
						} else {
							dispatch(successAction(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(failureAction([d.message]));
						reject(d.message);
					});
			});
		};
	};

export const fetchNewWorkOrdersTechKPITotal = fetchTechKPITotal(
	fetchNewWorkOrdersTechKPITotalStart,
	fetchNewWorkOrdersTechKPITotalSuccess,
	fetchNewWorkOrdersTechKPITotalFailure,
	'total_new_work_order_kpis'
);
export const fetchNewCreatedByTechWorkOrdersTechKPITotal = fetchTechKPITotal(
	fetchNewCreatedByTechWorkOrdersTechKPITotalStart,
	fetchNewCreatedByTechWorkOrdersTechKPITotalSuccess,
	fetchNewCreatedByTechWorkOrdersTechKPITotalFailure,
	'total_new_created_by_tech_work_order_kpis'
);
export const fetchPMWorkOrdersTechKPITotal = fetchTechKPITotal(
	fetchPMWorkOrdersTechKPITotalStart,
	fetchPMWorkOrdersTechKPITotalSuccess,
	fetchPMWorkOrdersTechKPITotalFailure,
	'total_pm_work_order_kpis'
);
export const fetchCompletedWorkOrdersTechKPITotal = fetchTechKPITotal(
	fetchCompletedWorkOrdersTechKPITotalStart,
	fetchCompletedWorkOrdersTechKPITotalSuccess,
	fetchCompletedWorkOrdersTechKPITotalFailure,
	'total_completed_work_order_kpis'
);
export const fetchPMCompletedWorkOrdersTechKPITotal = fetchTechKPITotal(
	fetchPMCompletedWorkOrdersTechKPITotalStart,
	fetchPMCompletedWorkOrdersTechKPITotalSuccess,
	fetchPMCompletedWorkOrdersTechKPITotalFailure,
	'total_pm_completed_work_order_kpis'
);
export const fetchStaleWorkOrdersTechKPITotal = fetchTechKPITotal(
	fetchStaleWorkOrdersTechKPITotalStart,
	fetchStaleWorkOrdersTechKPITotalSuccess,
	fetchStaleWorkOrdersTechKPITotalFailure,
	'total_stale_work_order_kpis'
);
export const fetchPlannedMaintenanceRateTechKPITotal = fetchTechKPITotal(
	fetchPlannedMaintenanceRateTechKPITotalStart,
	fetchPlannedMaintenanceRateTechKPITotalSuccess,
	fetchPlannedMaintenanceRateTechKPITotalFailure,
	'total_planned_maintenance_rate_kpis'
);
export const fetchCorrectiveMaintenanceRateTechKPITotal = fetchTechKPITotal(
	fetchCorrectiveMaintenanceRateTechKPITotalStart,
	fetchCorrectiveMaintenanceRateTechKPITotalSuccess,
	fetchCorrectiveMaintenanceRateTechKPITotalFailure,
	'total_corrective_maintenance_rate_kpis'
);
export const fetchCheckInRateTechKPITotal = fetchTechKPITotal(
	fetchCheckInRateTechKPITotalStart,
	fetchCheckInRateTechKPITotalSuccess,
	fetchCheckInRateTechKPITotalFailure,
	'total_check_in_rate_kpis'
);
export const fetchFirstTimeResolutionTechKPITotal = fetchTechKPITotal(
	fetchFirstTimeResolutionTechKPITotalStart,
	fetchFirstTimeResolutionTechKPITotalSuccess,
	fetchFirstTimeResolutionTechKPITotalFailure,
	'total_first_time_resolution_rate_kpis'
);
export const fetchAssetDownTimeTechKPITotal = fetchTechKPITotal(
	fetchAssetDownTimeTechKPITotalStart,
	fetchAssetDownTimeTechKPITotalSuccess,
	fetchAssetDownTimeTechKPITotalFailure,
	'total_average_down_time_kpis'
);
export const fetchResponseTimeTechKPITotal = fetchTechKPITotal(
	fetchResponseTimeTechKPITotalStart,
	fetchResponseTimeTechKPITotalSuccess,
	fetchResponseTimeTechKPITotalFailure,
	'total_average_response_time_kpis'
);
export const fetchCompletionTimeTechKPITotal = fetchTechKPITotal(
	fetchCompletionTimeTechKPITotalStart,
	fetchCompletionTimeTechKPITotalSuccess,
	fetchCompletionTimeTechKPITotalFailure,
	'total_average_completion_time_kpis'
);
export const fetchAverageSatisfactionRatingKPITotal = fetchTechKPITotal(
	fetchAverageSatisfactionRatingTechKPITotalStart,
	fetchAverageSatisfactionRatingTechKPITotalSuccess,
	fetchAverageSatisfactionRatingTechKPITotalFailure,
	'total_average_satisfaction_rating_kpis'
);

export const fetchAllTechKPITotals = (p, filters = null, pagination = null, sorting = null) => {
	let params = Object.assign({}, p);
	const techEmail = params.techEmail;
	const startDate = params.startDate;
	const endDate = params.endDate;

	// if pagination present, transform into limit/offset format and add to query params
	if (pagination) {
		const paginationQuery = {
			offset: (pagination.current - 1) * pagination.pageSize,
			limit: pagination.pageSize,
		};
		params = {
			...params,
			...paginationQuery,
		};
	}

	// if sorting present, fix order naming and add to query params
	if (sorting) {
		const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
		params = {
			...params,
			...sortingQuery,
		};
	}

	if (filters) {
		params = {
			...params,
			...filters,
		};
	}

	let queryUri = baseUrl + apiUrl + '/total_kpis';

	if (techEmail) {
		queryUri += `/${techEmail}`;
	}

	if (startDate) {
		queryUri += `/${startDate}`;
	}

	if (endDate) {
		queryUri += `/${endDate}`;
	}

	delete params.startDate;
	delete params.endDate;

	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(fetchAllTotalTechKpisStart());

			fetchUtils
				.get(
					queryUri,
					Object.assign(
						{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
						params
					)
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (!ok || d.status === 'error') {
						dispatch(fetchAllTotalTechKpisFailure([d.message]));
						reject(d.message);
					} else {
						dispatch(fetchAllTotalTechKpisSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(fetchAllTotalTechKpisFailure([d.message]));
					reject(d.message);
				});
		});
	};
};

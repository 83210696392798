import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Alert, Button, Select, Switch } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import BackButton from '../back_button/BackButton';
import { siteSurveyTemplatesRestCrudThunksForBuyer } from '../../thunks/site_survey_templates_thunks';
import { locationsRestCrudThunksForBuyer } from '../../thunks/locations_thunks';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { siteSurveysRestCrudThunksForBuyer } from '../../thunks/site_surveys_thunks';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';

const FormItem = Form.Item;

interface SiteSurveyFormProps extends FormComponentProps {
	creating: boolean;
	updating: boolean;
	formData: any;
	history: any;
	redirectForwardUrl?: string;
	onSuccess?: any;
	createErrors: any[];
	updateErrors: any[];
	currentUser: any;
	create: any;
	update: any;
	fetchLocations: any;
	fetchMultipleLocations: any;
	locations: any;
	fetchSiteSurveyTemplates: any;
	fetchMultipleSiteSurveyTemplates: any;
	siteSurveyTemplates: any;
}

class SiteSurveyForm extends React.Component<SiteSurveyFormProps, any> {
	constructor(props) {
		super(props);
		this.state = {
			allLocationsSelected: false,
		};
		this.handleAllLocationsChange = this.handleAllLocationsChange.bind(this);
	}

	handleSubmit = (e, submitAction) => {
		const { history, onSuccess, redirectForwardUrl, formData } = this.props;
		e.preventDefault();
		const isUpdate = formData && formData.id !== undefined;
		this.props.form.validateFields((err, values) => {
			if (!err) {
				let siteSurveys = {
					...(isUpdate
						? {
								...formData,
						  }
						: {
								siteSurveyTemplateId: values.siteSurveyTemplateId,
								locationIds:
									nullSafeGetOrElse('locationIds.length', values, 0) > 0
										? values.locationIds
										: undefined,
						  }),
					id: values.id,
					title: values.title,
					description: values.description,
				};

				submitAction(siteSurveys).then((record) => {
					if (redirectForwardUrl) {
						history.push(redirectForwardUrl);
					}
					if (onSuccess) {
						onSuccess(record);
					}
				});
			}
		});
	};

	handleAllLocationsChange(checked) {
		this.setState({ allLocationsSelected: checked });
	}

	render() {
		const {
			updating,
			creating,
			form,
			update,
			create,
			createErrors,
			formData,
			updateErrors,
			fetchLocations,
			fetchMultipleLocations,
			locations,
			fetchSiteSurveyTemplates,
			fetchMultipleSiteSurveyTemplates,
			siteSurveyTemplates,
		} = this.props;
		const { getFieldDecorator } = form;
		const isUpdate = formData && formData.id !== undefined;
		const submitAction = isUpdate ? update : create;
		const submitText = isUpdate ? 'Update site surveys' : 'Create site surveys';
		const isLoading = updating || creating;
		getFieldDecorator(`id`, { initialValue: formData.id });
		return (
			<Form
				layout="vertical"
				onSubmit={(e) => this.handleSubmit(e, submitAction)}
				className="supplierForm"
			>
				{createErrors.length > 0 ? (
					<FormItem>
						<Alert message={createErrors.join(' ')} type="error" />
					</FormItem>
				) : null}
				{updateErrors.length > 0 ? (
					<FormItem>
						<Alert message={updateErrors.join(' ')} type="error" />
					</FormItem>
				) : null}
				<FormItem label="Name">
					{getFieldDecorator('title', {
						initialValue: formData.title,
						rules: [
							{
								required: true,
								message: 'Please enter a title',
							},
						],
					})(<Input style={{ maxWidth: 300 }} />)}
				</FormItem>
				<FormItem label="Description">
					{getFieldDecorator('description', {
						initialValue: formData.description,
					})(<Input.TextArea style={{ maxWidth: 640 }} />)}
				</FormItem>
				{isUpdate ? null : (
					<Form.Item label="Choose a Site Survey Template">
						{getFieldDecorator('siteSurveyTemplateId', {
							initialValue: formData.siteSurveyTemplateId,
							rules: [
								{
									required: true,
									message: 'Please select a site survey',
								},
							],
						})(
							<OWAsyncSelect
								stateSlice={siteSurveyTemplates}
								valueAccessor={(el) => el.id}
								// labelAccessor={el => el.title}
								targetCollectionName="siteSurveyTemplatesIndex"
								fetchData={(
									searchText,
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								) => {
									fetchSiteSurveyTemplates(
										{ search: searchText },
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									);
								}}
								fetchMultiple={(ids, targetCollectionName) => {
									fetchMultipleSiteSurveyTemplates(ids, targetCollectionName);
								}}
								renderRecord={(sst) => (
									<Select.Option key={sst.id} value={sst.id}>
										{sst.title}
									</Select.Option>
								)}
								sortBy={{
									sort_by: 'title',
									order: 'ascend',
								}}
							/>
						)}
					</Form.Item>
				)}
				{isUpdate ? null : (
					<FormItem label="Locations">
						{[
							<FormItem>
								{getFieldDecorator('allLocations', {
									initialValue: this.state.allLocationsSelected,
									valuePropName: 'checked',
								})(<Switch onChange={this.handleAllLocationsChange} />)}
								<label style={{ marginLeft: 8 }}>Select All Locations</label>
							</FormItem>,
							getFieldDecorator('locationIds', {
								initialValue: formData.locationId ? [formData.locationId] : [],
								rules: [
									{
										type: 'array',
										required: this.state.allLocationsSelected ? false : true,
										message: 'Please select a location',
									},
								],
							})(
								<OWAsyncSelect
									mode="multiple"
									stateSlice={locations}
									targetCollectionName="SITE_SURVEY_FORM_LOCATIONS_AUTOCOMPLETE"
									fetchMultiple={(ids, targetCollectionName) => {
										fetchMultipleLocations(ids, targetCollectionName);
									}}
									fetchData={(
										searchText,
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									) => {
										fetchLocations(
											{ name: searchText },
											targetCollectionName,
											pagination,
											sorting,
											filters,
											addToTargetCollection
										);
									}}
									disabled={this.state.allLocationsSelected}
									emptyWhenDisabled={true}
									placeholder={
										this.state.allLocationsSelected ? 'All Locations Selected' : 'Select Locations'
									}
									renderRecord={(category) => (
										<Select.Option key={category.id} value={category.id}>
											{category.name}
										</Select.Option>
									)}
								/>
							),
						]}
					</FormItem>
				)}
				<FormItem>
					<Button
						type="primary"
						htmlType="submit"
						loading={isLoading}
						className="contactForm__button"
					>
						{submitText}
					</Button>
					<span style={{ marginLeft: '16px' }}>
						<BackButton buttonText="Cancel" />
					</span>
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	formData: ownProps.formData,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,

	createErrors: state.site_survey_templates.createErrors,
	updateErrors: state.site_survey_templates.updateErrors,
	creating: state.site_survey_templates.creating,
	updating: state.site_survey_templates.updating,
	currentUser: state.session.currentUser,
	taskLists: state.task_lists,
	locations: state.locations,
	siteSurveyTemplates: state.site_survey_templates,
});

const mapDispatchToProps = (dispatch) => ({
	update: (entity) => dispatch(siteSurveysRestCrudThunksForBuyer.update(entity)),
	create: (entity) => dispatch(siteSurveysRestCrudThunksForBuyer.createBulk(entity)),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			locationsRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchSiteSurveyTemplates: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			siteSurveyTemplatesRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleSiteSurveyTemplates: (ids, targetCollectionName) =>
		dispatch(siteSurveyTemplatesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(Form.create<SiteSurveyFormProps>()(SiteSurveyForm))
);

import * as React from 'react';
import { Layout, Row, Col, Card } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import {
	projectTemplatesRestCrudThunksForBuyer,
	projectTemplatesRestCrudThunksForSupplier,
} from '../../thunks/project_template_thunks';
import { TwoLineList } from '../two_line_list/TwoLineList';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { mappingTemplateTypesForDisplay } from '../../utils/DataMappingUtils';

const { Content } = Layout;

const style = require('./ProjectTemplatesDetailDetailsPage.less');

interface ProjectTemplatesDetailPageProps {
	match: any;
}

class ProjectTemplatesDetailPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const { getProjectTemplate, match, currentUser } = this.props;
		const projectTemplateId = match.params.id;
		const userType = nullSafeGetOrElse('userType', currentUser, 'buyer');
		getProjectTemplate(userType, projectTemplateId);
	}

	render() {
		const { projectTemplate, projectTemplatesFetching, match } = this.props;
		const projectTemplateId = match.params.id;
		return projectTemplatesFetching ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="projectTemplatesDetailDetailsPage">
				<LogOnMountWithStandardEventProperties eventType="visited project template detail page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '-0.5em -8px 0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<Card>
							<div className="projectTemplatesDetailDetailsPage__listSection">
								<div className="projectTemplatesDetailDetailsPage__listSectionTitle">
									Required Fields
								</div>
								{projectTemplate.requiredFields && projectTemplate.requiredFields.length === 0 ? (
									'No required fields'
								) : (
									<TwoLineList
										listItems={projectTemplate.requiredFields}
										lineOneRender={(record) => record.key}
										lineTwoRender={(record) => mappingTemplateTypesForDisplay(record)}
									/>
								)}
							</div>
							<div className="projectTemplatesDetailDetailsPage__listSection">
								<div className="projectTemplatesDetailDetailsPage__listSectionTitle">
									Optional Fields
								</div>
								{projectTemplate.optionalFields && projectTemplate.optionalFields.length === 0 ? (
									'No optional fields'
								) : (
									<TwoLineList
										listItems={projectTemplate.optionalFields}
										lineOneRender={(record) => record.key}
										lineTwoRender={(record) => mappingTemplateTypesForDisplay(record)}
									/>
								)}
							</div>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	projectTemplatesFetching: state.project_templates.fetching,
	projectTemplate: state.project_templates.detail,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	getProjectTemplate: (userType, id) =>
		dispatch(
			userType === ROLE_TYPES.SUPPLIER
				? projectTemplatesRestCrudThunksForSupplier.readOne(id)
				: projectTemplatesRestCrudThunksForBuyer.readOne(id)
		),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(ProjectTemplatesDetailPage)
);

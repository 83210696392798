import { QUOTES_CRUD_ACTION_CREATORS } from '../actions/quotes_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import FetchUtils, { parseJSON } from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { logoutSuccess } from '../actions/session_actions';
import { message } from 'antd';
import { retrieveCachedUserDetails } from './session_thunks';

export const quotesRestCrudThunksForBuyer = new RestCrudThunks(
	'proposals',
	'proposal',
	'proposal',
	'proposals',
	QUOTES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'quote/'
);

export const quotesRestCrudThunksForSupplier = new RestCrudThunks(
	'proposals',
	'proposal',
	'proposal',
	'proposals',
	QUOTES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'quote/'
);

const fetchUtils = new FetchUtils();
export const downloadQuotePDFForSupplier = (id) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/supplier/quote/proposals/${id}/generate_pdf`;
	return fetchUtils
		.fileRequest(baseUrl + apiUrl, {})
		.then((response) => {
			const dataType = response.type;
			const binaryData = [];
			binaryData.push(response);
			const downloadLink = document.createElement('a');
			downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
			downloadLink.setAttribute('download', `quote-${id}`);
			document.body.appendChild(downloadLink);
			downloadLink.click();
			downloadLink.remove();
		})
		.catch((err) => console.log(err));
};
export const generateQuotePDFForSupplier = (id) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/supplier/quote/proposals/${id}/generate_pdf`;
	return {
		url: baseUrl + apiUrl,
		httpHeaders: { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token },
	};
};
export const sendQuoteByEmailForSupplier = (id, email, data) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/supplier/quote/proposals/${id}/send_email/${email}`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.post(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};
export const getAllQuoteDocumentsForSupplier = (id) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/supplier/quote/proposals/${id}/all_documents`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};
export const getQuoteCheckInImagesForSupplier = (id) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/supplier/quote/proposals/${id}/checkin_images`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};
export const getQuoteCheckOutImagesForSupplier = (id) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/supplier/quote/proposals/${id}/checkout_images`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const getQuoteNumber = (id) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/supplier/quote/proposals?quoteNumber=${id}`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const updateQuoteStatusBulk = (roleType) => (ids, status) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/quote/status/action/bulk`;
	const entity = {
		ids: ids.map((id) => parseInt(id)),
		status: status,
	};
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(QUOTES_CRUD_ACTION_CREATORS.updateStart(entity));
			fetchUtils
				.post(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: entity,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (d.status === 'error') {
						dispatch(QUOTES_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject('An error occurred in updating the selected quotes.' || d.message);
					} else {
						dispatch(QUOTES_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(QUOTES_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject('An error occurred in updating the selected quotes.' || d.message);
				});
		});
	};
};

const updateQuoteStatus =
	(quoteStatus) =>
	(roleType) =>
	(entity, primaryKey = 'id') => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/quote/status/${quoteStatus}`;
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(QUOTES_CRUD_ACTION_CREATORS.createStart(entity));

				fetchUtils
					.post(baseUrl + apiUrl, {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entity,
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(QUOTES_CRUD_ACTION_CREATORS.createError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(QUOTES_CRUD_ACTION_CREATORS.createSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(QUOTES_CRUD_ACTION_CREATORS.createError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};

const approveQuote = updateQuoteStatus('approved');
const disputeQuote = updateQuoteStatus('disputed');
const markQuotePaid = updateQuoteStatus('paid');
const markQuoteProcessing = updateQuoteStatus('processing');
const markQuotePending = updateQuoteStatus('pending');
const publishQuote = updateQuoteStatus('pending');
const voidQuote = updateQuoteStatus('void');
export const approveQuoteForBuyer = approveQuote(ROLE_TYPES.BUYER);
export const disputeQuoteForBuyer = disputeQuote(ROLE_TYPES.BUYER);
export const markQuoteProcessingForBuyer = markQuoteProcessing(ROLE_TYPES.BUYER);
export const markQuotePendingForBuyer = markQuotePending(ROLE_TYPES.BUYER);
export const markQuotePaidForBuyer = markQuotePaid(ROLE_TYPES.BUYER);
export const publishQuoteForSupplier = publishQuote(ROLE_TYPES.SUPPLIER);
export const markQuotePaidForSupplier = markQuotePaid(ROLE_TYPES.SUPPLIER);
export const voidQuoteForSupplier = voidQuote(ROLE_TYPES.SUPPLIER);

const updateQuoteField =
	(field) =>
	(roleType) =>
	(entity, primaryKey = 'id') => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/quote/update_field/${field}`;
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(QUOTES_CRUD_ACTION_CREATORS.updateStart(entity));

				fetchUtils
					.post(baseUrl + apiUrl, {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entity,
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(QUOTES_CRUD_ACTION_CREATORS.updateError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(QUOTES_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(QUOTES_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};

const updateQuoteGLCode = updateQuoteField('gl_code');
export const updateQuoteGLCodeForBuyer = updateQuoteGLCode(ROLE_TYPES.BUYER);
const updateQuotePONumber = updateQuoteField('po_number');
export const updateQuotePONumberForBuyer = updateQuotePONumber(ROLE_TYPES.BUYER);

export const createQuoteBasedOnWorkOrder =
	(workOrderId) =>
	(entity, primaryKey = 'id') => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/supplier/quote/proposals/work_order/${workOrderId}`;
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(QUOTES_CRUD_ACTION_CREATORS.createStart(entity));

				fetchUtils
					.post(baseUrl + apiUrl, {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entity,
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(QUOTES_CRUD_ACTION_CREATORS.createError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(QUOTES_CRUD_ACTION_CREATORS.createSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(QUOTES_CRUD_ACTION_CREATORS.createError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};

const downloadQuoteCSV =
	(roleType) =>
	(params, filters = null) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/quote/proposals/csv_report_generator`;
		// if sorting present, fix order naming and add to query params
		if (filters) {
			params = {
				...params,
				...filters,
			};
		}
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.message);
							message.error(d.data);
						} else {
							resolve(d.data);
							const hide = message.loading(d.data, 0);
							setTimeout(hide, 7000);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};
export const downloadQuoteCSVForBuyer = downloadQuoteCSV(ROLE_TYPES.BUYER);
export const downloadQuoteCSVForSupplier = downloadQuoteCSV(ROLE_TYPES.SUPPLIER);
const downloadQuoteFormatCSV =
	(roleType) =>
	(formatName, params, filters = null) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/quote/quotes/csv_report_generator/${formatName}`;
		// if sorting present, fix order naming and add to query params
		if (filters) {
			params = {
				...params,
				...filters,
			};
		}
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.message);
							message.error(d.data);
						} else {
							resolve(d.data);
							const hide = message.loading(d.data, 0);
							setTimeout(hide, 7000);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};
export const downloadQuoteFormatCSVForBuyer = downloadQuoteFormatCSV(ROLE_TYPES.BUYER);
export const downloadQuoteFormatCSVForSupplier = downloadQuoteFormatCSV(ROLE_TYPES.SUPPLIER);
const quoteDownloadFormat =
	(roleType) =>
	(params, filters = null) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/quote/quote_download_formats`;
		// if sorting present, fix order naming and add to query params
		if (filters) {
			params = {
				...params,
				...filters,
			};
		}
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.message);
							message.error(d.data);
						} else {
							resolve(d.data);
							const hide = message.loading(d.data, 0);
							setTimeout(hide, 7000);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};
export const quoteDownloadFormatForBuyer = quoteDownloadFormat(ROLE_TYPES.BUYER);
export const quoteDownloadFormatForSupplier = quoteDownloadFormat(ROLE_TYPES.SUPPLIER);

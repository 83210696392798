import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import OWAsyncSelect from './OWAsyncSelect';
import { Input } from 'antd';
import { nullSafeGet } from '../../utils/DataAccessUtils';

const OWAsyncTupleSelect: FC<any> = ({ value, onChange, ...props }): React.ReactElement => {
	const selectValue = useMemo(() => nullSafeGet('0', value), [value]);
	const textValue = useMemo(() => nullSafeGet('1', value), [value]);

	const [search, setSearch] = useState(textValue);
	const [dropDownValue, setDropDownValue] = useState(selectValue);

	useEffect(() => {
		if (!selectValue && !textValue) {
			setSearch(undefined);
			setDropDownValue(undefined);
		}
	}, [selectValue, textValue]);

	const onValueChange = (textValue, ddValue) => {
		onChange([ddValue, textValue]);
	};

	const onSelectChange = (val) => {
		setDropDownValue(val);
		onValueChange(search, val);
	};

	const timer = useRef(undefined);
	useEffect(() => {
		clearTimeout(timer.current);
		timer.current =
			timer.current === undefined
				? null
				: setTimeout(() => {
						if (search !== null) {
							onValueChange(search, selectValue);
						}
				  }, 400);
	}, [search]);

	const onTextChange = (e) => {
		setSearch(e.target.value.length > 0 ? e.target.value : undefined);
	};

	return (
		<div className="flex flex-col">
			<div className="flex w-full flex-1">
				<OWAsyncSelect
					{...props}
					placeholder="Select Field"
					value={dropDownValue}
					onChange={onSelectChange}
					style={{ width: 400 }}
				/>
			</div>
			<div className="mt-2">
				<Input placeholder="Enter Field Value" value={search} onChange={onTextChange} />
			</div>
		</div>
	);
};

export default OWAsyncTupleSelect;

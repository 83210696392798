import { SmileOutlined } from '@ant-design/icons';
import { Result } from 'antd';
import React from 'react';

const ComingSoon: React.FC = () => {
	return (
		<Result
			icon={<SmileOutlined />}
			title="Coming Soon! This page is under construction. Please check back later."
		/>
	);
};

export default ComingSoon;

import { Card, Table } from 'antd';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchAssociatedPRsOfPurchaseOrderForSupplier } from '../../thunks/purchase_requests_thunks';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import { PurchaseRequestRowDisplay } from '../purchase_request_row_display/PurchaseRequestRowDisplay';
import { getEntityById } from '../../utils/DataAccessUtils';

const DEFAULT_BACK_LINK_TEXT = 'Back to purchase order';

const PurchaseOrderDetailPRsPage: FC<any> = ({
	purchaseOrder,
	history,
	location,
}): React.ReactElement => {
	const [purchaseRequests, setPurchaseRequests] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		fetchAssociatedPRsOfPurchaseOrderForSupplier(purchaseOrder.id)
			.then((res) => setPurchaseRequests(res as unknown as any[]))
			.finally(() => setLoading(false));
	}, [purchaseOrder]);

	const columns = useMemo(
		() => [
			{
				render: (_, record) => <PurchaseRequestRowDisplay purchaseRequest={record} />,
			},
		],
		[]
	);

	const openPRDetail = useCallback(
		(record) => {
			const path = `/supplier/purchaseRequests/detail/${record.id}`;
			history.push(getLinkWIthBackLinkParams(location, DEFAULT_BACK_LINK_TEXT, path));
		},
		[history, location]
	);

	const onPRRow = (record) => ({
		onClick: () => openPRDetail(record),
	});

	return purchaseOrder ? (
		<Card>
			<Table
				columns={columns}
				rowKey={(record) => `pr-${record.id}`}
				dataSource={purchaseRequests}
				showHeader={false}
				loading={loading}
				onRow={onPRRow}
			/>
		</Card>
	) : null;
};

const mapStateToProps = (state, ownProps) => ({
	purchaseOrder: getEntityById(ownProps.match.params.id, state.purchase_orders),
	match: ownProps.match,
	history: ownProps.history,
	location: ownProps.location,
});

export default withRouter<any, any>(connect(mapStateToProps)(PurchaseOrderDetailPRsPage));

import { Button, Form, Modal, Select } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { addEquipmentToServiceCallForSupplier } from '../../thunks/service_calls_thunks';
import { getEntityById, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { useForm } from 'antd/lib/form/Form';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import moment from 'moment';
import { equipmentTypesRestCrudThunksForSupplier } from '../../thunks/equipment_types_thunks';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import { scheduleServiceCallForSupplier } from '../../thunks/work_orders_thunks';
import { equipmentPerStockLocationsRestCrudThunksForSupplier } from '../../thunks/equipment_per_stock_locations_thunks';
import { EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS } from '../../actions/equipment_per_stock_locations_actions';
import { PERMISSION_NAMES, isSupplierAllowedToAccess } from '../../utils/AuthUtils';

const STOCK_LOCATION_ASSOCIATED_EQUIPMENTS_TC = 'equipmentsIndex';
const EQUIPMENT_PER_STOCK_LOCATION_DROPDOWN = 'stockLocationAssociatedEquipments';

const AddEquipmentModal = ({
	equipmentTypes,
	createEquipment,
	workOrder,
	onCancel,
	addEquipmentToServiceCall,
	successCallback,
	serviceCall,
	equipmentInServiceCall,
	openCreatePurchaseRequest,
	currentUser,
	scheduleServiceCall,
	woStockLocationId,
	fetchEquipmentTypes,
	fetchEquipmentsPerStockLocation,
	fetchMultipleEquipmentsPerStockLocation,
	equipmentsPerStockLocation,
	clearEquipmentPerStockLocationTargetCollection,
	epslFetching,
	stockLocations,
	fetchStockLocations,
	fetchMultipleStockLocations,
	companyConfig,
}): React.ReactElement => {
	const [form] = useForm();

	const { setFields } = form;

	useEffect(
		() => clearEquipmentPerStockLocationTargetCollection(EQUIPMENT_PER_STOCK_LOCATION_DROPDOWN),
		[]
	);

	const equipmentPerStockLocationId = Form.useWatch('equipmentPerStockLocationId', form);
	const equipmentTypeId = Form.useWatch('equipmentTypeId', form);
	const stockLocationId = Form.useWatch('stockLocationId', form);

	const [saving, setSaving] = useState(false);

	useEffect(() => {
		equipmentTypeId &&
			stockLocationId &&
			fetchEquipmentsPerStockLocation(
				{
					equipmentTypeId,
					stockLocationId,
				},
				EQUIPMENT_PER_STOCK_LOCATION_DROPDOWN
			);
	}, [equipmentTypeId, fetchEquipmentsPerStockLocation, stockLocationId]);

	const equipmentRecord = useMemo(() => {
		const equipmentRecords = getRecordsForTargetCollection(
			equipmentTypes,
			STOCK_LOCATION_ASSOCIATED_EQUIPMENTS_TC
		);
		return equipmentRecords.find((_) => _.id === equipmentTypeId);
	}, [equipmentTypes, equipmentTypeId]);

	const supplierCompanyId = useMemo(
		() => nullSafeGet('facility.supplierCompanyId', currentUser),
		[currentUser]
	);

	const supplierFacilityId = useMemo(() => nullSafeGet('facility.id', currentUser), [currentUser]);

	const createEquipmentWithSLResponse = useCallback(
		(modelName) => {
			return new Promise((resolve, reject) => {
				createEquipment({
					modelName,
					supplierCompanyId,
					supplierFacilityId,
					images: [],
				})
					.then((equipmentType) =>
						resolve({
							id: equipmentType.id,
							equipmentTypeNumber: equipmentType.equipmentTypeNumber,
							equipmentType,
						})
					)
					.catch((err) => reject(err));
			});
		},
		[supplierCompanyId, supplierFacilityId, createEquipment]
	);

	const initialValue = useMemo(
		() => ({
			serviceCallId: nullSafeGet('id', serviceCall),
			equipmentTypeId: nullSafeGet('partId', equipmentInServiceCall),
			equipmentPerStockLocationId: nullSafeGet(
				'equipmentPerStockLocationId',
				equipmentInServiceCall
			),
			stockLocationId:
				nullSafeGet('equipmentPerStockLocation.stockLocationId', equipmentInServiceCall) ||
				woStockLocationId,
		}),
		[serviceCall, equipmentInServiceCall, woStockLocationId]
	);

	const getServiceCallId = useCallback(
		(values) => {
			return new Promise((resolve, reject) => {
				values.serviceCallId
					? resolve(values.serviceCallId)
					: scheduleServiceCall({
							serviceScheduledAt: moment().format(),
							supplierFacilityId,
							workOrderId: workOrder.id,
					  })
							.then((sc) =>
								nullSafeGet('lastServiceCall.id', sc)
									? resolve(nullSafeGet('lastServiceCall.id', sc))
									: reject('Service call id not found')
							)
							.catch((err) => reject(err));
			});
		},
		[supplierFacilityId, workOrder, scheduleServiceCall]
	);

	const onSubmit = useCallback(
		(values) => {
			if (!values.equipmentPerStockLocationId) return;
			setSaving(true);
			getServiceCallId(values).then((serviceCallId) => {
				addEquipmentToServiceCall({
					serviceCallId,
					equipmentPerStockLocationId: values.equipmentPerStockLocationId,
				})
					.then(() => successCallback && successCallback())
					.finally(() => setSaving(false));
			});
		},
		[addEquipmentToServiceCall, successCallback, getServiceCallId]
	);

	const openCreatePurchaseOrder = useCallback(() => {
		openCreatePurchaseRequest(equipmentRecord, stockLocationId);
	}, [stockLocationId, equipmentRecord, openCreatePurchaseRequest]);

	const isNoStockLocationFound = useMemo(
		() =>
			!epslFetching &&
			getRecordsForTargetCollection(
				equipmentsPerStockLocation,
				EQUIPMENT_PER_STOCK_LOCATION_DROPDOWN
			).length < 1,
		[equipmentsPerStockLocation, epslFetching]
	);

	const showCreatePOButton = useMemo(
		() => equipmentTypeId && stockLocationId && isNoStockLocationFound,
		[equipmentTypeId, isNoStockLocationFound, stockLocationId]
	);

	useEffect(
		() =>
			setFields([
				{
					name: 'equipmentPerStockLocationId',
					value: equipmentPerStockLocationId,
					errors: showCreatePOButton
						? ['Selected equipment is not available in any stock location!']
						: [],
				},
			]),
		[isNoStockLocationFound, setFields, equipmentPerStockLocationId, showCreatePOButton]
	);

	const primaryActionButton = useMemo(() => {
		return showCreatePOButton ? (
			<Button
				type="primary"
				size="large"
				style={{ marginLeft: '16px' }}
				loading={saving}
				onClick={openCreatePurchaseOrder}
			>
				Request Equipment
			</Button>
		) : (
			<Button
				type="primary"
				size="large"
				style={{ marginLeft: '16px' }}
				key="submit"
				htmlType="submit"
				form="add-parts-form"
				loading={saving}
			>
				Add
			</Button>
		);
	}, [showCreatePOButton, saving, openCreatePurchaseOrder]);

	const canCreatePartOrEquipment = useMemo(
		() =>
			isSupplierAllowedToAccess(
				PERMISSION_NAMES.MODIFY_PARTS_AND_EQUIPMENTS,
				companyConfig,
				currentUser.roles
			),
		[companyConfig, currentUser.roles]
	);

	return (
		<Modal
			visible={true}
			width={600}
			title="Add equipment"
			closable
			onCancel={onCancel}
			forceRender
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				primaryActionButton,
			]}
		>
			<Form
				form={form}
				id="add-parts-form"
				layout="vertical"
				requiredMark={false}
				initialValues={initialValue}
				onFinish={onSubmit}
			>
				<Form.Item name="serviceCallId" hidden />
				<Form.Item name="equipmentTypeId" label="Equipment">
					<OWAsyncSelect
						stateSlice={equipmentTypes}
						targetCollectionName={STOCK_LOCATION_ASSOCIATED_EQUIPMENTS_TC}
						labelAccessor={(el) => nullSafeGet('modelName', el)}
						fetchData={(
							searchText,
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						) =>
							fetchEquipmentTypes(
								{
									search: searchText || undefined,
								},
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							)
						}
						fetchMultiple={() => {}}
						createNewEntity={
							canCreatePartOrEquipment ? (name) => createEquipmentWithSLResponse(name) : undefined
						}
						renderRecord={(equipmentType) => (
							<Select.Option key={equipmentType.id} value={equipmentType.id}>
								<div className="flex flex-row justify-between">
									{equipmentType.equipmentUniqueId ? `${equipmentType.equipmentTypeId} - ` : null}{' '}
									<div className="flex flex-row items-center">
										{equipmentType.modelName}
										{equipmentType.equipmentTypeNumber && (
											<div className="text-gray-400">
												&nbsp;{` - #${equipmentType.equipmentTypeNumber}`}
											</div>
										)}
									</div>
								</div>
							</Select.Option>
						)}
						sortBy={{ sort_by: 'name', order: 'ascend' }}
					/>
				</Form.Item>
				<Form.Item
					name="stockLocationId"
					label="Stock Location"
					rules={[
						{
							required: true,
							message: 'Please select a stock location!',
						},
					]}
				>
					<OWAsyncSelect
						stateSlice={stockLocations}
						valueAccessor={(el) => el.id}
						targetCollectionName={'stockLocationsIndex'}
						labelAccessor={(el) => nullSafeGetOrElse('name', el, '')}
						fetchData={(searchText, targetCollectionName) => {
							fetchStockLocations({ searchText }, targetCollectionName);
						}}
						fetchMultiple={(ids, targetCollectionName) => {
							fetchMultipleStockLocations(ids, targetCollectionName);
						}}
						renderRecord={(sl) => (
							<Select.Option key={sl.id} value={sl.id}>
								<div className="flex flex-row justify-between">
									<div>{nullSafeGet('name', sl)}</div>
								</div>
							</Select.Option>
						)}
					/>
				</Form.Item>
				<Form.Item
					name="equipmentPerStockLocationId"
					label="Serial Number"
					rules={[
						{
							required: true,
							message: 'Please select a serial number!',
						},
					]}
				>
					<OWAsyncSelect
						disabled={!(equipmentTypeId && stockLocationId)}
						stateSlice={equipmentsPerStockLocation}
						valueAccessor={(el) => el.id}
						targetCollectionName={EQUIPMENT_PER_STOCK_LOCATION_DROPDOWN}
						labelAccessor={(epsl) => {
							nullSafeGetOrElse('serialNumber', epsl, '-');
						}}
						fetchData={(searchText, targetCollectionName) => {
							equipmentTypeId &&
								stockLocationId &&
								fetchEquipmentsPerStockLocation(
									{
										searchText,
										equipmentTypeId,
										stockLocationId,
									},
									targetCollectionName
								);
						}}
						fetchMultiple={(ids, targetCollectionName) => {
							fetchMultipleEquipmentsPerStockLocation(ids, targetCollectionName);
						}}
						renderRecord={(epsl) => (
							<Select.Option key={epsl.id} value={epsl.id}>
								{nullSafeGetOrElse('serialNumber', epsl, '-')}
							</Select.Option>
						)}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state, ownProps) => ({
	equipmentTypes: state.equipment_types,
	workOrder: getEntityById(ownProps.match.params.id, state.work_orders),
	stockLocations: state.stock_locations,
	equipmentsPerStockLocation: state.equipment_per_stock_locations,
	epslFetching: state.equipment_per_stock_locations.fetching,
	currentUser: state.session.currentUser,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	createEquipment: (entity) => dispatch(equipmentTypesRestCrudThunksForSupplier.create(entity)),
	fetchEquipmentTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			equipmentTypesRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchStockLocations: (params, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.read(params, targetCollectionName)),
	fetchMultipleStockLocations: (ids, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	clearEquipmentPerStockLocationTargetCollection: (tcName) =>
		dispatch(EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.clearTargetCollection(tcName)),
	fetchEquipmentsPerStockLocation: (params, targetCollectionName) =>
		dispatch(
			equipmentPerStockLocationsRestCrudThunksForSupplier.read(params, targetCollectionName)
		),
	addEquipmentToServiceCall: (entity) => dispatch(addEquipmentToServiceCallForSupplier(entity)),
	fetchMultipleEquipmentsPerStockLocation: (ids, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	scheduleServiceCall: (serviceCall) => dispatch(scheduleServiceCallForSupplier(serviceCall)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(AddEquipmentModal)
);

import { SERVICE_CALLS_CRUD_ACTION_CREATORS } from '../actions/service_calls_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import FetchUtils, { parseJSON } from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { logoutSuccess } from '../actions/session_actions';

export const serviceCallsRestCrudThunksForBuyer = new RestCrudThunks(
	'service_calls',
	'service_call',
	'serviceCall',
	'serviceCalls',
	SERVICE_CALLS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'work_order/'
);
export const serviceCallsRestCrudThunksForSupplier = new RestCrudThunks(
	'service_calls',
	'service_call',
	'serviceCall',
	'serviceCalls',
	SERVICE_CALLS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'work_order/'
);

const fetchUtils = new FetchUtils();
const fetchServiceCallsForTimePeriod =
	(roleType) =>
	(
		params,
		targetCollectionName = null,
		sorting = null,
		filters = { startDate: undefined, endDate: undefined },
		addToTargetCollection = false
	) => {
		const baseUrl = getBackendUri();
		const { startDate, endDate, ...otherFilters } = filters;
		const apiUrl = `/api/v1/${roleType}/work_order/service_calls/${startDate}/${endDate}`;

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (otherFilters) {
			params = {
				...params,
				...otherFilters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(SERVICE_CALLS_CRUD_ACTION_CREATORS.fetchStart(targetCollectionName));

				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(
								SERVICE_CALLS_CRUD_ACTION_CREATORS.fetchError(d.message, targetCollectionName)
							);
							reject(d.message);
						} else {
							dispatch(
								SERVICE_CALLS_CRUD_ACTION_CREATORS.fetchSuccess(
									d.data,
									d.count,
									targetCollectionName,
									{
										total: d.count,
									},
									sorting,
									filters,
									addToTargetCollection
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(
							SERVICE_CALLS_CRUD_ACTION_CREATORS.fetchError(d.message, targetCollectionName)
						);
						reject(d.message);
					});
			});
		};
	};
export const fetchServiceCallsForTimePeriodForBuyer = fetchServiceCallsForTimePeriod(
	ROLE_TYPES.BUYER
);
export const fetchServiceCallsForTimePeriodForSupplier = fetchServiceCallsForTimePeriod(
	ROLE_TYPES.SUPPLIER
);

const removePartFromServiceCall = (roleType) => (entity) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/work_order/service_calls/${entity.serviceCallId}/remove_part/${entity.partId}`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(SERVICE_CALLS_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.patch(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: entity,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(SERVICE_CALLS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(SERVICE_CALLS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(SERVICE_CALLS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};
export const removePartFromServiceCallForSupplier = removePartFromServiceCall(ROLE_TYPES.SUPPLIER);

const removeEquipmentFromServiceCall = (roleType) => (entity) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/work_order/service_calls/${entity.serviceCallId}/remove_equipment/${entity.equipmentPerStockLocationId}`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(SERVICE_CALLS_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.patch(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: entity,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(SERVICE_CALLS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(SERVICE_CALLS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(SERVICE_CALLS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};
export const removeEquipmentFromServiceCallForSupplier = removeEquipmentFromServiceCall(
	ROLE_TYPES.SUPPLIER
);

const updatePartsOnServiceCallWithPartsPerStockLocation = (roleType) => (entity) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/work_order/service_calls/${entity.serviceCallId}/part/${entity.partId}/count/${entity.quantity}`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(SERVICE_CALLS_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.patch(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: entity,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(SERVICE_CALLS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(SERVICE_CALLS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(SERVICE_CALLS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};
export const updatePartsOnServiceCallWithPartsPerStockLocationForSupplier =
	updatePartsOnServiceCallWithPartsPerStockLocation(ROLE_TYPES.SUPPLIER);

const addEquipmentToServiceCall = (roleType) => (entity) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/work_order/service_calls/${entity.serviceCallId}/add_equipment/${entity.equipmentPerStockLocationId}`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(SERVICE_CALLS_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.patch(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: entity,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(SERVICE_CALLS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(SERVICE_CALLS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(SERVICE_CALLS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};
export const addEquipmentToServiceCallForSupplier = addEquipmentToServiceCall(ROLE_TYPES.SUPPLIER);

import { createStore, applyMiddleware, compose } from 'redux';
import RootReducer from '../reducers/root_reducer';
import thunk from 'redux-thunk';

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
	}
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default (preloadedState) =>
	createStore(RootReducer, preloadedState, composeEnhancers(applyMiddleware(thunk)));

import * as React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Alert, Select, Button, Switch, Checkbox, Form } from 'antd';
import { connect } from 'react-redux';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { locationsRestCrudThunksForSupplier } from '../../thunks/locations_thunks';
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import { ROLES, SUPPLIER_ADMIN, SUPPLIER_ASSIGNABLE_ROLES, SUPPLIER_TECH } from '../roles/roles';
import StockLocationFormFields from '../supplier_role_update_form/StockLocationFormFields';
import EmailComponentAntdV4 from '../email_component/EmailComponentAntdV4';
import SupplierRegionsLocationsTreeSelect from '../regions_tree_select/SupplierRegionsLocationsTreeSelect';

const FormItem = Form.Item;

function SupplierNewUserInviteForm(props) {
	const [form] = Form.useForm();
	const {
		visible,
		onCancel,
		onSubmit,
		formData,
		createErrors,
		updateErrors,
		locations,
		isLocationChange,
	} = props;
	const [showPassword, setShowPassword] = React.useState(false);

	const { setFieldsValue } = form;

	const roles = Form.useWatch('roles', form);
	const hasAccessToAllLocations = Form.useWatch('hasAccessToAllLocations', form);
	const isSharedContact = Form.useWatch('isSharedContact', form);

	const handleSubmit = React.useCallback(
		(values) => {
			const updatedValues = {
				...values,
				...(values.hasAccessToAllLocations && { locationIds: [] }),
			};

			onSubmit && onSubmit(updatedValues);
		},
		[onSubmit]
	);
	return (
		<Modal
			visible={visible}
			width={600}
			title={'Invite New User'}
			okText={'Submit'}
			onCancel={onCancel}
			onOk={form.submit}
			closable={true}
			destroyOnClose={true}
		>
			<Form
				form={form}
				layout="vertical"
				className="supplierForm"
				preserve={false}
				initialValues={{ roles: nullSafeGetOrElse('roles', formData, 'SUPPLIER_USER') }}
				onFinish={handleSubmit}
			>
				{createErrors.length > 0 ? (
					<FormItem>
						<Alert message={createErrors.join(' ')} type="error" />
					</FormItem>
				) : null}
				{updateErrors.length > 0 ? (
					<FormItem>
						<Alert message={updateErrors.join(' ')} type="error" />
					</FormItem>
				) : null}
				<EmailComponentAntdV4 />
				<FormItem
					label={'Name'}
					className={'flex flex-row'}
					required={true}
					rules={[
						{
							required: true,
							message: 'Please input name.',
						},
					]}
				>
					<div className={'flex flex-row'} style={{ marginBottom: -24 }}>
						<FormItem name={'nameGiven'}>
							<Input
								placeholder="Given name"
								style={{ maxWidth: 200 }}
								disabled={false}
								defaultValue={nullSafeGet('contact.nameGiven', formData)}
							/>
						</FormItem>
						<FormItem name={'nameFamily'}>
							<Input
								defaultValue={nullSafeGet('contact.nameFamily', formData)}
								placeholder="Family name"
								style={{ maxWidth: 200, marginLeft: 8 }}
								disabled={false}
							/>
						</FormItem>
					</div>
				</FormItem>

				<FormItem
					label="Roles"
					name="roles"
					required={true}
					rules={[
						{
							required: true,
							message: 'Please select a role',
						},
					]}
				>
					<Select disabled={isLocationChange ? isLocationChange : false} style={{ maxWidth: 200 }}>
						{Array.from(SUPPLIER_ASSIGNABLE_ROLES).map((name, idx) => {
							const entity = ROLES[name];
							return (
								<Select.Option value={name} key={`role-${idx}`}>
									{entity.displayName}
								</Select.Option>
							);
						})}
					</Select>
				</FormItem>
				{roles === SUPPLIER_TECH ? (
					<>
						<div className="flex flex-row">
							<FormItem name="hasAccessToAllLocations" valuePropName="checked">
								<Switch
									checked={!!form.getFieldValue('hasAccessToAllLocations')}
									onChange={(checked) =>
										form.setFieldsValue({ hasAccessToAllLocations: !!checked })
									}
								/>
								<label style={{ marginLeft: 8 }}>Select All Locations</label>
							</FormItem>
						</div>

						<FormItem
							name="locationIds"
							label="Locations"
							rules={[
								{
									type: 'array',
									required: !hasAccessToAllLocations,
									message: 'Please select a location',
								},
							]}
						>
							<SupplierRegionsLocationsTreeSelect
								mode="multiple"
								disabled={hasAccessToAllLocations}
								filtersSlice={locations}
								filtersValueAccessor={() => form.getFieldValue('locationIds')}
								targetCollectionName={'buyerUsersLocations'}
							/>
						</FormItem>
						<StockLocationFormFields setFieldsValue={setFieldsValue} />
					</>
				) : null}
				<div className="flex flex-row items-baseline">
					<FormItem name="isSharedContact" valuePropName="checked">
						<Switch />
					</FormItem>
					<label className="ml-2">Shared Account</label>
				</div>
				{roles !== SUPPLIER_ADMIN && (
					<div className="flex flex-row items-baseline">
						<FormItem name="readOnlyAccess" valuePropName="checked">
							<Switch />
						</FormItem>
						<label className="ml-2">Read Only Access</label>
					</div>
				)}
				<div style={{ marginTop: 12 }}>
					{showPassword || isSharedContact ? (
						<>
							{showPassword ? (
								<FormItem>
									<Button
										style={{ padding: 0 }}
										type={'link'}
										onClick={() => setShowPassword(false)}
									>
										<UpCircleOutlined translate="" />
										<span style={{ textTransform: 'capitalize' }}>Hide Password</span>
									</Button>
								</FormItem>
							) : null}

							<FormItem
								name="password"
								label={
									<span>
										Password <span style={{ color: 'rgba(0,0,0,0.45)' }}>(Optional)</span>
									</span>
								}
								required={false}
								style={{ marginBottom: 0 }}
							>
								<Input type="password" style={{ maxWidth: 200 }} />
							</FormItem>
							<div className="flex flex-row items-center">
								<FormItem name="passwordResetRequired" valuePropName="checked" required={false}>
									<Checkbox>Ask user to change password when they sign in</Checkbox>
								</FormItem>
							</div>
						</>
					) : (
						<FormItem>
							<Button style={{ padding: 0 }} type={'link'} onClick={() => setShowPassword(true)}>
								<DownCircleOutlined translate="" />
								<span style={{ textTransform: 'capitalize' }}>Set password</span>
							</Button>
						</FormItem>
					)}
				</div>
			</Form>
		</Modal>
	);
}

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	createErrors: state.supplier_contacts.createErrors,
	updateErrors: state.supplier_contacts.updateErrors,
	creating: state.supplier_contacts.creating,
	updating: state.supplier_contacts.updating,
	currentUser: state.session.currentUser,

	locations: state.locations,
	formData: ownProps.formData,
});

const mapDispatchToProps = (dispatch) => ({
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			locationsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupplierNewUserInviteForm);

import * as React from 'react';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Alert, Button, Row, Select } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { supplierFacilitiesRestCrudThunksForSupplier } from '../../thunks/supplier_facilities_thunks';
import { getObjectValues, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { spendCategoriesRestCrudThunksForSupplier } from '../../thunks/spend_categories_thunks';
import OWAsyncCheckbox from '../visual_checkbox_input/VisualCheckboxInput';
import { capitalize } from '../../utils/DataFormatterUtils';
import { CURRENCIES } from '../../utils/DataConstants';
import { getUserObj, InitUser } from '../../thunks/session_thunks';

const FormItem = Form.Item;
const style = require('./EditCurrencyForm.less');

interface EditCurrencyFormProps extends FormComponentProps {
	loading: boolean;
	updateSupplier: any;
	successCallback: any;
	cancelCallback: any;
	supplierFacility: any;
	currencyId: any;

	errors: string[];
}

class EditCurrencyForm extends React.Component<EditCurrencyFormProps, any> {
	handleSubmit = (e) => {
		const { updateSupplier, refreshUser, successCallback, supplierFacility, form } = this.props;
		e.preventDefault();

		form.validateFields((err, values) => {
			if (!err) {
				const updatedSupplier = {
					...supplierFacility,
					currencyId: nullSafeGetOrElse('currencyId', values, 'USD'),
				};
				updateSupplier(updatedSupplier).then((resp) =>
					refreshUser().then(() => successCallback(resp))
				);
			}
		});
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { loading, errors, supplierFacility, currencyId, cancelCallback } = this.props;
		const currencyRecordsList = getObjectValues(CURRENCIES);
		const currencyRecordsMap = getObjectValues(CURRENCIES);

		return (
			<Form onSubmit={this.handleSubmit} className="EditCurrencyForm">
				{errors && errors.length > 0
					? errors.map((msg, idx) => (
							<FormItem key={idx}>
								<Alert message={msg} type="error" />
							</FormItem>
					  ))
					: null}
				<div key={5}>
					<h5 className="EditCurrencyForm__title">Select the currency your company uses</h5>
					<Row className={'mb-4'}>
						<Alert
							showIcon
							type={'warning'}
							message={'This will not change the currency of any existing quotes/invoices'}
						/>
					</Row>
					<Form.Item label="Currency">
						{getFieldDecorator('currencyId', {
							rules: [{ required: true, message: 'Please select a currency for this event.' }],
							initialValue: currencyId || 'USD',
						})(
							<Select
								showSearch={true}
								style={{ width: 300 }}
								optionFilterProp="children"
								filterOption={(input, option) =>
									currencyRecordsMap[option.props.value].displayNamePlural
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}
								placeholder="Currency"
							>
								{currencyRecordsList.map((currency) => (
									<Select.Option key={currency.id} value={currency.id}>
										{capitalize(currency.displayNamePlural)} ({currency.id})
									</Select.Option>
								))}
							</Select>
						)}
					</Form.Item>
				</div>
				<FormItem>
					<Button type="primary" htmlType="submit" loading={loading}>
						Update Currency
					</Button>
					<Button style={{ marginLeft: 16 }} onClick={cancelCallback}>
						Cancel
					</Button>
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	loading: state.supplier_facilities.loading,
	errors: state.supplier_facilities.updateErrors,
	spendCategories: state.spend_categories,
	successCallback: ownProps.successCallback,
	cancelCallback: ownProps.cancelCallback,
	supplierFacility: ownProps.supplierFacility,
});

const mapDispatchToProps = (dispatch) => ({
	refreshUser: () => dispatch(InitUser('supplier')),
	updateSupplier: (supplierFacility) =>
		dispatch(supplierFacilitiesRestCrudThunksForSupplier.update(supplierFacility)),
});

export default withRouter<any, any>(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<EditCurrencyFormProps>()(EditCurrencyForm))
);

import { getSearchStringForBackNavigationWithBackText, nullSafeGetOrElse } from './DataAccessUtils';
import { HOME_PAGE_URLS, ROLE_TYPES } from './DataConstants';

const queryString = require('qs');

export const getLocationSearch = (location) => {
	const searchString = location.search;
	return searchString[0] === '?' ? searchString.slice(1) : searchString;
};

export const generateBackLink = (url, location: any = {}): string => {
	const queryParams = queryString.parse(getLocationSearch(location));
	return `${url}?${queryParams.backlinkParams || ''}`;
};

export const isHomePageUrl = (url) => {
	const isHomPage = HOME_PAGE_URLS.filter((_) => url.includes(_)).length > 0;
	return isHomPage;
};

export const getDefaultWorkOrderTab = (userType, filters) => {
	const key = userType === ROLE_TYPES.SUPPLIER ? 'hasSupplierArchived' : 'hasBuyerArchived';
	return Object.keys(filters || {}).includes(key) ? (filters[key] ? 'archived' : 'active') : 'all';
};

export const getBackLinkStates = (location, defaultPath) => {
	const queryParams = queryString.parse(getLocationSearch(location));
	if (queryParams.backlinkParams) {
		const { pathName, backLinkText, targetCollectionName, ...otherParams } = queryString.parse(
			queryParams.backlinkParams
		);
		const finalPath = pathName || defaultPath;
		return {
			backLinkParams: new URLSearchParams(queryParams).toString(),
			backLinkTo: `${finalPath}?${new URLSearchParams(otherParams).toString()}`,
			...(backLinkText && { backLinkText }),
			fromTargetCollectionName: targetCollectionName || null,
		};
	}
	return {};
};

//Take current params and wrap them in a backlink (+1 level)
export const getLinkWIthBackLinkParams = (location, text, path, extraParams?) => {
	const searchString = getSearchStringForBackNavigationWithBackText(text, location, extraParams);
	return searchString && searchString.length > 0
		? path.includes('?')
			? path.concat(`&backlinkParams=${encodeURIComponent(searchString)}`)
			: path.concat(`?backlinkParams=${encodeURIComponent(searchString)}`)
		: path;
};

//Take backlink params and move them to current params (-1 level)
export const goBackLink = (location, defaultPath) => {
	const queryParams = queryString.parse(getLocationSearch(location));

	if (queryParams.backlinkParams) {
		const pathName = nullSafeGetOrElse(
			'pathName',
			queryString.parse(queryParams.backlinkParams),
			defaultPath
		);
		return `${pathName}?${queryParams.backlinkParams}`;
	}
	return defaultPath;
};

export const getQueryStringFromObject = (params) => {
	const string = Object.keys(params || {}).reduce((str, key, idx) => {
		return str + `${idx === 0 ? '' : '&'}${key}=${encodeURIComponent(params[key])}`;
	}, '');
	return string;
};

export const openInNewTab = (link) => window.open(link, '_blank', 'noreferrer');

export const recursiveRemovePreserve = (urlSearchParams) => {
	if (urlSearchParams.backlinkParams) {
		const backSearchParams = queryString.parse(urlSearchParams.backlinkParams);
		const backlinkParams = new URLSearchParams(recursiveRemovePreserve(backSearchParams));
		const allParams = new URLSearchParams(urlSearchParams);
		allParams.set('backlinkParams', backlinkParams.toString());
		return allParams.toString();
	} else if (urlSearchParams.preserve) {
		let params = new URLSearchParams(urlSearchParams);
		params.delete('preserve');
		return params.toString();
	}
	return new URLSearchParams(urlSearchParams).toString();
};

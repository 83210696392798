import React from 'react';
import '@ant-design/compatible/assets/index.css';

const style = require('./TruncatedText.less');

export class TruncatedText extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			truncated: true,
		};
	}

	showMore = () => {
		this.setState({ truncated: false });
	};

	showLess = () => {
		this.setState({ truncated: true });
	};

	render() {
		const { children, truncatedHeight, ...otherProps } = this.props;
		const { truncated } = this.state;
		const containerStyle = otherProps.style
			? {
					...otherProps.style,
					minWidth: '96px',
					maxHeight: truncatedHeight,
			  }
			: {
					minWidth: '96px',
					maxHeight: truncatedHeight,
			  };
		return truncated ? (
			<div className="truncatedText truncated" {...otherProps} style={containerStyle}>
				{children}
				<div className="showMore" onClick={this.showMore}>
					Show more
				</div>
			</div>
		) : (
			<div className="truncatedText" {...otherProps}>
				{children}
				<div className="showLess" onClick={this.showLess}>
					Show less
				</div>
			</div>
		);
	}
}

import React, { FC, useMemo } from 'react';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { EmptyState } from '../empty_state/EmptyState';
import HyperLink, { ENTITY_TYPE } from '../common/HyperLink';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { PURCHASE_ORDER_LINE_ITEMS_CRUD_ACTION_CREATORS } from '../../actions/purchase_order_line_items_actions';
import { purchaseOrderLineItemsRestCrudThunksForSupplier } from '../../thunks/purchase_order_line_items_thunks';
import PurchaseOrderStatusDisplay from '../purchase_order_status_display/PurchaseOrderStatusDisplay';
import { getPurchaseOrderId, nullSafeGet } from '../../utils/DataAccessUtils';

const TC_NAME = 'purchaseOrderLineItemsIndex';

const EquipmentPurchaseOrdersPage: FC<any> = ({
	updateFilters,
	clearAndUpdateFilters,
	purchaseOrderLineItems,
	match,
}): React.ReactElement => {
	const equipmentTypeId = useMemo(() => match.params.id, [match.params.id]);

	const columns = useMemo(
		() => [
			{
				title: 'PO',
				dataIndex: 'supplierPurchaseOrder',
				render: (_) => (
					<HyperLink
						text={getPurchaseOrderId(_)}
						entityType={ENTITY_TYPE.PURCHASE_ORDER}
						entityId={nullSafeGet('id', _)}
						backLinkText="Back to Equipment"
					/>
				),
			},
			{
				title: 'Ordered Qty',
				dataIndex: 'equipmentQuantity',
				render: (_) => _ || 0,
			},
			{
				title: 'Received Qty',
				dataIndex: 'equipmentReceivedQuantity',
				render: (_) => _ || 0,
			},
			{
				title: 'Status',
				render: (_, record) => <PurchaseOrderStatusDisplay status={record.status} />,
			},
		],
		[]
	);

	return (
		<PaginatedReduxTableWithHeader
			targetCollectionName={TC_NAME}
			updateFilters={updateFilters}
			stateSlice={purchaseOrderLineItems}
			clearAndUpdateFilters={clearAndUpdateFilters}
			filterConfig={[]}
			entityCollectionName="purchase_order_line_items"
			tableColumns={columns}
			fetchData={purchaseOrderLineItemsRestCrudThunksForSupplier.read}
			rightActions={null}
			showHeader
			hideFilters
			preAppliedFilters={{ equipmentTypeId }}
			emptyState={
				<EmptyState
					graphic={
						<img
							alt="No Data"
							style={{ marginBottom: 8 }}
							src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
						/>
					}
					headline={'No Purchase Orders found'}
					body={null}
				/>
			}
		/>
	);
};

const mapStateToProps = (state) => ({
	purchaseOrderLineItems: state.purchase_order_line_items,
});

const mapDispatchToProps = (dispatch) => ({
	updateFilters: (filters, targetCollection) =>
		dispatch(
			PURCHASE_ORDER_LINE_ITEMS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)
		),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			PURCHASE_ORDER_LINE_ITEMS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(
				filters,
				targetCollectionName
			)
		),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(EquipmentPurchaseOrdersPage)
);

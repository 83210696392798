import * as React from 'react';
import { useMemo, useRef, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { SimpleRecordContainer } from '../simple_record_container/SimpleRecordContainer';
import { getBuyerCompanyApprovalTiers } from '../../thunks/company_approval_thunks';
import { Button, Card, Col, Row, Typography } from 'antd';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { EmptyState } from '../empty_state/EmptyState';
import { approvalsRestCrudThunksForBuyer } from '../../thunks/company_approval_thunks';
import { APPROVAL_HIERARCHIES_CRUD_ACTION_CREATORS } from '../../actions/company_approval_actions';

const style = require('../../styles/rowDisplay.less');
const { Text } = Typography;

function ApprovalHierarchyIndexPage({
	fetchBuyerCompanyApprovalSettings,
	history,
	currentUser,
	approvalHierarchies,
	fetching,
	updateApprovalsFilters,
}) {
	const columns = [
		{
			title: '',
			dataIndex: 'createdAt',
			defaultSortOrder: 'desc',
			order: 'desc',
			sort_by: 'createdAt',
			render: (text, record) => {
				const mirrorRecord = nullSafeGet('approvalHierarchyMirrorId', record)
					? [].filter((mRecord) => mRecord.id === nullSafeGet('approvalHierarchyMirrorId', record))
					: undefined;
				const users = nullSafeGetOrElse('tierDetails', record, []).flatMap(
					(tier) => tier.approvers
				);
				return (
					<Link
						to={`/buyer/approvalHierarchies/detail/templates/${record.id}/details`}
						style={{ display: 'flex', width: '100%' }}
					>
						<div className="rowColumn">
							<div className="rowPrimary">
								<Ellipsis
									tooltip={true}
									fullWidthRecognition={true}
									lines={1}
									style={{
										color: 'rgba(0,0,0,0.65)',
										paddingBottom: '1px',
										marginRight: '5%',
										overflow: 'hidden',
									}}
								>
									{record.name}
								</Ellipsis>
							</div>
							<div className="rowSecondary">
								{record.tierDetails ? (
									<div className="rowInlineGroup">
										<span className="rowGrayChip">
											{record.tierDetails && record.tierDetails.length
												? record.tierDetails.length
														.toString()
														.concat(record.tierDetails.length === 1 ? ' Tier' : ' Tiers')
												: null}
										</span>
									</div>
								) : null}
								{users.length > 0 ? (
									<div className="rowInlineGroup">
										<span className="rowGrayChip">
											{users.length} {users.length === 1 ? 'User' : 'Users'}
										</span>
									</div>
								) : null}
								<div className="rowInlineGroup">
									<span>
										{nullSafeGet('createdAt', record)
											? `Last updated ${moment(nullSafeGet('createdAt', record)).fromNow()}`
											: null}
									</span>
								</div>
								{mirrorRecord ? <Text type={'danger'}>Mirroring {record.name}</Text> : null}
							</div>
						</div>
					</Link>
				);
			},
		},
	];
	const popupRenderFunc = (record) => `
            <div style="max-width: 480px; padding: 8px;">
                <a href="/'buyer'/projects/detail/${record.id}">
                    <div
                        class="projectRowDisplay__rowTitle"
                        style="margin-bottom: 8px;"
                    >
                      ${record.title}
                    </div>
                    <div class="projectRowDisplay__rowSubtitle">
                        <div class="projectRowDisplay__rowInlineGroup">
                            <i class="icons8-font icons8-location-marker"></i>
                            <span>${nullSafeGet('location.storeId', record)}</span>
                        </div>
                        <div class="projectRowDisplay__rowInlineGroup">
                            <i class="icons8-font icons8-maintenance-filled"></i>
                            <span>${nullSafeGet('spendCategory.name', record)}</span>
                        </div>
                    </div>
                </a>
            </div>
        `;
	return (
		<Col className={'gap-2 px-2'}>
			<Row className={'mt-1 flex justify-end'}>
				<Button
					type={'primary'}
					onClick={() => history.push('/buyer/approvalHierarchies/detail/templates/new')}
				>
					Create Approval Hierarchy
				</Button>
			</Row>
			<Row className={'mt-1'}>
				<Card className={'flex w-full flex-col'}>
					<PaginatedReduxTable
						mode={'list'}
						tableLayoutFixed={true}
						updateQueryParams={true}
						popupRenderFunc={popupRenderFunc}
						showHeader={false}
						emptyState={
							<EmptyState
								graphic={
									<img
										style={{ marginBottom: 8 }}
										src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
									/>
								}
								headline={"It's a little lonely in here."}
								body={
									<div style={{ textAlign: 'center' }}>
										<div style={{ maxWidth: 440, marginBottom: 16 }}>
											Looks like your team has not created any hierarchy templates.
										</div>
										<Button
											type="primary"
											onClick={() => {
												this.props.history.push(`/buyer/approvalHierarchies/detail/templates/new`);
											}}
										>
											Create your first Hierarchy Template
										</Button>
									</div>
								}
							/>
						}
						collectionName="approval_hierarchies"
						targetCollectionName={'approvalsIndex'}
						columns={columns}
						onRow={() => {}}
						keyAccessor={(el) => el.id}
						initialFilters={{}}
						initialPagination={{ current: 1, pageSize: 10 }}
						fetchData={approvalsRestCrudThunksForBuyer.read}
					/>
				</Card>
			</Row>
		</Col>
	);
}

const mapStateToProps = (state, ownProps) => ({
	currentUser: state.session.currentUser,
	approvalHierarchies: state.approval_hierarchies,
	fetching: state.approval_hierarchies.fetching,
	history: ownProps.history,
});
const mapDispatchToProps = (dispatch) => ({
	updateApprovalsFilters: (filters, targetCollection) =>
		dispatch(APPROVAL_HIERARCHIES_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	fetchBuyerCompanyApprovalSettings: (id) => dispatch(getBuyerCompanyApprovalTiers('buyer', id)()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApprovalHierarchyIndexPage));

import React from 'react';
import { EditOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Alert, Button, Select, Table, Card, Popover, message } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import {
	getCurrency,
	getCurrencyIdIfSameLineItemsCurrency,
	getIfAllSameCurrencies,
	nullSafeGet,
	nullSafeGetOrElse,
	renderCurrencyByCurrencyId,
} from '../../utils/DataAccessUtils';
import BackButton from '../back_button/BackButton';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import { purchaseRequestsRestCrudThunksForSupplier } from '../../thunks/purchase_requests_thunks';
import { purchaseRequestLineItemsRestCrudThunksForSupplier } from '../../thunks/purchase_request_line_items_thunks';
import AddPartInPRForm from './AddPartInPRForm';
import AddEquipmentInPRForm from './AddEquipmentInPRForm';
import { PURCHASE_REQUEST_STATUSES } from '../../constants/PurchaseRequestStatuses';
import OWRadioGroup from '../ow_radio_group/OWRadioGroup';
import { locationsRestCrudThunksForSupplier } from '../../thunks/locations_thunks';

require('./PurchaseRequestForm.less');

interface PartFormProps extends FormComponentProps {
	isModal?: boolean;
	creating: boolean;
	updating: boolean;
	formData: any;
	history: any;
	redirectForwardUrl?: string;
	onSuccess?: any;
	createErrors: any[];
	updateErrors: any[];
	currentUser: any;
	create: any;
	update: any;
	createLineItemsBulk: any;
	stockLocations: any;
	fetchMultipleStockLocations: any;
	fetchStockLocations: any;
	requestedParts?: any;
	requestedEquipments?: any;
	locations: any;
	fetchMultipleLocations: any;
	fetchLocations: any;
}

class PurchaseRequestForm extends React.Component<PartFormProps, any> {
	state = {
		formDataInitialized: false,
		addPartVisible: false,
		currentlyEditingPart: null,
		addedParts: [],
		addEquipmentVisible: false,
		currentlyEditingEquipment: null,
		addedEquipments: [],
		creatingPR: false,
	};

	componentDidMount(): void {
		const { formData, fetchStockLocations, form, requestedParts, requestedEquipments } = this.props;
		const locationId = nullSafeGet('workOrder.locationId', formData);

		const stockLocationId =
			nullSafeGet('0.stockLocationId', requestedParts) ||
			nullSafeGet('0.stockLocationId', requestedEquipments);

		if (stockLocationId) {
			form.setFieldsValue && form.setFieldsValue({ stockLocationId });
		} else if (locationId) {
			fetchStockLocations(
				{
					locationId,
				},
				'prLocationCheckIndex'
			).then(
				(sl) =>
					form.setFieldsValue &&
					form.setFieldsValue({
						...(sl && sl.length > 0 ? { stockLocationId: sl[0].id } : {}),
					})
			);
		}

		nullSafeGet('0.partId', requestedParts) &&
			this.setState({
				addedParts: requestedParts,
			});

		nullSafeGet('0.equipmentTypeId', requestedEquipments) &&
			this.setState({
				addedEquipments: requestedEquipments,
			});
	}

	getPartLineItemsCurrency = () =>
		getCurrencyIdIfSameLineItemsCurrency(this.state.addedParts, 'part');
	getEquipmentLineItemsCurrency = () =>
		getCurrencyIdIfSameLineItemsCurrency(this.state.addedEquipments, 'equipment');

	handleSubmit = (e, submitAction) => {
		const { formData, history, onSuccess, redirectForwardUrl, createLineItemsBulk, currentUser } =
			this.props;
		e.preventDefault();

		const { warrantyAssetId, workOrderId } = formData;

		this.props.form.validateFields((err, values) => {
			if (!err) {
				const userCurrencyId = getCurrency({ currentUser }).id;
				const partItems: any[] = this.state.addedParts.map((eachPart) => ({
					id: eachPart.partLineItemId,
					isEquipmentLine: false,
					partId: eachPart.partId,
					partCatalogId: eachPart.partCatalogId,
					partQuantity: parseInt(eachPart.quantity, 10),
					partUnitCost: eachPart.unitCost ? parseFloat(eachPart.unitCost) : 0,
					partCost: eachPart.cost ? parseFloat(eachPart.cost) : undefined,
					partCurrencyId: nullSafeGetOrElse(
						'partCatalog.partEquipmentVendor.currencyId',
						eachPart,
						userCurrencyId
					),
					partUomId: 1,
					equipmentCurrencyId: userCurrencyId,
					equipmentUomId: 1,
					supplierFacilityId: values.supplierFacilityId,
					supplierCompanyId: values.supplierCompanyId,
				}));

				const equipmentItems: any[] = this.state.addedEquipments.map((eachEquipment) => ({
					id: eachEquipment.equipmentLineItemId,
					isEquipmentLine: true,
					equipmentTypeId: eachEquipment.equipmentTypeId,
					equipmentCatalogId: eachEquipment.equipmentCatalogId,
					equipmentQuantity: parseInt(eachEquipment.quantity, 10),
					equipmentUnitCost: eachEquipment.unitCost ? parseFloat(eachEquipment.unitCost) : 0,
					equipmentCost: eachEquipment.cost ? parseFloat(eachEquipment.cost) : undefined,
					partCurrencyId: userCurrencyId,
					partUomId: 1,
					equipmentCurrencyId: nullSafeGetOrElse(
						'equipmentCatalog.partEquipmentVendor.currencyId',
						eachEquipment,
						userCurrencyId
					),
					equipmentUomId: 1,
					supplierFacilityId: values.supplierFacilityId,
					supplierCompanyId: values.supplierCompanyId,
				}));

				const totalCost =
					partItems.reduce((acc, item) => acc + nullSafeGetOrElse('partCost', item, 0), 0) +
					equipmentItems.reduce(
						(acc, item) => acc + nullSafeGetOrElse('equipmentCost', item, 0),
						0
					);

				if (![...(partItems || []), ...(equipmentItems || [])].length) {
					message.error('Atleast one part or equipment needs to be added!');
					return;
				}

				const prCurrencyId = getIfAllSameCurrencies(
					[this.getPartLineItemsCurrency(), this.getEquipmentLineItemsCurrency()].filter((_) => !!_)
				);

				const purchase_request = {
					id: values.id,
					name: values.name,
					description: values.description,
					...(values.stockLocationId && { stockLocationId: parseInt(values.stockLocationId) }),
					...(values.locationId && { locationId: parseInt(values.locationId) }),
					supplierFacilityId: values.supplierFacilityId,
					supplierCompanyId: values.supplierCompanyId,
					totalCost,
					currencyId: prCurrencyId || userCurrencyId,
					status: PURCHASE_REQUEST_STATUSES.requested,
					createdByEmail: currentUser.email,
					...(warrantyAssetId && { warrantyAssetId }),
					...(workOrderId && { workOrderId }),
				};

				this.setState({ creatingPR: true });

				submitAction(purchase_request).then((record) => {
					const updatedPartItems = partItems.map((partItem) => {
						return {
							...partItem,
							supplierPurchaseRequestId: record.id,
						};
					});

					const updatedEquipmentItems = equipmentItems.map((equipmentItem) => {
						return {
							...equipmentItem,
							supplierPurchaseRequestId: record.id,
						};
					});

					createLineItemsBulk(updatedPartItems.concat(updatedEquipmentItems))
						.then(() => {
							if (redirectForwardUrl) {
								history.push(redirectForwardUrl);
							}
							if (onSuccess) {
								onSuccess(record);
							}
						})
						.finally(() => this.setState({ creatingPR: false }));
				});
			}
		});
	};

	hideAddPart = () =>
		this.setState({
			addPartVisible: false,
			currentlyEditingPart: null,
		});

	handleAddPart = (addedParts) => {
		this.hideAddPart();
		this.setState({ addedParts });
	};

	handleEditPart = (currentlyEditingPart) => () => {
		this.setState({
			currentlyEditingPart,
			addPartVisible: true,
		});
	};

	handleRemovePart = (id) => () => {
		this.setState({ addedParts: this.state.addedParts.filter((_) => _.id !== id) });
	};

	hideAddEquipment = () =>
		this.setState({
			addEquipmentVisible: false,
			currentlyEditingEquipment: null,
		});

	handleAddEquipment = (addedEquipments) => {
		this.hideAddEquipment();
		this.setState({ addedEquipments });
	};

	handleEditEquipment = (currentlyEditingEquipment) => () => {
		this.setState({
			currentlyEditingEquipment,
			addEquipmentVisible: true,
		});
	};

	handleRemoveEquipment = (id) => () => {
		this.setState({ addedEquipments: this.state.addedEquipments.filter((_) => _.id !== id) });
	};

	render() {
		const {
			isModal,
			form,
			update,
			create,
			createErrors,
			formData,
			updateErrors,
			currentUser,
			stockLocations,
			fetchMultipleStockLocations,
			fetchStockLocations,
			locations,
			fetchMultipleLocations,
			fetchLocations,
		} = this.props;
		const { formDataInitialized } = this.state;
		const { getFieldDecorator, getFieldValue } = form;
		const isUpdate = formData && formData.id !== undefined;
		const submitAction = isUpdate ? update : create;
		const submitText = isUpdate ? 'Update purchase request' : 'Create purchase request';

		const userCurrencyId = getCurrency({ currentUser }).id;

		if (!formDataInitialized) {
			if (formData.partSupplierPurchaseRequestLineItems) {
				this.setState({
					addedParts: formData.partSupplierPurchaseRequestLineItems.map((lineItem) => ({
						...lineItem,
						partId: nullSafeGet('partId', lineItem),
						vendorId: nullSafeGet('partCatalog.partEquipmentVendor.id', lineItem),
						quantity: lineItem.partQuantity,
						cost: lineItem.partCost,
						// unitCost: (lineItem.partCost || 0) / (lineItem.partQuantity || 1),
						unitCost:
							lineItem.partCost !== undefined
								? lineItem.partCost / (lineItem.partQuantity || 1)
								: undefined,
					})),
				});
			}
			if (formData.equipmentSupplierPurchaseRequestLineItems) {
				this.setState({
					addedEquipments: formData.equipmentSupplierPurchaseRequestLineItems.map((lineItem) => ({
						...lineItem,
						equipmentTypeId: nullSafeGet('equipmentTypeId', lineItem),
						vendorId: nullSafeGet('equipmentCatalog.partEquipmentVendor.id', lineItem),
						quantity: lineItem.equipmentQuantity,
						cost: lineItem.equipmentCost,
						unitCost:
							lineItem.equipmentCost !== undefined
								? lineItem.equipmentCost / (lineItem.equipmentQuantity || 1)
								: undefined,
					})),
				});
			}
			this.setState({ formDataInitialized: true });
		}

		getFieldDecorator('supplierFacilityId', {
			initialValue: formData.supplierFacilityId || nullSafeGet('facility.id', currentUser),
		});
		getFieldDecorator('supplierCompanyId', {
			initialValue:
				formData.supplierCompanyId || nullSafeGet('facility.supplierCompanyId', currentUser),
		});

		getFieldDecorator('id', { initialValue: formData.id });
		getFieldDecorator('status', { initialValue: formData.status || 'requested' });

		const partsColumns = [
			{
				title: 'S.No.',
				render: (_, __, idx) => idx + 1,
			},
			{
				title: 'Name',
				render: (_, record) => nullSafeGetOrElse('part.name', record, '--'),
			},
			{
				title: 'Vendor Name',
				render: (_, record) =>
					nullSafeGetOrElse('partCatalog.partEquipmentVendor.name', record, '--'),
			},
			{
				title: 'Quantity',
				dataIndex: 'quantity',
			},
			{
				title: 'Unit Cost',
				dataIndex: 'unitCost',
				render: (_, record) => {
					const currencyId = nullSafeGetOrElse(
						'partCatalog.partEquipmentVendor.currencyId',
						record,
						userCurrencyId
					);
					return renderCurrencyByCurrencyId(currencyId)(_);
				},
			},
			{
				title: 'Total Cost',
				dataIndex: 'cost',
				render: (_, record) => {
					const currencyId = nullSafeGetOrElse(
						'partCatalog.partEquipmentVendor.currencyId',
						record,
						userCurrencyId
					);
					return renderCurrencyByCurrencyId(currencyId)(_);
				},
			},
			{
				render: (_, record) => (
					<div className="flex flex-row items-center">
						<Button
							type="link"
							icon={<EditOutlined translate="" onClick={this.handleEditPart(record)} />}
						/>
						<MinusCircleOutlined
							className="ml-2 cursor-pointer"
							translate=""
							onClick={this.handleRemovePart(record.id)}
						/>
					</div>
				),
			},
		];

		const equipmentsColumns = [
			{
				title: 'S.No.',
				render: (_, __, idx) => idx + 1,
			},
			{
				title: 'Name',
				render: (_, record) => nullSafeGetOrElse('equipmentType.modelName', record, ''),
			},
			{
				title: 'Vendor Name',
				render: (_, record) =>
					nullSafeGetOrElse('equipmentCatalog.partEquipmentVendor.name', record, '--'),
			},
			{
				title: 'Quantity',
				dataIndex: 'quantity',
			},
			{
				title: 'Unit Cost',
				dataIndex: 'unitCost',
				render: (_, record) => {
					const currencyId = nullSafeGetOrElse(
						'equipmentCatalog.partEquipmentVendor.currencyId',
						record,
						userCurrencyId
					);
					return renderCurrencyByCurrencyId(currencyId)(_);
				},
			},
			{
				title: 'Total Cost',
				dataIndex: 'cost',
				render: (_, record) => {
					const currencyId = nullSafeGetOrElse(
						'equipmentCatalog.partEquipmentVendor.currencyId',
						record,
						userCurrencyId
					);
					return renderCurrencyByCurrencyId(currencyId)(_);
				},
			},
			{
				render: (_, record) => (
					<div className="flex flex-row items-center">
						<Button
							type="link"
							icon={<EditOutlined translate="" onClick={this.handleEditEquipment(record)} />}
						/>
						<MinusCircleOutlined
							className="ml-2 cursor-pointer"
							translate=""
							onClick={this.handleRemoveEquipment(record.id)}
						/>
					</div>
				),
			},
		];

		const shipToLocation = getFieldValue('shipTo') === 'location';

		const atLeastOneEquipmentAdded = !!nullSafeGet('state.addedEquipments.0', this);
		const atLeastOnePartAdded = !!nullSafeGet('state.addedParts.0', this);

		const locationDisabledByEquipment = atLeastOneEquipmentAdded && formData.workOrderId;
		const locatinoDisabledByPart = atLeastOnePartAdded && !formData.workOrderId;
		const addEquipmentDisabled = shipToLocation && !!formData.workOrderId;
		const addPartDisabled = shipToLocation && !formData.workOrderId;

		const partLineItemsCurrency = this.getPartLineItemsCurrency();
		const equipmentLineItemsCurrency = this.getEquipmentLineItemsCurrency();

		return (
			<Form
				layout="vertical"
				onSubmit={(e) => this.handleSubmit(e, submitAction)}
				className="purchaseRequestForm"
			>
				{createErrors.length > 0 ? (
					<Form.Item>
						<Alert message={createErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				{updateErrors.length > 0 ? (
					<Form.Item>
						<Alert message={updateErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				<Form.Item label="Name">
					{getFieldDecorator('name', {
						initialValue: formData.name,
						rules: [{ required: true, message: 'This field is required.' }],
					})(<Input style={{ maxWidth: 640 }} />)}
				</Form.Item>
				<Form.Item label="Description">
					{getFieldDecorator('description', {
						initialValue: formData.description,
					})(<Input.TextArea style={{ maxWidth: 640 }} />)}
				</Form.Item>
				{!formData.disableLocation ? (
					<Form.Item label="Ship to">
						{getFieldDecorator('shipTo', {
							initialValue: formData.locationId ? 'location' : 'stockLocation',
							rules: [{ required: true }],
						})(
							<OWRadioGroup
								valueAccessor={(item) => item.value}
								renderRecord={(item) => item.label}
								items={[
									{
										label: 'Stock Location',
										value: 'stockLocation',
									},
									{
										label: 'Location',
										value: 'location',
										disabled: locationDisabledByEquipment || locatinoDisabledByPart,
										disabledMessage: locationDisabledByEquipment
											? 'Equipment cannot be shipped to a Location with Work Order. Please remove added equipment to select'
											: 'Part cannot be shipped to location without Work Order. Please removed added part(s) to select',
									},
								]}
							/>
						)}
					</Form.Item>
				) : null}
				<Form.Item label={shipToLocation ? 'To Location' : 'To Stock Location'}>
					{shipToLocation
						? getFieldDecorator('locationId', {
								initialValue: formData.locationId,
								rules: [{ required: true, message: 'Ship to location is required.' }],
						  })(
								<OWAsyncSelect
									key={'location'}
									stateSlice={locations}
									valueAccessor={(el) => el.id}
									targetCollectionName="locationsIndex"
									fetchData={(searchText, targetCollectionName) => {
										fetchLocations({ name: searchText }, targetCollectionName);
									}}
									fetchMultiple={(ids, targetCollectionName) => {
										fetchMultipleLocations(ids, targetCollectionName);
									}}
									renderRecord={(l) => (
										<Select.Option key={l.id} value={l.id}>
											<div>{l.name}</div>
											<div style={{ color: 'rgba(0,0,0,0.45)' }}>
												{nullSafeGet('buyerFacility.primaryAddress.streetAddress1', l)},{' '}
												{nullSafeGet('buyerFacility.primaryAddress.city', l)}{' '}
												{nullSafeGet('buyerFacility.primaryAddress.region', l)}
											</div>
										</Select.Option>
									)}
								/>
						  )
						: getFieldDecorator('stockLocationId', {
								initialValue: formData.stockLocationId,
								rules: [{ required: true, message: 'Ship to location is required.' }],
						  })(
								<OWAsyncSelect
									key={'stockLocation'}
									stateSlice={stockLocations}
									valueAccessor={(el) => el.id}
									targetCollectionName="stockLocationDropdown"
									fetchData={(searchText, targetCollectionName) => {
										fetchStockLocations({ name: searchText }, targetCollectionName);
									}}
									fetchMultiple={(ids, targetCollectionName) => {
										fetchMultipleStockLocations(ids, targetCollectionName);
									}}
									renderRecord={(sl) => (
										<Select.Option key={sl.id} value={sl.id}>
											<div>{sl.name}</div>
											<div style={{ color: 'rgba(0,0,0,0.45)' }}>
												{nullSafeGet('address.streetAddress1', sl)},{' '}
												{nullSafeGet('address.city', sl)} {nullSafeGet('address.region', sl)}
											</div>
										</Select.Option>
									)}
								/>
						  )}
				</Form.Item>
				<div className="mt-4">
					<Card
						title={
							<div className="flex flex-row items-center">
								<div>{`Parts Requested`}</div>
								{this.state.addedParts.length > 0 && partLineItemsCurrency && (
									<div className="ml-2 normal-case text-neutral-400">
										{`(Total cost: ${renderCurrencyByCurrencyId(partLineItemsCurrency)(
											this.state.addedParts.reduce((acc, _) => acc + _.cost, 0)
										)})`}
									</div>
								)}
							</div>
						}
						extra={
							<Popover
								content={
									addPartDisabled
										? 'Parts cannot be shipped to a Location without Work Order'
										: null
								}
								trigger="hover"
							>
								<Button
									type="dashed"
									disabled={addPartDisabled}
									onClick={() => this.setState({ addPartVisible: true })}
									style={{ height: 32 }}
								>
									<PlusOutlined translate="" /> Add part
								</Button>
							</Popover>
						}
					>
						{this.state.addedParts.length > 0 ? (
							<Table
								columns={partsColumns}
								showHeader
								rowKey={(el) => el.id}
								dataSource={this.state.addedParts}
								pagination={false}
							/>
						) : (
							<div className="flex flex-row justify-center text-neutral-400">No Part Added</div>
						)}
					</Card>
				</div>
				<div className="mt-4">
					<Card
						title={
							<div className="flex flex-row items-center">
								<div>{`Equipment Requested`}</div>
								{this.state.addedEquipments.length > 0 && equipmentLineItemsCurrency && (
									<div className="ml-2 normal-case text-neutral-400">
										{`(Total cost: ${renderCurrencyByCurrencyId(equipmentLineItemsCurrency)(
											this.state.addedEquipments.reduce((acc, _) => acc + _.cost, 0)
										)})`}
									</div>
								)}
							</div>
						}
						extra={
							<Popover
								content={
									addEquipmentDisabled
										? 'Equipment cannot be shipped to a Location with Work Order'
										: null
								}
								trigger="hover"
							>
								<Button
									type="dashed"
									disabled={addEquipmentDisabled}
									onClick={() => this.setState({ addEquipmentVisible: true })}
									style={{ height: 32 }}
								>
									<PlusOutlined translate="" /> Add Equipment
								</Button>
							</Popover>
						}
					>
						{this.state.addedEquipments.length > 0 ? (
							<Table
								columns={equipmentsColumns}
								showHeader
								rowKey={(el) => el.id}
								dataSource={this.state.addedEquipments}
								pagination={false}
							/>
						) : (
							<div className="flex flex-row justify-center text-neutral-400">
								No Equipment Added
							</div>
						)}
					</Card>
				</div>
				{/* <Form.Item> */}
				<div className={`mt-4 flex ${isModal ? 'flex-row-reverse' : 'flex-row'} justify-start`}>
					<Button
						type="primary"
						htmlType="submit"
						loading={this.state.creatingPR}
						className={`contactForm__button ${isModal ? 'ml-4' : ''}`}
					>
						{submitText}
					</Button>
					<span className={isModal ? '' : 'ml-4'}>
						{isModal ? (
							<Button onClick={this.props.onSuccess}>Cancel</Button>
						) : (
							<BackButton buttonText="Cancel" />
						)}
					</span>
				</div>
				{/* </Form.Item> */}
				{this.state.addPartVisible && (
					<AddPartInPRForm
						allParts={this.state.addedParts}
						partData={this.state.currentlyEditingPart}
						onSuccess={this.handleAddPart}
						onCancel={this.hideAddPart}
					/>
				)}
				{this.state.addEquipmentVisible && (
					<AddEquipmentInPRForm
						allEquipments={this.state.addedEquipments}
						equipmentData={this.state.currentlyEditingEquipment}
						onSuccess={this.handleAddEquipment}
						onCancel={this.hideAddEquipment}
					/>
				)}
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	formData: ownProps.formData,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,

	createErrors: state.parts.createErrors,
	updateErrors: state.parts.updateErrors,
	creating: state.purchase_requests.creating,
	updating: state.purchase_requests.updating,
	stockLocations: state.stock_locations,
	locations: state.locations,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	update: (entity) => dispatch(purchaseRequestsRestCrudThunksForSupplier.update(entity)),
	create: (entity) => dispatch(purchaseRequestsRestCrudThunksForSupplier.create(entity)),
	createLineItemsBulk: (entities) =>
		dispatch(purchaseRequestLineItemsRestCrudThunksForSupplier.createBulk(entities)),
	fetchMultipleStockLocations: (ids, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchStockLocations: (params, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.read(params, targetCollectionName)),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchLocations: (params, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForSupplier.read(params, targetCollectionName)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Form.create<PartFormProps>()(PurchaseRequestForm))
);

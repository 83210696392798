import * as React from 'react';
import { FC, useState } from 'react';
import { UserName } from './UserNameComponent';
import { Avatar, Button, Col, Modal, Row } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';

const modes = [];

interface UserNameListProps {
	contacts: any;
	listMode?: 'inline' | 'default';
	mode?: 'card' | 'inline' | 'container' | 'avatar';
	displayNumber?: any;
	title?: any;
	size?: 'default' | 'large';
	userType?: 'buyer' | 'supplier';
	nameFormat?: 'given family' | 'family, given' | 'name';
	allowRemove?: boolean;
	onRemove?: any;
}

export const UserNameList: FC<UserNameListProps> = ({
	contacts,
	mode,
	displayNumber = 3,
	title = 'User List',
	size = 'default',
	userType = 'buyer',
	nameFormat = 'nameGiven nameFamily',
	listMode = 'default',
	allowRemove = false,
	onRemove = () => {},
}) => {
	const inlineList = contacts.map((contact, i) => (
		<Row style={{ marginBottom: 8, justifyContent: 'space-between', alignContent: 'center' }}>
			<UserName
				contact={contact}
				mode={'card'}
				userType={userType}
				nameFormat={nameFormat}
				showAvatar={false}
				allowHover={false}
			/>
			{allowRemove ? (
				<div style={{ display: 'flex', alignItems: 'center' }} onClick={() => onRemove(contact)}>
					<MinusCircleOutlined />
				</div>
			) : null}
		</Row>
	));
	const [showModal, setShowModal] = useState(false);

	const onShowMore = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setShowModal(true);
	};
	const onClose = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setShowModal(false);
	};

	return listMode === 'inline' ? (
		<>{inlineList}</>
	) : (
		<>
			<Modal
				title={title}
				visible={showModal}
				closable={true}
				onCancel={onClose}
				footer={null}
				bodyStyle={{
					display: 'flex',
					flexDirection: 'column',
					gap: 8,
				}}
			>
				{contacts.map((contact) => (
					<UserName
						contact={contact}
						mode={'card'}
						allowHover={true}
						userType={userType}
						nameFormat={nameFormat}
					/>
				))}
			</Modal>
			<span onClick={onShowMore}>
				{contacts.length > 1 ? (
					<Avatar.Group maxCount={displayNumber} maxPopoverTrigger={'focus'}>
						{contacts.map((contact, i) => (
							<span style={{ marginRight: '-8px' }}>
								<UserName
									contact={contact}
									mode={'avatar'}
									allowHover={true}
									placement={'top'}
									userType={userType}
									nameFormat={nameFormat}
								/>
							</span>
						))}
					</Avatar.Group>
				) : contacts.length > 0 ? (
					<UserName
						contact={contacts[0]}
						mode={mode}
						allowHover={true}
						userType={userType}
						nameFormat={nameFormat}
					/>
				) : null}
			</span>
		</>
	);
};

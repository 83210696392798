import FetchUtils, { parseJSON } from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { PLANNED_MAINTENANCE_SCHEDULE_GROUPS_CRUD_ACTION_CREATORS } from '../actions/planned_maintenance_schedule_groups_actions';
import { logoutSuccess } from '../actions/session_actions';
import { ROLE_TYPES } from '../utils/DataConstants';
import RestCrudThunks from './restCrudThunksGenerator';

export const plannedMaintenanceSchedulesGroupedRestCrudThunksForBuyer = new RestCrudThunks(
	'planned_maintenance_schedules/grouped',
	'planned_maintenance_schedule',
	'plannedMaintenanceScheduleGrouped',
	'plannedMaintenanceSchedulesGrouped',
	PLANNED_MAINTENANCE_SCHEDULE_GROUPS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'pm/'
);
export const plannedMaintenanceSchedulesGroupedRestCrudThunksForSupplier = new RestCrudThunks(
	'planned_maintenance_schedules/grouped',
	'planned_maintenance_schedule',
	'plannedMaintenanceSchedule',
	'plannedMaintenanceSchedules',
	PLANNED_MAINTENANCE_SCHEDULE_GROUPS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'pm/'
);

export const getPlannedMaintenanceName = (userType) => (name) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${userType}/pm/planned_maintenance_schedules?exactTitle=${name}`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

const fetchUtils = new FetchUtils();
const baseUrl = getBackendUri();
const getGroupedMaintenanceSchedules =
	(roleType) =>
	(params, targetCollectionName = null, pagination = null, sorting = null, filters = null) => {
		const apiUrl = `/api/v1/${roleType}/pm/planned_maintenance_schedules/grouped`;
		// if pagination present, transform into limit/offset format and add to query params
		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}
		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(
					PLANNED_MAINTENANCE_SCHEDULE_GROUPS_CRUD_ACTION_CREATORS.fetchStart(targetCollectionName)
				);

				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(
								PLANNED_MAINTENANCE_SCHEDULE_GROUPS_CRUD_ACTION_CREATORS.fetchError(
									d.message,
									targetCollectionName
								)
							);
							reject(d.message);
						} else {
							dispatch(
								PLANNED_MAINTENANCE_SCHEDULE_GROUPS_CRUD_ACTION_CREATORS.fetchSuccess(
									d.data,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(
							PLANNED_MAINTENANCE_SCHEDULE_GROUPS_CRUD_ACTION_CREATORS.fetchError(
								d.message,
								targetCollectionName
							)
						);
						reject(d.message);
					});
			});
		};
	};

export const getGroupedMaintenanceSchedulesForBuyer = getGroupedMaintenanceSchedules(
	ROLE_TYPES.BUYER
);

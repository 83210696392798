import * as React from 'react';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Upload, message } from 'antd';
import { connect } from 'react-redux';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { getDefaultFileList } from '../../utils/FileAccessUtils';
import { EmptyState } from '../empty_state/EmptyState';
import { retrieveCachedUserDetails } from '../../thunks/session_thunks';

export function fileUploadValidation(file, fileMimeType) {
	const fileMimeTypes = [fileMimeType];
	const isFileType = fileMimeTypes.indexOf(file.type) !== -1;
	if (!isFileType) {
		message.error(`Uploaded file is not of the expected type: ${fileMimeType}`);
	}
	return isFileType;
}

class FileUploader extends React.Component<any, any> {
	constructor(props) {
		super(props);
		const defaultFileList = getDefaultFileList(props.defaultFileList, props.userType);
		this.state = {
			uploadLoading: false,
			fileList: defaultFileList,
		};
	}

	handleUploadSuccess = (info) => {
		const { handleUploadSuccess, handleFileListChange, userType } = this.props;
		handleUploadSuccess(nullSafeGet('data', info));
	};

	render() {
		const { quoteId, buttonType, uploaderType, emptyState, multiple, beforeUpload, userType } =
			this.props;
		const { fileList } = this.state;

		function beforePDFUpload(file) {
			const pdfMimeTypes = ['application/pdf'];
			const isPDF = pdfMimeTypes.indexOf(file.type) !== -1;
			if (!isPDF) {
				message.error('You can only upload PDF files!');
			}
			const isLt2M = file.size / 1024 / 1024 < 4;
			if (!isLt2M) {
				message.error('PDF file size must smaller than 4MB!');
			}
			return isPDF && isLt2M;
		}

		const BACKEND_URI = getBackendUri();
		const token = retrieveCachedUserDetails(['token']).token;
		const uploadProps = {
			action: `${BACKEND_URI}/api/v1/${userType}/quote/proposals/proposal_pdf/upload/${quoteId}`,
			onSuccess: this.handleUploadSuccess,
			fileList: fileList,
			multiple: multiple,
			beforeUpload: beforePDFUpload,
			showUploadList: { showPreviewIcon: true, showRemoveIcon: true },
			headers: { 'X-Auth-Token': token },
		};

		const uploadReadOnlyProps = {
			action: `${BACKEND_URI}/api/v1/${userType}/quote/proposals/proposal_pdf/upload/${quoteId}`,
			onSuccess: this.handleUploadSuccess,
			fileList: fileList,
			multiple: multiple,
			beforeUpload: beforePDFUpload,
			showUploadList: { showPreviewIcon: true, showRemoveIcon: false },
			headers: { 'X-Auth-Token': token },
		};

		const uploadReadAndRemoveProps = {
			action: `${BACKEND_URI}/api/v1/${userType}/quote/proposals/proposal_pdf/upload/${quoteId}`,
			onSuccess: this.handleUploadSuccess,
			fileList: fileList,
			multiple: multiple,
			beforeUpload: beforePDFUpload,
			showUploadList: { showPreviewIcon: true, showRemoveIcon: true },
			headers: { 'X-Auth-Token': token },
		};

		return uploaderType === 'dragger' ? (
			<Upload.Dragger {...uploadProps}>
				<div style={{ marginTop: 32, height: 180 }}>
					<p className="ant-upload-drag-icon">
						<InboxOutlined />
					</p>
					<p className="ant-upload-text">Click or drag files to this area to upload</p>
					<p className="ant-upload-hint">Support for single or bulk upload.</p>
				</div>
			</Upload.Dragger>
		) : uploaderType === 'readOnly' ? (
			<Upload {...uploadReadOnlyProps}>
				{fileList && fileList.length === 0 ? (
					emptyState ? (
						emptyState
					) : (
						<EmptyState
							height={160}
							graphic={
								<img
									style={{ marginBottom: 8 }}
									src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
								/>
							}
							headline={"Nothing's here."}
							body={
								<div style={{ textAlign: 'center' }}>
									<div style={{ maxWidth: 488, marginBottom: 16 }}>
										No files have been uploaded yet.
									</div>
								</div>
							}
						/>
					)
				) : null}
			</Upload>
		) : uploaderType === 'readAndRemove' ? (
			<Upload {...uploadReadAndRemoveProps}></Upload>
		) : (
			<Upload {...uploadProps}>
				<Button type={buttonType}>
					<UploadOutlined /> Upload Quote
				</Button>
			</Upload>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	userType: state.session.userType,
	buttonType: ownProps.buttonType,
	emptyState: ownProps.emptyState,
	quoteId: ownProps.quoteId,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FileUploader);

import { ContactAvatar } from '../contact_avatar/ContactAvatar';
import * as React from 'react';
import { Col, Row, Select } from 'antd';
import { OptionProps } from 'antd/es/mentions';

const Option = Select.Option;

export default class UserOptionComponent extends React.Component<any, OptionProps> {
	constructor(props) {
		super(props);
	}
	render() {
		const { contact, status, search } = this.props;
		let user = contact;

		let searchName = user.nameGiven
			.concat(' ', user.nameFamily)
			.toLowerCase()
			.includes(search.toLowerCase());
		return (
			<Row>
				<Col style={{ alignContent: 'center' }}>
					<ContactAvatar
						hidePopover={true}
						placement="bottomRight"
						userType="buyer"
						contact={user}
						className={'contactAvatar'}
						style={{
							marginTop: '4px',
							backgroundColor: user.contactType == 'supplier' ? '#48a7bd' : '#7c48bd',
						}}
					/>
				</Col>
				<Col style={{ alignContent: 'center' }}>
					{!searchName ? (
						<Col style={{ display: 'inline-block', paddingLeft: '12px' }}>
							<div style={{ display: 'block' }}>
								<div style={{ fontSize: '0.95em', color: '#606060' }}>
									{user.nameGiven} {user.nameFamily}
								</div>
								<div style={{ fontSize: '1em', color: 'black' }}>{user.email}</div>
							</div>
						</Col>
					) : (
						<Col style={{ display: 'inline-block', paddingLeft: '12px' }}>
							<div style={{ display: 'block' }}>
								<div style={{ fontSize: '1em', color: 'black' }}>
									{user.nameGiven} {user.nameFamily}
								</div>
								<div style={{ fontSize: '0.95em', color: '#606060' }}>{user.email}</div>
							</div>
						</Col>
					)}
				</Col>
				<Col style={{ marginLeft: 'auto' }}>{status}</Col>
			</Row>
		);
	}
}

import React from 'react';
import { Sorter } from '../sorters/Sorters';
import {
	morePipelineFiltersBuyer,
	pipelineFiltersBuyer,
} from '../config/pipelineFilters/pipelineFilters';
import { WORK_ORDER_SORTERS } from '../config/sorters/sorters';
import { PipelineFilter } from '../pipelineFilters/pipelineFilters';
import SearchFilter from '../config/search';
import { Col, Row } from 'antd';
import NoAddWorkOrderRightActions from './noAddWorkOrderRightActions';
import Filters from '../filters/filters';
import ClearFilter from '../../clear_filters/ClearFilters';
import SelectedFilters from '../../selected_filters_pills/SelectedFilters';
import SaveFiltersActions from '../../saved_filter_views/SaveFiltersActions';
import { VIEW_ENTITY_TYPES } from '../../../utils/DataConstants';

export default function NoAddWorkOrderHeader({
	sorters,
	filterConfig,
	applySorting,
	applyFilters,
	filters,
	currentUser,
	switchDisplayMode,
	isDisplayModeActive,
	downloadCSV,
	counts,
	clearFilters,
	preAppliedFilters,
	targetCollectionName,
	userType,
}) {
	const filterConfiguration = filterConfig || {};

	return (
		<div style={{ margin: '16px 8px', display: 'grid', rowGap: '12px' }}>
			<PipelineFilter
				items={pipelineFiltersBuyer()}
				subItems={morePipelineFiltersBuyer()}
				onChange={applyFilters}
				mode="multiple"
				value={filters}
				counts={counts}
			/>
			<Row justify="space-between">
				<Col xl={18} xxl={16} style={{ display: 'flex', alignItems: 'flex-start', gap: '8px' }}>
					<Filters
						filterConfig={filterConfiguration.filters}
						applyFilters={applyFilters}
						applySorting={applySorting}
						filters={filters}
						clearFilters={clearFilters}
						targetCollectionName={targetCollectionName}
						userType={userType}
						sorters={sorters}
						preAppliedFilters={preAppliedFilters}
					/>
				</Col>
				<Col
					xl={6}
					xxl={8}
					style={{
						textAlign: 'right',
						display: 'flex',
						alignItems: 'flex-start',
						justifyContent: 'flex-end',
						gap: '8px',
					}}
				>
					<NoAddWorkOrderRightActions
						downloadCSV={downloadCSV}
						switchDisplayMode={switchDisplayMode}
						isDisplayModeActive={isDisplayModeActive}
						currentUser={currentUser}
					/>
				</Col>
			</Row>
			<SelectedFilters
				updateFilters={applyFilters}
				filterValues={filters}
				filterConfig={filterConfiguration.filters}
			/>
		</div>
	);
}

import React, { FC, useMemo } from 'react';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import InfoWithKeyValuePair from './InfoWithKeyValuePair';

const DepreciationParametersDisplay: FC<any> = ({ record }): React.ReactElement => {
	const details = useMemo(
		() => ({
			...(nullSafeGet('salvageValue', record) && {
				'Salvage Value': parseFloat(record.salvageValue),
			}),
			...(nullSafeGet('usefulLifeInMonths', record) && {
				'Useful Life in Months': record.usefulLifeInMonths,
			}),
			...(nullSafeGet('depreciationRate', record) && {
				'Depreciation Rate': `${record.depreciationRate}%`,
			}),
		}),
		[record]
	);

	return <InfoWithKeyValuePair details={details} />;
};

export default DepreciationParametersDisplay;

import React from 'react';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { HolderOutlined } from '@ant-design/icons';

export default function ProblemTypeItemRow({ item, index, removeIcon = false }) {
	return (
		<div className=" draggable-item PrivateNetworkLocationsPage__secondaryEntity__list__item flex items-center justify-start">
			<div className="flex basis-2/4 items-center">
				{!removeIcon && <HolderOutlined className="icon" translate={undefined} />}
				<div
					title={nullSafeGet('supplierFacility.displayName', item)}
					className=" PrivateNetworkLocationsPage__secondaryEntity__list__item__text text-ellipsis line-clamp-1"
				>
					{nullSafeGet('supplierFacility.displayName', item)}
				</div>
			</div>
			<div className="ml-16 basis-2/4 opacity-50">{`Rank ${index + 1}`}</div>
		</div>
	);
}

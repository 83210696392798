import * as React from 'react';
import { connect } from 'react-redux';
import { Layout, Row, Col, Card } from 'antd';
import { withRouter } from 'react-router';
import { EmptyState } from '../empty_state/EmptyState';
import { TextButton } from '../text_button/TextButton';
import { resendConfirmation } from '../../thunks/session_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;

class SupplierNeedEmailAuthenticationPage extends React.Component<any, any> {
	resendConfirmation = () => {
		const { resendEmailAuthentication } = this.props;
		resendEmailAuthentication(ROLE_TYPES.SUPPLIER);
	};

	render() {
		return (
			<Content className="signUpCompletionPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited supplier need email auth page" />
				{/*<BackTop/>*/}
				<Row align="middle" style={{ height: '100vh', margin: '0 -8px' }} gutter={16}>
					<Col span={12} offset={6}>
						<Card>
							<EmptyState
								graphic={
									<img
										style={{ marginBottom: 8 }}
										src="https://s3.amazonaws.com/mock-data-assets/categories/images/letter owl.svg"
									/>
								}
								headline={"You haven't activated your account yet."}
								body={
									<div style={{ textAlign: 'center' }}>
										<div style={{ maxWidth: 560, marginBottom: 16 }}>
											Please check your email. We sent you a note with an activation link.
										</div>
										<div>
											Can't find the activation email?{' '}
											<TextButton onClick={this.resendConfirmation}>
												Click here and we'll send you another one.
											</TextButton>
										</div>
									</div>
								}
							/>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({});
const mapDispatchToProps = (dispatch) => ({
	resendEmailAuthentication: (role) => dispatch(resendConfirmation(role)()),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(SupplierNeedEmailAuthenticationPage)
);

import * as React from 'react';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Input, Alert, Button } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { supplierFacilitiesRestCrudThunksForSupplier } from '../../thunks/supplier_facilities_thunks';

const FormItem = Form.Item;
const style = require('./EditSupplierDescriptionForm.less');

interface EditSupplierDescriptionFormProps extends FormComponentProps {
	loading: boolean;
	description: string;
	updateSupplier: any;
	successCallback: any;
	cancelCallback: any;
	supplierFacility: any;

	errors: string[];
}

class EditSupplierDescriptionForm extends React.Component<EditSupplierDescriptionFormProps, any> {
	handleSubmit = (e) => {
		const { updateSupplier, successCallback, supplierFacility, form } = this.props;
		e.preventDefault();
		form.validateFields((err, values) => {
			if (!err) {
				const updatedSupplier = {
					...supplierFacility,
					about: values.about,
				};
				updateSupplier(updatedSupplier).then((resp) => successCallback(resp));
			}
		});
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { loading, errors, cancelCallback, description } = this.props;
		return (
			<Form onSubmit={this.handleSubmit} className="editSupplierDescriptionForm">
				{errors && errors.length > 0
					? errors.map((msg, idx) => (
							<FormItem key={idx}>
								<Alert message={msg} type="error" />
							</FormItem>
					  ))
					: null}
				<FormItem>
					{getFieldDecorator('about', {
						rules: [{ required: true, message: 'Please provide a description of your business!' }],
						initialValue: description,
					})(
						<Input.TextArea
							autoSize={true}
							placeholder="Enter a short description of your business."
						/>
					)}
				</FormItem>
				<FormItem>
					<Button type="primary" htmlType="submit" loading={loading}>
						Update description
					</Button>
					<Button style={{ marginLeft: 16 }} onClick={cancelCallback}>
						Cancel
					</Button>
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	loading: state.supplier_facilities.loading,
	errors: state.supplier_facilities.updateErrors,
	description: ownProps.description,
	successCallback: ownProps.successCallback,
	cancelCallback: ownProps.cancelCallback,
	supplierFacility: ownProps.supplierFacility,
});

const mapDispatchToProps = (dispatch) => ({
	updateSupplier: (supplierFacility) =>
		dispatch(supplierFacilitiesRestCrudThunksForSupplier.update(supplierFacility)),
});

export default withRouter<any, any>(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<EditSupplierDescriptionFormProps>()(EditSupplierDescriptionForm))
);

import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Input, InputNumber, Spin } from 'antd';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

const InlineEditableText: FC<any> = ({
	value,
	onChange,
	inputType = 'text',
}): React.ReactElement => {
	const [input, setInput] = useState(value);
	const [editMode, setEditMode] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => setInput(value), [value]);

	const onInputChange = useCallback((val) => setInput(val), []);

	const _enableEditMode = useCallback(() => setEditMode(true), []);
	const _disableEditMode = useCallback(() => setEditMode(false), []);

	const onValueChange = useCallback(() => {
		setLoading(true);
		onChange(input).finally(() => {
			setLoading(false);
			_disableEditMode();
		});
	}, [_disableEditMode, input, onChange]);

	const inputField: React.ReactElement = useMemo(() => {
		switch (inputType) {
			case 'number':
				return <InputNumber value={input} onChange={onInputChange} />;
			default:
				return <Input value={input} onChange={onInputChange} />;
		}
	}, [input, inputType, onInputChange]);

	return editMode ? (
		<div className="flex flex-row items-center">
			<div>{inputField}</div>
			{loading ? (
				<div className="ml-1">
					<Spin />
				</div>
			) : (
				<>
					<div className="ml-1">
						<Button onClick={onValueChange} type="link" icon={<CheckOutlined translate="" />} />
					</div>
					<div className="ml-1">
						<Button onClick={_disableEditMode} type="link" icon={<CloseOutlined translate="" />} />
					</div>
				</>
			)}
		</div>
	) : (
		<div className="flex flex-row items-center">
			<div>{value}</div>
			<div className="ml-1">
				<Button onClick={_enableEditMode} type="link" icon={<EditOutlined translate="" />} />
			</div>
		</div>
	);
};

export default InlineEditableText;

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { Layout, Spin, Row, Col, Card, Table } from 'antd';
import { floatToPercentageString } from '../../utils/DataFormatterUtils';

import {
	collectAllParentsFromTreeData,
	collectObjectsWithValidPropFromTreeData,
	filterTreeData,
	flattenTreeData,
	nullSafeGet,
	nullSafeGetOrElse,
	removeEmptyChildrenFromTreeData,
	sortTreeData,
} from '../../utils/DataAccessUtils';
import { BarChart } from '../charts/BarChart';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { withRouter } from 'react-router';
import AnalyticsControlBar from '../analytics_control_bar/AnalyticsControlBar';
import {
	clearWorkLocationFilters,
	updateWorkLocationFilters,
} from '../../actions/work_analytics_actions';
import { EmptyState } from '../empty_state/EmptyState';
import {
	fetchWorkOrderCountByLocationWithRegions,
	fetchWorkOrderCountByMonth,
} from '../../thunks/work_analytics_thunks';
import {
	workOrderPrioritiesRestCrudThunksForBuyer,
	workOrderPrioritiesRestCrudThunksForSupplier,
} from '../../thunks/work_order_priorities_thunks';
import { CSV_EXPORT_DEFAULTS, ROLE_TYPES } from '../../utils/DataConstants';
import { ExportToCsv } from 'export-to-csv';

const DataSet = require('@antv/data-set');
const { Content } = Layout;
const workOrderCountByLocationChartConfig = {
	xAxisName: 'name',
	yAxisName: 'subTotal',
	position: 'name*subTotal',
	fillArea: false,
	color: 'name',
	cols: {
		fieldName: { alias: 'Location' },
		subTotal: { alias: '# Work Orders' },
	},
	yAxisLabel: {
		textStyle: {
			fontSize: '14',
			fontFamily: 'Roboto',
			fontWeight: 400,
			fill: 'rgba(0,0,0,0.65)',
		},
	},
	xAxisLabel: {
		textStyle: {
			fontSize: '14',
			fontFamily: 'Roboto',
			fontWeight: 400,
			fill: 'rgba(0,0,0,0.65)',
		},
	},
	transformer: (data) => {
		const ds = new DataSet();
		const dv = ds.createView().source(data);
		dv.transform({
			type: 'sort-by',
			fields: ['subTotal'],
			order: 'ASC', // default is ASC,DESC is reversed order.
		});
		return dv;
	},
};

const defaultSortOrder: 'ascend' | 'descend' = 'descend';
const tooltipTemplate = `
	<li data-index={index}>
			<!-- The marker for each record -->
			<span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>
			{name}: {value} work orders 
	</li>`;

const CSV_TITLE = 'Work Analytics by Locations';

const exportReduceFunction = (obj) => ({
	name: obj.name,
	total: nullSafeGetOrElse('workOrderCounts.subTotal', obj, 0),
	percentage: floatToPercentageString(
		nullSafeGetOrElse('workOrderCounts.percentageOfTotal', obj, 0) / 100
	),
});

const WorkAnalyticsOverviewLocationsPage: FC<any> = ({
	fetchWorkOrderCountsByLocation,
	analytics,
	saveLocationFilters,
	clearAllLocationFilters,
	accountActivated,
}): React.ReactElement => {
	const [initialLoading, setInitialLoading] = useState(true);
	const [search, setSearch] = useState('');
	const [filteredTreeData, setFilteredTreeData] = useState([]);
	const [locationIds, setLocationIds] = useState([]);

	useEffect(() => {
		if (analytics.locationFilters.startDate && analytics.locationFilters.endDate) {
			fetchWorkOrderCountsByLocation(analytics.locationFilters).then(() =>
				setInitialLoading(false)
			);
		}
	}, [analytics.locationFilters]);

	const { workOrderCountByLocation, locationFilters } = analytics;

	const filterByLocationIds = useCallback(
		(allData) => {
			return locationIds.length > 0
				? filterTreeData(allData, (obj) => locationIds.includes(obj.id))
				: allData;
		},
		[locationIds]
	);

	const woCountByLocationDisplayData = useMemo(() => {
		const woCountByLocation = nullSafeGetOrElse('workOrderCountByLocation', analytics, []);

		const displayData = !!search
			? filteredTreeData
			: filterByLocationIds(nullSafeGetOrElse('data', woCountByLocation, []));
		return sortTreeData(
			displayData,
			(a, b) =>
				parseFloat(nullSafeGetOrElse('workOrderCounts.subTotal', b, 0)) -
				parseFloat(nullSafeGetOrElse('workOrderCounts.subTotal', a, 0))
		);
	}, [analytics, filterByLocationIds, filteredTreeData, search]);

	const onDownloadAnalytics = useCallback(() => {
		const csvExporter = new ExportToCsv({
			...CSV_EXPORT_DEFAULTS,
			filename: CSV_TITLE,
			title: CSV_TITLE,
			headers: ['Location', 'Total Workorders', 'Percentage'],
		});
		csvExporter.generateCsv(flattenTreeData(woCountByLocationDisplayData, exportReduceFunction));
	}, [woCountByLocationDisplayData]);

	const onSearch = useCallback(
		(e) => {
			const woCountsByLocation = nullSafeGetOrElse('workOrderCountByLocation.data', analytics, []);

			const search = e.target.value;
			const allData = filterByLocationIds(woCountsByLocation);

			setFilteredTreeData(
				!!search
					? filterTreeData(allData, (obj) => obj.name.toLowerCase().includes(search.toLowerCase()))
					: []
			);
			setSearch(search);
		},
		[analytics, filterByLocationIds]
	);

	const removeLocationIdsIdsAndUpdateFilter = (filters) => {
		const { locationIds, ...otherFilters } = filters;
		setLocationIds(locationIds || []);
		saveLocationFilters(otherFilters);
	};

	const workOrderCountByLocationData = useMemo(
		() =>
			collectObjectsWithValidPropFromTreeData(woCountByLocationDisplayData, 'workOrderCounts')
				.map((el) => ({
					...el,
					subTotal: parseFloat(el.workOrderCounts.subTotal),
					percentageOfTotal: parseFloat(el.workOrderCounts.percentageOfTotal),
				}))
				.sort((a, b) => parseFloat(a.subTotal) - parseFloat(b.subTotal)),
		[woCountByLocationDisplayData]
	);

	const workOrderCountByLocationColumns = useMemo(
		() => [
			{
				title: 'Location',
				dataIndex: 'name',
				key: 'name',
				render: (text) => (
					<Ellipsis tooltip={true} style={{ display: 'inline' }} length={28}>
						{text}
					</Ellipsis>
				),
			},
			{
				title: '# Work Orders',
				dataIndex: ['workOrderCounts', 'subTotal'],
				key: 'totalSpend',
				defaultSortOrder: defaultSortOrder,
				sorter: (a, b) =>
					nullSafeGetOrElse('workOrderCounts.subTotal', a, 0) -
					nullSafeGetOrElse('workOrderCounts.subTotal', b, 0),
				render: (amount) => amount,
			},
			{
				title: '% of Total',
				dataIndex: ['workOrderCounts', 'percentageOfTotal'],
				key: 'percentageOfTotal',
				render: (num) => floatToPercentageString((num || 0) / 100),
				width: 100,
			},
		],
		[]
	);

	return (
		<Content
			className="analyticsOverviewLocationsPage"
			style={{ padding: '0 0.5em', position: 'relative' }}
		>
			{/*<BackTop/>*/}
			{accountActivated ? (
				[
					<Row key={1} style={{ margin: '0.5em -8px' }} gutter={16}>
						<Col span={24}>
							<div
								style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
							>
								<div></div>
								<AnalyticsControlBar
									updateFilters={removeLocationIdsIdsAndUpdateFilter}
									clearFilters={clearAllLocationFilters}
									supplierFacilityIds={locationFilters.supplierFacilityIds}
									locationIds={locationIds}
									startDate={locationFilters.startDate}
									endDate={locationFilters.endDate}
									isPM={locationFilters.isPM}
									showDownload
									onDownload={onDownloadAnalytics}
									showSearch
									search={search}
									onSearch={onSearch}
									filterConfig={{
										workCompletedAt: true,
										problemTypes: true,
									}}
								/>
							</div>
						</Col>
					</Row>,
					<>
						<Row key={2} style={{ margin: '0.5em -8px' }} gutter={16}>
							<Col span={24}>
								<Card>
									<Spin spinning={workOrderCountByLocation.loading || initialLoading}>
										{woCountByLocationDisplayData.length > 0 ? (
											<Table
												columns={workOrderCountByLocationColumns}
												rowKey={(el) => el.id}
												dataSource={removeEmptyChildrenFromTreeData(woCountByLocationDisplayData)}
												expandable={{
													...(!!search && {
														expandedRowKeys: collectAllParentsFromTreeData(
															filteredTreeData,
															(_) => _.id
														),
													}),
												}}
											/>
										) : (
											<EmptyState
												height={120}
												headline={'No work orders found'}
												body={
													<div style={{ maxWidth: 488, textAlign: 'center' }}>
														We didn't find any work orders. Try changing your filters or expanding
														the selected time period.
													</div>
												}
											/>
										)}
									</Spin>
								</Card>
							</Col>
						</Row>
						<Row key={3} style={{ margin: '0.5em -8px' }} gutter={16}>
							<Col span={24}>
								<Card bodyStyle={{ padding: '17.5px 24px' }}>
									<h5>New Work Order Count by Location</h5>
									<Spin spinning={workOrderCountByLocation.loading || initialLoading}>
										{workOrderCountByLocationData && workOrderCountByLocationData.length > 0 ? (
											<BarChart
												height={workOrderCountByLocationData.length * 32}
												chartPadding={[40, 40, 40, 200]}
												data={workOrderCountByLocationData}
												showTooltipTitle={false}
												tooltipItemTemplate={tooltipTemplate}
												xAxisName={nullSafeGet('xAxisName', workOrderCountByLocationChartConfig)}
												yAxisName={nullSafeGet('yAxisName', workOrderCountByLocationChartConfig)}
												xAxisLabel={nullSafeGet('xAxisLabel', workOrderCountByLocationChartConfig)}
												yAxisLabel={nullSafeGet('yAxisLabel', workOrderCountByLocationChartConfig)}
												fillArea={nullSafeGet('fillArea', workOrderCountByLocationChartConfig)}
												cols={nullSafeGet('cols', workOrderCountByLocationChartConfig)}
												color={nullSafeGet('color', workOrderCountByLocationChartConfig)}
												position={nullSafeGet('position', workOrderCountByLocationChartConfig)}
											/>
										) : (
											<EmptyState
												height={120}
												headline={'No work orders found'}
												body={
													<div style={{ maxWidth: 488, textAlign: 'center' }}>
														We didn't find any work orders. Try changing your filters or expanding
														the selected time period.
													</div>
												}
											/>
										)}
									</Spin>
								</Card>
							</Col>
						</Row>
					</>,
				]
			) : (
				<EmptyState
					graphic={
						<img
							style={{ marginBottom: 8 }}
							src="https://s3.amazonaws.com/mock-data-assets/categories/images/letter owl.svg"
							alt="You haven't activated your account yet."
						/>
					}
					headline={"You haven't activated your account yet."}
					body={
						<div style={{ textAlign: 'center' }}>
							<div style={{ maxWidth: 560, marginBottom: 16 }}>
								Please check your email. We sent you a note with an activation link.
							</div>
						</div>
					}
				/>
			)}
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	accountActivated: state.session.accountActivated,
	workOrderPriorities: state.work_order_priorities,
	currentUser: state.session.currentUser,
	analytics: state.work_analytics,
	userType: (state as any).session.userType,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	saveLocationFilters: (filters) => dispatch(updateWorkLocationFilters(filters)),
	clearAllLocationFilters: () => dispatch(clearWorkLocationFilters()),
	fetchWorkOrderPriorities: (params, filters = null, pagination = null, sorting = null) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? workOrderPrioritiesRestCrudThunksForSupplier.read(params, filters, pagination, sorting)
				: workOrderPrioritiesRestCrudThunksForBuyer.read(params, filters, pagination, sorting)
		),
	fetchWorkOrderCountsByLocation: (params, filters = null, pagination = null, sorting = null) =>
		dispatch(
			fetchWorkOrderCountByLocationWithRegions(ownProps.userType)(
				params,
				filters,
				pagination,
				sorting
			)
		),
	fetchWorkOrderCountsByMonth: (params, filters = null, pagination = null, sorting = null) =>
		dispatch(fetchWorkOrderCountByMonth(ownProps.userType)(params, filters, pagination, sorting)),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(WorkAnalyticsOverviewLocationsPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

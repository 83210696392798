import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Divider, Popover, Select, Space } from 'antd';
import React, { FC, useMemo, useState } from 'react';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { nullSafeGet } from '../../utils/DataAccessUtils';

type Props = {
	fetchData?: any;
	targetCollectionName?: any;
	initialPagination?: any;
	sortBy?: any;
	additionalFilters?: any;
	searchText?: any;
	renderRecord: any;
	warrantySupplierId: any;
	onChange: any;
	value: any;
};

const SupplierSelectionWorkOrderSelect: FC<any> = (props: Props) => {
	const [showingAll, setShowingAll] = useState(false);
	const [showToggle, setShowToggle] = useState(true);
	return (
		<OWAsyncSelect
			{...props}
			fetchData={(
				searchText,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection = false
			) =>
				props
					.fetchData(
						searchText,
						targetCollectionName,
						pagination,
						sorting,
						showingAll ? {} : filters,
						addToTargetCollection
					)
					.then((data) => {
						if (data.length < 1) {
							props.fetchData(
								props.searchText,
								props.targetCollectionName,
								props.initialPagination,
								props.sortBy,
								showingAll ? props.additionalFilters : {},
								false
							);
							setShowingAll(true);
							setShowToggle(false);
						} else {
							if (props.value === undefined) {
								props.onChange(nullSafeGet('0.id', data));
							}
							setShowToggle(true);
						}
					})
			}
			renderRecord={(supplierFacility, apiSuppliers) => (
				<Select.Option key={supplierFacility.id} value={supplierFacility.id}>
					{supplierFacility.name}
					{nullSafeGet('warrantySupplierId', props) === supplierFacility.id ? (
						<span className="ml-2 inline-block text-sm  text-gray-400">(Warranty)</span>
					) : !showingAll ? (
						apiSuppliers.find((item) => item.id === supplierFacility.id) ? (
							<span className="ml-2 inline-block text-sm  text-gray-400">
								( Rank{' '}
								{apiSuppliers.findIndex((item, index) => supplierFacility.id === item.id) + 1})
							</span>
						) : null
					) : null}
				</Select.Option>
			)}
			dropdownRender={(menu) => (
				<>
					{menu}
					{showToggle ? (
						<>
							<Divider style={{ margin: '8px 0' }} />
							<Space style={{ padding: '0 8px 4px' }}>
								<Button
									type="text"
									icon={
										<Popover
											overlayStyle={{ zIndex: 1060 }}
											content={
												showingAll
													? 'Click me to see only supplier which handles selected problem type and location'
													: 'Click me to see all your network suppliers'
											}
										>
											<InfoCircleOutlined translate="" />
										</Popover>
									}
									onClick={() => {
										props.fetchData(
											props.searchText,
											props.targetCollectionName,
											props.initialPagination,
											props.sortBy,
											showingAll ? props.additionalFilters : {},
											false
										);
										setShowingAll(!showingAll);
									}}
								>
									{showingAll ? 'Hide' : 'Show All'}
								</Button>
							</Space>
						</>
					) : null}
				</>
			)}
		/>
	);
};

export default SupplierSelectionWorkOrderSelect;

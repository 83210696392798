import React from 'react';

const OverlayContainer = ({ children }): React.ReactElement => {
	return (
		<div
			style={{
				position: 'absolute',
				left: 0,
				top: 0,
				right: 0,
				height: '100vh',
				background: 'rgba(255, 255, 255, 0.5)',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			{children}
		</div>
	);
};

export default OverlayContainer;

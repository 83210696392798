import React, { useMemo } from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import PurchaseRequestStatusDisplay from '../purchase_request_status_display/PurchaseRequestStatusDisplay';
import { getPurchaseRequestId, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import moment from 'moment';
import DateTimeHover from '../date_time_component/DateTime';

require('./PurchaseRequestRowDisplay.less');

export const PurchaseRequestRowDisplay: React.FC<any> = ({ purchaseRequest, ...props }) => {
	const partNumbers = useMemo(() => {
		const parts = nullSafeGetOrElse('partSupplierPurchaseRequestLineItems', purchaseRequest, []);
		const partNumbers = parts
			.map((_) => nullSafeGet('part.partNumber', _))
			.filter((_) => !!_)
			.map((_) => `#${_}`);
		return partNumbers.join(', ');
	}, [purchaseRequest]);

	const equipmentNumbers = useMemo(() => {
		const equipment = nullSafeGetOrElse(
			'equipmentSupplierPurchaseRequestLineItems',
			purchaseRequest,
			[]
		);
		const equipmentNumbers = equipment
			.map((_) => nullSafeGet('equipmentType.equipmentTypeNumber', _))
			.filter((_) => !!_)
			.map((_) => `#${_}`);
		return equipmentNumbers.join(', ');
	}, [purchaseRequest]);

	return (
		<div
			className="purchaseRequestRowDisplay"
			style={{ justifyContent: 'space-between' }}
			{...props}
		>
			<div className="purchaseRequestRowDisplay">
				<div>
					<div className="purchaseRequestRowDisplay__rowTitle">
						<Ellipsis tooltip={true} lines={1} fullWidthRecognition>
							{purchaseRequest.name}
						</Ellipsis>
					</div>
					{purchaseRequest.description ? (
						<div className="purchaseRequestRowDisplay__rowSubtitle mb-2">
							<Ellipsis tooltip={true} lines={1} fullWidthRecognition>
								{purchaseRequest.description}
							</Ellipsis>
						</div>
					) : null}
					<div className="purchaseRequestRowDisplay__rowSubtitle">
						<div
							className="purchaseRequestRowDisplay__rowInlineGroup"
							style={{ display: 'inline-flex' }}
						>
							<PurchaseRequestStatusDisplay status={purchaseRequest.status} />
						</div>
						<div className="purchaseRequestRowDisplay__rowInlineGroup">
							{`PR ${getPurchaseRequestId(purchaseRequest)}`}
						</div>
						<div className="purchaseRequestRowDisplay__rowInlineGroup">
							<span>
								Requested{' '}
								<DateTimeHover
									timestamp={nullSafeGet('createdAt', purchaseRequest)}
									showTime={false}
								/>
							</span>
						</div>
						{!!partNumbers ? (
							<div className="purchaseRequestRowDisplay__rowInlineGroup">
								{`Parts : ${partNumbers}`}
							</div>
						) : null}
						{!!equipmentNumbers ? (
							<div className="purchaseRequestRowDisplay__rowInlineGroup">
								{`Equipment : ${equipmentNumbers}`}
							</div>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
};

import React, { FC, useCallback, useMemo, useState } from 'react';
import { Button, Form, InputNumber, Modal } from 'antd';
import { connect } from 'react-redux';
import { getCurrency } from '../../utils/DataAccessUtils';

const FORM_NAME = 'edit-list-price-form';

const EditListPriceModal: FC<any> = ({ onCancel, listPrice, onSave, onSuccess, currentUser }) => {
	const [saving, setSaving] = useState(false);

	const onSubmit = useCallback(
		(values) => {
			setSaving(true);
			onSave({
				price: `${values.price}`,
				effectiveDate: listPrice.effectiveDateString,
			}).finally(() => {
				setSaving(false);
				onSuccess && onSuccess();
			});
		},
		[listPrice.effectiveDateString, onSave, onSuccess]
	);

	const initialValues = useMemo(
		() => ({
			...listPrice,
		}),
		[listPrice]
	);

	const title = useMemo(
		() => `Update List Price (Effective from ${listPrice.effectiveDateString})`,
		[listPrice.effectiveDateString]
	);

	const currency = useMemo(() => getCurrency({ currentUser }), [currentUser]);

	return (
		<Modal
			visible={true}
			width={600}
			title={title}
			closable
			onCancel={onCancel}
			forceRender
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button
					type="primary"
					size="large"
					style={{ marginLeft: '16px' }}
					key="submit"
					htmlType="submit"
					form={FORM_NAME}
					loading={saving}
				>
					{'Update'}
				</Button>,
			]}
		>
			<Form
				id={FORM_NAME}
				layout="vertical"
				requiredMark={false}
				initialValues={initialValues}
				onFinish={onSubmit}
			>
				<Form.Item
					name="price"
					label="List Price"
					rules={[
						{
							type: 'number',
							min: 1,
							message: `List price cannot be less than 1!`,
						},
						{
							required: true,
							message: 'Please enter List Price!',
						},
					]}
				>
					<InputNumber style={{ width: '100%' }} min="1" addonAfter={currency.id} />
				</Form.Item>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

export default connect(mapStateToProps)(EditListPriceModal);

import React, { FC, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Popover, message } from 'antd';
import {
	fetchAllPartsForSupplier,
	partsRestCrudThunksForSupplier,
} from '../../thunks/parts_thunks';
import { EmptyState } from '../empty_state/EmptyState';
import PartRowDisplay from '../part_row_display/PartRowDisplay';
import { PARTS_CRUD_ACTION_CREATORS } from '../../actions/parts_actions';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import { DEFAULT_NAME_SORTER } from '../../utils/DataConstants';
import UploadWithSampleModal from './UploadWithSampleModal';
import {
	PART_MASTER_FLATFILE_HEADER,
	getFlatfileRecordForPart,
} from '../stock_locations_detail_details_page/stock_location_constants';
import moment from 'moment';
import { DATE_FORMAT } from '../../utils/DataFormatterUtils';
import { ExportToCsv } from 'export-to-csv';
import { CSV_EXPORT_DEFAULTS } from '../../utils/DataConstants';
import { getCurrency, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { getIsActiveFilterField } from '../../utils/FilterUtils';

require('./PartsIndexPage.less');

const PARTS_TC = 'partsIndex';

const getMissngPartNumbers = (existing, newList) =>
	existing
		.filter(
			(part) =>
				!newList.find(
					(record) =>
						`${part.id}` === `${record.id}` ||
						nullSafeGetOrElse('partNumber', part, -1) === record.partNumber
				)
		)
		.map((_) => nullSafeGetOrElse('partNumber', _, nullSafeGet('id', _)));

const PartsIndexPage: FC<any> = ({
	history,
	updatePartsFilters,
	clearAndUpdateFilters,
	parts,
	fetchParts,
	fetchAllParts,
	createBulk,
	currentUser,
}) => {
	const [downloading, setDownloading] = useState(false);
	const [exportingCsv, setExportingCsv] = useState(false);
	const [partsForUpload, setPartsForUpload] = useState([]);
	const [uploadPopupVisible, setUploadPopupVisible] = useState(false);

	const userCurrency = useMemo(() => getCurrency({ currentUser }).id, [currentUser]);

	const onRow = useCallback(
		(record) => ({
			onClick: () => history.push(`/supplier/parts/detail/${record.id}`),
		}),
		[history]
	);

	const columns = useMemo(
		() => [
			{
				title: 'Name',
				dataIndex: 'name',
				sorter: true,
				render: (_, record) => <PartRowDisplay part={record} />,
			},
		],
		[]
	);

	const filterConfig = useMemo(
		() => [getIsActiveFilterField(updatePartsFilters, PARTS_TC, true)],
		[updatePartsFilters]
	);

	const getStatSliceProp = useCallback(
		(propName) => nullSafeGetOrElse(`${PARTS_TC}.${propName}`, parts, {}),
		[parts]
	);

	const refreshParts = useCallback(() => {
		fetchParts(
			{},
			PARTS_TC,
			getStatSliceProp('pagination'),
			getStatSliceProp('sorting'),
			getStatSliceProp('filters')
		);
	}, [fetchParts, getStatSliceProp]);

	const showUploadPopup = useCallback(() => {
		setDownloading(true);
		fetchAllParts()
			.then((res) => {
				setPartsForUpload(res);
				setUploadPopupVisible(true);
			})
			.finally(() => setDownloading(false));
	}, [fetchAllParts]);

	const hideUploadPopup = useCallback(() => setUploadPopupVisible(false), []);

	const exportAsCsv = useCallback(
		(parts) => {
			const date = moment().format(DATE_FORMAT);
			const csvTitle = `Parts_Master_List_${date}`;
			const csvExporter = new ExportToCsv({
				...CSV_EXPORT_DEFAULTS,
				filename: csvTitle,
				title: csvTitle,
				headers: PART_MASTER_FLATFILE_HEADER([userCurrency]).map((_) => _.label),
			});

			const data =
				parts && parts.length > 0
					? parts.map((_) => getFlatfileRecordForPart(_, userCurrency))
					: [getFlatfileRecordForPart({}, userCurrency)];
			csvExporter.generateCsv(data);
		},
		[userCurrency]
	);

	const onDownloadSample = useCallback(() => {
		exportAsCsv(partsForUpload);
	}, [exportAsCsv, partsForUpload]);

	const downloadCSV = useCallback(() => {
		setExportingCsv(true);
		fetchAllParts()
			.then((res) => {
				setPartsForUpload(res);
				exportAsCsv(res);
			})
			.finally(() => setExportingCsv(false));
	}, [exportAsCsv, fetchAllParts]);

	const onDataUpload = useCallback(
		(results) => {
			return new Promise((resolve, reject) => {
				const missingNumbers = getMissngPartNumbers(partsForUpload, results.validData);

				if (missingNumbers.length > 0) {
					reject(
						`Some parts are missing from existing list. Please include them! Part # ${missingNumbers.join(
							', '
						)} `
					);
				} else {
					createBulk(
						results.validData.map((_) => ({
							..._,
							id: _.id ? parseInt(_.id) : undefined,
							cost: _.cost ? parseFloat(_.cost) : undefined,
							images: [],
						}))
					)
						.then((res) => {
							resolve('Updated successfully!');
							setTimeout(() => {
								hideUploadPopup();
								message.success('Updated successfully!');
								refreshParts();
							}, 0);
						})
						.catch((err) => {
							reject(err || 'Unable to upload! Please contact support!');
						});
				}
			});
		},
		[createBulk, hideUploadPopup, partsForUpload, refreshParts]
	);

	return (
		<>
			<PaginatedReduxTableWithHeader
				targetCollectionName={PARTS_TC}
				updateFilters={updatePartsFilters}
				stateSlice={parts}
				clearAndUpdateFilters={clearAndUpdateFilters}
				filterConfig={filterConfig}
				entityCollectionName="parts"
				tableColumns={columns}
				onTableRow={onRow}
				fetchData={partsRestCrudThunksForSupplier.read}
				initialSorters={DEFAULT_NAME_SORTER}
				preAppliedFilters={{ isActive: true }}
				rightActions={
					<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PARTS_AND_EQUIPMENTS}>
						<div className="flex flex-row items-center">
							<Popover content="Download or Upload Parts" trigger="hover">
								<Button
									size="large"
									className="inline-block-visible-md ml-2"
									icon={<UploadOutlined translate="" />}
									loading={downloading}
									onClick={showUploadPopup}
								>
									<span className="inline-block-visible-xxl">Upload</span>
								</Button>
							</Popover>
							<Popover content="Export CSV" trigger="hover">
								<Button
									size="large"
									icon={<DownloadOutlined translate="" />}
									className="inline-block-visible-md ml-2"
									onClick={downloadCSV}
									loading={exportingCsv}
								>
									<span className="inline-block-visible-xxl">Export CSV</span>
								</Button>
							</Popover>
							<Button
								className="ml-2"
								type="primary"
								size="large"
								onClick={() => history.push('/supplier/parts/overview/new')}
							>
								New Part
							</Button>
						</div>
					</AccessPermissionChecker>
				}
				emptyState={
					<EmptyState
						graphic={
							<img
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
								alt="Add your first part"
							/>
						}
						headline={"Nothing's here!"}
						body={
							<div style={{ textAlign: 'center' }}>
								<div style={{ maxWidth: 440, marginBottom: 16 }}>
									Looks like your team has not added any parts yet.
								</div>
								<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PARTS_AND_EQUIPMENTS}>
									<Button
										type="primary"
										onClick={() => history.push('/supplier/parts/overview/new')}
									>
										Add your first part
									</Button>
								</AccessPermissionChecker>
							</div>
						}
					/>
				}
			/>
			{uploadPopupVisible && (
				<UploadWithSampleModal
					type="Parts"
					title="Upload Parts"
					downloadText="Do you want to download the existing Parts?"
					sampleFileText="Download Parts"
					onCancel={hideUploadPopup}
					onDownloadSample={onDownloadSample}
					fields={PART_MASTER_FLATFILE_HEADER([userCurrency])}
					onDataLoad={onDataUpload}
					showWarningMessage
				/>
			)}
		</>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	parts: state.parts,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	fetchParts: (params, targetCollectionName, pagination, sorting, filters, addToTargetCollection) =>
		dispatch(
			partsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	updatePartsFilters: (filters, targetCollection) =>
		dispatch(PARTS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(PARTS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)),
	fetchAllParts: (params) => dispatch(fetchAllPartsForSupplier(params)),
	createBulk: (entities) => dispatch(partsRestCrudThunksForSupplier.createBulk(entities)),
});

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(PartsIndexPage));

import * as React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Layout, Row, Col, Upload, DatePicker, Card, Steps, List } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { KeyValueDisplay } from '../key_value_display/KeyValueDisplay';
import { optionalFilter, capitalizeEachWord } from '../../utils/DataFormatterUtils';
import { AddressDisplay } from '../address_display/AddressDisplay';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { buyerContactsRestCrudThunksForBuyer } from '../../thunks/buyer_contacts_thunks';
import { buyerFacilitiesRestCrudThunksForBuyer } from '../../thunks/buyer_facilities_thunks';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { FlexibleImageAvatar } from '../flexible-image-avatar/FlexibleImageAvatar';
import { ContactAvatar } from '../contact_avatar/ContactAvatar';
import PaginatedReduxList from '../paginated_redux_list/PaginatedReduxList';

const ListItem = List.Item;
const ListItemMeta = ListItem.Meta;
const { Content } = Layout;
const Step = Steps.Step;
const { RangePicker } = DatePicker;
const Dragger = Upload.Dragger;
const BUYER_FACILITY_CONTACTS_COLLECTION_NAME = 'associatedBuyerContactsForFacility';

const style = require('./BuyerBuyersDetailPage.less');

const DAYS_OF_THE_WEEK = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
];

class BuyerBuyersDetailPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = { loading: true };
	}

	componentDidMount() {
		const {
			fetchBuyerFacility,
			fetchBuyerContacts,

			match,
		} = this.props;
		const buyerFacilityId = match.params.id;
		fetchBuyerFacility(buyerFacilityId).then(() => this.setState({ loading: false }));
	}

	render() {
		const {
			buyerFacility,

			match,
			buyerContacts,
			buyerFacilityReviews,
		} = this.props;

		const { loading } = this.state;
		const contactInfo = loading
			? {}
			: {
					Address: <AddressDisplay address={buyerFacility.primaryAddress} />,
					Email: optionalFilter(buyerFacility.primaryContactEmail),
			  };
		const buyerFacilityId = match.params.id;
		const buyerContactsForFacility = getRecordsForTargetCollection(
			buyerContacts,
			BUYER_FACILITY_CONTACTS_COLLECTION_NAME
		);

		const columns = [
			{
				title: 'Name',
				dataIndex: ['contact', 'nameGiven'],
				render: (text, record) => (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<ContactAvatar userType="buyer" contact={record} />
						<span style={{ marginLeft: 16 }}>
							{record.nameGiven} {record.nameFamily}
						</span>
					</div>
				),
			},
			{
				title: 'Email',
				dataIndex: 'email',
			},
		];

		const operatingHours = buyerFacility.operatingHours || {};

		return (
			<Content className="buyerBuyersDetailPage" style={{ position: 'relative', padding: 0 }}>
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				{loading ? (
					<PageLoadingPlaceholder />
				) : (
					<div style={{ maxWidth: 1200, margin: 'auto', paddingTop: 40, paddingBottom: 40 }}>
						<div
							style={{
								position: 'absolute',
								left: 0,
								right: 0,
								top: 0,
								height: 168,
								background: '#4B4DAE',
							}}
						/>
						<Row style={{ margin: '0.5em -8px' }} gutter={16}>
							<Col span={24}>
								<Card>
									<div className="buyerBuyersDetailPage__header">
										<div style={{ float: 'left', display: 'flex' }}>
											<div style={{ marginRight: 32 }}>
												<FlexibleImageAvatar
													displayName={buyerFacility.displayName}
													showPopover={false}
													popoverText={buyerFacility.displayName}
													width="80px"
													height="80px"
													imageUrl={buyerFacility.logoURL}
												/>
											</div>
											<div>
												<div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
													<h1 style={{ fontSize: 32, marginRight: 8, marginBottom: 0 }}>
														{buyerFacility.displayName}
													</h1>
												</div>
											</div>
										</div>
										<div
											style={{
												float: 'right',
												display: 'flex',
												justifyContent: 'flex-end',
												maxWidth: 400,
											}}
										></div>
									</div>
								</Card>
							</Col>
						</Row>

						<Row style={{ margin: '-0.5em -8px 0.5em -8px' }} gutter={16}>
							<Col span={16}>
								<Row style={{ margin: '0.5em -8px' }} gutter={16}>
									<Col span={24}>
										<Card title="Contacts">
											<PaginatedReduxList
												collectionName="buyer_contacts"
												targetCollectionName="eventBuyerTeamFormSearchResults"
												itemLayout="horizontal"
												initialPagination={{ current: 1, pageSize: 8 }}
												initialSorting={{}}
												initialFilters={{ buyerFacilityId: buyerFacilityId }}
												fetchData={buyerContactsRestCrudThunksForBuyer.read}
												listItemRenderFunction={(item) => (
													<List.Item key={item.email} actions={[]} extra="">
														<List.Item.Meta
															avatar={
																<ContactAvatar
																	size="large"
																	hidePopover={true}
																	contact={item}
																	userType="buyer"
																/>
															}
															title={
																<span>
																	{capitalizeEachWord(`${item.nameGiven} ${item.nameFamily}`)}
																</span>
															}
															description={
																item.title && item.department ? (
																	<span>
																		{capitalizeEachWord(item.title)},{' '}
																		{capitalizeEachWord(item.department)}
																	</span>
																) : null
															}
														/>
														<div></div>
													</List.Item>
												)}
											/>
										</Card>
									</Col>
								</Row>
							</Col>

							<Col span={8}>
								<Row style={{ margin: '0.5em -8px' }} gutter={16}>
									<Col span={24}>
										<Row style={{ margin: '0 -8px' }} gutter={16}>
											<Col span={24}>
												<Card title="Contact Information">
													<KeyValueDisplay keyValueStore={contactInfo} />
												</Card>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				)}
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	buyerFacilitiesFetching: state.buyer_facilities.fetching,
	buyerFacility: state.buyer_facilities.detail,
	buyerContacts: state.buyer_contacts,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	fetchBuyerFacility: (id) => dispatch(buyerFacilitiesRestCrudThunksForBuyer.readOne(id)),
	fetchBuyerContacts: (params, targetCollectionName) =>
		dispatch(buyerContactsRestCrudThunksForBuyer.read(params, targetCollectionName)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BuyerBuyersDetailPage));

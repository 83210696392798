import React from 'react';
import WorkOrderTable from '../WorkOrderTable';
import { BUYER_WO_FILTERS_ORDER } from '../../utils/WorkOrderUtils';
import posthog from 'posthog-js';

export default function WorkOrdersAllIndexPage() {
	const skipCount = posthog.isFeatureEnabled('OptimiseWorkOrderAPIforLargeData');

	return (
		<WorkOrderTable
			handleQueryParams
			filterConfig={{
				filters: BUYER_WO_FILTERS_ORDER,
			}}
			preAppliedFilters={{ no_count: skipCount }}
			ignoreRefreshCounts={skipCount}
			hideExportButton={skipCount}
			targetCollectionName="allWorkOrdersIndex"
		/>
	);
}

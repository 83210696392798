import React, { FC, useCallback, useMemo, useState } from 'react';
import {
	equipmentPerStockLocationCsvReportGeneratorSupplier,
	equipmentPerStockLocationsRestCrudThunksForSupplier,
} from '../../thunks/equipment_per_stock_locations_thunks';
import EquipmentPerStockLocationRowDisplay from '../equipment_per_stock_location_row_display/EquipmentPerStockLocationRowDisplay';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EmptyState } from '../empty_state/EmptyState';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import { EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS } from '../../actions/equipment_per_stock_locations_actions';
import {
	fetchWithDifferntSearchName,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { equipmentTypesRestCrudThunksForSupplier } from '../../thunks/equipment_types_thunks';
import HyperLink, { ENTITY_TYPE } from '../common/HyperLink';
import { locationsRestCrudThunksForSupplier } from '../../thunks/locations_thunks';
import {
	RECENTLY_UPDATED_SORTER,
	FILTER_CONFIG_NAMES,
	FILTER_FIELD_TYPE,
} from '../../utils/DataConstants';
import { Button, Popover } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import DateTimeHover from '../date_time_component/DateTime';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { renderEquipmenTypeSelect } from '../purchase_order_form/ReplaceEquipmentLineItemModal';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';

const EPSL_TC = 'stockLocationAssociatedEquipments';
const EPSL_ADVANCED_FILTERS_TC = 'epslAdvancedFilters';
const DEFAULT_BACK_LINK_TEXT = 'Back to Disbursed Equipment';

const StockLocationDisbursedEquipmentsPage: FC<any> = ({
	match,
	history,
	location,
	equipmentsPerStockLocation,
	updateFilters,
	clearAndUpdateFilters,
	stockLocation,
	equipmentTypes,
	fetchEquipmentTypes,
	fetchMultipleEquipments,
	locations,
	fetchLocations,
	fetchMultipleLocations,
	exportCsv,
}): React.ReactElement => {
	const stockLocationId = useMemo(() => match.params.id, [match.params.id]);

	const [downloading, setDownloading] = useState(false);

	const backText = useMemo(
		() => `Back to ${nullSafeGetOrElse('name', stockLocation, 'Stock location')}`,
		[stockLocation]
	);

	const getLink = useCallback(
		(link) => getLinkWIthBackLinkParams(location, backText, link),
		[backText, location]
	);

	const recordsAvailable = useMemo(
		() => getRecordsForTargetCollection(equipmentsPerStockLocation, EPSL_TC).length > 0,
		[equipmentsPerStockLocation]
	);

	const equipmentsColumns = useMemo(
		() => [
			{
				title: 'Name',
				dataIndex: 'name',
				render: (_, record) => (
					<EquipmentPerStockLocationRowDisplay equipmentPerStockLocation={record} />
				),
			},
			{
				title: 'Disbursed At',
				dataIndex: 'disbursedAt',
				render: (_) => (_ ? <DateTimeHover timestamp={_} showDate={true} /> : _),
			},
			{
				title: 'Disbursed To',
				dataIndex: ['asset', 'location', 'name'],
			},
			{
				title: 'Asset ID',
				dataIndex: 'asset',
				render: (asset) => (
					<HyperLink
						text={nullSafeGet('buyerSpecificAssetId', asset) || nullSafeGet('id', asset)}
						entityType={ENTITY_TYPE.ASSET_DETAIL}
						entityId={nullSafeGetOrElse('id', asset, '-1')}
						backLinkText={DEFAULT_BACK_LINK_TEXT}
					/>
				),
			},
			{
				title: 'Asset Number',
				dataIndex: ['asset', 'assetNumber'],
			},
		],
		[]
	);

	const onEquipmentRow = useCallback(
		(record) => ({
			onClick: () =>
				history.push(getLink(`/supplier/equipments/equipmentPerStockLocation/detail/${record.id}`)),
		}),
		[getLink, history]
	);

	const filterConfig = useMemo(
		() => [
			{
				label: 'Equipment Type',
				mode: 'multiple',
				fieldName: 'equipmentTypeIds',
				stateSlice: equipmentTypes,
				targetCollectionName: EPSL_ADVANCED_FILTERS_TC,
				fetch: fetchWithDifferntSearchName(fetchEquipmentTypes, 'search'),
				fetchMultiple: fetchMultipleEquipments,
				renderRecord: renderEquipmenTypeSelect,
				component: OWAsyncSelect,
			},
			{
				label: 'Location',
				fieldName: 'locationIds',
				mode: 'multiple',
				stateSlice: locations,
				targetCollectionName: EPSL_ADVANCED_FILTERS_TC,
				fetch: fetchLocations,
				fetchMultiple: fetchMultipleLocations,
			},
			{
				label: 'Disbursed Between',
				labelPrefix: 'Disbursed',
				fieldName: FILTER_CONFIG_NAMES.DISBURSED_AT,
				type: FILTER_FIELD_TYPE.DATE_RANGE,
			},
		],
		[
			equipmentTypes,
			fetchEquipmentTypes,
			fetchLocations,
			fetchMultipleEquipments,
			fetchMultipleLocations,
			locations,
		]
	);

	const getStatSliceProp = useCallback(
		(propName) => nullSafeGetOrElse(`${EPSL_TC}.${propName}`, equipmentsPerStockLocation, {}),
		[equipmentsPerStockLocation]
	);

	const downloadCSV = useCallback(() => {
		setDownloading(true);
		exportCsv({
			...getStatSliceProp('sorting'),
			...getStatSliceProp('filters'),
		}).finally(() => setDownloading(false));
	}, [exportCsv, getStatSliceProp]);

	return (
		<>
			<PaginatedReduxTableWithHeader
				targetCollectionName={EPSL_TC}
				updateFilters={updateFilters}
				stateSlice={equipmentsPerStockLocation}
				clearAndUpdateFilters={clearAndUpdateFilters}
				filterConfig={filterConfig}
				entityCollectionName="equipment_per_stock_locations"
				tableColumns={equipmentsColumns}
				onTableRow={onEquipmentRow}
				showHeader
				preAppliedFilters={{ stockLocationId, isDisbursed: true }}
				fetchData={equipmentPerStockLocationsRestCrudThunksForSupplier.read}
				hasDefaultHeaderPage
				initialSorters={RECENTLY_UPDATED_SORTER}
				rightActions={
					<Popover content="Export CSV" trigger="hover">
						<Button
							loading={downloading}
							size="large"
							icon={<DownloadOutlined translate="" />}
							className="inline-block-visible-md"
							onClick={downloadCSV}
							disabled={!recordsAvailable}
						>
							<span className="inline-block-visible-xxl">Export CSV</span>
						</Button>
					</Popover>
				}
				emptyState={
					<EmptyState
						graphic={
							<img
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
								alt="The tool shed is looking pretty empty."
							/>
						}
						headline={'The tool shed is looking pretty empty.'}
						body={
							<div style={{ textAlign: 'center' }}>
								<div style={{ maxWidth: 440, marginBottom: 16 }}>
									No Equipment has been disbursed from this stock location
								</div>
							</div>
						}
					/>
				}
			/>
		</>
	);
};

const mapStateToProps = (state) => ({
	stockLocation: state.stock_locations.detail,
	equipmentsPerStockLocation: state.equipment_per_stock_locations,
	equipmentTypes: state.equipment_types,
	locations: state.locations,
});

const mapDispatchToProps = (dispatch) => ({
	updateFilters: (filters, targetCollection) =>
		dispatch(
			EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)
		),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(
				filters,
				targetCollectionName
			)
		),
	fetchMultipleEquipments: (ids, targetCollectionName) =>
		dispatch(equipmentTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchEquipmentTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			equipmentTypesRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			locationsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	exportCsv: (params) => dispatch(equipmentPerStockLocationCsvReportGeneratorSupplier(params)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(StockLocationDisbursedEquipmentsPage)
);

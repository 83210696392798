import * as React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Alert, Form, Switch } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { connect } from 'react-redux';
import { FC, useMemo, useState } from 'react';
import { withRouter } from 'react-router';

const FormItem = Form.Item;

interface DeclineQuoteProps {
	onSubmit: any;
	onCancel: any;
	visible: boolean;
	createErrors: any;
	updateErrors: any;
	creating: boolean;
	updating: boolean;
	workOrderId: number;
	currentUser: any;
	form: any;
}

interface DeclineQuoteFormProps extends FormComponentProps {
	onSubmit: any;
	onCancel: any;
	visible: boolean;
	createErrors: any;
	updateErrors: any;
	creating: boolean;
	updating: boolean;
	workOrderId: number;
	currentUser: any;
}

const DeclineQuoteForm: FC<DeclineQuoteProps> = ({
	visible,
	onCancel,
	onSubmit,
	currentUser,
	workOrderId,
	createErrors,
	updateErrors,
}) => {
	const [form] = Form.useForm();
	const [declineButtonLoading, setDeclineButtonLoading] = useState(false);
	//useMemo(()=>form.setFieldsValue({workOrderId}),[workOrderId]);
	return (
		<Modal
			visible={visible}
			width={600}
			title="Decline Quote"
			okText="Decline"
			onCancel={onCancel}
			onOk={form.submit}
			confirmLoading={declineButtonLoading}
			closable={false}
		>
			<Form
				form={form}
				layout="vertical"
				className="declineQuoteForm"
				preserve={false}
				onFinish={(values) =>
					form.validateFields().then(() => {
						setDeclineButtonLoading(true);
						onSubmit(values);
					})
				}
			>
				<Form.Item label="workOrderId" hidden={true}>
					<Input value={workOrderId} />
				</Form.Item>
				{createErrors.length > 0 ? (
					<Form.Item>
						<Alert message={createErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				{updateErrors.length > 0 ? (
					<Form.Item>
						<Alert message={updateErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				<Form.Item
					name="note"
					label="Notes"
					rules={[
						{
							required: true,
							message: 'Please add a note on why this quote was declined.',
						},
					]}
					required={true}
				>
					<Input.TextArea />
				</Form.Item>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	createErrors: state.work_orders.createErrors,
	updateErrors: state.work_orders.updateErrors,
	creating: state.work_orders.creating,
	updating: state.work_orders.updating,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(DeclineQuoteForm));

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	proposalsRestCrudThunksForBuyer,
	proposalsRestCrudThunksForSupplier,
	proposalStatusChangeForSupplier,
} from '../../thunks/proposals_thunks';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import ProposalForm from '../proposal_form/ProposalForm';
import { Form } from '@ant-design/compatible';
import { ROLE_TYPES } from '../../utils/DataConstants';

const queryString = require('qs');

class EditWorkOrderProposalPage extends React.Component<any, any> {
	componentDidMount() {
		const { match, getProposal } = this.props;
		const proposalId = match.params.proposalId;
		getProposal(proposalId);
	}

	render() {
		const { history, proposal, fetching, userType } = this.props;

		const formData = {
			...proposal,
			workOrderId: proposal.workOrderId,
		};

		return [
			<LogOnMountWithStandardEventProperties eventType="visited supplier edit proposal page" />,
			<EditPage
				title="Edit Proposal"
				formComponentProps={{
					formData: formData,
					onSuccess: (rec) => {
						history.push(`/${userType}/workOrders/detail/${proposal.workOrderId}/quotes`);
					},
				}}
				fetching={fetching}
				FormComponent={ProposalForm}
			/>,
		];
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	proposal: state.proposals.detail,
	fetching: state.proposals.fetching,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getProposal: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? proposalsRestCrudThunksForSupplier.readOne(id)
				: proposalsRestCrudThunksForBuyer.readOne(id)
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(EditWorkOrderProposalPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(Form.create()(ComponentWithoutUserType));

import React, { useEffect, useRef } from 'react';
import { Button, Row, Table, Typography } from 'antd';
import Search from 'antd/lib/input/Search';
import { debounce } from '../../utils/PerformanceUtils';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';

export default function SwitchWorkspaceModal({
	searchWorkspaces,
	activeWorkspaceId,
	changeWorkspace,
	workspaces = [],
	userType,
	currentUser,
}) {
	const [data, setData] = React.useState(workspaces);
	const [count, setCount] = React.useState(workspaces.length);
	const [value, setValue] = React.useState('');
	const [loading, setLoading] = React.useState(currentUser.hasAllFacilityAccess);
	const [currentpage, setCurrentPage] = React.useState(1);

	const startSearchForWorkspaces = (value, userType, page = 1) => {
		setLoading(true);
		setCurrentPage(page);
		searchWorkspaces(value, userType, page)
			.then(({ data, count }) => {
				setData(data);
				setCount(count);
			})
			.catch(console.log)
			.finally(() => {
				setLoading(false);
			});
	};
	const debounced = useRef(
		debounce((newValue) => {
			newValue && startSearchForWorkspaces(newValue, userType);
		}, 350)
	);

	useEffect(() => debounced.current(value), [value]);
	useEffect(() => {
		if (currentUser.hasAllFacilityAccess) {
			// this means that this user has access to all workspaces, so fetching the workspaces to show them in the table.
			startSearchForWorkspaces(null, userType);
		}
	}, []);

	const columns = [
		{
			title: 'Facility',
			dataIndex: 'displayName',
			key: 'name',
			render: (text, record) => {
				const email = nullSafeGetOrElse('primaryContactEmail', record, ' ');
				return (
					<div>
						<div>
							<Typography.Text strong>{text}</Typography.Text>
						</div>
						<div>
							<Typography.Text type="secondary" italic>
								{nullSafeGet('primaryAddress.city', record)}
							</Typography.Text>
						</div>
						<div>
							<Typography.Text type="secondary" italic>
								{email.substring(email.indexOf('@') + 1)}
							</Typography.Text>
						</div>
					</div>
				);
			},
		},
		{
			title: '',
			dataIndex: '',
			align: 'right',
			key: 'x',
			render: (text, record) =>
				record.id === activeWorkspaceId ? (
					<Typography.Text type="success">Active</Typography.Text>
				) : (
					<Button
						type="ghost"
						onClick={() => {
							changeWorkspace(record.id, userType);
						}}
					>
						Switch
					</Button>
				),
		},
	];

	return (
		<>
			<Row justify="center">
				<Typography.Title level={5} style={{ textAlign: 'center' }}>
					You are currently active in{' '}
					<i>
						<u>{nullSafeGet('facility.displayName', currentUser)}</u>
					</i>
				</Typography.Title>
			</Row>
			<Row>
				<Search
					onChange={(e) => setValue(e.target.value)}
					placeholder="Search for a facility..."
					onSearch={(e) => {
						startSearchForWorkspaces(e, userType);
					}}
					style={{ margin: '16px 0' }}
				/>
			</Row>
			<Row>
				<Table
					loading={loading}
					showHeader={false}
					style={{ width: '100%' }}
					columns={columns}
					dataSource={data}
					pagination={{
						showSizeChanger: false,
						total: count,
						current: currentpage,
						onChange: (page) => {
							startSearchForWorkspaces(value, userType, page);
						},
					}}
				/>
			</Row>
		</>
	);
}

import React, { FC, useMemo } from 'react';
import { Card } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	getCurrency,
	getPurchaseOrderId,
	getWarrantyStatus,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import { TwoLineList } from '../two_line_list/TwoLineList';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import HyperLink, { ENTITY_TYPE } from '../common/HyperLink';
import { ROLE_TYPES } from '../../utils/DataConstants';
import DateTimeHover from '../date_time_component/DateTime';

const AssetDetailsCard: FC<any> = ({ asset, currentUser, userType }): React.ReactElement => {
	const isSupplier = useMemo(() => userType === ROLE_TYPES.SUPPLIER, [userType]);

	const currency = useMemo(() => getCurrency({ currentUser }), [currentUser]);

	const serviceWarranty = useMemo(() => nullSafeGet('warranties.0', asset), [asset]);

	const partWarranty = useMemo(() => nullSafeGet('partWarranty', asset), [asset]);

	const assetDetails = useMemo(
		() => [
			{
				key: 'Asset ID',
				value: asset.buyerSpecificAssetId || asset.id,
			},
			...(asset.assetNumber
				? [
						{
							key: 'Asset Number',
							value: asset.assetNumber,
						},
				  ]
				: []),
			...(asset.manufacturer
				? [
						{
							key: 'Manufacturer',
							value: asset.manufacturer,
						},
				  ]
				: []),
			...(asset.modelNumber
				? [
						{
							key: 'Model Number',
							value: asset.modelNumber,
						},
				  ]
				: []),
			...(asset.upcNumber
				? [
						{
							key: 'UPC Number',
							value: asset.upcNumber,
						},
				  ]
				: []),
			...(asset.serialNumber
				? [
						{
							key: 'Serial Number',
							value: asset.serialNumber,
						},
				  ]
				: []),
			...(asset.purchaseDate
				? [
						{
							key: 'Purchase Date',
							value: (
								<DateTimeHover
									timestamp={nullSafeGet('purchaseDate', asset)}
									showDate={true}
									showTime={false}
								/>
							),
						},
				  ]
				: []),
			...(asset.originalPrice
				? [
						{
							key: 'Purchase Price',
							value: currency.format(asset.originalPrice),
							accessPermissionName: PERMISSION_NAMES.SHOW_ASSET_PRICE,
						},
				  ]
				: []),
			...(asset.capitalisedCost
				? [
						{
							key: 'Capitalised Cost',
							value: currency.format(asset.capitalisedCost),
							accessPermissionName: PERMISSION_NAMES.SHOW_ASSET_PRICE,
						},
				  ]
				: []),
			...(asset.installDate
				? [
						{
							key: 'Install Date',
							value: (
								<DateTimeHover
									timestamp={nullSafeGet('installDate', asset)}
									showDate={true}
									showTime={false}
								/>
							),
						},
				  ]
				: []),
			...(asset.lifeExpectancyInMonths
				? [
						{
							key: 'Useful Life Expectancy (Months)',
							value: asset.lifeExpectancyInMonths,
						},
				  ]
				: []),
			...(getWarrantyStatus(serviceWarranty)
				? [
						{
							key: 'Service Warranty Status',
							value:
								getWarrantyStatus(serviceWarranty) === 'Active'
									? 'Under Warranty'
									: getWarrantyStatus(serviceWarranty) === 'Expired'
									? 'Warranty Expired'
									: 'Warranty Pending',
						},
				  ]
				: []),
			...(nullSafeGet('issueDate', serviceWarranty)
				? [
						{
							key: 'Service Warranty Start Date',
							value: (
								<DateTimeHover
									timestamp={nullSafeGet('issueDate', serviceWarranty)}
									showDate={true}
									showTime={false}
								/>
							),
						},
				  ]
				: []),
			...(nullSafeGet('expirationDate', serviceWarranty)
				? [
						{
							key: 'Service Warranty End Date',
							value: (
								<DateTimeHover
									timestamp={nullSafeGet('expirationDate', serviceWarranty)}
									showDate={true}
									showTime={false}
								/>
							),
						},
				  ]
				: []),
			...(nullSafeGet('provider.name', serviceWarranty)
				? [
						{
							key: 'Service Warranty Provider',
							value: nullSafeGet('provider.name', serviceWarranty),
						},
				  ]
				: []),
			...(nullSafeGet('NTEAmount', serviceWarranty)
				? [
						{
							key: 'Service Warranty Not-To-Exceed Amount',
							value: `${currency.format(serviceWarranty.NTEAmount)}`,
						},
				  ]
				: []),
			...(getWarrantyStatus(partWarranty)
				? [
						{
							key: 'Part Warranty Status',
							value:
								getWarrantyStatus(partWarranty) === 'Active'
									? 'Under Warranty'
									: getWarrantyStatus(partWarranty) === 'Expired'
									? 'Warranty Expired'
									: 'Warranty Pending',
						},
				  ]
				: []),
			...(nullSafeGet('issueDate', partWarranty)
				? [
						{
							key: 'Part Warranty Start Date',
							value: (
								<DateTimeHover
									timestamp={nullSafeGet('issueDate', partWarranty)}
									showDate={true}
									showTime={false}
								/>
							),
						},
				  ]
				: []),
			...(nullSafeGet('expirationDate', partWarranty)
				? [
						{
							key: 'Part Warranty End Date',
							value: (
								<DateTimeHover
									timestamp={nullSafeGet('expirationDate', partWarranty)}
									showDate={true}
									showTime={false}
								/>
							),
						},
				  ]
				: []),
			...(nullSafeGet('vendor.name', partWarranty)
				? [
						{
							key: 'Part Warranty Vendor',
							value: nullSafeGet('vendor.name', partWarranty),
						},
				  ]
				: []),
			...(asset.purchaseOrder && isSupplier
				? [
						{
							key: 'Purchase Order',
							value: (
								<HyperLink
									text={`PO ${getPurchaseOrderId(asset.purchaseOrder)}`}
									entityType={ENTITY_TYPE.PURCHASE_ORDER}
									entityId={asset.purchaseOrderId}
									backLinkText={'Back to Asset'}
								/>
							),
						},
				  ]
				: []),
		],
		[asset, currency, isSupplier, partWarranty, serviceWarranty]
	);

	const assetDetailWithAssetTypeDetails = useMemo(() => {
		const assetTypeDetails = nullSafeGetOrElse('assetTypeDetails', asset, []);

		const assetTypeKeyValuePair = assetTypeDetails.map((detail) => ({
			key: detail.key,
			value: detail.valueAsString,
		}));
		return [...assetDetails, ...assetTypeKeyValuePair];
	}, [asset, assetDetails]);

	return assetDetails.length > 0 ? (
		<div className="rowSpacing">
			<Card className="materialCard" bodyStyle={{ padding: 0 }}>
				<div className="materialCard__body">
					<TwoLineList
						listItems={assetDetailWithAssetTypeDetails}
						lineOneRender={(record) => record.value || '--'}
						lineTwoRender={(record) => record.key}
					/>
				</div>
			</Card>
		</div>
	) : null;
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

const ComponentWithoutUserType = withRouter(connect(mapStateToProps)(AssetDetailsCard));

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

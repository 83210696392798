import React, { FC } from 'react';
import { Spin } from 'antd';

const style = require('./PageLoadingPlaceholder.less');

export const PageLoadingPlaceholder: FC<{ style?: any; className?: any }> = (props) => {
	return (
		<div style={props.style} className={`pageLoadingPlaceholder ${props.className}`}>
			<Spin
				className="spinToWin"
				indicator={
					<img
						width="48px"
						height="auto"
						src="https://s3.amazonaws.com/emptor-data-assets/images/openwrench-logos/wrenchspinner.svg"
					/>
				}
				size="large"
			/>
		</div>
	);
};

import { PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS } from '../actions/planned_maintenance_schedules_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import FetchUtils, { parseJSON } from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { logoutSuccess } from '../actions/session_actions';
import { message } from 'antd';
import { capitalize } from '../utils/DataFormatterUtils';
import { TextButton } from '../components/text_button/TextButton';
import * as React from 'react';

export const plannedMaintenanceSchedulesRestCrudThunksForBuyer = new RestCrudThunks(
	'planned_maintenance_schedules',
	'planned_maintenance_schedule',
	'plannedMaintenanceSchedule',
	'plannedMaintenanceSchedules',
	PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'pm/'
);
export const plannedMaintenanceSchedulesRestCrudThunksForSupplier = new RestCrudThunks(
	'planned_maintenance_schedules',
	'planned_maintenance_schedule',
	'plannedMaintenanceSchedule',
	'plannedMaintenanceSchedules',
	PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'pm/'
);
const fetchUtils = new FetchUtils();
const baseUrl = getBackendUri();

export const bulkCreatePlannedMaintenanceSchedules =
	(roleType = 'buyer') =>
	(entity) => {
		const apiURL = `/api/v1/${roleType}/pm/planned_maintenance_schedules/bulk`;
		const baseUrl = getBackendUri();
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS.updateStart(entity));
				fetchUtils
					.post(baseUrl + apiURL, {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entity,
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (d.status === 'error') {
							dispatch(
								PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS.updateError(d.message, entity)
							);
							reject(
								'An error occurred in updating the selected planned maintenance schedules.' ||
									d.message
							);
						} else {
							dispatch(PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(
							PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS.updateError(d.message, entity)
						);
						reject(
							'An error occurred in updating the selected planned maintenance schedules.' ||
								d.message
						);
					});
			});
		};
	};

export const bulkUpdatePlannedMaintenanceSchedules =
	(roleType = 'buyer') =>
	(entity) => {
		const apiURL = `/api/v1/${roleType}/pm/planned_maintenance_schedules/bulk`;
		const baseUrl = getBackendUri();
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS.updateStart(entity));
				fetchUtils
					.put(baseUrl + apiURL, {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entity,
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (d.status === 'error') {
							dispatch(
								PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS.updateError(d.message, entity)
							);
							reject(
								'An error occurred in updating the selected planned maintenance schedules.' ||
									d.message
							);
						} else {
							dispatch(PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(
							PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS.updateError(d.message, entity)
						);
						reject(
							'An error occurred in updating the selected planned maintenance schedules.' ||
								d.message
						);
					});
			});
		};
	};

const getGroupedMaintenanceScheduleByTitleAndTemplateId =
	(roleType) =>
	(title, frequency, plannedMaintenanceTemplateId, basedOn, params = {}) => {
		const apiUrl = `/api/v1/${roleType}/pm/planned_maintenance_schedules/grouped`;

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS.fetchStart());

				fetchUtils
					.get(
						baseUrl + apiUrl + `/${title}/${frequency}/${plannedMaintenanceTemplateId}/${basedOn}`,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(
								PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS.fetchError(
									d.message,
									'template' + plannedMaintenanceTemplateId
								)
							);
							reject(d.message);
						} else {
							dispatch(PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS.fetchOneSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(
							PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS.fetchError(
								d.message,
								'template' + plannedMaintenanceTemplateId
							)
						);
						reject(d.message);
					});
			});
		};
	};

export const getGroupedMaintenanceScheduleForBuyer =
	getGroupedMaintenanceScheduleByTitleAndTemplateId(ROLE_TYPES.BUYER);
export const getGroupedMaintenanceScheduleForSupplier =
	getGroupedMaintenanceScheduleByTitleAndTemplateId(ROLE_TYPES.SUPPLIER);

const plannedMaintenanceScheduleStatusChange = (roleType) => (status) => (entity) => {
	const apiUrl = `/api/v1/${roleType}/pm/planned_maintenance_schedules/update_status/${status}`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.patch(baseUrl + apiUrl, { data: entity })
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(
							PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS.updateError(d.message, entity)
						);
						reject(d.message);
					} else {
						dispatch(PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(
						PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS.updateError(d.message, entity)
					);
					reject(d.message);
				});
		});
	};
};

const plannedMaintenanceScheduleStatusChangeForBuyer = plannedMaintenanceScheduleStatusChange(
	ROLE_TYPES.BUYER
);
const plannedMaintenanceScheduleStatusChangeForSupplier = plannedMaintenanceScheduleStatusChange(
	ROLE_TYPES.SUPPLIER
);
export const pausePlannedMaintenanceScheduleForBuyer =
	plannedMaintenanceScheduleStatusChangeForBuyer('paused');
export const activatePlannedMaintenanceScheduleForBuyer =
	plannedMaintenanceScheduleStatusChangeForBuyer('active');
export const deactivatePlannedMaintenanceScheduleForBuyer =
	plannedMaintenanceScheduleStatusChangeForBuyer('inactive');
export const pausePlannedMaintenanceScheduleForSupplier =
	plannedMaintenanceScheduleStatusChangeForSupplier('paused');
export const activatePlannedMaintenanceScheduleForSupplier =
	plannedMaintenanceScheduleStatusChangeForSupplier('active');
export const deactivatePlannedMaintenanceScheduleForSupplier =
	plannedMaintenanceScheduleStatusChangeForSupplier('inactive');

const plannedMaintenanceSkipSchedule = (roleType) => (entity) => {
	const apiUrl = `/api/v1/${roleType}/pm/planned_maintenance_schedules/skip_schedule`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.patch(baseUrl + apiUrl, { data: entity })
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(
							PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS.updateError(d.message, entity)
						);
						reject(d.message);
					} else {
						dispatch(PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(
						PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS.updateError(d.message, entity)
					);
					reject(d.message);
				});
		});
	};
};

export const plannedMaintenanceSkipScheduleForBuyer = plannedMaintenanceSkipSchedule(
	ROLE_TYPES.BUYER
);
export const plannedMaintenanceSkipScheduleForSupplier = plannedMaintenanceSkipSchedule(
	ROLE_TYPES.SUPPLIER
);

const plannedMaintenanceScheduleDelete =
	(roleType) =>
	(entity, primaryKeyName = 'id', secondPrimaryKeyName = null, showUndo = false) => {
		const apiUrl = `/api/v1/${roleType}/pm/planned_maintenance_schedules/delete_entity`;
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS.deleteStart(entity));
				const deleteUri = baseUrl + apiUrl;
				const deleteRecord = () =>
					fetchUtils
						.post(deleteUri, { data: entity })
						.then(parseJSON)
						.then(({ json, status, ok }) => {
							const d = json;
							if (status === 401) {
								dispatch(logoutSuccess());
							}
							if (d.status === 'error') {
								dispatch(
									PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS.deleteError(d.message, entity)
								);
								reject(d.message);
							} else {
								dispatch(PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS.deleteSuccess(d.data));
								resolve(d.data);
							}
						})
						.catch((d) => {
							dispatch(
								PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS.deleteError(d.message, entity)
							);
							reject(d.message);
							// @ts-ignore
							if (this && this.showMessages) {
								// @ts-ignore
								message.error(`Failed to delete ${this.singularHumanReadableEntityName}.`);
							}
						});
				const deleteTimout = setTimeout(deleteRecord, 4000);
				const cancelDelete = () => clearTimeout(deleteTimout);
				if (showUndo) {
					const hideUndo = message.success(
						// @ts-ignore
						<span>
							{capitalize(this.singularHumanReadableEntityName)} deleted.{' '}
							<TextButton
								onClick={() => {
									cancelDelete();
									hideUndo();
								}}
							>
								Undo
							</TextButton>
						</span>
					);
				}
			});
		};
	};

export const plannedMaintenanceScheduleDeleteForBuyer = plannedMaintenanceScheduleDelete(
	ROLE_TYPES.BUYER
);
export const plannedMaintenanceScheduleDeleteForSupplier = plannedMaintenanceScheduleDelete(
	ROLE_TYPES.SUPPLIER
);

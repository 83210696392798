import React, { FC } from 'react';

const FormDots: FC = (): React.ReactElement => {
	return (
		<div
			style={{
				overflow: 'hidden',
				display: 'inline-block',
				flex: '0 1 auto',
			}}
		>
			<div
				style={{
					display: 'block',
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'clip',
				}}
			>
				{Array.from(Array(1000).keys())
					.map((_) => ' .')
					.join(' ')}
			</div>
		</div>
	);
};

export default FormDots;

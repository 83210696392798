import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EmptyState } from '../empty_state/EmptyState';
import { Button, Card, Col, Layout, Popover, Row, Table, message } from 'antd';
import {
	journalRunsRestCrudThunksForSupplier,
	rerunJournalRunForSupplier,
	rollbackJournalRunForSupplier,
} from '../../thunks/journal_runs_thunks';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { DATE_ONLY_FORMAT, dateFormatter } from '../../utils/DataFormatterUtils';
import {
	DEFAULT_SORTER,
	JOURNAL_RUN_COMPLETED_STATUSES,
	JOURNAL_STATUSES,
} from '../../utils/DataConstants';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import JournalStatusDisplay from './JournalStatusDisplay';
import { openInNewTab } from '../../utils/HistoryUtils';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;

const TC_NAME = 'journalRunsIndex';

const LOADING_ACTION = {
	ROLLBACK: 'rollBack',
	RERUN: 'rerun',
};

const DepreciationJournalRunsPage: FC<any> = ({
	journalRunsFetching,
	journalRuns,
	rollback,
	rerun,
	fetchJournalRuns,
}): React.ReactElement => {
	const [updatingRecord, setUpdatingRecord] = useState<any>({});

	const records = getRecordsForTargetCollection(journalRuns, TC_NAME);

	const refreshJournalRuns = useCallback(() => {
		fetchJournalRuns(
			{},
			TC_NAME,
			{
				current: 1,
				pageSize: 200,
			},
			DEFAULT_SORTER
		);
	}, [fetchJournalRuns]);

	useEffect(() => {
		refreshJournalRuns();
	}, [refreshJournalRuns]);

	const canRollBack = useCallback(
		(record) => {
			const index = records.map((_) => _.id).indexOf(record.id);
			/*
		Rollback -> Any record which is completed/rerun_completed 
		and either previous record not available/previous record rollback_completed
		*/
			return (
				JOURNAL_RUN_COMPLETED_STATUSES.includes(record.status) &&
				(index == 0 ||
					nullSafeGet(`${index - 1}.status`, records) === JOURNAL_STATUSES.ROLLBACK_COMPLETED)
			);
		},
		[records]
	);

	const canRerun = useCallback(
		(record) => {
			const index = records.map((_) => _.id).indexOf(record.id);
			/*
			Rerun -> Any rolled back record and either next record not present/next record is rerun_completed/completed
		*/
			return (
				nullSafeGet('status', record) === JOURNAL_STATUSES.ROLLBACK_COMPLETED &&
				(index + 1 === records.length ||
					JOURNAL_RUN_COMPLETED_STATUSES.includes(nullSafeGet(`${index + 1}.status`, records)))
			);
		},
		[records]
	);

	const onRollBack = useCallback(
		(record) => () => {
			setUpdatingRecord({
				id: record.id,
				action: LOADING_ACTION.ROLLBACK,
			});
			rollback(record)
				.then(() => {
					message.success('Rollback started successfully!');
					refreshJournalRuns();
				})
				.catch(() => message.error('Unable to start rollback!'))
				.finally(() => setUpdatingRecord({}));
		},
		[refreshJournalRuns, rollback]
	);

	const onRerun = useCallback(
		(record) => () => {
			setUpdatingRecord({
				id: record.id,
				action: LOADING_ACTION.RERUN,
			});
			rerun(record)
				.then(() => {
					message.success('Rerun started successfully!');
					refreshJournalRuns();
				})
				.catch(() => message.error('Unable to start rerun!'))
				.finally(() => setUpdatingRecord({}));
		},
		[refreshJournalRuns, rerun]
	);

	const isLoading = useCallback(
		(record, checkAction) => {
			const { id, action } = updatingRecord;
			return id === record.id && checkAction === action;
		},
		[updatingRecord]
	);

	const onDownload = useCallback(
		(url) => () => {
			openInNewTab(url);
		},
		[]
	);

	const columns = useMemo(
		() => [
			{
				title: 'Run Date',
				dataIndex: 'depreciationRunAt',
				render: (date) => (date ? dateFormatter(date, DATE_ONLY_FORMAT) : '--'),
			},
			{
				title: 'Status',
				dataIndex: 'status',
				render: (status) => <JournalStatusDisplay status={status} />,
			},
			{
				title: 'Trigger Type',
				dataIndex: 'triggerType',
				render: (val) => {
					switch (val) {
						case 'manual':
							return 'Manual';
						default:
							return 'Automatic';
					}
				},
			},
			{
				title: 'Triggered By',
				dataIndex: 'triggeredBy',
				render: (val) => (val ? val : 'OpenWrench'),
			},
			{
				dataIndex: 'nbvFileUrl',
				render: (fileURL, record) => (
					<div>
						<Popover
							content={
								canRollBack(record) === record.id ? '' : 'You can rollback only last completed run!'
							}
							trigger="hover"
						>
							<Button
								loading={isLoading(record, LOADING_ACTION.ROLLBACK)}
								disabled={!canRollBack(record)}
								type="ghost"
								onClick={onRollBack(record)}
							>
								Rollback
							</Button>
						</Popover>
						<Popover
							content={canRerun(record) ? '' : 'You can rerun only rolled back runs!'}
							trigger="hover"
						>
							<Button
								loading={isLoading(record, LOADING_ACTION.RERUN)}
								disabled={!canRerun(record)}
								className="ml-2"
								type="primary"
								onClick={onRerun(record)}
							>
								Rerun
							</Button>
						</Popover>
						<Button
							className="ml-2"
							type="primary"
							onClick={onDownload(fileURL)}
							disabled={!fileURL}
						>
							Download
						</Button>
					</div>
				),
			},
		],
		[canRerun, canRollBack, isLoading, onDownload, onRerun, onRollBack]
	);

	return (
		<Content
			className="paginated-redux-table-with-header"
			style={{
				padding: '0 0.5em',
			}}
		>
			<LogOnMountWithStandardEventProperties eventType={`Visited Journal Runs page`} />
			<Row style={{ margin: '0.5em -8px' }} gutter={16}>
				<Col span={24}>
					<Card bodyStyle={{ padding: 8 }}>
						<Table
							loading={journalRunsFetching}
							showHeader
							dataSource={records}
							columns={columns}
							rowKey={(record) => record.id}
							locale={{
								emptyText: (
									<EmptyState
										graphic={
											<img
												alt="No Data"
												style={{ marginBottom: 8 }}
												src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
											/>
										}
										headline={'No Run histories found'}
										body={null}
									/>
								),
							}}
						/>
					</Card>
				</Col>
			</Row>
		</Content>
	);
};

const mapStateToProps = (state) => ({
	journalRuns: state.journal_runs,
	journalRunsFetching: state.journal_runs.fetching,
});

const mapDispatchToProps = (dispatch) => ({
	fetchJournalRuns: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			journalRunsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	rollback: (entity) => dispatch(rollbackJournalRunForSupplier(entity)),
	rerun: (entity) => dispatch(rerunJournalRunForSupplier(entity)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(DepreciationJournalRunsPage)
);

import * as React from 'react';
import { Card, Col, Popover, Row } from 'antd';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';

const BudgetHeaderDisplayCard = ({ title, amount, span, message, color = 'black' }) => {
	return (
		<Col span={span}>
			<Card
				title={
					<span>
						{title}
						<Popover style={{ marginTop: '-8px' }} content={message}>
							<span style={{ fontSize: '12px', color: 'rgba(0,0,0,0.6)', marginLeft: 8 }}>
								<InfoCircleOutlined />
							</span>
						</Popover>
					</span>
				}
			>
				<Row className={'items-end justify-between'}>
					<h5 className={'mb-0 font-bold'} style={{ color }}>
						{amount}
					</h5>
				</Row>
			</Card>
		</Col>
	);
};

const mapStateToProps = (state, ownProps) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BudgetHeaderDisplayCard));

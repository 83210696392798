import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { DownloadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Layout, Row, Col, Button, Card, Popover, Select, Input } from 'antd';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import {
	assetsRestCrudThunksForSupplier,
	downloadAssetCSVForSupplier,
} from '../../thunks/assets_thunks';
import { EmptyState } from '../empty_state/EmptyState';
import SubnavBar from '../subnav_bar/SubnavBar';
import { AdvancedFilters } from '../advanced_filters/AdvancedFilters';
import { ASSETS_CRUD_ACTION_CREATORS } from '../../actions/assets_actions';
import {
	nullSafeGet,
	nullSafeGetOrElse,
	changeFilterValueToArrayOfIds,
	getStartDateFieldName,
	getEndDateFieldName,
	getTupleNameFieldName,
	getTupleValueFieldName,
} from '../../utils/DataAccessUtils';
import { locationsRestCrudThunksForSupplier } from '../../thunks/locations_thunks';
import { assetTypesRestCrudThunksForSupplier } from '../../thunks/asset_types_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { regionsRestCrudThunksForSupplier } from '../../thunks/regions_thunks';
import RegionsLocationsTreeSelect from '../regions_tree_select/SupplierRegionsLocationsTreeSelect';
import OWAsyncTreeSelect from '../ow_async_tree_select/OWAsyncTreeSelect';
import OWRadioGroup from '../ow_radio_group/OWRadioGroup';
import { FILTER_CONFIG_NAMES, FILTER_FIELD_TYPE } from '../../utils/DataConstants';
import SelectedFilters from '../selected_filters_pills/SelectedFilters';
import { depreciationClassesRestCrudThunksForSupplier } from '../../thunks/depreciation_class_thunks';
import OWDateRangePicker from '../ow_date_range_picker/OWDateRangePicker';
import moment from 'moment';
import { formatDateForParam } from '../../utils/DataFormatterUtils';
import { downloadAssetNBVCSVForSupplier } from '../../thunks/asset_nbvs_thunks';
import AssetWithQRCodeDisplay from '../asset_row_display/AssetWithQRCodeDisplay';
import { assetTypesAdditionalFieldsRestCrudThunksForSupplier } from '../../thunks/asset_types_additional_fields_thunks';
import OWAsyncTupleSelect from '../ow_async_select/OWAsyncTupleSelect';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import {
	getIsActiveFilterField,
	INITIAL_ACTIVE_FILTERS,
	IS_ACTIVE_FILTER_ITEMS,
} from '../../utils/FilterUtils';

const queryString = require('qs');
const { Content } = Layout;

require('./SupplierAssetsIndexPage.less');

const ASSETS_OVERVIEW_TARGET_COLLECTION = 'assetsIndex';
const ASSETS_ADVANCED_FILTERS = 'assetsAdvancedFilters';

class SupplierAssetsIndexPage extends React.Component<any, any> {
	initialPagination: any;
	initialFilters: any;

	state = {
		showDepreciationClassFilter: false,
		nbvDownloading: false,
	};

	constructor(props) {
		super(props);
		const { location, history } = props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		const { current, pageSize, total, sort_by, order, ...assetFilters } = queryParams;
		this.initialFilters = assetFilters || {};
		this.initialPagination = {
			current: current ? parseInt(current) : 1,
			pageSize: pageSize ? parseInt(pageSize) : 10,
		};
		history.push(`/supplier/assets/overview/all?${new URLSearchParams(location.search)}`);
	}

	downloadCSV = () => {
		const { assets, downloadAssetCSV } = this.props;
		const filters = assets[ASSETS_OVERVIEW_TARGET_COLLECTION].filters;
		downloadAssetCSV({}, filters);
	};

	componentDidMount() {
		const { fetchDepreciationClasses } = this.props;
		fetchDepreciationClasses({}, undefined, { pageSize: 1 }).then(
			(res) =>
				nullSafeGet('length', res) > 0 && this.setState({ showDepreciationClassFilter: true })
		);
	}

	downloadNBVCsv = () => {
		const { assets, downloadAssetNBV } = this.props;
		const filters = assets[ASSETS_OVERVIEW_TARGET_COLLECTION].filters;
		this.setState({ nbvDownloading: true });
		downloadAssetNBV({}, filters).finally(() => this.setState({ nbvDownloading: false }));
	};

	render() {
		const {
			history,
			location,
			clearAndUpdateFilters,
			assets,
			fetchLocations,
			fetchMultipleLocations,
			locations,
			fetchAssetTypes,
			fetchMultipleAssetTypes,
			assetTypes,
			updateAssetsFilters,
			regions,
			fetchRegions,
			fetchMultipleRegions,
			depreciationClasses,
			fetchDepreciationClasses,
			fetchMultipleDepreciationClasses,
			assetTypesAdditionalFields,
			fetchAssetTypesAdditionalFields,
			fetchMultipleAssetTypesAdditionalFields,
		} = this.props;

		const onRow = (record) => ({
			onClick: () => {
				let searchString = location.search;
				if (searchString[0] === '?') {
					searchString = searchString.slice(1);
				}
				let backUrl = `/supplier/assets/detail/${record.id}/details`;
				if (searchString && searchString.length > 0) {
					backUrl = backUrl.concat(`?backlinkParams=${encodeURIComponent(searchString)}`);
				}
				history.push(backUrl);
			},
		});

		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				defaultSortOrder: 'ascend',
				render: (text, record) => <AssetWithQRCodeDisplay asset={record} />,
			},
		];

		const filterConfig = [
			{
				label: 'Location',
				fieldName: 'locationIds',
				stateSlice: locations,
				hideFilter: true,
				filtersValueAccessor: (filtersStateSlice) => {
					const locationIds = nullSafeGet(
						`${ASSETS_OVERVIEW_TARGET_COLLECTION}.filters.locationIds`,
						filtersStateSlice
					);
					const finalIds = nullSafeGetOrElse(
						`${ASSETS_OVERVIEW_TARGET_COLLECTION}.filters.regionIds`,
						filtersStateSlice,
						locationIds
					);
					return changeFilterValueToArrayOfIds(finalIds);
				},
				keyAccessor: (loc) => loc.id,
				valueAccessor: (loc) => loc.id,
				handleChange: (ids) => {
					updateAssetsFilters(
						{
							locationIds: ids.join(',') || undefined,
							regionIds: undefined,
						},
						ASSETS_OVERVIEW_TARGET_COLLECTION
					);
				},
				targetCollectionName: 'assetsAdvancedFilters',
				fetch: fetchLocations,
				fetchMultiple: fetchMultipleLocations,
				renderItem: (loc) => loc.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
			},
			{
				label: 'Asset Type',
				fieldName: FILTER_CONFIG_NAMES.ASSET_TYPE,
				mode: 'multiple',
				stateSlice: assetTypes,
				filtersValueAccessor: (filtersStateSlice) => {
					const ids = nullSafeGet(
						`${ASSETS_OVERVIEW_TARGET_COLLECTION}.filters.assetTypeIds`,
						filtersStateSlice
					);
					return changeFilterValueToArrayOfIds(ids);
				},
				keyAccessor: (assetType) => assetType.id,
				valueAccessor: (assetType) => assetType.id,
				handleChange: (ids) => {
					updateAssetsFilters(
						{
							assetTypeIds: ids.join(',') || undefined,
						},
						ASSETS_OVERVIEW_TARGET_COLLECTION
					);
				},
				targetCollectionName: 'assetsAdvancedFilters',
				fetch: fetchAssetTypes,
				fetchMultiple: fetchMultipleAssetTypes,
				renderItem: (assetType) => assetType.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
				component: OWAsyncTreeSelect,
			},
			{
				component: Input.Search,
				placeholder: 'Search for a manufacturer',
				handleChange: (e) => {
					updateAssetsFilters(
						{
							manufacturer: e.target.value || undefined,
						},
						ASSETS_OVERVIEW_TARGET_COLLECTION
					);
				},
				label: 'Manufacturer',
				fieldName: 'manufacturer',
				type: FILTER_FIELD_TYPE.SEARCH,
				filtersValueAccessor: (filtersStateSlice) =>
					nullSafeGet(`${ASSETS_OVERVIEW_TARGET_COLLECTION}.filters.manufacturer`, assets),
				renderItem: (loc) => loc,
				valueAccessor: (loc) => loc,
			},
			...(this.state.showDepreciationClassFilter
				? [
						{
							label: 'Depreciation Class',
							fieldName: 'depreciationClassIds',
							mode: 'multiple',
							stateSlice: depreciationClasses,
							filtersValueAccessor: (filtersStateSlice) => {
								const ids = nullSafeGet(
									`${ASSETS_OVERVIEW_TARGET_COLLECTION}.filters.depreciationClassIds`,
									filtersStateSlice
								);
								return changeFilterValueToArrayOfIds(ids);
							},
							keyAccessor: (dc) => dc.id,
							valueAccessor: (dc) => dc.id,
							handleChange: (ids) => {
								updateAssetsFilters(
									{
										depreciationClassIds: ids.join(',') || undefined,
									},
									ASSETS_OVERVIEW_TARGET_COLLECTION
								);
							},
							targetCollectionName: ASSETS_ADVANCED_FILTERS,
							fetch: fetchDepreciationClasses,
							fetchMultiple: fetchMultipleDepreciationClasses,
							renderItem: (dc) => dc.name,
							sortBy: { sort_by: 'name', order: 'ascend' },
							component: OWAsyncTreeSelect,
						},
				  ]
				: []),
			{
				label: 'Region',
				mode: 'multiple',
				stateSlice: regions,
				hideFilter: true,
				filtersValueAccessor: (filtersStateSlice) => {
					const ids = nullSafeGet(
						`${ASSETS_OVERVIEW_TARGET_COLLECTION}.filters.regionIds`,
						filtersStateSlice
					);
					return changeFilterValueToArrayOfIds(ids);
				},
				keyAccessor: (region) => region.id,
				valueAccessor: (region) => region.id,
				handleChange: (ids) => {
					updateAssetsFilters(
						{
							regionIds: ids.join(',') || undefined,
						},
						ASSETS_OVERVIEW_TARGET_COLLECTION
					);
				},
				targetCollectionName: 'assetsAdvancedFilters',
				fetch: fetchRegions,
				fetchMultiple: fetchMultipleRegions,
				renderItem: (region) => region.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
			},
			{
				label: 'Additional Fields',
				fieldName: FILTER_CONFIG_NAMES.ADDITIONAL_FIELD,
				type: FILTER_FIELD_TYPE.TUPLE,
				stateSlice: assetTypesAdditionalFields,
				filtersValueAccessor: (filtersStateSlice) => {
					const name = nullSafeGet(
						`${ASSETS_OVERVIEW_TARGET_COLLECTION}.filters.${getTupleNameFieldName(
							FILTER_CONFIG_NAMES.ADDITIONAL_FIELD
						)}`,
						filtersStateSlice
					);
					const value = nullSafeGet(
						`${ASSETS_OVERVIEW_TARGET_COLLECTION}.filters.${getTupleValueFieldName(
							FILTER_CONFIG_NAMES.INSTALL_AT
						)}`,
						filtersStateSlice
					);
					return [name, value];
				},
				handleChange: (values) => {
					updateAssetsFilters(
						{
							[getTupleNameFieldName(FILTER_CONFIG_NAMES.ADDITIONAL_FIELD)]: nullSafeGetOrElse(
								'0',
								values,
								undefined
							),
							[getTupleValueFieldName(FILTER_CONFIG_NAMES.ADDITIONAL_FIELD)]: nullSafeGetOrElse(
								'1',
								values,
								undefined
							),
						},
						ASSETS_OVERVIEW_TARGET_COLLECTION
					);
				},
				targetCollectionName: 'assetsAdvancedFilters',
				fetch: fetchAssetTypesAdditionalFields,
				fetchMultiple: fetchMultipleAssetTypesAdditionalFields,
				renderRecord: (entity) => (
					<Select.Option key={entity.id} value={entity.id}>
						<div className="flex flex-row items-center">
							<div>{entity.name}</div>
						</div>
					</Select.Option>
				),
				valueAccessor: (item) => item.name,
				component: OWAsyncTupleSelect,
			},
			{
				label: 'Installed Between',
				labelPrefix: 'Installed',
				fieldName: FILTER_CONFIG_NAMES.INSTALL_AT,
				type: FILTER_FIELD_TYPE.DATE_RANGE,
				filtersValueAccessor: (filtersStateSlice) => {
					const startDate = nullSafeGet(
						`${ASSETS_OVERVIEW_TARGET_COLLECTION}.filters.${getStartDateFieldName(
							FILTER_CONFIG_NAMES.INSTALL_AT
						)}`,
						filtersStateSlice
					);
					const endDate = nullSafeGet(
						`${ASSETS_OVERVIEW_TARGET_COLLECTION}.filters.${getEndDateFieldName(
							FILTER_CONFIG_NAMES.INSTALL_AT
						)}`,
						filtersStateSlice
					);
					return [startDate && moment(startDate), endDate && moment(endDate)];
				},
				handleChange: (values) => {
					updateAssetsFilters(
						{
							[getStartDateFieldName(FILTER_CONFIG_NAMES.INSTALL_AT)]:
								values && values.length > 0 && values[0]
									? formatDateForParam(values[0])
									: undefined,
							[getEndDateFieldName(FILTER_CONFIG_NAMES.INSTALL_AT)]:
								values && values.length > 1 && values[1]
									? formatDateForParam(values[1])
									: undefined,
						},
						ASSETS_OVERVIEW_TARGET_COLLECTION
					);
				},
				renderRecord: (item) => item,
				valueAccessor: (item) => item,
				component: OWDateRangePicker,
			},
			{
				fieldName: 'hasWarranty',
				type: 'radioGroup',
				items: [
					{
						label: 'All',
						value: 'undefined',
					},
					{
						label: 'Under Warranty',
						value: 'true',
					},
				],
				filtersValueAccessor: (filtersStateSlice) => {
					const filters = nullSafeGet(
						`${ASSETS_OVERVIEW_TARGET_COLLECTION}.filters`,
						filtersStateSlice
					);
					return typeof filters.hasWarranty === 'undefined'
						? 'undefined'
						: `${filters.hasWarranty}`;
				},
				defaultValue: undefined,
				keyAccessor: (item) => item.value,
				valueAccessor: (item) => item.value,
				handleChange: (hasWarranty) => {
					const val = hasWarranty === 'undefined' ? undefined : true;
					updateAssetsFilters({ hasWarranty: val }, ASSETS_OVERVIEW_TARGET_COLLECTION);
				},
				targetCollectionName: 'assetsAdvancedFilters',
				renderRecord: (item) => item.label,
				component: OWRadioGroup,
				info: [
					{
						title: 'Under Warranty',
						description: `Assets that are under warranty`,
					},
				],
			},
			{
				fieldName: 'downtimeStatus',
				type: 'radioGroup',
				items: [
					{
						label: 'All',
						value: undefined,
					},
					{
						label: 'Online',
						value: 'online',
					},
					{
						label: 'Offline',
						value: 'offline',
					},
				],
				filtersValueAccessor: (filtersStateSlice) =>
					nullSafeGet(
						`${ASSETS_OVERVIEW_TARGET_COLLECTION}.filters.downtimeStatus`,
						filtersStateSlice
					),
				keyAccessor: (item) => item.value,
				valueAccessor: (item) => item.value,
				handleChange: (downtimeStatus) =>
					updateAssetsFilters({ downtimeStatus }, ASSETS_OVERVIEW_TARGET_COLLECTION),
				targetCollectionName: ASSETS_OVERVIEW_TARGET_COLLECTION,
				renderRecord: (item) => item.label,
				component: OWRadioGroup,
				info: [
					{
						title: 'Online',
						description: 'Asset is working and in good condition.',
					},
					{
						title: 'Offline',
						description: 'Asset is not working at this moment.',
					},
				],
			},
			...[getIsActiveFilterField(updateAssetsFilters, ASSETS_OVERVIEW_TARGET_COLLECTION)],
		];

		return (
			<Content className="assetsIndexPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited supplier assets index page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '0.5em -8px' }}>
					<Col span={24}>
						<SubnavBar
							left={
								<div>
									<AdvancedFilters
										initialFilters={{ ...this.initialFilters, ...INITIAL_ACTIVE_FILTERS }}
										preAppliedFilters={INITIAL_ACTIVE_FILTERS}
										updateFilters={updateAssetsFilters}
										filterConfig={filterConfig}
										filtersTargetCollectionName={ASSETS_OVERVIEW_TARGET_COLLECTION}
										clearAndUpdateFilters={clearAndUpdateFilters}
										filtersStateSlice={assets}
									>
										<Form.Item label="Location">
											<RegionsLocationsTreeSelect
												mode="multiple"
												filtersSlice={assets}
												filtersValueAccessor={(filtersStateSlice) => {
													const locationIds = nullSafeGet(
														`${ASSETS_OVERVIEW_TARGET_COLLECTION}.filters.locationIds`,
														filtersStateSlice
													);
													const finalIds = nullSafeGetOrElse(
														`${ASSETS_OVERVIEW_TARGET_COLLECTION}.filters.regionIds`,
														filtersStateSlice,
														locationIds
													);
													return changeFilterValueToArrayOfIds(finalIds);
												}}
												onChange={(ids) => {
													updateAssetsFilters(
														{
															locationIds: ids.join(',') || undefined,
															regionIds: undefined,
														},
														ASSETS_OVERVIEW_TARGET_COLLECTION
													);
												}}
												targetCollectionName={'assetsAdvancedFilters'}
											/>
										</Form.Item>
									</AdvancedFilters>
								</div>
							}
							right={
								<Col style={{ textAlign: 'right' }}>
									<AccessPermissionChecker name={PERMISSION_NAMES.SHOW_ASSET_PRICE}>
										<Popover content="Download Net Book Values" trigger="hover">
											<Button
												loading={this.state.nbvDownloading}
												style={{ marginRight: 12 }}
												size="large"
												icon={<DownloadOutlined translate="" />}
												className="inline-block-visible-md"
												onClick={this.downloadNBVCsv}
											>
												<span className="inline-block-visible-xxl">Net Book Value</span>
											</Button>
										</Popover>
									</AccessPermissionChecker>
									<Button
										style={{ marginRight: 12 }}
										size="large"
										icon={<DownloadOutlined translate="" />}
										className="inline-block-visible-md"
										onClick={this.downloadCSV}
									>
										<span className="inline-block-visible-xxl">Export CSV</span>
									</Button>
									<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_ASSETS}>
										<Button
											size="large"
											type="primary"
											onClick={() => this.props.history.push('/supplier/assets/overview/new')}
										>
											Add Asset
										</Button>
									</AccessPermissionChecker>
								</Col>
							}
						/>
					</Col>
				</Row>
				<div className="flex w-full flex-1 flex-row">
					<SelectedFilters
						updateFilters={(filters) =>
							updateAssetsFilters(filters, ASSETS_OVERVIEW_TARGET_COLLECTION)
						}
						filterValues={nullSafeGetOrElse(
							`${ASSETS_OVERVIEW_TARGET_COLLECTION}.filters`,
							assets,
							{}
						)}
						filterConfig={filterConfig}
					/>
				</div>
				<Row style={{ margin: '0.5em -8px' }}>
					<Col span={24}>
						<Card bodyStyle={{ padding: 8 }} className="remove-table-cell-padding">
							<PaginatedReduxTable
								updateQueryParams={true}
								emptyState={
									<EmptyState
										graphic={
											<img
												style={{ marginBottom: 8 }}
												src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
												alt="Add your first asset"
											/>
										}
										headline={"It's a little lonely in here."}
										body={
											<div style={{ textAlign: 'center' }}>
												<div style={{ maxWidth: 440, marginBottom: 16 }}>
													Looks like your team has not added any assets yet.
												</div>
												<Button
													type="primary"
													onClick={() => this.props.history.push('/supplier/assets/overview/new')}
												>
													Add your first asset
												</Button>
											</div>
										}
									/>
								}
								collectionName="assets"
								onRow={onRow}
								showHeader={false}
								targetCollectionName="assetsIndex"
								columns={columns}
								keyAccessor={(el) => el.id}
								initialFilters={{ ...this.initialFilters, ...INITIAL_ACTIVE_FILTERS }}
								initialPagination={this.initialPagination}
								fetchData={assetsRestCrudThunksForSupplier.read}
							/>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state) => ({
	assets: state.assets,
	assetTypes: state.asset_types,
	regions: state.regions,
	locations: state.locations,
	depreciationClasses: state.depreciation_classes,
	assetTypesAdditionalFields: state.asset_types_additional_fields,
	currentUser: state.session.currentUser,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	updateAssetsFilters: (filters, targetCollection) =>
		dispatch(ASSETS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(ASSETS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)),
	fetchMultipleAssetTypes: (ids, targetCollectionName) =>
		dispatch(assetTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchAssetTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			assetTypesRestCrudThunksForSupplier.readLite(
				{ ...params, no_pagination: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleAssetTypesAdditionalFields: (ids, targetCollectionName) =>
		dispatch(
			assetTypesAdditionalFieldsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
		),
	fetchAssetTypesAdditionalFields: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			assetTypesAdditionalFieldsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			locationsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchRegions: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			regionsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleRegions: (ids, targetCollectionName) =>
		dispatch(regionsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	downloadAssetCSV: (params, filters) => dispatch(downloadAssetCSVForSupplier(params, filters)),
	fetchDepreciationClasses: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			depreciationClassesRestCrudThunksForSupplier.readLite(
				{ ...params, no_pagination: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleDepreciationClasses: (ids, targetCollectionName) =>
		dispatch(depreciationClassesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	downloadAssetNBV: (params, filters) => dispatch(downloadAssetNBVCSVForSupplier(params, filters)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(SupplierAssetsIndexPage)
);

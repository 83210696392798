import React, { FC } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const OWRichTextEditor: FC<any> = ({ initialEditorState, editorRef }): React.ReactElement => {
	return (
		<Editor
			tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
			onInit={(evt, editor) => (editorRef.current = editor)}
			initialValue={initialEditorState}
			init={{
				height: 240,
				menubar: false,
				plugins: [
					'autolink',
					'lists',
					'link',
					'image',
					'charmap',
					'preview',
					'anchor',
					'visualblocks',
					'code',
					'insertdatetime',
					'media',
					'table',
					'code',
				],
				toolbar:
					'undo redo | formatselect | bold italic underline | table | bullist numlist | ' +
					'removeformat',
				content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
			}}
		/>
	);
};

export default OWRichTextEditor;

import React from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Layout, Row, Col, Button, Card, message, Modal, Popconfirm } from 'antd';
import { EmptyState } from '../empty_state/EmptyState';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import {
	addNewSupplierForBuyers,
	createSupplierPrivateNetworkBulkForBuyer,
	createSupplierPrivateNetworkBulkForSupplier,
	deleteBuyerSupplierRelationshipBySupplierFacilityIdForBuyer,
	deleteBuyerSupplierRelationshipBySupplierFacilityIdForSupplier,
	fetchSuppliersForBuyers,
	inviteSupplierUserFromBuyer,
	supplierPrivateNetworkGroupedUpdateForBuyer,
	supplierPrivateNetworkGroupedUpdateForSupplier,
	supplierPrivateNetworksRestCrudThunksForBuyer,
	supplierPrivateNetworksRestCrudThunksForSupplier,
	supplierPrivateNetworksV2ForBuyer,
	supplierPrivateNetworksV2ForSupplier,
	supplierPrivateNetworkV2UpdateForBuyer,
	supplierPrivateNetworkV2UpdateForSupplier,
} from '../../thunks/supplier_private_networks_thunks';
import { FlexibleImageAvatar } from '../flexible-image-avatar/FlexibleImageAvatar';
import { AddressDisplay } from '../address_display/AddressDisplay';
import { capitalizeEachWord, stringArrToList } from '../../utils/DataFormatterUtils';
import {
	changeFilterValueToArrayOfIds,
	nullSafeGet,
	nullSafeGetOrElse,
	stopDefaultEvents,
} from '../../utils/DataAccessUtils';
import SupplierPrivateNetworkCreateForm from '../supplier_private_network_create_form/SupplierPrivateNetworkCreateForm';
import SupplierPrivateNetworkEditForm from '../supplier_private_network_edit_form/SupplierPrivateNetworkEditForm';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import SubnavBar from '../subnav_bar/SubnavBar';
import { AdvancedFilters } from '../advanced_filters/AdvancedFilters';
import RegionsLocationsTreeSelect from '../regions_tree_select/BuyerRegionsLocationsTreeSelect';
import { SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS } from '../../actions/supplier_private_networks_actions';
import {
	locationsRestCrudThunksForBuyer,
	locationsRestCrudThunksForSupplier,
} from '../../thunks/locations_thunks';
import SelectSupplierCard from '../select_supplier_card_display/SelectSupplierCard';
import InviteSupplierForm from '../invite_supplier_form/InviteSupplierForm';
import {
	regionsRestCrudThunksForBuyer,
	regionsRestCrudThunksForSupplier,
} from '../../thunks/regions_thunks';
import SelectedFilters from '../selected_filters_pills/SelectedFilters';
import { BUYER_ADMIN, SUPER_ADMIN } from '../roles/roles';
import { FILTER_FIELD_TYPE, ROLE_TYPES } from '../../utils/DataConstants';
import { withRouter } from 'react-router';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import { getAllSupplierPrivateNetworkEntities } from '../supplier_private_network_create_form/supplier_private_network_utils';
import OWRadioGroup from '../ow_radio_group/OWRadioGroup';

const { Content } = Layout;
const queryString = require('qs');
require('./PrivateNetworkIndexPage.less');

const SUPPLIER_PRIVATE_NETWORKS_INDEX_TARGET_COLLECTION = 'supplierPrivateNetworksIndex';
const SUPPLIER_PRIVATE_NETWORKS_SEARCH_TARGET_COLLECTION = 'supplierPrivateNetworksSearch';

class PrivateNetworkIndexPage extends React.Component<any, any> {
	initialPagination: any = { current: 1, pageSize: 10 };
	initialFilters: any = {};
	formRefs: any;

	constructor(props) {
		super(props);
		const { updateSupplierPrivateNetworksFilters, location, history, userType } = props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		const { current, pageSize, total, sort_by, order, ...networkFilters } = queryParams;
		this.initialPagination = { current: current ? current : 1, pageSize: pageSize ? pageSize : 10 };
		this.initialFilters =
			networkFilters && Object.keys(networkFilters).length > 0
				? {
						...networkFilters,
				  }
				: {};
		this.formRefs = {};
		updateSupplierPrivateNetworksFilters(
			this.initialFilters,
			SUPPLIER_PRIVATE_NETWORKS_INDEX_TARGET_COLLECTION
		);
		history.push(
			`/${userType}/suppliers/overview/privateNetwork?${new URLSearchParams(location.search)}`
		);

		this.state = {
			supplierPrivateNetworkCreateFormVisible: false,
			supplierPrivateNetworkEditFormVisible: false,
			supplierFacilityId: undefined,
			supplierFacility: null,
			infoModalVisible: false,
			selectSupplierModalVisible: false,
			inviteSupplierModalVisible: false,
			messageModalVisible: false,
			selectSuppliers: [],
			supplierInfo: {},
			removingFacilityId: null,
			updatingPrivateNetwork: false,
			creatingPrivateNetwork: false,
		};
	}

	componentDidMount() {}

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	handleSupplierPrivateNetworkCreateSubmit = () => {
		const { createSupplierPrivateNetworkBulk, currentUser, inviteSupplierUser } = this.props;
		const { supplierInfo } = this.state;
		const form = this.formRefs['supplierPrivateNetworkCreateForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			this.setState({ creatingPrivateNetwork: true });

			const allSNs = getAllSupplierPrivateNetworkEntities(values);

			createSupplierPrivateNetworkBulk(allSNs)
				.then(() => {
					this.setState({ supplierPrivateNetworkCreateFormVisible: false });
					this.refreshSupplierPrivateNetwork();
					if (supplierInfo.primaryContactEmail) {
						inviteSupplierUser(
							values.supplierFacilityId,
							currentUser.email,
							supplierInfo.primaryContactEmail,
							supplierInfo.primaryContactFirstName,
							supplierInfo.primaryContactLastName
						).then(() => {
							message.success(
								`${supplierInfo.primaryContactFirstName} ${supplierInfo.primaryContactLastName} has been sent an invite to join OpenWrench at the email address: ${supplierInfo.primaryContactEmail}`
							);
						});
					}
				})
				.finally(() => this.setState({ creatingPrivateNetwork: false }));
		});
	};

	handleSupplierPrivateNetworkCreateCancel = (e) => {
		this.setState({
			supplierPrivateNetworkCreateFormVisible: false,
		});
		const form = this.formRefs['supplierPrivateNetworkCreateForm'].props.form;
		form.resetFields();
	};

	showSupplierPrivateNetworkCreateModal = (record) => {
		this.setState({
			supplierPrivateNetworkCreateFormVisible: true,
		});
	};

	refreshSupplierPrivateNetwork = () => {
		const { fetchSupplierPrivateNetworkV2, supplierPrivateNetworks } = this.props;
		fetchSupplierPrivateNetworkV2(
			null,
			SUPPLIER_PRIVATE_NETWORKS_INDEX_TARGET_COLLECTION,
			this.initialPagination,
			nullSafeGetOrElse('supplierPrivateNetworksIndex.sorting', supplierPrivateNetworks, {}),
			nullSafeGetOrElse('supplierPrivateNetworksIndex.filters', supplierPrivateNetworks, {})
		);
	};

	handleSupplierPrivateNetworkEditSubmit = (values) => {
		this.setState({ updatingPrivateNetwork: true });
		const { updateGroupedSupplierPrivateNetwork } = this.props;
		const locationIdSeq = values.allLocations
			? []
			: nullSafeGet('locationIds.0', values)
			? values.locationIds
			: undefined;
		const problemTypeIdSeq = values.allProblemTypes
			? []
			: nullSafeGet('problemTypeIds.0', values)
			? values.problemTypeIds
			: undefined;

		const noLocationOrProblemTypeAccess = !locationIdSeq && !problemTypeIdSeq;

		const entity = {
			supplierFacilityId: values.supplierFacilityId,
			...(!noLocationOrProblemTypeAccess && { problemTypeIdSeq }),
			...(!noLocationOrProblemTypeAccess && { locationIdSeq }),
			noLocationOrProblemTypeAccess,
			buyerCompanyId: values.buyerCompanyId,
		};

		const fn = updateGroupedSupplierPrivateNetwork(values.supplierFacilityId, entity);

		Promise.all([fn])
			.then(() => {
				this.setState({ supplierPrivateNetworkEditFormVisible: false });
				this.refreshSupplierPrivateNetwork();
				message.success(`updated supplier in your private network.`);
			})
			.finally(() => this.setState({ updatingPrivateNetwork: false }));
	};

	handleSupplierPrivateNetworkEditCancel = (e) => {
		this.setState({
			supplierPrivateNetworkEditFormVisible: false,
			supplierFacilityId: null,
			supplierFacility: null,
		});
	};

	removeFromSupplierPrivateNetwork = (supplierFacilityId) => {
		const { deleteBuyerSupplierRelationship } = this.props;

		this.setState({ removingFacilityId: supplierFacilityId });
		deleteBuyerSupplierRelationship(supplierFacilityId)
			.then(() => {
				this.refreshSupplierPrivateNetwork();
				message.success(`Deleted supplier from your private network.`);
			})
			.finally(() => this.setState({ removingFacilityId: null }));
	};

	showSupplierPrivateNetworkEditModal = (record) => {
		this.setState({
			supplierPrivateNetworkEditFormVisible: true,
			supplierFacilityId: record.supplierFacilityId,
			supplierFacility: record,
		});
	};

	showInfoModal = (record) => {
		this.setState({ infoModalVisible: true });
	};
	handleCloseInfoModal = () => {
		this.setState({ infoModalVisible: false });
	};

	submitNewSupplierInfo = (supplier) => {
		const { fetchSuppliers } = this.props;
		this.setState({ supplierInfo: supplier }, () => {
			if (supplier.primaryContactEmail) {
				Promise.all([
					fetchSuppliers(
						supplier.name,
						supplier.primaryContactEmail,
						SUPPLIER_PRIVATE_NETWORKS_SEARCH_TARGET_COLLECTION
					),
				]).then(() => {
					const { supplierPrivateNetworks } = this.props;
					if (nullSafeGetOrElse('searchRecords.records', supplierPrivateNetworks, []).length > 0) {
						this.setState({
							selectSuppliers: supplierPrivateNetworks.searchRecords.records,
							selectSupplierModalVisible: true,
						});
					} else {
						this.handleAddNewSupplier();
					}
				});
			} else {
				// Supplier name and email not provided. so adding self managed supplier
				this.handleAddNewSupplier();
			}
		});
	};

	handleAddNewSupplier = () => {
		const { addNewSupplier } = this.props;
		const { supplierInfo } = this.state;
		Promise.all([addNewSupplier(supplierInfo)]).then(() => {
			const { supplierPrivateNetworks } = this.props;
			this.setState({ supplierFacilityId: supplierPrivateNetworks.inviteRecord.records.id }, () => {
				this.handleSupplierSelected(supplierPrivateNetworks.inviteRecord.records);
			});
		});
	};

	handleBackSelectSupplierModal = () => {
		this.setState({ selectSupplierModalVisible: false, infoModalVisible: true });
	};

	handleCloseSelectSupplierModal = () => {
		this.setState({ selectSupplierModalVisible: false, infoModalVisible: false });
	};

	handleSupplierSelected = (record) => {
		this.setState({ selectSupplierModalVisible: false, infoModalVisible: false });
		this.showSupplierPrivateNetworkCreateModal(record);
	};

	handleExistingSupplierSelected = (record) => {
		this.setState({ selectSupplierModalVisible: false, infoModalVisible: false });
		this.showSupplierPrivateNetworkEditModal(record);
	};

	render() {
		const {
			history,
			location,
			supplierPrivateNetworks,
			locations,
			regions,

			fetchLocations,
			fetchMultipleLocations,
			fetchRegions,
			fetchMultipleRegions,
			clearAndUpdateFilters,
			updateSupplierPrivateNetworksFilters,
			currentUser,
			userType,
		} = this.props;

		const onRow = (record) => ({
			onClick: () => {
				let searchString = location.search;
				if (searchString[0] === '?') {
					searchString = searchString.slice(1);
				}
				let backUrl = `/${userType}/suppliers/detail/${record.supplierFacilityId}`;
				if (searchString && searchString.length > 0) {
					backUrl = backUrl.concat(`?backlinkParams=${encodeURIComponent(searchString)}`);
				}
				history.push(backUrl);
			},
		});

		const filterConfig = [
			{
				fieldName: 'relationshipStatus',
				type: FILTER_FIELD_TYPE.RADIO_GROUP,
				items: [
					{
						label: 'All',
						value: 'undefined',
					},
					{
						label: 'Connected',
						value: 'Connected',
					},
					{
						label: 'Invited',
						value: 'Invited',
					},
					{
						label: 'Self Managed',
						value: 'SelfManaged',
					},
				],
				filtersValueAccessor: (filtersStateSlice) => {
					const filters = nullSafeGet(
						`${SUPPLIER_PRIVATE_NETWORKS_INDEX_TARGET_COLLECTION}.filters`,
						filtersStateSlice
					);
					return typeof filters.relationshipStatus === 'undefined'
						? 'undefined'
						: `${filters.relationshipStatus}`;
				},
				defaultValue: undefined,
				keyAccessor: (item) => item.value,
				valueAccessor: (item) => item.value,
				handleChange: (relationshipStatus) => {
					const val = relationshipStatus
						? relationshipStatus === 'undefined'
							? undefined
							: relationshipStatus
						: undefined;
					updateSupplierPrivateNetworksFilters(
						{ relationshipStatus: val },
						SUPPLIER_PRIVATE_NETWORKS_INDEX_TARGET_COLLECTION
					);
				},
				targetCollectionName: 'locationsAdvancedFilters',
				renderRecord: (item) => item.label,
				component: OWRadioGroup,
				showOutsideDropdown: true,
			},
			{
				label: 'Location',
				fieldName: 'locationIds',
				stateSlice: locations,
				hideFilter: true,
				filtersValueAccessor: (filtersStateSlice) => {
					const ids = nullSafeGet(
						`${SUPPLIER_PRIVATE_NETWORKS_INDEX_TARGET_COLLECTION}.filters.locationIds`,
						filtersStateSlice
					);
					return changeFilterValueToArrayOfIds(ids);
				},
				keyAccessor: (loc) => loc.id,
				valueAccessor: (loc) => loc.id,
				handleChange: (ids) => {
					updateSupplierPrivateNetworksFilters(
						{ locationIds: ids.join(',') || undefined },
						SUPPLIER_PRIVATE_NETWORKS_INDEX_TARGET_COLLECTION
					);
				},
				targetCollectionName: 'supplierPrivateNetworksAdvancedFilters',
				fetch: fetchLocations,
				fetchMultiple: fetchMultipleLocations,
				renderItem: (loc) => loc.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
			},
			{
				label: 'Region',
				mode: 'multiple',
				stateSlice: regions,
				hideFilter: true,
				filtersValueAccessor: (filtersStateSlice) => {
					const ids = nullSafeGet(
						`${SUPPLIER_PRIVATE_NETWORKS_INDEX_TARGET_COLLECTION}.filters.regionIds`,
						filtersStateSlice
					);
					return changeFilterValueToArrayOfIds(ids);
				},
				keyAccessor: (region) => region.id,
				valueAccessor: (region) => region.id,
				handleChange: (ids) => {
					updateSupplierPrivateNetworksFilters(
						{
							regionIds: ids.join(',') || undefined,
						},
						SUPPLIER_PRIVATE_NETWORKS_INDEX_TARGET_COLLECTION
					);
				},
				targetCollectionName: 'supplierPrivateNetworksAdvancedFilters',
				fetch: fetchRegions,
				fetchMultiple: fetchMultipleRegions,
				renderItem: (region) => region.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
			},
		];
		const isAdmin =
			currentUser.roles.includes(BUYER_ADMIN) || currentUser.roles.includes(SUPER_ADMIN);

		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				render: (text, record) => {
					const spendCategories = nullSafeGetOrElse(
						'supplierFacility.categoryNames',
						record,
						''
					).split(',');
					return (
						<div style={{ display: 'flex', alignItems: 'center', minWidth: 320 }}>
							<div style={{ marginRight: 16 }}>
								{nullSafeGet('supplierFacility.logoURL', record) ? (
									<FlexibleImageAvatar
										displayName={text}
										showPopover={false}
										popoverText={text}
										squareMode={true}
										width="120px"
										height="80px"
										imageUrl={nullSafeGet('supplierFacility.logoURL', record)}
									/>
								) : (
									<FlexibleImageAvatar
										displayName={
											nullSafeGet('supplierFacilityAlias', record) ||
											nullSafeGet('supplierFacility.name', record)
										}
										showPopover={false}
										popoverText={
											nullSafeGet('supplierFacilityAlias', record) ||
											nullSafeGet('supplierFacility.name', record)
										}
										squareMode={true}
										width="120px"
										height="80px"
										imageUrl="https://s3.amazonaws.com/emptor-data-assets/images/empty-state/icons8-city-filled-100.png"
									/>
								)}
							</div>
							<div>
								<div
									style={{
										fontSize: 18,
										wordBreak: 'break-word',
									}}
								>
									{nullSafeGet('supplierFacilityAlias', record) ||
										nullSafeGet('supplierFacility.name', record)}
								</div>
								<div style={{ marginBottom: 0, fontSize: 14 }}>
									{record.relationshipStatus && (
										<div
											className={`text-base capitalize text-gray-500 ${
												record.relationshipStatus === 'connected' ? 'text-green-500' : ''
											}`}
										>
											{record.relationshipStatus === 'SelfManaged'
												? 'Self Managed'
												: record.relationshipStatus}
										</div>
									)}
									<div
										style={{ wordBreak: 'normal', whiteSpace: 'normal', color: 'rgba(0,0,0,0.45)' }}
									>
										{spendCategories
											.map((categoryName) => capitalizeEachWord(categoryName))
											.slice(0, 2)
											.join(', ')}
										{spendCategories.length > 3 ? ' and more' : null}
									</div>
								</div>
								<AddressDisplay
									hidePostcode={true}
									hideCountry={true}
									hideStreetAddress={true}
									address={record.primaryAddress}
									style={{
										color: 'rgba(0,0,0,0.45)',
									}}
								/>
							</div>
						</div>
					);
				},
			},
			{
				title: 'Locations Served',
				dataIndex: 'locationNames',
				render: (text, record) => (
					<div>
						{nullSafeGet('locationNames.0', record) ? (
							<div>
								Serving locations: <br /> {stringArrToList(record.locationNames.map((l) => l))}
							</div>
						) : (
							<div>
								{record.locationIds ? 'Assigned to all locations' : 'No locations assigned'}
							</div>
						)}
					</div>
				),
			},
			{
				title: 'Actions',
				dataIndex: 'id',
				render: (text, record) => (
					<div>
						<Button
							disabled={!isAdmin}
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								this.showSupplierPrivateNetworkEditModal(record);
							}}
						>
							Edit Network
						</Button>
					</div>
				),
			},
			{
				title: 'Delete Actions',
				dataIndex: 'supplierFacilityId',
				render: (text, record) => (
					<Popconfirm
						title={'Are you sure, you want remove this supplier?'}
						onConfirm={(e) => {
							stopDefaultEvents(e);
							this.removeFromSupplierPrivateNetwork(record.supplierFacilityId);
						}}
						onCancel={(e) => stopDefaultEvents(e)}
						okText="Yes"
					>
						<Button
							loading={this.state.removingFacilityId === record.supplierFacilityId}
							disabled={!isAdmin}
							danger
							ghost
							onClick={(e) => stopDefaultEvents(e)}
						>
							Remove Supplier
						</Button>
					</Popconfirm>
				),
			},
		];
		return (
			<Content className="privateNetworkIndexPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties
					eventType={`visited ${userType} supplier private network index page`}
				/>
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<SupplierPrivateNetworkCreateForm
					userType={userType}
					wrappedComponentRef={this.saveFormRef('supplierPrivateNetworkCreateForm')}
					visible={this.state.supplierPrivateNetworkCreateFormVisible}
					supplierFacilityId={this.state.supplierFacilityId}
					supplierFacility={this.state.supplierFacility}
					onCancel={this.handleSupplierPrivateNetworkCreateCancel}
					onSubmit={this.handleSupplierPrivateNetworkCreateSubmit}
					loading={this.state.creatingPrivateNetwork}
				/>
				{this.state.supplierPrivateNetworkEditFormVisible && (
					<SupplierPrivateNetworkEditForm
						userType={userType}
						wrappedComponentRef={this.saveFormRef('supplierPrivateNetworkEditForm')}
						visible
						supplierFacilityId={this.state.supplierFacilityId}
						supplierFacility={this.state.supplierFacility}
						onCancel={this.handleSupplierPrivateNetworkEditCancel}
						onSubmit={this.handleSupplierPrivateNetworkEditSubmit}
						loading={this.state.updatingPrivateNetwork}
					/>
				)}
				<Modal
					//Select Supplier Modal
					width={800}
					title={
						<div style={{ width: '100%', position: 'relative', display: 'block' }}>
							<span>
								{this.state.selectSuppliers.length > 1
									? 'Did you mean any of the following Suppliers?'
									: 'Did you mean this Supplier?'}
							</span>
						</div>
					}
					footer={
						<div style={{ display: 'block', position: 'relative', width: '100%', height: '32px' }}>
							<div
								style={{
									display: 'inline-block',
									position: 'absolute',
									left: '0px',
									top: '0px',
									verticalAlign: 'bottom',
								}}
							>
								<Button onClick={this.handleBackSelectSupplierModal}>Back</Button>
							</div>
							<div
								style={{
									display: 'inline-block',
									position: 'absolute',
									right: '0px',
									top: '0px',
									verticalAlign: 'bottom',
								}}
							>
								<Button
									onClick={() => {
										this.handleAddNewSupplier();
										this.handleCloseSelectSupplierModal();
									}}
									type="primary"
								>
									No, Invite My Supplier
								</Button>
							</div>
						</div>
					}
					bodyStyle={{ overflowY: 'scroll' }}
					closable={false}
					onCancel={this.handleCloseInfoModal}
					visible={this.state.selectSupplierModalVisible}
					destroyOnClose={true}
				>
					<div
						style={{
							display: 'block',
							position: 'relative',
							width: '100%',
							height: '560px',
							borderRadius: '5px',
							padding: '4px 6px 4px 4px',
						}}
					>
						{this.state.selectSuppliers.map((supplier, i) => (
							<SelectSupplierCard
								supplier={supplier}
								currentSuppliers={supplierPrivateNetworks}
								key={i}
								handleSupplierSelected={() => {
									this.setState({ supplierFacilityId: supplier.id });
									this.handleSupplierSelected(supplier);
								}}
								handleExistingSupplierSelected={() => {
									this.setState({ supplierFacilityId: supplier.id });
									supplier.supplierFacilityId = supplier.id;
									this.handleExistingSupplierSelected(supplier);
								}}
							/>
						))}
						<div
							style={{
								display: 'none',
								position: 'relative',
								width: '100%',
								padding: '10px',
								textAlign: 'center',
							}}
						>
							<Button
								onClick={() => {
									this.handleAddNewSupplier();
									this.handleCloseSelectSupplierModal();
								}}
							>
								No, Invite My Supplier
							</Button>
						</div>
					</div>
				</Modal>

				<Modal
					//Supplier Contact info modal
					width={640}
					title={<span>Add Supplier</span>}
					footer={null}
					closable={false}
					onCancel={this.handleCloseInfoModal}
					visible={this.state.infoModalVisible}
					destroyOnClose={true}
				>
					<InviteSupplierForm
						submit={this.submitNewSupplierInfo}
						cancel={this.handleCloseInfoModal}
					/>
				</Modal>
				<Row style={{ margin: '0em ' }}>
					<Col span={24}>
						<SubnavBar
							left={
								<div>
									<AdvancedFilters
										updateFilters={updateSupplierPrivateNetworksFilters}
										filterConfig={filterConfig}
										inputParamName={'supplierFacilityAlias'}
										filtersTargetCollectionName={SUPPLIER_PRIVATE_NETWORKS_INDEX_TARGET_COLLECTION}
										clearAndUpdateFilters={clearAndUpdateFilters}
										filtersStateSlice={supplierPrivateNetworks}
										preAppliedFilters={{}}
										initialFilters={this.initialFilters}
									>
										<Form.Item label="Location">
											<RegionsLocationsTreeSelect
												mode="multiple"
												filtersSlice={supplierPrivateNetworks}
												filtersValueAccessor={(filtersStateSlice) => {
													const locationIds = nullSafeGet(
														`${SUPPLIER_PRIVATE_NETWORKS_INDEX_TARGET_COLLECTION}.filters.locationIds`,
														filtersStateSlice
													);
													const finalIds = nullSafeGetOrElse(
														`${SUPPLIER_PRIVATE_NETWORKS_INDEX_TARGET_COLLECTION}.filters.regionIds`,
														filtersStateSlice,
														locationIds
													);
													return changeFilterValueToArrayOfIds(finalIds);
												}}
												onChange={(ids) => {
													updateSupplierPrivateNetworksFilters(
														{
															locationIds: ids.join(',') || undefined,
															regionIds: undefined,
														},
														SUPPLIER_PRIVATE_NETWORKS_INDEX_TARGET_COLLECTION
													);
												}}
												targetCollectionName={'supplierPrivateNetworksAdvancedFilters'}
											/>
										</Form.Item>
									</AdvancedFilters>
								</div>
							}
							right={
								<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_SUPPLIERS}>
									<Col style={{ textAlign: 'right' }}>
										<Button size="large" type="primary" onClick={this.showInfoModal}>
											Add Supplier
										</Button>
									</Col>
								</AccessPermissionChecker>
							}
						/>
					</Col>
				</Row>
				<div className="flex w-full flex-1 flex-row">
					<SelectedFilters
						updateFilters={(filters) =>
							updateSupplierPrivateNetworksFilters(
								filters,
								SUPPLIER_PRIVATE_NETWORKS_INDEX_TARGET_COLLECTION
							)
						}
						filterValues={nullSafeGetOrElse(
							`${SUPPLIER_PRIVATE_NETWORKS_INDEX_TARGET_COLLECTION}.filters`,
							supplierPrivateNetworks,
							{}
						)}
						filterConfig={filterConfig}
					/>
				</div>
				<Row style={{ margin: '0.5em 0px ' }}>
					<Col span={24}>
						<Card bodyStyle={{ padding: 8 }}>
							<PaginatedReduxTable
								emptyState={
									<EmptyState
										graphic={
											<img
												style={{ marginBottom: 8 }}
												src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
												alt="It's a little lonely in here."
											/>
										}
										headline={"It's a little lonely in here."}
										body={
											<div style={{ textAlign: 'center' }}>
												<div style={{ maxWidth: 440, marginBottom: 16 }}>
													Looks like your team has not added any supplier in your private network
													yet.
												</div>
											</div>
										}
									/>
								}
								updateQueryParams={true}
								collectionName="supplier_private_networks"
								useDistinctCollection={true}
								onRow={onRow}
								showHeader={false}
								targetCollectionName={SUPPLIER_PRIVATE_NETWORKS_INDEX_TARGET_COLLECTION}
								columns={columns}
								keyAccessor={(el) => el.supplierFacilityId}
								initialFilters={this.initialFilters}
								initialPagination={this.initialPagination}
								// hiddenPagination={pagination.getOther()}
								fetchData={
									userType === ROLE_TYPES.SUPPLIER
										? supplierPrivateNetworksV2ForSupplier
										: supplierPrivateNetworksV2ForBuyer
								}
							/>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
	regions: state.regions,
	locations: state.locations,
	supplierPrivateNetworks: state.supplier_private_networks,
	selectSuppliers: state.selectSuppliers,
	userType: (state as any).session.userType,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	createSupplierPrivateNetwork: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? supplierPrivateNetworksRestCrudThunksForSupplier.create(entity)
				: supplierPrivateNetworksRestCrudThunksForBuyer.create(entity)
		),
	createSupplierPrivateNetworkBulk: (entities) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? createSupplierPrivateNetworkBulkForSupplier(entities)
				: createSupplierPrivateNetworkBulkForBuyer(entities)
		),
	updateSupplierPrivateNetworksFilters: (filters, targetCollection) =>
		dispatch(
			SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)
		),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(
				filters,
				targetCollectionName
			)
		),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: locationsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: locationsRestCrudThunksForBuyer.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchSuppliers: (name, email, targetCollectionName) =>
		dispatch(
			fetchSuppliersForBuyers(ownProps.userType)(name, email)('supplierPrivateNetworksSearch')
		),
	addNewSupplier: (entity) => dispatch(addNewSupplierForBuyers(ownProps.userType)(entity)),
	updateGroupedSupplierPrivateNetwork: (id, entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? supplierPrivateNetworkGroupedUpdateForSupplier(id)(entity)
				: supplierPrivateNetworkGroupedUpdateForBuyer(id)(entity)
		),
	updateSupplierPrivateNetworkV2: (id, entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? supplierPrivateNetworkV2UpdateForSupplier(id)(entity)
				: supplierPrivateNetworkV2UpdateForBuyer(id)(entity)
		),
	deleteBuyerSupplierRelationship: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? deleteBuyerSupplierRelationshipBySupplierFacilityIdForSupplier(id)
				: deleteBuyerSupplierRelationshipBySupplierFacilityIdForBuyer(id)
		),
	fetchSupplierPrivateNetworkV2: (
		additionalParams,
		targetCollectionName,
		pagination,
		sorting,
		filters
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? supplierPrivateNetworksV2ForSupplier(
						additionalParams,
						targetCollectionName,
						pagination,
						sorting,
						filters
				  )
				: supplierPrivateNetworksV2ForBuyer(
						additionalParams,
						targetCollectionName,
						pagination,
						sorting,
						filters
				  )
		),
	inviteSupplierUser: (supplierFacilityId, fromEmail, toEmail, firstName, lastName) =>
		dispatch(
			inviteSupplierUserFromBuyer(ownProps.userType)(
				supplierFacilityId,
				fromEmail,
				toEmail,
				firstName,
				lastName
			)
		),
	fetchRegions: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? regionsRestCrudThunksForSupplier.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: regionsRestCrudThunksForBuyer.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultipleRegions: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? regionsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: regionsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(PrivateNetworkIndexPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

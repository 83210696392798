import React, { FC, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EmptyState } from '../empty_state/EmptyState';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { QuantityRowDisplay } from '../part_quantity_row_display/PartQuantityRowDisplay';
import { Button, Modal } from 'antd';
import PurchaseRequestForm from '../purchase_request_form/PurchaseRequestForm';
import RequestedQuantityDisplay from './RequestedQuantityDisplay';
import HyperLink, { ENTITY_TYPE } from '../common/HyperLink';
import { capitalizeEachWord } from '../../utils/DataFormatterUtils';
import {
	backordersRestCrudThunksForSupplier,
	downloadBackordersCSVForSupplier,
} from '../../thunks/backorders_thunks';
import { BACKORDERS_CRUD_ACTION_CREATORS } from '../../actions/backorders_actions';
import { PartEquipmentTagWithName } from '../equipment_per_stock_location_row_display/PartEquipmentNameWithType';
import { vendorsRestCrudThunksForSupplier } from '../../thunks/vendors_thunks';
import { DownloadOutlined } from '@ant-design/icons';

const TC_NAME = 'backordersIndex';
const BACK_TEXT = 'Back to Backorders';

const StockLocationBackordersPage: FC<any> = ({
	match,
	updateFilters,
	clearAndUpdateFilters,
	fetchBackorders,
	backorders,
	currentUser,
	vendors,
	fetchVendors,
	fetchMultipleVendors,
	exportBackordersCsv,
}): React.ReactElement => {
	const stockLocationId = useMemo(() => match.params.id, [match.params.id]);

	const [createPRVisible, setCreatePRVisible] = useState(false);
	const [requestedParts, setRequestedParts] = useState([]);
	const [requestedEquipment, setRequestedEquipment] = useState([]);

	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [selectedRecords, setSelectedRecords] = useState([]);

	const [exporting, setExporting] = useState(false);

	const getStatSliceProp = useCallback(
		(propName) => nullSafeGetOrElse(`${TC_NAME}.${propName}`, backorders, {}),
		[backorders]
	);

	const onSelectCancel = useCallback(() => {
		setSelectedRowKeys([]);
		setSelectedRecords([]);
	}, []);

	const refreshBackOrders = useCallback(() => {
		onSelectCancel();
		fetchBackorders(
			{},
			TC_NAME,
			getStatSliceProp('pagination'),
			getStatSliceProp('sorting'),
			getStatSliceProp('filters')
		);
	}, [fetchBackorders, getStatSliceProp, onSelectCancel]);

	const getOrderQuantity = useCallback((record) => {
		const quantity =
			nullSafeGetOrElse('maxQuantity', record, 0) +
			nullSafeGetOrElse('requestedQuantity', record, 0) -
			(nullSafeGetOrElse('onHandQuantity', record, 0) +
				nullSafeGetOrElse('purchaseOrderQuantity', record, 0) +
				nullSafeGetOrElse('purchaseRequestQuantity', record, 0));
		return quantity > 0 ? quantity : 0;
	}, []);

	const getEntityCost = useCallback((record) => {
		if (record.isEquipmentLine) {
			return (
				nullSafeGet('defaultEquipmentCatalog.unitCost', record) ||
				nullSafeGet('defaultEquipmentCatalog.listPrice', record)
			);
		} else {
			return (
				nullSafeGet('defaultPartCatalog.unitCost', record) ||
				nullSafeGet('defaultPartCatalog.listPrice', record)
			);
		}
	}, []);

	const getEntityTotalCost = useCallback(
		(record) => {
			const cost = getEntityCost(record);
			return getOrderQuantity(record) * (cost ? parseFloat(cost) : 0);
		},
		[getEntityCost, getOrderQuantity]
	);

	const getPREntity = useCallback(
		(record) => {
			const commonValues = {
				vendorId: nullSafeGet('defaultVendor.id', record),
				unitCost: getEntityCost(record),
				cost: getEntityTotalCost(record),
				vendor: nullSafeGet('defaultVendor', record),
				quantity: getOrderQuantity(record),
				stockLocationId: parseInt(stockLocationId),
			};
			if (record.isEquipmentLine) {
				return {
					...commonValues,
					equipmentType: { modelName: nullSafeGet('name', record) },
					equipmentCatalog: {
						...nullSafeGetOrElse('defaultEquipmentCatalog', record, {}),
						partEquipmentVendor: nullSafeGet('defaultVendor', record),
					},
					equipmentCatalogId: nullSafeGetOrElse('defaultEquipmentCatalog.id', record, undefined),
					equipmentTypeId: nullSafeGet('equipmentTypeId', record),
				};
			} else {
				return {
					...commonValues,
					part: { name: nullSafeGet('name', record) },
					partCatalog: {
						...nullSafeGetOrElse('defaultPartCatalog', record, {}),
						partEquipmentVendor: nullSafeGet('defaultVendor', record),
					},
					partCatalogId: nullSafeGetOrElse('defaultPartCatalog.id', record, undefined),
					partId: nullSafeGet('partId', record),
				};
			}
		},
		[getEntityCost, getEntityTotalCost, getOrderQuantity, stockLocationId]
	);

	const onCreatePR = useCallback(
		(record) => () => {
			const entity = getPREntity(record);
			if (record.isEquipmentLine) {
				setRequestedEquipment([entity]);
			} else {
				setRequestedParts([entity]);
			}
			setCreatePRVisible(true);
		},
		[getPREntity]
	);

	const onCreatePRBulk = useCallback(() => {
		const consolidatedRecords = selectedRecords.reduce(
			(acc, record) => {
				const entity = getPREntity(record);
				if (record.isEquipmentLine) {
					return {
						...acc,
						equipmentTypes: [...acc.equipmentTypes, ...[entity]],
					};
				} else {
					return {
						...acc,
						parts: [...acc.parts, ...[entity]],
					};
				}
			},
			{
				parts: [],
				equipmentTypes: [],
			}
		);
		setRequestedParts(consolidatedRecords.parts);
		setRequestedEquipment(consolidatedRecords.equipmentTypes);
		setCreatePRVisible(true);
	}, [getPREntity, selectedRecords]);

	const userName = useMemo(() => {
		return currentUser.nameGiven && currentUser.nameFamily
			? capitalizeEachWord([currentUser.nameGiven, currentUser.nameFamily].join(' '))
			: '';
	}, [currentUser]);

	const PRName = useMemo(() => {
		const prefix = nullSafeGet('0.partId', requestedParts)
			? `Part '${nullSafeGetOrElse('0.part.name', requestedParts, '')}'`
			: null;

		return `${prefix} requested by ${userName} from Backorders`;
	}, [requestedParts, userName]);

	const columns = useMemo(
		() => [
			{
				title: 'ID',
				render: (_, record) =>
					record.isEquipmentLine
						? nullSafeGetOrElse('equipmentTypeNumber', record, '--')
						: nullSafeGetOrElse('partNumber', record, '--'),
			},
			{
				title: 'Name',
				render: (_, record) => (
					<PartEquipmentTagWithName
						isEquipmentLine={record.isEquipmentLine}
						name={
							<HyperLink
								text={record.name}
								entityType={record.equipmentTypeId ? ENTITY_TYPE.EQUIPMENT : ENTITY_TYPE.PART}
								entityId={record.equipmentTypeId || record.partId}
								backLinkText={BACK_TEXT}
							/>
						}
					/>
				),
			},
			{
				title: 'Vendor',
				dataIndex: ['defaultVendor', 'name'],
			},
			{
				title: 'Min Qty',
				dataIndex: 'minQuantity',
				render: (_) => _ || 0,
			},
			{
				title: 'Max Qty',
				dataIndex: 'maxQuantity',
				render: (_) => _ || 0,
			},
			{
				title: 'On Hand Qty',
				dataIndex: 'onHandQuantity',
				render: (_, record) => (
					<QuantityRowDisplay
						min={record.minQuantity}
						max={record.maxQuantity}
						quantity={record.onHandQuantity}
					/>
				),
			},
			{
				title: 'Requested Qty',
				dataIndex: 'requestedQuantity',
				render: (_, record) =>
					record.isEquipmentLine ? (
						'--'
					) : (
						<RequestedQuantityDisplay
							ppslId={nullSafeGet('partsPerStockLocationId', record)}
							record={record}
							backText={BACK_TEXT}
						/>
					),
			},
			{
				title: 'Total PR Qty',
				dataIndex: 'purchaseRequestQuantity',
				render: (text, record) =>
					text ? (
						<HyperLink
							text={text}
							newTabPath={`/supplier/purchaseRequests/overview/requests?stockLocationIds=${stockLocationId}&partIds=${nullSafeGet(
								'partId',
								record
							)}&statuses=${encodeURIComponent('requested,approved,orderInProgress')}`}
						/>
					) : (
						0
					),
			},
			{
				title: 'Total PO Qty',
				dataIndex: 'purchaseOrderQuantity',
				render: (text, record) =>
					text ? (
						<HyperLink
							text={text}
							newTabPath={`/supplier/purchaseOrders/overview/orders?stockLocationIds=${stockLocationId}&partIds=${nullSafeGet(
								'partId',
								record
							)}&status=${encodeURIComponent('ordered')}`}
						/>
					) : (
						0
					),
			},
			{
				render: (_, record) => (
					<Button type="primary" onClick={onCreatePR(record)}>
						Create PR
					</Button>
				),
			},
		],
		[onCreatePR, stockLocationId]
	);

	const hideCreatePRModal = useCallback(() => {
		setCreatePRVisible(false);
		setRequestedParts([]);
		setRequestedEquipment([]);
	}, []);

	const onPRSuccess = useCallback(() => {
		refreshBackOrders();
		hideCreatePRModal();
	}, [hideCreatePRModal, refreshBackOrders]);

	const onSelectChange = useCallback((newSelectedRowKeys: React.Key[], selectedRows) => {
		setSelectedRowKeys(newSelectedRowKeys);
		setSelectedRecords(selectedRows);
	}, []);

	const rowSelection = useMemo(
		() => ({
			selectedRowKeys,
			preserveSelectedRowKeys: true,
			onChange: onSelectChange,
		}),
		[onSelectChange, selectedRowKeys]
	);

	const canCreatePR = useMemo(() => selectedRecords.length > 0, [selectedRecords.length]);

	const filterConfig = useMemo(
		() => [
			{
				label: 'Vendor',
				fieldName: 'defaultVendorIds',
				mode: 'multiple',
				stateSlice: vendors,
				targetCollectionName: 'backordersAdvancedFilters',
				fetch: fetchVendors,
				fetchMultiple: fetchMultipleVendors,
			},
		],
		[fetchMultipleVendors, fetchVendors, vendors]
	);

	const downloadCSV = useCallback(() => {
		const filters = nullSafeGetOrElse(`${TC_NAME}.filters`, backorders, {});
		setExporting(true);
		exportBackordersCsv(filters).finally(() => setExporting(false));
	}, [backorders, exportBackordersCsv]);

	return (
		<>
			<PaginatedReduxTableWithHeader
				targetCollectionName={TC_NAME}
				updateFilters={updateFilters}
				stateSlice={backorders}
				clearAndUpdateFilters={clearAndUpdateFilters}
				filterConfig={filterConfig}
				entityCollectionName="backorders"
				tableColumns={columns}
				showHeader
				preAppliedFilters={{ stockLocationId }}
				fetchData={backordersRestCrudThunksForSupplier.read}
				hasDefaultHeaderPage
				rightActions={
					canCreatePR ? (
						<div className="flex-start flex flex-row">
							<Button onClick={onSelectCancel} size="large">
								Cancel
							</Button>
							<Button className="ml-2" size="large" type="primary" onClick={onCreatePRBulk}>
								Create PR
							</Button>
						</div>
					) : (
						<Button
							size="large"
							icon={<DownloadOutlined translate="" />}
							className="inline-block-visible-md"
							loading={exporting}
							onClick={downloadCSV}
						>
							<span className="inline-block-visible-xl">Export CSV</span>
						</Button>
					)
				}
				rowSelection={rowSelection}
				emptyState={
					<EmptyState
						graphic={
							<img
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
								alt="No parts available for backorder!"
							/>
						}
						headline={'No parts available for backorder!'}
						body={
							<div style={{ textAlign: 'center' }}>
								<div style={{ maxWidth: 440, marginBottom: 16 }}>
									There are no parts available for backorders
								</div>
							</div>
						}
					/>
				}
			/>
			{createPRVisible && (
				<Modal
					visible={createPRVisible}
					closable
					width="95%"
					title="Create Purchase Request"
					onCancel={hideCreatePRModal}
					footer={null}
				>
					<div>
						<h5 style={{ marginBottom: 24 }}>New Purchase Request</h5>
						<PurchaseRequestForm
							isModal
							formData={{
								name: PRName,
								description: PRName,
								stockLocationId,
							}}
							requestedParts={requestedParts}
							requestedEquipments={requestedEquipment}
							onSuccess={onPRSuccess}
						/>
					</div>
				</Modal>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	backorders: state.backorders,
	fetching: state.backorders.fetching,
	currentUser: state.session.currentUser,
	vendors: state.vendors,
});

const mapDispatchToProps = (dispatch) => ({
	fetchBackorders: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			backordersRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	updateFilters: (filters, targetCollection) =>
		dispatch(BACKORDERS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(BACKORDERS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)),
	fetchVendors: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			vendorsRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleVendors: (ids, targetCollectionName) =>
		dispatch(vendorsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	exportBackordersCsv: (params, filters) =>
		dispatch(downloadBackordersCSVForSupplier(params, filters)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(StockLocationBackordersPage)
);

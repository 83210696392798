import React, { FC, useCallback, useMemo, useState } from 'react';
import SaveFiltersPopup from './SaveFiltersPopup';
import { PAGINATION_PROPS, SORTING_PROPS } from '../../utils/DataConstants';
import SavedFilterViews from './SavedFilterViews';
import { filterKeys, isValidFilter } from '../../utils/DataAccessUtils';
import { Button } from 'antd';
import { SaveOutlined, FolderOpenOutlined } from '@ant-design/icons';

interface SaveFiltersActionsProps {
	preAppliedFilters: any;
	activeFIlters: any;
	activeSorter: any;
	userType: String;
	entityType: String;
	applyFiltersFromView: (filters, sorters) => void;
}

const SaveFiltersActions: FC<SaveFiltersActionsProps> = ({
	preAppliedFilters,
	activeFIlters,
	activeSorter,
	userType,
	entityType,
	applyFiltersFromView,
}): React.ReactElement => {
	const [saveFilterModalVisible, setSaveFilterModalVisible] = useState(false);
	const [savedViewsModalVisible, setSavedViewsModalVisible] = useState(false);

	const showSaveFiltersModal = useCallback(() => setSaveFilterModalVisible(true), []);
	const hideSaveFilterModal = useCallback(() => setSaveFilterModalVisible(false), []);
	const showSavedViewsModal = useCallback(() => setSavedViewsModalVisible(true), []);
	const hideSavedViewsModal = useCallback(() => setSavedViewsModalVisible(false), []);

	const applyFIlterFromView = useCallback(
		(filters) => {
			const withoutPagination = filterKeys(filters, PAGINATION_PROPS, false);
			applyFiltersFromView(
				filterKeys(withoutPagination, SORTING_PROPS, false),
				filterKeys(withoutPagination, SORTING_PROPS, true)
			);
			hideSavedViewsModal();
		},
		[hideSavedViewsModal, applyFiltersFromView]
	);

	const activeFiltersAndSorters = useMemo(
		() => ({
			...activeFIlters,
			...activeSorter,
		}),
		[activeFIlters, activeSorter]
	);

	const isFilterActive = useMemo(
		() => isValidFilter(activeFiltersAndSorters, preAppliedFilters),
		[activeFiltersAndSorters, preAppliedFilters]
	);

	return (
		<>
			{isFilterActive && (
				<Button onClick={showSaveFiltersModal} size="large" icon={<SaveOutlined translate="" />}>
					Save View
				</Button>
			)}
			<Button onClick={showSavedViewsModal} size="large" icon={<FolderOpenOutlined translate="" />}>
				Open View
			</Button>
			{saveFilterModalVisible && (
				<SaveFiltersPopup
					roleType={userType}
					entityType={entityType}
					filters={activeFiltersAndSorters}
					preAppliedFilters={preAppliedFilters}
					onCancel={hideSaveFilterModal}
				/>
			)}

			{savedViewsModalVisible && (
				<SavedFilterViews
					roleType={userType}
					entityType={entityType}
					onApplyFilter={applyFIlterFromView}
					onCancel={hideSavedViewsModal}
				/>
			)}
		</>
	);
};

export default SaveFiltersActions;

import React, { FC } from 'react';

const style = require('./EmptyState.less');

export const EmptyState: FC<any> = ({
	graphic,
	headline,
	height,
	body,
	style = {},
	...otherProps
}) => {
	return (
		<div className="itsNotReadyYet__container" style={{ ...style, height }} {...otherProps}>
			{graphic ? <div>{graphic}</div> : null}
			{headline ? <h5 className="itsNotReadyYet__title">{headline}</h5> : null}
			<div className="itsNotReadyYet__body">{body}</div>
		</div>
	);
};

import { Button, Form, InputNumber, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { FC, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import moment from 'moment';
import { defaultFiscalEnd, getFiscalRangeForYear } from './burden_rate_utils';

const FORM_NAME = 'burden-rate-form';
const dateFormat = 'MMM DD, YYYY';

const BurdenRateModal: FC<any> = ({
	onCancel,
	burdenRate,

	stockLocation,
	update,
	saving,
	companyConfig,
}): React.ReactElement => {
	const [form] = useForm();

	const fiscal = useMemo(
		() => nullSafeGetOrElse('detail.inventoryConfig.fiscalDate', companyConfig, defaultFiscalEnd),
		[companyConfig]
	);

	const onSubmit = useCallback(
		(values) => {
			const year = nullSafeGetOrElse('year', burdenRate, moment().year());
			const existingRates = nullSafeGetOrElse('burdenRates', stockLocation, []);
			const existingRecord = existingRates.find((_) => _.year === year);
			const burdenRates = existingRecord
				? existingRates.map((_) => (_.year === year ? { ..._, rate: `${values.rate}` } : _))
				: [...existingRates, { year, rate: `${values.rate}` }];

			update({
				...stockLocation,
				burdenRates,
			}).then(() => onCancel());
		},
		[burdenRate, onCancel, stockLocation, update]
	);

	const title = useMemo(() => {
		const { startDate, endDate } = getFiscalRangeForYear(burdenRate.year, fiscal);
		return `Update Burden Rate from ${startDate.format(dateFormat)} to ${endDate.format(
			dateFormat
		)}`;
	}, [burdenRate.year, fiscal]);

	return (
		<Modal
			visible={true}
			width={600}
			title={title}
			closable
			onCancel={onCancel}
			forceRender
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button
					type="primary"
					size="large"
					style={{ marginLeft: '16px' }}
					key="submit"
					htmlType="submit"
					form={FORM_NAME}
					loading={saving}
				>
					Save
				</Button>,
			]}
		>
			<Form
				form={form}
				id={FORM_NAME}
				layout="vertical"
				requiredMark={false}
				initialValues={{ rate: nullSafeGet('rate', burdenRate) }}
				onFinish={onSubmit}
			>
				<Form.Item
					name="rate"
					label="Rate"
					rules={[
						{
							required: true,
							message: `Please enter a rate`,
						},
						{
							type: 'number',
							min: 0,
							max: 100,
							message: 'Please enter rate in percentage not exceeding 100',
						},
					]}
				>
					<InputNumber style={{ width: '100%' }} addonAfter="%" />
				</Form.Item>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	saving: state.stock_locations.creating || state.stock_locations.updating,
	companyConfig: state.company_config,
});

const mapDispatchToProps = (dispatch) => ({
	update: (entity) => dispatch(stockLocationsRestCrudThunksForSupplier.update(entity)),
});

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(BurdenRateModal));

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, InputNumber, Row, Col, Card, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	bulkTransferPartsPerStockLocactionForSupplier,
	partsPerStockLocationsRestCrudThunksForSupplier,
} from '../../thunks/parts_per_stock_locations_thunks';
import { getRecordsForCompositeTargetCollection } from '../../reducers/standard_reducer_utils';

const FORM_ID = 'transfer_equipments_form';

const getPartFieldName = (ppsl) => `part-${ppsl.id}`;

const STOCK_LOCATION_INDEX = 'transferPartsStockLocationIndex';

const BulkTransferPartsForm: FC<any> = ({
	saving,
	onCancel,
	stockLocations,
	stockLocationId,
	toStockLocationId,
	fetchMultipleStockLocations,
	isPartsTransfer,
	selectedRecords,
	onTransferPartsSuccess,
	bulkTransfer,
	fetchMultiplePartsPerStockLocation,
	targetCollectionName,
	destinationRecords,
}): React.ReactElement => {
	const [form] = useForm();

	const { validateFields } = form;

	useEffect(() => {
		fetchMultipleStockLocations([stockLocationId, toStockLocationId], STOCK_LOCATION_INDEX);
	}, [fetchMultipleStockLocations, stockLocationId, toStockLocationId]);

	const stockLocationRecords = useMemo(
		() => getRecordsForCompositeTargetCollection(stockLocations, STOCK_LOCATION_INDEX),
		[stockLocations]
	);

	const currentStockLocation = useMemo(
		() =>
			nullSafeGet(
				'name',
				stockLocationRecords.find((_) => `${_.id}` === `${stockLocationId}`)
			),
		[stockLocationId, stockLocationRecords]
	);

	const destinationStockLocation = useMemo(
		() =>
			nullSafeGet(
				'name',
				stockLocationRecords.find((_) => `${_.id}` === `${toStockLocationId}`)
			),
		[toStockLocationId, stockLocationRecords]
	);

	const entityName = useMemo(() => (isPartsTransfer ? 'Parts' : 'Equipment'), [isPartsTransfer]);

	const [partValues, setPartValues] = useState({});

	const onFieldsChange = useCallback((values) => {
		if (values.length > 0) {
			const { name, value } = values[0];
			setPartValues((values) => ({
				...values,
				[name]: value,
			}));
		}
	}, []);

	const getUom = useCallback((ppsl, qty) => {
		return qty > 1
			? nullSafeGetOrElse('part.unitOfMeasure.displayNamePlural', ppsl, 'eaches')
			: nullSafeGetOrElse('part.unitOfMeasure.displayNameSingular', ppsl, 'each');
	}, []);

	const getQuantityProps = useCallback((quantity, max, min) => {
		if (quantity < min) {
			return {
				color: 'red',
				extraText: 'Almost out',
			};
		} else if (quantity < Math.round(max - (max - min) * 0.75)) {
			return {
				color: 'orange',
				extraText: 'Running low',
			};
		}
		return {};
	}, []);

	const getQuantityElement = useCallback(
		(ppsl, quantity, max, min) => {
			const { color, extraText } = getQuantityProps(quantity, max, min);
			return (
				<div className="flex flex-col justify-center">
					<div>{`${quantity} ${getUom(ppsl, quantity)}`}</div>
					{extraText && <div style={{ color }}>{extraText}</div>}
				</div>
			);
		},
		[getQuantityProps, getUom]
	);

	const getAfterQuantityField = useCallback(
		(ppsl) => {
			const quantity =
				ppsl.availableQuantity - nullSafeGetOrElse(getPartFieldName(ppsl), partValues, 0);
			const max = ppsl.maxQuantity || ppsl.availableQuantity;
			const min = ppsl.minQuantity || 0;
			return getQuantityElement(ppsl, quantity, max, min);
		},
		[getQuantityElement, partValues]
	);

	const getDestinationAfterQuantityField = useCallback(
		(ppsl) => {
			const destinationPpsl = destinationRecords.find((_) => _.partId === ppsl.partId);
			const quantity =
				nullSafeGetOrElse('availableQuantity', destinationPpsl, 0) +
				nullSafeGetOrElse(getPartFieldName(ppsl), partValues, 0);
			const max = nullSafeGetOrElse('maxQuantity', destinationPpsl, quantity);
			const min = nullSafeGetOrElse('minQuantity', destinationPpsl, 0);
			return getQuantityElement(destinationRecords, quantity, max, min);
		},
		[destinationRecords, getQuantityElement, partValues]
	);

	const onTransfer = useCallback(
		(values) => {
			const updatedRecords = selectedRecords.map((record) => ({
				...record,
				transferredQuantity: values[`part-${record.id}`],
			}));

			bulkTransfer(updatedRecords, toStockLocationId)
				.then(() => onTransferPartsSuccess())
				.catch((failedRecords) => {
					if (failedRecords && failedRecords.length > 0) {
						const ids = selectedRecords.map((_) => _.id);
						fetchMultiplePartsPerStockLocation(ids, targetCollectionName).then(() => {
							validateFields();
							message.error('Some quantity might have changed. Please update to proceed!');
						});
					}
				});
		},
		[
			selectedRecords,
			bulkTransfer,
			toStockLocationId,
			onTransferPartsSuccess,
			fetchMultiplePartsPerStockLocation,
			targetCollectionName,
			validateFields,
		]
	);

	const getHeader = useCallback(
		(text, stockLocationName) => (
			<Col span={6} className="flex flex-col justify-center">
				<div>{text}</div>
				<div className="text-slate-500">{stockLocationName && `(${stockLocationName})`}</div>
			</Col>
		),
		[]
	);

	return (
		<Card>
			<Row gutter={16}>
				<Col span={24}>
					<Form
						form={form}
						id={FORM_ID}
						onFieldsChange={onFieldsChange}
						layout="horizontal"
						labelCol={{ span: 12 }}
						wrapperCol={{ span: 12 }}
						labelAlign="left"
						requiredMark={false}
						onFinish={onTransfer}
					>
						{isPartsTransfer && (
							<>
								<Row className="mb-4">
									<Col span={4} />
									<Col span={20}>
										<Row className="text-center">
											<Col span={6}>Transfer Qty</Col>
											{getHeader('Existing Qty', currentStockLocation)}
											{getHeader('Qty after transfer', currentStockLocation)}
											{getHeader('Qty after transfer', destinationStockLocation)}
										</Row>
									</Col>
								</Row>
								{selectedRecords.map((ppsl) => (
									<Row className="text-center" key={`form-field-${ppsl.id}`}>
										<Col span={9}>
											<Form.Item
												name={getPartFieldName(ppsl)}
												label={nullSafeGet('part.name', ppsl)}
												initialValue={0}
												rules={[
													{
														type: 'number',
														min: 1,
														max: ppsl.availableQuantity,
														message: `You have entered more quantity than available`,
													},
													{
														required: true,
														message: 'Please enter valid quantity',
													},
												]}
											>
												<InputNumber style={{ width: '100%' }} min="1" />
											</Form.Item>
										</Col>
										<Col span={15}>
											<Row className="text-center">
												<Col span={8}>
													{`${ppsl.availableQuantity} ${getUom(ppsl, ppsl.availableQuantity)}`}
												</Col>
												<Col span={8}>{getAfterQuantityField(ppsl)}</Col>
												<Col span={8}>{getDestinationAfterQuantityField(ppsl)}</Col>
											</Row>
										</Col>
									</Row>
								))}
							</>
						)}
						<Form.Item>
							<div className="flex flex-row items-center">
								<Button onClick={onCancel} size="large">
									Cancel
								</Button>
								<Button
									type="primary"
									size="large"
									className="ml-4"
									key="submit"
									htmlType="submit"
									form={FORM_ID}
									loading={saving}
								>
									{`Transfer ${entityName}`}
								</Button>
							</div>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</Card>
	);
};

const mapStateToProps = (state) => ({
	stockLocations: state.stock_locations,
	saving: state.parts_per_stock_locations.updating || state.parts_per_stock_locations.fetching,
});

const mapDispatchToProps = (dispatch) => ({
	fetchMultipleStockLocations: (ids, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	bulkTransfer: (entities, toStockLocationid) =>
		dispatch(bulkTransferPartsPerStockLocactionForSupplier(entities, toStockLocationid)),
	fetchMultiplePartsPerStockLocation: (ids, targetCollectionName) =>
		dispatch(
			partsPerStockLocationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
		),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(BulkTransferPartsForm)
);

import React, { FC, useCallback, useEffect, useState } from 'react';
import PurchaseOrderForm from '../purchase_order_form/PurchaseOrderForm';
import { Col, Layout, Row } from 'antd';
import { getObjectValues, getTotalValue, nullSafeGet } from '../../utils/DataAccessUtils';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getPOLineItemsFromSelectedRecords } from './pr_to_po_utils';

const { Content } = Layout;

const PRToPOPage: FC<any> = ({
	selectedRecords,
	onCancel,
	history,
	selectedVendor,
	selectedSL,
	selectedLocation,
}): React.ReactElement => {
	const [poPartLineItemsInCart, setPoPartLineItemsInCart] = useState([]);
	const [poEquipmentLineItemsInCart, setPoEquipmentLineItemsInCart] = useState([]);
	const [lineItemsLoaded, setLineItemsLoaded] = useState(false);

	useEffect(() => {
		const { partLineItems, equipmentLineItems } =
			getPOLineItemsFromSelectedRecords(selectedRecords);

		setPoPartLineItemsInCart(partLineItems);
		setPoEquipmentLineItemsInCart(equipmentLineItems);

		setLineItemsLoaded(true);
	}, [selectedRecords]);

	const handleRemovePartFromCart = useCallback((removedPartId) => {
		setPoPartLineItemsInCart((_) => _.filter((lineItem) => lineItem.partId !== removedPartId));
	}, []);

	const handleRemoveEquipmentFromCart = useCallback((removedEquipmentId) => {
		setPoEquipmentLineItemsInCart((_) =>
			_.filter((lineItem) => lineItem.equipmentTypeId !== removedEquipmentId)
		);
	}, []);

	const handleEditPart = useCallback((record) => {
		setPoPartLineItemsInCart((_) =>
			_.map((lineItem) => (lineItem.partId === record.partId ? record : lineItem))
		);
	}, []);

	const handleEditEquipment = useCallback((record) => {
		setPoEquipmentLineItemsInCart((_) =>
			_.map((lineItem) => (lineItem.equipmentTypeId === record.equipmentTypeId ? record : lineItem))
		);
	}, []);

	const removeLineItemCosts = useCallback(() => {
		setPoPartLineItemsInCart((_) =>
			_.map((lineItem) => ({
				...lineItem,
				partCost: undefined,
			}))
		);
		setPoEquipmentLineItemsInCart((_) =>
			_.map((lineItem) => ({
				...lineItem,
				equipmentCost: undefined,
			}))
		);
	}, []);

	return (
		<Content style={{ padding: 8 }}>
			<Row gutter={16} className="rowSpacing">
				<Col span={24}>
					{lineItemsLoaded ? (
						<PurchaseOrderForm
							formData={{
								partSupplierPurchaseOrderLineItems: poPartLineItemsInCart,
								equipmentSupplierPurchaseOrderLineItems: poEquipmentLineItemsInCart,
								partEquipmentVendor: selectedVendor,
								partEquipmentVendorId: nullSafeGet('id', selectedVendor),
								stockLocationId: nullSafeGet('id', selectedSL),
								locationId: nullSafeGet('id', selectedLocation),
							}}
							handleUpdateLineItems={(poPartLineItemsInCart, poEquipmentLineItemsInCart) => {
								setPoPartLineItemsInCart(poPartLineItemsInCart);
								setPoEquipmentLineItemsInCart(poEquipmentLineItemsInCart);
							}}
							handleRemovePartFromCart={handleRemovePartFromCart}
							handleEditPart={handleEditPart}
							handleRemoveEquipmentFromCart={handleRemoveEquipmentFromCart}
							handleEditEquipment={handleEditEquipment}
							removeLineItemCosts={removeLineItemCosts}
							onSuccess={(rec) => history.push(`/supplier/purchaseOrders/detail/${rec.id}`)}
							onCancel={onCancel}
						/>
					) : null}
				</Col>
			</Row>
		</Content>
	);
};

export default withRouter<any, any>(connect()(PRToPOPage));

import { DatePicker } from 'antd';
import React, { FC, useCallback, useMemo } from 'react';
import { getDefaultDateRanges } from '../../utils/DataFormatterUtils';

const RANGE_PICKER_DATE_FORMAT = 'MM-DD-YYYY';

const OWDateRangePicker: FC<any> = ({
	value,
	onChange,
	ranges = getDefaultDateRanges(),
}): React.ReactElement => {
	const startDate = useMemo(() => value && value.length > 0 && value[0], [value]);
	const endDate = useMemo(() => value && value.length > 1 && value[1], [value]);
	return (
		<DatePicker.RangePicker
			key={startDate && endDate ? startDate.format('YYMMDD') + endDate.format('YYMMDD') : undefined}
			value={value}
			ranges={ranges}
			format={RANGE_PICKER_DATE_FORMAT}
			showTime={false}
			onChange={onChange}
			allowEmpty={[true, true]}
		/>
	);
};

export default OWDateRangePicker;

import { SUPPLIERS_CRUD_ACTION_CREATORS } from '../actions/suppliers_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { getBackendUri } from '../utils/EnvConfigUtils';
import FetchUtils, { parseJSON } from './fetch_utils';
import { logoutSuccess } from '../actions/session_actions';

export const suppliersRestCrudThunksForBuyer = new RestCrudThunks(
	'suppliers',
	'supplier',
	'supplier',
	'suppliers',
	SUPPLIERS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER
);
export const suppliersRestCrudThunksForSupplier = new RestCrudThunks(
	'suppliers',
	'supplier',
	'supplier',
	'suppliers',
	SUPPLIERS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER
);

const fetchUtils = new FetchUtils();

export const getSuggestionsForSupplierCompanies = (data) => (dispatch) => {
	return new Promise((resolve, reject) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/supplier/suppliers/get_suggested_suppliers_for_new_user`;
		fetchUtils
			.post(
				baseUrl + apiUrl,

				{
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data,
				}
			)
			.then(parseJSON)
			.then(({ json, status, ok }) => {
				const d = json;
				if (status === 401) {
					dispatch(logoutSuccess());
				}
				if (d.status === 'error') {
					reject(d.message);
				} else {
					resolve(d);
				}
			})
			.catch((d) => {
				reject(d.message);
			});
	});
};

export const createSupplierCompany = (data) => (dispatch) => {
	return new Promise((resolve, reject) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/supplier/suppliers/add_supplier_for_new_user`;
		fetchUtils
			.post(
				baseUrl + apiUrl,

				{
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data,
				}
			)
			.then(parseJSON)
			.then(({ json, status, ok }) => {
				const d = json;
				if (status === 401) {
					dispatch(logoutSuccess());
				}
				if (d.status === 'error') {
					reject(d.message);
				} else {
					resolve(d);
				}
			})
			.catch((d) => {
				reject(d.message);
			});
	});
};

export const associateSupplierContactWithCompany = (id, data) => (dispatch) => {
	return new Promise((resolve, reject) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/supplier/suppliers/associate_supplier_for_new_user/${id}`;
		fetchUtils
			.post(
				baseUrl + apiUrl,

				{
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data,
				}
			)
			.then(parseJSON)
			.then(({ json, status, ok }) => {
				const d = json;
				if (status === 401) {
					dispatch(logoutSuccess());
				}
				if (d.status === 'error') {
					reject(d.message);
				} else {
					resolve(d);
				}
			})
			.catch((d) => {
				reject(d.message);
			});
	});
};

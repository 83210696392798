import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { DownloadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Layout, Row, Col, Button, Card, Popover, Input } from 'antd';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import {
	assetsRestCrudThunksForDowntime,
	assetsRestCrudThunksForDowntimeForSupplier,
	downloadDowntimeAssetCSV,
} from '../../thunks/assets_thunks';
import { EmptyState } from '../empty_state/EmptyState';
import SubnavBar from '../subnav_bar/SubnavBar';
import { AdvancedFilters } from '../advanced_filters/AdvancedFilters';
import { ASSETS_CRUD_ACTION_CREATORS } from '../../actions/assets_actions';
import {
	nullSafeGet,
	nullSafeGetOrElse,
	changeFilterValueToArrayOfIds,
	getStartDateFieldName,
	getEndDateFieldName,
} from '../../utils/DataAccessUtils';
import {
	locationsRestCrudThunksForBuyer,
	locationsRestCrudThunksForSupplier,
} from '../../thunks/locations_thunks';
import {
	assetTypesRestCrudThunksForBuyer,
	assetTypesRestCrudThunksForSupplier,
} from '../../thunks/asset_types_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import {
	regionsRestCrudThunksForBuyer,
	regionsRestCrudThunksForSupplier,
} from '../../thunks/regions_thunks';
import RegionsLocationsTreeSelect from '../regions_tree_select/BuyerRegionsLocationsTreeSelect';
import OWAsyncTreeSelect from '../ow_async_tree_select/OWAsyncTreeSelect';
import { PAGE_SIZE_OPTIONS } from '../../constants/pagination';
import OWRadioGroup from '../ow_radio_group/OWRadioGroup';
import { FILTER_CONFIG_NAMES, FILTER_FIELD_TYPE, ROLE_TYPES } from '../../utils/DataConstants';
import SelectedFilters from '../selected_filters_pills/SelectedFilters';
import OWDateRangePicker from '../ow_date_range_picker/OWDateRangePicker';
import moment from 'moment';
import { formatDateForParam, secondsToStr } from '../../utils/DataFormatterUtils';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { Link } from 'react-router-dom';
import { AssetRowDisplay } from '../asset_row_display/AssetRowDisplay';
import {} from '../../thunks/asset_analytics_thunks';
import { getIsActiveFilterField, INITIAL_ACTIVE_FILTERS } from '../../utils/FilterUtils';

const queryString = require('qs');
const { Content } = Layout;

const ASSETS_DOWNTIME_TARGET_COLLECTION = 'assetsDowntime';

class AssetDowntimeHOC extends React.Component<any, any> {
	initialPagination: any = { current: 1, pageSize: 10 };
	initialFilters: any = {};
	initialSorting: any = {};
	preAppliedFilters: any = {};

	constructor(props) {
		super(props);
		const { updateAssetsFilters, location, history, userType } = props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		const {
			current,
			pageSize,
			total,
			pageSizeOptions,
			showSizeChanger,
			onShowSizeChange,
			sort_by,
			order,
			...assetFilters
		} = queryParams;
		this.initialPagination = { current: current ? current : 1, pageSize: pageSize ? pageSize : 10 };
		this.initialSorting = sort_by
			? { sort_by, order }
			: { sort_by: 'meanDownTime', order: 'descend' };
		const { startDate, endDate, rightNow } = assetFilters;
		this.initialFilters = assetFilters || {};
		if (!rightNow) {
			this.initialFilters.startDate =
				startDate || formatDateForParam(moment(startDate).subtract(3, 'month'));
			this.initialFilters.endDate = endDate || formatDateForParam(moment(endDate));
		}

		updateAssetsFilters(this.initialFilters, ASSETS_DOWNTIME_TARGET_COLLECTION);
		history.push(`/${userType}/assets/overview/downtime?${new URLSearchParams(location.search)}`);
	}

	downloadCSV = () => {
		const { assets, downloadAssetCSV } = this.props;
		const filters = assets[ASSETS_DOWNTIME_TARGET_COLLECTION].filters;
		downloadAssetCSV({}, filters);
	};

	render() {
		const {
			clearAndUpdateFilters,
			assets,
			fetchLocations,
			locations,
			fetchAssetTypes,
			assetTypes,
			updateAssetsFilters,
			regions,
			fetchRegions,
			userType,
		} = this.props;

		const activeSorting = nullSafeGet(`${ASSETS_DOWNTIME_TARGET_COLLECTION}.sorting`, assets);

		const columns = [
			{
				title: 'Asset',
				dataIndex: 'name',
				key: 'name',
				render: (text, { asset = {} }) => {
					return (
						<Popover content={<AssetRowDisplay asset={asset} />} trigger="hover">
							<Link
								to={`/${userType}/assets/detail/${asset.id}/downtimeHistory`}
								target="_blank"
								className="assetRowDisplay__rowTitle ml-1 flex flex-row items-center text-center text-base  text-slate-800"
							>
								<Ellipsis
									tooltip={true}
									length={56}
									className="assetRowDisplay__rowTitle flex w-auto flex-row items-center text-base  text-slate-800 underline"
								>
									{asset.name}
								</Ellipsis>

								<i
									className="icons8-font icons8-external-link ml-2 inline-block "
									style={{ color: '#6027b0' }}
								/>
							</Link>
						</Popover>
					);
				},
				width: '30%',
			},
			{
				title: 'Mean Downtime',
				dataIndex: 'meanDownTime',
				key: 'meanDownTime',
				render: (text) => secondsToStr(text),
				sorter: (a, b) => (a.meanDownTime || 0) - (b.meanDownTime || 0),
				sortOrder: activeSorting.sort_by === 'meanDownTime' ? activeSorting.order : null,
				width: '15%',
			},
			{
				title: 'Mean time to respond',
				dataIndex: 'meanTimeToRespond',
				key: 'meanTimeToRespond',
				render: (text) => secondsToStr(text),
				sorter: (a, b) => (a.meanTimeToRespond || 0) - (b.meanTimeToRespond || 0),
				sortOrder: activeSorting.sort_by === 'meanTimeToRespond' ? activeSorting.order : null,
				width: '15%',
			},
			{
				title: 'Mean time between failures',
				dataIndex: 'meanTimeBetweenFailures',
				key: 'meanTimeBetweenFailures',
				render: (text) => secondsToStr(text),
				sorter: (a, b) => (a.meanTimeBetweenFailures || 0) - (b.meanTimeBetweenFailures || 0),
				sortOrder: activeSorting.sort_by === 'meanTimeBetweenFailures' ? activeSorting.order : null,
				width: '15%',
			},
		];

		const filterConfig = [
			{
				label: 'Downtime Date Range',
				labelPrefix: 'Downtime Date Range',
				fieldName: 'default',
				type: FILTER_FIELD_TYPE.DATE_RANGE,
				filtersValueAccessor: (filtersStateSlice) => {
					const startDate = nullSafeGet(
						`${ASSETS_DOWNTIME_TARGET_COLLECTION}.filters.startDate`,
						filtersStateSlice
					);
					const endDate = nullSafeGet(
						`${ASSETS_DOWNTIME_TARGET_COLLECTION}.filters.endDate`,
						filtersStateSlice
					);
					return [startDate && moment(startDate), endDate && moment(endDate)];
				},

				handleChange: (values) => {
					updateAssetsFilters(
						{
							startDate:
								values && values.length > 1 && values[0]
									? formatDateForParam(values[0])
									: undefined,
							endDate:
								values && values.length > 1 && values[1]
									? formatDateForParam(values[1])
									: undefined,
							rightNow: values && values.length > 1 ? undefined : true,
						},
						ASSETS_DOWNTIME_TARGET_COLLECTION
					);
				},
				renderRecord: (item) => item,
				valueAccessor: (item) => item,
				component: OWDateRangePicker,
			},
			{
				component: Input.Search,
				placeholder: 'Search for a manufacturer',
				handleChange: (e) => {
					updateAssetsFilters(
						{
							manufacturer: e.target.value,
						},
						ASSETS_DOWNTIME_TARGET_COLLECTION
					);
				},
				defaultValue: nullSafeGet(
					`${ASSETS_DOWNTIME_TARGET_COLLECTION}.filters.manufacturer`,
					assets
				),
				label: 'Manufacturer',
				// labelPrefix: 'Manufacturer',
				fieldName: 'manufacturer',
				type: FILTER_FIELD_TYPE.SEARCH,
				filtersValueAccessor: (filtersStateSlice) =>
					nullSafeGet(`${ASSETS_DOWNTIME_TARGET_COLLECTION}.filters.manufacturer`, assets),
				renderItem: (loc) => loc,
				valueAccessor: (loc) => loc,
			},
			{
				label: 'Location',
				fieldName: 'locationIds',
				modee: 'multiple',
				stateSlice: locations,
				hideFilter: true,
				filtersValueAccessor: (filtersStateSlice) => {
					const ids = nullSafeGet(
						`${ASSETS_DOWNTIME_TARGET_COLLECTION}.filters.locationIds`,
						filtersStateSlice
					);
					return changeFilterValueToArrayOfIds(ids);
				},
				keyAccessor: (loc) => loc.id,
				valueAccessor: (loc) => loc.id,
				handleChange: (ids) => {
					updateAssetsFilters(
						{
							locationIds: ids.join(',') || undefined,
						},
						ASSETS_DOWNTIME_TARGET_COLLECTION
					);
				},
				targetCollectionName: 'assetsAdvancedFilters',
				fetch: fetchLocations,
				fetchMultiple: () => {},
				renderItem: (loc) => loc.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
			},
			{
				label: 'Asset Type',
				fieldName: FILTER_CONFIG_NAMES.ASSET_TYPE,
				mode: 'multiple',
				stateSlice: assetTypes,
				filtersValueAccessor: (filtersStateSlice) => {
					const ids = nullSafeGet(
						`${ASSETS_DOWNTIME_TARGET_COLLECTION}.filters.assetTypeIds`,
						filtersStateSlice
					);
					return changeFilterValueToArrayOfIds(ids);
				},
				keyAccessor: (assetType) => assetType.id,
				valueAccessor: (assetType) => assetType.id,
				handleChange: (ids) => {
					updateAssetsFilters(
						{
							assetTypeIds: ids.join(',') || undefined,
						},
						ASSETS_DOWNTIME_TARGET_COLLECTION
					);
				},
				targetCollectionName: 'assetsAdvancedFilters',
				fetch: fetchAssetTypes,
				fetchMultiple: () => {},
				renderItem: (assetType) => assetType.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
				component: OWAsyncTreeSelect,
			},
			{
				fieldName: 'downtimeStatus',
				type: 'radioGroup',
				items: [
					{
						label: 'All',
						value: undefined,
					},
					{
						label: 'Currently Offline',
						value: 'offline',
					},
				],
				filtersValueAccessor: (filtersStateSlice) =>
					nullSafeGet(
						`${ASSETS_DOWNTIME_TARGET_COLLECTION}.filters.downtimeStatus`,
						filtersStateSlice
					),
				keyAccessor: (item) => item.value,
				valueAccessor: (item) => item.value,
				handleChange: (downtimeStatus) =>
					updateAssetsFilters({ downtimeStatus }, ASSETS_DOWNTIME_TARGET_COLLECTION),
				targetCollectionName: ASSETS_DOWNTIME_TARGET_COLLECTION,
				renderRecord: (item) => item.label,
				component: OWRadioGroup,
				info: [
					{
						title: 'Currently Offline',
						description: 'Asset is not working at this moment.',
					},
				],
			},
			{
				label: 'Region',
				mode: 'multiple',
				stateSlice: regions,
				hideFilter: true,
				filtersValueAccessor: (filtersStateSlice) => {
					const ids = nullSafeGet(
						`${ASSETS_DOWNTIME_TARGET_COLLECTION}.filters.regionIds`,
						filtersStateSlice
					);
					return changeFilterValueToArrayOfIds(ids);
				},
				keyAccessor: (region) => region.id,
				valueAccessor: (region) => region.id,
				handleChange: (ids) => {
					updateAssetsFilters(
						{
							regionIds: ids.join(',') || undefined,
						},
						ASSETS_DOWNTIME_TARGET_COLLECTION
					);
				},
				targetCollectionName: 'assetsAdvancedFilters',
				fetch: fetchRegions,
				fetchMultiple: () => {},
				renderItem: (region) => region.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
			},
			...[getIsActiveFilterField(updateAssetsFilters, ASSETS_DOWNTIME_TARGET_COLLECTION)],
			{
				label: 'Installed Between',
				labelPrefix: 'Installed',
				fieldName: FILTER_CONFIG_NAMES.INSTALL_AT,
				type: FILTER_FIELD_TYPE.DATE_RANGE,
				filtersValueAccessor: (filtersStateSlice) => {
					const startDate = nullSafeGet(
						`${ASSETS_DOWNTIME_TARGET_COLLECTION}.filters.${getStartDateFieldName(
							FILTER_CONFIG_NAMES.INSTALL_AT
						)}`,
						filtersStateSlice
					);
					const endDate = nullSafeGet(
						`${ASSETS_DOWNTIME_TARGET_COLLECTION}.filters.${getEndDateFieldName(
							FILTER_CONFIG_NAMES.INSTALL_AT
						)}`,
						filtersStateSlice
					);
					return [startDate && moment(startDate), endDate && moment(endDate)];
				},
				handleChange: (values) => {
					updateAssetsFilters(
						{
							[getStartDateFieldName(FILTER_CONFIG_NAMES.INSTALL_AT)]:
								values && values.length > 1 && values[0]
									? formatDateForParam(values[0])
									: undefined,
							[getEndDateFieldName(FILTER_CONFIG_NAMES.INSTALL_AT)]:
								values && values.length > 1 && values[1]
									? formatDateForParam(values[1])
									: undefined,
						},
						ASSETS_DOWNTIME_TARGET_COLLECTION
					);
				},
				renderRecord: (item) => item,
				valueAccessor: (item) => item,
				component: OWDateRangePicker,
			},
			{
				fieldName: 'hasWarranty',
				type: 'radioGroup',
				items: [
					{
						label: 'All',
						value: 'undefined',
					},
					{
						label: 'Under Warranty',
						value: 'true',
					},
				],
				filtersValueAccessor: (filtersStateSlice) => {
					const filters = nullSafeGet(
						`${ASSETS_DOWNTIME_TARGET_COLLECTION}.filters`,
						filtersStateSlice
					);
					return typeof filters.hasWarranty === 'undefined'
						? 'undefined'
						: `${filters.hasWarranty}`;
				},
				defaultValue: undefined,
				keyAccessor: (item) => item.value,
				valueAccessor: (item) => item.value,
				handleChange: (hasWarranty) => {
					const val = hasWarranty === 'undefined' ? undefined : true;
					updateAssetsFilters({ hasWarranty: val }, ASSETS_DOWNTIME_TARGET_COLLECTION);
				},
				targetCollectionName: 'assetsAdvancedFilters',
				renderRecord: (item) => item.label,
				component: OWRadioGroup,
				info: [
					{
						title: 'Under Warranty',
						description: `Assets that are under warranty`,
					},
				],
			},
		];

		return (
			<Content className="AssetDowntimeHOC" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited assets index page" />
				<Row style={{ margin: '0.5em -8px' }}>
					<Col span={24}>
						<SubnavBar
							left={
								<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
									<AdvancedFilters
										updateFilters={updateAssetsFilters}
										filterConfig={filterConfig}
										filtersTargetCollectionName={ASSETS_DOWNTIME_TARGET_COLLECTION}
										clearAndUpdateFilters={(filters, targetCollectionName) => {
											const newFilters = { ...filters };
											if (!(newFilters.startDate && newFilters.endDate)) {
												newFilters.rightNow = true;
											}
											clearAndUpdateFilters(newFilters, targetCollectionName);
										}}
										filtersStateSlice={assets}
										preAppliedFilters={INITIAL_ACTIVE_FILTERS}
										initialFilters={{ ...this.initialFilters, ...INITIAL_ACTIVE_FILTERS }}
									>
										<Form.Item label="Location">
											<RegionsLocationsTreeSelect
												mode="multiple"
												filtersSlice={assets}
												filtersValueAccessor={(filtersStateSlice) => {
													const locationIds = nullSafeGet(
														`${ASSETS_DOWNTIME_TARGET_COLLECTION}.filters.locationIds`,
														filtersStateSlice
													);
													const finalIds = nullSafeGetOrElse(
														`${ASSETS_DOWNTIME_TARGET_COLLECTION}.filters.regionIds`,
														filtersStateSlice,
														locationIds
													);
													return changeFilterValueToArrayOfIds(finalIds);
												}}
												onChange={(ids) => {
													updateAssetsFilters(
														{
															locationIds: ids.join(',') || undefined,
															regionIds: undefined,
														},
														ASSETS_DOWNTIME_TARGET_COLLECTION
													);
												}}
												targetCollectionName={'assetsAdvancedFilters'}
											/>
										</Form.Item>
									</AdvancedFilters>
								</div>
							}
							right={
								<Col style={{ textAlign: 'right' }}>
									<Button
										style={{ marginRight: 12 }}
										size="large"
										icon={<DownloadOutlined translate="" />}
										className="inline-block-visible-md"
										onClick={this.downloadCSV}
									>
										<span className="inline-block-visible-xxl">Export CSV</span>
									</Button>
								</Col>
							}
						/>
					</Col>
				</Row>
				<div className="flex w-full flex-1 flex-row">
					<SelectedFilters
						updateFilters={(filters) => {
							const newFilters = { ...filters };
							if (
								filters.hasOwnProperty('startDate') &&
								filters.hasOwnProperty('endDate') &&
								!(filters.startDate && filters.endDate)
							) {
								newFilters.rightNow = true;
							}
							updateAssetsFilters(newFilters, ASSETS_DOWNTIME_TARGET_COLLECTION);
						}}
						filterValues={nullSafeGetOrElse(
							`${ASSETS_DOWNTIME_TARGET_COLLECTION}.filters`,
							assets,
							{}
						)}
						filterConfig={filterConfig}
					/>
				</div>
				<Row style={{ margin: '0.5em -8px' }}>
					<Col span={24}>
						<Card bodyStyle={{ padding: 8 }}>
							<PaginatedReduxTable
								updateQueryParams={true}
								emptyState={
									<EmptyState
										graphic={
											<img
												alt="Currently there are no assets!"
												style={{ marginBottom: 8 }}
												src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
											/>
										}
										headline={"It's a little lonely in here."}
										body={
											<div style={{ textAlign: 'center' }}>
												<div style={{ maxWidth: 440, marginBottom: 16 }}>
													We didn't find any assets with downtime. Try changing your filters or
													expanding the selected time period.
												</div>
											</div>
										}
									/>
								}
								collectionName="assets"
								targetCollectionName="assetsDowntime"
								columns={columns}
								keyAccessor={(el) => el.id}
								initialFilters={{ ...this.initialFilters, ...INITIAL_ACTIVE_FILTERS }}
								initialPagination={this.initialPagination}
								initialSorting={this.initialSorting}
								hiddenPagination={{ showSizeChanger: true, pageSizeOptions: PAGE_SIZE_OPTIONS }}
								fetchData={
									userType === ROLE_TYPES.SUPPLIER
										? assetsRestCrudThunksForDowntimeForSupplier.read
										: assetsRestCrudThunksForDowntime.read
								}
							/>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	assets: state.assets,
	assetTypes: state.asset_types,
	regions: state.regions,
	locations: state.locations,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	updateAssetsFilters: (filters, targetCollection) =>
		dispatch(ASSETS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(ASSETS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)),
	fetchAssetTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetTypesRestCrudThunksForSupplier.readLite(
						{ ...params, no_pagination: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: assetTypesRestCrudThunksForBuyer.readLite(
						{ ...params, no_pagination: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),

	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: locationsRestCrudThunksForBuyer.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchRegions: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? regionsRestCrudThunksForSupplier.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: regionsRestCrudThunksForBuyer.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	downloadAssetCSV: (params, filters) =>
		dispatch(downloadDowntimeAssetCSV(ownProps.userType)(params, filters)),
});

const ComponentWithoutUserType = withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(AssetDowntimeHOC)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Card } from 'antd';
import SupplierNewWorkOrderForm from '../supplier_new_work_order_form/SupplierNewWorkOrderForm';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { getBackLinkStates } from '../../utils/HistoryUtils';

const { Content } = Layout;

const DEFAULT_BACK_PATH = '/supplier/workOrders';

class SupplierNewWorkOrderPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			backLinkParams: '',
			backLinkTo: DEFAULT_BACK_PATH,
		};
	}

	componentDidMount(): void {
		const { location } = this.props;
		this.setState(getBackLinkStates(location, DEFAULT_BACK_PATH));
	}

	render() {
		const { history } = this.props;
		return (
			<Content>
				<LogOnMountWithStandardEventProperties eventType="visited supplier new work order page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<Card>
							<div>
								<h5 style={{ fontWeight: 400 }}>New Work Order</h5>
								<SupplierNewWorkOrderForm
									formData={{}}
									onSuccess={(rec) =>
										history.push(
											`/supplier/workOrders/detail/${rec.id}`.concat('?', this.state.backLinkParams)
										)
									}
								/>
							</div>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	location: ownProps.location,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(SupplierNewWorkOrderPage)
);

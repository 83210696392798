import React, { FC, useEffect, useState, useCallback, useMemo } from 'react';
import { Form, Input, Radio, Button, Modal } from 'antd';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { imageUploadValidation } from '../../utils/ImageUtils';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import { retrieveCachedUserDetails } from '../../thunks/session_thunks';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { getProtectedImageUriForBuyer } from '../../utils/FileAccessUtils';
import OWUpload from '../OWUpload';

interface TaskEditFormProps {
	taskAnswer: any;
	onCancel: () => void;
	onSave: (answer: any) => void;
}

interface FieldData {
	name: string;
	value?: any;
}

const TaskEditPopup: FC<TaskEditFormProps> = ({
	taskAnswer,
	onCancel,
	onSave,
}): React.ReactElement => {
	const [form] = Form.useForm();

	const [fields, setFields] = useState<FieldData[]>([]);
	const [attachments, setAttachments] = useState([]);
	const [uploading, setUploading] = useState(false);
	const [saving, setSaving] = useState(false);
	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState('');

	const BACKEND_URI = getBackendUri();
	const uploadHeaders = { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token };

	const initailList = useMemo(() => {
		const initialAttachments = taskAnswer.attachments.map((p, idx) => {
			const imgFileName = nullSafeGetOrElse('fileName', p, '');
			const imgFileId = nullSafeGetOrElse('fileId', p, '');
			const thumbUri = getProtectedImageUriForBuyer(imgFileId, imgFileName, 100, 100);
			const fullUri = getProtectedImageUriForBuyer(imgFileId, imgFileName, 400, 400);
			return {
				uid: idx,
				size: 1,
				name: imgFileName,
				filename: imgFileName,
				url: fullUri,
				status: 'done',
				thumbUrl: thumbUri,
				response: { data: { fileName: imgFileName, fileId: imgFileId } },
				type: 'image/jpeg',
			};
		});
		setAttachments(initialAttachments);
		return initialAttachments;
	}, [taskAnswer]);

	useEffect(() => {
		setFields([
			{
				name: 'answer',
				value: taskAnswer.answer,
			},
			{
				name: 'comments',
				value: taskAnswer.comments,
			},
		]);
	}, [form, taskAnswer]);

	const getFormField = useCallback((task) => {
		return task.questionType === 'multipleChoice' ? (
			nullSafeGetOrElse('multipleChoicesWithLabel.length', task, 0) !== 0 ? (
				<Radio.Group>
					{task.multipleChoicesWithLabel.map((choice, idx) => {
						const value = `${choice.value}${choice.description ? ` (${choice.description})` : ''}`;
						return (
							<Radio value={value} key={`rg-${idx}`}>
								{value}
							</Radio>
						);
					})}
				</Radio.Group>
			) : (
				''
			)
		) : (
			<Input.TextArea placeholder="" />
		);
	}, []);

	const handleImageUpdate = useCallback(
		(info) => {
			setUploading(info.file.status === 'uploading');

			if (info.file.status === 'done' || 'removed') {
				setAttachments(info.fileList);
			} else if (info.file.status === undefined) {
				info.fileList = attachments;
			}
		},
		[attachments]
	);

	const handleSubmit = useCallback(
		(values) => {
			setSaving(true);
			onSave({
				...taskAnswer,
				answer: values.answer,
				comments: values.comments,
				attachments: attachments.map((attachment) => attachment.response.data),
			});
		},
		[taskAnswer, attachments, onSave]
	);

	const handleCancel = useCallback(() => {
		setPreviewVisible(false);
		setPreviewImage('');
	}, []);

	const handleImagePreview = useCallback((obj) => {
		if (!obj.response.data) return;

		const imgFileId = obj.response.data.fileId;
		const imgFileName = obj.response.data.fileName;
		const thumbUrl = getProtectedImageUriForBuyer(imgFileId, imgFileName, 100, 100);
		const fullUrl = getProtectedImageUriForBuyer(imgFileId, imgFileName, 400, 400);
		setPreviewImage(fullUrl || thumbUrl);
		setPreviewVisible(true);
	}, []);

	return (
		<Modal visible={true} title="Edit Answer" onCancel={onCancel} footer={null} closable={true}>
			<Form layout="vertical" fields={fields} onFinish={handleSubmit}>
				<Form.Item name="answer" label={taskAnswer.title}>
					{getFormField(taskAnswer)}
				</Form.Item>
				<Form.Item name="comments" label="Comments">
					<Input.TextArea />
				</Form.Item>
				<Form.Item name="attachments" label="Images">
					<OWUpload
						name="file"
						listType="picture-card"
						accept="image/*"
						multiple={true}
						defaultFileList={initailList}
						headers={uploadHeaders}
						onPreview={handleImagePreview}
						action={`${BACKEND_URI}/api/v1/buyer/file/upload`}
						beforeUpload={imageUploadValidation}
						onChange={handleImageUpdate}
					>
						<div style={{ display: 'inline' }}>
							<LegacyIcon type={uploading ? 'loading' : 'plus'} />
							<div className="ant-upload-text">Add Image</div>
						</div>
					</OWUpload>
					<Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
						<img alt="example" style={{ width: '100%' }} src={previewImage} />
					</Modal>
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit" loading={saving}>
						Save
					</Button>
					<Button style={{ marginLeft: '24px' }} onClick={onCancel}>
						Cancel
					</Button>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default TaskEditPopup;

import { FC } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Button, Card } from 'antd';
import InvoiceUploader from '../invoice_uploader/InvoiceUploader';
import * as React from 'react';
import { isInternalTech } from '../../utils/AuthUtils';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';

const InvoiceDraftBanner: FC<any> = ({
	userType,
	currentUser,

	invoice,
	generateInvoicePDF,
	handleInvoicePDFUploadChange,
	generatingPDF,
}) => {
	return !isInternalTech(currentUser) &&
		invoice.status === 'draft' &&
		nullSafeGetOrElse('invoicePDFLink', invoice, []).length === 0 ? (
		<div className="rowSpacing">
			<Card
				style={{ border: '1px solid #8ed7eb' }}
				bodyStyle={{
					padding: '16px 24px',
					background: '#ebfbff',
				}}
			>
				<div>
					<h5 style={{ marginBottom: '4px' }}>Upload an invoice file</h5>
					<div>
						<p
							style={{
								fontSize: 16,
								marginBottom: '0.5em',
							}}
						>
							Please add an invoice file (PDF) for this invoice before submitting it.
						</p>
						<InvoiceUploader
							roleType={userType}
							invoiceId={invoice.id}
							buttonType="primary"
							defaultFileList={[]}
							multiple={false}
							handleUploadSuccess={handleInvoicePDFUploadChange(invoice)}
						/>
						<p style={{ marginBottom: 0, marginTop: 8 }}>
							Don't have an invoice file?{' '}
							<Button
								loading={generatingPDF}
								type="link"
								onClick={() => generateInvoicePDF(invoice.id)}
							>
								Generate PDF
							</Button>
						</p>
					</div>
				</div>
			</Card>
		</div>
	) : null;
};

const mapStateToProps = (state, ownProps) => ({
	currentUser: state.session.currentUser,
});

const ComponentWithoutUserType = withRouter(connect(mapStateToProps, () => {})(InvoiceDraftBanner));

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import { PURCHASE_ORDERS_CRUD_ACTION_CREATORS } from '../actions/purchase_orders_actions';
import { PURCHASE_REQUESTS_CRUD_ACTION_CREATORS } from '../actions/purchase_requests_actions';
import POPipeLineFilters from '../components/purchase_orders_index_page/POPipelineFIlters';
import PRPipeLineFilters from '../components/purchase_requests_index_page/PRPipelineFilters';
import {
	DEFAULT_SORTER,
	FETCH_DATA_PREFIX_FOR_TARGET_COLLECTION,
	GLOBAL_DEBOUNCE_TIME,
} from '../utils/DataConstants';

import { debounce } from '../utils/PerformanceUtils';

const queryString = require('qs');
const defaultPagination = { current: 1, pageSize: 10 };

const entityToPipeLineFilters = {
	supplier: {
		purchase_orders: POPipeLineFilters,
		purchase_requests: PRPipeLineFilters,
	},
	buyer: {},
};
const entityToCreators = {
	purchase_orders: PURCHASE_ORDERS_CRUD_ACTION_CREATORS,
	purchase_requests: PURCHASE_REQUESTS_CRUD_ACTION_CREATORS,
};

const parseURL = (
	handleQueryParams,
	history,
	location,
	preAppliedFilters,
	initialPagination,
	initialSorting,
	initialFilters = {}
) => {
	let pagination = initialPagination || defaultPagination;
	let sorting = initialSorting || DEFAULT_SORTER;
	let filters = { ...preAppliedFilters, ...initialFilters };
	if (handleQueryParams && location) {
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);

		const {
			current = pagination.current,
			pageSize = pagination.pageSize,
			sort_by = sorting.sort_by,
			order = sorting.order,
			...rest
		} = queryParams;

		pagination = { current: parseInt(current), pageSize: parseInt(pageSize) };
		sorting = { sort_by, order };
		filters = {
			...(initialFilters || {}),
			...(rest || {}),
			...preAppliedFilters,
		};
	}
	return { pagination, sorting, filters };
};
export const fetchEntities =
	(userType, entityName, fetchData) =>
	(
		handleQueryParams,
		history,
		location,
		targetCollectionName,
		preAppliedFilters = {},
		initialPagination,
		initialSorting,
		initialFilters,
		ignoreRefreshCounts
	) =>
	(dispatch, getState) => {
		const { pagination, sorting, filters } = parseURL(
			handleQueryParams,
			history,
			location,
			preAppliedFilters,
			initialPagination,
			initialSorting,
			initialFilters
		);
		dispatch(
			fetchDataCall(userType, entityName, fetchData)(
				handleQueryParams,
				history,
				null,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				ignoreRefreshCounts
			)
		);
	};
export const updatePaginationEntities =
	(userType, entityName, fetchData) =>
	(handleQueryParams, history, targetCollectionName, pagination, ignoreRefreshCounts) =>
	(dispatch, getState) => {
		const { sorting, filters } = getState()[entityName][targetCollectionName];
		return dispatch(
			fetchDataCall(userType, entityName, fetchData)(
				handleQueryParams,
				history,
				null,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				ignoreRefreshCounts
			)
		);
	};

export const updateFiltersEntities =
	(userType, entityName, fetchData) =>
	(handleQueryParams, history, targetCollectionName, filters, ignoreRefreshCounts) =>
	(dispatch, getState) => {
		const {
			sorting,
			filters: storeFilters,
			pagination,
		} = getState()[entityName][targetCollectionName];
		dispatch(
			fetchDataCall(userType, entityName, fetchData)(
				handleQueryParams,
				history,
				null,
				targetCollectionName,
				{ ...pagination, current: 1 },
				sorting,
				{
					...storeFilters,
					...filters,
				},
				ignoreRefreshCounts
			)
		);
	};

export const replaceFiltersAndSortingEntities =
	(userType, entityName, fetchData) =>
	(handleQueryParams, history, targetCollectionName, filters, sorting, ignoreRefreshCounts) =>
	(dispatch, getState) => {
		const { pagination } = getState()[entityName][targetCollectionName];
		dispatch(
			fetchDataCall(userType, entityName, fetchData)(
				handleQueryParams,
				history,
				null,
				targetCollectionName,
				{ ...pagination, current: 1 },
				sorting,
				filters,
				ignoreRefreshCounts
			)
		);
	};

export const clearFiltersEntities =
	(userType, entityName, fetchData) =>
	(handleQueryParams, history, targetCollectionName, preAppliedFilters = {}, ignoreRefreshCounts) =>
	(dispatch, getState) => {
		const { pagination } = getState()[entityName][targetCollectionName];
		dispatch(
			fetchDataCall(userType, entityName, fetchData)(
				handleQueryParams,
				history,
				null,
				targetCollectionName,
				{ ...pagination, current: 1 },
				{ ...DEFAULT_SORTER },
				{
					...preAppliedFilters,
				},
				ignoreRefreshCounts
			)
		);
	};

export const updateSortingEntities =
	(userType, entityName, fetchData) =>
	(handleQueryParams, history, targetCollectionName, sorting, ignoreRefreshCounts) =>
	(dispatch, getState) => {
		const { filters, pagination } = getState()[entityName][targetCollectionName];
		dispatch(
			fetchDataCall(userType, entityName, fetchData)(
				handleQueryParams,
				history,
				null,
				targetCollectionName,
				{ ...pagination, current: 1 },
				sorting,
				filters,
				ignoreRefreshCounts
			)
		);
	};

export const refreshCounts =
	(entityName) => (targetCollectionName, filters, fetchData) => (dispatch, getState) => {
		const finalPipelineFilters = entityToPipeLineFilters['supplier'][entityName];
		finalPipelineFilters.forEach((pf) => {
			dispatch(
				fetchData.countBy(
					{
						...filters,
						statuses: undefined,
						...pf.filter,
					},
					pf.name
				)
			);
		});
	};

const fetchDataFromServer = (props) => {
	const {
		dispatch,
		addtionalParams,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		fetchData,
		ignoreRefreshCounts,
		entityName,
	} = props;
	!ignoreRefreshCounts &&
		dispatch(refreshCounts(entityName)(targetCollectionName, filters, fetchData));
	return dispatch(
		fetchData.read(addtionalParams, targetCollectionName, pagination, sorting, filters)
	);
};

const debouncedFetchData = debounce(fetchDataFromServer, GLOBAL_DEBOUNCE_TIME);

const fetchDataCall =
	(userType, entityName, fetchData) =>
	(
		handleQueryParams,
		history,
		addtionalParams,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		ignoreRefreshCounts
	) =>
	(dispatch, getState) => {
		const props = {
			dispatch,
			userType,
			entityName,
			addtionalParams,
			targetCollectionName,
			pagination,
			sorting,
			filters,
			ignoreRefreshCounts,
			fetchData,
		};
		if (handleQueryParams) {
			const queryParams = queryString.stringify({
				...{
					current: pagination.current,
					pageSize: pagination.pageSize,
				},
				...sorting,
				...filters,
				...addtionalParams,
			});
			history.replace(`${window.location.pathname}?${queryParams}`);
		}

		if (
			targetCollectionName &&
			targetCollectionName.includes(FETCH_DATA_PREFIX_FOR_TARGET_COLLECTION)
		) {
			return fetchDataFromServer(props);
		}
		dispatch(entityToCreators[entityName].updateFilters(filters, targetCollectionName));

		return debouncedFetchData(props);
	};

import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'antd';

interface OneLineComponentProps {
	childrenToRender: any[];
	childrenText: String[];
	fontSize: number;
	additionalWidth: number;
	style: any;
}

const ButtonWidths = 115;

const OneLineComponent: FC<OneLineComponentProps> = ({
	childrenToRender,
	childrenText,
	fontSize,
	additionalWidth,
	style,
}): React.ReactElement => {
	const ref = useRef(null);

	const [parentWidth, setParentWidth] = useState(0);
	const [showAll, setShowAll] = useState(false);

	const setParentDimentions = useCallback(() => {
		ref.current && setParentWidth(ref.current.offsetWidth);
	}, []);

	useEffect(() => {
		setParentDimentions();
	}, [setParentDimentions]);

	useEffect(() => {
		window.addEventListener('resize', setParentDimentions);
		return () => {
			window.removeEventListener('resize', setParentDimentions);
		};
	}, [setParentDimentions]);

	const getTextWidth = useCallback(
		(text) => {
			const canvas = document.createElement('canvas');
			const context = canvas.getContext('2d');

			context.font = `${fontSize}px sans-serif`;
			return context.measureText(text).width;
		},
		[fontSize]
	);

	const changeShowStatus = useCallback((status) => () => setShowAll(status), []);

	const renderCount: number = useMemo(() => {
		const elementWidths = childrenText.map((text) => getTextWidth(text) + additionalWidth);
		let elementCount = elementWidths.length;
		let totalWidth = ButtonWidths;
		for (let i = 0; i < elementWidths.length; i++) {
			totalWidth += elementWidths[i];
			if (totalWidth > parentWidth) {
				elementCount = i;
				break;
			}
		}
		return elementCount || 1;
	}, [parentWidth, childrenText, additionalWidth, getTextWidth]);

	const elementsToShow = useMemo(
		() =>
			showAll || childrenToRender.length < renderCount
				? [...childrenToRender]
				: childrenToRender.slice(0, renderCount),
		[renderCount, showAll, childrenToRender]
	);

	const showAllNeeded = useMemo(
		() => renderCount < childrenToRender.length,
		[renderCount, childrenToRender]
	);

	const remainingCount = useMemo(
		() => childrenToRender.length - renderCount,
		[childrenToRender, renderCount]
	);

	return (
		<div
			ref={ref}
			style={{
				width: '100%',
				display: 'flex',
				flexWrap: 'wrap',
				alignItems: 'center',
				gap: 6,
				...style,
			}}
		>
			{elementsToShow.map((child) => child)}
			{showAllNeeded && (
				<Button onClick={changeShowStatus(!showAll)} type="link" style={{ lineHeight: 1 }}>
					{showAll ? 'See less' : `+ ${remainingCount} more`}
				</Button>
			)}
		</div>
	);
};

export default OneLineComponent;

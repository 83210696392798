import * as React from 'react';
import { Layout, Row, Col, Card, Button, Modal } from 'antd';
import { EditFilled, UploadOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ScrollToTopOnMount from '../scroll_to_top_on_mount/ScrollToTopOnMount';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { siteSurveyTemplatesRestCrudThunksForBuyer } from '../../thunks/site_survey_templates_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { FlatfileButton } from '@flatfile/react';
import { taskListsRestCrudThunksForBuyer } from '../../thunks/task_lists_thunks';
import TaskListRO from '../task_list/TaskListRO';
import { ExportToCsv } from 'export-to-csv';
import UploadTaskListPopup from './UploadTaskListPopup';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';

const { Content } = Layout;

require('./SiteSurveyTemplatesDetailDetailsPage.less');

const getFlatfileSettings = (text): any => {
	return {
		type: 'tasks - Site Survey Template',
		allowCustom: true,
		autoDetectHeaders: true,
		disableManualInput: true,
		displayEncoding: false,
		fields: [
			{
				label: 'Title',
				key: 'title',
			},
			{
				label: 'Description',
				key: 'description',
			},
			{
				label: 'Group',
				key: 'groupTitle',
			},
			{
				label: 'Question Type',
				key: 'questionType',
				type: 'select',
				options: [
					{ label: 'Multiple Choice', value: 'multipleChoice' },
					{ label: 'Free Text', value: 'textbox' },
				],
			},
			{
				label: 'Answer Options',
				key: 'multipleChoicesWithLabel',
				validators: [
					{
						validate: 'required_with_values',
						fieldValues: {
							questionType: 'multipleChoice',
						},
						error: 'Mutliple choice questions should have options',
					},
					{
						validate: 'regex_matches',
						regex: '[a-zA-Z0-9_ :]+(,[a-zA-Z0-9_ :]+)*$',
						error: 'Values should be comma separated. Ex: 1:low,2:high',
					},
				],
			},
		],
		i18nOverrides: {
			en: {
				otherLocales: ['en-US', 'en-CA', 'en-GB'],
				overrides: {
					header: `${text} {{thing}}`,
					header2: `${text} {{thing}}`,
					readySubmit:
						'Are you sure you to submit this task list? Please note this will clear all the existing tasks!',
					success: 'New task list updated successfully!',
				},
			},
		},
	};
};

const csvOptions = {
	fieldSeparator: ',',
	quoteStrings: '"',
	decimalSeparator: '.',
	showLabels: true,
	showTitle: false,
	title: 'Task List',
	useTextFile: false,
	useBom: true,
	headers: ['Title', 'Description', 'Group', 'Question Type', 'Mutliple Choices'],
};

class SiteSurveyTemplatesDetailDetailsPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			initialLoading: true,
			addTaskModalVisible: false,
			uploadTaskModalVisible: false,
		};
	}

	componentDidMount() {
		const {
			getSiteSurveyTemplate,

			match,
		} = this.props;
		const siteSurveyTemplateId = match.params.id;
		getSiteSurveyTemplate(siteSurveyTemplateId).finally(() => {
			this.setState({ initialLoading: false });
		});
	}

	onUploadTaskList = () => {
		this.setState({ uploadTaskModalVisible: true });
	};

	onUploadTaskModalClose = () => {
		this.setState({ uploadTaskModalVisible: false });
	};

	render() {
		const {
			siteSurveyTemplate,
			currentUser,
			getSiteSurveyTemplate,
			updateTaskList,

			match,
		} = this.props;
		const siteSurveyTemplateId = match.params.id;
		const getSourceDataForSiteSurveyTemplate = (sst) => {
			const tasks = nullSafeGetOrElse('taskList.tasks', sst, []);
			const formattedTasks = tasks.map((task) => ({
				data: {
					...task,
					multipleChoicesWithLabel: task.multipleChoicesWithLabel
						? task.multipleChoicesWithLabel
								.map(
									(choice) => `${choice.value}${choice.description ? `:${choice.description}` : ''}`
								)
								.join(',')
						: undefined,
				},
			}));
			return formattedTasks;
		};

		const onDownloadTaskList = () => {
			const tasks = nullSafeGetOrElse('taskList.tasks', siteSurveyTemplate, []);
			const formattedTasks = tasks.map((task) => ({
				title: task.title,
				description: task.description || '',
				group: task.groupTitle || '',
				questionType: task.questionType,
				multipleChoices: task.multipleChoicesWithLabel
					? task.multipleChoicesWithLabel
							.map(
								(choice) => `${choice.value}${choice.description ? `:${choice.description}` : ''}`
							)
							.join(',')
					: '',
			}));
			const csvExporter = new ExportToCsv({ ...csvOptions, filename: siteSurveyTemplate.title });
			csvExporter.generateCsv(formattedTasks);
		};

		const saveNewTaskList = (taskList) => {
			const newTaskList = { ...siteSurveyTemplate.taskList, tasks: taskList };
			updateTaskList(newTaskList)
				.then(() => getSiteSurveyTemplate(siteSurveyTemplateId))
				.then(() => {
					return 'Done';
				});
		};

		return this.state.initalLoading ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="siteSurveyTemplatesDetailDetailsPage">
				<LogOnMountWithStandardEventProperties eventType="visited buyer site survey template detail page" />
				<ScrollToTopOnMount />
				<Row gutter={16}>
					{siteSurveyTemplate.description ? (
						<Col span={24} className="rowSpacing">
							<Card>
								<div className="siteSurveyTemplatesDetailDetailsPage__descSection">
									<div className="siteSurveyTemplatesDetailDetailsPage__descSection">
										{siteSurveyTemplate.description}
									</div>
								</div>
							</Card>
						</Col>
					) : null}
					<Col span={24} className="rowSpacing">
						<Card
							title="Tasks"
							extra={
								<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_SITE_SURVEY_TEMPLATES}>
									<div>
										<FlatfileButton
											settings={getFlatfileSettings('Update')}
											// @ts-ignore
											source={getSourceDataForSiteSurveyTemplate(siteSurveyTemplate)}
											licenseKey={'9cfd8b67-b664-4b19-84cf-b5fb00d8fb5c'}
											customer={{
												companyId: nullSafeGet('facility.buyerCompanyId', currentUser),
												companyName: nullSafeGet('facility.name', currentUser),
												//                                             email: currentUser.email,
												//                                             name: `${currentUser.nameGiven} ${currentUser.nameFamily}`,
												userId: currentUser.email,
											}}
											onData={async (results) => {
												// do something with the results
												const newTasks = results.validData.map((task) => ({
													...task,
													multipleChoicesWithLabel: task.multipleChoicesWithLabel
														? task.multipleChoicesWithLabel.split(',').map((choice) => {
																const parts = choice.split(':');
																return {
																	value: parts[0],
																	...(parts.length > 1 && { description: parts[1] }),
																};
														  })
														: undefined,
												}));
												saveNewTaskList(newTasks);
											}}
											onCancel={() => {
												console.log('cancel');
											}}
											render={(importer, launch) => {
												return (
													<Button onClick={launch}>
														<EditFilled translate="" /> Edit Task List
													</Button>
												);
											}}
										/>
										<Button
											type="primary"
											style={{ marginLeft: '24px' }}
											onClick={this.onUploadTaskList}
										>
											<UploadOutlined translate="" />
											Replace Task List
										</Button>
									</div>
								</AccessPermissionChecker>
							}
						>
							<TaskListRO tasks={nullSafeGetOrElse('taskList.tasks', siteSurveyTemplate, [])} />
							{/* <TaskList tasks={nullSafeGetOrElse('taskList.tasks', siteSurveyTemplate, [])}/> */}
						</Card>
					</Col>
				</Row>
				<Modal
					title="Replace Task List"
					visible={this.state.uploadTaskModalVisible}
					footer={null}
					onCancel={this.onUploadTaskModalClose}
				>
					<UploadTaskListPopup
						isTemplate={true}
						onDownloadTaskList={onDownloadTaskList}
						onSaveTaskList={saveNewTaskList}
						onCancel={this.onUploadTaskModalClose}
					/>
				</Modal>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	onSuccess: ownProps.onSuccess,
	siteSurveyTemplatesFetching: state.site_survey_templates.fetching,
	siteSurveyTemplate: state.site_survey_templates.detail,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
	isLoading: state.site_survey_templates.updating || state.site_survey_templates.fetching,
});

const mapDispatchToProps = (dispatch) => ({
	getSiteSurveyTemplate: (id) => dispatch(siteSurveyTemplatesRestCrudThunksForBuyer.readOne(id)),
	updateTaskList: (entity) => dispatch(taskListsRestCrudThunksForBuyer.update(entity)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(SiteSurveyTemplatesDetailDetailsPage)
);

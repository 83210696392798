import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Row } from 'antd';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { KeyValueDisplay } from '../key_value_display/KeyValueDisplay';
import MultiPhoneNumberComponent from '../MultiPhoneNumberComponent';
import {
	buyerContactsRestCrudThunksForBuyer,
	buyerContactsRestCrudThunksForSupplier,
} from '../../thunks/buyer_contacts_thunks';
import { connect } from 'react-redux';
import MultiEmailComponentAntdV4 from '../MultiEmailComponent/MultiEmailComponentAntdV4';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { withRouter } from 'react-router';
import NewContactForm from '../new_contact_form/NewContactForm';
import { vendorContactsRestCrudThunksForSupplier } from '../../thunks/vendor_contacts_thunks';
import UserListComponent from '../user_list_component/UserListComponent';
import moment from 'moment';
import { timeUntilOrSince } from '../../utils/DataFormatterUtils';
import { UserName } from '../name_component/user/UserNameComponent';
import { UserNameList } from '../name_component/user/UserNameList';

const LOCATION_FORM_BUYER_CONTACTS_AUTOCOMPLETE = 'LOCATION_FORM_BUYER_CONTACTS_AUTOCOMPLETE';

function BuyerVendorInformationForm(props) {
	const [editable, setEditable] = useState(false);
	const [newContactVisible, setNewContactVisible] = useState(false);
	const { createContact, vendorId } = props;

	const [form] = Form.useForm();

	const handleCancel = () => {
		form.resetFields();
		setEditable(false);
	};
	const { data, buyerContacts } = props;

	useEffect(() => {
		if (editable) {
			form.setFieldsValue(data);
		}
	}, [form, data, editable]);

	const [accountManagers, setAccountManagers] = useState(
		nullSafeGetOrElse('accountManagerContacts', data, [])
	);
	const [salesReps, setSalesReps] = useState(nullSafeGetOrElse('salesRepContacts', data, []));
	const [accountsPayable, setAccountsPayable] = useState(
		nullSafeGetOrElse('accountsPayableContacts', data, [])
	);
	const [contactType, setContactType] = useState(nullSafeGetOrElse('contactType', data, []));

	const detailsMap = {
		'Ordering Emails': (
			<>{nullSafeGet('orderingEmails', data) ? data.orderingEmails.join(', ') : '--'}</>
		),
		'Account Managers':
			nullSafeGetOrElse('accountManagerContacts', data, []).length > 0 ? (
				<UserNameList
					contacts={nullSafeGetOrElse('accountManagerContacts', data, [])}
					listMode={'inline'}
					nameFormat={'name'}
				/>
			) : (
				'--'
			),
		'Sale Representatives':
			nullSafeGetOrElse('salesRepContacts', data, []).length > 0 ? (
				<UserNameList
					contacts={nullSafeGetOrElse('salesRepContacts', data, [])}
					listMode={'inline'}
					nameFormat={'name'}
				/>
			) : (
				'--'
			),
		'Accounts Payable':
			nullSafeGetOrElse('accountsPayableContacts', data, []).length > 0 ? (
				<UserNameList
					contacts={nullSafeGetOrElse('accountsPayableContacts', data, [])}
					listMode={'inline'}
					nameFormat={'name'}
				/>
			) : (
				'--'
			),
	};
	const handleCreateContact = (values) => {
		const contact = { ...values, vendorId: parseInt(vendorId) };
		createContact(contact).then((result) => {
			switch (contactType) {
				case 'accountManagerContacts':
					setAccountManagers([...accountManagers, result]);
					break;
				case 'salesRepContacts':
					setSalesReps([...salesReps, result]);
					break;
				case 'accountsPayableContacts':
					setAccountsPayable([...accountsPayable, result]);
					break;
			}
		});
		setNewContactVisible(false);
	};
	const handleSubmit = (values) => {
		const supplierVendorRelationship = {
			...values,
			salesRepEmails: salesReps.map((user) => user.email) || [],
			accountManagerEmails: accountManagers.map((user) => user.email) || [],
			accountsPayableEmails: accountsPayable.map((user) => user.email) || [],
		};
		props.onSubmit(supplierVendorRelationship);
		form.resetFields();
		setEditable(false);
	};

	return (
		<>
			<Row justify="space-between">
				<h5 style={{ display: 'flex', alignItems: 'center' }}>Vendor Contact Details</h5>
				<Button onClick={() => setEditable(!editable)}>{editable ? 'Cancel' : 'Edit'}</Button>{' '}
			</Row>
			<KeyValueDisplay keyWidth={120} keyValueStore={detailsMap} />
			<NewContactForm
				visible={newContactVisible}
				onDismiss={() => setNewContactVisible(false)}
				onSubmit={handleCreateContact}
			/>
			<Modal
				width={500}
				okText="Update Vendor"
				onCancel={handleCancel}
				onOk={form.submit}
				closable={false}
				visible={editable}
				okButtonProps={{ loading: !editable }}
			>
				<Form
					form={form}
					initialValues={data}
					onFinish={handleSubmit}
					layout="vertical"
					labelAlign="left"
					preserve={false}
				>
					<h5>Update {nullSafeGet('supplierFacility.displayName', data)} Contact Details</h5>
					<MultiEmailComponentAntdV4
						fieldProps={{
							preserve: false,
							tooltip: 'You can enter multiple emails seperated by comma',
							label: 'Ordering Emails',
							name: 'orderingEmails',
						}}
					/>
					<Form.Item label={'Account Managers'} name={'accountManagerContacts'}>
						<UserNameList
							contacts={accountManagers}
							listMode={'inline'}
							nameFormat={'name'}
							allowRemove={true}
							onRemove={(contact) => {
								setAccountManagers(accountManagers.filter((user) => contact.email !== user.email));
							}}
						/>
						<Button
							onClick={() => {
								setNewContactVisible(true);
								setContactType('accountManagerContacts');
							}}
						>
							Add Contact
						</Button>
					</Form.Item>
					<Form.Item label={'Sales Representatives'} name={'salesRepContacts'}>
						<UserNameList
							contacts={salesReps}
							listMode={'inline'}
							nameFormat={'name'}
							allowRemove={true}
							onRemove={(contact) => {
								setSalesReps(salesReps.filter((user) => contact.email !== user.email));
							}}
						/>
						<Button
							onClick={() => {
								setNewContactVisible(true);
								setContactType('salesRepContacts');
							}}
						>
							Add Contact
						</Button>
					</Form.Item>
					<Form.Item label={'Accounts Payable Contacts'} name={'accountsPayableContacts'}>
						<UserNameList
							contacts={accountsPayable}
							listMode={'inline'}
							nameFormat={'name'}
							allowRemove={true}
							onRemove={(contact) => {
								setAccountsPayable(accountsPayable.filter((user) => contact.email !== user.email));
							}}
						/>
						<Button
							onClick={() => {
								setNewContactVisible(true);
								setContactType('accountsPayableContacts');
							}}
						>
							Add Contact
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
}
const mapStateToProps = (state, ownProps) => ({
	userType: (state as any).session.userType,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	createContact: (entity) => dispatch(vendorContactsRestCrudThunksForSupplier.create(entity)),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(BuyerVendorInformationForm)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import React, { FC } from 'react';
import { BLACKS_AND_WHITES, STOPLIGHT_COLORS } from '../../utils/DataFormatterUtils';

const QuoteDisplayStatusDisplay: FC<any> = ({
	status,
	darkMode = false,
	style = {},
	textStyle = {},
	...props
}) => {
	let statusColor;
	let statusDisplayName;
	let statusBackgroundColor;

	switch (status) {
		case 'Pending':
			statusColor = 'rgb(137, 107, 12)';
			statusDisplayName = 'Pending';
			statusBackgroundColor = '#fcf3d9';
			break;
		case 'Declined':
			statusColor = STOPLIGHT_COLORS.pureRed;
			statusDisplayName = 'Declined';
			statusBackgroundColor = '#f9e6e6';
			break;
		case 'Expired':
			statusColor = BLACKS_AND_WHITES.blackPrimary;
			statusDisplayName = 'Expired';
			statusBackgroundColor = '#f2f2f2';
			break;
		case 'Approved':
			statusColor = STOPLIGHT_COLORS.pureGreen;
			statusDisplayName = 'Approved';
			statusBackgroundColor = '#e6f9e7';
			break;
		default:
			statusColor = BLACKS_AND_WHITES.blackPrimary;
			statusDisplayName = 'Unknown';
			statusBackgroundColor = '#f2f2f2';
	}

	if (darkMode) {
		statusColor = BLACKS_AND_WHITES.whiteSecondary;
	}

	return (
		<span
			{...props}
			style={{
				color: statusColor,
				background: statusBackgroundColor,
				padding: '4px 12px',
				borderRadius: 16,
				...style,
				...textStyle,
			}}
		>
			{statusDisplayName}
		</span>
	);
};

export default QuoteDisplayStatusDisplay;

import * as React from 'react';

import { CloseOutlined, PlusOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Input, Alert, Button, DatePicker } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { supplierFacilitiesRestCrudThunksForSupplier } from '../../thunks/supplier_facilities_thunks';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import moment from 'moment';
import FileUploader from '../file_uploader/FileUploader';

const style = require('./EditSupplierCertificationsForm.less');
const FormItem = Form.Item;

interface EditSupplierCertificationFormProps extends FormComponentProps {
	loading: boolean;
	certifications: any[];
	updateSupplier: any;
	successCallback: any;
	cancelCallback: any;
	supplierFacility: any;

	errors: string[];
}

const DATE_FORMAT = 'MM-DD-YYYY';
let certificationUuid = 1;

class EditSupplierCertificationForm extends React.Component<
	EditSupplierCertificationFormProps,
	any
> {
	handleSubmit = (e) => {
		const { updateSupplier, successCallback, supplierFacility, form } = this.props;
		e.preventDefault();
		form.validateFields((err, values) => {
			if (!err) {
				const certificationKeys = form.getFieldValue('certificationKeys');
				const certifications = certificationKeys
					.map((k) => ({
						certificationType: form.getFieldValue(`certifications-${k}-certificationType`),
						number: form.getFieldValue(`certifications-${k}-number`),
						issuer: form.getFieldValue(`certifications-${k}-issuer`),
						qualifier: form.getFieldValue(`certifications-${k}-qualifier`),
						expiration: form.getFieldValue(`certifications-${k}-expiration`),
						certificationFile: form.getFieldValue(`certifications-${k}-attachment`),
						isVerified:
							form.getFieldValue(`certifications-${k}-isVerified`) === undefined
								? false
								: form.getFieldValue(`certifications-${k}-isVerified`),
					}))
					.filter((el) => el.certificationType !== undefined);
				const updatedSupplier = {
					...supplierFacility,
					certifications,
				};
				updateSupplier(updatedSupplier).then((resp) => successCallback(resp));
			}
		});
	};

	removeCertification = (k) => {
		const { form } = this.props;
		// can use data-binding to get
		const certificationKeys = form.getFieldValue('certificationKeys');
		// We need at least one question
		if (certificationKeys.length === 1) {
			return;
		}

		// can use data-binding to set
		form.setFieldsValue({
			certificationKeys: certificationKeys.filter((key) => key !== k),
		});
	};

	handleAttachmentUploadChange = (fieldName) => (newAttachments) => {
		const { form } = this.props;
		if (newAttachments.length > 0) {
			const attachment = newAttachments[0];
			form.setFieldsValue({ [fieldName]: attachment });
		} else {
			form.setFieldsValue({ [fieldName]: undefined });
		}
	};

	addCertification = () => {
		const { form } = this.props;
		// can use data-binding to get
		const certificationKeys = form.getFieldValue('certificationKeys');
		const nextKeys = certificationKeys.concat(certificationUuid);
		// can use data-binding to set
		// important! notify form to detect changes
		form.setFieldsValue({
			certificationKeys: nextKeys,
		});
		certificationUuid++;
	};

	render() {
		const { getFieldDecorator, getFieldValue } = this.props.form;
		const { loading, errors, cancelCallback, certifications } = this.props;

		const certificationKeysInitialValue =
			certifications.length > 0 ? certifications.map((el, idx) => idx) : [0];
		getFieldDecorator('certificationKeys', { initialValue: certificationKeysInitialValue });

		const certificationKeys = getFieldValue('certificationKeys');

		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
				sm: { span: 4 },
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 20 },
			},
		};
		const formItemLayoutWithOutLabel = {
			wrapperCol: {
				xs: { span: 24, offset: 0 },
				sm: { span: 20, offset: 4 },
			},
		};

		const expirationDateValidator = (rule, value, callback) => {
			const now = moment();
			if (value && value < now) {
				callback('Cannot upload expired certifications.');
			}
			callback();
		};

		const formItems = certificationKeys.map((k, certificationIndex) => {
			return (
				<div key={k} className="certifications-container">
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div style={{ fontSize: '16px', color: 'black', marginBottom: '24px' }}>
							Certification {certificationIndex + 1}
						</div>

						<div className="remove-certifications-button">
							{certificationKeys.length > 1 ? (
								<CloseOutlined
									className="dynamic-delete-button"
									onClick={() => this.removeCertification(k)}
								/>
							) : null}
						</div>
					</div>
					<Form.Item {...formItemLayout} label="Type" required={false}>
						{getFieldDecorator(`certifications-${k}-certificationType`, {
							validateTrigger: ['onChange', 'onBlur'],
							initialValue: nullSafeGet(`${k}.certificationType`, certifications),
							rules: [
								{
									required: true,
									message: 'Please select a certification type.',
								},
							],
						})(<Input style={{ width: '90%' }} placeholder="" />)}
					</Form.Item>
					<Form.Item {...formItemLayout} label="Number" required={false}>
						{getFieldDecorator(`certifications-${k}-number`, {
							validateTrigger: ['onChange', 'onBlur'],
							initialValue: nullSafeGet(`${k}.number`, certifications),
							rules: [
								{
									required: true,
									whitespace: true,
									message: 'Please provide your certification number.',
								},
							],
						})(<Input style={{ width: '90%' }} />)}
					</Form.Item>
					<Form.Item {...formItemLayout} label="Issuer" required={false}>
						{getFieldDecorator(`certifications-${k}-issuer`, {
							validateTrigger: ['onChange', 'onBlur'],
							initialValue: nullSafeGet(`${k}.issuer`, certifications),
							rules: [
								{
									required: true,
									whitespace: true,
									message:
										'Please provide the name of the state, city, government body or other organization that issued your certification.',
								},
							],
						})(<Input style={{ width: '90%' }} />)}
					</Form.Item>
					<Form.Item {...formItemLayout} label="Qualifier" required={false}>
						{getFieldDecorator(`certifications-${k}-qualifier`, {
							validateTrigger: ['onChange', 'onBlur'],
							initialValue: nullSafeGet(`${k}.qualifier`, certifications),
						})(<Input style={{ width: '90%' }} />)}
					</Form.Item>
					<Form.Item {...formItemLayout} label="Expires" required={false}>
						{getFieldDecorator(`certifications-${k}-expiration`, {
							rules: [
								{
									type: 'object',
									required: true,
									message: "Please select your certification's expiration date!",
								},
								{ validator: expirationDateValidator },
							],
							initialValue: nullSafeGet(`${k}.expiration`, certifications)
								? moment(nullSafeGet(`${k}.expiration`, certifications))
								: undefined,
						})(<DatePicker format={DATE_FORMAT} />)}
					</Form.Item>
					<Form.Item {...formItemLayout} label="Attachment">
						{getFieldDecorator(
							`certifications-${k}-attachment`,
							{}
						)(
							<FileUploader
								roleType="supplier"
								handleUploadSuccess={this.handleAttachmentUploadChange(
									`certifications-${k}-attachment`
								)}
								showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
								defaultFileList={
									nullSafeGet(`${k}.certificationFile`, certifications) &&
									nullSafeGet(`${k}.certificationFile.fileName`, certifications)
										? [nullSafeGet(`${k}.certificationFile`, certifications)]
										: []
								}
								multiple={false}
							/>
						)}
					</Form.Item>
				</div>
			);
		});
		return (
			<Form
				layout="horizontal"
				onSubmit={this.handleSubmit}
				className="editSupplierCertificationForm"
			>
				{errors && errors.length > 0
					? errors.map((msg, idx) => (
							<FormItem key={idx}>
								<Alert message={msg} type="error" />
							</FormItem>
					  ))
					: null}
				{formItems}
				<Form.Item label="">
					<Button
						type="dashed"
						onClick={this.addCertification}
						style={{ width: '100%', height: 217 }}
					>
						<PlusOutlined /> Add Certification
					</Button>
				</Form.Item>
				<FormItem>
					<Button type="primary" htmlType="submit" loading={loading}>
						Update Certification
					</Button>
					<Button style={{ marginLeft: 16 }} onClick={cancelCallback}>
						Cancel
					</Button>
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	loading: state.supplier_facilities.loading,
	errors: state.supplier_facilities.updateErrors,
	certifications: ownProps.certifications,
	successCallback: ownProps.successCallback,
	cancelCallback: ownProps.cancelCallback,
	supplierFacility: ownProps.supplierFacility,
});

const mapDispatchToProps = (dispatch) => ({
	updateSupplier: (supplierFacility) =>
		dispatch(supplierFacilitiesRestCrudThunksForSupplier.update(supplierFacility)),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<EditSupplierCertificationFormProps>()(EditSupplierCertificationForm))
);

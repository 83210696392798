import React, { FC, useCallback, useMemo, useState } from 'react';
import { Button, message, Modal } from 'antd';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	assetsRestCrudThunksForSupplier,
	createAssetFromEquipmentPerStockLocationBulkForSupplier,
} from '../../thunks/assets_thunks';
import BulkTransferFromStockLocationForm from './BulkTransferFromStockLocationForm';
import SameAssetNumberResolveModal from '../asset_form/SameAssetNumberResolveModal';
import { BE_DATE_ONLY_FORMAT } from '../../utils/DataFormatterUtils';

const FORM_ID = 'transfer_equipments_form';

const BulkTransferFromStockLocationModal: FC<any> = ({
	saving,
	onCancel,
	stockLocationId,
	isPartsTransfer,
	createAssetFromEquipmentBulk,
	selectedEpsls,
	onSuccess,
	isTransfer,
	onTransferToSL,
	parentLoading,
	fetchAssets,
	locationType,
}): React.ReactElement => {
	const [loading, setLoading] = useState(false);
	const [conflictingAssetModalVisible, setConflictingAssetModalVisible] = useState(false);
	const [conflictingAsset, setConflictingAsset] = useState(null);
	const [formValues, setFormValues] = useState({});

	const _showConflictingAssetModal = useCallback(() => setConflictingAssetModalVisible(true), []);
	const _hideConflictingAssetModal = useCallback(() => setConflictingAssetModalVisible(false), []);

	const onTransferToLocation = useCallback(
		(values) => {
			const locationId = values.locationId;
			const entities = selectedEpsls.map((epsl) => ({
				equipmentPerStockLocationId: epsl.id,
				locationId,
				...(values.assetModelId && { assetModelId: values.assetModelId }),
				...(values.assetTypeId && { assetTypeId: values.assetTypeId }),
				...(values.assetNumber && { assetNumber: values.assetNumber }),
				...(values.transferDate && {
					transferDate: values.transferDate.format(BE_DATE_ONLY_FORMAT),
				}),
				...(values.transferNote && {
					transferNote: values.transferNote,
				}),
			}));
			setLoading(true);
			createAssetFromEquipmentBulk(entities)
				.then((res) => onSuccess(res))
				.catch((err) => message.error(err))
				.finally(() => setLoading(false));
		},
		[createAssetFromEquipmentBulk, onSuccess, selectedEpsls]
	);

	const onTransferToLocationSubmit = useCallback(
		(values) => {
			const { assetNumber, locationId } = values;
			if (!!assetNumber && !!locationId) {
				setLoading(true);
				fetchAssets({
					assetNumber,
					locationId,
				})
					.then((res) => {
						if (res && res.length > 0) {
							setConflictingAsset(res[0]);
							setFormValues(values);
							_showConflictingAssetModal();
						} else {
							onTransferToLocation(values);
						}
					})
					.catch(() => onTransferToLocation(values))
					.finally(() => setLoading(false));
			} else {
				onTransferToLocation(values);
			}
		},
		[_showConflictingAssetModal, fetchAssets, onTransferToLocation]
	);

	const onConflictResolveSuccess = useCallback(() => {
		_hideConflictingAssetModal();
		onTransferToLocation(formValues);
	}, [_hideConflictingAssetModal, formValues, onTransferToLocation]);

	const isSingleEpsl = useMemo(() => (selectedEpsls || []).length === 1, [selectedEpsls]);

	const isLocationEnabled = useMemo(
		() =>
			isSingleEpsl ||
			(selectedEpsls || []).every((_) => !!nullSafeGet('equipmentType.assetTypeId', _)),
		[isSingleEpsl, selectedEpsls]
	);

	const entityName = useMemo(() => (isPartsTransfer ? 'Parts' : 'Equipment'), [isPartsTransfer]);

	const title = useMemo(
		() => `Transfer ${isTransfer ? 'to Location' : entityName}`,
		[entityName, isTransfer]
	);

	return (
		<>
			<Modal
				visible={true}
				width={600}
				title={title}
				closable
				onCancel={onCancel}
				forceRender
				footer={[
					<Button onClick={onCancel} size="large">
						Cancel
					</Button>,
					<Button
						type="primary"
						size="large"
						style={{ marginLeft: '16px' }}
						key="submit"
						htmlType="submit"
						form={FORM_ID}
						loading={saving || loading || parentLoading}
					>
						{title}
					</Button>,
				]}
			>
				<BulkTransferFromStockLocationForm
					FORM_ID={FORM_ID}
					onTransferToSL={onTransferToSL}
					onTransferToLocation={onTransferToLocationSubmit}
					isPartsTransfer={isPartsTransfer}
					isTransfer={isTransfer}
					stockLocationId={stockLocationId}
					isLocationEnabled={isLocationEnabled}
					isSingleEpsl={isSingleEpsl}
					assetTypeId={nullSafeGet('0.equipmentType.assetTypeId', selectedEpsls)}
					assetNumber={nullSafeGet('0.assetNumber', selectedEpsls)}
					locationType={locationType}
					equipmentTypeModelName={nullSafeGet('0.equipmentType.modelName', selectedEpsls)}
				/>
			</Modal>
			{conflictingAssetModalVisible ? (
				<SameAssetNumberResolveModal
					conflictingAsset={conflictingAsset}
					onCancel={_hideConflictingAssetModal}
					onSuccess={onConflictResolveSuccess}
				/>
			) : null}
		</>
	);
};

const mapStateToProps = (state) => ({
	saving: state.parts_per_stock_locations.updating || state.equipment_per_stock_locations.updating,
});

const mapDispatchToProps = (dispatch) => ({
	createAssetFromEquipmentBulk: (entities) =>
		dispatch(createAssetFromEquipmentPerStockLocationBulkForSupplier(entities)),
	fetchAssets: (params) => dispatch(assetsRestCrudThunksForSupplier.read(params)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(BulkTransferFromStockLocationModal)
);

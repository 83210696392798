import React, { FC, useMemo } from 'react';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { Tag } from 'antd';
import HyperLink, { ENTITY_TYPE } from '../common/HyperLink';

const LocationStockLocationNameWithType: FC<any> = ({
	record,
	backLinkText,
}): React.ReactElement => {
	const tag = useMemo(
		() =>
			record.location ? (
				<Tag color="#e8def8" style={{ color: '#4a1e87' }}>
					L
				</Tag>
			) : (
				<Tag color="rgb(225, 229, 249)" style={{ color: 'rgb(11, 28, 111)' }}>
					SL
				</Tag>
			),
		[record.location]
	);

	const name = useMemo(
		() =>
			record.location ? (
				<HyperLink
					text={nullSafeGetOrElse('location.name', record, '--')}
					entityType={ENTITY_TYPE.LOCATION}
					entityId={nullSafeGetOrElse('location.id', record, '--')}
					backLinkText={backLinkText}
				/>
			) : (
				<HyperLink
					text={nullSafeGetOrElse('stockLocation.name', record, '--')}
					entityType={ENTITY_TYPE.STOCK_LOCATION}
					entityId={nullSafeGetOrElse('stockLocation.id', record, '--')}
					backLinkText={backLinkText}
				/>
			),
		[backLinkText, record]
	);

	return (
		<div className="flex flex-row items-center">
			{tag}
			{name}
		</div>
	);
};

export default LocationStockLocationNameWithType;

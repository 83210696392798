import * as React from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Button, Alert, Progress, Radio, DatePicker, Checkbox, message } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { connect } from 'react-redux';
import { debounce } from '../../utils/PerformanceUtils';
import { LocationAutocomplete } from '../location_autocomplete/LocationAutocomplete';
import Upload from 'antd/es/upload/Upload';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import moment from 'moment';
import { imageUploadValidation } from '../../utils/ImageUtils';

const FormItem = Form.Item;

interface LeadFormProps extends FormComponentProps {
	onSubmit: any;
	onCancel: any;
	visible: boolean;
	createErrors: any;
	updateErrors: any;
	creating: boolean;
	questions: any[];
	updating: boolean;
	currentUser: any;
}

class LeadForm extends React.Component<LeadFormProps, any> {
	constructor(props) {
		super(props);
		this.state = {
			activeStep: 1,
			otherValues: {},
			previewVisible: false,
			previewImage: false,
			willUploadPicture: false,
			uploadLoading: false,
			pictureURL: null,
			willChooseDates: false,
			chosenDates: [],
		};
		this.goNext = this.goNext.bind(this);
		this.goPrev = this.goPrev.bind(this);
	}

	goNext(validationSteps) {
		const { form, questions, onSubmit } = this.props;
		const { activeStep } = this.state;
		form.validateFields(validationSteps, (err, values) => {
			if (err) {
				return;
			}
			if (activeStep > questions.length) {
				onSubmit();
			} else {
				this.setState({ activeStep: activeStep + 1 });
			}
		});
	}

	goPrev() {
		const { activeStep } = this.state;
		if (activeStep === 1) {
			return false;
		}
		this.setState({ activeStep: activeStep - 1 });
		return true;
	}

	handleOtherValueChangeDebounced = (otherValueKey) =>
		debounce(
			(otherValue) => {
				let newOtherValues = this.state.otherValues;
				newOtherValues[`${otherValueKey}`] = otherValue;
				this.setState({ otherValues: newOtherValues });
			},
			500,
			false
		);

	handleOtherValueChange = (otherValueKey) => (e) => {
		e.stopPropagation();
		const inputString = e.target.value;
		this.handleOtherValueChangeDebounced(otherValueKey)(inputString);
	};

	handleUploadPicture = (value) => {
		value.target.value === 'yes'
			? this.setState({
					willUploadPicture: true,
			  })
			: this.setState({
					willUploadPicture: false,
			  });
	};

	handleChoosingDates = (value) => {
		value.target.value === 'Choose date(s)'
			? this.setState({
					willChooseDates: true,
			  })
			: this.setState({
					willChooseDates: false,
			  });
	};

	handleDateRangeChange = (momentDates, formattedDates) => {
		const { form } = this.props;
		this.setState({
			chosenDates: formattedDates,
		});
		form.setFieldsValue({ 'dates-available': momentDates });
	};

	handleCancel = () => this.setState({ previewVisible: false });

	handlePreview = (file) => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true,
		});
	};

	render() {
		const BACKEND_URI = getBackendUri();
		const {
			visible,
			questions,
			onCancel,
			onSubmit,
			form,
			currentUser,
			createErrors,
			updateErrors,
		} = this.props;
		const { activeStep, pictureURL, previewImage, previewVisible, chosenDates, otherValues } =
			this.state;
		const { getFieldDecorator } = form;

		const activeQuestionIndex = questions.findIndex((q) => q.id === activeStep);
		const progressPercentage =
			activeQuestionIndex >= 0
				? Math.round(((activeQuestionIndex + 1) / (questions.length + 1)) * 100)
				: 100;
		const dateFormat = 'MM-DD-YYYY';
		const labelFontSize = 16;
		const radioStyle = {
			display: 'block',
			height: '40px',
			lineHeight: '40px',
			fontSize: labelFontSize,
			marginLeft: 0,
		};
		const getDisplayMode = (question) => {
			if (activeStep !== question.id) {
				return 'none';
			} else {
				return 'block';
			}
		};

		const getTextAlignment = (question) => {
			if (question.questionType === 'textbox') {
				return 'center';
			} else {
				return undefined;
			}
		};

		const getNextText = (activeStep) => {
			if (activeStep < questions.length) {
				return 'Next';
			} else if (activeStep === questions.length) {
				return 'Submit';
			} else if (activeStep === questions.length + 1) {
				return 'Done';
			}
			return 'Next';
		};

		const disabledDate = (current) => {
			// Can not select days before today and today
			return current && current < moment().endOf('day');
		};

		const getQuestionItem = (question, idx) => {
			const normFile = (e) => {
				if (Array.isArray(e)) {
					return e;
				}
				return e && e.fileList;
			};

			const handleLocationAutocompleteSelect = (addr, lonLat, parsedAddress) => {
				form.setFieldsValue({
					[`answer-${question.id}`]: {
						longitude: lonLat.lng,
						latitude: lonLat.lat,
						parsedAddress: parsedAddress,
					},
				});
			};

			let inputField = <div></div>;
			let pictureField = <div></div>;
			let datePicker = <div></div>;
			if (question.questionType === 'yesNo') {
				inputField = (
					<Radio.Group>
						<Radio style={radioStyle} value="yes">
							Yes
						</Radio>
						<Radio style={radioStyle} value="no">
							No
						</Radio>
					</Radio.Group>
				);
			} else if (question.questionType === 'pictureYesNo') {
				inputField = (
					<Radio.Group onChange={this.handleUploadPicture}>
						<Radio style={radioStyle} value="yes">
							Yes
						</Radio>
						<Radio style={radioStyle} value="no">
							No
						</Radio>
					</Radio.Group>
				);
				pictureField = (
					<Upload
						name="file"
						listType="picture-card"
						className="avatar-uploader"
						multiple={true}
						onPreview={this.handlePreview}
						action={`${BACKEND_URI}/api/v1/buyer/image/public/upload`}
						beforeUpload={imageUploadValidation}
					>
						<div style={{ display: 'inline' }}>
							<LegacyIcon type={this.state.uploadLoading ? 'loading' : 'plus'} />
							<div className="ant-upload-text">Upload</div>
						</div>
						<Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
							<img alt="example" style={{ width: '100%' }} src={previewImage} />
						</Modal>
					</Upload>
				);
			} else if (question.questionType === 'multipleChoice') {
				inputField = (
					<Radio.Group onChange={this.handleChoosingDates}>
						{question.multipleChoices.map((choice, idx) => (
							<Radio style={radioStyle} value={choice} key={idx}>
								{choice}
							</Radio>
						))}
					</Radio.Group>
				);
				datePicker = (
					<div style={{ display: this.state.willChooseDates ? 'flex' : 'none' }}>
						<DatePicker.RangePicker
							disabledDate={disabledDate}
							onChange={this.handleDateRangeChange}
							format={dateFormat}
							showTime={false}
						/>
					</div>
				);
			} else if (question.questionType === 'multipleChoiceMultiSelect') {
				inputField = (
					<Checkbox.Group>
						{question.multipleChoices.map((choice, idx) => (
							<Checkbox style={radioStyle} value={choice} key={idx}>
								{choice}
							</Checkbox>
						))}
						{question.hasOther ? (
							<Checkbox style={radioStyle} value="other" key="other">
								<Input
									style={{ width: 380 }}
									placeholder="Anything else?"
									onChange={this.handleOtherValueChange(`${question.id}-otherOption`)}
									onClick={(e) => e.stopPropagation()}
								/>
							</Checkbox>
						) : null}
					</Checkbox.Group>
				);
			} else if (question.questionType === 'dateTime') {
				inputField = <DatePicker format={dateFormat} />;
			} else if (question.questionType === 'textbox') {
				inputField = (
					<Input
						size="large"
						style={{ width: question.width }}
						placeholder={question.placeholder}
					/>
				);
			} else if (question.questionType === 'address') {
				inputField = (
					<LocationAutocomplete
						placeholder="What's the address?"
						onSelect={handleLocationAutocompleteSelect}
					/>
				);
			} else {
				inputField = (
					<Input.TextArea style={{ width: question.width }} placeholder={question.placeholder} />
				);
			}
			return (
				<Form.Item
					key={question.id}
					label=""
					className="leadFormItem"
					style={{
						minHeight: 320,
						maxHeight: 320,
						overflow: 'auto',
						padding: 20,
						width: '100%',
						display: getDisplayMode(question),
						// textAlign: getTextAlignment(question),
					}}
				>
					<span
						style={{
							display: 'block',
							textAlign: 'center',
							fontSize: 24,
						}}
					>
						{question.title}
					</span>
					<span
						style={{
							display: 'block',
							textAlign: 'center',
							color: 'rgba(0,0,0,0.45)',
							marginBottom: 16,
						}}
					>
						{question.description}
					</span>
					{getFieldDecorator(`question-${question.id}`, { initialValue: question.title })}
					{getFieldDecorator(`question-type-${question.id}`, {
						initialValue: question.questionType,
					})}
					{getFieldDecorator(`description-${question.id}`, { initialValue: question.description })}
					{getFieldDecorator(`dates-available`)}
					{getFieldDecorator(`answer-${question.id}`, {
						validateTrigger: ['onChange', 'onBlur'],
						rules: [
							{
								required: question.description === 'Optional' ? false : true,
								message: 'Please answer the question!',
							},
						],
					})(inputField)}
					<div style={{ display: this.state.willUploadPicture ? 'flex' : 'none' }}>
						{getFieldDecorator(`pictureFiles`, {
							valuePropName: 'fileList',
							getValueFromEvent: normFile,
						})(pictureField)}
					</div>
					{datePicker}
					{getFieldDecorator(`dates-available`, { initialValue: chosenDates })}
					{getFieldDecorator(`other-value-${question.id}`, {
						initialValue: otherValues[`${question.id}-otherOption`],
					})}
				</Form.Item>
			);
		};
		return (
			<Modal
				visible={visible}
				width={600}
				title={null}
				onCancel={() => {
					Modal.confirm({
						content: (
							<div style={{ fontSize: 18 }}>
								Don't stop now. You're {progressPercentage}% done with your request.
							</div>
						),
						okText: 'Continue the request',
						cancelText: 'Cancel the request',
						onCancel: () => onCancel(),
					});
				}}
				onOk={onSubmit}
				footer={null}
				closable={false}
			>
				<Form layout="vertical" className="supplierForm">
					<Progress percent={progressPercentage} showInfo={progressPercentage > 50} />
					{createErrors.length > 0 ? (
						<FormItem>
							<Alert message={createErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					{updateErrors.length > 0 ? (
						<FormItem>
							<Alert message={updateErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					{questions.map((question, idx) => getQuestionItem(question, idx))}
					{getFieldDecorator(`questions`, { initialValue: questions.length })}
					<div
						style={{
							display: activeStep === questions.length + 1 ? 'flex' : 'none',
							padding: 24,
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<img
							style={{ marginBottom: 8 }}
							src="https://s3.amazonaws.com/mock-data-assets/categories/images/letter owl.svg"
						/>
						<div style={{ fontSize: 24 }}>Your matches are on the way!</div>
					</div>
					<FormItem style={{ marginBottom: 0, display: 'flex', justifyContent: 'center' }}>
						<Button
							style={{ opacity: activeStep === 1 ? 0 : 1 }}
							size="large"
							onClick={this.goPrev}
						>
							Back
						</Button>
						<Button
							type="primary"
							size="large"
							style={{ marginLeft: 16 }}
							onClick={() => this.goNext([`answer-${activeStep}`])}
						>
							{getNextText(activeStep)}
						</Button>
					</FormItem>
				</Form>
			</Modal>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	createErrors: state.buyer_contacts.createErrors,
	updateErrors: state.buyer_contacts.updateErrors,
	creating: state.buyer_contacts.creating,
	updating: state.buyer_contacts.updating,
	currentUser: state.session.currentUser,
	questions: ownProps.questions,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create<LeadFormProps>()(LeadForm));

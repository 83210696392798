import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fieldTechsRestCrudThunksForSupplier } from '../../thunks/field_techs_thunks';
import FieldTechForm from '../field_tech_form/FieldTechForm';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const style = require('./EditFieldTechPage.less');

class EditFieldTechPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const { getFieldTech, match } = this.props;
		const email = match.params.id;
		getFieldTech(email);
	}

	render() {
		const { history, fieldTech, fetching, match } = this.props;
		const email = match.params.id;
		//noinspection TypeScriptUnresolvedVariable
		return [
			<LogOnMountWithStandardEventProperties eventType="visited supplier edit field tech page" />,
			<EditPage
				title="Edit Field Tech"
				formComponentProps={{
					formData: fieldTech,
					onSuccess: (rec) => history.push(`/supplier/fieldTechs/detail/${email}`),
				}}
				fetching={fetching}
				FormComponent={FieldTechForm}
			/>,
		];
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		history: ownProps.history,
		fieldTech: state.field_techs.detail,
		fetching: state.field_techs.fetching,
		match: ownProps.match,
	};
};

const mapDispatchToProps = (dispatch) => ({
	getFieldTech: (email) => dispatch(fieldTechsRestCrudThunksForSupplier.readOne(email)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(EditFieldTechPage)
);

import {
	getEntitiesByCompositeId,
	getEntitiesById,
	nullSafeGetOrElse,
} from '../utils/DataAccessUtils';

export const DEFAULT_PAGE_SIZE = 10;

export const getRecordsForTargetCollection = (stateSlice, targetCollectionName) => {
	const stateSliceKeys = new Set(Object.keys(stateSlice));
	if (!stateSliceKeys.has(targetCollectionName))
		throw new Error('invalid target collection name: ' + targetCollectionName);
	return getEntitiesById(stateSlice[targetCollectionName].recordsIds, stateSlice.records);
};

export const clearRecordForTargetCollection = (targetCollectionNames) => {
	return standardInitialStateGenerator(targetCollectionNames);
};

export const getRecordsForCompositeTargetCollection = (stateSlice, targetCollectionName) => {
	const stateSliceKeys = new Set(Object.keys(stateSlice));
	if (!stateSliceKeys.has(targetCollectionName))
		throw new Error('invalid target collection name: ' + targetCollectionName);
	return getEntitiesByCompositeId(
		stateSlice[targetCollectionName].recordsIds,
		stateSlice.records,
		stateSlice.idNames
	);
};

export const standardInitialSearchStateGenerator = () => {
	return {
		searching: false,
		searchErrors: [],
		searchResults: [],
		nationalSearchResults: [],
		searchResultsCount: 0,
		searchPagination: {
			current: 1,
			pageSize: DEFAULT_PAGE_SIZE,
		},
		searchFilters: {},
		searchFacets: {},
		searchSorting: {},
	};
};

export const standardInitialStateGenerator = (targetCollectionNames) => {
	const baseInitialState = {
		targetCollectionNames: targetCollectionNames,
		fetching: true,
		fetchErrors: [],
		counting: [],
		countErrors: [],
		counts: {},
		creating: false,
		createErrors: [],
		updating: false,
		updateErrors: [],
		archiving: false,
		archiveErrors: [],
		unarchiving: false,
		unarchiveErrors: [],
		deleting: false,
		deleteErrors: [],
		records: {},
		recordsIds: [],
		selections: [],
		detail: {},
		formData: {},
		pagination: {
			current: 1,
			pageSize: DEFAULT_PAGE_SIZE,
		},
		sorting: {},
		filters: {},
	};

	const completeInitialState = targetCollectionNames.reduce(
		(initialState, targetCollectionName) => {
			initialState[targetCollectionName] = {
				...baseInitialState,
			};
			// for target collections, use ids instead of storing records,
			// and hydrate the entities from base records collection
			delete initialState[targetCollectionName].records;
			initialState[targetCollectionName].recordsIds = [];
			return initialState;
		},
		baseInitialState
	);

	return completeInitialState;
};

export const compositeInitialStateGenerator = (targetCollectionNames, idNames) => {
	const baseInitialState = standardInitialStateGenerator(targetCollectionNames);
	const completeInitialState = {
		...baseInitialState,
		idNames: idNames,
	};
	return completeInitialState;
};

export const compositeReducerGenerator = (
	CRUD_ACTION_CONSTANTS,
	initialState,
	idNames = [],
	customReducerFunction = null
) => {
	const getIdForEntity = (entity) => {
		return idNames
			.reduce((acc, el) => {
				acc.push(entity[el]);
				return acc;
			}, [])
			.join(',');
	};
	return (oldState = initialState, action): any => {
		Object.freeze(oldState);
		let newState;
		let newRecords;
		let newRecordsIds;
		let newRecordId;
		switch (action.type) {
			case CRUD_ACTION_CONSTANTS.fetchStart:
				if (action.targetCollectionName) {
					return {
						...oldState,
						fetching: true,
						[action.targetCollectionName]: {
							...oldState[action.targetCollectionName],
							fetching: true,
						},
					};
				} else {
					return {
						...oldState,
						fetching: true,
					};
				}
			case CRUD_ACTION_CONSTANTS.fetchError:
				if (action.targetCollectionName) {
					return {
						...oldState,
						fetching: false,
						fetchErrors: [...oldState.fetchErrors, action.error],
						[action.targetCollectionName]: {
							...oldState[action.targetCollectionName],
							fetching: false,
							fetchErrors: [
								...((oldState[action.targetCollectionName] &&
									oldState[action.targetCollectionName].fetchErrors) ||
									[]),
								action.error,
							],
						},
					};
				} else {
					return {
						...oldState,
						fetching: false,
						fetchErrors: [...oldState.fetchErrors, action.error],
					};
				}
			case CRUD_ACTION_CONSTANTS.fetchSuccess:
				// new records formatted as object for quick retrieval
				newRecords = action.records.reduce((acc, el) => {
					acc[getIdForEntity(el)] = el;
					return acc;
				}, {});
				newRecordsIds = action.records.map((el) => getIdForEntity(el));

				// add new records to base collection
				newState = {
					...oldState,
					fetching: false,
					fetchErrors: [],
					count: action.count,
					records: { ...oldState.records, ...newRecords },
				};

				// if update targeting child collection, replace records
				if (action.targetCollectionName) {
					newState[action.targetCollectionName] = {
						recordsIds: action.addToTargetCollection
							? [
									...nullSafeGetOrElse(`${action.targetCollectionName}.recordsIds`, newState, []),
									...newRecordsIds,
							  ]
							: newRecordsIds,
						sorting: oldState[action.targetCollectionName].sorting,
						filters: oldState[action.targetCollectionName].filters,
						pagination: oldState[action.targetCollectionName].pagination,
						count: action.count,
						fetching: false,
						fetchErrors: [],
					};
					// store pagination, sorting, and filters state of table if applicable
					if (action.pagination)
						newState[action.targetCollectionName].pagination = action.pagination;
					if (action.sorting) newState[action.targetCollectionName].sorting = action.sorting;
					if (action.filters) newState[action.targetCollectionName].filters = action.filters;
				} else {
					newState.recordsIds = newRecordsIds;
					if (action.pagination) newState.pagination = action.pagination;
					if (action.sorting) newState.sorting = action.sorting;
					if (action.filters) newState.filters = action.filters;
				}
				return newState;
			case CRUD_ACTION_CONSTANTS.fetchOneSuccess:
				// TODO: CONSIDER ADDING SUPPORT FOR TARGET COLLECTION NAME
				// TODO: SEPARATE DETAIL LOADING
				newRecords = {
					[getIdForEntity(action.record)]: action.record,
				};
				// add new records to base collection
				newState = {
					...oldState,
					fetching: false,
					fetchErrors: [],
					records: { ...oldState.records, ...newRecords },
					detail: action.record,
				};
				return newState;
			case CRUD_ACTION_CONSTANTS.fetchOneDeltaSuccess:
				// TODO: CONSIDER ADDING SUPPORT FOR TARGET COLLECTION NAME
				// TODO: SEPARATE DETAIL LOADING
				const id = getIdForEntity(action.record);
				newRecords = {
					[id]: { ...nullSafeGetOrElse(`records.${id}`, oldState, {}), ...action.record },
				};
				// add new records to base collection
				newState = {
					...oldState,
					fetching: false,
					fetchErrors: [],
					records: { ...oldState.records, ...newRecords },
					detail: newRecords[id],
				};
				return newState;
			case CRUD_ACTION_CONSTANTS.saveFormData:
				return {
					...oldState,
					formData: {
						...oldState.formData,
						...action.formData,
					},
				};
			case CRUD_ACTION_CONSTANTS.clearFormData:
				return {
					...oldState,
					formData: {},
				};
			case CRUD_ACTION_CONSTANTS.countByStart:
				return {
					...oldState,
					counting: [...oldState.counting, action.countName],
					countErrors: [],
				};
			case CRUD_ACTION_CONSTANTS.countBySuccess:
				return {
					...oldState,
					counting: oldState.counting.filter((c) => c !== action.countName),
					counts: {
						...oldState.counts,
						[action.countName]: action.count,
					},
				};
			case CRUD_ACTION_CONSTANTS.countByError:
				return {
					...oldState,
					counting: oldState.counting.filter((c) => c !== action.countName),
					countErrors: [...oldState.countErrors, action.error],
				};
			case CRUD_ACTION_CONSTANTS.createStart:
				return {
					...oldState,
					createErrors: [],
					creating: true,
				};
			case CRUD_ACTION_CONSTANTS.createSuccess:
				newRecordId = getIdForEntity(action.record);
				// add new records to base collection
				newState = {
					...oldState,
					creating: false,
					createErrors: [],
					detail: action.record,
					count: oldState.count + 1,
					sorting: oldState.sorting,
					filters: oldState.filters,
					records: {
						...oldState.records,
						[newRecordId]: action.record,
					},
				};
				if (newState.pagination && newState.pagination.total) {
					newState.pagination.total = newState.pagination.total + 1;
				}

				// if update targeting child collection, replace records
				if (action.targetCollectionName) {
					newState[action.targetCollectionName] = {
						recordsIds: [
							...nullSafeGetOrElse(`${action.targetCollectionName}.recordsIds`, newState, []),
							newRecordId,
						],
						sorting: oldState[action.targetCollectionName].sorting,
						filters: oldState[action.targetCollectionName].filters,
						pagination: oldState[action.targetCollectionName].pagination,
						count: nullSafeGetOrElse(`${action.targetCollectionName}.count`, newState, 0) + 1,
						creating: false,
						createErrors: [],
					};
					if (
						newState[action.targetCollectionName].pagination &&
						newState[action.targetCollectionName].pagination.total
					) {
						newState[action.targetCollectionName].pagination.total =
							newState[action.targetCollectionName].pagination.total + 1;
					}
				}
				return newState;
			case CRUD_ACTION_CONSTANTS.createError:
				return {
					...oldState,
					creating: false,
					createErrors: [...oldState.createErrors, action.error],
				};
			case CRUD_ACTION_CONSTANTS.updateStart:
				return {
					...oldState,
					updateErrors: [],
					updating: true,
				};
			case CRUD_ACTION_CONSTANTS.updateSuccess:
				newRecordId = getIdForEntity(action.record);
				// add new records to base collection
				newState = {
					...oldState,
					updating: false,
					updateErrors: [],
					detail: action.record,
					records: {
						...oldState.records,
						[newRecordId]: action.record,
					},
				};

				// if update targeting child collection, replace records
				if (action.targetCollectionName) {
					newState[action.targetCollectionName] = {
						recordsIds: [
							newRecordId,
							...nullSafeGetOrElse(
								`${action.targetCollectionName}.recordsIds`,
								newState,
								[]
							).filter((id) => id !== newRecordId),
						],
						updating: false,
						updateErrors: [],
					};
				}
				return newState;
			case CRUD_ACTION_CONSTANTS.updateError:
				return {
					...oldState,
					updating: false,
					updateErrors: [...oldState.updateErrors, action.error],
				};
			case CRUD_ACTION_CONSTANTS.archiveStart:
				return {
					...oldState,
					archiveErrors: [],
					archiving: true,
				};
			case CRUD_ACTION_CONSTANTS.archiveSuccess:
				newRecords = { ...oldState.records };
				delete newRecords[getIdForEntity(action.record)];
				newState = {
					...oldState,
					archiving: false,
					archiveErrors: [],
					records: newRecords,
				};
				newState.targetCollectionNames.forEach((targetCollectionName) => {
					newState[targetCollectionName] = {
						...newState[targetCollectionName],
						recordsIds: newState[targetCollectionName].recordsIds.filter(
							(recordId) => recordId !== getIdForEntity(action.record)
						),
						count: newState[targetCollectionName] - 1,
						archiving: false,
						archiveErrors: [],
					};
				});
				return newState;
			case CRUD_ACTION_CONSTANTS.archiveError:
				return {
					...oldState,
					archiving: false,
					archiveErrors: [...oldState.archiveErrors, action.error],
				};
			case CRUD_ACTION_CONSTANTS.unarchiveStart:
				return {
					...oldState,
					unarchiveErrors: [],
					unarchiving: true,
				};
			case CRUD_ACTION_CONSTANTS.unarchiveSuccess:
				newRecords = { ...oldState.records };
				delete newRecords[getIdForEntity(action.record)];
				newState = {
					...oldState,
					unarchiving: false,
					unarchiveErrors: [],
					records: newRecords,
				};
				newState.targetCollectionNames.forEach((targetCollectionName) => {
					newState[targetCollectionName] = {
						...newState[targetCollectionName],
						recordsIds: newState[targetCollectionName].recordsIds.filter(
							(recordId) => recordId !== getIdForEntity(action.record)
						),
						count: newState[targetCollectionName] - 1,
						unarchiving: false,
						unarchiveErrors: [],
					};
				});
				return newState;
			case CRUD_ACTION_CONSTANTS.unarchiveError:
				return {
					...oldState,
					unarchiving: false,
					unarchiveErrors: [...oldState.unarchiveErrors, action.error],
				};
			case CRUD_ACTION_CONSTANTS.deleteStart:
				return {
					...oldState,
					deleteErrors: [],
					deleting: true,
				};
			case CRUD_ACTION_CONSTANTS.deleteSuccess:
				newRecords = { ...oldState.records };
				delete newRecords[getIdForEntity(action.record)];
				newState = {
					...oldState,
					deleting: false,
					deleteErrors: [],
					records: newRecords,
				};
				newState.targetCollectionNames.forEach((targetCollectionName) => {
					newState[targetCollectionName] = {
						...newState[targetCollectionName],
						recordsIds: newState[targetCollectionName].recordsIds.filter(
							(recordId) => recordId !== getIdForEntity(action.record)
						),
						count: newState[targetCollectionName] - 1,
						deleting: false,
						deleteErrors: [],
					};
				});
				return newState;
			case CRUD_ACTION_CONSTANTS.deleteError:
				return {
					...oldState,
					deleting: false,
					deleteErrors: [...oldState.deleteErrors, action.error],
				};
			default:
				if (customReducerFunction) {
					return customReducerFunction(oldState, action);
				} else {
					return oldState;
				}
		}
	};
};

export const standardSearchReducerGenerator = (SEARCH_ACTION_CONSTANTS) => (oldState, action) => {
	Object.freeze(oldState);
	let newState;
	let newRecordId;
	switch (action.type) {
		case SEARCH_ACTION_CONSTANTS.searchStart:
			return {
				...oldState,
				searching: true,
			};
		case SEARCH_ACTION_CONSTANTS.searchSuccess:
			return {
				...oldState,
				searching: false,
				searchResultsCount: action.searchResultsCount,
				searchResults: action.searchResults,
				searchPagination: action.searchPagination,
				searchFilters: action.searchFilters,
				searchSorting: action.searchSorting,
			};
		case SEARCH_ACTION_CONSTANTS.receiveNationalSearchResults:
			return {
				...oldState,
				nationalSearchResults: action.nationalSearchResults,
			};
		case SEARCH_ACTION_CONSTANTS.receiveFacetSearchResults:
			return {
				...oldState,
				searchFacets: {
					...oldState.searchFacets,
					[action.facetName]: action.facetSearchResults,
				},
			};
		case SEARCH_ACTION_CONSTANTS.searchError:
			return {
				...oldState,
				searching: false,
				searchErrors: [...oldState.searchErrors, action.error],
			};
		case SEARCH_ACTION_CONSTANTS.updateSearchFilters:
			newState = {
				...oldState,
			};
			newState.searchFilters = {
				...newState.searchFilters,
				...action.searchFilters,
			};
			newState.searchPagination = {
				...newState.searchPagination,
				current: 1,
			};
			return newState;
		case SEARCH_ACTION_CONSTANTS.clearAllSearchFilters:
			newState = {
				...oldState,
			};
			newState.searchFilters = {};
			newState.searchPagination = {
				...newState.searchPagination,
				current: 1,
			};
			return newState;
		default:
			return oldState;
	}
};

export const standardReducerGenerator =
	(CRUD_ACTION_CONSTANTS, initialState, primaryKey = 'id', customReducerFunction = null) =>
	(oldState = initialState, action): any => {
		Object.freeze(oldState);
		let newState;
		let newRecords;
		let newRecordsIds;
		let newRecordId;
		switch (action.type) {
			case CRUD_ACTION_CONSTANTS.fetchStart:
				if (action.targetCollectionName !== null) {
					return {
						...oldState,
						fetching: true,
						[action.targetCollectionName]: {
							...oldState[action.targetCollectionName],
							fetching: true,
						},
					};
				} else {
					return {
						...oldState,
						fetching: true,
					};
				}
			case CRUD_ACTION_CONSTANTS.fetchError:
				if (action.targetCollectionName) {
					return {
						...oldState,
						fetching: false,
						fetchErrors: [...oldState.fetchErrors, action.error],
						[action.targetCollectionName]: {
							...oldState[action.targetCollectionName],
							fetching: false,
							fetchErrors: [
								...((oldState[action.targetCollectionName] &&
									oldState[action.targetCollectionName].fetchErrors) ||
									[]),
								action.error,
							],
						},
					};
				} else {
					return {
						...oldState,
						fetching: false,
						fetchErrors: [...oldState.fetchErrors, action.error],
					};
				}
			case CRUD_ACTION_CONSTANTS.fetchSuccess:
				// new records formatted as object for quick retrieval
				newRecords = action.records.reduce((acc, el) => {
					acc[el[primaryKey]] = el;
					return acc;
				}, {});
				newRecordsIds = action.records.map((el) => el[primaryKey]);

				// add new records to base collection
				newState = {
					...oldState,
					fetching: false,
					fetchErrors: [],
					count: action.count,
					records: { ...oldState.records, ...newRecords },
					selections: action.selections,
				};

				// if update targeting child collection, replace records
				if (action.targetCollectionName) {
					newState[action.targetCollectionName] = {
						...(oldState[action.targetCollectionName] ||
							standardInitialStateGenerator([action.targetCollectionName])),
						recordsIds: action.addToTargetCollection
							? [
									...nullSafeGetOrElse(`${action.targetCollectionName}.recordsIds`, newState, []),
									...newRecordsIds,
							  ]
							: newRecordsIds,
						sorting: oldState[action.targetCollectionName].sorting,
						filters: oldState[action.targetCollectionName].filters,
						pagination: oldState[action.targetCollectionName].pagination,
						selections: oldState[action.targetCollectionName].selections,
						count: action.count,
						fetching: false,
						fetchErrors: [],
					};
					// store pagination, sorting, and filters state of table if applicable
					if (action.pagination) {
						newState[action.targetCollectionName].pagination = action.pagination;
					}
					if (action.sorting) {
						newState[action.targetCollectionName].sorting = action.sorting;
					}
					if (action.filters) {
						newState[action.targetCollectionName].filters = action.filters;
					}
					if (action.selections) {
						newState[action.targetCollectionName].selections = action.selections;
					}
				} else {
					newState.recordsIds = newRecordsIds;
					if (action.pagination) {
						newState.pagination = action.pagination;
					}
					if (action.sorting) {
						newState.sorting = action.sorting;
					}
					if (action.filters) {
						newState.filters = action.filters;
					}
					if (action.selections) {
						newState.selections = action.selections;
					}
				}
				return newState;
			case CRUD_ACTION_CONSTANTS.fetchOneSuccess:
				// TODO: CONSIDER ADDING SUPPORT FOR TARGET COLLECTION NAME
				// TODO: SEPARATE DETAIL LOADING
				newRecords = {
					[action.record[primaryKey]]: action.record,
				};
				// add new records to base collection
				newState = {
					...oldState,
					fetching: false,
					fetchErrors: [],
					records: { ...oldState.records, ...newRecords },
					detail: action.record,
				};
				return newState;
			case CRUD_ACTION_CONSTANTS.fetchOneDeltaSuccess:
				// TODO: CONSIDER ADDING SUPPORT FOR TARGET COLLECTION NAME
				// TODO: SEPARATE DETAIL LOADING
				const id = action.record[primaryKey];
				newRecords = {
					[id]: { ...nullSafeGetOrElse(`records.${id}`, oldState, {}), ...action.record },
				};
				// add new records to base collection
				newState = {
					...oldState,
					fetching: false,
					fetchErrors: [],
					records: { ...oldState.records, ...newRecords },
					detail: newRecords[id],
				};
				return newState;
			case CRUD_ACTION_CONSTANTS.saveFormData:
				return {
					...oldState,
					formData: {
						...oldState.formData,
						...action.formData,
					},
				};
			case CRUD_ACTION_CONSTANTS.clearFormData:
				return {
					...oldState,
					formData: {},
				};

			case CRUD_ACTION_CONSTANTS.updateSelections:
				newState = {
					...oldState,
					[action.targetCollectionName]: {
						...oldState[action.targetCollectionName],
						selections: action.selections,
					},
				};
				return newState;

			case CRUD_ACTION_CONSTANTS.updateFilters:
				newState = {
					...oldState,
				};
				if (action.targetCollectionName) {
					newState[action.targetCollectionName] = {
						...newState[action.targetCollectionName],
						pagination: {
							...newState[action.targetCollectionName].pagination,
							current: 1,
						},
						filters: {
							...newState[action.targetCollectionName].filters,
							...action.filters,
						},
					};
				} else {
					newState.filters = {
						...newState.filters,
						...action.filters,
					};
					newState.pagination = {
						...newState.pagination,
						...action.pagination,
						current: 1,
					};
				}
				return newState;
			case CRUD_ACTION_CONSTANTS.clearAllFilters:
				newState = {
					...oldState,
				};
				if (action.targetCollectionName) {
					newState[action.targetCollectionName] = {
						...newState[action.targetCollectionName],
						pagination: {
							...newState[action.targetCollectionName].pagination,
							current: 1,
						},
						filters: {},
					};
				} else {
					newState.filters = {};
					newState.pagination = {
						...newState.pagination,
						current: 1,
					};
				}
				return newState;
			case CRUD_ACTION_CONSTANTS.clearAndUpdateFilters:
				newState = {
					...oldState,
				};
				if (action.targetCollectionName) {
					newState[action.targetCollectionName] = {
						...newState[action.targetCollectionName],
						pagination: {
							...action.pagination,
							current: 1,
						},
						filters: {
							...action.filters,
						},
					};
				} else {
					newState.filters = {
						...action.filters,
					};
					newState.pagination = {
						...newState.pagination,
						current: 1,
					};
				}
				return newState;
			case CRUD_ACTION_CONSTANTS.updateSorting:
				newState = {
					...oldState,
				};
				if (action.targetCollectionName) {
					newState[action.targetCollectionName] = {
						...newState[action.targetCollectionName],
						sorting: {
							sort_by: action.sort_by,
							order: action.order,
						},
					};
				} else {
					newState.sorting = {
						sort_by: action.sort_by,
						order: action.order,
					};
				}
				return newState;
			case CRUD_ACTION_CONSTANTS.updateFiltersAndSorting:
				newState = {
					...oldState,
				};
				if (action.targetCollectionName) {
					newState[action.targetCollectionName] = {
						...newState[action.targetCollectionName],
						filters: action.filters,
						sorting: action.sort_by,
					};
				} else {
					newState.filters = action.filters;
					newState.sorting = action.sort_by;
				}
				return newState;
			case CRUD_ACTION_CONSTANTS.clearTargetCollection:
				newState = {
					...oldState,
				};
				if (action.targetCollectionName) {
					newState[action.targetCollectionName] = {
						...newState[action.targetCollectionName],
						recordsIds: [],
					};
				}
				return newState;
			case CRUD_ACTION_CONSTANTS.cloneTargetCollection:
				newState = {
					...oldState,
				};
				if (action.newTargetCollectionName) {
					newState[action.newTargetCollectionName] = {
						...newState[action.fromTargetCollectionName],
					};
				}
				return newState;
			case CRUD_ACTION_CONSTANTS.countByStart:
				newState = {
					...oldState,
				};

				if (action.targetCollectionName) {
					newState[action.targetCollectionName] = {
						...newState[action.targetCollectionName],
						counting: [...oldState[action.targetCollectionName].counting, action.countName],
						countErrors: [],
					};
				} else {
					newState = {
						...oldState,
						counting: [...oldState.counting, action.countName],
						countErrors: [],
					};
				}

				return newState;
			case CRUD_ACTION_CONSTANTS.countBySuccess:
				newState = {
					...oldState,
				};

				if (action.targetCollectionName) {
					newState[action.targetCollectionName] = {
						...newState[action.targetCollectionName],
						counting: oldState[action.targetCollectionName].counting.filter(
							(c) => c !== action.countName
						),
						counts: {
							...oldState[action.targetCollectionName].counts,
							[action.countName]: action.count,
						},
					};
				} else {
					newState = {
						...oldState,
						counting: oldState.counting.filter((c) => c !== action.countName),
						counts: {
							...oldState.counts,
							[action.countName]: action.count,
						},
					};
				}

				return newState;

			case CRUD_ACTION_CONSTANTS.countByError:
				newState = {
					...oldState,
				};
				if (action.targetCollectionName) {
					newState[action.targetCollectionName] = {
						...newState[action.targetCollectionName],
						counting: oldState[action.targetCollectionName].counting.filter(
							(c) => c !== action.countName
						),
						countErrors: [...oldState[action.targetCollectionName].countErrors, action.error],
					};
				} else {
					newState = {
						...oldState,
						counting: oldState.counting.filter((c) => c !== action.countName),
						countErrors: [...oldState.countErrors, action.error],
					};
				}
				return newState;
			case CRUD_ACTION_CONSTANTS.createStart:
				return {
					...oldState,
					createErrors: [],
					creating: true,
				};
			case CRUD_ACTION_CONSTANTS.createSuccess:
				newRecordId = action.record[primaryKey];
				// add new records to base collection
				newState = {
					...oldState,
					creating: false,
					createErrors: [],
					detail: action.record,
					count: oldState.count + 1,
					sorting: oldState.sorting,
					filters: oldState.filters,
					records: {
						...oldState.records,
						[newRecordId]: action.record,
					},
				};
				if (newState.pagination && newState.pagination.total) {
					newState.pagination.total = newState.pagination.total + 1;
				}

				// if update targeting child collection, replace records
				if (action.targetCollectionName) {
					newState[action.targetCollectionName] = {
						...oldState[action.targetCollectionName],
						recordsIds: [
							newRecordId,
							...nullSafeGetOrElse(`${action.targetCollectionName}.recordsIds`, newState, []),
						],
						sorting: oldState[action.targetCollectionName].sorting,
						filters: oldState[action.targetCollectionName].filters,
						pagination: oldState[action.targetCollectionName].pagination,
						count: nullSafeGetOrElse(`${action.targetCollectionName}.count`, newState, 0) + 1,
						creating: false,
						createErrors: [],
					};

					if (
						newState[action.targetCollectionName].pagination &&
						newState[action.targetCollectionName].pagination.total
					) {
						newState[action.targetCollectionName].pagination.total =
							newState[action.targetCollectionName].pagination.total + 1;
					}
				}
				return newState;
			case CRUD_ACTION_CONSTANTS.createError:
				return {
					...oldState,
					creating: false,
					createErrors: [...oldState.createErrors, action.error],
				};
			case CRUD_ACTION_CONSTANTS.updateStart:
				return {
					...oldState,
					updateErrors: [],
					updating: true,
				};
			case CRUD_ACTION_CONSTANTS.updateSuccess:
				newRecordId = action.record[primaryKey];
				// add new records to base collection
				newState = {
					...oldState,
					updating: false,
					updateErrors: [],
					detail: action.record,
					records: {
						...oldState.records,
						[newRecordId]: action.record,
					},
				};

				// if update targeting child collection, replace records
				if (action.targetCollectionName) {
					newState[action.targetCollectionName] = {
						recordsIds: [
							newRecordId,
							...nullSafeGetOrElse(
								`${action.targetCollectionName}.recordsIds`,
								newState,
								[]
							).filter((id) => id !== newRecordId),
						],
						updating: false,
						updateErrors: [],
					};
				}
				return newState;
			case CRUD_ACTION_CONSTANTS.updateBulkSuccess:
				// new records formatted as object for quick retrieval
				newRecords = action.records.reduce((acc, el) => {
					acc[el[primaryKey]] = el;
					return acc;
				}, {});
				newRecordsIds = action.records.map((el) => el[primaryKey]);

				// add new records to base collection
				newState = {
					...oldState,
					updating: false,
					updateErrors: [],
					count: action.count,
					detail: action.record,
					records: { ...oldState.records, ...newRecords },
					selections: action.selections,
				};

				// if update targeting child collection, replace records
				if (action.targetCollectionName) {
					newState[action.targetCollectionName] = {
						...oldState[action.targetCollectionName],
						recordsIds: action.addToTargetCollection
							? [
									...nullSafeGetOrElse(`${action.targetCollectionName}.recordsIds`, newState, []),
									...newRecordsIds,
							  ]
							: newRecordsIds,
						updating: false,
						updateErrors: [],
					};
				}
				return newState;
			case CRUD_ACTION_CONSTANTS.updateError:
				return {
					...oldState,
					updating: false,
					updateErrors: [...oldState.updateErrors, action.error],
				};
			case CRUD_ACTION_CONSTANTS.archiveStart:
				return {
					...oldState,
					archiveErrors: [],
					archiving: true,
				};
			case CRUD_ACTION_CONSTANTS.archiveSuccess:
				newRecords = { ...oldState.records };
				delete newRecords[action.record[primaryKey]];
				newState = {
					...oldState,
					archiving: false,
					archiveErrors: [],
					records: newRecords,
				};
				newState.targetCollectionNames.forEach((targetCollectionName) => {
					newState[targetCollectionName] = {
						...newState[targetCollectionName],
						recordsIds: newState[targetCollectionName].recordsIds.filter(
							(recordId) => recordId !== action.record[primaryKey]
						),
						count: newState[targetCollectionName] - 1,
						archiving: false,
						archiveErrors: [],
					};
				});
				return newState;
			case CRUD_ACTION_CONSTANTS.archiveError:
				return {
					...oldState,
					archiving: false,
					archiveErrors: [...oldState.archiveErrors, action.error],
				};
			case CRUD_ACTION_CONSTANTS.unarchiveStart:
				return {
					...oldState,
					unarchiveErrors: [],
					unarchiving: true,
				};
			case CRUD_ACTION_CONSTANTS.unarchiveSuccess:
				newRecords = { ...oldState.records };
				delete newRecords[action.record[primaryKey]];
				newState = {
					...oldState,
					unarchiving: false,
					unarchiveErrors: [],
					records: newRecords,
				};
				newState.targetCollectionNames.forEach((targetCollectionName) => {
					newState[targetCollectionName] = {
						...newState[targetCollectionName],
						recordsIds: newState[targetCollectionName].recordsIds.filter(
							(recordId) => recordId !== action.record[primaryKey]
						),
						count: newState[targetCollectionName] - 1,
						unarchiving: false,
						unarchiveErrors: [],
					};
				});
				return newState;
			case CRUD_ACTION_CONSTANTS.unarchiveError:
				return {
					...oldState,
					unarchiving: false,
					unarchiveErrors: [...oldState.unarchiveErrors, action.error],
				};
			case CRUD_ACTION_CONSTANTS.deleteStart:
				return {
					...oldState,
					deleteErrors: [],
					deleting: true,
				};
			case CRUD_ACTION_CONSTANTS.deleteSuccess:
				newRecords = { ...oldState.records };
				delete newRecords[action.record[primaryKey]];
				newState = {
					...oldState,
					deleting: false,
					deleteErrors: [],
					records: newRecords,
				};
				newState.targetCollectionNames.forEach((targetCollectionName) => {
					newState[targetCollectionName] = {
						...newState[targetCollectionName],
						recordsIds: newState[targetCollectionName].recordsIds.filter(
							(recordId) => recordId !== action.record[primaryKey]
						),
						count: newState[targetCollectionName] - 1,
						deleting: false,
						deleteErrors: [],
					};
				});
				return newState;
			case CRUD_ACTION_CONSTANTS.deleteError:
				return {
					...oldState,
					deleting: false,
					deleteErrors: [...oldState.deleteErrors, action.error],
				};
			default:
				if (customReducerFunction) {
					return customReducerFunction(oldState, action);
				} else {
					return oldState;
				}
		}
	};

import React, { FC, useCallback, useMemo, useState } from 'react';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	companyConfigRestCrudThunks,
	companyConfigRestCrudThunksForBuyer,
} from '../../thunks/company_config_thunks';
import { Checkbox, Spin, Table } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { PERMISSION_NAMES, getBuyerAccessDefaultConfig } from '../../utils/AuthUtils';

const DirectPermissionConfigComponent: FC<any> = ({
	updateBuyerCompanySettings,
	companySettings,
}): React.ReactElement => {
	const [currentUpdatingKey, setCurrentUpdatingKey] = useState(null);

	const permissionsChanged = useCallback(
		(key) => (e) => {
			const updatedSetting = {
				...companySettings,
				config: {
					...companySettings.config,
					[key.permissionKey]: {
						...nullSafeGetOrElse(
							`config.${key.permissionKey}`,
							companySettings,
							getBuyerAccessDefaultConfig(key.permissionKey)
						),
						[key.roleKey]: e.target.checked,
					},
				},
			};

			setCurrentUpdatingKey(`${key.roleKey}-${key.permissionKey}`);

			updateBuyerCompanySettings(updatedSetting).finally(() => setCurrentUpdatingKey(null));
		},
		[companySettings, updateBuyerCompanySettings]
	);

	const permissionsColumns = useMemo(
		() => [
			{
				title: 'Permissions',
				dataIndex: 'configName',
				width: '25%',
			},
			{
				title: 'Store Associate',
				dataIndex: 'storeAssociatesAllowed',
				render: (_, record) => (
					<div className="flex flex-row">
						<Checkbox
							checked={_}
							onChange={permissionsChanged({
								permissionKey: record.configKey,
								roleKey: 'storeAssociatesAllowed',
							})}
						/>
						{currentUpdatingKey === `storeAssociatesAllowed-${record.configKey}` && (
							<div className="ml-2">
								<Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} translate="" spin />} />
							</div>
						)}
					</div>
				),
			},
			{
				title: 'Manager',
				dataIndex: 'managersAllowed',
				render: (_, record) => (
					<div className="flex flex-row">
						<Checkbox
							checked={_}
							onChange={permissionsChanged({
								permissionKey: record.configKey,
								roleKey: 'managersAllowed',
							})}
						/>
						{currentUpdatingKey === `managersAllowed-${record.configKey}` && (
							<div className="ml-2">
								<Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} translate="" spin />} />
							</div>
						)}
					</div>
				),
			},
		],
		[currentUpdatingKey, permissionsChanged]
	);

	const canMarkWorkOrderCompletedConfig = useMemo(
		() =>
			nullSafeGetOrElse(
				'config.canMarkWorkOrderCompleted',
				companySettings,
				getBuyerAccessDefaultConfig(PERMISSION_NAMES.CAN_MARK_WORK_ORDER_COMPLETED)
			),
		[companySettings]
	);

	const canReviewWorkOrderCompletiondConfig = useMemo(
		() =>
			nullSafeGetOrElse(
				'config.canReviewWorkOrderCompletion',
				companySettings,
				getBuyerAccessDefaultConfig(PERMISSION_NAMES.CAN_REVIEW_WORK_ORDER_COMPLETED)
			),
		[companySettings]
	);
	const canApproveWorkOrders = useMemo(
		() =>
			nullSafeGetOrElse(
				'config.canApproveWorkOrders',
				companySettings,
				getBuyerAccessDefaultConfig(PERMISSION_NAMES.CAN_APPROVE_WORK_ORDERS)
			),
		[companySettings]
	);
	const canApproveQuotes = useMemo(
		() =>
			nullSafeGetOrElse(
				'config.canApproveQuotes',
				companySettings,
				getBuyerAccessDefaultConfig(PERMISSION_NAMES.CAN_APPROVE_QUOTES)
			),
		[companySettings]
	);
	const canApproveInvoices = useMemo(
		() =>
			nullSafeGetOrElse(
				'config.canApproveInvoices',
				companySettings,
				getBuyerAccessDefaultConfig(PERMISSION_NAMES.CAN_APPROVE_INVOICES)
			),
		[companySettings]
	);

	const permissionsDataSource = useMemo(
		() => [
			{
				configKey: 'canMarkWorkOrderCompleted',
				configName: 'Can Mark Work Order Complete',
				...canMarkWorkOrderCompletedConfig,
			},
			{
				configKey: 'canReviewWorkOrderCompletion',
				configName: 'Can Review Work Order Completed By Third Party',
				...canReviewWorkOrderCompletiondConfig,
			},
			{
				configKey: 'canApproveWorkOrders',
				configName: 'Can Approve Work Orders',
				...canApproveWorkOrders,
			},
			{
				configKey: 'canApproveQuotes',
				configName: 'Can Approve Quotes',
				...canApproveQuotes,
			},
			{
				configKey: 'canApproveInvoices',
				configName: 'Can Approve Invoices',
				...canApproveInvoices,
			},
		],
		[
			canMarkWorkOrderCompletedConfig,
			canReviewWorkOrderCompletiondConfig,
			canApproveWorkOrders,
			canApproveQuotes,
			canApproveInvoices,
		]
	);

	return (
		<Table
			columns={permissionsColumns}
			dataSource={permissionsDataSource}
			showHeader
			pagination={false}
		/>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	currentUser: state.session.currentUser,

	companySettings: state.company_config.detail,
	companySettingsFetching: state.company_config.fetching,
});

const mapDispatchToProps = (dispatch) => ({
	getBuyerCompanySettings: (contact) =>
		dispatch(companyConfigRestCrudThunks(ROLE_TYPES.BUYER, contact)),
	updateBuyerCompanySettings: (settings) =>
		dispatch(companyConfigRestCrudThunksForBuyer.update(settings)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(DirectPermissionConfigComponent)
);

import * as React from 'react';
import { Layout, Row, Col, Card } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import EditableCellTable from '../editable_cell_table/EditableCellTable';
import {
	companyConfigRestCrudThunks,
	companyConfigRestCrudThunksForBuyer,
} from '../../thunks/company_config_thunks';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import _ from 'lodash';

const { Content } = Layout;

require('./BuyerAdminSettingsPage.less');

class BuyerAdminSettingsPage extends React.Component<any, any> {
	state = {
		currentlyUpdatingKey: null,
	};

	componentDidMount() {
		const { getBuyerCompanySettings, currentUser } = this.props;
		getBuyerCompanySettings(currentUser);
	}

	updateAdminSettings = (newSetting) => {
		const { companySettings, updateBuyerCompanySettings } = this.props;
		let newBuyerCompanySettings = { ...companySettings };

		if (newSetting.key.indexOf('internalTech') > -1) {
			const [supplierFacilityId, settingName] = newSetting.key.split('-');
			let configKey = '';
			switch (settingName) {
				case 'internalTechDeny':
					configKey = 'allowedToDenyWork';
					break;
				case 'internalTechTransfer':
					configKey = 'allowedToTransferWork';
					break;
				case 'internalTechSkipServiceRequest':
					configKey = 'skipApprovalForServiceRequests';
					break;
				case 'internalTechCheckInNotesMandatory':
					configKey = 'checkInNotesMandatory';
					break;
				case 'internalTechCheckOutNotesMandatory':
					configKey = 'checkOutNotesMandatory';
					break;
				case 'internalTechCheckInNumImagesRequired':
					configKey = 'numImagesRequiredOnCheckIn';
					break;
				case 'internalTechCheckOutNumImagesRequired':
					configKey = 'numImagesRequiredOnCheckOut';
					break;
				case 'internalTechOneClickComplete':
					configKey = 'allowedOneClickCompletion';
					break;
			}
			const internalTechConfig = nullSafeGetOrElse(
				'config.internalTechConfig',
				companySettings,
				[]
			);
			const newInternalTechConfig = internalTechConfig.map((techConfig) =>
				techConfig.supplierFacilityId == supplierFacilityId
					? {
							...techConfig,
							[configKey]: newSetting.numerical ? newSetting.numericalValue : newSetting.indicator,
					  }
					: techConfig
			);
			newBuyerCompanySettings = {
				...companySettings,
				config: {
					...companySettings.config,
					internalTechConfig: newInternalTechConfig,
				},
			};
		} else {
			switch (newSetting.key) {
				case 'staleWorkOrderDaysPostDue':
					newBuyerCompanySettings = newSetting.numericalValue
						? {
								...companySettings,
								config: {
									...companySettings.config,
									workOrder: {
										...nullSafeGetOrElse('config.workOrder', companySettings, {}),
										stale: {
											...nullSafeGetOrElse('config.workOrder.stale', companySettings, {}),
											...companySettings.stale,
											markStale: true,
											afterXDays: newSetting.numericalValue,
										},
									},
								},
						  }
						: newBuyerCompanySettings;
					break;
				case 'allowWorkOrderToBeDeferred':
					newBuyerCompanySettings = {
						...companySettings,
						config: {
							...companySettings.config,
							workOrder: {
								...nullSafeGetOrElse('config.workOrder', companySettings, {}),
								allowWorkOrderToBeDeferred: newSetting.indicator,
							},
						},
					};
					break;
				case 'thirdPartySuppliersAllowedOneClickCompletion':
					newBuyerCompanySettings = {
						...companySettings,
						config: {
							...companySettings.config,
							workOrder: {
								...nullSafeGetOrElse('config.workOrder', companySettings, {}),
								thirdPartySuppliersAllowedOneClickCompletion: newSetting.indicator,
							},
						},
					};
					break;
				case 'allowPONUmberEntry':
					newBuyerCompanySettings = {
						...companySettings,
						config: {
							...companySettings.config,
							workOrder: {
								...nullSafeGetOrElse('config.workOrder', companySettings, {}),
								allowPONUmberEntry: newSetting.indicator,
							},
						},
					};
					break;
				case 'showAssets':
					newBuyerCompanySettings = {
						...companySettings,
						config: {
							...companySettings.config,
							newServiceRequest: {
								...nullSafeGetOrElse('config.newServiceRequest', companySettings, {}),
								...companySettings.newServiceRequest,
								showAssets: newSetting.indicator,
							},
						},
					};
					break;
				case 'allowCancel':
					newBuyerCompanySettings = {
						...companySettings,
						config: {
							...companySettings.config,
							newServiceRequest: {
								...nullSafeGetOrElse('config.newServiceRequest', companySettings, {}),
								...companySettings.newServiceRequest,
								allowCancel: newSetting.indicator,
							},
						},
					};
					break;
				case 'allowReopen':
					newBuyerCompanySettings = {
						...companySettings,
						config: {
							...companySettings.config,
							newServiceRequest: {
								...nullSafeGetOrElse('config.newServiceRequest', companySettings, {}),
								...companySettings.newServiceRequest,
								allowReopen: newSetting.indicator,
							},
						},
					};
					break;
				case 'numImagesRequired':
					newBuyerCompanySettings = {
						...companySettings,
						config: {
							...companySettings.config,
							newServiceRequest: {
								...nullSafeGetOrElse('config.newServiceRequest', companySettings, {}),
								...companySettings.newServiceRequest,
								numImagesRequired: newSetting.numericalValue,
							},
						},
					};
					break;
				case 'mirrorForInternalTeam':
					newBuyerCompanySettings = {
						...companySettings,
						config: {
							...companySettings.config,
							workOrder: {
								...nullSafeGetOrElse('config.workOrder', companySettings, {}),
								archive: {
									...nullSafeGetOrElse('config.workOrder.archive', companySettings, {}),
									...companySettings.archive,
									mirrorForInternalTeam: newSetting.indicator,
								},
							},
						},
					};
					break;
				case 'showOnlyAssignedWorkOrdersInDefaultTechnicianView':
					newBuyerCompanySettings = {
						...companySettings,
						config: {
							...companySettings.config,
							workOrder: {
								...nullSafeGetOrElse('config.workOrder', companySettings, {}),
								showOnlyAssignedWorkOrdersInDefaultTechnicianView: newSetting.indicator,
							},
						},
					};
					break;
				case 'usePresetUUID':
					newBuyerCompanySettings = {
						...companySettings,
						config: {
							...companySettings.config,
							assetConfig: {
								...nullSafeGetOrElse('config.assetConfig', companySettings, {}),
								...companySettings.assetConfig,
								usePresetUUID: newSetting.indicator,
							},
						},
					};
					break;
				case 'useAssetNumbersAsUUID':
					newBuyerCompanySettings = {
						...companySettings,
						config: {
							...companySettings.config,
							assetConfig: {
								...nullSafeGetOrElse('config.assetConfig', companySettings, {}),
								...companySettings.assetConfig,
								useAssetNumbersAsUUID: newSetting.indicator,
							},
						},
					};
					break;
				case 'removeAssetNumberToQRCodeAssociation':
					newBuyerCompanySettings = {
						...companySettings,
						config: {
							...companySettings.config,
							assetConfig: {
								...nullSafeGetOrElse('config.assetConfig', companySettings, {}),
								...companySettings.assetConfig,
								removeAssetNumberToQRCodeAssociation: newSetting.indicator,
							},
						},
					};
					break;
				case 'enableCustomerReportedServiceRequests':
					newBuyerCompanySettings = {
						...companySettings,
						config: {
							...companySettings.config,
							assetConfig: {
								...nullSafeGetOrElse('config.assetConfig', companySettings, {}),
								...companySettings.assetConfig,
								enableCustomerReportedServiceRequests: newSetting.indicator,
							},
						},
					};
					break;
				case 'allowAreaAssociationWithAssets':
					newBuyerCompanySettings = {
						...companySettings,
						config: {
							...companySettings.config,
							assetConfig: {
								...nullSafeGetOrElse('config.assetConfig', companySettings, {}),
								...companySettings.assetConfig,
								allowAreaAssociationWithAssets: newSetting.indicator,
							},
						},
					};
					break;
				case 'acceptCapExCategory':
					newBuyerCompanySettings = {
						...companySettings,
						config: {
							...companySettings.config,
							capExConfig: {
								...nullSafeGetOrElse('config.capExConfig', companySettings, {}),
								...companySettings.capExConfig,
								acceptCapExCategory: newSetting.indicator,
							},
						},
					};
					break;
				case 'nteCheckWithoutTax':
					newBuyerCompanySettings = {
						...companySettings,
						config: {
							...companySettings.config,
							invoiceConfig: {
								...nullSafeGetOrElse('config.invoiceConfig', companySettings, {}),
								nteCheckWithoutTax: newSetting.indicator,
							},
						},
					};
					break;
				case 'skipInvoiceApprovalHierarchyIfQuoteHasTheSame':
					newBuyerCompanySettings = {
						...companySettings,
						config: {
							...companySettings.config,
							invoiceConfig: {
								...nullSafeGetOrElse('config.invoiceConfig', companySettings, {}),
								skipInvoiceApprovalHierarchyIfQuoteHasTheSame: newSetting.indicator,
							},
						},
					};
					break;
				case 'disableSimpleInvoiceEntry':
					newBuyerCompanySettings = {
						...companySettings,
						config: {
							...companySettings.config,
							suppliersConfig: {
								...nullSafeGetOrElse('config.suppliersConfig', companySettings, {}),
								disableSimpleInvoiceEntry: newSetting.indicator,
							},
						},
					};
					break;
				case 'addUserToWorkOrderApprovalHierarchyOnTheGo':
					newBuyerCompanySettings = {
						...companySettings,
						config: {
							...companySettings.config,
							approvalHierarchyConfig: {
								...nullSafeGetOrElse('config.approvalHierarchyConfig', companySettings, {}),
								addUserToWorkOrderApprovalHierarchyOnTheGo: newSetting.indicator,
							},
						},
					};
					break;
				case 'addUserToProposalApprovalHierarchyOnTheGo':
					newBuyerCompanySettings = {
						...companySettings,
						config: {
							...companySettings.config,
							approvalHierarchyConfig: {
								...nullSafeGetOrElse('config.approvalHierarchyConfig', companySettings, {}),
								addUserToProposalApprovalHierarchyOnTheGo: newSetting.indicator,
							},
						},
					};
					break;
				case 'addUserToInvoiceApprovalHierarchyOnTheGo':
					newBuyerCompanySettings = {
						...companySettings,
						config: {
							...companySettings.config,
							approvalHierarchyConfig: {
								...nullSafeGetOrElse('config.approvalHierarchyConfig', companySettings, {}),
								addUserToInvoiceApprovalHierarchyOnTheGo: newSetting.indicator,
							},
						},
					};
					break;
				default:
					newBuyerCompanySettings = {
						...companySettings,
						config: {
							...companySettings.config,
							approvalConfig: {
								...nullSafeGetOrElse('config.approvalConfig', companySettings, {}),
								[newSetting.key]: newSetting.indicator,
							},
						},
					};
			}
		}
		return new Promise((resolve) => {
			updateBuyerCompanySettings(newBuyerCompanySettings).finally(() => resolve(true));
		});
	};

	render() {
		const { companySettingsFetching, companySettings } = this.props;

		const workOrderApprovalSettings = nullSafeGetOrElse(
			'config.approvalConfig',
			companySettings,
			{}
		);

		const internalTechAllArray = [
			{
				settings: 'Mirror Archive Action For Internal Technician Team',
				key: 'mirrorForInternalTeam',
				indicator: nullSafeGetOrElse(
					'config.workOrder.archive.mirrorForInternalTeam',
					companySettings,
					false
				),
			},
			{
				settings: 'Allow Internal Technician Team To Approve Quotes And Change NTE',
				key: `allowToApproveProposalByInternalTech`,
				indicator: nullSafeGetOrElse(
					'config.approvalConfig.allowToApproveProposalByInternalTech',
					companySettings,
					false
				),
			},
			{
				settings: 'Show Only Assigned Work Orders In Default Technician View',
				key: 'showOnlyAssignedWorkOrdersInDefaultTechnicianView',
				indicator: nullSafeGetOrElse(
					'config.workOrder.showOnlyAssignedWorkOrdersInDefaultTechnicianView',
					companySettings,
					false
				),
			},
		];

		const suppliersConfigArray = [
			{
				settings: 'Disable Simple Invoice Entry',
				key: 'disableSimpleInvoiceEntry',
				indicator: nullSafeGetOrElse(
					'config.suppliersConfig.disableSimpleInvoiceEntry',
					companySettings,
					false
				),
			},
		];

		const workOrderConfig = nullSafeGetOrElse('config.workOrder', companySettings, {});

		const workOrderArray = [
			{
				settings: 'Days past due after which work order should be marked stale',
				key: 'staleWorkOrderDaysPostDue',
				indicator: nullSafeGetOrElse('config.workOrder.stale.afterXDays', companySettings, 14),
				numerical: true,
			},
			{
				settings: 'Allow Work Orders to be deferred',
				key: 'allowWorkOrderToBeDeferred',
				indicator: nullSafeGetOrElse(
					'config.workOrder.allowWorkOrderToBeDeferred',
					companySettings,
					false
				),
			},
			{
				settings: 'Allow Work Orders to be completed in one click by 3rd party suppliers',
				key: 'thirdPartySuppliersAllowedOneClickCompletion',
				indicator: nullSafeGetOrElse(
					'config.workOrder.thirdPartySuppliersAllowedOneClickCompletion',
					companySettings,
					false
				),
			},
			{
				settings: 'Allow PO number entry in Work orders',
				key: 'allowPONUmberEntry',
				indicator:
					typeof workOrderConfig.allowPONUmberEntry === 'undefined'
						? true
						: workOrderConfig.allowPONUmberEntry,
			},
		];

		const approvalConfigArray = [
			{
				settings: 'Auto Approve Service Requests',
				key: 'autoApproveServiceRequests',
				indicator: nullSafeGetOrElse(
					'autoApproveServiceRequests',
					workOrderApprovalSettings,
					false
				),
			},
			{
				settings: 'Auto Approve Supplier Initiated Work Order',
				key: 'autoApproveSupplierInitiatedWorkOrders',
				indicator: nullSafeGetOrElse(
					'autoApproveSupplierInitiatedWorkOrders',
					workOrderApprovalSettings,
					false
				),
			},
			{
				settings: 'Auto Approve Work Orders Completed By Third Party',
				key: 'autoApproveWorkOrdersCompletedByThirdParty',
				indicator: nullSafeGetOrElse(
					'autoApproveWorkOrdersCompletedByThirdParty',
					workOrderApprovalSettings,
					false
				),
			},
		];

		const assetConfigurationsArray = [
			{
				settings: 'Use Preset Unique Identifier for Asset QR code',
				key: 'usePresetUUID',
				indicator: nullSafeGetOrElse('config.assetConfig.usePresetUUID', companySettings, false),
			},
			{
				settings: 'Use Asset Number as Preset Unique Identifier',
				key: 'useAssetNumbersAsUUID',
				indicator: nullSafeGetOrElse(
					'config.assetConfig.useAssetNumbersAsUUID',
					companySettings,
					false
				),
			},
			{
				settings: 'Remove asset number association from QR code',
				key: 'removeAssetNumberToQRCodeAssociation',
				indicator: nullSafeGetOrElse(
					'config.assetConfig.removeAssetNumberToQRCodeAssociation',
					companySettings,
					false
				),
			},
			{
				settings: 'Enable customer requested service requests',
				key: 'enableCustomerReportedServiceRequests',
				indicator: nullSafeGetOrElse(
					'config.assetConfig.enableCustomerReportedServiceRequests',
					companySettings,
					false
				),
			},
			{
				settings: 'Enable area association for assets',
				key: 'allowAreaAssociationWithAssets',
				indicator: nullSafeGetOrElse(
					'config.assetConfig.allowAreaAssociationWithAssets',
					companySettings,
					false
				),
			},
		];

		const invoiceConfigurationArray = [
			{
				settings: 'Enable CapEx Categorization',
				key: 'acceptCapExCategory',
				indicator: nullSafeGetOrElse(
					'config.capExConfig.acceptCapExCategory',
					companySettings,
					false
				),
			},
			{
				settings: 'NTE based checks should be done without tax',
				naturalText: true,
				key: 'nteCheckWithoutTax',
				indicator: nullSafeGetOrElse(
					'config.invoiceConfig.nteCheckWithoutTax',
					companySettings,
					false
				),
			},
			{
				settings: 'Skip approval hierarchy if invoice has same hierarchy as quote',
				naturalText: true,
				key: 'skipInvoiceApprovalHierarchyIfQuoteHasTheSame',
				indicator: nullSafeGetOrElse(
					'config.invoiceConfig.skipInvoiceApprovalHierarchyIfQuoteHasTheSame',
					companySettings,
					false
				),
			},
		];

		const approvalHierarchyConfigurationArray = [
			{
				settings: 'Enable approval forwarding for work orders',
				naturalText: true,
				key: 'addUserToWorkOrderApprovalHierarchyOnTheGo',
				indicator: _.get(
					companySettings,
					'config.approvalHierarchyConfig.addUserToWorkOrderApprovalHierarchyOnTheGo',
					true
				),
			},
			{
				settings: 'Enable approval forwarding for quotes',
				naturalText: true,
				key: 'addUserToProposalApprovalHierarchyOnTheGo',
				indicator: _.get(
					companySettings,
					'config.approvalHierarchyConfig.addUserToProposalApprovalHierarchyOnTheGo',
					true
				),
			},
			{
				settings: 'Enable approval forwarding for invoices',
				naturalText: true,
				key: 'addUserToInvoiceApprovalHierarchyOnTheGo',
				indicator: _.get(
					companySettings,
					'config.approvalHierarchyConfig.addUserToInvoiceApprovalHierarchyOnTheGo',
					true
				),
			},
		];

		const serviceRequestsArray = [
			{
				settings: 'Show Assets In Service Requests Form',
				key: 'showAssets',
				indicator: nullSafeGetOrElse('config.newServiceRequest.showAssets', companySettings, false),
			},
			{
				settings: 'Allow Cancellation of Service Requests',
				key: 'allowCancel',
				indicator: nullSafeGetOrElse(
					'config.newServiceRequest.allowCancel',
					companySettings,
					false
				),
			},
			{
				settings: 'Allow Re-opening Service Requests',
				key: 'allowReopen',
				indicator: nullSafeGetOrElse(
					'config.newServiceRequest.allowReopen',
					companySettings,
					false
				),
			},
			{
				settings: 'Required Number of Images In Service Requests Form',
				key: 'numImagesRequired',
				indicator: nullSafeGetOrElse(
					'config.newServiceRequest.numImagesRequired',
					companySettings,
					0
				),
				numerical: true,
			},
		];

		const internalTechArray = (companySettings.config.internalTechConfig || []).map(
			(internalTechSetting) => ({
				title: `Internal Tech Configurations - ${internalTechSetting.supplierFacility.name}`,
				data: [
					{
						settings: 'Skip service request approval',
						key: `${internalTechSetting.supplierFacilityId}-internalTechSkipServiceRequest`,
						indicator: internalTechSetting.skipApprovalForServiceRequests,
					},
					{
						settings: 'Allow technician to decline work',
						key: `${internalTechSetting.supplierFacilityId}-internalTechDeny`,
						indicator: internalTechSetting.allowedToDenyWork,
					},
					{
						settings: 'Allow technician to transfer work',
						key: `${internalTechSetting.supplierFacilityId}-internalTechTransfer`,
						indicator: internalTechSetting.allowedToTransferWork,
					},
					{
						settings: 'Check-in notes mandatory',
						key: `${internalTechSetting.supplierFacilityId}-internalTechCheckInNotesMandatory`,
						indicator: nullSafeGetOrElse('checkInNotesMandatory', internalTechSetting, false),
					},
					{
						settings: 'Check-out notes mandatory',
						key: `${internalTechSetting.supplierFacilityId}-internalTechCheckOutNotesMandatory`,
						indicator: nullSafeGetOrElse('checkOutNotesMandatory', internalTechSetting, false),
					},
					{
						settings: 'Required number of images during check-in',
						key: `${internalTechSetting.supplierFacilityId}-internalTechCheckInNumImagesRequired`,
						indicator: nullSafeGetOrElse('numImagesRequiredOnCheckIn', internalTechSetting, 0),
						numerical: true,
					},
					{
						settings: 'Required number of images during check-out',
						key: `${internalTechSetting.supplierFacilityId}-internalTechCheckOutNumImagesRequired`,
						indicator: nullSafeGetOrElse('numImagesRequiredOnCheckOut', internalTechSetting, 0),
						numerical: true,
					},
					{
						settings: 'Allow to complete work orders in one click',
						key: `${internalTechSetting.supplierFacilityId}-internalTechOneClickComplete`,
						indicator:
							typeof internalTechSetting.allowedOneClickCompletion === 'undefined'
								? true
								: internalTechSetting.allowedOneClickCompletion,
					},
				],
			})
		);

		const getColumns = (title) => [
			{
				title: title || 'Configuration',
				dataIndex: 'settings',
				width: '640px',
				render: (text, record) => (
					<span style={{ fontSize: 16 }}>
						{record.naturalText ? text : text[0] ? text[0].concat(text.slice(1).toLowerCase()) : ''}
					</span>
				),
			},
			{
				dataIndex: 'indicator',
				width: '80px',
				editable: true,
			},
			{
				dataIndex: 'numerical',
				render: () => '',
			},
		];

		const overallConfigurationsArray = [
			{
				title: 'Approval Configurations',
				data: approvalConfigArray,
			},
			{
				title: 'Service Request Configurations',
				data: serviceRequestsArray,
			},
			{
				title: 'Work Order Configurations',
				data: workOrderArray,
			},
			{
				title: 'Asset Configurations',
				data: assetConfigurationsArray,
			},
			{
				title: 'Invoice Configurations',
				data: invoiceConfigurationArray,
			},
			{
				title: 'Approval Hierarchy Configuration',
				data: approvalHierarchyConfigurationArray,
			},
			{
				title: 'Suppliers',
				data: suppliersConfigArray,
			},
			{
				title: 'Internal Tech Configurations',
				data: internalTechAllArray,
			},
		].concat(internalTechArray);

		return companySettingsFetching ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="buyerAdminSettingsPage">
				<LogOnMountWithStandardEventProperties eventType="visited buyer admin settings page" />

				{overallConfigurationsArray.map((configurationGroup, index) => (
					<Row className="rowSpacing" key={index}>
						<Col style={{ maxWidth: 800, margin: 'auto' }} span={24}>
							<Card title={configurationGroup.title}>
								<EditableCellTable
									handleSave={this.updateAdminSettings}
									columns={getColumns(configurationGroup.title)}
									data={configurationGroup.data}
								/>
							</Card>
						</Col>
					</Row>
				))}
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	currentUser: state.session.currentUser,

	companySettings: state.company_config.detail,
	companySettingsFetching: state.company_config.fetching,
});

const mapDispatchToProps = (dispatch) => ({
	getBuyerCompanySettings: (contact) => dispatch(companyConfigRestCrudThunks('buyer', contact)),
	updateBuyerCompanySettings: (settings) =>
		dispatch(companyConfigRestCrudThunksForBuyer.update(settings)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(BuyerAdminSettingsPage)
);

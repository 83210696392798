import * as React from 'react';
import { Spin, Button, Input, Tag, Checkbox, Tooltip, Row } from 'antd';
import { connect } from 'react-redux';
import { debounce } from '../../utils/PerformanceUtils';
import { isEqual } from 'lodash';
import { supplierFacilitiesSearchThunks } from '../../thunks/search_thunks';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { capitalizeEachWord } from '../../utils/DataFormatterUtils';
import { lengthTruncatedText } from '../../utils/TextFormatterUtils';

class SidebarMultiselectFilter extends React.Component<any, any> {
	state = {
		showAll: false,
		searchText: '',
		initialLoad: true,
	};

	// componentDidMount() {
	//     const {searching, additionalParams, searchFilters, searchSorting} = this.props;
	//     const {searchText} = this.state;
	//     const otherParams = additionalParams || {};
	//     this.debouncedCallback(
	//         {...otherParams, maxFacetHits: 100, facetQuery: searchText},
	//         null,
	//         nextProps.searchSorting,
	//         nextProps.searchFilters
	//     );
	// }

	componentWillReceiveProps(nextProps) {
		const { searching, additionalParams, searchFilters, searchSorting } = this.props;
		const { searchText } = this.state;
		const searchFiltersChanged = !isEqual(nextProps.searchFilters, searchFilters);
		const searchSortingChanged = !isEqual(nextProps.searchSorting, searchSorting);
		const additionalParamsChanged = !isEqual(nextProps.additionalParams, additionalParams);
		const propsChanged = searchFiltersChanged || searchSortingChanged || additionalParamsChanged;
		const otherParams = nextProps.additionalParams || {};
		if (propsChanged) {
			this.debouncedCallback(
				{ ...otherParams, maxFacetHits: 100, facetQuery: searchText && searchText.trim() },
				null,
				nextProps.searchSorting,
				nextProps.searchFilters
			);
		}
	}

	debouncedCallback = debounce(
		(params, pagination, sorting, filters) => {
			const { fetchSearchFilterData } = this.props;
			const { initialLoad } = this.state;
			fetchSearchFilterData(params, pagination, sorting, filters).then(() => {
				if (initialLoad) {
					this.setState({ initialLoad: false });
				}
			});
		},
		200,
		false
	);

	handleSearchDebounced = (e) => {
		const { additionalParams, searchFilters, searchSorting } = this.props;
		const searchText = e.target.value && e.target.value.trim();
		const otherParams = additionalParams || {};
		this.setState({ searchText: searchText }, () => {
			this.debouncedCallback(
				{ ...otherParams, maxFacetHits: 100, facetQuery: searchText },
				null,
				searchSorting,
				searchFilters
			);
		});
	};

	render() {
		const {
			limit,
			records,
			filterKey,
			placeholderNoun,
			valueKey,
			displayKey,
			uniqueKey,
			handleChange,
		} = this.props;
		const { showAll, initialLoad } = this.state;
		const labelFontSize = 14;

		const radioStyle = {
			lineHeight: '40px',
			fontSize: labelFontSize,
			marginLeft: 0,
		};

		let resultRecords = records;
		let visibleRecords;

		if (!showAll && resultRecords.length > limit) {
			visibleRecords = resultRecords.slice(0, limit);
		} else {
			visibleRecords = resultRecords;
		}

		return (
			<div>
				{initialLoad ? (
					<Spin />
				) : (
					<div>
						<Input.Search
							placeholder={`Filter ${placeholderNoun}`}
							style={{ marginBottom: 8 }}
							onChange={this.handleSearchDebounced}
						/>
						<div style={{ marginBottom: 8 }}>
							<Checkbox.Group onChange={handleChange}>
								{visibleRecords.map((category, idx) => (
									<Row>
										<Checkbox
											style={radioStyle}
											value={category[valueKey]}
											key={category[uniqueKey]}
										>
											<Tooltip title={capitalizeEachWord(category[displayKey])}>
												{lengthTruncatedText(capitalizeEachWord(category[displayKey]), 35)}
												<Tag style={{ marginLeft: 8 }}>{category.count}</Tag>
											</Tooltip>
										</Checkbox>
									</Row>
								))}
							</Checkbox.Group>
						</div>
						{visibleRecords.length === 0 ? <div>No {placeholderNoun} found</div> : null}
						{resultRecords.length > limit ? (
							<div>
								<Button onClick={() => this.setState({ showAll: !showAll })}>
									{showAll ? 'Show less' : 'Show all'}
								</Button>
							</div>
						) : null}
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const { collectionName } = ownProps;
	const relevantState = state[collectionName];

	if (!relevantState) {
		throw new Error(
			'collection not found in root reducer. please check name for typos or add reducer for collection'
		);
	}

	let mappedProps = {
		emptyState: ownProps.emptyState,
		collectionName: ownProps.collectionName,
		showHeader: ownProps.showHeader,
		keyAccessor: ownProps.keyAccessor,
		records: nullSafeGetOrElse(`searchFacets.${ownProps.facetName}`, relevantState, []),
		searchPagination: relevantState.searchPagination,
		searchFilters: relevantState.searchFilters,
		searchSorting: relevantState.searchSorting,
		searching: relevantState.searching,
	};

	return mappedProps;
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchSearchFilterData: (params, pagination, sorting, filters) =>
		dispatch(
			supplierFacilitiesSearchThunks.searchFacet(
				ownProps.facetName,
				params,
				pagination,
				sorting,
				filters
			)
		),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMultiselectFilter);

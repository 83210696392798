import * as React from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { getCurrency, nullSafeGet } from '../../utils/DataAccessUtils';
import { QUOTE_STATUS_DISPLAY_NAMES } from '../../constants/QuoteStatuses';
import { getContactName } from '../../utils/DataFormatterUtils';
import QuoteDisplayStatusDisplay from '../quote_display_status_display/QuoteDisplayStatusDisplay';
import moment from 'moment';
import DateTimeHover from '../date_time_component/DateTime';

require('./QuoteRowDisplay.less');

export const QuoteRowDisplay: React.FC<any> = ({ quote, ...props }) => (
	<div className="quoteRowDisplay" {...props}>
		<div className="rowColumn">
			<div className="rowPrimary" style={{ color: 'rgba(0,0,0,0.65)' }}>
				<Ellipsis
					tooltip={true}
					fullWidthRecognition={true}
					lines={1}
					style={{ paddingBottom: '1px', marginRight: '5%' }}
				>
					{nullSafeGet('workOrder.title', quote)}
				</Ellipsis>
			</div>
			<div className="rowSecondary">
				<div className="rowInlineGroup">
					<span className="rowGrayChip">
						<i className="icons8-font icons8-location-marker" />
						<span>{nullSafeGet('workOrder.location.name', quote)}</span>
					</span>
				</div>
				<div className="rowInlineGroup">
					<QuoteDisplayStatusDisplay status={QUOTE_STATUS_DISPLAY_NAMES[quote.status]} />
				</div>
				{nullSafeGet('workOrder.isCapex', quote) ? (
					<div className="rowInlineGroup">
						<span className={'rowGrayChip'} style={{ padding: '4px 12px', borderRadius: 16 }}>
							<span>CapEx</span>
						</span>
					</div>
				) : null}
				<div className="rowInlineGroup">
					<i className="icons8-font icons8-maintenance-filled" />
					<span>{nullSafeGet('workOrder.problemType.hierarchyName', quote)}</span>
				</div>
				{nullSafeGet('workOrder.supplierFacility.displayName', quote) ? (
					<div className="rowInlineGroup">
						<i className="icons8-font icons8-company" />
						{nullSafeGet('workOrder.supplierFacility.displayName', quote)}
					</div>
				) : null}
				<div className="rowInlineGroup">{'WO #'.concat(nullSafeGet('workOrderId', quote))}</div>
				{nullSafeGet('workOrder.buyerProjects.length', quote) === 1 ? (
					<div className="rowInlineGroup">
						<span>
							<i className="icons8-font icons8-blueprint" />
							{nullSafeGet('workOrder.buyerProjects', quote)[0].title}
						</span>
					</div>
				) : nullSafeGet('workOrder.buyerProjects.length', quote) > 1 ? (
					<div className="rowInlineGroup">
						<span>
							<i className="icons8-font icons8-blueprint" />
							{nullSafeGet('workOrder.buyerProjects.length', quote)} Projects
						</span>
					</div>
				) : null}
			</div>
			<div className="rowTertiary rowBlackPrimary mt-2">
				<span style={{ whiteSpace: 'nowrap', color: 'rgba(0,0,0,0.45)', fontSize: 14 }}>
					<>
						Submitted <DateTimeHover timestamp={nullSafeGet('createdAt', quote)} showTime={false} />{' '}
						{quote.createdByContact ? `by ${getContactName(quote.createdByContact)}` : ''}
					</>
				</span>
			</div>
		</div>
		<div className="quoteRowDisplay__rightSide">
			<div className="quoteRowDisplay__rowInlineGroup">
				{getCurrency({ workOrder: nullSafeGet('workOrder', quote) }).format(quote.totalAfterTax)}
			</div>
		</div>
	</div>
);

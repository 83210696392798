import React, { FC, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Card } from 'antd';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import StockLocationAddPartForm from '../stock_location_add_part_form/StockLocationAddOrEditPartForm';

const { Content } = Layout;

const NewPartsPerStockLocationPage: FC<any> = ({ history, match }): React.ReactElement => {
	const stockLocationId = useMemo(() => match.params.id, [match.params.id]);

	return (
		<Content>
			<LogOnMountWithStandardEventProperties eventType="visited supplier new parts per stock location page" />
			<Row style={{ margin: '0.5em -8px' }} gutter={16}>
				<Col span={24}>
					<Card>
						<div>
							<h5 style={{ marginBottom: 24 }}>New Part Per Stock Location</h5>
							<StockLocationAddPartForm
								formData={{}}
								stockLocationId={stockLocationId}
								onSuccess={(rec) =>
									history.push(`/supplier/stockLocations/detail/${stockLocationId}/parts`)
								}
							/>
						</div>
					</Card>
				</Col>
			</Row>
		</Content>
	);
};

export default withRouter(connect()(NewPartsPerStockLocationPage));

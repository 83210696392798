import {
	FETCH_ALL_MESSAGES_START,
	FETCH_ALL_MESSAGES_SUCCESS,
	RECEIVE_LOADING_MESSAGE,
	RECEIVE_NEW_MESSAGE,
	SOMEONE_STARTED_TYPING,
	SOMEONE_STOPPED_TYPING,
} from '../actions/messages_actions';

const isEqual = require('fast-deep-equal');

const messagesReducer = (oldState = {}, action) => {
	Object.freeze(oldState);
	let newState;
	switch (action.type) {
		case FETCH_ALL_MESSAGES_START:
			return {
				...oldState,
				[action.channelId]: { messages: [], isLoading: true, isTyping: false, isTypingText: '' },
			};
		case FETCH_ALL_MESSAGES_SUCCESS:
			return {
				...oldState,
				[action.channelId]: {
					...oldState[action.channelId],
					messages: action.messages,
					isLoading: false,
				},
			};
		case RECEIVE_LOADING_MESSAGE:
			return {
				...oldState,
				[action.channelId]: {
					...oldState[action.channelId],
					messages: [...oldState[action.channelId].messages, { ...action.message, loading: true }],
					isLoading: false,
				},
			};
		case RECEIVE_NEW_MESSAGE:
			const areMessagesEqual = (m1, m2) => {
				return m1.text == m2.text && m1.noteAddedBy == m2.noteAddedBy;
			};
			const oldMessagesWithoutLoadingMessage = oldState[action.channelId].messages.filter((el) => {
				return !el.loading || !areMessagesEqual(el, action.message);
			});
			return {
				...oldState,
				[action.channelId]: {
					...oldState[action.channelId],
					messages: [...oldMessagesWithoutLoadingMessage, action.message],
					isLoading: false,
				},
			};
		case SOMEONE_STARTED_TYPING:
			return {
				...oldState,
				[action.channelId]: {
					...oldState[action.channelId],
					isTyping: true,
					isTypingText: action.message,
				},
			};
		case SOMEONE_STOPPED_TYPING:
			return {
				...oldState,
				[action.channelId]: {
					...oldState[action.channelId],
					isTyping: false,
					isTypingText: '',
				},
			};
		default:
			return oldState;
	}
};

export default messagesReducer;

import { PROPOSALS_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/proposals_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = standardInitialStateGenerator([
	'RFP_DETAIL_PROPOSALS',
	'workOrderAssociatedQuotes',
	'quotesTable',
	'quotesIndex',
]);
export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState);

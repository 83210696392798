import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Card } from 'antd';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { requestsForProposalRestCrudThunksForBuyer } from '../../thunks/requests_for_proposal_thunks';

import { REQUESTS_FOR_PROPOSAL_CRUD_ACTION_CREATORS } from '../../actions/requests_for_proposal_actions';
import { EmptyState } from '../empty_state/EmptyState';
import { RFPRowDisplay } from '../rfp_row_display/RFPRowDisplay';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import * as queryString from 'querystring';

const { Content } = Layout;

const style = require('./RequestsForProposalIndexPage.less');

const DATE_FORMAT = 'MMM D, YYYY';

const initState = {
	filteredInfo: null,
	sortedInfo: null,
	visible: false,
};

interface SourcingPageState {
	filteredInfo: any;
	sortedInfo: any;
	visible: boolean;
}

const REQUESTS_FOR_PROPOSAL_OVERVIEW_TARGET_COLLECTION = 'requestsForProposalIndex';

class RequestsForProposalIndexPage extends React.Component<any, SourcingPageState> {
	initialPagination: any = { current: 1, pageSize: 10 };
	constructor(props) {
		super(props);

		const { location } = this.props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		const { current, pageSize, ...other } = queryParams;
		this.initialPagination = {
			...this.initialPagination,
			current: current ? current : this.initialPagination.current,
			pageSize: pageSize ? pageSize : this.initialPagination.pageSize,
		};
		this.state = {
			...initState,
		};
		this.handleChange = this.handleChange.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
		this.clearAll = this.clearAll.bind(this);
		this.handleOk = this.handleOk.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.showModal = this.showModal.bind(this);
	}

	handleSizeChange = (current, size) => {
		const { location, history, updateRequestForProposalFilters } = this.props;

		let backUrl = `/buyer/requestsForProposal/overview/all`;
		history.push(backUrl);
		updateRequestForProposalFilters({}, REQUESTS_FOR_PROPOSAL_OVERVIEW_TARGET_COLLECTION);
	};

	showModal(e) {
		this.setState({
			visible: true,
		});
	}

	handleOk(e) {
		this.setState({
			visible: false,
		});
	}

	handleCancel(e) {
		this.setState({
			visible: false,
		});
	}

	handleChange(pagination, filters, sorter) {
		console.log('Various parameters', pagination, filters, sorter);
		this.setState({
			filteredInfo: filters,
			sortedInfo: sorter,
		});
	}

	clearFilters() {
		this.setState({ filteredInfo: null });
	}

	clearAll() {
		this.setState({
			filteredInfo: null,
			sortedInfo: null,
		});
	}

	render() {
		let filteredInfo = this.state.filteredInfo;
		let sortedInfo = this.state.sortedInfo;
		const {
			history,
			requestsForProposal,
			accountActivated,
			updateSourcingEventFilters,
			currentUser,
		} = this.props;

		const MY_FILTER = { ownerEmail: currentUser.email };

		filteredInfo = filteredInfo || {};
		sortedInfo = sortedInfo || {};

		const columns = [
			{
				title: 'Name',
				dataIndex: 'title',
				render: (text, record) => <RFPRowDisplay rfp={record} />,
			},
		];
		const onRow = (record) => ({
			onClick: () => history.push(`/buyer/requestsForProposal/detail/${record.id}`),
		});

		return (
			<Content className="requestsForProposalPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited buyer rfp index page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}

				<Row style={{ margin: '0.5em -8px' }}>
					<Col span={24}>
						<Card bodyStyle={{ padding: 8 }}>
							{accountActivated ? (
								<PaginatedReduxTable
									updateQueryParams={true}
									emptyState={
										<EmptyState
											graphic={
												<img
													style={{ marginBottom: 8 }}
													src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
												/>
											}
											headline={"It's a little lonely in here."}
											body={
												<div style={{ textAlign: 'center' }}>
													<div style={{ maxWidth: 440, marginBottom: 16 }}>
														Looks like your team has not created any requests for proposal yet.
													</div>
												</div>
											}
										/>
									}
									collectionName="requests_for_proposal"
									targetCollectionName={REQUESTS_FOR_PROPOSAL_OVERVIEW_TARGET_COLLECTION}
									columns={columns}
									onRow={onRow}
									showHeader={false}
									keyAccessor={(el) => el.id}
									initialPagination={this.initialPagination}
									fetchData={requestsForProposalRestCrudThunksForBuyer.read}
								/>
							) : (
								<EmptyState
									graphic={
										<img
											style={{ marginBottom: 8 }}
											src="https://s3.amazonaws.com/mock-data-assets/categories/images/letter owl.svg"
										/>
									}
									headline={"You haven't activated your account yet."}
									body={
										<div style={{ textAlign: 'center' }}>
											<div style={{ maxWidth: 560, marginBottom: 16 }}>
												Please check your email. We sent you a note with an activation link.
											</div>
										</div>
									}
								/>
							)}
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	currentUser: state.session.currentUser,
	accountActivated: state.session.accountActivated,
	requestsForProposal: state.requests_for_proposal,
});

const mapDispatchToProps = (dispatch) => ({
	getRequestsForProposalCountBy: (params, countName) =>
		dispatch(requestsForProposalRestCrudThunksForBuyer.countBy(params, countName)),
	updateRequestForProposalFilters: (filters, targetCollection) =>
		dispatch(REQUESTS_FOR_PROPOSAL_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(RequestsForProposalIndexPage)
);

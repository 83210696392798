import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Popconfirm } from 'antd';
import { withRouter } from 'react-router';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import { budgetsRestCrudThunksForBuyer, getBudgetCycleForBuyer } from '../../thunks/budget_thunks';
import { useMemo } from 'react';
import { updateNavbarState } from '../../actions/navbar_actions';
import { workOrdersRestCrudThunksForBuyer } from '../../thunks/work_orders_thunks';

const queryString = require('qs');

function BudgetDetailPage({
	match,
	currentUser,
	companyConfig,
	fetchBudgetCycle,
	budget,
	loading,
	location,
	history,
	updateNavbar,
	deleteBudget,

	childRoutes,
}) {
	const budgetId = match.params.id;
	let searchString = location.search;
	if (searchString[0] === '?') {
		searchString = searchString.slice(1);
	}
	const queryParams = queryString.parse(searchString);

	useMemo(() => {
		fetchBudgetCycle(budgetId, queryParams);
	}, [searchString]);

	let locationName;
	const pathList = history.location.pathname.split('/');
	if (pathList.includes('locations')) {
		const locationId = parseInt(pathList[pathList.indexOf('locations') + 1]);
		if (locationId === 0) {
			locationName = 'Company Wide';
		} else {
			const location = nullSafeGetOrElse('budgetPerLocations', budget, []).find(
				(loc) => loc.locationId === locationId
			);
			locationName = nullSafeGet('name', location);
		}
	} else {
		locationName = undefined;
	}
	useMemo(() => {
		if (locationName) {
			updateNavbar(
				`Back to ${budget.title}`,
				`/buyer/budgets/detail/${budgetId}/details`.concat(location.search)
			);
		}
	}, [locationName, location.search]);

	return (
		<div>
			<DetailPageHeader
				exactPath={'/buyer/budgets/detail/:id'}
				redirectPath={`/buyer/budgets/detail/${budgetId}/details`}
				backLinkText={'Back to budgets'}
				backLinkTo={`/buyer/admin/budgets`}
				title={loading ? null : `${budget.title} ${locationName ? `- ${locationName}` : ''}`}
				subtitle={<div></div>}
				subtext={<div className="body1"></div>}
				actions={[
					<Button
						ghost={true}
						style={{ marginBottom: 16, marginRight: 16 }}
						key="reopen"
						onClick={() => history.push(`/buyer/budgets/edit/${budgetId}`)}
					>
						Edit
					</Button>,
					<Popconfirm
						onConfirm={() =>
							deleteBudget(budget).then(() => {
								history.push('/buyer/admin/budgets/overview');
							})
						}
						placement="topLeft"
						title="Are you sure you want to delete?"
						okText="Yes"
						cancelText="No"
					>
						<Button ghost={true} style={{ marginBottom: 16, marginRight: 16 }} key="delete">
							Delete
						</Button>
					</Popconfirm>,
				]}
				tabs={[]}
				backgroundColor={nullSafeGet('detail.config.theme.navblock', companyConfig)}
				handleTabChange={() => {}}
				checkTabActive={() => {}}
				childRoutes={childRoutes}
			/>
		</div>
	);
}

const mapStateToProps = (state, ownProps) => ({
	budget: state.budgets.detail,
	loading: state.budgets.fetching,
	match: ownProps.match,
	history: ownProps.history,
	location: ownProps.location,
	currentUser: state.session.currentUser,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	fetchBudgetCycle: (budgetId, params) => dispatch(getBudgetCycleForBuyer(budgetId, params)),
	updateNavbar: (backlinkTitle, backlinkTo, navbarBackgroundColor) =>
		dispatch(updateNavbarState(backlinkTitle, backlinkTo, navbarBackgroundColor)),
	deleteBudget: (entity) =>
		dispatch(budgetsRestCrudThunksForBuyer.delete(entity, 'id', null, true)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BudgetDetailPage));

import * as BizCharts from 'bizcharts';
import React, { FC } from 'react';

const { Chart, Coord, Axis, Geom, Tooltip, View } = BizCharts;

interface ColumnLineComboChartProps {
	cols: any;
	height: number;
	data: any;
	fillArea: boolean;
	position1: string;
	position2: string;
	chartPadding?: [number, number, number, number];
	xAxisLabel?: any;
	yAxisLabel?: any;
	color1?: string;
	color2?: string;
	xAxisName: string;
	yAxisName: string;
	tooltipItemTemplate?: any;
	showTooltipTitle?: boolean;
}

export const ColumnLineComboChart: FC<ColumnLineComboChartProps> = ({
	chartPadding,
	showTooltipTitle = true,
	tooltipItemTemplate,
	data,
	cols,
	color1,
	color2,
	height,
	fillArea,
	xAxisName,
	yAxisName,
	xAxisLabel,
	yAxisLabel,
	position1,
	position2,
}) => {
	return (
		<Chart
			height={height}
			padding={chartPadding ? chartPadding : [40, 40, 40, 40]}
			data={data}
			scale={cols}
			forceFit={true}
		>
			<Axis name={xAxisName} label={xAxisLabel} />
			<Axis name={yAxisName} label={yAxisLabel} />
			<Tooltip
				crosshairs={{ type: 'y' }}
				showTitle={showTooltipTitle}
				itemTpl={
					tooltipItemTemplate
						? tooltipItemTemplate
						: `
                    <li data-index={index}>
                        <!-- The marker for each record -->
                        <span style="background-color:{color1};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>
                        {name}: {value}
                    </li>
                `
				}
			/>
			<Geom type="interval" position={position1} color={color1} />
			<Geom type="line" position={position2} color={color2} size={2} />
			{fillArea ? <Geom type="area" position={position2} color={color2} /> : null}
			<Geom
				type="point"
				position={position2}
				color={color2}
				size={4}
				shape={'circle'}
				style={{ stroke: '#fff', lineWidth: 1 }}
			/>
		</Chart>
	);
};

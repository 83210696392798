import React from 'react';
import NotificationsIndexPage from '../notifications_index_page/NotificationsIndexPage';
import { EmptyState } from '../empty_state/EmptyState';
import { ROLE_TYPES } from '../../utils/DataConstants';
import {
	userNotificationsRestCrudThunksForBuyer,
	userNotificationsRestCrudThunksForSupplier,
} from '../../thunks/user_notifications_thunks';
import { connect } from 'react-redux';

function NotificationsTasks({
	fetchUserNotificationsCountForSupplier,
	fetchUserNotificationsCountForBuyer,
	userType,
}) {
	const fetchUserNotificationsCount =
		userType === ROLE_TYPES.BUYER
			? fetchUserNotificationsCountForBuyer
			: fetchUserNotificationsCountForSupplier;

	return (
		<NotificationsIndexPage
			key={'NotificationsTasks'}
			showFilters={false}
			preAppliedFilters={{ hasSeen: false, isImportant: true }}
			targetCollectionName="TASKS_NOTIFICATIONS"
			onNotificationClick={() => {
				fetchUserNotificationsCount({ isImportant: true, hasSeen: false }, 'TASKS_NOTIFICATIONS');
			}}
			emptyState={
				<EmptyState
					graphic={
						<img
							style={{ marginBottom: 8 }}
							src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
						/>
					}
					headline={"You don't have any important notification."}
					body={null}
				/>
			}
		/>
	);
}

const mapStateToProps = (state, ownProps) => ({
	userType: state.session.userType,
});

const mapDispatchToProps = (dispatch) => ({
	fetchUserNotificationsCountForBuyer: (params, countName = null) =>
		dispatch(userNotificationsRestCrudThunksForBuyer.countBy(params, countName)),
	fetchUserNotificationsCountForSupplier: (params, countName = null) =>
		dispatch(userNotificationsRestCrudThunksForSupplier.countBy(params, countName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsTasks);

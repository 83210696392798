import * as React from 'react';
import { connect } from 'react-redux';
import { Layout, Row, Col, Button, Card, Table } from 'antd';
import {
	fetchSupplierFacilitiesIfEverInPrivateNetworkForBuyer,
	fetchSupplierFacilitiesIfEverInPrivateNetworkForSupplier,
} from '../../thunks/supplier_facilities_thunks';
import { fetchAllKPIs } from '../../thunks/kpi_analytics_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import VendorScorecard from '../vendor_scorecard';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { withRouter } from 'react-router';

const { Content } = Layout;

function PrivateNetworkIndexPage() {
	return (
		<div>
			<Content className="analyticsOverviewVendorsPage" style={{ padding: '0' }}>
				<LogOnMountWithStandardEventProperties eventType="visited KPI analytics overview vendors page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<VendorScorecard />
			</Content>
		</div>
	);
}

const mapStateToProps = (state, ownProps) => ({
	supplierFacilities: state.supplier_facilities,
});

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		fetchSupplierFacilitiesIfEverInPrivateNetwork: (params, targetCollectionName) =>
			dispatch(
				ownProps.userType === ROLE_TYPES.SUPPLIER
					? fetchSupplierFacilitiesIfEverInPrivateNetworkForSupplier(params, targetCollectionName)
					: fetchSupplierFacilitiesIfEverInPrivateNetworkForBuyer(params, targetCollectionName)
			),
		fetchKPIs: (params, targetCollectionName) =>
			dispatch(fetchAllKPIs(ownProps.userType)(params, targetCollectionName)),
	};
};

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(PrivateNetworkIndexPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
	InitUser,
	checkUserTypeToken,
	getUserObj,
	loginViaAuth0,
	retrieveCachedUserDetails,
} from '../../thunks/session_thunks';
import { skipTokenCheck } from '../../actions/session_actions';
import { useAuth0 } from '@auth0/auth0-react';
import { posthog } from 'posthog-js';

const LoginWrapper = ({ store, children, isLoggedIn, currentUser, isActive, isApproved }) => {
	const auth0 = useAuth0();
	const { isAuthenticated: isAuth0Authenticated, isLoading, logout } = auth0;

	const { token, userType = 'buyer' } = retrieveCachedUserDetails(['token', 'userType']);

	// Effect to renew the token every 6 hours
	useEffect(() => {
		var timeoutInterval;

		// if user is loggedIn
		if (isLoggedIn) {
			// Function to renew the token and set up the next renewal

			var renewTokenAndSetInterval = () => {
				const expiry = localStorage.getItem('token_expiry');
				const now = new Date();

				// expiry exist and is already due
				if (expiry && now.getTime() > parseInt(expiry)) {
					store.dispatch(checkUserTypeToken(userType)());
				} else {
					posthog.capture('user_no_expiry_found');
					timeoutInterval = setTimeout(renewTokenAndSetInterval, parseInt(expiry) - now.getTime());
				}
			};

			// Initial Check.
			renewTokenAndSetInterval();

			// Clean up the interval when the component unmounts
			return () => {
				timeoutInterval && clearTimeout(timeoutInterval);
			};
		} else {
			// either user was never logged in or did logout. clearTimeout if exist
			timeoutInterval && clearTimeout(timeoutInterval);
		}
	}, [isLoggedIn, token]);

	useEffect(() => {
		// if user is loggedIn and is either not approved or not active . renew auth. this code is just because we can renew auth for already logged in users which shouldnt be
		// can be removed in future
		if (isLoggedIn) {
			if (isActive === false || isApproved === false) {
				posthog.capture('inactive_user_found', { isActive, isApproved });
				store.dispatch(checkUserTypeToken(userType)());
			}
		}
	}, [isLoggedIn]);

	useEffect(() => {
		const userType1 = userType || 'buyer';
		async function fetchData() {
			const claims = await auth0.getIdTokenClaims();
			store
				.dispatch(loginViaAuth0(userType1, claims.__raw, claims))
				.then(console.log)
				.catch(() => {
					// this says login wth SSO was success but failed on OW API , this will mean that SSO sdk will considered user logged in but our system wont. logging out user form sso also, so that they can retry.
					logout({ logoutParams: { returnTo: `${window.location.origin}/${userType1}/login` } });
				});
		}
		//  this is app level wrapper
		// first, wait for Auth0 loading to finish fetching details
		// check if the user is authenticated on SSO -> get the token and pass it to backend to validate and then store it in localstorage
		// if not, check if there is already a token in localStorage -> get it renewed from backend and store new token in localstorage
		// else, user is not auth either via SSO or OW . mark user loggedIn false

		if (!isLoading) {
			if (isAuth0Authenticated) {
				fetchData();
			} else if (token) {
				// @ts-ignore
				store.dispatch(InitUser(userType1, window.location.search));
			} else {
				store.dispatch(skipTokenCheck());
			}
		}
	}, [isLoading]);

	return children;
};

const mapStateToProps = (state) => ({
	isLoggedIn: state.session.isAuthenticated,
	currentUser: state.session.currentUser,
	isActive: state.session.isActive,
	isApproved: state.session.isApproved,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LoginWrapper);

import * as BizCharts from 'bizcharts';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { currencyFormatter } from '../../utils/DataFormatterUtils';

const { Chart, Axis, Geom, Tooltip } = BizCharts;

interface LineChartProps {
	cols: any;
	height: number;
	data: any;
	fillArea: boolean;
	position: string;
	chartPadding?: [number, number, number, number];
	xAxisLabel?: any;
	style?: any;
	yAxisLabel?: any;
	color?: string;
	xAxisName: string;
	yAxisName: string;
	tooltipTemplate?: any;
	userType: string;
}

const DarkLineChart: FC<LineChartProps> = ({
	style = {},
	chartPadding,
	tooltipTemplate,
	data,
	cols,
	color,
	height,
	fillArea,
	xAxisName,
	yAxisName,
	xAxisLabel,
	yAxisLabel,
	position,
	userType,
}) => {
	const bgColor = userType === 'supplier' ? '#3c899a' : '#4c228a';

	return (
		<div
			style={{
				...style,
				backgroundColor: bgColor,
			}}
		>
			<Chart
				theme="dark"
				background={{
					fill: bgColor,
				}}
				plotBackground={{
					fill: bgColor,
				}}
				height={height}
				padding="auto"
				data={data}
				scale={cols}
				forceFit={true}
			>
				<Axis name={xAxisName} label={xAxisLabel} />
				<Axis name={yAxisName} label={yAxisLabel} />
				<Tooltip
					crosshairs={{ type: 'y' }}
					useHtml
					htmlContent={(_, items) => {
						const { title, name, value } = items[0];
						return `<div class='absolute flex flex-col text-slate-300 p-4' style='background-color:rgba(0, 0, 0, 0.5)'>
							<div>Month : ${title}</div>
							<div>${name} : ${currencyFormatter()(value)}</div>
						</div>`;
					}}
				/>
				<Geom type="line" position={position} color={color} size={2} />
				{fillArea ? <Geom type="area" position={position} color={color} /> : null}
			</Chart>
		</div>
	);
};

export default connect(
	(state) => ({
		userType: state.session.userType,
	}),
	(dispatch, ownProps) => ({})
)(DarkLineChart);

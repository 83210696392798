import { REQUESTS_FOR_PROPOSAL_CRUD_ACTION_CREATORS } from '../actions/requests_for_proposal_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import FetchUtils from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';

export const requestsForProposalRestCrudThunksForBuyer = new RestCrudThunks(
	'requests_for_proposal',
	'request_for_proposal',
	'requestForProposal',
	'requestsForProposal',
	REQUESTS_FOR_PROPOSAL_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'quote/'
);
export const requestsForProposalRestCrudThunksForSupplier = new RestCrudThunks(
	'requests_for_proposal',
	'request_for_proposal',
	'requestForProposal',
	'requestsForProposal',
	REQUESTS_FOR_PROPOSAL_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'quote/'
);

const fetchUtils = new FetchUtils();
const BASE_URL = getBackendUri();
const getRFPStatusUrl = (roleType, status, eventId) =>
	`/api/v1/${roleType}/quote/requests_for_proposal/${status}/${eventId}`;

const requestForProposalStatusChange = (roleType) => (status, entity) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(REQUESTS_FOR_PROPOSAL_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.patch(BASE_URL + getRFPStatusUrl(roleType, status, entity.id), {})
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(REQUESTS_FOR_PROPOSAL_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(REQUESTS_FOR_PROPOSAL_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(REQUESTS_FOR_PROPOSAL_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

export const requestForProposalStatusChangeForBuyer = requestForProposalStatusChange(
	ROLE_TYPES.BUYER
);
export const requestForProposalStatusChangeForSupplier = requestForProposalStatusChange(
	ROLE_TYPES.SUPPLIER
);

export const markNotBiddingRFP = (roleType) => (entity) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(REQUESTS_FOR_PROPOSAL_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.patch(BASE_URL + `/api/v1/${roleType}/quote/bidders/${entity.id}/not_bidding`, {})
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(REQUESTS_FOR_PROPOSAL_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(REQUESTS_FOR_PROPOSAL_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(REQUESTS_FOR_PROPOSAL_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

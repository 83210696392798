import { Button, Form, Modal, Select, message } from 'antd';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import TransferAssetFormFields from './TransferAssetFormFields';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { ROLE_TYPES } from '../../utils/DataConstants';
import {
	locationsRestCrudThunksForBuyer,
	locationsRestCrudThunksForSupplier,
} from '../../thunks/locations_thunks';
import {
	moveAssetToLocationForBuyer,
	moveAssetToLocationForSupplier,
} from '../../thunks/assets_thunks';
import { withRouter } from 'react-router';
import { BE_DATE_ONLY_FORMAT } from '../../utils/DataFormatterUtils';
import {
	areasRestCrudThunksForBuyer,
	areasRestCrudThunksForSupplier,
} from '../../thunks/areas_thunks';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { getBuyerCompanySettingValue } from '../../utils/AuthUtils';

const FORM_NAME = 'transfer-to-loc-form';

const TransferAssetToLocationForm: FC<any> = ({
	match,
	asset,
	onCancel,
	locations,
	fetchLocations,
	fetchMultipleLocations,
	moveAssetToLocation,
	onSuccess,
	areas,
	fetchAreas,
	fetchMultipleAreas,
	companyConfig,
	userType,
	currentUser,
}): React.ReactElement => {
	const [form] = Form.useForm();

	const [transferring, setTransferring] = useState(false);

	const { setFieldsValue } = form;

	const locationId = Form.useWatch('locationId', form);

	useEffect(() => {
		locationId && locationId !== asset.locationId && setFieldsValue({ areaId: undefined });
	}, [asset.locationId, locationId, setFieldsValue]);

	const assetId = useMemo(() => match.params.id, [match.params.id]);

	const handleSubmit = useCallback(
		(values) => {
			const data = {
				locationId: values.locationId,
				areaId: values.areaId,
				...(values.transferNote && { transferNote: values.transferNote }),
				...(values.transferDate && {
					transferDate: values.transferDate.format(BE_DATE_ONLY_FORMAT),
				}),
			};

			setTransferring(true);
			moveAssetToLocation(assetId, data)
				.then((res) => {
					message.success('Asset transferred successfully!');
					onSuccess(res);
				})
				.catch((err) => message.error(err))
				.finally(() => setTransferring(false));
		},
		[assetId, moveAssetToLocation, onSuccess]
	);

	const initialValues = useMemo(
		() => ({
			locationId: nullSafeGet('locationId', asset),
			areaId: nullSafeGet('areaId', asset),
		}),
		[asset]
	);

	const allowArea = useMemo(
		() =>
			getBuyerCompanySettingValue(userType)(
				'assetConfig.allowAreaAssociationWithAssets',
				companyConfig,
				currentUser,
				false
			),
		[companyConfig, currentUser, userType]
	);

	return (
		<Modal
			visible={true}
			title="Transfer to location"
			onCancel={onCancel}
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button
					type="primary"
					size="large"
					style={{ marginLeft: '16px' }}
					key="submit"
					htmlType="submit"
					form={FORM_NAME}
					loading={transferring}
				>
					Transfer
				</Button>,
			]}
			closable={true}
		>
			<Form
				id={FORM_NAME}
				layout="vertical"
				onFinish={handleSubmit}
				initialValues={initialValues}
				form={form}
			>
				<Form.Item
					name="locationId"
					label="To location"
					rules={[{ required: true, message: 'Location is required!' }]}
				>
					<OWAsyncSelect
						stateSlice={locations}
						targetCollectionName="assetTransferTargetCollection"
						fetchMultiple={(ids, targetCollectionName) => {
							fetchMultipleLocations(ids, targetCollectionName);
						}}
						fetchData={(search, targetCollectionName) => {
							fetchLocations({ search }, targetCollectionName);
						}}
						renderRecord={(loc) => (
							<Select.Option key={loc.id} value={loc.id}>
								{loc.name}
							</Select.Option>
						)}
					/>
				</Form.Item>
				{allowArea && locationId ? (
					<Form.Item name="areaId" label="Area">
						<OWAsyncSelect
							stateSlice={areas}
							targetCollectionName="assetTransferTargetCollection"
							fetchMultiple={fetchMultipleAreas}
							fetchData={(search, ...args) => {
								fetchAreas({ search }, ...args);
							}}
							additionalFilters={{ locationId }}
							renderRecord={(loc) => (
								<Select.Option key={loc.id} value={loc.id} disabled={loc.id === asset.locationId}>
									{loc.name}
								</Select.Option>
							)}
						/>
					</Form.Item>
				) : null}
				<TransferAssetFormFields />
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	locations: state.locations,
	areas: state.areas,
	currentUser: state.session.currentUser,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchLocations: (params, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.readLite(params, targetCollectionName)
				: locationsRestCrudThunksForBuyer.readLite(params, targetCollectionName)
		),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: locationsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchAreas: (params, ...args) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? areasRestCrudThunksForSupplier.read(params, ...args)
				: areasRestCrudThunksForBuyer.read(params, ...args)
		),
	fetchMultipleAreas: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? areasRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: areasRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	moveAssetToLocation: (assetId, data) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? moveAssetToLocationForSupplier(assetId, data)
				: moveAssetToLocationForBuyer(assetId, data)
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(TransferAssetToLocationForm)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

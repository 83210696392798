import { getBackendUri } from '../utils/EnvConfigUtils';
import FetchUtils, { parseJSON } from './fetch_utils';
import { logoutSuccess } from '../actions/session_actions';
import {
	fetchSpendByVendorStart,
	fetchSpendByVendorSuccess,
	fetchSpendByVendorFailure,
	fetchSpendByCategoryStart,
	fetchSpendByCategorySuccess,
	fetchSpendByCategoryFailure,
	fetchSpendByFacilityStart,
	fetchSpendByFacilitySuccess,
	fetchSpendByFacilityFailure,
	fetchSpendByMonthStart,
	fetchSpendByMonthSuccess,
	fetchSpendByMonthFailure,
	fetchSpendByLocationSuccess,
	fetchSpendByLocationFailure,
	fetchSpendByLocationStart,
	fetchSpendByWeekStart,
	fetchSpendByWeekSuccess,
	fetchSpendByWeekFailure,
	fetchSpendByDayStart,
	fetchSpendByDaySuccess,
	fetchSpendByProblemTypeStart,
	fetchSpendByProblemTypeSuccess,
	fetchSpendByProblemTypeFailure,
	fetchSpendByRegionStart,
	fetchSpendByRegionSuccess,
	fetchSpendByRegionFailure,
} from '../actions/spend_analytics_actions';
import { crushFilters, formatDateParameters } from '../utils/DataAccessUtils';

const fetchUtils = new FetchUtils();
const baseUrl = getBackendUri();
const apiUrl = (userType) => `/api/v1/${userType}/invoice/invoice_reportings`;

const fetchSpendData =
	(startAction, successAction, failureAction, apiSubstring) =>
	(userType) =>
	(p, filters = null, pagination = null, sorting = null) => {
		let params = { ...p };
		// if pagination present, transform into limit/offset format and add to query params
		const startDate = params.startDate;
		const endDate = params.endDate;
		if (!startDate || !endDate) {
			return (dispatch) => {
				new Promise((resolve, reject) => {
					resolve([]);
				});
			};
		}

		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		let queryUri = baseUrl + apiUrl(userType) + '/' + apiSubstring;

		if (startDate) {
			queryUri += `/${startDate.format('YYYY-MM-DD')}`;
		}

		if (endDate) {
			queryUri += `/${endDate.format('YYYY-MM-DD')}`;
		}

		delete params.startDate;
		delete params.endDate;

		params = formatDateParameters(params);

		if (params.locationIds && params.locationIds.length === 0) {
			delete params.locationIds;
		}
		if (params.supplierFacilityIds && params.supplierFacilityIds.length === 0) {
			delete params.supplierFacilityIds;
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(startAction());

				fetchUtils
					.get(
						queryUri,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							crushFilters(params)
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (!ok || d.status === 'error') {
							dispatch(failureAction([d.message]));
							reject(d.message);
						} else {
							dispatch(successAction(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(failureAction([d.message]));
						reject(d.message);
					});
			});
		};
	};

export const fetchSpendDataByMonth = fetchSpendData(
	fetchSpendByMonthStart,
	fetchSpendByMonthSuccess,
	fetchSpendByMonthFailure,
	'invoice_monthly_cost'
);
export const fetchSpendDataByWeek = fetchSpendData(
	fetchSpendByWeekStart,
	fetchSpendByWeekSuccess,
	fetchSpendByWeekFailure,
	'invoice_weekly_cost'
);
export const fetchSpendDataByDay = fetchSpendData(
	fetchSpendByDayStart,
	fetchSpendByDaySuccess,
	fetchSpendByWeekFailure,
	'invoice_daily_cost'
);
export const fetchSpendDataByCategory = fetchSpendData(
	fetchSpendByCategoryStart,
	fetchSpendByCategorySuccess,
	fetchSpendByCategoryFailure,
	'invoice_costs_by_spend_category'
);
export const fetchSpendDataByProblemType = fetchSpendData(
	fetchSpendByProblemTypeStart,
	fetchSpendByProblemTypeSuccess,
	fetchSpendByProblemTypeFailure,
	'invoice_costs_by_problem_type'
);
export const fetchSpendDataByProblemTypeWithChildren = fetchSpendData(
	fetchSpendByProblemTypeStart,
	fetchSpendByProblemTypeSuccess,
	fetchSpendByProblemTypeFailure,
	'invoice_costs_by_problem_type_with_children'
);
export const fetchSpendDataByVendor = fetchSpendData(
	fetchSpendByVendorStart,
	fetchSpendByVendorSuccess,
	fetchSpendByVendorFailure,
	'invoice_costs_by_supplier_facility'
);
export const fetchSpendDataByLocation = fetchSpendData(
	fetchSpendByLocationStart,
	fetchSpendByLocationSuccess,
	fetchSpendByLocationFailure,
	'invoice_costs_by_location'
);
export const fetchSpendDataByLocationWithRegions = fetchSpendData(
	fetchSpendByLocationStart,
	fetchSpendByLocationSuccess,
	fetchSpendByLocationFailure,
	'invoice_costs_by_location_with_regions'
);
export const fetchSpendDataByRegion = fetchSpendData(
	fetchSpendByRegionStart,
	fetchSpendByRegionSuccess,
	fetchSpendByRegionFailure,
	'invoice_costs_by_region'
);
export const fetchSpendDataByFacility = fetchSpendData(
	fetchSpendByFacilityStart,
	fetchSpendByFacilitySuccess,
	fetchSpendByFacilityFailure,
	'invoice_costs_by_buyer_facility'
);

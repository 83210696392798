import { USERS_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/users_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = standardInitialStateGenerator([
	'usersIndex',
	'quotesAdvancedFilters',
	'PRAdvancedFilters',
	'purchaseOrdersAdvancedFilters',
	'purchaseRequestsAdvancedFilters',
	'impairmentAdjustmentFilters',
	'poLineItemsAdvancedFilters',
	'prLineItemsAdvancedFilters',
]);
export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState);

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Card, message, Drawer, Button } from 'antd';
import { getObjectValues, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { getBuyerCompanyPrivateNetworksWithLocationsForSupplier } from '../../thunks/supplier_private_networks_thunks';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import {
	addSupplierDispatcherCCs,
	dispatchersRestCrudThunksForSupplier,
	dispatchersRestCrudThunksForSupplierBulk,
	removeSupplierDispatcherCCs,
} from '../../thunks/dispatchers_thunks';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import TableWrapperComponent from '../table_wrapper_component/TableWrapperComponent';
import { fetchFacilityActiveUsers } from '../../thunks/users_thunks';
import { supplierContactsRestCrudThunksForSupplier } from '../../thunks/supplier_contacts_thunks';
import { fieldTechsRestCrudThunksForSupplier } from '../../thunks/field_techs_thunks';
import defaultTablePagination from '../pagination_component/PaginationComponent';
import SupplierLocationPageDisplay from '../supplier_location_page_display/SupplierLocationPageDisplay';
import { UserName } from '../name_component/user/UserNameComponent';
import { UserNameList } from '../name_component/user/UserNameList';
import LocationPageDisplay from '../location_page_display/LocationPageDisplay';
import { CloseOutlined } from '@ant-design/icons';

const { Content } = Layout;

require('./SupplierClientsIndexPage.less');
let self;

class SupplierClientsIndexPage extends React.Component<any, any> {
	formRefs: any;
	constructor(props) {
		super(props);
		self = this;
		let defaultPagination = new defaultTablePagination(self);
		this.state = {
			supplierLocationDetail: null,
			initialLoading: true,
			selectedRowKeys: [],
			reloadingTable: false,
			currentRecord: null,
			showLocation: false,
			pagination: defaultPagination,
		};
	}
	componentDidMount() {
		const { fetchLocationDispatchers } = this.props;
		fetchLocationDispatchers({})
			.then((res) => {
				this.setState({ initialLoading: false });
			})
			.catch((res) => this.setState({ initialLoading: false }));
	}
	updateType(type) {
		switch (type) {
			case 'add':
				return this.handleAdd;
			case 'remove':
				return this.handleRemove;
			case 'update':
				return this.handleUpdate;
		}
	}
	handleAdd(field, values, records, aself) {
		const { addDispatcherCCs, currentUser, fetchLocationDispatchers } = self.props;
		let locationDispatcherList = [];
		const ids = records.map((record) => record.id).join(',');
		const entity = { ccList: values };
		records.map((record) => {
			const locationDispatcher = {
				id: record.id,
				buyerCompanyId: record.location.buyerCompanyId,
				buyerFacilityId: record.buyerFacilityId,
				supplierFacilityId: nullSafeGet('facility.id', currentUser),
				locationId: record.locationId,
				dispatcher: field === 'dispatcher' ? values : record.dispatcher,
				primaryTech: field === 'primaryTech' ? values : record.primaryTech,
				ccList: field === 'ccList' ? values : record.ccList,
				locationIds: record.locationIds,
			};
			locationDispatcherList.push(locationDispatcher);
		});
		message.open({
			key: 'loadingMessage',
			type: 'loading',
			duration: 60,
			content: `${records.length} Record${records.length > 1 ? 's' : ''} Updating...`,
		});
		addDispatcherCCs(ids, entity)
			.then(() => {
				message.success(`${records.length} Record${records.length > 1 ? 's' : ''} Updated`);
				fetchLocationDispatchers({});
				aself.props.hideModal();
			})
			.catch(() => {
				message.error(`Failed to update ${records.length} record${records.length > 1 ? 's' : ''}`);
			})
			.finally(() => {
				aself.setState({ buttonLoading: false });
				message.destroy('loadingMessage');
			});
	}
	handleRemove(field, values, records, aself) {
		const { removeDispatcherCCs, currentUser, fetchLocationDispatchers } = self.props;
		let locationDispatcherList = [];
		const ids = records.map((record) => record.id).join(',');
		const entity = { ccList: values };
		records.map((record) => {
			const locationDispatcher = {
				id: record.id,
				buyerCompanyId: record.location.buyerCompanyId,
				buyerFacilityId: record.buyerFacilityId,
				supplierFacilityId: nullSafeGet('facility.id', currentUser),
				locationId: record.locationId,
				dispatcher: field === 'dispatcher' ? values : record.dispatcher,
				primaryTech: field === 'primaryTech' ? values : record.primaryTech,
				ccList: field === 'ccList' ? values : record.ccList,
				locationIds: record.locationIds,
			};
			locationDispatcherList.push(locationDispatcher);
		});

		message.open({
			key: 'loadingMessage',
			type: 'loading',
			duration: 60,
			content: `${records.length} Record${records.length > 1 ? 's' : ''} Updating...`,
		});
		removeDispatcherCCs(ids, entity)
			.then(() => {
				message.success(`${records.length} Record${records.length > 1 ? 's' : ''} Updated`);
				fetchLocationDispatchers({});
				aself.props.hideModal();
			})
			.catch(() => {
				message.error(`Failed to update ${records.length} record${records.length > 1 ? 's' : ''}`);
			})
			.finally(() => {
				aself.setState({ buttonLoading: false });
				message.destroy('loadingMessage');
			});
	}
	handleUpdate(field, values, records, aself) {
		const { updateLocationDispatcher, currentUser, fetchLocationDispatchers } = self.props;
		let locationDispatcherList = [];
		records.map((record) => {
			const locationDispatcher = {
				id: record.id,
				buyerCompanyId: record.location.buyerCompanyId,
				buyerFacilityId: record.buyerFacilityId,
				supplierFacilityId: nullSafeGet('facility.id', currentUser),
				locationId: record.locationId,
				dispatcher: field === 'dispatcher' ? values : record.dispatcher,
				primaryTech: field === 'primaryTech' ? values : record.primaryTech,
				ccList: field === 'ccList' ? values : record.ccList,
				locationIds: record.locationIds,
			};
			locationDispatcherList.push(locationDispatcher);
		});
		message.open({
			key: 'loadingMessage',
			type: 'loading',
			duration: 60,
			content: `${records.length} Record${records.length > 1 ? 's' : ''} Updating...`,
		});
		updateLocationDispatcher(locationDispatcherList)
			.then(() => {
				this.setState({ locationDispatcherUpdateVisible: false });
				message.success(`${records.length} Record${records.length > 1 ? 's' : ''} Updated`);
				fetchLocationDispatchers({});
				aself.props.hideModal();
			})
			.catch(() => {
				message.error(`Failed to update ${records.length} record${records.length > 1 ? 's' : ''}`);
			})
			.finally(() => {
				aself.setState({ buttonLoading: false });
				message.destroy('loadingMessage');
			});
	}

	onSelectChange = (newSelectedRowKeys) => {
		this.setSelectedRowKeys(newSelectedRowKeys);
	};
	setSelectedRowKeys = (newSelectedRowKeys) => {
		this.setState({ selectedRowKeys: newSelectedRowKeys });
	};
	showLocation = (record) => {
		return {
			onClick: (event) => {
				this.setState({ currentRecord: record, showLocation: true });
			},
		};
	};
	hideLocation = (event) => {
		this.setState({ showLocation: false });
	};

	render() {
		const { dispatchers } = this.props;
		const { selectedRowKeys } = this.state;
		let privateNetworkLocations = getObjectValues(dispatchers.records).map((record) => {
			return { ...record };
		});
		const columns = [
			{
				title: 'Company',
				dataIndex: 'displayName',
				render: (text, record) => (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Ellipsis tooltip={true} length={64}>
							{nullSafeGetOrElse(
								'location.buyerCompany.displayName',
								record,
								nullSafeGet('location.buyerCompany.name', record)
							)}
						</Ellipsis>
					</div>
				),
				props: {
					useDefault: true,
				},
			},
			{
				title: 'Location',
				dataIndex: 'name',
				render: (text, record) => (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Ellipsis tooltip={true} length={64}>
							{nullSafeGet('location.name', record)}
						</Ellipsis>
					</div>
				),
				props: {
					useDefault: true,
				},
			},
			{
				title: 'Customer Contact',
				dataIndex: 'facilityInCharge',
				render: (text, record) =>
					nullSafeGetOrElse('location.facilityInChargeContacts', record, []).length > 0 ? (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginTop: -12,
								marginBottom: -12,
								gap: 8,
							}}
						>
							<UserNameList
								title={'Customer Contacts'}
								contacts={record.location.facilityInChargeContacts}
								mode={'card'}
								userType={'supplier'}
							/>
						</div>
					) : (
						<div style={{ display: 'flex', alignItems: 'center' }}>{'--'}</div>
					),
				props: {
					useDefault: true,
				},
				filterSearch: true,
			},
			{
				title: 'Dispatcher',
				dataIndex: 'dispatcher',
				render: (text, record) =>
					record.dispatcherContact ? (
						<div style={{ marginTop: -12, marginBottom: -12 }}>
							<UserName
								contact={nullSafeGet('dispatcherContact', record)}
								mode={'card'}
								userType={'supplier'}
							/>
						</div>
					) : record.dispatcher ? (
						<Ellipsis tooltip={true} fullWidthRecognition={true} lines={1}>
							{record.dispatcher}
						</Ellipsis>
					) : (
						'--'
					),
				props: {
					useDefault: true,
					update: {
						canUpdate: true,
						updateMethod: (type) => this.updateType(type),
						stateSlice: this.props.supplierContacts,
						fetchData: this.props.fetchSupplierContacts,
						fetchMultiple: this.props.fetchMultipleSupplierContacts,
						targetCollectionName: 'dispatcherSupplierContacts',
						type: 'single',
					},
				},
			},
			{
				title: 'Primary Technician',
				dataIndex: 'primaryTech',
				render: (text, record) =>
					record.primaryTechContact ? (
						<div
							style={{ display: 'flex', alignItems: 'center', marginTop: -12, marginBottom: -12 }}
						>
							<UserName
								contact={nullSafeGet('primaryTechContact', record)}
								mode={'card'}
								userType={'supplier'}
							/>
						</div>
					) : record.primaryTech ? (
						<Ellipsis tooltip={true} fullWidthRecognition={true} lines={1}>
							{record.primaryTech}
						</Ellipsis>
					) : (
						'--'
					),

				props: {
					useDefault: true,
					update: {
						canUpdate: true,
						updateMethod: (type) => this.updateType(type),
						stateSlice: this.props.fieldTechs,
						fetchData: this.props.fetchFieldTechs,
						fetchMultiple: this.props.fetchMultipleFieldTechs,
						targetCollectionName: 'primaryTechForLocation',
						type: 'single',
					},
				},
			},
			{
				title: 'CCs',
				dataIndex: 'ccList',
				render: (text, record) =>
					nullSafeGetOrElse('ccListContacts', record, []).length > 0 ? (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginTop: -12,
								marginBottom: -12,
								gap: 8,
							}}
						>
							<UserNameList
								title={'CCs'}
								contacts={record.ccListContacts}
								mode={'avatar'}
								userType={'supplier'}
							/>
						</div>
					) : (
						<div style={{ display: 'flex', alignItems: 'center' }}>{'--'}</div>
					),

				props: {
					useDefault: true,
					update: {
						canUpdate: true,
						updateMethod: (type) => this.updateType(type),
						stateSlice: this.props.supplierContacts,
						fetchData: this.props.fetchSupplierContacts,
						fetchMultiple: this.props.fetchMultipleSupplierContacts,
						targetCollectionName: 'ccListSupplierContacts',
						type: 'multiple',
					},
				},
			},
		];

		return (
			<Content className="yourClientsPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited supplier clients index page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				{this.state.initialLoading ? (
					<PageLoadingPlaceholder />
				) : (
					<div>
						<Row style={{ margin: '0.5em -8px' }} gutter={16}>
							<Col span={24}>
								<Card bodyStyle={{ padding: 8 }}>
									<TableWrapperComponent
										columns={columns}
										showHeader={true}
										selectedRows={selectedRowKeys}
										loading={dispatchers.loading}
										dataSource={privateNetworkLocations}
										onSelectChange={this.onSelectChange}
										currentUser={this.props.currentUser}
										pagination={this.state.pagination}
										onRow={this.showLocation}
									/>
								</Card>
							</Col>
						</Row>
						<Drawer
							width={1000}
							placement="right"
							closable={false}
							onClose={this.hideLocation}
							visible={this.state.showLocation}
							bodyStyle={{ paddingBottom: 0 }}
						>
							<div
								//Header
								className={'absolute top-0 right-0 z-40 flex flex-row'}
							>
								<Button onClick={this.hideLocation} type={'text'} style={{ padding: '8px' }}>
									<CloseOutlined translate="" style={{ fontSize: '24px', color: 'white' }} />
								</Button>
							</div>
							<LocationPageDisplay
								record={nullSafeGet('location', this.state.currentRecord)}
								locationFetching={false}
							/>
						</Drawer>
					</div>
				)}
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	currentUser: state.session.currentUser,
	supplierContacts: state.supplier_contacts,
	creating: state.supplier_contacts.creating,
	dispatchers: state.dispatchers,
	updating: state.supplier_contacts.updating,
	fieldTechs: state.field_techs,
	supplierUsers: state.supplier_users,
	privateNetworkBuyerCompany: state.supplier_private_networks,
});

const mapDispatchToProps = (dispatch) => ({
	fetchBuyerCompanyWithLocations: (params) =>
		dispatch(getBuyerCompanyPrivateNetworksWithLocationsForSupplier(params)),
	fetchLocationDispatchers: (params) => dispatch(dispatchersRestCrudThunksForSupplier.read(params)),
	updateLocationDispatcher: (entity) =>
		dispatch(dispatchersRestCrudThunksForSupplierBulk.update(entity)),
	addDispatcherCCs: (ids, entity) => dispatch(addSupplierDispatcherCCs(ids, entity)),
	removeDispatcherCCs: (ids, entity) => dispatch(removeSupplierDispatcherCCs(ids, entity)),
	fetchSupplierUsers: () => dispatch(fetchFacilityActiveUsers('supplier')()),
	fetchSupplierContacts: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			supplierContactsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleSupplierContacts: (ids, targetCollectionName) =>
		dispatch(supplierContactsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchFieldTechs: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			fieldTechsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleFieldTechs: (ids, targetCollectionName) =>
		dispatch(fieldTechsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(SupplierClientsIndexPage)
);

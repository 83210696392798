export const FETCH_BID_COMPARISON_START = 'FETCH_BID_COMPARISON_START';
export function fetchBidComparisonStart(sourcingEventId, supplierFacilityId) {
	return {
		type: FETCH_BID_COMPARISON_START,
		sourcingEventId,
		supplierFacilityId,
	};
}

export const FETCH_BID_COMPARISON_SUCCESS = 'FETCH_BID_COMPARISON_SUCCESS';
export function fetchBidComparisonSuccess(bidDetails) {
	return {
		type: FETCH_BID_COMPARISON_SUCCESS,
		bidDetails: [bidDetails],
	};
}

export const FETCH_BID_COMPARISON_ERROR = 'FETCH_BID_COMPARISON_ERROR';
export function fetchBidComparisonError(error) {
	return {
		type: FETCH_BID_COMPARISON_ERROR,
		error,
	};
}
export const FETCH_QUESTIONNAIRES_FOR_SOURCING_EVENT_START =
	'FETCH_QUESTIONNAIRES_FOR_SOURCING_EVENT_START';
export function fetchQuestionnairesForSourcingEventStart() {
	return {
		type: FETCH_QUESTIONNAIRES_FOR_SOURCING_EVENT_START,
	};
}

export const FETCH_QUESTIONNAIRES_FOR_SOURCING_EVENT_SUCCESS =
	'FETCH_QUESTIONNAIRES_FOR_SOURCING_EVENT_SUCCESS';
export function fetchQuestionnairesForSourcingEventSuccess(questionnaires) {
	return {
		type: FETCH_QUESTIONNAIRES_FOR_SOURCING_EVENT_SUCCESS,
		questionnaires,
	};
}

export const FETCH_QUESTIONNAIRES_FOR_SOURCING_EVENT_ERROR =
	'FETCH_QUESTIONNAIRES_FOR_SOURCING_EVENT_ERROR';
export function fetchQuestionnairesForSourcingEventError(error) {
	return {
		type: FETCH_QUESTIONNAIRES_FOR_SOURCING_EVENT_ERROR,
		error,
	};
}

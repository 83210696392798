export const SUPER_ADMIN = 'SUPER_ADMIN';
export const BUYER_ADMIN = 'BUYER_ADMIN';
export const SUPPLIER_ADMIN = 'SUPPLIER_ADMIN';
export const SUPPLIER_TECH = 'SUPPLIER_TECH';
export const BUYER_USER = 'BUYER_USER';
export const SUPPLIER_USER = 'SUPPLIER_USER';
export const BUYER_STORE_ASSOCIATE = 'BUYER_STORE_ASSOCIATE';
export const SUPPLIER_MANAGER = 'SUPPLIER_MANAGER';
export const SUPPLIER_INVENTORY_MANAGER = 'SUPPLIER_INVENTORY_MANAGER';
export const SUPPLIER_ACCOUNTING_MANAGER = 'SUPPLIER_ACCOUNTING_MANAGER';

// TODO: Move POJO of available roles to backend
export const ROLES = {
	[SUPER_ADMIN]: {
		displayName: 'Super Admin',
		id: SUPER_ADMIN,
	},
	[BUYER_ADMIN]: {
		displayName: 'Admin',
		id: BUYER_ADMIN,
	},
	[BUYER_USER]: {
		displayName: 'Manager',
		id: BUYER_USER,
	},
	[BUYER_STORE_ASSOCIATE]: {
		displayName: 'Store Associate',
		id: BUYER_STORE_ASSOCIATE,
	},
	[SUPPLIER_ADMIN]: {
		displayName: 'Admin',
		id: SUPPLIER_ADMIN,
	},
	[SUPPLIER_TECH]: {
		displayName: 'Field Tech',
		id: SUPPLIER_TECH,
	},
	[SUPPLIER_USER]: {
		displayName: 'User',
		id: SUPPLIER_USER,
	},
	[SUPPLIER_MANAGER]: {
		displayName: 'Manager',
		id: SUPPLIER_MANAGER,
	},
	[SUPPLIER_INVENTORY_MANAGER]: {
		displayName: 'Inventory Manager',
		id: SUPPLIER_INVENTORY_MANAGER,
	},
	[SUPPLIER_ACCOUNTING_MANAGER]: {
		displayName: 'Accounting Manager',
		id: SUPPLIER_ACCOUNTING_MANAGER,
	},
};

export const ALL_ROLES = new Set([
	SUPER_ADMIN,
	BUYER_ADMIN,
	BUYER_STORE_ASSOCIATE,
	BUYER_USER,
	SUPPLIER_ADMIN,
	SUPPLIER_USER,
	SUPPLIER_MANAGER,
	SUPPLIER_TECH,
]);
export const SUPER_ADMIN_ONLY = new Set([SUPER_ADMIN]);
export const BUYER_ADMIN_ONLY = new Set([SUPER_ADMIN, BUYER_ADMIN]);
export const BUYER_ONLY_NO_USER = new Set([SUPER_ADMIN, BUYER_ADMIN]);
export const BUYER_ONLY_NO_STORE_ASSOCIATE = new Set([SUPER_ADMIN, BUYER_ADMIN, BUYER_USER]);
export const BUYER_ONLY = new Set([SUPER_ADMIN, BUYER_ADMIN, BUYER_USER, BUYER_STORE_ASSOCIATE]);
export const SUPPLIER_ADMIN_ONLY = new Set([SUPER_ADMIN, SUPPLIER_ADMIN]);
export const SUPPLIER_ONLY_NO_USER = new Set([SUPER_ADMIN, SUPPLIER_ADMIN, SUPPLIER_MANAGER]);
export const SUPPLIER_ASSIGNABLE_ROLES = new Set([
	SUPPLIER_TECH,
	SUPPLIER_ADMIN,
	SUPPLIER_USER,
	SUPPLIER_MANAGER,
	SUPPLIER_INVENTORY_MANAGER,
	SUPPLIER_ACCOUNTING_MANAGER,
]);

export const SUPPLIER_ONLY = new Set([
	SUPER_ADMIN,
	SUPPLIER_TECH,
	SUPPLIER_ADMIN,
	SUPPLIER_USER,
	SUPPLIER_MANAGER,
	SUPPLIER_INVENTORY_MANAGER,
	SUPPLIER_ACCOUNTING_MANAGER,
]);

export const BUYER_SUPPLIER_ADMIN_ROLES = [BUYER_ADMIN, SUPPLIER_ADMIN, SUPER_ADMIN];

export const authorizedRolesCombinations = {
	SUPER_ADMIN_ONLY,
	ALL_ROLES,
	BUYER_ADMIN_ONLY,
	BUYER_ONLY_NO_USER,
	BUYER_ONLY_NO_STORE_ASSOCIATE,
	BUYER_ONLY,
	SUPPLIER_ADMIN_ONLY,
	SUPPLIER_ONLY_NO_USER,
	SUPPLIER_ONLY,
};

export const checkAuthorization =
	(authorizedRoles: Set<string>) =>
	(roles: string[]): boolean =>
		roles && roles.some((role) => authorizedRoles.has(role));
export const checkCompanyPermission =
	(unauthorizedCompanies: Set<number>) =>
	(companyId: number): boolean => {
		if (!unauthorizedCompanies || unauthorizedCompanies.size == 0) {
			return true;
		} else {
			return !unauthorizedCompanies.has(companyId);
		}
	};

export const BUYER_ROLES = Object.keys(ROLES).reduce((acc, r) => {
	if (BUYER_ONLY.has(r) && r !== SUPER_ADMIN) acc[r] = ROLES[r];
	return acc;
}, {});

export const SUPPLIER_ROLES = Object.keys(ROLES).reduce((acc, r) => {
	if (SUPPLIER_ONLY.has(r) && r !== SUPER_ADMIN) acc[r] = ROLES[r];
	return acc;
}, {});

import React, { FC, useMemo } from 'react';
import { Row, Button, Modal, Alert } from 'antd';
import { CloudDownloadOutlined, PlusCircleFilled, WarningOutlined } from '@ant-design/icons';
import { FlatfileButton } from '@flatfile/react';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { FLAT_FILE_LICENSE_KEY, WARNING_BUTTON_WAIT_TIME } from '../../utils/DataConstants';
import ButtonWithTimer from '../common/ButtonWithTimer';

const UploadWithSampleModal: FC<any> = ({
	type,
	title,
	onCancel,
	onDownloadSample,
	downloading,
	currentUser,
	fields,
	onDataLoad,
	sampleFileText,
	downloadText,
	showWarningMessage,
	onRecordInit,
	onRecordChange,
}): React.ReactElement => {
	const flatFileButton = useMemo(
		() => (
			<FlatfileButton
				settings={{
					type,
					allowCustom: true,
					autoDetectHeaders: true,
					disableManualInput: true,
					displayEncoding: false,
					fields,
					i18nOverrides: {
						en: {
							otherLocales: ['en-US', 'en-CA', 'en-GB'],
							overrides: {
								header: `Upload {{thing}}`,
								header2: `Upload {{thing}}`,
								success: `${type} updated successfully!`,
							},
						},
					},
				}}
				licenseKey={FLAT_FILE_LICENSE_KEY}
				onCancel={onCancel}
				customer={{
					companyId: nullSafeGet('facility.buyerCompanyId', currentUser),
					companyName: nullSafeGet('facility.name', currentUser),
					userId: currentUser.email,
				}}
				onData={onDataLoad}
				onRecordInit={onRecordInit}
				onRecordChange={onRecordChange}
				render={(importer, launch) =>
					showWarningMessage ? (
						<ButtonWithTimer
							icon={<PlusCircleFilled translate="" />}
							style={{ marginTop: '12px', width: '100%' }}
							disableUntilSeconds={WARNING_BUTTON_WAIT_TIME}
							onClick={launch}
						>
							Proceed to Upload
						</ButtonWithTimer>
					) : (
						<Button
							icon={<PlusCircleFilled translate="" />}
							style={{ marginTop: '12px', width: '100%' }}
							onClick={launch}
						>
							Proceed to Upload
						</Button>
					)
				}
			/>
		),
		[
			currentUser,
			fields,
			onCancel,
			onDataLoad,
			onRecordChange,
			onRecordInit,
			showWarningMessage,
			type,
		]
	);

	return (
		<Modal visible={true} title={title} closable onCancel={onCancel} forceRender footer={null}>
			<Row>{downloadText || 'Do you want to download a sample file?'}</Row>
			{showWarningMessage ? (
				<>
					<div className="mt-2">
						<Alert message="You will lose data if you do not read this carefully." type="error" />
					</div>
					<div className="mt-4 flex flex-row items-center">
						<WarningOutlined translate="" />
						<div className="ml-2 text-gray-400 ">
							{`Please ensure that you always upload the entire ${type} list. You can download the ${type} list here to make updates, addition/deletion.`}
						</div>
					</div>
				</>
			) : null}
			<Row>
				<Button
					type="primary"
					style={{ marginTop: '24px', width: '100%' }}
					loading={downloading}
					onClick={onDownloadSample}
				>
					<CloudDownloadOutlined translate="" />
					{sampleFileText || 'Download Sample File'}
				</Button>
			</Row>
			<Row>{flatFileButton}</Row>
		</Modal>
	);
};

const mapStateToProps = (state, ownProps) => ({
	currentUser: state.session.currentUser,
});

export default withRouter<any, any>(connect(mapStateToProps)(UploadWithSampleModal));

import * as React from 'react';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import {
	getWorkOrderBasePriorityBackgroundColor,
	dateFormatter,
	STOPLIGHT_COLORS,
	BLACKS_AND_WHITES,
} from '../../utils/DataFormatterUtils';
import { ContactAvatar } from '../contact_avatar/ContactAvatar';
import { ROLE_TYPES } from '../../utils/DataConstants';
import WorkOrderStatusDisplay from '../work_order_status_display/WorkOrderStatusDisplay';
import { Link } from 'react-router-dom';

const moment = require('moment');
const style = require('./ServiceCallPopoverDisplay.less');

export const ServiceCallPopoverDisplay: React.FC<any> = ({ serviceCall }) => {
	if (!serviceCall) {
		return null;
	}

	let categoryBackgroundColor;
	switch (nullSafeGet('workOrder.displayStatus', serviceCall)) {
		case 'Pending':
			categoryBackgroundColor = '#4a1e87';
			break;
		case 'Open':
			categoryBackgroundColor = STOPLIGHT_COLORS.pureRed;
			break;
		case 'InProgress':
			categoryBackgroundColor = 'rgb(11, 28, 111)';
			break;
		case 'OnHold':
			categoryBackgroundColor = 'rgb(137, 107, 12)';
			break;
		case 'Completed':
			categoryBackgroundColor = STOPLIGHT_COLORS.pureGreen;
			break;
		case 'Cancelled':
			categoryBackgroundColor = BLACKS_AND_WHITES.blackPrimary;
			break;
		default:
			categoryBackgroundColor = BLACKS_AND_WHITES.blackPrimary;
	}
	return (
		<div className="serviceCallPopoverDisplay__row">
			{/*<div className="serviceCallPopoverDisplay__headerWrapper">*/}
			{/*    <Button*/}
			{/*        onClick={() => {*/}
			{/*        }}*/}
			{/*        type="link"*/}
			{/*        size="large"*/}
			{/*    >*/}
			{/*        <i className="icons8-font icons8-edit"/>*/}
			{/*    </Button>*/}
			{/*    <Button*/}
			{/*        onClick={() => {*/}
			{/*        }}*/}
			{/*        type="link"*/}
			{/*        size="large"*/}
			{/*    >*/}
			{/*        <i className="icons8-font icons8-cancel"/>*/}
			{/*    </Button>*/}
			{/*    <Button*/}
			{/*        onClick={() => {*/}
			{/*        }}*/}
			{/*        type="link"*/}
			{/*        size="large"*/}
			{/*    >*/}
			{/*        <i className="icons8-font icons8-"/>*/}
			{/*    </Button>*/}
			{/*</div>*/}
			<div className="serviceCallPopoverDisplay__bodyWrapper">
				<div
					className="materialCard__primaryTitle materialCard__flexCentVert"
					style={{ margin: 0, paddingTop: 8, paddingBottom: 8, alignItems: 'flex-start' }}
				>
					<div
						className="serviceCallPopoverDisplay__iconLabel"
						style={{
							height: 36,
							marginRight: 16,
							marginTop: 5,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'flex-start',
						}}
					>
						<div
							style={{
								background: categoryBackgroundColor,
								height: 17,
								width: 17,
								borderRadius: 5,
							}}
						></div>
					</div>
					<div>
						<Link
							to={`/supplier/workOrders/detail/${nullSafeGet('workOrder.id', serviceCall)}/details`}
						>
							<h5
								style={{
									marginBottom: 4,
									lineHeight: 1.3,
								}}
							>
								{nullSafeGet('workOrder.title', serviceCall)}
							</h5>
						</Link>
						{dateFormatter(serviceCall.serviceScheduledAt, 'dddd, MMMM D')} at{' '}
						{dateFormatter(serviceCall.serviceScheduledAt, 'h:mm a')}
					</div>
				</div>
				<div
					className="serviceCallPopoverDisplay__text materialCard__supportingText materialCard__flexCentVert"
					style={{ paddingTop: 8, paddingBottom: 8 }}
				>
					<div className="serviceCallPopoverDisplay__text serviceCallPopoverDisplay__iconLabel">
						<i style={{ fontSize: 20 }} className={`icons8-font icons8-location-logo-2`} />
					</div>
					<div className="serviceCallPopoverDisplay__text">
						{nullSafeGet('workOrder.location.name', serviceCall)}
					</div>
				</div>
				<div
					className="serviceCallPopoverDisplay__text materialCard__supportingText materialCard__flexCentVert"
					style={{ paddingTop: 8, paddingBottom: 8 }}
				>
					<div className="serviceCallPopoverDisplay__text serviceCallPopoverDisplay__iconLabel">
						<i style={{ fontSize: 20 }} className={`icons8-font icons8-company-2`} />
					</div>
					<div className="serviceCallPopoverDisplay__text">
						{nullSafeGet('supplierFacility.displayName', serviceCall)}
					</div>
				</div>
				<div
					className="serviceCallPopoverDisplay__text materialCard__supportingText materialCard__flexCentVert"
					style={{ paddingTop: 8, paddingBottom: 8 }}
				>
					<div className="serviceCallPopoverDisplay__text serviceCallPopoverDisplay__iconLabel">
						<i style={{ fontSize: 20 }} className={`icons8-font icons8-worker`} />
					</div>
					{nullSafeGet('leadTechnicianEmailContact.nameGiven', serviceCall) ? (
						<div className="serviceCallPopoverDisplay__text">
							{nullSafeGetOrElse('additionalTechnicians', serviceCall, []).length > 0 ? (
								<div style={{ marginBottom: 8 }}>
									{nullSafeGetOrElse('additionalTechnicians', serviceCall, []).length + 1}{' '}
									technicians
								</div>
							) : null}
							<div
								className="materialCard__primaryTitle"
								style={{
									marginBottom: 0,
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<ContactAvatar
									size="small"
									hidePopover={true}
									style={{ marginRight: 8 }}
									contact={nullSafeGet('leadTechnicianEmailContact', serviceCall)}
									userType={ROLE_TYPES.BUYER}
								/>
								<div>
									{nullSafeGet('leadTechnicianEmailContact.nameGiven', serviceCall)}{' '}
									{nullSafeGet('leadTechnicianEmailContact.nameFamily', serviceCall)}
								</div>
							</div>
							{nullSafeGet('additionalTechnicians', serviceCall).map((contact) => (
								<div
									className="materialCard__primaryTitle"
									style={{
										marginBottom: 0,
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<ContactAvatar
										size="small"
										hidePopover={true}
										style={{ marginRight: 8 }}
										contact={contact}
										userType={ROLE_TYPES.BUYER}
									/>
									<div>
										{contact.nameGiven} {contact.nameFamily}
									</div>
								</div>
							))}
						</div>
					) : null}
				</div>
				<div
					className="serviceCallPopoverDisplay__text materialCard__supportingText materialCard__flexCentVert"
					style={{ paddingTop: 8, paddingBottom: 8 }}
				>
					<div className="serviceCallPopoverDisplay__text serviceCallPopoverDisplay__iconLabel">
						<i style={{ fontSize: 20 }} className={`icons8-font icons8-engineer-symbol`} />
					</div>
					<div className="serviceCallPopoverDisplay__text">
						{nullSafeGet('workOrder.problemType.hierarchyName', serviceCall)}
					</div>
				</div>
				<div
					className="serviceCallPopoverDisplay__text materialCard__supportingText materialCard__flexCentVert"
					style={{ paddingTop: 8, paddingBottom: 8 }}
				>
					<div className="serviceCallPopoverDisplay__text serviceCallPopoverDisplay__iconLabel">
						<i style={{ fontSize: 20 }} className={`icons8-font icons8-service-bell`} />
					</div>
					<div style={{ color: getWorkOrderBasePriorityBackgroundColor(serviceCall.woPriority) }}>
						{nullSafeGet('workOrder.woPriority.name', serviceCall)}
					</div>
				</div>
				<div
					className="serviceCallPopoverDisplay__text materialCard__supportingText materialCard__flexCentVert"
					style={{ paddingTop: 8, paddingBottom: 8 }}
				>
					<div className="serviceCallPopoverDisplay__text serviceCallPopoverDisplay__iconLabel">
						<i style={{ fontSize: 20 }} className={`icons8-font icons8-track-order`} />
					</div>
					<div className="serviceCallPopoverDisplay__text">
						<WorkOrderStatusDisplay
							className="serviceCallsDetailPage__subtitle"
							status={nullSafeGet('workOrder.status', serviceCall)}
							displayStatus={nullSafeGet('workOrder.displayStatus', serviceCall)}
						/>
					</div>
				</div>
				<div
					className="serviceCallPopoverDisplay__text materialCard__supportingText materialCard__flexCentVert"
					style={{ paddingTop: 8, paddingBottom: 8 }}
				>
					<div className="serviceCallPopoverDisplay__text serviceCallPopoverDisplay__iconLabel">
						<i style={{ fontSize: 20 }} className={`icons8-font icons8-search-button`} />
					</div>
					<div className="serviceCallPopoverDisplay__text">
						WO #{nullSafeGet('workOrder.id', serviceCall)}
					</div>
				</div>
			</div>
		</div>
	);
};

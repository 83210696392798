import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Alert } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { connect } from 'react-redux';
import {
	addWarrantySupplierToStoreState,
	fetchSupplierFacilitiesInPrivateNetworkForSupplier,
	supplierFacilitiesRestCrudThunksForSupplier,
} from '../../thunks/supplier_facilities_thunks';
import { getWarrantyStatus, nullSafeGet } from '../../utils/DataAccessUtils';
import SupplierSelectionWorkOrderSelect from '../supplier_selection_work_order_select';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';

const FormItem = Form.Item;
export const DATE_FORMAT = 'MMMM D, YYYY h:mm a';
const TRANSFER_WORK_ORDER_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE =
	'TRANSFER_WORK_ORDER_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE';

interface TransferWorkOrderModalFormProps extends FormComponentProps {
	onSubmit: any;
	onCancel: any;
	createErrors: any;
	updateErrors: any;
	currentUser: any;
	supplierFacilities: any;
	fetchMultipleSupplierFacilitiesInPrivateNetwork: any;
	fetchSupplierFacilitiesInPrivateNetwork: any;
	visible: boolean;
	creating: boolean;
	updating: boolean;
	formData: any;
	fetchSupplierFacility: any;
	addWarrantySupplier: any;
}

class TransferWorkOrderModalForm extends React.Component<TransferWorkOrderModalFormProps, any> {
	constructor(props) {
		super(props);
		this.state = {
			showAllSuppliers: false,
		};
	}
	handleSupplierFacilityChange = (supplierFacilityId) => {
		const { fetchSupplierFacility } = this.props;
		if (supplierFacilityId) {
			fetchSupplierFacility(supplierFacilityId).then((supplierFacility) => {
				supplierFacility.status === 'private'
					? this.setState({ privateSupplier: true })
					: this.setState({ privateSupplier: false });
			});
		}
	};

	render() {
		const {
			visible,
			onCancel,
			onSubmit,
			form,
			formData,
			supplierFacilities,
			fetchMultipleSupplierFacilitiesInPrivateNetwork,
			fetchSupplierFacilitiesInPrivateNetwork,
			createErrors,
			updateErrors,
			addWarrantySupplier,
			creating,
			updating,
		} = this.props;
		const { showingAllSuppliers } = this.state;
		const { getFieldDecorator } = form;
		const okText = 'Transfer';
		const titleText = 'Transfer Work Order';

		const asset = nullSafeGet('asset', formData);
		const warranty = nullSafeGet('warranties.0', asset);

		getFieldDecorator(`id`, { initialValue: nullSafeGet('id', formData) });
		return (
			<Modal
				visible={visible}
				width={600}
				title={titleText}
				okText={okText}
				onCancel={onCancel}
				onOk={onSubmit}
				closable={false}
				confirmLoading={creating || updating}
			>
				<Form layout="vertical" className="supplierForm">
					{createErrors.length > 0 ? (
						<FormItem>
							<Alert message={createErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					{updateErrors.length > 0 ? (
						<FormItem>
							<Alert message={updateErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					<Form.Item required={true} label="Supplier">
						{getFieldDecorator('supplierFacilityId', {
							initialValue: nullSafeGet('supplierFacilityId', formData),
						})(
							<SupplierSelectionWorkOrderSelect
								disabled={false}
								allowClear={true}
								stateSlice={supplierFacilities}
								targetCollectionName={
									TRANSFER_WORK_ORDER_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE
								}
								fetchMultiple={(ids, targetCollectionName) => {
									fetchMultipleSupplierFacilitiesInPrivateNetwork(ids, targetCollectionName);
								}}
								warrantySupplierId={nullSafeGet('provider.supplierFacilityId', warranty)}
								fetchData={(
									searchText,
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								) =>
									new Promise((resolve, reject) =>
										fetchSupplierFacilitiesInPrivateNetwork(
											{ name: searchText, offset: 0, limit: 1000 },
											targetCollectionName,
											pagination,
											sorting,
											filters,
											addToTargetCollection
										).then((data) => {
											const warrantySupplierId = nullSafeGet(
												'warranties.0.provider.supplierFacilityId',
												this.state.asset
											);
											if (!data.some((supplier) => supplier.id === warrantySupplierId)) {
												resolve(
													addWarrantySupplier(
														warrantySupplierId,
														TRANSFER_WORK_ORDER_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE
													)
												);
											} else {
												resolve(data);
											}
										})
									)
								}
								onChange={this.handleSupplierFacilityChange}
								renderRecord={(supplierFacility, apiSuppliers) => (
									<div>
										<Ellipsis
											tooltip={true}
											length={32}
											fullWidthRecognition={true}
											style={{ wordBreak: 'normal' }}
										>
											{supplierFacility.name}
										</Ellipsis>
										{this.state.asset &&
										getWarrantyStatus(nullSafeGet('warranties.0', this.state.asset)) === 'Active' &&
										nullSafeGet('warranties.0.provider.supplierFacilityId', this.state.asset) ===
											supplierFacility.id ? (
											<span className="mt-0 inline-block  text-sm text-gray-400">(Warranty)</span>
										) : (
											<p className={'mb-0'}>
												{!showingAllSuppliers &&
													(apiSuppliers.find((item) => item.id === supplierFacility.id) ? (
														<span className="mt-0 inline-block  text-sm text-gray-400">
															(Rank{' '}
															{apiSuppliers.findIndex(
																(item, index) => supplierFacility.id === item.id
															) + 1}
															)
														</span>
													) : null)}
											</p>
										)}
									</div>
								)}
								sortBy={{}}
								additionalFilters={{
									problemTypeId: nullSafeGet('problemTypeId', formData),
									locationId: nullSafeGet('locationId', formData),
								}}
							/>
						)}
					</Form.Item>
				</Form>
			</Modal>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	supplierFacilities: state.supplier_facilities,
	currentUser: state.session.currentUser,
	createErrors: state.buyer_contacts.createErrors,
	updateErrors: state.buyer_contacts.updateErrors,
	creating: state.work_orders.creating,
	updating: state.work_orders.updating,
});

const mapDispatchToProps = (dispatch) => ({
	fetchSupplierFacility: (id) => dispatch(supplierFacilitiesRestCrudThunksForSupplier.readOne(id)),
	fetchMultipleSupplierFacilitiesInPrivateNetwork: (ids, targetCollectionName) =>
		dispatch(supplierFacilitiesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchSupplierFacilitiesInPrivateNetwork: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters
	) =>
		dispatch(
			fetchSupplierFacilitiesInPrivateNetworkForSupplier(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters
			)
		),
	addWarrantySupplier: (supplierId, targetCollectionName) =>
		dispatch(addWarrantySupplierToStoreState(supplierId, targetCollectionName)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create<TransferWorkOrderModalFormProps>()(TransferWorkOrderModalForm));

import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Alert, Layout, Button, Row, Col, InputNumber, Collapse, message } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { nullSafeGetOrElse, nullSafeGet } from '../../utils/DataAccessUtils';
import BackButton from '../back_button/BackButton';
import { workOrdersRestCrudThunksForSupplier } from '../../thunks/work_orders_thunks';
import { getInvoiceNumber, invoicesRestCrudThunksForSupplier } from '../../thunks/invoices_thunks';

const Panel = Collapse.Panel;

const style = require('./SupplierSimpleInvoiceForm.less');
const queryString = require('qs');

interface SupplierInvoiceProps {
	formData: any;
	onSuccess: any;
	creating: boolean;
	updating: boolean;
	createErrors: string[];
	updateErrors: string[];
	redirectForwardUrl: string;
	redirectBackwardUrl: string;
	currentUser: any;
	history: any;
	createInvoice: any;
	updateInvoice: any;
	workOrder: any;
	getWorkOrder: any;
}

interface SupplierInvoiceFormProps extends FormComponentProps {
	formData: any;
	onSuccess: any;
	creating: boolean;
	updating: boolean;
	createErrors: string[];
	updateErrors: string[];
	redirectForwardUrl: string;
	redirectBackwardUrl: string;
	currentUser: any;
	history: any;
	createInvoice: any;
	updateInvoice: any;
	workOrder: any;
	getWorkOrder: any;
	checkInvoiceNumber: any;
}

class SupplierSimpleInvoiceForm extends React.Component<SupplierInvoiceFormProps, any> {
	componentDidMount() {
		const { form, getWorkOrder, formData } = this.props;
		if (formData.workOrderId) {
			getWorkOrder(formData.workOrderId);
		}
	}

	handleSubmit = (e) => {
		const {
			form,
			formData,
			redirectForwardUrl,
			currentUser,
			history,
			onSuccess,
			createInvoice,
			updateInvoice,
		} = this.props;
		e.preventDefault();

		form.validateFields((err, values) => {
			if (!err) {
				const submitAction = values.id ? updateInvoice : createInvoice;

				let invoice = {
					id: values.id,
					externalWorkOrderId: values.externalWorkOrderId,
					invoiceNumber: values.invoiceNumber,
					scope: values.scope,
					status: 'draft',
					laborLineItems: [],
					materialLineItems: [],
					invoiceTax: `0`,
					invoiceTotalBeforeTax: `${values.miscTotalBeforeTax}`,
					invoiceTotalAfterTax: `${values.miscTotalBeforeTax}`,
					laborTotalBeforeTax: '0',
					materialTotalBeforeTax: '0',
					travelTotalBeforeTax: '0',
					freightTotalBeforeTax: '0',
					miscTotalBeforeTax: `${values.miscTotalBeforeTax}`,
					workOrderId: parseInt(formData.workOrderId, 10),
					supplierFacilityId: values.supplierFacilityId || nullSafeGet('facility.id', currentUser),
					createdBy: currentUser.email,
					attachments: values.attachments,
				};

				submitAction(invoice)
					.then((record) => {
						if (redirectForwardUrl) {
							history.push(redirectForwardUrl);
						}
						if (onSuccess) {
							onSuccess(record);
						}
					})
					.catch((err) => {
						if (err && err.startsWith('Invoice(s) are already associated with this work order')) {
							message.error(err);
							history.goBack();
						}
					});
			}
		});
	};
	handleInvoiceNumberChange = async (rule, value, callback) => {
		const { checkInvoiceNumber, formData } = this.props;
		if (value) {
			let data = await checkInvoiceNumber(value);
			if (data && Array.isArray(data)) {
				data.map((invoice) => {
					callback(
						<span>
							Error: Duplicate with{' '}
							<a
								href={'/supplier/invoices/detail/'.concat(nullSafeGet('id', invoice), `/details`)}
								target="_blank"
							>
								Invoice {nullSafeGet('id', invoice)}
							</a>
						</span>
					);
				});
			}
		}
	};

	render() {
		const { formData, creating, updating, updateErrors, createErrors, form, workOrder } =
			this.props;
		const submitting = creating || updating;
		const errorMessagesText = createErrors.concat(updateErrors).join('; ');
		const isUpdate = formData.hasOwnProperty('id') && formData.id !== undefined;
		let submitText = isUpdate ? 'Update invoice' : 'Create invoice';
		const { getFieldDecorator, getFieldValue } = form;

		const DATE_FORMAT = 'MM-DD-YYYY';

		getFieldDecorator('id', { initialValue: formData.id });
		getFieldDecorator('attachments', { initialValue: formData.attachments || [] });

		return (
			<Layout.Content>
				<Row className="supplierInvoiceForm" gutter={16}>
					<Col span={24}>
						<Form layout="vertical" hideRequiredMark={true}>
							{createErrors.length > 0 || updateErrors.length > 0 ? (
								<Form.Item label="">
									<Alert message={errorMessagesText} type="error" />
								</Form.Item>
							) : null}
							<Form.Item style={{ marginBottom: 32 }}>
								<div className="supplierInvoiceForm__sectionHeadPrimary">Total</div>
								{getFieldDecorator(`miscTotalBeforeTax`, {
									validateTrigger: ['onBlur'],
									initialValue: nullSafeGetOrElse(`miscTotalBeforeTax`, formData, 0),
									rules: [
										{
											required: true,
											message: 'Please enter invoice total',
										},
									],
								})(<InputNumber step={1} style={{ width: 240 }} min={0.01} />)}
							</Form.Item>
							<Form.Item className="supplierInvoiceForm__sectionHeadPrimary" label="Invoice #">
								{getFieldDecorator('invoiceNumber', {
									validateTrigger: ['onBlur'],
									initialValue: nullSafeGet(`invoiceNumber`, formData),
									rules: [
										{
											whitespace: true,
											required: true,
											message: 'This field is required.',
										},
										{
											validator: this.handleInvoiceNumberChange,
										},
									],
								})(<Input style={{ maxWidth: 320, marginTop: '-8px' }} />)}
							</Form.Item>
							<Form.Item style={{ marginBottom: 32 }}>
								<div className="supplierInvoiceForm__sectionHeadPrimary">Work Order #</div>
								{getFieldDecorator('externalWorkOrderId', {
									initialValue: nullSafeGetOrElse(
										`externalWorkOrderId`,
										formData,
										`${workOrder.id}`
									),
								})(<Input style={{ maxWidth: 320 }} />)}
							</Form.Item>
							<Form.Item style={{ marginBottom: 32 }}>
								<div className="supplierInvoiceForm__sectionHeadPrimary">
									What are you billing for?
								</div>
								{getFieldDecorator('scope', {
									initialValue: nullSafeGet(`scope`, formData),
								})(
									<Input.TextArea
										placeholder="We replaced the gasket and repaired the fixture. Everything's good as new."
										style={{ maxWidth: 640 }}
									/>
								)}
							</Form.Item>
							{/*<div style={{marginBottom: 32}}>*/}
							{/*    <FileUploader*/}
							{/*        roleType="supplier"*/}
							{/*        defaultFileList={[]}*/}
							{/*        handleUploadSuccess={() => {*/}
							{/*        }}*/}
							{/*        uploaderType={'dragger'}*/}
							{/*    />*/}
							{/*    /!*this.handleFileAttachmentUploadChange(invoice)*!/*/}
							{/*</div>*/}
							<Form.Item label="">
								<Button loading={submitting} type="primary" onClick={(e) => this.handleSubmit(e)}>
									{submitText}
								</Button>
								<span style={{ marginLeft: '16px' }}>
									<BackButton buttonText="Cancel" />
								</span>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Layout.Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	formData: ownProps.formData,
	onSuccess: ownProps.onSuccess,
	updating: state.invoices.updating,
	creating: state.invoices.creating,
	createErrors: state.invoices.createErrors,
	updateErrors: state.invoices.updateErrors,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	redirectBackwardUrl: ownProps.redirectBackwardUrl,
	currentUser: state.session.currentUser,
	history: ownProps.history,
	location: ownProps.location,
	workOrder: state.work_orders.detail,
});

const mapDispatchToProps = (dispatch) => ({
	checkInvoiceNumber: (invoice) => dispatch(getInvoiceNumber(invoice)),
	createInvoice: (invoice) => dispatch(invoicesRestCrudThunksForSupplier.create(invoice)),
	updateInvoice: (invoice) => dispatch(invoicesRestCrudThunksForSupplier.update(invoice)),
	getWorkOrder: (id) => dispatch(workOrdersRestCrudThunksForSupplier.readOne(id)),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<SupplierInvoiceFormProps>()(SupplierSimpleInvoiceForm))
);

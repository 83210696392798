import {
	FETCH_BID_COMPARISON_START,
	FETCH_BID_COMPARISON_SUCCESS,
	FETCH_BID_COMPARISON_ERROR,
	FETCH_QUESTIONNAIRES_FOR_SOURCING_EVENT_START,
	FETCH_QUESTIONNAIRES_FOR_SOURCING_EVENT_SUCCESS,
	FETCH_QUESTIONNAIRES_FOR_SOURCING_EVENT_ERROR,
} from '../actions/bid_comparison_actions';
import { BidComparisonStoreState } from '../types/models';

const initialState: BidComparisonStoreState = {
	bidDetails: {},
	errors: [],
	questionnairesLoading: false,
	questionnaires: { answersBySupplier: {}, questions: [] },
	loading: new Set(),
};

export default (oldState = initialState, action): BidComparisonStoreState => {
	Object.freeze(oldState);
	let newLoading;
	switch (action.type) {
		case FETCH_BID_COMPARISON_START:
			newLoading = new Set(oldState.loading);
			newLoading.add(action.sourcingEventId + '.' + action.supplierFacilityId);
			return {
				...oldState,
				loading: newLoading,
			};
		case FETCH_BID_COMPARISON_SUCCESS:
			let newBidDetails = action.bidDetails.reduce((acc, el) => {
				acc[`${el.sourcingEventId}.${el.supplierFacilityId}`] = el;
				return acc;
			}, {});
			const newBidDetailIds = action.bidDetails.map(
				(el) => el.sourcingEventId + '.' + el.supplierFacilityId
			);
			newLoading = new Set(oldState.loading);
			newBidDetailIds.forEach((id) => {
				newLoading.delete(id);
			});
			return {
				...oldState,
				loading: newLoading,
				errors: [],
				bidDetails: { ...oldState.bidDetails, ...newBidDetails },
			};
		case FETCH_BID_COMPARISON_ERROR:
			return {
				...oldState,
				loading: new Set([]),
				errors: [...oldState.errors, action.error],
			};
		case FETCH_QUESTIONNAIRES_FOR_SOURCING_EVENT_START:
			return {
				...oldState,
				questionnairesLoading: true,
			};
		case FETCH_QUESTIONNAIRES_FOR_SOURCING_EVENT_SUCCESS:
			return {
				...oldState,
				questionnairesLoading: false,
				errors: [],
				questionnaires: action.questionnaires,
			};
		case FETCH_QUESTIONNAIRES_FOR_SOURCING_EVENT_ERROR:
			return {
				...oldState,
				questionnairesLoading: false,
				errors: [...oldState.errors, action.error],
			};
		default:
			return oldState;
	}
};

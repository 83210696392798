const SUPPLIER_INITIATED_PENDING_APPROVAL = 'SupplierInitiatedPendingApproval';
const PENDING_APPROVAL = 'PendingApproval';
const UNASSIGNED = 'Unassigned';
const SNOOZED = 'Snoozed';
const DEFERRED_FOR_BUDGET = 'DeferredForBudget';
const DEFERRED = 'Deferred';
const PLANNED_WORK = 'PlannedWork';
const STARTED_WORK = 'StartedWork';
const FINISHED_WORK = 'FinishedWork';
const ASSIGNED_TO_INTERNAL_TECH = 'AssignedToInternalTech';
const INTERNAL_TECH_WORK_COMPLETE = 'InternalTechWorkComplete';
const INTERNAL_TECH_WORK_REVIEWED_AND_COMPLETED = 'InternalTechWorkReviewedAndCompleted';
const PENDING_CONFIRMATION_BY_SERVICE_PROVIDER = 'PendingConfirmationByServiceProvider';
const CONFIRMED_BY_SERVICE_PROVIDER = 'ConfirmedByServiceProvider';
const TECH_ASSIGNED = 'TechAssigned';
const TECH_SCHEDULED = 'TechScheduled';
const TECH_RESCHEDULED = 'TechRescheduled';
const TECH_EN_ROUTE = 'TechEnRoute';
const TECH_WAITING_ON_SITE = 'TechWaitingOnSite';
const TECH_WORKING_ON_SITE = 'TechWorkingOnSite';
const WORK_INCOMPLETE_WITH_REASON = 'WorkIncompleteWithReason';
const WORK_UNSATISFACTORY = 'WorkUnsatisfactory';
const WAITING_FOR_REVIEW = 'WaitingForReview';
const WAITING_FOR_QUOTE = 'WaitingForQuote';
const WAITING_FOR_PROPOSAL = 'WaitingForProposal';
const WAITING_FOR_PROPOSAL_APPROVAL = 'WaitingForProposalApproval';
const PROPOSAL_APPROVED = 'ProposalApproved';
const PARTS_ON_ORDER = 'PartsOnOrder';
const PARTS_RECEIVED = 'PartsReceived';
const PARTS_REQUESTED = 'PartsRequested';
const DELAYED_BY_THIRD_PARTY = 'DelayedByThirdParty';
const WORK_REVIEWED_AND_COMPLETED = 'WorkReviewedAndCompleted';
const INVOICED = 'Invoiced';
const PAYMENT_MADE = 'PaymentMade';
const PAYMENT_ACCEPTED = 'PaymentAccepted';
const CANCELLED_WITH_REASON = 'CancelledWithReason';

export const WORK_ORDER_STATUSES = {
	supplierInitiatedPendingApproval: SUPPLIER_INITIATED_PENDING_APPROVAL,
	pendingApproval: PENDING_APPROVAL,
	unassigned: UNASSIGNED,
	deferred: DEFERRED,
	deferredForBudget: DEFERRED_FOR_BUDGET,
	snoozed: SNOOZED,
	plannedWork: PLANNED_WORK,
	startedWork: STARTED_WORK,
	finishedWork: FINISHED_WORK,
	assignedToInternalTech: ASSIGNED_TO_INTERNAL_TECH,
	internalTechWorkComplete: INTERNAL_TECH_WORK_COMPLETE,
	internalTechWorkReviewedAndCompleted: INTERNAL_TECH_WORK_REVIEWED_AND_COMPLETED,
	pendingConfirmationByServiceProvider: PENDING_CONFIRMATION_BY_SERVICE_PROVIDER,
	confirmedByServiceProvider: CONFIRMED_BY_SERVICE_PROVIDER,
	techAssigned: TECH_ASSIGNED,
	techScheduled: TECH_SCHEDULED,
	techRescheduled: TECH_RESCHEDULED,
	techEnRoute: TECH_EN_ROUTE,
	techWaitingOnSite: TECH_WAITING_ON_SITE,
	techWorkingOnSite: TECH_WORKING_ON_SITE,
	workIncompleteWithReason: WORK_INCOMPLETE_WITH_REASON,
	workUnsatisfactory: WORK_UNSATISFACTORY,
	waitingForReview: WAITING_FOR_REVIEW,
	waitingForQuote: WAITING_FOR_QUOTE,
	waitingForProposal: WAITING_FOR_PROPOSAL,
	waitingForProposalApproval: WAITING_FOR_PROPOSAL_APPROVAL,
	proposalApproved: PROPOSAL_APPROVED,
	partsOnOrder: PARTS_ON_ORDER,
	partsReceived: PARTS_RECEIVED,
	partsRequested: PARTS_REQUESTED,
	delayedByThirdParty: DELAYED_BY_THIRD_PARTY,
	workReviewedAndCompleted: WORK_REVIEWED_AND_COMPLETED,
	invoiced: INVOICED,
	paymentMade: PAYMENT_MADE,
	paymentAccepted: PAYMENT_ACCEPTED,
	cancelledWithReason: CANCELLED_WITH_REASON,
};

export const PRE_SUPPLIER_ASSIGNMENT_STATUSES = [
	WORK_ORDER_STATUSES.pendingApproval,
	WORK_ORDER_STATUSES.unassigned,
];

export const SELF_MANAGEMENT_STATUSES = [
	WORK_ORDER_STATUSES.unassigned,
	WORK_ORDER_STATUSES.plannedWork,
	WORK_ORDER_STATUSES.startedWork,
	WORK_ORDER_STATUSES.finishedWork,
	WORK_ORDER_STATUSES.partsOnOrder,
	WORK_ORDER_STATUSES.partsReceived,
];

export const TECH_SCHEDULED_STATUSES = [
	WORK_ORDER_STATUSES.techScheduled,
	WORK_ORDER_STATUSES.techRescheduled,
];

export const INTERNAL_TECH_COMPLETED_STATUSES = [
	WORK_ORDER_STATUSES.internalTechWorkComplete,
	WORK_ORDER_STATUSES.internalTechWorkReviewedAndCompleted,
];

export const SERVICE_PROVIDER_COMPLETED_STATUSES = [
	WORK_ORDER_STATUSES.workIncompleteWithReason,
	WORK_ORDER_STATUSES.workUnsatisfactory,
	WORK_ORDER_STATUSES.waitingForReview,
	WORK_ORDER_STATUSES.workReviewedAndCompleted,
	WORK_ORDER_STATUSES.invoiced,
	WORK_ORDER_STATUSES.paymentMade,
	WORK_ORDER_STATUSES.paymentAccepted,
	WORK_ORDER_STATUSES.cancelledWithReason,
];

export const SCHEDULE_TECH_STATUSES = [
	WORK_ORDER_STATUSES.assignedToInternalTech,
	WORK_ORDER_STATUSES.confirmedByServiceProvider,
	WORK_ORDER_STATUSES.delayedByThirdParty,
	WORK_ORDER_STATUSES.partsOnOrder,
	WORK_ORDER_STATUSES.partsReceived,
	WORK_ORDER_STATUSES.partsRequested,
	WORK_ORDER_STATUSES.workIncompleteWithReason,
	WORK_ORDER_STATUSES.workUnsatisfactory,
	WORK_ORDER_STATUSES.proposalApproved,
	WORK_ORDER_STATUSES.waitingForQuote,
];

export const NOT_SCHEDULABLE_STATUSES = [
	WORK_ORDER_STATUSES.supplierInitiatedPendingApproval,
	WORK_ORDER_STATUSES.pendingApproval,
	WORK_ORDER_STATUSES.unassigned,
	WORK_ORDER_STATUSES.cancelledWithReason,
	WORK_ORDER_STATUSES.waitingForReview,
	WORK_ORDER_STATUSES.workReviewedAndCompleted,
	WORK_ORDER_STATUSES.invoiced,
	WORK_ORDER_STATUSES.paymentMade,
	WORK_ORDER_STATUSES.paymentAccepted,
];

export const BUYER_NOTIFICATION_APPROVALS_DISPLAY_NAMES = {
	WorkOrderApproval: 'Work Orders',
	InvoiceApproval: 'Invoices',
	ProposalApproval: 'Quotes',
};

export const SUPPLIER_NOTIFICATION_APPROVALS_DISPLAY_NAMES = {
	WorkOrderApproval: 'Work Orders',
	InvoiceApproval: 'Invoices',
	ProposalApproval: 'Quotes',
};

export const BUYER_NOTIFICATION_NOTES_DISPLAY_NAMES = {
	WorkOrderNotesMentions: 'Mention',
	WorkOrderNotes: 'Subscription',
};

export const SUPPLIER_NOTIFICATION_NOTES_DISPLAY_NAMES = {
	WorkOrderNotesMentions: 'Mention',
	WorkOrderNotes: 'Subscription',
};

export const BUYER_NOTIFICATION_WORK_ORDER_STATUS_DISPLAY_NAMES = {
	[SUPPLIER_INITIATED_PENDING_APPROVAL]: 'Supplier Initiated Work Pending Approval',
	[PENDING_APPROVAL]: 'Pending Approval',
	[UNASSIGNED]: 'Unassigned',
	// [DEFERRED]: 'Deferred',
	// [DEFERRED_FOR_BUDGET]: 'Deferred For Budget',
	// [SNOOZED]: 'Snoozed',
	[ASSIGNED_TO_INTERNAL_TECH]: 'Assigned to Internal Team',
	[INTERNAL_TECH_WORK_COMPLETE]: 'Task Completed by Internal Tech',
	[INTERNAL_TECH_WORK_REVIEWED_AND_COMPLETED]: "Internal Tech's Work Reviewed and Completed",
	[PENDING_CONFIRMATION_BY_SERVICE_PROVIDER]: 'Pending Contractor Confirmation',
	[CONFIRMED_BY_SERVICE_PROVIDER]: 'Confirmed by Service Provider',
	[TECH_ASSIGNED]: 'Tech Assigned',
	[TECH_SCHEDULED]: 'Tech Scheduled',
	[TECH_RESCHEDULED]: 'Tech Rescheduled',
	[TECH_WORKING_ON_SITE]: 'Tech Working on Site',
	[WORK_INCOMPLETE_WITH_REASON]: 'Work Incomplete with Reason',
	[WAITING_FOR_REVIEW]: 'Waiting for Review',
	[WAITING_FOR_QUOTE]: 'Waiting for Quote',
	[PARTS_ON_ORDER]: 'Parts on Order',
	[PARTS_RECEIVED]: 'Parts Received',
	[PARTS_REQUESTED]: 'Parts Requested',
	[DELAYED_BY_THIRD_PARTY]: 'Delayed by Third Party',
	[WORK_REVIEWED_AND_COMPLETED]: 'Work Reviewed and Completed',
	[INVOICED]: 'Invoiced',
	[CANCELLED_WITH_REASON]: 'Cancelled With Reason',
};

export const SUPPLIER_NOTIFICATION_WORK_ORDER_STATUS_DISPLAY_NAMES = {
	[ASSIGNED_TO_INTERNAL_TECH]: 'Assigned to Internal Team',
	[INTERNAL_TECH_WORK_REVIEWED_AND_COMPLETED]: "Internal Tech's Work Reviewed and Completed",
	[PENDING_CONFIRMATION_BY_SERVICE_PROVIDER]: 'Pending Contractor Confirmation',
	[CONFIRMED_BY_SERVICE_PROVIDER]: 'Confirmed by Service Provider',
	[TECH_ASSIGNED]: 'Tech Assigned',
	[TECH_SCHEDULED]: 'Tech Scheduled',
	[WORK_UNSATISFACTORY]: 'Work Unsatisfactory',
	[WAITING_FOR_QUOTE]: 'Waiting for Quote',
	[WORK_REVIEWED_AND_COMPLETED]: 'Work Reviewed and Completed',
	[PAYMENT_MADE]: 'Payment Made',
	[CANCELLED_WITH_REASON]: 'Cancelled With Reason',
};

export const BUYER_NOTIFICATION_RFP_STATUS_DISPLAY_NAMES = {
	RequestProposal: 'RFP Created',
	Participating: 'Participating',
	NotParticipating: 'Not Participating',
	Awarded: 'Awarded',
	Declined: 'Declined',
	RequestForProposalEnded: 'RFP Closed',
};

export const SUPPLIER_NOTIFICATION_RFP_STATUS_DISPLAY_NAMES = {
	RequestProposal: 'RFP Created',
	Participating: 'Participating',
	NotParticipating: 'Not Participating',
	NotAwarded: 'Not Awarded',
	Awarded: 'Awarded',
	RequestForProposalEnded: 'RFP Closed',
};

export const WORK_ORDER_STATUS_DISPLAY_NAMES = {
	[SUPPLIER_INITIATED_PENDING_APPROVAL]: 'Supplier Initiated Work Pending Approval',
	[PENDING_APPROVAL]: 'Pending Approval',
	[UNASSIGNED]: 'Unassigned',
	[DEFERRED_FOR_BUDGET]: 'Deferred For Budget',
	[SNOOZED]: 'Snoozed',
	[DEFERRED]: 'Deferred',
	[PLANNED_WORK]: 'Planned',
	[STARTED_WORK]: 'Started Work',
	[FINISHED_WORK]: 'Finished Work',
	[ASSIGNED_TO_INTERNAL_TECH]: 'Assigned to Internal Team',
	[INTERNAL_TECH_WORK_COMPLETE]: 'Task Completed by Internal Tech',
	[INTERNAL_TECH_WORK_REVIEWED_AND_COMPLETED]: "Internal Tech's Work Reviewed and Completed",
	[PENDING_CONFIRMATION_BY_SERVICE_PROVIDER]: 'Pending Contractor Confirmation',
	[CONFIRMED_BY_SERVICE_PROVIDER]: 'Confirmed by Service Provider',
	[TECH_ASSIGNED]: 'Tech Assigned',
	[TECH_SCHEDULED]: 'Tech Scheduled',
	[TECH_RESCHEDULED]: 'Tech Rescheduled',
	[TECH_EN_ROUTE]: 'Tech En Route',
	[TECH_WAITING_ON_SITE]: 'Tech Waiting on Site',
	[TECH_WORKING_ON_SITE]: 'Tech Working on Site',
	[WORK_INCOMPLETE_WITH_REASON]: 'Work Incomplete with Reason',
	[WORK_UNSATISFACTORY]: 'Work Unsatisfactory',
	[WAITING_FOR_REVIEW]: 'Waiting for Review',
	[WAITING_FOR_QUOTE]: 'Waiting for Quote',
	[WAITING_FOR_PROPOSAL]: 'Waiting for Proposal',
	[WAITING_FOR_PROPOSAL_APPROVAL]: 'Waiting for Proposal Approval',
	[PROPOSAL_APPROVED]: 'Proposal Approved',
	[PARTS_ON_ORDER]: 'Parts on Order',
	[PARTS_RECEIVED]: 'Parts Received',
	[PARTS_REQUESTED]: 'Parts Requested',
	[DELAYED_BY_THIRD_PARTY]: 'Delayed by Third Party',
	[WORK_REVIEWED_AND_COMPLETED]: 'Work Reviewed and Completed',
	[INVOICED]: 'Invoiced',
	[PAYMENT_MADE]: 'Payment Made',
	[PAYMENT_ACCEPTED]: 'Payment Accepted',
	[CANCELLED_WITH_REASON]: 'Cancelled With Reason',
};

export const WORK_ORDER_STATUS_DISPLAY_NAMES_REVERSE_MAP = {
	'Supplier Initiated Work Pending Approval': SUPPLIER_INITIATED_PENDING_APPROVAL,
	'Pending Approval': PENDING_APPROVAL,
	Unassigned: UNASSIGNED,
	PlannedWork: PLANNED_WORK,
	StartedWork: STARTED_WORK,
	FinishedWork: FINISHED_WORK,
	'Assigned to Internal Tech': ASSIGNED_TO_INTERNAL_TECH,
	'Task Completed by Internal Tech': INTERNAL_TECH_WORK_COMPLETE,
	"Internal Tech's Work Reviewed and Completed": INTERNAL_TECH_WORK_REVIEWED_AND_COMPLETED,
	'Pending Contractor Confirmation': PENDING_CONFIRMATION_BY_SERVICE_PROVIDER,
	'Confirmed by Service Provider': CONFIRMED_BY_SERVICE_PROVIDER,
	'Tech Assigned': TECH_ASSIGNED,
	'Tech Scheduled': TECH_SCHEDULED,
	'Tech Rescheduled': TECH_RESCHEDULED,
	'Tech En Route': TECH_EN_ROUTE,
	'Tech Working on Site': TECH_WORKING_ON_SITE,
	'Work Incomplete with Reason': WORK_INCOMPLETE_WITH_REASON,
	'Work Unsatisfactory': WORK_UNSATISFACTORY,
	'Waiting for Review': WAITING_FOR_REVIEW,
	'Waiting for Quote': WAITING_FOR_QUOTE,
	'Waiting for Proposal': WAITING_FOR_PROPOSAL,
	'Waiting for Proposal Approval': WAITING_FOR_PROPOSAL_APPROVAL,
	'Proposal Approved': PROPOSAL_APPROVED,
	'Parts on Order': PARTS_ON_ORDER,
	'Parts Received': PARTS_RECEIVED,
	'Parts Requested': PARTS_REQUESTED,
	'Delayed by Third Party': DELAYED_BY_THIRD_PARTY,
	'Work Reviewed and Completed': WORK_REVIEWED_AND_COMPLETED,
	Invoiced: INVOICED,
	'Payment Made': PAYMENT_MADE,
	'Payment Accepted': PAYMENT_ACCEPTED,
	'Cancelled With Reason': CANCELLED_WITH_REASON,
};

export const CONTRACTOR_CHECK_OUT_OPTIONS = [
	{
		displayName: 'Work Complete',
		value: WORK_ORDER_STATUSES.waitingForReview,
	},
	{
		displayName: 'Parts on Order',
		value: WORK_ORDER_STATUSES.partsOnOrder,
	},
	{
		displayName: 'Parts Received',
		value: WORK_ORDER_STATUSES.partsReceived,
	},
	{
		displayName: 'Quote Required',
		value: WORK_ORDER_STATUSES.waitingForQuote,
	},
	{
		displayName: 'Delayed by Third Party',
		value: WORK_ORDER_STATUSES.delayedByThirdParty,
	},
	{
		displayName: 'Work Incomplete With Reason',
		value: WORK_ORDER_STATUSES.workIncompleteWithReason,
	},
];

import * as React from 'react';
import { Layout, Row, Col, Card, Divider, Button } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { getTimeDurationFromSeconds } from '../../utils/DataFormatterUtils';
import {
	compareStrings,
	getCurrency,
	getEntityById,
	getObjectValues,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import {
	proposalsRestCrudThunksForBuyer,
	proposalStatusChangeForBuyer,
	syncProposalApprovalHierarchy,
} from '../../thunks/proposals_thunks';
import {
	appendWorkOrderNotesForBuyer,
	workOrdersRestCrudThunksForBuyer,
} from '../../thunks/work_orders_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import ApprovalHierarchyDisplayQuote from '../approval_hierarchy_display_quote/ApprovalHierarchyDisplayQuote';
import CapexSwitch from '../capex_switch/CapexSwitch';
import {
	disputeQuoteForBuyer,
	markQuotePaidForBuyer,
	markQuotePendingForBuyer,
	markQuoteProcessingForBuyer,
	quotesRestCrudThunksForBuyer,
} from '../../thunks/quotes_thunks';
import QuotesAdditionalDetails from '../quotes_components/QuotesAdditionalDetails';
import { EmptyState } from '../empty_state/EmptyState';
import { BUYER_ADMIN, BUYER_USER, SUPER_ADMIN } from '../roles/roles';
import QuoteUploader from '../quote_uploader/QuoteUploader';
import { Link } from 'react-router-dom';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import QuoteEditButton from '../quote_edit_button/QuoteEditButton';

const { Content } = Layout;

const style = require('./WorkOrdersDetailQuotesPage.less');

class WorkOrderQuotesDetailPage extends React.Component<any, any> {
	formRefs: any;
	constructor(props) {
		super(props);
		this.formRefs = {};
	}
	state = {
		quotes: [],
		noSupplier: false,
	};
	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};
	refreshQuote = () => {
		const { getQuote, quote } = this.props;
		getQuote(quote.id);
	};

	componentDidMount() {
		const { getQuote, getQuotes, getWorkOrder, match, workOrder } = this.props;
		const workOrderId = match.params.id;
		const getQuoteDetails = (workOrder) => {
			if (nullSafeGet('supplierFacilityId', workOrder)) {
				getQuotes(
					{
						workOrderId,
						supplierFacilityId: workOrder.supplierFacilityId,
					},
					'workOrderAssociatedQuotes'
				).then((quotes) => (nullSafeGet('0.id', quotes) ? getQuote(quotes[0].id) : null));
			} else {
				this.setState({ noSupplier: true });
			}
		};
		if (workOrder && workOrder.id) {
			getQuoteDetails(workOrder);
		} else {
			getWorkOrder(workOrderId).then((data) => {
				getQuoteDetails(data);
			});
		}
	}

	handleQuotePDFUploadChange = (quoteFileUrl) => {
		const { updateQuote, getQuote, quote } = this.props;

		const newQuote = {
			...quote,
			proposalPdfLink: quoteFileUrl,
		};

		this.refreshQuote();
	};

	render() {
		const {
			quotes,
			quoteFetching,
			quote,
			match,
			currentUser,
			approveQuote,
			declineQuote,
			appendWorkOrderNotes,
			companyConfig,
			workOrder,
			workOrderFetching,
			syncQuote,
			history,
		} = this.props;
		const { noSupplier } = this.state;
		const isAdmin =
			currentUser.roles.includes(BUYER_ADMIN) || currentUser.roles.includes(SUPER_ADMIN);
		const isManager = currentUser.roles.includes(BUYER_USER);
		const hasPDF = quote.proposalPdfLink && quote.proposalPdfLink.length > 0;
		const currency = getCurrency({ workOrder: quote.workOrder });

		const pdfElement = hasPDF ? (
			<p className={'mb-1'}>
				<a href={quote.proposalPdfLink} target="_blank" rel="noreferrer">
					Quote PDF
				</a>
			</p>
		) : null;
		const uploadQuote = isAdmin ? (
			<QuoteUploader
				roleType="supplier"
				quoteId={quote && quote.id}
				defaultFileList={[]}
				multiple={false}
				handleUploadSuccess={this.handleQuotePDFUploadChange}
			/>
		) : null;

		return workOrderFetching || (quoteFetching && !noSupplier) ? (
			<PageLoadingPlaceholder />
		) : nullSafeGetOrElse('count', quotes, 0) > 0 && !noSupplier ? (
			<Content className="workOrderQuoteDetailsPage">
				<LogOnMountWithStandardEventProperties eventType="visited buyer quotes page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '16px 8px' }} gutter={12} key={'quote'}>
					<Col span={24}>
						<Card>
							<Row justify={'space-between'} className={'rowSpacing'}>
								<Col />
								<Col>
									{isAdmin || isManager ? (
										<Button
											onClick={() => {
												history.push(`/buyer/quotes/detail/${quote.id}`);
											}}
										>
											View Quote Page
										</Button>
									) : null}
									<QuoteEditButton
										quote={quote}
										style={{ marginLeft: 12 }}
										onClick={() => {
											history.push(
												`/buyer/workOrders/detail/${quote.workOrder.id}/editProposal/${quote.id}`
											);
										}}
									/>
								</Col>
							</Row>
							<div
								style={{
									display: 'flex',
									position: 'relative',
									height: 'fit-content',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										position: 'relative',
										flexDirection: 'column',
										width: '100%',
									}}
								>
									<Row className="w-full justify-between align-top">
										<ApprovalHierarchyDisplayQuote
											workOrder={workOrder}
											quote={quote}
											currentUser={currentUser}
											approveQuote={approveQuote}
											declineQuote={declineQuote}
											syncQuote={syncQuote}
											appendWorkOrderNotes={appendWorkOrderNotes}
											style={style}
											refresh={this.refreshQuote}
											history={history}
										/>
										<CapexSwitch
											quote={quote}
											refresh={this.refreshQuote}
											companyConfig={companyConfig}
										/>
									</Row>
								</div>
							</div>
							<div className={'mt-5 flex flex-row justify-between'}>
								<QuotesAdditionalDetails
									quote={quote}
									passthroughItems={[
										pdfElement || uploadQuote
											? {
													key: 'File',
													value: (
														<div>
															{pdfElement}
															{uploadQuote}
														</div>
													),
													className: 'detailRow__item firstRow lastCol',
											  }
											: {},
									]}
								/>
							</div>
							<Divider />
							<div className="sectionHeaderWrapper">
								<span className="sectionHeaderText">Incurred Cost</span>
								<span className="sectionHeaderText2">
									{currency.format(
										parseFloat(nullSafeGet(`incurredCostTotalBeforeTax`, quote)) || 0
									)}
								</span>
							</div>
							<Divider />
							<div className="sectionHeaderWrapper" style={{ marginBottom: 8 }}>
								<span className="sectionHeaderText">Labor</span>
								<span className="sectionHeaderText2">
									{currency.format(quote.laborTotalBeforeTax)}
								</span>
							</div>
							{nullSafeGetOrElse('laborLineItems', quote, []).map((lli, idx) => (
								<div
									className="sectionHeaderWrapper2"
									style={{
										marginBottom:
											idx === nullSafeGetOrElse('laborLineItems', quote, []).length - 1 ? 16 : 8,
									}}
								>
									<span className="lineItemDetailText">
										{lli.numberOfTech} {lli.roleType} * {getTimeDurationFromSeconds(lli.seconds)} @{' '}
										{currency.format(parseFloat(lli.hourlyRate))} / hour
									</span>
									<span className="lineItemDetailText">
										{currency.format(parseFloat(lli.amount))}
									</span>
								</div>
							))}
							<Divider />

							<div className="sectionHeaderWrapper" style={{ marginBottom: 8 }}>
								<span className="sectionHeaderText">Materials</span>
								<span className="sectionHeaderText2">
									{currency.format(quote.materialTotalBeforeTax)}
								</span>
							</div>
							{nullSafeGetOrElse('materialLineItems', quote, []).map((mli, idx) => (
								<div
									className="sectionHeaderWrapper2"
									style={{
										marginBottom:
											idx === nullSafeGetOrElse('laborLineItems', quote, []).length - 1 ? 16 : 8,
									}}
								>
									<span className="lineItemDetailText">
										{mli.quantity} {mli.unitType} of {mli.description}
										{mli.partNumber ? ` (${mli.partNumber})` : null} @{' '}
										{currency.format(parseFloat(mli.unitPrice))} / {mli.unitType}
									</span>
									<span className="lineItemDetailText">
										{currency.format(parseFloat(mli.amount))}
									</span>
								</div>
							))}
							<Divider />
							<div className="sectionHeaderWrapper">
								<span className="sectionHeaderText">Travel</span>
								<span className="sectionHeaderText2">
									{currency.format(parseFloat(quote.travelTotalBeforeTax) || 0)}
								</span>
							</div>
							<Divider />
							<div className="sectionHeaderWrapper">
								<span className="sectionHeaderText">Freight</span>
								<span className="sectionHeaderText2">
									{currency.format(parseFloat(quote.freightTotalBeforeTax) || 0)}
								</span>
							</div>
							<Divider />
							<div className="sectionHeaderWrapper">
								<span className="sectionHeaderText">Miscellaneous</span>
								<span className="sectionHeaderText2">
									{currency.format(parseFloat(nullSafeGet(`miscTotalBeforeTax`, quote)) || 0)}
								</span>
							</div>
							<Divider />
							<div className="sectionHeaderWrapper">
								<span className="sectionHeaderText">Total Before Tax</span>
								<span className="sectionHeaderText2">{currency.format(quote.totalBeforeTax)}</span>
							</div>
							<Divider />
							<div className="sectionHeaderWrapper">
								<span className="sectionHeaderText">Tax</span>
								<span className="sectionHeaderText2">{currency.format(quote.tax)}</span>
							</div>
							<Divider />
							<div className="sectionHeaderWrapper">
								<span className="sectionHeaderText" style={{ fontWeight: 600 }}>
									Total After Tax
								</span>
								<span className="sectionHeaderText2" style={{ fontWeight: 600 }}>
									{currency.format(quote.totalAfterTax)}
								</span>
							</div>
						</Card>
					</Col>
				</Row>
			</Content>
		) : (
			<EmptyState
				graphic={
					<img
						style={{ marginBottom: 8 }}
						src="https://s3.amazonaws.com/mock-data-assets/categories/images/bank terminal.svg"
						alt="It's a little lonely in here."
					/>
				}
				headline={"You haven't received a quote for this work order yet."}
				body={
					<AccessPermissionChecker name={PERMISSION_NAMES.CAN_UPLOAD_QUOTES_FOR_SUPPLIER}>
						<div style={{ textAlign: 'center' }}>
							<Link to={`/buyer/workOrders/detail/${nullSafeGet('id', workOrder)}/newProposal`}>
								<Button type="primary">Create a quote</Button>
							</Link>
						</div>
					</AccessPermissionChecker>
				}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	quoteFetching: state.proposals.fetching,
	quote: state.proposals.detail,
	quotes: state.proposals,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
	workOrder: getEntityById(ownProps.match.params.id, state.work_orders),
	workOrderFetching: state.work_orders.fetching,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	appendWorkOrderNotes: (entity) => dispatch(appendWorkOrderNotesForBuyer(entity)),
	updateQuote: (entity) => dispatch(quotesRestCrudThunksForBuyer.update(entity)),
	getWorkOrder: (id) => dispatch(workOrdersRestCrudThunksForBuyer.readOne(id)),
	getQuote: (id) => dispatch(quotesRestCrudThunksForBuyer.readOne(id)),
	approveQuote: (entity) => dispatch(proposalStatusChangeForBuyer('awarded', entity)),
	declineQuote: (entity) => dispatch(proposalStatusChangeForBuyer('declined', entity)),
	syncQuote: (entity) => dispatch(syncProposalApprovalHierarchy(entity)),
	markQuoteProcessing: (id) => dispatch(markQuoteProcessingForBuyer({ id })),
	markQuotePending: (id) => dispatch(markQuotePendingForBuyer({ id })),
	markQuotePaid: (id) => dispatch(markQuotePaidForBuyer({ id })),
	disputeQuote: (id) => dispatch(disputeQuoteForBuyer({ id })),
	getQuotes: (params, targetCollectionName) =>
		dispatch(proposalsRestCrudThunksForBuyer.read(params, targetCollectionName)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkOrderQuotesDetailPage));

import * as React from 'react';
import { connect } from 'react-redux';
import {
	regionsRestCrudThunksForBuyer,
	regionsRestCrudThunksForSupplier,
} from '../../thunks/regions_thunks';
import {
	fetchLocationsWithRegionsAndSubRegionsForBuyer,
	fetchLocationsWithRegionsAndSubRegionsForSupplier,
} from '../../thunks/locations_thunks';
import LocationsTreeSelect from '../locations_tree_select/LocationsTreeSelect';
import { LocationDropdownRecord } from '../name_component/location/LocationDropdownRecord';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { withRouter } from 'react-router';

class BuyerRegionsLocationsTreeSelect extends React.Component<any, any> {
	render() {
		const {
			onChange,
			mode,
			targetCollectionName,
			filtersValueAccessor,
			filtersSlice,
			locations,
			disabled,
			fetchLocationsWithHierarchy,
			fetchMultipleRegions,
			noIsActiveFilter = false,
			hiddenRecords,
			style = {},
		} = this.props;
		return (
			<div style={{ ...style }}>
				<LocationsTreeSelect
					allowClear={true}
					style={{ width: '100%' }}
					stateSlice={locations}
					targetCollectionName={targetCollectionName}
					value={filtersValueAccessor(filtersSlice)}
					onChange={onChange}
					disabled={disabled}
					hiddenRecords={hiddenRecords}
					fetchData={(
						searchText,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
					) => {
						return fetchLocationsWithHierarchy(
							{ name: searchText, noIsActiveFilter },
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						);
					}}
					fetchMultiple={(ids, targetCollectionName) =>
						fetchMultipleRegions(ids, targetCollectionName)
					}
					renderRecord={(record) => <LocationDropdownRecord record={record} />}
					sortBy={{ sort_by: 'name', order: 'ascend' }}
					mode={mode}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	filtersSlice: ownProps.filtersSlice,
	regions: state.regions,
	locations: state.locations,
	workOrders: state.work_orders,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchLocationsWithHierarchy: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? fetchLocationsWithRegionsAndSubRegionsForSupplier(
						{ ...params, noIsActiveFilter: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: fetchLocationsWithRegionsAndSubRegionsForBuyer(
						{ ...params, noIsActiveFilter: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchRegions: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? regionsRestCrudThunksForSupplier.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: regionsRestCrudThunksForBuyer.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultipleRegions: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? regionsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: regionsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(BuyerRegionsLocationsTreeSelect)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

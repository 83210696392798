import React, { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { ROLE_TYPES } from '../../utils/DataConstants';
import SiteSurveyForm from '../site_survey_form/SiteSurveyForm';
import {
	siteSurveysRestCrudThunksForBuyer,
	siteSurveysRestCrudThunksForSupplier,
} from '../../thunks/site_surveys_thunks';
import { getLinkWIthBackLinkParams, goBackLink } from '../../utils/HistoryUtils';

const queryString = require('qs');

const EditSiteSurveyPage: FC<any> = ({
	getSiteSurvey,
	siteSurvey,
	match,
	history,
	location,
	fetching,
	userType,
}): React.ReactElement => {
	const siteSurveyId = useMemo(() => match.params.id, [match.params.id]);
	const urlPath = `/${userType}/siteSurveys/detail/${siteSurveyId}/details`;

	useEffect(() => getSiteSurvey(siteSurveyId), [siteSurveyId]);

	return (
		<>
			<LogOnMountWithStandardEventProperties eventType="visited edit site survey template page" />
			<EditPage
				title="Edit Site Survey"
				formComponentProps={{
					formData: siteSurvey,
					onSuccess: (rec) => {
						history.push(goBackLink(location, urlPath));
					},
				}}
				fetching={fetching}
				FormComponent={SiteSurveyForm}
			/>
		</>
	);
};

const mapStateToProps = (state, ownProps) => {
	return {
		history: ownProps.history,
		siteSurvey: state.site_surveys.detail,
		fetching: state.site_surveys.fetching,
		match: ownProps.match,
		location: ownProps.location,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	getSiteSurvey: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? siteSurveysRestCrudThunksForSupplier.readOne(id)
				: siteSurveysRestCrudThunksForBuyer.readOne(id)
		),
});

const EditSiteSurveyPageWR = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(EditSiteSurveyPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(EditSiteSurveyPageWR);

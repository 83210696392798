import { EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/equipment_per_stock_locations_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = standardInitialStateGenerator([
	'EQUIPMENT_PER_STOCK_LOCATIONS_FOR_EQUIPMENT',
	'equipmentPerStockLocationsIndex',
	'stockLocationAssociatedEquipments',
	'serviceCallEquipmentsPerStockLocationsDropdown',
]);
export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState);

import * as React from 'react';
import {
	Alert,
	Button,
	Checkbox,
	Col,
	Input,
	Layout,
	List,
	Modal,
	Popconfirm,
	Popover,
	Row,
	Typography,
} from 'antd';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import {
	getCurrency,
	getObjectValues,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import ApprovalTiersComponent from '../approval_tiers_component/ApprovalTiersComponent';
import SubnavBar from '../subnav_bar/SubnavBar';
import { fetchFacilityActiveBuyerUsersAndInternalSupplierUsers } from '../../thunks/users_thunks';
import ModalHeaderSteps from '../modal_header_steps_component/modalHeaderSteps';
import { EmptyState } from '../empty_state/EmptyState';
import {
	approvalsRestCrudThunksForBuyer,
	deleteBuyerCompanyApprovalTiers,
	editBuyerCompanyApprovalTiers,
	getBuyerCompanyApprovalTiers,
	patchBuyerCompanyApprovalTiers,
	updateBuyerCompanyApprovalTiers,
} from '../../thunks/company_approval_thunks';
import UserSelectComponent from '../user_select_component/UserSelectComponent';
import UserListComponent from '../user_list_component/UserListComponent';
import {
	companyConfigRestCrudThunks,
	companyConfigRestCrudThunksForBuyer,
} from '../../thunks/company_config_thunks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { mappingApprovalTypesForDisplay } from '../../utils/DataMappingUtils';

const { Content } = Layout;

const style = require('./ApprovalHierarchyComponent.less');

function ApprovalHierarchyComponent({
	id,

	fetchBuyerCompanyApprovalSettings,
	getApprovalHierarchy,
	updateBuyerCompanyApprovalSettings,
	patchBuyerCompanyApprovalSettings,
	editBuyerCompanyApprovalSettings,
	deleteBuyerCompanyApprovalSettings,
	getBuyerCompanyConfig,
	updateBuyerCompanyConfig,
	fetchBuyerUsers,

	currentUser,
	companyConfig,
	token,
	history,
	approvalHierarchies,
	users,

	companyConfigFetching,
	usersFetching,
}) {
	const [allowTechnicians, setAllowTechnicians] = useState(false);
	const [toggleLoading, setToggleLoading] = useState(false);
	const [initialLoading, setInitialLoading] = useState(true);
	const [tiers, setTiers] = useState([]);
	const [approvalHierarchy, setApprovalHierarchy] = useState({});
	const [pendingTiers, setPendingTiers] = useState([]);
	const [isDragging, setIsDragging] = useState(false);
	const [dragUser, setDragUser] = useState();
	const [showNeedsApprover, setShowNeedsApprover] = useState(false);
	const [showOutOfSequence, setShowOutOfSequence] = useState(false);
	const [approvalHierarchyName, setApprovalHierarchyName] = useState('');

	const [approvalModalVisible, setApprovalModalVisible] = useState(false);
	const [approvalNameModalVisible, setApprovalNameModalVisible] = useState(false);
	const [usersModalVisible, setUsersModalVisible] = useState(false);

	const refreshTiers = () => {
		setInitialLoading(true);
		if (id) {
			getApprovalHierarchy(id).then((result) => {
				convertApprovalTiers(result);
			});
		} else {
			setTiers([]);
			setApprovalHierarchy(null);
			setInitialLoading(false);
		}
	};
	useEffect(() => {
		refreshTiers();
	}, []);

	useEffect(() => {
		getBuyerCompanyConfig(currentUser).then((result) => {
			const allowTechnicians = nullSafeGetOrElse(
				'config.approvalConfig.allowToApproveProposalByInternalTech',
				result,
				false
			);

			const fetchUserCall = allowTechnicians ? 'internal' : 'buyer';
			fetchBuyerUsers(fetchUserCall);
			setAllowTechnicians(allowTechnicians);
		});
	}, []);

	const toggleAllowTechnicians = () => {
		const type = !allowTechnicians ? 'internal' : 'buyer';
		setToggleLoading(true);
		updateBuyerCompanyConfig({
			...companyConfig,
			config: {
				...companyConfig.config,
				workOrder: {
					...companyConfig.config.workOrder,
					['allowToApproveProposalByInternalTech']: !allowTechnicians,
				},
			},
		}).then(() =>
			fetchBuyerUsers(type).then(() => {
				setAllowTechnicians(!allowTechnicians);
				setToggleLoading(false);
			})
		);
	};
	const convertApprovalTiers = (hierarchy) => {
		let tierDetails = JSON.parse(JSON.stringify(hierarchy.tierDetails));
		setApprovalHierarchyName(hierarchy.name);
		const tiers = tierDetails.map((tier, i) => {
			let amountUpto = nullSafeGetOrElse('amountUpto', tier, undefined);
			let approvers = nullSafeGet('approvers', tier);
			return {
				id: i,
				num: nullSafeGet('label', tier),
				name: nullSafeGetOrElse('name', tier, ''),
				amount: amountUpto !== undefined ? parseFloat(amountUpto) : amountUpto,
				users: approvers.filter((approver) => {
					let type = nullSafeGet('approverType', approver);
					return type === 'user'; //need to eventually handle roles
				}),
				daysAfterEscalation: tier.daysAfterEscalation,
			};
		});
		setTiers(tiers);
		setApprovalHierarchy(hierarchy);
		setInitialLoading(false);
	};
	const showApprovalNameModal = (e) => {
		setApprovalNameModalVisible(true);
		setApprovalModalVisible(false);
		setUsersModalVisible(false);
	};
	const showApprovalModal = (e) => {
		const email = nullSafeGet('email', currentUser);
		let pendingTiers = JSON.parse(JSON.stringify(tiers));
		if (tiers.length < 1) {
			pendingTiers = [
				{
					id: 0,
					num: 1,
					users: [
						{
							approverType: 'user',
							contact: currentUser,
							email: email,
						},
					],
				},
			];
		}
		setApprovalNameModalVisible(false);
		setApprovalModalVisible(true);
		setUsersModalVisible(false);
		setPendingTiers(pendingTiers);
	};
	const backToApprovalModal = (e) => {
		setApprovalModalVisible(true);
		setUsersModalVisible(false);
	};
	const backToNameModal = () => {
		setApprovalNameModalVisible(true);
		setApprovalModalVisible(false);
		setUsersModalVisible(false);
	};
	const showUsersModal = (e) => {
		setApprovalNameModalVisible(false);
		setApprovalModalVisible(false);
		setUsersModalVisible(true);
	};
	const onCancel = (e) => {
		setApprovalNameModalVisible(false);
		setApprovalModalVisible(false);
		setUsersModalVisible(false);
		setPendingTiers([...tiers]);
	};
	const onSubmit = (e) => {
		approvalHierarchy === undefined || approvalHierarchy === null
			? submitApprovalTiers()
			: updateApprovalTiers();
		setUsersModalVisible(false);
		setTiers(pendingTiers);
	};
	const updateLevels = (tiers) => {
		setPendingTiers(tiers);
	};
	const removeUser = (level, user, e) => {
		let pendingT = JSON.parse(JSON.stringify(pendingTiers));
		const tierList = pendingT.map((t) => {
			if (t.id === level.id) {
				t = removeUserFromLevel(user, level);
			}
			return t;
		});
		setPendingTiers(tierList);
	};

	//User Selection
	const onChangeSearch = (tierId, value, e) => {
		const user = JSON.parse(value);
		const tierList = pendingTiers.map((t) => {
			if (t.id === tierId) {
				t = addUserToTier(user, t);
			} else if (user.level != null) {
				if (t.id === user.level.id) {
					t = removeUserFromLevel(user, t);
				}
			}
			return t;
		});
		setPendingTiers(tierList);
	};

	const onSearchFocus = (self) => {};
	const onSearchBlur = (tier, e) => {
		let pendingTierList = JSON.parse(JSON.stringify(pendingTiers));
		const tierList = pendingTierList.map((t) => {
			t.showSearch = false;
			return t;
		});
		setPendingTiers(tierList);
	};
	const onSearch = (value) => {};

	const addUserToTier = (user, t) => {
		t.users.push(user);
		return t;
	};
	const removeUserFromLevel = (user, t) => {
		for (let i = 0; t.users.length > i; i++) {
			if (t.users[i].contact.email === user.contact.email) {
				t.users.splice(i, 1);
				break;
			}
		}
		return t;
	};

	const clickOffDropdown = (t, e) => {
		document.body.removeEventListener('onClick', clickOffDropdown.bind(t));
		toggleSearch(JSON.stringify(t), e);
	};
	const toggleSearch = (t, e) => {
		t = JSON.parse(t);
		t.showSearch = !t.showSearch;
		let pendingTierList = JSON.parse(JSON.stringify(pendingTiers));
		const tierList = pendingTierList.map((tier) => {
			if (t.id === tier.id) {
				tier = t;
			}
			return tier;
		});

		document.body.addEventListener('onClick', clickOffDropdown.bind(t));
		setPendingTiers(tierList);
	};
	//Drag & Drop
	const startDragging = (user, e) => {
		setDragUser(JSON.parse(user));
	};
	const stopDragging = (e) => {
		setIsDragging(false);
	};
	const userDropped = (level, e) => {
		e.preventDefault();
		e.stopPropagation();
		const user = dragUser;
		let pendingTierList = JSON.parse(JSON.stringify(pendingTiers));
		const tierList = pendingTierList.map((t) => {
			if (t.id === level.id) {
				for (let i = 0; t.users.length > i; i++) {
					if (user.contact.email === t.users[i].contact.email) {
						t = removeUserFromLevel(user, t);
						break;
					}
				}
				t = addUserToTier(user, t);
			} else {
				for (let i = 0; t.users.length > i; i++) {
					if (user.contact.email === t.users[i].contact.email) {
						t = removeUserFromLevel(user, t);
						break;
					}
				}
			}
			return t;
		});
		setPendingTiers(tierList);
		setIsDragging(false);
		setDragUser(undefined);
	};

	const submitApprovalTiers = () => {
		const pendingTierList = revertApprovalTiers(pendingTiers);
		const obj = {
			name: approvalHierarchyName,
			buyerCompanyId: currentUser.facility.buyerCompanyId,
			tierDetails: pendingTierList,
		};
		updateBuyerCompanyApprovalSettings(obj).then((result) =>
			history.push(`/buyer/approvalHierarchies/detail/templates/${result.id}/details`)
		);
	};

	const updateApprovalTiers = () => {
		const pendingTierList = revertApprovalTiers(pendingTiers);
		const obj = {
			id: approvalHierarchy.id,
			buyerCompanyId: currentUser.facility.buyerCompanyId,
			tierDetails: pendingTierList,
			name: approvalHierarchyName,
		};
		editBuyerCompanyApprovalSettings(obj).then((result) => setApprovalHierarchy(result));
	};

	const revertApprovalTiers = (tierList) => {
		return tierList.map((t, i) => {
			if (tierList.length - 1 === i) {
				//Last tier has no amountUpto limit and therefore should be omitted
				return {
					label: t.num.toString(),
					name: t.name,
					approvedByType: 'any',
					approvers: t.users.map((user) => {
						return {
							approverType: 'user',
							contactType: nullSafeGetOrElse('contact.contactType', user, 'buyer'),
							email: nullSafeGet('contact.email', user),
						};
					}),
					daysAfterEscalation: t.daysAfterEscalation,
				};
			} else {
				return {
					label: t.num.toString(),
					name: t.name,
					amountUpto: t.amount.toString(),
					approvedByType: 'any',
					approvers: t.users.map((user) => {
						return {
							approverType: 'user',
							contactType: nullSafeGetOrElse('contact.contactType', user, 'buyer'),
							email: nullSafeGet('contact.email', user),
						};
					}),
					daysAfterEscalation: t.daysAfterEscalation,
				};
			}
		});
	};
	const checkOutOfSequence = (tierList) => {
		let amounts = [];
		let outOfSequence = false;
		tierList.forEach((t) => {
			if (t.amount !== undefined) {
				if (amounts.every((amount) => t.amount >= amount)) {
					amounts.push(t.amount);
				} else {
					outOfSequence = true;
				}
			}
		});
		setShowOutOfSequence(outOfSequence);
		return outOfSequence;
	};
	const userDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};
	const deleteApprovalTiers = (e) => {
		deleteBuyerCompanyApprovalSettings(tiers, nullSafeGet('id', approvalHierarchy)).then(
			(results) => {
				setTiers([]);
			}
		);
	};

	const currency = getCurrency({ currentUser });
	const Users = getObjectValues(users).map((el) => ({
		...el,
		key: el.contact.email,
	}));
	const filteredUsers = Users.map((user) => {
		user['level'] = null;
		user['status'] = null;
		pendingTiers.map((pendingLevel) =>
			pendingLevel.users.map((levelUser) => {
				if (nullSafeGet('contact.email', user) === nullSafeGet('contact.email', levelUser)) {
					user['level'] = pendingLevel;
					user['status'] = 'Current Tier: '.concat(pendingLevel.num);
				}
			})
		);
		return user;
	});
	let steps = [
		{
			title: 'Set Title',
			isActive: approvalNameModalVisible,
			icon: null,
		},
		{
			title: 'Set Approval Tiers',
			isActive: approvalModalVisible,
			icon: null,
		},
		{
			title: 'Add Approvers',
			isActive: usersModalVisible,
			icon: null,
		},
	];

	const addUserListLoading = companyConfigFetching || usersFetching || toggleLoading;

	let loading = nullSafeGetOrElse('loading', approvalHierarchies, false) || initialLoading;
	let modalHeaderSteps = <ModalHeaderSteps steps={steps} />;
	let lastAmount;

	return loading ? (
		<PageLoadingPlaceholder />
	) : (
		<Content className="buyerApprovalSettingsPage">
			<LogOnMountWithStandardEventProperties eventType="Visited buyer admin approval settings page" />
			<div
				style={{
					display: 'flex',
					width: '100%',
					left: '0',
					flexDirection: 'row',
					justifyContent: 'center',
					position: 'fixed',
					top: '20px',
					zIndex: 9999,
				}}
			>
				<Alert
					type={'error'}
					style={{
						display: showNeedsApprover ? 'flex' : 'none',
						flexDirection: 'row',
						textAlign: 'center',
						zIndex: 9999,
					}}
					message={'You must assign at least one user to each tier'}
					showIcon
				/>
			</div>
			<Modal
				visible={approvalNameModalVisible}
				width={600}
				title={modalHeaderSteps}
				destroyOnClose={true}
				bodyStyle={{ overflow: 'hidden', padding: '12px 24px' }}
				footer={
					<Row>
						<div style={{ marginLeft: 'auto' }}>
							<Popconfirm
								onConfirm={onCancel}
								placement="topLeft"
								title="Are you sure you want to cancel?"
								okText="Yes"
								cancelText="No"
							>
								<Button>Cancel</Button>
							</Popconfirm>
							<Button
								type={'primary'}
								style={{ marginLeft: '8px' }}
								onClick={(e) => {
									if (checkOutOfSequence(pendingTiers)) {
									} else {
										showApprovalModal(e);
									}
								}}
							>
								Next
							</Button>
						</div>
					</Row>
				}
			>
				<span>Approval Hierarchy Title</span>
				<Input
					defaultValue={approvalHierarchyName}
					onChange={(e) => setApprovalHierarchyName(e.target.value)}
				/>
			</Modal>

			<ApprovalTiersComponent
				showOutOfSequence={showOutOfSequence}
				checkOutOfSequence={checkOutOfSequence}
				tiers={pendingTiers}
				updateLevels={updateLevels}
				approvalModalVisible={approvalModalVisible}
				modalHeaderSteps={modalHeaderSteps}
				backToApprovalModal={backToApprovalModal}
				pendingTiers={pendingTiers}
				showUsersModal={showUsersModal}
				backToNameModal={backToNameModal}
			/>
			<Modal
				visible={usersModalVisible}
				width={560}
				title={modalHeaderSteps}
				okText={
					approvalHierarchy === null || approvalHierarchy === undefined
						? 'Create Approval Hierarchy'
						: 'Update Approval Hierarchy'
				}
				onCancel={backToApprovalModal}
				cancelText="Back"
				onOk={(e) => {
					onSubmit(e);
				}}
				closable={false}
				style={style}
				maskClosable={false}
			>
				<div style={{ marginBottom: '20px' }}>
					<Checkbox
						disabled={addUserListLoading}
						checked={allowTechnicians}
						onChange={(e) => toggleAllowTechnicians()}
					/>
					<span style={{ marginLeft: '8px' }}>
						Include Internal Technician Team In Approval Hierarchy
					</span>
				</div>
				<div className={'UsersModalBody'}>
					{pendingTiers.map((level, i, levs) => {
						lastAmount =
							nullSafeGetOrElse('amount', levs[i - 1], 0) === level.amount
								? lastAmount
								: nullSafeGetOrElse('amount', levs[i - 1], 0);
						return (
							<div key={level.id.toString()} className={'UsersContainer'} style={style}>
								<div className={'UsersContainerLabel'}>
									<span>{level.name && level.name !== '' ? level.name : `Tier ${level.num}`}</span>
									<Typography.Text className={'AmountLabel'}>
										{pendingTiers.length - 1 !== i
											? i === 0
												? `${currency.format(0, 0)} to ${currency.format(level.amount, 0)}`
												: `${currency.format(lastAmount, 0)} to ${currency.format(level.amount, 0)}`
											: `Over ${currency.format(
													pendingTiers.length > 1 ? pendingTiers[i - 1].amount : 0,
													0
											  )}`}
									</Typography.Text>
								</div>
								<UserListComponent
									users={level.users}
									token={token}
									style={style}
									allowRemove={true}
									removeUser={removeUser.bind(null, level)}
									rightItems={null}
									emptyState={<span>No users have been added to this tier!</span>}
									trailingItems={[
										<List.Item
											key={'userDrop'}
											className={'DropUser'}
											onDrop={userDropped.bind(null, level)}
											onDragOver={userDragOver}
											style={{
												height: isDragging ? '70px' : '0px',
												border: isDragging ? '4px solid purple' : '0px',
												borderRadius: isDragging ? '12px' : '0px',
												padding: '0px',
											}}
										></List.Item>,
										<List.Item key={'userAdd'} className={'AddUser'}>
											<Button
												loading={addUserListLoading}
												className={'AddUserButton'}
												onClick={toggleSearch.bind(null, JSON.stringify(level))}
												style={{ display: level.showSearch ? 'none' : 'inline-block' }}
											>
												Add User
											</Button>
											<div className="SearchUsers">
												{level.showSearch ? (
													<UserSelectComponent
														config={{
															style: { width: '512px' },
															placeholder: 'Select a person',
															optionFilterProp: 'label',
															onChange: onChangeSearch.bind(null, level.id),
															onFocus: onSearchFocus.bind(null),
															defaultActiveFirstOption: true,
															onBlur: (e) => onSearchBlur(level, e),
															value: null,
															onSearch: onSearch,
														}}
														show={level.showSearch}
														users={filteredUsers.map((user) => {
															return {
																...user,
																show: user.level && level.num === user.level.num ? false : true,
															};
														})}
													/>
												) : null}
											</div>
										</List.Item>,
									]}
								/>
							</div>
						);
					})}
				</div>
			</Modal>
			{/*<ScrollToTopOnMount/>*/}
			{/*<BackTop/>*/}
			<Row type="flex" className="rowSpacing">
				<Col style={{ margin: 'auto' }} span={24}>
					<SubnavBar
						left={<div />}
						right={
							<div>
								<Button
									type="primary"
									size="large"
									onClick={showApprovalNameModal}
									style={{ marginRight: 12 }}
								>
									{tiers.length > 0 ? 'Edit' : 'Create'}
								</Button>
								{/*tiers.length > 0 ? (
									<Popconfirm
										onConfirm={deleteApprovalTiers}
										placement="bottomLeft"
										title="Are you sure you want to delete your approval tiers?"
										okText="Yes"
										cancelText="No"
									>
										<Button danger style={{ marginRight: 12 }} size="large" type="primary">
											Delete
										</Button>
									</Popconfirm>
								) : null*/}
							</div>
						}
					/>
				</Col>
			</Row>
			<Row type="flex" className="rowSpacing RowBody">
				<Col>
					{tiers.length > 0 ? (
						tiers.map((level, i, levs) => {
							const escalationElement =
								level.daysAfterEscalation > -1 ? (
									<Popover
										content={
											level.daysAfterEscalation > 0
												? `Pending approvals will automatically escalate to the next tier in ${nullSafeGet(
														'daysAfterEscalation',
														level
												  )} day${level.daysAfterEscalation > 1 ? 's' : ''}.`
												: `Pending approvals will automatically escalate to the next tier immediately.`
										}
									>
										<div className={'UsersContainerEscalation'}>
											<i className="icons8-font icons8-stairs-up"></i>
											<Typography.Text className={'UsersContainerEscalationLabel ml-2'}>
												{level.daysAfterEscalation} day{level.daysAfterEscalation > 1 ? 's' : ''}
											</Typography.Text>
										</div>
									</Popover>
								) : undefined;
							lastAmount =
								nullSafeGetOrElse('amount', levs[i - 1], 0) === level.amount
									? lastAmount
									: nullSafeGetOrElse('amount', levs[i - 1], 0);
							return (
								<div key={level.id.toString()} className={'UsersContainer'} style={style}>
									<div className={'UsersContainerLabel'}>
										<span>
											<span>
												{level.name && level.name !== '' ? level.name : `Tier ${level.num}`}
											</span>
											<Typography.Text className={'AmountLabel mr-8'}>
												{tiers.length - 1 !== i
													? i === 0
														? `${currency.format(0, 0)} to ${currency.format(level.amount, 0)}`
														: `${currency.format(lastAmount, 0)} to ${currency.format(
																level.amount,
																0
														  )}`
													: `Over ${currency.format(
															tiers.length > 1 ? tiers[i - 1].amount : 0,
															0
													  )}`}
											</Typography.Text>
										</span>
										<span className={'ml-auto flex'}>{escalationElement}</span>
									</div>
									<UserListComponent
										users={level.users}
										rightItems={null}
										token={token}
										style={style}
										allowRemove={false}
										removeUser={removeUser.bind(null, level)}
										trailingItems={[]}
										emptyState={
											<span className={'ml-2, mt-1.5'}>No users have been added to this tier!</span>
										}
									/>
								</div>
							);
						})
					) : (
						<EmptyState
							graphic={
								<img
									style={{ marginBottom: 8 }}
									src="https://s3.amazonaws.com/mock-data-assets/categories/images/bank terminal.svg"
								/>
							}
							headline={"You haven't set up this Approval Hierarchy yet!"}
							body={
								<div style={{ textAlign: 'center' }}>
									<div style={{ maxWidth: 440, marginBottom: 16 }}>
										<Button
											style={{ marginLeft: 16 }}
											type="primary"
											size="large"
											onClick={showApprovalNameModal}
										>
											{tiers.length > 0 ? 'Update Approval Hierarchy' : 'Create Approval Hierarchy'}
										</Button>
									</div>
								</div>
							}
						/>
					)}
				</Col>
			</Row>
		</Content>
	);
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	currentUser: state.session.currentUser,
	token: state.session.token,
	usersFetching: nullSafeGetOrElse('buyer_users.loading', state, false),
	users: [
		...getObjectValues(nullSafeGetOrElse('buyer_users.records', state, {})),
		...getObjectValues(nullSafeGetOrElse('supplier_users.records', state, {})),
	],
	approvalHierarchies: state.approval_hierarchies,
	approvalHierarchiesPatching: state.approval_hierarchies.patching,
	companyConfigFetching: state.company_config.fetching,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	getApprovalHierarchy: (id) => dispatch(approvalsRestCrudThunksForBuyer.readOne(id)),
	fetchBuyerUsers: (type) =>
		dispatch(fetchFacilityActiveBuyerUsersAndInternalSupplierUsers(type)()),
	fetchBuyerCompanyApprovalSettings: (id) => dispatch(getBuyerCompanyApprovalTiers('buyer', id)()),
	updateBuyerCompanyApprovalSettings: (tiers) => dispatch(updateBuyerCompanyApprovalTiers(tiers)()),
	editBuyerCompanyApprovalSettings: (tiers) => dispatch(editBuyerCompanyApprovalTiers(tiers)()),
	deleteBuyerCompanyApprovalSettings: (tiers, id) =>
		dispatch(deleteBuyerCompanyApprovalTiers(tiers, id)()),
	patchBuyerCompanyApprovalSettings: (id, mirrorId, type) =>
		dispatch(patchBuyerCompanyApprovalTiers(id, mirrorId, type)()),
	getBuyerCompanyConfig: (contact) => dispatch(companyConfigRestCrudThunks('buyer', contact)),
	updateBuyerCompanyConfig: (settings) =>
		dispatch(companyConfigRestCrudThunksForBuyer.update(settings)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(ApprovalHierarchyComponent)
);

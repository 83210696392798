import React from 'react';
import { Button, Popconfirm, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	purchaseRequestStatusChangeForSupplier,
	purchaseRequestsRestCrudThunksForSupplier,
} from '../../thunks/purchase_requests_thunks';
import { Link } from 'react-router-dom';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import {
	EDITABLE_PR_STATUSES,
	PURCHASE_REQUEST_STATUS_DISPLAY_NAMES,
} from '../../constants/PurchaseRequestStatuses';
import { getBackLinkStates, getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import {
	getCurrency,
	getCurrencyIdIFSamePRLineItemCurrency,
	getEntityById,
	nullSafeGet,
	renderCurrencyByCurrencyId,
} from '../../utils/DataAccessUtils';
import { isSupplierInventoryConfigEnabled } from '../common/SupplierInventoryConfigPermissionChecker';
import { CONFIG_NAMES } from '../../utils/AuthUtils';
import { PURCHASE_REQUESTS_CRUD_ACTION_CREATORS } from '../../actions/purchase_requests_actions';
import { updatePaginationEntities } from '../../thunks/thunks_wrapper';
import NextPreviousButton from '../next_previous_button/NextPreviousButton';

const DEFAULT_BACK_LINK_TEXT = 'Back to purchase requests';

class PurchaseRequestsDetailPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			lightboxIsOpen: false,
			currentIndex: 0,
			backLinkText: DEFAULT_BACK_LINK_TEXT,
			backLinkTo: '/supplier/purchaseRequests',
			rejecting: false,
			cancelling: false,
		};
	}

	goToPrevious = () => {
		this.setState({ currentIndex: this.state.currentIndex - 1 });
	};

	goToNext = () => {
		this.setState({ currentIndex: this.state.currentIndex + 1 });
	};

	refreshPR = () => {
		const { getPurchaseRequest, match } = this.props;
		const purchaseRequestId = match.params.id;
		getPurchaseRequest(purchaseRequestId);
	};

	componentDidMount() {
		const { location, purchaseRequest, getPurchaseRequestDelta } = this.props;
		if (nullSafeGet('id', purchaseRequest)) {
			getPurchaseRequestDelta(nullSafeGet('id', purchaseRequest));
		} else {
			this.refreshPR();
		}
		this.setState(getBackLinkStates(location, DEFAULT_BACK_LINK_TEXT));
	}

	componentDidUpdate(prevProps) {
		const { purchaseRequest, getPurchaseRequestDelta } = this.props;
		const { purchaseRequest: prevPurchaseRequest } = prevProps;
		if (purchaseRequest && prevPurchaseRequest && purchaseRequest.id !== prevPurchaseRequest.id) {
			getPurchaseRequestDelta(purchaseRequest.id);
		}
	}

	handleTabChange = (key) => {
		const { history, match } = this.props;
		const purchaseRequestId = match.params.id;
		history.push(`/supplier/purchaseRequests/detail/${purchaseRequestId}/${key}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	handleCancel = () => {
		const { cancelPurchaseRequest, purchaseRequest } = this.props;
		this.setState({ cancelling: true });
		cancelPurchaseRequest(purchaseRequest.id)
			.then(() => {
				message.success('Cancelled successfully!');
				this.refreshPR();
			})
			.finally(() =>
				this.setState({
					cancelling: false,
				})
			);
	};

	handleReject = () => {
		const { rejectPurchaseRequest, purchaseRequest } = this.props;
		this.setState({ rejecting: true });
		rejectPurchaseRequest(purchaseRequest.id)
			.then(() => {
				message.success('Reject successfully!');
				this.refreshPR();
			})
			.finally(() =>
				this.setState({
					rejecting: false,
				})
			);
	};

	render() {
		const {
			purchaseRequest,
			purchaseRequestsFetching,
			match,
			companyConfig,
			purchaseRequests,
			location,
			history,
			updatePagination,
			cloneTargetCollection,
		} = this.props;
		const purchaseRequestId = match.params.id;
		const equipmentLineItemAvailable = nullSafeGet(
			'equipmentSupplierPurchaseRequestLineItems.0',
			purchaseRequest
		);
		const equipmentWithSLAvailable =
			nullSafeGet('stockLocationId', purchaseRequest) && equipmentLineItemAvailable;
		const equipmentWithLocationAvailable =
			nullSafeGet('locationId', purchaseRequest) && equipmentLineItemAvailable;

		const poDisabled = isSupplierInventoryConfigEnabled(
			companyConfig,
			CONFIG_NAMES.DISABLE_PURCHASE_ORDERS
		);

		const tabs = [
			{
				key: 'details',
				name: 'Overview',
			},
			...(poDisabled && equipmentWithSLAvailable
				? [
						{
							key: 'receivedEquipments',
							name: 'Received Equipment',
						},
				  ]
				: []),
			...(poDisabled && equipmentWithLocationAvailable
				? [
						{
							key: 'receivedAssets',
							name: 'Received Assets',
						},
				  ]
				: []),
		];
		const canEdit = EDITABLE_PR_STATUSES.includes(nullSafeGet('status', purchaseRequest));

		const canReject = nullSafeGet('status', purchaseRequest) === 'approved';

		const canCancel = ['approved', 'requested'].includes(nullSafeGet('status', purchaseRequest));

		const prCurrencyId = getCurrencyIdIFSamePRLineItemCurrency(purchaseRequest);

		const fetching = purchaseRequestsFetching && !nullSafeGet('id', purchaseRequest);
		return (
			<DetailPageHeader
				className="purchaseRequestsDetailPage"
				exactPath={'/supplier/purchaseRequests/detail/:id'}
				redirectPath={`/supplier/purchaseRequests/detail/${purchaseRequestId}/details`}
				backLinkText={this.state.backLinkText}
				backLinkTo={this.state.backLinkTo}
				title={fetching ? null : purchaseRequest.name}
				subtitle={
					<div className="purchaseRequestsDetailPage__subtitleRow">
						<div className="purchaseRequestsDetailPage__subtitle">
							{PURCHASE_REQUEST_STATUS_DISPLAY_NAMES[purchaseRequest.status]}
						</div>
						{purchaseRequest.totalCost && prCurrencyId ? (
							<div className="purchaseRequestsDetailPage__subtitle">
								{renderCurrencyByCurrencyId(prCurrencyId)(purchaseRequest.totalCost)}
							</div>
						) : null}
					</div>
				}
				actions={[
					<Link
						to={getLinkWIthBackLinkParams(
							location,
							'Back to Purchase Request Detail',
							`/supplier/purchaseRequests/edit/${purchaseRequestId}`
						)}
						key={1}
						style={{ marginRight: 16 }}
					>
						<Button ghost={true} disabled={!canEdit}>
							Edit
						</Button>
					</Link>,
					...(canReject
						? [
								<Popconfirm
									title="Are you sure you want to reject this purchase request?"
									onConfirm={this.handleReject}
									okText="Reject"
									cancelText="Cancel"
									className="mr-4"
								>
									<Button ghost={true} loading={this.state.rejecting}>
										Reject
									</Button>
								</Popconfirm>,
						  ]
						: []),
					...(canCancel
						? [
								<Popconfirm
									title="Are you sure you want to cancel this purchase request?"
									onConfirm={this.handleCancel}
									okText="Cancel"
									cancelText="No"
									className="mr-4"
								>
									<Button key={2} ghost={true} loading={this.state.cancelling}>
										Cancel
									</Button>
								</Popconfirm>,
						  ]
						: []),
				]}
				tabs={tabs}
				backgroundColor={nullSafeGet('config.theme.navblock', companyConfig)}
				handleTabChange={this.handleTabChange}
				checkTabActive={this.checkTabActive}
				childRoutes={this.props.childRoutes}
				tabsRightActions={() => (
					<NextPreviousButton
						key={this.state.fromTargetCollectionName}
						location={location}
						history={history}
						activeId={nullSafeGet('id', purchaseRequest)}
						getEnitityUrl={(id) => `/supplier/purchaseRequests/detail/${id}/details`}
						stateSlice={purchaseRequests}
						targetCollectionName={this.state.fromTargetCollectionName}
						updatePagination={updatePagination}
						cloneTargetCollection={cloneTargetCollection}
						entityName="purchase request"
					/>
				)}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	purchaseRequestsFetching: state.purchase_requests.fetching,
	purchaseRequests: state.purchase_requests,
	purchaseRequest: getEntityById(ownProps.match.params.id, state.purchase_requests),
	match: ownProps.match,
	location: ownProps.location,
	history: ownProps.history,
	companyConfig: state.company_config.detail,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	getPurchaseRequest: (id) => dispatch(purchaseRequestsRestCrudThunksForSupplier.readOne(id)),
	getPurchaseRequestDelta: (id) =>
		dispatch(purchaseRequestsRestCrudThunksForSupplier.readDelta(id)),
	rejectPurchaseRequest: (id) => dispatch(purchaseRequestStatusChangeForSupplier(id, 'denied')),
	cancelPurchaseRequest: (id) => dispatch(purchaseRequestStatusChangeForSupplier(id, 'cancelled')),
	cloneTargetCollection: (from, to) =>
		dispatch(PURCHASE_REQUESTS_CRUD_ACTION_CREATORS.cloneTargetCollection(from, to)),
	updatePagination: (pagination, targetCollectionName) =>
		dispatch(
			updatePaginationEntities(
				'supplier',
				'purchase_requests',
				purchaseRequestsRestCrudThunksForSupplier
			)(false, undefined, targetCollectionName, pagination, true)
		),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PurchaseRequestsDetailPage));

import React from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
	Input,
	Alert,
	Layout,
	Button,
	Row,
	Col,
	InputNumber,
	DatePicker,
	Radio,
	message,
} from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { nullSafeGetOrElse, nullSafeGet, getCurrency } from '../../utils/DataAccessUtils';
import BackButton from '../back_button/BackButton';
import { debounce } from '../../utils/PerformanceUtils';
import {
	workOrdersRestCrudThunksForBuyer,
	workOrdersRestCrudThunksForSupplier,
} from '../../thunks/work_orders_thunks';
import {
	createInvoiceByInternalTech,
	fetchTaxLineItemsForBuyer,
	fetchTaxLineItemsForSupplier,
	getInvoiceNumber,
	getInvoiceNumberBuyer,
	invoicesRestCrudThunksForBuyer,
	invoicesRestCrudThunksForSupplier,
} from '../../thunks/invoices_thunks';
import moment from 'moment';
import {
	proposalsRestCrudThunksForBuyer,
	proposalsRestCrudThunksForSupplier,
} from '../../thunks/proposals_thunks';
import FormDots from '../common/FormDots';
import { ROLE_TYPES } from '../../utils/DataConstants';
import OWRichTextEditor from '../../rich_text_editor/OWRichTextEditor';

require('./BuyerInvoiceForm.less');

interface BuyerInvoiceFormProps extends FormComponentProps {
	formData: any;
	onSuccess: any;
	creating?: boolean;
	updating?: boolean;
	createErrors?: string[];
	updateErrors?: string[];
	redirectForwardUrl?: string;
	redirectBackwardUrl?: string;
	currentUser?: any;
	history?: any;
	createInvoice?: any;
	updateInvoice?: any;
	workOrder?: any;
	getWorkOrder?: any;
	getQuotes?: any;
	checkInvoiceNumber?: any;
	fetchTaxLineItems?: any;
	userType?: any;
}

let partUuid = 1;
let laborUuid = 1;
let miscUuid = 1;

class BuyerInvoiceForm extends React.Component<BuyerInvoiceFormProps, any> {
	constructor(props) {
		super(props);
		this.state = {
			formDataInitialized: false,
			isUpdate: props.formData.hasOwnProperty('id') && props.formData.id !== undefined,
			laborItemInitialValues: {},
			partItemInitialValues: {},
			equipmentItemInitialValues: {},
			miscItemInitialValues: {},
			hasQuote: false,
			editorRef: React.createRef(),
		};
	}

	componentDidMount() {
		const { form, getWorkOrder, formData, getQuotes, fetchTaxLineItems } = this.props;
		const { isUpdate } = this.state;
		if (formData.workOrderId) {
			getWorkOrder(formData.workOrderId).then((workOrder) => {
				let workOrderId = formData.workOrderId;
				if (!isUpdate) {
					getQuotes(
						{
							workOrderId,
							buyerFacilityId: workOrder.buyerFacilityId,
						},
						'workOrderAssociatedQuotes'
					)
						.then((proposals) => {
							if (proposals.length > 0) {
								this.setState({ hasQuote: true });
								proposals = proposals.filter(
									(proposal) => proposal.status.toLowerCase() === 'awarded'
								);
								if (proposals.length === 1) {
									let proposal = proposals[0];
									this.transferToQuoteFromProposal(formData, proposal);
								}
							}
						})
						.then(() => {
							const currency = !!this.state.hasQuote
								? getCurrency({ workOrder })
								: getCurrency({ supplier: nullSafeGet('supplierFacility', workOrder) });
							if (currency.id === 'CAD') {
								fetchTaxLineItems(currency.id).then((data) => {
									form.setFieldsValue({ taxKeys: data });
								});
							}
						});
				}
			});
		}
	}

	getScope = () =>
		nullSafeGet('state.editorRef.current', this) &&
		(this.state.editorRef.current as any).getContent();

	handleSubmit = (e) => {
		const {
			form,
			formData,
			redirectForwardUrl,
			currentUser,
			history,
			onSuccess,
			createInvoice,
			updateInvoice,
		} = this.props;
		e.preventDefault();

		form.validateFields((err, values) => {
			if (!err) {
				const submitAction = values.id ? updateInvoice : createInvoice;

				const laborItems = values.laborKeys.map((laborKey) => {
					let laborItem = {
						roleType: values[`labor-${laborKey}-roleType`],
						numberOfTech: parseInt(values[`labor-${laborKey}-numberOfTech`], 10),
						seconds: parseFloat(values[`labor-${laborKey}-minutes`]) * 60,
						hourlyRate: values[`labor-${laborKey}-hourlyRate`],
						amount: values[`labor-${laborKey}-amount`],
					};
					return laborItem;
				});

				const partsItems = values.partsKeys.map((partsKey) => {
					let partAmt = values[`parts-${partsKey}-amount`];
					let partItem = {
						description: values[`parts-${partsKey}-description`],
						partId: values[`parts-${partsKey}-partId`],
						partNumber:
							values[`parts-${partsKey}-partNumber`] !== undefined
								? values[`parts-${partsKey}-partNumber`]
								: values[`parts-${partsKey}-partId`],
						unitType: values[`parts-${partsKey}-unitType`],
						quantity: values[`parts-${partsKey}-quantity`]
							? `${values[`parts-${partsKey}-quantity`]}`
							: '',
						unitPrice: values[`parts-${partsKey}-unitPrice`]
							? `${values[`parts-${partsKey}-unitPrice`]}`
							: '',
						amount: `${partAmt}`,
					};
					return partItem;
				});

				const equipmentItems = values.equipmentKeys.map((equipmentKey) => {
					let equipmentAmt = values[`equipment-${equipmentKey}-amount`];
					let equipmentItem = {
						description: values[`equipment-${equipmentKey}-description`],
						equipmentId: values[`equipment-${equipmentKey}-equipmentId`],
						equipmentNumber:
							values[`equipment-${equipmentKey}-equipmentNumber`] !== undefined
								? values[`equipment-${equipmentKey}-equipmentNumber`]
								: values[`equipment-${equipmentKey}-equipmentId`],
						quantity: '1',
						unitType: 'each',
						unitPrice: values[`equipment-${equipmentKey}-unitPrice`]
							? `${values[`equipment-${equipmentKey}-unitPrice`]}`
							: undefined,
						amount: `${equipmentAmt}`,
					};
					return equipmentItem;
				});

				const miscItems = values.miscKeys.map((miscKey) => {
					let miscAmt = values[`misc-${miscKey}-amount`];
					let miscItem = {
						description: values[`misc-${miscKey}-description`],
						miscId: values[`misc-${miscKey}-miscId`],
						unitPrice: values[`misc-${miscKey}-unitPrice`]
							? `${values[`misc-${miscKey}-unitPrice`]}`
							: undefined,
						amount: `${miscAmt}`,
					};
					return miscItem;
				});

				const materialLineItems = partsItems.concat(equipmentItems);

				const taxLineItems = values.taxKeys.map((taxKey) => {
					return {
						taxType: `${values[`tax-${taxKey}-taxType`]}`,
						amount: `${values[`tax-${taxKey}-amount`]}`,
					};
				});

				const invoice = {
					id: values.id,
					scope: this.getScope(),
					externalWorkOrderId: values.externalWorkOrderId,
					invoiceNumber: values.invoiceNumber,
					paymentTerms: values.paymentTerms,
					dueDate: values.dueDate,
					invoiceTaxRate: values.invoiceTaxRate,
					invoiceTax: `${values.invoiceTax}`,
					invoiceTotalBeforeTax: `${values.invoiceTotalBeforeTax}`,
					invoiceTotalAfterTax: `${parseFloat(values.invoiceTotalAfterTax).toFixed(2)}`,
					laborTotalBeforeTax: `${values.laborTotalBeforeTax}` || '0',
					status: 'pending',
					laborLineItems: laborItems || [],
					taxLineItems: taxLineItems || [],
					materialTotalBeforeTax: `${values.materialTotalBeforeTax}` || '0',
					materialLineItems: materialLineItems || [],
					freightTotalBeforeTax: `${values.freightTotalBeforeTax}`,
					travelTotalBeforeTax: `${values.travelTotalBeforeTax}`,
					miscLineItems: miscItems || [],
					miscTotalBeforeTax: `${values.miscTotalBeforeTax}`,
					invoicePDFLink: formData.invoicePDFLink,
					isDeleted: formData.isDeleted,
					workOrderId: parseInt(formData.workOrderId, 10),
					buyerFacilityId: values.buyerFacilityId || nullSafeGet('facility.id', currentUser),
					supplierFacilityId: formData.supplierFacilityId,
					createdBy: currentUser.email,
					attachments: values.attachments,
				};

				submitAction(invoice)
					.then((record) => {
						if (redirectForwardUrl) {
							history.push(redirectForwardUrl);
						}
						if (onSuccess) {
							onSuccess(record);
						}
					})
					.catch((err) => {
						if (err && err.startsWith('Invoice(s) are already associated with this work order')) {
							message.error(err);
							history.goBack();
						}
					});
			}
		});
	};

	transferToQuoteFromProposal = (formData, proposal) => {
		const { form } = this.props;
		let materialTotalBeforeTax = nullSafeGetOrElse('materialTotalBeforeTax', proposal, 0);
		let laborTotalBeforeTax = nullSafeGetOrElse('laborTotalBeforeTax', proposal, 0);
		let freightTotalBeforeTax = nullSafeGetOrElse('freightTotalBeforeTax', proposal, 0);
		let miscTotalBeforeTax = nullSafeGetOrElse('miscTotalBeforeTax', proposal, 0);
		let travelTotalBeforeTax = nullSafeGetOrElse('travelTotalBeforeTax', proposal, 0);
		let incurredCostTotalBeforeTax = nullSafeGetOrElse('incurredCostTotalBeforeTax', proposal, 0);
		let invoiceTax = nullSafeGetOrElse('tax', proposal, 0);
		let scope = nullSafeGetOrElse('scope', proposal, '');

		form.setFieldsValue({ [`scope`]: scope });
		if (materialTotalBeforeTax !== 0) {
			partUuid = this.addItem('partsKeys', partUuid, 'partsTotalBeforeTax');

			form.setFieldsValue({ [`parts-0-description`]: 'Proposed Total' });
			form.setFieldsValue({ [`parts-0-quantity`]: '1' });
			form.setFieldsValue({ [`parts-0-unitPrice`]: materialTotalBeforeTax.toString() });
			form.setFieldsValue({ [`parts-0-amount`]: materialTotalBeforeTax.toString() });
			form.setFieldsValue({ partsTotalBeforeTax: materialTotalBeforeTax.toString() });
		}
		if (laborTotalBeforeTax !== 0) {
			form.setFieldsValue({ [`laborKeys`]: [] });
			laborUuid = this.addItem('laborKeys', laborUuid, 'laborTotalBeforeTax');
			form.setFieldsValue({ [`labor-0-hourlyRate`]: laborTotalBeforeTax.toString() });
			form.setFieldsValue({ [`labor-0-minutes`]: '60' });
			form.setFieldsValue({ [`labor-0-numberOfTech`]: '1' });
			form.setFieldsValue({ [`labor-0-amount`]: laborTotalBeforeTax.toString() });
			form.setFieldsValue({ laborTotalBeforeTax: laborTotalBeforeTax.toString() });
		}
		if (parseFloat(miscTotalBeforeTax) !== 0) {
			const miscKeys = form.setFieldsValue({ [`miscKeys`]: [] });

			formData[`misc-${miscUuid}-amount`] = miscTotalBeforeTax.toString();
			formData[`misc-${miscUuid}-unitPrice`] = miscTotalBeforeTax.toString();
			formData[`misc-${miscUuid}-description`] = 'Miscellaneous';
			miscUuid = this.addItem('miscKeys', miscUuid, 'miscTotalBeforeTax');
			form.setFieldsValue({ [`misc-${miscUuid}-amount`]: miscTotalBeforeTax.toString() });
			form.setFieldsValue({ [`misc-${miscUuid}-unitPrice`]: miscTotalBeforeTax.toString() });
			form.setFieldsValue({ [`misc-${miscUuid}-description`]: 'Miscellaneous' });
		}
		if (parseFloat(incurredCostTotalBeforeTax) !== 0) {
			formData[`misc-${miscUuid}-amount`] = incurredCostTotalBeforeTax.toString();
			formData[`misc-${miscUuid}-unitPrice`] = incurredCostTotalBeforeTax.toString();
			formData[`misc-${miscUuid}-description`] = 'Incurred Cost';
			miscUuid = this.addItem('miscKeys', miscUuid, 'miscTotalBeforeTax');
			form.setFieldsValue({ [`misc-${miscUuid}-amount`]: incurredCostTotalBeforeTax.toString() });
			form.setFieldsValue({
				[`misc-${miscUuid}-unitPrice`]: incurredCostTotalBeforeTax.toString(),
			});
			form.setFieldsValue({ [`misc-${miscUuid}-description`]: 'Incurred Cost' });
		}
		form.setFieldsValue({ ['freightTotalBeforeTax']: freightTotalBeforeTax.toString() });
		form.setFieldsValue({ ['travelTotalBeforeTax']: travelTotalBeforeTax.toString() });
		form.setFieldsValue({
			['miscTotalBeforeTax']: (
				parseFloat(miscTotalBeforeTax) + parseFloat(incurredCostTotalBeforeTax)
			).toString(),
		});
		form.setFieldsValue({ ['invoiceTax']: invoiceTax });
		form.setFieldsValue({ freightTotalBeforeTax: freightTotalBeforeTax.toString() });
		form.setFieldsValue({ travelTotalBeforeTax: travelTotalBeforeTax.toString() });
		form.setFieldsValue({ invoiceTax: invoiceTax });

		this.recalculateTotals();
	};

	handleFirstAddItem = (keys, totalKeyName) => {
		const { form } = this.props;
		if (keys.length === 1) {
			const total = form.getFieldValue(totalKeyName);
			const key = keys[0];
			switch (totalKeyName) {
				case 'laborTotalBeforeTax':
					this.setState(
						total
							? {
									laborItemInitialValues: {
										[`labor-${key}-numberOfTech`]: `${1}`,
										[`labor-${key}-minutes`]: `${60}`,
										[`labor-${key}-hourlyRate`]: `${total}`,
										[`labor-${key}-amount`]: `${total}`,
									},
							  }
							: {}
					);
					break;
				case 'partsTotalBeforeTax':
					this.setState(
						total
							? {
									partItemInitialValues: {
										[`parts-${key}-description`]: 'Part',
										[`parts-${key}-partNumber`]: '1',
										[`parts-${key}-quantity`]: 1,
										[`parts-${key}-unitPrice`]: total,
										[`parts-${key}-amount`]: total,
									},
							  }
							: {}
					);
					break;
				case 'equipmentTotalBeforeTax':
					this.setState(
						total
							? {
									equipmentItemInitialValues: {
										[`equipment-${key}-description`]: 'Equipment',
										[`equipment-${key}-equipmentNumber`]: '1',
										[`equipment-${key}-unitPrice`]: total,
										[`equipment-${key}-amount`]: total,
									},
							  }
							: {}
					);
					break;
				case 'miscTotalBeforeTax':
					this.setState(
						total
							? {
									miscItemInitialValues: {
										...this.state.miscItemInitialValues,
										[`misc-${key}-description`]: 'Miscellaneous',
										[`misc-${key}-unitPrice`]: total,
										[`misc-${key}-amount`]: total,
									},
							  }
							: {}
					);
					break;
			}
		}
	};

	addItem = (itemTypeVarName, uuid, totalKeyName) => {
		const { form } = this.props;
		// can use data-binding to get
		const keys = form.getFieldValue(itemTypeVarName) || [];
		const nextKeys = keys.concat(uuid);
		// can use data-binding to set
		// important! notify form to detect changes
		form.setFieldsValue({
			[itemTypeVarName]: nextKeys,
		});
		this.handleFirstAddItem(nextKeys, totalKeyName);
		return uuid + 1;
	};

	removeItem = (itemTypeCollectionKey, itemTypeKey, k) => {
		const { form } = this.props;

		// can use data-binding to get
		const keys = form.getFieldValue(itemTypeCollectionKey);

		// can use data-binding to set
		form.setFieldsValue(
			{
				[itemTypeCollectionKey]: keys.filter((key) => key !== k),
			},
			() => {
				const itemKeys = form.getFieldValue(itemTypeCollectionKey);
				const itemTotalBeforeTax = itemKeys.reduce((acc, k2) => {
					const price = form.getFieldValue(`${itemTypeKey}-${k2}-amount`);
					return acc + parseFloat(price);
				}, 0);

				form.setFieldsValue(
					{ [`${itemTypeKey}TotalBeforeTax`]: itemTotalBeforeTax },
					this.recalculateTotals
				);
			}
		);
	};
	recalculateTotals = debounce(() => {
		const { form } = this.props;
		const subtotals = form.getFieldsValue([
			'laborTotalBeforeTax',
			'partsTotalBeforeTax',
			'equipmentTotalBeforeTax',
			'freightTotalBeforeTax',
			'travelTotalBeforeTax',
			'miscTotalBeforeTax',
			'invoiceTax',
		]);
		form.setFieldsValue({
			invoiceTotalBeforeTax:
				parseFloat(nullSafeGetOrElse('laborTotalBeforeTax', subtotals, 0)) +
				parseFloat(nullSafeGetOrElse('partsTotalBeforeTax', subtotals, 0)) +
				parseFloat(nullSafeGetOrElse('equipmentTotalBeforeTax', subtotals, 0)) +
				parseFloat(nullSafeGetOrElse('freightTotalBeforeTax', subtotals, 0)) +
				parseFloat(nullSafeGetOrElse('travelTotalBeforeTax', subtotals, 0)) +
				parseFloat(nullSafeGetOrElse('miscTotalBeforeTax', subtotals, 0)),
			invoiceTotalAfterTax:
				parseFloat(nullSafeGetOrElse('laborTotalBeforeTax', subtotals, 0)) +
				parseFloat(nullSafeGetOrElse('partsTotalBeforeTax', subtotals, 0)) +
				parseFloat(nullSafeGetOrElse('equipmentTotalBeforeTax', subtotals, 0)) +
				parseFloat(nullSafeGetOrElse('freightTotalBeforeTax', subtotals, 0)) +
				parseFloat(nullSafeGetOrElse('travelTotalBeforeTax', subtotals, 0)) +
				parseFloat(nullSafeGetOrElse('miscTotalBeforeTax', subtotals, 0)) +
				parseFloat(nullSafeGetOrElse('invoiceTax', subtotals, 0)),
			materialTotalBeforeTax:
				parseFloat(nullSafeGetOrElse('partsTotalBeforeTax', subtotals, 0)) +
				parseFloat(nullSafeGetOrElse('equipmentTotalBeforeTax', subtotals, 0)),
		});
	}, 200);

	handleEquipmentChange = (val, k) => {
		const { form } = this.props;
		const equipmentCharge = val;
		const equipmentKeys = form.getFieldValue(`equipmentKeys`);
		if (equipmentCharge) {
			form.setFieldsValue({ [`equipment-${k}-amount`]: equipmentCharge.toString() });
		} else {
			form.setFieldsValue({ [`equipment-${k}-amount`]: undefined });
		}
		const equipmentTotalBeforeTax = equipmentKeys.reduce((acc, k2) => {
			const price =
				k2 === k ? equipmentCharge : form.getFieldValue(`equipment-${k2}-unitPrice`) || '0';
			return acc + parseFloat(price);
		}, 0);

		form.setFieldsValue({ equipmentTotalBeforeTax: equipmentTotalBeforeTax });
		this.recalculateTotals();
	};

	handleMiscChange = (val, k) => {
		const { form } = this.props;
		const miscCharge = val;
		const miscKeys = form.getFieldValue(`miscKeys`);
		if (miscCharge) {
			form.setFieldsValue({ [`misc-${k}-amount`]: miscCharge.toString() });
		} else {
			form.setFieldsValue({ [`misc-${k}-amount`]: undefined });
		}
		const miscTotalBeforeTax = miscKeys.reduce((acc, k2) => {
			const price = k2 === k ? miscCharge : form.getFieldValue(`misc-${k2}-amount`) || '0';
			return acc + parseFloat(price);
		}, 0);

		form.setFieldsValue({ miscTotalBeforeTax: miscTotalBeforeTax });
		this.recalculateTotals();
	};

	handleLaborChange = (val, k, keyName) => {
		const { form } = this.props;
		const laborHourlyRate =
			keyName === 'hourlyRate' ? val : form.getFieldValue(`labor-${k}-hourlyRate`);
		const laborMinutes = keyName === 'minutes' ? val : form.getFieldValue(`labor-${k}-minutes`);
		const laborNumberOfTech =
			keyName === 'numberOfTech' ? val : form.getFieldValue(`labor-${k}-numberOfTech`);
		const laborKeys = form.getFieldValue(`laborKeys`);
		let amount = 0;
		if (laborHourlyRate && laborMinutes && laborNumberOfTech) {
			amount = ((laborHourlyRate * laborMinutes) / 60) * laborNumberOfTech;
			form.setFieldsValue({ [`labor-${k}-amount`]: amount.toString() });
		} else {
			form.setFieldsValue({ [`labor-${k}-amount`]: undefined });
		}

		const laborTotalBeforeTax = laborKeys.reduce((acc, k2) => {
			const price = k2 === k ? amount : form.getFieldValue(`labor-${k2}-amount`) || '0';
			return acc + parseFloat(price);
		}, 0);
		form.setFieldsValue({ laborTotalBeforeTax: laborTotalBeforeTax });
		this.recalculateTotals();
	};

	handlePartChange = (val, k, keyName) => {
		const { form } = this.props;
		const partUnitPrice =
			keyName === 'unitPrice' ? val : form.getFieldValue(`parts-${k}-unitPrice`);
		const partQuantity = keyName === 'quantity' ? val : form.getFieldValue(`parts-${k}-quantity`);
		const partsKeys = form.getFieldValue(`partsKeys`);
		let amount = 0;
		if (partUnitPrice && partQuantity) {
			amount = partUnitPrice * partQuantity;
			form.setFieldsValue({ [`parts-${k}-amount`]: amount.toString() });
		} else {
			form.setFieldsValue({ [`parts-${k}-amount`]: undefined });
		}
		const partsTotalBeforeTax = partsKeys.reduce((acc, k2) => {
			const price = k2 === k ? amount : form.getFieldValue(`parts-${k2}-amount`) || '0';
			return acc + parseFloat(price);
		}, 0);

		form.setFieldsValue({ partsTotalBeforeTax: partsTotalBeforeTax });
		this.recalculateTotals();
	};

	handleTaxChange = (val, k) => {
		const { form } = this.props;
		const taxCharge = val;
		const taxKeys = form.getFieldValue(`taxKeys`);
		if (taxCharge) {
			form.setFieldsValue({ [`tax-${k}-amount`]: taxCharge.toString() });
		} else {
			form.setFieldsValue({ [`tax-${k}-amount`]: undefined });
		}
		const invoiceTax = taxKeys.reduce((acc, k2) => {
			const price = k2 === k ? taxCharge : parseFloat(form.getFieldValue(`tax-${k2}-amount`)) || 0;
			return acc + price;
		}, 0);

		form.setFieldsValue({ invoiceTax: invoiceTax });
		this.recalculateTotals();
	};

	handleInvoiceNumberChange = async (rule, value, callback) => {
		const { checkInvoiceNumber, formData, userType } = this.props;
		const { isUpdate } = this.state;
		if (value) {
			let data = await checkInvoiceNumber(value);
			if (data && Array.isArray(data)) {
				data.map((invoice) => {
					if (
						(isUpdate && nullSafeGet('id', invoice) !== nullSafeGet('id', formData)) ||
						!isUpdate
					) {
						callback(
							<span>
								Error: Duplicate with{' '}
								<a
									href={`/${userType}/invoices/detail/`.concat(
										nullSafeGet('id', invoice),
										`/details`
									)}
									target="_blank"
									rel="noreferrer"
								>
									Invoice {nullSafeGet('id', invoice)}
								</a>
							</span>
						);
					}
					return false;
				});
			}
		}
	};

	render() {
		const { formData, creating, updating, updateErrors, createErrors, form, workOrder } =
			this.props;
		const { formDataInitialized, isUpdate, hasQuote } = this.state;
		const submitting = creating || updating;
		const errorMessagesText = createErrors.concat(updateErrors).join('; ');
		let submitText = isUpdate ? 'Update invoice' : 'Create invoice';
		const { getFieldDecorator, getFieldValue } = form;
		const currency = !!hasQuote
			? getCurrency({ workOrder })
			: getCurrency({ supplier: nullSafeGet('supplierFacility', workOrder) });

		const DATE_FORMAT = 'MM-DD-YYYY';

		if (!formDataInitialized) {
			if (formData.laborLineItems) {
				laborUuid = formData.laborLineItems.length;
				formData.laborKeys = formData.laborLineItems.map((el, idx) => idx);
				formData.laborLineItems.forEach((lineItem, idx) => {
					formData[`labor-${idx}-roleType`] = lineItem.roleType;
					formData[`labor-${idx}-numberOfTech`] = lineItem.numberOfTech;
					formData[`labor-${idx}-minutes`] = lineItem.seconds / 60.0;
					formData[`labor-${idx}-hourlyRate`] = lineItem.hourlyRate;
					formData[`labor-${idx}-amount`] =
						lineItem.amount || (lineItem.numberOfTech && lineItem.seconds && lineItem.hourlyRate)
							? (
									((lineItem.hourlyRate * lineItem.seconds) / 3600.0) *
									lineItem.numberOfTech
							  ).toString()
							: '0';
				});
				if (!formData[`laborTotalBeforeTax`]) {
					formData[`laborTotalBeforeTax`] = formData.laborKeys
						.reduce((acc, k) => {
							const price = formData[`labor-${k}-amount`] || 0;
							return acc + parseFloat(price);
						}, 0)
						.toString();
				}
			}

			if (formData.materialLineItems) {
				let partLineItems = formData.materialLineItems.filter(
					(i) => i.partId !== undefined || i.partNumber !== undefined
				);
				partUuid = partLineItems.length;
				formData.partsKeys = partLineItems.map((el, idx) => idx);
				partLineItems.forEach((lineItem, idx) => {
					formData[`parts-${idx}-description`] = lineItem.description;
					formData[`parts-${idx}-partId`] = lineItem.partId;
					formData[`parts-${idx}-partNumber`] =
						lineItem.partNumber !== undefined ? lineItem.partNumber : lineItem.partId;
					formData[`parts-${idx}-unitType`] = lineItem.unitType;
					formData[`parts-${idx}-quantity`] = lineItem.quantity;
					formData[`parts-${idx}-unitPrice`] = lineItem.unitPrice;
					formData[`parts-${idx}-amount`] =
						lineItem.amount || (lineItem.unitPrice && lineItem.quantity)
							? lineItem.unitPrice * lineItem.quantity
							: 0;
				});
				if (!formData[`partsTotalBeforeTax`]) {
					formData[`partsTotalBeforeTax`] = formData.partsKeys
						.reduce((acc, k2) => {
							const price = formData[`parts-${k2}-amount`] || 0;
							return acc + parseFloat(price);
						}, nullSafeGetOrElse('materialTotalBeforeTax', formData, 0))
						.toString();
				}
			}

			if (formData.miscLineItems) {
				let miscLineItems = formData.miscLineItems;
				miscUuid = miscLineItems.length;
				formData.miscKeys = miscLineItems.map((el, idx) => idx);
				miscLineItems.forEach((lineItem, idx) => {
					formData[`misc-${idx}-description`] = lineItem.description;
					formData[`misc-${idx}-unitPrice`] = lineItem.unitPrice;
					formData[`misc-${idx}-amount`] = lineItem.amount || lineItem.unitPrice || 0;
				});
				if (formData.miscKeys) {
					formData[`miscTotalBeforeTax`] = formData.miscKeys
						.reduce((acc, k2) => {
							const price = formData[`misc-${k2}-amount`] || 0;
							return acc + parseFloat(price);
						}, nullSafeGetOrElse('miscTotalBeforeTax', formData, 0))
						.toString();
				}
			}

			if (formData.materialLineItems) {
				let equipmentLineItems = formData.materialLineItems.filter(
					(i) => i.partId === undefined && i.partNumber === undefined
				);
				formData.equipmentKeys = equipmentLineItems.map((_, idx) => idx);
				equipmentLineItems.forEach((lineItem, idx) => {
					formData[`equipment-${idx}-description`] = lineItem.description;
					formData[`equipment-${idx}-equipmentId`] = lineItem.equipmentId;
					formData[`equipment-${idx}-equipmentNumber`] =
						lineItem.equipmentNumber !== undefined
							? lineItem.equipmentNumber
							: lineItem.equipmentId;
					formData[`equipment-${idx}-unitPrice`] = lineItem.unitPrice;
					formData[`equipment-${idx}-amount`] =
						lineItem.amount || lineItem.unitPrice ? lineItem.unitPrice : 0;
				});
				if (!formData[`equipmentTotalBeforeTax`]) {
					formData[`equipmentTotalBeforeTax`] = formData.equipmentKeys
						.reduce((acc, k2) => {
							const price = formData[`equipment-${k2}-amount`] || 0;
							return acc + parseFloat(price);
						}, 0)
						.toString();
				}
			}

			if (formData.taxLineItems) {
				let taxLineItems = formData.taxLineItems;
				formData.taxKeys = taxLineItems.map((el, idx) => idx);
				taxLineItems.forEach((lineItem, idx) => {
					formData[`tax-${idx}-taxType`] = lineItem.taxType;
					formData[`tax-${idx}-amount`] = lineItem.amount;
				});
				if (!formData[`invoiceTax`]) {
					formData[`invoiceTax`] = formData.taxKeys
						.reduce((acc, k2) => {
							const price = formData[`tax-${k2}-amount`] || 0;
							return acc + parseFloat(price);
						}, 0)
						.toString();
				}
			}

			this.setState({ formDataInitialized: true });
		}

		getFieldDecorator('id', { initialValue: formData.id });
		getFieldDecorator('attachments', { initialValue: formData.attachments || [] });
		getFieldDecorator('partsKeys', { initialValue: formData.partsKeys || [] });
		getFieldDecorator('equipmentKeys', { initialValue: formData.equipmentKeys || [] });
		getFieldDecorator('miscKeys', { initialValue: formData.miscKeys || [] });
		getFieldDecorator('laborKeys', { initialValue: formData.laborKeys || [] });
		getFieldDecorator('taxKeys', { initialValue: formData.taxKeys || [] });
		getFieldDecorator(`invoiceTotalAfterTax`, {
			initialValue: nullSafeGet(`invoiceTotalAfterTax`, formData),
		});
		getFieldDecorator(`invoiceTotalBeforeTax`, {
			initialValue: nullSafeGet(`invoiceTotalBeforeTax`, formData),
		});
		getFieldDecorator(`materialTotalBeforeTax`, {
			initialValue: nullSafeGet(`materialTotalBeforeTax`, formData),
		});
		getFieldDecorator(`partsTotalBeforeTax`, {
			initialValue: nullSafeGet(`partsTotalBeforeTax`, formData),
		});

		getFieldDecorator(`laborTotalBeforeTax`, {
			initialValue: nullSafeGet(`laborTotalBeforeTax`, formData),
		});
		getFieldDecorator(`equipmentTotalBeforeTax`, {
			initialValue: nullSafeGet(`equipmentTotalBeforeTax`, formData),
		});

		const partsKeys = getFieldValue('partsKeys');
		const equipmentKeys = getFieldValue('equipmentKeys');
		const miscKeys = getFieldValue('miscKeys');
		const laborKeys = getFieldValue('laborKeys');
		const taxKeys = getFieldValue('taxKeys');

		const taxLineItems = taxKeys.map((k, taxIndex) => {
			const taxType = formData[`tax-${k}-taxType`] || taxKeys[taxIndex];
			return (
				<Row key={k}>
					<Col offset={19} span={2}>
						<Form.Item label={taxIndex === 0 ? 'Type' : ''} required={false}>
							{form.getFieldDecorator(`tax-${k}-taxType`, {
								initialValue: taxType,
							})}
							<div
								style={
									taxIndex === 0
										? {
												height: 40,
												display: 'flex',
												justifyContent: 'start',
												alignItems: 'center',
										  }
										: { height: 40, display: 'flex', justifyContent: 'start', alignItems: 'center' }
								}
							>
								{taxType}
							</div>
						</Form.Item>
					</Col>
					<Col span={3}>
						<Form.Item label={taxIndex === 0 ? 'Amount' : ''} required={true}>
							{form.getFieldDecorator(`tax-${k}-amount`, {
								initialValue:
									formData[`tax-${k}-amount`] ||
									nullSafeGet(`taxItemInitialValues.tax-${k}-amount`, this.state),
								validateTrigger: ['onBlur'],
								rules: [
									{
										required: true,
										message: 'Please add tax amount.',
									},
								],
							})(
								<InputNumber
									step={0.01}
									style={{ width: '100%' }}
									addonAfter={currency.id}
									onChange={(e) => this.handleTaxChange(e, k)}
								/>
							)}
						</Form.Item>
					</Col>
				</Row>
			);
		});

		const laborFormItems = laborKeys.map((k, laborIndex) => {
			return (
				<Row key={k}>
					<Col span={1}>
						<div
							style={
								laborIndex === 0
									? {
											height: 40,
											marginTop: 29,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
									  }
									: { height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }
							}
						>
							{laborIndex + 1}
						</div>
					</Col>

					<Col span={5}>
						<Form.Item label={laborIndex === 0 ? 'Role Type' : ''}>
							{getFieldDecorator(`labor-${k}-roleType`, {
								validateTrigger: ['onBlur'],
								initialValue: nullSafeGetOrElse(`labor-${k}-roleType`, formData, 'Technician'),
								rules: [
									{
										required: true,
										whitespace: true,
										message: 'Please add role type.',
									},
								],
							})(<Input />)}
						</Form.Item>
					</Col>

					<Col span={3} offset={1}>
						<Form.Item label={laborIndex === 0 ? 'Number of Techs' : ''}>
							{getFieldDecorator(`labor-${k}-numberOfTech`, {
								validateTrigger: ['onBlur'],
								initialValue:
									nullSafeGet(`labor-${k}-numberOfTech`, formData) ||
									nullSafeGet(`laborItemInitialValues.labor-${k}-numberOfTech`, this.state),
								rules: [
									{
										required: true,
										message: 'Please add number of techs.',
									},
								],
							})(
								<InputNumber
									onChange={(e) => this.handleLaborChange(e, k, 'numberOfTech')}
									step={1}
									style={{ width: '100%' }}
								/>
							)}
						</Form.Item>
					</Col>

					<Col span={3} offset={1}>
						<Form.Item label={laborIndex === 0 ? 'Time (Minutes)' : ''}>
							{getFieldDecorator(`labor-${k}-minutes`, {
								initialValue:
									nullSafeGet(`labor-${k}-minutes`, formData) ||
									nullSafeGet(`laborItemInitialValues.labor-${k}-minutes`, this.state),
								validateTrigger: ['onBlur'],
								rules: [
									{
										required: true,
										message: 'Please add quantity.',
									},
								],
							})(
								<InputNumber
									step={1}
									onChange={(e) => this.handleLaborChange(e, k, 'minutes')}
									style={{ width: '100%' }}
								/>
							)}
						</Form.Item>
					</Col>

					<Col span={3} offset={1}>
						<Form.Item label={laborIndex === 0 ? 'Hourly Rate' : ''}>
							{getFieldDecorator(`labor-${k}-hourlyRate`, {
								validateTrigger: ['onBlur'],
								initialValue:
									nullSafeGet(`labor-${k}-hourlyRate`, formData) ||
									nullSafeGet(`laborItemInitialValues.labor-${k}-hourlyRate`, this.state),
								rules: [
									{
										whitespace: true,
										required: true,
										message: 'Please add rate.',
									},
								],
							})(
								<InputNumber
									step={0.01}
									onChange={(e) => this.handleLaborChange(e, k, 'hourlyRate')}
									style={{ width: '100%' }}
									addonAfter={currency.id}
								/>
							)}
						</Form.Item>
					</Col>
					<Col
						span={1}
						style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}
					>
						<div
							style={
								laborIndex === 0
									? {
											height: 40,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											marginTop: 30,
									  }
									: {
											height: 40,
											display: 'flex',
											alignItems: 'center',
									  }
							}
						>
							{laborKeys.length > 0 ? (
								<MinusCircleOutlined
									translate=""
									className="dynamic-delete-button"
									onClick={() => this.removeItem('laborKeys', 'labor', k)}
								/>
							) : null}
						</div>
					</Col>
					<Col offset={3} span={2}>
						<div
							style={
								laborIndex === 0
									? {
											height: 40,
											marginTop: 29,
											display: 'flex',
											justifyContent: 'flex-end',
											alignItems: 'center',
									  }
									: {
											height: 40,
											display: 'flex',
											justifyContent: 'flex-end',
											alignItems: 'center',
									  }
							}
						>
							{form.getFieldDecorator(`labor-${k}-amount`, {
								initialValue:
									formData[`labor-${k}-amount`] ||
									nullSafeGet(`laborItemInitialValues.labor-${k}-amount`, this.state),
							})}
							{currency.format(form.getFieldValue(`labor-${k}-amount`))}
						</div>
					</Col>
				</Row>
			);
		});

		const laborForm = (
			<div style={{ marginBottom: 32 }}>
				<div className="buyerInvoiceForm__sectionHead">
					<div className="buyerInvoiceForm__sectionHeadPrimary">Labor</div>
					<FormDots />
					{nullSafeGetOrElse('length', laborKeys, 0) > 0 ? (
						getFieldDecorator(`laborTotalBeforeTax`, {
							validateTrigger: ['onBlur'],
							initialValue: `${nullSafeGetOrElse(`laborTotalBeforeTax`, formData, 0)}`,
						})(
							<div className="buyerInvoiceForm__sectionHeadSecondary">
								{currency.format(form.getFieldValue(`laborTotalBeforeTax`))}
							</div>
						)
					) : (
						<div className="buyerInvoiceForm__sectionHeadSecondary">
							{getFieldDecorator(`laborTotalBeforeTax`, {
								validateTrigger: ['onBlur'],
								initialValue: `${nullSafeGetOrElse(`laborTotalBeforeTax`, formData, 0)}`,
							})(
								<InputNumber
									step={1}
									onChange={this.recalculateTotals}
									style={{ width: 240, textAlign: 'right' }}
									addonAfter={currency.id}
								/>
							)}
						</div>
					)}
				</div>
				{laborFormItems}
				<Row>
					<Col span={17} offset={1}>
						<Button
							type="dashed"
							onClick={() =>
								(laborUuid = this.addItem('laborKeys', laborUuid, 'laborTotalBeforeTax'))
							}
							style={{ height: 32 }}
						>
							<PlusOutlined translate="" /> Add labor
						</Button>
					</Col>
				</Row>
			</div>
		);

		const partsFormItems = partsKeys.map((k, partsIndex) => {
			getFieldDecorator(`parts-${k}-partId`, {
				initialValue: nullSafeGet(`parts-${k}-partId`, formData),
			});
			return (
				<Row key={k}>
					<Col span={1}>
						<div
							style={
								partsIndex === 0
									? {
											height: 40,
											marginTop: 29,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
									  }
									: { height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }
							}
						>
							{partsIndex + 1}
						</div>
					</Col>

					<Col span={5}>
						<Form.Item label={partsIndex === 0 ? 'Description' : ''}>
							{getFieldDecorator(`parts-${k}-description`, {
								validateTrigger: ['onBlur'],
								initialValue:
									nullSafeGet(`parts-${k}-description`, formData) ||
									nullSafeGet(`partItemInitialValues.parts-${k}-description`, this.state),
								rules: [
									{
										required: true,
										whitespace: true,
										message: 'Please add description.',
									},
								],
							})(<Input />)}
						</Form.Item>
					</Col>
					<Col span={3} offset={1}>
						<Form.Item label={partsIndex === 0 ? 'Part Number' : ''}>
							{getFieldDecorator(`parts-${k}-partNumber`, {
								validateTrigger: ['onBlur'],
								initialValue:
									nullSafeGet(`parts-${k}-partNumber`, formData) ||
									nullSafeGet(`partItemInitialValues.parts-${k}-partNumber`, this.state)
										? (
												nullSafeGet(`parts-${k}-partNumber`, formData) ||
												nullSafeGet(`partItemInitialValues.parts-${k}-partNumber`, this.state)
										  ).toString()
										: undefined,
							})(<Input />)}
						</Form.Item>
					</Col>

					<Col span={2} offset={1}>
						<Form.Item label={partsIndex === 0 ? 'Unit Type' : ''}>
							{getFieldDecorator(`parts-${k}-unitType`, {
								validateTrigger: ['onBlur'],
								initialValue: nullSafeGetOrElse(`parts-${k}-unitType`, formData, 'each'),
								rules: [
									{
										required: true,
										message: 'Please add unit of measure.',
									},
								],
							})(<Input />)}
						</Form.Item>
					</Col>

					<Col span={2} offset={1}>
						<Form.Item label={partsIndex === 0 ? 'Quantity' : ''}>
							{getFieldDecorator(`parts-${k}-quantity`, {
								initialValue:
									nullSafeGet(`parts-${k}-quantity`, formData) ||
									nullSafeGet(`partItemInitialValues.parts-${k}-quantity`, this.state),
								validateTrigger: ['onBlur'],
								rules: [
									{
										required: true,
										message: 'Please add quantity.',
									},
								],
							})(
								<InputNumber
									onChange={(e) => this.handlePartChange(e, k, 'quantity')}
									style={{ width: '100%' }}
								/>
							)}
						</Form.Item>
					</Col>
					<Col span={3} offset={1}>
						<Form.Item label={partsIndex === 0 ? 'Unit Price' : ''}>
							{getFieldDecorator(`parts-${k}-unitPrice`, {
								validateTrigger: ['onBlur'],
								initialValue:
									nullSafeGet(`parts-${k}-unitPrice`, formData) ||
									nullSafeGet(`partItemInitialValues.parts-${k}-unitPrice`, this.state),
								rules: [
									{
										required: true,
										message: 'Please add price.',
									},
								],
							})(
								<InputNumber
									step={0.01}
									onChange={(e) => this.handlePartChange(e, k, 'unitPrice')}
									style={{ width: '100%' }}
									addonAfter={currency.id}
								/>
							)}
						</Form.Item>
					</Col>
					<Col
						span={1}
						style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}
					>
						<div
							style={
								partsIndex === 0
									? {
											height: 40,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											marginTop: 30,
									  }
									: {
											height: 40,
											display: 'flex',
											alignItems: 'center',
									  }
							}
						>
							{partsKeys.length > 0 ? (
								<MinusCircleOutlined
									translate=""
									className="dynamic-delete-button"
									onClick={() => this.removeItem('partsKeys', 'parts', k)}
								/>
							) : null}
						</div>
					</Col>
					<Col span={2} offset={1}>
						<Form.Item>
							<div
								style={
									partsIndex === 0
										? {
												height: 40,
												display: 'flex',
												justifyContent: 'flex-end',
												alignItems: 'center',
												marginTop: 30,
										  }
										: {
												height: 40,
												display: 'flex',
												justifyContent: 'flex-end',
												alignItems: 'center',
										  }
								}
							>
								{form.getFieldDecorator(`parts-${k}-amount`, {
									initialValue:
										formData[`parts-${k}-amount`] ||
										nullSafeGet(`partItemInitialValues.parts-${k}-amount`, this.state),
								})}
								{currency.format(form.getFieldValue(`parts-${k}-amount`))}
							</div>
						</Form.Item>
					</Col>
				</Row>
			);
		});

		const partsForm = (
			<div style={{ marginBottom: 32 }}>
				<div className="buyerInvoiceForm__sectionHead">
					<div className="buyerInvoiceForm__sectionHeadPrimary">Parts</div>
					<FormDots />
					{nullSafeGetOrElse('length', partsKeys, 0) > 0 ? (
						getFieldDecorator(`partsTotalBeforeTax`, {
							validateTrigger: ['onBlur'],
							initialValue: `${nullSafeGetOrElse(`partsTotalBeforeTax`, formData, 0)}`,
						})(
							<div className="buyerInvoiceForm__sectionHeadSecondary">
								{currency.format(form.getFieldValue(`partsTotalBeforeTax`))}
							</div>
						)
					) : (
						<div className="buyerInvoiceForm__sectionHeadSecondary">
							{getFieldDecorator(`partsTotalBeforeTax`, {
								validateTrigger: ['onBlur'],
								initialValue: `${nullSafeGetOrElse(`partsTotalBeforeTax`, formData, 0)}`,
							})(
								<InputNumber
									step={1}
									onChange={this.recalculateTotals}
									style={{ width: 240 }}
									addonAfter={currency.id}
								/>
							)}
						</div>
					)}
				</div>
				{partsFormItems}
				<Row>
					<Col span={19} offset={1}>
						<Button
							type="dashed"
							onClick={() =>
								(partUuid = this.addItem('partsKeys', partUuid, 'partsTotalBeforeTax'))
							}
							style={{ height: 32 }}
						>
							<PlusOutlined translate="" /> Add part
						</Button>
					</Col>
				</Row>
			</div>
		);

		const equipmentFormItems = equipmentKeys.map((k, equipmentIndex) => {
			getFieldDecorator(`equipment-${k}-equipmentId`, {
				initialValue: nullSafeGet(`equipment-${k}-equipmentId`, formData),
			});
			return (
				<Row key={k}>
					<Col span={1}>
						<div
							style={
								equipmentIndex === 0
									? {
											height: 40,
											marginTop: 29,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
									  }
									: { height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }
							}
						>
							{equipmentIndex + 1}
						</div>
					</Col>

					<Col span={8}>
						<Form.Item label={equipmentIndex === 0 ? 'Description' : ''}>
							{getFieldDecorator(`equipment-${k}-description`, {
								validateTrigger: ['onBlur'],
								initialValue:
									nullSafeGet(`equipment-${k}-description`, formData) ||
									nullSafeGet(`equipmentItemInitialValues.equipment-${k}-description`, this.state),
								rules: [
									{
										required: true,
										whitespace: true,
										message: 'Please add description.',
									},
								],
							})(<Input />)}
						</Form.Item>
					</Col>

					<Col span={4} offset={1}>
						<Form.Item label={equipmentIndex === 0 ? 'Equipment Number' : ''}>
							{getFieldDecorator(`equipment-${k}-equipmentNumber`, {
								validateTrigger: ['onBlur'],
								initialValue:
									nullSafeGet(`equipment-${k}-equipmentNumber`, formData) ||
									nullSafeGet(
										`equipmentItemInitialValues.equipment-${k}-equipmentNumber`,
										this.state
									)
										? (
												nullSafeGet(`equipment-${k}-equipmentNumber`, formData) ||
												nullSafeGet(
													`equipmentItemInitialValues.equipment-${k}-equipmentNumber`,
													this.state
												)
										  ).toString()
										: undefined,
							})(<Input />)}
						</Form.Item>
					</Col>

					<Col span={3} offset={1}>
						<Form.Item label={equipmentIndex === 0 ? 'Price' : ''}>
							{getFieldDecorator(`equipment-${k}-unitPrice`, {
								validateTrigger: ['onBlur'],
								initialValue:
									nullSafeGet(`equipment-${k}-unitPrice`, formData) ||
									nullSafeGet(`equipmentItemInitialValues.equipment-${k}-unitPrice`, this.state),
								rules: [
									{
										required: true,
										message: 'Please add price.',
									},
								],
							})(
								<InputNumber
									step={0.01}
									onChange={(e) => this.handleEquipmentChange(e, k)}
									style={{ width: '100%' }}
									addonAfter={currency.id}
								/>
							)}
						</Form.Item>
					</Col>
					<Col
						span={1}
						style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}
					>
						<div
							style={
								equipmentIndex === 0
									? {
											height: 40,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											marginTop: 30,
									  }
									: {
											height: 40,
											display: 'flex',
											alignItems: 'center',
									  }
							}
						>
							{equipmentKeys.length > 0 ? (
								<MinusCircleOutlined
									translate=""
									className="dynamic-delete-button"
									onClick={() => this.removeItem('equipmentKeys', 'equipment', k)}
								/>
							) : null}
						</div>
					</Col>

					<Col span={2} offset={3}>
						<div
							style={
								equipmentIndex === 0
									? {
											height: 40,
											marginTop: 29,
											display: 'flex',
											justifyContent: 'flex-end',
											alignItems: 'center',
									  }
									: {
											height: 40,
											display: 'flex',
											justifyContent: 'flex-end',
											alignItems: 'center',
									  }
							}
						>
							{form.getFieldDecorator(`equipment-${k}-amount`, {
								initialValue:
									formData[`equipment-${k}-amount`] ||
									nullSafeGet(`equipmentItemInitialValues.equipment-${k}-amount`, this.state),
							})}
							{currency.format(form.getFieldValue(`equipment-${k}-amount`))}
						</div>
					</Col>
				</Row>
			);
		});

		const equipmentForm = (
			<div style={{ marginBottom: 32 }}>
				<div className="buyerInvoiceForm__sectionHead">
					<div className="buyerInvoiceForm__sectionHeadPrimary">Equipment</div>
					<FormDots />
					{nullSafeGetOrElse('length', equipmentKeys, 0) > 0 ? (
						getFieldDecorator(`equipmentTotalBeforeTax`, {
							validateTrigger: ['onBlur'],
							initialValue: `${nullSafeGetOrElse(`equipmentTotalBeforeTax`, formData, 0)}`,
						})(
							<div className="buyerInvoiceForm__sectionHeadSecondary">
								{currency.format(form.getFieldValue(`equipmentTotalBeforeTax`))}
							</div>
						)
					) : (
						<div className="buyerInvoiceForm__sectionHeadSecondary">
							{getFieldDecorator(`equipmentTotalBeforeTax`, {
								validateTrigger: ['onBlur'],
								initialValue: `${nullSafeGetOrElse(`equipmentTotalBeforeTax`, formData, 0)}`,
							})(
								<InputNumber
									step={1}
									onChange={this.recalculateTotals}
									style={{ width: 240 }}
									addonAfter={currency.id}
								/>
							)}
						</div>
					)}
				</div>
				{equipmentFormItems}
				<Row>
					<Col span={16} offset={1}>
						<Button
							type="dashed"
							onClick={() =>
								(partUuid = this.addItem('equipmentKeys', partUuid, 'equipmentTotalBeforeTax'))
							}
							style={{ height: 32 }}
						>
							<PlusOutlined translate="" /> Add equipment
						</Button>
					</Col>
				</Row>
			</div>
		);

		const miscFormItems = miscKeys.map((k, miscIndex) => {
			getFieldDecorator(`misc-${k}-miscId`, {
				initialValue: nullSafeGet(`misc-${k}-miscId`, formData),
			});
			return (
				<Row key={k}>
					<Col span={1}>
						<div
							style={
								miscIndex === 0
									? {
											height: 40,
											marginTop: 29,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
									  }
									: { height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }
							}
						>
							{miscIndex + 1}
						</div>
					</Col>

					<Col span={12}>
						<Form.Item label={miscIndex === 0 ? 'Description' : ''}>
							{getFieldDecorator(`misc-${k}-description`, {
								validateTrigger: ['onBlur'],
								initialValue:
									nullSafeGet(`misc-${k}-description`, formData) ||
									nullSafeGet(`miscItemInitialValues.misc-${k}-description`, this.state),
								rules: [
									{
										required: true,
										whitespace: true,
										message: 'Please add description.',
									},
								],
							})(<Input />)}
						</Form.Item>
					</Col>
					<Col offset={1} span={4}>
						<Form.Item label={miscIndex === 0 ? 'Price' : ''}>
							{getFieldDecorator(`misc-${k}-unitPrice`, {
								validateTrigger: ['onBlur'],
								initialValue:
									nullSafeGet(`misc-${k}-amount`, formData) ||
									nullSafeGet(`miscItemInitialValues.misc-${k}-amount`, this.state),
								rules: [
									{
										required: true,
										message: 'Please add price.',
									},
								],
							})(
								<InputNumber
									step={0.01}
									onChange={(e) => this.handleMiscChange(e, k)}
									style={{ width: '100%' }}
									addonAfter={currency.id}
								/>
							)}
						</Form.Item>
					</Col>
					<Col
						span={1}
						style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}
					>
						<div
							style={
								miscIndex === 0
									? {
											height: 40,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											marginTop: 30,
									  }
									: {
											height: 40,
											display: 'flex',
											alignItems: 'center',
									  }
							}
						>
							{miscKeys.length > 0 ? (
								<MinusCircleOutlined
									translate=""
									className="dynamic-delete-button"
									onClick={() => this.removeItem('miscKeys', 'misc', k)}
								/>
							) : null}
						</div>
					</Col>

					<Col span={2} offset={3}>
						<div
							style={
								miscIndex === 0
									? {
											height: 40,
											marginTop: 29,
											display: 'flex',
											justifyContent: 'flex-end',
											alignItems: 'center',
									  }
									: {
											height: 40,
											display: 'flex',
											justifyContent: 'flex-end',
											alignItems: 'center',
									  }
							}
						>
							{form.getFieldDecorator(`misc-${k}-amount`, {
								initialValue:
									formData[`misc-${k}-amount`] ||
									nullSafeGet(`miscItemInitialValues.misc-${k}-amount`, this.state),
							})}
							{currency.format(form.getFieldValue(`misc-${k}-amount`))}
						</div>
					</Col>
				</Row>
			);
		});

		const miscForm = (
			<div style={{ marginBottom: 32 }}>
				<div className="buyerInvoiceForm__sectionHead">
					<div className="buyerInvoiceForm__sectionHeadPrimary">Miscellaneous</div>
					<FormDots />
					{nullSafeGetOrElse('length', miscKeys, 0) > 0 ? (
						getFieldDecorator(`miscTotalBeforeTax`, {
							validateTrigger: ['onBlur'],
							initialValue: `${nullSafeGetOrElse(`miscTotalBeforeTax`, formData, 0)}`,
						})(
							<div className="buyerInvoiceForm__sectionHeadSecondary">
								{currency.format(form.getFieldValue(`miscTotalBeforeTax`))}
							</div>
						)
					) : (
						<div className="buyerInvoiceForm__sectionHeadSecondary">
							{getFieldDecorator(`miscTotalBeforeTax`, {
								validateTrigger: ['onBlur'],
								initialValue: `${nullSafeGetOrElse(`miscTotalBeforeTax`, formData, 0)}`,
							})(
								<InputNumber
									step={1}
									onChange={this.recalculateTotals}
									style={{ width: 240 }}
									addonAfter={currency.id}
								/>
							)}
						</div>
					)}
				</div>
				{miscFormItems}
				<Row>
					<Col span={16} offset={1}>
						<Button
							type="dashed"
							onClick={() => (miscUuid = this.addItem('miscKeys', miscUuid, 'miscTotalBeforeTax'))}
							style={{ height: 32 }}
						>
							<PlusOutlined translate="" /> Add miscellaneous
						</Button>
					</Col>
				</Row>
			</div>
		);

		const taxForm = (
			<div style={{ marginBottom: 32 }}>
				<div className="buyerInvoiceForm__sectionHead">
					<div className="buyerInvoiceForm__sectionHeadPrimary">Tax</div>
					<FormDots />
					{nullSafeGetOrElse('length', taxKeys, 0) > 0 ? (
						getFieldDecorator(`invoiceTax`, {
							validateTrigger: ['onBlur'],
							initialValue: `${nullSafeGetOrElse(`invoiceTax`, formData, 0)}`,
						})(
							<div className="buyerInvoiceForm__sectionHeadSecondary">
								{currency.format(form.getFieldValue(`invoiceTax`))}
							</div>
						)
					) : (
						<div className="buyerInvoiceForm__sectionHeadSecondary">
							{getFieldDecorator(`invoiceTax`, {
								validateTrigger: ['onBlur'],
								initialValue: `${nullSafeGetOrElse(`invoiceTax`, formData, 0)}`,
								rules: [
									{
										required: true,
										message: 'Please specify tax if any.',
									},
								],
							})(
								<InputNumber
									step={1}
									onChange={this.recalculateTotals}
									style={{ width: 240 }}
									addonAfter={currency.id}
								/>
							)}
						</div>
					)}
				</div>
				{taxLineItems}
			</div>
		);

		return (
			<Layout.Content>
				<Row className="buyerInvoiceForm" gutter={16}>
					<Col span={24}>
						<Form hideRequiredMark={true} layout="vertical">
							{createErrors.length > 0 || updateErrors.length > 0 ? (
								<Form.Item label="">
									<Alert message={errorMessagesText} type="error" />
								</Form.Item>
							) : null}
							<div style={{ marginBottom: 32 }}>
								<div className="buyerInvoiceForm__sectionHeadPrimary">Scope</div>
								{getFieldDecorator('scope', {
									initialValue: nullSafeGet(`scope`, formData),
								})(
									<OWRichTextEditor
										initialEditorState={nullSafeGet(`scope`, formData)}
										editorRef={this.state.editorRef}
									/>
								)}
							</div>
							<Form.Item className="buyerInvoiceForm__sectionHeadPrimary" label="Invoice #">
								{getFieldDecorator('invoiceNumber', {
									validateTrigger: ['onBlur'],
									initialValue: nullSafeGet(`invoiceNumber`, formData),
									rules: [
										{
											whitespace: true,
											required: true,
											message: 'This field is required.',
										},
										{
											validator: this.handleInvoiceNumberChange,
										},
									],
								})(<Input style={{ maxWidth: 320 }} />)}
							</Form.Item>
							<div style={{ marginBottom: 32 }}>
								<div className="buyerInvoiceForm__sectionHeadPrimary">Work Order #</div>
								{getFieldDecorator('externalWorkOrderId', {
									initialValue: nullSafeGetOrElse(
										`externalWorkOrderId`,
										formData,
										`${workOrder.id}`
									),
								})(<Input style={{ maxWidth: 320 }} />)}
							</div>
							<div style={{ marginBottom: 32 }}>
								<div className="buyerInvoiceForm__sectionHeadPrimary">Payment Terms</div>
								{getFieldDecorator('paymentTerms', {
									initialValue: nullSafeGet(`paymentTerms`, formData),
								})(<Input placeholder="Net 30" style={{ maxWidth: 320 }} />)}
							</div>
							<div style={{ marginBottom: 32 }}>
								<div className="buyerInvoiceForm__sectionHeadPrimary">Due Date</div>
								{getFieldDecorator('dueDate', {
									initialValue: formData.dueDate ? moment(formData.dueDate) : undefined,
								})(<DatePicker format={DATE_FORMAT} />)}
							</div>
							<Form.Item
								className="buyerInvoiceForm__sectionHeadPrimary"
								label="Has this invoice been paid?"
							>
								{getFieldDecorator('status', {
									validateTrigger: ['onBlur'],
									initialValue: 'approved',
								})(
									<Radio.Group>
										<Radio.Button
											value={'approved'}
											style={{ flexWrap: 'nowrap', height: 40, lineHeight: '40px' }}
										>
											No
										</Radio.Button>
										<Radio.Button
											value={'paid'}
											style={{ flexWrap: 'nowrap', height: 40, lineHeight: '40px' }}
										>
											Yes
										</Radio.Button>
									</Radio.Group>
								)}
							</Form.Item>
							{laborForm}
							{partsForm}
							{equipmentForm}
							<div style={{ marginBottom: 32 }}>
								<div className="buyerInvoiceForm__sectionHead">
									<div className="buyerInvoiceForm__sectionHeadPrimary">Travel</div>
									<FormDots />
									<div className="buyerInvoiceForm__sectionHeadSecondary">
										{getFieldDecorator(`travelTotalBeforeTax`, {
											validateTrigger: ['onBlur'],
											initialValue: nullSafeGetOrElse(`travelTotalBeforeTax`, formData, 0),
											rules: [
												{
													required: true,
													message: 'Please specify travel charges if any.',
												},
											],
										})(
											<InputNumber
												step={1}
												onChange={this.recalculateTotals}
												style={{ width: 240 }}
												addonAfter={currency.id}
											/>
										)}
									</div>
								</div>
							</div>
							<div style={{ marginBottom: 32 }}>
								<div className="buyerInvoiceForm__sectionHead">
									<div className="buyerInvoiceForm__sectionHeadPrimary">Freight</div>
									<FormDots />
									<div className="buyerInvoiceForm__sectionHeadSecondary">
										{getFieldDecorator(`freightTotalBeforeTax`, {
											validateTrigger: ['onBlur'],
											initialValue: nullSafeGetOrElse(`freightTotalBeforeTax`, formData, 0),
											rules: [
												{
													required: true,
													message: 'Please specify freight charges if any.',
												},
											],
										})(
											<InputNumber
												step={1}
												onChange={this.recalculateTotals}
												style={{ width: 240 }}
												addonAfter={currency.id}
											/>
										)}
									</div>
								</div>
							</div>
							{miscForm}
							<div style={{ marginBottom: 32 }}>
								<div className="buyerInvoiceForm__sectionHead">
									<div className="buyerInvoiceForm__sectionHeadPrimary">Total Before Tax</div>
									<FormDots />
									<div className="buyerInvoiceForm__sectionHeadSecondary">
										{currency.format(getFieldValue('invoiceTotalBeforeTax'))}
									</div>
								</div>
							</div>
							{taxForm}
							<div style={{ marginBottom: 32 }}>
								<div className="buyerInvoiceForm__sectionHead">
									<div className="buyerInvoiceForm__sectionHeadPrimary">Total With Tax</div>
									<FormDots />
									<div className="buyerInvoiceForm__sectionHeadSecondary">
										{currency.format(getFieldValue('invoiceTotalAfterTax'))}
									</div>
								</div>
							</div>
							<Form.Item label="">
								<Button loading={submitting} type="primary" onClick={(e) => this.handleSubmit(e)}>
									{submitText}
								</Button>
								<span style={{ marginLeft: '16px' }}>
									<BackButton buttonText="Cancel" />
								</span>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Layout.Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	formData: ownProps.formData,
	onSuccess: ownProps.onSuccess,
	updating: state.invoices.updating,
	creating: state.invoices.creating,
	createErrors: state.invoices.createErrors,
	updateErrors: state.invoices.updateErrors,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	redirectBackwardUrl: ownProps.redirectBackwardUrl,
	currentUser: state.session.currentUser,
	history: ownProps.history,
	location: ownProps.location,
	backToWorkOrder: ownProps.backToWorkOrder,
	workOrder: state.work_orders.detail,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchTaxLineItems: (currencyId) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? fetchTaxLineItemsForSupplier(currencyId)
				: fetchTaxLineItemsForBuyer(currencyId)
		),
	checkInvoiceNumber: (invoice) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? getInvoiceNumber(invoice)
				: getInvoiceNumberBuyer(invoice)
		),
	createInvoice: (invoice) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? createInvoiceByInternalTech(invoice)
				: invoicesRestCrudThunksForBuyer.create(invoice)
		),
	updateInvoice: (invoice) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? invoicesRestCrudThunksForSupplier.update(invoice)
				: invoicesRestCrudThunksForBuyer.update(invoice)
		),
	getWorkOrder: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? workOrdersRestCrudThunksForSupplier.readOne(id)
				: workOrdersRestCrudThunksForBuyer.readOne(id)
		),
	getQuotes: (params, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? proposalsRestCrudThunksForSupplier.read(params, targetCollectionName)
				: proposalsRestCrudThunksForBuyer.read(params, targetCollectionName)
		),
});

const componentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(BuyerInvoiceForm)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(Form.create<BuyerInvoiceFormProps>()(componentWithoutUserType));

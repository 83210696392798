import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Layout, Row, Col, Card, Button, Modal, message, Popover, Tag } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import {
	getCurrency,
	nullSafeGet,
	nullSafeGetOrElse,
	renderCurrency,
	renderCurrencyByCurrencyId,
	stopDefaultEvents,
} from '../../utils/DataAccessUtils';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { EmptyState } from '../empty_state/EmptyState';
import { equipmentTypesRestCrudThunksForSupplier } from '../../thunks/equipment_types_thunks';
import { ProtectedImageThumbnails } from '../protected_image_thumbnails/ProtectedImageThumbnails';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { equipmentCatalogsRestCrudThunksForSupplier } from '../../thunks/equipment_catalogs_thunks';
import { equipmentPerStockLocationsRestCrudThunksForSupplier } from '../../thunks/equipment_per_stock_locations_thunks';
import { EquipmentRentalRowDisplay } from '../equipment_rental_row_display/EquipmentRentalRowDisplay';
import EquipmentCatalogModalForm from '../equipment_catalog_modal_form/EquipmentCatalogModalForm';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { HorizontalKeyValueDisplay } from '../horizontal_key_value_display/HorizontalKeyValueDisplay';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { AdvancedFilters } from '../advanced_filters/AdvancedFilters';
import { EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS } from '../../actions/equipment_per_stock_locations_actions';
import { isInternalTech } from '../../utils/AuthUtils';
import BulkTransferFromStockLocationModal from '../stock_locations_detail_details_page/BulkTransferFromStockLocationModal';
import GreyStatusTag from '../equipment_per_stock_location_row_display/GreyStatusTag';
import HyperLink from '../common/HyperLink';

const { Content } = Layout;

require('./EquipmentsDetailDetailsPage.less');

const EQUIPMENT_CATALOGS_FOR_EQUIPMENT = 'EQUIPMENT_CATALOGS_FOR_EQUIPMENT';
const EQUIPMENT_PER_STOCK_LOCATIONS_FOR_EQUIPMENT = 'EQUIPMENT_PER_STOCK_LOCATIONS_FOR_EQUIPMENT';
const DEFAULT_BACK_TEXT = 'Back to equipment';

const EquipmentsDetailPage: FC<any> = ({
	match,
	getEquipment,
	equipmentType,
	equipmentsFetching,
	history,
	deleteEquipmentCatalog,
	location,
	equipmentsPerStockLocation,
	updateEpslFilters,
	clearAndUpdateFilters,
	currentUser,
}): React.ReactElement => {
	const [equipmentCatalogModalFormVisible, setEquipmentCatalogModalFormVisible] = useState(false);
	const [equipmentCatalogFormData, setEquipmentCatalogFormData] = useState({});
	const [moveAssetModalVisible, setMoveAssetModalVisible] = useState(false);
	const [selectedEpsls, setSelectedEpsls] = useState([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

	const showMoveAssetModal = useCallback(() => setMoveAssetModalVisible(true), []);
	const hideMoveAssetModal = useCallback(() => setMoveAssetModalVisible(false), []);

	const currencyId = useMemo(() => getCurrency({ currentUser }), [currentUser]);

	useEffect(() => {
		const equipmentTypeId = match.params.id;
		setEquipmentCatalogFormData({
			...(equipmentTypeId && { equipmentTypeId: parseInt(equipmentTypeId) }),
		});
	}, [match]);

	const fetchEquipment = useCallback(() => {
		getEquipment(match.params.id);
	}, [getEquipment, match]);

	useEffect(() => fetchEquipment(), []);

	const handleEquipmentCatalogModalFormOpen = useCallback(
		() => setEquipmentCatalogModalFormVisible(true),
		[]
	);

	const handleEquipmentCatalogModalFormClose = useCallback(() => {
		setEquipmentCatalogFormData({ equipmentTypeId: parseInt(match.params.id) });
		setEquipmentCatalogModalFormVisible(false);
	}, [match]);

	const handleEditEquipmentCatalog = useCallback((entity) => {
		setEquipmentCatalogModalFormVisible(true);
		setEquipmentCatalogFormData({ ...entity });
	}, []);

	const equipmentTypeId = match.params.id;

	const equipmentDetails = useMemo(() => {
		const details = {
			'Equipment Type #': nullSafeGetOrElse('equipmentTypeNumber', equipmentType, '--'),
			'Alias Equipment Type #': nullSafeGetOrElse('aliasEquipmentTypeNumber', equipmentType, '--'),
			Manufacturer: nullSafeGetOrElse('manufacturerName', equipmentType, '--'),
			'Manufacturer ID': nullSafeGetOrElse('manufacturerId', equipmentType, '--'),
			Cost: renderCurrency(currentUser)(equipmentType.cost),
			...(equipmentType.defaultVendor && {
				'Default Vendor': nullSafeGet('defaultVendor.name', equipmentType),
			}),
			...(equipmentType.assetType && {
				'Asset Type': nullSafeGet('assetType.name', equipmentType),
			}),
		};
		return details;
	}, [equipmentType, currentUser]);

	const onEquipmentCatalogDetail = useCallback(
		(entity) => () =>
			history.push(
				getLinkWIthBackLinkParams(
					location,
					'Back to Equipment Detail',
					`/supplier/equipments/equipmentCatalogs/detail/${entity.id}`
				)
			),
		[history, location]
	);

	const equipmentCatalogsColumns = useMemo(
		() => [
			{
				title: 'Vendor',
				dataIndex: 'partEquipmentVendor',
				render: (vendor) => (
					<div className="flex flex-row">
						<div>{nullSafeGet('name', vendor)}</div>
						{vendor.id === nullSafeGet('defaultVendorId', equipmentType) ? (
							<div className="ml-2">
								<Tag>Default</Tag>
							</div>
						) : null}
					</div>
				),
			},
			{
				title: 'Last Price',
				dataIndex: 'unitCost',
				render: (_, record) =>
					renderCurrencyByCurrencyId(
						nullSafeGetOrElse('partEquipmentVendor.currencyId', record, currencyId)
					)(_),
			},
			{
				title: 'List Price',
				dataIndex: 'listPrice',
				render: (_, record) =>
					renderCurrencyByCurrencyId(
						nullSafeGetOrElse('partEquipmentVendor.currencyId', record, currencyId)
					)(_),
			},
			{
				render: (_, record) => (
					<div className="flex flex-row items-center">
						<Button
							onClick={() => handleEditEquipmentCatalog(record)}
							type="link"
							icon={<EditOutlined translate="" />}
						/>
						<Button
							onClick={() => deleteEquipmentCatalog(record)}
							type="link"
							icon={<DeleteOutlined translate="" />}
						/>
						<Button className="ml-2" onClick={onEquipmentCatalogDetail(record)} type="ghost">
							Show Detail
						</Button>
					</div>
				),
			},
		],
		[
			currencyId,
			deleteEquipmentCatalog,
			equipmentType,
			handleEditEquipmentCatalog,
			onEquipmentCatalogDetail,
		]
	);

	const openEpsl = useCallback(
		(record) => {
			const path = `/supplier/equipments/equipmentPerStockLocation/detail/${record.id}`;
			history.push(getLinkWIthBackLinkParams(location, DEFAULT_BACK_TEXT, path));
		},
		[location, history]
	);

	const onEpslRow = (record) => ({
		onClick: () => openEpsl(record),
	});

	const onCloseMoveAsset = useCallback(() => {
		setSelectedRowKeys([]);
		hideMoveAssetModal();
	}, [hideMoveAssetModal]);

	const openMoveToAsset = useCallback(() => {
		const records = getRecordsForTargetCollection(
			equipmentsPerStockLocation,
			EQUIPMENT_PER_STOCK_LOCATIONS_FOR_EQUIPMENT
		);
		const selectedRecords = selectedRowKeys.reduce((acc, key) => {
			const epsl = records.find((_) => _.id === key);
			return epsl ? [...acc, ...[epsl]] : acc;
		}, []);

		setSelectedEpsls(selectedRecords);
		showMoveAssetModal();
	}, [selectedRowKeys, equipmentsPerStockLocation, showMoveAssetModal]);

	const openTransferToLocation = useCallback(
		(epsl) => (e) => {
			stopDefaultEvents(e);
			setSelectedEpsls([epsl]);
			showMoveAssetModal();
		},
		[showMoveAssetModal]
	);

	const canTransferToLocation = useMemo(
		() => !!nullSafeGet('assetTypeId', equipmentType),
		[equipmentType]
	);

	const getStockLocationLink = useCallback(
		(sl, isDisbursed) => {
			const tabName = isDisbursed ? 'disbursedEquipments' : 'receivedEquipment';
			return `/supplier/stockLocations/detail/${nullSafeGet(
				'id',
				sl
			)}/${tabName}?equipmentTypeIds=${nullSafeGet('id', equipmentType)}`;
		},
		[equipmentType]
	);

	const equipmentPerStockLocationColumns = useMemo(
		() => [
			{
				title: 'Equipment ID',
				dataIndex: 'equipmentUniqueId',
			},
			{
				title: 'Serial No.',
				dataIndex: 'serialNumber',
				render: (text) => text || '--',
			},
			{
				title: 'Stock Location',
				dataIndex: ['stockLocation'],
				render: (sl, record) => (
					<HyperLink
						text={nullSafeGet('name', sl)}
						entityId={nullSafeGet('id', sl)}
						newTabPath={getStockLocationLink(sl, record.isDisbursed)}
						backLinkText={DEFAULT_BACK_TEXT}
					/>
				),
			},
			{
				title: 'Rental',
				dataIndex: 'isRental',
				render: (text) => <EquipmentRentalRowDisplay isRental={text} />,
			},
			{
				align: 'center',
				render: (_, record) =>
					isInternalTech(currentUser) ? (
						record.isDisbursed ? (
							<GreyStatusTag text="Disbursed" />
						) : (
							<Popover
								content={
									canTransferToLocation
										? ''
										: 'Equipment needs to be associated with an asset type to transfer'
								}
								placement={'top'}
								trigger="hover"
							>
								<Button size="large" onClick={openTransferToLocation(record)}>
									Transfer to location
								</Button>
							</Popover>
						)
					) : null,
			},
		],
		[canTransferToLocation, currentUser, getStockLocationLink, openTransferToLocation]
	);

	const onMoveAssetSuccess = useCallback(() => {
		message.success('Equipment(s) moved to location successfully!');
		fetchEquipment();
		onCloseMoveAsset();
	}, [fetchEquipment, onCloseMoveAsset]);

	const onSelectChange = useCallback((newSelectedRowKeys: React.Key[]) => {
		setSelectedRowKeys(newSelectedRowKeys);
	}, []);

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		getCheckboxProps: (record) => ({
			disabled: record.isDisbursed,
		}),
	};

	return equipmentsFetching ? (
		<PageLoadingPlaceholder />
	) : (
		<Content className="equipmentsDetailDetailsPage">
			<LogOnMountWithStandardEventProperties eventType="visited supplier equipment detail page" />
			{equipmentCatalogModalFormVisible ? (
				<EquipmentCatalogModalForm
					visible={equipmentCatalogModalFormVisible}
					formData={equipmentCatalogFormData}
					onSuccess={handleEquipmentCatalogModalFormClose}
					onCancel={handleEquipmentCatalogModalFormClose}
					targetCollectionName={EQUIPMENT_CATALOGS_FOR_EQUIPMENT}
				/>
			) : null}
			<Row style={{ margin: '16px 4px' }} gutter={12}>
				<Col span={24}>
					<div className="rowSpacing">
						<Card bodyStyle={{ padding: 0 }}>
							{equipmentType.images && equipmentType.images.length > 0 ? (
								<div>
									{
										<ProtectedImageThumbnails
											style={{ marginBottom: 32 }}
											imageWidth={160}
											imageHeight={160}
											photoStrings={equipmentType.images}
											role={ROLE_TYPES.SUPPLIER}
										/>
									}
								</div>
							) : null}
							<div className="materialCard__body">
								{equipmentType.description && equipmentType.description.length > 0 ? (
									<div className="text-lg">{equipmentType.description}</div>
								) : null}
								<div className="materialCard__supportingText">
									<HorizontalKeyValueDisplay keyWidth={160} keyValueStore={equipmentDetails} />
								</div>
							</div>
						</Card>
					</div>
				</Col>
				<Col span={24}>
					<div className="rowSpacing">
						<Card
							title="Vendors"
							extra={
								<Button
									onClick={handleEquipmentCatalogModalFormOpen}
									type="link"
									icon={<PlusOutlined translate="" />}
								>
									Add Vendor
								</Button>
							}
						>
							<PaginatedReduxTable
								showHeader={true}
								emptyState={
									<EmptyState
										graphic={
											<img
												alt="No Data"
												style={{ marginBottom: 8 }}
												src="https://s3.amazonaws.com/mock-data-assets/categories/images/sunset.svg"
											/>
										}
										headline={'Nothing much to see here.'}
										body={
											<div style={{ textAlign: 'center' }}>
												<div style={{ maxWidth: 440, marginBottom: 16 }}>
													You haven't added any equipment sold by this vendor yet.
												</div>
											</div>
										}
									/>
								}
								collectionName="equipment_catalogs"
								targetCollectionName={EQUIPMENT_CATALOGS_FOR_EQUIPMENT}
								columns={equipmentCatalogsColumns}
								keyAccessor={(el) => el.id}
								initialFilters={{ equipmentTypeId: equipmentTypeId }}
								initialPagination={{ current: 1, pageSize: 10 }}
								fetchData={equipmentCatalogsRestCrudThunksForSupplier.read}
							/>
						</Card>
					</div>
					<div className="rowSpacing">
						<AdvancedFilters
							initialFilters={{}}
							preAppliedFilters={{ equipmentTypeId }}
							inputParamName={'serialNumber'}
							updateFilters={updateEpslFilters}
							filterConfig={[]}
							filtersTargetCollectionName={EQUIPMENT_PER_STOCK_LOCATIONS_FOR_EQUIPMENT}
							clearAndUpdateFilters={clearAndUpdateFilters}
							filtersStateSlice={equipmentsPerStockLocation}
						/>
					</div>
					<div className="rowSpacing">
						<Card>
							{selectedRowKeys && selectedRowKeys.length > 0 ? (
								<Button type="link" onClick={openMoveToAsset}>
									{`Transfer ${selectedRowKeys.length} equipment${
										selectedRowKeys.length > 1 ? 's' : ''
									} to location`}
								</Button>
							) : null}
							<PaginatedReduxTable
								showHeader={true}
								rowSelection={rowSelection}
								emptyState={
									<EmptyState
										graphic={
											<img
												alt={`You haven't stocked this equipment anywhere yet`}
												style={{ marginBottom: 8 }}
												src="https://s3.amazonaws.com/mock-data-assets/categories/images/sunset.svg"
											/>
										}
										headline={'You should get out more.'}
										body={
											<div style={{ textAlign: 'center' }}>
												<div style={{ maxWidth: 440, marginBottom: 16 }}>
													You haven't stocked this equipment anywhere yet.
												</div>
											</div>
										}
									/>
								}
								collectionName="equipment_per_stock_locations"
								targetCollectionName={EQUIPMENT_PER_STOCK_LOCATIONS_FOR_EQUIPMENT}
								columns={equipmentPerStockLocationColumns}
								keyAccessor={(el) => el.id}
								onRow={onEpslRow}
								initialFilters={{ equipmentTypeId: equipmentTypeId }}
								initialPagination={{ current: 1, pageSize: 10 }}
								fetchData={equipmentPerStockLocationsRestCrudThunksForSupplier.read}
							/>
						</Card>
					</div>
				</Col>
			</Row>
			{moveAssetModalVisible && (
				<Modal
					visible
					closable
					title="Transfer to location"
					onCancel={onCloseMoveAsset}
					footer={null}
				>
					<BulkTransferFromStockLocationModal
						selectedEpsls={selectedEpsls}
						onCancel={onCloseMoveAsset}
						onSuccess={onMoveAssetSuccess}
						isTransfer
					/>
				</Modal>
			)}
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	equipmentsPerStockLocation: state.equipment_per_stock_locations,
	equipmentsFetching: state.equipment_types.fetching,
	equipmentType: state.equipment_types.detail,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
	location: ownProps.location,
});

const mapDispatchToProps = (dispatch) => ({
	updateEpslFilters: (filters, targetCollection) =>
		dispatch(
			EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)
		),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(
				filters,
				targetCollectionName
			)
		),
	getEquipment: (id) => dispatch(equipmentTypesRestCrudThunksForSupplier.readOne(id)),
	deleteEquipmentCatalog: (entity) =>
		dispatch(equipmentCatalogsRestCrudThunksForSupplier.delete(entity, 'id', null, true)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EquipmentsDetailPage));

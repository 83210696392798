import * as React from 'react';
import { Route, withRouter } from 'react-router-dom';
import OWAsyncRadio from '../visual_radio_input/VisualRadioInput';
import { connect } from 'react-redux';
import { locationsRestCrudThunksForSupplier } from '../../thunks/locations_thunks';
import {
	problemTypesHeirarchicalForSupplier,
	problemTypesRestCrudThunksForSupplier,
} from '../../thunks/problem_types_thunks';
import { assetsRestCrudThunksForSupplier } from '../../thunks/assets_thunks';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { MenuOutlined } from '@ant-design/icons';
import { Button, Drawer, Input, Menu, message, Modal, Progress, Table } from 'antd';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import { imageUploadValidation, normFile } from '../../utils/ImageUtils';
import { getProtectedImageUriForSupplier } from '../../utils/FileAccessUtils';
import { TwoLineList } from '../two_line_list/TwoLineList';
import { ProtectedImageThumbnails } from '../protected_image_thumbnails/ProtectedImageThumbnails';
import { ROLE_TYPES, WO_STEP_NAMES } from '../../utils/DataConstants';
import {
	checkIsWarrantyWorkOrder,
	createRecordsObjectFromArray,
	filterKeys,
	getFiltersFromLocation,
	getImageCountErrorRules,
	nullSafeGet,
	nullSafeGetOrElse,
	mergeTroubleshootingRoutes,
	getElementAt,
} from '../../utils/DataAccessUtils';
import OWRadio from '../static_visual_radio_input/StaticVisualRadioInput';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { WORK_ORDERS_CRUD_ACTION_CREATORS } from '../../actions/work_orders_actions';
import { workOrderPrioritiesRestCrudThunksForSupplier } from '../../thunks/work_order_priorities_thunks';
import { workOrdersRestCrudThunksForSupplier } from '../../thunks/work_orders_thunks';
import { workOrderAttributesRestCrudThunksForSupplier } from '../../thunks/work_order_attributes_thunks';
import { getBuyerCompanyPrivateNetworksWithLocationsForSupplier } from '../../thunks/supplier_private_networks_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { retrieveCachedUserDetails } from '../../thunks/session_thunks';
import { getBackLinkStates, getQueryStringFromObject } from '../../utils/HistoryUtils';
import { troubleshootingRestCrudThunksForSupplier } from '../../thunks/troubleshooting_thunks';
import TroubleshootingDisplay from '../common/TroubleshootingDisplay';
import { troubleshootingAnswersRestCrudThunksForSupplier } from '../../thunks/troubleshooting_answer_thunks';
import {
	getExistingTsAnswersFromStateForWOTsRoute,
	getTroubleshootingAnswersForNecessaryWOSteps,
	getUpdatedTroubleshootingStates,
	getUpdatedTsAnswersState,
} from '../../utils/TroubleshootingUtils';
import { WorkOrderRowDisplay } from '../work_order_row_display/WorkOrderRowDisplay';
import OWUpload from '../OWUpload';

require('../supplier_new_work_order_wizard_page/SupplierNewWorkOrderWizardPage.less');

const DependentFieldOrder = [
	{
		key: 'problemTypeLevel1Id',
		default: undefined,
	},
	{
		key: 'problemTypeLevel2Available',
		default: false,
	},
	{
		key: 'problemTypeLevel2Id',
		default: undefined,
	},
	{
		key: 'problemTypeLevel3Available',
		default: false,
	},
	{
		key: 'problemTypeLevel3Id',
		default: undefined,
	},
	{
		key: 'problemTypeLevel4Available',
		default: false,
	},
	{
		key: 'asset',
		default: undefined,
	},
];

const duplicateWorkOrderColumns = [
	{
		title: 'Name',
		dataIndex: 'id',
		render: (text, item) => <WorkOrderRowDisplay workOrder={item} />,
	},
];

interface SupplierAssetNewWorkOrderWizardPageProps extends FormComponentProps {
	onSubmit: any;
	onCancel: any;
	redirectForwardUrl: any;
	saveFormData: any;
	visible: boolean;
	createErrors: any;
	updateErrors: any;
	creating: boolean;
	updating: boolean;
	location: any;
	asset: any;
	currentUser: any;
}

class SupplierAssetNewWorkOrderWizardPage extends React.Component<any, any> {
	formRefs: any;

	getDefaultBackPath = () => {
		const { match } = this.props;
		return `/supplier/assets/detail/${match.params.id}`;
	};

	constructor(props) {
		super(props);
		this.formRefs = {};
		this.state = {
			allPhotos: [],
			willChooseDates: false,
			chosenDates: [],
			willUploadPicture: false,
			uploadLoading: false,
			pictureURL: null,
			locations: [],
			locationUpdateVisible: false,
			userContact: {},
			previewVisible: false,
			previewImage: false,
			navMenuVisible: false,
			location: {},
			woPriority: {},
			userLocation: {},
			problemType: {},
			area: {},
			problemTypePriorLevelId: null,
			problemTypePriorLevel: null,
			spendCategoryId: null,
			industryId: 1,
			buyerCompanyId: -1,
			supplier: null,
			nte: undefined,
			currentRoute: '',
			backLinkParams: '',
			backLinkTo: this.getDefaultBackPath(),
			troubleshootingRoutes: [],
			troubleshootingAnswers: [],
			tsAnswerUploading: false,
			currencyExchangeRateLocationToSupplier: 1,
			currencyExchangeRateBuyerToLocation: 1,
			numPicturesRequired: 0,
		};
	}

	handleLocationChange = (locationId, loc) => {
		const { fetchLocation } = this.props;
		if (loc) {
			this.setState({
				location: loc,
				industryId: nullSafeGet('buyerCompany.industryId', loc),
			});
		} else if (locationId) {
			fetchLocation(locationId).then((location) => {
				this.setState({
					location,
				});
			});
		}
	};

	handleAssetLoad = (asset) => {
		const { fetchProblemType, fetchProblemTypesHierarchical, form } = this.props;
		if (!asset) {
			return;
		}
		this.setState({ asset });

		const problemTypeIds = nullSafeGet('assetType.allProblemTypeIds', asset);
		const locationId = asset.locationId;
		const assetId = asset.id;
		form.setFieldsValue({
			locationId,
			assetId,
		});
		this.handleLocationChange(locationId, nullSafeGet('location', asset));
		if (problemTypeIds && problemTypeIds.length === 1) {
			const problemTypeId = problemTypeIds[0];
			this.setState({
				...this.state.initialValues,
				problemTypeId,
			});
			fetchProblemType(problemTypeId).then((problemType) => {
				this.handleProblemTypeChange(problemTypeId, problemType, WO_STEP_NAMES.TITLE);
				this.setState({
					problemType,
					problemTypeTopLevelId: problemTypeId,
					problemTypePriorLevelId: problemTypeId,
					problemTypePriorLevel: problemType.name,
					spendCategoryId: problemType.spendCategoryId,
				});
			});
		} else if (problemTypeIds && problemTypeIds.length > 1) {
			fetchProblemTypesHierarchical({
				ids: problemTypeIds,
			}).then((problemTypes) => {
				this.setState({
					allProblemTypes: problemTypes,
					currentProblemTypes: {
						[WO_STEP_NAMES.PROBLEM_TYPE_TOP_LEVEL]: createRecordsObjectFromArray(problemTypes),
					},
				});
			});
		} else {
			fetchProblemTypesHierarchical().then((problemTypes) => {
				this.setState({
					allProblemTypes: problemTypes,
					currentProblemTypes: {
						[WO_STEP_NAMES.PROBLEM_TYPE_TOP_LEVEL]: createRecordsObjectFromArray(problemTypes),
					},
				});
			});
		}
	};

	componentDidMount() {
		document.body.classList.add('supplierNewWorkOrderWizardBody');
		const { match, fetchAsset, history, location } = this.props;
		this.setState(getBackLinkStates(location, this.getDefaultBackPath()));

		const assetId = match.params.id;

		fetchAsset(assetId).then((asset) => {
			this.handleAssetLoad(asset);
			this.goNext([], WO_STEP_NAMES.ASSET_CONDITION, WO_STEP_NAMES.ASSET);
			// history.push(`/supplier/assets/${assetId}/newWorkOrder/${WO_STEP_NAMES.ASSET_CONDITION}`);
		});
	}

	componentWillMount() {
		document.body.classList.add('supplierNewWorkOrderWizardBody');
	}

	componentWillUnmount() {
		document.body.classList.remove('supplierNewWorkOrderWizardBody');
		const { clearFormData } = this.props;
		clearFormData();
	}

	removeTroubleshootingRoutes = (currentRoute) => {
		const routeHierarchy = [
			WO_STEP_NAMES.ASSET,
			WO_STEP_NAMES.ASSET_CONDITION,
			WO_STEP_NAMES.PROBLEM_TYPE_TOP_LEVEL,
			WO_STEP_NAMES.PROBLEM_TYPE_NEXT_LEVEL,
			WO_STEP_NAMES.PROBLEM_TYPE_NEXT_BRANCH,
			WO_STEP_NAMES.PRIORITY,
			WO_STEP_NAMES.SUPPLIER,
		];
		this.setState(getUpdatedTroubleshootingStates(routeHierarchy, currentRoute, this.state));
	};

	onRouteChanged = () => {
		const pathName = nullSafeGetOrElse('props.location.pathname', this, '');
		const currentRoute = getElementAt(pathName.split('/'), -1);
		this.removeTroubleshootingRoutes(currentRoute);
		const params = getFiltersFromLocation(this.props.location);
		const allowedStateKeys = [
			'problemTypeLevel2Available',
			'problemTypeLevel3Available',
			'problemTypePriorLevelId',
			'problemTypePriorLevel',
			'spendCategoryId',
		];
		const newState = {
			...filterKeys(params, allowedStateKeys, true),
			currentRoute,
		};
		this.setState(newState);
	};

	getTroubleshootingParams = () => {
		const problemTypeId =
			this.state.problemTypeLevel3Id ||
			this.state.problemTypeLevel2Id ||
			this.state.problemTypeLevel1Id;
		return {
			...(nullSafeGet('state.location.id', this) && {
				locationId: nullSafeGet('state.location.id', this),
			}),
			...(problemTypeId && { problemTypeId }),
			...(nullSafeGet('state.asset.assetTypeId', this) && {
				assetTypeId: nullSafeGet('state.asset.assetTypeId', this),
			}),
			...(nullSafeGet('state.asset.id', this) && {
				assetId: nullSafeGet('state.asset.id', this),
			}),
			...(nullSafeGet('state.woPriority.id', this) && {
				workOrderPriorityId: nullSafeGet('state.woPriority.id', this),
			}),
			...(nullSafeGet('state.supplier.facility.id', this) && {
				supplierFacilityId: nullSafeGet('state.supplier.facility.id', this),
			}),
		};
	};

	checkTroubleShooting = (args, previousStep) => {
		const { fetchTroubleshooting } = this.props;
		const params = this.getTroubleshootingParams();
		fetchTroubleshooting(params).then((res) => {
			if (res && res.length > 0) {
				const existingTroubleshootingRoutes = nullSafeGetOrElse(
					'state.troubleshootingRoutes',
					this,
					[]
				);
				if (existingTroubleshootingRoutes.find((_) => _.id === res[0].id)) {
					this.proceedToNextStep(args);
				} else {
					this.setState({
						troubleshootingRoutes: [
							...existingTroubleshootingRoutes,
							...[
								{
									...res[0],
									args,
									previousStep,
								},
							],
						],
					});
					this.proceedToNextStep({
						validationSteps: [],
						destination: `troubleshooting-${res[0].id}`,
					});
				}
			} else {
				this.proceedToNextStep(args);
			}
		});
	};

	componentDidUpdate(prevProps): void {
		if (this.props.location !== prevProps.location) {
			this.onRouteChanged();
		}
	}

	updateTroubleshootingAnswerIfAvailable = (workOrderId) => {
		const { createTroubleshootingAnswersBulk } = this.props;
		const answers = nullSafeGetOrElse('troubleshootingAnswers', this.state, []);
		return new Promise((resolve, reject) => {
			if (answers.length > 0) {
				this.setState({ tsAnswerUploading: true });
				createTroubleshootingAnswersBulk(
					answers.map((_) => ({
						..._,
						workOrderId,
					}))
				)
					.then((res) => resolve(res))
					.catch((err) => {
						message.error(err);
						reject(err);
					})
					.finally(() => this.setState({ tsAnswerUploading: false }));
			} else {
				resolve(true);
			}
		});
	};

	handleSubmit = () => {
		const { form, formData, history, currentUser, submitWorkOrderForm } = this.props;
		const { location, problemType, currencyExchangeRateLocationToSupplier } = this.state;
		form.validateFields((err, values) => {
			if (!err) {
				// put image data into proper format to persist
				const picturesInfo = this.state.allPhotos.map((p) => {
					const fileName = nullSafeGetOrElse('response.data.fileName', p, '');
					const fileId = nullSafeGetOrElse('response.data.fileId', p, '');
					return `${fileId}/${fileName}`;
				});

				const contractorDetails = {
					contractorContacts: [],
				};

				const workOrder = {
					title: values.title,
					problemTypeId: parseInt(nullSafeGet('id', problemType), 10) || 1,
					woPriorityId: nullSafeGet('id', this.state.woPriority)
						? parseInt(nullSafeGet('id', this.state.woPriority), 10)
						: null,
					spendCategoryId: parseInt(this.state.spendCategoryId, 10),
					locationId: parseInt(location.id, 10),
					assetId: formData.assetId ? parseInt(formData.assetId, 10) : null,
					...(formData.assetId && {
						isAssetDown: formData.isAssetDown === 'true' ? true : false,
					}),
					images: picturesInfo,
					currencyExchangeRateLocationToSupplier: currencyExchangeRateLocationToSupplier,
					description: formData.additionalInfo,
					buyerFacilityId: nullSafeGet('buyerFacilityId', location),
					buyerCompanyId: nullSafeGet('buyerCompanyId', location),
					createdBy: currentUser.email,
					supplierFacilityId: currentUser.facility.id,
					contractorDetails: contractorDetails,
					notes: [],
					supplierAttachments: [],
				};

				submitWorkOrderForm(workOrder).then((rec) => {
					this.updateTroubleshootingAnswerIfAvailable(rec.id).then(() =>
						history.push(
							`/supplier/workOrders/detail/${rec.id}/details`.concat('?', this.state.backLinkParams)
						)
					);
				});
			}
		});
	};

	handleUploadPicture = (value) => {
		value.target.value === 'yes'
			? this.setState({
					willUploadPicture: true,
			  })
			: this.setState({
					willUploadPicture: false,
			  });
	};

	proceedToNextStep = (values) => {
		const { validationSteps, destination, useReplace = false, params = {} } = values;
		const { form, history, match } = this.props;
		form.validateFields(validationSteps, (err) => {
			if (err) {
				console.log(err);
				return;
			}
			window.scrollTo(0, 0);
			const navigateFunction = useReplace ? history.replace : history.push;
			const queryString = getQueryStringFromObject(params);
			const navigateTo = `/supplier/assets/${match.params.id}/newWorkOrder/${destination}${
				queryString ? `?${queryString}` : ''
			}`;
			console.log(`navigateTo : ${navigateTo}`);
			navigateFunction(navigateTo);
		});
	};

	goNext = (
		validationSteps,
		destination,
		stepName,
		ingnoreTroubleshooting = false,
		useReplace = false,
		params = {}
	) => {
		const args = {
			validationSteps,
			destination,
			useReplace,
			params,
		};

		const requiredSteps = [
			WO_STEP_NAMES.ASSET,
			WO_STEP_NAMES.PROBLEM_TYPE_TOP_LEVEL,
			WO_STEP_NAMES.PROBLEM_TYPE_NEXT_LEVEL,
			WO_STEP_NAMES.PROBLEM_TYPE_NEXT_BRANCH,
			WO_STEP_NAMES.PRIORITY,
			WO_STEP_NAMES.SUPPLIER,
		];

		!ingnoreTroubleshooting && requiredSteps.includes(stepName)
			? this.checkTroubleShooting(args, stepName)
			: this.proceedToNextStep(args);
	};

	cancelRequest = () => this.props.history.push(this.state.backLinkTo);

	goBack = () => {
		const { history } = this.props;
		history.goBack();
	};

	goNextAfterBriefPause = (
		validationSteps,
		destination,
		currentStep,
		ignoreTroubleshooting = false
	) => {
		setTimeout(
			() => this.goNext(validationSteps, destination, currentStep, ignoreTroubleshooting),
			350
		);
	};

	resetFieldAFter = (changedState) => {
		const index = DependentFieldOrder.findIndex((_) => _.key === changedState);
		const newState =
			index > -1
				? DependentFieldOrder.slice(index + 1).reduce((obj, field) => {
						obj[field.key] = field.default;
						return obj;
				  }, {})
				: {};
		this.setState(newState);
	};

	changeId = (key, value) => {
		this.setState({ [key]: value });
		this.resetFieldAFter(key);
	};

	handlePreview = (obj) => {
		let thumbUrl = null;
		let fullUrl = null;
		let photoInfo = null;
		if (obj.response.data) {
			const imgFileId = obj.response.data.fileId;
			const imgFileName = obj.response.data.fileName;
			thumbUrl = getProtectedImageUriForSupplier(imgFileId, imgFileName, 100, 100);
			fullUrl = getProtectedImageUriForSupplier(imgFileId, imgFileName, 800, 800);
			photoInfo = {
				uid: imgFileId,
				size: 1,
				name: imgFileName,
				filename: imgFileName,
				url: fullUrl,
				status: 'done',
				thumbUrl: thumbUrl,
				response: { data: { fileName: imgFileName, fileId: imgFileId } },
				type: 'image/jpeg',
			};
		}
		this.setState({
			photoInfo: [photoInfo],
			previewImage: fullUrl || thumbUrl,
			previewVisible: true,
		});
	};

	handleImageUpdate = (info) => {
		this.setState({ allPhotos: info.fileList });
	};

	getProblemType = (problemTypeId) => {
		const allProblemTypes = nullSafeGetOrElse('state.allProblemTypes', this, []);

		const find = (pts) => {
			return pts.reduce((acc, _) => {
				// eslint-disable-next-line eqeqeq
				if (_.id == problemTypeId) {
					return {
						...acc,
						..._,
					};
				} else if (nullSafeGetOrElse('children', _, []).length > 0) {
					return { ...acc, ...find(_.children) };
				} else {
					return acc;
				}
			}, {});
		};

		return find(allProblemTypes);
	};

	handleProblemTypeChange = (problemTypeId, problemType, nextStep) => {
		if (problemType.hasChildren) {
			this.setState({
				currentProblemTypes: {
					...this.state.currentProblemTypes,
					[nextStep]: createRecordsObjectFromArray(problemType.children),
				},
			});
		}
		this.setState({
			problemType,
			problemTypePriorLevelId: problemTypeId,
			problemTypePriorLevel: problemType.hierarchyName,
			spendCategoryId: problemType.spendCategoryId,
			numPicturesRequired: nullSafeGetOrElse('serviceRequestNumPicturesRequired', problemType, 0),
		});
		if (problemTypeId) {
			const {
				fetchWorkOrderAttributes,
				fetchWorkOrderPriority,
				fetchSupplierFacilitiesInPrivateNetwork,
			} = this.props;
			if (problemTypeId) {
				if (problemType.spendCategoryId) {
					fetchSupplierFacilitiesInPrivateNetwork({
						spendCategoryId: problemType.spendCategoryId,
						locationId: nullSafeGet('id', this.state.location),
					}).then((s) =>
						s.length > 0 ? this.setState({ supplier: s[0] }) : this.setState({ supplier: null })
					);
				}
				const { currencyExchangeRateBuyerToLocation } = this.state;
				fetchWorkOrderAttributes({ problemTypeId }).then((workOrderAttributes) => {
					if (workOrderAttributes.length > 0) {
						this.setNTE(workOrderAttributes[0].NTEAmount * currencyExchangeRateBuyerToLocation);
						if (workOrderAttributes[0].workOrderPriorityId) {
							fetchWorkOrderPriority(workOrderAttributes[0].workOrderPriorityId).then(
								(workOrderPriority) => this.setState({ woPriority: workOrderPriority })
							);
						}
						fetchSupplierFacilitiesInPrivateNetwork({
							spendCategoryId: nullSafeGet('spendCategoryId', problemType),
							locationId: nullSafeGet('id', this.state.location),
						}).then((s) =>
							s.length > 0 ? this.setState({ supplier: s[0] }) : this.setState({ supplier: null })
						);
					}
				});
			}
		}
	};

	handleProblemTypeTopLevelChange = (problemTypeId) => {
		const problemType = this.getProblemType(problemTypeId);
		this.handleProblemTypeChange(problemTypeId, problemType, WO_STEP_NAMES.PROBLEM_TYPE_NEXT_LEVEL);
		this.setState(
			{
				problemTypeTopLevelId: problemTypeId,
			},
			() => {
				if (problemType && problemType.hasChildren) {
					this.setState({ problemTypeLevel2Available: true });
					this.goNext(
						[],
						WO_STEP_NAMES.PROBLEM_TYPE_NEXT_LEVEL,
						WO_STEP_NAMES.PROBLEM_TYPE_TOP_LEVEL
					);
				} else {
					this.setState({ problemTypeLevel2Available: false });
					this.goNext([], WO_STEP_NAMES.TITLE, WO_STEP_NAMES.PROBLEM_TYPE_TOP_LEVEL);
				}
			}
		);
	};

	handleProblemTypeNextLevelChange = (problemTypeId) => {
		const problemType = this.getProblemType(problemTypeId);
		this.handleProblemTypeChange(
			problemTypeId,
			problemType,
			WO_STEP_NAMES.PROBLEM_TYPE_NEXT_BRANCH
		);

		if (problemType.hasChildren) {
			this.setState({
				problemTypeLevel3Available: problemType.hasChildren,
			});
			this.goNext(
				[],
				WO_STEP_NAMES.PROBLEM_TYPE_NEXT_BRANCH,
				WO_STEP_NAMES.PROBLEM_TYPE_NEXT_LEVEL
			);
		} else {
			this.setState({
				problemTypeLevel3Available: false,
			});
			this.goNext([], WO_STEP_NAMES.TITLE, WO_STEP_NAMES.PROBLEM_TYPE_NEXT_LEVEL);
		}
	};

	handleProblemTypeNextLevelBranchChange = (problemTypeId) => {
		const problemType = this.getProblemType(problemTypeId);
		this.handleProblemTypeChange(
			problemTypeId,
			problemType,
			WO_STEP_NAMES.PROBLEM_TYPE_NEXT_BRANCH
		);

		if (problemType.hasChildren) {
			this.goNext(
				[],
				WO_STEP_NAMES.PROBLEM_TYPE_NEXT_BRANCH,
				WO_STEP_NAMES.PROBLEM_TYPE_NEXT_LEVEL
			);
		} else {
			this.goNext([], WO_STEP_NAMES.TITLE, WO_STEP_NAMES.PROBLEM_TYPE_NEXT_LEVEL);
		}
	};

	handleWorkOrderPriority = (woPriorityId) => {
		const { fetchWorkOrderPriority } = this.props;
		fetchWorkOrderPriority(woPriorityId).then((woPriority) => {
			this.setState({ woPriority }, () => {
				this.goNextAfterBriefPause([], WO_STEP_NAMES.COMPLETION, WO_STEP_NAMES.PRIORITY);
			});
		});
	};

	handleWorkOrderPriorityClick = (woPriorityId) => {
		const { fetchWorkOrderPriority } = this.props;
		fetchWorkOrderPriority(woPriorityId).then((woPriority) => {
			this.setState({ woPriority }, () => {
				this.goNext([], WO_STEP_NAMES.COMPLETION, WO_STEP_NAMES.PRIORITY);
			});
		});
	};

	setNTE = (nte) => {
		const { form } = this.props;
		this.setState({ nte });
		form.setFieldsValue({
			nte: nte ? parseFloat(nte).toFixed(2) : undefined,
		});
	};

	handleNavMenuClose = () => {
		this.setState({ navMenuVisible: false });
	};

	handleAssetCondition = () => {
		const { asset } = this.state;
		const problemTypeIds = nullSafeGet('assetType.allProblemTypeIds', asset);
		const { fetchWorkOrders } = this.props;
		fetchWorkOrders({
			assetId: nullSafeGet('id', asset),
			locationId: nullSafeGet('location.id', this.state),
			isPM: false,
			displayStatuses: ['Pending', 'Open', 'InProgress', 'OnHold'],
		}).then((workOrders) => {
			if (!workOrders || workOrders.length === 0) {
				if (problemTypeIds && problemTypeIds.length === 1) {
					this.goNextAfterBriefPause([], WO_STEP_NAMES.TITLE, WO_STEP_NAMES.ASSET_CONDITION);
				} else {
					this.goNextAfterBriefPause(
						[],
						WO_STEP_NAMES.PROBLEM_TYPE_TOP_LEVEL,
						WO_STEP_NAMES.ASSET_CONDITION
					);
				}
			} else {
				this.setState({ duplicateWorkOrders: workOrders });
				this.goNext([], WO_STEP_NAMES.DUPLICATE_CHECK, WO_STEP_NAMES.ASSET);
			}
		});
	};
	handleDuplicate = (resp) => {
		const { asset } = this.state;
		const problemTypeIds = nullSafeGet('assetType.allProblemTypeIds', asset);
		if (resp == 1) {
			this.cancelRequest();
		} else {
			if (problemTypeIds && problemTypeIds.length === 1) {
				this.goNextAfterBriefPause([], WO_STEP_NAMES.TITLE, WO_STEP_NAMES.ASSET_CONDITION);
			} else {
				this.goNextAfterBriefPause(
					[],
					WO_STEP_NAMES.PROBLEM_TYPE_TOP_LEVEL,
					WO_STEP_NAMES.ASSET_CONDITION
				);
			}
		}
	};

	handleNavMenuOpen = () => {
		this.setState({ navMenuVisible: true });
	};

	handleNavMenuClick = (e) => {
		const methods = {
			problemTypeNextLevel: () =>
				this.handleProblemTypeTopLevelChange(this.state.problemTypeLevel1Id),
			problemTypeNextBranch: () =>
				this.handleProblemTypeNextLevelChange(this.state.problemTypeLevel2Id),
		};
		methods[e.key] ? methods[e.key]() : this.goNext([], e.key, '', true);
	};

	handleCancel = () => this.setState({ previewVisible: false });

	isTroubleshootingAllowedToProceed = (checkSteps) => {
		const tsRoutes = nullSafeGetOrElse('state.troubleshootingRoutes', this, []);
		const allowedToProceed =
			tsRoutes
				.filter((_) => checkSteps.includes(_.previousStep))
				.filter((_) => !nullSafeGetOrElse('troubleshootingSteps', _, [{}])[0].canProceedToNextStep)
				.length === 0;

		return allowedToProceed;
	};

	isProblemTypeAndAssetSelected = () => {
		const {
			problemTypeLevel1Id,
			problemTypeLevel2Available,
			problemTypeLevel2Id,
			problemTypeLevel3Available,
			problemTypeLevel3Id,
		} = this.state;
		const problemTypeSelected = problemTypeLevel1Id
			? problemTypeLevel2Available
				? problemTypeLevel2Id
					? problemTypeLevel3Available
						? problemTypeLevel3Id
							? true
							: false
						: true
					: false
				: true
			: false;

		const checkSteps = [
			WO_STEP_NAMES.PROBLEM_TYPE_TOP_LEVEL,
			WO_STEP_NAMES.PROBLEM_TYPE_NEXT_LEVEL,
			WO_STEP_NAMES.PROBLEM_TYPE_NEXT_BRANCH,
		];
		return problemTypeSelected && this.isTroubleshootingAllowedToProceed(checkSteps);
	};

	isPrioritySelected = () =>
		nullSafeGet('woPriority.id', this.state) &&
		this.isTroubleshootingAllowedToProceed([WO_STEP_NAMES.PRIORITY]);

	render() {
		const {
			location,
			creating,
			formData,
			workOrderPriorities,
			fetchWorkOrderPriorities,
			fetchMultipleWorkOrderPriorities,
			form,
			match,
		} = this.props;
		const { getFieldDecorator, getFieldValue } = form;
		const { previewVisible, previewImage } = this.state;
		const BACKEND_URI = getBackendUri();
		const uploadHeaders = { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token };

		const { locationTsAnswer, problemTypeTsAnswer, priorityTsAnswer } =
			getTroubleshootingAnswersForNecessaryWOSteps(this.state);

		const workOrderDetails = [
			{ key: 'Location', value: nullSafeGetOrElse('name', this.state.location, '') },
			...locationTsAnswer,
			{ key: 'Area', value: nullSafeGetOrElse('hierarchyName', this.state.area, '') },
			{
				key: 'Problem Type',
				value: nullSafeGetOrElse('hierarchyName', this.state.problemType, ''),
			},
			...problemTypeTsAnswer,
			{ key: 'Asset', value: nullSafeGetOrElse('name', this.state.asset, '') },
			...(nullSafeGetOrElse('name', this.state.asset, '')
				? [
						{
							key: 'Is the Asset Under Warranty?',
							value: checkIsWarrantyWorkOrder(
								nullSafeGet('asset.warranties.0', this.state),
								nullSafeGet('supplierFacilityId', formData)
							)
								? 'No'
								: 'Yes',
						},
						{
							key: 'Is the Asset functioning?',
							value: formData.isAssetDown === 'true' ? 'No' : 'Yes',
						},
				  ]
				: []),
			{ key: 'Title', value: nullSafeGetOrElse('title', formData, '') },
			{ key: 'Priority', value: nullSafeGetOrElse('woPriority.name', this.state, '') },
			...priorityTsAnswer,
		];

		const photoStrings = (getFieldValue('images') || []).map((p) => {
			const fileName = nullSafeGetOrElse('response.data.fileName', p, '');
			const fileId = nullSafeGetOrElse('response.data.fileId', p, '');
			return `${fileId}/${fileName}`;
		});

		const minPhotosCount = nullSafeGetOrElse('state.numPicturesRequired', this, 0);

		const imageCountErrorMessage = `You need to upload at least ${minPhotosCount} photo${
			minPhotosCount > 1 ? 's' : ''
		}`;

		const imageCountMatched =
			minPhotosCount <= nullSafeGetOrElse('length', getFieldValue('images'), 0);

		const getTroubleshootingSteps = () => {
			const troubleshootingRoutes = nullSafeGetOrElse('state.troubleshootingRoutes', this, []);
			return troubleshootingRoutes.reduce((steps, route) => {
				const existingAnswer = getExistingTsAnswersFromStateForWOTsRoute(this.state, route);
				return {
					...steps,
					[`troubleshooting-${route.id}`]: {
						id: `t-${route.id}`,
						label: 'Troubleshooting',
						component: (
							<TroubleshootingDisplay
								troubleshootingSteps={route.troubleshootingSteps}
								onBack={this.goBack}
								onNextStep={(answers) => {
									if (nullSafeGet('0', answers)) {
										const troubleshootingAnswers = getUpdatedTsAnswersState(
											this.state,
											route,
											answers
										);
										this.setState({ troubleshootingAnswers });
									}
									route.args && this.proceedToNextStep(route.args);
								}}
								onCancel={this.cancelRequest}
								existingAnswer={existingAnswer}
							/>
						),
					},
				};
			}, {});
		};

		const defaultNavMenuSteps = [
			...(this.state.asset
				? [
						{
							title: 'assetCondition',
							enabled: true,
						},
				  ]
				: []),
			{
				title: 'problemTypeTopLevel',
			},
			...(this.state.problemTypeLevel2Available
				? [
						{
							title: 'problemTypeNextLevel',
							enabled: true,
						},
				  ]
				: []),
			...(this.state.problemTypeLevel3Available
				? [
						{
							title: 'problemTypeNextBranch',
							enabled: true,
						},
				  ]
				: []),
			{
				title: 'title',
				enabled: this.isProblemTypeAndAssetSelected(),
			},
			{
				title: 'photos',
				enabled: this.isProblemTypeAndAssetSelected() && nullSafeGet('title', formData),
			},
			{
				title: 'priority',
				enabled:
					this.isProblemTypeAndAssetSelected() &&
					nullSafeGet('title', formData) &&
					imageCountMatched,
			},
			{
				title: 'completion',
				enabled:
					this.isProblemTypeAndAssetSelected() &&
					nullSafeGet('title', formData) &&
					this.isPrioritySelected() &&
					imageCountMatched,
			},
		];

		const isImageUploading = (getFieldValue('images') || []).some((_) => _.status === 'uploading');

		const getNavSteps = () => {
			const allNavSteps = mergeTroubleshootingRoutes(
				defaultNavMenuSteps,
				nullSafeGetOrElse('state.troubleshootingRoutes', this, []),
				(route) => defaultNavMenuSteps.map((_) => _.title).indexOf(route.previousStep),
				(route) => ({
					title: `troubleshooting-${route.id}`,
					enabled: true,
				})
			);

			return allNavSteps;
		};

		const defaultSteps = {
			assetCondition: {
				id: 1,
				label: 'Asset Status',
				component: (
					<div key={8} className="supplierNewWorkOrderWizardPage__section px-2">
						<h3 className="supplierNewWorkOrderWizardPage__title" style={{ marginBottom: 16 }}>
							{`Is the asset functioning?`}
						</h3>
						<Form.Item style={{ width: '100%' }}>
							{getFieldDecorator(`isAssetDown`, {
								initialValue: nullSafeGetOrElse('isAssetDown', formData, ''),
							})(
								<OWRadio
									fieldName="assetId"
									onChange={this.handleAssetCondition}
									onClick={this.handleAssetCondition}
									renderRecord={(location) => location.name}
									records={{
										false: {
											id: 'false',
											name: 'Yes',
										},
										true: {
											id: 'true',
											name: 'No',
										},
									}}
									sorter={(records) => records.sort((a, b) => (a.id > b.id ? 1 : -1))}
								/>
							)}
						</Form.Item>
						<div className="flex flex-row">
							<Button size="large" type="ghost" onClick={() => this.goBack()}>
								Back
							</Button>
						</div>
					</div>
				),
			},
			problemTypeTopLevel: {
				id: 2,
				label: 'Problem Type',
				component: (
					<div key={5} className="supplierNewWorkOrderWizardPage__section px-2">
						<h3 className="supplierNewWorkOrderWizardPage__title">What kind of problem is it?</h3>
						<Form.Item className={'flex w-full flex-grow'}>
							{getFieldDecorator(`problemTypeTopLevel`, {
								rules: [
									{
										required: false,
									},
								],
								initialValue: nullSafeGet('problemTypeLevel1Id', this.state),
							})(
								<OWRadio
									fieldName="problemTypeTopLevel"
									onChange={this.handleProblemTypeTopLevelChange}
									onClick={this.handleProblemTypeTopLevelChange}
									renderRecord={(pt) => pt.name}
									records={nullSafeGetOrElse(
										`state.currentProblemTypes.${WO_STEP_NAMES.PROBLEM_TYPE_TOP_LEVEL}`,
										this,
										[]
									)}
									sorter={(records) => records.sort((a, b) => (a.id > b.id ? 1 : -1))}
								/>
							)}
						</Form.Item>
						<Button size="large" type="ghost" onClick={() => this.goBack()}>
							Back
						</Button>
					</div>
				),
			},
			problemTypeNextLevel: {
				id: 3,
				label: 'Problem Type - 2',
				component: (
					<div key={6} className="supplierNewWorkOrderWizardPage__section px-2">
						<h3 className="supplierNewWorkOrderWizardPage__title">What kind of problem is it?</h3>
						<Form.Item className={'flex w-full flex-grow'}>
							{getFieldDecorator(`problemTypeId`, {
								rules: [
									{
										required: false,
									},
								],
								initialValue: nullSafeGet('problemTypeLevel2Id', this.state),
							})(
								nullSafeGet('currentRoute', this.state) === 'problemTypeNextLevel' ? (
									<OWRadio
										fieldName="problemTypeId"
										onChange={this.handleProblemTypeNextLevelChange}
										onClick={this.handleProblemTypeNextLevelChange}
										renderRecord={(pt) => pt.name}
										records={nullSafeGetOrElse(
											`state.currentProblemTypes.${WO_STEP_NAMES.PROBLEM_TYPE_NEXT_LEVEL}`,
											this,
											[]
										)}
										sorter={(records) => records.sort((a, b) => (a.id > b.id ? 1 : -1))}
									/>
								) : (
									<div />
								)
							)}
						</Form.Item>
						<Button size="large" type="ghost" onClick={() => this.goBack()}>
							Back
						</Button>
					</div>
				),
			},
			problemTypeNextBranch: {
				id: 4,
				label: 'Problem Type - 3',
				component: (
					<div key={7} className="supplierNewWorkOrderWizardPage__section px-2">
						<h3 className="supplierNewWorkOrderWizardPage__title">What kind of problem is it?</h3>
						<Form.Item className={'flex w-full flex-grow'}>
							{getFieldDecorator(`problemTypeIdNextLevel`, {
								rules: [
									{
										required: false,
									},
								],
								initialValue: nullSafeGet('problemTypeLevel3Id', this.state),
							})(
								nullSafeGet('currentRoute', this.state) === 'problemTypeNextBranch' ? (
									<OWRadio
										fieldName="problemTypeIdNextLevel"
										onChange={this.handleProblemTypeNextLevelBranchChange}
										onClick={this.handleProblemTypeNextLevelBranchChange}
										renderRecord={(pt) => pt.name}
										records={nullSafeGetOrElse(
											`state.currentProblemTypes.${WO_STEP_NAMES.PROBLEM_TYPE_NEXT_BRANCH}`,
											this,
											[]
										)}
										sorter={(records) => records.sort((a, b) => (a.id > b.id ? 1 : -1))}
									/>
								) : (
									<div />
								)
							)}
						</Form.Item>
						<Button size="large" type="ghost" onClick={() => this.goBack()}>
							Back
						</Button>
					</div>
				),
			},
			title: {
				id: 5,
				label: 'Description',
				component: (
					<div key={9} className="supplierNewWorkOrderWizardPage__section px-2">
						<h3 className="supplierNewWorkOrderWizardPage__title">
							In a few words, what's the problem?
						</h3>
						<Form.Item className={'flex w-full flex-grow'}>
							{getFieldDecorator(`title`, {
								rules: [{ required: true, message: 'Please describe the problem.' }],
								initialValue: nullSafeGet('title', formData),
							})(
								<Input
									showCount
									maxLength={120}
									style={{ width: '100%', marginBottom: 40 }}
									size="large"
								/>
							)}
							<div className="flex w-full flex-row justify-center">
								<Button size="large" type="ghost" onClick={() => this.goBack()}>
									Back
								</Button>
								<Button
									size="large"
									type="primary"
									className="ml-4"
									onClick={() =>
										this.goNextAfterBriefPause(['title'], WO_STEP_NAMES.PHOTOS, WO_STEP_NAMES.TITLE)
									}
								>
									Next step
								</Button>
							</div>
						</Form.Item>
					</div>
				),
			},
			duplicateCheck: {
				id: 21,
				label: 'Possible Duplicates',
				component: (
					<div key={11} className="supplierNewWorkOrderWizardPage__section px-2">
						<h3 className="supplierNewWorkOrderWizardPage__title">
							We found potential duplicate requests. Do you still want to open a new request?
						</h3>
						<div className="w-full">
							<Table
								pagination={false}
								showHeader={false}
								dataSource={nullSafeGetOrElse('duplicateWorkOrders', this.state, [])}
								columns={duplicateWorkOrderColumns}
							/>
						</div>
						<Form.Item style={{ width: '100%' }}>
							{getFieldDecorator(`isDuplicate`, {
								initialValue: nullSafeGet('isDuplicate', formData),
							})(
								<OWRadio
									fieldName="isDuplicate"
									onChange={this.handleDuplicate}
									onClick={this.handleDuplicate}
									renderRecord={(option) => option.name}
									records={{
										1: { id: 1, name: 'No, cancel this request' },
										2: { id: 2, name: 'Yes, this is a different issue' },
									}}
									sorter={(records) => records.sort((a, b) => (a.id > b.id ? 1 : -1))}
								/>
							)}
						</Form.Item>
						<Button size="large" type="ghost" onClick={() => this.goBack()}>
							Back
						</Button>
					</div>
				),
			},
			photos: {
				id: 6,
				label: 'Photos',
				component: (
					<div key={10} className="supplierNewWorkOrderWizardPage__section px-2">
						<h3 className="supplierNewWorkOrderWizardPage__title">Want to add some photos?</h3>
						<Form.Item
							style={{
								marginBottom: 20,
								width: '100%',
							}}
							className="text-center"
						>
							{getFieldDecorator(`images`, {
								valuePropName: 'fileList',
								getValueFromEvent: normFile,
								initialValue: this.state.allPhotos,
								rules: getImageCountErrorRules(minPhotosCount, imageCountErrorMessage),
							})(
								<OWUpload
									accept="image/*"
									name="file"
									listType="picture-card"
									className="workorder-image-uploader"
									multiple={true}
									headers={uploadHeaders}
									onPreview={this.handlePreview}
									action={`${BACKEND_URI}/api/v1/supplier/file/upload`}
									beforeUpload={imageUploadValidation}
									onChange={this.handleImageUpdate}
								>
									<div style={{ display: 'inline' }}>
										<LegacyIcon type={this.state.uploadLoading ? 'loading' : 'plus'} />
										<div className="ant-upload-text">Upload</div>
									</div>
									<Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
										<img alt="example" style={{ width: '100%' }} src={previewImage} />
									</Modal>
								</OWUpload>
							)}
						</Form.Item>
						<div className="flex flex-row">
							<Button size="large" type="ghost" onClick={() => this.goBack()}>
								Back
							</Button>
							<Button
								size="large"
								type="primary"
								className="ml-4"
								loading={isImageUploading}
								onClick={() =>
									this.goNextAfterBriefPause(
										['images'],
										WO_STEP_NAMES.PRIORITY,
										WO_STEP_NAMES.PHOTOS
									)
								}
							>
								Next step
							</Button>
						</div>
					</div>
				),
			},
			priority: {
				id: 7,
				label: 'Priority',
				component: (
					<div key={11} className="supplierNewWorkOrderWizardPage__section px-2">
						<h3 className="supplierNewWorkOrderWizardPage__title">
							How quickly does this need to get fixed?
						</h3>
						<Form.Item className={'flex w-full flex-grow'}>
							<div
								style={{
									marginBottom: 20,
								}}
							>
								{getFieldDecorator(`woPriorityId`, {
									initialValue: nullSafeGet('woPriority.id', this.state),
								})(
									<OWAsyncRadio
										mode="default"
										fieldName="woPriorityId"
										stateSlice={workOrderPriorities}
										onChange={this.handleWorkOrderPriority}
										onClick={this.handleWorkOrderPriorityClick}
										targetCollectionName={'WORK_ORDER_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE'}
										fetchMultiple={(ids, targetCollectionName) =>
											fetchMultipleWorkOrderPriorities(ids, targetCollectionName)
										}
										fetchData={(
											searchText,
											targetCollectionName,
											pagination = null,
											sorting = null,
											filters = null
										) =>
											fetchWorkOrderPriorities(
												{ name: searchText },
												targetCollectionName,
												pagination,
												sorting,
												filters
											)
										}
										renderRecord={(workOrderPriority) => workOrderPriority.name}
									/>
								)}
							</div>
							<div className="flex w-full flex-row justify-center">
								<Button size="large" type="ghost" onClick={() => this.goBack()}>
									Back
								</Button>
								<Button
									size="large"
									type="primary"
									className="ml-4"
									onClick={() => {
										nullSafeGet('woPriority.id', this.state)
											? this.goNextAfterBriefPause(
													[],
													WO_STEP_NAMES.COMPLETION,
													WO_STEP_NAMES.PRIORITY
											  )
											: message.error('Work order priority cannot be empty!');
									}}
								>
									Next step
								</Button>
							</div>
						</Form.Item>
					</div>
				),
			},
			completion: {
				id: 8,
				label: 'Summary',
				component: (
					<div
						key={12}
						className="supplierNewWorkOrderWizardPage__section px-2"
						style={{ width: '100%' }}
					>
						<h3 className="supplierNewWorkOrderWizardPage__title">Summary</h3>
						<div style={{ marginBottom: 24 }}>
							<TwoLineList
								listItems={workOrderDetails.filter((field) => field.value && field.value !== '')}
								lineOneRender={(record) => record.value}
								lineTwoRender={(record) => record.key}
							/>
							<ProtectedImageThumbnails
								style={{ marginTop: 16 }}
								imageWidth={64}
								imageHeight={64}
								photoStrings={photoStrings}
								role={ROLE_TYPES.SUPPLIER}
							/>
						</div>
						<div className="flex w-full flex-row justify-center">
							<Button size="large" type="ghost" onClick={() => this.goBack()}>
								Back
							</Button>
							<Button
								onClick={this.handleSubmit}
								type="primary"
								size="large"
								className="ml-4"
								loading={creating || this.state.tsAnswerUploading}
							>
								Create Work Order
							</Button>
						</div>
					</div>
				),
			},
		};

		const getSteps = () => {
			const allStepKeys = mergeTroubleshootingRoutes(
				Object.keys(defaultSteps),
				nullSafeGetOrElse('state.troubleshootingRoutes', this, []),
				(route) => Object.keys(defaultSteps).indexOf(route.previousStep),
				(route) => `troubleshooting-${route.id}`
			);

			const troubleshootingSteps = getTroubleshootingSteps();

			const allSteps = allStepKeys.reduce((steps, key) => {
				return {
					...steps,
					[key]: key.startsWith('troubleshooting') ? troubleshootingSteps[key] : defaultSteps[key],
				};
			}, {});

			return allSteps;
		};

		const allSteps = getSteps();
		const allNavSteps = getNavSteps();
		const currentStepKey = location.pathname.split('/')[location.pathname.split('/').length - 1];
		const currentStepIndex = allNavSteps.map((_) => _.title).indexOf(currentStepKey);
		const currentCompletionPercentage = ((currentStepIndex * 1.0) / allNavSteps.length) * 100;

		const assetId = match.params.id;

		return (
			<div className="supplierNewWorkOrderWizardPage">
				<LogOnMountWithStandardEventProperties eventType="visited supplier new work order wizard page" />
				<Drawer
					width={240}
					title="Overview"
					bodyStyle={{ padding: '16px 0' }}
					placement="left"
					closable={true}
					onClose={this.handleNavMenuClose}
					visible={this.state.navMenuVisible}
				>
					<Menu
						onClick={this.handleNavMenuClick}
						style={{ width: 240 }}
						selectedKeys={[this.state.currentRoute]}
						mode="inline"
					>
						{allNavSteps.map((k) => {
							const step = allSteps[k.title];
							return (
								<Menu.Item disabled={!k.enabled} key={k.title}>
									{step.label}
								</Menu.Item>
							);
						})}
					</Menu>
				</Drawer>
				<div
					style={{
						position: 'absolute',
						display: 'flex',
						alignItems: 'center',
						zIndex: 3,
						right: 16,
						top: 8,
					}}
				>
					<Button type="link" onClick={() => this.props.history.push(this.state.backLinkTo)}>
						Cancel Request
					</Button>
				</div>
				<div className="supplierNewWorkOrderWizardPage__inner">
					{Object.keys(allSteps).map((k) => {
						const step = allSteps[k];
						return (
							<Route
								key={step.id}
								path={`/supplier/assets/${assetId}/newWorkOrder/${k}`}
								render={() => step.component}
								exact={true}
							/>
						);
					})}
				</div>
				<div
					style={{
						position: 'absolute',
						display: 'flex',
						alignItems: 'center',
						zIndex: 3,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'white',
						border: '1px solid #f5f5f5',
						width: '100%',
					}}
				>
					<Button
						style={{ display: 'inline-block', margin: '8px 16px 8px 8px' }}
						size="large"
						icon={<MenuOutlined translate="" />}
						onClick={this.handleNavMenuOpen}
					>
						Overview
					</Button>
					<Progress
						style={{ margin: '8px 16px 8px 8px' }}
						strokeLinecap="square"
						strokeColor="#2792b0"
						percent={currentCompletionPercentage}
						showInfo={false}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	redirectForwardUrl: ownProps.redirectForwardUrl,
	formData: state.work_orders.formData,
	currentUser: state.session.currentUser,
	spendCategories: state.spend_categories,
	problemTypes: state.problem_types,
	locations: state.locations,
	creating: state.work_orders.creating,
	workOrderPriorities: state.work_order_priorities,
	assets: state.assets,
	areas: state.areas,
	location: ownProps.location,
});

const mapDispatchToProps = (dispatch) => ({
	saveFormData: (formData) => dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.saveFormData(formData)),
	clearFormData: () => dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.clearFormData()),
	fetchLocation: (id) => dispatch(locationsRestCrudThunksForSupplier.readOne(id)),
	fetchAsset: (id) => dispatch(assetsRestCrudThunksForSupplier.readOne(id)),
	fetchProblemType: (id) => dispatch(problemTypesRestCrudThunksForSupplier.readOne(id)),
	fetchMultipleProblemTypes: (ids, targetCollectionName) =>
		dispatch(problemTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchWorkOrderAttributes: (params) =>
		dispatch(workOrderAttributesRestCrudThunksForSupplier.read(params)),
	fetchWorkOrderPriority: (id) =>
		dispatch(workOrderPrioritiesRestCrudThunksForSupplier.readOne(id)),
	fetchWorkOrderPriorities: (params, targetCollectionName) =>
		dispatch(workOrderPrioritiesRestCrudThunksForSupplier.read(params, targetCollectionName)),
	fetchMultipleWorkOrderPriorities: (ids, targetCollectionName) =>
		dispatch(workOrderPrioritiesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchSupplierFacilitiesInPrivateNetwork: (params) =>
		dispatch(getBuyerCompanyPrivateNetworksWithLocationsForSupplier(params)),
	submitWorkOrderForm: (workOrder) =>
		dispatch(workOrdersRestCrudThunksForSupplier.create(workOrder)),
	fetchTroubleshooting: (params) => dispatch(troubleshootingRestCrudThunksForSupplier.read(params)),
	fetchProblemTypesHierarchical: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			problemTypesHeirarchicalForSupplier(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	createTroubleshootingAnswersBulk: (entities) =>
		dispatch(troubleshootingAnswersRestCrudThunksForSupplier.createBulk(entities)),
	fetchWorkOrders: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			workOrdersRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
});

export default withRouter<any, any>(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(
		Form.create<SupplierAssetNewWorkOrderWizardPageProps>({
			onValuesChange: (props, changedFields) => {
				props.saveFormData(changedFields);
			},
		})(SupplierAssetNewWorkOrderWizardPage)
	)
);

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import EquipmentForm from '../equipment_form/EquipmentForm';
import { equipmentTypesRestCrudThunksForSupplier } from '../../thunks/equipment_types_thunks';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

require('./EditEquipmentPage.less');

class EditEquipmentPage extends React.Component<any, any> {
	componentDidMount() {
		const { getEquipment, match } = this.props;
		const id = match.params.id;
		getEquipment(id);
	}

	render() {
		const { history, equipmentType, fetching } = this.props;

		//noinspection TypeScriptUnresolvedVariable
		return [
			<LogOnMountWithStandardEventProperties eventType="visited supplier edit equipment page" />,
			<EditPage
				title="Edit Equipment Type"
				formComponentProps={{
					formData: equipmentType,
					onSuccess: (rec) => history.push(`/supplier/equipments/detail/${rec.id}`),
				}}
				fetching={fetching}
				FormComponent={EquipmentForm}
			/>,
		];
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		history: ownProps.history,
		equipmentType: state.equipment_types.detail,
		fetching: state.equipment_types.fetching,
		match: ownProps.match,
	};
};

const mapDispatchToProps = (dispatch) => ({
	getEquipment: (id) => dispatch(equipmentTypesRestCrudThunksForSupplier.readOne(id)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(EditEquipmentPage)
);

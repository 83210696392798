import { USER_NOTIFICATIONS_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/user_notifications_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = standardInitialStateGenerator([
	'READ_NOTIFICATIONS',
	'UNREAD_NOTIFICATIONS',
	'notificationsIndex',
	'supplierNotificationsIndex',
	'TASKS_NOTIFICATIONS',
]);

export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState, 'id');

import { ASSET_TYPES_ADDITIONAL_FIELDS_CRUD_ACTION_CREATORS } from '../actions/asset_types_additional_fields_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';

export const assetTypesAdditionalFieldsRestCrudThunksForBuyer = new RestCrudThunks(
	'all_additional_fields',
	'all_additional_field',
	'allAdditionalField',
	'allAdditionalFields',
	ASSET_TYPES_ADDITIONAL_FIELDS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'asset/asset_types/'
);
export const assetTypesAdditionalFieldsRestCrudThunksForSupplier = new RestCrudThunks(
	'all_additional_fields',
	'all_additional_field',
	'allAdditionalField',
	'allAdditionalFields',
	ASSET_TYPES_ADDITIONAL_FIELDS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'asset/asset_types/'
);

import * as React from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import {
	requestForProposalStatusIndicatorFormatter,
	dateUntilOrSince,
} from '../../utils/DataFormatterUtils';
import moment from 'moment';
import DateTimeHover from '../date_time_component/DateTime';

const style = require('./RFPRowDisplay.less');

export const RFPRowDisplay: React.FC<any> = ({ rfp, ...props }) => {
	const now = moment();
	const hoursDiff = now.diff(rfp.dueDate, 'h');
	let dueDateString = '';
	return (
		<div className="rfpRowDisplay" {...props}>
			<div>
				<div className="rfpRowDisplay__rowTitle">
					<Ellipsis tooltip={true} length={64}>
						{rfp.title}
					</Ellipsis>
				</div>
				<div className="rfpRowDisplay__rowSubtitle">
					<div className="rfpRowDisplay__rowInlineGroup">
						{requestForProposalStatusIndicatorFormatter(rfp.status)}
					</div>
					<div className="rfpRowDisplay__rowInlineGroup">
						<i className="icons8-font icons8-maintenance-filled" />
						{nullSafeGet('workOrder.spendCategory.name', rfp)}
					</div>
				</div>
			</div>
			<div className="rfpRowDisplay__rightSide">
				<div className="rfpRowDisplay__extra1">
					<span>
						Due{' '}
						<DateTimeHover
							timestamp={nullSafeGet('dueDate', rfp)}
							showTime={false}
							showYear={false}
						/>
					</span>
				</div>
			</div>
		</div>
	);
};

import { IMPAIRMENT_FILES_CRUD_ACTION_CREATORS } from '../actions/impairment_files_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { retrieveCachedUserDetails } from './session_thunks';

export const impairmentFilesRestCrudThunksForBuyer = new RestCrudThunks(
	'impairment_files',
	'impairment_file',
	'impairmentFile',
	'impairmentFiles',
	IMPAIRMENT_FILES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'asset/'
);
export const impairmentFilesRestCrudThunksForSupplier = new RestCrudThunks(
	'impairment_files',
	'impairment_file',
	'impairmentFile',
	'impairmentFiles',
	IMPAIRMENT_FILES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'asset/'
);

export const uploadImpairmentFile = (roleType) => (data) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/asset/impairment_files `;

	const formData = new FormData();
	formData.append('file', data);
	return fetch(baseUrl + apiUrl, {
		method: 'POST',
		body: formData,
		headers: {
			'X-Auth-Token': retrieveCachedUserDetails(['token']).token,
		},
	});
};

export const uploadImpairmentFileForSupplier = uploadImpairmentFile(ROLE_TYPES.SUPPLIER);

import * as React from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';
import SubnavTabs from '../subnav_tabs/SubnavTabs';
import { nullSafeGet } from '../../utils/DataAccessUtils';

const style = require('./SupplierSettingsPage.less');
const { Content } = Layout;

class SupplierSettingsPage extends React.Component<any, any> {
	handleTabChange = (key) => {
		const { history } = this.props;
		history.push(`/supplier/settings/${key}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	render() {
		const { companyConfig } = this.props;
		const tabs = [
			{
				key: 'notifications',
				name: 'Notifications',
			},
		];
		return (
			<div>
				<Route
					path="/supplier/settings"
					exact={true}
					render={(props) => (
						<Redirect
							to={{
								pathname: '/supplier/settings/notifications',
							}}
						/>
					)}
				/>
				<Content className="supplierSettingsPage" style={{ padding: '0 0.5em' }}>
					{/*<ScrollToTopOnMount/>*/}
					{/*<BackTop/>*/}

					<div
						style={{
							margin: '0 -8px',
							padding: '24px',
							paddingBottom: 0,
							background: nullSafeGet('detail.config.theme.navblock', companyConfig),
							marginBottom: 8,
						}}
					>
						<h4
							style={{
								color: 'rgba(255, 255, 255, 0.9)',
								marginRight: 8,
								marginBottom: 24,
							}}
						>
							Settings
						</h4>
						<SubnavTabs
							tabs={tabs}
							onChange={this.handleTabChange}
							isActive={this.checkTabActive}
						/>
					</div>
					{this.props.childRoutes.map((r, i) => (
						<RouteWithSubRoutes key={i} {...r} />
					))}
				</Content>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	companyConfig: state.company_config,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SupplierSettingsPage));

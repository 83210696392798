import React, { FC, useMemo } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PartsEquipmentWorkOrdersList from '../parts_equipment_work_orders_list/PartsEquipmentWorkOrdersList';

const EquipmentPerStockLocationWorkOrdersPage: FC<any> = ({ match }): React.ReactElement => {
	const equipmentPerStockLocationId = useMemo(() => match.params.id, [match.params.id]);

	return (
		<PartsEquipmentWorkOrdersList
			preAppliedFilters={{ equipmentPerStockLocationId }}
			backText="Back to Equipment"
		/>
	);
};

export default withRouter<any, any>(connect()(EquipmentPerStockLocationWorkOrdersPage));

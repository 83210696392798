import * as React from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { plannedMaintenanceFrequencyTextFormatter } from '../../utils/DataFormatterUtils';
import { EmptorTag } from '../emptor_tag/EmptorTag';

const style = require('./PlannedMaintenanceScheduleGroupRowDisplay.less');

export const PlannedMaintenanceScheduleGroupRowDisplay: React.FC<any> = ({
	plannedMaintenanceScheduleGroup,
}) => {
	if (!plannedMaintenanceScheduleGroup) {
		return null;
	}
	return (
		<div className="plannedMaintenanceSchedulesRowDisplay">
			<div>
				<div className="plannedMaintenanceSchedulesRowDisplay__rowTitle">
					<Ellipsis tooltip={true} length={64}>
						{nullSafeGet('title', plannedMaintenanceScheduleGroup)}
					</Ellipsis>
				</div>
				<div className="plannedMaintenanceSchedulesRowDisplay__rowSubtitle">
					<div className="plannedMaintenanceSchedulesRowDisplay__rowInlineGroup">
						<i className="icons8-font icons8-maintenance-filled"></i>{' '}
						{nullSafeGet(
							'pmTemplateOpt.problemType.hierarchyName',
							plannedMaintenanceScheduleGroup
						)}
					</div>
					<div className="plannedMaintenanceSchedulesRowDisplay__rowInlineGroup">
						<i className="icons8-font icons8-location-marker"></i>{' '}
						{plannedMaintenanceScheduleGroup.basedOn === 'asset'
							? nullSafeGetOrElse('assetIds', plannedMaintenanceScheduleGroup, []).length +
							  ' asset' +
							  (nullSafeGetOrElse('assetIds', plannedMaintenanceScheduleGroup, []).length > 1
									? 's'
									: '')
							: plannedMaintenanceScheduleGroup.basedOn === 'assetType'
							? nullSafeGetOrElse('assetTypeIds', plannedMaintenanceScheduleGroup, []).length +
							  ' asset type' +
							  (nullSafeGetOrElse('assetTypeIds', plannedMaintenanceScheduleGroup, []).length > 1
									? 's'
									: '')
							: nullSafeGetOrElse('locationIds', plannedMaintenanceScheduleGroup, []).length +
							  ' location' +
							  (nullSafeGetOrElse('locationIds', plannedMaintenanceScheduleGroup, []).length > 1
									? 's'
									: '')}
					</div>
					<div className="plannedMaintenanceSchedulesRowDisplay__rowInlineGroup">
						<i className="icons8-font icons8-settings"></i>{' '}
						{plannedMaintenanceScheduleGroup.basedOn === 'asset'
							? 'Asset Based'
							: plannedMaintenanceScheduleGroup.basedOn === 'assetType'
							? 'Asset Type Based'
							: 'Location Based'}
					</div>
				</div>
			</div>
			<div className="plannedMaintenanceSchedulesRowDisplay__rightSide">
				<div className="plannedMaintenanceSchedulesRowDisplay__rowTitle">
					<EmptorTag className="plannedMaintenanceSchedulesRowDisplay__pill">
						{plannedMaintenanceFrequencyTextFormatter(plannedMaintenanceScheduleGroup.frequency)}
					</EmptorTag>
				</div>
				<div className="plannedMaintenanceSchedulesRowDisplay__rowSubtitle"></div>
			</div>
		</div>
	);
};

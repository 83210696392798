import FetchUtils from '../thunks/fetch_utils';

import { getBackendUri } from '../utils/EnvConfigUtils';
import { UploadFile, UploadFileStatus } from 'antd/lib/upload/interface';
import { ROLE_TYPES } from './DataConstants';
import { retrieveCachedUserDetails } from '../thunks/session_thunks';

export const FILE_EXTENSION_TO_MIME_TYPE_MAP = {
	'3gp': 'video/3gpp',
	mp4: 'video/mp4', //.mp4, .m4a, .m4p, .m4b, .m4r
	m4a: 'video/mp4', //.mp4, .m4a, .m4p, .m4b, .m4r
	m4p: 'video/mp4', //.mp4, .m4a, .m4p, .m4b, .m4r
	m4b: 'video/mp4', //.mp4, .m4a, .m4p, .m4b, .m4r
	m4r: 'video/mp4', //.mp4, .m4a, .m4p, .m4b, .m4r
	m1v: 'video/mpeg', //.m1v
	ogg: 'video/ogg', //.ogg
	mov: 'video/quicktime', //.mov, .qt
	qt: 'video/quicktime', //.mov, .qt
	webm: 'video/webm', //.webm
	m4v: 'video/x-m4v', //.m4v
	asf: 'video/ms-asf', //.asf, .wma, .wmv
	wma: 'video/ms-asf', //.asf, .wma, .wmv
	wmv: 'video/x-ms-wmv', //.wmv
	avi: 'video/x-msvideo',
};

export const extractFileExtensionFromFilename = (filename) => {
	const matches = /\.[^.\\/:*?"<>|\r\n]+$/.exec(filename);
	return matches[0];
};

// FIXME: make this work for supplier
export const getFile = (fileId, fileName, roleType = 'buyer') => {
	const BACKEND_URI = getBackendUri();
	const fetchUtils = new FetchUtils();
	return fetchUtils.get(
		`${BACKEND_URI}/api/v1/${roleType}/file/download/${encodeURIComponent(
			fileId
		)}/${encodeURIComponent(fileName)}/${encodeURIComponent(
			retrieveCachedUserDetails(['token']).token
		)}`,
		{}
	);
};

export const getFileMimeTypeFromString = (fileString) => {
	if (!fileString || fileString.length === 0) {
		return '';
	}
	const fileExtension = extractFileExtensionFromFilename(fileString);
	const mimeType = FILE_EXTENSION_TO_MIME_TYPE_MAP[fileExtension];
	return mimeType;
};

export const getFileLinkFromString = (userType) => (fileString) => {
	const [fileId, fileName] = fileString.split('/');
	const BACKEND_URI = getBackendUri();
	return `${BACKEND_URI}/api/v1/${userType}/file/download/${encodeURIComponent(
		fileId
	)}/${encodeURIComponent(fileName)}/${encodeURIComponent(
		retrieveCachedUserDetails(['token']).token
	)}`;
};

export const getProtectedImageUri = (userType) => (fileId, fileName, width, height) => {
	const BACKEND_URI = getBackendUri();
	return `${BACKEND_URI}/api/v1/${userType}/file/download_resize/${encodeURIComponent(
		fileId
	)}/${encodeURIComponent(fileName)}/${width}x${height}/${encodeURIComponent(
		retrieveCachedUserDetails(['token']).token
	)}`;
};

export const getProtectedImageUriFromString = (userType) => (photoString, width, height) => {
	const [fileId, fileName] = photoString.split('/');
	const BACKEND_URI = getBackendUri();
	return `${BACKEND_URI}/api/v1/${userType}/file/download_resize/${encodeURIComponent(
		fileId
	)}/${encodeURIComponent(fileName)}/${width}x${height}/${encodeURIComponent(
		retrieveCachedUserDetails(['token']).token
	)}`;
};

export const getProtectedImageUriForBuyer = getProtectedImageUri(ROLE_TYPES.BUYER);
export const getProtectedImageUriForSupplier = getProtectedImageUri(ROLE_TYPES.SUPPLIER);

export const getFileLink = (fileId, fileName, roleType = 'buyer'): string => {
	const BACKEND_URI = getBackendUri();
	return `${BACKEND_URI}/api/v1/${roleType}/file/download/${encodeURIComponent(
		fileId
	)}/${encodeURIComponent(fileName)}/${encodeURIComponent(
		retrieveCachedUserDetails(['token']).token
	)}`;
};

export function getBase64(img, callback) {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
}

interface FileAttachment {
	fileId: string;
	fileName: string;
}

export function convertArrayOfObjectsToCSV(data, columnDelimiter = ',', lineDelimiter = '\n') {
	let result, ctr, keys;

	if (data == null || !data.length) {
		return null;
	}

	keys = Object.keys(data[0]);

	result = '';
	result += keys.join(columnDelimiter);
	result += lineDelimiter;

	data.forEach(function (item) {
		ctr = 0;
		keys.forEach(function (key) {
			if (ctr > 0) result += columnDelimiter;

			result += item[key];
			ctr++;
		});
		result += lineDelimiter;
	});

	return result;
}

export const getDefaultFileListFromString = (fileAttachments, userType = 'buyer'): UploadFile[] =>
	fileAttachments.map((fa, idx) => {
		const [fileId, fileName] = fa.split('/');
		const fileStatus: UploadFileStatus = 'done';
		const fileUrl = getFileLink(fileId, fileName, userType);
		return {
			uid: idx * -1,
			size: 42,
			name: fileName,
			status: fileStatus,
			response: {
				data: {
					fileId: fileId,
					fileName: fileName,
				},
			},
			url: fileUrl,
			type: 'application/pdf',
		};
	});

export const getDefaultFileList = (fileAttachments, userType = 'buyer'): UploadFile[] =>
	fileAttachments.map((fa, idx) => {
		const fileStatus: UploadFileStatus = 'done';
		const fileUrl = getFileLink(fa.fileId, fa.fileName, userType);
		return {
			uid: idx * -1,
			size: 42,
			name: fa.fileName,
			status: fileStatus,
			response: {
				data: {
					fileId: fa.fileId,
					fileName: fa.fileName,
				},
			},
			url: fileUrl,
			type: 'application/pdf',
		};
	});

import React, { FC, useMemo } from 'react';
import HyperLink from '../common/HyperLink';

require('./PartQuantityRowDisplay.less');

export const QuantityRowDisplay: FC<any> = ({ min, max, quantity, onClick, ...otherProps }) => {
	// if (!quantity || quantity === 0) {
	// 	return <span {...otherProps}>0 Out of stock</span>;
	// }
	let color = undefined;
	let extraText = '';
	if (!quantity || quantity <= 0) {
		color = 'red';
		extraText = 'Out of stock';
	} else if (quantity < min) {
		color = 'red';
		extraText = 'Almost out';
	} else if (quantity < Math.round(max - (max - min) * 0.75)) {
		color = 'orange';
		extraText = 'Running low';
	}

	const displayQuantity = useMemo(() => (quantity > 0 ? quantity : 0), [quantity]);

	return (
		<div className="flex flex-row items-center">
			<span {...otherProps}>
				{onClick ? <HyperLink text={displayQuantity} onClick={onClick} /> : displayQuantity}
			</span>
			{extraText ? <span style={{ marginLeft: 8, color }}>{extraText}</span> : null}
		</div>
	);
};

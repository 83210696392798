import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { WALKTHROUGHS_CRUD_ACTION_CREATORS } from '../actions/walkthroughs_actions';
import { getBackendUri } from '../utils/EnvConfigUtils';
import FetchUtils, { parseJSON } from './fetch_utils';
import { logoutSuccess } from '../actions/session_actions';
import { message } from 'antd';
import WalkthroughPipeLineFilters from '../components/site_surveys_detail_details_page/walkthroughs_pipeline_filters';

const fetchUtils = new FetchUtils();

export const walkthroughsRestCrudThunksForBuyer = new RestCrudThunks(
	'walkthroughs',
	'walkthrough',
	'walkthrough',
	'walkthroughs',
	WALKTHROUGHS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'site_survey/'
);

export const walkthroughsRestCrudThunksForSupplier = new RestCrudThunks(
	'walkthroughs',
	'walkthrough',
	'walkthrough',
	'walkthroughs',
	WALKTHROUGHS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'site_survey/'
);

const downloadWalkthroughCSV =
	(roleType) =>
	(id, params = {}, filters = null) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/site_survey/walkthroughs/csv_report_generator/${id}`;

		// if sorting present, fix order naming and add to query params
		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.message);
							message.error(d.data);
						} else {
							resolve(d.data);
							const hide = message.loading(d.data, 0);
							setTimeout(hide, 7000);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};

export const downloadWalkthroughCSVForBuyer = downloadWalkthroughCSV(ROLE_TYPES.BUYER);
export const downloadWalkthroughCSVForSupplier = downloadWalkthroughCSV(ROLE_TYPES.SUPPLIER);

const downloadWalkthroughScoreCSV = (roleType) => (id) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/site_survey/walkthroughs/csv_score_report_generator/${id}`;

	// if sorting present, fix order naming and add to query params
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(baseUrl + apiUrl, {})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
						message.error(d.data);
					} else {
						resolve(d.data);
						const hide = message.loading(d.data, 0);
						setTimeout(hide, 7000);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const downloadWalkthroughScoreCSVForBuyer = downloadWalkthroughScoreCSV(ROLE_TYPES.BUYER);
export const downloadWalkthroughScoreCSVForSupplier = downloadWalkthroughScoreCSV(
	ROLE_TYPES.SUPPLIER
);

const downloadWalkthroughPDF = (roleType) => (id) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/site_survey/walkthroughs/${id}/generate_pdf`;
	return fetchUtils
		.fileRequest(baseUrl + apiUrl, {})
		.then((response) => {
			const dataType = response.type;
			const binaryData = [];
			binaryData.push(response);
			const downloadLink = document.createElement('a');
			downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
			downloadLink.setAttribute('download', `walkthrough-${id}`);
			document.body.appendChild(downloadLink);
			downloadLink.click();
			downloadLink.remove();
		})
		.catch((err) => console.log(err));
};

export const downloadWalkthroughPDFForBuyer = downloadWalkthroughPDF(ROLE_TYPES.BUYER);
export const downloadWalkthroughPDFForSupplier = downloadWalkthroughPDF(ROLE_TYPES.SUPPLIER);

const markWalkthroughComplete = (roleType) => (id) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/site_survey/walkthroughs/completed/${id}`;
	return (dispatch) => {
		dispatch(WALKTHROUGHS_CRUD_ACTION_CREATORS.updateStart({}));
		return new Promise((resolve, reject) => {
			fetchUtils
				.patch(
					baseUrl + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					})
				)
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(WALKTHROUGHS_CRUD_ACTION_CREATORS.updateError(d.message, {}));
						reject(d.message);
					} else {
						dispatch(WALKTHROUGHS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(WALKTHROUGHS_CRUD_ACTION_CREATORS.updateError(d.message, {}));
					reject(d.message);
				});
		});
	};
};

export const markWalkthroughCompleteForBuyer = markWalkthroughComplete(ROLE_TYPES.BUYER);
export const markWalkthroughCompleteForSupplier = markWalkthroughComplete(ROLE_TYPES.SUPPLIER);

const refreshWalkthroughCounts = (userType) => (filters) => (dispatch) => {
	const apiUrl = `/api/v1/${userType}/site_survey/walkthroughs/count_by_status`;

	const data = {
		...(filters || {}),
		statuses: WalkthroughPipeLineFilters.map((_) => _.name).join(','),
		status: undefined,
	};
	fetchUtils
		.get(
			getBackendUri() + apiUrl,
			Object.assign(
				{
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
				},
				data
			)
		)
		.then(parseJSON)
		.then(({ json }) => {
			const d = json;
			if (d.status === 'error') {
				message.error(d.message);
			} else {
				if (d.data && d.data.length > 0) {
					d.data.map((category) =>
						dispatch(
							WALKTHROUGHS_CRUD_ACTION_CREATORS.countBySuccess(
								category.count,
								category.categoryName
							)
						)
					);
				}
			}
		})
		.catch((d) => {
			message.error(d.message);
		});
};

export const refreshWalkthroughCountsForBuyer = refreshWalkthroughCounts(ROLE_TYPES.BUYER);
export const refreshWalkthroughCountsForSupplier = refreshWalkthroughCounts(ROLE_TYPES.SUPPLIER);

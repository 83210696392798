import React, { FC, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import { EmptyState } from '../empty_state/EmptyState';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { STOCK_LOCATIONS_CRUD_ACTION_CREATORS } from '../../actions/stock_locations_actions';
import { Button } from 'antd';
import { nullSafeGetOrElse, stopDefaultEvents } from '../../utils/DataAccessUtils';
import TaxRateModal from '../stock_locations_detail_details_page/TaxRateModal';
import {
	defaultFiscalEnd,
	getCurrentTaxRateFromStockLocation,
	getDefaultRate,
} from '../stock_locations_detail_details_page/burden_rate_utils';
import { DATE_ONLY_FORMAT } from '../../utils/DataFormatterUtils';

const AccountingTaxRatePage: FC<any> = ({
	stockLocations,
	updateFilters,
	clearAndUpdateFilters,
	companyConfig,
}): React.ReactElement => {
	const [currentEditingRecord, setCurrentEditingRecord] = useState(null);
	const [editRateModalVisible, setEditRateModalVisible] = useState(false);

	const fiscal = useMemo(
		() => nullSafeGetOrElse('detail.inventoryConfig.fiscalDate', companyConfig, defaultFiscalEnd),
		[companyConfig]
	);

	const editStockLocation = useCallback(
		(record) => (e) => {
			stopDefaultEvents(e);
			setCurrentEditingRecord(record);
			setEditRateModalVisible(true);
		},
		[]
	);

	const hideEditRateModal = useCallback(() => setEditRateModalVisible(false), []);

	const columns = useMemo(
		() => [
			{
				title: 'Stock Location',
				dataIndex: 'name',
			},
			{
				title: 'Tax Rate',
				render: (_, record) => {
					const { rate } = getCurrentTaxRateFromStockLocation(record, fiscal);
					return <div>{`${rate}%`}</div>;
				},
			},
			{
				title: 'Period',
				render: () => {
					const { startDate, endDate } = getDefaultRate(fiscal);
					return `${startDate.format(DATE_ONLY_FORMAT)} - ${endDate.format(DATE_ONLY_FORMAT)}`;
				},
			},
			{
				render: (_, record) => (
					<div>
						<Button type="ghost" onClick={editStockLocation(record)}>
							Edit
						</Button>
					</div>
				),
			},
		],
		[editStockLocation, fiscal]
	);

	return (
		<>
			<PaginatedReduxTableWithHeader
				targetCollectionName="stockLocationsIndex"
				updateFilters={updateFilters}
				stateSlice={stockLocations}
				clearAndUpdateFilters={clearAndUpdateFilters}
				filterConfig={[]}
				entityCollectionName="stock_locations"
				tableColumns={columns}
				fetchData={stockLocationsRestCrudThunksForSupplier.read}
				rightActions={null}
				showHeader
				emptyState={
					<EmptyState
						graphic={
							<img
								alt="No Data"
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
							/>
						}
						headline={'No Stock locations found'}
						body={null}
					/>
				}
			/>
			{editRateModalVisible && (
				<TaxRateModal
					taxRate={getCurrentTaxRateFromStockLocation(currentEditingRecord, fiscal)}
					stockLocation={currentEditingRecord}
					onCancel={hideEditRateModal}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	stockLocations: state.stock_locations,
	companyConfig: state.company_config,
});

const mapDispatchToProps = (dispatch) => ({
	updateFilters: (filters, targetCollection) =>
		dispatch(STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			STOCK_LOCATIONS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)
		),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(AccountingTaxRatePage)
);

import React, { FC, useCallback, useMemo, ReactElement, useState } from 'react';
import { connect } from 'react-redux';
import {
	getEquipmentId,
	getPurchaseOrderId,
	nullSafeGet,
	nullSafeGetOrElse,
	renderCurrency,
} from '../../utils/DataAccessUtils';
import { Button, Card, Col, Layout, Row } from 'antd';
import { HorizontalKeyValueDisplay } from '../horizontal_key_value_display/HorizontalKeyValueDisplay';

import { withRouter } from 'react-router';
import { EditOutlined, ExportOutlined } from '@ant-design/icons';
import EditSerialNumberModal from './EditSerialNumberModal';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { EmptyState } from '../empty_state/EmptyState';
import { getContactName } from '../../utils/DataFormatterUtils';
import AssetCardDisplay from '../asset_card_display/AssetCardDisplay';
import HyperLink, { ENTITY_TYPE } from '../common/HyperLink';
import {
	downloadEpslAuditsCSVForSupplier,
	epslAuditsCrudThunksForSupplier,
} from '../../thunks/epsl_audits_thunks';
import { getAuditFieldNameByKey } from '../../utils/AuditUtils';
import DateTimeHover from '../date_time_component/DateTime';
import PPSLOrAssetHistoryComponent from '../asset_history_details_page/PPSLOrAssetHistoryComponent';

const { Content } = Layout;

const EquipmentPerStockLocationDetailsDetailPage: FC<any> = ({
	epsl,
	fetching,
	currentUser,
	match,
	exportAuditsCsv,
}): ReactElement => {
	const [editModalVisible, setEditModalVisible] = useState(false);

	const showEditModal = useCallback(() => setEditModalVisible(true), []);
	const hideEditModal = useCallback(() => setEditModalVisible(false), []);

	const equipmentPerStockLocationId = useMemo(() => match.params.id, [match.params.id]);

	const [exportingAudits, setExportingAudits] = useState(false);

	const epslDetail = useMemo(
		() => ({
			'Equipment ID': getEquipmentId(epsl),
			'Equipment Type #': nullSafeGetOrElse('equipmentType.equipmentTypeNumber', epsl, '--'),
			'Alias Equipment Type #': nullSafeGetOrElse(
				'equipmentType.aliasEquipmentTypeNumber',
				epsl,
				'--'
			),
			'Equipment Type': (
				<HyperLink
					text={nullSafeGetOrElse('equipmentType.modelName', epsl, '--')}
					entityType={ENTITY_TYPE.EQUIPMENT}
					entityId={nullSafeGetOrElse('equipmentType.id', epsl, '--')}
					backLinkText="Back to Equipment type detail"
				/>
			),
			'Stock Location': (
				<HyperLink
					text={nullSafeGetOrElse('stockLocation.name', epsl, '--')}
					entityType={ENTITY_TYPE.STOCK_LOCATION}
					entityId={nullSafeGetOrElse('stockLocation.id', epsl, '--')}
					backLinkText="Back to PPSL"
				/>
			),
			'Serial Number': (
				<div>
					{nullSafeGetOrElse('serialNumber', epsl, '--')}
					<Button type="link" icon={<EditOutlined translate="" onClick={showEditModal} />} />
				</div>
			),
			'Asset Number': nullSafeGetOrElse('assetNumber', epsl, '--'),
			...(nullSafeGet('cost', epsl) && {
				Cost: renderCurrency(currentUser)(epsl.cost),
			}),
			...(nullSafeGet('purchaseOrder', epsl) && {
				PO: (
					<HyperLink
						text={`PO ${getPurchaseOrderId(epsl.purchaseOrder)}`}
						entityType={ENTITY_TYPE.PURCHASE_ORDER}
						entityId={nullSafeGet('purchaseOrder.id', epsl)}
						backLinkText={'Back to Equipment'}
					/>
				),
			}),
		}),
		[currentUser, epsl, showEditModal]
	);
	const auditColumns = useMemo(
		() => [
			{
				title: 'Audit Type',
				dataIndex: 'auditType',
				render: (_) => getAuditFieldNameByKey(_),
			},
			{
				title: 'Updated By',
				dataIndex: 'createdByContact',
				render: (_) => getContactName(_),
			},
			{
				title: 'Updated At',
				dataIndex: 'createdAt',
				render: (_) => (_ ? <DateTimeHover timestamp={_} /> : '--'),
			},
			{
				title: 'Price Change',
				dataIndex: 'priceChange',
				render: (_) => renderCurrency(currentUser)(_),
			},
			{
				title: 'Original Price',
				dataIndex: 'originalPrice',
				render: (_) => renderCurrency(currentUser)(_),
			},
			{
				title: 'Burden Rate Amount',
				dataIndex: 'burdenRateAmount',
				render: (_) => renderCurrency(currentUser)(_),
			},
			{
				title: 'Tax Rate Amount',
				dataIndex: 'taxRateAmount',
				render: (_) => renderCurrency(currentUser)(_),
			},
			{
				title: 'Capitalized Cost',
				dataIndex: 'capitalisedCost',
				render: (_) => renderCurrency(currentUser)(_),
			},
			{
				title: 'Notes',
				dataIndex: 'notes',
			},
		],
		[currentUser]
	);

	const onExportAudits = useCallback(() => {
		setExportingAudits(true);
		exportAuditsCsv({ equipmentPerStockLocationId }).finally(() => setExportingAudits(false));
	}, [equipmentPerStockLocationId, exportAuditsCsv]);

	return (
		<Content
			style={{
				padding: '0 0.5em',
			}}
		>
			<Row style={{ margin: '16px 4px' }} gutter={12}>
				<Col span={16}>
					<Card loading={fetching} title="Details">
						<HorizontalKeyValueDisplay rowStyle={{ minWidth: '25%' }} keyValueStore={epslDetail} />
					</Card>
				</Col>
				<Col span={8}>
					<Card loading={fetching} title={epsl.asset ? 'Associated Asset' : ''}>
						{epsl.asset ? (
							<AssetCardDisplay hideDeletedDetails asset={epsl.asset} />
						) : (
							'No associated Asset Found'
						)}
					</Card>
				</Col>
			</Row>
			<Row style={{ margin: '16px 4px' }} gutter={12}>
				<Col span={24}>
					<div className="rowSpacing">
						<Card
							bodyStyle={{ padding: 0 }}
							title="Audits"
							extra={
								<Button
									icon={<ExportOutlined translate="" />}
									type="ghost"
									onClick={onExportAudits}
									loading={exportingAudits}
								>
									Export
								</Button>
							}
						>
							<PaginatedReduxTable
								mode={'list'}
								tableLayoutFixed={true}
								showHeader={true}
								emptyState={
									<EmptyState
										graphic={
											<img
												style={{ marginBottom: 8 }}
												src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
												alt="No audits found!"
											/>
										}
										headline={"It's a little lonely in here."}
										body={null}
									/>
								}
								collectionName="epsl_audits"
								targetCollectionName={'epslAuditsIndex'}
								columns={auditColumns}
								keyAccessor={(el) => el.id}
								initialFilters={{ equipmentPerStockLocationId }}
								initialPagination={{ current: 1, pageSize: 10 }}
								fetchData={epslAuditsCrudThunksForSupplier.read}
							/>
						</Card>
					</div>
				</Col>
			</Row>
			<PPSLOrAssetHistoryComponent filter={{ equipmentPerStockLocationId }} />
			{editModalVisible && <EditSerialNumberModal onCancel={hideEditModal} />}
		</Content>
	);
};

const mapStateToProps = (state) => ({
	fetching: state.equipment_per_stock_locations.fetching,
	epsl: state.equipment_per_stock_locations.detail,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	exportAuditsCsv: (params) => dispatch(downloadEpslAuditsCSVForSupplier(params)),
});

const ComponentWithoutUserType = withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(EquipmentPerStockLocationDetailsDetailPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS } from '../../actions/purchase_request_line_items_actions';
import {
	fetchStockLocationsWithPurchaseRequestLineItemCountForSupplier,
	stockLocationsRestCrudThunksForSupplier,
} from '../../thunks/stock_locations_thunks';
import {
	fetchVendorsWithPurchaseRequestLineItemCountForSupplier,
	vendorsRestCrudThunksForSupplier,
} from '../../thunks/vendors_thunks';
import {
	fetchLocationsWithPurchaseRequestLineItemCountForSupplier,
	locationsRestCrudThunksForSupplier,
} from '../../thunks/locations_thunks';
import { downloadPRLineItemsCSVForSupplier } from '../../thunks/purchase_request_line_items_thunks';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import OWRadioGroup from '../ow_radio_group/OWRadioGroup';
import { Form, Select } from 'antd';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';

const VENDORS_TC = 'prLineItemsAdvancedFilters';
const LOCATIONS_TC = 'locationsIndex';
const SL_TC = 'stockLocationDropdown';
const STATUS_FILTER = { status: 'approved' };

const ReadyToOrderFilters: FC<any> = ({
	locations,
	fetchLocations,
	fetchMultipleLocations,
	locationsFetching,
	stockLocations,
	fetchStockLocations,
	fetchMultipleStockLocations,
	stockLocationsFetching,
	vendors,
	fetchMultipleVendors,
	vendorsFetching,
	fetchVendors,
	updateFilters,
	targetCollectionName,
	form,
	filters,
}): React.ReactElement => {
	const { setFieldsValue } = form;

	const onSubmit = () => {};

	const locationType = Form.useWatch('locationType', form);
	const locationId = Form.useWatch('locationId', form);
	const stockLocationId = Form.useWatch('stockLocationId', form);
	const partEquipmentVendorId = Form.useWatch('partEquipmentVendorId', form);

	const isLocationSelect = useMemo(() => locationType === 'location', [locationType]);

	useEffect(() => {
		updateFilters(
			{
				partEquipmentVendorId,
				...(isLocationSelect
					? { locationId, stockLocationId: undefined }
					: { stockLocationId, locationId: undefined }),
			},
			targetCollectionName
		);
	}, [
		isLocationSelect,
		locationId,
		stockLocationId,
		targetCollectionName,
		updateFilters,
		partEquipmentVendorId,
	]);

	useEffect(() => {
		setFieldsValue({
			...(isLocationSelect && { stockLocationId: undefined }),
			...(!isLocationSelect && { locationId: undefined }),
		});
	}, [isLocationSelect, setFieldsValue]);

	useEffect(() => {
		isLocationSelect &&
			fetchLocations(
				{
					partEquipmentVendorId,
				},
				LOCATIONS_TC
			);
	}, [fetchLocations, isLocationSelect, partEquipmentVendorId]);

	useEffect(() => {
		!isLocationSelect &&
			fetchStockLocations(
				{
					partEquipmentVendorId,
				},
				SL_TC
			);
	}, [fetchStockLocations, isLocationSelect, partEquipmentVendorId]);

	useEffect(() => {
		(locationId || stockLocationId || (!locationId && !stockLocationId)) &&
			fetchVendors(
				{
					locationId,
					stockLocationId,
				},
				VENDORS_TC
			);
	}, [fetchVendors, locationId, stockLocationId, partEquipmentVendorId]);

	return (
		<Form
			form={form}
			id="add-parts-form"
			layout="horizontal"
			requiredMark={false}
			initialValues={{ ...filters, locationType: 'stockLocation' }}
			onFinish={onSubmit}
		>
			<div className="felx-row flex w-full items-baseline">
				<div>Showing requests from </div>
				<Form.Item name="locationType">
					<OWRadioGroup
						className="ml-2"
						valueAccessor={(item) => item.value}
						renderRecord={(item) => item.label}
						items={[
							{
								label: 'Stock Location',
								value: 'stockLocation',
							},
							{
								label: 'Location',
								value: 'location',
							},
						]}
					/>
				</Form.Item>
				{isLocationSelect ? (
					<Form.Item name="locationId" label=" ">
						<OWAsyncSelect
							loading={locationsFetching}
							placeholder="Select Location"
							allowClear
							style={{ width: 300, marginLeft: 16 }}
							key={'location'}
							stateSlice={locations}
							valueAccessor={(el) => el.id}
							targetCollectionName={LOCATIONS_TC}
							fetchData={(search, targetCollectionName) => {
								fetchLocations(
									{
										name: search || undefined,
										partEquipmentVendorId,
									},
									targetCollectionName
								);
							}}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleLocations(ids, targetCollectionName);
							}}
							renderRecord={(l) => (
								<Select.Option key={l.id} value={l.id}>
									<div className="flex flex-row justify-between">
										<Ellipsis tooltip={true} length={30}>
											{l.name}
										</Ellipsis>
										{` (${nullSafeGetOrElse('count', l, 0)})`}
									</div>
								</Select.Option>
							)}
						/>
					</Form.Item>
				) : (
					<Form.Item name="stockLocationId" label=" ">
						<OWAsyncSelect
							loading={stockLocationsFetching}
							placeholder="Select Stock Location"
							style={{ width: 300, marginLeft: 16 }}
							key={'stockLocation'}
							allowClear
							stateSlice={stockLocations}
							valueAccessor={(el) => el.id}
							targetCollectionName={SL_TC}
							fetchData={(search, targetCollectionName) => {
								fetchStockLocations(
									{
										name: search || undefined,
										partEquipmentVendorId,
									},
									targetCollectionName
								);
							}}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleStockLocations(ids, targetCollectionName);
							}}
							renderRecord={(sl) => (
								<Select.Option key={sl.id} value={sl.id}>
									<div className="flex flex-row justify-between">
										<Ellipsis tooltip={true} length={30}>
											{sl.name}
										</Ellipsis>
										{` (${nullSafeGetOrElse('count', sl, 0)})`}
									</div>
								</Select.Option>
							)}
						/>
					</Form.Item>
				)}
				<div className="ml-2">for vendor</div>
				<Form.Item name="partEquipmentVendorId" label=" ">
					<OWAsyncSelect
						loading={vendorsFetching}
						placeholder="Select Vendor"
						allowClear
						style={{ width: 300, marginLeft: 16 }}
						stateSlice={vendors}
						targetCollectionName={VENDORS_TC}
						fetchMultiple={(ids, targetCollectionName) => {
							fetchMultipleVendors(ids, targetCollectionName);
						}}
						fetchData={(searchText, targetCollectionName) => {
							fetchVendors(
								{
									name: searchText || undefined,
									locationId: locationId || undefined,
									stockLocationId: stockLocationId || undefined,
								},
								targetCollectionName
							);
						}}
						renderRecord={(vendor) => (
							<Select.Option key={vendor.id} value={vendor.id}>
								<div className="flex flex-row justify-between">
									<Ellipsis tooltip={true} length={30}>
										{vendor.name}
									</Ellipsis>
									{` (${nullSafeGetOrElse('count', vendor, 0)})`}
								</div>
							</Select.Option>
						)}
					/>
				</Form.Item>
			</div>
		</Form>
	);
};

const mapStateToProps = (state) => ({
	stockLocations: state.stock_locations,
	stockLocationsFetching: state.stock_locations.fetching,
	vendors: state.vendors,
	vendorsFetching: state.vendors.fetching,
	locations: state.locations,
	locationsFetching: state.locations.fetching,
});

const mapDispatchToProps = (dispatch) => ({
	updateFilters: (filters, targetCollection) =>
		dispatch(
			PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)
		),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(
				filters,
				targetCollectionName
			)
		),
	fetchMultipleStockLocations: (ids, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchStockLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			fetchStockLocationsWithPurchaseRequestLineItemCountForSupplier(
				{ ...STATUS_FILTER, ...params },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchVendors: (params, targetCollectionName) =>
		dispatch(
			fetchVendorsWithPurchaseRequestLineItemCountForSupplier(
				{ ...params, ...STATUS_FILTER, no_pagination: true },
				targetCollectionName
			)
		),
	fetchMultipleVendors: (ids, targetCollectionName) =>
		dispatch(vendorsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			fetchLocationsWithPurchaseRequestLineItemCountForSupplier(
				{ ...STATUS_FILTER, ...params },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	exportPRLineItemsCSV: (params, filters) =>
		dispatch(downloadPRLineItemsCSVForSupplier(params, filters)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(ReadyToOrderFilters)
);

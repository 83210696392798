import React, { FC, useCallback, useMemo } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { Button } from 'antd';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { EmptyState } from '../empty_state/EmptyState';
import {
	FILTER_CONFIG_NAMES,
	FILTER_FIELD_TYPE,
	FILTER_VALUE_TYPE,
} from '../../utils/DataConstants';
import { DownloadOutlined } from '@ant-design/icons';
import { updateAssetImpairmentsForSupplier } from '../../thunks/assets_thunks';
import {
	impairmentFilesRestCrudThunksForSupplier,
	uploadImpairmentFileForSupplier,
} from '../../thunks/impairment_files_thunks';
import { IMPAIRMENT_FILES_CRUD_ACTION_CREATORS } from '../../actions/impairment_files_actions';
import { DATE_FORMAT, dateFormatter, getContactName } from '../../utils/DataFormatterUtils';
import { usersRestCrudThunksForSupplier } from '../../thunks/users_thunks';
import { ExportToCsv } from 'export-to-csv';
import { CSV_EXPORT_DEFAULTS } from '../../utils/DataConstants';
import { openInNewTab } from '../../utils/HistoryUtils';
import FlatfileUploader from './FlatfileUploader';
import DateTimeHover from '../date_time_component/DateTime';

const IF_TC_NAME = 'impairmentFilesIndex';
const CSV_TITLE = 'Sample_Impairments';

const ImpairmentAdjustmentIndexPage: FC<any> = ({
	impairmentFiles,
	updateFilters,
	clearAndUpdateFilters,
	updateImpairments,
	users,
	fetchUsers,
	fetchMultipleUsers,
	fetchImpairmentFiles,
}) => {
	const onDownload = useCallback((link) => () => openInNewTab(link), []);

	const columns = useMemo(
		() => [
			{
				title: 'File Name',
				dataIndex: 'fileName',
			},
			{
				title: 'Upload Date',
				dataIndex: 'createdAt',
				render: (date) => (date ? <DateTimeHover timestamp={date} /> : '--'),
			},
			{
				title: 'Uploaded By',
				dataIndex: 'updatedByContact',
				render: (_) => getContactName(_),
			},
			{
				dataIndex: 'fileUrl',
				render: (link) => (
					<Button type="primary" onClick={onDownload(link)}>
						Download
					</Button>
				),
			},
		],
		[onDownload]
	);

	const filterConfig = useMemo(
		() => [
			{
				label: 'Uploaded by',
				fieldName: 'createdByEmails',
				mode: 'multiple',
				stateSlice: users,
				valueType: FILTER_VALUE_TYPE.STRING,
				valueAccessor: (user) => nullSafeGet('contact.email', user),
				targetCollectionName: 'impairmentAdjustmentFilters',
				fetch: fetchUsers,
				fetchMultiple: fetchMultipleUsers,
				renderItem: (user) => getContactName(user.contact),
			},
			{
				label: 'Uploaded Between',
				labelPrefix: 'Uploaded',
				fieldName: FILTER_CONFIG_NAMES.UPLOADED_AT,
				type: FILTER_FIELD_TYPE.DATE_RANGE,
			},
		],
		[fetchMultipleUsers, fetchUsers, users]
	);

	const getStatSliceProp = useCallback(
		(propName) => nullSafeGetOrElse(`${IF_TC_NAME}.${propName}`, impairmentFiles, {}),
		[impairmentFiles]
	);

	const refreshImpairmentFiles = useCallback(() => {
		fetchImpairmentFiles(
			{},
			IF_TC_NAME,
			getStatSliceProp('pagination'),
			getStatSliceProp('sorting'),
			getStatSliceProp('filters')
		);
	}, [fetchImpairmentFiles, getStatSliceProp]);

	const onDataUpload = useCallback(
		(validData, fileInfo, originalFile) => {
			return new Promise((resolve, reject) => {
				Promise.all([
					uploadImpairmentFileForSupplier(originalFile),
					updateImpairments(
						validData.map((data) => ({
							...data,
							assetId: parseInt(data.assetId),
							impairmentAdjustment: `${data.impairmentAdjustment}`,
						}))
					),
				])
					.then((res) => {
						refreshImpairmentFiles();
						resolve('Updated successfully!');
					})
					.catch(() => reject('Unable to upload! Please contact support!'));
			});
		},
		[refreshImpairmentFiles, updateImpairments]
	);

	const onDownloadSample = useCallback(() => {
		const csvExporter = new ExportToCsv({
			...CSV_EXPORT_DEFAULTS,
			filename: CSV_TITLE,
			title: CSV_TITLE,
			headers: ['Asset ID', 'Impairment Adjustment', 'Date (MM/DD/YYYY)'],
		});
		csvExporter.generateCsv([
			{
				assetId: 1,
				impairmentAdjustment: 10,
				date: '01/31/2023',
			},
			{
				assetId: 2,
				impairmentAdjustment: 20,
				date: '12/31/2022',
			},
		]);
	}, []);

	const flatFIleButton = useMemo(
		() => (
			<FlatfileUploader
				onDataUpload={onDataUpload}
				loadingMessage="Updlading Impairment Adjustments"
			/>
		),
		[onDataUpload]
	);

	return (
		<>
			<PaginatedReduxTableWithHeader
				targetCollectionName={IF_TC_NAME}
				updateFilters={updateFilters}
				stateSlice={impairmentFiles}
				clearAndUpdateFilters={clearAndUpdateFilters}
				filterConfig={filterConfig}
				showHeader
				entityCollectionName="impairment_files"
				tableColumns={columns}
				fetchData={impairmentFilesRestCrudThunksForSupplier.read}
				rightActions={
					<div className="flex flex-row items-center">
						<Button
							icon={<DownloadOutlined />}
							size="large"
							type="ghost"
							onClick={onDownloadSample}
							className="mr-2"
						>
							Download Sample
						</Button>
						{flatFIleButton}
					</div>
				}
				emptyState={
					<EmptyState
						graphic={
							<img
								alt="No Data"
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
							/>
						}
						headline={'No files found'}
						body={flatFIleButton}
					/>
				}
			/>
		</>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
	impairmentFiles: state.impairment_files,
	users: state.users,
});

const mapDispatchToProps = (dispatch) => ({
	fetchImpairmentFiles: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			impairmentFilesRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	updateFilters: (filters, targetCollection) =>
		dispatch(IMPAIRMENT_FILES_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			IMPAIRMENT_FILES_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)
		),
	updateImpairments: (entities) => dispatch(updateAssetImpairmentsForSupplier(entities)),
	fetchMultipleUsers: (ids, targetCollectionName) =>
		dispatch(usersRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchUsers: (params, targetCollectionName, pagination, sorting, filters, addToTargetCollection) =>
		dispatch(
			usersRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
});

const IAIndexPage = withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(ImpairmentAdjustmentIndexPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(IAIndexPage);

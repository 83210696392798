import * as React from 'react';
import { Layout, Row, Col, Card, Button, message, Modal } from 'antd';
import { EditFilled, UploadOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { siteSurveysRestCrudThunksForBuyer } from '../../thunks/site_surveys_thunks';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import LocationCardDisplay from '../location_card_display/LocationCardDisplay';
import TaskListRO from '../task_list/TaskListRO';
import ScheduleWalkthroughModalForm from '../schedule_walkthrough_modal_form/ScheduleWalkthroughModalForm';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { FlatfileButton } from '@flatfile/react';
import UploadTaskListPopup from '../site_survey_templates_detail_details_page/UploadTaskListPopup';
import { ExportToCsv } from 'export-to-csv';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import SiteSurveyWalkthroughSection from './SiteSurveyWalkthroughSection';

const { Content } = Layout;

const csvOptions = {
	fieldSeparator: ',',
	quoteStrings: '"',
	decimalSeparator: '.',
	showLabels: true,
	showTitle: false,
	title: 'Task List',
	useTextFile: false,
	useBom: true,
	headers: ['Title', 'Description', 'Group', 'Question Type', 'Mutliple Choices'],
};

class SiteSurveysDetailPage extends React.Component<any, any> {
	formRefs: any = {
		scheduleWalkthroughForm: null,
	};

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			scheduleWalkthroughFormVisible: false,
			uploadTaskModalVisible: false,
		};
	}

	componentDidMount() {
		const { getSiteSurvey, match } = this.props;
		const siteSurveyId = match.params.id;
		getSiteSurvey(siteSurveyId).then(() => {
			this.setState({ loading: false });
		});
	}

	handleSchedule = (e) => {
		this.setState({
			scheduleWalkthroughFormVisible: true,
		});
		e.stopPropagation();
		e.preventDefault();
	};

	refreshSiteSurvey = () => {
		const { getSiteSurvey, match } = this.props;
		this.setState({ loading: true });
		const siteSurveyId = match.params.id;
		getSiteSurvey(siteSurveyId).then(() => {
			this.setState({ loading: false });
		});
	};

	handleScheduleWalkthroughCancel = () => {
		this.setState({
			scheduleWalkthroughFormVisible: false,
		});
	};

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	onUploadTaskList = () => {
		this.setState({ uploadTaskModalVisible: true });
	};

	onUploadTaskModalClose = () => {
		this.setState({ uploadTaskModalVisible: false });
	};

	handleScheduleSuccess = () => {
		message.success('Walkthrough scheduled successfully!');
		this.refreshSiteSurvey();
		this.handleScheduleWalkthroughCancel();
	};

	render() {
		const { currentUser, updateSiteSurvey, siteSurvey } = this.props;

		const { loading } = this.state;

		const getSourceDataForSiteSurvey = (ss) => {
			const tasks = nullSafeGetOrElse('tasks', ss, []);
			const formattedTasks = tasks.map((task) => ({
				data: {
					...task,
					multipleChoicesWithLabel: task.multipleChoicesWithLabel
						? task.multipleChoicesWithLabel
								.map(
									(choice) => `${choice.value}${choice.description ? `:${choice.description}` : ''}`
								)
								.join(',')
						: undefined,
				},
			}));
			return formattedTasks;
		};

		const onDownloadTaskList = () => {
			const tasks = nullSafeGetOrElse('tasks', siteSurvey, []);
			const formattedTasks = tasks.map((task) => ({
				title: task.title,
				description: task.description || '',
				group: task.groupTitle || '',
				questionType: task.questionType,
				multipleChoices: task.multipleChoicesWithLabel
					? task.multipleChoicesWithLabel
							.map(
								(choice) => `${choice.value}${choice.description ? `:${choice.description}` : ''}`
							)
							.join(',')
					: '',
			}));
			const csvExporter = new ExportToCsv({ ...csvOptions, filename: siteSurvey.title });
			csvExporter.generateCsv(formattedTasks);
		};

		const saveNewTaskList = (taskList) => {
			const newSiteSurvey = {
				...siteSurvey,
				areTasksEdited: true,
				tasks: taskList,
			};
			updateSiteSurvey(newSiteSurvey).then(() => this.refreshSiteSurvey());
		};

		return loading ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="siteSurveysDetailDetailsPage">
				<LogOnMountWithStandardEventProperties eventType="visited buyer site survey detail page" />
				{this.state.scheduleWalkthroughFormVisible ? (
					<ScheduleWalkthroughModalForm
						onCancel={this.handleScheduleWalkthroughCancel}
						onSuccess={this.handleScheduleSuccess}
					/>
				) : null}
				<Row style={{ margin: '16px 4px' }} gutter={12}>
					<Col span={16}>
						<div className="rowSpacing">
							<Card
								title="Walkthroughs"
								extra={
									<AccessPermissionChecker name={PERMISSION_NAMES.ADD_WALKTHROUGHS}>
										<Button
											style={{
												position: 'absolute',
												right: 32,
												top: 12.5,
											}}
											type="primary"
											onClick={this.handleSchedule}
										>
											Schedule Walkthrough
										</Button>
									</AccessPermissionChecker>
								}
							>
								<SiteSurveyWalkthroughSection />
							</Card>
						</div>
						<div className="rowSpacing">
							<Card
								title="Tasks"
								extra={
									<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_SITE_SURVEYS}>
										<FlatfileButton
											settings={{
												type: 'tasks - Site Survey',
												disableManualInput: true,
												fields: [
													{
														label: 'Title',
														key: 'title',
													},
													{
														label: 'Description',
														key: 'description',
													},
													{
														label: 'Group',
														key: 'groupTitle',
													},
													{
														label: 'Question Type',
														key: 'questionType',
														type: 'select',
														options: [
															{ label: 'Multiple Choice', value: 'multipleChoice' },
															{ label: 'Free Text', value: 'textbox' },
														],
													},
													{
														label: 'Answer Options',
														key: 'multipleChoicesWithLabel',
														validators: [
															{
																validate: 'required_with_values',
																fieldValues: {
																	questionType: 'multipleChoice',
																},
																error: 'Mutliple choice questions should have options',
															},
															{
																validate: 'regex_matches',
																regex: '[a-zA-Z0-9_ :]+(,[a-zA-Z0-9_ :]+)*$',
																error: 'Values should be comma separated. Ex: 1:low,2:high',
															},
														],
													},
												],
												i18nOverrides: {
													en: {
														otherLocales: ['en-US', 'en-CA', 'en-GB'],
														overrides: {
															header: 'Update {{thing}}',
															header2: 'Update {{thing}}',
															readySubmit: 'Are you ready to submit your updated task list?',
															success: "We've updated your task list!",
														},
													},
												},
											}}
											// @ts-ignore
											source={getSourceDataForSiteSurvey(siteSurvey)}
											licenseKey={'9cfd8b67-b664-4b19-84cf-b5fb00d8fb5c'}
											customer={{
												companyId: nullSafeGet('facility.buyerCompanyId', currentUser),
												companyName: nullSafeGet('facility.name', currentUser),
												userId: currentUser.email,
											}}
											onData={async (results) => {
												// do something with the results
												const newTasks = results.validData.map((task) => ({
													...task,
													multipleChoicesWithLabel: task.multipleChoicesWithLabel
														? task.multipleChoicesWithLabel.split(',').map((choice) => {
																const parts = choice.split(':');
																return {
																	value: parts[0],
																	...(parts.length > 1 && { description: parts[1] }),
																};
														  })
														: undefined,
												}));

												saveNewTaskList(newTasks);
											}}
											onCancel={() => {
												console.log('cancel');
											}}
											render={(importer, launch) => {
												return (
													<Button onClick={launch}>
														<EditFilled translate="" /> Edit Task List
													</Button>
												);
											}}
										/>
										<Button
											type="primary"
											style={{ marginLeft: '24px' }}
											onClick={this.onUploadTaskList}
										>
											<UploadOutlined translate="" />
											Replace Task List
										</Button>
									</AccessPermissionChecker>
								}
							>
								<TaskListRO tasks={nullSafeGetOrElse('tasks', siteSurvey, [])} />
							</Card>
						</div>
					</Col>
					<Col span={8}>
						<div className="rowSpacing">
							{nullSafeGet('location', siteSurvey) ? (
								<div className="rowSpacing">
									<LocationCardDisplay location={siteSurvey.location} />
								</div>
							) : null}
						</div>
					</Col>
				</Row>
				<Modal
					title="Replace Task List"
					visible={this.state.uploadTaskModalVisible}
					footer={null}
					onCancel={this.onUploadTaskModalClose}
				>
					<UploadTaskListPopup
						onDownloadTaskList={onDownloadTaskList}
						onSaveTaskList={saveNewTaskList}
						onCancel={this.onUploadTaskModalClose}
					/>
				</Modal>
			</Content>
		);
	}
}

const mapStateToProps = (state) => ({
	siteSurveysFetching: state.site_surveys.fetching,
	siteSurvey: state.site_surveys.detail,
	walkthroughs: state.walkthroughs,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	getSiteSurvey: (id) => dispatch(siteSurveysRestCrudThunksForBuyer.readOne(id)),
	updateSiteSurvey: (id) => dispatch(siteSurveysRestCrudThunksForBuyer.update(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteSurveysDetailPage));

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	clearWorkVendorFilters,
	updateWorkVendorFilters,
} from '../../actions/work_analytics_actions';
import { fetchWorkOrderCountByVendor } from '../../thunks/work_analytics_thunks';
import WorkAnalyticsCategoryBreakdownPage from '../work_analytics_category_breakdown_page/WorkAnalyticsCategoryBreakdownPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

class WorkAnalyticsOverviewVendorsPage extends React.Component<any, any> {
	render() {
		const { analytics, userType } = this.props;
		const { workOrderCountByVendor, vendorFilters } = analytics;

		return [
			<LogOnMountWithStandardEventProperties eventType="visited work analytics vendors page" />,
			<WorkAnalyticsCategoryBreakdownPage
				updateFilters={updateWorkVendorFilters}
				clearFilters={clearWorkVendorFilters}
				workOrderCountByCategory={workOrderCountByVendor}
				filters={vendorFilters}
				fetchWorkOrderCountsByCategory={fetchWorkOrderCountByVendor(userType)}
				fieldDisplayName="Vendor"
			/>,
		];
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	accountActivated: state.session.accountActivated,
	currentUser: state.session.currentUser,
	analytics: state.work_analytics,
});

const mapDispatchToProps = (dispatch) => ({});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(WorkAnalyticsOverviewVendorsPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import IntercomService from './IntercomService';
import Cohere from 'cohere-js';
import posthog from 'posthog-js';
import { getImpersonatorUser } from '../thunks/session_thunks';
import { nullSafeGet } from './DataAccessUtils';

class ThirdPartyService {
	// TODO: init method should also go here. consolidation

	boot({ user }): void {
		let userObj = user;
		const isImpersonationMode = !!localStorage.getItem('impersonation');

		if (isImpersonationMode) {
			userObj = getImpersonatorUser();
		}
		// posthog-js
		posthog.identify(userObj.contact.email, {
			company: nullSafeGet('contact.facility.name', userObj),
			userType: nullSafeGet('user.userType', userObj),
			email: nullSafeGet('contact.email', userObj),
			roles: nullSafeGet('user.roles', userObj),
		});

		IntercomService.boot({ user: { ...userObj.user, ...userObj.contact } });
		window['Canny']('identify', {
			appID: '60679823a8497c443e504ecf',
			user: {
				name: userObj.contact.nameGiven + ' ' + userObj.contact.nameFamily,
				email: userObj.contact.email,
				id: userObj.contact.email,
			},
		});
		Cohere.identify(userObj.contact.email, {
			displayName: userObj.contact.nameGiven + ' ' + userObj.contact.nameFamily,
			email: userObj.contact.email,
		});
	}
}

export default new ThirdPartyService();

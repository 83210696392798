import { PARTS_EQUIPMENTS_WORK_ORDERS_CRUD_ACTION_CREATORS } from '../actions/parts_equipments_work_orders_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';

export const partsEquipmentsWorkOrdersRestCrudThunksForBuyer = new RestCrudThunks(
	'work_order_parts_equipments',
	'work_order_parts_equipment',
	'workOrderPartsEquipment',
	'workOrderPartsEquipment',
	PARTS_EQUIPMENTS_WORK_ORDERS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'work_order/'
);
export const partsEquipmentsWorkOrdersRestCrudThunksForSupplier = new RestCrudThunks(
	'work_order_parts_equipments',
	'work_order_parts_equipment',
	'workOrderPartsEquipment',
	'workOrderPartsEquipment',
	PARTS_EQUIPMENTS_WORK_ORDERS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'work_order/'
);

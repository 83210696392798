import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Spin, Tooltip } from 'antd';
import { EmptyState } from '../empty_state/EmptyState';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import {
	getLineItemVendor,
	getPurchaseRequestId,
	isPRLineItemSelectionDisabled,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import {
	downloadPRLineItemsCSVForSupplier,
	purchaseRequestLineItemsRestCrudThunksForSupplier,
} from '../../thunks/purchase_request_line_items_thunks';
import { PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS } from '../../actions/purchase_request_line_items_actions';
import HyperLink, { ENTITY_TYPE } from '../common/HyperLink';
import PurchaseRequestStatusDisplay from '../purchase_request_status_display/PurchaseRequestStatusDisplay';
import { getRecordsForCompositeTargetCollection } from '../../reducers/standard_reducer_utils';
import PartEquipmentNameWithType from '../equipment_per_stock_location_row_display/PartEquipmentNameWithType';
import { getDisabledMessage } from './po_create_utils';
import LocationStockLocationNameWithType from '../equipment_per_stock_location_row_display/LocationStockLocationNameWithType';
import PRToPOPage from './PRToPOPage';
import { DownloadOutlined } from '@ant-design/icons';
import ReadyToOrderFilters from './ReadyToOrderFilters';
import { useForm } from 'antd/lib/form/Form';

const TC_NAME = 'readyToOrderIndexPage';
const BACK_TEXT = 'Back To Ready to Order list';

const ReadyToOrderListPage: FC<any> = ({
	purchaseRequestLineItems,
	clearFilters,
	updateFilters,
	fetchApprovedLineItemsCount,
	exportPRLineItemsCSV,
}) => {
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [selectedVendor, setSelectedVendor] = useState<any>({ id: undefined, name: undefined });
	const [selectedSL, setSelectedSL] = useState<any>({ id: undefined, name: undefined });
	const [selectedLocation, setSelectedLocation] = useState<any>({ id: undefined, name: undefined });
	const [poFormVisible, setPoFormVisible] = useState(false);
	const [selectedRecords, setSelectedRecords] = useState([]);

	const [prLiFetching, setPrLiFetching] = useState(false);

	const [form] = useForm();

	const [exporting, setExporting] = useState(false);

	useEffect(() => {
		return () => {
			clearFilters(TC_NAME);
			form.resetFields();
		};
	}, []);

	const getRecordFromTargetCollection = useCallback(
		(id) => {
			const records = getRecordsForCompositeTargetCollection(purchaseRequestLineItems, TC_NAME);
			return records.find((_) => _.id === id);
		},
		[purchaseRequestLineItems]
	);

	useEffect(() => {
		setPrLiFetching(true);
		fetchApprovedLineItemsCount().finally(() => setPrLiFetching(false));
	}, []);

	const onSelectChange = useCallback((newSelectedRowKeys: React.Key[], selectedRows) => {
		setSelectedRowKeys(newSelectedRowKeys);
		setSelectedRecords(selectedRows);
		if (selectedRows.length > 0) {
			const record = selectedRows.find((_) => !!getLineItemVendor(_).id) || selectedRows[0];
			setSelectedVendor({
				...getLineItemVendor(record),
			});
			setSelectedSL(nullSafeGetOrElse('stockLocation', record, {}));
			setSelectedLocation(nullSafeGetOrElse('location', record, {}));
		} else {
			setSelectedVendor({});
			setSelectedSL({});
			setSelectedLocation({});
		}
	}, []);

	const canCreatePO = useMemo(() => selectedRowKeys.length > 0, [selectedRowKeys.length]);

	const rowSelection = useMemo(
		() => ({
			selectedRowKeys,
			preserveSelectedRowKeys: true,
			onChange: onSelectChange,
			getCheckboxProps: (record) => ({
				disabled: isPRLineItemSelectionDisabled({
					record,
					selectedLocation,
					selectedSL,
					selectedVendor,
				}),
			}),
		}),
		[onSelectChange, selectedLocation, selectedRowKeys, selectedSL, selectedVendor]
	);

	const onSelectCancel = useCallback(() => {
		setSelectedVendor({});
		setSelectedSL({});
		setSelectedLocation({});
		setSelectedRowKeys([]);
		setSelectedRecords([]);
		setPoFormVisible(false);
	}, []);

	const filters = useMemo(
		() => nullSafeGetOrElse(`${TC_NAME}.filters`, purchaseRequestLineItems, {}),
		[purchaseRequestLineItems]
	);

	const { partEquipmentVendorId, locationId, stockLocationId } = filters;

	useEffect(() => {
		onSelectCancel();
	}, [partEquipmentVendorId, locationId, stockLocationId, onSelectCancel]);

	const columns = useMemo(
		() => [
			{
				title: 'PR',
				dataIndex: 'supplierPurchaseRequest',
				key: 'supplierPurchaseRequestId',
				sorter: true,
				render: (_) => (
					<HyperLink
						text={getPurchaseRequestId(_)}
						entityType={ENTITY_TYPE.PURCHASE_REQUEST}
						entityId={_.id}
						backLinkText="Back to Requests"
					/>
				),
			},
			{
				title: 'Part/Eq ID',
				key: 'partEquipmentNumber',
				sorter: true,
				render: (_, record) =>
					record.isEquipmentLine
						? nullSafeGetOrElse('equipmentType.equipmentTypeNumber', record, '--')
						: nullSafeGetOrElse('part.partNumber', record, '--'),
			},
			{
				title: 'Name',
				key: 'partEquipmentName',
				sorter: true,
				render: (_, record) => <PartEquipmentNameWithType record={record} backText={BACK_TEXT} />,
			},
			{
				title: 'Vendor',
				key: 'vendor',
				sorter: true,
				render: (_, record) => nullSafeGetOrElse('name', getLineItemVendor(record), '--'),
			},
			{
				title: 'Location',
				key: 'location',
				sorter: true,
				render: (_, record) => (
					<LocationStockLocationNameWithType record={record} backLinkText={BACK_TEXT} />
				),
			},
			{
				title: 'Quantity',
				key: 'quantity',
				sorter: true,
				dataIndex: 'equipmentQuantity',
				render: (_, record) =>
					record.isEquipmentLine
						? nullSafeGetOrElse('equipmentQuantity', record, 0)
						: nullSafeGetOrElse('partQuantity', record, 0),
			},
			{
				title: 'Status',
				render: (_, record) => <PurchaseRequestStatusDisplay status={record.status} />,
			},
		],
		[]
	);

	const onCreatePO = useCallback(() => setPoFormVisible(true), []);

	const CustomRow = (props) => {
		const id = props['data-row-key'];
		const record = getRecordFromTargetCollection(id);
		const message = getDisabledMessage({
			record,
			canCreatePO,
			selectedVendor,
			selectedSL,
			selectedLocation,
		});
		return message ? (
			<Tooltip title={message} placement="topLeft">
				<tr {...props} />
			</Tooltip>
		) : (
			<tr {...props} />
		);
	};

	const canShowPRList = useMemo(() => {
		return partEquipmentVendorId || !!locationId || !!stockLocationId;
	}, [locationId, partEquipmentVendorId, stockLocationId]);

	const totalCount = useMemo(
		() => nullSafeGetOrElse('counts.approved', purchaseRequestLineItems, 0),
		[purchaseRequestLineItems]
	);

	const downloadCSV = useCallback(() => {
		setExporting(true);
		exportPRLineItemsCSV(filters).finally(() => setExporting(false));
	}, [exportPRLineItemsCSV, filters]);

	return !poFormVisible ? (
		<div>
			<ReadyToOrderFilters targetCollectionName={TC_NAME} form={form} filters={filters} />
			{canShowPRList ? (
				<PaginatedReduxTableWithHeader
					targetCollectionName={TC_NAME}
					updateFilters={updateFilters}
					stateSlice={purchaseRequestLineItems}
					clearAndUpdateFilters={clearFilters}
					filterConfig={[]}
					entityCollectionName="purchase_request_line_items"
					tableColumns={columns}
					fetchData={purchaseRequestLineItemsRestCrudThunksForSupplier.read}
					rowSelection={rowSelection}
					preAppliedFilters={{ ...filters, status: 'approved' }}
					components={{
						body: {
							row: CustomRow,
						},
					}}
					rightActions={
						canCreatePO ? (
							<div className="flex-start flex flex-row">
								<Button onClick={onSelectCancel} size="large">
									Cancel
								</Button>
								<Button className="ml-2" size="large" type="primary" onClick={onCreatePO}>
									Create Purchase Order
								</Button>
							</div>
						) : (
							<Button
								size="large"
								icon={<DownloadOutlined translate="" />}
								className="inline-block-visible-md ml-2"
								loading={exporting}
								onClick={downloadCSV}
							>
								<span className="inline-block-visible-xl">Export CSV</span>
							</Button>
						)
					}
					hideFilters
					showHeader
					emptyState={
						<EmptyState
							graphic={
								<img
									alt="No purchase requests found"
									style={{ marginBottom: 8 }}
									src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
								/>
							}
							headline={'No purchase requests found'}
						/>
					}
				/>
			) : (
				<div className="flex flex-row justify-center p-4">
					<EmptyState
						graphic={
							<img
								alt="No purchase requests found"
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
							/>
						}
						headline={
							prLiFetching ? (
								<div>
									<Spin />
								</div>
							) : totalCount ? (
								`You have a total of ${totalCount} item${totalCount > 1 ? 's' : ''} ready to order`
							) : (
								'Currently, you do not have any item ready to order.'
							)
						}
						body={
							!prLiFetching &&
							!!totalCount && <div>Please select Vendor and Location to list Requests</div>
						}
					/>
				</div>
			)}
		</div>
	) : (
		<PRToPOPage
			selectedRecords={selectedRecords}
			onCancel={onSelectCancel}
			selectedVendor={selectedVendor}
			selectedSL={selectedSL}
			selectedLocation={selectedLocation}
		/>
	);
};

const mapStateToProps = (state) => ({
	purchaseRequestLineItems: state.purchase_request_line_items,
});

const mapDispatchToProps = (dispatch) => ({
	updateFilters: (filters, targetCollection) =>
		dispatch(
			PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)
		),
	clearFilters: (targetCollectionName) =>
		dispatch(
			PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS.clearAllFilters(targetCollectionName)
		),
	fetchApprovedLineItemsCount: () =>
		dispatch(
			purchaseRequestLineItemsRestCrudThunksForSupplier.countBy(
				{
					status: 'approved',
				},
				'approved'
			)
		),
	exportPRLineItemsCSV: (params, filters) =>
		dispatch(downloadPRLineItemsCSVForSupplier(params, filters)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(ReadyToOrderListPage)
);

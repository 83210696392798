import React, { useState } from 'react';
import { connect } from 'react-redux';
import { showDate, showHour } from '../../utils/DataFormatterUtils';
import { ImageThumbnails } from '../image_thumbnails/ImageThumbnails';
import { getFileMimeTypeFromString } from '../../utils/FileAccessUtils';
import { ContactAvatar } from '../contact_avatar/ContactAvatar';
import { getObjectValues, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { Popover, Spin } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import {
	contactsRestCrudThunksForBuyer,
	contactsRestCrudThunksForSupplier,
} from '../../thunks/contacts_thunks';
import moment from 'moment';

const MessageBubble = ({
	currentUser,
	n,
	idx,
	subscribers = [],
	all_contacts,
	getContacts,
	userType,
}) => {
	const [seenByOpen, setSeenByOpen] = useState(false);
	const [seenBydata, setSeenBydata] = useState([]);
	const notesReadyBy =
		n.noteReadDetails &&
		n.noteReadDetails.filter((contact) => contact.readBy !== currentUser.email);
	function findUsersByEmail(users, readByArray) {
		const userEmails = readByArray.map((entry) => entry.readBy);
		const uniqueUserEmails = Array.from(new Set(userEmails));

		const usersWithReadByEmail = users.filter((user) => uniqueUserEmails.includes(user.email));
		const emailsNotInUserArray = uniqueUserEmails.filter(
			(email) => !users.find((user) => user.email === email)
		);

		return { usersWithReadByEmail, emailsNotInUserArray };
	}
	const handleClickChange = (open) => {
		setSeenByOpen(open);

		if (open) {
			const { usersWithReadByEmail, emailsNotInUserArray } = findUsersByEmail(
				(subscribers || []).concat(
					getObjectValues(all_contacts.records).map((el) => ({
						...el,
						key: el.email,
					}))
				),
				notesReadyBy
			);
			if (emailsNotInUserArray.length) {
				getContacts(emailsNotInUserArray).then((data = []) => {
					const { usersWithReadByEmail } = findUsersByEmail(
						(subscribers || [])
							.concat(
								getObjectValues(all_contacts.records).map((el) => ({
									...el,
									key: el.email,
								}))
							)
							.concat(
								getObjectValues(data).map((el) => ({
									...el,
									key: el.email,
								}))
							),
						notesReadyBy
					);
					setSeenBydata(usersWithReadByEmail);
				});
			} else {
				setSeenBydata(usersWithReadByEmail);
			}
		}
	};

	const getName = (userContact) => {
		return `-- ${nullSafeGetOrElse(
			'noteAddedByContact.nameGiven',
			userContact,
			''
		)} ${nullSafeGetOrElse('noteAddedByContact.nameFamily', userContact, '')}`;
	};

	return (
		<li className={n.noteAddedBy === currentUser.email ? 'me' : 'him'} key={idx}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<div style={{ fontSize: 16, whiteSpace: 'pre-wrap' }}>{n.text}</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'flex-end',
					}}
				>
					<div
						style={{
							display: 'flex',
							fontSize: '12px',
							color: '#aaa',
						}}
						className="mr-2"
					>
						{n.noteAddedBy !== currentUser.email && getName(n)}
					</div>
					<div
						style={{
							display: 'flex',
							fontSize: '10px',
							color: n.noteAddedBy === currentUser.email ? 'rgba(209,230,250,1)' : '#aaa',
						}}
					>
						{showHour(n.noteAddedAt, 'MMM DD')}
					</div>
				</div>
			</div>
			{n.photo ? (
				<ImageThumbnails imageWidth={240} imageHeight={240} photoStrings={[n.photo]} />
			) : null}
			{n.video ? (
				<video width="100%" height="auto" controls>
					<source src={n.video} type={getFileMimeTypeFromString(n.video)} />
					Your browser does not support the video tag.
				</video>
			) : null}
			<div className="time">
				{n.noteAddedBy === currentUser.email
					? [
							<div key={1} style={{ marginRight: 8 }}>
								<ContactAvatar contact={n.noteAddedByContact} userType={userType} />
							</div>,
							<div key={2}>{showDate(n.noteAddedAt, 'MMM DD')}</div>,
					  ]
					: [
							<div key={1} style={{ marginLeft: 8 }}>
								<ContactAvatar contact={n.noteAddedByContact} userType={userType} />
							</div>,
							<div key={2}>{showDate(n.noteAddedAt, 'MMM DD')}</div>,
					  ]}
			</div>
			{(n.noteAddedBy === currentUser.email &&
				notesReadyBy &&
				nullSafeGetOrElse('length', notesReadyBy, 0) && (
					<div className="row mt-1 flex items-start justify-end">
						<span className="text-sm">{nullSafeGet('length', notesReadyBy)}</span>{' '}
						<Popover
							placement="left"
							title="Seen by"
							trigger="click"
							onVisibleChange={(open) => handleClickChange(open)}
							visible={seenByOpen}
							content={
								<div className="max-w-40 no-scrollbar flex max-h-60 cursor-pointer  flex-col overflow-y-scroll pr-2 ">
									{(notesReadyBy &&
										seenBydata.length &&
										notesReadyBy.map(({ readBy, readAt }) => {
											const contact = seenBydata.find((el) => el.email === readBy);
											if (contact.nameGiven)
												return (
													<div className="mb-3 flex flex-row items-center">
														<ContactAvatar
															hidePopover={true}
															placement="bottomRight"
															userType={userType}
															contact={contact}
															className={'contactAvatar mb-2'}
															style={{
																backgroundColor:
																	contact.contactType === 'supplier' ? '#48a7bd' : '#7c48bd',
															}}
														/>

														<div className="inline-block w-full pl-3">
															<div className="flex w-full items-center justify-between">
																{contact.nameGiven} {contact.nameFamily}{' '}
																<div className="ml-2 text-xs text-slate-400">
																	{moment(readAt).fromNow()}
																</div>
															</div>
															<div>{contact.email}</div>
														</div>
													</div>
												);
											else return null;
										})) || <Spin />}
								</div>
							}
						>
							<EyeOutlined className="ml-1" style={{ lineHeight: 1.5 }} translate="" />
						</Popover>
					</div>
				)) ||
				null}
		</li>
	);
};

const mapStateToProps = (state) => ({
	all_contacts: state.all_contacts,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getContacts: (ids) => {
		if (ownProps.userType === 'supplier') {
			return dispatch(
				contactsRestCrudThunksForSupplier.readMultiple(ids, 'contactsIndex', null, null, null, true)
			);
		}
		return dispatch(
			contactsRestCrudThunksForBuyer.readMultiple(ids, 'contactsIndex', null, null, null, true)
		);
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageBubble);

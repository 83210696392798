import { PURCHASE_REQUESTS_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/purchase_requests_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = standardInitialStateGenerator([
	'purchaseRequestsIndex',
	'APPROVED_REQUESTS_LIST',
	'purchaseOrdersAdvancedFilters',
	'poLineItemsAdvancedFilters',
	'workOrdersPurchaseRequests',
]);
export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState);

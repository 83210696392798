import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Button, Card, message, Popconfirm, Popover } from 'antd';
import {
	acceptWorkOrderForSupplier,
	declineWorkOrderForSupplier,
	declineWorkOrderForPrivateSupplier,
	manualCheckInServiceCallForSupplier,
	manualCheckOutServiceCallForSupplier,
	transferWorkOrderForSupplier,
	workOrdersRestCrudThunksForSupplier,
} from '../../thunks/work_orders_thunks';
import SubnavBar from '../subnav_bar/SubnavBar';
import {
	nullSafeGet,
	nullSafeGetOrElse,
	changeFilterValueToArrayOfIds,
} from '../../utils/DataAccessUtils';
import { AdvancedFilters } from '../advanced_filters/AdvancedFilters';
import { workOrderPrioritiesRestCrudThunksForSupplier } from '../../thunks/work_order_priorities_thunks';
import { locationsRestCrudThunksForSupplier } from '../../thunks/locations_thunks';
import { spendCategoriesRestCrudThunksForSupplier } from '../../thunks/spend_categories_thunks';
import ScheduleServiceCallModalForm from '../schedule_service_call_modal_form/ScheduleServiceCallModalForm';
import {
	rescheduleServiceCallForSupplier,
	scheduleServiceCallForSupplier,
} from '../../thunks/work_orders_thunks';
import ManualCheckInModalForm from '../manual_checkin_modal_form/ManualCheckInModalForm';
import ManualCheckOutModalForm from '../manual_checkout_modal_form/ManualCheckOutModalForm';
import {
	SCHEDULE_TECH_STATUSES,
	TECH_SCHEDULED_STATUSES,
	WORK_ORDER_STATUSES,
} from '../../constants/WorkOrderStatuses';
import { fieldTechsRestCrudThunksForSupplier } from '../../thunks/field_techs_thunks';
import TransferWorkOrderModalForm from '../transfer_work_order_modal_form/TransferWorkOrderModalForm';
import { EmptyState } from '../empty_state/EmptyState';
import PaginatedReduxCalendar from '../paginated_redux_calendar/PaginatedReduxCalendar';
import { capitalize } from '../../utils/DataFormatterUtils';
import { fetchServiceCallsForTimePeriodForSupplier } from '../../thunks/service_calls_thunks';
import { SERVICE_CALLS_CRUD_ACTION_CREATORS } from '../../actions/service_calls_actions';
import moment from 'moment';
import { ServiceCallPopoverDisplay } from '../service_call_popover_display/ServiceCallPopoverDisplay';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import DeclineWorkorderForm from '../decline_workorder_form/DeclineWorkorderForm';
import OWAsyncTreeSelect from '../ow_async_tree_select/OWAsyncTreeSelect';
import OWLocationAsyncTreeSelect from '../ow_location_async_tree_select/OWLocationAsyncTreeSelect';

const queryString = require('qs');
const { Content } = Layout;
const SERVICE_CALLS_CALENDAR_TARGET_COLLECTION = 'serviceCallsCalendarIndex';
const style = require('./SupplierServiceCallCalendarPage.less');

class SupplierServiceCallCalendarPage extends React.Component<any, any> {
	formRefs: any = {
		transferWorkOrderForm: null,
		rescheduleServiceCallForm: null,
		manualCheckInForm: null,
		manualCheckOutForm: null,
	};
	initialDateRange: any;

	constructor(props) {
		super(props);
		this.state = {
			rescheduleServiceCallFormVisible: false,
			transferWorkOrderFormVisible: false,
			declineWorkOrderFormVisible: false,
			manualCheckInFormVisible: false,
			manualCheckOutFormVisible: false,
			selectedWorkOrder: undefined,
			selectedServiceCall: {},
		};
		const { updateServiceCallsFilters, history, location } = props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		const { total, sort_by, order, ...serviceCallFilters } = queryParams;
		let startDate, endDate;
		if (!serviceCallFilters.startDate || !serviceCallFilters.endDate) {
			startDate = moment().startOf('month').format('YYYY-MM-DD');
			endDate = moment().endOf('month').format('YYYY-MM-DD');
			this.initialDateRange = [new Date(startDate), new Date(endDate)];
			history.replace(
				`${location.pathname}?${queryString.stringify({ ...queryParams, startDate, endDate })}`
			);
		} else {
			history.replace(`${location.pathname}?${queryString.stringify(queryParams)}`);
			this.initialDateRange = [
				new Date(serviceCallFilters.startDate),
				new Date(serviceCallFilters.endDate),
			];
		}
		updateServiceCallsFilters(
			{
				startDate,
				endDate,
				...serviceCallFilters,
			},
			SERVICE_CALLS_CALENDAR_TARGET_COLLECTION
		);
	}

	handleArchive = (workOrder, e) => {
		const { history, archiveWorkOrder } = this.props;
		archiveWorkOrder(workOrder).then(() => {
			history.push('/supplier/workOrders/overview/scheduled');
		});
		e.stopPropagation();
		e.preventDefault();
	};

	handleDelete = (workOrder, e) => {
		const { history, deleteWorkOrder } = this.props;
		deleteWorkOrder(workOrder).then(() => {
			history.push('/supplier/workOrders/overview/scheduled');
		});
		e.stopPropagation();
		e.preventDefault();
	};

	handleEdit = (workOrder, e) => {
		const { history } = this.props;
		history.push(`/supplier/workOrders/edit/${workOrder.id}`);
		e.stopPropagation();
		e.preventDefault();
	};

	handleCreate = (workOrder, e) => {
		const { history } = this.props;
		history.push(`/supplier/workOrders/edit/${workOrder.id}`);
		e.stopPropagation();
		e.preventDefault();
	};

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	handleCancel = (workOrder, e) => {
		const { history, cancelWorkOrder } = this.props;
		cancelWorkOrder(workOrder).then(() => {
			history.push('/supplier/workOrders/overview/scheduled');
		});
		e.stopPropagation();
		e.preventDefault();
	};

	handlePause = (workOrder, e) => {
		const { history } = this.props;
		history.push(`/supplier/workOrders/edit/${workOrder.id}`);
		e.stopPropagation();
		e.preventDefault();
	};

	handleAccept = (workOrder, e) => {
		const { history, acceptWorkOrder } = this.props;
		acceptWorkOrder(workOrder).then(() => {
			history.push('/supplier/workOrders/overview/scheduled');
		});
		e.stopPropagation();
		e.preventDefault();
	};

	handleDecline = (workOrder, e) => {
		this.setState({
			declineWorkOrderFormVisible: true,
			selectedWorkOrder: workOrder,
		});
		e.stopPropagation();
		e.preventDefault();
	};

	handleSchedule = (workOrder, e) => {
		this.setState({
			scheduleServiceCallFormVisible: true,
			selectedWorkOrder: workOrder,
		});
		e.stopPropagation();
		e.preventDefault();
	};

	handleReschedule = (workOrder, e) => {
		this.setState({
			rescheduleServiceCallFormVisible: true,
			selectedWorkOrder: workOrder,
			selectedServiceCall: nullSafeGetOrElse('lastServiceCall', workOrder, {}),
		});
		e.stopPropagation();
		e.preventDefault();
	};

	handleManualCheckIn = (workOrder, e) => {
		this.setState({
			manualCheckInFormVisible: true,
			selectedServiceCall: nullSafeGetOrElse('lastServiceCall', workOrder, {}),
		});
		e.stopPropagation();
		e.preventDefault();
	};

	handleTransfer = (workOrder, e) => {
		this.setState({
			transferWorkOrderFormVisible: true,
			selectedWorkOrder: workOrder,
		});
		e.stopPropagation();
		e.preventDefault();
	};

	handleManualCheckOut = (workOrder, e) => {
		this.setState({
			manualCheckOutFormVisible: true,
			selectedServiceCall: nullSafeGetOrElse('lastServiceCall', workOrder, {}),
		});
		e.stopPropagation();
		e.preventDefault();
	};

	handleScheduleServiceCallSubmit = () => {
		const { scheduleServiceCall } = this.props;
		const form = this.formRefs['scheduleServiceCallForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			scheduleServiceCall(values).then(() => {
				this.setState({ scheduleServiceCallFormVisible: false });
				message.success(`Service call scheduled.`);
			});
		});
	};

	handleScheduleServiceCallCancel = (e) => {
		this.setState({
			scheduleServiceCallFormVisible: false,
		});
	};

	handleRescheduleServiceCallSubmit = () => {
		const { rescheduleServiceCall } = this.props;
		const form = this.formRefs['rescheduleServiceCallForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			rescheduleServiceCall(values).then(() => {
				this.setState({ rescheduleServiceCallFormVisible: false });
				message.success(`Service call rescheduled.`);
			});
		});
	};

	handleTransferWorkOrderCancel = (e) => {
		this.setState({
			transferWorkOrderFormVisible: false,
		});
	};

	handleTransferWorkOrderSubmit = () => {
		const { transferWorkOrder } = this.props;
		const form = this.formRefs['transferWorkOrderForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			transferWorkOrder(values).then(() => {
				this.setState({ transferWorkOrderFormVisible: false });
				message.success(`Work order transferred`);
			});
		});
	};

	handleDeclineWorkOrderCancel = (e) => {
		this.setState({
			declineWorkOrderFormVisible: false,
		});
	};

	handleDeclineWorkOrderSubmit = () => {
		const { history, declineWorkOrder, declineWorkOrderPrivate, currentUser } = this.props;
		const isPrivateSupplier = nullSafeGet('facility.status', currentUser) === 'private';
		const declineRoute = isPrivateSupplier ? declineWorkOrderPrivate : declineWorkOrder;

		const form = this.formRefs['declineWorkOrderForm'].props.form;
		form.validateFields((err, values) => {
			const workOrderFormData = {
				id: this.state.selectedWorkOrder.id,
				...(values.note && {
					note: {
						text: values.note,
						noteAddedBy: currentUser.email,
						noteAddedAt: moment(),
					},
				}),
			};
			declineRoute(workOrderFormData).then(() => {
				this.setState({ declineWorkOrderFormVisible: false });
				message.success(`Work order declined`);
				history.push('/supplier/workOrders/overview/active');
			});
		});
	};

	handleRescheduleServiceCallCancel = (e) => {
		this.setState({
			rescheduleServiceCallFormVisible: false,
		});
	};

	handleManualCheckInSubmit = () => {
		const { manualCheckIn } = this.props;
		const form = this.formRefs['manualCheckInForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			manualCheckIn(values).then(() => {
				this.setState({ manualCheckInFormVisible: false });
				message.success(`Manually Checked In.`);
			});
		});
	};

	handleManualCheckInCancel = (e) => {
		this.setState({
			manualCheckInFormVisible: false,
		});
	};

	handleManualCheckOutSubmit = () => {
		const { manualCheckOut } = this.props;
		const form = this.formRefs['manualCheckOutForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			manualCheckOut(values).then(() => {
				this.setState({ manualCheckOutFormVisible: false });
				message.success(`Manually Checked Out.`);
			});
		});
	};

	handleManualCheckOutCancel = (e) => {
		this.setState({
			manualCheckOutFormVisible: false,
		});
	};

	handleClearAndUpdateFilters = (initialFilters, targetCollectionName) => {
		const { clearAndUpdateFilters, history, serviceCalls } = this.props;
		const startDate = nullSafeGet(
			`${SERVICE_CALLS_CALENDAR_TARGET_COLLECTION}.filters.startDate`,
			serviceCalls
		);
		const endDate = nullSafeGet(
			`${SERVICE_CALLS_CALENDAR_TARGET_COLLECTION}.filters.endDate`,
			serviceCalls
		);
		clearAndUpdateFilters(
			{
				startDate: startDate,
				endDate: endDate,
			},
			targetCollectionName
		);
		history.push(
			`/supplier/workOrders/overview/scheduled?startDate=${startDate}&endDate=${endDate}`
		);
	};

	render() {
		const {
			workOrders,
			currentUser,
			serviceCalls,
			location,
			updateServiceCallsFilters,
			history,
			fetchLocations,
			fetchMultipleLocations,
			fetchWorkOrderPriorities,
			fetchMultipleWorkOrderPriorities,
			fetchSpendCategories,
			fetchMultipleSpendCategories,
			fetchMultipleFieldTechs,
			fetchFieldTechs,
			locations,
			workOrderPriorities,
			spendCategories,
			fieldTechs,
			clearAndUpdateFilters,
			updateServiceCallsSorting,
		} = this.props;

		const transformRecords = (records) => {
			if (!records || records.length === 0) {
				return [];
			}
			const transformedRecord = records.reduce((acc, serviceCall) => {
				const keys = ['serviceScheduledAt'];
				const displayNames = {
					serviceScheduledAt: 'work',
				};
				const events = keys.map((k) => {
					if (serviceCall.checkOutTime) {
						const checkInTime = new Date(serviceCall.checkInTime);
						const checkOutTime = new Date(serviceCall.checkOutTime);
						const timeOnSite = checkOutTime.getTime() - checkInTime.getTime();
						return {
							id: serviceCall.id,
							title: `${capitalize(
								nullSafeGetOrElse('workOrder.problemType.hierarchyName', serviceCall, 'Scheduled')
							)} at ${nullSafeGet('workOrder.location.name', serviceCall)}`,
							category: nullSafeGet('workOrder.displayStatus', serviceCall),
							...serviceCall,
							start:
								timeOnSite >= 3600000 ? checkInTime : new Date(checkOutTime.getTime() - 3600000),
							end: new Date(new Date(serviceCall.checkOutTime).getTime()),
						};
					} else if (serviceCall.checkInTime) {
						return {
							id: serviceCall.id,
							title: `${capitalize(
								nullSafeGetOrElse('workOrder.problemType.hierarchyName', serviceCall, 'Scheduled')
							)} at ${nullSafeGet('workOrder.location.name', serviceCall)}`,
							category: nullSafeGet('workOrder.displayStatus', serviceCall),
							...serviceCall,
							start: new Date(new Date(serviceCall.checkInTime).getTime()),
							end: new Date(new Date(serviceCall.checkInTime).getTime() + 3600000),
						};
					}
					return {
						id: serviceCall.id,
						title: `${capitalize(
							nullSafeGetOrElse('workOrder.problemType.hierarchyName', serviceCall, 'Scheduled')
						)} at ${nullSafeGet('workOrder.location.name', serviceCall)}`,
						category: nullSafeGet('workOrder.displayStatus', serviceCall),
						...serviceCall,
						start: new Date(serviceCall.serviceScheduledAt),
						end: new Date(new Date(serviceCall.serviceScheduledAt).getTime() + 3600000),
					};
				});
				return acc.concat(events);
			}, []);
			return transformedRecord;
		};
		const isPrivateSupplier = nullSafeGet('facility.status', currentUser) === 'private';
		const internalTechConfig =
			currentUser && currentUser.facility && currentUser.facility.internalTechConfig;

		const { allowedToTransferWork, allowedToDenyWork } = internalTechConfig || {
			allowedToTransferWork: true,
			allowedToDenyWork: false,
		};
		const EventComponent = ({ event }) => {
			return (
				<Popover
					trigger="click"
					placement="topLeft"
					overlayClassName="workOrderPopoverDisplay_wrapper"
					content={<ServiceCallPopoverDisplay serviceCall={event} />}
				>
					{event.title}
				</Popover>
			);
		};

		const getWorkOrderActions = (workOrder) => {
			const accept = (
				<Button
					style={{ marginRight: 8 }}
					key="accept"
					type="primary"
					ghost={true}
					onClick={(e) => this.handleAccept(workOrder, e)}
				>
					<i className="icons8-font icons8-green-check-mark"></i> Accept
				</Button>
			);
			const decline = (
				<div onClick={(e) => e.stopPropagation()}>
					<Popconfirm
						onConfirm={(e) => this.handleDecline(workOrder, e)}
						placement="topLeft"
						title="Are you sure you want to decline?"
						okText="Yes"
						cancelText="No"
					>
						<Button style={{ marginRight: 8 }} key="decline">
							<i className="icons8-font icons8-cancel"></i> Decline
						</Button>
					</Popconfirm>
				</div>
			);
			const edit = (
				<Button
					style={{ marginRight: 8 }}
					key="edit"
					onClick={(e) => this.handleEdit(workOrder, e)}
				>
					<i className="icons8-font icons8-font-margin-right icons8-edit"></i> Edit
				</Button>
			);
			const schedule = (
				<Button
					style={{ marginRight: 8 }}
					key="schedule"
					onClick={(e) => this.handleSchedule(workOrder, e)}
				>
					<i className="icons8-font icons8-schedule"></i> Schedule
				</Button>
			);
			const reschedule = (
				<Button
					style={{ marginRight: 8 }}
					key="reschedule"
					onClick={(e) => this.handleReschedule(workOrder, e)}
				>
					<i className="icons8-font icons8-rescheduling-a-task"></i> Reschedule
				</Button>
			);
			const manualCheckIn = (
				<Button
					style={{ marginRight: 8 }}
					key="manualCheckIn"
					onClick={(e) => this.handleManualCheckIn(workOrder, e)}
				>
					<i className="icons8-font icons8-checkmark"></i> Check In
				</Button>
			);
			const manualCheckOut = (
				<Button
					style={{ marginRight: 8 }}
					key="manualCheckOut"
					onClick={(e) => this.handleManualCheckOut(workOrder, e)}
				>
					<i className="icons8-font icons8-checkout"></i> Check Out
				</Button>
			);
			const transfer = (
				<Button
					style={{ marginRight: 8 }}
					key="transfer"
					onClick={(e) => this.handleTransfer(workOrder, e)}
				>
					<i className="icons8-font icons8-forward"></i> Transfer
				</Button>
			);
			const archive = (
				<Button
					style={{ marginRight: 8 }}
					key="archive"
					danger
					ghost={true}
					onClick={(e) => this.handleArchive(workOrder, e)}
				>
					<i className="icons8-font icons8-trash-can"></i> Archive
				</Button>
			);
			const privateOptions = isPrivateSupplier
				? [...[allowedToTransferWork ? transfer : []], ...[allowedToDenyWork ? decline : []]]
				: [];
			switch (workOrder.displayStatus) {
				case 'Pending':
					if (workOrder.status === WORK_ORDER_STATUSES.supplierInitiatedPendingApproval) {
						return [edit];
					} else {
						return null;
					}
				case 'Open':
					return [accept, decline].concat(privateOptions);
				case 'InProgress':
					if (SCHEDULE_TECH_STATUSES.includes(workOrder.status)) {
						return [schedule].concat(privateOptions);
					} else if (TECH_SCHEDULED_STATUSES.includes(workOrder.status)) {
						return [reschedule, manualCheckIn].concat(privateOptions);
					} else if (workOrder.status === WORK_ORDER_STATUSES.techWorkingOnSite) {
						return [manualCheckOut];
					} else {
						return null;
					}
				case 'OnHold':
					return null;
				case 'Completed':
					return [archive];
				case 'Cancelled':
					return [archive];
				default:
					return null;
			}
		};

		return (
			<Content className="supplierWorkOrdersIndexPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited supplier service call calendar page" />
				{/*<BackTop/>*/}
				<ScheduleServiceCallModalForm
					formData={{
						...this.state.selectedServiceCall,
						workOrder: this.state.selectedWorkOrder,
					}}
					isReschedule={true}
					wrappedComponentRef={this.saveFormRef('rescheduleServiceCallForm')}
					visible={this.state.rescheduleServiceCallFormVisible}
					onCancel={this.handleRescheduleServiceCallCancel}
					onSubmit={this.handleRescheduleServiceCallSubmit}
				/>
				<TransferWorkOrderModalForm
					formData={this.state.selectedWorkOrder}
					wrappedComponentRef={this.saveFormRef('transferWorkOrderForm')}
					visible={this.state.transferWorkOrderFormVisible}
					onCancel={this.handleTransferWorkOrderCancel}
					onSubmit={this.handleTransferWorkOrderSubmit}
				/>
				<DeclineWorkorderForm
					wrappedComponentRef={this.saveFormRef('declineWorkOrderForm')}
					visible={this.state.declineWorkOrderFormVisible}
					onCancel={this.handleDeclineWorkOrderCancel}
					onSubmit={this.handleDeclineWorkOrderSubmit}
				/>
				<ManualCheckInModalForm
					formData={this.state.selectedServiceCall}
					wrappedComponentRef={this.saveFormRef('manualCheckInForm')}
					visible={this.state.manualCheckInFormVisible}
					onCancel={this.handleManualCheckInCancel}
					onSubmit={this.handleManualCheckInSubmit}
				/>
				<ManualCheckOutModalForm
					formData={this.state.selectedServiceCall}
					wrappedComponentRef={this.saveFormRef('manualCheckOutForm')}
					visible={this.state.manualCheckOutFormVisible}
					onCancel={this.handleManualCheckOutCancel}
					onSubmit={this.handleManualCheckOutSubmit}
					privateSupplier={nullSafeGet('facility.status', currentUser) === 'private' ? true : false}
				/>
				<Row key={2} style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<SubnavBar
							left={
								<div>
									<AdvancedFilters
										updateFilters={updateServiceCallsFilters}
										filterConfig={[
											{
												label: 'Location',
												stateSlice: locations,
												mode: 'multiple',
												filtersValueAccessor: (filtersStateSlice) => {
													const ids = nullSafeGet(
														`${SERVICE_CALLS_CALENDAR_TARGET_COLLECTION}.filters.locationIds`,
														filtersStateSlice
													);
													return changeFilterValueToArrayOfIds(ids);
												},
												keyAccessor: (loc) => loc.id,
												valueAccessor: (loc) => loc.id,
												handleChange: (ids) => {
													updateServiceCallsFilters(
														{ locationIds: ids.join(',') || undefined },
														SERVICE_CALLS_CALENDAR_TARGET_COLLECTION
													);
												},
												targetCollectionName: 'workOrdersAdvancedFilters',
												fetch: fetchLocations,
												fetchMultiple: fetchMultipleLocations,
												renderItem: (loc) =>
													`${nullSafeGet('buyerCompany.name', loc)} - ${loc.name}`,
												sortBy: { sort_by: 'name', order: 'ascend' },
												component: OWLocationAsyncTreeSelect,
											},
											{
												label: 'Technician',
												stateSlice: fieldTechs,
												filtersValueAccessor: (filtersStateSlice) =>
													nullSafeGet(
														`${SERVICE_CALLS_CALENDAR_TARGET_COLLECTION}.filters.fieldTechnicianEmail`,
														filtersStateSlice
													),
												keyAccessor: (fieldTech) => fieldTech.email,
												valueAccessor: (fieldTech) => fieldTech.email,
												handleChange: (technicianEmail) => {
													updateServiceCallsFilters(
														{ fieldTechnicianEmail: technicianEmail },
														SERVICE_CALLS_CALENDAR_TARGET_COLLECTION
													);
												},
												targetCollectionName: 'workOrdersAdvancedFilters',
												fetch: fetchFieldTechs,
												fetchMultiple: fetchMultipleFieldTechs,
												renderItem: (fieldTech) => fieldTech.name,
												sortBy: { sort_by: 'name', order: 'ascend' },
											},
											{
												label: 'Priority',
												mode: 'multiple',
												stateSlice: workOrderPriorities,
												filtersValueAccessor: (filtersStateSlice) => {
													const woPriorityIds = nullSafeGet(
														`${SERVICE_CALLS_CALENDAR_TARGET_COLLECTION}.filters.woPriorityIds`,
														filtersStateSlice
													);
													return changeFilterValueToArrayOfIds(woPriorityIds);
												},
												keyAccessor: (workOrderPriority) => workOrderPriority.id,
												valueAccessor: (workOrderPriority) => workOrderPriority.id,
												handleChange: (ids) => {
													updateServiceCallsFilters(
														{
															woPriorityIds: ids.join(',') || undefined,
														},
														SERVICE_CALLS_CALENDAR_TARGET_COLLECTION
													);
												},
												targetCollectionName: 'workOrdersAdvancedFilters',
												fetch: fetchWorkOrderPriorities,
												fetchMultiple: fetchMultipleWorkOrderPriorities,
												renderItem: (workOrderPriority) => workOrderPriority.name,
												component: OWAsyncTreeSelect,
											},
											{
												label: 'Trade',
												mode: 'multiple',
												stateSlice: spendCategories,
												filtersValueAccessor: (filtersStateSlice) => {
													const ids = nullSafeGet(
														`${SERVICE_CALLS_CALENDAR_TARGET_COLLECTION}.filters.spendCategoryIds`,
														filtersStateSlice
													);
													return changeFilterValueToArrayOfIds(ids);
												},
												keyAccessor: (spendCategory) => spendCategory.id,
												valueAccessor: (spendCategory) => spendCategory.id,
												handleChange: (ids) => {
													updateServiceCallsFilters(
														{ spendCategoryIds: ids.join(',') || undefined },
														SERVICE_CALLS_CALENDAR_TARGET_COLLECTION
													);
												},
												targetCollectionName: 'workOrdersAdvancedFilters',
												fetch: fetchSpendCategories,
												fetchMultiple: fetchMultipleSpendCategories,
												renderItem: (spendCategory) => spendCategory.name,
												sortBy: { sort_by: 'name', order: 'ascend' },
												component: OWAsyncTreeSelect,
											},
										]}
										filtersTargetCollectionName={SERVICE_CALLS_CALENDAR_TARGET_COLLECTION}
										clearAndUpdateFilters={this.handleClearAndUpdateFilters}
										filtersStateSlice={serviceCalls}
									/>
								</div>
							}
							right={<div></div>}
						/>
					</Col>
				</Row>
				<Row key={3} style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<Card style={{ minHeight: 560 }}>
							<PaginatedReduxCalendar
								updateQueryParams={true}
								initialDateRange={this.initialDateRange}
								emptyState={
									<EmptyState
										graphic={
											<img
												style={{ marginBottom: 8 }}
												src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
											/>
										}
										headline={"It's a little lonely in here."}
										body={
											<div style={{ textAlign: 'center' }}>
												<div style={{ maxWidth: 440, marginBottom: 16 }}>
													No service calls found.
												</div>
											</div>
										}
									/>
								}
								collectionName="service_calls"
								targetCollectionName={SERVICE_CALLS_CALENDAR_TARGET_COLLECTION}
								fetchData={fetchServiceCallsForTimePeriodForSupplier}
								transformRecords={transformRecords}
								updateFilters={updateServiceCallsFilters}
								EventComponent={EventComponent}
							/>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	location: ownProps.location,

	currentUser: state.session.currentUser,
	workOrders: state.work_orders,
	serviceCalls: state.service_calls,
	locations: state.locations,
	workOrderPriorities: state.work_order_priorities,
	spendCategories: state.spend_categories,
	fieldTechs: state.field_techs,
});

const mapDispatchToProps = (dispatch) => ({
	scheduleServiceCall: (serviceCall) => dispatch(scheduleServiceCallForSupplier(serviceCall)),
	rescheduleServiceCall: (serviceCall) => dispatch(rescheduleServiceCallForSupplier(serviceCall)),
	manualCheckIn: (serviceCall) => dispatch(manualCheckInServiceCallForSupplier(serviceCall)),
	manualCheckOut: (serviceCall) => dispatch(manualCheckOutServiceCallForSupplier(serviceCall)),
	fetchSpendCategories: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			spendCategoriesRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleSpendCategories: (ids, targetCollectionName) =>
		dispatch(spendCategoriesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchMultipleWorkOrderPriorities: (ids, targetCollectionName) =>
		dispatch(workOrderPrioritiesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchWorkOrderPriorities: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			workOrderPrioritiesRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			locationsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleFieldTechs: (ids, targetCollectionName) =>
		dispatch(fieldTechsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchFieldTechs: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			fieldTechsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	updateServiceCallsFilters: (filters, targetCollection) =>
		dispatch(SERVICE_CALLS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	updateServiceCallsSorting: (sortBy, order, targetCollection) =>
		dispatch(SERVICE_CALLS_CRUD_ACTION_CREATORS.updateSorting(sortBy, order, targetCollection)),
	deleteWorkOrder: (entity) =>
		dispatch(workOrdersRestCrudThunksForSupplier.delete(entity, 'id', null, true)),
	archiveWorkOrder: (entity) =>
		dispatch(workOrdersRestCrudThunksForSupplier.archive(entity, 'id', true)),
	acceptWorkOrder: (entity) => dispatch(acceptWorkOrderForSupplier(entity, 'id')),
	declineWorkOrder: (entity) => dispatch(declineWorkOrderForSupplier(entity, 'id')),
	declineWorkOrderPrivate: (entity) => dispatch(declineWorkOrderForPrivateSupplier(entity, 'id')),
	transferWorkOrder: (entity) => dispatch(transferWorkOrderForSupplier(entity, 'id')),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			SERVICE_CALLS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)
		),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SupplierServiceCallCalendarPage)
);

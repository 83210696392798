import * as React from 'react';
import { Popover } from 'antd';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { FC } from 'react';
import { nullSafeGet, nullSafeGetOrElse } from '../../../utils/DataAccessUtils';
import { UserCard } from './UserCard';

interface UserNameComponentProps {
	contact: any;
	userType?: 'buyer' | 'supplier';
	mode?: 'card' | 'inline' | 'container' | 'avatar';
	nameFormat?: string;
	secondaryField?: string;
	showAvatar?: boolean;
	size?: 'default' | 'large';
	allowHover?: boolean;
	popoverMode?: 'simple' | 'full';
	placement?: string;
}

export const UserName: FC<UserNameComponentProps> = ({
	contact,
	userType = 'buyer',
	mode = 'card',
	nameFormat = 'nameGiven nameFamily',
	secondaryField = 'email',
	showAvatar = true,
	size = 'default',
	allowHover = true,
	popoverMode = 'full',
	placement = 'leftTop',
}) => {
	const nameList = nameFormat
		.match(/([a-zA-z])\w+/g)
		.map((name) => [name, nullSafeGetOrElse(name, contact, '')]);
	let fullName = nameFormat.slice();
	nameList.forEach(function (item) {
		let myExp = new RegExp(item[0], 'g');
		fullName = fullName.replace(myExp, item[1]);
	});
	const subTitle = nullSafeGet(secondaryField, contact);

	const onClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const popoverContent = (
		<UserCard
			userType={userType}
			contact={contact}
			name={fullName}
			subTitle={subTitle}
			showAvatar={showAvatar}
			size={size}
			mode={popoverMode}
		/>
	);
	const element =
		mode === 'card' ? (
			<span className="cursor-pointer underline">
				<UserCard
					userType={userType}
					contact={contact}
					name={fullName}
					subTitle={subTitle}
					showAvatar={showAvatar}
					size={size}
				/>
			</span>
		) : mode === 'inline' ? (
			<span className="cursor-pointer underline">{fullName}</span>
		) : mode === 'container' ? (
			<Ellipsis tooltip={false} fullWidthRecognition={true}>
				{fullName}
			</Ellipsis>
		) : mode === 'avatar' ? (
			<span className="cursor-pointer underline">
				<UserCard
					userType={userType}
					contact={contact}
					name={fullName}
					subTitle={subTitle}
					showAvatar={showAvatar}
					size={size}
					mode={mode}
				/>
			</span>
		) : (
			<span></span>
		);

	return allowHover ? (
		<Popover placement={placement} content={popoverContent}>
			{element}
		</Popover>
	) : (
		element
	);
};

import { Button } from 'antd';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { Document, Page } from 'react-pdf';

const OWPdfViewer: FC<any> = ({ file }): React.ReactElement => {
	const [numPages, setNumPages] = useState(0);
	const [pageNumber, setPageNumber] = useState<number>(1);

	const isNextEnabled = useMemo(() => pageNumber < numPages, [numPages, pageNumber]);
	const isPrevEnabled = useMemo(() => pageNumber > 1, [pageNumber]);

	const onNext = useCallback(() => setPageNumber((num) => num + 1), []);
	const onPrev = useCallback(() => setPageNumber((num) => num - 1), []);

	return (
		<div className="flex flex-col items-center justify-center">
			{numPages > 0 && (
				<div className="flex flex-col items-center">
					<div>
						Page {pageNumber} of {numPages}
					</div>
					{numPages > 1 && (
						<div className="flex flex-row">
							<Button disabled={!isPrevEnabled} type="link" onClick={onPrev}>
								Prev
							</Button>
							<Button disabled={!isNextEnabled} type="link" onClick={onNext} className="ml-1">
								Next
							</Button>
						</div>
					)}
				</div>
			)}
			<Document file={file} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
				<div className="border-2 border-solid border-slate-50">
					<Page width={1000} pageNumber={pageNumber} />
				</div>
			</Document>
		</div>
	);
};

export default OWPdfViewer;

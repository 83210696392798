import { DownOutlined, LeftOutlined, RightOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Tooltip, message } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import './index.less';
import posthog from 'posthog-js';

const recordstoNumberedMap = (map, state) => {
	const newMap = {};
	const pagination = state.pagination;
	const startingIndex = pagination.pageSize * (pagination.current - 1);
	const ids = state.recordsIds;

	ids.map((element, index) => {
		newMap[startingIndex + index] = element;
	});

	return { ...map, ...newMap };
};

function NextPreviousButton({
	activeId,
	getEnitityUrl,
	stateSlice,
	targetCollectionName,
	location,
	history,
	updatePagination,
	cloneTargetCollection,
	entityName,
}) {
	const targetCollection = 'FETCH_DATA_FROM_SERVER_widget_tempNextPrevCollection';

	const [loading, setLoading] = React.useState(false);
	const [idMap, setIdMap] = React.useState(
		recordstoNumberedMap({}, stateSlice[targetCollectionName])
	);
	const indexInCurrentSlice = stateSlice[targetCollectionName].recordsIds.findIndex(
		(item) => item === activeId
	);
	const total = nullSafeGet(`${targetCollectionName}.count`, stateSlice);
	const pagination = stateSlice[targetCollectionName].pagination;
	const [currentIndex, setCurrentIndex] = React.useState(
		pagination.pageSize * (pagination.current - 1) + indexInCurrentSlice
	);

	const navigate = useCallback(
		(delta) => {
			const index = currentIndex + delta;
			const pagination = stateSlice[targetCollection].pagination;
			const fetching = stateSlice[targetCollection].fetching;

			const nextId = idMap[index];
			if (nextId) {
				const url = getEnitityUrl(nextId);
				const fullUrl = `${url}${location.search}`;
				history.push(fullUrl);
			} else {
				setLoading(true);
				updatePagination(
					{ current: Math.ceil((index + 1) / pagination.pageSize), pageSize: pagination.pageSize },
					targetCollection
				).then((data) => {
					if (data && data.length) {
						setIdMap(
							recordstoNumberedMap(idMap, {
								recordsIds: data.map((item) => item.id),
								pagination: {
									current: Math.ceil((index + 1) / pagination.pageSize),
									pageSize: pagination.pageSize,
								},
							})
						);
						const url = getEnitityUrl(data[0].id);
						const fullUrl = `${url}${location.search}`;
						history.push(fullUrl);
						setLoading(false);
					} else {
						setLoading(false);
						setCurrentIndex(currentIndex);
						message.info('Reached the end of list!');
						return;
					}
				});
			}
			if (nextId && index > 2 && index + 2 < total && !idMap[index + delta * 2] && !fetching) {
				updatePagination(
					{
						current: Math.ceil((index + 1) / pagination.pageSize) + delta,
						pageSize: pagination.pageSize,
					},
					targetCollection
				).then((data) => {
					setIdMap(
						recordstoNumberedMap(idMap, {
							recordsIds: data.map((item) => item.id),
							pagination: {
								current: Math.ceil((index + 1) / pagination.pageSize) + delta,
								pageSize: pagination.pageSize,
							},
						})
					);
					setLoading(false);
				});
			}
			setCurrentIndex(index);
		},
		[currentIndex, idMap, total, updatePagination, location, history, stateSlice, getEnitityUrl]
	);

	const onNext = useCallback(() => {
		navigate(1);
	}, [navigate]);

	const onPrevious = useCallback(() => {
		navigate(-1);
	}, [navigate]);

	const handleKeyDown = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === 'j' && currentIndex + 1 < total) {
				onNext();
			} else if (event.key === 'k' && currentIndex > 0) {
				onPrevious();
			}
		},
		[onNext, onPrevious, currentIndex]
	);

	useEffect(() => {
		// document.addEventListener('keydown', handleKeyDown);
		// return () => {
		// 	document.removeEventListener('keydown', handleKeyDown);
		// };
	}, [handleKeyDown]);

	useEffect(() => {
		// copy targetCollectionName to different targetcollectionName
		if (stateSlice[targetCollectionName] && stateSlice[targetCollectionName].recordsIds.length) {
			cloneTargetCollection && cloneTargetCollection(targetCollectionName, targetCollection);
		}
		return () => {
			// cloneTargetCollection && cloneTargetCollection(targetCollection, targetCollectionName);
		};
	}, []);
	return (
		<div
			className="flex flex-row items-center text-gray-300"
			style={{ marginRight: 16, marginBottom: 8 }}
		>
			<span className=" text-white">{currentIndex + 1} </span>
			{total && <span className="ml-1 mr-2 inline-block "> of {total}</span>}

			<Tooltip title={`Previous ${entityName}`}>
				<Button
					className="nextPreviousButton ml-2 mr-2"
					icon={<LeftOutlined translate="" />}
					onClick={onPrevious}
					disabled={currentIndex <= 0}
					loading={loading}
					ghost
					size="small"
				/>
			</Tooltip>
			<Tooltip title={`Next ${entityName}`}>
				<Button
					className="nextPreviousButton"
					loading={loading}
					icon={<RightOutlined translate="" />}
					onClick={onNext}
					ghost
					disabled={total && currentIndex + 1 >= total}
					size="small"
				/>
			</Tooltip>
		</div>
	);
}

function NextPrevious(props) {
	const { stateSlice, targetCollectionName, activeId } = props;
	if (
		!stateSlice[targetCollectionName] ||
		!stateSlice[targetCollectionName].recordsIds.length ||
		stateSlice[targetCollectionName].recordsIds.length < 2 ||
		!activeId
	) {
		return null;
	}

	return <NextPreviousButton key={targetCollectionName} {...props} />;
}

export default React.memo(NextPrevious);

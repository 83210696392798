import * as React from 'react';
import { Layout, Button, message, Popover, Dropdown, Menu } from 'antd';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';
import SubnavTabs from '../subnav_tabs/SubnavTabs';
import { assetsRestCrudThunksForSupplier } from '../../thunks/assets_thunks';
import {
	getProtectedImageUriForSupplier,
	getProtectedImageUriFromString,
} from '../../utils/FileAccessUtils';
import { Link } from 'react-router-dom';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { ROLE_TYPES } from '../../utils/DataConstants';
import Carousel, { Modal as Lightbox, ModalGateway } from 'react-images';
import TransferToStockLocationPopup from './TransferToStockLocationPopup';
import { clearNavbarState, updateNavbarState } from '../../actions/navbar_actions';
import {
	generateBackLink,
	getBackLinkStates,
	getLinkWIthBackLinkParams,
} from '../../utils/HistoryUtils';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import TransferAssetToLocationForm from './TransferAssetToLocationForm';

const { Content } = Layout;

require('./SupplierAssetsDetailPage.less');

const DEFAULT_BACK_LINK = '/supplier/assets/overview/all';
const DEFAULT_BACK_TEXT = 'Back to assets';

class SupplierAssetsDetailPage extends React.Component<any, any> {
	formRefs: any;

	constructor(props) {
		super(props);
		this.formRefs = {
			moveToEquipmentForm: null,
		};
		this.state = {
			lightboxIsOpen: false,
			currentIndex: 0,
			manualsIncluded: false,
			moveToSLFormVisible: false,
			moveToLocationFormVisible: false,
		};
	}

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	handleViewChange = (idx) => {
		this.setState({ currentIndex: idx });
	};

	openLightbox = (currentIndex = 0) => {
		this.setState({ lightboxIsOpen: true, currentIndex });
	};

	closeLightbox = () => {
		this.setState({ lightboxIsOpen: false });
	};

	componentWillUnmount(): void {
		this.props.clearNavbar();
	}

	populateNavBar = () => {
		const { location, updateNavbar } = this.props;
		const { backLinkText = DEFAULT_BACK_TEXT, backLinkTo } = getBackLinkStates(
			location,
			DEFAULT_BACK_LINK
		) as any;
		updateNavbar(
			backLinkText || DEFAULT_BACK_TEXT,
			backLinkTo || generateBackLink(DEFAULT_BACK_LINK, location)
		);
	};

	componentDidMount() {
		const { getAsset, match } = this.props;
		const assetId = match.params.id;
		this.populateNavBar();
		getAsset(assetId).then((asset) => {
			if (nullSafeGet('assetModel.manuals', asset)) {
				this.setState({ manualsIncluded: true });
			} else {
				this.setState({ manualsIncluded: false });
			}
		});
	}

	handleTabChange = (key) => {
		const { history, match } = this.props;
		const assetId = match.params.id;
		history.push(`/supplier/assets/detail/${assetId}/${key}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	handleDelete = () => {
		const { history, deleteAsset, asset } = this.props;
		deleteAsset(asset).then(() => {
			history.push('/supplier/assets/overview/all');
		});
	};

	hideMoveToSLForm = () => this.setState({ moveToSLFormVisible: false });
	showMoveToSLForm = () => this.setState({ moveToSLFormVisible: true });

	showTransferForm = () => this.setState({ moveToLocationFormVisible: true });
	hideTransferForm = () => this.setState({ moveToLocationFormVisible: false });

	onTransferToLocationSuccess = () => {
		const { getAsset, match } = this.props;
		const assetId = match.params.id;
		this.hideTransferForm();
		getAsset(assetId);
	};

	onEquipmentPerStockLocationSuccess = (epsl) => {
		const { history } = this.props;
		const path = `/supplier/equipments/equipmentPerStockLocation/detail/${nullSafeGet(
			'id',
			epsl
		)}/details`;
		history.push(path);
	};

	moveToEquipment = () => {
		const { moveAssetToEquipmentPerStockLocation, asset, currentUser } = this.props;
		const form = this.formRefs['moveToEquipmentForm'].props.form;

		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			const equipmentPerStockLocation = {
				...values,
				cost: parseFloat(values.cost),
				name: values.name,
				images: values.equipmentType.images,
				stockLocationId: values.stockLocationId,
				supplierFacilityId: nullSafeGet('facility.id', currentUser),
				supplierCompanyId: nullSafeGet('facility.supplierCompanyId', currentUser),
			};

			moveAssetToEquipmentPerStockLocation(equipmentPerStockLocation, asset.id)
				.then((record) => {
					form.resetFields();
					this.onEquipmentPerStockLocationSuccess(record);
					message.success(`Moved to stock location.`);
				})
				.finally(() => this.hideMoveToSLForm());
		});
	};

	handleCopy = () => {
		const { match, history, userType, location } = this.props;
		const assetId = match.params.id;
		history.push(
			getLinkWIthBackLinkParams(
				location,
				'Back to asset',
				`/${userType}/assets/overview/new?copyFromAssetId=${assetId}`
			)
		);
	};

	render() {
		const { asset, assetsFetching, match } = this.props;

		const assetId = match.params.id;
		let tabs = [
			{
				key: 'details',
				name: 'Overview',
			},
			{
				key: 'history',
				name: 'Transfer History',
			},
			{
				key: 'downtimeHistory',
				name: 'Downtime History',
			},
			{
				key: 'depreciationJournal',
				name: 'Depreciation Journal',
				accessPermissionName: PERMISSION_NAMES.SHOW_ASSET_PRICE,
			},
			{
				key: 'depreciationModels',
				name: 'Depreciation Models',
				accessPermissionName: PERMISSION_NAMES.SHOW_ASSET_PRICE,
			},
			{
				key: 'audit',
				name: 'Audit Logs',
			},
		];
		if (this.state.manualsIncluded) {
			tabs.push({ key: 'manuals', name: 'Manuals' });
		}
		let photoUris = [];
		let lightboxPhotoUris = [];
		if (asset.images) {
			photoUris = asset.images.slice(0, 4).map((assetImg) => {
				const [imgFileId, imgFileName] = assetImg.split('/');
				return getProtectedImageUriForSupplier(imgFileId, imgFileName, 1200, 800);
			});
			lightboxPhotoUris = asset.images.map((photoString) => ({
				src: getProtectedImageUriFromString(ROLE_TYPES.SUPPLIER)(photoString, 2400, 1600),
			}));
		}
		return (
			<div>
				<Route
					path="/supplier/assets/detail/:id"
					exact={true}
					render={() => (
						<Redirect
							to={{
								pathname: `/supplier/assets/detail/${assetId}/details`,
							}}
						/>
					)}
				/>
				<Content className="supplierAssetsDetailPage">
					{/*<ScrollToTopOnMount/>*/}
					{/*<BackTop/>*/}
					<ModalGateway>
						{this.state.lightboxIsOpen ? (
							<Lightbox onClose={this.closeLightbox}>
								<Carousel
									trackProps={{
										onViewChange: this.handleViewChange,
									}}
									views={lightboxPhotoUris}
									currentIndex={this.state.currentIndex}
								/>
							</Lightbox>
						) : null}
					</ModalGateway>

					<div className="imageLayout">
						{photoUris.map((photoUri, idx) => (
							<div
								key={idx}
								className={`imageTile imageTile${idx}`}
								style={{ backgroundImage: `url(${photoUri})` }}
							/>
						))}
						<div
							className={`imageLayout__gradientOverlay ${
								asset.isActive ? '' : 'imageLayout__gradientOverlay--darker'
							}`}
						>
							{!asset.isActive ? <div className="assetInactiveLabel">Inactive</div> : null}
							<div className="supplierAssetsDetailPage__overtitle">
								{nullSafeGet('assetType.name', asset)}
							</div>
							<h4 className="supplierAssetsDetailPage__title">
								{assetsFetching ? null : asset.name}
							</h4>
							<SubnavTabs
								tabs={tabs}
								onChange={this.handleTabChange}
								isActive={this.checkTabActive}
							/>
							<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_ASSETS}>
								<div style={{ position: 'absolute', top: 40, right: 40 }}>
									<Link to={`/supplier/assets/edit/${assetId}`} style={{ marginRight: 16 }}>
										<Button ghost={true}>Edit</Button>
									</Link>
									<Button ghost={true} onClick={this.handleDelete} style={{ marginRight: 16 }}>
										Delete
									</Button>
									<Button ghost={true} onClick={this.handleCopy} style={{ marginRight: 16 }}>
										Copy and create new
									</Button>
									<Popover
										content={!asset.isActive ? 'Inactive assets cannot be transferred' : ''}
										trigger="hover"
									>
										<Dropdown
											overlay={
												<Menu>
													<Menu.Item onClick={this.showMoveToSLForm}>To Stock Location</Menu.Item>
													<Menu.Item onClick={this.showTransferForm}>To Location</Menu.Item>
												</Menu>
											}
										>
											<Button ghost={true} disabled={!asset.isActive || assetsFetching}>
												Transfer
											</Button>
										</Dropdown>
									</Popover>
								</div>
							</AccessPermissionChecker>
							<div style={{ position: 'absolute', bottom: 40, right: 40 }}>
								{lightboxPhotoUris.length > 0 ? (
									<Button
										style={{ color: 'white' }}
										type="ghost"
										onClick={() => this.openLightbox()}
									>
										View Photos
									</Button>
								) : null}
							</div>
						</div>
					</div>
					{this.props.childRoutes.map((r, i) => (
						<RouteWithSubRoutes key={i} {...r} />
					))}
					{this.state.moveToSLFormVisible && (
						<TransferToStockLocationPopup
							onCancel={this.hideMoveToSLForm}
							onSuccess={this.onEquipmentPerStockLocationSuccess}
						/>
					)}
					{this.state.moveToLocationFormVisible ? (
						<TransferAssetToLocationForm
							asset={asset}
							onCancel={this.hideTransferForm}
							onSuccess={this.onTransferToLocationSuccess}
						/>
					) : null}
				</Content>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	assetsFetching: state.assets.fetching,
	asset: state.assets.detail,
	userType: state.session.userType,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	getAsset: (id) => dispatch(assetsRestCrudThunksForSupplier.readOne(id)),
	deleteAsset: (entity) =>
		dispatch(assetsRestCrudThunksForSupplier.delete(entity, 'id', null, true)),
	updateNavbar: (backlinkTitle, backlinkTo, navbarBackgroundColor) =>
		dispatch(updateNavbarState(backlinkTitle, backlinkTo, navbarBackgroundColor)),
	clearNavbar: () => dispatch(clearNavbarState()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SupplierAssetsDetailPage));

import * as React from 'react';
import { Button, Popover, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { partsRestCrudThunksForSupplier } from '../../thunks/parts_thunks';
import { Link } from 'react-router-dom';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import { getBackLinkStates } from '../../utils/HistoryUtils';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { CONFIG_NAMES, PERMISSION_NAMES } from '../../utils/AuthUtils';
import { isSupplierInventoryConfigEnabled } from '../common/SupplierInventoryConfigPermissionChecker';

const DEFAULT_BACK_LINK = '/supplier/parts';

class PartsDetailPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			lightboxIsOpen: false,
			currentIndex: 0,
			backLinkTo: DEFAULT_BACK_LINK,
			backLinkText: 'Back to parts',
		};
	}

	goToPrevious = () => {
		this.setState({ currentIndex: this.state.currentIndex - 1 });
	};

	goToNext = () => {
		this.setState({ currentIndex: this.state.currentIndex + 1 });
	};

	componentDidMount() {
		const { getPart, match, location } = this.props;
		const partId = match.params.id;
		this.setState(getBackLinkStates(location, DEFAULT_BACK_LINK));
		getPart(partId);
	}

	handleTabChange = (key) => {
		const { history, match } = this.props;
		const partId = match.params.id;
		history.push(`/supplier/parts/detail/${partId}/${key}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	handleDelete = () => {
		const { history, deletePart, part } = this.props;
		deletePart(part)
			.then(() => {
				history.push('/supplier/parts/overview/all');
			})
			.catch((err) => message.error(err));
	};

	render() {
		const { part, partsFetching, match, companyConfig } = this.props;
		const partId = match.params.id;
		const poDisabled = isSupplierInventoryConfigEnabled(
			companyConfig,
			CONFIG_NAMES.DISABLE_PURCHASE_ORDERS
		);

		const tabs = [
			{
				key: 'details',
				name: 'Overview',
			},
			{
				key: 'prices',
				name: 'Price History',
			},
			{
				key: 'purchaseRequests',
				name: 'Purchase Requests',
			},
			...(!poDisabled
				? [
						{
							key: 'purchaseOrders',
							name: 'Purchase Orders',
						},
				  ]
				: []),
			{
				key: 'workOrders',
				name: 'Work Orders',
			},
		];
		return (
			<DetailPageHeader
				exactPath={'/supplier/parts/detail/:id'}
				redirectPath={`/supplier/parts/detail/${partId}/details`}
				backLinkText={this.state.backLinkText}
				backLinkTo={this.state.backLinkTo}
				title={
					partsFetching ? null : (
						<div>
							<div>{part.name}</div>
							{!part.isActive ? <div className="text-sm">Inactive</div> : null}
						</div>
					)
				}
				subtitle={null}
				actions={[
					<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PARTS_AND_EQUIPMENTS}>
						<Link to={`/supplier/parts/edit/${partId}`} key={1} style={{ marginRight: 16 }}>
							<Button ghost={true}>Edit</Button>
						</Link>
					</AccessPermissionChecker>,
					<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PARTS_AND_EQUIPMENTS}>
						<Popover
							content={
								part.isAssociatedWithOtherEntities
									? `Part cannot be deleted as it is asssociated with other entities!`
									: ''
							}
							trigger="hover"
						>
							<Button
								key={2}
								ghost={true}
								onClick={this.handleDelete}
								disabled={partsFetching || part.isAssociatedWithOtherEntities}
							>
								Delete
							</Button>
						</Popover>
					</AccessPermissionChecker>,
				]}
				tabs={tabs}
				backgroundColor={nullSafeGet('config.theme.navblock', companyConfig)}
				handleTabChange={this.handleTabChange}
				checkTabActive={this.checkTabActive}
				childRoutes={this.props.childRoutes}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	partsFetching: state.parts.fetching,
	part: state.parts.detail,
	match: ownProps.match,
	history: ownProps.history,
	companyConfig: state.company_config.detail,
	location: ownProps.location,
});

const mapDispatchToProps = (dispatch) => ({
	getPart: (id) => dispatch(partsRestCrudThunksForSupplier.readOne(id)),
	deletePart: (entity) => dispatch(partsRestCrudThunksForSupplier.delete(entity, 'id', null, true)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PartsDetailPage));

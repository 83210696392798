import * as React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Layout, Card, Row, Col, Button, message, Tag } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { metersRestCrudThunksForBuyer } from '../../thunks/meters_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import moment from 'moment';
import SubnavBar from '../subnav_bar/SubnavBar';
import MeterReadingModalForm from '../meter_reading_modal_form/MeterReadingModalForm';
import { meterReadingsRestCrudThunksForBuyer } from '../../thunks/meter_readings_thunks';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { EmptyState } from '../empty_state/EmptyState';
import { LineChart } from '../charts/LineChart';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import DateTimeHover from '../date_time_component/DateTime';

const { Content } = Layout;

const style = require('./MetersDetailDetailsPage.less');
const meterReadingsLineChartConfig = {
	xAxisName: 'recordedAt',
	yAxisName: 'value',
	position: 'recordedAt*value',
	fillArea: false,
	cols: {
		recordedAt: { alias: 'Reading Time', type: 'time', mask: 'M/DD h:mm a' },
		value: { alias: 'Meter Value' },
	},
	yAxisLabel: {
		// formatter: val => `$${val.toLocaleString('en')}`,
		textStyle: {
			fontSize: '14',
			fontFamily: 'Roboto',
			fontWeight: 400,
			fill: 'rgba(0,0,0,0.65)',
		},
	},
	xAxisLabel: {
		textStyle: {
			fontSize: '14',
			fontFamily: 'Roboto',
			fontWeight: 400,
			fill: 'rgba(0,0,0,0.65)',
		},
	},
};

class MetersDetailPage extends React.Component<any, any> {
	formRefs: any = {};
	state = {
		meterReadingFormData: {},
		meterReadingModalFormVisible: false,
		editForm: false,
	};

	componentDidMount() {
		const {
			getMeter,

			id,
			match,
		} = this.props;
		const meterId = match.params.meterId || id;
		getMeter(meterId);
	}

	componentWillReceiveProps(nextProps) {
		const { getMeter } = nextProps;
		if (nextProps.id && this.props.id !== nextProps.id) {
			getMeter(nextProps.id);
		}
	}

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	handleMeterReadingModalFormEdit = (meterReading) => {
		this.setState({
			meterReadingModalFormVisible: true,
			meterReadingFormData: meterReading,
		});
	};

	handleMeterReadingModalFormOpen = () => {
		const { meter } = this.props;
		this.setState({
			editForm: false,
			meterReadingModalFormVisible: true,
			meterReadingFormData: { meterId: meter.id },
		});
	};

	handleMeterReadingModalFormClose = () => {
		const { meter } = this.props;
		const form = this.formRefs['meterReadingForm'].props.form;
		form.resetFields();
		this.setState({
			editForm: false,
			meterReadingModalFormVisible: false,
			meterReadingFormData: {},
		});
	};

	handleMeterReadingModalFormSubmit = () => {
		const { createMeterReading, match, getMeter } = this.props;
		const form = this.formRefs['meterReadingForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}
			createMeterReading(values, 'id', 'METER_ASSOCIATED_METER_READINGS').then(() => {
				this.setState({ meterReadingModalFormVisible: false, editForm: false });
				message.success(`Meter reading recorded.`);
				form.resetFields();
			});
		});
	};

	handleMeterReadingModalFormEditSubmit = () => {
		const { updateMeterReading, match, getMeter } = this.props;
		const form = this.formRefs['meterReadingForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}
			updateMeterReading(values, 'id', 'METER_ASSOCIATED_METER_READINGS').then(() => {
				this.setState({ meterReadingModalFormVisible: false, editForm: false });
				message.success(`Meter reading recorded.`);
				form.resetFields();
			});
		});
	};

	editMeterReadings = (meterReading) => (e) => {
		this.setState({ editForm: true });
		this.handleMeterReadingModalFormEdit(meterReading);
	};

	deleteMeterReadings = (meterReading) => (e) => {
		const { deleteMeterReading } = this.props;
		deleteMeterReading(meterReading);
	};

	render() {
		const {
			meter,
			meterReadings,
			metersFetching,

			history,
			match,
		} = this.props;

		const pointMeterReadingsColumns = [
			{
				title: 'Reading',
				dataIndex: 'value',
				render: (text, record) => (
					<div>
						<span>{text + ' ' + meter.valueUnit}</span>
						{record.isBaseline ? (
							<Tag style={{ marginLeft: 8 }} color="purple">
								Baseline
							</Tag>
						) : null}
					</div>
				),
			},
			{
				title: 'Recorded At',
				dataIndex: 'recordedAt',
				defaultSortOrder: 'desc',
				render: (date, record) => (date ? <DateTimeHover timestamp={date} showDate={true} /> : ''),
			},
		];

		const baselineMeterReadingsColumns = [
			{
				title: 'Reading',
				dataIndex: 'value',
				render: (text, record) => (
					<div>
						<span>{text + ' ' + meter.valueUnit}</span>
						{record.isBaseline ? (
							<Tag style={{ marginLeft: 8 }} color="purple">
								Baseline
							</Tag>
						) : null}
					</div>
				),
			},
			{
				title: 'Last Baseline Reading',
				dataIndex: 'lastBaselineValue',
				render: (text, record) => (
					<div>
						<span>{text ? text + ' ' + meter.valueUnit : '--'}</span>
					</div>
				),
			},
			{
				title: 'Recorded At',
				dataIndex: 'recordedAt',
				defaultSortOrder: 'desc',
				render: (date, record) => (date ? <DateTimeHover timestamp={date} showDate={true} /> : ''),
			},
		];

		const comparableMeterReadingsColumns = [
			{
				title: 'Reading',
				dataIndex: 'value',
				render: (text, record) => (
					<div>
						<span>{text + ' ' + meter.valueUnit}</span>
						{record.isBaseline ? (
							<Tag style={{ marginLeft: 8 }} color="purple">
								Baseline
							</Tag>
						) : null}
					</div>
				),
			},
			{
				title: 'Comparison Reading',
				dataIndex: 'expectedValue',
				render: (text, record) => (
					<div>
						<span>{text + ' ' + meter.valueUnit}</span>
					</div>
				),
			},
			{
				title: 'Recorded At',
				dataIndex: 'recordedAt',
				defaultSortOrder: 'desc',
				render: (date, record) => (date ? <DateTimeHover timestamp={date} showDate={true} /> : ''),
			},
		];

		const editDeleteMeterReadingsColumns = [
			{
				title: '',
				dataIndex: 'id',
				render: (text, record) => (
					<div>
						<Button onClick={this.editMeterReadings(record)} style={{ marginRight: 10 }}>
							Edit
						</Button>
						<Button onClick={this.deleteMeterReadings(record)}>Delete</Button>
					</div>
				),
			},
		];

		const meterReadingsColumns = (
			meter.seriesType === 'comparable'
				? comparableMeterReadingsColumns
				: meter.seriesType === 'baseline'
				? baselineMeterReadingsColumns
				: pointMeterReadingsColumns
		).concat(editDeleteMeterReadingsColumns);

		const meterReadingData = getRecordsForTargetCollection(
			meterReadings,
			'METER_ASSOCIATED_METER_READINGS'
		);

		return metersFetching ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="metersDetailDetailsPage">
				<MeterReadingModalForm
					isEdit={this.state.editForm}
					meter={meter}
					formData={this.state.meterReadingFormData}
					wrappedComponentRef={this.saveFormRef('meterReadingForm')}
					visible={this.state.meterReadingModalFormVisible}
					onCancel={this.handleMeterReadingModalFormClose}
					onSubmit={
						this.state.editForm
							? this.handleMeterReadingModalFormEditSubmit
							: this.handleMeterReadingModalFormSubmit
					}
				/>
				<LogOnMountWithStandardEventProperties eventType="visited buyer meter detail page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}

				{this.props.id ? <h5>{meter.name}</h5> : null}
				<Row style={{ margin: '16px 12px' }}>
					<Col span={24}>
						<SubnavBar
							left={<div></div>}
							right={
								<Button size="large" type="primary" onClick={this.handleMeterReadingModalFormOpen}>
									Add Reading
								</Button>
							}
						/>
					</Col>
				</Row>
				<Row style={{ margin: '16px 12px' }}>
					<Col span={24}>
						<Card bordered={false}>
							{meter.valueType === 'number' && meterReadingData && meterReadingData.length > 0 ? (
								<LineChart
									height={260}
									chartPadding={[40, 40, 40, 80]}
									data={meterReadingData.map((el) => {
										return { ...el, value: parseInt(el.value) };
									})}
									xAxisName={nullSafeGet('xAxisName', meterReadingsLineChartConfig)}
									yAxisName={nullSafeGet('yAxisName', meterReadingsLineChartConfig)}
									yAxisLabel={nullSafeGet('yAxisLabel', meterReadingsLineChartConfig)}
									fillArea={nullSafeGet('fillArea', meterReadingsLineChartConfig)}
									cols={nullSafeGet('cols', meterReadingsLineChartConfig)}
									// color={nullSafeGet('color', meterReadingsLineChartConfig)}
									position={nullSafeGet('position', meterReadingsLineChartConfig)}
								/>
							) : null}
							<PaginatedReduxTable
								showHeader={true}
								emptyState={
									<EmptyState
										graphic={
											<img
												style={{ marginBottom: 8 }}
												src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
											/>
										}
										headline={'No news is good news.'}
										body={
											<div style={{ textAlign: 'center' }}>
												<div style={{ maxWidth: 440, marginBottom: 16 }}>
													There aren't any readings for this meter.
												</div>
											</div>
										}
									/>
								}
								collectionName="meter_readings"
								targetCollectionName={'METER_ASSOCIATED_METER_READINGS'}
								columns={meterReadingsColumns}
								keyAccessor={(el) => el.id}
								initialFilters={{ meterId: meter.id }}
								initialPagination={null}
								fetchData={meterReadingsRestCrudThunksForBuyer.read}
							/>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	metersFetching: state.meters.fetching,
	meter: state.meters.detail,
	meterReadings: state.meter_readings,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	id: ownProps.id,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	getMeter: (id) => dispatch(metersRestCrudThunksForBuyer.readOne(id)),
	createMeterReading: (meterReading, primaryKey, targetCollectionName) =>
		dispatch(
			meterReadingsRestCrudThunksForBuyer.create(meterReading, primaryKey, targetCollectionName)
		),
	getMeterReading: (id) => dispatch(meterReadingsRestCrudThunksForBuyer.readOne(id)),
	updateMeterReading: (meterReading) =>
		dispatch(meterReadingsRestCrudThunksForBuyer.update(meterReading)),
	deleteMeterReading: (meterReading) =>
		dispatch(meterReadingsRestCrudThunksForBuyer.delete(meterReading, 'id', null, true)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MetersDetailPage));

import { UPDATE_NAVBAR_STATE, CLEAR_NAVBAR_STATE } from '../actions/navbar_actions';

const messagesReducer = (
	oldState = { backlinkTitle: null, backlinkTo: null, navbarBackgroundColor: null },
	action
) => {
	Object.freeze(oldState);
	switch (action.type) {
		case UPDATE_NAVBAR_STATE:
			return {
				...oldState,
				backlinkTitle: action.backlinkTitle,
				backlinkTo: action.backlinkTo,
				navbarBackgroundColor: action.navbarBackgroundColor,
			};
		case CLEAR_NAVBAR_STATE:
			return {
				...oldState,
				backlinkTitle: null,
				backlinkTo: null,
				navbarBackgroundColor: null,
			};
		default:
			return oldState;
	}
};

export default messagesReducer;

import { Button, DatePicker, Form, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { FC, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { DEPRECIATION_METHODS } from '../../utils/DataConstants';
import DepreciationClassCommonFormItems from '../depreciation_pages/DepreciationClassCommonFormItems';

const FORM_NAME = 'depreciation-class-form';

const DepreciationModelsPlaygroundModal: FC<any> = ({
	depreciationClass,
	onCancel,
	handleSubmit,
}): React.ReactElement => {
	const [form] = useForm();

	const isUpdate = useMemo(() => !!depreciationClass, [depreciationClass]);

	const initialValues = useMemo(
		() => ({
			tillDate: nullSafeGet('tillDate', depreciationClass),
			depreciationMethod: nullSafeGet('depreciationMethod', depreciationClass),
			depreciationRate: parseFloat(nullSafeGetOrElse('depreciationRate', depreciationClass, 0)),
			depreciationPeriod: 'monthly',
			usefulLifeInMonths: nullSafeGet('usefulLifeInMonths', depreciationClass),
			salvageValue: parseFloat(nullSafeGetOrElse('salvageValue', depreciationClass, 0)),
		}),
		[depreciationClass]
	);

	const getValueForKey = useCallback(
		(key, values, method, toInt = false) => ({
			[key]:
				values.depreciationMethod === method
					? toInt
						? parseInt(values[key])
						: `${values[key]}`
					: undefined,
		}),
		[]
	);

	const getEntityFromValues = useCallback(
		(values) => ({
			tillDate: values.tillDate,
			depreciationMethod: values.depreciationMethod,
			...getValueForKey('depreciationRate', values, DEPRECIATION_METHODS.DECLINING),
			...getValueForKey('depreciationPeriod', values, DEPRECIATION_METHODS.DECLINING),
			...getValueForKey('depreciationPeriod', values, DEPRECIATION_METHODS.STRAIGHT_LINE),
			...getValueForKey('usefulLifeInMonths', values, DEPRECIATION_METHODS.STRAIGHT_LINE, true),
			...getValueForKey('salvageValue', values, DEPRECIATION_METHODS.STRAIGHT_LINE),
		}),
		[getValueForKey]
	);

	const onSubmit = useCallback(
		(values) => {
			handleSubmit({
				...(depreciationClass || {}),
				...getEntityFromValues(values),
			});
		},
		[depreciationClass, getEntityFromValues, handleSubmit]
	);

	return (
		<Modal
			visible={true}
			width={600}
			title={`Update Projection details`}
			closable
			onCancel={onCancel}
			forceRender
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button
					type="primary"
					size="large"
					style={{ marginLeft: '16px' }}
					key="submit"
					htmlType="submit"
					form={FORM_NAME}
				>
					{isUpdate ? 'Update' : 'Save'}
				</Button>,
			]}
		>
			<Form
				form={form}
				id={FORM_NAME}
				layout="vertical"
				requiredMark={false}
				initialValues={initialValues}
				onFinish={onSubmit}
			>
				<Form.Item
					name="tillDate"
					label="Project till Date"
					rules={[
						{
							required: true,
							message: `Please select a date`,
						},
					]}
				>
					<DatePicker />
				</Form.Item>
				<DepreciationClassCommonFormItems form={form} />
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state) => ({});

export default withRouter<any, any>(connect(mapStateToProps)(DepreciationModelsPlaygroundModal));

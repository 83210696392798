import * as React from 'react';
import { connect } from 'react-redux';
import { Layout, Row, Col, Button, Card, message, Table, Select, Input } from 'antd';
import { EmptyState } from '../empty_state/EmptyState';
import { floatToPercentageString } from '../../utils/DataFormatterUtils';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { fieldTechsRestCrudThunksForSupplier } from '../../thunks/field_techs_thunks';
import { FieldTechRowDisplay } from '../field_tech_row_display/FieldTechRowDisplay';
import { fetchAllTechKPIs, fetchAllTechKPITotals } from '../../thunks/tech_kpi_analytics_thunks';
import moment from 'moment';
import { SUPPLIER_ADMIN_ONLY } from '../roles/roles';
import { supplierSignUpInvite } from '../../thunks/supplier_contacts_thunks';
import SupplierNewUserInviteForm from '../supplier_new_user_invite_form/SupplierNewUserInviteForm';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import {
	clearTechKPIFilters,
	updateTechKPIFilters,
} from '../../actions/tech_kpi_analytics_actions';
import { debounce } from '../../utils/PerformanceUtils';
import { CSV_EXPORT_DEFAULTS } from '../../utils/DataConstants';
import { ExportToCsv } from 'export-to-csv';
import { CloudDownloadOutlined, DownloadOutlined } from '@ant-design/icons';

const isEqual = require('fast-deep-equal');
const { Content } = Layout;

class SupplierFieldTechsAnalyticsOverviewPage extends React.Component<any, any> {
	formRefs: any = {};

	state = {
		modalVisible: false,
		scorecardsData: [],
		scorecardsDataLoading: true,
		month: moment().month() == 0 ? 12 : moment().month(),
		year: moment().month() == 0 ? moment().year() - 1 : moment().year(),
		startDate: moment().startOf('week').format('YYYY-MM-DD'),
		endDate: moment().format('YYYY-MM-DD'),
		selectionValue: '1',
		pagination: { offset: 0, limit: 10 },
	};

	monthText = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	constructor(props) {
		super(props);
		this.handleSupplierUserInviteCancel = this.handleSupplierUserInviteCancel.bind(this);
		this.handleSupplierUserInviteSubmit = this.handleSupplierUserInviteSubmit.bind(this);
		this.showModal = this.showModal.bind(this);
		this.formRefs = {
			userInviteForm: null,
		};
	}

	showModal(e) {
		this.setState({
			modalVisible: true,
		});
	}

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	handleSupplierUserInviteSubmit(values) {
		const { inviteNewUser } = this.props;
		const contact = {
			...values,
			nameFamily: values.nameFamily || '',
			roles: [values.roles],
		};

		inviteNewUser(contact).then(() => {
			this.setState({ visible: false });
			message.success(`Invite sent to ${contact.nameGiven} at ${contact.email}.`);
		});
	}

	handleSupplierUserInviteCancel(e) {
		this.setState({
			modalVisible: false,
		});
	}

	debouncedCallback = debounce(
		(params = {}) => {
			const { fetchFieldTechs, getKPIs, saveKPIFilters } = this.props;
			fetchFieldTechs({ ...this.state.pagination, ...params }, 'KPI_SCORECARD_TECHS').then(
				(fieldTechs) => {
					const scorecardsDataPromise = fieldTechs.map((fieldTech) => {
						return getKPIs({
							techEmail: [fieldTech.email],
							startDate: this.state.startDate,
							endDate: this.state.endDate,
						}).then((kpi) => {
							return {
								...fieldTech,
								newWOs: nullSafeGet('newWOs', kpi.totalTechNewWorkOrderKPIs),
								pmWOs: nullSafeGet('pmWOs', kpi.totalTechPMWorkOrderKPIs),
								completedWOs: nullSafeGet('completedWOs', kpi.totalTechCompletedWorkOrderKPIs),
								staleWOs: nullSafeGet('staleWOs', kpi.totalTechStaleWorkOrderKPIs),
								pmRate: nullSafeGet(
									'plannedMaintenancePercentage',
									kpi.totalTechPlannedMaintenanceRateKPIs
								),
								correctiveRate: nullSafeGet(
									'correctiveMaintenancePercentage',
									kpi.totalTechCorrectiveMaintenanceRateKPIs
								),
								checkInCheckOut: nullSafeGet('checkInRate', kpi.totalTechCheckInRateKPIs),
								firstTimeFix: nullSafeGet(
									'firstTimeResolutionRate',
									kpi.totalTechFirstTimeResolutionRateKPIs
								),
								downtime: nullSafeGet('averageDownTimeAsString', kpi.totalTechAverageDownTimeKPIs),
								responseTime: nullSafeGet(
									'averageResponseTimeAsString',
									kpi.totalTechAverageResponseTimeKPIs
								),
								completionTime: nullSafeGet(
									'averageCompletionTimeAsString',
									kpi.totalTechAverageCompletionTimeKPIs
								),
								satisfactionRating: nullSafeGet(
									'averageSatisfactionRatings',
									kpi.totalTechAverageSatisfactionRatingKPIs
								),
							};
						});
					});
					Promise.all(scorecardsDataPromise).then((scorecardsData) => {
						this.setState({ scorecardsData: scorecardsData, scorecardsDataLoading: false });
					});
				}
			);
			saveKPIFilters({ startDate: this.state.startDate, endDate: this.state.endDate });
		},
		500,
		false
	);

	componentDidMount() {
		const { saveKPIFilters } = this.props;
		saveKPIFilters({ startDate: this.state.startDate, endDate: this.state.endDate });
	}

	componentWillReceiveProps(nextProps) {
		const { techKpiAnalytics } = this.props;
		if (!isEqual(nextProps.techKpiAnalytics.techKpiFilters, techKpiAnalytics.techKpiFilters)) {
			this.setState({ scorecardsDataLoading: true });
			this.debouncedCallback();
		}
	}

	componentWillUnmount() {
		const { clearKPIFilters } = this.props;
		clearKPIFilters();
	}

	getColumns = () => [
		{
			title: '',
			dataIndex: 'name',
			render: (text, record) => {
				return <FieldTechRowDisplay fieldTech={record} />;
			},
		},
		{
			title: 'New WOs',
			dataIndex: 'newWOs',
			render: (text, rec) => {
				return rec.newWOs || '--';
			},
		},
		{
			title: 'PM WOs',
			dataIndex: 'pmWOs',
			render: (text, rec) => {
				return rec.pmWOs || '--';
			},
		},
		{
			title: 'Completed WOs',
			dataIndex: 'completedWOs',
			render: (text, rec) => {
				return rec.completedWOs || '--';
			},
		},
		{
			title: 'Stale WOs',
			dataIndex: 'staleWOs',
			render: (text, rec) => {
				return rec.staleWOs || '--';
			},
		},
		{
			title: '% PM',
			dataIndex: 'pmRate',
			render: (text, rec) => {
				return floatToPercentageString(rec.pmRate) || '--';
			},
		},
		{
			title: '% Reactive',
			dataIndex: 'correctiveRate',
			render: (text, rec) => {
				return floatToPercentageString(rec.correctiveRate) || '--';
			},
		},
		{
			title: 'Checked In',
			dataIndex: 'checkInCheckOut',
			render: (text, rec) => {
				return floatToPercentageString(rec.checkInCheckOut) || '--';
			},
		},
		{
			title: 'Fixed First Time',
			dataIndex: 'firstTimeFix',
			render: (text, rec) => {
				return floatToPercentageString(rec.firstTimeFix) || '--';
			},
		},
		// {
		//     title: 'Downtime',
		//     dataIndex: 'downtime',
		//     render: (text, rec) => {
		//         return rec.downtime ? rec.downtime : '--';
		//     }
		// },
		{
			title: 'Response Time',
			dataIndex: 'responseTime',
			render: (text, rec) => {
				return rec.responseTime ? rec.responseTime : '--';
			},
		},
		{
			title: 'Completion Time',
			dataIndex: 'completionTime',
			render: (text, rec) => {
				return rec.completionTime ? rec.completionTime : '--';
			},
		},
		{
			title: 'Customer Satisfaction',
			dataIndex: 'customerSatisfaction',
			render: (text, rec) => {
				return rec.satisfactionRating ? rec.satisfactionRating.toFixed(2) : '--';
			},
		},
	];

	onDownloadAnalytics = () => {
		const title = 'Tech Analytics';
		const csvExporter = new ExportToCsv({
			...CSV_EXPORT_DEFAULTS,
			filename: title,
			title,
			headers: this.getColumns().map((_) => _.title),
		});
		const dataIndex = this.getColumns().map((_) => _.dataIndex);
		csvExporter.generateCsv(
			this.state.scorecardsData.map((obj) =>
				dataIndex.reduce((acc, key) => {
					return {
						...acc,
						[key]: nullSafeGetOrElse(`${key}`, obj, '-'),
					};
				}, {})
			)
		);
	};

	render() {
		const { history, currentUser, saveKPIFilters, field_techs } = this.props;
		const { scorecardsData, scorecardsDataLoading } = this.state;
		const onRow = (record) => ({
			onClick: () =>
				history.push(
					`/supplier/analytics/fieldTechAnalytics/detail/${encodeURIComponent(record.email)}`
				),
		});

		const columns = this.getColumns();
		const total = nullSafeGet('KPI_SCORECARD_TECHS.count', field_techs);
		return (
			<div>
				{scorecardsDataLoading ? (
					<PageLoadingPlaceholder />
				) : (
					<Content
						className="supplierFieldTechsAnalyticsOverviewPage"
						style={{ padding: '0 0.5em' }}
					>
						<LogOnMountWithStandardEventProperties eventType="visited supplier field tech analytics overview page" />
						{/*<ScrollToTopOnMount/>*/}
						{/*<BackTop/>*/}
						<SupplierNewUserInviteForm
							wrappedComponentRef={this.saveFormRef('userInviteForm')}
							visible={this.state.modalVisible}
							formData={{ roles: 'SUPPLIER_TECH' }}
							onCancel={this.handleSupplierUserInviteCancel}
							onSubmit={this.handleSupplierUserInviteSubmit}
						/>
						<div className="flex gap-y-4">
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									flex: 1,
									paddingTop: 16,
								}}
							>
								<div style={{ marginLeft: 8 }}>
									<div className="text-3xl font-bold">
										Technician Scorecard ({this.state.startDate} - {this.state.endDate})
									</div>
								</div>
								<div className="mr-2 flex flex-row items-center">
									<Button
										size="large"
										icon={<DownloadOutlined translate="" />}
										className="inline-block"
										onClick={this.onDownloadAnalytics}
									>
										<span className="inline-block">Export CSV</span>
									</Button>
								</div>
							</div>
						</div>
						<div className="mb-6 mt-2 flex gap-x-4">
							<Input.Search
								placeholder="Search for a technician"
								style={{ width: 200 }}
								onSearch={(value) => this.debouncedCallback({ search: value })}
								onChange={(e) => {
									this.debouncedCallback({ search: e.target.value });
								}}
							/>
							<Select
								defaultValue={this.state.selectionValue.toString()}
								style={{ width: '150px', marginRight: 8 }}
								onSelect={(val) => {
									const currentMonth = moment().month() == 0 ? 12 : moment().month();
									if (val === '1') {
										const startDate = moment().startOf('week').format('YYYY-MM-DD');
										const endDate = moment().format('YYYY-MM-DD');
										this.setState({
											selectionValue: val,
											month: null,
											startDate: startDate,
											endDate: endDate,
										});
										saveKPIFilters({ startDate: startDate, endDate: endDate });
									} else if (val === '2') {
										const startDate = moment()
											.subtract(1, 'week')
											.startOf('week')
											.format('YYYY-MM-DD');
										const endDate = moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD');
										this.setState({
											selectionValue: val,
											month: null,
											startDate: startDate,
											endDate: endDate,
										});
										saveKPIFilters({ startDate: startDate, endDate: endDate });
									} else if (val === '3') {
										const startDate = moment().startOf('month').format('YYYY-MM-DD');
										const endDate = moment().format('YYYY-MM-DD');
										this.setState({
											selectionValue: val,
											startDate: startDate,
											endDate: endDate,
										});
										saveKPIFilters({ startDate: startDate, endDate: endDate });
									} else if (val === '4') {
										const startDate = moment()
											.subtract(1, 'months')
											.startOf('month')
											.format('YYYY-MM-DD');
										const endDate = moment()
											.subtract(1, 'months')
											.endOf('month')
											.format('YYYY-MM-DD');
										this.setState({
											selectionValue: val,
											month: null,
											startDate: startDate,
											endDate: endDate,
										});
										saveKPIFilters({ startDate: startDate, endDate: endDate });
									}
								}}
							>
								<Select.Option value="1">Current Week</Select.Option>
								<Select.Option value="2">Last Week</Select.Option>
								<Select.Option value="3">Current Month</Select.Option>
								<Select.Option value="4">Last Month</Select.Option>
							</Select>
						</div>
						<Row style={{ margin: '0.5em -8px' }} gutter={16}>
							<Col span={24}>
								<Card bodyStyle={{ padding: 8 }}>
									{scorecardsData.length > 0 ? (
										<Table
											columns={columns}
											showHeader={true}
											rowKey={(el) => el['id']}
											dataSource={scorecardsData}
											onRow={onRow}
											pagination={{
												current: Math.floor(this.state.pagination.offset / 10) + 1,
												pageSize: 10,
												total,
											}}
											onChange={(pagination) => {
												this.setState(
													{
														pagination: {
															offset: (pagination.current - 1) * 10,
															limit: 10,
														},
													},
													() => {
														this.debouncedCallback();
													}
												);
											}}
										/>
									) : (
										<EmptyState
											graphic={
												<img
													style={{ marginBottom: 8 }}
													src="https://s3.amazonaws.com/mock-data-assets/categories/images/sloth.svg"
												/>
											}
											headline={'My work here is done'}
											body={
												<div style={{ textAlign: 'center' }}>
													<div style={{ maxWidth: 440, marginBottom: 16 }}>
														Looks like your team has not added any field techs yet.
													</div>
													{currentUser &&
													currentUser.roles.some((role) => SUPPLIER_ADMIN_ONLY.has(role)) ? (
														<Button type="primary" onClick={(e) => this.showModal(e)}>
															Invite your first field technician
														</Button>
													) : null}
												</div>
											}
										/>
									)}
								</Card>
							</Col>
						</Row>
					</Content>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	supplierFacilities: state.supplier_facilities,
	currentUser: state.session.currentUse,
	techKpiAnalytics: state.tech_kpi_analytics,
	field_techs: state.field_techs,
});

const mapDispatchToProps = (dispatch) => ({
	saveKPIFilters: (filters) => dispatch(updateTechKPIFilters(filters)),
	clearKPIFilters: () => dispatch(clearTechKPIFilters()),
	inviteNewUser: (contact) => dispatch(supplierSignUpInvite(contact)),
	fetchFieldTechs: (params, targetCollectionName, pagination, sorting, filters) =>
		dispatch(
			fieldTechsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters
			)
		),
	getKPIs: (params) => dispatch(fetchAllTechKPITotals(params)),
	getLatestMonthlyKPIs: (params) => dispatch(fetchAllTechKPIs(params)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SupplierFieldTechsAnalyticsOverviewPage);

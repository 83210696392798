import * as React from 'react';

import Result from 'ant-design-pro/lib/Result';
import { LockOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button } from 'antd';
import { connect } from 'react-redux';
import { resetPassword } from '../../thunks/session_thunks';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { Link } from 'react-router-dom';

const style = require('./BuyerResetPasswordForm.less');
const FormItem = Form.Item;

interface ResetPasswordFormProps extends FormComponentProps {
	resetPassword: any;
	loading: boolean;
	token: any;
	username: string;
}

class BuyerResetPasswordForm extends React.Component<ResetPasswordFormProps, any> {
	state = {
		resetComplete: false,
	};

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.props.resetPassword(values.newPassword, this.props.token).then(() => {
					this.setState({ resetComplete: true });
				});
			}
		});
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { loading } = this.props;
		const { resetComplete } = this.state;
		return resetComplete ? (
			<Result
				type="success"
				title={<div>You're all set.</div>}
				description={
					<div>
						<p className="signupForm__successExplanation">
							You've successfully reset your password.
						</p>
					</div>
				}
				actions={
					<div style={{ marginBottom: 24 }}>
						<Link to="/buyer/login">
							<Button type="primary">Back to log in</Button>
						</Link>
					</div>
				}
			/>
		) : (
			<Form onSubmit={this.handleSubmit} className="resetPasswordForm">
				<FormItem>
					{getFieldDecorator('newPassword', {
						rules: [{ required: true, message: 'Please input a new password!' }],
					})(
						<Input
							prefix={<LockOutlined style={{ fontSize: 14 }} />}
							size="large"
							type="password"
							placeholder="Password"
						/>
					)}
				</FormItem>
				<FormItem>
					<Button
						type="primary"
						htmlType="submit"
						loading={loading}
						className="resetPasswordForm__button"
					>
						Change password
					</Button>
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	token: state.session.token || ownProps.token,
	loading: state.session.loading,
});

const mapDispatchToProps = (dispatch) => ({
	resetPassword: (newPassword, token) => dispatch(resetPassword('buyer')(newPassword, token)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create<ResetPasswordFormProps>()(BuyerResetPasswordForm));

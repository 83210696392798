import React, { FC, useMemo } from 'react';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';

interface WidgetHeaderProps {
	pagination?: any;
	title: string;
	entityName: string;
}

const WidgetHeader: FC<WidgetHeaderProps> = ({ title, pagination }): React.ReactElement => {
	return (
		<div
			className="truncate"
			style={{
				display: 'inline-lock',
				flex: '1 1',
				padding: '16px 24px',
				minHeight: '48px',
				color: 'rgba(0, 0, 0, 0.85)',
				letterSpacing: '0.5px',
				fontSize: '15px',
			}}
		>
			<span
				style={{
					textTransform: 'uppercase',
				}}
			>
				{title}
			</span>
			{nullSafeGetOrElse('total', pagination, 0) ? (
				<span
					style={{
						color: 'rgba(0, 0, 0, 0.5)',
					}}
				>
					{` (${nullSafeGetOrElse('total', pagination, 0)})`}
				</span>
			) : null}
		</div>
	);
};

export default WidgetHeader;

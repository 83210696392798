import React, { FC, useMemo } from 'react';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { getProtectedImageUriForSupplier } from '../../utils/FileAccessUtils';
import { FlexibleImageAvatar } from '../flexible-image-avatar/FlexibleImageAvatar';

const PartOrEquipmentAvatar: FC<any> = ({ item }): React.ReactElement => {
	const previewImage = useMemo(
		() =>
			nullSafeGet('images', item) && item.images.length > 0
				? item.images.map((locImg) => {
						const [imgFileId, imgFileName] = locImg.split('/');
						return getProtectedImageUriForSupplier(imgFileId, imgFileName, 1200, 800);
				  })[0]
				: null,
		[item]
	);

	return previewImage ? (
		<FlexibleImageAvatar
			displayName={nullSafeGet('name', item)}
			showPopover={false}
			popoverText={nullSafeGet('name', item)}
			squareMode={true}
			width="120px"
			height="80px"
			imageUrl={previewImage}
		/>
	) : (
		<FlexibleImageAvatar
			displayName={nullSafeGet('name', item)}
			showPopover={false}
			popoverText={nullSafeGet('name', item)}
			squareMode={true}
			width="120px"
			height="80px"
			imageUrl="https://s3.amazonaws.com/emptor-data-assets/images/empty-state/icons8-screw-filled-100.png"
		/>
	);
};

export default PartOrEquipmentAvatar;

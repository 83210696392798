import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { WORK_ORDER_PRIORITIES_CRUD_ACTION_CREATORS } from '../actions/work_order_priorities_actions';

export const workOrderPrioritiesRestCrudThunksForBuyer = new RestCrudThunks(
	'work_order_priorities',
	'work_order_priority',
	'workOrderPriority',
	'workOrderPriorities',
	WORK_ORDER_PRIORITIES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'work_order/'
);
export const workOrderPrioritiesRestCrudThunksForSupplier = new RestCrudThunks(
	'work_order_priorities',
	'work_order_priority',
	'workOrderPriority',
	'workOrderPriorities',
	WORK_ORDER_PRIORITIES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'work_order/'
);

import { getBackendUri } from '../utils/EnvConfigUtils';
import FetchUtils, { parseJSON } from './fetch_utils';

import {
	fetchBuyerUsers,
	fetchBuyerUsersSuccess,
	fetchBuyerUsersFailure,
	disableBuyerUserStart,
	disableBuyerUserSuccess,
	disableBuyerUserFailure,
	reinviteBuyerUserStart,
	reinviteBuyerUserSuccess,
	reinviteBuyerUserFailure,
} from '../actions/buyer_users_actions';

import {
	fetchSupplierUsers,
	fetchSupplierUsersSuccess,
	fetchSupplierUsersFailure,
	disableSupplierUserStart,
	disableSupplierUserSuccess,
	disableSupplierUserFailure,
	reinviteSupplierUserStart,
	reinviteSupplierUserSuccess,
	reinviteSupplierUserFailure,
} from '../actions/supplier_users_actions';
import { ROLE_TYPES } from '../utils/DataConstants';
import { logoutSuccess } from '../actions/session_actions';

import RestCrudThunks from './restCrudThunksGenerator';
import { USERS_CRUD_ACTION_CREATORS } from '../actions/users_actions';

const fetchUtils = new FetchUtils();
const BASE_URL = getBackendUri();

export const usersRestCrudThunksForBuyer = new RestCrudThunks(
	'users',
	'user',
	'user',
	'facility_users',
	USERS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'admin/'
);
export const usersRestCrudThunksForSupplier = new RestCrudThunks(
	'facility_users_list',
	'user',
	'user',
	'facility_users_list',
	USERS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'admin/'
);

export const fetchFacilityActiveUsers =
	(userRole, filters = null, params = {}) =>
	() => {
		let fetchUsers;
		let fetchUsersSuccess;
		let fetchUsersFailure;
		if (userRole === 'buyer') {
			fetchUsers = fetchBuyerUsers;
			fetchUsersSuccess = fetchBuyerUsersSuccess;
			fetchUsersFailure = fetchBuyerUsersFailure;
		} else if (userRole === 'supplier') {
			fetchUsers = fetchSupplierUsers;
			fetchUsersSuccess = fetchSupplierUsersSuccess;
			fetchUsersFailure = fetchSupplierUsersFailure;
		} else {
			throw new Error('invalid user role provided');
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(fetchUsers());

				fetchUtils
					.get(BASE_URL + `/api/v1/${userRole}/admin/facility_active_users_list`, params)
					.then((response) => response.json())
					.then((d) => {
						if (d.status === 'error') {
							dispatch(fetchUsersFailure([d.message]));
							reject(d.message);
						} else {
							dispatch(fetchUsersSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(fetchUsersFailure(d.message));
						reject(d.message);
					});
			});
		};
	};
export const fetchFacilityActiveBuyerUsersAndInternalSupplierUsers =
	(userRole, filters = null, params = {}) =>
	() => {
		let fetchUsers;
		let fetchUsersSuccess;
		let fetchUsersFailure;
		let route;
		if (userRole === 'buyer') {
			route = 'facility_active_users_list';
			fetchUsers = fetchBuyerUsers;
			fetchUsersSuccess = fetchBuyerUsersSuccess;
			fetchUsersFailure = fetchBuyerUsersFailure;
		} else if (userRole === 'internal') {
			route = 'facility_active_users_list_with_internal_techs';
			fetchUsers = fetchBuyerUsers;
			fetchUsersSuccess = fetchBuyerUsersSuccess;
			fetchUsersFailure = fetchBuyerUsersFailure;
		} else {
			throw new Error('invalid user role provided');
		}
		if (filters) {
			params = {
				...params,
				...filters,
			};
		}
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(fetchUsers());
				fetchUtils
					.get(BASE_URL + `/api/v1/buyer/admin/${route}`, params)
					.then((response) => response.json())
					.then((d) => {
						const data =
							d &&
							d.data &&
							d.data.map((data) => (data.contactWithAccount ? data.contactWithAccount : data));
						if (d.status === 'error') {
							dispatch(fetchUsersFailure([d.message]));
							reject(d.message);
						} else {
							dispatch(fetchUsersSuccess(data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(fetchUsersFailure(d.message));
						reject(d.message);
					});
			});
		};
	};

export const fetchFacilityUsers =
	(userRole, filters = null, params = {}) =>
	() => {
		let fetchUsers;
		let fetchUsersSuccess;
		let fetchUsersFailure;
		if (userRole === 'buyer') {
			fetchUsers = fetchBuyerUsers;
			fetchUsersSuccess = fetchBuyerUsersSuccess;
			fetchUsersFailure = fetchBuyerUsersFailure;
		} else if (userRole === 'supplier') {
			fetchUsers = fetchSupplierUsers;
			fetchUsersSuccess = fetchSupplierUsersSuccess;
			fetchUsersFailure = fetchSupplierUsersFailure;
		} else {
			throw new Error('invalid user role provided');
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(fetchUsers());

				fetchUtils
					.get(BASE_URL + `/api/v1/${userRole}/admin/facility_users_list`, params)
					.then((response) => response.json())
					.then((d) => {
						if (d.status === 'error') {
							dispatch(fetchUsersFailure([d.message]));
							reject(d.message);
						} else {
							dispatch(fetchUsersSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(fetchUsersFailure(d.message));
						reject(d.message);
					});
			});
		};
	};

export const disableUser = (userRole) => (userName) => {
	let disableUserStart;
	let disableUserSuccess;
	let disableUserFailure;
	if (userRole === 'buyer') {
		disableUserStart = disableBuyerUserStart;
		disableUserSuccess = disableBuyerUserSuccess;
		disableUserFailure = disableBuyerUserFailure;
	} else if (userRole === 'supplier') {
		disableUserStart = disableSupplierUserStart;
		disableUserSuccess = disableSupplierUserSuccess;
		disableUserFailure = disableSupplierUserFailure;
	} else {
		throw new Error('invalid user role provided');
	}
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(disableUserStart(userName));

			fetchUtils
				.patch(BASE_URL + `/api/v1/${userRole}/admin/disable/${encodeURIComponent(userName)}`, {})
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(disableUserFailure(userName, [d.message]));
						reject(d.message);
					} else {
						dispatch(disableUserSuccess(userName));
						dispatch(fetchFacilityUsers(userRole)());
						resolve(userName);
					}
				})
				.catch((d) => {
					dispatch(disableUserFailure(userName, [d.message]));
					reject(d.message);
				});
		});
	};
};

export const reinviteUser = (userRole) => (userName) => {
	let disableUserStart;
	let disableUserSuccess;
	let disableUserFailure;
	let reinviteUserStart;
	let reinviteUserSuccess;
	let reinviteUserFailure;
	if (userRole === 'buyer') {
		reinviteUserStart = reinviteBuyerUserStart;
		reinviteUserSuccess = reinviteBuyerUserSuccess;
		reinviteUserFailure = reinviteBuyerUserFailure;
	} else if (userRole === 'supplier') {
		reinviteUserStart = reinviteSupplierUserStart;
		reinviteUserSuccess = reinviteSupplierUserSuccess;
		reinviteUserFailure = reinviteSupplierUserFailure;
	} else {
		throw new Error('invalid user role provided');
	}
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(reinviteUserStart(userName));

			fetchUtils
				.patch(BASE_URL + `/api/v1/${userRole}/admin/reinvite/${encodeURIComponent(userName)}`, {})
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(reinviteUserFailure(userName, [d.message]));
						reject(d.message);
					} else {
						dispatch(reinviteUserSuccess(userName));
						resolve(userName);
					}
				})
				.catch((d) => {
					dispatch(reinviteUserFailure(userName, [d.message]));
					reject(d.message);
				});
		});
	};
};

const fetchUser = (userRole) => (userName) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(BASE_URL + `/api/v1/${userRole}/admin/facility_users/${userName}`, {})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const fetchUserForBuyer = fetchUser(ROLE_TYPES.BUYER);
export const fetchUserForSupplier = fetchUser(ROLE_TYPES.SUPPLIER);

import * as React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Layout, Card } from 'antd';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { spendCategoriesRestCrudThunksForBuyer } from '../../thunks/spend_categories_thunks';
import { supplierFacilitiesRestCrudThunksForBuyer } from '../../thunks/supplier_facilities_thunks';
import { getObjectValues, nullSafeGet } from '../../utils/DataAccessUtils';
import { getSupplierPrivateNetworksForLocationForBuyer } from '../../thunks/supplier_private_networks_thunks';
import { FlexibleImageAvatar } from '../flexible-image-avatar/FlexibleImageAvatar';
import { AddressDisplay } from '../address_display/AddressDisplay';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const Content = Layout.Content;

const style = require('./LocationsDetailSuppliersPage.less');

class LocationsDetailSuppliersPage extends React.Component<any, any> {
	componentDidMount() {
		const {
			match,
			fetchSpendCategories,
			fetchSupplierPrivateNetworksForLocation,
			fetchSupplierFacilities,
		} = this.props;
		const locationId = match.params.id;
		fetchSpendCategories({});
		fetchSupplierFacilities({});
		fetchSupplierPrivateNetworksForLocation(locationId, {});
	}

	render() {
		const {
			supplierPrivateNetworks,
			locationsFetching,
			spendCategoriesFetching,
			spendCategories,
			supplierFacilities,
			supplierFacilitiesFetching,
		} = this.props;
		const fetching = locationsFetching || spendCategoriesFetching || supplierFacilitiesFetching;
		const spendCategoryRecords = getObjectValues(spendCategories.records);
		const supplierFacilitiesRecords = getObjectValues(supplierFacilities.records).slice(0, 3);
		return fetching ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="locationsDetailSuppliersPage">
				<LogOnMountWithStandardEventProperties eventType="visited buyer location detail suppliers page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				{supplierPrivateNetworks.locationDetailRecords.map((spnCat, spnCatIdx) => (
					<Card
						style={{ maxWidth: 800, margin: '8px 16px' }}
						key={spnCat.id}
						title={spnCat.problemType.hierarchyName}
					>
						{spnCat.supplierPrivateNetworkSeq.map((spn, spnIdx) => (
							<div key={spn.id} style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ marginRight: 24 }}>
									{nullSafeGet('supplierFacility.logoURL', spn) ? (
										<FlexibleImageAvatar
											displayName={nullSafeGet('supplierFacility.name', spn)}
											showPopover={false}
											popoverText={nullSafeGet('supplierFacility.name', spn)}
											squareMode={true}
											width="80px"
											height="60px"
											imageUrl={nullSafeGet('supplierFacility.logoURL', spn)}
										/>
									) : (
										<FlexibleImageAvatar
											displayName={nullSafeGet('supplierFacility.name', spn)}
											showPopover={false}
											popoverText={nullSafeGet('supplierFacility.name', spn)}
											squareMode={true}
											width="80px"
											height="60px"
											imageUrl="https://s3.amazonaws.com/emptor-data-assets/images/empty-state/icons8-city-filled-100.png"
										/>
									)}
								</div>
								<div>
									<div
										style={{
											fontSize: 18,
											wordBreak: 'break-word',
										}}
									>
										{nullSafeGet('supplierFacility.name', spn)}
									</div>
									<AddressDisplay
										hidePostcode={true}
										hideCountry={true}
										hideStreetAddress={true}
										address={nullSafeGet('supplierFacility.primaryAddress', spn)}
										style={{
											color: 'rgba(0,0,0,0.45)',
										}}
									/>
								</div>
							</div>
						))}
						{spnCat.supplierPrivateNetworkCount === 0 ? (
							<div className="locationsDetailSuppliersPage__emptyState">
								You haven't assigned any suppliers to this location yet.
							</div>
						) : null}
					</Card>
				))}
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	locationsFetching: state.locations.fetching,
	location: state.locations.detail,
	spendCategories: state.spend_categories,
	spendCategoriesFetching: state.spend_categories.fetching,
	supplierFacilities: state.supplier_facilities,
	supplierFacilitiesFetching: state.supplier_facilities.fetching,
	supplierPrivateNetworks: state.supplier_private_networks,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	fetchSpendCategories: (params) => dispatch(spendCategoriesRestCrudThunksForBuyer.read(params)),
	fetchSupplierFacilities: (params) =>
		dispatch(supplierFacilitiesRestCrudThunksForBuyer.readHydrated(params)),
	fetchSupplierPrivateNetworksForLocation: (locationId, params) =>
		dispatch(getSupplierPrivateNetworksForLocationForBuyer(locationId, params)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(LocationsDetailSuppliersPage)
);

import React from 'react';
import NotificationsIndexPage from '../notifications_index_page/NotificationsIndexPage';
import {
	BUYER_FILTER_STATUS_NOTIFICATION_TYPES_MAP,
	SUPPLIER_FILTER_STATUS_NOTIFICATION_TYPES_MAP,
} from '../../utils/NotificationUtils';
import { connect } from 'react-redux';
import { ROLE_TYPES } from '../../utils/DataConstants';

function NotificationsStatusUpdates({ userType, currentUser }) {
	const NOTIFICATION_STATUS_UPDATES_TYPES =
		userType === ROLE_TYPES.BUYER
			? BUYER_FILTER_STATUS_NOTIFICATION_TYPES_MAP
			: SUPPLIER_FILTER_STATUS_NOTIFICATION_TYPES_MAP;

	return (
		<NotificationsIndexPage
			key={'NotificationsStatusUpdates'}
			preAppliedFilters={{
				hasSeen: false,
			}}
			notificationTypes={NOTIFICATION_STATUS_UPDATES_TYPES.map((filter) => {
				if (filter.children && filter.children.length > 0) {
					return filter.children.map((child) => child.key);
				}
				return filter.key;
			}).flat()}
			typeFilters={NOTIFICATION_STATUS_UPDATES_TYPES}
		/>
	);
}

const mapStateToProps = (state, ownProps) => ({
	currentUser: state.session.currentUser,
	userType: state.session.userType,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsStatusUpdates);

import React, { FC, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, message } from 'antd';
import { EmptyState } from '../empty_state/EmptyState';
import { PART_CATALOGS_CRUD_ACTION_CREATORS } from '../../actions/part_catalogs_actions';
import {
	downloadPartCatalogPriceCSVForSupplier,
	partCatalogsRestCrudThunksForSupplier,
	updatePartCatalogsListPriceForSupplier,
} from '../../thunks/part_catalogs_thunks';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { nullSafeGetOrElse, renderCurrency } from '../../utils/DataAccessUtils';
import { ExportToCsv } from 'export-to-csv';
import { CSV_EXPORT_DEFAULTS } from '../../utils/DataConstants';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import { partsRestCrudThunksForSupplier } from '../../thunks/parts_thunks';
import { vendorsRestCrudThunksForSupplier } from '../../thunks/vendors_thunks';
import UploadWithSampleModal from './UploadWithSampleModal';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';

const TC_NAME = 'PART_CATALOGS_FOR_PART';
const CSV_TITLE = 'Sample_Price_List';
const PART_CATALOGS_ADVANCED_FILTERS = 'partCatalogsAdvancedFilters';

const PartsPriceListIndexPage: FC<any> = ({
	updateFilters,
	clearAndUpdateFilters,
	updateSorters,
	partCatalogs,
	currentUser,
	updatePriceList,
	downloadPriceList,
	history,
	location,
	parts,
	fetchParts,
	fetchMultipleParts,
	vendors,
	fetchVendors,
	fetchMultipleVendors,
	fetchPriceList,
}) => {
	const [downloading, setDownloading] = useState(false);
	const [uploadPopupVisible, setUploadPopupVisible] = useState(false);

	const showUpload = useCallback(() => setUploadPopupVisible(true), []);
	const hideUpload = useCallback(() => setUploadPopupVisible(false), []);

	const onRow = useCallback(
		(record) => ({
			onClick: () =>
				history.push(
					getLinkWIthBackLinkParams(
						location,
						'Back to Price List',
						`/supplier/parts/partCatalogs/detail/${record.id}`
					)
				),
		}),
		[history, location]
	);

	const getStatSliceProp = useCallback(
		(propName) => nullSafeGetOrElse(`${TC_NAME}.${propName}`, partCatalogs, {}),
		[partCatalogs]
	);

	const refreshPriceList = useCallback(() => {
		fetchPriceList(
			{},
			TC_NAME,
			getStatSliceProp('pagination'),
			getStatSliceProp('sorting'),
			getStatSliceProp('filters')
		);
	}, [fetchPriceList, getStatSliceProp]);

	const columns = useMemo(
		() => [
			{
				title: 'Part Number',
				dataIndex: ['part', 'partNumber'],
			},
			{
				title: 'Part Name',
				dataIndex: ['part', 'name'],
				key: 'partName',
				sorter: true,
			},
			{
				title: 'Vendor Name',
				dataIndex: ['partEquipmentVendor', 'name'],
				key: 'vendorName',
				sorter: true,
			},
			{
				title: 'List Price',
				dataIndex: 'listPrice',
				render: renderCurrency(currentUser),
				key: 'listPrice',
				sorter: true,
			},
			{
				title: 'Last Price',
				dataIndex: 'unitCost',
				key: 'unitCost',
				render: renderCurrency(currentUser),
				sorter: true,
			},
		],
		[currentUser]
	);

	const filterConfig = useMemo(
		() => [
			{
				label: 'Part',
				fieldName: 'partIds',
				mode: 'multiple',
				stateSlice: parts,
				targetCollectionName: PART_CATALOGS_ADVANCED_FILTERS,
				fetch: fetchParts,
				fetchMultiple: fetchMultipleParts,
				renderItem: (part) => (
					<div className="flex flex-row items-center">
						<div>{part.name}</div>
						<div>
							{part.partNumber && (
								<span className="text-gray-400">&nbsp;{`- #${part.partNumber}`}</span>
							)}
						</div>
					</div>
				),
			},
			{
				label: 'Vendor',
				fieldName: 'partEquipmentVendorIds',
				mode: 'multiple',
				stateSlice: vendors,
				targetCollectionName: PART_CATALOGS_ADVANCED_FILTERS,
				fetch: fetchVendors,
				fetchMultiple: fetchMultipleVendors,
			},
		],
		[fetchMultipleParts, fetchMultipleVendors, fetchParts, fetchVendors, parts, vendors]
	);

	const download = useCallback(() => {
		const filters = nullSafeGetOrElse(`${TC_NAME}.filters`, partCatalogs, {});
		setDownloading(true);
		downloadPriceList({}, filters).finally(() => setDownloading(false));
	}, [downloadPriceList, partCatalogs]);

	const fields: any[] = useMemo(
		() => [
			{
				label: 'Part Number',
				key: 'partNumber',
			},
			{
				label: 'Price',
				key: 'price',
			},
			{
				label: 'Effective Date (MM/DD/YYYY)',
				key: 'effectiveDate',
			},
			{
				label: 'Vendor ID',
				key: 'vendorId',
			},
		],
		[]
	);

	const onDownloadSample = useCallback(() => {
		const csvExporter = new ExportToCsv({
			...CSV_EXPORT_DEFAULTS,
			filename: CSV_TITLE,
			title: CSV_TITLE,
			headers: fields.map((_) => _.label),
		});
		csvExporter.generateCsv([
			{
				partNumber: 1234,
				price: 10,
				effectiveDate: '01/31/2023',
				vendorId: 123,
			},
			{
				partNumber: 4321,
				price: 20,
				effectiveDate: '02/27/2023',
				vendorId: 123,
			},
		]);
	}, [fields]);

	const onDataUpload = useCallback(
		(results) => {
			return new Promise((resolve, reject) => {
				updatePriceList(results.validData)
					.then((res) => {
						resolve('Updated successfully!');
						setTimeout(() => {
							hideUpload();
							message.success('Updated successfully!');
							refreshPriceList();
						}, 0);
					})
					.catch((err) => {
						const genericError = 'Unable to upload! Please contact support!';
						const message = nullSafeGetOrElse('error', err, []).reduce((acc, obj) => {
							const suffix = `${obj.reason} for Part Number ${obj.partNumber}`;
							return acc ? acc + '; ' + suffix : suffix;
						}, '');
						reject(message || genericError);
					});
			});
		},
		[hideUpload, refreshPriceList, updatePriceList]
	);

	return (
		<>
			<PaginatedReduxTableWithHeader
				targetCollectionName={TC_NAME}
				updateFilters={updateFilters}
				stateSlice={partCatalogs}
				clearAndUpdateFilters={clearAndUpdateFilters}
				updateSorters={updateSorters}
				filterConfig={filterConfig}
				entityCollectionName="part_catalogs"
				tableColumns={columns}
				onTableRow={onRow}
				showHeader
				fetchData={partCatalogsRestCrudThunksForSupplier.read}
				rightActions={
					<div className="flex flex-row items-center">
						<Button
							type="ghost"
							size="large"
							icon={<DownloadOutlined translate="" />}
							loading={downloading}
							onClick={download}
						>
							Export CSV
						</Button>
						<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PARTS_AND_EQUIPMENTS}>
							<Button
								className="ml-2"
								type="primary"
								size="large"
								icon={<UploadOutlined translate="" />}
								onClick={showUpload}
							>
								Upload
							</Button>
						</AccessPermissionChecker>
					</div>
				}
				emptyState={
					<EmptyState
						graphic={
							<img
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
								alt="Looks like your team has not added any price list yet."
							/>
						}
						headline={"Nothing's here!"}
						body={
							<div style={{ textAlign: 'center' }}>
								<div style={{ maxWidth: 440, marginBottom: 16 }}>
									Looks like your team has not added any price list yet.
								</div>
							</div>
						}
					/>
				}
			/>
			{uploadPopupVisible && (
				<UploadWithSampleModal
					type="Price List"
					title="Upload Price List"
					onCancel={hideUpload}
					onDownloadSample={onDownloadSample}
					fields={fields}
					onDataLoad={onDataUpload}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state, ownProps) => ({
	currentUser: state.session.currentUser,
	partCatalogs: state.part_catalogs,
	parts: state.parts,
	vendors: state.vendors,
});

const mapDispatchToProps = (dispatch) => ({
	updateFilters: (filters, targetCollection) =>
		dispatch(PART_CATALOGS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			PART_CATALOGS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)
		),
	updateSorters: (sortBy, order, targetCollection) =>
		dispatch(PART_CATALOGS_CRUD_ACTION_CREATORS.updateSorting(sortBy, order, targetCollection)),
	updatePriceList: (entities) => dispatch(updatePartCatalogsListPriceForSupplier(entities)),
	downloadPriceList: (params, filters) =>
		dispatch(downloadPartCatalogPriceCSVForSupplier(params, filters)),
	fetchMultipleParts: (ids, targetCollectionName) =>
		dispatch(partsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchParts: (params, targetCollectionName, pagination, sorting, filters, addToTargetCollection) =>
		dispatch(
			partsRestCrudThunksForSupplier.readLite(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleVendors: (ids, targetCollectionName) =>
		dispatch(vendorsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchVendors: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			vendorsRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchPriceList: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			partCatalogsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(PartsPriceListIndexPage)
);

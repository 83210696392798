import { STOPLIGHT_COLORS } from '../utils/DataFormatterUtils';

export const PURCHASE_ORDER_STATUSES = {
	new: 'new',
	ordered: 'ordered',
	received: 'received',
	cancelled: 'cancelled',
	closed: 'closed',
	partially_received: 'partially_received',
};

export const EDITABLE_PO_STATUSES = [PURCHASE_ORDER_STATUSES.new, PURCHASE_ORDER_STATUSES.ordered];

export const CLOSABLE_PO_STATUSES = [
	PURCHASE_ORDER_STATUSES.ordered,
	PURCHASE_ORDER_STATUSES.partially_received,
];

export const RECEIVABLE_PO_STATUSES = [
	PURCHASE_ORDER_STATUSES.new,
	PURCHASE_ORDER_STATUSES.ordered,
	PURCHASE_ORDER_STATUSES.partially_received,
];

export const PURCHASE_ORDER_STATUS_DISPLAY_NAMES = {
	[PURCHASE_ORDER_STATUSES.new]: 'New',
	[PURCHASE_ORDER_STATUSES.ordered]: 'Ordered',
	[PURCHASE_ORDER_STATUSES.received]: 'Received',
	[PURCHASE_ORDER_STATUSES.cancelled]: 'Cancelled',
	[PURCHASE_ORDER_STATUSES.closed]: 'Closed',
	[PURCHASE_ORDER_STATUSES.partially_received]: 'Partially Received',
};

export const PURCHASE_ORDER_STATUS_DISPLAY_TEXT_COLORS = {
	[PURCHASE_ORDER_STATUSES.new]: '#4a1e87',
	[PURCHASE_ORDER_STATUSES.ordered]: 'rgb(11, 28, 111)',
	[PURCHASE_ORDER_STATUSES.cancelled]: STOPLIGHT_COLORS.pureRed,
	[PURCHASE_ORDER_STATUSES.closed]: STOPLIGHT_COLORS.pureRed,
	[PURCHASE_ORDER_STATUSES.received]: STOPLIGHT_COLORS.pureGreen,
	[PURCHASE_ORDER_STATUSES.partially_received]: '#4a1e87',
};

export const PURCHASE_ORDER_STATUS_DISPLAY_BACKGROUND_COLORS = {
	[PURCHASE_ORDER_STATUSES.new]: '#e8def8',
	[PURCHASE_ORDER_STATUSES.ordered]: 'rgb(225, 229, 249)',
	[PURCHASE_ORDER_STATUSES.cancelled]: '#f9e6e6',
	[PURCHASE_ORDER_STATUSES.closed]: '#f9e6e6',
	[PURCHASE_ORDER_STATUSES.received]: '#e6f9e7',
	[PURCHASE_ORDER_STATUSES.partially_received]: '#e8def8',
};

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Layout, Row, Col, Card, Button, Drawer, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import {
	assetTypesRestCrudThunksForBuyer,
	assetTypesRestCrudThunksForSupplier,
} from '../../thunks/asset_types_thunks';
import { TwoLineList } from '../two_line_list/TwoLineList';
import { EmptyState } from '../empty_state/EmptyState';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { AssetModelRowDisplay } from '../asset_model_row_display/AssetModelRowDisplay';
import {
	assetModelsRestCrudThunksForBuyer,
	assetModelsRestCrudThunksForSupplier,
} from '../../thunks/asset_models_thunks';
import AssetModelForm from '../asset_model_form/AssetModelForm';
import AssetModelDetail from '../asset_model_detail/AssetModelDetail';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { mappingTemplateTypesForDisplay } from '../../utils/DataMappingUtils';

const { Content } = Layout;

require('./AssetTemplatesDetailDetailsPage.less');
const ASSET_MODELS_FOR_TYPES = 'ASSET_MODELS_FOR_TYPES';

const AssetTemplatesDetailPage: FC<any> = ({
	match,
	getAssetType,
	getAssetModel,
	deleteAssetModel,
	assetType,
	assetTypesFetching,
	userType,
}): React.ReactElement => {
	const [assetModelDetailData, setAssetModelDetailData] = useState({});
	const [assetModelFormData, setAssetModelFormData] = useState({});
	const [assetModelFormDrawerVisible, setAssetModelFormDrawerVisible] = useState(false);
	const [assetModelDetailDrawerVisible, setAssetModelDetailDrawerVisible] = useState(false);

	const [currentAssetModelFetching, setCurrentAssetModelFetching] = useState(undefined);
	const [currentDeletingAssetModel, setCurrentDeletingAssetModel] = useState(undefined);

	const assetTemplateId = useMemo(() => match.params.id, [match.params.id]);

	useEffect(() => getAssetType(assetTemplateId), [assetTemplateId]);

	const onClickAssetModel = useCallback(
		(record) => {
			getAssetModel(record.id).then((assetModel) => {
				setAssetModelDetailData(assetModel);
				setAssetModelDetailDrawerVisible(true);
			});
		},
		[getAssetModel]
	);

	const handleEdit = useCallback(
		(id, e) => {
			setCurrentAssetModelFetching(id);
			getAssetModel(id)
				.then((assetModel) => {
					setAssetModelFormData(assetModel);
					setAssetModelFormDrawerVisible(true);
				})
				.finally(() => setCurrentAssetModelFetching(undefined));
			e.stopPropagation();
			e.preventDefault();
		},
		[getAssetModel]
	);

	const handleDelete = useCallback(
		(record, e) => {
			setCurrentDeletingAssetModel(record.id);
			deleteAssetModel(record)
				.catch(() => message.error('Unable to delete asset model'))
				.finally(() => setCurrentDeletingAssetModel(undefined));
			e.stopPropagation();
			e.preventDefault();
		},
		[deleteAssetModel]
	);

	const handleAssetModelCreateDrawerOpen = useCallback(() => {
		setAssetModelFormData({});
		setAssetModelFormDrawerVisible(true);
	}, []);

	const handleAssetModelFormDrawerClose = useCallback(
		() => setAssetModelFormDrawerVisible(false),
		[]
	);

	const handleAssetModelDetailDrawerClose = useCallback(
		() => setAssetModelDetailDrawerVisible(false),
		[]
	);

	const handleAssetModelFormDrawerCloseOnSuccess = useCallback(() => {
		getAssetType(assetTemplateId).then(() => setAssetModelFormDrawerVisible(false));
	}, [assetTemplateId, getAssetType]);

	const onRow = useCallback(
		(record) => ({
			onClick: () => onClickAssetModel(record),
		}),
		[onClickAssetModel]
	);

	const assetModelsColumns = useMemo(
		() => [
			{
				title: '',
				dataIndex: 'title',
				render: (text, record) => <AssetModelRowDisplay titleLength={32} assetModel={record} />,
			},
			{
				title: 'Actions',
				dataIndex: 'id',
				render: (id, record) => (
					<div>
						<Button
							style={{ marginRight: 8 }}
							key="edit"
							loading={currentAssetModelFetching === id}
							onClick={(e) => handleEdit(id, e)}
						>
							<i className="icons8-font icons8-font-margin-right icons8-edit"></i> Edit
						</Button>
						<Button
							style={{ marginRight: 8 }}
							key="cancel"
							loading={currentDeletingAssetModel === id}
							onClick={(e) => handleDelete(record, e)}
						>
							<i className="icons8-font icons8-font-margin-right icons8-cancel"></i> Delete
						</Button>
					</div>
				),
			},
		],
		[currentAssetModelFetching, currentDeletingAssetModel, handleDelete, handleEdit]
	);

	return assetTypesFetching ? (
		<PageLoadingPlaceholder />
	) : (
		<div>
			<LogOnMountWithStandardEventProperties eventType="visited asset template detail page" />
			<Drawer
				width={1000}
				placement="right"
				closable={true}
				onClose={handleAssetModelFormDrawerClose}
				visible={assetModelFormDrawerVisible}
			>
				<AssetModelForm
					formData={assetModelFormData}
					assetTypeId={assetType.id}
					onSuccess={handleAssetModelFormDrawerCloseOnSuccess}
					onCancel={handleAssetModelFormDrawerClose}
				/>
			</Drawer>
			<Drawer
				width={1000}
				placement="right"
				closable={true}
				onClose={handleAssetModelDetailDrawerClose}
				visible={assetModelDetailDrawerVisible}
			>
				<AssetModelDetail
					formData={assetModelDetailData}
					onSuccess={handleAssetModelDetailDrawerClose}
					onCancel={handleAssetModelDetailDrawerClose}
				/>
			</Drawer>
			<Content className="assetTemplatesDetailDetailsPage">
				<Row style={{ margin: '-0.5em -8px 0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<div className="rowSpacing">
							<Col span={24}>
								<Card>
									<div className="assetTemplatesDetailDetailsPage__listSection">
										<div className="assetTemplatesDetailDetailsPage__listSectionTitle">
											Required Fields
										</div>
										{assetType.requiredFields && assetType.requiredFields.length === 0 ? (
											'No required fields'
										) : (
											<TwoLineList
												listItems={assetType.requiredFields}
												lineOneRender={(record) => record.key}
												lineTwoRender={(record) => mappingTemplateTypesForDisplay(record)}
											/>
										)}
									</div>
									<div className="assetTemplatesDetailDetailsPage__listSection">
										<div className="assetTemplatesDetailDetailsPage__listSectionTitle">
											Optional Fields
										</div>
										{assetType.optionalFields && assetType.optionalFields.length === 0 ? (
											'No optional fields'
										) : (
											<TwoLineList
												listItems={assetType.optionalFields}
												lineOneRender={(record) => record.key}
												lineTwoRender={(record) => mappingTemplateTypesForDisplay(record)}
											/>
										)}
									</div>
								</Card>
							</Col>
						</div>
						<div className="rowSpacing">
							<Col span={24}>
								<Card>
									<div className="assetTemplatesDetailDetailsPage__listSection">
										<div className="assetTemplatesDetailDetailsPage__listSectionTitle">
											Associated Problem Types
										</div>
										{assetType.allProblemTypesName || '--'}
									</div>
									<div className="assetTemplatesDetailDetailsPage__listSection">
										<div className="assetTemplatesDetailDetailsPage__listSectionTitle">
											Depreciation class
										</div>
										{assetType.depreciationClassName || '--'}
									</div>
								</Card>
							</Col>
						</div>
						<div className="rowSpacing">
							<Col span={24}>
								<Card
									title="Models"
									extra={
										<Button
											onClick={handleAssetModelCreateDrawerOpen}
											type="link"
											icon={<PlusOutlined translate="" />}
										>
											Add Model
										</Button>
									}
								>
									<PaginatedReduxTable
										showHeader={false}
										emptyState={
											<EmptyState
												graphic={
													<img
														style={{ marginBottom: 8 }}
														src="https://s3.amazonaws.com/mock-data-assets/categories/images/sunset.svg"
														alt="You haven't added any models load for this type"
													/>
												}
												headline={'Nothing much to see here.'}
												body={
													<div style={{ textAlign: 'center' }}>
														<div style={{ maxWidth: 440, marginBottom: 16 }}>
															You haven't added any models load for this type
														</div>
													</div>
												}
											/>
										}
										collectionName="asset_models"
										targetCollectionName={ASSET_MODELS_FOR_TYPES}
										columns={assetModelsColumns}
										onRow={onRow}
										keyAccessor={(el) => el.id}
										initialFilters={{ assetTypeId: assetTemplateId }}
										initialPagination={{ current: 1, pageSize: 10 }}
										fetchData={
											userType === ROLE_TYPES.SUPPLIER
												? assetModelsRestCrudThunksForSupplier.read
												: assetModelsRestCrudThunksForBuyer.read
										}
									/>
								</Card>
							</Col>
						</div>
					</Col>
				</Row>
			</Content>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => ({
	assetTypesFetching: state.asset_types.fetching,
	assetType: state.asset_types.detail,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getAssetType: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetTypesRestCrudThunksForSupplier.readOne(id)
				: assetTypesRestCrudThunksForBuyer.readOne(id)
		),
	getAssetModel: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetModelsRestCrudThunksForSupplier.readOne(id)
				: assetModelsRestCrudThunksForBuyer.readOne(id)
		),
	deleteAssetModel: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetModelsRestCrudThunksForSupplier.delete(entity, 'id', null, true)
				: assetModelsRestCrudThunksForBuyer.delete(entity, 'id', null, true)
		),
});

const ATDetailDetailsPage = withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(AssetTemplatesDetailPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ATDetailDetailsPage);

import * as React from 'react';
import { FC } from 'react';

interface LocationDropdownRecordProps {
	record: any;
}
export const LocationDropdownRecord: FC<LocationDropdownRecordProps> = ({ record }) => {
	return (
		<span key={`${record.id} - ${record.name}`}>
			<span>{record.name}</span>
			{record.isLocation && !record.isActive ? (
				<span style={{ marginLeft: 8, color: 'gray', fontWeight: 350, fontSize: 14 }}>
					(Inactive)
				</span>
			) : null}
		</span>
	);
};

import * as React from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Switch, Input, Alert, Button, Modal, Select } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import BackButton from '../back_button/BackButton';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import { getProtectedImageUriForSupplier } from '../../utils/FileAccessUtils';
import { imageUploadValidation, normFile } from '../../utils/ImageUtils';
import { LocationAutocomplete } from '../location_autocomplete/LocationAutocomplete';
import { supplierFacilitiesRestCrudThunksForSupplier } from '../../thunks/supplier_facilities_thunks';
import { partsPerStockLocationsRestCrudThunksForSupplier } from '../../thunks/parts_per_stock_locations_thunks';
import { partsRestCrudThunksForSupplier } from '../../thunks/parts_thunks';
import { retrieveCachedUserDetails } from '../../thunks/session_thunks';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { locationsRestCrudThunksForSupplier } from '../../thunks/locations_thunks';
import OWUpload from '../OWUpload';

require('./StockLocationForm.less');

interface StockLocationFormProps extends FormComponentProps {
	creating: boolean;
	updating: boolean;
	formData: any;
	history: any;
	redirectForwardUrl?: string;
	onSuccess?: any;
	createErrors: any[];
	updateErrors: any[];
	currentUser: any;
	create: any;
	update: any;
	createSupplierFacility: any;
	updateSupplierFacility: any;
	fetchPartsInStockLocation: any;
	fetchMultiplePartsInStockLocation: any;
	partsPerStockLocations: any;
	fetchPart: any;
	fetchParts: any;
	fetchMultipleParts: any;
	parts: any;
	locations: any;
	fetchMultipleLocations: any;
	fetchLocations: any;
}

class StockLocationForm extends React.Component<StockLocationFormProps, any> {
	state = {
		previewVisible: false,
		uploadLoading: false,
		previewImage: null,
		allPhotos: [],
	};

	componentDidMount() {
		const { formData } = this.props;

		let photosInfo;
		if (formData.images) {
			photosInfo = formData.images.map((p, idx) => {
				const [imgFileId, imgFileName] = p.split('/');
				const thumbUri = getProtectedImageUriForSupplier(imgFileId, imgFileName, 100, 100);
				const fullUri = getProtectedImageUriForSupplier(imgFileId, imgFileName, 400, 400);
				return {
					uid: idx,
					size: 1,
					name: imgFileName,
					filename: imgFileName,
					url: fullUri,
					status: 'done',
					thumbUrl: thumbUri,
					response: { data: { fileName: imgFileName, fileId: imgFileId } },
					type: 'image/jpeg',
				};
			});
		} else {
			photosInfo = [];
		}
		this.setState({ allPhotos: photosInfo });
	}

	handleSubmit = (e, submitAction) => {
		const { history, onSuccess, redirectForwardUrl } = this.props;
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const picturesInfo = values.images.map((p) => {
					const fileName = nullSafeGetOrElse('response.data.fileName', p, '');
					const fileId = nullSafeGetOrElse('response.data.fileId', p, '');
					return `${fileId}/${fileName}`;
				});

				const address = Object.keys(values)
					.filter((k) => k.indexOf('primaryAddress') > -1)
					.reduce((acc, k) => {
						const [_, x] = k.split('-');
						acc[x] = values[k];
						return acc;
					}, {});

				const stockLocation = {
					id: values.id,
					name: values.name,
					description: values.description,
					images: picturesInfo,
					...(!values.isMobile && !values.isExistingLocation && { address }),
					...(values.isExistingLocation && { locationId: values.locationId }),
					...(!!values.latitude && { latitude: values.latitude }),
					...(!!values.longitude && { longitude: values.longitude }),
					isMobile: values.isMobile,
					warehouseId: values.warehouseId,
					supplierFacilityId: values.supplierFacilityId,
					supplierCompanyId: values.supplierCompanyId,
				};

				submitAction(stockLocation).then((record) => {
					if (redirectForwardUrl) {
						history.push(redirectForwardUrl);
					}
					if (onSuccess) {
						onSuccess(record);
					}
				});
			}
		});
	};

	handlePreview = (file) => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true,
		});
	};

	handleCancel = () => this.setState({ previewVisible: false });

	handleImageUpdate = (info) => {
		if (info.file.status === 'done') {
			this.setState({ allPhotos: info.fileList });
		} else if (info.file.status === undefined) {
			info.fileList = this.state.allPhotos;
		}
	};

	handleLocationAutocompleteSelect = (addr, lonLat, parsedAddress) => {
		const { form } = this.props;
		form.setFieldsValue({
			'primaryAddress-streetAddress1': parsedAddress.streetAddress1,
			'primaryAddress-city': parsedAddress.city,
			'primaryAddress-region': parsedAddress.region,
			'primaryAddress-postcode': parsedAddress.postcode,
			'primaryAddress-country': parsedAddress.country,
			longitude: lonLat.lng,
			latitude: lonLat.lat,
		});
	};

	render() {
		const {
			updating,
			creating,
			form,
			update,
			create,
			createErrors,
			formData,
			updateErrors,
			currentUser,
			locations,
			fetchMultipleLocations,
			fetchLocations,
		} = this.props;
		const { previewVisible, previewImage } = this.state;
		const { getFieldDecorator } = form;
		const isUpdate = formData && formData.id !== undefined;
		const submitAction = isUpdate ? update : create;
		const submitText = isUpdate ? 'Update stock location' : 'Create stock location';
		const isLoading = updating || creating;
		const createRawAddress = (addr) => {
			if (!addr || addr === undefined) {
				return '';
			}
			return `${addr.streetAddress1}  ${addr.city}, ${addr.region || ''}, ${addr.postcode || ''}, ${
				addr.country
			}`;
		};

		const rawAddress = createRawAddress(nullSafeGet('address', formData));

		const BACKEND_URI = getBackendUri();
		const uploadHeaders = { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token };

		getFieldDecorator('supplierFacilityId', {
			initialValue: formData.supplierFacilityId || nullSafeGet('facility.id', currentUser),
		});
		getFieldDecorator('supplierCompanyId', {
			initialValue:
				formData.supplierCompanyId || nullSafeGet('facility.supplierCompanyId', currentUser),
		});

		getFieldDecorator('id', { initialValue: formData.id });
		getFieldDecorator(`primaryAddress-streetAddress1`, {
			initialValue: nullSafeGet('address.streetAddress1', formData),
		});
		getFieldDecorator(`primaryAddress-streetAddress2`, {
			initialValue: nullSafeGet('address.streetAddress2', formData),
		});
		getFieldDecorator(`primaryAddress-city`, {
			initialValue: nullSafeGet('address.city', formData),
		});
		getFieldDecorator(`primaryAddress-region`, {
			initialValue: nullSafeGet('address.region', formData),
		});
		getFieldDecorator(`primaryAddress-postcode`, {
			initialValue: nullSafeGet('address.postcode', formData),
		});
		getFieldDecorator(`primaryAddress-country`, {
			initialValue: nullSafeGet('address.country', formData),
		});
		getFieldDecorator(`longitude`, {
			initialValue: nullSafeGet('longitude', formData),
		});
		getFieldDecorator(`latitude`, {
			initialValue: nullSafeGet('latitude', formData),
		});

		return (
			<Form
				layout="vertical"
				onSubmit={(e) => this.handleSubmit(e, submitAction)}
				className="supplierForm"
			>
				{createErrors.length > 0 ? (
					<Form.Item>
						<Alert message={createErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				{updateErrors.length > 0 ? (
					<Form.Item>
						<Alert message={updateErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				<Form.Item label="Name">
					{getFieldDecorator('name', {
						initialValue: formData.name,
						rules: [{ required: true, message: 'This field is required.' }],
					})(<Input style={{ maxWidth: 640 }} />)}
				</Form.Item>
				<Form.Item label="Description">
					{getFieldDecorator('description', {
						initialValue: formData.description,
					})(<Input.TextArea style={{ maxWidth: 640 }} />)}
				</Form.Item>
				<Form.Item label="Warehouse id">
					{getFieldDecorator('warehouseId', {
						initialValue: formData.warehouseId,
					})(<Input style={{ maxWidth: 640 }} />)}
				</Form.Item>
				<Form.Item label="Photos">
					{getFieldDecorator(`images`, {
						valuePropName: 'fileList',
						getValueFromEvent: normFile,
						initialValue: this.state.allPhotos,
					})(
						<OWUpload
							accept="image/*"
							name="file"
							listType="picture-card"
							className="avatar-uploader"
							multiple={true}
							headers={uploadHeaders}
							onPreview={this.handlePreview}
							action={`${BACKEND_URI}/api/v1/supplier/file/upload`}
							beforeUpload={imageUploadValidation}
							onChange={this.handleImageUpdate}
						>
							<div style={{ display: 'inline' }}>
								<LegacyIcon type={this.state.uploadLoading ? 'loading' : 'plus'} />
								<div className="ant-upload-text">Upload</div>
							</div>
							<Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
								<img alt="example" style={{ width: '100%' }} src={previewImage} />
							</Modal>
						</OWUpload>
					)}
				</Form.Item>
				<Form.Item label="Is Mobile?">
					{getFieldDecorator('isMobile', {
						initialValue: formData.isMobile === undefined ? false : formData.isMobile,
						valuePropName: 'checked',
					})(<Switch />)}
				</Form.Item>
				<Form.Item label="Use Exisiting location?">
					{getFieldDecorator('isExistingLocation', {
						initialValue: formData.isExistingLocation || formData.locationId,
						valuePropName: 'checked',
					})(<Switch />)}
				</Form.Item>
				{form.getFieldValue(`isMobile`) || form.getFieldValue(`isExistingLocation`)
					? null
					: [
							<Form.Item required={true} label="Address">
								<div style={{ maxWidth: 800 }}>
									<LocationAutocomplete
										initialValue={rawAddress}
										placeholder="Search for and select an address"
										onSelect={this.handleLocationAutocompleteSelect}
									/>
								</div>
							</Form.Item>,
					  ]}
				{form.getFieldValue(`isExistingLocation`) ? (
					<Form.Item required={true} label="Location">
						{getFieldDecorator('locationId', {
							initialValue: formData.locationId,
						})(
							<OWAsyncSelect
								style={{ maxWidth: 800 }}
								stateSlice={locations}
								targetCollectionName={'locationsIndex'}
								fetchMultiple={(ids, targetCollectionName) => {
									fetchMultipleLocations(ids, targetCollectionName);
								}}
								fetchData={(
									searchText,
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								) => {
									fetchLocations(
										{ name: searchText },
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									);
								}}
								renderRecord={(location) => (
									<Select.Option key={location.id} value={location.id}>
										{location.name}
									</Select.Option>
								)}
								sortBy={{ sort_by: 'name', order: 'ascend' }}
							/>
						)}
					</Form.Item>
				) : null}
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						loading={isLoading}
						className="contactForm__button"
					>
						{submitText}
					</Button>
					<span style={{ marginLeft: '16px' }}>
						<BackButton buttonText="Cancel" />
					</span>
				</Form.Item>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	formData: ownProps.formData,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,

	locations: state.locations,
	createErrors: state.stock_locations.createErrors,
	updateErrors: state.stock_locations.updateErrors,
	creating: state.stock_locations.creating,
	updating: state.stock_locations.updating,
	currentUser: state.session.currentUser,
	partsPerStockLocations: state.parts_per_stock_locations,
	parts: state.parts,
});

const mapDispatchToProps = (dispatch) => ({
	update: (entity) => dispatch(stockLocationsRestCrudThunksForSupplier.update(entity)),
	create: (entity) => dispatch(stockLocationsRestCrudThunksForSupplier.create(entity)),
	fetchPartsInStockLocation: (params, targetCollectionName) =>
		dispatch(partsPerStockLocationsRestCrudThunksForSupplier.read(params, targetCollectionName)),
	fetchMultiplePartsInStockLocation: (ids, targetCollectionName) =>
		dispatch(
			partsPerStockLocationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
		),
	fetchPart: (id) => dispatch(partsRestCrudThunksForSupplier.readOne(id)),
	fetchParts: (params, targetCollectionName) =>
		dispatch(
			partsRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName
			)
		),
	fetchMultipleParts: (ids, targetCollectionName) =>
		dispatch(partsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	createSupplierFacility: (entity) =>
		dispatch(supplierFacilitiesRestCrudThunksForSupplier.create(entity)),
	updateSupplierFacility: (entity) =>
		dispatch(supplierFacilitiesRestCrudThunksForSupplier.update(entity)),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			locationsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
});

export default withRouter<any, any>(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<StockLocationFormProps>()(StockLocationForm))
);

import {
	FETCH_BUYER_AND_SUPPLIER_USERS,
	FETCH_BUYER_AND_SUPPLIER_USERS_FAILURE,
	FETCH_BUYER_AND_SUPPLIER_USERS_SUCCESS,
} from '../actions/work_orders_subscribers_actions';
import { SearchedSubscribersStoreState } from '../types/models';

const initialState: SearchedSubscribersStoreState = {
	records: {},
	detail: {},
	errors: [],
	loading: false,
	loadingUsers: new Set([]),
	addedUsers: new Set([]),
	removedUsers: new Set([]),
};
export default (oldState = initialState, action): SearchedSubscribersStoreState => {
	Object.freeze(oldState);
	let newRecords;
	let newLoadingUsers;
	switch (action.type) {
		case FETCH_BUYER_AND_SUPPLIER_USERS:
			return Object.assign({}, oldState, {
				errors: [],
				loading: true,
			});
		case FETCH_BUYER_AND_SUPPLIER_USERS_SUCCESS:
			return Object.assign({}, oldState, {
				records: action.records,
				errors: [],
				loading: false,
			});
		case FETCH_BUYER_AND_SUPPLIER_USERS_FAILURE:
			return Object.assign({}, oldState, {
				loading: false,
				errors: [oldState.errors, ...action.errors],
			});
		default:
			return oldState;
	}
};

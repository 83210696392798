import React, { FC, useCallback, useRef, useEffect, useMemo } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Layout, Row, Col, Button, Spin } from 'antd';
import SubnavBar from '../subnav_bar/SubnavBar';
import { connect } from 'react-redux';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import { withRouter } from 'react-router';
import { PrinterOutlined } from '@ant-design/icons';
import { equipmentPerStockLocationsRestCrudThunksForSupplier } from '../../thunks/equipment_per_stock_locations_thunks';
import EquipmentsLabels from './EquipmentLabels';

const { Content } = Layout;

require('./PrintEquipmentLabels.less');

const STOCK_LOCATION_ASSOCIATED_EQUIPMENT_TARGET_COLLECTION = 'stockLocationAssociatedEquipments';

const PrintEquipmentLabels: FC<any> = ({
	match,
	getStockLocation,
	getEquipmentPerStockLocations,
	stockLocation,
	stockLocationsFetching,
	equipmentPerStockLocations,
	equipmentPerStockLocationsFetching,
	history,
}): React.ReactElement => {
	const stockLocationId = useMemo(() => match.params.id, [match.params.id]);

	useEffect(() => {
		getStockLocation(stockLocationId);
		getEquipmentPerStockLocations(
			{ stockLocationId, isDisbursed: false },

			STOCK_LOCATION_ASSOCIATED_EQUIPMENT_TARGET_COLLECTION,
			{
				current: 1,
				pageSize: 250,
			}
		);
	}, [getEquipmentPerStockLocations, getStockLocation, stockLocationId]);

	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const backToStockLocationEquipmentsPage = useCallback(() => history.goBack(), [history]);

	return (
		<Content style={{ padding: '0 0.5em' }}>
			{equipmentPerStockLocationsFetching ? (
				<div className="m-4 flex flex-row justify-center">
					<Spin />
				</div>
			) : (
				<div>
					<Row style={{ margin: '0.5em -8px' }} gutter={16}>
						<Col span={24}>
							<SubnavBar
								left={null}
								right={
									<div>
										<Button onClick={backToStockLocationEquipmentsPage} size="large">
											Cancel
										</Button>
										<Button
											onClick={handlePrint}
											className="ml-2"
											type="primary"
											icon={<PrinterOutlined translate="" />}
											size="large"
										>
											Print Labels
										</Button>
									</div>
								}
							/>
						</Col>
					</Row>
					<Row style={{ margin: '0.5em -8px' }} gutter={16}>
						<Col span={24}>
							<EquipmentsLabels
								stockLocation={stockLocation}
								stockLocationsFetching={stockLocationsFetching}
								equipmentPerStockLocations={equipmentPerStockLocations}
								equipmentPerStockLocationsFetching={equipmentPerStockLocationsFetching}
								ref={componentRef}
							/>
						</Col>
					</Row>
				</div>
			)}
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	equipmentPerStockLocations: state.equipment_per_stock_locations,
	equipmentPerStockLocationsFetching: state.equipment_per_stock_locations.fetching,
	stockLocation: state.stock_locations.detail,
	stockLocationsFetching: state.stock_locations.fetching,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	getStockLocation: (id) => dispatch(stockLocationsRestCrudThunksForSupplier.readOne(id)),
	getEquipmentPerStockLocations: (params, targetCollectionName, pagination) =>
		dispatch(
			equipmentPerStockLocationsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination
			)
		),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrintEquipmentLabels));

import * as React from 'react';
import { PAGE_SIZE_OPTIONS } from '../../constants/pagination';
import queryString from 'querystring';

export default class defaultTablePagination {
	cached;
	component;
	current: any;
	stateName;
	total;
	pageSize;
	pageSizeOptions;
	showSizeChanger;
	onShowSizeChange;
	onChange;
	constructor(
		component,
		current: any = 1,
		pageSize: any = 10,
		stateName = 'pagination',
		pageSizeOptions = PAGE_SIZE_OPTIONS,
		showSizeChanger = true,
		handleSizeChange = () => null
	) {
		this.cached = {};
		this.component = component;
		this.current = parseInt(current);
		this.pageSize = parseInt(pageSize);
		this.pageSizeOptions = pageSizeOptions;
		this.showSizeChanger = showSizeChanger;
		this.onShowSizeChange = handleSizeChange ? this.handleSizeChange : handleSizeChange;
		this.onChange = handleSizeChange ? this.handleSizeChange : handleSizeChange;
		this.stateName = stateName;

		if (this.component.hasOwnProperty('handleSizeChange')) {
			this.cached['handleSizeChange'] = this.component.handleSizeChange;
		}
	}
	handleSizeChange = (page, size) => {
		this.current = page;
		this.pageSize = size;
		if (this.cached.hasOwnProperty('handleSizeChange')) {
			this.cached.handleSizeChange(page, size);
		}
		let stateObj = {};
		stateObj[this.stateName] = { ...this };
		this.component.setState(stateObj);
	};

	//For Managing what does or does not go into the url (paginated redux table)
	getURL = () => {
		return {
			current: this.current,
			pageSize: this.pageSize,
		};
	};
	getOther = () => {
		return {
			onShowSizeChange: this.handleSizeChange,
			onChange: this.handleSizeChange,
			showSizeChanger: this.showSizeChanger,
			pageSizeOptions: this.pageSizeOptions,
			handleSizeChange: this.handleSizeChange,
		};
	};
}

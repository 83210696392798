import React, { FC } from 'react';

const GreyStatusTag: FC<any> = ({ text }): React.ReactElement => (
	<span
		style={{
			color: '#555',
			background: 'rgba(0, 0, 0, 0.1)',
			padding: '4px 12px',
			borderRadius: 16,
			whiteSpace: 'nowrap',
		}}
	>
		{text}
	</span>
);

export default GreyStatusTag;

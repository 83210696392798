import {
	CLEAR_CATEGORY_FILTERS,
	CLEAR_FACILITY_FILTERS,
	CLEAR_VENDOR_FILTERS,
	FETCH_SPEND_BY_CATEGORY_FAILURE,
	FETCH_SPEND_BY_CATEGORY_START,
	FETCH_SPEND_BY_CATEGORY_SUCCESS,
	FETCH_SPEND_BY_PROBLEM_TYPE_FAILURE,
	FETCH_SPEND_BY_PROBLEM_TYPE_START,
	FETCH_SPEND_BY_PROBLEM_TYPE_SUCCESS,
	FETCH_SPEND_BY_LOCATION_FAILURE,
	FETCH_SPEND_BY_LOCATION_START,
	FETCH_SPEND_BY_LOCATION_SUCCESS,
	FETCH_SPEND_BY_FACILITY_FAILURE,
	FETCH_SPEND_BY_FACILITY_START,
	FETCH_SPEND_BY_FACILITY_SUCCESS,
	FETCH_SPEND_BY_MONTH_FAILURE,
	FETCH_SPEND_BY_MONTH_START,
	FETCH_SPEND_BY_MONTH_SUCCESS,
	FETCH_SPEND_BY_VENDOR_FAILURE,
	FETCH_SPEND_BY_VENDOR_START,
	FETCH_SPEND_BY_VENDOR_SUCCESS,
	UPDATE_CATEGORY_FILTERS,
	UPDATE_PROBLEM_TYPE_FILTERS,
	UPDATE_FACILITY_FILTERS,
	UPDATE_VENDOR_FILTERS,
	CLEAR_LOCATION_FILTERS,
	UPDATE_LOCATION_FILTERS,
	CLEAR_PROBLEM_TYPE_FILTERS,
	CLEAR_REGION_FILTERS,
	UPDATE_REGION_FILTERS,
	FETCH_SPEND_BY_REGION_START,
	FETCH_SPEND_BY_REGION_FAILURE,
	FETCH_SPEND_BY_REGION_SUCCESS,
} from '../actions/spend_analytics_actions';
import { SpendAnalyticsStoreState } from '../types/models';

const initialState: SpendAnalyticsStoreState = {
	spendByMonth: { data: [], loading: false, errors: [] },
	spendByCategory: { data: [], loading: false, errors: [] },
	spendByProblemType: { data: [], loading: false, errors: [] },
	spendByVendor: { data: [], loading: false, errors: [] },
	spendByFacility: { data: [], loading: false, errors: [] },
	spendByLocation: { data: [], loading: false, errors: [] },
	spendByRegion: { data: [], loading: false, errors: [] },
	categoryFilters: {},
	problemTypeFilters: {},
	locationFilters: {},
	regionFilters: {},
	facilityFilters: {},
	vendorFilters: {},
};

export default (oldState = initialState, action): SpendAnalyticsStoreState => {
	Object.freeze(oldState);
	switch (action.type) {
		default:
			return oldState;
		case FETCH_SPEND_BY_MONTH_START:
			return {
				...oldState,
				spendByMonth: {
					...oldState.spendByMonth,
					loading: true,
				},
			};
		case FETCH_SPEND_BY_CATEGORY_START:
			return {
				...oldState,
				spendByCategory: {
					...oldState.spendByCategory,
					loading: true,
				},
			};
		case FETCH_SPEND_BY_PROBLEM_TYPE_START:
			return {
				...oldState,
				spendByProblemType: {
					...oldState.spendByProblemType,
					loading: true,
				},
			};
		case FETCH_SPEND_BY_VENDOR_START:
			return {
				...oldState,
				spendByVendor: {
					...oldState.spendByVendor,
					loading: true,
				},
			};
		case FETCH_SPEND_BY_LOCATION_START:
			return {
				...oldState,
				spendByLocation: {
					...oldState.spendByLocation,
					loading: true,
				},
			};
		case FETCH_SPEND_BY_REGION_START:
			return {
				...oldState,
				spendByRegion: {
					...oldState.spendByRegion,
					loading: true,
				},
			};
		case FETCH_SPEND_BY_FACILITY_START:
			return {
				...oldState,
				spendByFacility: {
					...oldState.spendByFacility,
					loading: true,
				},
			};
		case FETCH_SPEND_BY_MONTH_FAILURE:
			return {
				...oldState,
				spendByMonth: {
					...oldState.spendByMonth,
					loading: false,
					errors: [...oldState.spendByMonth.errors, ...action.errors],
				},
			};
		case FETCH_SPEND_BY_CATEGORY_FAILURE:
			return {
				...oldState,
				spendByCategory: {
					...oldState.spendByCategory,
					loading: false,
					errors: [...oldState.spendByCategory.errors, ...action.errors],
				},
			};
		case FETCH_SPEND_BY_PROBLEM_TYPE_FAILURE:
			return {
				...oldState,
				spendByProblemType: {
					...oldState.spendByProblemType,
					loading: false,
					errors: [...oldState.spendByProblemType.errors, ...action.errors],
				},
			};
		case FETCH_SPEND_BY_VENDOR_FAILURE:
			return {
				...oldState,
				spendByVendor: {
					...oldState.spendByVendor,
					loading: false,
					errors: [...oldState.spendByVendor.errors, ...action.errors],
				},
			};
		case FETCH_SPEND_BY_LOCATION_FAILURE:
			return {
				...oldState,
				spendByLocation: {
					...oldState.spendByLocation,
					loading: false,
					errors: [...oldState.spendByLocation.errors, ...action.errors],
				},
			};
		case FETCH_SPEND_BY_REGION_FAILURE:
			return {
				...oldState,
				spendByRegion: {
					...oldState.spendByRegion,
					loading: false,
					errors: [...oldState.spendByRegion.errors, ...action.errors],
				},
			};
		case FETCH_SPEND_BY_FACILITY_FAILURE:
			return {
				...oldState,
				spendByFacility: {
					...oldState.spendByFacility,
					loading: false,
					errors: [...oldState.spendByFacility.errors, ...action.errors],
				},
			};
		case FETCH_SPEND_BY_MONTH_SUCCESS:
			return {
				...oldState,
				spendByMonth: {
					...oldState.spendByMonth,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_SPEND_BY_CATEGORY_SUCCESS:
			return {
				...oldState,
				spendByCategory: {
					...oldState.spendByCategory,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_SPEND_BY_PROBLEM_TYPE_SUCCESS:
			return {
				...oldState,
				spendByProblemType: {
					...oldState.spendByProblemType,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_SPEND_BY_VENDOR_SUCCESS:
			return {
				...oldState,
				spendByVendor: {
					...oldState.spendByVendor,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_SPEND_BY_LOCATION_SUCCESS:
			return {
				...oldState,
				spendByLocation: {
					...oldState.spendByLocation,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_SPEND_BY_REGION_SUCCESS:
			return {
				...oldState,
				spendByRegion: {
					...oldState.spendByRegion,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_SPEND_BY_FACILITY_SUCCESS:
			return {
				...oldState,
				spendByFacility: {
					...oldState.spendByFacility,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case CLEAR_CATEGORY_FILTERS:
			return {
				...oldState,
				categoryFilters: {},
			};
		case UPDATE_CATEGORY_FILTERS:
			return {
				...oldState,
				categoryFilters: {
					...oldState.categoryFilters,
					...action.filters,
				},
			};
		case CLEAR_PROBLEM_TYPE_FILTERS:
			return {
				...oldState,
				problemTypeFilters: {},
			};
		case UPDATE_PROBLEM_TYPE_FILTERS:
			return {
				...oldState,
				problemTypeFilters: {
					...oldState.problemTypeFilters,
					...action.filters,
				},
			};
		case CLEAR_LOCATION_FILTERS:
			return {
				...oldState,
				locationFilters: {},
			};
		case UPDATE_LOCATION_FILTERS:
			return {
				...oldState,
				locationFilters: {
					...oldState.locationFilters,
					...action.filters,
				},
			};
		case CLEAR_REGION_FILTERS:
			return {
				...oldState,
				regionFilters: {},
			};
		case UPDATE_REGION_FILTERS:
			return {
				...oldState,
				regionFilters: {
					...oldState.regionFilters,
					...action.filters,
				},
			};
		case CLEAR_FACILITY_FILTERS:
			return {
				...oldState,
				facilityFilters: {},
			};
		case UPDATE_FACILITY_FILTERS:
			return {
				...oldState,
				facilityFilters: {
					...oldState.facilityFilters,
					...action.filters,
				},
			};
		case CLEAR_VENDOR_FILTERS:
			return {
				...oldState,
				vendorFilters: {},
			};
		case UPDATE_VENDOR_FILTERS:
			return {
				...oldState,
				vendorFilters: {
					...oldState.vendorFilters,
					...action.filters,
				},
			};
	}
};

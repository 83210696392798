export const FETCH_SPEND_BY_MONTH_START = 'FETCH_SPEND_BY_MONTH_START';
export const FETCH_SPEND_BY_MONTH_SUCCESS = 'FETCH_SPEND_BY_MONTH_SUCCESS';
export const FETCH_SPEND_BY_MONTH_FAILURE = 'FETCH_SPEND_BY_MONTH_FAILURE';

export const FETCH_SPEND_BY_WEEK_START = 'FETCH_SPEND_BY_WEEK_START';
export const FETCH_SPEND_BY_WEEK_SUCCESS = 'FETCH_SPEND_BY_WEEK_SUCCESS';
export const FETCH_SPEND_BY_WEEK_FAILURE = 'FETCH_SPEND_BY_WEEK_FAILURE';

export const FETCH_SPEND_BY_DAY_START = 'FETCH_SPEND_BY_DAY_START';
export const FETCH_SPEND_BY_DAY_SUCCESS = 'FETCH_SPEND_BY_DAY_SUCCESS';
export const FETCH_SPEND_BY_DAY_FAILURE = 'FETCH_SPEND_BY_DAY_FAILURE';

export const FETCH_SPEND_BY_CATEGORY_START = 'FETCH_SPEND_BY_CATEGORY_START';
export const FETCH_SPEND_BY_PROBLEM_TYPE_START = 'FETCH_SPEND_BY_PROBLEM_TYPE_START';
export const FETCH_SPEND_BY_VENDOR_START = 'FETCH_SPEND_BY_VENDOR_START';
export const FETCH_SPEND_BY_LOCATION_START = 'FETCH_SPEND_BY_LOCATION_START';
export const FETCH_SPEND_BY_REGION_START = 'FETCH_SPEND_BY_REGION_START';
export const FETCH_SPEND_BY_FACILITY_START = 'FETCH_SPEND_BY_FACILITY_START';
export const FETCH_SPEND_TIME_SERIES_BY_CONTRACT_STATUS_START =
	'FETCH_SPEND_TIME_SERIES_BY_CONTRACT_STATUS_START';

export const FETCH_SPEND_BY_CATEGORY_SUCCESS = 'FETCH_SPEND_BY_CATEGORY_SUCCESS';
export const FETCH_SPEND_BY_PROBLEM_TYPE_SUCCESS = 'FETCH_SPEND_BY_PROBLEM_TYPE_SUCCESS';
export const FETCH_SPEND_BY_VENDOR_SUCCESS = 'FETCH_SPEND_BY_VENDOR_SUCCESS';
export const FETCH_SPEND_BY_LOCATION_SUCCESS = 'FETCH_SPEND_BY_LOCATION_SUCCESS';
export const FETCH_SPEND_BY_REGION_SUCCESS = 'FETCH_SPEND_BY_REGION_SUCCESS';
export const FETCH_SPEND_BY_FACILITY_SUCCESS = 'FETCH_SPEND_BY_FACILITY_SUCCESS';
export const FETCH_SPEND_TIME_SERIES_BY_CONTRACT_STATUS_SUCCESS =
	'FETCH_SPEND_TIME_SERIES_BY_CONTRACT_STATUS_SUCCESS';

export const FETCH_SPEND_BY_CATEGORY_FAILURE = 'FETCH_SPEND_BY_CATEGORY_FAILURE';
export const FETCH_SPEND_BY_PROBLEM_TYPE_FAILURE = 'FETCH_SPEND_BY_PROBLEM_TYPE_FAILURE';
export const FETCH_SPEND_BY_VENDOR_FAILURE = 'FETCH_SPEND_BY_VENDOR_FAILURE';
export const FETCH_SPEND_BY_LOCATION_FAILURE = 'FETCH_SPEND_BY_LOCATION_FAILURE';
export const FETCH_SPEND_BY_REGION_FAILURE = 'FETCH_SPEND_BY_REGION_FAILURE';
export const FETCH_SPEND_BY_FACILITY_FAILURE = 'FETCH_SPEND_BY_FACILITY_FAILURE';
export const FETCH_SPEND_TIME_SERIES_BY_CONTRACT_STATUS_FAILURE =
	'FETCH_SPEND_TIME_SERIES_BY_CONTRACT_STATUS_FAILURE';

export const FETCH_SPEND_BY_TOP_CATEGORY_START = 'FETCH_SPEND_BY_TOP_CATEGORY_START';
export const FETCH_SPEND_BY_TOP_VENDOR_START = 'FETCH_SPEND_BY_TOP_VENDOR_START';
export const FETCH_SPEND_BY_TOP_FACILITY_START = 'FETCH_SPEND_BY_TOP_FACILITY_START';

export const FETCH_SPEND_BY_TOP_CATEGORY_SUCCESS = 'FETCH_SPEND_BY_TOP_CATEGORY_SUCCESS';
export const FETCH_SPEND_BY_TOP_VENDOR_SUCCESS = 'FETCH_SPEND_BY_TOP_VENDOR_SUCCESS';
export const FETCH_SPEND_BY_TOP_FACILITY_SUCCESS = 'FETCH_SPEND_BY_TOP_FACILITY_SUCCESS';

export const FETCH_SPEND_BY_TOP_CATEGORY_FAILURE = 'FETCH_SPEND_BY_TOP_CATEGORY_FAILURE';
export const FETCH_SPEND_BY_TOP_VENDOR_FAILURE = 'FETCH_SPEND_BY_TOP_VENDOR_FAILURE';
export const FETCH_SPEND_BY_TOP_FACILITY_FAILURE = 'FETCH_SPEND_BY_TOP_FACILITY_FAILURE';

export const UPDATE_CATEGORY_FILTERS = 'UPDATE_CATEGORY_FILTERS';
export const CLEAR_CATEGORY_FILTERS = 'CLEAR_CATEGORY_FILTERS';

export const UPDATE_PROBLEM_TYPE_FILTERS = 'UPDATE_PROBLEM_TYPE_FILTERS';
export const CLEAR_PROBLEM_TYPE_FILTERS = 'CLEAR_PROBLEM_TYPE_FILTERS';

export const UPDATE_VENDOR_FILTERS = 'UPDATE_VENDOR_FILTERS';
export const CLEAR_VENDOR_FILTERS = 'CLEAR_VENDOR_FILTERS';

export const UPDATE_FACILITY_FILTERS = 'UPDATE_FACILITY_FILTERS';
export const CLEAR_FACILITY_FILTERS = 'CLEAR_FACILITY_FILTERS';

export const UPDATE_LOCATION_FILTERS = 'UPDATE_LOCATION_FILTERS';
export const CLEAR_LOCATION_FILTERS = 'CLEAR_LOCATION_FILTERS';

export const UPDATE_REGION_FILTERS = 'UPDATE_REGION_FILTERS';
export const CLEAR_REGION_FILTERS = 'CLEAR_REGION_FILTERS';

export function fetchSpendByMonthStart() {
	return {
		type: FETCH_SPEND_BY_MONTH_START,
	};
}

export function fetchSpendByWeekStart() {
	return {
		type: FETCH_SPEND_BY_WEEK_START,
	};
}

export function fetchSpendByDayStart() {
	return {
		type: FETCH_SPEND_BY_DAY_START,
	};
}

export function fetchSpendByCategoryStart() {
	return {
		type: FETCH_SPEND_BY_CATEGORY_START,
	};
}

export function fetchSpendByProblemTypeStart() {
	return {
		type: FETCH_SPEND_BY_PROBLEM_TYPE_START,
	};
}

export function fetchSpendByVendorStart() {
	return {
		type: FETCH_SPEND_BY_VENDOR_START,
	};
}

export function fetchSpendByLocationStart() {
	return {
		type: FETCH_SPEND_BY_LOCATION_START,
	};
}

export function fetchSpendByRegionStart() {
	return {
		type: FETCH_SPEND_BY_REGION_START,
	};
}

export function fetchSpendByFacilityStart() {
	return {
		type: FETCH_SPEND_BY_FACILITY_START,
	};
}

export function fetchSpendByMonthSuccess(data) {
	return {
		type: FETCH_SPEND_BY_MONTH_SUCCESS,
		data,
	};
}

export function fetchSpendByWeekSuccess(data) {
	return {
		type: FETCH_SPEND_BY_WEEK_SUCCESS,
		data,
	};
}

export function fetchSpendByDaySuccess(data) {
	return {
		type: FETCH_SPEND_BY_DAY_SUCCESS,
		data,
	};
}

export function fetchSpendByCategorySuccess(data) {
	return {
		type: FETCH_SPEND_BY_CATEGORY_SUCCESS,
		data,
	};
}

export function fetchSpendByProblemTypeSuccess(data) {
	return {
		type: FETCH_SPEND_BY_PROBLEM_TYPE_SUCCESS,
		data,
	};
}

export function fetchSpendByVendorSuccess(data) {
	return {
		type: FETCH_SPEND_BY_VENDOR_SUCCESS,
		data,
	};
}

export function fetchSpendByLocationSuccess(data) {
	return {
		type: FETCH_SPEND_BY_LOCATION_SUCCESS,
		data,
	};
}

export function fetchSpendByRegionSuccess(data) {
	return {
		type: FETCH_SPEND_BY_REGION_SUCCESS,
		data,
	};
}

export function fetchSpendByFacilitySuccess(data) {
	return {
		type: FETCH_SPEND_BY_FACILITY_SUCCESS,
		data,
	};
}

export function fetchSpendByMonthFailure(errors) {
	return {
		type: FETCH_SPEND_BY_MONTH_FAILURE,
		errors,
	};
}

export function fetchSpendByWeekFailure(errors) {
	return {
		type: FETCH_SPEND_BY_WEEK_FAILURE,
		errors,
	};
}

export function fetchSpendByDayFailure(errors) {
	return {
		type: FETCH_SPEND_BY_DAY_FAILURE,
		errors,
	};
}

export function fetchSpendByCategoryFailure(errors) {
	return {
		type: FETCH_SPEND_BY_CATEGORY_FAILURE,
		errors,
	};
}

export function fetchSpendByProblemTypeFailure(errors) {
	return {
		type: FETCH_SPEND_BY_PROBLEM_TYPE_FAILURE,
		errors,
	};
}

export function fetchSpendByVendorFailure(errors) {
	return {
		type: FETCH_SPEND_BY_VENDOR_FAILURE,
		errors,
	};
}

export function fetchSpendByLocationFailure(errors) {
	return {
		type: FETCH_SPEND_BY_LOCATION_FAILURE,
		errors,
	};
}

export function fetchSpendByRegionFailure(errors) {
	return {
		type: FETCH_SPEND_BY_REGION_FAILURE,
		errors,
	};
}

export function fetchSpendByFacilityFailure(errors) {
	return {
		type: FETCH_SPEND_BY_FACILITY_FAILURE,
		errors,
	};
}

export function fetchSpendByTopCategoryStart() {
	return {
		type: FETCH_SPEND_BY_TOP_CATEGORY_START,
	};
}

export function fetchSpendByTopVendorStart() {
	return {
		type: FETCH_SPEND_BY_TOP_VENDOR_START,
	};
}

export function fetchSpendByTopFacilityStart() {
	return {
		type: FETCH_SPEND_BY_TOP_FACILITY_START,
	};
}

export function fetchSpendByTopCategorySuccess(data) {
	return {
		type: FETCH_SPEND_BY_TOP_CATEGORY_SUCCESS,
		data,
	};
}

export function fetchSpendByTopVendorSuccess(data) {
	return {
		type: FETCH_SPEND_BY_TOP_VENDOR_SUCCESS,
		data,
	};
}

export function fetchSpendByTopFacilitySuccess(data) {
	return {
		type: FETCH_SPEND_BY_TOP_FACILITY_SUCCESS,
		data,
	};
}

export function fetchSpendByTopCategoryFailure(errors) {
	return {
		type: FETCH_SPEND_BY_TOP_CATEGORY_FAILURE,
		errors,
	};
}

export function fetchSpendByTopVendorFailure(errors) {
	return {
		type: FETCH_SPEND_BY_TOP_VENDOR_FAILURE,
		errors,
	};
}

export function fetchSpendByTopFacilityFailure(errors) {
	return {
		type: FETCH_SPEND_BY_TOP_FACILITY_FAILURE,
		errors,
	};
}

export function updateCategoryFilters(filters) {
	return {
		type: UPDATE_CATEGORY_FILTERS,
		filters,
	};
}

export function clearCategoryFilters() {
	return {
		type: CLEAR_CATEGORY_FILTERS,
	};
}

export function updateProblemTypeFilters(filters) {
	return {
		type: UPDATE_PROBLEM_TYPE_FILTERS,
		filters,
	};
}

export function clearProblemTypeFilters() {
	return {
		type: CLEAR_PROBLEM_TYPE_FILTERS,
	};
}

export function updateLocationFilters(filters) {
	return {
		type: UPDATE_LOCATION_FILTERS,
		filters,
	};
}

export function clearLocationFilters() {
	return {
		type: CLEAR_LOCATION_FILTERS,
	};
}

export function updateRegionFilters(filters) {
	return {
		type: UPDATE_REGION_FILTERS,
		filters,
	};
}

export function clearRegionFilters() {
	return {
		type: CLEAR_REGION_FILTERS,
	};
}

export function updateVendorFilters(filters) {
	return {
		type: UPDATE_VENDOR_FILTERS,
		filters,
	};
}

export function clearVendorFilters() {
	return {
		type: CLEAR_VENDOR_FILTERS,
	};
}

export function updateFacilityFilters(filters) {
	return {
		type: UPDATE_FACILITY_FILTERS,
		filters,
	};
}

export function clearFacilityFilters() {
	return {
		type: CLEAR_FACILITY_FILTERS,
	};
}

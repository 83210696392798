import React, { FC } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Avatar } from 'antd';

const style = require('./SocialMediaBadge.less');

const SOCIAL_MEDIA_UTILS = {
	twitter: {
		icon: 'twitter',
		linkGenerator: (handle) => `https://www.twitter.com/${handle}`,
		color: 'rgb(0, 172, 237)',
	},
	facebook: {
		icon: 'facebook',
		linkGenerator: (handle) => `https://www.facebook.com/${handle}`,
		color: 'rgb(59, 89, 152)',
	},
	crunchbase: {
		linkGenerator: (handle) => `https://www.crunchbase.com/${handle}`,
		color: 'rgb(22, 137, 207)',
		text: 'CB',
	},
	linkedIn: {
		icon: 'linkedin',
		linkGenerator: (handle) => `https://www.linkedin.com/${handle}`,
		color: 'rgb(0, 123, 182)',
	},
};

const VALID_SOCIAL_MEDIA_TYPES = new Set(Object.keys(SOCIAL_MEDIA_UTILS));
const isValidSocialMediaType = (type) => VALID_SOCIAL_MEDIA_TYPES.has(type);

export const SocialMediaBadge: FC<any> = ({ type, handle }) => {
	if (!isValidSocialMediaType(type)) {
		throw new Error('invalid social media type' + type);
	}
	return (
		<a href={SOCIAL_MEDIA_UTILS[type].linkGenerator(handle)}>
			<Avatar
				style={{
					boxShadow: '5px 5px 10px rgba(0,0,0,0.15)',
					height: 40,
					width: 40,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					borderRadius: '50%',
					background: SOCIAL_MEDIA_UTILS[type].color,
				}}
			>
				{SOCIAL_MEDIA_UTILS[type].icon !== undefined ? (
					<LegacyIcon
						style={{ position: 'relative', top: 4, fontSize: 20 }}
						className="socialIcon"
						type={SOCIAL_MEDIA_UTILS[type].icon}
					/>
				) : (
					<span style={{ fontSize: 18 }}>{SOCIAL_MEDIA_UTILS[type].text}</span>
				)}
			</Avatar>
		</a>
	);
};

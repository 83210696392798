import * as React from 'react';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Alert, Button } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { supplierFacilitiesRestCrudThunksForSupplier } from '../../thunks/supplier_facilities_thunks';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { spendCategoriesRestCrudThunksForSupplier } from '../../thunks/spend_categories_thunks';
import OWAsyncCheckbox from '../visual_checkbox_input/VisualCheckboxInput';

const FormItem = Form.Item;
const style = require('./EditSupplierSpendCategoriesForm.less');

interface EditSupplierSpendCategoriesFormProps extends FormComponentProps {
	loading: boolean;
	updateSupplier: any;
	successCallback: any;
	cancelCallback: any;
	supplierFacility: any;
	spendCategories: any;
	fetchMultipleSpendCategories: any;
	fetchSpendCategories: any;

	errors: string[];
}

class EditSupplierSpendCategoriesForm extends React.Component<
	EditSupplierSpendCategoriesFormProps,
	any
> {
	handleSubmit = (e) => {
		const { updateSupplier, spendCategories, successCallback, supplierFacility, form } = this.props;
		e.preventDefault();

		form.validateFields((err, values) => {
			if (!err) {
				const spendCategoriesRecordsMap = spendCategories.records;
				const categoryIds = nullSafeGetOrElse('categoryIds', values, []).map((id) => parseInt(id));
				const categoryNames = nullSafeGetOrElse('categoryIds', values, []).map(
					(id) => spendCategoriesRecordsMap[id].name
				);

				const updatedSupplier = {
					...supplierFacility,
					categoryIds,
					categoryNames,
				};
				updateSupplier(updatedSupplier).then((resp) => successCallback(resp));
			}
		});
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const {
			loading,
			errors,
			supplierFacility,
			spendCategories,
			fetchMultipleSpendCategories,
			fetchSpendCategories,
			cancelCallback,
		} = this.props;
		return (
			<Form onSubmit={this.handleSubmit} className="editSupplierSpendCategoriesForm">
				{errors && errors.length > 0
					? errors.map((msg, idx) => (
							<FormItem key={idx}>
								<Alert message={msg} type="error" />
							</FormItem>
					  ))
					: null}
				<div key={5}>
					<h3 className="editSupplierSpendCategoriesForm__title">
						Select the services your company provides
					</h3>
					<Form.Item>
						{getFieldDecorator(`categoryIds`, {
							initialValue: supplierFacility.categoryIds,
						})(
							<OWAsyncCheckbox
								mode="multiple"
								fieldName="spendCategoryIds"
								stateSlice={spendCategories}
								initialPagination={{ current: 1, pageSize: 525 }}
								// onChange={this.handleSpendCategoriesChange}
								keepFetchedOrder={true}
								targetCollectionName={'SUPPLIER_FACILITY_SPEND_CATEGORIES_AUTOCOMPLETE'}
								fetchMultiple={(ids, targetCollectionName) =>
									fetchMultipleSpendCategories(ids, targetCollectionName)
								}
								fetchData={(
									searchText,
									targetCollectionName,
									pagination = null,
									sorting = {
										sort_by: 'name',
										order: 'ascend',
									},
									filters = null,
									addToTargetCollection = false
								) =>
									fetchSpendCategories(
										{
											name: searchText,
										},
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									)
								}
								renderRecord={(spendCategory) => spendCategory.name}
							/>
						)}
					</Form.Item>
				</div>
				<FormItem>
					<Button type="primary" htmlType="submit" loading={loading}>
						Update services provided
					</Button>
					<Button style={{ marginLeft: 16 }} onClick={cancelCallback}>
						Cancel
					</Button>
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	loading: state.supplier_facilities.loading,
	errors: state.supplier_facilities.updateErrors,
	spendCategories: state.spend_categories,
	successCallback: ownProps.successCallback,
	cancelCallback: ownProps.cancelCallback,
	supplierFacility: ownProps.supplierFacility,
});

const mapDispatchToProps = (dispatch) => ({
	updateSupplier: (supplierFacility) =>
		dispatch(supplierFacilitiesRestCrudThunksForSupplier.update(supplierFacility)),
	fetchMultipleSpendCategories: (ids, targetCollectionName) =>
		dispatch(spendCategoriesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchSpendCategories: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			spendCategoriesRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
});

export default withRouter<any, any>(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<EditSupplierSpendCategoriesFormProps>()(EditSupplierSpendCategoriesForm))
);

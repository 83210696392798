import React, { FC, useEffect, useMemo } from 'react';

import '@ant-design/compatible/assets/index.css';
import { Modal, Alert, Select, Switch, Form } from 'antd';
import { connect } from 'react-redux';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import {
	supplierFacilitiesRestCrudThunksForBuyer,
	supplierFacilitiesRestCrudThunksForSupplier,
} from '../../thunks/supplier_facilities_thunks';
import {
	spendCategoriesRestCrudThunksForBuyer,
	spendCategoriesRestCrudThunksForSupplier,
} from '../../thunks/spend_categories_thunks';
import {
	fetchLocationsWithRegionsForBuyer,
	fetchLocationsWithRegionsForSupplier,
	locationsRestCrudThunksForBuyer,
	locationsRestCrudThunksForSupplier,
} from '../../thunks/locations_thunks';
import {
	problemTypesHeirarchicalForBuyer,
	problemTypesHeirarchicalForSupplier,
	problemTypesRestCrudThunksForBuyer,
	problemTypesRestCrudThunksForSupplier,
} from '../../thunks/problem_types_thunks';
import ProblemTypeTreeSelect from '../problem_type_tree_select';
import {
	regionsRestCrudThunksForBuyer,
	regionsRestCrudThunksForSupplier,
} from '../../thunks/regions_thunks';
import OWLocationAsyncTreeSelect from '../ow_location_async_tree_select/OWLocationAsyncTreeSelect';
import { LocationDropdownRecord } from '../name_component/location/LocationDropdownRecord';
import { useState } from 'react';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { withRouter } from 'react-router';

const FormItem = Form.Item;

const SupplierPrivateNetworkEditForm: FC<any> = ({
	supplierFacility = {},
	locations,
	fetchProblemTypes,
	fetchMultipleProblemTypes,
	problemTypes,
	visible,
	onCancel,
	onSubmit,
	createErrors,
	updateErrors,
	fetchLocationsWithHierarchy,
	fetchMultipleRegions,
	loading,
}): React.ReactElement => {
	const [checkAllProblemTypes, setCheckAllProblemTypes] = useState(false);
	const [checkAllLocations, setCheckAllLocations] = useState(false);
	const [form] = Form.useForm();

	const locationIds = Form.useWatch('locationIds', form);
	const problemTypeIds = Form.useWatch('problemTypeIds', form);

	useEffect(() => {
		form.setFieldsValue({
			allProblemTypes: checkAllProblemTypes,
			allLocations: checkAllLocations,
		});
		form.validateFields();
	}, [checkAllProblemTypes, checkAllLocations, form]);

	useEffect(() => {
		nullSafeGet('problemTypeIds', supplierFacility) &&
		nullSafeGet('problemTypeIds', supplierFacility).length === 0
			? setCheckAllProblemTypes(true)
			: setCheckAllProblemTypes(false);
		nullSafeGet('locationIds', supplierFacility) &&
		nullSafeGet('locationIds', supplierFacility).length === 0
			? setCheckAllLocations(true)
			: setCheckAllLocations(false);
	}, [supplierFacility]);

	const locationIdMandatory = useMemo(
		() => !checkAllLocations && (checkAllProblemTypes || !!nullSafeGet('0', problemTypeIds)),
		[checkAllLocations, checkAllProblemTypes, problemTypeIds]
	);

	const problemTypeMandaotry = useMemo(
		() => !checkAllProblemTypes && (checkAllLocations || !!nullSafeGet('0', locationIds)),
		[checkAllLocations, checkAllProblemTypes, locationIds]
	);

	return (
		<Modal
			visible={visible}
			width={600}
			okText="Edit Supplier"
			onCancel={onCancel}
			onOk={form.submit}
			confirmLoading={loading}
			closable={false}
		>
			<Form
				form={form}
				layout="vertical"
				className="supplierForm"
				initialValues={{
					...(supplierFacility || {}),
					allProblemTypes: false,
					problemTypeIds: nullSafeGetOrElse('problemTypeIds', supplierFacility, []),
					allLocations: false,
					locationIds: nullSafeGetOrElse('locationIds', supplierFacility, []),
				}}
				onFinish={onSubmit}
			>
				<h5>
					{`Edit ${nullSafeGetOrElse(
						'supplierFacilityAlias',
						supplierFacility,
						nullSafeGetOrElse('name', supplierFacility, 'Supplier Facility')
					)}'s Problem Types and Locations`}
				</h5>
				{Object.keys(supplierFacility).map((key) => (
					<Form.Item hidden name={key} />
				))}

				{createErrors.length > 0 ? (
					<FormItem>
						<Alert message={createErrors.join(' ')} type="error" />
					</FormItem>
				) : null}
				{updateErrors.length > 0 ? (
					<FormItem>
						<Alert message={updateErrors.join(' ')} type="error" />
					</FormItem>
				) : null}
				<FormItem label="Problem Types" valuePropName="checked" name="allProblemTypes">
					<Switch
						checked={checkAllProblemTypes}
						onChange={(checked) => setCheckAllProblemTypes(checked)}
					/>
					<label style={{ marginLeft: 8 }}>Select All Problem Types</label>
				</FormItem>

				<FormItem
					name="problemTypeIds"
					rules={[
						{
							type: 'array',
							required: problemTypeMandaotry,
							message: 'Please select a problem type',
						},
					]}
				>
					<ProblemTypeTreeSelect
						mode="multiple"
						stateSlice={problemTypes}
						targetCollectionName="SUPPLIER_PRIVATE_NETWORK_FORM_PROBLEM_TYPES_AUTOCOMPLETE"
						fetchMultiple={(ids, targetCollectionName) => {
							fetchMultipleProblemTypes(ids, targetCollectionName);
						}}
						fetchData={(
							searchText,
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						) =>
							fetchProblemTypes(
								{ name: searchText },
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							)
						}
						disabled={checkAllProblemTypes}
						emptyWhenDisabled={true}
						placeholder={
							checkAllProblemTypes ? 'All Problem Types Selected' : 'Select Problem Types'
						}
						renderRecord={(problemType) => (
							<Select.Option key={problemType.id} value={problemType.id}>
								{problemType.hierarchyName}
							</Select.Option>
						)}
					/>
				</FormItem>

				<FormItem name="allLocations" valuePropName="checked" label="Locations">
					<Switch
						checked={checkAllLocations}
						onChange={(checked) => setCheckAllLocations(checked)}
					/>
					<label style={{ marginLeft: 8 }}>Select All Locations</label>
				</FormItem>
				<FormItem
					name="locationIds"
					rules={[
						{
							type: 'array',
							required: locationIdMandatory,
							message: 'Please select a location',
						},
					]}
				>
					<OWLocationAsyncTreeSelect
						style={{ width: '100%' }}
						mode="multiple"
						stateSlice={locations}
						disabled={checkAllLocations}
						targetCollectionName="SUPPLIER_PRIVATE_NETWORK_FORM_LOCATIONS_AUTOCOMPLETE"
						fetchData={(
							searchText,
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						) =>
							fetchLocationsWithHierarchy(
								{ name: searchText },
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							)
						}
						fetchMultiple={(ids, targetCollectionName) =>
							fetchMultipleRegions(ids, targetCollectionName)
						}
						placeholder={checkAllLocations ? 'All Locations Selected' : 'Select Location(s)'}
						renderRecord={(record) => <LocationDropdownRecord record={record} />}
						sortBy={{ sort_by: 'name', order: 'ascend' }}
					/>
				</FormItem>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	supplierFacilitiesFetching: state.supplier_facilities.fetching,
	spendCategories: state.spend_categories,
	problemTypes: state.problem_types,
	locations: state.locations,
	createErrors: state.supplier_private_networks.createErrors,
	updateErrors: state.supplier_private_networks.updateErrors,
	creating: state.supplier_private_networks.creating,
	updating: state.supplier_private_networks.updating,
	supplierPrivateNetworkFetching: state.supplier_private_networks.fetching,
	supplierPrivateNetworkByFacilityFetching:
		state.supplier_private_networks.supplierPrivateNetworkBySupplierFacility.fetching,
	supplierPrivateNetworkByFacilityRecords:
		state.supplier_private_networks.supplierPrivateNetworkBySupplierFacility.records,

	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getSupplierFacility: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? supplierFacilitiesRestCrudThunksForSupplier.readOne(id)
				: supplierFacilitiesRestCrudThunksForBuyer.readOne(id)
		),
	fetchSpendCategories: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? spendCategoriesRestCrudThunksForSupplier.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: spendCategoriesRestCrudThunksForBuyer.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultipleSpendCategories: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? spendCategoriesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: spendCategoriesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchProblemTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? problemTypesHeirarchicalForSupplier(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: problemTypesHeirarchicalForBuyer(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultipleProblemTypes: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? problemTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: problemTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: locationsRestCrudThunksForBuyer.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: locationsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchLocationsWithHierarchy: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? fetchLocationsWithRegionsForSupplier(
						{ ...params, noIsActiveFilter: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: fetchLocationsWithRegionsForBuyer(
						{ ...params, noIsActiveFilter: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultipleRegions: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? regionsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: regionsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SupplierPrivateNetworkEditForm)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

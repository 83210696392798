import * as React from 'react';

import { connect } from 'react-redux';

import { Layout, Spin, Row, Col, Card, Table } from 'antd';
import { downloadCSVForAnalytics, floatToPercentageString } from '../../utils/DataFormatterUtils';

import {
	getAnalyticsDisplayData,
	getCurrency,
	nullSafeGet,
	nullSafeGetOrElse,
	sortTreeData,
} from '../../utils/DataAccessUtils';
import { BarChart } from '../charts/BarChart';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { withRouter } from 'react-router';
import AnalyticsControlBar from '../analytics_control_bar/AnalyticsControlBar';
import { clearVendorFilters, updateVendorFilters } from '../../actions/spend_analytics_actions';
import { fetchSpendDataByVendor, fetchSpendDataByMonth } from '../../thunks/spend_analytics_thunks';
import { debounce } from '../../utils/PerformanceUtils';
import { EmptyState } from '../empty_state/EmptyState';
import { StackedAreaChart } from '../charts/StackedAreaChart';
import moment from 'moment';
import { subcategoriesDisplayNamesMapper } from '../../utils/AnalyticsUtils';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

require('./SpendAnalyticsOverviewVendorsPage.less');
const G2 = require('g2');
const DataSet = require('@antv/data-set');
const { Content } = Layout;
const isEqual = require('fast-deep-equal');
const spendByMonthConfigFunc = (currency) => ({
	xAxisName: 'yearMonthKey',
	yAxisName: 'monthlyTotal',
	position: 'yearMonthKey*monthlyTotal',
	fillArea: false,
	transformer: (data) => {
		const ds = new DataSet();
		const dv = ds.createView().source(data);
		dv.transform({
			type: 'fold',
			fields: [
				subcategoriesDisplayNamesMapper['laborMonthlyTotal'],
				subcategoriesDisplayNamesMapper['materialMonthlyTotal'],
				subcategoriesDisplayNamesMapper['freightMonthlyTotal'],
				subcategoriesDisplayNamesMapper['travelMonthlyTotal'],
				subcategoriesDisplayNamesMapper['miscMonthlyTotal'],
				subcategoriesDisplayNamesMapper['taxMonthlyTotal'],
			], // expand fields into separate records
			key: 'subcategory', // new key field
			value: 'monthlyTotal', // new value field
		});
		return dv;
	},
	color: 'subcategory',
	cols: {
		yearMonthKey: { alias: 'Month' },
		monthlyTotal: { alias: 'Spend' },
	},
	yAxisLabel: {
		formatter: (val) => (val > 0 ? currency.symbolFormat(val, 0) : currency.symbolFormat(0, 0)),
		textStyle: {
			fontSize: '12',
			fontFamily: 'Roboto',
			fill: 'rgba(0,0,0,0.85)',
		},
	},
	xAxisLabel: {
		formatter: (val) => moment(val, 'YYYY-MM').format('MMM YYYY'),
		textStyle: {
			fontSize: '12',
			fontFamily: 'Roboto',
			fill: 'rgba(0,0,0,0.85)',
		},
	},
});
const spendByVendorChartConfigFunc = (currency) => ({
	xAxisName: 'fieldName',
	yAxisName: 'subTotal',
	position: 'fieldName*subTotal',
	fillArea: false,
	color: 'fieldName',
	cols: {
		fieldName: { alias: 'Vendor' },
		subTotal: { alias: 'Spend' },
	},
	yAxisLabel: {
		formatter: (val) => (val > 0 ? currency.symbolFormat(val, 0) : currency.symbolFormat(0, 0)),
		textStyle: {
			fontSize: '12',
			fontFamily: 'Roboto',
			fill: 'rgba(0,0,0,0.85)',
		},
	},
	xAxisLabel: {
		textStyle: {
			fontSize: '12',
			fontFamily: 'Roboto',
			fill: 'rgba(0,0,0,0.85)',
		},
	},
});

class SpendAnalyticsOverviewVendorsPage extends React.Component<any, any> {
	state = {
		initialLoading: true,
	};
	debouncedCallback = debounce(
		() => {
			const { fetchSpendData, fetchTimeSeriesSpendData, analytics } = this.props;
			const { initialLoading } = this.state;
			const promise1 = fetchSpendData(analytics.vendorFilters);
			const promise2 = fetchTimeSeriesSpendData(analytics.vendorFilters);
			if (initialLoading) {
				Promise.all([promise1, promise2]).then(() => this.setState({ initialLoading: false }));
			}
		},
		500,
		false
	);

	componentDidMount() {
		const { fetchSpendData, fetchTimeSeriesSpendData, analytics } = this.props;
		const { initialLoading } = this.state;
		if (analytics.vendorFilters.startDate && analytics.vendorFilters.endDate) {
			const promise1 = fetchSpendData(analytics.vendorFilters);
			const promise2 = fetchTimeSeriesSpendData(analytics.vendorFilters);
			if (initialLoading) {
				Promise.all([promise1, promise2]).then(() => this.setState({ initialLoading: false }));
			}
		}
	}

	componentWillReceiveProps(nextProps) {
		const { analytics } = this.props;
		if (!isEqual(nextProps.analytics.vendorFilters, analytics.vendorFilters)) {
			this.debouncedCallback();
		}
	}

	getDisplayData = () =>
		sortTreeData(
			getAnalyticsDisplayData(nullSafeGetOrElse('props.analytics.spendByVendor.data', this, [])),
			(a, b) =>
				parseFloat(nullSafeGetOrElse('subTotal', b, 0)) -
				parseFloat(nullSafeGetOrElse('subTotal', a, 0))
		);

	onDownloadAnalytics = () => {
		downloadCSVForAnalytics(this.getDisplayData(), 'Spend Analytics by Vendors', 'Vendor');
	};

	render() {
		const { saveVendorFilters, clearAllVendorFilters, analytics, accountActivated, currentUser } =
			this.props;
		const { initialLoading } = this.state;
		const { spendByVendor, spendByMonth, vendorFilters } = analytics;
		console.log(vendorFilters);

		const spendByVendorData = this.getDisplayData();
		const currency = getCurrency({ currentUser });

		const spendByMonthData =
			spendByMonth.data &&
			spendByMonth.data.map((el) => ({
				...el,
				monthlyTotal: parseFloat(el.monthlyTotal),
				[subcategoriesDisplayNamesMapper['freightMonthlyTotal']]: parseFloat(
					el.freightMonthlyTotal
				),
				[subcategoriesDisplayNamesMapper['laborMonthlyTotal']]: parseFloat(el.laborMonthlyTotal),
				[subcategoriesDisplayNamesMapper['materialMonthlyTotal']]: parseFloat(
					el.materialMonthlyTotal
				),
				[subcategoriesDisplayNamesMapper['miscMonthlyTotal']]: parseFloat(el.miscMonthlyTotal),
				[subcategoriesDisplayNamesMapper['taxMonthlyTotal']]: parseFloat(el.taxMonthlyTotal),
				[subcategoriesDisplayNamesMapper['travelMonthlyTotal']]: parseFloat(el.travelMonthlyTotal),
			}));

		const defaultSortOrder: 'ascend' | 'descend' = 'descend';
		const tooltipTemplate = `
                    <li data-index={index}>
                        <!-- The marker for each record -->
                        <span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>
                        {name}: {value}
                    </li>
        `;

		const spendByVendorColumns = [
			{
				title: 'Vendor',
				dataIndex: 'fieldName',
				render: (text) => (
					<Ellipsis tooltip={true} style={{ display: 'inline' }} length={28}>
						{text}
					</Ellipsis>
				),
			},
			{
				title: `Total Spent (${currency.id})`,
				dataIndex: 'subTotal',
				defaultSortOrder: defaultSortOrder,
				sorter: (a, b) => a.subTotal - b.subTotal,
				render: (amount) => currency.symbolFormat(amount, 0),
			},
			{
				title: '% of Total',
				dataIndex: 'percentageOfTotal',
				render: (num) => floatToPercentageString(num / 100),
				width: 100,
			},
		];
		const spendByVendorChartConfig = spendByVendorChartConfigFunc(currency);
		const spendByMonthConfig = spendByMonthConfigFunc(currency);

		return (
			<Content
				className="analyticsOverviewVendorsPage"
				style={{ padding: '0 0.5em', position: 'relative' }}
			>
				<LogOnMountWithStandardEventProperties eventType="visited spend analytics vendors page" />
				{/*<BackTop/>*/}
				{accountActivated ? (
					<div>
						<Row key={1} style={{ margin: '0.5em -8px' }} gutter={16}>
							<Col span={24}>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									<AnalyticsControlBar
										updateFilters={saveVendorFilters}
										clearFilters={clearAllVendorFilters}
										problemTypeIds={vendorFilters.problemTypeIds}
										supplierFacilityIds={vendorFilters.supplierFacilityIds}
										locationIds={vendorFilters.locationIds}
										startDate={vendorFilters.startDate}
										endDate={vendorFilters.endDate}
										isCapex={vendorFilters.isCapex}
										isPM={vendorFilters.isPM}
										workCreatedAtStartDate={vendorFilters.workCreatedAtStartDate}
										workCreatedAtEndDate={vendorFilters.workCreatedAtEndDate}
										workCompletedAtStartDate={vendorFilters.workCompletedAtStartDate}
										workCompletedAtEndDate={vendorFilters.workCompletedAtEndDate}
										showDownload
										onDownload={this.onDownloadAnalytics}
										filterConfig={{
											workCreatedAt: true,
											workCompletedAt: true,
											capEx: true,
											isSpendAnalytics: true,
											isPM: true,
											problemTypes: true,
										}}
									/>
									<div></div>
								</div>
							</Col>
						</Row>
						{!spendByMonth.loading &&
						!initialLoading &&
						(!spendByVendorData || spendByVendorData.length == 0) ? (
							<EmptyState
								height={undefined}
								headline={'No invoices found'}
								body={
									<div style={{ maxWidth: 488, textAlign: 'center' }}>
										We didn't find any invoice data. Try changing your filters or expanding the
										selected time period.
									</div>
								}
							/>
						) : (
							[
								<>
									<Row key={2} style={{ margin: '0.5em -8px' }} gutter={16}>
										<Col span={24}>
											<Card>
												<Spin spinning={spendByVendor.loading || initialLoading}>
													{spendByVendorData && spendByVendorData.length > 0 ? (
														<Table
															pagination={{ pageSize: 8 }}
															columns={spendByVendorColumns}
															rowKey={(el) => el['vendor']}
															dataSource={spendByVendorData}
														/>
													) : null}
												</Spin>
											</Card>
										</Col>
									</Row>
									<Row>
										<Col span={24}>
											<Card>
												<Spin spinning={spendByVendor.loading || initialLoading}>
													{spendByVendorData && spendByVendorData.length > 0 ? (
														<BarChart
															height={spendByVendorData.length * 32}
															chartPadding={[40, 40, 40, 200]}
															data={spendByVendorData}
															showTooltipTitle={false}
															tooltipItemTemplate={tooltipTemplate}
															xAxisName={nullSafeGet('xAxisName', spendByVendorChartConfig)}
															yAxisName={nullSafeGet('yAxisName', spendByVendorChartConfig)}
															xAxisLabel={nullSafeGet('xAxisLabel', spendByVendorChartConfig)}
															yAxisLabel={nullSafeGet('yAxisLabel', spendByVendorChartConfig)}
															fillArea={nullSafeGet('fillArea', spendByVendorChartConfig)}
															cols={nullSafeGet('cols', spendByVendorChartConfig)}
															color={nullSafeGet('color', spendByVendorChartConfig)}
															position={nullSafeGet('position', spendByVendorChartConfig)}
														/>
													) : null}
												</Spin>
											</Card>
										</Col>
									</Row>
								</>,

								<Row key={3} style={{ margin: '0.5em -8px' }} gutter={16}>
									<Col span={24}>
										<Card>
											<Spin spinning={spendByMonth.loading || initialLoading}>
												{spendByMonth.data && spendByMonth.data.length > 0 ? (
													<StackedAreaChart
														height={260}
														chartPadding={[40, 40, 40, 80]}
														data={spendByMonthConfig.transformer(spendByMonthData)}
														tooltipItemTemplate={tooltipTemplate}
														showTooltipTitle={false}
														xAxisName={nullSafeGet('xAxisName', spendByMonthConfig)}
														yAxisName={nullSafeGet('yAxisName', spendByMonthConfig)}
														yAxisLabel={nullSafeGet('yAxisLabel', spendByMonthConfig)}
														xAxisLabel={nullSafeGet('xAxisLabel', spendByMonthConfig)}
														fillArea={nullSafeGet('fillArea', spendByMonthConfig)}
														cols={nullSafeGet('cols', spendByMonthConfig)}
														color={nullSafeGet('color', spendByMonthConfig)}
														position={nullSafeGet('position', spendByMonthConfig)}
													/>
												) : null}
											</Spin>
										</Card>
									</Col>
								</Row>,
							]
						)}
					</div>
				) : (
					<EmptyState
						graphic={
							<img
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/letter owl.svg"
							/>
						}
						headline={"You haven't activated your account yet."}
						body={
							<div style={{ textAlign: 'center' }}>
								<div style={{ maxWidth: 560, marginBottom: 16 }}>
									Please check your email. We sent you a note with an activation link.
								</div>
							</div>
						}
					/>
				)}
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	accountActivated: state.session.accountActivated,
	currentUser: state.session.currentUser,
	analytics: state.spend_analytics,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	saveVendorFilters: (filters) => dispatch(updateVendorFilters(filters)),
	clearAllVendorFilters: (filters) => dispatch(clearVendorFilters()),
	fetchSpendData: (params, filters = null, pagination = null, sorting = null) =>
		dispatch(fetchSpendDataByVendor(ownProps.userType)(params, filters, pagination, sorting)),
	fetchTimeSeriesSpendData: (params, filters = null, pagination = null, sorting = null) =>
		dispatch(fetchSpendDataByMonth(ownProps.userType)(params, filters, pagination, sorting)),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SpendAnalyticsOverviewVendorsPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

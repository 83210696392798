import * as React from 'react';
import { Layout, Row, Col, Card, Button, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { siteSurveysRestCrudThunksForSupplier } from '../../thunks/site_surveys_thunks';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { TaskList } from '../task_list/TaskList';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import SupplierLocationCardDisplay from '../supplier_location_card_display/SupplierLocationCardDisplay';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import SiteSurveyWalkthroughSection from '../site_surveys_detail_details_page/SiteSurveyWalkthroughSection';
import ScheduleWalkthroughModalForm from '../schedule_walkthrough_modal_form/ScheduleWalkthroughModalForm';

const { Content } = Layout;

require('./SupplierSiteSurveysDetailDetailsPage.less');

class SiteSurveysDetailPage extends React.Component<any, any> {
	formRefs: any = {
		scheduleWalkthroughForm: null,
	};

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			scheduleWalkthroughFormVisible: false,
		};
	}

	componentDidMount() {
		const {
			getSiteSurvey,

			match,
		} = this.props;
		const siteSurveyId = match.params.id;
		getSiteSurvey(siteSurveyId).then(() => {
			this.setState({ loading: false });
		});
	}

	handleSchedule = (e) => {
		this.setState({
			scheduleWalkthroughFormVisible: true,
		});
		e.stopPropagation();
		e.preventDefault();
	};

	refreshSiteSurvey = () => {
		const {
			getSiteSurvey,

			match,
		} = this.props;
		this.setState({ loading: true });
		const siteSurveyId = match.params.id;
		getSiteSurvey(siteSurveyId).then(() => {
			this.setState({ loading: false });
		});
	};

	handleScheduleWalkthroughCancel = () => {
		this.setState({
			scheduleWalkthroughFormVisible: false,
		});
	};

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	handleScheduleSuccess = () => {
		message.success('Walkthrough scheduled successfully!');
		this.refreshSiteSurvey();
		this.handleScheduleWalkthroughCancel();
	};

	render() {
		const { siteSurvey, history } = this.props;

		const { loading } = this.state;

		return loading ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="siteSurveysDetailDetailsPage">
				<LogOnMountWithStandardEventProperties eventType="visited supplier site survey detail page" />
				{this.state.scheduleWalkthroughFormVisible ? (
					<ScheduleWalkthroughModalForm
						onCancel={this.handleScheduleWalkthroughCancel}
						onSuccess={this.handleScheduleSuccess}
					/>
				) : null}
				<Row style={{ margin: '16px 4px' }} gutter={12}>
					<Col span={16}>
						<div className="rowSpacing">
							<Card
								title="Walkthroughs"
								extra={
									<AccessPermissionChecker name={PERMISSION_NAMES.ADD_WALKTHROUGHS}>
										<Button
											style={{
												position: 'absolute',
												right: 32,
												top: 12.5,
											}}
											type="primary"
											onClick={this.handleSchedule}
										>
											Schedule Walkthrough
										</Button>
									</AccessPermissionChecker>
								}
							>
								<SiteSurveyWalkthroughSection />
							</Card>
						</div>
						<div className="rowSpacing">
							<Card title="Tasks">
								<TaskList tasks={nullSafeGetOrElse('tasks', siteSurvey, [])} />
							</Card>
						</div>
					</Col>
					<Col span={8}>
						{nullSafeGet('location', siteSurvey) ? (
							<div className="rowSpacing">
								<SupplierLocationCardDisplay
									location={siteSurvey.location}
									onViewLocationPage={() =>
										history.push(
											`/supplier/locations/detail/${nullSafeGet('location.id', siteSurvey)}`
										)
									}
								/>
							</div>
						) : null}
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state) => ({
	siteSurveysFetching: state.site_surveys.fetching,
	siteSurvey: state.site_surveys.detail,
	walkthroughs: state.walkthroughs,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	getSiteSurvey: (id) => dispatch(siteSurveysRestCrudThunksForSupplier.readOne(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteSurveysDetailPage));

import {
	ADD_CONTACT_TO_EVENT_SUPPLIER_TEAM_FORM_DATA,
	EVENT_SUPPLIER_TEAMS_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS,
} from '../actions/event_supplier_teams_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = standardInitialStateGenerator(['associatedInternalTeam']);
const eventSupplierTeamsReducerCrudLogic = standardReducerGenerator(
	CRUD_ACTION_CONSTANTS,
	initialState
);

export default (oldState = initialState, action): any => {
	Object.freeze(oldState);
	switch (action.type) {
		case ADD_CONTACT_TO_EVENT_SUPPLIER_TEAM_FORM_DATA:
			return Object.assign({}, oldState, {
				formData: {
					...oldState.formData,
					internalTeam: oldState.formData.internalTeam
						? [...oldState.formData.internalTeam, action.eventSupplierTeam]
						: [action.eventSupplierTeam],
				},
			});
		default:
			return eventSupplierTeamsReducerCrudLogic(oldState, action);
	}
};

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import '@ant-design/compatible/assets/index.css';
import { Layout, Row, Col, Button } from 'antd';
import { EmptyState } from '../empty_state/EmptyState';
import queryString from 'querystring';
import { getElementAt, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import {
	projectsRestCrudThunksForBuyer,
	projectsRestCrudThunksForSupplier,
} from '../../thunks/projects_thunks';
import { PROJECTS_CRUD_ACTION_CREATORS } from '../../actions/projects_actions';
import { ProjectsRowDisplay } from '../project_row_display/ProjectsRowDisplay';
import { BUYER_ONLY_NO_STORE_ASSOCIATE } from '../roles/roles';
import { ROLE_TYPES } from '../../utils/DataConstants';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import { useCallback, useEffect, useMemo, useState } from 'react';

const PROJECTS_OVERVIEW_TARGET_COLLECTION = 'projectsIndex';
const { Content } = Layout;

function ProjectsIndexPage({
	location,
	history,
	updateProjectsSorting,
	updateProjectsFilters,
	currentUser,
	userType,
	projects,
	clearAndUpdateFilters,
}) {
	const [initialPagination, setInitialPagination] = useState({});
	const [initialSorting, setInitialSorting] = useState<any>({
		sort_by: 'createdAt',
		order: 'ascend',
	});
	const getCreateButton = (
		<Button
			type="primary"
			size="large"
			onClick={() => history.push(`/${userType}/projects/newProject`)}
		>
			Create Project
		</Button>
	);

	const currentTab = useMemo(() => {
		const pathName = nullSafeGetOrElse('pathname', location, '');
		return getElementAt(pathName.split('/'), -1) || 'active';
	}, [location]);

	const isArchived = useMemo(() => currentTab === 'archived', [currentTab]);

	useEffect(() => {
		let searchString = location.search;

		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		const { current, pageSize, sort_by, order, ...projectFilters } = queryParams;
		let pagination = { current: current ? current : 1, pageSize: pageSize ? pageSize : 10 };
		let sorting = {
			sort_by: sort_by ? sort_by : 'createdAt',
			order: order ? order : 'ascend',
		};
		let filters =
			projectFilters && Object.keys(projectFilters).length > 0
				? {
						...projectFilters,
				  }
				: {};

		updateProjectsSorting(sorting.sort_by, sorting.order, PROJECTS_OVERVIEW_TARGET_COLLECTION);
		setInitialPagination(pagination);
		setInitialSorting(sorting);

		updateProjectsFilters(filters, PROJECTS_OVERVIEW_TARGET_COLLECTION);

		history.push(
			`/${userType}/projects/overview/${currentTab}?${new URLSearchParams(location.search)}`
		);
	}, [
		currentTab,
		history,
		location.pathname,
		location.search,
		updateProjectsFilters,
		updateProjectsSorting,
		userType,
	]);

	const columns = useMemo(
		() => [
			{
				title: '',
				dataIndex: initialSorting.sort_by,
				defaultSortOrder: initialSorting.order,
				order: initialSorting.order,
				sort_by: initialSorting.sort_by,
				render: (text, record) => (
					<Row style={{ width: '100%' }}>
						<ProjectsRowDisplay project={record} />
					</Row>
				),
			},
		],
		[]
	);

	const onRow = useCallback(
		(record) => ({
			onClick: () => {
				let backUrl = `/${userType}/projects/detail/${record.id}/details`;
				history.push(getLinkWIthBackLinkParams(location, 'Back to Projects', backUrl));
			},
		}),
		[location]
	);
	return (
		<div>
			<LogOnMountWithStandardEventProperties eventType="visited work orders index page" />
			<PaginatedReduxTableWithHeader
				preAppliedFilters={{ isArchived }}
				updateFilters={updateProjectsFilters}
				filterConfig={[]}
				clearAndUpdateFilters={clearAndUpdateFilters}
				showHeader={false}
				rightActions={getCreateButton}
				emptyState={
					currentUser.roles &&
					currentUser.roles.some((role) => BUYER_ONLY_NO_STORE_ASSOCIATE.has(role)) ? (
						<EmptyState
							graphic={
								<img
									style={{ marginBottom: 8 }}
									src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
								/>
							}
							headline={"It's a little lonely in here."}
							body={
								<div style={{ textAlign: 'center' }}>
									<div style={{ maxWidth: 440, marginBottom: 16 }}>
										Looks like your team has not created any projects yet.
									</div>
									<Button
										type="primary"
										onClick={() => history.push(`/${userType}/projects/newProject`)}
									>
										Create your first project
									</Button>
								</div>
							}
						/>
					) : (
						<EmptyState
							graphic={
								<img
									style={{ marginBottom: 8 }}
									src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
								/>
							}
							headline={"It's a little lonely in here."}
							body={
								<div style={{ textAlign: 'center' }}>
									<div style={{ maxWidth: 440, marginBottom: 16 }}>
										Looks like your team has not created any projects.
									</div>
									<Button
										type="primary"
										onClick={() => {
											[2483, 2489, 2490].includes(
												nullSafeGet('facility.buyerCompanyId', currentUser)
											)
												? history.push(`/${userType}/projects/newProject`)
												: history.push(`/${userType}/projects/newProject`);
										}}
									>
										Create your first project
									</Button>
								</div>
							}
						/>
					)
				}
				entityCollectionName="projects"
				targetCollectionName={PROJECTS_OVERVIEW_TARGET_COLLECTION}
				tableColumns={columns}
				onTableRow={onRow}
				initialPagination={initialPagination}
				initialSorting={initialSorting}
				fetchData={
					nullSafeGet('userType', currentUser) === ROLE_TYPES.SUPPLIER
						? projectsRestCrudThunksForSupplier.read
						: projectsRestCrudThunksForBuyer.read
				}
				stateSlice={projects}
			/>
		</div>
	);
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	locations: state.locations,
	currentUser: state.session.currentUser,
	projects: state.projects,
});

const mapDispatchToProps = (dispatch) => ({
	updateProjectsFilters: (filters, targetCollection) =>
		dispatch(PROJECTS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(PROJECTS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)),
	updateProjectsSorting: (sortBy, order, targetCollection) =>
		dispatch(PROJECTS_CRUD_ACTION_CREATORS.updateSorting(sortBy, order, targetCollection)),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ProjectsIndexPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

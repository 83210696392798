import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Table, Checkbox, InputNumber } from 'antd';
import React from 'react';
import InlineEditableText from '../common/InlineEditableText';

const EditableContext = React.createContext(undefined);

const EditableRow = ({ form, index, ...props }) => (
	<EditableContext.Provider value={form}>
		<tr {...props} />
	</EditableContext.Provider>
);

const EditableFormRow = Form.create<any>()(EditableRow);

const numericalRecord = 'numerical';
const numericalValue = 'numericalValue';

class EditableCell extends React.Component<any, any> {
	input: any;
	form: any;

	saveChecked = (e) => {
		const { record, handleSave } = this.props;
		this.form.validateFields((error, values) => {
			if (error && error[e.currentTarget.id]) {
				return;
			}
			values[e.target.id] = e.target.checked;
			handleSave({ ...record, ...values });
		});
	};

	saveNumber = (value) => {
		const { record, handleSave } = this.props;
		return handleSave({ ...record, ...{ [numericalValue]: value } });
	};

	renderCheckedCell = (form) => {
		this.form = form;
		const { dataIndex, record, title, defaultValue } = this.props;
		const finalValueWithDefault =
			`${record[dataIndex]}` === 'false' || `${record[dataIndex]}` === 'true'
				? record[dataIndex]
				: defaultValue;
		return (
			<Form.Item style={{ margin: 0, float: 'right' }}>
				{form.getFieldDecorator(dataIndex, {
					valuePropName: 'checked',
					rules: [
						{
							required: true,
							message: `${title} is required.`,
						},
					],
					initialValue: finalValueWithDefault,
				})(
					<Checkbox
						style={{ display: 'flex', justifyContent: 'center' }}
						ref={(node) => (this.input = node)}
						onChange={this.saveChecked}
					/>
				)}
			</Form.Item>
		);
	};

	renderNumberCell = () => {
		const { dataIndex, record } = this.props;
		return (
			<div style={{ margin: 0, float: 'right' }}>
				<InlineEditableText
					value={record[dataIndex]}
					onChange={this.saveNumber}
					inputType="number"
				/>
			</div>
		);
	};

	render() {
		const {
			editable,
			optional,
			dataIndex,
			title,
			record,
			index,
			handleSave,
			children,
			...restProps
		} = this.props;
		return (
			<td {...restProps}>
				{editable ? (
					record[numericalRecord] ? (
						<EditableContext.Consumer>{this.renderNumberCell}</EditableContext.Consumer>
					) : (
						<EditableContext.Consumer>{this.renderCheckedCell}</EditableContext.Consumer>
					)
				) : (
					children
				)}
			</td>
		);
	}
}

export default class EditableTable extends React.Component<any, any> {
	columns: any;

	render() {
		const components = {
			body: {
				row: EditableFormRow,
				cell: EditableCell,
			},
		};
		const columns = this.props.columns.map((col) => {
			if (!col.editable) {
				return col;
			}
			return {
				...col,
				onCell: (record) => ({
					record,
					editable: col.editable,
					dataIndex: col.dataIndex,
					title: col.title,
					defaultValue: col.defaultValue,
					handleSave: this.props.handleSave,
				}),
			};
		});
		return (
			<Table
				pagination={false}
				components={components}
				dataSource={this.props.data}
				columns={columns}
				showHeader={this.props.showHeader || false}
				rowClassName={this.props.rowClassName}
			/>
		);
	}
}

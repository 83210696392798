import * as React from 'react';
import {
	Layout,
	Row,
	Col,
	Table,
	Card,
	Button,
	Modal,
	Tooltip,
	Form,
	Input,
	Divider,
	message,
} from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import FileUploader from '../file_uploader/FileUploader';

import { KeyValueDisplay } from '../key_value_display/KeyValueDisplay';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { dateFormatter, optionalFilter, capitalizeEachWord } from '../../utils/DataFormatterUtils';
import Mapbox from '../mapbox/Mapbox';
import { SocialMediaBadge } from '../social_media_badge/SocialMediaBadge';
import { AddressDisplay } from '../address_display/AddressDisplay';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import {
	supplierFacilitiesRestCrudThunksForBuyer,
	supplierFacilitiesRestCrudThunksForSupplier,
} from '../../thunks/supplier_facilities_thunks';
import {
	supplierFacilityReviewsRestCrudThunksForBuyer,
	supplierFacilityReviewsRestCrudThunksForSupplier,
} from '../../thunks/supplier_facility_reviews_thunks';
import { FlexibleImageAvatar } from '../flexible-image-avatar/FlexibleImageAvatar';
import { EmptyState } from '../empty_state/EmptyState';
import { SOCIAL_MEDIA_TYPES } from '../../constants/social_media';
import { FAQRowDisplay } from '../faq_row_display/FAQRowDisplay';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import BuyerSupplierRelationshipForm from './BuyerSupplierRelationshipForm';
import LocationandSupplierEditCard from './LocationandSupplierEditCard';
import SupplierPrivateNetworkEditForm from '../supplier_private_network_edit_form/SupplierPrivateNetworkEditForm';
import {
	inviteSupplierUserFromBuyer,
	requestFilesV2ForBuyer,
	requestFilesV2ForSupplier,
	supplierPrivateNetworkGroupedUpdateForBuyer,
	supplierPrivateNetworkGroupedUpdateForSupplier,
	supplierPrivateNetworksV2ForBuyer,
	supplierPrivateNetworksV2ForSupplier,
	supplierPrivateNetworkV2UpdateForBuyer,
	supplierPrivateNetworkV2UpdateForSupplier,
} from '../../thunks/supplier_private_networks_thunks';
import { Link } from 'react-router-dom';
import { generateBackLink } from '../../utils/HistoryUtils';
import { clearNavbarState, updateNavbarState } from '../../actions/navbar_actions';
import {
	BUYER_ADMIN,
	BUYER_USER,
	SUPER_ADMIN,
	SUPPLIER_ADMIN,
	SUPPLIER_MANAGER,
} from '../roles/roles';
import BuyerSupplierInformationForm from './BuyerSupplierInformationForm';
import { ROLE_TYPES } from '../../utils/DataConstants';
import BuyerSupplierContactDirectory from './BuyerSupplierContactDirectory';
import SelfManagedSupplierInvitationForm from '../self_managed_supplier_invitation_form/SelfManagedSupplierInvitationForm';

const { Content } = Layout;

require('./BuyerSuppliersDetailPage.less');
const queryString = require('qs');

const DAYS_OF_THE_WEEK = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
];

const DEFAULT_BACK_LINK_TO = (userType) => `/${userType}/suppliers/overview/privateNetwork`;
const DEFAULT_BACK_LINK_TEXT = 'Back to Suppliers';

class BuyerSuppliersDetailPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			modalContent: null,
			modalVisible: false,
			supplierPrivateNetworkEditFormVisible: false,
			data: {},
			requested: [],
			updatingPrivateNetwork: false,
			inviteSupplierModalVisible: false,
			inviteLoading: false,
		};
		this.fetchData = this.fetchData.bind(this);
	}

	fetchData() {
		this.setState({ loading: true });
		const {
			supplierId,
			fetchSupplierRelationship,
			fetchSupplierFacility,
			fetchSupplierFacilityReviews,
			match,
			currentUser,
		} = this.props;
		const supplierFacilityId = supplierId
			? supplierId
			: match.params.id === 'me'
			? currentUser.facility.id
			: match.params.id;
		fetchSupplierRelationship(supplierFacilityId).then((supplierRelationship) => {
			if (supplierRelationship.length > 0) {
				this.setState({ loading: false, data: supplierRelationship[0] });
			} else {
				fetchSupplierFacility(supplierFacilityId).then((supplierFacility) =>
					this.setState({ loading: false, data: { supplierFacility: supplierFacility } })
				);
			}
		});
		fetchSupplierFacilityReviews(supplierFacilityId);
	}

	componentDidMount() {
		const { location, updateNavbar, userType } = this.props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		if (queryParams.backlinkParams) {
			const { tab, pathName, backLinkText, ...otherParams } = queryString.parse(
				queryParams.backlinkParams
			);
			const finalPath = pathName || DEFAULT_BACK_LINK_TO(userType);
			let backText = backLinkText || DEFAULT_BACK_LINK_TEXT;
			updateNavbar(backText, generateBackLink(finalPath, location));
		} else {
			updateNavbar(
				DEFAULT_BACK_LINK_TEXT,
				generateBackLink(DEFAULT_BACK_LINK_TO(userType), location)
			);
		}
		this.fetchData();
	}

	componentWillUnmount(): void {
		this.props.clearNavbar();
	}

	handleEditToggle = (toggleKey) => {
		const currentState = this.state[toggleKey];
		this.setState({ [toggleKey]: !currentState });
	};

	handleEditCancel = (toggleKey) => {
		this.setState({ [toggleKey]: false });
	};

	handleOpenCertificationModal = (certification, idx) => {
		this.setState({
			modalVisible: true,
			modalContent: (
				<div key={idx}>
					<h5>{certification.certificationType}</h5>

					<KeyValueDisplay
						keyValueStore={{
							'Certificate #': certification.number,
							Issuer: certification.issuer,
							Qualifier: certification.qualifier,
							Expiration: dateFormatter(certification.expiration, 'MMM D, YYYY'),
							Attachment: certification.certificationFile,
						}}
						renderFuncs={{
							Attachment: (file) =>
								file ? (
									<FileUploader
										roleType="supplier"
										defaultFileList={file ? [file] : []}
										uploaderType="readOnly"
									/>
								) : (
									'--'
								),
						}}
					/>
				</div>
			),
		});
	};

	handleOpenInsuranceModal = (insurance, idx) => {
		const policyDocColumns = [
			{
				title: 'Policy',
				dataIndex: 'policyName',
			},
			{
				title: 'Amount',
				dataIndex: 'amount',
				render: (text, record) => <span>${text.toLocaleString('en')}</span>,
			},
			{
				title: 'Expires',
				dataIndex: 'expiration',
				render: (text, record) => dateFormatter(text, 'MMM D, YYYY'),
			},
			{
				title: 'Attachment',
				dataIndex: 'policyFile',
				render: (text, record) =>
					text ? (
						<FileUploader roleType="supplier" defaultFileList={[text]} uploaderType="readOnly" />
					) : (
						'--'
					),
			},
		];
		this.setState({
			modalVisible: true,
			modalContent: (
				<div>
					<h5>{insurance.companyName}</h5>
					<div style={{ padding: '8px 0', display: 'flex' }}>
						<div>
							<div
								style={{
									fontSize: 16,
									marginBottom: 4,
								}}
							>
								{insurance.companyContact.name}
							</div>
							<div
								style={{
									fontSize: 14,
									color: 'rgba(0,0,0,0.45)',
								}}
							>
								{insurance.companyContact.phone}
							</div>
						</div>
						<div
							style={{
								fontSize: 14,
								marginLeft: 32,
								color: 'rgba(0,0,0,0.45)',
							}}
						>
							<AddressDisplay address={insurance.companyContact.address} />
						</div>
					</div>
					<Table pagination={false} dataSource={insurance.policyDocs} columns={policyDocColumns} />
				</div>
			),
		});
	};

	handleOpenLicenseModal = (license, idx) => {
		this.setState({
			modalVisible: true,
			modalContent: (
				<div>
					<h5 style={{ marginBottom: 16 }}>{license.licenseType}</h5>
					<KeyValueDisplay
						keyValueStore={{
							'License #': license.number,
							Issuer: license.issuer,
							Qualifier: license.qualifier,
							Expiration: dateFormatter(license.expiration, 'MMM D, YYYY'),
							Attachment: license.licenseFile,
						}}
						renderFuncs={{
							Attachment: (file) =>
								file ? (
									<FileUploader
										roleType="supplier"
										defaultFileList={file ? [file] : []}
										uploaderType="readOnly"
									/>
								) : (
									'--'
								),
						}}
					/>
				</div>
			),
		});
	};
	handleSupplierPrivateNetworkEditSubmit = (values) => {
		this.setState({ updatingPrivateNetwork: true });
		const { updateGroupedSupplierPrivateNetwork } = this.props;
		const locationIdSeq = values.allLocations
			? []
			: nullSafeGet('locationIds.0', values)
			? values.locationIds
			: undefined;
		const problemTypeIdSeq = values.allProblemTypes
			? []
			: nullSafeGet('problemTypeIds.0', values)
			? values.problemTypeIds
			: undefined;

		const noLocationOrProblemTypeAccess = !locationIdSeq && !problemTypeIdSeq;

		const entity = {
			supplierFacilityId: values.supplierFacilityId,
			...(!noLocationOrProblemTypeAccess && { problemTypeIdSeq }),
			...(!noLocationOrProblemTypeAccess && { locationIdSeq }),
			noLocationOrProblemTypeAccess,
			buyerCompanyId: values.buyerCompanyId,
		};

		const fn = updateGroupedSupplierPrivateNetwork(values.supplierFacilityId, entity);

		Promise.all([fn])
			.then(() => {
				this.setState({ supplierPrivateNetworkEditFormVisible: false });
				this.fetchData();
				message.success(`updated supplier in your private network.`);
			})
			.finally(() => this.setState({ updatingPrivateNetwork: false }));
	};

	onUpdateSuccess = (data) => this.setState({ data });

	updateFields = (values = {}) => {
		const { updateSupplierInPrivateNetworkV2 } = this.props;

		const dataModel = {
			...this.state.data,
			...values,
		};
		updateSupplierInPrivateNetworkV2(dataModel)
			.then((data) => {
				this.onUpdateSuccess(data);
				message.success('Details updated succesfully');
			})
			.catch((err) => {
				message.error('Something went wrong, Please try again!');
			});
	};

	inviteSelfManagedSupplier = (values) => {
		const { data: { supplierFacility = {} } = {} } = this.state;
		const { inviteSupplierUser, currentUser } = this.props;
		inviteSupplierUser(
			supplierFacility.id,
			currentUser.email,
			values.primaryContactEmail,
			values.primaryContactFirstName,
			values.primaryContactLastName
		)
			.then(() => {
				message.success(
					`${values.primaryContactFirstName} ${values.primaryContactLastName} has been sent an invite to join OpenWrench at the email address: ${values.primaryContactEmail}`
				);
			})
			.finally(() => {
				this.setState({ inviteSupplierModalVisible: false, inviteLoading: false });
				this.fetchData();
			});
	};

	handleFileManualUpload = (newAttachments) => {
		let callUpdate = newAttachments.every((element) => {
			return element && element.fileId && element.fileName;
		});
		if (callUpdate) {
			this.updateFields({ internalBuyerAttachments: newAttachments });
		}
	};
	requestFile = (type) => {
		const { currentUser, requestFiles } = this.props;
		const { data: { supplierFacility = {} } = {}, requested } = this.state;

		requestFiles({
			supplierFacilityId: supplierFacility.id,
			type,
			fromEmail: currentUser.email,
		}).then(() => {
			this.setState({ requested: [...requested, type] });
		});
	};

	render() {
		const { currentUser, userType } = this.props;

		const {
			loading,
			supplierPrivateNetworkEditFormVisible,
			data = {},
			requested,
			inviteSupplierModalVisible,
		} = this.state;
		if (loading) return <PageLoadingPlaceholder />;
		const {
			supplierFacility = {},
			supplierFacilityAlias,
			relationshipStatus,
			problemTypeHierarchyNames,
			locationNames,
			internalBuyerAttachments,
			internalBuyerNotes,
		} = data;
		const isManager =
			currentUser.roles.includes(BUYER_ADMIN) ||
			currentUser.roles.includes(SUPER_ADMIN) ||
			currentUser.roles.includes(BUYER_USER) ||
			currentUser.roles.includes(SUPPLIER_MANAGER) ||
			currentUser.roles.includes(SUPPLIER_ADMIN);
		const isAdmin =
			currentUser.roles.includes(BUYER_ADMIN) ||
			currentUser.roles.includes(SUPER_ADMIN) ||
			currentUser.roles.includes(SUPPLIER_ADMIN);

		const contactInfo = loading
			? {}
			: {
					Email: optionalFilter(supplierFacility.primaryContactEmail),
					Phone: optionalFilter(supplierFacility.primaryPhone),
					Address: <AddressDisplay address={supplierFacility.primaryAddress} />,
					Website: optionalFilter(supplierFacility.website),
			  };

		const operatingHours = supplierFacility.operatingHours || {};

		return (
			<Content className="buyerSuppliersDetailPage" style={{ position: 'relative', padding: 0 }}>
				<Modal
					visible={this.state.modalVisible}
					width={600}
					onCancel={(e) => this.setState({ modalVisible: false, modalContent: null })}
					footer={null}
					closable={true}
				>
					{this.state.modalContent}
				</Modal>
				{supplierPrivateNetworkEditFormVisible && (
					<SupplierPrivateNetworkEditForm
						userType={userType}
						visible
						supplierFacilityId={
							supplierPrivateNetworkEditFormVisible &&
							nullSafeGet('supplierFacilityId', supplierFacility)
						}
						onCancel={() => this.setState({ supplierPrivateNetworkEditFormVisible: false })}
						onSubmit={this.handleSupplierPrivateNetworkEditSubmit}
						supplierFacility={data}
						loading={this.state.updatingPrivateNetwork}
					/>
				)}
				{inviteSupplierModalVisible && (
					<SelfManagedSupplierInvitationForm
						userType={userType}
						visible
						supplierFacilityId={
							supplierPrivateNetworkEditFormVisible &&
							nullSafeGet('supplierFacilityId', supplierFacility)
						}
						onCancel={() => this.setState({ inviteSupplierModalVisible: false })}
						onSubmit={this.inviteSelfManagedSupplier}
						supplierFacility={data}
						loading={this.state.inviteLoading}
					/>
				)}
				<LogOnMountWithStandardEventProperties
					eventType={`visited ${userType} supplier detail page`}
				/>
				{loading ? (
					<PageLoadingPlaceholder />
				) : (
					<div style={{}}>
						<Row style={{ margin: '0 -8px' }} gutter={16}>
							<Col span={24}>
								<div className="buyerSuppliersDetailPage__header">
									<div style={{ float: 'left', display: 'flex' }}>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<div style={{ marginRight: 24 }}>
												{supplierFacility.logoURL ? (
													<FlexibleImageAvatar
														displayName={supplierFacilityAlias || supplierFacility.displayName}
														showPopover={false}
														popoverText={supplierFacilityAlias || supplierFacility.displayName}
														width="120px"
														height="120px"
														imageUrl={supplierFacility.logoURL}
													/>
												) : (
													<FlexibleImageAvatar
														displayName={supplierFacilityAlias || supplierFacility.displayName}
														showPopover={false}
														popoverText={supplierFacilityAlias || supplierFacility.displayName}
														width="120px"
														height="120px"
														imageUrl="https://s3.amazonaws.com/emptor-data-assets/images/empty-state/icons8-city-filled-100.png"
													/>
												)}
											</div>
											<div>
												<h4 style={{ color: 'white', marginBottom: 4 }}>
													{supplierFacilityAlias || supplierFacility.displayName}
													{relationshipStatus && (
														<div
															className={`buyerSuppliersDetailPage__relationshipStatus ${relationshipStatus}`}
														>
															{relationshipStatus === 'SelfManaged'
																? 'Self Managed'
																: relationshipStatus}
														</div>
													)}
												</h4>
											</div>
										</div>
									</div>
									<div
										style={{
											float: 'right',
											display: 'flex',
											justifyContent: 'flex-end',
											alignItems: 'center',
											maxWidth: 400,
										}}
									>
										{relationshipStatus && ['Invited', 'SelfManaged'].includes(relationshipStatus) && (
											<Button
												type="default"
												key="invite"
												onClick={() => this.setState({ inviteSupplierModalVisible: true })}
												ghost={true}
											>
												Invite
											</Button>
										)}
										{SOCIAL_MEDIA_TYPES.every(
											(sm) =>
												supplierFacility.socialMedia === undefined ||
												supplierFacility.socialMedia[sm.key] === undefined
										)
											? null
											: SOCIAL_MEDIA_TYPES.map((sm) =>
													supplierFacility.socialMedia[sm.key] !== undefined ? (
														<div style={{ marginLeft: 8 }}>
															<SocialMediaBadge
																key={sm.type}
																type={sm.type}
																handle={supplierFacility.socialMedia[sm.key]}
															/>
														</div>
													) : null
											  )}
									</div>
								</div>
							</Col>
						</Row>

						<Row style={{ margin: '16px 4px' }} gutter={16}>
							<Col span={16}>
								{supplierFacility.about && supplierFacility.about.length > 0 && (
									<Row className="rowSpacing" gutter={16}>
										<Col span={24}>
											<Card>
												<h5 style={{ display: 'flex', alignItems: 'center' }}>
													About this supplier
												</h5>
												<div style={{ fontSize: 18 }}>{supplierFacility.about}</div>
											</Card>
										</Col>
									</Row>
								)}
								<Row className="rowSpacing" gutter={16}>
									<Col span={24}>
										<Card>
											<BuyerSupplierInformationForm
												data={this.state.data}
												onSubmit={this.updateFields}
											/>
										</Card>
									</Col>
								</Row>
								<Row className="rowSpacing" gutter={16}>
									<Col span={24}>
										<BuyerSupplierContactDirectory
											supplier={this.state.data}
											onSucess={this.onUpdateSuccess}
										/>
									</Col>
								</Row>

								{relationshipStatus && (
									<>
										<Row className="rowSpacing" gutter={16}>
											<Col span={24}>
												<Card>
													<BuyerSupplierRelationshipForm
														data={this.state.data}
														onSubmit={this.updateFields}
														allowedToEdit={isManager}
													/>
												</Card>
											</Col>
										</Row>
										<Row className="rowSpacing" gutter={16}>
											<Col span={24}>
												<Card>
													<Row justify="space-between">
														<h5 style={{ display: 'flex', alignItems: 'center' }}>Assignment</h5>
														{isManager ? (
															<Button
																onClick={() =>
																	this.setState({ supplierPrivateNetworkEditFormVisible: true })
																}
																disabled={!isAdmin}
															>
																Edit
															</Button>
														) : null}
													</Row>
													<Row className="keyValueTable__key" style={{ marginTop: 20 }}>
														Problem Types
														{nullSafeGet('length', problemTypeHierarchyNames) && (
															<span style={{ fontSize: 14 }}>
																({nullSafeGet('length', problemTypeHierarchyNames) || 'All'})
															</span>
														)}
													</Row>
													{problemTypeHierarchyNames ? (
														<>
															<LocationandSupplierEditCard
																type="problemTypeHierarchyNames"
																data={this.state.data}
																label="Problems"
															/>
														</>
													) : (
														<EmptyState
															height={120}
															headline={null}
															body={
																<div style={{ textAlign: 'center' }}>
																	<div style={{ maxWidth: 488, marginBottom: 16 }}>
																		You havent assigned any Problem Type to{' '}
																		{nullSafeGet('supplierFacilityAlias', data)}
																	</div>
																	<div style={{ maxWidth: 488, marginBottom: 16 }}>
																		{isManager ? (
																			<Button
																				onClick={() =>
																					this.setState({
																						supplierPrivateNetworkEditFormVisible: true,
																					})
																				}
																			>
																				Assign
																			</Button>
																		) : null}
																	</div>
																</div>
															}
														/>
													)}
													<Divider />
													<div className="keyValueTable__key" style={{ marginTop: 20 }}>
														Locations
														{nullSafeGet('length', locationNames) && (
															<span style={{ fontSize: 14 }}>
																{' '}
																({nullSafeGet('length', locationNames) || 'All'})
															</span>
														)}
													</div>
													{locationNames ? (
														<>
															<LocationandSupplierEditCard
																type="locationNames"
																data={this.state.data}
																label="Locations"
															/>
														</>
													) : (
														<EmptyState
															height={120}
															headline={null}
															body={
																<div style={{ textAlign: 'center' }}>
																	<div style={{ textAlign: 'center' }}>
																		<div style={{ maxWidth: 488, marginBottom: 16 }}>
																			You havent assigned any Location to{' '}
																			{nullSafeGet('supplierFacilityAlias', data)}
																		</div>
																		<div style={{ maxWidth: 488, marginBottom: 16 }}>
																			{isManager ? (
																				<Button
																					onClick={() =>
																						this.setState({
																							supplierPrivateNetworkEditFormVisible: true,
																						})
																					}
																				>
																					Assign
																				</Button>
																			) : null}
																		</div>
																	</div>
																</div>
															}
														/>
													)}
												</Card>
											</Col>
										</Row>
									</>
								)}

								<Row className="rowSpacing" gutter={16}>
									<Col span={24}>
										<Card>
											<h5 style={{ display: 'flex', alignItems: 'center' }}>W9</h5>
											{!supplierFacility.w9 ? (
												<EmptyState
													height={120}
													headline={null}
													body={
														<div style={{ textAlign: 'center' }}>
															<div style={{ maxWidth: 488, marginBottom: 16 }}>
																This supplier hasn't uploaded a W9.
															</div>
															{isManager ? (
																<div style={{ maxWidth: 488, marginBottom: 16 }}>
																	<Button
																		disabled={requested.includes('w9')}
																		onClick={() => this.requestFile('w9')}
																	>
																		{requested.includes('w9') ? 'Requested' : 'Request'}
																	</Button>
																</div>
															) : null}
														</div>
													}
												/>
											) : (
												<FileUploader
													roleType="supplier"
													defaultFileList={supplierFacility.w9 ? [supplierFacility.w9] : []}
													uploaderType="readOnly"
												/>
											)}
										</Card>
									</Col>
								</Row>

								<Row className="rowSpacing" gutter={16}>
									<Col span={24}>
										<Card>
											<h5 style={{ display: 'flex', alignItems: 'center' }}>Insurances</h5>
											{!supplierFacility.insurances || supplierFacility.insurances.length === 0 ? (
												<EmptyState
													height={120}
													headline={null}
													body={
														<div style={{ textAlign: 'center' }}>
															<div style={{ maxWidth: 488, marginBottom: 16 }}>
																This supplier hasn't uploaded Insurance information.
															</div>
															<div style={{ maxWidth: 488, marginBottom: 16 }}>
																<Button
																	disabled={requested.includes('coi')}
																	onClick={() => this.requestFile('coi')}
																>
																	{requested.includes('coi') ? 'Requested' : 'Request'}
																</Button>
															</div>
														</div>
													}
												/>
											) : (
												<div>
													{supplierFacility.insurances.map((insurance, idx) => (
														<div key={idx}>
															<div
																style={{
																	fontSize: 18,
																	display: 'flex',
																	alignItems: 'center',
																}}
															>
																<img
																	width="80px"
																	height="80px"
																	style={{ marginRight: 16 }}
																	src="https://mock-data-assets.s3.amazonaws.com/categories/images/licenseonfile.png"
																/>
																<div>
																	<div>Insured by {insurance.companyName}</div>
																	<div>
																		<Button
																			style={{
																				paddingLeft: 0,
																				paddingRight: 0,
																			}}
																			onClick={() => this.handleOpenInsuranceModal(insurance, idx)}
																			type="link"
																		>
																			View Details
																		</Button>
																	</div>
																</div>
															</div>
														</div>
													))}
													{isManager ? (
														<div style={{ maxWidth: 488, marginBottom: 16, marginTop: 16 }}>
															<Button
																disabled={requested.includes('coi')}
																onClick={() => this.requestFile('coi')}
															>
																{requested.includes('coi') ? 'Requested' : 'Request'}
															</Button>
														</div>
													) : null}
												</div>
											)}
										</Card>
									</Col>
								</Row>

								<Row className="rowSpacing" gutter={16}>
									<Col span={24}>
										<Card>
											<h5 style={{ display: 'flex', alignItems: 'center' }}>Licenses</h5>
											{!supplierFacility.licenses || supplierFacility.licenses.length === 0 ? (
												<EmptyState
													height={120}
													headline={null}
													body={
														<div style={{ textAlign: 'center' }}>
															<div style={{ maxWidth: 488, marginBottom: 16 }}>
																This supplier hasn't uploaded any Licenses.
															</div>
															{isManager ? (
																<div style={{ maxWidth: 488, marginBottom: 16 }}>
																	<Button
																		disabled={requested.includes('license')}
																		onClick={() => this.requestFile('license')}
																	>
																		{requested.includes('license') ? 'Requested' : 'Request'}
																	</Button>
																</div>
															) : null}
														</div>
													}
												/>
											) : (
												<div>
													{supplierFacility.licenses.map((license, idx) => (
														<div key={idx}>
															<div
																style={{
																	fontSize: 18,
																	display: 'flex',
																	alignItems: 'center',
																}}
															>
																<img
																	width="80px"
																	height="80px"
																	style={{ marginRight: 16 }}
																	src="https://mock-data-assets.s3.amazonaws.com/categories/images/licenseonfile.png"
																/>
																<div>
																	<div>
																		Licensed for {license.licenseType} by {license.issuer}
																	</div>
																	<div>
																		<Button
																			style={{
																				paddingLeft: 0,
																				paddingRight: 0,
																			}}
																			onClick={() => this.handleOpenLicenseModal(license, idx)}
																			type="link"
																		>
																			View Details
																		</Button>
																	</div>
																</div>
															</div>
														</div>
													))}
													{isManager ? (
														<div style={{ maxWidth: 488, marginBottom: 16, marginTop: 16 }}>
															<Button
																disabled={requested.includes('license')}
																onClick={() => this.requestFile('license')}
															>
																{requested.includes('license') ? 'Requested' : 'Request'}
															</Button>
														</div>
													) : null}
												</div>
											)}
										</Card>
									</Col>
								</Row>

								<Row className="rowSpacing" gutter={16}>
									<Col span={24}>
										<Card>
											<h5 style={{ display: 'flex', alignItems: 'center' }}>
												Frequently asked questions
											</h5>
											{supplierFacility.faqs && supplierFacility.faqs.length > 0 ? (
												supplierFacility.faqs.map((faq, idx) => (
													<FAQRowDisplay style={{ marginBottom: 40 }} key={idx} faq={faq} />
												))
											) : (
												<EmptyState
													height={120}
													headline={null}
													body={
														<div style={{ textAlign: 'center' }}>
															<div style={{ maxWidth: 488, marginBottom: 16 }}>
																They haven't added any answers to frequently asked questions yet.
															</div>
														</div>
													}
												/>
											)}
										</Card>
									</Col>
								</Row>
							</Col>

							<Col span={8}>
								{relationshipStatus && (
									<>
										{isManager ? (
											<Row className="rowSpacing">
												<Col span={24}>
													<Tooltip placement="top" title="Only visible to your team">
														<Card>
															<h5 style={{ display: 'flex', alignItems: 'center' }}>
																Internal Attachments
															</h5>
															{userType === ROLE_TYPES.SUPPLIER ? (
																<FileUploader
																	defaultFileList={internalBuyerAttachments || []}
																	roleType="supplier"
																	handleUploadSuccess={this.handleFileManualUpload}
																	uploaderType={'dragger'}
																	multiple
																/>
															) : (
																<FileUploader
																	defaultFileList={internalBuyerAttachments || []}
																	roleType="buyer"
																	handleUploadSuccess={this.handleFileManualUpload}
																	uploaderType={'dragger'}
																	multiple
																/>
															)}
														</Card>
													</Tooltip>
												</Col>
											</Row>
										) : null}
										<Row className="rowSpacing">
											<Col span={24}>
												<Tooltip placement="top" title="Only visible to your team">
													<Card>
														<h5 style={{ display: 'flex', alignItems: 'center' }}>
															Internal Notes
														</h5>
														<Form
															onFinish={(values) => {
																this.updateFields(values);
															}}
															initialValues={{ internalBuyerNotes }}
														>
															<Form.Item name="internalBuyerNotes">
																<Input.TextArea />
															</Form.Item>
															<Button type="primary" htmlType="submit">
																Save
															</Button>
														</Form>
													</Card>
												</Tooltip>
											</Col>
										</Row>
									</>
								)}
								<Row style={{ margin: '0 -8px' }} gutter={16}>
									<Col span={24}>
										<Row className="rowSpacing" gutter={16}>
											<Col span={24}>
												<Card bodyStyle={{ padding: 0 }}>
													{supplierFacility.longitude && supplierFacility.latitude ? (
														<div style={{ height: 240, position: 'relative' }}>
															<Mapbox
																longitude={supplierFacility.longitude}
																latitude={supplierFacility.latitude}
																circleRadiusInMiles={supplierFacility.radiusOfServiceInMiles}
																baseLayerStyle="light"
																zoomLevel={7}
															/>
														</div>
													) : null}
													{
														<div style={{ padding: 24 }}>
															<KeyValueDisplay keyWidth="33%" keyValueStore={contactInfo} />
														</div>
													}
												</Card>
											</Col>
										</Row>
										<Row className="rowSpacing" gutter={16}>
											<Col span={24}>
												<Card>
													<h5 style={{ display: 'flex', alignItems: 'center' }}>
														Services provided
													</h5>
													<div
														style={{
															wordBreak: 'normal',
															whiteSpace: 'normal',
															fontSize: 16,
														}}
													>
														{supplierFacility.categoryNames &&
															supplierFacility.categoryNames
																.split(',')
																.map((categoryName) => capitalizeEachWord(categoryName))
																.join(', ')}
													</div>
												</Card>
											</Col>
										</Row>
										<Row className="rowSpacing" gutter={16}>
											<Col span={24}>
												<Card>
													<h5 style={{ display: 'flex', alignItems: 'center' }}>Business hours</h5>
													<div style={{ fontSize: 16 }}>
														{operatingHours.timeZoneName ? (
															<div
																style={{
																	color: 'rgba(0,0,0,0.45)',
																	marginBottom: 16,
																}}
															>
																{operatingHours.timeZoneName}
															</div>
														) : null}
														<table>
															{DAYS_OF_THE_WEEK.map((dayOfTheWeek, idx) => {
																const fromTime = nullSafeGet(
																	`${dayOfTheWeek}.from`,
																	operatingHours
																);
																const toTime = nullSafeGet(`${dayOfTheWeek}.to`, operatingHours);
																const hasHours = fromTime && toTime;

																return (
																	<tr key={idx} style={{ lineHeight: '32px' }}>
																		<td>{dayOfTheWeek}</td>
																		<td style={{ paddingLeft: 32 }}>
																			{hasHours ? `${fromTime} to ${toTime}` : 'No hours available'}
																		</td>
																	</tr>
																);
															})}
														</table>
													</div>
												</Card>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				)}
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	currentUser: state.session.currentUser,
	supplierFacilityReviews: state.supplier_facility_reviews,
	supplierFacilitiesFetching: state.supplier_facilities.fetching,
	match: ownProps.match,
	history: ownProps.history,
	userType: (state as any).session.userType,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchSupplierRelationship: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? supplierPrivateNetworksV2ForSupplier(null, 'supplier_facility', null, null, {
						supplierFacilityId: id,
				  })
				: supplierPrivateNetworksV2ForBuyer(null, 'supplier_facility', null, null, {
						supplierFacilityId: id,
				  })
		),
	fetchSupplierFacility: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? supplierFacilitiesRestCrudThunksForSupplier.readOne(id)
				: supplierFacilitiesRestCrudThunksForBuyer.readOne(id)
		),
	requestFiles: (data) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? requestFilesV2ForSupplier(data)
				: requestFilesV2ForBuyer(data)
		),
	updateGroupedSupplierPrivateNetwork: (id, entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? supplierPrivateNetworkGroupedUpdateForSupplier(id)(entity)
				: supplierPrivateNetworkGroupedUpdateForBuyer(id)(entity)
		),
	updateSupplierInPrivateNetworkV2: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? supplierPrivateNetworkV2UpdateForSupplier(entity)
				: supplierPrivateNetworkV2UpdateForBuyer(entity)
		),
	fetchSupplierFacilityReviews: (supplierFacilityId) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? supplierFacilityReviewsRestCrudThunksForSupplier.read(
						{ supplierFacilityId },
						'supplierDetailReviews'
				  )
				: supplierFacilityReviewsRestCrudThunksForBuyer.read(
						{ supplierFacilityId },
						'supplierDetailReviews'
				  )
		),
	updateNavbar: (backlinkTitle, backlinkTo, navbarBackgroundColor) =>
		dispatch(updateNavbarState(backlinkTitle, backlinkTo, navbarBackgroundColor)),
	clearNavbar: () => dispatch(clearNavbarState()),
	inviteSupplierUser: (supplierFacilityId, fromEmail, toEmail, firstName, lastName) =>
		dispatch(
			inviteSupplierUserFromBuyer(ownProps.userType)(
				supplierFacilityId,
				fromEmail,
				toEmail,
				firstName,
				lastName
			)
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(BuyerSuppliersDetailPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

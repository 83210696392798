import {
	CURRENCIES,
	DATE_PARAM_NAMES,
	DEFAULT_SORTER,
	PAGINATION_PROPS,
	TAB_PROPS,
} from './DataConstants';
import JSONCrush from 'jsoncrush';
import moment from 'moment';
import { BUYER_ADMIN_ONLY, SUPER_ADMIN_ONLY, SUPPLIER_ADMIN_ONLY } from '../components/roles/roles';

export function getObjectValues(obj: any): any[] {
	return Object.keys(obj).map((k) => obj[k]);
}

export function nullSafeGetOrElse(path: string, obj: any, elseVal: any): any {
	const accessKeys = path.split('.');
	return accessKeys.reduce((xs, x) => (xs && xs[x] ? xs[x] : elseVal), obj);
}

export function nullSafeGet(path: string, obj: any): any {
	const accessKeys = path.split('.');
	return accessKeys.reduce((xs, x) => (xs && xs[x] ? xs[x] : undefined), obj);
}

export function nullSafeGeWithZero(path: string, obj: any): any {
	const accessKeys = path.split('.');
	return accessKeys.reduce((xs, x) => (xs && xs[x] !== undefined ? xs[x] : undefined), obj);
}

export function parseQueryParams(queryParams: string): any {
	return queryParams
		.replace('?', '')
		.split('=')
		.reduce((acc, el, idx, arr) => {
			if (idx % 2 !== 0) acc[arr[idx - 1]] = el;
			return acc;
		}, {});
}

export function getEntitiesById(ids, entitiesMap) {
	return ids.map((id) => entitiesMap[id]);
}

export function getEntityById(id, slice) {
	return nullSafeGetOrElse(`records.${id}`, slice, {});
}

export function getEntitiesByCompositeId(ids, entitiesMap, idNames) {
	return ids.map((id) => entitiesMap[id]);
}

interface PaginationProps {
	current: number;
	pageSize: number;
}

export function getCurrentPage(records: any[], pagination: PaginationProps) {
	const currentPage = pagination.current;
	const pageSize = pagination.pageSize;
	const startIdx = currentPage * pageSize - 1;
	const endIdx = currentPage * pageSize + pageSize;
	return records.slice(startIdx, endIdx);
}

export function chunkify(arr: any[], chunkSize: number, balanced: boolean) {
	if (chunkSize < 2) {
		return [arr];
	}

	let len = arr.length,
		out = [],
		i = 0,
		size;

	if (len % chunkSize === 0) {
		size = Math.floor(len / chunkSize);
		while (i < len) {
			out.push(arr.slice(i, (i += size)));
		}
	} else if (balanced) {
		while (i < len) {
			size = Math.ceil((len - i) / chunkSize--);
			out.push(arr.slice(i, (i += size)));
		}
	} else {
		chunkSize--;
		size = Math.floor(len / chunkSize);
		if (len % size === 0) {
			size--;
		}
		while (i < size * chunkSize) {
			out.push(arr.slice(i, (i += size)));
		}
		out.push(arr.slice(size * chunkSize));
	}
	return out;
}

export function changeFilterValueToArrayOfValues(val: any) {
	return `${val || ''}`.split(',').filter((_) => !!_);
}

export const changeFilterValueToArrayOfIds = (val: any) =>
	changeFilterValueToArrayOfValues(val).map((_) => parseInt(_));

export function parseFilterValueToAdvancedFilters(val: any) {
	val = val || {};
	return Object.keys(val).reduce((obj, key) => {
		Array.isArray(val[key]) ? (obj[key] = val[key].join(',')) : (obj[key] = val[key]);
		return obj;
	}, {});
}

const queryString = require('qs');

export function getFiltersFromLocation(location) {
	let searchString = location.search;
	if (searchString[0] === '?') {
		searchString = searchString.slice(1);
	}

	return queryString.parse(searchString);
}

export function excludeObject(source, exclude) {
	return Object.keys(source).reduce((obj, key) => {
		source[key] !== exclude[key] && (obj[key] = source[key]);
		return obj;
	}, {});
}

export function isValidFilter(
	filterObj,
	preAppliedFilters = {},
	defaultFilters = {},
	defaultSorter
) {
	const ignoreKeys = [
		...Object.keys(preAppliedFilters),
		...PAGINATION_PROPS,
		...TAB_PROPS,
		'backlinkParams',
	];
	const checkKeys = [...Object.keys(defaultFilters)];

	return Object.keys(
		excludeObject(
			filterObj,
			Object.keys(defaultSorter || {}).length > 0 ? defaultSorter : DEFAULT_SORTER
		) || {}
	).some((key) =>
		checkKeys.includes(key) ? filterObj[key] !== defaultFilters[key] : !ignoreKeys.includes(key)
	);
}

export function cleanFiltersForView(filters, preAppliedFilters) {
	const ignoreKeys = [...PAGINATION_PROPS, ...TAB_PROPS];

	return Object.keys(filters).reduce((obj, key) => {
		!ignoreKeys.includes(key) && filters[key] !== undefined && (obj[key] = filters[key]);
		return obj;
	}, {});
}

export const getFiltersWithoutPaginationAndSorting = (params) => {
	const { current, pageSize, sort_by, order, ...filters } = params;
	return filters;
};

export function filterKeys(input, keysArr, withKeys) {
	return Object.keys(input || {}).reduce((obj, key) => {
		withKeys
			? keysArr.includes(key) && (obj[key] = input[key])
			: !keysArr.includes(key) && (obj[key] = input[key]);
		return obj;
	}, {});
}

export function getSearchStringForBackNavigation(location) {
	let searchString = location.search;
	let searchParams = new URLSearchParams(location.search);
	const pathName = location.pathname;
	if (searchString[0] === '?') {
		searchParams.set('pathName', pathName);
		searchString = searchParams.toString();
	} else {
		searchString = `pathName=${pathName}`;
	}
	return searchString;
}

export function getSearchStringForBackNavigationWithBackText(backText, location, extraParams = {}) {
	let searchString = location.search;
	let searchParams = new URLSearchParams(location.search);
	const pathName = location.pathname;
	for (const key in extraParams) {
		searchParams.set(key, extraParams[key]);
	}
	if (searchString[0] === '?') {
		searchParams.set('pathName', pathName);
		searchParams.set('backLinkText', backText);
		searchString = searchParams.toString();
	} else {
		searchString = `pathName=${pathName}&backLinkText=${backText}`;
	}
	return searchString;
}
export function getSelectionObject(record) {
	return {
		id: record.id.toString(),
		status: record.status,
		invoicePDFLink: record.invoicePDFLink,
		nte: nullSafeGet('workOrder.nte', record),
		invoiceTotalAfterTax: nullSafeGet('invoiceTotalAfterTax', record),
		invoiceTotalBeforeTax: nullSafeGet('invoiceTotalBeforeTax', record),
		workOrder: nullSafeGet('workOrder', record),
	};
}

export function crushFilters(value: any) {
	const excludeKeys = [...PAGINATION_PROPS];
	const crushed = Object.keys(value || {}).reduce((obj, key) => {
		obj[key] =
			!excludeKeys.includes(key) && value[key] !== undefined && `${value[key]}`
				? JSONCrush.crush(`${value[key]}`)
				: value[key];
		return obj;
	}, {});
	return {
		...crushed,
		...(Object.keys(crushed).length > 0 && { is_compressed: true }),
	};
}

export function parseQueryParameters(queryParams: string): any {
	return queryParams
		.replace('?', '')
		.split(/[=&]/)
		.reduce((acc, el, idx, arr) => {
			if (idx % 2 !== 0) acc[arr[idx - 1]] = el;
			return acc;
		}, {});
}

export const getAllLeafNodes = (records) => {
	let leafNodes = [];
	records.map((record) => {
		if (record.children && record.children.length > 0) {
			leafNodes = [...leafNodes, ...getAllLeafNodes(record.children)];
		} else {
			leafNodes = [...leafNodes, record];
		}
		return undefined;
	});

	return leafNodes;
};

export const findRecord = (allRecords, valueAccessor, val) => {
	const possilbleRecord = allRecords.find((_) => valueAccessor(_) === val);
	if (possilbleRecord) {
		return possilbleRecord;
	}
	for (let i = 0; i < allRecords.length; i++) {
		if (allRecords[i].children && allRecords[i].children.length > 0) {
			const foundRecord = findRecord(allRecords[i].children, valueAccessor, val);
			if (foundRecord) return foundRecord;
		}
	}
	return undefined;
};

export const getFilterFromConfig = (filterConfig, fieldName) =>
	filterConfig.find((_) => _.fieldName === fieldName);

export const isMatchingField = (id, fieldName) => id.startsWith(fieldName);

export const formatDateParameters = (params) =>
	Object.keys(params || {}).reduce((obj, key) => {
		obj[key] =
			DATE_PARAM_NAMES.includes(key) && params[key]
				? params[key].format('YYYY-MM-DD')
				: params[key];
		return obj;
	}, {});

export const stopDefaultEvents = (e) => {
	e && e.preventDefault && e.preventDefault();
	e && e.stopPropagation && e.stopPropagation();
};

export const getStartDateFieldName = (fieldName) =>
	fieldName !== 'default' ? `${fieldName}StartDate` : 'startDate';
export const getEndDateFieldName = (fieldName) =>
	fieldName !== 'default' ? `${fieldName}EndDate` : 'endDate';

export const getTupleNameFieldName = (fieldName) => `${fieldName}Name`;
export const getTupleValueFieldName = (fieldName) => `${fieldName}Value`;

export const isTextElementEmpty = (str) => {
	if (str !== undefined) {
		const el = document.createElement('p');
		el.innerHTML = str;
		str = el.textContent.toString();
		return str.trim().replace(/(<([^>]+)>)/gi, '').length < 1;
	}
	return true;
};

const isDefined = (value) => !!value;

const isExpirationDateValid = (warranty) => {
	const expirationDate = nullSafeGet('expirationDate', warranty);
	return (
		(expirationDate && moment(expirationDate).diff(moment()) > 0) || expirationDate === undefined
	);
};

const isIssueDateValid = (warranty) => {
	const issueDate = nullSafeGet('issueDate', warranty);
	return (issueDate && moment(issueDate).diff(moment()) < 0) || issueDate === undefined;
};

const isSupplierValid = (warranty, supplierId) => {
	return (
		isDefined(supplierId) && supplierId === nullSafeGet('provider.supplierFacilityId', warranty)
	);
};

export const checkIsWarrantyWorkOrder = (warranty, supplierId) => {
	return (
		isDefined(warranty) &&
		isExpirationDateValid(warranty) &&
		isIssueDateValid(warranty) &&
		isSupplierValid(warranty, supplierId)
	);
};

export const getWarrantyStatus = (warranty) =>
	isDefined(warranty)
		? (isDefined(warranty.expirationDate) && moment(warranty.expirationDate).diff(moment()) > 0) ||
		  !isDefined(warranty.expirationDate)
			? //Warranty Expiration Date is in future or is undefined
			  (isDefined(warranty.issueDate) && moment(warranty.issueDate).diff(moment()) < 0) ||
			  (isDefined(warranty.expirationDate) && !isDefined(warranty.issueDate))
				? //Warranty Issue Date is in past or is undefined
				  'Active'
				: //Warranty Issue Date is in future
				  'Pending'
			: isDefined(warranty.expirationDate) &&
			  moment(warranty.expirationDate).add(1, 'months') > moment()
			? //Warranty expiration date was less than a month ago
			  'Expired'
			: false
		: false;

export const checkIsWarrantyActive = (warranty) =>
	isDefined(warranty) &&
	//expirationDate is less than current date or expiration date is undefined
	((isDefined(nullSafeGet('expirationDate', warranty)) &&
		moment(nullSafeGet('expirationDate', warranty)).diff(moment()) > 0) ||
		!isDefined(warranty.expirationDate)) &&
	//issueDate is greater than current date or issue date is undefined
	((isDefined(nullSafeGet('issueDate', warranty)) &&
		moment(nullSafeGet('issueDate', warranty)).diff(moment()) < 0) ||
		!isDefined(warranty.issueDate));

export const hasAccessToAllLocations = (role, user) => {
	switch (role) {
		case 'BUYER_STORE_ASSOCIATE':
			return nullSafeGetOrElse('hasAccessToAllLocations', user, false);
		case 'BUYER_USER':
			return nullSafeGetOrElse('hasAccessToAllLocations', user, false);
		case 'BUYER_ADMIN':
			return true;
		case 'SUPER_ADMIN':
			return true;
		default:
			return nullSafeGetOrElse('hasAccessToAllLocations', user, false);
	}
};

export const compareStrings = (string1, string2) =>
	string2.localeCompare(string1, undefined, { sensitivity: 'base' }) === 0;

export const isNonEmptyObject = (obj) => {
	if (!obj) return false;

	const cleanedObj = Object.entries(obj)
		.filter(([_, value]) => !!value)
		.reduce((newObj, [key, value]) => {
			newObj[key] = value;
			return newObj;
		}, {});

	return Object.keys(cleanedObj).length > 0;
};
export const equalsIngnoreCase = (str1, str2) =>
	str1 && str2 && `${str1}`.toLowerCase().trim() === `${str2}`.toLowerCase().trim();

export const roundOffToDecimal = (num, places = 2) => {
	const placesNum = Math.pow(10, places);
	return Math.round(num * placesNum) / placesNum;
};

export const checkHasApprovalHierarchy = (item) => {
	return item && item.hasApprovalHierarchy;
};

export const getAnalyticsDisplayData = (data) =>
	data
		.map((el) => ({
			...el,
			subTotal: parseFloat(el.subTotal),
			percentageOfTotal: parseFloat(el.percentageOfTotal),
		}))
		.sort((a, b) => parseFloat(a.subTotal) - parseFloat(b.subTotal));

export const removeEmptyChildrenFromTreeData = (data) =>
	data.map((obj) => ({
		...obj,
		children:
			nullSafeGetOrElse('children.length', obj, 0) > 0
				? removeEmptyChildrenFromTreeData(obj.children)
				: undefined,
	}));

export const flattenTreeData = (data, reduceFn) =>
	data.reduce((acc, obj) => {
		const newAcc = acc.concat([reduceFn(obj)]);
		return newAcc.concat(flattenTreeData(obj.children || [], reduceFn));
	}, []);

export const filterTreeData = (data, filterFn) =>
	data
		.map((obj) => {
			if (filterFn(obj)) {
				return obj;
			} else if (obj.children && obj.children.length > 0) {
				const matchingChildren = filterTreeData(obj.children, filterFn);
				return matchingChildren.length > 0 ? { ...obj, children: matchingChildren } : undefined;
			} else {
				return undefined;
			}
		})
		.filter((_) => !!_);

export const collectObjectsWithValidPropFromTreeData = (data, propName) =>
	data.reduce((acc, obj) => {
		if (nullSafeGetOrElse('children', obj, []).length > 0) {
			return acc.concat(collectObjectsWithValidPropFromTreeData(obj.children, propName));
		} else {
			return acc.concat(obj[propName] ? [obj] : []);
		}
	}, []);

export const collectAllParentsFromTreeData = (data, reducerFn) =>
	data.reduce((acc, obj) => {
		return nullSafeGetOrElse('children', obj, []).length > 0
			? acc.concat(reducerFn(obj)).concat(collectAllParentsFromTreeData(obj.children, reducerFn))
			: acc;
	}, []);

export const sortTreeData = (data, sortFn) =>
	data
		.map((obj) =>
			obj.children
				? {
						...obj,
						children: sortTreeData(obj.children, sortFn),
				  }
				: obj
		)
		.sort(sortFn);

export function createRecordsObjectFromArray(arr, keyName = 'id') {
	return arr.reduce((acc, el) => {
		const key = el[keyName];
		acc[key] = el;
		return acc;
	}, {});
}

export const getCurrency = (props) => {
	const { currentUser, supplier, location, workOrder, buyerCompany, supplierFacility, currencyId } =
		props;

	if (currencyId) {
		return nullSafeGetOrElse(`${currencyId}`, CURRENCIES, 'USD');
	}
	if (nullSafeGet('currencyId', workOrder)) {
		return CURRENCIES[nullSafeGetOrElse('currencyId', workOrder, 'USD')];
	}
	if (nullSafeGet('currencyId', supplierFacility)) {
		return CURRENCIES[nullSafeGetOrElse('currencyId', supplierFacility, 'USD')];
	}
	if (nullSafeGet('currencyId', buyerCompany)) {
		return CURRENCIES[nullSafeGetOrElse('currencyId', buyerCompany, 'USD')];
	}
	if (nullSafeGet('currencyId', supplier)) {
		return CURRENCIES[nullSafeGetOrElse('currencyId', supplier, 'USD')];
	}
	if (nullSafeGet('buyerFacility.currencyId', location)) {
		return CURRENCIES[nullSafeGetOrElse('buyerFacility.currencyId', location, 'USD')];
	}

	if (nullSafeGet('facility.buyerCompany.currencyId', currentUser)) {
		return CURRENCIES[nullSafeGetOrElse('facility.buyerCompany.currencyId', currentUser, 'USD')];
	} else if (nullSafeGetOrElse('facility.currencyId', currentUser, 'USD')) {
		return CURRENCIES[nullSafeGetOrElse('facility.currencyId', currentUser, 'USD')];
	}
};

export const getImageCountErrorRules = (minPhotosCount, message) => [
	...(minPhotosCount
		? [
				{
					required: true,
					message,
				},
		  ]
		: []),
	...(minPhotosCount
		? [
				{
					type: 'array',
					min: minPhotosCount,
					message,
				},
		  ]
		: []),
];
export const fetchWithDifferntSearchName =
	(fetchMethod, searchFieldName) =>
	({ name }, targetCollectionName, pagination, sorting, filters, addToTargetCollection) =>
		fetchMethod(
			{
				[searchFieldName]: name || undefined,
			},
			targetCollectionName,
			pagination,
			sorting,
			filters,
			addToTargetCollection
		);

export const renderCurrency = (currentUser) => (value) => {
	const currency = getCurrency({ currentUser });
	return value ? currency.format(value) : '--';
};

export const renderCurrencyByCurrencyId = (currencyId) => (value) => {
	const currency = getCurrency({ currencyId });
	return value ? currency.format(value) : '--';
};

const isTruthyEqual = (val1, val2) => !!val1 && !!val2 && val1 === val2;

const getValueFromLocationOrStockLocation = (record, key) =>
	nullSafeGet(key, record) || nullSafeGet(`buyerFacility.${key}`, record);

const getLatitude = (record) => getValueFromLocationOrStockLocation(record, 'latitude');
const getLongitude = (record) => getValueFromLocationOrStockLocation(record, 'longitude');

const isSameLocation = (obj1 = {} as any, obj2 = {} as any) =>
	isTruthyEqual(getLatitude(obj1), getLatitude(obj2)) &&
	isTruthyEqual(getLongitude(obj1), getLongitude(obj2));

export const isSameSelectedLocation = (currentRecord, selectedLocation) => {
	return nullSafeGet('id', selectedLocation) && nullSafeGet('id', currentRecord)
		? currentRecord.id === selectedLocation.id ||
				isSameLocation(currentRecord, selectedLocation) ||
				isSameLocation(currentRecord, nullSafeGet('location', selectedLocation))
		: true;
};

export const getLineItemVendor = (record) =>
	record.isEquipmentLine
		? nullSafeGetOrElse('equipmentCatalog.partEquipmentVendor', record, {})
		: nullSafeGetOrElse('partCatalog.partEquipmentVendor', record, {});

export const isPRLineItemSelectionDisabled = ({
	record,
	selectedLocation,
	selectedSL,
	selectedVendor,
}) => {
	const slToSL = isSameSelectedLocation(nullSafeGet('stockLocation', record), selectedSL);
	const slToLoc = isSameSelectedLocation(nullSafeGet('stockLocation', record), selectedLocation);
	const locToLoc = isSameSelectedLocation(nullSafeGet('location', record), selectedLocation);
	const locToSL = isSameSelectedLocation(nullSafeGet('location', record), selectedSL);
	return (
		record.status !== 'approved' ||
		(nullSafeGet('id', getLineItemVendor(record)) &&
			nullSafeGet('id', selectedVendor) &&
			getLineItemVendor(record).id !== nullSafeGet('id', selectedVendor)) ||
		!slToSL ||
		!slToLoc ||
		!locToLoc ||
		!locToSL
	);
};

export const checkWillChangeApprovalHierarchyQuote = (quote, workOrder, rules) => (param) => {
	const relevantParams = ['isPM', 'isCapex'];

	if (quote && workOrder) {
		let woParams = {
			...relevantParams.reduce((obj, p) => ({ ...obj, [p]: workOrder[p] }), {}),
			...param,
		};
		//Has approval hierarchy
		if (!!nullSafeGet('approvalHierarchy', quote)) {
			const tierDetails = nullSafeGet('approvalHierarchy.tierDetails', quote);
			const hasApprovers = tierDetails.some(
				(tier) => nullSafeGetOrElse('approvedByUsers.length', tier, 0) > 0
			);

			//Has begun approval process
			if (hasApprovers) {
				const currentHierarchyId = nullSafeGet('approvalHierarchyId', quote);
				const defaultRule = rules.find((rule) => rule.isDefault);
				const approvalRules = rules
					//Remove default
					.filter((rule) => !rule.isDefault)
					//Sort Rules by rank
					.sort((a, b) => {
						return a.rank - b.rank;
					})
					//For each check to see if it meets the proper criteria
					.filter((rule) =>
						relevantParams.every((param) => {
							//if rule param is not defined, it is not set and therefore true
							return rule[param] !== undefined ? rule[param] === woParams[param] : true;
						})
					);
				const newRule = approvalRules.length === 0 ? defaultRule : approvalRules[0];
				return currentHierarchyId !== nullSafeGet('approvalHierarchyId', newRule);
			} else {
				return false;
			}
		} else {
			return false;
		}
	} else {
		return false;
	}
};

export const getParamsWithPaginationAndSorting = (params, pagination, sorting, filters) => {
	// if pagination present, transform into limit/offset format and add to query params
	if (pagination) {
		const paginationQuery = {
			offset: (pagination.current - 1) * pagination.pageSize,
			limit: pagination.pageSize,
		};
		params = {
			...params,
			...paginationQuery,
		};
	}

	// if sorting present, fix order naming and add to query params
	if (sorting) {
		const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
		params = {
			...params,
			...sortingQuery,
		};
	}

	if (filters) {
		params = {
			...params,
			...filters,
		};
	}

	return params;
};

export const isInApprovalHierarchy = (currentUser, approvalHierarchy) => {
	const tiers = nullSafeGetOrElse('tierDetails', approvalHierarchy, []);
	const approvers = tiers.flatMap((tier) => tier.approvers);
	return approvers.some((approver) => approver.email === currentUser.email);
};
export const isInvoiceTotalGreaterThanNTE = (invoice, workOrder) => {
	const nte = parseFloat(nullSafeGetOrElse('workOrder.nte', invoice, 0));
	const total = parseFloat(nullSafeGetOrElse('invoiceTotalAfterTax', invoice, 0));
	const totalBeforeTax = parseFloat(nullSafeGetOrElse('invoiceTotalBeforeTax', invoice, 0));
	const isNteCheckWithoutTax = nullSafeGet(
		'buyerCompany.buyerCompanySettings.config.invoiceConfig.nteCheckWithoutTax',
		workOrder
	);

	return isNteCheckWithoutTax ? nte < totalBeforeTax : nte < total;
};

export const getBudgetPriorityValue = (budget) => {
	return budget.invoiceTotal || budget.proposalTotal || budget.workOrderNte || 0;
};

export const getTotalValue = (v1, v2) => {
	if (v1 !== undefined && v2 !== undefined) {
		return v1 + v2;
	} else if (v1 !== undefined) {
		return v1;
	} else if (v2 !== undefined) {
		return v2;
	}

	return undefined;
};

export const getIfAllSameCurrencies = (currencies) =>
	currencies.length > 0
		? currencies.every((_) => _ === currencies[0])
			? currencies[0]
			: undefined
		: undefined;

export const getCurrencyIdIfSameLineItemsCurrency = (lineItems, prefix) => {
	const currencies = lineItems
		.map((_) => nullSafeGet(`${prefix}Catalog.partEquipmentVendor.currencyId`, _))
		.filter((_) => !!_);
	return getIfAllSameCurrencies(currencies);
};

export const getCurrencyIdIFSamePRLineItemCurrency = (purchaseRequest) => {
	const partCurrency = getCurrencyIdIfSameLineItemsCurrency(
		nullSafeGetOrElse('partSupplierPurchaseRequestLineItems', purchaseRequest, []),
		'part'
	);
	const equipmentCurrency = getCurrencyIdIfSameLineItemsCurrency(
		nullSafeGetOrElse('equipmentSupplierPurchaseRequestLineItems', purchaseRequest, []),
		'equipment'
	);
	return getIfAllSameCurrencies([partCurrency, equipmentCurrency].filter((_) => !!_));
};

export const mergeTroubleshootingRoutes = (sourceArray, toBeMerged, keyFn, reducerFn) => {
	return toBeMerged.reduce((keys, route) => {
		const defaultStepIndex = keyFn(route);
		return [
			...keys.slice(0, defaultStepIndex + 1),
			...[reducerFn(route)],
			...keys.slice(defaultStepIndex + 1),
		];
	}, sourceArray);
};

export const getPurchaseOrderId = (po) =>
	`#${nullSafeGetOrElse(
		'purchaseOrderUniqueIdWithPrefix',
		po,
		`${nullSafeGetOrElse('id', po, '--')}`
	)}`;

export const getPurchaseRequestId = (pr) =>
	`#${nullSafeGetOrElse(
		'purchaseRequestCustomIdentifier',
		pr,
		`${nullSafeGetOrElse('id', pr, '--')}`
	)}`;

export const getEquipmentId = (epsl) =>
	nullSafeGetOrElse('equipmentUniqueId', epsl, nullSafeGetOrElse('id', epsl, ''));

export const isToAssetPOLineItem = (record) =>
	nullSafeGet('equipmentTypeId', record) && nullSafeGet('supplierPurchaseOrder.locationId', record);

export const getElementAt = (arr, index) => (index >= 0 ? arr[index] : arr[arr.length + index]);

export const isAdminUser = (currentUser) =>
	nullSafeGetOrElse('roles', currentUser, []).some(
		(role) => BUYER_ADMIN_ONLY.has(role) || SUPPLIER_ADMIN_ONLY.has(role)
	);

export const isCheckInModalMandatory = (currentUser) => {
	const checkInNotesMandatory = nullSafeGetOrElse(
		'facility.internalTechConfig.checkInNotesMandatory',
		currentUser,
		false
	);
	const numImagesRequired = nullSafeGetOrElse(
		'facility.internalTechConfig.numImagesRequiredOnCheckIn',
		currentUser,
		0
	);
	return checkInNotesMandatory || numImagesRequired > 0;
};

export const getConfigValueDefaultingToTrue = (companyConfig, configNamePath, propName) => {
	const config = nullSafeGetOrElse(configNamePath, companyConfig, {});
	return typeof config[propName] === 'undefined' ? true : config[propName];
};

export const getPONumbers = (workOrder) =>
	nullSafeGetOrElse('poNumber', workOrder, '')
		.split(',')
		.filter((_) => !!_)
		.join(', ');

export const isPositiveNumber = (value) => value !== null && value !== undefined && value >= 0;

export const getDisabledDateTime = (disabledBeforeTime) => (current) => {
	if (!current) {
		return {};
	}
	const currentDate = moment();
	const selectedDate = current.clone().startOf('day');
	// If the selected date is in the future, return full time disabled
	if (selectedDate.isAfter(currentDate, 'day')) {
		return {
			disabledHours: () => [...Array(24).keys()],
			disabledMinutes: () => [...Array(60).keys()],
			disabledSeconds: () => [...Array(60).keys()],
		};
	}

	const checkIn = moment(disabledBeforeTime);

	// If the selected date is today, disable future times and times before disabledBeforeTime
	if (selectedDate.isSame(currentDate, 'day')) {
		return {
			disabledHours: () => {
				const hours = [];
				const currentHour = currentDate.hour();
				const checkInHour = checkIn.hour();
				if (currentDate.day() === checkIn.day()) {
					for (let i = 0; i < checkInHour; i++) {
						hours.push(i);
					}
				}
				for (let i = currentHour + 1; i < 24; i++) {
					hours.push(i);
				}
				return hours;
			},
			disabledMinutes: (selectedHour) => {
				const minutes = [];
				const currentMinute = currentDate.minute();
				const checkInMinute = moment(disabledBeforeTime).minute();
				if (selectedHour === checkIn.hour() && currentDate.day() === checkIn.day()) {
					for (let i = 0; i < checkInMinute; i++) {
						minutes.push(i);
					}
				}
				if (selectedHour === currentDate.hour()) {
					for (let i = currentMinute + 1; i < 60; i++) {
						minutes.push(i);
					}
				}
				return minutes;
			},
			disabledSeconds: (selectedHour, selectedMinute) => {
				const seconds = [];
				const currentSecond = currentDate.second();
				if (selectedHour === currentDate.hour() && selectedMinute === currentDate.minute()) {
					for (let i = currentSecond + 1; i < 60; i++) {
						seconds.push(i);
					}
				}
				return seconds;
			},
		};
	}
	return {};
};

export const getStatSliceProp = (propName, targetCollectionName, stateSlice) =>
	nullSafeGetOrElse(`${targetCollectionName}.${propName}`, stateSlice, {});

export const mergeTypeFieldsInit = (currentTypeDetails, typeDetails) => {
	const fieldsToMoveToOptional = currentTypeDetails.filter(
		(field) => !typeDetails.requiredFields.some((requiredField) => requiredField.key === field.key)
	);
	const mergedOptionalFields = [...typeDetails.optionalFields, ...fieldsToMoveToOptional];

	// Ensure uniqueness
	const uniqueOptionalFields = mergedOptionalFields.reduce((acc, field) => {
		if (!acc.some((f) => f.key === field.key) && field.key !== '') {
			acc.push(field);
		}
		return acc;
	}, []);

	return {
		...typeDetails,
		requiredFields: typeDetails.requiredFields,
		optionalFields: uniqueOptionalFields,
	};
};

export const mergeTypeFields = (currentType, newType) => {
	const previousOptionalFields = currentType.optionalFields || [];
	const previousRequiredFields = currentType.requiredFields || [];

	// Fields to move from previous required to optional (if not required anymore)
	const fieldsToMoveToOptional = previousRequiredFields.filter(
		(requiredField) =>
			!newType.requiredFields.some((newRequiredField) => newRequiredField.key === requiredField.key)
	);

	// Separate fields that need to be moved from optional to required
	const fieldsToMoveToRequired = previousOptionalFields.filter((optionalField) =>
		newType.requiredFields.some((requiredField) => requiredField.key === optionalField.key)
	);

	// Remove these fields from the previous optional fields
	const remainingOptionalFields = previousOptionalFields.filter(
		(optionalField) => !fieldsToMoveToRequired.some((field) => field.key === optionalField.key)
	);

	// Combine remaining optional fields with the new optional fields
	const updatedOptionalFields = [
		...remainingOptionalFields,
		...fieldsToMoveToOptional,
		...newType.optionalFields,
	];

	// Ensure uniqueness in optional fields
	const uniqueOptionalFields = updatedOptionalFields.reduce((acc, field) => {
		if (!acc.some((f) => f.key === field.key) && field.key !== '') {
			acc.push(field);
		}
		return acc;
	}, []);

	// Return updated type with new required and optional fields
	return {
		...newType,
		requiredFields: newType.requiredFields,
		optionalFields: uniqueOptionalFields,
	};
};

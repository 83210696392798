import React, { FC, useCallback } from 'react';
import { Tag, Button } from 'antd';

const entityTypeMap = {
	work_orders: 'Work Orders',
	invoices: 'Invoices',
	quotes: 'Quotes',
};

interface ADSavedViewRowDisplayProps {
	record: any;
	onAddWidget: (view) => void;
}

const ADSavedViewRowDisplay: FC<ADSavedViewRowDisplayProps> = ({
	record,
	onAddWidget,
}): React.ReactElement => {
	const addWidget = useCallback(() => {
		record && onAddWidget && onAddWidget(record);
	}, [record, onAddWidget]);

	return (
		<>
			<div
				style={{
					maxWidth: '80%',
					display: 'flex',
					flexDirection: 'row',
				}}
			>
				<span
					style={{
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
				>
					{record.name}
				</span>
				<Tag style={{ marginLeft: 8 }}>{entityTypeMap[record.entityType]}</Tag>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
				}}
			>
				<Button onClick={addWidget}>Add</Button>
			</div>
		</>
	);
};

export default ADSavedViewRowDisplay;

import { SPEND_CATEGORIES_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/spend_categories_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = standardInitialStateGenerator([
	'ASSET_TYPE_FORM_SPEND_CATEGORIES_AUTOCOMPLETE',
	'WORK_ORDER_FORM_SPEND_CATEGORIES_AUTOCOMPLETE',
	'SUPPLIER_WORK_ORDER_FORM_SPEND_CATEGORIES_AUTOCOMPLETE',
	'COMPANY_FORM_SPEND_CATEGORIES_AUTOCOMPLETE',
	'SUPPLIER_PRIVATE_NETWORK_FORM_SPEND_CATEGORIES_AUTOCOMPLETE',
	'PLANNED_MAINTENANCE_TEMPLATE_FORM_SPEND_CATEGORIES_AUTOCOMPLETE',
	'SUPPLIER_FACILITY_SPEND_CATEGORIES_AUTOCOMPLETE',
	'workOrdersAdvancedFilters',
]);
export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState);

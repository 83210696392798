import { LOCATIONS_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/locations_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = standardInitialStateGenerator([
	'locationsIndex',
	'locationsAnalyticsDropdown',
	'rankingPageLocations',
	'supplierPrivateNetworksAdvancedFilters',
	'METER_FORM_LOCATIONS_AUTOCOMPLETE',
	'ASSET_FORM_LOCATIONS_AUTOCOMPLETE',
	'WORK_ORDER_FORM_LOCATIONS_AUTOCOMPLETE',
	'SUPPLIER_WORK_ORDER_FORM_LOCATIONS_AUTOCOMPLETE',
	'SUPPLIER_PRIVATE_NETWORK_FORM_LOCATIONS_AUTOCOMPLETE',
	'PLANNED_MAINTENANCE_SCHEDULE_FORM_LOCATIONS_AUTOCOMPLETE',
	'PLANNED_MAINTENANCE_SCHEDULE_FORM_ADD_LOCATIONS_AUTOCOMPLETE',
	'BUDGETS_FORM_LOCATIONS_AUTOCOMPLETE',
	'SUPPLIER_CLIENT_LOCATIONS',
	'SITE_SURVEY_FORM_LOCATIONS_AUTOCOMPLETE',
	'workOrdersAdvancedFilters',
	'invoicesAdvancedFilters',
	'quotesAdvancedFilters',
	'assetsAdvancedFilters',
	'siteSurveysAdvancedFilters',
	'adminStoreAssociateIndex',
	'adminFieldTechsIndex',
	'metersAdvancedFilters',
	'techAnalyticsAdvancedFilters',
	'buyerUsersLocations',
	'plannedMaintenanceAdvancedFilters',
	'purchaseRequestsAdvancedFilters',
	'prLineItemsAdvancedFilters',
	'purchaseOrdersAdvancedFilters',
	'poLineItemsAdvancedFilters',
	'addPartsDropdown',
	'epslAdvancedFilters',
	'requestedPartsAdvancedFilters',
	'troubleshootingFormIndex',
	'assetTransferTargetCollection',
]);
export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState);

import { Button, Card, Popconfirm, Table, message } from 'antd';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { EmptyState } from '../empty_state/EmptyState';
import { formatPhoneNumber } from '../../utils/DataFormatterUtils';
import { KeyValueDisplay } from '../key_value_display/KeyValueDisplay';
import EditContactDirectoryModal from './EditContactDirectoryModal';
import { DeleteOutlined, EditOutlined, ExportOutlined } from '@ant-design/icons';
import { ExportToCsv } from 'export-to-csv';
import { CSV_EXPORT_DEFAULTS, ROLE_TYPES } from '../../utils/DataConstants';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	supplierPrivateNetworkV2UpdateForBuyer,
	supplierPrivateNetworkV2UpdateForSupplier,
} from '../../thunks/supplier_private_networks_thunks';

const BuyerSupplierContactDirectory: FC<any> = ({
	supplier,
	onSucess,
	updateSupplierInPrivateNetworkV2,
}): React.ReactElement => {
	const [modalVisible, setModalVisible] = useState(false);
	const [editingRecord, setEditingRecord] = useState<any>(null);
	const [updating, setUpdating] = useState(false);

	const csvTitle = useMemo(
		() => `${nullSafeGetOrElse('supplierFacilityAlias', supplier, 'supplier')}_contact_directory`,
		[supplier]
	);

	const data = useMemo(
		() =>
			nullSafeGetOrElse('supplierContactDirectory', supplier, []).map((_, idx) => ({
				id: `contact_${idx}`,
				..._,
			})),
		[supplier]
	);

	const onAdd = useCallback(() => setModalVisible(true), []);

	const onEdit = useCallback(
		(record) => () => {
			setEditingRecord(record);
			setModalVisible(true);
		},
		[]
	);

	const handleCancel = useCallback(() => {
		setEditingRecord(null);
		setModalVisible(false);
	}, []);

	const updateContactDirectory = useCallback(
		(supplierContactDirectory) => {
			setUpdating(true);
			updateSupplierInPrivateNetworkV2({
				...supplier,
				supplierContactDirectory,
			})
				.then((data) => {
					message.success('Updated Successfully!');
					onSucess(data);
				})
				.catch(() => {
					message.error('Something went wrong, Please try again!');
				})
				.finally(() => {
					setUpdating(false);
					handleCancel();
				});
		},
		[handleCancel, onSucess, supplier, updateSupplierInPrivateNetworkV2]
	);

	const onDelete = useCallback(
		(record) => () => {
			updateContactDirectory(data.filter((_) => _.id !== record.id));
		},
		[data, updateContactDirectory]
	);

	const handleSubmit = useCallback(
		(values) => {
			const supplierContactDirectory = editingRecord
				? data.map((_) => (_.id === editingRecord.id ? values : _))
				: [...data, ...[values]];
			updateContactDirectory(supplierContactDirectory);
		},
		[data, editingRecord, updateContactDirectory]
	);

	const columns = useMemo(
		() => [
			{
				title: 'Name',
				dataIndex: 'name',
			},
			{
				title: 'Title',
				dataIndex: 'title',
			},
			{
				title: 'Contact',
				render: (_, record) => {
					const contactsMap = {
						...(nullSafeGet('phoneNumbers', record) && {
							'Phone Numbers': record.phoneNumbers
								.map((item) => formatPhoneNumber(item))
								.join(', '),
						}),
						...(nullSafeGet('emails', record) && {
							Emails: nullSafeGetOrElse('emails', record, []).join(', '),
						}),
					};
					return <KeyValueDisplay keyWidth={120} keyValueStore={contactsMap} />;
				},
			},
			{
				title: 'Notes',
				dataIndex: 'notes',
				render: (_) => <div className="whitespace-pre-line">{_}</div>,
			},
			{
				render: (_, record) => (
					<div className="flex flex-row items-center">
						<Button
							title="Edit"
							onClick={onEdit(record)}
							type="link"
							icon={<EditOutlined translate="" />}
						/>
						<Popconfirm
							onConfirm={onDelete(record)}
							placement="topLeft"
							title="Are you sure you want to delete?"
							okText="Yes"
							cancelText="No"
						>
							<Button title="Delete" type="link" icon={<DeleteOutlined translate="" />} />
						</Popconfirm>
					</div>
				),
			},
		],
		[onDelete, onEdit]
	);

	const onExportContacts = useCallback(() => {
		const csvExporter = new ExportToCsv({
			...CSV_EXPORT_DEFAULTS,
			filename: csvTitle,
			title: csvTitle,
			headers: ['Name', 'Title', 'Phone Numbers', 'Emails', 'Notes'],
		});
		csvExporter.generateCsv(
			data.map((row) => ({
				name: row.name,
				title: row.title,
				phoneNumbers: nullSafeGetOrElse('phoneNumbers', row, []).join(', '),
				emails: nullSafeGetOrElse('emails', row, []).join(', '),
				notes: row.notes,
			}))
		);
	}, [csvTitle, data]);

	return (
		<Card
			title="Supplier Contact Directory"
			extra={
				<div className="flex flex-row items-center">
					<Button icon={<ExportOutlined translate="" />} onClick={onExportContacts}>
						Export
					</Button>
					<div className="ml-2">
						<Button onClick={onAdd} type="primary">
							Add
						</Button>
					</div>
				</div>
			}
		>
			{nullSafeGet('0', data) ? (
				<Table columns={columns} rowKey={(el) => el['id']} dataSource={data} />
			) : (
				<EmptyState
					graphic={
						<img
							style={{ marginBottom: 8 }}
							src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
							alt="You haven't added any contact to the directory yet!"
						/>
					}
					headline={"You haven't added any contact to the directory yet!"}
					body={
						<div style={{ textAlign: 'center' }}>
							<div style={{ maxWidth: 440, marginBottom: 16 }}>
								<Button style={{ marginLeft: 16 }} type="primary" size="large" onClick={onAdd}>
									Add Contact
								</Button>
							</div>
						</div>
					}
				/>
			)}
			{modalVisible && (
				<EditContactDirectoryModal
					record={editingRecord}
					onCancel={handleCancel}
					onSubmit={handleSubmit}
					loading={updating}
				/>
			)}
		</Card>
	);
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	updateSupplierInPrivateNetworkV2: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? supplierPrivateNetworkV2UpdateForSupplier(entity)
				: supplierPrivateNetworkV2UpdateForBuyer(entity)
		),
});

const ComponentWithoutUserType = withRouter(
	connect(null, mapDispatchToProps)(BuyerSupplierContactDirectory)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import { Button, Form, InputNumber, Row, Select, Spin, message } from 'antd';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { partsPerStockLocationsRestCrudThunksForSupplier } from '../../thunks/parts_per_stock_locations_thunks';
import { updatePartsOnServiceCallWithPartsPerStockLocationForSupplier } from '../../thunks/service_calls_thunks';
import {
	getConfigValueDefaultingToTrue,
	getCurrency,
	getEntityById,
	getObjectValues,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import {
	fetchSLWithPPSLAndSLWithLocationForSupplier,
	stockLocationsRestCrudThunksForSupplier,
} from '../../thunks/stock_locations_thunks';
import { useForm } from 'antd/lib/form/Form';
import {
	fetchPartsWithStockLocationForSupplier,
	partsRestCrudThunksForSupplier,
} from '../../thunks/parts_thunks';
import { unitsOfMeasureRestCrudThunksForSupplier } from '../../thunks/units_of_measure_thunks';
import { scheduleServiceCallForSupplier } from '../../thunks/work_orders_thunks';
import moment from 'moment';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { STOCK_LOCATIONS_CRUD_ACTION_CREATORS } from '../../actions/stock_locations_actions';
import { requestedPartsRestCrudThunksForSupplier } from '../../thunks/requested_parts_thunks';
import OWRadioGroup from '../ow_radio_group/OWRadioGroup';
import {
	fetchLocationsWithStockLocationsForSupplier,
	locationsRestCrudThunksForSupplier,
} from '../../thunks/locations_thunks';
import PartWithIDDisplay from '../parts_detail_details_page/PartWithIDDisplay';
import NewPartFormFields from './NewPartFormFields';
import { partCatalogsRestCrudThunksForSupplier } from '../../thunks/part_catalogs_thunks';
import { LIMIT_ONE_PAGINATION } from '../../utils/DataConstants';
import { PERMISSION_NAMES, isSupplierAllowedToAccess } from '../../utils/AuthUtils';
import { requiredFieldsNotFilled } from '../../utils/WORequestPartsUtil';

const STOCK_LOCATION_ASSOCIATED_PARTS_TC = 'addPartsInWorkOrderIndex';
const STOCK_LOCATIONS_WITH_PPSL_TC = 'stockLocationsWithPpslIndex';

const AddPartsFormForSLWithLocation: FC<any> = ({
	fetchMultipleStockLocations,
	parts,
	fetchMultiplePartsPerStockLocations,
	createPart,
	deletePart,
	workOrder,
	onCancel,
	updatePartsWithQuantity,
	successCallback,
	serviceCall,
	partInServiceCall,
	openCreatePurchaseRequest,
	currentUser,
	unitsOfMeasure,
	fetchUnitsOfMeasure,
	scheduleServiceCall,
	woStockLocationId,
	fetchParts,
	partsPerStockLocation,
	clearStockLocationTargetCollection,
	stockLocations,
	fetchStockLocationsWithPpsl,
	reserving,
	slLoading,
	locations,
	fetchLocations,
	fetchMultipleLocations,
	createPartCatalog,
	companyConfig,
}) => {
	const [form] = useForm();

	const [locationWithoutSLFetching, setLocationWithoutSLFetching] = useState(true);
	const [locationWithoutSLAvailable, setLocationWithoutSLAvailable] = useState(false);

	useEffect(() => {
		fetchLocations({ noStockLocationAssociated: true }, null, LIMIT_ONE_PAGINATION)
			.then((res) => setLocationWithoutSLAvailable(!!nullSafeGetOrElse('0', res, false)))
			.finally(() => setLocationWithoutSLFetching(false));
	}, [fetchLocations]);

	const { setFieldsValue } = form;

	const partId = Form.useWatch('partId', form);
	const stockLocationId = Form.useWatch('stockLocationId', form);
	const quantity = Form.useWatch('quantity', form);
	const locationType = Form.useWatch('locationType', form);
	const locationId = Form.useWatch('locationId', form);
	const serviceCallId = Form.useWatch('serviceCallId', form);

	const [maxValue, setMaxValue] = useState(0);
	const [saving, setSaving] = useState(false);
	const [newPart, setNewPart] = useState(null);

	const currencyId = useMemo(() => nullSafeGet('id', getCurrency({ currentUser })), [currentUser]);

	const isUpdateQuantity = useMemo(() => !!partInServiceCall, [partInServiceCall]);

	const isLocationSelect = useMemo(() => locationType === 'location', [locationType]);

	const actualMaxValue = useMemo(() => (maxValue > 0 ? maxValue : 0), [maxValue]);

	const canCreatePartOrEquipment = useMemo(
		() =>
			isSupplierAllowedToAccess(
				PERMISSION_NAMES.MODIFY_PARTS_AND_EQUIPMENTS,
				companyConfig,
				currentUser.roles
			),
		[companyConfig, currentUser.roles]
	);

	useEffect(() => clearStockLocationTargetCollection(STOCK_LOCATIONS_WITH_PPSL_TC), []);

	const newPartSelected = useMemo(() => !!newPart, [newPart]);

	useEffect(() => {
		newPartSelected &&
			!isLocationSelect &&
			fetchStockLocationsWithPpsl(-1, {}, STOCK_LOCATIONS_WITH_PPSL_TC);
	}, [fetchStockLocationsWithPpsl, isLocationSelect, newPartSelected, partId]);

	const existingParts = useMemo(
		() => nullSafeGetOrElse('partsWithQuantity', serviceCall, []),
		[serviceCall]
	);

	useEffect(() => {
		woStockLocationId && !stockLocationId && setFieldsValue({ stockLocationId: woStockLocationId });
	}, [woStockLocationId, setFieldsValue, stockLocationId, partId]);

	const selectedStockLocationRecord = useMemo(
		() =>
			getRecordsForTargetCollection(stockLocations, STOCK_LOCATIONS_WITH_PPSL_TC).find(
				(_) => _.id === stockLocationId
			),
		[stockLocationId, stockLocations]
	);

	const partsPerStockLocationId = useMemo(
		() => nullSafeGet('partAtStockLocation.id', selectedStockLocationRecord),
		[selectedStockLocationRecord]
	);

	const existingPartQuantity = useMemo(() => {
		const matchingRecord = existingParts.find(
			(_) => _.partsPerStockLocationId === partsPerStockLocationId
		);
		return nullSafeGetOrElse('quantity', matchingRecord, 0);
	}, [existingParts, partsPerStockLocationId]);

	useEffect(() => {
		fetchUnitsOfMeasure();
	}, [fetchUnitsOfMeasure]);

	useEffect(() => {
		if (partId && stockLocationId && !slLoading && !selectedStockLocationRecord) {
			setFieldsValue({
				stockLocationId: undefined,
			});
		}
	}, [partId, selectedStockLocationRecord, setFieldsValue, slLoading, stockLocationId]);

	useEffect(() => {
		partId && fetchStockLocationsWithPpsl(partId, {}, STOCK_LOCATIONS_WITH_PPSL_TC);
	}, [partId, fetchStockLocationsWithPpsl]);

	const partRecord = useMemo(() => {
		const partRecords = getRecordsForTargetCollection(parts, STOCK_LOCATION_ASSOCIATED_PARTS_TC);
		return partRecords.find((_) => _.id === partId);
	}, [parts, partId]);

	const isStockedPart = useMemo(() => nullSafeGet('isStocked', partRecord), [partRecord]);

	const slWIthPpslRecord = useMemo(() => {
		const stockLocationRecords = getRecordsForTargetCollection(
			stockLocations,
			STOCK_LOCATIONS_WITH_PPSL_TC
		);
		return stockLocationRecords.find((_) => _.id === stockLocationId);
	}, [stockLocationId, stockLocations]);

	const isMobileStockLocation = useMemo(
		() =>
			nullSafeGetOrElse('isMobile', slWIthPpslRecord, false) ||
			nullSafeGet('location.id', slWIthPpslRecord) === workOrder.locationId,
		[slWIthPpslRecord, workOrder.locationId]
	);

	/*
	BE doesn't send any SL record for a Non-Stocked Part. So in any case when,
	a stock location is selected for a non-stocked part, it should default to
	PR flow
	*/
	const nonStockedPartAndStockLocationChosen = useMemo(
		() => !isStockedPart && !!stockLocationId,
		[isStockedPart, stockLocationId]
	);

	useEffect(() => {
		setMaxValue(
			partInServiceCall
				? nullSafeGetOrElse('partsPerStockLocation.availableQuantity', partInServiceCall, 0) +
						nullSafeGetOrElse('quantity', partInServiceCall, 0)
				: nullSafeGetOrElse('partAtStockLocation.availableQuantity', slWIthPpslRecord, 0)
		);
	}, [
		partsPerStockLocation,
		partsPerStockLocationId,
		partInServiceCall,
		stockLocations,
		stockLocationId,
		slWIthPpslRecord,
	]);

	const supplierCompanyId = useMemo(
		() => nullSafeGet('facility.supplierCompanyId', currentUser),
		[currentUser]
	);

	const supplierFacilityId = useMemo(() => nullSafeGet('facility.id', currentUser), [currentUser]);

	const defaultUnitOfMeasureId = useMemo(() => {
		const unitsOfMeasureRecords =
			unitsOfMeasure && unitsOfMeasure.records ? getObjectValues(unitsOfMeasure.records) : [];
		const eachUnit = unitsOfMeasureRecords.find((_) => _.displayNameSingular === 'each');
		return nullSafeGetOrElse('id', eachUnit, 1);
	}, [unitsOfMeasure]);

	const createPartWithSLResponse = useCallback((name) => {
		return new Promise((resolve) => {
			setNewPart({
				name,
			});
			resolve({});
		});
	}, []);

	const initialValue = useMemo(
		() => ({
			serviceCallId: nullSafeGet('id', serviceCall),
			partId: nullSafeGet('partId', partInServiceCall),
			quantity: nullSafeGet('quantity', partInServiceCall),
			partsPerStockLocationId: nullSafeGet('partsPerStockLocationId', partInServiceCall),
			stockLocationId: nullSafeGet('partsPerStockLocation.stockLocationId', partInServiceCall),
		}),
		[serviceCall, partInServiceCall]
	);

	const getServiceCallId = useCallback(() => {
		return new Promise((resolve, reject) => {
			serviceCallId
				? resolve(serviceCallId)
				: scheduleServiceCall({
						serviceScheduledAt: moment().format(),
						supplierFacilityId,
						workOrderId: workOrder.id,
				  })
						.then((sc) =>
							nullSafeGet('lastServiceCall.id', sc)
								? resolve(nullSafeGet('lastServiceCall.id', sc))
								: reject('Service call id not found')
						)
						.catch((err) => reject(err));
		});
	}, [serviceCallId, scheduleServiceCall, supplierFacilityId, workOrder.id]);

	const createPartCatalogIfNecessary = useCallback(
		(partEquipmentVendorId, partId) => {
			return new Promise((resolve, reject) => {
				if (!partEquipmentVendorId) {
					resolve({});
				} else {
					createPartCatalog({
						partId,
						partEquipmentVendorId,
						currencyId,
					})
						.then((pc) => resolve(pc))
						.catch((err) => reject(err));
				}
			});
		},
		[createPartCatalog, currencyId]
	);

	const getPartRecordAfterUpdating = useCallback(() => {
		return new Promise((resolve, reject) => {
			if (!newPart) {
				if (partRecord.defaultVendorId && !partRecord.defaultPartCatalogId) {
					createPartCatalogIfNecessary(partRecord.defaultVendorId, partId)
						.then((defaultPartCatalog) => ({
							...partRecord,
							defaultPartCatalog,
						}))
						.catch((err) => {
							message.error(err);
							resolve(partRecord);
						});
				} else {
					resolve(partRecord);
				}
			}

			const { getFieldsValue } = form;

			const values = getFieldsValue();

			if (newPartSelected) {
				createPart({
					name: values.name,
					uomId: defaultUnitOfMeasureId,
					supplierFacilityId,
					supplierCompanyId,
					images: [],
					partNumber: values.partNumber,
					defaultVendorId: values.defaultVendorId,
				})
					.then((createdPart) => {
						setFieldsValue({ partId: createdPart.id });
						createPartCatalogIfNecessary(values.defaultVendorId, createdPart.id)
							.then((defaultPartCatalog) => {
								resolve({
									...createdPart,
									defaultPartCatalogId: nullSafeGet('id', defaultPartCatalog),
									defaultPartCatalog,
								});
							})
							.catch((err) => {
								message.error(err);
								reject(err);
							});
					})
					.catch((err) => {
						message.error(err);
						reject(err);
					});
			} else {
				resolve(partRecord);
				newPart && deletePart(newPart);
			}
		});
	}, [
		createPart,
		createPartCatalogIfNecessary,
		defaultUnitOfMeasureId,
		deletePart,
		form,
		newPart,
		newPartSelected,
		partId,
		partRecord,
		setFieldsValue,
		supplierCompanyId,
		supplierFacilityId,
	]);

	const updatePartsQuantityToWO = useCallback(
		(addQuantity) => {
			return new Promise((resolve, reject) => {
				getServiceCallId()
					.then((serviceCallId) =>
						updatePartsWithQuantity({
							serviceCallId,
							partsPerStockLocationId,
							partId,
							stockLocationId,
							quantity: addQuantity + existingPartQuantity,
						})
							.then((res) => resolve(res))
							.catch((err) => reject(err))
					)
					.catch((err) => reject(err));
			});
		},
		[
			existingPartQuantity,
			getServiceCallId,
			partId,
			partsPerStockLocationId,
			stockLocationId,
			updatePartsWithQuantity,
		]
	);

	const onSubmit = useCallback(() => {
		setSaving(true);
		updatePartsQuantityToWO(quantity)
			.then(() => successCallback && successCallback())
			.catch((err) => message.error(err))
			.finally(() => setSaving(false));
	}, [updatePartsQuantityToWO, quantity, successCallback]);

	const openCreatePR = useCallback(() => {
		if (requiredFieldsNotFilled(form, companyConfig, newPartSelected)) {
			return;
		}

		setSaving(true);
		getPartRecordAfterUpdating().then((part) => {
			if (actualMaxValue) {
				updatePartsQuantityToWO(actualMaxValue)
					.then(() =>
						openCreatePurchaseRequest({
							part,
							quantity: quantity ? quantity - actualMaxValue : 0,
							...(!isLocationSelect && { stockLocationId }),
							...(isLocationSelect && { locationId }),
							disableLocation: !locationWithoutSLAvailable,
						})
					)
					.catch(() => message.error('Unable to reserve parts! Please contact support!'))
					.finally(() => setSaving(false));
			} else {
				setSaving(false);
				openCreatePurchaseRequest({
					part,
					quantity: quantity ? quantity - actualMaxValue : 0,
					...(!isLocationSelect && { stockLocationId }),
					...(isLocationSelect && { locationId }),
					disableLocation: !locationWithoutSLAvailable,
				});
			}
		});
	}, [
		form,
		companyConfig,
		newPartSelected,
		getPartRecordAfterUpdating,
		actualMaxValue,
		updatePartsQuantityToWO,
		openCreatePurchaseRequest,
		quantity,
		isLocationSelect,
		stockLocationId,
		locationId,
		locationWithoutSLAvailable,
	]);

	const availableQuantityLessThanNeeded = useMemo(
		() => !maxValue || maxValue < quantity,
		[maxValue, quantity]
	);

	const stockedPartAndSkipBackorders = useMemo(() => {
		const skipBackOrders = nullSafeGetOrElse(
			'inventoryConfig.skipBackordersForStockedPart',
			companyConfig,
			false
		);
		return partId && isStockedPart && availableQuantityLessThanNeeded && skipBackOrders;
	}, [availableQuantityLessThanNeeded, companyConfig, isStockedPart, partId]);

	const showCreatePOButton = useMemo(() => {
		return (
			(partId && (isMobileStockLocation ? availableQuantityLessThanNeeded : false)) ||
			isLocationSelect ||
			(nonStockedPartAndStockLocationChosen && availableQuantityLessThanNeeded) ||
			stockedPartAndSkipBackorders
		);
	}, [
		availableQuantityLessThanNeeded,
		isLocationSelect,
		isMobileStockLocation,
		nonStockedPartAndStockLocationChosen,
		partId,
		stockedPartAndSkipBackorders,
	]);

	const primaryActionButton = useMemo(() => {
		return showCreatePOButton ? (
			<Button
				type="primary"
				size="large"
				style={{ marginLeft: '16px' }}
				loading={saving || reserving}
				onClick={openCreatePR}
			>
				Request Parts
			</Button>
		) : (
			<Button
				type="primary"
				size="large"
				style={{ marginLeft: '16px' }}
				key="submit"
				htmlType="submit"
				form="add-parts-form"
				loading={saving}
			>
				{isUpdateQuantity ? 'Update' : 'Add'}
			</Button>
		);
	}, [showCreatePOButton, saving, reserving, openCreatePR, isUpdateQuantity]);

	return locationWithoutSLFetching ? (
		<div className="flex flex-row justify-center">
			<Spin />
		</div>
	) : (
		<Form
			form={form}
			id="add-parts-form"
			layout="vertical"
			requiredMark={false}
			initialValues={initialValue}
			onFinish={onSubmit}
		>
			<Form.Item name="serviceCallId" hidden />
			<Form.Item name="stockLocationId" hidden />
			<Form.Item
				hidden={newPartSelected}
				name="partId"
				label="Part"
				rules={[
					{
						required: true,
						message: 'Please select a Part!',
					},
				]}
			>
				<OWAsyncSelect
					key="partId"
					disabled={isUpdateQuantity}
					stateSlice={parts}
					targetCollectionName={STOCK_LOCATION_ASSOCIATED_PARTS_TC}
					labelAccessor={(el) => nullSafeGet('name', el)}
					fetchData={(
						search,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
					) =>
						fetchParts(
							{ search: search || undefined },
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						)
					}
					createNewEntity={
						canCreatePartOrEquipment ? (name) => createPartWithSLResponse(name) : undefined
					}
					fetchMultiple={(ids, targetCollectionName) => {
						fetchMultiplePartsPerStockLocations(ids, targetCollectionName);
					}}
					renderRecord={(part) => {
						return (
							<Select.Option key={part.id} value={part.id}>
								<div className="flex max-w-full flex-row items-center justify-between">
									<PartWithIDDisplay part={part} numberMaxChars={64} nameLengthPercentage={80} />
									<div className="text-gray-400">{part.isStocked ? 'Stocked' : 'Not Stocked'}</div>
								</div>
							</Select.Option>
						);
					}}
					sortBy={{ sort_by: 'name', order: 'ascend' }}
				/>
			</Form.Item>
			{newPartSelected ? <NewPartFormFields part={newPart} /> : null}
			{!isUpdateQuantity && !isStockedPart && locationWithoutSLAvailable && (
				<Form.Item name="locationType" initialValue={'location'}>
					<OWRadioGroup
						valueAccessor={(item) => item.value}
						renderRecord={(item) => item.label}
						items={[
							{
								label: 'Location',
								value: 'location',
							},
							{
								label: 'Stock Location',
								value: 'stockLocation',
							},
						]}
					/>
				</Form.Item>
			)}
			{isLocationSelect ? (
				<Form.Item
					name="locationId"
					label="Location"
					initialValue={nullSafeGet('locationId', workOrder)}
					rules={[
						{
							required: true,
							message: 'Please select a location!',
						},
					]}
				>
					<OWAsyncSelect
						stateSlice={locations}
						valueAccessor={(el) => el.id}
						targetCollectionName={'addPartsDropdown'}
						fetchData={(
							name,
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						) =>
							fetchLocations(
								{
									name: name || undefined,
								},
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							)
						}
						fetchMultiple={(ids, targetCollectionName) => {
							fetchMultipleLocations(ids, targetCollectionName);
						}}
						renderRecord={(l) => (
							<Select.Option
								key={l.id}
								value={l.id}
								disabled={nullSafeGet('linkedStockLocationIds.0', l)}
							>
								<div>{l.name}</div>
								{nullSafeGet('linkedStockLocationIds.0', l) ? (
									<div className="text-gray-300">
										Please choose the corresponding stock location
									</div>
								) : (
									<div style={{ color: 'rgba(0,0,0,0.45)' }}>
										{nullSafeGet('buyerFacility.primaryAddress.streetAddress1', l)},{' '}
										{nullSafeGet('buyerFacility.primaryAddress.city', l)}{' '}
										{nullSafeGet('buyerFacility.primaryAddress.region', l)}
									</div>
								)}
							</Select.Option>
						)}
					/>
				</Form.Item>
			) : (
				<Form.Item
					name="stockLocationId"
					label={
						<div className="flex flex-row items-center">
							Stock Location
							{slLoading && (
								<div className="ml-2">
									<Spin size="small" />
								</div>
							)}
						</div>
					}
					rules={[
						{
							required: true,
							message: 'Please select a stock location!',
						},
					]}
				>
					<OWAsyncSelect
						disabled={(!newPartSelected && !partId) || isUpdateQuantity}
						stateSlice={stockLocations}
						valueAccessor={(el) => el.id}
						targetCollectionName={STOCK_LOCATIONS_WITH_PPSL_TC}
						fetchData={(
							name,
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						) =>
							(newPartSelected || partId) &&
							fetchStockLocationsWithPpsl(
								newPartSelected ? -1 : partId,
								{
									name: name || undefined,
								},
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							)
						}
						fetchMultiple={(ids, targetCollectionName) => {
							fetchMultipleStockLocations(ids, targetCollectionName);
						}}
						renderRecord={(sl) => (
							<Select.Option key={sl.id} value={sl.id}>
								<Row align="top" justify="space-between">
									<div style={{ flex: 1 }}>{nullSafeGet('name', sl)}</div>
									<div style={{ color: 'rgba(0, 0, 0, 0.5)' }}>
										{`Available: ${
											nullSafeGetOrElse('partAtStockLocation.availableQuantity', sl, 0) < 0
												? 0
												: nullSafeGetOrElse('partAtStockLocation.availableQuantity', sl, 0)
										}`}
									</div>
								</Row>
							</Select.Option>
						)}
						sortBy={{ sort_by: 'availableQuantity', order: 'ascend' }}
					/>
				</Form.Item>
			)}
			<Form.Item
				name="quantity"
				label={`${showCreatePOButton ? 'Request' : 'Add'} Quantity`}
				rules={[
					{
						type: 'number',
						...(!isLocationSelect && { min: 1 }),
						warningOnly: true,
					},
					{
						required: true,
						message: 'Please enter valid quantity',
					},
				]}
			>
				<InputNumber style={{ width: '100%' }} min="1" />
			</Form.Item>
			<Row
				justify="end"
				style={{
					borderTop: 'solid 1px rgba(0, 0, 0, 0.1)',
					paddingTop: '8px',
					margin: '0 0 -8px',
				}}
			>
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>
				{primaryActionButton}
			</Row>
		</Form>
	);
};

const mapStateToProps = (state, ownProps) => ({
	parts: state.parts,
	workOrder: getEntityById(ownProps.match.params.id, state.work_orders),
	stockLocations: state.stock_locations,
	slLoading: state.stock_locations.fetching,
	partsPerStockLocation: state.parts_per_stock_locations,
	ppslFetching: state.parts_per_stock_locations.fetching,
	currentUser: state.session.currentUser,
	unitsOfMeasure: state.units_of_measure,
	requestedParts: state.requested_parts,
	requestedPartsLoading: state.requested_parts.fetching,
	reserving: state.requested_parts.creating || state.requested_parts.updating,
	locations: state.locations,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	createPart: (entity) => dispatch(partsRestCrudThunksForSupplier.create(entity)),
	deletePart: (entity) => dispatch(partsRestCrudThunksForSupplier.delete(entity)),
	fetchParts: (params, targetCollectionName, pagination, sorting, filters, addToTargetCollection) =>
		dispatch(
			partsRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchPartsWithStockLocations: (
		stockLocationId,
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			fetchPartsWithStockLocationForSupplier(
				stockLocationId,
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchPartsPerStockLocation: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			partsPerStockLocationsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	clearStockLocationTargetCollection: (tcName) =>
		dispatch(STOCK_LOCATIONS_CRUD_ACTION_CREATORS.clearTargetCollection(tcName)),
	fetchMultiplePartsPerStockLocations: (ids, targetCollectionName) =>
		partsPerStockLocationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName),
	updatePartsWithQuantity: (entity) =>
		dispatch(updatePartsOnServiceCallWithPartsPerStockLocationForSupplier(entity)),
	fetchMultipleStockLocations: (ids, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchStockLocations: (params, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.read(params, targetCollectionName)),
	fetchUnitsOfMeasure: (params) => dispatch(unitsOfMeasureRestCrudThunksForSupplier.read(params)),
	scheduleServiceCall: (serviceCall) => dispatch(scheduleServiceCallForSupplier(serviceCall)),
	fetchStockLocationsWithPpsl: (
		partId,
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			fetchSLWithPPSLAndSLWithLocationForSupplier(
				partId,
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleRequestedParts: (ids, targetCollectionName) =>
		dispatch(requestedPartsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchRequestedParts: (params, targetCollectionName) =>
		dispatch(requestedPartsRestCrudThunksForSupplier.read(params, targetCollectionName)),
	reservePart: (entity) => dispatch(requestedPartsRestCrudThunksForSupplier.create(entity)),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			fetchLocationsWithStockLocationsForSupplier(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	createPartCatalog: (entity) => dispatch(partCatalogsRestCrudThunksForSupplier.create(entity)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(AddPartsFormForSLWithLocation)
);

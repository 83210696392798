import React, { FC, useMemo } from 'react';
import { Card, Col, Layout, Row, Table } from 'antd';
import { HorizontalKeyValueDisplay } from '../horizontal_key_value_display/HorizontalKeyValueDisplay';
import { troubleshootingRestCrudThunksForBuyer } from '../../thunks/troubleshooting_thunks';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { TROUBLESHOOTING_STEP_COLUMNS } from './TroubleshootingForm';

const { Content } = Layout;

const TroubleshootingDetailDetailsPage: FC<any> = ({
	troubleshootingAttribute,
	fetching,
}): React.ReactElement => {
	const troubleshootingDetail = useMemo(
		() => ({
			...(troubleshootingAttribute.name && {
				Name: troubleshootingAttribute.name,
			}),
			...(nullSafeGet('location.name', troubleshootingAttribute) && {
				Location: nullSafeGet('location.name', troubleshootingAttribute),
			}),
			...(nullSafeGet('asset.name', troubleshootingAttribute) && {
				Asset: nullSafeGet('asset.name', troubleshootingAttribute),
			}),
			...(nullSafeGet('assetType.name', troubleshootingAttribute) && {
				'Asset Type': nullSafeGet('assetType.name', troubleshootingAttribute),
			}),
			...(nullSafeGet('problemType.name', troubleshootingAttribute) && {
				'Problem Type': nullSafeGet('problemType.name', troubleshootingAttribute),
			}),
			...(nullSafeGet('workorderPriority.name', troubleshootingAttribute) && {
				'Workorder Priority': nullSafeGet('workorderPriority.name', troubleshootingAttribute),
			}),
			...(nullSafeGet('supplierFacility.name', troubleshootingAttribute) && {
				'Supplier Facility': nullSafeGet('supplierFacility.name', troubleshootingAttribute),
			}),
		}),
		[troubleshootingAttribute]
	);

	return (
		<Content
			style={{
				padding: '0 0.5em',
			}}
		>
			<Row style={{ margin: '16px 4px' }} gutter={12}>
				<Col span={24}>
					<Card loading={fetching} title="Attributes">
						<HorizontalKeyValueDisplay
							rowStyle={{ minWidth: '25%' }}
							keyValueStore={troubleshootingDetail}
						/>
					</Card>
				</Col>
			</Row>
			<Row style={{ margin: '16px 4px' }} gutter={12}>
				<Col span={24}>
					<Card loading={fetching} title="Steps">
						<Table
							columns={TROUBLESHOOTING_STEP_COLUMNS}
							showHeader
							rowKey={(el, idx) => `step-${idx}`}
							dataSource={nullSafeGetOrElse('troubleshootingSteps', troubleshootingAttribute, [])}
						/>
					</Card>
				</Col>
			</Row>
		</Content>
	);
};

const mapStateToProps = (state) => ({
	fetching: state.troubleshooting.fetching,
	troubleshootingAttribute: state.troubleshooting.detail,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	update: (entity) => dispatch(troubleshootingRestCrudThunksForBuyer.update(entity)),
	fetchTroubleshootingAttribute: (id) =>
		dispatch(troubleshootingRestCrudThunksForBuyer.readOne(id)),
});

const ComponentWithoutUserType = withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(TroubleshootingDetailDetailsPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

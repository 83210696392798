import { EmptyState } from '../../empty_state/EmptyState';
import * as React from 'react';
import { Row } from 'antd';

export function QuotesPendingApprovalTableEmptyState(currentUser) {
	return (
		<EmptyState
			graphic={
				<img
					style={{ marginBottom: 8 }}
					src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
				/>
			}
			headline={'No quotes pending approval in this project!'}
			body={
				<div style={{ textAlign: 'center' }}>
					<div style={{ maxWidth: 440, marginBottom: 16 }}>
						Looks like your team doesn't have any quotes pending approval in this project.
					</div>
				</div>
			}
			height={225}
		/>
	);
}

export function QuotesGenericEmptyState(currentUser) {
	return (
		<EmptyState
			graphic={
				<img
					style={{ marginBottom: 8 }}
					src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
				/>
			}
			headline={'No quotes in this view!'}
			body={
				<div style={{ textAlign: 'center' }}>
					<div style={{ maxWidth: 440, marginBottom: 16 }}>
						Looks like your team doesn't have any quotes in this View.
					</div>
				</div>
			}
			height={225}
		/>
	);
}

export function DashboardQuotesEmptyState() {
	return (
		<Row
			justify="center"
			style={{
				fontSize: '16px',
				color: 'rgba(0, 0, 0, .5)',
			}}
		>
			No quotes in this view!
		</Row>
	);
}

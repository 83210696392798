import { crudActionConstantsGenerator } from './crud_action_constants_generator';
import { crudActionCreatorsGenerator } from './crud_action_creators_generator';

export const SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CONSTANTS = crudActionConstantsGenerator(
	'SUPPLIER_PRIVATE_NETWORKS'
);
export const SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS = crudActionCreatorsGenerator(
	SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CONSTANTS
);
export const FETCH_DISTINCT_SUPPLIER_PRIVATE_NETWORKS_SUCCESS =
	'FETCH_DISTINCT_SUPPLIER_PRIVATE_NETWORKS_SUCCESS';
export const fetchDistinctSupplierPrivateNetworksSuccess = (
	records,
	count,
	targetCollectionName,
	pagination,
	sorting,
	filters
) => {
	return {
		count,
		records,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		type: FETCH_DISTINCT_SUPPLIER_PRIVATE_NETWORKS_SUCCESS,
	};
};

export const FETCH_SUPPLIERS_FOR_BUYERS = 'FETCH_SUPPLIERS_FOR_BUYERS';
export function fetchSuppliersForBuyers() {
	return {
		type: FETCH_SUPPLIERS_FOR_BUYERS,
	};
}
export const FETCH_SUPPLIERS_FOR_BUYERS_SUCCESS = 'FETCH_SUPPLIERS_FOR_BUYERS_SUCCESS';
export const fetchSuppliersForBuyersSuccess = (records) => {
	return {
		records,
		type: FETCH_SUPPLIERS_FOR_BUYERS_SUCCESS,
	};
};
export const FETCH_SUPPLIERS_FOR_BUYERS_FAILURE = 'FETCH_SUPPLIERS_FOR_BUYERS_FAILURE';
export function fetchSuppliersForBuyersFailure(errors) {
	return {
		type: FETCH_SUPPLIERS_FOR_BUYERS_FAILURE,
		errors,
	};
}

export const ADD_SUPPLIERS_FOR_BUYERS_SUCCESS = 'ADD_SUPPLIERS_FOR_BUYERS_SUCCESS';
export const addSuppliersForBuyersSuccess = (records) => {
	return {
		records,
		type: ADD_SUPPLIERS_FOR_BUYERS_SUCCESS,
	};
};

export const FETCH_HYDRATED_DISTINCT_SUPPLIER_PRIVATE_NETWORKS_SUCCESS =
	'FETCH_HYDRATED_DISTINCT_SUPPLIER_PRIVATE_NETWORKS_SUCCESS';
export const fetchHydratedDistinctSupplierPrivateNetworksSuccess = (
	records,
	count,
	targetCollectionName,
	pagination,
	sorting,
	filters
) => {
	return {
		count,
		records,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		type: FETCH_HYDRATED_DISTINCT_SUPPLIER_PRIVATE_NETWORKS_SUCCESS,
	};
};

export const FETCH_SUPPLIER_PRIVATE_NETWORKS_FOR_LOCATION_SUCCESS =
	'FETCH_SUPPLIER_PRIVATE_NETWORKS_FOR_LOCATION_SUCCESS';
export const fetchSupplierPrivateNetworksForLocationSuccess = (
	records,
	count,
	targetCollectionName,
	pagination,
	sorting,
	filters
) => {
	return {
		count,
		records,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		type: FETCH_SUPPLIER_PRIVATE_NETWORKS_FOR_LOCATION_SUCCESS,
	};
};

export const FETCH_BUYER_COMPANY_PRIVATE_NETWORK_WITH_LOCATIONS_SUCCESS =
	'FETCH_BUYER_COMPANY_PRIVATE_NETWORK_WITH_LOCATIONS_SUCCESS';
export const fetchBuyerCompanyPrivateNetworkWithLocationsSuccess = (
	records,
	count,
	targetCollectionName,
	pagination,
	sorting,
	filters
) => {
	return {
		count,
		records,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		type: FETCH_BUYER_COMPANY_PRIVATE_NETWORK_WITH_LOCATIONS_SUCCESS,
	};
};

import { Button, Form, Input, Select, Table, message } from 'antd';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { locationsRestCrudThunksForBuyer } from '../../thunks/locations_thunks';
import BackButton from '../back_button/BackButton';
import { troubleshootingRestCrudThunksForBuyer } from '../../thunks/troubleshooting_thunks';
import {
	problemTypesHeirarchicalForBuyer,
	problemTypesRestCrudThunksForBuyer,
} from '../../thunks/problem_types_thunks';
import { workOrderPrioritiesRestCrudThunksForBuyer } from '../../thunks/work_order_priorities_thunks';
import TroubleshootingStepModal from './TroubleshootingStepModal';
import ProblemTypeTreeSelect from '../problem_type_tree_select';
import { getStepInputLabel } from '../../utils/TroubleshootingUtils';

const TROUBLESHOOTING_FORM_TC_NAME = 'troubleshootingFormIndex';
const FORM_NAME = 'troubleshooting-form';

export const TROUBLESHOOTING_STEP_COLUMNS = [
	{
		title: 'Step No.',
		dataIndex: 'orderingId',
	},
	{
		title: 'Title',
		dataIndex: 'title',
		width: 50,
		render: (_) => <div className="richTextWrapper" dangerouslySetInnerHTML={{ __html: _ }} />,
	},
	{
		title: 'Description',
		dataIndex: 'description',
		width: 50,
		render: (_) => <div className="richTextWrapper" dangerouslySetInnerHTML={{ __html: _ }} />,
	},
	{
		title: 'Allowed to Next Step',
		dataIndex: 'canProceedToNextStep',
		render: (_) => (_ ? 'Yes' : 'No'),
	},
	{
		title: 'Input Required',
		dataIndex: 'inputRequired',
		render: (_) => (_ ? 'Yes' : 'No'),
	},
	{
		title: 'Input Type',
		dataIndex: 'inputType',
		render: (_) => getStepInputLabel(_),
	},
];

const TroubleshootingForm: FC<any> = ({
	locations,
	fetchLocations,
	fetchMultipleLocations,
	problemTypes,
	fetchProblemTypes,
	workOrderPriorities,
	fetchWorkOrderPriorities,
	fetchMultipleWorkOrderPriorities,
	formData,
	isLoading,
	onCancel,
	create,
	update,
	onSuccess,
}): React.ReactElement => {
	const [form] = useForm();

	const [steps, setSteps] = useState([]);
	const [currentEditingStep, setCurrentEditingStep] = useState(null);
	const [stepPopupVisible, setStepPopupVisible] = useState(false);

	useEffect(() => {
		const allSteps = nullSafeGetOrElse('troubleshootingSteps', formData, []);
		setSteps(
			allSteps
				.sort((a, b) => parseInt(a.orderingId || 0) - parseInt(b.orderingId || 0))
				.map((_, idx) => ({ ..._, orderingId: idx + 1 }))
		);
	}, [formData]);

	const defaultFormFieldStyle = { maxWidth: 640 };

	const isUpdate = useMemo(() => !!nullSafeGet('id', formData), [formData]);

	const submitText = useMemo(() => (isUpdate ? 'Update' : 'Create'), [isUpdate]);

	const submitAction = useMemo(() => (isUpdate ? update : create), [create, isUpdate, update]);

	const onSubmit = useCallback(
		(values) => {
			submitAction({
				...formData,
				...values,
				troubleshootingSteps: steps,
			})
				.then((res) => onSuccess && onSuccess(res))
				.catch((err) => message.error(err));
		},
		[formData, onSuccess, steps, submitAction]
	);

	const onAddStep = useCallback(() => setStepPopupVisible(true), []);

	const onStepPopupCancel = useCallback(() => {
		setCurrentEditingStep(null);
		setStepPopupVisible(false);
	}, []);

	const onStepEditSuccess = useCallback(
		(step) => {
			setSteps((allSteps) => {
				if (!!step.orderingId) {
					return allSteps.map((_) => (_.orderingId === step.orderingId ? step : _));
				} else {
					return [
						...allSteps,
						...[
							{
								...step,
								orderingId: allSteps.length + 1,
							},
						],
					];
				}
			});
			onStepPopupCancel();
		},
		[onStepPopupCancel]
	);

	const onEditStep = useCallback(
		(step) => () => {
			setCurrentEditingStep(step);
			setStepPopupVisible(true);
		},
		[]
	);

	const columns = useMemo(
		() => [
			...TROUBLESHOOTING_STEP_COLUMNS,
			...[
				{
					render: (_, record) => (
						<Button type="ghost" onClick={onEditStep(record)}>
							Edit
						</Button>
					),
				},
			],
		],
		[onEditStep]
	);

	return (
		<Form
			form={form}
			id={FORM_NAME}
			layout="vertical"
			requiredMark={false}
			initialValues={formData}
			onFinish={onSubmit}
		>
			<Form.Item name="name" label="Name">
				<Input style={defaultFormFieldStyle} />
			</Form.Item>
			<Form.Item name="locationId" label="Location">
				<OWAsyncSelect
					key="locationId"
					style={defaultFormFieldStyle}
					stateSlice={locations}
					targetCollectionName={TROUBLESHOOTING_FORM_TC_NAME}
					labelAccessor={(el) => nullSafeGet('name', el)}
					allowClear
					fetchData={(
						search,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
					) =>
						fetchLocations(
							{ search: search || undefined },
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						)
					}
					fetchMultiple={(ids, targetCollectionName) => {
						fetchMultipleLocations(ids, targetCollectionName);
					}}
					renderRecord={(entity) => {
						return (
							<Select.Option key={entity.id} value={entity.id}>
								{entity.name}
							</Select.Option>
						);
					}}
					sortBy={{ sort_by: 'name', order: 'ascend' }}
				/>
			</Form.Item>
			<Form.Item name="problemTypeId" label="Problem Type">
				<ProblemTypeTreeSelect
					allowClear={true}
					style={defaultFormFieldStyle}
					stateSlice={problemTypes}
					targetCollectionName={TROUBLESHOOTING_FORM_TC_NAME}
					fetchData={(
						searchText,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
					) =>
						fetchProblemTypes(
							{ name: searchText },
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						)
					}
					fetchMultiple={() => {}}
					renderRecord={(pt) => (
						<Select.Option key={pt.id} value={pt.id}>
							{pt.name}
						</Select.Option>
					)}
				/>
			</Form.Item>
			<Form.Item name="workOrderPriorityId" label="Workorder Priority">
				<OWAsyncSelect
					key="workOrderPriorityId"
					style={defaultFormFieldStyle}
					stateSlice={workOrderPriorities}
					targetCollectionName={TROUBLESHOOTING_FORM_TC_NAME}
					labelAccessor={(el) => nullSafeGet('name', el)}
					allowClear
					fetchData={(
						search,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
					) =>
						fetchWorkOrderPriorities(
							{ search: search || undefined },
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						)
					}
					fetchMultiple={(ids, targetCollectionName) => {
						fetchMultipleWorkOrderPriorities(ids, targetCollectionName);
					}}
					renderRecord={(entity) => {
						return (
							<Select.Option key={entity.id} value={entity.id}>
								{entity.name}
							</Select.Option>
						);
					}}
					sortBy={{ sort_by: 'name', order: 'ascend' }}
				/>
			</Form.Item>
			<Form.Item
				name="troubleshootingSteps"
				label={
					<div className="flex flex-row items-baseline">
						<div>Steps</div>
						<div className="ml-2">
							<Button type="link" onClick={onAddStep}>
								Add Step
							</Button>
						</div>
					</div>
				}
			>
				<Table
					columns={columns}
					showHeader
					rowKey={(el, idx) => `step-${idx}`}
					dataSource={steps}
					pagination={false}
				/>
				{stepPopupVisible ? (
					<TroubleshootingStepModal
						step={currentEditingStep}
						onCancel={onStepPopupCancel}
						onSuccess={onStepEditSuccess}
					/>
				) : null}
			</Form.Item>
			<Form.Item>
				{onCancel ? (
					<Button type="ghost" onClick={onCancel}>
						Cancel
					</Button>
				) : (
					<BackButton buttonText="Cancel" />
				)}
				<span style={{ marginLeft: '16px' }}>
					<Button
						type="primary"
						htmlType="submit"
						key="submit"
						loading={isLoading}
						form={FORM_NAME}
					>
						{submitText}
					</Button>
				</span>
			</Form.Item>
		</Form>
	);
};

const mapStateToProps = (state) => ({
	locations: state.locations,
	problemTypes: state.problem_types,
	workOrderPriorities: state.work_order_priorities,
	isLoading: state.troubleshooting.creating || state.troubleshooting.updating,
});

const mapDispatchToProps = (dispatch) => ({
	create: (entity) => dispatch(troubleshootingRestCrudThunksForBuyer.create(entity)),
	update: (entity) => dispatch(troubleshootingRestCrudThunksForBuyer.update(entity)),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchLocations: (params, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForBuyer.read(params, targetCollectionName)),
	fetchMultipleProblemTypes: (ids, targetCollectionName) =>
		dispatch(problemTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchProblemTypes: (params, targetCollectionName) =>
		dispatch(problemTypesHeirarchicalForBuyer(params, targetCollectionName)),
	fetchMultipleWorkOrderPriorities: (ids, targetCollectionName) =>
		dispatch(workOrderPrioritiesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchWorkOrderPriorities: (params, targetCollectionName) =>
		dispatch(workOrderPrioritiesRestCrudThunksForBuyer.read(params, targetCollectionName)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(TroubleshootingForm)
);

import React, { FC, useCallback, useMemo, useState } from 'react';
import { Row, Col, Card, Button } from 'antd';
import { DATE_FORMAT, dateFormatter, getContactName } from '../../utils/DataFormatterUtils';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { EmptyState } from '../empty_state/EmptyState';
import {
	assetHistoryRestCrudThunksForSupplier,
	downloadAssetHistoryCSVForSupplier,
} from '../../thunks/asset_history_thunks';
import LocationStockLocationNameWithType from '../equipment_per_stock_location_row_display/LocationStockLocationNameWithType';
import { connect } from 'react-redux';
import { DownloadOutlined } from '@ant-design/icons';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import DateTimeHover from '../date_time_component/DateTime';

const PPSLOrAssetHistoryComponent: FC<any> = ({
	filter,
	exportTransferHistory,
	fetching,
}): React.ReactElement => {
	const [exporting, setExporting] = useState(false);

	const columns = useMemo(
		() => [
			{
				title: 'Location Name',
				dataIndex: 'locationId',
				render: (_, record) => (
					<LocationStockLocationNameWithType
						record={record}
						backLinkText={'Back to Transfer history'}
					/>
				),
			},
			{
				title: 'Usage',
				dataIndex: 'locationId',
				render: (_, record) => (record.locationId ? 'In Use' : 'At warehouse'),
			},
			{
				title: 'From date',
				dataIndex: 'markedTransferredToCurrentLocationAt',
				render: (val) => (val ? <DateTimeHover timestamp={val} showDate /> : '__'),
			},
			{
				title: 'To date',
				dataIndex: 'markedTransferredFromCurrentLocationAt',
				render: (val) => (val ? <DateTimeHover timestamp={val} showDate /> : '__'),
			},
			{
				title: 'Transferred By',
				dataIndex: 'transferredByContact',
				render: (_) => getContactName(_),
			},
			{
				title: 'Transfer Note',
				dataIndex: 'transferNote',
			},
		],
		[]
	);

	const downloadCSV = useCallback(() => {
		setExporting(true);
		exportTransferHistory(filter).finally(() => setExporting(false));
	}, [exportTransferHistory, filter]);

	return (
		<Row style={{ margin: '0.5em -8px' }} gutter={16}>
			<Col span={24}>
				<Card
					bodyStyle={{ padding: 8 }}
					extra={
						<Button
							size="large"
							icon={<DownloadOutlined translate="" />}
							className="inline-block-visible-md"
							loading={exporting}
							onClick={downloadCSV}
						>
							<span className="inline-block-visible-xl">Export CSV</span>
						</Button>
					}
				>
					<PaginatedReduxTable
						mode="list"
						emptyState={
							<EmptyState
								graphic={
									<img
										alt="No asset history found"
										style={{ marginBottom: 8 }}
										src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
									/>
								}
								headline={'No asset history found'}
								body={null}
							/>
						}
						collectionName="asset_history"
						targetCollectionName="assetHistoryIndex"
						columns={columns}
						showHeader
						keyAccessor={(el) => el.id}
						initialFilters={filter}
						fetchData={assetHistoryRestCrudThunksForSupplier.read}
					/>
				</Card>
			</Col>
		</Row>
	);
};

const mapStateToProps = (state) => ({
	fetching: state.asset_history.fetching,
});

const mapDispatchToProps = (dispatch) => ({
	exportTransferHistory: (params, filters) =>
		dispatch(downloadAssetHistoryCSVForSupplier(params, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PPSLOrAssetHistoryComponent);

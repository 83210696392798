import { Button, Form, Input, Modal, message } from 'antd';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { connect } from 'react-redux';
import {
	areasRestCrudThunksForBuyer,
	areasRestCrudThunksForSupplier,
} from '../../thunks/areas_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';

const FORM_NAME = 'area-form';

const AddOrEditAreaModal: FC<any> = ({
	area,
	location,
	onCancel,
	createArea,
	updateArea,
	targetCollectionName,
}) => {
	const [saving, setSaving] = useState(false);

	const isUpdate = useMemo(() => nullSafeGet('id', area), [area]);

	const submitMethod = useMemo(
		() => (isUpdate ? updateArea : createArea),
		[createArea, isUpdate, updateArea]
	);

	const onSubmit = useCallback(
		(values) => {
			const entity = area
				? { ...area, ...values }
				: {
						...values,
						images: [],
						locationId: nullSafeGet('id', location),
				  };
			setSaving(entity);
			submitMethod(entity, 'id', targetCollectionName)
				.then(() => {
					message.success(`Area ${isUpdate ? 'updated' : 'added'} successfully!`);
					onCancel();
				})
				.catch((err) => message.error(err))
				.finally(() => setSaving(false));
		},
		[area, isUpdate, location, onCancel, submitMethod, targetCollectionName]
	);

	return (
		<Modal
			visible={true}
			width={600}
			title={`${isUpdate ? 'Edit' : 'New'} Area`}
			onCancel={onCancel}
			closable={false}
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button
					type="primary"
					size="large"
					style={{ marginLeft: '16px' }}
					key="submit"
					htmlType="submit"
					form={FORM_NAME}
					loading={saving}
				>
					{isUpdate ? 'Update' : 'Create'}
				</Button>,
			]}
		>
			<Form
				id={FORM_NAME}
				layout="vertical"
				requiredMark={false}
				initialValues={{ ...(area || {}) }}
				onFinish={onSubmit}
			>
				<Form.Item
					name="name"
					label="Name"
					rules={[
						{
							required: true,
							message: 'Please give your area a name.',
						},
					]}
				>
					<Input style={{ width: '100%' }} />
				</Form.Item>
			</Form>
		</Modal>
	);
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	createArea: (entity, primaryKey, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? areasRestCrudThunksForSupplier.create(entity, primaryKey, targetCollectionName)
				: areasRestCrudThunksForBuyer.create(entity, primaryKey, targetCollectionName)
		),
	updateArea: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? areasRestCrudThunksForSupplier.update(entity)
				: areasRestCrudThunksForBuyer.update(entity)
		),
});

const ComponentWithoutUserType = connect(null, mapDispatchToProps)(AddOrEditAreaModal);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

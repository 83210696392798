import React, { FC, useCallback, useMemo, useState } from 'react';
import {
	bulkTransferEquipmentsPerStockLocactionForSupplier,
	equipmentPerStockLocationCsvReportGeneratorSupplier,
	equipmentPerStockLocationsRestCrudThunksForSupplier,
	fetchAllEquipmentPerStockLocactionForSupplier,
} from '../../thunks/equipment_per_stock_locations_thunks';
import EquipmentPerStockLocationRowDisplay from '../equipment_per_stock_location_row_display/EquipmentPerStockLocationRowDisplay';
import { EquipmentRentalRowDisplay } from '../equipment_rental_row_display/EquipmentRentalRowDisplay';
import { Button, Dropdown, Menu, Popover, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EmptyState } from '../empty_state/EmptyState';
import {
	DownloadOutlined,
	EditOutlined,
	PlusOutlined,
	PrinterOutlined,
	UploadOutlined,
} from '@ant-design/icons';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import BulkTransferFromStockLocationModal from './BulkTransferFromStockLocationModal';
import {
	getRecordsForCompositeTargetCollection,
	getRecordsForTargetCollection,
} from '../../reducers/standard_reducer_utils';
import StockLocationAddEquipmentForm from '../stock_location_add_equipment_form/StockLocationAddEquipmentForm';
import { EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS } from '../../actions/equipment_per_stock_locations_actions';
import {
	compareStrings,
	fetchWithDifferntSearchName,
	getPurchaseOrderId,
	getPurchaseRequestId,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import { DATE_FORMAT } from '../../utils/DataFormatterUtils';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { assetTypesRestCrudThunksForSupplier } from '../../thunks/asset_types_thunks';
import { equipmentTypesRestCrudThunksForSupplier } from '../../thunks/equipment_types_thunks';
import HyperLink from '../common/HyperLink';
import FeildTechSLManagePermissionChecker, {
	canFieldManageStockLocation,
} from '../common/FeildTechSLManagePermissionChecker';
import { RECENTLY_UPDATED_SORTER, FILTER_CONFIG_NAMES } from '../../utils/DataConstants';
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv';
import { CSV_EXPORT_DEFAULTS } from '../../utils/DataConstants';
import {
	getReceivedEquipmentFlatfileHeader,
	getFlatfileRecordForReceivedEquipment,
	getFlatfileRecordForReceivedEquipmentExport,
	getEquipmentTypeFlatfileOptions,
	EQUIPMENT_EXPORT_HEADER,
} from './stock_location_constants';
import UploadWithSampleModal from '../parts_index_page/UploadWithSampleModal';
import EditReceivedEquipmentBulk from './EditReceivedEquipmentBulk';
import DateTimeHover from '../date_time_component/DateTime';
import TransferToLocationReceivedEquipmentBulk from './TransferToLocationReceivedEquipmentBulk';
import { renderEquipmenTypeSelect } from '../purchase_order_form/ReplaceEquipmentLineItemModal';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';

const EPSL_TC = 'stockLocationAssociatedEquipments';
const EPSL_ADVANCED_FILTERS_TC = 'epslAdvancedFilters';

const StockLocationEquipmentsPage: FC<any> = ({
	match,
	history,
	location,
	fetchEquipmentsPerStockLocation,
	bulkTransfer,
	equipmentsPerStockLocation,
	updateEquipmentsPerStocklocationFilters,
	clearAndUpdateFilters,
	stockLocation,
	assetTypes,
	equipmentTypes,
	fetchAssetTypes,
	fetchMultipleAssetTypes,
	fetchEquipmentTypes,
	fetchMultipleEquipments,
	currentUser,
	fetchAllEquipmentPerStockLocation,
	createBulk,
	fetchAllEquipmentTypes,
	exportCsv,
}): React.ReactElement => {
	const [transferLoading, setTransferLoading] = useState(false);

	const stockLocationId = useMemo(() => match.params.id, [match.params.id]);

	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [transferModalVisible, setTransferModalVisible] = useState(null);
	const [transferBulkToLocationVisible, setTransferBulkToLocationVisible] = useState(null);
	const [addEquipmentFormVisible, setAddEquipmentFormVisible] = useState(false);

	const [fetchingEpsl, setFetchingEpsl] = useState(false);
	const [epsls, setEpsls] = useState([]);

	const [downloading, setDownloading] = useState(false);
	const [exportingCsv, setExportingCsv] = useState(false);
	const [esplsForUpload, setEpslsForUpload] = useState([]);
	const [uploadPopupVisible, setUploadPopupVisible] = useState(false);
	const [allEquipmentTypes, setAllEquipmentTypes] = useState([]);

	const backText = useMemo(
		() => `Back to ${nullSafeGetOrElse('name', stockLocation, 'Stock location')}`,
		[stockLocation]
	);

	const getLink = useCallback(
		(link) => getLinkWIthBackLinkParams(location, backText, link),
		[backText, location]
	);

	const editAllowed = useMemo(
		() => getRecordsForTargetCollection(equipmentsPerStockLocation, EPSL_TC).length > 0,
		[equipmentsPerStockLocation]
	);

	const allEquipmentTypesNames = useMemo(
		() => getEquipmentTypeFlatfileOptions(allEquipmentTypes),
		[allEquipmentTypes]
	);

	const equipmentsColumns = useMemo(
		() => [
			{
				title: 'Name',
				dataIndex: 'name',
				render: (_, record) => (
					<EquipmentPerStockLocationRowDisplay equipmentPerStockLocation={record} />
				),
			},
			{
				title: 'Received/Transferred From',
				dataIndex: '',
				render: (_, record) => {
					if (
						nullSafeGetOrElse('addedType', record, '').toLowerCase() === 'transferred' &&
						record.fromStockLocationId
					) {
						// const path = `/supplier/stockLocations/detail/${record.fromStockLocationId}`;
						return (
							<div>
								{nullSafeGetOrElse(
									'fromStockLocation.name',
									record,
									`SL # ${record.fromStockLocationId}`
								)}
							</div>
						);
					} else if (
						nullSafeGetOrElse('addedType', record, '').toLowerCase() === 'transferred' &&
						record.fromLocationId
					) {
						return (
							<div>
								{nullSafeGetOrElse(
									'fromLocation.name',
									record,
									`Location # ${record.fromStockLocationId}`
								)}
							</div>
						);
					} else if (compareStrings(record.addedType, 'received') && record.purchaseOrder) {
						const path = `/supplier/purchaseOrders/detail/${record.purchaseOrderId}/details`;
						return (
							<HyperLink
								newTabPath={path}
								text={`PO ${getPurchaseOrderId(record.purchaseOrder)}`}
							/>
						);
					} else if (compareStrings(record.addedType, 'received') && record.purchaseRequest) {
						const path = `/supplier/purchaseRequests/detail/${record.purchaseRequestId}/details`;
						return (
							<HyperLink
								newTabPath={path}
								text={`PR ${getPurchaseRequestId(record.purchaseRequest)}`}
							/>
						);
					} else {
						return '--';
					}
				},
			},
			{
				title: 'Received At',
				dataIndex: 'receivedAt',
				render: (date) => (date ? <DateTimeHover timestamp={date} showDate={true} /> : '--'),
			},
			{
				title: 'Rental',
				dataIndex: 'isRental',
				render: (text) => <EquipmentRentalRowDisplay isRental={text} />,
			},
		],
		[]
	);

	const canTransfer = useMemo(() => selectedRowKeys.length > 0, [selectedRowKeys.length]);

	const onEquipmentRow = useCallback(
		(record) => ({
			onClick: () =>
				canTransfer
					? false
					: history.push(
							getLink(`/supplier/equipments/equipmentPerStockLocation/detail/${record.id}`)
					  ),
		}),
		[canTransfer, getLink, history]
	);

	const onSelectChange = useCallback((newSelectedRowKeys: React.Key[]) => {
		setSelectedRowKeys(newSelectedRowKeys);
	}, []);

	const rowSelection = useMemo(
		() => ({
			selectedRowKeys,
			onChange: onSelectChange,
		}),
		[onSelectChange, selectedRowKeys]
	);

	const onTransferClick = useCallback((type) => setTransferModalVisible(type), []);

	const hideTransferModal = useCallback(() => setTransferModalVisible(null), []);

	const onSelectCancel = useCallback(() => setSelectedRowKeys([]), []);

	const getStatSliceProp = useCallback(
		(propName) => nullSafeGetOrElse(`${EPSL_TC}.${propName}`, equipmentsPerStockLocation, {}),
		[equipmentsPerStockLocation]
	);

	const refreshEquipmentsPerStockLocation = useCallback(() => {
		fetchEquipmentsPerStockLocation(
			{},
			EPSL_TC,
			getStatSliceProp('pagination'),
			getStatSliceProp('sorting'),
			getStatSliceProp('filters')
		);
	}, [fetchEquipmentsPerStockLocation, getStatSliceProp]);

	const onTransferEquipmentsSuccess = useCallback(() => {
		hideTransferModal();
		setTransferBulkToLocationVisible(false);
		onSelectCancel();
		refreshEquipmentsPerStockLocation();
	}, [hideTransferModal, onSelectCancel, refreshEquipmentsPerStockLocation]);

	const selectedRecords = useMemo(() => {
		const records = getRecordsForCompositeTargetCollection(equipmentsPerStockLocation, EPSL_TC);
		return records.filter((_) => selectedRowKeys.includes(_.id));
	}, [equipmentsPerStockLocation, selectedRowKeys]);

	const onTransfer = useCallback(
		(values) => {
			const updatedRecords = selectedRecords.map((record) => ({
				...record,
				id: `${record.id}`,
				stockLocationId: values.toStockLocationId,
				...(values.transferNote && { transferNote: values.transferNote }),
			}));

			bulkTransfer(updatedRecords).then(() => onTransferEquipmentsSuccess());
		},
		[bulkTransfer, selectedRecords, onTransferEquipmentsSuccess]
	);

	const onAddEquipment = useCallback(() => setAddEquipmentFormVisible(true), []);

	const hideAddEquipment = useCallback(() => setAddEquipmentFormVisible(false), []);

	const onAddEquipmentSuccess = useCallback(() => {
		hideAddEquipment();
		refreshEquipmentsPerStockLocation();
	}, [hideAddEquipment, refreshEquipmentsPerStockLocation]);

	const filterConfig = useMemo(
		() => [
			{
				label: 'Equipment Type',
				mode: 'multiple',
				fieldName: 'equipmentTypeIds',
				stateSlice: equipmentTypes,
				targetCollectionName: EPSL_ADVANCED_FILTERS_TC,
				fetch: fetchWithDifferntSearchName(fetchEquipmentTypes, 'search'),
				fetchMultiple: fetchMultipleEquipments,
				renderRecord: renderEquipmenTypeSelect,
				component: OWAsyncSelect,
			},
			{
				label: 'Asset Type',
				fieldName: FILTER_CONFIG_NAMES.ASSET_TYPE,
				mode: 'multiple',
				stateSlice: assetTypes,
				targetCollectionName: EPSL_ADVANCED_FILTERS_TC,
				fetch: fetchAssetTypes,
				fetchMultiple: fetchMultipleAssetTypes,
			},
			{
				fieldName: 'isRental',
				type: 'radioGroup',
				items: [
					{
						label: 'All',
						value: 'undefined',
					},
					{
						label: 'Rented',
						value: 'true',
					},
					{
						label: 'Owned',
						value: 'false',
					},
				],
				showOutsideDropdown: true,
				defaultValue: undefined,
			},
		],
		[
			equipmentTypes,
			fetchEquipmentTypes,
			fetchMultipleEquipments,
			assetTypes,
			fetchAssetTypes,
			fetchMultipleAssetTypes,
		]
	);

	const preAppliedFilters = useMemo(
		() => ({ stockLocationId, isDisbursed: false }),
		[stockLocationId]
	);

	const fetchCurrentSLEntities = useCallback(
		() =>
			fetchAllEquipmentPerStockLocation({
				...preAppliedFilters,
				...{ view: 'exportView' },
			}),
		[fetchAllEquipmentPerStockLocation, preAppliedFilters]
	);

	const onBulkEpslEdit = useCallback(() => {
		setFetchingEpsl(true);
		const promises = [fetchCurrentSLEntities(), fetchAllEquipmentTypes()];
		Promise.all(promises)
			.then((value) => {
				setEpsls(nullSafeGetOrElse('0', value, []));
				setAllEquipmentTypes(nullSafeGetOrElse('1', value, []));
			})
			.catch((err) => message.error(err))
			.finally(() => setFetchingEpsl(false));
	}, [fetchAllEquipmentTypes, fetchCurrentSLEntities]);

	const showUploadPopup = useCallback(() => {
		setDownloading(true);
		const promises = [fetchCurrentSLEntities(), fetchAllEquipmentTypes()];
		Promise.all(promises)
			.then((value) => {
				setEpslsForUpload(nullSafeGetOrElse('0', value, []));
				setAllEquipmentTypes(nullSafeGetOrElse('1', value, []));
				setUploadPopupVisible(true);
			})
			.catch((err) => message.error(err))
			.finally(() => setDownloading(false));
	}, [fetchAllEquipmentTypes, fetchCurrentSLEntities]);

	const hideUploadPopup = useCallback(() => setUploadPopupVisible(false), []);

	const exportAsCsv = useCallback(
		(eqs, getRecord = getFlatfileRecordForReceivedEquipmentExport) => {
			const date = moment().format(DATE_FORMAT);
			const csvTitle = `${stockLocation.name}_${date}_received_equipment`;
			const csvExporter = new ExportToCsv({
				...CSV_EXPORT_DEFAULTS,
				filename: csvTitle,
				title: csvTitle,
				headers: EQUIPMENT_EXPORT_HEADER,
			});

			const data = eqs && eqs.length > 0 ? eqs.map((_) => getRecord(_)) : [getRecord()];
			csvExporter.generateCsv(data);
		},
		[stockLocation.name]
	);

	const onDownloadSample = useCallback(() => {
		exportAsCsv(esplsForUpload);
	}, [esplsForUpload, exportAsCsv]);

	const downloadCSV = useCallback(() => {
		setExportingCsv(true);
		exportCsv({
			...getStatSliceProp('sorting'),
			...getStatSliceProp('filters'),
		}).finally(() => setExportingCsv(false));
	}, [exportCsv, getStatSliceProp]);

	const onDataUpload = useCallback(
		(results) => {
			return new Promise((resolve, reject) => {
				createBulk(
					results.validData.map((_) => {
						const { equipmentId, ...entity } = getFlatfileRecordForReceivedEquipmentExport(_);
						return {
							...entity,
							equipmentId: equipmentId || undefined,
							cost: _.cost ? parseFloat(_.cost) : undefined,
							stockLocationId: parseInt(stockLocationId),
							equipmentTypeId: parseInt(_.equipmentTypeId),
							isRental: _.isRental,
							supplierFacilityId: parseInt(nullSafeGet('supplierFacilityId', stockLocation)),
							supplierCompanyId: parseInt(nullSafeGet('supplierCompanyId', stockLocation)),
							images: [],
						};
					})
				)
					.then((res) => {
						resolve('Updated successfully!');
						setTimeout(() => {
							hideUploadPopup();
							message.success('Updated successfully!');
							refreshEquipmentsPerStockLocation();
						}, 0);
					})
					.catch((err) => {
						console.log(err);
						reject(err);
					});
			});
		},
		[createBulk, hideUploadPopup, refreshEquipmentsPerStockLocation, stockLocation, stockLocationId]
	);

	const onEditDataSuccess = useCallback(
		(results) => {
			return new Promise((resolve, reject) => {
				createBulk(
					results.validData.map((_) => ({
						...getFlatfileRecordForReceivedEquipment(_),
						id: _.id ? parseInt(_.id) : undefined,
						cost: _.cost ? parseFloat(_.cost) : undefined,
						stockLocationId: parseInt(stockLocationId),
						equipmentTypeId: parseInt(_.equipmentTypeId),
						isRental: _.isRental,
						supplierFacilityId: parseInt(nullSafeGet('supplierFacilityId', stockLocation)),
						supplierCompanyId: parseInt(nullSafeGet('supplierCompanyId', stockLocation)),
						images: [],
					}))
				)
					.then((res) => {
						resolve('Updated successfully!');
						setTimeout(() => {
							hideUploadPopup();
							message.success('Updated successfully!');
							refreshEquipmentsPerStockLocation();
						}, 0);
					})
					.catch((err) => {
						console.log(err);
						reject('Unable to upload! Please contact support!');
					});
			});
		},
		[createBulk, hideUploadPopup, refreshEquipmentsPerStockLocation, stockLocation, stockLocationId]
	);

	const onBulkEpslEditSuccess = useCallback(() => {
		setEpsls([]);
		refreshEquipmentsPerStockLocation();
	}, [refreshEquipmentsPerStockLocation]);

	const transferMenu = useMemo(
		() => (
			<Menu>
				<Menu.Item onClick={() => onTransferClick('stockLocation')}>To Stock Location</Menu.Item>
				<Menu.Item
					onClick={() => {
						if (selectedRecords.length < 2) {
							onTransferClick('location');
						} else {
							setTransferBulkToLocationVisible(true);
							setTransferLoading(true);
						}
					}}
				>
					To Location
				</Menu.Item>
			</Menu>
		),
		[onTransferClick, selectedRecords]
	);

	return (
		<>
			<PaginatedReduxTableWithHeader
				targetCollectionName={EPSL_TC}
				updateFilters={updateEquipmentsPerStocklocationFilters}
				stateSlice={equipmentsPerStockLocation}
				clearAndUpdateFilters={clearAndUpdateFilters}
				filterConfig={filterConfig}
				entityCollectionName="equipment_per_stock_locations"
				tableColumns={equipmentsColumns}
				onTableRow={onEquipmentRow}
				rowSelection={canFieldManageStockLocation(currentUser, stockLocation) ? rowSelection : null}
				showHeader
				preAppliedFilters={preAppliedFilters}
				fetchData={equipmentPerStockLocationsRestCrudThunksForSupplier.read}
				hasDefaultHeaderPage
				initialSorters={RECENTLY_UPDATED_SORTER}
				rightActions={
					canTransfer ? (
						<div>
							<Button onClick={onSelectCancel} size="large">
								Cancel
							</Button>
							<Dropdown overlay={transferMenu}>
								<Button className="ml-2" size="large" type="primary" loading={transferLoading}>
									Transfer
								</Button>
							</Dropdown>
						</div>
					) : (
						<div className="flex-start flex flex-row">
							<Popover content="View Printable Labels" trigger="hover">
								<Button
									size="large"
									icon={<PrinterOutlined translate="" />}
									onClick={() =>
										history.push(
											getLink(`/supplier/stockLocations/printEquipmentLabels/${stockLocationId}`)
										)
									}
								/>
							</Popover>
							<FeildTechSLManagePermissionChecker>
								<Popover content="Download or Upload Received Equipment" trigger="hover">
									<Button
										size="large"
										className="inline-block-visible-md ml-2"
										icon={<UploadOutlined translate="" />}
										loading={downloading}
										onClick={showUploadPopup}
									>
										<span className="inline-block-visible-xxl">Upload</span>
									</Button>
								</Popover>
								<Popover content="Export CSV" trigger="hover">
									<Button
										size="large"
										icon={<DownloadOutlined translate="" />}
										className="inline-block-visible-md ml-2"
										onClick={downloadCSV}
										loading={exportingCsv}
										disabled={!editAllowed}
									>
										<span className="inline-block-visible-xxl">Export CSV</span>
									</Button>
								</Popover>
							</FeildTechSLManagePermissionChecker>
							<FeildTechSLManagePermissionChecker>
								<Popover
									content={
										editAllowed ? 'Edit Received Equipment' : 'No Equipment available to edit'
									}
									trigger="hover"
								>
									<Button
										className="ml-2"
										size="large"
										loading={fetchingEpsl}
										icon={<EditOutlined translate="" />}
										onClick={onBulkEpslEdit}
										disabled={!editAllowed}
									>
										Edit
									</Button>
								</Popover>
							</FeildTechSLManagePermissionChecker>
							<FeildTechSLManagePermissionChecker>
								<Button
									className="ml-2"
									size="large"
									icon={<PlusOutlined translate="" />}
									type="primary"
									onClick={onAddEquipment}
								>
									Add
								</Button>
							</FeildTechSLManagePermissionChecker>
						</div>
					)
				}
				emptyState={
					<EmptyState
						graphic={
							<img
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
								alt="The tool shed is looking pretty empty."
							/>
						}
						headline={'The tool shed is looking pretty empty.'}
						body={
							<div style={{ textAlign: 'center' }}>
								<div style={{ maxWidth: 440, marginBottom: 16 }}>
									You haven't added any equipment to this location yet.
								</div>
							</div>
						}
					/>
				}
			/>
			{transferModalVisible && (
				<BulkTransferFromStockLocationModal
					onCancel={hideTransferModal}
					stockLocationId={stockLocationId}
					selectedEpsls={selectedRecords}
					onTransferToSL={onTransfer}
					onSuccess={onTransferEquipmentsSuccess}
					locationType={transferModalVisible}
				/>
			)}
			{transferBulkToLocationVisible && (
				<TransferToLocationReceivedEquipmentBulk
					selectedRecords={selectedRecords}
					onSuccess={onTransferEquipmentsSuccess}
					onClose={() => setTransferBulkToLocationVisible(false)}
					transferInitiated={() => setTransferLoading(false)}
				/>
			)}
			{addEquipmentFormVisible && (
				<StockLocationAddEquipmentForm
					stockLocationId={stockLocationId}
					onCancel={hideAddEquipment}
					onSuccess={onAddEquipmentSuccess}
				/>
			)}
			{!fetchingEpsl && (
				<EditReceivedEquipmentBulk
					epsls={epsls}
					onSuccess={onBulkEpslEditSuccess}
					onDataUpload={onEditDataSuccess}
					allEquipmentTypesNames={allEquipmentTypesNames}
				/>
			)}
			{uploadPopupVisible && (
				<UploadWithSampleModal
					type="Received Equipment"
					title="Upload Received Equipment"
					downloadText="Do you want to download the existing equipment?"
					sampleFileText="Download Received Equipment"
					onCancel={hideUploadPopup}
					onDownloadSample={onDownloadSample}
					fields={getReceivedEquipmentFlatfileHeader(allEquipmentTypesNames)}
					onDataLoad={onDataUpload}
					showWarningMessage
				/>
			)}
		</>
	);
};

const mapStateToProps = (state, ownProps) => ({
	match: ownProps.match,
	history: ownProps.history,
	location: ownProps.location,
	stockLocation: state.stock_locations.detail,
	currentUser: state.session.currentUser,
	equipmentsPerStockLocation: state.equipment_per_stock_locations,
	assetTypes: state.asset_types,
	equipmentTypes: state.equipment_types,
});

const mapDispatchToProps = (dispatch) => ({
	fetchEquipmentsPerStockLocation: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			equipmentPerStockLocationsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	bulkTransfer: (entities) =>
		dispatch(bulkTransferEquipmentsPerStockLocactionForSupplier(entities)),
	updateEquipmentsPerStocklocationFilters: (filters, targetCollection) =>
		dispatch(
			EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)
		),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(
				filters,
				targetCollectionName
			)
		),
	fetchMultipleAssetTypes: (ids, targetCollectionName) =>
		dispatch(assetTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchAssetTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			assetTypesRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleEquipments: (ids, targetCollectionName) =>
		dispatch(equipmentTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchEquipmentTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			equipmentTypesRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	createBulk: (entities) =>
		dispatch(equipmentPerStockLocationsRestCrudThunksForSupplier.createBulk(entities)),
	fetchAllEquipmentPerStockLocation: (params) =>
		dispatch(fetchAllEquipmentPerStockLocactionForSupplier(params, RECENTLY_UPDATED_SORTER)),
	fetchAllEquipmentTypes: () =>
		dispatch(equipmentTypesRestCrudThunksForSupplier.readLite({ no_pagination: true })),
	exportCsv: (params) => dispatch(equipmentPerStockLocationCsvReportGeneratorSupplier(params)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(StockLocationEquipmentsPage)
);

import { Result } from 'antd';
import { ResultProps } from 'antd/lib/result';
import React from 'react';
import BackButton from '../back_button/BackButton';
import PermissionDeniedPage from '../permission_denied_page/PermissionDeniedPage';

interface OWResultProps extends ResultProps {
	error?: any;
}

export default function OWRESULT({ error, ...resultProps }: OWResultProps) {
	if (error && error === 'Do not have permission to perform this action') {
		return (
			<Result
				status="403"
				title="403"
				subTitle="Sorry, you are not authorized to access this page."
				extra={<BackButton type="primary" buttonText="Go Back"></BackButton>}
				{...resultProps}
			/>
		);
	}
	if (error) {
		return (
			<Result
				status="500"
				title="Oops!"
				subTitle="Sorry, something went wrong. Please try again later."
				extra={<BackButton type="primary" buttonText="Go Back"></BackButton>}
				{...resultProps}
			/>
		);
	}
}

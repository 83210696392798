import { BUYERS_CRUD_ACTION_CREATORS } from '../actions/buyers_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';

export const buyersRestCrudThunksForBuyer = new RestCrudThunks(
	'buyers',
	'buyer',
	'buyer',
	'buyers',
	BUYERS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER
);
export const buyersRestCrudThunksForSupplier = new RestCrudThunks(
	'buyers',
	'buyer',
	'buyer',
	'buyers',
	BUYERS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER
);

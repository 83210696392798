import * as React from 'react';
import SupplierSignupForm from '../supplier_signup_form/SupplierSignupForm';
import { Layout, Row, Col } from 'antd';
import * as queryString from 'querystring';
import { withRouter } from 'react-router';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
const { Content } = Layout;
const style = require('./SupplierSignupPage.less');

export default withRouter<any, any>(
	class SupplierSignupPage extends React.Component<any, any> {
		render() {
			const { location } = this.props;
			let searchString = location.search;
			if (searchString[0] === '?') {
				searchString = searchString.slice(1);
			}
			const queryParams = queryString.parse(searchString);
			const email = queryParams.email;
			const nameGiven = queryParams.nameGiven;
			const nameFamily = queryParams.nameFamily;

			return (
				<Content
					className="signUpPage"
					style={{
						height: '100vh',
						display: 'flex',
						backgroundColor: 'rgb(16, 35, 40)',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<LogOnMountWithStandardEventProperties eventType="visited supplier signup page" />
					{/*<BackTop/>*/}
					<Row
						style={{
							maxWidth: 800,
							boxShadow: 'rgba(0,0,0,0.5) 10px 10px 50px',
							width: '100%',
							margin: 'auto',
						}}
					>
						<Col sm={24} md={10}>
							<div style={{ background: 'rgb(38, 111, 131)', padding: 40, height: '100%' }}>
								<div
									style={{
										width: '100%',
										height: 80,
										backgroundSize: 'contain',
										backgroundRepeat: 'no-repeat',
										backgroundPosition: 'center',
										backgroundImage: `url('https://s3.amazonaws.com/emptor-data-assets/images/openwrench-logos/openwrenchlogo-white-fieldservice.png')`,
									}}
								/>
							</div>
						</Col>
						<Col sm={24} md={14}>
							<div style={{ padding: '56px 40px', background: 'white' }}>
								<h4 style={{ marginBottom: 24 }}>Sign up</h4>
								<SupplierSignupForm email={email} nameGiven={nameGiven} nameFamily={nameFamily} />
							</div>
						</Col>
					</Row>
				</Content>
			);
		}
	}
);

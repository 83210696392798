import React, { FC, useMemo } from 'react';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { ROLE_TYPES } from '../../utils/DataConstants';

const RemoveSubscriberButton: FC<any> = ({
	currentUser,
	subscriber,
	onRemove,
	workOrder,
}): React.ReactElement => {
	const userType = useMemo(() => currentUser.userType, [currentUser.userType]);

	const isBuyerAllowedToRemove = useMemo(
		() => nullSafeGet('facilityId', subscriber) !== nullSafeGet('supplierFacilityId', workOrder),
		[subscriber, workOrder]
	);

	const isSupplierAllowedToRemove = useMemo(
		() =>
			subscriber.contactType === ROLE_TYPES.SUPPLIER &&
			nullSafeGet('facilityId', subscriber) === nullSafeGet('facility.id', currentUser),
		[currentUser, subscriber]
	);

	const isAllowedToRemove = useMemo(
		() => (userType === ROLE_TYPES.BUYER ? isBuyerAllowedToRemove : isSupplierAllowedToRemove),
		[isBuyerAllowedToRemove, isSupplierAllowedToRemove, userType]
	);

	return isAllowedToRemove ? <Button onClick={onRemove(subscriber)}>Remove</Button> : null;
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

export default connect(mapStateToProps)(RemoveSubscriberButton);

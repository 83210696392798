import React, { FC } from 'react';
import HyperLink, { ENTITY_TYPE } from '../common/HyperLink';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { dateFormatter } from '../../utils/DataFormatterUtils';

const warrantyDateFormat = 'MMMM D, YYYY';

const AssetWithPartWarrantyDisplay: FC<any> = ({ asset }): React.ReactElement => {
	return (
		<div className="flex flex-col">
			<div className="flex flex-row items-center">
				<div>{`Serial # `}</div>
				<div className="ml-2">
					<HyperLink
						text={asset.serialNumber || asset.assetNumber}
						entityType={ENTITY_TYPE.ASSET_DETAIL}
						entityId={asset.id}
					/>
				</div>
			</div>
			{nullSafeGet('partWarranty', asset) ? (
				<div className="flex flex-col">
					<div>Part Warranty:</div>
					{nullSafeGet('partWarranty.issueDate', asset) ? (
						<div>{`Start Date : ${dateFormatter(
							nullSafeGet('partWarranty.issueDate', asset),
							warrantyDateFormat
						)}`}</div>
					) : null}
					{nullSafeGet('partWarranty.expirationDate', asset) ? (
						<div>{`End Date : ${dateFormatter(
							nullSafeGet('partWarranty.expirationDate', asset),
							warrantyDateFormat
						)}`}</div>
					) : null}
				</div>
			) : null}
		</div>
	);
};

export default AssetWithPartWarrantyDisplay;

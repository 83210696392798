import React, { FC } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Card } from 'antd';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import ProjectTemplateForm from '../project_template_form/ProjectTemplateForm';

const { Content } = Layout;

const NewProjectTemplatePage: FC<any> = ({ history, userType }): React.ReactElement => {
	return (
		<Content>
			<LogOnMountWithStandardEventProperties eventType="visited new project template page" />
			<Row style={{ margin: '0.5em -8px' }} gutter={16}>
				<Col span={24}>
					<Card>
						<div>
							<h5 style={{ marginBottom: 24 }}>New Project Template</h5>
							<ProjectTemplateForm
								formData={{}}
								onSuccess={(rec) =>
									history.push(`/${userType}/projects/templates/detail/${rec.id}`)
								}
							/>
						</div>
					</Card>
				</Col>
			</Row>
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	assetTemplate: state.asset_types.detail,
});

const mapDispatchToProps = (dispatch) => ({});

const NewAssetTemplatePageWR = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(NewProjectTemplatePage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(NewAssetTemplatePageWR);

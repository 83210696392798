import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router';
import { Button, Layout } from 'antd';
import ScrollToTopOnMount from '../scroll_to_top_on_mount/ScrollToTopOnMount';
import SubnavTabs from '../subnav_tabs/SubnavTabs';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { Link } from 'react-router-dom';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import posthog from 'posthog-js';
const { Content } = Layout;
const style = require('./BuyerNotificationsOverviewPage.less');

class BuyerNotificationsOverviewPage extends React.Component<any, any> {
	handleTabChange = (key) => {
		const { history } = this.props;
		history.push(`/buyer/inbox/overview/${key}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	render() {
		const { companyConfig, location } = this.props;
		const unreadCount = nullSafeGet('userNotifications.counts.TASKS_NOTIFICATIONS', this.props);
		const tabs = [
			{
				key: 'important',
				name: unreadCount ? (
					<>
						Important <span className="text-base ">({unreadCount})</span>
					</>
				) : (
					'Important'
				),
			},
			{
				key: 'approvals',
				name: 'Approvals',
			},
			{
				key: 'notes',
				name: 'Notes',
			},
			{
				key: 'status',
				name: 'Status Updates',
			},
		];

		return (
			<div>
				<ScrollToTopOnMount />
				<div>
					<Route
						path="/buyer/inbox/overview"
						exact={true}
						render={(props) => (
							<Redirect
								to={{
									pathname: '/buyer/inbox/overview/important',
								}}
							/>
						)}
					/>
					<Content className="buyerNotificationsPage" style={{ padding: '0 0.5em' }}>
						{/*<ScrollToTopOnMount/>*/}
						{/*<BackTop/>*/}

						<div
							style={{
								margin: '0 -8px',
								padding: '24px',
								paddingBottom: 0,
								background: nullSafeGet('detail.config.theme.navblock', companyConfig),
								marginBottom: 8,
							}}
						>
							<div className="flex justify-between">
								<h4
									style={{
										color: 'rgba(255, 255, 255, 0.9)',
										marginRight: 8,
										marginBottom: 24,
									}}
								>
									Inbox
								</h4>
								<div className="flex gap-x-4 ">
									<Link
										to={getLinkWIthBackLinkParams(
											location,
											'Back to Inbox',
											`/buyer/settings/notifications`
										)}
										style={{ marginRight: 16 }}
									>
										<Button ghost={true}>Settings</Button>
									</Link>
								</div>
							</div>
							<SubnavTabs
								tabs={tabs}
								onChange={this.handleTabChange}
								isActive={this.checkTabActive}
							/>
						</div>
						{this.props.childRoutes.map((r, i) => (
							<RouteWithSubRoutes key={i} {...r} />
						))}
					</Content>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	companyConfig: state.company_config,
	userNotifications: state.user_notifications,
});
const mapDispatchToProps = (dispatch) => ({});
export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(BuyerNotificationsOverviewPage)
);

import { Button, Card } from 'antd';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { EmptyState } from '../empty_state/EmptyState';
import HyperLink, { ENTITY_TYPE } from '../common/HyperLink';
import {
	getEntityById,
	nullSafeGet,
	nullSafeGetOrElse,
	stopDefaultEvents,
} from '../../utils/DataAccessUtils';
import ShipmentStatusDisplay from '../stock_locations_detail_details_page/ShipmentStatusDisplay';
import { requestedPartsRestCrudThunksForSupplier } from '../../thunks/requested_parts_thunks';
import RequestedPartsQuantityModal from './RequestedPartsQuantityModal';
import { STOPLIGHT_COLORS } from '../../utils/DataFormatterUtils';
import LocationStockLocationNameWithType from '../equipment_per_stock_location_row_display/LocationStockLocationNameWithType';
import DateTimeHover from '../date_time_component/DateTime';

const REQUESTED_PARTS_TC = 'requestedPartsIndex';

const renderDate = (date) => (date ? <DateTimeHover timestamp={date} showDate={true} /> : '--');

const RequestedPartsSectionInWorkOrder: FC<any> = ({
	onRefresh,
	defaultServiceCall,
	workOrder,
}): React.ReactElement => {
	const [requestedPartQuantityVisible, setRequestedPartQuantityVisible] = useState(false);
	const [currentRequestedPart, setCurrentRequestedPart] = useState(null);
	const [releaseText, setReleaseText] = useState(null);

	const allowReservedQuantity = false;

	const showRequestedQuantityModal = useCallback(
		(record, releaseText = '') =>
			(e) => {
				stopDefaultEvents(e);
				setRequestedPartQuantityVisible(true);
				setCurrentRequestedPart(record);
				setReleaseText(releaseText);
			},
		[]
	);

	const hideRequestedQuantityModal = useCallback(() => {
		setRequestedPartQuantityVisible(false);
		setCurrentRequestedPart(null);
		setReleaseText('');
	}, []);

	const requestedPartQuantitySuccess = useCallback(() => {
		hideRequestedQuantityModal();
		onRefresh && onRefresh();
	}, [hideRequestedQuantityModal, onRefresh]);

	const requestedPartsColumns = useMemo(
		() => [
			{
				title: 'Part Number',
				dataIndex: ['part', 'partNumber'],
			},
			{
				title: 'Part Name',
				dataIndex: ['part', 'name'],
				render: (_, record) =>
					record.partsPerStockLocation ? (
						<div className="flex flex-col">
							<HyperLink
								entityType={ENTITY_TYPE.PPSL}
								entityId={nullSafeGet('partsPerStockLocation.id', record)}
								text={nullSafeGet('partsPerStockLocation.part.name', record)}
								backLinkText="Back to Work Order"
							/>
							{nullSafeGet('trackingNumber', record) ? (
								<div className="mt-2">{`Tracking No: ${nullSafeGet(
									'trackingNumber',
									record
								)}`}</div>
							) : null}
						</div>
					) : (
						<HyperLink
							entityType={ENTITY_TYPE.PART}
							entityId={nullSafeGet('id', record)}
							text={nullSafeGet('part.name', record)}
							backLinkText="Back to Work Order"
						/>
					),
			},
			{
				title: 'Location',
				dataIndex: ['partsPerStockLocation', 'stockLocation'],
				render: (sl, record) => (
					<LocationStockLocationNameWithType
						record={sl ? record.partsPerStockLocation : record}
						backLinkText="Back to Work Order"
					/>
				),
			},
			...(allowReservedQuantity
				? [
						{
							title: 'Reserved Qty',
							dataIndex: ['reservedQuantity'],
							render: (_) => _ || 0,
						},
				  ]
				: []),
			{
				title: 'Requested Qty',
				dataIndex: ['actualRequestedQuantity'],
				render: (qty, record) =>
					record.returnedQuantity ? (
						<div className="flex flex-row items-center">
							<div className="text-gray-400 line-through">{qty}</div>
							<div className="ml-2">{qty - record.returnedQuantity}</div>
						</div>
					) : (
						qty
					),
			},
			{
				title: 'Requested At',
				dataIndex: 'createdAt',
				render: renderDate,
			},
			{
				title: 'Shipping Status',
				dataIndex: 'shipped',
				render: (_, record) =>
					record.requestedQuantity > 0 ? (
						record.requestedPartDirectlyViaPurchaseRequestId ? (
							<span
								style={{
									background: '#e6f9e7',
									color: STOPLIGHT_COLORS.pureGreen,
									padding: '4px 12px',
									borderRadius: 16,
									whiteSpace: 'nowrap',
								}}
							>
								Available
							</span>
						) : (
							<div className="flex flex-col">
								<div>
									<ShipmentStatusDisplay shipped={_} />
								</div>
								{_ ? (
									<div className="mt-2">
										<DateTimeHover timestamp={nullSafeGet('markShippedAt', record)} />
									</div>
								) : null}
							</div>
						)
					) : (
						'--'
					),
			},
			{
				dataIndex: 'shipped',
				render: (shipped, record) => {
					const description = (
						<div className="flex flex-col text-gray-400">
							<div style={{ color: STOPLIGHT_COLORS.pureGreen }}>
								{record.consumedQuantity ? `Consumed : ${record.consumedQuantity}` : null}
							</div>
							<div style={{ color: STOPLIGHT_COLORS.pureRed }}>
								{record.returnedQuantity ? `Returned : ${record.returnedQuantity}` : null}
							</div>
						</div>
					);
					const showDescirption = !!record.consumedQuantity || !!record.returnedQuantity;
					return (
						<div className="flex flex-col items-start justify-start">
							{nullSafeGetOrElse('requestedQuantity', record, 0) > 0 ? (
								shipped || record.requestedPartDirectlyViaPurchaseRequestId ? (
									<div className="flex flex-row items-center">
										{record.partsPerStockLocationId ? (
											<Button onClick={showRequestedQuantityModal(record, 'Return')}>Return</Button>
										) : null}
										<Button
											onClick={showRequestedQuantityModal(record)}
											type="primary"
											className="ml-2"
										>
											Consume
										</Button>
									</div>
								) : (
									<Button type="primary" onClick={showRequestedQuantityModal(record, 'Remove')}>
										Remove
									</Button>
								)
							) : null}
							{showDescirption ? description : null}
						</div>
					);
				},
			},
		],
		[allowReservedQuantity, showRequestedQuantityModal]
	);

	return (
		<Card title={`Requested Parts`}>
			<PaginatedReduxTable
				mode="list"
				emptyState={
					<EmptyState
						graphic={
							<img
								alt="No requested parts found"
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
							/>
						}
						headline="No requested parts found"
						body={null}
					/>
				}
				collectionName="requested_parts"
				targetCollectionName={REQUESTED_PARTS_TC}
				columns={requestedPartsColumns}
				showHeader
				keyAccessor={(el) => el.id}
				initialFilters={{
					workOrderId: workOrder.id,
				}}
				fetchData={requestedPartsRestCrudThunksForSupplier.read}
			/>
			{requestedPartQuantityVisible && (
				<RequestedPartsQuantityModal
					releaseText={releaseText}
					requestedPart={currentRequestedPart}
					serviceCall={defaultServiceCall}
					onCancel={hideRequestedQuantityModal}
					successCallback={requestedPartQuantitySuccess}
				/>
			)}
		</Card>
	);
};

const mapStateToProps = (state, ownProps) => ({
	workOrder: getEntityById(ownProps.match.params.id, state.work_orders),
});

export default withRouter<any, any>(connect(mapStateToProps)(RequestedPartsSectionInWorkOrder));

import React, { FC } from 'react';
import { CloseOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { Input, Button, Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 4 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 20 },
	},
};

const formItemLayoutWithOutLabel = {
	wrapperCol: {
		xs: { span: 24, offset: 0 },
		sm: { span: 20, offset: 4 },
	},
};

interface IAddTask {
	taskKeys: any[];
	taskKey: any;
	taskIndex: number;
	form: WrappedFormUtils<any>;
	formData: any;
	isSingleAddition?: boolean;
	removeTask: (k: any) => void;
	updateChoiceKeys: (k: any, val: any, opt: DefaultOptionType | DefaultOptionType[]) => void;
	addChoice: (k: any) => void;
	removeChoice: (k: any, c: any) => void;
}

const AddTask: FC<IAddTask> = ({
	taskKeys,
	taskKey,
	taskIndex,
	form,
	formData,
	isSingleAddition,
	removeTask,
	updateChoiceKeys,
	addChoice,
	removeChoice,
}): React.ReactElement => {
	const taskType = nullSafeGet(`tasks.${taskKey}.taskType`, formData);
	const taskChoices = nullSafeGet(`tasks.${taskKey}.multipleChoicesWithLabel`, formData);
	const multipleChoiceDefaultKeys =
		taskChoices && taskChoices.length > 0 ? taskChoices.map((el, idx) => idx) : [0, 1];
	const defaultChoiceKeys = taskType === 'multipleChoice' ? multipleChoiceDefaultKeys : [];
	form.getFieldDecorator(`tasks-${taskKey}-choiceKeys`, {
		initialValue: nullSafeGetOrElse(`tasks-${taskKey}-choiceKeys`, formData, defaultChoiceKeys),
	});

	return (
		<div key={taskKey} className="task-container">
			{!isSingleAddition && (
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div style={{ fontSize: '16px', color: 'black', marginBottom: '24px' }}>
						Task {taskIndex + 1}
					</div>

					<div className="remove-task-button">
						{taskKeys.length > 1 ? (
							<CloseOutlined
								translate=""
								className="dynamic-delete-button"
								onClick={() => removeTask(taskKey)}
							/>
						) : null}
					</div>
				</div>
			)}
			<Form.Item {...formItemLayout} label="Title" required={false}>
				{form.getFieldDecorator(`tasks-${taskKey}-title`, {
					validateTrigger: ['onChange', 'onBlur'],
					initialValue:
						nullSafeGet(`tasks-${taskKey}-title`, formData) ||
						nullSafeGet(`tasks.${taskKey}.title`, formData),
					rules: [
						{
							required: true,
							whitespace: true,
							message: 'Please give your task a title.',
						},
					],
				})(<Input style={{ width: '60%' }} />)}
			</Form.Item>
			<Form.Item {...formItemLayout} label="Group title" required={false}>
				{form.getFieldDecorator(`tasks-${taskKey}-groupTitle`, {
					validateTrigger: ['onChange', 'onBlur'],
					initialValue:
						nullSafeGet(`tasks-${taskKey}-groupTitle`, formData) ||
						nullSafeGet(`tasks.${taskKey}.groupTitle`, formData),
					rules: [
						{
							required: false,
							whitespace: true,
						},
					],
				})(<Input style={{ width: '60%' }} />)}
			</Form.Item>
			<Form.Item {...formItemLayout} label="Type" required={false}>
				{form.getFieldDecorator(`tasks-${taskKey}-type`, {
					validateTrigger: ['onChange'],
					initialValue:
						nullSafeGet(`tasks-${taskKey}-type`, formData) ||
						nullSafeGet(`tasks.${taskKey}.taskType`, formData),
					rules: [
						{
							required: true,
							message: 'Please choose a task type.',
						},
					],
				})(
					// TODO: Add onChange function to clear out choices and choiceKeys when changing from
					// TODO: multiple choice to another type or filter choices from other task types
					// TODO: on form validation and submission.
					<Select
						onChange={(val, opt) => updateChoiceKeys(taskKey, val, opt)}
						style={{ width: 200 }}
					>
						<Select.Option value="multipleChoice">Multiple Choice</Select.Option>
						<Select.Option value="textbox">Free Text</Select.Option>
					</Select>
				)}
			</Form.Item>
			{form.getFieldDecorator(`tasks-${taskKey}-orderingId`, {
				initialValue: nullSafeGetOrElse(`tasks-${taskKey}-orderingId`, formData, taskKey),
			})}
			{form.getFieldValue(`tasks-${taskKey}-type`) === 'multipleChoice' ? (
				<div>
					{form.getFieldValue(`tasks-${taskKey}-choiceKeys`).map((c, choiceIndex) => (
						<div key={`choices-${c}`}>
							{/* <div>{`Choice ${choiceIndex + 1}`}</div> */}
							{/* <div style={{ display: 'flex', flexDirection: 'row'}}> */}
							<Form.Item label={`Choice ${choiceIndex + 1}`} {...formItemLayout} required={true}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'flex-start',
									}}
								>
									{form.getFieldDecorator(`tasks-${taskKey}-choices-${c}-value`, {
										validateTrigger: ['onChange', 'onBlur'],
										initialValue:
											nullSafeGet(`tasks-${taskKey}-choices-${c}-value`, formData) ||
											nullSafeGet(`tasks.${taskKey}.multipleChoicesWithLabel.${c}`, formData),
										rules: [
											{
												required: true,
												whitespace: true,
												message: 'Please enter a choice value.',
											},
										],
									})(<Input placeholder="* Enter Value" style={{ width: '30%' }} />)}

									{form.getFieldDecorator(`tasks-${taskKey}-choices-${c}-description`, {
										validateTrigger: ['onChange', 'onBlur'],
										initialValue:
											nullSafeGet(`tasks-${taskKey}-choices-${c}-description`, formData) ||
											nullSafeGet(
												`tasks.${taskKey}.multipleChoicesWithLabel.${c}.description`,
												formData
											),
										rules: [
											{
												required: false,
												whitespace: true,
											},
										],
									})(
										<Input
											placeholder="Enter Description"
											style={{ width: '30%', marginLeft: '24px' }}
										/>
									)}
									{form.getFieldValue(`tasks-${taskKey}-choiceKeys`).length > 1 ? (
										<MinusCircleOutlined
											translate=""
											className="dynamic-delete-button"
											onClick={() => removeChoice(taskKey, c)}
										/>
									) : null}
								</div>
							</Form.Item>
							{/* </div> */}
						</div>
					))}
					<Form.Item {...formItemLayoutWithOutLabel}>
						<Button type="dashed" onClick={() => addChoice(taskKey)} style={{ width: '60%' }}>
							<PlusOutlined translate="" /> Add choice
						</Button>
					</Form.Item>
				</div>
			) : null}
		</div>
	);
};

export default AddTask;

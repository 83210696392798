import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Alert, Layout, Button, Row, Col, InputNumber, Collapse } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { nullSafeGetOrElse, nullSafeGet } from '../../utils/DataAccessUtils';
import BackButton from '../back_button/BackButton';
import { workOrdersRestCrudThunksForSupplier } from '../../thunks/work_orders_thunks';
import { getQuoteNumber, quotesRestCrudThunksForSupplier } from '../../thunks/quotes_thunks';

const Panel = Collapse.Panel;

const style = require('./SupplierSimpleQuoteForm.less');
const queryString = require('qs');

interface SupplierQuoteProps {
	formData: any;
	onSuccess: any;
	creating: boolean;
	updating: boolean;
	createErrors: string[];
	updateErrors: string[];
	redirectForwardUrl: string;
	redirectBackwardUrl: string;
	currentUser: any;
	history: any;
	createQuote: any;
	updateQuote: any;
	workOrder: any;
	getWorkOrder: any;
}

interface SupplierQuoteFormProps extends FormComponentProps {
	formData: any;
	onSuccess: any;
	creating: boolean;
	updating: boolean;
	createErrors: string[];
	updateErrors: string[];
	redirectForwardUrl: string;
	redirectBackwardUrl: string;
	currentUser: any;
	history: any;
	createQuote: any;
	updateQuote: any;
	workOrder: any;
	getWorkOrder: any;
	checkQuoteNumber: any;
}

class SupplierSimpleQuoteForm extends React.Component<SupplierQuoteFormProps, any> {
	componentDidMount() {
		const { form, getWorkOrder, formData } = this.props;
		if (formData.workOrderId) {
			getWorkOrder(formData.workOrderId);
		}
	}

	handleSubmit = (e) => {
		const {
			form,
			formData,
			redirectForwardUrl,
			currentUser,
			history,
			onSuccess,
			createQuote,
			updateQuote,
		} = this.props;
		e.preventDefault();

		form.validateFields((err, values) => {
			if (!err) {
				const submitAction = values.id ? updateQuote : createQuote;

				let quote = {
					id: values.id,
					externalWorkOrderId: values.externalWorkOrderId,
					quoteNumber: values.quoteNumber,
					scope: values.scope,
					status: 'draft',
					laborLineItems: [],
					materialLineItems: [],
					quoteTax: `0`,
					quoteTotalBeforeTax: `${values.miscTotalBeforeTax}`,
					quoteTotalAfterTax: `${values.miscTotalBeforeTax}`,
					laborTotalBeforeTax: '0',
					materialTotalBeforeTax: '0',
					travelTotalBeforeTax: '0',
					freightTotalBeforeTax: '0',
					miscTotalBeforeTax: `${values.miscTotalBeforeTax}`,
					workOrderId: parseInt(formData.workOrderId, 10),
					supplierFacilityId: values.supplierFacilityId || nullSafeGet('facility.id', currentUser),
					createdBy: currentUser.email,
					attachments: values.attachments,
				};

				submitAction(quote).then((record) => {
					if (redirectForwardUrl) {
						history.push(redirectForwardUrl);
					}
					if (onSuccess) {
						onSuccess(record);
					}
				});
			}
		});
	};
	handleQuoteNumberChange = async (rule, value, callback) => {
		const { checkQuoteNumber, formData } = this.props;
		if (value) {
			let data = await checkQuoteNumber(value);
			if (data && Array.isArray(data)) {
				data.map((quote) => {
					callback(
						<span>
							Error: Duplicate with{' '}
							<a
								href={'/supplier/quotes/detail/'.concat(nullSafeGet('id', quote), `/details`)}
								target="_blank"
							>
								Quote {nullSafeGet('id', quote)}
							</a>
						</span>
					);
				});
			}
		}
	};

	render() {
		const {
			formData,
			creating,
			updating,
			updateErrors,
			createErrors,
			form,
			workOrder,
			currentUser,
		} = this.props;
		const submitting = creating || updating;
		const errorMessagesText = createErrors.concat(updateErrors).join('; ');
		const isUpdate = formData.hasOwnProperty('id') && formData.id !== undefined;
		let submitText = isUpdate ? 'Update quote' : 'Create quote';
		const { getFieldDecorator, getFieldValue } = form;

		const DATE_FORMAT = 'MM-DD-YYYY';

		getFieldDecorator('id', { initialValue: formData.id });
		getFieldDecorator('attachments', { initialValue: formData.attachments || [] });

		return (
			<Layout.Content>
				<Row className="supplierQuoteForm" gutter={16}>
					<Col span={24}>
						<Form layout="vertical" hideRequiredMark={true}>
							{createErrors.length > 0 || updateErrors.length > 0 ? (
								<Form.Item label="">
									<Alert message={errorMessagesText} type="error" />
								</Form.Item>
							) : null}
							<Form.Item style={{ marginBottom: 32 }}>
								<div className="supplierQuoteForm__sectionHeadPrimary">Total</div>
								{getFieldDecorator(`miscTotalBeforeTax`, {
									validateTrigger: ['onBlur'],
									initialValue: nullSafeGetOrElse(`miscTotalBeforeTax`, formData, 0),
									rules: [
										{
											required: true,
											message: 'Please enter quote total',
										},
									],
								})(<InputNumber step={1} style={{ width: 240 }} min={0.01} />)}
							</Form.Item>
							<Form.Item className="supplierQuoteForm__sectionHeadPrimary" label="Quote #">
								{getFieldDecorator('quoteNumber', {
									validateTrigger: ['onBlur'],
									initialValue: nullSafeGet(`quoteNumber`, formData),
									rules: [
										{
											whitespace: true,
											required: true,
											message: 'This field is required.',
										},
										{
											validator: this.handleQuoteNumberChange,
										},
									],
								})(<Input style={{ maxWidth: 320, marginTop: '-8px' }} />)}
							</Form.Item>
							<Form.Item style={{ marginBottom: 32 }}>
								<div className="supplierQuoteForm__sectionHeadPrimary">Work Order #</div>
								{getFieldDecorator('externalWorkOrderId', {
									initialValue: nullSafeGetOrElse(
										`externalWorkOrderId`,
										formData,
										`${workOrder.id}`
									),
								})(<Input style={{ maxWidth: 320 }} />)}
							</Form.Item>
							<Form.Item style={{ marginBottom: 32 }}>
								<div className="supplierQuoteForm__sectionHeadPrimary">
									What are you billing for?
								</div>
								{getFieldDecorator('scope', {
									initialValue: nullSafeGet(`scope`, formData),
								})(
									<Input.TextArea
										placeholder="We replaced the gasket and repaired the fixture. Everything's good as new."
										style={{ maxWidth: 640 }}
									/>
								)}
							</Form.Item>
							{/*<div style={{marginBottom: 32}}>*/}
							{/*    <FileUploader*/}
							{/*        roleType="supplier"*/}
							{/*        defaultFileList={[]}*/}
							{/*        handleUploadSuccess={() => {*/}
							{/*        }}*/}
							{/*        uploaderType={'dragger'}*/}
							{/*    />*/}
							{/*    /!*this.handleFileAttachmentUploadChange(quote)*!/*/}
							{/*</div>*/}
							<Form.Item label="">
								<Button loading={submitting} type="primary" onClick={(e) => this.handleSubmit(e)}>
									{submitText}
								</Button>
								<span style={{ marginLeft: '16px' }}>
									<BackButton buttonText="Cancel" />
								</span>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Layout.Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	formData: ownProps.formData,
	onSuccess: ownProps.onSuccess,
	updating: state.proposals.updating,
	creating: state.proposals.creating,
	createErrors: state.proposals.createErrors,
	updateErrors: state.proposals.updateErrors,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	redirectBackwardUrl: ownProps.redirectBackwardUrl,
	currentUser: state.session.currentUser,
	history: ownProps.history,
	location: ownProps.location,
	workOrder: state.work_orders.detail,
});

const mapDispatchToProps = (dispatch) => ({
	checkQuoteNumber: (quote) => dispatch(getQuoteNumber(quote)),
	createQuote: (quote) => dispatch(quotesRestCrudThunksForSupplier.create(quote)),
	updateQuote: (quote) => dispatch(quotesRestCrudThunksForSupplier.update(quote)),
	getWorkOrder: (id) => dispatch(workOrdersRestCrudThunksForSupplier.readOne(id)),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<SupplierQuoteFormProps>()(SupplierSimpleQuoteForm))
);

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import StockLocationForm from '../stock_location_form/StockLocationForm';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const style = require('./EditStockLocationPage.less');

class EditStockLocationPage extends React.Component<any, any> {
	componentDidMount() {
		const { getStockLocation, match } = this.props;
		const id = match.params.id;
		getStockLocation(id);
	}

	render() {
		const { history, stockLocation, fetching, match } = this.props;

		//noinspection TypeScriptUnresolvedVariable
		return [
			<LogOnMountWithStandardEventProperties eventType="visited supplier edit stock location page" />,
			<EditPage
				title="Edit Stock Location"
				formComponentProps={{
					formData: stockLocation,
					onSuccess: (rec) => history.push(`/supplier/stockLocations/detail/${rec.id}`),
				}}
				fetching={fetching}
				FormComponent={StockLocationForm}
			/>,
		];
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		history: ownProps.history,
		stockLocation: state.stock_locations.detail,
		fetching: state.stock_locations.fetching,
		match: ownProps.match,
	};
};

const mapDispatchToProps = (dispatch) => ({
	getStockLocation: (id) => dispatch(stockLocationsRestCrudThunksForSupplier.readOne(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditStockLocationPage));

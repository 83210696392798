import { Button, Row } from 'antd';
import React, { FC, useCallback } from 'react';

const ModalFormFooter: FC<any> = ({
	okText,
	cancelText,
	loading,
	formId,
	onCancelClick,
}): React.ReactElement => {
	const onCancel = useCallback(() => onCancelClick && onCancelClick(), [onCancelClick]);

	return (
		<Row
			justify="end"
			style={{
				borderTop: 'solid 1px rgba(0, 0, 0, 0.1)',
				paddingTop: '8px',
				margin: '0 0 -8px',
			}}
		>
			<Button onClick={onCancel} size="large">
				{cancelText || 'Cancel'}
			</Button>
			<Button
				type="primary"
				size="large"
				style={{ marginLeft: '16px' }}
				loading={loading}
				key="submit"
				htmlType="submit"
				form={formId}
			>
				{okText || 'Save'}
			</Button>
		</Row>
	);
};

export default ModalFormFooter;

import React, { FC, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button } from 'antd';
import {
	locationTypesRestCrudThunksForBuyer,
	locationTypesRestCrudThunksForSupplier,
} from '../../thunks/location_types_thunks';
import { EmptyState } from '../empty_state/EmptyState';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { LOCATION_TYPES_CRUD_ACTION_CREATORS } from '../../actions/location_types_actions';
import { ROLE_TYPES } from '../../utils/DataConstants';

require('./LocationTypesIndexPage.less');

const LOCATION_TYPE_OVERVIEW_TARGET_COLLECTION = 'locationTypesIndex';

const LocationTypesIndexPage: FC<any> = ({
	userType,
	history,
	locationTypes,
	clearAndUpdateFilters,
	updateFilters,
}): React.ReactElement => {
	const onRow = (record) => ({
		onClick: () => history.push(`/${userType}/locations/templates/detail/${record.id}`),
	});

	const columns = useMemo(
		() => [
			{
				title: 'Name',
				dataIndex: 'name',
				defaultSortOrder: 'ascend',
			},
		],
		[]
	);

	const onNewTemplatePage = useCallback(() => {
		history.push(`/${userType}/locations/overview/templates/new`);
	}, [history, userType]);

	return (
		<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_LOCATION_TYPES} isPage>
			<PaginatedReduxTableWithHeader
				targetCollectionName={LOCATION_TYPE_OVERVIEW_TARGET_COLLECTION}
				stateSlice={locationTypes}
				clearAndUpdateFilters={clearAndUpdateFilters}
				updateFilters={updateFilters}
				filterConfig={[]}
				entityCollectionName="location_types"
				tableColumns={columns}
				onTableRow={onRow}
				fetchData={
					userType === ROLE_TYPES.SUPPLIER
						? locationTypesRestCrudThunksForSupplier.read
						: locationTypesRestCrudThunksForBuyer.read
				}
				rightActions={
					<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_LOCATIONS}>
						<Button type="primary" size="large" onClick={onNewTemplatePage}>
							Add Location Template
						</Button>
					</AccessPermissionChecker>
				}
				emptyState={
					<EmptyState
						graphic={
							<img
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
								alt="Looks like your team has not added any locations yet."
							/>
						}
						headline={"It's a little lonely in here."}
						body={
							<div style={{ textAlign: 'center' }}>
								<div style={{ maxWidth: 440, marginBottom: 16 }}>
									Looks like your team has not added any locations yet.
								</div>
								<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_LOCATIONS}>
									<Button type="primary" onClick={onNewTemplatePage}>
										Add your first location template
									</Button>
								</AccessPermissionChecker>
							</div>
						}
					/>
				}
			/>
		</AccessPermissionChecker>
	);
};

const mapStateToProps = (state) => ({
	locationTypes: state.location_types,
});

const mapDispatchToProps = (dispatch) => ({
	updateFilters: (filters, targetCollection) =>
		dispatch(LOCATION_TYPES_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			LOCATION_TYPES_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(LocationTypesIndexPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

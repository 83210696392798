import React, { FC } from 'react';

const AssetIdDisplay: FC<any> = ({ asset }): React.ReactElement => {
	return (
		<div className="flex text-gray-500">
			<div className="mr-4">{`Asset ID ${asset.buyerSpecificAssetId || asset.id}`}</div>
		</div>
	);
};

export default AssetIdDisplay;

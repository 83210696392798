import React, { useMemo } from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { FlexibleImageAvatar } from '../flexible-image-avatar/FlexibleImageAvatar';
import { getProtectedImageUriForSupplier } from '../../utils/FileAccessUtils';
import { Popconfirm, Row } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { dateFormatter, DATE_FORMAT } from '../../utils/DataFormatterUtils';
import { getEquipmentId, nullSafeGet } from '../../utils/DataAccessUtils';

require('./EquipmentInServiceCallRowDisplay.less');

const EquipmentInServiceCallRowDisplay: React.FC<any> = ({
	equipmentWithServiceCall,
	onConfirmRemove,
}) => {
	const equipmentPerStockLocation = useMemo(
		() => equipmentWithServiceCall.equipmentType,
		[equipmentWithServiceCall]
	);

	const serviceCall = useMemo(
		() => equipmentWithServiceCall.serviceCall,
		[equipmentWithServiceCall]
	);

	const previewImage =
		equipmentPerStockLocation.images && equipmentPerStockLocation.images.length > 0
			? equipmentPerStockLocation.images.map((locImg) => {
					const [imgFileId, imgFileName] = locImg.split('/');
					return getProtectedImageUriForSupplier(imgFileId, imgFileName, 1200, 800);
			  })[0]
			: null;
	return (
		<div
			className="equipmentPerStockLocationRowDisplay"
			style={{ justifyContent: 'space-between', marginBottom: '16px' }}
		>
			<div className="equipmentPerStockLocationRowDisplay">
				<div style={{ marginRight: 24 }}>
					{previewImage ? (
						<FlexibleImageAvatar
							displayName={equipmentPerStockLocation.name}
							showPopover={false}
							popoverText={equipmentPerStockLocation.name}
							squareMode={true}
							width="120px"
							height="80px"
							imageUrl={previewImage}
						/>
					) : (
						<FlexibleImageAvatar
							displayName={equipmentPerStockLocation.name}
							showPopover={false}
							popoverText={equipmentPerStockLocation.name}
							squareMode={true}
							width="120px"
							height="80px"
							imageUrl="https://s3.amazonaws.com/emptor-data-assets/images/empty-state/icons8-maintenance-filled-100.png"
						/>
					)}
				</div>
				<div>
					<div className="equipmentPerStockLocationRowDisplay__rowTitle">
						<Ellipsis tooltip={true} length={56}>
							{equipmentPerStockLocation.name}
						</Ellipsis>
					</div>
					<div className="equipmentPerStockLocationRowDisplay__rowSubtitle">
						{nullSafeGet('equipmentUniqueId', equipmentPerStockLocation) ? (
							<div className="equipmentPerStockLocationRowDisplay__rowInlineGroup">
								{`Equipment ID${getEquipmentId(equipmentPerStockLocation)}`}
							</div>
						) : null}
						{nullSafeGet('equipmentType.modelName', equipmentPerStockLocation) ? (
							<div className="equipmentPerStockLocationRowDisplay__rowInlineGroup">
								{nullSafeGet('equipmentType.modelName', equipmentPerStockLocation)}
							</div>
						) : null}
						{nullSafeGet('serialNumber', equipmentPerStockLocation) ? (
							<div className="equipmentPerStockLocationRowDisplay__rowInlineGroup">
								Serial# {nullSafeGet('serialNumber', equipmentPerStockLocation)}
							</div>
						) : null}
						<div className="equipmentPerStockLocationRowDisplay__rowInlineGroup">
							<Ellipsis tooltip={true} length={50}>
								{equipmentPerStockLocation.description}
							</Ellipsis>
						</div>
					</div>
					<div className="equipmentPerStockLocationRowDisplay__rowSubtitle">
						{`Service Call ${equipmentWithServiceCall.serviceCallNumber} 
						(${dateFormatter(serviceCall.createdAt, DATE_FORMAT)})`}
					</div>
				</div>
			</div>
			<Row align="middle" gutter={16}>
				<Popconfirm
					onConfirm={onConfirmRemove}
					title="Are you sure you want to remove this equipment?"
					okText="Yes"
					cancelText="No"
				>
					<DeleteOutlined style={{ marginLeft: 8 }} translate="" />
				</Popconfirm>
			</Row>
		</div>
	);
};

export default EquipmentInServiceCallRowDisplay;

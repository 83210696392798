import React from 'react';
import { Layout, Button, Popover } from 'antd';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';
import SubnavTabs from '../subnav_tabs/SubnavTabs';
import { assetsRestCrudThunksForBuyer } from '../../thunks/assets_thunks';
import {
	getProtectedImageUriForBuyer,
	getProtectedImageUriFromString,
} from '../../utils/FileAccessUtils';
import { Link } from 'react-router-dom';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { ROLE_TYPES } from '../../utils/DataConstants';
import Carousel, { Modal as Lightbox, ModalGateway } from 'react-images';
import { clearNavbarState, updateNavbarState } from '../../actions/navbar_actions';
import {
	generateBackLink,
	getBackLinkStates,
	getLinkWIthBackLinkParams,
} from '../../utils/HistoryUtils';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import TransferAssetToLocationForm from '../supplier_assets_detail_page/TransferAssetToLocationForm';

require('./AssetsDetailPage.less');
const queryString = require('qs');

const { Content } = Layout;
const DEFAULT_BACK_LINK = '/buyer/assets';

class AssetsDetailPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			lightboxIsOpen: false,
			currentIndex: 0,
			manualsIncluded: false,
			backLinkTo: DEFAULT_BACK_LINK,
			backLinkText: 'Back to assets',
			transferModalVisible: false,
		};
	}

	handleViewChange = (idx) => {
		this.setState({ currentIndex: idx });
	};

	openLightbox = (currentIndex = 0) => {
		this.setState({ lightboxIsOpen: true, currentIndex });
	};

	closeLightbox = () => {
		this.setState({ lightboxIsOpen: false });
	};

	componentDidMount() {
		const { getAsset, match, updateNavbar, location } = this.props;
		const assetId = match.params.id;
		getAsset(assetId).then((asset) => {
			let searchString = location.search;
			if (searchString[0] === '?') {
				searchString = searchString.slice(1);
			}
			const queryParams = queryString.parse(searchString);
			if (queryParams.backlinkParams) {
				const { pathName, backLinkText } = queryString.parse(queryParams.backlinkParams);
				let backPath = pathName || '/buyer/assets/overview/all';
				let backText = backLinkText || 'Back to Assets';
				const backLinkTo = generateBackLink(backPath, location);
				this.setState({ backLinkTo, backLinkText: backText });
				updateNavbar(backText, generateBackLink(backPath, location));
			} else {
				updateNavbar('Back to Assets', generateBackLink('/buyer/assets/overview/all', location));
			}
			if (nullSafeGet('assetModel.manuals', asset)) {
				this.setState({ manualsIncluded: true });
			} else {
				this.setState({ manualsIncluded: false });
			}
		});
	}
	componentWillUnmount(): void {
		this.props.clearNavbar();
	}

	handleTabChange = (key) => {
		const { history, match, location } = this.props;
		const assetId = match.params.id;
		const path = `/buyer/assets/detail/${assetId}/${key}`;
		history.push(`${path}?${getBackLinkStates(location, path).backLinkParams || ''}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	handleDelete = () => {
		const { history, deleteAsset, asset } = this.props;
		deleteAsset(asset).then(() => {
			history.push('/buyer/assets/overview/all');
		});
	};

	showTransferModal = () => this.setState({ transferModalVisible: true });
	hideTransferModal = () => this.setState({ transferModalVisible: false });

	onTransferSuccess = () => {
		const { getAsset, match } = this.props;
		const assetId = match.params.id;
		this.hideTransferModal();
		getAsset(assetId);
	};

	handleCopy = () => {
		const { match, history, userType, location } = this.props;
		const assetId = match.params.id;
		history.push(
			getLinkWIthBackLinkParams(
				location,
				'Back to asset',
				`/${userType}/assets/overview/new?copyFromAssetId=${assetId}`
			)
		);
	};

	render() {
		const { asset, assetsFetching, match } = this.props;
		const assetId = match.params.id;
		let tabs = [
			{
				key: 'details',
				name: 'Overview',
			},
			{
				key: 'downtimeHistory',
				name: 'Downtime History',
			},
			{
				key: 'audit',
				name: 'Audit Logs',
			},
		];
		if (this.state.manualsIncluded) {
			tabs.push({ key: 'manuals', name: 'Manuals' });
		}
		let photoUris = [];
		let lightboxPhotoUris = [];
		if (asset.images && asset.images.length) {
			photoUris = asset.images.slice(0, 4).map((assetImg) => {
				const [imgFileId, imgFileName] = assetImg.split('/');
				return getProtectedImageUriForBuyer(imgFileId, imgFileName, 1200, 800);
			});
			lightboxPhotoUris = asset.images.map((photoString) => ({
				src: getProtectedImageUriFromString(ROLE_TYPES.BUYER)(photoString, 2400, 1600),
			}));
		}

		return (
			<div>
				<Route
					path="/buyer/assets/detail/:id"
					exact={true}
					render={() => (
						<Redirect
							to={{
								pathname: `/buyer/assets/detail/${assetId}/details`,
							}}
						/>
					)}
				/>
				<Content className="assetsDetailPage">
					{/*<ScrollToTopOnMount/>*/}
					{/*<BackTop/>*/}
					<ModalGateway>
						{this.state.lightboxIsOpen ? (
							<Lightbox onClose={this.closeLightbox}>
								<Carousel
									trackProps={{
										onViewChange: this.handleViewChange,
									}}
									views={lightboxPhotoUris}
									currentIndex={this.state.currentIndex}
								/>
							</Lightbox>
						) : null}
					</ModalGateway>

					<div className="imageLayout">
						{photoUris.map((photoUri, idx) => (
							<div
								key={idx}
								className={`imageTile imageTile${idx}`}
								style={{ backgroundImage: `url(${photoUri})` }}
							/>
						))}
						<div
							className={`imageLayout__gradientOverlay ${
								asset.isActive ? '' : 'imageLayout__gradientOverlay--darker'
							}`}
						>
							{!asset.isActive ? <div className="assetInactiveLabel">Inactive</div> : null}
							<div className="assetsDetailPage__overtitle">
								{nullSafeGet('assetType.name', asset)}
							</div>
							<h4 className="assetsDetailPage__title">{assetsFetching ? null : asset.name}</h4>
							<SubnavTabs
								tabs={tabs}
								onChange={this.handleTabChange}
								isActive={this.checkTabActive}
							/>
							<div style={{ position: 'absolute', top: 40, right: 40 }}>
								<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_ASSETS}>
									<Link to={`/buyer/assets/edit/${assetId}`} style={{ marginRight: 16 }}>
										<Button ghost={true}>Edit</Button>
									</Link>
									<Button ghost={true} onClick={this.handleDelete} style={{ marginRight: 16 }}>
										Delete
									</Button>
									<Button ghost={true} onClick={this.handleCopy} style={{ marginRight: 16 }}>
										Copy and create new
									</Button>
									<Popover
										content={!asset.isActive ? 'Inactive assets cannot be transferred' : ''}
										trigger="hover"
									>
										<Button
											ghost={true}
											onClick={this.showTransferModal}
											disabled={!asset.isActive || assetsFetching}
										>
											Transfer
										</Button>
									</Popover>
								</AccessPermissionChecker>
							</div>
							<div style={{ position: 'absolute', bottom: 40, right: 40 }}>
								{lightboxPhotoUris.length > 0 ? (
									<Button
										style={{ color: 'white' }}
										type="ghost"
										onClick={() => this.openLightbox()}
									>
										View Photos
									</Button>
								) : null}
							</div>
						</div>
					</div>
					{this.props.childRoutes.map((r, i) => (
						<RouteWithSubRoutes key={i} {...r} />
					))}
					{this.state.transferModalVisible ? (
						<TransferAssetToLocationForm
							asset={asset}
							onCancel={this.hideTransferModal}
							onSuccess={this.onTransferSuccess}
						/>
					) : null}
				</Content>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	assetsFetching: state.assets.fetching,
	asset: state.assets.detail,
	userType: state.session.userType,
});

const mapDispatchToProps = (dispatch) => ({
	getAsset: (id) => dispatch(assetsRestCrudThunksForBuyer.readOne(id)),
	deleteAsset: (entity) => dispatch(assetsRestCrudThunksForBuyer.delete(entity, 'id', null, true)),
	updateNavbar: (backlinkTitle, backlinkTo, navbarBackgroundColor) =>
		dispatch(updateNavbarState(backlinkTitle, backlinkTo, navbarBackgroundColor)),
	clearNavbar: () => dispatch(clearNavbarState()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetsDetailPage));

import * as React from 'react';
import moment from 'moment';
import { Avatar } from 'antd';
import { ALL_ROLES, ROLES } from '../components/roles/roles';
import { nullSafeGet, nullSafeGetOrElse } from './DataAccessUtils';
import { DAYS_OF_THE_WEEK_MAP } from '../constants/days_of_the_week';
import { CSV_EXPORT_DEFAULTS } from './DataConstants';
import { ExportToCsv } from 'export-to-csv';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { morePipelineFiltersBuyer } from '../components/WorkOrderTable/config/pipelineFilters/pipelineFilters';

const Color = require('color');

export const BACKEND_DATE_FORMAT = 'MM/DD/yyyy HH:mm:ss';

export const DATE_FORMAT = 'MMM D, YYYY h:mm a';

export const DATE_ONLY_FORMAT = 'MMM DD, YYYY';

export const TIME_ONLY_FORMAT = 'hh:mm A';

export const BE_DATE_ONLY_FORMAT = 'MM/DD/yyyy';

export const OPEN_WRENCH_DATA_VIZ_COLORS_12 = [
	'rgb(123,72,189)',
	'rgb(20,147,173)',
	'rgb(82,233,230)',
	'rgb(21,78,86)',
	'rgb(160,205,226)',
	'rgb(30,67,141)',
	'rgb(119,128,183)',
	'rgb(113,221,130)',
	'rgb(42,151,57)',
	'rgb(132,238,21)',
	'rgb(108,140,96)',
	'rgb(17,82,5)',
];
export const OPEN_WRENCH_DATA_VIZ_COLORS_6 = [
	'rgb(123,72,189)',
	'rgb(20,147,173)',
	'rgb(0,60,112)',
	'rgb(166,182,249)',
	'rgb(106,116,170)',
	'rgb(54,194,114)',
];
export const OPEN_WRENCH_DATA_VIZ_COLORS_8 = [
	'rgb(123,72,189)',
	'rgb(20,147,173)',
	'rgb(0,60,112)',
	'rgb(31,132,236)',
	'rgb(102,205,137)',
	'rgb(22,137,74)',
	'rgb(19,68,36)',
	'rgb(124,206,30)',
];

export const divergent_color_scheme_idea = [
	'#1673ae',
	'#008bc1',
	'#00a2c5',
	'#00b7b6',
	'#00ca98',
	'#37d96f',
	'#9ce340',
	'#eee600',
];

export const divergent_color_scheme_idea_2 = [
	'#003f5c',
	'#2f4b7c',
	'#665191',
	'#a05195',
	'#d45087',
	'#f95d6a',
	'#ff7c43',
	'#ffa600',
];

export const dual_color_scheme_idea = [
	'#1693ae',
	'#51a2b9',
	'#76b2c4',
	'#96c1cf',
	'#b5d1da',
	'#d3e1e5',
	'#f1f1f1',
	'#d6cbdf',
	'#bba7ce',
	'#a084bc',
	'#8562aa',
	'#684099',
	'#4a1e87',
];
export const dual_color_scheme_idea_2 = [
	'#1693ae',
	'#53a4bb',
	'#7ab6c9',
	'#9dc8d6',
	'#bedae4',
	'#dfecf1',
	'#ffffff',
	'#e2d7eb',
	'#c4b0d7',
	'#a78bc3',
	'#8966af',
	'#6a429b',
	'#4a1e87',
];
export const SUCCESS_COLOR = '#52c41a';
export const ERROR_COLOR = '#f5222d';

export const STOPLIGHT_COLORS = {
	pureGreen: 'rgb(27, 121, 34)',
	yellowGreen: 'rgb(81, 113, 11)',
	pureYellow: 'rgb(171, 133, 15)',
	yellowRed: 'rgb(219, 123, 43)',
	pureRed: 'rgb(204, 50, 50)',
};

export const ORDERED_STOPLIGHT_COLORS = [
	STOPLIGHT_COLORS.pureRed,
	STOPLIGHT_COLORS.yellowRed,
	STOPLIGHT_COLORS.pureYellow,
	STOPLIGHT_COLORS.yellowGreen,
	STOPLIGHT_COLORS.pureGreen,
];

export const BLACKS_AND_WHITES = {
	blackPrimary: 'rgba(0,0,0,0.65)',
	blackSecondary: 'rgba(0,0,0,0.45)',
	blackTertiary: 'rgba(0,0,0,0.25)',
	whitePrimary: 'rgba(255,255,255,0.85)',
	whiteSecondary: 'rgba(255,255,255,0.65)',
	whiteTertiary: 'rgba(255,255,255,0.45)',
};

const getWorkOrderPriorityColor = (priorityId) => {
	switch (priorityId) {
		case 1:
			return STOPLIGHT_COLORS.pureRed;
		case 2:
			return STOPLIGHT_COLORS.yellowRed;
		case 3:
			return STOPLIGHT_COLORS.pureYellow;
		case 4:
			return STOPLIGHT_COLORS.yellowGreen;
		case 5:
			return STOPLIGHT_COLORS.pureGreen;
		default:
			return 'rgba(0,0,0,0.45)';
	}
};

export const getWorkOrderBasePriorityColor = (priority) =>
	nullSafeGet('linkedToId', priority)
		? getWorkOrderPriorityColor(priority.linkedToId)
		: getWorkOrderPriorityColor(nullSafeGet('id', priority));

const getWorkOrderPriorityBackgroundColor = (priority) => {
	switch (priority) {
		case 1:
			return Color(STOPLIGHT_COLORS.pureRed).darken(0.2);
		case 2:
			return Color(STOPLIGHT_COLORS.yellowRed).darken(0.2);
		case 3:
			return Color(STOPLIGHT_COLORS.pureYellow).darken(0.1);
		case 4:
			return Color(STOPLIGHT_COLORS.yellowGreen).darken(0.2);
		case 5:
			return Color(STOPLIGHT_COLORS.pureGreen).darken(0.2);
		default:
			return 'rgba(0,0,0,0.45)';
	}
};

const getWorkOrderPriorityPillBackgroundColor = (priority) => {
	switch (priority) {
		case 1:
			return 'rgb(249, 230, 230)';
		case 2:
			return 'rgb(251, 239, 229)';
		case 3:
			return 'rgb(252, 243, 217)';
		case 4:
			return 'rgb(242, 249, 225)';
		case 5:
			return 'rgb(230, 249, 231)';
		default:
			return 'rgba(0,0,0,0.15)';
	}
};

export const getWorkOrderBasePriorityPillBackgroundColor = (priority) =>
	nullSafeGet('linkedToId', priority)
		? getWorkOrderPriorityPillBackgroundColor(priority.linkedToId)
		: getWorkOrderPriorityPillBackgroundColor(nullSafeGet('id', priority));

export const getWorkOrderBasePriorityBackgroundColor = (priority) =>
	nullSafeGet('linkedToId', priority)
		? getWorkOrderPriorityBackgroundColor(priority.linkedToId)
		: getWorkOrderPriorityBackgroundColor(nullSafeGet('id', priority));

const getWorkOrderPriorityAbbreviation = (priority, defaultAbbr = '0') => {
	switch (priority) {
		case 1:
			return 'E';
		case 2:
			return 'U';
		case 3:
			return 'N';
		case 4:
			return '7';
		case 5:
			return '30';
		default:
			return defaultAbbr;
	}
};

export const getWorkOrderBasePriorityAbbreviation = (priority) => {
	const defaultAbbr = nullSafeGetOrElse('name', priority, '0').charAt(0);
	return getWorkOrderPriorityAbbreviation(nullSafeGet('id', priority), defaultAbbr);
};

export const currencyFormatter =
	(decimalDigits: number = 2, currency: string = 'USD') =>
	(amount) => {
		if (!amount && amount !== 0) {
			return '--';
		}
		let amt = undefined;
		try {
			amt = parseFloat(amount);
		} catch (err) {
			amt = amount;
		}
		return `${parseFloat(amt).toLocaleString('en', { style: 'currency', currency: currency })}`;
	};

export const customCurrencyFormatter =
	(decimalDigits: number = 2, currency: string = 'USD', symbol: string = '$') =>
	(amount) => {
		if (!amount && amount !== 0) {
			return '--';
		}
		let amt = undefined;
		try {
			amt = parseFloat(amount);
		} catch (err) {
			amt = amount;
		}
		const sign = amt >= 0 ? '' : '-';
		const amtString = `${parseFloat(amt).toFixed(decimalDigits)}`
			? `${Math.abs(parseFloat(amt)).toFixed(decimalDigits)}`.replace(
					new RegExp(/\B(?=(\d{3})+(?!\d))/g),
					','
			  )
			: '';
		return `${sign}${symbol}${amtString} ${currency}`;
	};
export const symbolCurrencyFormatter =
	(decimalDigits: number = 2, currency: string = 'USD', symbol: string = '$') =>
	(amount) => {
		if (!amount && amount !== 0) {
			return '--';
		}
		let amt = undefined;
		try {
			amt = parseFloat(amount);
		} catch (err) {
			amt = amount;
		}
		const amtString = `${parseFloat(amt).toFixed(decimalDigits)}`
			? `${parseFloat(amt).toFixed(decimalDigits)}`.replace(
					new RegExp(/\B(?=(\d{3})+(?!\d))/g),
					','
			  )
			: '';
		return `${symbol}${amtString}`;
	};

export const capitalize = (str: string | null) =>
	str ? str.charAt(0).toUpperCase() + str.slice(1) : '';

export const capitalizeEachWord = (str: string) =>
	str
		? str
				.split(' ')
				.map((word) => capitalize(word))
				.join(' ')
		: '';

export const dateFormatter = (dateString: string, format: string = DATE_FORMAT) =>
	moment.parseZone(dateString).format(format);

export const dateFormatterWithoutTimeZone = (dateString: string, format: string = DATE_FORMAT) =>
	moment(dateString).format(format);

export const numberWithCommas = (num: number) => {
	return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const showHourIfSameDay = (
	dateString: string,
	longDateFormat: string = 'M/D/YY'
): string => {
	const now = moment();
	const date = moment(dateString);
	const timeDiff = now.diff(date, 'days');
	if (timeDiff === 0) {
		return date.format('h:mm a');
	} else if (timeDiff === 1) {
		return 'Yesterday';
	} else {
		return date.format(longDateFormat);
	}
};

export const showHour = (dateString: string, longDateFormat: string = 'M/D/YY'): string => {
	const now = moment();
	const date = moment(dateString);
	const timeDiff = now.diff(date, 'days');
	return date.format('h:mm a');
};

export const showDate = (dateString: string, longDateFormat: string = 'M/D/YY'): string => {
	const now = moment();
	const date = moment(dateString);

	if (now.isSame(date, 'day')) {
		return 'Today';
	} else if (now.clone().subtract(1, 'day').isSame(date, 'day')) {
		return 'Yesterday';
	} else if (now.clone().add(1, 'day').isSame(date, 'day')) {
		return 'Tomorrow';
	} else {
		return date.format(longDateFormat);
	}
};

export const pricingModelClassFormatter = (pricingModelClass: string) => {
	const validPricingModelClasses = new Set(['non-lumpSum', 'lumpSum']);
	if (!validPricingModelClasses.has(pricingModelClass))
		throw new Error('invalid pricing model class supplied: ' + pricingModelClass);
	const aliasMapper = {
		'non-lumpSum': 'Detailed Breakdown',
		lumpSum: 'Lump Sum',
	};
	return aliasMapper[pricingModelClass];
};

// TODO: GENERALIZE IF YOU WANT TO USE OUTSIDE OF SOURCING OVERVIEW
export const sourcingEventStatusIndicatorFormatter = (sourcingEventStatus: string) => {
	if (!sourcingEventStatus) {
		return null;
	}
	// TODO: Refactor this to get valid statuses from a config somewhere -- this list does not belong here;
	const validStatuses = new Set(['draft', 'active', 'closed', 'award']);
	if (!validStatuses.has(sourcingEventStatus))
		throw new Error('invalid sourcing event status provided: ' + sourcingEventStatus);
	return (
		<span>
			<span className={`sourcingEvent__statusIndicator ${sourcingEventStatus}`}></span>
			<span>{capitalize(sourcingEventStatus)}</span>
		</span>
	);
};

export const plannedMaintenanceSchedulesStatusIndicatorFormatter = (scheduleStatus: string) => {
	if (!scheduleStatus) {
		return null;
	}
	// TODO: Refactor this to get valid statuses from a config somewhere -- this list does not belong here;
	const validStatuses = new Set(['inactive', 'active', 'paused', 'expired']);
	if (!validStatuses.has(scheduleStatus))
		throw new Error('invalid planned maintenance schedule status provided: ' + scheduleStatus);
	return (
		<span>
			<span className={`plannedMaintenanceSchedules__statusIndicator ${scheduleStatus}`}></span>
			<span>{capitalize(scheduleStatus)}</span>
		</span>
	);
};

export const requestForProposalStatusIndicatorFormatter = (requestForProposalStatus: string) => {
	if (!requestForProposalStatus) {
		return null;
	}
	// TODO: Refactor this to get valid statuses from a config somewhere -- this list does not belong here;
	const validStatuses = new Set(['draft', 'active', 'closed', 'award']);
	if (!validStatuses.has(requestForProposalStatus))
		throw new Error('invalid request for proposal status provided: ' + requestForProposalStatus);
	return (
		<span>
			<span className={`requestsForProposal__statusIndicator ${requestForProposalStatus}`}></span>
			<span>{capitalize(requestForProposalStatus)}</span>
		</span>
	);
};

export const workOrderPriorityIndicatorFormatter = (workOrderPriority: string) => {
	const priorityBackgroundColor = getWorkOrderBasePriorityBackgroundColor(workOrderPriority);
	if (!workOrderPriority) {
		return null;
	}
	const indicatorStyle = {
		width: '10px',
		height: '10px',
		borderRadius: '50%',
		display: 'inline-block',
	};
	return <span style={{ ...indicatorStyle, backgroundColor: priorityBackgroundColor }}></span>;
};

export const statusIndicatorFormatter = (isActive: boolean, verbose: boolean = true) => {
	const accountStatus = isActive ? 'active' : 'inactive';
	return (
		<span>
			<span
				style={{
					width: 10,
					height: 10,
					borderRadius: '50%',
					display: 'inline-block',
					background: isActive ? SUCCESS_COLOR : ERROR_COLOR,
					marginRight: 8,
				}}
			></span>
			{verbose ? <span>{capitalize(accountStatus)}</span> : null}
		</span>
	);
};

export const userRoleTextFormatter = (userRole: string) => {
	if (!ALL_ROLES.has(userRole)) throw new Error('invalid user role provided: ' + userRole);
	return ROLES[userRole].displayName;
};

export const sourcingEventStatusTextFormatter = (sourcingEventStatus: string) => {
	// TODO: Refactor this to get valid statuses from a config somewhere -- this list does not belong here;
	const validStatuses = new Set(['draft', 'active', 'closed', 'award']);
	if (!validStatuses.has(sourcingEventStatus))
		throw new Error('invalid sourcing event status provided: ' + sourcingEventStatus);

	const STATUS_TEXT_MAPPER = {
		draft: 'Draft',
		active: 'Bidding Active',
		closed: 'Bidding Closed',
		award: 'Bidding Closed',
	};

	return STATUS_TEXT_MAPPER[sourcingEventStatus];
};

export const getSourcingEventStatusTagColor = (sourcingEventStatus: string) => {
	// TODO: Refactor this to get valid statuses from a config somewhere -- this list does not belong here;
	const validStatuses = new Set(['draft', 'active', 'closed', 'award']);
	if (!validStatuses.has(sourcingEventStatus))
		throw new Error('invalid sourcing event status provided: ' + sourcingEventStatus);

	const STATUS_COLOR_MAPPER = {
		draft: 'purple',
		active: 'magenta',
		closed: 'geekblue',
		award: 'green',
	};

	return STATUS_COLOR_MAPPER[sourcingEventStatus];
};

export const fillRange = (start, end) => {
	return Array.from({ length: end - start }, (x, i) => i + start);
};

export const plannedMaintenanceFrequencyTextFormatter = (frequency: string) => {
	if (!frequency) {
		return '';
	}

	const validStatuses = new Set(['daily', 'weekly', 'monthly', 'yearly']);
	const frequencies = ['days', 'weeks', 'months', 'years'];
	const frequencyUnit = frequency.split(/\d/).slice(-1);

	const everyXDaysValidator = /every\d+\w/;

	if (!(frequencies.some((f) => frequency.includes(f)) || everyXDaysValidator.test(frequency))) {
		throw new Error('invalid frequency provided: ' + frequency);
	}

	const daysTextMapper = fillRange(1, 1000).reduce(
		(map, day) => (
			(map['every' + day + frequencyUnit] = 'Every ' + day + ' ' + frequencyUnit), map
		),
		{}
	);

	const FREQUENCY_TEXT_MAPPER = {
		daily: 'Every day',
		weekly: 'Every week',
		monthly: 'Every month',
		...daysTextMapper,
	};

	return FREQUENCY_TEXT_MAPPER[frequency];
};
export const breakdownFrequencyText = (frequencyUnit) =>
	fillRange(1, 1000).reduce(
		(map, amount) => ((map['every' + amount + frequencyUnit] = { amount, frequencyUnit }), map),
		{}
	);

export const getFrequencyUnit = (frequency) => {
	const units = ['day', 'week', 'month', 'year'];
	for (let unit of units) {
		if (frequency && frequency.includes(unit)) {
			return unit + 's';
		}
	}
	return 'days'; // Default value
};

export const awardStatusTextFormatter = (awardStatus: string) => {
	// TODO: Refactor this to get valid statuses from a config somewhere -- this list does not belong here;
	const validStatuses = new Set(['pending', 'awarded', 'declined']);
	if (!validStatuses.has(awardStatus))
		throw new Error('invalid award status provided: ' + awardStatus);

	const STATUS_TEXT_MAPPER = {
		pending: 'Pending',
		awarded: 'Congrats! Awarded',
		declined: 'Declined',
	};

	return STATUS_TEXT_MAPPER[awardStatus];
};

export function stringArrToList(input: string[], oxford: boolean = true, join: string = 'and') {
	switch (input.length) {
		case 0: {
			return input;
		}
		case 1: {
			return input[0];
		}
		case 2: {
			return `${input[0]} ${join} ${input[1]}`;
		}
		default: {
			let returnString = input[0];
			for (let i = 1; i < input.length; i++) {
				if (i < input.length - 1) {
					returnString += `, ${input[i]}`;
				} else {
					returnString += oxford ? ',' : '';
					returnString += ` ${join} ${input[i]}`;
				}
			}
			return returnString;
		}
	}
}

export const getTimeDuration = (
	date1,
	date2,
	options = { hideMinutes: false, hideSeconds: false }
) => {
	const now = moment(date1);
	const then = moment(date2);
	let seconds = now.diff(then, 'seconds');
	let hours = Math.floor(seconds / 3600.0);
	seconds = seconds - hours * 3600;
	let minutes = Math.floor(seconds / 60.0);
	seconds = seconds - minutes * 60;
	let displayParts = [];
	if (hours > 1) {
		displayParts.push(`${hours} hours`);
	} else if (hours > 0) {
		displayParts.push(`${hours} hour`);
	}
	if (minutes > 1 && !options.hideMinutes) {
		displayParts.push(`${minutes} minutes`);
	} else if (minutes > 0 && !options.hideMinutes) {
		displayParts.push(`${minutes} minute`);
	}
	if (seconds > 1 && !options.hideSeconds) {
		displayParts.push(`${seconds} seconds`);
	} else if (seconds > 0 && !options.hideSeconds) {
		displayParts.push(`${seconds} second`);
	}
	return stringArrToList(displayParts);
};

export const getTimeDurationFromSeconds = (seconds) => {
	let hours = Math.floor(seconds / 3600.0);
	seconds = seconds - hours * 3600;
	let minutes = Math.floor(seconds / 60.0);
	seconds = seconds - minutes * 60;
	let displayParts = [];
	if (hours > 1) {
		displayParts.push(`${hours} hours`);
	} else if (hours > 0) {
		displayParts.push(`${hours} hour`);
	}
	if (minutes > 1) {
		displayParts.push(`${minutes} minutes`);
	} else if (minutes > 0) {
		displayParts.push(`${minutes} minute`);
	}
	if (seconds > 1) {
		displayParts.push(`${seconds} seconds`);
	} else if (seconds > 0) {
		displayParts.push(`${seconds} second`);
	}
	return stringArrToList(displayParts);
};

export const timeSince = (date, options = undefined) => {
	return getTimeDuration(moment(), date, options);
};

export const timeUntil = (date, options = undefined) => {
	return getTimeDuration(date, moment(), options);
};

export const timeUntilOrSince = (date, options = undefined) => {
	const isUntil = moment().diff(date) < 0;
	const hoursDifference = Math.abs(moment().diff(date, 'h'));
	if (hoursDifference >= 48) {
		return `${dateFormatter(date, 'MMM D')} at ${dateFormatter(date, 'h:mm a')}`;
	} else if (hoursDifference >= 24) {
		if (isUntil) {
			return 'tomorrow';
		} else {
			return 'yesterday';
		}
	} else {
		if (isUntil) {
			const timeDuration = timeUntil(date, { hideSeconds: true });
			return 'in ' + timeDuration;
		} else {
			const timeDuration = timeSince(date, { hideSeconds: true });
			return timeDuration + ' ago';
		}
	}
};

export const dateUntilOrSince = (date, options = undefined) => {
	const isUntil = moment().diff(date) < 0;
	const hoursDifference = Math.abs(moment().diff(date, 'h'));
	if (hoursDifference > 168) {
		return `${dateFormatter(date, 'MMM D')}`;
	} else {
		if (isUntil) {
			const timeDuration = timeUntil(date, { hideMinutes: true, hideSeconds: true });
			if (timeDuration === 'one day') {
				return 'tomorrow';
			}
			return 'in ' + timeDuration;
		} else {
			const timeDuration = timeSince(date, { hideMinutes: true, hideSeconds: true });
			if (timeDuration === 'one day') {
				return 'yesterday';
			}
			return timeDuration + ' ago';
		}
	}
};

export const isLocationOpen = (locationOperatingHours) => {
	const now = moment();
	const currentDayOfWeek = now.weekday();
	const currentDayOfWeekName = DAYS_OF_THE_WEEK_MAP[currentDayOfWeek];
	const locationOpensAt = nullSafeGet(`${currentDayOfWeekName}.from`, locationOperatingHours);
	const locationClosesAt = nullSafeGet(`${currentDayOfWeekName}.to`, locationOperatingHours);
	if (
		!locationOpensAt ||
		!locationClosesAt ||
		!(
			now.isBefore(moment(locationOpensAt, 'h:mm a')) ||
			now.isAfter(moment(locationClosesAt, 'h:mm a'))
		)
	) {
		return true;
	} else {
		return false;
	}
};

export const sourcingEventTypeTextFormatter = (sourcingEventType: string) => {
	// TODO: Refactor this to get valid types from a config somewhere -- this list does not belong here;
	const lowercaseSourcingEventType = sourcingEventType.toLowerCase();
	const validStatuses = new Set(['rfp', 'rfi', 'auction', 'rfq']);
	if (!validStatuses.has(lowercaseSourcingEventType))
		throw new Error('invalid sourcing event type provided: ' + sourcingEventType);

	const STATUS_TEXT_MAPPER = {
		rfp: 'Request for Proposal',
		rfi: 'Request for Information',
		auction: 'Reverse Auction',
		rfq: 'Request for Quotation',
	};

	return STATUS_TEXT_MAPPER[lowercaseSourcingEventType];
};

export const bidderStatusTextFormatter = (bidderStatus: string) => {
	if (!bidderStatus) {
		return '';
	}

	// TODO: Refactor this to get valid statuses from a config somewhere -- this list does not belong here;
	const validStatuses = new Set([
		'norespnose',
		'noResponse',
		'bidding',
		'notBidding',
		'awarded',
		'declined',
	]);
	if (!validStatuses.has(bidderStatus))
		throw new Error('invalid bidder status provided: ' + bidderStatus);

	const STATUS_TEXT_MAPPER = {
		noresponse: 'No Response',
		noResponse: 'No Response',
		notBidding: 'Not Participating',
		bidding: 'Participating',
		awarded: 'Awarded',
		declined: 'Not Awarded',
	};

	return STATUS_TEXT_MAPPER[bidderStatus];
};

export const ndaRequiredTextFormatter = (isRequired: boolean) => {
	return isRequired ? 'Required' : 'Not required';
};

export const nameAvatarFormatter = (name: string) => {
	const initials = name
		.split(' ')
		.map((el) => el.slice(0, 1).toUpperCase())
		.join('');
	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Avatar size="small">{initials}</Avatar>
			<span style={{ marginLeft: '8px' }}>{name}</span>
		</div>
	);
};

export const roundToNearest = (value: number, step: number = 1.0): number => {
	let inverseStep = 1.0 / step;
	return Math.round(value * inverseStep) / inverseStep;
};

export const optionalFilter = (val: any, placeholderText = '--') => {
	return val === undefined || val === null || (typeof val == 'string' && val === '')
		? placeholderText
		: val;
};

export const getInitials = (nameGiven, nameFamily) => {
	if (!nameGiven && !nameFamily) {
		return '';
	}
	if ((!nameFamily || nameFamily.length == 0) && nameGiven) {
		return nameGiven.slice(0, 1).toUpperCase();
	}
	return (nameGiven.slice(0, 1) + nameFamily.slice(0, 1)).toUpperCase();
};
export const getInitial = (word) => (word && typeof word === 'string' ? word.slice(0, 1) : '');

export const getContactName = (contact) =>
	`${nullSafeGetOrElse('nameGiven', contact, '')} ${nullSafeGetOrElse('nameFamily', contact, '')}`;

export const acceptanceRequiredFormatter = (acceptanceRequired: boolean) => {
	return acceptanceRequired ? 'Required' : 'Not required';
};

export const floatToPercentageString = (num: number, decimalPlaces: number = 2): string => {
	if (num === null || num === undefined) {
		return '--';
	}
	return (num * 100).toFixed(decimalPlaces) + '%';
};

export const isHTML = (html: string) => {
	const doc = document.createElement('div');
	doc.innerHTML = html;
	return doc.innerHTML === html;
};

const DEFAULT_START_DATES = {
	[`Last week`]: moment().subtract(7, 'day'),
	[`Last month`]: moment().subtract(1, 'month'),
	[`Last 2 months`]: moment().subtract(2, 'month'),
	[`Last 3 months`]: moment().subtract(3, 'month'),
	[`Last 6 months`]: moment().subtract(6, 'month'),
	[`Last 1 year`]: moment().subtract(1, 'year'),
};

export const getDefaultDateRanges = () =>
	Object.keys(DEFAULT_START_DATES).reduce((obj, key) => {
		obj[key] = [DEFAULT_START_DATES[key], moment()];
		return obj;
	}, {});

const isSameDate = (date1, date2) => date1.diff(date2, 'day') === 0;

export const findIfDateRangeLabelAvailable = (startDate, endDate) => {
	if (!isSameDate(moment(), endDate)) {
		return undefined;
	}
	return Object.keys(DEFAULT_START_DATES).find((key) =>
		isSameDate(DEFAULT_START_DATES[key], startDate)
	);
};

export const formatDateForParam = (date) => date.format(BACKEND_DATE_FORMAT);

export const DEFAULT_WARRANTY_PERIODS = (values) => {
	const [start, end] = values;
	if (start === undefined && end === undefined) {
		return {};
	}

	const calcDate = (val, unit) =>
		start ? [start, start.clone().add(val, unit)] : [end.clone().subtract(val, unit), end];

	return {
		[`3 months`]: calcDate(3, 'month'),
		[`6 months`]: calcDate(6, 'month'),
		[`12 months`]: calcDate(12, 'month'),
		[`18 months`]: calcDate(18, 'month'),
		[`24 months`]: calcDate(24, 'month'),
		[`30 months`]: calcDate(30, 'month'),
		[`36 months`]: calcDate(36, 'month'),
		[`48 months`]: calcDate(48, 'month'),
		[`60 months`]: calcDate(60, 'month'),
	};
};
export const getDefaultDatePeriods = (defaultPeriods) =>
	Object.keys(defaultPeriods).reduce((obj, key) => {
		obj[key] = defaultPeriods[key];
		return obj;
	}, {});

export const getDaysInMonthsBetweenDates = (startDate, endDate, existingObject) => {
	const getDiffInDays = (start, end) => {
		const val = start.isSame(end, 'day') ? 0 : end.diff(start, 'days');
		if (val !== 0) {
			console.log(`value ${val}`);
			console.log(`start : ${start.format('DD MM yyyy')}`);
			console.log(`end : ${end.format('DD MM yyyy')}`);
		}
		return val;
	};

	const addDiffToObject = (obj, key, diff) => (obj[key] = obj[key] ? obj[key] + diff : diff);

	const result = existingObject ? { ...existingObject } : [];

	const endOfMonth = startDate.clone().endOf('month');
	if (endOfMonth < endDate) {
		console.log('end of month is less');
		const key = startDate.format('MMMM yyyy');
		addDiffToObject(result, key, getDiffInDays(startDate, endOfMonth));
		while (endOfMonth < endDate) {
			endOfMonth.add(1, 'month');
			const key = endOfMonth.format('MMMM yyyy');
			if (endOfMonth < endDate) {
				addDiffToObject(result, key, endOfMonth.daysInMonth());
			}
		}
	} else {
		console.log('end of month is not less than end date');
		const key = startDate.format('MMMM yyyy');
		addDiffToObject(result, key, getDiffInDays(startDate, endDate));
	}
	return result;
};

export const downloadCSVForAnalytics = (data, title, category) => {
	const csvExporter = new ExportToCsv({
		...CSV_EXPORT_DEFAULTS,
		filename: title,
		title,
		headers: [category, 'Total Spend', 'Percentage'],
	});
	csvExporter.generateCsv(
		data.map((obj) => ({
			name: obj.fieldName,
			total: currencyFormatter(0, 'USD')(nullSafeGetOrElse('subTotal', obj, 0)),
			percentage: floatToPercentageString(nullSafeGetOrElse('percentageOfTotal', obj, 0) / 100),
		}))
	);
};

const phoneUtil = PhoneNumberUtil.getInstance();
export const isValidPhoneNumber = (value) => {
	return value && phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(value, 'US'));
};
export const formatPhoneNumber = (str) => {
	return phoneUtil.format(phoneUtil.parse(str, 'US'), PhoneNumberFormat.INTERNATIONAL);
};
export const checkAndFormatPhoneNumber = (phoneNumber) => {
	let isValid;
	try {
		isValid = isValidPhoneNumber(phoneNumber);
	} catch (error) {
		console.warn('Validation error:', error.message);
		return phoneNumber;
	}

	return isValid ? formatPhoneNumber(phoneNumber) : phoneNumber;
};
export const getNumberSuffix = (number) => {
	const lastNumber = number.toString().slice(-1);
	const lastTwoNumbers = number.toString().slice(-2);

	switch (lastTwoNumbers) {
		case '11':
			return 'th';
		case '12':
			return 'th';
		case '13':
			return 'th';
		default:
			switch (lastNumber) {
				case '1':
					return 'st';
				case '2':
					return 'nd';
				case '3':
					return 'rd';
				default:
					return 'th';
			}
	}
};

export const getWeekOfTheMonth = (date) => {
	const monthWeeks = ['first', 'second', 'third', 'fourth', 'last'];
	const day = date.date();
	const daysInMonth = date.daysInMonth();
	if (daysInMonth - day < 7) {
		return 'last';
	} else {
		return monthWeeks[Math.floor((day - 1) / 7)];
	}
};

export const isLastDayOfTheMonth = (date) => {
	const day = date.date();
	const daysInMonth = date.daysInMonth();
	return day === daysInMonth;
};

export const joinList = (list = [], lastSeparator = 'and') =>
	list.slice(0, -1).join(', ') + (list.length > 1 ? ` ${lastSeparator} ` : ``) + list.slice(-1);

export const secondsToStr = (seconds: number): string => {
	if (seconds === 0) {
		return '0';
	}
	const hours = Math.floor(seconds / 3600);
	const remainingSeconds = seconds % 3600;
	const days = Math.floor(hours / 24);
	const remainingHours = hours % 24;
	if (days > 0) {
		return `${days} day${days > 1 ? 's' : ''}`;
	} else if (remainingHours > 0) {
		return `${remainingHours} hour${remainingHours > 1 ? 's' : ''}`;
	} else if (remainingSeconds > 60) {
		const minutes = Math.floor(remainingSeconds / 60);
		return `${minutes} minute${minutes > 1 ? 's' : ''}`;
	} else {
		return '---';
	}
};

export const transformTypesTracked = (type, showPrefix = true, showPlural = true) => {
	switch (type) {
		case 'pending_workorders':
			return `${showPrefix ? 'Pending ' : ''}Work Order${showPlural ? 's' : ''}`;
		case 'approved_workorders':
			return `${showPrefix ? 'Approved ' : ''}Work Order${showPlural ? 's' : ''}`;
		case 'completed_workorders':
			return `${showPrefix ? 'Completed ' : ''}Work Order${showPlural ? 's' : ''}`;
		case 'pending_quotes':
			return `${showPrefix ? 'Pending ' : ''}Quote${showPlural ? 's' : ''}`;
		case 'approved_quotes':
			return `${showPrefix ? 'Approved ' : ''}Quote${showPlural ? 's' : ''}`;
		case 'pending_invoices':
			return `${showPrefix ? 'Pending ' : ''}Invoice${showPlural ? 's' : ''}`;
		default:
			return `${showPrefix ? 'Approved ' : ''}Invoice${showPlural ? 's' : ''}`;
	}
};

export const isSubStatusSelected = (displayStatus, selectedStatuses) => {
	const displayStatuses = morePipelineFiltersBuyer();
	const subStatuses = nullSafeGetOrElse(displayStatus, displayStatuses, []);

	return subStatuses.some((s) => {
		const subStatusName = nullSafeGet('name', s);
		return selectedStatuses.includes(subStatusName);
	});
};

export const aggregateCounts = (filters, counts) => {
	let amount = 0;
	const statuses = nullSafeGetOrElse('displayStatuses', filters, '').split(',');
	const displayStatuses = morePipelineFiltersBuyer();
	const selectedStatuses = nullSafeGetOrElse('statuses', filters, undefined);
	const selectedStatusesArray = selectedStatuses ? selectedStatuses.split(',') : [];

	statuses.forEach((ds) => {
		if (selectedStatuses) {
			nullSafeGetOrElse(ds, displayStatuses, []).forEach((s) => {
				const subStatusName = nullSafeGet('name', s);
				if (selectedStatusesArray.some((s) => s === subStatusName)) {
					amount += nullSafeGetOrElse(subStatusName, counts, 0);
				}
			});
		} else {
			amount += nullSafeGetOrElse(ds, counts, 0);
		}
	});
	return amount;
};

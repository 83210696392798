import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Alert, Select, Switch, Form, Spin } from 'antd';
import { connect } from 'react-redux';
import {
	hasAccessToAllLocations,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import BuyerRegionsLocationsTreeSelect from '../regions_tree_select/BuyerRegionsLocationsTreeSelect';
import { fetchUserForBuyer } from '../../thunks/users_thunks';
import { BUYER_ADMIN } from '../roles/roles';
import { FILTER_CONFIG_NAMES } from '../../utils/DataConstants';

const FormItem = Form.Item;

const BuyerAdminRoleUpdateForm: FC<any> = ({
	visible,
	onCancel,
	onSubmit,
	formData,
	createErrors,
	updateErrors,
	locations,
	isLocationChange,
	fetchUser,
	creating,
	updating,
}) => {
	const [form] = Form.useForm();

	const { setFieldsValue } = form;

	const [loading, setLoading] = useState(false);
	const email = useMemo(
		() => (visible ? nullSafeGet('contact.email', formData) : null),
		[formData, visible]
	);

	const roles = Form.useWatch('roles', form);

	const isAllLocationChecked = Form.useWatch('hasAccessToAllLocations', form);

	const refreshUser = useCallback(() => {
		setLoading(true);
		fetchUser(email)
			.then((user) => {
				setFieldsValue({
					email: nullSafeGet('contact.email', user),
					nameGiven: nullSafeGet('contact.nameGiven', user),
					nameFamily: nullSafeGet('contact.nameFamily', user),
					roles:
						nullSafeGetOrElse('role', user, []).length > 0
							? nullSafeGet('role', user)[0]
							: 'BUYER_STORE_ASSOCIATE',
					locationIds: nullSafeGetOrElse('locationIds', user, []),
					hasAccessToAllLocations: nullSafeGetOrElse('hasAccessToAllLocations', user, false),
					isSharedContact: nullSafeGetOrElse('contact.isSharedContact', user, false),
					readOnlyAccess: nullSafeGetOrElse('contact.readOnlyAccess', user, false),
				});
			})
			.finally(() => setLoading(false));
	}, [email, fetchUser, setFieldsValue]);

	useEffect(() => email && refreshUser(), [email, fetchUser, refreshUser]);
	const handleSubmit = React.useCallback(
		(values) => {
			const updatedValues = {
				...values,
				...(values.hasAccessToAllLocations && { locationIds: [] }),
			};

			onSubmit && onSubmit(updatedValues);
		},
		[onSubmit]
	);

	return (
		<Modal
			visible={visible}
			width={600}
			title={'Edit User Information'}
			okText={'Update'}
			onCancel={onCancel}
			onOk={form.submit}
			closable={true}
			destroyOnClose={true}
			confirmLoading={creating || updating}
		>
			{loading ? (
				<Spin />
			) : (
				<Form
					form={form}
					layout="vertical"
					className="supplierForm"
					preserve={false}
					initialValues={{}}
					onFinish={handleSubmit}
				>
					{createErrors.length > 0 ? (
						<FormItem>
							<Alert message={createErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					{updateErrors.length > 0 ? (
						<FormItem>
							<Alert message={updateErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					<FormItem label="Email" name={'email'}>
						<Input defaultValue={email} style={{ maxWidth: 300 }} disabled={true} />
					</FormItem>
					<FormItem
						label={'Name'}
						className={'flex flex-row'}
						required={true}
						rules={[
							{
								required: true,
								message: 'Please input name.',
							},
						]}
					>
						<div className={'flex flex-row'} style={{ marginBottom: -24 }}>
							<FormItem name={'nameGiven'}>
								<Input
									placeholder="Given name"
									style={{ maxWidth: 200 }}
									disabled={false}
									defaultValue={nullSafeGet('contact.nameGiven', formData)}
								/>
							</FormItem>
							<FormItem name={'nameFamily'}>
								<Input
									defaultValue={nullSafeGet('contact.nameFamily', formData)}
									placeholder="Family name"
									style={{ maxWidth: 200, marginLeft: 8 }}
									disabled={false}
								/>
							</FormItem>
						</div>
					</FormItem>

					<FormItem
						label="Roles"
						name="roles"
						required={true}
						rules={[
							{
								required: true,
								message: 'Please select a role',
							},
						]}
					>
						<Select
							disabled={isLocationChange ? isLocationChange : false}
							style={{ maxWidth: 200 }}
							defaultValue={
								nullSafeGet('role', formData) === undefined ? 'BUYER_USER' : formData.role[0]
							}
						>
							<Select.Option value="BUYER_ADMIN">Admin</Select.Option>
							<Select.Option value="BUYER_USER">Manager</Select.Option>
							<Select.Option value="BUYER_STORE_ASSOCIATE">Store Associate</Select.Option>
						</Select>
					</FormItem>
					<FormItem
						name="hasAccessToAllLocations"
						valuePropName="checked"
						required={true}
						hidden={form.getFieldValue(`roles`) === 'BUYER_ADMIN'}
					>
						<Switch
							checked={!!form.getFieldValue('hasAccessToAllLocations')}
							onChange={(checked) => form.setFieldsValue({ hasAccessToAllLocations: !!checked })}
						/>
						<label style={{ marginLeft: 8 }}>Select All Locations</label>
					</FormItem>
					<FormItem
						name="locationIds"
						hidden={form.getFieldValue('roles') === 'BUYER_ADMIN'}
						label={form.getFieldValue(`roles`) === 'BUYER_STORE_ASSOCIATE' ? 'Locations' : null}
						rules={[
							{
								type: 'array',
								required: !isAllLocationChecked,
								message: 'Please select a location',
							},
						]}
					>
						<BuyerRegionsLocationsTreeSelect
							mode="multiple"
							noIsActiveFilter
							disabled={isAllLocationChecked}
							filtersSlice={locations}
							filtersValueAccessor={() => form.getFieldValue(FILTER_CONFIG_NAMES.LOCATION)}
							targetCollectionName={'buyerUsersLocations'}
						/>
					</FormItem>
					<div className="flex flex-row items-baseline">
						<FormItem name="isSharedContact" valuePropName="checked">
							<Switch />
						</FormItem>
						<label className="ml-2">Shared Account</label>
					</div>
					{roles !== BUYER_ADMIN && (
						<div className="flex flex-row items-baseline">
							<FormItem name="readOnlyAccess" valuePropName="checked">
								<Switch />
							</FormItem>
							<label className="ml-2">Read Only Access</label>
						</div>
					)}
				</Form>
			)}
		</Modal>
	);
};

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	createErrors: state.buyer_contacts.createErrors,
	updateErrors: state.buyer_contacts.updateErrors,
	creating: state.buyer_contacts.creating,
	updating: state.buyer_contacts.updating,
	currentUser: state.session.currentUser,
	formData: ownProps.formData,
	isLocationChange: ownProps.isLocationChange,

	buyerUsers: state.buyer_users,
	locations: state.locations,
	regions: state.regions,
});

const mapDispatchToProps = (dispatch) => ({
	fetchUser: (email) => dispatch(fetchUserForBuyer(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BuyerAdminRoleUpdateForm);

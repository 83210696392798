import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import '@ant-design/compatible/assets/index.css';

import { Button, Col, Row, message, Alert } from 'antd';
import {
	clearFiltersQuotes,
	fetchQuotes,
	updateFiltersQuotes,
	updatePaginationQuotes,
	updateSortingQuotes,
	replaceFiltersAndSortingQuotes,
	updateSelection,
	updateQuoteStatusBulkWrapper,
} from '../../thunks/quotes_thunks_wrapper';
import {
	changeFilterValueToArrayOfIds,
	getEndDateFieldName,
	getEntitiesById,
	getSelectionObject,
	getStartDateFieldName,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import TableHeader from './TableHeader';
import QuotesListTable from './QuotesListTable';
import TableFooter from './TableFooter';
import { standardInitialStateGenerator } from '../../reducers/standard_reducer_utils';
import OWAsyncTreeSelect from '../ow_async_tree_select/OWAsyncTreeSelect';
import {
	fetchSupplierFacilitiesInPrivateNetworkForBuyer,
	fetchSupplierFacilitiesInPrivateNetworkForSupplier,
} from '../../thunks/supplier_facilities_thunks';
import OWRadioGroup from '../ow_radio_group/OWRadioGroup';
import { FILTER_CONFIG_NAMES, FILTER_FIELD_TYPE, ROLE_TYPES } from '../../utils/DataConstants';
import { unionWith, differenceWith, isEqual } from 'lodash';
import TableSelectionRow from './TableSelectionRow/TableSelectionRow';

import {
	QUOTE_MULTI_SELECT_ACTION_CONFIG_FOR_BUYER,
	QUOTE_MULTI_SELECT_ACTION_CONFIG_FOR_SUPPLIER,
} from './config/multiSelect/multiSelect';
import { isInternalTech } from '../../utils/AuthUtils';
import moment from 'moment';
import { formatDateForParam } from '../../utils/DataFormatterUtils';
import OWDateRangePicker from '../ow_date_range_picker/OWDateRangePicker';

const TARGETCOLLECTIONNAME = 'quotesTable';

function QuotesTable({
	data,
	error,
	openMoreInDefault,
	updatePagination,
	quotes,
	quoteSlice,
	fetchData,
	location,
	updateSorting,
	updateFilters,
	clearFilters,
	companyConfig,
	currentUser,
	downloadQuotesCSV,
	CustomFooter,
	CustomHeader,
	renderQuote,
	tableStyle,
	history,
	renderEmptyState,
	quoteDownloadFormats,
	downloadQuoteFormatCSV,
	fetchQuoteDownloadFormats,
	targetCollectionName,
	userType,
	locations,
	supplierFacilities,
	fetchSupplierFacilitiesInPrivateNetwork,
	filterConfig,
	enableViews,
	replaceFiltersAndSorting,
	preAppliedFilters,
	enableMultiSelect,
	updateSelections,
	updateQuoteStatuses,
	errors,
	enableBannerMessages,
	//loading,
	enableMessaging,
	backText,
}) {
	const {
		filters: activeFilters,
		pagination,
		sorting: activeSorters,
	} = quotes || standardInitialStateGenerator([targetCollectionName]);
	React.useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const preserve = queryParams.has('preserve');

		if (!preserve || !(data && data.length)) {
			fetchData(location);
		}
	}, []);

	//Statuses
	const { creating, archiving, unarchiving, fetching, updating } = quoteSlice;
	if (enableMessaging) {
		updating
			? message.open({
					key: 'quoteTableMessageUpdating',
					type: 'loading',
					content: 'Updating...',
					duration: 60,
			  })
			: message.destroy('quoteTableMessageUpdating');
	}

	const COMMON_FILTER_CONFIGS = useMemo(
		() => [
			{
				label: 'Location',
				fieldName: FILTER_CONFIG_NAMES.LOCATION,
				mode: 'multiple',
				stateSlice: locations,
				hideFilter: true,
				filtersValueAccessor: (activeFilters) => {
					const locationIds = nullSafeGet(`locationIds`, activeFilters);
					const finalIds = nullSafeGetOrElse(`regionIds`, activeFilters, locationIds);
					return changeFilterValueToArrayOfIds(finalIds);
				},
				keyAccessor: (loc) => loc.id,
				valueAccessor: (loc) => loc.id,
				handleChange: (ids) => {
					updateFilters({
						locationIds: ids.join(',') || undefined,
						regionIds: undefined,
					});
				},
				targetCollectionName: 'quotesAdvancedFilters',
				fetchMultiple: () => {},
				renderItem: (loc) => loc.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
			},
			{
				label: 'Created Between',
				labelPrefix: 'Created',
				fieldName: FILTER_CONFIG_NAMES.CREATED_AT,
				type: FILTER_FIELD_TYPE.DATE_RANGE,
				filtersValueAccessor: (filtersStateSlice) => {
					const startDate =
						filtersStateSlice[getStartDateFieldName(FILTER_CONFIG_NAMES.CREATED_AT)];
					const endDate = filtersStateSlice[getEndDateFieldName(FILTER_CONFIG_NAMES.CREATED_AT)];
					return [startDate && moment(startDate), endDate && moment(endDate)];
				},
				handleChange: (values) => {
					updateFilters({
						[getStartDateFieldName(FILTER_CONFIG_NAMES.CREATED_AT)]:
							values && values.length > 1 && values[0] ? formatDateForParam(values[0]) : undefined,
						[getEndDateFieldName(FILTER_CONFIG_NAMES.CREATED_AT)]:
							values && values.length > 1 && values[1] ? formatDateForParam(values[1]) : undefined,
					});
				},
				renderRecord: (item) => item,
				valueAccessor: (item) => item,
				component: OWDateRangePicker,
			},
			{
				label: 'Approved Between',
				labelPrefix: 'Approved',
				fieldName: FILTER_CONFIG_NAMES.APPROVED_AT,
				type: FILTER_FIELD_TYPE.DATE_RANGE,
				filtersValueAccessor: (filtersStateSlice) => {
					const startDate =
						filtersStateSlice[getStartDateFieldName(FILTER_CONFIG_NAMES.APPROVED_AT)];
					const endDate = filtersStateSlice[getEndDateFieldName(FILTER_CONFIG_NAMES.APPROVED_AT)];
					return [startDate && moment(startDate), endDate && moment(endDate)];
				},
				handleChange: (values) => {
					updateFilters({
						[getStartDateFieldName(FILTER_CONFIG_NAMES.APPROVED_AT)]:
							values && values.length > 1 && values[0] ? formatDateForParam(values[0]) : undefined,
						[getEndDateFieldName(FILTER_CONFIG_NAMES.APPROVED_AT)]:
							values && values.length > 1 && values[1] ? formatDateForParam(values[1]) : undefined,
					});
				},
				renderRecord: (item) => item,
				valueAccessor: (item) => item,
				component: OWDateRangePicker,
			},
		],
		[locations, updateFilters]
	);
	let actions = (
		userType === 'buyer'
			? QUOTE_MULTI_SELECT_ACTION_CONFIG_FOR_BUYER
			: QUOTE_MULTI_SELECT_ACTION_CONFIG_FOR_SUPPLIER
	).map((action) => {
		return {
			...action,
			fn: () =>
				updateQuoteStatuses(
					quotes.selections.map((record) => record.id),
					action.toStatus,
					Object.keys(quotes.selections[0])
				)
					.then((data) => {
						message.destroy('quoteTableMessageUpdating');
						message.open({
							key: 'quoteTableSuccess',
							type: 'success',
							content: data.length.toString().concat(' records updated'),
							duration: 2,
						});
					})
					.catch((error) => {
						message.open({
							key: 'quoteTableError',
							type: 'error',
							content: error,
							duration: 10,
						});
					}),
		};
	});

	let selectItems = enableMultiSelect
		? {
				onSelectCurrentPage: (records) => {
					let keys = unionWith(
						quotes.selections,
						records.map((rec) => getSelectionObject(rec)),
						isEqual
					);
					updateSelections(targetCollectionName, keys);
				},
				onDeselectCurrentPage: (records) => {
					let keys = differenceWith(
						quotes.selections,
						records.map((rec) => getSelectionObject(rec)),
						isEqual
					);
					updateSelections(targetCollectionName, keys);
				},
				onSelectNone: (e) => {
					updateSelections(targetCollectionName, []);
				},
				selections: quotes.selections,
				rowSelection: {
					onSelect: (record, selected, selectedRows, e) => {
						let isSelected = quotes.selections
							.map((item) => item.id)
							.includes(record.id.toString());
						let keys = !isSelected
							? unionWith(quotes.selections, [getSelectionObject(record)], isEqual)
							: differenceWith(quotes.selections, [getSelectionObject(record)], isEqual);
						updateSelections(targetCollectionName, keys);
					},
					selectedRowKeys: quotes.selections.map((item) => item.id),
					preserveSelectedRowKeys: true,
				},
		  }
		: null;

	const BUYER_FILTER_CONFIGS = useMemo(
		() => [
			{
				label: 'Supplier',
				fieldName: 'supplierFacilityIds',
				mode: 'multiple',
				stateSlice: supplierFacilities,
				filtersValueAccessor: (filtersStateSlice) =>
					changeFilterValueToArrayOfIds(nullSafeGet(`supplierFacilityIds`, filtersStateSlice)),
				keyAccessor: (supplierFacility) => supplierFacility.id,
				valueAccessor: (supplierFacility) => supplierFacility.id,
				handleChange: (ids) => {
					updateFilters({
						supplierFacilityIds: ids.join(',') || undefined,
					});
				},
				targetCollectionName: 'quotesAdvancedFilters',
				fetch: fetchSupplierFacilitiesInPrivateNetwork,
				fetchMultiple: () => {},
				renderItem: (supplierFacility) => supplierFacility.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
				component: OWAsyncTreeSelect,
			},
			{
				fieldName: 'isCapex',
				type: 'radioGroup',
				items: [
					{
						label: 'All',
						value: undefined,
					},
					{
						label: 'R&M',
						value: 'false',
					},
					{
						label: 'CapEx',
						value: 'true',
					},
				],
				filtersValueAccessor: (filtersStateSlice) => {
					const val = filtersStateSlice.isCapex;
					return typeof val === 'undefined' ? undefined : `${val}`;
				},
				keyAccessor: (item) => item.value,
				valueAccessor: (item) => item.value,
				handleChange: (isCapex) => {
					const val = isCapex ? isCapex === 'true' : undefined;
					updateFilters({ isCapex: val });
				},
				targetCollectionName: 'workOrdersAdvancedFilters',
				renderRecord: (item) => item.label,
				component: OWRadioGroup,
				info: [
					{
						title: 'Repair and Maintenance (R&M)',
						description: 'Costs associated with repair and maintenance of existing assets',
					},
					{
						title: 'Capital Expenditures (CapEx)',
						description: 'Costs associated with buying new assets or upgrading existing assets',
					},
				],
			},
		],
		[supplierFacilities, fetchSupplierFacilitiesInPrivateNetwork, updateFilters]
	);

	const FILTER_CONFIG = useMemo(() => {
		const defaultConfig: any[] =
			userType === ROLE_TYPES.SUPPLIER
				? [...COMMON_FILTER_CONFIGS, ...(isInternalTech(currentUser) ? BUYER_FILTER_CONFIGS : [])]
				: [...COMMON_FILTER_CONFIGS, ...BUYER_FILTER_CONFIGS];
		const advancedFilterConfig =
			filterConfig && filterConfig.filters && filterConfig.filters.length > 0
				? filterConfig.filters.map((filter) => defaultConfig.find((_) => _.fieldName === filter))
				: defaultConfig;
		return {
			...(filterConfig || {}),
			filters: advancedFilterConfig.filter((_) => !!_),
		};
	}, [userType, filterConfig, COMMON_FILTER_CONFIGS, BUYER_FILTER_CONFIGS]);
	return (
		<div>
			{CustomHeader ? (
				<CustomHeader
					applySorting={updateSorting}
					applyFilters={(e) => updateFilters(e)}
					filters={activeFilters}
					sorters={activeSorters}
					downloadCSV={() => downloadQuotesCSV({}, activeFilters)}
					counts={quoteSlice.counts}
					filterConfig={FILTER_CONFIG}
					clearFilters={clearFilters}
					targetCollectionName={targetCollectionName}
					userType={userType}
					pagination={pagination}
				/>
			) : (
				<TableHeader
					applySorting={updateSorting}
					applyFilters={(e) => updateFilters(e)}
					filters={activeFilters}
					sorters={activeSorters}
					downloadCSV={() => downloadQuotesCSV({}, activeFilters)}
					counts={quoteSlice.counts}
					filterConfig={FILTER_CONFIG}
					clearFilters={clearFilters}
					targetCollectionName={targetCollectionName}
					enableViews={enableViews}
					userType={userType}
					applyFiltersFromView={replaceFiltersAndSorting}
					preAppliedFilters={preAppliedFilters}
				/>
			)}

			<div style={{ marginTop: 16, marginLeft: 4, background: 'white' }}>
				{enableBannerMessages &&
				nullSafeGet('filters.statuses', quoteSlice[targetCollectionName]) === 'Draft' ? (
					<Row>
						<Alert
							style={{ width: '100%', padding: '8px 9px', borderWidth: 0 }}
							type={'warning'}
							showIcon
							message={
								<span style={{ paddingLeft: '16px' }}>
									Drafted quotes can't be seen by your customers. Please make sure to submit your
									quote once work has been completed.
								</span>
							}
						/>
					</Row>
				) : null}
				{enableMultiSelect ? (
					<TableSelectionRow
						selectItems={selectItems}
						actions={actions}
						records={data}
						counts={quoteSlice.counts}
						updatePagination={updatePagination}
						pagination={pagination}
						updating={updating}
						errors={errors}
					/>
				) : null}
				<QuotesListTable
					fetching={fetching}
					data={data}
					currentUser={currentUser}
					companyConfig={companyConfig}
					renderQuote={renderQuote}
					refreshTable={fetchData}
					tableStyle={tableStyle}
					renderEmptyState={renderEmptyState}
					location={location}
					userType={userType}
					selectItems={selectItems}
					enableMultiSelect={enableMultiSelect}
					backText={backText}
					targetCollectionName={targetCollectionName}
				/>
				{CustomFooter ? (
					<CustomFooter
						updatePagination={updatePagination}
						pagination={{ ...pagination, current: 1, pageSize: 3 }}
						filters={activeFilters}
						sorter={activeSorters}
						location={location}
						history={history}
						enableMultiSelect={enableMultiSelect}
						openMoreInDefault={openMoreInDefault}
						userType={userType}
					/>
				) : (
					<Row justify="end">
						<TableFooter
							records={data}
							//enableMultiSelect={enableMultiSelect}
							updatePagination={updatePagination}
							pagination={
								pagination && pagination.total && pagination.total !== 0 ? pagination : null
							}
							//selectItems={selectItems}
						/>
					</Row>
				)}
			</div>
		</div>
	);
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	handleQueryParams: ownProps.handleQueryParams,
	locations: state.locations,
	currentUser: state.session.currentUser,
	errors: state.session.errors,
	loading: state.session.loading,
	spendCategories: state.spend_categories,
	problemTypes: state.problem_types,
	regions: state.regions,
	areas: state.areas,
	supplierFacilities: state.supplier_facilities,
	quotes: state.proposals[ownProps.targetCollectionName || TARGETCOLLECTIONNAME],
	quoteSlice: state.proposals,
	companyConfig: state.company_config.detail,
	targetCollectionName: ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
	data: getEntitiesById(
		state.proposals[ownProps.targetCollectionName || TARGETCOLLECTIONNAME]
			? state.proposals[ownProps.targetCollectionName || TARGETCOLLECTIONNAME].recordsIds || []
			: [],
		state.proposals.records
	),
	quoteDownloadFormats: state.quote_download_formats,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchData: (location) =>
		dispatch(
			fetchQuotes(ownProps.userType)(
				ownProps.handleQueryParams,
				ownProps.history,
				location,
				ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
				ownProps.preAppliedFilters,
				ownProps.initialPagination,
				ownProps.initialSorting,
				ownProps.initialFilters,
				ownProps.ignoreRefreshCounts
			)
		),
	updatePagination: (pagination) =>
		dispatch(
			updatePaginationQuotes(ownProps.userType)(
				ownProps.handleQueryParams,
				ownProps.history,
				ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
				pagination,
				ownProps.ignoreRefreshCounts
			)
		),
	updateSorting: (sorting) =>
		dispatch(
			updateSortingQuotes(ownProps.userType)(
				ownProps.handleQueryParams,
				ownProps.history,
				ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
				sorting,
				ownProps.ignoreRefreshCounts
			)
		),
	updateFilters: (filters) =>
		dispatch(
			updateFiltersQuotes(ownProps.userType)(
				ownProps.handleQueryParams,
				ownProps.history,
				ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
				filters,
				ownProps.ignoreRefreshCounts
			)
		),
	updateSelections: (targetCollectionName, selections) =>
		dispatch(
			updateSelection(ownProps.userType)(
				ownProps.handleQueryParams,
				ownProps.history,
				ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
				selections
			)
		),
	replaceFiltersAndSorting: (filters, sorting) =>
		dispatch(
			replaceFiltersAndSortingQuotes(ownProps.userType)(
				ownProps.handleQueryParams,
				ownProps.history,
				ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
				filters,
				sorting,
				ownProps.ignoreRefreshCounts
			)
		),
	clearFilters: () =>
		dispatch(
			clearFiltersQuotes(ownProps.userType)(
				ownProps.handleQueryParams,
				ownProps.history,
				ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
				ownProps.preAppliedFilters,
				ownProps.ignoreRefreshCounts
			)
		),
	fetchSupplierFacilitiesInPrivateNetwork: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) => {
		const fnToCall =
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? fetchSupplierFacilitiesInPrivateNetworkForSupplier
				: fetchSupplierFacilitiesInPrivateNetworkForBuyer;
		return dispatch(
			fnToCall(
				params,
				targetCollectionName || TARGETCOLLECTIONNAME,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		);
	},
	updateQuoteStatuses: (ids, status, quoteParams) =>
		dispatch(
			updateQuoteStatusBulkWrapper(ownProps.userType)(
				ids,
				status,
				TARGETCOLLECTIONNAME,
				quoteParams
			)
		),
});

const QuotesHOC = withRouter(connect(mapStateToProps, mapDispatchToProps)(QuotesTable));

export default connect(
	(state) => ({
		userType: state.session.userType,
	}),
	(dispatch, ownProps) => ({})
)(QuotesHOC);

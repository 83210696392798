import * as React from 'react';
import { Layout, Row, Col, Card, Button, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import {
	invoicesRestCrudThunksForSupplier,
	markInvoicePaidForSupplier,
} from '../../thunks/invoices_thunks';

import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { Link } from 'react-router-dom';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import InvoiceUploader from '../invoice_uploader/InvoiceUploader';

const { Content } = Layout;

class SupplierWorkOrderInvoicesDetailPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const { getInvoices, match } = this.props;
		const workOrderId = match.params.id;
		getInvoices({ workOrderId }, 'workOrderAssociatedInvoices');
	}

	handlePaidInvoice = (invoice) => {
		const { markInvoicePaid } = this.props;
		markInvoicePaid(invoice.id).then(() => {
			message.success(`Invoice paid.`);
		});
	};

	handleFileAttachmentUploadChange = (invoice) => (invoiceFileUrl) => {
		const { match, getInvoices, updateInvoice } = this.props;
		const newInvoice = {
			...invoice,
			invoicePDFLink: invoiceFileUrl,
		};
		updateInvoice(newInvoice).then(() => {
			const workOrderId = match.params.id;
			setTimeout(() => getInvoices({ workOrderId }, 'workOrderAssociatedInvoices'), 100);
		});
	};

	render() {
		const { invoices, invoicesFetching, match } = this.props;
		const workOrderId = match.params.id;

		const associatedInvoices = getRecordsForTargetCollection(
			invoices,
			'workOrderAssociatedInvoices'
		);
		return invoicesFetching ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="invoicesDetailDetailsPage">
				<LogOnMountWithStandardEventProperties eventType="visited supplier work order invoices page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<div>
					{associatedInvoices.map((invoice) => (
						<Row style={{ margin: '16px 8px' }} gutter={12} key={invoice.id}>
							<Col span={8}>
								{invoicesFetching ? null : (
									<div className="rowSpacing">
										<Card title="Your Attachments">
											{invoice.invoicePDFLink && invoice.invoicePDFLink.length > 0 ? (
												<p>
													<a href={invoice.invoicePDFLink} target="_blank" rel="noreferrer">
														Invoice PDF
													</a>
													<Button
														type="link"
														onClick={() =>
															this.handleFileAttachmentUploadChange(invoice)(undefined)
														}
													>
														<i className="icons8-font icons8-trash-can"></i>
													</Button>
												</p>
											) : null}
											<div style={{ marginBottom: 16 }}>
												<InvoiceUploader
													roleType="supplier"
													invoiceId={invoice.id}
													defaultFileList={[]}
													multiple={false}
													handleUploadSuccess={this.handleFileAttachmentUploadChange(invoice)}
												/>
											</div>
											{invoice.invoicePDFLink && invoice.invoicePDFLink.length > 0 ? (
												<Link to={`/supplier/workOrders/detail/${workOrderId}/invoices`}>
													<Button type="primary">Go to invoice</Button>
												</Link>
											) : null}
										</Card>
									</div>
								)}
							</Col>
						</Row>
					))}
				</div>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	invoicesFetching: state.invoices.fetching,
	invoices: state.invoices,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	getInvoices: (params, targetCollectionName) =>
		dispatch(invoicesRestCrudThunksForSupplier.read(params, targetCollectionName)),
	updateInvoice: (entity) => dispatch(invoicesRestCrudThunksForSupplier.update(entity)),
	markInvoicePaid: (id) => dispatch(markInvoicePaidForSupplier({ id })),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SupplierWorkOrderInvoicesDetailPage)
);

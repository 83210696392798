import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS } from '../actions/equipment_per_stock_locations_actions';
import { getBackendUri } from '../utils/EnvConfigUtils';
import FetchUtils from './fetch_utils';
import { parseJSON } from './fetch_utils';
import { logoutSuccess } from '../actions/session_actions';
import { message } from 'antd';

const fetchUtils = new FetchUtils();

export const equipmentPerStockLocationsRestCrudThunksForBuyer = new RestCrudThunks(
	'equipment_per_stock_locations',
	'equipment_per_stock_location',
	'equipmentPerStockLocation',
	'equipmentPerStockLocations',
	EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'inventory/'
);

export const equipmentPerStockLocationsRestCrudThunksForSupplier = new RestCrudThunks(
	'equipment_per_stock_locations',
	'equipment_per_stock_location',
	'equipmentPerStockLocation',
	'equipmentPerStockLocations',
	EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'inventory/'
);

const moveAssetToEquipment = (roleType) => (assetId, toStockLocationId, data) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/inventory/equipment_per_stock_locations/from_asset/${assetId}/toStockLocation/${toStockLocationId}`;

	// if sorting present, fix order naming and add to query params

	return (dispatch) => {
		const entity = {};
		return new Promise((resolve, reject) => {
			dispatch(EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.post(
					baseUrl + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data,
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(
							EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateError(d.message, entity)
						);
						reject(d.message);
					} else {
						dispatch(EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(
						EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.createError(d.message, entity)
					);
					reject(d.message);
				});
		});
	};
};

export const moveAssetToEquipmentPerStockLocationForSupplier = moveAssetToEquipment(
	ROLE_TYPES.SUPPLIER
);

export const bulkTransferEquipmentsPerStockLocaction = (roleType) => (entities) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/inventory/equipment_per_stock_locations/bulk_transfer `;

	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateStart({}));

			fetchUtils
				.post(
					baseUrl + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entities,
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(
							EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateError(d.message, d.data)
						);
						reject(d.message);
					} else {
						dispatch(EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(
						EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateError(d.message, d.data)
					);
					reject(d.message);
				});
		});
	};
};

export const bulkTransferEquipmentsPerStockLocactionForSupplier =
	bulkTransferEquipmentsPerStockLocaction(ROLE_TYPES.SUPPLIER);

export const bulkTransferEquipmentTypeFromStockLocation = (roleType) => (entities) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/inventory/equipment_per_stock_locations/bulk_transfer_by_equipment_type_and_stock_location`;

	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateStart({}));

			fetchUtils
				.post(
					baseUrl + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entities,
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(
							EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateError(d.message, d.data)
						);
						reject(d.message);
					} else {
						dispatch(EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(
						EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateError(d.message, d.data)
					);
					reject(d.message);
				});
		});
	};
};

export const bulkTransferEquipmentTypeFromStockLocationForSupplier =
	bulkTransferEquipmentTypeFromStockLocation(ROLE_TYPES.SUPPLIER);

export const equipmentPerStockLocationCsvReportGenerator = (roleType) => (params) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/inventory/equipment_per_stock_locations/csv_report_generator`;

	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(
					baseUrl + apiUrl,
					Object.assign(
						{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
						params
					)
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
						message.error(d.data);
					} else {
						resolve(d.data);
						const hide = message.loading(d.data, 0);
						setTimeout(hide, 7000);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const equipmentPerStockLocationCsvReportGeneratorSupplier =
	equipmentPerStockLocationCsvReportGenerator(ROLE_TYPES.SUPPLIER);

const fetchAllEquipmentPerStockLocaction =
	(roleType) =>
	(params, sorting = null) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/inventory/all_equipment_per_stock_locations`;

		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.data);
						} else {
							resolve(d.data);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};

export const fetchAllEquipmentPerStockLocactionForSupplier = fetchAllEquipmentPerStockLocaction(
	ROLE_TYPES.SUPPLIER
);

const updateMultipleEpsl = (roleType) => (entities) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/inventory/equipment_per_stock_locations/update_multiple`;

	// if sorting present, fix order naming and add to query params

	return (dispatch) => {
		const entity = {};
		return new Promise((resolve, reject) => {
			dispatch(EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.put(
					baseUrl + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entities,
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(
							EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateError(d.message, entity)
						);
						reject(d.message);
					} else {
						dispatch(EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(
						EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.createError(d.message, entity)
					);
					reject(d.message);
				});
		});
	};
};

export const updateMultipleEpslForSupplier = updateMultipleEpsl(ROLE_TYPES.SUPPLIER);

const createMultipleEpslAsDeleted = (roleType) => (entities) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/inventory/equipment_per_stock_locations/create_multiple_as_deleted`;

	// if sorting present, fix order naming and add to query params

	return (dispatch) => {
		const entity = {};
		return new Promise((resolve, reject) => {
			dispatch(EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.post(
					baseUrl + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entities,
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(
							EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateError(d.message, entity)
						);
						reject(d.message);
					} else {
						dispatch(EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(
						EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.createError(d.message, entity)
					);
					reject(d.message);
				});
		});
	};
};

export const createMultipleEpslAsDeletedForSupplier = createMultipleEpslAsDeleted(
	ROLE_TYPES.SUPPLIER
);

const bulkTransferAssetsToStockLocations = (roleType) => (data) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/inventory/equipment_per_stock_locations/bulk_transfer_assets`;

	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateStart({}));

			fetchUtils
				.post(
					baseUrl + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data,
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateError(d.message, {}));
						reject(d.message);
					} else {
						dispatch(EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateError(d.message, {}));
					reject(d.message);
				});
		});
	};
};

export const bulkTransferAssetsToStockLocationsForSupplier = bulkTransferAssetsToStockLocations(
	ROLE_TYPES.SUPPLIER
);

const getAssetsBulkTransferToStockLocationStatus = (roleType) => () => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/inventory/equipment_per_stock_locations/bulk_transfer_assets/status`;

	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(
					baseUrl + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
						message.error(d.data);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const getAssetsBulkTransferToStockLocationStatusForSupplier =
	getAssetsBulkTransferToStockLocationStatus(ROLE_TYPES.SUPPLIER);

import { message } from 'antd';

export const getResizedImageUrl = (filePath, width, height) =>
	`https://images.emptor.ai/fit-in/${width}x${height}/${filePath}`;

export const normFile = (e) => {
	if (Array.isArray(e)) {
		return e;
	}
	return e && e.fileList;
};

export const imageMimeTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'];

export function imageUploadValidation(file) {
	const isImage = imageMimeTypes.indexOf(file.type) !== -1;
	if (!isImage) {
		message.error('You can only upload jpeg, png, gif or svg image files!');
	}
	const isLt20M = file.size / 1024 / 1024 < 20;
	if (!isLt20M) {
		message.error('Image file size must smaller than 20MB!');
	}
	return isImage && isLt20M;
}

const hRange = [0, 360];
const sRange = [0, 100];
const lRange = [0, 100];

const getHashOfString = (str: string) => {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	hash = Math.abs(hash);
	return hash;
};

const normalizeHash = (hash: number, min: number, max: number) => {
	return Math.floor((hash % (max - min)) + min);
};

const generateHSL = (name: string) => {
	const hash = getHashOfString(name);
	const h = normalizeHash(hash, hRange[0], hRange[1]);
	const s = normalizeHash(hash, sRange[0], sRange[1]);
	const l = normalizeHash(hash, lRange[0], lRange[1]);
	return [h, s, l];
};

const HSLtoString = (hsl) => {
	return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};

export const getAvatarStyle = (name) => {
	const hsl = generateHSL(name || '');
	return {
		backgroundColor: HSLtoString(hsl),
		color: hsl[2] > 50 ? '#000' : '#FFF',
	};
};

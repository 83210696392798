import * as React from 'react';
import { Layout, Button, List } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import { assetModelsRestCrudThunksForSupplier } from '../../thunks/asset_models_thunks';

const style = require('./SupplierAssetModelsDetailPage.less');
const ListItem = List.Item;
const ListItemMeta = ListItem.Meta;
const { Content } = Layout;

interface SupplierAsseModelsDetailPageState {}

interface SupplierAssetModelsDetailPageProps {
	match: any;
}

class SupplierAssetModelsDetailPage extends React.Component<
	any,
	SupplierAsseModelsDetailPageState
> {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const { getAssetModel, match } = this.props;
		const assetModelId = match.params.id;
		getAssetModel(assetModelId);
	}

	handleTabChange = (key) => {
		const { history, match } = this.props;
		const assetModelId = match.params.id;
		history.push(`/supplier/assets/models/detail/${assetModelId}/${key}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	handleDelete = () => {
		const { history, deleteAssetModel, assetModel } = this.props;
		deleteAssetModel(assetModel).then(() => {
			history.push(`/supplier/assets/templates/detail/${assetModel.assetTypeId}`);
		});
	};

	render() {
		const { assetModel, assetModelsFetching, companyConfig, match } = this.props;
		const assetModelId = match.params.id;
		const tabs = [
			{
				key: 'details',
				name: 'Overview',
			},
		];
		return (
			<div>
				<DetailPageHeader
					exactPath={'/supplier/assets/models/detail/:id'}
					redirectPath={`/supplier/assets/models/detail/${assetModelId}/details`}
					backLinkText={'Back to asset models'}
					backLinkTo={`/supplier/assets/templates/detail/${assetModel.assetTypeId}`}
					title={assetModelsFetching ? null : assetModel.modelName}
					subtitle={null}
					actions={[
						<Link
							key={1}
							to={`/supplier/assets/models/edit/${assetModelId}`}
							style={{ marginRight: 16 }}
						>
							<Button ghost={true}>Edit</Button>
						</Link>,
						<Button key={2} ghost={true} onClick={this.handleDelete}>
							Delete
						</Button>,
					]}
					tabs={tabs}
					backgroundColor={nullSafeGet('detail.config.theme.navblock', companyConfig)}
					handleTabChange={this.handleTabChange}
					checkTabActive={this.checkTabActive}
					childRoutes={this.props.childRoutes}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	assetTypesFetching: state.asset_types.fetching,
	assetType: state.asset_types.detail,
	companyConfig: state.company_config,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	getAssetModel: (id) => dispatch(assetModelsRestCrudThunksForSupplier.readOne(id)),
	deleteAssetModel: (entity) =>
		dispatch(assetModelsRestCrudThunksForSupplier.delete(entity, 'id', null, true)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SupplierAssetModelsDetailPage)
);

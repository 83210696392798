import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Modal, Alert, Spin, Button, Select, Steps, Card } from 'antd';
import BackButton from '../back_button/BackButton';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { imageUploadValidation, normFile } from '../../utils/ImageUtils';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { locationsRestCrudThunksForBuyer } from '../../thunks/locations_thunks';
import { debounce } from '../../utils/PerformanceUtils';
import { assetsRestCrudThunksForBuyer } from '../../thunks/assets_thunks';
import { problemTypesRestCrudThunksForBuyer } from '../../thunks/problem_types_thunks';
import { getProtectedImageUriForBuyer } from '../../utils/FileAccessUtils';
import { spendCategoriesRestCrudThunksForBuyer } from '../../thunks/spend_categories_thunks';
import { workOrderAttributesRestCrudThunksForBuyer } from '../../thunks/work_order_attributes_thunks';
import { workOrderPrioritiesRestCrudThunksForBuyer } from '../../thunks/work_order_priorities_thunks';
import { workOrdersRestCrudThunksForBuyer } from '../../thunks/work_orders_thunks';
import moment from 'moment';
import { TwoLineList } from '../two_line_list/TwoLineList';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { WorkOrderRowDisplay } from '../work_order_row_display/WorkOrderRowDisplay';
import { WORK_ORDERS_CRUD_ACTION_CREATORS } from '../../actions/work_orders_actions';
import { ProtectedImageThumbnails } from '../protected_image_thumbnails/ProtectedImageThumbnails';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { unionBy } from 'lodash';
import {
	fetchSupplierFacilitiesInPrivateNetworkForBuyer,
	supplierFacilitiesRestCrudThunksForBuyer,
} from '../../thunks/supplier_facilities_thunks';
import FileUploader from '../file_uploader/FileUploader';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { buyersRestCrudThunksForBuyer } from '../../thunks/buyers_thunks';
import { retrieveCachedUserDetails } from '../../thunks/session_thunks';
import AssetNumberDisplay from '../asset_row_display/AssetNumberDisplay';
import OWUpload from '../OWUpload';

const style = require('./WorkOrderForm.less');

const WORK_ORDER_FORM_LOCATIONS_AUTOCOMPLETE = 'WORK_ORDER_FORM_LOCATIONS_AUTOCOMPLETE';
const WORK_ORDER_FORM_ASSETS_AUTOCOMPLETE = 'WORK_ORDER_FORM_ASSETS_AUTOCOMPLETE';
const WORK_ORDER_FORM_PROBLEM_TYPES_AUTOCOMPLETE = 'WORK_ORDER_FORM_PROBLEM_TYPES_AUTOCOMPLETE';
const WORK_ORDER_FORM_SPEND_CATEGORIES_AUTOCOMPLETE =
	'WORK_ORDER_FORM_SPEND_CATEGORIES_AUTOCOMPLETE';
const WORK_ORDER_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE =
	'WORK_ORDER_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE';
const WORK_ORDER_FORM_SUPPLIER_PRIVATE_NETWORKS_AUTOCOMPLETE =
	'WORK_ORDER_FORM_SUPPLIER_PRIVATE_NETWORKS_AUTOCOMPLETE';
const WORK_ORDER_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE =
	'WORK_ORDER_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE';

const Step = Steps.Step;

interface WorkOrderFormProps extends FormComponentProps {
	history: any;
	onSubmit: any;
	onCancel: any;
	redirectForwardUrl?: string;
	onSuccess?: any;
	creating: boolean;
	updating: boolean;
	formData: any;
	createErrors: any[];
	updateErrors: any[];
	companyConfig: any;
	fetchLocation: any;
	fetchLocations: any;
	fetchAsset: any;
	fetchMultipleAssets: any;
	fetchAssets: any;
	fetchBuyer: any;
	fetchMultipleLocations: any;
	locations: any;
	assetsFetching: any;
	assets: any;
	fetchProblemType: any;
	fetchMultipleProblemTypes: any;
	fetchProblemTypes: any;
	problemTypesFetching: any;
	problemTypes: any;
	fetchSpendCategory: any;
	fetchMultipleSpendCategories: any;
	fetchSpendCategories: any;
	spendCategories: any;
	spendCategoriesFetching: any;
	fetchWorkOrderAttributes: any;
	workOrderPriorities: any;
	fetchWorkOrderPriority: any;
	fetchWorkOrderPriorities: any;
	workOrderPrioritiesFetching: any;
	supplierFacilities: any;
	supplierFacilitiesFetching: any;
	fetchSupplierFacility: any;
	fetchSupplierFacilitiesInPrivateNetwork: any;
	currentUser: any;
	submitWorkOrderForm: any;
}

class NewWorkOrderForm extends React.Component<WorkOrderFormProps, any> {
	constructor(props) {
		super(props);
		this.state = {
			activeStep: 1,
			totalSteps: 3,
			previewVisible: false,
			uploadLoading: false,
			previewImage: false,
			location: null,
			asset: null,
			problemType: null,
			spendCategory: null,
			inputValues: {},
			nteAmount: 0,
			workOrderPriorityId: null,
			workOrderPriority: null,
			photoInfo: [],
			allPhotos: [],
			supplier: null,
			industryId: 1,
		};
		this.goNext = this.goNext.bind(this);
		this.goPrev = this.goPrev.bind(this);
	}

	componentDidMount() {
		const {
			formData,
			currentUser,
			fetchLocation,
			fetchLocations,
			fetchAsset,
			fetchAssets,
			fetchBuyer,
			fetchProblemType,
			fetchMultipleProblemTypes,
			fetchProblemTypes,
			fetchSpendCategory,
			fetchMultipleSpendCategories,
			fetchSpendCategories,
			fetchWorkOrderPriority,
			fetchWorkOrderPriorities,
			fetchSupplierFacilitiesInPrivateNetwork,
		} = this.props;

		fetchBuyer(nullSafeGet('facility.buyerCompanyId', currentUser)).then((buyer) => {
			this.setState({ industryId: buyer.industryId });
		});

		const locationId = formData.locationId;
		const assetId = formData.assetId;
		const problemTypeId = formData.problemTypeId || 1;
		const spendCategoryId = formData.spendCategoryId;
		const workOrderPriorityId = formData.workOrderPriorityId;

		fetchLocations({});
		// TODO: HYDRATE THESE IN THE ROUTE AND REMOVE THIS SHIT
		if (locationId) {
			fetchLocation(locationId).then((l) => this.setState({ location: l }));
		}

		locationId ? fetchAssets({ locationId: locationId }) : fetchAssets({});

		if (assetId) {
			fetchAsset(assetId).then((a) => this.setState({ asset: a }));
		}

		if (locationId || assetId) {
			locationId && assetId
				? fetchProblemTypes({ locationId: locationId, assetId: assetId })
				: fetchProblemTypes({ locationId: locationId });
		} else {
			fetchProblemTypes({});
		}

		if (problemTypeId) {
			fetchProblemType(problemTypeId).then((p) => this.setState({ problemType: p }));
		}

		fetchWorkOrderPriorities({});

		if (workOrderPriorityId) {
			fetchWorkOrderPriority(workOrderPriorityId).then((w) =>
				this.setState({ workOrderPriority: w })
			);
		}

		if (problemTypeId) {
			fetchSupplierFacilitiesInPrivateNetwork({ problemTypeId, locationId }).then((s) =>
				s.length > 0 ? this.setState({ supplier: s[0] }) : this.setState({ supplier: null })
			);
		} else {
			fetchSupplierFacilitiesInPrivateNetwork({ problemTypeId, locationId });
		}

		let photosInfo;
		if (formData.images) {
			photosInfo = formData.images.map((p, idx) => {
				const [imgFileId, imgFileName] = p.split('/');
				const thumbUri = getProtectedImageUriForBuyer(imgFileId, imgFileName, 100, 100);
				const fullUri = getProtectedImageUriForBuyer(imgFileId, imgFileName, 400, 400);
				return {
					uid: idx,
					size: 1,
					name: imgFileName,
					filename: imgFileName,
					url: fullUri,
					status: 'done',
					thumbUrl: thumbUri,
					response: { data: { fileName: imgFileName, fileId: imgFileId } },
					type: 'image/jpeg',
				};
			});
		} else {
			photosInfo = [];
		}
		this.setState({ allPhotos: photosInfo });
	}

	handleSubmit = () => {
		const { form, history, redirectForwardUrl, onSuccess, currentUser, submitWorkOrderForm } =
			this.props;
		const { location, supplier } = this.state;
		form.validateFields((err, values) => {
			if (!err) {
				// put image data into proper format to persist
				const picturesInfo = this.state.allPhotos.map((p) => {
					const fileName = nullSafeGetOrElse('response.data.fileName', p, '');
					const fileId = nullSafeGetOrElse('response.data.fileId', p, '');
					return `${fileId}/${fileName}`;
				});

				const note = [
					{
						text: nullSafeGetOrElse('notes', values, ''),
						noteAddedAt: values.currentDateTime,
						noteAddedBy: currentUser.email,
					},
				];

				const contractorDetails = {
					contractorContacts: [],
				};

				let workOrder = {
					title: values.title,
					problemTypeId: parseInt(values.problemTypeId, 10),
					woPriorityId: parseInt(values.workOrderPriorityId, 10),
					spendCategoryId: parseInt(values.spendCategoryId, 10),
					locationId: parseInt(values.locationId, 10),
					assetId: values.assetId ? parseInt(values.assetId, 10) : undefined,
					images: picturesInfo,
					nte: values.NTEAmount ? parseFloat(values.NTEAmount) : undefined,
					description: values.additionalInfo,
					buyerFacilityId: nullSafeGet('buyerFacilityId', location),
					buyerCompanyId: values.buyerCompanyId,
					createdBy: values.createdBy,
					supplierFacilityId: supplier ? supplier.id : undefined,
					contractorDetails: contractorDetails,
					notes: note,
					buyerAttachments: values.buyerAttachments,
				};

				submitWorkOrderForm(workOrder).then((record) => {
					if (redirectForwardUrl) {
						history.push(redirectForwardUrl);
					}
					if (onSuccess) {
						onSuccess(record);
					}
				});
			}
		});
	};

	goNext(validationSteps) {
		const { form } = this.props;
		const { activeStep, totalSteps } = this.state;
		form.validateFields(validationSteps, (err, values) => {
			if (err) {
				return;
			}
			if (activeStep >= totalSteps) {
				this.handleSubmit();
			} else {
				this.setState({ activeStep: activeStep + 1 });
			}
		});
	}

	goPrev() {
		const { activeStep } = this.state;
		if (activeStep === 1) {
			return false;
		}
		this.setState({ activeStep: activeStep - 1 });
		return true;
	}

	handlePreview = (obj) => {
		let thumbUrl = null;
		let fullUrl = null;
		let photoInfo = null;
		if (obj.response.data) {
			const imgFileId = obj.response.data.fileId;
			const imgFileName = obj.response.data.fileName;
			thumbUrl = getProtectedImageUriForBuyer(imgFileId, imgFileName, 100, 100);
			fullUrl = getProtectedImageUriForBuyer(imgFileId, imgFileName, 800, 800);
			photoInfo = {
				uid: imgFileId,
				size: 1,
				name: imgFileName,
				filename: imgFileName,
				url: fullUrl,
				status: 'done',
				thumbUrl: thumbUrl,
				response: { data: { fileName: imgFileName, fileId: imgFileId } },
				type: 'image/jpeg',
			};
		}

		this.setState({
			photoInfo: [photoInfo],
			previewImage: fullUrl || thumbUrl,
			previewVisible: true,
		});
	};

	handleCancel = () => this.setState({ previewVisible: false });

	debouncedSearchLocations = debounce(
		(searchText) => {
			const { fetchLocations } = this.props;
			fetchLocations({ name: searchText && searchText.trim() });
		},
		500,
		false
	);

	searchLocations = (searchText) => {
		this.debouncedSearchLocations(searchText);
	};

	handleLocationChange = (locationId) => {
		const { fetchLocation, fetchAssets, fetchProblemTypes } = this.props;
		if (locationId) {
			fetchLocation(locationId).then((loc) =>
				this.setState({
					location: loc,
				})
			);
		}
	};

	debouncedSearchAssets = debounce(
		(searchText) => {
			const { fetchAssets } = this.props;
			const { location } = this.state;
			searchText = searchText && searchText.trim();
			location === null
				? fetchAssets({ search: searchText })
				: fetchAssets({ search: searchText, locationId: location.id });
		},
		500,
		false
	);

	searchAssets = (searchText) => {
		this.debouncedSearchAssets(searchText);
	};

	handleAssetChange = (assetId) => {
		const { fetchAsset, fetchProblemTypes } = this.props;
		const { location } = this.state;
		if (assetId) {
			fetchAsset(assetId).then((asset) => this.setState({ asset: asset }));
			fetchProblemTypes({ locationId: location.id, assetId: assetId });
		}
	};

	debouncedSearchProblemTypes = debounce(
		(searchText) => {
			const { fetchProblemTypes } = this.props;
			const { location, asset } = this.state;
			searchText = searchText && searchText.trim();
			location && asset
				? fetchProblemTypes({
						name: searchText,
						locationId: location.id,
						assetId: asset.id,
				  })
				: location
				? fetchProblemTypes({ name: searchText, locationId: location.id })
				: fetchProblemTypes({ name: searchText });
		},
		500,
		false
	);

	searchProblemTypes = (searchText) => {
		this.debouncedSearchProblemTypes(searchText);
	};

	handleProblemTypeChange = (problemTypeId) => {
		const {
			fetchProblemType,
			fetchWorkOrderAttributes,
			fetchSpendCategory,
			fetchWorkOrderPriority,
			fetchSupplierFacilitiesInPrivateNetwork,
		} = this.props;
		if (problemTypeId) {
			fetchProblemType(problemTypeId).then((problemType) => {
				this.setState({ problemType: problemType });
				if (problemType.spendCategoryId) {
					fetchSpendCategory(problemType.spendCategoryId).then((spendCategory) =>
						this.setState({ spendCategory: spendCategory })
					);
					fetchSupplierFacilitiesInPrivateNetwork({
						spendCategoryId: problemType.spendCategoryId,
						locationId: nullSafeGet('id', this.state.location),
					}).then((s) =>
						s.length > 0 ? this.setState({ supplier: s[0] }) : this.setState({ supplier: null })
					);
				}
				fetchWorkOrderAttributes({ problemTypeId: problemTypeId }).then((workOrderAttributes) => {
					if (workOrderAttributes.length > 0) {
						this.setState({
							nteAmount: workOrderAttributes[0].NTEAmount,
							spendCategoryId: workOrderAttributes[0].spendCategoryId,
							workOrderPriorityId: workOrderAttributes[0].workOrderPriorityId,
						});
						if (workOrderAttributes[0].spendCategoryId) {
							fetchSpendCategory(workOrderAttributes[0].spendCategoryId).then((spendCategory) =>
								this.setState({ spendCategory: spendCategory })
							);
						}
						if (workOrderAttributes[0].workOrderPriorityId) {
							fetchWorkOrderPriority(workOrderAttributes[0].workOrderPriorityId).then(
								(workOrderPriority) => this.setState({ workOrderPriority: workOrderPriority })
							);
						}
						fetchSupplierFacilitiesInPrivateNetwork({
							spendCategoryId: nullSafeGet('spendCategoryId', problemType),
							locationId: nullSafeGet('id', this.state.location),
						}).then((s) =>
							s.length > 0 ? this.setState({ supplier: s[0] }) : this.setState({ supplier: null })
						);
					}
				});
			});
		}
	};

	handleImageUpdate = (info) => {
		if (info.file.status === 'done') {
			this.setState({ allPhotos: info.fileList });
		} else if (info.file.status === undefined) {
			info.fileList = this.state.allPhotos;
		}
	};

	handleInputValueChangeDebounced = (inputKey) =>
		debounce(
			(inputString) => {
				let newInputValues = this.state.inputValues;
				newInputValues[`${inputKey}`] = inputString;
				this.setState({ inputValues: newInputValues });
			},
			500,
			false
		);

	handleInputValueChange = (inputKey) => (e) => {
		const inputString = e.target.value;
		this.handleInputValueChangeDebounced(inputKey)(inputString);
	};

	handleNTEValueChangeDebounced = () =>
		debounce(
			(nteAmount) => {
				this.setState({ nteAmount: nteAmount });
			},
			500,
			false
		);

	handleNTEValueChange = (e) => {
		const inputString = e.target.value;
		this.handleNTEValueChangeDebounced()(inputString);
	};

	debouncedSearchSpendCategories = debounce(
		(searchText) => {
			const { fetchSpendCategories } = this.props;
			fetchSpendCategories({
				name: searchText && searchText.trim(),
			});
		},
		500,
		false
	);

	searchSpendCategories = (searchText) => {
		this.debouncedSearchSpendCategories(searchText);
	};

	handleSpendCategoryChange = (spendCategoryId) => {
		const {
			fetchWorkOrderAttributes,
			fetchSpendCategory,
			fetchWorkOrderPriority,
			fetchSupplierFacilitiesInPrivateNetwork,
		} = this.props;
		if (spendCategoryId) {
			fetchSpendCategory(spendCategoryId).then((spendCategory) =>
				this.setState({
					spendCategory: spendCategory,
					spendCategoryId: spendCategoryId,
				})
			);
			fetchWorkOrderAttributes({
				problemTypeId: nullSafeGet('problemType.id', this.state),
				spendCategoryId: spendCategoryId,
			}).then((workOrderAttributes) => {
				if (workOrderAttributes.length > 0) {
					this.setState({
						nteAmount: workOrderAttributes[0].NTEAmount,
						workOrderPriorityId: workOrderAttributes[0].workOrderPriorityId,
					});
					if (workOrderAttributes[0].workOrderPriorityId) {
						fetchWorkOrderPriority(workOrderAttributes[0].workOrderPriorityId).then(
							(workOrderPriority) => this.setState({ workOrderPriority: workOrderPriority })
						);
					}
				}
			});
			fetchSupplierFacilitiesInPrivateNetwork({
				spendCategoryId: spendCategoryId,
				locationId: nullSafeGet('id', this.state.location),
			}).then((s) =>
				s.length > 0 ? this.setState({ supplier: s[0] }) : this.setState({ supplier: null })
			);
		}
	};

	debouncedSearchWorkOrderPriorities = debounce(
		(searchText) => {
			const { fetchWorkOrderPriorities } = this.props;
			fetchWorkOrderPriorities({ name: searchText && searchText.trim() });
		},
		500,
		false
	);

	searchWorkOrderPriorities = (searchText) => {
		this.debouncedSearchWorkOrderPriorities(searchText);
	};

	handleWorkOrderPriorityChange = (workOrderPriorityId) => {
		const { fetchWorkOrderPriority } = this.props;
		if (workOrderPriorityId) {
			fetchWorkOrderPriority(workOrderPriorityId).then((workOrderPriority) =>
				this.setState({
					workOrderPriority: workOrderPriority,
					workOrderPriorityId: workOrderPriorityId,
				})
			);
		}
	};

	debouncedSearchSupplierInPrivateNetwork = debounce(
		(searchText) => {
			const { fetchSupplierFacilitiesInPrivateNetwork } = this.props;
			fetchSupplierFacilitiesInPrivateNetwork({
				name: searchText && searchText.trim(),
				spendCategoryId: nullSafeGet('id', this.state.spendCategory),
				locationId: nullSafeGet('id', this.state.location),
			});
		},
		500,
		false
	);

	searchSupplierInPrivateNetwork = (searchText) => {
		this.debouncedSearchSupplierInPrivateNetwork(searchText);
	};

	handleSupplierChange = (supplierFacilityId) => {
		const { fetchSupplierFacility } = this.props;
		if (supplierFacilityId) {
			fetchSupplierFacility(supplierFacilityId).then((supplier) =>
				this.setState({ supplier: supplier })
			);
		}
	};

	handleFileAttachmentUploadChange = (newAttachments) => {
		const { form } = this.props;
		form.setFieldsValue({ buyerAttachments: newAttachments });
	};

	render() {
		const {
			createErrors,
			updateErrors,
			creating,
			updating,
			form,
			formData,
			companyConfig,
			fetchLocations,
			fetchMultipleLocations,
			locations,
			fetchAssets,
			assets,
			fetchMultipleAssets,
			problemTypes,
			fetchMultipleProblemTypes,
			fetchProblemTypes,
			spendCategories,
			fetchMultipleSpendCategories,
			fetchSpendCategories,
			workOrderPriorities,
			workOrderPrioritiesFetching,
			supplierFacilities,
			supplierFacilitiesFetching,
			currentUser,
		} = this.props;

		//const {updateWorkOrdersFilters} = this.props;

		const { getFieldDecorator } = form;
		const { activeStep, totalSteps, previewVisible, previewImage } = this.state;
		const isLoading = creating || updating;

		const workOrderPrioritiesDropdownRecords = getRecordsForTargetCollection(
			workOrderPriorities,
			WORK_ORDER_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE
		);
		const supplierPrivateNetworkDropdownRecords = getRecordsForTargetCollection(
			supplierFacilities,
			WORK_ORDER_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE
		);

		getFieldDecorator('problemTypeId', {
			initialValue: nullSafeGet('problemType.id', this.state),
		});

		const BACKEND_URI = getBackendUri();
		const uploadHeaders = { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token };

		const getNextText = (activeStep) => {
			if (activeStep < totalSteps) {
				return 'Next';
			} else if (activeStep === totalSteps) {
				return 'Create Work Order';
			}
			return 'Next';
		};

		const step1 = () => {
			return (
				<div>
					<Form.Item required={true} label="Location">
						{getFieldDecorator('locationId', {
							initialValue: nullSafeGet('location.id', this.state),
							rules: [{ required: true, message: 'This field is required.' }],
						})(
							<OWAsyncSelect
								placeholder="Type to search for location"
								style={{ maxWidth: 640 }}
								stateSlice={locations}
								targetCollectionName={WORK_ORDER_FORM_LOCATIONS_AUTOCOMPLETE}
								onChange={this.handleLocationChange}
								fetchMultiple={(ids, targetCollectionName) => {
									fetchMultipleLocations(ids, targetCollectionName);
								}}
								fetchData={(
									searchText,
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								) => {
									fetchLocations(
										{ name: searchText },
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									);
								}}
								renderRecord={(location) => (
									<Select.Option key={location.id} value={location.id}>
										<div>{location.name}</div>
									</Select.Option>
								)}
								sortBy={{ sort_by: 'name', order: 'ascend' }}
							/>
						)}
					</Form.Item>
					<Form.Item label="Affected Asset">
						{getFieldDecorator('assetId', {
							initialValue: nullSafeGet('asset.id', this.state),
						})(
							<OWAsyncSelect
								placeholder="Type to search for asset"
								style={{ maxWidth: 640 }}
								stateSlice={assets}
								disabled={nullSafeGet('location.id', this.state) ? false : true}
								disabledPlaceholder={
									nullSafeGet('location.id', this.state)
										? 'No assets added at location'
										: 'Please choose a location'
								}
								allowClear={true}
								targetCollectionName={WORK_ORDER_FORM_ASSETS_AUTOCOMPLETE}
								onChange={this.handleAssetChange}
								fetchMultiple={(ids, targetCollectionName) => {
									fetchMultipleAssets(ids, targetCollectionName);
								}}
								fetchData={(
									searchText,
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								) => {
									fetchAssets(
										{ search: searchText },
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									);
								}}
								additionalFilters={{ locationId: nullSafeGet('location.id', this.state) }}
								renderRecord={(d) => (
									<Select.Option value={d.id} key={d.id}>
										<div className="flex flex-col">
											<div>{d.name}</div>
											<AssetNumberDisplay asset={d} />
										</div>
									</Select.Option>
								)}
								sortBy={{ sort_by: 'name', order: 'ascend' }}
							/>
						)}
					</Form.Item>
					<Form.Item required={true} label="Problem Type">
						{getFieldDecorator('problemTypeId', {
							initialValue: nullSafeGet('problemType.id', this.state),
							rules: [{ required: true, message: 'This field is required.' }],
						})(
							<OWAsyncSelect
								placeholder="Type to search for problem type"
								style={{ maxWidth: 300 }}
								stateSlice={problemTypes}
								targetCollectionName={WORK_ORDER_FORM_PROBLEM_TYPES_AUTOCOMPLETE}
								onChange={this.handleProblemTypeChange}
								fetchMultiple={(ids, targetCollectionName) => {
									fetchMultipleProblemTypes(ids, targetCollectionName);
								}}
								fetchData={(
									searchText,
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								) => {
									fetchProblemTypes(
										{ name: searchText },
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									);
								}}
								additionalFilters={{
									locationId: nullSafeGet('location.id', this.state),
									assetId: nullSafeGet('asset.id', this.state),
									industryId: nullSafeGetOrElse('industryId', this.state, 1),
								}}
								renderRecord={(d) => (
									<Select.Option value={d.id} key={d.id}>
										<div>{d.hierarchyName}</div>
									</Select.Option>
								)}
								sortBy={{ sort_by: 'name', order: 'ascend' }}
							/>
						)}
					</Form.Item>
					<Form.Item required={true} label="Problem Description">
						{getFieldDecorator('title', {
							initialValue: this.state.inputValues[`title`],
							rules: [{ required: true, message: 'This field is required.' }],
						})(
							<Input
								style={{ maxWidth: 640 }}
								onChange={this.handleInputValueChange(`title`)}
								onClick={(e) => e.stopPropagation()}
							/>
						)}
					</Form.Item>
					<Form.Item label="Photos">
						{getFieldDecorator(`images`, {
							valuePropName: 'fileList',
							getValueFromEvent: normFile,
							initialValue: this.state.allPhotos,
						})(
							<OWUpload
								name="file"
								listType="picture-card"
								className="workorder-image-uploader"
								multiple={true}
								headers={uploadHeaders}
								onPreview={this.handlePreview}
								action={`${BACKEND_URI}/api/v1/buyer/file/upload`}
								beforeUpload={imageUploadValidation}
								onChange={this.handleImageUpdate}
							>
								<div style={{ display: 'inline' }}>
									<LegacyIcon type={this.state.uploadLoading ? 'loading' : 'plus'} />
									<div className="ant-upload-text">Upload</div>
								</div>
								<Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
									<img alt="example" style={{ width: '100%' }} src={previewImage} />
								</Modal>
							</OWUpload>
						)}
					</Form.Item>
					<Form.Item label="Additional Details">
						{getFieldDecorator('additionalInfo', {
							initialValue: this.state.inputValues[`additionalInfo`],
						})(
							<Input.TextArea
								style={{ maxWidth: 640 }}
								onChange={this.handleInputValueChange(`additionalInfo`)}
								onClick={(e) => e.stopPropagation()}
							/>
						)}
					</Form.Item>
					<Form.Item style={{ marginBottom: 0, display: 'flex' }}>
						<Button
							type="primary"
							size="large"
							loading={isLoading}
							onClick={() =>
								this.goNext([
									`title`,
									`locationId`,
									`assetId`,
									`images`,
									`problemTypeId`,
									`additionalInfo`,
								])
							}
						>
							{getNextText(activeStep)}
						</Button>
					</Form.Item>
				</div>
			);
		};

		const step2 = () => {
			return (
				<div>
					<Form.Item label="Not-To-Exceed Amount">
						{getFieldDecorator('NTEAmount', {
							initialValue: this.state.nteAmount,
							rules: [{ required: true, message: 'This field is required.' }],
						})(
							<Input
								style={{ maxWidth: 640 }}
								onChange={this.handleNTEValueChange}
								onClick={(e) => e.stopPropagation()}
							/>
						)}
					</Form.Item>
					<Form.Item required={true} label="Category">
						{getFieldDecorator('spendCategoryId', {
							initialValue: nullSafeGet('id', this.state.spendCategory),
							rules: [{ required: true, message: 'This field is required.' }],
						})(
							<OWAsyncSelect
								placeholder="Type to search for trade"
								style={{ maxWidth: 640 }}
								stateSlice={spendCategories}
								targetCollectionName={WORK_ORDER_FORM_SPEND_CATEGORIES_AUTOCOMPLETE}
								onChange={this.handleSpendCategoryChange}
								fetchMultiple={(ids, targetCollectionName) => {
									fetchMultipleSpendCategories(ids, targetCollectionName);
								}}
								fetchData={(
									searchText,
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								) => {
									fetchSpendCategories(
										{ name: searchText },
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									);
								}}
								renderRecord={(d) => (
									<Select.Option value={d.id} key={d.id}>
										<div>{d.name}</div>
									</Select.Option>
								)}
								sortBy={{ sort_by: 'name', order: 'ascend' }}
							/>
						)}
					</Form.Item>
					<Form.Item required={true} label="Priority">
						{getFieldDecorator('workOrderPriorityId', {
							initialValue: nullSafeGet('id', this.state.workOrderPriority),
							rules: [{ required: true, message: 'This field is required.' }],
						})(
							<Select
								notFoundContent={workOrderPrioritiesFetching ? <Spin size="small" /> : null}
								placeholder="Type to search for priorities"
								filterOption={false}
								showSearch={true}
								onSearch={this.searchWorkOrderPriorities}
								onChange={this.handleWorkOrderPriorityChange}
								style={{ maxWidth: 640 }}
							>
								{workOrderPrioritiesDropdownRecords.map((d) => (
									<Select.Option value={d.id} key={d.id}>
										<div>{d.name}</div>
									</Select.Option>
								))}
							</Select>
						)}
					</Form.Item>
					<Form.Item label="Supplier">
						{getFieldDecorator('supplierFacilityId', {
							initialValue: nullSafeGet('supplier.id', this.state),
						})(
							<Select
								key={nullSafeGet('supplier.id', this.state)}
								notFoundContent={
									supplierFacilitiesFetching ? (
										<Spin size="small" />
									) : (
										'No supplier in your private network for this trade'
									)
								}
								placeholder="Type to search suppliers in your network"
								filterOption={false}
								showSearch={true}
								onSearch={this.searchSupplierInPrivateNetwork}
								onChange={this.handleSupplierChange}
								style={{ maxWidth: 640 }}
							>
								{supplierPrivateNetworkDropdownRecords.map((d) => (
									<Select.Option value={d.id} key={d.id}>
										<div>{d.name}</div>
									</Select.Option>
								))}
							</Select>
						)}
					</Form.Item>

					<Form.Item label="Any other file attachments" style={{ width: 400 }}>
						{form.getFieldDecorator('buyerAttachments', {
							initialValue: formData.buyerAttachments || [],
						})(
							<FileUploader
								defaultFileList={formData.buyerAttachments || []}
								roleType="buyer"
								handleUploadSuccess={this.handleFileAttachmentUploadChange}
								showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
								uploaderType="dragger"
							/>
						)}
					</Form.Item>
					<Form.Item style={{ marginBottom: 0, display: 'flex' }}>
						<Button style={{ opacity: 1 }} size="large" onClick={this.goPrev}>
							Back
						</Button>
						<Button
							type="primary"
							size="large"
							style={{ marginLeft: 16 }}
							loading={isLoading}
							onClick={() =>
								this.goNext([
									`NTEAmount`,
									`workOrderPriorityId`,
									`supplierFacilityId`,
									`spendCategoryId`,
								])
							}
						>
							{getNextText(activeStep)}
						</Button>
					</Form.Item>
				</div>
			);
		};

		const workOrderDetails = [
			{ key: 'Title', value: nullSafeGetOrElse('title', this.state.inputValues, '') },
			{ key: 'Incident Location', value: nullSafeGetOrElse('name', this.state.location, '') },
			{ key: 'Affected Asset', value: nullSafeGetOrElse('name', this.state.asset, '') },
			{ key: 'Problem Type', value: nullSafeGetOrElse('name', this.state.problemType, '') },
			{
				key: 'Additional Info',
				value: nullSafeGetOrElse('additionalInfo', this.state.inputValues, ''),
			},
			{ key: 'Supplier Trade', value: nullSafeGetOrElse('name', this.state.spendCategory, '') },
			{ key: 'NTE Amount', value: '$' + nullSafeGetOrElse('nteAmount', this.state, '') },
			{ key: 'Priority', value: nullSafeGetOrElse('name', this.state.workOrderPriority, '') },
			{
				key: 'Supplier',
				value: nullSafeGetOrElse('supplierFacility.name', this.state.supplier, ''),
			},
			{ key: 'Notes', value: nullSafeGetOrElse('notes', this.state.inputValues, '') },
		];

		const columns = [
			{
				title: 'Name',
				dataIndex: 'title',
				render: (text, record) => <WorkOrderRowDisplay workOrder={record} />,
			},
		];

		const popupRenderFunc = (record) => `
            <div style="max-width: 480px; padding: 8px;">
                <a href="/buyer/workOrders/detail/${record.id}">
                    <div
                        class="workOrderRowDisplay__rowTitle"
                        style="margin-bottom: 8px;"
                    >
                      ${record.title}
                    </div>
                    <div class="workOrderRowDisplay__rowSubtitle">
                        <div class="workOrderRowDisplay__rowInlineGroup">
                            <i class="icons8-font icons8-location-marker"></i>
                            <span>${nullSafeGet('location.storeId', record)}</span>
                        </div>
                        <div class="workOrderRowDisplay__rowInlineGroup">
                            <i class="icons8-font icons8-maintenance-filled"></i>
                            <span>${nullSafeGet('spendCategory.name', record)}</span>
                        </div>
                    </div>
                </a>
            </div>
        `;

		const onRow = (record) => ({
			onClick: () => window.open(`/buyer/workOrders/detail/${record.id}`, '_blank'),
		});

		const WORK_ORDERS_OVERVIEW_TARGET_COLLECTION = 'workOrdersIndex';

		const selectedLocationId = nullSafeGet('id', this.state.location);
		const selectedAssetId = nullSafeGet('id', this.state.asset);
		const selectedSpendCategoryId = nullSafeGet('id', this.state.spendCategory);
		const POSSIBLE_DUPLICATE_FILTER = selectedAssetId
			? {
					locationId: selectedLocationId,
					assetId: selectedAssetId,
			  }
			: {
					locationId: selectedLocationId,
					spendCategoryId: selectedSpendCategoryId,
			  };

		const photoStrings = this.state.allPhotos.map((p) => {
			const fileName = nullSafeGetOrElse('response.data.fileName', p, '');
			const fileId = nullSafeGetOrElse('response.data.fileId', p, '');
			return `${fileId}/${fileName}`;
		});

		const step3 = () => {
			// {updateWorkOrdersFilters(POSSIBLE_DUPLICATE_FILTER, WORK_ORDERS_OVERVIEW_TARGET_COLLECTION)}
			return (
				<div>
					<Card title="Work Order Details">
						<TwoLineList
							listItems={workOrderDetails.filter((field) => field.value !== '')}
							lineOneRender={(record) => record.value}
							lineTwoRender={(record) => record.key}
						/>
						<ProtectedImageThumbnails
							style={{ marginTop: 16 }}
							imageWidth={64}
							imageHeight={64}
							photoStrings={photoStrings}
							role={ROLE_TYPES.BUYER}
						/>
					</Card>
					<Card title="Potential Duplicates">
						<PaginatedReduxTable
							mode={this.state.displayMode}
							longitudeAccessor={(el) => nullSafeGet('location.buyerFacility.longitude', el)}
							latitudeAccessor={(el) => nullSafeGet('location.buyerFacility.latitude', el)}
							popupRenderFunc={popupRenderFunc}
							showHeader={false}
							emptyState={
								<div style={{ textAlign: 'center' }}>
									<div style={{ maxWidth: 440, marginBottom: 16 }}>
										Could not find any potential duplicate work orders
									</div>
								</div>
							}
							collectionName="work_orders"
							targetCollectionName={WORK_ORDERS_OVERVIEW_TARGET_COLLECTION}
							columns={columns}
							onRow={onRow}
							keyAccessor={(el) => el.id}
							initialPagination={{ current: 1, pageSize: 5 }}
							fetchData={workOrdersRestCrudThunksForBuyer.read}
							additionalParams={POSSIBLE_DUPLICATE_FILTER}
							initialSorting={{
								sort_by: 'id',
								order: 'desc',
							}}
						/>
					</Card>
					<p></p>
					<Form.Item style={{ marginBottom: 0, display: 'flex' }}>
						<Button style={{ opacity: 1 }} size="large" onClick={this.goPrev}>
							Back
						</Button>
						<BackButton buttonText="Cancel Work Order" size="large" style={{ marginLeft: 16 }} />
						<Button
							type="primary"
							size="large"
							style={{ marginLeft: 16 }}
							loading={isLoading}
							onClick={() => this.goNext([])}
						>
							{getNextText(activeStep)}
						</Button>
					</Form.Item>
				</div>
			);
		};

		const steps = [
			{
				title: 'Describe Issue',
				subtitle: nullSafeGet('config.newWorkOrder.step1.instructions', companyConfig),
				content: step1(),
			},
			{
				title: 'Work Order Details',
				subtitle: 'Assign a contractor from our private network to resolve the issue',
				content: step2(),
			},
			{
				title: 'Confirm',
				subtitle:
					'Review your work order details and make sure there are no duplicate or recently completed work orders for this same issue',
				content: step3(),
			},
		];

		getFieldDecorator('buyerCompanyId', {
			initialValue: formData.buyerCompanyId || nullSafeGet('facility.buyerCompanyId', currentUser),
		});
		getFieldDecorator('createdBy', {
			initialValue: formData.createdBy || nullSafeGet('email', currentUser),
		});
		getFieldDecorator('currentDateTime', { initialValue: moment(new Date()) });

		return (
			<Form layout="vertical" className="newWorkOrderForm">
				{createErrors.length > 0 ? (
					<Form.Item>
						<Alert message={createErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				{updateErrors.length > 0 ? (
					<Form.Item>
						<Alert message={updateErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				<div className="workOrderForm__steps">
					<Steps current={activeStep - 1}>
						{steps.map((item) => (
							<Step key={item.title} title={item.title} />
						))}
					</Steps>
				</div>
				{steps.map((step, idx) => (
					<div style={{ display: idx === activeStep - 1 ? 'block' : 'none' }}>
						<h5 className="workOrderFlowStepTitle">{step.subtitle}</h5>
						<div className="workOrderFlowSteps">{step.content}</div>
					</div>
				))}
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,
	createErrors: state.work_orders.createErrors,
	updateErrors: state.work_orders.updateErrors,
	formData: ownProps.formData,
	companyConfig: state.company_config.detail,
	creating: state.work_orders.creating,
	updating: state.work_orders.updating,
	locationsFetching: state.locations.fetching,
	locations: state.locations,
	assetsFetching: state.assets.fetching,
	assets: state.assets,
	problemTypesFetching: state.problem_types.fetching,
	problemTypes: state.problem_types,
	spendCategories: state.spend_categories,
	spendCategoriesFetching: state.spend_categories.fetching,
	workOrderPriorities: state.work_order_priorities,
	workOrderPrioritiesFetching: state.work_order_priorities.fetching,
	supplierFacilities: state.supplier_facilities,
	supplierFacilitiesFetching: state.supplier_facilities.fetching,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchLocation: (id) => dispatch(locationsRestCrudThunksForBuyer.readOne(id)),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			locationsRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchAsset: (id) => dispatch(assetsRestCrudThunksForBuyer.readOne(id)),
	fetchMultipleAssets: (ids, targetCollectionName) =>
		dispatch(assetsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchAssets: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			assetsRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchBuyer: (id) => dispatch(buyersRestCrudThunksForBuyer.readOne(id)),
	fetchProblemType: (id) => dispatch(problemTypesRestCrudThunksForBuyer.readOne(id)),
	fetchMultipleProblemTypes: (ids, targetCollectionName) =>
		dispatch(problemTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchProblemTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			problemTypesRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchSpendCategory: (id) => dispatch(spendCategoriesRestCrudThunksForBuyer.readOne(id)),
	fetchMultipleSpendCategory: (ids, targetCollectionName) =>
		dispatch(spendCategoriesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchSpendCategories: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			spendCategoriesRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchWorkOrderAttributes: (params) =>
		dispatch(workOrderAttributesRestCrudThunksForBuyer.read(params)),
	fetchWorkOrderPriority: (id) => dispatch(workOrderPrioritiesRestCrudThunksForBuyer.readOne(id)),
	fetchWorkOrderPriorities: (params) =>
		dispatch(
			workOrderPrioritiesRestCrudThunksForBuyer.read(
				params,
				WORK_ORDER_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE
			)
		),
	fetchSupplierFacility: (id) => dispatch(supplierFacilitiesRestCrudThunksForBuyer.readOne(id)),
	fetchSupplierFacilitiesInPrivateNetwork: (params) =>
		dispatch(
			fetchSupplierFacilitiesInPrivateNetworkForBuyer(
				params,
				WORK_ORDER_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE
			)
		),
	updateWorkOrdersFilters: (filters, targetCollection) =>
		dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	submitWorkOrderForm: (workOrder) => dispatch(workOrdersRestCrudThunksForBuyer.create(workOrder)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Form.create<WorkOrderFormProps>()(NewWorkOrderForm))
);

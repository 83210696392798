import { Form, InputNumber, Modal, Select, message } from 'antd';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import {
	getCurrency,
	getPurchaseRequestId,
	nullSafeGet,
	nullSafeGetOrElse,
	roundOffToDecimal,
} from '../../utils/DataAccessUtils';
import ModalFormFooter from '../common/ModalFormFooter';
import { vendorsRestCrudThunksForSupplier } from '../../thunks/vendors_thunks';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { equipmentTypesRestCrudThunksForSupplier } from '../../thunks/equipment_types_thunks';
import { equipmentCatalogsRestCrudThunksForSupplier } from '../../thunks/equipment_catalogs_thunks';
import { v4 as uuiv4 } from 'uuid';
import PartEquipmentCostFormField from '../common/PartEquipmentCostFormField';
import { PERMISSION_NAMES, isSupplierAllowedToAccess } from '../../utils/AuthUtils';
import { renderEquipmenTypeSelect } from '../purchase_order_form/ReplaceEquipmentLineItemModal';

const FORM_ID = 'add-equipment-form';
const EQUIPMENTS_TC_NAME = 'equipmentTypesAtStockLocationIndex';

const AddEquipmentInPRForm: FC<any> = ({
	onCancel,
	equipmentTypes,
	fetchEquipment,
	fetchEquipmentTypes,
	fetchMultipleEquipments,
	fetchEquipmentsCatalog,
	fetchVendors,
	fetchMultipleVendors,
	createVendor,
	createEquipmentsPerCatalog,
	currentUser,
	createEquipment,
	onSuccess,
	allEquipments,
	equipmentData,
	vendors,
	purchaseRequests,
	loading,
	companyConfig,
}): React.ReactElement => {
	const [form] = Form.useForm();
	const [addingEquipment, setAddingEquipment] = useState(false);
	const userCurrencyId = nullSafeGet('currencyId', getCurrency({ currentUser }));

	const { setFieldsValue } = form;

	const equipmentTypeId =
		Form.useWatch('equipmentTypeId', form) || nullSafeGet('equipmentTypeId', equipmentData);
	const vendorId = Form.useWatch('vendorId', form) || nullSafeGet('vendorId', equipmentData);
	const unitCost = Form.useWatch('unitCost', form) || nullSafeGet('unitCost', equipmentData);
	const quantity = Form.useWatch('quantity', form) || nullSafeGet('quantity', equipmentData);

	const currencyId = useMemo(() => {
		const vendorRecords = getRecordsForTargetCollection(vendors, 'EQUIPMENT_VENDORS_AUTOCOMPLETE');
		return nullSafeGetOrElse(
			'currencyId',
			vendorRecords.find((_) => _.id === vendorId),
			userCurrencyId
		);
	}, [userCurrencyId, vendorId, vendors]);

	useEffect(() => {
		setFieldsValue({
			cost: roundOffToDecimal((unitCost || 0) * (quantity || 0)),
		});
	}, [unitCost, quantity, setFieldsValue]);

	useEffect(() => {
		fetchEquipmentsCatalog({ equipmentTypeId }).then((res) => {
			if (res && res.length === 1) {
				setFieldsValue({ vendorId: res[0].partEquipmentVendorId });
			}
		});
	}, [equipmentTypeId, fetchEquipmentsCatalog, setFieldsValue]);

	const supplierCompanyId = useMemo(
		() => nullSafeGet('facility.supplierCompanyId', currentUser),
		[currentUser]
	);
	const supplierFacilityId = useMemo(() => nullSafeGet('facility.id', currentUser), [currentUser]);

	const getEquipmentCatalogFromVendorId = useCallback(
		(vendorId) => {
			return new Promise((resolve, reject) => {
				if (vendorId) {
					fetchEquipmentsCatalog({
						equipmentTypeId,
						partEquipmentVendorId: vendorId,
					})
						.then((res) => {
							if (res && res.length > 0) {
								resolve(res[0]);
							} else {
								createEquipmentsPerCatalog({
									partEquipmentVendorId: vendorId,
									currencyId: 'USD',
									unitCost,
									equipmentTypeId,
									supplierFacilityId,
									supplierCompanyId,
								})
									.then((ppc) => resolve(ppc))
									.catch((err) => reject(err));
							}
						})
						.catch((err) => reject(err));
				} else resolve(null);
			});
		},
		[
			equipmentTypeId,
			unitCost,
			fetchEquipmentsCatalog,
			createEquipmentsPerCatalog,
			supplierCompanyId,
			supplierFacilityId,
		]
	);

	const getEquipmentRecord = useCallback(() => {
		return new Promise((resolve, reject) => {
			const equipmentsRecords = getRecordsForTargetCollection(equipmentTypes, EQUIPMENTS_TC_NAME);
			const equipment =
				equipmentsRecords.find((_) => _.id === equipmentTypeId) ||
				nullSafeGet('equipment', equipmentData);
			equipment
				? resolve(equipment)
				: fetchEquipment(equipmentTypeId)
						.then((res) => resolve(res))
						.catch((err) => reject(err));
		});
	}, [equipmentData, equipmentTypeId, equipmentTypes, fetchEquipment]);

	const isEditingEquipment = useMemo(
		() => equipmentData && allEquipments.find((_) => _.id === equipmentData.id),
		[equipmentData, allEquipments]
	);

	const handleSubmit = useCallback(
		(values) => {
			setAddingEquipment(true);
			Promise.all([getEquipmentCatalogFromVendorId(values.vendorId), getEquipmentRecord()])
				.then(([equipmentCatalog, equipmentType]) => {
					const newRecord = {
						...values,
						equipmentType,
						...(equipmentCatalog && { equipmentCatalog }),
						...(equipmentCatalog && { equipmentCatalogId: (equipmentCatalog as any).id }),
					};

					const newEquipments = isEditingEquipment
						? allEquipments.map((_) => (_.id === equipmentData.id ? newRecord : _))
						: [...allEquipments, ...[{ ...newRecord, id: uuiv4() }]];

					onSuccess && onSuccess(newEquipments);
				})
				.catch((err) => message.error(err))
				.finally(() => setAddingEquipment(false));
		},
		[
			isEditingEquipment,
			onSuccess,
			allEquipments,
			equipmentData,
			getEquipmentRecord,
			getEquipmentCatalogFromVendorId,
		]
	);

	const prSelectionExist = useMemo(() => !!nullSafeGet('0', purchaseRequests), [purchaseRequests]);

	const canCreatePartOrEquipment = useMemo(
		() =>
			isSupplierAllowedToAccess(
				PERMISSION_NAMES.MODIFY_PARTS_AND_EQUIPMENTS,
				companyConfig,
				currentUser.roles
			),
		[companyConfig, currentUser.roles]
	);

	const canCreateVendors = isSupplierAllowedToAccess(
		PERMISSION_NAMES.MODIFY_VENDORS,
		companyConfig,
		currentUser.roles
	);

	return (
		<Modal
			visible={true}
			title={'Add Equipment to Purchase Request'}
			onCancel={onCancel}
			bodyStyle={{ height: '480px' }}
			footer={null}
			closable={true}
		>
			<Form
				layout="vertical"
				onFinish={handleSubmit}
				initialValues={equipmentData || {}}
				id={FORM_ID}
				form={form}
			>
				{prSelectionExist ? (
					<Form.Item
						name="supplierPurchaseRequestId"
						label="Purchase Request"
						rules={[
							{
								required: true,
								message: 'Please select a purchase request.',
							},
						]}
					>
						<Select disabled={purchaseRequests.length === 1}>
							{purchaseRequests.map((pr) => (
								<Select.Option value={pr.id} key={pr.id}>
									<div>{`${getPurchaseRequestId(pr)} - ${pr.name}`}</div>
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				) : null}
				<Form.Item
					name="equipmentTypeId"
					label="Equipment"
					rules={[
						{
							required: true,
							message: 'Please select an equipmentType.',
						},
					]}
				>
					<OWAsyncSelect
						stateSlice={equipmentTypes}
						targetCollectionName={EQUIPMENTS_TC_NAME}
						fetchMultiple={(ids, targetCollectionName) => {
							fetchMultipleEquipments(ids, targetCollectionName);
						}}
						fetchData={(
							searchText,
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						) => {
							fetchEquipmentTypes(
								{ search: searchText || undefined },
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							);
						}}
						createNewEntity={
							canCreatePartOrEquipment
								? (modelName) =>
										createEquipment({
											modelName,
											supplierCompanyId,
											supplierFacilityId,
											images: [],
										})
								: undefined
						}
						renderRecord={renderEquipmenTypeSelect}
						sortBy={{ sort_by: 'name', order: 'ascend' }}
					/>
				</Form.Item>
				<Form.Item
					name="quantity"
					label="Quantity"
					rules={[
						{
							required: true,
							message: 'Please add quantity.',
						},
					]}
				>
					<InputNumber style={{ width: '100%' }} />
				</Form.Item>
				<Form.Item name="vendorId" label="Vendor">
					<OWAsyncSelect
						disabled={prSelectionExist}
						stateSlice={vendors}
						valueAccessor={(el) => el.id}
						targetCollectionName="EQUIPMENT_VENDORS_AUTOCOMPLETE"
						createNewEntity={
							canCreateVendors ? (name) => createVendor({ name, currencyId: 'USD' }) : undefined
						}
						fetchData={(searchText, targetCollectionName) =>
							fetchVendors({ name: searchText }, targetCollectionName)
						}
						fetchMultiple={(ids, targetCollectionName) => {
							fetchMultipleVendors(ids, targetCollectionName);
						}}
						placeholder="Select Vendor"
						renderRecord={(vendor) => (
							<Select.Option key={vendor.id} value={vendor.id}>
								{vendor.name}
							</Select.Option>
						)}
					/>
				</Form.Item>
				<PartEquipmentCostFormField
					entityId={equipmentTypeId}
					unitCost={unitCost}
					entityName="equipmentTypeId"
					vendorId={vendorId}
					fetchMethod={fetchEquipmentsCatalog}
					setFieldsValue={setFieldsValue}
					form={form}
					currencyId={currencyId}
				/>
				{quantity && unitCost && (
					<Form.Item name="cost" label="Total Cost">
						<InputNumber disabled step={0.01} style={{ width: '100%' }} addonAfter={currencyId} />
					</Form.Item>
				)}
				<ModalFormFooter
					okText={isEditingEquipment ? 'Save Changes' : 'Add Equipment'}
					cancelText="Cancel"
					formId={FORM_ID}
					loading={addingEquipment || loading}
					onCancelClick={onCancel}
				/>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	equipmentTypes: state.equipment_types,
	currentUser: state.session.currentUser,
	vendors: state.vendors,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	createEquipment: (entity) => dispatch(equipmentTypesRestCrudThunksForSupplier.create(entity)),
	fetchEquipment: (id) => dispatch(equipmentTypesRestCrudThunksForSupplier.readOne(id)),
	fetchEquipmentTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			equipmentTypesRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleEquipments: (ids, targetCollectionName) =>
		dispatch(equipmentTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchVendors: (params, targetCollectionName) =>
		dispatch(
			vendorsRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName
			)
		),
	fetchMultipleVendors: (ids, targetCollectionName) =>
		dispatch(vendorsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchEquipmentsCatalog: (params, targetCollectionName) =>
		dispatch(equipmentCatalogsRestCrudThunksForSupplier.read(params, targetCollectionName)),
	createVendor: (entity) => dispatch(vendorsRestCrudThunksForSupplier.create(entity)),
	createEquipmentsPerCatalog: (entity, targetCollectionName) =>
		dispatch(equipmentCatalogsRestCrudThunksForSupplier.create(entity, 'id', targetCollectionName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEquipmentInPRForm);

import * as React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Alert, Select, Switch, Form } from 'antd';
import { connect } from 'react-redux';
import {
	hasAccessToAllLocations,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import BuyerRegionsLocationsTreeSelect from '../regions_tree_select/BuyerRegionsLocationsTreeSelect';

const FormItem = Form.Item;

function BuyerRoleUpdateForm(props) {
	const [form] = Form.useForm();
	const {
		visible,
		onCancel,
		onSubmit,
		formData,
		createErrors,
		updateErrors,
		locations,
		buyerUsers,
		isLocationChange,
	} = props;

	let email = visible ? nullSafeGet('contact.email', formData) : null;
	let nameGiven = nullSafeGet('contact.nameGiven', formData);
	let nameFamily = nullSafeGet('contact.nameFamily', formData);
	let user = email ? buyerUsers.records[email] : null;
	let value = nullSafeGetOrElse(`locationIds`, user, undefined) || [];
	const [role, setRole] = React.useState(user && user.role && user.role[0]);
	const [checkAllLocations, setCheckAllLocations] = React.useState(
		hasAccessToAllLocations(role, user)
	);

	//On new User
	React.useMemo(() => {
		setRole(user && user.role && user.role[0]);
	}, [email]);

	//On role
	React.useMemo(() => {
		setCheckAllLocations(hasAccessToAllLocations(role, user));
	}, [role]);

	//On change
	React.useEffect(() => {
		form.setFieldsValue({
			email: email,
			nameGiven: nameGiven,
			nameFamily: nameFamily,
			roles: role ? role : null,
			hasAccessToAllLocations: checkAllLocations,
			locationIds: value,
		});
	}, [checkAllLocations, role, value, nameGiven, nameFamily]);

	return (
		<Modal
			visible={visible}
			width={600}
			title={'Edit User Information'}
			okText={'Update'}
			onCancel={onCancel}
			onOk={form.submit}
			closable={true}
			destroyOnClose={true}
		>
			<Form
				form={form}
				layout="vertical"
				className="supplierForm"
				preserve={false}
				initialValues={{}}
				onFinish={onSubmit}
			>
				{createErrors.length > 0 ? (
					<FormItem>
						<Alert message={createErrors.join(' ')} type="error" />
					</FormItem>
				) : null}
				{updateErrors.length > 0 ? (
					<FormItem>
						<Alert message={updateErrors.join(' ')} type="error" />
					</FormItem>
				) : null}
				<FormItem label="Email" name={'email'}>
					<Input defaultValue={email} style={{ maxWidth: 300 }} disabled={true} />
				</FormItem>
				<FormItem
					label={'Name'}
					className={'flex flex-row'}
					required={true}
					rules={[
						{
							required: true,
							message: 'Please input name.',
						},
					]}
				>
					<div className={'flex flex-row'}>
						<FormItem name={'nameGiven'}>
							<Input
								placeholder="Given name"
								style={{ maxWidth: 200 }}
								disabled={false}
								defaultValue={nullSafeGet('contact.nameGiven', formData)}
							/>
						</FormItem>
						<FormItem name={'nameFamily'}>
							<Input
								defaultValue={nullSafeGet('contact.nameFamily', formData)}
								placeholder="Family name"
								style={{ maxWidth: 200, marginLeft: 8 }}
								disabled={false}
							/>
						</FormItem>
					</div>
				</FormItem>

				<FormItem
					label="Roles"
					name="roles"
					required={true}
					rules={[
						{
							required: true,
							message: 'Please select a role',
						},
					]}
				>
					<Select
						disabled={isLocationChange ? isLocationChange : false}
						style={{ maxWidth: 200 }}
						defaultValue={
							nullSafeGet('role', formData) === undefined ? 'BUYER_USER' : formData.role[0]
						}
						onChange={(value) => {
							setRole(value);
						}}
					>
						<Select.Option value="BUYER_ADMIN">Admin</Select.Option>
						<Select.Option value="BUYER_USER">Manager</Select.Option>
						<Select.Option value="BUYER_STORE_ASSOCIATE">Store Associate</Select.Option>
					</Select>
				</FormItem>
				<FormItem
					name="hasAccessToAllLocations"
					valuePropName="checked"
					label="Locations"
					required={true}
					hidden={form.getFieldValue(`roles`) !== 'BUYER_USER'}
				>
					<Switch
						checked={checkAllLocations}
						onChange={(checked) => setCheckAllLocations(checked)}
					/>
					<label style={{ marginLeft: 8 }}>Select All Locations</label>
				</FormItem>
				<FormItem
					name="locationIds"
					hidden={form.getFieldValue('roles') === 'BUYER_ADMIN'}
					label={form.getFieldValue(`roles`) === 'BUYER_STORE_ASSOCIATE' ? 'Locations' : null}
					rules={[
						{
							type: 'array',
							required: !checkAllLocations,
							message: 'Please select a location',
						},
					]}
				>
					<BuyerRegionsLocationsTreeSelect
						mode="multiple"
						noIsActiveFilter
						disabled={checkAllLocations}
						filtersSlice={locations}
						filtersValueAccessor={() => form.getFieldValue('locationIds')}
						targetCollectionName={'buyerUsersLocations'}
					/>
				</FormItem>
			</Form>
		</Modal>
	);
}

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	createErrors: state.buyer_contacts.createErrors,
	updateErrors: state.buyer_contacts.updateErrors,
	creating: state.buyer_contacts.creating,
	updating: state.buyer_contacts.updating,
	currentUser: state.session.currentUser,
	formData: ownProps.formData,
	isLocationChange: ownProps.isLocationChange,

	buyerUsers: state.buyer_users,
	locations: state.locations,
	regions: state.regions,
});

export default connect(mapStateToProps)(BuyerRoleUpdateForm);

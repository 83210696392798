import { Button, DatePicker, Form, Input, TimePicker, message } from 'antd';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { connect } from 'react-redux';
import { getFileLink, getProtectedImageUriFromString } from '../../utils/FileAccessUtils';
import ReactPlayer from 'react-player';
import OWPdfViewer from '../ow_pdf_viewer/OWPdfViewer';
import { useForm } from 'antd/lib/form/Form';
import {
	formatForTroubleshootingAnswerInput,
	getTroubleshootingBackendInput,
} from '../../utils/TroubleshootingUtils';
import { TIME_ONLY_FORMAT } from '../../utils/DataFormatterUtils';

const getInputFieldName = (step) => `troubleshooting-input-${nullSafeGet('orderingId', step)}`;

const dateFormat = 'MMMM DD, YYYY';

const TroubleshootingDisplay: FC<any> = ({
	troubleshootingSteps,
	onBack,
	onNextStep,
	onCancel,
	userType,
	startWithIndex = 0,
	existingAnswer,
}): React.ReactElement => {
	const [stepIndex, setStepIndex] = useState(startWithIndex);
	const [tsAnswers, setTsAnswers] = useState(existingAnswer || []);

	const [form] = useForm();

	const initialValues = useMemo(() => {
		const answers = existingAnswer || [];
		return answers.reduce(
			(acc, ans) => ({
				...acc,
				[getInputFieldName(ans.troubleshootingStep)]: formatForTroubleshootingAnswerInput(ans),
			}),
			{}
		);
	}, [existingAnswer]);

	const { getFieldValue } = form;

	const currentStep: any = useMemo(
		() => nullSafeGetOrElse(`${stepIndex}`, troubleshootingSteps, {}),
		[stepIndex, troubleshootingSteps]
	);

	const getFileLinkFromString = useCallback(
		(fileIdWithName) => {
			const fileId = fileIdWithName.split('/')[0];
			const fileName = fileIdWithName.split('/')[1];
			return getFileLink(fileId, fileName, userType);
		},
		[userType]
	);

	const getPdfElement = useCallback(
		(fileIdWithName) => <OWPdfViewer file={getFileLinkFromString(fileIdWithName)} />,
		[getFileLinkFromString]
	);

	const getImageElement = useCallback(
		(fileString) => {
			const link = getProtectedImageUriFromString(userType)(fileString, 600, 800);
			// eslint-disable-next-line jsx-a11y/img-redundant-alt
			return <img src={link} alt="Unable to display image" />;
		},
		[userType]
	);

	const getEmbedElement = useCallback((link) => <ReactPlayer url={link} controls playing />, []);

	const getVideoElement = useCallback(
		(fileIdWithName) => {
			const link = getFileLinkFromString(fileIdWithName);
			return <ReactPlayer url={link} controls playing />;
		},
		[getFileLinkFromString]
	);

	const attachmentElement = useMemo(() => {
		const attachments = nullSafeGetOrElse('attachments', currentStep, []);
		if (attachments.length > 0) {
			const fileIdWithName = nullSafeGetOrElse('fileIdWithName', attachments[0], '/');

			switch (attachments[0].attachmentType) {
				case 'image':
					return getImageElement(fileIdWithName);
				case 'pdf':
					return getPdfElement(fileIdWithName);
				case 'embeddedVideo':
					return getEmbedElement(fileIdWithName);
				case 'video':
					return getVideoElement(fileIdWithName);
			}
		}
		return null;
	}, [getEmbedElement, getImageElement, getPdfElement, getVideoElement, currentStep]);

	const onBackClick = useCallback(() => {
		stepIndex > 0 ? setStepIndex((i) => i - 1) : onBack();
	}, [onBack, stepIndex]);

	const proceedToNextStep = useCallback(
		(answers) => {
			stepIndex < troubleshootingSteps.length - 1
				? setStepIndex((i) => i + 1)
				: onNextStep(
						answers.map((_) => ({
							..._,
							answer: getTroubleshootingBackendInput(_),
						}))
				  );
		},
		[onNextStep, stepIndex, troubleshootingSteps.length]
	);

	const inputFieldName = useMemo(() => getInputFieldName(currentStep), [currentStep]);

	const onNextClick = useCallback(() => {
		if (nullSafeGet('inputRequired', currentStep)) {
			const inputValue = getFieldValue(inputFieldName);
			if (!inputValue) {
				message.error('Please select/enter a value');
			} else {
				const existingAnswer = tsAnswers.find(
					(_) => nullSafeGet('troubleshootingStep.orderingId', _) === currentStep.orderingId
				);
				const newAnswers = !!existingAnswer
					? tsAnswers.map((_) =>
							nullSafeGet('troubleshootingStep.orderingId', _) === currentStep.orderingId
								? { ..._, answer: inputValue }
								: _
					  )
					: [
							...tsAnswers,
							...[
								{
									troubleshootingStep: { ...currentStep },
									answer: inputValue,
								},
							],
					  ];
				setTsAnswers(newAnswers);
				proceedToNextStep(newAnswers);
			}
		} else {
			proceedToNextStep(tsAnswers);
		}
	}, [currentStep, getFieldValue, inputFieldName, proceedToNextStep, tsAnswers]);

	const getInputField = useCallback((inputType) => {
		switch (inputType) {
			case 'dateOnly':
				return <DatePicker format={dateFormat} size="large" />;
			case 'timeRange':
				return <TimePicker.RangePicker size="large" format={TIME_ONLY_FORMAT} />;
			case 'text':
				return <Input style={{ width: '100%' }} size="large" />;
			default:
				return null;
		}
	}, []);

	const inputField = useMemo(
		() => (!!currentStep.inputType ? getInputField(currentStep.inputType) : null),
		[currentStep.inputType, getInputField]
	);

	return (
		<div
			key={5}
			style={{
				marginTop: '15vh',
				width: '750px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				marginBottom: '80px',
			}}
		>
			<h3 className="troubleshooting-title">
				<span dangerouslySetInnerHTML={{ __html: currentStep.title }} />
			</h3>
			<Form
				form={form}
				layout="vertical"
				requiredMark={false}
				initialValues={initialValues}
				style={{ width: '750px', alignItems: 'center', textAlign: 'center' }}
			>
				<Form.Item name={inputFieldName} style={{ width: '100%' }}>
					{inputField}
				</Form.Item>
				<Form.Item>
					<div className="flex flex-col items-center">
						<div className="text-xl font-normal">
							<span dangerouslySetInnerHTML={{ __html: currentStep.description }} />
						</div>
						<div>{attachmentElement}</div>
					</div>
				</Form.Item>
			</Form>
			<div className="flex flex-row">
				<Button size="large" type="ghost" onClick={onBackClick}>
					Back
				</Button>
				{nullSafeGetOrElse('canProceedToNextStep', currentStep, false) ? (
					<Button size="large" type="primary" className="ml-4" onClick={onNextClick}>
						Next step
					</Button>
				) : (
					<Button size="large" type="primary" className="ml-4" onClick={onCancel}>
						Okay
					</Button>
				)}
			</div>
		</div>
	);
};

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(TroubleshootingDisplay);

//Pipeline Filters
import { BLACKS_AND_WHITES, STOPLIGHT_COLORS } from '../../../../utils/DataFormatterUtils';

const PENDING_QUOTES = 'Pending';
const EXPIRED_QUOTES = 'Expired';
const DECLINED_QUOTES = 'Declined';
const APPROVED_QUOTES = 'Awarded';
const PENDING_FILTER = { status: PENDING_QUOTES };
const EXPIRED_FILTER = { status: EXPIRED_QUOTES };
const DECLINED_FILTER = { status: DECLINED_QUOTES };
const APPROVED_FILTER = { status: APPROVED_QUOTES };

export const pipelineFilters = [
	{
		displayName: 'Pending',
		filter: PENDING_FILTER,
		name: PENDING_QUOTES,
		icon: 'icons8-edit',
		iconStyle: { color: '#4a1e87' },
	},
	{
		displayName: 'Declined',
		filter: DECLINED_FILTER,
		name: DECLINED_QUOTES,
		icon: 'icons8-cancel',
		iconStyle: { color: STOPLIGHT_COLORS.pureRed },
	},
	{
		displayName: 'Approved',
		filter: APPROVED_FILTER,
		name: APPROVED_QUOTES,
		icon: 'icons8-approve',
		iconStyle: { color: STOPLIGHT_COLORS.pureYellow },
	},
	{
		displayName: 'Expired',
		filter: EXPIRED_FILTER,
		name: EXPIRED_QUOTES,
		icon: 'icons8-minus-2',
		iconStyle: { color: BLACKS_AND_WHITES.blackTertiary },
	},
];

export const SuplierPipelinefilters = [...pipelineFilters];

export const CLEAR_STATUS_FILTER = { displayStatus: undefined };
export const CLEAR_DISPLAY_STATUS_FILTER = { status: undefined };

import moment from 'moment';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, DatePicker, InputNumber, Select, Spin } from 'antd';
import BackButton from '../back_button/BackButton';
import * as React from 'react';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
	supplierFacilitiesRestCrudThunksForBuyer,
	supplierFacilitiesRestCrudThunksForSupplier,
} from '../../thunks/supplier_facilities_thunks';
import {
	suppliersRestCrudThunksForBuyer,
	suppliersRestCrudThunksForSupplier,
} from '../../thunks/suppliers_thunks';
import {
	buyersRestCrudThunksForBuyer,
	buyersRestCrudThunksForSupplier,
} from '../../thunks/buyers_thunks';
import {
	projectsRestCrudThunksForBuyer,
	projectsRestCrudThunksForSupplier,
} from '../../thunks/projects_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';
import {
	projectTemplatesRestCrudThunksForBuyer,
	projectTemplatesRestCrudThunksForSupplier,
} from '../../thunks/project_template_thunks';
import { debounce } from '../../utils/PerformanceUtils';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';

interface ProjectFormProps extends FormComponentProps {
	history: any;
	onSubmit: any;
	onCancel: any;
	redirectForwardUrl?: string;
	onSuccess?: any;
	isApprovalForm?: any;
	creating: boolean;
	updating: boolean;
	formData: any;
	createErrors: any[];
	updateErrors: any[];
	fetchBuyer: any;
	currentUser: any;
	createProject: any;
	fetchSupplierFacility: any;
	fetchSupplierCompany: any;
	fetchProjectTemplate: any;
	fetchProjectTemplates: any;
	projectTemplates: any;
	projectTemplatesFetching: any;
}

const PROJECT_FORM_PROJECT_TEMPLATES_AUTOCOMPLETE = 'PROJECT_FORM_PROJECT_TEMPLATES_AUTOCOMPLETE';

class ProjectCreateForm extends React.Component<ProjectFormProps, any> {
	constructor(props) {
		super(props);
		this.state = {
			previewVisible: false,
			uploadLoading: false,
			privateSupplier: false,
			industryId: 1,
			budget: 0,
			projectTemplate: {},
		};
	}

	componentDidMount() {
		const { formData, currentUser, fetchProjectTemplates } = this.props;
		const userType = nullSafeGetOrElse('userType', currentUser, 'buyer');
		fetchProjectTemplates(userType, { limit: 50, offset: 0 });

		if (formData.budget) {
			this.setState({ budget: formData.budget });
		}
	}

	handleSubmit = (e) => {
		const { form, history, redirectForwardUrl, onSuccess, createProject, currentUser } = this.props;
		const { projectTemplate } = this.state;
		e.preventDefault();
		form.validateFields((err, values) => {
			if (!err) {
				const requiredFieldDetails = nullSafeGetOrElse('requiredFields', projectTemplate, []).map(
					(rf) => ({
						key: form.getFieldValue(`requiredFields-${rf.key}-key`),
						valueType: form.getFieldValue(`requiredFields-${rf.key}-valueType`),
						valueAsString: form.getFieldValue(`requiredFields-${rf.key}-valueAsString`),
					})
				);

				const optionalFieldDetails = nullSafeGetOrElse('optionalFields', projectTemplate, [])
					.map((of) => ({
						key: form.getFieldValue(`optionalFields-${of.key}-key`),
						valueType: form.getFieldValue(`optionalFields-${of.key}-valueType`),
						valueAsString: form.getFieldValue(`optionalFields-${of.key}-valueAsString`),
					}))
					.filter((t) => t.valueAsString !== undefined && t.valueAsString !== '');
				const projectTemplateDetails = requiredFieldDetails.concat(optionalFieldDetails);
				let project = {
					title: values.title,
					dueDate: values.dueDate,
					budget: values.budget,
					description: values.additionalInfo,
					buyerCompanyId: values.buyerCompanyId,
					createdBy: values.createdBy,
					projectTemplateId: values.projectTemplateId
						? parseInt(values.projectTemplateId, 10)
						: undefined,
					projectTemplateDetails: projectTemplateDetails,
				};
				const userType = nullSafeGetOrElse('userType', currentUser, 'buyer');
				createProject(userType, project).then((record) => {
					if (redirectForwardUrl) {
						history.push(redirectForwardUrl);
					}
					if (onSuccess) {
						onSuccess(record);
					}
				});
			}
		});
	};

	handleCancel = () => this.setState({ previewVisible: false });

	searchProjectTemplates = (searchText) => {
		this.debouncedSearchProjectTemplates(searchText);
	};
	debouncedSearchProjectTemplates = debounce(
		(searchText) => {
			const { fetchProjectTemplates, currentUser } = this.props;
			const userType = nullSafeGetOrElse('userType', currentUser, 'buyer');
			fetchProjectTemplates(userType, { name: searchText && searchText.trim() });
		},
		500,
		false
	);

	handleProjectTemplateChange = (projectTemplateId) => {
		const { fetchProjectTemplate, currentUser } = this.props;
		const userType = nullSafeGetOrElse('userType', currentUser, 'buyer');
		if (projectTemplateId) {
			fetchProjectTemplate(userType, projectTemplateId).then((lt) =>
				this.setState({ projectTemplate: lt })
			);
		}
	};

	render() {
		const { updating, form, formData, currentUser, projectTemplates, projectTemplatesFetching } =
			this.props;
		const { getFieldDecorator } = form;
		const { projectTemplate } = this.state;
		const dateFormat = 'dddd, MMM D, YYYY, h:mm A';
		const submitLabel = 'Create project';
		const projectTemplatesDropdownRecords = getRecordsForTargetCollection(
			projectTemplates,
			PROJECT_FORM_PROJECT_TEMPLATES_AUTOCOMPLETE
		);
		let initialProjectDetailsMap = {};
		if (formData.projectTemplateDetails) {
			formData.projectTemplateDetails.map((ltd) => {
				initialProjectDetailsMap[`requiredFields-${ltd.key}-key`] = ltd.key;
				initialProjectDetailsMap[`requiredFields-${ltd.key}-valueType`] = ltd.valueType;
				initialProjectDetailsMap[`requiredFields-${ltd.key}-valueAsString`] = ltd.valueAsString;
				initialProjectDetailsMap[`optionalFields-${ltd.key}-key`] = ltd.key;
				initialProjectDetailsMap[`optionalFields-${ltd.key}-valueType`] = ltd.valueType;
				initialProjectDetailsMap[`optionalFields-${ltd.key}-valueAsString`] = ltd.valueAsString;
			});
		}

		const getRequiredFieldItems = (lt) => {
			if (!lt || !lt.id) {
				return null;
			}
			return lt.requiredFields.map((field) => {
				getFieldDecorator(`requiredFields-${field.key}-key`, { initialValue: field.key });
				getFieldDecorator(`requiredFields-${field.key}-valueType`, { initialValue: field.type });
				return (
					<Form.Item label={field.key}>
						{form.getFieldDecorator(`requiredFields-${field.key}-valueAsString`, {
							validateTrigger: ['onChange', 'onBlur'],
							initialValue: initialProjectDetailsMap[`requiredFields-${field.key}-valueAsString`],
							rules: [{ required: true, message: 'This field is required.' }],
						})(<Input style={{ maxWidth: 640 }} />)}
					</Form.Item>
				);
			});
		};

		const getOptionalFieldItems = (lt) => {
			if (!lt || !lt.id) {
				return null;
			}
			return lt.optionalFields.map((field) => {
				getFieldDecorator(`optionalFields-${field.key}-key`, { initialValue: field.key });
				getFieldDecorator(`optionalFields-${field.key}-valueType`, { initialValue: field.type });
				return (
					<Form.Item label={field.key}>
						{form.getFieldDecorator(`optionalFields-${field.key}-valueAsString`, {
							validateTrigger: ['onChange', 'onBlur'],
							initialValue: initialProjectDetailsMap[`requiredFields-${field.key}-valueAsString`],
						})(<Input style={{ maxWidth: 640 }} />)}
					</Form.Item>
				);
			});
		};

		getFieldDecorator('id', { initialValue: formData.id });
		getFieldDecorator('createdAt', { initialValue: formData.createdAt });
		getFieldDecorator('createdBy', {
			initialValue: formData.createdBy || nullSafeGet('email', currentUser),
		});
		getFieldDecorator('buyerCompanyId', {
			initialValue: formData.buyerCompanyId || nullSafeGet('facility.buyerCompanyId', currentUser),
		});

		const calculatedDueDate = moment(
			new Date(
				new Date().getTime() +
					nullSafeGetOrElse('expectedResolutionInSeconds', formData.woPriority, 0) * 1000
			)
		);
		getFieldDecorator('dueDate', { initialValue: calculatedDueDate });
		getFieldDecorator('budget', { initialValue: formData.budget });

		return (
			<Form layout="vertical" onSubmit={this.handleSubmit} className="supplierForm">
				<Form.Item label="Title">
					{getFieldDecorator('title', {
						initialValue: formData.title,
						rules: [{ required: true, message: 'This field is required.' }],
					})(<Input style={{ maxWidth: 640 }} />)}
				</Form.Item>
				<Form.Item label="Additional Info">
					{getFieldDecorator('additionalInfo', {
						initialValue: formData.description,
					})(<Input.TextArea style={{ maxWidth: 640 }} />)}
				</Form.Item>
				<Form.Item label="Due date">
					{getFieldDecorator('dueDate', {
						rules: [{ required: true, message: 'This field is required.' }],
					})(<DatePicker style={{ width: 640 }} format={dateFormat} showTime={true} />)}
				</Form.Item>
				<Form.Item label="Budget">
					{getFieldDecorator('budget', {
						initialValue: formData.budget,
						rules: [{ required: true, message: 'This field is required.' }],
					})(<InputNumber style={{ display: 'flex', width: '100%', maxWidth: 640 }} />)}
				</Form.Item>
				{getFieldDecorator('status', {
					initialValue: formData.status,
				})}
				<Form.Item label="Project Template">
					{getFieldDecorator('projectTemplateId', {
						initialValue: formData.projectTemplateId,
					})(
						<Select
							notFoundContent={projectTemplatesFetching ? <Spin size="small" /> : null}
							placeholder="Type to search for project template"
							filterOption={false}
							showSearch={true}
							onSearch={this.searchProjectTemplates}
							onChange={this.handleProjectTemplateChange}
							style={{ maxWidth: 640 }}
						>
							{projectTemplatesDropdownRecords.map((d) => (
								<Select.Option value={d.id} key={d.id}>
									{d.name}
								</Select.Option>
							))}
						</Select>
					)}
				</Form.Item>
				{getRequiredFieldItems(projectTemplate)}
				{getOptionalFieldItems(projectTemplate)}
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						loading={updating}
						className="contactForm__button"
					>
						{submitLabel}
					</Button>
					<span style={{ marginLeft: '16px' }}>
						<BackButton buttonText="Cancel" />
					</span>
				</Form.Item>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,
	projectTemplatesFetching: state.project_templates.fetching,
	projectTemplates: state.project_templates,
	isApprovalForm: ownProps.isApprovalForm,
	createErrors: state.work_orders.createErrors,
	updateErrors: state.work_orders.updateErrors,
	formData: ownProps.formData,
	creating: state.work_orders.creating,
	updating: state.work_orders.updating,
	supplierFacilities: state.supplier_facilities,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	fetchBuyer: (userType, id) =>
		dispatch(
			userType === ROLE_TYPES.SUPPLIER
				? buyersRestCrudThunksForSupplier.readOne(id)
				: buyersRestCrudThunksForBuyer.readOne(id)
		),
	createProject: (userType, project) =>
		dispatch(
			userType === ROLE_TYPES.SUPPLIER
				? projectsRestCrudThunksForSupplier.create(project)
				: projectsRestCrudThunksForBuyer.create(project)
		),
	fetchProjectTemplate: (userType, id) =>
		dispatch(
			userType === ROLE_TYPES.SUPPLIER
				? projectTemplatesRestCrudThunksForSupplier.readOne(id)
				: projectTemplatesRestCrudThunksForBuyer.readOne(id)
		),
	fetchProjectTemplates: (userType, params) =>
		dispatch(
			userType === ROLE_TYPES.SUPPLIER
				? projectTemplatesRestCrudThunksForSupplier.read(
						params,
						PROJECT_FORM_PROJECT_TEMPLATES_AUTOCOMPLETE
				  )
				: projectTemplatesRestCrudThunksForBuyer.read(
						params,
						PROJECT_FORM_PROJECT_TEMPLATES_AUTOCOMPLETE
				  )
		),
	fetchSupplierFacility: (userType, id) =>
		dispatch(
			userType === ROLE_TYPES.SUPPLIER
				? supplierFacilitiesRestCrudThunksForSupplier.readOne(id)
				: supplierFacilitiesRestCrudThunksForBuyer.readOne(id)
		),
	fetchSupplierCompany: (userType, id) =>
		dispatch(
			userType === ROLE_TYPES.SUPPLIER
				? suppliersRestCrudThunksForSupplier.readOne(id)
				: suppliersRestCrudThunksForBuyer.readOne(id)
		),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Form.create<ProjectFormProps>()(ProjectCreateForm))
);

import * as React from 'react';
import { Spin, TreeSelect } from 'antd';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { ReactNode } from 'react';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { debounce } from '../../utils/PerformanceUtils';
import * as _ from 'lodash';

interface OWAsyncSelectTreeProps {
	stateSlice: any;
	targetCollectionName: string;
	placeholder?: string;
	disabledPlaceholder?: string;
	valueAccessor?: any;
	labelAccessor?: any;
	additionalFilters?: any;
	initialPagination?: any;
	allowClear?: boolean;
	size?: 'large' | 'small';
	style?: any;
	mode?: 'default' | 'multiple' | 'tags' | 'combobox';
	value?: any;
	onChange?: any;
	loading?: boolean;
	disabled?: boolean;
	emptyWhenDisabled?: boolean;
	sortBy?: any;

	fetchMultiple(ids: string[] | number[], targetCollectionName?: string): void;

	renderRecord(data: any): ReactNode;

	fetchData(
		searchText: string,
		targetCollectionName: string,
		pagination?: any,
		sorting?: any,
		filters?: any,
		addToTargetCollection?: boolean
	): Promise<any[]>;
}

const defaultPagination = { current: 1, pageSize: 1000 };
const { SHOW_PARENT } = TreeSelect;
const arrDataToTreeData = (arrData, valueAccessor, labelAccessor) => {
	let mapData = {};
	let childIds = [];
	arrData.forEach((el) => {
		if (el.childrenIds && el.childrenIds.length > 0) {
			childIds = [...childIds, ...el.childrenIds];
		}
		mapData[el.id] = el;
	});
	const transformRecord = (el) => ({
		value: el.id,
		key: el.id,
		title: labelAccessor(el),
		children: el.childrenIds ? el.childrenIds.map((id) => transformRecord(mapData[id])) : [],
	});
	const recs = arrData.map((el) => transformRecord(el));
	return recs.filter((rec) => {
		return !childIds.includes(rec.value);
	});
};

export default class OWAsyncTreeSelect extends React.Component<OWAsyncSelectTreeProps, any> {
	constructor(props) {
		super(props);
		const value = props.value;
		this.state = {
			value: value,
			treeData: [],
		};
		this.labelAccessor = this.labelAccessor.bind(this);
	}
	labelAccessor(record) {
		return this.props.labelAccessor ? this.props.labelAccessor(record) : record['name'];
	}

	setTreeDataAfterFetch = (arrData) => {
		const treeData = arrDataToTreeData(arrData, this.props.valueAccessor, this.labelAccessor);
		this.setState({ treeData });
	};

	componentWillReceiveProps(nextProps) {
		// Should be a controlled component.
		if ('value' in nextProps) {
			this.setState({ value: nextProps.value });
		}
		if (!_.isEqual(nextProps.additionalFilters, this.props.additionalFilters)) {
			this.props
				.fetchData(
					this.state.searchText && this.state.searchText.trim(),
					nextProps.targetCollectionName,
					this.props.initialPagination || defaultPagination,
					nextProps.sortBy,
					nextProps.additionalFilters,
					false
				)
				.then((arrData) => this.setTreeDataAfterFetch(arrData));
		}

		return null;
	}

	componentDidMount() {
		const { value } = this.state;
		const { targetCollectionName } = this.props;
		if (value) {
			this.props.fetchMultiple(Array.isArray(value) ? value : [value], targetCollectionName);
		}

		this.props
			.fetchData(
				'',
				targetCollectionName,
				this.props.initialPagination || defaultPagination,
				this.props.sortBy,
				this.props.additionalFilters,
				false
			)
			.then((arrData) => this.setTreeDataAfterFetch(arrData));
	}

	handleValueChange = (values) => {
		const { mode } = this.props;
		const valueSet = new Set();
		const elementSet = new Set();
		function addAllChildren(array) {
			array.map(function f(a) {
				if (Array.isArray(a.children) && a.children.length) {
					a.children.map(f);
				} else {
					valueSet.add(a.key);
					elementSet.add(a);
				}
			});
		}

		function find(array, key) {
			var object;
			array.some(function f(a) {
				if (a.key === key) {
					object = a;
					return true;
				}
				if (Array.isArray(a.children) && a.children.length) {
					return a.children.some(f);
				}
			});
			return object;
		}
		if (mode === 'multiple') {
			values.map((key) => {
				const entity = find(this.state.treeData, key);
				if (entity && entity.children && entity.children.length) {
					addAllChildren(entity.children);
				} else if (entity) {
					valueSet.add(entity.key);
					elementSet.add(entity);
				}
			});
		} else {
			elementSet.add(find(this.state.treeData, values));
		}
		const updatedValue = mode === 'multiple' ? Array.from(valueSet) : values;
		const updatedElement = mode === 'multiple' ? Array.from(elementSet) : Array.from(elementSet)[0];
		if (!('value' in this.props)) {
			this.setState({ value: updatedValue });
		}
		this.triggerChange(updatedValue, updatedElement);
	};

	triggerChange = (changedValue, el) => {
		// Should provide an event to pass value to Form.
		const onChange = this.props.onChange;
		if (onChange) {
			onChange(changedValue, el);
		}
	};

	handleSearch = debounce(
		(searchText) => {
			this.props
				.fetchData(
					searchText && searchText.trim(),
					this.props.targetCollectionName,
					this.props.initialPagination || defaultPagination,
					this.props.sortBy,
					this.props.additionalFilters,
					false
				)
				.then((arrData) => this.setTreeDataAfterFetch(arrData));
			this.setState({
				searchText: searchText && searchText.trim(),
				pagination: this.props.initialPagination || defaultPagination,
			});
		},
		200,
		false
	);

	render() {
		const { value } = this.state;
		const {
			disabled,
			allowClear = false,
			loading,
			emptyWhenDisabled,
			valueAccessor = (el) => el.id,
			stateSlice,
			targetCollectionName,
			mode,
			placeholder,
			disabledPlaceholder,
			size,
			style,
		} = this.props;
		const selectedVals = Array.isArray(value) ? value : [value];
		const selectedRecords = selectedVals
			.map((v) => stateSlice.records[v])
			.filter((r) => r && valueAccessor(r));
		const dropdownRecords = getRecordsForTargetCollection(stateSlice, targetCollectionName);
		const visibleRecords = selectedRecords.concat(
			dropdownRecords.filter((r) => !selectedVals.some((val) => valueAccessor(r) == val))
		);

		const fetching = stateSlice.fetching;
		const noVisibleRecordsWithoutSearch =
			nullSafeGetOrElse('searchText.length', this.state, 0) === 0 && visibleRecords.length === 0;

		const renderingData = disabled && emptyWhenDisabled ? [] : this.state.treeData;

		return (
			<TreeSelect
				multiple={mode === 'multiple'}
				treeCheckable={mode === 'multiple'}
				showCheckedStrategy={SHOW_PARENT}
				treeDefaultExpandAll={true}
				notFoundContent={fetching ? <Spin size="small" /> : null}
				placeholder={disabled || noVisibleRecordsWithoutSearch ? disabledPlaceholder : placeholder}
				loading={loading}
				showSearch={true}
				filterTreeNode={false}
				treeNodeFilterProp="title"
				onChange={this.handleValueChange}
				treeData={renderingData}
				size={size}
				style={style}
				allowClear={allowClear}
				value={disabled && emptyWhenDisabled ? [] : value}
				disabled={disabled || noVisibleRecordsWithoutSearch}
				onSearch={this.handleSearch}
			/>
		);
	}
}

import { BRANDS_CRUD_ACTION_CREATORS } from '../actions/brands_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';

export const brandsRestCrudThunksForBuyer = new RestCrudThunks(
	'brands',
	'brand',
	'brand',
	'brands',
	BRANDS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'brand/'
);
export const brandsRestCrudThunksForSupplier = new RestCrudThunks(
	'brands',
	'brand',
	'brand',
	'brands',
	BRANDS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'brand/'
);

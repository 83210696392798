import React, { FC, useMemo } from 'react';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { connect } from 'react-redux';
import { ROLE_TYPES } from '../../utils/DataConstants';

const AssetNumberDisplay: FC<any> = ({
	asset,
	companyConfig,
	style = {},
	userType,
	currentUser,
}): React.ReactElement => {
	const removeAssetNumberToQRCodeAssociation = useMemo(
		() =>
			userType === ROLE_TYPES.SUPPLIER
				? nullSafeGetOrElse(
						'facility.privateBuyerCompanySettings.config.assetConfig.removeAssetNumberToQRCodeAssociation',
						currentUser,
						false
				  )
				: nullSafeGetOrElse(
						'config.assetConfig.removeAssetNumberToQRCodeAssociation',
						companyConfig,
						false
				  ),
		[companyConfig, currentUser, userType]
	);

	return !removeAssetNumberToQRCodeAssociation && asset.assetNumber ? (
		<div style={style} className="text-gray-400">{`Asset # ${asset.assetNumber}`}</div>
	) : null;
};

const mapStateToProps = (state) => ({
	companyConfig: state.company_config.detail,
	currentUser: state.session.currentUser,
});

const ComponentWithoutUserType = connect(mapStateToProps)(AssetNumberDisplay);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import React from 'react';
import { Spin, TreeSelect } from 'antd';
import { ReactNode } from 'react';
import { getObjectValues } from '../../utils/DataAccessUtils';
import * as _ from 'lodash';

interface OWLocationAsyncSelectTreeProps {
	stateSlice: any;
	targetCollectionName: string;
	placeholder?: string;
	disabledPlaceholder?: string;
	valueAccessor?: any;
	additionalFilters?: any;
	initialPagination?: any;
	allowClear?: boolean;
	size?: 'large' | 'small';
	style?: any;
	mode?: 'default' | 'multiple' | 'tags' | 'combobox';
	value?: any;
	onChange?: any;
	loading?: boolean;
	disabled?: boolean;
	emptyWhenDisabled?: boolean;
	sortBy?: any;

	fetchMultiple(ids: string[] | number[], targetCollectionName?: string): void;

	renderRecord(data: any): ReactNode;

	fetchData(
		searchText: string,
		targetCollectionName: string,
		pagination?: any,
		sorting?: any,
		filters?: any,
		addToTargetCollection?: boolean
	): Promise<any[]>;
}

const defaultPagination = { current: 1, pageSize: 1000 };
const { SHOW_PARENT } = TreeSelect;
const addKeys = (locations, renderRecord) =>
	locations
		? locations.map((location) => ({
				...location,
				title: renderRecord(location),
				filterValue: location.id.toString().concat(' - ', location.name),
				key: location.id,
				value: location.id,
				children: addKeys(location.children, renderRecord),
		  }))
		: [];

export default class OWLocationAsyncTreeSelect extends React.Component<
	OWLocationAsyncSelectTreeProps,
	any
> {
	constructor(props) {
		super(props);
		const value = props.value;
		this.state = {
			value: value,
			treeData: [],
			childMap: {},
			parentMap: {},
		};
	}
	arrDataToTreeData = (arrData, mode) => {
		const { renderRecord } = this.props;
		let mapData = {};
		let parentMap = {};
		let childMap = {};
		let childIds = [];
		arrData.forEach((el, i) => {
			el.key = i;
			if (el.childrenIds && el.childrenIds.length > 0) {
				parentMap[i] = el.id;
				childIds = [...childIds, ...el.childrenIds];
			} else {
				childMap[i] = el.id;
			}
			mapData[i] = el;
		});
		const transformRecord = (el) => ({
			value: el.id,
			key: el.id,
			title: renderRecord(el),
			children: el.children ? el.children.map((child) => transformRecord(child)) : [],
		});
		const recs = arrData.map((el) => transformRecord(el));
		const treeData = recs.filter((rec) => {
			return !this.getKeysFromKeyMap(childIds, childMap).includes(rec.value);
		});
		this.setState({ treeData, childMap, parentMap });
	};

	componentWillReceiveProps(nextProps) {
		const { renderRecord } = this.props;
		// Should be a controlled component.
		if ('value' in nextProps && nextProps.value) {
			this.setState({ value: nextProps.value });
		}
		if (!_.isEqual(nextProps.additionalFilters, this.props.additionalFilters)) {
			this.props
				.fetchData(
					this.state.searchText && this.state.searchText.trim(),
					nextProps.targetCollectionName,
					this.props.initialPagination || defaultPagination,
					nextProps.sortBy,
					nextProps.additionalFilters,
					false
				)
				.then((arrData) => {
					const treeData = addKeys(arrData, renderRecord);
					this.setState({ treeData });
					//this.arrDataToTreeData(arrData, this.props.mode);
				});
		}

		return null;
	}

	componentDidMount() {
		const { value } = this.state;
		const { targetCollectionName, renderRecord } = this.props;
		if (value) {
			this.props.fetchMultiple(Array.isArray(value) ? value : [value], targetCollectionName);
			this.props
				.fetchData(
					'',
					targetCollectionName,
					this.props.initialPagination || defaultPagination,
					this.props.sortBy,
					this.props.additionalFilters,
					false
				)
				.then((arrData) => {
					const treeData = addKeys(arrData, renderRecord);
					this.setState({ treeData });
				});
		} else {
			this.props
				.fetchData(
					'',
					targetCollectionName,
					this.props.initialPagination || defaultPagination,
					this.props.sortBy,
					this.props.additionalFilters,
					false
				)
				.then((arrData) => {
					const treeData = addKeys(arrData, renderRecord);
					this.setState({ treeData });
				});
		}
	}

	handleValueChange = (values) => {
		const { mode } = this.props;
		const valueSet = new Set();
		const elementSet = new Set();
		function addAllChildren(array) {
			array.map(function f(a) {
				if (Array.isArray(a.children) && a.children.length) {
					a.children.map(f);
				} else {
					valueSet.add(a.key);
					elementSet.add(a);
				}
			});
		}

		function find(array, key) {
			var object;
			array.some(function f(a) {
				if (a.key === key) {
					object = a;
					return true;
				}
				if (Array.isArray(a.children) && a.children.length) {
					return a.children.some(f);
				}
			});
			return object;
		}
		if (mode === 'multiple') {
			values.map((key) => {
				const entity = find(this.state.treeData, key);
				if (entity && entity.children && entity.children.length) {
					addAllChildren(entity.children);
				} else if (entity) {
					valueSet.add(entity.key);
					elementSet.add(entity);
				}
			});
		} else {
			elementSet.add(find(this.state.treeData, values));
		}
		const updatedValue = mode === 'multiple' ? Array.from(valueSet) : values;
		const updatedElement = mode === 'multiple' ? Array.from(elementSet) : Array.from(elementSet)[0];
		if (!('value' in this.props)) {
			this.setState({ value: updatedValue });
		}
		this.triggerChange(updatedValue, updatedElement);
	};

	triggerChange = (changedValue, el) => {
		// Should provide an event to pass value to Form.
		const onChange = this.props.onChange;
		if (onChange) {
			onChange(changedValue, el);
		}
	};

	getKeysFromKeyMap = (values, keyMap) => {
		return values && keyMap
			? Array.isArray(values)
				? values.map((value) => {
						let i = -1;
						return Object.values(keyMap).find((keyCheck) => {
							i++;
							return value === keyCheck;
						})
							? parseInt(Object.keys(keyMap)[i])
							: undefined;
				  })
				: Object.keys(keyMap)[getObjectValues(keyMap).indexOf(values)]
			: [];
	};
	getValuesFromKeyMap = (keys, keyMap) => {
		return keyMap ? (Array.isArray(keys) ? keys.map((key) => keyMap[key]) : [keyMap[keys]]) : [];
	};

	render() {
		const { value } = this.state;
		const {
			disabled,
			allowClear = false,
			loading,
			emptyWhenDisabled,
			stateSlice,
			mode,
			placeholder,
			disabledPlaceholder,
			size,
			style,
		} = this.props;
		const selectedVals = Array.isArray(value) ? value : !!value ? [value] : [];

		const fetching = stateSlice.fetching;
		return (
			<TreeSelect
				multiple={mode === 'multiple'}
				treeCheckable={mode === 'multiple'}
				showCheckedStrategy={SHOW_PARENT}
				treeDefaultExpandAll
				notFoundContent={fetching ? <Spin size="small" /> : null}
				placeholder={disabled ? disabledPlaceholder : placeholder}
				loading={loading}
				showSearch={true}
				treeNodeFilterProp="filterValue"
				treeNodeLabelProp="title"
				onChange={this.handleValueChange}
				treeData={disabled && emptyWhenDisabled ? [] : this.state.treeData}
				size={size}
				style={style}
				allowClear={allowClear}
				value={disabled && emptyWhenDisabled ? [] : selectedVals}
				disabled={disabled}
			/>
		);
	}
}

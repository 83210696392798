import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router';
import { Layout } from 'antd';
import ScrollToTopOnMount from '../scroll_to_top_on_mount/ScrollToTopOnMount';
import SubnavTabs from '../subnav_tabs/SubnavTabs';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';
import { nullSafeGet } from '../../utils/DataAccessUtils';

const { Content } = Layout;
const style = require('./WorkOrdersOverviewPage.less');

class WorkOrdersOverviewPage extends React.Component<any, any> {
	handleTabChange = (key) => {
		const { history } = this.props;
		history.push(`/buyer/workOrders/overview/${key}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	render() {
		const { companyConfig } = this.props;
		const tabs = [
			{
				key: 'active',
				name: 'Active',
			},
			{
				key: 'archived',
				name: 'Archived',
			},
			{
				key: 'all',
				name: 'All',
			},
		];

		return (
			<div>
				<ScrollToTopOnMount />
				<div>
					<Route
						path="/buyer/workOrders/overview"
						exact={true}
						render={(props) => (
							<Redirect
								to={{
									pathname: '/buyer/workOrders/overview/active',
								}}
							/>
						)}
					/>
					<Content className="workOrdersPage" style={{ padding: '0 0.5em' }}>
						{/*<ScrollToTopOnMount/>*/}
						{/*<BackTop/>*/}

						<div
							style={{
								margin: '0 -8px',
								padding: '24px',
								paddingBottom: 0,
								background: nullSafeGet('detail.config.theme.navblock', companyConfig),
								marginBottom: 8,
							}}
						>
							<div
								style={{
									marginBottom: 24,
								}}
							>
								<h4
									style={{
										color: 'rgba(255, 255, 255, 0.9)',
										marginRight: 8,
										marginBottom: 0,
									}}
								>
									Work Orders
								</h4>
							</div>
							<SubnavTabs
								tabs={tabs}
								onChange={this.handleTabChange}
								isActive={this.checkTabActive}
							/>
						</div>
						{this.props.childRoutes.map((r, i) => (
							<RouteWithSubRoutes key={i} {...r} />
						))}
					</Content>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	companyConfig: state.company_config,
});
const mapDispatchToProps = (dispatch) => ({});
export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(WorkOrdersOverviewPage)
);

import {
	LOGGING_IN,
	DONE_LOGGING_IN,
	LOGIN_SUCCESS,
	LOGIN_FAILURE,
	LOGGING_OUT,
	DONE_LOGGING_OUT,
	LOGOUT_SUCCESS,
	LOGOUT_FAILURE,
	SKIP_TOKEN_CHECK,
	CHECK_TOKEN_SUCCESS,
	CHECK_TOKEN_FAILURE,
	REQUESTING_PASSWORD_RESET,
	REQUEST_PASSWORD_RESET_SUCCESS,
	REQUEST_PASSWORD_RESET_FAILURE,
	CHANGING_PASSWORD,
	DONE_CHANGING_PASSWORD,
	PASSWORD_CHANGE_SUCCESS,
	PASSWORD_CHANGE_FAILURE,
	SIGNUP_COMPLETING,
	SIGNUP_COMPLETION_SUCCESS,
	SIGNUP_COMPLETION_FAILURE,
	SIGNUP_FAILURE,
	ACCOUNT_ACTIVATION_SUCCESS,
} from '../actions/session_actions';
import { SessionStoreState } from '../types/models';

const initialState: SessionStoreState = {
	token: '',
	expiresOn: new Date(),
	accountActivated: false,
	initialCheck: false,
	isAuthenticated: false,
	userType: '',
	clientLocation: {},
	resetLinkSent: false,
	currentUser: {},
	errors: [],
	loading: 0,
	workspaceId: null,
	switchingWorkspace: 0,
	confirmWorkspaceChange: 0,
	workSpaceError: [],
	featureFlags: {},
	isActive: false,
	isApproved: false,
};

const reducer = (oldState = initialState, action): SessionStoreState => {
	Object.freeze(oldState);
	switch (action.type) {
		case CHECK_TOKEN_SUCCESS:
			return Object.assign({}, oldState, {
				initialCheck: true,
				isAuthenticated: true,
				token: action.token,
				expiresOn: action.expiresOn,
				accountActivated: action.accountActivated,
				isActive: action.isActive,
				isApproved: action.isApproved,
				userType: action.userType,
				currentUser: action.currentUser,
				workspaceId: action.workspaceId,
				featureFlags: action.featureFlags,
			});
		case ACCOUNT_ACTIVATION_SUCCESS:
			return Object.assign({}, oldState, {
				accountActivated: true,
			});
		case CHECK_TOKEN_FAILURE:
			return Object.assign({}, oldState, {
				initialCheck: true,
				isAuthenticated: false,
				accountActivated: false,
				isActive: false,
				isApproved: false,
				userType: '',
				currentUser: {},
				errors: action.errors,
				featureFlags: {},
			});
		case SKIP_TOKEN_CHECK:
			return Object.assign({}, oldState, { initialCheck: true });
		case LOGGING_IN:
			return Object.assign({}, oldState, {
				loading: 1,
				errors: [],
			});
		case DONE_LOGGING_IN:
			return Object.assign({}, oldState, {
				loading: 0,
			});
		case LOGIN_SUCCESS:
			return Object.assign({}, oldState, {
				errors: [],
				token: action.token,
				expiresOn: action.expiresOn,
				accountActivated: action.accountActivated,
				isActive: action.isActive,
				isApproved: action.isApproved,
				userType: action.userType,
				currentUser: action.currentUser,
				isAuthenticated: true,
				workspaceId: action.workspaceId,
				featureFlags: action.featureFlags,
			});
		case LOGIN_FAILURE:
			return Object.assign({}, oldState, { errors: action.errors });

		case SIGNUP_FAILURE:
			return Object.assign({}, oldState, { errors: action.errors });

		case SIGNUP_COMPLETING:
			return Object.assign({}, oldState, {
				loading: 1,
				errors: [],
			});
		case SIGNUP_COMPLETION_SUCCESS:
			return Object.assign({}, oldState, {
				errors: [],
				loading: 0,
			});
		case SIGNUP_COMPLETION_FAILURE:
			return Object.assign({}, oldState, {
				errors: action.errors,
				loading: 0,
			});
		case CHANGING_PASSWORD:
			return Object.assign({}, oldState, {
				loading: 1,
				errors: [],
			});
		case DONE_CHANGING_PASSWORD:
			return Object.assign({}, oldState, {
				loading: 0,
			});
		case PASSWORD_CHANGE_SUCCESS:
			return Object.assign({}, oldState, { errors: [] });
		case PASSWORD_CHANGE_FAILURE:
			return Object.assign({}, oldState, { errors: action.errors });
		case REQUESTING_PASSWORD_RESET:
			return Object.assign({}, oldState, {
				loading: 1,
				errors: [],
			});
		case REQUEST_PASSWORD_RESET_SUCCESS:
			return Object.assign({}, oldState, {
				errors: [],
				resetLinkSent: true,
				loading: 0,
			});
		case REQUEST_PASSWORD_RESET_FAILURE:
			return Object.assign({}, oldState, {
				errors: action.errors,
				loading: 0,
			});
		case LOGGING_OUT:
			return Object.assign({}, oldState, {
				loading: 1,
				errors: [],
			});
		case DONE_LOGGING_OUT:
			return Object.assign({}, oldState, {
				loading: 0,
			});
		case LOGOUT_SUCCESS:
			const today = new Date();
			const yesterday = new Date(today.getTime() - 1000 * 60 * 60 * 24);
			return Object.assign({}, oldState, {
				errors: [],
				token: '',
				userType: '',
				expiresOn: yesterday,
				accountActivated: false,
				isActive: false,
				isApproved: false,
				currentUser: {},
				isAuthenticated: false,
				featureFlags: {},
			});
		case LOGOUT_FAILURE:
			return Object.assign({}, oldState, { errors: action.errors });
		case 'SWITCHING_WORKSPACE':
			return { ...oldState, switchingWorkspace: 1, confirmWorkspaceChange: 0 };
		case 'SWITCHING_WORKSPACE_SUCCESS':
			return { ...oldState, switchingWorkspace: 0, workspaceId: action.id };
		case 'SWITCHING_WORKSPACE_FAILURE':
			return { ...oldState, switchingWorkspace: 0, workSpaceError: action.error };
		case 'INITIATE_WORKSPACE_CHANGE':
			return { ...oldState, confirmWorkspaceChange: action.id };
		case 'CANCEL_WORKSPACE_CHANGE':
			return { ...oldState, confirmWorkspaceChange: 0 };
		default:
			return oldState;
	}
};

export default reducer;

import * as React from 'react';
import { Spin, TreeSelect } from 'antd';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { ReactNode } from 'react';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import * as _ from 'lodash';

interface LocationsTreeSelectProbs {
	stateSlice: any;
	targetCollectionName: string;
	placeholder?: string;
	disabledPlaceholder?: string;
	valueAccessor?: any;
	additionalFilters?: any;
	initialPagination?: any;
	allowClear?: boolean;
	size?: 'large' | 'small';
	style?: any;
	mode?: 'default' | 'multiple' | 'tags' | 'combobox';
	value?: any;
	onChange?: any;
	loading?: boolean;
	disabled?: boolean;
	emptyWhenDisabled?: boolean;
	sortBy?: any;
	hiddenRecords?: any;

	fetchMultiple(ids: string[] | number[], targetCollectionName?: string): void;

	renderRecord(data: any): ReactNode;

	fetchData(
		searchText: string,
		targetCollectionName: string,
		pagination?: any,
		sorting?: any,
		filters?: any,
		addToTargetCollection?: boolean
	): Promise<any[]>;
}

const defaultPagination = { current: 1, pageSize: 1000 };
const { SHOW_PARENT } = TreeSelect;
const addKeys = (locations, renderRecord) =>
	locations
		? locations.map((location) => ({
				...location,
				title: renderRecord(location),
				filterValue: location.id.toString().concat(' - ', location.name),
				key: location.id,
				value: location.id,
				children: addKeys(location.children, renderRecord),
		  }))
		: [];

export default class LocationsTreeSelect extends React.Component<LocationsTreeSelectProbs, any> {
	constructor(props) {
		super(props);
		const value = props.value;
		this.state = {
			value: value,
			treeData: [],
		};
	}

	componentWillReceiveProps(nextProps) {
		// Should be a controlled component.
		if ('value' in nextProps) {
			this.setState({ value: nextProps.value });
		}
		if (!_.isEqual(nextProps.additionalFilters, this.props.additionalFilters)) {
			this.props
				.fetchData(
					this.state.searchText && this.state.searchText.trim(),
					nextProps.targetCollectionName,
					this.props.initialPagination || defaultPagination,
					nextProps.sortBy,
					nextProps.additionalFilters,
					false
				)
				.then((arrData) => {
					const treeData = addKeys(arrData, this.props.renderRecord);
					this.setState({ treeData });
				})
				.catch((err) => console.log(err));
		}

		return null;
	}

	componentDidMount() {
		const { value } = this.state;
		const { targetCollectionName } = this.props;
		if (value) {
			this.props.fetchMultiple(Array.isArray(value) ? value : [value], targetCollectionName);
			this.props
				.fetchData(
					'',
					targetCollectionName,
					this.props.initialPagination || defaultPagination,
					this.props.sortBy,
					this.props.additionalFilters,
					false
				)
				.then((arrData) => {
					const treeData = addKeys(arrData, this.props.renderRecord);
					this.setState({ treeData });
				});
		} else {
			this.props
				.fetchData(
					'',
					targetCollectionName,
					this.props.initialPagination || defaultPagination,
					this.props.sortBy,
					this.props.additionalFilters,
					false
				)
				.then((arrData) => {
					const treeData = addKeys(arrData, this.props.renderRecord);
					this.setState({ treeData });
				});
		}
	}

	handleValueChange = (values) => {
		const { mode } = this.props;
		const valueSet = new Set();
		const elementSet = new Set();
		function addAllChildren(array) {
			array.map(function f(a) {
				if (Array.isArray(a.children) && a.children.length) {
					a.children.map(f);
				} else {
					valueSet.add(a.key);
					elementSet.add(a);
				}
			});
		}

		function find(array, key) {
			var object;
			array.some(function f(a) {
				if (a.key === key) {
					object = a;
					return true;
				}
				if (Array.isArray(a.children) && a.children.length) {
					return a.children.some(f);
				}
			});
			return object;
		}
		if (mode === 'multiple') {
			values.map((key) => {
				const entity = find(this.state.treeData, key);
				if (entity && entity.children && entity.children.length) {
					addAllChildren(entity.children);
				} else if (entity) {
					valueSet.add(entity.key);
					elementSet.add(entity);
				}
			});
		} else {
			elementSet.add(find(this.state.treeData, values));
		}
		const updatedValue = mode === 'multiple' ? Array.from(valueSet) : values;
		const updatedElement = mode === 'multiple' ? Array.from(elementSet) : Array.from(elementSet)[0];
		if (!('value' in this.props)) {
			this.setState({ value: updatedValue });
		}
		this.triggerChange(updatedValue, updatedElement);
	};

	triggerChange = (changedValue, el) => {
		// Should provide an event to pass value to Form.
		const onChange = this.props.onChange;
		if (onChange) {
			onChange(changedValue, el);
		}
	};

	hideRecords = (records) => {
		return records.filter((record) => this.recursiveFilter(record));
	};

	recursiveFilter = (record) => {
		const { hiddenRecords = [] } = this.props;

		if (
			record.children.length > 0 &&
			record.children.filter((child) => this.recursiveFilter(child)).length === 0
		) {
			//has children, but all children are filtered
			return false;
		}

		return record.children.length > 0
			? (record.children = record.children.filter((child) => this.recursiveFilter(child))) //filter children
			: !hiddenRecords.includes(record.id); //check if record is filtered
	};

	render() {
		const { value } = this.state;
		const {
			disabled,
			allowClear = false,
			loading,
			emptyWhenDisabled,
			valueAccessor = (el) => el.id,
			stateSlice,
			targetCollectionName,
			mode,
			placeholder,
			disabledPlaceholder,
			size,
			style,
			renderRecord,
			hiddenRecords = [],
		} = this.props;
		const selectedVals = Array.isArray(value) ? value : [value];
		const selectedRecords = selectedVals
			.map((v) => stateSlice.records[v])
			.filter((r) => r && valueAccessor(r));
		const dropdownRecords = getRecordsForTargetCollection(stateSlice, targetCollectionName).filter(
			(record) => !hiddenRecords.includes(record.id)
		);
		const visibleRecords = selectedRecords.concat(
			dropdownRecords.filter((r) => !selectedVals.some((val) => valueAccessor(r) == val))
		);
		const fetching = stateSlice.fetching;
		const noVisibleRecordsWithoutSearch =
			nullSafeGetOrElse('searchText.length', this.state, 0) === 0 && visibleRecords.length === 0;
		const filteredTreeData = this.hideRecords(this.state.treeData);
		return (
			<TreeSelect
				multiple={mode === 'multiple'}
				treeCheckable={mode === 'multiple'}
				showCheckedStrategy={SHOW_PARENT}
				treeDefaultExpandAll
				notFoundContent={fetching ? <Spin size="small" /> : null}
				placeholder={disabled || noVisibleRecordsWithoutSearch ? disabledPlaceholder : placeholder}
				loading={loading}
				showSearch={true}
				treeNodeFilterProp="filterValue"
				treeNodeLabelProp="title"
				onChange={this.handleValueChange}
				treeData={disabled && emptyWhenDisabled ? [] : filteredTreeData}
				size={size}
				style={style}
				allowClear={allowClear}
				value={disabled && emptyWhenDisabled ? [] : value}
				disabled={disabled || noVisibleRecordsWithoutSearch}
			/>
		);
	}
}

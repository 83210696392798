import React, { FC, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import DateTimeHover from '../date_time_component/DateTime';
import { UserName } from '../name_component/user/UserNameComponent';
import { ProtectedImageThumbnails } from '../protected_image_thumbnails/ProtectedImageThumbnails';
import { Button, Card, Divider, Timeline } from 'antd';
import moment from 'moment';
import { isInternalTech, PERMISSION_NAMES } from '../../utils/AuthUtils';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { ContactAvatar } from '../contact_avatar/ContactAvatar';

const SupplierServiceCallDetails: FC<any> = ({
	currentUser,
	workOrder,
	handleManualCheckOut,
	showApproveModal,
	showRejectModal,
	handleReschedule,
	handleManualCheckIn,
	isReadOnly,
}): React.ReactElement => {
	const userType = useMemo(() => currentUser.userType, [currentUser.userType]);

	const isPrivateSupplier = useMemo(() => isInternalTech(currentUser), [currentUser]);

	const differentAssignedSupplier = useMemo(
		() =>
			isPrivateSupplier
				? nullSafeGet('facility.id', currentUser) !== workOrder.supplierFacilityId
				: false,
		[currentUser, isPrivateSupplier, workOrder.supplierFacilityId]
	);

	const getServiceCallDetails = useCallback(
		(serviceCall) => [
			...(serviceCall.serviceScheduledAt
				? [
						<div>
							<div style={{ fontSize: 16 }}>
								Scheduled to arrive by{' '}
								<DateTimeHover
									timestamp={nullSafeGet('serviceScheduledAt', serviceCall)}
									dateFormat={'h:mm a on MMM D'}
									showDate
									parseZone={false}
								/>
								.
							</div>
							{!serviceCall.checkOutStatus &&
							!isReadOnly &&
							(workOrder.status === 'TechScheduled' || workOrder.status === 'TechRescheduled') ? (
								<div>
									<Button
										style={{ marginTop: 16 }}
										type="primary"
										key="reschedule"
										onClick={handleReschedule}
									>
										Reschedule
									</Button>
									<Button
										style={{ marginTop: 16, marginLeft: 16 }}
										type="primary"
										key="manualCheckIn"
										onClick={handleManualCheckIn}
									>
										Remote Check In
									</Button>
								</div>
							) : null}
						</div>,
				  ]
				: []),
			...(serviceCall.checkInTime
				? [
						<div style={{ fontSize: 16 }}>
							<div>
								Started work at{' '}
								<DateTimeHover
									timestamp={nullSafeGet('checkInTime', serviceCall)}
									dateFormat={'h:mm a on MMM D'}
									showDate
								/>
								.
								{serviceCall.checkInGeoLocation ? null : nullSafeGet(
										`checkInByContact`,
										serviceCall
								  ) ? (
									<span style={{ color: 'rgba(0,0,0,0.45)' }}>
										{' '}
										(Checked in remotely by{' '}
										<UserName
											mode={'inline'}
											contact={nullSafeGet(`checkInByContact`, serviceCall)}
										/>
										)
									</span>
								) : null}
							</div>
							{serviceCall.checkInNotes && serviceCall.checkInNotes.length > 0 ? (
								<div className="serviceCallNotesBlurb">{serviceCall.checkInNotes}</div>
							) : null}
							{serviceCall.checkInImages && serviceCall.checkInImages.length > 0 ? (
								<ProtectedImageThumbnails
									style={{ marginTop: 16 }}
									imageWidth={64}
									imageHeight={64}
									photoStrings={serviceCall.checkInImages}
									role={userType}
								/>
							) : null}
							{!isReadOnly &&
							!serviceCall.checkOutStatus &&
							workOrder.status === 'TechWorkingOnSite' ? (
								<div>
									<Button
										style={{ marginTop: 16 }}
										type="primary"
										key="manualCheckOut"
										onClick={handleManualCheckOut}
									>
										Remote Check Out
									</Button>
								</div>
							) : null}
						</div>,
				  ]
				: []),
			...(serviceCall.checkOutTime
				? [
						<div style={{ fontSize: 16 }}>
							<div>
								Ended work at{' '}
								<DateTimeHover
									timestamp={nullSafeGet('checkOutTime', serviceCall)}
									dateFormat={'h:mm a on MMM D'}
									showDate
								/>
								.
								{serviceCall.checkOutGeoLocation ? null : nullSafeGet(
										`checkOutByContact`,
										serviceCall
								  ) ? (
									<span style={{ color: 'rgba(0,0,0,0.45)' }}>
										{' '}
										(Checked out remotely by{' '}
										<UserName
											mode={'inline'}
											contact={nullSafeGet(`checkOutByContact`, serviceCall)}
										/>
										)
									</span>
								) : null}
							</div>
							{serviceCall.checkOutNotes && serviceCall.checkOutNotes.length > 0 ? (
								<div className="serviceCallNotesBlurb">{serviceCall.checkOutNotes}</div>
							) : null}

							{serviceCall.checkOutImages && serviceCall.checkOutImages.length > 0 ? (
								<ProtectedImageThumbnails
									style={{ marginTop: 16 }}
									imageWidth={64}
									imageHeight={64}
									photoStrings={serviceCall.checkOutImages}
									role={userType}
								/>
							) : null}
						</div>,
				  ]
				: []),
			//Work was completed by a third party and this user is an internal technician
			...(serviceCall.checkOutTime && isPrivateSupplier && differentAssignedSupplier
				? [
						<div style={{ fontSize: 16 }}>
							{serviceCall.checkInTime && serviceCall.checkOutTime ? (
								<div>
									On site for{' '}
									{moment(serviceCall.checkInTime).from(moment(serviceCall.checkOutTime), true)}
								</div>
							) : null}
							{!isReadOnly &&
							serviceCall.checkOutStatus === 'WaitingForReview' &&
							workOrder.status === 'WaitingForReview'
								? [
										<AccessPermissionChecker
											name={PERMISSION_NAMES.CAN_REVIEW_WORK_ORDER_COMPLETED}
										>
											<Button
												style={{ marginTop: 16 }}
												type="primary"
												key="approve"
												onClick={showApproveModal}
											>
												Approve Work
											</Button>
										</AccessPermissionChecker>,
										<AccessPermissionChecker
											name={PERMISSION_NAMES.CAN_REVIEW_WORK_ORDER_COMPLETED}
										>
											<Button
												key="reject"
												style={{ marginLeft: 16, marginTop: 16 }}
												onClick={showRejectModal}
											>
												Reject Work
											</Button>
										</AccessPermissionChecker>,
								  ]
								: null}
						</div>,
				  ]
				: []),
			//If work was completed by internal technicians
			...(serviceCall.checkOutTime && isPrivateSupplier && !differentAssignedSupplier
				? [
						<div style={{ fontSize: 16 }}>
							{serviceCall.checkInTime && serviceCall.checkOutTime ? (
								<div>
									On site for{' '}
									{moment(serviceCall.checkInTime).from(moment(serviceCall.checkOutTime), true)}
								</div>
							) : null}
						</div>,
				  ]
				: //Other
				serviceCall.checkInTime && serviceCall.checkOutTime
				? [
						<div style={{ fontSize: 16 }}>
							{serviceCall.checkInTime && serviceCall.checkOutTime ? (
								<div>
									On site for{' '}
									{moment(serviceCall.checkInTime).from(moment(serviceCall.checkOutTime), true)}
								</div>
							) : null}
						</div>,
				  ]
				: []),
		],
		[
			differentAssignedSupplier,
			handleManualCheckIn,
			handleManualCheckOut,
			handleReschedule,
			isPrivateSupplier,
			isReadOnly,
			showApproveModal,
			showRejectModal,
			userType,
			workOrder.status,
		]
	);

	const ConditionalWrapper = ({ condition, wrapper, children }) =>
		condition ? wrapper(children) : children;

	return (
		<ConditionalWrapper
			condition={isReadOnly}
			wrapper={(children) => <Card title="Service Calls">{children}</Card>}
		>
			{nullSafeGetOrElse('associatedServiceCalls', workOrder, []).map((sc, scIdx) => [
				<div key={sc.id + '-1'}>
					<div style={{ marginBottom: 24 }}>
						<div className="serviceCallSupplierName" style={{ marginBottom: 8 }}>
							{nullSafeGet('supplierFacility.name', sc)}
						</div>
						{sc.leadTechnicianEmail ? (
							<div
								className="materialCard__primaryTitle"
								style={{
									marginBottom: 0,
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<ContactAvatar
									size="large"
									hidePopover={true}
									style={{ marginRight: 16 }}
									contact={sc.leadTechnicianEmailContact}
									userType={userType}
								/>
								<div>
									<div className="materialCard__title">
										{nullSafeGet('leadTechnicianEmailContact.nameGiven', sc)}{' '}
										{nullSafeGet('leadTechnicianEmailContact.nameFamily', sc)}
									</div>
									<div className="materialCard__subtitle">
										{nullSafeGet('leadTechnicianEmailContact.title', sc)}{' '}
										{nullSafeGet('leadTechnicianEmailContact.department', sc)}
									</div>
								</div>
							</div>
						) : null}
					</div>
					<Timeline>
						{getServiceCallDetails(sc).map((scd, idx) => (
							<Timeline.Item key={idx}>{scd}</Timeline.Item>
						))}
					</Timeline>
				</div>,
				scIdx === nullSafeGetOrElse('associatedServiceCalls', workOrder, []).length - 1 ? null : (
					<Divider key={sc.id + '-2'} />
				),
			])}
		</ConditionalWrapper>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

export default connect(mapStateToProps)(SupplierServiceCallDetails);

import { UndoOutlined } from '@ant-design/icons';
import React from 'react';

export const QUOTE_MULTI_SELECT_ACTION_CONFIG_FOR_BUYER = [
	{
		label: 'Mark Awarded',
		statuses: ['pending', 'disputed'],
		toStatus: 'awarded',
	},
	{
		label: 'Mark Declined',
		statuses: ['pending'],
		toStatus: 'declined',
	},
	{
		label: 'Back To Pending',
		icon: <UndoOutlined translate="" />,
		statuses: ['awarded', 'declined'],
		toStatus: 'pending',
	},
];

export const QUOTE_MULTI_SELECT_ACTION_CONFIG_FOR_SUPPLIER = [
	{
		label: 'Publish',
		statuses: ['draft'],
		toStatus: 'pending',

		requireParams: ['quotePDFLink'],
		disableMessage: 'Not all quotes have a PDF attached. Please upload a PDF to Publish.',
		refLink: '/supplier/quotes/detail/#/details',
	},
];

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	clearWorkAssetTypeFilters,
	updateWorkAssetTypeFilters,
} from '../../actions/work_analytics_actions';
import { fetchWorkOrderCountByAssetType } from '../../thunks/work_analytics_thunks';
import WorkAnalyticsCategoryBreakdownPage from '../work_analytics_category_breakdown_page/WorkAnalyticsCategoryBreakdownPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

class WorkAnalyticsOverviewAssetTypesPage extends React.Component<any, any> {
	render() {
		const { analytics, userType } = this.props;
		const { workOrderCountByAssetType, assetTypeFilters } = analytics;

		return [
			<LogOnMountWithStandardEventProperties eventType="visited work analytics asset types page" />,
			<WorkAnalyticsCategoryBreakdownPage
				updateFilters={updateWorkAssetTypeFilters}
				clearFilters={clearWorkAssetTypeFilters}
				workOrderCountByCategory={workOrderCountByAssetType}
				filters={assetTypeFilters}
				fetchWorkOrderCountsByCategory={fetchWorkOrderCountByAssetType(userType)}
				fieldDisplayName="Asset Type"
			/>,
		];
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	accountActivated: state.session.accountActivated,
	currentUser: state.session.currentUser,
	analytics: state.work_analytics,
	userType: (state as any).session.userType,
});

const mapDispatchToProps = (dispatch) => ({});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(WorkAnalyticsOverviewAssetTypesPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

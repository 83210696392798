import * as React from 'react';
import { Layout, Row, Col, Card, Button, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { invoicesRestCrudThunksForBuyer } from '../../thunks/invoices_thunks';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { EmptyState } from '../empty_state/EmptyState';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { Link } from 'react-router-dom';
import {
	appendWorkOrderNotesForBuyer,
	workOrdersRestCrudThunksForBuyer,
} from '../../thunks/work_orders_thunks';
import InvoiceUploader from '../invoice_uploader/InvoiceUploader';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import InvoicePageDisplay from '../invoice_page_display/InvoicePageDisplay';

const { Content } = Layout;

require('./WorkOrdersDetailInvoicesPage.less');

class WorkOrderInvoicesDetailPage extends React.Component<any, any> {
	formRefs: any;

	constructor(props) {
		super(props);

		this.formRefs = {
			editGLCodeForm: null,
			editPONumberForm: null,
		};

		this.state = {
			editGLCodeFormVisible: false,
			editPONumberFormVisible: false,
			selectedInvoice: {},
			invoiceExists: false,
		};
	}

	refreshInvoice = () => {
		const { getWorkOrder, getInvoices, getCount, workOrder, match } = this.props;
		const workOrderId = workOrder.id || nullSafeGet('params.id', match);
		getInvoices({ workOrderId }, 'workOrderAssociatedInvoices');
		getCount(workOrderId, 'draft').then((d) => this.setState({ invoiceExists: d > 0 }));
		getWorkOrder(workOrderId);
	};

	componentDidMount() {
		const { getInvoices, getCount, workOrder, match } = this.props;
		const workOrderId = workOrder.id || nullSafeGet('params.id', match);
		getInvoices({ workOrderId }, 'workOrderAssociatedInvoices');
		getCount(workOrderId, 'draft').then((d) => this.setState({ invoiceExists: d > 0 }));
	}

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	handleInvoicePDFUploadChange = (invoice) => (invoiceFileUrl) => {
		const { updateInvoice } = this.props;

		const newInvoice = {
			...invoice,
			invoicePDFLink: invoiceFileUrl,
			isDeleted: false,
		};
		updateInvoice(newInvoice).then(() => {
			message.success(`Invoice published.`);
			this.refreshInvoice();
		});
	};

	render() {
		const { invoices, invoicesFetching, workOrder } = this.props;
		const workOrderId = workOrder.id;
		const hasSupplier = !!nullSafeGet('supplierFacilityId', workOrder);

		const associatedInvoices = getRecordsForTargetCollection(
			invoices,
			'workOrderAssociatedInvoices'
		);

		return invoicesFetching ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="invoicesDetailDetailsPage">
				<LogOnMountWithStandardEventProperties eventType="visited buyer work order detail invoices page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				{associatedInvoices.length > 0 ? (
					<div>
						{associatedInvoices.map((invoice) => {
							if (invoice.isDeleted) {
								return (
									<Row gutter={12}>
										<Col span={24}>
											<Card>
												<div>
													<h5 style={{ marginBottom: '4px' }}>Upload an invoice file</h5>
													<div>
														<p
															style={{
																fontSize: 16,
																marginBottom: '0.5em',
															}}
														>
															Add an invoice file (PDF) to finish creating the invoice.
														</p>
														<InvoiceUploader
															roleType="buyer"
															invoiceId={invoice.id}
															buttonType="primary"
															defaultFileList={[]}
															multiple={false}
															handleUploadSuccess={this.handleInvoicePDFUploadChange(invoice)}
														/>
													</div>
												</div>
											</Card>
										</Col>
									</Row>
								);
							} else {
								return (
									<InvoicePageDisplay
										invoice={invoice}
										refreshInvoice={this.refreshInvoice}
										showViewInvoicePage={true}
									/>
								);
							}
						})}
					</div>
				) : (
					<EmptyState
						graphic={
							<img
								alt="You haven't received an invoice for this work order yet."
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/bank terminal.svg"
							/>
						}
						headline={"You haven't received an invoice for this work order yet."}
						body={
							hasSupplier ? (
								!this.state.invoiceExists ? (
									<AccessPermissionChecker name={PERMISSION_NAMES.CAN_UPLOAD_INVOICES_FOR_SUPPLIER}>
										<div style={{ textAlign: 'center' }}>
											<Link to={`/buyer/workOrders/detail/${workOrderId}/invoices/new`}>
												<Button type="primary">Create Invoice</Button>
											</Link>
										</div>
									</AccessPermissionChecker>
								) : (
									<span>
										Your service provider has drafted an invoice, it just hasn't been published yet.
									</span>
								)
							) : null
						}
					/>
				)}
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	workOrdersFetching: state.work_orders.fetching,
	workOrder: state.work_orders.detail,
	invoicesFetching: state.invoices.fetching,
	invoices: state.invoices,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	appendWorkOrderNotes: (entity) => dispatch(appendWorkOrderNotesForBuyer(entity)),
	getWorkOrder: (id) => dispatch(workOrdersRestCrudThunksForBuyer.readOne(id)),
	getInvoices: (params, targetCollectionName) =>
		dispatch(invoicesRestCrudThunksForBuyer.read(params, targetCollectionName)),
	getCount: (woId, status) =>
		dispatch(invoicesRestCrudThunksForBuyer.countBy({ workOrderId: woId, status: status }, status)),
	updateInvoice: (entity) => dispatch(invoicesRestCrudThunksForBuyer.update(entity)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(WorkOrderInvoicesDetailPage)
);

import React, { FC, useMemo } from 'react';
import { connect } from 'react-redux';
import { regionsRestCrudThunksForSupplier } from '../../thunks/regions_thunks';
import { TreeSelect } from 'antd';
import {
	fetchLocationsWithBuyerCompaniesForSupplier,
	fetchLocationsWithRegionsAndSubRegionsForSupplier,
} from '../../thunks/locations_thunks';
import LocationsTreeSelect from '../locations_tree_select/LocationsTreeSelect';
import { isInternalTech } from '../../utils/AuthUtils';
import { LocationDropdownRecord } from '../name_component/location/LocationDropdownRecord';

const SupplierRegionsLocationsTreeSelect: FC<any> = ({
	onChange,
	mode,
	targetCollectionName,
	filtersValueAccessor,
	filtersSlice,
	locations,
	fetchLocationsWithRegionHierarchy,
	fetchLocationsWithBuyerCompanies,
	fetchMultipleRegions,
	placeholder,
	currentUser,
	disabled,
}): React.ReactElement => {
	const locationsFetchFunction = useMemo(
		() =>
			isInternalTech(currentUser)
				? fetchLocationsWithRegionHierarchy
				: fetchLocationsWithBuyerCompanies,
		[currentUser, fetchLocationsWithBuyerCompanies, fetchLocationsWithRegionHierarchy]
	);

	return (
		<LocationsTreeSelect
			disabled={disabled}
			allowClear={true}
			style={{ width: '100%' }}
			stateSlice={locations}
			targetCollectionName={targetCollectionName}
			value={filtersValueAccessor(filtersSlice)}
			onChange={onChange}
			placeholder={placeholder}
			fetchData={(
				searchText,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			) => {
				return locationsFetchFunction(
					{ name: searchText },
					targetCollectionName,
					pagination,
					sorting,
					filters,
					addToTargetCollection
				);
			}}
			fetchMultiple={(ids, targetCollectionName) => fetchMultipleRegions(ids, targetCollectionName)}
			renderRecord={(record) => <LocationDropdownRecord record={record} />}
			sortBy={{ sort_by: 'name', order: 'ascend' }}
			mode={mode}
		/>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	filtersSlice: ownProps.filtersSlice,
	regions: state.regions,
	locations: state.locations,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	fetchLocationsWithBuyerCompanies: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			fetchLocationsWithBuyerCompaniesForSupplier(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchLocationsWithRegionHierarchy: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			fetchLocationsWithRegionsAndSubRegionsForSupplier(
				{ ...params, noIsActiveFilter: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchRegions: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			regionsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleRegions: (ids, targetCollectionName) =>
		dispatch(regionsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupplierRegionsLocationsTreeSelect);

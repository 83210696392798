import React, { FC } from 'react';
import { Row, Button } from 'antd';
import { CloudDownloadOutlined, PlusCircleFilled } from '@ant-design/icons';
import { FlatfileButton } from '@flatfile/react';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

interface UploadTaskListPopupProps {
	currentUser: any;
	isTemplate: boolean;
	onDownloadTaskList: () => void;
	onCancel: () => void;
	onSaveTaskList: (taskList: any) => void;
}

const UploadTaskListPopup: FC<UploadTaskListPopupProps> = ({
	currentUser,
	isTemplate,
	onDownloadTaskList,
	onCancel,
	onSaveTaskList,
}): React.ReactElement => {
	return (
		<div>
			<Row>
				Please note that uploading will clear the existing tasks. You can choose to download the
				existing list, add new tasks and upload! Do you want to download?
			</Row>
			<Row>
				<Button
					type="primary"
					style={{ marginTop: '24px', width: '100%' }}
					onClick={onDownloadTaskList}
				>
					<CloudDownloadOutlined translate="" />
					Download task list
				</Button>
			</Row>
			<Row>
				<FlatfileButton
					settings={{
						type: `tasks - Site Survey${isTemplate ? ' Template' : ''}`,
						allowCustom: true,
						autoDetectHeaders: true,
						disableManualInput: true,
						displayEncoding: false,
						fields: [
							{
								label: 'Title',
								key: 'title',
							},
							{
								label: 'Description',
								key: 'description',
							},
							{
								label: 'Group',
								key: 'groupTitle',
							},
							{
								label: 'Question Type',
								key: 'questionType',
								type: 'select',
								options: [
									{ label: 'Multiple Choice', value: 'multipleChoice' },
									{ label: 'Free Text', value: 'textbox' },
								],
							},
							{
								label: 'Answer Options',
								key: 'multipleChoicesWithLabel',
								validators: [
									{
										validate: 'required_with_values',
										fieldValues: {
											questionType: 'multipleChoice',
										},
										error: 'Mutliple choice questions should have options',
									},
									{
										validate: 'regex_matches',
										regex: '[a-zA-Z0-9_ :]+(,[a-zA-Z0-9_ :]+)*$',
										error: 'Values should be comma separated. Ex: 1:low,2:high',
									},
								],
							},
						],
						i18nOverrides: {
							en: {
								otherLocales: ['en-US', 'en-CA', 'en-GB'],
								overrides: {
									header: `Replace {{thing}}`,
									header2: `Replace {{thing}}`,
									readySubmit:
										'Are you sure you to submit this task list? Please note this will clear all the existing tasks!',
									success: 'New task list updated successfully!',
								},
							},
						},
					}}
					// @ts-ignore
					source={[]}
					licenseKey={'9cfd8b67-b664-4b19-84cf-b5fb00d8fb5c'}
					customer={{
						companyId: nullSafeGet('facility.buyerCompanyId', currentUser),
						companyName: nullSafeGet('facility.name', currentUser),
						userId: currentUser.email,
					}}
					onData={async (results) => {
						const newTasks = results.validData.map((task) => ({
							...task,
							multipleChoicesWithLabel: task.multipleChoicesWithLabel
								? task.multipleChoicesWithLabel.split(',').map((choice) => {
										const parts = choice.split(':');
										return {
											value: parts[0],
											...(parts.length > 1 && { description: parts[1] }),
										};
								  })
								: undefined,
						}));
						onSaveTaskList(newTasks);
						onCancel();
					}}
					onCancel={onCancel}
					render={(importer, launch) => {
						return (
							<Button style={{ marginTop: '12px', width: '100%' }} onClick={launch}>
								<PlusCircleFilled translate="" />
								Proceed to Replace
							</Button>
						);
					}}
				/>
			</Row>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => ({
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(UploadTaskListPopup)
);

import { JOURNAL_HISTORIES_CRUD_ACTION_CREATORS } from '../actions/journal_histories_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { getBackendUri } from '../utils/EnvConfigUtils';
import FetchUtils, { parseJSON } from './fetch_utils';
import { logoutSuccess } from '../actions/session_actions';

const fetchUtils = new FetchUtils();
const BASE_URL = getBackendUri();

export const journalHistoriesRestCrudThunksForBuyer = new RestCrudThunks(
	'depreciation_journal_histories',
	'journal_history',
	'journalHistory',
	'journalHistories',
	JOURNAL_HISTORIES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'asset/'
);
export const journalHistoriesRestCrudThunksForSupplier = new RestCrudThunks(
	'depreciation_journal_histories',
	'journal_history',
	'journalHistory',
	'journalHistories',
	JOURNAL_HISTORIES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'asset/'
);

const rollbackJournalHistory =
	(userType) =>
	(entity: any = {}) => {
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(JOURNAL_HISTORIES_CRUD_ACTION_CREATORS.updateStart(entity));

				fetchUtils
					.post(
						BASE_URL +
							`/api/v1/${userType}/asset/depreciation_journal_histories/rollback/${entity.id}`,
						{
							headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
							data: entity,
						}
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(JOURNAL_HISTORIES_CRUD_ACTION_CREATORS.updateError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(JOURNAL_HISTORIES_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(JOURNAL_HISTORIES_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};

export const rollbackJournalHistoryForSupplier = rollbackJournalHistory(ROLE_TYPES.SUPPLIER);
export const rollbackJournalHistoryForBuyer = rollbackJournalHistory(ROLE_TYPES.BUYER);

const rerunJournalHistory =
	(userType) =>
	(entity: any = {}) => {
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(JOURNAL_HISTORIES_CRUD_ACTION_CREATORS.updateStart(entity));

				fetchUtils
					.post(
						BASE_URL +
							`/api/v1/${userType}/asset/depreciation_journal_histories/rerun/${entity.id}`,
						{
							headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
							data: entity,
						}
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(JOURNAL_HISTORIES_CRUD_ACTION_CREATORS.updateError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(JOURNAL_HISTORIES_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(JOURNAL_HISTORIES_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};

export const rerunJournalHistoryForSupplier = rerunJournalHistory(ROLE_TYPES.SUPPLIER);
export const rerunJournalHistoryForBuyer = rerunJournalHistory(ROLE_TYPES.BUYER);

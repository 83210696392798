import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { REGIONS_CRUD_ACTION_CREATORS } from '../actions/regions_actions';
import { logoutSuccess } from '../actions/session_actions';
import { default as FetchUtils, parseJSON } from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';

const fetchUtils = new FetchUtils();

export const regionsRestCrudThunksForBuyer = new RestCrudThunks(
	'regions',
	'region',
	'region',
	'regions',
	REGIONS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'location/'
);
export const regionsRestCrudThunksForSupplier = new RestCrudThunks(
	'regions',
	'region',
	'region',
	'regions',
	REGIONS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'location/'
);

const fetchRegionsWithChildren =
	(roleType) =>
	(
		params,
		targetCollectionName = null,
		pagination = null,
		sorting = null,
		filters = null,
		addToTargetCollection = false
	) => {
		const apiUrl = `/api/v1/${roleType}/location/regions/with_children`;
		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(REGIONS_CRUD_ACTION_CREATORS.fetchStart(targetCollectionName));

				fetchUtils
					.get(
						getBackendUri() + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(REGIONS_CRUD_ACTION_CREATORS.fetchError(d.message, targetCollectionName));
							reject(d.message);
						} else {
							dispatch(
								REGIONS_CRUD_ACTION_CREATORS.fetchSuccess(
									d.data,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters,
									addToTargetCollection
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(REGIONS_CRUD_ACTION_CREATORS.fetchError(d.message, targetCollectionName));
						reject(d.message);
					});
			});
		};
	};

export const fetchRegionsWithChildrenForBuyer = fetchRegionsWithChildren(ROLE_TYPES.BUYER);
export const fetchRegionsWithChildrenForSupplier = fetchRegionsWithChildren(ROLE_TYPES.SUPPLIER);

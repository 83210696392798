import React, { FC, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EmptyState } from '../empty_state/EmptyState';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { REQUESTED_PARTS_CRUD_ACTION_CREATORS } from '../../actions/requested_parts_actions';
import {
	requestedPartsRestCrudThunksForSupplier,
	revertShipmentForSupplier,
	shipmentsCsvReportGeneratorForSupplier,
	updateTrackingNumberForSupplier,
} from '../../thunks/requested_parts_thunks';
import HyperLink, { ENTITY_TYPE } from '../common/HyperLink';
import { Button, Popconfirm, Popover, message } from 'antd';
import ReadyToShipModal from './ReadyToShipModal';
import { DATE_FORMAT, STOPLIGHT_COLORS, dateFormatter } from '../../utils/DataFormatterUtils';
import { FILTER_CONFIG_NAMES, FILTER_FIELD_TYPE } from '../../utils/DataConstants';
import { partsRestCrudThunksForSupplier } from '../../thunks/parts_thunks';
import PartLocationDisplay from './PartLocationDisplay';
import { DownloadOutlined, EditOutlined } from '@ant-design/icons';
import FeildTechSLManagePermissionChecker from '../common/FeildTechSLManagePermissionChecker';
import { locationsRestCrudThunksForSupplier } from '../../thunks/locations_thunks';
import DateTimeHover from '../date_time_component/DateTime';

const SHIPMENTS_TC = 'stockLocationShipmentsIndex';
const AF_TC = 'requestedPartsAdvancedFilters';

const StockLocationShippedPage: FC<any> = ({
	match,
	updateFilters,
	clearAndUpdateFilters,
	updateSorters,
	requestedParts,
	fetchRequestedParts,
	parts,
	fetchParts,
	fetchMultipleParts,
	exportCsv,
	updateTrackingNumber,
	locations,
	fetchLocations,
	fetchMultipleLocations,
	revertShipment,
	companyConfig,
}): React.ReactElement => {
	const stockLocationId = useMemo(() => match.params.id, [match.params.id]);

	const [downloading, setDownloading] = useState(false);
	const [shipmentModalVisible, setShipmentModalVisible] = useState(false);
	const [revertingShipmentId, setRevertingShipmentId] = useState(null);

	const [updatingRecord, setUpdatingRecord] = useState(null);

	const getStatSliceProp = useCallback(
		(propName) => nullSafeGetOrElse(`${SHIPMENTS_TC}.${propName}`, requestedParts, {}),
		[requestedParts]
	);

	const hideShipmentModal = useCallback(() => {
		setShipmentModalVisible(false);
		setUpdatingRecord(null);
	}, []);

	const refreshRequestedParts = useCallback(() => {
		fetchRequestedParts(
			{},
			SHIPMENTS_TC,
			getStatSliceProp('pagination'),
			getStatSliceProp('sorting'),
			getStatSliceProp('filters')
		);
	}, [fetchRequestedParts, getStatSliceProp]);

	const onShipmentSuccess = useCallback(() => {
		refreshRequestedParts();
		hideShipmentModal();
	}, [hideShipmentModal, refreshRequestedParts]);

	const onUpdateShipment = useCallback(
		(record) => () => {
			setUpdatingRecord(record);
			setShipmentModalVisible(true);
		},
		[]
	);

	const onRevertShimpent = useCallback(
		(record) => () => {
			setRevertingShipmentId(nullSafeGet('id', record));
			revertShipment(record)
				.then(() => {
					message.success('Shipment reverted successfully!');
					refreshRequestedParts();
				})
				.finally(() => setRevertingShipmentId(null));
		},
		[refreshRequestedParts, revertShipment]
	);

	const autoConsumeEnabled = useMemo(
		() => nullSafeGetOrElse('inventoryConfig.autoConsumeShippedItems', companyConfig, false),
		[companyConfig]
	);

	const shipmentsColumns = useMemo(
		() => [
			{
				title: 'Work Order',
				dataIndex: 'workOrder',
				render: (_) => (
					<div className="flex flex-col">
						<HyperLink
							text={`#${_.id}`}
							entityId={_.id}
							entityType={ENTITY_TYPE.WORK_ORDER_PARTS_AND_EQUPIPMENTS}
							backLinkText="Back to Shipments"
						/>
						<div className="mt-2">
							{_.createdAt ? dateFormatter(_.createdAt, DATE_FORMAT) : '--'}
						</div>
					</div>
				),
				key: 'workOrderCreatedAt',
				sorter: true,
			},
			{
				title: 'Ship To',
				dataIndex: ['workOrder', 'location', 'name'],
			},
			{
				title: 'Part Number',
				dataIndex: ['partsPerStockLocation', 'part', 'partNumber'],
				render: (_) => _ || '--',
			},
			{
				title: 'Part Name',
				dataIndex: ['partsPerStockLocation', 'part', 'name'],
			},
			{
				title: 'Located at',
				dataIndex: 'partsPerStockLocation',
				render: (ppsl) => <PartLocationDisplay ppsl={ppsl} />,
			},
			{
				title: 'Requested Qty',
				dataIndex: ['actualRequestedQuantity'],
				render: (qty, record) => (
					<div className="flex flex-col">
						<div>
							{record.returnedQuantity ? (
								<div className="flex flex-row items-center">
									<div className="text-gray-400 line-through">{qty}</div>
									<div className="ml-2">{qty - record.returnedQuantity}</div>
								</div>
							) : (
								qty
							)}
						</div>
						{!autoConsumeEnabled ? (
							<>
								{record.consumedQuantity ? (
									<div
										style={{ color: STOPLIGHT_COLORS.pureGreen }}
									>{`Consumed : ${record.consumedQuantity}`}</div>
								) : null}
								{record.returnedQuantity ? (
									<div
										style={{ color: STOPLIGHT_COLORS.pureRed }}
									>{`Returned : ${record.returnedQuantity}`}</div>
								) : null}
							</>
						) : null}
					</div>
				),
			},
			{
				title: 'Requested At',
				dataIndex: 'createdAt',
				render: (_) =>
					_ ? <DateTimeHover timestamp={_} showDate={true} showTime={false} /> : '--',
				key: 'createdAt',
				sorter: true,
			},
			{
				title: 'Shipped At',
				dataIndex: 'markShippedAt',
				render: (_) =>
					_ ? <DateTimeHover timestamp={_} showDate={true} showTime={false} /> : '--',
				key: 'markShippedAt',
				sorter: true,
			},
			{
				dataIndex: 'shipped',
				title: 'Tracking No.',
				render: (_, record) => (
					<div className="flex flex-row items-center">
						<div>{`${nullSafeGetOrElse('trackingNumber', record, 'NA')}`}</div>
						<FeildTechSLManagePermissionChecker>
							<Button
								type="link"
								icon={<EditOutlined translate="" onClick={onUpdateShipment(record)} />}
							/>
						</FeildTechSLManagePermissionChecker>
					</div>
				),
			},
			{
				render: (_, record) => (
					<FeildTechSLManagePermissionChecker>
						<Popconfirm
							onConfirm={onRevertShimpent(record)}
							placement="bottomLeft"
							title="Are you sure you want to revert shipment?"
							okText="Yes"
							cancelText="No"
						>
							<Button loading={revertingShipmentId === nullSafeGet('id', record)} type="ghost">
								Revert
							</Button>
						</Popconfirm>
					</FeildTechSLManagePermissionChecker>
				),
			},
		],
		[onRevertShimpent, onUpdateShipment, revertingShipmentId]
	);

	const filterConfig = useMemo(
		() => [
			{
				label: 'Ship To',
				fieldName: 'shipToLocationIds',
				mode: 'multiple',
				stateSlice: locations,
				targetCollectionName: AF_TC,
				fetch: fetchLocations,
				fetchMultiple: fetchMultipleLocations,
			},
			{
				label: 'Part',
				fieldName: 'partIds',
				mode: 'multiple',
				stateSlice: parts,
				targetCollectionName: AF_TC,
				fetch: fetchParts,
				fetchMultiple: fetchMultipleParts,
				renderItem: (part) => (
					<div className="flex flex-row items-center">
						<div>{part.name}</div>
						<div>
							{part.partNumber && (
								<span className="text-gray-400">&nbsp;{`- #${part.partNumber}`}</span>
							)}
						</div>
					</div>
				),
			},
			{
				label: 'Shipped Between',
				labelPrefix: 'Shipped',
				fieldName: FILTER_CONFIG_NAMES.SHIPPED_AT,
				type: FILTER_FIELD_TYPE.DATE_RANGE,
			},
			{
				fieldName: 'isNotConsumed',
				type: FILTER_FIELD_TYPE.RADIO_GROUP,
				items: [
					{
						label: 'All',
						value: 'undefined',
					},
					{
						label: 'Not Consumed',
						value: 'true',
					},
				],
			},
		],
		[fetchLocations, fetchMultipleLocations, fetchMultipleParts, fetchParts, locations, parts]
	);

	const onUpdateShipmentSubmit = useCallback(
		(values) => updateTrackingNumber({ ...updatingRecord }, values),
		[updateTrackingNumber, updatingRecord]
	);

	const downloadCSV = useCallback(() => {
		setDownloading(true);
		exportCsv({
			...getStatSliceProp('sorting'),
			...getStatSliceProp('filters'),
		}).finally(() => setDownloading(false));
	}, [exportCsv, getStatSliceProp]);

	return (
		<>
			<PaginatedReduxTableWithHeader
				targetCollectionName={SHIPMENTS_TC}
				updateFilters={updateFilters}
				stateSlice={requestedParts}
				clearAndUpdateFilters={clearAndUpdateFilters}
				updateSorters={updateSorters}
				filterConfig={filterConfig}
				entityCollectionName="requested_parts"
				tableColumns={shipmentsColumns}
				showHeader
				preAppliedFilters={{ stockLocationId, shipped: true }}
				fetchData={requestedPartsRestCrudThunksForSupplier.read}
				hasDefaultHeaderPage
				rightActions={
					<Popover content="Export CSV" trigger="hover">
						<Button
							loading={downloading}
							size="large"
							icon={<DownloadOutlined translate="" />}
							className="inline-block-visible-md"
							onClick={downloadCSV}
						>
							<span className="inline-block-visible-xxl">Export CSV</span>
						</Button>
					</Popover>
				}
				emptyState={
					<EmptyState
						graphic={
							<img
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
								alt="No shipments found!"
							/>
						}
						headline={'No shipments found!'}
						body={
							<div style={{ textAlign: 'center' }}>
								<div style={{ maxWidth: 440, marginBottom: 16 }}>
									No shipments found for this stock location
								</div>
							</div>
						}
					/>
				}
			/>
			{shipmentModalVisible && (
				<ReadyToShipModal
					onCancel={hideShipmentModal}
					successCallback={onShipmentSuccess}
					onSubmitShipment={onUpdateShipmentSubmit}
					requestedPart={updatingRecord}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	requestedParts: state.requested_parts,
	parts: state.parts,
	stockLocation: state.stock_locations.detail,
	currentUser: state.session.currentUser,
	locations: state.locations,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	updateFilters: (filters, targetCollection) =>
		dispatch(REQUESTED_PARTS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			REQUESTED_PARTS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)
		),
	updateSorters: (sortBy, order, targetCollection) =>
		dispatch(REQUESTED_PARTS_CRUD_ACTION_CREATORS.updateSorting(sortBy, order, targetCollection)),
	fetchRequestedParts: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			requestedPartsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleParts: (ids, targetCollectionName) =>
		dispatch(partsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchParts: (params, targetCollectionName, pagination, sorting, filters, addToTargetCollection) =>
		dispatch(
			partsRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	exportCsv: (params) => dispatch(shipmentsCsvReportGeneratorForSupplier(params)),
	updateTrackingNumber: (entity, data) => dispatch(updateTrackingNumberForSupplier(entity, data)),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			locationsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	revertShipment: (entity) => dispatch(revertShipmentForSupplier(entity)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StockLocationShippedPage));

import React, { FC, useCallback, useMemo, useState } from 'react';
import { Steps, Button, Row, Radio, Popover, message, Col, Card, Checkbox } from 'antd';
import {
	BUYER_ADMIN,
	BUYER_ONLY_NO_STORE_ASSOCIATE,
	SUPER_ADMIN,
	SUPPLIER_ADMIN,
} from '../roles/roles';
import { EditOutlined, InfoCircleOutlined, UndoOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
	appendWorkOrderNotesForBuyer,
	appendWorkOrderNotesForSupplier,
	workOrdersRestCrudThunksForBuyer,
	workOrdersRestCrudThunksForSupplier,
} from '../../thunks/work_orders_thunks';
import {
	deleteCapexCategoryAssociationForBuyer,
	deleteCapexCategoryAssociationForSupplier,
} from '../../thunks/capex_association_thunks';
import {
	getCurrency,
	isInvoiceTotalGreaterThanNTE,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import CapExAdditionalInfoPopup from '../invoices_detail_details_page/CapExAdditionalInfoPopup';
import {
	approveInvoiceForBuyer,
	approveInvoiceForSupplier,
	disputeInvoiceForBuyer,
	disputeInvoiceForSupplier,
	markInvoiceDraftForSupplier,
	markInvoicePaidForBuyer,
	markInvoicePaidForSupplier,
	markInvoicePendingForBuyer,
	markInvoicePendingForSupplier,
	markInvoiceProcessingForBuyer,
	markInvoiceProcessingForSupplier,
	publishInvoiceForSupplier,
	syncInvoiceApprovalHierarchy,
	syncInvoiceApprovalHierarchyForBuyer,
	syncInvoiceApprovalHierarchyForSupplier,
} from '../../thunks/invoices_thunks';
import CapexSwitch from '../capex_switch/CapexSwitch';
import ApprovalHierarchyDisplayInvoice from '../approval_hierarchy_display_invoice/ApprovalHierarchyDisplayInvoice';
import DeclineInvoiceForm from '../decline_invoice_form/DeclineInvoiceForm';
import moment from 'moment';
import { UserName } from '../name_component/user/UserNameComponent';
import { Form } from '@ant-design/compatible';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { syncProposalApprovalHierarchy } from '../../thunks/proposals_thunks';
const INVOICE_ACTIONS = {
	APPROVE: 'approve',
	PAID: 'paid',
	PROCESSING: 'processing',
	PENDING: 'pending',
	DISPUTE: 'dispute',
	DRAFT: 'draft',
};
const InvoiceActions: FC<any> = ({
	invoice,
	currentUser,
	approveInvoice,
	disputeInvoice,
	markInvoicePaid,
	markInvoiceProcessing,
	markInvoicePending,
	markInvoiceDraft,
	publishInvoice,
	appendWorkOrderNotes,
	companyConfig,
	updateWorkOrderForm,
	deleteCapExAssociation,
	refreshInvoice,
	style,
	userType,
	history,
	invoicesCreating,
	updateInvoice,
	syncInvoice,
}): React.ReactElement => {
	const isInternalTech = nullSafeGetOrElse('facility.status', currentUser, '') === 'private';
	const isThirdParty = userType === 'supplier' && !isInternalTech;
	const statuses = [
		...(isThirdParty ? ['draft'] : []),
		'pending',
		'approved',
		'processing',
		'paid',
	];
	const getInvoiceIndex = useCallback((status) => statuses.indexOf(status), []);
	const [expenseTypeLoading, setExpenseTypeLoading] = useState(false);
	const [capexCategoryAdditionalInfoVisible, setCapexCategoryAdditionalInfoVisible] =
		useState(false);
	const [currentInvoiceAction, setCurrentInvoiceAction] = useState(null);
	const [showDeclineModal, setShowDeclineModal] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const isCapex = useMemo(() => nullSafeGetOrElse('workOrder.isCapex', invoice, false), [invoice]);
	const showCapExCategory = useMemo(
		() => nullSafeGetOrElse('config.capExConfig.acceptCapExCategory', companyConfig, false),
		[companyConfig]
	);
	const capexEditable = useMemo(
		() => invoice.status !== 'processing' && invoice.status !== 'paid',
		[invoice]
	);
	const isLoading = useCallback(
		(action) => currentInvoiceAction === action,
		[currentInvoiceAction]
	);
	const updateIsCapexInWorkOrder = useCallback(
		(isCapex) => {
			const needUpdate =
				(invoice.workOrder.isCapex && !isCapex) || (!invoice.workOrder.isCapex && isCapex);
			if (needUpdate) {
				setExpenseTypeLoading(true);
				updateWorkOrderForm({ ...invoice.workOrder, isCapex })
					.then(() => refreshInvoice())
					.finally(() => setExpenseTypeLoading(false));
			}
		},
		[invoice, updateWorkOrderForm, refreshInvoice]
	);
	const deleteCapexCategoryAssociation = useCallback(() => {
		if (invoice.capExCategoryAssociationId) {
			Promise.all([
				deleteCapExAssociation(invoice.capExCategoryAssociationId),
				updateIsCapexInWorkOrder(false),
			]);
		}
	}, [invoice, deleteCapExAssociation, updateIsCapexInWorkOrder]);
	const showCapExAdditionalInfo = useCallback(
		() => setCapexCategoryAdditionalInfoVisible(true),
		[]
	);
	const capexCategoryEnabled = useMemo(
		() => nullSafeGetOrElse('config.capExConfig.acceptCapExCategory', companyConfig, false),
		[companyConfig]
	);
	const onChangeExpenseType = useCallback(
		(e) => {
			if (!capexEditable) return;
			const isCapExNewValue = e.target.value;
			if (capexCategoryEnabled) {
				if (isCapExNewValue) {
					showCapExAdditionalInfo();
				} else {
					deleteCapexCategoryAssociation();
				}
			} else {
				updateIsCapexInWorkOrder(isCapExNewValue);
			}
		},
		[
			capexCategoryEnabled,
			capexEditable,
			showCapExAdditionalInfo,
			deleteCapexCategoryAssociation,
			updateIsCapexInWorkOrder,
		]
	);
	const hideCapExAdditionalInfo = useCallback(() => {
		setCapexCategoryAdditionalInfoVisible(false);
		updateIsCapexInWorkOrder(true);
	}, [updateIsCapexInWorkOrder]);
	const onCapExCategoryAssociationSuccess = useCallback(() => {
		hideCapExAdditionalInfo();
		invoice.workOrder.isCapex && refreshInvoice();
	}, [hideCapExAdditionalInfo, invoice, refreshInvoice]);
	const SpendCategoryDetails = useMemo(() => {
		return <CapexSwitch companyConfig={companyConfig} refresh={refreshInvoice} quote={invoice} />;
	}, [
		invoice,
		capexEditable,
		expenseTypeLoading,
		isCapex,
		showCapExCategory,
		onChangeExpenseType,
		showCapExAdditionalInfo,
	]);
	const handleApproveInvoice = useCallback(() => {
		setCurrentInvoiceAction(INVOICE_ACTIONS.APPROVE);
		approveInvoice(invoice.id)
			.then(() => {
				message.success(`Invoice approved.`);
			})
			.finally(() => setCurrentInvoiceAction(null));
	}, [invoice, approveInvoice]);
	const handlePaidInvoice = useCallback(() => {
		setCurrentInvoiceAction(INVOICE_ACTIONS.PAID);
		markInvoicePaid(invoice.id)
			.then(() => {
				message.success(`Invoice paid.`);
			})
			.finally(() => setCurrentInvoiceAction(null));
	}, [invoice, markInvoicePaid]);
	const handleProcessingInvoice = useCallback(() => {
		setCurrentInvoiceAction(INVOICE_ACTIONS.PROCESSING);
		markInvoiceProcessing(invoice.id)
			.then(() => {
				message.success(`Invoice is being processed.`);
			})
			.finally(() => setCurrentInvoiceAction(null));
	}, [invoice, markInvoiceProcessing]);
	const handlePendingInvoice = useCallback(() => {
		setCurrentInvoiceAction(INVOICE_ACTIONS.PENDING);
		markInvoicePending(invoice.id)
			.then(() => {
				message.success(`Invoice made pending.`);
			})
			.finally(() => setCurrentInvoiceAction(null));
	}, [invoice, markInvoicePending]);
	const handleDraftInvoice = useCallback(() => {
		setCurrentInvoiceAction(INVOICE_ACTIONS.DRAFT);
		markInvoiceDraft(invoice.id)
			.then(() => {
				message.success(`Invoice moved back to draft.`);
			})
			.finally(() => setCurrentInvoiceAction(null));
	}, [invoice, markInvoiceDraft]);
	const handlePublishInvoice = () => {
		publishInvoice(invoice.id)
			.then(() => {
				message.success(`Invoice published.`);
			})
			.finally(() => setCurrentInvoiceAction(null));
	};
	const handleDisputeInvoice = useCallback(
		(values) => {
			setCurrentInvoiceAction(INVOICE_ACTIONS.DISPUTE);
			const note = {
				text: 'Invoice Disputed: '.concat(values.note),
				noteAddedBy: nullSafeGet('email', currentUser),
				noteAddedAt: moment.utc(),
			};
			const disputeNote = {
				text: values.note,
				noteAddedBy: nullSafeGet('email', currentUser),
				noteAddedAt: moment.utc(),
			};
			const workOrderNoteDetails = {
				id: invoice.workOrder.id,
				locationId: invoice.workOrder.locationId,
				note,
			};
			setButtonLoading(true);
			disputeInvoice(invoice.id, disputeNote)
				.then(() => {
					appendWorkOrderNotes(workOrderNoteDetails).then(() => {
						message.success(`Invoice disputed.`);
						setShowDeclineModal(false);
						setButtonLoading(false);
						refreshInvoice();
					});
				})
				.finally(() => setCurrentInvoiceAction(null));
		},
		[invoice, disputeInvoice]
	);
	const lastDisputeNote = invoice.disputeNotes && invoice.disputeNotes.slice(-1)[0];
	const disputeNote = lastDisputeNote ? (
		<Col className={'py-4'}>
			<div>
				<span style={{ fontSize: 16 }}>
					Disputed by{' '}
					<UserName mode={'inline'} contact={nullSafeGet(`noteAddedByContact`, lastDisputeNote)} />{' '}
					{moment(nullSafeGet(`noteAddedAt`, lastDisputeNote)).fromNow()}
				</span>
			</div>
			<div
				style={{
					display: 'inline-flex',
					background: '#fafafa',
					color: 'rgba(0, 0, 0, 0.65)',
					borderRadius: '30px',
					padding: '8px 16px',
				}}
			>
				<span
					style={{
						fontSize: 16,
					}}
				>
					{nullSafeGet(`text`, lastDisputeNote)}
				</span>
			</div>
		</Col>
	) : null;

	const currency = getCurrency({ supplier: nullSafeGet('workOrder.supplierFacility', invoice) });
	const nte = nullSafeGet('workOrder.nte', invoice);
	const invoiceId = nullSafeGet('id', invoice);
	const totalIsGreaterThanNTE = isInvoiceTotalGreaterThanNTE(
		invoice,
		nullSafeGet('workOrder', invoice)
	);

	const [autoPublishOnApproval, setAutoPublishOnApproval] = useState(invoice.autoPublishOnApproval);
	const togglePublishOnApproval = (e) => {
		setAutoPublishOnApproval(!autoPublishOnApproval);
		setButtonLoading(true);
		updateInvoice({ ...invoice, autoPublishOnApproval: !invoice.autoPublishOnApproval }).then(
			() => {
				setButtonLoading(false);
			}
		);
	};

	//Third Party Supplier Info
	const nteIssueAlert =
		totalIsGreaterThanNTE && (invoice.status === 'draft' || invoice.status === 'pending') ? (
			<div className="rowSpacing">
				<Card
					style={{ border: '1px solid #8ed7eb' }}
					bodyStyle={{
						padding: '16px 24px',
						background: '#ebfbff',
					}}
				>
					<div>
						<h5 style={{ marginBottom: '4px' }}>Total is greater than NTE Amount</h5>
						<div>
							<p
								style={{
									fontSize: 16,
									marginBottom: '0.5em',
								}}
							>
								{invoice.status === 'draft'
									? `You can't submit an Invoice for more than the not-to-exceed amount ${currency.format(
											nte
									  )} for this work order. Please submit a Quote to increase not-to-exceed amount or edit this invoice.`
									: `${nullSafeGet(
											'workOrder.buyerCompany.displayName',
											invoice
									  )} can't approve this invoice because it exceeds the not-to-exceed amount of ${currency.format(
											nte
									  )} for this work order. Please submit a Quote to increase not-to-exceed amount or edit this invoice.`}
							</p>
						</div>
						<Button
							type="primary"
							key="approve"
							onClick={() => history.push(`/supplier/invoices/edit/${invoiceId}`)}
						>
							Edit Invoice
						</Button>
					</div>
				</Card>
			</div>
		) : null;
	const publishInvoiceAlert =
		!totalIsGreaterThanNTE &&
		invoice.status === 'draft' &&
		invoice.invoicePDFLink &&
		invoice.invoicePDFLink.length > 0 ? (
			invoice.workOrder.displayStatus === 'Completed' ? (
				<div className="rowSpacing">
					<Card
						style={{ border: '1px solid #8ed7eb' }}
						bodyStyle={{
							padding: '16px 24px',
							background: '#ebfbff',
						}}
					>
						<div>
							<h5 style={{ marginBottom: '4px' }}>Publish invoice?</h5>
							<div>
								<p
									style={{
										fontSize: 16,
										marginBottom: '0.5em',
									}}
								>
									This invoice is still a draft and isn't visible to the customer yet.
								</p>
								<Button
									type="primary"
									key="approve"
									loading={invoicesCreating}
									onClick={handlePublishInvoice}
								>
									Publish invoice
								</Button>
							</div>
						</div>
					</Card>
				</div>
			) : (
				<div className="rowSpacing">
					<Card
						style={{ border: '1px solid #8ed7eb' }}
						bodyStyle={{
							padding: '16px 24px',
							background: '#ebfbff',
						}}
					>
						<div>
							<h5 style={{ marginBottom: '4px' }}>
								Can't publish invoice yet. Waiting on work order completion.
							</h5>
							<div>
								<p
									style={{
										fontSize: 16,
										marginBottom: '0.5em',
									}}
								>
									This invoice is still a draft and isn't visible to the customer yet.
								</p>
								<div className="flex flex-row justify-between">
									<div className="flex flex-grow flex-col flex-nowrap">
										<div className="flex flex-row" style={{ gap: 8, alignItems: 'center' }}>
											<Button
												type="primary"
												key="approve"
												disabled={true}
												loading={invoicesCreating}
												onClick={() => handlePublishInvoice}
											>
												Publish invoice
											</Button>
											<Checkbox
												style={{ marginLeft: 12 }}
												checked={autoPublishOnApproval}
												onClick={togglePublishOnApproval}
												disabled={buttonLoading}
											/>
											<label>Auto publish invoice once work is approved</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Card>
				</div>
			)
		) : null;
	const thirdPartySupplierButtons = isThirdParty ? (
		<div>
			{invoice.status === 'pending' ? (
				<div className="flex flex-row justify-between">
					<div className="flex flex-grow flex-col flex-nowrap">
						<h5>This invoice is pending approval.</h5>
						<div className="flex flex-row" style={{ gap: 20, marginBottom: 20 }}>
							<Button
								key="draft"
								type="default"
								icon={<UndoOutlined translate="" />}
								loading={buttonLoading}
								onClick={handleDraftInvoice}
							>
								Move Back To Draft
							</Button>
						</div>
					</div>
				</div>
			) : null}
			{invoice.status === 'approved' || invoice.status === 'processing' ? (
				<div>
					<h5>Has this invoice been paid?</h5>
					<div style={{ marginTop: 16, marginBottom: 40 }}>
						<Button
							type="primary"
							key="approve"
							onClick={handlePaidInvoice}
							loading={buttonLoading}
						>
							Yes, it was paid
						</Button>
					</div>
				</div>
			) : null}
		</div>
	) : null;

	return (
		<div>
			<div style={{ marginTop: 24, marginBottom: 12 }}>
				<Steps current={getInvoiceIndex(invoice.status)}>
					{isThirdParty ? <Steps.Step title="Draft" /> : null}
					<Steps.Step title="Pending" />
					<Steps.Step title="Approved" />
					<Steps.Step title="Processing" />
					<Steps.Step title="Paid" />
				</Steps>
				<div style={{ marginTop: 12 }}>
					<h5 style={{ marginBottom: 0 }}>Invoice {invoice.id}</h5>
					{invoice.status === 'disputed' ? disputeNote : null}
				</div>
			</div>
			{isThirdParty ? (
				<div>
					{nteIssueAlert}
					{publishInvoiceAlert}
					{thirdPartySupplierButtons}
				</div>
			) : (
				<div>
					<DeclineInvoiceForm
						visible={showDeclineModal}
						onCancel={() => setShowDeclineModal(false)}
						onSubmit={handleDisputeInvoice}
					/>
					<div
						style={{
							display: 'flex',
							position: 'relative',
							height: 'fit-content',
							flexDirection: 'row',
						}}
					>
						<div
							style={{
								position: 'relative',
								flexDirection: 'column',
								width: '100%',
							}}
						>
							<Row className="w-full justify-between">
								<ApprovalHierarchyDisplayInvoice
									workOrder={invoice.workOrder}
									invoice={invoice}
									refresh={refreshInvoice}
									currentUser={currentUser}
									approveInvoice={approveInvoice}
									declineInvoice={disputeInvoice}
									markInvoicePending={markInvoicePending}
									markInvoiceProcessing={markInvoiceProcessing}
									markInvoicePaid={markInvoicePaid}
									appendWorkOrderNotes={appendWorkOrderNotes}
									style={style}
									companyConfig={companyConfig}
									userType={userType}
									syncInvoice={syncInvoice}
								/>
								<div>{SpendCategoryDetails}</div>
							</Row>
						</div>
					</div>
					{capexCategoryAdditionalInfoVisible && (
						<CapExAdditionalInfoPopup
							workOrder={invoice.workOrder}
							onCancel={hideCapExAdditionalInfo}
							onSuccess={onCapExCategoryAssociationSuccess}
						/>
					)}
				</div>
			)}
		</div>
	);
};
const mapStateToProps = (state, ownProps) => ({
	match: ownProps.match,
	currentUser: state.session.currentUser,
	companyConfig: state.company_config.detail,
	history: ownProps.history,
	invoicesCreating: state.invoices.creating,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	approveInvoice: (invoice) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? approveInvoiceForSupplier(invoice)
				: approveInvoiceForBuyer(invoice)
		),
	markInvoicePaid: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? markInvoicePaidForSupplier({ id })
				: markInvoicePaidForBuyer({ id })
		),
	markInvoiceProcessing: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? markInvoiceProcessingForSupplier({ id })
				: markInvoiceProcessingForBuyer({ id })
		),
	markInvoicePending: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? markInvoicePendingForSupplier({ id })
				: markInvoicePendingForBuyer({ id })
		),
	markInvoiceDraft: (id) => dispatch(markInvoiceDraftForSupplier({ id })),
	disputeInvoice: (id, disputeNote) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? disputeInvoiceForSupplier({ id, disputeNote })
				: disputeInvoiceForBuyer({ id, disputeNote })
		),
	updateWorkOrderForm: (workOrder) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? workOrdersRestCrudThunksForSupplier.update(workOrder)
				: workOrdersRestCrudThunksForBuyer.update(workOrder)
		),
	deleteCapExAssociation: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? deleteCapexCategoryAssociationForSupplier(id)
				: deleteCapexCategoryAssociationForBuyer(id)
		),
	appendWorkOrderNotes: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? appendWorkOrderNotesForSupplier(entity)
				: appendWorkOrderNotesForBuyer(entity)
		),
	publishInvoice: (id) => dispatch(publishInvoiceForSupplier({ id })),
	syncInvoice: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? syncInvoiceApprovalHierarchyForSupplier(entity)
				: syncInvoiceApprovalHierarchyForBuyer(entity)
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Form.create()(InvoiceActions))
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import React, { FC } from 'react';
import { Route, Redirect } from 'react-router-dom';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';

const DepreciationPage: FC<any> = ({ childRoutes }): React.ReactElement => {
	return (
		<AccessPermissionChecker name={PERMISSION_NAMES.ACCESS_DEPRECIATION} isPage>
			<div>
				<Route
					path="/supplier/depreciation"
					exact={true}
					render={(props) => (
						<Redirect
							to={{
								pathname: '/supplier/depreciation/overview',
							}}
						/>
					)}
				/>
				{childRoutes.map((r, i) => (
					<RouteWithSubRoutes key={i} {...r} />
				))}
			</div>
		</AccessPermissionChecker>
	);
};

export default DepreciationPage;

import * as React from 'react';
import { connect } from 'react-redux';

import { Layout, Spin, Row, Col, Card, Table } from 'antd';
import { floatToPercentageString } from '../../utils/DataFormatterUtils';

import {
	getAnalyticsDisplayData,
	nullSafeGet,
	nullSafeGetOrElse,
	sortTreeData,
} from '../../utils/DataAccessUtils';
import { BarChart } from '../charts/BarChart';
import { withRouter } from 'react-router';
import AnalyticsControlBar from '../analytics_control_bar/AnalyticsControlBar';
import { debounce } from '../../utils/PerformanceUtils';
import { EmptyState } from '../empty_state/EmptyState';
import { CSV_EXPORT_DEFAULTS } from '../../utils/DataConstants';
import { ExportToCsv } from 'export-to-csv';

const DataSet = require('@antv/data-set');
const { Content } = Layout;
const isEqual = require('fast-deep-equal');

class WorkAnalyticsCategoryBreakdownPage extends React.Component<any, any> {
	workOrderCountByCategoryChartConfig: any;

	constructor(props) {
		super(props);
		this.workOrderCountByCategoryChartConfig = props.chartConfig
			? props.chartConfig
			: {
					xAxisName: 'fieldName',
					yAxisName: 'subTotal',
					position: 'fieldName*subTotal',
					fillArea: false,
					color: 'fieldName',
					cols: {
						fieldName: { alias: props.fieldDisplayName },
						subTotal: { alias: '# Work Orders' },
					},
					yAxisLabel: {
						textStyle: {
							fontSize: '14',
							fontFamily: 'Roboto',
							fontWeight: 400,
							fill: 'rgba(0,0,0,0.65)',
						},
					},
					xAxisLabel: {
						textStyle: {
							fontSize: '14',
							fontFamily: 'Roboto',
							fontWeight: 400,
							fill: 'rgba(0,0,0,0.65)',
						},
					},
					transformer: (data) => {
						const ds = new DataSet();
						const dv = ds.createView().source(data);
						dv.transform({
							type: 'sort-by',
							fields: ['subTotal'],
							order: 'ASC', // default is ASC,DESC is reversed order.
						});
						return dv;
					},
			  };
		this.state = {
			initialLoading: true,
		};
	}

	debouncedCallback = debounce(
		() => {
			const { fetchWorkOrderCountsByCategory, filters } = this.props;
			const { initialLoading } = this.state;
			fetchWorkOrderCountsByCategory(filters).then(() => this.setState({ initialLoading: false }));
		},
		1000,
		false
	);

	componentDidMount() {
		const { fetchWorkOrderCountsByCategory, filters } = this.props;
		const { initialLoading } = this.state;
		if (filters.startDate && filters.endDate) {
			if (initialLoading) {
				fetchWorkOrderCountsByCategory(filters).then(() =>
					this.setState({ initialLoading: false })
				);
			}
		}
	}

	componentWillReceiveProps(nextProps) {
		const { filters } = this.props;
		if (!isEqual(nextProps.filters, filters)) {
			this.debouncedCallback();
		}
	}

	getDisplayData = () =>
		sortTreeData(
			getAnalyticsDisplayData(nullSafeGetOrElse('props.workOrderCountByCategory.data', this, [])),
			(a, b) =>
				parseFloat(nullSafeGetOrElse('subTotal', b, 0)) -
				parseFloat(nullSafeGetOrElse('subTotal', a, 0))
		);

	onDownloadAnalytics = () => {
		const { fieldDisplayName } = this.props;
		const csvExporter = new ExportToCsv({
			...CSV_EXPORT_DEFAULTS,
			filename: `Work Analytics by ${fieldDisplayName}`,
			title: `Work Analytics by ${fieldDisplayName}`,
			headers: [fieldDisplayName, 'Total Workorders', 'Percentage'],
		});
		csvExporter.generateCsv(
			this.getDisplayData().map((obj) => ({
				name: obj.fieldName,
				total: nullSafeGetOrElse('subTotal', obj, 0),
				percentage: floatToPercentageString(nullSafeGetOrElse('percentageOfTotal', obj, 0) / 100),
			}))
		);
	};

	render() {
		const {
			saveFilters,
			clearAllFilters,
			filters,
			fieldDisplayName,
			workOrderCountByCategory,
			accountActivated,
		} = this.props;
		const { initialLoading } = this.state;

		const workOrderCountByCategoryData = this.getDisplayData();

		const defaultSortOrder: 'ascend' | 'descend' = 'descend';
		const tooltipTemplate = `
                    <li data-index={index}>
                        <!-- The marker for each record -->
                        <span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>
                        {name}: {value} work orders 
                    </li>
        `;

		const workOrderCountByCategoryColumns = [
			{
				title: fieldDisplayName,
				dataIndex: 'fieldName',
			},
			{
				title: '# Work Orders',
				dataIndex: 'subTotal',
				defaultSortOrder: defaultSortOrder,
				sorter: (a, b) => a.subTotal - b.subTotal,
				render: (amount) => amount,
			},
			{
				title: '% of Total',
				dataIndex: 'percentageOfTotal',
				render: (num) => floatToPercentageString(num / 100),
			},
		];
		const chartHeight = Math.max(480, workOrderCountByCategory.data.length * 32);

		return (
			<Content
				className="analyticsOverviewCategoryPage"
				style={{ padding: '0 0.5em', position: 'relative' }}
			>
				{/*<BackTop/>*/}
				{accountActivated ? (
					[
						<Row key={1} style={{ margin: '0.5em -8px' }} gutter={16}>
							<Col span={24}>
								<div
									style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
								>
									<div></div>
									<AnalyticsControlBar
										updateFilters={saveFilters}
										clearFilters={clearAllFilters}
										supplierFacilityIds={filters.supplierFacilityIds}
										locationIds={filters.locationIds}
										startDate={filters.startDate}
										endDate={filters.endDate}
										isPM={filters.isPM}
										workCompletedAtStartDate={filters.workCompletedAtStartDate}
										workCompletedAtEndDate={filters.workCompletedAtEndDate}
										showDownload
										onDownload={this.onDownloadAnalytics}
										filterConfig={{ workCompletedAt: true }}
									/>
								</div>
							</Col>
						</Row>,
						<Row key={2} style={{ margin: '0.5em -8px' }} gutter={16}>
							<Col span={24}>
								<Spin spinning={workOrderCountByCategory.loading || initialLoading}>
									{workOrderCountByCategoryData && workOrderCountByCategoryData.length > 0 ? (
										<>
											<Row style={{ margin: '0.5em -8px' }} gutter={16}>
												<Col span={24}>
													<Card>
														<Table
															style={{ marginTop: 40 }}
															pagination={{ pageSize: 8 }}
															columns={workOrderCountByCategoryColumns}
															rowKey={(el) => el['category']}
															dataSource={workOrderCountByCategoryData}
														/>
													</Card>
												</Col>
											</Row>
											<Row>
												<Col span={24}>
													<Card>
														<BarChart
															height={chartHeight}
															chartPadding={[40, 40, 40, 200]}
															data={this.workOrderCountByCategoryChartConfig.transformer(
																workOrderCountByCategoryData
															)}
															showTooltipTitle={false}
															tooltipItemTemplate={tooltipTemplate}
															xAxisName={nullSafeGet(
																'xAxisName',
																this.workOrderCountByCategoryChartConfig
															)}
															yAxisName={nullSafeGet(
																'yAxisName',
																this.workOrderCountByCategoryChartConfig
															)}
															xAxisLabel={nullSafeGet(
																'xAxisLabel',
																this.workOrderCountByCategoryChartConfig
															)}
															yAxisLabel={nullSafeGet(
																'yAxisLabel',
																this.workOrderCountByCategoryChartConfig
															)}
															fillArea={nullSafeGet(
																'fillArea',
																this.workOrderCountByCategoryChartConfig
															)}
															cols={nullSafeGet('cols', this.workOrderCountByCategoryChartConfig)}
															color={nullSafeGet('color', this.workOrderCountByCategoryChartConfig)}
															position={nullSafeGet(
																'position',
																this.workOrderCountByCategoryChartConfig
															)}
														/>
													</Card>
												</Col>
											</Row>
										</>
									) : (
										<EmptyState
											height={chartHeight}
											headline={'No work orders found'}
											body={
												<div style={{ maxWidth: 488, textAlign: 'center' }}>
													Try changing your filters or expanding the selected time period.
												</div>
											}
										/>
									)}
								</Spin>
							</Col>
						</Row>,
					]
				) : (
					<EmptyState
						graphic={
							<img
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/letter owl.svg"
							/>
						}
						headline={"You haven't activated your account yet."}
						body={
							<div style={{ textAlign: 'center' }}>
								<div style={{ maxWidth: 560, marginBottom: 16 }}>
									Please check your email. We sent you a note with an activation link.
								</div>
							</div>
						}
					/>
				)}
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	fieldDisplayName: ownProps.fieldDisplayName,
	workOrderCountByCategory: ownProps.workOrderCountByCategory,
	chartConfig: ownProps.chartConfig,
	filters: ownProps.filters,
	accountActivated: state.session.accountActivated,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	saveFilters: (filters) => dispatch(ownProps.updateFilters(filters)),
	clearAllFilters: () => dispatch(ownProps.clearFilters()),
	fetchWorkOrderCountsByCategory: (params, filters = null, pagination = null, sorting = null) =>
		dispatch(ownProps.fetchWorkOrderCountsByCategory(params, filters, pagination, sorting)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(WorkAnalyticsCategoryBreakdownPage)
);

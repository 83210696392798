import { BUYER_CONTACTS_CRUD_ACTION_CREATORS } from '../actions/buyer_contacts_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import FetchUtils from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { checkUserTypeToken } from './session_thunks';

export const buyerContactsRestCrudThunksForBuyer = new RestCrudThunks(
	'buyer_contacts',
	'buyer_contact',
	'buyerContact',
	'buyerContacts',
	BUYER_CONTACTS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER
);
export const buyerContactsRestCrudThunksForSupplier = new RestCrudThunks(
	'buyer_contacts',
	'buyer_contact',
	'buyerContact',
	'buyerContacts',
	BUYER_CONTACTS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER
);

const fetchUtils = new FetchUtils();
const baseUrl = getBackendUri();

export function automaticallyAssociateBuyerContactWithFacility(contact: any) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(BUYER_CONTACTS_CRUD_ACTION_CREATORS.updateStart(contact));

			fetchUtils
				.patch(
					baseUrl +
						`/api/v1/buyer/buyer_contacts/${encodeURIComponent(
							contact.email
						)}/update_facility_based_domain`,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					})
				)
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(BUYER_CONTACTS_CRUD_ACTION_CREATORS.updateError(d.message, contact));
						reject(d.message);
					} else {
						dispatch(BUYER_CONTACTS_CRUD_ACTION_CREATORS.updateSuccess(contact));
						dispatch(checkUserTypeToken(ROLE_TYPES.BUYER)());
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(BUYER_CONTACTS_CRUD_ACTION_CREATORS.updateError(d.message, contact));
					reject(d.message);
				});
		});
	};
}

export function manuallyAssociateBuyerContactWithFacility(contact: any, facilityId: number) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(BUYER_CONTACTS_CRUD_ACTION_CREATORS.updateStart(contact));

			fetchUtils
				.patch(
					baseUrl +
						`/api/v1/buyer/buyer_contacts/${encodeURIComponent(
							contact.email
						)}/update_facility_id_assoc_domain/${encodeURIComponent(facilityId.toString())}`,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					})
				)
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(BUYER_CONTACTS_CRUD_ACTION_CREATORS.updateError(d.message, contact));
						reject(d.message);
					} else {
						dispatch(BUYER_CONTACTS_CRUD_ACTION_CREATORS.updateSuccess(contact));
						dispatch(checkUserTypeToken(ROLE_TYPES.BUYER)());
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(BUYER_CONTACTS_CRUD_ACTION_CREATORS.updateError(d.message, contact));
					reject(d.message);
				});
		});
	};
}

export function buyerSignUpInvite(contact: any) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(BUYER_CONTACTS_CRUD_ACTION_CREATORS.createStart(contact));

			fetchUtils
				.post(baseUrl + `/api/v1/buyer/buyer_sign_up_invite/${encodeURIComponent(contact.email)}`, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: contact,
				})
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(BUYER_CONTACTS_CRUD_ACTION_CREATORS.createError(d.message, contact));
						reject(d.message);
					} else {
						dispatch(BUYER_CONTACTS_CRUD_ACTION_CREATORS.createSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(BUYER_CONTACTS_CRUD_ACTION_CREATORS.createError(d.message, contact));
					reject(d.message);
				});
		});
	};
}

export function buyerRoleUpdateUser(contact: any) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(BUYER_CONTACTS_CRUD_ACTION_CREATORS.createStart(contact));

			fetchUtils
				.post(baseUrl + `/api/v1/buyer/assignRolesToContact`, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: contact,
				})
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(BUYER_CONTACTS_CRUD_ACTION_CREATORS.createError(d.message, contact));
						reject(d.message);
					} else {
						dispatch(BUYER_CONTACTS_CRUD_ACTION_CREATORS.createSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(BUYER_CONTACTS_CRUD_ACTION_CREATORS.createError(d.message, contact));
					reject(d.message);
				});
		});
	};
}
export function adminChangePassword(entity) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(BUYER_CONTACTS_CRUD_ACTION_CREATORS.createStart(entity));

			fetchUtils
				.post(baseUrl + `/api/v1/buyer/admin/changeUserPassword`, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: entity,
				})
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(BUYER_CONTACTS_CRUD_ACTION_CREATORS.createError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(BUYER_CONTACTS_CRUD_ACTION_CREATORS.createSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(BUYER_CONTACTS_CRUD_ACTION_CREATORS.createError(d.message, entity));
					reject(d.message);
				});
		});
	};
}

export function buyerUpdateUserContact(contact: any) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(BUYER_CONTACTS_CRUD_ACTION_CREATORS.createStart(contact));

			fetchUtils
				.post(baseUrl + `/api/v1/buyer/updateContact`, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: contact,
				})
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(BUYER_CONTACTS_CRUD_ACTION_CREATORS.createError(d.message, contact));
						reject(d.message);
					} else {
						dispatch(BUYER_CONTACTS_CRUD_ACTION_CREATORS.createSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(BUYER_CONTACTS_CRUD_ACTION_CREATORS.createError(d.message, contact));
					reject(d.message);
				});
		});
	};
}

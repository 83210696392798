export const BUYER_NOTIFICATION_TYPES = {
	WorkOrder: 'Work Order',
	WorkOrderNotes: 'Subscription',
	WorkOrderNotesMentions: 'Mentions',
	PlannedMaintenanceWorkOrder: 'Planned Maintenance',
	SupplierInitiatedWorkOrder: 'Supplier Initiated Work Order',
	RFP: 'Requests For Proposals',
	Proposal: 'Quotes',
	Invoice: 'Invoices',
	Review: 'Review Pending',
	WorkOrderApproval: 'Work Orders',
	InvoiceApproval: 'Invoices',
	ProposalApproval: 'Quotes',
	WorkOrderApprovalReminder: 'Reminders',
	InvoiceApprovalReminder: 'Reminders',
	ProposalApprovalReminder: 'Reminders',
	SupplierInitiatedPendingApproval: 'Supplier Initiated Work Pending Approval',
	PendingApproval: 'Pending Approval',
	Unassigned: 'Unassigned',
	AssignedToInternalTech: 'Assigned to Internal Team',
	InternalTechWorkComplete: 'Task Completed by Internal Tech',
	InternalTechWorkReviewedAndCompleted: "Internal Tech's Work Reviewed and Completed",
	PendingConfirmationByServiceProvider: 'Pending Contractor Confirmation',
	ConfirmedByServiceProvider: 'Confirmed by Service Provider',
	TechAssigned: 'Tech Assigned',
	TechScheduled: 'Tech Scheduled',
	TechRescheduled: 'Tech Rescheduled',
	TechWorkingOnSite: 'Tech Working on Site',
	WorkIncompleteWithReason: 'Work Incomplete with Reason',
	WaitingForQuote: 'Waiting for Quote',
	PartsOnOrder: 'Parts on Order',
	PartsReceived: 'Parts Received',
	PartsRequested: 'Parts Requested',
	DelayedByThirdParty: 'Delayed by Third Party',
	WorkReviewedAndCompleted: 'Work Reviewed and Completed',
	Invoiced: 'Invoiced',
	CancelledWithReason: 'Cancelled With Reason',
	RFP_RequestProposal: 'RFP Created',
	RFP_Participating: 'Participating',
	RFP_NotParticipating: 'Not Participating',
	RFP_Awarded: 'Not Awarded',
	RFP_Declined: 'Awarded',
	RFP_RequestForProposalEnded: 'RFP Closed',
	WorkUnsatisfactory: '  WorkUnsatisfactory,',
	PaymentMade: '  PaymentMade,',
};
export const NOTIFICATION_NOTES_TYPES = [
	{ label: 'Subscription', key: 'WorkOrderNotes' },
	{ label: 'Mentions', key: 'WorkOrderNotesMentions' },
];
export const BUYER_NOTIFICATION_APPROVALS_TYPES_MAP = [
	{
		label: 'Work Orders',
		key: 'WorkOrder',
		children: [
			{ label: 'WorkOrderApproval', key: 'WorkOrderApproval' },
			{ label: 'WorkOrderApprovalReminder', key: 'WorkOrderApprovalReminder' },
			{ label: 'PendingApproval', key: 'PendingApproval' },
			{ label: 'WaitingForReview', key: 'WaitingForReview' },
			{ label: 'SupplierInitiatedPendingApproval', key: 'SupplierInitiatedPendingApproval' },
		],
	},
	{
		label: 'Invoices',
		key: 'Invoice',
		children: [
			{ label: 'InvoiceApproval', key: 'InvoiceApproval' },
			{ label: 'InvoiceApprovalReminder', key: 'InvoiceApprovalReminder' },
		],
	},
	{
		label: 'Quotes',
		key: 'Proposal',
		children: [
			{ label: 'ProposalApproval', key: 'ProposalApproval' },
			{ label: 'ProposalApprovalReminder', key: 'ProposalApprovalReminder' },
		],
	},
];

export const BUYER_FILTER_STATUS_NOTIFICATION_TYPES_MAP = [
	{
		label: 'Work Orders',
		key: 'WorkOrder',
		children: [
			{ label: 'Work Order', key: 'WorkOrder' },
			{ label: 'Unassigned', key: 'Unassigned' },
			{ label: 'AssignedToInternalTech', key: 'AssignedToInternalTech' },
			{ label: 'InternalTechWorkComplete', key: 'InternalTechWorkComplete' },
			{
				label: 'InternalTechWorkReviewedAndCompleted',
				key: 'InternalTechWorkReviewedAndCompleted',
			},
			{
				label: 'PendingConfirmationByServiceProvider',
				key: 'PendingConfirmationByServiceProvider',
			},
			{ label: 'ConfirmedByServiceProvider', key: 'ConfirmedByServiceProvider' },
			{ label: 'TechAssigned', key: 'TechAssigned' },
			{ label: 'TechScheduled', key: 'TechScheduled' },
			{ label: 'TechRescheduled', key: 'TechRescheduled' },
			{ label: 'TechWorkingOnSite', key: 'TechWorkingOnSite' },
			{ label: 'WorkIncompleteWithReason', key: 'WorkIncompleteWithReason' },
			{ label: 'PartsOnOrder', key: 'PartsOnOrder' },
			{ label: 'PartsReceived', key: 'PartsReceived' },
			{ label: 'PartsRequested', key: 'PartsRequested' },
			{ label: 'DelayedByThirdParty', key: 'DelayedByThirdParty' },
			{ label: 'WorkReviewedAndCompleted', key: 'PartsRequested' },
			{ label: 'CancelledWithReason', key: 'CancelledWithReason' },
		],
	},
	{
		label: 'Invoices',
		key: 'Invoiced',
		children: [{ label: 'Invoiced', key: 'Invoiced' }],
	},
	{
		label: 'Quotes',
		key: 'Proposal',
		children: [
			{ label: 'RFP_RequestProposal', key: 'RFP_RequestProposal' },
			{ label: 'RFP_Participating', key: 'RFP_Participating' },
			{ label: 'RFP_NotParticipating', key: 'RFP_NotParticipating' },
			{ label: 'RFP_Awarded', key: 'RFP_Awarded' },
			{ label: 'RFP_Declined', key: 'RFP_Declined' },
			{ label: 'RFP_RequestForProposalEnded', key: 'RFP_RequestForProposalEnded' },
			{ label: 'WaitingForQuote', key: 'WaitingForQuote' },
			{ label: 'Proposal', key: 'Proposal' },
			{ label: 'ProposalAwarded', key: 'ProposalAwarded' },
			{ label: 'ProposalDeclined', key: 'ProposalDeclined' },
		],
	},
];

export const SUPPLIER_FILTER_STATUS_NOTIFICATION_TYPES_MAP = [
	{
		label: 'Work Orders',
		key: 'WorkOrder',
		children: [
			{ label: 'Work Order', key: 'WorkOrder' },
			{ label: 'AssignedToInternalTech', key: 'AssignedToInternalTech' },
			{ label: 'ConfirmedByServiceProvider', key: 'ConfirmedByServiceProvider' },
			{
				label: 'InternalTechWorkReviewedAndCompleted',
				key: 'InternalTechWorkReviewedAndCompleted',
			},
			{ label: 'TechAssigned', key: 'TechAssigned' },
			{ label: 'TechScheduled', key: 'TechScheduled' },
			{
				label: 'PendingConfirmationByServiceProvider',
				key: 'PendingConfirmationByServiceProvider',
			},
			{ label: 'WorkUnsatisfactory', key: 'WorkUnsatisfactory' },
			{ label: 'WorkReviewedAndCompleted', key: 'WorkReviewedAndCompleted' },
			{ label: 'CancelledWithReason', key: 'CancelledWithReason' },
		],
	},
	{
		label: 'Invoices',
		key: 'Invoiced',
		children: [{ label: 'Payment Made', key: 'PaymentMade' }],
	},
	{
		label: 'Quotes',
		key: 'Proposal',
		children: [
			{ label: 'WaitingForQuote', key: 'WaitingForQuote' },
			{ label: 'Proposal', key: 'Proposal' },
			{ label: 'ProposalAwarded', key: 'ProposalAwarded' },
			{ label: 'ProposalDeclined', key: 'ProposalDeclined' },
			{ label: 'RFP_RequestProposal', key: 'RFP_RequestProposal' },
			{ label: 'RFP_Participating', key: 'RFP_Participating' },
			{ label: 'RFP_NotParticipating', key: 'RFP_NotParticipating' },
			{ label: 'RFP_Awarded', key: 'RFP_Awarded' },
			{ label: 'RFP_Declined', key: 'RFP_Declined' },
			{ label: 'RFP_RequestForProposalEnded', key: 'RFP_RequestForProposalEnded' },
		],
	},
];

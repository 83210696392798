import { Button, Modal } from 'antd';
import React, { FC, useCallback, useState } from 'react';
import AssetCardDisplay from '../asset_card_display/AssetCardDisplay';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { connect } from 'react-redux';
import { ROLE_TYPES } from '../../utils/DataConstants';
import {
	assetsRestCrudThunksForBuyer,
	assetsRestCrudThunksForSupplier,
} from '../../thunks/assets_thunks';

const SameAssetNumberResolveModal: FC<any> = ({
	conflictingAsset,
	onSuccess,
	updateAsset,
	onCancel,
}): React.ReactElement => {
	const [isLoading, setIsLoading] = useState(false);

	const handleAssetConflictingConfirm = useCallback(() => {
		setIsLoading(true);
		updateAsset({
			...conflictingAsset,
			assetNumber: undefined,
			assetUUID: undefined,
		})
			.then((newAsset) => {
				// @ts-ignore
				// form.setFieldsValue({ assetUUID: conflictingAsset.assetUUID });
				onSuccess();
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => setIsLoading(false));
	}, [conflictingAsset, onSuccess, updateAsset]);

	return (
		<Modal
			visible={true}
			width={600}
			title={`There's already an asset numbered ${nullSafeGetOrElse(
				'assetNumber',
				conflictingAsset,
				'--'
			)}`}
			okText="Update"
			onCancel={onCancel}
			footer={[
				<Button loading={isLoading} onClick={handleAssetConflictingConfirm}>
					Override
				</Button>,
				<Button type="primary" onClick={onCancel}>
					Cancel
				</Button>,
			]}
			closable={true}
		>
			{nullSafeGet('id', conflictingAsset) ? <AssetCardDisplay asset={conflictingAsset} /> : null}
		</Modal>
	);
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	updateAsset: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetsRestCrudThunksForSupplier.update(entity)
				: assetsRestCrudThunksForBuyer.update(entity)
		),
});

const ComponentWithoutUserType = connect(null, mapDispatchToProps)(SameAssetNumberResolveModal);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

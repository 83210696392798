import {
	pipelineFilters,
	SuplierPipelinefilters,
} from '../components/InvoicesTable/config/pipelineFilters/pipelineFilters';
import { DEFAULT_SORTER, GLOBAL_DEBOUNCE_TIME, ROLE_TYPES } from '../utils/DataConstants';
import {
	downloadInvoiceCSVForBuyer,
	downloadInvoiceCSVForSupplier,
	downloadInvoiceFormatCSVForBuyer,
	downloadInvoiceFormatCSVForSupplier,
	invoicesRestCrudThunksForBuyer,
	invoicesRestCrudThunksForSupplier,
	updateInvoiceStatusBulk,
} from './invoices_thunks';
import {
	invoiceDownloadFormatsRestCrudThunksForBuyer,
	invoiceDownloadFormatsRestCrudThunksForSupplier,
} from './invoice_download_formats_thunks';
import { INVOICES_CRUD_ACTION_CREATORS } from '../actions/invoices_actions';

const queryString = require('qs');
const defaultPagination = { current: 1, pageSize: 10 };

const debounce = require('debounce-promise');

const getMethodByRole = (userType) =>
	userType === ROLE_TYPES.SUPPLIER
		? invoicesRestCrudThunksForSupplier
		: invoicesRestCrudThunksForBuyer;
const parseURL = (
	handleQueryParams,
	history,
	location,
	preAppliedFilters,
	initialPagination,
	initialSorting,
	initialFilters = {},
	initialSelections = []
) => {
	let pagination = initialPagination || defaultPagination;
	let sorting = initialSorting || DEFAULT_SORTER;
	let filters = { ...preAppliedFilters, ...initialFilters };
	let selections = initialSelections;
	if (handleQueryParams && location) {
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);

		const {
			current = pagination.current,
			pageSize = pagination.pageSize,
			sort_by = sorting.sort_by,
			order = sorting.order,
			...workOrderFilters
		} = queryParams;

		pagination = { current: parseInt(current), pageSize: parseInt(pageSize) };
		sorting = { sort_by, order };
		filters = {
			...(workOrderFilters || {}),
			...preAppliedFilters,
		};
	}
	return { pagination, sorting, filters, selections };
};
export const fetchInvoices =
	(userType) =>
	(
		handleQueryParams,
		history,
		location,
		targetCollectionName,
		preAppliedFilters = {},
		initialPagination,
		initialSorting,
		initialFilters,
		ignoreRefreshCounts
	) =>
	(dispatch, getState) => {
		const { pagination, sorting, filters } = parseURL(
			handleQueryParams,
			history,
			location,
			preAppliedFilters,
			initialPagination,
			initialSorting,
			initialFilters
		);
		dispatch(updateSelection(userType)(false, history, targetCollectionName, []));
		dispatch(
			fetchData(userType)(
				handleQueryParams,
				history,
				null,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				ignoreRefreshCounts
			)
		);
	};

export const updatePaginationInvoices =
	(userType) =>
	(handleQueryParams, history, targetCollectionName, pagination, ignoreRefreshCounts) =>
	(dispatch, getState) => {
		const { sorting, filters } = getState().invoices[targetCollectionName];
		dispatch(
			fetchData(userType)(
				handleQueryParams,
				history,
				null,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				ignoreRefreshCounts
			)
		);
	};

export const updateFiltersInvoices =
	(userType) =>
	(handleQueryParams, history, targetCollectionName, filters, ignoreRefreshCounts) =>
	(dispatch, getState) => {
		const {
			sorting,
			filters: storeFilters,
			pagination,
		} = getState().invoices[targetCollectionName];
		dispatch(
			fetchData(userType)(
				handleQueryParams,
				history,
				null,
				targetCollectionName,
				{ ...pagination, current: 1 },
				sorting,
				{
					...storeFilters,
					...filters,
				},
				ignoreRefreshCounts
			)
		);
	};

export const replaceFiltersAndSortingInvoices =
	(userType) =>
	(handleQueryParams, history, targetCollectionName, filters, sorting, ignoreRefreshCounts) =>
	(dispatch, getState) => {
		const { pagination } = getState().invoices[targetCollectionName];
		dispatch(
			fetchData(userType)(
				handleQueryParams,
				history,
				null,
				targetCollectionName,
				{ ...pagination, current: 1 },
				sorting,
				filters,
				ignoreRefreshCounts
			)
		);
	};

export const clearFiltersInvoices =
	(userType) =>
	(handleQueryParams, history, targetCollectionName, preAppliedFilters, ignoreRefreshCounts) =>
	(dispatch, getState) => {
		const { pagination } = getState().invoices[targetCollectionName];
		dispatch(
			fetchData(userType)(
				handleQueryParams,
				history,
				null,
				targetCollectionName,
				{ ...pagination, current: 1 },
				{ ...DEFAULT_SORTER },
				{
					...preAppliedFilters,
				},
				ignoreRefreshCounts
			)
		);
	};

export const updateSortingInvoices =
	(userType) =>
	(handleQueryParams, history, targetCollectionName, sorting, ignoreRefreshCounts) =>
	(dispatch, getState) => {
		const { filters, pagination } = getState().invoices[targetCollectionName];
		dispatch(
			fetchData(userType)(
				handleQueryParams,
				history,
				null,
				targetCollectionName,
				{ ...pagination, current: 1 },
				sorting,
				filters,
				ignoreRefreshCounts
			)
		);
	};
export const refreshCounts =
	(userType) => (targetCollectionName, filters) => (dispatch, getState) => {
		const finalPipelineFilters =
			userType === ROLE_TYPES.SUPPLIER ? SuplierPipelinefilters : pipelineFilters;
		finalPipelineFilters.forEach((pf) => {
			dispatch(
				getMethodByRole(userType).countBy(
					{
						...filters,
						statuses: undefined,
						...pf.filter,
					},
					pf.name
				)
			);
		});
	};

const fetchDataFromServer = (props) => {
	const {
		dispatch,
		userType,
		addtionalParams,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		ignoreRefreshCounts,
	} = props;
	dispatch(
		getMethodByRole(userType).read(
			addtionalParams,
			targetCollectionName,
			pagination,
			sorting,
			filters
		)
	);
	!ignoreRefreshCounts && dispatch(refreshCounts(userType)(targetCollectionName, filters));
};

const debouncedFetchData = debounce(fetchDataFromServer, GLOBAL_DEBOUNCE_TIME);

const fetchData =
	(userType) =>
	(
		handleQueryParams,
		history,
		addtionalParams,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		ignoreRefreshCounts
	) =>
	(dispatch) => {
		const props = {
			dispatch,
			userType,
			addtionalParams,
			targetCollectionName,
			pagination,
			sorting,
			filters,
			ignoreRefreshCounts,
		};
		if (targetCollectionName && targetCollectionName.includes('_widget_')) {
			fetchDataFromServer(props);
		} else {
			dispatch(INVOICES_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollectionName));
			debouncedFetchData(props);
		}
		if (handleQueryParams) {
			const queryParams = queryString.stringify({
				...{
					current: pagination.current,
					pageSize: pagination.pageSize,
				},
				...sorting,
				...filters,
				...addtionalParams,
			});
			history.replace(`${window.location.pathname}?${queryParams}`);
		}
	};

export const downloadInvoicesCSV = (userType) => (params, filters) => (dispatch) => {
	const fnToCall =
		userType === ROLE_TYPES.SUPPLIER ? downloadInvoiceCSVForSupplier : downloadInvoiceCSVForBuyer;
	dispatch(fnToCall(params, filters));
};

export const fetchInvoiceDownloadFormats = (userType) => (params) => (dispatch) => {
	const fnToCall =
		userType === ROLE_TYPES.SUPPLIER
			? invoiceDownloadFormatsRestCrudThunksForSupplier
			: invoiceDownloadFormatsRestCrudThunksForBuyer;
	return dispatch(fnToCall.read(params, 'invoiceFormatsIndex'));
};

export const downloadInvoiceFormatCSV =
	(userType) => (formatName, params, filters) => (dispatch) => {
		const fnToCall =
			userType === ROLE_TYPES.SUPPLIER
				? downloadInvoiceFormatCSVForSupplier
				: downloadInvoiceFormatCSVForBuyer;
		return dispatch(fnToCall(formatName, params, filters));
	};

export const updateSelection =
	(userType) =>
	(handleQueryParams, history, targetCollectionName, selections) =>
	(dispatch, getState) => {
		return dispatch(
			INVOICES_CRUD_ACTION_CREATORS.updateSelections(selections, targetCollectionName)
		);
	};

export const updateInvoiceStatusBulkWrapper =
	(roleType) =>
	(ids, status, targetCollectionName, invoiceParams, additionalParams) =>
	(dispatch, getState) => {
		const { sorting, pagination, history, filters } = getState().invoices[targetCollectionName];
		return new Promise((resolve, reject) => {
			dispatch(updateInvoiceStatusBulk(roleType)(ids, status, additionalParams))
				.then((data) => {
					if (data.status !== 'error') {
						let ents = data.map((d) => {
							let record = {};
							invoiceParams.map((param) => (record[param] = d[param]));
							record['id'] = record['id'].toString();
							return record;
						});
						dispatch(updateSelection(roleType)(false, history, targetCollectionName, ents));
						dispatch(
							fetchData(roleType)(
								false,
								history,
								{},
								targetCollectionName,
								pagination,
								sorting,
								filters,
								false
							)
						);
						resolve(data); //Async with fetch
					} else {
						reject(data);
					}
				})
				.catch((error) => reject(error));
		});
	};

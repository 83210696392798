import {
	FETCH_CREDITS_ERROR,
	FETCH_CREDITS_START,
	FETCH_CREDITS_SUCCESS,
} from '../actions/credits_actions';
import { CreditsStoreState } from '../types/models';

const initialState: CreditsStoreState = {
	availableCredits: 0,
	totalCreditsPurchasedToDate: 0,
	loading: false,
	errors: [],
};

export default (oldState = initialState, action): CreditsStoreState => {
	Object.freeze(oldState);
	switch (action.type) {
		case FETCH_CREDITS_START:
			return {
				...oldState,
				loading: true,
			};
		case FETCH_CREDITS_ERROR:
			return {
				...oldState,
				errors: [action.error],
				loading: false,
			};
		case FETCH_CREDITS_SUCCESS:
			return {
				...oldState,
				availableCredits: action.availableCredits,
				totalCreditsPurchasedToDate: action.totalCreditsPurchasedToDate,
				loading: false,
			};
		default:
			return oldState;
	}
};

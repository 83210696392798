import React, { FC, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router';
import { Layout } from 'antd';
import ScrollToTopOnMount from '../scroll_to_top_on_mount/ScrollToTopOnMount';
import SubnavTabs from '../subnav_tabs/SubnavTabs';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { BUYER_ADMIN, SUPER_ADMIN, SUPPLIER_ADMIN } from '../roles/roles';
import { PERMISSION_NAMES, isUserAllowedToAccess } from '../../utils/AuthUtils';
const { Content } = Layout;

require('./LocationsOverviewPage.less');

const LocationsOverviewPage: FC<any> = ({
	userType,
	history,
	companyConfig,
	currentUser,
	childRoutes,
}): React.ReactElement => {
	const handleTabChange = useCallback(
		(key) => {
			history.push(`/${userType}/locations/overview/${key}`);
		},
		[history, userType]
	);

	const checkTabActive = useCallback(
		(key) => {
			const pathname = history.location.pathname;
			return pathname.indexOf(key) !== -1;
		},
		[history.location.pathname]
	);

	const isAdmin = useMemo(
		() =>
			currentUser.roles.includes(BUYER_ADMIN) ||
			currentUser.roles.includes(SUPER_ADMIN) ||
			currentUser.roles.includes(SUPPLIER_ADMIN),
		[currentUser.roles]
	);

	const templatesVisible = useMemo(
		() =>
			isUserAllowedToAccess(currentUser.userType)(
				PERMISSION_NAMES.MODIFY_LOCATION_TYPES,
				companyConfig,
				currentUser.roles
			),
		[companyConfig, currentUser.roles, currentUser.userType]
	);

	const tabs = useMemo(
		() => [
			{
				key: 'all',
				name: 'All',
			},
			...(templatesVisible
				? [
						{
							key: 'templates/overview',
							name: 'Templates',
						},
				  ]
				: []),
			...(isAdmin
				? [
						{
							key: 'regions/overview',
							name: 'Regions',
						},
						{
							key: 'brands/overview',
							name: 'Brands',
						},
				  ]
				: []),
		],
		[isAdmin, templatesVisible]
	);

	return (
		<div>
			<ScrollToTopOnMount />
			<div>
				<Route
					path={`/${userType}/locations/overview`}
					exact={true}
					render={(props) => (
						<Redirect
							to={{
								pathname: `/${userType}/locations/overview/all`,
							}}
						/>
					)}
				/>
				<Content className="locationsPage" style={{ padding: '0 0.5em' }}>
					<div
						style={{
							margin: '0 -8px',
							padding: '24px',
							paddingBottom: 0,
							background: nullSafeGet('config.theme.navblock', companyConfig),
							marginBottom: 8,
						}}
					>
						<h4
							style={{
								color: 'rgba(255, 255, 255, 0.9)',
								marginRight: 8,
								marginBottom: 24,
							}}
						>
							Locations
						</h4>
						<SubnavTabs tabs={tabs} onChange={handleTabChange} isActive={checkTabActive} />
					</div>
					{childRoutes.map((r, i) => (
						<RouteWithSubRoutes key={i} {...r} />
					))}
				</Content>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	companyConfig: state.company_config.detail,
	currentUser: state.session.currentUser,
});

const ComponentWithoutUserType = withRouter<any, any>(
	connect(mapStateToProps)(LocationsOverviewPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

export const DAYS_OF_THE_WEEK = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
];

export const TOKEN_DEFAULT_EXPIRY = 6 * 60 * 60 * 1000;

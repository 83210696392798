import React, { FC, useMemo, ReactElement, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { nullSafeGetOrElse, renderCurrency } from '../../utils/DataAccessUtils';
import { Button, Card, Col, Layout, Row } from 'antd';
import { HorizontalKeyValueDisplay } from '../horizontal_key_value_display/HorizontalKeyValueDisplay';
import { withRouter } from 'react-router';
import ReservedQtyDisplay from './ReservedQtyDisplay';
import RequestedQuantityDisplay from './RequestedQuantityDisplay';
import { DATE_FORMAT, dateFormatter, getContactName } from '../../utils/DataFormatterUtils';
import { ExportOutlined } from '@ant-design/icons';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { EmptyState } from '../empty_state/EmptyState';
import {
	downloadPpslAuditsCSVForSupplier,
	ppslAuditsCrudThunksForSupplier,
} from '../../thunks/ppsl_audits_thunks';
import { getAuditFieldNameByKey } from '../../utils/AuditUtils';
import HyperLink, { ENTITY_TYPE } from '../common/HyperLink';
import DateTimeHover from '../date_time_component/DateTime';

const { Content } = Layout;
const BACK_TEXT = 'Back to Part Per Stock Location Detail';

const PartsPerStockLocationDetailsDetailPage: FC<any> = ({
	ppsl,
	fetching,
	match,
	exportCsv,
	currentUser,
}): ReactElement => {
	const [exporting, setExporting] = useState(false);

	const reservedQuantityAvailable = useMemo(() => false, []);

	const partsPerStockLocationId = useMemo(() => match.params.id, [match.params.id]);

	const epslDetail = useMemo(
		() => ({
			'Available Qty': nullSafeGetOrElse('availableQuantity', ppsl, 0),
			'On Hand Qty': nullSafeGetOrElse('onHandQuantity', ppsl, 0),
			...(reservedQuantityAvailable && {
				'Reserved Qty': <ReservedQtyDisplay record={ppsl} backText={BACK_TEXT} />,
			}),
			'Unit Cost': renderCurrency(currentUser)(
				nullSafeGetOrElse('totalStockedPartsCost', ppsl, 0) /
					nullSafeGetOrElse('onHandQuantity', ppsl, 1)
			),
			'Min Qty': nullSafeGetOrElse('minQuantity', ppsl, 0),
			'Max Qty': nullSafeGetOrElse('maxQuantity', ppsl, 0),
			'Requested Qty': <RequestedQuantityDisplay record={ppsl} backText={BACK_TEXT} />,
			'Stock Location': (
				<HyperLink
					text={nullSafeGetOrElse('stockLocation.name', ppsl, '--')}
					entityType={ENTITY_TYPE.STOCK_LOCATION}
					entityId={nullSafeGetOrElse('stockLocation.id', ppsl, '--')}
					backLinkText="Back to PPSL"
				/>
			),
			Aisle: nullSafeGetOrElse('aisle', ppsl, '--'),
			Bay: nullSafeGetOrElse('bay', ppsl, '--'),
			Level: nullSafeGetOrElse('level', ppsl, '--'),
			Bin: nullSafeGetOrElse('bin', ppsl, '--'),
		}),
		[currentUser, ppsl, reservedQuantityAvailable]
	);

	const auditColumns = useMemo(
		() => [
			{
				title: 'Audit Type',
				dataIndex: 'auditType',
				render: (_) => getAuditFieldNameByKey(_),
			},
			{
				title: 'Updated By',
				dataIndex: 'createdByContact',
				render: (_) => getContactName(_),
			},
			{
				title: 'Updated At',
				dataIndex: 'createdAt',
				render: (_) => (_ ? <DateTimeHover timestamp={_} /> : '--'),
			},
			{
				title: 'Quantity',
				dataIndex: 'diffQuantity',
				render: (_) => _ || '0',
			},
			{
				title: 'Unit Cost',
				dataIndex: 'unitCost',
				render: (_) => renderCurrency(currentUser)(_),
			},
			{
				title: 'Extended Cost',
				dataIndex: 'extendedCost',
				render: (_) => renderCurrency(currentUser)(_),
			},
			{
				title: 'Total Quantity',
				dataIndex: 'totalQuantity',
			},
			{
				title: 'Total Cost',
				dataIndex: 'totalCost',
				render: (_) => renderCurrency(currentUser)(_),
			},
			{
				title: 'Notes',
				dataIndex: 'notes',
			},
		],
		[currentUser]
	);

	const onExportAudits = useCallback(() => {
		setExporting(true);
		exportCsv({ partsPerStockLocationId }).finally(() => setExporting(false));
	}, [exportCsv, partsPerStockLocationId]);

	return (
		<Content
			style={{
				padding: '0 0.5em',
			}}
		>
			<Row style={{ margin: '16px 4px' }} gutter={12}>
				<Col span={24}>
					<Card loading={fetching} title="Details">
						<HorizontalKeyValueDisplay rowStyle={{ minWidth: '25%' }} keyValueStore={epslDetail} />
					</Card>
				</Col>
			</Row>
			<Row style={{ margin: '16px 4px' }} gutter={12}>
				<Col span={24}>
					<div className="rowSpacing">
						<Card
							bodyStyle={{ padding: 0 }}
							title="Audits"
							extra={
								<Button
									icon={<ExportOutlined translate="" />}
									type="ghost"
									onClick={onExportAudits}
									loading={exporting}
								>
									Export
								</Button>
							}
						>
							<PaginatedReduxTable
								mode={'list'}
								tableLayoutFixed={true}
								showHeader={true}
								emptyState={
									<EmptyState
										graphic={
											<img
												style={{ marginBottom: 8 }}
												src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
												alt="No audits found!"
											/>
										}
										headline={"It's a little lonely in here."}
										body={null}
									/>
								}
								collectionName="ppsl_audits"
								targetCollectionName={'ppslAuditsIndex'}
								columns={auditColumns}
								keyAccessor={(el) => el.id}
								initialFilters={{ partsPerStockLocationId }}
								initialPagination={{ current: 1, pageSize: 10 }}
								fetchData={ppslAuditsCrudThunksForSupplier.read}
							/>
						</Card>
					</div>
				</Col>
			</Row>
		</Content>
	);
};

const mapStateToProps = (state) => ({
	fetching: state.parts_per_stock_locations.fetching,
	ppsl: state.parts_per_stock_locations.detail,
	stockLocation: state.stock_locations.detail,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	exportCsv: (params) => dispatch(downloadPpslAuditsCSVForSupplier(params)),
});

const ComponentWithoutUserType = withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(PartsPerStockLocationDetailsDetailPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

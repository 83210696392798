import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Form, InputNumber, Spin } from 'antd';
import OWRadioGroup from '../ow_radio_group/OWRadioGroup';
import {
	nullSafeGet,
	nullSafeGetOrElse,
	renderCurrencyByCurrencyId,
	roundOffToDecimal,
} from '../../utils/DataAccessUtils';
import { connect } from 'react-redux';

interface PartEquipmentCostFormFieldProps {
	currentUser?: any;
	unitCost: Number;
	entityId: Number;
	entityName: string;
	vendorId: Number;
	fetchMethod: any;
	setFieldsValue: any;
	form: any;
	currencyId: any;
}

const PartEquipmentCostFormField: FC<PartEquipmentCostFormFieldProps> = ({
	entityId,
	entityName,
	vendorId,
	fetchMethod,
	setFieldsValue,
	form,
	currencyId,
}) => {
	const unitCost = Form.useWatch('unitCost', form);

	const [catalog, setCatalog] = useState(null);
	const [catalogLoading, setCatalogLoading] = useState(false);
	const [selectedCost, setSelectedCost] = useState(null);

	const refreshCatalog = useCallback(() => {
		setCatalogLoading(true);
		fetchMethod({
			[entityName]: entityId,
			partEquipmentVendorId: vendorId,
		})
			.then((res) => {
				const catalog = res && res.length > 0 ? res[0] : null;
				setCatalog(catalog);
			})
			.finally(() => setCatalogLoading(false));
	}, [entityId, entityName, fetchMethod, vendorId]);

	useEffect(() => {
		unitCost &&
			(parseFloat(nullSafeGetOrElse('unitCost', catalog, 0)) === parseFloat(unitCost) ||
				parseFloat(nullSafeGetOrElse('listPrice', catalog, 0)) === parseFloat(unitCost)) &&
			setSelectedCost(`${unitCost}`);
	}, [catalog, unitCost]);

	useEffect(() => {
		entityId && vendorId && refreshCatalog();
	}, [entityId, refreshCatalog, setFieldsValue, vendorId]);

	const onPriceSelect = useCallback(
		(unitCost) => {
			setFieldsValue({ unitCost: unitCost ? roundOffToDecimal(unitCost) : unitCost });
			setSelectedCost(unitCost);
		},
		[setFieldsValue]
	);

	const catalogCurrencyId = useMemo(
		() => nullSafeGetOrElse('partEquipmentVendor.currencyId', catalog, currencyId),
		[catalog, currencyId]
	);

	return (
		<>
			<Form.Item
				name="unitCost"
				label={
					<div className="flex flex-row items-center">
						Unit Cost
						{catalogLoading ? (
							<div className="ml-2">
								<Spin size="small" />
							</div>
						) : nullSafeGet('unitCost', catalog) || nullSafeGet('listPrice', catalog) ? (
							<div className="ml-2">
								<OWRadioGroup
									value={selectedCost}
									onChange={onPriceSelect}
									disabled={catalogLoading}
									valueAccessor={(item) => item.value}
									renderRecord={(item) => item.label}
									items={[
										...(nullSafeGet('listPrice', catalog)
											? [
													{
														label: `List Price ${renderCurrencyByCurrencyId(catalogCurrencyId)(
															nullSafeGet('listPrice', catalog)
														)}`,
														value: nullSafeGet('listPrice', catalog),
													},
											  ]
											: []),
										...(nullSafeGet('unitCost', catalog)
											? [
													{
														label: `Last Price ${renderCurrencyByCurrencyId(catalogCurrencyId)(
															nullSafeGet('unitCost', catalog)
														)}`,
														value: nullSafeGet('unitCost', catalog),
													},
											  ]
											: []),
									]}
								/>
							</div>
						) : null}
					</div>
				}
				className="w-100"
			>
				<InputNumber style={{ width: '100%' }} addonAfter={catalogCurrencyId} />
			</Form.Item>
		</>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

export default connect(mapStateToProps)(PartEquipmentCostFormField);

import React, { FC, useCallback, useMemo, ReactElement } from 'react';
import { connect } from 'react-redux';
import { nullSafeGet, nullSafeGetOrElse, renderCurrency } from '../../utils/DataAccessUtils';
import { Card, Col, Layout, Row } from 'antd';
import { HorizontalKeyValueDisplay } from '../horizontal_key_value_display/HorizontalKeyValueDisplay';
import { withRouter } from 'react-router';
import OWEditableNotesComponent from '../common/OWEditableNotesComponent';
import ListPriceHistory from '../parts_index_page/ListPriceHistory';
import {
	equipmentCatalogsRestCrudThunksForSupplier,
	updateEquipmentCatalogsListPriceForSupplier,
} from '../../thunks/equipment_catalogs_thunks';

const { Content } = Layout;

const EquipmentCatalogDetailsDetailPage: FC<any> = ({
	equipmentCatalog,
	currentUser,
	fetching,
	update,
	updatePriceList,
	fetchEquipmentCatalog,
	match,
}): ReactElement => {
	const refreshEquipmentCatalog = useCallback(() => {
		match.params.id && fetchEquipmentCatalog(match.params.id);
	}, [fetchEquipmentCatalog, match.params.id]);

	const equipmentCatalogDetail = useMemo(
		() => ({
			Vendor: nullSafeGetOrElse('partEquipmentVendor.name', equipmentCatalog, '__'),
			'Last Price': renderCurrency(currentUser)(equipmentCatalog.unitCost),
			'List Price': renderCurrency(currentUser)(equipmentCatalog.listPrice),
		}),
		[currentUser, equipmentCatalog]
	);

	const handleSaveNotes = useCallback(
		(notes) =>
			update({
				...equipmentCatalog,
				notes,
			}),
		[equipmentCatalog, update]
	);

	const onUpdateListPrice = useCallback(
		(values) => {
			const data = {
				...values,
				equipmentTypeNumber: nullSafeGet('equipmentType.equipmentTypeNumber', equipmentCatalog),
				vendorId: nullSafeGet('partEquipmentVendor.vendorId', equipmentCatalog),
			};
			return updatePriceList([data]);
		},
		[equipmentCatalog, updatePriceList]
	);

	return (
		<Content
			style={{
				padding: '0 0.5em',
			}}
		>
			<Row style={{ margin: '16px 4px' }} gutter={12}>
				<Col span={16}>
					<Card loading={fetching} title="Details">
						<HorizontalKeyValueDisplay
							rowStyle={{ minWidth: '25%' }}
							keyValueStore={equipmentCatalogDetail}
						/>
					</Card>
					<ListPriceHistory
						onUpdate={onUpdateListPrice}
						loading={fetching}
						listPrices={nullSafeGetOrElse('listPrices', equipmentCatalog, [])}
						refreshEntity={refreshEquipmentCatalog}
					/>
				</Col>
				<Col span={8}>
					<OWEditableNotesComponent
						loading={fetching}
						id="equipmentCatalogNotes"
						tooltipMessage=""
						title="Notes"
						allowEdit={true}
						notes={nullSafeGetOrElse('notes', equipmentCatalog, '')}
						onSaveNotes={handleSaveNotes}
					/>
				</Col>
			</Row>
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	fetching: state.equipment_catalogs.fetching,
	equipmentCatalog: state.equipment_catalogs.detail,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	update: (entity) => dispatch(equipmentCatalogsRestCrudThunksForSupplier.update(entity)),
	fetchEquipmentCatalog: (id) => dispatch(equipmentCatalogsRestCrudThunksForSupplier.readOne(id)),
	updatePriceList: (entities) => dispatch(updateEquipmentCatalogsListPriceForSupplier(entities)),
});

const DCDetailPage = withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(EquipmentCatalogDetailsDetailPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(DCDetailPage);

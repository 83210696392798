import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ProposalForm from '../proposal_form/ProposalForm';
import { proposalsRestCrudThunksForSupplier } from '../../thunks/proposals_thunks';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const queryString = require('qs');

class EditProposalPage extends React.Component<any, any> {
	componentDidMount() {
		const { location, match, getProposal } = this.props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		const proposalId = queryParams.proposalId;
		getProposal(proposalId);
	}

	render() {
		const { history, proposal, fetching } = this.props;

		const formData = {
			...proposal,
			workOrderId: proposal.workOrderId,
			requestForProposalId: proposal.requestForProposalId,
		};

		return [
			<LogOnMountWithStandardEventProperties eventType="visited edit proposal page" />,
			<EditPage
				title="Edit Proposal"
				formComponentProps={{
					formData: formData,
					onSuccess: (rec) =>
						history.push(`/supplier/opportunities/detail/${rec.requestForProposal.id}`),
				}}
				fetching={fetching}
				FormComponent={ProposalForm}
			/>,
		];
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	proposal: state.proposals.detail,
	fetching: state.proposals.fetching,
});

const mapDispatchToProps = (dispatch) => ({
	getProposal: (id) => dispatch(proposalsRestCrudThunksForSupplier.readOne(id)),
});

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(EditProposalPage));

import { PURCHASE_ORDER_RECEIPTS_CRUD_ACTION_CREATORS } from '../actions/purchase_order_receipts_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';

export const purchaseOrderReceiptsRestCrudThunksForSupplier = new RestCrudThunks(
	'purchase_order_receipts',
	'purchase_order_receipt',
	'purchaseOrderReceipt',
	'purchaseOrderReceipts',
	PURCHASE_ORDER_RECEIPTS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'inventory/'
);

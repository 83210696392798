import { nullSafeGetOrElse } from './DataAccessUtils';

export const getPOLineItemUnitCostValue = (record, type) => {
	const quantity = nullSafeGetOrElse(`${type}Quantity`, record, 1);
	const totalCost =
		record[`${type}ReceivedAverageCost`] !== undefined
			? record[`${type}ReceivedAverageCost`] * quantity
			: record[`${type}Cost`] !== undefined
			? record[`${type}Cost`]
			: undefined;
	return {
		totalCost,
		unitCost: totalCost !== undefined ? totalCost / quantity : undefined,
	};
};

export const getPurchaseOrderTotalCost = (purchaseOrder) => {
	const partLineItems = nullSafeGetOrElse('partSupplierPurchaseOrderLineItems', purchaseOrder, []);
	const totalPartCost = partLineItems.reduce(
		(acc, part) => acc + parseFloat(getPOLineItemUnitCostValue(part, 'part')['totalCost'] || 0),
		0
	);

	const equipmentLineItems = nullSafeGetOrElse(
		'equipmentSupplierPurchaseOrderLineItems',
		purchaseOrder,
		[]
	);
	const totalEquipmentCost = equipmentLineItems.reduce(
		(acc, et) => acc + parseFloat(getPOLineItemUnitCostValue(et, 'equipment')['totalCost'] || 0),
		0
	);

	return (
		totalPartCost +
		totalEquipmentCost +
		parseFloat(nullSafeGetOrElse('freightTotalBeforeTax', purchaseOrder, 0)) +
		parseFloat(nullSafeGetOrElse('miscTotalBeforeTax', purchaseOrder, 0)) +
		parseFloat(nullSafeGetOrElse('tax', purchaseOrder, 0))
	);
};

import React, { FC, useEffect, useMemo, useState } from 'react';
import {
	Button,
	DatePicker,
	Form,
	Input,
	InputNumber,
	Popover,
	Radio,
	Row,
	Select,
	Slider,
	SliderSingleProps,
	Steps,
	Switch,
	TreeSelect,
	message,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import ProblemTypeTreeSelect from '../problem_type_tree_select';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
	companyConfigRestCrudThunks,
	companyConfigRestCrudThunksForBuyer,
} from '../../thunks/company_config_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';
import {
	problemTypesHeirarchicalForBuyer,
	problemTypesRestCrudThunksForBuyer,
} from '../../thunks/problem_types_thunks';
import { budgetsRestCrudThunksForBuyer } from '../../thunks/budget_thunks';
import { getCurrency, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import BuyerRegionsLocationsTreeSelect from '../regions_tree_select/BuyerRegionsLocationsTreeSelect';
import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';
import { getNumberSuffix } from '../../utils/DataFormatterUtils';
import DistinctLocationBudgetComponent from './DistinctLocationBudgetComponent';
import { fetchAllLocationsForBuyer } from '../../thunks/locations_thunks';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import {
	fetchSupplierFacilitiesInPrivateNetworkForBuyer,
	supplierFacilitiesRestCrudThunksForBuyer,
} from '../../thunks/supplier_facilities_thunks';

const WORK_ORDER_FORM_PROBLEM_TYPES_AUTOCOMPLETE = 'WORK_ORDER_FORM_PROBLEM_TYPES_AUTOCOMPLETE';
const BUDGETS_FORM_SUPPLIERS_AUTOCOMPLETE = 'BUDGETS_FORM_SUPPLIERS_AUTOCOMPLETE';
const BUDGETS_FORM_LOCATIONS_AUTOCOMPLETE = 'BUDGETS_FORM_LOCATIONS_AUTOCOMPLETE';

const BudgetForm: FC<any> = ({
	formData,
	currentUser,
	onSuccess,

	createBudgetForm,
	updateBudgetForm,

	locations,
	problemTypes,
	fetchProblemTypes,
	fetchMultipleProblemTypes,

	supplierFacilities,
	fetchSupplierFacilitiesInPrivateNetwork,
	fetchMultipleSupplierFacilitiesInPrivateNetwork,

	fetchAllLocations,
}): React.ReactElement => {
	const [form] = Form.useForm();

	const [buttonLoading, setButtonLoading] = useState(false);
	const [fetchingAllLocations, setFetchingAllLocations] = useState(false);
	const [allLocations, setAllLocations] = useState([]);
	const [locationDistinctBudget, setLocationDistinctBudget] = useState([]);

	const [step, setStep] = useState(0);
	const currency = getCurrency({ currentUser });

	const budgetShareType = Form.useWatch('budgetShareType', form);
	const sharedBudget = Form.useWatch('sharedBudget', form);
	const locationIds = Form.useWatch('locationIds', form);
	const values = Form.useWatch([], form);

	const isBudgetDistinctForEachLoc = useMemo(
		() => budgetShareType === 'distinct',
		[budgetShareType]
	);

	useEffect(() => {
		if (isBudgetDistinctForEachLoc && !fetchingAllLocations && !nullSafeGet('0', allLocations)) {
			setFetchingAllLocations(true);
			fetchAllLocations()
				.then((res) => {
					setAllLocations(res);
				})
				.catch(() => message.error('Unable to fetch all locations'))
				.finally(() => setFetchingAllLocations(false));
		}
	}, [allLocations, fetchAllLocations, fetchingAllLocations, isBudgetDistinctForEachLoc]);

	useEffect(() => {
		if (!!nullSafeGet('0', allLocations)) {
			const locations = locationIds
				? allLocations.filter((loc) => locationIds.includes(loc.id))
				: allLocations;
			const { budgetPerLocations = [] } = formData;
			setLocationDistinctBudget(
				locations.map((loc) => {
					const locationBudget =
						budgetPerLocations.find((budgetloc) => budgetloc.locationId === loc.id) || {};
					return {
						...loc,
						...locationBudget,
					};
				})
			);
		}
	}, [allLocations, sharedBudget, locationIds]);

	const handleSubmit = (values) => {
		form
			.validateFields(['sharedBudget'], (err) => {
				console.log(err);
			})
			.then((validationValues) => {
				const submit = values.id ? updateBudgetForm : createBudgetForm;
				let budget = {
					id: values.id,
					title: values.title,
					description: values.description,
					startDate: nullSafeGet('periodStartDate', values).startOf('day'),
					endDate: nullSafeGet('periodEndDate', values)
						? nullSafeGet('periodEndDate', values).startOf('day')
						: undefined,
					months: values.months,
					recurring: values.recurring,
					...(isBudgetDistinctForEachLoc
						? {
								budgetPerLocations: locationDistinctBudget.map((loc) => ({
									locationId: parseInt(loc.id),
									budget: parseFloat(loc.budget),
								})),
						  }
						: {
								sharedBudget: values.sharedBudget,
								//Optional
								locationIds: values.locationIds,
						  }),
					thresholdPercentage: values.thresholdPercentage.toString(),
					accrualTypesTracked: values.accrualTypesTracked,

					isCompanyWide: values.isCompanyWide,
					problemTypeIds:
						nullSafeGetOrElse('problemTypeIds', values, []).length > 0
							? values.problemTypeIds
							: undefined,
					supplierFacilityIds:
						nullSafeGetOrElse('supplierFacilityIds', values, []).length > 0
							? values.supplierFacilityIds
							: undefined,
					isCapex: values.isCapex,
					isPM: values.isPM,
					trackOnlyCompletedWorkOrders: values.trackOnlyCompletedWorkOrders,
				};

				setButtonLoading(true);
				submit(budget)
					.then((result) => {
						setButtonLoading(false);
						onSuccess(result);
					})
					.catch((e) => {
						console.log(e);
						console.log('error');
					});
			});
	};

	const onFirstNext = () => {
		form
			.validateFields(['title', 'periodStartDate'], (err) => {
				console.log(err);
			})
			.then((values) => {
				setStep(1);
			});
	};
	const onSecondNext = () => {
		form
			.validateFields(['accrualTypesTracked'], (err) => {
				console.log(err);
			})
			.then((values) => {
				setStep(2);
			});
	};

	const submitLabel = formData.id ? 'Update Budget' : 'Create Budget';
	const initialValues = { ...formData };

	const onlyCompletedWorkOrders = nullSafeGet('trackOnlyCompletedWorkOrders', values);
	const removePendingWorkOrders = (value) => {
		if (value) {
			let array = values.accrualTypesTracked;
			if (array.indexOf('pending_workorders') >= 0) {
				array.splice(array.indexOf('pending_workorders'), 1);
				form.setFieldsValue({ accrualTypesTracked: array });
			}
		}
	};
	const treeData = [
		{
			value: 'WorkOrder',
			key: 'WorkOrder',
			title: 'Work Orders',
			children: [
				...(onlyCompletedWorkOrders
					? []
					: [
							{
								value: 'pending_workorders',
								key: 'pending_workorders',
								title: 'Pending',
								label: 'Work Order - Pending',
							},
					  ]),
				{
					value: 'approved_workorders',
					key: 'approved_workorders',
					title: 'Approved',
					label: 'Work Order - Approved',
				},
			],
		},
		{
			value: 'Quote',
			key: 'Quote',
			title: 'Quotes',
			children: [
				{
					value: 'pending_quotes',
					key: 'pending_quotes',
					title: 'Pending',
					label: 'Quote - Pending',
				},
				{
					value: 'approved_quotes',
					key: 'approved_quotes',
					title: 'Approved',
					label: 'Quote - Approved',
				},
			],
		},
		{
			value: 'Invoice',
			key: 'Invoice',
			title: 'Invoices',
			children: [
				{
					value: 'pending_invoices',
					key: 'pending_invoices',
					title: 'Pending',
					label: 'Invoice - Pending',
				},
			],
		},
	];

	const marks: SliderSingleProps['marks'] = {
		0: '0%',
		100: {
			style: { color: 'black' },
			label: <strong>100%</strong>,
		},
	};
	const startDate = nullSafeGet('periodStartDate', values) || moment();

	return (
		<Form
			style={{ maxWidth: 600 }}
			layout="vertical"
			form={form}
			preserve={false}
			initialValues={{
				...initialValues,
			}}
			onFinish={handleSubmit}
		>
			<Row className={'mb-4'}>
				<Steps current={step}>
					<Steps.Step title="Basic Information" />
					<Steps.Step title="Filters" />
					<Steps.Step title="Locations" />
				</Steps>
			</Row>
			<Form.Item name="id" initialValue={formData.id} hidden={true} />
			<Form.Item
				hidden={step !== 0}
				name="title"
				label="Title"
				rules={[{ required: true, message: 'Please input a title for your budget.' }]}
			>
				<Input />
			</Form.Item>
			<Form.Item hidden={step !== 0} name="description" label="Description">
				<TextArea />
			</Form.Item>
			<Form.Item
				hidden={step !== 0}
				name="periodStartDate"
				label="Budget Start Date"
				initialValue={formData.startDate ? moment(formData.startDate) : undefined}
				required={true}
				rules={[
					{
						message: 'Please input a start date for your budget.',
						validator: (_, value) => {
							if (value) {
								return Promise.resolve();
							} else {
								return Promise.reject();
							}
						},
					},
				]}
			>
				<DatePicker />
			</Form.Item>
			<Form.Item
				hidden={step !== 0}
				name="recurring"
				label="Recurring"
				initialValue={formData.recurring || false}
				valuePropName={'checked'}
			>
				<Switch />
			</Form.Item>
			{values && nullSafeGet('recurring', values) ? (
				<Form.Item
					hidden={step !== 0}
					name="months"
					label="Period"
					initialValue={1}
					required={true}
				>
					<Select>
						<Select.Option value={1}>
							Monthly - Starts on the {startDate.date()}
							{getNumberSuffix(startDate.date())} day of each month
						</Select.Option>
						<Select.Option value={3}>
							Quarterly - Starts on the {startDate.date()}
							{getNumberSuffix(startDate.date())} day every third month
						</Select.Option>
						<Select.Option value={12}>
							Yearly - Starts on {startDate.format('MMM D')}
							{getNumberSuffix(startDate.date())} each year
						</Select.Option>
					</Select>
				</Form.Item>
			) : null}
			<Form.Item
				hidden={step !== 0}
				name="periodEndDate"
				label="Budget End Date"
				initialValue={formData.endDate ? moment(formData.endDate) : undefined}
			>
				<DatePicker />
			</Form.Item>
			<Form.Item
				hidden={step !== 1}
				name="trackOnlyCompletedWorkOrders"
				label="Only Accrue Completed Work Orders"
				initialValue={formData.trackOnlyCompletedWorkOrders || true}
				valuePropName={'checked'}
			>
				<Switch onChange={removePendingWorkOrders} />
			</Form.Item>
			<Form.Item
				hidden={step !== 1}
				name="accrualTypesTracked"
				label="Accrued Types Tracked"
				initialValue={[]}
				rules={[{ required: false, message: 'Please select a record type to track.' }]}
			>
				<TreeSelect
					mode={'multiple'}
					treeCheckable={'multiple'}
					treeData={treeData}
					treeNodeLabelProp="label"
				/>
			</Form.Item>
			<Form.Item
				hidden={step !== 1}
				name={'isPM'}
				label={'Work Order Type'}
				initialValue={undefined}
			>
				<Radio.Group>
					<Radio.Button style={{ flexWrap: 'nowrap' }} value={undefined}>
						Any
					</Radio.Button>
					<Radio.Button style={{ flexWrap: 'nowrap' }} value={true}>
						Planned
					</Radio.Button>
					<Radio.Button style={{ flexWrap: 'nowrap' }} value={false}>
						Reactive
					</Radio.Button>
				</Radio.Group>
			</Form.Item>
			<Form.Item
				hidden={step !== 1}
				label={'Expenditure Type'}
				name={'isCapex'}
				initialValue={undefined}
			>
				<Radio.Group>
					<Radio.Button style={{ flexWrap: 'nowrap' }} value={undefined}>
						Any
					</Radio.Button>
					<Radio.Button style={{ flexWrap: 'nowrap' }} value={false}>
						R&M
					</Radio.Button>
					<Radio.Button style={{ flexWrap: 'nowrap' }} value={true}>
						CapEx
					</Radio.Button>
				</Radio.Group>
			</Form.Item>
			<Form.Item
				hidden={step !== 1}
				name="problemTypeIds"
				label="Problem Type"
				initialValue={undefined}
			>
				<ProblemTypeTreeSelect
					mode="multiple"
					stateSlice={problemTypes}
					targetCollectionName={WORK_ORDER_FORM_PROBLEM_TYPES_AUTOCOMPLETE}
					fetchMultiple={(ids, targetCollectionName) => {
						fetchMultipleProblemTypes(ids, targetCollectionName);
					}}
					fetchData={(
						searchText,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
					) =>
						fetchProblemTypes(
							{ name: searchText },
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						)
					}
					additionalFilters={{}}
					sortBy={{ sort_by: 'name', order: 'ascend' }}
				/>
			</Form.Item>
			<Form.Item
				hidden={step !== 1}
				name="supplierFacilityIds"
				label="Supplier Facilities"
				initialValue={undefined}
			>
				<OWAsyncSelect
					mode="multiple"
					allowClear={true}
					stateSlice={supplierFacilities}
					targetCollectionName={BUDGETS_FORM_SUPPLIERS_AUTOCOMPLETE}
					fetchMultiple={(ids, targetCollectionName) => {
						fetchMultipleSupplierFacilitiesInPrivateNetwork(ids, targetCollectionName);
					}}
					fetchData={(
						searchText,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
					) => {
						fetchSupplierFacilitiesInPrivateNetwork(
							{ name: searchText },
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						);
					}}
					renderRecord={(supplierFacility) => (
						<Select.Option key={supplierFacility.id} value={supplierFacility.id}>
							{supplierFacility.name}
						</Select.Option>
					)}
					additionalFilters={{}}
					sortBy={{ sort_by: 'name', order: 'ascend' }}
				/>
			</Form.Item>
			<Form.Item
				hidden={step !== 2}
				name="isCompanyWide"
				label={
					<div>
						<span>Budget Basis</span>
					</div>
				}
				initialValue={true}
			>
				<Radio.Group>
					<Radio.Button style={{ flexWrap: 'nowrap' }} value={true}>
						Company Wide
					</Radio.Button>
					<Radio.Button style={{ flexWrap: 'nowrap' }} value={false}>
						Location Based
					</Radio.Button>
				</Radio.Group>
			</Form.Item>
			{values && !values.isCompanyWide ? (
				<Form.Item
					label={'Budget Type'}
					initialValue={formData.sharedBudget ? 'shared' : 'distinct'}
					name="budgetShareType"
					hidden={step !== 2}
				>
					<Select>
						<Select.Option value={'shared'}>
							Budget will be shared amongst the selected locations.
						</Select.Option>
						<Select.Option value={'distinct'}>
							Budget will be distinct for each location.
						</Select.Option>
					</Select>
				</Form.Item>
			) : null}
			{values && !values.isCompanyWide ? (
				<Form.Item hidden={step !== 2} name="locationIds" label="Locations">
					<BuyerRegionsLocationsTreeSelect
						mode="multiple"
						emptyWhenDisabled={false}
						filtersSlice={locations}
						filtersValueAccessor={() => form.getFieldValue('locationIds')}
						targetCollectionName={BUDGETS_FORM_LOCATIONS_AUTOCOMPLETE}
						renderRecord={(d) => (
							<Select.Option value={d.id} key={d.id}>
								<div>{d.name}</div>
							</Select.Option>
						)}
						sortBy={{ sort_by: 'name', order: 'ascend' }}
					/>
				</Form.Item>
			) : null}
			{values && (values.isCompanyWide || values.budgetShareType === 'shared') ? (
				<Form.Item
					hidden={step !== 2 || isBudgetDistinctForEachLoc}
					name="sharedBudget"
					rules={[{ required: true, message: 'Please input a budget amount.' }]}
					label={
						<div>
							<span>Shared Budget</span>
							<Popover
								content={
									<span>
										Budget amount is shared{' '}
										{values && values.isCompanyWide ? 'company wide' : 'by each location'}{' '}
										{values && values.recurring ? 'and resets each period' : ''}.
									</span>
								}
							>
								<InfoCircleOutlined translate="" style={{ marginLeft: '12px' }} />
							</Popover>
						</div>
					}
					required={true}
				>
					<InputNumber addonAfter={currency.id} min={0} />
				</Form.Item>
			) : null}
			{values && !values.isCompanyWide && isBudgetDistinctForEachLoc ? (
				<DistinctLocationBudgetComponent
					locationDistinctBudget={locationDistinctBudget}
					loading={fetchingAllLocations}
					onEditBudget={setLocationDistinctBudget}
					allowedBudget={sharedBudget}
					hidden={step !== 2}
				/>
			) : null}
			<Form.Item
				hidden={step !== 2}
				name="thresholdPercentage"
				label="Alert Threshold"
				initialValue={90}
			>
				<Slider marks={marks} />
			</Form.Item>
			<Row className={'gap-2'}>
				<Button
					hidden={step !== 0}
					type="primary"
					loading={buttonLoading}
					className="contactForm__button"
					onClick={onFirstNext}
				>
					Next
				</Button>
				<Button
					loading={buttonLoading}
					className="contactForm__button"
					onClick={() => setStep(0)}
					hidden={step !== 1}
				>
					Back
				</Button>
				<Button
					type="primary"
					loading={buttonLoading}
					className="contactForm__button"
					onClick={onSecondNext}
					hidden={step !== 1}
				>
					Next
				</Button>
				<Button
					hidden={step !== 2}
					loading={buttonLoading}
					className="contactForm__button"
					onClick={() => setStep(1)}
				>
					Back
				</Button>
				<Button
					hidden={step !== 2}
					type="primary"
					htmlType="submit"
					loading={buttonLoading}
					className="contactForm__button"
				>
					{submitLabel}
				</Button>
			</Row>
		</Form>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	currentUser: state.session.currentUser,
	onSuccess: ownProps.onSuccess,

	locations: state.locations,
	supplierFacilities: state.supplier_facilities,
	companySettings: state.company_config.detail,
	companySettingsFetching: state.company_config.fetching,
	problemTypes: state.problem_types,
});

const mapDispatchToProps = (dispatch) => ({
	getCompanySettings: (contact) => dispatch(companyConfigRestCrudThunks(ROLE_TYPES.BUYER, contact)),
	updateCompanySettings: (settings) =>
		dispatch(companyConfigRestCrudThunksForBuyer.update(settings)),
	fetchMultipleProblemTypes: (ids, targetCollectionName) =>
		dispatch(problemTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchProblemTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			problemTypesHeirarchicalForBuyer(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchSupplierFacilitiesInPrivateNetwork: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters
	) =>
		dispatch(
			fetchSupplierFacilitiesInPrivateNetworkForBuyer(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters
			)
		),
	fetchMultipleSupplierFacilitiesInPrivateNetwork: (ids, targetCollectionName) =>
		dispatch(supplierFacilitiesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	createBudgetForm: (budget) => dispatch(budgetsRestCrudThunksForBuyer.create(budget)),
	updateBudgetForm: (budget) => dispatch(budgetsRestCrudThunksForBuyer.update(budget)),
	fetchAllLocations: (params) => dispatch(fetchAllLocationsForBuyer(params)),
});

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(BudgetForm));

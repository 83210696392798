import React, { FC, useState } from 'react';
import { isTextElementEmpty, nullSafeGet } from '../../utils/DataAccessUtils';
import { Button, Card, Divider, Drawer } from 'antd';
import { AddressDisplay } from '../address_display/AddressDisplay';
import Mapbox from '../mapbox/Mapbox';
import LocationPageDisplay from '../location_page_display/LocationPageDisplay';
import { CloseOutlined } from '@ant-design/icons';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';

const LocationCardDisplay: FC<any> = ({ location, mapHeight = 240, mapWidth = '100%' }) => {
	const [locationDrawerVisible, setLocationDrawerVisible] = useState(false);

	const _openLocationDrawer = () => setLocationDrawerVisible(true);
	const _closeLocationDrawer = () => setLocationDrawerVisible(false);

	return (
		<div>
			<Drawer
				width={1000}
				placement="right"
				closable={false}
				onClose={_closeLocationDrawer}
				visible={locationDrawerVisible}
				bodyStyle={{ paddingBottom: 0 }}
			>
				<div
					//Header
					className={'absolute top-0 right-0 z-40 flex flex-row'}
				>
					<Button onClick={_closeLocationDrawer} type={'text'} style={{ padding: '8px' }}>
						<CloseOutlined translate="" style={{ fontSize: '24px', color: 'white' }} />
					</Button>
				</div>
				<LocationPageDisplay record={location} locationFetching={false} />
			</Drawer>
			<Card
				className="materialCard"
				bodyStyle={{ padding: 0 }}
				hoverable={true}
				onClick={_openLocationDrawer}
			>
				<div
					style={{
						height: mapHeight,
						width: mapWidth,
						position: 'absolute',
						top: 0,
						left: 0,
						right: 0,
					}}
				>
					<Mapbox
						longitude={nullSafeGet('buyerFacility.longitude', location)}
						latitude={nullSafeGet('buyerFacility.latitude', location)}
						baseLayerStyle="light"
						zoomLevel={7}
					/>
				</div>
				<div style={{ height: 240, position: 'relative' }} />
				<div className="materialCard__body">
					<div className="materialCard__primaryTitle">
						{nullSafeGet('brand.logoURL', location) ? (
							<img
								src={nullSafeGet('brand.logoURL', location)}
								alt=""
								className="h-auto w-20 object-contain"
							/>
						) : null}
						<div className="materialCard__title">{nullSafeGet('name', location)}</div>
						<div className="materialCard__subtitle">
							{nullSafeGet('locationType.name', location)} #{nullSafeGet('storeId', location)}
						</div>
					</div>
					<AddressDisplay
						hidePostcode={true}
						hideCountry={true}
						className="materialCard__supportingText"
						address={nullSafeGet('buyerFacility.primaryAddress', location)}
					/>
				</div>
				{!isTextElementEmpty(location.internalNotes) ? (
					<AccessPermissionChecker name={PERMISSION_NAMES.SHOW_INTERNAL_TECH_NOTES}>
						<div style={{ padding: 24, paddingTop: 0 }}>
							<Divider style={{ marginTop: 0 }} />
							<div
								className="richTextWrapper"
								dangerouslySetInnerHTML={{ __html: location.internalNotes }}
							/>
						</div>
					</AccessPermissionChecker>
				) : null}
				{!isTextElementEmpty(location.notes) ? (
					<div style={{ padding: 24, paddingTop: 0 }}>
						<Divider style={{ marginTop: 0 }} />
						<div className="richTextWrapper" dangerouslySetInnerHTML={{ __html: location.notes }} />
					</div>
				) : null}
			</Card>
		</div>
	);
};

export default LocationCardDisplay;

import {
	STOPLIGHT_COLORS,
	customCurrencyFormatter,
	symbolCurrencyFormatter,
} from './DataFormatterUtils';

export const GLOBAL_DEBOUNCE_TIME = 500;

export const FETCH_DATA_PREFIX_FOR_TARGET_COLLECTION = 'FETCH_DATA_FROM_SERVER_';

export const countries = [
	{ name: 'Afghanistan', code: 'AF' },
	{ name: 'Åland Islands', code: 'AX' },
	{ name: 'Albania', code: 'AL' },
	{ name: 'Algeria', code: 'DZ' },
	{ name: 'American Samoa', code: 'AS' },
	{ name: 'Andorra', code: 'AD' },
	{ name: 'Angola', code: 'AO' },
	{ name: 'Anguilla', code: 'AI' },
	{ name: 'Antarctica', code: 'AQ' },
	{ name: 'Antigua and Barbuda', code: 'AG' },
	{ name: 'Argentina', code: 'AR' },
	{ name: 'Armenia', code: 'AM' },
	{ name: 'Aruba', code: 'AW' },
	{ name: 'Australia', code: 'AU' },
	{ name: 'Austria', code: 'AT' },
	{ name: 'Azerbaijan', code: 'AZ' },
	{ name: 'Bahamas', code: 'BS' },
	{ name: 'Bahrain', code: 'BH' },
	{ name: 'Bangladesh', code: 'BD' },
	{ name: 'Barbados', code: 'BB' },
	{ name: 'Belarus', code: 'BY' },
	{ name: 'Belgium', code: 'BE' },
	{ name: 'Belize', code: 'BZ' },
	{ name: 'Benin', code: 'BJ' },
	{ name: 'Bermuda', code: 'BM' },
	{ name: 'Bhutan', code: 'BT' },
	{ name: 'Bolivia', code: 'BO' },
	{ name: 'Bosnia and Herzegovina', code: 'BA' },
	{ name: 'Botswana', code: 'BW' },
	{ name: 'Bouvet Island', code: 'BV' },
	{ name: 'Brazil', code: 'BR' },
	{ name: 'British Indian Ocean Territory', code: 'IO' },
	{ name: 'Brunei Darussalam', code: 'BN' },
	{ name: 'Bulgaria', code: 'BG' },
	{ name: 'Burkina Faso', code: 'BF' },
	{ name: 'Burundi', code: 'BI' },
	{ name: 'Cambodia', code: 'KH' },
	{ name: 'Cameroon', code: 'CM' },
	{ name: 'Canada', code: 'CA' },
	{ name: 'Cape Verde', code: 'CV' },
	{ name: 'Cayman Islands', code: 'KY' },
	{ name: 'Central African Republic', code: 'CF' },
	{ name: 'Chad', code: 'TD' },
	{ name: 'Chile', code: 'CL' },
	{ name: 'China', code: 'CN' },
	{ name: 'Christmas Island', code: 'CX' },
	{ name: 'Cocos (Keeling) Islands', code: 'CC' },
	{ name: 'Colombia', code: 'CO' },
	{ name: 'Comoros', code: 'KM' },
	{ name: 'Congo', code: 'CG' },
	{ name: 'Congo, Democratic Republic', code: 'CD' },
	{ name: 'Cook Islands', code: 'CK' },
	{ name: 'Costa Rica', code: 'CR' },
	{ name: "Cote d'Ivoire", code: 'CI' },
	{ name: 'Croatia', code: 'HR' },
	{ name: 'Cuba', code: 'CU' },
	{ name: 'Cyprus', code: 'CY' },
	{ name: 'Czech Republic', code: 'CZ' },
	{ name: 'Denmark', code: 'DK' },
	{ name: 'Djibouti', code: 'DJ' },
	{ name: 'Dominica', code: 'DM' },
	{ name: 'Dominican Republic', code: 'DO' },
	{ name: 'Ecuador', code: 'EC' },
	{ name: 'Egypt', code: 'EG' },
	{ name: 'El Salvador', code: 'SV' },
	{ name: 'Equatorial Guinea', code: 'GQ' },
	{ name: 'Eritrea', code: 'ER' },
	{ name: 'Estonia', code: 'EE' },
	{ name: 'Ethiopia', code: 'ET' },
	{ name: 'Falkland Islands (Malvinas)', code: 'FK' },
	{ name: 'Faroe Islands', code: 'FO' },
	{ name: 'Fiji', code: 'FJ' },
	{ name: 'Finland', code: 'FI' },
	{ name: 'France', code: 'FR' },
	{ name: 'French Guiana', code: 'GF' },
	{ name: 'French Polynesia', code: 'PF' },
	{ name: 'French Southern Territories', code: 'TF' },
	{ name: 'Gabon', code: 'GA' },
	{ name: 'Gambia', code: 'GM' },
	{ name: 'Georgia', code: 'GE' },
	{ name: 'Germany', code: 'DE' },
	{ name: 'Ghana', code: 'GH' },
	{ name: 'Gibraltar', code: 'GI' },
	{ name: 'Greece', code: 'GR' },
	{ name: 'Greenland', code: 'GL' },
	{ name: 'Grenada', code: 'GD' },
	{ name: 'Guadeloupe', code: 'GP' },
	{ name: 'Guam', code: 'GU' },
	{ name: 'Guatemala', code: 'GT' },
	{ name: 'Guernsey', code: 'GG' },
	{ name: 'Guinea', code: 'GN' },
	{ name: 'Guinea-Bissau', code: 'GW' },
	{ name: 'Guyana', code: 'GY' },
	{ name: 'Haiti', code: 'HT' },
	{ name: 'Heard Island and Mcdonald Islands', code: 'HM' },
	{ name: 'Holy See (Vatican City State)', code: 'VA' },
	{ name: 'Honduras', code: 'HN' },
	{ name: 'Hong Kong', code: 'HK' },
	{ name: 'Hungary', code: 'HU' },
	{ name: 'Iceland', code: 'IS' },
	{ name: 'India', code: 'IN' },
	{ name: 'Indonesia', code: 'ID' },
	{ name: 'Iran', code: 'IR' },
	{ name: 'Iraq', code: 'IQ' },
	{ name: 'Ireland', code: 'IE' },
	{ name: 'Isle of Man', code: 'IM' },
	{ name: 'Israel', code: 'IL' },
	{ name: 'Italy', code: 'IT' },
	{ name: 'Jamaica', code: 'JM' },
	{ name: 'Japan', code: 'JP' },
	{ name: 'Jersey', code: 'JE' },
	{ name: 'Jordan', code: 'JO' },
	{ name: 'Kazakhstan', code: 'KZ' },
	{ name: 'Kenya', code: 'KE' },
	{ name: 'Kiribati', code: 'KI' },
	{ name: 'North Korea', code: 'KP' },
	{ name: 'South Korea', code: 'KR' },
	{ name: 'Kuwait', code: 'KW' },
	{ name: 'Kyrgyzstan', code: 'KG' },
	{ name: 'Laos', code: 'LA' },
	{ name: 'Latvia', code: 'LV' },
	{ name: 'Lebanon', code: 'LB' },
	{ name: 'Lesotho', code: 'LS' },
	{ name: 'Liberia', code: 'LR' },
	{ name: 'Libyan Arab Jamahiriya', code: 'LY' },
	{ name: 'Liechtenstein', code: 'LI' },
	{ name: 'Lithuania', code: 'LT' },
	{ name: 'Luxembourg', code: 'LU' },
	{ name: 'Macao', code: 'MO' },
	{ name: 'Macedonia', code: 'MK' },
	{ name: 'Madagascar', code: 'MG' },
	{ name: 'Malawi', code: 'MW' },
	{ name: 'Malaysia', code: 'MY' },
	{ name: 'Maldives', code: 'MV' },
	{ name: 'Mali', code: 'ML' },
	{ name: 'Malta', code: 'MT' },
	{ name: 'Marshall Islands', code: 'MH' },
	{ name: 'Martinique', code: 'MQ' },
	{ name: 'Mauritania', code: 'MR' },
	{ name: 'Mauritius', code: 'MU' },
	{ name: 'Mayotte', code: 'YT' },
	{ name: 'Mexico', code: 'MX' },
	{ name: 'Micronesia', code: 'FM' },
	{ name: 'Moldova, Republic of', code: 'MD' },
	{ name: 'Monaco', code: 'MC' },
	{ name: 'Mongolia', code: 'MN' },
	{ name: 'Montserrat', code: 'MS' },
	{ name: 'Morocco', code: 'MA' },
	{ name: 'Mozambique', code: 'MZ' },
	{ name: 'Myanmar', code: 'MM' },
	{ name: 'Namibia', code: 'NA' },
	{ name: 'Nauru', code: 'NR' },
	{ name: 'Nepal', code: 'NP' },
	{ name: 'Netherlands', code: 'NL' },
	{ name: 'Netherlands Antilles', code: 'AN' },
	{ name: 'New Caledonia', code: 'NC' },
	{ name: 'New Zealand', code: 'NZ' },
	{ name: 'Nicaragua', code: 'NI' },
	{ name: 'Niger', code: 'NE' },
	{ name: 'Nigeria', code: 'NG' },
	{ name: 'Niue', code: 'NU' },
	{ name: 'Norfolk Island', code: 'NF' },
	{ name: 'Northern Mariana Islands', code: 'MP' },
	{ name: 'Norway', code: 'NO' },
	{ name: 'Oman', code: 'OM' },
	{ name: 'Pakistan', code: 'PK' },
	{ name: 'Palau', code: 'PW' },
	{ name: 'Palestinian Territory', code: 'PS' },
	{ name: 'Panama', code: 'PA' },
	{ name: 'Papua New Guinea', code: 'PG' },
	{ name: 'Paraguay', code: 'PY' },
	{ name: 'Peru', code: 'PE' },
	{ name: 'Philippines', code: 'PH' },
	{ name: 'Pitcairn', code: 'PN' },
	{ name: 'Poland', code: 'PL' },
	{ name: 'Portugal', code: 'PT' },
	{ name: 'Puerto Rico', code: 'PR' },
	{ name: 'Qatar', code: 'QA' },
	{ name: 'Reunion', code: 'RE' },
	{ name: 'Romania', code: 'RO' },
	{ name: 'Russian Federation', code: 'RU' },
	{ name: 'RWANDA', code: 'RW' },
	{ name: 'Saint Helena', code: 'SH' },
	{ name: 'Saint Kitts and Nevis', code: 'KN' },
	{ name: 'Saint Lucia', code: 'LC' },
	{ name: 'Saint Pierre and Miquelon', code: 'PM' },
	{ name: 'Saint Vincent and the Grenadines', code: 'VC' },
	{ name: 'Samoa', code: 'WS' },
	{ name: 'San Marino', code: 'SM' },
	{ name: 'Sao Tome and Principe', code: 'ST' },
	{ name: 'Saudi Arabia', code: 'SA' },
	{ name: 'Senegal', code: 'SN' },
	{ name: 'Serbia and Montenegro', code: 'CS' },
	{ name: 'Seychelles', code: 'SC' },
	{ name: 'Sierra Leone', code: 'SL' },
	{ name: 'Singapore', code: 'SG' },
	{ name: 'Slovakia', code: 'SK' },
	{ name: 'Slovenia', code: 'SI' },
	{ name: 'Solomon Islands', code: 'SB' },
	{ name: 'Somalia', code: 'SO' },
	{ name: 'South Africa', code: 'ZA' },
	{ name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
	{ name: 'Spain', code: 'ES' },
	{ name: 'Sri Lanka', code: 'LK' },
	{ name: 'Sudan', code: 'SD' },
	{ name: 'Suriname', code: 'SR' },
	{ name: 'Svalbard and Jan Mayen', code: 'SJ' },
	{ name: 'Swaziland', code: 'SZ' },
	{ name: 'Sweden', code: 'SE' },
	{ name: 'Switzerland', code: 'CH' },
	{ name: 'Syrian Arab Republic', code: 'SY' },
	{ name: 'Taiwan', code: 'TW' },
	{ name: 'Tajikistan', code: 'TJ' },
	{ name: 'Tanzania', code: 'TZ' },
	{ name: 'Thailand', code: 'TH' },
	{ name: 'Timor-Leste', code: 'TL' },
	{ name: 'Togo', code: 'TG' },
	{ name: 'Tokelau', code: 'TK' },
	{ name: 'Tonga', code: 'TO' },
	{ name: 'Trinidad and Tobago', code: 'TT' },
	{ name: 'Tunisia', code: 'TN' },
	{ name: 'Turkey', code: 'TR' },
	{ name: 'Turkmenistan', code: 'TM' },
	{ name: 'Turks and Caicos Islands', code: 'TC' },
	{ name: 'Tuvalu', code: 'TV' },
	{ name: 'Uganda', code: 'UG' },
	{ name: 'Ukraine', code: 'UA' },
	{ name: 'United Arab Emirates', code: 'AE' },
	{ name: 'United Kingdom', code: 'GB' },
	{ name: 'United States', code: 'US' },
	{ name: 'United States Minor Outlying Islands', code: 'UM' },
	{ name: 'Uruguay', code: 'UY' },
	{ name: 'Uzbekistan', code: 'UZ' },
	{ name: 'Vanuatu', code: 'VU' },
	{ name: 'Venezuela', code: 'VE' },
	{ name: 'Vietnam', code: 'VN' },
	{ name: 'Virgin Islands, British', code: 'VG' },
	{ name: 'Virgin Islands, U.S.', code: 'VI' },
	{ name: 'Wallis and Futuna', code: 'WF' },
	{ name: 'Western Sahara', code: 'EH' },
	{ name: 'Yemen', code: 'YE' },
	{ name: 'Zambia', code: 'ZM' },
	{ name: 'Zimbabwe', code: 'ZW' },
];

export const emptorGradients = {
	blue: ['rgb(44, 121, 207)', 'rgb(16, 87, 118)'],
	red: ['rgb(208, 78, 90)', 'rgb(171, 52, 90)'],
	purple: ['rgb(128, 96, 233)', 'rgb(81, 74, 188)'],
	yellow: ['rgb(238, 165, 38)', 'rgb(196, 118, 39)'],
};

export const ROLE_TYPES = {
	BUYER: 'buyer',
	SUPPLIER: 'supplier',
	PUBLIC: 'public',
};

export const multiValueFilterFieldNames = [
	'locationIds',
	'woPriorityIds',
	'problemTypeIds',
	'supplierFacilityIds',
	'displayStatuses',
	'statuses',
	'spendCategoryIds',
	'assetIds',
	'assetTypeIds',
	'plannedMaintenanceTemplateIds',
	'partEquipmentVendorIds',
	'stockLocationIds',
	'equipmentTypeIds',
	'partIds',
	'createdByEmails',
	'approvedByEmails',
	'depreciationClassIds',
	'defaultVendorIds',
	'shipToLocationIds',
	'brandIds',
	'resolutionTypeIds',
	'regionIds',
	'locationTypeIds',
];

export const VIEW_ENTITY_TYPES = {
	WORKORDERS: 'work_orders',
	INVOICES: 'invoices',
	QUOTES: 'quotes',
	ASSETS: 'assets',
};

export const PAGINATION_PROPS = ['pageSize', 'current'];

export const LIMIT_ONE_PAGINATION = { pageSize: 1, current: 1 };

export const SORTING_PROPS = ['sort_by', 'order'];

export const TAB_PROPS = ['tab', 'pathName'];

export const FILTER_CONFIG_NAMES = {
	LOCATION: 'locationIds',
	PRIORITY: 'woPriorityIds',
	ASSET_TYPE: 'assetTypeIds',
	PROBLEM_TYPE: 'problemTypeIds',
	SUPPLIER: 'supplierFacilityIds',
	TECHNICIAN: 'fieldTechnicianEmail',
	ASSET: 'assetIds',
	PM_TEMPLATE: 'plannedMaintenanceTemplateIds',
	VENDOR: 'partEquipmentVendorIds',
	STOCK_LOCATION: 'stockLocationIds',
	EQUIPMENT: 'equipmentIds',
	PART: 'partIds',
	EQUIPMENT_TYPE: 'equipmentTypeIds',
	CREATED_BY: 'createdByEmails',
	APPROVED_BY: 'approvedByEmails',
	DEPRECIATION_CLASS: 'depreciationClassIds',
	SHIP_TO_LOCATION: 'shipToLocationIds',
	BRAND: 'brandIds',
	TRADE: 'spendCategoryIds',
	RESOULTION_TYPE: 'resolutionTypeIds',
	REGION: 'regionIds',
	LOCAATION_TYPE: 'locationTypeIds',

	IS_CAPEX: 'isCapex',
	IS_STALE: 'isStale',
	IS_PLANNED_MAINTENANCE: 'isPM',
	CREATED_AT: 'createdAt',
	COMPLETED_AT: 'completedAt',
	INSTALL_AT: 'installAt',
	UPLOADED_AT: 'uploadedAt',
	DUE_AT: 'dueAt',
	PUBLISHED_AT: 'publishedAt',
	APPROVED_AT: 'approvedAt',
	MARKED_PAID_AT: 'markedPaidAt',
	IS_WARRANTY: 'isWarranty',
	IS_ASSET_DOWN: 'isAssetDown',
	LAST_WALKTHROUGH_COMPLETED_AT: 'lastWalkthroughCompletedAt',
	UPDATED_AT: 'updatedAt',
	SHIPPED_AT: 'markShippedAt',
	DISBURSED_AT: 'disbursedAt',
	ADDITIONAL_FIELD: 'additionalField',
	IS_PAST_DUE: 'pastDue',
	IS_PAST_SCHEDULE: 'pastSchedule',
	IS_PAST_ESTIMATED_COMPLETION: 'pastEstimatedCompletion',
	PM_SCHEDULE: 'plannedMaintenanceScheduleId',
};

export const DEFAULT_SORTER = {
	sort_by: 'createdAt',
	order: 'desc',
};

export const DEFAULT_NAME_SORTER = {
	sort_by: 'name',
	order: 'ascend',
};

export const RECENTLY_UPDATED_SORTER = {
	sort_by: 'updatedAt',
	order: 'desc',
};

export const HOME_PAGE_URLS = ['dashboards/overview/all', 'workOrders/overview/active'];

export const WARNING_BUTTON_WAIT_TIME = 5;

export const DATE_PARAM_NAMES = [
	'workCreatedAtStartDate',
	'workCreatedAtEndDate',
	'workCompletedAtStartDate',
	'workCompletedAtEndDate',
];

export enum FILTER_FIELD_TYPE {
	RADIO_GROUP = 'radioGroup',
	DATE_RANGE = 'dateRange',
	TUPLE = 'tuple',
	SEARCH = 'search',
	OW_MULTI_SELECT = 'owMutilSelect',
}

export enum FILTER_VALUE_TYPE {
	ID = 'id',
	STRING = 'string',
}

export const FLAT_FILE_LICENSE_KEY = '9cfd8b67-b664-4b19-84cf-b5fb00d8fb5c';

export const CSV_EXPORT_DEFAULTS = {
	fieldSeparator: ',',
	quoteStrings: '"',
	decimalSeparator: '.',
	showLabels: true,
	showTitle: false,
	useTextFile: false,
	useBom: true,
};

export const CURRENCIES = {
	CAD: {
		id: 'CAD',
		symbol: 'CA$',
		displayNameSingular: 'Canadian Dollar',
		displayNamePlural: 'Canadian Dollars',
		format: (amount, digits = 2) => customCurrencyFormatter(digits, 'CAD', '$')(amount),
		symbolFormat: (amount, digits = 2) => symbolCurrencyFormatter(digits, 'CAD', '$')(amount),
	},
	CNY: {
		id: 'CNY',
		symbol: '¥',
		displayNamePlural: 'yuan',
		displayNameSingular: 'yuan',
		format: (amount, digits = 2) => customCurrencyFormatter(digits, 'CNY', '¥')(amount),
		symbolFormat: (amount, digits = 2) => symbolCurrencyFormatter(digits, 'CNY', '¥')(amount),
	},
	EUR: {
		id: 'EUR',
		symbol: '€',
		displayNameSingular: 'euro',
		displayNamePlural: 'euros',
		format: (amount, digits = 2) => customCurrencyFormatter(digits, 'EUR', '€')(amount),
		symbolFormat: (amount, digits = 2) => symbolCurrencyFormatter(digits, 'EUR', '€')(amount),
	},
	MXN: {
		id: 'MXN',
		symbol: 'MEX$',
		displayNameSingular: 'peso',
		displayNamePlural: 'pesos',
		format: (amount, digits = 2) => customCurrencyFormatter(digits, 'MEX', '$')(amount),
		symbolFormat: (amount, digits = 2) => symbolCurrencyFormatter(digits, 'MEX', '$')(amount),
	},
	USD: {
		id: 'USD',
		symbol: '$',
		displayNameSingular: 'US Dollar',
		displayNamePlural: 'US Dollars',
		format: (amount, digits = 2) => customCurrencyFormatter(digits, 'USD', '$')(amount),
		symbolFormat: (amount, digits = 2) => symbolCurrencyFormatter(digits, 'USD', '$')(amount),
	},
	JPY: {
		id: 'JPY',
		symbol: '¥',
		displayNamePlural: 'yen',
		displayNameSingular: 'yen',
		format: (amount, digits = 2) => customCurrencyFormatter(digits, 'JPY', '¥')(amount),
		symbolFormat: (amount, digits = 2) => symbolCurrencyFormatter(digits, 'JPY', '¥')(amount),
	},
	NZD: {
		id: 'NZD',
		symbol: '$',
		displayNameSingular: 'New Zealand Dollar',
		displayNamePlural: 'New Zealand Dollars',
		format: (amount, digits = 2) => customCurrencyFormatter(digits, 'NZD', '$')(amount),
		symbolFormat: (amount, digits = 2) => symbolCurrencyFormatter(digits, 'NZD', '$')(amount),
	},
	AUD: {
		id: 'AUD',
		symbol: '$',
		displayNameSingular: 'Australian Dollar',
		displayNamePlural: 'Australian Dollars',
		format: (amount, digits = 2) => customCurrencyFormatter(digits, 'AUD', '$')(amount),
		symbolFormat: (amount, digits = 2) => symbolCurrencyFormatter(digits, 'AUD', '$')(amount),
	},
};

export const WO_STEP_NAMES = {
	USER_LOCATION: 'userLocation',
	LOCATIONS: 'locations',
	AREA_TOP_LEVEL: 'areasTopLevel',
	AREA_NEXT_LEVEL: 'areasNextLevel',
	AREA_NEXT_BRANCH: 'areasNextBranch',
	PROBLEM_TYPE_TOP_LEVEL: 'problemTypeTopLevel',
	PROBLEM_TYPE_NEXT_LEVEL: 'problemTypeNextLevel',
	PROBLEM_TYPE_NEXT_BRANCH: 'problemTypeNextBranch',
	ASSET: 'asset',
	ASSET_CONDITION: 'assetCondition',
	TITLE: 'title',
	PHOTOS: 'photos',
	PRIORITY: 'priority',
	SUPPLIER: 'supplier',
	NTE: 'nte',
	COMPLETION: 'completion',
	DUPLICATE_CHECK: 'duplicateCheck',
	SUBMITTED_BY: 'submittedBy',
};

export const JOURNAL_STATUSES = {
	STARTED: 'started',
	COMPLETED: 'completed',
	ROLLBACK_STARTED: 'rollback_started',
	ROLLBACK_COMPLETED: 'rollback_completed',
	RERUN_STARTED: 'rerun_started',
	RERUN_COMPLETED: 'rerun_completed',
};

export const JOURNAL_DISPLAY_PROPS = {
	[JOURNAL_STATUSES.STARTED]: {
		displayName: 'Started',
		icon: 'icons8-wrench',
		iconStyle: { color: STOPLIGHT_COLORS.yellowGreen },
	},
	[JOURNAL_STATUSES.COMPLETED]: {
		displayName: 'Completed',
		icon: 'icons8-green-check-mark',
		iconStyle: { color: STOPLIGHT_COLORS.pureGreen },
	},
	[JOURNAL_STATUSES.ROLLBACK_STARTED]: {
		displayName: 'Rollback In Progress',
		icon: 'icons8-wrench',
		iconStyle: { color: STOPLIGHT_COLORS.yellowGreen },
	},
	[JOURNAL_STATUSES.ROLLBACK_COMPLETED]: {
		displayName: 'Rolled Back',
		icon: 'icons8-pause-squared',
		iconStyle: { color: STOPLIGHT_COLORS.pureYellow },
	},
	[JOURNAL_STATUSES.RERUN_STARTED]: {
		displayName: 'Rerun Started',
		icon: 'icons8-wrench',
		iconStyle: { color: STOPLIGHT_COLORS.yellowGreen },
	},
	[JOURNAL_STATUSES.RERUN_COMPLETED]: {
		displayName: 'Rerun Completed',
		icon: 'icons8-green-check-mark',
		iconStyle: { color: STOPLIGHT_COLORS.pureGreen },
	},
};

export const DEPRECIATION_METHODS = {
	DECLINING: 'declining',
	STRAIGHT_LINE: 'straightLine',
};

export const JOURNAL_RUN_COMPLETED_STATUSES = [
	JOURNAL_STATUSES.COMPLETED,
	JOURNAL_STATUSES.RERUN_COMPLETED,
];

export const JOURNAL_RUN_INPROGRESS_STATUSES = [
	JOURNAL_STATUSES.STARTED,
	JOURNAL_STATUSES.ROLLBACK_STARTED,
	JOURNAL_STATUSES.RERUN_STARTED,
];

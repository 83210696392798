import { CloudSyncOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Divider } from 'antd';
import React, { useEffect, useState } from 'react';

const LoginButton = () => {
	const { loginWithRedirect, isAuthenticated } = useAuth0();
	if (isAuthenticated) return null;
	return (
		<Button onClick={() => loginWithRedirect()} block>
			<CloudSyncOutlined translate="" className="-ml-0.5 h-5 w-5" aria-hidden="true" />
			LOG IN WITH SSO
		</Button>
	);
};

export default LoginButton;

const LogoutButton = () => {
	const { logout, isAuthenticated } = useAuth0();
	if (!isAuthenticated) return null;
	return <button onClick={() => logout()}>Log Out</button>;
};

const Profile = () => {
	const auth0 = useAuth0();
	const {
		user,
		error,
		isAuthenticated,
		isLoading,
		getAccessTokenSilently,
		getAccessTokenWithPopup,
	} = auth0;

	useEffect(() => {
		(async () => {
			try {
				const token = await getAccessTokenSilently({
					authorizationParams: {
						// audience: 'https://api.example.com/', // Value in Identifier field for the API being called.
						// scope: 'read:posts', // Scope that exists for the API being called. You can create these through the Auth0 Management API or through the Auth0 Dashboard in the Permissions view of your API.
					},
				});
				const claims = await auth0.getIdTokenClaims();
				console.log('claims', claims);

				setToken(token);
			} catch (e) {
				console.error(e);
			}
		})();
	}, [getAccessTokenSilently]);

	const [token, setToken] = useState('');

	if (isLoading && !error) {
		return <div>Loading ...</div>;
	}

	return (
		isAuthenticated && (
			<div>
				<img src={user.picture} alt={user.name} />
				<h2>{user.name}</h2>
				<p>{user.email}</p>
				<p>{user.upn}</p>
				<Divider />
				<p>{token}</p>
			</div>
		)
	);
};

export { LogoutButton, Profile };

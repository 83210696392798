import * as React from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { getCurrency, nullSafeGet } from '../../utils/DataAccessUtils';
import { INVOICE_STATUS_DISPLAY_NAMES } from '../../constants/InvoiceStatuses';
import InvoiceDisplayStatusDisplay from '../invoice_display_status_display/InvoiceDisplayStatusDisplay';
import DateTimeHover from '../date_time_component/DateTime';
import { getContactName } from '../../utils/DataFormatterUtils';

const style = require('./InvoiceRowDisplay.less');

export const InvoiceRowDisplay: React.FC<any> = ({ invoice, ...props }) => (
	<div className="invoiceRowDisplay" {...props}>
		<div className="rowColumn">
			<div className="rowPrimary" style={{ color: 'rgba(0,0,0,0.65)' }}>
				<Ellipsis
					tooltip={true}
					fullWidthRecognition={true}
					lines={1}
					style={{ paddingBottom: '1px' }}
				>
					{nullSafeGet('workOrder.title', invoice)}
				</Ellipsis>
			</div>
			<div className="rowSecondary">
				<div className="rowInlineGroup" style={{ display: 'inline-flex' }}>
					<span className="rowGrayChip">
						<i className="icons8-font icons8-location-marker" />
						<span>{nullSafeGet('workOrder.location.name', invoice)}</span>
					</span>
				</div>
				<div className="rowInlineGroup" style={{ display: 'inline-flex' }}>
					<InvoiceDisplayStatusDisplay status={INVOICE_STATUS_DISPLAY_NAMES[invoice.status]} />
				</div>
				{nullSafeGet('workOrder.isCapex', invoice) ? (
					<div className="rowInlineGroup" style={{ display: 'inline-flex' }}>
						<span className={'rowGrayChip'} style={{ padding: '4px 12px', borderRadius: 16 }}>
							<span>CapEx</span>
						</span>
					</div>
				) : null}
				<div className="rowInlineGroup" style={{ display: 'inline-flex' }}>
					<i className="icons8-font icons8-maintenance-filled" />
					<span>{nullSafeGet('workOrder.problemType.hierarchyName', invoice)}</span>
				</div>
				{nullSafeGet('workOrder.supplierFacility.displayName', invoice) ? (
					<div className="rowInlineGroup" style={{ display: 'inline-flex' }}>
						<i className="icons8-font icons8-company" />
						{nullSafeGet('workOrder.supplierFacility.displayName', invoice)}
					</div>
				) : null}
				<div className="rowInlineGroup">{'WO #'.concat(nullSafeGet('workOrderId', invoice))}</div>
				{nullSafeGet('workOrder.buyerProjects.length', invoice) === 1 ? (
					<div className="rowInlineGroup" style={{ display: 'inline-flex' }}>
						<span>
							<i className="icons8-font icons8-blueprint" />
							{nullSafeGet('workOrder.buyerProjects', invoice)[0].title}
						</span>
					</div>
				) : nullSafeGet('workOrder.buyerProjects.length', invoice) > 1 ? (
					<div className="rowInlineGroup" style={{ display: 'inline-flex' }}>
						<span>
							<i className="icons8-font icons8-blueprint" />
							{nullSafeGet('workOrder.buyerProjects.length', invoice)} Projects
						</span>
					</div>
				) : null}
			</div>
			<div className="rowTertiary rowBlackPrimary mt-2">
				<span style={{ whiteSpace: 'nowrap', color: 'rgba(0,0,0,0.45)', fontSize: 14 }}>
					<>
						Submitted{' '}
						<DateTimeHover timestamp={nullSafeGet('createdAt', invoice)} showTime={false} />{' '}
						{invoice.createdByContact ? `by ${getContactName(invoice.createdByContact)}` : ''}
					</>
				</span>
			</div>
		</div>
		<div className="invoiceRowDisplay__rightSide">
			<div className="invoiceRowDisplay__rowInlineGroup">
				{getCurrency({ workOrder: nullSafeGet('workOrder', invoice) }).format(
					invoice.invoiceTotalAfterTax
				)}
			</div>
		</div>
	</div>
);

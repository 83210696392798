import React, { FC, useCallback, useMemo, useState } from 'react';
import { getCurrency, nullSafeGet } from '../../utils/DataAccessUtils';
import { Button, Form, Modal, Select, message } from 'antd';
import { EquipmentRowDisplay } from '../equipment_row_display/EquipmentRowDisplay';
import { connect } from 'react-redux';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { replaceLineItemInPO } from '../../thunks/purchase_order_line_items_thunks';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { equipmentTypesRestCrudThunksForSupplier } from '../../thunks/equipment_types_thunks';
import { equipmentCatalogsRestCrudThunksForSupplier } from '../../thunks/equipment_catalogs_thunks';
import { replaceLineItemInPR } from '../../thunks/purchase_request_line_items_thunks';

const FORM_ID = 'replace_line_item_form';
const EQUIPMENTS_TC_NAME = 'equipmentTypesAtStockLocationIndex';

export const renderEquipmenTypeSelect = (et, equipmentTypeId?) => (
	<Select.Option key={et.id} value={et.id} disabled={et.id === equipmentTypeId}>
		<div className="space-between flex flex-row items-center">
			<div>{et.modelName}</div>
			<div>
				{et.equipmentTypeNumber && (
					<div className="text-gray-400">&nbsp;{` - #${et.equipmentTypeNumber}`}</div>
				)}
			</div>
		</div>
	</Select.Option>
);

const ReplaceEquipmentLineItemModal: FC<any> = ({
	lineItem,
	vendorId,
	onCancel,
	onSuccess,
	currentUser,
	equipmentTypes,
	fetchEquipmentTypes,
	fetchMultipleEquipmentTypes,
	fetchEquipmentsCatalog,
	createEquipmentsPerCatalog,
	isPRLineItem,
	replacePOLineItem,
	replacePRLineItem,
}): React.ReactElement => {
	const [form] = Form.useForm();

	const equipmentTypeId = Form.useWatch('equipmentTypeId', form);
	const userCurrencyId = useMemo(
		() => nullSafeGet('id', getCurrency({ currentUser })),
		[currentUser]
	);

	const [replacingLineItem, setReplacingLineItem] = useState(false);

	const getEquipmentCatalogFromVendorId = useCallback(() => {
		return new Promise((resolve, reject) => {
			if (!vendorId) {
				resolve({});
				return;
			}

			fetchEquipmentsCatalog({
				equipmentTypeId,
				partEquipmentVendorId: vendorId,
			})
				.then((res) => {
					if (res && res.length > 0) {
						resolve(res[0]);
					} else {
						createEquipmentsPerCatalog({
							partEquipmentVendorId: vendorId,
							currencyId: userCurrencyId,
							equipmentTypeId,
						})
							.then((ppc) => resolve(ppc))
							.catch((err) => reject(err));
					}
				})
				.catch((err) => {
					console.log(err);
					createEquipmentsPerCatalog({
						partEquipmentVendorId: vendorId,
						currencyId: userCurrencyId,
						equipmentTypeId,
					})
						.then((ppc) => resolve(ppc))
						.catch((err) => reject(err));
				});
		});
	}, [
		fetchEquipmentsCatalog,
		equipmentTypeId,
		vendorId,
		createEquipmentsPerCatalog,
		userCurrencyId,
	]);

	const equipmentTypeRecord = useMemo(() => {
		const equipmentTypeRecords = getRecordsForTargetCollection(equipmentTypes, EQUIPMENTS_TC_NAME);
		return equipmentTypeRecords.find((_) => _.id === equipmentTypeId);
	}, [equipmentTypeId, equipmentTypes]);

	const handleSubmit = useCallback(() => {
		setReplacingLineItem(true);
		const replaceMethod = isPRLineItem ? replacePRLineItem : replacePOLineItem;
		getEquipmentCatalogFromVendorId()
			.then((equipmentCatalog) => {
				replaceMethod({
					...lineItem,
					equipmentTypeId,
					...(nullSafeGet('id', equipmentCatalog) && {
						equipmentCatalogId: nullSafeGet('id', equipmentCatalog),
					}),
					...(nullSafeGet('equipmentUomId', equipmentTypeRecord) && {
						equipmentUomId: nullSafeGet('equipmentUomId', equipmentTypeRecord),
					}),
					...(nullSafeGet('equipmentCurrencyId', equipmentTypeRecord) && {
						equipmentCurrencyId: nullSafeGet('equipmentCurrencyId', equipmentTypeRecord),
					}),
				})
					.then((res) => onSuccess && onSuccess(res))
					.catch((err) => message.error(err))
					.finally(() => setReplacingLineItem(false));
			})
			.catch((err) => {
				message.error(err);
				setReplacingLineItem(false);
			});
	}, [
		equipmentTypeId,
		equipmentTypeRecord,
		getEquipmentCatalogFromVendorId,
		isPRLineItem,
		lineItem,
		onSuccess,
		replacePOLineItem,
		replacePRLineItem,
	]);

	return (
		<Modal
			visible={true}
			title={`Replace Equipment in Purchase Order`}
			onCancel={onCancel}
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button
					type="primary"
					size="large"
					style={{ marginLeft: '16px' }}
					key="submit"
					htmlType="submit"
					form={FORM_ID}
					loading={replacingLineItem}
				>
					Replace Equipment
				</Button>,
			]}
			closable={true}
		>
			<Form layout="vertical" onFinish={handleSubmit} id={FORM_ID} form={form}>
				<Form.Item label={`Replace Equipment`}>
					<EquipmentRowDisplay equipmentType={nullSafeGet('equipmentType', lineItem)} />
				</Form.Item>
				<Form.Item
					name="equipmentTypeId"
					label="With Equipment"
					rules={[
						{
							required: true,
							message: 'Please select a equipment.',
						},
					]}
				>
					<OWAsyncSelect
						stateSlice={equipmentTypes}
						targetCollectionName={EQUIPMENTS_TC_NAME}
						fetchMultiple={(ids, targetCollectionName) => {
							fetchMultipleEquipmentTypes(ids, targetCollectionName);
						}}
						fetchData={(
							searchText,
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						) => {
							fetchEquipmentTypes(
								{ search: searchText || undefined },
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							);
						}}
						renderRecord={(et) => renderEquipmenTypeSelect(et, lineItem.equipmentTypeId)}
						sortBy={{ sort_by: 'name', order: 'ascend' }}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	equipmentTypes: state.equipment_types,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	fetchEquipmentTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			equipmentTypesRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleEquipmentTypes: (ids, targetCollectionName) =>
		dispatch(equipmentTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchEquipmentsCatalog: (params, targetCollectionName) =>
		dispatch(equipmentCatalogsRestCrudThunksForSupplier.read(params, targetCollectionName)),
	createEquipmentsPerCatalog: (entity, targetCollectionName) =>
		dispatch(equipmentCatalogsRestCrudThunksForSupplier.create(entity, 'id', targetCollectionName)),
	replacePOLineItem: (entity) => dispatch(replaceLineItemInPO(entity)),
	replacePRLineItem: (entity) => dispatch(replaceLineItemInPR(entity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReplaceEquipmentLineItemModal);

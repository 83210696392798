import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { Content } from 'antd/lib/layout/layout';
import ScrollToTopOnMount from '../scroll_to_top_on_mount/ScrollToTopOnMount';
import { Input, message, Tooltip, Card } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { posthog } from 'posthog-js';

const style = require('./BuyerReferralsPage.less');
const dateFormat = 'MM-DD-YYYY';

class BuyerReferralsPage extends React.Component<any, any> {
	shareInputRef: any;

	constructor(props) {
		super(props);
		this.shareInputRef = null;
	}

	copyToClipboard = (e) => {
		this.shareInputRef.input.select();
		document.execCommand('copy');
		e.target.focus();
		message.success('Link copied to clipboard.');
		posthog.capture('copied buyer referral link');
	};

	render() {
		const { currentUser = {} } = this.props;
		return (
			<Content className="buyerProfilePage" style={{ padding: '0 0.5em' }}>
				<ScrollToTopOnMount />
				<Card bodyStyle={{ padding: '2em 4em' }} style={{ margin: '4em auto 2em', maxWidth: 720 }}>
					<h5>Invite friends to OpenWrench and earn $250.*</h5>
					<p style={{ fontSize: 16 }}>
						Enjoy using OpenWrench? Share your referral link with a friend!
					</p>
					<p style={{ fontSize: 16 }}>
						For every person who signs up, we'll send you a $250 Amazon gift card.
					</p>
					<p style={{ fontSize: 16 }}>
						Need inspiration? Check out our referral{' '}
						<a
							href="https://docs.google.com/document/d/1qV0VqGIS7hjClPvJ17yGbtqXTuf97lHtJC80SyJEObU/edit?usp=sharing"
							target="_blank"
						>
							email template
						</a>
					</p>
					<Input
						style={{ width: '100%' }}
						ref={(input) => (this.shareInputRef = input)}
						onClick={this.copyToClipboard}
						addonAfter={
							<Tooltip title="Copy to Clipboard">
								<CopyOutlined onClick={this.copyToClipboard} />
							</Tooltip>
						}
						defaultValue={`https://form.typeform.com/to/sfEwvX?referrer_email=${currentUser.email}`}
						value={`https://form.typeform.com/to/sfEwvX?referrer_email=${currentUser.email}`}
					/>
					<h5 style={{ marginTop: '2em' }}>Redeeming Rewards</h5>

					<p>
						To qualify for the reward, the company you refer must sign up for and attend a demo with
						an OpenWrench account executive. Your reward will be emailed to you within 30 days of
						their demo date.
					</p>

					<p>
						*Disclaimer: The prospect demo must not meet any of the disqualification criteria listed
						below in order to be eligible for the displayed offer.
					</p>

					<h5 style={{ marginTop: '2em' }}>Reward Disqualifications</h5>
					<ul>
						<li>
							Rewards do not apply retroactively or to accounts that are closed before the reward is
							issued.
						</li>
						<li>Rewards cannot be combined with any other promotions, discounts, or offers.</li>
						<li>Admins of multiple accounts may not refer themselves.</li>
						<li>
							OpenWrench reserves the right to decline rewards if it suspects fraudulent behavior.
						</li>
						<li>
							You cannot run PPC ads or content campaigns on OpenWrench products or trademarks.
						</li>
						<li>Referred prospects must not have taken an OpenWrench demo in the past 3 months.</li>
						<li>Referrals must lead to a demo with the prospect.</li>
						<li>Prospect’s company must have at least 20 locations.</li>
					</ul>
				</Card>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({ currentUser: state.session.currentUser });

const mapDispatchToProps = (dispatch) => ({});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(BuyerReferralsPage)
);

import React, { FC, useCallback, useMemo } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getCurrency, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { dateFormatter, DATE_FORMAT } from '../../utils/DataFormatterUtils';
import { Card } from 'antd';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { EmptyState } from '../empty_state/EmptyState';
import { partCatalogChangesRestCrudThunksForSupplier } from '../../thunks/part_catalog_changes_thunks';
import DateTimeHover from '../date_time_component/DateTime';

const PartsDetailPricesPage: FC<any> = ({ part, currentUser }): React.ReactElement => {
	const initialPagination = useMemo(
		() => ({
			current: 1,
			pageSize: 5,
		}),
		[]
	);

	const getContact = useCallback(
		(price) =>
			`${nullSafeGetOrElse('changedByContact.nameGiven', price, '')} ${nullSafeGetOrElse(
				'changedByContact.nameFamily',
				price,
				''
			)}`,
		[]
	);
	const currency = getCurrency({ currentUser });

	const columns = useMemo(
		() => [
			{
				title: 'Vendor',
				dataIndex: ['partEquipmentVendor', 'name'],
			},
			{
				title: 'Unit Cost',
				render: (_, record) => currency.format(nullSafeGet('unitCost', record)),
			},
			{
				title: 'Changed by',
				render: (_, record) => getContact(record),
			},
			{
				title: 'Changed at',
				dataIndex: 'createdAt',
				render: (createdAt) => <DateTimeHover timestamp={createdAt} showDate />,
			},
		],
		[currency, getContact]
	);

	return (
		<Card>
			<PaginatedReduxTable
				mode="list"
				updateQueryParams={true}
				emptyState={
					<EmptyState
						graphic={
							<img
								alt="No data"
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
							/>
						}
						headline={'No receipts found'}
					/>
				}
				collectionName="part_catalog_changes"
				targetCollectionName="partCataloghangeIndex"
				columns={columns}
				showHeader={true}
				keyAccessor={(el) => el.id}
				initialPagination={initialPagination}
				additionalParams={{
					partId: part.id,
				}}
				fetchData={partCatalogChangesRestCrudThunksForSupplier.read}
			/>
		</Card>
	);
};

const mapStateToProps = (state) => ({
	part: state.parts.detail,
	currentUser: state.session.currentUser,
});

export default withRouter<any, any>(connect(mapStateToProps)(PartsDetailPricesPage));

import { Button, Form, InputNumber, Modal, message } from 'antd';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { nullSafeGet, nullSafeGetOrElse, roundOffToDecimal } from '../../utils/DataAccessUtils';
import PartEquipmentCostFormField from '../common/PartEquipmentCostFormField';
import { partCatalogsRestCrudThunksForSupplier } from '../../thunks/part_catalogs_thunks';
import { connect } from 'react-redux';
import { equipmentCatalogsRestCrudThunksForSupplier } from '../../thunks/equipment_catalogs_thunks';
import { updateLineItemQuantityForSupplier } from '../../thunks/purchase_order_line_items_thunks';
import ChoosePRForPOLineItemModal from './ChoosePRForPOLineItemModal';

const FORM_ID = 'edit-cost-form';

const EditPOLineItemModal: FC<any> = ({
	cost,
	quantity,
	title,
	onCancel,
	onSuccess,
	record,
	fetchPartsCatalog,
	fetchEquipmentsCatalog,
	partEquipmentVendorId,
	poCurrencyId,
	updateQuantity,
}): React.ReactElement => {
	const [form] = Form.useForm();

	const [updating, setUpdating] = useState(false);
	const [choosePRModalVisible, setChoosePRModalVisible] = useState(false);
	const [diffQuantity, setDiffQuantity] = useState(0);

	const { getFieldsValue } = form;

	const unitCost = Form.useWatch('unitCost', form);
	const totalQuantity = Form.useWatch('totalQuantity', form);

	const { setFieldsValue } = form;

	const _hidePRModal = () => setChoosePRModalVisible(false);

	useEffect(() => {
		const changedValue = parseInt(totalQuantity);
		!isNaN(changedValue) && setFieldsValue({ totalQuantity: changedValue });
	}, [totalQuantity, setFieldsValue]);

	useEffect(() => {
		setFieldsValue({ totalCost: roundOffToDecimal(unitCost * (totalQuantity || 0)) });
	}, [unitCost, totalQuantity, setFieldsValue]);

	const prLineItems = useMemo(
		() => nullSafeGetOrElse('supplierPurchaseRequestLineItems', record, []),
		[record]
	);

	const updateQuantityAndSubmit = useCallback(
		(affectedPurchaseRequestLineItemId) => {
			_hidePRModal();
			setUpdating(true);

			const values = getFieldsValue();

			updateQuantity(record.id, {
				...values,
				affectedPurchaseRequestLineItemId,
			})
				.then(() => onSuccess(totalQuantity, values.totalCost))
				.catch((err) => message.error(err))
				.finally(() => setUpdating(false));
		},
		[getFieldsValue, onSuccess, record.id, totalQuantity, updateQuantity]
	);

	const onSubmit = useCallback(
		(values) => {
			if (values.totalQuantity !== quantity) {
				setDiffQuantity(values.totalQuantity - quantity);
				if (prLineItems.length > 1) {
					setChoosePRModalVisible(true);
				} else {
					const prLineItemId = nullSafeGet('0.id', prLineItems);
					updateQuantityAndSubmit(prLineItemId);
				}
			} else {
				onSuccess(quantity, values.totalCost);
			}
		},
		[onSuccess, prLineItems, quantity, updateQuantityAndSubmit]
	);

	const initialValues = useMemo(
		() => ({
			unitCost: cost !== undefined ? roundOffToDecimal((cost || 0) / (quantity || 1)) : undefined,
			totalCost: cost,
			totalQuantity: quantity,
		}),
		[cost, quantity]
	);

	const entityId = record.partId || record.equipmentTypeId;

	const entityName = record.partId ? 'partId' : 'equipmentTypeId';

	const vendorId =
		partEquipmentVendorId ||
		nullSafeGet('equipmentCatalog.partEquipmentVendorId', record) ||
		nullSafeGet('partCatalog.partEquipmentVendorId', record);

	const fetchMethod = record.partId ? fetchPartsCatalog : fetchEquipmentsCatalog;

	return (
		<Modal
			visible={true}
			width={600}
			title={title}
			closable
			onCancel={onCancel}
			forceRender
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button
					type="primary"
					size="large"
					key="submit"
					htmlType="submit"
					form={FORM_ID}
					loading={updating}
				>
					{`Update ${record.isEquipmentLine ? 'Equipment' : 'Part'}`}
				</Button>,
			]}
		>
			<Form
				id={FORM_ID}
				form={form}
				layout="vertical"
				requiredMark={false}
				initialValues={initialValues}
				onFinish={onSubmit}
			>
				<Form.Item
					name="totalQuantity"
					label="Total Quantity"
					rules={[
						{
							required: true,
							message: 'Please add valid quantity.',
						},
						{
							type: 'number',
							min: 1,
							message: 'Quantity cannot be less than 1',
						},
					]}
				>
					<InputNumber style={{ width: '100%' }} step={1} disabled={!record.id} />
				</Form.Item>
				<PartEquipmentCostFormField
					entityId={entityId}
					unitCost={unitCost}
					entityName={entityName}
					vendorId={vendorId}
					fetchMethod={fetchMethod}
					setFieldsValue={setFieldsValue}
					form={form}
					currencyId={poCurrencyId}
				/>
				<Form.Item
					name="totalCost"
					label="Total Cost"
					rules={[
						{
							required: true,
							message: 'Please add cost.',
						},
					]}
				>
					<InputNumber disabled style={{ width: '100%' }} addonAfter={poCurrencyId} />
				</Form.Item>
			</Form>
			{choosePRModalVisible ? (
				<ChoosePRForPOLineItemModal
					onCancel={_hidePRModal}
					onSuccess={updateQuantityAndSubmit}
					record={record}
					diffQuantity={diffQuantity}
				/>
			) : null}
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	fetchPartsCatalog: (params, targetCollectionName) =>
		dispatch(partCatalogsRestCrudThunksForSupplier.read(params, targetCollectionName)),
	fetchEquipmentsCatalog: (params, targetCollectionName) =>
		dispatch(equipmentCatalogsRestCrudThunksForSupplier.read(params, targetCollectionName)),
	updateQuantity: (id, params) => dispatch(updateLineItemQuantityForSupplier(id, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPOLineItemModal);

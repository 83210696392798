import React, { FC, useCallback, useState, useEffect, useMemo } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import {
	savedFilterViewsRestCrudThunksForBuyer,
	savedFilterViewsRestCrudThunksForSupplier,
} from '../../thunks/saved_filter_views_thunks';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { multiValueFilterFieldNames, ROLE_TYPES } from '../../utils/DataConstants';
import { BUYER_ADMIN_ONLY, SUPPLIER_ADMIN_ONLY } from '../roles/roles';
import { cleanFiltersForView } from '../../utils/DataAccessUtils';
import OWRadioGroup from '../ow_radio_group/OWRadioGroup';

interface SaveFiltersFormProps {
	filters: any;
	roleType: 'buyer' | 'supplier';
	entityType: String;
	preAppliedFilters: any;
	onCancel: () => void;
	currentUser?: any;
	saveFilterViewForBuyer?: (entity: any) => any;
	saveFilterViewForSupplier?: (entity: any) => any;
}

interface FieldData {
	name: string;
	value?: any;
}

const SaveFiltersPopup: FC<SaveFiltersFormProps> = ({
	filters,
	roleType,
	entityType,
	currentUser,
	preAppliedFilters,
	onCancel,
	saveFilterViewForBuyer,
	saveFilterViewForSupplier,
}): React.ReactElement => {
	const [form] = Form.useForm();

	const [fields, setFields] = useState<FieldData[]>([]);
	const [saving, setSaving] = useState(false);

	const isAdmin = useMemo(() => {
		const roleList = roleType === ROLE_TYPES.SUPPLIER ? SUPPLIER_ADMIN_ONLY : BUYER_ADMIN_ONLY;
		return currentUser.roles.some((role) => roleList.has(role));
	}, [roleType, currentUser]);

	const saveFilterMethod = useMemo(
		() => (roleType === ROLE_TYPES.SUPPLIER ? saveFilterViewForSupplier : saveFilterViewForBuyer),
		[roleType, saveFilterViewForSupplier, saveFilterViewForBuyer]
	);

	const handleSubmit = useCallback(
		(values) => {
			setSaving(true);
			const cleanedFilters = cleanFiltersForView(filters, preAppliedFilters);
			const filterValue = Object.keys(cleanedFilters).reduce((obj, key) => {
				multiValueFilterFieldNames.includes(key)
					? (obj[key] = cleanedFilters[key].split(','))
					: (obj[key] = cleanedFilters[key]);
				return obj;
			}, {});
			const payload = {
				name: values.name,
				entityType,
				isPublic: values.isPublic === 'true',
				filterValue: filterValue,
				filterQuery: new URLSearchParams(cleanedFilters).toString(),
			};
			saveFilterMethod(payload)
				.then(() => onCancel())
				.catch((err) => alert(err));
		},
		[entityType, preAppliedFilters, filters, saveFilterMethod, onCancel]
	);

	useEffect(() => {
		setFields([
			{
				name: 'name',
				value: '',
			},
			{
				name: 'isPublic',
				value: 'false',
			},
		]);
	}, [form]);

	return (
		<Modal visible={true} title="Save this View" onCancel={onCancel} footer={null} closable={true}>
			<Form
				layout="vertical"
				style={{ width: 400 }}
				fields={fields}
				requiredMark={false}
				onFinish={handleSubmit}
			>
				<Form.Item
					name="name"
					label="Name"
					rules={[{ required: true, message: 'Please enter a name for the view!' }]}
				>
					<Input type="text" placeholder="Enter Name of this view" />
				</Form.Item>
				{isAdmin && (
					<Form.Item name="isPublic">
						{/* @ts-ignore */}
						<OWRadioGroup
							items={[
								{
									label: 'Private',
									value: 'false',
								},
								{
									label: 'Public',
									value: 'true',
								},
							]}
							valueAccessor={(item) => item.value}
							renderRecord={(item) => item.label}
							info={[
								{
									title: 'Private',
									description: 'Visible only to you.',
								},
								{
									title: 'Public',
									description: 'Visible to everyone in your team.',
								},
							]}
						/>
					</Form.Item>
				)}
				<Form.Item>
					<Button type="primary" htmlType="submit" loading={saving}>
						Save
					</Button>
					<Button style={{ marginLeft: '24px' }} onClick={onCancel}>
						Cancel
					</Button>
				</Form.Item>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	saveFilterViewForBuyer: (entity) =>
		dispatch(savedFilterViewsRestCrudThunksForBuyer.create(entity)),
	saveFilterViewForSupplier: (entity) =>
		dispatch(savedFilterViewsRestCrudThunksForSupplier.create(entity)),
});

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(SaveFiltersPopup));

import { Radio } from 'antd';
import React from 'react';
import { ROLE_TYPES } from '../../../utils/DataConstants';
import { isMatch, isNil, omitBy, isMatchWith } from 'lodash';

export default function AssignedFilters(props) {
	const { userType, items = [], value, onChange, style = {}, tabStyle = {}, selector } = props;
	if (!items.length || userType !== ROLE_TYPES.SUPPLIER) {
		return null;
	}

	let selectedVal = undefined;
	items.map((tab) => {
		const isMatched = isMatchWith(value, omitBy(tab.value, isNil), (objValue, srcValue) => {
			if (srcValue) {
				return srcValue == objValue;
			}
			return true;
		});
		if (isMatched) {
			selectedVal = tab.value[selector];
		}
	});
	return (
		<Radio.Group style={{ ...style }} value={selectedVal}>
			{items.map((tab) => {
				return (
					<Radio.Button
						style={{ ...tabStyle, height: 40, lineHeight: '40px', flexWrap: 'nowrap' }}
						value={tab.selectVal}
						onClick={() => onChange(tab.value)}
					>
						{tab.label}
					</Radio.Button>
				);
			})}
		</Radio.Group>
	);
}

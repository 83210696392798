import { PROPOSALS_CRUD_ACTION_CREATORS } from '../actions/proposals_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import FetchUtils, { parseJSON } from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { logoutSuccess, softError } from '../actions/session_actions';

export const proposalsRestCrudThunksForBuyer = new RestCrudThunks(
	'proposals',
	'proposal',
	'proposal',
	'proposals',
	PROPOSALS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'quote/'
);
export const proposalsRestCrudThunksForSupplier = new RestCrudThunks(
	'proposals',
	'proposal',
	'proposal',
	'proposals',
	PROPOSALS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'quote/'
);

const fetchUtils = new FetchUtils();
const BASE_URL = getBackendUri();

export const syncProposalApprovalHierarchy = (entity) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(PROPOSALS_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.patch(
					BASE_URL + `/api/v1/buyer/quote/proposals/approval_hierarchy_update/${entity.id}`,
					{}
				)
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(PROPOSALS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(PROPOSALS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(PROPOSALS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

const getBuyerProposalStatusUrl = (status, eventId) =>
	`/api/v1/buyer/quote/proposals/${status}/${eventId}`;

export const proposalStatusChangeForBuyer = (status, entity) => {
	const declineNote = entity.declineNote;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(PROPOSALS_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.patch(BASE_URL + getBuyerProposalStatusUrl(status, entity.id), {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: { declineNote },
				})
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(PROPOSALS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(PROPOSALS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(PROPOSALS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

const getSupplierProposalStatusUrl = (status, entityId) =>
	`/api/v1/supplier/quote/proposals/${status}/${entityId}`;

export const proposalStatusChangeForSupplier = (entity, status = 'pending') => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(PROPOSALS_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.patch(BASE_URL + getSupplierProposalStatusUrl(status, entity.id), {})
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(PROPOSALS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(PROPOSALS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(PROPOSALS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};
const getInternalSupplierProposalStatusUrl = (status, entityId) =>
	`/api/v1/supplier/quote/proposals/internal_tech/${status}/${entityId}`;

export const proposalStatusChangeForInternalSupplier = (entity, status = 'pending') => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(PROPOSALS_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.patch(BASE_URL + getInternalSupplierProposalStatusUrl(status, entity.id), {})
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(PROPOSALS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(PROPOSALS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(PROPOSALS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Row, Col, Card, Table, Button, Affix, Spin } from 'antd';
import { purchaseRequestsRestCrudThunksForSupplier } from '../../thunks/purchase_requests_thunks';
import { PurchaseRequestPartLineItemRowDisplay } from '../purchase_request_part_line_item_row_display/PurchaseRequestPartLineItemRowDisplay';
import { currencyFormatter } from '../../utils/DataFormatterUtils';
import {
	getEntityById,
	getObjectValues,
	getTotalValue,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import SubnavBar from '../subnav_bar/SubnavBar';
import PurchaseOrderForm from '../purchase_order_form/PurchaseOrderForm';
import { purchaseOrdersRestCrudThunksForSupplier } from '../../thunks/purchase_orders_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const APPROVED_REQUESTS_LIST = 'APPROVED_REQUESTS_LIST';
require('./EditPurchaseOrderPage.less');

class EditPurchaseOrderPage extends React.Component<any, any> {
	state = {
		selectedRowKeys: [],
		selectedVendor: { id: undefined, name: undefined },
		poPartLineItemsInCart: [],
		poEquipmentLineItemsInCart: [],
		checkingOut: true,
	};

	componentDidMount() {
		const { fetchPurchaseRequests, getPurchaseOrder, match } = this.props;
		const id = match.params.id;
		getPurchaseOrder(id).then((purchaseOrder) => {
			this.setState({
				poPartLineItemsInCart: purchaseOrder.partSupplierPurchaseOrderLineItems,
				poEquipmentLineItemsInCart: purchaseOrder.equipmentSupplierPurchaseOrderLineItems,
				selectedVendor: purchaseOrder.partEquipmentVendor,
			});
		});
		fetchPurchaseRequests({ status: 'approved' }, APPROVED_REQUESTS_LIST);
	}

	refreshPOLineItems = () => {
		const { fetchPurchaseRequests, getPurchaseOrder, match } = this.props;
		const id = match.params.id;
		getPurchaseOrder(id).then((purchaseOrder) => {
			this.setState({
				poPartLineItemsInCart: purchaseOrder.partSupplierPurchaseOrderLineItems,
				poEquipmentLineItemsInCart: purchaseOrder.equipmentSupplierPurchaseOrderLineItems,
				selectedVendor: purchaseOrder.partEquipmentVendor,
			});
		});
		fetchPurchaseRequests({ status: 'approved' }, APPROVED_REQUESTS_LIST);
	};

	handleSelectChange = (selectedRowKeys, selectedRows) => {
		const { poPartLineItemsInCart } = this.state;
		if (selectedRowKeys.length > 0) {
			this.setState({
				selectedRowKeys,
				selectedVendor: {
					id: nullSafeGet('0.partCatalog.partEquipmentVendor.id', selectedRows),
					name: nullSafeGet('0.partCatalog.partEquipmentVendor.name', selectedRows),
				},
			});
		} else if (!poPartLineItemsInCart || poPartLineItemsInCart.length === 0) {
			this.setState({ selectedRowKeys, selectedVendor: {} });
		}
	};

	handleAddToCart = () => {
		const { selectedRowKeys, poPartLineItemsInCart } = this.state;
		const { purchaseRequests } = this.props;
		const approvedPurchaseRequests = getRecordsForTargetCollection(
			purchaseRequests,
			APPROVED_REQUESTS_LIST
		);
		const requestedPartsMap = approvedPurchaseRequests.reduce((acc, el) => {
			if (el.partSupplierPurchaseRequestLineItems) {
				el.partSupplierPurchaseRequestLineItems.forEach((partLineItem) => {
					acc[partLineItem.id] = partLineItem;
				});
			}
			return acc;
		}, {});
		const newPOLineItems = selectedRowKeys.map((selectedRowKey) => {
			const requestedPart = requestedPartsMap[selectedRowKey];
			return {
				partCost:
					requestedPart.partCost !== undefined ? parseFloat(requestedPart.partCost) : undefined,
				partCatalog: requestedPart.partCatalog,
				partQuantity: requestedPart.partQuantity,
				partId: requestedPart.partCatalog.partId,
				part: requestedPart.partCatalog.part,
				prLineItemIds: [requestedPart.id],
			};
		});
		const combinedPOLineItems = newPOLineItems.concat(poPartLineItemsInCart);
		const recordsByPartId = combinedPOLineItems.reduce((acc, el) => {
			const combinedEl = acc[el.partId];
			if (combinedEl) {
				acc[el.partId] = {
					...combinedEl,
					partQuantity: el.partQuantity + combinedEl.partQuantity,
					partCost: getTotalValue(el.partCost, combinedEl.partCost),
					prLineItemIds: combinedEl.prLineItemIds.concat(el.prLineItemIds),
				};
			} else {
				acc[el.partId] = el;
			}
			return acc;
		}, {});
		const reducedPOLineItems = getObjectValues(recordsByPartId);
		this.setState({ selectedRowKeys: [], poPartLineItemsInCart: reducedPOLineItems });
	};

	handleStartCheckingOut = () => {
		this.setState({ checkingOut: true });
	};

	handleAddLineItems = () => {
		this.setState({ checkingOut: false });
	};

	handleEditPart = (record) => {
		const poPartLineItemsInCart = nullSafeGetOrElse('state.poPartLineItemsInCart', this, []);
		this.setState({
			poPartLineItemsInCart: poPartLineItemsInCart.map((lineItem) =>
				lineItem.partId === record.partId ? record : lineItem
			),
		});
	};

	handleEditEquipment = (record) => {
		const poEquipmentLineItemsInCart = nullSafeGetOrElse(
			'state.poEquipmentLineItemsInCart',
			this,
			[]
		);
		this.setState({
			poEquipmentLineItemsInCart: poEquipmentLineItemsInCart.map((lineItem) =>
				lineItem.equipmentTypeId === record.equipmentTypeId ? record : lineItem
			),
		});
	};

	removeLineItemCosts = () => {
		const poPartLineItemsInCart = nullSafeGetOrElse('state.poPartLineItemsInCart', this, []);
		const poEquipmentLineItemsInCart = nullSafeGetOrElse(
			'state.poEquipmentLineItemsInCart',
			this,
			[]
		);
		this.setState({
			poPartLineItemsInCart: poPartLineItemsInCart.map((lineItem) => ({
				...lineItem,
				partCost: undefined,
			})),
			poEquipmentLineItemsInCart: poEquipmentLineItemsInCart.map((lineItem) => ({
				...lineItem,
				equipmentCost: undefined,
			})),
		});
	};

	handleUpdateLineItems = (poPartLineItemsInCart, poEquipmentLineItemsInCart) => {
		this.setState({
			poPartLineItemsInCart,
			poEquipmentLineItemsInCart,
		});
	};

	render() {
		const {
			purchaseRequests,
			purchaseOrder,
			purchaseRequestsFetching,
			purchaseOrdersFetching,
			history,
		} = this.props;

		const {
			selectedRowKeys,
			selectedVendor,
			checkingOut,
			poPartLineItemsInCart,
			poEquipmentLineItemsInCart,
		} = this.state;

		const prPartLineItemColumns = [
			{
				title: 'Requested Part',
				dataIndex: 'partId',
				render: (text, record) => (
					<PurchaseRequestPartLineItemRowDisplay
						titleLength={40}
						purchaseRequestPartLineItem={record}
					/>
				),
			},
			{
				title: 'Vendor',
				dataIndex: ['part', 'vendor', 'id'],
				render: (text, record) => nullSafeGet('partCatalog.partEquipmentVendor.name', record),
			},
			{
				title: 'Cost',
				dataIndex: 'partQuantity',
				render: (amount, record) => currencyFormatter(2)(record.partCost),
			},
		];

		const prPartLineItemColumns2 = [
			{
				dataIndex: 'partId',
				render: (text, record) => (
					<PurchaseRequestPartLineItemRowDisplay
						titleLength={32}
						purchaseRequestPartLineItem={record}
					/>
				),
			},
			{
				title: 'Cost',
				dataIndex: 'partQuantity',
				render: (amount, record) => currencyFormatter(2)(record.partCost),
			},
		];

		const approvedPurchaseRequests = getRecordsForTargetCollection(
			purchaseRequests,
			APPROVED_REQUESTS_LIST
		);
		const requestedParts = approvedPurchaseRequests.reduce((acc, el) => {
			if (el.partSupplierPurchaseRequestLineItems) {
				return acc.concat(
					el.partSupplierPurchaseRequestLineItems.filter(
						(partLineItem) => partLineItem.associatedPurchaseOrderLineItemId === undefined
					)
				);
			}
		}, []);

		const prPartLineItemIdsInCartArr = poPartLineItemsInCart.reduce((acc, el) => {
			return acc.concat(el.prLineItemIds);
		}, []);
		const prPartLineItemIdsInCart = new Set(prPartLineItemIdsInCartArr);

		const rowSelection = {
			selectedRowKeys,
			onChange: this.handleSelectChange,
			getCheckboxProps: (record) => ({
				disabled:
					(nullSafeGet('selectedVendor.id', this.state) &&
						record.partCatalog.partEquipmentVendor.id !==
							nullSafeGet('selectedVendor.id', this.state)) ||
					prPartLineItemIdsInCart.has(record.id),
			}),
		};

		const fetching = purchaseOrdersFetching || purchaseRequestsFetching;

		return fetching ? (
			<div
				style={{
					minHeight: '100vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Spin size="large" />
			</div>
		) : (
			<div style={{ margin: '24px 24px 0' }}>
				<LogOnMountWithStandardEventProperties eventType="visited edit purchase order page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<h4 style={{ marginBottom: 24 }}>Edit Purchase Order</h4>
				{selectedRowKeys.length > 0 && !checkingOut ? (
					<Row gutter={16} className="rowSpacing">
						<Col span={24}>
							<SubnavBar
								left={
									<Button size="large" onClick={this.handleAddToCart} type="primary">
										Add items to cart
									</Button>
								}
								right={null}
							/>
						</Col>
					</Row>
				) : null}
				{!checkingOut ? (
					<Row gutter={16} className="rowSpacing">
						<Col span={poPartLineItemsInCart.length > 0 ? 14 : 24}>
							<h5>Requested Items</h5>
							<Card>
								<Table
									showHeader={true}
									rowSelection={rowSelection}
									columns={prPartLineItemColumns}
									rowKey={(prPartLineItem) => prPartLineItem.id}
									dataSource={requestedParts}
									pagination={false}
									loading={false}
								/>
							</Card>
						</Col>
						{poPartLineItemsInCart.length > 0 ? (
							<Col span={10}>
								<Affix offsetTop={24} style={{ right: 16 }}>
									<h5>Items in Cart</h5>
									<Card>
										{selectedVendor && selectedVendor.name ? (
											<div className="rowSpacing">
												<Col span={24}>
													<SubnavBar
														left={<h5 style={{ marginBottom: 0 }}>{selectedVendor.name}</h5>}
														right={
															<Button type="primary" onClick={this.handleStartCheckingOut}>
																Create Purchase Order
															</Button>
														}
													/>
												</Col>
											</div>
										) : null}
										<Table
											columns={prPartLineItemColumns2}
											rowKey={(part) => part['partId']}
											dataSource={poPartLineItemsInCart}
											showHeader={false}
											pagination={false}
											loading={false}
										/>
									</Card>
								</Affix>
							</Col>
						) : null}
					</Row>
				) : null}
				{checkingOut ? (
					<Row gutter={16} className="rowSpacing">
						<Col span={24}>
							<PurchaseOrderForm
								formData={{
									...purchaseOrder,
									partSupplierPurchaseOrderLineItems: poPartLineItemsInCart,
									equipmentSupplierPurchaseOrderLineItems: poEquipmentLineItemsInCart,
									partEquipmentVendor: selectedVendor,
									partEquipmentVendorId: nullSafeGet('id', selectedVendor),
								}}
								addMoreLineItems={this.handleAddLineItems}
								onLineItemRemoved={this.refreshPOLineItems}
								handleEditPart={this.handleEditPart}
								handleEditEquipment={this.handleEditEquipment}
								handleUpdateLineItems={this.handleUpdateLineItems}
								removeLineItemCosts={this.removeLineItemCosts}
								onSuccess={(rec) => history.push(`/supplier/purchaseOrders/detail/${rec.id}`)}
							/>
						</Col>
					</Row>
				) : null}
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	purchaseRequests: state.purchase_requests,
	purchaseOrder: getEntityById(ownProps.match.params.id, state.purchase_orders),
	purchaseOrdersFetching: state.purchase_orders.fetching,
	purchaseRequestsFetching: state.purchase_orders.fetching,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	fetchPurchaseRequests: (params, targetCollectionName) =>
		dispatch(purchaseRequestsRestCrudThunksForSupplier.read(params, targetCollectionName)),
	getPurchaseOrder: (id) => dispatch(purchaseOrdersRestCrudThunksForSupplier.readOne(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditPurchaseOrderPage));

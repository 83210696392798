import * as BizCharts from 'bizcharts';
import React, { FC } from 'react';

const { Chart, Axis, Geom, Tooltip } = BizCharts;

interface LineChartProps {
	cols: any;
	height: number;
	data: any;
	fillArea: boolean;
	position: string;
	chartPadding?: [number, number, number, number];
	xAxisLabel?: any;
	yAxisLabel?: any;
	color?: string;
	xAxisName: string;
	yAxisName: string;
	tooltipTemplate?: any;
}

export const LineChart: FC<LineChartProps> = ({
	chartPadding,
	tooltipTemplate,
	data,
	cols,
	color,
	height,
	fillArea,
	xAxisName,
	yAxisName,
	xAxisLabel,
	yAxisLabel,
	position,
}) => {
	return (
		<Chart height={height} padding="auto" data={data} scale={cols} forceFit={true}>
			<Axis name={xAxisName} label={xAxisLabel} />
			<Axis name={yAxisName} label={yAxisLabel} />
			<Tooltip
				crosshairs={{ type: 'y' }}
				itemTpl={
					tooltipTemplate
						? tooltipTemplate
						: `
                    <li data-index={index}>
                       
                        <span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>
                        {name}: {value}
                    </li>
                `
				}
			/>
			<Geom type="line" position={position} color={color} size={2} />
			{fillArea ? <Geom type="area" position={position} color={color} /> : null}
			<Geom
				type="point"
				position={position}
				color={color}
				size={4}
				shape={'circle'}
				style={{ stroke: '#fff', lineWidth: 1 }}
			/>
		</Chart>
	);
};

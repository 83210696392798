import {
	checkIsWarrantyActive,
	getCurrency,
	getObjectValues,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, Button, Modal, InputNumber } from 'antd';
import { imageUploadValidation, normFile } from '../../utils/ImageUtils';
import BackButton from '../back_button/BackButton';
import * as React from 'react';
import _ from 'lodash';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import ProblemTypeTreeSelect from '../problem_type_tree_select';

import { locationsRestCrudThunksForSupplier } from '../../thunks/locations_thunks';
import { assetsRestCrudThunksForSupplier } from '../../thunks/assets_thunks';
import { spendCategoriesRestCrudThunksForSupplier } from '../../thunks/spend_categories_thunks';
import {
	problemTypesRestCrudThunksForSupplier,
	problemTypesHeirarchicalForSupplier,
} from '../../thunks/problem_types_thunks';
import { workOrderAttributesRestCrudThunksForSupplier } from '../../thunks/work_order_attributes_thunks';
import { workOrderPrioritiesRestCrudThunksForSupplier } from '../../thunks/work_order_priorities_thunks';
import { getBuyerCompanyPrivateNetworksWithLocationsForSupplier } from '../../thunks/supplier_private_networks_thunks';
import { workOrdersRestCrudThunksForSupplier } from '../../thunks/work_orders_thunks';
import { getProtectedImageUriForSupplier } from '../../utils/FileAccessUtils';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import {
	addWarrantySupplierToStoreState,
	supplierFacilitiesRestCrudThunksForSupplier,
} from '../../thunks/supplier_facilities_thunks';
import {
	PRE_SUPPLIER_ASSIGNMENT_STATUSES,
	WORK_ORDER_STATUSES,
} from '../../constants/WorkOrderStatuses';
import { WORK_ORDERS_CRUD_ACTION_CREATORS } from '../../actions/work_orders_actions';
import { areasRestCrudThunksForSupplier } from '../../thunks/areas_thunks';
import { retrieveCachedUserDetails } from '../../thunks/session_thunks';
import SupplierSelectionWorkOrderSelect from '../supplier_selection_work_order_select';
import {
	getBuyerCompanyApprovalTiers,
	hasApprovalHierarchyChanged,
} from '../../thunks/company_approval_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { SUPPLIER_ADMIN_ONLY } from '../roles/roles';
import OWRadioGroup from '../ow_radio_group/OWRadioGroup';
import { getExchangeRate } from '../../thunks/currencies_thunks';
import { projectsRestCrudThunksForSupplier } from '../../thunks/projects_thunks';
import { resolutionTypesRestCrudThunksForSupplier } from '../../thunks/resolution_types_thunks';
import ApprovalHierarchyChangeModal from '../ApprovalHierarchyChangeModal';
import OWRichTextEditor from '../../rich_text_editor/OWRichTextEditor';
import AssetNumberDisplay from '../asset_row_display/AssetNumberDisplay';
import OWUpload from '../OWUpload';
import { getBuyerCompanySettingValue } from '../../utils/AuthUtils';

const WORK_ORDER_FORM_LOCATIONS_AUTOCOMPLETE = 'SUPPLIER_WORK_ORDER_FORM_LOCATIONS_AUTOCOMPLETE';
const WORK_ORDER_FORM_ASSETS_AUTOCOMPLETE = 'SUPPLIER_WORK_ORDER_FORM_ASSETS_AUTOCOMPLETE';
const WORK_ORDER_FORM_AREAS_AUTOCOMPLETE = 'SUPPLIER_WORK_ORDER_FORM_AREAS_AUTOCOMPLETE';
const WORK_ORDER_FORM_PROBLEM_TYPES_AUTOCOMPLETE =
	'SUPPLIER_WORK_ORDER_FORM_PROBLEM_TYPES_AUTOCOMPLETE';
const WORK_ORDER_FORM_SPEND_CATEGORIES_AUTOCOMPLETE =
	'SUPPLIER_WORK_ORDER_FORM_SPEND_CATEGORIES_AUTOCOMPLETE';
const WORK_ORDER_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE =
	'SUPPLIER_WORK_ORDER_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE';
const WORK_ORDER_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE =
	'SUPPLIER_WORK_ORDER_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE';
const WORK_ORDER_FORM_PROJECTS_AUTOCOMPLETE = 'WORK_ORDER_FORM_PROJECTS_AUTOCOMPLETE';

interface WorkOrderFormProps extends FormComponentProps {
	history: any;
	onSubmit: any;
	onCancel: any;
	redirectForwardUrl?: string;
	onSuccess?: any;
	creating: boolean;
	updating: boolean;
	formData: any;
	createErrors: any[];
	updateErrors: any[];
	fetchMultipleLocations: any;
	fetchLocations: any;
	fetchLocation: any;
	locations: any;
	fetchAsset: any;
	fetchMultipleAssets: any;
	fetchAssets: any;
	assets: any;
	fetchMultipleAreas: any;
	fetchAreas: any;
	areas: any;
	fetchMultipleProblemTypes: any;
	fetchProblemTypes: any;
	problemTypes: any;
	fetchMultipleSpendCategories: any;
	fetchSpendCategories: any;
	spendCategories: any;
	fetchWorkOrderAttributes: any;
	fetchWorkOrderPriority: any;
	fetchMultipleWorkOrderPriorities: any;
	fetchWorkOrderPriorities: any;
	workOrderPriorities: any;
	supplierFacilities: any;
	fetchSupplierFacilitiesInPrivateNetwork: any;
	fetchMultipleSupplierFacilitiesInPrivateNetwork: any;
	currentUser: any;
	updateWorkOrderForm: any;
	fetchSupplierFacility: any;
	fetchSupplierCompany: any;
	fetchApprovalHeirarchy: any;
	addWarrantySupplier: any;
	fetchExchangeRate: any;
	projects: any;
	fetchProjects: any;
	fetchMultipleProjects: any;
	resolutionTypes: any;
	fetchMultipleResolutionTypes: any;
	fetchResolutionTypes: any;
	approveWorkOrderForm: any;
	isApprovalForm: any;
	willApprovalHierarchyChange: any;
	companyConfig: any;
}

class SupplierWorkOrderEditSafeForm extends React.Component<WorkOrderFormProps, any> {
	confirmChange: any = () => {};
	cancelChange: any = () => {};
	constructor(props) {
		super(props);
		this.state = {
			woChanges: {},
			workOrderApprovalHierarchy: nullSafeGet('formData.approvalHierarchy', props),
			woPriorityId: nullSafeGet('formData.woPriorityId', props),
			problemTypeId: nullSafeGet('formData.problemTypeId', props),
			userApprovalInfo: {},
			previewVisible: false,
			uploadLoading: false,
			previewImage: false,
			privateSupplier: false,
			inputValues: {},
			nteAmount: 0,
			workOrderPriority: null,
			workOrderPriorityId: null,
			locationId: null,
			buyerFacilityId: null,

			assetSelectionDisabled: true,
			allPhotos: [],
			approvalHeirarchy: {},
			asset: nullSafeGet('formData.asset', props),
			warranty: nullSafeGet('formData.asset.warranties.0', props),
			supplierFacilityId: nullSafeGet('formData.supplierFacilityId', props),
			supplier: nullSafeGetOrElse('supplierFacility', this.props.formData, {}),
			exchangeRate: nullSafeGetOrElse('formData.currencyExchangeRateLocationToSupplier', props, 1),
			currencyExchangeRateBuyerToLocation: nullSafeGetOrElse(
				'currencyExchangeRateBuyerToLocation',
				this.props.formData,
				1
			),
			currentNTE: nullSafeGetOrElse('formData.nte', props, 0),
			locationNTE: nullSafeGetOrElse(
				'formData.nteInLocationCurrency',
				props,
				nullSafeGet('formData.nte', props) /
					nullSafeGetOrElse('currencyExchangeRateLocationToSupplier', this.props.formData, 1)
			),
			editorRef: React.createRef(),
		};
	}

	componentDidMount() {
		const { form, formData, fetchApprovalHeirarchy, currentUser, fetchExchangeRate } = this.props;
		const { locationNTE, supplier } = this.state;
		let photosInfo;
		if (formData.images) {
			photosInfo = formData.images.map((p, idx) => {
				const [imgFileId, imgFileName] = p.split('/');
				const thumbUri = getProtectedImageUriForSupplier(imgFileId, imgFileName, 100, 100);
				const fullUri = getProtectedImageUriForSupplier(imgFileId, imgFileName, 400, 400);
				return {
					uid: idx,
					size: 1,
					name: imgFileName,
					filename: imgFileName,
					url: fullUri,
					status: 'done',
					thumbUrl: thumbUri,
					response: { data: { fileName: imgFileName, fileId: imgFileId } },
					type: 'image/jpeg',
				};
			});
		} else {
			photosInfo = [];
		}

		this.setState({
			locationId: formData.locationId,
			problemTypeId: formData.problemTypeId,
			workOrderPriorityId: formData.woPriorityId,
			nteAmount: formData.nte,

			assetSelectionDisabled: formData.assetId || formData.locationId ? false : true,
			allPhotos: photosInfo,
			location: nullSafeGetOrElse('location', formData, {}),
		});

		const workOrderCurrency = getCurrency({ workOrder: formData });
		const supplierCurrency = getCurrency({ supplier });
		if (supplierCurrency && workOrderCurrency && workOrderCurrency.id !== supplierCurrency.id) {
			//Should only be possible if Suppliers Currency has changed
			fetchExchangeRate(supplierCurrency.id, workOrderCurrency.id, currentUser.contactType).then(
				(rate) =>
					this.setState(
						{
							exchangeRate: nullSafeGetOrElse('conversionRate', rate, 1),
							currentNTE: locationNTE * nullSafeGetOrElse('conversionRate', rate, 1),
						},
						() => {
							form.setFieldsValue({
								nte: this.state.currentNTE,
								nteInLocationCurrency: locationNTE,
							});
						}
					)
			);
		}

		fetchApprovalHeirarchy().then((data) => {
			const tiers = nullSafeGet('0.tierDetails', data);
			if (tiers && tiers.length > 0) {
				const userTier =
					_.find(tiers, (item) => _.find(item.approvers, { email: currentUser.email })) || tiers[0];
				this.setState({
					approvalHeirarchy: { amount: userTier.amountUpto || 1000000000 },
				});
			}
		});
	}

	getStatus = (supplierFacilityId, oldStatus) => {
		const { privateSupplier, currentUser } = this.state;
		let newStatus;
		if (!privateSupplier) {
			newStatus = oldStatus;
		} else if (
			PRE_SUPPLIER_ASSIGNMENT_STATUSES.includes(oldStatus) &&
			supplierFacilityId &&
			privateSupplier
		) {
			newStatus = WORK_ORDER_STATUSES.assignedToInternalTech;
		} else if (PRE_SUPPLIER_ASSIGNMENT_STATUSES.includes(oldStatus) && supplierFacilityId) {
			newStatus = WORK_ORDER_STATUSES.pendingConfirmationByServiceProvider;
		} else if (
			oldStatus === WORK_ORDER_STATUSES.supplierInitiatedPendingApproval &&
			supplierFacilityId
		) {
			newStatus = WORK_ORDER_STATUSES.confirmedByServiceProvider;
		} else if (!supplierFacilityId) {
			newStatus = WORK_ORDER_STATUSES.unassigned;
		} else {
			newStatus = oldStatus;
		}
		return newStatus;
	};

	getAdditionalInfo = () =>
		nullSafeGet('state.editorRef.current', this) &&
		(this.state.editorRef.current as any).getContent();

	handleSubmit = (e) => {
		const { form, formData, history, redirectForwardUrl, onSuccess, updateWorkOrderForm } =
			this.props;
		const { exchangeRate, supplier, location, currentNTE, hasQuote } = this.state;

		const locationCurrency = getCurrency({ location });
		const supplierCurrency = getCurrency({ supplier });
		const workOrderCurrency = getCurrency({ workOrder: formData });

		e.preventDefault();
		form.validateFields((err, values) => {
			if (!err) {
				// put image data into proper format to persist
				const picturesInfo = values.images.map((p) => {
					const fileName = nullSafeGetOrElse('response.data.fileName', p, '');
					const fileId = nullSafeGetOrElse('response.data.fileId', p, '');
					return `${fileId}/${fileName}`;
				});

				let workOrder = {
					id: values.id,
					title: values.title,
					status: this.getStatus(values.supplierFacilityId, values.status),
					areaId: values.areaId ? parseInt(values.areaId, 10) : undefined,
					problemTypeId: parseInt(values.problemTypeId, 10),
					woPriorityId: parseInt(values.workOrderPriorityId, 10),
					spendCategoryId: parseInt(values.spendCategoryId, 10),
					locationId: parseInt(values.locationId, 10),
					assetId: values.assetId ? parseInt(values.assetId, 10) : null,
					...(values.assetId && {
						isAssetDown: values.isAssetDown === 'true' ? true : false,
					}),
					images: picturesInfo,

					nte: currentNTE ? currentNTE : undefined,
					currencyId: !hasQuote ? supplierCurrency.id : workOrderCurrency.id,
					nteInLocationCurrency: values.nteInLocationCurrency
						? values.nteInLocationCurrency
						: undefined,
					locationCurrencyId: locationCurrency.id,
					currencyExchangeRateLocationToSupplier:
						locationCurrency.id === supplierCurrency.id ? 1 : exchangeRate,
					description: this.getAdditionalInfo(),
					buyerFacilityId: values.buyerFacilityId || this.state.buyerFacilityId,
					buyerCompanyId: values.buyerCompanyId,
					createdBy: values.createdBy,
					supplierFacilityId: values.supplierFacilityId,
					contractorDetails: values.contractorDetails,
					notes: values.notes,
					poNumber: values.poNumber,
					buyerAttachments: values.buyerAttachments,
					supplierAttachments: values.supplierAttachments,
					buyerProjectIds: values.buyerProjectIds,
					resolutionTypeId: values.resolutionType,
				};

				updateWorkOrderForm(workOrder).then((record) => {
					if (redirectForwardUrl) {
						history.push(redirectForwardUrl);
					}
					if (onSuccess) {
						onSuccess(record);
					}
				});
			}
		});
	};

	handlePreview = (obj) => {
		let thumbUrl = null;
		let fullUrl = null;
		let photoInfo = null;
		if (obj.response.data) {
			const imgFileId = obj.response.data.fileId;
			const imgFileName = obj.response.data.fileName;
			thumbUrl = getProtectedImageUriForSupplier(imgFileId, imgFileName, 100, 100);
			fullUrl = getProtectedImageUriForSupplier(imgFileId, imgFileName, 400, 400);
			photoInfo = {
				uid: imgFileId,
				size: 1,
				name: imgFileName,
				filename: imgFileName,
				url: fullUrl,
				status: 'done',
				thumbUrl: thumbUrl,
				response: { data: { fileName: imgFileName, fileId: imgFileId } },
				type: 'image/jpeg',
			};
		}

		this.setState({
			photoInfo: [photoInfo],
			previewImage: fullUrl || thumbUrl,
			previewVisible: true,
		});
	};

	handleCancel = () => this.setState({ previewVisible: false });

	handleImageUpdate = (info) => {
		if (info.file.status === 'done') {
			this.setState({ allPhotos: info.fileList });
		} else if (info.file.status === undefined) {
			info.fileList = this.state.allPhotos;
		}
	};

	isAreaFilterAllowed = () => {
		const { companyConfig, currentUser } = this.props;
		return getBuyerCompanySettingValue(ROLE_TYPES.SUPPLIER)(
			'assetConfig.allowAreaAssociationWithAssets',
			companyConfig,
			currentUser,
			false
		);
	};

	refreshAssets = () => {
		const { locationId, areaId, problemTypeId } = this.state;
		const areaFilterAllowed = this.isAreaFilterAllowed();
		const { fetchAssets } = this.props;
		fetchAssets({
			locationId,
			problemTypeId,
			...(areaFilterAllowed && areaId && { areaIdOrNull: areaId }),
		}).then((assets) =>
			assets.length > 0 ? this.setState({ assetSelectionDisabled: false }) : null
		);
	};

	handleLocationChange = (locationId) => {
		const { fetchLocation, fetchWorkOrderPriorities } = this.props;
		fetchLocation(locationId).then((loc) => {
			this.setState({
				locationId: locationId,
				buyerFacilityId: loc.buyerFacilityId,
				location: loc,
			});
			fetchWorkOrderPriorities({ buyerCompanyId: loc.buyerCompanyId });
		});
		this.refreshAssets();
	};

	handleAreaChange = (areaId) => {
		this.setState({ areaId: areaId });
		this.refreshAssets();
	};

	handleWorkOrderPriorityChange = (woPriorityId) => {
		const { form } = this.props;
		this.confirmChange = () => {
			this.setState({ woPriorityId });
		};
		this.cancelChange = () => {
			form.setFieldsValue({ workOrderPriorityId: this.state.woPriorityId });
		};
		this.changeApprovalHierarchyParamCheck({ woPriorityId: woPriorityId });
	};
	handleProblemTypeChange = (problemTypeId) => {
		const { form } = this.props;
		this.confirmChange = () => {
			this.setState({ problemTypeId });
		};
		this.cancelChange = () => {
			form.setFieldsValue({ problemTypeId: this.state.problemTypeId });
		};
		this.changeApprovalHierarchyParamCheck({ problemTypeId: problemTypeId });
		this.refreshAssets();
	};

	fetchSupplierData = (
		searchText = '' || {},
		targetCollectionName = WORK_ORDER_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE,
		pagination = null,
		sorting = null,
		filters = null,
		addToTargetCollection = false
	) => {
		const { addWarrantySupplier, fetchSupplierFacilitiesInPrivateNetwork } = this.props;
		const { warranty } = this.state;
		return new Promise((resolve, reject) =>
			fetchSupplierFacilitiesInPrivateNetwork(
				{ name: searchText, offset: 0, limit: 100 },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			).then((data) => {
				const warrantySupplierId = nullSafeGet('provider.supplierFacilityId', warranty);
				if (data && !data.some((supplier) => nullSafeGet('id', supplier) === warrantySupplierId)) {
					/*
						Removed because it is crashing the app
						addWarrantySupplier(
							warrantySupplierId,
							WORK_ORDER_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE
						);
					 */
				}
				resolve(data);
			})
		);
	};

	handleAssetChange = (assetId) => {
		const { fetchAsset } = this.props;
		if (assetId) {
			fetchAsset(assetId).then((asset) => {
				this.setState(
					{
						assetId,
						asset,
						warranty: nullSafeGet('warranties.0', asset),
					},
					() => {
						this.fetchSupplierData();
					}
				);
			});
		} else {
			this.setState({ assetId: assetId, asset: undefined, warranty: undefined });
		}
	};

	handleSupplierFacilityChange = (supplierFacilityId) => {
		const { fetchSupplierFacility, fetchExchangeRate, currentUser } = this.props;
		const { location } = this.state;
		if (supplierFacilityId) {
			fetchSupplierFacility(supplierFacilityId).then((supplierFacility) => {
				const locationCurrencyId = getCurrency({ location }).id;
				const supplierCurrencyId = nullSafeGet('currencyId', supplierFacility);
				if (locationCurrencyId && supplierCurrencyId && locationCurrencyId !== supplierCurrencyId) {
					fetchExchangeRate(supplierCurrencyId, locationCurrencyId, currentUser.contactType).then(
						(rate) =>
							this.setState({
								exchangeRate: nullSafeGetOrElse('conversionRate', rate, 1),
							})
					);
				} else {
					this.setState({ exchangeRate: 1 });
				}
				this.setState({
					supplierFacilityId,
					privateSupplier: supplierFacility.status === 'private',
				});
			});
		}
	};

	//param = {key: value}
	changeApprovalHierarchyParamCheck = (param) => {
		const { willApprovalHierarchyChange, formData, isApprovalForm, currentUser } = this.props;
		const { woChanges } = this.state;
		const changes = { ...woChanges, ...param };

		this.setState({ buttonLoading: true, pendingParam: param });
		willApprovalHierarchyChange(nullSafeGet('id', formData), changes).then((result) => {
			const quoteHierarchyChanged =
				nullSafeGetOrElse('quoteApprovalChanges.changedHierarchy.length', result, 0) > 0;
			const quoteChanged =
				nullSafeGet('quoteApprovalChanges.approversAlreadyPresent', result) &&
				nullSafeGetOrElse('quoteApprovalChanges.changedHierarchy.length', result, 0) > 0;
			const invoiceChanged =
				nullSafeGet('invoiceApprovalChanges.approversAlreadyPresent', result) &&
				nullSafeGetOrElse('invoiceApprovalChanges.changedHierarchy.length', result, 0) > 0;
			const workOrderHierarchyChanged =
				nullSafeGetOrElse('workOrderApprovalChanges.changedHierarchy.length', result, 0) > 0 &&
				isApprovalForm;

			this.setState({
				nextApprovalHierarchy: quoteHierarchyChanged
					? nullSafeGet('quoteApprovalChanges.changedHierarchy.0', result)
					: undefined,
				workOrderApprovalHierarchy: workOrderHierarchyChanged
					? nullSafeGet('workOrderApprovalChanges.changedHierarchy.0', result)
					: nullSafeGet('approvalHierarchy', formData),
			});
			if (quoteChanged || invoiceChanged || workOrderHierarchyChanged) {
				this.setState({
					showApprovalHierarchyChangeModal: true,
					approvalHierarchyChanges: [
						...(quoteChanged ? ['quote'] : []),
						...(invoiceChanged ? ['invoice'] : []),
						...(workOrderHierarchyChanged ? ['work order'] : []), //doesn't matter if approvers are present or not
					],
				});
			} else {
				this.changeApprovalHierarchyParamConfirm(param)();
			}
		});
	};
	changeApprovalHierarchyParamConfirm = (param) => () => {
		const { woChanges } = this.state;
		this.confirmChange();
		this.setState({
			...param,
			showApprovalHierarchyChangeModal: false,
			buttonLoading: false,
			pendingParam: undefined,
			woChanges: { ...woChanges, ...param },
		});
	};
	changeApprovalHierarchyParamDismiss = () => {
		this.cancelChange();
		this.setState({
			showApprovalHierarchyChangeModal: false,
			buttonLoading: false,
			pendingParam: undefined,
			nextApprovalHierarchy: undefined,
		});
	};

	render() {
		const {
			createErrors,
			updateErrors,
			creating,
			updating,
			form,
			formData,
			locations,
			areas,
			assets,
			problemTypes,
			spendCategories,
			workOrderPriorities,
			currentUser,
			fetchLocations,
			fetchMultipleLocations,
			fetchAreas,
			fetchMultipleAreas,
			fetchAssets,
			fetchMultipleAssets,
			fetchProblemTypes,
			fetchMultipleProblemTypes,
			fetchSpendCategories,
			fetchMultipleSpendCategories,
			fetchWorkOrderPriorities,
			fetchMultipleWorkOrderPriorities,
			supplierFacilities,
			fetchMultipleSupplierFacilitiesInPrivateNetwork,
			projects,
			fetchProjects,
			fetchMultipleProjects,
			resolutionTypes,
			fetchMultipleResolutionTypes,
			fetchResolutionTypes,
		} = this.props;
		const { getFieldDecorator, getFieldValue } = form;
		const {
			previewVisible,
			previewImage,
			asset,
			warranty,
			location,
			supplier,
			exchangeRate,
			currentNTE,
			locationNTE,
			currencyExchangeRateBuyerToLocation,
			hasQuote,
		} = this.state;
		const dateFormat = 'dddd, MMM D, YYYY, h:mm A';
		const BACKEND_URI = getBackendUri();
		const uploadHeaders = { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token };
		const isAdmin = currentUser.roles.some((role) => SUPPLIER_ADMIN_ONLY.has(role));
		const { approvalHeirarchy } = this.state;
		const locationCurrency = getCurrency({ location });
		const supplierCurrency = getCurrency({ supplier });
		const workOrderCurrency = getCurrency({ workOrder: formData });
		const isInternalTech = nullSafeGet('facility.status', currentUser) === 'private';
		const allowedToEditNTE = nullSafeGet(
			'facility.internalTechConfig.allowedToApproveProposal',
			currentUser
		);
		const resolutionsFetching = nullSafeGet('fetching', resolutionTypes);
		const hasResolutionTypes = resolutionsFetching
			? true
			: getObjectValues(nullSafeGetOrElse('records', resolutionTypes, {})).length > 0;

		getFieldDecorator('id', { initialValue: formData.id });
		getFieldDecorator('createdAt', { initialValue: formData.createdAt });
		getFieldDecorator('notes', { initialValue: formData.notes });
		getFieldDecorator('poNumber', { initialValue: formData.poNumber });
		getFieldDecorator('buyerFacilityId', {
			initialValue: formData.buyerFacilityId,
		});
		getFieldDecorator('buyerCompanyId', {
			initialValue:
				formData.buyerCompanyId ||
				nullSafeGet('facility.supplierCompany.privateBuyerCompanyId', currentUser),
		});
		getFieldDecorator('createdBy', {
			initialValue: formData.createdBy || nullSafeGet('email', currentUser),
		});
		getFieldDecorator('contractorDetails', {
			initialValue: formData.contractorDetails || {
				contractorContacts: [],
			},
		});

		getFieldDecorator('problemTypeId', {
			initialValue: this.state.problemTypeId,
		});

		getFieldDecorator('buyerAttachments', { initialValue: formData.buyerAttachments || [] });
		getFieldDecorator('supplierAttachments', { initialValue: formData.supplierAttachments || [] });

		const areaFilterAllowed = this.isAreaFilterAllowed();

		return (
			<Form layout="vertical" onSubmit={this.handleSubmit} className="supplierForm">
				<Form.Item label="Title">
					{getFieldDecorator('title', {
						initialValue: formData.title,
						rules: [{ required: true, message: 'This field is required.' }],
					})(<Input style={{ maxWidth: 800 }} disabled={true} />)}
				</Form.Item>
				<Form.Item label="Incident Location">
					{getFieldDecorator('locationId', {
						initialValue: this.state.locationId,
						rules: [{ required: true, message: 'This field is required.' }],
					})(
						<OWAsyncSelect
							disabled={true}
							stateSlice={locations}
							targetCollectionName={WORK_ORDER_FORM_LOCATIONS_AUTOCOMPLETE}
							onChange={this.handleLocationChange}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleLocations(ids, targetCollectionName);
							}}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchLocations(
									{ name: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							renderRecord={(location) => (
								<Select.Option key={location.id} value={location.id}>
									<div>
										{nullSafeGet('buyerCompany.name', location)} - {location.name}
									</div>
									<div style={{ color: 'rgba(0,0,0,0.45)' }}>
										{nullSafeGet('buyerFacility.primaryAddress.streetAddress1', location)},{' '}
										{nullSafeGet('buyerFacility.primaryAddress.city', location)}{' '}
										{nullSafeGet('buyerFacility.primaryAddress.region', location)}
									</div>
								</Select.Option>
							)}
							sortBy={{ sort_by: 'name', order: 'ascend' }}
						/>
					)}
				</Form.Item>
				<Form.Item label="Area">
					{getFieldDecorator('areaId', {
						initialValue: formData.areaId,
					})(
						<OWAsyncSelect
							stateSlice={areas}
							targetCollectionName={WORK_ORDER_FORM_AREAS_AUTOCOMPLETE}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleAreas(ids, targetCollectionName);
							}}
							onChange={this.handleAreaChange}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchAreas(
									{ name: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							renderRecord={(area) => (
								<Select.Option key={area.id} value={area.id}>
									{area.hierarchyName}
								</Select.Option>
							)}
							additionalFilters={{
								locationId: nullSafeGet('locationId', this.state),
								brandId: nullSafeGet('location.brandId', this.state),
							}}
							sortBy={{ sort_by: 'name', order: 'ascend' }}
						/>
					)}
				</Form.Item>
				<Form.Item required={true} label="Problem Type">
					{getFieldDecorator('problemTypeId', {
						initialValue: formData.problemTypeId,
						rules: [{ required: true, message: 'This field is required.' }],
					})(
						<ProblemTypeTreeSelect
							mode="default"
							stateSlice={problemTypes}
							targetCollectionName={WORK_ORDER_FORM_PROBLEM_TYPES_AUTOCOMPLETE}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleProblemTypes(ids, targetCollectionName);
							}}
							onChange={this.handleProblemTypeChange}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) =>
								fetchProblemTypes(
									{ name: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								)
							}
							additionalFilters={{
								locationId: nullSafeGet('locationId', this.state),
								brandId: nullSafeGet('location.brandId', this.state),
							}}
							sortBy={{ sort_by: 'name', order: 'ascend' }}
						/>
					)}
				</Form.Item>
				<Form.Item label="Affected Asset">
					{getFieldDecorator('assetId', {
						initialValue: formData.assetId,
					})(
						<OWAsyncSelect
							stateSlice={assets}
							allowClear={true}
							targetCollectionName={WORK_ORDER_FORM_ASSETS_AUTOCOMPLETE}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleAssets(ids, targetCollectionName);
							}}
							onChange={this.handleAssetChange}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchAssets(
									{ search: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							renderRecord={(asset) => (
								<Select.Option key={asset.id} value={asset.id}>
									<div className="flex flex-col">
										<div>{asset.name}</div>
										<AssetNumberDisplay asset={asset} />
									</div>
									{checkIsWarrantyActive(nullSafeGet('warranties.0', asset)) ? (
										<span className="ml-2 inline-block text-gray-400">(Warranty)</span>
									) : null}
								</Select.Option>
							)}
							additionalFilters={{
								locationId: nullSafeGetOrElse('locationId', this.state, formData.locationId),
								problemTypeId: nullSafeGetOrElse(
									'problemTypeId',
									this.state,
									formData.problemTypeId
								),
								...(areaFilterAllowed &&
									nullSafeGetOrElse('areaId', this.state, formData.areaId) && {
										areaIdOrNull: nullSafeGetOrElse('areaId', this.state, formData.areaId),
									}),
							}}
							sortBy={{ sort_by: 'name', order: 'ascend' }}
						/>
					)}
				</Form.Item>
				{getFieldValue('assetId') && (
					<Form.Item label="Is the asset functioning?">
						{form.getFieldDecorator('isAssetDown', {
							initialValue: formData.isAssetDown ? 'true' : 'false',
						})(
							<OWRadioGroup
								disabled
								valueAccessor={(item) => item.value}
								renderRecord={(item) => item.label}
								items={[
									{
										label: 'Yes',
										value: 'false',
									},
									{
										label: 'No',
										value: 'true',
									},
								]}
							/>
						)}
					</Form.Item>
				)}
				<Form.Item label="Additional Info">
					{getFieldDecorator('additionalInfo', {
						initialValue: formData.description,
					})(
						<OWRichTextEditor
							initialEditorState={formData.description}
							editorRef={this.state.editorRef}
						/>
					)}
				</Form.Item>
				<Form.Item label="Photos">
					{getFieldDecorator(`images`, {
						valuePropName: 'fileList',
						getValueFromEvent: normFile,
						initialValue: this.state.allPhotos,
					})(
						<OWUpload
							accept="image/*"
							name="file"
							listType="picture-card"
							className="workorder-image-uploader"
							multiple={true}
							headers={uploadHeaders}
							onPreview={this.handlePreview}
							action={`${BACKEND_URI}/api/v1/supplier/file/upload`}
							beforeUpload={imageUploadValidation}
							onChange={this.handleImageUpdate}
						>
							<div style={{ display: 'inline' }}>
								<LegacyIcon type={this.state.uploadLoading ? 'loading' : 'plus'} />
								<div className="ant-upload-text">Upload</div>
							</div>
							<Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
								<img alt="example" style={{ width: '100%' }} src={previewImage} />
							</Modal>
						</OWUpload>
					)}
				</Form.Item>
				<div className={'flex flex-row'}>
					<Form.Item
						label="NTE"
						validateStatus={approvalHeirarchy.status}
						help={
							approvalHeirarchy.status ? (
								<div
									style={{ width: 1000 }}
									className={'absolute flex flex-row flex-nowrap'}
								>{`This NTE change bypasses the requirement of a quote. Based on approval hierarchy, you can set NTE upto ${locationCurrency.format(
									approvalHeirarchy.amount * currencyExchangeRateBuyerToLocation
								)}`}</div>
							) : !allowedToEditNTE ? (
								<div
									style={{ width: 1000 }}
									className={'absolute flex flex-row flex-nowrap'}
								>{`You are not allowed to update the NTE or approve quotes.`}</div>
							) : (
								''
							)
						}
						extra={
							nullSafeGet('supplier.status', this.state) === 'private' ? (
								<div className="mt-2 text-xs text-gray-500">
									NTE cannot be set for an internal service provider
								</div>
							) : null
						}
					>
						{getFieldDecorator('nteInLocationCurrency', {
							initialValue: locationNTE,
							validateTrigger: 'onChange',
							rules: [
								{
									validator: (rule, value, callback) => {
										if (
											approvalHeirarchy.amount &&
											parseFloat(value) / currencyExchangeRateBuyerToLocation >
												parseFloat(approvalHeirarchy.amount) &&
											parseFloat(value) / currencyExchangeRateBuyerToLocation !== formData.nte
										) {
											this.setState({
												approvalHeirarchy: {
													...approvalHeirarchy,
													status: isAdmin ? 'warning' : 'error',
												},
											});
											callback(isAdmin ? undefined : '');
										} else {
											this.setState({
												approvalHeirarchy: {
													...approvalHeirarchy,
													status: null,
												},
											});
											callback();
										}
									},
								},
							],
						})(
							<InputNumber
								style={{ width: 200 }}
								disabled={
									!allowedToEditNTE || nullSafeGet('supplier.status', this.state) === 'private'
								}
								addonAfter={locationCurrency.id}
								onChange={(value) =>
									this.setState({
										locationNTE: value,
										currentNTE: (value as any) * exchangeRate,
									})
								}
							/>
						)}
					</Form.Item>
					{supplierCurrency && locationCurrency && supplierCurrency.id !== locationCurrency.id ? (
						<div className="flex flex-row" style={{ marginTop: 30 }}>
							<i
								className="icons8-font icons8-data-transfer"
								style={{ fontSize: 24, marginTop: 2, marginRight: 26 }}
							/>
							<InputNumber
								style={{ width: 200, height: 38 }}
								disabled={true}
								value={currentNTE.toFixed(2)}
								addonAfter={hasQuote ? workOrderCurrency.id : supplierCurrency.id}
							/>
						</div>
					) : null}
				</div>
				<Form.Item label="Priority">
					{getFieldDecorator('workOrderPriorityId', {
						initialValue: this.state.workOrderPriorityId,
						rules: [{ required: true, message: 'This field is required.' }],
					})(
						<OWAsyncSelect
							disabled={true}
							disabledPlaceholder={formData.woPriorityId}
							stateSlice={workOrderPriorities}
							targetCollectionName={WORK_ORDER_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleWorkOrderPriorities(ids, targetCollectionName);
							}}
							onChange={this.handleWorkOrderPriorityChange}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchWorkOrderPriorities(
									{ name: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							renderRecord={(workOrderPriority) => (
								<Select.Option key={workOrderPriority.id} value={workOrderPriority.id}>
									{workOrderPriority.name}
								</Select.Option>
							)}
						/>
					)}
				</Form.Item>
				<ApprovalHierarchyChangeModal
					visible={this.state.showApprovalHierarchyChangeModal}
					onOk={this.changeApprovalHierarchyParamConfirm}
					param={this.state.pendingParam}
					onCancel={this.changeApprovalHierarchyParamDismiss}
					approvalHierarchyChanges={this.state.approvalHierarchyChanges}
				/>
				<Form.Item required={true} label="Supplier">
					{getFieldDecorator('supplierFacilityId', {
						initialValue: formData.supplierFacilityId,
					})(
						<SupplierSelectionWorkOrderSelect
							disabled={true}
							allowClear={true}
							stateSlice={supplierFacilities}
							disabledPlaceholder={formData.supplierFacilityId}
							targetCollectionName={
								WORK_ORDER_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE
							}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleSupplierFacilitiesInPrivateNetwork(ids, targetCollectionName);
							}}
							warrantySupplierId={nullSafeGet('provider.supplierFacilityId', warranty)}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) =>
								this.fetchSupplierData(
									{ name: searchText, offset: 0, limit: 100 },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								)
							}
							onChange={this.handleSupplierFacilityChange}
							additionalFilters={{
								problemTypeId: this.state.problemTypeId,
								locationId: this.state.locationId,
							}}
							sortBy={{ sort_by: 'name', order: 'ascend' }}
						/>
					)}
				</Form.Item>
				{hasResolutionTypes ? (
					<Form.Item label="Resolution Type">
						{getFieldDecorator('resolutionType', {
							initialValue: formData.resolutionTypeId,
						})(
							<OWAsyncSelect
								style={{ maxWidth: 800 }}
								stateSlice={resolutionTypes}
								targetCollectionName={'RESOLUTION_TYPES_AUTOCOMPLETE'}
								fetchMultiple={(ids, targetCollectionName) => {
									fetchMultipleResolutionTypes(ids, targetCollectionName);
								}}
								fetchData={(
									searchText,
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								) => {
									fetchResolutionTypes(
										{ name: searchText },
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									);
								}}
								renderRecord={(resolutionType) => (
									<Select.Option key={resolutionType.id} value={resolutionType.id}>
										{resolutionType.resolutionCode ? `${resolutionType.resolutionCode} - ` : null}
										{resolutionType.resolutionType}
									</Select.Option>
								)}
								sortBy={{ sort_by: 'name', order: 'ascend' }}
								allowClear
							/>
						)}
					</Form.Item>
				) : null}

				{isInternalTech ? (
					<Form.Item label="Project">
						{getFieldDecorator('buyerProjectIds', {
							initialValue: formData.buyerProjectIds,
						})(
							<OWAsyncSelect
								style={{ maxWidth: 800 }}
								mode={'multiple'}
								stateSlice={projects}
								targetCollectionName={WORK_ORDER_FORM_PROJECTS_AUTOCOMPLETE}
								fetchMultiple={(ids, targetCollectionName) => {
									fetchMultipleProjects(ids, targetCollectionName);
								}}
								fetchData={(
									searchText,
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								) => {
									fetchProjects(
										{ name: searchText },
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									);
								}}
								renderRecord={(project) => (
									<Select.Option key={project.id} value={project.id}>
										{project.title}
									</Select.Option>
								)}
							/>
						)}
					</Form.Item>
				) : null}
				{getFieldDecorator('status', {
					initialValue: formData.status,
				})}
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						loading={updating}
						className="contactForm__button"
					>
						Update work order
					</Button>
					<span style={{ marginLeft: '16px' }}>
						<BackButton buttonText="Cancel" />
					</span>
				</Form.Item>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,
	createErrors: state.work_orders.createErrors,
	updateErrors: state.work_orders.updateErrors,
	formData: ownProps.formData,
	creating: state.work_orders.creating,
	updating: state.work_orders.updating,
	locations: state.locations,
	areas: state.areas,
	assets: state.assets,
	problemTypes: state.problem_types,
	spendCategories: state.spend_categories,
	workOrderPriorities: state.work_order_priorities,
	supplierFacilities: state.supplier_facilities,
	currentUser: state.session.currentUser,
	projects: state.projects,
	resolutionTypes: state.resolution_types,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchLocation: (id) => dispatch(locationsRestCrudThunksForSupplier.readOne(id)),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			locationsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleResolutionTypes: (ids, targetCollectionName) =>
		dispatch(resolutionTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchResolutionTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			resolutionTypesRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleAreas: (ids, targetCollectionName) =>
		dispatch(areasRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchAreas: (params, targetCollectionName, pagination, sorting, filters, addToTargetCollection) =>
		dispatch(
			areasRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchAsset: (id) => dispatch(assetsRestCrudThunksForSupplier.readOne(id)),
	fetchMultipleAssets: (ids, targetCollectionName) =>
		dispatch(assetsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchAssets: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			assetsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchProblemType: (id) => dispatch(problemTypesRestCrudThunksForSupplier.readOne(id)),
	fetchMultipleProblemTypes: (ids, targetCollectionName) =>
		dispatch(problemTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchProblemTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			problemTypesHeirarchicalForSupplier(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchSpendCategory: (id) => dispatch(spendCategoriesRestCrudThunksForSupplier.readOne(id)),
	fetchMultipleSpendCategories: (ids, targetCollectionName) =>
		dispatch(spendCategoriesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchApprovalHeirarchy: (id) =>
		dispatch(getBuyerCompanyApprovalTiers(ROLE_TYPES.SUPPLIER, null)()),
	fetchSpendCategories: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			spendCategoriesRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchWorkOrderAttributes: (params) =>
		dispatch(workOrderAttributesRestCrudThunksForSupplier.read(params)),
	fetchWorkOrderPriority: (id) =>
		dispatch(workOrderPrioritiesRestCrudThunksForSupplier.readOne(id)),
	fetchWorkOrderPriorities: (params) =>
		dispatch(
			workOrderPrioritiesRestCrudThunksForSupplier.read(
				params,
				WORK_ORDER_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE
			)
		),
	fetchSupplierFacility: (id) => dispatch(supplierFacilitiesRestCrudThunksForSupplier.readOne(id)),
	fetchSupplierFacilitiesInPrivateNetwork: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters
	) =>
		dispatch(
			getBuyerCompanyPrivateNetworksWithLocationsForSupplier(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters
			)
		),
	fetchMultipleSupplierFacilitiesInPrivateNetwork: (ids, targetCollectionName) =>
		dispatch(supplierFacilitiesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	updateWorkOrdersFilters: (filters, targetCollection) =>
		dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	updateWorkOrderForm: (workOrder) =>
		dispatch(workOrdersRestCrudThunksForSupplier.update(workOrder)),
	addWarrantySupplier: (supplierId, targetCollectionName) =>
		dispatch(addWarrantySupplierToStoreState(supplierId, targetCollectionName)),
	fetchExchangeRate: (c1, c2, userType) => dispatch(getExchangeRate(c1, c2)(userType)),
	fetchMultipleProjects: (ids, targetCollectionName) =>
		dispatch(projectsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchProjects: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			projectsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	willApprovalHierarchyChange: (workOrderId, params) =>
		dispatch(hasApprovalHierarchyChanged('supplier', workOrderId, params)),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<WorkOrderFormProps>()(SupplierWorkOrderEditSafeForm))
);

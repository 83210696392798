import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Checkbox, ConfigProvider, Empty, Form, List, message, Popover, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { debounce } from '../../utils/PerformanceUtils';
import { TextButton } from '../text_button/TextButton';
import { unionBy, differenceBy } from 'lodash';

export default function AddSupplierList({
	setDrawer,
	fetchAllSuppliers,
	suppliers = [],
	selectedItem,
	problemType,
	updateRank,
	onSubmit,
}) {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [showAll, setShowAll] = useState(false);

	useEffect(() => {
		fetchAllSuppliers({ problemTypeId: problemType.id }).then((data = []) => {
			const filteredData = differenceBy(data, suppliers, (item) => item.supplierFacility.id);
			if (filteredData && filteredData.length) {
				setData(differenceBy(data, suppliers, (item) => item.supplierFacility.id));
				setLoading(false);
			} else {
				fetchAllSuppliers().then((data) => {
					setData(differenceBy(data, suppliers, (item) => item.supplierFacility.id));
					setLoading(false);
					setShowAll(true);
				});
			}
		});
	}, []);

	const debouncedCallback = debounce(
		(searchText) => {
			setLoading(true);
			fetchAllSuppliers({
				supplierFacilityAlias: searchText && searchText.trim(),
				...(!showAll && { problemTypeId: problemType.id }),
			}).then((data) => {
				setData(differenceBy(data, suppliers, (item) => item.supplierFacility.id));
				setLoading(false);
			});
		},
		400,
		false
	);
	const handleSuppliersSearchDebounced = (e) => {
		const searchText = e.target.value;
		debouncedCallback(searchText);
	};

	return (
		<div className=" flex h-full flex-col">
			<div>
				<h6 className="mb-5 ml-0">Select suppliers for {problemType.hierarchyName} </h6>
			</div>

			<input
				onChange={handleSuppliersSearchDebounced}
				id="location"
				className=" inset-0 mb-4 block w-full rounded-sm border border-solid border-owgray p-3 text-[14px] leading-none shadow-sm outline-none placeholder:text-placeholder hover:border-buyer focus:border-buyer focus:shadow-input"
				placeholder="Search for a Supplier"
			/>

			<Form
				onFinish={({ suppliers: newSuppliers = [] }) => {
					if (newSuppliers.length < 1) {
						setDrawer(null);
						return;
					}
					const newList = newSuppliers.map((item, index) => ({
						...selectedItem,
						rank: suppliers.length + index + 1,
						supplierFacilityId: item,
						supplierFacility: (data.find((iterator) => iterator.supplierFacilityId === item) || {})
							.supplierFacility,
					}));
					const promises = [...suppliers, ...newList].map((item) => updateRank(item));
					Promise.all(promises).then(() => {
						message.success(
							<span>
								Added {newList.length} {newList.length === 1 ? 'supplier' : 'suppliers'} to{' '}
								{problemType.hierarchyName}
							</span>
						);
						onSubmit([...suppliers, ...newList]);
						setDrawer(null);
					});
				}}
				className="relative h-full"
			>
				<div className="h-full overflow-scroll pb-28 ">
					<div className="mb-4 flex flex-wrap gap-3">
						{suppliers.map((supplier) => (
							<Tag>{supplier.supplierFacility.name}</Tag>
						))}
					</div>
					<Form.Item name="suppliers">
						<Checkbox.Group>
							<ConfigProvider
								renderEmpty={() => <Empty description="There are no suppliers to be added" />}
							>
								<List
									dataSource={data}
									loading={loading}
									renderItem={(item: any) => (
										<div className="mb-4">
											<Checkbox className="flex items-center" value={item.supplierFacility.id}>
												<div className="flex flex-col ">
													<span className="font-medium">
														{item.supplierFacility.supplierFacilityAlias ||
															item.supplierFacility.displayName ||
															item.supplierFacility.name}
													</span>
													<span className="mt-1 inline-block text-sm text-gray-500">
														{nullSafeGet('supplierFacility.primaryAddress.streetAddress1', item)}{' '}
														{nullSafeGet('supplierFacility.primaryAddress.city', item)}{' '}
														{nullSafeGet('supplierFacility.primaryAddress.country', item)}{' '}
													</span>
												</div>
											</Checkbox>
											<br />
										</div>
									)}
								/>
							</ConfigProvider>
						</Checkbox.Group>
					</Form.Item>
					<span className="mt-0 inline-block text-base text-slate-700">
						<Button
							size="large"
							type="ghost"
							icon={
								<Popover
									overlayStyle={{ zIndex: 1060 }}
									content={
										showAll
											? 'Showing All Suppliers'
											: `Showing suppliers which are assigned to ${problemType.hierarchyName}`
									}
								>
									<InfoCircleOutlined />
								</Popover>
							}
							onClick={() => {
								fetchAllSuppliers({
									...(showAll && { problemTypeId: problemType.id }),
								}).then((data) => {
									setData(differenceBy(data, suppliers, (item) => item.supplierFacility.id));
									setLoading(false);
								});
								setShowAll(!showAll);
							}}
							className="cursor-pointer text-base text-buyer"
						>
							{showAll ? `Hide` : `Show All`}
						</Button>

						<TextButton></TextButton>
					</span>
				</div>
				<div className="absolute bottom-0 left-0 w-full bg-white pb-4">
					<Button
						disabled={loading}
						htmlType="submit"
						type="primary"
						size="large"
						className=" mt-10 w-full"
					>
						{loading && <LoadingOutlined />} Update Suppliers
					</Button>
				</div>
			</Form>
		</div>
	);
}

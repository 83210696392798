import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Select, Alert, InputNumber, Switch } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { connect } from 'react-redux';
import { getCurrency, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { vendorsRestCrudThunksForSupplier } from '../../thunks/vendors_thunks';
import { partCatalogsRestCrudThunksForSupplier } from '../../thunks/part_catalogs_thunks';
import { partsRestCrudThunksForSupplier } from '../../thunks/parts_thunks';
import OWRichTextEditor from '../../rich_text_editor/OWRichTextEditor';
import ChangeVendorCurrencyComponent from '../vendors_detail_details_page/ChangeVendorCurrencyComponent';

const FormItem = Form.Item;
export const DATE_FORMAT = 'MMMM D, YYYY h:mm a';

interface PartCatalogModalFormProps extends FormComponentProps {
	onSuccess: any;
	onCancel: any;
	createErrors: any;
	updateErrors: any;
	create: any;
	update: any;
	visible: boolean;
	formData: any;
	currencies: any;
	currentUser: any;
	vendors: any;
	targetCollectionName: any;
	fetchVendors: any;
	fetchMultipleVendors: any;
	loading: boolean;
	part: any;
	updatePart: any;
}

const PART_CATALOG_FORM_VENDORS_AUTOCOMPLETE = 'PART_CATALOG_FORM_VENDORS_AUTOCOMPLETE';

class PartCatalogModalForm extends React.Component<PartCatalogModalFormProps, any> {
	state = {
		editorRef: React.createRef(),
	};

	componentDidMount() {
		const { formData } = this.props;
		const notes = formData.notes;

		this.setState({ notes });
	}

	handleCancel = () => {
		const { onCancel, form } = this.props;
		form.resetFields();
		onCancel();
	};

	updateDefaultVendorIfApplicable = (values) => {
		return new Promise((resolve, reject) => {
			if (values.isDefaultVendor) {
				const { part, updatePart } = this.props;
				updatePart({
					...part,
					defaultVendorId: values.partEquipmentVendorId,
				})
					.then((res) => resolve(res))
					.catch((err) => reject(err));
			} else {
				resolve(true);
			}
		});
	};

	getNotes = () =>
		nullSafeGet('state.editorRef.current', this) &&
		(this.state.editorRef.current as any).getContent();

	getCurrencyId = () => {
		const { form, currentUser, vendors } = this.props;
		const { getFieldValue } = form;
		const currency = getCurrency({ currentUser });
		const vendorId = getFieldValue('partEquipmentVendorId');
		return nullSafeGetOrElse(`records.${vendorId}.currencyId`, vendors, currency.id);
	};

	handleSubmit = (e) => {
		const { targetCollectionName, form, create, update, onSuccess } = this.props;
		e.preventDefault();
		form.validateFields((err, values) => {
			if (!err) {
				const submitAction = values.id ? update : create;
				Promise.all([
					this.updateDefaultVendorIfApplicable(values),
					submitAction(
						{
							...values,
							notes: this.getNotes(),
							...(values.unitCost && { unitCost: `${values.unitCost}` }),
							...(values.listPrice && { listPrice: `${values.listPrice}` }),
							currencyId: this.getCurrencyId(),
						},
						targetCollectionName
					),
				]).then(() => {
					form.resetFields();
					onSuccess();
				});
			}
		});
	};

	render() {
		const {
			visible,
			form,
			formData,
			vendors,
			currentUser,
			fetchVendors,
			fetchMultipleVendors,
			createErrors,
			updateErrors,
			loading,
			part,
		} = this.props;
		const { getFieldDecorator, getFieldValue } = form;
		const isUpdate = !!formData.partEquipmentVendorId;
		const okText = isUpdate ? 'Update' : 'Create';
		const titleText = `${isUpdate ? 'Edit' : 'Add'} Vendor for Part`;
		getFieldDecorator(`id`, { initialValue: formData.id });
		getFieldDecorator(`partId`, { initialValue: formData.partId });
		getFieldDecorator(`supplierFacilityId`, {
			initialValue: formData.supplierFacilityId || nullSafeGet('facility.id', currentUser),
		});
		getFieldDecorator(`supplierCompanyId`, {
			initialValue:
				formData.supplierCompanyId || nullSafeGet('facility.supplierCompanyId', currentUser),
		});

		const isDefaultVendor =
			!!formData.partEquipmentVendorId &&
			formData.partEquipmentVendorId === nullSafeGet('defaultVendorId', part);

		const currencyId = this.getCurrencyId();

		return (
			<Modal
				visible={visible}
				width={600}
				title={titleText}
				okText={okText}
				onCancel={this.handleCancel}
				onOk={this.handleSubmit}
				closable={false}
				confirmLoading={loading}
			>
				<Form layout="vertical">
					{createErrors.length > 0 ? (
						<FormItem>
							<Alert message={createErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					{updateErrors.length > 0 ? (
						<FormItem>
							<Alert message={updateErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					<FormItem label="Vendor">
						{getFieldDecorator(`partEquipmentVendorId`, {
							rules: [
								{
									required: true,
									message: 'Please select a vendor.',
								},
							],
							initialValue: formData.partEquipmentVendorId,
						})(
							<OWAsyncSelect
								disabled={isUpdate}
								style={{ maxWidth: 320 }}
								stateSlice={vendors}
								targetCollectionName={PART_CATALOG_FORM_VENDORS_AUTOCOMPLETE}
								fetchMultiple={(ids, targetCollectionName) => {
									fetchMultipleVendors(ids, targetCollectionName);
								}}
								fetchData={(searchText, targetCollectionName) => {
									fetchVendors({ name: searchText }, targetCollectionName);
								}}
								renderRecord={(vendor) => (
									<Select.Option key={vendor.id} value={vendor.id}>
										{vendor.name}
									</Select.Option>
								)}
							/>
						)}
					</FormItem>
					<FormItem label="Is Default Vendor">
						{form.getFieldDecorator('isDefaultVendor', {
							initialValue: isDefaultVendor,
							valuePropName: 'checked',
						})(<Switch />)}
					</FormItem>
					<div className="flex flex-row items-center">
						<FormItem label="Last Price">
							{getFieldDecorator('unitCost', {
								initialValue: formData.unitCost,
							})(
								<InputNumber
									style={{ width: 320 }}
									min={0.0}
									precision={2}
									step={0.01}
									addonAfter={currencyId}
								/>
							)}
						</FormItem>
						<div>
							{getFieldValue('partEquipmentVendorId') ? (
								<ChangeVendorCurrencyComponent vendorId={getFieldValue('partEquipmentVendorId')} />
							) : null}
						</div>
					</div>
					<div className="flex flex-row items-center">
						<FormItem label="List Price">
							{getFieldDecorator('listPrice', {
								initialValue: formData.listPrice,
							})(
								<InputNumber
									style={{ width: 320 }}
									min={0.0}
									precision={2}
									step={0.01}
									addonAfter={currencyId}
								/>
							)}
						</FormItem>
						<div>
							{getFieldValue('partEquipmentVendorId') ? (
								<ChangeVendorCurrencyComponent vendorId={getFieldValue('partEquipmentVendorId')} />
							) : null}
						</div>
					</div>
					<FormItem label="Notes">
						{getFieldDecorator('notes', {
							initialValue: formData.notes,
						})(
							<OWRichTextEditor
								initialEditorState={formData.notes}
								editorRef={this.state.editorRef}
							/>
						)}
					</FormItem>
				</Form>
			</Modal>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSuccess: ownProps.onSuccess,
	formData: ownProps.formData,
	vendors: state.vendors,
	currencies: state.currencies,
	currentUser: state.session.currentUser,
	targetCollectionName: ownProps.targetCollectionName,
	createErrors: state.buyer_contacts.createErrors,
	updateErrors: state.buyer_contacts.updateErrors,
	loading: state.part_catalogs.updating || state.part_catalogs.creating,
	part: state.parts.detail,
});

const mapDispatchToProps = (dispatch) => ({
	create: (entity, targetCollectionName) =>
		dispatch(partCatalogsRestCrudThunksForSupplier.create(entity, 'id', targetCollectionName)),
	update: (entity) => dispatch(partCatalogsRestCrudThunksForSupplier.update(entity)),
	fetchVendors: (params, targetCollectionName) =>
		dispatch(
			vendorsRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName
			)
		),
	fetchMultipleVendors: (ids, targetCollectionName) =>
		dispatch(vendorsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	updatePart: (entity) => dispatch(partsRestCrudThunksForSupplier.update(entity)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create<PartCatalogModalFormProps>()(PartCatalogModalForm));

import { getBuyerCompanySettingValue } from './AuthUtils';
import { nullSafeGet } from './DataAccessUtils';
import { FILTER_CONFIG_NAMES } from './DataConstants';

/*
    1. Is internal supplier? => null
    2. Is asset warranty? => asset warranty nte
    3. Has existing NTE? => Existing NTE
    4. Has work order attributes NTE? => Work Order Attribute NTE
    5. Has no NTE? => undefined
 */
export const getNewLocationNTE = (
	hasSupplier,
	privateSupplier,
	assetWarrantyNTE,
	locationNTE,
	workOrderAttributesNTE,
	currencyExchangeRateBuyerToLocation
) =>
	!hasSupplier || privateSupplier
		? null //1. Is internal supplier? => null
		: assetWarrantyNTE
		? assetWarrantyNTE //2. Is asset warranty? => asset warranty nte
		: locationNTE
		? locationNTE // 3. Has existing NTE? => Existing NTE
		: workOrderAttributesNTE
		? workOrderAttributesNTE * currencyExchangeRateBuyerToLocation //4. Has work order attributes NTE? => Work Order Attribute NTE
		: undefined; //5. Has no NTE? => undefined

export const BUYER_WO_FILTERS_ORDER = [
	FILTER_CONFIG_NAMES.LOCATION,
	FILTER_CONFIG_NAMES.PRIORITY,
	FILTER_CONFIG_NAMES.ASSET_TYPE,
	FILTER_CONFIG_NAMES.PROBLEM_TYPE,
	FILTER_CONFIG_NAMES.SUPPLIER,
	FILTER_CONFIG_NAMES.RESOULTION_TYPE,
	FILTER_CONFIG_NAMES.CREATED_AT,
	FILTER_CONFIG_NAMES.COMPLETED_AT,
	FILTER_CONFIG_NAMES.IS_PAST_DUE,
	FILTER_CONFIG_NAMES.IS_PAST_SCHEDULE,
	FILTER_CONFIG_NAMES.IS_PAST_ESTIMATED_COMPLETION,
	FILTER_CONFIG_NAMES.IS_CAPEX,
	FILTER_CONFIG_NAMES.IS_STALE,
	FILTER_CONFIG_NAMES.IS_PLANNED_MAINTENANCE,
	FILTER_CONFIG_NAMES.IS_WARRANTY,
	FILTER_CONFIG_NAMES.IS_ASSET_DOWN,
	FILTER_CONFIG_NAMES.PM_SCHEDULE,
];

export const INTERNAL_SERVICE_TEAM_WO_FILTERS_ORDER = [
	FILTER_CONFIG_NAMES.LOCATION,
	FILTER_CONFIG_NAMES.TECHNICIAN,
	FILTER_CONFIG_NAMES.SUPPLIER,
	FILTER_CONFIG_NAMES.PRIORITY,
	FILTER_CONFIG_NAMES.ASSET_TYPE,
	FILTER_CONFIG_NAMES.PROBLEM_TYPE,
	FILTER_CONFIG_NAMES.RESOULTION_TYPE,
	FILTER_CONFIG_NAMES.CREATED_AT,
	FILTER_CONFIG_NAMES.COMPLETED_AT,
	FILTER_CONFIG_NAMES.IS_PAST_DUE,
	FILTER_CONFIG_NAMES.IS_PAST_SCHEDULE,
	FILTER_CONFIG_NAMES.IS_PAST_ESTIMATED_COMPLETION,
	FILTER_CONFIG_NAMES.IS_CAPEX,
	FILTER_CONFIG_NAMES.IS_STALE,
	FILTER_CONFIG_NAMES.IS_PLANNED_MAINTENANCE,
	FILTER_CONFIG_NAMES.IS_WARRANTY,
	FILTER_CONFIG_NAMES.IS_ASSET_DOWN,
	FILTER_CONFIG_NAMES.PM_SCHEDULE,
];

export const THIRD_PARTY_SUPPLIER_WO_FILTERS_ORDER = [
	FILTER_CONFIG_NAMES.LOCATION,
	FILTER_CONFIG_NAMES.TECHNICIAN,
	FILTER_CONFIG_NAMES.PRIORITY,
	FILTER_CONFIG_NAMES.TRADE,
	FILTER_CONFIG_NAMES.CREATED_AT,
	FILTER_CONFIG_NAMES.COMPLETED_AT,
	FILTER_CONFIG_NAMES.IS_STALE,
	FILTER_CONFIG_NAMES.IS_PLANNED_MAINTENANCE,
	FILTER_CONFIG_NAMES.IS_WARRANTY,
];

export const getAssetFilterForWOCreation = (userType) => (companyConfig, currentUser, state) => {
	const areaAllowed = getBuyerCompanySettingValue(userType)(
		'assetConfig.allowAreaAssociationWithAssets',
		companyConfig,
		currentUser,
		false
	);

	return {
		locationId: nullSafeGet('location.id', state),
		problemTypeId: nullSafeGet('problemType.id', state),
		...(areaAllowed &&
			nullSafeGet('area.id', state) && {
				areaIdOrNull: nullSafeGet('area.id', state),
			}),
	};
};

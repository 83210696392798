import React, { FC, useMemo } from 'react';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { Tag } from 'antd';
import HyperLink, { ENTITY_TYPE } from '../common/HyperLink';

export const PartEquipmentTagWithName = ({ isEquipmentLine, name }) => {
	const tag = useMemo(
		() =>
			isEquipmentLine ? (
				<Tag color="#e8def8" style={{ color: '#4a1e87' }}>
					Eq
				</Tag>
			) : (
				<Tag color="rgb(225, 229, 249)" style={{ color: 'rgb(11, 28, 111)' }}>
					P
				</Tag>
			),
		[isEquipmentLine]
	);

	return (
		<div className="flex flex-row items-center">
			{tag}
			{name}
		</div>
	);
};

const PartEquipmentNameWithType: FC<any> = ({ record, backText }): React.ReactElement => {
	const entity = useMemo(
		() => ({
			id: record.isEquipmentLine
				? nullSafeGetOrElse('equipmentType.id', record, '--')
				: nullSafeGetOrElse('part.id', record, '--'),
			name: record.isEquipmentLine
				? nullSafeGetOrElse('equipmentType.modelName', record, '--')
				: nullSafeGetOrElse('part.name', record, '--'),
			type: record.isEquipmentLine ? ENTITY_TYPE.EQUIPMENT : ENTITY_TYPE.PART,
		}),
		[record]
	);

	return (
		<PartEquipmentTagWithName
			isEquipmentLine={record.isEquipmentLine}
			name={
				<HyperLink
					text={entity.name}
					entityType={entity.type}
					entityId={entity.id}
					backLinkText={backText || 'Back'}
				/>
			}
		/>
	);
};

export default PartEquipmentNameWithType;

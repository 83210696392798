import * as React from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Alert, Button, Modal, Select, InputNumber, message, Switch } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import {
	getCurrency,
	getObjectValues,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import BackButton from '../back_button/BackButton';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import { getProtectedImageUriForSupplier } from '../../utils/FileAccessUtils';
import { imageUploadValidation, normFile } from '../../utils/ImageUtils';
import { partsRestCrudThunksForSupplier } from '../../thunks/parts_thunks';
import { unitsOfMeasureRestCrudThunksForSupplier } from '../../thunks/units_of_measure_thunks';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { vendorsRestCrudThunksForSupplier } from '../../thunks/vendors_thunks';
import { replacePartVendorsForSupplier } from '../../thunks/part_catalogs_thunks';
import { retrieveCachedUserDetails } from '../../thunks/session_thunks';
import { PERMISSION_NAMES, isSupplierAllowedToAccess } from '../../utils/AuthUtils';
import OWUpload from '../OWUpload';

require('./PartForm.less');

interface PartFormProps extends FormComponentProps {
	creating: boolean;
	updating: boolean;
	formData: any;
	history: any;
	redirectForwardUrl?: string;
	onSuccess?: any;
	createErrors: any[];
	updateErrors: any[];
	currentUser: any;
	create: any;
	update: any;
	replaceVendors: any;
	unitsOfMeasure: any;
	fetchUnitsOfMeasure: any;
	fetchVendors: any;
	fetchMultipleVendors: any;
	vendors: any;
	createVendor: any;
	onCancel?: any;
	companyConfig: any;
}

class PartForm extends React.Component<PartFormProps, any> {
	state = {
		previewVisible: false,
		uploadLoading: false,
		previewImage: null,
		allPhotos: [],
		replacingVendors: false,
	};

	componentDidMount() {
		const { fetchUnitsOfMeasure, formData } = this.props;
		fetchUnitsOfMeasure({});

		let photosInfo;
		if (formData.images) {
			photosInfo = formData.images.map((p, idx) => {
				const [imgFileId, imgFileName] = p.split('/');
				const thumbUri = getProtectedImageUriForSupplier(imgFileId, imgFileName, 100, 100);
				const fullUri = getProtectedImageUriForSupplier(imgFileId, imgFileName, 400, 400);
				return {
					uid: idx,
					size: 1,
					name: imgFileName,
					filename: imgFileName,
					url: fullUri,
					status: 'done',
					thumbUrl: thumbUri,
					response: { data: { fileName: imgFileName, fileId: imgFileId } },
					type: 'image/jpeg',
				};
			});
		} else {
			photosInfo = [];
		}
		this.setState({ allPhotos: photosInfo });
	}

	onSubmitSuccess = (record) => {
		const { history, onSuccess, redirectForwardUrl } = this.props;
		if (redirectForwardUrl) {
			history.push(redirectForwardUrl);
		}
		if (onSuccess) {
			onSuccess(record);
		}
	};

	handleSubmit = (e, submitAction) => {
		const { replaceVendors } = this.props;
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const picturesInfo = values.images.map((p) => {
					const fileName = nullSafeGetOrElse('response.data.fileName', p, '');
					const fileId = nullSafeGetOrElse('response.data.fileId', p, '');
					return `${fileId}/${fileName}`;
				});

				const partCatalogs = nullSafeGetOrElse('partEquipmentVendorIds', values, []).map(
					(vendorId) => {
						return {
							partEquipmentVendorId: vendorId,
							currencyId: 'USD',
							supplierFacilityId: values.supplierFacilityId,
							supplierCompanyId: values.supplierCompanyId,
						};
					}
				);

				const part = {
					id: values.id,
					isActive: values.isActive,
					name: values.name,
					description: values.description,
					images: picturesInfo,
					manufacturerId: values.manufacturerId,
					manufacturerName: values.manufacturerName,
					partNumber: values.partNumber,
					...(values.aliasPartId && { aliasPartId: values.aliasPartId }),
					...(values.cost && { cost: parseFloat(values.cost) }),
					uomId: values.uomId,
					supplierCompanyId: values.supplierCompanyId,
					defaultVendorId: values.defaultVendorId,
				};

				submitAction(part)
					.then((record) => {
						if (partCatalogs.length > 0) {
							const updatedPartCatalog = partCatalogs.map((partCatalog) => {
								return {
									...partCatalog,
									partId: record.id,
								};
							});
							this.setState({ replacingVendors: true });
							replaceVendors(record.id, updatedPartCatalog)
								.then((vendors) => {
									this.onSubmitSuccess(record);
								})
								.finally(() => this.setState({ replacingVendors: true }));
						} else this.onSubmitSuccess(record);
					})
					.catch((err) => message.error(err));
			}
		});
	};

	handlePreview = (file) => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true,
		});
	};

	handleCancel = () => this.setState({ previewVisible: false });

	handleImageUpdate = (info) => {
		if (info.file.status === 'done') {
			this.setState({ allPhotos: info.fileList });
		} else if (info.file.status === undefined) {
			info.fileList = this.state.allPhotos;
		}
	};

	render() {
		const {
			updating,
			creating,
			form,
			update,
			create,
			createErrors,
			formData,
			updateErrors,
			currentUser,
			unitsOfMeasure,
			fetchVendors,
			fetchMultipleVendors,
			vendors,
			createVendor,
			onCancel,
			companyConfig,
		} = this.props;
		const { previewVisible, previewImage } = this.state;
		const { getFieldDecorator } = form;
		const isUpdate = formData && formData.id !== undefined;
		const submitAction = isUpdate ? update : create;
		const submitText = isUpdate ? 'Update part' : 'Create part';
		const currency = getCurrency({ currentUser });
		const isLoading = updating || creating;

		const unitsOfMeasureRecords = getObjectValues(unitsOfMeasure.records);

		const BACKEND_URI = getBackendUri();
		const uploadHeaders = { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token };

		getFieldDecorator('supplierFacilityId', {
			initialValue: nullSafeGet('facility.id', currentUser),
		});
		getFieldDecorator('supplierCompanyId', {
			initialValue:
				formData.supplierCompanyId || nullSafeGet('facility.supplierCompanyId', currentUser),
		});

		getFieldDecorator('id', { initialValue: formData.id });

		const canCreateVendors = isSupplierAllowedToAccess(
			PERMISSION_NAMES.MODIFY_VENDORS,
			companyConfig,
			currentUser.roles
		);

		return (
			<Form
				layout="vertical"
				onSubmit={(e) => this.handleSubmit(e, submitAction)}
				className="supplierForm"
			>
				{createErrors.length > 0 ? (
					<Form.Item>
						<Alert message={createErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				{updateErrors.length > 0 ? (
					<Form.Item>
						<Alert message={updateErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				<Form.Item label="Name">
					{getFieldDecorator('name', {
						initialValue: formData.name,
						rules: [{ required: true, message: 'Please enter name of the part.' }],
					})(<Input style={{ maxWidth: 640 }} />)}
				</Form.Item>
				<Form.Item label="Is Active">
					{getFieldDecorator('isActive', {
						initialValue: formData.isActive === undefined ? true : formData.isActive,
						valuePropName: 'checked',
					})(<Switch />)}
				</Form.Item>
				<Form.Item label="Part Number">
					{getFieldDecorator('partNumber', {
						initialValue: formData.partNumber,
						rules: [{ required: true, message: 'Please enter part number.' }],
					})(<Input style={{ maxWidth: 640 }} />)}
				</Form.Item>
				<Form.Item label="Alias Part Number">
					{getFieldDecorator('aliasPartId', {
						initialValue: formData.aliasPartId,
					})(<Input style={{ maxWidth: 640 }} />)}
				</Form.Item>
				<Form.Item label="Description">
					{getFieldDecorator('description', {
						initialValue: formData.description,
					})(<Input.TextArea style={{ maxWidth: 640 }} />)}
				</Form.Item>
				<Form.Item label="Vendors">
					{getFieldDecorator('partEquipmentVendorIds', {
						initialValue: formData.partEquipmentVendorIds,
					})(
						<OWAsyncSelect
							style={{ maxWidth: 640 }}
							mode="multiple"
							stateSlice={vendors}
							targetCollectionName="PART_VENDORS_AUTOCOMPLETE"
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleVendors(ids, targetCollectionName);
							}}
							fetchData={(searchText, targetCollectionName) => {
								fetchVendors({ name: searchText }, targetCollectionName);
							}}
							placeholder={'Select Vendors'}
							createNewEntity={
								canCreateVendors ? (name) => createVendor({ name, currencyId: 'USD' }) : undefined
							}
							renderRecord={(vendor) => (
								<Select.Option key={vendor.id} value={vendor.id}>
									{vendor.name}
								</Select.Option>
							)}
						/>
					)}
				</Form.Item>
				<Form.Item label="Default Vendor">
					{getFieldDecorator('defaultVendorId', {
						initialValue: formData.defaultVendorId,
					})(
						<OWAsyncSelect
							style={{ maxWidth: 640 }}
							stateSlice={vendors}
							targetCollectionName="defaultPartEquipmentVendors"
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleVendors(ids, targetCollectionName);
							}}
							fetchData={(searchText, targetCollectionName) => {
								fetchVendors({ name: searchText }, targetCollectionName);
							}}
							placeholder={'Select Default Vendor'}
							createNewEntity={
								canCreateVendors ? (name) => createVendor({ name, currencyId: 'USD' }) : undefined
							}
							renderRecord={(vendor) => (
								<Select.Option key={vendor.id} value={vendor.id}>
									{vendor.name}
								</Select.Option>
							)}
						/>
					)}
				</Form.Item>
				<Form.Item label="Manufacturer Name">
					{getFieldDecorator('manufacturerName', {
						initialValue: formData.manufacturerName,
					})(<Input style={{ maxWidth: 640 }} />)}
				</Form.Item>
				<Form.Item label="Manufacturer Id">
					{getFieldDecorator('manufacturerId', {
						initialValue: formData.manufacturerId,
					})(<Input style={{ maxWidth: 640 }} />)}
				</Form.Item>
				<Form.Item label="Unit of Measure">
					{getFieldDecorator('uomId', {
						initialValue: formData.uomId,
						rules: [{ required: true, message: 'Please select unit of measure.' }],
					})(
						<Select style={{ maxWidth: 640 }}>
							{unitsOfMeasureRecords.map((uom) => (
								<Select.Option key={uom.id} value={uom.id}>
									{uom.displayNameSingular}
								</Select.Option>
							))}
						</Select>
					)}
				</Form.Item>
				<Form.Item label="Cost">
					{getFieldDecorator('cost', {
						initialValue: formData.cost,
					})(
						<InputNumber
							style={{ minWidth: 120 }}
							min={0.0}
							formatter={(value) => `${value}`}
							precision={2}
							step={0.01}
							addonAfter={currency.id}
							// parser={value => parseFloat(value.replace(/\$/, ''))}
						/>
					)}
				</Form.Item>
				<Form.Item label="Photos">
					{getFieldDecorator(`images`, {
						valuePropName: 'fileList',
						getValueFromEvent: normFile,
						initialValue: this.state.allPhotos,
					})(
						<OWUpload
							accept="image/*"
							name="file"
							listType="picture-card"
							className="avatar-uploader"
							multiple={true}
							headers={uploadHeaders}
							onPreview={this.handlePreview}
							action={`${BACKEND_URI}/api/v1/supplier/file/upload`}
							beforeUpload={imageUploadValidation}
							onChange={this.handleImageUpdate}
						>
							<div style={{ display: 'inline' }}>
								<LegacyIcon type={this.state.uploadLoading ? 'loading' : 'plus'} />
								<div className="ant-upload-text">Upload</div>
							</div>
							<Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
								<img alt="example" style={{ width: '100%' }} src={previewImage} />
							</Modal>
						</OWUpload>
					)}
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						loading={isLoading || this.state.replacingVendors}
						className="contactForm__button"
					>
						{submitText}
					</Button>
					<span style={{ marginLeft: '16px' }}>
						{onCancel ? (
							<Button type="ghost" onClick={onCancel}>
								Cancel
							</Button>
						) : (
							<BackButton buttonText="Cancel" />
						)}
					</span>
				</Form.Item>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	formData: ownProps.formData,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,

	createErrors: state.parts.createErrors,
	updateErrors: state.parts.updateErrors,
	creating: state.parts.creating,
	updating: state.parts.updating,
	unitsOfMeasure: state.units_of_measure,
	currentUser: state.session.currentUser,
	vendors: state.vendors,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	update: (entity) => dispatch(partsRestCrudThunksForSupplier.update(entity)),
	create: (entity) => dispatch(partsRestCrudThunksForSupplier.create(entity)),
	replaceVendors: (partId, entities) => dispatch(replacePartVendorsForSupplier(partId, entities)),
	fetchUnitsOfMeasure: (params) => dispatch(unitsOfMeasureRestCrudThunksForSupplier.read(params)),
	fetchVendors: (params, targetCollectionName) =>
		dispatch(
			vendorsRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName
			)
		),
	fetchMultipleVendors: (ids, targetCollectionName) =>
		dispatch(vendorsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	createVendor: (entity) => dispatch(vendorsRestCrudThunksForSupplier.create(entity)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Form.create<PartFormProps>()(PartForm))
);

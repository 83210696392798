import React, { useCallback, useMemo } from 'react';
import ScrollToTopOnMount from '../scroll_to_top_on_mount/ScrollToTopOnMount';
import { Redirect, Route, withRouter } from 'react-router';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import SubnavTabs from '../subnav_tabs/SubnavTabs';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';

const { Content } = Layout;

const InvoicesSavedFiltersViewOverviewPage = ({
	childRoutes,
	history,
	companyConfig,
}): React.ReactElement => {
	const handleTabChange = useCallback(
		(key) => {
			history.push(`/buyer/invoices/savedViews/overview/${key}`);
		},
		[history]
	);

	const checkTabActive = useCallback(
		(key) => {
			const pathname = history.location.pathname;
			return pathname.indexOf(key) !== -1;
		},
		[history]
	);

	const tabs = useMemo(
		() => [
			{
				key: 'all',
				name: 'All',
			},
		],
		[]
	);

	return (
		<div>
			<ScrollToTopOnMount />
			<div>
				<Route
					path="/buyer/invoices/savedViews/overview"
					exact={true}
					render={() => (
						<Redirect
							to={{
								pathname: '/buyer/invoices/savedViews/overview/all',
							}}
						/>
					)}
				/>
				<Content style={{ padding: '0 0.5em' }}>
					<div
						style={{
							margin: '0 -8px',
							padding: '24px',
							paddingBottom: 0,
							background: nullSafeGet('detail.config.theme.navblock', companyConfig),
							marginBottom: 8,
						}}
					>
						<h4
							style={{
								color: 'rgba(255, 255, 255, 0.9)',
								marginRight: 8,
								marginBottom: 24,
							}}
						>
							Invoices Saved Views
						</h4>
						<SubnavTabs tabs={tabs} onChange={handleTabChange} isActive={checkTabActive} />
					</div>
					{childRoutes.map((r, i) => (
						<RouteWithSubRoutes key={i} {...r} />
					))}
				</Content>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	companyConfig: state.company_config,
});

const mapDispatchToProps = () => ({});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(InvoicesSavedFiltersViewOverviewPage)
);

import { crudActionCreatorsGenerator } from './crud_action_creators_generator';
import { crudActionConstantsGenerator } from './crud_action_constants_generator';

export const EVENT_SUPPLIER_TEAMS_CRUD_ACTION_CONSTANTS =
	crudActionConstantsGenerator('EVENT_SUPPLIER_TEAMS');
export const EVENT_SUPPLIER_TEAMS_CRUD_ACTION_CREATORS = crudActionCreatorsGenerator(
	EVENT_SUPPLIER_TEAMS_CRUD_ACTION_CONSTANTS
);

export const ADD_CONTACT_TO_EVENT_SUPPLIER_TEAM_FORM_DATA =
	'ADD_CONTACT_TO_EVENT_SUPPLIER_FORM_DATA';

export const addContactToEventSupplierTeamFormData = (eventSupplierTeam) => ({
	eventSupplierTeam,
	type: ADD_CONTACT_TO_EVENT_SUPPLIER_TEAM_FORM_DATA,
});

import * as React from 'react';
import { connect } from 'react-redux';
import { getEntitiesById } from '../../utils/DataAccessUtils';
import { isEqual } from 'lodash';
import { withRouter } from 'react-router';
import EventCalendar from '../event_calendar/EventCalendar';
import moment from 'moment';

const queryString = require('qs');

interface ReduxCalendarProps {
	location: any;
	history: any;
	match: any;
	collectionName: string;
	targetCollectionName: string;
	initialDateRange: any;
	updateFilters: any;
	data: any[];
	renderFunction: any[];
	additionalParams: any;
	updateQueryParams?: boolean;
	filters: any;
	sorting: any;
	emptyState?: any;
	transformRecords: any;
	initialFilters: any;
	EventComponent: any;
	fetching: boolean;
	fetchData: (
		params: any,

		targetCollectionName: string | null,
		sorting: { sort_by: string; order: string } | {},
		filters: any,
		addToTargetCollection: boolean
	) => any;
}

class ReduxCalendar extends React.Component<ReduxCalendarProps, any> {
	constructor(props) {
		super(props);
		this.handleCalendarChange = this.handleCalendarChange.bind(this);
		this.state = { initialLoading: true };
	}

	handleCalendarChange(dateResponse) {
		const {
			fetchData,
			updateFilters,
			history,
			location,
			updateQueryParams,
			targetCollectionName,
			filters,
			sorting,
			additionalParams = {},
		} = this.props;
		let startDate, endDate;
		console.log(dateResponse);
		if (Array.isArray(dateResponse)) {
			if (updateQueryParams) {
				const queryParams = queryString.stringify({ ...filters, ...additionalParams });
				history.replace(`${location.pathname}?${queryParams}`);
			}
			if (dateResponse.length === 1) {
				startDate = moment(dateResponse[0]).subtract(1, 'day').format('YYYY-MM-DD');
				endDate = moment(dateResponse[0]).add(1, 'day').format('YYYY-MM-DD');
			} else {
				startDate = moment(dateResponse[0]).format('YYYY-MM-DD');
				endDate = moment(dateResponse[dateResponse.length - 1]).format('YYYY-MM-DD');
			}
		} else if (typeof dateResponse == 'object' && dateResponse.start && dateResponse.end) {
			if (updateQueryParams) {
				const queryParams = queryString.stringify({ ...filters, ...additionalParams });
				history.replace(`${location.pathname}?${queryParams}`);
			}
			startDate = moment(dateResponse.start).format('YYYY-MM-DD');
			endDate = moment(dateResponse.end).format('YYYY-MM-DD');
		} else {
			return;
		}

		updateFilters(
			{
				startDate,
				endDate,
			},
			targetCollectionName
		);
	}

	componentWillReceiveProps(nextProps) {
		const {
			fetchData,
			updateQueryParams,
			history,
			location,
			fetching,
			targetCollectionName,
			additionalParams,
			filters,
			sorting,
		} = this.props;
		const filtersChanged = !isEqual(nextProps.filters, filters);
		const sortingChanged = !isEqual(nextProps.sorting, sorting);
		const additionalParamsChanged = !isEqual(nextProps.additionalParams, additionalParams);
		const propsChanged =
			this.state.initialLoading || filtersChanged || sortingChanged || additionalParamsChanged;
		if (propsChanged && !fetching) {
			if (updateQueryParams) {
				const queryParams = queryString.stringify({
					...nextProps.sorting,
					...nextProps.filters,
					...nextProps.additionalParams,
				});
				history.replace(`${location.pathname}?${queryParams}`);
			}
			fetchData(
				nextProps.additionalParams || {},

				targetCollectionName,
				nextProps.sorting,
				nextProps.filters,
				false
			).then(() => {
				if (this.state.initialLoading) {
					this.setState({ initialLoading: false });
				}
			});
		}
	}

	render() {
		const {
			emptyState,
			data,
			initialDateRange,
			fetching,
			filters,
			EventComponent,
			sorting,
			transformRecords,
		} = this.props;

		const calendarEvents = transformRecords(data);

		return (
			<EventCalendar
				events={calendarEvents}
				defaultDate={
					initialDateRange && Array.isArray(initialDateRange) && initialDateRange.length === 2
						? new Date((initialDateRange[0].getTime() + initialDateRange[1].getTime()) / 2.0)
						: undefined
				}
				onRangeChange={this.handleCalendarChange}
				components={{
					event: EventComponent,
				}}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const { targetCollectionName, collectionName } = ownProps;
	const relevantState = state[collectionName];
	if (!relevantState) {
		throw new Error(
			'collection not found in root reducer. please check name for typos or add reducer for collection'
		);
	}

	if (targetCollectionName && !relevantState[targetCollectionName]) {
		throw new Error(
			'target collection not found in root reducer. please check name for typos or add reducer for collection'
		);
	}

	let mappedProps = {
		emptyState: ownProps.emptyState,
		collectionName: ownProps.collectionName,
		targetCollectionName: ownProps.targetCollectionName,
		updateQueryParams: ownProps.updateQueryParams,
		transformRecords: ownProps.transformRecords,
		EventComponent: ownProps.EventComponent,
		updateFilters: ownProps.updateFilters,
		initialDateRange: ownProps.initialDateRange,
		data: null,
		filters: null,
		sorting: null,
		fetching: null,
	};

	let relevantRecords;
	if (ownProps.useDistinctCollection) {
		relevantRecords = relevantState.distinctRecords;
	} else {
		relevantRecords = relevantState.records;
	}
	if (targetCollectionName) {
		mappedProps.data = getEntitiesById(
			relevantState[ownProps.targetCollectionName].recordsIds,
			relevantRecords
		);
		mappedProps.filters = relevantState[ownProps.targetCollectionName].filters;
		mappedProps.sorting = relevantState[ownProps.targetCollectionName].sorting;
		mappedProps.fetching = relevantState[ownProps.targetCollectionName].fetching;
	} else {
		mappedProps.data = getEntitiesById(relevantState.recordsIds, relevantRecords);
		mappedProps.filters = relevantState.filters;
		mappedProps.sorting = relevantState.sorting;
		mappedProps.fetching = relevantState.fetching;
	}

	return mappedProps;
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchData: (params, targetCollectionName, sorting, filters, addToTargetCollection) =>
		dispatch(
			ownProps.fetchData(params, targetCollectionName, sorting, filters, addToTargetCollection)
		),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReduxCalendar));

import React, { FC, useCallback, useMemo } from 'react';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { EmptyState } from '../empty_state/EmptyState';
import { WalkthroughRowDisplay } from '../walkthrough_row_display/WalkthroughRowDisplay';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import {
	walkthroughsRestCrudThunksForBuyer,
	walkthroughsRestCrudThunksForSupplier,
} from '../../thunks/walkthroughs_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { getStatSliceProp } from '../../utils/DataAccessUtils';

const SITE_SURVEY_ASSOCIATED_WORKTHROUGHS = 'siteSurveyAssociatedWalkthroughs';
const pageSize = 5;

const SiteSurveyWalkthroughSection: FC<any> = ({
	walkthroughs,
	currentUser,
	location,
	history,
	match,
}): React.ReactElement => {
	const userType = useMemo(() => currentUser.userType, [currentUser.userType]);

	const siteSurveyId = useMemo(() => match.params.id, [match.params.id]);

	const walkthroughsColumns = useMemo(
		() => [
			{
				title: 'Name',
				dataIndex: 'title',
				render: (_, record) => <WalkthroughRowDisplay walkthrough={record} />,
			},
		],
		[]
	);

	const onWalkthroughsRow = useCallback(
		(record) => ({
			onClick: () => {
				let searchString = location.search;
				if (searchString[0] === '?') {
					searchString = searchString.slice(1);
				}
				const path = `/${userType}/siteSurveys/walkthrough/detail/${record.id}/details`;
				history.push(getLinkWIthBackLinkParams(location, 'Back to Site Survey', path));
			},
		}),
		[history, location, userType]
	);

	const onSeeAll = useCallback(() => {
		history.push(`/${userType}/siteSurveys/detail/${siteSurveyId}/walkthroughs`);
	}, [history, siteSurveyId, userType]);

	const pagination = getStatSliceProp(
		'pagination',
		SITE_SURVEY_ASSOCIATED_WORKTHROUGHS,
		walkthroughs
	);

	return (
		<>
			<PaginatedReduxTable
				showHeader={false}
				emptyState={
					<EmptyState
						graphic={
							<img
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
								alt="You haven't conducted any walkthroughs yet."
							/>
						}
						headline={"You haven't conducted any walkthroughs yet."}
						body={
							<div style={{ textAlign: 'center' }}>
								<div style={{ maxWidth: 440, marginBottom: 16 }}></div>
							</div>
						}
					/>
				}
				collectionName="walkthroughs"
				targetCollectionName={SITE_SURVEY_ASSOCIATED_WORKTHROUGHS}
				columns={walkthroughsColumns}
				onRow={onWalkthroughsRow}
				keyAccessor={(el) => el.id}
				initialFilters={{ siteSurveyId }}
				initialPagination={{ current: 1, pageSize }}
				hidePagination
				fetchData={
					userType === ROLE_TYPES.SUPPLIER
						? walkthroughsRestCrudThunksForSupplier.read
						: walkthroughsRestCrudThunksForBuyer.read
				}
			/>
			{pagination.total > pageSize ? (
				<Button
					onClick={onSeeAll}
					type="link"
					style={{
						width: '100%',
						borderBottomLeftRadius: 4,
						borderBottomRightRadius: 4,
						padding: 12,
						height: 40,
						borderTop: '1px solid #e8e8e8',
						textAlign: 'center',
					}}
				>
					See all
				</Button>
			) : (
				<div />
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
	walkthroughs: state.walkthroughs,
});

export default withRouter(connect(mapStateToProps)(SiteSurveyWalkthroughSection));

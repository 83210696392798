import * as React from 'react';
import { Button, Card, Col, Row, Select, Table } from 'antd';
import { getCurrency, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import moment from 'moment';
import { dateFormatter, transformTypesTracked } from '../../utils/DataFormatterUtils';
import { budgetsRestCrudThunksForBuyer } from '../../thunks/budget_thunks';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { useMemo } from 'react';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import BudgetHeaderDisplayRow from '../budget_header_display_row/BudgetHeaderDisplayRow';
import { generateBackLink } from '../../utils/HistoryUtils';
import { updateNavbarState } from '../../actions/navbar_actions';
import BudgetCycleComponent from '../budget_cycle_component/BudgetCycleComponent';
import { BudgetDetailChart } from '../charts/BudgetDetailChart';

function BudgetDetailLocationsPage({
	budget,

	currentUser,
	match,
	history,
	loading,
	location,

	updateNavbar,
}) {
	if (loading) {
		return <PageLoadingPlaceholder />;
	}
	const locationId = parseInt(match.params.locationId);
	const currency = getCurrency({ currentUser });

	const columns = [
		{
			title: 'Date',
			dataIndex: 'transactionDate',
			render: (text, record) => dateFormatter(text, 'MMM DD, YYYY'),
			sorter: (a, b) => moment(a.transactionDate).valueOf() - moment(b.transactionDate).valueOf(),
			width: 120,
		},
		{
			title: 'Title',
			dataIndex: 'title',
			render: (text, record) => text,
			sorter: (a, b) => a.title.localeCompare(b.title),
			width: 300,
		},
		{
			title: 'Service Provider',
			dataIndex: 'supplierFacilityDisplayName',
			render: (text, record) => text,
			sorter: (a, b) => a.supplierFacilityDisplayName.localeCompare(b.supplierFacilityDisplayName),
			width: 340,
		},
		{
			title: 'Type',
			dataIndex: 'type',
			render: (text, record) => transformTypesTracked(text, true, false),
			sorter: (a, b) => a.type.localeCompare(b.type),
			width: 160,
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			render: (text, record) => currency.format(text),
			sorter: (a, b) => a.amount - b.amount,
			width: 200,
		},
		{
			title: '',
			dataIndex: 'type',
			render: (text, record) => {
				const type = transformTypesTracked(text, false, true).replace(' ', '').toLowerCase();
				return (
					<Button onClick={() => history.push(`/buyer/${type}/detail/${record.entityId}/details`)}>
						View {transformTypesTracked(text, false, false)}
					</Button>
				);
			},
		},
	];

	const locationBudget =
		locationId !== 0
			? budget.budgetPerLocations.find((budget) => locationId === budget.locationId)
			: { ...budget, budget: budget.sharedBudget };

	const items = locationBudget.budgetAccruedItems.concat(locationBudget.budgetSpentItems);

	const data =
		(locationBudget &&
			locationBudget.budgetSpentTimeSeriesData &&
			locationBudget.budgetSpentTimeSeriesData.map((d, i) => ({
				day: d.dayNumber,
				dayKey: `${d.date}`,
				consumedAmount: 0,
				totalConsumedAmount: moment().diff(moment(d.date)) >= 0 ? parseFloat(d.total) : undefined,
			}))) ||
		[];

	const budgetSpent = parseFloat(nullSafeGetOrElse('budgetSpent', locationBudget, 0));

	const budgetData = locationBudget;
	const budgetLimit = nullSafeGetOrElse(
		'budget',
		locationBudget,
		nullSafeGetOrElse('sharedBudget', locationBudget, 0)
	);
	const budgetMax = Math.max(budgetLimit, budgetSpent);
	const budgetChartConfig = {
		xAxisName: 'dayKey',
		yAxisName: 'totalConsumedAmount',
		position: 'dayKey*totalConsumedAmount',
		fillArea: false,
		cols: {
			dayKey: { alias: 'Day' },
			totalConsumedAmount: {
				min: 0,
				max: budgetMax,
				alias: 'Budget Spent',
			},
		},
		yAxisLabel: {
			textStyle: {
				fontSize: '12',
				fontFamily: 'Roboto',
				fill: 'rgba(0,0,0,0.85)',
			},
			formatter: (val, item) => {
				return currency.format(val);
			},
		},
		xAxisLabel: {
			textStyle: {
				fontSize: '12',
				fontFamily: 'Roboto',
				fill: 'rgba(0,0,0,0.85)',
			},
		},
	};

	return (
		<Row style={{ margin: '8px 4px' }} gutter={8}>
			<Col className={'rowSpacing'} span={24}>
				<Row className={'items-baseline justify-between'}>
					<BudgetCycleComponent budget={locationBudget} />
				</Row>
			</Col>
			<Col span={24} className={'rowSpacing'}>
				<BudgetHeaderDisplayRow
					budgetPerLocations={[locationBudget]}
					currency={currency}
					sharedBudget={nullSafeGetOrElse('sharedBudget', budget, 0)}
				/>
			</Col>
			<Col className={'rowSpacing'} span={24}>
				<Card>
					<BudgetDetailChart
						height={500}
						chartPadding={[40, 40, 40, 40]}
						data={data || []}
						xAxisName={nullSafeGet('xAxisName', budgetChartConfig)}
						yAxisName={nullSafeGet('yAxisName', budgetChartConfig)}
						yAxisLabel={nullSafeGet('yAxisLabel', budgetChartConfig)}
						fillArea={nullSafeGet('fillArea', budgetChartConfig)}
						cols={nullSafeGet('cols', budgetChartConfig)}
						position={nullSafeGet('position', budgetChartConfig)}
						currency={currency}
						budget={budgetData}
					/>
				</Card>
			</Col>
			<Col span={24} className={'rowSpacing'}>
				<Card>
					<Table dataSource={items} columns={columns} />
				</Card>
			</Col>
		</Row>
	);
}
const mapStateToProps = (state, ownProps) => ({
	budget: state.budgets.detail,
	loading: state.budgets.fetching,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
	location: ownProps.location,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	getBudget: (id) => dispatch(budgetsRestCrudThunksForBuyer.readOne(id)),
	deleteBudget: (budget) => dispatch(budgetsRestCrudThunksForBuyer.delete(budget)),
	updateNavbar: (backlinkTitle, backlinkTo, navbarBackgroundColor) =>
		dispatch(updateNavbarState(backlinkTitle, backlinkTo, navbarBackgroundColor)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BudgetDetailLocationsPage));

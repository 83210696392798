import React, { FC } from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { FlexibleImageAvatar } from '../flexible-image-avatar/FlexibleImageAvatar';
import { getProtectedImageUriForSupplier } from '../../utils/FileAccessUtils';
import StockedPartTag from '../parts_index_page/StockedPartTag';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { Typography } from 'antd';

const { Text } = Typography;

require('./PartRowDisplay.less');

export const getPartNumberText = (part) => (
	<span>
		{nullSafeGet('partNumber', part) ? <span>Part #{part.partNumber}</span> : null}
		{nullSafeGet('aliasPartId', part) ? (
			<span className={part.partNumber ? 'ml-4' : ''}>Alias Part #{part.aliasPartId}</span>
		) : null}
	</span>
);

const PartRowDisplay: FC<any> = ({ part, ...props }) => {
	const previewImage =
		part.images && part.images.length > 0
			? part.images.map((locImg) => {
					const [imgFileId, imgFileName] = locImg.split('/');
					return getProtectedImageUriForSupplier(imgFileId, imgFileName, 1200, 800);
			  })[0]
			: null;

	return (
		<div className="partRowDisplay" style={{ justifyContent: 'space-between' }} {...props}>
			<div className="partRowDisplay">
				<div style={{ marginRight: 24 }}>
					{previewImage ? (
						<FlexibleImageAvatar
							displayName={part.name}
							showPopover={false}
							popoverText={part.name}
							squareMode={true}
							width="120px"
							height="80px"
							imageUrl={previewImage}
						/>
					) : (
						<FlexibleImageAvatar
							displayName={part.name}
							showPopover={false}
							popoverText={part.name}
							squareMode={true}
							width="120px"
							height="80px"
							imageUrl="https://s3.amazonaws.com/emptor-data-assets/images/empty-state/icons8-screw-filled-100.png"
						/>
					)}
				</div>
				<div>
					<div className="partRowDisplay__rowTitle">
						<Ellipsis tooltip={true} lines={1} fullWidthRecognition className={'pb-0.5'}>
							{part.name}
						</Ellipsis>
					</div>
					<div className="partRowDisplay__rowSubtitle flex flex-col">
						<Ellipsis tooltip={true} lines={1} fullWidthRecognition>
							{getPartNumberText(part)}
						</Ellipsis>
						{part.description && (
							<Ellipsis tooltip={true} length={80}>
								{part.description}
							</Ellipsis>
						)}
						<div className="mt-2 flex flex-row items-center">
							<StockedPartTag isStocked={part.isStocked} />
							{part.isActive ? null : (
								<div className="ml-4">
									<Text className={'mr-1'} type={'danger'}>
										Inactive
									</Text>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PartRowDisplay;

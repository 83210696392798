import { getObjectValues, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';

export const getPOLineItemsFromSelectedRecords = (
	selectedRecords,
	partRecords = {},
	equipmentRecords = {}
) => {
	const getUnitCost = (combinedEl, element, prefix) => {
		const costField = `${prefix}Cost`;
		const quantityField = `${prefix}Quantity`;

		const getUnitCostFromTotalCost = () => {
			if (combinedEl[costField] !== undefined) {
				return combinedEl[costField] / nullSafeGetOrElse(`${quantityField}`, combinedEl, 1);
			} else if (element[costField] !== undefined) {
				return element[costField] / nullSafeGetOrElse(`${quantityField}`, element, 1);
			}

			return undefined;
		};

		return combinedEl.unitCost || element.unitCost || getUnitCostFromTotalCost();
	};

	const partLineItems = selectedRecords
		.filter((_) => !_.isEquipmentLine)
		.map((requestedPart) => {
			return {
				partCost:
					requestedPart.partCost !== undefined ? parseFloat(requestedPart.partCost) : undefined,
				partCatalog: requestedPart.partCatalog,
				partQuantity: requestedPart.partQuantity,
				unitCost:
					requestedPart.partCost !== undefined
						? requestedPart.partCost / requestedPart.partQuantity
						: undefined,
				partId: requestedPart.partId,
				part: requestedPart.part,
				prLineItemIds: [requestedPart.id],
				warrantyAssetId: nullSafeGet('supplierPurchaseRequest.warrantyAssetId', requestedPart),
				warrantyAsset: nullSafeGet('supplierPurchaseRequest.warrantyAsset', requestedPart),
				supplierPurchaseRequestLineItems: [requestedPart],
			};
		});
	const recordsByPartId = partLineItems.reduce((acc, el) => {
		const combinedEl = acc[el.partId];
		if (combinedEl) {
			acc[el.partId] = {
				...combinedEl,
				...(el.warrantyAssetId && { warrantyAssetId: el.warrantyAssetId }),
				...(el.warrantyAsset && { warrantyAsset: el.warrantyAsset }),
				partQuantity: el.partQuantity + combinedEl.partQuantity,
				unitCost: getUnitCost(combinedEl, el, 'part'),
				partCost: getUnitCost(combinedEl, el, 'part')
					? (el.partQuantity + combinedEl.partQuantity) * getUnitCost(combinedEl, el, 'part')
					: undefined,
				prLineItemIds: nullSafeGetOrElse('prLineItemIds', combinedEl, []).concat(el.prLineItemIds),
				supplierPurchaseRequestLineItems: combinedEl.supplierPurchaseRequestLineItems.concat(
					el.supplierPurchaseRequestLineItems
				),
			};
		} else {
			acc[el.partId] = el;
		}
		return acc;
	}, partRecords);
	const reducedPartRecords = getObjectValues(recordsByPartId);

	const equipmentLineItems = selectedRecords
		.filter((_) => _.isEquipmentLine)
		.map((requestedEquipment) => {
			return {
				equipmentCost:
					requestedEquipment.equipmentCost !== undefined
						? parseFloat(requestedEquipment.equipmentCost)
						: undefined,
				equipmentCatalog: requestedEquipment.equipmentCatalog,
				equipmentQuantity: requestedEquipment.equipmentQuantity,
				unitCost:
					requestedEquipment.equipmentCost !== undefined
						? requestedEquipment.equipmentCost / requestedEquipment.equipmentQuantity
						: undefined,
				equipmentTypeId: requestedEquipment.equipmentTypeId,
				equipmentType: requestedEquipment.equipmentType,
				prLineItemIds: [requestedEquipment.id],
				warrantyAssetId: nullSafeGet('supplierPurchaseRequest.warrantyAssetId', requestedEquipment),
				warrantyAsset: nullSafeGet('supplierPurchaseRequest.warrantyAsset', requestedEquipment),
				supplierPurchaseRequestLineItems: [requestedEquipment],
			};
		});
	const recordsByEquipmentId = equipmentLineItems.reduce((acc, el) => {
		const combinedEl = acc[el.equipmentTypeId];
		if (combinedEl) {
			acc[el.equipmentTypeId] = {
				...combinedEl,
				...(el.warrantyAssetId && { warrantyAssetId: el.warrantyAssetId }),
				...(el.warrantyAsset && { warrantyAsset: el.warrantyAsset }),
				equipmentQuantity: el.equipmentQuantity + combinedEl.equipmentQuantity,
				unitCost: getUnitCost(combinedEl, el, 'equipment'),
				equipmentCost: getUnitCost(combinedEl, el, 'equipment')
					? (el.equipmentQuantity + combinedEl.equipmentQuantity) *
					  getUnitCost(combinedEl, el, 'equipment')
					: undefined,
				prLineItemIds: nullSafeGetOrElse('prLineItemIds', combinedEl, []).concat(el.prLineItemIds),
				supplierPurchaseRequestLineItems: combinedEl.supplierPurchaseRequestLineItems.concat(
					el.supplierPurchaseRequestLineItems
				),
			};
		} else {
			acc[el.equipmentTypeId] = el;
		}
		return acc;
	}, equipmentRecords);
	const reducedEquipmentRecords = getObjectValues(recordsByEquipmentId);

	return {
		partLineItems: reducedPartRecords,
		equipmentLineItems: reducedEquipmentRecords,
	};
};

import * as React from 'react';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Alert, Button, Row, Col, Switch } from 'antd';
import { connect } from 'react-redux';
import {
	locationsRestCrudThunksForBuyer,
	locationsRestCrudThunksForSupplier,
	readAllLocationsWithoutRoleChecksForBuyer,
	readAllLocationsWithoutRoleChecksForSupplier,
} from '../../thunks/locations_thunks';
import { withRouter } from 'react-router';
import { ROLE_TYPES } from '../../utils/DataConstants';

const FormItem = Form.Item;
require('./InviteSupplierForm.less');

interface InviteSupplierFormProps extends FormComponentProps {
	onSubmit: any;
	onCancel: any;
	visible: boolean;
	createErrors: any;
	updateErrors: any;
	creating: boolean;
	updating: boolean;
	currentUser: any;
	formData: any;

	fetchLocations: any;
	fetchMultipleLocations: any;
	locations: any;
	submit: any;
	cancel: any;
	loading: any;
}
class InviteSupplierForm extends React.Component<InviteSupplierFormProps, any> {
	handleSubmit = (e) => {
		this.props.form.validateFields((err, values) => {
			const { primaryContactEmail, ...restValues } = values;
			// check to not send primaryContactEmail as '' to the backend
			const supplier = { ...values, primaryContactEmail: values.primaryContactEmail || undefined };
			this.props.submit(supplier);
		});
	};
	handleCancel = (e) => {
		this.props.cancel();
	};
	render() {
		const { form, createErrors, updateErrors, loading } = this.props;
		const { getFieldDecorator } = form;
		return (
			<div className="InviteSupplierForm">
				<Form layout="vertical" hideRequiredMark={true}>
					{createErrors.length > 0 ? (
						<FormItem>
							<Alert message={createErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					{updateErrors.length > 0 ? (
						<FormItem>
							<Alert message={updateErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					<FormItem label="Supplier Company Name">
						{getFieldDecorator('name', {
							rules: [{ required: true, message: 'Please input the company name!' }],
							initialValue: '',
						})(<Input placeholder="John's Plumbing Service" />)}
					</FormItem>
					<FormItem label="Supplier Contact Email Address">
						{getFieldDecorator('primaryContactEmail', {
							rules: [
								{ required: false, message: "Please input your primary contact's email!" },
								{ type: 'email', message: 'This is not a valid email!' },
							],
						})(<Input placeholder="John.Doe@doeplumbing.com" />)}
					</FormItem>
					<Row style={{ flexWrap: 'nowrap', gap: '10px' }}>
						<FormItem label="Contact First Name" className="InlineFormItem">
							{getFieldDecorator('primaryContactFirstName', {
								rules: [
									{ required: false, message: "Please input your primary contact's first name!" },
								],
							})(<Input placeholder="John" />)}
						</FormItem>
						<FormItem label="Contact Last Name" className="InlineFormItemRight">
							{getFieldDecorator('primaryContactLastName', {
								rules: [
									{ required: false, message: "Please input your primary contact's last name!" },
								],
							})(<Input placeholder="Doe" />)}
						</FormItem>
					</Row>
				</Form>
				<Row>
					<Col className="buttonWrapper">
						<Button htmlType="reset" className="buttonRight" onClick={this.handleCancel}>
							Cancel
						</Button>
						<Button
							type="primary"
							htmlType="submit"
							className="buttonRight"
							loading={loading}
							onClick={this.handleSubmit}
						>
							Continue
						</Button>
					</Col>
				</Row>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	createErrors: state.buyer_contacts.createErrors,
	updateErrors: state.buyer_contacts.updateErrors,
	creating: state.buyer_contacts.creating,
	updating: state.buyer_contacts.updating,
	currentUser: state.session.currentUser,
	formData: ownProps.formData,
	isLocationChange: ownProps.isLocationChange,
	loading: state.supplier_private_networks.fetching,

	locations: state.locations,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? readAllLocationsWithoutRoleChecksForSupplier(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: readAllLocationsWithoutRoleChecksForBuyer(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: locationsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(InviteSupplierForm)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(Form.create()(ComponentWithoutUserType));

import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';

const style = require('./BuyerNotificationsPage.less');

export default class BuyerNotificationsPage extends React.Component<any, null> {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div>
				<Route
					path="/buyer/inbox"
					exact={true}
					render={(props) => (
						<Redirect
							to={{
								pathname: '/buyer/inbox/overview',
							}}
						/>
					)}
				/>
				{this.props.childRoutes.map((r, i) => (
					<RouteWithSubRoutes key={i} {...r} />
				))}
			</div>
		);
	}
}

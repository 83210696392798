import React, { useCallback, useMemo } from 'react';
import { VIEW_ENTITY_TYPES } from '../../utils/DataConstants';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { Row } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { savedFilterViewsRestCrudThunksForBuyer } from '../../thunks/saved_filter_views_thunks';
import { BUYER_ADMIN_ONLY } from '../roles/roles';
import SavedFilterViewEmptyState from '../saved_filter_views/SavedFilterViewEmptyState';
import SavedFilterViewsRowDisplay from '../saved_filter_views/SavedFilterViewsRowDisplay';

const queryString = require('qs');
const INVOICES_SAVED_FILTER_VIEWS_TARGET_COLLECTION = 'invoicesSavedViewsIndex';

const InvoicesSavedFiltersViewIndexPage = ({
	location,
	currentUser,
	deleteSavedFilterView,
	history,
}): React.ReactElement => {
	const searchString = useMemo(
		() => (location.search[0] === '?' ? location.search.slice(1) : location.search),
		[location]
	);

	const queryParams = queryString.parse(searchString);
	const { current, pageSize, sort_by, order } = queryParams;
	const initialPagination = {
		current: current ? current : 1,
		pageSize: pageSize ? pageSize : 5,
	};
	const initialSorting = {
		sort_by: sort_by ? sort_by : 'isPublic',
		order: order ? order : 'ascend',
	};
	const initialFilters = {
		entityType: VIEW_ENTITY_TYPES.INVOICES,
	};

	const isAdmin = useMemo(() => {
		return currentUser.roles.some((role) => BUYER_ADMIN_ONLY.has(role));
	}, [currentUser]);

	const onOpenView = useCallback(
		(view) => {
			history.push(`/buyer/invoices/overview/all?${view.filterQuery}`);
		},
		[history]
	);

	const columns = [
		{
			title: '',
			dataIndex: initialSorting.sort_by,
			defaultSortOrder: initialSorting.order,
			order: initialSorting.order,
			sort_by: initialSorting.sort_by,
			render: (_, record) => (
				<Row>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							width: '100%',
							alignItems: 'baseline',
						}}
					>
						<SavedFilterViewsRowDisplay
							record={record}
							isAdmin={isAdmin}
							onOpenView={onOpenView}
							onDelete={deleteSavedFilterView}
						/>
					</div>
				</Row>
			),
		},
	];

	return (
		<div style={{ padding: '24px' }}>
			<PaginatedReduxTable
				tableLayoutFixed={true}
				updateQueryParams={true}
				showHeader={false}
				emptyState={<SavedFilterViewEmptyState entityType={VIEW_ENTITY_TYPES.INVOICES} />}
				collectionName="saved_filter_views"
				targetCollectionName={INVOICES_SAVED_FILTER_VIEWS_TARGET_COLLECTION}
				columns={columns}
				initialFilters={initialFilters}
				initialPagination={initialPagination}
				fetchData={savedFilterViewsRestCrudThunksForBuyer.read}
			/>
		</div>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	deleteSavedFilterView: (entity) =>
		dispatch(savedFilterViewsRestCrudThunksForBuyer.delete(entity)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(InvoicesSavedFiltersViewIndexPage)
);

import React from 'react';

require('./WarrantyDisplay.less');

const WarrantyDisplay = (status, prefix = ''): React.ReactElement => {
	const text = status === 'Active' ? 'Warranty' : 'Warranty Expired';
	return (
		<span className={status === 'Active' ? 'WarrantyDisplay' : 'WarrantyDisplayExpired'}>
			<span style={{ whiteSpace: 'nowrap' }}>{prefix ? `${prefix} ${text}` : text}</span>
		</span>
	);
};

export default WarrantyDisplay;

import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Select, Alert, InputNumber, Switch } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { connect } from 'react-redux';
import { getCurrency, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { vendorsRestCrudThunksForSupplier } from '../../thunks/vendors_thunks';
import { equipmentCatalogsRestCrudThunksForSupplier } from '../../thunks/equipment_catalogs_thunks';
import { equipmentTypesRestCrudThunksForSupplier } from '../../thunks/equipment_types_thunks';
import OWRichTextEditor from '../../rich_text_editor/OWRichTextEditor';
import ChangeVendorCurrencyComponent from '../vendors_detail_details_page/ChangeVendorCurrencyComponent';

const FormItem = Form.Item;
export const DATE_FORMAT = 'MMMM D, YYYY h:mm a';

interface EquipmentCatalogModalFormProps extends FormComponentProps {
	onSuccess: any;
	onCancel: any;
	createErrors: any;
	updateErrors: any;
	create: any;
	update: any;
	visible: boolean;

	formData: any;
	currencies: any;
	currentUser: any;
	vendors: any;
	targetCollectionName: any;
	fetchVendors: any;
	fetchMultipleVendors: any;
	equipmentType: any;
	updateEquipment: any;
}

const EQUIPMENT_CATALOG_FORM_VENDORS_AUTOCOMPLETE = 'EQUIPMENT_CATALOG_FORM_VENDORS_AUTOCOMPLETE';

const EquipmentCatalogModalForm: FC<EquipmentCatalogModalFormProps> = ({
	targetCollectionName,
	create,
	update,
	onSuccess,
	form,
	visible,
	onCancel,
	formData,
	vendors,
	currentUser,
	fetchVendors,
	fetchMultipleVendors,
	createErrors,
	updateErrors,
	equipmentType,
	updateEquipment,
}): React.ReactElement => {
	const [saving, setSaving] = useState(false);

	const editorRef = useRef(null);
	const { getFieldValue } = form;

	const updateDefaultVendorIfApplicable = useCallback(
		(values) => {
			return new Promise((resolve, reject) => {
				if (values.isDefaultVendor) {
					updateEquipment({
						...equipmentType,
						defaultVendorId: values.partEquipmentVendorId,
					})
						.then((res) => resolve(res))
						.catch((err) => reject(err));
				} else {
					resolve(true);
				}
			});
		},
		[equipmentType, updateEquipment]
	);

	const getNotes = useCallback(
		() => nullSafeGet('current', editorRef) && editorRef.current.getContent(),
		[]
	);

	const currencyId = useMemo(() => {
		const currency = getCurrency({ currentUser });
		const vendorId = getFieldValue('partEquipmentVendorId');
		return nullSafeGetOrElse(`records.${vendorId}.currencyId`, vendors, currency.id);
	}, [currentUser, getFieldValue, vendors]);

	const handleSubmit = useCallback(
		(e) => {
			e.preventDefault();
			form.validateFields((err, values) => {
				console.log(err);
				if (!err) {
					setSaving(true);
					const submitAction = values.id ? update : create;
					Promise.all([
						updateDefaultVendorIfApplicable(values),
						submitAction(
							{
								...values,
								notes: getNotes(),
								...(values.unitCost && { unitCost: parseFloat(values.unitCost) }),
								...(values.listPrice && { listPrice: `${values.listPrice}` }),
								currencyId,
							},
							targetCollectionName
						),
					])
						.then(() => {
							onSuccess();
						})
						.finally(() => setSaving(false));
				}
			});
		},
		[
			form,
			update,
			create,
			updateDefaultVendorIfApplicable,
			getNotes,
			currencyId,
			targetCollectionName,
			onSuccess,
		]
	);

	const { getFieldDecorator } = form;
	getFieldDecorator(`id`, { initialValue: formData.id });
	getFieldDecorator(`equipmentTypeId`, { initialValue: formData.equipmentTypeId });
	getFieldDecorator(`supplierFacilityId`, {
		initialValue: formData.supplierFacilityId || nullSafeGet('facility.id', currentUser),
	});
	getFieldDecorator(`supplierCompanyId`, {
		initialValue:
			formData.supplierCompanyId || nullSafeGet('facility.supplierCompanyId', currentUser),
	});

	const isUpdate = useMemo(() => !!formData.id, [formData.id]);

	const isDefaultVendor = useMemo(
		() =>
			!!formData.partEquipmentVendorId &&
			formData.partEquipmentVendorId === nullSafeGet('defaultVendorId', equipmentType),
		[formData.partEquipmentVendorId, equipmentType]
	);

	return (
		<Modal
			visible={visible}
			width={600}
			confirmLoading={saving}
			title={`${isUpdate ? 'Edit' : 'Add'} Vendor for Equipment`}
			okText={isUpdate ? 'Update' : 'Create'}
			onCancel={onCancel}
			onOk={handleSubmit}
			closable={false}
		>
			<Form layout="vertical">
				{createErrors.length > 0 ? (
					<FormItem>
						<Alert message={createErrors.join(' ')} type="error" />
					</FormItem>
				) : null}
				{updateErrors.length > 0 ? (
					<FormItem>
						<Alert message={updateErrors.join(' ')} type="error" />
					</FormItem>
				) : null}
				<FormItem label="Vendor">
					{getFieldDecorator(`partEquipmentVendorId`, {
						rules: [
							{
								required: true,
								message: 'Please select a vendor.',
							},
						],
						initialValue: formData.partEquipmentVendorId,
					})(
						<OWAsyncSelect
							disabled={isUpdate}
							style={{ maxWidth: 300 }}
							stateSlice={vendors}
							targetCollectionName={EQUIPMENT_CATALOG_FORM_VENDORS_AUTOCOMPLETE}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleVendors(ids, targetCollectionName);
							}}
							fetchData={(searchText, targetCollectionName) => {
								fetchVendors({ name: searchText }, targetCollectionName);
							}}
							renderRecord={(vendor) => (
								<Select.Option key={vendor.id} value={vendor.id}>
									{vendor.name}
								</Select.Option>
							)}
						/>
					)}
				</FormItem>
				<FormItem label="Is Default Vendor">
					{form.getFieldDecorator('isDefaultVendor', {
						initialValue: isDefaultVendor,
						valuePropName: 'checked',
					})(<Switch />)}
				</FormItem>
				<div className="flex flex-row items-center">
					<FormItem label="Last Price">
						{getFieldDecorator('unitCost', {
							initialValue: formData.unitCost,
						})(
							<InputNumber
								style={{ width: 320 }}
								min={0.0}
								precision={2}
								step={0.01}
								addonAfter={currencyId}
							/>
						)}
					</FormItem>
					<div>
						{getFieldValue('partEquipmentVendorId') ? (
							<ChangeVendorCurrencyComponent vendorId={getFieldValue('partEquipmentVendorId')} />
						) : null}
					</div>
				</div>
				<div className="flex flex-row items-center">
					<FormItem label="List Price">
						{getFieldDecorator('listPrice', {
							initialValue: formData.listPrice,
						})(
							<InputNumber
								style={{ width: 320 }}
								min={0.0}
								precision={2}
								step={0.01}
								addonAfter={currencyId}
							/>
						)}
					</FormItem>
					<div>
						{getFieldValue('partEquipmentVendorId') ? (
							<ChangeVendorCurrencyComponent vendorId={getFieldValue('partEquipmentVendorId')} />
						) : null}
					</div>
				</div>
				<FormItem label="Notes">
					{getFieldDecorator('notes', {
						initialValue: formData.notes,
					})(<OWRichTextEditor initialEditorState={formData.notes || ''} editorRef={editorRef} />)}
				</FormItem>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSuccess: ownProps.onSuccess,
	formData: ownProps.formData,
	vendors: state.vendors,
	currencies: state.currencies,
	currentUser: state.session.currentUser,
	targetCollectionName: ownProps.targetCollectionName,
	createErrors: state.buyer_contacts.createErrors,
	updateErrors: state.buyer_contacts.updateErrors,
	equipmentType: state.equipment_types.detail,
});

const mapDispatchToProps = (dispatch) => ({
	create: (entity, targetCollectionName) =>
		dispatch(equipmentCatalogsRestCrudThunksForSupplier.create(entity, 'id', targetCollectionName)),
	update: (entity) => dispatch(equipmentCatalogsRestCrudThunksForSupplier.update(entity)),
	fetchVendors: (params, targetCollectionName) =>
		dispatch(
			vendorsRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName
			)
		),
	fetchMultipleVendors: (ids, targetCollectionName) =>
		dispatch(vendorsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	updateEquipment: (entity) => dispatch(equipmentTypesRestCrudThunksForSupplier.update(entity)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create<EquipmentCatalogModalFormProps>()(EquipmentCatalogModalForm));

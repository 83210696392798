import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import {
	getCurrency,
	getWarrantyStatus,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import { AddressDisplay } from '../address_display/AddressDisplay';
import { checkAndFormatPhoneNumber, dateFormatter } from '../../utils/DataFormatterUtils';
import { UserName } from '../name_component/user/UserNameComponent';
import WarrantyDisplay from '../common/WarrantyDisplay';
import { useReactToPrint } from 'react-to-print';
import { Button, Tooltip } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import './index.less';
import { getAllInternalContactsForWO } from '../work_orders_detail_details_page/WorkOrderInternalContact';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import SimpleMessageBubble from '../MessageBubble/simple';
import { ProtectedImageThumbnails } from '../protected_image_thumbnails/ProtectedImageThumbnails';
import WorkOrderPrintOptionsModal from './WorkOrderPrintOptionsModal';
import ServiceCallDetails from '../work_orders_detail_details_page/ServiceCallDetails';
import { ROLE_TYPES } from '../../utils/DataConstants';
import SupplierServiceCallDetails from '../work_orders_detail_details_page/SupplierServiceCallDetails';

require('./index.less');

const WorkOrderPrintable: FC<any> = ({ workOrder, currentUser, userType }) => {
	const [printOptionsVisible, setPrintOptionsVisible] = useState(false);
	const [printOptions, setPrintOptions] = useState<any>({});

	const componentRef = useRef();

	const _showPrintOptions = () => setPrintOptionsVisible(true);
	const _hidePrintOptions = () => setPrintOptionsVisible(false);

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		documentTitle: `Openwrench - Work Order:#${workOrder.id} `,
		bodyClass: 'printWo',
	});

	const onPrint = useCallback(
		(values) => {
			setPrintOptions(values);
			_hidePrintOptions();
			handlePrint();
		},
		[handlePrint]
	);

	const workOrderCurrency = getCurrency({ workOrder });
	let optionalDetails = {};
	if (workOrder.buyerProjects && workOrder.buyerProjects.length > 0) {
		optionalDetails['Projects'] = workOrder.buyerProjects.map((project, i, projects) =>
			projects.length - 1 === i ? project.title : project.title.concat(', ')
		);
	}
	if (nullSafeGet('isWarranty', workOrder)) {
		optionalDetails['Warranty Status'] = 'Under Warranty';
	}

	const getContactDisplay = (contact) => (
		<div>
			{nullSafeGetOrElse(
				'submittedByName',
				workOrder,
				`${nullSafeGetOrElse('nameGiven', contact, '')} ${nullSafeGetOrElse(
					'nameFamily',
					contact,
					''
				)}`
			)}
			<br />
			{nullSafeGet('title', contact)} {nullSafeGet('department', contact)}
			{nullSafeGet('phoneWork', contact) && (
				<div
					className="materialCard__supportingText materialCard__flexCentVert"
					style={{ marginBottom: 8 }}
				>
					<div className="materialCard__iconLabel"></div>
					<div>{nullSafeGet('phoneWork', contact)}</div>
				</div>
			)}
			{nullSafeGet('phoneMobile', contact) && <div>{nullSafeGet('phoneMobile', contact)}</div>}
			{nullSafeGet('email', contact) && <div>{nullSafeGet('email', contact)}</div>}
		</div>
	);

	const WOdetails = {
		Description: workOrder.description ? (
			<div
				style={{ fontSize: 16 }}
				className="richTextWrapper"
				dangerouslySetInnerHTML={{ __html: workOrder.description }}
			/>
		) : undefined,
		Reported: `${dateFormatter(workOrder.createdAt, 'MMM D')} at ${dateFormatter(
			workOrder.createdAt,
			'h:mm a'
		)}`,
		Due: `${dateFormatter(workOrder.dueDate, 'MMM D')} at ${dateFormatter(
			workOrder.dueDate,
			'h:mm a'
		)}`,

		'Service Provider': (
			<>
				{nullSafeGet('supplierFacility.displayName', workOrder)}
				<br />
			</>
		),
		'Internal Contact': getAllInternalContactsForWO(workOrder).map((_) => getContactDisplay(_)),
		NTE: nullSafeGet('nte', workOrder)
			? workOrderCurrency.format(nullSafeGet('nte', workOrder))
			: nullSafeGet('nte', workOrder),
		Trade: nullSafeGet('spendCategory.name', workOrder),
		'Problem Type': nullSafeGet('problemType.hierarchyName', workOrder),
		Area: nullSafeGet('area.hierarchyName', workOrder),
		'Purchase Order #': `${nullSafeGet('poNumber', workOrder)}`,
		...optionalDetails,
		...(nullSafeGet('assetId', workOrder) && {
			'Asset status': nullSafeGet('isAssetDown', workOrder) ? 'Offline' : 'Online',
		}),
		...(nullSafeGet('markedCompleteByContact', workOrder)
			? {
					'Marked Complete By': (
						<UserName
							contact={nullSafeGet('markedCompleteByContact', workOrder)}
							mode={'inline'}
							placement={'right'}
						/>
					),
			  }
			: {}),
		...(nullSafeGet('completedAt', workOrder)
			? {
					Completed: `${dateFormatter(workOrder.createdAt, 'MMM D')} at ${dateFormatter(
						workOrder.createdAt,
						'h:mm a'
					)}`,
			  }
			: {}),
	};
	const filteredWorkOrderDetails = Object.keys(WOdetails).reduce((acc, el) => {
		const val = WOdetails[el];
		if (val !== null && val !== 'undefined' && val !== undefined && val !== '') {
			acc[el] = WOdetails[el];
		}
		return acc;
	}, {});
	const asset = useMemo(() => nullSafeGet('asset', workOrder), [workOrder]);
	const notes = useMemo(() => nullSafeGetOrElse('notes', workOrder, []), [workOrder]);
	const actualNotes = useMemo(
		() =>
			notes.filter(
				(n) =>
					(n.text && n.text.length > 0) ||
					(n.photo && n.photo.length > 0) ||
					(n.video && n.video.length > 0)
			),
		[notes]
	);
	const subscribers = useMemo(() => {
		return [
			...(workOrder.buyerSubscribers || []),
			...(workOrder.supplierSubscribers || []),
			...(nullSafeGetOrElse('supplierSubscribers.length', workOrder, 0) === 0 &&
			nullSafeGet('supplierFacility.id', workOrder)
				? [
						{
							nameGiven: nullSafeGet('supplierFacility.displayName', workOrder),
							nameFamily: '',
							email: nullSafeGet('supplierSubscriberEmails.0', workOrder),
						},
				  ]
				: []),
		];
	}, [workOrder]);

	return (
		<>
			<div className={'workOrderPrintable'}>
				<Tooltip title="Click me to print a pdf of this work order">
					<Button
						onClick={_showPrintOptions}
						shape="circle"
						icon={<PrinterOutlined translate="" />}
					/>
				</Tooltip>

				<div className=" hidden h-0 overflow-hidden" id="printdiv">
					<div ref={componentRef} className="bg-white">
						<header>
							<div className="  mx-auto flex items-start  justify-start bg-buyer  px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
								<div>
									<span className="sr-only">OpenWrench</span>
									<img
										className="h-8 w-auto sm:h-10"
										src="https://emptor-data-assets.s3.amazonaws.com/images/emptor-logo/openwrenchlogo-white.png"
										alt=""
									/>
								</div>
							</div>
						</header>
						<main className="-mx-4 px-4 py-8  sm:mx-0  sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-16">
							<div className="overflow-hidden bg-white px-4 py-6 shadow sm:rounded-lg sm:px-6">
								<div className="flex flex-row justify-between ">
									<div className="flex basis-1/2 flex-col pr-4">
										<div className="flex flex-col">
											{nullSafeGet('location.brand.logoURL', workOrder) ? (
												<img
													src={nullSafeGet('location.brand.logoURL', workOrder)}
													alt={nullSafeGet('location.brand.brandName', workOrder)}
													className="mr-3 h-auto w-16 object-contain"
												/>
											) : null}
											<div className="text-md font-semibold text-gray-900 ">
												{nullSafeGet('location.name', workOrder)}
											</div>
											<div className="mt-1 text-sm text-gray-500">
												#{nullSafeGet('location.storeId', workOrder)}
											</div>
										</div>
										<div className="mt-1 text-sm text-gray-500">
											<AddressDisplay
												hidePostcode={true}
												hideCountry={true}
												className="text-sm text-gray-500"
												address={nullSafeGet('location.buyerFacility.primaryAddress', workOrder)}
											/>
										</div>
										<div className="mt-1 text-sm text-gray-500">
											{nullSafeGet('location.phoneNumber', workOrder)
												? checkAndFormatPhoneNumber(nullSafeGet('location.phoneNumber', workOrder))
												: null}
										</div>
									</div>
									<div className="basis-1/2">
										<div className="text-md font-semibold text-gray-900">
											Work Order : #{workOrder.id}
										</div>

										<div className="flex flex-row">
											<div className="mt-1 mr-1 text-sm text-gray-500">
												{workOrder.displayStatus}
											</div>
											{'|'}
											<div className="mt-1 ml-1 text-sm text-gray-500">
												{nullSafeGet('woPriority.name', workOrder)}
											</div>
										</div>
										<p className="mt-1 mr-1 bg-gray-200 text-sm uppercase italic text-gray-500 ">
											"{workOrder.title}"
										</p>
									</div>
								</div>
								<div className="w-full border-t border-gray-300" />
								{asset ? (
									<div>
										<div className="text-md mt-4 font-semibold text-gray-900">Asset</div>
										<div className=" grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
											<div className="mr-16">
												<div className="mt-1 mr-1 text-sm text-gray-500">
													{nullSafeGet('name', asset)}
												</div>
												<div className="mt-1 mr-1 text-sm text-gray-500">
													{asset.downtimeStatus === 'offline' ? 'Offline' : null}
													{getWarrantyStatus(nullSafeGet('warranties.0', asset)) === 'Active' ? (
														<div>{WarrantyDisplay('Active')}</div>
													) : null}
												</div>
												<div className="mt-1 mr-1 text-sm text-gray-500">
													{nullSafeGet('assetType.name', asset)}
												</div>
											</div>
											<div className="mt-1 mr-1 text-sm text-gray-500">
												{nullSafeGet('id', asset) ? (
													<div className="mt-1 mr-1 text-sm text-gray-500">
														Asset ID: {nullSafeGet('id', asset)}
													</div>
												) : null}
												{nullSafeGet('assetNumber', asset) ? (
													<div className="mt-1 mr-1 text-sm text-gray-500">
														Asset Number: {nullSafeGet('assetNumber', asset)}
													</div>
												) : null}
												{nullSafeGet('serialNumber', asset) ? (
													<div className="mt-1 mr-1 text-sm text-gray-500">
														Serial Number: {nullSafeGet('serialNumber', asset)}
													</div>
												) : null}
												<div className="mt-1 mr-1 text-sm text-gray-500">
													{nullSafeGet('manufacturer', asset)}
												</div>
												{nullSafeGet('modelNumber', asset) ? (
													<div className="mt-1 mr-1 text-sm text-gray-500">
														Model {nullSafeGet('modelNumber', asset)}
													</div>
												) : null}
											</div>
										</div>
									</div>
								) : null}
								<div className="shadow-sm ring-1 ring-gray-900/5 sm:rounded-lg">
									<dl className="mt-4 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
										{Object.keys(filteredWorkOrderDetails).map((item) => (
											<div key={item} className="mt-2 border-t border-gray-900/5 pt-6 sm:pr-4">
												<dt className="font-semibold text-gray-900">{item}</dt>
												<dd className="mt-1 text-gray-500">
													{filteredWorkOrderDetails[item] || '--'}
												</dd>
											</div>
										))}
									</dl>
								</div>
								{nullSafeGet('includeImages', printOptions) &&
								workOrder.images &&
								workOrder.images.length > 0 ? (
									<div className="workOrderNotes mt-4 shadow-sm ring-1 ring-gray-900/5 sm:rounded-lg">
										<div className="text-md font-semibold text-gray-900">Work Order Photos</div>
										<div className="materialCard__subtitle">
											{
												<ProtectedImageThumbnails
													style={{ marginBottom: 32 }}
													imageWidth={240}
													imageHeight={240}
													photoStrings={workOrder.images}
													role={userType}
												/>
											}
										</div>
									</div>
								) : null}
								{nullSafeGet('includeNotes', printOptions) && actualNotes.length > 0 ? (
									<div className="workOrderNotes mt-4 shadow-sm ring-1 ring-gray-900/5 sm:rounded-lg">
										<div className="text-md font-semibold text-gray-900">Work Order Notes</div>
										<div id={'chatBox'}>
											<ul>
												{actualNotes.map((n, idx) => {
													return (
														<SimpleMessageBubble
															currentUser={currentUser}
															n={n}
															idx={idx}
															subscribers={subscribers}
															userType="buyer"
															showReadReceipts={false}
															alwaysShowDate={true}
														/>
													);
												})}
											</ul>
										</div>
									</div>
								) : null}
							</div>
							{nullSafeGet('includeServiceCalls', printOptions) ? (
								userType === ROLE_TYPES.SUPPLIER ? (
									<SupplierServiceCallDetails workOrder={workOrder} isReadOnly />
								) : (
									<ServiceCallDetails workOrder={workOrder} isReadOnly />
								)
							) : null}
						</main>
					</div>
				</div>
			</div>
			{printOptionsVisible ? (
				<WorkOrderPrintOptionsModal onCancel={_hidePrintOptions} onSubmit={onPrint} />
			) : null}
		</>
	);
};

const mapStateToProps = (state, ownProps) => ({
	currentUser: state.session.currentUser,
	companyConfig: state.company_config.detail,
});
const mapDispatchToProps = (dispatch) => ({});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(WorkOrderPrintable)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

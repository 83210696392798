import * as BizCharts from 'bizcharts';
import React, { FC } from 'react';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';

const { Chart, Axis, Geom, Legend, Tooltip } = BizCharts;
interface StackedAreaChartProps {
	cols: any;
	height: number;
	data: any;
	fillArea: boolean;
	position: string;
	chartPadding?: [number, number, number, number];
	xAxisLabel?: any;
	yAxisLabel?: any;
	color?: string;
	xAxisName: string;
	yAxisName: string;
	tooltipItemTemplate?: any;
	showTooltipTitle?: boolean;
}

export const StackedAreaChart: FC<StackedAreaChartProps> = ({
	chartPadding,
	showTooltipTitle = true,
	tooltipItemTemplate,
	data,
	cols,
	color,
	height,
	fillArea,
	xAxisName,
	yAxisName,
	xAxisLabel,
	yAxisLabel,
	position,
}) => {
	const identity = (val) => val;
	const yAxisLabelFormatter = nullSafeGetOrElse('formatter', yAxisLabel, identity);
	return (
		<Chart height={height} padding="auto" data={data} scale={cols} forceFit={true}>
			<Axis name={xAxisName} label={xAxisLabel} />
			<Axis name={yAxisName} label={yAxisLabel} />
			<Tooltip
				crosshairs={{ type: 'y' }}
				showTitle={showTooltipTitle}
				itemTpl={
					tooltipItemTemplate
						? tooltipItemTemplate
						: `
                    <li data-index={index}>
                        <!-- The marker for each record -->
                        <span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>
                        {name}: {yAxisLabelFormatter(value)}
                    </li>
                `
				}
			/>
			<Geom type="areaStack" position={position} color={color} />
			<Geom
				type="point"
				adjust={'stack'}
				position={position}
				color={color}
				size={4}
				shape={'circle'}
				style={{ stroke: '#fff', lineWidth: 1 }}
			/>
		</Chart>
	);
};

import React, { FC } from 'react';
import { STOPLIGHT_COLORS } from '../../utils/DataFormatterUtils';

const OfflineTag: FC<any> = ({ text, className }): React.ReactElement => {
	return (
		<div className={`${className || ''}`}>
			<span
				style={{
					color: STOPLIGHT_COLORS.pureRed,
					background: '#f9e6e6',
					padding: '4px 12px',
					borderRadius: 16,
					fontWeight: 400,
					fontSize: '1rem',
				}}
			>
				{text || 'Offline'}
			</span>
		</div>
	);
};

export default OfflineTag;

import * as React from 'react';
import { Layout, Button, List } from 'antd';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';
import SubnavTabs from '../subnav_tabs/SubnavTabs';
import {
	projectTemplatesRestCrudThunksForBuyer,
	projectTemplatesRestCrudThunksForSupplier,
} from '../../thunks/project_template_thunks';
import { Link } from 'react-router-dom';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { ROLE_TYPES } from '../../utils/DataConstants';
import {
	projectsRestCrudThunksForBuyer,
	projectsRestCrudThunksForSupplier,
} from '../../thunks/projects_thunks';

const style = require('./ProjectTemplatesDetailPage.less');
const ListItem = List.Item;
const ListItemMeta = ListItem.Meta;
const { Content } = Layout;

interface ProjectTemplatesDetailPageState {}

interface ProjectTemplatesDetailPageProps {
	match: any;
}

class ProjectTemplatesDetailPage extends React.Component<any, ProjectTemplatesDetailPageState> {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const { getProjectTemplate, match, currentUser } = this.props;
		const projectTemplateId = match.params.id;
		const userType = nullSafeGetOrElse('userType', currentUser, 'buyer');
		getProjectTemplate(userType, projectTemplateId);
	}

	handleTabChange = (key) => {
		const { history, match, currentUser } = this.props;
		const projectTemplateId = match.params.id;
		const userType = nullSafeGetOrElse('userType', currentUser, 'buyer');
		history.push(`/${userType}/projects/templates/detail/${projectTemplateId}/${key}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	handleDelete = () => {
		const { history, deleteProjectTemplate, projectTemplate, currentUser } = this.props;
		const userType = nullSafeGetOrElse('userType', currentUser, 'buyer');
		deleteProjectTemplate(userType, projectTemplate).then(() => {
			history.push(`/${userType}/projects/overview/templates/overview`);
		});
	};

	render() {
		const { projectTemplate, projectTemplatesFetching, companyConfig, match, currentUser } =
			this.props;
		const projectTemplateId = match.params.id;
		const tabs = [
			{
				key: 'details',
				name: 'Overview',
			},
		];
		const userType = nullSafeGetOrElse('userType', currentUser, 'buyer');
		return (
			<div>
				<Route
					path={`/${userType}/projects/templates/detail/:id`}
					exact={true}
					render={(props) => (
						<Redirect
							to={{
								pathname: `/${userType}/projects/templates/detail/${projectTemplateId}/details`,
							}}
						/>
					)}
				/>
				<Content className="projectTemplateDetailPage" style={{ padding: '0 0.5em' }}>
					{/*<ScrollToTopOnMount/>*/}
					{/*<BackTop/>*/}

					<div
						style={{
							margin: '0 -8px',
							padding: '24px',
							paddingBottom: 0,
							background: nullSafeGet('detail.config.theme.navblock', companyConfig),
							marginBottom: 8,
						}}
					>
						<h4
							style={{
								fontSize: 32,
								minHeight: 41,
								color: 'rgba(255, 255, 255, 0.9)',
								marginRight: 8,
								marginBottom: 24,
							}}
						>
							{projectTemplatesFetching ? null : projectTemplate.name}
						</h4>
						<SubnavTabs
							tabs={tabs}
							onChange={this.handleTabChange}
							isActive={this.checkTabActive}
						/>
						<div style={{ position: 'absolute', top: 60, right: 40 }}>
							<Link
								to={`/${userType}/projects/templates/edit/${projectTemplateId}`}
								style={{ marginRight: 16 }}
							>
								<Button ghost={true}>Edit</Button>
							</Link>
							<Button ghost={true} onClick={this.handleDelete}>
								Delete
							</Button>
						</div>
					</div>
					{this.props.childRoutes.map((r, i) => (
						<RouteWithSubRoutes key={i} {...r} />
					))}
				</Content>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	projectTemplatesFetching: state.project_templates.fetching,
	projectTemplate: state.project_templates.detail,
	companyConfig: state.company_config,
	match: ownProps.match,
	history: ownProps.history,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	getProjectTemplate: (userType, id) =>
		dispatch(
			userType === ROLE_TYPES.SUPPLIER
				? projectTemplatesRestCrudThunksForSupplier.readOne(id)
				: projectTemplatesRestCrudThunksForBuyer.readOne(id)
		),
	deleteProjectTemplate: (userType, entity) =>
		dispatch(
			userType === ROLE_TYPES.SUPPLIER
				? projectTemplatesRestCrudThunksForSupplier.delete(entity, 'id', null, true)
				: projectTemplatesRestCrudThunksForBuyer.delete(entity, 'id', null, true)
		),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(ProjectTemplatesDetailPage)
);

import { Card } from 'antd';
import React, { FC } from 'react';
import AssetWORowDisplay from '../assets_detail_details_page/AssetWORowDisplay';
import { EmptyState } from '../empty_state/EmptyState';
import WorkOrderTable from '../WorkOrderTable';

const ASSET_ASSOCIATED_ACTIVE_WORK_ORDERS_TARGET_COLLECTION = 'assetAssociatedActiveWorkOrders';

const AssetActiveWorkOrdersCard: FC<any> = ({ assetId }): React.ReactElement => {
	return (
		<Card title="Active Work Orders">
			<WorkOrderTable
				CustomHeader={() => <></>}
				preAppliedFilters={{
					assetId: assetId,
					isActive: true,
					hasBuyerArchived: false,
				}}
				tableStyle={{
					display: 'flex',
					flexDirection: 'column',
					cursor: 'pointer',
					userSelect: 'none',
					padding: '8px 8px',
					marginTop: '-10px',
					marginBottom: '-9px',
				}}
				initialPagination={{ current: 1, pageSize: 3 }}
				initialSorting={{
					sort_by: 'woPriorityAndIsAssetDown',
					order: 'ascend',
				}}
				targetCollectionName={ASSET_ASSOCIATED_ACTIVE_WORK_ORDERS_TARGET_COLLECTION}
				renderEmptyState={() => (
					<EmptyState
						graphic={
							<img
								alt="There aren't any active work orders for this asset."
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
							/>
						}
						headline={'No news is good news.'}
						body={
							<div style={{ textAlign: 'center' }}>
								<div style={{ maxWidth: 440, marginBottom: 16 }}>
									There aren't any active work orders for this asset.
								</div>
							</div>
						}
					/>
				)}
				backText="Back to asset"
				CustomWorkOrderRowDisplay={AssetWORowDisplay}
				openMoreInDefault
				ignoreRefreshCounts
			/>
		</Card>
	);
};

export default AssetActiveWorkOrdersCard;

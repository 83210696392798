import { authorizedRolesCombinations } from '../components/roles/roles';
import { ROLE_TYPES } from './DataConstants';
import { PERMISSION_NAMES } from './AuthUtils';
import { nullSafeGet } from './DataAccessUtils';

export const STRIPE_TEST_API_KEY = 'pk_test_tydO9GGsVzeDP5bXXuA6OvlL';
export const STRIPE_LIVE_API_KEY = 'pk_live_kT83xmAK8smH4hEGydsUezgF';

export const RELEASE_VERSION = 1.0;

export const BACKEND_URI_BY_ENV = {
	RELEASE: process.env.REACT_APP_BACKEND_URI || 'http://localhost:3000',
	DEV: 'http://api-dev.useopenwrench.com',
	TEST: 'https://api-test.useopenwrench.com',
	PROD: 'https://api.useopenwrench.com',
};

// THIS NEEDS TO BE CHANGED IF YOU DON'T WANT TO RUN AGAINST PROD
export const CURRENT_ENV = process.env.REACT_APP_CURRENT_ENV || 'PROD';
// THIS SHOULD BE CHANGED DEPENDING ON IF YOU ARE PUSHING TO BUYER OR SUPPLIER SIDE OF WEB APP
export const CURRENT_ENV_ROLE_TYPE =
	ROLE_TYPES[process.env.REACT_APP_CURRENT_ENV_ROLE_TYPE] || ROLE_TYPES.SUPPLIER;

export const BACKEND_ENV_NAMES = new Set(Object.keys(BACKEND_URI_BY_ENV));

export const getBackendUriWithEnv = (env) => {
	if (!BACKEND_ENV_NAMES.has(env)) {
		throw new Error(`Invalid environment name provided to RestCrudThunksGenerator: ${env}`);
	}
	return BACKEND_URI_BY_ENV[env];
};

export const getBackendUri = () => BACKEND_URI_BY_ENV[CURRENT_ENV];

export const BACKEND_WSS_URI_BY_ENV = {
	RELEASE: process.env.REACT_APP_WSS_BACKEND_URI || 'ws://localhost:3000',
	DEV: 'ws://api-dev.useopenwrench.com',
	TEST: 'wss://api-test.useopenwrench.com',
	PROD: 'wss://api.useopenwrench.com',
};

export const getWSSBackendUri = () => BACKEND_WSS_URI_BY_ENV[CURRENT_ENV];

export const BUYER_MENU_ITEMS = [
	{
		to: '/buyer/dashboards',
		title: 'Dashboard',
		icon: 'icons8-binoculars',
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		checkActive: (path) => new RegExp('/buyer/dashboards').test(path),
	},
	{
		to: '/buyer/workOrders',
		title: 'Work Orders',
		icon: 'icons8-wrench',
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		checkActive: (path) => new RegExp('/buyer/workOrders').test(path),
	},
	{
		to: '/buyer/inbox',
		title: 'Inbox',
		icon: 'icons8-mailbox',
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		checkActive: (path) => new RegExp('/buyer/inbox').test(path),
		count: (props = {}) => nullSafeGet('userNotifications.counts.TASKS_NOTIFICATIONS', props),
	},
	{
		to: '/buyer/projects',
		title: 'Projects',
		icon: 'icons8-blueprint',
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
		checkActive: (path) => new RegExp('/buyer/projects').test(path),
	},
	{
		to: '/buyer/requestsForProposal',
		title: 'Requests For Proposal',
		icon: 'icons8-cash-in-hand',
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
		checkActive: (path) => new RegExp('/buyer/requestsForProposal').test(path),
	},
	{
		to: '/buyer/siteSurveys',
		title: 'Site Surveys',
		icon: 'icons8-clipboard-checklist',
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		checkActive: (path) => new RegExp('/buyer/siteSurveys').test(path),
	},
	{
		to: '/buyer/plannedMaintenance',
		title: 'Planned Maintenance',
		icon: 'icons8-refresh',
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
		checkActive: (path) => new RegExp('/buyer/plannedMaintenance').test(path),
	},
	{
		to: '/buyer/quotes',
		title: 'Quotes',
		icon: 'icons8-billing-machine',
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
		checkActive: (path) => new RegExp('/buyer/quotes').test(path),
	},
	{
		to: '/buyer/invoices',
		title: 'Invoices',
		icon: 'icons8-invoice',
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
		checkActive: (path) => new RegExp('/buyer/invoices').test(path),
	},
	{
		to: '/buyer/assets',
		title: 'Assets',
		icon: 'icons8-bulldozer',
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		checkActive: (path) => new RegExp('/buyer/assets').test(path),
	},
	{
		to: '/buyer/meters',
		title: 'Meters',
		icon: 'icons8-dashboard',
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		checkActive: (path) => new RegExp('/buyer/meters').test(path),
	},
	{
		to: '/buyer/locations',
		title: 'Locations',
		icon: 'icons8-store',
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		checkActive: (path) => new RegExp('/buyer/locations').test(path),
	},
	{
		to: '/buyer/suppliers',
		title: 'Suppliers',
		icon: 'icons8-worker',
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
		checkActive: (path) => new RegExp('/buyer/suppliers').test(path),
	},
	{
		to: '',
		title: 'Analytics',
		icon: 'icons8-line-chart',
		accessPermissionName: PERMISSION_NAMES.ACCESS_ANALYTICS,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		children: [
			{
				to: '/buyer/analytics/assets',
				title: 'Downtime Analytics',
				icon: 'icons8-sand-watch',
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
				checkActive: (path) => new RegExp('/buyer/analytics/assets').test(path),
			},
			{
				to: '/buyer/analytics/spend',
				title: 'Spend Analytics',
				icon: 'icons8-fund-accounting',
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				checkActive: (path) => new RegExp('/buyer/analytics/spend').test(path),
			},
			{
				to: '/buyer/analytics/work',
				title: 'Work Analytics',
				icon: 'icons8-chart',
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				checkActive: (path) => new RegExp('/buyer/analytics/work').test(path),
			},
			{
				to: '/buyer/analytics/kpi',
				title: 'Scorecards',
				icon: 'icons8-scorecard',
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
				checkActive: (path) => new RegExp('/buyer/analytics/kpi').test(path),
			},
		],
	},
	{
		to: '/buyer/admin',
		title: 'Admin',
		icon: 'icons8-multiple-users',
		authorizedRoles: authorizedRolesCombinations.BUYER_ADMIN_ONLY,

		checkActive: (path) => new RegExp('/buyer/admin').test(path),
	},
	{
		to: '/buyer/approvalHierarchies',
		title: 'Approvals',
		icon: 'icons8-checkmark',
		authorizedRoles: authorizedRolesCombinations.BUYER_ADMIN_ONLY,
		checkActive: (path) => new RegExp('/buyer/approvalHierarchies').test(path),
	},
];

export const SUPPLIER_MENU_ITEMS = [
	{
		to: '/supplier/dashboards',
		title: 'Dashboard',
		icon: 'icons8-binoculars',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		checkActive: (path) => new RegExp('/supplier/dashboards').test(path),
	},
	{
		to: '/supplier/workOrders',
		title: 'Work Orders',
		icon: 'icons8-wrench',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		checkActive: (path) => new RegExp('/supplier/workOrders').test(path),
	},
	{
		to: '/supplier/inbox',
		title: 'Inbox',
		icon: 'icons8-mailbox',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		checkActive: (path) => new RegExp('/supplier/inbox').test(path),
		count: (props = {}) => nullSafeGet('userNotifications.counts.TASKS_NOTIFICATIONS', props),
	},
	{
		to: '/supplier/walkthroughs',
		title: 'Walkthroughs',
		icon: 'icons8-clipboard-checklist',
		authorizedRoles: authorizedRolesCombinations.SUPER_ADMIN_ONLY,
		unauthorizedCompanies: new Set([276322]),
		checkActive: (path) => new RegExp('/supplier/walkthroughs').test(path),
	},
	{
		to: '/supplier/opportunities',
		title: 'Opportunities',
		icon: 'icons8-cash-in-hand',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		checkActive: (path) => new RegExp('/supplier/opportunities').test(path),
	},
	{
		to: '/supplier/quotes',
		title: 'Quotes',
		icon: 'icons8-billing-machine',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		checkActive: (path) => new RegExp('/supplier/quotes').test(path),
	},
	{
		to: '/supplier/invoices',
		title: 'Invoices',
		icon: 'icons8-invoice',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		checkActive: (path) => new RegExp('/supplier/invoices').test(path),
	},
	{
		to: '/supplier/clients',
		title: 'Clients',
		icon: 'icons8-business-building-2',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		checkActive: (path) => new RegExp('/supplier/clients').test(path),
	},
	{
		to: '/supplier/fieldTechs',
		title: 'Field Techs',
		icon: 'icons8-street-worker',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		checkActive: (path) => new RegExp('/supplier/fieldTechs').test(path),
	},
	{
		to: '/supplier/analytics/fieldTechAnalytics',
		title: 'Analytics',
		icon: 'icons8-chart',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		unauthorizedCompanies: new Set([276322]),
		checkActive: (path) => new RegExp('/supplier/analytics').test(path),
	},
	{
		to: '',
		title: 'Inventory',
		icon: 'icons8-warehouse-3',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		unauthorizedCompanies: new Set([276322]),
		checkActive: (path) => new RegExp('/supplier/stockLocations').test(path),
		children: [
			{
				to: '/supplier/stockLocations',
				title: 'Stock Locations',
				icon: 'icons8-warehouse-3',
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				unauthorizedCompanies: new Set([276322]),
				checkActive: (path) => new RegExp('/supplier/stockLocations').test(path),
			},
			{
				to: '/supplier/parts',
				title: 'Parts',
				icon: 'icons8-screw',
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				unauthorizedCompanies: new Set([276322]),
				checkActive: (path) => new RegExp('/supplier/parts').test(path),
			},
			{
				to: '/supplier/equipments',
				title: 'Equipment',
				icon: 'icons8-drill-2',
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				unauthorizedCompanies: new Set([276322]),
				checkActive: (path) => new RegExp('/supplier/equipments').test(path),
			},
			{
				to: '/supplier/purchaseRequests',
				title: 'Purchase Requests',
				icon: 'icons8-add-shopping-cart',
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				accessPermissionName: PERMISSION_NAMES.ACCESS_PR_AND_PO,
				unauthorizedCompanies: new Set([276322]),
				checkActive: (path) => new RegExp('/supplier/purchaseRequests').test(path),
			},
			{
				to: '/supplier/purchaseOrders',
				title: 'Purchase Orders',
				icon: 'icons8-buying',
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				accessPermissionName: PERMISSION_NAMES.ACCESS_PR_AND_PO,
				unauthorizedCompanies: new Set([276322]),
				checkActive: (path) => new RegExp('/supplier/purchaseOrders').test(path),
			},
			{
				to: '/supplier/vendors',
				title: 'Vendors',
				icon: 'icons8-shop-2',
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				unauthorizedCompanies: new Set([276322]),
				checkActive: (path) => new RegExp('/supplier/vendors').test(path),
			},
		],
	},
	{
		to: '/supplier/company/me',
		title: 'Company',
		icon: 'icons8-company-2',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		checkActive: (path) => new RegExp('/supplier/company').test(path),
	},
	{
		to: '/supplier/admin',
		title: 'Admin',
		icon: 'icons8-multiple-users',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ADMIN_ONLY,
		checkActive: (path) => new RegExp('/supplier/admin').test(path),
	},
];

export const INTERNAL_SUPPLIER_MENU_ITEMS = [
	{
		to: '/supplier/dashboards',
		title: 'Dashboard',
		icon: 'icons8-binoculars',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		checkActive: (path) => new RegExp('/supplier/dashboards').test(path),
	},
	{
		to: '/supplier/workOrders',
		title: 'Work Orders',
		icon: 'icons8-wrench',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		checkActive: (path) => new RegExp('/supplier/workOrders').test(path),
	},
	{
		to: '/supplier/inbox',
		title: 'Inbox',
		icon: 'icons8-mailbox',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		checkActive: (path) => new RegExp('/supplier/inbox').test(path),
		count: (props = {}) => nullSafeGet('userNotifications.counts.TASKS_NOTIFICATIONS', props),
	},
	{
		to: '/supplier/projects',
		title: 'Projects',
		icon: 'icons8-blueprint',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		checkActive: (path) => new RegExp('/supplier/projects').test(path),
	},
	{
		to: '/supplier/siteSurveys',
		title: 'Site Surveys',
		icon: 'icons8-clipboard-checklist',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		checkActive: (path) => new RegExp('/supplier/siteSurveys').test(path),
	},
	{
		to: '/supplier/plannedMaintenance',
		title: 'Planned Maintenance',
		icon: 'icons8-refresh',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		checkActive: (path) => new RegExp('/supplier/plannedMaintenance').test(path),
	},
	{
		to: '/supplier/requestsForProposal',
		title: 'Requests For Proposal',
		icon: 'icons8-cash-in-hand',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		checkActive: (path) => new RegExp('/supplier/requestsForProposal').test(path),
	},
	{
		to: '/supplier/quotes',
		title: 'Quotes',
		icon: 'icons8-billing-machine',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		checkActive: (path) => new RegExp('/supplier/quotes').test(path),
	},
	{
		to: '/supplier/invoices',
		title: 'Invoices',
		icon: 'icons8-invoice',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		checkActive: (path) => new RegExp('/supplier/invoices').test(path),
	},
	{
		to: '/supplier/clients',
		title: 'Routing',
		icon: 'icons8-business-building-2',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ADMIN_ONLY,
		checkActive: (path) => new RegExp('/supplier/clients').test(path),
	},
	{
		to: '/supplier/locations',
		title: 'Locations',
		icon: 'icons8-store',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		checkActive: (path) => new RegExp('/supplier/locations').test(path),
	},
	{
		to: '/supplier/suppliers',
		title: 'Suppliers',
		icon: 'icons8-company-2',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		checkActive: (path) => new RegExp('/supplier/suppliers').test(path),
	},
	{
		to: '/supplier/assets',
		title: 'Assets',
		icon: 'icons8-bulldozer',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		checkActive: (path) => new RegExp('/supplier/assets').test(path),
	},
	{
		to: '/supplier/meters',
		title: 'Meters',
		icon: 'icons8-dashboard',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		checkActive: (path) => new RegExp('/supplier/meters').test(path),
	},
	{
		to: '/supplier/fieldTechs',
		title: 'Field Techs',
		icon: 'icons8-street-worker',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		checkActive: (path) => new RegExp('/supplier/fieldTechs').test(path),
	},
	{
		to: '',
		title: 'Analytics',
		icon: 'icons8-line-chart',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		accessPermissionName: PERMISSION_NAMES.ACCESS_ANALYTICS,
		children: [
			{
				to: '/supplier/analytics/assets',
				title: 'Downtime Analytics',
				icon: 'icons8-sand-watch',
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				checkActive: (path) => new RegExp('/supplier/analytics/assets').test(path),
			},
			{
				to: '/supplier/analytics/fieldTechAnalytics',
				title: 'Tech Scorecards',
				icon: 'icons8-chart',
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				checkActive: (path) => new RegExp('/supplier/analytics/fieldTechAnalytics').test(path),
			},
			{
				to: '/supplier/analytics/spend',
				title: 'Spend Analytics',
				icon: 'icons8-fund-accounting',
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				checkActive: (path) => new RegExp('/supplier/analytics/spend').test(path),
			},
			{
				to: '/supplier/analytics/work',
				title: 'Work Analytics',
				icon: 'icons8-chart',
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				checkActive: (path) => new RegExp('/supplier/analytics/work').test(path),
			},
			{
				to: '/supplier/analytics/kpi',
				title: 'Scorecards',
				icon: 'icons8-scorecard',
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				checkActive: (path) => new RegExp('/supplier/analytics/kpi').test(path),
			},
		],
	},
	{
		to: '',
		title: 'Inventory',
		icon: 'icons8-warehouse-3',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		unauthorizedCompanies: new Set([276322]),
		checkActive: (path) => new RegExp('/supplier/stockLocations').test(path),
		children: [
			{
				to: '/supplier/stockLocations',
				title: 'Stock Locations',
				icon: 'icons8-warehouse-3',
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				checkActive: (path) => new RegExp('/supplier/stockLocations').test(path),
			},
			{
				to: '/supplier/parts',
				title: 'Parts',
				icon: 'icons8-screw',
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				checkActive: (path) => new RegExp('/supplier/parts').test(path),
			},
			{
				to: '/supplier/equipments',
				title: 'Equipment',
				icon: 'icons8-drill-2',
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				checkActive: (path) => new RegExp('/supplier/equipments').test(path),
			},
			{
				to: '/supplier/purchaseRequests',
				title: 'Purchase Requests',
				icon: 'icons8-add-shopping-cart',
				accessPermissionName: PERMISSION_NAMES.ACCESS_PR_AND_PO,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				checkActive: (path) => new RegExp('/supplier/purchaseRequests').test(path),
			},
			{
				to: '/supplier/purchaseOrders',
				title: 'Purchase Orders',
				icon: 'icons8-buying',
				accessPermissionName: PERMISSION_NAMES.ACCESS_PR_AND_PO,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				checkActive: (path) => new RegExp('/supplier/purchaseOrders').test(path),
			},
			{
				to: '/supplier/vendors',
				title: 'Vendors',
				icon: 'icons8-shop-2',
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				checkActive: (path) => new RegExp('/supplier/vendors').test(path),
			},
		],
	},
	{
		to: '/supplier/depreciation',
		title: 'Accounting',
		icon: 'icons8-bulldozer',
		accessPermissionName: PERMISSION_NAMES.ACCESS_DEPRECIATION,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		checkActive: (path) => new RegExp('/supplier/depreciation').test(path),
	},
	{
		to: '/supplier/company/me',
		title: 'Company',
		icon: 'icons8-company-2',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		checkActive: (path) => new RegExp('/supplier/company').test(path),
	},
	{
		to: '/supplier/admin',
		title: 'Admin',
		icon: 'icons8-multiple-users',
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ADMIN_ONLY,
		checkActive: (path) => new RegExp('/supplier/admin').test(path),
	},
];

export const MENU_ITEMS_BY_ROLE = {
	supplier: SUPPLIER_MENU_ITEMS,
	internal_supplier: INTERNAL_SUPPLIER_MENU_ITEMS,
	buyer: BUYER_MENU_ITEMS,
};

export const HOME_PAGE_BY_ROLE = {
	supplier: '/supplier/workOrders',
	buyer: '/buyer/workOrders',
};

export const DASHBOARD_HOME_PAGE_BY_ROLE = {
	supplier: '/supplier/dashboards/overview/all',
	buyer: '/buyer/dashboards/overview/all',
};

export const LOGIN_PAGE_BY_ROLE = {
	unknown: '/login',
	supplier: '/supplier/login',
	buyer: '/buyer/login',
};

export const NEW_ACCOUNT_SETUP_PAGE_BY_ROLE = {
	supplier: '/supplier/newAccountSetup',
	buyer: '/buyer/newAccountSetup',
};

export const SIGNUP_COMPLETION_PAGE_BY_ROLE = {
	supplier: '/supplier/signupCompletion',
	buyer: '/buyer/signupCompletion',
};

export const NEED_EMAIL_AUTHENTICATION_PAGE_BY_ROLE = {
	supplier: '/supplier/needEmailAuthentication',
	buyer: '/buyer/needEmailAuthentication',
};

export const getMenuItemsByRole = (role, featureFlags) => {
	return MENU_ITEMS_BY_ROLE[role];
};
export const getHomePageByRole = (role, featureFlags) => {
	return DASHBOARD_HOME_PAGE_BY_ROLE[role];
};
export const getNewAccountSetupPageByRole = (role) => NEW_ACCOUNT_SETUP_PAGE_BY_ROLE[role];
export const getSignupCompletionPageByRole = (role) => SIGNUP_COMPLETION_PAGE_BY_ROLE[role];
export const getNeedEmailAuthenticationPageByRole = (role) =>
	NEED_EMAIL_AUTHENTICATION_PAGE_BY_ROLE[role];
export const getLoginPageByRole = (role) => LOGIN_PAGE_BY_ROLE[role];

import React, { FC, useEffect, useMemo, useState } from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { locationsRestCrudThunksForSupplier } from '../../thunks/locations_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import SupplierLocationPageDisplay from '../supplier_location_page_display/SupplierLocationPageDisplay';

const { Content } = Layout;

require('./SupplierLocationsDetailDetailsPage.less');

const SupplierLocationsDetailPage: FC<any> = ({
	getLocation,
	match,
	location,
	locationsFetching,
}): React.ReactElement => {
	const [propsLoaded, setPropsLoaded] = useState(false);

	useEffect(() => setPropsLoaded(true), []);

	const locationId = useMemo(() => match.params.id, [match.params.id]);

	useEffect(() => {
		getLocation(locationId);
	}, [getLocation, locationId, match.params.id]);

	return locationsFetching || !propsLoaded ? (
		<PageLoadingPlaceholder />
	) : (
		<Content className="supplierLocationsDetailDetailsPage">
			<LogOnMountWithStandardEventProperties eventType="visited supplier location detail page" />

			<SupplierLocationPageDisplay record={location} locationsFetching={locationsFetching} />
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	locationsFetching: state.locations.fetching,
	location: state.locations.detail,
	match: ownProps.match,
});

const mapDispatchToProps = (dispatch) => ({
	getLocation: (id) => dispatch(locationsRestCrudThunksForSupplier.readOne(id)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SupplierLocationsDetailPage)
);

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { budgetsRestCrudThunksForBuyer } from '../../thunks/budget_thunks';
import BudgetForm from '../budget_form/BudgetForm';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';

const queryString = require('qs');

class EditBudgetPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			backLinkParams: '',
		};
	}
	componentDidMount() {
		const { getBudget, match, location } = this.props;
		const budgetId = match.params.id;
		getBudget(budgetId).then((budget) => {
			//Setup back button persistence
			let searchString = location.search;
			if (searchString[0] === '?') {
				searchString = searchString.slice(1);
			}
			const queryParams = queryString.parse(searchString);
			if (queryParams.backlinkParams) {
				const { tab, pathName, ...otherParams } = queryString.parse(queryParams.backlinkParams);
				const finalPath = pathName || `/buyer/workOrders/detail/${budgetId}/details`;
				this.setState({
					backLinkParams: new URLSearchParams(queryParams).toString(),
				});
			}
		});
	}

	render() {
		const { history, budget, fetching } = this.props;

		//noinspection TypeScriptUnresolvedVariable
		return fetching ? (
			<PageLoadingPlaceholder />
		) : (
			[
				<LogOnMountWithStandardEventProperties eventType="visited buyer budget form" />,
				<EditPage
					title="Edit Budget"
					formComponentProps={{
						formData: budget,
						isEditForm: true,
						onSuccess: (rec) =>
							history.push(
								`/buyer/budgets/detail/${rec.id}/details`.concat('?', this.state.backLinkParams)
							),
					}}
					fetching={fetching}
					FormComponent={BudgetForm}
				/>,
			]
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		history: ownProps.history,
		budget: state.budgets.detail,
		fetching: state.budgets.fetching,
		match: ownProps.match,
	};
};

const mapDispatchToProps = (dispatch) => ({
	getBudget: (id) => dispatch(budgetsRestCrudThunksForBuyer.readOne(id)),
});

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(EditBudgetPage));

import { REQUESTED_PARTS_CRUD_ACTION_CREATORS } from '../actions/requested_parts_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import FetchUtils, { parseJSON } from './fetch_utils';
import { logoutSuccess } from '../actions/session_actions';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { message } from 'antd';

const baseUrl = getBackendUri();
const fetchUtils = new FetchUtils();

export const requestedPartsRestCrudThunksForBuyer = new RestCrudThunks(
	'work_order_requested_parts',
	'work_order_requested_part',
	'requested_part',
	'requested_parts',
	REQUESTED_PARTS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'work_order/'
);
export const requestedPartsRestCrudThunksForSupplier = new RestCrudThunks(
	'work_order_requested_parts',
	'work_order_requested_part',
	'requested_part',
	'requested_parts',
	REQUESTED_PARTS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'work_order/'
);

const releaseRequestedQuantity = (roleType) => (entity, data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(REQUESTED_PARTS_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.patch(
					baseUrl +
						`/api/v1/${roleType}/work_order/work_order_requested_parts/${entity.id}/release`,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data,
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					const d = json;
					if (d.status === 'error') {
						dispatch(REQUESTED_PARTS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(REQUESTED_PARTS_CRUD_ACTION_CREATORS.updateSuccess(entity));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(REQUESTED_PARTS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

export const releaseRequestedQuantityForSupplier = releaseRequestedQuantity(ROLE_TYPES.SUPPLIER);

const addShipmentToRequestedQuantity = (roleType) => (entity, data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(REQUESTED_PARTS_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.patch(
					baseUrl +
						`/api/v1/${roleType}/work_order/work_order_requested_parts/${entity.id}/add_shipment`,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data,
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					const d = json;
					if (d.status === 'error') {
						dispatch(REQUESTED_PARTS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(REQUESTED_PARTS_CRUD_ACTION_CREATORS.updateSuccess(entity));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(REQUESTED_PARTS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

export const addShipmentToRequestedQuantityForSupplier = addShipmentToRequestedQuantity(
	ROLE_TYPES.SUPPLIER
);

const revertShipment = (roleType) => (entity) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(REQUESTED_PARTS_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.patch(
					baseUrl +
						`/api/v1/${roleType}/work_order/work_order_requested_parts/${entity.id}/revert_shipment`,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					const d = json;
					if (d.status === 'error') {
						dispatch(REQUESTED_PARTS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(REQUESTED_PARTS_CRUD_ACTION_CREATORS.updateSuccess(entity));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(REQUESTED_PARTS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

export const revertShipmentForSupplier = revertShipment(ROLE_TYPES.SUPPLIER);

const updateTrackingNumber = (roleType) => (entity, data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(REQUESTED_PARTS_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.patch(
					baseUrl +
						`/api/v1/${roleType}/work_order/work_order_requested_parts/${entity.id}/add_tracking_number`,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data,
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					const d = json;
					if (d.status === 'error') {
						dispatch(REQUESTED_PARTS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(REQUESTED_PARTS_CRUD_ACTION_CREATORS.updateSuccess(entity));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(REQUESTED_PARTS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

export const updateTrackingNumberForSupplier = updateTrackingNumber(ROLE_TYPES.SUPPLIER);

const addShipmentsBulkToRequestedQuantity = (roleType) => (data) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(REQUESTED_PARTS_CRUD_ACTION_CREATORS.updateStart({}));

			fetchUtils
				.patch(
					baseUrl + `/api/v1/${roleType}/work_order/work_order_requested_parts/add_shipments_bulk`,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data,
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					const d = json;
					if (d.status === 'error') {
						dispatch(REQUESTED_PARTS_CRUD_ACTION_CREATORS.updateError(d.message, {}));
						reject(d.message);
					} else {
						dispatch(REQUESTED_PARTS_CRUD_ACTION_CREATORS.updateSuccess({}));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(REQUESTED_PARTS_CRUD_ACTION_CREATORS.updateError(d.message, {}));
					reject(d.message);
				});
		});
	};
};

export const addShipmentsBulkToRequestedQuantityForSupplier = addShipmentsBulkToRequestedQuantity(
	ROLE_TYPES.SUPPLIER
);

export const shipmentsCsvReportGenerator = (roleType) => (params) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/work_order/work_order_requested_parts/csv_report_generator`;

	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(
					baseUrl + apiUrl,
					Object.assign(
						{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
						params
					)
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
						message.error(d.data);
					} else {
						resolve(d.data);
						const hide = message.loading(d.data, 0);
						setTimeout(hide, 7000);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const shipmentsCsvReportGeneratorForSupplier = shipmentsCsvReportGenerator(
	ROLE_TYPES.SUPPLIER
);

export const shipmentsPDFReportGenerator = (roleType) => (params) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/work_order/work_order_requested_parts/download`;

	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(
					baseUrl + apiUrl,
					Object.assign(
						{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
						params
					)
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
						message.error(d.data);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const shipmentsPDFReportGeneratorForSupplier = shipmentsPDFReportGenerator(
	ROLE_TYPES.SUPPLIER
);

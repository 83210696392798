import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Steps, Row, Col, Card, Table, Layout, Spin } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import EditSupplierLicensesForm from '../edit_supplier_licenses_form/EditSupplierLicensesForm';
import { KeyValueDisplay } from '../key_value_display/KeyValueDisplay';
import { dateFormatter } from '../../utils/DataFormatterUtils';
import { EmptyState } from '../empty_state/EmptyState';
import { AddressDisplay } from '../address_display/AddressDisplay';
import FileUploader from '../file_uploader/FileUploader';
import EditSupplierCertificationsForm from '../edit_supplier_certifications_form/EditSupplierCertificationsForm';
import EditSupplierInsuranceCoverageForm from '../edit_supplier_insurance_coverage_form/EditSupplierInsuranceCoverageForm';
import {
	supplierFacilityReviewsRestCrudThunksForSupplier,
	sendReferralReviewsForSupplier,
} from '../../thunks/supplier_facility_reviews_thunks';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { suppliersRestCrudThunksForSupplier } from '../../thunks/suppliers_thunks';
import SupplierCompanyForm from '../supplier_company_form/SupplierCompanyForm';
import { LocationAutocomplete } from '../location_autocomplete/LocationAutocomplete';
import { supplierFacilitiesRestCrudThunksForSupplier } from '../../thunks/supplier_facilities_thunks';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import SupplierContactContactMethodsForm from '../supplier_contact_contact_methods_form/SupplierContactContactMethodsForm';

const style = require('./PostSignUpSupplierForm.less');
const Step = Steps.Step;
const { Content } = Layout;

interface PostSignUpSupplierFormProps extends FormComponentProps {
	history: any;
	onSubmit: any;
	onCancel: any;
	redirectForwardUrl?: string;
	onSuccess?: any;
	creating: boolean;
	updating: boolean;
	formData: any;
	createErrors: any[];
	updateErrors: any[];
	currentUser: any;
	match: any;
	supplierFacilityReviews: any;
	supplierFacilitiesFetching: boolean;
	supplierFacility: any;
	fetchSupplierCompany: any;
	fetchSupplierFacility: any;
	fetchSupplierFacilityReviews: any;
	updateSupplierCompany: any;
	sendReferralReviews: any;
}

let emailAddressUuid = 3;
let serviceAreaSelfPerformingUuid = 1;
let serviceAreaNotSelfPerformingUuid = 1;

class PostSignUpSupplierForm extends React.Component<PostSignUpSupplierFormProps, any> {
	constructor(props) {
		super(props);
		this.state = {
			activeStep: 1,
			totalSteps: 4,
			loading: true,
			editingLicenses: false,
			editingCertifications: false,
			editingInsuranceCoverage: false,
			supplierCompany: undefined,
			supplierAssociated: true,
		};
		this.goNext = this.goNext.bind(this);
		this.goPrev = this.goPrev.bind(this);
		this.goSkip = this.goSkip.bind(this);
	}

	componentDidMount() {
		const {
			currentUser,
			fetchSupplierCompany,
			fetchSupplierFacility,
			fetchSupplierFacilityReviews,
		} = this.props;
		const supplierFacilityId = currentUser.facility ? currentUser.facility.id : undefined;

		if (supplierFacilityId !== undefined) {
			//this.setState({activeStep: 2});
			fetchSupplierFacility(supplierFacilityId).then((supplierFacility) => {
				fetchSupplierCompany(supplierFacility.supplierCompanyId).then((supplierCompany) => {
					this.setState({ supplierCompany: supplierCompany });
				});
				this.setState({
					loading: false,
				});
			});

			fetchSupplierFacilityReviews(supplierFacilityId);
		} else {
			this.setState({ supplierAssociated: false });
		}
	}

	componentWillReceiveProps(newProps) {
		if (
			nullSafeGet('facility.id', this.props.currentUser) !==
			nullSafeGet('facility.id', newProps.currentUser)
		) {
			const {
				currentUser,
				fetchSupplierCompany,
				fetchSupplierFacility,
				fetchSupplierFacilityReviews,
			} = newProps;
			const supplierFacilityId = nullSafeGet('facility.id', currentUser);
			fetchSupplierFacility(supplierFacilityId).then((supplierFacility) => {
				fetchSupplierCompany(supplierFacility.supplierCompanyId).then((supplierCompany) => {
					this.setState({ supplierCompany: supplierCompany });
				});
				this.setState({
					loading: false,
				});
			});

			fetchSupplierFacilityReviews(supplierFacilityId);
		}
	}

	handleEditToggle = (toggleKey) => {
		const currentState = this.state[toggleKey];
		this.setState({ [toggleKey]: !currentState });
	};

	handleEditCancel = (toggleKey) => {
		this.setState({ [toggleKey]: false });
	};

	handleServiceAreaSubmit = () => {
		const { form, updateSupplierCompany } = this.props;
		const { supplierCompany } = this.state;
		form.validateFields((err) => {
			if (!err) {
				const serviceAreaSelfPerformingKeys = form.getFieldValue('serviceAreaSelfPerformingKeys');
				const serviceAreaNotSelfPerformingKeys = form.getFieldValue(
					'serviceAreaNotSelfPerformingKeys'
				);
				const serviceAreaSelfPerforming = serviceAreaSelfPerformingKeys
					.map((k) => form.getFieldValue(`serviceAreas-${k}-SelfPerforming`))
					.filter((sa) => sa !== undefined);
				const serviceAreaSelfPerformingParsed = serviceAreaSelfPerformingKeys
					.map((k) => form.getFieldValue(`serviceAreas-${k}-SelfPerformingParsedAddress`))
					.filter((sa) => sa !== undefined);
				const serviceAreaNotSelfPerforming = serviceAreaNotSelfPerformingKeys
					.map((k) => form.getFieldValue(`serviceAreas-${k}-NotSelfPerforming`))
					.filter((sa) => sa !== undefined);
				const serviceAreaNotSelfPerformingParsed = serviceAreaNotSelfPerformingKeys
					.map((k) => form.getFieldValue(`serviceAreas-${k}-NotSelfPerformingParsedAddress`))
					.filter((sa) => sa !== undefined);
				const updatedSupplier = {
					...supplierCompany,
					serviceAreaSelfPerforming: serviceAreaSelfPerforming,
					serviceAreaNotSelfPerforming: serviceAreaNotSelfPerforming,
					serviceAreaSelfPerformingParsedAddress: serviceAreaSelfPerformingParsed,
					serviceAreaNotSelfPerformingParsedAddress: serviceAreaNotSelfPerformingParsed,
				};
				updateSupplierCompany(updatedSupplier);
			}
		});
	};

	handleRequestReviewSubmit = () => {
		const { form, sendReferralReviews, currentUser } = this.props;

		form.validateFields((err) => {
			if (!err) {
				// sending emails out for reviewd
				const emailAddressKeys = form.getFieldValue('emailAddressKeys');
				const emailAddresses = emailAddressKeys
					.map((k) => form.getFieldValue(`emailAddresses-${k}`))
					.filter((ea) => ea !== undefined && ea != '');
				const reviewReferral = {
					referrerEmail: currentUser.email,
					refereeEmails: emailAddresses,
				};
				sendReferralReviews(reviewReferral);
			}
		});
	};

	goNext(validationSteps) {
		const { form } = this.props;
		const { activeStep } = this.state;
		form.validateFields(validationSteps, (err) => {
			if (err) {
				return;
			}
			if (activeStep === 2) {
				this.handleServiceAreaSubmit();
				this.setState({ activeStep: activeStep + 1 });
			} else if (activeStep === 3) {
				this.handleRequestReviewSubmit();
				this.setState({ activeStep: activeStep + 1 });
			} else {
				this.setState({ activeStep: activeStep + 1 });
			}
		});
	}

	goSkip() {
		const { activeStep } = this.state;
		this.setState({ activeStep: activeStep + 1 });
		return true;
	}

	goPrev() {
		const { activeStep } = this.state;
		if (activeStep === 1) {
			return false;
		}
		this.setState({ activeStep: activeStep - 1 });
		return true;
	}

	handleFileAttachmentUploadChange = (newAttachments) => {
		const { form } = this.props;
		form.setFieldsValue({ buyerAttachments: newAttachments });
	};

	addServiceAreaSelfPerforming = () => {
		const { form } = this.props;
		const serviceAreaSelfPerformingKeys = form.getFieldValue('serviceAreaSelfPerformingKeys');
		const nextKeys = serviceAreaSelfPerformingKeys.concat(serviceAreaSelfPerformingUuid);
		form.setFieldsValue({
			serviceAreaSelfPerformingKeys: nextKeys,
		});
		serviceAreaSelfPerformingUuid++;
	};

	removeServiceAreaSelfPerforming = (k) => {
		const { form } = this.props;
		const serviceAreaSelfPerformingKeys = form.getFieldValue('serviceAreaSelfPerformingKeys');
		if (serviceAreaSelfPerformingKeys.length === 1) {
			return;
		}
		form.setFieldsValue({
			serviceAreaSelfPerformingKeys: serviceAreaSelfPerformingKeys.filter((key) => key !== k),
		});
	};

	addServiceAreaNotSelfPerforming = () => {
		const { form } = this.props;
		const serviceAreaNotSelfPerformingKeys = form.getFieldValue('serviceAreaNotSelfPerformingKeys');
		const nextKeys = serviceAreaNotSelfPerformingKeys.concat(serviceAreaNotSelfPerformingUuid);
		form.setFieldsValue({
			serviceAreaNotSelfPerformingKeys: nextKeys,
		});
		serviceAreaNotSelfPerformingUuid++;
	};

	removeServiceAreaNotSelfPerforming = (k) => {
		const { form } = this.props;
		const serviceAreaNotSelfPerformingKeys = form.getFieldValue('serviceAreaNotSelfPerformingKeys');
		if (serviceAreaNotSelfPerformingKeys.length === 1) {
			return;
		}
		form.setFieldsValue({
			serviceAreaNotSelfPerformingKeys: serviceAreaNotSelfPerformingKeys.filter((key) => key !== k),
		});
	};

	addEmailAddress = () => {
		const { form } = this.props;
		const emailAddressKeys = form.getFieldValue('emailAddressKeys');
		const nextKeys = emailAddressKeys.concat(emailAddressUuid);
		form.setFieldsValue({
			emailAddressKeys: nextKeys,
		});
		emailAddressUuid++;
	};

	removeEmailAddress = (k) => {
		const { form } = this.props;
		const emailAddressKeys = form.getFieldValue('emailAddressKeys');
		if (emailAddressKeys.length === 1) {
			return;
		}
		form.setFieldsValue({
			emailAddressKeys: emailAddressKeys.filter((key) => key !== k),
		});
	};

	render() {
		const {
			createErrors,
			updateErrors,
			creating,
			updating,
			form,
			currentUser,
			supplierFacility,
			supplierFacilitiesFetching,
		} = this.props;

		const { getFieldDecorator, getFieldValue } = form;
		const {
			activeStep,
			editingLicenses,
			editingCertifications,
			editingInsuranceCoverage,
			loading,
			supplierAssociated,
		} = this.state;
		const isLoading = creating || updating;

		const getNextText = (activeStep) => {
			if (activeStep === 2) {
				return 'Update Service Area';
			} else if (activeStep === 3) {
				return 'Request Reviews';
			}
			return 'Next';
		};

		const policyDocColumns = [
			{
				title: 'Policy',
				dataIndex: 'policyName',
			},
			{
				title: 'Amount',
				dataIndex: 'amount',
				render: (text) => <span>${text.toLocaleString('en')}</span>,
			},
			{
				title: 'Expires',
				dataIndex: 'expiration',
				render: (text) => dateFormatter(text, 'MMM D, YYYY'),
			},
			{
				title: 'Attachment',
				dataIndex: 'policyFile',
				render: (text) =>
					text ? (
						<FileUploader roleType="supplier" defaultFileList={[text]} uploaderType="readOnly" />
					) : (
						'--'
					),
			},
		];

		const serviceAreaSelfPerformingKeysInitialValue = [0];
		getFieldDecorator('serviceAreaSelfPerformingKeys', {
			initialValue: serviceAreaSelfPerformingKeysInitialValue,
		});

		const serviceAreaSelfPerformingKeys = getFieldValue('serviceAreaSelfPerformingKeys');

		const serviceAreaNotSelfPerformingKeysInitialValue = [0];
		getFieldDecorator('serviceAreaNotSelfPerformingKeys', {
			initialValue: serviceAreaNotSelfPerformingKeysInitialValue,
		});

		const serviceAreaNotSelfPerformingKeys = getFieldValue('serviceAreaNotSelfPerformingKeys');

		const step1 = () => {
			return (
				<div>
					{loading && supplierAssociated ? (
						<PageLoadingPlaceholder />
					) : (
						<Row style={{ margin: '0.5em -8px' }} gutter={16}>
							<Col span={24}>
								<SupplierCompanyForm
									formData={supplierFacility}
									minimal={true}
									newAccountSetupMode={true}
									onSubmit={() => this.props.history.push('/supplier/suppliers/me')}
								/>
							</Col>
						</Row>
					)}
				</div>
			);
		};

		// const step2 = () => {
		//     return (
		//         <div>
		//             <Row style={{margin: '0.5em -8px'}} gutter={16}>
		//                 <Col span={24}>
		//                     <h6 style={{marginBottom: 24}}>
		//                         Areas where you perform service yourself
		//                     </h6>
		//                     {
		//                         [
		//                             serviceAreaSelfPerformingFormItems,
		//                             <Form.Item>
		//                                 <Button type="dashed" onClick={() =>
		//                                     this.addServiceAreaSelfPerforming()} style={{width: '100%'}}>
		//                                     <Icon type="plus"/> Add Service Area
		//                                 </Button>
		//                             </Form.Item>
		//                         ]
		//                     }
		//                 </Col>
		//             </Row>
		//             <Row style={{margin: '0.5em -8px'}} gutter={16}>
		//                 <Col span={24}>
		//                     <h6 style={{marginBottom: 24}}>
		//                         Areas where you subcontract service
		//                     </h6>
		//                     {
		//                         [
		//                             serviceAreaNotSelfPerformingFormItems,
		//                             <Form.Item>
		//                                 <Button type="dashed" onClick={() =>
		//                                     this.addServiceAreaNotSelfPerforming()} style={{width: '100%'}}>
		//                                     <Icon type="plus"/> Add Service Area
		//                                 </Button>
		//                             </Form.Item>
		//                         ]
		//                     }
		//                 </Col>
		//             </Row>
		//             <Button
		//                 style={{opacity: 1}}
		//                 size="large"
		//                 onClick={this.goSkip}
		//             >
		//                 Skip
		//             </Button>
		//             <Button
		//                 type="primary"
		//                 size="large"
		//                 style={{marginLeft: 16}}
		//                 loading={isLoading}
		//                 onClick={() => this.goNext([])}
		//             >
		//                 {getNextText(activeStep)}
		//             </Button>
		//         </div>
		//     );
		// };
		//
		// const reviews = getRecordsForTargetCollection(supplierFacilityReviews, 'supplierDetailReviews');
		// const numOfReviews = reviews.length;
		// const averageRating =
		//     reviews.length > 0 ? (
		//         (reviews.reduce((acc, r) => (acc + r.rating), 0) / numOfReviews).toFixed(1)
		//     ) : (
		//         '0'
		//     );
		// const roundedRatings = reviews.map(r => Math.round(r.rating));
		// const ratingsByStar = [1, 2, 3, 4, 5].reduce(
		//     (acc, el) => {
		//         acc[el] = roundedRatings.filter(r => r === el).length;
		//         return acc;
		//     },
		//     {}
		// );
		//
		// const emailAddressKeysInitialValue = [0, 1, 2];
		// getFieldDecorator('emailAddressKeys', {initialValue: emailAddressKeysInitialValue});
		//
		// const emailAddressKeys = getFieldValue('emailAddressKeys');
		//
		// const emailAddressFormItems = emailAddressKeys.map((k, emailAddressIndex) => {
		//     return (
		//         <div
		//             key={k}
		//             style={{display: 'flex', alignItems: 'center', marginBottom: 16}}
		//         >
		//             <div style={{fontSize: '16px', color: 'black'}}>
		//                 Customer Email {emailAddressIndex + 1}
		//             </div>
		//             <div style={{marginLeft: 16}}>
		//                 {getFieldDecorator(`emailAddresses-${k}`, {
		//                     validateTrigger: ['onChange', 'onBlur']
		//                 })(
		//                     <Input style={{width: 400}}/>
		//                 )}
		//             </div>
		//             <div>
		//                 {emailAddressKeys.length > 1 ? (
		//                     <Icon
		//                         className="dynamic-delete-button"
		//                         type="close"
		//                         onClick={() => this.removeEmailAddress(k)}
		//                     />
		//                 ) : null}
		//             </div>
		//         </div>
		//     );
		// });
		//
		// const step3 = () => {
		//     return (
		//         <div>
		//             <Row style={{margin: '0.5em -8px'}} gutter={16}>
		//                 <Col span={24}>
		//                     {
		//                         reviews.length > 0 ? (
		//                             <Card title="Your Current Reviews">
		//                                 <div>
		//                                     <div style={{
		//                                         display: 'flex',
		//                                         alignItems: 'center',
		//                                         marginLeft: 16,
		//                                         marginTop: 8,
		//                                         marginBottom: 32
		//                                     }}>
		//                                         <div>
		//                                             <div style={{fontSize: 22, fontWeight: 'bold'}}>
		//                                                 {averageRating}
		//                                             </div>
		//                                             <div style={{marginBottom: 4}}>
		//                                                 <Rate
		//                                                     style={{fontSize: 18}}
		//                                                     allowHalf={true}
		//                                                     value={Math.round(parseFloat(averageRating) * 2) / 2}
		//                                                     disabled={true}
		//                                                 />
		//                                             </div>
		//                                             <div>
		//                                                 {numOfReviews} reviews
		//                                             </div>
		//                                         </div>
		//                                         <Divider
		//                                             style={{
		//                                                 height: 80,
		//                                                 marginLeft: 32,
		//                                                 marginRight: 32
		//                                             }}
		//                                             type="vertical"
		//                                         />
		//                                         <div>
		//                                             {
		//                                                 [5, 4, 3, 2, 1].map((el, idx) => (
		//                                                     <div style={{fontSize: 12}}>
		//                                                             <span>
		//                                                                 {el}
		//                                                             </span>
		//                                                         <Icon
		//                                                             style={{
		//                                                                 color: 'rgba(0, 0, 0, 0.1)',
		//                                                                 marginLeft: 4
		//                                                             }}
		//                                                             type="star"
		//                                                         />
		//                                                         <div
		//                                                             style={{
		//                                                                 display: 'inline-block',
		//                                                                 position: 'relative',
		//                                                                 width: (104),
		//                                                                 marginLeft: 8,
		//                                                                 borderRadius: '5px',
		//                                                                 height: 8,
		//                                                                 overflow: 'hidden',
		//                                                                 background: 'rgba(0,0,0,0.1)'
		//                                                             }}
		//                                                         >
		//                                                             <span
		//                                                                 style={{
		//                                                                     display: 'inline-block',
		//                                                                     position: 'absolute',
		//                                                                     width: (104 * (numOfReviews ? (ratingsByStar[el] / numOfReviews) : (0))),
		//                                                                     height: 8,
		//                                                                     background: 'rgb(250, 219, 20)'
		//                                                                 }}
		//                                                             />
		//                                                         </div>
		//                                                         <span style={{marginLeft: 8}}>
		//                                                                 {numOfReviews ? (floatToPercentageString(ratingsByStar[el] / numOfReviews, 0)) : (floatToPercentageString(0, 0))}, {ratingsByStar[el]}
		//                                                             </span>
		//                                                     </div>
		//                                                 ))
		//                                             }
		//                                         </div>
		//                                     </div>
		//                                     <Divider/>
		//                                     <div>
		//                                         {
		//                                             reviews.map((review, idx) => (
		//                                                 <div key={review.id}
		//                                                      style={{marginLeft: 16, marginBottom: 24}}>
		//                                                     <SupplierReview
		//                                                         userType="buyer"
		//
		//                                                         review={review}
		//                                                     />
		//                                                 </div>
		//                                             ))
		//                                         }
		//                                     </div>
		//                                 </div>
		//                             </Card>
		//                         ) : (null)
		//                     }
		//                 </Col>
		//             </Row>
		//             {emailAddressFormItems}
		//             <Form.Item>
		//                 <Button type="dashed" onClick={() => this.addEmailAddress()} style={{width: '100%'}}>
		//                     <Icon type="plus"/> Add Email Address
		//                 </Button>
		//             </Form.Item>
		//             <Button
		//                 style={{opacity: 1}}
		//                 size="large"
		//                 onClick={this.goPrev}
		//             >
		//                 Back
		//             </Button>
		//             <Button
		//                 style={{opacity: 1, marginLeft: 16}}
		//                 size="large"
		//                 onClick={this.goSkip}
		//             >
		//                 Skip
		//             </Button>
		//             <Button
		//                 type="primary"
		//                 size="large"
		//                 style={{marginLeft: 16}}
		//                 loading={isLoading}
		//                 onClick={() => this.goNext(
		//                     [`emailAddresses-1`, `emailAddresses-2`, `emailAddresses-3`])}
		//             >
		//                 {getNextText(activeStep)}
		//             </Button>
		//         </div>
		//     );
		// };

		const step5 = () => {
			return (
				<div>
					<Row style={{ margin: '0.5em -8px' }} gutter={16}>
						<Col span={24}>
							<SupplierContactContactMethodsForm
								redirectForwardUrl={'/supplier/workOrders'}
								formData={currentUser}
								goPrev={this.goPrev}
							/>
						</Col>
					</Row>
				</div>
			);
		};

		const steps = [
			{
				shortTitle: 'Your Company',
				title: 'Welcome!',
				subtitle: "Let's start by getting your company set up.",
				content: step1(),
			},
			// {
			//     shortTitle: 'Service Coverage',
			//     title: 'Buyers can\'t hire you if they can\'t find you.',
			//     subtitle: 'Please list the states, counties, cities or zip codes where you provide service so that potential customers can find your company.',
			//     content: step2(),
			// },
			// {
			//     shortTitle: 'Reviews',
			//     title: '92% of business-to-business buyers are more likely to purchase after reading a trusted review.',
			//     subtitle: 'Invite your best customers to leave you a review on OpenWrench.',
			//     content: step3(),
			// },
			// {
			//     shortTitle: 'Licenses and Insurance',
			//     title: '78% of buyers on OpenWrench want to see license and insurance coverage before making a purchase.',
			//     subtitle: 'Add your licenses, certifications, insurance coverage so buyers don\'t need to ask.',
			//     content: step4(),
			// },
			// {
			//     shortTitle: 'Your Account',
			//     title: 'Give your customers a way to reach you',
			//     subtitle: null,
			//     content: step5()
			// }
		];

		return (
			<div className="newPreferredSupplierForm" style={{ minHeight: '100%' }}>
				<div style={{ padding: 40 }}>
					<img
						width="auto"
						height="48px"
						src="https://emptor-data-assets.s3.amazonaws.com/images/openwrench-logos/openwrench-new-logo-black.svg"
					/>
				</div>
				<Card style={{ maxWidth: 720, margin: 'auto' }} bodyStyle={{ padding: '40px 80px' }}>
					<div>
						{createErrors.length > 0 ? (
							<Alert style={{ marginBottom: 24 }} message={createErrors.join(' ')} type="error" />
						) : null}
						{updateErrors.length > 0 ? (
							<Alert style={{ marginBottom: 24 }} message={updateErrors.join(' ')} type="error" />
						) : null}
						{steps.map((step, idx) => (
							<div style={{ display: idx === activeStep - 1 ? 'block' : 'none' }}>
								<div style={{ marginBottom: 40 }}>
									<h4 className="preferredSupplierFlowStepTitle">{step.title}</h4>
									{step.subtitle ? (
										<div className="h6 preferredSupplierForm__subtitle">{step.subtitle}</div>
									) : null}
								</div>
								<div className="preferredSupplierFlowSteps" style={{ textAlign: 'left' }}>
									{step.content}
								</div>
							</div>
						))}
					</div>
				</Card>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,
	createErrors: state.supplier_facilities.createErrors,
	updateErrors: state.supplier_facilities.updateErrors,
	formData: ownProps.formData,
	creating: state.supplier_facilities.creating,
	updating: state.supplier_facilities.updating,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	supplierFacilityReviews: state.supplier_facility_reviews,
	supplierFacilitiesFetching: state.supplier_facilities.fetching,
	supplierFacility: state.supplier_facilities.detail,
});

const mapDispatchToProps = (dispatch) => ({
	fetchSupplierCompany: (id) => dispatch(suppliersRestCrudThunksForSupplier.readOne(id)),
	fetchSupplierFacility: (id) => dispatch(supplierFacilitiesRestCrudThunksForSupplier.readOne(id)),
	fetchSupplierFacilityReviews: (supplierFacilityId) =>
		dispatch(
			supplierFacilityReviewsRestCrudThunksForSupplier.read(
				{ supplierFacilityId },
				'supplierDetailReviews'
			)
		),
	updateSupplierCompany: (entity) => dispatch(suppliersRestCrudThunksForSupplier.update(entity)),
	sendReferralReviews: (entity) => dispatch(sendReferralReviewsForSupplier(entity)),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<PostSignUpSupplierFormProps>()(PostSignUpSupplierForm))
);

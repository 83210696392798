import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Alert } from 'antd';
import { connect } from 'react-redux';
import { signUp } from '../../thunks/session_thunks';
import { SUPPLIER_ROLES, SUPPLIER_ADMIN } from '../roles/roles';
import { getObjectValues } from '../../utils/DataAccessUtils';
import { withRouter } from 'react-router';
import { FormComponentProps } from '@ant-design/compatible/es/form';

const style = require('./SupplierClaimSignUpForm.less');
const FormItem = Form.Item;

interface SupplierClaimSignupFormProps extends FormComponentProps {
	signUp: any;
	loading: boolean;
	onSubmit: any;
	username: string;
	history: any;
	password: string;
	facilityId: string;
	errors: string[];
}

interface SupplierClaimSignupFormState {
	confirmDirty: boolean;
	autoCompleteResult: string[];
	uploadLoading: boolean;
	verificationEmailSent: boolean;
	newUserEmail: string;
	newUserFirstName: string;
	newUserLastName: string;
}

const roles = getObjectValues(SUPPLIER_ROLES);

class SupplierClaimSignupForm extends React.Component<
	SupplierClaimSignupFormProps,
	SupplierClaimSignupFormState
> {
	state = {
		confirmDirty: false,
		autoCompleteResult: [],
		uploadLoading: false,
		verificationEmailSent: false,
		newUserEmail: '',
		newUserFirstName: '',
		newUserLastName: '',
	};

	componentDidMount() {}

	handleSubmit = (e) => {
		e.preventDefault();
		const { form, onSubmit } = this.props;
		form.validateFieldsAndScroll((err, values) => {
			if (!err) {
				const [nameGiven, nameFamily] = values.fullName.split(' ');
				const userRoles = values.roles || [SUPPLIER_ADMIN];
				this.props
					.signUp(
						values.email,
						values.password,
						nameGiven,
						nameFamily,
						values.gender,
						userRoles,
						values.facilityId
					)
					.then((newUser) => {
						this.setState({
							verificationEmailSent: true,
							newUserEmail: newUser.email,
							newUserFirstName: newUser.nameGiven,
							newUserLastName: newUser.nameFamily,
						});
						onSubmit();
					})
					.catch((err) => {
						console.log(err.message);
					});
			}
		});
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { loading, errors, facilityId } = this.props;

		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
				sm: { span: 6 },
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 14 },
			},
		};
		const tailFormItemLayout = {
			wrapperCol: {
				xs: {
					span: 24,
					offset: 0,
				},
				sm: {
					span: 14,
					offset: 6,
				},
			},
		};

		return (
			<Form hideRequiredMark={true} onSubmit={this.handleSubmit}>
				{errors.length > 0
					? errors.map((error, idx) => (
							<FormItem key={idx}>
								<Alert message={error} type="error" />
							</FormItem>
					  ))
					: null}
				{getFieldDecorator('facilityId', {
					initialValue: facilityId,
				})}
				<FormItem {...formItemLayout} label="Name" hasFeedback>
					{getFieldDecorator('fullName', {
						rules: [
							{ required: true, message: 'Please enter your name!' },
							{
								type: 'array',
								min: 2,
								transform(value) {
									if (!value) return [];
									return value.split(' ');
								},
								message: 'Please input your full name!',
							},
						],
					})(<Input />)}
				</FormItem>
				<FormItem {...formItemLayout} label="E-mail" hasFeedback>
					{getFieldDecorator('email', {
						rules: [
							{
								type: 'email',
								message: 'The input is not valid email!',
							},
							{
								required: true,
								message: 'Please input your email!',
							},
						],
					})(<Input />)}
				</FormItem>
				<FormItem {...formItemLayout} label="Password">
					{getFieldDecorator('password', {
						rules: [
							{
								required: true,
								message: 'Please input your password!',
							},
						],
					})(<Input type="password" />)}
				</FormItem>
				<FormItem {...tailFormItemLayout}>
					<Button type="primary" loading={loading} htmlType="submit">
						Register
					</Button>
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	loading: state.session.loading,
	onSubmit: ownProps.onSubmit,
	history: ownProps.history,
	errors: state.session.errors,
	facilityId: ownProps.facilityId,
});

const mapDispatchToProps = (dispatch) => ({
	signUp: (username, password, nameGiven, nameFamily, gender, roles, facilityId) =>
		dispatch(
			signUp('supplier')(username, password, nameGiven, nameFamily, gender, roles, facilityId)
		),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<SupplierClaimSignupFormProps>()(SupplierClaimSignupForm))
);

import { Row } from 'antd';
import { nullSafeGet, nullSafeGetOrElse } from '../../../utils/DataAccessUtils';
import React, { useCallback, useMemo } from 'react';
import {
	CLEAR_DISPLAY_STATUS_FILTER,
	CLEAR_STATUS_FILTER,
} from '../config/pipelineFilters/pipelineFilters';

const style = require('./pipelineFilters.less');

interface PipelineProps {
	items: any;
	subItems: any;
	value: any;

	onChange: any;
	style: any;
}

export function PipelineFilter(props) {
	const { items, onChange, value, counts } = props;

	const statusValue = useMemo(
		() =>
			nullSafeGetOrElse('statuses', value, '')
				.split(',')
				.filter((_) => !!_),
		[value]
	);

	let isFilterActive = useMemo(() => statusValue.length > 0, [statusValue]);

	const isActive = useCallback(
		(item) => {
			return isFilterActive && statusValue.includes(item.name);
		},
		[isFilterActive, statusValue]
	);

	const onValueChange = useCallback(
		(item) => () => {
			const newVal = isActive(item)
				? statusValue.filter((_) => _ !== item.name)
				: [...statusValue, item.name];
			const updatedVal = newVal.length > 0 ? newVal.join(',') : undefined;
			onChange({
				statuses: updatedVal,
			});
		},
		[isActive, statusValue, onChange]
	);

	const pipelines = items.map((item, i) => {
		let stepLinerClassName;
		if (i === 0) {
			stepLinerClassName = 'pipelineCounts__stepLiner--first';
		} else if (i === items.length - 1) {
			stepLinerClassName = 'pipelineCounts__stepLiner--last';
		} else {
			stepLinerClassName = 'pipelineCounts__stepLiner';
		}
		return (
			<div
				key={item.name}
				onClick={onValueChange(item)}
				className={`pipelineCounts__step ${isActive(item) ? 'active' : ''}`}
			>
				<div className={`${stepLinerClassName} ${isActive(item) ? 'active' : ''}`} />
				<div className="pipelineCounts__name">
					<span className="inline-block-visible-xl">
						{item.icon ? (
							<i
								style={{
									...item.iconStyle,
									marginRight: '8px',
									color: isActive(item) ? 'white' : nullSafeGet('iconStyle.color', item),
								}}
								className={`icons8-font ${item.icon}`}
							/>
						) : null}
					</span>
					<span>{item.displayName}</span>
				</div>
				<div className="pipelineCounts__count">{counts[item.name]}</div>
			</div>
		);
	});

	return (
		<Row>
			<Row key={1} className="gap-y-1">
				{pipelines.map((pipe) => pipe)}
			</Row>
		</Row>
	);
}

import { Form, Input, Select } from 'antd';
import React from 'react';

export default function SingleEmailComponent({ fieldProps, ...props }) {
	const emailValidator = (rule, value, callback) => {
		if (!value) {
			callback();
		}
		const emailRegex = /^([a-zA-Z0-9+_\.-]+)@([\da-zA-Z\.-]+)\.([a-zA-Z\.]{2,6})$/;

		if (value.match(emailRegex)) {
			callback();
		} else {
			callback(value + ' is not a valid email');
		}
	};
	return (
		<Form.Item
			{...fieldProps}
			rules={[...(fieldProps.rules || []), { validator: emailValidator }, { required: true }]}
		>
			<Input placeholder="Enter emails" {...props} />
		</Form.Item>
	);
}

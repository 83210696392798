import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Button, Card, message, Typography, Radio, Select } from 'antd';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { locationsRestCrudThunksForBuyer } from '../../thunks/locations_thunks';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { EmptyState } from '../empty_state/EmptyState';
import SubnavBar from '../subnav_bar/SubnavBar';
import {
	nullSafeGet,
	changeFilterValueToArrayOfIds,
	getTupleNameFieldName,
	nullSafeGetOrElse,
	getTupleValueFieldName,
} from '../../utils/DataAccessUtils';
import { EmptorTag } from '../emptor_tag/EmptorTag';
import { AdvancedFilters } from '../advanced_filters/AdvancedFilters';
import { LOCATIONS_CRUD_ACTION_CREATORS } from '../../actions/locations_actions';
import { BUYER_ONLY_NO_STORE_ASSOCIATE } from '../roles/roles';
import { buyerRoleUpdateUser } from '../../thunks/buyer_contacts_thunks';
import BuyerRoleUpdateForm from '../buyer_role_update_form/BuyerRoleUpdateForm';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import {
	fetchRegionsWithChildrenForBuyer,
	regionsRestCrudThunksForBuyer,
} from '../../thunks/regions_thunks';
import { FILTER_CONFIG_NAMES, FILTER_FIELD_TYPE } from '../../utils/DataConstants';
import ProblemTypeTreeSelect from '../problem_type_tree_select';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import OWAsyncTupleSelect from '../ow_async_select/OWAsyncTupleSelect';
import { locationTypesAdditionalFieldsRestCrudThunksForBuyer } from '../../thunks/location_types_additional_fields_thunks';
import { brandsRestCrudThunksForBuyer } from '../../thunks/brands_thunks';
import { locationTypesRestCrudThunksForBuyer } from '../../thunks/location_types_thunks';
import SelectedFilters from '../selected_filters_pills/SelectedFilters';
import { getIsActiveFilterField, INITIAL_IS_ACTIVE_FILTERS } from '../../utils/FilterUtils';

const queryString = require('qs');
const { Content } = Layout;

require('./LocationsIndexPage.less');
const { Text } = Typography;

const LOCATIONS_OVERVIEW_TARGET_COLLECTION = 'locationsIndex';

class LocationsIndexPage extends React.Component<any, any> {
	formRefs: any;
	initialFilters: any;
	initialPagination: any = { current: 1, pageSize: 10 };

	checkBrandsCount = () => {
		const { fetchBrandsCount } = this.props;
		fetchBrandsCount().then((count) =>
			this.setState({
				brandAvailable: count > 0,
			})
		);
	};

	constructor(props) {
		super(props);
		this.handleBuyerRoleUpdateSubmit = this.handleBuyerRoleUpdateSubmit.bind(this);
		this.handleBuyerRoleUpdateCancel = this.handleBuyerRoleUpdateCancel.bind(this);
		this.formRefs = {
			roleUpdateForm: null,
		};
		const { updateLocationsFilters, location, history } = props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		const {
			current,
			pageSize,
			total,
			pageSizeOptions,
			showSizeChanger,
			onShowSizeChange,
			sort_by,
			order,
			...locationFilters
		} = queryParams;
		this.initialPagination = {
			...this.initialPagination,
			current: current ? current : this.initialPagination.current,
			pageSize: pageSize ? pageSize : this.initialPagination.pageSize,
		};
		this.state = {
			displayMode: 'list',
			brandAvailable: false,
		};
		this.initialFilters = locationFilters || {};
		updateLocationsFilters(this.initialFilters, LOCATIONS_OVERVIEW_TARGET_COLLECTION);
		history.push(`/buyer/locations/overview/all?${new URLSearchParams(location.search)}`);

		this.checkBrandsCount();
	}

	switchDisplayMode = (mode) => this.setState({ displayMode: mode });
	isDisplayModeActive = (mode) => this.state.displayMode === mode;

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	showRoleUpdateModal = (record) => (e) => {
		this.setState({
			roleUpdateVisible: true,
			userContact: {
				contact: {
					...record,
				},
				role: record['roles'],
				locationIds: record['locationIds'] || [],
			},
		});
	};

	handleBuyerRoleUpdateSubmit() {
		const { roleUpdateUser, history } = this.props;
		const form = this.formRefs['roleUpdateForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			const updateRole = {
				userId: values.email,
				roles: [values.roles],
				locationIds: values.locationIds,
			};

			roleUpdateUser(updateRole).then(() => {
				this.setState({ roleUpdateVisible: false });
				form.resetFields();
				message.success(`Location Access Granted`);
				history.push(`/buyer/locations/overview/all`);
			});
		});
	}

	handleBuyerRoleUpdateCancel(e) {
		this.setState({
			roleUpdateVisible: false,
		});
	}

	render() {
		const {
			history,
			locations,
			clearAndUpdateFilters,
			updateLocationsFilters,
			currentUser,
			regions,
			fetchRegionsWithChildren,
			fetchMultipleRegions,
			location,
			locationTypesAdditionalFields,
			fetchLocationTypesAdditionalFields,
			fetchMultipleLocationTypesAdditionalFields,
			brands,
			fetchBrands,
			fetchMultipleBrands,
			locationTypes,
			fetchLocationTypes,
			fetchMultipleLocationTypes,
		} = this.props;
		const popupRenderFunc = (record) => `
            <div>
                <a href="/buyer/locations/detail/${record.id}">
                    <div class="locationsIndexPage__row" style="align-items: flex-start;">
                        <div style="margin-right: 24px;">
                            <div
                                class="locationsIndexPage__rowTitle"
                            >
                                ${record.name}
                            </div>
                            <div
                                class="locationsIndexPage__rowSubtitle"
                            >
                                    ${nullSafeGet(
																			'buyerFacility.primaryAddress.streetAddress1',
																			record
																		)}
                            </div>
                        </div>
                        <div class="emptorTag locationsIndexPage__pill">
                            ${
															record.totalNotClosedWOCount > 0
																? record.totalNotClosedWOCount + ' Open WOs'
																: 'No Open WOs'
														}
                        </div>
                    </div>
                    <div
                        class="locationsIndexPage__rowSubtitle"
                    >
                        <div class="locationsIndexPage__rowInlineGroup">
                            ${nullSafeGet(
															'buyerFacility.primaryAddress.city',
															record
														)}, ${nullSafeGet(
			'buyerFacility.primaryAddress.region',
			record
		)} ${nullSafeGet('buyerFacility.primaryAddress.postcode', record)}
                        </div>
                    </div>
                </a>
            </div>
        `;

		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				defaultSortOrder: 'ascend',
				render: (text, record) => (
					<div className="locationsIndexPage__row">
						<div>
							<div className="flex">
								{nullSafeGet('brand.logoURL', record) ? (
									<img
										src={nullSafeGet('brand.logoURL', record)}
										alt={nullSafeGet('brand.brandName', record)}
										className="mr-3 h-auto w-16 object-contain"
									/>
								) : null}
								<div>
									<div className="locationsIndexPage__rowTitle">
										<Ellipsis tooltip={true} length={64}>
											{record.name}
										</Ellipsis>
									</div>
									<div className="locationsIndexPage__rowSubtitle">
										<div className="locationsIndexPage__rowInlineGroup">
											{nullSafeGet('buyerFacility.primaryAddress.city', record)}
										</div>
										<div className="locationsIndexPage__rowInlineGroup2">
											{record.locationType.name} {record.storeId}
										</div>
										{record.isActive ? null : (
											<div className="locationsIndexPage__rowInlineGroup">
												<Text className={'mr-1'} type={'danger'}>
													Inactive
												</Text>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
						<div className="flex items-end">
							<EmptorTag className="locationsIndexPage__pill">
								{record.totalNotClosedWOCount > 0
									? record.totalNotClosedWOCount + ' Open WOs'
									: 'No Open WOs'}
							</EmptorTag>
						</div>
					</div>
				),
			},
		];

		const onRow = (record) => ({
			onClick: () => {
				let searchString = location.search;
				if (searchString[0] === '?') {
					searchString = searchString.slice(1);
				}
				let backUrl = `/buyer/locations/detail/${record.id}/details`;
				if (searchString && searchString.length > 0) {
					backUrl = backUrl.concat(`?backlinkParams=${encodeURIComponent(searchString)}`);
				}
				history.push(backUrl);
			},
		});

		const filterConfig = [
			{
				label: 'Region',
				mode: 'multiple',
				stateSlice: regions,
				fieldName: FILTER_CONFIG_NAMES.REGION,
				filtersValueAccessor: (filtersStateSlice) => {
					const ids = nullSafeGet(
						`${LOCATIONS_OVERVIEW_TARGET_COLLECTION}.filters.${FILTER_CONFIG_NAMES.REGION}`,
						filtersStateSlice
					);
					return changeFilterValueToArrayOfIds(ids);
				},
				keyAccessor: (region) => region.id,
				valueAccessor: (region) => region.id,
				handleChange: (ids) => {
					updateLocationsFilters(
						{
							[FILTER_CONFIG_NAMES.REGION]: ids.join(',') || undefined,
						},
						LOCATIONS_OVERVIEW_TARGET_COLLECTION
					);
				},
				targetCollectionName: 'locationsAdvancedFilters',
				fetch: fetchRegionsWithChildren,
				fetchMultiple: fetchMultipleRegions,
				renderItem: (region) => region.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
				component: ProblemTypeTreeSelect,
			},
			{
				label: 'Location Types',
				mode: 'multiple',
				fieldName: FILTER_CONFIG_NAMES.LOCAATION_TYPE,
				stateSlice: locationTypes,
				filtersValueAccessor: (filtersStateSlice) => {
					const ids = nullSafeGet(
						`${LOCATIONS_OVERVIEW_TARGET_COLLECTION}.filters.${FILTER_CONFIG_NAMES.LOCAATION_TYPE}`,
						filtersStateSlice
					);
					return changeFilterValueToArrayOfIds(ids);
				},
				keyAccessor: (lt) => lt.id,
				valueAccessor: (lt) => lt.id,
				handleChange: (ids) => {
					updateLocationsFilters(
						{
							[FILTER_CONFIG_NAMES.LOCAATION_TYPE]: ids.join(',') || undefined,
						},
						LOCATIONS_OVERVIEW_TARGET_COLLECTION
					);
				},
				targetCollectionName: 'locationsAdvancedFilters',
				fetch: fetchLocationTypes,
				fetchMultiple: fetchMultipleLocationTypes,
				renderItem: (lt) => lt.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
			},
			...(this.state.brandAvailable
				? [
						{
							label: 'Brand',
							mode: 'multiple',
							stateSlice: brands,
							fieldName: FILTER_CONFIG_NAMES.BRAND,
							filtersValueAccessor: (filtersStateSlice) => {
								const ids = nullSafeGet(
									`${LOCATIONS_OVERVIEW_TARGET_COLLECTION}.filters.${FILTER_CONFIG_NAMES.BRAND}`,
									filtersStateSlice
								);
								return changeFilterValueToArrayOfIds(ids);
							},
							keyAccessor: (brand) => brand.id,
							valueAccessor: (brand) => brand.id,
							handleChange: (ids) => {
								updateLocationsFilters(
									{
										[FILTER_CONFIG_NAMES.BRAND]: ids.join(',') || undefined,
									},
									LOCATIONS_OVERVIEW_TARGET_COLLECTION
								);
							},
							targetCollectionName: 'locationsAdvancedFilters',
							fetch: fetchBrands,
							fetchMultiple: fetchMultipleBrands,
							renderItem: (brand) => brand.brandName,
							sortBy: { sort_by: 'name', order: 'ascend' },
						},
				  ]
				: []),
			{
				label: 'Additional Fields',
				fieldName: FILTER_CONFIG_NAMES.ADDITIONAL_FIELD,
				type: FILTER_FIELD_TYPE.TUPLE,
				stateSlice: locationTypesAdditionalFields,
				filtersValueAccessor: (filtersStateSlice) => {
					const name = nullSafeGet(
						`${LOCATIONS_OVERVIEW_TARGET_COLLECTION}.filters.${getTupleNameFieldName(
							FILTER_CONFIG_NAMES.ADDITIONAL_FIELD
						)}`,
						filtersStateSlice
					);
					const value = nullSafeGet(
						`${LOCATIONS_OVERVIEW_TARGET_COLLECTION}.filters.${getTupleValueFieldName(
							FILTER_CONFIG_NAMES.INSTALL_AT
						)}`,
						filtersStateSlice
					);
					return [name, value];
				},
				handleChange: (values) => {
					updateLocationsFilters(
						{
							[getTupleNameFieldName(FILTER_CONFIG_NAMES.ADDITIONAL_FIELD)]: nullSafeGetOrElse(
								'0',
								values,
								undefined
							),
							[getTupleValueFieldName(FILTER_CONFIG_NAMES.ADDITIONAL_FIELD)]: nullSafeGetOrElse(
								'1',
								values,
								undefined
							),
						},
						LOCATIONS_OVERVIEW_TARGET_COLLECTION
					);
				},
				targetCollectionName: 'locationsAdvancedFilters',
				fetch: fetchLocationTypesAdditionalFields,
				fetchMultiple: fetchMultipleLocationTypesAdditionalFields,
				renderRecord: (entity) => (
					<Select.Option key={entity.id} value={entity.id}>
						<div className="flex flex-row items-center">
							<div>{entity.name}</div>
						</div>
					</Select.Option>
				),
				valueAccessor: (item) => item.name,
				component: OWAsyncTupleSelect,
			},
			...[getIsActiveFilterField(updateLocationsFilters, LOCATIONS_OVERVIEW_TARGET_COLLECTION)],
		];

		return (
			<Content className="locationsIndexPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited buyer locations index page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<BuyerRoleUpdateForm
					wrappedComponentRef={this.saveFormRef('roleUpdateForm')}
					visible={this.state.roleUpdateVisible}
					formData={this.state.userContact}
					onCancel={this.handleBuyerRoleUpdateCancel}
					onSubmit={this.handleBuyerRoleUpdateSubmit}
					isLocationChange={true}
				/>
				<Row style={{ margin: '0.5em -8px' }}>
					<Col span={24}>
						<SubnavBar
							left={
								<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
									<AdvancedFilters
										updateFilters={updateLocationsFilters}
										filterConfig={filterConfig}
										filtersTargetCollectionName={LOCATIONS_OVERVIEW_TARGET_COLLECTION}
										clearAndUpdateFilters={clearAndUpdateFilters}
										filtersStateSlice={locations}
										initialFilters={{ ...this.initialFilters, ...INITIAL_IS_ACTIVE_FILTERS }}
										preAppliedFilters={INITIAL_IS_ACTIVE_FILTERS}
									/>
								</div>
							}
							right={
								<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
									<Button.Group size="large">
										<Button
											onClick={() => this.switchDisplayMode('list')}
											disabled={this.isDisplayModeActive('list')}
										>
											<i className="icons8-font icons8-bulleted-list-2" />
										</Button>
										<Button
											onClick={() => this.switchDisplayMode('map')}
											disabled={this.isDisplayModeActive('map')}
										>
											<i className="icons8-font icons8-location-marker" />
										</Button>
									</Button.Group>
									{currentUser.roles.some((role) => BUYER_ONLY_NO_STORE_ASSOCIATE.has(role)) ? (
										<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_LOCATIONS}>
											<Button
												size="large"
												type="primary"
												onClick={() => this.props.history.push('/buyer/locations/overview/new')}
											>
												Add Location
											</Button>
										</AccessPermissionChecker>
									) : null}
								</div>
							}
						/>
					</Col>
				</Row>
				<div className="flex w-full flex-1 flex-row">
					<SelectedFilters
						updateFilters={(filters) =>
							updateLocationsFilters(filters, LOCATIONS_OVERVIEW_TARGET_COLLECTION)
						}
						filterValues={nullSafeGetOrElse(
							`${LOCATIONS_OVERVIEW_TARGET_COLLECTION}.filters`,
							locations,
							{}
						)}
						filterConfig={filterConfig}
					/>
				</div>
				<Row style={{ margin: '0.5em -8px' }}>
					<Col span={24}>
						<Card bodyStyle={{ padding: 8 }} style={{ minHeight: 560 }}>
							<PaginatedReduxTable
								mode={this.state.displayMode}
								updateQueryParams={true}
								longitudeAccessor={(el) => nullSafeGet('buyerFacility.longitude', el)}
								latitudeAccessor={(el) => nullSafeGet('buyerFacility.latitude', el)}
								emptyState={
									<EmptyState
										graphic={
											<img
												alt="Looks like your team has not added any locations yet."
												style={{ marginBottom: 8 }}
												src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
											/>
										}
										headline={"It's a little lonely in here."}
										body={
											currentUser.roles.some((role) => BUYER_ONLY_NO_STORE_ASSOCIATE.has(role)) ? (
												<div style={{ textAlign: 'center' }}>
													<div style={{ maxWidth: 440, marginBottom: 16 }}>
														Looks like your team has not added any locations yet.
													</div>
													<Button
														type="primary"
														onClick={() => this.props.history.push('/buyer/locations/overview/new')}
													>
														Add your first location
													</Button>
												</div>
											) : (
												<div style={{ textAlign: 'center' }}>
													<div style={{ maxWidth: 440, marginBottom: 16 }}>
														Looks like your team has not setup access for you yet.
													</div>
													<Button type="primary" onClick={this.showRoleUpdateModal(currentUser)}>
														Request access to my location
													</Button>
												</div>
											)
										}
									/>
								}
								collectionName="locations"
								targetCollectionName="locationsIndex"
								columns={columns}
								popupRenderFunc={popupRenderFunc}
								showHeader={false}
								keyAccessor={(el) => el.id}
								onRow={onRow}
								initialFilters={{ ...this.initialFilters, ...INITIAL_IS_ACTIVE_FILTERS }}
								initialPagination={this.initialPagination}
								fetchData={locationsRestCrudThunksForBuyer.read}
							/>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	locations: state.locations,
	regions: state.regions,
	brands: state.brands,
	currentUser: state.session.currentUser,
	locationTypesAdditionalFields: state.location_types_additional_fields,
	locationTypes: state.location_types,
});

const mapDispatchToProps = (dispatch) => ({
	updateLocationsFilters: (filters, targetCollection) =>
		dispatch(LOCATIONS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(LOCATIONS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)),
	fetchRegionsWithChildren: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			fetchRegionsWithChildrenForBuyer(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleRegions: (ids, targetCollectionName) =>
		dispatch(regionsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	roleUpdateUser: (entity) => dispatch(buyerRoleUpdateUser(entity)),
	fetchMultipleLocationTypesAdditionalFields: (ids, targetCollectionName) =>
		dispatch(
			locationTypesAdditionalFieldsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchLocationTypesAdditionalFields: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			locationTypesAdditionalFieldsRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchBrands: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			brandsRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleBrands: (ids, targetCollectionName) =>
		dispatch(brandsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchBrandsCount: (params) => dispatch(brandsRestCrudThunksForBuyer.countBy(params, 'all')),
	fetchLocationTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			locationTypesRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleLocationTypes: (ids, targetCollectionName) =>
		dispatch(locationTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationsIndexPage));

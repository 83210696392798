import React, { FC, useCallback, useMemo, useState } from 'react';
import { PartPerStockLocationRowDisplay } from '../parts_per_stock_location_row_display/PartPerStockLocationRowDisplay';
import { QuantityRowDisplay } from '../part_quantity_row_display/PartQuantityRowDisplay';
import {
	fetchAllPartsPerStockLocactionForSupplier,
	partsPerStockLocationsRestCrudThunksForSupplier,
} from '../../thunks/parts_per_stock_locations_thunks';
import { Layout, Button, Popover, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EmptyState } from '../empty_state/EmptyState';
import {
	DownloadOutlined,
	EditOutlined,
	PlusOutlined,
	PrinterOutlined,
	UploadOutlined,
} from '@ant-design/icons';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import {
	getRecordsForCompositeTargetCollection,
	getRecordsForTargetCollection,
} from '../../reducers/standard_reducer_utils';
import BuilkTransferPartsForm from './BuilkTransferPartsForm';
import BulkTransferFromStockLocationModal from './BulkTransferFromStockLocationModal';
import {
	nullSafeGet,
	nullSafeGetOrElse,
	renderCurrency,
	stopDefaultEvents,
} from '../../utils/DataAccessUtils';
import { PARTS_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS } from '../../actions/parts_per_stock_locations_actions';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import ReservedQtyDisplay from './ReservedQtyDisplay';
import RequestedQuantityDisplay from './RequestedQuantityDisplay';
import FeildTechSLManagePermissionChecker, {
	canFieldManageStockLocation,
} from '../common/FeildTechSLManagePermissionChecker';
import { DEFAULT_NAME_SORTER, FILTER_FIELD_TYPE } from '../../utils/DataConstants';
import EditPartsBulk from './EditPartsBulk';
import { ExportToCsv } from 'export-to-csv';
import { CSV_EXPORT_DEFAULTS } from '../../utils/DataConstants';
import {
	PART_FLATFILE_HEADER,
	getFlatFileRecordForPartLevel,
	getRecordForExport,
	getStockLocationPartsLevelExportFields,
} from './stock_location_constants';
import UploadWithSampleModal from '../parts_index_page/UploadWithSampleModal';
import moment from 'moment';
import { DATE_FORMAT } from '../../utils/DataFormatterUtils';

const { Content } = Layout;

const PPSL_TC = 'stockLocationAssociatedPartsPerStockLocations';
const BACK_TEXT = 'Back to Stock location';

const getMissngPartNumbers = (existing, newList) =>
	existing
		.filter(
			(ppsl) =>
				!newList.find(
					(record) =>
						`${ppsl.id}` === `${record.partId}` ||
						nullSafeGetOrElse('part.partNumber', ppsl, -1) === record.partNumber
				)
		)
		.map((_) => nullSafeGetOrElse('part.partNumber', _, nullSafeGet('part.id', _)));

const StockLocationsPartsPage: FC<any> = ({
	match,
	history,
	location,
	fetchPartsPerStockLocation,
	partsPerStockLocation,
	fetching,
	updatePartsPerStocklocationFilters,
	clearAndUpdateFilters,
	currentUser,
	stockLocation,
	fetchAllPartsPerStockLocation,
	createBulk,
}): React.ReactElement => {
	const stockLocationId = useMemo(() => match.params.id, [match.params.id]);

	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [transferFormVisible, setTransferFormVisible] = useState(false);
	const [transferModalVisible, setTransferModalVisible] = useState(false);
	const [destinationRecords, setDestinationRecords] = useState([]);
	const [toStockLocationId, setToStockLocationId] = useState(null);

	const [fetchingPpsl, setFetchingPpsl] = useState(false);
	const [exportingCsv, setExportingCsv] = useState(false);
	const [ppsls, setPpsls] = useState([]);

	const [fetchinPpslForUpload, setFetchingPpslForUpload] = useState(false);
	const [ppslsForUpload, setPpslsForUpload] = useState([]);

	const [uploadPopupVisible, setUploadPopupVisible] = useState(false);

	const reservedQuantityAvailable = false;

	const editAllowed = useMemo(
		() => getRecordsForTargetCollection(partsPerStockLocation, PPSL_TC).length > 0,
		[partsPerStockLocation]
	);

	const getLink = useCallback(
		(link) => getLinkWIthBackLinkParams(location, BACK_TEXT, link),
		[location]
	);

	const onPpslEdit = useCallback(
		(id) => (e) => {
			stopDefaultEvents(e);
			history.push(getLink(`/supplier/parts/partsPerStockLocation/edit/${id}`));
		},
		[getLink, history]
	);

	const partsColumns = useMemo(
		() => [
			{
				title: 'Name',
				dataIndex: 'title',
				render: (_, record) => <PartPerStockLocationRowDisplay partPerStockLocation={record} />,
			},
			{
				title: 'Min / Max',
				render: (_, record) => (
					<div className="flex flex-col">
						<div>{`Min - ${nullSafeGetOrElse('minQuantity', record, '--')}`}</div>
						<div>{`Max - ${nullSafeGetOrElse('maxQuantity', record, '--')}`}</div>
					</div>
				),
			},
			{
				title: 'Total Qty',
				dataIndex: 'onHandQuantity',
			},
			{
				title: 'Requested Qty',
				render: (_, record) => <RequestedQuantityDisplay record={record} backText={BACK_TEXT} />,
			},
			{
				title: 'Available Qty',
				dataIndex: 'availableQuantity',
				render: (_, record) => (
					<QuantityRowDisplay
						min={record.minQuantity}
						max={record.maxQuantity}
						quantity={record.availableQuantity}
					/>
				),
			},
			...(reservedQuantityAvailable
				? [
						{
							title: 'Reserved Qty',
							render: (_, record) => <ReservedQtyDisplay record={record} backText={BACK_TEXT} />,
						},
				  ]
				: []),
			{
				title: 'Total Cost',
				dataIndex: 'totalStockedPartsCost',
				render: renderCurrency(currentUser),
			},
			{
				dataIndex: 'id',
				render: (_) => (
					<Button type="ghost" onClick={onPpslEdit(_)}>
						Edit
					</Button>
				),
			},
		],
		[currentUser, onPpslEdit, reservedQuantityAvailable]
	);

	const canTransfer = useMemo(() => selectedRowKeys.length > 0, [selectedRowKeys.length]);

	const onPartRow = useCallback(
		(record) => ({
			onClick: () =>
				canTransfer
					? false
					: history.push(getLink(`/supplier/parts/partsPerStockLocation/detail/${record.id}`)),
		}),
		[canTransfer, getLink, history]
	);

	const onSelectChange = useCallback((newSelectedRowKeys: React.Key[]) => {
		setSelectedRowKeys(newSelectedRowKeys);
	}, []);

	const rowSelection = useMemo(
		() => ({
			selectedRowKeys,
			onChange: onSelectChange,
		}),
		[onSelectChange, selectedRowKeys]
	);

	const onTransferClick = useCallback(() => setTransferModalVisible(true), []);

	const hideTransferModal = useCallback(() => setTransferModalVisible(false), []);

	const onSelectCancel = useCallback(() => {
		setTransferFormVisible(false);
		setSelectedRowKeys([]);
	}, []);

	const getStatSliceProp = useCallback(
		(propName) => nullSafeGetOrElse(`${PPSL_TC}.${propName}`, partsPerStockLocation, {}),
		[partsPerStockLocation]
	);

	const refreshPartsPerStockLocation = useCallback(() => {
		fetchPartsPerStockLocation(
			{},
			PPSL_TC,
			getStatSliceProp('pagination'),
			getStatSliceProp('sorting'),
			getStatSliceProp('filters')
		);
	}, [fetchPartsPerStockLocation, getStatSliceProp]);

	const onBulkPpslEditSuccess = useCallback(() => {
		setPpsls([]);
		refreshPartsPerStockLocation();
	}, [refreshPartsPerStockLocation]);

	const onTransferPartsSuccess = useCallback(() => {
		onSelectCancel();
		refreshPartsPerStockLocation();
	}, [onSelectCancel, refreshPartsPerStockLocation]);

	const selectedRecords = useMemo(() => {
		const records = getRecordsForCompositeTargetCollection(partsPerStockLocation, PPSL_TC);
		return records.filter((_) => selectedRowKeys.includes(_.id));
	}, [partsPerStockLocation, selectedRowKeys]);

	const onToStockLocationSelected = useCallback(
		(values) => {
			setToStockLocationId(values.toStockLocationId);
			fetchPartsPerStockLocation(
				{
					stockLocationId: values.toStockLocationId,
					partIds: selectedRecords.map((_) => nullSafeGet('partId', _)).filter((_) => !!_),
				},
				'destinationPartsPerStockLocationIndex'
			).then((res) => {
				setDestinationRecords(res);
				hideTransferModal();
				setTransferFormVisible(true);
			});
		},
		[fetchPartsPerStockLocation, hideTransferModal, selectedRecords]
	);

	const onAddPart = useCallback(() => {
		history.push(`/supplier/stockLocations/detail/${stockLocationId}/parts/new`);
	}, [history, stockLocationId]);

	const filterConfig = useMemo(
		() => [
			{
				fieldName: 'isStocked',
				type: FILTER_FIELD_TYPE.RADIO_GROUP,
				items: [
					{
						label: 'All',
						value: 'undefined',
					},
					{
						label: 'Stocked',
						value: 'true',
					},
					{
						label: 'Not Stocked',
						value: 'false',
					},
				],
				defaultValue: undefined,
				targetCollectionName: PPSL_TC,
				showOutsideDropdown: true,
			},
			{
				fieldName: 'isThresholdReached',
				type: FILTER_FIELD_TYPE.RADIO_GROUP,
				items: [
					{
						label: 'All',
						value: 'undefined',
					},
					{
						label: 'Less than min qty',
						value: 'true',
					},
				],
				defaultValue: undefined,
				targetCollectionName: PPSL_TC,
				showOutsideDropdown: true,
			},
			{
				fieldName: 'isOutOfStock',
				type: FILTER_FIELD_TYPE.RADIO_GROUP,
				items: [
					{
						label: 'All',
						value: 'undefined',
					},
					{
						label: 'Out of stock',
						value: 'true',
					},
				],
				defaultValue: undefined,
				targetCollectionName: PPSL_TC,
				showOutsideDropdown: true,
			},
		],
		[]
	);

	const fetchCurrentSLEntities = useCallback(
		() => fetchAllPartsPerStockLocation({ stockLocationId }),
		[fetchAllPartsPerStockLocation, stockLocationId]
	);

	const onBulkPpslEdit = useCallback(() => {
		setFetchingPpsl(true);
		fetchCurrentSLEntities()
			.then((res) => setPpsls(res))
			.finally(() => setFetchingPpsl(false));
	}, [fetchCurrentSLEntities]);

	const showUploadPopup = useCallback(() => {
		setFetchingPpslForUpload(true);
		fetchCurrentSLEntities()
			.then((res) => {
				setPpslsForUpload(res);
				setUploadPopupVisible(true);
			})
			.finally(() => setFetchingPpslForUpload(false));
	}, [fetchCurrentSLEntities]);

	const hideUploadPopup = useCallback(() => setUploadPopupVisible(false), []);

	const exportAsCsv = useCallback(
		(data, headerFields) => {
			const date = moment().format(DATE_FORMAT);
			const csvTitle = `${stockLocation.name}_${date}_parts_level`;
			const csvExporter = new ExportToCsv({
				...CSV_EXPORT_DEFAULTS,
				filename: csvTitle,
				title: csvTitle,
				headers: headerFields.map((_) => _.label),
			});
			csvExporter.generateCsv(data);
		},
		[stockLocation.name]
	);

	const onDownloadSample = useCallback(() => {
		const data =
			ppslsForUpload.length > 0
				? ppslsForUpload.map((_) => getFlatFileRecordForPartLevel(_))
				: [getFlatFileRecordForPartLevel()];
		exportAsCsv(data, PART_FLATFILE_HEADER);
	}, [exportAsCsv, ppslsForUpload]);

	const downloadCSV = useCallback(() => {
		setExportingCsv(true);
		fetchCurrentSLEntities()
			.then((res) => {
				if (res.length < 1) {
					message.error('No parts level found to export!');
				} else {
					const headerFields = getStockLocationPartsLevelExportFields(currentUser);
					const data = res.map((_) => getRecordForExport(_, headerFields));
					exportAsCsv(data, headerFields);
				}
			})
			.finally(() => setExportingCsv(false));
	}, [currentUser, exportAsCsv, fetchCurrentSLEntities]);

	const onDataUpload = useCallback(
		(results) => {
			return new Promise((resolve, reject) => {
				const missingNumbers = getMissngPartNumbers(ppslsForUpload, results.validData);

				if (missingNumbers.length > 0) {
					reject(
						`Some parts are missing from existing list. Please include them! Part # ${missingNumbers.join(
							', '
						)} `
					);
				} else {
					createBulk(
						results.validData.map((_) => ({
							id: _.id ? parseInt(_.id) : undefined,
							stockLocationId: parseInt(stockLocationId),
							supplierFacilityId: parseInt(nullSafeGet('supplierFacilityId', stockLocation)),
							supplierCompanyId: parseInt(nullSafeGet('supplierCompanyId', stockLocation)),
							isStocked: _.isStocked,
							onHandQuantity: parseInt(_.onHandQuantity),
							minQuantity: parseInt(_.minQuantity),
							maxQuantity: parseInt(_.maxQuantity),
							partId: parseInt(_.partId),
							aisle: _.aisle,
							bay: _.bay,
							level: _.level,
							bin: _.bin,
						}))
					)
						.then((res) => {
							resolve('Updated successfully!');
							setTimeout(() => {
								hideUploadPopup();
								message.success('Updated successfully!');
								refreshPartsPerStockLocation();
							}, 0);
						})
						.catch(() => {
							reject('Unable to upload! Please contact support!');
						});
				}
			});
		},
		[
			createBulk,
			hideUploadPopup,
			ppslsForUpload,
			refreshPartsPerStockLocation,
			stockLocation,
			stockLocationId,
		]
	);

	return (
		<>
			{transferFormVisible ? (
				<Content className="purchaseRequestsIndexPage" style={{ padding: '0 0.5em' }}>
					<BuilkTransferPartsForm
						onCancel={onSelectCancel}
						targetCollectionName={PPSL_TC}
						stockLocationId={stockLocationId}
						selectedRecords={selectedRecords}
						isPartsTransfer
						destinationRecords={destinationRecords}
						toStockLocationId={toStockLocationId}
						onTransferPartsSuccess={onTransferPartsSuccess}
					/>
				</Content>
			) : (
				<PaginatedReduxTableWithHeader
					targetCollectionName={PPSL_TC}
					updateFilters={updatePartsPerStocklocationFilters}
					stateSlice={partsPerStockLocation}
					clearAndUpdateFilters={clearAndUpdateFilters}
					filterConfig={filterConfig}
					entityCollectionName="parts_per_stock_locations"
					tableColumns={partsColumns}
					onTableRow={onPartRow}
					rowSelection={
						canFieldManageStockLocation(currentUser, stockLocation) ? rowSelection : null
					}
					showHeader
					preAppliedFilters={{ stockLocationId }}
					fetchData={partsPerStockLocationsRestCrudThunksForSupplier.read}
					hasDefaultHeaderPage
					initialSorters={DEFAULT_NAME_SORTER}
					rightActions={
						canTransfer ? (
							<div className="flex-start flex flex-row">
								<Button onClick={onSelectCancel} size="large">
									Cancel
								</Button>
								<Button className="ml-2" size="large" type="primary" onClick={onTransferClick}>
									Transfer
								</Button>
							</div>
						) : (
							<div className="flex-start flex flex-row">
								<Popover content="View Printable Labels" trigger="hover">
									<Button
										size="large"
										icon={<PrinterOutlined translate="" />}
										onClick={() =>
											history.push(
												getLink(
													`/supplier/stockLocations/printPartsPerStockLocationLabels/${stockLocationId}`
												)
											)
										}
									/>
								</Popover>
								<FeildTechSLManagePermissionChecker>
									<Popover content="Download or Upload Parts Level" trigger="hover">
										<Button
											size="large"
											className="inline-block-visible-md ml-2"
											icon={<UploadOutlined translate="" />}
											loading={fetchinPpslForUpload}
											onClick={showUploadPopup}
										>
											<span className="inline-block-visible-xxl">Upload</span>
										</Button>
									</Popover>
									<Popover content="Export CSV" trigger="hover">
										<Button
											size="large"
											icon={<DownloadOutlined translate="" />}
											className="inline-block-visible-md ml-2"
											onClick={downloadCSV}
											loading={exportingCsv}
										>
											<span className="inline-block-visible-xxl">Export CSV</span>
										</Button>
									</Popover>
								</FeildTechSLManagePermissionChecker>
								<FeildTechSLManagePermissionChecker>
									<Popover
										content={editAllowed ? 'Edit Parts Level' : 'No Parts Level available to edit'}
										trigger="hover"
									>
										<Button
											size="large"
											className="inline-block-visible-md ml-2"
											loading={fetchingPpsl}
											icon={<EditOutlined translate="" />}
											onClick={onBulkPpslEdit}
											disabled={!editAllowed}
										>
											Edit
										</Button>
									</Popover>
								</FeildTechSLManagePermissionChecker>
								<FeildTechSLManagePermissionChecker>
									<Popover content="Add Part" trigger="hover">
										<Button
											size="large"
											className="inline-block-visible-md ml-2"
											icon={<PlusOutlined translate="" />}
											type="primary"
											onClick={onAddPart}
										>
											Add
										</Button>
									</Popover>
								</FeildTechSLManagePermissionChecker>
							</div>
						)
					}
					emptyState={
						<EmptyState
							graphic={
								<img
									style={{ marginBottom: 8 }}
									src="https://s3.amazonaws.com/mock-data-assets/categories/images/sunset.svg"
									alt="Nothing much to see here."
								/>
							}
							headline={'Nothing much to see here.'}
							body={
								<div style={{ textAlign: 'center' }}>
									<div style={{ maxWidth: 440, marginBottom: 16 }}>
										You haven't added any parts to this location yet.
									</div>
								</div>
							}
						/>
					}
				/>
			)}
			{transferModalVisible && (
				<BulkTransferFromStockLocationModal
					onCancel={hideTransferModal}
					stockLocationId={stockLocationId}
					loading={fetching}
					isPartsTransfer
					onTransferToSL={onToStockLocationSelected}
				/>
			)}
			{!fetchingPpsl && (
				<EditPartsBulk
					ppsls={ppsls}
					onSuccess={onBulkPpslEditSuccess}
					onDataUpload={onDataUpload}
				/>
			)}
			{uploadPopupVisible && (
				<UploadWithSampleModal
					type="Parts Level"
					title="Upload Parts Level"
					downloadText="Do you want to download the existing parts level?"
					sampleFileText="Download Parts Level"
					onCancel={hideUploadPopup}
					onDownloadSample={onDownloadSample}
					fields={PART_FLATFILE_HEADER}
					onDataLoad={onDataUpload}
					showWarningMessage
				/>
			)}
		</>
	);
};

const mapStateToProps = (state, ownProps) => ({
	match: ownProps.match,
	history: ownProps.history,
	location: ownProps.location,
	partsPerStockLocation: state.parts_per_stock_locations,
	fetching: state.parts_per_stock_locations.fetching,
	currentUser: state.session.currentUser,
	stockLocation: state.stock_locations.detail,
});

const mapDispatchToProps = (dispatch) => ({
	fetchPartsPerStockLocation: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			partsPerStockLocationsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchAllPartsPerStockLocation: (params) =>
		dispatch(fetchAllPartsPerStockLocactionForSupplier(params, DEFAULT_NAME_SORTER)),
	updatePartsPerStocklocationFilters: (filters, targetCollection) =>
		dispatch(
			PARTS_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)
		),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			PARTS_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(
				filters,
				targetCollectionName
			)
		),
	createBulk: (entities) =>
		dispatch(partsPerStockLocationsRestCrudThunksForSupplier.createBulk(entities)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StockLocationsPartsPage));

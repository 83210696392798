import { PARTS_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS } from '../actions/parts_per_stock_locations_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import FetchUtils, { parseJSON } from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { logoutSuccess } from '../actions/session_actions';

export const partsPerStockLocationsRestCrudThunksForBuyer = new RestCrudThunks(
	'parts_per_stock_locations',
	'parts_per_stock_location',
	'partsPerStockLocation',
	'partsPerStockLocations',
	PARTS_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'inventory/'
);
export const partsPerStockLocationsRestCrudThunksForSupplier = new RestCrudThunks(
	'parts_per_stock_locations',
	'parts_per_stock_location',
	'partsPerStockLocation',
	'partsPerStockLocations',
	PARTS_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'inventory/'
);

const fetchUtils = new FetchUtils();
export const bulkTransferPartsPerStockLocaction = (roleType) => (entities, toStockLocationid) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/inventory/parts_per_stock_locations/bulk_transfer/${toStockLocationid}`;

	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(PARTS_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateStart({}));

			fetchUtils
				.post(
					baseUrl + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entities,
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(PARTS_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateError(d.message, d.data));
						reject(d.data);
					} else {
						dispatch(PARTS_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(PARTS_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateError(d.message, d.data));
					reject(d.message);
				});
		});
	};
};

export const bulkTransferPartsPerStockLocactionForSupplier = bulkTransferPartsPerStockLocaction(
	ROLE_TYPES.SUPPLIER
);

const fetchAllPartsPerStockLocaction =
	(roleType) =>
	(params, sorting = null) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/inventory/all_parts_per_stock_locations`;

		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.data);
						} else {
							resolve(d.data);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};

export const fetchAllPartsPerStockLocactionForSupplier = fetchAllPartsPerStockLocaction(
	ROLE_TYPES.SUPPLIER
);

export const ppslAdjust = (roleType) => (id, data) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/inventory/parts_per_stock_locations/${id}/adjust`;

	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(PARTS_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateStart({}));

			fetchUtils
				.patch(
					baseUrl + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data,
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(PARTS_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateError(d.message, d.data));
						reject(d.data);
					} else {
						dispatch(PARTS_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(PARTS_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateError(d.message, d.data));
					reject(d.message);
				});
		});
	};
};

export const ppslAdjustForSupplier = ppslAdjust(ROLE_TYPES.SUPPLIER);

import * as React from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import moment from 'moment';
import {
	dateFormatter,
	floatToPercentageString,
	timeUntilOrSince,
} from '../../utils/DataFormatterUtils';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { UserName } from '../name_component/user/UserNameComponent';
import OWInsightPopover from '../ow_insight_popover/OWInsightPopover';
import { UNFINISHED_WORK_ORDER_DISPLAY_STATUS_DISPLAY_NAMES } from '../../constants/WorkOrderDisplayStatuses';
import WalkthroughStatusDisplay from '../walkthrough_status_display/WalkthroughStatusDisplay';
import DateTimeHover from '../date_time_component/DateTime';

const style = require('./WalkthroughRowDisplay.less');

export const WalkthroughRowDisplay: React.FC<any> = ({ walkthrough, ...props }) => {
	const now = moment();
	const completionPercentage =
		(walkthrough.taskAnswers.length &&
			walkthrough.taskAnswers.map((t) => t.answer && t.answer.trim()).filter((ans) => !!ans)
				.length /
				(walkthrough.taskAnswers.length * 1.0)) ||
		0;
	let statusText = '';
	if (walkthrough.status === 'complete') {
		statusText = 'Completed';
	} else if (completionPercentage === 0 || completionPercentage === Infinity) {
		statusText = 'Not Started';
	} else {
		statusText = `${floatToPercentageString(completionPercentage, 0)} Answered`;
	}

	const commentsNumber = 0;
	const sumOfComments = walkthrough.taskAnswers.reduce(
		(number, task) => (task.comments ? 1 : 0) + number,
		commentsNumber
	);

	const attachmentsNumber = 0;
	const sumOfAttachments = walkthrough.taskAnswers.reduce(
		(number, task) => nullSafeGetOrElse('attachments.length', task, 0) + number,
		attachmentsNumber
	);

	return (
		<div className="walkthroughRowDisplayBuyer" {...props}>
			<div className="walkthroughRowDisplay__leftSide">
				<div className="walkthroughRowDisplay__rowTitle" style={{ lineHeight: '18.0px' }}>
					<Ellipsis tooltip={true} length={64}>
						{walkthrough.title}
					</Ellipsis>
				</div>
				<div className="walkthroughRowDisplay__rowInlineGroup">
					<div className="walkthroughRowDisplay__text">
						<WalkthroughStatusDisplay status={statusText} displayText={statusText} />
					</div>
					{walkthrough.status === 'complete' ? (
						<div className="walkthroughRowDisplay__text">
							<span>
								Completed
								{walkthrough.completedAt ? (
									<>
										{' '}
										<DateTimeHover timestamp={nullSafeGet('completedAt', walkthrough)} showDate />
									</>
								) : (
									''
								)}
								{` `}
								{walkthrough.completedByContact ? (
									<span>
										{'by '}
										<UserName
											contact={walkthrough.completedByContact}
											mode={'inline'}
											placement={'top'}
										/>
									</span>
								) : null}
							</span>
						</div>
					) : null}
					<div className="walkthroughRowDisplay__text">
						<span>
							{`Created `}{' '}
							<DateTimeHover timestamp={nullSafeGet('createdAt', walkthrough)} showDate />
						</span>
					</div>
				</div>
			</div>
			<div className="walkthroughRowDisplay__rightSide flex flex-grow-0">
				<div className="walkthroughRowDisplay__extra1">
					<div className="walkthroughRowDisplay__pill">
						<div
							style={{
								display: 'flex',
								gap: 16,
								flexGrow: 1,
								justifyContent: 'flex-end',
								alignContent: 'center',
							}}
						>
							{sumOfComments > 0 ? (
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
									}}
								>
									<div
										style={{
											minWidth: 10,
											margin: '0px',
										}}
									>
										{sumOfComments}
									</div>
									<i
										style={{
											fontSize: 20,
											verticalAlign: 'middle',
											cursor: 'pointer',
											color: '#999',
											margin: '0px 2px',
											paddingLeft: '4px',
											paddingRight: '4px',
										}}
										className="icons8-font icons8-speech-bubble"
									/>
								</div>
							) : null}
							{sumOfAttachments > 0 ? (
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
									}}
								>
									<div
										style={{
											minWidth: 10,
											margin: '0px',
										}}
									>
										{sumOfAttachments}
									</div>
									<i
										style={{
											fontSize: 20,
											verticalAlign: 'middle',
											cursor: 'pointer',
											color: '#999',
											margin: '0px 2px',
											paddingLeft: '4px',
											paddingRight: '4px',
										}}
										className="icons8-font icons8-compact-camera"
									/>
								</div>
							) : null}
							<OWInsightPopover
								title={'Work Orders'}
								breakdown={'displayStatus'}
								labels={UNFINISHED_WORK_ORDER_DISPLAY_STATUS_DISPLAY_NAMES}
								icon={'icons8-wrench'}
								defaultData={walkthrough.taskAnswers.flatMap((task) =>
									task.hasOwnProperty('workOrders') ? task.workOrders : []
								)}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

import { ContactAvatar } from '../contact_avatar/ContactAvatar';
import * as React from 'react';
import { Select } from 'antd';
import TypedSelect, { FieldNames, FilterFunc, SelectHandler } from 'rc-select/lib/Select';
import { RenderNode } from 'rc-select/lib/BaseSelect';
import { createElement } from 'react';
import UserOptionComponent from '../user_option_component/UserOptionComponent';

let defaultConfig = {
	showSearch: true,
	autoFocus: true,
	open: true,
	style: null,
};

export default class UserSelectComponent extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			selectConfig: { ...defaultConfig, ...props.config },
			search: '',
		};
	}
	onSearch = (value) => {
		this.setState({ search: value });
	};
	render() {
		const { users, show } = this.props;
		const { selectConfig, search } = this.state;
		let selectStyle = { display: show ? 'inline-block' : 'none' };

		let config = {
			...selectConfig,
			onSearch: (value) => {
				selectConfig['onSearch'](value);
				this.onSearch(value);
			},
			style: { ...{ ...selectConfig.style, ...selectStyle } },
			filterOption: (input, value) => {
				return value.label.toLowerCase().includes(input.toLowerCase())
					? value.label.toLowerCase().includes(input.toLowerCase())
					: value.key.toLowerCase().includes(input.toLowerCase());
			},
		};
		return (
			<Select {...config}>
				{users.map((user) => {
					return (
						<Select.Option
							key={user.contact.email}
							value={JSON.stringify(user)}
							title={user.contact.nameGiven.concat(' ', user.contact.nameFamily)}
							label={user.contact.nameGiven.concat(' ', user.contact.nameFamily)}
							style={{ display: user.show ? 'flex' : 'none' }}
						>
							<UserOptionComponent
								contact={user.contact}
								status={user.status}
								search={search}
							></UserOptionComponent>
						</Select.Option>
					);
				})}
			</Select>
		);
	}
}

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { workOrdersRestCrudThunksForSupplier } from '../../thunks/work_orders_thunks';
import SupplierWorkOrderEditForm from '../supplier_work_order_edit_form/SupplierWorkOrderEditForm';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { goBackLink, recursiveRemovePreserve } from '../../utils/HistoryUtils';

const style = require('./EditSupplierWorkOrderPage.less');
const queryString = require('qs');

class EditSupplierWorkOrderPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const { getWorkOrder, match } = this.props;
		const workOrderId = match.params.id;
		getWorkOrder(workOrderId);
	}

	render() {
		const { history, workOrder, fetching, location } = this.props;
		const defaultUrlPath = `/supplier/workOrders/detail/${workOrder.id}/details`;

		//noinspection TypeScriptUnresolvedVariable
		return [
			<LogOnMountWithStandardEventProperties eventType="visited supplier edit work order page" />,
			<EditPage
				title="Edit Work Order"
				formComponentProps={{
					formData: workOrder,
					onSuccess: (rec) => {
						let string = goBackLink(location, defaultUrlPath);
						const [path, queryParams] = string.split('?');
						let urlSearchParams = queryString.parse(queryParams);
						history.push(`${path}?${recursiveRemovePreserve(urlSearchParams)}`);
					},
				}}
				fetching={fetching}
				FormComponent={SupplierWorkOrderEditForm}
			/>,
		];
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		history: ownProps.history,
		workOrder: state.work_orders.detail,
		fetching: state.work_orders.fetching,
		match: ownProps.match,
	};
};

const mapDispatchToProps = (dispatch) => ({
	getWorkOrder: (id) => dispatch(workOrdersRestCrudThunksForSupplier.readOne(id)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(EditSupplierWorkOrderPage)
);

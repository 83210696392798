import React, { FC, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Card } from 'antd';
import LocationTypeForm from '../location_type_form/LocationTypeForm';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;

const NewLocationTypePage: FC<any> = ({ userType, history }): React.ReactElement => {
	const onCreateSuccess = useCallback(
		(rec) => {
			history.push(`/${userType}/locations/templates/detail/${rec.id}`);
		},
		[history, userType]
	);

	return (
		<Content>
			<LogOnMountWithStandardEventProperties
				eventType={`visited ${userType} new location type page`}
			/>
			<Row style={{ margin: '0.5em -8px' }} gutter={16}>
				<Col span={24}>
					<Card>
						<div>
							<h5 style={{ marginBottom: 24 }}>New Location Type</h5>
							<LocationTypeForm formData={{}} onSuccess={onCreateSuccess} />
						</div>
					</Card>
				</Col>
			</Row>
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	locationType: state.location_types.detail,
});

const ComponentWithoutUserType = withRouter(connect(mapStateToProps)(NewLocationTypePage));

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import React, { FC, useCallback, useMemo, useState } from 'react';
import { getCurrency, getObjectValues, nullSafeGet } from '../../utils/DataAccessUtils';
import { Button, Form, InputNumber, Modal, message, Select } from 'antd';
import { supplierExchangeRatesRestCrudThunksForSupplier } from '../../thunks/supplier_exchange_rates_thunks';
import { connect } from 'react-redux';
import { capitalize } from '../../utils/DataFormatterUtils';

const FORM_NAME = 'exchange-rate-form';

const AddEditExchangeRateModal: FC<any> = ({
	exchangeRate,
	update,
	create,
	onCancel,
	onSuccess,
	currencies,
	currentUser,
}): React.ReactElement => {
	const [saving, setSaving] = useState(false);

	const isUpdate = useMemo(() => nullSafeGet('id', exchangeRate), [exchangeRate]);

	const currencyRecordsList = getObjectValues(currencies.records);
	const currencyRecordsMap = currencies.records;

	const submitMethod = useMemo(() => (isUpdate ? update : create), [create, isUpdate, update]);

	const initialValues = useMemo(
		() => ({
			...(exchangeRate || {}),
		}),
		[exchangeRate]
	);

	const userCurrencyId = useMemo(
		() => nullSafeGet('id', getCurrency({ currentUser })),
		[currentUser]
	);

	const onSubmit = useCallback(
		(values) => {
			const entity = {
				...(exchangeRate || {}),
				...values,
			};
			setSaving(true);
			submitMethod(entity)
				.then(() => {
					message.success(`Exchange rate ${isUpdate ? 'updated' : 'added'} successfully!`);
					onSuccess();
				})
				.catch((err) => message.error(err))
				.finally(() => setSaving(false));
		},
		[isUpdate, exchangeRate, onSuccess, submitMethod]
	);

	return (
		<Modal
			visible={true}
			width={600}
			title={`${isUpdate ? 'Update' : 'New'} exchange rate`}
			onCancel={onCancel}
			closable={false}
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button
					type="primary"
					size="large"
					style={{ marginLeft: '16px' }}
					key="submit"
					htmlType="submit"
					form={FORM_NAME}
					loading={saving}
				>
					{isUpdate ? 'Update' : 'Create'}
				</Button>,
			]}
		>
			<Form
				id={FORM_NAME}
				layout="vertical"
				requiredMark={false}
				initialValues={initialValues}
				onFinish={onSubmit}
			>
				<div className="flex flex-row items-center">
					<Form.Item
						name="currencyId"
						label="Currency"
						rules={[
							{
								required: true,
								message: `Please select a currency`,
							},
						]}
					>
						<Select
							style={{ width: 132 }}
							optionFilterProp="children"
							filterOption={(input, option) =>
								currencyRecordsMap[option.props.value].displayNamePlural
									.toLowerCase()
									.indexOf(input.toLowerCase()) >= 0
							}
							placeholder="Currency"
							disabled={isUpdate}
						>
							{currencyRecordsList.map((currency) => (
								<Select.Option
									key={currency.id}
									value={currency.id}
									disabled={currency.id === userCurrencyId}
								>
									{capitalize(currency.displayNamePlural)} ({currency.abbreviation})
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<div className="ml-2">{`-> ${userCurrencyId}`}</div>
				</div>
				<Form.Item
					name="exchangeRate"
					label="Exchange Rate"
					rules={[
						{
							required: true,
							message: 'Exchange rate is required!',
						},
					]}
				>
					<InputNumber style={{ width: '100%' }} />
				</Form.Item>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	currencies: state.currencies,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	create: (entity) => dispatch(supplierExchangeRatesRestCrudThunksForSupplier.create(entity)),
	update: (entity) => dispatch(supplierExchangeRatesRestCrudThunksForSupplier.update(entity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditExchangeRateModal);

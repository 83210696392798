import * as React from 'react';
import { ContactAvatar } from '../contact_avatar/ContactAvatar';
import { UserName } from '../name_component/user/UserNameComponent';

const style = require('./FieldTechRowDisplay.less');

export const FieldTechRowDisplay: React.FC<any> = ({ fieldTech, ...props }) => (
	<div className="fieldTechRowDisplay" {...props}>
		<div className="fieldTechRowDisplay__rowTitle">
			<div>
				<ContactAvatar userType="supplier" contact={fieldTech.supplierContact} />
				<span style={{ marginLeft: 16 }}>
					<UserName mode={'inline'} userType="supplier" contact={fieldTech.supplierContact} />
				</span>
			</div>
		</div>
	</div>
);

import React, { FC } from 'react';
import { Route, Redirect } from 'react-router-dom';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';

const TroubleshootingPage: FC<any> = ({ childRoutes }): React.ReactElement => {
	const basePath = '/buyer/admin/troubleshooting';

	return (
		<div>
			<Route
				path={basePath}
				exact={true}
				render={(props) => (
					<Redirect
						to={{
							pathname: `${basePath}/overview`,
						}}
					/>
				)}
			/>
			{childRoutes.map((r, i) => (
				<RouteWithSubRoutes key={i} {...r} />
			))}
		</div>
	);
};

export default TroubleshootingPage;

import * as React from 'react';
import { Layout, Row, Col, DatePicker, Card, Steps, Button, List } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AddressDisplay } from '../address_display/AddressDisplay';
import { KeyValueDisplay } from '../key_value_display/KeyValueDisplay';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { dateFormatter, optionalFilter } from '../../utils/DataFormatterUtils';
import { Link } from 'react-router-dom';
import Mapbox from '../mapbox/Mapbox';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { FlexibleImageAvatar } from '../flexible-image-avatar/FlexibleImageAvatar';
import { ROLES } from '../roles/roles';
import { getProtectedImageUriForSupplier } from '../../utils/FileAccessUtils';
import { checkUserTypeToken } from '../../thunks/session_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { getPaymentMethod } from '../../thunks/payments_thunks';
import { fetchCredits } from '../../thunks/credits_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const ListItem = List.Item;
const ListItemMeta = ListItem.Meta;

const { Content } = Layout;
const Step = Steps.Step;
const { RangePicker } = DatePicker;

const style = require('./MySupplierProfilePage.less');
// const iconStyle = require('../../styles/iconfont.css');
const dateFormat = 'MM-DD-YYYY';

class MySupplierProfilePage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = { loading: true };
	}

	componentDidMount() {
		const { refreshCurrentUser, getCreditsCount, fetchPaymentMethod, currentUser } = this.props;
		refreshCurrentUser().then((resp) => {
			fetchPaymentMethod(currentUser.email, resp.json.token);
			getCreditsCount(resp.json.token);
			this.setState({ loading: false });
		});
	}

	render() {
		const { supplier, match, credits, currentUser, payments } = this.props;
		const supplierContactId = match.params.id;
		const dateFormat = 'MMM D, YYYY';
		const { loading } = this.state;

		const emptorCreditsCount = credits.availableCredits;

		const userDetails = {
			Roles: currentUser.roles,
			'Member Since': dateFormatter(currentUser.createdAt, dateFormat),
			Email: currentUser.email,
			'Work Phone': optionalFilter(currentUser.phoneWork),
			'Mobile Phone': optionalFilter(currentUser.phoneMobile),
			Fax: optionalFilter(currentUser.fax),
		};
		const userDetailsRenderFuncs = {
			Roles: (roles) => <div>{roles.map((r) => ROLES[r].displayName).join(', ')}</div>,
		};
		const facilityDetails = {
			Facility: currentUser.facility,
			Address: currentUser.facility.primaryAddress,
		};

		const facilityDetailsRenderFuncs = {
			Facility: (facility) => (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<div style={{ marginRight: 8 }}>
						<FlexibleImageAvatar
							displayName={currentUser.facility.displayName}
							showPopover={false}
							popoverText={currentUser.facility.displayName}
							imageUrl={currentUser.facility.logoURL}
							width="32px"
							height="32px"
						/>
					</div>
					<div style={{ fontSize: 16 }}>{facility.displayName}</div>
				</div>
			),
			Address: (address) => <AddressDisplay address={address} />,
		};

		let imgFileId, imgFileName, photoUri;
		if (currentUser.photoLink) {
			[imgFileId, imgFileName] = currentUser.photoLink.split('/');
			photoUri = getProtectedImageUriForSupplier(imgFileId, imgFileName, 'auto', 240);
		}

		return (
			<Content className="supplierProfilePage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited my supplier profile page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				{loading ? (
					<PageLoadingPlaceholder />
				) : (
					<div>
						<Row style={{ margin: '0.5em 0' }} gutter={16}>
							<Col span={24}>
								<Card>
									<div className="supplierProfilePage__header">
										<div style={{ float: 'left', display: 'flex', alignItems: 'center' }}>
											<div style={{ marginRight: 32 }}>
												<FlexibleImageAvatar
													displayName={currentUser.nameGiven + ' ' + currentUser.nameFamily}
													showPopover={false}
													popoverText={''}
													imageUrl={photoUri}
													width="96px"
													height="96px"
												/>
											</div>
											<div
												style={{
													height: 80,
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'center',
												}}
											>
												<div style={{ display: 'flex' }}>
													<h4>
														{currentUser.nameGiven} {currentUser.nameFamily}
													</h4>
												</div>
												{currentUser.department && currentUser.title ? (
													<div style={{ marginBottom: 0, fontSize: 16, display: 'flex' }}>
														{currentUser.department}, {currentUser.title}
													</div>
												) : null}
											</div>
										</div>
										<div
											style={{
												float: 'right',
												width: '60%',
												display: 'flex',
												justifyContent: 'flex-end',
												maxWidth: 550,
											}}
										>
											<Link to="/supplier/myProfile/edit">
												<Button>Edit Profile</Button>
											</Link>
										</div>
									</div>
								</Card>
							</Col>
						</Row>

						<Row style={{ margin: '0.5em 0' }} gutter={16}>
							<Col span={24}>
								<Card title="Account Details">
									<KeyValueDisplay
										keyValueStore={userDetails}
										renderFuncs={userDetailsRenderFuncs}
									/>
								</Card>
							</Col>
						</Row>
					</div>
				)}
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	currentUser: state.session.currentUser,

	credits: state.credits,
	payments: state.payments,
});

const mapDispatchToProps = (dispatch) => ({
	refreshCurrentUser: () => dispatch(checkUserTypeToken(ROLE_TYPES.SUPPLIER)()),
	fetchPaymentMethod: (email) => dispatch(getPaymentMethod(email)),
	getCreditsCount: () => dispatch(fetchCredits()),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(MySupplierProfilePage)
);

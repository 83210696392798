import { nullSafeGet } from '../../utils/DataAccessUtils';

export const getAllSupplierPrivateNetworkEntities = (values) => {
	const locationIds = values.allLocations
		? []
		: nullSafeGet('locationIds.0', values)
		? values.locationIds
		: undefined;
	const problemTypeIds = values.allProblemTypes
		? []
		: nullSafeGet('problemTypeIds.0', values)
		? values.problemTypeIds
		: undefined;

	const noLocationOrProblemTypeAccess = !locationIds && !problemTypeIds;

	if (noLocationOrProblemTypeAccess) {
		return [
			{
				buyerCompanyId: values.buyerCompanyId,
				supplierFacilityId: values.supplierFacilityId,
				noLocationOrProblemTypeAccess,
			},
		];
	} else if (nullSafeGet('0', locationIds) && nullSafeGet('0', problemTypeIds)) {
		return locationIds.flatMap((locationId) =>
			problemTypeIds.map((problemTypeId) => ({
				buyerCompanyId: values.buyerCompanyId,
				supplierFacilityId: values.supplierFacilityId,
				locationId,
				problemTypeId,
			}))
		);
	} else if (nullSafeGet('0', locationIds)) {
		return locationIds.map((locationId) => ({
			buyerCompanyId: values.buyerCompanyId,
			supplierFacilityId: values.supplierFacilityId,
			locationId,
		}));
	} else if (nullSafeGet('0', problemTypeIds)) {
		return problemTypeIds.map((problemTypeId) => ({
			buyerCompanyId: values.buyerCompanyId,
			supplierFacilityId: values.supplierFacilityId,
			problemTypeId,
		}));
	} else {
		return [
			{
				buyerCompanyId: values.buyerCompanyId,
				supplierFacilityId: values.supplierFacilityId,
			},
		];
	}
};

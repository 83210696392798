import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PlannedMaintenanceTemplateForm from '../planned_maintenance_template_form/PlannedMaintenanceTemplateForm';
import {
	plannedMaintenanceTemplatesRestCrudThunksForBuyer,
	plannedMaintenanceTemplatesRestCrudThunksForSupplier,
} from '../../thunks/planned_maintenance_templates_thunks';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { ROLE_TYPES } from '../../utils/DataConstants';

const style = require('./EditPlannedMaintenanceTemplatePage.less');

class EditPlannedMaintenanceTemplatePage extends React.Component<any, any> {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const { getPlannedMaintenanceTemplate, match } = this.props;
		const plannedMaintenanceTemplateId = match.params.id;
		getPlannedMaintenanceTemplate(plannedMaintenanceTemplateId);
	}

	render() {
		const { history, fetching, pmTemplate, userType } = this.props;

		return [
			<LogOnMountWithStandardEventProperties
				eventType={`visited ${userType} edit pm template page`}
			/>,
			<EditPage
				title="Edit Planned Maintenance Template"
				formComponentProps={{
					formData: pmTemplate,
					onSuccess: (rec) =>
						history.push(`/${userType}/plannedMaintenance/templates/detail/${rec.id}`),
				}}
				fetching={fetching}
				FormComponent={PlannedMaintenanceTemplateForm}
			/>,
		];
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	pmTemplate: state.planned_maintenance_templates.detail,
	fetching: state.planned_maintenance_templates.fetching,
	match: ownProps.match,
	userType: (state as any).session.userType,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getPlannedMaintenanceTemplate: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? plannedMaintenanceTemplatesRestCrudThunksForSupplier.readOne(id)
				: plannedMaintenanceTemplatesRestCrudThunksForBuyer.readOne(id)
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(EditPlannedMaintenanceTemplatePage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

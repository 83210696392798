import { DownloadOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import React from 'react';

export default function RightActions({
	downloadCSV,
	invoiceDownloadFormatRecords,
	downloadInvoiceFormatCSV,
}) {
	return (
		<>
			{invoiceDownloadFormatRecords.length > 0 ? (
				<div style={{ marginLeft: 16 }}>
					<Dropdown
						overlay={
							<Menu>
								<Menu.Item onClick={downloadCSV} key={'owdefault'}>
									OpenWrench CSV
								</Menu.Item>
								{invoiceDownloadFormatRecords.map((i) => (
									<Menu.Item onClick={() => downloadInvoiceFormatCSV(i.name)} key={i.name}>
										{i.name}
									</Menu.Item>
								))}
							</Menu>
						}
					>
						<Button size="large" icon={<DownloadOutlined />}>
							Export <DownOutlined />
						</Button>
					</Dropdown>
				</div>
			) : (
				<Button
					size="large"
					icon={<DownloadOutlined />}
					className="inline-block-visible-md"
					onClick={downloadCSV}
				>
					<span className="inline-block-visible-xxl">Export CSV</span>
				</Button>
			)}
		</>
	);
}

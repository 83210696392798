import { Button } from 'antd';
import React, { FC, useMemo } from 'react';
import { isValidFilter } from '../../utils/DataAccessUtils';

interface ClearFiltersProps {
	activeFilters: any;
	activeSorter: any;
	preAppliedFilters: any;
	defaultSorters: any;
	clearFilters: (e) => void;
	defaultFilters: any;
	style: any;
}

const ClearFilter: FC<ClearFiltersProps> = ({
	activeFilters,
	defaultFilters,
	activeSorter,
	preAppliedFilters,
	defaultSorters,
	clearFilters,
	style = {},
}): React.ReactElement => {
	const activeFiltersAndSorters = useMemo(
		() => ({
			...activeFilters,
			...activeSorter,
		}),
		[activeFilters, activeSorter]
	);

	const isFilterActive = useMemo(
		() => isValidFilter(activeFiltersAndSorters, preAppliedFilters, defaultFilters, defaultSorters),
		[activeFiltersAndSorters, defaultFilters, defaultSorters, preAppliedFilters]
	);

	return isFilterActive ? (
		<Button onClick={clearFilters} size="large" style={{ ...style }}>
			Clear Filters
		</Button>
	) : null;
};

export default ClearFilter;

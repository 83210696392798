import React, { FC, useMemo } from 'react';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { Card } from 'antd';
import { ContactCardDisplay } from '../contact_card_display/ContactCardDisplay';
import { connect } from 'react-redux';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { isInternalTech } from '../../utils/AuthUtils';

const LocationContactInformation: FC<any> = ({ location, currentUser }): React.ReactElement => {
	const userType = useMemo(() => currentUser.userType, [currentUser.userType]);

	const isThirdPartySupplier = useMemo(
		() => userType === ROLE_TYPES.SUPPLIER && !isInternalTech(currentUser),
		[currentUser, userType]
	);

	return (
		<>
			{nullSafeGetOrElse('facilityInChargeContacts', location, []).length > 0 ? (
				<div className="rowSpacing">
					<Card title="Facilities Contacts">
						{location.facilityInChargeContacts.map((contact, idx) => (
							<div className="rowSpacing" key={`loc-fac-cont-${idx}`}>
								<ContactCardDisplay roleType={userType} contact={contact} />
							</div>
						))}
					</Card>
				</div>
			) : nullSafeGet('facilityInChargeContact', location) ? (
				<div className="rowSpacing">
					<ContactCardDisplay
						roleType={userType}
						title="Facilities Contact"
						contact={location.facilityInChargeContact}
					/>
				</div>
			) : null}
			{nullSafeGetOrElse('primaryContactsContacts', location, []).length > 0 ? (
				<div className="rowSpacing">
					<Card title="Primary Contacts">
						{nullSafeGetOrElse('primaryContactsContacts', location, []).map((contact, idx) => (
							<div className="rowSpacing" key={`loc-primary-cont-${idx}`}>
								<ContactCardDisplay roleType={userType} contact={contact} />
							</div>
						))}
					</Card>
				</div>
			) : null}
			{!isThirdPartySupplier &&
			nullSafeGetOrElse('sendApprovedInvoicesToEmails', location, []).length > 0 ? (
				<div className="rowSpacing">
					<Card title="Approved invoice emails">
						{nullSafeGetOrElse('sendApprovedInvoicesToEmails', location, []).map((email, idx) => (
							<div className="rowSpacing" key={`approved-${idx}`}>
								{email}
							</div>
						))}
					</Card>
				</div>
			) : null}
		</>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

export default connect(mapStateToProps)(LocationContactInformation);

import { EQUIPMENT_TYPES_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/equipment_types_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = standardInitialStateGenerator([
	'equipmentsIndex',
	'VENDOR_ASSOCIATED_EQUIPMENTS_TARGET_COLLECTION',
	'equipmentTypesAtStockLocationIndex',
	'addEquipmentsInWorkOrderIndex',
	'epslAdvancedFilters',
	'purchaseRequestsAdvancedFilters',
	'purchaseOrdersAdvancedFilters',
	'equipmentCatalogsAdvancedFilters',
	'poLineItemsAdvancedFilters',
	'prLineItemsAdvancedFilters',
	'equipmentTypeForTransferIndex',
]);
export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState);

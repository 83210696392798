import { Input, Select, Spin } from 'antd';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	fetchCapExCategoriesForBuyer,
	fetchCapExCategoriesForSupplier,
} from '../../thunks/invoices_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';

const CapExCategoryInput: FC<any> = ({
	fetchCapExCategories,
	value,
	onChange,
}): React.ReactElement => {
	const [categoriesLoading, setCategoriesLoading] = useState(false);
	const [categories, setCategories] = useState([]);

	const isCategoriesAvailable = useMemo(() => categories && categories.length > 0, [categories]);

	useEffect(() => {
		setCategoriesLoading(true);
		fetchCapExCategories({
			limit: 50,
		})
			.then((res) =>
				setCategories(
					res.map((cat) => ({
						value: cat.id,
						label: cat.name,
					}))
				)
			)
			.finally(() => setCategoriesLoading(false));
	}, []);

	const onValueChange = useCallback(
		(val) => {
			const changedValue = isCategoriesAvailable ? categories.find((_) => _.value === val) : val;
			onChange(changedValue);
		},
		[categories, onChange, isCategoriesAvailable]
	);

	const modifiedValue = useMemo(() => (value && value.value) || value, [value]);

	return categoriesLoading ? (
		<Spin />
	) : isCategoriesAvailable ? (
		<Select
			value={modifiedValue}
			onChange={onValueChange}
			placeholder="Select a CapEx category"
			options={categories}
			style={{ maxWidth: 640 }}
		/>
	) : (
		<Input value={modifiedValue} onChange={onValueChange} style={{ maxWidth: 640 }} />
	);
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchCapExCategories: (params) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? fetchCapExCategoriesForSupplier(params)
				: fetchCapExCategoriesForBuyer(params)
		),
});

const ComponentWithoutUserType = withRouter(connect(null, mapDispatchToProps)(CapExCategoryInput));

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import { detect } from 'detect-browser';
import { CLIENT_TYPES, CLIENTS } from '../../constants/customer_analytics';
import { RELEASE_VERSION } from '../../utils/EnvConfigUtils';
import * as React from 'react';
import { LogOnMount } from '@amplitude/react-amplitude';
import { connect } from 'react-redux';

class OWLogOnMount extends React.Component<any, any> {
	render() {
		const { currentUser, eventProperties = {}, eventType } = this.props;
		const browser = detect() || {
			os: navigator.userAgent.toLowerCase(),
			name: 'NA',
			version: 'NA',
		};
		return (
			<LogOnMount
				eventProperties={{
					client: CLIENTS.OPEN_WRENCH_WEB_CLIENT,
					deviceType: browser.os,
					deviceName: browser.name + ' ' + browser.version,
					clientVersion: RELEASE_VERSION,
					clientType: CLIENT_TYPES.WEB,
					roleType: currentUser.userType,
					...eventProperties,
				}}
				eventType={eventType}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	currentUser: state.session.currentUser,
	eventProperties: ownProps.eventProperties,
	eventType: ownProps.eventType,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(OWLogOnMount);

import { notification } from 'antd';
import 'whatwg-fetch';
import { retrieveCachedUserDetails } from './session_thunks';

export function parseJSON(response): any {
	return new Promise((resolve) =>
		response
			.json()
			.then((json) => {
				resolve({
					status: response.status,
					ok: response.ok,
					json,
				});
			})
			.catch((err) => {
				resolve(response);
			})
	);
}

const isReadOnlyUser = (url) => {
	const exceptionURLs = [
		'api/v1/supplier/work_order/work_orders/append_notes',
		'api/v1/buyer/work_order/work_orders/append_notes',
		'api/v1/supplier/notification/user_notifications/has_seen',
		'api/v1/buyer/notification/user_notifications/has_seen',
	];
	for (const route of exceptionURLs) {
		if (url && url.indexOf(route) >= 0) {
			return false;
		}
	}
	return localStorage.getItem('isReadOnlyUser') === 'true';
};

const isImpersonation = (url) => {
	let result = false;
	const exceptionURLs = [
		'/api/v1/buyer/buyer_sign_up_invite/',
		'/api/v1/supplier/supplier_sign_up_invite/',
	];
	for (const route of exceptionURLs) {
		if (url && url.indexOf(route) >= 0) {
			return false;
		}
	}
	result = !!localStorage.getItem('impersonation');
	return result;
};
export default class FetchUtils {
	constructor() {
		this.request = this.request.bind(this);
		this.algoliaRequest = this.algoliaRequest.bind(this);
		this.get = this.get.bind(this);
		this.post = this.post.bind(this);
		this.put = this.put.bind(this);
		this.delete = this.delete.bind(this);
	}

	getQueryString(params) {
		const esc = encodeURIComponent;
		return Object.keys(params)
			.map((k) => esc(k) + '=' + esc(params[k]))
			.join('&');
	}

	// removes all keys in blacklist or with an invalid value
	sanitize(params, forbiddenKeys = new Set([])) {
		// if params are array and not object, return array unmodified
		if (Array.isArray(params)) {
			return params;
		}
		return Object.keys(params)
			.filter((k) => {
				const v = params[k];
				return v !== null && v !== undefined && !forbiddenKeys.has(k);
			})
			.reduce((acc, k) => {
				acc[k] = params[k];
				return acc;
			}, {});
	}

	fileRequest(url, params) {
		return fetch(url, {
			method: 'GET',
			headers: {
				'X-Auth-Token': retrieveCachedUserDetails(['token']).token,
			},
		}).then((data) => {
			return data.blob();
		});
	}

	algoliaRequest(url, params, appId, apiKey) {
		const method = params.method || 'GET';
		let queryString = '';
		let body;
		let headers = params.headers || {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		};

		// set auth token header if provided
		if (appId !== undefined) {
			headers['X-Algolia-Application-Id'] = appId;
		}
		if (apiKey !== undefined) {
			headers['X-Algolia-API-Key'] = apiKey;
		}

		if (method === 'GET' || method === 'DELETE') {
			// use query params for GET and DELETE
			const FORBIDDEN_QUERY_PARAM_KEYS = new Set(['method', 'headers']);
			const sanitizedQueryParams = this.sanitize(params, FORBIDDEN_QUERY_PARAM_KEYS);
			const queryValsString = this.getQueryString(sanitizedQueryParams);
			queryString = queryValsString.length > 0 ? '?' + queryValsString : '';
		} else if (method === 'PATCH') {
			// use body for POST and PUT
			body = JSON.stringify(this.sanitize(params.data || {}));
		} else {
			body = JSON.stringify(this.sanitize(params.data || {}));
		}

		const queryUrl = url + queryString;

		return fetch(queryUrl, { method, headers, body });
	}

	request(url, params) {
		const method = params.method || 'GET';
		if (method !== 'GET' && (isImpersonation(url) || isReadOnlyUser(url))) {
			console.log('failed url');
			console.log(url);
			return new Promise((resolve, reject) => {
				notification.error({
					message: 'Action not allowed',
					description: 'You are in read-only mode. Requested action can not be allowed.',
					style: {
						width: 600,
					},
				});
				reject({ message: 'Not Allowed' });
			});
		} else {
			let queryString = '';
			let body;
			let headers = params.headers || {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			};

			// set auth token header if provided

			headers['X-Auth-Token'] = retrieveCachedUserDetails(['token']).token;

			// use query params for GET and DELETE
			if (method === 'GET' || method === 'DELETE') {
				const FORBIDDEN_QUERY_PARAM_KEYS = new Set(['method', 'headers']);
				const sanitizedQueryParams = this.sanitize(params, FORBIDDEN_QUERY_PARAM_KEYS);
				const queryValsString = this.getQueryString(sanitizedQueryParams);
				queryString = queryValsString.length > 0 ? '?' + queryValsString : '';
			}
			// use body for POST and PUT
			else if (method === 'PATCH') {
				body = JSON.stringify(this.sanitize(params.data || {}));
			} else body = JSON.stringify(this.sanitize(params.data || {}));

			const queryUrl = url + queryString;

			console.log(`${queryUrl} ${method} ${headers} ${body}`);

			return fetch(queryUrl, { method, headers, body });
		}
	}

	get(url, params) {
		return this.request(url, Object.assign({ method: 'GET' }, params));
	}

	post(url, params) {
		return this.request(url, Object.assign({ method: 'POST' }, params));
	}

	put(url, params) {
		return this.request(url, Object.assign({ method: 'PUT' }, params));
	}

	patch(url, params) {
		return this.request(url, Object.assign({ method: 'PATCH' }, params));
	}

	delete(url, params) {
		return this.request(url, Object.assign({ method: 'DELETE' }, params));
	}
}

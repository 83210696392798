import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, InputNumber, Modal, message } from 'antd';
import { getCurrency, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { useForm } from 'antd/lib/form/Form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ppslAdjustForSupplier } from '../../thunks/parts_per_stock_locations_thunks';

const FORM_NAME = 'ppsl-adjustment-form';

const PPSLAdjustmentModal: FC<any> = ({
	ppsl,
	onCancel,
	onSuccess,
	currentUser,
	match,
	ppslAdjust,
}): React.ReactElement => {
	const partsPerStockLocationId = useMemo(() => match.params.id, [match.params.id]);

	const [form] = useForm();

	const [saving, setSaving] = useState(false);

	const adjustOnHandQuantity = Form.useWatch('adjustOnHandQuantity', form);

	const { setFieldsValue } = form;

	useEffect(() => {
		const changedValue = parseInt(adjustOnHandQuantity);
		!isNaN(changedValue) && setFieldsValue({ adjustOnHandQuantity: changedValue });
	}, [adjustOnHandQuantity, setFieldsValue]);

	const onSubmit = useCallback(
		(values) => {
			setSaving(true);
			ppslAdjust(partsPerStockLocationId, {
				...values,
				adjustTotalStockedPartsCost: `${values.adjustTotalStockedPartsCost}`,
			})
				.then(() => {
					message.success('Adjustment added successfully!');
					onSuccess && onSuccess();
				})
				.catch((err) => message.error(err))
				.finally(() => setSaving(false));
		},
		[onSuccess, partsPerStockLocationId, ppslAdjust]
	);

	const userCurrencyId = useMemo(() => getCurrency({ currentUser }).id, [currentUser]);

	return (
		<Modal
			visible={true}
			width={600}
			title={`${nullSafeGetOrElse('part.name', ppsl, '')} Adjustment`}
			closable
			onCancel={onCancel}
			forceRender
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button
					type="primary"
					size="large"
					style={{ marginLeft: '16px' }}
					key="submit"
					htmlType="submit"
					form={FORM_NAME}
					loading={saving}
				>
					Add Adjustment
				</Button>,
			]}
		>
			<Form form={form} id={FORM_NAME} layout="vertical" requiredMark={false} onFinish={onSubmit}>
				<Form.Item
					name="adjustOnHandQuantity"
					label="Add Quantity"
					rules={[
						{
							required: true,
							message: `Please enter quantity`,
						},
						{
							type: 'number',
							message: 'Please enter a valid number',
						},
					]}
				>
					<InputNumber style={{ width: '100%' }} step={1} />
				</Form.Item>
				<Form.Item
					name="adjustTotalStockedPartsCost"
					label="Add Total Cost"
					rules={[
						{
							required: true,
							message: `Please enter total cost`,
						},
						{
							type: 'number',
							message: 'Please enter a valid number',
						},
					]}
				>
					<InputNumber style={{ width: '100%' }} addonAfter={userCurrencyId} />
				</Form.Item>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	ppslAdjust: (id, data) => dispatch(ppslAdjustForSupplier(id, data)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(PPSLAdjustmentModal)
);

const cancelable = (promise) => {
	let hasCancelled = false;

	return {
		promise: promise.then((v) => {
			if (hasCancelled) {
				throw { isCancelled: true };
			}

			return v;
		}),
		cancel: () => (hasCancelled = true),
	};
};

export const createTakeLatest = () => {
	let cancelablePromise = null;

	const takeLatest = (promise) => {
		if (cancelablePromise) {
			cancelablePromise.cancel();
			cancelablePromise = cancelable(promise);
		} else {
			cancelablePromise = cancelable(promise);
		}

		return cancelablePromise.promise;
	};

	return takeLatest;
};

import * as React from 'react';
import BuyerForgotPasswordForm from '../buyer_forgot_password_form/BuyerForgotPasswordForm';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

import { Layout, Row, Col } from 'antd';

const { Content } = Layout;
const style = require('./BuyerForgotPasswordPage.less');
const style2 = require('../../styles/blah.less');

export default class BuyerForgotPasswordPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Content
				className="forgotPasswordPage"
				style={{
					height: '100vh',
					display: 'flex',
					backgroundColor: '#220e3f',
					alignItems: 'center',
				}}
			>
				<LogOnMountWithStandardEventProperties eventType="visited buyer forgot password page" />
				{/*<BackTop/>*/}
				<Row
					style={{
						maxWidth: 800,
						boxShadow: 'rgba(0,0,0,0.5) 10px 10px 50px',
						width: '100%',
						margin: 'auto',
					}}
				>
					<Col sm={24} md={10}>
						<div style={{ background: '#391769', padding: 40, height: '100%' }}>
							<div
								style={{
									width: '100%',
									height: 80,
									backgroundSize: 'contain',
									backgroundRepeat: 'no-repeat',
									backgroundPosition: 'center',
									backgroundImage: `url('https://s3.amazonaws.com/emptor-data-assets/images/openwrench-logos/openwrenchlogo-white-facilities.png')`,
								}}
							/>
						</div>
					</Col>
					<Col sm={24} md={14}>
						<div style={{ padding: '56px 40px', background: 'white' }}>
							<h4 style={{ marginBottom: 24 }}>Reset Password</h4>
							<BuyerForgotPasswordForm email="" />
						</div>
					</Col>
				</Row>
			</Content>
		);
	}
}

import { DISPATCHERS_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/dispatchers_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = standardInitialStateGenerator(['dispatchersIndex']);
// export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState);

const supplierDispatcherReducerCrudLogic = standardReducerGenerator(
	CRUD_ACTION_CONSTANTS,
	initialState
);

export default (oldState = initialState, action): any => {
	Object.freeze(oldState);
	switch (action.type) {
		case 'DISPATCHERS_FETCH_SUCCESS':
			const newRecords = action.records.map((record, idx) => ({ ...record, key: idx + 1 }));

			const newRecordsIds = newRecords.map((_) => _.id);

			// add new records to base collection
			const newState = {
				...oldState,
				fetching: false,
				fetchErrors: [],
				count: action.count,
				records: newRecords,
				distinctRecords: { ...oldState.distinctRecords, ...newRecords },
			};

			// if update targeting child collection, replace records
			if (action.targetCollectionName) {
				newState[action.targetCollectionName] = {
					recordsIds: newRecordsIds,
					records: newRecords,
					count: action.count,
					fetching: false,
					fetchErrors: [],
				};
				// store pagination, sorting, and filters state of table if applicable
				if (action.pagination) {
					newState[action.targetCollectionName].pagination = action.pagination;
				}
				if (action.sorting) {
					newState[action.targetCollectionName].sorting = action.sorting;
				}
				if (action.filters) {
					newState[action.targetCollectionName].filters = action.filters;
				}
			} else {
				newState.recordsIds = newRecordsIds;
				if (action.pagination) {
					newState.pagination = action.pagination;
				}
				if (action.sorting) {
					newState.sorting = action.sorting;
				}
				if (action.filters) {
					newState.filters = action.filters;
				}
			}
			return newState;
		default:
			return supplierDispatcherReducerCrudLogic(oldState, action);
	}
};

import React, { FC, useEffect, useMemo, useState } from 'react';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import { connect } from 'react-redux';
import { Modal, Spin } from 'antd';
import AddPartsFormForSLWithLocation from './AddPartsFormForSLWithLocation';
import AddPartsSimpleForm from './AddPartsSimpleForm';

const AddPartsModal: FC<any> = ({
	getSLWithLocationCount,
	onCancel,
	partInServiceCall,
	...props
}): React.ReactElement => {
	const [countLoading, setCountLoading] = useState(false);
	const [slWithLocationAvailable, setSlWithLocationAvailable] = useState(false);

	const isUpdateQuantity = useMemo(() => !!partInServiceCall, [partInServiceCall]);

	useEffect(() => {
		setCountLoading(true);
		getSLWithLocationCount()
			.then((count) => setSlWithLocationAvailable(count > 0))
			.finally(() => setCountLoading(false));
	}, [getSLWithLocationCount]);

	return (
		<Modal
			visible={true}
			width={600}
			title={isUpdateQuantity ? 'Update quantity' : 'Add parts'}
			closable
			onCancel={onCancel}
			forceRender
			footer={null}
		>
			{countLoading ? (
				<div className="flex flex-row justify-center">
					<Spin />
				</div>
			) : slWithLocationAvailable ? (
				<AddPartsFormForSLWithLocation
					onCancel={onCancel}
					partInServiceCall={partInServiceCall}
					{...props}
				/>
			) : (
				<AddPartsSimpleForm onCancel={onCancel} partInServiceCall={partInServiceCall} {...props} />
			)}
		</Modal>
	);
};

const mapDispatchToProps = (dispatch) => ({
	getSLWithLocationCount: () =>
		dispatch(
			stockLocationsRestCrudThunksForSupplier.countBy(
				{
					isLocationAssociated: true,
				},
				'WITH_LOCATION'
			)
		),
});

export default connect(null, mapDispatchToProps)(AddPartsModal);

import { BUYER_SETTINGS_CRUD_ACTION_CREATORS } from '../actions/buyer_settings_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';

export const buyerSettingsRestCrudThunksForBuyer = new RestCrudThunks(
	'buyer_settings',
	'buyer_setting',
	'buyerSetting',
	'buyerSettings',
	BUYER_SETTINGS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false
);

import {
	CLEAR_WORK_CATEGORY_FILTERS,
	CLEAR_WORK_FACILITY_FILTERS,
	CLEAR_WORK_VENDOR_FILTERS,
	CLEAR_WORK_LOCATION_FILTERS,
	CLEAR_WORK_PRIORITY_FILTERS,
	CLEAR_WORK_MANUFACTURER_FILTERS,
	CLEAR_WORK_ASSET_TYPE_FILTERS,
	CLEAR_WORK_PROBLEM_TYPE_FILTERS,
	UPDATE_WORK_MANUFACTURER_FILTERS,
	UPDATE_WORK_ASSET_TYPE_FILTERS,
	UPDATE_WORK_PROBLEM_TYPE_FILTERS,
	UPDATE_WORK_LOCATION_FILTERS,
	UPDATE_WORK_PRIORITY_FILTERS,
	UPDATE_WORK_CATEGORY_FILTERS,
	UPDATE_WORK_FACILITY_FILTERS,
	UPDATE_WORK_VENDOR_FILTERS,
	FETCH_WORK_ORDER_COUNT_BY_MANUFACTURER_FAILURE,
	FETCH_WORK_ORDER_COUNT_BY_MANUFACTURER_START,
	FETCH_WORK_ORDER_COUNT_BY_MANUFACTURER_SUCCESS,
	FETCH_WORK_ORDER_COUNT_BY_ASSET_TYPE_FAILURE,
	FETCH_WORK_ORDER_COUNT_BY_ASSET_TYPE_START,
	FETCH_WORK_ORDER_COUNT_BY_ASSET_TYPE_SUCCESS,
	FETCH_WORK_ORDER_COUNT_BY_PROBLEM_TYPE_SUCCESS,
	FETCH_WORK_ORDER_COUNT_BY_PROBLEM_TYPE_FAILURE,
	FETCH_WORK_ORDER_COUNT_BY_PROBLEM_TYPE_START,
	FETCH_WORK_ORDER_COUNT_BY_CATEGORY_FAILURE,
	FETCH_WORK_ORDER_COUNT_BY_CATEGORY_START,
	FETCH_WORK_ORDER_COUNT_BY_CATEGORY_SUCCESS,
	FETCH_WORK_ORDER_COUNT_BY_LOCATION_FAILURE,
	FETCH_WORK_ORDER_COUNT_BY_LOCATION_START,
	FETCH_WORK_ORDER_COUNT_BY_LOCATION_SUCCESS,
	FETCH_WORK_ORDER_COUNT_BY_FACILITY_FAILURE,
	FETCH_WORK_ORDER_COUNT_BY_FACILITY_START,
	FETCH_WORK_ORDER_COUNT_BY_FACILITY_SUCCESS,
	FETCH_WORK_ORDER_COUNT_BY_MONTH_FAILURE,
	FETCH_WORK_ORDER_COUNT_BY_MONTH_START,
	FETCH_WORK_ORDER_COUNT_BY_MONTH_SUCCESS,
	FETCH_WORK_ORDER_COUNT_BY_VENDOR_FAILURE,
	FETCH_WORK_ORDER_COUNT_BY_VENDOR_START,
	FETCH_WORK_ORDER_COUNT_BY_VENDOR_SUCCESS,
	FETCH_WORK_ORDER_COUNT_BY_WEEK_START,
	FETCH_WORK_ORDER_COUNT_BY_DAY_START,
	FETCH_WORK_ORDER_COUNT_BY_WEEK_FAILURE,
	FETCH_WORK_ORDER_COUNT_BY_DAY_FAILURE,
	FETCH_WORK_ORDER_COUNT_BY_WEEK_SUCCESS,
	FETCH_WORK_ORDER_COUNT_BY_DAY_SUCCESS,
	FETCH_WORK_ORDER_COUNT_BY_REGION_START,
	FETCH_WORK_ORDER_COUNT_BY_REGION_FAILURE,
	FETCH_WORK_ORDER_COUNT_BY_REGION_SUCCESS,
	CLEAR_WORK_REGION_FILTERS,
	UPDATE_WORK_REGION_FILTERS,
} from '../actions/work_analytics_actions';
import { WorkAnalyticsStoreState } from '../types/models';

const initialState: WorkAnalyticsStoreState = {
	workOrderCountByMonth: { data: [], loading: false, errors: [] },
	workOrderCountByDay: { data: [], loading: false, errors: [] },
	workOrderCountByWeek: { data: [], loading: false, errors: [] },
	workOrderCountByCategory: { data: [], loading: false, errors: [] },
	workOrderCountByVendor: { data: [], loading: false, errors: [] },
	workOrderCountByFacility: { data: [], loading: false, errors: [] },
	workOrderCountByLocation: { data: [], loading: false, errors: [] },
	workOrderCountByRegion: { data: [], loading: false, errors: [] },
	workOrderCountByAssetType: { data: [], loading: false, errors: [] },
	workOrderCountByProblemType: { data: [], loading: false, errors: [] },
	workOrderCountByManufacturer: { data: [], loading: false, errors: [] },
	problemTypeFilters: {},
	assetTypeFilters: {},
	manufacturerFilters: {},
	categoryFilters: {},
	vendorFilters: {},
	facilityFilters: {},
	locationFilters: {},
	regionFilters: {},
	priorityFilters: {},
};

export default (oldState = initialState, action): WorkAnalyticsStoreState => {
	Object.freeze(oldState);
	switch (action.type) {
		default:
			return oldState;
		case FETCH_WORK_ORDER_COUNT_BY_MONTH_START:
			return {
				...oldState,
				workOrderCountByMonth: {
					...oldState.workOrderCountByMonth,
					loading: true,
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_WEEK_START:
			return {
				...oldState,
				workOrderCountByWeek: {
					...oldState.workOrderCountByWeek,
					loading: true,
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_DAY_START:
			return {
				...oldState,
				workOrderCountByDay: {
					...oldState.workOrderCountByDay,
					loading: true,
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_MANUFACTURER_START:
			return {
				...oldState,
				workOrderCountByManufacturer: {
					...oldState.workOrderCountByManufacturer,
					loading: true,
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_PROBLEM_TYPE_START:
			return {
				...oldState,
				workOrderCountByProblemType: {
					...oldState.workOrderCountByProblemType,
					loading: true,
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_ASSET_TYPE_START:
			return {
				...oldState,
				workOrderCountByAssetType: {
					...oldState.workOrderCountByAssetType,
					loading: true,
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_CATEGORY_START:
			return {
				...oldState,
				workOrderCountByCategory: {
					...oldState.workOrderCountByCategory,
					loading: true,
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_VENDOR_START:
			return {
				...oldState,
				workOrderCountByVendor: {
					...oldState.workOrderCountByVendor,
					loading: true,
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_LOCATION_START:
			return {
				...oldState,
				workOrderCountByLocation: {
					...oldState.workOrderCountByLocation,
					loading: true,
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_REGION_START:
			return {
				...oldState,
				workOrderCountByRegion: {
					...oldState.workOrderCountByRegion,
					loading: true,
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_FACILITY_START:
			return {
				...oldState,
				workOrderCountByFacility: {
					...oldState.workOrderCountByFacility,
					loading: true,
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_MONTH_FAILURE:
			return {
				...oldState,
				workOrderCountByMonth: {
					...oldState.workOrderCountByMonth,
					loading: false,
					errors: [...oldState.workOrderCountByMonth.errors, ...action.errors],
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_WEEK_FAILURE:
			return {
				...oldState,
				workOrderCountByWeek: {
					...oldState.workOrderCountByWeek,
					loading: false,
					errors: [...oldState.workOrderCountByWeek.errors, ...action.errors],
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_DAY_FAILURE:
			return {
				...oldState,
				workOrderCountByDay: {
					...oldState.workOrderCountByDay,
					loading: false,
					errors: [...oldState.workOrderCountByDay.errors, ...action.errors],
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_CATEGORY_FAILURE:
			return {
				...oldState,
				workOrderCountByCategory: {
					...oldState.workOrderCountByCategory,
					loading: false,
					errors: [...oldState.workOrderCountByCategory.errors, ...action.errors],
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_VENDOR_FAILURE:
			return {
				...oldState,
				workOrderCountByVendor: {
					...oldState.workOrderCountByVendor,
					loading: false,
					errors: [...oldState.workOrderCountByVendor.errors, ...action.errors],
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_LOCATION_FAILURE:
			return {
				...oldState,
				workOrderCountByLocation: {
					...oldState.workOrderCountByLocation,
					loading: false,
					errors: [...oldState.workOrderCountByLocation.errors, ...action.errors],
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_REGION_FAILURE:
			return {
				...oldState,
				workOrderCountByRegion: {
					...oldState.workOrderCountByRegion,
					loading: false,
					errors: [...oldState.workOrderCountByRegion.errors, ...action.errors],
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_FACILITY_FAILURE:
			return {
				...oldState,
				workOrderCountByFacility: {
					...oldState.workOrderCountByFacility,
					loading: false,
					errors: [...oldState.workOrderCountByFacility.errors, ...action.errors],
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_ASSET_TYPE_FAILURE:
			return {
				...oldState,
				workOrderCountByAssetType: {
					...oldState.workOrderCountByAssetType,
					loading: false,
					errors: [...oldState.workOrderCountByAssetType.errors, ...action.errors],
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_MANUFACTURER_FAILURE:
			return {
				...oldState,
				workOrderCountByManufacturer: {
					...oldState.workOrderCountByManufacturer,
					loading: false,
					errors: [...oldState.workOrderCountByManufacturer.errors, ...action.errors],
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_PROBLEM_TYPE_FAILURE:
			return {
				...oldState,
				workOrderCountByProblemType: {
					...oldState.workOrderCountByProblemType,
					loading: false,
					errors: [...oldState.workOrderCountByProblemType.errors, ...action.errors],
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_MONTH_SUCCESS:
			return {
				...oldState,
				workOrderCountByMonth: {
					...oldState.workOrderCountByMonth,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_WEEK_SUCCESS:
			return {
				...oldState,
				workOrderCountByWeek: {
					...oldState.workOrderCountByWeek,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_DAY_SUCCESS:
			return {
				...oldState,
				workOrderCountByDay: {
					...oldState.workOrderCountByDay,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_CATEGORY_SUCCESS:
			return {
				...oldState,
				workOrderCountByCategory: {
					...oldState.workOrderCountByCategory,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_VENDOR_SUCCESS:
			return {
				...oldState,
				workOrderCountByVendor: {
					...oldState.workOrderCountByVendor,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_LOCATION_SUCCESS:
			return {
				...oldState,
				workOrderCountByLocation: {
					...oldState.workOrderCountByLocation,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_REGION_SUCCESS:
			return {
				...oldState,
				workOrderCountByRegion: {
					...oldState.workOrderCountByRegion,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_FACILITY_SUCCESS:
			return {
				...oldState,
				workOrderCountByFacility: {
					...oldState.workOrderCountByFacility,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_PROBLEM_TYPE_SUCCESS:
			return {
				...oldState,
				workOrderCountByProblemType: {
					...oldState.workOrderCountByProblemType,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_ASSET_TYPE_SUCCESS:
			return {
				...oldState,
				workOrderCountByAssetType: {
					...oldState.workOrderCountByAssetType,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_WORK_ORDER_COUNT_BY_MANUFACTURER_SUCCESS:
			return {
				...oldState,
				workOrderCountByManufacturer: {
					...oldState.workOrderCountByManufacturer,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case UPDATE_WORK_MANUFACTURER_FILTERS:
			return {
				...oldState,
				manufacturerFilters: {
					...oldState.manufacturerFilters,
					...action.filters,
				},
			};
		case CLEAR_WORK_MANUFACTURER_FILTERS:
			return {
				...oldState,
				manufacturerFilters: {},
			};
		case UPDATE_WORK_ASSET_TYPE_FILTERS:
			return {
				...oldState,
				assetTypeFilters: {
					...oldState.assetTypeFilters,
					...action.filters,
				},
			};
		case CLEAR_WORK_ASSET_TYPE_FILTERS:
			return {
				...oldState,
				assetTypeFilters: {},
			};
		case UPDATE_WORK_PROBLEM_TYPE_FILTERS:
			return {
				...oldState,
				problemTypeFilters: {
					...oldState.problemTypeFilters,
					...action.filters,
				},
			};
		case CLEAR_WORK_PROBLEM_TYPE_FILTERS:
			return {
				...oldState,
				problemTypeFilters: {},
			};
		case CLEAR_WORK_CATEGORY_FILTERS:
			return {
				...oldState,
				categoryFilters: {},
			};
		case UPDATE_WORK_CATEGORY_FILTERS:
			return {
				...oldState,
				categoryFilters: {
					...oldState.categoryFilters,
					...action.filters,
				},
			};
		case CLEAR_WORK_LOCATION_FILTERS:
			return {
				...oldState,
				locationFilters: {},
			};
		case UPDATE_WORK_LOCATION_FILTERS:
			return {
				...oldState,
				locationFilters: {
					...oldState.locationFilters,
					...action.filters,
				},
			};
		case CLEAR_WORK_REGION_FILTERS:
			return {
				...oldState,
				regionFilters: {},
			};
		case UPDATE_WORK_REGION_FILTERS:
			return {
				...oldState,
				regionFilters: {
					...oldState.regionFilters,
					...action.filters,
				},
			};
		case CLEAR_WORK_FACILITY_FILTERS:
			return {
				...oldState,
				facilityFilters: {},
			};
		case UPDATE_WORK_FACILITY_FILTERS:
			return {
				...oldState,
				facilityFilters: {
					...oldState.facilityFilters,
					...action.filters,
				},
			};
		case CLEAR_WORK_PRIORITY_FILTERS:
			return {
				...oldState,
				priorityFilters: {},
			};
		case UPDATE_WORK_PRIORITY_FILTERS:
			return {
				...oldState,
				priorityFilters: {
					...oldState.priorityFilters,
					...action.filters,
				},
			};
		case CLEAR_WORK_VENDOR_FILTERS:
			return {
				...oldState,
				vendorFilters: {},
			};
		case UPDATE_WORK_VENDOR_FILTERS:
			return {
				...oldState,
				vendorFilters: {
					...oldState.vendorFilters,
					...action.filters,
				},
			};
	}
};

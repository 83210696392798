import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout } from 'antd';
import PlannedMaintenanceScheduleForm from '../planned_maintenance_schedule_form/PlannedMaintenanceScheduleForm';
import { NewPage } from '../new_page/NewPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;

class NewPlannedMaintenanceSchedulePage extends React.Component<any, any> {
	render() {
		const { history, userType } = this.props;
		return [
			<LogOnMountWithStandardEventProperties
				eventType={`visited ${userType} new pm schedule page`}
			/>,
			<NewPage
				title="New Planned Maintenance Schedule"
				formComponentProps={{
					formData: {},
					onSuccess: (rec) => {
						history.push(
							`/${userType}/plannedMaintenance/detail/${encodeURIComponent(rec.title)}/${
								rec.frequency
							}/${rec.plannedMaintenanceTemplateId}/${rec.basedOn ? rec.basedOn : 'location'}`
						);
					},
				}}
				fetching={false}
				FormComponent={PlannedMaintenanceScheduleForm}
			/>,
		];
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
});
const mapDispatchToProps = (dispatch) => ({});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(NewPlannedMaintenanceSchedulePage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

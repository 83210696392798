import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { COMPANY_CONFIG_CRUD_ACTION_CREATORS } from '../actions/company_config_actions';
import { nullSafeGet } from '../utils/DataAccessUtils';

export const companyConfigRestCrudThunksForBuyer = new RestCrudThunks(
	'buyer_company_settings',
	'buyer_company_setting',
	'buyerCompanySetting',
	'buyerCompanySettings',
	COMPANY_CONFIG_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false
);

export const companyConfigRestCrudThunksForSupplier = new RestCrudThunks(
	'supplier_company_settings',
	'supplier_company_setting',
	'supplierCompanySetting',
	'supplierCompanySettings',
	COMPANY_CONFIG_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false
);

export const companyConfigRestCrudThunks = (roleType, contact) => {
	const companyId =
		roleType === 'buyer'
			? nullSafeGet('facility.buyerCompanyId', contact)
			: nullSafeGet('facility.supplierCompanyId', contact);
	return companyId
		? roleType === 'buyer'
			? companyConfigRestCrudThunksForBuyer.readOne(companyId)
			: companyConfigRestCrudThunksForSupplier.readOne(companyId)
		: (dispatch) => new Promise((resolve) => resolve(false));
};

import { PROJECTS_CRUD_ACTION_CREATORS } from '../actions/projects_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';

export const projectsRestCrudThunksForBuyer = new RestCrudThunks(
	'projects',
	'project',
	'project',
	'projects',
	PROJECTS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'project/'
);

export const projectsRestCrudThunksForSupplier = new RestCrudThunks(
	'projects',
	'project',
	'project',
	'projects',
	PROJECTS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'project/'
);

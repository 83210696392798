import { Button, Form, Input, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { impersonationActivate } from '../../thunks/session_thunks';
import { getHomePageByRole } from '../../utils/EnvConfigUtils';

export class Impersonation extends React.Component {
	render() {
		const { impersonationActivate, userType } = this.props;
		return (
			<Row justify="center" align="center" style={{ marginTop: 64 }}>
				<div>
					<Form
						name="basic"
						onFinish={(values) => {
							impersonationActivate(userType, values.email)
								.then(() => {
									window.location.assign(getHomePageByRole(userType, {}));
								})
								.catch(alert);
						}}
						autoComplete="off"
						layout="vertical"
					>
						<Form.Item
							label="Enter an email you want to view/impersonate the product as"
							help="You can only view the product in a read only mode."
							name="email"
							rules={[{ required: true, message: 'Please enter an email!' }]}
						>
							<Input />
						</Form.Item>

						<Form.Item>
							<Button type="primary" htmlType="submit" style={{ marginTop: 32 }}>
								Submit
							</Button>
						</Form.Item>
					</Form>
				</div>
			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	userType: state.session.userType,
});

const mapDispatchToProps = (dispatch) => ({
	impersonationActivate: (userType, email) => dispatch(impersonationActivate(userType, email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Impersonation);

import React, { FC, useMemo } from 'react';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { JOURNAL_DISPLAY_PROPS } from '../../utils/DataConstants';

const JournalStatusDisplay: FC<any> = ({ status }) => {
	const props = useMemo(() => nullSafeGetOrElse(status, JOURNAL_DISPLAY_PROPS, {}), [status]);

	return (
		<div className="flex flex-row items-center">
			{props.icon ? (
				<i
					style={{
						...props.iconStyle,
						marginRight: '8px',
					}}
					className={`icons8-font ${props.icon}`}
				/>
			) : null}
			<div>{props.displayName}</div>
		</div>
	);
};

export default JournalStatusDisplay;

import {
	CLEAR_TECH_KPI_FILTERS,
	FETCH_ALL_MONTHLY_TECH_KPIS_FAILURE,
	FETCH_ALL_MONTHLY_TECH_KPIS_START,
	FETCH_ALL_MONTHLY_TECH_KPIS_SUCCESS,
	FETCH_ASSET_DOWNTIME_TECH_KPI_TOTAL_FAILURE,
	FETCH_ASSET_DOWNTIME_TECH_KPI_TOTAL_START,
	FETCH_ASSET_DOWNTIME_TECH_KPI_TOTAL_SUCCESS,
	FETCH_CHECK_IN_RATE_TECH_KPI_TOTAL_FAILURE,
	FETCH_CHECK_IN_RATE_TECH_KPI_TOTAL_START,
	FETCH_CHECK_IN_RATE_TECH_KPI_TOTAL_SUCCESS,
	FETCH_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE,
	FETCH_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_START,
	FETCH_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS,
	FETCH_CORRECTIVE_MAINTENANCE_RATE_TECH_KPI_TOTAL_FAILURE,
	FETCH_CORRECTIVE_MAINTENANCE_RATE_TECH_KPI_TOTAL_START,
	FETCH_FIRST_TIME_RESOLUTION_TECH_KPI_TOTAL_FAILURE,
	FETCH_FIRST_TIME_RESOLUTION_TECH_KPI_TOTAL_START,
	FETCH_FIRST_TIME_RESOLUTION_TECH_KPI_TOTAL_SUCCESS,
	FETCH_NEW_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE,
	FETCH_NEW_WORK_ORDERS_TECH_KPI_TOTAL_START,
	FETCH_NEW_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS,
	FETCH_PLANNED_MAINTENANCE_RATE_TECH_KPI_TOTAL_FAILURE,
	FETCH_PLANNED_MAINTENANCE_RATE_TECH_KPI_TOTAL_START,
	FETCH_PLANNED_MAINTENANCE_RATE_TECH_KPI_TOTAL_SUCCESS,
	FETCH_RESPONSE_TIME_TECH_KPI_TOTAL_FAILURE,
	FETCH_RESPONSE_TIME_TECH_KPI_TOTAL_START,
	FETCH_RESPONSE_TIME_TECH_KPI_TOTAL_SUCCESS,
	UPDATE_TECH_KPI_FILTERS,
	FETCH_CORRECTIVE_MAINTENANCE_RATE_TECH_KPI_TOTAL_SUCCESS,
	FETCH_STALE_WORK_ORDERS_TECH_KPI_TOTAL_START,
	FETCH_STALE_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE,
	FETCH_STALE_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS,
	FETCH_COMPLETION_TIME_TECH_KPI_TOTAL_START,
	FETCH_COMPLETION_TIME_TECH_KPI_TOTAL_FAILURE,
	FETCH_COMPLETION_TIME_TECH_KPI_TOTAL_SUCCESS,
	FETCH_AVERAGE_SATISFACTION_RATING_TECH_KPI_TOTAL_START,
	FETCH_AVERAGE_SATISFACTION_RATING_TECH_KPI_TOTAL_FAILURE,
	FETCH_AVERAGE_SATISFACTION_RATING_TECH_KPI_TOTAL_SUCCESS,
	FETCH_PM_WORK_ORDERS_TECH_KPI_TOTAL_START,
	FETCH_PM_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS,
	FETCH_PM_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE,
	FETCH_NEW_CREATED_BY_TECH_WORK_ORDERS_TECH_KPI_TOTAL_START,
	FETCH_NEW_CREATED_BY_TECH_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE,
	FETCH_NEW_CREATED_BY_TECH_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS,
	FETCH_PM_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_START,
	FETCH_PM_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE,
	FETCH_PM_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS,
} from '../actions/tech_kpi_analytics_actions';
import { TechKPIAnalyticsStoreState } from '../types/models';

const initialState: TechKPIAnalyticsStoreState = {
	newWorkOrdersTotal: { data: [], loading: false, errors: [] },
	newTechCreatedWorkOrdersTotal: { data: [], loading: false, errors: [] },
	pmWorkOrdersTotal: { data: [], loading: false, errors: [] },
	completedWorkOrdersTotal: { data: [], loading: false, errors: [] },
	pmCompletedWorkOrdersTotal: { data: [], loading: false, errors: [] },
	staleWorkOrdersTotal: { data: [], loading: false, errors: [] },
	plannedMaintenanceRateTotal: { data: [], loading: false, errors: [] },
	correctiveMaintenanceRateTotal: { data: [], loading: false, errors: [] },
	checkInRateTotal: { data: [], loading: false, errors: [] },
	firstTimeRateTotal: { data: [], loading: false, errors: [] },
	assetDownTimeTotal: { data: [], loading: false, errors: [] },
	responseTimeTotal: { data: [], loading: false, errors: [] },
	completionTimeTotal: { data: [], loading: false, errors: [] },
	avgSatisfactionRatingTotal: { data: [], loading: false, errors: [] },
	techKPIsByTech: { loading: false, errors: [] },
	techKpiFilters: {},
};

export default (oldState = initialState, action): TechKPIAnalyticsStoreState => {
	Object.freeze(oldState);
	switch (action.type) {
		case FETCH_ALL_MONTHLY_TECH_KPIS_START:
			return {
				...oldState,
				techKPIsByTech: {
					...oldState.techKPIsByTech,
					loading: true,
				},
			};
		case FETCH_ALL_MONTHLY_TECH_KPIS_FAILURE:
			return {
				...oldState,
				techKPIsByTech: {
					...oldState.techKPIsByTech,
					loading: false,
					errors: [...oldState.techKPIsByTech.errors, ...action.errors],
				},
			};
		case FETCH_ALL_MONTHLY_TECH_KPIS_SUCCESS:
			return {
				...oldState,
				techKPIsByTech: {
					...oldState.techKPIsByTech,
					...action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_NEW_WORK_ORDERS_TECH_KPI_TOTAL_START:
			return {
				...oldState,
				newWorkOrdersTotal: {
					...oldState.newWorkOrdersTotal,
					loading: true,
				},
			};
		case FETCH_NEW_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE:
			return {
				...oldState,
				newWorkOrdersTotal: {
					...oldState.newWorkOrdersTotal,
					loading: false,
					errors: [...oldState.newWorkOrdersTotal.errors, ...action.errors],
				},
			};
		case FETCH_NEW_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS:
			return {
				...oldState,
				newWorkOrdersTotal: {
					...oldState.newWorkOrdersTotal,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_NEW_CREATED_BY_TECH_WORK_ORDERS_TECH_KPI_TOTAL_START:
			return {
				...oldState,
				newTechCreatedWorkOrdersTotal: {
					...oldState.newTechCreatedWorkOrdersTotal,
					loading: true,
				},
			};
		case FETCH_NEW_CREATED_BY_TECH_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE:
			return {
				...oldState,
				newTechCreatedWorkOrdersTotal: {
					...oldState.newTechCreatedWorkOrdersTotal,
					loading: false,
					errors: [...oldState.newTechCreatedWorkOrdersTotal.errors, ...action.errors],
				},
			};
		case FETCH_NEW_CREATED_BY_TECH_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS:
			return {
				...oldState,
				newTechCreatedWorkOrdersTotal: {
					...oldState.newTechCreatedWorkOrdersTotal,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_PM_WORK_ORDERS_TECH_KPI_TOTAL_START:
			return {
				...oldState,
				pmWorkOrdersTotal: {
					...oldState.pmWorkOrdersTotal,
					loading: true,
				},
			};
		case FETCH_PM_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE:
			return {
				...oldState,
				pmWorkOrdersTotal: {
					...oldState.pmWorkOrdersTotal,
					loading: false,
					errors: [...oldState.pmWorkOrdersTotal.errors, ...action.errors],
				},
			};
		case FETCH_PM_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS:
			return {
				...oldState,
				pmWorkOrdersTotal: {
					...oldState.pmWorkOrdersTotal,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_START:
			return {
				...oldState,
				completedWorkOrdersTotal: {
					...oldState.completedWorkOrdersTotal,
					loading: true,
				},
			};
		case FETCH_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE:
			return {
				...oldState,
				completedWorkOrdersTotal: {
					...oldState.completedWorkOrdersTotal,
					loading: false,
					errors: [...oldState.completedWorkOrdersTotal.errors, ...action.errors],
				},
			};
		case FETCH_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS:
			return {
				...oldState,
				completedWorkOrdersTotal: {
					...oldState.completedWorkOrdersTotal,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_PM_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_START:
			return {
				...oldState,
				pmCompletedWorkOrdersTotal: {
					...oldState.pmCompletedWorkOrdersTotal,
					loading: true,
				},
			};
		case FETCH_PM_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE:
			return {
				...oldState,
				pmCompletedWorkOrdersTotal: {
					...oldState.pmCompletedWorkOrdersTotal,
					loading: false,
					errors: [...oldState.pmCompletedWorkOrdersTotal.errors, ...action.errors],
				},
			};
		case FETCH_PM_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS:
			return {
				...oldState,
				pmCompletedWorkOrdersTotal: {
					...oldState.pmCompletedWorkOrdersTotal,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_STALE_WORK_ORDERS_TECH_KPI_TOTAL_START:
			return {
				...oldState,
				staleWorkOrdersTotal: {
					...oldState.staleWorkOrdersTotal,
					loading: true,
				},
			};
		case FETCH_STALE_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE:
			return {
				...oldState,
				staleWorkOrdersTotal: {
					...oldState.staleWorkOrdersTotal,
					loading: false,
					errors: [...oldState.staleWorkOrdersTotal.errors, ...action.errors],
				},
			};
		case FETCH_STALE_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS:
			return {
				...oldState,
				staleWorkOrdersTotal: {
					...oldState.staleWorkOrdersTotal,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_PLANNED_MAINTENANCE_RATE_TECH_KPI_TOTAL_START:
			return {
				...oldState,
				plannedMaintenanceRateTotal: {
					...oldState.plannedMaintenanceRateTotal,
					loading: true,
				},
			};
		case FETCH_PLANNED_MAINTENANCE_RATE_TECH_KPI_TOTAL_FAILURE:
			return {
				...oldState,
				plannedMaintenanceRateTotal: {
					...oldState.plannedMaintenanceRateTotal,
					loading: false,
					errors: [...oldState.plannedMaintenanceRateTotal.errors, ...action.errors],
				},
			};
		case FETCH_PLANNED_MAINTENANCE_RATE_TECH_KPI_TOTAL_SUCCESS:
			return {
				...oldState,
				plannedMaintenanceRateTotal: {
					...oldState.plannedMaintenanceRateTotal,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_CORRECTIVE_MAINTENANCE_RATE_TECH_KPI_TOTAL_START:
			return {
				...oldState,
				correctiveMaintenanceRateTotal: {
					...oldState.correctiveMaintenanceRateTotal,
					loading: true,
				},
			};
		case FETCH_CORRECTIVE_MAINTENANCE_RATE_TECH_KPI_TOTAL_FAILURE:
			return {
				...oldState,
				correctiveMaintenanceRateTotal: {
					...oldState.correctiveMaintenanceRateTotal,
					loading: false,
					errors: [...oldState.correctiveMaintenanceRateTotal.errors, ...action.errors],
				},
			};
		case FETCH_CORRECTIVE_MAINTENANCE_RATE_TECH_KPI_TOTAL_SUCCESS:
			return {
				...oldState,
				correctiveMaintenanceRateTotal: {
					...oldState.correctiveMaintenanceRateTotal,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_CHECK_IN_RATE_TECH_KPI_TOTAL_START:
			return {
				...oldState,
				checkInRateTotal: {
					...oldState.checkInRateTotal,
					loading: true,
				},
			};
		case FETCH_CHECK_IN_RATE_TECH_KPI_TOTAL_FAILURE:
			return {
				...oldState,
				checkInRateTotal: {
					...oldState.checkInRateTotal,
					loading: false,
					errors: [...oldState.checkInRateTotal.errors, ...action.errors],
				},
			};
		case FETCH_CHECK_IN_RATE_TECH_KPI_TOTAL_SUCCESS:
			return {
				...oldState,
				checkInRateTotal: {
					...oldState.checkInRateTotal,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_FIRST_TIME_RESOLUTION_TECH_KPI_TOTAL_START:
			return {
				...oldState,
				firstTimeRateTotal: {
					...oldState.firstTimeRateTotal,
					loading: true,
				},
			};
		case FETCH_FIRST_TIME_RESOLUTION_TECH_KPI_TOTAL_FAILURE:
			return {
				...oldState,
				firstTimeRateTotal: {
					...oldState.firstTimeRateTotal,
					loading: false,
					errors: [...oldState.firstTimeRateTotal.errors, ...action.errors],
				},
			};
		case FETCH_FIRST_TIME_RESOLUTION_TECH_KPI_TOTAL_SUCCESS:
			return {
				...oldState,
				firstTimeRateTotal: {
					...oldState.firstTimeRateTotal,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_ASSET_DOWNTIME_TECH_KPI_TOTAL_START:
			return {
				...oldState,
				assetDownTimeTotal: {
					...oldState.assetDownTimeTotal,
					loading: true,
				},
			};
		case FETCH_ASSET_DOWNTIME_TECH_KPI_TOTAL_FAILURE:
			return {
				...oldState,
				assetDownTimeTotal: {
					...oldState.assetDownTimeTotal,
					loading: false,
					errors: [...oldState.assetDownTimeTotal.errors, ...action.errors],
				},
			};
		case FETCH_ASSET_DOWNTIME_TECH_KPI_TOTAL_SUCCESS:
			return {
				...oldState,
				assetDownTimeTotal: {
					...oldState.assetDownTimeTotal,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_RESPONSE_TIME_TECH_KPI_TOTAL_START:
			return {
				...oldState,
				responseTimeTotal: {
					...oldState.responseTimeTotal,
					loading: true,
				},
			};
		case FETCH_RESPONSE_TIME_TECH_KPI_TOTAL_FAILURE:
			return {
				...oldState,
				responseTimeTotal: {
					...oldState.responseTimeTotal,
					loading: false,
					errors: [...oldState.responseTimeTotal.errors, ...action.errors],
				},
			};
		case FETCH_RESPONSE_TIME_TECH_KPI_TOTAL_SUCCESS:
			return {
				...oldState,
				responseTimeTotal: {
					...oldState.responseTimeTotal,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_COMPLETION_TIME_TECH_KPI_TOTAL_START:
			return {
				...oldState,
				completionTimeTotal: {
					...oldState.completionTimeTotal,
					loading: true,
				},
			};
		case FETCH_COMPLETION_TIME_TECH_KPI_TOTAL_FAILURE:
			return {
				...oldState,
				completionTimeTotal: {
					...oldState.completionTimeTotal,
					loading: false,
					errors: [...oldState.completionTimeTotal.errors, ...action.errors],
				},
			};
		case FETCH_COMPLETION_TIME_TECH_KPI_TOTAL_SUCCESS:
			return {
				...oldState,
				completionTimeTotal: {
					...oldState.completionTimeTotal,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_AVERAGE_SATISFACTION_RATING_TECH_KPI_TOTAL_START:
			return {
				...oldState,
				avgSatisfactionRatingTotal: {
					...oldState.avgSatisfactionRatingTotal,
					loading: true,
				},
			};
		case FETCH_AVERAGE_SATISFACTION_RATING_TECH_KPI_TOTAL_FAILURE:
			return {
				...oldState,
				avgSatisfactionRatingTotal: {
					...oldState.avgSatisfactionRatingTotal,
					loading: false,
					errors: [...oldState.avgSatisfactionRatingTotal.errors, ...action.errors],
				},
			};
		case FETCH_AVERAGE_SATISFACTION_RATING_TECH_KPI_TOTAL_SUCCESS:
			return {
				...oldState,
				avgSatisfactionRatingTotal: {
					...oldState.avgSatisfactionRatingTotal,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case UPDATE_TECH_KPI_FILTERS:
			return {
				...oldState,
				techKpiFilters: {
					...oldState.techKpiFilters,
					...action.filters,
				},
			};
		case CLEAR_TECH_KPI_FILTERS:
			return {
				...oldState,
				techKpiFilters: {},
			};
		default:
			return oldState;
	}
};

import {
	DISABLE_BUYER_USER_FAILURE,
	DISABLE_BUYER_USER_START,
	DISABLE_BUYER_USER_SUCCESS,
	FETCH_BUYER_USERS,
	FETCH_BUYER_USERS_FAILURE,
	FETCH_BUYER_USERS_SUCCESS,
	REINVITE_BUYER_USER_FAILURE,
	REINVITE_BUYER_USER_START,
	REINVITE_BUYER_USER_SUCCESS,
} from '../actions/buyer_users_actions';
import { BuyerUsersStoreState } from '../types/models';

const initialState: BuyerUsersStoreState = {
	records: {},
	detail: {},
	errors: [],
	loading: false,
	loadingUsers: new Set([]),
};

export default (oldState = initialState, action): BuyerUsersStoreState => {
	Object.freeze(oldState);
	let newRecords;
	let newLoadingUsers;
	switch (action.type) {
		case FETCH_BUYER_USERS:
			return Object.assign({}, oldState, {
				errors: [],
				loading: true,
			});
		case FETCH_BUYER_USERS_SUCCESS:
			newRecords = action.records.reduce((acc, el) => {
				acc[el.account.userName] = el;
				return acc;
			}, {});
			return Object.assign({}, oldState, {
				records: newRecords,
				loading: false,
				errors: [],
			});
		case FETCH_BUYER_USERS_FAILURE:
			return Object.assign({}, oldState, {
				loading: false,
				errors: [...oldState.errors, ...action.errors],
			});
		case DISABLE_BUYER_USER_START:
			newLoadingUsers = new Set(oldState.loadingUsers);
			newLoadingUsers.add(action.userName);
			return Object.assign({}, oldState, {
				errors: [],
				loadingUsers: newLoadingUsers,
			});
		case DISABLE_BUYER_USER_SUCCESS:
			newLoadingUsers = new Set(oldState.loadingUsers);
			newLoadingUsers.delete(action.userName);
			return Object.assign({}, oldState, {
				loadingUsers: newLoadingUsers,
				errors: [],
			});
		case DISABLE_BUYER_USER_FAILURE:
			newLoadingUsers = new Set(oldState.loadingUsers);
			newLoadingUsers.delete(action.userName);
			return Object.assign({}, oldState, {
				loadingUsers: newLoadingUsers,
				errors: [...oldState.errors, ...action.errors],
			});
		case REINVITE_BUYER_USER_START:
			newLoadingUsers = new Set(oldState.loadingUsers);
			newLoadingUsers.add(action.userName);
			return Object.assign({}, oldState, {
				errors: [],
				loadingUsers: newLoadingUsers,
			});
		case REINVITE_BUYER_USER_SUCCESS:
			newLoadingUsers = new Set(oldState.loadingUsers);
			newLoadingUsers.delete(action.userName);
			return Object.assign({}, oldState, {
				loadingUsers: newLoadingUsers,
				errors: [],
			});
		case REINVITE_BUYER_USER_FAILURE:
			newLoadingUsers = new Set(oldState.loadingUsers);
			newLoadingUsers.delete(action.userName);
			return Object.assign({}, oldState, {
				loadingUsers: newLoadingUsers,
				errors: [...oldState.errors, ...action.errors],
			});
		default:
			return oldState;
	}
};

import {
	SAVE_SOURCING_FORM_DATA,
	SOURCING_FORM_SUBMITTING,
	SOURCING_FORM_DONE_SUBMITTING,
} from '../actions/sourcing_actions';

const initialState: any = {
	sourcingEventFormSubmitting: false,
	sourcingEventFormData: {},
	sourcingEventFormErrors: [],
};

export default (oldState = initialState, action): any => {
	Object.freeze(oldState);
	switch (action.type) {
		case SAVE_SOURCING_FORM_DATA:
			return Object.assign({}, oldState, {
				sourcingEventFormData: {
					...oldState.sourcingEventFormData,
					...action.formData,
				},
			});
		case SOURCING_FORM_SUBMITTING:
			return Object.assign({}, oldState, { sourcingEventFormSubmitting: true });
		case SOURCING_FORM_DONE_SUBMITTING:
			return Object.assign({}, oldState, { sourcingEventFormSubmitting: false });
		default:
			return oldState;
	}
};

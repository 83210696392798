import { Form, Input } from 'antd';
import React, { useState } from 'react';
import { isValidPhoneNumber } from '../../utils/DataFormatterUtils';
const AsYouTypeFormatter = require('google-libphonenumber').AsYouTypeFormatter;

export default function PhoneNumberComponentAntdV4({ form, fieldProps, ...props }) {
	const formProps = { ...fieldProps };
	const inputProps = { ...props };
	const [value, setValue] = useState(props.value);

	const phoneValidator = (rule, value, callback) => {
		!value || value.length === 0
			? callback()
			: isValidPhoneNumber(value)
			? callback()
			: callback('Not a valid phone number.');
	};

	const handlePhoneNumberEntry = (e) => {
		const formatter = new AsYouTypeFormatter('US');
		const phoneString = e.target.value.replace(/[^0-9+]/g, '').split('');
		phoneString.map((c) => formatter.inputDigit(c));
		setValue(formatter.currentOutput_.toString());
		//form.setFieldsValue({ [fieldName]: formatter.currentOutput_.toString() });
		//form.validateFields([fieldName]);
	};

	return (
		<Form.Item
			preserve={false}
			{...formProps}
			rules={[...(fieldProps.rules || []), { validator: phoneValidator }]}
		>
			<Input {...inputProps} onChange={handlePhoneNumberEntry} value={value} />
		</Form.Item>
	);
}

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col, Card, Tooltip } from 'antd';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { isTextElementEmpty, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import Mapbox from '../mapbox/Mapbox';
import { OperatingHoursCardDisplay } from '../operating_hours_card_display/OperatingHoursCardDisplay';
import { WorkOrderRowDisplay } from '../work_order_row_display/WorkOrderRowDisplay';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { EmptyState } from '../empty_state/EmptyState';
import { workOrdersRestCrudThunksForSupplier } from '../../thunks/work_orders_thunks';
import { connect } from 'react-redux';
import { assetsRestCrudThunksForSupplier } from '../../thunks/assets_thunks';
import OWEditableNotesComponent from '../common/OWEditableNotesComponent';
import FileUploader from '../file_uploader/FileUploader';
import { locationsRestCrudThunksForSupplier } from '../../thunks/locations_thunks';
import { locationDetailsRestCrudThunksForSupplier } from '../../thunks/location_details_thunks';
import { AssetRowDisplay } from '../asset_row_display/AssetRowDisplay';
import { PERMISSION_NAMES, isSupplierAllowedToAccess } from '../../utils/AuthUtils';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import AssetCardDisplay from '../asset_card_display/AssetCardDisplay';
import AreasDisplay from '../locations_detail_details_page/AreasDisplay';
import LocationContactInformation from '../locations_detail_details_page/LocationContactInformation';
import LocationDetailsCard from '../locations_detail_details_page/LocationDetailsCard';
import posthog from 'posthog-js';

require('./SupplierLocationPageDisplay.less');

const LOCATION_ASSOCIATED_ACTIVE_SUPPLIER_WORK_ORDERS_TARGET_COLLECTION =
	'locationAssociatedActiveSupplierWorkOrders';
const LOCATION_ASSOCIATED_INACTIVE_SUPPLIER_WORK_ORDERS_TARGET_COLLECTION =
	'locationAssociatedInActiveSupplierWorkOrders';
const LOCATION_ASSOCIATED_ASSETS_TARGET_COLLECTION = 'locationAssociatedAssets';

const SupplierLocationDetailPage: FC<any> = ({
	record,
	history,
	getLocation,
	getLocationDetails,
	updateLocationDetails,
	locationsFetching,
	currentUser,
	updateLocation,
	companyConfig,
}): React.ReactElement => {
	const [notesLoading, setNotesLoading] = useState(false);
	const [locationDetails, setLocationDetails] = useState(null);

	const isInternalTech = useMemo(
		() => !!nullSafeGet('facility.supplierCompany.privateBuyerCompanyId', currentUser),
		[currentUser]
	);

	const canModifyLocation = useMemo(
		() =>
			isSupplierAllowedToAccess(
				PERMISSION_NAMES.MODIFY_LOCATIONS,
				companyConfig,
				nullSafeGet('roles', currentUser)
			),
		[companyConfig, currentUser]
	);

	useEffect(() => {
		setNotesLoading(true);
		record.id &&
			getLocationDetails(record.id)
				.then((res) => res && setLocationDetails(res))
				.finally(() => setNotesLoading(false));
	}, [getLocationDetails, record.id]);

	const refreshLocation = useCallback(
		() => record.id && getLocation(record.id),
		[getLocation, record.id]
	);

	const onLocationDetailsSuccess = useCallback((res) => setLocationDetails(res), []);

	const handleFileAttachmentUploadChange = useCallback(
		(attachments) => {
			const payload = {
				...(locationDetails || {}),
				locationId: record.id,
				attachments,
				notes: nullSafeGetOrElse('notes', locationDetails, ''),
			};
			updateLocationDetails(payload).then(() => refreshLocation());
		},
		[locationDetails, record.id, refreshLocation, updateLocationDetails]
	);

	const handleInternalFileAttachmentUploadChange = useCallback(
		(internalAttachments) => {
			const updatedLocation = {
				...record,
				internalAttachments: internalAttachments,
			};
			updateLocation(updatedLocation);
		},
		[record, updateLocation]
	);

	const handleSaveNotes = useCallback(
		(notes) => {
			const payload = {
				...(locationDetails || {}),
				locationId: record.id,
				notes,
			};
			return updateLocationDetails(payload);
		},
		[locationDetails, record.id, updateLocationDetails]
	);

	const locationId = useMemo(() => record.id, [record.id]);

	const workOrderColumns = useMemo(
		() => [
			{
				title: 'Name',
				dataIndex: 'title',
				render: (_, record) => <WorkOrderRowDisplay workOrder={record} />,
			},
		],
		[]
	);

	const assetsColumns = useMemo(
		() => [
			{
				title: 'Name',
				dataIndex: 'name',
				render: (_, record) => <AssetRowDisplay asset={record} />,
			},
		],
		[]
	);

	const onWorkOrderRow = useCallback(
		(record) => ({
			onClick: () => history.push(`/supplier/workOrders/detail/${record.id}`),
		}),
		[history]
	);

	const onAssetRow = useCallback(
		(record) => ({
			onClick: () => history.push(`/supplier/assets/detail/${record.id}`),
		}),
		[history]
	);

	const handleSaveLocationNotes = useCallback(
		(propName) => (notes) => {
			const updatedLocation = {
				...record,
				[propName]: notes,
			};
			return updateLocation(updatedLocation);
		},
		[record, updateLocation]
	);

	const skipCount = posthog.isFeatureEnabled('OptimiseWorkOrderAPIforLargeData');
	return locationsFetching ? (
		<PageLoadingPlaceholder />
	) : (
		<Row style={{ margin: '16px 4px' }} gutter={12}>
			<Col span={16}>
				<div className="rowSpacing">
					<Card className="materialCard" bodyStyle={{ padding: 0 }}>
						<div style={{ height: 300, position: 'absolute', top: 0, left: 0, right: 0 }}>
							<Mapbox
								longitude={nullSafeGet('buyerFacility.longitude', record)}
								latitude={nullSafeGet('buyerFacility.latitude', record)}
								baseLayerStyle="light"
								zoomLevel={7}
							/>
						</div>
						<div style={{ height: 300, position: 'relative' }} />
						<div className="px-6 py-4">
							<div className="materialCard__title">
								{nullSafeGet('buyerFacility.primaryAddress.streetAddress1', record)}
							</div>
							{nullSafeGet('buyerFacility.primaryAddress.streetAddress2', record) ? (
								<div className="materialCard__title">
									{nullSafeGet('buyerFacility.primaryAddress.streetAddress2', record)}
								</div>
							) : null}
							<div className="materialCard__subtitle">
								{nullSafeGet('buyerFacility.primaryAddress.city', record)},{' '}
								{nullSafeGet('buyerFacility.primaryAddress.region', record)}{' '}
								{nullSafeGet('buyerFacility.primaryAddress.postcode', record)}
							</div>
						</div>
					</Card>
				</div>
				<div className="rowSpacing">
					<Card title="Active Work Orders">
						<PaginatedReduxTable
							tableLayoutFixed={true}
							showHeader={false}
							emptyState={
								<EmptyState
									graphic={
										<img
											style={{ marginBottom: 8 }}
											src="https://s3.amazonaws.com/mock-data-assets/categories/images/sunset.svg"
											alt="There aren't any active work orders for this location."
										/>
									}
									headline={"All's well at this location."}
									body={
										<div style={{ textAlign: 'center' }}>
											<div style={{ maxWidth: 440, marginBottom: 16 }}>
												There aren't any active work orders for this location.
											</div>
										</div>
									}
								/>
							}
							collectionName="work_orders"
							targetCollectionName={
								LOCATION_ASSOCIATED_ACTIVE_SUPPLIER_WORK_ORDERS_TARGET_COLLECTION
							}
							columns={workOrderColumns}
							onRow={onWorkOrderRow}
							keyAccessor={(el) => el.id}
							initialFilters={{
								locationId: locationId,
								isActive: true,
								[isInternalTech ? 'hasBuyerArchived' : 'hasSupplierArchived']: false,
							}}
							initialPagination={{ current: 1, pageSize: 3 }}
							fetchData={workOrdersRestCrudThunksForSupplier.read}
						/>
					</Card>
				</div>
				<div className="rowSpacing">
					<Card title="Work History">
						<PaginatedReduxTable
							tableLayoutFixed={true}
							showHeader={false}
							emptyState={
								<EmptyState
									graphic={
										<img
											style={{ marginBottom: 8 }}
											src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
											alt="There aren't any past work orders."
										/>
									}
									headline={'No news is good news.'}
									body={
										<div style={{ textAlign: 'center' }}>
											<div style={{ maxWidth: 440, marginBottom: 16 }}>
												There aren't any past work orders.
											</div>
										</div>
									}
								/>
							}
							collectionName="work_orders"
							targetCollectionName={
								LOCATION_ASSOCIATED_INACTIVE_SUPPLIER_WORK_ORDERS_TARGET_COLLECTION
							}
							columns={workOrderColumns}
							onRow={onWorkOrderRow}
							keyAccessor={(el) => el.id}
							initialFilters={{
								locationId: locationId,
								isActive: false,
								no_count: skipCount,
							}}
							initialPagination={{ current: 1, pageSize: 3 }}
							fetchData={workOrdersRestCrudThunksForSupplier.read}
						/>
					</Card>
				</div>
				{isInternalTech ? (
					<div className="rowSpacing">
						<Card title="Assets">
							<PaginatedReduxTable
								showHeader={false}
								emptyState={
									<EmptyState
										graphic={
											<img
												style={{ marginBottom: 8 }}
												src="https://s3.amazonaws.com/mock-data-assets/categories/images/ficus.svg"
												alt="You haven't added any assets to this location yet."
											/>
										}
										headline={"It's lonely in here."}
										body={
											<div style={{ textAlign: 'center' }}>
												<div style={{ maxWidth: 440, marginBottom: 16 }}>
													You haven't added any assets to this location yet.
												</div>
											</div>
										}
									/>
								}
								collectionName="assets"
								targetCollectionName={LOCATION_ASSOCIATED_ASSETS_TARGET_COLLECTION}
								columns={assetsColumns}
								onRow={onAssetRow}
								keyAccessor={(el) => el.id}
								initialFilters={{ locationId: locationId }}
								initialPagination={{ current: 1, pageSize: 3 }}
								fetchData={assetsRestCrudThunksForSupplier.read}
							/>
						</Card>
					</div>
				) : null}
			</Col>
			<Col span={8}>
				{nullSafeGet('brand.logoURL', record) ? (
					<div className="rowSpacing">
						<Card title="Brand">
							<img
								src={nullSafeGet('brand.logoURL', record)}
								alt=""
								className="h-auto w-20 object-contain"
							/>
						</Card>
					</div>
				) : null}
				<LocationDetailsCard location={record} />
				<div className="rowSpacing">
					<OWEditableNotesComponent
						id="techNotes"
						tooltipMessage="Only visible to internal tech team"
						title="Field Tech Notes"
						allowEdit={true}
						loading={notesLoading}
						notes={nullSafeGetOrElse('notes', locationDetails, '')}
						onSaveNotes={handleSaveNotes}
						onSaveSuccess={onLocationDetailsSuccess}
					/>
				</div>

				<div className="rowSpacing">
					<Tooltip placement="top" title="Only visible to internal tech team">
						<Card title="Field Tech Attachments" loading={notesLoading}>
							<FileUploader
								roleType="supplier"
								defaultFileList={nullSafeGetOrElse('attachments', locationDetails, [])}
								handleUploadSuccess={handleFileAttachmentUploadChange}
								uploaderType={'dragger'}
							/>
						</Card>
					</Tooltip>
				</div>
				{isInternalTech && nullSafeGet('internalNotes', record) && (
					<AccessPermissionChecker name={PERMISSION_NAMES.SHOW_INTERNAL_TECH_NOTES}>
						<div className="rowSpacing">
							<OWEditableNotesComponent
								id="buyerNotes"
								tooltipMessage="Only visible to your team"
								title="Location Staff Notes (Internal)"
								notes={nullSafeGet('internalNotes', record)}
								allowEdit={canModifyLocation}
								onSaveNotes={handleSaveLocationNotes('internalNotes')}
							/>
						</div>
					</AccessPermissionChecker>
				)}
				{isInternalTech && (
					<AccessPermissionChecker name={PERMISSION_NAMES.SHOW_INTERNAL_TECH_NOTES}>
						<Tooltip placement="top" title="Only visible to your team">
							<Card title="Location Staff Attachments">
								<FileUploader
									roleType="supplier"
									defaultFileList={nullSafeGetOrElse('internalAttachments', record, [])}
									handleUploadSuccess={handleInternalFileAttachmentUploadChange}
									uploaderType={canModifyLocation ? 'dragger' : 'readOnly'}
								/>
							</Card>
						</Tooltip>
					</AccessPermissionChecker>
				)}
				{isInternalTech ? (
					<div className="rowSpacing">
						<OWEditableNotesComponent
							id="notes"
							tooltipMessage="visible to everyone"
							title="Location Notes"
							allowEdit={canModifyLocation}
							notes={nullSafeGetOrElse('notes', record, '')}
							onSaveNotes={handleSaveLocationNotes('notes')}
						/>
					</div>
				) : !isTextElementEmpty(record.notes) ? (
					<div className="rowSpacing">
						<Card title="Location Notes">
							<div className="richTextWrapper" dangerouslySetInnerHTML={{ __html: record.notes }} />
						</Card>
					</div>
				) : null}
				<LocationContactInformation location={record} />
				<div className="rowSpacing">
					<OperatingHoursCardDisplay operatingHours={record.operatingHours} />
				</div>
				{isInternalTech ? <AreasDisplay location={record} /> : null}
				{nullSafeGet('asset', record) ? (
					<div className="rowSpacing">
						<AssetCardDisplay asset={record.asset} />
					</div>
				) : null}
			</Col>
		</Row>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	getLocation: (id) => dispatch(locationsRestCrudThunksForSupplier.readOne(id)),
	getLocationDetails: (id) => dispatch(locationDetailsRestCrudThunksForSupplier.readOne(id)),
	updateLocationDetails: (entity) =>
		dispatch(locationDetailsRestCrudThunksForSupplier.update(entity)),
	updateLocation: (entity) => dispatch(locationsRestCrudThunksForSupplier.update(entity)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(SupplierLocationDetailPage)
);

import * as React from 'react';
import { Layout, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { Document, Page } from 'react-pdf';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { getFileLink } from '../../utils/FileAccessUtils';

const { Content } = Layout;

const style = require('./AssetsManualsPageDisplay.less');

class AssetsManualsPageDisplay extends React.Component<any, any> {
	state = {
		loading: true,
		numPages: null,
		pdfFiles: [],
	};

	componentDidMount() {
		const { asset, userType } = this.props;

		const manuals = nullSafeGetOrElse('assetModel.manuals', asset, []);
		const fileManuals = manuals.map((file) => {
			let url;
			if (file.fileId) {
				// Component will show file.url as link
				const fileId = nullSafeGetOrElse('fileId', file, '');
				const fileName = nullSafeGetOrElse('fileName', file, '');
				const fileUrl = getFileLink(fileId, fileName, userType);
				url = fileUrl;
			}

			return {
				url: url,
				httpHeaders: null,
			};
		});

		this.setState({
			loading: false,
			pdfFiles: fileManuals,
		});
	}

	onDocumentLoadSuccess = ({ numPages }) => {
		this.setState({ numPages });
	};

	render() {
		const { assetsFetching } = this.props;

		const pdfDocuments = this.state.pdfFiles.map((pdfFile) => {
			const doc = (
				<Document file={pdfFile} onLoadSuccess={this.onDocumentLoadSuccess}>
					{Array.from(new Array(this.state.numPages), (el, index) => (
						<Page width={900} key={`page_${index + 1}`} pageNumber={index + 1} />
					))}
				</Document>
			);
			return doc;
		});

		return assetsFetching ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="assetsDetailDetailsPage">
				<Row style={{ margin: '16px 4px' }} gutter={12}>
					<Col span={24}>
						<div className="rowSpacing">
							{this.state.pdfFiles.length > 0 ? <div>{pdfDocuments}</div> : null}
						</div>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	assetsFetching: ownProps.assetsFetching,
	asset: ownProps.asset,
	userType: ownProps.userType,
	currentUser: state.session.currenUser,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(AssetsManualsPageDisplay)
);

import React, { FC } from 'react';
import { Popover } from 'antd';
import { KeyValueDisplay } from '../key_value_display/KeyValueDisplay';
import { InfoCircleOutlined } from '@ant-design/icons';

const InfoWithKeyValuePair: FC<any> = ({ details }): React.ReactElement => {
	return (
		<Popover content={<KeyValueDisplay keyValueStore={details} />} trigger="hover">
			<InfoCircleOutlined translate="" style={{ marginLeft: '12px' }} />
		</Popover>
	);
};

export default InfoWithKeyValuePair;

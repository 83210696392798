import * as React from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { siteSurveysRestCrudThunksForSupplier } from '../../thunks/site_surveys_thunks';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import queryString from 'querystring';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';

require('./SupplierSiteSurveysDetailPage.less');

class SupplierSiteSurveysDetailPage extends React.Component<any, any> {
	state = {
		backLinkTo: 'supplier/siteSurveys',
	};
	componentDidMount() {
		const { getSiteSurvey, location, match } = this.props;
		const siteSurveyId = match.params.id;
		getSiteSurvey(siteSurveyId).then((siteSurvey) => {
			let searchString = location.search;
			if (searchString[0] === '?') {
				searchString = searchString.slice(1);
			}
			const queryParams = queryString.parse(searchString);
			if (queryParams.backlinkParams) {
				this.setState({
					loading: false,
					backLinkTo: `/supplier/siteSurveys/overview/all?${queryParams.backlinkParams}`,
				});
			} else {
				this.setState({
					loading: false,
					backLinkTo: `/supplier/siteSurveys/overview/all`,
				});
			}
		});
	}

	handleTabChange = (key) => {
		const { history, match } = this.props;
		const siteSurveyId = match.params.id;
		history.push(`/supplier/siteSurveys/detail/${siteSurveyId}/${key}`);
	};

	handleDelete = () => {
		const { history, deleteSiteSurvey, siteSurvey } = this.props;
		deleteSiteSurvey(siteSurvey).then(() => {
			history.push('/supplier/siteSurveys/overview/all');
		});
	};
	handleEdit = () => {
		const { history, siteSurvey } = this.props;
		history.push(`/buyer/siteSurveys/overview/edit/${siteSurvey.id}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	render() {
		const { siteSurvey, siteSurveysFetching, match, companyConfig } = this.props;
		const siteSurveyId = match.params.id;
		const tabs = [
			{
				key: 'details',
				name: 'Overview',
			},
			{
				key: 'walkthroughs',
				name: 'Walkthroughs',
			},
		];
		return (
			<div className="siteSurveysDetailPage">
				<DetailPageHeader
					exactPath={'/supplier/siteSurveys/detail/:id'}
					redirectPath={`/supplier/siteSurveys/detail/${siteSurveyId}/details`}
					backLinkText={'Back to site surveys'}
					backLinkTo={this.state.backLinkTo}
					title={siteSurveysFetching ? null : siteSurvey.title}
					subtitle={
						<div className="siteSurveysDetailPage__subtitleRow">
							<div className="siteSurveysDetailPage__subtitle">
								{nullSafeGet('location.name', siteSurvey)}
							</div>
						</div>
					}
					actions={[
						<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_SITE_SURVEYS}>
							<Button key={1} ghost={true} onClick={this.handleEdit} style={{ marginRight: 16 }}>
								Edit
							</Button>
							<Button key={1} ghost={true} onClick={this.handleDelete}>
								Delete
							</Button>
						</AccessPermissionChecker>,
					]}
					tabs={tabs}
					backgroundColor={nullSafeGet('detail.config.theme.navblock', companyConfig)}
					handleTabChange={this.handleTabChange}
					checkTabActive={this.checkTabActive}
					childRoutes={this.props.childRoutes}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	siteSurveysFetching: state.site_surveys.fetching,
	siteSurvey: state.site_surveys.detail,
	match: ownProps.match,
	history: ownProps.history,
	companyConfig: state.company_config,
});

const mapDispatchToProps = (dispatch) => ({
	getSiteSurvey: (id) => dispatch(siteSurveysRestCrudThunksForSupplier.readOne(id)),
	deleteSiteSurvey: (entity) =>
		dispatch(siteSurveysRestCrudThunksForSupplier.delete(entity, 'id', null, true)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(SupplierSiteSurveysDetailPage)
);

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Button, Card } from 'antd';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import {
	projectTemplatesRestCrudThunksForBuyer,
	projectTemplatesRestCrudThunksForSupplier,
} from '../../thunks/project_template_thunks';
import { EmptyState } from '../empty_state/EmptyState';
import SubnavBar from '../subnav_bar/SubnavBar';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import queryString from 'querystring';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { FC, useEffect, useMemo } from 'react';
import { PROJECT_TEMPLATES_CRUD_ACTION_CREATORS } from '../../actions/project_templates_actions';

const { Content } = Layout;

const PROJECT_TEMPLATE_OVERVIEW_TARGET_COLLECTION = 'projectTemplatesIndex';

const ProjectTemplatesIndexPage: FC<any> = ({
	location,
	updateProjectTemplatesFilters,
	history,
	clearAndUpdateFilters,
	projectTemplates,
	userType,
}): React.ReactElement => {
	const initialFilters = useMemo(() => {
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);

		const {
			current,
			pageSize,
			total,
			pageSizeOptions,
			showSizeChanger,
			onShowSizeChange,
			sort_by,
			order,
			...assetFilters
		} = queryParams;

		return assetFilters && Object.keys(assetFilters).length > 0 ? assetFilters : {};
	}, [location.search]);

	useEffect(
		() =>
			updateProjectTemplatesFilters(initialFilters, PROJECT_TEMPLATE_OVERVIEW_TARGET_COLLECTION),
		[initialFilters, updateProjectTemplatesFilters]
	);

	const onRow = (record) => ({
		onClick: () => history.push(`/${userType}/projects/templates/detail/${record.id}`),
	});

	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			defaultSortOrder: 'ascend',
			render: (text, record) => text,
		},
	];

	console.log(projectTemplates);
	return (
		<Content className="projectTemplatesIndexPage" style={{ padding: '0 0.5em' }}>
			<LogOnMountWithStandardEventProperties eventType="visited project templates index page" />
			{/*<ScrollToTopOnMount/>*/}
			{/*<BackTop/>*/}
			<Row style={{ margin: '0.5em ' }} gutter={16}>
				<SubnavBar
					left={<div>&nbsp;</div>}
					right={
						<Button
							type="primary"
							size="large"
							onClick={() => history.push(`/${userType}/projects/overview/templates/new`)}
						>
							Add Project Template
						</Button>
					}
				/>
			</Row>
			<Row style={{ margin: '0.5em 0' }} gutter={16}>
				<Col span={24}>
					<Card bodyStyle={{ padding: 8 }}>
						<PaginatedReduxTable
							showHeader={false}
							updateQueryParams={true}
							emptyState={
								<EmptyState
									graphic={
										<img
											style={{ marginBottom: 8 }}
											src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
										/>
									}
									headline={"It's a little lonely in here."}
									body={
										<div style={{ textAlign: 'center' }}>
											<div style={{ maxWidth: 440, marginBottom: 16 }}>
												Looks like your team has not added any project templates yet.
											</div>
											<Button
												type="primary"
												onClick={() => history.push(`/${userType}/projects/overview/templates/new`)}
											>
												Add your first project template
											</Button>
										</div>
									}
								/>
							}
							onRow={onRow}
							collectionName="project_templates"
							targetCollectionName={PROJECT_TEMPLATE_OVERVIEW_TARGET_COLLECTION}
							columns={columns}
							keyAccessor={(el) => el.id}
							fetchData={
								userType === ROLE_TYPES.SUPPLIER
									? projectTemplatesRestCrudThunksForSupplier.read
									: projectTemplatesRestCrudThunksForBuyer.read
							}
						/>
					</Card>
				</Col>
			</Row>
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	projectTemplates: state.project_templates,
});

const mapDispatchToProps = (dispatch) => ({
	updateProjectTemplatesFilters: (filters, targetCollection) =>
		dispatch(PROJECT_TEMPLATES_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			PROJECT_TEMPLATES_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)
		),
});
const ProjectTemplatesPage = withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(ProjectTemplatesIndexPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ProjectTemplatesPage);

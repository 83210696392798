import { Button, Col, message, Popconfirm, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	SCHEDULE_TECH_STATUSES,
	TECH_SCHEDULED_STATUSES,
	WORK_ORDER_STATUSES,
} from '../../../constants/WorkOrderStatuses';
import {
	acceptWorkOrderForSupplier,
	cancelWorkOrderForSupplier,
	declineWorkOrderForPrivateSupplier,
	declineWorkOrderForSupplier,
	manualCheckInServiceCallForSupplier,
	manualCheckOutServiceCallForSupplier,
	rescheduleServiceCallForSupplier,
	scheduleServiceCallForSupplier,
	transferWorkOrderForPrivateSupplier,
	transferWorkOrderForSupplier,
	workOrdersRestCrudThunksForSupplier,
} from '../../../thunks/work_orders_thunks';
import {
	getSearchStringForBackNavigationWithBackText,
	isCheckInModalMandatory,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../../utils/DataAccessUtils';
import DeclineWorkorderForm from '../../decline_workorder_form/DeclineWorkorderForm';
import ManualCheckInModalForm from '../../manual_checkin_modal_form/ManualCheckInModalForm';
import ManualCheckOutModalForm from '../../manual_checkout_modal_form/ManualCheckOutModalForm';
import ScheduleServiceCallModalForm from '../../schedule_service_call_modal_form/ScheduleServiceCallModalForm';
import TransferWorkOrderModalForm from '../../transfer_work_order_modal_form/TransferWorkOrderModalForm';
import { WorkOrderRowDisplay } from '../../work_order_row_display/WorkOrderRowDisplay';
import { getLinkWIthBackLinkParams } from '../../../utils/HistoryUtils';
import { updateWorkOrderResolutionTypeForSupplier } from '../../../thunks/resolution_types_thunks';

require('./WorkOrderTableRow.less');

class WorkOrderTableRow extends React.Component<any, any> {
	formRefs: any = {
		transferWorkOrderForm: null,
		scheduleServiceCallForm: null,
		rescheduleServiceCallForm: null,
		manualCheckInForm: null,
		manualCheckOutForm: null,
	};
	constructor(props) {
		super(props);
		this.state = { modal: null };
	}
	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	render() {
		const {
			record,
			currentUser,
			handleArchive,
			refreshTable,
			acceptWorkOrder,
			scheduleServiceCall,
			unarchiveWorkOrder,
			rescheduleServiceCall,
			location,
			backText,
			updateWorkOrder,
			targetCollectionName,
			updateWorkOrderResolutionType,
		} = this.props;
		const BACK_TEXT = backText || 'Back to work orders';
		const isPrivateSupplier = nullSafeGet('facility.status', currentUser) === 'private';
		const getWorkOrderActions = (workOrder) => {
			if (workOrder === undefined) return [];
			const unarchive = (
				<div className={'ActionWrapper'}>
					<Button
						className={'ActionButton'}
						key="unarchive"
						onClick={(e) =>
							unarchiveWorkOrder(workOrder).then((e) => {
								refreshTable(location);
							})
						}
					>
						<i className="icons8-font icons8-trash-can"></i> Unarchive
					</Button>
				</div>
			);
			if (workOrder.hasSupplierArchived) {
				return [unarchive];
			}

			const internalTechConfig =
				currentUser && currentUser.facility && currentUser.facility.internalTechConfig;

			const { allowedToTransferWork, allowedToDenyWork } = internalTechConfig || {
				allowedToTransferWork: true,
				allowedToDenyWork: false,
			};

			const differentAssignedSupplier = isPrivateSupplier
				? nullSafeGet('facility.id', currentUser) !== workOrder.supplierFacilityId
				: false;

			const accept = (
				<div className={'ActionWrapper'}>
					<Button
						className={'ActionButton'}
						key="accept"
						type="primary"
						ghost={true}
						onClick={(e) =>
							acceptWorkOrder(workOrder, e).then((e) => {
								refreshTable(location);
							})
						}
					>
						<i className="icons8-font icons8-green-check-mark"></i> Accept
					</Button>
				</div>
			);
			const decline = (
				<div className={'ActionWrapper'} onClick={(e) => e.stopPropagation()}>
					<Popconfirm
						onConfirm={(e) => {
							e.stopPropagation();
							e.preventDefault();
							this.setState({ modal: 'declineWorkOrderFormVisible' });
						}}
						placement="topLeft"
						title="Are you sure you want to decline?"
						okText="Yes"
						cancelText="No"
					>
						<Button className={'ActionButton'} key="decline">
							<i className="icons8-font icons8-cancel"></i> Decline
						</Button>
					</Popconfirm>
				</div>
			);
			const edit = (
				<div className={'ActionWrapper'}>
					<Link
						className={'ActionLink'}
						to={getLinkWIthBackLinkParams(
							location,
							BACK_TEXT,
							`/supplier/workOrders/edit/${workOrder.id}`
						)}
					>
						<Button
							className={'ActionButton'}
							key="edit"
							icon={<i className="icons8-font icons8-font-margin-right icons8-edit" />}
						>
							Edit
						</Button>
						{/*<i className="icons8-font icons8-font-margin-right icons8-edit" /> Edit*/}
					</Link>
				</div>
			);
			const startWork = (
				<div className={'ActionWrapper'}>
					<Button
						className={'ActionButton'}
						key="startWork"
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
							const { manualCheckIn } = this.props;
							const serviceCall = {
								workOrderId: workOrder.id,
								supplierFacilityId: workOrder.supplierFacilityId,
								serviceScheduledAt: moment(),
								leadTechnicianEmail: currentUser.email,
								additionalTechnicianEmails: [],
								stockLocationIds: [],
							};
							scheduleServiceCall(serviceCall).then((updatedWorkOrder) => {
								if (isCheckInModalMandatory(currentUser)) {
									this.setState({ modal: 'manualCheckInFormVisible' });
								} else {
									const lastServiceCall = nullSafeGetOrElse(
										'lastServiceCall',
										updatedWorkOrder,
										{}
									);
									const updatedServiceCall = {
										...lastServiceCall,
										checkInByEmail: currentUser.email,
										checkInTime: moment(),
										checkInNotes: undefined,
										checkInPhotos: [],
									};
									manualCheckIn(updatedServiceCall).then(() => {
										message.success(`Work started.`);
									});
								}
							});
						}}
						icon={<i className="icons8-font icons8-checkmark" />}
					>
						Start Work
					</Button>
				</div>
			);
			const schedule = (
				<div className={'ActionWrapper'}>
					<Button
						className={'ActionButton'}
						key="schedule"
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
							this.setState({ modal: 'scheduleServiceCallFormVisible' });
						}}
					>
						<i className="icons8-font icons8-schedule"></i> Schedule
					</Button>
				</div>
			);
			const reschedule = (
				<div className={'ActionWrapper'}>
					<Button
						className={'ActionButton'}
						key="reschedule"
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
							this.setState({ modal: 'rescheduleServiceCallFormVisible' });
						}}
					>
						<i className="icons8-font icons8-rescheduling-a-task"></i> Reschedule
					</Button>
				</div>
			);
			const manualCheckIn = (
				<div className={'ActionWrapper'}>
					<Button
						className={'ActionButton'}
						key="manualCheckIn"
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
							this.setState({ modal: 'manualCheckInFormVisible' });
						}}
					>
						<i className="icons8-font icons8-checkmark"></i> Check In
					</Button>
				</div>
			);
			const manualCheckOut = (
				<div className={'ActionWrapper'}>
					<Button
						className={'ActionButton'}
						key="manualCheckOut"
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
							this.setState({ modal: 'manualCheckOutFormVisible' });
						}}
					>
						<i className="icons8-font icons8-checkout"></i> Check Out
					</Button>
				</div>
			);
			const transfer = (
				<div className={'ActionWrapper'}>
					<Button
						className={'ActionButton'}
						key="transfer"
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
							this.setState({ modal: 'transferWorkOrderFormVisible' });
						}}
					>
						<i className="icons8-font icons8-forward"></i> Transfer
					</Button>
				</div>
			);
			const archive = (
				<div className={'ActionWrapper'}>
					<Button
						className={'ActionButton'}
						key="archive"
						danger
						ghost={true}
						onClick={(e) =>
							handleArchive(workOrder, e).then((e) => {
								refreshTable(location);
							})
						}
					>
						<i className="icons8-font icons8-trash-can"></i> Archive
					</Button>
				</div>
			);
			const privateOptions: any[] = isPrivateSupplier
				? [edit, ...[allowedToTransferWork ? transfer : []], ...[allowedToDenyWork ? decline : []]]
				: [];
			const publicOpenOptions = isPrivateSupplier ? [] : [accept, decline];

			if (differentAssignedSupplier) {
				switch (workOrder.displayStatus) {
					case 'Pending':
						if (workOrder.status === WORK_ORDER_STATUSES.supplierInitiatedPendingApproval) {
							return [edit];
						} else {
							return null;
						}
					case 'Open':
						return privateOptions;
					case 'InProgress':
						return privateOptions;
					case 'OnHold':
						return privateOptions;
					case 'Completed':
						return [archive];
					case 'Cancelled':
						return [archive];
					default:
						return null;
				}
			} else {
				switch (workOrder.displayStatus) {
					case 'Pending':
						if (workOrder.status === WORK_ORDER_STATUSES.supplierInitiatedPendingApproval) {
							return [edit];
						} else {
							return null;
						}
					case 'Open':
						if (SCHEDULE_TECH_STATUSES.includes(workOrder.status)) {
							return [schedule, startWork].concat(privateOptions);
						} else {
							return publicOpenOptions.concat(privateOptions);
						}
					case 'InProgress':
						if (SCHEDULE_TECH_STATUSES.includes(workOrder.status)) {
							return [schedule, startWork].concat(privateOptions);
						} else if (TECH_SCHEDULED_STATUSES.includes(workOrder.status)) {
							return [reschedule, manualCheckIn].concat(privateOptions);
						} else if (workOrder.status === WORK_ORDER_STATUSES.techWorkingOnSite) {
							return [manualCheckOut].concat(privateOptions);
						} else {
							return privateOptions;
						}
					case 'OnHold':
						return privateOptions;
					case 'Completed':
						return [archive];
					case 'Cancelled':
						return [archive];
					default:
						return null;
				}
			}
		};

		const getLink = () => {
			const path = `/supplier/workOrders/detail/${nullSafeGet('id', record)}/details${
				targetCollectionName ? `?targetCollectionName=${targetCollectionName}` : ''
			}`;
			return getLinkWIthBackLinkParams(location, BACK_TEXT, path, {
				preserve: true,
			});
		};

		const { modal } = this.state;
		return (
			<>
				{modal === 'rescheduleServiceCallFormVisible' && (
					<ScheduleServiceCallModalForm
						formData={{
							...nullSafeGetOrElse('lastServiceCall', record, {}),
							workOrder: record,
						}}
						isReschedule={true}
						wrappedComponentRef={this.saveFormRef('rescheduleServiceCallForm')}
						visible={modal === 'rescheduleServiceCallFormVisible'}
						onCancel={() => this.setState({ modal: null })}
						onSubmit={() => {
							const form = this.formRefs['rescheduleServiceCallForm'].props.form;
							form.validateFields((err, values) => {
								if (err) {
									return;
								}
								rescheduleServiceCall(values).then(() => {
									this.setState({ modal: null });
									message.success(`Service call rescheduled.`);
								});
							});
						}}
					/>
				)}
				{modal === 'scheduleServiceCallFormVisible' && (
					<ScheduleServiceCallModalForm
						formData={{
							workOrder: record,
							supplierFacilityId: nullSafeGet('facility.id', currentUser),
						}}
						wrappedComponentRef={this.saveFormRef('scheduleServiceCallForm')}
						visible={modal === 'scheduleServiceCallFormVisible'}
						onCancel={() => this.setState({ modal: null })}
						onSubmit={() => {
							const form = this.formRefs['scheduleServiceCallForm'].props.form;
							form.validateFields((err, values) => {
								if (err) {
									return;
								}

								scheduleServiceCall(values).then(() => {
									this.setState({ modal: null });
									message.success(`Service call scheduled.`);
								});
							});
						}}
					/>
				)}
				{modal === 'transferWorkOrderFormVisible' && (
					<TransferWorkOrderModalForm
						formData={record}
						wrappedComponentRef={this.saveFormRef('transferWorkOrderForm')}
						visible={modal === 'transferWorkOrderFormVisible'}
						onCancel={() => this.setState({ modal: null })}
						onSubmit={() => {
							const { transferWorkOrder, transferWorkOrderForPrivateSupplier } = this.props;
							const form = this.formRefs['transferWorkOrderForm'].props.form;
							form.validateFields((err, values) => {
								if (err) {
									return;
								}
								(isPrivateSupplier
									? transferWorkOrderForPrivateSupplier(values)
									: transferWorkOrder(values)
								).then(() => {
									this.setState({ modal: null });
									message.success(`Work order transferred`);
								});
							});
						}}
					/>
				)}
				{modal === 'declineWorkOrderFormVisible' && (
					<DeclineWorkorderForm
						wrappedComponentRef={this.saveFormRef('declineWorkOrderForm')}
						visible={modal === 'declineWorkOrderFormVisible'}
						onCancel={() => this.setState({ modal: null })}
						onSubmit={() => {
							const { declineWorkOrder, declineWorkOrderPrivate, currentUser } = this.props;
							const declineRoute = isPrivateSupplier ? declineWorkOrderPrivate : declineWorkOrder;
							const form = this.formRefs['declineWorkOrderForm'].props.form;
							form.validateFields((err, values) => {
								const workOrderFormData = {
									id: record.id,
									...(values.note && {
										note: {
											text: values.note,
											noteAddedBy: currentUser.email,
											noteAddedAt: moment(),
										},
									}),
								};
								declineRoute(workOrderFormData).then(() => {
									this.setState({ modal: null });
									message.success(`Work order declined`);
									refreshTable(location);
								});
							});
						}}
					/>
				)}
				{modal === 'manualCheckInFormVisible' && (
					<ManualCheckInModalForm
						formData={nullSafeGetOrElse('lastServiceCall', record, {})}
						wrappedComponentRef={this.saveFormRef('manualCheckInForm')}
						visible={modal === 'manualCheckInFormVisible'}
						onCancel={() => this.setState({ modal: null })}
						onSubmit={() => {
							const { manualCheckIn } = this.props;
							const form = this.formRefs['manualCheckInForm'].props.form;
							form.validateFields((err, values) => {
								if (err) {
									return;
								}

								manualCheckIn(values).then(() => {
									this.setState({ modal: null });
									message.success(`Manually checked in.`);
								});
							});
						}}
					/>
				)}
				{modal === 'manualCheckOutFormVisible' && (
					<ManualCheckOutModalForm
						formData={nullSafeGetOrElse('lastServiceCall', record, {})}
						wrappedComponentRef={this.saveFormRef('manualCheckOutForm')}
						visible={modal === 'manualCheckOutFormVisible'}
						onCancel={() => this.setState({ modal: null })}
						onSubmit={() => {
							const { manualCheckOut } = this.props;
							const form = this.formRefs['manualCheckOutForm'].props.form;
							form.validateFields((err, values) => {
								if (err) {
									return;
								}
								if (values.estimatedCompletionDate) {
									const newWorkOrder = {
										...record,
										estimatedCompletionDate: values.estimatedCompletionDate.utc().format(),
									};
									updateWorkOrder(newWorkOrder)
										.then(() => {
											message.success(`Work order Updated`);
										})
										.then(() => {
											if (values.resolutionTypeId) {
												updateWorkOrderResolutionType(record.id, values.resolutionTypeId).then(
													() => {
														manualCheckOut(values).then(() => {
															this.setState({ modal: null });
															message.success(`Manually checked out.`);
														});
													}
												);
											} else {
												manualCheckOut(values).then(() => {
													this.setState({ modal: null });
													message.success(`Manually checked out.`);
												});
											}
										});
								} else {
									if (values.resolutionTypeId) {
										updateWorkOrderResolutionType(record.id, values.resolutionTypeId).then(() => {
											manualCheckOut(values).then(() => {
												this.setState({ modal: null });
												message.success(`Manually checked out.`);
											});
										});
									} else {
										manualCheckOut(values).then(() => {
											this.setState({ modal: null });
											message.success(`Manually checked out.`);
										});
									}
								}
							});
						}}
						privateSupplier={isPrivateSupplier}
					/>
				)}
				<Row className="index-row-wrapper" style={{ display: 'flex', flexWrap: 'nowrap' }}>
					<Col style={{ display: 'flex', flexGrow: 1 }}>
						<Link className="index-padding" style={{ width: '100%' }} to={getLink()}>
							<WorkOrderRowDisplay workOrder={record} />
						</Link>
					</Col>
					<Col
						className={`ActionRow`}
						style={{
							gridTemplateColumns: `repeat(${
								nullSafeGetOrElse('length', getWorkOrderActions(record), 2) > 1 ? 2 : 1
							}, auto[col-start])`,
						}}
					>
						{getWorkOrderActions(record)}
					</Col>
				</Row>
			</>
		);
	}
}

// function WorkOrderTableRow() {
// 	const [modal, this.setState]{modal: = useState(null});
// 	const [formRefs, saveFormRef] = useState({});
// }

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
	cancelWorkOrder: (entity) => dispatch(cancelWorkOrderForSupplier(entity)),
	handleArchive: (entity) =>
		dispatch(workOrdersRestCrudThunksForSupplier.archive(entity, 'id', true)),
	unarchiveWorkOrder: (entity) =>
		dispatch(workOrdersRestCrudThunksForSupplier.unarchive(entity, 'id', true)),
	scheduleServiceCall: (serviceCall) => dispatch(scheduleServiceCallForSupplier(serviceCall)),
	rescheduleServiceCall: (serviceCall) => dispatch(rescheduleServiceCallForSupplier(serviceCall)),
	manualCheckIn: (serviceCall) => dispatch(manualCheckInServiceCallForSupplier(serviceCall)),
	manualCheckOut: (serviceCall) => dispatch(manualCheckOutServiceCallForSupplier(serviceCall)),
	transferWorkOrder: (entity) => dispatch(transferWorkOrderForSupplier(entity, 'id')),
	transferWorkOrderForPrivateSupplier: (entity) =>
		dispatch(transferWorkOrderForPrivateSupplier(entity, 'id')),
	declineWorkOrder: (entity) => dispatch(declineWorkOrderForSupplier(entity, 'id')),
	declineWorkOrderPrivate: (entity) => dispatch(declineWorkOrderForPrivateSupplier(entity, 'id')),
	acceptWorkOrder: (entity) => dispatch(acceptWorkOrderForSupplier(entity, 'id')),
	updateWorkOrder: (entity) => dispatch(workOrdersRestCrudThunksForSupplier.update(entity)),
	updateWorkOrderResolutionType: (workOrderId, resolutionTypeId) =>
		dispatch(updateWorkOrderResolutionTypeForSupplier(workOrderId, resolutionTypeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderTableRow);

import React, { FC, useCallback, useMemo, useState } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Alert, Select, InputNumber, Form, Button, message, Switch, Input, Drawer } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { connect } from 'react-redux';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { partsRestCrudThunksForSupplier } from '../../thunks/parts_thunks';
import { useForm } from 'antd/lib/form/Form';
import { partsPerStockLocationsRestCrudThunksForSupplier } from '../../thunks/parts_per_stock_locations_thunks';
import { withRouter } from 'react-router';
import BackButton from '../back_button/BackButton';
import PartWithIDDisplay from '../parts_detail_details_page/PartWithIDDisplay';
import PartForm from '../part_form/PartForm';
import { PERMISSION_NAMES, isSupplierAllowedToAccess } from '../../utils/AuthUtils';

interface StockLocationAddPartFormProps extends FormComponentProps {
	onCancel: any;
	visible: boolean;
	createErrors: any;
	updateErrors: any;
	creating: boolean;
	updating: boolean;
	formData: any;

	fetchParts: any;
	fetchMultipleParts: any;
	parts: any;

	stockLocationId: any;

	createPart: any;
	onSuccess: any;
	match: any;
	companyConfig: any;
	currentUser: any;
}

const StockLocationAddOrEditPartForm: FC<StockLocationAddPartFormProps> = ({
	createErrors,
	updateErrors,
	parts,
	fetchParts,
	creating,
	fetchMultipleParts,
	createPart,
	onSuccess,
	stockLocationId,
	formData,
	companyConfig,
	currentUser,
}): React.ReactElement => {
	const [form] = useForm();

	const { setFieldsValue } = form;

	const [showCreatePart, setShowCreatePart] = useState(false);
	const [newPartName, setNewPartName] = useState('');

	const isEdit = useMemo(() => nullSafeGet('id', formData), [formData]);

	const initialValues = useMemo(() => formData, [formData]);

	const handleAddPartSubmit = useCallback(
		(values) => {
			const partsPerStockLocation = {
				...(formData || {}),
				...values,
				isStocked: !!values.isStocked,
				stockLocationId: parseInt(stockLocationId),
			};

			createPart(partsPerStockLocation).then(() => {
				onSuccess();
				message.success(isEdit ? 'Updated successfully!' : `Added a new part.`);
			});
		},
		[createPart, formData, isEdit, onSuccess, stockLocationId]
	);

	const hideCreatePart = useCallback(() => {
		setShowCreatePart(false);
		setNewPartName('');
	}, []);

	const onCreatePart = useCallback((val) => {
		return new Promise((resolve) => {
			setShowCreatePart(true);
			setNewPartName(val);
			resolve(null);
		});
	}, []);

	const onCreatePartSuccess = useCallback(
		(record) => {
			message.success('Part created successfully!');
			nullSafeGet('id', record) &&
				setFieldsValue({
					partId: record.id,
				});
			hideCreatePart();
		},
		[hideCreatePart, setFieldsValue]
	);

	const canCreatePartOrEquipment = useMemo(
		() =>
			isSupplierAllowedToAccess(
				PERMISSION_NAMES.MODIFY_PARTS_AND_EQUIPMENTS,
				companyConfig,
				currentUser.roles
			),
		[companyConfig, currentUser.roles]
	);

	return (
		<Form
			form={form}
			style={{ width: 600 }}
			id="add-part-form"
			layout="vertical"
			requiredMark={false}
			initialValues={initialValues}
			onFinish={handleAddPartSubmit}
		>
			{createErrors.length > 0 ? (
				<Form.Item>
					<Alert message={createErrors.join(' ')} type="error" />
				</Form.Item>
			) : null}
			{updateErrors.length > 0 ? (
				<Form.Item>
					<Alert message={updateErrors.join(' ')} type="error" />
				</Form.Item>
			) : null}
			<Form.Item
				label="Part"
				name="partId"
				rules={[{ required: true, message: 'This field is required.' }]}
			>
				<OWAsyncSelect
					disabled={isEdit}
					stateSlice={parts}
					targetCollectionName="stockLocationAssociatedParts"
					fetchMultiple={(ids, targetCollectionName) => {
						fetchMultipleParts(ids, targetCollectionName);
					}}
					fetchData={(
						search,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
					) => {
						fetchParts(
							{ search },
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						);
					}}
					renderRecord={(part) => (
						<Select.Option key={part.id} value={part.id}>
							<PartWithIDDisplay part={part} numberMaxChars={80} />
						</Select.Option>
					)}
					createNewEntity={canCreatePartOrEquipment ? onCreatePart : undefined}
					newEntityLabelChecker={(part) => part.name}
					labelAccessor={(part) => <PartWithIDDisplay part={part} />}
					sortBy={{ sort_by: 'name', order: 'ascend' }}
				/>
			</Form.Item>
			<Form.Item name="isStocked" label="Is Stocked" valuePropName="checked" initialValue={'true'}>
				<Switch />
			</Form.Item>
			<Form.Item name="onHandQuantity" label="On Hand Qty">
				<InputNumber style={{ width: '100%' }} />
			</Form.Item>
			{isEdit && (
				<Form.Item name="availableQuantity" label="Available Qty">
					<InputNumber style={{ width: '100%' }} disabled />
				</Form.Item>
			)}
			<Form.Item name="minQuantity" label="Min Qty">
				<InputNumber style={{ width: '100%' }} />
			</Form.Item>
			<Form.Item name="maxQuantity" label="Max Qty">
				<InputNumber style={{ width: '100%' }} />
			</Form.Item>
			<Form.Item name="aisle" label="Aisle">
				<Input style={{ width: '100%' }} />
			</Form.Item>
			<Form.Item name="bay" label="Bay">
				<Input style={{ width: '100%' }} />
			</Form.Item>
			<Form.Item name="level" label="Level">
				<Input style={{ width: '100%' }} />
			</Form.Item>
			<Form.Item name="bin" label="Bin">
				<Input />
			</Form.Item>
			<Form.Item>
				<BackButton buttonText="Cancel" />
				<Button
					type="primary"
					size="large"
					style={{ marginLeft: '16px' }}
					key="submit"
					htmlType="submit"
					form="add-part-form"
					loading={creating}
				>
					{`${isEdit ? 'Update' : `Add`} Part`}
				</Button>
			</Form.Item>
			<Drawer
				width="50%"
				placement="right"
				closable={true}
				onClose={hideCreatePart}
				visible={showCreatePart}
			>
				<PartForm
					formData={{
						name: newPartName,
					}}
					onSuccess={onCreatePartSuccess}
				/>
			</Drawer>
		</Form>
	);
};

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	createErrors: state.parts_per_stock_locations.createErrors,
	updateErrors: state.parts_per_stock_locations.updateErrors,
	creating: state.parts_per_stock_locations.creating,
	updating: state.parts_per_stock_locations.updating,

	parts: state.parts,
	currentUser: state.session.currentUser,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	fetchParts: (params, targetCollectionName, pagination, sorting, filters, addToTargetCollection) =>
		dispatch(
			partsRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleParts: (ids, targetCollectionName) =>
		dispatch(partsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	createPart: (entity) => dispatch(partsPerStockLocationsRestCrudThunksForSupplier.create(entity)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(StockLocationAddOrEditPartForm)
);

import { STOPLIGHT_COLORS } from '../utils/DataFormatterUtils';

export const PURCHASE_REQUEST_STATUSES = {
	requested: 'requested',
	approved: 'approved',
	denied: 'denied',
	cancelled: 'cancelled',
	orderInProgress: 'orderInProgress',
	ordered: 'ordered',
	fulfilled: 'fulfilled',
	partially_fulfilled: 'partially_fulfilled',
	partially_ordered: 'partially_ordered',
};

export const EXCLUDE_PR_STATUSES_FOR_PO_DISABLED = [
	PURCHASE_REQUEST_STATUSES.orderInProgress,
	PURCHASE_REQUEST_STATUSES.partially_ordered,
	PURCHASE_REQUEST_STATUSES.partially_fulfilled,
];

export const EDITABLE_PR_STATUSES = [
	PURCHASE_REQUEST_STATUSES.requested,
	PURCHASE_REQUEST_STATUSES.approved,
];

export const HIGHER_ORDER_PR_STATUSES = [
	PURCHASE_REQUEST_STATUSES.requested,
	PURCHASE_REQUEST_STATUSES.approved,
	PURCHASE_REQUEST_STATUSES.ordered,
	PURCHASE_REQUEST_STATUSES.fulfilled,
];

export const NOT_FULFILLED_STATUSES = [
	PURCHASE_REQUEST_STATUSES.requested,
	PURCHASE_REQUEST_STATUSES.approved,
	PURCHASE_REQUEST_STATUSES.cancelled,
	PURCHASE_REQUEST_STATUSES.denied,
	PURCHASE_REQUEST_STATUSES.orderInProgress,
	PURCHASE_REQUEST_STATUSES.partially_fulfilled,
	PURCHASE_REQUEST_STATUSES.ordered,
	PURCHASE_REQUEST_STATUSES.partially_ordered,
];

export const PURCHASE_REQUEST_STATUS_DISPLAY_NAMES = {
	[PURCHASE_REQUEST_STATUSES.requested]: 'Requested',
	[PURCHASE_REQUEST_STATUSES.approved]: 'Approved',
	[PURCHASE_REQUEST_STATUSES.denied]: 'Denied',
	[PURCHASE_REQUEST_STATUSES.cancelled]: 'Cancelled',
	[PURCHASE_REQUEST_STATUSES.orderInProgress]: 'Order In Progress',
	[PURCHASE_REQUEST_STATUSES.partially_ordered]: 'Partially Ordered',
	[PURCHASE_REQUEST_STATUSES.ordered]: 'Ordered',
	[PURCHASE_REQUEST_STATUSES.fulfilled]: 'Fulfilled',
	[PURCHASE_REQUEST_STATUSES.partially_fulfilled]: 'Partially Fulfilled',
};

export const PURCHASE_REQUEST_STATUS_DISPLAY_TEXT_COLORS = {
	[PURCHASE_REQUEST_STATUSES.requested]: '#4a1e87',
	[PURCHASE_REQUEST_STATUSES.approved]: 'rgb(11, 28, 111)',
	[PURCHASE_REQUEST_STATUSES.denied]: STOPLIGHT_COLORS.pureRed,
	[PURCHASE_REQUEST_STATUSES.cancelled]: STOPLIGHT_COLORS.pureRed,
	[PURCHASE_REQUEST_STATUSES.orderInProgress]: 'rgb(11, 28, 111)',
	[PURCHASE_REQUEST_STATUSES.partially_ordered]: 'rgb(11, 28, 111)',
	[PURCHASE_REQUEST_STATUSES.ordered]: 'rgb(11, 28, 111)',
	[PURCHASE_REQUEST_STATUSES.fulfilled]: STOPLIGHT_COLORS.pureGreen,
	[PURCHASE_REQUEST_STATUSES.partially_fulfilled]: 'rgb(11, 28, 111)',
};

export const PURCHASE_REQUEST_STATUS_DISPLAY_BACKGROUND_COLORS = {
	[PURCHASE_REQUEST_STATUSES.requested]: '#e8def8',
	[PURCHASE_REQUEST_STATUSES.approved]: 'rgb(225, 229, 249)',
	[PURCHASE_REQUEST_STATUSES.denied]: '#f9e6e6',
	[PURCHASE_REQUEST_STATUSES.cancelled]: '#f9e6e6',
	[PURCHASE_REQUEST_STATUSES.orderInProgress]: 'rgb(225, 229, 249)',
	[PURCHASE_REQUEST_STATUSES.partially_ordered]: 'rgb(225, 229, 249)',
	[PURCHASE_REQUEST_STATUSES.ordered]: 'rgb(225, 229, 249)',
	[PURCHASE_REQUEST_STATUSES.fulfilled]: '#e6f9e7',
	[PURCHASE_REQUEST_STATUSES.partially_fulfilled]: 'rgb(225, 229, 249)',
};

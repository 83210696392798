import React, { FC, useCallback, useMemo, useState } from 'react';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import { Button, Form, Input, Modal, Popconfirm } from 'antd';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import moment from 'moment';
import {
	cancelWorkOrderForBuyer,
	cancelWorkOrderForSupplier,
} from '../../thunks/work_orders_thunks';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ROLE_TYPES } from '../../utils/DataConstants';

const CancelWorkOrder: FC<any> = ({
	workOrder,
	cancelButton,
	refresh = () => {},

	userType,
	companyConfig,
	cancelWorkOrder,
	currentUser,
}): React.ReactElement => {
	const [form] = Form.useForm();

	const [showCancelModal, setShowCancelModal] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);

	const canShowCancelButton = useMemo(
		() =>
			userType === 'buyer'
				? nullSafeGetOrElse('config.newServiceRequest.allowCancel', companyConfig, false)
				: true, //supplier configuration handled in page (to allow differentiation between third party and internal)
		[companyConfig, userType]
	);

	const customButton = cancelButton ? (
		{
			...cancelButton,
			props: { ...cancelButton.props, onClick: () => setShowCancelModal(true) },
		}
	) : (
		<Popconfirm
			onConfirm={() => setShowCancelModal(true)}
			placement="topLeft"
			title="Are you sure you want to cancel?"
			okText="Yes"
			cancelText="No"
		>
			<Button
				type="default"
				key="cancel"
				style={{ marginBottom: 16, marginRight: 16 }}
				ghost={true}
			>
				Cancel
			</Button>
		</Popconfirm>
	);

	const handleCancelWorkOrder = useCallback(
		(values) => {
			const cancelNote = {
				text: values.note,
				noteAddedBy: nullSafeGet('email', currentUser),
				noteAddedAt: moment.utc(),
			};

			setButtonLoading(true);
			cancelWorkOrder({ ...workOrder, note: cancelNote }).then(() => {
				setShowCancelModal(false);
				setButtonLoading(false);
				refresh();
			});
		},
		[cancelWorkOrder, currentUser, refresh, workOrder]
	);

	return canShowCancelButton ? (
		<AccessPermissionChecker name={PERMISSION_NAMES.CANCEL_WORK_ORDERS}>
			<div>
				<Modal
					title={`${`Cancel`} Work Order`}
					visible={showCancelModal}
					width={600}
					okText="Confirm"
					onCancel={() => setShowCancelModal(false)}
					onOk={form.submit}
					confirmLoading={buttonLoading}
					closable={false}
				>
					<Form
						form={form}
						onFinish={(values) => {
							setButtonLoading(true);
							handleCancelWorkOrder(values);
						}}
					>
						<Form.Item
							name="note"
							label="Notes"
							rules={[
								{
									required: true,
									message: `Please add a note on why this work order was ${
										userType === `supplier` ? `declined` : `cancelled`
									}.`,
								},
							]}
							required={true}
						>
							<Input.TextArea />
						</Form.Item>
					</Form>
				</Modal>
				{customButton}
			</div>
		</AccessPermissionChecker>
	) : null;
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	fetching: state.work_orders.fetching,
	match: ownProps.match,
	companyConfig: state.company_config.detail,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	cancelWorkOrder: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? cancelWorkOrderForSupplier(entity)
				: cancelWorkOrderForBuyer(entity)
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(CancelWorkOrder)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import { Form, InputNumber, Select } from 'antd';
import React, { FC, useMemo } from 'react';
import { DEPRECIATION_METHODS } from '../../utils/DataConstants';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getCurrency, nullSafeGet } from '../../utils/DataAccessUtils';
import OWRadioGroup from '../ow_radio_group/OWRadioGroup';

const DepreciationClassCommonFormItems: FC<any> = ({ form, currentUser }): React.ReactElement => {
	const currency = useMemo(
		() =>
			getCurrency({
				supplier: nullSafeGet('facility', currentUser),
			}),
		[currentUser]
	);

	const depreciationMethod = Form.useWatch('depreciationMethod', form);
	const isVariable = Form.useWatch('isVariable', form);

	const depreciationTypes = useMemo(
		() => [
			{
				value: DEPRECIATION_METHODS.DECLINING,
				label: 'Declining Balance',
			},
			{
				value: DEPRECIATION_METHODS.STRAIGHT_LINE,
				label: 'Straight Line',
			},
		],
		[]
	);

	const timePeriods = useMemo(
		() => [
			{
				value: false,
				label: 'Fixed',
			},
			{
				value: true,
				label: 'Variable',
			},
		],
		[]
	);

	const additionalField = useMemo(() => {
		switch (depreciationMethod) {
			case DEPRECIATION_METHODS.DECLINING:
				return (
					<>
						<Form.Item
							name="depreciationRate"
							label="Depreciation Rate (Yearly)"
							rules={[
								{
									required: true,
									message: `Please enter a rate`,
								},
								{
									type: 'number',
									min: 0,
									max: 100,
									message: 'Please enter rate in percentage not exceeding 100',
								},
							]}
						>
							<InputNumber style={{ width: '100%' }} addonAfter="%" />
						</Form.Item>
						<Form.Item
							name="depreciationPeriod"
							label="Depreciation Period"
							rules={[
								{
									required: true,
									message: `Please select a period`,
								},
							]}
						>
							<Select>
								<Select.Option value="monthly" key="monthly">
									Monthly
								</Select.Option>
							</Select>
						</Form.Item>
					</>
				);
			case DEPRECIATION_METHODS.STRAIGHT_LINE:
				return (
					<>
						<Form.Item
							name="isVariable"
							label="Time Period"
							rules={[
								{
									required: true,
									message: `Please select a time period`,
								},
							]}
						>
							<OWRadioGroup
								valueAccessor={(item) => item.value}
								renderRecord={(item) => item.label}
								items={timePeriods}
							/>
						</Form.Item>
						{isVariable ? (
							<Form.Item
								name="variableType"
								label="Variable Type"
								rules={[
									{
										required: true,
										message: `Please select a Variable Type`,
									},
								]}
							>
								<Select>
									<Select.Option value="locations_end_of_lease" key="locations_end_of_lease">
										Location's end of lease
									</Select.Option>
								</Select>
							</Form.Item>
						) : null}
						<Form.Item
							name="usefulLifeInMonths"
							label={`Useful Life in Months ${isVariable ? '(Default)' : ''}`}
							rules={[
								{
									required: true,
									message: `Please enter a Useful Life in Months`,
								},
								{
									type: 'number',
									message: 'Please enter a valid number',
								},
							]}
						>
							<InputNumber style={{ width: '100%' }} />
						</Form.Item>
						<Form.Item
							name="salvageValue"
							label="Salvage Value"
							rules={[
								{
									type: 'number',
									message: 'Please enter a valid number',
								},
							]}
						>
							<InputNumber
								step={1}
								style={{ width: '100%', textAlign: 'right' }}
								addonAfter={nullSafeGet('id', currency)}
							/>
						</Form.Item>
						<Form.Item
							name="depreciationPeriod"
							label="Depreciation Period"
							rules={[
								{
									required: true,
									message: `Please select a period`,
								},
							]}
						>
							<Select>
								<Select.Option value="monthly" key="monthly">
									Monthly
								</Select.Option>
							</Select>
						</Form.Item>
					</>
				);
			default:
				return null;
		}
	}, [currency, depreciationMethod, isVariable, timePeriods]);

	return (
		<>
			<Form.Item
				name="depreciationMethod"
				label="Depreciation Method"
				rules={[
					{
						required: true,
						message: `Please select a Depreciation Method`,
					},
				]}
			>
				<Select>
					{depreciationTypes.map((item) => (
						<Select.Option value={item.value} key={item.value}>
							{item.label}
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			{additionalField}
		</>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

export default withRouter<any, any>(connect(mapStateToProps)(DepreciationClassCommonFormItems));

import { Button, Form, Popover } from 'antd';
import React, { FC, useCallback, useMemo, useState } from 'react';
import {
	getCurrency,
	nullSafeGet,
	nullSafeGetOrElse,
	roundOffToDecimal,
} from '../../utils/DataAccessUtils';
import { connect } from 'react-redux';
import { FlatfileImporter } from '@flatfile/react';
import { FLAT_FILE_LICENSE_KEY } from '../../utils/DataConstants';
import { ExportToCsv } from 'export-to-csv';
import { CSV_EXPORT_DEFAULTS } from '../../utils/DataConstants';
import { UploadOutlined } from '@ant-design/icons';
import UploadWithSampleModal from '../parts_index_page/UploadWithSampleModal';

export const FLATFILE_HEADER: any[] = [
	{
		label: 'Location ID',
		key: 'id',
		validators: [{ validate: 'required' }],
	},
	{
		label: 'Name',
		key: 'name',
		alternates: ['Location Name'],
	},
	{
		label: 'Budget',
		key: 'budget',
		validators: [
			{ validate: 'required' },
			{
				validate: 'regex_matches',
				regex: `d*`,
			},
		],
	},
];

const getFlatfileRecordForBudget = (record = {}) => ({
	id: nullSafeGetOrElse('id', record, ''),
	name: nullSafeGetOrElse('name', record, ''),
	budget: nullSafeGetOrElse('budget', record, 0),
});

const DistinctLocationBudgetComponent: FC<any> = ({
	locationDistinctBudget,
	loading,
	onEditBudget,
	allowedBudget,
	currentUser,
	hidden,
}): React.ReactElement => {
	const [uploadPopupVisible, setUploadPopupVisible] = useState(false);

	const canEdit = useMemo(() => nullSafeGet('0', locationDistinctBudget), [locationDistinctBudget]);

	const currencyId = useMemo(() => nullSafeGet('id', getCurrency({ currentUser })), [currentUser]);

	const importer = useMemo(
		() =>
			new FlatfileImporter(FLAT_FILE_LICENSE_KEY, {
				type: 'Import type',
				fields: FLATFILE_HEADER,
			}),
		[]
	);

	importer.setCustomer({
		companyId: nullSafeGet('facility.buyerCompanyId', currentUser),
		companyName: nullSafeGet('facility.name', currentUser),
		userId: currentUser.email,
	});

	const onDownloadSample = useCallback(() => {
		const csvTitle = `location_wise_budget`;
		const csvExporter = new ExportToCsv({
			...CSV_EXPORT_DEFAULTS,
			filename: csvTitle,
			title: csvTitle,
			headers: FLATFILE_HEADER.map((_) => _.label),
		});

		csvExporter.generateCsv(locationDistinctBudget.map((_) => getFlatfileRecordForBudget(_)));
	}, [locationDistinctBudget]);

	const onEdit = useCallback(() => {
		importer
			.requestDataFromUser({
				source: locationDistinctBudget.map((_) => ({
					data: getFlatfileRecordForBudget(_),
				})),
			})
			.then((results) => {
				const budgetSplit = results.validData;
				// const totalFinalBudget = budgetSplit.reduce((acc, loc) => acc + parseFloat(loc.budget), 0);
				// if(allowedBudget < totalFinalBudget) {
				//   const diff = roundOffToDecimal(totalFinalBudget - allowedBudget);
				//   importer.displayError(`Your total budget id ${diff} ${currencyId} more than the shared budget!`)
				// } else if(allowedBudget > totalFinalBudget) {
				//   const diff = roundOffToDecimal(allowedBudget - totalFinalBudget)
				//   importer.displayError(`You still have ${diff} ${currencyId} to be allocated from the shared budget!`)
				// } else {
				onEditBudget(budgetSplit);
				importer.displaySuccess('Budget updated Successfully');
				// }
			});
	}, [importer, locationDistinctBudget, onEditBudget]);

	const showUploadPopup = useCallback(() => {
		setUploadPopupVisible(true);
	}, []);
	const hideUploadPopup = useCallback(() => setUploadPopupVisible(false), []);

	const onDataUpload = useCallback(
		(result) => {
			return new Promise((resolve) => {
				onEditBudget(result.validData);
				resolve('Budget updated successfully!');
				setTimeout(() => setUploadPopupVisible(false), 0);
			});
		},
		[onEditBudget]
	);

	return (
		<Form.Item label="Distinct Location Budget" hidden={hidden}>
			<div className="flex flex-row items-center">
				<Button type="link" disabled={!canEdit} loading={loading} onClick={onEdit}>
					Edit Budgets
				</Button>
				<div className="ml-2">
					<Popover content="Download or Upload distinct location budget" trigger="hover">
						<Button
							size="large"
							className="inline-block-visible-md ml-2"
							icon={<UploadOutlined translate="" />}
							onClick={showUploadPopup}
							loading={loading}
						>
							Upload
						</Button>
					</Popover>
				</div>
			</div>
			{uploadPopupVisible && (
				<UploadWithSampleModal
					type="Distinct Location Budgets"
					title="Upload distinct location budgets"
					downloadText="Do you want to download the existing distinct location budgets?"
					sampleFileText="Download distinct location budget"
					onCancel={hideUploadPopup}
					onDownloadSample={onDownloadSample}
					fields={FLATFILE_HEADER}
					onDataLoad={onDataUpload}
				/>
			)}
		</Form.Item>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

export default connect(mapStateToProps)(DistinctLocationBudgetComponent);

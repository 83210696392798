import { List } from 'antd';
import React, { useEffect, useState } from 'react';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { debounce } from '../../utils/PerformanceUtils';

export default function LocationList({ fetchLocations, setSelected, selected, locations, total }) {
	const [loading, setLoading] = useState(true);
	const [page, setPage] = useState(1);
	const [inputValue, setInputValue] = useState('');
	const debouncedCallback = debounce(
		(searchText) => {
			setLoading(true);
			setInputValue(searchText);
			fetchLocations({ search: searchText, offset: 0, limit: 10 }).then((data) => {
				setLoading(false);
				setPage(1);
			});
		},
		400,
		false
	);
	const handleLocationsSearchDebounced = (e) => {
		const searchText = e.target.value;
		debouncedCallback(searchText);
	};

	useEffect(() => {
		fetchLocations({ offset: 0, limit: 10 }).then((data) => {
			setLoading(false);
			setSelected({ id: data[0].id, name: data[0].name });
		});
	}, []);

	return (
		<div className="block h-full w-full">
			<div className="mb-5 text-3xl text-black">All Locations</div>
			<input
				onChange={handleLocationsSearchDebounced}
				id="location"
				className=" inset-0 mb-4 block w-full rounded-sm border border-solid border-owgray  p-3 text-[14px] leading-none shadow-sm outline-none placeholder:text-placeholder hover:border-buyer focus:border-buyer focus:shadow-input"
				placeholder="Search for a location"
			/>
			<List
				pagination={{
					current: page,
					pageSize: 10,
					total,
					onChange: (current) => {
						setPage(current);
						fetchLocations({ search: inputValue, offset: (current - 1) * 10, limit: 10 });
					},
					showSizeChanger: false,
				}}
				loading={loading}
				itemLayout="horizontal"
				dataSource={locations}
				renderItem={(item: any, index) => (
					<li
						style={{ borderBottom: 'solid 1px #e5e7eb' }}
						key={item.name}
						className={`flex cursor-pointer border-b py-4  hover:bg-buyer/10 ${
							selected.id === item.id ? 'bg-buyer/25 ' : ''
						}`}
						onClick={() => {
							setSelected({ id: item.id, name: item.name });
						}}
					>
						<div className={`ml-3 w-full `}>
							<p className="mb-1 font-medium text-gray-900">{item.name}</p>
							<p className="mb-0 text-gray-500">{`${nullSafeGet(
								'buyerFacility.primaryAddress.city',
								item
							)}`}</p>
						</div>
					</li>
				)}
			/>
		</div>
	);
}

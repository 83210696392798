import * as React from 'react';
import { getWarrantyStatus, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import {
	getProtectedImageUriForBuyer,
	getProtectedImageUriForSupplier,
} from '../../utils/FileAccessUtils';
import { Card, Drawer, Button } from 'antd';
import AssetsManualsPageDisplay from '../assets_manuals_page_display/AssetsManualsPageDisplay';
import { CloseOutlined } from '@ant-design/icons';
import OfflineTag from '../common/OfflineTag';
import WarrantyDisplay from '../common/WarrantyDisplay';
import { connect } from 'react-redux';
import AssetDetailPageDisplay from '../asset_detail_page_display/AssetDetailPageDisplay';
import { withRouter } from 'react-router';
import { FC, useState } from 'react';
import { isInternalTech } from '../../utils/AuthUtils';
import SupplierAssetDetailPageDisplay from '../supplier_asset_detail_page_display/SupplierAssetDetailPageDisplay';
import AssetInvoice from '../asset_invoice/AssetInvoice';

require('./AssetCardDisplay.less');

const AssetCardDisplay: FC<any> = ({
	//From Parent
	asset,
	hideDeletedDetails,

	//Mapped State to props
	companyConfig,
	userType,
	currentUser,
}): React.ReactElement => {
	const [assetDrawerVisible, setAssetDrawerVisible] = useState(false);
	const [assetManualDrawerVisible, setAssetManualDrawerVisible] = useState(false);

	const removeAssetNumberToQRCodeAssociation = nullSafeGetOrElse(
		'config.assetConfig.removeAssetNumberToQRCodeAssociation',
		companyConfig,
		false
	);

	const assetImages = nullSafeGetOrElse('images', asset, []);
	const assetImageUris = assetImages.map((assetImg) => {
		const [imgFileId, imgFileName] = assetImg.split('/');
		return userType === 'supplier'
			? getProtectedImageUriForSupplier(imgFileId, imgFileName, 1200, 800)
			: getProtectedImageUriForBuyer(imgFileId, imgFileName, 1200, 800);
	});
	const firstAssetImage = assetImageUris.length > 0 ? assetImageUris[0] : null;
	const isExternalSupplier = userType === 'supplier' && !isInternalTech(currentUser);

	return (
		<div>
			<Drawer
				width={1000}
				placement="right"
				closable={false}
				onClose={() => setAssetDrawerVisible(false)}
				visible={assetDrawerVisible}
				bodyStyle={{
					padding: 0,
					margin: 0,
				}}
			>
				<div
					//Header
					className={'absolute top-0 right-0 z-40 flex flex-row'}
				>
					<Button
						onClick={() => setAssetDrawerVisible(false)}
						type={'text'}
						style={{ padding: '8px' }}
					>
						<CloseOutlined translate="" style={{ fontSize: '24px', color: 'white' }} />
					</Button>
				</div>
				{isExternalSupplier ? (
					<SupplierAssetDetailPageDisplay asset={asset} isDrawer={true} />
				) : (
					<AssetDetailPageDisplay
						asset={asset}
						isDrawer={true}
						hideDeletedDetails={hideDeletedDetails}
					/>
				)}
			</Drawer>
			<Drawer
				width={1000}
				placement="right"
				closable={true}
				onClose={() => setAssetManualDrawerVisible(false)}
				visible={assetManualDrawerVisible}
			>
				<AssetsManualsPageDisplay asset={asset} assetsFetching={false} userType={userType} />
			</Drawer>
			<Card
				hoverable={true}
				onClick={() => setAssetDrawerVisible(true)}
				className="materialCard"
				bodyStyle={{ padding: 0 }}
			>
				{firstAssetImage
					? [
							<div
								key={1}
								style={{
									height: 240,
									position: 'absolute',
									top: 0,
									left: 0,
									right: 0,
									backgroundSize: 'cover',
									backgroundImage: `url(${firstAssetImage})`,
								}}
							/>,
							<div key={2} style={{ height: 240, position: 'relative' }} />,
					  ]
					: null}
				<div className="materialCard__body AssetCardDisplay">
					<div className="materialCard__primaryTitle">
						<div className="materialCard__title">{nullSafeGet('name', asset)}</div>
						<div className="Chips-Row">
							{asset.downtimeStatus === 'offline' ? <OfflineTag /> : null}
							{getWarrantyStatus(nullSafeGet('warranties.0', asset)) === 'Active' ? (
								<div>{WarrantyDisplay('Active')}</div>
							) : null}
						</div>
						<div className="materialCard__subtitle">{nullSafeGet('assetType.name', asset)}</div>
						{!removeAssetNumberToQRCodeAssociation && (
							<div className="materialCard__subtitle">
								Asset Number {nullSafeGetOrElse('assetNumber', asset, '--')}
							</div>
						)}
					</div>
					<div className="materialCard__supportingText">
						{nullSafeGet('serialNumber', asset) ? (
							<div>Serial Number {nullSafeGet('serialNumber', asset)}</div>
						) : null}
						<div>{nullSafeGet('manufacturer', asset)}</div>
						{nullSafeGet('modelNumber', asset) ? (
							<div>Model {nullSafeGet('modelNumber', asset)}</div>
						) : null}
					</div>
					{nullSafeGet('invoicesTotal', asset) ? (
						<div className="materialCard__supportingText">
							Total Maintenance Spent: <AssetInvoice asset={asset} card={false} />
						</div>
					) : null}
				</div>
			</Card>
			{nullSafeGetOrElse('assetModel.manuals.length', asset, 0) > 0 ? (
				<Card
					hoverable={true}
					onClick={() => setAssetManualDrawerVisible(true)}
					className="materialCard"
					bodyStyle={{ padding: 0 }}
				>
					<div className="materialCard__iconLabel" style={{ paddingBottom: 8, paddingTop: 8 }}>
						<Button type="link">
							{nullSafeGet('assetModel.manuals.length', asset) === 1
								? 'View Manual'
								: nullSafeGet('assetModel.manuals.length', asset) + 'View Manuals'}
						</Button>
					</div>
				</Card>
			) : null}
		</div>
	);
};

const mapStateToProps = (state, ownProps) => ({
	companyConfig: state.company_config.detail,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(AssetCardDisplay)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

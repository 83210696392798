import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Alert, Button } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { supplierContactsRestCrudThunksForSupplier } from '../../thunks/supplier_contacts_thunks';

const style = require('./SupplierContactContactMethodsForm.less');
const FormItem = Form.Item;

interface SupplierContactProps {
	updating: boolean;
	formData: any;
	history: any;
	redirectForwardUrl?: string;
	goPrev?: any;
	inviteNewUser: any;
	createErrors: any[];
	updateErrors: any[];
	currentUser: any;
	create: any;
	update: any;
}

interface SupplierContactFormProps extends FormComponentProps {
	updating: boolean;
	formData: any;
	history: any;
	redirectForwardUrl?: string;
	goPrev?: any;
	updateErrors: any[];
	currentUser: any;
	update: any;
}

class SupplierContactForm extends React.Component<SupplierContactFormProps, any> {
	handleSubmit = (e) => {
		const { currentUser, history, formData, update, redirectForwardUrl } = this.props;
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				let contact = {
					...formData,
					supplierFacilityId:
						formData.supplierFacilityId ||
						nullSafeGet('facility.id', formData) ||
						nullSafeGet('facility.id', currentUser),
					...values,
				};

				update(contact).then(() => {
					if (redirectForwardUrl) {
						history.push(redirectForwardUrl);
					}
				});
			}
		});
	};

	render() {
		const { updating, form, update, formData, updateErrors, currentUser, goPrev } = this.props;
		const { getFieldDecorator, setFieldsValue } = form;

		return (
			<Form layout="vertical" onSubmit={this.handleSubmit} className="supplierForm">
				{updateErrors.length > 0 ? (
					<FormItem>
						<Alert message={updateErrors.join(' ')} type="error" />
					</FormItem>
				) : null}
				<FormItem label="Work phone">
					{getFieldDecorator('phoneWork', {
						rules: [{}],
						initialValue: formData.phoneWork,
					})(<Input style={{ maxWidth: 300 }} placeholder="(209) 372-0200" />)}
				</FormItem>
				<FormItem label="Mobile phone">
					{getFieldDecorator('phoneMobile', {
						rules: [{}],
						initialValue: formData.phoneMobile,
					})(<Input style={{ maxWidth: 300 }} placeholder="(209) 372-0200" />)}
				</FormItem>
				<FormItem>
					{goPrev ? (
						<Button style={{ opacity: 1, marginRight: 16 }} size="large" onClick={goPrev}>
							Back
						</Button>
					) : null}
					<Button
						size="large"
						type="primary"
						htmlType="submit"
						loading={updating}
						className="contactForm__button"
					>
						Finalize your account
					</Button>
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	minimal: ownProps.minimal,
	sourcingEventId: ownProps.sourcingEventId,

	formData: ownProps.formData,
	addTeamMember: ownProps.addTeamMember,
	newAccountSetupMode: ownProps.newAccountSetupMode,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	goPrev: ownProps.goPrev,
	createErrors: state.supplier_contacts.createErrors,
	updateErrors: state.supplier_contacts.updateErrors,
	updating: state.supplier_contacts.updating,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	update: (entity) => dispatch(supplierContactsRestCrudThunksForSupplier.update(entity)),
	create: (entity) => dispatch(supplierContactsRestCrudThunksForSupplier.create(entity)),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<SupplierContactFormProps>()(SupplierContactForm))
);

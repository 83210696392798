import { getObjectValues, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import * as React from 'react';
import { Checkbox, Row } from 'antd';
import { isEqual } from 'lodash';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';

export default class FilterWrapper extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			filters: {},
			isUpdated: true,
		};

		this.toggleItem = this.toggleItem.bind(this);
	}
	componentDidMount() {
		const { dataSource, activeFilters } = this.props;
		this.setState({ filters: this.populateFilterItems(dataSource) });
	}
	componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
		const { dataSource, activeFilters, columnFilter, identifier } = this.props;
		if (identifier === prevProps.identifier) {
			if (!isEqual(activeFilters, prevProps.activeFilters)) {
				this.setState({ filters: this.populateFilterItems(dataSource) });
			}
			if (!isEqual(dataSource, prevProps.dataSource)) {
				this.setState({ filters: this.populateFilterItems(dataSource) });
			}
		}
	}

	populateFilterItems(dataSource) {
		const { filterEnum, columnFilter, activeFilters } = this.props;
		let obj = {
			filterEnum: filterEnum,
			key: nullSafeGet('key', filterEnum),
			object: nullSafeGetOrElse('key', filterEnum, '').includes('-')
				? nullSafeGet('key', filterEnum).split('-')[0]
				: null,
			text: nullSafeGet('text', filterEnum),
			value: nullSafeGet('value', filterEnum),
			valuepath: nullSafeGet('valuepath', filterEnum),
			labelpath: nullSafeGet('labelpath', filterEnum),
			sublabelpath: nullSafeGet('sublabelpath', filterEnum),

			datapath: nullSafeGet('datapath', columnFilter),
			displayAs: nullSafeGet('displayAs', columnFilter),
			displayOption: true,

			filters: {},
		};
		if (obj.datapath) {
			dataSource.map((record) => {
				let item = nullSafeGet(obj.datapath, record); //Gets object for filter_enum from record
				if (item) {
					if (Array.isArray(item)) {
						item.map((o) => {
							obj.filters = this.addFilterItem(
								obj.key,
								obj.valuepath,
								obj.labelpath,
								obj.sublabelpath,
								o,
								obj.filters
							); //Add this filter to the list of filters
						});
					} else {
						obj.filters = this.addFilterItem(
							obj.key,
							obj.valuepath,
							obj.labelpath,
							obj.sublabelpath,
							item,
							obj.filters
						); //Add this filter to the list of filters
					}
				} else {
					obj.filters = this.addFilterItem(
						obj.key,
						obj.valuepath,
						obj.labelpath,
						obj.sublabelpath,
						null,
						obj.filters
					);
				}
			});
		}
		return obj.filters;
	}

	addFilterItem(
		key = '',
		valuePath = '',
		displayStringPaths = [],
		subDisplayStringPaths = [],
		obj = {},
		filters = {}
	) {
		const { activeFilters } = this.props;
		if (obj === null) {
			let active = false;
			if (activeFilters.hasOwnProperty('*')) {
				active = activeFilters['*'].active;
			}
			filters['*'] = this.createFilterItem(key, '*', 'None', '', active);
		} else {
			let value = nullSafeGet(valuePath, obj); //Retrieves the filter value
			let filterList = getObjectValues(filters).map((filter) => ({
				text: filter.text,
				value: filter.value,
				type: filter.type,
			}));
			let values = filterList.map((filter) => filter.value); //Retrieves the existing list of filter values
			if (value) {
				let display = value; //In case displayText fails, display the filter value as a fallback
				let subDisplay = '';
				let displayArray = displayStringPaths.map((path) => {
					let string = nullSafeGet(path, obj); //Attempt to retrieve display string
					if (string && typeof string === 'string') {
						return string;
					} else {
						return ''; //If it fails, return ''
					}
				});
				let subDisplayArray = subDisplayStringPaths.map((path) => {
					let string = nullSafeGet(path, obj); //Attempt to retrieve display string
					if (string && typeof string === 'string') {
						return string;
					} else {
						return ''; //If it fails, return ''
					}
				});
				//Check if displayString has more than 0 characters
				if (displayArray.toString().replace(',', '').length > 0) {
					display = displayArray.toString().replace(',', ' '); //Replace display with displayString (if it exists)
				}
				if (subDisplayArray.toString().replace(',', '').length > 0) {
					subDisplay = subDisplayArray.toString().replace(',', ' ');
				}
				//Check to see if this value is already in the list of filters
				if (!values.includes(value.toString())) {
					//filters[value] = this.createFilterItem(display,key.concat('|',value));
					let active = false;
					if (activeFilters.hasOwnProperty(value)) {
						active = activeFilters[value].active;
					}
					filters[value] = this.createFilterItem(key, value, display, subDisplay, active);
				}
			}
		}
		return filters;
	}

	createFilterItem(type, value, text, subText, active, children = null) {
		let obj;
		if (children === null) {
			obj = {
				text: text,
				subText: subText,
				value: value,
				type: type,
				active: active,
				onFilter: this.onFilter.bind(this),
			};
		} else {
			obj = {
				text: text,
				subText: subText,
				value: value,
				type: type,
				active: active,
				children: children,
			};
		}
		return obj;
	}

	onFilter = (value, record) => {
		let valuepath = this.props.filterEnum.valuepath;
		if (nullSafeGet('datapath', this.props.columnFilter)) {
			let item = nullSafeGet(this.props.columnFilter.datapath, record);
			if (Array.isArray(item)) {
				if (value === '*' && item.length === 0) {
					//handle none
					return true;
				} else {
					return item.filter((obj) => obj[valuepath] === value).length > 0;
				}
			} else {
				//Handle null
				if (value === '*' && !item) {
					//handle none
					return true;
				} else if (item) {
					return item[valuepath] === value;
				}
			}
		}
	};
	toggleItem(filterItem) {
		const { handleCheck } = this.props;
		const { filters } = this.state;

		let f = { ...filters };
		if (filterItem.active) {
			f[filterItem.value].active = false;
		} else {
			f[filterItem.value].active = true;
		}

		handleCheck(f[filterItem.value]);
		this.setState({ filters: f });
	}

	render() {
		const { value } = this.props;
		const { filters } = this.state;
		return getObjectValues(filters).map((filterItem) => {
			if (
				filterItem.value.toLowerCase().includes(value) ||
				filterItem.text.toLowerCase().includes(value)
			) {
				return (
					<Checkbox
						key={filterItem.value}
						checked={filterItem.active}
						onChange={this.toggleItem.bind(null, filterItem)}
						style={{
							display: 'flex',
							margin: '0px',
							padding: '4px 8px',
							width: '100%',
							alignItems: filterItem.subText ? 'center' : 'none',
							alignContent: filterItem.subText ? 'none' : 'center',
							justifyItems: 'flex-start',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<Ellipsis style={{ lineHeight: 1.2 }} fullWidthRecognition={true} lines={1}>
								{filterItem.text}
							</Ellipsis>
							<Ellipsis
								fullWidthRecognition={true}
								lines={1}
								style={{ lineHeight: 1.2, fontSize: '12px', color: 'rgba(0,0,0,0.45)' }}
							>
								{filterItem.subText}
							</Ellipsis>
						</div>
					</Checkbox>
				);
			}
		});
	}
}

import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Alert, DatePicker } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { connect } from 'react-redux';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import moment from 'moment';

const FormItem = Form.Item;
export const DATE_FORMAT = 'MMMM D, YYYY h:mm a';
const disabledDate = (current) => {
	// Can not select days before today and today
	return current > moment().endOf('day');
};

interface BackDateWorkOrderModalFormProps extends FormComponentProps {
	onSubmit: any;
	onCancel: any;
	createErrors: any;
	updateErrors: any;
	currentUser: any;
	visible: boolean;
	creating: boolean;
	updating: boolean;
	formData: any;
	fetchSupplierFacility: any;
	addWarrantySupplier: any;
}

class BackDateWorkOrderModalForm extends React.Component<BackDateWorkOrderModalFormProps, any> {
	render() {
		const {
			visible,
			onCancel,
			onSubmit,
			form,
			formData,
			createErrors,
			updateErrors,
			creating,
			updating,
		} = this.props;
		const { getFieldDecorator } = form;
		const okText = 'Submit';
		const titleText = 'Edit Reported time';

		getFieldDecorator(`id`, { initialValue: nullSafeGet('id', formData) });
		return (
			<Modal
				visible={visible}
				width={600}
				title={titleText}
				okText={okText}
				onCancel={onCancel}
				onOk={onSubmit}
				closable={false}
				confirmLoading={creating || updating}
			>
				<Form layout="vertical" className="supplierForm">
					{createErrors.length > 0 ? (
						<FormItem>
							<Alert message={createErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					{updateErrors.length > 0 ? (
						<FormItem>
							<Alert message={updateErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					<Form.Item required={true} label="Reported At">
						{getFieldDecorator('reportedAt', {
							rules: [
								{
									type: 'object',
									required: true,
									message: 'Please select a time',
								},
							],
							initialValue: formData.createdAt ? moment(formData.createdAt) : undefined,
						})(
							<DatePicker
								disabledDate={disabledDate}
								style={{ width: '100%' }}
								showTime={true}
								format={DATE_FORMAT}
							/>
						)}
					</Form.Item>
				</Form>
			</Modal>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,

	currentUser: state.session.currentUser,
	createErrors: state.buyer_contacts.createErrors,
	updateErrors: state.buyer_contacts.updateErrors,
	creating: state.work_orders.creating,
	updating: state.work_orders.updating,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create<BackDateWorkOrderModalFormProps>()(BackDateWorkOrderModalForm));

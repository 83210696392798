import * as React from 'react';
import { Layout, Row, Col, Card } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { invoicesRestCrudThunksForBuyer } from '../../thunks/invoices_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import InvoiceUploader from '../invoice_uploader/InvoiceUploader';
import {
	appendWorkOrderNotesForBuyer,
	workOrdersRestCrudThunksForBuyer,
} from '../../thunks/work_orders_thunks';
import InvoicePageDisplay from '../invoice_page_display/InvoicePageDisplay';
import { markUserNotificationsReadForBuyer } from '../../thunks/user_notifications_thunks';

const { Content } = Layout;

const style = require('./InvoicesDetailDetailsPage.less');

class InvoicesDetailPage extends React.Component<any, any> {
	formRefs: any;

	constructor(props) {
		super(props);

		this.formRefs = {
			editGLCodeForm: null,
			editPONumberForm: null,
		};

		this.state = {
			editGLCodeFormVisible: false,
			editPONumberFormVisible: false,
		};
	}

	refreshInvoice = () => {
		const { getWorkOrder, getInvoice, match } = this.props;
		const invoiceId = match.params.id;
		return getInvoice(invoiceId).then((invoice) => {
			getWorkOrder(invoice.workOrderId);
			return invoice;
		});
	};

	componentDidMount() {
		const { markUserNotificationsRead } = this.props;
		this.refreshInvoice().then(
			(invoice) => invoice && invoice.id && markUserNotificationsRead(invoice.id)
		);
	}

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	handleInvoicePDFUploadChange = (invoiceFileUrl) => {
		const { updateInvoice, invoice } = this.props;

		const newInvoice = {
			...invoice,
			invoicePDFLink: invoiceFileUrl,
			isDeleted: false,
		};

		updateInvoice(newInvoice).then(() => {
			this.refreshInvoice();
		});
	};

	render() {
		const { workOrdersFetching, invoice, invoices, invoicesFetching, invoicesUpdating } =
			this.props;

		if (invoice.isDeleted) {
			return (
				<Row gutter={12}>
					<Col span={24}>
						<Card>
							<div>
								<h5 style={{ marginBottom: '4px' }}>Upload an invoice file</h5>
								<div>
									<p
										style={{
											fontSize: 16,
											marginBottom: '0.5em',
										}}
									>
										Add an invoice file (PDF) to finish creating the invoice.
									</p>
									<InvoiceUploader
										roleType="buyer"
										invoiceId={invoice.id}
										buttonType="primary"
										defaultFileList={[]}
										multiple={false}
										handleUploadSuccess={this.handleInvoicePDFUploadChange}
									/>
								</div>
							</div>
						</Card>
					</Col>
				</Row>
			);
		}

		return invoicesFetching || workOrdersFetching ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="invoicesDetailDetailsPage">
				<LogOnMountWithStandardEventProperties eventType="visited buyer invoice detail page" />
				<InvoicePageDisplay
					invoice={invoice}
					refreshInvoice={this.refreshInvoice}
					showViewInvoicePage={false}
					invoicesUpdating={invoicesUpdating}
				/>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	workOrdersFetching: state.work_orders.fetching,
	workOrder: state.work_orders.detail,
	invoicesFetching: state.invoices.fetching,
	invoicesUpdating: state.invoices.updating,
	invoices: state.invoices,
	invoice: state.invoices.detail,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	appendWorkOrderNotes: (entity) => dispatch(appendWorkOrderNotesForBuyer(entity)),
	getWorkOrder: (id) => dispatch(workOrdersRestCrudThunksForBuyer.readOne(id)),
	getInvoice: (id) => dispatch(invoicesRestCrudThunksForBuyer.readOne(id)),
	updateInvoice: (entity) => dispatch(invoicesRestCrudThunksForBuyer.update(entity)),
	markUserNotificationsRead: (entityId) =>
		dispatch(markUserNotificationsReadForBuyer('Invoice', entityId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoicesDetailPage));

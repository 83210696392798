import { crudActionCreatorsGenerator } from './crud_action_creators_generator';
import { crudActionConstantsGenerator } from './crud_action_constants_generator';

export const PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CONSTANTS = crudActionConstantsGenerator(
	'PLANNED_MAINTENANCE_SCHEDULES'
);
export const PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CREATORS = crudActionCreatorsGenerator(
	PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CONSTANTS
);

export const PLANNED_MAINTENANCE_SCHEDULES_FETCH_GROUPED_SUCCESS =
	'PLANNED_MAINTENANCE_SCHEDULES_FETCH_GROUPED_SUCCESS';
export const plannedMaintenanceSchedulesFetchGroupedSuccess = (
	records,
	count,
	targetCollectionName,
	pagination,
	sorting,
	filters
) => {
	return {
		count,
		records,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		type: PLANNED_MAINTENANCE_SCHEDULES_FETCH_GROUPED_SUCCESS,
	};
};

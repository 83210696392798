import React, { FC, useCallback, useRef, useEffect, useMemo } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Layout, Row, Col, Button, Spin } from 'antd';
import SubnavBar from '../subnav_bar/SubnavBar';
import PartsLabels from './PartsLabels';
import { PrinterOutlined } from '@ant-design/icons';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import { partsPerStockLocationsRestCrudThunksForSupplier } from '../../thunks/parts_per_stock_locations_thunks';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

const { Content } = Layout;

const STOCK_LOCATION_ASSOCIATED_PARTS_PER_STOCK_LOCATIONS_TARGET_COLLECTION =
	'stockLocationAssociatedPartsPerStockLocations';

require('./PrintPartsPerStockLocationLabels.less');

const StockLocationsDetailPage: FC<any> = ({
	getStockLocation,
	getPartsPerStockLocations,
	match,
	stockLocation,
	stockLocationsFetching,
	partsPerStockLocations,
	partsPerStockLocationsFetching,
	history,
}): React.ReactElement => {
	const stockLocationId = useMemo(() => match.params.id, [match.params.id]);

	useEffect(() => {
		getStockLocation(stockLocationId);
		getPartsPerStockLocations(
			{ stockLocationId },
			STOCK_LOCATION_ASSOCIATED_PARTS_PER_STOCK_LOCATIONS_TARGET_COLLECTION,
			{
				current: 1,
				pageSize: 250,
			}
		);
	}, [getPartsPerStockLocations, getStockLocation, stockLocationId]);

	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const backToStockLocationPartsPage = useCallback(
		() => history.push(`/supplier/stockLocations/detail/${stockLocationId}/parts`),
		[history, stockLocationId]
	);

	return (
		<Content className="purchaseRequestsIndexPage" style={{ padding: '0 0.5em' }}>
			{partsPerStockLocationsFetching ? (
				<div className="m-4 flex flex-row justify-center">
					<Spin />
				</div>
			) : (
				<div>
					<Row style={{ margin: '0.5em -8px' }} gutter={16}>
						<Col span={24}>
							<SubnavBar
								left={null}
								right={
									<div>
										<Button onClick={backToStockLocationPartsPage} size="large">
											Cancel
										</Button>
										<Button
											onClick={handlePrint}
											className="ml-2"
											type="primary"
											icon={<PrinterOutlined translate="" />}
											size="large"
										>
											Print Labels
										</Button>
									</div>
								}
							/>
						</Col>
					</Row>
					<Row style={{ margin: '0.5em -8px' }} gutter={16}>
						<Col span={24}>
							<PartsLabels
								stockLocation={stockLocation}
								stockLocationsFetching={stockLocationsFetching}
								partsPerStockLocations={partsPerStockLocations}
								partsPerStockLocationsFetching={partsPerStockLocationsFetching}
								ref={componentRef}
							/>
						</Col>
					</Row>
				</div>
			)}
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	partsPerStockLocations: state.parts_per_stock_locations,
	stockLocationsFetching: state.stock_locations.fetching,
	partsPerStockLocationsFetching: state.parts_per_stock_locations.fetching,
	stockLocation: state.stock_locations.detail,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	getStockLocation: (id) => dispatch(stockLocationsRestCrudThunksForSupplier.readOne(id)),
	getPartsPerStockLocations: (params, targetCollectionName, pagination) =>
		dispatch(
			partsPerStockLocationsRestCrudThunksForSupplier.read(params, targetCollectionName, pagination)
		),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(StockLocationsDetailPage)
);

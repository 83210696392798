import * as React from 'react';
import { FlexibleImageAvatar } from '../flexible-image-avatar/FlexibleImageAvatar';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { AddressDisplay } from '../address_display/AddressDisplay';
import { Typography } from 'antd';

require('./VendorRowDisplay.less');
const { Text } = Typography;

export const VendorRowDisplay: React.FC<any> = ({ vendor, ...props }) => {
	return (
		<div style={{ display: 'flex', alignItems: 'center', minWidth: 320 }}>
			<div style={{ marginRight: 16 }}>
				{nullSafeGet('logoUrl', vendor) ? (
					<FlexibleImageAvatar
						displayName={vendor.name}
						showPopover={false}
						popoverText={vendor.name}
						squareMode={true}
						width="120px"
						height="80px"
						imageUrl={nullSafeGet('logoUrl', vendor)}
					/>
				) : (
					<FlexibleImageAvatar
						displayName={nullSafeGet('name', vendor)}
						showPopover={false}
						popoverText={nullSafeGet('name', vendor)}
						squareMode={true}
						width="120px"
						height="80px"
						imageUrl="https://s3.amazonaws.com/emptor-data-assets/images/empty-state/icons8-city-filled-100.png"
					/>
				)}
			</div>
			<div>
				<div
					style={{
						fontSize: 18,
						wordBreak: 'break-word',
					}}
				>
					{nullSafeGet('name', vendor)}
				</div>
				{vendor.isActive ? null : (
					<div className="">
						<Text className={'mr-1'} type={'danger'}>
							Inactive
						</Text>
					</div>
				)}
				<AddressDisplay
					hidePostcode={true}
					hideCountry={true}
					hideStreetAddress={true}
					address={vendor.address}
					style={{
						color: 'rgba(0,0,0,0.45)',
					}}
				/>
			</div>
		</div>
	);
};

import { Form, message, Modal, Select, Spin } from 'antd';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { moveAssetToEquipmentPerStockLocationForSupplier } from '../../thunks/equipment_per_stock_locations_thunks';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import ModalFormFooter from '../common/ModalFormFooter';
import { equipmentTypesRestCrudThunksForSupplier } from '../../thunks/equipment_types_thunks';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import TransferAssetFormFields from './TransferAssetFormFields';
import { BE_DATE_ONLY_FORMAT } from '../../utils/DataFormatterUtils';
import { renderEquipmenTypeSelect } from '../purchase_order_form/ReplaceEquipmentLineItemModal';

const ET_TC_NAME = 'equipmentTypeForTransferIndex';

const TransferToStockLocationPopup: FC<any> = ({
	stockLocations,
	asset,
	onCancel,
	onSuccess,
	fetchStockLocations,
	fetchMultipleStockLocations,
	moveAssetToEquipmentPerStockLocation,
	equipmentTypes,
	fetchEquipmentTypes,
	fetchMultipleEquipmentTypes,
}): React.ReactElement => {
	const [saving, setSaving] = useState(false);
	const [associatedETFetching, setAssociatedETFetching] = useState(true);
	const [associateETAvailable, setAssociatedETAvailable] = useState(false);

	const assetTypeId = useMemo(() => nullSafeGet('assetTypeId', asset), [asset]);

	const fetchAssociatedEquipmentTypes = useCallback(() => {
		setAssociatedETFetching(true);
		fetchEquipmentTypes({ assetTypeId })
			.then((res) => nullSafeGet('0', res) && setAssociatedETAvailable(true))
			.finally(() => setAssociatedETFetching(false));
	}, [assetTypeId, fetchEquipmentTypes]);

	useEffect(() => {
		if (nullSafeGet('equipmentTypeId', asset)) {
			setAssociatedETFetching(false);
		} else if (assetTypeId) {
			fetchAssociatedEquipmentTypes();
		}
	}, [asset, assetTypeId, fetchAssociatedEquipmentTypes]);

	const handleSubmit = useCallback(
		(values) => {
			setSaving(true);
			moveAssetToEquipmentPerStockLocation(asset.id, values.stockLocationId, {
				equipmentTypeId: values.equipmentTypeId,
				...(values.transferNote && { transferNote: values.transferNote }),
				...(values.transferDate && {
					transferDate: values.transferDate.format(BE_DATE_ONLY_FORMAT),
				}),
			})
				.catch((err) => message.error(err))
				.then((record) => onSuccess && onSuccess(record))
				.finally(() => setSaving(false));
		},
		[asset, moveAssetToEquipmentPerStockLocation, onSuccess]
	);

	const associatedEquipmentTypeRecord = useMemo(() => {
		if (nullSafeGet('equipmentTypeId', asset)) {
			const records = getRecordsForTargetCollection(equipmentTypes, ET_TC_NAME);
			return records.find((_) => _.id === nullSafeGet('equipmentTypeId', asset));
		}

		return undefined;
	}, [asset, equipmentTypes]);

	return (
		<Modal
			visible={true}
			title="Transfer to stock location"
			onCancel={onCancel}
			footer={null}
			closable={true}
		>
			{associatedETFetching ? (
				<div className="flex flex-row items-center justify-center">
					<Spin />
				</div>
			) : (
				<Form id="transfer-to-sl-form" layout="vertical" onFinish={handleSubmit}>
					<Form.Item
						name="stockLocationId"
						label="Stock location"
						rules={[{ required: true, message: 'Stock location is required!' }]}
					>
						<OWAsyncSelect
							stateSlice={stockLocations}
							targetCollectionName="stockLocationDropdown"
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleStockLocations(ids, targetCollectionName);
							}}
							fetchData={(searchText, targetCollectionName) => {
								fetchStockLocations({ name: searchText }, targetCollectionName);
							}}
							renderRecord={(stockLocation) => (
								<Select.Option key={stockLocation.id} value={stockLocation.id}>
									{stockLocation.name}
								</Select.Option>
							)}
						/>
					</Form.Item>
					<Form.Item
						name="equipmentTypeId"
						label="Equipment Type"
						initialValue={nullSafeGet('equipmentTypeId', asset)}
						rules={[{ required: true, message: 'Equipment type is required!' }]}
					>
						<OWAsyncSelect
							disabled={!!associatedEquipmentTypeRecord}
							stateSlice={equipmentTypes}
							targetCollectionName={ET_TC_NAME}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleEquipmentTypes(ids, targetCollectionName);
							}}
							fetchData={(searchText, targetCollectionName) => {
								fetchEquipmentTypes(
									{
										search: searchText,
										...(associateETAvailable && { assetTypeId }),
									},
									targetCollectionName
								);
							}}
							renderRecord={renderEquipmenTypeSelect}
						/>
					</Form.Item>
					<TransferAssetFormFields />
					<ModalFormFooter
						okText="Transfer"
						cancelText="Cancel"
						loading={saving}
						formId="transfer-to-sl-form"
						onCancelClick={onCancel}
					/>
				</Form>
			)}
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	asset: state.assets.detail,
	stockLocations: state.stock_locations,
	equipmentTypes: state.equipment_types,
});

const mapDispatchToProps = (dispatch) => ({
	fetchStockLocations: (params, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.read(params, targetCollectionName)),
	fetchMultipleStockLocations: (ids, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	moveAssetToEquipmentPerStockLocation: (assetId, toStockLocationId, data) =>
		dispatch(moveAssetToEquipmentPerStockLocationForSupplier(assetId, toStockLocationId, data)),
	fetchEquipmentTypes: (params, targetCollectionName) =>
		dispatch(equipmentTypesRestCrudThunksForSupplier.readLite(params, targetCollectionName)),
	fetchMultipleEquipmentTypes: (ids, targetCollectionName) =>
		dispatch(equipmentTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransferToStockLocationPopup);

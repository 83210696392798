import * as React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import '../styles/blah.less';
import '../styles/emoji.less';
import '../styles/tailwind.out.css';
import { routes } from './routes/routes';
import RouteWithSubRoutes from './route_with_sub_routes/RouteWithSubRoutes';
import { Badge, Modal, Typography } from 'antd';
import {
	LockOutlined,
	LogoutOutlined,
	SettingOutlined,
	SwapOutlined,
	UserOutlined,
} from '@ant-design/icons';
import { Dropdown, Layout, Menu, Button, Tooltip, Row, Col } from 'antd';
import { withRouter, Switch, Route } from 'react-router';
import {
	changeWorkspace,
	logOut,
	resendConfirmation,
	searchWorkspaces,
} from '../thunks/session_thunks';
import { getMenuItemsByRole, getWSSBackendUri } from '../utils/EnvConfigUtils';
import NotFoundPage from './not_found_page/NotFoundPage';
import { PageLoadingPlaceholder } from './page_loading_placeholder/PageLoadingPlaceholder';
import { ContactAvatar } from './contact_avatar/ContactAvatar';
import { checkAuthorization, checkCompanyPermission } from './roles/roles';
import { enquireScreen } from '../utils/ResponsiveUtils';
import { nullSafeGet, nullSafeGetOrElse } from '../utils/DataAccessUtils';

import {
	userNotificationsRestCrudThunksForBuyer,
	userNotificationsRestCrudThunksForSupplier,
} from '../thunks/user_notifications_thunks';
import { ROLE_TYPES } from '../utils/DataConstants';
import amplitude from 'amplitude-js';
import { AmplitudeProvider } from '@amplitude/react-amplitude';
import LogOnMountWithStandardEventProperties from './log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import Cohere from 'cohere-js';
import IntercomService from '../utils/IntercomService';
import OfflineHandler from './OfflineHandler';
import WorkspacesHoc from './workspace_hoc';
import SwitchWorkspaceModal from './workspaces_switch_modal';
import posthog from 'posthog-js';
import { withAuth0 } from '@auth0/auth0-react';
import ChangePasswordModal from './change_password_modal/ChangePasswordModal';
import { CONFIG_NAMES, isUserAllowedToAccess } from '../utils/AuthUtils';
import { isSupplierInventoryConfigEnabled } from './common/SupplierInventoryConfigPermissionChecker';

const { Text } = Typography;
const AMPLITUDE_KEY = 'e375307b092b3f16ce11c42e38063d02';

require('../styles/icons8-styles.less');

const Content = Layout.Content;
const Sider = Layout.Sider;

require('lodash');
const Color = require('color');

const transparentHeaderLocations = [
	'buyer/login',
	'supplier/login',
	'buyer/signup',
	'supplier/signup',
	'buyer/forgotPassword',
	'supplier/forgotPassword',
	'public/suppliers/detail',
	'search',
	'writeAReview',
];

const menuLightModeLocations = [
	'solutions/work-order-management',
	'solutions/asset-management',
	'solutions/contractor-management',
	'solutions/planned-maintenance',
	'solutions/analytics',
	'contact-us',
];

interface AppState {
	collapsed: boolean;
	menuMode: string;
	isModalVisible: boolean;
}

class App extends React.Component<any, AppState> {
	notificationSocket: WebSocket;
	/*eslint-disable */

	heartbeat?: NodeJS.Timeout;
	_isLoggedIn = false;

	/*eslint-enable */

	constructor(props) {
		super(props);

		this.state = {
			collapsed: false,
			menuMode: 'desktop',
			isModalVisible: false,
		};
		Cohere.init('m2RcRCMfxRMTZW3Q63gSyHdr');
		this.onCollapse = this.onCollapse.bind(this);
		this.onUserMenuClick = this.onUserMenuClick.bind(this);
		this.onMobileMenuClick = this.onMobileMenuClick.bind(this);
		this.handleNoticeClear = this.handleNoticeClear.bind(this);
		this.checkActiveRoute = this.checkActiveRoute.bind(this);
		this.hideModal = this.hideModal.bind(this);
	}

	establishWebSocketConnection = (email, token) =>
		new WebSocket(
			`${getWSSBackendUri()}/api/v1/${this.props.userType}/websocket/notification/${email}/${token}`
		);

	notificationOnClick = (notificationJSON) => {
		const { updateUserNotificationForBuyer, updateUserNotificationForSupplier, userType, history } =
			this.props;
		const appDomainRegex =
			userType === ROLE_TYPES.BUYER
				? /https:\/\/app\.useopenwrench\.com(.+)/i
				: /https:\/\/partners\.useopenwrench\.com(.+)/i;
		const notificationCapture = nullSafeGet(
			'webAppUrl',
			notificationJSON[`${userType}PushNotification`]
		).match(appDomainRegex);
		const notificationUrl = notificationCapture[1];
		const updateUserNotification =
			userType === ROLE_TYPES.BUYER
				? updateUserNotificationForBuyer
				: updateUserNotificationForSupplier;
		const newNotification = {
			...notificationJSON,
			hasSeen: true,
		};
		updateUserNotification(newNotification);
		history.push(notificationUrl);
	};

	refreshNotifications = () => {
		const {
			fetchUserNotificationsCountForBuyer,
			fetchUserNotificationsCountForSupplier,
			userType,
		} = this.props;

		const fetchUserNotificationsCount =
			userType === ROLE_TYPES.BUYER
				? fetchUserNotificationsCountForBuyer
				: fetchUserNotificationsCountForSupplier;
		fetchUserNotificationsCount({ isImportant: true, hasSeen: false }, 'TASKS_NOTIFICATIONS');
	};

	webSocketLifeCycle = () => {
		// disabling notifications
		return null;

		/*
        ###################
        Following lines of code are unreachable as we have returned null in the previous line for disabling notifications
        So commenting the unreachable code for now
        ###################
        */
		// const { currentUser, userType, isAuthenticated } = this.props;
		// const token = retrieveCachedUserDetails(['token']).token;
		// if (!isEmptyText(currentUser.email) && !isEmptyText(token)) {
		// 	this.notificationSocket = this.establishWebSocketConnection(currentUser.email, token);

		// 	this.notificationSocket.onopen = () => {
		// 		console.log('WebSocket Notification Client Connected');
		// 		const heartBeatFn = () => {
		// 			if (this._isLoggedIn) {
		// 				if (this.notificationSocket.readyState === 1) {
		// 					this.heartbeat = setInterval(() => {
		// 						// this.notificationSocket.send(JSON.stringify(
		// 						//     {noteType: "heartbeat", userName: currentUser.email}));
		// 						this.socketSend(
		// 							JSON.stringify({ noteType: 'heartbeat', userName: currentUser.email })
		// 						);
		// 					}, 10000);
		// 				} else {
		// 					setTimeout(() => {
		// 						heartBeatFn();
		// 					}, 100);
		// 				}
		// 			}
		// 		};
		// 		heartBeatFn();
		// 	};

		// 	this.notificationSocket.onerror = (e: Event): any => {
		// 		console.log(e);
		// 	};

		// 	this.notificationSocket.onmessage = (e: MessageEvent): any => {
		// 		const notificationJSON = JSON.parse(e.data);
		// 		const descr = nullSafeGet(`${userType}PushNotification.webAppMessage`, notificationJSON);
		// 		if (
		// 			notificationJSON.messageType !== 'heartbeat' &&
		// 			notificationJSON.messageType !== 'joined' &&
		// 			notificationJSON[`${userType}PushNotification`]
		// 		) {
		// 			this.refreshNotifications();
		// 			notification.open({
		// 				type: 'info',
		// 				message: 'New Notification',
		// 				description: descr,
		// 				onClick: () => this.notificationOnClick(notificationJSON),
		// 			});
		// 		}
		// 	};

		// 	this.notificationSocket.onclose = (e): any => {
		// 		clearInterval(this.heartbeat);
		// 		if (this._isLoggedIn) {
		// 			console.log(
		// 				'Notification Socket is closed. Reconnect will be attempted in 3 seconds.',
		// 				e.reason
		// 			);
		// 			setTimeout(() => {
		// 				if (isAuthenticated) {
		// 					// this.webSocketLifeCycle();
		// 				}
		// 			}, 3000);
		// 		}
		// 	};
		// } else {
		// 	console.log('Token and Email not set to connect to Notification Socket');
		// }
	};

	socketSend = (JSONString, retries = 3) => {
		if (this.notificationSocket.readyState === 1) {
			this.notificationSocket.send(JSONString);
		} else {
			if (retries > 0) {
				console.log('Notification Socket Send :: Retry ::', retries);
				// this.webSocketLifeCycle();
				this.socketSend(JSONString, retries - 1);
			} else {
				console.log('Notification not sent', JSONString);
			}
		}
	};

	componentDidMount() {
		this._isLoggedIn = true;
		const { isAuthenticated, history } = this.props;
		enquireScreen((b) => {
			this.setState({ menuMode: b ? 'mobile' : 'desktop' });
		});

		if (isAuthenticated) {
			this.refreshNotifications();
		}
		IntercomService.init();

		function trackPageView() {
			posthog.capture('$pageview');
		}

		history.listen(trackPageView);
	}

	componentDidUpdate(prevProps) {
		this._isLoggedIn = true;
		const { isAuthenticated } = this.props;

		if (isAuthenticated !== prevProps.isAuthenticated && isAuthenticated) {
			this.refreshNotifications();
		}
	}

	atLogOutActions = (userType) => {
		const { history } = this.props;
		this._isLoggedIn = false;
		if (this.heartbeat) clearInterval(this.heartbeat);
		this.notificationSocket && this.notificationSocket.close();
		// history.push(`/${userType}/login`);
	};

	componentWillUnmount() {
		const { userType } = this.props;
		this.atLogOutActions(userType);
	}

	onCollapse() {
		this.setState({ collapsed: !this.state.collapsed });
	}

	onUserMenuClick({ key }) {
		const {
			userType,
			logOut,
			history,
			auth0: { logout: Auth0logout },
			currentUser,
		} = this.props;
		switch (key) {
			case 'changePassword':
				history.push(`/${userType}/changePassword`);
				return true;
			case 'logout':
				logOut(userType).then((res) => {
					if (currentUser.isSSOUser) {
						Auth0logout({
							logoutParams: { returnTo: `${window.location.origin}/${userType}/login` },
						});
					}
					this.atLogOutActions(userType);
				});
				return true;
			case 'login':
				history.push('/login');
				return true;
			case 'profile':
				history.push(`/${userType}/myProfile`);
				return true;
			case 'settings':
				history.push(`/${userType}/settings`);
				return true;
			case 'signup':
				history.push('/signup');
				return true;
			case 'workspaces':
				this.setState({ isModalVisible: true });
				return true;
			default:
				return true;
		}
	}

	hideModal() {
		this.setState({ isModalVisible: false });
	}

	onMobileMenuClick({ key }) {
		const { history } = this.props;
		switch (key) {
			case 'signUpAsSupplier':
				history.push('/supplier/signup');
				return true;
			case 'signUpAsBuyer':
				history.push('/buyer/signup');
				return true;
			case 'healthcare':
				history.push('/health');
				return true;
			case 'cre':
				history.push('/cre');
				return true;
			case 'blog':
				history.push(`/blog`);
				return true;
			default:
				return true;
		}
	}

	getMenuSelectedKeys() {
		const { location } = this.props;
		const pathname = location.pathname;
		// TODO: these should not be hard coded. refactor menu to work off a list.
		const keys = ['/dashboard', '/sourcing', '/analytics', '/contracts', '/projects'];
		return keys.filter((key) => pathname.indexOf(key) !== -1);
	}

	handleNoticeClear() {}

	handleNoticeVisibleChange() {}

	resendConfirmation = () => {
		const { userType, resendConfirmation } = this.props;
		resendConfirmation(userType);
	};

	checkActiveRoute(route) {
		const { location } = this.props;
		let regex = new RegExp(route);
		return regex.test(location.pathname);
	}

	checkActiveRoutes(routeArray: string[]) {
		return routeArray.some((route) => this.checkActiveRoute(route));
	}

	getLinkClasses = (route) => {
		let linkClasses = ['linkItem'];
		let isActive = false;
		let isLightMode = this.checkMenuLightMode();
		if (Array.isArray(route)) {
			isActive = this.checkActiveRoutes(route);
		} else {
			isActive = this.checkActiveRoute(route);
		}
		if (isActive) {
			linkClasses.push('linkItem--active');
		}
		if (isLightMode) {
			linkClasses.push('linkItem--lightMode');
		}
		return linkClasses.join(' ');
	};

	checkMenuLightMode() {
		const { location } = this.props;
		return menuLightModeLocations.some((loc) => {
			let regex = new RegExp(loc);
			return regex.test(location.pathname);
		});
	}

	render() {
		const {
			authChecked,
			backlinkTo,
			backlinkTitle,
			history,
			navbarBackgroundColor,
			companyConfig,
			isAuthenticated,
			location,
			currentUser,
			userType,
			activeWorkspaceId,
			featureFlags,
		} = this.props;
		const { collapsed } = this.state;

		const pathname = location.pathname;

		/*#########
        This Funtion is unused in code. But this might be used for notices. So will use it in future if needed
        Commenting it for now
        ###########
        */
		// function getNoticeData(notices) {
		// 	if (notices.length === 0) {
		// 		return {};
		// 	}
		// 	const newNotices = notices.map((notice) => {
		// 		const newNotice = { ...notice };
		// 		if (newNotice.datetime) {
		// 			newNotice.datetime = moment(notice.datetime).fromNow();
		// 		}
		// 		// transform id to item key
		// 		if (newNotice.id) {
		// 			newNotice.key = newNotice.id;
		// 		}
		// 		if (newNotice.extra && newNotice.status) {
		// 			const color = {
		// 				todo: '',
		// 				processing: 'blue',
		// 				urgent: 'red',
		// 				doing: 'gold',
		// 			}[newNotice.status];
		// 			newNotice.extra = (
		// 				<Tag color={color} style={{ marginRight: 0 }}>
		// 					{newNotice.extra}
		// 				</Tag>
		// 			);
		// 		}
		// 		return newNotice;
		// 	});
		// 	return groupBy(newNotices, 'type');
		// }

		// extrapolate log in type for auth links from url
		const buyerInUrl = location.pathname.indexOf('buyer') >= 0;
		const supplierInUrl = location.pathname.indexOf('supplier') >= 0;
		let userTypeFromUrl = '';
		if (buyerInUrl) userTypeFromUrl = 'buyer';
		if (supplierInUrl) userTypeFromUrl = 'supplier';

		let menuItemStyle;
		if (collapsed) {
			menuItemStyle = {
				position: 'relative',
				fontSize: 28,
				left: -6,
			};
		} else {
			menuItemStyle = { fontSize: 24 };
		}

		const showTransparentHeader = transparentHeaderLocations.some((loc) => {
			let regex = new RegExp(loc);
			return regex.test(location.pathname);
		});

		let headerStyle = {};
		let headerLogoUrl = '';
		if (showTransparentHeader) {
			headerStyle = {
				padding: '0',
				position: 'absolute',
				height: 64,
				lineHeight: '64px',
				left: 0,
				right: 0,
			};
		} else {
			if (isAuthenticated) {
				headerStyle = {
					padding: '0',
					background: '#3D82AA',
					position: 'relative',
				};
			} else {
				headerStyle = {
					padding: '0',
					height: 64,
					lineHeight: '64px',
					position: 'absolute',
					left: 0,
					right: 0,
				};
			}
		}
		const whiteLogoUrl =
			'https://s3.amazonaws.com/emptor-data-assets/images/emptor-logo/openwrenchlogo-white.png';
		const greyLogoUrl =
			'https://s3.amazonaws.com/emptor-data-assets/images/emptor-logo/openwrenchlogo-grey.png';

		//These logos are unused in the code. Might be useful in future so commenting it out
		// const colorLogoUrl =
		// 	'https://s3.amazonaws.com/emptor-data-assets/images/emptor-logo/openwrenchlogo.png';
		// const whiteLogoCollapsedUrl =
		// 	'https://s3.amazonaws.com/emptor-data-assets/images/openwrench-logos/openwrenchlogocollapsed-white.png';
		// const colorLogoCollapsedUrl =
		// 	'https://s3.amazonaws.com/mock-data-assets/categories/images/Logo_Coloured_Symbol.png';

		const siderLogoUrl = collapsed
			? nullSafeGet('config.theme.logoMinimized', companyConfig)
			: nullSafeGet('config.theme.logo', companyConfig);
		const getSubnavColor = () => {
			if (navbarBackgroundColor) {
				return navbarBackgroundColor;
			} else if (nullSafeGet('config.theme.navblock', companyConfig)) {
				return Color(nullSafeGet('config.theme.navblock', companyConfig)).darken(0.2).toString();
			} else if (userType === ROLE_TYPES.SUPPLIER) {
				return '#1b4d5a';
			} else {
				return '#351b5a';
			}
		};

		let headerLogoStyle = {};
		if (showTransparentHeader) {
			headerLogoUrl = whiteLogoUrl;
			headerLogoStyle = {
				color: 'white',
			};
		} else {
			headerLogoUrl = greyLogoUrl;
			headerLogoStyle = {
				color: '#1f8ceb',
			};
		}

		const changePWParams = currentUser.isSharedContact
			? {
					tooltip: 'Shared users must logout to reset password',
					disabled: true,
			  }
			: {};
		const userMenu = (currentUser) => {
			let items = [
				{ icon: <UserOutlined translate="" />, key: 'profile', label: 'Profile' },

				{ icon: <SettingOutlined translate="" />, key: 'settings', label: 'Settings' },
				{ icon: <LogoutOutlined translate="" />, key: 'logout', label: 'Log Out' },
			];
			if (
				currentUser.hasAllFacilityAccess ||
				(currentUser.secondaryFacilityIds && currentUser.secondaryFacilityIds.length)
			) {
				items = [
					...items.slice(0, 2),
					{
						icon: <SwapOutlined translate="" rotate={90} />,
						key: 'workspaces',
						label: 'Switch Facility',
					},
					...items.slice(2),
				];
			}
			if (!currentUser.isSSOUser) {
				items = [
					...items.slice(0, 1),
					{
						icon: <LockOutlined translate="" style={{ fontSize: 12 }} />,
						key: 'changePassword',
						label: 'Change Password',
						...changePWParams,
					},
					...items.slice(1),
				];
			}
			return (
				<Menu mode="inline" selectedKeys={[]} onClick={this.onUserMenuClick}>
					{items.map((item) =>
						nullSafeGet('tooltip', item) ? (
							<Menu.Item key={item.key} disabled={true} style={{ width: 'max-content' }}>
								<Tooltip placement={'left'} trigger={'hover'} title={nullSafeGet('tooltip', item)}>
									<div style={{ display: 'flex', width: '100%', gap: '8px', flexWrap: 'nowrap' }}>
										<div>{item.icon}</div>
										{item.label}
									</div>
								</Tooltip>
							</Menu.Item>
						) : (
							<Menu.Item key={item.key} icon={item.icon} style={{ minWidth: 'max-content' }}>
								{item.label}
							</Menu.Item>
						)
					)}
				</Menu>
			);
		};

		let menuItems = [];
		if (currentUser.roles && userType) {
			let updatedUserType = userType;
			if (
				userType === 'supplier' &&
				nullSafeGetOrElse('facility.status', currentUser, '') === 'private'
			) {
				updatedUserType = 'internal_supplier';
			}

			const disablePO = isSupplierInventoryConfigEnabled(
				companyConfig,
				CONFIG_NAMES.DISABLE_PURCHASE_ORDERS
			);

			menuItems = getMenuItemsByRole(updatedUserType, featureFlags)
				.filter((menuItem) => {
					const hasRoleAuth = checkAuthorization(menuItem.authorizedRoles)(currentUser.roles);
					const hasCompanyAuth = checkCompanyPermission(menuItem.unauthorizedCompanies)(
						userType === ROLE_TYPES.SUPPLIER
							? nullSafeGet('facility.supplierCompanyId', currentUser)
							: nullSafeGet('facility.buyerCompanyId', currentUser)
					);
					const hasAccessPermission = isUserAllowedToAccess(userType)(
						menuItem.accessPermissionName,
						companyConfig,
						currentUser.roles
					);
					return hasRoleAuth && hasCompanyAuth && hasAccessPermission;
				})
				.map((menuItem) =>
					menuItem.children
						? {
								...menuItem,
								children: menuItem.children.filter((child) => {
									const hasRoleAuth = checkAuthorization(child.authorizedRoles)(currentUser.roles);
									const hasCompanyAuth = checkCompanyPermission(child.unauthorizedCompanies)(
										userType === 'supplier'
											? nullSafeGet('facility.supplierCompanyId', currentUser)
											: nullSafeGet('facility.buyerCompanyId', currentUser)
									);
									const hasAccessPermission = isUserAllowedToAccess(userType)(
										child.accessPermissionName,
										companyConfig,
										currentUser.roles
									);
									if (child.to === '/supplier/purchaseOrders' && disablePO) {
										return false;
									}
									return hasRoleAuth && hasCompanyAuth && hasAccessPermission;
								}),
						  }
						: menuItem
				);
		}
		// @ts-ignore
		// @ts-ignore
		// @ts-ignore
		return authChecked ? (
			<AmplitudeProvider
				amplitudeInstance={amplitude.getInstance()}
				apiKey={AMPLITUDE_KEY}
				userId={currentUser.email}
				key={currentUser.email}
			>
				<LogOnMountWithStandardEventProperties eventType="started session" />
				<Layout className="layout hide-horizontal-scrollbar">
					{isAuthenticated ? (
						<Sider
							collapsible={true}
							collapsed={collapsed}
							onCollapse={this.onCollapse}
							breakpoint="lg"
							width={256}
							style={{
								minHeight: '100vh',
								background: nullSafeGet('config.theme.sidebar', companyConfig),
							}}
						>
							<Link to="/">
								<div style={{ padding: collapsed ? '0 20px' : '0px 56px 0 24px' }}>
									<div
										className="siderLogoContainer"
										style={{ backgroundImage: `url(${siderLogoUrl})` }}
									/>
								</div>
							</Link>
							<Menu
								style={{
									background: nullSafeGet('config.theme.sidebar', companyConfig),
									marginTop: 24,
								}}
								theme="dark"
								selectedKeys={this.getMenuSelectedKeys()}
								mode="inline"
							>
								{menuItems.map((item, i) =>
									item.children ? (
										<Menu.SubMenu
											key={i}
											title={
												<div className="ant-submenu-item">
													<i style={menuItemStyle} className={`${item.icon}`} />
													<span className="nav-text">{item.title}</span>
												</div>
											}
										>
											{item.children.map((child) => (
												<Menu.Item
													className={child.checkActive(pathname) ? 'siderMenuItemActive' : ''}
													title={child.title}
													key={child.to}
												>
													<Link
														className={`flex items-baseline justify-between ${
															child.checkActive(pathname) ? 'siderMenuLinkActive' : ''
														}`}
														to={child.to}
													>
														<div>
															<i style={menuItemStyle} className={`${child.icon}`} />
															<span className="nav-text">{child.title}</span>
														</div>
														{(child.count && (
															<Badge
																className="ml-2"
																size="small"
																count={child.count(this.props)}
															/>
														)) ||
															null}
													</Link>
												</Menu.Item>
											))}
										</Menu.SubMenu>
									) : (
										<Menu.Item
											className={item.checkActive(pathname) ? 'siderMenuItemActive' : ''}
											title={item.title}
											key={item.to}
										>
											<Link
												className={`flex items-baseline justify-between ${
													item.checkActive(pathname) ? 'siderMenuLinkActive' : ''
												}`}
												to={item.to}
											>
												<div>
													<i style={menuItemStyle} className={`${item.icon}`} />
													<span className="nav-text">{item.title}</span>
												</div>
												{(item.count && (
													<Badge className="ml-2" size="default" count={item.count(this.props)} />
												)) ||
													null}
											</Link>
										</Menu.Item>
									)
								)}
							</Menu>
						</Sider>
					) : null}
					<Layout>
						<Content>
							<ChangePasswordModal currentUser={currentUser} />
							{isAuthenticated ? (
								<div
									style={{
										height: 40,
										backgroundColor: getSubnavColor(),
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
									}}
								>
									<div style={{ marginLeft: 26 }}>
										{backlinkTo ? (
											<Link to={backlinkTo}>
												<Button
													style={{
														display: 'flex',
														alignItems: 'center',
														color: 'white',
													}}
													type="link"
													ghost={true}
												>
													<i
														style={{ fontSize: 20, marginRight: 8 }}
														className={`icons8-font icons8-long-arrow-left`}
													/>
													<span>{backlinkTitle}</span>
												</Button>
											</Link>
										) : null}
									</div>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
									>
										<Tooltip placement="bottom" title="Refer a Friend">
											<Button
												onClick={() => history.push(`/${userType}/referral`)}
												key="feedback"
												ghost={true}
												type="link"
												style={{ paddingTop: 0 }}
											>
												<i style={{ fontSize: 24, color: 'white' }} className={`icons8-get-cash`} />
											</Button>
										</Tooltip>
										<Tooltip placement="bottom" title="Request a Feature">
											<Button key="feedback" type="link" ghost={true} style={{ paddingTop: 0 }}>
												<a
													href="https://openwrench.canny.io/feature-requests"
													target="_blank"
													rel="noreferrer"
												>
													<i
														style={{ fontSize: 24, color: 'white' }}
														className={`icons8-fantasy`}
													/>
												</a>
											</Button>
										</Tooltip>
										<Tooltip placement="bottom" title="Report a Bug">
											<Button key="feedback" ghost={true} type="link" style={{ paddingTop: 0 }}>
												<a href="https://openwrench.canny.io/bugs" target="_blank" rel="noreferrer">
													<i style={{ fontSize: 24, color: 'white' }} className={`icons8-bug-2`} />
												</a>
											</Button>
										</Tooltip>
										<Tooltip placement="bottom" title="Chat with Support">
											<Button
												key="help"
												ghost
												type="link"
												style={{ paddingTop: 0 }}
												onClick={() => {
													//@ts-ignore;
													window.Intercom('show');
												}}
											>
												<i style={{ fontSize: 24, color: 'white' }} className={`icons8-headset`} />
											</Button>
										</Tooltip>
										<div style={{ margin: '0 16px' }}>
											<Dropdown placement="bottomLeft" overlay={userMenu(currentUser)}>
												<Row align="middle">
													<Col>
														<ContactAvatar
															hidePopover={true}
															contact={currentUser}
															userType={userType}
														/>
													</Col>
													{((currentUser.hasAllFacilityAccess ||
														(currentUser.secondaryFacilityIds &&
															currentUser.secondaryFacilityIds.length)) && (
														<Col style={{ color: 'white', marginLeft: 12 }}>
															<Row style={{ flexDirection: 'column' }}>
																<Text style={{ color: 'white' }}>{currentUser.email}</Text>
																<span
																	style={{
																		color: 'white',
																		opacity: 0.8,
																		lineHeight: 1,
																		fontSize: 12,
																	}}
																>
																	{nullSafeGet('facility.displayName', currentUser)}
																</span>
															</Row>
														</Col>
													)) ||
														null}
												</Row>
											</Dropdown>
											<Modal
												footer={
													<Row justify="space-between">
														<Link to={`/${userType}/newFacilitySetup`}>
															<Button onClick={this.hideModal} type="default">
																Create new
															</Button>
														</Link>

														<Button onClick={this.hideModal} type="primary">
															Cancel
														</Button>
													</Row>
												}
												centered
												bodyStyle={{ maxHeight: '60vh', overflow: 'auto' }}
												onCancel={this.hideModal}
												visible={this.state.isModalVisible}
											>
												<SwitchWorkspaceModal
													userType={userType}
													workspaces={currentUser.secondaryFacilities}
													activeWorkspaceId={activeWorkspaceId}
													changeWorkspace={this.props.changeWorkspace}
													searchWorkspaces={this.props.searchWorkspaces}
													currentUser={currentUser}
												/>
											</Modal>
										</div>
									</div>
								</div>
							) : null}
							<Switch>
								{routes.map((r, i) => (
									<RouteWithSubRoutes key={i} {...r} />
								))}
								<Route component={NotFoundPage} />
							</Switch>
						</Content>
					</Layout>
				</Layout>
			</AmplitudeProvider>
		) : (
			<PageLoadingPlaceholder />
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	isAuthenticated: state.session.isAuthenticated,
	activeWorkspaceId: state.session.workspaceId,
	authChecked: state.session.initialCheck,
	accountActivated: state.session.accountActivated,
	companyConfig: state.company_config.detail,

	currentUser: state.session.currentUser,
	userType: state.session.userType,
	backlinkTo: state.navbar.backlinkTo,
	backlinkTitle: state.navbar.backlinkTitle,
	navbarBackgroundColor: state.navbar.navbarBackgroundColor,
	location: ownProps.location,
	featureFlags: state.session.featureFlags,
	userNotifications: state.user_notifications,
});

const mapDispatchToProps = (dispatch) => ({
	resendConfirmation: (role) => dispatch(resendConfirmation(role)()),
	fetchUserNotificationsCountForBuyer: (params, countName = null) =>
		dispatch(userNotificationsRestCrudThunksForBuyer.countBy(params, countName)),
	fetchUserNotificationsCountForSupplier: (params, countName = null) =>
		dispatch(userNotificationsRestCrudThunksForSupplier.countBy(params, countName)),
	updateUserNotificationForBuyer: (entity) =>
		dispatch(userNotificationsRestCrudThunksForBuyer.update(entity)),
	updateUserNotificationForSupplier: (entity) =>
		dispatch(userNotificationsRestCrudThunksForSupplier.update(entity)),
	logOut: (role) => dispatch(logOut(role)()),
	changeWorkspace: (id, userType) => dispatch(changeWorkspace(id, userType)),
	searchWorkspaces: (str, userType, page) => dispatch(searchWorkspaces(str, userType, page)),
});

const ComponentWithoutUserType = withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(OfflineHandler(WorkspacesHoc(withAuth0(App))))
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import React, { FC, useMemo } from 'react';
import PurchaseOrderStatusDisplay from '../purchase_order_status_display/PurchaseOrderStatusDisplay';
import {
	getCurrency,
	getPurchaseOrderId,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import moment from 'moment';
import DateTimeHover from '../date_time_component/DateTime';

require('./PurchaseOrderRowDisplay.less');

const PartEquipmentFirstLineDisplay: FC<any> = ({ purchaseOrder }) => {
	const totalCount = useMemo(
		() =>
			nullSafeGetOrElse('partSupplierPurchaseOrderLineItems', purchaseOrder, []).length +
			nullSafeGetOrElse('equipmentSupplierPurchaseOrderLineItems', purchaseOrder, []).length,
		[purchaseOrder]
	);

	const displayText = useMemo(() => {
		const partLineItmes = nullSafeGetOrElse(
			'partSupplierPurchaseOrderLineItems',
			purchaseOrder,
			[]
		);
		const equipmentLineItems = nullSafeGetOrElse(
			'equipmentSupplierPurchaseOrderLineItems',
			purchaseOrder,
			[]
		);
		if (partLineItmes.length > 0) {
			const record = partLineItmes[0];
			return `${nullSafeGetOrElse('partQuantity', record, '--')} ${
				nullSafeGetOrElse('partQuantity', record, 0) > 1
					? nullSafeGet('partUom.displayNamePlural', record)
					: nullSafeGet('partUom.displayNameSingular', record)
			} of ${nullSafeGetOrElse('part.name', record, '')}`;
		} else if (equipmentLineItems.length > 0) {
			const record = equipmentLineItems[0];
			return `${nullSafeGetOrElse('equipmentQuantity', record, 0)} of ${nullSafeGetOrElse(
				'equipmentType.modelName',
				record,
				''
			)}`;
		} else return '';
	}, [purchaseOrder]);

	return displayText ? (
		<div>
			<span>{displayText}</span>
			{totalCount > 1 ? (
				<span className="additional-detail ml-2">{`+${totalCount - 1} more`}</span>
			) : null}
		</div>
	) : null;
};

const PurchaseOrderRowDisplay: FC<any> = ({ purchaseOrder, ...props }) => {
	const currency = getCurrency({
		currencyId: nullSafeGet('currencyId', purchaseOrder),
		supplierFacility: nullSafeGet('supplierFacility', purchaseOrder),
	});
	return (
		<div className="flex items-center justify-between" {...props}>
			<div className="purchaseOrderRowDisplay flex items-center justify-between">
				<div className="flex flex-col">
					<div className="mb-3 flex flex-row items-center">
						<div className="purchaseOrderRowDisplay__rowTitle">
							Purchase Order {getPurchaseOrderId(purchaseOrder)}
						</div>
					</div>
					<div className="purchaseOrderRowDisplay__rowSubtitle">
						{nullSafeGet('partEquipmentVendor.name', purchaseOrder) ? (
							<div
								className="purchaseOrderRowDisplay__rowInlineGroup"
								style={{ display: 'inline-flex' }}
							>
								<i className="icons8-font icons8-company mr-2" />
								{nullSafeGet('partEquipmentVendor.name', purchaseOrder)}
							</div>
						) : null}
						<div className="purchaseOrderRowDisplay__rowInlineGroup">
							<span>
								Created{' '}
								<DateTimeHover
									timestamp={nullSafeGet('createdAt', purchaseOrder)}
									showTime={false}
								/>
							</span>
						</div>
						<div
							className="purchaseOrderRowDisplay__rowInlineGroup"
							style={{ display: 'inline-flex' }}
						>
							<PurchaseOrderStatusDisplay status={purchaseOrder.status} />
						</div>
					</div>
					<div className="purchaseOrderRowDisplay__rowInlineGroup mt-2">
						<PartEquipmentFirstLineDisplay purchaseOrder={purchaseOrder} />
					</div>
				</div>
			</div>
			<div>{currency.format(nullSafeGetOrElse('totalCost', purchaseOrder, 0))}</div>
		</div>
	);
};

export default PurchaseOrderRowDisplay;

import { EQUIPMENT_TYPES_CRUD_ACTION_CREATORS } from '../actions/equipment_types_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import FetchUtils, { parseJSON } from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { logoutSuccess } from '../actions/session_actions';
import { message } from 'antd';

export const equipmentTypesRestCrudThunksForBuyer = new RestCrudThunks(
	'equipments',
	'equipment',
	'equipment',
	'equipments',
	EQUIPMENT_TYPES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'inventory/'
);

export const equipmentTypesRestCrudThunksForSupplier = new RestCrudThunks(
	'equipments',
	'equipment',
	'equipment',
	'equipments',
	EQUIPMENT_TYPES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'inventory/'
);

const fetchUtils = new FetchUtils();
export const fetchEquipmentsWithStockLocation =
	(roleType) =>
	(
		stockLocationId,
		params,
		targetCollectionName = null,
		pagination = null,
		sorting = null,
		filters = null,
		addToTargetCollection = false
	) => {
		// if pagination present, transform into limit/offset format and add to query params
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/inventory/equipments/with_stock_location_id/${stockLocationId}`;
		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(EQUIPMENT_TYPES_CRUD_ACTION_CREATORS.fetchStart(targetCollectionName));

				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(EQUIPMENT_TYPES_CRUD_ACTION_CREATORS.fetchError(d.message, d.data));
							reject(d.message);
						} else {
							dispatch(
								EQUIPMENT_TYPES_CRUD_ACTION_CREATORS.fetchSuccess(
									d.data,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters,
									addToTargetCollection
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(EQUIPMENT_TYPES_CRUD_ACTION_CREATORS.fetchError(d.message, d.data));
						reject(d.message);
					});
			});
		};
	};

export const fetchEquipmentsWithStockLocationForSupplier = fetchEquipmentsWithStockLocation(
	ROLE_TYPES.SUPPLIER
);

const fetchAllEquipment =
	(roleType) =>
	(params, sorting = null) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/inventory/all_equipment`;

		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.data);
						} else {
							resolve(d.data);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};

export const fetchAllEquipmentForSupplier = fetchAllEquipment(ROLE_TYPES.SUPPLIER);

const downloadEquipmentTypesCSV =
	(roleType) =>
	(params, filters = null) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/inventory/equipments/csv_report_generator`;

		// if sorting present, fix order naming and add to query params

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.message);
							message.error(d.data);
						} else {
							resolve(d.data);
							const hide = message.loading(d.data, 0);
							setTimeout(hide, 7000);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};

export const downloadEquipmentTypesCSVForSupplier = downloadEquipmentTypesCSV(ROLE_TYPES.SUPPLIER);

export const CREATED_AT_NEWEST = {
	label: 'Newest',
	selectVal: 'latest',
	value: {
		sort_by: 'createdAt',
		order: 'desc',
	},
};
export const CREATED_AT_OLDEST = {
	label: 'Oldest',
	selectVal: 'oldest',
	value: {
		sort_by: 'createdAt',
		order: 'ascend',
	},
};
export const PRIORITY = {
	label: 'Priority',
	selectVal: 'priority',
	value: {
		sort_by: 'woPriority',
		order: 'ascend',
	},
};

export const LOCATION_NAME = {
	label: 'Location',
	selectVal: 'locationName',
	value: {
		sort_by: 'locationName',
		order: 'ascend',
	},
};

//Defaults
export const WORK_ORDER_SORTERS = [CREATED_AT_NEWEST, CREATED_AT_OLDEST, PRIORITY, LOCATION_NAME];

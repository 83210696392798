import React, { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FlatfileImporter } from '@flatfile/react';
import { FLAT_FILE_LICENSE_KEY } from '../../utils/DataConstants';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import {
	getReceivedEquipmentFlatfileHeader,
	getFlatfileRecordForReceivedEquipment,
} from './stock_location_constants';

const EditReceivedEquipmentBulk: FC<any> = ({
	currentUser,
	epsls,
	onSuccess,
	onDataUpload,
	allEquipmentTypesNames,
}): React.ReactElement => {
	const [init, setInit] = useState(false);

	useEffect(() => {
		setInit(epsls.length > 0 && allEquipmentTypesNames.length > 0);
	}, [allEquipmentTypesNames.length, epsls.length]);

	const importer = useMemo(
		() =>
			new FlatfileImporter(FLAT_FILE_LICENSE_KEY, {
				type: 'Import type',
				fields: getReceivedEquipmentFlatfileHeader(allEquipmentTypesNames),
			}),
		[]
	);

	importer.setCustomer({
		companyId: nullSafeGet('facility.buyerCompanyId', currentUser),
		companyName: nullSafeGet('facility.name', currentUser),
		userId: currentUser.email,
	});

	useEffect(() => {
		init &&
			importer
				.requestDataFromUser({
					source: epsls.map((_) => ({
						data: getFlatfileRecordForReceivedEquipment(_),
					})),
				})
				.then((results) => {
					onDataUpload(results)
						.then(() => {
							importer.displaySuccess('Equipment updated Successfully');
							onSuccess && onSuccess();
						})
						.catch((err) => importer.displayError(err));
					importer.displayLoader('Updating Equipment.');
				});
	}, [importer, onDataUpload, onSuccess, epsls, init]);

	return <></>;
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

export default withRouter<any, any>(connect(mapStateToProps)(EditReceivedEquipmentBulk));

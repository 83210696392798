import React, { FC } from 'react';
import { connect } from 'react-redux';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { SUPPLIER_TECH } from '../roles/roles';

export const canFieldManageStockLocation = (currentUser, stockLocation) => {
	const email = currentUser.email;
	const allEmails = nullSafeGetOrElse('canManageFieldTechEmailsString', stockLocation, '')
		.split(',')
		.filter((_) => !!_);

	const canUserManageCurrentSL = allEmails.includes(email);

	const isFieldTech = nullSafeGetOrElse('roles', currentUser, []).some((_) => _ === SUPPLIER_TECH);
	return isFieldTech
		? currentUser.canManageAllStockLocations
			? true
			: canUserManageCurrentSL
		: true;
};

const FeildTechSLManagePermissionChecker: FC<any> = ({
	stockLocation,
	currentUser,
	children,
}): React.ReactElement => {
	return canFieldManageStockLocation(currentUser, stockLocation) ? children : null;
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
	stockLocation: state.stock_locations.detail,
});

const ComponentWithoutUserType = connect(mapStateToProps)(FeildTechSLManagePermissionChecker);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

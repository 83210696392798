import React, { FC } from 'react';
import { Form } from '@ant-design/compatible';
import { AdvancedFilters } from '../../advanced_filters/AdvancedFilters';
import {
	changeFilterValueToArrayOfIds,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../../utils/DataAccessUtils';
import BuyerRegionsLocationsTreeSelect from '../../regions_tree_select/BuyerRegionsLocationsTreeSelect';
import { ROLE_TYPES } from '../../../utils/DataConstants';
import SupplierRegionsLocationsTreeSelect from '../../regions_tree_select/SupplierRegionsLocationsTreeSelect';
import { INVOICES_SORTERS } from '../config/sorters/sorters';

interface FiltersProps {
	filters: any;
	applyFilters: any;
	clearFilters: any;
	targetCollectionName: String;
	filterConfig: any;
	userType: any;
	sorters: any;
	applySorting: any;
	preAppliedFilters: any;
}

const Filters: FC<FiltersProps> = ({
	preAppliedFilters,
	filters,
	applyFilters,
	applySorting,
	clearFilters,
	targetCollectionName,
	filterConfig,
	userType,
	sorters,
}): React.ReactElement => {
	const LocationComponent =
		ROLE_TYPES.SUPPLIER === userType
			? SupplierRegionsLocationsTreeSelect
			: BuyerRegionsLocationsTreeSelect;
	return (
		<AdvancedFilters
			updateFilters={applyFilters}
			filterConfig={filterConfig}
			filtersTargetCollectionName={targetCollectionName}
			clearAndUpdateFilters={clearFilters}
			initialFilters={filters}
			filtersStateSlice={filters}
			applySorting={applySorting}
			sorters={sorters}
			sorterItems={INVOICES_SORTERS}
			preAppliedFilters={preAppliedFilters}
		>
			<Form.Item label="Location">
				<LocationComponent
					mode="multiple"
					filtersSlice={filters}
					filtersValueAccessor={(filtersStateSlice) => {
						const locationIds = nullSafeGet(`locationIds`, filtersStateSlice);
						const finalIds = nullSafeGetOrElse(`regionIds`, filtersStateSlice, locationIds);
						return changeFilterValueToArrayOfIds(finalIds);
					}}
					onChange={(ids) => {
						applyFilters({
							locationIds: ids.join(',') || undefined,
							regionIds: undefined,
						});
					}}
					targetCollectionName={'workOrdersAdvancedFilters'}
				/>
			</Form.Item>
		</AdvancedFilters>
	);
};

export default Filters;

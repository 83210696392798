import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Card } from 'antd';
import LocationForm from '../location_form/LocationForm';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;

class NewLocationPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
	}

	render() {
		const { history, userType } = this.props;
		return (
			<Content>
				<LogOnMountWithStandardEventProperties
					eventType={`visited ${userType} new location page`}
				/>
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<Card>
							<div>
								<h5 style={{ marginBottom: 24 }}>New Location</h5>
								<LocationForm
									formData={{}}
									onSuccess={(rec) => history.push(`/${userType}/locations/detail/${rec.id}`)}
								/>
							</div>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(NewLocationPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

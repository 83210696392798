import {
	addWarrantySupplierToTargetCollection,
	SUPPLIER_FACILITIES_CRUD_ACTION_CREATORS,
} from '../actions/supplier_facilities_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { logoutSuccess } from '../actions/session_actions';
import { default as FetchUtils, parseJSON } from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';

export const supplierFacilitiesRestCrudThunksPublic = new RestCrudThunks(
	'supplier_facilities',
	'supplier_facility',
	'supplierFacility',
	'supplierFacilities',
	SUPPLIER_FACILITIES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.PUBLIC
);
export const supplierFacilitiesRestCrudThunksForBuyer = new RestCrudThunks(
	'supplier_facilities',
	'supplier_facility',
	'supplierFacility',
	'supplierFacilities',
	SUPPLIER_FACILITIES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER
);
export const supplierFacilitiesRestCrudThunksForSupplier = new RestCrudThunks(
	'supplier_facilities',
	'supplier_facility',
	'supplierFacility',
	'supplierFacilities',
	SUPPLIER_FACILITIES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER
);

export const addWarrantySupplierToStoreState =
	(supplierId, targetCollectionName) => (dispatch, getState) => {
		return dispatch(addWarrantySupplierToTargetCollection(supplierId, targetCollectionName));
	};

export const supplierFacilitiesNearbyBySpendCategoryPublic = (
	spendCategoryId,
	lat,
	lng,
	targetCollectionName = null,
	params = {},
	pagination = null,
	sorting = null,
	filters = null
) => {
	const fetchUtils = new FetchUtils();
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/public/supplier_facilities`;
	// if pagination present, transform into limit/offset format and add to query params
	if (pagination) {
		const paginationQuery = {
			offset: (pagination.current - 1) * pagination.pageSize,
			limit: pagination.pageSize,
		};
		params = {
			...params,
			...paginationQuery,
		};
	}

	// if sorting present, fix order naming and add to query params
	if (sorting) {
		const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
		params = {
			...params,
			...sortingQuery,
		};
	}

	if (filters) {
		params = {
			...params,
			...filters,
		};
	}
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(
					baseUrl + apiUrl + `/spend_category/${spendCategoryId}/lat_long/${lat},${lng}`,
					Object.assign(
						{
							headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						},
						params
					)
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(
							SUPPLIER_FACILITIES_CRUD_ACTION_CREATORS.fetchError(d.message, targetCollectionName)
						);
						reject(d.message);
					} else {
						dispatch(
							SUPPLIER_FACILITIES_CRUD_ACTION_CREATORS.fetchSuccess(
								d.data,
								d.count,
								targetCollectionName,
								{
									...pagination,
									total: d.count,
								},
								sorting,
								filters
							)
						);
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(
						SUPPLIER_FACILITIES_CRUD_ACTION_CREATORS.fetchError(d.message, targetCollectionName)
					);
					reject(d.message);
				});
		});
	};
};

export const supplierFacilitiesCountBySpendCategoryPublic = (spendCategoryId, lat, lng) => {
	const fetchUtils = new FetchUtils();
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/public/supplier_facilities`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(baseUrl + apiUrl + `/spend_category/${spendCategoryId}/lat_long/${lat},${lng}/count`, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

const fetchUtils = new FetchUtils();
const baseUrl = getBackendUri();

const fetchSupplierFacilitiesInPrivateNetwork =
	(roleType) =>
	(
		params,
		targetCollectionName = null,
		pagination = null,
		sorting = null,
		filters = null,
		addToTargetCollection = false
	) => {
		const apiUrl = `/api/v1/${roleType}/supplier_facilities/private_network`;
		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		} else {
			pagination = { current: 1, pageSize: 1000 };
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(SUPPLIER_FACILITIES_CRUD_ACTION_CREATORS.fetchStart(targetCollectionName));

				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(
								SUPPLIER_FACILITIES_CRUD_ACTION_CREATORS.fetchError(d.message, targetCollectionName)
							);
							reject(d.message);
						} else {
							dispatch(
								SUPPLIER_FACILITIES_CRUD_ACTION_CREATORS.fetchSuccess(
									d.data,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters,
									addToTargetCollection
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(
							SUPPLIER_FACILITIES_CRUD_ACTION_CREATORS.fetchError(d.message, targetCollectionName)
						);
						reject(d.message);
					});
			});
		};
	};

export const fetchSupplierFacilitiesInPrivateNetworkForBuyer =
	fetchSupplierFacilitiesInPrivateNetwork(ROLE_TYPES.BUYER);
export const fetchSupplierFacilitiesInPrivateNetworkForSupplier =
	fetchSupplierFacilitiesInPrivateNetwork(ROLE_TYPES.SUPPLIER);

const fetchSupplierFacilitiesIfEverInPrivateNetwork =
	(roleType) =>
	(
		params,
		targetCollectionName = null,
		pagination = null,
		sorting = null,
		filters = null,
		addToTargetCollection = false
	) => {
		const apiUrl = `/api/v1/${roleType}/supplier_facilities/private_network/ever`;
		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(SUPPLIER_FACILITIES_CRUD_ACTION_CREATORS.fetchStart(targetCollectionName));

				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(
								SUPPLIER_FACILITIES_CRUD_ACTION_CREATORS.fetchError(d.message, targetCollectionName)
							);
							reject(d.message);
						} else {
							dispatch(
								SUPPLIER_FACILITIES_CRUD_ACTION_CREATORS.fetchSuccess(
									d.data,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters,
									addToTargetCollection
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(
							SUPPLIER_FACILITIES_CRUD_ACTION_CREATORS.fetchError(d.message, targetCollectionName)
						);
						reject(d.message);
					});
			});
		};
	};

export const fetchSupplierFacilitiesIfEverInPrivateNetworkForBuyer =
	fetchSupplierFacilitiesIfEverInPrivateNetwork(ROLE_TYPES.BUYER);
export const fetchSupplierFacilitiesIfEverInPrivateNetworkForSupplier =
	fetchSupplierFacilitiesIfEverInPrivateNetwork(ROLE_TYPES.SUPPLIER);

const fetchSupplierFacilitiesIfEverScorecardInPrivateNetwork =
	(roleType) =>
	(
		params,
		targetCollectionName = null,
		pagination = null,
		sorting = null,
		filters = null,
		addToTargetCollection = false
	) => {
		const apiUrl = `/api/v1/${roleType}/supplier_facilities/private_network/ever/with_wo_count`;
		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(SUPPLIER_FACILITIES_CRUD_ACTION_CREATORS.fetchStart(targetCollectionName));

				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(
								SUPPLIER_FACILITIES_CRUD_ACTION_CREATORS.fetchError(d.message, targetCollectionName)
							);
							reject(d.message);
						} else {
							dispatch(
								SUPPLIER_FACILITIES_CRUD_ACTION_CREATORS.fetchSuccess(
									d.data,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters,
									addToTargetCollection
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(
							SUPPLIER_FACILITIES_CRUD_ACTION_CREATORS.fetchError(d.message, targetCollectionName)
						);
						reject(d.message);
					});
			});
		};
	};

export const fetchSupplierFacilitiesIfEverScorecardInPrivateNetworkForBuyer =
	fetchSupplierFacilitiesIfEverScorecardInPrivateNetwork(ROLE_TYPES.BUYER);
export const fetchSupplierFacilitiesIfEverScorecardInPrivateNetworkForSupplier =
	fetchSupplierFacilitiesIfEverScorecardInPrivateNetwork(ROLE_TYPES.SUPPLIER);

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Card } from 'antd';
import SupplierFacilityForm from '../supplier_facility_form/SupplierFacilityForm';
import { supplierFacilitiesRestCrudThunksForSupplier } from '../../thunks/supplier_facilities_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;
const style = require('./SupplierFacilityCreatePage.less');

class SupplierFacilityCreatePage extends React.Component<any, any> {
	constructor(props) {
		super(props);
	}

	render() {
		const { currentUser, supplierFacility } = this.props;
		const supplierCompanyId = currentUser.facility.supplierCompanyId;

		return (
			<Content className="supplierFacilityCreatePage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited supplier new facility page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<Card>
							<div>
								<h5 style={{ marginBottom: 24 }}>Create Facility</h5>
								<SupplierFacilityForm
									formData={{}}
									redirectForwardUrl="/supplier/admin/facilities"
									supplierCompanyId={supplierCompanyId}
								/>
							</div>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	supplierFacilitiesFetching: state.supplier_facilities.fetching,
	supplierFacility: state.supplier_facilities.detail,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	fetchSupplierFacility: (id) => dispatch(supplierFacilitiesRestCrudThunksForSupplier.readOne(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SupplierFacilityCreatePage));

import * as React from 'react';
import { Layout, Row, Col, Card } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import {
	plannedMaintenanceTemplatesRestCrudThunksForBuyer,
	plannedMaintenanceTemplatesRestCrudThunksForSupplier,
} from '../../thunks/planned_maintenance_templates_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { currencyFormatter } from '../../utils/DataFormatterUtils';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import FileUploader from '../file_uploader/FileUploader';

const { Content } = Layout;

class PlannedMaintenanceTemplatesDetailPage extends React.Component<any, any> {
	componentDidMount() {
		const { getPlannedMaintenanceTemplate, match } = this.props;
		const plannedMaintenanceTemplateId = match.params.id;
		getPlannedMaintenanceTemplate(plannedMaintenanceTemplateId);
	}

	render() {
		const { plannedMaintenanceTemplate, plannedMaintenanceTemplatesFetching, userType } =
			this.props;

		return plannedMaintenanceTemplatesFetching ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="plannedMaintenanceTemplatesDetailDetailsPage">
				<LogOnMountWithStandardEventProperties
					eventType={`visited ${userType} pm template detail page`}
				/>
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}

				<Row style={{ margin: '16px 4px' }} gutter={12}>
					<Col span={24}>
						<div className="rowSpacing">
							<Card bodyStyle={{ padding: 0 }}>
								<div className="materialCard__body">
									{plannedMaintenanceTemplate.templateId ? (
										<div className="materialCard__subtitle">
											<div style={{ fontSize: 16, marginBottom: 24 }}>
												Template ID: {plannedMaintenanceTemplate.templateId}
											</div>
										</div>
									) : null}
									<div className="materialCard__primaryTitle">
										<div
											style={{ fontSize: 16 }}
											className="richTextWrapper"
											dangerouslySetInnerHTML={{ __html: plannedMaintenanceTemplate.description }}
										/>
									</div>
									{plannedMaintenanceTemplate.nte ? (
										<div className="materialCard__subtitle">
											<div style={{ fontSize: 16, marginBottom: 24 }}>
												NTE: {currencyFormatter(2)(plannedMaintenanceTemplate.nte)}
											</div>
										</div>
									) : null}
									{plannedMaintenanceTemplate.supplierFacilityId ? (
										<div className="materialCard__subtitle">
											<div style={{ fontSize: 16, marginBottom: 24 }}>
												Assign To: {plannedMaintenanceTemplate.supplierFacility.name}
											</div>
										</div>
									) : null}
									{plannedMaintenanceTemplate.supplierContractId ? (
										<div className="materialCard__subtitle">
											<div style={{ fontSize: 16, marginBottom: 24 }}>
												Contract ID: {plannedMaintenanceTemplate.supplierContractId}
											</div>
										</div>
									) : null}
								</div>
							</Card>
							{nullSafeGet('supplierContractDocuments.0', plannedMaintenanceTemplate) ? (
								<div className="mt-2">
									<Card title="Contract Documents">
										<FileUploader
											roleType={userType}
											defaultFileList={plannedMaintenanceTemplate.supplierContractDocuments || []}
											uploaderType={'readOnly'}
										/>
									</Card>
								</div>
							) : null}
						</div>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	plannedMaintenanceTemplatesFetching: state.planned_maintenance_templates.fetching,
	plannedMaintenanceTemplate: state.planned_maintenance_templates.detail,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getPlannedMaintenanceTemplate: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? plannedMaintenanceTemplatesRestCrudThunksForSupplier.readOne(id)
				: plannedMaintenanceTemplatesRestCrudThunksForBuyer.readOne(id)
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(PlannedMaintenanceTemplatesDetailPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

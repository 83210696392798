import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, message, Select, Button } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { connect } from 'react-redux';
import { signUp } from '../../thunks/session_thunks';
import { SUPPLIER_USER, SUPPLIER_ROLES } from '../roles/roles';
import { getObjectValues } from '../../utils/DataAccessUtils';
import BackButton from '../back_button/BackButton';
import { getHomePageByRole } from '../../utils/EnvConfigUtils';
import { withRouter } from 'react-router';

const style = require('./SupplierSignupForm.less');
const FormItem = Form.Item;
const Option = Select.Option;

interface SignupProps {
	email: string;
	signUp: any;
	loading: boolean;
	username: string;
	history: any;
	password: string;
}

interface SignupFormProps extends FormComponentProps {
	email: string;
	nameGiven: string;
	nameFamily: string;
	signUp: any;
	loading: boolean;
	username: string;
	history: any;
	password: string;
}

interface SignupFormState {
	confirmDirty: boolean;
	autoCompleteResult: string[];
	uploadLoading: boolean;
	verificationEmailSent: boolean;
	newUserEmail: string;
	newUserFirstName: string;
	newUserLastName: string;
}

function getBase64(img, callback) {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
}

const roles = getObjectValues(SUPPLIER_ROLES);

class SupplierSignupForm extends React.Component<SignupFormProps, SignupFormState> {
	state = {
		confirmDirty: false,
		autoCompleteResult: [],
		uploadLoading: false,
		verificationEmailSent: false,
		newUserEmail: '',
		newUserFirstName: '',
		newUserLastName: '',
	};

	handleUploadChange = (info) => {
		if (info.file.status === 'uploading') {
			this.setState({ uploadLoading: true });
			return;
		}
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			getBase64(info.file.originFileObj, (imageUrl) =>
				this.setState({
					uploadLoading: false,
				})
			);
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFieldsAndScroll((err, values) => {
			if (!err) {
				const [nameGiven, nameFamily] = values.fullName.split(' ');
				const userRoles = values.roles || [SUPPLIER_USER];
				this.props
					.signUp(
						values.email.trim(),
						values.password.trim(),
						nameGiven,
						nameFamily,
						values.gender,
						userRoles,
						values.facilityId
					)
					.then((newUser) => {
						this.setState({
							verificationEmailSent: true,
							newUserEmail: newUser.email,
							newUserFirstName: newUser.nameGiven,
							newUserLastName: newUser.nameFamily,
						});
						const homePage = getHomePageByRole('supplier', {});
						this.props.history.push(homePage);
					})
					.catch((err) => {
						message.error(`${err.message}`);
					});
			}
		});
	};

	handleConfirmBlur = (e) => {
		const { form } = this.props;
		const value = e.target.value;
		this.setState({ confirmDirty: this.state.confirmDirty || !!value }, () =>
			form.validateFields(['confirm'], { force: true }, () => {})
		);
	};

	checkPassword = (rule, value, callback) => {
		const { form } = this.props;
		const otherValue = form.getFieldValue('confirm');
		if (value && otherValue && value !== otherValue) {
			callback("The new passwords you entered don't match!");
		} else {
			callback();
		}
	};

	checkConfirm = (rule, value, callback) => {
		const { form } = this.props;
		const { confirmDirty } = this.state;
		const otherValue = form.getFieldValue('password');
		if (value && otherValue && confirmDirty && value !== otherValue) {
			callback("The new passwords you entered don't match!");
		} else {
			callback();
		}
	};

	handleWebsiteChange = (value) => {
		let autoCompleteResult;
		if (!value) {
			autoCompleteResult = [];
		} else {
			autoCompleteResult = ['.com', '.org', '.net'].map((domain) => `${value}${domain}`);
		}
		this.setState({ autoCompleteResult });
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { loading, email, nameGiven, nameFamily } = this.props;
		const {
			autoCompleteResult,
			verificationEmailSent,
			newUserEmail,
			newUserFirstName,
			newUserLastName,
		} = this.state;

		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
				sm: { span: 6 },
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 18 },
			},
		};
		const tailFormItemLayout = {
			wrapperCol: {
				xs: {
					span: 24,
					offset: 0,
				},
				sm: {
					span: 14,
					offset: 6,
				},
			},
		};
		const prefixSelector = getFieldDecorator('prefix', {
			initialValue: '86',
		})(
			<Select style={{ width: 60 }}>
				<Option value="86">+86</Option>
				<Option value="87">+87</Option>
			</Select>
		);

		const websiteOptions: string[] = autoCompleteResult.map((website) => website);

		return (
			<Form hideRequiredMark={true} onSubmit={this.handleSubmit}>
				<FormItem {...formItemLayout} label="Name" hasFeedback>
					{getFieldDecorator('fullName', {
						initialValue:
							(nameGiven || '').concat(nameFamily || '') &&
							(nameGiven || '').concat(' ', nameFamily || ''),
						rules: [
							{
								required: true,
								message: 'Please enter your name!',
							},
							{
								type: 'array',
								min: 2,
								transform(value) {
									if (!value) return [];
									return value.split(' ');
								},
								message: 'Please input your full name!',
							},
						],
					})(<Input disabled={nameGiven || nameFamily ? true : false} />)}
				</FormItem>
				<FormItem {...formItemLayout} label="E-mail" hasFeedback>
					{getFieldDecorator('email', {
						initialValue: email,
						rules: [
							{
								type: 'email',
								message: 'The input is not valid email!',
							},
							{
								required: true,
								message: 'Please input your email!',
							},
						],
					})(<Input disabled={email ? true : false} />)}
				</FormItem>
				<FormItem {...formItemLayout} label="Password">
					{getFieldDecorator('password', {
						rules: [
							{
								required: true,
								message: 'Please input your password!',
							},
						],
					})(<Input type="password" />)}
				</FormItem>
				<FormItem {...tailFormItemLayout}>
					<Button type="primary" loading={loading} size="large" htmlType="submit">
						Register
					</Button>
					<span style={{ marginLeft: 8 }}>
						<BackButton size="large" buttonText="Go back" />
					</span>
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	loading: state.session.loading,
	history: ownProps.history,
	email: ownProps.email,
});

const mapDispatchToProps = (dispatch) => ({
	signUp: (username, password, nameGiven, nameFamily, gender, roles, facilityId) =>
		dispatch(
			signUp('supplier')(username, password, nameGiven, nameFamily, gender, roles, facilityId)
		),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Form.create<SignupFormProps>()(SupplierSignupForm))
);

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	journalHistoriesRestCrudThunksForSupplier,
	rerunJournalHistoryForSupplier,
	rollbackJournalHistoryForSupplier,
} from '../../thunks/journal_histories_thunks';
import { EmptyState } from '../empty_state/EmptyState';
import { getCurrency, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { DATE_ONLY_FORMAT, dateFormatter } from '../../utils/DataFormatterUtils';
import { Button, Card, Popover, Table, message, Layout } from 'antd';
import NBVCardDisplay from './NBVCardDisplay';
import {
	DEPRECIATION_METHODS,
	JOURNAL_DISPLAY_PROPS,
	JOURNAL_STATUSES,
} from '../../utils/DataConstants';
import JournalStatusDisplay from '../depreciation_pages/JournalStatusDisplay';
import { ExportOutlined } from '@ant-design/icons';
import { ExportToCsv } from 'export-to-csv';
import { CSV_EXPORT_DEFAULTS } from '../../utils/DataConstants';
import DepreciationParametersDisplay from './DepreciationParametersDisplay';
import PendingJournalsCardDisplay from './PendingJournalsCardDisplay';
import { JOURNAL_TYPE, addNBVs, getJournalValue } from './depreciation_utils';
import InfoWithKeyValuePair from './InfoWithKeyValuePair';
import moment from 'moment';

const { Content } = Layout;

const LOADING_ACTION = {
	ROLLBACK: 'rollBack',
	RERUN: 'rerun',
};

const renderDate = (date) => (date ? dateFormatter(date, DATE_ONLY_FORMAT) : '--');

const renderJournalType = (val) => {
	switch (val) {
		case JOURNAL_TYPE.IMPAIRMENT:
			return 'Impairment';
		case 'existingDepreciation':
			return 'Existing Depreciation';
		default:
			return 'Depreciation';
	}
};

const renderJournalTypeWithInfo = (val, record) => {
	const labels = {
		[DEPRECIATION_METHODS.DECLINING]: 'Declining Balance',
		[DEPRECIATION_METHODS.STRAIGHT_LINE]: 'Straight Line',
	};

	const details = {
		'Depreciation Method': nullSafeGetOrElse('record.depreciationMethod', labels, '--'),
	};

	return (
		<div className="flex flex-row items-center">
			<div>{renderJournalType(val)}</div>
			<InfoWithKeyValuePair details={details} />
		</div>
	);
};

const SupplierAssetDepreciationJournalPage: FC<any> = ({
	match,
	currentUser,
	rollback,
	rerun,
	fetchJournalHistories,
	asset,
}): React.ReactElement => {
	const currency = useMemo(() => getCurrency({ currentUser }), [currentUser]);
	const [updatingRecord, setUpdatingRecord] = useState<any>({});
	const [journalHistories, setJournalHistories] = useState([]);
	const [loading, setLoading] = useState(false);

	const assetId = useMemo(() => nullSafeGet('params.id', match), [match]);

	const refreshJournalHistories = useCallback(() => {
		setLoading(true);
		fetchJournalHistories({ assetId })
			.then((res) =>
				setJournalHistories(addNBVs(asset.capitalisedCost, res, asset.existingDepreciation))
			)
			.finally(() => setLoading(false));
	}, [asset, assetId, fetchJournalHistories]);

	useEffect(() => {
		refreshJournalHistories();
	}, [refreshJournalHistories]);

	const canRollbackId = useMemo(() => {
		const canRollBackRecords = journalHistories
			.filter((_) => _.entityType === JOURNAL_TYPE.DEPRECIATION)
			.filter(
				(_) =>
					_.status === JOURNAL_STATUSES.COMPLETED || _.status === JOURNAL_STATUSES.RERUN_COMPLETED
			);
		return canRollBackRecords.length > 0 ? canRollBackRecords[0].id : null;
	}, [journalHistories]);

	const canRerunId = useMemo(() => {
		const canRerunRecords = journalHistories
			.filter((_) => _.entityType === JOURNAL_TYPE.DEPRECIATION)
			.filter((_) => _.status === JOURNAL_STATUSES.ROLLBACK_COMPLETED);
		const filterRecordsCount = canRerunRecords.length;
		return filterRecordsCount > 0 ? canRerunRecords[filterRecordsCount - 1].id : null;
	}, [journalHistories]);

	const canRerun = useCallback((record) => record.id === canRerunId, [canRerunId]);

	const canRollback = useCallback((record) => record.id === canRollbackId, [canRollbackId]);

	const onRollBack = useCallback(
		(record) => () => {
			setUpdatingRecord({
				id: record.id,
				action: LOADING_ACTION.ROLLBACK,
			});
			rollback(record)
				.then(() => {
					message.success('Rolled back successfully!');
					refreshJournalHistories();
				})
				.catch(() => message.error('Unable to rollback!'))
				.finally(() => setUpdatingRecord({}));
		},
		[refreshJournalHistories, rollback]
	);

	const onRerun = useCallback(
		(record) => () => {
			setUpdatingRecord({
				id: record.id,
				action: LOADING_ACTION.RERUN,
			});
			rerun(record)
				.then(() => {
					message.success('Rerun completed successfully!');
					refreshJournalHistories();
				})
				.catch(() => message.error('Unable to rerun!'))
				.finally(() => setUpdatingRecord({}));
		},
		[refreshJournalHistories, rerun]
	);

	const isLoading = useCallback(
		(record, checkAction) => {
			const { id, action } = updatingRecord;
			return id === record.id && checkAction === action;
		},
		[updatingRecord]
	);

	const renderCurrencyValue = useCallback(
		(val) => (val ? currency.format(val > 0 ? val : 0) : '__'),
		[currency]
	);

	const renderStatus = useCallback(
		(isText = false) =>
			(status, record) => {
				if (status === JOURNAL_STATUSES.ROLLBACK_COMPLETED) {
					return isText ? (
						nullSafeGetOrElse(`${status}.displayName`, JOURNAL_DISPLAY_PROPS, '--')
					) : (
						<JournalStatusDisplay status={status} />
					);
				} else {
					const propName =
						record.entityType === JOURNAL_TYPE.IMPAIRMENT ? 'impairmentDate' : 'depreciationRunAt';
					const date = nullSafeGet(propName, record);
					return date ? dateFormatter(date, DATE_ONLY_FORMAT) : '--';
				}
			},
		[]
	);

	const columns = useMemo(
		() => [
			{
				title: 'Journal Date',
				dataIndex: 'status',
				render: renderStatus(),
			},
			{
				title: 'Type',
				dataIndex: 'entityType',
				render: renderJournalTypeWithInfo,
			},
			{
				title: 'Value',
				render: (_, record) => (
					<div className="flex flex-row items-center">
						{renderCurrencyValue(getJournalValue(record))}
						{nullSafeGet('depreciationParameters', record) && (
							<DepreciationParametersDisplay record={record.depreciationParameters} />
						)}
					</div>
				),
			},
			{
				title: 'Calculated Net Book Value',
				dataIndex: 'netBookValue',
				render: renderCurrencyValue,
			},
			{
				title: 'Capitalised cost',
				dataIndex: ['netBookValueParameters', 'capitalisedCost'],
				render: renderCurrencyValue,
			},
			{
				title: 'Calculated At',
				dataIndex: 'updatedAt',
				render: renderDate,
			},
			{
				render: (_, record) => {
					return record.entityType === JOURNAL_TYPE.DEPRECIATION ? (
						<div>
							<Popover
								content={canRollback(record) ? '' : 'You can rollback only last completed journal!'}
								trigger="hover"
							>
								<Button
									loading={isLoading(record, LOADING_ACTION.ROLLBACK)}
									disabled={!canRollback(record)}
									type="ghost"
									onClick={onRollBack(record)}
								>
									Rollback
								</Button>
							</Popover>
							<Popover
								content={
									canRerun(record) ? '' : 'You can calculate only recently rolled back journal!'
								}
								trigger="hover"
							>
								<Button
									loading={isLoading(record, LOADING_ACTION.RERUN)}
									disabled={!canRerun(record)}
									className="ml-2"
									type="primary"
									onClick={onRerun(record)}
								>
									Calculate
								</Button>
							</Popover>
						</div>
					) : null;
				},
			},
		],
		[canRerun, canRollback, isLoading, onRerun, onRollBack, renderCurrencyValue, renderStatus]
	);

	const csvTitle = useMemo(() => {
		const date = moment().format(DATE_ONLY_FORMAT);
		return `${nullSafeGetOrElse('name', asset, '')}_depreciation_journal_${date}`;
	}, [asset]);

	const onExportHistory = useCallback(() => {
		const csvExporter = new ExportToCsv({
			...CSV_EXPORT_DEFAULTS,
			filename: csvTitle,
			title: csvTitle,
			headers: columns.map((_) => _.title).filter((_) => !!_),
		});
		csvExporter.generateCsv(
			journalHistories.map((_) => ({
				status: renderStatus(true)(_.status, _),
				type: renderJournalType(_.entityType),
				depreciationValue: renderCurrencyValue(getJournalValue(_)),
				netBookValue: renderCurrencyValue(_.netBookValue),
				calculatedAt: renderDate(_),
			}))
		);
	}, [columns, csvTitle, journalHistories, renderCurrencyValue, renderStatus]);

	return (
		<Content style={{ padding: '0 0.5em' }}>
			<PendingJournalsCardDisplay onSuccess={refreshJournalHistories} />
			<NBVCardDisplay rerender={nullSafeGet('id', updatingRecord) || !loading} />
			<Card
				title="Journal History"
				loading={loading}
				extra={
					!loading && (
						<Button icon={<ExportOutlined translate="" />} type="ghost" onClick={onExportHistory}>
							Export
						</Button>
					)
				}
				style={{ marginTop: 16 }}
			>
				{!loading && journalHistories.length > 0 ? (
					<Table loading={loading} dataSource={journalHistories} columns={columns} />
				) : (
					<EmptyState
						graphic={
							<img
								alt="No Data"
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
							/>
						}
						headline={'No Journal history found'}
						body={null}
					/>
				)}
			</Card>
		</Content>
	);
};

const mapStateToProps = (state) => ({
	asset: state.assets.detail,
	journalHistories: state.journal_histories,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	fetchJournalHistories: (params) =>
		dispatch(journalHistoriesRestCrudThunksForSupplier.read(params)),
	rollback: (entity) => dispatch(rollbackJournalHistoryForSupplier(entity)),
	rerun: (entity) => dispatch(rerunJournalHistoryForSupplier(entity)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(SupplierAssetDepreciationJournalPage)
);

import React, { FC, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router';
import { Layout } from 'antd';
import ScrollToTopOnMount from '../scroll_to_top_on_mount/ScrollToTopOnMount';
import SubnavTabs from '../subnav_tabs/SubnavTabs';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { PERMISSION_NAMES, isUserAllowedToAccess } from '../../utils/AuthUtils';
import { ROLE_TYPES } from '../../utils/DataConstants';
import BulkTransferAssetsButton from './BulkTransferAssetsButton';
import {
	assetsRestCrudThunksForBuyer,
	assetsRestCrudThunksForSupplier,
} from '../../thunks/assets_thunks';

const { Content } = Layout;
require('./AssetsOverviewPage.less');

const ASSETS_OVERVIEW_TARGET_COLLECTION = 'assetsIndex';

const AssetsOverviewPage: FC<any> = ({
	history,
	currentUser,
	companyConfig,
	childRoutes,
	userType,
	fetchAssets,
	assets,
}): React.ReactElement => {
	const handleTabChange = useCallback(
		(key) => history.push(`/${userType}/assets/overview/${key}`),
		[history, userType]
	);

	const checkTabActive = useCallback(
		(key) => {
			const pathname = history.location.pathname;
			return pathname.indexOf(key) !== -1;
		},
		[history.location.pathname]
	);

	const templatesVisible = useMemo(
		() =>
			isUserAllowedToAccess(currentUser.userType)(
				PERMISSION_NAMES.MODIFY_ASSET_TYPES,
				companyConfig,
				currentUser.roles
			),
		[companyConfig, currentUser.roles, currentUser.userType]
	);

	const tabs = useMemo(
		() => [
			{
				key: 'all',
				name: 'All',
			},
			...(templatesVisible
				? [
						{
							key: 'templates/overview',
							name: 'Types',
						},
				  ]
				: []),
			{
				key: 'downtime',
				name: 'Downtime',
			},
		],
		[templatesVisible]
	);

	const getStatSliceProp = useCallback(
		(propName) => nullSafeGetOrElse(`${ASSETS_OVERVIEW_TARGET_COLLECTION}.${propName}`, assets, {}),
		[assets]
	);

	const refreshAssets = useCallback(() => {
		fetchAssets(
			null,
			ASSETS_OVERVIEW_TARGET_COLLECTION,
			getStatSliceProp('pagination'),
			getStatSliceProp('sorting'),
			getStatSliceProp('filters')
		);
	}, [fetchAssets, getStatSliceProp]);

	return (
		<div>
			<ScrollToTopOnMount />
			<div>
				<Route
					path={`/${userType}/assets/overview`}
					exact={true}
					render={(props) => (
						<Redirect
							to={{
								pathname: `/${userType}/assets/overview/all`,
							}}
						/>
					)}
				/>
				<Content className="assetsPage" style={{ padding: '0 0.5em' }}>
					<div
						style={{
							margin: '0 -8px',
							padding: '24px',
							paddingBottom: 0,
							background: nullSafeGet('config.theme.navblock', companyConfig),
							marginBottom: 8,
						}}
					>
						<div className="flex flex-row items-center justify-between">
							<h4
								style={{
									color: 'rgba(255, 255, 255, 0.9)',
									marginRight: 8,
									marginBottom: 24,
								}}
							>
								Assets
							</h4>
							{userType === ROLE_TYPES.SUPPLIER ? (
								<BulkTransferAssetsButton onSuccess={refreshAssets} />
							) : null}
						</div>
						<SubnavTabs tabs={tabs} onChange={handleTabChange} isActive={checkTabActive} />
					</div>
					{childRoutes.map((r, i) => (
						<RouteWithSubRoutes key={i} {...r} />
					))}
				</Content>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	companyConfig: state.company_config.detail,
	currentUser: state.session.currentUser,
	assets: state.assets,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchAssets: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetsRestCrudThunksForSupplier.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: assetsRestCrudThunksForBuyer.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(AssetsOverviewPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import { BIDDERS_CRUD_ACTION_CREATORS } from '../actions/bidders_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { getBackendUri } from '../utils/EnvConfigUtils';
import FetchUtils from './fetch_utils';
import { APPROVAL_RULES_CRUD_ACTION_CREATORS } from '../actions/company_approval_actions';
import { message } from 'antd';

const BASE_URL = getBackendUri();
const fetchUtils = new FetchUtils();

export const biddersRestCrudThunksForBuyer = new RestCrudThunks(
	'bidders',
	'bidder',
	'bidder',
	'bidders',
	BIDDERS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'quote/'
);
export const biddersRestCrudThunksForSupplier = new RestCrudThunks(
	'bidders',
	'bidder',
	'bidder',
	'bidders',
	BIDDERS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'quote/'
);

export const changeBidderAwardStatus = (roleType) => (rfpId, supplierFacilityId, status) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/quote/bidders`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.patch(
					baseUrl + apiUrl + `/${rfpId}/${supplierFacilityId}/${status}`,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					})
				)
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const changeBidderAwardStatusForBuyer = changeBidderAwardStatus(ROLE_TYPES.BUYER);
export const changeBidderAwardStatusForSupplier = changeBidderAwardStatus(ROLE_TYPES.SUPPLIER);

export const readBidders =
	(roleType) =>
	(
		rpfId,
		params = {},
		targetCollectionName = null,
		pagination = null,
		sorting = null,
		filters = null
	) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/quote/bidders`;
		// if pagination present, transform into limit/offset format and add to query params
		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(BIDDERS_CRUD_ACTION_CREATORS.fetchStart(targetCollectionName));
				fetchUtils
					.get(
						baseUrl + apiUrl + `?requestForProposalId=${rpfId}`,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then((response) => response.json())
					.then((d) => {
						if (d.status === 'error') {
							dispatch(BIDDERS_CRUD_ACTION_CREATORS.fetchError(d.message, targetCollectionName));
							reject(d.message);
						} else {
							dispatch(
								BIDDERS_CRUD_ACTION_CREATORS.fetchSuccess(
									d.data.map((el) => ({
										...el,
										supplierFacilityId: el.supplierFacility.id,
									})),
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(BIDDERS_CRUD_ACTION_CREATORS.fetchError(d.message, targetCollectionName));
						reject(d.message);
					});
			});
		};
	};

export const readBiddersForBuyer = readBidders(ROLE_TYPES.BUYER);
export const readBiddersForSupplier = readBidders(ROLE_TYPES.SUPPLIER);

export const remindBiddersToMakeProposal = (userRole) => (id, supplierFacilityId) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(APPROVAL_RULES_CRUD_ACTION_CREATORS.fetchStart());

			fetchUtils
				.post(
					BASE_URL +
						`/api/v1/${userRole}/quote/requests_for_proposal/${id}/supplier_facility_reminder/${supplierFacilityId}`,
					{}
				)
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(APPROVAL_RULES_CRUD_ACTION_CREATORS.fetchError([d.message], ''));
						message.error('Reminder Failed.');
						reject(d.message);
					} else {
						message.success('Reminder Sent.');
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(APPROVAL_RULES_CRUD_ACTION_CREATORS.fetchError([d.message], ''));
					reject(d.message);
				});
		});
	};
};

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Form, Input, InputNumber, Select, Spin } from 'antd';
import {
	getCurrency,
	getObjectValues,
	isToAssetPOLineItem,
	nullSafeGet,
	roundOffToDecimal,
} from '../../utils/DataAccessUtils';
import { capitalize } from '../../utils/DataFormatterUtils';
import { DownCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import TransferToLocationFormFields from '../stock_locations_detail_details_page/TransferToLocationFormFields';
import { fetchSupplierCurrencyExchangeRateByIdForSupplier } from '../../thunks/supplier_exchange_rates_thunks';
import { isSupplierInventoryConfigEnabled } from '../common/SupplierInventoryConfigPermissionChecker';
import { CONFIG_NAMES } from '../../utils/AuthUtils';

const ReceiptFormFields: FC<any> = ({
	maxValue,
	currencies,
	currentUser,
	isReturn,
	form,
	disableQuantity,
	showAdvancedOptions,
	existingUnitCost,
	lineItem,
	fetchExchangeRateById,
	companyConfig,
	defaultCurrencyId,
}): React.ReactElement => {
	const currencyRecordsList = getObjectValues(currencies.records);
	const currencyRecordsMap = currencies.records;
	const currency = getCurrency({ currentUser });

	const [fetchingMonthlyExchangeRate, setFetchingMonthlyExchangeRate] = useState(false);

	const [additionalFieldsVisible, setAdditionalFieldsVisible] = useState(false);

	const toggleAdditionalFields = useCallback(() => setAdditionalFieldsVisible((_) => !_), []);

	const { setFieldsValue, getFieldValue } = form;

	const exchangeRate = Form.useWatch('exchangeRate', form);
	const invoiceTotal = Form.useWatch('invoiceTotal', form);
	const invoiceCurrencyId = Form.useWatch('invoiceCurrency', form);
	const quantity = Form.useWatch('quantity', form);

	const allInvoiceFieldsEntered = useMemo(
		() => exchangeRate && invoiceTotal && quantity,
		[exchangeRate, invoiceTotal, quantity]
	);

	useEffect(() => {
		!!quantity &&
			!!existingUnitCost &&
			setFieldsValue({
				invoiceTotal: roundOffToDecimal(existingUnitCost * quantity),
			});
	}, [existingUnitCost, quantity, setFieldsValue]);

	useEffect(() => {
		!!invoiceTotal &&
			!!quantity &&
			setFieldsValue({
				price: roundOffToDecimal(invoiceTotal / Math.abs(quantity)),
			});
	}, [invoiceTotal, quantity, setFieldsValue]);

	useEffect(() => {
		allInvoiceFieldsEntered &&
			setFieldsValue({
				totalPrice: Math.abs(roundOffToDecimal(exchangeRate * invoiceTotal)),
				unitPrice: Math.abs(roundOffToDecimal((exchangeRate * invoiceTotal) / quantity)),
			});
	}, [allInvoiceFieldsEntered, exchangeRate, invoiceTotal, quantity, setFieldsValue]);

	const showExchangeRate = useMemo(
		() => !!invoiceCurrencyId && invoiceCurrencyId !== nullSafeGet('id', currency),
		[currency, invoiceCurrencyId]
	);

	const exchangeRateEnabled = useMemo(
		() =>
			isSupplierInventoryConfigEnabled(
				companyConfig,
				CONFIG_NAMES.ALLOW_MONTHLY_CURRENCY_EXCHANGE_RATE
			),
		[companyConfig]
	);

	useEffect(() => {
		if (exchangeRateEnabled && showExchangeRate && !getFieldValue('exchangeRate')) {
			setFetchingMonthlyExchangeRate(true);
			fetchExchangeRateById(invoiceCurrencyId)
				.then((res) => {
					setFieldsValue({ exchangeRate: res.exchangeRate });
				})
				.finally(() => setFetchingMonthlyExchangeRate(false));
		}
	}, [
		exchangeRate,
		exchangeRateEnabled,
		fetchExchangeRateById,
		getFieldValue,
		invoiceCurrencyId,
		setFieldsValue,
		showExchangeRate,
	]);

	const canGetAssetDetails = useMemo(() => isToAssetPOLineItem(lineItem), [lineItem]);

	const initialAssetTypeId = useMemo(
		() => nullSafeGet('equipmentType.assetTypeId', lineItem),
		[lineItem]
	);

	const userCurrencyId = useMemo(
		() => nullSafeGet('id', getCurrency({ currentUser })),
		[currentUser]
	);

	const enableCurrencyChoosing = useMemo(
		() => !!defaultCurrencyId && !!userCurrencyId && defaultCurrencyId !== userCurrencyId,
		[defaultCurrencyId, userCurrencyId]
	);

	const currencyOptions = useMemo(() => [defaultCurrencyId], [defaultCurrencyId]);

	const advancedOptions = useMemo(
		() => (
			<>
				<Form.Item name="invoiceNumber" label="Invoice Number">
					<Input style={{ width: '100%' }} />
				</Form.Item>
				<Form.Item name="invoiceCurrency" hidden={!enableCurrencyChoosing} label="Invoice Currency">
					<Select
						className="w-full"
						showSearch={true}
						optionFilterProp="children"
						filterOption={(input, option) =>
							currencyRecordsMap[option.props.value].displayNamePlural
								.toLowerCase()
								.indexOf(input.toLowerCase()) >= 0
						}
						placeholder="Currency"
					>
						{currencyRecordsList
							.filter((_) => currencyOptions.includes(_.id))
							.map((currency) => (
								<Select.Option key={currency.id} value={currency.id}>
									{capitalize(currency.displayNamePlural)} ({currency.abbreviation})
								</Select.Option>
							))}
					</Select>
				</Form.Item>
				<Form.Item name="invoiceTotal" label="Invoice Line Total">
					<InputNumber
						style={{ width: '100%' }}
						step={0.01}
						addonAfter={invoiceCurrencyId || null}
					/>
				</Form.Item>
				<Form.Item name="price" label="Unit Cost">
					<InputNumber
						disabled
						step={0.01}
						style={{ width: '100%' }}
						addonAfter={invoiceCurrencyId || null}
					/>
				</Form.Item>
				{showExchangeRate && (
					<>
						<Form.Item
							name="exchangeRate"
							label={
								<div className="flex flex-row items-center">
									Exchange Rate
									{fetchingMonthlyExchangeRate ? (
										<div className="ml-2">
											<Spin size="small" />
										</div>
									) : null}
								</div>
							}
						>
							<InputNumber step={0.01} style={{ width: '100%' }} />
						</Form.Item>
						<Form.Item name="totalPrice" label={`Total Cost (In ${currency.id})`}>
							<InputNumber disabled style={{ width: '100%' }} addonAfter={currency.id} />
						</Form.Item>
						<Form.Item name="unitPrice" label={`Unit Cost (In ${currency.id})`}>
							<InputNumber disabled style={{ width: '100%' }} addonAfter={currency.id} />
						</Form.Item>
					</>
				)}
			</>
		),
		[
			currency.id,
			currencyOptions,
			currencyRecordsList,
			currencyRecordsMap,
			enableCurrencyChoosing,
			fetchingMonthlyExchangeRate,
			invoiceCurrencyId,
			showExchangeRate,
		]
	);

	return (
		<>
			<Form.Item
				name="quantity"
				label="Quantity"
				rules={[
					{
						type: 'number',
						...(!disableQuantity && { min: 1 }),
						...(!disableQuantity && { max: maxValue }),
						...(!disableQuantity && {
							message: `You are trying to enter more than the ${
								isReturn ? 'received' : 'receivable'
							} quantity - ${maxValue}`,
						}),
						...(!disableQuantity && { warningOnly: !isReturn }),
					},
				]}
			>
				<InputNumber disabled={disableQuantity} style={{ width: '100%' }} min="1" />
			</Form.Item>
			{canGetAssetDetails && !isReturn ? (
				<TransferToLocationFormFields
					equipmentTypeModelName={nullSafeGet('equipmentType.modelName', lineItem)}
					form={form}
					initialAssetTypeId={initialAssetTypeId}
					hideAdditionalDetails
				/>
			) : null}

			{showAdvancedOptions ? (
				<div className="flex flex-col">
					<Button
						style={{
							alignSelf: 'flex-end',
							padding: 0,
						}}
						icon={
							additionalFieldsVisible ? (
								<MinusCircleOutlined translate="" />
							) : (
								<DownCircleOutlined translate="" />
							)
						}
						onClick={toggleAdditionalFields}
						type="link"
					>
						{additionalFieldsVisible ? 'Hide' : 'Additional Details'}
					</Button>
					{additionalFieldsVisible ? advancedOptions : null}
				</div>
			) : (
				advancedOptions
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
	currencies: state.currencies,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	fetchExchangeRateById: (id) => dispatch(fetchSupplierCurrencyExchangeRateByIdForSupplier(id)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(ReceiptFormFields)
);

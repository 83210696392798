import { ASSET_TYPES_CRUD_ACTION_CREATORS } from '../actions/asset_types_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import FetchUtils, { parseJSON } from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { logoutSuccess } from '../actions/session_actions';
import { message } from 'antd';

const fetchUtils = new FetchUtils();

export const assetTypesRestCrudThunksForBuyer = new RestCrudThunks(
	'asset_types',
	'asset_type',
	'assetType',
	'assetTypes',
	ASSET_TYPES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'asset/'
);
export const assetTypesRestCrudThunksForSupplier = new RestCrudThunks(
	'asset_types',
	'asset_type',
	'assetType',
	'assetTypes',
	ASSET_TYPES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'asset/'
);

export const fetchAssetTypesLite =
	(roleType) =>
	(
		params,
		targetCollectionName = null,
		pagination = null,
		sorting = null,
		filters = null,
		addToTargetCollection = false
	) => {
		// if pagination present, transform into limit/offset format and add to query params
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/asset/asset_types/lite`;

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(ASSET_TYPES_CRUD_ACTION_CREATORS.fetchStart(targetCollectionName));

				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(ASSET_TYPES_CRUD_ACTION_CREATORS.fetchError(d.message, d.data));
							reject(d.message);
						} else {
							dispatch(
								ASSET_TYPES_CRUD_ACTION_CREATORS.fetchSuccess(
									d.data,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters,
									addToTargetCollection
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(ASSET_TYPES_CRUD_ACTION_CREATORS.fetchError(d.message, d.data));
						reject(d.message);
					});
			});
		};
	};

export const fetchAssetTypesLiteForSupplier = fetchAssetTypesLite(ROLE_TYPES.SUPPLIER);
export const fetchAssetTypesLiteForBuyer = fetchAssetTypesLite(ROLE_TYPES.BUYER);

const downloadAssetTypesCSV =
	(roleType) =>
	(params, filters = null) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/asset/asset_types/csv_report_generator`;

		// if sorting present, fix order naming and add to query params

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.message);
							message.error(d.data);
						} else {
							resolve(d.data);
							const hide = message.loading(d.data, 0);
							setTimeout(hide, 7000);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};

export const downloadAssetTypesCSVForBuyer = downloadAssetTypesCSV(ROLE_TYPES.BUYER);
export const downloadAssetTypesCSVForSupplier = downloadAssetTypesCSV(ROLE_TYPES.SUPPLIER);

import { CHANGE_NOTIFICATION_VOLUME, PLAY_NOTIFICATION } from '../actions/sound_effects_actions';
import UISoundEffect from '../utils/SoundEffectsUtils';
const sfx = {
	chat: new UISoundEffect(
		'https://emptor-data-assets.s3.amazonaws.com/audio/chat-notification.wav',
		{ volume: 1 }
	),
};
export default (oldState = { volume: 1 }, action) => {
	Object.freeze(oldState);
	let newState;
	switch (action.type) {
		case PLAY_NOTIFICATION:
			if (Object.keys(sfx).includes(action.notification)) {
				sfx[action.notification].play(oldState.volume);
			}
			return oldState;
		case CHANGE_NOTIFICATION_VOLUME:
			return {
				...oldState,
				volume: action.volume,
			};
		default:
			return oldState;
	}
};

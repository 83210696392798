import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, List, Modal } from 'antd';
import ReactDragListView from 'react-drag-listview';
import { HolderOutlined, MinusCircleOutlined } from '@ant-design/icons';

interface AttentionDashboardEditPopupProps {
	savedFilterViews: any[];
	updateView: (view: any) => any;
	onCancel: () => void;
	refreshDashboard: () => any;
	saveDashboard: (ids: any[]) => any;
}

const AttentionDashboardEditPopup: FC<AttentionDashboardEditPopupProps> = ({
	savedFilterViews,
	onCancel,
	saveDashboard,
}): React.ReactElement => {
	const [views, setViews] = useState([]);

	useEffect(() => {
		setViews(savedFilterViews);
	}, [savedFilterViews]);

	const dragProps = useMemo(
		() => ({
			onDragEnd(fromIndex, toIndex) {
				setViews((views) => {
					const data = [...views];
					const item = data.splice(fromIndex, 1)[0];
					data.splice(toIndex, 0, item);
					return data;
				});
			},
			nodeSelector: '.each-view-row',
			handleSelector: '.widget-drag-handle',
		}),
		[]
	);

	const onSave = useCallback(() => saveDashboard(views.map((_) => _.id)), [views, saveDashboard]);

	const removeView = useCallback(
		(id) => () => setViews((views) => views.filter((_) => _.id !== id)),
		[]
	);

	return (
		<Modal
			visible={true}
			title="Edit Dashboard"
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button type="primary" onClick={onSave} size="large" style={{ marginLeft: '24px' }}>
					Save
				</Button>,
			]}
			onCancel={onCancel}
			closable={true}
		>
			<ReactDragListView {...dragProps}>
				<List
					dataSource={views}
					renderItem={(view) => (
						<List.Item className="each-view-row" key={view.id}>
							<div>
								<HolderOutlined
									className="widget-drag-handle"
									style={{ cursor: 'pointer' }}
									translate=""
								/>
								<span style={{ marginLeft: '12px' }}>{view.name}</span>
							</div>
							<MinusCircleOutlined
								onClick={removeView(view.id)}
								style={{ marginLeft: 8 }}
								translate=""
							/>
						</List.Item>
					)}
				/>
			</ReactDragListView>
		</Modal>
	);
};

export default AttentionDashboardEditPopup;

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Dropdown, Menu, Spin } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import {
	downloadWalkthroughCSVForBuyer,
	downloadWalkthroughScoreCSVForBuyer,
	walkthroughsRestCrudThunksForBuyer,
} from '../../thunks/walkthroughs_thunks';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import PrintableWalkthrough from '../walkthroughs_detail_details_page/PrintableWalkthrough';
import queryString from 'querystring';
import { goBackLink } from '../../utils/HistoryUtils';

require('./WalkthroughsDetailPage.less');

const WalkthroughsDetailPage: FC<any> = ({
	getWalkthrough,
	match,
	walkthroughsFetching,
	history,
	location,
	walkthrough,
	deleteWalkthrough,
	downloadWalkthroughCSV,
	downloadWalkthroughScoreCSV,
	childRoutes,
	companyConfig,
}): React.ReactElement => {
	const [exporting, setExporting] = useState(false);

	const walkthroughId = useMemo(() => match.params.id, [match.params.id]);

	useEffect(() => getWalkthrough(walkthroughId), []);

	const handleTabChange = useCallback(
		(key) => history.push(`/buyer/siteSurveys/walkthrough/detail/${walkthroughId}/${key}`),
		[history, walkthroughId]
	);

	const handleDelete = useCallback(() => {
		deleteWalkthrough(walkthrough).then(() => {
			history.push(
				goBackLink(
					location,
					`/buyer/siteSurveys/detail/${nullSafeGet('siteSurveyId', walkthrough)}/details`
				)
			);
		});
	}, [deleteWalkthrough, history, walkthrough]);

	const checkTabActive = useCallback(
		(key) => {
			const pathname = history.location.pathname;
			return pathname.indexOf(key) !== -1;
		},
		[history.location.pathname]
	);

	const downloadCSV = useCallback(() => {
		downloadWalkthroughCSV(walkthroughId);
	}, [downloadWalkthroughCSV, walkthroughId]);

	const downloadScoreCSV = useCallback(() => {
		downloadWalkthroughScoreCSV(walkthroughId);
	}, [downloadWalkthroughScoreCSV, walkthroughId]);

	const tabs = useMemo(
		() => [
			{
				key: 'details',
				name: 'Overview',
			},
		],
		[]
	);

	const onPrintInitiate = useCallback(() => setExporting(true), []);
	const onPrintStart = useCallback(() => setExporting(false), []);

	const exportMenu = useMemo(
		() => (
			<Menu>
				<Menu.Item onClick={downloadScoreCSV}>Export Score CSV</Menu.Item>
				<Menu.Item onClick={downloadCSV}>Export CSV</Menu.Item>
				<Menu.Item>
					<PrintableWalkthrough
						walkthrough={walkthrough}
						onPrintInitiate={onPrintInitiate}
						onPrintStart={onPrintStart}
					/>
				</Menu.Item>
			</Menu>
		),
		[downloadCSV, downloadScoreCSV, onPrintInitiate, onPrintStart, walkthrough]
	);

	const backLinkTo = useMemo(() => {
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		return queryParams.backlinkParams
			? `/buyer/siteSurveys/detail/${nullSafeGet(
					'siteSurvey.id',
					walkthrough
			  )}/details?${nullSafeGet('backlinkParams', queryParams)}`
			: `/buyer/siteSurveys/detail/${nullSafeGet('siteSurvey.id', walkthrough)}/details`;
	}, [walkthrough]);

	return (
		<div className="walkthroughsDetailPage">
			<DetailPageHeader
				exactPath={'/buyer/siteSurveys/walkthrough/detail/:id'}
				redirectPath={`/buyer/siteSurveys/walkthrough/detail/${walkthroughId}/details`}
				backLinkText={'Back to site survey'}
				backLinkTo={backLinkTo}
				title={walkthroughsFetching ? null : walkthrough.title}
				subtitle={
					<div className="walkthroughsDetailPage__subtitleRow">
						<div className="walkthroughsDetailPage__subtitle">
							{nullSafeGet('location.name', walkthrough)}
						</div>
					</div>
				}
				actions={[
					<Dropdown overlay={exportMenu}>
						<Button key={1} style={{ marginRight: 16 }} ghost={true}>
							<div className="flex flex-row items-center">
								<span>Export</span>
								<div className="ml-2">
									<DownOutlined />
								</div>
								{exporting ? (
									<div className="ml-2">
										<Spin
											indicator={<LoadingOutlined style={{ fontSize: 16, color: '#fff' }} spin />}
										/>
									</div>
								) : null}
							</div>
						</Button>
					</Dropdown>,
					<AccessPermissionChecker name={PERMISSION_NAMES.DELETE_WALKTHROUGHS}>
						<Button key={2} ghost={true} onClick={handleDelete}>
							Delete
						</Button>
					</AccessPermissionChecker>,
				]}
				tabs={tabs}
				backgroundColor={nullSafeGet('detail.config.theme.navblock', companyConfig)}
				handleTabChange={handleTabChange}
				checkTabActive={checkTabActive}
				childRoutes={childRoutes}
			/>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => ({
	walkthroughsFetching: state.walkthroughs.fetching,
	walkthrough: state.walkthroughs.detail,
	match: ownProps.match,
	history: ownProps.history,
	companyConfig: state.company_config,
	currentUser: state.session.currentUser,
	loading: state.walkthroughs.fetching,
	location: ownProps.location,
});

const mapDispatchToProps = (dispatch) => ({
	getWalkthrough: (id) => dispatch(walkthroughsRestCrudThunksForBuyer.readOne(id)),
	deleteWalkthrough: (entity) =>
		dispatch(walkthroughsRestCrudThunksForBuyer.delete(entity, 'id', null, true)),
	downloadWalkthroughCSV: (id) => dispatch(downloadWalkthroughCSVForBuyer(id)),
	downloadWalkthroughScoreCSV: (id) => dispatch(downloadWalkthroughScoreCSVForBuyer(id)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(WalkthroughsDetailPage)
);

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Card } from 'antd';
import BuyerContactForm from '../buyer_contact_form/BuyerContactForm';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;
const style = require('./MyBuyerEditProfilePage.less');

class MyBuyerEditProfilePage extends React.Component<any, any> {
	constructor(props) {
		super(props);
	}

	render() {
		const { currentUser } = this.props;
		return (
			<Content className="buyerNewAccountSetupProfilePage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited buyer edit profile page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<Card>
							<div>
								<h4 style={{ fontSize: 24 }}>Edit Profile</h4>
								<BuyerContactForm formData={currentUser} redirectForwardUrl="/buyer/myProfile" />
							</div>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(MyBuyerEditProfilePage)
);

import * as React from 'react';
import { Alert, Button, Col, Menu, Popover, Row } from 'antd';
import TableSelector from '../TableSelector/TableSelector';
import { UndoOutlined } from '@ant-design/icons';

export default function TableSelectionRow({
	records,
	selectItems,
	counts,
	actions,
	pagination,
	updatePagination,
	updating,
	errors,
}) {
	const selectedRowKeys = selectItems.rowSelection.selectedRowKeys;
	const selections = selectItems.selections;
	//Get Buttons with statuses
	let buttons = getActionButtons(actions, selections, updating, <UndoOutlined />);

	let menuItems = () => {
		const items = actions
			.filter((action) =>
				selections.every((selection) =>
					action.statuses.some((statuses) => statuses.includes(selection.status))
				)
			)
			.map((action, i) => ({
				key: action.label,
				label: action.label,
			}));
		return <Menu mode={'inline'} items={items}></Menu>;
	};

	return selections.length === 0 && records.length === 0 ? null : (
		<Row
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				borderBottom: '1px solid #f0f0f0',
			}}
		>
			<Row
				style={{
					display: 'flex',
					alignItems: 'center',
					gap: '8px',
					width: '100%',
					padding: '0px 8px',
				}}
			>
				<TableSelector selectItems={selectItems} records={records} counts={counts}></TableSelector>
				{selectedRowKeys.length > 0 ? (
					<Col
						style={{
							display: 'flex',
							minWidth: '80px',
							gap: '8px',
							marginLeft: '14px',
							alignItems: 'center',
						}}
					>
						<span>{selectedRowKeys.length} selected</span>
						<Button type={'link'} size={'small'} onClick={selectItems.onSelectNone}>
							Clear Selection
						</Button>
					</Col>
				) : null}
				<Col style={{ display: 'flex', gap: '8px' }}>
					{selectedRowKeys.length > 0
						? buttons.length > 0
							? buttons.map((button) => button)
							: null
						: null}
				</Col>
				{errors.map((error, idx) => (
					<Alert style={{ marginLeft: '4px' }} key={idx} message={error} type="error" closable />
				))}
			</Row>
		</Row>
	);
}

function getActionButtons(actions, selections, updating, icon) {
	return actions
		.filter((action) =>
			//Forward Buttons
			selections.every((selection) =>
				action.statuses.some((statuses) => statuses.includes(selection.status))
			)
		)
		.map((action, i) => {
			let requireParams = action.hasOwnProperty('requireParams')
				? action.requireParams.some((param) =>
						selections.some((selection) => selection[param] === undefined)
				  )
				: false;

			return requireParams ? (
				<Popover content={action.disableMessage}>
					<Button
						onClick={action.fn}
						icon={action.hasOwnProperty('icon') ? action.icon : null}
						type={i === 0 ? 'primary' : 'default'}
						loading={updating}
						disabled={requireParams}
					>
						{action.label}
					</Button>
				</Popover>
			) : (
				<Button
					onClick={action.fn}
					icon={action.hasOwnProperty('icon') ? action.icon : null}
					type={i === 0 ? 'primary' : 'default'}
					loading={updating}
					disabled={requireParams}
				>
					{action.label}
				</Button>
			);
		});
}

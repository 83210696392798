import { Button, Modal, Spin, Tree } from 'antd';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import AddRegionPopup from './AddRegionPopup';
import './RegionsOverviewIndexPage.less';
import { EditOutlined } from '@ant-design/icons';
import {
	fetchRegionsWithChildrenForBuyer,
	fetchRegionsWithChildrenForSupplier,
} from '../../thunks/regions_thunks';
import { connect } from 'react-redux';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import EditRegionPopup from './EditRegionPopup';
import {
	locationsRestCrudThunksForBuyer,
	locationsRestCrudThunksForSupplier,
} from '../../thunks/locations_thunks';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { EmptyState } from '../empty_state/EmptyState';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { withRouter } from 'react-router';

const REGIONS_WITH_CHILDREN = 'regionsWithChildrenDropdown';

const addKeys = (regions) =>
	regions &&
	regions.map((region) => ({
		...region,
		key: regions.id,
		children: addKeys(region.children),
	}));

const RegionsOverviewIndexPage: FC<any> = ({
	loading,
	regions,
	fetchRegionsWithHierarchy,
	getLocationsCount,
}): React.ReactElement => {
	const [currentParent, setCurrentParent] = useState(null);
	const [addRegionVisible, setAddRegionVisible] = useState(false);
	const [editingRegion, setEditingRegion] = useState(null);
	const [editRegionVisible, setEditRegionVisible] = useState(false);
	const [fetchingCountForRegionId, setFetchingCountForRegionId] = useState(null);

	const refreshRegions = useCallback(
		() => fetchRegionsWithHierarchy({}, REGIONS_WITH_CHILDREN),
		[fetchRegionsWithHierarchy]
	);

	useEffect(() => {
		refreshRegions();
	}, [refreshRegions]);

	const regionsHierarchy = useMemo(
		() => getRecordsForTargetCollection(regions, REGIONS_WITH_CHILDREN),
		[regions]
	);

	const showAddRootLevelRegion = useCallback(() => {
		setCurrentParent(null);
		setAddRegionVisible(true);
	}, []);

	const hideAddRegion = useCallback(() => setAddRegionVisible(false), []);

	const showEditRegion = useCallback(
		(region) => () => {
			setEditingRegion(region);
			setEditRegionVisible(true);
		},
		[]
	);

	const hideEditRegion = useCallback(() => setEditRegionVisible(false), []);

	const onRegionAddSuccess = useCallback(() => {
		hideAddRegion();
		refreshRegions();
	}, [hideAddRegion, refreshRegions]);

	const onRegionEditSuccess = useCallback(() => {
		hideEditRegion();
		refreshRegions();
	}, [hideEditRegion, refreshRegions]);

	const getCount = useCallback(
		(region) => () => {
			setFetchingCountForRegionId(region.id);
			setCurrentParent(region);
			getLocationsCount(region.id)
				.then((res) => {
					if (res) {
						Modal.error({
							title: `Cannot add child to '${nullSafeGetOrElse('name', region, 'this region')}'!`,
							content: `${
								res > 1 ? `There are ${res} locations` : `There is a location`
							} associated with '${nullSafeGetOrElse(
								'name',
								region,
								'this region'
							)}'. Please remove association to proceed.`,
						});
					} else {
						setAddRegionVisible(true);
					}
				})
				.finally(() => setFetchingCountForRegionId(null));
		},
		[getLocationsCount]
	);

	const renderTitle = useCallback(
		(nodeData) => (
			<div className="flex flex-row items-baseline pb-4">
				<div className="flex flex-row items-center">
					<div className="text-lg">{nodeData.name}</div>
					<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_LOCATIONS}>
						<EditOutlined translate="" className="ml-2" onClick={showEditRegion(nodeData)} />
					</AccessPermissionChecker>
				</div>
				<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_LOCATIONS}>
					<Button
						size="small"
						type="link"
						className="ml-2"
						loading={nodeData.id === fetchingCountForRegionId}
						onClick={getCount(nodeData)}
					>
						Add child
					</Button>
				</AccessPermissionChecker>
			</div>
		),
		[fetchingCountForRegionId, showEditRegion, getCount]
	);

	return loading ? (
		<div className="flex flex-row items-center justify-center ">
			<Spin />
		</div>
	) : (
		<div id="regions-tree-view" className="m-4 mt-6 flex flex-col">
			<div className="mb-4 flex flex-row justify-end">
				<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_LOCATIONS}>
					<Button type="primary" onClick={showAddRootLevelRegion}>
						Add Region
					</Button>
				</AccessPermissionChecker>
			</div>
			{regionsHierarchy && regionsHierarchy.length > 0 ? (
				<Tree
					rootClassName="draggable-tree pt-4"
					defaultExpandAll
					showLine={{ showLeafIcon: false }}
					selectable={false}
					treeData={addKeys(regionsHierarchy)}
					titleRender={renderTitle}
				/>
			) : (
				<EmptyState
					height={120}
					headline={'No regions found'}
					body={
						<div style={{ maxWidth: 488, textAlign: 'center' }}>
							Currently there are no regions available. Please add region to view here.
						</div>
					}
				/>
			)}
			{addRegionVisible && (
				<AddRegionPopup
					parentData={currentParent}
					onSuccess={onRegionAddSuccess}
					onCancel={hideAddRegion}
				/>
			)}
			{editRegionVisible && (
				<EditRegionPopup
					region={editingRegion}
					onSuccess={onRegionEditSuccess}
					onCancel={hideEditRegion}
				/>
			)}
		</div>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	regions: state.regions,
	loading: state.regions.fetching,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchRegionsWithHierarchy: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? fetchRegionsWithChildrenForSupplier(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: fetchRegionsWithChildrenForBuyer(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	getLocationsCount: (regionId) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.countBy({ regionId }, '')
				: locationsRestCrudThunksForBuyer.countBy({ regionId }, '')
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(RegionsOverviewIndexPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import * as React from 'react';
import { Layout, DatePicker, Button, List, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { quotesRestCrudThunksForBuyer } from '../../thunks/quotes_thunks';
import {
	getCurrency,
	getEntityById,
	getSearchStringForBackNavigationWithBackText,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import { Link } from 'react-router-dom';
import { QUOTE_STATUS_DISPLAY_NAMES } from '../../constants/QuoteStatuses';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import QuoteEditButton from '../quote_edit_button/QuoteEditButton';
import NextPreviousButton from '../next_previous_button/NextPreviousButton';
import { PROPOSALS_CRUD_ACTION_CREATORS } from '../../actions/proposals_actions';
import { updatePaginationQuotes } from '../../thunks/quotes_thunks_wrapper';

const queryString = require('qs');
const style = require('./QuotesDetailPage.less');
const ListItem = List.Item;
const ListItemMeta = ListItem.Meta;
const { Content } = Layout;
const { RangePicker } = DatePicker;
const dateFormat = 'MM-DD-YYYY';

interface QuotesDetailPageState {}

interface QuotesDetailPageProps {
	match: any;
}

class QuotesDetailPage extends React.Component<any, QuotesDetailPageState> {
	state = {
		loading: true,
		backLinkTo: '/buyer/quotes',
		backLinkText: 'Back to quotes',
		fromTargetCollectionName: null,
	};

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const { getQuote, location, match, quote } = this.props;
		const quoteId = match.params.id;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		if (queryParams.targetCollectionName) {
			this.setState({ fromTargetCollectionName: queryParams.targetCollectionName });
		}
		if (queryParams.backlinkParams) {
			const { pathName, backLinkText, ...otherParams } = queryString.parse(
				queryParams.backlinkParams
			);
			const finalPath = pathName || `/buyer/quotes/overview/all`;
			this.setState({
				loading: false,
				backLinkTo: `${finalPath}?${new URLSearchParams(otherParams).toString()}`,
				...(backLinkText && { backLinkText }),
			});
		} else {
			this.setState({
				loading: false,
				backLinkTo: `/buyer/quotes/overview/all`,
			});
		}

		if (!(quote && quote.id)) {
			getQuote(quoteId);
		}
	}

	handleTabChange = (key) => {
		const { history, match } = this.props;
		const quoteId = match.params.id;
		history.push(`/buyer/quotes/detail/${quoteId}/${key}`);
	};

	handleDelete = () => {
		const { history, deleteQuote, quote } = this.props;
		deleteQuote(quote).then(() => {
			history.push('/buyer/quotes/overview/all');
		});
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	render() {
		const {
			quote,
			match,
			companyConfig,
			cloneTargetCollection,
			location,
			history,
			proposals,
			updatePagination,
		} = this.props;
		const quoteId = match.params.id;
		const tabs = [
			{
				key: 'details',
				name: 'Overview',
			},
		];
		const getLink = (route) => {
			const searchString = getSearchStringForBackNavigationWithBackText('Back to quotes', location);
			const backUrl = `${route}${
				!!searchString ? `?backlinkParams=${encodeURIComponent(searchString)}` : ''
			}`;
			return backUrl;
		};
		const currency = getCurrency({ workOrder: nullSafeGet('workOrder', quote) });
		return (
			<div className="quotesDetailPage">
				<DetailPageHeader
					exactPath={'/buyer/quotes/detail/:id'}
					redirectPath={`/buyer/quotes/detail/${quoteId}/details`}
					backLinkText={this.state.backLinkText}
					backLinkTo={this.state.backLinkTo}
					title={nullSafeGet('workOrder.title', quote)}
					subtitle={
						<div className="quotesDetailPage__subtitleRow">
							<div className="quotesDetailPage__subtitle">
								{QUOTE_STATUS_DISPLAY_NAMES[quote.status]}
							</div>
							<div className="quotesDetailPage__subtitle">
								{currency.format(quote.totalAfterTax)}
							</div>
						</div>
					}
					actions={[
						<Link to={`/buyer/workOrders/detail/${quote.workOrderId}/details`} target={'_blank'}>
							<Button ghost={true} style={{ marginRight: 16 }}>
								View Work Order <i className="icons8-font icons8-external-link ml-2" />
							</Button>
						</Link>,
						<QuoteEditButton
							quote={quote}
							ghost={true}
							onClick={() => {
								history.push(getLink(`/buyer/quotes/edit/${quoteId}`));
							}}
						/>,
					]}
					tabs={tabs}
					backgroundColor={nullSafeGet('detail.config.theme.navblock', companyConfig)}
					handleTabChange={this.handleTabChange}
					checkTabActive={this.checkTabActive}
					childRoutes={this.props.childRoutes}
					tabsRightActions={() => (
						<NextPreviousButton
							key={this.state.fromTargetCollectionName}
							location={location}
							history={history}
							activeId={quote.id}
							getEnitityUrl={(id) => `/buyer/quotes/detail/${id}/details`}
							stateSlice={proposals}
							targetCollectionName={this.state.fromTargetCollectionName}
							updatePagination={updatePagination}
							cloneTargetCollection={cloneTargetCollection}
							entityName="quote"
						/>
					)}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	quote: getEntityById(ownProps.match.params.id, state.proposals),
	proposals: state.proposals,
	match: ownProps.match,
	history: ownProps.history,
	companyConfig: state.company_config,
});

const mapDispatchToProps = (dispatch) => ({
	getQuote: (id) => dispatch(quotesRestCrudThunksForBuyer.readOne(id)),
	deleteQuote: (entity) => dispatch(quotesRestCrudThunksForBuyer.delete(entity, 'id', null, true)),
	cloneTargetCollection: (from, to) =>
		dispatch(PROPOSALS_CRUD_ACTION_CREATORS.cloneTargetCollection(from, to)),
	updatePagination: (pagination, targetCollectionName) =>
		dispatch(
			updatePaginationQuotes('buyer')(false, undefined, targetCollectionName, pagination, true)
		),
});

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(QuotesDetailPage));

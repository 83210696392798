import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Layout, Row, Col, Card, Table, Button, message, Drawer, Popover } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import {
	getCurrency,
	getEntityById,
	nullSafeGet,
	nullSafeGetOrElse,
	renderCurrencyByCurrencyId,
	roundOffToDecimal,
	stopDefaultEvents,
} from '../../utils/DataAccessUtils';
import {
	markOrderedPurchaseOrderForSupplier,
	purchaseOrdersRestCrudThunksForSupplier,
} from '../../thunks/purchase_orders_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { ContactRowDisplay } from '../contact_row_display/ContactRowDisplay';
import { AddressDisplay } from '../address_display/AddressDisplay';
import { HorizontalKeyValueDisplay } from '../horizontal_key_value_display/HorizontalKeyValueDisplay';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import PurchaseOrderStatusDisplay from '../purchase_order_status_display/PurchaseOrderStatusDisplay';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import HyperLink, { ENTITY_TYPE } from '../common/HyperLink';
import {
	PURCHASE_ORDER_STATUSES,
	RECEIVABLE_PO_STATUSES,
} from '../../constants/PurchaseOrderStatuses';
import { purchaseOrderReceiptsRestCrudThunksForSupplier } from '../../thunks/purchase_order_receipt_thunks';
import UpdateSerialAmdAssetNumbersModal from './UpdateSerialAmdAssetNumbersModal';
import { createMultipleEpslAsDeletedForSupplier } from '../../thunks/equipment_per_stock_locations_thunks';
import InfoWithKeyValuePair from '../supplier_assets_detail_page/InfoWithKeyValuePair';
import PRWarrantyAssetDetail from '../purchase_requests_detail_details_page/PRWarrantyAssetDetail';
import { BrandDisplay } from '../brands_overview_index_page/BrandsOverviewIndexPage';
import { PurchaseRequestEquipmentLineItemRowDisplay } from '../purchase_request_equipment_line_item_row_display/PurchaseRequestEquipmentLineItemRowDisplay';
import { PurchaseRequestPartLineItemRowDisplay } from '../purchase_request_part_line_item_row_display/PurchaseRequestPartLineItemRowDisplay';
import { EditOutlined } from '@ant-design/icons';
import PartForm from '../part_form/PartForm';
import EquipmentForm from '../equipment_form/EquipmentForm';
import { createMultipleAssetsAsDeletedForSupplier } from '../../thunks/assets_thunks';
import ReplacePartLineItemModal from '../purchase_order_form/ReplacePartLineItemModal';
import ReplaceEquipmentLineItemModal from '../purchase_order_form/ReplaceEquipmentLineItemModal';
import DateTimeHover from '../date_time_component/DateTime';
import { getPOLineItemUnitCostValue } from '../../utils/InventoryUtils';
import ReceivePurchaseOrderLineItemModal from './ReceivePurchaseOrderLineItemModal';

const { Content } = Layout;
const DEFAULT_BACK_LINK_TEXT = 'Back to purchase order';

require('./PurchaseOrdersDetailDetailsPage.less');

const PurchaseOrdersDetailPage: FC<any> = ({
	match,
	getPurchaseOrder,
	purchaseOrder,
	history,
	purchaseOrdersFetching,
	location,
	createPOReceiptBulk,
	markOrdered,
	createEpslsBulkAsDeleted,
	createAssetsBulkAsDeleted,
}): React.ReactElement => {
	const [currentLineItem, setCurrentLineItem] = useState(null);
	const [receiveModalVisible, setReceiveModalVisible] = useState(false);
	const [receivingAllParts, setReceivingAllParts] = useState(false);
	const [receivingAllEquipment, setReceivingAllEquipment] = useState(false);
	const [isReturn, setIsReturn] = useState(false);

	const [equipmentSerialNumberVisible, setEquipmentSerialNumberVisible] = useState(false);
	const [createdEntities, setCreatedEntities] = useState([]);
	const [receivingEntityType, setReceivingEntityType] = useState(null);
	const [receiptEntities, setReceiptEntities] = useState<any>({});

	const [statusUpdating, setStatusUpdating] = useState(false);
	const [currentlyEditingPart, setCurrentlyEditingPart] = useState(null);
	const [partEditDrawerVisible, setPartEditDrawerVisible] = useState(false);
	const [currentlyEditingEquipment, setCurrentlyEditingEquipment] = useState(null);
	const [equipmentEditDrawerVisible, setEquipmentEditDrawerVisible] = useState(false);

	const [replacingLineItem, setReplacingLineItem] = useState(null);
	const [replacePartModalVisible, setReplacePartModalVisible] = useState(false);
	const [replaceEquipmentModalVisible, setReplaceEquipmentModalVisible] = useState(false);

	const refreshPurchaseOrder = useCallback(() => {
		const purchaseOrderId = match.params.id;
		getPurchaseOrder(purchaseOrderId);
	}, [match.params.id, getPurchaseOrder]);

	useEffect(() => {
		if (!nullSafeGet('id', purchaseOrder)) {
			refreshPurchaseOrder();
		}
	}, []);

	const currencyId = useMemo(() => purchaseOrder.currencyId, [purchaseOrder.currencyId]);

	const currency = useMemo(() => getCurrency({ currencyId }), [currencyId]);

	const receivable = useMemo(
		() => RECEIVABLE_PO_STATUSES.includes(nullSafeGet('status', purchaseOrder)),
		[purchaseOrder]
	);

	const hideSerialNumberUpdate = useCallback(() => setEquipmentSerialNumberVisible(false), []);

	const showReceiveLineItem = useCallback(
		(lineItem, isReturn = false) =>
			(e) => {
				stopDefaultEvents(e);
				setIsReturn(isReturn);
				setCurrentLineItem(lineItem);
				setReceiveModalVisible(true);
			},
		[]
	);

	const hideReceiveLineItem = useCallback(() => {
		setReceiveModalVisible(false);
		setCurrentLineItem(null);
	}, []);

	const onPOReceiptSuccess = useCallback(
		(receipt) => {
			message.success(`${receipt.receivedQuantity > 0 ? 'Received' : 'Returned'} successfully!`);
			refreshPurchaseOrder();
			hideReceiveLineItem();
		},
		[hideReceiveLineItem, refreshPurchaseOrder]
	);

	const onEquipmentCreateSuccess = useCallback(
		(receipt, deletedEntities) => {
			hideReceiveLineItem();
			setReceiptEntities([receipt]);
			setReceivingEntityType(nullSafeGet('locationId', purchaseOrder) ? 'asset' : 'equipment');
			setCreatedEntities(deletedEntities);
			setEquipmentSerialNumberVisible(true);
		},
		[hideReceiveLineItem, purchaseOrder]
	);

	const onUpdateSerialNumberSuccess = useCallback(() => {
		setCreatedEntities([]);
		setEquipmentSerialNumberVisible(false);
		refreshPurchaseOrder();
	}, [refreshPurchaseOrder]);

	const getWarrantyCurrencyField = useCallback(
		(record) => (
			<div className="flex flex-row items-center">
				<div>{currency.format(0)}</div>
				<InfoWithKeyValuePair
					details={{
						'Warranty Asset': (
							<PRWarrantyAssetDetail
								id={record.warrantyAssetId}
								asset={record.warrantyAsset}
								backLinkText={'Back To Purchase Order Detail'}
							/>
						),
					}}
				/>
			</div>
		),
		[currency]
	);

	const editPart = useCallback(
		(lineItem) => (e) => {
			stopDefaultEvents(e);
			setCurrentlyEditingPart(lineItem.part);
			setReplacingLineItem(lineItem);
			setPartEditDrawerVisible(true);
		},
		[]
	);

	const replacePartLineItem = () => setReplacePartModalVisible(true);
	const _hideReplacePartModal = () => setReplacePartModalVisible(false);

	const replaceEquipmentLineItem = () => setReplaceEquipmentModalVisible(true);
	const _hideReplaceEquipmentModal = () => setReplaceEquipmentModalVisible(false);

	const onPartEditClose = useCallback(() => {
		setPartEditDrawerVisible(false);
		setCurrentlyEditingPart(null);
		setReplacingLineItem(null);
	}, []);

	const onPartEditSuccess = useCallback(() => {
		onPartEditClose();
		refreshPurchaseOrder();
	}, [onPartEditClose, refreshPurchaseOrder]);

	const onReplacePartLineItemSuccess = useCallback(() => {
		_hideReplacePartModal();
		onPartEditClose();
		refreshPurchaseOrder();
	}, [onPartEditClose, refreshPurchaseOrder]);

	const onEquipmentEditClose = useCallback(() => {
		setEquipmentEditDrawerVisible(false);
		setCurrentlyEditingEquipment(null);
		setReplacingLineItem(null);
	}, []);

	const onReplaceEquipmentLineItemSuccess = useCallback(() => {
		_hideReplaceEquipmentModal();
		onEquipmentEditClose();
		refreshPurchaseOrder();
	}, [onEquipmentEditClose, refreshPurchaseOrder]);

	const partsColumns = useMemo(
		() => [
			{
				title: 'Part Number',
				dataIndex: ['part'],
				render: (part, record) => (
					<div className="flex flex-row items-center">
						<div>{nullSafeGet('partNumber', part) || '--'}</div>
						<Button type="link" icon={<EditOutlined translate="" />} onClick={editPart(record)} />
					</div>
				),
			},
			{
				title: 'Part Detail',
				render: (_, record) => (
					<PurchaseRequestPartLineItemRowDisplay purchaseRequestPartLineItem={record} />
				),
			},
			{
				title: 'Quantity',
				dataIndex: 'quantity',
				render: (_, record) => (
					<div style={{ whiteSpace: 'nowrap' }}>
						{nullSafeGetOrElse('partQuantity', record, '--')}{' '}
						{nullSafeGetOrElse('partQuantity', record, 0) > 1
							? nullSafeGet('partUom.displayNamePlural', record)
							: nullSafeGet('partUom.displayNameSingular', record)}
					</div>
				),
			},
			{
				title: 'Received',
				render: (_, record) => nullSafeGetOrElse('partReceivedQuantity', record, 0),
			},
			{
				title: 'Unit Cost',
				render: (_, record) =>
					record.warrantyAssetId
						? getWarrantyCurrencyField(record)
						: renderCurrencyByCurrencyId(currencyId)(
								getPOLineItemUnitCostValue(record, 'part')['unitCost']
						  ),
			},
			{
				title: 'Total Cost',
				dataIndex: 'partCost',
				render: (cost, record) =>
					record.warrantyAssetId
						? getWarrantyCurrencyField(record)
						: renderCurrencyByCurrencyId(currencyId)(
								getPOLineItemUnitCostValue(record, 'part')['totalCost']
						  ),
			},
			{
				title: 'Status',
				render: (_, record) =>
					record.status ? <PurchaseOrderStatusDisplay status={record.status} /> : 'NA',
			},
			...(receivable
				? [
						{
							title: '',
							render: (_, record) => (
								<>
									<Button
										disabled={record.status === PURCHASE_ORDER_STATUSES.received}
										onClick={showReceiveLineItem(record)}
									>
										Receive
									</Button>
									<Button
										disabled={nullSafeGetOrElse('partReceivedQuantity', record, 0) < 1}
										className="ml-2"
										onClick={showReceiveLineItem(record, true)}
									>
										Return
									</Button>
								</>
							),
						},
				  ]
				: []),
		],
		[currencyId, editPart, getWarrantyCurrencyField, receivable, showReceiveLineItem]
	);

	const editEquipment = useCallback(
		(lineItem) => (e) => {
			stopDefaultEvents(e);
			setCurrentlyEditingEquipment(lineItem.equipmentType);
			setReplacingLineItem(lineItem);
			setEquipmentEditDrawerVisible(true);
		},
		[]
	);

	const onEquipmentEditSuccess = useCallback(() => {
		onEquipmentEditClose();
		refreshPurchaseOrder();
	}, [onEquipmentEditClose, refreshPurchaseOrder]);

	const equipmentColumns = useMemo(
		() => [
			{
				title: 'Equipment Type #',
				dataIndex: ['equipmentType'],
				render: (equipmentType, record) => (
					<div className="flex flex-row items-center">
						<div>{nullSafeGet('equipmentTypeNumber', equipmentType) || '--'}</div>
						<Button
							type="link"
							icon={<EditOutlined translate="" />}
							onClick={editEquipment(record)}
						/>
					</div>
				),
			},
			{
				title: 'Equipment Detail',
				render: (_, record) => (
					<PurchaseRequestEquipmentLineItemRowDisplay purchaseRequestEquipmentLineItem={record} />
				),
			},
			{
				title: 'Quantity',
				dataIndex: 'equipmentQuantity',
			},
			{
				title: 'Received',
				render: (_, record) => nullSafeGetOrElse('equipmentReceivedQuantity', record, 0),
			},
			{
				title: 'Unit Cost',
				render: (_, record) =>
					record.warrantyAssetId
						? getWarrantyCurrencyField(record)
						: renderCurrencyByCurrencyId(currencyId)(
								getPOLineItemUnitCostValue(record, 'equipment')['unitCost']
						  ),
			},
			{
				title: 'Total Cost',
				render: (_, record) =>
					record.warrantyAssetId
						? getWarrantyCurrencyField(record)
						: renderCurrencyByCurrencyId(currencyId)(
								getPOLineItemUnitCostValue(record, 'equipment')['totalCost']
						  ),
			},
			{
				title: 'Status',
				render: (_, record) =>
					record.status ? <PurchaseOrderStatusDisplay status={record.status} /> : 'NA',
			},
			...(receivable
				? [
						{
							title: '',
							render: (_, record) => (
								<>
									<Button
										disabled={record.status === PURCHASE_ORDER_STATUSES.received}
										onClick={showReceiveLineItem(record)}
									>
										Receive
									</Button>
									<Button
										disabled={nullSafeGetOrElse('equipmentReceivedQuantity', record, 0) < 1}
										className="ml-2"
										onClick={showReceiveLineItem(record, true)}
									>
										Return
									</Button>
								</>
							),
						},
				  ]
				: []),
		],
		[currencyId, editEquipment, getWarrantyCurrencyField, receivable, showReceiveLineItem]
	);

	const shipToLocation = useMemo(() => purchaseOrder.locationId, [purchaseOrder.locationId]);

	const receiveAllEquipmentDisabledWithoutAssetType = useMemo(() => {
		const eqLineItems = nullSafeGetOrElse(
			'equipmentSupplierPurchaseOrderLineItems',
			purchaseOrder,
			[]
		);
		console.log(eqLineItems);
		return shipToLocation && nullSafeGet('0', eqLineItems)
			? !!eqLineItems.find((_) => !nullSafeGet('equipmentType.assetTypeId', _))
			: false;
	}, [purchaseOrder, shipToLocation]);

	const shipToName = useMemo(
		() =>
			shipToLocation
				? nullSafeGet(`location.name`, purchaseOrder)
				: nullSafeGet('supplierFacility.displayName', purchaseOrder),
		[purchaseOrder, shipToLocation]
	);

	const purchaseOrderDetails = useMemo(
		() => ({
			'Ordered by': (
				<ContactRowDisplay
					contact={purchaseOrder.createdByContact}
					userType={ROLE_TYPES.SUPPLIER}
				/>
			),
			'Ship to': (
				<HyperLink
					text={nullSafeGet(`${shipToLocation ? 'location' : 'stockLocation'}.name`, purchaseOrder)}
					entityType={shipToLocation ? ENTITY_TYPE.LOCATION : ENTITY_TYPE.STOCK_LOCATION}
					entityId={nullSafeGet(
						`${shipToLocation ? 'locationId' : 'stockLocationId'}`,
						purchaseOrder
					)}
					backLinkText={DEFAULT_BACK_LINK_TEXT}
				/>
			),
			...(nullSafeGet('brand', purchaseOrder) && {
				Brand: <BrandDisplay brand={purchaseOrder.brand} />,
			}),
			'Shipping address': (
				<div className="flex flex-col">
					<div>{shipToName}</div>
					<AddressDisplay
						address={
							nullSafeGet('location.buyerFacility.primaryAddress', purchaseOrder) ||
							nullSafeGet('stockLocation.address', purchaseOrder) ||
							nullSafeGet('stockLocation.location.buyerFacility.primaryAddress', purchaseOrder)
						}
					/>
				</div>
			),
			Vendor: (
				<HyperLink
					text={nullSafeGet('partEquipmentVendor.name', purchaseOrder)}
					entityType={ENTITY_TYPE.VENDOR}
					entityId={nullSafeGet('partEquipmentVendorId', purchaseOrder)}
					backLinkText={DEFAULT_BACK_LINK_TEXT}
				/>
			),
			'Created at': <DateTimeHover timestamp={nullSafeGet('createdAt', purchaseOrder)} />,
			'Total cost': renderCurrencyByCurrencyId(currencyId)(purchaseOrder.totalCost),
			'Receipts Total': renderCurrencyByCurrencyId(currencyId)(purchaseOrder.actualCost),
		}),
		[purchaseOrder, shipToLocation, shipToName, currencyId]
	);

	const otherChargesDetails = useMemo(
		() => ({
			'Freight charges': renderCurrencyByCurrencyId(currencyId)(
				purchaseOrder.freightTotalBeforeTax
			),
			'Misc charges': renderCurrencyByCurrencyId(currencyId)(purchaseOrder.miscTotalBeforeTax),
			Tax: renderCurrencyByCurrencyId(currencyId)(purchaseOrder.tax),
		}),
		[
			currencyId,
			purchaseOrder.freightTotalBeforeTax,
			purchaseOrder.miscTotalBeforeTax,
			purchaseOrder.tax,
		]
	);

	const openEquipmentDetail = useCallback(
		(record) => {
			const path = `/supplier/equipments/detail/${record.equipmentTypeId}`;
			history.push(getLinkWIthBackLinkParams(location, DEFAULT_BACK_LINK_TEXT, path));
		},
		[history, location]
	);

	const equipmentOnRow = (record) => ({
		onClick: () => openEquipmentDetail(record),
	});

	const openPartDetail = useCallback(
		(record) => {
			const path = `/supplier/parts/detail/${record.partId}`;
			history.push(getLinkWIthBackLinkParams(location, DEFAULT_BACK_LINK_TEXT, path));
		},
		[history, location]
	);

	const partsOnRow = (record) => ({
		onClick: () => openPartDetail(record),
	});

	const onReceiveAllParts = useCallback(() => {
		const entities = purchaseOrder.partSupplierPurchaseOrderLineItems.map((_) => ({
			purchaseOrderLineItemId: _.id,
			purchaseOrderId: purchaseOrder.id,
			receiptNumber: '',
			pricePerQuantity: roundOffToDecimal(
				nullSafeGetOrElse('partCost', _, 0) / nullSafeGetOrElse('partQuantity', _, 1)
			),
			partId: _.partId,
			receivedQuantity: _.partQuantity - nullSafeGetOrElse('partReceivedQuantity', _, 0),
		}));
		setReceivingAllParts(true);
		createPOReceiptBulk(entities)
			.then(() => {
				message.success('Parts received successfully!');
				refreshPurchaseOrder();
			})
			.catch(() => message.error('Unable to receive parts!'))
			.finally(() => setReceivingAllParts(false));
	}, [createPOReceiptBulk, refreshPurchaseOrder, purchaseOrder]);

	const createDeletedAssetsBulk = useCallback(() => {
		const allAssetEntities = nullSafeGetOrElse(
			'equipmentSupplierPurchaseOrderLineItems',
			purchaseOrder,
			[]
		).flatMap((lineItem) => {
			const quantity =
				lineItem.equipmentQuantity - nullSafeGetOrElse('equipmentReceivedQuantity', lineItem, 0);
			const entities = Array.from(Array(quantity).keys()).map((_) => ({
				locationId: nullSafeGet('supplierPurchaseOrder.locationId', lineItem),
				name: nullSafeGetOrElse('equipmentType.modelName', lineItem, ''),
				isActive: true,
				images: [],
				...(nullSafeGet('equipmentType.assetTypeId', lineItem) && {
					assetTypeId: nullSafeGet('equipmentType.assetTypeId', lineItem),
				}),
				assetTypeDetails: [],
				isLeased: false,
				buyerCompanyId: nullSafeGet('supplierPurchaseOrder.location.buyerCompanyId', lineItem),
			}));
			return entities;
		});

		return createAssetsBulkAsDeleted(allAssetEntities);
	}, [createAssetsBulkAsDeleted, purchaseOrder]);

	const createDeletedEpslsBulk = useCallback(() => {
		const allEpslEntities = nullSafeGetOrElse(
			'equipmentSupplierPurchaseOrderLineItems',
			purchaseOrder,
			[]
		).flatMap((lineItem) => {
			const quantity =
				lineItem.equipmentQuantity - nullSafeGetOrElse('equipmentReceivedQuantity', lineItem, 0);
			const entities = Array.from(Array(quantity).keys()).map((_) => ({
				stockLocationId: nullSafeGet('supplierPurchaseOrder.stockLocationId', lineItem),
				equipmentTypeId: lineItem.equipmentTypeId,
				name: nullSafeGetOrElse('equipmentType.modelName', lineItem, ''),
				isRental: false,
				vendorId: nullSafeGet('supplierPurchaseOrder.partEquipmentVendorId', lineItem),
				images: [],
				supplierFacilityId: lineItem.supplierFacilityId,
				supplierCompanyId: lineItem.supplierCompanyId,
			}));
			return entities;
		});

		return createEpslsBulkAsDeleted(allEpslEntities);
	}, [createEpslsBulkAsDeleted, purchaseOrder]);

	const onReceiveAllEquipment = useCallback(() => {
		setReceivingAllEquipment(true);

		const isAssetReceive = nullSafeGet('locationId', purchaseOrder);

		const promiseToCall = isAssetReceive ? createDeletedAssetsBulk : createDeletedEpslsBulk;

		promiseToCall()
			.then((res) => {
				setCreatedEntities(res);

				const receipts = purchaseOrder.equipmentSupplierPurchaseOrderLineItems.map((_) => ({
					purchaseOrderLineItemId: _.id,
					purchaseOrderId: _.supplierPurchaseOrderId,
					receiptNumber: '',
					pricePerQuantity: roundOffToDecimal(
						nullSafeGetOrElse('equipmentCost', _, 0) / nullSafeGetOrElse('equipmentQuantity', _, 1)
					),
					equipmentTypeId: _.equipmentTypeId,
					...(nullSafeGet('equipmentType.assetTypeId', _) && {
						assetTypeId: nullSafeGet('equipmentType.assetTypeId', _),
					}),
					receivedQuantity:
						_.equipmentQuantity - nullSafeGetOrElse('equipmentReceivedQuantity', _, 0),
				}));

				setReceiptEntities(receipts);

				setReceivingEntityType(isAssetReceive ? 'asset' : 'equipment');
				setEquipmentSerialNumberVisible(true);
			})
			.catch((err) => message.error(err))
			.finally(() => setReceivingAllEquipment(false));
	}, [createDeletedAssetsBulk, createDeletedEpslsBulk, purchaseOrder]);

	const onOrdered = useCallback(() => {
		setStatusUpdating(true);
		markOrdered(purchaseOrder)
			.then(() => refreshPurchaseOrder())
			.catch(() => message.error('Unable to update Purchase Order!'))
			.finally(() => setStatusUpdating(false));
	}, [markOrdered, purchaseOrder, refreshPurchaseOrder]);

	const fetching = purchaseOrdersFetching && !nullSafeGet('id', purchaseOrder);
	return fetching ? (
		<PageLoadingPlaceholder />
	) : (
		<Content className="purchaseOrdersDetailDetailsPage">
			<LogOnMountWithStandardEventProperties eventType="visited purchase order detail page" />
			<Row style={{ margin: '16px 4px' }} gutter={12}>
				<Col span={24} className="rowSpacing">
					<Card>
						{purchaseOrder.status === 'new' ? (
							<div>
								<h5>Has this Purchase Order been sent to vendor already?</h5>
								<div style={{ marginTop: 16, marginBottom: 40 }}>
									<Button type="primary" key="approve" onClick={onOrdered} loading={statusUpdating}>
										Yes, it was ordered
									</Button>
								</div>
							</div>
						) : null}
						<HorizontalKeyValueDisplay
							rowStyle={{ minWidth: '25%' }}
							keyValueStore={purchaseOrderDetails}
						/>
					</Card>
				</Col>
				<Col span={24} className="rowSpacing">
					<Row className={'flex'}>
						<Col className={'flex flex-grow'} span={16} style={{ paddingRight: 12 }}>
							<Card title={'Description'} style={{ height: '100%', width: '100%' }}>
								<div
									className="richTextWrapper"
									dangerouslySetInnerHTML={{
										__html: nullSafeGetOrElse('description', purchaseOrder, '--'),
									}}
								/>
							</Card>
						</Col>
						<Col span={8}>
							<div className="rowSpacing">
								<Card title={'Payment Terms'}>
									<div>{nullSafeGetOrElse('paymentTerms', purchaseOrder, '--')}</div>
								</Card>
							</div>
							<Card title={'Shipping Terms'}>
								<div>{nullSafeGetOrElse('shippingTerms', purchaseOrder, '--')}</div>
							</Card>
						</Col>
					</Row>
				</Col>
				{(purchaseOrder.partSupplierPurchaseOrderLineItems || []).length > 0 ? (
					<Col span={24} className="rowSpacing">
						<Card
							title={`Parts`}
							extra={
								receivable &&
								purchaseOrder.partSupplierPurchaseOrderLineItems.some(
									(_) => _.status !== PURCHASE_ORDER_STATUSES.received
								) ? (
									<Button type="primary" onClick={onReceiveAllParts} loading={receivingAllParts}>
										Receive All
									</Button>
								) : null
							}
						>
							<Table
								columns={partsColumns}
								rowKey={(partLineItem) => partLineItem['id']}
								dataSource={purchaseOrder.partSupplierPurchaseOrderLineItems}
								showHeader={true}
								loading={false}
								onRow={partsOnRow}
							/>
						</Card>
					</Col>
				) : null}
				{(purchaseOrder.equipmentSupplierPurchaseOrderLineItems || []).length > 0 ? (
					<Col span={24} className="rowSpacing">
						<Card
							title={`Equipment`}
							extra={
								receivable &&
								purchaseOrder.equipmentSupplierPurchaseOrderLineItems.some(
									(_) => _.status !== PURCHASE_ORDER_STATUSES.received
								) ? (
									<Popover
										content={
											receiveAllEquipmentDisabledWithoutAssetType
												? 'Please associate asset type with every equipment type in this purchase order!'
												: null
										}
										trigger="hover"
									>
										<Button
											type="primary"
											onClick={onReceiveAllEquipment}
											loading={receivingAllEquipment}
											disabled={receiveAllEquipmentDisabledWithoutAssetType}
										>
											Receive All
										</Button>
									</Popover>
								) : null
							}
						>
							<Table
								columns={equipmentColumns}
								rowKey={(equipment) => equipment['id']}
								dataSource={purchaseOrder.equipmentSupplierPurchaseOrderLineItems}
								showHeader={true}
								loading={false}
								onRow={equipmentOnRow}
							/>
						</Card>
					</Col>
				) : null}
				<Col span={24} className="rowSpacing">
					<Card title={`Other Charges`}>
						<HorizontalKeyValueDisplay
							rowStyle={{ minWidth: '25%' }}
							keyValueStore={otherChargesDetails}
						/>
					</Card>
				</Col>
			</Row>
			{receiveModalVisible && (
				<ReceivePurchaseOrderLineItemModal
					lineItem={currentLineItem}
					isReturn={isReturn}
					onCancel={hideReceiveLineItem}
					onPOReceiptSuccess={onPOReceiptSuccess}
					onEquipmentCreateSuccess={onEquipmentCreateSuccess}
				/>
			)}
			{equipmentSerialNumberVisible && (
				<UpdateSerialAmdAssetNumbersModal
					receiptEntities={receiptEntities}
					createdEntities={createdEntities}
					entityType={receivingEntityType}
					onSuccess={onUpdateSerialNumberSuccess}
					onCancel={hideSerialNumberUpdate}
				/>
			)}
			{partEditDrawerVisible ? (
				<Drawer
					width={640}
					placement="right"
					closable={true}
					onClose={onPartEditClose}
					visible={true}
					extra={
						<Popover
							content={
								nullSafeGetOrElse('partReceivedQuantity', replacingLineItem, 0) > 0
									? 'Return already received items to proceed'
									: ''
							}
							trigger="hover"
						>
							<Button
								type="link"
								disabled={nullSafeGetOrElse('partReceivedQuantity', replacingLineItem, 0) > 0}
								onClick={replacePartLineItem}
							>
								Replace Part
							</Button>
						</Popover>
					}
				>
					<PartForm
						onCancel={onPartEditClose}
						formData={currentlyEditingPart}
						onSuccess={onPartEditSuccess}
					/>
				</Drawer>
			) : null}
			{equipmentEditDrawerVisible ? (
				<Drawer
					width={640}
					placement="right"
					closable={true}
					onClose={onEquipmentEditClose}
					visible={true}
					extra={
						<Popover
							content={
								nullSafeGetOrElse('equipmentReceivedQuantity', replacingLineItem, 0) > 0
									? 'Return already received items to proceed'
									: ''
							}
							trigger="hover"
						>
							<Button
								type="link"
								disabled={nullSafeGetOrElse('equipmentReceivedQuantity', replacingLineItem, 0) > 0}
								onClick={replaceEquipmentLineItem}
							>
								Replace Equipment
							</Button>
						</Popover>
					}
				>
					<EquipmentForm
						onCancel={onEquipmentEditClose}
						formData={currentlyEditingEquipment}
						onSuccess={onEquipmentEditSuccess}
					/>
				</Drawer>
			) : null}
			{replacePartModalVisible ? (
				<ReplacePartLineItemModal
					lineItem={replacingLineItem}
					vendorId={nullSafeGet('partEquipmentVendorId', purchaseOrder)}
					onCancel={_hideReplacePartModal}
					onSuccess={onReplacePartLineItemSuccess}
				/>
			) : null}
			{replaceEquipmentModalVisible ? (
				<ReplaceEquipmentLineItemModal
					lineItem={replacingLineItem}
					vendorId={nullSafeGet('partEquipmentVendorId', purchaseOrder)}
					onCancel={_hideReplaceEquipmentModal}
					onSuccess={onReplaceEquipmentLineItemSuccess}
				/>
			) : null}
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	purchaseOrdersFetching: state.purchase_orders.fetching,
	purchaseOrder: getEntityById(ownProps.match.params.id, state.purchase_orders),
	match: ownProps.match,
	history: ownProps.history,
	location: ownProps.location,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	getPurchaseOrder: (id) => dispatch(purchaseOrdersRestCrudThunksForSupplier.readOne(id)),
	createPOReceiptBulk: (entities) =>
		dispatch(purchaseOrderReceiptsRestCrudThunksForSupplier.createBulk(entities)),
	markOrdered: (entity) => dispatch(markOrderedPurchaseOrderForSupplier(entity)),
	createEpslsBulkAsDeleted: (entities) =>
		dispatch(createMultipleEpslAsDeletedForSupplier(entities)),
	createAssetsBulkAsDeleted: (entities) =>
		dispatch(createMultipleAssetsAsDeletedForSupplier(entities)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(PurchaseOrdersDetailPage)
);

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { invoicesRestCrudThunksForSupplier } from '../../thunks/invoices_thunks';
import SupplierInvoiceForm from '../supplier_invoice_form/SupplierInvoiceForm';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const style = require('./EditSupplierInvoicePage.less');
const queryString = require('qs');

class EditSupplierInvoicePage extends React.Component<any, any> {
	componentDidMount() {
		const { getInvoice, match } = this.props;
		const invoiceId = match.params.id;
		getInvoice(invoiceId);
	}

	render() {
		const { history, location, invoice, fetching } = this.props;

		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		//noinspection TypeScriptUnresolvedVariable
		return [
			<LogOnMountWithStandardEventProperties eventType="visited supplier edit invoice page" />,
			<EditPage
				title={`Edit Invoice ${invoice.id}`}
				formComponentProps={{
					formData: invoice,
					onSuccess: (rec) => {
						if (!!queryParams.btwo) {
							history.push(`/supplier/workOrders/detail/${rec.workOrderId}/invoices`);
						} else {
							history.push(`/supplier/invoices/detail/${rec.id}`);
						}
					},
				}}
				fetching={fetching}
				FormComponent={SupplierInvoiceForm}
			/>,
		];
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		history: ownProps.history,
		invoice: state.invoices.detail,
		fetching: state.invoices.fetching,
		match: ownProps.match,
	};
};

const mapDispatchToProps = (dispatch) => ({
	getInvoice: (id) => dispatch(invoicesRestCrudThunksForSupplier.readOne(id)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(EditSupplierInvoicePage)
);

import { Form, Input, Modal } from 'antd';
import React, { FC, useMemo } from 'react';
import MultiPhoneNumberComponent from '../MultiPhoneNumberComponent';
import MultiEmailComponentAntdV4 from '../MultiEmailComponent/MultiEmailComponentAntdV4';

const EditContactDirectoryModal: FC<any> = ({
	record,
	onCancel,
	onSubmit,
	loading,
}): React.ReactElement => {
	const [form] = Form.useForm();

	const initialValues = useMemo(() => (record ? { ...record } : {}), [record]);

	return (
		<Modal
			width={600}
			okText={!!record ? 'Update Contact' : 'Add Contact'}
			onCancel={onCancel}
			onOk={form.submit}
			closable={false}
			confirmLoading={loading}
			visible={true}
		>
			<Form
				form={form}
				initialValues={initialValues}
				onFinish={onSubmit}
				layout="vertical"
				labelAlign="left"
				preserve={false}
			>
				<Form.Item label="Name" name="name" required>
					<Input placeholder="Enter name of the person" />
				</Form.Item>
				<Form.Item label="Title" name="title">
					<Input placeholder="Enter title of the person. Ex. Manager" />
				</Form.Item>
				<MultiPhoneNumberComponent
					type={'phoneNumber'}
					fieldProps={{
						name: 'phoneNumbers',
					}}
				/>
				<MultiEmailComponentAntdV4
					fieldProps={{
						preserve: false,
						label: 'Email',
						name: 'emails',
					}}
				/>
				<Form.Item label="Notes" name="notes">
					<Input.TextArea />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default EditContactDirectoryModal;

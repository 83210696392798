import {
	getConfigValueDefaultingToTrue,
	getImageCountErrorRules,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, Button, Modal } from 'antd';
import { imageUploadValidation, normFile } from '../../utils/ImageUtils';
import BackButton from '../back_button/BackButton';
import * as React from 'react';
import _ from 'lodash';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';

import { locationsRestCrudThunksForBuyer } from '../../thunks/locations_thunks';
import { assetsRestCrudThunksForBuyer } from '../../thunks/assets_thunks';
import { spendCategoriesRestCrudThunksForBuyer } from '../../thunks/spend_categories_thunks';
import { problemTypesRestCrudThunksForBuyer } from '../../thunks/problem_types_thunks';
import { workOrderAttributesRestCrudThunksForBuyer } from '../../thunks/work_order_attributes_thunks';
import { workOrderPrioritiesRestCrudThunksForBuyer } from '../../thunks/work_order_priorities_thunks';
import { workOrdersRestCrudThunksForBuyer } from '../../thunks/work_orders_thunks';
import { getProtectedImageUriForBuyer } from '../../utils/FileAccessUtils';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import {
	fetchSupplierFacilitiesInPrivateNetworkForBuyer,
	supplierFacilitiesRestCrudThunksForBuyer,
} from '../../thunks/supplier_facilities_thunks';
import { suppliersRestCrudThunksForBuyer } from '../../thunks/suppliers_thunks';
import {
	PRE_SUPPLIER_ASSIGNMENT_STATUSES,
	WORK_ORDER_STATUSES,
} from '../../constants/WorkOrderStatuses';
import { buyersRestCrudThunksForBuyer } from '../../thunks/buyers_thunks';
import { areasRestCrudThunksForBuyer } from '../../thunks/areas_thunks';
import { retrieveCachedUserDetails } from '../../thunks/session_thunks';
import { BUYER_ADMIN_ONLY, BUYER_STORE_ASSOCIATE } from '../roles/roles';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { getBuyerCompanyApprovalTiers } from '../../thunks/company_approval_thunks';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import AssetNumberDisplay from '../asset_row_display/AssetNumberDisplay';
import OWUpload from '../OWUpload';
import { getBuyerCompanySettingValue } from '../../utils/AuthUtils';

const WORK_ORDER_FORM_LOCATIONS_AUTOCOMPLETE = 'WORK_ORDER_FORM_LOCATIONS_AUTOCOMPLETE';
const WORK_ORDER_FORM_ASSETS_AUTOCOMPLETE = 'WORK_ORDER_FORM_ASSETS_AUTOCOMPLETE';
const WORK_ORDER_FORM_AREAS_AUTOCOMPLETE = 'WORK_ORDER_FORM_AREAS_AUTOCOMPLETE';
const WORK_ORDER_FORM_PROBLEM_TYPES_AUTOCOMPLETE = 'WORK_ORDER_FORM_PROBLEM_TYPES_AUTOCOMPLETE';
const WORK_ORDER_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE =
	'WORK_ORDER_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE';

interface WorkOrderFormProps extends FormComponentProps {
	history: any;
	onSubmit: any;
	onCancel: any;
	redirectForwardUrl?: string;
	onSuccess?: any;
	isApprovalForm?: any;
	creating: boolean;
	updating: boolean;
	formData: any;
	createErrors: any[];
	updateErrors: any[];
	fetchMultipleLocations: any;
	fetchLocations: any;
	fetchLocation: any;
	locations: any;
	fetchMultipleAssets: any;
	fetchAssets: any;
	fetchBuyer: any;
	assets: any;
	fetchMultipleAreas: any;
	fetchAreas: any;
	areas: any;
	fetchMultipleProblemTypes: any;
	fetchProblemTypes: any;
	problemTypes: any;
	fetchMultipleSpendCategories: any;
	fetchSpendCategories: any;
	spendCategories: any;
	fetchWorkOrderAttributes: any;
	fetchMultipleWorkOrderPriorities: any;
	fetchWorkOrderPriorities: any;
	workOrderPriorities: any;
	supplierFacilities: any;
	fetchSupplierFacilitiesInPrivateNetwork: any;
	fetchMultipleSupplierFacilitiesInPrivateNetwork: any;
	currentUser: any;
	updateWorkOrderForm: any;
	fetchSupplierFacility: any;
	fetchSupplierCompany: any;
	fetchApprovalHeirarchy: any;
	fetchProblemType: any;
	companyConfig: any;
}

class WorkOrderPendingEditForm extends React.Component<WorkOrderFormProps, any> {
	constructor(props) {
		super(props);
		this.state = {
			locationId: null,
			assetId: null,
			areaId: null,
			problemTypeId: null,
			previewVisible: false,
			uploadLoading: false,
			previewImage: false,
			privateSupplier: false,
			allPhotos: [],
			industryId: 1,
			approvalHeirarchy: {},
			numPicturesRequired: nullSafeGetOrElse(
				'problemType.serviceRequestNumPicturesRequired',
				this.props.formData,
				0
			),
		};
	}

	componentDidMount() {
		const { formData, fetchBuyer, currentUser, fetchApprovalHeirarchy } = this.props;

		fetchBuyer(nullSafeGet('facility.buyerCompanyId', currentUser)).then((buyer) => {
			this.setState({ industryId: buyer.industryId });
		});

		if (formData.locationId) {
			this.setState({ locationId: formData.locationId });
		}

		if (formData.assetId) {
			this.setState({ assetId: formData.assetId });
		}

		if (formData.areaId) {
			this.setState({ areaId: formData.areaId });
		}

		if (formData.problemTypeId) {
			this.setState({ problemTypeId: formData.problemTypeId });
		}

		let photosInfo;
		if (formData.images) {
			photosInfo = formData.images.map((p, idx) => {
				const [imgFileId, imgFileName] = p.split('/');
				const thumbUri = getProtectedImageUriForBuyer(imgFileId, imgFileName, 100, 100);
				const fullUri = getProtectedImageUriForBuyer(imgFileId, imgFileName, 400, 400);
				return {
					uid: idx,
					size: 1,
					name: imgFileName,
					filename: imgFileName,
					url: fullUri,
					status: 'done',
					thumbUrl: thumbUri,
					response: { data: { fileName: imgFileName, fileId: imgFileId } },
					type: 'image/jpeg',
				};
			});
		} else {
			photosInfo = [];
		}
		this.setState({ allPhotos: photosInfo });
		fetchApprovalHeirarchy().then((data) => {
			const tiers = nullSafeGet('0.tierDetails', data);
			if (tiers && tiers.length > 0) {
				const userTier =
					_.find(tiers, (item) => _.find(item.approvers, { email: currentUser.email })) || tiers[0];
				this.setState({
					approvalHeirarchy: { amount: userTier.amountUpto || 1000000000 },
				});
			}
		});
	}

	getStatus = (supplierFacilityId, oldStatus) => {
		const { privateSupplier } = this.state;
		let newStatus;
		if (
			PRE_SUPPLIER_ASSIGNMENT_STATUSES.includes(oldStatus) &&
			supplierFacilityId &&
			privateSupplier
		) {
			newStatus = WORK_ORDER_STATUSES.assignedToInternalTech;
		} else if (oldStatus === WORK_ORDER_STATUSES.unassigned) {
			newStatus = WORK_ORDER_STATUSES.pendingApproval;
		} else {
			newStatus = oldStatus;
		}
		return newStatus;
	};

	handleSubmit = (e) => {
		const { form, history, redirectForwardUrl, onSuccess, currentUser, updateWorkOrderForm } =
			this.props;

		e.preventDefault();
		form.validateFields((err, values) => {
			if (!err) {
				// put image data into proper format to persist
				const picturesInfo = values.images.map((p) => {
					const fileName = nullSafeGetOrElse('response.data.fileName', p, '');
					const fileId = nullSafeGetOrElse('response.data.fileId', p, '');
					return `${fileId}/${fileName}`;
				});

				let workOrder = {
					id: values.id,
					title: values.title,
					status: this.getStatus(values.supplierFacilityId, values.status),
					areaId: values.areaId ? parseInt(values.areaId, 10) : undefined,
					problemTypeId: parseInt(values.problemTypeId, 10),
					woPriorityId: parseInt(values.workOrderPriorityId, 10),
					locationId: parseInt(values.locationId, 10),
					assetId: values.assetId ? parseInt(values.assetId, 10) : undefined,
					images: picturesInfo,
					nte: values.nte ? parseFloat(values.nte) : undefined,
					description: values.additionalInfo,
					buyerFacilityId: values.buyerFacilityId,
					buyerCompanyId: values.buyerCompanyId,
					createdBy: values.createdBy,
					supplierFacilityId: values.supplierFacilityId,
					contractorDetails: values.contractorDetails,
					notes: values.notes,
					poNumber: values.poNumber,
					buyerAttachments: values.buyerAttachments,
					supplierAttachments: values.supplierAttachments,
				};

				updateWorkOrderForm(workOrder).then((record) => {
					if (redirectForwardUrl) {
						history.push(redirectForwardUrl);
					}
					if (onSuccess) {
						onSuccess(record);
					}
				});
			}
		});
	};

	handlePreview = (obj) => {
		let thumbUrl = null;
		let fullUrl = null;
		let photoInfo = null;
		if (obj.response.data) {
			const imgFileId = obj.response.data.fileId;
			const imgFileName = obj.response.data.fileName;
			thumbUrl = getProtectedImageUriForBuyer(imgFileId, imgFileName, 100, 100);
			fullUrl = getProtectedImageUriForBuyer(imgFileId, imgFileName, 400, 400);
			photoInfo = {
				uid: imgFileId,
				size: 1,
				name: imgFileName,
				filename: imgFileName,
				url: fullUrl,
				status: 'done',
				thumbUrl: thumbUrl,
				response: { data: { fileName: imgFileName, fileId: imgFileId } },
				type: 'image/jpeg',
			};
		}

		this.setState({
			photoInfo: [photoInfo],
			previewImage: fullUrl || thumbUrl,
			previewVisible: true,
		});
	};

	handleCancel = () => this.setState({ previewVisible: false });

	handleImageUpdate = (info) => {
		if (info.file.status === 'done') {
			this.setState({ allPhotos: info.fileList });
		} else if (info.file.status === undefined) {
			info.fileList = this.state.allPhotos;
		}
	};

	isAreaFilterAllowed = () => {
		const { companyConfig, currentUser } = this.props;
		return getBuyerCompanySettingValue(ROLE_TYPES.BUYER)(
			'assetConfig.allowAreaAssociationWithAssets',
			companyConfig,
			currentUser,
			false
		);
	};

	handleLocationChange = (locationId) => {
		const { fetchLocation } = this.props;
		fetchLocation(locationId).then((loc) => {
			this.setState({ locationId: locationId, location: loc });
		});
	};

	handleAreaChange = (areaId) => {
		this.setState({ areaId: areaId });
	};

	getProblemTypeRecord = (problemTypeId) => {
		return new Promise((resolve, reject) => {
			const { problemTypes, fetchProblemType } = this.props;
			const records = getRecordsForTargetCollection(
				problemTypes,
				WORK_ORDER_FORM_PROBLEM_TYPES_AUTOCOMPLETE
			);
			const problemType = records.find((_) => _.id === problemTypeId);
			problemType
				? resolve(problemType)
				: fetchProblemType(problemTypeId)
						.then((res) => resolve(res))
						.catch((err) => reject(err));
		});
	};

	handleProblemTypeChange = (problemTypeId) => {
		this.setState({ problemTypeId });
		this.getProblemTypeRecord(problemTypeId).then((problemType) =>
			this.setState({
				numPicturesRequired: nullSafeGetOrElse('serviceRequestNumPicturesRequired', problemType, 0),
			})
		);
	};

	handleAssetChange = (assetId) => {
		this.setState({ assetId: assetId });
	};

	handleSupplierFacilityChange = (supplierFacilityId) => {
		const { fetchSupplierFacility } = this.props;
		if (supplierFacilityId) {
			fetchSupplierFacility(supplierFacilityId).then((supplierFacility) => {
				supplierFacility.status === 'private'
					? this.setState({ privateSupplier: true })
					: this.setState({ privateSupplier: false });
			});
		}
	};

	render() {
		const {
			isApprovalForm,
			updating,
			form,
			formData,
			locations,
			assets,
			areas,
			problemTypes,
			workOrderPriorities,
			currentUser,
			fetchLocations,
			fetchMultipleLocations,
			fetchAssets,
			fetchMultipleAssets,
			fetchAreas,
			fetchMultipleAreas,
			fetchProblemTypes,
			fetchMultipleProblemTypes,
			fetchWorkOrderPriorities,
			fetchMultipleWorkOrderPriorities,
			companyConfig,
		} = this.props;
		const { getFieldDecorator } = form;
		const { previewVisible, previewImage } = this.state;
		const BACKEND_URI = getBackendUri();
		const uploadHeaders = { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token };
		const submitLabel = isApprovalForm ? 'Approve work order' : 'Update work order';

		getFieldDecorator('id', { initialValue: formData.id });
		getFieldDecorator('createdAt', { initialValue: formData.createdAt });
		getFieldDecorator('notes', { initialValue: formData.notes });
		getFieldDecorator('buyerCompanyId', {
			initialValue: formData.buyerCompanyId || nullSafeGet('facility.buyerCompanyId', currentUser),
		});
		getFieldDecorator('createdBy', {
			initialValue: formData.createdBy || nullSafeGet('email', currentUser),
		});
		getFieldDecorator('contractorDetails', {
			initialValue: formData.contractorDetails || {
				contractorContacts: [],
			},
		});

		getFieldDecorator('problemTypeId', {
			initialValue: formData.problemTypeId,
		});

		getFieldDecorator('buyerAttachments', { initialValue: formData.buyerAttachments || [] });
		getFieldDecorator('supplierAttachments', { initialValue: formData.supplierAttachments || [] });

		getFieldDecorator('supplierFacilityId', {
			initialValue: formData.supplierFacilityId,
		});

		getFieldDecorator('supplierFacilityId', {
			initialValue: formData.supplierFacilityId,
		});

		getFieldDecorator('dueDate', {
			initialValue: formData.dueDate,
		});

		const isAdmin = currentUser.roles.some((role) => BUYER_ADMIN_ONLY.has(role));
		const { approvalHeirarchy } = this.state;

		const minPhotosCount = nullSafeGetOrElse('state.numPicturesRequired', this, 0);

		const imageCountErrorMessage = `You need to upload at least ${minPhotosCount} photo${
			minPhotosCount > 1 ? 's' : ''
		}`;

		const poEntryEnabled = getConfigValueDefaultingToTrue(
			companyConfig,
			'config.workOrder',
			'allowPONUmberEntry'
		);

		const areaFilterAllowed = this.isAreaFilterAllowed();

		return (
			<Form layout="vertical" onSubmit={this.handleSubmit} className="supplierForm">
				<Form.Item label="Title">
					{getFieldDecorator('title', {
						initialValue: formData.title,
						rules: [{ required: true, message: 'This field is required.' }],
					})(<Input style={{ maxWidth: 800 }} />)}
				</Form.Item>
				<Form.Item label="Incident Location">
					{getFieldDecorator('locationId', {
						initialValue: formData.locationId,
						rules: [{ required: true, message: 'This field is required.' }],
					})(
						<OWAsyncSelect
							stateSlice={locations}
							targetCollectionName={WORK_ORDER_FORM_LOCATIONS_AUTOCOMPLETE}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleLocations(ids, targetCollectionName);
							}}
							onChange={this.handleLocationChange}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchLocations(
									{ name: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							renderRecord={(location) => (
								<Select.Option key={location.id} value={location.id}>
									{location.name}
								</Select.Option>
							)}
							sortBy={{ sort_by: 'name', order: 'ascend' }}
						/>
					)}
				</Form.Item>
				<Form.Item label="Area">
					{getFieldDecorator('areaId', {
						initialValue: formData.areaId,
					})(
						<OWAsyncSelect
							stateSlice={areas}
							targetCollectionName={WORK_ORDER_FORM_AREAS_AUTOCOMPLETE}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleAreas(ids, targetCollectionName);
							}}
							onChange={this.handleAreaChange}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchAreas(
									{ name: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							renderRecord={(area) => (
								<Select.Option key={area.id} value={area.id}>
									{area.hierarchyName}
								</Select.Option>
							)}
							additionalFilters={{
								locationId: nullSafeGet('locationId', this.state),
								brandId: nullSafeGet('location.brandId', this.state),
							}}
							sortBy={{ sort_by: 'name', order: 'ascend' }}
						/>
					)}
				</Form.Item>
				<Form.Item required={true} label="Problem Type">
					{getFieldDecorator('problemTypeId', {
						initialValue: formData.problemTypeId,
						rules: [{ required: true, message: 'This field is required.' }],
					})(
						<OWAsyncSelect
							stateSlice={problemTypes}
							targetCollectionName={WORK_ORDER_FORM_PROBLEM_TYPES_AUTOCOMPLETE}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleProblemTypes(ids, targetCollectionName);
							}}
							onChange={this.handleProblemTypeChange}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchProblemTypes(
									{ name: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							renderRecord={(problemType) => (
								<Select.Option key={problemType.id} value={problemType.id}>
									{problemType.hierarchyName}
								</Select.Option>
							)}
							additionalFilters={{
								locationId: nullSafeGet('locationId', this.state),
								industryId: nullSafeGetOrElse('industryId', this.state, 1),
								brandId: nullSafeGet('location.brandId', this.state),
							}}
							sortBy={{ sort_by: 'name', order: 'ascend' }}
						/>
					)}
				</Form.Item>

				<Form.Item label="Affected Asset">
					{getFieldDecorator('assetId', {
						initialValue: formData.assetId,
					})(
						<OWAsyncSelect
							stateSlice={assets}
							allowClear={true}
							targetCollectionName={WORK_ORDER_FORM_ASSETS_AUTOCOMPLETE}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleAssets(ids, targetCollectionName);
							}}
							onChange={this.handleAssetChange}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchAssets(
									{ search: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							renderRecord={(asset) => (
								<Select.Option key={asset.id} value={asset.id}>
									<div className="flex flex-col">
										<div>{asset.name}</div>
										<AssetNumberDisplay asset={asset} />
									</div>
								</Select.Option>
							)}
							additionalFilters={{
								locationId: nullSafeGetOrElse('locationId', this.state, formData.locationId),
								problemTypeId: nullSafeGetOrElse(
									'problemTypeId',
									this.state,
									formData.problemTypeId
								),
								...(areaFilterAllowed &&
									nullSafeGetOrElse('areaId', this.state, formData.areaId) && {
										areaIdOrNull: nullSafeGetOrElse('areaId', this.state, formData.areaId),
									}),
							}}
							sortBy={{ sort_by: 'name', order: 'ascend' }}
						/>
					)}
				</Form.Item>
				<Form.Item label="Additional Info">
					{getFieldDecorator('additionalInfo', {
						initialValue: formData.description,
					})(<Input.TextArea style={{ maxWidth: 300 }} />)}
				</Form.Item>
				<Form.Item label="Photos">
					{getFieldDecorator(`images`, {
						valuePropName: 'fileList',
						getValueFromEvent: normFile,
						initialValue: this.state.allPhotos,
						rules: getImageCountErrorRules(minPhotosCount, imageCountErrorMessage),
					})(
						<OWUpload
							accept="image/*"
							name="file"
							listType="picture-card"
							className="workorder-image-uploader"
							multiple={true}
							headers={uploadHeaders}
							onPreview={this.handlePreview}
							action={`${BACKEND_URI}/api/v1/buyer/file/upload`}
							beforeUpload={imageUploadValidation}
							onChange={this.handleImageUpdate}
						>
							<div style={{ display: 'inline' }}>
								<LegacyIcon type={this.state.uploadLoading ? 'loading' : 'plus'} />
								<div className="ant-upload-text">Upload</div>
							</div>
							<Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
								<img alt="example" style={{ width: '100%' }} src={previewImage} />
							</Modal>
						</OWUpload>
					)}
				</Form.Item>
				<Form.Item
					label="NTE"
					validateStatus={approvalHeirarchy.status}
					help={
						approvalHeirarchy.status
							? `This NTE change bypasses the requirement of a quote. Based on approval hierarchy, you can set NTE upto $${approvalHeirarchy.amount}`
							: ''
					}
				>
					{getFieldDecorator('nte', {
						initialValue: formData.nte,
						validateTrigger: 'onChange',
						rules: [
							{
								validator: (rule, value, callback) => {
									if (
										approvalHeirarchy.amount &&
										parseInt(value) > parseInt(approvalHeirarchy.amount) &&
										parseInt(value) !== formData.nte
									) {
										this.setState({
											approvalHeirarchy: {
												...approvalHeirarchy,
												status: isAdmin ? 'warning' : 'error',
											},
										});
										callback(isAdmin ? undefined : '');
									} else {
										this.setState({
											approvalHeirarchy: {
												...approvalHeirarchy,
												status: null,
											},
										});
										callback();
									}
								},
							},
						],
					})(
						<Input
							style={{ maxWidth: 300 }}
							disabled={
								currentUser.roles &&
								currentUser.roles.some((role) => BUYER_STORE_ASSOCIATE === role)
							}
						/>
					)}
				</Form.Item>
				<Form.Item label="Priority">
					{getFieldDecorator('workOrderPriorityId', {
						initialValue: formData.woPriorityId,
						rules: [{ required: true, message: 'This field is required.' }],
					})(
						<OWAsyncSelect
							stateSlice={workOrderPriorities}
							targetCollectionName={WORK_ORDER_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleWorkOrderPriorities(ids, targetCollectionName);
							}}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchWorkOrderPriorities(
									{ name: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							renderRecord={(workOrderPriority) => (
								<Select.Option key={workOrderPriority.id} value={workOrderPriority.id}>
									{workOrderPriority.name}
								</Select.Option>
							)}
						/>
					)}
				</Form.Item>
				{poEntryEnabled ? (
					<Form.Item label="PO Number">
						{getFieldDecorator('poNumber', {
							initialValue: formData.poNumber,
						})(<Input style={{ maxWidth: 300 }} />)}
					</Form.Item>
				) : null}
				{getFieldDecorator('status', {
					initialValue: formData.status,
				})}
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						loading={updating}
						className="contactForm__button"
					>
						{submitLabel}
					</Button>
					<span style={{ marginLeft: '16px' }}>
						<BackButton buttonText="Cancel" />
					</span>
				</Form.Item>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,
	isApprovalForm: ownProps.isApprovalForm,
	createErrors: state.work_orders.createErrors,
	updateErrors: state.work_orders.updateErrors,
	formData: ownProps.formData,
	creating: state.work_orders.creating,
	updating: state.work_orders.updating,
	locations: state.locations,
	assets: state.assets,
	areas: state.areas,
	problemTypes: state.problem_types,
	spendCategories: state.spend_categories,
	workOrderPriorities: state.work_order_priorities,
	supplierFacilities: state.supplier_facilities,
	currentUser: state.session.currentUser,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			locationsRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleAssets: (ids, targetCollectionName) =>
		dispatch(assetsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchAssets: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			assetsRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchBuyer: (id) => dispatch(buyersRestCrudThunksForBuyer.readOne(id)),
	fetchMultipleAreas: (ids, targetCollectionName) =>
		dispatch(areasRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchAreas: (params, targetCollectionName, pagination, sorting, filters, addToTargetCollection) =>
		dispatch(
			areasRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleProblemTypes: (ids, targetCollectionName) =>
		dispatch(problemTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchProblemTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			problemTypesRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleSpendCategories: (ids, targetCollectionName) =>
		dispatch(spendCategoriesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchSpendCategories: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			spendCategoriesRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchWorkOrderAttributes: (params) =>
		dispatch(workOrderAttributesRestCrudThunksForBuyer.read(params)),
	fetchMultipleWorkOrderPriorities: (ids, targetCollectionName) =>
		dispatch(workOrderPrioritiesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchWorkOrderPriorities: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			workOrderPrioritiesRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchSupplierFacilitiesInPrivateNetwork: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters
	) =>
		dispatch(
			fetchSupplierFacilitiesInPrivateNetworkForBuyer(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters
			)
		),
	fetchMultipleSupplierFacilitiesInPrivateNetwork: (ids, targetCollectionName) =>
		dispatch(supplierFacilitiesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	updateWorkOrderForm: (workOrder) => dispatch(workOrdersRestCrudThunksForBuyer.update(workOrder)),
	fetchSupplierFacility: (id) => dispatch(supplierFacilitiesRestCrudThunksForBuyer.readOne(id)),
	fetchSupplierCompany: (id) => dispatch(suppliersRestCrudThunksForBuyer.readOne(id)),
	fetchApprovalHeirarchy: (id) => dispatch(getBuyerCompanyApprovalTiers(ROLE_TYPES.BUYER, null)()),
	fetchProblemType: (id) => dispatch(problemTypesRestCrudThunksForBuyer.readOne(id)),
	fetchLocation: (id) => dispatch(locationsRestCrudThunksForBuyer.readOne(id)),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<WorkOrderFormProps>()(WorkOrderPendingEditForm))
);

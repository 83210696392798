import * as React from 'react';

import { CloseOutlined, PlusOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Input, Alert, Button, DatePicker } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { supplierFacilitiesRestCrudThunksForSupplier } from '../../thunks/supplier_facilities_thunks';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import moment from 'moment';
import FileUploader from '../file_uploader/FileUploader';

const style = require('./EditSupplierLicensesForm.less');
const FormItem = Form.Item;

interface EditSupplierLicenseProps {
	loading: boolean;
	licenses: any[];
	updateSupplier: any;
	successCallback: any;
	cancelCallback: any;
	supplierFacility: any;

	errors: string[];
}

interface EditSupplierLicenseFormProps extends FormComponentProps {
	loading: boolean;
	licenses: any[];
	updateSupplier: any;
	successCallback: any;
	cancelCallback: any;
	supplierFacility: any;

	errors: string[];
}

const DATE_FORMAT = 'MM-DD-YYYY';
let licenseUuid = 1;

class EditSupplierLicenseForm extends React.Component<EditSupplierLicenseFormProps, any> {
	handleSubmit = (e) => {
		const { updateSupplier, successCallback, supplierFacility, form } = this.props;
		e.preventDefault();
		form.validateFields((err, values) => {
			if (!err) {
				const licenseKeys = form.getFieldValue('licenseKeys');
				const licenses = licenseKeys
					.map((k) => ({
						licenseType: form.getFieldValue(`licenses-${k}-licenseType`),
						number: form.getFieldValue(`licenses-${k}-number`),
						issuer: form.getFieldValue(`licenses-${k}-issuer`),
						qualifier: form.getFieldValue(`licenses-${k}-qualifier`),
						expiration: form.getFieldValue(`licenses-${k}-expiration`),
						licenseFile: form.getFieldValue(`licenses-${k}-attachment`),
						isVerified:
							form.getFieldValue(`licenses-${k}-isVerified`) === undefined
								? false
								: form.getFieldValue(`licenses-${k}-isVerified`),
					}))
					.filter((el) => el.licenseType !== undefined);
				const updatedSupplier = {
					...supplierFacility,
					licenses,
				};
				updateSupplier(updatedSupplier).then((resp) => successCallback(resp));
			}
		});
	};

	removeLicense = (k) => {
		const { form } = this.props;
		// can use data-binding to get
		const licenseKeys = form.getFieldValue('licenseKeys');
		// We need at least one question
		if (licenseKeys.length === 1) {
			return;
		}

		// can use data-binding to set
		form.setFieldsValue({
			licenseKeys: licenseKeys.filter((key) => key !== k),
		});
	};

	handleAttachmentUploadChange = (fieldName) => (newAttachments) => {
		const { form } = this.props;
		if (newAttachments.length > 0) {
			const attachment = newAttachments[0];
			form.setFieldsValue({ [fieldName]: attachment });
		} else {
			form.setFieldsValue({ [fieldName]: undefined });
		}
	};

	addLicense = () => {
		const { form } = this.props;
		// can use data-binding to get
		const licenseKeys = form.getFieldValue('licenseKeys');
		const nextKeys = licenseKeys.concat(licenseUuid);
		// can use data-binding to set
		// important! notify form to detect changes
		form.setFieldsValue({
			licenseKeys: nextKeys,
		});
		licenseUuid++;
	};

	render() {
		const { getFieldDecorator, getFieldValue } = this.props.form;
		const { loading, errors, cancelCallback, licenses } = this.props;

		const licenseKeysInitialValue = licenses.length > 0 ? licenses.map((el, idx) => idx) : [0];
		getFieldDecorator('licenseKeys', { initialValue: licenseKeysInitialValue });

		const licenseKeys = getFieldValue('licenseKeys');

		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
				sm: { span: 4 },
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 20 },
			},
		};
		const formItemLayoutWithOutLabel = {
			wrapperCol: {
				xs: { span: 24, offset: 0 },
				sm: { span: 20, offset: 4 },
			},
		};

		const expirationDateValidator = (rule, value, callback) => {
			const now = moment();
			if (value && value < now) {
				callback('Cannot upload expired licenses.');
			}
			callback();
		};

		const formItems = licenseKeys.map((k, licenseIndex) => {
			return (
				<div key={k} className="licenses-container">
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div style={{ fontSize: '16px', color: 'black', marginBottom: '24px' }}>
							License {licenseIndex + 1}
						</div>

						<div className="remove-licenses-button">
							{licenseKeys.length > 1 ? (
								<CloseOutlined
									className="dynamic-delete-button"
									onClick={() => this.removeLicense(k)}
								/>
							) : null}
						</div>
					</div>
					<Form.Item {...formItemLayout} label="Type" required={false}>
						{getFieldDecorator(`licenses-${k}-licenseType`, {
							validateTrigger: ['onChange', 'onBlur'],
							initialValue: nullSafeGet(`${k}.licenseType`, licenses),
							rules: [
								{
									required: true,
									message: 'Please select a license type.',
								},
							],
						})(<Input style={{ width: '90%' }} />)}
					</Form.Item>
					<Form.Item {...formItemLayout} label="Number" required={false}>
						{getFieldDecorator(`licenses-${k}-number`, {
							validateTrigger: ['onChange', 'onBlur'],
							initialValue: nullSafeGet(`${k}.number`, licenses),
							rules: [
								{
									required: true,
									whitespace: true,
									message: 'Please provide your license number.',
								},
							],
						})(<Input style={{ width: '90%' }} />)}
					</Form.Item>
					<Form.Item {...formItemLayout} label="Issuer" required={false}>
						{getFieldDecorator(`licenses-${k}-issuer`, {
							validateTrigger: ['onChange', 'onBlur'],
							initialValue: nullSafeGet(`${k}.issuer`, licenses),
							rules: [
								{
									required: true,
									whitespace: true,
									message:
										'Please provide the name of the state, city, government body or other organization that issued your license.',
								},
							],
						})(<Input style={{ width: '90%' }} />)}
					</Form.Item>
					<Form.Item {...formItemLayout} label="Qualifier" required={false}>
						{getFieldDecorator(`licenses-${k}-qualifier`, {
							validateTrigger: ['onChange', 'onBlur'],
							initialValue: nullSafeGet(`${k}.qualifier`, licenses),
						})(<Input style={{ width: '90%' }} />)}
					</Form.Item>
					<Form.Item {...formItemLayout} label="Expires" required={false}>
						{getFieldDecorator(`licenses-${k}-expiration`, {
							rules: [
								{
									type: 'object',
									required: true,
									message: "Please select your license's expiration date!",
								},
								{ validator: expirationDateValidator },
							],
							initialValue: nullSafeGet(`${k}.expiration`, licenses)
								? moment(nullSafeGet(`${k}.expiration`, licenses))
								: undefined,
						})(<DatePicker format={DATE_FORMAT} />)}
					</Form.Item>
					<Form.Item {...formItemLayout} label="Attachment">
						{getFieldDecorator(
							`licenses-${k}-attachment`,
							{}
						)(
							<FileUploader
								roleType="supplier"
								handleUploadSuccess={this.handleAttachmentUploadChange(`licenses-${k}-attachment`)}
								showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
								defaultFileList={
									nullSafeGet(`${k}.licenseFile`, licenses) &&
									nullSafeGet(`${k}.licenseFile.fileName`, licenses)
										? [nullSafeGet(`${k}.licenseFile`, licenses)]
										: []
								}
								multiple={false}
							/>
						)}
					</Form.Item>
				</div>
			);
		});
		return (
			<Form onSubmit={this.handleSubmit} className="editSupplierLicenseForm">
				{errors && errors.length > 0
					? errors.map((msg, idx) => (
							<FormItem key={idx}>
								<Alert message={msg} type="error" />
							</FormItem>
					  ))
					: null}
				{formItems}
				<Form.Item label="">
					<Button type="dashed" onClick={this.addLicense} style={{ width: '100%', height: 217 }}>
						<PlusOutlined /> Add License
					</Button>
				</Form.Item>
				<FormItem>
					<Button type="primary" htmlType="submit" loading={loading}>
						Update License
					</Button>
					<Button style={{ marginLeft: 16 }} onClick={cancelCallback}>
						Cancel
					</Button>
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	loading: state.supplier_facilities.loading,
	errors: state.supplier_facilities.updateErrors,
	licenses: ownProps.licenses,
	successCallback: ownProps.successCallback,
	cancelCallback: ownProps.cancelCallback,
	supplierFacility: ownProps.supplierFacility,
});

const mapDispatchToProps = (dispatch) => ({
	updateSupplier: (supplierFacility) =>
		dispatch(supplierFacilitiesRestCrudThunksForSupplier.update(supplierFacility)),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<EditSupplierLicenseFormProps>()(EditSupplierLicenseForm))
);

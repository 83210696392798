import { METER_READINGS_CRUD_ACTION_CREATORS } from '../actions/meter_readings_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';

export const meterReadingsRestCrudThunksForBuyer = new RestCrudThunks(
	'meter_readings',
	'meter_reading',
	'meterReading',
	'meterReadings',
	METER_READINGS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'asset/'
);
export const meterReadingsRestCrudThunksForSupplier = new RestCrudThunks(
	'meter_readings',
	'meter_reading',
	'meterReading',
	'meterReadings',
	METER_READINGS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'asset/'
);

import * as React from 'react';

import { connect } from 'react-redux';

import { Layout, Spin, Row, Col, Card, Table } from 'antd';
import { floatToPercentageString } from '../../utils/DataFormatterUtils';

import {
	collectAllParentsFromTreeData,
	collectObjectsWithValidPropFromTreeData,
	filterTreeData,
	flattenTreeData,
	nullSafeGet,
	nullSafeGetOrElse,
	removeEmptyChildrenFromTreeData,
	sortTreeData,
} from '../../utils/DataAccessUtils';
import { BarChart } from '../charts/BarChart';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { withRouter } from 'react-router';
import AnalyticsControlBar from '../analytics_control_bar/AnalyticsControlBar';
import {
	clearWorkProblemTypeFilters,
	updateWorkProblemTypeFilters,
} from '../../actions/work_analytics_actions';
import { debounce } from '../../utils/PerformanceUtils';
import { EmptyState } from '../empty_state/EmptyState';
import {
	fetchWorkOrderCountByProblemTypeWithChildren,
	fetchWorkOrderCountByMonth,
} from '../../thunks/work_analytics_thunks';
import {
	workOrderPrioritiesRestCrudThunksForBuyer,
	workOrderPrioritiesRestCrudThunksForSupplier,
} from '../../thunks/work_order_priorities_thunks';
import { CSV_EXPORT_DEFAULTS, ROLE_TYPES } from '../../utils/DataConstants';
import { ExportToCsv } from 'export-to-csv';

const { Content } = Layout;
require('g2');
require('@antv/data-set');
const isEqual = require('fast-deep-equal');
const workOrderCountByProblemTypeChartConfig = {
	xAxisName: 'hierarchyName',
	yAxisName: 'subTotal',
	position: 'hierarchyName*subTotal',
	fillArea: false,
	color: 'hierarchyName',
	cols: {
		fieldName: { alias: 'Problem Type' },
		subTotal: { alias: '# Work Orders' },
	},
	yAxisLabel: {
		textStyle: {
			fontSize: '14',
			fontFamily: 'Roboto',
			fontWeight: 400,
			fill: 'rgba(0,0,0,0.65)',
		},
	},
	xAxisLabel: {
		textStyle: {
			fontSize: '14',
			fontFamily: 'Roboto',
			fontWeight: 400,
			fill: 'rgba(0,0,0,0.65)',
		},
	},
};

const CSV_TITLE = 'Work Analytics by Problem Types';

const exportReduceFunction = (obj) => ({
	name: obj.hierarchyName,
	total: nullSafeGetOrElse('workOrderCounts.subTotal', obj, 0),
	percentage: floatToPercentageString(
		nullSafeGetOrElse('workOrderCounts.percentageOfTotal', obj, 0) / 100
	),
});

class WorkAnalyticsOverviewProblemTypesPage extends React.Component<any, any> {
	state = {
		initialLoading: true,
		search: '',
		filteredTreeData: [],
		problemTypeIds: [],
	};
	debouncedCallback = debounce(
		() => {
			const { fetchWorkOrderCountsByProblemType, fetchWorkOrderCountsByMonth, analytics } =
				this.props;
			const { initialLoading } = this.state;
			const promise1 = fetchWorkOrderCountsByProblemType(analytics.problemTypeFilters);
			const promise2 = fetchWorkOrderCountsByMonth(analytics.problemTypeFilters);
			if (initialLoading) {
				Promise.all([promise1, promise2]).then(() => this.setState({ initialLoading: false }));
			}
		},
		500,
		false
	);

	componentDidMount() {
		const { fetchWorkOrderCountsByProblemType, analytics } = this.props;
		const { initialLoading } = this.state;
		// const promise3 = fetchWorkOrderPriorities({});
		if (analytics.problemTypeFilters.startDate && analytics.problemTypeFilters.endDate) {
			const promise1 = fetchWorkOrderCountsByProblemType(analytics.problemTypeFilters);
			// const promise2 = fetchWorkOrderCountsByMonth(analytics.problemTypeFilters);
			if (initialLoading) {
				Promise.all([promise1]).then(() => this.setState({ initialLoading: false }));
			}
		}
	}

	componentWillReceiveProps(nextProps) {
		const { analytics } = this.props;
		if (!isEqual(nextProps.analytics.problemTypeFilters, analytics.problemTypeFilters)) {
			this.debouncedCallback();
		}
	}

	filterByProblemTypeId = (allData) => {
		const problemTypeIds = nullSafeGetOrElse('state.problemTypeIds', this, []);
		if (problemTypeIds.length > 0) {
			return filterTreeData(allData, (obj) => problemTypeIds.includes(obj.id));
		} else {
			return allData;
		}
	};

	getDisplayData = () => {
		const woCountsByProblemType = nullSafeGetOrElse(
			'props.analytics.workOrderCountByProblemType.data',
			this,
			[]
		);

		const displayData = !!this.state.search
			? nullSafeGetOrElse('state.filteredTreeData', this, [])
			: this.filterByProblemTypeId(woCountsByProblemType);

		return sortTreeData(
			displayData,
			(a, b) =>
				parseFloat(nullSafeGetOrElse('workOrderCounts.subTotal', b, 0)) -
				parseFloat(nullSafeGetOrElse('workOrderCounts.subTotal', a, 0))
		);
	};

	onDownloadAnalytics = () => {
		const csvExporter = new ExportToCsv({
			...CSV_EXPORT_DEFAULTS,
			filename: CSV_TITLE,
			title: CSV_TITLE,
			headers: ['Problem Type', 'Total Workorders', 'Percentage'],
		});
		csvExporter.generateCsv(flattenTreeData(this.getDisplayData(), exportReduceFunction));
	};

	onSearch = (e) => {
		const woCountsByProblemType = nullSafeGetOrElse(
			'props.analytics.workOrderCountByProblemType.data',
			this,
			[]
		);

		const search = e.target.value;
		const allData = this.filterByProblemTypeId(woCountsByProblemType);

		this.setState({
			filteredTreeData: !!search
				? filterTreeData(allData, (obj) => obj.name.toLowerCase().includes(search.toLowerCase()))
				: [],
			search,
		});
	};

	removeProblemTypeIdsAndUpdateFilter = (filters) => {
		const { saveProblemTypeFilters } = this.props;
		const { problemTypeIds, ...otherFilters } = filters;
		this.setState({
			problemTypeIds,
		});
		saveProblemTypeFilters(otherFilters);
	};

	render() {
		const { clearAllProblemTypeFilters, analytics, accountActivated } = this.props;
		const { initialLoading } = this.state;
		const { workOrderCountByProblemType, problemTypeFilters } = analytics;

		const workOrderCountByProblemTypeData = collectObjectsWithValidPropFromTreeData(
			this.getDisplayData(),
			'workOrderCounts'
		)
			.map((el) => ({
				...el,
				subTotal: parseFloat(el.workOrderCounts.subTotal),
				percentageOfTotal: parseFloat(el.workOrderCounts.percentageOfTotal),
			}))
			.sort((a, b) => parseFloat(a.subTotal) - parseFloat(b.subTotal));

		const defaultSortOrder: 'ascend' | 'descend' = 'descend';
		const tooltipTemplate = `
                    <li data-index={index}>
                        <!-- The marker for each record -->
                        <span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>
                        {name}: {value}
                    </li>
        `;

		const workOrderCountByProblemTypeColumns = [
			{
				title: 'Problem Type',
				dataIndex: 'name',
				key: 'name',
				render: (text) => (
					<Ellipsis tooltip={true} style={{ display: 'inline' }} length={28}>
						{text}
					</Ellipsis>
				),
			},
			{
				title: '# Work Orders',
				dataIndex: ['workOrderCounts', 'subTotal'],
				key: 'totalSpend',
				defaultSortOrder: defaultSortOrder,
				sorter: (a, b) =>
					nullSafeGetOrElse('workOrderCounts.subTotal', a, 0) -
					nullSafeGetOrElse('workOrderCounts.subTotal', b, 0),
				render: (amount) => amount,
			},
			{
				title: '% of Total',
				dataIndex: ['workOrderCounts', 'percentageOfTotal'],
				key: 'percentageOfTotal',
				render: (num) => floatToPercentageString((num || 0) / 100),
				width: 100,
			},
		];

		return (
			<Content
				className="analyticsOverviewProblemTypesPage"
				style={{ padding: '0 0.5em', position: 'relative' }}
			>
				{/*<BackTop/>*/}
				{accountActivated ? (
					[
						<Row key={1} style={{ margin: '0.5em -8px' }} gutter={16}>
							<Col span={24}>
								<div
									style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
								>
									<div></div>
									<AnalyticsControlBar
										updateFilters={this.removeProblemTypeIdsAndUpdateFilter}
										clearFilters={clearAllProblemTypeFilters}
										supplierFacilityIds={problemTypeFilters.supplierFacilityIds}
										problemTypeIds={this.state.problemTypeIds}
										locationIds={problemTypeFilters.locationIds}
										startDate={problemTypeFilters.startDate}
										endDate={problemTypeFilters.endDate}
										isPM={problemTypeFilters.isPM}
										showDownload
										onDownload={this.onDownloadAnalytics}
										showSearch
										search={this.state.search}
										onSearch={this.onSearch}
										filterConfig={{
											workCompletedAt: true,
											problemTypes: true,
										}}
									/>
								</div>
							</Col>
						</Row>,

						<>
							<Row key={2} style={{ margin: '0.5em -8px' }} gutter={16}>
								<Col span={24}>
									<Card>
										<Spin spinning={workOrderCountByProblemType.loading || initialLoading}>
											{this.getDisplayData().length > 0 ? (
												<Table
													columns={workOrderCountByProblemTypeColumns}
													rowKey={(el) => el.id}
													dataSource={removeEmptyChildrenFromTreeData(this.getDisplayData())}
													expandable={{
														...(!!this.state.search && {
															expandedRowKeys: collectAllParentsFromTreeData(
																this.state.filteredTreeData,
																(_) => _.id
															),
														}),
													}}
												/>
											) : (
												<EmptyState
													height={120}
													headline={'No work orders found'}
													body={
														<div style={{ maxWidth: 488, textAlign: 'center' }}>
															We didn't find any work orders. Try changing your filters or expanding
															the selected time period.
														</div>
													}
												/>
											)}
										</Spin>
									</Card>
								</Col>
							</Row>
							<Row>
								<Col span={24}>
									<Card>
										<Spin spinning={workOrderCountByProblemType.loading || initialLoading}>
											{workOrderCountByProblemTypeData &&
											workOrderCountByProblemTypeData.length > 0 ? (
												<BarChart
													height={workOrderCountByProblemTypeData.length * 32}
													chartPadding={[40, 40, 40, 200]}
													data={workOrderCountByProblemTypeData}
													showTooltipTitle={false}
													tooltipItemTemplate={tooltipTemplate}
													xAxisName={nullSafeGet(
														'xAxisName',
														workOrderCountByProblemTypeChartConfig
													)}
													yAxisName={nullSafeGet(
														'yAxisName',
														workOrderCountByProblemTypeChartConfig
													)}
													xAxisLabel={nullSafeGet(
														'xAxisLabel',
														workOrderCountByProblemTypeChartConfig
													)}
													yAxisLabel={nullSafeGet(
														'yAxisLabel',
														workOrderCountByProblemTypeChartConfig
													)}
													fillArea={nullSafeGet('fillArea', workOrderCountByProblemTypeChartConfig)}
													cols={nullSafeGet('cols', workOrderCountByProblemTypeChartConfig)}
													color={nullSafeGet('color', workOrderCountByProblemTypeChartConfig)}
													position={nullSafeGet('position', workOrderCountByProblemTypeChartConfig)}
												/>
											) : (
												<EmptyState
													height={120}
													headline={'No work orders found'}
													body={
														<div style={{ maxWidth: 488, textAlign: 'center' }}>
															We didn't find any work orders. Try changing your filters or expanding
															the selected time period.
														</div>
													}
												/>
											)}
										</Spin>
									</Card>
								</Col>
							</Row>
						</>,
					]
				) : (
					<EmptyState
						graphic={
							<img
								style={{ marginBottom: 8 }}
								alt="You haven't activated your account yet."
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/letter owl.svg"
							/>
						}
						headline={"You haven't activated your account yet."}
						body={
							<div style={{ textAlign: 'center' }}>
								<div style={{ maxWidth: 560, marginBottom: 16 }}>
									Please check your email. We sent you a note with an activation link.
								</div>
							</div>
						}
					/>
				)}
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	accountActivated: state.session.accountActivated,
	workOrderPriorities: state.work_order_priorities,
	currentUser: state.session.currentUser,
	analytics: state.work_analytics,
	userType: (state as any).session.userType,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	saveProblemTypeFilters: (filters) => dispatch(updateWorkProblemTypeFilters(filters)),
	clearAllProblemTypeFilters: (filters) => dispatch(clearWorkProblemTypeFilters()),
	fetchWorkOrderPriorities: (params, filters = null, pagination = null, sorting = null) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? workOrderPrioritiesRestCrudThunksForSupplier.read(params, filters, pagination, sorting)
				: workOrderPrioritiesRestCrudThunksForBuyer.read(params, filters, pagination, sorting)
		),
	fetchWorkOrderCountsByProblemType: (params, filters = null, pagination = null, sorting = null) =>
		dispatch(
			fetchWorkOrderCountByProblemTypeWithChildren(ownProps.userType)(
				params,
				filters,
				pagination,
				sorting
			)
		),
	fetchWorkOrderCountsByMonth: (params, filters = null, pagination = null, sorting = null) =>
		dispatch(fetchWorkOrderCountByMonth(ownProps.userType)(params, filters, pagination, sorting)),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(WorkAnalyticsOverviewProblemTypesPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Dropdown, Menu, Spin } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import {
	downloadWalkthroughCSVForSupplier,
	downloadWalkthroughScoreCSVForSupplier,
	walkthroughsRestCrudThunksForSupplier,
	downloadWalkthroughPDFForSupplier,
} from '../../thunks/walkthroughs_thunks';
import { timeUntilOrSince } from '../../utils/DataFormatterUtils';
import moment from 'moment';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import PrintableWalkthrough from '../walkthroughs_detail_details_page/PrintableWalkthrough';

require('./SupplierSiteSurveysWalkthroughDetailPage.less');

const SupplierWalkthroughDetailPage: FC<any> = ({
	getWalkthrough,
	match,
	history,
	walkthroughsFetching,
	walkthrough,
	downloadWalkthroughCSV,
	downloadWalkthroughScoreCSV,
	companyConfig,
	childRoutes,
}): React.ReactElement => {
	const [exporting, setExporting] = useState(false);

	const walkthroughId = useMemo(() => match.params.id, [match]);

	useEffect(() => {
		getWalkthrough(walkthroughId);
	}, [walkthroughId, getWalkthrough]);

	const handleTabChange = useCallback(
		(key) => {
			history.push(`/supplier/siteSurveys/walkthrough/detail/${walkthroughId}/${key}`);
		},
		[walkthroughId, history]
	);

	const checkTabActive = useCallback(
		(key) => {
			const pathname = history.location.pathname;
			return pathname.indexOf(key) !== -1;
		},
		[history]
	);

	const downloadCSV = useCallback(
		() => downloadWalkthroughCSV(walkthroughId),
		[walkthroughId, downloadWalkthroughCSV]
	);

	const downloadScoreCSV = useCallback(
		() => downloadWalkthroughScoreCSV(walkthroughId),
		[walkthroughId, downloadWalkthroughScoreCSV]
	);

	const onPrintInitiate = useCallback(() => setExporting(true), []);
	const onPrintStart = useCallback(() => setExporting(false), []);

	const exportMenu = useMemo(
		() => (
			<Menu>
				<Menu.Item onClick={downloadScoreCSV}>Export Score CSV</Menu.Item>
				<Menu.Item onClick={downloadCSV}>Export CSV</Menu.Item>
				<Menu.Item>
					<PrintableWalkthrough
						walkthrough={walkthrough}
						onPrintInitiate={onPrintInitiate}
						onPrintStart={onPrintStart}
					/>
				</Menu.Item>
			</Menu>
		),
		[downloadScoreCSV, downloadCSV, walkthrough, onPrintInitiate, onPrintStart]
	);

	const tabs = useMemo(
		() => [
			{
				key: 'details',
				name: 'Overview',
			},
		],
		[]
	);

	return (
		<div className="walkthroughsDetailPage">
			<DetailPageHeader
				exactPath={'/supplier/siteSurveys/walkthrough/detail/:id'}
				redirectPath={`/supplier/siteSurveys/walkthrough/detail/${walkthroughId}/details`}
				backLinkText={'Back to site survey'}
				backLinkTo={`/supplier/siteSurveys/overview/all`}
				title={walkthroughsFetching ? null : walkthrough.title}
				subtitle={
					<div className="walkthroughsDetailPage__subtitleRow">
						<div className="walkthroughsDetailPage__subtitle">
							{nullSafeGet('location.name', walkthrough)}
						</div>
					</div>
				}
				actions={[
					<Dropdown overlay={exportMenu}>
						<Button key={1} style={{ marginRight: 16 }} ghost={true}>
							<div className="flex flex-row items-center">
								<span>Export</span>
								<div className="ml-2">
									<DownOutlined translate="" />
								</div>
								{exporting ? (
									<div className="ml-2">
										<Spin
											indicator={
												<LoadingOutlined
													translate=""
													style={{ fontSize: 16, color: '#fff' }}
													spin
												/>
											}
										/>
									</div>
								) : null}
							</div>
						</Button>
					</Dropdown>,
				]}
				tabs={tabs}
				backgroundColor={nullSafeGet('detail.config.theme.navblock', companyConfig)}
				handleTabChange={handleTabChange}
				checkTabActive={checkTabActive}
				childRoutes={childRoutes}
			/>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => ({
	walkthroughsFetching: state.walkthroughs.fetching,
	walkthrough: state.walkthroughs.detail,
	match: ownProps.match,
	history: ownProps.history,
	companyConfig: state.company_config,
});

const mapDispatchToProps = (dispatch) => ({
	getWalkthrough: (id) => dispatch(walkthroughsRestCrudThunksForSupplier.readOne(id)),
	downloadWalkthroughCSV: (id) => dispatch(downloadWalkthroughCSVForSupplier(id)),
	downloadWalkthroughScoreCSV: (id) => dispatch(downloadWalkthroughScoreCSVForSupplier(id)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(SupplierWalkthroughDetailPage)
);

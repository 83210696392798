export const FETCH_BUYER_USERS = 'FETCH_BUYER_USERS';
export function fetchBuyerUsers() {
	return {
		type: FETCH_BUYER_USERS,
	};
}

export const FETCH_BUYER_USERS_SUCCESS = 'FETCH_BUYER_USERS_SUCCESS';
export function fetchBuyerUsersSuccess(records) {
	return {
		type: FETCH_BUYER_USERS_SUCCESS,
		records,
	};
}

export const FETCH_BUYER_USERS_FAILURE = 'FETCH_BUYER_USERS_FAILURE';
export function fetchBuyerUsersFailure(errors) {
	return {
		type: FETCH_BUYER_USERS_FAILURE,
		errors,
	};
}

export const DISABLE_BUYER_USER_START = 'DISABLE_BUYER_USER_START';
export function disableBuyerUserStart(userName) {
	return {
		type: DISABLE_BUYER_USER_START,
		userName,
	};
}

export const DISABLE_BUYER_USER_SUCCESS = 'DISABLE_BUYER_USER_SUCCESS';
export function disableBuyerUserSuccess(userName) {
	return {
		type: DISABLE_BUYER_USER_SUCCESS,
		userName,
	};
}

export const DISABLE_BUYER_USER_FAILURE = 'DISABLE_BUYER_USER_FAILURE';
export function disableBuyerUserFailure(userName, errors) {
	return {
		type: DISABLE_BUYER_USER_FAILURE,
		userName,
		errors,
	};
}

export const REINVITE_BUYER_USER_START = 'REINVITE_BUYER_USER_START';
export function reinviteBuyerUserStart(userName) {
	return {
		type: REINVITE_BUYER_USER_START,
		userName,
	};
}

export const REINVITE_BUYER_USER_SUCCESS = 'REINVITE_BUYER_USER_SUCCESS';
export function reinviteBuyerUserSuccess(userName) {
	return {
		type: REINVITE_BUYER_USER_SUCCESS,
		userName,
	};
}

export const REINVITE_BUYER_USER_FAILURE = 'REINVITE_BUYER_USER_FAILURE';
export function reinviteBuyerUserFailure(userName, errors) {
	return {
		type: REINVITE_BUYER_USER_FAILURE,
		userName,
		errors,
	};
}

import React, { useCallback, useRef, useState } from 'react';
import { Button, List, Tooltip, message } from 'antd';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import {
	fetchAllUsersForBuyers,
	fetchAllUsersForSuppliers,
} from '../../thunks/work_orders_subscribers_thunks';
import { getObjectValues, nullSafeGet } from '../../utils/DataAccessUtils';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import { imageMimeTypes, imageUploadValidation } from '../../utils/ImageUtils';
import { ContactAvatar } from '../contact_avatar/ContactAvatar';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { MentionsInput, Mention } from 'react-mentions';
import OWUpload from '../OWUpload';
import './index.less';
import ChatUploader from './ChatUploader';
const videoMimeTypes = [
	'video/3gpp', //.3gp
	'video/mp4', //.mp4, .m4a, .m4p, .m4b, .m4r, .m4v
	'video/mpeg', //.m1v
	'video/ogg', //.ogg
	'video/quicktime', //.mov, .qt
	'video/webm', //.webm
	'video/x-m4v', //.m4v
	'video/ms-asf', //.asf, .wma, .wmv
	'video/x-ms-wmv', //.wmv
	'video/x-msvideo', //.avi
];

const ChatInput = ({
	handlePhotoUploadChange,
	handleStartVideoCall,
	fetchUsers,
	workOrder,
	currentUser,
	token,
	handleVideoUploadChange,
	onChange,
	value,
	fetchSupplierUsers,
}) => {
	const userType = currentUser.userType;

	const loadUsers = useCallback(
		(key: string, cb) => {
			// WorkAround: MentionsInput calls this funtion everytime. if space count is more than 1, it will not call the api.
			// https://github.com/signavio/react-mentions/issues/247#issuecomment-516529736
			const count = key.split(' ').length - 1;
			if (count < 2) {
				if (currentUser.userType === ROLE_TYPES.SUPPLIER) {
					fetchSupplierUsers(key, workOrder.id).then((data) =>
						cb(
							getObjectValues(data).map((el) => ({
								...el,
								id: el.email,
								display: `${el.nameGiven} ${el.nameFamily}`,
							}))
						)
					);
				} else {
					fetchUsers(key, workOrder.id).then((data) =>
						cb(
							getObjectValues(data).map((el) => ({
								...el,
								id: el.email,
								display: `${el.nameGiven} ${el.nameFamily}`,
							}))
						)
					);
				}
			} else {
				cb([]);
			}
		},
		[currentUser.userType, fetchSupplierUsers, fetchUsers, workOrder.id]
	);

	const debounceloadUsers = useCallback(debounce(loadUsers, 250), [loadUsers]);

	const onSearch = useCallback(
		(search: string, cb) => {
			debounceloadUsers(search, cb);
		},
		[debounceloadUsers]
	);
	function beforeVideoUpload(file) {
		const isVideo = videoMimeTypes.indexOf(file.type) !== -1;
		if (!isVideo) {
			message.error("This file either isn't a video or is not in a file type we support.");
		}
		return isVideo;
	}

	return (
		<div className="mx-4 flex flex-row">
			<div className="flex items-center bg-zinc-50  px-3">
				<a
					href={`https://live.useopenwrench.com/?roomName=${nullSafeGet(
						'facility.buyerCompanyId',
						currentUser
					)}-${workOrder.id}&authToken=${token}&userType=${userType}`}
					target="_blank"
					rel="noreferrer"
				>
					<Tooltip title="Start video call">
						<Button
							onClick={handleStartVideoCall}
							shape="circle"
							style={{ border: 'none', marginRight: 4 }}
						>
							<i style={{ fontSize: 20 }} className="icons8-font icons8-video-call"></i>
						</Button>
					</Tooltip>
				</a>
				<ChatUploader
					name="file"
					showUploadList={false}
					beforeUpload={imageUploadValidation}
					onChange={handlePhotoUploadChange}
					id={workOrder.id}
					accept={imageMimeTypes}
				>
					<Tooltip title="Upload photo">
						<Button style={{ border: 'none', marginRight: 4 }} shape="circle">
							<i style={{ fontSize: 20 }} className="icons8-font icons8-compact-camera"></i>
						</Button>
					</Tooltip>
				</ChatUploader>
				<ChatUploader
					name="file"
					showUploadList={false}
					beforeUpload={beforeVideoUpload}
					onChange={handleVideoUploadChange}
					accept={videoMimeTypes}
					id={workOrder.id}
				>
					<Tooltip title="Upload video">
						<Button style={{ border: 'none' }} shape="circle">
							<i style={{ fontSize: 20 }} className="icons8-font icons8-video"></i>
						</Button>
					</Tooltip>
				</ChatUploader>
			</div>
			<div className="w-full">
				<MentionsInput
					placeholder="Add a note to this work order."
					forceSuggestionsAboveCursor
					value={value}
					onChange={onChange}
					className="chat-input ant-input"
					singleLine
					allowSpaceInQuery={true}
				>
					<Mention
						trigger="@"
						data={onSearch}
						renderSuggestion={(user) => (
							<div className="flex flex-row ">
								<ContactAvatar
									hidePopover={true}
									placement="bottomRight"
									userType={currentUser.userType}
									contact={user}
									className="mb-2 block h-8 w-8"
									style={{
										backgroundColor: user.contactType === 'supplier' ? '#48a7bd' : '#7c48bd',
									}}
								/>
								<div className="ml-4">
									<div className="">
										<div>
											<div>
												{user.nameGiven} {user.nameFamily}
											</div>
											<div>{user.email}</div>
										</div>
									</div>
								</div>
							</div>
						)}
					/>
				</MentionsInput>
			</div>
			<div className="flex items-center bg-zinc-50 px-3">
				<Tooltip title="Send">
					<Button htmlType="submit" shape="circle" style={{ border: 'none' }}>
						<i style={{ fontSize: 20 }} className="icons8-font icons8-paper-airplane"></i>
					</Button>
				</Tooltip>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	searched_subscribers: state.searched_subscribers,
});

const mapDispatchToProps = (dispatch) => ({
	fetchUsers: (search, woId, params) => dispatch(fetchAllUsersForBuyers(search, woId, params)()),
	fetchSupplierUsers: (search, woId, params) =>
		dispatch(fetchAllUsersForSuppliers(search, woId, params)()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatInput);

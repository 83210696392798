import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	plannedMaintenanceSchedulesRestCrudThunksForBuyer,
	plannedMaintenanceSchedulesRestCrudThunksForSupplier,
} from '../../thunks/planned_maintenance_schedules_thunks';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import EditSpecificPlannedMaintenanceScheduleForm from '../edit_specific_planned_maintenance_schedule_form/EditSpecificPlannedMaintenanceScheduleForm';
import {
	fetchSupplierFacilitiesInPrivateNetworkForBuyer,
	fetchSupplierFacilitiesInPrivateNetworkForSupplier,
	supplierFacilitiesRestCrudThunksForBuyer,
	supplierFacilitiesRestCrudThunksForSupplier,
} from '../../thunks/supplier_facilities_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';

require('./EditSpecificPlannedMaintenanceSchedulePage.less');

class EditSpecificPlannedMaintenanceSchedulePage extends React.Component<any, any> {
	state = {
		initialLoading: true,
		pmSchedule: {},
	};

	componentDidMount() {
		const { getPlannedMaintenanceSchedule, match } = this.props;
		const id = match.params.id;
		getPlannedMaintenanceSchedule(id).then((pm) => {
			this.setState({ pmSchedule: pm, initialLoading: false });
		});
	}

	render() {
		const { history, userType } = this.props;

		const { initialLoading, pmSchedule } = this.state;

		//noinspection TypeScriptUnresolvedVariable
		return [
			<LogOnMountWithStandardEventProperties
				eventType={`visited ${userType} edit pm schedule page`}
			/>,
			<EditPage
				title="Edit Planned Maintenance Schedule"
				formComponentProps={{
					formData: pmSchedule,
					onSuccess: (rec, basedOn) =>
						history.push(
							`/${userType}/plannedMaintenance/detail/${encodeURIComponent(
								rec.title
							)}/${encodeURIComponent(rec.frequency)}/${encodeURIComponent(
								rec.plannedMaintenanceTemplateId
							)}/${basedOn}`
						),
				}}
				fetching={initialLoading}
				FormComponent={EditSpecificPlannedMaintenanceScheduleForm}
			/>,
		];
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	match: ownProps.match,
	plannedMaintenanceSchedules: state.planned_maintenance_schedules.detail,
	fetching: state.planned_maintenance_schedules.fetching,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getPlannedMaintenanceSchedule: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? plannedMaintenanceSchedulesRestCrudThunksForSupplier.readOne(id)
				: plannedMaintenanceSchedulesRestCrudThunksForBuyer.readOne(id)
		),
	fetchSupplierFacilitiesInPrivateNetwork: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? fetchSupplierFacilitiesInPrivateNetworkForSupplier(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters
				  )
				: fetchSupplierFacilitiesInPrivateNetworkForBuyer(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters
				  )
		),
	fetchMultipleSupplierFacilitiesInPrivateNetwork: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? supplierFacilitiesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: supplierFacilitiesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(EditSpecificPlannedMaintenanceSchedulePage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

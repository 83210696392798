import * as React from 'react';
import { BLACKS_AND_WHITES, STOPLIGHT_COLORS } from '../../utils/DataFormatterUtils';

const Color = require('color');

export default ({
	status,
	displayText,
	darkMode = false,
	style = {},
	textStyle = {},
	...props
}) => {
	let statusColor;
	let statusDisplayName;
	let statusBackgroundColor;

	switch (status) {
		case 'Not Started':
			statusColor = '#4a1e87';
			statusBackgroundColor = '#e8def8';
			break;
		case 'Completed':
			statusColor = STOPLIGHT_COLORS.pureGreen;
			statusBackgroundColor = 'rgb(230, 249, 231)';
			break;
		default:
			statusColor = STOPLIGHT_COLORS.pureYellow;
			statusBackgroundColor = 'rgb(252, 243, 217)';
	}

	if (darkMode) {
		statusColor = BLACKS_AND_WHITES.whiteSecondary;
	}

	return (
		<span
			{...props}
			style={{
				color: statusColor,
				background: statusBackgroundColor,
				padding: '4px 12px',
				borderRadius: 16,
				...style,
				...textStyle,
			}}
		>
			{displayText}
		</span>
	);
};

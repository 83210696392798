import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import ProjectCreateForm from '../project_create_form/ProjectCreateForm';

class ProjectNewPage extends React.Component<any, any> {
	render() {
		const { history } = this.props;

		return [
			<LogOnMountWithStandardEventProperties eventType="visited create project page" />,
			<EditPage
				title="Create New Project"
				formComponentProps={{
					formData: {},
					isApprovalForm: false,
					onSuccess: (rec) => history.goBack(),
				}}
				fetching={false}
				FormComponent={ProjectCreateForm}
			/>,
		];
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		history: ownProps.history,
		match: ownProps.match,
	};
};

const mapDispatchToProps = (dispatch) => ({});

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(ProjectNewPage));

import React, { FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout } from 'antd';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import StockLocationAddPartForm from '../stock_location_add_part_form/StockLocationAddOrEditPartForm';
import { partsPerStockLocationsRestCrudThunksForSupplier } from '../../thunks/parts_per_stock_locations_thunks';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { EditPage } from '../edit_page/EditPage';

const { Content } = Layout;

const EditPartsPerStockLocationPage: FC<any> = ({
	history,
	match,
	getPpsl,
	fetching,
	ppsl,
}): React.ReactElement => {
	const ppslId = useMemo(() => match.params.id, [match.params.id]);

	useEffect(() => {
		ppslId && getPpsl(ppslId);
	}, [getPpsl, ppslId]);

	return (
		<Content>
			<LogOnMountWithStandardEventProperties eventType="visited supplier edit parts per stock location page" />
			<EditPage
				title="Edit Parts Per Stock Location"
				formComponentProps={{
					formData: ppsl,
					stockLocationId: nullSafeGet('stockLocationId', ppsl),
					onSuccess: (rec) => history.goBack(),
				}}
				fetching={fetching}
				FormComponent={StockLocationAddPartForm}
			/>
		</Content>
	);
};

const mapStateToProps = (state) => {
	return {
		fetching: state.parts_per_stock_locations.fetching,
		ppsl: state.parts_per_stock_locations.detail,
	};
};

const mapDispatchToProps = (dispatch) => ({
	getPpsl: (id) => dispatch(partsPerStockLocationsRestCrudThunksForSupplier.readOne(id)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(EditPartsPerStockLocationPage)
);

import React, { FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { ROLE_TYPES } from '../../utils/DataConstants';
import ProjectTemplateForm from '../project_template_form/ProjectTemplateForm';
import {
	projectTemplatesRestCrudThunksForBuyer,
	projectTemplatesRestCrudThunksForSupplier,
} from '../../thunks/project_template_thunks';

require('./EditProjectTemplatePage.less');

const EditProjectTemplatePage: FC<any> = ({
	getProjectTemplate,
	match,
	history,
	projectTemplate,
	fetching,
	userType,
}): React.ReactElement => {
	const projectTemplateId = useMemo(() => match.params.id, [match.params.id]);

	useEffect(() => getProjectTemplate(projectTemplateId), [projectTemplateId, getProjectTemplate]);

	return (
		<>
			<LogOnMountWithStandardEventProperties eventType="visited edit asset template page" />,
			<EditPage
				title="Edit Project Template"
				formComponentProps={{
					formData: projectTemplate,
					onSuccess: (rec) => history.push(`/${userType}/projects/templates/detail/${rec.id}`),
				}}
				fetching={fetching}
				FormComponent={ProjectTemplateForm}
			/>
			,
		</>
	);
};

const mapStateToProps = (state, ownProps) => {
	return {
		history: ownProps.history,
		projectTemplate: state.project_templates.detail,
		fetching: state.project_templates.fetching,
		match: ownProps.match,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	getProjectTemplate: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? projectTemplatesRestCrudThunksForSupplier.readOne(id)
				: projectTemplatesRestCrudThunksForBuyer.readOne(id)
		),
});

const EditAssetTemplatePageWR = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(EditProjectTemplatePage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(EditAssetTemplatePageWR);

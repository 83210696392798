import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Alert } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { connect } from 'react-redux';

const FormItem = Form.Item;

interface SupplierRejectWorkFormProps extends FormComponentProps {
	onSubmit: any;
	onCancel: any;
	visible: boolean;
	createErrors: any;
	updateErrors: any;
	creating: boolean;
	updating: boolean;
	workOrderId: number;
	currentUser: any;
}

class SupplierRejectReviewWorkForm extends React.Component<SupplierRejectWorkFormProps, any> {
	render() {
		const {
			visible,
			onCancel,
			onSubmit,
			form,
			workOrderId,
			createErrors,
			updateErrors,
			creating,
			updating,
		} = this.props;
		const { getFieldDecorator } = form;
		getFieldDecorator('workOrderId', { initialValue: workOrderId });
		return (
			<Modal
				visible={visible}
				width={600}
				title="Reject Work"
				okText="Reject"
				onCancel={onCancel}
				onOk={onSubmit}
				closable={false}
				confirmLoading={creating || updating}
			>
				<Form layout="vertical" className="supplierForm">
					{createErrors.length > 0 ? (
						<FormItem>
							<Alert message={createErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					{updateErrors.length > 0 ? (
						<FormItem>
							<Alert message={updateErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					<FormItem label="Notes" required={true}>
						{getFieldDecorator('note', {
							rules: [
								{
									required: true,
									message: 'Please add a note on why this work is rejected.',
								},
							],
						})(<Input.TextArea />)}
					</FormItem>
				</Form>
			</Modal>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	createErrors: state.work_orders.createErrors,
	updateErrors: state.work_orders.updateErrors,
	creating: state.work_orders.creating,
	updating: state.work_orders.updating,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create<SupplierRejectWorkFormProps>()(SupplierRejectReviewWorkForm));

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import VendorForm from '../vendor_form/VendorForm';
import { vendorsRestCrudThunksForSupplier } from '../../thunks/vendors_thunks';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const style = require('./EditVendorPage.less');

class EditVendorPage extends React.Component<any, any> {
	componentDidMount() {
		const { getVendor, match } = this.props;
		const id = match.params.id;
		getVendor(id);
	}

	render() {
		const { history, vendor, fetching } = this.props;

		//noinspection TypeScriptUnresolvedVariable
		return [
			<LogOnMountWithStandardEventProperties eventType="visited supplier edit vendor page" />,
			<EditPage
				title="Edit Vendor"
				formComponentProps={{
					formData: vendor,
					onSuccess: (rec) => history.push(`/supplier/vendors/detail/${rec.id}`),
				}}
				fetching={fetching}
				FormComponent={VendorForm}
			/>,
		];
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		history: ownProps.history,
		vendor: state.vendors.detail,
		fetching: state.vendors.fetching,
		match: ownProps.match,
	};
};

const mapDispatchToProps = (dispatch) => ({
	getVendor: (id) => dispatch(vendorsRestCrudThunksForSupplier.readOne(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditVendorPage));

import React, { FC } from 'react';

const style = require('./EquipmentRentalRowDisplay.less');

export const EquipmentRentalRowDisplay: FC<any> = ({ isRental, ...otherProps }) => {
	let color = undefined;
	let text = '';

	if (isRental === true) {
		color = 'orange';
		text = 'Rented';
	} else {
		color = 'green';
		text = 'Owned';
	}

	return (
		<div>
			<span style={{ color }}>{text}</span>
		</div>
	);
};

import * as React from 'react';
import { hasApprovalHierarchyChanged } from '../thunks/company_approval_thunks';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { joinList } from '../utils/DataFormatterUtils';

const ApprovalHierarchyChangeModal = ({
	visible,
	onOk,
	onCancel,
	param,
	approvalHierarchyChanges = [],
}) => (
	<Modal
		visible={visible}
		title={'Warning'}
		onOk={onOk(param)}
		okText={'Proceed'}
		onCancel={onCancel}
	>
		<span>This will change the {joinList(approvalHierarchyChanges)} approval hierarchy</span>
	</Modal>
);

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) => ({
	willApprovalHierarchyChange: (workOrderId, params) =>
		dispatch(hasApprovalHierarchyChanged('buyer', workOrderId, params)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ApprovalHierarchyChangeModal)
);

import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { checkAuthorization } from '../roles/roles';
import PermissionDeniedPage from '../permission_denied_page/PermissionDeniedPage';
import { checkAccountSetupNeeded } from '../../utils/AuthUtils';
import {
	CURRENT_ENV_ROLE_TYPE,
	getLoginPageByRole,
	getNeedEmailAuthenticationPageByRole,
	getNewAccountSetupPageByRole,
	getSignupCompletionPageByRole,
} from '../../utils/EnvConfigUtils';

class ProtectedRoute extends React.Component<any, any> {
	render() {
		const {
			isAuthenticated,
			component: Component,
			exact,
			path,
			parentRoutes,
			childRoutes,
			authorizedRoles,
			currentUser,
			accountActivated,
			userType,
			location,
		} = this.props;
		const isAuthorized = checkAuthorization(authorizedRoles)(currentUser.roles);
		const accountSetupNeeded = checkAccountSetupNeeded(currentUser);
		const accountSetupPage = getNewAccountSetupPageByRole(userType);
		const signupCompletionPage = getSignupCompletionPageByRole(userType);
		const emailAuthenticationNeededPage = getNeedEmailAuthenticationPageByRole(userType);
		let inferredUserType;
		if (path.indexOf('buyer') >= 0) {
			inferredUserType = 'buyer';
		} else if (path.indexOf('supplier') >= 0) {
			inferredUserType = 'supplier';
		} else {
			inferredUserType = null;
		}
		const loginPage = getLoginPageByRole(inferredUserType || CURRENT_ENV_ROLE_TYPE);
		const onAccountSetupPage = new RegExp(accountSetupPage).test(path);
		const onSignupCompletionPage = new RegExp(signupCompletionPage).test(path);
		const onEmailAuthenticationNeededPage = new RegExp(emailAuthenticationNeededPage).test(path);

		if (isAuthenticated) {
			return (
				<Route
					path={path}
					exact={exact}
					render={(props) => {
						if (
							!accountSetupNeeded &&
							!onSignupCompletionPage &&
							!onEmailAuthenticationNeededPage &&
							!onAccountSetupPage &&
							!accountActivated
						) {
							// if email not yet verified redirect
							return (
								<Redirect
									to={{
										pathname: emailAuthenticationNeededPage,
										state: { from: props.location },
									}}
								/>
							);
						} else if (!onSignupCompletionPage && accountSetupNeeded && !onAccountSetupPage) {
							// if account not fully set up (e.g. missing company info) redirect
							return (
								<Redirect
									to={{
										pathname: accountSetupPage,
										state: { from: props.location },
									}}
								/>
							);
						} else {
							// if account doesn't have permission to see this page, redirect
							if (isAuthorized) {
								return (
									<Component {...props} parentRoutes={parentRoutes} childRoutes={childRoutes} />
								);
							} else {
								return <PermissionDeniedPage />;
							}
						}
					}}
				/>
			);
		} else {
			return (
				// if not authenticated, redirect
				<Redirect to={{ pathname: loginPage, state: { from: location } }} />
			);
		}
	}
}

const mapStateToProps = (state, ownProps) => ({
	isAuthenticated: state.session.isAuthenticated,
	currentUser: state.session.currentUser,
	accountActivated: state.session.accountActivated,
	userType: state.session.userType,
	...ownProps,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute));

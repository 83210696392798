import React from 'react';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { Card, Drawer, Button, Col, Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { FlexibleImageAvatar } from '../flexible-image-avatar/FlexibleImageAvatar';
import { AddressDisplay } from '../address_display/AddressDisplay';
import BuyerSuppliersDetailPage from '../buyer_suppliers_detail_page/BuyerSuppliersDetailPage';
import { checkAndFormatPhoneNumber } from '../../utils/DataFormatterUtils';
import {
	supplierPrivateNetworksV2ForBuyer,
	supplierPrivateNetworksV2ForSupplier,
} from '../../thunks/supplier_private_networks_thunks';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { isInternalTech } from '../../utils/AuthUtils';

require('./ServiceProviderCardDisplay.less');

class ServiceProviderCardDisplay extends React.Component<any, any> {
	state = {
		supplierDrawerVisible: false,
		supplierRelationship: {},
	};
	constructor(props) {
		super(props);
		const { fetchSupplierRelationship, workOrder } = props;
		const { supplierFacilityId } = workOrder;

		fetchSupplierRelationship(supplierFacilityId).then((data) => {
			this.setState({ supplierRelationship: data && data[0] });
		});
	}

	handleSupplierDrawerOpen = () => {
		this.setState({ supplierDrawerVisible: true });
	};

	handleSupplierDrawerClose = () => {
		this.setState({ supplierDrawerVisible: false });
	};

	render() {
		const { workOrder, currentUser, userType } = this.props;
		const { supplierRelationship } = this.state;

		const canAllowBuyerSupplierRelationship =
			userType === ROLE_TYPES.BUYER || isInternalTech(currentUser);

		//@ts-ignore;
		const phoneNumbers = [
			Array.from(
				new Set(
					[
						...(nullSafeGet('supplierFacility.primaryPhone', workOrder)
							? [nullSafeGet('supplierFacility.primaryPhone', workOrder)]
							: []),
						...nullSafeGetOrElse('supplierPhoneNumbers', supplierRelationship, []),
					].map((num) => checkAndFormatPhoneNumber(num))
				)
			),
		];
		//@ts-ignore;
		const emails = [
			Array.from(
				new Set([
					...(nullSafeGet('supplierFacility.primaryContactEmail', workOrder)
						? [nullSafeGet('supplierFacility.primaryContactEmail', workOrder)]
						: []),
					...nullSafeGetOrElse('workOrdersRoutedTo', supplierRelationship, []),
				])
			),
		];
		return (
			<div>
				<Drawer
					width={1000}
					placement="right"
					closable={false}
					onClose={this.handleSupplierDrawerClose}
					visible={this.state.supplierDrawerVisible}
					bodyStyle={{ padding: 0, backgroundColor: '#f0f4f5', overflowX: 'hidden' }}
				>
					<div
						//Header
						className={'absolute top-0 right-0 z-40 flex flex-row'}
					>
						<Button
							onClick={this.handleSupplierDrawerClose}
							type={'text'}
							style={{ padding: '8px' }}
						>
							<CloseOutlined translate="" style={{ fontSize: '24px', color: 'white' }} />
						</Button>
					</div>
					<BuyerSuppliersDetailPage supplierId={nullSafeGet('supplierFacilityId', workOrder)} />
				</Drawer>
				<Card
					title="Service Provider"
					onClick={canAllowBuyerSupplierRelationship ? this.handleSupplierDrawerOpen : null}
					hoverable={true}
				>
					<div className="materialCard__flexCentVert" style={{ marginBottom: 24 }}>
						<div style={{ marginRight: 16 }}>
							{nullSafeGet('supplierFacility.logoURL', workOrder) ? (
								<FlexibleImageAvatar
									displayName={nullSafeGet('supplierFacility.displayName', workOrder)}
									showPopover={false}
									popoverText={nullSafeGet('supplierFacility.displayName', workOrder)}
									squareMode={true}
									width="40px"
									height="40px"
									imageUrl={nullSafeGet('supplierFacility.logoURL', workOrder)}
								/>
							) : (
								<FlexibleImageAvatar
									displayName={nullSafeGet('supplierFacility.displayName', workOrder)}
									showPopover={false}
									popoverText={nullSafeGet('supplierFacility.displayName', workOrder)}
									squareMode={true}
									width="40px"
									height="40px"
									imageUrl="https://s3.amazonaws.com/emptor-data-assets/images/empty-state/icons8-city-filled-100.png"
								/>
							)}
						</div>
						<div>
							<div className="materialCard__title">
								{nullSafeGet('supplierFacility.displayName', workOrder)}
							</div>
							<AddressDisplay
								className="materialCard__subtitle"
								hidePostcode={true}
								hideCountry={true}
								hideStreetAddress={true}
								address={nullSafeGet('supplierFacility.primaryAddress', workOrder)}
								style={{
									color: 'rgba(0,0,0,0.45)',
								}}
							/>
						</div>
					</div>
					<div
						className="materialCard__supportingText materialCard__flexCentVert"
						style={{ marginTop: 16, marginBottom: 8 }}
					>
						<div className="materialCard__iconLabel">
							<i style={{ fontSize: 24 }} className={`icons8-font icons8-phone`} />
						</div>
						<div style={{ display: 'flex', whiteSpace: 'normal', flexWrap: 'wrap' }}>
							{phoneNumbers.map((num, i, list) => (
								<div style={{ marginRight: 4 }}>
									{checkAndFormatPhoneNumber(num)}
									{list.length - 1 > i ? ',' : null}
								</div>
							))}
						</div>
					</div>
					{nullSafeGet('supplierFacility.primaryContactEmail', workOrder) ? (
						<div
							className="materialCard__supportingText materialCard__flexCentVert"
							style={{ marginBottom: 8 }}
						>
							<div className="materialCard__iconLabel">
								<i
									style={{ fontSize: 24 }}
									className={`icons8-font icons8-secured-letter-filled`}
								/>
							</div>
							<Col>
								{emails.map((email) => (
									<Row wrap>{email}</Row>
								))}
							</Col>
						</div>
					) : null}
				</Card>
			</div>
		);
	}
}
const mapStateToProps = (state, ownProps) => ({
	currentUser: state.session.currentUser,
	supplierFacilityReviews: state.supplier_facility_reviews,
	supplierFacilitiesFetching: state.supplier_facilities.fetching,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchSupplierRelationship: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? supplierPrivateNetworksV2ForSupplier(null, 'supplier_facility', null, null, {
						supplierFacilityId: id,
				  })
				: supplierPrivateNetworksV2ForBuyer(null, 'supplier_facility', null, null, {
						supplierFacilityId: id,
				  })
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ServiceProviderCardDisplay)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

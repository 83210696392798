import { message } from 'antd';
import { getConfigValueDefaultingToTrue } from './DataAccessUtils';

export const requiredFieldsNotFilled = (form, companyConfig, newPartSelected) => {
	const { getFieldsValue } = form;
	const values = getFieldsValue();

	const defaultVendorMandatory = getConfigValueDefaultingToTrue(
		companyConfig,
		'inventoryConfig',
		'defaultVendorRequiredOnCreatingNewPart'
	);

	if (newPartSelected && defaultVendorMandatory && !values.defaultVendorId) {
		message.error('Default vendor must be selected!');
		return true;
	}

	if (!values.quantity) {
		message.error('Please enter valid quantity!');
		return true;
	}

	return false;
};

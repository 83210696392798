import posthog from 'posthog-js';

export const SOFT_ERROR = 'SOFT_ERROR';

export function softError(message) {
	return {
		type: SOFT_ERROR,
		message,
	};
}

export const SKIP_TOKEN_CHECK = 'SKIP_TOKEN_CHECK';

export function skipTokenCheck() {
	return {
		type: SKIP_TOKEN_CHECK,
	};
}

export const REQUESTING_PASSWORD_RESET = 'REQUESTING_PASSWORD_RESET';

export function requestingPasswordReset() {
	return {
		type: REQUESTING_PASSWORD_RESET,
	};
}

export const REQUEST_PASSWORD_RESET_SUCCESS = 'REQUEST_PASSWORD_RESET_SUCCESS';

export function requestPasswordResetSuccess() {
	return {
		type: REQUEST_PASSWORD_RESET_SUCCESS,
	};
}

export const REQUEST_PASSWORD_RESET_FAILURE = 'REQUEST_PASSWORD_RESET_FAILURE';

export function requestPasswordResetFailure(errors) {
	return {
		type: REQUEST_PASSWORD_RESET_FAILURE,
		errors,
	};
}

export const LOGGING_IN = 'LOGGING_IN';

export function loggingIn() {
	return {
		type: LOGGING_IN,
	};
}

export const DONE_LOGGING_IN = 'DONE_LOGGING_IN';

export function doneLoggingIn() {
	return {
		type: DONE_LOGGING_IN,
	};
}

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export function loginSuccess(
	token,
	expiresOn,
	accountActivated,
	currentUser,
	userType,
	workspaceId,
	featureFlags,
	isActive,
	isApproved
) {
	return {
		type: LOGIN_SUCCESS,
		token,
		expiresOn,
		accountActivated,
		currentUser,
		userType,
		workspaceId,
		featureFlags,
		isActive,
		isApproved,
	};
}

export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export function loginFailure(errors) {
	return {
		type: LOGIN_FAILURE,
		errors,
	};
}

export const SIGNUP_COMPLETING = 'SIGNUP_COMPLETING';

export function signUpCompleting() {
	return {
		type: SIGNUP_COMPLETING,
	};
}

export const SIGNUP_COMPLETION_SUCCESS = 'SIGNUP_COMPLETION_SUCCESS';

export function signUpCompletionSuccess(token) {
	return {
		type: SIGNUP_COMPLETION_SUCCESS,
		token,
	};
}

export const SIGNUP_COMPLETION_FAILURE = 'SIGNUP_COMPLETION_FAILURE';

export function signUpCompletionFailure(errors) {
	return {
		type: SIGNUP_COMPLETION_FAILURE,
		errors,
	};
}

export const LOGGING_OUT = 'LOGGING_OUT';

export function loggingOut() {
	return {
		type: LOGGING_OUT,
	};
}

export const DONE_LOGGING_OUT = 'DONE_LOGGING_OUT';

export function doneLoggingOut() {
	return {
		type: DONE_LOGGING_OUT,
	};
}

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export function logoutSuccess() {
	return {
		type: LOGOUT_SUCCESS,
	};
}

export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export function logoutFailure(errors) {
	return {
		type: LOGOUT_FAILURE,
		errors,
	};
}

export const SIGNING_UP = 'SIGNING_UP';

export function signingUp() {
	return {
		type: SIGNING_UP,
	};
}

export const DONE_SIGNING_UP = 'DONE_SIGNING_UP';

export function doneSigningUp() {
	return {
		type: DONE_SIGNING_UP,
	};
}

export const RESEND_CONFIRMATION_MAIL_START = 'RESEND_CONFIRMATION_MAIL_START';

export function resendConfirmationMailStart() {
	return {
		type: RESEND_CONFIRMATION_MAIL_START,
	};
}

export const RESEND_CONFIRMATION_MAIL_FAILURE = 'RESEND_CONFIRMATION_MAIL_FAILURE';

export function resendConfirmationMailFailure(errors) {
	return {
		type: RESEND_CONFIRMATION_MAIL_FAILURE,
		errors,
	};
}

export const RESEND_CONFIRMATION_MAIL_SUCCESS = 'RESEND_CONFIRMATION_MAIL_SUCCESS';

export function resendConfirmationMailSuccess() {
	return {
		type: RESEND_CONFIRMATION_MAIL_SUCCESS,
	};
}

export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';

export function signupSuccess(token = null, expiresOn = new Date()) {
	return {
		type: SIGNUP_SUCCESS,
		token,
		expiresOn,
	};
}

export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export function signupFailure(errors) {
	return {
		type: SIGNUP_FAILURE,
		errors,
	};
}

export const CHANGING_PASSWORD = 'CHANGING_PASSWORD';

export function changingPassword() {
	return {
		type: CHANGING_PASSWORD,
	};
}

export const DONE_CHANGING_PASSWORD = 'DONE_CHANGING_PASSWORD';

export function doneChangingPassword() {
	return {
		type: DONE_CHANGING_PASSWORD,
	};
}

export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';

export function passwordChangeSuccess(token) {
	return {
		type: PASSWORD_CHANGE_SUCCESS,
		token,
	};
}

export const PASSWORD_CHANGE_FAILURE = 'PASSWORD_CHANGE_FAILURE';

export function passwordChangeFailure(errors) {
	return {
		type: PASSWORD_CHANGE_FAILURE,
		errors,
	};
}

export const CHECK_TOKEN_SUCCESS = 'CHECK_TOKEN_SUCCESS';

export function checkTokenSuccess(
	token,
	expiresOn,
	accountActivated,
	currentUser,
	userType,
	workspaceId,
	featureFlags,
	isActive,
	isApproved
) {
	return {
		type: CHECK_TOKEN_SUCCESS,
		token,
		expiresOn,
		accountActivated,
		currentUser,
		userType,
		workspaceId,
		featureFlags,
		isActive,
		isApproved,
	};
}

export const ACCOUNT_ACTIVATION_SUCCESS = 'ACCOUNT_ACTIVATION_SUCCESS';

export function accountActivationSuccess() {
	return {
		type: ACCOUNT_ACTIVATION_SUCCESS,
	};
}

export const CHECK_TOKEN_FAILURE = 'CHECK_TOKEN_FAILURE';

export function checkTokenFailure(errors) {
	return {
		type: CHECK_TOKEN_FAILURE,
		errors,
	};
}

export function switchingWorkspace() {
	return {
		type: 'SWITCHING_WORKSPACE',
	};
}

export function switchingWorkspaceSuccess(id) {
	return {
		type: 'SWITCHING_WORKSPACE_SUCCESS',
		id,
	};
}

export function switchingWorkspaceFailure(errors) {
	return {
		type: 'SWITCHING_WORKSPACE_FAILURE',
		errors,
	};
}

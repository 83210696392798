import * as React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

const style = require('./EventCalendar.less');
const calendarStyle = require('../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css');

const localizer = momentLocalizer(moment); // or globalizeLocalizer

class EventCalendar extends React.Component<any, any> {
	render() {
		const { events, handleEventSelect, ...otherProps } = this.props;
		const eventPropGetter = (event) => ({
			className: `calEventCategory--${event.category}`,
		});
		console.log(otherProps.date);
		return (
			<div className="eventCalendar__wrapper" style={{}}>
				<Calendar
					localizer={localizer}
					{...otherProps}
					events={events}
					startAccessor="start"
					endAccessor="end"
					step={60}
					eventPropGetter={eventPropGetter}
					showMultiDayTimes
				/>
			</div>
		);
	}
}

export default EventCalendar;

import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import '@ant-design/compatible/assets/index.css';
import { Select, Spin, Radio, Modal, Form, Alert } from 'antd';
import * as React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
	approvalRulesRestCrudThunksForBuyer,
	approvalsRestCrudThunksForBuyer,
} from '../../thunks/company_approval_thunks';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { useMemo, useState } from 'react';
import OWAsyncTreeSelect from '../ow_async_tree_select/OWAsyncTreeSelect';
import { workOrderPrioritiesRestCrudThunksForBuyer } from '../../thunks/work_order_priorities_thunks';
import _ from 'lodash';
import {
	mappingApprovalRuleComparisonData,
	mappingURLParamsToAddApprovalButton,
} from '../../utils/DataMappingUtils';
import ProblemTypeTreeSelect from '../problem_type_tree_select';
import {
	problemTypesHeirarchicalForBuyer,
	problemTypesRestCrudThunksForBuyer,
} from '../../thunks/problem_types_thunks';

const WORK_ORDER_FORM_PROBLEM_TYPES_AUTOCOMPLETE = 'WORK_ORDER_FORM_PROBLEM_TYPES_AUTOCOMPLETE';

function ApprovalRuleModal({
	visible,
	record,
	existingRules,
	hideModal,
	currentUser,
	approvalHierarchyType,
	isDefault,

	workOrderPriorities,
	fetchWorkOrderPriorities,

	problemTypes,
	fetchProblemTypes,
	fetchMultipleProblemTypes,

	editSuccess,
	createSuccess,

	hierarchiesFetching,
	hierarchies,
	fetchHierarchyTemplates,
	type,
}) {
	const [form] = Form.useForm();
	const isUpdate = !!nullSafeGet('id', record);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(undefined);

	useMemo(() => {
		fetchHierarchyTemplates({ limit: 50, offset: 0 });
	}, []);

	const handleSubmit = (values) => {
		let approvalRule = {
			id: values.id,
			woPriorityIds: values.woPriorityIds,
			problemTypeIds: values.problemTypeIds,
			isCapex: values.isCapex,
			isPM: values.isPM,
			approvalHierarchyId: values.approvalHierarchyId,
			approvalHierarchyType: approvalHierarchyType,
			isDefault,
		};
		if (
			existingRules.some(
				(er) =>
					_.isEqual(
						mappingApprovalRuleComparisonData(er),
						mappingApprovalRuleComparisonData(approvalRule)
					) && er.id !== approvalRule.id
			)
		) {
			setErrorMessage('Error: Approval Rule with these conditions already exists.');
		} else if (
			approvalRule.isCapex === undefined &&
			approvalRule.isPM === undefined &&
			approvalRule.woPriorityIds === undefined &&
			approvalRule.problemTypeIds === undefined &&
			!isDefault
		) {
			setErrorMessage('Error: You must select a condition.');
		} else {
			setButtonLoading(true);
			const submitRule = isUpdate ? editSuccess : createSuccess;
			submitRule(approvalRule)
				.then(() => {
					setButtonLoading(false);
					setErrorMessage(undefined);
					hideModal();
				})
				.catch((e) => {
					console.log(e);
					console.log('error');
				});
		}
	};

	const hierarchiesDropdownRecords = getRecordsForTargetCollection(
		hierarchies,
		'APPROVAL_HIERARCHY_AUTOCOMPLETE'
	);

	return (
		<Modal
			visible={visible}
			width={600}
			title={
				isDefault
					? 'Select Default Approval Hierarchy'
					: isUpdate
					? 'Update Approval Rule'
					: 'Add Approval Rule'
			}
			okText={isUpdate ? 'Update Rule' : 'Add Rule'}
			onOk={form.submit}
			confirmLoading={buttonLoading}
			onCancel={hideModal}
			closable={true}
			destroyOnClose={true}
		>
			<Form
				layout="vertical"
				form={form}
				preserve={false}
				initialValues={{}}
				onFinish={handleSubmit}
			>
				<Form.Item hidden={errorMessage === undefined}>
					<Alert message={errorMessage} type="error" />
				</Form.Item>
				<h6 hidden={isDefault}>If {mappingURLParamsToAddApprovalButton(type)} is</h6>
				<Form.Item name={'id'} hidden={true} initialValue={record.id}></Form.Item>
				<Form.Item
					label={'Expenditure Type'}
					name={'isCapex'}
					initialValue={record.isCapex}
					hidden={isDefault}
				>
					<Radio.Group>
						<Radio.Button style={{ flexWrap: 'nowrap' }} value={undefined}>
							Any
						</Radio.Button>
						<Radio.Button style={{ flexWrap: 'nowrap' }} value={false}>
							R&M
						</Radio.Button>
						<Radio.Button style={{ flexWrap: 'nowrap' }} value={true}>
							CapEx
						</Radio.Button>
					</Radio.Group>
				</Form.Item>
				<Form.Item
					name={'isPM'}
					label={'Work Order Type'}
					initialValue={record.isPM}
					hidden={isDefault}
				>
					<Radio.Group>
						<Radio.Button style={{ flexWrap: 'nowrap' }} value={undefined}>
							Any
						</Radio.Button>
						<Radio.Button style={{ flexWrap: 'nowrap' }} value={true}>
							Planned
						</Radio.Button>
						<Radio.Button style={{ flexWrap: 'nowrap' }} value={false}>
							Reactive
						</Radio.Button>
					</Radio.Group>
				</Form.Item>
				<Form.Item
					name={'woPriorityIds'}
					label={'Work Order Priority'}
					initialValue={nullSafeGet('woPriorityIds', record)}
					hidden={isDefault}
				>
					<OWAsyncTreeSelect
						mode={'multiple'}
						fetchData={fetchWorkOrderPriorities}
						fetchMultiple={() => {}}
						renderRecord={(workOrderPriority) => workOrderPriority.name}
						stateSlice={workOrderPriorities}
						targetCollectionName={'workOrdersAdvancedFilters'}
					/>
				</Form.Item>
				<Form.Item
					name="problemTypeIds"
					label="Problem Type"
					initialValue={nullSafeGet('problemTypeIds', record)}
					hidden={isDefault}
				>
					<ProblemTypeTreeSelect
						mode="multiple"
						stateSlice={problemTypes}
						targetCollectionName={WORK_ORDER_FORM_PROBLEM_TYPES_AUTOCOMPLETE}
						fetchMultiple={(ids, targetCollectionName) => {
							fetchMultipleProblemTypes(ids, targetCollectionName);
						}}
						fetchData={(
							searchText,
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						) =>
							fetchProblemTypes(
								{ name: searchText },
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							)
						}
						additionalFilters={{}}
						sortBy={{ sort_by: 'name', order: 'ascend' }}
					/>
				</Form.Item>
				<h6 hidden={isDefault}>then use</h6>
				<Form.Item
					label="Approval Hierarchy"
					name="approvalHierarchyId"
					rules={[{ required: true, message: 'This field is required.' }]}
					initialValue={nullSafeGet('approvalHierarchyId', record)}
				>
					<Select
						notFoundContent={hierarchiesFetching ? <Spin size="small" /> : null}
						placeholder="Type to search for an approval hierarchy"
						filterOption={false}
						showSearch={true}
					>
						{hierarchiesDropdownRecords.map((d) => (
							<Select.Option value={d.id} key={d.id}>
								<div>{d.name}</div>
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	);
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	isApprovalForm: ownProps.isApprovalForm,
	createErrors: state.work_orders.createErrors,
	updateErrors: state.work_orders.updateErrors,
	formData: ownProps.formData,
	creating: state.work_orders.creating,
	updating: state.work_orders.updating,
	currentUser: state.session.currentUser,
	hierarchies: state.approval_hierarchies,
	hierarchiesFetching: state.approval_hierarchies.fetching,
	workOrderPriorities: state.work_order_priorities,
	problemTypes: state.problem_types,
});

const mapDispatchToProps = (dispatch) => ({
	fetchApprovalRule: (id) => dispatch(approvalRulesRestCrudThunksForBuyer.readOne(id)),
	fetchWorkOrderPriorities: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) => {
		return dispatch(
			workOrderPrioritiesRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		);
	},
	fetchHierarchyTemplates: (params) =>
		dispatch(approvalsRestCrudThunksForBuyer.read(params, 'APPROVAL_HIERARCHY_AUTOCOMPLETE')),
	fetchMultipleProblemTypes: (ids, targetCollectionName) =>
		dispatch(problemTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchProblemTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			problemTypesHeirarchicalForBuyer(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApprovalRuleModal));

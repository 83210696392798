export const FETCH_SCORES_COMPARISON_START = 'FETCH_SCORES_COMPARISON_START';
export function fetchScoresComparisonStart(sourcingEventId, supplierFacilityId) {
	return {
		type: FETCH_SCORES_COMPARISON_START,
		sourcingEventId,
		supplierFacilityId,
	};
}

export const FETCH_SCORES_COMPARISON_SUCCESS = 'FETCH_SCORES_COMPARISON_SUCCESS';
export function fetchScoresComparisonSuccess(scoresDetails) {
	return {
		type: FETCH_SCORES_COMPARISON_SUCCESS,
		scoresDetails: [scoresDetails],
	};
}

export const FETCH_SCORES_COMPARISON_ERROR = 'FETCH_SCORES_COMPARISON_ERROR';
export function fetchScoresComparisonError(error) {
	return {
		type: FETCH_SCORES_COMPARISON_ERROR,
		error,
	};
}

import { PARTS_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/parts_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = standardInitialStateGenerator([
	'partsIndex',
	'VENDOR_ASSOCIATED_PARTS_TARGET_COLLECTION',
	'stockLocationAssociatedParts',
	'addPartsInWorkOrderIndex',
	'purchaseRequestsAdvancedFilters',
	'purchaseOrdersAdvancedFilters',
	'partCatalogsAdvancedFilters',
	'poLineItemsAdvancedFilters',
	'prLineItemsAdvancedFilters',
	'requestedPartsAdvancedFilters',
]);
export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState);

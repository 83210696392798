import React, { useMemo } from 'react';
import WorkOrderTable from '../WorkOrderTable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import {
	INTERNAL_SERVICE_TEAM_WO_FILTERS_ORDER,
	THIRD_PARTY_SUPPLIER_WO_FILTERS_ORDER,
} from '../../utils/WorkOrderUtils';
import posthog from 'posthog-js';

function WorkOrdersArchivedIndexPage({ currentUser }) {
	const isInternalTech = useMemo(
		() => nullSafeGetOrElse('facility.status', currentUser, '') === 'private',
		[currentUser]
	);

	const advancedFiltersConfig = useMemo(
		() =>
			isInternalTech
				? INTERNAL_SERVICE_TEAM_WO_FILTERS_ORDER
				: THIRD_PARTY_SUPPLIER_WO_FILTERS_ORDER,
		[isInternalTech]
	);
	const skipCount = posthog.isFeatureEnabled('OptimiseWorkOrderAPIforLargeData');
	const preAppliedFilters = {
		[isInternalTech ? 'hasBuyerArchived' : 'hasSupplierArchived']: true,
		no_count: skipCount,
	};
	return (
		<WorkOrderTable
			handleQueryParams
			preAppliedFilters={preAppliedFilters}
			filterConfig={{
				filters: advancedFiltersConfig,
			}}
			ignoreRefreshCounts={skipCount}
			hideExportButton={skipCount}
			targetCollectionName="archivedWorkOrdersIndex"
		/>
	);
}

export default withRouter(
	connect(
		(state) => ({
			currentUser: (state as any).session.currentUser,
		}),
		() => ({})
	)(WorkOrdersArchivedIndexPage)
);

import * as React from 'react';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Input, Alert, Button } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { supplierFacilitiesRestCrudThunksForSupplier } from '../../thunks/supplier_facilities_thunks';
import { LocationAutocomplete } from '../location_autocomplete/LocationAutocomplete';
import PhoneNumberComponent from '../PhoneNumberComponent/PhoneNumberComponentAntdV3';

const FormItem = Form.Item;
const style = require('./EditSupplierContactInformationForm.less');

interface EditSupplierContactInformationFormProps extends FormComponentProps {
	loading: boolean;
	primaryAddress: any;
	primaryContactEmail: string;
	radiusOfServiceInMiles: number;
	longitude: number;
	latitude: number;
	primaryPhone: string;
	website: string;
	updateSupplier: any;
	successCallback: any;
	cancelCallback: any;
	supplierFacility: any;

	errors: string[];
}

class EditSupplierContactInformationForm extends React.Component<
	EditSupplierContactInformationFormProps,
	any
> {
	handleSubmit = (e) => {
		const { updateSupplier, successCallback, supplierFacility, form } = this.props;
		e.preventDefault();
		form.validateFields((err, values) => {
			if (!err) {
				const primaryAddress = Object.keys(values)
					.filter((k) => k.indexOf('primaryAddress') > -1)
					.reduce((acc, k) => {
						const [_, x] = k.split('-');
						acc[x] = values[k];
						return acc;
					}, {});
				const updatedSupplier = {
					...supplierFacility,
					primaryAddress: primaryAddress,
					primaryContactEmail: values.primaryContactEmail,
					website: values.website,
					primaryPhone: values.primaryPhone,
					longitude: values.longitude,
					latitude: values.latitude,
				};
				updateSupplier(updatedSupplier).then((resp) => successCallback(resp));
			}
		});
	};

	handleLocationAutocompleteSelect = (addr, lonLat, parsedAddress) => {
		const { form } = this.props;
		form.setFieldsValue({
			'primaryAddress-streetAddress1': parsedAddress.streetAddress1,
			'primaryAddress-city': parsedAddress.city,
			'primaryAddress-region': parsedAddress.region,
			'primaryAddress-postcode': parsedAddress.postcode,
			'primaryAddress-country': parsedAddress.country,
			longitude: lonLat.lng,
			latitude: lonLat.lat,
		});
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const {
			loading,
			errors,
			primaryContactEmail,
			primaryPhone,
			primaryAddress = {},
			longitude,
			latitude,
			cancelCallback,
			website,
		} = this.props;
		getFieldDecorator(`longitude`, { initialValue: longitude });
		getFieldDecorator(`latitude`, { initialValue: latitude });
		getFieldDecorator(`primaryAddress-streetAddress1`, {
			initialValue: primaryAddress.streetAddress1,
		});
		getFieldDecorator(`primaryAddress-city`, { initialValue: primaryAddress.city });
		getFieldDecorator(`primaryAddress-region`, { initialValue: primaryAddress.region });
		getFieldDecorator(`primaryAddress-postcode`, { initialValue: primaryAddress.postcode });
		getFieldDecorator(`primaryAddress-country`, { initialValue: primaryAddress.country });
		const rawAddress = `${primaryAddress.streetAddress1}  ${primaryAddress.city}, ${primaryAddress.region}, ${primaryAddress.postcode}, ${primaryAddress.country}`;
		return (
			<Form onSubmit={this.handleSubmit} className="editSupplierContactInformationForm">
				{errors && errors.length > 0
					? errors.map((msg, idx) => (
							<FormItem key={idx}>
								<Alert message={msg} type="error" />
							</FormItem>
					  ))
					: null}
				<FormItem>
					<LocationAutocomplete
						lat={this.props.form.getFieldValue('latitude')}
						lon={this.props.form.getFieldValue('longitude')}
						initialValue={rawAddress}
						placeholder="9035 Village Dr, Yosemite Valley, CA 95389"
						onSelect={this.handleLocationAutocompleteSelect}
					/>
				</FormItem>
				<FormItem>
					{getFieldDecorator('primaryContactEmail', {
						rules: [
							{
								required: true,
								message:
									'Please enter the email you want to be the primary point of contact for your business.',
							},
						],
						initialValue: primaryContactEmail,
					})(<Input placeholder="hello@acmeplumbinginc.com" />)}
				</FormItem>
				<FormItem>
					{getFieldDecorator('website', {
						initialValue: website,
					})(<Input placeholder="acmeplumbinginc.com" />)}
				</FormItem>
				<PhoneNumberComponent
					form={this.props.form}
					label="Phone Number"
					fieldProps={{
						initialValue: primaryPhone,
						name: 'primaryPhone',
					}}
					style={{ maxWidth: 200 }}
				/>
				{/*
				<FormItem>
					{getFieldDecorator('primaryPhone', {
						initialValue: primaryPhone,
					})(<Input placeholder="(209) 372-0200" />)}
				</FormItem>*/}
				<FormItem>
					<Button type="primary" htmlType="submit" loading={loading}>
						Update contact information
					</Button>
					<Button style={{ marginLeft: 16 }} onClick={cancelCallback}>
						Cancel
					</Button>
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	loading: state.supplier_facilities.loading,
	errors: state.supplier_facilities.updateErrors,
	primaryAddress: ownProps.primaryAddress,
	primaryPhone: ownProps.primaryPhone,
	primaryContactEmail: ownProps.primaryContactEmail,
	longitude: ownProps.longitude,
	latitude: ownProps.latitude,
	website: ownProps.website,
	successCallback: ownProps.successCallback,
	cancelCallback: ownProps.cancelCallback,
	supplierFacility: ownProps.supplierFacility,
});

const mapDispatchToProps = (dispatch) => ({
	updateSupplier: (supplierFacility) =>
		dispatch(supplierFacilitiesRestCrudThunksForSupplier.update(supplierFacility)),
});

export default withRouter<any, any>(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<EditSupplierContactInformationFormProps>()(EditSupplierContactInformationForm))
);

import * as React from 'react';
import { getWarrantyStatus, nullSafeGet } from '../../utils/DataAccessUtils';
import WorkOrderDisplayStatusDisplay from '../work_order_display_status_display/WorkOrderDisplayStatusDisplay';
import {
	getWorkOrderBasePriorityBackgroundColor,
	getWorkOrderBasePriorityPillBackgroundColor,
} from '../../utils/DataFormatterUtils';
import warrantyDisplay from '../common/WarrantyDisplay';

const moment = require('moment');
const style = require('./WorkOrderRowDisplaySimple.less');

export const WorkOrderRowDisplaySimple: React.FC<any> = ({ workOrder, simpler }) => {
	if (!workOrder) {
		return null;
	}
	return (
		<div className="innerRowDisplay">
			<div
				className="rowTitle"
				style={{
					whiteSpace: 'nowrap',
					color: 'rgba(0,0,0,.65)',
				}}
			>
				{workOrder.title}
			</div>
			<WorkOrderDisplayStatusDisplay status={workOrder.displayStatus} />
			{simpler ? null : (
				<div className="rowSubtitle">
					{nullSafeGet('isWarranty', workOrder) ? warrantyDisplay('Active') : null}
					<span
						className={'rowGrayChip'}
						style={{
							color: getWorkOrderBasePriorityBackgroundColor(workOrder.woPriority),
							backgroundColor: getWorkOrderBasePriorityPillBackgroundColor(workOrder.woPriority),
						}}
					>
						{nullSafeGet('woPriority.name', workOrder)}
					</span>
					{workOrder.isCapex ? <span className={'rowGrayChip'}>CapEx</span> : null}
					{moment(workOrder.dueDate).diff(moment(), 'milliseconds') < 0 &&
					workOrder.displayStatus !== 'Completed' &&
					workOrder.displayStatus !== 'Cancelled' ? (
						<span
							className={'rowGrayChip'}
							style={{
								color: 'rgb(163, 40, 40)',
								backgroundColor: 'rgb(249, 230, 230)',
								whiteSpace: 'nowrap',
							}}
						>
							Past due {moment(workOrder.dueDate).fromNow()}
						</span>
					) : (
						<span
							className={'rowGrayChip'}
							style={{
								color: 'rgba(0,0,0,0.45)',
								whiteSpace: 'nowrap',
								backgroundColor: 'white',
							}}
						>
							Reported {moment(workOrder.createdAt).fromNow()}
						</span>
					)}
				</div>
			)}
		</div>
	);
};

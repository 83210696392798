import * as React from 'react';
import { Layout, Row, Col, Card, Button, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import {
	quotesRestCrudThunksForSupplier,
	markQuotePaidForSupplier,
} from '../../thunks/quotes_thunks';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { Link } from 'react-router-dom';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import QuoteUploader from '../quote_uploader/QuoteUploader';
const { Content } = Layout;
class SupplierWorkOrderQuotesDetailPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		const { getQuotes, match } = this.props;
		const workOrderId = match.params.id;
		getQuotes({ workOrderId }, 'workOrderAssociatedQuotes');
	}
	handlePaidQuote = (quote) => {
		const { markQuotePaid } = this.props;
		markQuotePaid(quote.id).then(() => {
			message.success(`Quote paid.`);
		});
	};
	handleFileAttachmentUploadChange = (quote) => (quoteFileUrl) => {
		const { match, getQuotes, updateQuote } = this.props;
		const newQuote = {
			...quote,
			quotePDFLink: quoteFileUrl,
		};
		updateQuote(newQuote).then(() => {
			const workOrderId = match.params.id;
			setTimeout(() => getQuotes({ workOrderId }, 'workOrderAssociatedQuotes'), 100);
		});
	};
	render() {
		const { quotes, quotesFetching, match } = this.props;
		const workOrderId = match.params.id;
		const associatedQuotes = getRecordsForTargetCollection(quotes, 'workOrderAssociatedQuotes');
		return quotesFetching ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="quotesDetailDetailsPage">
				<LogOnMountWithStandardEventProperties eventType="visited supplier work order quotes page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<div>
					{associatedQuotes.map((quote) => (
						<Row style={{ margin: '16px 8px' }} gutter={12} key={quote.id}>
							<Col span={8}>
								{quotesFetching ? null : (
									<div className="rowSpacing">
										<Card title="Your Attachments">
											{quote.quotePDFLink && quote.quotePDFLink.length > 0 ? (
												<p>
													<a href={quote.quotePDFLink} target="_blank" rel="noreferrer">
														Quote PDF
													</a>
													<Button
														type="link"
														onClick={() => this.handleFileAttachmentUploadChange(quote)(undefined)}
													>
														<i className="icons8-font icons8-trash-can"></i>
													</Button>
												</p>
											) : null}
											<div style={{ marginBottom: 16 }}>
												<QuoteUploader
													roleType="supplier"
													quoteId={quote.id}
													defaultFileList={[]}
													multiple={false}
													handleUploadSuccess={this.handleFileAttachmentUploadChange(quote)}
												/>
											</div>
											{quote.quotePDFLink && quote.quotePDFLink.length > 0 ? (
												<Link to={`/supplier/workOrders/detail/${workOrderId}/quotes`}>
													<Button type="primary">Go to quote</Button>
												</Link>
											) : null}
										</Card>
									</div>
								)}
							</Col>
						</Row>
					))}
				</div>
			</Content>
		);
	}
}
const mapStateToProps = (state, ownProps) => ({
	quotesFetching: state.quotes.fetching,
	quotes: state.quotes,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
});
const mapDispatchToProps = (dispatch) => ({
	getQuotes: (params, targetCollectionName) =>
		dispatch(quotesRestCrudThunksForSupplier.read(params, targetCollectionName)),
	updateQuote: (entity) => dispatch(quotesRestCrudThunksForSupplier.update(entity)),
	markQuotePaid: (id) => dispatch(markQuotePaidForSupplier({ id })),
});
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SupplierWorkOrderQuotesDetailPage)
);

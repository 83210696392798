export const crudActionCreatorsGenerator = (ENTITY_CRUD_ACTION_CONSTANTS) => {
	return {
		fetchStart: (targetCollectionName = null) => {
			return {
				type: ENTITY_CRUD_ACTION_CONSTANTS.fetchStart,
				targetCollectionName,
			};
		},

		fetchSuccess: (
			records,
			count,
			targetCollectionName,
			pagination,
			sorting,
			filters,
			addToTargetCollection = false
		) => {
			return {
				count,
				records,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection,
				type: ENTITY_CRUD_ACTION_CONSTANTS.fetchSuccess,
			};
		},

		fetchOneSuccess: (record) => {
			return {
				record,
				type: ENTITY_CRUD_ACTION_CONSTANTS.fetchOneSuccess,
			};
		},
		fetchOneDeltaSuccess: (record) => {
			return {
				record,
				type: ENTITY_CRUD_ACTION_CONSTANTS.fetchOneDeltaSuccess,
			};
		},

		fetchError: (error, targetCollectionName) => {
			return {
				error,
				targetCollectionName,
				type: ENTITY_CRUD_ACTION_CONSTANTS.fetchError,
			};
		},

		createStart: (record) => {
			return {
				record,
				type: ENTITY_CRUD_ACTION_CONSTANTS.createStart,
			};
		},

		createSuccess: (record, targetCollectionName = null) => {
			return {
				record,
				targetCollectionName,
				type: ENTITY_CRUD_ACTION_CONSTANTS.createSuccess,
			};
		},

		createBulkSuccess: (record) => {
			return {
				record,
				type: ENTITY_CRUD_ACTION_CONSTANTS.createBulkSuccess,
			};
		},

		updateBulkSuccess: (records) => {
			return {
				records,
				type: ENTITY_CRUD_ACTION_CONSTANTS.updateBulkSuccess,
			};
		},

		createError: (error, record) => {
			return {
				error,
				record,
				type: ENTITY_CRUD_ACTION_CONSTANTS.createError,
			};
		},

		updateStart: (record) => {
			return {
				record,
				type: ENTITY_CRUD_ACTION_CONSTANTS.updateStart,
			};
		},

		updateSuccess: (record) => {
			return {
				record,
				type: ENTITY_CRUD_ACTION_CONSTANTS.updateSuccess,
			};
		},

		updateError: (error, record) => {
			return {
				error,
				record,
				type: ENTITY_CRUD_ACTION_CONSTANTS.updateError,
			};
		},

		countByStart: (countName) => {
			return {
				countName,
				type: ENTITY_CRUD_ACTION_CONSTANTS.countByStart,
			};
		},

		countBySuccess: (count, countName, targetCollectionName) => {
			return {
				count,
				countName,
				type: ENTITY_CRUD_ACTION_CONSTANTS.countBySuccess,
				targetCollectionName,
			};
		},

		countByError: (error, countName) => {
			return {
				error,
				countName,
				type: ENTITY_CRUD_ACTION_CONSTANTS.countByError,
			};
		},

		archiveStart: (record) => {
			return {
				record,
				type: ENTITY_CRUD_ACTION_CONSTANTS.archiveStart,
			};
		},

		archiveSuccess: (record) => {
			return {
				record,
				type: ENTITY_CRUD_ACTION_CONSTANTS.archiveSuccess,
			};
		},

		archiveError: (error, record) => {
			return {
				error,
				record,
				type: ENTITY_CRUD_ACTION_CONSTANTS.archiveError,
			};
		},

		unarchiveStart: (record) => {
			return {
				record,
				type: ENTITY_CRUD_ACTION_CONSTANTS.unarchiveStart,
			};
		},

		unarchiveSuccess: (record) => {
			return {
				record,
				type: ENTITY_CRUD_ACTION_CONSTANTS.unarchiveSuccess,
			};
		},

		unarchiveError: (error, record) => {
			return {
				error,
				record,
				type: ENTITY_CRUD_ACTION_CONSTANTS.unarchiveError,
			};
		},

		deleteStart: (record) => {
			return {
				record,
				type: ENTITY_CRUD_ACTION_CONSTANTS.deleteStart,
			};
		},

		deleteSuccess: (record) => {
			return {
				record,
				type: ENTITY_CRUD_ACTION_CONSTANTS.deleteSuccess,
			};
		},

		deleteError: (error, record) => {
			return {
				error,
				record,
				type: ENTITY_CRUD_ACTION_CONSTANTS.deleteError,
			};
		},

		saveFormData: (formData) => {
			return {
				type: ENTITY_CRUD_ACTION_CONSTANTS.saveFormData,
				formData,
			};
		},

		clearFormData: () => {
			return {
				type: ENTITY_CRUD_ACTION_CONSTANTS.clearFormData,
			};
		},

		updateSelections: (selections, targetCollectionName = null) => {
			return {
				selections,
				targetCollectionName,
				type: ENTITY_CRUD_ACTION_CONSTANTS.updateSelections,
			};
		},
		updateFilters: (filters, targetCollectionName = null) => {
			return {
				type: ENTITY_CRUD_ACTION_CONSTANTS.updateFilters,
				filters,
				targetCollectionName,
			};
		},

		updateSorting: (sort_by, order, targetCollectionName = null) => {
			return {
				type: ENTITY_CRUD_ACTION_CONSTANTS.updateSorting,
				sort_by,
				order,
				targetCollectionName,
			};
		},

		clearAllFilters: (targetCollectionName = null) => {
			return {
				type: ENTITY_CRUD_ACTION_CONSTANTS.clearAllFilters,
				targetCollectionName,
			};
		},

		clearAndUpdateFilters: (filters, targetCollectionName = null) => {
			return {
				type: ENTITY_CRUD_ACTION_CONSTANTS.clearAndUpdateFilters,
				filters,
				targetCollectionName,
			};
		},

		updateFiltersAndSorting: (filters, sort_by, targetCollectionName = null) => {
			return {
				type: ENTITY_CRUD_ACTION_CONSTANTS.updateFiltersAndSorting,
				filters,
				sort_by,
				targetCollectionName,
			};
		},

		clearTargetCollection: (targetCollectionName) => {
			return {
				type: ENTITY_CRUD_ACTION_CONSTANTS.clearTargetCollection,
				targetCollectionName,
			};
		},
		cloneTargetCollection: (fromTargetCollectionName, newTargetCollectionName) => {
			return {
				type: ENTITY_CRUD_ACTION_CONSTANTS.cloneTargetCollection,
				fromTargetCollectionName,
				newTargetCollectionName,
			};
		},
	};
};

export const searchActionCreatorsGenerator = (ENTITY_SEARCH_ACTION_CONSTANTS) => {
	return {
		updateSearchFilters: (searchFilters) => {
			return {
				type: ENTITY_SEARCH_ACTION_CONSTANTS.updateSearchFilters,
				searchFilters,
			};
		},

		clearAllSearchFilters: () => {
			return {
				type: ENTITY_SEARCH_ACTION_CONSTANTS.clearAllSearchFilters,
			};
		},

		searchStart: () => {
			return {
				type: ENTITY_SEARCH_ACTION_CONSTANTS.searchStart,
			};
		},

		searchSuccess: (
			searchResults,
			searchResultsCount,
			searchPagination,
			searchSorting,
			searchFilters
		) => {
			return {
				searchResultsCount,
				searchResults,
				searchPagination,
				searchSorting,
				searchFilters,
				type: ENTITY_SEARCH_ACTION_CONSTANTS.searchSuccess,
			};
		},

		receiveNationalSearchResults: (nationalSearchResults) => {
			return {
				nationalSearchResults,
				type: ENTITY_SEARCH_ACTION_CONSTANTS.receiveNationalSearchResults,
			};
		},

		receiveFacetSearchResults: (facetName, facetSearchResults) => {
			return {
				facetSearchResults,
				facetName,
				type: ENTITY_SEARCH_ACTION_CONSTANTS.receiveFacetSearchResults,
			};
		},

		searchError: (error) => {
			return {
				error,
				type: ENTITY_SEARCH_ACTION_CONSTANTS.searchError,
			};
		},
	};
};

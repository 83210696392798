export const getBulkTransferToLocationFlatfileHeader = (
	locations = [],
	transferNoteMandatory
): any[] => [
	{
		label: 'Asset ID',
		key: 'assetId',
		validators: [{ validate: 'required' }],
	},
	{
		label: 'Destination Location',
		key: 'locationId',
		type: 'select',
		options: locations,
		alternates: ['Name'],
		validators: [{ validate: 'required' }],
	},
	{
		label: 'Transfer Note',
		key: 'transferNote',
		validators: [...(transferNoteMandatory ? [{ validate: 'required' }] : [])],
	},
	{
		label: 'Effective Date (MM/DD/yyyy)',
		key: 'transferDate',
		validators: [
			{
				validate: 'regex_matches',
				regex: `[0-9]{1,2}/[0-9]{1,2}/[0-9]{2,4}`,
			},
		],
	},
];

export const getBulkTransferToSLFlatfileHeader = (
	stockLocations = [],
	allEquipmentTypesNames = [],
	transferNoteMandatory
): any[] => [
	{
		label: 'Asset ID',
		key: 'assetId',
		validators: [{ validate: 'required' }],
	},
	{
		label: 'Destination Stock Location',
		key: 'stockLocationId',
		type: 'select',
		options: stockLocations,
		alternates: ['Name'],
	},
	{
		label: 'Equipment Type',
		key: 'equipmentTypeId',
		type: 'select',
		options: allEquipmentTypesNames,
		alternates: ['Equipment'],
		validators: [{ validate: 'required' }],
	},
	{
		label: 'Transfer Note',
		key: 'transferNote',
		validators: [...(transferNoteMandatory ? [{ validate: 'required' }] : [])],
	},
	{
		label: 'Effective Date (MM/DD/yyyy)',
		key: 'transferDate',
		validators: [
			{
				validate: 'regex_matches',
				regex: `[0-9]{1,2}/[0-9]{1,2}/[0-9]{2,4}`,
			},
		],
	},
];

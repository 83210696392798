import * as React from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Alert, DatePicker } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { connect } from 'react-redux';
import moment from 'moment';
import { imageUploadValidation, normFile } from '../../utils/ImageUtils';
import { getProtectedImageUriForSupplier } from '../../utils/FileAccessUtils';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import { getImageCountErrorRules, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { retrieveCachedUserDetails } from '../../thunks/session_thunks';
import { isInternalTech } from '../../utils/AuthUtils';
import OWUpload from '../OWUpload';

const FormItem = Form.Item;
export const DATE_FORMAT = 'MMMM D, YYYY h:mm a';

interface ManualCheckInModalFormProps extends FormComponentProps {
	onSubmit: any;
	onCancel: any;
	createErrors: any;
	updateErrors: any;
	currentUser: any;
	visible: boolean;
	creating: boolean;
	updating: boolean;

	formData: any;
}

const disabledDate = (current) => {
	// Can not select days before today and today
	return current > moment().endOf('day');
};

class ManualCheckInModalForm extends React.Component<ManualCheckInModalFormProps, any> {
	constructor(props) {
		super(props);
		this.state = {
			previewVisible: false,
			uploadLoading: false,
			previewImage: false,
			photoInfo: [],
			allPhotos: [],
		};
	}

	handlePreview = (obj) => {
		let thumbUrl = null;
		let fullUrl = null;
		let photoInfo = null;
		if (obj.response.data) {
			const imgFileId = obj.response.data.fileId;
			const imgFileName = obj.response.data.fileName;
			thumbUrl = getProtectedImageUriForSupplier(imgFileId, imgFileName, 100, 100);
			fullUrl = getProtectedImageUriForSupplier(imgFileId, imgFileName, 400, 400);
			photoInfo = {
				uid: imgFileId,
				size: 1,
				name: imgFileName,
				filename: imgFileName,
				url: fullUrl,
				status: 'done',
				thumbUrl: thumbUrl,
				response: { data: { fileName: imgFileName, fileId: imgFileId } },
				type: 'image/jpeg',
			};
		}

		this.setState({
			photoInfo: [photoInfo],
			previewImage: fullUrl || thumbUrl,
			previewVisible: true,
		});
	};

	handleImageUpdate = (info) => {
		if (info.file.status === 'done') {
			this.setState({ allPhotos: info.fileList });
		} else if (info.file.status === undefined) {
			info.fileList = this.state.allPhotos;
		}
	};

	handleRemove = (file) => {
		const allPhotos = nullSafeGetOrElse('state.allPhotos', this, []);
		this.setState({
			allPhotos: allPhotos.filter((_) => _.uid !== file.uid),
		});
	};

	handleCancel = () => this.setState({ previewVisible: false });

	render() {
		const {
			visible,
			onCancel,
			onSubmit,
			currentUser,
			form,
			formData,
			createErrors,
			updateErrors,
			creating,
			updating,
		} = this.props;
		const { previewVisible, previewImage } = this.state;
		const { getFieldDecorator } = form;
		const okText = 'Check In';
		const titleText = 'Remote Check In';

		const photoStrings = this.state.allPhotos.map((p) => {
			const fileName = nullSafeGetOrElse('response.data.fileName', p, '');
			const fileId = nullSafeGetOrElse('response.data.fileId', p, '');
			return `${fileId}/${fileName}`;
		});

		const BACKEND_URI = getBackendUri();
		const uploadHeaders = { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token };
		getFieldDecorator(`id`, { initialValue: formData.id });
		getFieldDecorator(`checkInByEmail`, { initialValue: currentUser.email });
		getFieldDecorator(`checkInImages`, { initialValue: photoStrings });

		const checkInNotesMandatory = nullSafeGetOrElse(
			'facility.internalTechConfig.checkInNotesMandatory',
			currentUser,
			false
		);
		const numImagesRequired = nullSafeGetOrElse(
			'facility.internalTechConfig.numImagesRequiredOnCheckIn',
			currentUser,
			0
		);
		const imageCountErrorMessage = `You need to upload at least ${numImagesRequired} photo${
			numImagesRequired > 1 ? 's' : ''
		}`;

		return (
			<Modal
				visible={visible}
				width={600}
				title={titleText}
				okText={okText}
				onCancel={onCancel}
				onOk={onSubmit}
				closable={false}
				confirmLoading={creating || updating}
			>
				<Form layout="vertical" className="supplierForm">
					{createErrors.length > 0 ? (
						<FormItem>
							<Alert message={createErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					{updateErrors.length > 0 ? (
						<FormItem>
							<Alert message={updateErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					<FormItem label="Checked In At">
						{getFieldDecorator(`checkInTime`, {
							rules: [
								{
									type: 'object',
									required: true,
									message: 'Please select the time technician(s) started work.',
								},
							],
							initialValue: formData.checkInTime ? moment(formData.checkInTime) : undefined,
						})(
							<DatePicker
								disabledDate={disabledDate}
								style={{ width: '100%' }}
								showTime={true}
								format={DATE_FORMAT}
							/>
						)}
					</FormItem>
					<FormItem label="Add Before Photos">
						{getFieldDecorator(`images`, {
							valuePropName: 'fileList',
							getValueFromEvent: normFile,
							initialValue: this.state.allPhotos,
							rules: getImageCountErrorRules(numImagesRequired, imageCountErrorMessage),
						})(
							<OWUpload
								accept="image/*"
								name="file"
								listType="picture-card"
								className="servicecall-image-uploader"
								multiple={true}
								headers={uploadHeaders}
								onPreview={this.handlePreview}
								action={`${BACKEND_URI}/api/v1/supplier/file/upload`}
								beforeUpload={imageUploadValidation}
								onChange={this.handleImageUpdate}
								onRemove={this.handleRemove}
							>
								<div style={{ display: 'inline' }}>
									<LegacyIcon type={this.state.uploadLoading ? 'loading' : 'plus'} />
									<div className="ant-upload-text">Upload</div>
								</div>
								<Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
									<img alt="example" style={{ width: '100%' }} src={previewImage} />
								</Modal>
							</OWUpload>
						)}
					</FormItem>
					<FormItem label="Notes">
						{getFieldDecorator('checkInNotes', {
							initialValue: formData.checkInNotes,
							rules: [
								{
									required: checkInNotesMandatory,
									message: 'Check in notes is mandatory',
								},
							],
						})(<Input.TextArea style={{ maxWidth: 300 }} onClick={(e) => e.stopPropagation()} />)}
					</FormItem>
				</Form>
			</Modal>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	currentUser: state.session.currentUser,
	createErrors: state.buyer_contacts.createErrors,
	updateErrors: state.buyer_contacts.updateErrors,
	creating: state.work_orders.creating,
	updating: state.work_orders.updating,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create<ManualCheckInModalFormProps>()(ManualCheckInModalForm));

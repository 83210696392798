import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import LocationForm from '../location_form/LocationForm';
import {
	locationsRestCrudThunksForBuyer,
	locationsRestCrudThunksForSupplier,
} from '../../thunks/locations_thunks';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { ROLE_TYPES } from '../../utils/DataConstants';

const style = require('./EditLocationPage.less');

class EditLocationPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const { getLocation, match } = this.props;
		const locationId = match.params.id;
		getLocation(locationId);
	}

	render() {
		const { history, location, fetching } = this.props;

		//noinspection TypeScriptUnresolvedVariable
		return [
			<LogOnMountWithStandardEventProperties eventType="visited buyer edit location page" />,
			<EditPage
				title="Edit Location"
				formComponentProps={{
					formData: location,
					onSuccess: (rec) => history.goBack(),
				}}
				fetching={fetching}
				FormComponent={LocationForm}
			/>,
		];
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		history: ownProps.history,
		location: state.locations.detail,
		fetching: state.locations.fetching,
		match: ownProps.match,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	getLocation: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.readOne(id)
				: locationsRestCrudThunksForBuyer.readOne(id)
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(EditLocationPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import * as React from 'react';
import { FilterOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, Button, Popover } from 'antd';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { useEffect, useRef, useState } from 'react';
import ClearFilter from '../clear_filters/ClearFilters';
import { Sorter } from '../WorkOrderTable/sorters/Sorters';
import { isEqual } from 'lodash';

export function AdvancedFilters(props) {
	const {
		filtersTargetCollectionName,
		clearAndUpdateFilters,
		filtersStateSlice,
		filterConfig,
		filterPlaceHolder,
		hideClearFilters,
		updateFilters,
		applySorting,
		sorters,
		sorterItems = [],
		inputParamName = 'search',
		initialFilters = {}, //The initial states of the filters
		preAppliedFilters = {}, //Filters that are on by default and remain after a clear
		showSearch = true,
		moveClearToEnd = false,
		initialPagination,
		defaultSorters,
	} = props;

	const filters = filtersStateSlice.hasOwnProperty(filtersTargetCollectionName)
		? nullSafeGet(`${filtersTargetCollectionName}.filters`, filtersStateSlice)
		: filtersStateSlice;

	//Filters which change, but have specific starting states that are not undefined
	const defaultFilters = {};
	filterConfig
		.filter((fc) => fc.hasOwnProperty('defaultValue') && fc.hasOwnProperty('fieldName'))
		.map((fc) => (defaultFilters[fc.fieldName] = fc['defaultValue']));

	const initialSearch = nullSafeGetOrElse(
		inputParamName,
		preAppliedFilters,
		nullSafeGetOrElse(inputParamName, initialFilters, undefined)
	);

	const [search, setSearch] = useState(initialSearch);

	useEffect(() => {
		setSearch(initialSearch);
	}, [initialSearch]);

	const timer = useRef(undefined);
	useEffect(() => {
		clearTimeout(timer.current);
		timer.current =
			timer.current === undefined
				? null
				: setTimeout(() => {
						if (search !== initialSearch) {
							updateFilters(
								{ [inputParamName]: search && search.trim() },
								filtersTargetCollectionName
							);
						}
				  }, 400);
	}, [search]);

	const onChange = (e) => {
		setSearch(e.target.value.length > 0 ? e.target.value : undefined);
	};
	const clearFilters = (e) => {
		setSearch(null);
		clearAndUpdateFilters(
			{ ...preAppliedFilters, ...defaultFilters, [inputParamName]: undefined },
			filtersTargetCollectionName
		);
	};

	const isActive = filterConfig
		.filter((filter) => !filter.showOutsideDropdown)
		.some((fc) => {
			let value = fc.filtersValueAccessor(filtersStateSlice);
			value =
				value === 'true'
					? true
					: value === 'false'
					? false
					: value === 'undefined'
					? undefined
					: value;
			return Array.isArray(value)
				? value.length > 0 && value.filter((_) => !!_).length > 0
				: Object.keys(defaultFilters).includes(fc.fieldName)
				? !isEqual(defaultFilters[fc.fieldName], value)
				: value;
		});

	const buildFilter = (filter) => {
		if (filter.isSpecial) {
			return filter.component;
		} else {
			const ComponentToRender = filter.component || OWAsyncSelect;
			const renderMethod = (entity) => (
				<Select.Option key={filter.keyAccessor(entity)} value={filter.valueAccessor(entity)}>
					{filter.renderItem(entity)}
				</Select.Option>
			);
			return (
				<ComponentToRender
					allowClear={true}
					placeholder={filter.placeholder}
					style={filter.style || { width: '100%' }}
					stateSlice={filter.stateSlice}
					value={filter.filtersValueAccessor(filtersStateSlice)}
					valueAccessor={filter.valueAccessor}
					labelAccessor={filter.labelAccessor}
					onChange={filter.handleChange}
					targetCollectionName={filter.targetCollectionName}
					additionalFilters={
						filter.additionalFiltersAccessor
							? filter.additionalFiltersAccessor(filtersStateSlice)
							: {}
					}
					items={filter.items}
					fetchData={(
						searchText,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
					) =>
						filter.fetch(
							{ name: searchText },
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						)
					}
					fetchMultiple={(ids, targetCollectionName) =>
						filter.fetchMultiple(ids, targetCollectionName)
					}
					renderRecord={filter.renderRecord || renderMethod}
					sortBy={filter.sortBy}
					mode={filter.mode}
					info={filter.info}
					initialPagination={initialPagination}
				/>
			);
		}
	};

	const showFiltersUI =
		filterConfig
			.filter((filter) => !filter.hideFilter || (filter.hideFilter && filter.elementDefined))
			.filter((filter) => !filter.showOutsideDropdown).length > 0 || props.children;

	const filtersUI = filterConfig
		.filter((filter) => !filter.hideFilter)
		.filter((filter) => !filter.showOutsideDropdown)
		.map((filter) => (
			<Form.Item label={filter.label} key={filter.label}>
				{buildFilter(filter)}
			</Form.Item>
		));

	const outsideFiltersUI = filterConfig
		.filter((filter) => !filter.hideFilter)
		.filter((filter) => filter.showOutsideDropdown)
		.map((filter) => buildFilter(filter));

	const popoverContent = (
		<Form layout="vertical" style={{ width: 400, maxHeight: '90vh', overflow: 'scroll' }}>
			<h5>Additional Filters</h5>
			{props.children}
			{filtersUI}
		</Form>
	);

	return (
		<div style={{ display: 'inline-flex', flexDirection: 'row', gap: '8px' }}>
			{showSearch ? (
				<Input.Search
					placeholder={filterPlaceHolder || 'Search'}
					value={search}
					style={{ width: 180 }}
					onChange={onChange}
				/>
			) : null}
			{showFiltersUI ? (
				<Popover content={popoverContent} trigger="click">
					<Button
						type={isActive ? 'primary' : undefined}
						size="large"
						icon={<FilterOutlined translate="" />}
					>
						<span className="inline-block-visible-xxl">Filters</span>
					</Button>
				</Popover>
			) : null}
			{sorterItems.length > 0 ? (
				<Sorter value={sorters} onChange={applySorting} items={sorterItems} />
			) : null}
			{!hideClearFilters && (
				<ClearFilter
					defaultFilters={defaultFilters}
					preAppliedFilters={preAppliedFilters}
					activeFilters={filters}
					activeSorter={sorters}
					defaultSorters={defaultSorters}
					clearFilters={clearFilters}
					style={moveClearToEnd ? { order: 100 } : {}}
				/>
			)}
			{outsideFiltersUI.length > 0 ? outsideFiltersUI.map((filter) => filter) : null}
		</div>
	);
}
AdvancedFilters.contextType = AdvancedFilters;

import { combineReducers } from 'redux';
import areas from './areas_reducer';
import approval_hierarchies from './company_approval_reducer';
import approval_rules from './approval_associations_reducer';
import assets from './assets_reducer';
import buyer_settings from './buyer_settings_reducer';
import company_config from './company_config_reducer';
import supplier_settings from './supplier_settings_reducer';
import asset_models from './asset_models_reducer';
import asset_types from './asset_types_reducer';
import bidders from './bidders_reducer';
import location_types from './location_types_reducer';
import sound_effects from './sound_effects_reducer';
import locations from './locations_reducer';
import parts from './parts_reducer';
import parts_per_stock_locations from './parts_per_stock_locations_reducer';
import equipment_types from './equipment_types_reducer';
import stock_locations from './stock_locations_reducer';
import warranties from './warranties_reducer';
import part_warranties from './part_warranties_reducer';
import work_order_attributes from './work_order_attributes_reducer';
import high_level_categories from './high_level_categories_reducer';
import work_order_priorities from './work_order_priorities_reducer';
import session from './session_reducer';
import spend_analytics from './spend_analytics_reducer';
import work_analytics from './work_analytics_reducer';
import kpi_analytics from './kpi_analytics_reducer';
import tech_kpi_analytics from './tech_kpi_analytics_reducer';
import field_techs from './field_techs_reducer';
import site_surveys from './site_surveys_reducer';
import site_survey_templates from './site_survey_templates_reducer';
import walkthroughs from './walkthroughs_reducer';
import task_lists from './task_lists_reducer';
import dispatchers from './dispatchers_reducer';
import sourcing from './sourcing_reducer';
import navbar from './navbar_reducer';
import sourcing_event_supplier_assocs from './sourcing_event_supplier_assocs_reducer';
import bid_comparison from './bid_comparison_reducer';
import scores_comparison from './scores_comparison_reducer';
import blog_posts from './blog_posts_reducer';
import buyer_users from './buyer_users_reducer';
import supplier_users from './supplier_users_reducer';
import buyer_contacts from './buyer_contacts_reducer';
import buyer_facilities from './buyer_facilities_reducer';
import proposals from './proposals_reducer';
import planned_maintenance_schedule_groups from './planned_maintenance_schedule_groups_reducer';
import planned_maintenance_schedules from './planned_maintenance_schedules_reducer';
import planned_maintenance_templates from './planned_maintenance_templates_reducer';
import requests_for_proposal from './requests_for_proposal_reducer';
import service_calls from './service_calls_reducer';
import supplier_contacts from './supplier_contacts_reducer';
import supplier_facilities from './supplier_facilities_reducer';
import supplier_facility_reviews from './supplier_facility_reviews_reducer';
import supplier_private_networks from './supplier_private_networks_reducer';
import contracts from './contracts_reducer';
import currencies from './currencies_reducer';
import credits from './credits_reducer';
import event_buyer_teams from './event_buyer_teams_reducer';
import event_supplier_teams from './event_supplier_teams_reducer';
import invoices from './invoices_reducer';
import invoice_download_formats from './invoice_download_formats_reducer';
import line_item_prices from './line_item_prices_reducer';
import lump_sum_prices from './lump_sum_prices_reducer';
import line_items from './line_items_reducer';
import messages from './messages_reducer';
import meters from './meters_reducer';
import meter_types from './meter_types_reducer';
import meter_readings from './meter_readings_reducer';
import performance_milestone_payments from './performance_milestone_payments_reducer';
import performance_milestones from './performance_milestones_reducer';
import payments from './payments_reducer';
import pricing_schedules from './pricing_schedules_reducer';
import purchase_orders from './purchase_orders_reducer';
import purchase_order_line_items from './purchase_order_line_items_reducer';
import purchase_requests from './purchase_requests_reducer';
import purchase_request_line_items from './purchase_request_line_items_reducer';
import schedule_milestone_payments from './schedule_milestone_payments_reducer';
import schedule_milestones from './schedule_milestones_reducer';
import questionnaires from './questionnaires_reducer';
import scoring_matrices from './scoring_matrices_reducer';
import rate_cards from './rate_cards_reducer';
import rates from './rates_reducer';
import resources from './resources_reducer';
import resource_roles from './resource_roles_reducer';
import score_cards from './score_cards_reducer';
import sourcing_events from './sourcing_events_reducer';
import sourcing_event_group_messages from './sourcing_event_group_messages_reducer';
import sourcing_event_templates from './sourcing_event_templates_reducer';
import spend_categories from './spend_categories_reducer';
import statements_of_work from './statements_of_work_reducer';
import suppliers from './suppliers_reducer';
import vendors from './vendors_reducer';
import buyers from './buyers_reducer';
import tasks from './tasks_reducer';
import units_of_measure from './units_of_measure_reducer';
import user_notifications from './user_notifications_reducer';
import one_time_notifications from './one_time_notifications_reducer';
import problem_types from './problem_types_reducer';
import work_orders from './work_orders_reducer';
import part_catalogs from './part_catalogs_reducer';
import part_catalog_changes from './part_catalog_changes_reducer';
import equipment_catalogs from './equipment_catalogs_reducer';
import equipment_catalog_changes from './equipment_catalog_changes_reducer';
import equipment_per_stock_locations from './equipment_per_stock_locations_reducer';
import regions from './regions_reducer';
import projects from './projects_reducer';
import project_templates from './project_templates_reducer';
import searched_subscribers from './work_order_subscribers_reducer';
import saved_filter_views from './saved_filter_views_reducer';
import attention_dashboards from './attention_dashboard_reducer';
import purchase_order_receipts from './purchase_order_receipts_reducer';
import capex_associations from './capex_association_reducer';
import troubleshooting from './troubleshooting_reducer';
import troubleshooting_answers from './troubleshooting_answer_reducer';
import location_details from './location_details_reducer';
import users from './users_reducer';
import asset_history from './asset_history_reducer';
import depreciation_classes from './depreciation_class_reducer';
import impairment_files from './impairment_files_reducer';
import journal_runs from './journal_runs_reducer';
import journal_histories from './journal_histories_reducer';
import asset_net_book_values from './asset_nbvs_reducer';
import requested_parts from './requested_parts_reducer';
import work_orders_parts_equipment from './parts_equipments_work_orders_reducer';
import inventory_settings from './inventory_settings_reducer';
import equipment_types_per_stock_location from './equipment_types_per_stock_location_reducer';
import backorders from './backorders_reducer';
import brands from './brands_reducer';
import asset_types_additional_fields from './asset_types_additional_fields_reducer';
import resolution_types from './resolution_types_reducer';
import location_types_additional_fields from './location_types_additional_fields_reducer';
import all_contacts from './contacts_reducer';
import budgets from './budgets_reducer';
import downtime_analytics from './downtime_analytics_reducers';
import supplier_currency_exchange_rates from './supplier_exchange_rates_reducer';
import ppsl_audits from './ppsl_audits_reducer';
import epsl_audits from './epsl_audits_reducer';

import {
	SessionStoreState,
	AreasStoreState,
	AssetsStoreState,
	AssetModelsStoreState,
	BuyerSettingsStoreState,
	SupplierSettingsStoreState,
	AssetTypesStoreState,
	BiddersStoreState,
	CompanyConfigState,
	LocationsStoreState,
	LocationTypesStoreState,
	RegionsStoreState,
	ProblemTypesStoreState,
	PartsStoreState,
	PartCatalogsStoreState,
	PartCatalogChangesStoreState,
	EquipmentCatalogsStoreState,
	EquipmentCatalogChangesStoreState,
	PartsPerStockLocationsStoreState,
	EquipmentTypesStoreState,
	EquipmentPerStockLocationsStoreState,
	StockLocationsStoreState,
	WarrantiesStoreState,
	PartWarrantiesStoreState,
	SoundEffectsStoreState,
	WorkOrdersStoreState,
	WorkOrderAttributesStoreState,
	WorkOrderPrioritiesStoreState,
	SourcingEventSupplierAssocsStoreState,
	SpendAnalyticsStoreState,
	WorkAnalyticsStoreState,
	KPIAnalyticsStoreState,
	TechKPIAnalyticsStoreState,
	BidComparisonStoreState,
	ScoresComparisonStoreState,
	BlogPostsStoreState,
	BuyerUsersStoreState,
	ApprovalHierarchiesStoreState,
	BuyerContactsStoreState,
	BuyerFacilitiesStoreState,
	ProposalsStoreState,
	PlannedMaintenanceSchedulesStoreState,
	PlannedMaintenanceTemplatesStoreState,
	RequestsForProposalStoreState,
	ServiceCallsStoreState,
	SupplierContactsStoreState,
	SupplierFacilitiesStoreState,
	SupplierFacilityReviewsStoreState,
	SupplierPrivateNetworksStoreState,
	ContractsStoreState,
	MetersStoreState,
	MeterTypesStoreState,
	MeterReadingsStoreState,
	CurrenciesStoreState,
	CreditsStoreState,
	InvoiceDownloadFormatsStoreState,
	InvoicesStoreState,
	FieldTechsStoreState,
	SiteSurveysStoreState,
	SiteSurveyTemplatesStoreState,
	WalkthroughsStoreState,
	TaskListsStoreState,
	DispatchersStoreState,
	EventBuyerTeamsStoreState,
	EventSupplierTeamsStoreState,
	LineItemPricesStoreState,
	LumpSumPricesStoreState,
	LineItemsStoreState,
	PerformanceMilestonePaymentsStoreState,
	PerformanceMilestonesStoreState,
	PricingSchedulesStoreState,
	PurchaseOrdersStoreState,
	PurchaseOrderLineItemsStoreState,
	PurchaseRequestsStoreState,
	PurchaseRequestLineItemsStoreState,
	ScheduleMilestonePaymentsStoreState,
	ScheduleMilestonesStoreState,
	PaymentsStoreState,
	QuestionnairesStoreState,
	RateCardsStoreState,
	RatesStoreState,
	ResourceRolesStoreState,
	ResourcesStoreState,
	ScoreCardsStoreState,
	SourcingEventsStoreState,
	SourcingEventGroupMessagesStoreState,
	SourcingEventTemplatesStoreState,
	HighLevelCategoriesStoreState,
	SpendCategoriesStoreState,
	ScoringMatricesStoreState,
	StatementsOfWorkStoreState,
	SupplierUsersStoreState,
	SuppliersStoreState,
	BuyersStoreState,
	TasksStoreState,
	VendorsStoreState,
	UnitsOfMeasureStoreState,
	UserNotificationsStoreState,
	SearchedSubscribersStoreState,
	ProjectsStoreState,
	SavedFilterViewsStoreState,
	AttentionDashboardStoreState,
	PurchaseOrderReceiptStoreState,
	CapExAssocationStoreState,
	TroubleshootingState,
	TroubleshootingAnswerState,
	LocationDetails,
	UsersStoreState,
	AssetHistoryStoreState,
	DepreciationClassStoreState,
	ImpairmentFileStoreState,
	JournalRunsStoreState,
	JournalHistoriesStoreState,
	AssetNetBookValueStoreState,
	ProjectTemplatesStoreState,
	ApprovalRulesStoreState,
	RequestedPartsStoreState,
	WokrOrdersPartsEquipmentStoreState,
	InventorySettingsState,
	EquipmentTypesPerStockLocationStoreState,
	BackOrdersStoreState,
	BrandsStoreState,
	AssetTypesAdditionalFieldsStoreState,
	ResolutionTypesStoreState,
	BudgetsStoreState,
	LocationTypesAdditionalFieldsStoreState,
	ALLCONTACTSSTORE,
	SupplierExchangeRateStoreState,
	PpslAuditStoreState,
	EpslAuditStoreState,
} from '../types/models';

export interface RootState {
	session: SessionStoreState;
	sourcing: any;
	areas: AreasStoreState;
	assets: AssetsStoreState;
	asset_models: AssetModelsStoreState;
	buyer_settings: BuyerSettingsStoreState;
	supplier_settings: SupplierSettingsStoreState;
	asset_types: AssetTypesStoreState;
	bidders: BiddersStoreState;
	company_config: CompanyConfigState;
	locations: LocationsStoreState;
	location_types: LocationTypesStoreState;
	regions: RegionsStoreState;
	problem_types: ProblemTypesStoreState;
	parts: PartsStoreState;
	part_catalogs: PartCatalogsStoreState;
	part_catalog_changes: PartCatalogChangesStoreState;
	equipment_catalogs: EquipmentCatalogsStoreState;
	equipment_catalog_changes: EquipmentCatalogChangesStoreState;
	parts_per_stock_locations: PartsPerStockLocationsStoreState;
	equipment_types: EquipmentTypesStoreState;
	equipment_per_stock_locations: EquipmentPerStockLocationsStoreState;
	stock_locations: StockLocationsStoreState;
	warranties: WarrantiesStoreState;
	part_warranties: PartWarrantiesStoreState;
	sound_effects: SoundEffectsStoreState;
	work_orders: WorkOrdersStoreState;
	work_order_attributes: WorkOrderAttributesStoreState;
	work_order_priorities: WorkOrderPrioritiesStoreState;
	sourcing_event_supplier_assocs: SourcingEventSupplierAssocsStoreState;
	spend_analytics: SpendAnalyticsStoreState;
	work_analytics: WorkAnalyticsStoreState;
	kpi_analytics: KPIAnalyticsStoreState;
	tech_kpi_analytics: TechKPIAnalyticsStoreState;
	bid_comparison: BidComparisonStoreState;
	scores_comparison: ScoresComparisonStoreState;
	blog_posts: BlogPostsStoreState;
	buyer_users: BuyerUsersStoreState;
	approval_hierarchies: ApprovalHierarchiesStoreState;
	approval_rules: ApprovalRulesStoreState;
	buyer_contacts: BuyerContactsStoreState;
	buyer_facilities: BuyerFacilitiesStoreState;
	navbar: any;
	proposals: ProposalsStoreState;
	planned_maintenance_schedules: PlannedMaintenanceSchedulesStoreState;
	['planned_maintenance_schedules/grouped']: any;
	planned_maintenance_templates: PlannedMaintenanceTemplatesStoreState;
	requests_for_proposal: RequestsForProposalStoreState;
	service_calls: ServiceCallsStoreState;
	supplier_contacts: SupplierContactsStoreState;
	supplier_facilities: SupplierFacilitiesStoreState;
	supplier_facility_reviews: SupplierFacilityReviewsStoreState;
	supplier_private_networks: SupplierPrivateNetworksStoreState;
	contracts: ContractsStoreState;
	messages: any;
	meters: MetersStoreState;
	meter_types: MeterTypesStoreState;
	meter_readings: MeterReadingsStoreState;
	currencies: CurrenciesStoreState;
	credits: CreditsStoreState;
	invoice_download_formats: InvoiceDownloadFormatsStoreState;
	invoices: InvoicesStoreState;
	field_techs: FieldTechsStoreState;
	site_surveys: SiteSurveysStoreState;
	site_survey_templates: SiteSurveyTemplatesStoreState;
	walkthroughs: WalkthroughsStoreState;
	task_lists: TaskListsStoreState;
	dispatchers: DispatchersStoreState;
	event_buyer_teams: EventBuyerTeamsStoreState;
	event_supplier_teams: EventSupplierTeamsStoreState;
	line_item_prices: LineItemPricesStoreState;
	lump_sum_prices: LumpSumPricesStoreState;
	line_items: LineItemsStoreState;
	performance_milestone_payments: PerformanceMilestonePaymentsStoreState;
	performance_milestones: PerformanceMilestonesStoreState;
	pricing_schedules: PricingSchedulesStoreState;
	purchase_orders: PurchaseOrdersStoreState;
	purchase_order_line_items: PurchaseOrderLineItemsStoreState;
	purchase_requests: PurchaseRequestsStoreState;
	purchase_request_line_items: PurchaseRequestLineItemsStoreState;
	schedule_milestone_payments: ScheduleMilestonePaymentsStoreState;
	schedule_milestones: ScheduleMilestonesStoreState;
	payments: PaymentsStoreState;
	questionnaires: QuestionnairesStoreState;
	rate_cards: RateCardsStoreState;
	rates: RatesStoreState;
	resource_roles: ResourceRolesStoreState;
	resources: ResourcesStoreState;
	score_cards: ScoreCardsStoreState;
	sourcing_events: SourcingEventsStoreState;
	sourcing_event_group_messages: SourcingEventGroupMessagesStoreState;
	sourcing_event_templates: SourcingEventTemplatesStoreState;
	high_level_categories: HighLevelCategoriesStoreState;
	spend_categories: SpendCategoriesStoreState;
	scoring_matrices: ScoringMatricesStoreState;
	statements_of_work: StatementsOfWorkStoreState;
	supplier_users: SupplierUsersStoreState;
	suppliers: SuppliersStoreState;
	buyers: BuyersStoreState;
	tasks: TasksStoreState;
	vendors: VendorsStoreState;
	units_of_measure: UnitsOfMeasureStoreState;
	user_notifications: UserNotificationsStoreState;
	one_time_notifications: any;
	searched_subscribers: SearchedSubscribersStoreState;
	projects: ProjectsStoreState;
	project_templates: ProjectTemplatesStoreState;
	saved_filter_views: SavedFilterViewsStoreState;
	attention_dashboards: AttentionDashboardStoreState;
	purchase_order_receipts: PurchaseOrderReceiptStoreState;
	capex_associations: CapExAssocationStoreState;
	troubleshooting: TroubleshootingState;
	troubleshooting_answers: TroubleshootingAnswerState;
	location_details: LocationDetails;
	users: UsersStoreState;
	asset_history: AssetHistoryStoreState;
	depreciation_classes: DepreciationClassStoreState;
	impairment_files: ImpairmentFileStoreState;
	journal_runs: JournalRunsStoreState;
	journal_histories: JournalHistoriesStoreState;
	asset_net_book_values: AssetNetBookValueStoreState;
	requested_parts: RequestedPartsStoreState;
	work_orders_parts_equipment: WokrOrdersPartsEquipmentStoreState;
	inventory_settings: InventorySettingsState;
	equipment_types_per_stock_location: EquipmentTypesPerStockLocationStoreState;
	backorders: BackOrdersStoreState;
	brands: BrandsStoreState;
	asset_types_additional_fields: AssetTypesAdditionalFieldsStoreState;
	resolution_types: ResolutionTypesStoreState;
	location_types_additional_fields: LocationTypesAdditionalFieldsStoreState;
	all_contacts: ALLCONTACTSSTORE;
	budgets: BudgetsStoreState;
	downtime_analytics: any;
	supplier_currency_exchange_rates: SupplierExchangeRateStoreState;
	ppsl_audits: PpslAuditStoreState;
	epsl_audits: EpslAuditStoreState;
}

export default combineReducers<RootState>({
	areas,
	assets,
	asset_models,
	asset_types,
	bidders,
	bid_comparison,
	blog_posts,
	buyer_contacts,
	buyer_facilities,
	buyer_users,
	buyer_settings,
	buyers,
	approval_hierarchies,
	approval_rules,
	company_config,
	contracts,
	currencies,
	credits,
	dispatchers,
	event_buyer_teams,
	event_supplier_teams,
	field_techs,
	site_surveys,
	site_survey_templates,
	walkthroughs,
	task_lists,
	invoice_download_formats,
	invoices,
	kpi_analytics,
	tech_kpi_analytics,
	locations,
	location_types,
	line_item_prices,
	lump_sum_prices,
	line_items,
	one_time_notifications,
	parts,
	part_catalogs,
	part_catalog_changes,
	equipment_catalogs,
	equipment_catalog_changes,
	parts_per_stock_locations,
	equipment_types,
	equipment_per_stock_locations,
	messages,
	meters,
	meter_types,
	meter_readings,
	navbar,
	payments,
	performance_milestone_payments,
	performance_milestones,
	pricing_schedules,
	problem_types,
	purchase_orders,
	purchase_order_line_items,
	purchase_requests,
	purchase_request_line_items,
	proposals,
	high_level_categories,
	planned_maintenance_schedules,
	['planned_maintenance_schedules/grouped']: planned_maintenance_schedule_groups,
	planned_maintenance_templates,
	questionnaires,
	rate_cards,
	rates,
	regions,
	requests_for_proposal,
	resources,
	resource_roles,
	sound_effects,
	sourcing_event_supplier_assocs,
	session,
	sourcing,
	spend_analytics,
	service_calls,
	supplier_contacts,
	supplier_facilities,
	supplier_facility_reviews,
	supplier_private_networks,
	schedule_milestone_payments,
	schedule_milestones,
	score_cards,
	scores_comparison,
	stock_locations,
	scoring_matrices,
	sourcing_events,
	sourcing_event_group_messages,
	sourcing_event_templates,
	spend_categories,
	statements_of_work,
	supplier_users,
	supplier_settings,
	suppliers,
	tasks,
	searched_subscribers,
	units_of_measure,
	user_notifications,
	vendors,
	work_analytics,
	work_orders,
	warranties,
	part_warranties,
	work_order_attributes,
	work_order_priorities,
	projects,
	project_templates,
	saved_filter_views,
	attention_dashboards,
	purchase_order_receipts,
	capex_associations,
	troubleshooting,
	troubleshooting_answers,
	location_details,
	users,
	asset_history,
	depreciation_classes,
	impairment_files,
	journal_runs,
	journal_histories,
	asset_net_book_values,
	requested_parts,
	work_orders_parts_equipment,
	inventory_settings,
	equipment_types_per_stock_location,
	backorders,
	brands,
	asset_types_additional_fields,
	resolution_types,
	location_types_additional_fields,
	all_contacts,
	budgets,
	downtime_analytics,
	supplier_currency_exchange_rates,
	ppsl_audits,
	epsl_audits,
});

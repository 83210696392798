import * as React from 'react';

const style = require('./DetailRow.less');
export default function DetailRow({ details, style = {} }) {
	const renderDetails = details
		.filter((detail) => detail.key && detail.value)
		.map((detail) => (
			<>
				<div className={detail.className ? detail.className : 'detailRow__item'}>
					<div className="detailRow__title">{detail.key}</div>
					<div className="detailRow__subtitle">{detail.value}</div>
					<div className="detailRow__subtext">{detail.subVal}</div>
				</div>
			</>
		));

	const maxColNum = renderDetails.length < 6 ? renderDetails.length : 6;
	return details.length > 0 ? (
		<div
			style={{ ...style, gridTemplateColumns: `repeat(${maxColNum}, auto [col-start])` }}
			className="detailRow"
		>
			{renderDetails}
		</div>
	) : null;
}

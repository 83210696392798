import React, { FC } from 'react';

const style = require('./AddressDisplay.less');

interface AddressDisplayProps {
	address: any;
	hideStreetAddress?: any;
	hideCity?: any;
	hideRegion?: any;
	hidePostcode?: any;
	hideCountry?: any;
	style?: any;
	className?: any;
	prefix?: any;
}

export const AddressDisplay: FC<AddressDisplayProps> = ({
	address,
	hideStreetAddress,
	hideCity,
	hideRegion,
	hidePostcode,
	hideCountry,
	prefix,
	...props
}) => {
	if (!address) {
		return null;
	}
	let streetAddress1, streetAddress2, city, region, postcode, country;
	if (!hideStreetAddress) {
		streetAddress1 = <div>{address.streetAddress1}</div>;
		streetAddress2 = <div>{address.streetAddress2}</div>;
	} else {
		streetAddress1 = null;
		streetAddress2 = null;
	}
	if (!hideCity) {
		city = <span>{address.city}</span>;
	} else {
		city = null;
	}
	if (!hideRegion) {
		region = (
			<span>
				{city ? ',' : null} {address.region}
			</span>
		);
	} else {
		region = null;
	}
	if (!hidePostcode) {
		postcode = <span>{address.postcode}</span>;
	} else {
		postcode = null;
	}
	if (!hideCountry) {
		country = <div>{address.country}</div>;
	} else {
		country = null;
	}
	return (
		<div className="addressDisplay" {...props}>
			{prefix}
			{streetAddress1}
			{streetAddress2}
			<div>
				{city}
				{region} {postcode}
			</div>
			{country}
		</div>
	);
};

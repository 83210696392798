import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { Layout, Spin, Row, Col, Card, Table, Button, Alert } from 'antd';
import {
	floatToPercentageString,
	formatDateForParam,
	secondsToStr,
} from '../../utils/DataFormatterUtils';

import {
	collectObjectsWithValidPropFromTreeData,
	filterTreeData,
	flattenTreeData,
	nullSafeGet,
	nullSafeGetOrElse,
	removeEmptyChildrenFromTreeData,
} from '../../utils/DataAccessUtils';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { withRouter } from 'react-router';
import { EmptyState } from '../empty_state/EmptyState';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
	downtimeAnalyticsRestCrudThunksForBuyer,
	downtimeAnalyticsRestCrudThunksForSupplier,
} from '../../thunks/asset_analytics_thunks';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { DOWNTIME_ANALYTICS_CRUD_ACTION_CREATORS } from '../../actions/downtime_analytics_actions';
import DowntimeAnalyticsControlBar from '../downtime_analytics_control_bar/DowntimeAnalyticsControlBar';
import { ROLE_TYPES, CSV_EXPORT_DEFAULTS } from '../../utils/DataConstants';
import { ExportToCsv } from 'export-to-csv';
import { BarChart } from '../charts/BarChart';
const queryString = require('qs');

const { Content } = Layout;

const exportReduceFunction = (obj) => ({
	name: obj.name,
	noOfAssetsOffline: obj.noOfAssetsOffline,
	meanDownTime: secondsToStr(obj.meanDownTime),
	meanTimeToRespond: secondsToStr(obj.meanTimeToRespond),
	meanTimeBetweenFailures: secondsToStr(obj.meanTimeBetweenFailures),
});

const locationChartConfig = (fieldName, fieldCol) => ({
	xAxisName: 'name',
	yAxisName: `${fieldName}`,
	position: `name*${fieldName}`,
	fillArea: false,
	color: 'name',
	cols: {
		fieldName: { alias: 'Location' },
		[fieldName]: fieldCol || { alias: `${fieldCol}`, formatter: (val) => secondsToStr(val || 0) },
	},
	yAxisLabel: {
		textStyle: {
			fontSize: '14',
			fontFamily: 'Roboto',
			fontWeight: 400,
			fill: 'rgba(0,0,0,0.65)',
		},
	},
	xAxisLabel: {
		textStyle: {
			fontSize: '14',
			fontFamily: 'Roboto',
			fontWeight: 400,
			fill: 'rgba(0,0,0,0.65)',
		},
	},
});

const DowntimeAnalyticsOverviewLocationsPage: FC<any> = ({
	getOverviewAnalaytics,
	analytics,
	saveFilters,
	clearFilters,
	userType,
}): React.ReactElement => {
	const { filters } = analytics.groupByLocations;

	const startDate = filters.startDate && moment(filters.startDate);
	const endDate = filters.endDate && moment(filters.endDate);

	const fetchData = (params, pagination?, sorting?, filters?) =>
		getOverviewAnalaytics(
			{ ...params, groupBy: 'location' },
			'groupByLocations',
			pagination,
			sorting,
			filters
		);

	useEffect(() => {
		fetchData({}, null, null, {
			startDate: formatDateForParam(moment().subtract(1, 'months')),
			endDate: formatDateForParam(moment()),
		});
	}, []);

	const data = getRecordsForTargetCollection(analytics, 'groupByLocations');

	const filterByLocationIds = useCallback(
		(allData) => {
			return (filters.locationIds || []).length > 0
				? filterTreeData(allData, (obj) => (filters.locationIds || []).includes(obj.id))
				: allData;
		},
		[filters.locationIds]
	);
	const displayData = useMemo(() => {
		const displayData = filterByLocationIds(data || []);
		return displayData;
	}, [data, filterByLocationIds]);

	const getLocationIds = (location) => {
		const valueSet = new Set();
		function addAllChildren(array) {
			array.map(function f(a) {
				if (Array.isArray(a.children) && a.children.length) {
					a.children.map(f);
				} else {
					valueSet.add(a.id);
				}
			});
		}
		if (location && location.children && location.children.length) {
			addAllChildren(location.children);
		} else if (location) {
			valueSet.add(location.id);
		}
		return Array.from(valueSet).join(',');
	};
	const getLink = useCallback(
		(extraParams?) => {
			const filters = analytics.groupByLocations.filters;
			const queryParams = queryString.stringify({
				startDate: filters.startDate && filters.startDate,
				endDate: filters.endDate && filters.endDate,
				locationIds: filters.locationIds && filters.locationIds.join(','),
				assetTypeIds: filters.assetTypeIds && filters.assetTypeIds.join(','),
				manufacturer: filters.manufacturer,
				sort_by: 'meanDownTime',
				order: 'descend',
				...extraParams,
			});
			return `/${userType}/assets/overview/downtime?${queryParams}`;
		},
		[analytics.groupByLocations]
	);
	const openDetails = (location) => {
		window.open(getLink({ locationIds: getLocationIds(location) }), '_blank');
	};

	const columns = [
		{
			title: 'Location',
			dataIndex: 'name',
			key: 'name',
			render: (text) => (
				<Ellipsis tooltip={true} style={{ display: 'inline' }} length={28}>
					{text}
				</Ellipsis>
			),
			width: '30%',
		},
		{
			title: 'No. of Assets Down',
			dataIndex: 'noOfAssetsOffline',
			key: 'noOfAssetsOffline',
			render: (text) => text,
			sorter: (a, b) => a.noOfAssetsOffline - b.noOfAssetsOffline,
			width: '15%',
		},
		{
			title: 'Mean Downtime',
			dataIndex: 'meanDownTime',
			key: 'meanDownTime',
			render: (text) => secondsToStr(text),
			sorter: (a, b) => (a.meanDownTime || 0) - (b.meanDownTime || 0),
			defaultSortOrder: 'descend',
			width: '15%',
		},
		{
			title: 'Mean time to respond',
			dataIndex: 'meanTimeToRespond',
			key: 'meanTimeToRespond',
			render: (text) => secondsToStr(text),
			sorter: (a, b) => (a.meanTimeToRespond || 0) - (b.meanTimeToRespond || 0),
			width: '15%',
		},
		{
			title: 'Mean time between failures',
			dataIndex: 'meanTimeBetweenFailures',
			key: 'meanTimeBetweenFailures',
			render: (text) => secondsToStr(text),
			sorter: (a, b) => (a.meanTimeBetweenFailures || 0) - (b.meanTimeBetweenFailures || 0),
			width: '15%',
		},
		{
			title: '',
			dataIndex: 'action',
			key: 'action',
			render: (text, location) => (
				<Button
					type="link"
					onClick={() => {
						openDetails(location);
					}}
				>
					See Details <i className="icons8-font icons8-external-link ml-2 inline-block" />
				</Button>
			),
			width: '10%',
		},
	];

	const noOfAssetsDownCountByLocationData = useMemo(
		() =>
			collectObjectsWithValidPropFromTreeData(data, 'noOfAssetsOffline').map((el) => ({
				...el,
			})),
		[data]
	);

	const onDownloadAnalytics = useCallback(() => {
		const csvExporter = new ExportToCsv({
			...CSV_EXPORT_DEFAULTS,
			filename: 'Downtime Analytics by locations',
			title: 'Downtime Analytics by locations',
			headers: [
				'Location',
				'No. of Assets Down',
				'Mean Downtime',
				'Mean time to respond',
				'Mean time between failures',
			],
		});
		csvExporter.generateCsv(
			flattenTreeData(removeEmptyChildrenFromTreeData(displayData), exportReduceFunction)
		);
	}, [displayData]);

	return (
		<Content
			className="analyticsOverviewLocationsPage"
			style={{ padding: '0 0.5em', position: 'relative' }}
		>
			{/*<BackTop/>*/}
			<Row key={1} style={{ margin: '0.5em -8px' }} gutter={16}>
				<Col span={24}>
					<div className=" mb-2 grid gap-y-3">
						<DowntimeAnalyticsControlBar
							updateFilters={(filters) => {
								if (filters.startDate && filters.endDate) {
									fetchData({}, null, null, {
										...filters,
										startDate: formatDateForParam(filters.startDate),
										endDate: formatDateForParam(filters.endDate),
									});
								} else if (startDate && endDate) {
									fetchData({ rightNow: true }, null, null, filters);
								} else {
									saveFilters({ ...filters }, 'groupByLocations');
								}
							}}
							clearFilters={() => clearFilters('groupByLocations')}
							filters={filters}
							startDate={startDate}
							endDate={endDate}
							filterConfig={{
								assetTypes: true,
								manufacturer: true,
							}}
							showDownload
							onDownload={onDownloadAnalytics}
						/>
					</div>
				</Col>
			</Row>

			<>
				<Row key={2} style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<Card>
							<Spin spinning={analytics.fetching}>
								{data.length > 0 ? (
									<Table
										columns={columns}
										rowKey={(el) => el.id}
										dataSource={removeEmptyChildrenFromTreeData(displayData)}
										pagination={{ pageSize: 10 }}
										// sortDirections={['descend', 'ascend']}
									/>
								) : (
									<EmptyState
										height={120}
										headline={'No locations found'}
										body={
											<div style={{ maxWidth: 488, textAlign: 'center' }}>
												We didn't find any locations. Try changing your filters or expanding the
												selected time period.
											</div>
										}
									/>
								)}
							</Spin>
						</Card>
					</Col>
				</Row>
				{/* <Row key={3} gutter={16} className="mt-6 mb-10 grid gap-y-4">
					<Col span={12}>
						<Card bodyStyle={{ padding: 16 }}>
							<h5>No of Assets Down </h5>
							<Spin spinning={analytics.fetching}>
								{noOfAssetsDownCountByLocationData.length > 0 ? (
									<BarChart
										height={noOfAssetsDownCountByLocationData.length * 32}
										data={noOfAssetsDownCountByLocationData.sort(
											(a, b) => b.noOfAssetsOffline - a.noOfAssetsOffline
										)}
										showTooltipTitle={false}
										tooltipItemTemplate={`
										<li data-index={index}>
												<!-- The marker for each record -->
												<span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>
												{name}: {value} assets down 
										</li>`}
										xAxisName={nullSafeGet(
											'xAxisName',
											locationChartConfig('noOfAssetsOffline', { alias: 'No of Assets Down' })
										)}
										yAxisName={nullSafeGet(
											'yAxisName',
											locationChartConfig('noOfAssetsOffline', { alias: 'No of Assets Down' })
										)}
										xAxisLabel={nullSafeGet(
											'xAxisLabel',
											locationChartConfig('noOfAssetsOffline', { alias: 'No of Assets Down' })
										)}
										yAxisLabel={nullSafeGet(
											'yAxisLabel',
											locationChartConfig('noOfAssetsOffline', { alias: 'No of Assets Down' })
										)}
										fillArea={nullSafeGet(
											'fillArea',
											locationChartConfig('noOfAssetsOffline', { alias: 'No of Assets Down' })
										)}
										cols={nullSafeGet(
											'cols',
											locationChartConfig('noOfAssetsOffline', { alias: 'No of Assets Down' })
										)}
										color={nullSafeGet(
											'color',
											locationChartConfig('noOfAssetsOffline', { alias: 'No of Assets Down' })
										)}
										position={nullSafeGet(
											'position',
											locationChartConfig('noOfAssetsOffline', { alias: 'No of Assets Down' })
										)}
									/>
								) : (
									<EmptyState
										height={120}
										headline={'No locations found'}
										body={
											<div style={{ maxWidth: 488, textAlign: 'center' }}>
												We didn't find any locations. Try changing your filters or expanding the
												selected time period.
											</div>
										}
									/>
								)}
							</Spin>
						</Card>
					</Col>
					<Col span={12}>
						<Card bodyStyle={{ padding: 16 }}>
							<h5>Mean Downtime </h5>
							<Spin spinning={analytics.fetching}>
								{noOfAssetsDownCountByLocationData.length > 0 ? (
									<BarChart
										height={noOfAssetsDownCountByLocationData.length * 32}
										chartPadding={[40, 40, 40, 200]}
										data={noOfAssetsDownCountByLocationData.sort(
											(a, b) => b.meanDownTime - a.meanDownTime
										)}
										showTooltipTitle={false}
										tooltipItemTemplate={`
										<li data-index={index}>
												<!-- The marker for each record -->
												<span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>
												{name}: {value} Mean Downtime 
										</li>`}
										xAxisName={nullSafeGet(
											'xAxisName',
											locationChartConfig('meanDownTime', {
												alias: 'Mean Downtime',
												formatter: (val) => secondsToStr(val || 0),
											})
										)}
										yAxisName={nullSafeGet(
											'yAxisName',
											locationChartConfig('meanDownTime', {
												alias: 'Mean Downtime',
												formatter: (val) => secondsToStr(val || 0),
											})
										)}
										xAxisLabel={nullSafeGet(
											'xAxisLabel',
											locationChartConfig('meanDownTime', {
												alias: 'Mean Downtime',
												formatter: (val) => secondsToStr(val || 0),
											})
										)}
										yAxisLabel={nullSafeGet(
											'yAxisLabel',
											locationChartConfig('meanDownTime', {
												alias: 'Mean Downtime',
												formatter: (val) => secondsToStr(val || 0),
											})
										)}
										fillArea={nullSafeGet(
											'fillArea',
											locationChartConfig('meanDownTime', {
												alias: 'Mean Downtime',
												formatter: (val) => secondsToStr(val || 0),
											})
										)}
										cols={nullSafeGet(
											'cols',
											locationChartConfig('meanDownTime', {
												alias: 'Mean Downtime',
												formatter: (val) => secondsToStr(val || 0),
											})
										)}
										color={nullSafeGet(
											'color',
											locationChartConfig('meanDownTime', {
												alias: 'Mean Downtime',
												formatter: (val) => secondsToStr(val || 0),
											})
										)}
										position={nullSafeGet(
											'position',
											locationChartConfig('meanDownTime', {
												alias: 'Mean Downtime',
												formatter: (val) => secondsToStr(val || 0),
											})
										)}
									/>
								) : (
									<EmptyState
										height={120}
										headline={'No locations found'}
										body={
											<div style={{ maxWidth: 488, textAlign: 'center' }}>
												We didn't find any locations. Try changing your filters or expanding the
												selected time period.
											</div>
										}
									/>
								)}
							</Spin>
						</Card>
					</Col>
					<Col span={12}>
						<Card bodyStyle={{ padding: 16 }}>
							<h5>Mean time to respond </h5>
							<Spin spinning={analytics.fetching}>
								{noOfAssetsDownCountByLocationData.length > 0 ? (
									<BarChart
										height={noOfAssetsDownCountByLocationData.length * 32}
										chartPadding={[40, 40, 40, 200]}
										data={noOfAssetsDownCountByLocationData.sort(
											(a, b) => b.meanTimeToRespond - a.meanTimeToRespond
										)}
										showTooltipTitle={false}
										tooltipItemTemplate={`
										<li data-index={index}>
												<!-- The marker for each record -->
												<span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>
												{name}: {value} Mean time to respond 
										</li>`}
										xAxisName={nullSafeGet(
											'xAxisName',
											locationChartConfig('meanTimeToRespond', {
												alias: 'Mean time to respond',
												formatter: (val) => secondsToStr(val || 0),
											})
										)}
										yAxisName={nullSafeGet(
											'yAxisName',
											locationChartConfig('meanTimeToRespond', {
												alias: 'Mean time to respond',
												formatter: (val) => secondsToStr(val || 0),
											})
										)}
										xAxisLabel={nullSafeGet(
											'xAxisLabel',
											locationChartConfig('meanTimeToRespond', {
												alias: 'Mean time to respond',
												formatter: (val) => secondsToStr(val || 0),
											})
										)}
										yAxisLabel={nullSafeGet(
											'yAxisLabel',
											locationChartConfig('meanTimeToRespond', {
												alias: 'Mean time to respond',
												formatter: (val) => secondsToStr(val || 0),
											})
										)}
										fillArea={nullSafeGet(
											'fillArea',
											locationChartConfig('meanTimeToRespond', {
												alias: 'Mean time to respond',
												formatter: (val) => secondsToStr(val || 0),
											})
										)}
										cols={nullSafeGet(
											'cols',
											locationChartConfig('meanTimeToRespond', {
												alias: 'Mean time to respond',
												formatter: (val) => secondsToStr(val || 0),
											})
										)}
										color={nullSafeGet(
											'color',
											locationChartConfig('meanTimeToRespond', {
												alias: 'Mean time to respond',
												formatter: (val) => secondsToStr(val || 0),
											})
										)}
										position={nullSafeGet(
											'position',
											locationChartConfig('meanTimeToRespond', {
												alias: 'Mean time to respond',
												formatter: (val) => secondsToStr(val || 0),
											})
										)}
									/>
								) : (
									<EmptyState
										height={120}
										headline={'No locations found'}
										body={
											<div style={{ maxWidth: 488, textAlign: 'center' }}>
												We didn't find any locations. Try changing your filters or expanding the
												selected time period.
											</div>
										}
									/>
								)}
							</Spin>
						</Card>
					</Col>
					<Col span={12}>
						<Card bodyStyle={{ padding: 16 }}>
							<h5>Mean time between failures </h5>
							<Spin spinning={analytics.fetching}>
								{noOfAssetsDownCountByLocationData.length > 0 ? (
									<BarChart
										height={noOfAssetsDownCountByLocationData.length * 32}
										chartPadding={[40, 40, 40, 200]}
										data={noOfAssetsDownCountByLocationData.sort(
											(a, b) => b.meanTimeBetweenFailures - a.meanTimeBetweenFailures
										)}
										showTooltipTitle={false}
										tooltipItemTemplate={`
										<li data-index={index}>
												<!-- The marker for each record -->
												<span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>
												{name}: {value} Mean time between failures 
										</li>`}
										xAxisName={nullSafeGet(
											'xAxisName',
											locationChartConfig('meanTimeBetweenFailures', {
												alias: 'Mean time between failures',
												formatter: (val) => secondsToStr(val || 0),
											})
										)}
										yAxisName={nullSafeGet(
											'yAxisName',
											locationChartConfig('meanTimeBetweenFailures', {
												alias: 'Mean time between failures',
												formatter: (val) => secondsToStr(val || 0),
											})
										)}
										xAxisLabel={nullSafeGet(
											'xAxisLabel',
											locationChartConfig('meanTimeBetweenFailures', {
												alias: 'Mean time between failures',
												formatter: (val) => secondsToStr(val || 0),
											})
										)}
										yAxisLabel={nullSafeGet(
											'yAxisLabel',
											locationChartConfig('meanTimeBetweenFailures', {
												alias: 'Mean time between failures',
												formatter: (val) => secondsToStr(val || 0),
											})
										)}
										fillArea={nullSafeGet(
											'fillArea',
											locationChartConfig('meanTimeBetweenFailures', {
												alias: 'Mean time between failures',
												formatter: (val) => secondsToStr(val || 0),
											})
										)}
										cols={nullSafeGet(
											'cols',
											locationChartConfig('meanTimeBetweenFailures', {
												alias: 'Mean time between failures',
												formatter: (val) => secondsToStr(val || 0),
											})
										)}
										color={nullSafeGet(
											'color',
											locationChartConfig('meanTimeBetweenFailures', {
												alias: 'Mean time between failures',
												formatter: (val) => secondsToStr(val || 0),
											})
										)}
										position={nullSafeGet(
											'position',
											locationChartConfig('meanTimeBetweenFailures', {
												alias: 'Mean time between failures',
												formatter: (val) => secondsToStr(val || 0),
											})
										)}
									/>
								) : (
									<EmptyState
										height={120}
										headline={'No locations found'}
										body={
											<div style={{ maxWidth: 488, textAlign: 'center' }}>
												We didn't find any locations. Try changing your filters or expanding the
												selected time period.
											</div>
										}
									/>
								)}
							</Spin>
						</Card>
					</Col>
				</Row> */}
			</>
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	currentUser: state.session.currentUser,
	analytics: state.downtime_analytics,
	userType: (state as any).session.userType,
	locations: state.locations,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	saveFilters: (filters, targetCollectionName) =>
		dispatch(DOWNTIME_ANALYTICS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollectionName)),
	clearAllFilters: (targetCollectionName) =>
		dispatch(DOWNTIME_ANALYTICS_CRUD_ACTION_CREATORS.clearAllFilters(targetCollectionName)),
	getOverviewAnalaytics: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? downtimeAnalyticsRestCrudThunksForSupplier.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: downtimeAnalyticsRestCrudThunksForBuyer.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(DowntimeAnalyticsOverviewLocationsPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
	changeFilterValueToArrayOfIds,
	getEntitiesById,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import { standardInitialStateGenerator } from '../../reducers/standard_reducer_utils';
import { useEffect, useMemo, useState } from 'react';
import {
	fetchSupplierFacilitiesIfEverScorecardInPrivateNetworkForBuyer,
	fetchSupplierFacilitiesIfEverScorecardInPrivateNetworkForSupplier,
} from '../../thunks/supplier_facilities_thunks';
import { fetchAllKPIs } from '../../thunks/kpi_analytics_thunks';
import {
	capitalizeEachWord,
	floatToPercentageString,
	secondsToStr,
} from '../../utils/DataFormatterUtils';
import { AddressDisplay } from '../address_display/AddressDisplay';
import * as React from 'react';
import { Button, Card, Pagination, Row, Select, Spin, Table, Tag } from 'antd';
import { EmptyState } from '../empty_state/EmptyState';
import { PAGE_SIZE_OPTIONS } from '../WorkOrderTable/config/pagination/pagination';
import queryString from 'querystring';
import { AdvancedFilters } from '../advanced_filters/AdvancedFilters';
import OWAsyncTreeSelect from '../ow_async_tree_select/OWAsyncTreeSelect';
import { ROLE_TYPES } from '../../utils/DataConstants';
import moment from 'moment';
import ProblemTypeTreeSelect from '../problem_type_tree_select';
import {
	problemTypesHeirarchicalForBuyer,
	problemTypesHeirarchicalForSupplier,
	problemTypesRestCrudThunksForBuyer,
	problemTypesRestCrudThunksForSupplier,
} from '../../thunks/problem_types_thunks';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import { parseURL } from '../../thunks/work_orders_thunks_wrapper';
const TARGET_COLLECTION_NAME = 'allSupplierFacilitiesEver';
const ADVANCED_FILTERS_TARGET_COLLECTION_NAME = 'scorecardAdvancedFilters';

const defaultPagination = {
	current: 1,
	pageSize: 10,
};

function VendorScorecardComp({
	data,
	error,
	history,
	location,
	supplierFacilities,
	supplierFacilitiesSlice,
	problemTypes,
	fetchProblemTypes,
	fetchMultipleProblemTypes,
	handleQueryParams,
	targetCollectionName = TARGET_COLLECTION_NAME,
	fetchRecords,
	updatePagination,
	clearAndUpdateFilters,
	fetchSearch,
	updateFilters,
	filterConfig,
	kpisLoading,
	userType,
}) {
	const {
		fetching,
		filters: activeFilters,
		pagination,
		sorting: activeSorters,
	} = supplierFacilities || standardInitialStateGenerator([targetCollectionName]);
	const [vendorInfo, setVendorInfo] = useState(undefined);
	const [loading, setLoading] = useState(true);
	const [initialLoading, setInitialLoading] = useState(true);

	useEffect(() => {
		setLoading(true);
		fetchRecords(setVendorInfo)(location)(activeFilters);
	}, []);

	const columns = useMemo(
		() => [
			{
				title: 'Suppliers',
				dataIndex: 'name',
				render: (text, record) => {
					const spendCategories = nullSafeGetOrElse('categoryNames', record, '').split(',');
					return (
						<div style={{ display: 'flex', alignItems: 'center' }} className="cursor-pointer">
							<div>
								<div
									style={{
										fontSize: 18,
										wordBreak: 'break-word',
									}}
								>
									{nullSafeGet('name', record)}
								</div>
								<div style={{ marginBottom: 0, fontSize: 14 }}>
									<div
										style={{ wordBreak: 'normal', whiteSpace: 'normal', color: 'rgba(0,0,0,0.45)' }}
									>
										{spendCategories
											.map((categoryName) => capitalizeEachWord(categoryName))
											.slice(0, 2)
											.join(', ')}
										{spendCategories.length > 3 ? ' and more' : null}
									</div>
								</div>
								<AddressDisplay
									hidePostcode={true}
									hideCountry={true}
									hideStreetAddress={true}
									address={record.primaryAddress}
									style={{
										color: 'rgba(0,0,0,0.45)',
									}}
								/>
							</div>
						</div>
					);
				},
			},
			{
				title: 'Total Lifetime WOs',
				dataIndex: 'lifeTimeTotalWorkOrders',
				render: (text) => text,
			},
			{
				title: 'Current Month WOs',
				dataIndex: ['monthlyWorkOrderKPIs', 'totalWOs'],
				render: (rec) => rec || '--',
			},
			{
				title: 'Time to Accept',
				dataIndex: ['monthlyTimeToAcceptKPIs', 'monthlyAverage'],
				render: (rec) => {
					return (rec && secondsToStr(rec)) || '--';
				},
			},
			{
				title: 'Time To Schedule Visit',
				dataIndex: ['monthlyTimeToScheduleVisitKPIs', 'monthlyAverage'],
				render: (rec) => {
					return (rec && secondsToStr(rec)) || '--';
				},
			},
			{
				title: 'Arrived On Time',
				dataIndex: ['monthlyOnTimeArrivalKPIs', 'monthlyAverage'],
				render: (rec) => {
					return floatToPercentageString(rec) || '--';
				},
			},
			{
				title: 'Completed On Time',
				dataIndex: ['monthlyWorkCompleteWithinDueDateKPIs', 'monthlyAverage'],
				render: (rec) => {
					return floatToPercentageString(rec) || '--';
				},
			},
		],
		[vendorInfo]
	);

	useEffect(() => {
		if (!loading) {
			setLoading(true);
		}
		if (!fetching && initialLoading) {
			setInitialLoading(false);
		}
	}, [fetching, kpisLoading]);

	useEffect(() => {
		setLoading(false);
	}, [columns]);

	const onRow = (record) => ({
		onClick: () => {
			const path = `/${userType}/analytics/kpi/vendors/detail/${record.id}`;
			const passthroughFilters = queryString.stringify(activeFilters);
			const link = `${getLinkWIthBackLinkParams(
				location,
				'Back to vendors',
				path
			)}&${passthroughFilters}`;
			history.push(link);
		},
	});

	const FILTER_CONFIG = useMemo(
		() => [
			{
				label: 'Supplier',
				fieldName: 'supplierFacilityIds',
				mode: 'multiple',
				stateSlice: supplierFacilitiesSlice,
				filtersValueAccessor: (filtersStateSlice) => {
					const ids = nullSafeGet(
						`${TARGET_COLLECTION_NAME}.filters.supplierFacilityIds`,
						filtersStateSlice
					);
					return changeFilterValueToArrayOfIds(ids);
				},
				keyAccessor: (supplierFacility) => supplierFacility.id,
				valueAccessor: (supplierFacility) => supplierFacility.id,
				handleChange: (ids) => {
					updateFilters(setVendorInfo)(
						{
							supplierFacilityIds: ids.join(',') || undefined,
						},
						TARGET_COLLECTION_NAME
					);
				},
				targetCollectionName: ADVANCED_FILTERS_TARGET_COLLECTION_NAME,
				fetch: fetchSearch,
				fetchMultiple: () => {},
				renderItem: (supplierFacility) => supplierFacility.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
				component: OWAsyncTreeSelect,
			},
			{
				label: 'Problem Type',
				mode: 'multiple',
				fieldName: 'problemTypeIds',
				stateSlice: problemTypes,
				filtersValueAccessor: (filtersStateSlice) => {
					const problemTypeIds = nullSafeGet(
						`${TARGET_COLLECTION_NAME}.filters.problemTypeIds`,
						filtersStateSlice
					);
					return changeFilterValueToArrayOfIds(problemTypeIds);
				},
				keyAccessor: (problemType) => problemType.id,
				valueAccessor: (problemType) => problemType.id,
				handleChange: (ids) => {
					updateFilters(setVendorInfo)(
						{
							problemTypeIds: ids.join(',') || undefined,
						},
						TARGET_COLLECTION_NAME
					);
				},
				targetCollectionName: 'scorecardAdvancedFilters',
				fetch: fetchProblemTypes,
				fetchMultiple: fetchMultipleProblemTypes,
				renderItem: (problemType) => problemType.hierarchyName,
				sortBy: { sort_by: 'name', order: 'ascend' },
				component: ProblemTypeTreeSelect,
			},
		],
		[filterConfig, supplierFacilitiesSlice, supplierFacilities, activeFilters, problemTypes]
	);

	const firstDateOfMonth = moment().startOf('month');
	const today = moment();

	return (
		<>
			<Row className={'m-0'}>
				{initialLoading ? null : (
					<AdvancedFilters
						updateFilters={updateFilters(setVendorInfo)}
						filterConfig={FILTER_CONFIG}
						filtersTargetCollectionName={TARGET_COLLECTION_NAME}
						clearAndUpdateFilters={clearAndUpdateFilters(setVendorInfo)}
						filtersStateSlice={supplierFacilitiesSlice}
						preAppliedFilters={{}}
						initialFilters={activeFilters}
						inputParamName={'name'}
					/>
				)}
			</Row>
			<div className={'mt-4'}>
				<Tag style={{ fontSize: '14px', padding: '4px 12px' }}>
					Date Range : {firstDateOfMonth.format('DD MMM YYYY')} to {today.format('DD MMM YYYY')}
				</Tag>
			</div>
			<div className={'mt-4'}>
				{vendorInfo === undefined || (vendorInfo && vendorInfo.length > 0) ? (
					<Card>
						<Table
							columns={columns}
							showHeader={true}
							rowKey={(el) => el['id']}
							dataSource={vendorInfo}
							loading={loading}
							onRow={onRow}
							onChange={({ current, pageSize }) =>
								updatePagination({ current, pageSize }, setVendorInfo)
							}
						/>
					</Card>
				) : !activeFilters ? (
					<EmptyState
						graphic={
							<img
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/sloth.svg"
							/>
						}
						headline={'My work here is done'}
						body={
							<div style={{ textAlign: 'center' }}>
								<div style={{ maxWidth: 440, marginBottom: 16 }}>
									You haven't added any suppliers to your private network yet. Once you've added
									them, we'll start keeping track of their performance here.
								</div>
								<Button
									type="primary"
									onClick={() => this.props.history.push(`/${userType}/dashboards/overview/all`)}
								>
									Find vendors
								</Button>
							</div>
						}
					/>
				) : (
					<EmptyState
						graphic={
							<img
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/sloth.svg"
							/>
						}
						headline={`Couldn't find any suppliers matching these filters`}
						body={
							<div style={{ textAlign: 'center' }}>
								<div style={{ maxWidth: 440, marginBottom: 16 }}>
									Change or clear your filters to see your suppliers here
								</div>
							</div>
						}
					/>
				)}
			</div>
		</>
	);
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	handleQueryParams: ownProps.handleQueryParams,
	locations: state.locations,
	currentUser: state.session.currentUser,
	workOrderPriorities: state.work_order_priorities,
	spendCategories: state.spend_categories,
	problemTypes: state.problem_types,
	regions: state.regions,
	areas: state.areas,
	fieldTechs: state.field_techs,
	supplierFacilities:
		state.supplier_facilities[ownProps.targetCollectionName || TARGET_COLLECTION_NAME],
	supplierFacilitiesSlice: state.supplier_facilities,
	kpisLoading: state.kpi_analytics.monthlyKPIsBySupplier.loading,
	companyConfig: state.company_config.detail,
	targetCollectionName: ownProps.targetCollectionName,
	data: getEntitiesById(
		state.supplier_facilities[ownProps.targetCollectionName || TARGET_COLLECTION_NAME]
			? state.supplier_facilities[ownProps.targetCollectionName || TARGET_COLLECTION_NAME]
					.recordsIds || []
			: [],
		state.supplier_facilities.records
	),
});

const fetchSearchData =
	(userType) =>
	(
		handleQueryParams,
		history,
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
	(dispatch) => {
		if (handleQueryParams) {
			const queryParams = queryString.stringify({
				...{
					current: pagination.current || 10,
					pageSize: pagination.pageSize || 10,
				},
				...sorting,
				...filters,
				...params,
			});
			history.replace(`${window.location.pathname}?${queryParams}`);
		}
		return dispatch(
			userType === ROLE_TYPES.SUPPLIER
				? fetchSupplierFacilitiesIfEverScorecardInPrivateNetworkForSupplier(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: fetchSupplierFacilitiesIfEverScorecardInPrivateNetworkForBuyer(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		);
	};

const fetchSupplierFacilities =
	(userType) =>
	(callback) =>
	(handleQueryParams, history, params, targetCollectionName, pagination, sorting, filters) =>
	(dispatch) =>
		dispatch(
			fetchSearchData(userType)(
				handleQueryParams,
				history,
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				false
			)
		).then((suppliers = []) => {
			const start = (pagination.current - 1) * pagination.pageSize;
			const end = start + pagination.pageSize - 1;
			let numbers = Array.from({ length: end - start + 1 }, (_, index) => start + index);
			if (suppliers.length < numbers.length) {
				numbers = suppliers.map((_, index) => index);
			}
			Promise.all(
				numbers.map((sf) =>
					dispatch(
						fetchAllKPIs(userType)({
							...filters,
							supplierFacilityIds: nullSafeGet(`${sf}.id`, suppliers),
						})
					)
				)
			).then((KPIs) => {
				callback(
					suppliers
						.slice(0, start)
						.concat(
							...numbers.map((i) => ({ ...suppliers[i], ...(KPIs[i] || []) })),
							...suppliers.slice(end)
						)
				);
			});
		});

const fetchSupplierData =
	(userType) =>
	(callback, location) =>
	(
		handleQueryParams,
		history,
		targetCollectionName,
		preAppliedFilters,
		initialPagination,
		initialSorting,
		initialFilters
	) =>
	(dispatch) => {
		const { pagination, sorting, filters } = parseURL(
			handleQueryParams,
			history,
			location,
			{},
			initialPagination,
			initialSorting,
			initialFilters
		);

		return dispatch(
			fetchSupplierFacilities(userType)(callback)(
				false,
				history,
				{},
				targetCollectionName,
				pagination,
				sorting,
				filters
			)
		);
	};

const updatePaginationThunk =
	(userType) =>
	(callback) =>
	(handleQueryParams, history, targetCollectionName, pagination) =>
	(dispatch, getState) => {
		const { sorting, filters } = getState()['supplier_facilities'][targetCollectionName];
		dispatch(
			fetchSupplierFacilities(userType)(callback)(
				handleQueryParams,
				history,
				{},
				targetCollectionName,
				pagination,
				sorting,
				filters
			)
		);
	};
const updateFilterThunk =
	(userType) =>
	(handleQueryParams, history, targetCollectionName, filters, ignoreRefreshCounts, callback) =>
	(dispatch, getState) => {
		const {
			sorting,
			pagination,
			filters: storeFilters,
		} = getState()['supplier_facilities'][targetCollectionName];
		return dispatch(
			fetchSupplierFacilities(userType)(callback)(
				handleQueryParams,
				history,
				null,
				targetCollectionName,
				{ ...pagination, current: 1 },
				sorting,
				{ ...storeFilters, ...filters }
			)
		);
	};

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchRecords: (callback) => (location) => (filters) =>
		dispatch(
			fetchSupplierData(ownProps.userType)(callback, location)(
				true,
				ownProps.history,
				TARGET_COLLECTION_NAME,
				ownProps.preAppliedFilters,
				ownProps.initialPagination || { current: 1, pageSize: 10 },
				ownProps.initialSorting || { sort_by: 'name', order: 'ascend' },
				filters
			)
		),

	fetchSearch: (params, targetCollectionName) =>
		dispatch(
			fetchSearchData(ownProps.userType)(
				false,
				ownProps.history,
				params,
				targetCollectionName,
				{ current: 1, pageSize: 1000 },
				ownProps.sorting,
				ownProps.filters,
				false
			)
		),

	fetchSupplierRecords: (callback) => (location) =>
		dispatch(
			fetchSupplierFacilities(ownProps.userType)(callback)(
				true,
				ownProps.history,
				TARGET_COLLECTION_NAME,
				ownProps.preAppliedFilters,
				ownProps.initialPagination,
				ownProps.initialSorting,
				ownProps.initialFilters
			)
		),
	updatePagination: (pagination, callback) =>
		dispatch(
			updatePaginationThunk(ownProps.userType)(callback)(
				true,
				ownProps.history,
				ownProps.targetCollectionName || TARGET_COLLECTION_NAME,
				pagination
			)
		),
	updateFilters: (callback) => (filter, targetCollectionName) =>
		dispatch(
			updateFilterThunk(ownProps.userType)(
				true,
				ownProps.history,
				targetCollectionName || TARGET_COLLECTION_NAME,
				filter,
				ownProps.ignoreRefreshCounts,
				callback
			)
		),

	clearAndUpdateFilters: (callback) => () =>
		dispatch(
			fetchSupplierFacilities(ownProps.userType)(callback)(
				true,
				ownProps.history,
				{},
				TARGET_COLLECTION_NAME,
				{ current: 1, pageSize: 10 },
				{ sort_by: 'name', order: 'ascend' },
				{}
			)
		),
	fetchProblemTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) => {
		const fnToCall =
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? problemTypesHeirarchicalForSupplier
				: problemTypesHeirarchicalForBuyer;
		return dispatch(
			fnToCall(params, targetCollectionName, pagination, sorting, filters, addToTargetCollection)
		);
	},
	fetchMultipleProblemTypes: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? problemTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: problemTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(VendorScorecardComp)
);

export default connect(
	(state) => ({
		userType: state.session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

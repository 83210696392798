import { PART_WARRANTIES_CRUD_ACTION_CREATORS } from '../actions/part_warranties_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';

export const partWarrantiesRestCrudThunksForBuyer = new RestCrudThunks(
	'part_warranties',
	'part_warranty',
	'partWarranty',
	'partWarranties',
	PART_WARRANTIES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'asset/'
);
export const partWarrantiesRestCrudThunksForSupplier = new RestCrudThunks(
	'part_warranties',
	'part_warranty',
	'partWarranty',
	'partWarranties',
	PART_WARRANTIES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'asset/'
);

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import ProjectEditForm from '../project_edit_form/ProjectEditForm';
import {
	projectsRestCrudThunksForBuyer,
	projectsRestCrudThunksForSupplier,
} from '../../thunks/projects_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';

class ProjectEditPage extends React.Component<any, any> {
	componentDidMount() {
		const { getProject, match, currentUser } = this.props;
		const projectId = match.params.id;
		const userType = nullSafeGetOrElse('userType', currentUser, 'buyer');
		getProject(userType, projectId);
	}

	render() {
		const { history, fetching, project } = this.props;
		return [
			<LogOnMountWithStandardEventProperties eventType="visited buyer edit project page" />,
			<EditPage
				title="Edit Project"
				formComponentProps={{
					formData: project,
					isApprovalForm: false,
					onSuccess: (rec) => history.goBack(),
				}}
				FormComponent={ProjectEditForm}
			/>,
		];
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		history: ownProps.history,
		project: state.projects.detail,
		fetching: state.projects.fetching,
		match: ownProps.match,
		currentUser: state.session.currentUser,
	};
};

const mapDispatchToProps = (dispatch) => ({
	getProject: (userType, id) =>
		dispatch(
			userType === ROLE_TYPES.SUPPLIER
				? projectsRestCrudThunksForSupplier.readOne(id)
				: projectsRestCrudThunksForBuyer.readOne(id)
		),
});

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(ProjectEditPage));

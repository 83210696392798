import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Layout, Row, Col, Button, Card } from 'antd';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { siteSurveysRestCrudThunksForBuyer } from '../../thunks/site_surveys_thunks';
import { SITE_SURVEYS_CRUD_ACTION_CREATORS } from '../../actions/site_surveys_actions';
import { EmptyState } from '../empty_state/EmptyState';
import SubnavBar from '../subnav_bar/SubnavBar';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { AdvancedFilters } from '../advanced_filters/AdvancedFilters';
import {
	nullSafeGet,
	nullSafeGetOrElse,
	changeFilterValueToArrayOfIds,
	getStartDateFieldName,
	getEndDateFieldName,
} from '../../utils/DataAccessUtils';
import RegionsLocationsTreeSelect from '../regions_tree_select/BuyerRegionsLocationsTreeSelect';
import { locationsRestCrudThunksForBuyer } from '../../thunks/locations_thunks';
import moment from 'moment';
import SelectedFilters from '../selected_filters_pills/SelectedFilters';
import { FILTER_CONFIG_NAMES, FILTER_FIELD_TYPE } from '../../utils/DataConstants';
import { DATE_FORMAT, dateFormatter, formatDateForParam } from '../../utils/DataFormatterUtils';
import OWDateRangePicker from '../ow_date_range_picker/OWDateRangePicker';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import DateTimeHover from '../date_time_component/DateTime';

const { Content } = Layout;
require('./SiteSurveysIndexPage.less');

const initState = {
	filteredInfo: null,
	sortedInfo: null,
	visible: false,
};

interface SourcingPageState {
	filteredInfo: any;
	sortedInfo: any;
	visible: boolean;
}

const SITE_SURVEYS_OVERVIEW_TARGET_COLLECTION = 'siteSurveysIndex';

const queryString = require('qs');

class SiteSurveysIndexPage extends React.Component<any, SourcingPageState> {
	initialPagination: any = { current: 1, pageSize: 10 };
	initialFilters: any = {};
	initialSorting: any;

	constructor(props) {
		super(props);
		const { updateSiteSurveyFilters, location } = props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		const { current, pageSize, sort_by, order, ...siteSurveyFilters } = queryParams;
		this.initialPagination = { current: current ? current : 1, pageSize: pageSize ? pageSize : 10 };
		this.initialFilters =
			siteSurveyFilters && Object.keys(siteSurveyFilters).length > 0
				? { ...siteSurveyFilters }
				: {};
		updateSiteSurveyFilters(siteSurveyFilters, SITE_SURVEYS_OVERVIEW_TARGET_COLLECTION);
		this.state = {
			...initState,
		};
		this.handleChange = this.handleChange.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
		this.clearAll = this.clearAll.bind(this);
		this.handleOk = this.handleOk.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.showModal = this.showModal.bind(this);
	}

	handleSizeChange = (current, pageSize) => {
		const { location, history, updateSiteSurveyFilters } = this.props;
		let searchString = location.search;
		let searchParams = new URLSearchParams(location.search);
		history.replace(`${location.pathname}?${searchParams}`);
		updateSiteSurveyFilters({}, SITE_SURVEYS_OVERVIEW_TARGET_COLLECTION);
	};

	showModal(e) {
		this.setState({
			visible: true,
		});
	}

	handleOk(e) {
		this.setState({
			visible: false,
		});
	}

	handleCancel(e) {
		this.setState({
			visible: false,
		});
	}

	handleChange(pagination, filters, sorter) {
		this.setState({
			filteredInfo: filters,
			sortedInfo: sorter,
		});
	}

	clearFilters() {
		this.setState({ filteredInfo: null });
	}

	clearAll() {
		this.setState({
			filteredInfo: null,
			sortedInfo: null,
		});
	}

	render() {
		const {
			history,
			siteSurveys,
			locations,
			accountActivated,
			updateSiteSurveyFilters,
			fetchLocations,
			fetchMultipleLocations,
			clearAndUpdateFilters,
			location,
		} = this.props;

		const columns = [
			{
				title: 'Name',
				dataIndex: 'title',
				key: 'title',
				sorter: true,
			},
			{
				title: 'Location',
				dataIndex: ['location', 'name'],
				key: 'location',
				sorter: true,
			},
			{
				title: 'Last Walkthrough Completed',
				dataIndex: 'lastWalkthroughCompletedAt',
				key: 'lastWalkthroughCompletedAt',
				sorter: true,
				render: (_) => (_ ? <DateTimeHover timestamp={_} showDate /> : '--'),
			},
			{
				title: 'Total Walkthroughs',
				dataIndex: 'numberOfWalkthroughs',
				key: 'numberOfWalkthroughs',
				sorter: true,
			},
		];

		const filterConfig = [
			{
				label: 'Location',
				fieldName: 'locationIds',
				hideFilter: true,
				elementDefined: true,
				stateSlice: locations,
				filtersValueAccessor: (filtersStateSlice) => {
					const locationIds = nullSafeGet(
						`${SITE_SURVEYS_OVERVIEW_TARGET_COLLECTION}.filters.locationIds`,
						filtersStateSlice
					);
					const finalIds = nullSafeGetOrElse(
						`${SITE_SURVEYS_OVERVIEW_TARGET_COLLECTION}.filters.regionIds`,
						filtersStateSlice,
						locationIds
					);
					return changeFilterValueToArrayOfIds(finalIds);
				},
				handleChange: (ids) => {
					updateSiteSurveyFilters(
						{
							locationIds: ids.join(',') || undefined,
							regionIds: undefined,
						},
						SITE_SURVEYS_OVERVIEW_TARGET_COLLECTION
					);
				},
				keyAccessor: (loc) => loc.id,
				valueAccessor: (loc) => loc.id,
				targetCollectionName: 'siteSurveysAdvancedFilters',
				fetch: fetchLocations,
				fetchMultiple: fetchMultipleLocations,
				renderItem: (loc) => loc.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
			},
			{
				label: 'Last Walkthrough Completed Between',
				labelPrefix: 'Last Walkthrough completed',
				fieldName: FILTER_CONFIG_NAMES.LAST_WALKTHROUGH_COMPLETED_AT,
				type: FILTER_FIELD_TYPE.DATE_RANGE,
				filtersValueAccessor: (filtersStateSlice) => {
					const startDate = nullSafeGet(
						`${SITE_SURVEYS_OVERVIEW_TARGET_COLLECTION}.filters.${getStartDateFieldName(
							FILTER_CONFIG_NAMES.LAST_WALKTHROUGH_COMPLETED_AT
						)}`,
						filtersStateSlice
					);
					const endDate = nullSafeGet(
						`${SITE_SURVEYS_OVERVIEW_TARGET_COLLECTION}.filters.${getEndDateFieldName(
							FILTER_CONFIG_NAMES.LAST_WALKTHROUGH_COMPLETED_AT
						)}`,
						filtersStateSlice
					);
					return [startDate && moment(startDate), endDate && moment(endDate)];
				},
				handleChange: (values) => {
					updateSiteSurveyFilters(
						{
							[getStartDateFieldName(FILTER_CONFIG_NAMES.LAST_WALKTHROUGH_COMPLETED_AT)]:
								values && values.length > 1 && values[0]
									? formatDateForParam(values[0])
									: undefined,
							[getEndDateFieldName(FILTER_CONFIG_NAMES.LAST_WALKTHROUGH_COMPLETED_AT)]:
								values && values.length > 1 && values[1]
									? formatDateForParam(values[1])
									: undefined,
						},
						SITE_SURVEYS_OVERVIEW_TARGET_COLLECTION
					);
				},
				renderRecord: (item) => item,
				valueAccessor: (item) => item,
				component: OWDateRangePicker,
			},
		];

		const onRow = (record) => ({
			onClick: () => {
				let searchString = location.search;
				if (searchString[0] === '?') {
					searchString = searchString.slice(1);
				}
				let backUrl = `/buyer/siteSurveys/detail/${record.id}/details`;
				if (searchString && searchString.length > 0) {
					backUrl = backUrl.concat(`?backlinkParams=${encodeURIComponent(searchString)}`);
				}
				history.push(backUrl);
			},
		});

		return (
			<Content className="siteSurveysPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited buyer site surveys index page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<div style={{ margin: '0.5em 0' }} className="flex flex-col">
					<SubnavBar
						left={
							<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
								<AdvancedFilters
									updateFilters={updateSiteSurveyFilters}
									filterConfig={filterConfig}
									filtersTargetCollectionName={SITE_SURVEYS_OVERVIEW_TARGET_COLLECTION}
									clearAndUpdateFilters={clearAndUpdateFilters}
									filtersStateSlice={siteSurveys}
									initialFilters={this.initialFilters}
								>
									<Form.Item label="Location">
										<RegionsLocationsTreeSelect
											mode="multiple"
											filtersSlice={siteSurveys}
											filtersValueAccessor={(filtersStateSlice) => {
												const locationIds = nullSafeGet(
													`${SITE_SURVEYS_OVERVIEW_TARGET_COLLECTION}.filters.locationIds`,
													filtersStateSlice
												);
												const finalIds = nullSafeGetOrElse(
													`${SITE_SURVEYS_OVERVIEW_TARGET_COLLECTION}.filters.regionIds`,
													filtersStateSlice,
													locationIds
												);
												return changeFilterValueToArrayOfIds(finalIds);
											}}
											onChange={(ids) => {
												updateSiteSurveyFilters(
													{
														locationIds: ids.join(',') || undefined,
														regionIds: undefined,
													},
													SITE_SURVEYS_OVERVIEW_TARGET_COLLECTION
												);
											}}
											targetCollectionName={'siteSurveysAdvancedFilters'}
										/>
									</Form.Item>
								</AdvancedFilters>
							</div>
						}
						right={
							<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_SITE_SURVEYS}>
								<Button
									type="primary"
									size="large"
									onClick={() => this.props.history.push('/buyer/siteSurveys/overview/new')}
								>
									Add Site Surveys
								</Button>
							</AccessPermissionChecker>
						}
					/>
				</div>
				<div className="flex w-full flex-1 flex-row">
					<SelectedFilters
						updateFilters={(filters) =>
							updateSiteSurveyFilters(filters, SITE_SURVEYS_OVERVIEW_TARGET_COLLECTION)
						}
						filterValues={nullSafeGet(
							`${SITE_SURVEYS_OVERVIEW_TARGET_COLLECTION}.filters`,
							siteSurveys
						)}
						filterConfig={filterConfig}
					/>
				</div>
				<Row style={{ margin: '0.5em -8px' }}>
					<Col span={24}>
						<Card bodyStyle={{ padding: 8 }}>
							{accountActivated ? (
								<PaginatedReduxTable
									emptyState={
										<EmptyState
											graphic={
												<img
													style={{ marginBottom: 8 }}
													src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
													alt="It's a little lonely in here."
												/>
											}
											headline={"It's a little lonely in here."}
											body={
												<div style={{ textAlign: 'center' }}>
													<div style={{ maxWidth: 440, marginBottom: 16 }}>
														Looks like your team has not created any site surveys yet.
													</div>
												</div>
											}
										/>
									}
									collectionName="site_surveys"
									targetCollectionName="siteSurveysIndex"
									columns={columns}
									updateQueryParams={true}
									onRow={onRow}
									keyAccessor={(el) => el.id}
									initialPagination={this.initialPagination}
									initialFilters={this.initialFilters}
									fetchData={siteSurveysRestCrudThunksForBuyer.read}
								/>
							) : (
								<EmptyState
									graphic={
										<img
											style={{ marginBottom: 8 }}
											src="https://s3.amazonaws.com/mock-data-assets/categories/images/letter owl.svg"
											alt="You haven't activated your account yet."
										/>
									}
									headline={"You haven't activated your account yet."}
									body={
										<div style={{ textAlign: 'center' }}>
											<div style={{ maxWidth: 560, marginBottom: 16 }}>
												Please check your email. We sent you a note with an activation link.
											</div>
										</div>
									}
								/>
							)}
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	currentUser: state.session.currentUser,
	accountActivated: state.session.accountActivated,
	siteSurveys: state.site_surveys,
	locations: state.locations,
	location: ownProps.location,
});

const mapDispatchToProps = (dispatch) => ({
	getSiteSurveysCount: (params, countName) =>
		dispatch(siteSurveysRestCrudThunksForBuyer.countBy(params, countName)),
	updateSiteSurveyFilters: (filters, targetCollection) =>
		dispatch(SITE_SURVEYS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			SITE_SURVEYS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)
		),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			locationsRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(SiteSurveysIndexPage)
);

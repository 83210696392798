import {
	DISABLE_SUPPLIER_USER_FAILURE,
	DISABLE_SUPPLIER_USER_START,
	DISABLE_SUPPLIER_USER_SUCCESS,
	FETCH_SUPPLIER_USERS,
	FETCH_SUPPLIER_USERS_FAILURE,
	FETCH_SUPPLIER_USERS_SUCCESS,
	REINVITE_SUPPLIER_USER_FAILURE,
	REINVITE_SUPPLIER_USER_START,
	REINVITE_SUPPLIER_USER_SUCCESS,
} from '../actions/supplier_users_actions';
import { SupplierUsersStoreState } from '../types/models';

const initialState: SupplierUsersStoreState = {
	records: {},
	detail: {},
	errors: [],
	loading: false,
	loadingUsers: new Set([]),
};

const reducer = (oldState = initialState, action): SupplierUsersStoreState => {
	Object.freeze(oldState);
	let newRecords;
	let newLoadingUsers;
	switch (action.type) {
		case FETCH_SUPPLIER_USERS:
			return Object.assign({}, oldState, {
				errors: [],
				loading: true,
			});
		case FETCH_SUPPLIER_USERS_SUCCESS:
			newRecords = action.records.reduce((acc, el) => {
				acc[el.account.userName] = el;
				return acc;
			}, {});
			return Object.assign({}, oldState, {
				records: newRecords,
				loading: false,
				errors: [],
			});
		case FETCH_SUPPLIER_USERS_FAILURE:
			return Object.assign({}, oldState, {
				loading: false,
				errors: [...oldState.errors, ...action.errors],
			});
		case DISABLE_SUPPLIER_USER_START:
			newLoadingUsers = new Set(oldState.loadingUsers);
			newLoadingUsers.add(action.userName);
			return Object.assign({}, oldState, {
				errors: [],
				loadingUsers: newLoadingUsers,
			});
		case DISABLE_SUPPLIER_USER_SUCCESS:
			newLoadingUsers = new Set(oldState.loadingUsers);
			newLoadingUsers.delete(action.userName);
			return Object.assign({}, oldState, {
				loadingUsers: newLoadingUsers,
				errors: [],
			});
		case DISABLE_SUPPLIER_USER_FAILURE:
			newLoadingUsers = new Set(oldState.loadingUsers);
			newLoadingUsers.delete(action.userName);
			return Object.assign({}, oldState, {
				loadingUsers: newLoadingUsers,
				errors: [...oldState.errors, ...action.errors],
			});
		case REINVITE_SUPPLIER_USER_START:
			newLoadingUsers = new Set(oldState.loadingUsers);
			newLoadingUsers.add(action.userName);
			return Object.assign({}, oldState, {
				errors: [],
				loadingUsers: newLoadingUsers,
			});
		case REINVITE_SUPPLIER_USER_SUCCESS:
			newLoadingUsers = new Set(oldState.loadingUsers);
			newLoadingUsers.delete(action.userName);
			return Object.assign({}, oldState, {
				loadingUsers: newLoadingUsers,
				errors: [],
			});
		case REINVITE_SUPPLIER_USER_FAILURE:
			newLoadingUsers = new Set(oldState.loadingUsers);
			newLoadingUsers.delete(action.userName);
			return Object.assign({}, oldState, {
				loadingUsers: newLoadingUsers,
				errors: [...oldState.errors, ...action.errors],
			});
		default:
			return oldState;
	}
};

export default reducer;

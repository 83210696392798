import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { Button, Form, Modal, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import OWRichTextEditor from '../../rich_text_editor/OWRichTextEditor';

const RichTextEditorModal: FC<any> = ({
	id,
	onCancel,
	title,
	loading,
	okText,
	onSubmit,
	useRichText,
	notes,
}): React.ReactElement => {
	const FORM_NAME = useMemo(() => `${id}-notes-form`, [id]);

	const editorRef = useRef(null);

	const [form] = useForm();

	const [text, setText] = useState(useRichText ? '' : notes || '');

	const handleSubmit = useCallback(() => {
		const note = useRichText
			? editorRef && editorRef.current && editorRef.current.getContent()
			: text;
		onSubmit(note);
	}, [onSubmit, text, useRichText]);

	return (
		<Modal
			visible={true}
			width={800}
			title={title}
			closable
			onCancel={onCancel}
			forceRender
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button
					type="primary"
					size="large"
					style={{ marginLeft: '16px' }}
					loading={loading}
					key="submit"
					htmlType="submit"
					form={FORM_NAME}
				>
					{okText}
				</Button>,
			]}
		>
			<Form
				form={form}
				id={FORM_NAME}
				layout="vertical"
				requiredMark={false}
				initialValues={{ notes }}
				onFinish={handleSubmit}
			>
				<Form.Item name="notes">
					{useRichText ? (
						<OWRichTextEditor initialEditorState={notes || ''} editorRef={editorRef} />
					) : (
						<Input.TextArea
							rows={4}
							// initialValue={notes || ''}
							onChange={(e) => setText(e.target.value)}
						/>
					)}
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default RichTextEditorModal;

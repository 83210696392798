import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Alert, Button, DatePicker, Select, InputNumber, Radio, Checkbox } from 'antd';
import { connect } from 'react-redux';
import {
	bulkCreatePlannedMaintenanceSchedules,
	bulkUpdatePlannedMaintenanceSchedules,
	plannedMaintenanceScheduleDeleteForBuyer,
	plannedMaintenanceScheduleDeleteForSupplier,
} from '../../thunks/planned_maintenance_schedules_thunks';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import BackButton from '../back_button/BackButton';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import {
	locationsRestCrudThunksForBuyer,
	locationsRestCrudThunksForSupplier,
} from '../../thunks/locations_thunks';
import {
	plannedMaintenanceTemplatesRestCrudThunksForBuyer,
	plannedMaintenanceTemplatesRestCrudThunksForSupplier,
} from '../../thunks/planned_maintenance_templates_thunks';
import {
	assetsRestCrudThunksForBuyer,
	assetsRestCrudThunksForSupplier,
} from '../../thunks/assets_thunks';
import {
	assetTypesRestCrudThunksForBuyer,
	assetTypesRestCrudThunksForSupplier,
} from '../../thunks/asset_types_thunks';
import { cross_join } from '../../utils/ArrayUtils';
import { Option } from 'antd/es/mentions';
import { DAYS_OF_THE_WEEK } from '../../constants/times';
import {
	breakdownFrequencyText,
	capitalize,
	getFrequencyUnit,
	getNumberSuffix,
	getWeekOfTheMonth,
	isLastDayOfTheMonth,
} from '../../utils/DataFormatterUtils';
import {
	fetchSupplierFacilitiesInPrivateNetworkForBuyer,
	fetchSupplierFacilitiesInPrivateNetworkForSupplier,
	supplierFacilitiesRestCrudThunksForBuyer,
	supplierFacilitiesRestCrudThunksForSupplier,
} from '../../thunks/supplier_facilities_thunks';
import { getBuyerCompanyApprovalTiers } from '../../thunks/company_approval_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';
import _ from 'lodash';
import { debounce } from '../../utils/PerformanceUtils';
import { BUYER_ADMIN_ONLY } from '../roles/roles';
import { getPlannedMaintenanceName } from '../../thunks/planned_maintenance_schedule_groups_thunks';
import BuyerRegionsLocationsTreeSelect from '../regions_tree_select/BuyerRegionsLocationsTreeSelect';
import AssetNumberDisplay from '../asset_row_display/AssetNumberDisplay';
import FileUploader from '../file_uploader/FileUploader';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { isEqual } from 'lodash';
import OWDatePicker from '../ow_date_picker/OWDatePicker';

const moment = require('moment');

require('./PlannedMaintenanceScheduleForm.less');
const PLANNED_MAINTENANCE_SCHEDULE_FORM_LOCATIONS_AUTOCOMPLETE =
	'PLANNED_MAINTENANCE_SCHEDULE_FORM_LOCATIONS_AUTOCOMPLETE';
const PLANNED_MAINTENANCE_SCHEDULE_FORM_ASSETS_AUTOCOMPLETE =
	'PLANNED_MAINTENANCE_SCHEDULE_FORM_ASSETS_AUTOCOMPLETE';
const PLANNED_MAINTENANCE_SCHEDULE_FORM_ASSET_TYPES_AUTOCOMPLETE =
	'PLANNED_MAINTENANCE_SCHEDULE_FORM_ASSET_TYPES_AUTOCOMPLETE';
const PLANNED_MAINTENANCE_SCHEDULE_FORM_PLANNED_MAINTENANCE_TEMPLATES_AUTOCOMPLETE =
	'PLANNED_MAINTENANCE_SCHEDULE_FORM_PLANNED_MAINTENANCE_TEMPLATES_AUTOCOMPLETE';
const PLANNED_MAINTENANCE_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE =
	'PLANNED_MAINTENANCE_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE';

const FORM_FIELD_WIDTH_STYLE = { maxWidth: 600 };

interface PlannedMaintenanceScheduleFormProps extends FormComponentProps {
	creating: boolean;
	updating: boolean;
	formData: any;
	history: any;
	redirectForwardUrl?: string;
	onSuccess?: any;
	plannedMaintenanceTemplates: any;
	fetchPlannedMaintenanceTemplates: any;
	fetchMultiplePlannedMaintenanceTemplates: any;
	fetchLocations: any;
	fetchMultipleLocations: any;
	fetchAssets: any;
	fetchMultipleAssets: any;
	fetchAssetTypes: any;
	fetchMultipleAssetTypes: any;
	locations: any;
	assets: any;
	assetTypes: any;
	createErrors: any[];
	updateErrors: any[];
	currentUser: any;
	create: any;
	update: any;
	deleteEntity: any;
	fetchMultipleSupplierFacilitiesInPrivateNetwork: any;
	fetchSupplierFacilitiesInPrivateNetwork: any;
	supplierFacilities: any;
	fetchApprovalHeirarchy: any;
	getPlannedMaintenanceTitle: any;
	userType: any;
}

class PlannedMaintenanceScheduleForm extends React.Component<
	PlannedMaintenanceScheduleFormProps,
	any
> {
	constructor(props) {
		super(props);
		this.state = {
			frequencyUnit: getFrequencyUnit(props.formData.frequency),
			nte: null,
			approvalHeirarchy: {},
		};
	}
	componentDidMount() {
		const { fetchApprovalHeirarchy, currentUser } = this.props;
		fetchApprovalHeirarchy().then((data) => {
			const tiers = nullSafeGet('0.tierDetails', data);
			if (tiers && tiers.length > 0) {
				const userTier =
					_.find(tiers, (item) => _.find(item.approvers, { email: currentUser.email })) || tiers[0];
				this.setState({
					approvalHeirarchy: { amount: userTier.amountUpto || 1000000000 },
				});
			}
		});
	}
	handleNTEValueChangeDebounced = () =>
		debounce(
			(nte) => {
				this.setState({ nte: nte });
			},
			500,
			false
		);

	handleNTEValueChange = (e) => {
		const inputString = e.target.value;
		this.handleNTEValueChangeDebounced()(inputString);
	};

	handlePlannedMaintenanceTitleChange = async (rule, value, callback) => {
		const { getPlannedMaintenanceTitle, formData } = this.props;
		const isUpdate = formData && formData.plannedMaintenanceTemplateId !== undefined;
		if (value && value !== formData.title) {
			if (value.includes('%')) {
				callback(<span>% character is not allowed.</span>);
			}
			let data = await getPlannedMaintenanceTitle(encodeURIComponent(value));
			if (data && Array.isArray(data)) {
				data.map((PMSchedule) => {
					callback(
						<span>
							Error: Duplicate with this{' '}
							<a
								href={'/buyer/plannedMaintenance/detail/'.concat(
									encodeURIComponent(nullSafeGet('title', PMSchedule)),
									'/',
									encodeURIComponent(nullSafeGet('frequency', PMSchedule)),
									'/',
									encodeURIComponent(nullSafeGet('plannedMaintenanceTemplateId', PMSchedule)),
									'/',
									encodeURIComponent(nullSafeGet('basedOn', PMSchedule)),
									`/details`
								)}
								target="_blank"
								rel="noreferrer"
							>
								Planned Maintenance Schedule
							</a>
						</span>
					);
				});
			}
		}
	};

	handleFileManualUpload = (supplierContractDocuments) => {
		const { form } = this.props;
		form.setFieldsValue({ supplierContractDocuments });
	};

	handleFileListChange = (info) => {
		if (info.file.status === undefined) {
			info.fileList.splice(-1, 1);
		}
		return info;
	};

	handlePMTemplateChange = (id) => {
		const { plannedMaintenanceTemplates, form, formData } = this.props;
		const { setFieldsValue } = form;
		const records = getRecordsForTargetCollection(
			plannedMaintenanceTemplates,
			PLANNED_MAINTENANCE_SCHEDULE_FORM_PLANNED_MAINTENANCE_TEMPLATES_AUTOCOMPLETE
		);
		const pmTemplate = records.find((_) => _.id === id);

		const isCreate = formData && formData.plannedMaintenanceTemplateId === undefined;
		setFieldsValue({
			plannedMaintenanceTemplateId: id,
			...(isCreate &&
				pmTemplate && {
					supplierFacilityId: nullSafeGet('supplierFacilityId', pmTemplate),
					nte: nullSafeGet('nte', pmTemplate),
					supplierContractId: nullSafeGet('supplierContractId', pmTemplate),
					supplierContractDocuments: nullSafeGet('supplierContractDocuments', pmTemplate),
				}),
		});
	};

	handleSubmit = (e, submitAction) => {
		const { history, redirectForwardUrl, onSuccess, formData, deleteEntity } = this.props;
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const isUpdate = formData && formData.plannedMaintenanceTemplateId !== undefined;

				const schedules = nullSafeGetOrElse('plannedMaintenanceSchedules', formData, []);
				const firstSchedule = schedules && schedules[0];
				const isSameNTE = schedules.every((sched) => sched.nte === firstSchedule.nte);
				const isSameSupplier = schedules.every(
					(sched) => sched.supplierFacilityId === firstSchedule.supplierFacilityId
				);
				const isSameContractId = schedules.every(
					(sched) => sched.supplierContractId === firstSchedule.supplierContractId
				);
				const isSameContractDocuments = schedules.every((sched) =>
					isEqual(
						nullSafeGetOrElse('supplierContractDocuments', sched, []),
						nullSafeGetOrElse('supplierContractDocuments', firstSchedule, [])
					)
				);

				//Optional Values
				//Can't Vary
				const monthConfig =
					values.frequencyUnit === 'months'
						? values.monthConfig !== 'undefined'
							? values.monthConfig
							: undefined
						: undefined;
				const weekDays =
					values.frequencyUnit === 'weeks'
						? values.weekDays.map((day) => _.lowerCase(day))
						: undefined;

				//Can Vary
				const nte = values.nte ? parseFloat(values.nte) : undefined;
				const nteRemoved = nte === undefined && isSameNTE;
				const supplierFacilityId = values.supplierFacilityId;
				const supplierRemoved = supplierFacilityId === undefined && isSameSupplier;
				const supplierContractId = values.supplierContractId;
				const supplierContractIdRemoved = supplierContractId === undefined && isSameContractId;
				const supplierContractDocuments = values.supplierContractDocuments;
				const supplierContractDocumentsRemoved =
					nullSafeGetOrElse('supplierContractDocuments', values, []).length === 0 &&
					isSameContractDocuments;

				const missingLocationIds = () => {
					if (
						values.basedOn === 'location' &&
						values.basedOn === formData.basedOn &&
						values.title === formData.title &&
						values.frequency === formData.frequencyDays
					) {
						return (formData.locationIds || []).filter(
							(item) => (values.locationIds || []).indexOf(item) < 0
						);
					} else if (formData.basedOn === 'location') {
						return formData.locationIds || [];
					} else {
						return [];
					}
				};

				const missingAssetIds = () => {
					if (
						values.basedOn === 'asset' &&
						values.basedOn === formData.basedOn &&
						values.title === formData.title &&
						values.frequency === formData.frequencyDays
					) {
						return (formData.assetIds || []).filter(
							(item) => (values.assetIds || []).indexOf(item) < 0
						);
					} else if (formData.basedOn === 'asset') {
						return formData.assetIds || [];
					} else {
						return [];
					}
				};

				const missingAssetTypeLocationIds = () => {
					if (
						values.basedOn === 'assetType' &&
						values.basedOn === formData.basedOn &&
						values.title === formData.title &&
						values.frequency === formData.frequencyDays
					) {
						return (formData.locationIds || []).filter(
							(item) => (values.locationIds || []).indexOf(item) < 0
						);
					} else if (formData.basedOn === 'assetType') {
						return formData.locationIds || [];
					} else {
						return [];
					}
				};

				const missingAssetTypeIds = () => {
					if (
						values.basedOn === 'assetType' &&
						values.basedOn === formData.basedOn &&
						values.title === formData.title &&
						values.frequency === formData.frequencyDays
					) {
						return (formData.assetTypeIds || []).filter(
							(item) => (values.assetTypeIds || []).indexOf(item) < 0
						);
					} else if (formData.basedOn === 'assetType') {
						return formData.assetTypeIds || [];
					} else {
						return [];
					}
				};

				const locationDeletePromises = missingLocationIds().map((locationId) => {
					let plannedMaintenanceSchedule = {
						title: formData.title,
						startDate: formData.startDate,
						endDate: formData.endDate,
						plannedMaintenanceTemplateId: formData.plannedMaintenanceTemplateId,
						frequency: 'every' + formData.frequencyDays + values.frequencyUnit,
						weekDays,
						monthConfig,
						status: formData.status,
						basedOn: formData.basedOn,
						supplierFacilityId: formData.supplierFacilityId,
						nte: formData.nte,
						locationId,
					};

					deleteEntity(plannedMaintenanceSchedule);
				});

				const assetDeletePromises = missingAssetIds().map((assetId) => {
					let plannedMaintenanceSchedule = {
						title: formData.title,
						startDate: formData.startDate,
						endDate: formData.endDate,
						plannedMaintenanceTemplateId: formData.plannedMaintenanceTemplateId,
						frequency: 'every' + formData.frequencyDays + values.frequencyUnit,
						weekDays,
						monthConfig,
						status: formData.status,
						basedOn: formData.basedOn,
						supplierFacilityId: formData.supplierFacilityId,
						nte: formData.nte,
						assetId,
					};
					deleteEntity(plannedMaintenanceSchedule);
				});

				const assetTypeDeletePromises = cross_join([
					missingAssetTypeIds(),
					missingAssetTypeLocationIds(),
				]).map((assetTypeLocationComb) => {
					let plannedMaintenanceSchedule = {
						title: formData.title,
						startDate: formData.startDate,
						endDate: formData.endDate,
						plannedMaintenanceTemplateId: formData.plannedMaintenanceTemplateId,
						frequency: 'every' + formData.frequencyDays + values.frequencyUnit,
						weekDays,
						monthConfig,
						status: formData.status,
						basedOn: formData.basedOn,
						supplierFacilityId: formData.supplierFacilityId,
						nte: formData.nte,
						assetTypeId: assetTypeLocationComb[0],
						locationId: assetTypeLocationComb[1],
					};
					deleteEntity(plannedMaintenanceSchedule);
				});

				const commonValues = {
					title: values.title,
					startDate: moment(values.startAndEndDates[0]).format(`YYYY-MM-DD`),
					endDate: values.startAndEndDates[1]
						? moment(values.startAndEndDates[1]).format(`YYYY-MM-DD`)
						: undefined,
					plannedMaintenanceTemplateId: values.plannedMaintenanceTemplateId,
					frequency: 'every' + values.frequency + values.frequencyUnit,
					weekDays,
					monthConfig,
					status: values.status,
					basedOn: values.basedOn,
					supplierFacilityId,
					nte,
					supplierContractId,
					supplierContractDocuments,
					createConfig: values.createConfig,
					nteRemoved,
					supplierRemoved,
					supplierContractIdRemoved,
					supplierContractDocumentsRemoved,
				};

				if (values.basedOn === 'asset') {
					Promise.all(
						locationDeletePromises.concat(assetDeletePromises).concat(assetTypeDeletePromises)
					).then(() => {
						const plannedMaintenanceSchedule = {
							...commonValues,
							...(isUpdate ? { ids: schedules.map((s) => s.id) } : { assetIds: values.assetIds }),
						};
						submitAction(plannedMaintenanceSchedule).then((record) => {
							if (redirectForwardUrl) {
								history.push(redirectForwardUrl);
							}
							if (onSuccess) {
								onSuccess(record[0], 'asset');
							}
						});
					});
				} else if (values.basedOn === 'location') {
					Promise.all(
						locationDeletePromises.concat(assetDeletePromises).concat(assetTypeDeletePromises)
					).then(() => {
						const plannedMaintenanceSchedule = {
							...commonValues,
							...(isUpdate
								? { ids: schedules.map((s) => s.id) }
								: { locationIds: values.locationIds }),
						};

						submitAction(plannedMaintenanceSchedule).then((record) => {
							if (redirectForwardUrl) {
								history.push(redirectForwardUrl);
							}
							if (onSuccess) {
								onSuccess(record[0], 'location');
							}
						});
					});
				} else if (values.basedOn === 'assetType') {
					Promise.all(
						locationDeletePromises.concat(assetDeletePromises).concat(assetTypeDeletePromises)
					).then(() => {
						const plannedMaintenanceSchedule = {
							...commonValues,
							...(isUpdate
								? { ids: schedules.map((s) => s.id) }
								: {
										assetTypeIds: values.assetTypeIds,
										locationIds: values.locationIds,
								  }),
						};
						submitAction(plannedMaintenanceSchedule).then((record) => {
							if (redirectForwardUrl) {
								history.push(redirectForwardUrl);
							}
							if (onSuccess) {
								onSuccess(record[0], 'assetType');
							}
						});
					});
				}
			}
		});
	};

	render() {
		const {
			updating,
			creating,
			form,
			create,
			createErrors,
			update,
			updateErrors,
			plannedMaintenanceTemplates,
			fetchPlannedMaintenanceTemplates,
			fetchMultiplePlannedMaintenanceTemplates,
			locations,
			assets,
			assetTypes,
			fetchAssets,
			fetchMultipleAssets,
			fetchAssetTypes,
			fetchMultipleAssetTypes,
			formData,
			currentUser,
			supplierFacilities,
			fetchMultipleSupplierFacilitiesInPrivateNetwork,
			fetchSupplierFacilitiesInPrivateNetwork,
			userType,
		} = this.props;
		const { frequencyUnit, approvalHeirarchy } = this.state;
		const { getFieldDecorator, getFieldValue } = form;
		const isUpdate = formData && formData.plannedMaintenanceTemplateId !== undefined;
		const submitAction = isUpdate ? update : create;
		const submitText = isUpdate ? 'Update schedule' : 'Create schedule';
		const isLoading = updating || creating;
		const DATE_FORMAT = 'MM-DD-YYYY';
		const isAdmin = currentUser.roles.some((role) => BUYER_ADMIN_ONLY.has(role));
		const frequencies = ['days', 'weeks', 'months', 'years'];
		const frequencyBreakdown = isUpdate
			? formData.frequency && breakdownFrequencyText(frequencyUnit)[formData.frequency]
			: { amount: 1 };
		const startAndEndDates = form.getFieldValue('startAndEndDates');
		const referenceDay =
			startAndEndDates && startAndEndDates[0] ? moment(startAndEndDates[0]) : moment();
		const dayOfTheWeek = DAYS_OF_THE_WEEK[referenceDay.day()];
		const weekOfTheMonth = getWeekOfTheMonth(referenceDay);
		const schedules = nullSafeGetOrElse('plannedMaintenanceSchedules', formData, []);

		const firstSchedule = schedules && schedules[0];
		const createConfig = nullSafeGet('createConfig', firstSchedule) || 'create';
		const isSameCreateConfig = schedules.every(
			(sched) =>
				nullSafeGetOrElse('createConfig', sched, 'create') ===
				nullSafeGet('createConfig', firstSchedule)
		);
		const weekDays =
			firstSchedule && firstSchedule.weekDays
				? firstSchedule.weekDays.map((d) => capitalize(d))
				: [];
		const selectedWeekDays =
			weekDays.length > 0 ? weekDays : [DAYS_OF_THE_WEEK[referenceDay.day()]];
		const monthConfig =
			firstSchedule && firstSchedule.monthConfig ? firstSchedule.monthConfig : 'undefined';

		const startDate = isUpdate
			? schedules.some((sched) => sched.startDate !== firstSchedule.startDate)
				? undefined /*'*** Values Vary ***'*/
				: moment.parseZone(firstSchedule.startDate)
			: undefined;

		const endDate = isUpdate
			? schedules.some((sched) => sched.endDate !== firstSchedule.endDate)
				? undefined /*'*** Values Vary ***'*/
				: firstSchedule.endDate
				? moment.parseZone(firstSchedule.endDate)
				: undefined
			: undefined;

		const isSameStartDate = schedules.every((sched) => sched.startDate === firstSchedule.startDate);
		const isSameEndDate = schedules.every((sched) => sched.endDate === firstSchedule.endDate);
		const isSameNTE = schedules.every((sched) => sched.nte === firstSchedule.nte);
		const isSameSupplier = schedules.every(
			(sched) => sched.supplierFacilityId === firstSchedule.supplierFacilityId
		);
		const isSameContractId = schedules.every(
			(sched) => sched.supplierContractId === firstSchedule.supplierContractId
		);
		const isSameContractDocuments = schedules.every((sched) =>
			isEqual(
				nullSafeGetOrElse('supplierContractDocuments', sched, []),
				nullSafeGetOrElse('supplierContractDocuments', firstSchedule, [])
			)
		);

		getFieldDecorator('status', { initialValue: formData.status || 'active' });
		getFieldDecorator('supplierContractDocuments', {
			initialValue: formData.supplierContractDocuments || [],
		});

		const supplierContractDocuments = nullSafeGet('supplierContractDocuments.0', formData)
			? nullSafeGet('supplierContractDocuments', formData)
			: nullSafeGet('0', getFieldValue('supplierContractDocuments'))
			? getFieldValue('supplierContractDocuments')
			: [];

		return (
			<Form
				layout="vertical"
				onSubmit={(e) => this.handleSubmit(e, submitAction)}
				className="supplierForm"
				style={FORM_FIELD_WIDTH_STYLE}
			>
				{createErrors.length > 0 ? (
					<Form.Item>
						<Alert message={createErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				{updateErrors.length > 0 ? (
					<Form.Item>
						<Alert message={updateErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				<Form.Item label="Name">
					{getFieldDecorator('title', {
						validateTrigger: ['onBlur'],
						initialValue: formData.title,
						rules: [
							{ required: true, message: 'Please give the PM schedule a title.' },
							{ validator: this.handlePlannedMaintenanceTitleChange },
						],
					})(<Input />)}
				</Form.Item>
				<Form.Item
					label="Scheduled Start and End Dates"
					help={
						!(isSameStartDate && isSameEndDate)
							? 'Schedule values vary, must edit on individual schedule.'
							: null
					}
				>
					{form.getFieldDecorator('startAndEndDates', {
						rules: [
							{
								type: 'array',
								required: true,
								message: 'Please select a start and end date!',
							},
						],
						initialValue: [startDate, endDate],
					})(
						<OWDatePicker
							format={DATE_FORMAT}
							showTime={false}
							allowEmpty={[false, true]}
							disabled={!(isSameStartDate && isSameEndDate)}
						/>
					)}
				</Form.Item>
				{getFieldValue('basedOn') === 'meters' ? (
					<Form.Item label="Trigger">
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<label style={{ marginRight: 8, whiteSpace: 'nowrap' }}>
								Create work order when reading
							</label>
							{getFieldDecorator('triggerOperator', {
								rules: [{ required: true, message: 'This field is required.' }],
							})(
								<Select style={{ marginRight: 8, width: 80, ...FORM_FIELD_WIDTH_STYLE }}>
									<Select.Option value="=" key="=">
										=
									</Select.Option>
									<Select.Option value=">" key=">">
										{'>'}
									</Select.Option>
									<Select.Option value="<" key="<">
										{'<'}
									</Select.Option>
								</Select>
							)}
							{getFieldDecorator('triggerValue', {
								rules: [{ required: true, message: 'This field is required.' }],
							})(<Input />)}
						</div>
					</Form.Item>
				) : (
					<Form.Item label="Frequency">
						<div>
							<label style={{ marginRight: 8 }}>Every</label>
							{getFieldDecorator('frequency', {
								initialValue: (frequencyBreakdown && frequencyBreakdown.amount) || 7,
								rules: [{ required: true, message: 'This field is required.' }],
							})(<InputNumber min={1} max={365} />)}
							{getFieldDecorator('frequencyUnit', {
								initialValue: frequencyUnit,
								rules: [{ required: true, message: 'This field is required.' }],
							})(
								<Select
									style={{ marginLeft: 8, width: 200 }}
									onChange={(frequencyUnit) => this.setState({ frequencyUnit })}
								>
									{frequencies.map((f) => (
										<Option key={f} value={f}>
											{f}
										</Option>
									))}
								</Select>
							)}
						</div>
					</Form.Item>
				)}
				{this.state.frequencyUnit === 'weeks' ? (
					<Form.Item label={'Repeat On'}>
						{getFieldDecorator('weekDays', {
							initialValue: selectedWeekDays,
							rules: [{ required: true, message: 'This field is required.' }],
						})(<Checkbox.Group options={DAYS_OF_THE_WEEK} />)}
					</Form.Item>
				) : this.state.frequencyUnit === 'months' ? (
					<Form.Item>
						{getFieldDecorator('monthConfig', {
							initialValue: monthConfig,
							rules: [{ required: true, message: 'This field is required.' }],
						})(
							<Select>
								<Option
									key={'monthConfig'}
									value={weekOfTheMonth.concat('_', _.lowerCase(dayOfTheWeek))}
								>
									On the {weekOfTheMonth} {dayOfTheWeek} of the month.
								</Option>
								{isLastDayOfTheMonth(referenceDay) ? (
									<Option key={'lastDayOfTheMonth'} value={'last_day'}>
										On the last day of the month.
									</Option>
								) : null}
								<Option key={'undefined'} value={'undefined'}>
									On the {referenceDay.date()}
									{getNumberSuffix(referenceDay.date())} day of the month.
								</Option>
							</Select>
						)}
					</Form.Item>
				) : null}
				<Form.Item label="Planned Maintenance Template">
					{getFieldDecorator('plannedMaintenanceTemplateId', {
						initialValue: formData.plannedMaintenanceTemplateId,
						rules: [{ required: true, message: 'This field is required.' }],
					})(
						<OWAsyncSelect
							disabled={isUpdate}
							stateSlice={plannedMaintenanceTemplates}
							targetCollectionName={
								PLANNED_MAINTENANCE_SCHEDULE_FORM_PLANNED_MAINTENANCE_TEMPLATES_AUTOCOMPLETE
							}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultiplePlannedMaintenanceTemplates(ids, targetCollectionName);
							}}
							onChange={this.handlePMTemplateChange}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchPlannedMaintenanceTemplates(
									{ title: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							renderRecord={(plannedMaintenanceTemplate) => (
								<Select.Option
									key={plannedMaintenanceTemplate.id}
									value={plannedMaintenanceTemplate.id}
								>
									{plannedMaintenanceTemplate.title}
								</Select.Option>
							)}
							sortBy={{ sort_by: 'title', order: 'ascend' }}
						/>
					)}
				</Form.Item>
				<Form.Item>
					{getFieldDecorator('basedOn', {
						initialValue: formData.basedOn || 'location',
						rules: [{ required: true, message: 'This field is required.' }],
					})(
						<Radio.Group disabled={isUpdate}>
							<Radio value="asset">Based on Asset</Radio>
							<Radio value="location">Based on Location</Radio>
							<Radio value="assetType">Based on Asset Type</Radio>
						</Radio.Group>
					)}
				</Form.Item>

				{getFieldValue('basedOn') === 'location' ? (
					<Form.Item label="Locations">
						{getFieldDecorator('locationIds', {
							initialValue: formData.locationIds,
							rules: [
								{
									required: getFieldValue('basedOn') === 'location',
									message: 'This field is required.',
								},
							],
						})(
							<BuyerRegionsLocationsTreeSelect
								mode="multiple"
								disabled={isUpdate || getFieldValue('basedOn') !== 'location'}
								emptyWhenDisabled={false}
								filtersSlice={locations}
								filtersValueAccessor={() => form.getFieldValue('locationIds')}
								targetCollectionName={PLANNED_MAINTENANCE_SCHEDULE_FORM_LOCATIONS_AUTOCOMPLETE}
								renderRecord={(d) => (
									<Select.Option value={d.id} key={d.id}>
										<div>{d.name}</div>
									</Select.Option>
								)}
								sortBy={{ sort_by: 'name', order: 'ascend' }}
							/>
						)}
					</Form.Item>
				) : null}
				{getFieldValue('basedOn') === 'asset' ? (
					<Form.Item label="Assets">
						{getFieldDecorator('assetIds', {
							initialValue: formData.assetIds,
							rules: [
								{
									required: getFieldValue('basedOn') === 'asset',
									message: 'This field is required.',
								},
							],
						})(
							<OWAsyncSelect
								mode="multiple"
								disabled={isUpdate || getFieldValue('basedOn') !== 'asset'}
								emptyWhenDisabled={false}
								stateSlice={assets}
								targetCollectionName={PLANNED_MAINTENANCE_SCHEDULE_FORM_ASSETS_AUTOCOMPLETE}
								fetchMultiple={(ids, targetCollectionName) => {
									fetchMultipleAssets(ids, targetCollectionName);
								}}
								fetchData={(
									searchText,
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								) => {
									fetchAssets(
										{ name: searchText },
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									);
								}}
								renderRecord={(d) => (
									<Select.Option value={d.id} key={d.id}>
										<div className="flex flex-col">
											<div>
												{nullSafeGetOrElse('location.name', d, '')} - {d.name}
											</div>
											<AssetNumberDisplay asset={d} />
										</div>
									</Select.Option>
								)}
								sortBy={{ sort_by: 'name', order: 'ascend' }}
							/>
						)}
					</Form.Item>
				) : null}
				{getFieldValue('basedOn') === 'assetType' ? (
					<div>
						<Form.Item label="Asset Types">
							{getFieldDecorator('assetTypeIds', {
								initialValue: formData.assetTypeIds,
								rules: [
									{
										required: getFieldValue('basedOn') === 'assetType',
										message: 'This field is required.',
									},
								],
							})(
								<OWAsyncSelect
									mode="multiple"
									disabled={isUpdate || getFieldValue('basedOn') !== 'assetType'}
									emptyWhenDisabled={false}
									stateSlice={assetTypes}
									targetCollectionName={PLANNED_MAINTENANCE_SCHEDULE_FORM_ASSET_TYPES_AUTOCOMPLETE}
									fetchMultiple={(ids, targetCollectionName) => {
										fetchMultipleAssetTypes(ids, targetCollectionName);
									}}
									fetchData={(
										searchText,
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									) => {
										fetchAssetTypes(
											{ name: searchText },
											targetCollectionName,
											pagination,
											sorting,
											filters,
											addToTargetCollection
										);
									}}
									renderRecord={(d) => (
										<Select.Option value={d.id} key={d.id}>
											<div>{d.name}</div>
										</Select.Option>
									)}
									sortBy={{ sort_by: 'name', order: 'ascend' }}
								/>
							)}
						</Form.Item>
						<Form.Item label="Locations">
							{getFieldDecorator('locationIds', {
								initialValue: formData.locationIds,
								rules: [
									{
										required: getFieldValue('basedOn') === 'assetType',
										message: 'This field is required.',
									},
								],
							})(
								<BuyerRegionsLocationsTreeSelect
									mode="multiple"
									disabled={isUpdate || getFieldValue('basedOn') !== 'assetType'}
									emptyWhenDisabled={false}
									filtersSlice={locations}
									filtersValueAccessor={() => form.getFieldValue('locationIds')}
									targetCollectionName={PLANNED_MAINTENANCE_SCHEDULE_FORM_LOCATIONS_AUTOCOMPLETE}
									renderRecord={(d) => (
										<Select.Option value={d.id} key={d.id}>
											<div>{d.name}</div>
										</Select.Option>
									)}
									sortBy={{ sort_by: 'name', order: 'ascend' }}
								/>
							)}
						</Form.Item>
					</div>
				) : null}
				<Form.Item
					label="Assign To"
					help={
						!isSameSupplier
							? 'Schedule values vary, must edit on individual schedule.'
							: 'If left empty, rank 1 supplier will get picked based on problem type and location.'
					}
				>
					{getFieldDecorator('supplierFacilityId', {
						initialValue: isSameSupplier ? formData.supplierFacilityId : undefined,
					})(
						<OWAsyncSelect
							allowClear={true}
							disabled={!isSameSupplier}
							stateSlice={supplierFacilities}
							targetCollectionName={PLANNED_MAINTENANCE_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleSupplierFacilitiesInPrivateNetwork(ids, targetCollectionName);
							}}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchSupplierFacilitiesInPrivateNetwork(
									{ name: searchText, offset: 0, limit: 100 },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							renderRecord={(supplierFacility) => (
								<Select.Option key={supplierFacility.id} value={supplierFacility.id}>
									{supplierFacility.name}
								</Select.Option>
							)}
							additionalFilters={{
								problemTypeId: this.state.problemTypeId,
							}}
							sortBy={{ sort_by: 'name', order: 'ascend' }}
						/>
					)}
				</Form.Item>
				<Form.Item
					label="Not To Exceed Amount"
					validateStatus={approvalHeirarchy.status}
					help={
						approvalHeirarchy.status
							? `This NTE change bypasses the requirement of a quote. Based on approval hierarchy, you can set NTE upto $${approvalHeirarchy.amount}`
							: !isSameNTE
							? 'Schedule values vary, must edit on individual schedule.'
							: null
					}
				>
					{getFieldDecorator('nte', {
						initialValue: isSameNTE ? formData.nte || this.state.nte : undefined,
						validateTrigger: 'onChange',
						rules: [
							{
								validator: (rule, value, callback) => {
									if (
										approvalHeirarchy.amount &&
										parseInt(value) > parseInt(approvalHeirarchy.amount) &&
										parseInt(value) !== formData.nte
									) {
										this.setState({
											approvalHeirarchy: {
												...approvalHeirarchy,
												status: isAdmin ? 'warning' : 'error',
											},
										});
										callback(isAdmin ? undefined : '');
									} else {
										this.setState({
											approvalHeirarchy: {
												...approvalHeirarchy,
												status: null,
											},
										});
										callback();
									}
								},
							},
						],
					})(
						<Input
							onChange={this.handleNTEValueChange}
							onClick={(e) => e.stopPropagation()}
							disabled={!isSameNTE}
						/>
					)}
				</Form.Item>
				{getFieldValue('basedOn') === 'meter' ? (
					<Form.Item label="Meters">
						{getFieldDecorator('meterIds', {
							initialValue: formData.meterIds,
							rules: [
								{
									required: getFieldValue('basedOn') === 'meter',
									message: 'This field is required.',
								},
							],
						})(
							<OWAsyncSelect
								mode="multiple"
								disabled={getFieldValue('basedOn') !== 'meter'}
								emptyWhenDisabled={true}
								stateSlice={assets}
								targetCollectionName={PLANNED_MAINTENANCE_SCHEDULE_FORM_ASSETS_AUTOCOMPLETE}
								fetchMultiple={(ids, targetCollectionName) => {
									fetchMultipleAssets(ids, targetCollectionName);
								}}
								fetchData={(
									searchText,
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								) => {
									fetchAssets(
										{ name: searchText },
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									);
								}}
								renderRecord={(d) => (
									<Select.Option value={d.id} key={d.id}>
										<div>
											{nullSafeGetOrElse('location.name', d, '')} - {d.name}
										</div>
									</Select.Option>
								)}
								sortBy={{ sort_by: 'name', order: 'ascend' }}
							/>
						)}
					</Form.Item>
				) : null}
				<Form.Item
					label={'Scheduling Configuration'}
					help={isSameCreateConfig ? null : 'Values vary, must edit on individual schedule.'}
				>
					{getFieldDecorator('createConfig', {
						initialValue: isSameCreateConfig ? createConfig : isUpdate ? undefined : 'create',
					})(
						<Select disabled={!isSameCreateConfig}>
							<Option value={'create'}>Always schedule new work order.</Option>
							<Option value={'cancel_existing_and_create'}>
								Cancel existing and schedule a new work order.
							</Option>
							<Option value={'create_after_prior_completion'}>
								Wait until work order is completed.
							</Option>
							<Option value={'dont_create'}>Skip until work order is completed.</Option>
						</Select>
					)}
				</Form.Item>
				<Form.Item
					label="Contract ID"
					help={
						!isSameContractId ? 'Schedule values vary, must edit on individual schedule.' : null
					}
				>
					{getFieldDecorator('supplierContractId', {
						initialValue: isSameContractId ? formData.supplierContractId : undefined,
					})(<Input disabled={!isSameContractId} />)}
				</Form.Item>
				<Form.Item
					label="Contract Documents"
					help={
						!isSameContractDocuments
							? 'Schedule values vary, must edit on individual schedule.'
							: null
					}
				>
					{getFieldDecorator(`supplierContractDocuments`)(
						isSameContractDocuments ? (
							<FileUploader
								roleType={userType}
								defaultFileList={supplierContractDocuments}
								handleUploadSuccess={this.handleFileManualUpload}
								handleFileListChange={this.handleFileListChange}
								accept="application/pdf"
								uploaderType={'dragger'}
							/>
						) : (
							<Input hidden />
						)
					)}
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						loading={isLoading}
						className="contactForm__button"
					>
						{submitText}
					</Button>
					<span style={{ marginLeft: '16px' }}>
						<BackButton buttonText="Cancel" />
					</span>
				</Form.Item>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	formData: ownProps.formData,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,

	locations: state.locations,
	assets: state.assets,
	assetTypes: state.asset_types,
	plannedMaintenanceTemplates: state.planned_maintenance_templates,
	createErrors: state.planned_maintenance_schedules.createErrors,
	updateErrors: state.planned_maintenance_schedules.updateErrors,
	creating: state.planned_maintenance_schedules.creating,
	updating: state.planned_maintenance_schedules.updating,
	currentUser: state.session.currentUser,
	supplierFacilities: state.supplier_facilities,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	create: (entity) => dispatch(bulkCreatePlannedMaintenanceSchedules(ownProps.userType)(entity)),
	update: (entity) => dispatch(bulkUpdatePlannedMaintenanceSchedules(ownProps.userType)(entity)),
	deleteEntity: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? plannedMaintenanceScheduleDeleteForSupplier(entity)
				: plannedMaintenanceScheduleDeleteForBuyer(entity)
		),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: locationsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: locationsRestCrudThunksForBuyer.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultipleAssets: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: assetsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchAssets: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetsRestCrudThunksForSupplier.readLite(
						{ ...params },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: assetsRestCrudThunksForBuyer.readLite(
						{ ...params },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultipleAssetTypes: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: assetTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchAssetTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetTypesRestCrudThunksForSupplier.readLite(
						{ ...params, no_pagination: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: assetTypesRestCrudThunksForBuyer.readLite(
						{ ...params, no_pagination: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultiplePlannedMaintenanceTemplates: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? plannedMaintenanceTemplatesRestCrudThunksForSupplier.readMultiple(
						ids,
						targetCollectionName
				  )
				: plannedMaintenanceTemplatesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchPlannedMaintenanceTemplates: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? plannedMaintenanceTemplatesRestCrudThunksForSupplier.readLite(
						{ ...params, no_pagination: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: plannedMaintenanceTemplatesRestCrudThunksForBuyer.readLite(
						{ ...params, no_pagination: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchSupplierFacilitiesInPrivateNetwork: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? fetchSupplierFacilitiesInPrivateNetworkForSupplier(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters
				  )
				: fetchSupplierFacilitiesInPrivateNetworkForBuyer(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters
				  )
		),
	fetchMultipleSupplierFacilitiesInPrivateNetwork: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? supplierFacilitiesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: supplierFacilitiesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchApprovalHeirarchy: (id) => dispatch(getBuyerCompanyApprovalTiers(ownProps.userType, null)()),
	getPlannedMaintenanceTitle: (name) =>
		dispatch(getPlannedMaintenanceName(ownProps.userType)(name)),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(PlannedMaintenanceScheduleForm)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(Form.create()(ComponentWithoutUserType));

import * as React from 'react';
import { Card, Col, Row } from 'antd';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import BudgetHeaderDisplayCard from '../budget_header_display_card/BudgetHeaderDisplayCard';

const BudgetHeaderDisplayRow = ({ budgetPerLocations, currency, sharedBudget }) => {
	const budget = {
		budget: sharedBudget || 0,
		budgetAccrued: 0,
		budgetConsumed: 0,
		budgetSpent: 0,
	};
	budgetPerLocations.map((item) => {
		if (!sharedBudget) {
			budget['budget'] = budget['budget'] + parseFloat(item['budget']);
		}
		budget['budgetAccrued'] = budget['budgetAccrued'] + parseFloat(item['budgetAccrued']);
		budget['budgetConsumed'] = budget['budgetConsumed'] + parseFloat(item['budgetConsumed']);
		budget['budgetSpent'] = budget['budgetSpent'] + parseFloat(item['budgetSpent']);
	});
	const remaining = parseFloat(budget.budget) - parseFloat(budget.budgetConsumed);
	const budgetLimit = currency.format(budget.budget);
	const remainingBudget = currency.format(remaining);
	const overBudget = currency.format(remaining * -1);
	const actualSpending = currency.format(budget.budgetSpent);
	const accruals = currency.format(budget.budgetAccrued);

	return (
		<Row gutter={4}>
			<BudgetHeaderDisplayCard
				title={'Budget Total'}
				message={'The budget amount for the current period.'}
				amount={budgetLimit}
				span={6}
			/>
			<BudgetHeaderDisplayCard
				title={'Spent'}
				message={
					'The accumulated total of approved, processing and paid invoices for the selected period.'
				}
				amount={actualSpending}
				span={6}
			/>
			<BudgetHeaderDisplayCard
				title={'Accrued'}
				message={'The total from the selected accrual types tracked for the selected period.'}
				amount={accruals}
				span={6}
			/>
			{remaining > 0 ? (
				<BudgetHeaderDisplayCard
					title={'Remaining'}
					message={'The total budget minus the accrued and spent amounts for the selected period.'}
					amount={remainingBudget}
					span={6}
					color={'green'}
				/>
			) : (
				<BudgetHeaderDisplayCard
					title={'Over Budget'}
					message={'The amount over the budget total.'}
					amount={overBudget}
					span={6}
					color={'red'}
				/>
			)}
		</Row>
	);
	return null;
};

const mapStateToProps = (state, ownProps) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BudgetHeaderDisplayRow));

import * as React from 'react';
import { List, Button } from 'antd';

export default class ClientShowMoreList extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = { numVisibleRecords: props.initialNumVisibleRecords };
	}

	handleShowMore = () => {
		const { initialNumVisibleRecords, dataSource, showAll } = this.props;
		const { numVisibleRecords } = this.state;
		if (showAll) {
			this.setState({ numVisibleRecords: dataSource.length });
		} else {
			this.setState({ numVisibleRecords: numVisibleRecords + initialNumVisibleRecords });
		}
	};

	handleShowLess = () => {
		const { initialNumVisibleRecords } = this.props;
		const { numVisibleRecords } = this.state;
		this.setState({ numVisibleRecords: initialNumVisibleRecords });
	};

	render() {
		const { initialNumVisibleRecords, dataSource, renderItem, showAll, ...otherProps } = this.props;
		const { numVisibleRecords } = this.state;
		const visibleRecords = dataSource.slice(0, numVisibleRecords);
		const loadMoreText = showAll ? 'Show all' : 'Show more';
		const loadMoreButton =
			dataSource.length > 0 ? (
				numVisibleRecords < dataSource.length ? (
					<div
						style={{ paddingTop: 12, paddingBottom: 12, display: 'flex', justifyContent: 'center' }}
					>
						<Button onClick={this.handleShowMore}>{loadMoreText}</Button>
					</div>
				) : numVisibleRecords === initialNumVisibleRecords ? null : (
					<div
						style={{ paddingTop: 12, paddingBottom: 12, display: 'flex', justifyContent: 'center' }}
					>
						<Button onClick={this.handleShowLess}>Show less</Button>
					</div>
				)
			) : null;
		return (
			<List
				{...otherProps}
				loadMore={loadMoreButton}
				renderItem={renderItem}
				dataSource={visibleRecords}
			/>
		);
	}
}

import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

require('./SpendAnalyticsPage.less');

class SpendAnalyticsPage extends React.Component<any, null> {
	render() {
		const { userType } = this.props;
		return (
			<AccessPermissionChecker name={PERMISSION_NAMES.ACCESS_ANALYTICS} isPage>
				<div>
					<Route
						path={`/${userType}/analytics/spend`}
						exact={true}
						render={(props) => (
							<Redirect
								to={{
									pathname: `/${userType}/analytics/spend/overview`,
								}}
							/>
						)}
					/>
					{this.props.childRoutes.map((r, i) => (
						<RouteWithSubRoutes key={i} {...r} />
					))}
				</div>
			</AccessPermissionChecker>
		);
	}
}
const ComponentWithoutUserType = withRouter(
	connect(
		() => ({}),
		() => ({})
	)(SpendAnalyticsPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

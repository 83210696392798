import React, { FC } from 'react';

const StockedPartTag: FC<any> = ({ isStocked }): React.ReactElement => (
	<span
		style={{
			color: '#555',
			background: 'rgba(0, 0, 0, 0.1)',
			padding: '4px 12px',
			borderRadius: 16,
			whiteSpace: 'nowrap',
		}}
	>
		{isStocked ? 'Stocked' : 'Not Stocked'}
	</span>
);

export default StockedPartTag;

import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
	resolutionRestCrudThunksForBuyer,
	resolutionTypesRestCrudThunksForBuyer,
} from '../../thunks/resolution_types_thunks';
import { Button, Card, Col, Form, Input, Layout, message, Modal, Radio, Row, Table } from 'antd';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { getObjectValues, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { EmptyState } from '../empty_state/EmptyState';
import { ContactAvatar } from '../contact_avatar/ContactAvatar';
import SubnavBar from '../subnav_bar/SubnavBar';
import { MinusCircleOutlined } from '@ant-design/icons';
const { Content } = Layout;

const style = require('./ResolutionTypeRowDisplay.less');

function ResolutionTypeConfigPage({
	resolutionTypes,

	fetchResolutionTypes,
	createResolutionType,
	updateResolutionType,
	deleteResolutionType,
}) {
	const [form] = Form.useForm();
	const [modalVisible, setModalVisible] = useState(false);
	const [modalButtonLoading, setModalButtonLoading] = useState(false);
	const [currentRecord, setCurrentRecord] = useState({});
	useMemo(() => fetchResolutionTypes(), []);

	const columns = [
		{
			title: 'Code',
			dataIndex: ['code'],
			render: (text, record) => <div>{record.resolutionCode}</div>,
		},
		{
			title: 'Type',
			dataIndex: ['type'],
			render: (text, record) => <div>{record.resolutionType}</div>,
		},
		{
			title: '',
			dataIndex: [''],
			render: (text, record) => (
				<Row style={{ display: 'flex', gap: 8, width: '100%', justifyContent: 'start' }}>
					<Button
						onClick={() => {
							setModalVisible(true);
							setCurrentRecord(record);
							form.setFieldsValue({
								resolutionCode: record.resolutionCode,
								resolutionType: record.resolutionType,
							});
						}}
					>
						Edit
					</Button>
					<MinusCircleOutlined
						translate=""
						className="dynamic-delete-button"
						onClick={() => {
							message.open({
								key: 'MessageUpdating',
								type: 'loading',
								content: 'Updating...',
								duration: 60,
							});
							deleteResolutionType(record)
								.then(() => {
									message.open({
										key: 'MessageUpdating',
										type: 'success',
										content: 'Complete!',
										duration: 2,
									});
								})
								.catch(() => {
									message.open({
										key: 'MessageUpdating',
										type: 'error',
										content: 'Error!',
										duration: 2,
									});
								});
						}}
					/>
				</Row>
			),
		},
	];

	const records = getObjectValues(resolutionTypes.records);

	const onSubmit = (values) => {
		const submit = currentRecord ? updateResolutionType : createResolutionType;
		setModalButtonLoading(true);
		submit({
			id: nullSafeGetOrElse('id', currentRecord, undefined),
			resolutionCode: values.resolutionCode,
			resolutionType: values.resolutionType,
		}).then(() => {
			setModalButtonLoading(true);
			setModalVisible(false);
		});
	};

	return (
		<Content className="adminUsersPage" style={{ padding: '0 0.5em' }}>
			<Modal
				title={currentRecord ? 'Edit Resolution Type' : 'Create Resolution Type'}
				visible={modalVisible}
				preserve={false}
				destroyOnClose={true}
				onCancel={() => {
					setModalVisible(false);
					setCurrentRecord(null);
				}}
				onOk={form.submit}
				okText={currentRecord ? 'Edit' : 'Create'}
				onOkProps={{ loading: modalButtonLoading }}
			>
				<Form form={form} onFinish={onSubmit}>
					<Form.Item label={'Code'} name={'resolutionCode'}>
						<Input maxLength={50} showCount={true} />
					</Form.Item>
					<Form.Item label={'Type'} name={'resolutionType'} rules={[{ required: true }]}>
						<Input maxLength={100} showCount={true} />
					</Form.Item>
				</Form>
			</Modal>
			<Row style={{ margin: '0.5em -8px' }} gutter={16}>
				<Col span={24}>
					<SubnavBar
						left={null}
						right={
							<div>
								<Button
									style={{ marginLeft: 16 }}
									type="primary"
									size="large"
									onClick={() => {
										setModalVisible(true);
										setCurrentRecord(undefined);
										form.setFieldsValue({
											resolutionCode: undefined,
											resolutionType: undefined,
										});
									}}
								>
									Add Resolution Type
								</Button>
							</div>
						}
					/>
				</Col>
			</Row>
			<Card>
				<Table
					columns={columns}
					dataSource={records}
					emptyState={
						<EmptyState
							height={120}
							headline={null}
							body={
								<div style={{ textAlign: 'center' }}>
									<div style={{ maxWidth: 488, marginBottom: 16 }}>
										You don't have any resolution types yet!
									</div>
									<Button
										onClick={() => {
											setModalVisible(true);
											form.setFieldsValue({
												resolutionCode: undefined,
												resolutionType: undefined,
											});
										}}
									>
										Create Resolution Type
									</Button>
								</div>
							}
						/>
					}
				/>
			</Card>
		</Content>
	);
}

const mapStateToProps = (state, ownProps) => ({
	resolutionTypes: state.resolution_types,
});
const mapDispatchToProps = (dispatch) => ({
	fetchResolutionTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			resolutionTypesRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	createResolutionType: (entity) => dispatch(resolutionTypesRestCrudThunksForBuyer.create(entity)),
	updateResolutionType: (entity) => dispatch(resolutionTypesRestCrudThunksForBuyer.update(entity)),
	deleteResolutionType: (entity) => dispatch(resolutionTypesRestCrudThunksForBuyer.delete(entity)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResolutionTypeConfigPage));

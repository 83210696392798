import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

class SuppliersPage extends React.Component<any, null> {
	render() {
		const { userType } = this.props;
		return (
			<div>
				<Route
					path={`/${userType}/suppliers`}
					exact={true}
					render={(props) => (
						<Redirect
							to={{
								pathname: `/${userType}/suppliers/overview`,
							}}
						/>
					)}
				/>
				{this.props.childRoutes.map((r, i) => (
					<RouteWithSubRoutes key={i} {...r} />
				))}
			</div>
		);
	}
}
const ComponentWithoutUserType = withRouter(
	connect(
		() => ({}),
		() => ({})
	)(SuppliersPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import React, { FC, useMemo } from 'react';
import { filterKeys, parseFilterValueToAdvancedFilters } from '../../utils/DataAccessUtils';
import {
	SORTING_PROPS,
	VIEW_ENTITY_TYPES,
	FETCH_DATA_PREFIX_FOR_TARGET_COLLECTION,
} from '../../utils/DataConstants';
import InvoicesTable from '../InvoicesTable';
import { DashboardInvoicesEmptyState } from '../InvoicesTable/empyState/InvoicesTableEmptyState';
import { ShowAllFooter } from '../InvoicesTable/TableFooter';
import WorkOrderTable from '../WorkOrderTable';
import { DashboardWOEmptyState } from '../WorkOrderTable/emptyState/WorkOrderTableEmptyState';
import { ShowAllFooterWorkOrders } from '../WorkOrderTable/TableFooter';
import WidgetHeader from './WidgetHeader';
import QuotesTable from '../QuotesTable';
import { DashboardQuotesEmptyState } from '../QuotesTable/empyState/QuotesTableEmptyState';

interface EachWidgetProps {
	id: string;
	savedView: any;
}

const EachWidget: FC<EachWidgetProps> = ({ id, savedView }): React.ReactElement => {
	const sorters = useMemo(
		() => filterKeys(parseFilterValueToAdvancedFilters(savedView.filterValue), SORTING_PROPS, true),
		[savedView]
	);

	const filters = useMemo(
		() =>
			filterKeys(parseFilterValueToAdvancedFilters(savedView.filterValue), SORTING_PROPS, false),
		[savedView]
	);

	return (
		<>
			{savedView.entityType === VIEW_ENTITY_TYPES.WORKORDERS && (
				<WorkOrderTable
					CustomHeader={(props) => (
						<WidgetHeader title={savedView.name} entityName="work order" {...props} />
					)}
					preAppliedFilters={filters}
					tableStyle={{
						display: 'flex',
						flexDirection: 'column',
						cursor: 'pointer',
						userSelect: 'none',
						padding: '8px 8px',
						marginTop: '-10px',
						marginBottom: '-9px',
					}}
					initialPagination={{ current: 1, pageSize: 3 }}
					initialSorting={sorters}
					targetCollectionName={`${FETCH_DATA_PREFIX_FOR_TARGET_COLLECTION}${id}`}
					CustomFooter={ShowAllFooterWorkOrders}
					renderEmptyState={DashboardWOEmptyState}
					backText="Back to dashboard"
					openMoreInDefault
					ignoreRefreshCounts
				/>
			)}
			{savedView.entityType === VIEW_ENTITY_TYPES.INVOICES && (
				<InvoicesTable
					CustomHeader={(props) => (
						<WidgetHeader title={savedView.name} entityName="invoice" {...props} />
					)}
					preAppliedFilters={filters}
					tableStyle={{
						display: 'flex',
						flexDirection: 'column',
						cursor: 'pointer',
						userSelect: 'none',
						padding: '8px 8px',
						marginTop: '-15px',
						marginBottom: '-11px',
					}}
					targetCollectionName={`invoice_widget_${id}`}
					initialPagination={{ current: 1, pageSize: 3 }}
					initialSorting={sorters}
					CustomFooter={ShowAllFooter}
					renderEmptyState={DashboardInvoicesEmptyState}
					backText="Back to dashboard"
					openMoreInDefault
					ignoreRefreshCounts
				/>
			)}
			{savedView.entityType === VIEW_ENTITY_TYPES.QUOTES && (
				<QuotesTable
					CustomHeader={(props) => (
						<WidgetHeader title={savedView.name} entityName="quote" {...props} />
					)}
					preAppliedFilters={filters}
					tableStyle={{
						display: 'flex',
						flexDirection: 'column',
						cursor: 'pointer',
						userSelect: 'none',
						padding: '8px 8px',
						marginTop: '-15px',
						marginBottom: '-11px',
					}}
					targetCollectionName={`quote_widget_${id}`}
					initialPagination={{ current: 1, pageSize: 3 }}
					initialSorting={sorters}
					CustomFooter={ShowAllFooter}
					renderEmptyState={DashboardQuotesEmptyState}
					backText="Back to dashboard"
					openMoreInDefault
					ignoreRefreshCounts
				/>
			)}
		</>
	);
};

export default EachWidget;

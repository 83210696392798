import React from 'react';
import { Button, Dropdown, Menu, Popconfirm, Spin, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	cancelPurchaseOrderForSupplier,
	closePurchaseOrderForSupplier,
	generatePurchaseOrderPDFForSupplier,
	purchaseOrdersRestCrudThunksForSupplier,
	sendPurchaseOrderByEmailForSupplier,
} from '../../thunks/purchase_orders_thunks';
import { Link } from 'react-router-dom';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import {
	CLOSABLE_PO_STATUSES,
	EDITABLE_PO_STATUSES,
	PURCHASE_ORDER_STATUSES,
	PURCHASE_ORDER_STATUS_DISPLAY_NAMES,
} from '../../constants/PurchaseOrderStatuses';
import SupplierSendPurchaseOrderByEmailForm from '../supplier_send_purchase_order_by_email_form/SupplierSendPurchaseOrderByEmailForm';
import { getBackLinkStates } from '../../utils/HistoryUtils';
import { getCurrency, getEntityById, nullSafeGet } from '../../utils/DataAccessUtils';
import { DownOutlined } from '@ant-design/icons';
import { getPurchaseOrderTotalCost } from '../../utils/InventoryUtils';
import NextPreviousButton from '../next_previous_button/NextPreviousButton';
import { PURCHASE_ORDERS_CRUD_ACTION_CREATORS } from '../../actions/purchase_orders_actions';
import { updatePaginationEntities } from '../../thunks/thunks_wrapper';


require('./PurchaseOrdersDetailPage.less');

const DEFAULT_BACK_LINK_TEXT = 'Back to purchase orders';

class PurchaseOrdersDetailPage extends React.Component<any, any> {
	state = {
		lightboxIsOpen: false,
		currentIndex: 0,
		sendPurchaseOrderByEmailModalVisible: false,
		backLinkText: DEFAULT_BACK_LINK_TEXT,
		backLinkTo: '/supplier/purchaseOrders',
		emailingPO: false,
		pdfDownloading: false,
		cancelling: false,
		closing: false,
		fromTargetCollectionName: null,
	};

	formRefs = {
		sendPurchaseOrderByEmailForm: null,
	};

	componentDidMount() {
		const { getPurchaseOrder, location, match, purchaseOrder } = this.props;
		const purchaseOrderId = match.params.id;
		if (!nullSafeGet('id', purchaseOrder)) {
			getPurchaseOrder(purchaseOrderId);
		}
		this.setState(getBackLinkStates(location, DEFAULT_BACK_LINK_TEXT));
	}

	refreshPurchaseOrder = () => {
		const { getPurchaseOrder, match } = this.props;
		const purchaseOrderId = match.params.id;
		getPurchaseOrder(purchaseOrderId);
	};

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	handleSendByEmailSubmit = (values) => {
		const { sendPurchaseOrderByEmail } = this.props;
		const allEmails = values.emails.join(',');
		this.setState({ emailingPO: true });
		sendPurchaseOrderByEmail(values.purchaseOrderId, allEmails)
			.then(() => {
				this.setState({
					sendPurchaseOrderByEmailModalVisible: false,
				});
				message.success(`Purchase order emailed successfully.`);
				this.refreshPurchaseOrder();
			})
			.finally(() => this.setState({ emailingPO: false }));
	};

	handleSendByEmailCancel = (e) => {
		this.setState({
			sendPurchaseOrderByEmailModalVisible: false,
		});
	};

	handleSendByEmail = () => {
		this.setState({
			sendPurchaseOrderByEmailModalVisible: true,
		});
	};

	goToPrevious = () => {
		this.setState({ currentIndex: this.state.currentIndex - 1 });
	};

	goToNext = () => {
		this.setState({ currentIndex: this.state.currentIndex + 1 });
	};

	handleTabChange = (key) => {
		const { history, match } = this.props;
		const purchaseOrderId = match.params.id;
		history.push(`/supplier/purchaseOrders/detail/${purchaseOrderId}/${key}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	handleCancel = () => {
		const { cancelPurchaseOrder, purchaseOrder } = this.props;
		this.setState({ cancelling: true });
		cancelPurchaseOrder(purchaseOrder)
			.then(() => {
				this.refreshPurchaseOrder();
			})
			.finally(() => this.setState({ cancelling: false }));
	};

	handleClose = () => {
		const { closePurchaseOrder, purchaseOrder } = this.props;
		this.setState({ closing: true });
		closePurchaseOrder(purchaseOrder)
			.then(() => {
				this.refreshPurchaseOrder();
			})
			.finally(() => this.setState({ closing: false }));
	};

	handleDelete = () => {
		const { history, deletePurchaseOrder, purchaseOrder } = this.props;
		deletePurchaseOrder(purchaseOrder).then(() => {
			history.push('/supplier/purchaseOrders/overview/all');
		});
	};

	onDownload = () => {
		const { match, purchaseOrder } = this.props;
		const purchaseOrderId = match.params.id;
		const pdfFile = generatePurchaseOrderPDFForSupplier(purchaseOrderId);
		const poNumber =
			nullSafeGet('purchaseOrderUniqueIdWithPrefix', purchaseOrder) ||
			nullSafeGet('id', purchaseOrder) ||
			purchaseOrderId;

		this.setState({ pdfDownloading: true });

		fetch(pdfFile.url, {
			headers: pdfFile.httpHeaders,
		})
			.then((response) => {
				response.blob().then((blob) => {
					// Creating new object of PDF file
					const fileURL = window.URL.createObjectURL(blob);

					// Setting various property values
					let alink = document.createElement('a');
					alink.href = fileURL;
					alink.download = `PO_#${poNumber}.pdf`;
					alink.click();
				});
			})
			.finally(() => this.setState({ pdfDownloading: false }));
	};

	render() {
		const {
			purchaseOrder,
			purchaseOrdersFetching,
			match,
			companyConfig,
			purchaseOrders,
			location,
			history,
			updatePagination,
			cloneTargetCollection,
		} = this.props;
		const currency = getCurrency({
			currencyId: nullSafeGet('currencyId', purchaseOrder),
			supplierFacility: nullSafeGet('supplierFacility', purchaseOrder),
		});
		const fetching = purchaseOrdersFetching && !nullSafeGet('id', purchaseOrder);
		const purchaseOrderId = match.params.id;
		const equipmentLineItemAvailable = nullSafeGet(
			'equipmentSupplierPurchaseOrderLineItems.0',
			purchaseOrder
		);
		const equipmentWithSLAvailable =
			nullSafeGet('stockLocationId', purchaseOrder) && equipmentLineItemAvailable;
		const equipmentWithLocationAvailable =
			nullSafeGet('locationId', purchaseOrder) && equipmentLineItemAvailable;

		const canEditOrCancel = EDITABLE_PO_STATUSES.includes(nullSafeGet('status', purchaseOrder));

		const canClose = CLOSABLE_PO_STATUSES.includes(nullSafeGet('status', purchaseOrder));

		const isCancelled = nullSafeGet('status', purchaseOrder) === PURCHASE_ORDER_STATUSES.cancelled;

		const sendOrderMenu = () => (
			<Menu>
				<Menu.Item onClick={this.onDownload}>Download</Menu.Item>
				{canEditOrCancel ? <Menu.Item onClick={this.handleSendByEmail}>Send</Menu.Item> : null}
			</Menu>
		);

		const tabs = [
			{
				key: 'details',
				name: 'Overview',
			},
			{
				key: 'receipts',
				name: 'Receipts',
			},
			{
				key: 'purchaseRequests',
				name: 'Purchase Requests',
			},
			...(equipmentWithSLAvailable
				? [
						{
							key: 'receivedEquipments',
							name: 'Received Equipment',
						},
				  ]
				: []),
			...(equipmentWithLocationAvailable
				? [
						{
							key: 'receivedAssets',
							name: 'Received Assets',
						},
				  ]
				: []),
		];

		return (
			<div className="purchaseOrdersDetailPage">
				{this.state.sendPurchaseOrderByEmailModalVisible && (
					<SupplierSendPurchaseOrderByEmailForm
						wrappedComponentRef={this.saveFormRef('sendPurchaseOrderByEmailForm')}
						onCancel={this.handleSendByEmailCancel}
						onSubmit={this.handleSendByEmailSubmit}
						loading={this.state.emailingPO}
						purchaseOrderId={purchaseOrderId}
					/>
				)}

				<DetailPageHeader
					exactPath={'/supplier/purchaseOrders/detail/:id'}
					redirectPath={`/supplier/purchaseOrders/detail/${purchaseOrderId}/details`}
					backLinkText={this.state.backLinkText}
					backLinkTo={this.state.backLinkTo}
					title={
						fetching
							? null
							: 'Purchase Order #' +
							  (purchaseOrder.purchaseOrderUniqueIdWithPrefix || purchaseOrder.id)
					}
					subtitle={
						<div className="purchaseOrdersDetailPage__subtitleRow">
							<div className="purchaseOrdersDetailPage__subtitle">
								{PURCHASE_ORDER_STATUS_DISPLAY_NAMES[purchaseOrder.status]}
							</div>
							<div className="purchaseOrdersDetailPage__subtitle">
								{currency.format(getPurchaseOrderTotalCost(purchaseOrder))}
							</div>
						</div>
					}
					actions={
						isCancelled
							? [
									<Popconfirm
										onConfirm={this.handleDelete}
										placement="topLeft"
										title="Are you sure you want to cancel?"
										okText="Yes"
										cancelText="No"
									>
										<Button key={2} style={{ marginRight: 16 }} ghost={true}>
											Delete
										</Button>
									</Popconfirm>,
							  ]
							: [
									...(canEditOrCancel
										? [
												<Link
													to={`/supplier/purchaseOrders/edit/${purchaseOrderId}`}
													key={1}
													style={{ marginRight: 16 }}
												>
													<Button disabled={!canEditOrCancel} ghost={true}>
														Edit
													</Button>
												</Link>,
										  ]
										: []),
									...(canEditOrCancel
										? [
												<Popconfirm
													onConfirm={this.handleCancel}
													placement="topLeft"
													title="Are you sure you want to cancel?"
													okText="Yes"
													cancelText="No"
												>
													<Button
														disabled={!canEditOrCancel}
														key={2}
														style={{ marginRight: 16 }}
														ghost={true}
														loading={this.state.cancelling}
													>
														Cancel
													</Button>
												</Popconfirm>,
										  ]
										: []),
									...(canClose
										? [
												<Popconfirm
													onConfirm={this.handleClose}
													placement="topLeft"
													title="Are you sure you want to close?"
													okText="Yes"
													cancelText="No"
												>
													<Button
														key={2}
														style={{ marginRight: 16 }}
														ghost={true}
														loading={this.state.closing}
													>
														Close
													</Button>
												</Popconfirm>,
										  ]
										: []),
									<Dropdown overlay={sendOrderMenu}>
										<Button key={1} style={{ marginRight: 16 }} ghost={true}>
											<div className="flex flex-row items-center">
												<span>PDF</span>
												<div className="ml-2">
													<DownOutlined translate="" />
												</div>
												{this.state.pdfDownloading && (
													<div className="ml-2">
														<Spin />
													</div>
												)}
											</div>
										</Button>
									</Dropdown>,
							  ]
					}
					tabs={tabs}
					backgroundColor={nullSafeGet('detail.config.theme.navblock', companyConfig)}
					handleTabChange={this.handleTabChange}
					checkTabActive={this.checkTabActive}
					childRoutes={this.props.childRoutes}
					tabsRightActions={() => (
						<NextPreviousButton
							key={this.state.fromTargetCollectionName}
							location={location}
							history={history}
							activeId={nullSafeGet('id', purchaseOrder)}
							getEnitityUrl={(id) => `/supplier/purchaseOrders/detail/${id}/details`}
							stateSlice={purchaseOrders}
							targetCollectionName={this.state.fromTargetCollectionName}
							updatePagination={updatePagination}
							cloneTargetCollection={cloneTargetCollection}
							entityName="purchase order"
						/>
					)}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	purchaseOrdersFetching: state.purchase_orders.fetching,
	purchaseOrders: state.purchase_orders,
	purchaseOrder: getEntityById(ownProps.match.params.id, state.purchase_orders),
	match: ownProps.match,
	history: ownProps.history,
	location: ownProps.location,
	companyConfig: state.company_config,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	getPurchaseOrder: (id) => dispatch(purchaseOrdersRestCrudThunksForSupplier.readOne(id)),
	deletePurchaseOrder: (entity) =>
		dispatch(purchaseOrdersRestCrudThunksForSupplier.delete(entity, 'id', null, true)),
	cancelPurchaseOrder: (entity) => dispatch(cancelPurchaseOrderForSupplier(entity)),
	closePurchaseOrder: (entity) => dispatch(closePurchaseOrderForSupplier(entity)),
	sendPurchaseOrderByEmail: (id, email, data) =>
		dispatch(sendPurchaseOrderByEmailForSupplier(id, email, data)),
	cloneTargetCollection: (from, to) =>
		dispatch(PURCHASE_ORDERS_CRUD_ACTION_CREATORS.cloneTargetCollection(from, to)),
	updatePagination: (pagination, targetCollectionName) =>
		dispatch(
			updatePaginationEntities(
				'supplier',
				'purchase_orders',
				purchaseOrdersRestCrudThunksForSupplier
			)(false, undefined, targetCollectionName, pagination, true)
		),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PurchaseOrdersDetailPage));

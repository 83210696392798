import { Form } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import * as React from 'react';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { connect } from 'react-redux';
import { Alert, Input, Modal } from 'antd';

const FormItem = Form.Item;

interface PasswordUpdateFormProps extends FormComponentProps {
	onSubmit: any;
	onCancel: any;
	visible: boolean;
	createErrors: any;
	updateErrors: any;
	creating: boolean;
	updating: boolean;
	currentUser: any;
	formData: any;
	loading: boolean;
	errors: string[];
}

class PasswordUpdateForm extends React.Component<PasswordUpdateFormProps, any> {
	constructor(props) {
		super(props);
		const { formData } = this.props;
		let email = `${nullSafeGet('contact.email', formData)}`;
		this.state = {
			email: email,
			password: '',
		};
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.props.onSubmit();
			}
		});
	};

	checkPassword = (rule, value, callback) => {
		const { form } = this.props;
		const otherValue = form.getFieldValue('confirmNewPassword');
		if (value && otherValue && value !== otherValue) {
			callback("The new passwords you entered don't match!");
		} else {
			callback();
		}
	};

	checkConfirm = (rule, value, callback) => {
		const { form } = this.props;
		const { confirmDirty } = this.state;
		const otherValue = form.getFieldValue('newPassword');
		if (value && otherValue && confirmDirty && value !== otherValue) {
			callback("The new passwords you entered don't match!");
		} else {
			callback();
		}
	};
	handleConfirmBlur = (e) => {
		const { form } = this.props;
		const value = e.target.value;
		this.setState({ confirmDirty: this.state.confirmDirty || !!value }, () =>
			form.validateFields(['confirmNewPassword'], { force: true }, () => {})
		);
	};

	render() {
		const { visible, onCancel, onSubmit, form, formData, loading, errors } = this.props;
		const { getFieldDecorator } = form;
		return (
			<Modal
				visible={visible}
				width={600}
				title={'Change User Password'}
				okText={'Update'}
				onCancel={onCancel}
				onOk={this.handleSubmit}
				closable={false}
			>
				<Form layout="vertical" className="passwordUpdateForm">
					{errors.length > 0
						? errors.map((error, idx) => (
								<FormItem key={idx}>
									<Alert message={error} type="error" />
								</FormItem>
						  ))
						: null}
					<FormItem label={'Email'}>
						{getFieldDecorator('email', {
							initialValue: nullSafeGet('contact.email', formData),
						})(<Input style={{ maxWidth: 300 }} disabled={true} />)}
					</FormItem>
					<FormItem label="New Password" hasFeedback={true}>
						{getFieldDecorator('newPassword', {
							rules: [
								{
									required: true,
									message: 'Please input your password!',
								},
								{
									validator: this.checkPassword,
								},
							],
						})(<Input type="password" style={{ maxWidth: 300 }} />)}
					</FormItem>
					<FormItem label="Confirm New Password" hasFeedback={true}>
						{getFieldDecorator('confirmNewPassword', {
							rules: [
								{
									required: true,
									message: 'Please confirm your password!',
								},
								{
									validator: this.checkConfirm,
								},
							],
							validateTrigger: ['onBlur', 'onChange'],
						})(
							<Input
								type="password"
								style={{ maxWidth: 300 }}
								onBlur={this.handleConfirmBlur}
								loading={loading}
							/>
						)}
					</FormItem>
				</Form>
			</Modal>
		);
	}
}
const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,

	loading: state.session.loading,
	errors: state.session.errors,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	createErrors: state.buyer_contacts.createErrors,
	updateErrors: state.buyer_contacts.updateErrors,
	creating: state.buyer_contacts.creating,
	updating: state.buyer_contacts.updating,
	currentUser: state.session.currentUser,
	formData: ownProps.formData,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create<PasswordUpdateFormProps>()(PasswordUpdateForm));

import * as React from 'react';
import SupplierChangePasswordForm from '../supplier_change_password_form/SupplierChangePasswordForm';

import { Layout, Row, Col, BackTop, Card } from 'antd';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;
const style = require('./SupplierChangePasswordPage.less');
const style2 = require('../../styles/blah.less');

class SupplierChangePasswordPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Content className="changePasswordPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited supplier change password page" />
				<BackTop />
				<Row align="middle" style={{ height: '100vh', margin: '0 -8px' }} gutter={16}>
					<Col span={12} offset={6}>
						<Card title="Change password">
							<SupplierChangePasswordForm />
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

export default SupplierChangePasswordPage;

//Pipeline Filters
import { BLACKS_AND_WHITES, STOPLIGHT_COLORS } from '../../../../utils/DataFormatterUtils';

const PENDING_WORK_ORDERS = 'Pending';
const OPEN_WORK_ORDERS = 'Open';
const DEFERRED_WORK_ORDERS = 'Deferred';
const IN_PROGRESS_WORK_ORDERS = 'InProgress';
const ON_HOLD_WORK_ORDERS = 'OnHold';
const COMPLETED_WORK_ORDERS = 'Completed';
const CANCELLED_WORK_ORDERS = 'Cancelled';
const WORK_ORDERS_OVERVIEW_TARGET_COLLECTION = 'workOrdersIndex';
const PENDING_FILTER = { displayStatus: PENDING_WORK_ORDERS };
const OPEN_FILTER = { displayStatus: OPEN_WORK_ORDERS };
const DEFERRED_FILTER = { displayStatus: DEFERRED_WORK_ORDERS };
const IN_PROGRESS_FILTER = { displayStatus: IN_PROGRESS_WORK_ORDERS };
const ON_HOLD_FILTER = { displayStatus: ON_HOLD_WORK_ORDERS };
const COMPLETED_FILTER = { displayStatus: COMPLETED_WORK_ORDERS };
const CANCELLED_FILTER = { displayStatus: CANCELLED_WORK_ORDERS };
const NO_FILTER = { displayStatus: undefined, status: undefined };
const NO_FILTER2 = { status: undefined };

//PipeFilters

// REQUESTED
const SUPPLIER_INITIATED_PENDING_APPROVAL = 'SupplierInitiatedPendingApproval';
const SUPPLIER_INITIATED_PENDING_APPROVAL_FILTER = {
	status: SUPPLIER_INITIATED_PENDING_APPROVAL,
};
const PENDING_APPROVAL = 'PendingApproval';
const PENDING_APPROVAL_FILTER = { status: PENDING_APPROVAL };

// OPEN
const UNASSIGNED = 'Unassigned';
const UNASSIGNED_FILTER = { status: UNASSIGNED };
const ASSIGNED_TO_INTERNAL_TECH = 'AssignedToInternalTech';
const ASSIGNED_TO_INTERNAL_TECH_FILTER = { status: ASSIGNED_TO_INTERNAL_TECH };
const PENDING_CONFIRMATION_BY_SERVICE_PROVIDER = 'PendingConfirmationByServiceProvider';
const PENDING_CONFIRMATION_BY_SERVICE_PROVIDER_FILTER = {
	status: PENDING_CONFIRMATION_BY_SERVICE_PROVIDER,
};

// DEFERRED
const SNOOZED = 'Snoozed';
const SNOOZED_FILTER = { status: SNOOZED };
const DEFERRED_FOR_BUDGET = 'DeferredForBudget';
const DEFERRED_FOR_BUDGET_FILTER = { status: DEFERRED_FOR_BUDGET };

// IN PROGRESS
const CONFIRMED_BY_SERVICE_PROVIDER = 'ConfirmedByServiceProvider';
const CONFIRMED_BY_SERVICE_PROVIDER_FILTER = { status: CONFIRMED_BY_SERVICE_PROVIDER };
const TECH_ASSIGNED = 'TechAssigned';
const TECH_ASSIGNED_FILTER = { status: TECH_ASSIGNED };
const TECH_SCHEDULED = 'TechScheduled';
const TECH_SCHEDULED_FILTER = { status: TECH_SCHEDULED };
const TECH_RESCHEDULED = 'TechRescheduled';
const TECH_RESCHEDULED_FILTER = { status: TECH_RESCHEDULED };
const TECH_EN_ROUTE = 'TechEnRoute';
const TECH_EN_ROUTE_FILTER = { status: TECH_EN_ROUTE };
const TECH_WAITING_ON_SITE = 'TechWaitingOnSite';
const TECH_WAITING_ON_SITE_FILTER = { status: TECH_WAITING_ON_SITE };
const TECH_WORKING_ON_SITE = 'TechWorkingOnSite';
const TECH_WORKING_ON_SITE_FILTER = { status: TECH_WORKING_ON_SITE };
const WORK_INCOMPLETE_WITH_REASON = 'WorkIncompleteWithReason';
const WORK_INCOMPLETE_WITH_REASON_FILTER = { status: WORK_INCOMPLETE_WITH_REASON };
const WORK_UNSATISFACTORY = 'WorkUnsatisfactory';
const WORK_UNSATISFACTORY_FILTER = { status: WORK_UNSATISFACTORY };
const WAITING_FOR_REVIEW = 'WaitingForReview';
const WAITING_FOR_REVIEW_FILTER = { status: WAITING_FOR_REVIEW };
const WAITING_FOR_QUOTE = 'WaitingForQuote';
const WAITING_FOR_QUOTE_FILTER = { status: WAITING_FOR_QUOTE };
const PROPOSAL_APPROVED = 'ProposalApproved';
const PROPOSAL_APPROVED_FILTER = { status: PROPOSAL_APPROVED };

// ON HOLD
const WAITING_FOR_PROPOSAL = 'WaitingForProposal';
const WAITING_FOR_PROPOSAL_FILTER = { status: WAITING_FOR_PROPOSAL };
const WAITING_FOR_PROPOSAL_APPROVAL = 'WaitingForProposalApproval';
const WAITING_FOR_PROPOSAL_APPROVAL_FILTER = { status: WAITING_FOR_PROPOSAL_APPROVAL };
const PARTS_REQUESTED = 'PartsRequested';
const PARTS_REQUESTED_FILTER = { status: PARTS_REQUESTED };
const PARTS_ON_ORDER = 'PartsOnOrder';
const PARTS_ON_ORDER_FILTER = { status: PARTS_ON_ORDER };
const PARTS_RECEIVED = 'PartsReceived';
const PARTS_RECEIVED_FILTER = { status: PARTS_RECEIVED };
const DELAYED_BY_THIRD_PARTY = 'DelayedByThirdParty';
const DELAYED_BY_THIRD_PARTY_FILTER = { status: DELAYED_BY_THIRD_PARTY };

// COMPLETED
const INTERNAL_TECH_WORK_COMPLETE = 'InternalTechWorkComplete';
const INTERNAL_TECH_WORK_COMPLETE_FILTER = { status: INTERNAL_TECH_WORK_COMPLETE };
const INTERNAL_TECH_WORK_REVIEWED_AND_COMPLETED = 'InternalTechWorkReviewedAndCompleted';
const INTERNAL_TECH_WORK_REVIEWED_AND_COMPLETED_FILTER = {
	status: INTERNAL_TECH_WORK_REVIEWED_AND_COMPLETED,
};
const WORK_REVIEWED_AND_COMPLETED = 'WorkReviewedAndCompleted';
const WORK_REVIEWED_AND_COMPLETED_FILTER = { status: WORK_REVIEWED_AND_COMPLETED };
const INVOICED = 'Invoiced';
const INVOICED_FILTER = { status: INVOICED };
const PAYMENT_MADE = 'PaymentMade';
const PAYMENT_MADE_FILTER = { status: PAYMENT_MADE };
const PAYMENT_ACCEPTED = 'PaymentAccepted';
const PAYMENT_ACCEPTED_FILTER = { status: PAYMENT_ACCEPTED };

//Cancelled
const CANCELLED_WITH_REASON = 'CancelledWithReason';
const CANCELLED_WITH_REASON_FILTER = { status: CANCELLED_WITH_REASON };

export const pipelineFiltersBuyer = (showDeferred = false) => [
	{
		displayName: 'Requested',
		filter: PENDING_FILTER,
		name: PENDING_WORK_ORDERS,
		icon: 'icons8-edit',
		iconStyle: { color: '#4a1e87' },
		count: 0,
	},
	...(showDeferred
		? [
				{
					displayName: 'Deferred',
					filter: DEFERRED_FILTER,
					name: DEFERRED_WORK_ORDERS,
					icon: 'icons8-hourglass',
					iconStyle: { color: 'rgb(213, 125, 5)' },
					count: 0,
				},
		  ]
		: []),
	{
		displayName: 'Open',
		filter: OPEN_FILTER,
		name: OPEN_WORK_ORDERS,
		icon: 'icons8-error',
		iconStyle: { color: STOPLIGHT_COLORS.pureRed },
		count: 0,
	},
	{
		displayName: 'In Progress',
		filter: IN_PROGRESS_FILTER,
		name: IN_PROGRESS_WORK_ORDERS,
		icon: 'icons8-wrench',
		iconStyle: { color: STOPLIGHT_COLORS.yellowGreen },
		count: 0,
	},
	{
		displayName: 'On Hold',
		filter: ON_HOLD_FILTER,
		name: ON_HOLD_WORK_ORDERS,
		icon: 'icons8-pause-squared',
		iconStyle: { color: STOPLIGHT_COLORS.pureYellow },
		count: 0,
	},
	{
		displayName: 'Completed',
		filter: COMPLETED_FILTER,
		name: COMPLETED_WORK_ORDERS,
		icon: 'icons8-green-check-mark',
		iconStyle: { color: STOPLIGHT_COLORS.pureGreen },
		count: 0,
	},
	{
		displayName: 'Cancelled',
		filter: CANCELLED_FILTER,
		name: CANCELLED_WORK_ORDERS,
		icon: 'icons8-cancel',
		iconStyle: { color: BLACKS_AND_WHITES.blackTertiary },
		count: 0,
	},
];
export const morePipelineFiltersBuyer = (showDeferred = false) => ({
	[PENDING_WORK_ORDERS]: [
		{
			displayName: 'Suggested by Service Provider',
			name: SUPPLIER_INITIATED_PENDING_APPROVAL,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: SUPPLIER_INITIATED_PENDING_APPROVAL_FILTER,
		},
		{
			displayName: 'Requested by Location Staff',
			name: PENDING_APPROVAL,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: PENDING_APPROVAL_FILTER,
		},
	],
	...(showDeferred
		? {
				[DEFERRED_WORK_ORDERS]: [
					{
						displayName: 'Deferred For Budget',
						name: DEFERRED_FOR_BUDGET,
						icon: null,
						iconStyle: { color: 'rgb(213, 125, 5)' },
						filter: DEFERRED_FOR_BUDGET_FILTER,
					},
					{
						displayName: 'Snoozed',
						name: SNOOZED,
						icon: null,
						iconStyle: { color: '#4a1e87' },
						filter: SNOOZED_FILTER,
					},
				],
		  }
		: {}),
	[OPEN_WORK_ORDERS]: [
		{
			displayName: 'Unassigned',
			name: UNASSIGNED,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: UNASSIGNED_FILTER,
		},
		{
			displayName: 'Pending Confirmation',
			name: PENDING_CONFIRMATION_BY_SERVICE_PROVIDER,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: PENDING_CONFIRMATION_BY_SERVICE_PROVIDER_FILTER,
		},
		{
			displayName: 'Assigned to Internal Team',
			name: ASSIGNED_TO_INTERNAL_TECH,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: ASSIGNED_TO_INTERNAL_TECH_FILTER,
		},
	],
	[IN_PROGRESS_WORK_ORDERS]: [
		{
			displayName: 'Confirmed',
			name: CONFIRMED_BY_SERVICE_PROVIDER,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: CONFIRMED_BY_SERVICE_PROVIDER_FILTER,
		},
		{
			displayName: 'Tech Assigned',
			name: TECH_ASSIGNED,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: TECH_ASSIGNED_FILTER,
		},
		{
			displayName: 'Tech Scheduled',
			name: TECH_SCHEDULED,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: TECH_SCHEDULED_FILTER,
		},
		{
			displayName: 'Tech Rescheduled',
			name: TECH_RESCHEDULED,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: TECH_RESCHEDULED_FILTER,
		},
		{
			displayName: 'Tech Working',
			name: TECH_WORKING_ON_SITE,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: TECH_WORKING_ON_SITE_FILTER,
		},
		{
			displayName: 'Work Incomplete',
			name: WORK_INCOMPLETE_WITH_REASON,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: WORK_INCOMPLETE_WITH_REASON_FILTER,
		},
		{
			displayName: 'Work Unsatisfactory',
			name: WORK_UNSATISFACTORY,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: WORK_UNSATISFACTORY_FILTER,
		},
		{
			displayName: 'Waiting for Review',
			name: WAITING_FOR_REVIEW,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: WAITING_FOR_REVIEW_FILTER,
		},
		{
			displayName: 'Parts Received',
			name: PARTS_RECEIVED,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: PARTS_RECEIVED_FILTER,
		},
		{
			displayName: 'Waiting for Quote',
			name: WAITING_FOR_QUOTE,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: WAITING_FOR_QUOTE_FILTER,
		},
		{
			displayName: 'Proposal Approved',
			name: PROPOSAL_APPROVED,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: PROPOSAL_APPROVED_FILTER,
		},
	],
	[ON_HOLD_WORK_ORDERS]: [
		{
			displayName: 'Waiting for Proposal',
			name: WAITING_FOR_PROPOSAL,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: WAITING_FOR_PROPOSAL_FILTER,
		},
		{
			displayName: 'Waiting for Proposal Approval',
			name: WAITING_FOR_PROPOSAL_APPROVAL,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: WAITING_FOR_PROPOSAL_APPROVAL_FILTER,
		},
		{
			displayName: 'Parts Requested',
			name: PARTS_REQUESTED,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: PARTS_REQUESTED_FILTER,
		},
		{
			displayName: 'Parts on Order',
			name: PARTS_ON_ORDER,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: PARTS_ON_ORDER_FILTER,
		},
		{
			displayName: 'Delayed by Third Party',
			name: DELAYED_BY_THIRD_PARTY,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: DELAYED_BY_THIRD_PARTY_FILTER,
		},
	],
	[COMPLETED_WORK_ORDERS]: [
		{
			displayName: 'Completed by Internal Tech',
			name: INTERNAL_TECH_WORK_COMPLETE,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: INTERNAL_TECH_WORK_COMPLETE_FILTER,
		},
		{
			displayName: 'Internal Work Reviewed',
			name: INTERNAL_TECH_WORK_REVIEWED_AND_COMPLETED,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: INTERNAL_TECH_WORK_REVIEWED_AND_COMPLETED_FILTER,
		},
		{
			displayName: 'Work Reviewed and Completed',
			name: WORK_REVIEWED_AND_COMPLETED,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: WORK_REVIEWED_AND_COMPLETED_FILTER,
		},
		{
			displayName: 'Invoiced',
			name: INVOICED,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: INVOICED_FILTER,
		},
		{
			displayName: 'Payment Made',
			name: PAYMENT_MADE,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: PAYMENT_MADE_FILTER,
		},
		{
			displayName: 'Payment Accepted',
			name: PAYMENT_ACCEPTED,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: PAYMENT_ACCEPTED_FILTER,
		},
	],
	[CANCELLED_WORK_ORDERS]: [
		{
			displayName: 'Cancelled With Reason',
			name: CANCELLED_WITH_REASON,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: CANCELLED_WITH_REASON_FILTER,
		},
	],
});
export const pipelineFiltersSupplier = (showDeferred = false) => [
	{
		displayName: 'Requested',
		name: PENDING_WORK_ORDERS,
		icon: 'icons8-edit',
		iconStyle: { color: '#4a1e87' },
		filter: PENDING_FILTER,
	},
	...(showDeferred
		? [
				{
					displayName: 'Deferred',
					filter: DEFERRED_FILTER,
					name: DEFERRED_WORK_ORDERS,
					icon: 'icons8-hourglass',
					iconStyle: { color: 'rgb(213, 125, 5)' },
					count: 0,
				},
		  ]
		: []),
	{
		displayName: 'Open',
		name: OPEN_WORK_ORDERS,
		icon: 'icons8-error',
		iconStyle: { color: STOPLIGHT_COLORS.pureRed },
		filter: OPEN_FILTER,
	},
	{
		displayName: 'In Progress',
		name: IN_PROGRESS_WORK_ORDERS,
		icon: 'icons8-wrench',
		iconStyle: { color: STOPLIGHT_COLORS.yellowGreen },
		filter: IN_PROGRESS_FILTER,
	},
	{
		displayName: 'On Hold',
		name: ON_HOLD_WORK_ORDERS,
		icon: 'icons8-pause-squared',
		iconStyle: { color: STOPLIGHT_COLORS.pureYellow },
		filter: ON_HOLD_FILTER,
	},
	{
		displayName: 'Completed',
		name: COMPLETED_WORK_ORDERS,
		icon: 'icons8-green-check-mark',
		iconStyle: { color: STOPLIGHT_COLORS.pureGreen },
		filter: COMPLETED_FILTER,
	},
	{
		displayName: 'Cancelled',
		name: CANCELLED_WORK_ORDERS,
		icon: 'icons8-cancel',
		iconStyle: { color: BLACKS_AND_WHITES.blackTertiary },
		filter: CANCELLED_FILTER,
	},
];
export const morePipelineFiltersSupplier = (showDeferred = false) => ({
	[PENDING_WORK_ORDERS]: [],
	...(showDeferred
		? {
				[DEFERRED_WORK_ORDERS]: [
					{
						displayName: 'Deferred For Budget',
						name: DEFERRED_FOR_BUDGET,
						icon: null,
						iconStyle: { color: 'rgb(213, 125, 5)' },
						filter: DEFERRED_FOR_BUDGET_FILTER,
					},
					{
						displayName: 'Snoozed',
						name: SNOOZED,
						icon: null,
						iconStyle: { color: '#4a1e87' },
						filter: SNOOZED_FILTER,
					},
				],
		  }
		: {}),
	[OPEN_WORK_ORDERS]: [
		{
			displayName: 'Unassigned',
			name: UNASSIGNED,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: UNASSIGNED_FILTER,
		},
		{
			displayName: 'Pending Confirmation',
			name: PENDING_CONFIRMATION_BY_SERVICE_PROVIDER,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: PENDING_CONFIRMATION_BY_SERVICE_PROVIDER_FILTER,
		},
		{
			displayName: 'Assigned to Internal Team',
			name: ASSIGNED_TO_INTERNAL_TECH,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: ASSIGNED_TO_INTERNAL_TECH_FILTER,
		},
	],
	[IN_PROGRESS_WORK_ORDERS]: [
		{
			displayName: 'Confirmed',
			name: CONFIRMED_BY_SERVICE_PROVIDER,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: CONFIRMED_BY_SERVICE_PROVIDER_FILTER,
		},
		{
			displayName: 'Tech Assigned',
			name: TECH_ASSIGNED,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: TECH_ASSIGNED_FILTER,
		},
		{
			displayName: 'Tech Scheduled',
			name: TECH_SCHEDULED,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: TECH_SCHEDULED_FILTER,
		},
		{
			displayName: 'Tech Rescheduled',
			name: TECH_RESCHEDULED,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: TECH_RESCHEDULED_FILTER,
		},
		// {
		//     displayName: 'Tech On the Way',
		//     name: TECH_EN_ROUTE,
		//     icon: null,
		//     iconStyle: {color: '#4a1e87'},
		// filter: TECH_EN_ROUTE_FILTER,
		// },
		// {
		//     displayName: 'Tech Waiting',
		//     name: TECH_WAITING_ON_SITE,
		//     icon: null,
		//     iconStyle: {color: '#4a1e87'},
		// filter: CONFIRMED_BY_SERVICE_PROVIDER_FILTER,
		// },
		{
			displayName: 'Tech Working',
			name: TECH_WORKING_ON_SITE,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: TECH_WORKING_ON_SITE_FILTER,
		},
		{
			displayName: 'Work Incomplete',
			name: WORK_INCOMPLETE_WITH_REASON,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: WORK_INCOMPLETE_WITH_REASON_FILTER,
		},
		{
			displayName: 'Work Unsatisfactory',
			name: WORK_UNSATISFACTORY,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: WORK_UNSATISFACTORY_FILTER,
		},
		{
			displayName: 'Waiting for Review',
			name: WAITING_FOR_REVIEW,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: WAITING_FOR_REVIEW_FILTER,
		},
		{
			displayName: 'Waiting for Quote',
			name: WAITING_FOR_QUOTE,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: WAITING_FOR_QUOTE_FILTER,
		},
		{
			displayName: 'Parts Received',
			name: PARTS_RECEIVED,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: PARTS_RECEIVED_FILTER,
		},
		{
			displayName: 'Proposal Approved',
			name: PROPOSAL_APPROVED,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: PROPOSAL_APPROVED_FILTER,
		},
	],
	[ON_HOLD_WORK_ORDERS]: [
		{
			displayName: 'Waiting for Proposal',
			name: WAITING_FOR_PROPOSAL,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: WAITING_FOR_PROPOSAL_FILTER,
		},
		{
			displayName: 'Waiting for Proposal Approval',
			name: WAITING_FOR_PROPOSAL_APPROVAL,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: WAITING_FOR_PROPOSAL_APPROVAL_FILTER,
		},
		{
			displayName: 'Parts Requested',
			name: PARTS_REQUESTED,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: PARTS_REQUESTED_FILTER,
		},
		{
			displayName: 'Parts on Order',
			name: PARTS_ON_ORDER,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: PARTS_ON_ORDER_FILTER,
		},
		{
			displayName: 'Delayed by Third Party',
			name: DELAYED_BY_THIRD_PARTY,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: DELAYED_BY_THIRD_PARTY_FILTER,
		},
	],
	[COMPLETED_WORK_ORDERS]: [
		{
			displayName: 'Completed by Internal Tech',
			name: INTERNAL_TECH_WORK_COMPLETE,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: INTERNAL_TECH_WORK_COMPLETE_FILTER,
		},
		{
			displayName: 'Internal Work Reviewed',
			name: INTERNAL_TECH_WORK_REVIEWED_AND_COMPLETED,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: INTERNAL_TECH_WORK_REVIEWED_AND_COMPLETED_FILTER,
		},
		{
			displayName: 'Work Reviewed and Completed',
			name: WORK_REVIEWED_AND_COMPLETED,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: WORK_REVIEWED_AND_COMPLETED_FILTER,
		},
		{
			displayName: 'Invoiced',
			name: INVOICED,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: INVOICED_FILTER,
		},
		{
			displayName: 'Payment Made',
			name: PAYMENT_MADE,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: PAYMENT_MADE_FILTER,
		},
		{
			displayName: 'Payment Accepted',
			name: PAYMENT_ACCEPTED,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: PAYMENT_ACCEPTED_FILTER,
		},
	],
	[CANCELLED_WORK_ORDERS]: [
		{
			displayName: 'Cancelled With Reason',
			name: CANCELLED_WITH_REASON,
			icon: null,
			iconStyle: { color: '#4a1e87' },
			filter: CANCELLED_WITH_REASON_FILTER,
		},
	],
});

export const CLEAR_STATUS_FILTER = { displayStatus: undefined };
export const CLEAR_DISPLAY_STATUS_FILTER = { status: undefined };

import { WARRANTIES_CRUD_ACTION_CREATORS } from '../actions/warranties_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';

export const warrantiesRestCrudThunksForBuyer = new RestCrudThunks(
	'warranties',
	'warranty',
	'warranty',
	'warranties',
	WARRANTIES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'asset/'
);
export const warrantiesRestCrudThunksForSupplier = new RestCrudThunks(
	'warranties',
	'warranty',
	'warranty',
	'warranties',
	WARRANTIES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'asset/'
);

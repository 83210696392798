import { Form, Modal, Select, Switch, message } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { allowFieldTechToManageStockLocationsForSupplier } from '../../thunks/field_techs_thunks';

const EditManageStockLocationsModal: FC<any> = ({
	onCancel,
	onSuccess,
	fieldTech,
	stockLocations,
	fetchStockLocations,
	fetchMultipleStockLocations,
	updateStockLocations,
	updating,
}): React.ReactElement => {
	const [form] = Form.useForm();
	const { setFieldsValue } = form;

	const [canManageAllStockLocations, setCanManageAllStockLocations] = useState(false);

	const handleSubmit = useCallback(
		(values) =>
			updateStockLocations({
				...values,
				email: fieldTech.email,
			})
				.then(() => onSuccess && onSuccess())
				.catch(() => message.error('Unable to update managed stock locations.')),
		[fieldTech.email, onSuccess, updateStockLocations]
	);

	useEffect(() => {
		setFieldsValue({
			stockLocationIds: nullSafeGetOrElse('managedStockLocations', fieldTech, []).map((_) => _.id),
		});
		setCanManageAllStockLocations(nullSafeGet('canManageAllStockLocations', fieldTech));
	}, [fieldTech, setFieldsValue]);

	useEffect(
		() =>
			setFieldsValue({
				...(canManageAllStockLocations && { stockLocationIds: [] }),
				canManageAllStockLocations,
			}),
		[canManageAllStockLocations, setFieldsValue]
	);

	return (
		<Modal
			visible={true}
			width={600}
			title={'Edit Managed Stock Locations'}
			okText={'Save'}
			onCancel={onCancel}
			onOk={form.submit}
			closable={true}
			confirmLoading={updating}
			destroyOnClose={true}
		>
			<Form
				form={form}
				layout="vertical"
				className="supplierForm"
				preserve={false}
				initialValues={{}}
				onFinish={handleSubmit}
			>
				<Form.Item
					name="canManageAllStockLocations"
					valuePropName="checked"
					label="Stock Locations"
				>
					<Switch checked={canManageAllStockLocations} onChange={setCanManageAllStockLocations} />
					<label style={{ marginLeft: 8 }}>Select All Stock Locations</label>
				</Form.Item>
				<Form.Item name="stockLocationIds">
					<OWAsyncSelect
						mode="multiple"
						disabled={canManageAllStockLocations}
						emptyWhenDisabled={true}
						stateSlice={stockLocations}
						targetCollectionName={'fieldTechsDropdown'}
						fetchMultiple={(ids, targetCollectionName) => {
							fetchMultipleStockLocations(ids, targetCollectionName);
						}}
						fetchData={(
							searchText,
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						) => {
							fetchStockLocations(
								{ name: searchText },
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							);
						}}
						renderRecord={(d) => (
							<Select.Option value={d.id} key={d.id}>
								{d.name}
							</Select.Option>
						)}
						sortBy={{ sort_by: 'name', order: 'ascend' }}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	stockLocations: state.stock_locations,
	updating: state.field_techs.updating,
	fieldTech: state.field_techs.detail,
});

const mapDispatchToProps = (dispatch) => ({
	fetchMultipleStockLocations: (ids, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchStockLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			stockLocationsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	updateStockLocations: (entity) =>
		dispatch(allowFieldTechToManageStockLocationsForSupplier(entity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditManageStockLocationsModal);

import { ConfigProvider, Table } from 'antd';
import React, { useCallback } from 'react';
import { ROLE_TYPES } from '../../../utils/DataConstants';
import {
	BuyerWorkOrderTableEmptyState,
	SupplierWorkOrderTableEmptyState,
} from '../emptyState/WorkOrderTableEmptyState';
import WorkOrderTableRowBuyer from '../WorkOrderTableRow/buyer';
import WorkOrderTableRowSupplier from '../WorkOrderTableRow/supplier';
import { getSearchStringForBackNavigationWithBackText } from '../../../utils/DataAccessUtils';

export default function WOTable({
	fetching,
	data,
	currentUser,
	companyConfig,
	CustomWorkOrderRowDisplay,
	refreshTable,
	tableStyle,
	renderEmptyState,
	userType,
	location,
	backText,
	targetCollectionName,
}) {
	const EmptyState =
		userType === ROLE_TYPES.SUPPLIER
			? SupplierWorkOrderTableEmptyState
			: BuyerWorkOrderTableEmptyState;
	const WorkOrderTableRow =
		userType === ROLE_TYPES.SUPPLIER ? WorkOrderTableRowSupplier : WorkOrderTableRowBuyer;
	const columns = [
		{
			render: (text, record) =>
				CustomWorkOrderRowDisplay ? (
					<CustomWorkOrderRowDisplay
						record={record}
						currentUser={currentUser}
						companyConfig={companyConfig}
						refreshTable={refreshTable}
						location={location}
						userType={userType}
						targetCollectionName={targetCollectionName}
					/>
				) : (
					<WorkOrderTableRow
						record={record}
						currentUser={currentUser}
						companyConfig={companyConfig}
						refreshTable={refreshTable}
						location={location}
						backText={backText}
						targetCollectionName={targetCollectionName}
					/>
				),
		},
	];
	return (
		<ConfigProvider
			renderEmpty={() =>
				renderEmptyState
					? renderEmptyState(currentUser)
					: <EmptyState currentUser={currentUser} /> || undefined
			}
		>
			<Table
				rowClassName={'clickable-index-row'}
				style={{ tableLayout: 'fixed', ...tableStyle }}
				className="table-fixed-100"
				showHeader={false}
				pagination={false}
				loading={fetching}
				dataSource={data}
				columns={columns}
			/>
		</ConfigProvider>
	);
}

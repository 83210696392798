import * as React from 'react';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { Card } from 'antd';

const DAYS_OF_THE_WEEK = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
];

export const OperatingHoursCardDisplay: React.FC<any> = ({ operatingHours, location }) => {
	return (
		<Card title="Operating hours">
			<div style={{ fontSize: 16 }}>
				{nullSafeGet('operatingHours.timeZoneName', location) ? (
					<div style={{ color: 'rgba(0,0,0,0.45)', marginBottom: 16 }}>
						{nullSafeGet('operatingHours.timeZoneName', location)}
					</div>
				) : null}
				<table>
					{DAYS_OF_THE_WEEK.map((dayOfTheWeek, idx) => {
						const fromTime = nullSafeGet(`${dayOfTheWeek}.from`, operatingHours);
						const toTime = nullSafeGet(`${dayOfTheWeek}.to`, operatingHours);
						const hasHours = fromTime && toTime;

						return (
							<tr key={idx} style={{ lineHeight: '32px' }}>
								<td>{dayOfTheWeek}</td>
								<td style={{ paddingLeft: 32, color: 'rgba(0,0,0,0.45)' }}>
									{hasHours ? `${fromTime} to ${toTime}` : 'No hours available'}
								</td>
							</tr>
						);
					})}
				</table>
			</div>
		</Card>
	);
};

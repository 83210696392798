import React, { useCallback } from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { getEquipmentId, nullSafeGetOrElse, renderCurrency } from '../../utils/DataAccessUtils';
import GreyStatusTag from './GreyStatusTag';
import { connect } from 'react-redux';

require('./EquipmentPerStockLocationRowDisplay.less');

const EquipmentPerStockLocationRowDisplay: React.FC<any> = ({
	equipmentPerStockLocation,
	currentUser,
	...props
}) => {
	const getAddedType = useCallback((type) => {
		switch (type) {
			case 'transferred':
				return 'Transferred';
			case 'received':
				return 'Received';
			default:
				return '';
		}
	}, []);

	return (
		<div
			className="equipmentPerStockLocationRowDisplay"
			style={{ justifyContent: 'space-between' }}
			{...props}
		>
			<div className="equipmentPerStockLocationRowDisplay">
				<div>
					<div className="equipmentPerStockLocationRowDisplay__rowTitle">
						<Ellipsis tooltip={true} length={56}>
							{equipmentPerStockLocation.name}
						</Ellipsis>
					</div>
					<div className="equipmentPerStockLocationRowDisplay__rowSubtitle">
						<div className="equipmentPerStockLocationRowDisplay__rowInlineGroup">
							{`Equipment ID ${getEquipmentId(equipmentPerStockLocation)}`}
						</div>
					</div>
					<div className="equipmentPerStockLocationRowDisplay__rowSubtitle">
						<div className="equipmentPerStockLocationRowDisplay__rowInlineGroup">
							{nullSafeGetOrElse('equipmentType.modelName', equipmentPerStockLocation, '--')}
						</div>
						{equipmentPerStockLocation.serialNumber && (
							<div className="equipmentPerStockLocationRowDisplay__rowInlineGroup">
								Serial# {equipmentPerStockLocation.serialNumber}
							</div>
						)}
						{equipmentPerStockLocation.assetNumber && (
							<div className="equipmentPerStockLocationRowDisplay__rowInlineGroup">
								Asset# {equipmentPerStockLocation.assetNumber}
							</div>
						)}
						{equipmentPerStockLocation.cost ? (
							<div className="equipmentPerStockLocationRowDisplay__rowInlineGroup">
								Cost {renderCurrency(currentUser)(equipmentPerStockLocation.cost)}
							</div>
						) : null}
						{equipmentPerStockLocation.description && (
							<div className="equipmentPerStockLocationRowDisplay__rowInlineGroup">
								<Ellipsis tooltip={true} length={50}>
									{equipmentPerStockLocation.description}
								</Ellipsis>
							</div>
						)}
					</div>
					<div className="equipmentPerStockLocationRowDisplay__rowSubtitle">
						{typeof equipmentPerStockLocation.addedType !== 'undefined' ? (
							<div className="equipmentPerStockLocationRowDisplay__rowInlineGroup">
								{getAddedType(equipmentPerStockLocation.addedType)}
							</div>
						) : null}
						{equipmentPerStockLocation.isDisbursed ? (
							<div className="equipmentPerStockLocationRowDisplay__rowInlineGroup">
								<GreyStatusTag text="Disbursed" />
							</div>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

export default connect(mapStateToProps)(EquipmentPerStockLocationRowDisplay);

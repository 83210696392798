import React, { FC, useMemo, useState } from 'react';
import { Button } from 'antd';
import { BUYER_ONLY_NO_STORE_ASSOCIATE } from '../roles/roles';
import { isUserAllowedToAccess, PERMISSION_NAMES } from '../../utils/AuthUtils';
import {
	checkHasApprovalHierarchy,
	compareStrings,
	isInApprovalHierarchy,
	nullSafeGet,
} from '../../utils/DataAccessUtils';
import { dateFormatter, getNumberSuffix } from '../../utils/DataFormatterUtils';

const UndeferComponent: FC<any> = ({
	currentUser,
	workOrder,
	unsnoozeWorkOrder,
	companyConfig,
	refreshWorkOrder,
}): React.ReactElement => {
	const [buttonLoading, setButtonLoading] = useState(false);

	const undeferType = nullSafeGet('status', workOrder) === 'Snoozed' ? 'Unsnooze' : 'Undefer';
	const snoozeUntilDate = workOrder.snoozeUntil
		? dateFormatter(workOrder.snoozeUntil, 'MMMM D').concat(
				getNumberSuffix(workOrder.snoozeUntil),
				', ',
				dateFormatter(workOrder.snoozeUntil, 'YYYY')
		  )
		: null;
	const question = `This work order is ${
		nullSafeGet('status', workOrder) === 'Snoozed' ? 'snoozed' : 'deferred'
	}${snoozeUntilDate ? ' until '.concat(snoozeUntilDate) : ''}.`;

	const isApprovalAllowed = useMemo(
		() =>
			checkHasApprovalHierarchy(workOrder)
				? isInApprovalHierarchy(currentUser, nullSafeGet('approvalHierarchy', workOrder))
				: isUserAllowedToAccess('buyer')(
						PERMISSION_NAMES.CAN_APPROVE_WORK_ORDERS,
						companyConfig,
						currentUser.roles
				  ),
		[]
	);

	return isApprovalAllowed ? (
		<div>
			<h5>{question}</h5>
			<div style={{ marginTop: 16, marginBottom: 20 }}>
				<Button
					type="primary"
					key="approve"
					loading={buttonLoading}
					onClick={() => {
						setButtonLoading(true);
						unsnoozeWorkOrder({ id: workOrder.id }).then(() => {
							setButtonLoading(false);
							refreshWorkOrder();
						});
					}}
				>
					{undeferType} work order
				</Button>
			</div>
		</div>
	) : null;
};

export default UndeferComponent;

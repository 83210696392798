import * as React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Layout, Row, Col, DatePicker, Table, Card, Button, List, Modal } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import FileUploader from '../file_uploader/FileUploader';

import { KeyValueDisplay } from '../key_value_display/KeyValueDisplay';

import {
	dateFormatter,
	optionalFilter,
	capitalizeEachWord,
	checkAndFormatPhoneNumber,
} from '../../utils/DataFormatterUtils';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';

import Mapbox from '../mapbox/Mapbox';
import { SocialMediaBadge } from '../social_media_badge/SocialMediaBadge';
import { AddressDisplay } from '../address_display/AddressDisplay';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { supplierFacilitiesRestCrudThunksForSupplier } from '../../thunks/supplier_facilities_thunks';
import { supplierFacilityReviewsRestCrudThunksForSupplier } from '../../thunks/supplier_facility_reviews_thunks';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { FlexibleImageAvatar } from '../flexible-image-avatar/FlexibleImageAvatar';
import { EmptyState } from '../empty_state/EmptyState';
import EditSupplierDescriptionForm from '../edit_supplier_description_form/EditSupplierDescriptionForm';
import EditSupplierFAQForm from '../edit_supplier_faq_form/EditSupplierFAQForm';
import EditSupplierOperatingHoursForm from '../edit_supplier_operating_hours_form/EditSupplierOperatingHoursForm';
import EditSupplierContactInformationForm from '../edit_supplier_contact_information_form/EditSupplierContactInformationForm';
import EditSupplierSocialMediaForm from '../edit_supplier_social_media_form/EditSupplierSocialMediaForm';
import { SOCIAL_MEDIA_TYPES } from '../../constants/social_media';
import EditSupplierLicensesForm from '../edit_supplier_licenses_form/EditSupplierLicensesForm';
import EditSupplierCertificationsForm from '../edit_supplier_certifications_form/EditSupplierCertificationsForm';
import EditSupplierInsuranceCoverageForm from '../edit_supplier_insurance_coverage_form/EditSupplierInsuranceCoverageForm';
import EditSupplierCoreInfoForm from '../edit_supplier_core_info_form/EditSupplierCoreInfoForm';
import { FAQRowDisplay } from '../faq_row_display/FAQRowDisplay';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import EditSupplierSpendCategoriesForm from '../edit_supplier_spend_categories_form/EditSupplierSpendCategoriesForm';
import EditCurrencyForm from '../edit_currency_form/EditCurrencyForm';
import { CURRENCIES } from '../../utils/DataConstants';

const ListItem = List.Item;
const { Content } = Layout;
const { RangePicker } = DatePicker;

const style = require('./SupplierSuppliersDetailPage.less');

const DAYS_OF_THE_WEEK = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
];

class SupplierSuppliersDetailPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			editingCoreInfo: false,
			editingDescription: false,
			editingFAQ: false,
			editingBusinessHours: false,
			editingContactInformation: false,
			editingSocialMediaLinks: false,
			editingLicenses: false,
			editingCertifications: false,
			editingSpendCategories: false,
			editingInsuranceCoverage: false,
			editingCurrency: false,
			modalContent: null,
			modalVisible: false,
		};
	}

	componentDidMount() {
		const { fetchSupplierFacility, fetchSupplierFacilityReviews, match, currentUser } = this.props;
		const supplierFacilityId = match.params.id === 'me' ? currentUser.facility.id : match.params.id;
		fetchSupplierFacility(supplierFacilityId).then(() => this.setState({ loading: false }));
		fetchSupplierFacilityReviews(supplierFacilityId);
	}

	handleEditToggle = (toggleKey) => {
		const currentState = this.state[toggleKey];
		this.setState({ [toggleKey]: !currentState });
	};

	handleEditCancel = (toggleKey) => {
		this.setState({ [toggleKey]: false });
	};

	handleOpenCertificationModal = (certification, idx) => {
		this.setState({
			modalVisible: true,
			modalContent: (
				<div key={idx}>
					<h5>{certification.certificationType}</h5>

					<KeyValueDisplay
						keyValueStore={{
							'Certificate #': certification.number,
							Issuer: certification.issuer,
							Qualifier: certification.qualifier,
							Expiration: dateFormatter(certification.expiration, 'MMM D, YYYY'),
							Attachment: certification.certificationFile,
						}}
						renderFuncs={{
							Attachment: (file) =>
								file ? (
									<FileUploader
										roleType="supplier"
										defaultFileList={file ? [file] : []}
										uploaderType="readOnly"
									/>
								) : (
									'--'
								),
						}}
					/>
				</div>
			),
		});
	};

	handleOpenInsuranceModal = (insurance) => {
		const policyDocColumns = [
			{
				title: 'Policy',
				dataIndex: 'policyName',
			},
			{
				title: 'Amount',
				dataIndex: 'amount',
				render: (text) => <span>${text.toLocaleString('en')}</span>,
			},
			{
				title: 'Expires',
				dataIndex: 'expiration',
				render: (text) => dateFormatter(text, 'MMM D, YYYY'),
			},
			{
				title: 'Attachment',
				dataIndex: 'policyFile',
				render: (text) =>
					text ? (
						<FileUploader roleType="supplier" defaultFileList={[text]} uploaderType="readOnly" />
					) : (
						'--'
					),
			},
		];
		this.setState({
			modalVisible: true,
			modalContent: (
				<div>
					<h5>{insurance.companyName}</h5>
					<div style={{ padding: '8px 0', display: 'flex' }}>
						<div>
							<div
								style={{
									fontSize: 16,
									marginBottom: 4,
								}}
							>
								{insurance.companyContact.name}
							</div>
							<div
								style={{
									fontSize: 14,
									color: 'rgba(0,0,0,0.45)',
								}}
							>
								{insurance.companyContact.phone}
							</div>
						</div>
						<div
							style={{
								fontSize: 14,
								marginLeft: 32,
								color: 'rgba(0,0,0,0.45)',
							}}
						>
							<AddressDisplay address={insurance.companyContact.address} />
						</div>
					</div>
					<Table pagination={false} dataSource={insurance.policyDocs} columns={policyDocColumns} />
				</div>
			),
		});
	};

	handleOpenLicenseModal = (license) => {
		this.setState({
			modalVisible: true,
			modalContent: (
				<div>
					<h5 style={{ marginBottom: 16 }}>{license.licenseType}</h5>
					<KeyValueDisplay
						keyValueStore={{
							'License #': license.number,
							Issuer: license.issuer,
							Qualifier: license.qualifier,
							Expiration: dateFormatter(license.expiration, 'MMM D, YYYY'),
							Attachment: license.licenseFile,
						}}
						renderFuncs={{
							Attachment: (file) =>
								file ? (
									<FileUploader
										roleType="supplier"
										defaultFileList={file ? [file] : []}
										uploaderType="readOnly"
									/>
								) : (
									'--'
								),
						}}
					/>
				</div>
			),
		});
	};

	handleW9Upload = (newAttachments) => {
		const { supplierFacility, updateSupplier } = this.props;
		let callUpdate = newAttachments.every((element) => {
			return element && element.fileId && element.fileName;
		});
		if (callUpdate) {
			const updatedSupplier = {
				...supplierFacility,
				w9: newAttachments[0],
			};
			updateSupplier(updatedSupplier).then((resp) => {});
		}
	};

	render() {
		const { supplierFacility, supplierFacilityReviews } = this.props;

		const {
			loading,
			editingCoreInfo,
			editingDescription,
			editingFAQ,
			editingBusinessHours,
			editingContactInformation,
			editingSocialMediaLinks,
			editingLicenses,
			editingCertifications,
			editingSpendCategories,
			editingInsuranceCoverage,
			editingCurrency,
		} = this.state;
		const contactInfo = loading
			? {}
			: {
					'Primary Contact Email': optionalFilter(supplierFacility.primaryContactEmail),
					Phone: optionalFilter(checkAndFormatPhoneNumber(supplierFacility.primaryPhone)),
					Address: <AddressDisplay address={supplierFacility.primaryAddress} />,
					Website: optionalFilter(supplierFacility.website),
			  };

		// will need to be changed once paginating reviews due to increased numbers, but for now this should be ok
		const reviews = getRecordsForTargetCollection(supplierFacilityReviews, 'supplierDetailReviews');
		const numOfReviews = reviews.length;
		const roundedRatings = reviews.map((r) => Math.round(r.rating));
		const currency = CURRENCIES[nullSafeGetOrElse('currencyId', supplierFacility, 'USD')];
		const currencyString = currency.displayNamePlural.concat(' (', currency.id, ')');

		const operatingHours = supplierFacility.operatingHours || {};

		return (
			<Content className="supplierSuppliersDetailPage" style={{ position: 'relative', padding: 0 }}>
				<Modal
					visible={this.state.modalVisible}
					width={600}
					onCancel={() => this.setState({ modalVisible: false, modalContent: null })}
					footer={null}
					closable={true}
				>
					{this.state.modalContent}
				</Modal>
				<LogOnMountWithStandardEventProperties eventType="visited supplier supplier detail page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				{loading ? (
					<PageLoadingPlaceholder />
				) : (
					<div style={{}}>
						<Row style={{ margin: '0 -8px' }} gutter={16}>
							<Col span={24}>
								<div className="supplierSuppliersDetailPage__header">
									<div style={{ float: 'left', display: 'flex' }}>
										{editingCoreInfo ? (
											<EditSupplierCoreInfoForm
												successCallback={() => this.handleEditToggle('editingCoreInfo')}
												cancelCallback={() => this.handleEditCancel('editingCoreInfo')}
												supplierFacility={supplierFacility}
												logoURL={supplierFacility.logoURL}
												displayName={supplierFacility.displayName}
											/>
										) : (
											[
												<div style={{ display: 'flex', alignItems: 'center' }}>
													<div style={{ marginRight: 24 }}>
														{supplierFacility.logoURL ? (
															<FlexibleImageAvatar
																displayName={supplierFacility.displayName}
																showPopover={false}
																popoverText={supplierFacility.displayName}
																squareMode={true}
																width="120px"
																height="80px"
																imageUrl={supplierFacility.logoURL}
															/>
														) : (
															<FlexibleImageAvatar
																displayName={supplierFacility.name}
																showPopover={false}
																popoverText={supplierFacility.name}
																squareMode={true}
																width="120px"
																height="80px"
																imageUrl="https://s3.amazonaws.com/emptor-data-assets/images/empty-state/icons8-city-filled-100.png"
															/>
														)}
													</div>
													<div>
														<h4 style={{ color: 'white', marginBottom: 4 }}>
															{supplierFacility.displayName}
														</h4>
													</div>
												</div>,
											]
										)}
										{editingCoreInfo ? null : (
											<div
												className="editLink"
												style={{ marginLeft: 16 }}
												onClick={() => this.handleEditToggle('editingCoreInfo')}
											>
												Edit
											</div>
										)}
									</div>
									<div
										style={{
											float: 'right',
											display: 'flex',
											justifyContent: 'flex-end',
											alignItems: 'center',
											maxWidth: 400,
										}}
									>
										{editingSocialMediaLinks ? (
											<div style={{ minWidth: 480 }}>
												<EditSupplierSocialMediaForm
													successCallback={() => this.handleEditToggle('editingSocialMediaLinks')}
													cancelCallback={() => this.handleEditCancel('editingSocialMediaLinks')}
													supplierFacility={supplierFacility}
													socialMedia={supplierFacility.socialMedia}
												/>
											</div>
										) : SOCIAL_MEDIA_TYPES.every(
												(sm) =>
													supplierFacility.socialMedia === undefined ||
													supplierFacility.socialMedia[sm.key] === undefined
										  ) ? null : (
											SOCIAL_MEDIA_TYPES.map((sm) =>
												supplierFacility.socialMedia[sm.key] !== undefined ? (
													<div style={{ marginLeft: 8 }}>
														<SocialMediaBadge
															key={sm.type}
															type={sm.type}
															handle={supplierFacility.socialMedia[sm.key]}
														/>
													</div>
												) : null
											)
										)}
										{editingSocialMediaLinks ? null : (
											<div
												className="editLink"
												style={{ marginLeft: 16 }}
												onClick={() => this.handleEditToggle('editingSocialMediaLinks')}
											>
												Edit
											</div>
										)}
									</div>
								</div>
							</Col>
						</Row>

						<Row style={{ margin: '16px 4px' }} gutter={16}>
							<Col span={16}>
								<Row className="rowSpacing" gutter={16}>
									<Col span={24}>
										<Card>
											<h5 style={{ display: 'flex', alignItems: 'center' }}>
												About this supplier{' '}
												<Button
													style={{ marginLeft: 8 }}
													size="small"
													onClick={() => this.handleEditToggle('editingDescription')}
												>
													{editingDescription ? 'Cancel' : 'Edit'}
												</Button>
											</h5>
											{editingDescription ? (
												<EditSupplierDescriptionForm
													successCallback={() => this.handleEditToggle('editingDescription')}
													cancelCallback={() => this.handleEditCancel('editingDescription')}
													supplierFacility={supplierFacility}
													description={supplierFacility.about}
												/>
											) : supplierFacility.about && supplierFacility.about.length > 0 ? (
												<div style={{ fontSize: 18 }}>{supplierFacility.about}</div>
											) : (
												<EmptyState
													height={120}
													headline={null}
													body={
														<div style={{ textAlign: 'center' }}>
															<div style={{ maxWidth: 520, marginBottom: 16 }}>
																You haven't added a description of your company yet.
															</div>
															<Button
																type="primary"
																icon={<PlusOutlined />}
																onClick={() => this.handleEditToggle('editingDescription')}
															>
																Add a description
															</Button>
														</div>
													}
												/>
											)}
										</Card>
									</Col>
								</Row>

								<Row className="rowSpacing" gutter={16}>
									<Col span={24}>
										<Card>
											<h5 style={{ display: 'flex', alignItems: 'center' }}>W9 </h5>
											<div
												style={{
													textAlign: 'center',
													maxWidth: 488,
													marginBottom: 16,
													margin: 'auto',
												}}
											>
												{!supplierFacility.w9 ? (
													<>
														<div
															style={{
																marginBottom: 16,
																fontSize: 16,
															}}
														>
															Suppliers with w9 have a higher chance to get more work!
															<br />
															Upload w9 for your customers.
														</div>
														<FileUploader
															buttonType="primary"
															roleType="supplier"
															defaultFileList={supplierFacility.w9 ? [supplierFacility.w9] : []}
															handleUploadSuccess={this.handleW9Upload}
															multiple={false}
														/>
													</>
												) : (
													<FileUploader
														buttonType="primary"
														roleType="supplier"
														defaultFileList={supplierFacility.w9 ? [supplierFacility.w9] : []}
														handleUploadSuccess={this.handleW9Upload}
														uploaderType={'readAndRemove'}
														multiple={false}
													/>
												)}
											</div>
										</Card>
									</Col>
								</Row>

								<Row className="rowSpacing" gutter={16}>
									<Col span={24}>
										<Card>
											<h5 style={{ display: 'flex', alignItems: 'center' }}>
												Insurance coverage{' '}
												<Button
													style={{ marginLeft: 8 }}
													size="small"
													onClick={() => this.handleEditToggle('editingInsuranceCoverage')}
												>
													{editingInsuranceCoverage ? 'Cancel' : 'Edit'}
												</Button>
											</h5>
											{editingInsuranceCoverage ? (
												<EditSupplierInsuranceCoverageForm
													successCallback={() => this.handleEditToggle('editingInsuranceCoverage')}
													cancelCallback={() => this.handleEditCancel('editingInsuranceCoverage')}
													supplierFacility={supplierFacility}
													insuranceCoverages={supplierFacility.insurances}
												/>
											) : supplierFacility.insurances.length > 0 ? (
												<div>
													{supplierFacility.insurances.map((insurance, idx) => (
														<div key={idx} style={{ fontSize: 24 }}>
															<div
																style={{
																	fontSize: 18,
																	display: 'flex',
																	alignItems: 'center',
																}}
															>
																<img
																	width="80px"
																	height="80px"
																	style={{ marginRight: 16 }}
																	src="https://mock-data-assets.s3.amazonaws.com/categories/images/insuranceonfile.png"
																/>
																<div>
																	<div>Insured by {insurance.companyName}</div>
																	<div>
																		<Button
																			style={{
																				paddingLeft: 0,
																				paddingRight: 0,
																			}}
																			onClick={() => this.handleOpenInsuranceModal(insurance, idx)}
																			type="link"
																		>
																			View Details
																		</Button>
																	</div>
																</div>
															</div>
														</div>
													))}
												</div>
											) : (
												<EmptyState
													height={120}
													headline={null}
													body={
														<div style={{ textAlign: 'center' }}>
															<div style={{ maxWidth: 488, marginBottom: 16 }}>
																You haven't uploaded any insurance coverage yet.
															</div>
															<Button
																type="primary"
																icon={<PlusOutlined />}
																onClick={() => this.handleEditToggle('editingInsuranceCoverage')}
															>
																Add insurance coverage
															</Button>
														</div>
													}
												/>
											)}
										</Card>
									</Col>
								</Row>
								<Row className="rowSpacing" gutter={16}>
									<Col span={24}>
										<Card>
											<h5 style={{ display: 'flex', alignItems: 'center' }}>
												Licenses{' '}
												<Button
													style={{ marginLeft: 8 }}
													size="small"
													onClick={() => this.handleEditToggle('editingLicenses')}
												>
													{editingLicenses ? 'Cancel' : 'Edit'}
												</Button>
											</h5>
											{editingLicenses ? (
												<EditSupplierLicensesForm
													successCallback={() => this.handleEditToggle('editingLicenses')}
													cancelCallback={() => this.handleEditCancel('editingLicenses')}
													supplierFacility={supplierFacility}
													licenses={supplierFacility.licenses}
												/>
											) : supplierFacility.licenses.length > 0 ? (
												<div>
													{supplierFacility.licenses.map((license, idx) => (
														<div key={idx}>
															<div
																style={{
																	fontSize: 18,
																	display: 'flex',
																	alignItems: 'center',
																}}
															>
																<img
																	width="80px"
																	height="80px"
																	style={{ marginRight: 16 }}
																	src="https://mock-data-assets.s3.amazonaws.com/categories/images/licenseonfile.png"
																/>
																<div>
																	<div>
																		Licensed for {license.licenseType} by {license.issuer}
																	</div>
																	<div>
																		<Button
																			style={{
																				paddingLeft: 0,
																				paddingRight: 0,
																			}}
																			onClick={() => this.handleOpenLicenseModal(license, idx)}
																			type="link"
																		>
																			View Details
																		</Button>
																	</div>
																</div>
															</div>
														</div>
													))}
												</div>
											) : (
												<EmptyState
													height={120}
													headline={null}
													body={
														<div style={{ textAlign: 'center' }}>
															<div style={{ maxWidth: 488, marginBottom: 16 }}>
																Let your customers know you're licensed to do the job.
															</div>
															<Button
																type="primary"
																icon={<PlusOutlined />}
																onClick={() => this.handleEditToggle('editingLicenses')}
															>
																Add licenses
															</Button>
														</div>
													}
												/>
											)}
										</Card>
									</Col>
								</Row>

								<Row className="rowSpacing" gutter={16}>
									<Col span={24}>
										<Card>
											<h5 style={{ display: 'flex', alignItems: 'center' }}>
												Certifications{' '}
												<Button
													style={{ marginLeft: 8 }}
													size="small"
													onClick={() => this.handleEditToggle('editingCertifications')}
												>
													{editingCertifications ? 'Cancel' : 'Edit'}
												</Button>
											</h5>
											{editingCertifications ? (
												<EditSupplierCertificationsForm
													successCallback={() => this.handleEditToggle('editingCertifications')}
													cancelCallback={() => this.handleEditCancel('editingCertifications')}
													supplierFacility={supplierFacility}
													certifications={supplierFacility.certifications}
												/>
											) : supplierFacility.certifications.length > 0 ? (
												<div>
													{supplierFacility.certifications.map((certification, idx) => (
														<div key={idx} style={{ fontSize: 24 }}>
															<div
																style={{
																	fontSize: 18,
																	display: 'flex',
																	alignItems: 'center',
																}}
															>
																<img
																	width="80px"
																	height="80px"
																	style={{ marginRight: 16 }}
																	src="https://mock-data-assets.s3.amazonaws.com/categories/images/certificateonfile.png"
																/>
																<div>
																	<div>Certified {certification.certificationType}</div>
																	<div>
																		<Button
																			style={{
																				paddingLeft: 0,
																				paddingRight: 0,
																			}}
																			onClick={() =>
																				this.handleOpenCertificationModal(certification, idx)
																			}
																			type="link"
																		>
																			View Details
																		</Button>
																	</div>
																</div>
															</div>
														</div>
													))}
												</div>
											) : (
												<EmptyState
													height={120}
													headline={null}
													body={
														<div style={{ textAlign: 'center' }}>
															<div style={{ maxWidth: 488, marginBottom: 16 }}>
																Diverse supplier? Environmentally friendly?
																<br />
																Upload certifications let your customers know.
															</div>
															<Button
																type="primary"
																icon={<PlusOutlined />}
																onClick={() => this.handleEditToggle('editingCertifications')}
															>
																Add certifications
															</Button>
														</div>
													}
												/>
											)}
										</Card>
									</Col>
								</Row>

								<Row className="rowSpacing" gutter={16}>
									<Col span={24}>
										<Card>
											<h5 style={{ display: 'flex', alignItems: 'center' }}>
												Frequently asked questions{' '}
												<Button
													style={{ marginLeft: 8 }}
													size="small"
													onClick={() => this.handleEditToggle('editingFAQ')}
												>
													{editingFAQ ? 'Cancel' : 'Edit'}
												</Button>
											</h5>
											{editingFAQ ? (
												<EditSupplierFAQForm
													successCallback={() => this.handleEditToggle('editingFAQ')}
													cancelCallback={() => this.handleEditCancel('editingFAQ')}
													supplierFacility={supplierFacility}
													faqs={supplierFacility.faqs}
												/>
											) : supplierFacility.faqs && supplierFacility.faqs.length > 0 ? (
												supplierFacility.faqs.map((faq, idx) => (
													<FAQRowDisplay style={{ marginBottom: 40 }} key={idx} faq={faq} />
												))
											) : (
												<EmptyState
													height={120}
													headline={null}
													body={
														<div style={{ textAlign: 'center' }}>
															<div style={{ maxWidth: 488, marginBottom: 16 }}>
																You haven't added any answers to frequently asked questions yet.
															</div>
															<Button
																type="primary"
																icon={<PlusOutlined />}
																onClick={() => this.handleEditToggle('editingFAQ')}
															>
																Build your FAQ
															</Button>
														</div>
													}
												/>
											)}
										</Card>
									</Col>
								</Row>

								{/*<Row className="rowSpacing" gutter={16}>*/}
								{/*    <Col span={24}>*/}
								{/*        <Card>*/}
								{/*            <h5>Reviews</h5>*/}
								{/*            {*/}
								{/*                reviews.length > 0 ? (*/}
								{/*                    <div>*/}
								{/*                        <div>*/}
								{/*                            {*/}
								{/*                                reviews.map((review, idx) => (*/}
								{/*                                    <div key={review.id}*/}
								{/*                                         style={{marginBottom: 24}}>*/}
								{/*                                        <SupplierReview*/}
								{/*                                            userType="buyer"*/}
								{/*                                            */}
								{/*                                            review={review}*/}
								{/*                                        />*/}
								{/*                                    </div>*/}
								{/*                                ))*/}
								{/*                            }*/}
								{/*                        </div>*/}
								{/*                    </div>*/}
								{/*                ) : (*/}
								{/*                    <EmptyState*/}
								{/*                        height={120}*/}
								{/*                        graphic={null}*/}
								{/*                        headline={null}*/}
								{/*                        body={*/}
								{/*                            <div style={{textAlign: 'center'}}>*/}
								{/*                                <div style={{maxWidth: 488, marginBottom: 16}}>*/}
								{/*                                    You haven't received any reviews yet.*/}
								{/*                                </div>*/}
								{/*                            </div>*/}
								{/*                        }*/}
								{/*                    />*/}
								{/*                )*/}
								{/*            }*/}
								{/*        </Card>*/}
								{/*    </Col>*/}
								{/*</Row>*/}
							</Col>

							<Col span={8}>
								<Row style={{ margin: '0 -8px' }} gutter={16}>
									<Col span={24}>
										<Row className="rowSpacing" gutter={16}>
											<Col span={24}>
												<Card bodyStyle={{ padding: 0 }}>
													{supplierFacility.longitude && supplierFacility.latitude ? (
														<div style={{ height: 240, position: 'relative' }}>
															<Mapbox
																longitude={supplierFacility.longitude}
																latitude={supplierFacility.latitude}
																circleRadiusInMiles={supplierFacility.radiusOfServiceInMiles}
																baseLayerStyle="light"
																zoomLevel={7}
															/>
														</div>
													) : null}
													<div style={{ padding: 24 }}>
														<h5 style={{ display: 'flex', alignItems: 'center' }}>
															Contact info{' '}
															<Button
																style={{ marginLeft: 8 }}
																size="small"
																onClick={() => this.handleEditToggle('editingContactInformation')}
															>
																{editingContactInformation ? 'Cancel' : 'Edit'}
															</Button>
														</h5>
														{editingContactInformation ? (
															<EditSupplierContactInformationForm
																successCallback={() =>
																	this.handleEditToggle('editingContactInformation')
																}
																cancelCallback={() =>
																	this.handleEditCancel('editingContactInformation')
																}
																supplierFacility={supplierFacility}
																primaryAddress={supplierFacility.primaryAddress}
																primaryContactEmail={supplierFacility.primaryContactEmail}
																radiusOfServiceInMiles={supplierFacility.radiusOfServiceInMiles}
																website={supplierFacility.website}
																primaryPhone={supplierFacility.primaryPhone}
																longitude={supplierFacility.longitude}
																latitude={supplierFacility.latitude}
															/>
														) : (
															<div>
																<KeyValueDisplay keyWidth="33%" keyValueStore={contactInfo} />
															</div>
														)}
													</div>
												</Card>
											</Col>
										</Row>
										<Row className="rowSpacing" gutter={16}>
											<Col span={24}>
												<Card>
													<h5 style={{ display: 'flex', alignItems: 'center' }}>
														Services provided{' '}
														<Button
															style={{ marginLeft: 8 }}
															size="small"
															onClick={() => this.handleEditToggle('editingSpendCategories')}
														>
															{editingSpendCategories ? 'Cancel' : 'Edit'}
														</Button>
													</h5>
													<Modal
														visible={editingSpendCategories}
														width={'80%'}
														style={{ maxWidth: '1000px' }}
														onCancel={() => this.handleEditCancel('editingSpendCategories')}
														footer={null}
														closable={true}
													>
														<EditSupplierSpendCategoriesForm
															successCallback={() =>
																this.handleEditToggle('editingSpendCategories')
															}
															cancelCallback={() => this.handleEditCancel('editingSpendCategories')}
															supplierFacility={supplierFacility}
														/>
													</Modal>
													<div
														style={{
															wordBreak: 'normal',
															whiteSpace: 'normal',
															fontSize: 16,
														}}
													>
														{supplierFacility.categoryNames.length > 0 ? (
															supplierFacility.categoryNames
																.split(',')
																.map((categoryName) => capitalizeEachWord(categoryName))
																.join(', ')
														) : (
															<EmptyState
																height={120}
																headline={null}
																body={
																	<div style={{ textAlign: 'center' }}>
																		<div style={{ maxWidth: 488, marginBottom: 16 }}>
																			You haven't listed any services you provide yet.
																		</div>
																		<Button
																			type="primary"
																			icon={<PlusOutlined />}
																			onClick={() =>
																				this.handleEditToggle('editingSpendCategories')
																			}
																		>
																			List services provided
																		</Button>
																	</div>
																}
															/>
														)}
													</div>
												</Card>
											</Col>
										</Row>
										<Row className="rowSpacing" gutter={16}>
											<Col span={24}>
												<Card>
													<h5 style={{ display: 'flex', alignItems: 'center' }}>
														Currency{' '}
														<Button
															style={{ marginLeft: 8 }}
															size="small"
															onClick={() => this.handleEditToggle('editingCurrency')}
														>
															{editingCurrency ? 'Cancel' : 'Edit'}
														</Button>
													</h5>
													<Modal
														visible={editingCurrency}
														width={'80%'}
														style={{ maxWidth: '1000px' }}
														onCancel={() => this.handleEditCancel('editingCurrency')}
														footer={null}
														closable={true}
													>
														<EditCurrencyForm
															successCallback={() => this.handleEditToggle('editingCurrency')}
															cancelCallback={() => this.handleEditCancel('editingCurrency')}
															supplierFacility={supplierFacility}
															currencyId={nullSafeGet('currencyId', supplierFacility)}
														/>
													</Modal>
													<div
														style={{
															wordBreak: 'normal',
															whiteSpace: 'normal',
															fontSize: 16,
														}}
													>
														{currencyString}
													</div>
												</Card>
											</Col>
										</Row>
										<Row className="rowSpacing" gutter={16}>
											<Col span={24}>
												<Card>
													<h5 style={{ display: 'flex', alignItems: 'center' }}>
														Business hours{' '}
														<Button
															style={{ marginLeft: 8 }}
															size="small"
															onClick={() => this.handleEditToggle('editingBusinessHours')}
														>
															{editingBusinessHours ? 'Cancel' : 'Edit'}
														</Button>
													</h5>
													{editingBusinessHours ? (
														<EditSupplierOperatingHoursForm
															successCallback={() => this.handleEditToggle('editingBusinessHours')}
															cancelCallback={() => this.handleEditCancel('editingBusinessHours')}
															supplierFacility={supplierFacility}
															operatingHours={supplierFacility.operatingHours}
														/>
													) : (
														<div style={{ fontSize: 16 }}>
															{operatingHours.timeZoneName ? (
																<div
																	style={{
																		color: 'rgba(0,0,0,0.45)',
																		marginBottom: 16,
																	}}
																>
																	{operatingHours.timeZoneName}
																</div>
															) : null}
															<table>
																{DAYS_OF_THE_WEEK.map((dayOfTheWeek, idx) => {
																	const fromTime = nullSafeGet(
																		`${dayOfTheWeek}.from`,
																		operatingHours
																	);
																	const toTime = nullSafeGet(`${dayOfTheWeek}.to`, operatingHours);
																	const hasHours = fromTime && toTime;

																	return (
																		<tr key={idx} style={{ lineHeight: '32px' }}>
																			<td>{dayOfTheWeek}</td>
																			<td style={{ paddingLeft: 32 }}>
																				{hasHours
																					? `${fromTime} to ${toTime}`
																					: 'No hours available'}
																			</td>
																		</tr>
																	);
																})}
															</table>
														</div>
													)}
												</Card>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				)}
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	currentUser: state.session.currentUser,
	supplierFacilityReviews: state.supplier_facility_reviews,
	supplierFacilitiesFetching: state.supplier_facilities.fetching,
	supplierFacility: state.supplier_facilities.detail,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	fetchSupplierFacility: (id) => dispatch(supplierFacilitiesRestCrudThunksForSupplier.readOne(id)),
	fetchSupplierFacilityReviews: (supplierFacilityId) =>
		dispatch(
			supplierFacilityReviewsRestCrudThunksForSupplier.read(
				{ supplierFacilityId },
				'supplierDetailReviews'
			)
		),
	updateSupplier: (supplierFacility) =>
		dispatch(supplierFacilitiesRestCrudThunksForSupplier.update(supplierFacility)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SupplierSuppliersDetailPage)
);

import React, { FC, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Select, Tooltip, message } from 'antd';
import { EmptyState } from '../empty_state/EmptyState';
import { vendorsRestCrudThunksForSupplier } from '../../thunks/vendors_thunks';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import { usersRestCrudThunksForSupplier } from '../../thunks/users_thunks';
import { getContactName } from '../../utils/DataFormatterUtils';
import {
	FILTER_CONFIG_NAMES,
	FILTER_FIELD_TYPE,
	FILTER_VALUE_TYPE,
} from '../../utils/DataConstants';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { partsRestCrudThunksForSupplier } from '../../thunks/parts_thunks';
import { equipmentTypesRestCrudThunksForSupplier } from '../../thunks/equipment_types_thunks';
import {
	purchaseRequestsLiteRestCrudThunksForSupplier,
	purchaseRequestsRestCrudThunksForSupplier,
} from '../../thunks/purchase_requests_thunks';
import {
	fetchWithDifferntSearchName,
	getPurchaseOrderId,
	nullSafeGet,
	nullSafeGetOrElse,
	roundOffToDecimal,
	stopDefaultEvents,
} from '../../utils/DataAccessUtils';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import {
	downloadPOLineItemsCSVForSupplier,
	purchaseOrderLineItemsRestCrudThunksForSupplier,
} from '../../thunks/purchase_order_line_items_thunks';
import { PURCHASE_ORDER_LINE_ITEMS_CRUD_ACTION_CREATORS } from '../../actions/purchase_order_line_items_actions';
import PurchaseOrderStatusDisplay from '../purchase_order_status_display/PurchaseOrderStatusDisplay';
import HyperLink from '../common/HyperLink';
import { PURCHASE_ORDER_STATUSES } from '../../constants/PurchaseOrderStatuses';
import { getRecordsForCompositeTargetCollection } from '../../reducers/standard_reducer_utils';
import { purchaseOrderReceiptsRestCrudThunksForSupplier } from '../../thunks/purchase_order_receipt_thunks';
import ReceivePurchaseOrderLineItemModal from '../purchase_orders_detail_details_page/ReceivePurchaseOrderLineItemModal';
import PartEquipmentNameWithType from '../equipment_per_stock_location_row_display/PartEquipmentNameWithType';
import { locationsRestCrudThunksForSupplier } from '../../thunks/locations_thunks';
import UpdateSerialAmdAssetNumbersModal from '../purchase_orders_detail_details_page/UpdateSerialAmdAssetNumbersModal';
import {
	createMultipleEpslAsDeletedForSupplier,
	equipmentPerStockLocationsRestCrudThunksForSupplier,
} from '../../thunks/equipment_per_stock_locations_thunks';
import {
	assetsRestCrudThunksForSupplier,
	createMultipleAssetsAsDeletedForSupplier,
} from '../../thunks/assets_thunks';
import LocationStockLocationNameWithType from '../equipment_per_stock_location_row_display/LocationStockLocationNameWithType';
import {
	getPOLineItemDisabledMessage,
	isPOLineItemWithoutAssetTypeIdToLocation,
} from '../purchase_requests_index_page/po_create_utils';
import { DownloadOutlined } from '@ant-design/icons';
import { renderEquipmenTypeSelect } from '../purchase_order_form/ReplaceEquipmentLineItemModal';

const TC_NAME = 'purchaseOrderLineItemsIndex';
const PO_LINE_ITEMS_ADVANCED_FILTERS = 'poLineItemsAdvancedFilters';
const BACK_TEXT = 'Back to Orders';

const PurchaseOrderLineItemsIndexPage: FC<any> = ({
	clearAndUpdateFilters,
	purchaseOrderLineItems,
	updateFilters,
	vendors,
	fetchVendors,
	fetchMultipleVendors,
	stockLocations,
	fetchStockLocations,
	fetchMultipleStockLocations,
	users,
	fetchUsers,
	fetchMultipleUsers,
	equipmentTypes,
	fetchEquipmentTypes,
	fetchMultipleEquipments,
	parts,
	fetchParts,
	fetchMultipleParts,
	purchaseRequests,
	fetchPurchaseRequests,
	fetchMultiplePurchaseRequests,
	createPOReceiptBulk,
	fetchPurchaseOrderLineItems,
	locations,
	fetchLocations,
	fetchMultipleLocations,
	fetchEquipmentsPerStockLocations,
	fetchAssets,
	exportPOLineItemsCSV,
	createEpslsBulkAsDeleted,
	createAssetsBulkAsDeleted,
}): React.ReactElement => {
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [receivingAll, setReceivingAll] = useState(false);
	const [currentLineItem, setCurrentLineItem] = useState(null);
	const [receiveMdoalVisible, setReceiveModalVisible] = useState(false);
	const [isReturn, setIsReturn] = useState(false);

	const [equipmentSerialNumberVisible, setEquipmentSerialNumberVisible] = useState(false);
	const [createdEntities, setCreatedEntities] = useState([]);
	const [receivingEntityType, setReceivingEntityType] = useState(null);
	const [receiptEntities, setReceiptEntities] = useState<any>({});

	const [isLocationSelect, setIsLocationSelect] = useState(false);
	const [isStockLocationSelect, setIsStockLocationSelect] = useState(false);

	const [exporting, setExporting] = useState(false);

	const filterConfig = useMemo(
		() => [
			{
				fieldName: 'isEquipmentLine',
				type: FILTER_FIELD_TYPE.RADIO_GROUP,
				items: [
					{
						label: 'All',
						value: 'undefined',
					},
					{
						label: 'Parts',
						value: 'false',
					},
					{
						label: 'Equipment',
						value: 'true',
					},
				],
				defaultValue: undefined,
				showOutsideDropdown: true,
			},
			{
				fieldName: 'status',
				type: FILTER_FIELD_TYPE.RADIO_GROUP,
				items: [
					{
						label: 'All',
						value: 'undefined',
					},
					{
						label: 'Ordered',
						value: 'ordered',
					},
					{
						label: 'Partially Received',
						value: PURCHASE_ORDER_STATUSES.partially_received,
					},
					{
						label: 'Received',
						value: PURCHASE_ORDER_STATUSES.received,
					},
				],
				defaultValue: undefined,
				showOutsideDropdown: true,
			},
			{
				label: 'Stock Location',
				fieldName: 'stockLocationIds',
				mode: 'multiple',
				stateSlice: stockLocations,
				targetCollectionName: PO_LINE_ITEMS_ADVANCED_FILTERS,
				fetch: fetchStockLocations,
				fetchMultiple: fetchMultipleStockLocations,
			},
			{
				label: 'Location',
				fieldName: 'locationIds',
				mode: 'multiple',
				stateSlice: locations,
				targetCollectionName: PO_LINE_ITEMS_ADVANCED_FILTERS,
				fetch: fetchLocations,
				fetchMultiple: fetchMultipleLocations,
			},
			{
				label: 'Vendor',
				fieldName: 'partEquipmentVendorIds',
				mode: 'multiple',
				stateSlice: vendors,
				targetCollectionName: PO_LINE_ITEMS_ADVANCED_FILTERS,
				fetch: fetchVendors,
				fetchMultiple: fetchMultipleVendors,
			},
			{
				label: 'Part',
				fieldName: 'partIds',
				mode: 'multiple',
				stateSlice: parts,
				targetCollectionName: PO_LINE_ITEMS_ADVANCED_FILTERS,
				fetch: fetchParts,
				fetchMultiple: fetchMultipleParts,
				renderItem: (part) => (
					<div className="flex flex-row items-center">
						<div>{part.name}</div>
						<div>
							{part.partNumber && (
								<span className="text-gray-400">&nbsp;{`- #${part.partNumber}`}</span>
							)}
						</div>
					</div>
				),
			},
			{
				label: 'Equipment Type',
				fieldName: 'equipmentTypeIds',
				mode: 'multiple',
				stateSlice: equipmentTypes,
				targetCollectionName: PO_LINE_ITEMS_ADVANCED_FILTERS,
				fetch: fetchWithDifferntSearchName(fetchEquipmentTypes, 'search'),
				fetchMultiple: fetchMultipleEquipments,
				renderRecord: renderEquipmenTypeSelect,
				component: OWAsyncSelect,
			},
			{
				label: 'Purchase Request',
				fieldName: 'purchaseRequestId',
				stateSlice: purchaseRequests,
				targetCollectionName: PO_LINE_ITEMS_ADVANCED_FILTERS,
				fetch: fetchWithDifferntSearchName(fetchPurchaseRequests, 'search'),
				fetchMultiple: fetchMultiplePurchaseRequests,
				renderRecord: (pr) => (
					<Select.Option key={pr.id} value={pr.id}>
						<Ellipsis key={pr.id} tooltip={true} length={50}>
							{`#${pr.id} - ${pr.name}`}
						</Ellipsis>
					</Select.Option>
				),
				component: OWAsyncSelect,
			},
			{
				label: 'Ordered by',
				fieldName: 'createdByEmails',
				mode: 'multiple',
				stateSlice: users,
				valueType: FILTER_VALUE_TYPE.STRING,
				valueAccessor: (user) => nullSafeGet('contact.email', user),
				targetCollectionName: PO_LINE_ITEMS_ADVANCED_FILTERS,
				fetch: fetchUsers,
				fetchMultiple: fetchMultipleUsers,
				renderItem: (user) => getContactName(user.contact),
			},
			{
				label: 'Ordered Between',
				labelPrefix: 'Ordered',
				fieldName: FILTER_CONFIG_NAMES.CREATED_AT,
				type: FILTER_FIELD_TYPE.DATE_RANGE,
			},
		],
		[
			equipmentTypes,
			fetchEquipmentTypes,
			fetchLocations,
			fetchMultipleEquipments,
			fetchMultipleLocations,
			fetchMultipleParts,
			fetchMultiplePurchaseRequests,
			fetchMultipleStockLocations,
			fetchMultipleUsers,
			fetchMultipleVendors,
			fetchParts,
			fetchPurchaseRequests,
			fetchStockLocations,
			fetchUsers,
			fetchVendors,
			locations,
			parts,
			purchaseRequests,
			stockLocations,
			users,
			vendors,
		]
	);

	const hideSerialNumberUpdate = useCallback(() => setEquipmentSerialNumberVisible(false), []);

	const getStatSliceProp = useCallback(
		(propName) => nullSafeGetOrElse(`${TC_NAME}.${propName}`, purchaseOrderLineItems, {}),
		[purchaseOrderLineItems]
	);

	const refreshLineItems = useCallback(() => {
		fetchPurchaseOrderLineItems(
			{},
			TC_NAME,
			getStatSliceProp('pagination'),
			getStatSliceProp('sorting'),
			getStatSliceProp('filters')
		);
	}, [fetchPurchaseOrderLineItems, getStatSliceProp]);

	const isSomeQuantityReceived = useCallback(
		(record) =>
			record.isEquipmentLine ? record.equipmentReceivedQuantity > 0 : record.partReceivedQuantity,
		[]
	);

	const showReceiveLineItem = useCallback(
		(record, isReturn = false) =>
			(e) => {
				stopDefaultEvents(e);
				setIsReturn(isReturn);
				setCurrentLineItem(record);
				setReceiveModalVisible(true);
			},
		[]
	);

	const hideReceiveLineItem = useCallback(() => {
		setReceiveModalVisible(false);
		setCurrentLineItem(null);
	}, []);

	const getCreatedEpsls = useCallback(
		(receiptIds, pageSize) => {
			return fetchEquipmentsPerStockLocations({ receiptIds }, null, {
				current: 1,
				pageSize: Math.abs(pageSize),
			});
		},
		[fetchEquipmentsPerStockLocations]
	);

	const getEpslAndShowSerialNumbers = useCallback(
		(receiptIds, quantity) => {
			return new Promise((resolve) => {
				getCreatedEpsls(receiptIds, quantity).then((res) => {
					if (nullSafeGet('0', res)) {
						setCreatedEntities(res);
						setReceivingEntityType('equipment');
						setEquipmentSerialNumberVisible(true);
					} else {
						refreshLineItems();
					}
					hideReceiveLineItem();
					resolve(true);
				});
			});
		},
		[getCreatedEpsls, hideReceiveLineItem, refreshLineItems]
	);

	const getCreatedAssets = useCallback(
		(receiptIds, pageSize) => {
			return fetchAssets({ receiptIds }, null, {
				current: 1,
				pageSize: Math.abs(pageSize),
			});
		},
		[fetchAssets]
	);

	const getAssetsAndShowSerialNumbers = useCallback(
		(receiptIds, quantity) => {
			return new Promise((resolve) => {
				getCreatedAssets(receiptIds, quantity).then((res) => {
					if (nullSafeGet('0', res)) {
						setCreatedEntities(res);
						setReceivingEntityType('asset');
						setEquipmentSerialNumberVisible(true);
					} else {
						refreshLineItems();
					}
					hideReceiveLineItem();
					resolve(true);
				});
			});
		},
		[getCreatedAssets, hideReceiveLineItem, refreshLineItems]
	);

	const onPOReceiptSuccess = useCallback(
		(receipt) => {
			message.success(`${receipt.receivedQuantity > 0 ? 'Received' : 'Returned'} successfully!`);
			refreshLineItems();
			hideReceiveLineItem();
		},
		[hideReceiveLineItem, refreshLineItems]
	);

	const onEquipmentCreateSuccess = useCallback(
		(receipt, deletedEntities) => {
			hideReceiveLineItem();
			setReceiptEntities([receipt]);
			setReceivingEntityType(receipt.assetTypeId ? 'asset' : 'equipment');
			setCreatedEntities(deletedEntities);
			setEquipmentSerialNumberVisible(true);
		},
		[hideReceiveLineItem]
	);

	const onUpdateSerialNumberSuccess = useCallback(() => {
		setCreatedEntities([]);
		setEquipmentSerialNumberVisible(false);
		refreshLineItems();
	}, [refreshLineItems]);

	const canReceive = useMemo(() => selectedRowKeys.length > 0, [selectedRowKeys.length]);

	const onSelectChange = useCallback((newSelectedRowKeys: React.Key[], selectedRows) => {
		setSelectedRowKeys(newSelectedRowKeys);
		if (selectedRows.length > 0) {
			setIsLocationSelect(!!nullSafeGet('0.supplierPurchaseOrder.locationId', selectedRows));
			setIsStockLocationSelect(
				!!nullSafeGet('0.supplierPurchaseOrder.stockLocationId', selectedRows)
			);
		} else {
			setIsLocationSelect(false);
			setIsStockLocationSelect(false);
		}
	}, []);

	const getRecordFromTargetCollection = useCallback(
		(id) => {
			const records = getRecordsForCompositeTargetCollection(purchaseOrderLineItems, TC_NAME);
			return records.find((_) => _.id === id);
		},
		[purchaseOrderLineItems]
	);

	const CustomRow = (props) => {
		const id = props['data-row-key'];
		const record = getRecordFromTargetCollection(id);
		const message = getPOLineItemDisabledMessage({
			record,
			isLocationSelect,
			isStockLocationSelect,
		});
		return message ? (
			<Tooltip title={message} placement="topLeft">
				<tr {...props} />
			</Tooltip>
		) : (
			<tr {...props} />
		);
	};

	const rowSelection = useMemo(
		() => ({
			selectedRowKeys,
			onChange: onSelectChange,
			getCheckboxProps: (record) => ({
				disabled:
					record.status === PURCHASE_ORDER_STATUSES.received ||
					record.status === PURCHASE_ORDER_STATUSES.cancelled ||
					isPOLineItemWithoutAssetTypeIdToLocation(record) ||
					(isLocationSelect && !nullSafeGet('supplierPurchaseOrder.locationId', record)) ||
					(isStockLocationSelect && !nullSafeGet('supplierPurchaseOrder.stockLocationId', record)),
			}),
		}),
		[isLocationSelect, isStockLocationSelect, onSelectChange, selectedRowKeys]
	);

	const onSelectCancel = useCallback(() => {
		setSelectedRowKeys([]);
		setReceivingAll(false);
		setIsLocationSelect(false);
		setIsStockLocationSelect(false);
	}, []);

	const selectedRecords = useMemo(() => {
		const records = getRecordsForCompositeTargetCollection(purchaseOrderLineItems, TC_NAME);
		return records.filter((_) => selectedRowKeys.includes(_.id));
	}, [purchaseOrderLineItems, selectedRowKeys]);

	const partReceipts = useMemo(
		() =>
			selectedRecords
				.filter((_) => !_.isEquipmentLine)
				.map((_) => ({
					purchaseOrderLineItemId: _.id,
					purchaseOrderId: _.supplierPurchaseOrderId,
					receiptNumber: '',
					pricePerQuantity: roundOffToDecimal(
						nullSafeGetOrElse('partCost', _, 0) / nullSafeGetOrElse('partQuantity', _, 1)
					),
					partId: _.partId,
					receivedQuantity: _.partQuantity - nullSafeGetOrElse('partReceivedQuantity', _, 0),
				})),
		[selectedRecords]
	);

	const equipmentReceipts = useMemo(
		() =>
			selectedRecords
				.filter((_) => _.isEquipmentLine)
				.map((_) => ({
					purchaseOrderLineItemId: _.id,
					purchaseOrderId: _.supplierPurchaseOrderId,
					receiptNumber: '',
					pricePerQuantity: roundOffToDecimal(
						nullSafeGetOrElse('equipmentCost', _, 0) / nullSafeGetOrElse('equipmentQuantity', _, 1)
					),
					...(nullSafeGet('equipmentType.assetTypeId', _) && {
						assetTypeId: nullSafeGet('equipmentType.assetTypeId', _),
					}),
					equipmentTypeId: _.equipmentTypeId,
					receivedQuantity:
						_.equipmentQuantity - nullSafeGetOrElse('equipmentReceivedQuantity', _, 0),
				})),
		[selectedRecords]
	);

	const createDeletedAssetsBulk = useCallback(() => {
		const allAssetEntities = selectedRecords
			.filter((_) => _.isEquipmentLine)
			.flatMap((lineItem) => {
				const quantity =
					lineItem.equipmentQuantity - nullSafeGetOrElse('equipmentReceivedQuantity', lineItem, 0);
				const entities = Array.from(Array(quantity).keys()).map((_) => ({
					locationId: nullSafeGet('supplierPurchaseOrder.locationId', lineItem),
					name: nullSafeGetOrElse('equipmentType.modelName', lineItem, ''),
					isActive: true,
					images: [],
					...(nullSafeGet('equipmentType.assetTypeId', lineItem) && {
						assetTypeId: nullSafeGet('equipmentType.assetTypeId', lineItem),
					}),
					assetTypeDetails: [],
					isLeased: false,
					buyerCompanyId: nullSafeGet('supplierPurchaseOrder.location.buyerCompanyId', lineItem),
				}));
				return entities;
			});

		return createAssetsBulkAsDeleted(allAssetEntities);
	}, [createAssetsBulkAsDeleted, selectedRecords]);

	const createDeletedEpslsBulk = useCallback(() => {
		const allEpslEntities = selectedRecords
			.filter((_) => _.isEquipmentLine)
			.flatMap((lineItem) => {
				const quantity =
					lineItem.equipmentQuantity - nullSafeGetOrElse('equipmentReceivedQuantity', lineItem, 0);
				const entities = Array.from(Array(quantity).keys()).map((_) => ({
					stockLocationId: nullSafeGet('supplierPurchaseOrder.stockLocationId', lineItem),
					equipmentTypeId: lineItem.equipmentTypeId,
					name: nullSafeGetOrElse('equipmentType.modelName', lineItem, ''),
					isRental: false,
					vendorId: nullSafeGet('supplierPurchaseOrder.partEquipmentVendorId', lineItem),
					images: [],
					supplierFacilityId: lineItem.supplierFacilityId,
					supplierCompanyId: lineItem.supplierCompanyId,
				}));
				return entities;
			});

		return createEpslsBulkAsDeleted(allEpslEntities);
	}, [createEpslsBulkAsDeleted, selectedRecords]);

	const onReceiveAll = useCallback(() => {
		const receiptEntities = [...partReceipts, ...equipmentReceipts];
		setReceivingAll(true);

		const promiseToCall = isLocationSelect ? createDeletedAssetsBulk : createDeletedEpslsBulk;

		if (equipmentReceipts.length > 0) {
			promiseToCall()
				.then((res) => {
					setCreatedEntities(res);
					setReceivingEntityType(isLocationSelect ? 'asset' : 'equipment');
					setReceiptEntities(receiptEntities);
					setEquipmentSerialNumberVisible(true);
				})
				.catch((err) => message.error(err))
				.finally(() => setReceivingAll(false));
		} else {
			createPOReceiptBulk(receiptEntities)
				.then((res) => {
					message.success('Selected items received successfully!');
					onSelectCancel();
					refreshLineItems();
				})
				.catch((err) => message.error(err))
				.finally(() => setReceivingAll(false));
		}
	}, [
		createDeletedAssetsBulk,
		createDeletedEpslsBulk,
		createPOReceiptBulk,
		equipmentReceipts,
		isLocationSelect,
		onSelectCancel,
		partReceipts,
		refreshLineItems,
	]);

	const columns = useMemo(
		() => [
			{
				title: 'PO',
				dataIndex: 'supplierPurchaseOrder',
				render: (_) => (
					<HyperLink
						text={getPurchaseOrderId(_)}
						newTabPath={`/supplier/purchaseOrders/detail/${nullSafeGet('id', _)}/details`}
						backLinkText={BACK_TEXT}
					/>
				),
			},
			{
				title: 'ID',
				render: (_, record) =>
					record.isEquipmentLine
						? nullSafeGetOrElse('equipmentType.equipmentTypeNumber', record, '--')
						: nullSafeGetOrElse('part.partNumber', record, '--'),
			},
			{
				title: 'Name',
				render: (_, record) => <PartEquipmentNameWithType record={record} backText={BACK_TEXT} />,
			},
			{
				title: 'Location',
				dataIndex: 'supplierPurchaseOrder',
				render: (_) => <LocationStockLocationNameWithType record={_} backLinkText={BACK_TEXT} />,
			},
			{
				title: 'Ordered Qty',
				dataIndex: 'equipmentQuantity',
				render: (_, record) =>
					record.isEquipmentLine
						? nullSafeGetOrElse('equipmentQuantity', record, 0)
						: nullSafeGetOrElse('partQuantity', record, 0),
			},
			{
				title: 'Received Qty',
				render: (_, record) =>
					record.isEquipmentLine
						? nullSafeGetOrElse('equipmentReceivedQuantity', record, 0)
						: nullSafeGetOrElse('partReceivedQuantity', record, 0),
			},
			{
				title: 'Status',
				render: (_, record) => <PurchaseOrderStatusDisplay status={record.status} />,
			},
			{
				title: canReceive ? (
					<div className="flex-start flex flex-row">
						<Button onClick={onSelectCancel} size="large">
							Cancel
						</Button>
						<Button
							loading={receivingAll}
							className="ml-2"
							size="large"
							type="primary"
							onClick={onReceiveAll}
						>
							Bulk Receive
						</Button>
					</div>
				) : null,
				render: (_, record) =>
					record.status !== PURCHASE_ORDER_STATUSES.cancelled ? (
						<>
							<Button
								disabled={
									record.status === PURCHASE_ORDER_STATUSES.received ||
									record.status === PURCHASE_ORDER_STATUSES.cancelled
								}
								className="mr-2"
								onClick={showReceiveLineItem(record)}
							>
								Receive
							</Button>
							<Button
								disabled={!isSomeQuantityReceived(record)}
								onClick={showReceiveLineItem(record, true)}
							>
								Return
							</Button>
						</>
					) : null,
			},
		],
		[
			canReceive,
			isSomeQuantityReceived,
			onReceiveAll,
			onSelectCancel,
			receivingAll,
			showReceiveLineItem,
		]
	);

	const downloadCSV = useCallback(() => {
		const filters = nullSafeGetOrElse(`${TC_NAME}.filters`, purchaseOrderLineItems, {});
		setExporting(true);
		exportPOLineItemsCSV(filters).finally(() => setExporting(false));
	}, [exportPOLineItemsCSV, purchaseOrderLineItems]);

	return (
		<>
			<PaginatedReduxTableWithHeader
				targetCollectionName={TC_NAME}
				updateFilters={updateFilters}
				stateSlice={purchaseOrderLineItems}
				clearAndUpdateFilters={clearAndUpdateFilters}
				filterConfig={filterConfig}
				entityCollectionName="purchase_order_line_items"
				tableColumns={columns}
				fetchData={purchaseOrderLineItemsRestCrudThunksForSupplier.read}
				rightActions={
					<Button
						size="large"
						icon={<DownloadOutlined translate="" />}
						className="inline-block-visible-md"
						loading={exporting}
						onClick={downloadCSV}
					>
						<span className="inline-block-visible-xl">Export CSV</span>
					</Button>
				}
				components={{
					body: {
						row: CustomRow,
					},
				}}
				rowSelection={rowSelection}
				showHeader
				emptyState={
					<EmptyState
						graphic={
							<img
								alt="No Data"
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
							/>
						}
						headline={'No purchase orders found'}
					/>
				}
			/>
			{receiveMdoalVisible && (
				<ReceivePurchaseOrderLineItemModal
					lineItem={currentLineItem}
					isReturn={isReturn}
					onCancel={hideReceiveLineItem}
					onPOReceiptSuccess={onPOReceiptSuccess}
					onEquipmentCreateSuccess={onEquipmentCreateSuccess}
				/>
			)}
			{equipmentSerialNumberVisible && (
				<UpdateSerialAmdAssetNumbersModal
					receiptEntities={receiptEntities}
					createdEntities={createdEntities}
					entityType={receivingEntityType}
					onSuccess={onUpdateSerialNumberSuccess}
					onCancel={hideSerialNumberUpdate}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
	purchaseOrderLineItems: state.purchase_order_line_items,
	stockLocations: state.stock_locations,
	users: state.users,
	vendors: state.vendors,
	parts: state.parts,
	equipmentTypes: state.equipment_types,
	purchaseRequests: state.purchase_requests,
	locations: state.locations,
});

const mapDispatchToProps = (dispatch) => ({
	updateFilters: (filters, targetCollection) =>
		dispatch(
			PURCHASE_ORDER_LINE_ITEMS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)
		),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			PURCHASE_ORDER_LINE_ITEMS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(
				filters,
				targetCollectionName
			)
		),
	fetchVendors: (params, targetCollectionName) =>
		dispatch(
			vendorsRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName
			)
		),
	fetchMultipleVendors: (ids, targetCollectionName) =>
		dispatch(vendorsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchMultipleStockLocations: (ids, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchStockLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			stockLocationsRestCrudThunksForSupplier.readLite(
				{ ...params, no_pagination: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleUsers: (ids, targetCollectionName) =>
		dispatch(usersRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchUsers: (params, targetCollectionName, pagination, sorting, filters, addToTargetCollection) =>
		dispatch(
			usersRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleParts: (ids, targetCollectionName) =>
		dispatch(partsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchParts: (params, targetCollectionName, pagination, sorting, filters, addToTargetCollection) =>
		dispatch(
			partsRestCrudThunksForSupplier.readLite(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleEquipments: (ids, targetCollectionName) =>
		dispatch(equipmentTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchEquipmentTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			equipmentTypesRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultiplePurchaseRequests: (ids, targetCollectionName) =>
		dispatch(purchaseRequestsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchPurchaseRequests: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			purchaseRequestsLiteRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	createPOReceiptBulk: (entities) =>
		dispatch(purchaseOrderReceiptsRestCrudThunksForSupplier.createBulk(entities)),
	fetchPurchaseOrderLineItems: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			purchaseOrderLineItemsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			locationsRestCrudThunksForSupplier.readLite(
				{ ...params, no_pagination: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchEquipmentsPerStockLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			equipmentPerStockLocationsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchAssets: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			assetsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	exportPOLineItemsCSV: (params, filters) =>
		dispatch(downloadPOLineItemsCSVForSupplier(params, filters)),
	createEpslsBulkAsDeleted: (entities) =>
		dispatch(createMultipleEpslAsDeletedForSupplier(entities)),
	createAssetsBulkAsDeleted: (entities) =>
		dispatch(createMultipleAssetsAsDeletedForSupplier(entities)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderLineItemsIndexPage)
);

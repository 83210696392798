import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Alert } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { connect } from 'react-redux';
import FileUploader from '../file_uploader/FileUploader';
import { Document, Page, pdfjs } from 'react-pdf';
import { generateInvoicePDFForSupplier } from '../../thunks/invoices_thunks';
import { imageUploadValidation, normFile } from '../../utils/ImageUtils';
import { getProtectedImageUriForSupplier } from '../../utils/FileAccessUtils';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import { retrieveCachedUserDetails } from '../../thunks/session_thunks';
import OWUpload from '../OWUpload';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FormItem = Form.Item;

interface SupplierSendInvoiceByEmailFormProps extends FormComponentProps {
	onSubmit: any;
	onCancel: any;
	visible: boolean;
	createErrors: any;
	updateErrors: any;
	creating: boolean;
	updating: boolean;
	invoiceId: any;
	currentUser: any;
	documents: any;
	checkInImages: any;
	checkOutImages: any;
}

class SupplierSendInvoiceByEmailForm extends React.Component<SupplierSendInvoiceByEmailFormProps> {
	state = {
		numPages: null,
		pageNumber: 1,
		previewVisible: false,
		uploadLoading: false,
		previewImage: null,
		pdfFile: { url: null, httpHeaders: null },
	};

	componentWillReceiveProps(nextProps) {
		if (
			nextProps.invoiceId &&
			(!this.state.pdfFile.url || nextProps.invoiceId !== this.props.invoiceId)
		) {
			this.setState({
				pdfFile: generateInvoicePDFForSupplier(nextProps.invoiceId),
			});
		}
	}

	onDocumentLoadSuccess = ({ numPages }) => {
		this.setState({ numPages });
	};

	handlePreview = (file) => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true,
		});
	};

	handleCancel = () => this.setState({ previewVisible: false });

	handleFileAttachmentUploadChange = (newAttachments) => {
		const { form } = this.props;
		form.setFieldsValue({ documents: newAttachments });
	};

	render() {
		const {
			visible,
			onCancel,
			onSubmit,
			form,
			invoiceId,
			currentUser,
			createErrors,
			updateErrors,
			documents,
			checkInImages,
			checkOutImages,
		} = this.props;
		const { getFieldDecorator } = form;
		const { pageNumber, numPages, previewImage, previewVisible } = this.state;

		const BACKEND_URI = getBackendUri();
		const uploadHeaders = { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token };
		let checkInPhotosInfo;
		let checkOutPhotosInfo;

		if (checkInImages) {
			checkInPhotosInfo = checkInImages.map((p, idx) => {
				const [imgFileId, imgFileName] = p.split('/');
				const thumbUri = getProtectedImageUriForSupplier(imgFileId, imgFileName, 100, 100);
				const fullUri = getProtectedImageUriForSupplier(imgFileId, imgFileName, 400, 400);
				return {
					uid: idx,
					size: 1,
					name: imgFileName,
					filename: imgFileName,
					url: fullUri,
					status: 'done',
					thumbUrl: thumbUri,
					response: { data: { fileName: imgFileName, fileId: imgFileId } },
					type: 'image/jpeg',
				};
			});
		} else {
			checkInPhotosInfo = [];
		}

		if (checkOutImages) {
			checkOutPhotosInfo = checkOutImages.map((p, idx) => {
				const [imgFileId, imgFileName] = p.split('/');
				const thumbUri = getProtectedImageUriForSupplier(imgFileId, imgFileName, 100, 100);
				const fullUri = getProtectedImageUriForSupplier(imgFileId, imgFileName, 400, 400);
				return {
					uid: idx,
					size: 1,
					name: imgFileName,
					filename: imgFileName,
					url: fullUri,
					status: 'done',
					thumbUrl: thumbUri,
					response: { data: { fileName: imgFileName, fileId: imgFileId } },
					type: 'image/jpeg',
				};
			});
		} else {
			checkOutPhotosInfo = [];
		}

		form.getFieldDecorator(`invoiceId`, { initialValue: invoiceId });

		return (
			<Modal
				visible={visible}
				width={800}
				title="Send Invoice by Email"
				okText="Send"
				onCancel={onCancel}
				onOk={onSubmit}
				closable={false}
			>
				<Form layout="vertical" className="sendInvoiceByEmailForm">
					{createErrors.length > 0 ? (
						<FormItem>
							<Alert message={createErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					{updateErrors.length > 0 ? (
						<FormItem>
							<Alert message={updateErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					<FormItem label="Send-to Email" hasFeedback={true} style={{ width: 320 }}>
						{getFieldDecorator('email', {
							rules: [
								{
									type: 'email',
									message: 'The input is not valid email!',
								},
								{
									required: true,
									message: 'Please enter the email of the person you want to send the invoice to',
								},
							],
						})(<Input />)}
					</FormItem>
					{checkInImages.length > 0 ? (
						<FormItem label="Check In Images" hasFeedback={true}>
							{getFieldDecorator(`checkInImages`, {
								valuePropName: 'fileList',
								getValueFromEvent: normFile,
								initialValue: checkInPhotosInfo,
							})(
								<OWUpload
									name="file"
									listType="picture-card"
									className="avatar-uploader"
									multiple={true}
									headers={uploadHeaders}
									onPreview={this.handlePreview}
									action={`${BACKEND_URI}/api/v1/supplier/file/upload`}
									beforeUpload={imageUploadValidation}
								/>
							)}
						</FormItem>
					) : null}
					{checkOutImages.length > 0 ? (
						<FormItem label="Check Out Images" hasFeedback={true}>
							{getFieldDecorator(`checkOutImages`, {
								valuePropName: 'fileList',
								getValueFromEvent: normFile,
								initialValue: checkOutPhotosInfo,
							})(
								<OWUpload
									name="file"
									listType="picture-card"
									className="avatar-uploader"
									multiple={true}
									headers={uploadHeaders}
									onPreview={this.handlePreview}
									action={`${BACKEND_URI}/api/v1/supplier/file/upload`}
									beforeUpload={imageUploadValidation}
								/>
							)}
						</FormItem>
					) : null}
					{documents.length > 0 ? (
						<FormItem label="Other attachments" hasFeedback={true}>
							{form.getFieldDecorator('documents', { initialValue: documents })(
								<FileUploader
									roleType="supplier"
									defaultFileList={documents || []}
									uploaderType={'readAndRemove'}
									handleUploadSuccess={this.handleFileAttachmentUploadChange}
								/>
							)}
						</FormItem>
					) : null}
					{this.state.pdfFile.url ? (
						<div>
							<Document file={this.state.pdfFile} onLoadSuccess={this.onDocumentLoadSuccess}>
								<Page pageNumber={pageNumber} />
							</Document>
							<p>
								Page {pageNumber} of {numPages}
							</p>
						</div>
					) : null}
				</Form>
			</Modal>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	createErrors: state.supplier_contacts.createErrors,
	updateErrors: state.supplier_contacts.updateErrors,
	creating: state.supplier_contacts.creating,
	updating: state.supplier_contacts.updating,

	currentUser: state.session.currentUser,
	invoiceId: ownProps.invoiceId,
	documents: ownProps.documents,
	checkInImages: ownProps.checkInImages,
	checkOutImages: ownProps.checkOutImages,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create<SupplierSendInvoiceByEmailFormProps>()(SupplierSendInvoiceByEmailForm));

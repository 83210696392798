import { Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
	buyerGetQuestionnaireByWorkOrder,
	getQuestionnaireByWorkOrder,
} from '../../thunks/work_orders_thunks';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';

function QuestionnaireSurvey({ workOrder, getQuestionnaire, userType }) {
	const [data, setData] = useState([]);
	useEffect(() => {
		getQuestionnaire(userType, workOrder.id).then(setData);
	}, []);

	const getAnswers = (answer: any = {}) => {
		if (nullSafeGet('question.questionType', answer) === 'multipleChoice') {
			return (
				(
					nullSafeGetOrElse('question.multipleChoices', answer, []).find(
						(q) => q.value === answer.answer
					) || {}
				).description || answer.answer
			);
		}
		return answer.answer;
	};

	const answers = nullSafeGet(`${nullSafeGetOrElse('length', data, 0) - 1}.answers`, data);
	if (!(answers && answers.length)) return null;
	return (
		<div className="rowSpacing">
			<Card title="Survey Answers">
				{answers.map((answer) => (
					<div className="mb-4">
						<div className="mb-1    text-gray-900">{answer.question.title}</div>
						<div className=" flex items-center gap-x-2   text-gray-500">
							{getAnswers(answer) || '--'}
						</div>
					</div>
				))}
			</Card>
		</div>
	);
}

const mapStateToProps = (state) => ({
	userType: state.session.userType,
});

const mapDispatchToProps = (dispatch) => ({
	getQuestionnaire: (userType, id) => dispatch(getQuestionnaireByWorkOrder(userType)(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireSurvey);

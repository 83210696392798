import React, { FC } from 'react';
import HyperLink, { ENTITY_TYPE } from '../common/HyperLink';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import WarrantyDisplay from '../common/WarrantyDisplay';

const PRWarrantyAssetDetail: FC<any> = ({ id, asset, backLinkText }): React.ReactElement => {
	return (
		<div className="flex flex-col justify-start">
			<HyperLink
				text={nullSafeGetOrElse('name', asset, `# ${id}`)}
				entityType={ENTITY_TYPE.ASSET_DETAIL}
				entityId={id}
				backLinkText={backLinkText}
			/>
			<div className="mt-2">{WarrantyDisplay('Active')}</div>
		</div>
	);
};

export default PRWarrantyAssetDetail;

import * as React from 'react';
import { Layout, Row, Col, Card, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { locationsRestCrudThunksForBuyer } from '../../thunks/locations_thunks';
import { getProtectedImageUriForBuyer } from '../../utils/FileAccessUtils';
import {
	nullSafeGet,
	nullSafeGetOrElse,
	getSearchStringForBackNavigationWithBackText,
} from '../../utils/DataAccessUtils';
import Mapbox from '../mapbox/Mapbox';
import { OperatingHoursCardDisplay } from '../operating_hours_card_display/OperatingHoursCardDisplay';
import { WorkOrderRowDisplay } from '../work_order_row_display/WorkOrderRowDisplay';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { EmptyState } from '../empty_state/EmptyState';
import { workOrdersRestCrudThunksForBuyer } from '../../thunks/work_orders_thunks';
import { AssetRowDisplay } from '../asset_row_display/AssetRowDisplay';
import { assetsRestCrudThunksForBuyer } from '../../thunks/assets_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import FileUploader from '../file_uploader/FileUploader';
import OWEditableNotesComponent from '../common/OWEditableNotesComponent';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES, isBuyerAllowedToAccess } from '../../utils/AuthUtils';
import AreasDisplay from './AreasDisplay';
import LocationContactInformation from './LocationContactInformation';
import LocationDetailsCard from './LocationDetailsCard';
import posthog from 'posthog-js';

const { Content } = Layout;

require('./LocationsDetailDetailsPage.less');

const LOCATION_ASSOCIATED_ACTIVE_WORK_ORDERS_TARGET_COLLECTION =
	'locationAssociatedActiveWorkOrders';
const LOCATION_ASSOCIATED_INACTIVE_WORK_ORDERS_TARGET_COLLECTION =
	'locationAssociatedInActiveWorkOrders';
const LOCATION_ASSOCIATED_ASSETS_TARGET_COLLECTION = 'locationAssociatedAssets';

class LocationsDetailPage extends React.Component<any, any> {
	formRefs: any;

	constructor(props) {
		super(props);
		this.state = {
			locationLoading: true,
			previewVisible: false,
			uploadLoading: false,
			previewImage: null,
			selectedArea: {},
			pagination: { current: 1, pageSize: 3, pageSizeOptions: ['3', '5', '10', '25', '50'] },
			pagination2: { current: 1, pageSize: 3, pageSizeOptions: ['3', '5', '10', '25', '50'] },
			pagination3: { current: 1, pageSize: 3, pageSizeOptions: ['3', '5', '10', '25', '50'] },
		};
	}

	refreshLocation = () => {
		this.setState({ locationLoading: true });
		const {
			getLocation,

			match,
		} = this.props;
		const locationId = match.params.id;
		getLocation(locationId).then(() => this.setState({ locationLoading: false }));
	};

	componentDidMount() {
		this.refreshLocation();
	}

	handleFileAttachmentUploadChange = (internalAttachments) => {
		const { location, updateLocation } = this.props;
		const updatedLocation = {
			...location,
			internalAttachments: internalAttachments,
		};
		updateLocation(updatedLocation);
	};

	handleSaveNotes = (propName) => (notes) => {
		const { location, updateLocation } = this.props;
		const updatedLocation = {
			...location,
			[propName]: notes,
		};
		return updateLocation(updatedLocation);
	};

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	render() {
		const { location, history, match, companyConfig, currentUser } = this.props;
		const { pagination, pagination2, pagination3 } = this.state;
		const locationId = match.params.id;
		let photoUris = [];
		if (location.images) {
			photoUris = location.images.map((locImg) => {
				const [imgFileId, imgFileName] = locImg.split('/');
				return getProtectedImageUriForBuyer(imgFileId, imgFileName, 1200, 'auto');
			});
		}
		const workOrderColumns = [
			{
				title: 'Name',
				dataIndex: 'title',
				render: (text, record) => <WorkOrderRowDisplay workOrder={record} />,
			},
		];

		const assetsColumns = [
			{
				title: 'Name',
				dataIndex: 'name',
				render: (text, record) => <AssetRowDisplay asset={record} />,
			},
		];

		let searchString = getSearchStringForBackNavigationWithBackText(
			'Back to Location',
			history.location
		);
		const onWorkOrderRow = (record) => ({
			onClick: () =>
				history.push(
					`/buyer/workOrders/detail/${record.id}/details?backlinkParams=${encodeURIComponent(
						searchString
					)}`
				),
		});

		const onAssetRow = (record) => ({
			onClick: () =>
				history.push(
					`/buyer/assets/detail/${record.id}/details?backlinkParams=${encodeURIComponent(
						searchString
					)}`
				),
		});

		const canModifyLocation = isBuyerAllowedToAccess(
			PERMISSION_NAMES.MODIFY_LOCATIONS,
			companyConfig,
			nullSafeGet('roles', currentUser)
		);
		const skipCount = posthog.isFeatureEnabled('OptimiseWorkOrderAPIforLargeData');

		return this.state.locationLoading ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="locationsDetailDetailsPage">
				<LogOnMountWithStandardEventProperties eventType="visited buyer location detail page" />
				<Row gutter={16}>
					<Col lg={24} xl={16}>
						<div className="rowSpacing">
							<Card className="materialCard" bodyStyle={{ padding: 0 }}>
								<div style={{ height: 300, position: 'absolute', top: 0, left: 0, right: 0 }}>
									<Mapbox
										longitude={nullSafeGet('buyerFacility.longitude', location)}
										latitude={nullSafeGet('buyerFacility.latitude', location)}
										baseLayerStyle="light"
										zoomLevel={7}
									/>
								</div>
								<div style={{ height: 300, position: 'relative' }} />
								<div className="px-6 py-4">
									<div className="materialCard__title">
										{nullSafeGet('buyerFacility.primaryAddress.streetAddress1', location)}
									</div>
									{nullSafeGet('buyerFacility.primaryAddress.streetAddress2', location) ? (
										<div className="materialCard__title">
											{nullSafeGet('buyerFacility.primaryAddress.streetAddress2', location)}
										</div>
									) : null}
									<div className="materialCard__subtitle">
										{nullSafeGet('buyerFacility.primaryAddress.city', location)},{' '}
										{nullSafeGet('buyerFacility.primaryAddress.region', location)}{' '}
										{nullSafeGet('buyerFacility.primaryAddress.postcode', location)}
									</div>
								</div>
							</Card>
						</div>

						<div className="rowSpacing">
							<Card title="Active Work Orders">
								<PaginatedReduxTable
									tableLayoutFixed={true}
									style={{ width: '100%' }}
									showHeader={false}
									emptyState={
										<EmptyState
											graphic={
												<img
													style={{ marginBottom: 8 }}
													src="https://s3.amazonaws.com/mock-data-assets/categories/images/sunset.svg"
													alt="There aren't any active work orders for this location."
												/>
											}
											headline={"All's well on the home front."}
											body={
												<div style={{ textAlign: 'center' }}>
													<div style={{ maxWidth: 440, marginBottom: 16 }}>
														There aren't any active work orders for this location.
													</div>
												</div>
											}
										/>
									}
									collectionName="work_orders"
									targetCollectionName={LOCATION_ASSOCIATED_ACTIVE_WORK_ORDERS_TARGET_COLLECTION}
									columns={workOrderColumns}
									onRow={onWorkOrderRow}
									keyAccessor={(el) => el.id}
									initialFilters={{ locationId: locationId, isActive: true }}
									initialPagination={pagination}
									fetchData={workOrdersRestCrudThunksForBuyer.read}
								/>
							</Card>
						</div>

						<div className="rowSpacing">
							<Card title="Work History">
								<PaginatedReduxTable
									tableLayoutFixed={true}
									showHeader={false}
									emptyState={
										<EmptyState
											graphic={
												<img
													style={{ marginBottom: 8 }}
													src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
													alt="There aren't any past work orders."
												/>
											}
											headline={'No news is good news.'}
											body={
												<div style={{ textAlign: 'center' }}>
													<div style={{ maxWidth: 440, marginBottom: 16 }}>
														There aren't any past work orders.
													</div>
												</div>
											}
										/>
									}
									collectionName="work_orders"
									targetCollectionName={LOCATION_ASSOCIATED_INACTIVE_WORK_ORDERS_TARGET_COLLECTION}
									columns={workOrderColumns}
									onRow={onWorkOrderRow}
									keyAccessor={(el) => el.id}
									initialFilters={{
										locationId: locationId,
										isActive: false,
										hasBuyerArchived: false,
										no_count: skipCount,
									}}
									initialPagination={pagination2}
									fetchData={workOrdersRestCrudThunksForBuyer.read}
								/>
							</Card>
						</div>
						<div className="rowSpacing">
							<Card title="Assets">
								<PaginatedReduxTable
									tableLayoutFixed={true}
									showHeader={false}
									emptyState={
										<EmptyState
											graphic={
												<img
													style={{ marginBottom: 8 }}
													src="https://s3.amazonaws.com/mock-data-assets/categories/images/ficus.svg"
													alt="You haven't added any assets to this location yet."
												/>
											}
											headline={"It's lonely in here."}
											body={
												<div style={{ textAlign: 'center' }}>
													<div style={{ maxWidth: 440, marginBottom: 16 }}>
														You haven't added any assets to this location yet.
													</div>
												</div>
											}
										/>
									}
									collectionName="assets"
									targetCollectionName={LOCATION_ASSOCIATED_ASSETS_TARGET_COLLECTION}
									columns={assetsColumns}
									onRow={onAssetRow}
									keyAccessor={(el) => el.id}
									initialFilters={{ locationId: locationId }}
									initialPagination={pagination3}
									fetchData={assetsRestCrudThunksForBuyer.read}
								/>
							</Card>
						</div>
					</Col>
					<Col lg={24} xl={8}>
						{nullSafeGet('brand.logoURL', location) ? (
							<div className="rowSpacing">
								<Card title={`Brand: ${nullSafeGet('brand.brandName', location)}`}>
									<img
										src={nullSafeGet('brand.logoURL', location)}
										alt=""
										className="h-auto w-20 object-contain"
									/>
								</Card>
							</div>
						) : null}
						<AccessPermissionChecker name={PERMISSION_NAMES.SHOW_LOCATION_DETAILS}>
							<LocationDetailsCard location={location} />
						</AccessPermissionChecker>
						{
							<div className="rowSpacing">
								<Tooltip placement="top" title="Only visible to your team">
									<Card title="Location Staff Attachments">
										<FileUploader
											roleType="buyer"
											defaultFileList={location.internalAttachments || []}
											handleUploadSuccess={this.handleFileAttachmentUploadChange}
											uploaderType={canModifyLocation ? 'dragger' : 'readOnly'}
										/>
									</Card>
								</Tooltip>
							</div>
						}
						<AccessPermissionChecker name={PERMISSION_NAMES.SHOW_INTERNAL_TECH_NOTES}>
							<div className="rowSpacing">
								<OWEditableNotesComponent
									id="internalNotes"
									tooltipMessage="Only visible to your team"
									title="Location Staff Notes (Internal)"
									allowEdit={canModifyLocation}
									notes={nullSafeGetOrElse('internalNotes', location, '')}
									onSaveNotes={this.handleSaveNotes('internalNotes')}
								/>
							</div>
						</AccessPermissionChecker>
						<div className="rowSpacing">
							<OWEditableNotesComponent
								id="notes"
								tooltipMessage="visible to everyone"
								title="Location Notes"
								allowEdit={canModifyLocation}
								notes={nullSafeGetOrElse('notes', location, '')}
								onSaveNotes={this.handleSaveNotes('notes')}
							/>
						</div>
						<LocationContactInformation location={location} />
						<div className="rowSpacing">
							<OperatingHoursCardDisplay operatingHours={location.operatingHours} />
						</div>
						<div className="rowSpacing">
							<AreasDisplay location={location} />
						</div>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state) => ({
	locationsFetching: state.locations.fetching,
	location: state.locations.detail,
	currentUser: state.session.currentUser,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	getLocation: (id) => dispatch(locationsRestCrudThunksForBuyer.readOne(id)),
	updateLocation: (entity) => dispatch(locationsRestCrudThunksForBuyer.update(entity)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationsDetailPage));

import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Alert } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { connect } from 'react-redux';
import { nullSafeGet } from '../../utils/DataAccessUtils';

const FormItem = Form.Item;

interface EditPONumberFormProps extends FormComponentProps {
	onSubmit: any;
	onCancel: any;
	visible: boolean;
	createErrors: any;
	updateErrors: any;
	formData: any;
	creating: boolean;
	updating: boolean;
	currentUser: any;
	confirming: boolean;
}

class EditPONumberForm extends React.Component<EditPONumberFormProps, any> {
	render() {
		const {
			visible,
			formData,
			onCancel,
			confirming,
			onSubmit,
			form,
			currentUser,
			createErrors,
			updateErrors,
		} = this.props;
		const { getFieldDecorator } = form;
		getFieldDecorator('id', { initialValue: nullSafeGet('id', formData) });

		return (
			<Modal
				visible={visible}
				width={600}
				title="Edit PO Number"
				okText="Update"
				onCancel={onCancel}
				onOk={onSubmit}
				confirmLoading={confirming}
				closable={false}
			>
				<Form layout="vertical" hideRequiredMark={true}>
					{createErrors.length > 0 ? (
						<FormItem>
							<Alert message={createErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					{updateErrors.length > 0 ? (
						<FormItem>
							<Alert message={updateErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					<FormItem>
						{getFieldDecorator('poNumber', {
							initialValue: nullSafeGet('poNumber', formData),
						})(<Input style={{ width: '100%' }} />)}
					</FormItem>
				</Form>
			</Modal>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	formData: ownProps.formData,
	confirming: ownProps.confirming,
	createErrors: state.buyer_contacts.createErrors,
	updateErrors: state.buyer_contacts.updateErrors,
	creating: state.buyer_contacts.creating,
	updating: state.buyer_contacts.updating,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create<EditPONumberFormProps>()(EditPONumberForm));

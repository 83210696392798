import { Card } from 'antd';
import React, { FC } from 'react';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { getTroubleshootingAnswerDisplay } from '../../utils/TroubleshootingUtils';

const EachTroubleshootingGroupDisplay: FC<any> = ({ tsId, answers }): React.ReactElement => {
	return (
		<div className="rowSpacing">
			<Card title="Troubleshooting">
				<div className="flex flex-col">
					{answers.map((ans, idx) => (
						<div key={`${tsId}-each-ts-answer-${idx}`} className="mb-3">
							{nullSafeGet('troubleshootingStep.title', ans) ? (
								<div style={{ color: 'rgba(0, 0, 0, 0.45)' }} className="text-md">
									{nullSafeGet('troubleshootingStep.title', ans)}
								</div>
							) : null}
							{nullSafeGet('troubleshootingStep.description', ans) ? (
								<div
									style={{ color: 'rgba(0, 0, 0, 0.45)' }}
									className="text-md mt-2 text-gray-400"
								>
									{nullSafeGet('troubleshootingStep.description', ans)}
								</div>
							) : null}
							{nullSafeGet('answer', ans) ? (
								<div className="mt-2 text-lg">{getTroubleshootingAnswerDisplay(ans)}</div>
							) : null}
						</div>
					))}
				</div>
			</Card>
		</div>
	);
};

export default EachTroubleshootingGroupDisplay;

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Table, Input, Button, Card, message, Radio } from 'antd';
import { ContactAvatar } from '../contact_avatar/ContactAvatar';
import {
	disableUser,
	fetchFacilityActiveUsers,
	fetchFacilityUsers,
	reinviteUser,
} from '../../thunks/users_thunks';
import { getObjectValues, nullSafeGet } from '../../utils/DataAccessUtils';
import { statusIndicatorFormatter } from '../../utils/DataFormatterUtils';
import SupplierNewUserInviteForm from '../supplier_new_user_invite_form/SupplierNewUserInviteForm';
import {
	supplierRoleUpdateUser,
	supplierSignUpInvite,
	supplierUpdateUserContact,
} from '../../thunks/supplier_contacts_thunks';
import SubnavBar from '../subnav_bar/SubnavBar';
import { ROLES } from '../roles/roles';
import SupplierRoleUpdateForm from '../supplier_role_update_form/SupplierRoleUpdateForm';
import { debounce } from '../../utils/PerformanceUtils';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { checkUserTypeToken } from '../../thunks/session_thunks';
import queryString from 'querystring';
import PasswordUpdateForm from '../password_update_form/PasswordUpdateForm';
import { adminChangePassword } from '../../thunks/supplier_contacts_thunks';

const { Content } = Layout;

require('./SupplierAdminUsersPage.less');

const initState = {
	filteredInfo: null,
	sortedInfo: null,
	visible: false,
	roleUpdateVisible: false,
	invitedUsers: new Set([]),
	userContact: null,
	displayMode: 'active',
	changePasswordVisible: false,
	searchText: undefined,
};

interface SourcingPageState {
	filteredInfo: any;
	sortedInfo: any;
	visible: boolean;
	roleUpdateVisible: boolean;
	invitedUsers: any;
	userContact: any;
	displayMode: any;
	changePasswordVisible: any;
	searchText: any;
}

class SupplierAdminUsersPage extends React.Component<any, SourcingPageState> {
	formRefs: any;
	initialPagination: any = {
		current: 1,
		pageSize: 10,
		showSizeChanger: true,
		pageSizeOptions: ['5', '10', '25', '50'],
		onChange: (current, size) => this.handleSizeChange(current, size),
	};
	constructor(props) {
		super(props);
		this.state = {
			...initState,
		};
		this.handleChange = this.handleChange.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
		this.clearAll = this.clearAll.bind(this);
		this.handleSupplierUserInviteCancel = this.handleSupplierUserInviteCancel.bind(this);
		this.handleSupplierUserInviteSubmit = this.handleSupplierUserInviteSubmit.bind(this);
		this.handleSupplierRoleUpdateSubmit = this.handleSupplierRoleUpdateSubmit.bind(this);
		this.handleSupplierRoleUpdateCancel = this.handleSupplierRoleUpdateCancel.bind(this);
		this.showModal = this.showModal.bind(this);
		this.showChangePasswordModal = this.showChangePasswordModal.bind(this);
		this.handleChangePasswordSubmit = this.handleChangePasswordSubmit.bind(this);
		this.handleChangePasswordCancel = this.handleChangePasswordCancel.bind(this);
		this.formRefs = {
			userInviteForm: null,
			roleUpdateForm: null,
			changePasswordForm: null,
		};

		const { location } = this.props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		const { current, pageSize, ...other } = queryParams;
		this.initialPagination = {
			...this.initialPagination,
			current: current ? current : this.initialPagination.current,
			pageSize: pageSize ? pageSize : this.initialPagination.pageSize,
		};
	}

	handleSizeChange = (current, size) => {
		const { history, location, fetchSupplierUsers, fetchSupplierActiveUsers } = this.props;
		let backUrl = `/supplier/admin/users?`;
		let searchParams = new URLSearchParams(location.search);
		searchParams.set('current', current);
		searchParams.set('pageSize', size);
		history.push(backUrl.concat(searchParams.toString()));
		this.initialPagination = { ...this.initialPagination, current: current, pageSize: size };
		if (this.state.displayMode === 'active') {
			fetchSupplierActiveUsers();
		} else {
			fetchSupplierUsers();
		}
	};

	componentDidMount() {
		const { fetchSupplierActiveUsers, fetchSupplierUsers } = this.props;
		if (this.state.displayMode === 'active') {
			fetchSupplierActiveUsers();
		} else {
			fetchSupplierUsers();
		}
	}

	showModal(e) {
		this.setState({
			visible: true,
		});
	}

	handleOk(e) {
		this.setState({
			visible: false,
		});
	}

	handleCancel(e) {
		this.setState({
			visible: false,
		});
	}

	showRoleUpdateModal = (record) => (e) => {
		this.setState({
			roleUpdateVisible: true,
			userContact: record,
		});
	};

	handleChange(pagination, filters, sorter) {
		this.setState({
			filteredInfo: filters,
			sortedInfo: sorter,
		});
	}

	clearFilters() {
		this.setState({ filteredInfo: null });
	}

	clearAll() {
		this.setState({
			filteredInfo: null,
			sortedInfo: null,
		});
	}

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	handleSupplierUserInviteSubmit(values) {
		const { inviteNewUser } = this.props;
		const contact = {
			...values,
			nameFamily: values.nameFamily || '',
			roles: [values.roles],
		};

		inviteNewUser(contact).then(() => {
			this.setState({ visible: false });
			message.success(`Invite sent to ${contact.nameGiven} at ${contact.email}.`);
		});
	}

	handleSupplierUserInviteCancel(e) {
		this.setState({
			visible: false,
		});
	}

	handleSupplierRoleUpdateSubmit(values) {
		const { updateUserContact, refreshCurrentUser, fetchSupplierActiveUsers, fetchSupplierUsers } =
			this.props;

		const updateContact = {
			...values,
			userId: values.email,
			roles: [values.roles],
		};

		updateUserContact(updateContact).then(() => {
			refreshCurrentUser().then(() => {
				this.setState({ roleUpdateVisible: false });
				let refreshRecords =
					this.state.displayMode === 'active' ? fetchSupplierActiveUsers : fetchSupplierUsers;
				refreshRecords().then(() => {
					message.success(`Role Updated`);
				});
			});
		});
	}

	handleSupplierRoleUpdateCancel(e) {
		this.setState({
			roleUpdateVisible: false,
		});
	}

	showChangePasswordModal = (record) => (e) => {
		this.setState({
			changePasswordVisible: true,
			userContact: record,
		});
	};

	handleChangePasswordSubmit() {
		const {
			adminChangePassword,
			refreshCurrentUser,
			fetchSupplierActiveUsers,
			fetchSupplierUsers,
		} = this.props;
		const form = this.formRefs['changePasswordForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			const updateContact = {
				userName: values.email,
				newPassword: values.newPassword,
			};

			adminChangePassword(updateContact).then(() => {
				refreshCurrentUser().then(() => {
					let refreshRecords =
						this.state.displayMode === 'active' ? fetchSupplierActiveUsers : fetchSupplierUsers;
					refreshRecords().then(() => {
						this.setState({ changePasswordVisible: false });
						form.resetFields();
						message.success(`Password Changed`);
					});
				});
			});
		});
	}

	handleChangePasswordCancel(e) {
		const form = this.formRefs['changePasswordForm'].props.form;
		form.resetFields();
		this.setState({
			changePasswordVisible: false,
		});
	}

	debouncedCallback = debounce(
		(searchText) => {
			const { fetchSupplierActiveUsersWithFilter, fetchSupplierUsersWithFilter } = this.props;
			searchText = searchText && searchText.trim();
			if (this.state.displayMode === 'active') {
				fetchSupplierActiveUsersWithFilter({ search: searchText });
			} else {
				fetchSupplierUsersWithFilter({ search: searchText });
			}
			this.setState({ searchText: searchText && searchText.trim() });
		},
		400,
		false
	);

	handleSearchDebounced = (e) => {
		const searchText = e.target.value;
		this.debouncedCallback(searchText);
	};

	switchDisplayMode = (e) => {
		const mode = nullSafeGet('target.value', e);
		this.setState({ displayMode: mode });
		const {
			fetchSupplierActiveUsers,
			fetchSupplierUsers,
			fetchSupplierActiveUsersWithFilter,
			fetchSupplierUsersWithFilter,
		} = this.props;
		const { searchText } = this.state;
		if (mode === 'active') {
			searchText
				? fetchSupplierActiveUsersWithFilter({ search: searchText })
				: fetchSupplierActiveUsers();
		} else {
			searchText ? fetchSupplierUsersWithFilter({ search: searchText }) : fetchSupplierUsers();
		}
	};

	isDisplayModeActive = (mode) => this.state.displayMode === mode;

	render() {
		let filteredInfo = this.state.filteredInfo;
		let sortedInfo = this.state.sortedInfo;
		const { history, supplierUsers, disableSupplierUser, reinviteSupplierUser, currentUser } =
			this.props;
		const { invitedUsers } = this.state;
		const supplierUsersRecords = getObjectValues(supplierUsers.records).map((el) => ({
			...el,
			key: el.contact.email,
		}));
		filteredInfo = filteredInfo || {};
		sortedInfo = sortedInfo || {};

		const columns = [
			{
				title: 'Name',
				dataIndex: ['contact', 'nameGiven'],
				render: (text, record) => (
					<div>
						<ContactAvatar userType="supplier" size="default" contact={record.contact} />
						<span style={{ marginLeft: 16 }}>
							{record.contact.nameGiven} {record.contact.nameFamily}
						</span>
					</div>
				),
			},
			{
				title: 'Email',
				dataIndex: ['contact', 'email'],
			},
			{
				title: 'Roles',
				dataIndex: 'role',
				render: (text, record) => (
					<div>{record.role.map((r) => ROLES[r].displayName).join(', ')}</div>
				),
			},
			{
				title: 'Account Status',
				dataIndex: ['account', 'isActive'],
				render: (text, record) => statusIndicatorFormatter(text),
			},
			{
				title: '',
				dataIndex: ['account', 'userName'],
				render: (text, record) =>
					record.account.userName === currentUser.email ? null : record.account.isActive ? (
						<Row style={{ display: 'flex', gap: 8, width: '100%', justifyContent: 'start' }}>
							{(!record.isSSOUser && (
								<Button
									loading={supplierUsers.loadingUsers.has(record.account.userName)}
									onClick={this.showChangePasswordModal(record)}
								>
									Change Password
								</Button>
							)) ||
								null}
							<Button
								loading={supplierUsers.loadingUsers.has(record.account.userName)}
								onClick={this.showRoleUpdateModal(record)}
							>
								Edit
							</Button>
							<Button
								loading={supplierUsers.loadingUsers.has(record.account.userName)}
								onClick={() => disableSupplierUser(record.account.userName)}
								danger
							>
								Disable
							</Button>
						</Row>
					) : invitedUsers.has(record.account.userName) ? (
						<Row style={{ display: 'flex', gap: 8, width: '100%', justifyContent: 'start' }}>
							{(!record.isSSOUser && (
								<Button
									loading={supplierUsers.loadingUsers.has(record.account.userName)}
									onClick={this.showChangePasswordModal(record)}
								>
									Change Password
								</Button>
							)) ||
								null}
							<Button
								loading={supplierUsers.loadingUsers.has(record.account.userName)}
								onClick={this.showRoleUpdateModal(record)}
							>
								Edit
							</Button>
							<Button disabled={true}>Invite sent</Button>
						</Row>
					) : (
						<Row style={{ display: 'flex', gap: 8, width: '100%', justifyContent: 'start' }}>
							{(!record.isSSOUser && (
								<Button
									loading={supplierUsers.loadingUsers.has(record.account.userName)}
									onClick={this.showChangePasswordModal(record)}
								>
									Change Password
								</Button>
							)) ||
								null}
							<Button
								loading={supplierUsers.loadingUsers.has(record.account.userName)}
								onClick={this.showRoleUpdateModal(record)}
							>
								Edit
							</Button>
							<Button
								loading={supplierUsers.loadingUsers.has(record.account.userName)}
								onClick={() =>
									reinviteSupplierUser(record.account.userName).then((userName) => {
										let newInvitedUsers = new Set(invitedUsers);
										newInvitedUsers.add(userName);
										this.setState({ invitedUsers: newInvitedUsers });
									})
								}
							>
								Reactivate
							</Button>
						</Row>
					),
			},
		];
		return (
			<Content className="adminUsersPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited supplier admin users page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}

				{this.state.visible && (
					<SupplierNewUserInviteForm
						wrappedComponentRef={this.saveFormRef('userInviteForm')}
						visible={this.state.visible}
						onCancel={this.handleSupplierUserInviteCancel}
						onSubmit={this.handleSupplierUserInviteSubmit}
					/>
				)}

				{this.state.roleUpdateVisible && (
					<SupplierRoleUpdateForm
						wrappedComponentRef={this.saveFormRef('roleUpdateForm')}
						visible={this.state.roleUpdateVisible}
						formData={this.state.userContact}
						onCancel={this.handleSupplierRoleUpdateCancel}
						onSubmit={this.handleSupplierRoleUpdateSubmit}
					/>
				)}

				<PasswordUpdateForm
					wrappedComponentRef={this.saveFormRef('changePasswordForm')}
					visible={this.state.changePasswordVisible}
					formData={this.state.userContact}
					onCancel={this.handleChangePasswordCancel}
					onSubmit={this.handleChangePasswordSubmit}
				/>

				<Row style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<SubnavBar
							left={
								<div>
									<Input.Search
										placeholder="Filter by name or email"
										style={{ width: 240 }}
										onChange={this.handleSearchDebounced}
									/>
									<Radio.Group
										onChange={this.switchDisplayMode}
										value={this.state.displayMode}
										style={{ marginLeft: 16, marginTop: 4 }}
									>
										<Radio.Button value="active">Active</Radio.Button>
										<Radio.Button value="all">All</Radio.Button>
									</Radio.Group>
								</div>
							}
							right={
								<div>
									<Button
										style={{ marginLeft: 16 }}
										type="primary"
										size="large"
										onClick={this.showModal}
									>
										Invite New User
									</Button>
								</div>
							}
						/>
					</Col>
				</Row>
				<Row style={{ margin: '0.5em 0' }}>
					<Col span={24}>
						<Card bodyStyle={{ padding: 8 }}>
							<Table
								columns={columns}
								dataSource={supplierUsersRecords}
								loading={supplierUsers.loading}
								pagination={this.initialPagination}
							/>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	supplierUsers: state.supplier_users,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	fetchSupplierUsers: () => dispatch(fetchFacilityUsers('supplier')()),
	fetchSupplierActiveUsers: () => dispatch(fetchFacilityActiveUsers('supplier')()),
	fetchSupplierUsersWithFilter: (filter) => dispatch(fetchFacilityUsers('supplier', filter)()),
	fetchSupplierActiveUsersWithFilter: (filter) =>
		dispatch(fetchFacilityActiveUsers('supplier', filter)()),
	disableSupplierUser: (userName) => dispatch(disableUser('supplier')(userName)),
	inviteNewUser: (contact) => dispatch(supplierSignUpInvite(contact)),
	reinviteSupplierUser: (userName) => dispatch(reinviteUser('supplier')(userName)),
	roleUpdateUser: (entity) => dispatch(supplierRoleUpdateUser(entity)),
	updateUserContact: (entity) => dispatch(supplierUpdateUserContact(entity)),
	refreshCurrentUser: () => dispatch(checkUserTypeToken(ROLE_TYPES.SUPPLIER)()),
	adminChangePassword: (entity) => dispatch(adminChangePassword(entity)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(SupplierAdminUsersPage)
);

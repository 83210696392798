import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';
import { WORK_ORDER_PRIORITIES_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/work_order_priorities_actions';

const initialState: any = standardInitialStateGenerator([
	'WORK_ORDER_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE',
	'SUPPLIER_WORK_ORDER_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE',
	'PLANNED_MAINTENANCE_TEMPLATE_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE',
	'PLANNED_MAINTENANCE_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE',
	'workOrdersAdvancedFilters',
	'troubleshootingFormIndex',
]);
export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState);

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Card } from 'antd';
import ServiceRequestForm from '../service_request_form/ServiceRequestForm';
import { getBackLinkStates } from '../../utils/HistoryUtils';

const { Content } = Layout;

const DEFAULT_BACK_PATH = '/buyer/workOrders';

class NewServiceRequestPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			backLinkParams: '',
			backLinkTo: DEFAULT_BACK_PATH,
		};
	}

	componentDidMount(): void {
		const { location } = this.props;
		this.setState(getBackLinkStates(location, DEFAULT_BACK_PATH));
	}

	render() {
		const { history } = this.props;
		return (
			<Content>
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<Card>
							<div>
								<h5 style={{ fontWeight: 400 }}>New Service Request</h5>
								<ServiceRequestForm
									formData={{}}
									onSuccess={(rec) =>
										history.push(
											`/buyer/workOrders/detail/${rec.id}`.concat('?', this.state.backLinkParams)
										)
									}
								/>
							</div>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	location: ownProps.location,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewServiceRequestPage));

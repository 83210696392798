import * as React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Alert, Select, Switch, Button, Form, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import {
	fetchLocationsWithRegionsForBuyer,
	locationsRestCrudThunksForBuyer,
} from '../../thunks/locations_thunks';
import { regionsRestCrudThunksForBuyer } from '../../thunks/regions_thunks';
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import BuyerRegionsLocationsTreeSelect from '../regions_tree_select/BuyerRegionsLocationsTreeSelect';
import { BUYER_ADMIN } from '../roles/roles';
import EmailComponentAntdV4 from '../email_component/EmailComponentAntdV4';

const FormItem = Form.Item;

function BuyerNewUserInviteForm(props) {
	const [form] = Form.useForm();
	const {
		visible,
		onCancel,
		onSubmit,
		formData,
		createErrors,
		updateErrors,
		locations,
		isLocationChange,
	} = props;

	const [showPassword, setShowPassword] = React.useState(false);

	const roles = Form.useWatch('roles', form);

	const hasAccessToAllLocations = Form.useWatch('hasAccessToAllLocations', form);
	const isSharedContact = Form.useWatch('isSharedContact', form);
	const handleSubmit = React.useCallback(
		(values) => {
			const updatedValues = {
				...values,
				...(values.hasAccessToAllLocations && { locationIds: [] }),
			};

			onSubmit && onSubmit(updatedValues);
		},
		[onSubmit]
	);
	return (
		<Modal
			visible={visible}
			width={600}
			title={'Invite New User'}
			okText={'Submit'}
			onCancel={onCancel}
			onOk={form.submit}
			closable={true}
			destroyOnClose={true}
		>
			<Form
				form={form}
				layout="vertical"
				className="supplierForm"
				preserve={false}
				initialValues={{
					passwordResetRequired: false,
					isSharedContact: false,
					email: '',
					roles: 'BUYER_STORE_ASSOCIATE',
				}}
				onFinish={handleSubmit}
			>
				{createErrors.length > 0 ? (
					<FormItem>
						<Alert message={createErrors.join(' ')} type="error" />
					</FormItem>
				) : null}
				{updateErrors.length > 0 ? (
					<FormItem>
						<Alert message={updateErrors.join(' ')} type="error" />
					</FormItem>
				) : null}
				<EmailComponentAntdV4 />
				<FormItem
					label={'Name'}
					className={'flex flex-row'}
					required={true}
					rules={[
						{
							required: true,
							message: 'Please input name.',
						},
					]}
				>
					<div className={'flex flex-row'} style={{ marginBottom: -24 }}>
						<FormItem name={'nameGiven'}>
							<Input
								placeholder="Given name"
								style={{ maxWidth: 200 }}
								disabled={false}
								defaultValue={nullSafeGet('contact.nameGiven', formData)}
							/>
						</FormItem>
						<FormItem name={'nameFamily'}>
							<Input
								defaultValue={nullSafeGet('contact.nameFamily', formData)}
								placeholder="Family name"
								style={{ maxWidth: 200, marginLeft: 8 }}
								disabled={false}
							/>
						</FormItem>
					</div>
				</FormItem>

				<FormItem
					label="Roles"
					name="roles"
					required={true}
					rules={[
						{
							required: true,
							message: 'Please select a role',
						},
					]}
				>
					<Select
						disabled={isLocationChange ? isLocationChange : false}
						style={{ maxWidth: 200 }}
						onChange={(role) => {
							if (role === BUYER_ADMIN) {
								form.setFieldsValue({ hasAccessToAllLocations: true });
							}
						}}
					>
						<Select.Option value="BUYER_ADMIN">Admin</Select.Option>
						<Select.Option value="BUYER_USER">Manager</Select.Option>
						<Select.Option value="BUYER_STORE_ASSOCIATE">Store Associate</Select.Option>
					</Select>
				</FormItem>
				<FormItem
					name="hasAccessToAllLocations"
					valuePropName="checked"
					hidden={roles === 'BUYER_ADMIN'}
				>
					<Switch
						checked={hasAccessToAllLocations}
						onChange={(checked) => form.setFieldsValue({ hasAccessToAllLocations: !!checked })}
					/>
					<label style={{ marginLeft: 8 }}>Select All Locations</label>
				</FormItem>
				<FormItem
					name="locationIds"
					hidden={roles === 'BUYER_ADMIN'}
					label={'Locations'}
					rules={[
						{
							type: 'array',
							required: !hasAccessToAllLocations,
							message: 'Please select a location',
						},
					]}
				>
					<BuyerRegionsLocationsTreeSelect
						mode="multiple"
						noIsActiveFilter
						disabled={hasAccessToAllLocations}
						filtersSlice={locations}
						filtersValueAccessor={() => form.getFieldValue('locationIds')}
						targetCollectionName={'buyerUsersLocations'}
					/>
				</FormItem>
				<div className="flex flex-row items-baseline">
					<FormItem name="isSharedContact" valuePropName="checked">
						<Switch />
					</FormItem>
					<label className="ml-2">Shared Account</label>
				</div>
				{roles !== BUYER_ADMIN && (
					<div className="flex flex-row items-baseline">
						<FormItem name="readOnlyAccess" valuePropName="checked">
							<Switch />
						</FormItem>
						<label className="ml-2">Read Only Access</label>
					</div>
				)}
				<div style={{ marginTop: 12 }}>
					{showPassword || isSharedContact ? (
						<>
							{showPassword ? (
								<FormItem>
									<Button
										style={{ padding: 0 }}
										type={'link'}
										onClick={() => setShowPassword(false)}
									>
										<UpCircleOutlined translate="" />
										<span style={{ textTransform: 'capitalize' }}>Hide Password</span>
									</Button>
								</FormItem>
							) : null}

							<FormItem
								name="password"
								label={
									<span>
										Password <span style={{ color: 'rgba(0,0,0,0.45)' }}>(Optional)</span>
									</span>
								}
								required={false}
								style={{ marginBottom: 0 }}
							>
								<Input type="password" style={{ maxWidth: 200 }} />
							</FormItem>
							<FormItem name="passwordResetRequired" valuePropName="checked" required={false}>
								<Checkbox>Ask user to change password when they sign in</Checkbox>
							</FormItem>
						</>
					) : (
						<FormItem>
							<Button style={{ padding: 0 }} type={'link'} onClick={() => setShowPassword(true)}>
								<DownCircleOutlined translate="" />
								<span style={{ textTransform: 'capitalize' }}>Set password</span>
							</Button>
						</FormItem>
					)}
				</div>
			</Form>
		</Modal>
	);
}

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	createErrors: state.buyer_contacts.createErrors,
	updateErrors: state.buyer_contacts.updateErrors,
	creating: state.buyer_contacts.creating,
	updating: state.buyer_contacts.updating,
	currentUser: state.session.currentUser,

	buyerUsers: state.buyer_users,
	locations: state.locations,
	regions: state.regions,
});

const mapDispatchToProps = (dispatch) => ({
	fetchLocationsWithHierarchy: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			fetchLocationsWithRegionsForBuyer(
				{ ...params, includeInactive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchRegions: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			regionsRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleRegions: (ids, targetCollectionName) =>
		dispatch(regionsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			locationsRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BuyerNewUserInviteForm);

import React, { FC, useCallback, useMemo, ReactElement } from 'react';
import { connect } from 'react-redux';
import { nullSafeGet, nullSafeGetOrElse, renderCurrency } from '../../utils/DataAccessUtils';
import { Card, Col, Layout, Row } from 'antd';
import { HorizontalKeyValueDisplay } from '../horizontal_key_value_display/HorizontalKeyValueDisplay';

import { withRouter } from 'react-router';
import OWEditableNotesComponent from '../common/OWEditableNotesComponent';
import {
	partCatalogsRestCrudThunksForSupplier,
	updatePartCatalogsListPriceForSupplier,
} from '../../thunks/part_catalogs_thunks';
import ListPriceHistory from './ListPriceHistory';

const { Content } = Layout;

const PartCatalogDetailsDetailPage: FC<any> = ({
	partCatalog,
	currentUser,
	fetching,
	update,
	updatePriceList,
	fetchPartCatalog,
	match,
}): ReactElement => {
	const refreshPartCatalog = useCallback(() => {
		match.params.id && fetchPartCatalog(match.params.id);
	}, [fetchPartCatalog, match.params.id]);

	const partCatalogDetail = useMemo(
		() => ({
			Vendor: nullSafeGetOrElse('partEquipmentVendor.name', partCatalog, '__'),
			'Last Price': renderCurrency(currentUser)(partCatalog.unitCost),
			'List Price': renderCurrency(currentUser)(partCatalog.listPrice),
		}),
		[currentUser, partCatalog]
	);

	const handleSaveNotes = useCallback(
		(notes) =>
			update({
				...partCatalog,
				notes,
			}),
		[partCatalog, update]
	);

	const onUpdateListPrice = useCallback(
		(values) => {
			const data = {
				...values,
				partNumber: nullSafeGet('part.partNumber', partCatalog),
				vendorId: nullSafeGet('partEquipmentVendor.vendorId', partCatalog),
			};
			return updatePriceList([data]);
		},
		[partCatalog, updatePriceList]
	);

	return (
		<Content
			style={{
				padding: '0 0.5em',
			}}
		>
			<Row style={{ margin: '16px 4px' }} gutter={12}>
				<Col span={16}>
					<Card loading={fetching} title="Details">
						<HorizontalKeyValueDisplay
							rowStyle={{ minWidth: '25%' }}
							keyValueStore={partCatalogDetail}
						/>
					</Card>
					<ListPriceHistory
						onUpdate={onUpdateListPrice}
						loading={fetching}
						listPrices={nullSafeGetOrElse('listPrices', partCatalog, [])}
						refreshEntity={refreshPartCatalog}
					/>
				</Col>
				<Col span={8}>
					<OWEditableNotesComponent
						id="partCatalogNotes"
						tooltipMessage=""
						title="Notes"
						loading={fetching}
						allowEdit={true}
						notes={nullSafeGetOrElse('notes', partCatalog, '')}
						onSaveNotes={handleSaveNotes}
					/>
				</Col>
			</Row>
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	fetching: state.part_catalogs.fetching,
	partCatalog: state.part_catalogs.detail,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	update: (entity) => dispatch(partCatalogsRestCrudThunksForSupplier.update(entity)),
	fetchPartCatalog: (id) => dispatch(partCatalogsRestCrudThunksForSupplier.readOne(id)),
	updatePriceList: (entities) => dispatch(updatePartCatalogsListPriceForSupplier(entities)),
});

const ComponentWithoutUserType = withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(PartCatalogDetailsDetailPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

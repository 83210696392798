import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { SAVED_FILTER_VIEWS_CRUD_ACTION_CREATORS } from '../actions/saved_filter_views_actions';

export const savedFilterViewsRestCrudThunksForBuyer = new RestCrudThunks(
	'saved_filter_views',
	'saved_filter_view',
	'savedFilterView',
	'savedFilterViews',
	SAVED_FILTER_VIEWS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'view/'
);

export const savedFilterViewsRestCrudThunksForSupplier = new RestCrudThunks(
	'saved_filter_views',
	'saved_filter_view',
	'savedFilterView',
	'savedFilterViews',
	SAVED_FILTER_VIEWS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'view/'
);

import * as React from 'react';
import { Checkbox, Input, Row, Col, Select, Button } from 'antd';
import { getObjectValues, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import filters_enum from './FiltersEnum';
import FilterWrapper from './FilterWrapper';
import { isEqual } from 'lodash';

export default class FilterDropdown extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			filters: {},
			active: props.activeFilters,
		};
		this.handleApplyFilters = this.handleApplyFilters.bind(this);
		this.handleCancelFilters = this.handleCancelFilters.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
	}

	componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
		const { column, filteredDataSource, activeFilters, identifier, syncActive } = this.props;
		const { active } = this.state;
		if (identifier === prevProps.identifier) {
			if (!isEqual(active, activeFilters)) {
				if (!isEqual(filteredDataSource, prevProps.filteredDataSource)) {
					this.setState({ active: activeFilters });
				} else if (syncActive && syncActive !== prevProps.syncActive) {
					this.setState({ active: activeFilters });
				}
			}
			if (!isEqual(filteredDataSource, prevProps.filteredDataSource)) {
				this.setState({ filters: this.setupFilters() });
			}
		}
	}
	setupFilters() {
		const { column } = this.props;
		let filterList = nullSafeGetOrElse('props.filterList', column, []);
		let filters = {};
		if (filterList.length > 0) {
			//For each filter in the column.props.filterList, collect filter_enum data
			filterList
				.filter((columnFilter) => validateFilterExists(columnFilter))
				.map((colFilter) => {
					let filter_enum = filters_enum[colFilter.object][colFilter.name]; //the specific enum for this filter
					let filter = {
						enum: filter_enum,
						columnFilter: colFilter,
						update: nullSafeGet('props.update', column),
					};

					filters[filter_enum.key] = filter;
				});
		}
		return filters;
	}
	componentDidMount() {
		let filters = this.setupFilters();
		this.setState({ filters: filters });
	}
	removeInactiveFilters(active) {
		let activeFilters = {};
		let a = getObjectValues(active)
			.filter((af) => {
				return af.active;
			})
			.map((af) => {
				activeFilters[af.value] = af;
			});
		return activeFilters;
	}

	handleSearch(newValue) {
		const { handleFilterText, column } = this.props;
		handleFilterText(column.title, newValue);
	}
	handleChange(newValue) {}
	handleCheck = (filterItem) => {
		const { active } = this.state;
		let f = { ...active };
		f[filterItem.value] = filterItem;
		f = this.removeInactiveFilters(f);
		this.setState({ active: f });
	};
	handleApplyFilters = () => {
		const { column, setFilters } = this.props;
		const { active } = this.state;
		setFilters(column.title, active, false);
	};
	handleCancelFilters = () => {
		const { column, setFilters } = this.props;
		setFilters(column.title, {}, true);
		this.setState({ active: {} });
	};

	render() {
		const { column, filteredDataSource, filterText, handleFilterText } = this.props;
		const { filters, active } = this.state;

		return (
			<Row
				key={column.title.concat('-row')}
				style={{ display: 'flex', width: '290px' }}
				onMouseDown={(e) => {
					e.preventDefault();
					e.stopPropagation();
				}}
			>
				<Select
					showSearch
					value={filterText}
					placeholder={'Search...'}
					style={{ width: '100%', margin: '8px' }}
					defaultActiveFirstOption={false}
					showArrow={false}
					filterOption={true}
					onSearch={this.handleSearch}
					onChange={this.handleChange}
					notFoundContent={null}
					defaultOpen={true}
					autoFocus
				/>
				<Row style={{ display: 'flex', width: '100%', overflowY: 'scroll', maxHeight: '250px' }}>
					{getObjectValues(filters).map((filter, i) => {
						return (
							<FilterWrapper
								key={column.title.concat('-', Object.keys(filters)[i])}
								identifier={column.title.concat('-', Object.keys(filters)[i])}
								style={{
									display: 'flex',
								}}
								activeFilters={active}
								dataSource={filteredDataSource}
								filterEnum={filter.enum}
								columnFilter={filter.columnFilter}
								update={filter.update}
								handleCheck={this.handleCheck}
								value={filterText}
							/>
						);
					})}
				</Row>
				<Row
					style={{
						display: 'flex',
						width: '100%',
						overflowY: 'scroll',
						borderTop: '1px solid rgb(240,240,240)',
						padding: '7px 8px',
						justifyContent: 'space-between',
					}}
				>
					<Button onClick={this.handleCancelFilters} size="small">
						Reset
					</Button>
					<Button onClick={this.handleApplyFilters} type="primary" size="small">
						Apply
					</Button>
				</Row>
			</Row>
		);
	}
}

function validateFilterExists(filter) {
	let filterKeys = Object.keys(filters_enum);
	let filterObject = nullSafeGet('object', filter);
	if (filterObject && filterKeys.includes(filterObject)) {
		let filterNameKeys = Object.keys(nullSafeGet(filterObject, filters_enum));
		let filterName = nullSafeGet('name', filter);
		if (filterNameKeys && filterNameKeys.includes(filterName)) {
			return true;
		} else {
			console.warn(
				'Filter Name: ' + filterName + ' was not found in Filter Object:' + filterObject
			);
		}
	} else {
		console.warn('Filter Object: ' + filterObject + ' was not found');
	}
	return false;
}

import * as React from 'react';
import { nullSafeGet } from '../../../utils/DataAccessUtils';
import WorkOrderDisplayStatusDisplay from '../../work_order_display_status_display/WorkOrderDisplayStatusDisplay';
import {
	getWorkOrderBasePriorityBackgroundColor,
	getWorkOrderBasePriorityPillBackgroundColor,
} from '../../../utils/DataFormatterUtils';
import { Link } from 'react-router-dom';
import DateTimeHover from '../../date_time_component/DateTime';

const moment = require('moment');
const style = require('./RowDisplay.less');

export const StaleWORowDisplay: React.FC<any> = ({ record }) => {
	if (!record) {
		return null;
	}
	const workOrder = record;
	const getLink = () => {
		let backUrl = `/buyer/workOrders/detail/${workOrder.id}/details`;
		return backUrl;
	};
	return (
		<Link to={getLink()}>
			<div className="workOrderRowDisplay__row" style={{ height: '55px' }}>
				<div style={{ width: '100%' }}>
					<div className="workOrderRowDisplay__rowTitle">
						<div
							style={{
								maxWidth: '100%',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								color: 'rgba(0,0,0,.65)',
							}}
						>
							{workOrder.title}
						</div>
					</div>
					<div className="workOrderRowDisplay__rowSubtitle">
						<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
							<div>
								<div
									className="workOrderRowDisplay__rowInlineGroup"
									style={{ display: 'inline-flex' }}
								>
									<span className="workOrderRowDisplay__grayChip">
										<i className="icons8-font icons8-location-marker" />
										<span>{nullSafeGet('location.name', workOrder)}</span>
									</span>
								</div>
								<div
									className="workOrderRowDisplay__rowInlineGroup"
									style={{ display: 'inline-flex' }}
								>
									{workOrder.displayStatus.replace(/([A-Z])/g, ' $1').trim()}
								</div>
							</div>
						</div>
						<div className="workOrderRowDisplay__rowSubtitle">
							<span style={{ whiteSpace: 'nowrap', color: 'rgba(0,0,0,0.45)', fontSize: 14 }}>
								{moment(workOrder.dueDate).diff(moment(), 'milliseconds') < 0 &&
								workOrder.displayStatus !== 'Completed' &&
								workOrder.displayStatus !== 'Cancelled' ? (
									<span>
										Past due <DateTimeHover timestamp={nullSafeGet('dueDate', workOrder)} />
									</span>
								) : (
									<span>
										Reported <DateTimeHover timestamp={nullSafeGet('createdAt', workOrder)} />
									</span>
								)}
							</span>
						</div>
					</div>
				</div>
			</div>
		</Link>
	);
};

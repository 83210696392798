import { APPROVAL_HIERARCHIES_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/company_approval_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = standardInitialStateGenerator([
	'approvalsIndex',
	'approvalsIndexInvoice',
	'approvalsIndexQuote',
	'approvalsIndexWorkOrder',
	'APPROVAL_HIERARCHY_AUTOCOMPLETE',
]);

export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState);

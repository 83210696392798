import React, { FC } from 'react';
import { Redirect, Route, withRouter } from 'react-router';
import { Layout } from 'antd';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { connect } from 'react-redux';

const { Content } = Layout;

const TroubleshootingOverviewPage: FC<any> = ({
	childRoutes,
	companyConfig,
}): React.ReactElement => {
	const basePath = '/buyer/admin/troubleshooting/overview';

	return (
		<div>
			<div>
				<Route
					path={basePath}
					exact={true}
					render={(props) => (
						<Redirect
							to={{
								pathname: `${basePath}/all`,
							}}
						/>
					)}
				/>
				{childRoutes.map((r, i) => (
					<RouteWithSubRoutes key={i} {...r} />
				))}
			</div>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => ({
	companyConfig: state.company_config,
});

export default withRouter<any, any>(connect(mapStateToProps)(TroubleshootingOverviewPage));

import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { attentionDashboardsRestCrudThunksForBuyer } from '../../thunks/attention_dashboard_thunks';
import { Card, Spin } from 'antd';
import EachWidget from '../attention_dashboard_components/EachWidget';
import ADHeader from '../attention_dashboard_components/ADHeader';
import ADAddWidgetPopup from '../attention_dashboard_components/ADAddWidgetPopup';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { savedFilterViewsRestCrudThunksForBuyer } from '../../thunks/saved_filter_views_thunks';
import AttentionDashboardEditPopup from '../attention_dashboard_components/AttentionDashboardEditPopup';
import OverlayContainer from '../common/OverlayContainer';
import { nullSafeGet } from '../../utils/DataAccessUtils';

const AttentionDashboardIndexPage = ({
	currentUser,
	attentionDashboard,
	getAttentionDashboard,
	saveAttentionDashboard,
	updateSavedFilterView,
}): React.ReactElement => {
	const [loading, setLoading] = useState(false);
	const [addWidgetModalVisible, setAddWidgetModalVisible] = useState(false);
	const [editWidgetModalVisible, setEditWidgetModalVisible] = useState(false);

	const showAddWidgetModal = useCallback(() => setAddWidgetModalVisible(true), []);
	const hideAddWidgetModal = useCallback(() => setAddWidgetModalVisible(false), []);
	const showEditModal = useCallback(() => setEditWidgetModalVisible(true), []);
	const hideEditModal = useCallback(() => setEditWidgetModalVisible(false), []);

	const refreshDashboard = useCallback(() => {
		setLoading(true);
		getAttentionDashboard().then(() => setLoading(false));
	}, [getAttentionDashboard]);

	useEffect(() => {
		refreshDashboard();
	}, [refreshDashboard]);

	const onSaveDahboard = useCallback(
		(savedFilterViewIds) => {
			hideEditModal();
			setLoading(true);
			saveAttentionDashboard({
				...attentionDashboard,
				savedFilterViewIds,
			}).then(() => setLoading(false));
		},
		[hideEditModal, attentionDashboard, saveAttentionDashboard]
	);

	return (
		<div
			style={{
				marginTop: '1em',
				width: '100%',
				position: 'relative',
			}}
		>
			{loading ? (
				<OverlayContainer>
					<Spin />
				</OverlayContainer>
			) : (
				<div>
					<ADHeader
						addWidget={showAddWidgetModal}
						editDashboard={showEditModal}
						name={nullSafeGet('nameGiven', currentUser)}
					/>

					<div>
						{attentionDashboard && attentionDashboard.savedFilterViews
							? attentionDashboard.savedFilterViews.map((savedView) => (
									<div className="rowSpacing" key={savedView.id}>
										<Card bodyStyle={{ padding: 0 }}>
											<EachWidget id={savedView.id} savedView={savedView} />
										</Card>
									</div>
							  ))
							: null}
					</div>
					{addWidgetModalVisible && (
						<ADAddWidgetPopup
							filterIds={attentionDashboard.savedFilterViewIds}
							roleType={ROLE_TYPES.BUYER}
							onCancel={hideAddWidgetModal}
						/>
					)}
					{editWidgetModalVisible && (
						<AttentionDashboardEditPopup
							savedFilterViews={attentionDashboard.savedFilterViews}
							onCancel={hideEditModal}
							updateView={updateSavedFilterView}
							refreshDashboard={getAttentionDashboard}
							saveDashboard={onSaveDahboard}
						/>
					)}
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
	attentionDashboard: state.attention_dashboards.detail,
});

const mapDispatchToProps = (dispatch) => ({
	getAttentionDashboard: () =>
		dispatch(attentionDashboardsRestCrudThunksForBuyer.readOne('default')),
	saveAttentionDashboard: (entity) =>
		dispatch(attentionDashboardsRestCrudThunksForBuyer.update(entity)),
	updateSavedFilterView: (entity) =>
		dispatch(savedFilterViewsRestCrudThunksForBuyer.update(entity)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(AttentionDashboardIndexPage)
);

import * as React from 'react';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Input, Radio, message, Alert, Select, Button, AutoComplete } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { connect } from 'react-redux';
import { signUp } from '../../thunks/session_thunks';
import { BUYER_ROLES } from '../roles/roles';
import { getObjectValues } from '../../utils/DataAccessUtils';
import BackButton from '../back_button/BackButton';
import { withRouter } from 'react-router';
import { getHomePageByRole } from '../../utils/EnvConfigUtils';
import { Link } from 'react-router-dom';
import { validateDomain } from '../../utils/AuthUtils';
import { SSODomainForBuyer } from '../../thunks/domain_thunks';

const style = require('./BuyerSignupForm.less');
const FormItem = Form.Item;
const Option = Select.Option;
const AutoCompleteOption = AutoComplete.Option;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

interface SignupProps {
	email: string;
	signUp: any;
	loading: boolean;
	history: any;
	username: string;
	reviewModalMode?: boolean;
	successCallback?: any;
	errors: any[];
	password: string;
}

interface SignupFormProps extends FormComponentProps {
	email: string;
	nameGiven: string;
	nameFamily: string;
	signUp: any;
	loading: boolean;
	history: any;
	username: string;
	reviewModalMode?: boolean;
	successCallback?: any;
	getAndValidateDomain: any;
	errors: any[];
	password: string;
}

interface SignupFormState {
	confirmDirty: boolean;
	shouldLoginViaSSO: boolean;
	autoCompleteResult: string[];
	uploadLoading: boolean;
	verificationEmailSent: boolean;
	newUserEmail: string;
	newUserFirstName: string;
	newUserLastName: string;
}

function getBase64(img, callback) {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
}

const roles = getObjectValues(BUYER_ROLES);

class BuyerSignupForm extends React.Component<SignupFormProps, SignupFormState> {
	state = {
		confirmDirty: false,
		autoCompleteResult: [],
		uploadLoading: false,
		verificationEmailSent: false,
		newUserEmail: '',
		newUserFirstName: '',
		newUserLastName: '',
		shouldLoginViaSSO: false,
	};

	handleUploadChange = (info) => {
		if (info.file.status === 'uploading') {
			this.setState({ uploadLoading: true });
			return;
		}
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			getBase64(info.file.originFileObj, (imageUrl) =>
				this.setState({
					uploadLoading: false,
				})
			);
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFieldsAndScroll((err, values) => {
			this.props
				.getAndValidateDomain(values.email)
				.then((domains = []) => {
					if (!err) {
						const [nameGiven, nameFamily] = values.fullName.split(' ');
						const userRoles = values.roles || [];
						this.props
							.signUp(
								values.email.trim(),
								values.password.trim(),
								nameGiven,
								nameFamily,
								values.gender,
								userRoles,
								values.facilityId
							)
							.then((newUser) => {
								this.setState({
									verificationEmailSent: true,
									newUserEmail: newUser.email,
									newUserFirstName: newUser.nameGiven,
									newUserLastName: newUser.nameFamily,
								});

								if (!this.props.reviewModalMode) {
									const homePage = getHomePageByRole('buyer', {});
									this.props.history.push(homePage);
								}

								if (this.props.successCallback) {
									this.props.successCallback(newUser);
								}
							})
							.catch((err) => {
								message.error(`${err.message}`);
							});
					}
				})
				.catch((err) => {
					this.setState({ shouldLoginViaSSO: err });
				});
		});
	};

	handleConfirmBlur = (e) => {
		const { form } = this.props;
		const value = e.target.value;
		this.setState({ confirmDirty: this.state.confirmDirty || !!value }, () =>
			form.validateFields(['confirm'], { force: true }, () => {})
		);
	};

	checkConfirm = (rule, value, callback) => {
		const { form } = this.props;
		const { confirmDirty } = this.state;
		const otherValue = form.getFieldValue('password');
		if (value && otherValue && confirmDirty && value !== otherValue) {
			callback("The new passwords you entered don't match!");
		} else {
			callback();
		}
	};

	handleWebsiteChange = (value) => {
		let autoCompleteResult;
		if (!value) {
			autoCompleteResult = [];
		} else {
			autoCompleteResult = ['.com', '.org', '.net'].map((domain) => `${value}${domain}`);
		}
		this.setState({ autoCompleteResult });
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { loading, errors, reviewModalMode, email, nameGiven, nameFamily } = this.props;
		const {
			autoCompleteResult,
			verificationEmailSent,
			newUserEmail,
			newUserFirstName,
			newUserLastName,
			shouldLoginViaSSO,
		} = this.state;

		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
				sm: { span: 6 },
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 18 },
			},
		};
		const tailFormItemLayout = {
			wrapperCol: {
				xs: {
					span: 24,
					offset: 0,
				},
				sm: {
					span: 14,
					offset: 6,
				},
			},
		};
		const prefixSelector = getFieldDecorator('prefix', {
			initialValue: '86',
		})(
			<Select style={{ width: 60 }}>
				<Option value="86">+86</Option>
				<Option value="87">+87</Option>
			</Select>
		);

		return (
			<Form hideRequiredMark={true} onSubmit={this.handleSubmit}>
				{errors.length > 0
					? errors.map((msg, idx) => (
							<FormItem key={idx}>
								<Alert message={msg} type="error" />
							</FormItem>
					  ))
					: null}
				{shouldLoginViaSSO ? (
					<FormItem key={'sso'}>
						<Alert
							action={
								<Link to="/buyer/login">
									<Button size="small" type="ghost">
										Go back
									</Button>
								</Link>
							}
							message="Please login with SSO"
							type="warning"
						/>
					</FormItem>
				) : null}
				<FormItem {...formItemLayout} label="Name" hasFeedback>
					{getFieldDecorator('fullName', {
						initialValue:
							(nameGiven || '').concat(nameFamily || '') &&
							(nameGiven || '').concat(' ', nameFamily || ''),
						rules: [
							{
								required: true,
								message: 'Please enter your name!',
							},
							{
								type: 'array',
								min: 2,
								transform(value) {
									if (!value) return [];
									return value.split(' ');
								},
								message: 'Please input your full name!',
							},
						],
					})(<Input disabled={nameGiven || nameFamily ? true : false} />)}
				</FormItem>
				<FormItem {...formItemLayout} label="E-mail" hasFeedback>
					{getFieldDecorator('email', {
						initialValue: email,
						rules: [
							{
								type: 'email',
								message: 'The input is not valid email!',
							},
							{
								required: true,
								message: 'Please input your email!',
							},
						],
					})(<Input disabled={email ? true : false} />)}
				</FormItem>
				<FormItem {...formItemLayout} label="Password">
					{getFieldDecorator('password', {
						rules: [
							{
								required: true,
								message: 'Please input your password!',
							},
						],
					})(<Input type="password" />)}
				</FormItem>
				{reviewModalMode ? (
					<FormItem>
						<Button
							type="primary"
							style={{ width: '100%' }}
							loading={loading}
							size="large"
							htmlType="submit"
						>
							Register and post review
						</Button>
					</FormItem>
				) : (
					<FormItem {...tailFormItemLayout}>
						<Button type="primary" loading={loading} htmlType="submit">
							Register
						</Button>
						<span style={{ marginLeft: 8 }}>
							<BackButton buttonText="Go back" />
						</span>
					</FormItem>
				)}
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	email: ownProps.email,
	errors: state.session.errors,
	loading: state.session.loading,
	history: ownProps.history,
	reviewModalMode: ownProps.reviewModalMode,
	successCallback: ownProps.successCallback,
});

const mapDispatchToProps = (dispatch) => ({
	signUp: (username, password, nameGiven, nameFamily, gender, roles, facilityId) =>
		dispatch(signUp('buyer')(username, password, nameGiven, nameFamily, gender, roles, facilityId)),
	getAndValidateDomain: (username) => dispatch(SSODomainForBuyer(username)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Form.create<SignupFormProps>()(BuyerSignupForm))
);

import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { Button, Form, Modal, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import OWRadioGroup from '../ow_radio_group/OWRadioGroup';
import { StepInputTypes } from '../../utils/TroubleshootingUtils';
import OWRichTextEditor from '../../rich_text_editor/OWRichTextEditor';
import FileUploader from '../file_uploader/FileUploader';
import { connect } from 'react-redux';
import { retrieveCachedUserDetails } from '../../thunks/session_thunks';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import { imageUploadValidation } from '../../utils/ImageUtils';
import OWUpload from '../OWUpload';

const FORM_NAME = 'troubleshooting-step-form';

const UPLOAD_TYPES = {
	IMAGE: 'image',
	PDF: 'pdf',
	VIDEO: 'video',
};

const Uploader: FC<any> = ({
	type,
	accept,
	title,
	handleUpdate,
	handleRemove,
}): React.ReactElement => {
	const uploadHeaders = { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token };

	return (
		<OWUpload
			accept={accept}
			name="file"
			listType="picture-card"
			className="servicecall-image-uploader"
			multiple={true}
			headers={uploadHeaders}
			action={`${getBackendUri()}/api/v1/buyer/file/upload`}
			beforeUpload={type === UPLOAD_TYPES.IMAGE ? imageUploadValidation : null}
			onChange={handleUpdate(type)}
			onRemove={handleRemove(type)}
		>
			<div style={{ display: 'inline' }}>
				<div className="ant-upload-text">{title}</div>
			</div>
		</OWUpload>
	);
};

const TroubleshootingStepModal: FC<any> = ({
	step,
	onCancel,
	onSuccess,
	currentUser,
}): React.ReactElement => {
	const [form] = useForm();

	const titleRef = useRef(null);
	const descriptionRef = useRef(null);

	const [existingAttachments, setExistingAttachments] = useState(
		nullSafeGetOrElse('attachments', step, [])
	);
	const [allPhotos, setAllPhotos] = useState([]);
	const [allPdfs, setAllPdfs] = useState([]);
	const [allVideos, setAllVideos] = useState([]);

	const userType = useMemo(() => currentUser.userType, [currentUser.userType]);

	const inputRequired = Form.useWatch('inputRequired', form);

	const isUpdate = useMemo(() => !!nullSafeGet('orderingId', step), [step]);

	const title = useMemo(() => (isUpdate ? 'Update Step' : 'Add Step'), [isUpdate]);

	const getDescription = useCallback(
		() => nullSafeGet('current', descriptionRef) && descriptionRef.current.getContent(),
		[]
	);

	const getTitle = useCallback(
		() => nullSafeGet('current', titleRef) && titleRef.current.getContent(),
		[]
	);

	const getAttachmentsFormat =
		(type) =>
		(fileList): [any] =>
			fileList
				.map((file) => {
					const fileId = nullSafeGet('response.data.fileId', file);
					const fileName = nullSafeGet('response.data.fileName', file);
					return fileId && fileName
						? {
								attachmentType: type,
								fileIdWithName: `${fileId}/${fileName}`,
						  }
						: undefined;
				})
				.filter((_) => !!_);

	const onSubmit = useCallback(
		(values) => {
			const entity = {
				stepType: 'message',
				...(step || {}),
				...values,
				title: getTitle(),
				description: getDescription(),
				attachments: [
					...existingAttachments,
					...getAttachmentsFormat(UPLOAD_TYPES.IMAGE)(allPhotos),
					...getAttachmentsFormat(UPLOAD_TYPES.PDF)(allPdfs),
					...getAttachmentsFormat(UPLOAD_TYPES.VIDEO)(allVideos),
				],
			};
			onSuccess(entity);
		},
		[allPdfs, allPhotos, allVideos, existingAttachments, getDescription, getTitle, onSuccess, step]
	);

	//Just need to hanlde remove as it is read and remove only
	const handleFileAttachmentUploadChange = (newAttachments) =>
		setExistingAttachments((eas) =>
			eas.filter((ea) => {
				const fileId = nullSafeGet('0', ea.fileIdWithName.split('/'));
				return !!newAttachments.find((_) => !!_.fileId && _.fileId === fileId);
			})
		);

	const existingAttachmentsParsed = useMemo(
		() =>
			nullSafeGetOrElse('attachments', step, []).map((attchment) => ({
				...attchment,
				fileId: nullSafeGet('0', nullSafeGetOrElse('fileIdWithName', attchment, '').split('/')),
				fileName: nullSafeGet('1', nullSafeGetOrElse('fileIdWithName', attchment, '').split('/')),
			})),
		[step]
	);

	const handleUpdate = useCallback(
		(type) => (info) => {
			if (info.file.status === 'done') {
				const fileList = info.fileList.filter((_) => _.status === 'done');
				switch (type) {
					case UPLOAD_TYPES.IMAGE:
						setAllPhotos(fileList);
						break;
					case UPLOAD_TYPES.PDF:
						setAllPdfs(fileList);
						break;
					case UPLOAD_TYPES.VIDEO:
						setAllVideos(fileList);
						break;
				}
			}
		},
		[]
	);

	const handleRemove = (type) => (file) => {
		const filterFileList = (ap) => ap.filter((_) => _.uid !== file.uid);
		switch (type) {
			case UPLOAD_TYPES.IMAGE:
				setAllPhotos((ap) => filterFileList(ap));
				break;
			case UPLOAD_TYPES.PDF:
				setAllPdfs((ap) => filterFileList(ap));
				break;
			case UPLOAD_TYPES.VIDEO:
				setAllVideos((av) => filterFileList(av));
				break;
		}
	};

	return (
		<Modal
			visible={true}
			width={600}
			title={title}
			closable
			onCancel={onCancel}
			forceRender
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button
					type="primary"
					size="large"
					style={{ marginLeft: '16px' }}
					key="submit"
					htmlType="submit"
					form={FORM_NAME}
				>
					{title}
				</Button>,
			]}
		>
			<Form form={form} id={FORM_NAME} layout="vertical" initialValues={step} onFinish={onSubmit}>
				<Form.Item
					name="title"
					label="Title"
					rules={[
						{
							required: true,
							message: `Please enter title`,
						},
					]}
				>
					<OWRichTextEditor initialEditorState={nullSafeGet('title', step)} editorRef={titleRef} />
				</Form.Item>
				<Form.Item name="description" label="Description">
					<OWRichTextEditor
						initialEditorState={nullSafeGet('Description', step)}
						editorRef={descriptionRef}
					/>
				</Form.Item>
				<Form.Item name="canProceedToNextStep" label="Allowed to Next Step" initialValue={false}>
					<OWRadioGroup
						valueAccessor={(item) => item.value}
						renderRecord={(item) => item.label}
						items={[
							{
								label: 'Yes',
								value: true,
							},
							{
								label: 'No',
								value: false,
							},
						]}
					/>
				</Form.Item>
				<Form.Item name="inputRequired" label="Input Required" initialValue={false}>
					<OWRadioGroup
						valueAccessor={(item) => item.value}
						renderRecord={(item) => item.label}
						items={[
							{
								label: 'Yes',
								value: true,
							},
							{
								label: 'No',
								value: false,
							},
						]}
					/>
				</Form.Item>
				{inputRequired ? (
					<Form.Item name="inputType" label="Input Type">
						<Select style={{ width: '100%' }}>
							{StepInputTypes.map((f) => (
								<Select.Option key={f.value} value={f.value}>
									{f.label}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				) : null}
				<Form.Item name="fileList" label="Attachments">
					<div className="flex flex-row items-center">
						<div>
							<Uploader
								type={UPLOAD_TYPES.IMAGE}
								accept="image/*"
								title="Upload Photo"
								handleUpdate={handleUpdate}
								handleRemove={handleRemove}
							/>
						</div>
						<div className="ml-4">
							<Uploader
								type={UPLOAD_TYPES.PDF}
								accept="application/pdf"
								title="Upload Pdf"
								handleUpdate={handleUpdate}
								handleRemove={handleRemove}
							/>
						</div>
						<div className="ml-4">
							<Uploader
								type={UPLOAD_TYPES.VIDEO}
								accept="video/*"
								title="Upload Video"
								handleUpdate={handleUpdate}
								handleRemove={handleRemove}
							/>
						</div>
					</div>
					<div className="rowSpacing">
						<FileUploader
							roleType={userType}
							defaultFileList={existingAttachmentsParsed}
							handleUploadSuccess={handleFileAttachmentUploadChange}
							uploaderType={'readAndRemove'}
						/>
					</div>
				</Form.Item>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

export default connect(mapStateToProps)(TroubleshootingStepModal);

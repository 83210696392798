import * as React from 'react';
import { Layout, DatePicker, Button, List } from 'antd';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router';
import { clearNavbarState, updateNavbarState } from '../../actions/navbar_actions';
import { generateBackLink } from '../../utils/HistoryUtils';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import {
	approvalsRestCrudThunksForBuyer,
	getBuyerCompanyApprovalTiers,
} from '../../thunks/company_approval_thunks';

const queryString = require('qs');

const ListItem = List.Item;
const { Content } = Layout;
const { RangePicker } = DatePicker;
const DEFAULT_BACK_LINK = '/buyer/approvalHierarchies/overview/templates';

class ApprovalHierarchyDetailPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			lightboxIsOpen: false,
			currentIndex: 0,
			backLinkTo: DEFAULT_BACK_LINK,
			backLinkText: 'Back to Approval Hierarchies',
		};
	}

	componentDidMount() {
		const { getApprovalHierarchy, match, updateNavbar, location } = this.props;
		const id = match.params.id;
		getApprovalHierarchy(id).then((approvalHierarchy) => {
			let searchString = location.search;
			if (searchString[0] === '?') {
				searchString = searchString.slice(1);
			}
			const queryParams = queryString.parse(searchString);
			if (queryParams.backlinkParams) {
				const { tab, pathName, backLinkText, ...otherParams } = queryString.parse(
					queryParams.backlinkParams
				);
				let backPath = pathName || '/buyer/approvalHierarchies/overview/templates';
				let backText = backLinkText || 'Back to Approval Hierarchies';
				updateNavbar(backText, generateBackLink(backPath, location));
			} else {
				updateNavbar(
					'Back to Approval Hierarchies',
					generateBackLink('/buyer/approvalHierarchies/overview/templates', location)
				);
			}
		});
	}
	componentWillUnmount(): void {
		this.props.clearNavbar();
	}

	handleTabChange = (key) => {
		const { history, match } = this.props;
		const id = match.params.id;
		history.push(`/buyer/approvalHierarchies/${id}/${key}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	handleDelete = () => {
		const { history, deleteAsset, asset } = this.props;
		deleteAsset(asset).then(() => {
			history.push('/buyer/approvalHierarchies/');
		});
	};

	render() {
		const { approvalHierarchy, approvalHierarchiesFetching, match } = this.props;
		const id = match.params.id;
		let tabs = [];
		return (
			<DetailPageHeader
				exactPath={`/buyer/approvalHierarchies/overview/templates`}
				redirectPath={`/buyer/approvalHierarchies/overview/templates`}
				backLinkText={this.state.backLinkText || 'Back to Templates'}
				backLinkTo={this.state.backLinkTo}
				title={id === 'new' ? 'New Approval Hierarchy' : approvalHierarchy.name}
				subtitle={<div style={{ display: 'flex', flexWrap: 'wrap' }}></div>}
				subtext={<div></div>}
				actions={[]}
				tabs={tabs}
				handleTabChange={this.handleTabChange}
				checkTabActive={this.checkTabActive}
				childRoutes={this.props.childRoutes}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	approvalHierarchiesFetching: state.approval_hierarchies.fetching,
	approvalHierarchy: state.approval_hierarchies.detail,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	getApprovalHierarchy: (id) => dispatch(approvalsRestCrudThunksForBuyer.readOne(id)),
	updateNavbar: (backlinkTitle, backlinkTo, navbarBackgroundColor) =>
		dispatch(updateNavbarState(backlinkTitle, backlinkTo, navbarBackgroundColor)),
	clearNavbar: () => dispatch(clearNavbarState()),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ApprovalHierarchyDetailPage)
);

import { METERS_CRUD_ACTION_CREATORS } from '../actions/meters_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';

export const metersRestCrudThunksForBuyer = new RestCrudThunks(
	'meters',
	'meter',
	'meter',
	'meters',
	METERS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'asset/'
);
export const metersRestCrudThunksForSupplier = new RestCrudThunks(
	'meters',
	'meter',
	'meter',
	'meters',
	METERS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'asset/'
);

import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Alert, Button, Select } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import BackButton from '../back_button/BackButton';
import { metersRestCrudThunksForBuyer } from '../../thunks/meters_thunks';
import { meterTypesRestCrudThunksForBuyer } from '../../thunks/meter_types_thunks';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { assetsRestCrudThunksForBuyer } from '../../thunks/assets_thunks';
import { locationsRestCrudThunksForBuyer } from '../../thunks/locations_thunks';
import { assetTypesRestCrudThunksForBuyer } from '../../thunks/asset_types_thunks';
import AssetNumberDisplay from '../asset_row_display/AssetNumberDisplay';

const METER_FORM_LOCATIONS_AUTOCOMPLETE = 'METER_FORM_LOCATIONS_AUTOCOMPLETE';
const METER_FORM_ASSET_TYPES_AUTOCOMPLETE = 'METER_FORM_ASSET_TYPES_AUTOCOMPLETE';
const METER_FORM_ASSETS_AUTOCOMPLETE = 'METER_FORM_ASSETS_AUTOCOMPLETE';
const METER_FORM_METER_TYPES_AUTOCOMPLETE = 'METER_FORM_METER_TYPES_AUTOCOMPLETE';
const queryString = require('qs');

interface MeterFormProps extends FormComponentProps {
	creating: boolean;
	updating: boolean;
	formData: any;
	history: any;
	location: any;
	locations: any;
	assetTypes: any;
	assets: any;
	fetchLocation: any;
	fetchLocations: any;
	fetchMultipleLocations: any;
	fetchAssetType: any;
	fetchAssetTypes: any;
	fetchMultipleAssetTypes: any;
	fetchAsset: any;
	fetchAssets: any;
	fetchMultipleAssets: any;
	fetchMeterType: any;
	fetchMeterTypes: any;
	fetchMultipleMeterTypes: any;
	meterTypesFetching: any;
	meterTypes: any;
	redirectForwardUrl?: string;
	onSuccess?: any;
	createErrors: any[];
	updateErrors: any[];
	currentUser: any;
	create: any;
	update: any;
}

class MeterForm extends React.Component<MeterFormProps, any> {
	state = {
		meterType: {},
		asset: {},
		location: {},
		assetType: {},
		readingType: null,
	};

	componentDidMount() {
		let searchString = this.props.location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		const assetId = queryParams.assetId;
		const meterTypeId = queryParams.meterTypeId;
		if (assetId) {
			this.handleAssetChange(assetId);
		}
		if (meterTypeId) {
			this.handleMeterTypeChange(meterTypeId);
		}
	}

	handleLocationChange = (id) => {
		const { fetchLocation, fetchAssets } = this.props;
		if (id) {
			fetchLocation(id).then((location) => {
				this.setState({ location: location });
				fetchAssets({ locationId: location.id });
			});
		}
	};

	handleAssetTypeChange = (id) => {
		const { fetchAssetType, fetchAssets } = this.props;
		if (id) {
			fetchAssetType(id).then((assetType) => {
				this.setState({ assetType: assetType });
				fetchAssets({
					locationId: nullSafeGet('location.id', this.state),
					assetTypeId: assetType.id,
				});
			});
		}
	};

	handleAssetChange = (id) => {
		const { fetchAsset } = this.props;
		if (id) {
			fetchAsset(id).then((asset) => {
				this.setState({ asset });
			});
		}
	};

	handleMeterTypeChange = (id) => {
		const { fetchMeterType } = this.props;
		if (id) {
			fetchMeterType(id).then((meterType) => {
				this.setState({ meterType });
			});
		}
	};

	handleReadingTypeChange = (value) => {
		this.setState({ readingType: value });
	};

	handleSubmit = (e, submitAction) => {
		const { history, onSuccess, redirectForwardUrl } = this.props;
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				let meter = {
					id: values.id,
					name: values.name,
					locationId: parseInt(nullSafeGet('asset.locationId', this.state), 10),
					assetId: values.assetId,
					meterTypeId: values.meterTypeId,
					valueType: nullSafeGet('meterType.valueType', this.state),
					valueUnit: nullSafeGet('meterType.valueUnit', this.state),
					seriesType: values.seriesType,
					defaultExpectedValue: values.defaultExpectedValue,
				};

				submitAction(meter).then((record) => {
					if (redirectForwardUrl) {
						history.push(redirectForwardUrl);
					}
					if (onSuccess) {
						onSuccess(record);
					}
				});
			}
		});
	};

	render() {
		const {
			updating,
			creating,
			form,
			update,
			create,
			createErrors,
			formData,
			updateErrors,
			currentUser,
			fetchAssets,
			fetchMultipleAssets,
			assets,
			fetchAssetTypes,
			fetchMultipleAssetTypes,
			assetTypes,
			fetchLocations,
			fetchMultipleLocations,
			locations,
			fetchMeterTypes,
			fetchMultipleMeterTypes,
			meterTypes,
		} = this.props;
		const { getFieldDecorator } = form;
		const isUpdate = formData && formData.id !== undefined;
		const submitAction = isUpdate ? update : create;
		const submitText = isUpdate ? 'Update meter' : 'Create meter';
		const isLoading = updating || creating;

		getFieldDecorator('buyerCompanyId', {
			initialValue: formData.buyerCompanyId || nullSafeGet('facility.buyerCompanyId', currentUser),
		});
		getFieldDecorator('id', { initialValue: formData.id });

		const readingTypes = ['Point', 'Comparable', 'Baseline'];

		return (
			<Form
				layout="vertical"
				onSubmit={(e) => this.handleSubmit(e, submitAction)}
				className="supplierForm"
			>
				{createErrors.length > 0 ? (
					<Form.Item>
						<Alert message={createErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				{updateErrors.length > 0 ? (
					<Form.Item>
						<Alert message={updateErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				<Form.Item label="Name">
					{getFieldDecorator('name', {
						initialValue: formData.name,
					})(<Input style={{ maxWidth: 640 }} />)}
				</Form.Item>
				<Form.Item label="Location">
					{getFieldDecorator('locationId', {
						initialValue: formData.locationId || nullSafeGet('location.id', this.state),
						rules: [
							{
								required: true,
								message: 'This field is required.',
							},
						],
					})(
						<OWAsyncSelect
							style={{ maxWidth: 640 }}
							stateSlice={locations}
							targetCollectionName={METER_FORM_LOCATIONS_AUTOCOMPLETE}
							onChange={this.handleLocationChange}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleLocations(ids, targetCollectionName);
							}}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchLocations(
									{ name: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							renderRecord={(location) => (
								<Select.Option key={location.id} value={location.id}>
									{location.name}
								</Select.Option>
							)}
							sortBy={{ sort_by: 'name', order: 'ascend' }}
						/>
					)}
				</Form.Item>
				<Form.Item label="Asset Type">
					{getFieldDecorator('assetTypeId', {
						initialValue:
							nullSafeGet('asset.assetTypeId', formData) || nullSafeGet('assetType.id', this.state),
					})(
						<OWAsyncSelect
							style={{ maxWidth: 640 }}
							stateSlice={assetTypes}
							targetCollectionName={METER_FORM_ASSET_TYPES_AUTOCOMPLETE}
							onChange={this.handleAssetTypeChange}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleAssetTypes(ids, targetCollectionName);
							}}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchAssetTypes(
									{ name: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							renderRecord={(assetType) => (
								<Select.Option key={assetType.id} value={assetType.id}>
									{assetType.name}
								</Select.Option>
							)}
							sortBy={{ sort_by: 'name', order: 'ascend' }}
						/>
					)}
				</Form.Item>
				<Form.Item label="Asset">
					{getFieldDecorator('assetId', {
						initialValue: formData.assetId || nullSafeGet('asset.id', this.state),
						rules: [
							{
								required: true,
								message: 'This field is required.',
							},
						],
					})(
						<OWAsyncSelect
							style={{ maxWidth: 640 }}
							stateSlice={assets}
							targetCollectionName={METER_FORM_ASSETS_AUTOCOMPLETE}
							onChange={this.handleAssetChange}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleAssets(ids, targetCollectionName);
							}}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchAssets(
									{
										search: searchText,
										locationId: nullSafeGetOrElse('location.id', this.state, formData.locationId),
										assetTypeId: nullSafeGetOrElse(
											'assetType.id',
											this.state,
											formData.assetTypeId
										),
									},
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							renderRecord={(asset) => (
								<Select.Option key={asset.id} value={asset.id}>
									<div className="flex flex-col">
										<div>{asset.name}</div>
										<AssetNumberDisplay asset={asset} />
									</div>
								</Select.Option>
							)}
							sortBy={{ sort_by: 'name', order: 'ascend' }}
						/>
					)}
				</Form.Item>
				<Form.Item label="Meter Type">
					{getFieldDecorator('meterTypeId', {
						initialValue: formData.meterTypeId,
						rules: [
							{
								required: true,
								message: 'This field is required.',
							},
						],
					})(
						<OWAsyncSelect
							style={{ maxWidth: 640 }}
							stateSlice={meterTypes}
							targetCollectionName={METER_FORM_METER_TYPES_AUTOCOMPLETE}
							onChange={this.handleMeterTypeChange}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleMeterTypes(ids, targetCollectionName);
							}}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchMeterTypes(
									{ name: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							renderRecord={(meterType) => (
								<Select.Option key={meterType.id} value={meterType.id}>
									{meterType.name}
								</Select.Option>
							)}
							sortBy={{ sort_by: 'name', order: 'ascend' }}
						/>
					)}
				</Form.Item>
				<Form.Item label="Type of Reading">
					{getFieldDecorator('seriesType', {
						initialValue: formData.seriesType,
						rules: [
							{
								required: true,
								message: 'This field is required.',
							},
						],
					})(
						<Select style={{ maxWidth: 640 }} onChange={this.handleReadingTypeChange}>
							{readingTypes.map((choice, idx) => (
								<Select.Option key={idx} value={choice.toLowerCase()}>
									{choice}
								</Select.Option>
							))}
						</Select>
					)}
				</Form.Item>

				{nullSafeGet('readingType', this.state) == 'comparable' ? (
					<Form.Item label="Default Value for Comparison">
						{getFieldDecorator('defaultExpectedValue', {
							initialValue: formData.defaultExpectedValue,
						})(<Input style={{ maxWidth: 640 }} placeholder="" />)}
					</Form.Item>
				) : null}

				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						loading={isLoading}
						className="contactForm__button"
					>
						{submitText}
					</Button>
					<span style={{ marginLeft: '16px' }}>
						<BackButton buttonText="Cancel" />
					</span>
				</Form.Item>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	location: ownProps.location,
	formData: ownProps.formData,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,

	meterTypesFetching: state.meter_types.fetching,
	meterTypes: state.meter_types,
	locations: state.locations,
	locationsFetching: state.locations.fetching,
	assetTypes: state.asset_types,
	assetTypesFetching: state.asset_types.fetching,
	assetsFetching: state.assets.fetching,
	assets: state.assets,
	createErrors: state.meters.createErrors,
	updateErrors: state.meters.updateErrors,
	creating: state.meters.creating,
	updating: state.meters.updating,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	update: (entity) => dispatch(metersRestCrudThunksForBuyer.update(entity)),
	create: (entity) => dispatch(metersRestCrudThunksForBuyer.create(entity)),
	fetchLocation: (id) => dispatch(locationsRestCrudThunksForBuyer.readOne(id)),
	fetchLocations: (params) =>
		dispatch(locationsRestCrudThunksForBuyer.read(params, METER_FORM_LOCATIONS_AUTOCOMPLETE)),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchAssetType: (id) => dispatch(assetTypesRestCrudThunksForBuyer.readOne(id)),
	fetchAssetTypes: (params) =>
		dispatch(assetTypesRestCrudThunksForBuyer.read(params, METER_FORM_ASSET_TYPES_AUTOCOMPLETE)),
	fetchMultipleAssetTypes: (ids, targetCollectionName) =>
		dispatch(assetTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchAsset: (id) => dispatch(assetsRestCrudThunksForBuyer.readOne(id)),
	fetchAssets: (params) =>
		dispatch(assetsRestCrudThunksForBuyer.read(params, METER_FORM_ASSETS_AUTOCOMPLETE)),
	fetchMultipleAssets: (ids, targetCollectionName) =>
		dispatch(assetsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchMeterType: (id) => dispatch(meterTypesRestCrudThunksForBuyer.readOne(id)),
	fetchMeterTypes: (params, targetCollectionName) =>
		dispatch(meterTypesRestCrudThunksForBuyer.read(params, targetCollectionName)),
	fetchMultipleMeterTypes: (ids, targetCollectionName) =>
		dispatch(meterTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(Form.create<MeterFormProps>()(MeterForm))
);

import React, { FC, useEffect, useState } from 'react';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import { connect } from 'react-redux';
import { Form, Select, Switch } from 'antd';

const FormItem = Form.Item;

const StockLocationFormFields: FC<any> = ({
	user,
	stockLocations,
	fetchStockLocations,
	fetchMultipleStockLocations,
	setFieldsValue,
}): React.ReactElement => {
	const [hasAccessToAllStockLocations, setHasAccessToAllStockLocations] = useState(true);

	useEffect(() => {
		setFieldsValue &&
			setFieldsValue({
				...(hasAccessToAllStockLocations && { stockLocationIds: [] }),
				hasAccessToAllStockLocations,
				canManageAllStockLocations: hasAccessToAllStockLocations,
			});
	}, [hasAccessToAllStockLocations, setFieldsValue]);

	useEffect(() => {
		if (user) {
			setHasAccessToAllStockLocations(
				typeof user.hasAccessToAllStockLocations !== 'undefined'
					? user.hasAccessToAllStockLocations
					: true
			);
			setFieldsValue({
				stockLocationIds: nullSafeGet('stockLocationIds', user),
			});
		}
	}, [setFieldsValue, user]);

	return (
		<>
			<FormItem name="canManageAllStockLocations" hidden />
			<FormItem name="hasAccessToAllStockLocations" valuePropName="checked" label="Stock Locations">
				<Switch checked={hasAccessToAllStockLocations} onChange={setHasAccessToAllStockLocations} />
				<label style={{ marginLeft: 8 }}>Select All Stock Locations</label>
			</FormItem>
			<FormItem name="stockLocationIds">
				<OWAsyncSelect
					mode="multiple"
					disabled={hasAccessToAllStockLocations}
					emptyWhenDisabled={true}
					stateSlice={stockLocations}
					targetCollectionName={'fieldTechsDropdown'}
					fetchMultiple={(ids, targetCollectionName) => {
						fetchMultipleStockLocations(ids, targetCollectionName);
					}}
					fetchData={(
						searchText,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
					) => {
						fetchStockLocations(
							{ name: searchText },
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						);
					}}
					renderRecord={(d) => (
						<Select.Option value={d.id} key={d.id}>
							{d.name}
						</Select.Option>
					)}
					sortBy={{ sort_by: 'name', order: 'ascend' }}
				/>
			</FormItem>
		</>
	);
};

const mapStateToProps = (state) => ({
	stockLocations: state.stock_locations,
});

const mapDispatchToProps = (dispatch) => ({
	fetchMultipleStockLocations: (ids, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchStockLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			stockLocationsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
});

export default connect(mapStateToProps, mapDispatchToProps)(StockLocationFormFields);

import { EmptyState } from '../../empty_state/EmptyState';
import { Button, Row } from 'antd';
import { nullSafeGet } from '../../../utils/DataAccessUtils';
import * as React from 'react';
import { BUYER_ONLY_NO_STORE_ASSOCIATE } from '../../roles/roles';
import { Link } from 'react-router-dom';

export function EmergencyWorkOrderTableEmptyState(currentUser) {
	return (
		<EmptyState
			graphic={
				<img
					style={{ marginBottom: 8 }}
					src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
				/>
			}
			headline={'No emergencies in this project!'}
			body={
				<div style={{ textAlign: 'center' }}>
					<div style={{ maxWidth: 440, marginBottom: 16 }}>
						Looks like your team doesn't have any emergencies in this project.
					</div>
				</div>
			}
			height={225}
		/>
	);
}
export function StaleWorkOrderTableEmptyState(currentUser) {
	return (
		<EmptyState
			graphic={
				<img
					style={{ marginBottom: 8 }}
					src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
					alt=""
				/>
			}
			headline={'No stale work orders in this project!'}
			body={
				<div style={{ textAlign: 'center' }}>
					<div style={{ maxWidth: 440, marginBottom: 16 }}>
						Looks like your team doesn't have any stale work orders in this project.
					</div>
				</div>
			}
			height={225}
		/>
	);
}
export function CompletedNotInvoicedWorkOrderTableEmptyState(currentUser) {
	return (
		<EmptyState
			graphic={
				<img
					style={{ marginBottom: 8 }}
					src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
					alt=""
				/>
			}
			headline={'No completed work orders without invoices in this project!'}
			body={
				<div style={{ textAlign: 'center' }}>
					<div style={{ maxWidth: 440, marginBottom: 16 }}>
						Looks like your team doesn't have any completed work orders that have not been invoiced
						in this project.
					</div>
				</div>
			}
			height={225}
		/>
	);
}
export function PendingApprovalWorkOrderTableEmptyState(props) {
	return (
		<EmptyState
			graphic={
				<img
					style={{ marginBottom: 8 }}
					src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
					alt=""
				/>
			}
			headline={'No work orders pending approval in this project!'}
			body={
				<div style={{ textAlign: 'center' }}>
					<div style={{ maxWidth: 440, marginBottom: 16 }}>
						Looks like your team doesn't have any work orders pending approval in this project.
					</div>
				</div>
			}
			height={225}
		/>
	);
}
export function QuoteApprovalWorkOrderTableEmptyState(props) {
	return (
		<EmptyState
			graphic={
				<img
					style={{ marginBottom: 8 }}
					src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
				/>
			}
			headline={'No quotes waiting on approval in this project!'}
			body={
				<div style={{ textAlign: 'center' }}>
					<div style={{ maxWidth: 440, marginBottom: 16 }}>
						Looks like your team doesn't have any quotes waiting for approval in this project.
					</div>
				</div>
			}
			height={225}
		/>
	);
}
export function BuyerWorkOrderTableEmptyState({ currentUser }) {
	return (
		<>
			{currentUser.roles &&
			currentUser.roles.some((role) => BUYER_ONLY_NO_STORE_ASSOCIATE.has(role)) ? (
				<EmptyState
					graphic={
						<img
							style={{ marginBottom: 8 }}
							src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
							alt=""
						/>
					}
					headline={"It's a little lonely in here."}
					body={
						<div style={{ textAlign: 'center' }}>
							<div style={{ maxWidth: 440, marginBottom: 16 }}>
								Looks like your team has not created any work orders yet.
							</div>
							<Link to="/buyer/workOrders/newWorkOrder">
								<Button type="primary">Create your first work order</Button>
							</Link>
						</div>
					}
				/>
			) : (
				<EmptyState
					graphic={
						<img
							style={{ marginBottom: 8 }}
							src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
							alt=""
						/>
					}
					headline={"It's a little lonely in here."}
					body={
						<div style={{ textAlign: 'center' }}>
							<div style={{ maxWidth: 440, marginBottom: 16 }}>
								Looks like your team has not created any service requests yet.
							</div>
							<Link
								to={
									[2483, 2489, 2490].includes(nullSafeGet('facility.buyerCompanyId', currentUser))
										? '/buyer/workOrders/requestService'
										: '/buyer/workOrders/requestServiceForm'
								}
							>
								<Button type="primary">Create your first service request</Button>
							</Link>
						</div>
					}
				/>
			)}
		</>
	);
}
export function SupplierWorkOrderTableEmptyState({}) {
	return (
		<EmptyState
			graphic={
				<img
					style={{ marginBottom: 8 }}
					src="https://s3.amazonaws.com/mock-data-assets/categories/images/lighthouse.svg"
				/>
			}
			headline={"We can't find any work orders for you."}
			body={
				<div style={{ textAlign: 'center' }}>
					<div style={{ maxWidth: 450, marginBottom: 16 }}>
						Make yourself more visible to buyers looking for your services by adding to your
						company's profile!
					</div>
					<Link to="/supplier/company/me">
						<Button type="primary">Edit company profile</Button>
					</Link>
				</div>
			}
		/>
	);
}

export const DashboardWOEmptyState = (): React.ReactElement => {
	return (
		<Row
			justify="center"
			style={{
				fontSize: '16px',
				color: 'rgba(0, 0, 0, .5)',
			}}
		>
			No work orders in this view!
		</Row>
	);
};

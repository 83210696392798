import { PURCHASE_ORDERS_CRUD_ACTION_CREATORS } from '../actions/purchase_orders_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { parseJSON } from './fetch_utils';
import { logoutSuccess } from '../actions/session_actions';
import FetchUtils from './fetch_utils';
import { retrieveCachedUserDetails } from './session_thunks';
import POPipeLineFilters from '../components/purchase_orders_index_page/POPipelineFIlters';
import { message } from 'antd';

const fetchUtils = new FetchUtils();
const BASE_URL = getBackendUri();

export const purchaseOrdersRestCrudThunksForBuyer = new RestCrudThunks(
	'purchase_orders',
	'purchase_order',
	'purchaseOrder',
	'purchaseOrders',
	PURCHASE_ORDERS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER
);
export const purchaseOrdersRestCrudThunksForSupplier = new RestCrudThunks(
	'purchase_orders',
	'purchase_order',
	'purchaseOrder',
	'purchaseOrders',
	PURCHASE_ORDERS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'inventory/'
);

export const generatePurchaseOrderPDFForSupplier = (id) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/supplier/inventory/purchase_orders/${id}/generate_pdf`;
	return {
		url: baseUrl + apiUrl,
		httpHeaders: { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token },
	};
};

export const sendPurchaseOrderByEmailForSupplier = (id, email, data) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/supplier/inventory/purchase_orders/${id}/send_email/${email}`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.post(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const refreshPOCountsForSupplier = (filters) => (dispatch) => {
	const apiUrl = `/api/v1/supplier/inventory/purchase_orders/count_by_status`;

	const data = {
		...(filters || {}),
		statuses: POPipeLineFilters.map((_) => _.name).join(','),
		status: undefined,
	};
	fetchUtils
		.get(
			BASE_URL + apiUrl,
			Object.assign(
				{
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
				},
				data
			)
		)
		.then(parseJSON)
		.then(({ json }) => {
			const d = json;
			if (d.status === 'error') {
				message.error(d.message);
			} else {
				if (d.data && d.data.length > 0) {
					d.data.map((category) =>
						dispatch(
							PURCHASE_ORDERS_CRUD_ACTION_CREATORS.countBySuccess(
								category.count,
								category.categoryName
							)
						)
					);
				}
			}
		})
		.catch((d) => {
			message.error(d.message);
		});
};

const updatePurchaseOrderStatusForSupplier = (status: String) => (entity: any) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/supplier/inventory/purchase_orders/${entity.id}/${status}`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(PURCHASE_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.patch(
					baseUrl + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(PURCHASE_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(PURCHASE_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(entity));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(PURCHASE_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

export const markOrderedPurchaseOrderForSupplier = updatePurchaseOrderStatusForSupplier('ordered');
export const cancelPurchaseOrderForSupplier = updatePurchaseOrderStatusForSupplier('cancel');
export const closePurchaseOrderForSupplier = updatePurchaseOrderStatusForSupplier('closed');

import moment from 'moment';

export const JOURNAL_TYPE = {
	IMPAIRMENT: 'impairment',
	DEPRECIATION: 'journal_history',
	EXISTING_DEPRECIATION: 'existingDepreciation',
};

export const getJournalValue = (history) =>
	history.entityType === JOURNAL_TYPE.IMPAIRMENT
		? history.impairmentAdjustment
		: history.depreciationValue;

export const addNBVs = (capitalizedCost, histories, existingDepreciation) => {
	const modifiedHistories = [
		...histories,
		...(existingDepreciation
			? [
					{
						depreciationValue: existingDepreciation,
						entityType: 'existingDepreciation',
					},
			  ]
			: []),
	];
	return modifiedHistories
		.reverse()
		.reduce(
			(acc, history) => {
				const val = getJournalValue(history);
				const cost = acc.cost - (val || 0);
				return {
					cost,
					histories: [
						...acc.histories,
						{
							...history,
							netBookValue: typeof val !== 'undefined' ? cost : undefined,
							initialValue: acc.cost,
						},
					],
				};
			},
			{
				cost: capitalizedCost,
				histories: [],
			}
		)
		.histories.reverse();
};

const getMonthsTillEndDate = (tillDate) => {
	const currentDate = moment();
	const allDates = [];
	while (currentDate.isBefore(tillDate)) {
		const dateObj = currentDate.endOf('month');
		allDates.push({
			dateObj: dateObj.clone(),
			date: dateObj.format(`MMM YYYY`),
		});
		currentDate.add('months', 1);
	}

	return allDates;
};

const isFiscalEnd = (date, fiscal) => date.month() === fiscal.month - 1;

export const getDecliningBalanceProjection = ({
	capitalisedCost,
	nbv,
	tillDate,
	depreciationRate,
	fiscal,
}) => {
	const allDates = getMonthsTillEndDate(tillDate);

	return allDates
		.reduce(
			(acc, obj, idx) => {
				const { initialNbv, nbv, data } = acc;
				const depreciation = (initialNbv * (depreciationRate / 100)) / 12;
				const newNbv = nbv - Number(depreciation);
				return {
					initialNbv: isFiscalEnd(obj.dateObj, fiscal) ? newNbv : initialNbv,
					nbv: newNbv,
					data: [
						...data,
						{
							id: idx,
							date: obj.date,
							depreciation,
							nbv: newNbv,
						},
					],
				};
			},
			{
				initialNbv: nbv,
				nbv,
				data: [],
			}
		)
		.data.reverse();
};

export const getStraightLineProjection = ({
	installDate,
	capitalisedCost,
	nbv,
	tillDate,
	usefulLifeInMonths,
	salvageValue = 0,
}) => {
	const dep = Number(capitalisedCost / usefulLifeInMonths).toFixed(2);

	const allDates = getMonthsTillEndDate(tillDate);

	const data = allDates
		.reduce(
			(acc, obj, idx) => {
				const { nbv, data } = acc;
				const newNbv = nbv - Number(dep) > salvageValue ? nbv - Number(dep) : salvageValue;
				return {
					nbv: newNbv,
					data: [
						...data,
						{
							id: idx,
							date: obj.date,
							depreciation: dep,
							nbv: newNbv,
						},
					],
				};
			},
			{
				nbv,
				data: [],
			}
		)
		.data.reverse();

	return data;
};

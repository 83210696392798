import * as React from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import PartOrEquipmentAvatar from '../common/PartOrEquipmentAvatar';
import GreyStatusTag from '../equipment_per_stock_location_row_display/GreyStatusTag';

require('./PartPerStockLocationRowDisplay.less');

export const PartPerStockLocationRowDisplay: React.FC<any> = ({
	partPerStockLocation,
	hideImage,
	...props
}) => {
	return (
		<div
			className="partPerStockLocationRowDisplay"
			style={{ justifyContent: 'space-between' }}
			{...props}
		>
			<div className="partPerStockLocationRowDisplay">
				{!hideImage && (
					<div style={{ marginRight: 24 }}>
						<PartOrEquipmentAvatar item={partPerStockLocation.part || {}} />
					</div>
				)}
				<div>
					<div className="partPerStockLocationRowDisplay__rowTitle">
						<Ellipsis tooltip={true} length={56} className={'pb-0.5'}>
							{nullSafeGet('part.name', partPerStockLocation)}
						</Ellipsis>
					</div>
					<div className="partPerStockLocationRowDisplay__rowSubtitle">
						{nullSafeGet('part.partNumber', partPerStockLocation) ? (
							<div className="partPerStockLocationRowDisplay__rowInlineGroup">
								Part #{nullSafeGet('part.partNumber', partPerStockLocation)}
							</div>
						) : null}
						{nullSafeGet('part.description', partPerStockLocation) && (
							<div
								className="partPerStockLocationRowDisplay__rowInlineGroup"
								style={{ display: 'inline-flex' }}
							>
								<Ellipsis tooltip={true} length={80}>
									{nullSafeGet('part.description', partPerStockLocation)}
								</Ellipsis>
							</div>
						)}
					</div>
					{!partPerStockLocation.isStocked && (
						<div className="partPerStockLocationRowDisplay__rowSubtitle">
							<GreyStatusTag text="Not Stocked" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Alert } from 'antd';
import { connect } from 'react-redux';
import Result from 'ant-design-pro/lib/Result';
import { changePassword } from '../../thunks/session_thunks';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { Link } from 'react-router-dom';

const style = require('./BuyerChangePasswordForm.less');
const FormItem = Form.Item;

interface ChangePasswordFormProps extends FormComponentProps {
	changePassword: any;
	loading: boolean;
	errors: any;

	username: string;
}

class BuyerChangePasswordForm extends React.Component<ChangePasswordFormProps, any> {
	state = {
		confirmDirty: false,
		passwordChanged: false,
	};
	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.props.changePassword(values.currentPassword, values.newPassword).then(() => {
					this.setState({ passwordChanged: true });
				});
			}
		});
	};

	checkPassword = (rule, value, callback) => {
		const { form } = this.props;
		const otherValue = form.getFieldValue('confirmNewPassword');
		const currentPassword = form.getFieldValue('currentPassword');
		if (value && otherValue && value !== otherValue) {
			callback("The new passwords you entered don't match!");
		} else if (value && currentPassword && value === currentPassword) {
			callback("Your new password can't be the same as your current password.");
		} else {
			callback();
		}
	};

	checkConfirm = (rule, value, callback) => {
		const { form } = this.props;
		const { confirmDirty } = this.state;
		const otherValue = form.getFieldValue('newPassword');
		if (value && otherValue && confirmDirty && value !== otherValue) {
			callback("The new passwords you entered don't match!");
		} else {
			callback();
		}
	};

	handleConfirmBlur = (e) => {
		const { form } = this.props;
		const value = e.target.value;
		this.setState({ confirmDirty: this.state.confirmDirty || !!value }, () =>
			form.validateFields(['confirmNewPassword'], { force: true }, () => {})
		);
	};

	render() {
		const formItemLayout = {
			labelCol: {
				sm: { span: 24 },
				md: { span: 8 },
			},
			wrapperCol: {
				sm: { span: 24 },
				md: { span: 14 },
			},
		};
		const formItemLayout2 = {
			wrapperCol: {
				sm: { span: 24 },
				md: { span: 12, offset: 8 },
			},
		};
		const formItemLayout3 = {
			wrapperCol: {
				sm: { span: 24 },
				md: { span: 14, offset: 8 },
			},
		};
		const { getFieldDecorator } = this.props.form;
		const { loading, errors } = this.props;
		const { passwordChanged } = this.state;

		return passwordChanged ? (
			<Result
				type="success"
				title={<div>Your password has been reset.</div>}
				description={
					<div>
						<p className="signupForm__successExplanation">
							We've updated your password as requested.
						</p>
						<p className="signupForm__successExplanation">
							If you forget it again, you can always request another reset by following the 'Forgot
							your password?' link from the login page.
						</p>
					</div>
				}
				actions={
					<div style={{ marginBottom: 24 }}>
						<Link to="/buyer/login">
							<Button type="primary">Back to log in</Button>
						</Link>
					</div>
				}
			/>
		) : (
			<Form onSubmit={this.handleSubmit} className="changePasswordForm">
				{errors.length > 0
					? errors.map((error, idx) => (
							<FormItem {...formItemLayout3} key={idx}>
								<Alert message={error} type="error" />
							</FormItem>
					  ))
					: null}
				<FormItem {...formItemLayout} label="Current Password" hasFeedback={true}>
					{getFieldDecorator('currentPassword', {
						rules: [{ required: true, message: 'Please input your password!' }],
					})(<Input type="password" />)}
				</FormItem>
				<FormItem {...formItemLayout} label="New Password" hasFeedback={true}>
					{getFieldDecorator('newPassword', {
						rules: [
							{
								required: true,
								message: 'Please input your new password!',
							},
							{
								validator: this.checkPassword,
							},
						],
					})(<Input type="password" />)}
				</FormItem>
				<FormItem {...formItemLayout} label="Confirm New Password" hasFeedback={true}>
					{getFieldDecorator('confirmNewPassword', {
						rules: [
							{
								required: true,
								message: 'Please confirm your new password!',
							},
							{
								validator: this.checkConfirm,
							},
						],
						validateTrigger: ['onBlur', 'onChange', 'onFocus'],
					})(<Input type="password" onBlur={this.handleConfirmBlur} />)}
				</FormItem>
				<FormItem {...formItemLayout2}>
					<Button
						type="primary"
						htmlType="submit"
						loading={loading}
						className="changePasswordForm__button"
					>
						Change password
					</Button>
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	loading: state.session.loading,
	errors: state.session.errors,
});

const mapDispatchToProps = (dispatch) => ({
	changePassword: (currentPassword, newPassword) =>
		dispatch(changePassword('buyer')(currentPassword, newPassword)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create<ChangePasswordFormProps>()(BuyerChangePasswordForm));

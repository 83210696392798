import { crudActionCreatorsGenerator } from './crud_action_creators_generator';
import { crudActionConstantsGenerator } from './crud_action_constants_generator';
import { updateNavbarState } from './navbar_actions';

export const PURCHASE_ORDERS_CRUD_ACTION_CONSTANTS =
	crudActionConstantsGenerator('PURCHASE_ORDERS');
const PURCHASE_ORDERS_CRUD_ACTION_CREATORS = crudActionCreatorsGenerator(
	PURCHASE_ORDERS_CRUD_ACTION_CONSTANTS
);

const originalFn = PURCHASE_ORDERS_CRUD_ACTION_CREATORS.updateSuccess;
PURCHASE_ORDERS_CRUD_ACTION_CREATORS.updateSuccess = function (proposal) {
	return function (dispatch, getState) {
		const { navbar } = getState();
		const { backlinkTitle, navbarBackgroundColor } = navbar;
		if (navbar && navbar.backlinkTo) {
			const [path, queryParams] = navbar.backlinkTo.split('?');
			const urlSearchParams = new URLSearchParams(queryParams);
			urlSearchParams.delete('preserve');
			const backlinkTo = `${path}?${urlSearchParams.toString()}`;
			dispatch(updateNavbarState(backlinkTitle, backlinkTo, navbarBackgroundColor));
		}
		dispatch(originalFn(proposal));
	};
};
export { PURCHASE_ORDERS_CRUD_ACTION_CREATORS };

import { ContactAvatar } from '../contact_avatar/ContactAvatar';
import React, { FC, useCallback } from 'react';
import { Button, Col, Row } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

const UserListItemComponent: FC<any> = ({
	removeUser,
	user,
	token,
	allowRemove,
	rightItems,
	userType,
}): React.ReactElement => {
	const onRemoveUser = useCallback(() => {
		removeUser(user);
	}, [removeUser, user]);

	return user.contact !== undefined ? (
		<Row
			style={{ alignItems: 'center', width: '100%', paddingTop: '6px', paddingBottom: '6px' }}
			key={user.contact.email}
		>
			<Col>
				<ContactAvatar
					hidePopover={true}
					placement="bottomRight"
					userType={userType}
					contact={user.contact}
					token={token}
					className={'contactAvatar'}
				/>
			</Col>
			<Col
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'left',
					marginLeft: '8px',
				}}
			>
				<Row style={{ fontSize: 14, color: 'rgba(0,0,0,0.8)' }}>
					{user.contact.nameGiven} {user.contact.nameFamily}
				</Row>
				<Row style={{ fontSize: 12, color: 'rgba(0,0,0,0.6)' }}>{user.contact.email}</Row>
			</Col>
			<Col style={{ marginLeft: 'auto' }}>
				{rightItems}
				<Button
					className="RemoveUser"
					onClick={allowRemove ? onRemoveUser : null}
					style={{
						border: 'none',
						paddingTop: '2px',
						boxShadow: 'none',
						textAlign: 'center',
						outline: 'none',
					}}
				>
					{allowRemove ? (
						<MinusCircleOutlined translate="" className="dynamic-delete-button" />
					) : null}
				</Button>
			</Col>
		</Row>
	) : (
		<Row style={{ alignItems: 'center', width: '100%', paddingTop: '6px', paddingBottom: '6px' }}>
			<Col
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'left',
					marginLeft: '8px',
				}}
			>
				<Row>{user.email}</Row>
			</Col>
			<Col style={{ marginLeft: 'auto' }}>{rightItems}</Col>
		</Row>
	);
};

const mapStateToProps = (state) => ({
	userType: state.session.userType,
});

export default connect(mapStateToProps)(UserListItemComponent);

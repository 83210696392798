import React, { FC, useMemo } from 'react';
import { connect } from 'react-redux';
import { ROLE_TYPES } from '../../utils/DataConstants';
import {
	PERMISSION_NAMES,
	isBuyerAllowedToAccess,
	isInternalTech,
	isSupplierAllowedToAccess,
} from '../../utils/AuthUtils';
import PermissionDeniedPage from '../permission_denied_page/PermissionDeniedPage';

const THIRD_PARTY_NOT_ALLOWED = [PERMISSION_NAMES.CAN_BACKDATE_WORK_ORDER];

const AccessPermissionChecker: FC<any> = ({
	isPage,
	name,
	userType,
	exclude3rdPartySuppliers,
	companyConfig,
	currentUser,
	children,
	permissionDeniedContent = null,
}): React.ReactElement => {
	const isThirdParty = useMemo(
		() => userType === ROLE_TYPES.SUPPLIER && !isInternalTech(currentUser),
		[currentUser, userType]
	);

	const ignorePermissionCheck = useMemo(
		() => isThirdParty && exclude3rdPartySuppliers,
		[exclude3rdPartySuppliers, isThirdParty]
	);

	const noSupplierAccess = useMemo(
		() => isThirdParty && THIRD_PARTY_NOT_ALLOWED.includes(name),
		[isThirdParty, name]
	);

	const hasAccess = useMemo(
		() =>
			userType === ROLE_TYPES.SUPPLIER
				? !noSupplierAccess &&
				  (ignorePermissionCheck ||
						isSupplierAllowedToAccess(name, companyConfig, currentUser.roles))
				: isBuyerAllowedToAccess(name, companyConfig, currentUser.roles),
		[companyConfig, currentUser, ignorePermissionCheck, name, noSupplierAccess, userType]
	);

	return !name || hasAccess ? (
		children
	) : isPage ? (
		<PermissionDeniedPage />
	) : (
		permissionDeniedContent
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
	companyConfig: state.company_config.detail,
});

const ComponentWithoutUserType = connect(mapStateToProps)(AccessPermissionChecker);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import * as React from 'react';
import { Layout, Row, Card, Col } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { siteSurveyTemplatesRestCrudThunksForSupplier } from '../../thunks/site_survey_templates_thunks';
import { TaskList } from '../task_list/TaskList';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;

require('../site_survey_templates_detail_details_page/SiteSurveyTemplatesDetailDetailsPage.less');

class SupplierSiteSurveyTemplatesDetailDetailsPage extends React.Component<any, any> {
	componentDidMount() {
		const { getSiteSurveyTemplate, match } = this.props;
		const siteSurveyTemplateId = match.params.id;
		getSiteSurveyTemplate(siteSurveyTemplateId);
	}

	render() {
		const { siteSurveyTemplate, siteSurveyTemplatesFetching } = this.props;

		return siteSurveyTemplatesFetching ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="siteSurveyTemplatesDetailDetailsPage">
				<LogOnMountWithStandardEventProperties eventType="visited supplier site survey template detail page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row gutter={16}>
					{siteSurveyTemplate.description ? (
						<Col span={24} className="rowSpacing">
							<Card>
								<div className="siteSurveyTemplatesDetailDetailsPage__descSection">
									<div className="siteSurveyTemplatesDetailDetailsPage__descSection">
										{siteSurveyTemplate.description}
									</div>
								</div>
							</Card>
						</Col>
					) : null}
					<Col span={24} className="rowSpacing">
						<Card title="Tasks">
							<TaskList tasks={nullSafeGetOrElse('taskList.tasks', siteSurveyTemplate, [])} />
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	siteSurveyTemplatesFetching: state.site_survey_templates.fetching,
	siteSurveyTemplate: state.site_survey_templates.detail,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	getSiteSurveyTemplate: (id) => dispatch(siteSurveyTemplatesRestCrudThunksForSupplier.readOne(id)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(SupplierSiteSurveyTemplatesDetailDetailsPage)
);

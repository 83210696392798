import {
	getCurrency,
	getEquipmentId,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';

const UOM_VALUES = [
	{ label: 'each', value: 1 },
	{ label: 'box', value: 2 },
	{ label: 'pound', value: 3 },
];

const getUOMNameFromId = (id) =>
	nullSafeGetOrElse(
		'label',
		UOM_VALUES.find((_) => _.value === id),
		'each'
	);

const getCurrencyOptions = (currencies) =>
	currencies.map((c) => ({
		label: c,
		value: c,
	}));

export const PART_FLATFILE_HEADER: any[] = [
	{
		label: 'Record ID',
		key: 'id',
	},
	{
		label: 'OW Part ID',
		key: 'partId',
		validators: [{ validate: 'required' }, { validate: 'unique' }],
	},
	{
		label: 'Name',
		key: 'name',
		alternates: ['Part Name'],
	},
	{
		label: 'Part Number',
		key: 'partNumber',
		alternates: ['Part Number'],
		validators: [{ validate: 'required' }, { validate: 'unique' }],
	},
	{
		label: 'Is Stocked',
		key: 'isStocked',
		type: 'checkbox',
		validators: [{ validate: 'required' }],
	},
	{
		label: 'On Hand Qty',
		key: 'onHandQuantity',
		validators: [
			{ validate: 'required' },
			{
				validate: 'regex_matches',
				regex: `d*`,
			},
		],
	},
	{
		label: 'Min Qty',
		key: 'minQuantity',
		validators: [
			{ validate: 'required' },
			{
				validate: 'regex_matches',
				regex: `d*`,
			},
		],
	},
	{
		label: 'Max Qty',
		key: 'maxQuantity',
		validators: [
			{ validate: 'required' },
			{
				validate: 'regex_matches',
				regex: `d*`,
			},
		],
	},
	{
		label: 'Aisle',
		key: 'aisle',
	},
	{
		label: 'Bay',
		key: 'bay',
	},
	{
		label: 'Level',
		key: 'level',
	},
	{
		label: 'Bin',
		key: 'bin',
	},
];

export const EQUIPMENT_FLATFILE_HEADER: any[] = [
	{
		label: 'Record ID',
		key: 'id',
	},
	{
		label: 'OW Equipment Type ID',
		key: 'equipmentTypeId',
		alternates: ['OW Equipment Type ID'],
		validators: [{ validate: 'required' }],
	},
	{
		label: 'Name',
		key: 'name',
		alternates: ['Equipment Name'],
	},
	{
		label: 'Equipment Type #',
		key: 'equipmentTypeNumber',
		alternates: ['Equipment Type #'],
	},
	{
		label: 'Min Qty',
		key: 'minQuantity',
		validators: [
			{ validate: 'required' },
			{
				validate: 'regex_matches',
				regex: `d*`,
			},
		],
	},
	{
		label: 'Max Qty',
		key: 'maxQuantity',
		validators: [
			{ validate: 'required' },
			{
				validate: 'regex_matches',
				regex: `d*`,
			},
		],
	},
];

export const getReceivedEquipmentFlatfileHeader = (allEquipmentTypesNames = []): any[] => [
	{
		label: 'Equipment ID',
		key: 'id',
	},
	{
		label: 'Equipment Type #',
		key: 'equipmentTypeId',
		type: 'select',
		options: allEquipmentTypesNames,
		alternates: ['Equipment', 'equipmentTypeNumber'],
		validators: [{ validate: 'required' }],
	},
	{
		label: 'Name',
		key: 'name',
		alternates: ['Equipment Name'],
	},
	{
		label: 'Serial Number',
		key: 'serialNumber',
		validators: [{ validate: 'required' }],
	},
	{
		label: 'Cost',
		key: 'cost',
	},
	{
		label: 'Is Rental',
		key: 'isRental',
		type: 'checkbox',
		validators: [{ validate: 'required' }],
	},
];

export const EQUIPMENT_EXPORT_HEADER = [
	'Equipment ID',
	'Equipment Type #',
	'Name',
	'Serial Number',
	'Cost',
	'Is Rental',
];

export const TRANSFER_TO_LOCATION_EQUIPMENT_FLATFILE_HEADER = (
	locations = [],
	assetTypes = [],
	assetModels = [],
	transferNoteMandatory = false
): any[] => [
	{
		label: 'Equipment #',
		key: 'id',
		validators: [{ validate: 'required' }],
	},
	{
		label: 'Location',
		key: 'locationId',
		type: 'select',
		options: locations,
		validators: [{ validate: 'required' }],
	},
	{
		label: 'Asset Type',
		key: 'assetTypeId',
		type: 'select',
		options: assetTypes,
	},
	{
		label: 'Asset Number',
		key: 'assetNumber',
		validators: [{ validate: 'unique' }],
	},
	{
		label: 'Asset Model',
		key: 'assetModelId',
		type: 'select',
		options: assetModels,
	},
	{
		label: 'Transfer Note',
		key: 'transferNote',
		validators: [...(transferNoteMandatory ? [{ validate: 'required' }] : [])],
	},
	{
		label: 'Effective Date (MM/DD/yyyy)',
		key: 'transferDate',
		validators: [
			{
				validate: 'regex_matches',
				regex: `[0-9]{1,2}/[0-9]{1,2}/[0-9]{2,4}`,
			},
		],
	},
];

export const TRANSFER_TO_STOCK_LOCATION_EQUIPMENT_FLATFILE_HEADER = (
	stockLocations = [],
	transferNoteMandatory
): any[] => [
	{
		label: 'Equipment #',
		key: 'id',
		validators: [{ validate: 'required' }],
	},
	{
		label: 'Destination Stock Location',
		key: 'stockLocationId',
		type: 'select',
		options: stockLocations,
		alternates: ['Name', 'stock locations', 'locations'],
		validators: [{ validate: 'required' }],
	},
	{
		label: 'Transfer Note',
		key: 'transferNote',
		validators: [...(transferNoteMandatory ? [{ validate: 'required' }] : [])],
	},
	{
		label: 'Effective Date (MM/DD/yyyy)',
		key: 'transferDate',
		validators: [
			{
				validate: 'regex_matches',
				regex: `[0-9]{1,2}/[0-9]{1,2}/[0-9]{2,4}`,
			},
		],
	},
];

export const PART_MASTER_FLATFILE_HEADER = (currencies: string[]): any[] => [
	{
		label: 'OW Part ID',
		key: 'id',
		validators: [{ validate: 'unique' }],
	},
	{
		label: 'Name',
		key: 'name',
		alternates: ['Part Name'],
	},
	{
		label: 'Part Number',
		key: 'partNumber',
		alternates: ['Part Number'],
		validators: [{ validate: 'required' }, { validate: 'unique' }],
	},
	{
		label: 'Alias Part Number',
		key: 'aliasPartId',
		alternates: ['Alias Part Number'],
	},
	{
		label: 'Description',
		key: 'description',
	},
	{
		label: 'Manufacturer Name',
		key: 'manufacturerName',
	},
	{
		label: 'Manufacturer ID',
		key: 'manufacturerId',
	},
	{
		label: 'Default Vendor Name',
		key: 'defaultVendorName',
	},
	{
		label: 'Unit of Measure',
		key: 'uomId',
		type: 'select',
		options: UOM_VALUES,
	},
	{
		label: 'Cost',
		key: 'cost',
		validators: [
			{
				validate: 'regex_matches',
				regex: `d*`,
			},
		],
	},
	{
		label: 'Currency',
		key: 'currency',
		type: 'select',
		options: getCurrencyOptions(currencies),
	},
];

export const EQUIPMENT_MASTER_FLATFILE_HEADER = (currencies: string[]): any[] => [
	{
		label: 'OW Equipment Type ID',
		key: 'id',
	},
	{
		label: 'Model Name',
		key: 'modelName',
		alternates: ['Equipment Name'],
		validators: [{ validate: 'required' }],
	},
	{
		label: 'Equipment Type #',
		key: 'equipmentTypeNumber',
		alternates: ['Equipment Type ID'],
		validators: [{ validate: 'unique' }],
	},
	{
		label: 'Description',
		key: 'description',
	},
	{
		label: 'Manufacturer Name',
		key: 'manufacturerName',
	},
	{
		label: 'Manufacturer ID',
		key: 'manufacturerId',
	},
	{
		label: 'Cost',
		key: 'cost',
		validators: [
			{
				validate: 'regex_matches',
				regex: `d*`,
			},
		],
	},
	{
		label: 'Currency',
		key: 'currency',
		type: 'select',
		options: getCurrencyOptions(currencies),
	},
];

export const getFlatfileRecordForEquipmentLevel = (record = {}) => ({
	id: nullSafeGetOrElse('id', record, ''),
	equipmentTypeId: nullSafeGetOrElse('equipmentType.id', record, '--'),
	name: nullSafeGetOrElse('equipmentType.modelName', record, '--'),
	equipmentTypeNumber: nullSafeGetOrElse('equipmentType.equipmentTypeNumber', record, '--'),
	onHandQuantity: nullSafeGetOrElse('onHandQuantity', record, 0),
	minQuantity: nullSafeGetOrElse('minQuantity', record, 0),
	maxQuantity: nullSafeGetOrElse('maxQuantity', record, 0),
});

export const getFlatfileRecordForReceivedEquipment = (record = {}) => ({
	id: nullSafeGetOrElse('id', record, ''),
	equipmentTypeId: nullSafeGetOrElse('equipmentTypeId', record, ''),
	name: nullSafeGetOrElse('name', record, '--'),
	serialNumber: nullSafeGetOrElse('serialNumber', record, ''),
	cost: nullSafeGetOrElse('cost', record, ''),
	isRental: nullSafeGetOrElse('isRental', record, false) ? 'Yes' : 'No',
});

export const getFlatfileRecordForReceivedEquipmentExport = (record = {}) => ({
	equipmentId: getEquipmentId(record),
	equipmentTypeNumber: nullSafeGetOrElse('equipmentType.equipmentTypeNumber', record, ''),
	name: nullSafeGetOrElse('name', record, '--'),
	serialNumber: nullSafeGetOrElse('serialNumber', record, ''),
	cost: nullSafeGetOrElse('cost', record, ''),
	isRental: nullSafeGetOrElse('isRental', record, false) ? 'Yes' : 'No',
});

export const getFlatfileRecordForTransferToLocationEquipment = (record = {}) => ({
	id: nullSafeGetOrElse('id', record, ''),
	locationId: nullSafeGetOrElse('asset.locationId', record, ''),
	assetTypeId: nullSafeGetOrElse('equipmentType.assetTypeId', record, ''),
	assetNumber: nullSafeGetOrElse('assetNumber', record, ''),
	assetModelId: nullSafeGetOrElse('modelName', record, ''),
	transferNote: nullSafeGetOrElse('transferNote', record, ''),
	transferDate: nullSafeGetOrElse('transferDate', record, ''),
});

export const getFlatFileRecordForPartLevel = (record = {}) => ({
	id: nullSafeGetOrElse('id', record, ''),
	partId: nullSafeGetOrElse('part.id', record, '--'),
	name: nullSafeGetOrElse('part.name', record, '--'),
	partNumber: nullSafeGetOrElse('part.partNumber', record, '--'),
	isStocked: nullSafeGetOrElse('isStocked', record, false),
	onHandQuantity: parseInt(nullSafeGetOrElse('onHandQuantity', record, 0)),
	minQuantity: parseInt(nullSafeGetOrElse('minQuantity', record, 0)),
	maxQuantity: parseInt(nullSafeGetOrElse('maxQuantity', record, 0)),
	aisle: nullSafeGetOrElse('aisle', record, ''),
	bay: nullSafeGetOrElse('bay', record, ''),
	level: nullSafeGetOrElse('level', record, ''),
	bin: nullSafeGetOrElse('bin', record, ''),
});

export const getFlatfileRecordForPart = (record = {}, currency) => ({
	id: nullSafeGetOrElse('id', record, ''),
	name: nullSafeGetOrElse('name', record, ''),
	partNumber: nullSafeGetOrElse('partNumber', record, ''),
	aliasPartId: nullSafeGetOrElse('aliasPartId', record, ''),
	description: nullSafeGetOrElse('description', record, ''),
	manufacturerName: nullSafeGetOrElse('manufacturerName', record, ''),
	manufacturerId: nullSafeGetOrElse('manufacturerId', record, ''),
	defaultVendorName: nullSafeGetOrElse('defaultVendor.name', record, ''),
	uomId: getUOMNameFromId(nullSafeGetOrElse('uomId', record, '')),
	cost: nullSafeGet('cost', record) ? parseFloat(nullSafeGet('cost', record)) : '',
	currency,
});

export const getFlatfileRecordForEquipment = (record = {}, currency) => ({
	id: nullSafeGetOrElse('id', record, ''),
	modelName: nullSafeGetOrElse('modelName', record, ''),
	equipmentTypeNumber: nullSafeGetOrElse('equipmentTypeNumber', record, ''),
	description: nullSafeGetOrElse('description', record, ''),
	manufacturerName: nullSafeGetOrElse('manufacturerName', record, ''),
	manufacturerId: nullSafeGetOrElse('manufacturerId', record, ''),
	cost: nullSafeGet('cost', record) ? parseFloat(nullSafeGet('cost', record)) : '',
	currency,
});

export const getStockLocationPartsLevelExportFields = (currentUser) => [
	{
		label: 'Part Name',
		key: 'part.name',
	},
	{
		label: 'Part Number',
		key: 'part.partNumber',
	},
	{
		label: 'Min Qty',
		key: 'minQuantity',
	},
	{
		label: 'Max Qty',
		key: 'maxQuantity',
	},
	{
		label: 'Total Qty',
		key: 'onHandQuantity',
		default: 0,
	},
	{
		label: 'Requested Qty',
		key: 'requestedQuantity',
		default: 0,
	},
	{
		label: 'Available Qty',
		key: 'availableQuantity',
		renderFn: (val) => (val < 0 ? 0 : val),
	},
	{
		label: 'Total Cost',
		key: 'totalStockedPartsCost',
	},
	{
		label: 'Currency',
		key: 'currency',
		default: getCurrency({ currentUser }).id,
	},
];

export const getStockLocationEquipmentTypeLevelExportFields = (currentUser) => [
	{
		label: 'Equipment Type Name',
		key: 'equipmentType.modelName',
	},
	{
		label: 'Equipment Type Number',
		key: 'equipmentType.equipmentTypeNumber',
	},
	{
		label: 'Min Qty',
		key: 'minQuantity',
	},
	{
		label: 'Max Qty',
		key: 'maxQuantity',
	},
	{
		label: 'Total Qty',
		key: 'onHandQuantity',
		default: 0,
	},
	{
		label: 'Requested Qty',
		key: 'requestedQuantity',
		default: 0,
	},
	{
		label: 'Available Qty',
		key: 'onHandQuantity',
		default: 0,
	},
	{
		label: 'Total Cost',
		key: 'totalStockedEquipmentCost',
	},
	{
		label: 'Currency',
		key: 'currency',
		default: getCurrency({ currentUser }).id,
	},
];

export const getRecordForExport = (record, fields) =>
	fields.reduce((acc, field) => {
		const value = field.key
			? nullSafeGetOrElse(field.key, record, field.default !== undefined ? field.default : '--')
			: record;
		return {
			...acc,
			[field.label]: field.renderFn ? field.renderFn(value) : value,
		};
	}, {});

export const getEquipmentTypeFlatfileOptions = (equipmentTypes) =>
	equipmentTypes.map((_) => ({
		label: `${_.equipmentTypeNumber ? `${_.equipmentTypeNumber} - ` : ''}${_.modelName}`,
		value: _.id,
		assetTypeId: _.assetTypeId,
	}));

import * as React from 'react';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { Card } from 'antd';
import moment from 'moment';
import { TwoLineList } from '../two_line_list/TwoLineList';
import { dateFormatter } from '../../utils/DataFormatterUtils';

export const WarrantyCardDisplay: React.FC<any> = ({ warranty }) => {
	if (!warranty.issueDate || !warranty.expirationDate) {
		return null;
	}
	const issued = moment(warranty.issueDate);
	const expires = moment(warranty.expirationDate);
	const warrantyActive = moment().isBetween(issued, expires);
	let warrantyDetails = [];

	if (warranty.provider) {
		warrantyDetails.push({
			key: 'Provider',
			value: nullSafeGet('provider.name', warranty),
		});
	}
	if (warranty.issueDate) {
		warrantyDetails.push({
			key: 'Issued',
			value: dateFormatter(warranty.issueDate),
		});
	}
	if (warranty.expirationDate) {
		warrantyDetails.push({
			key: 'Expires',
			value: dateFormatter(warranty.expirationDate),
		});
	}
	return (
		<Card className="materialCard" bodyStyle={{ padding: 0 }}>
			<div className="materialCard__body">
				<div className="materialCard__primaryTitle">
					<div className="materialCard__title">
						{warrantyActive ? 'Under Warranty' : 'Warranty Expired'}
					</div>
				</div>
				<TwoLineList
					listItems={warrantyDetails}
					lineOneRender={(record) => record.value}
					lineTwoRender={(record) => record.key}
				/>
			</div>
		</Card>
	);
};

import * as React from 'react';
import { Layout, DatePicker, Button, List } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fieldTechsRestCrudThunksForSupplier } from '../../thunks/field_techs_thunks';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { Link } from 'react-router-dom';
import { FlexibleImageAvatar } from '../flexible-image-avatar/FlexibleImageAvatar';
import { getProtectedImageUriForSupplier } from '../../utils/FileAccessUtils';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';

const style = require('./FieldTechsDetailPage.less');
const ListItem = List.Item;
const ListItemMeta = ListItem.Meta;
const { Content } = Layout;
const { RangePicker } = DatePicker;
const dateFormat = 'MM-DD-YYYY';

interface FieldTechsDetailPageState {}

interface FieldTechsDetailPageProps {
	match: any;
}

class FieldTechsDetailPage extends React.Component<any, FieldTechsDetailPageState> {
	componentDidMount() {
		const { getFieldTech, match } = this.props;
		const fieldTechId = match.params.id;
		getFieldTech(fieldTechId);
	}

	handleTabChange = (key) => {
		const { history, match } = this.props;
		const fieldTechId = match.params.id;
		history.push(`/supplier/fieldTechs/detail/${fieldTechId}/${key}`);
	};

	handleDelete = () => {
		const { history, deleteFieldTech, fieldTech } = this.props;
		deleteFieldTech(fieldTech).then(() => {
			history.push('/supplier/fieldTechs/overview/all');
		});
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	render() {
		const { fieldTech, fieldTechsFetching, match, companyConfig } = this.props;
		const fieldTechId = match.params.id;
		const tabs = [
			{
				key: 'details',
				name: 'Overview',
			},
		];
		let imgFileId, imgFileName, photoUri;
		if (nullSafeGet('supplierContact.photoLink', fieldTech)) {
			[imgFileId, imgFileName] = nullSafeGet('supplierContact.photoLink', fieldTech).split('/');
			photoUri = getProtectedImageUriForSupplier(imgFileId, imgFileName, 'auto', 240);
		}
		return (
			<DetailPageHeader
				exactPath={'/supplier/fieldTechs/detail/:id'}
				redirectPath={`/supplier/fieldTechs/detail/${fieldTechId}/details`}
				avatar={
					<div style={{ marginRight: 32 }}>
						<FlexibleImageAvatar
							displayName={
								nullSafeGet('supplierContact.nameGiven', fieldTech) +
								' ' +
								nullSafeGet('supplierContact.nameFamily', fieldTech)
							}
							showPopover={false}
							popoverText={''}
							imageUrl={photoUri}
							width="96px"
							height="96px"
						/>
					</div>
				}
				backLinkText={'Back to field techs'}
				backLinkTo={'/supplier/fieldTechs'}
				backgroundColor={nullSafeGet('detail.config.theme.navblock', companyConfig)}
				title={
					fieldTechsFetching ? null : (
						<span>
							{nullSafeGet('supplierContact.nameGiven', fieldTech)}{' '}
							{nullSafeGet('supplierContact.nameFamily', fieldTech)}
						</span>
					)
				}
				subtitle={
					nullSafeGet('supplierContact.department', fieldTech) &&
					nullSafeGet('supplierContact.title', fieldTech) ? (
						<span>
							{nullSafeGet('supplierContact.department', fieldTech)},{' '}
							{nullSafeGet('supplierContact.title', fieldTech)}
						</span>
					) : null
				}
				actions={[
					<Link to={`/supplier/fieldTechs/edit/${fieldTechId}`} key={1} style={{ marginRight: 16 }}>
						<Button ghost={true}>Edit</Button>
					</Link>,
					<Button key={2} ghost={true} onClick={this.handleDelete}>
						Delete
					</Button>,
				]}
				tabs={tabs}
				handleTabChange={this.handleTabChange}
				checkTabActive={this.checkTabActive}
				childRoutes={this.props.childRoutes}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	fieldTechsFetching: state.field_techs.fetching,
	fieldTech: state.field_techs.detail,
	match: ownProps.match,
	history: ownProps.history,
	companyConfig: state.company_config,
});

const mapDispatchToProps = (dispatch) => ({
	getFieldTech: (id) => dispatch(fieldTechsRestCrudThunksForSupplier.readOne(id)),
	deleteFieldTech: (entity) =>
		dispatch(fieldTechsRestCrudThunksForSupplier.delete(entity, 'email', null, true)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FieldTechsDetailPage));

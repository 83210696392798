import { crudActionCreatorsGenerator } from './crud_action_creators_generator';
import { crudActionConstantsGenerator } from './crud_action_constants_generator';
import { updateNavbarState } from './navbar_actions';
import { recursiveRemovePreserve } from '../utils/HistoryUtils';

export const PURCHASE_REQUESTS_CRUD_ACTION_CONSTANTS =
	crudActionConstantsGenerator('PURCHASE_REQUESTS');
const PURCHASE_REQUESTS_CRUD_ACTION_CREATORS = crudActionCreatorsGenerator(
	PURCHASE_REQUESTS_CRUD_ACTION_CONSTANTS
);

const originalFn = PURCHASE_REQUESTS_CRUD_ACTION_CREATORS.updateSuccess;
PURCHASE_REQUESTS_CRUD_ACTION_CREATORS.updateSuccess = function (proposal) {
	return function (dispatch, getState) {
		const { navbar } = getState();
		const { backlinkTitle, navbarBackgroundColor } = navbar;
		if (navbar && navbar.backlinkTo) {
			const [path, queryParams] = navbar.backlinkTo.split('?');
			const urlSearchParams = new URLSearchParams(queryParams);
			const params = recursiveRemovePreserve(urlSearchParams);
			const backlinkTo = `${path}?${params.toString()}`;
			dispatch(updateNavbarState(backlinkTitle, backlinkTo, navbarBackgroundColor));
		}
		dispatch(originalFn(proposal));
	};
};

export { PURCHASE_REQUESTS_CRUD_ACTION_CREATORS };

import { EQUIPMENT_TYPES_PER_STOCK_LOCATION_CRUD_ACTION_CREATORS } from '../actions/equipment_types_per_stock_location_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import FetchUtils, { parseJSON } from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { logoutSuccess } from '../actions/session_actions';

const fetchUtils = new FetchUtils();

export const equipmentTypesPerStockLocationRestCrudThunksForBuyer = new RestCrudThunks(
	'stock_location_equipments',
	'stock_location_equipment',
	'equipmentTypesPerStockLocation',
	'equipmentTypesPerStockLocations',
	EQUIPMENT_TYPES_PER_STOCK_LOCATION_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'inventory/'
);
export const equipmentTypesPerStockLocationRestCrudThunksForSupplier = new RestCrudThunks(
	'stock_location_equipments',
	'stock_location_equipment',
	'equipmentTypesPerStockLocation',
	'equipmentTypesPerStockLocations',
	EQUIPMENT_TYPES_PER_STOCK_LOCATION_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'inventory/'
);

const fetchAllEquipmentTypesPerStockLocaction =
	(roleType) =>
	(params, sorting = null) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/inventory/all_stock_location_equipments`;

		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.data);
						} else {
							resolve(d.data);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};

export const fetchAllEquipmentTypesPerStockLocactionForSupplier =
	fetchAllEquipmentTypesPerStockLocaction(ROLE_TYPES.SUPPLIER);

import React, { useCallback } from 'react';
import { Layout, Row, Col, Card, Table, Button, Popconfirm } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import {
	markWalkthroughCompleteForSupplier,
	walkthroughsRestCrudThunksForSupplier,
} from '../../thunks/walkthroughs_thunks';
import { EmptyState } from '../empty_state/EmptyState';
import {
	getObjectValues,
	getSearchStringForBackNavigationWithBackText,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { ProtectedImageThumbnails } from '../protected_image_thumbnails/ProtectedImageThumbnails';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { groupBy } from 'lodash';
import { useEffect, useState } from 'react';
import { SimpleRecordContainer } from '../simple_record_container/SimpleRecordContainer';
import { Link } from 'react-router-dom';
import TaskEditPopup from '../common/TaskEditPopup';
import { WorkOrderRowDisplaySimple } from '../work_order_row_display_simple/WorkOrderRowDisplaySimple';
import LocationName from '../name_component/location/LocationNameComponent';
import moment from 'moment';
import { dateFormatter } from '../../utils/DataFormatterUtils';
import { UserName } from '../name_component/user/UserNameComponent';
import { KeyValueDisplay } from '../key_value_display/KeyValueDisplay';
import DateTimeHover from '../date_time_component/DateTime';

const { Content } = Layout;

require('./SupplierWalkthroughDetailDetailsPage.less');

const sanitizeTaskAnswerTitle = (groupedAnswers) =>
	Object.keys(groupedAnswers).reduce((acc, key) => {
		acc[key] = (groupedAnswers[key] || []).map((answer, idx) =>
			answer.titleId ? answer : { ...answer, titleId: `${key}-${answer.title}-${idx}` }
		);
		return acc;
	}, {});

const SupplierWalkthroughsDetailPage = ({
	getWalkthrough,
	updateWalkthrough,
	walkthroughsFetching,
	walkthrough,
	location,
	match,
	markWalkthroughComplete,
}) => {
	const walkthroughId = match.params.id;
	const [loading, setLoading] = useState(true);
	const [expandedRowKeys, setExpandedRowKeys] = useState(new Set());
	const [showEditTaskAnswer, setShowEditTaskAnswer] = useState(false);
	const [currentTaskAnswer, setCurrentTaskAnswer] = useState({});
	const [showMarkThisComplete, setShowMarkThisComplete] = useState(true);

	useEffect(() => {
		getWalkthrough(walkthroughId).then(() => setLoading(false));
	}, []);

	const toggleExpandByTitle = (title) => {
		expandedRowKeys.has(title) ? expandedRowKeys.delete(title) : expandedRowKeys.add(title);

		setExpandedRowKeys(new Set(expandedRowKeys));
	};

	const onExpandIconClick = (title) => {
		toggleExpandByTitle(title);
	};

	const workOrderBackText = 'Back to Walkthrough';
	const getWorkOrderLink = (record) => {
		const searchString = getSearchStringForBackNavigationWithBackText(workOrderBackText, location);
		const backUrl = `/supplier/workOrders/detail/${record.id}/details${
			!!searchString ? `?backlinkParams=${encodeURIComponent(searchString)}` : ''
		}`;
		return backUrl;
	};

	const onExpand = (expanded, record) => {
		toggleExpandByTitle(record.title);
	};

	const completeWalkthrough = useCallback(() => {
		markWalkthroughComplete(walkthrough.id).then(() => {
			getWalkthrough(walkthrough.id);
		});
	}, [getWalkthrough, markWalkthroughComplete, walkthrough]);

	const handleEdit = (taskAnswer) => {
		setCurrentTaskAnswer(taskAnswer);
		setShowEditTaskAnswer(true);
	};

	const hideTaskAnswerPopup = () => {
		setCurrentTaskAnswer({});
		setShowEditTaskAnswer(false);
	};

	const isWalkthroughComplete = walkthrough.status && walkthrough.status === 'complete';

	let walkthroughDetails = loading
		? {}
		: {
				'Walkthrough ID': `# ${nullSafeGet('id', walkthrough)}`,
				//Status: capitalize(walkthrough.status),
				Location: (
					<LocationName
						allowHover={false}
						mode={'inline'}
						location={walkthrough.location}
						placement={'top'}
					/>
				),
				Created:
					moment().diff(walkthrough.createdAt, 'm') <= 1 ? (
						'just now'
					) : moment().diff(walkthrough.createdAt, 'h') <= 24 ? (
						moment(walkthrough.createdAt).fromNow()
					) : (
						<DateTimeHover timestamp={nullSafeGet('createdAt', walkthrough)} />
					),
				Due:
					Math.abs(moment().diff(walkthrough.dueAt, 'm')) <= 1 ? (
						'just now'
					) : moment().diff(walkthrough.dueAt, 'h') <= 24 ? (
						moment(walkthrough.dueAt).fromNow()
					) : (
						<DateTimeHover timestamp={nullSafeGet('dueAt', walkthrough)} />
					),
				...(walkthrough.status === 'complete' && walkthrough.completedAt
					? {
							Completed: <DateTimeHover timestamp={nullSafeGet('completedAt', walkthrough)} />,
					  }
					: {}),
				...(walkthrough.completedByContact
					? {
							'Completed By': (
								<UserName
									contact={walkthrough.completedByContact}
									mode={'inline'}
									placement={'top'}
								/>
							),
					  }
					: {}),
		  };
	const filteredWalkthroughDetails = Object.keys(walkthroughDetails).reduce((acc, el) => {
		const val = walkthroughDetails[el];
		if (val !== null && val !== 'undefined' && val !== undefined && val !== '') {
			acc[el] = walkthroughDetails[el];
		}
		return acc;
	}, {});

	const TaskAnswerDetails = ({ record }) => {
		const photoStrings = record.attachments.map((p) => {
			const fileName = nullSafeGetOrElse('fileName', p, '');
			const fileId = nullSafeGetOrElse('fileId', p, '');
			return `${fileId}/${fileName}`;
		});

		const workOrders =
			record.workOrders &&
			record.workOrders.filter(
				(record) => record.displayStatus !== 'Completed' && record.displayStatus !== 'Cancelled'
			);

		return (
			<div className={'taskAnswerDetails'}>
				{record.comments && record.comments.length > 0 ? (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<div className="commentsBlurb">{record.comments}</div>
					</div>
				) : null}
				{photoStrings && photoStrings.length > 0 ? (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<div>
							<ProtectedImageThumbnails
								style={{ marginTop: 4 }}
								imageWidth={120}
								imageHeight={120}
								photoStrings={photoStrings}
								role={ROLE_TYPES.SUPPLIER}
							/>
						</div>
					</div>
				) : null}
				{workOrders && workOrders.length > 0 ? (
					<div>
						<SimpleRecordContainer
							items={workOrders.map((record) => (
								<Link to={getWorkOrderLink(record)} style={{ display: 'flex', width: '100%' }}>
									<WorkOrderRowDisplaySimple workOrder={record} simpler={true} />
								</Link>
							))}
						/>
					</div>
				) : null}
			</div>
		);
	};

	const expandRowRender = (record) => {
		return <TaskAnswerDetails record={record} />;
	};

	const recordHasContent = (record) =>
		(record.comments && record.comments.length > 0) ||
		(record.attachments && record.attachments.length > 0) ||
		(record.workOrders && record.workOrders.length > 0);

	const expandAll = () => {
		const titleIds = getObjectValues(groupedFilteredTaskAnswers)
			.flatMap((record) => record)
			.filter((record) => recordHasContent(record))
			.map((record) => record.titleId);
		setExpandedRowKeys(new Set(titleIds));
	};
	const collapseAll = () => {
		setExpandedRowKeys(new Set());
	};

	const walkthroughColumns = [
		{
			title: 'Question',
			dataIndex: 'task',
			render: (text, record) => (
				<div>
					<div className="walkthroughRowDisplay__rowTitle text-gray-500">
						<Ellipsis style={{ wordBreak: 'normal' }} tooltip={true} length={64}>
							{nullSafeGet('title', record)}
						</Ellipsis>
					</div>
					{nullSafeGet('description', record) && (
						<div className="walkthroughRowDisplay__rowSubtitle text-gray-400">
							{record.description}
						</div>
					)}
					{nullSafeGet('questionType', record) !== 'multipleChoice' && (
						<div className="walkthroughRowDisplay__rowTitle mt-2 text-gray-600">
							<div className="walkthroughRowDisplay__rowInlineGroup">
								<Ellipsis tooltip={true} style={{ fontSize: 16, display: 'inline' }} length={500}>
									{nullSafeGetOrElse('answer', record, '--')}
								</Ellipsis>
							</div>
						</div>
					)}
				</div>
			),
		},
		{
			title: 'Question',
			dataIndex: 'task',
			render: (text, record) => {
				const numComments = record.comments ? 1 : 0;
				const attachments = nullSafeGetOrElse('attachments', record, []);
				const numAttachments = attachments.length;
				return (
					<div className="flex flex-row items-center">
						<div
							className={'icons'}
							style={{
								display: 'flex',
								gap: 16,
								flexGrow: 1,
								justifyContent: 'flex-end',
								alignItems: 'center',
							}}
						>
							{numComments > 0 ? (
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
									}}
								>
									<div
										style={{
											minWidth: 10,
											margin: '0px',
										}}
									>
										{numComments}
									</div>
									<i
										style={{
											fontSize: 20,
											verticalAlign: 'middle',
											cursor: 'pointer',
											color: '#999',
											margin: '0px 2px',
											paddingLeft: '4px',
											paddingRight: '4px',
										}}
										className="icons8-font icons8-speech-bubble"
									/>
								</div>
							) : null}
							{numAttachments > 0 ? (
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
									}}
								>
									<div
										style={{
											minWidth: 10,
											margin: '0px',
										}}
									>
										{numAttachments}
									</div>
									<i
										style={{
											fontSize: 20,
											verticalAlign: 'middle',
											cursor: 'pointer',
											color: '#999',
											margin: '0px 2px',
											paddingLeft: '4px',
											paddingRight: '4px',
										}}
										className="icons8-font icons8-compact-camera"
									/>
								</div>
							) : null}
						</div>
					</div>
				);
			},
		},
		{
			title: 'Answer',
			dataIndex: 'task',
			render: (text, record) => (
				<div className="flex flex-row items-center justify-end">
					{nullSafeGet('questionType', record) === 'multipleChoice' && (
						<div className="ml-2 text-gray-600">
							<div className="walkthroughRowDisplay__rowInlineGroup whitespace-nowrap">
								<Ellipsis
									tooltip={true}
									style={{ wordBreak: 'normal', fontSize: 16, display: 'inline' }}
									length={500}
								>
									{nullSafeGetOrElse('answer', record, '--')}
								</Ellipsis>
							</div>
						</div>
					)}
					{!isWalkthroughComplete ? (
						<div className="flex-end ml-2 flex items-center">
							<Button
								style={{ marginLeft: 8 }}
								key="new"
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									handleEdit(record);
								}}
							>
								Edit
							</Button>
						</div>
					) : null}
				</div>
			),
		},
	];

	const updateAnswer = (answer) => {
		const groupedFilteredTaskAnswers =
			walkthrough.taskAnswers &&
			sanitizeTaskAnswerTitle(groupBy(walkthrough.taskAnswers, 'groupTitle'));
		const flattenedAnswers = Object.keys(groupedFilteredTaskAnswers).reduce(
			(acc, key) => [...acc, ...(groupedFilteredTaskAnswers[key] || [])],
			[]
		);
		const newWalkthrough = {
			...walkthrough,
			taskAnswers: flattenedAnswers.map((ans) => (ans.titleId === answer.titleId ? answer : ans)),
		};

		updateWalkthrough(newWalkthrough).then(() => {
			setShowEditTaskAnswer(false);
			const walkthroughId = match.params.id;
			getWalkthrough(walkthroughId);
		});
	};

	const canBeMarkedComplete =
		walkthrough.status &&
		walkthrough.status !== 'complete' &&
		nullSafeGetOrElse('taskAnswers', walkthrough, []).some((_) => !!_.answer);
	const groupedFilteredTaskAnswers =
		walkthrough.taskAnswers &&
		sanitizeTaskAnswerTitle(groupBy(walkthrough.taskAnswers, 'groupTitle'));
	return walkthroughsFetching ? (
		<PageLoadingPlaceholder />
	) : (
		<Content className="supplierWalkthroughsDetailDetailsPage">
			<LogOnMountWithStandardEventProperties eventType="visited supplier walkthrough detail page" />
			{/*<ScrollToTopOnMount/>*/}
			{/*<BackTop/>*/}
			<Row style={{ margin: '16px 4px' }} gutter={12}>
				{showMarkThisComplete && canBeMarkedComplete && (
					<Col span={24}>
						<Card className="rowSpacing">
							<h5>Do you want to mark this walkthrough complete?</h5>
							<div style={{ marginTop: 16, marginBottom: 16 }}>
								<Popconfirm
									title="Are you sure you want to mark this walkthrough complete?"
									okText="Yes"
									cancelText="No"
									onConfirm={completeWalkthrough}
								>
									<Button type="primary" key={3} style={{ marginRight: 16 }} ghost={true}>
										Mark this complete
									</Button>
								</Popconfirm>
								<Button
									key="reminder"
									style={{ marginLeft: 16 }}
									onClick={() => setShowMarkThisComplete(false)}
								>
									No, this walkthrough is not complete
								</Button>
							</div>
						</Card>
					</Col>
				)}
				<Col span={24}>
					<Card title="Walkthrough Details" className="rowSpacing">
						<div style={{ padding: 0 }} className="materialCard__body">
							<div className="materialCard__supportingText">
								<KeyValueDisplay
									horizontal={true}
									keyWidth={120}
									keyValueStore={filteredWalkthroughDetails}
								/>
							</div>
						</div>
					</Card>
				</Col>
				<Col span={24} className={'rowSpacing'}>
					{walkthrough.taskAnswers && walkthrough.taskAnswers.filter((t) => t.answer).length > 0 ? (
						<Row justify={'end'}>
							<Button type={'text'} onClick={expandAll}>
								Expand All
							</Button>
							<Button type={'text'} onClick={collapseAll}>
								Collapse All
							</Button>
						</Row>
					) : null}
				</Col>
				<Col span={24}>
					{walkthrough.taskAnswers && walkthrough.taskAnswers.filter((t) => t.answer).length > 0 ? (
						Object.keys(groupedFilteredTaskAnswers).map((gtaKey) => {
							return (
								<div key={gtaKey} className="rowSpacing">
									<Card title={gtaKey !== 'undefined' ? gtaKey : null}>
										<Table
											expandIconColumnIndex={-1}
											showHeader={false}
											pagination={false}
											loading={loading}
											columns={walkthroughColumns}
											rowKey={(el) => el.titleId}
											dataSource={groupedFilteredTaskAnswers[gtaKey]}
											// nullSafeGetOrElse('taskAnswers', walkthrough, [])}
											expandedRowKeys={expandedRowKeys as any}
											onExpand={onExpand}
											expandedRowRender={expandRowRender}
											onRow={(record, i) => ({
												onClick:
													(record.comments && record.comments.length > 0) ||
													(record.attachments && record.attachments.length > 0) ||
													(record.workOrders && record.workOrders.length > 0)
														? (event) => onExpandIconClick(record.titleId)
														: () => null,
											})}
										/>
									</Card>
								</div>
							);
						})
					) : (
						<EmptyState
							height={120}
							headline={`Walkthrough incomplete`}
							body={
								<div style={{ maxWidth: 488, textAlign: 'center' }}>
									Walkthrough has not been conducted yet
								</div>
							}
						/>
					)}
				</Col>
			</Row>
			{showEditTaskAnswer && (
				<TaskEditPopup
					taskAnswer={currentTaskAnswer}
					onCancel={hideTaskAnswerPopup}
					onSave={updateAnswer}
				/>
			)}
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	walkthroughsFetching: state.walkthroughs.fetching,
	walkthrough: state.walkthroughs.detail,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	getWalkthrough: (id) => dispatch(walkthroughsRestCrudThunksForSupplier.readOne(id)),
	updateWalkthrough: (walkthrough) =>
		dispatch(walkthroughsRestCrudThunksForSupplier.update(walkthrough)),
	markWalkthroughComplete: (id) => dispatch(markWalkthroughCompleteForSupplier(id)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SupplierWalkthroughsDetailPage)
);

import * as React from 'react';

import { Layout } from 'antd';
import { Redirect } from 'react-router';
import { CURRENT_ENV_ROLE_TYPE } from '../../utils/EnvConfigUtils';
const { Content } = Layout;
const style = require('./LoginPage.less');
const style2 = require('../../styles/blah.less');

export default class LoginPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Redirect
				to={{
					pathname: `/${CURRENT_ENV_ROLE_TYPE}/assets/overview/all`,
				}}
			/>
		);
	}
}

import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { PROBLEM_TYPES_CRUD_ACTION_CREATORS } from '../actions/problem_types_actions';
import { parseJSON } from './fetch_utils';
import { logoutSuccess } from '../actions/session_actions';
import FetchUtils from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';

export const problemTypesRestCrudThunksForBuyer = new RestCrudThunks(
	'problem_types',
	'problem_type',
	'problemType',
	'problemTypes',
	PROBLEM_TYPES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'work_order/'
);
export const problemTypesRestCrudThunksForSupplier = new RestCrudThunks(
	'problem_types',
	'problem_type',
	'problemType',
	'problemTypes',
	PROBLEM_TYPES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'work_order/'
);

const fetchUtils = new FetchUtils();
export const problemTypesTopLevel =
	(roleType) =>
	(
		params,
		targetCollectionName = null,
		pagination = null,
		sorting = null,
		filters = null,
		addToTargetCollection = false
	) => {
		// if pagination present, transform into limit/offset format and add to query params
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/work_order/problem_types/top_level`;
		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(PROBLEM_TYPES_CRUD_ACTION_CREATORS.fetchStart(targetCollectionName));

				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(PROBLEM_TYPES_CRUD_ACTION_CREATORS.fetchError(d.message, d.data));
							reject(d.message);
						} else {
							dispatch(
								PROBLEM_TYPES_CRUD_ACTION_CREATORS.fetchSuccess(
									d.data,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters,
									addToTargetCollection
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(PROBLEM_TYPES_CRUD_ACTION_CREATORS.fetchError(d.message, d.data));
						reject(d.message);
					});
			});
		};
	};
export const problemTypesHeirarchical =
	(roleType) =>
	(
		params,
		targetCollectionName = null,
		pagination = null,
		sorting = null,
		filters = null,
		addToTargetCollection = false
	) => {
		// if pagination present, transform into limit/offset format and add to query params
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/work_order/problem_types/with_children`;
		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(PROBLEM_TYPES_CRUD_ACTION_CREATORS.fetchStart(targetCollectionName));

				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(PROBLEM_TYPES_CRUD_ACTION_CREATORS.fetchError(d.message, d.data));
							reject(d.message);
						} else {
							dispatch(
								PROBLEM_TYPES_CRUD_ACTION_CREATORS.fetchSuccess(
									d.data,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters,
									addToTargetCollection
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(PROBLEM_TYPES_CRUD_ACTION_CREATORS.fetchError(d.message, d.data));
						reject(d.message);
					});
			});
		};
	};

export const problemTypesTopLevelForBuyer = problemTypesTopLevel('buyer');
export const problemTypesHeirarchicalForBuyer = problemTypesHeirarchical('buyer');
export const problemTypesHeirarchicalForSupplier = problemTypesHeirarchical('supplier');
export const problemTypesTopLevelForSupplier = problemTypesTopLevel('supplier');

import * as React from 'react';
import { ContactAvatar } from '../contact_avatar/ContactAvatar';
import { capitalize } from '../../utils/DataFormatterUtils';

export const ContactRowDisplay: React.FC<any> = ({
	contact,
	userType,
	style = {},
	...otherProps
}) => {
	if (!contact) {
		return null;
	}
	return (
		<div style={{ display: 'flex', alignItems: 'center', ...style }} {...otherProps}>
			<div>
				<ContactAvatar
					hidePopover={true}
					style={{ marginRight: 8 }}
					contact={contact}
					userType={userType}
				/>
			</div>
			<div style={{ fontSize: 16 }}>
				{capitalize(contact.nameGiven)} {capitalize(contact.nameFamily)}
			</div>
		</div>
	);
};

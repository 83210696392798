import { BUYER_CONTACTS_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/buyer_contacts_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const BUYER_CONTACT_PRIMARY_KEY = 'email';

const initialState: any = standardInitialStateGenerator([
	'eventBuyerTeamFormSearchResults',
	'associatedBuyerContactsForFacility',
	'LOCATION_FORM_BUYER_CONTACTS_AUTOCOMPLETE',
]);
export default standardReducerGenerator(
	CRUD_ACTION_CONSTANTS,
	initialState,
	BUYER_CONTACT_PRIMARY_KEY
);

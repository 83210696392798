import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	assetTypesRestCrudThunksForBuyer,
	assetTypesRestCrudThunksForSupplier,
} from '../../thunks/asset_types_thunks';
import { Link } from 'react-router-dom';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import { clearNavbarState, updateNavbarState } from '../../actions/navbar_actions';
import { getBackLinkStates } from '../../utils/HistoryUtils';
import { ROLE_TYPES } from '../../utils/DataConstants';

require('./AssetTemplatesDetailPage.less');

const AssetTemplatesDetailPage: FC<any> = ({
	userType,
	getAssetType,
	match,
	location,
	history,
	deleteAssetType,
	assetType,
	assetTypesFetching,
	companyConfig,
	childRoutes,
}): React.ReactElement => {
	const defaultPath = useMemo(() => `/${userType}/assets/overview/templates/overview`, [userType]);

	const assetTemplateId = useMemo(() => match.params.id, [match.params.id]);

	const [backLinkTo, setBackLinkTo] = useState(defaultPath);

	useEffect(() => {
		getAssetType(assetTemplateId);

		const { backLinkTo: link } = getBackLinkStates(location, defaultPath) as any;
		link && setBackLinkTo(link);
	}, [assetTemplateId, defaultPath, getAssetType, location]);

	const handleTabChange = useCallback(
		(key) => {
			const assetTemplateId = match.params.id;
			history.push(`/${userType}/assets/templates/detail/${assetTemplateId}/${key}`);
		},
		[history, match.params.id, userType]
	);

	const checkTabActive = useCallback(
		(key) => {
			const pathname = history.location.pathname;
			return pathname.indexOf(key) !== -1;
		},
		[history.location.pathname]
	);

	const handleDelete = useCallback(() => {
		deleteAssetType(assetType).then(() => {
			history.push(`/${userType}/assets/overview/templates/overview`);
		});
	}, [assetType, deleteAssetType, history, userType]);

	const tabs = [
		{
			key: 'details',
			name: 'Overview',
		},
	];

	return (
		<div>
			<DetailPageHeader
				exactPath={`/${userType}/assets/templates/detail/:id`}
				redirectPath={`/${userType}/assets/templates/detail/${assetTemplateId}/details`}
				backLinkText={'Back to asset templates'}
				backLinkTo={backLinkTo}
				title={assetTypesFetching ? null : assetType.name}
				subtitle={null}
				actions={[
					<Link
						key={1}
						to={`/${userType}/assets/templates/edit/${assetTemplateId}`}
						style={{ marginRight: 16 }}
					>
						<Button ghost={true}>Edit</Button>
					</Link>,
					<Button key={2} ghost={true} onClick={handleDelete}>
						Delete
					</Button>,
				]}
				tabs={tabs}
				backgroundColor={nullSafeGet('detail.config.theme.navblock', companyConfig)}
				handleTabChange={handleTabChange}
				checkTabActive={checkTabActive}
				childRoutes={childRoutes}
			/>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => ({
	assetTypesFetching: state.asset_types.fetching,
	assetType: state.asset_types.detail,
	companyConfig: state.company_config,
	match: ownProps.match,
	history: ownProps.history,
	location: ownProps.location,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getAssetType: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetTypesRestCrudThunksForSupplier.readOne(id)
				: assetTypesRestCrudThunksForBuyer.readOne(id)
		),
	deleteAssetType: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetTypesRestCrudThunksForSupplier.delete(entity, 'id', null, true)
				: assetTypesRestCrudThunksForBuyer.delete(entity, 'id', null, true)
		),
	updateNavbar: (backlinkTitle, backlinkTo, navbarBackgroundColor) =>
		dispatch(updateNavbarState(backlinkTitle, backlinkTo, navbarBackgroundColor)),
	clearNavbar: () => dispatch(clearNavbarState()),
});

const AssetTemplateDetailPage = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(AssetTemplatesDetailPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(AssetTemplateDetailPage);

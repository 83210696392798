import { DOWNTIME_ANALYTICS_CRUD_ACTION_CONSTANTS } from '../actions/downtime_analytics_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = standardInitialStateGenerator([
	'overview',
	'groupByLocations',
	'groupByAssetTypes',
	'groupByManufacturers',
	'groupByProblemTypes',
]);
export default standardReducerGenerator(DOWNTIME_ANALYTICS_CRUD_ACTION_CONSTANTS, initialState);

import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { TROUBLESHOOTING_ANSWERS_CRUD_ACTION_CREATORS } from '../actions/troubleshooting_answer_actions';

export const troubleshootingAnswersRestCrudThunksForBuyer = new RestCrudThunks(
	'work_order_troubleshooting_answers',
	'work_order_troubleshooting_answer',
	'troubleshottingAnswer',
	'troubleshottingAnswers',
	TROUBLESHOOTING_ANSWERS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'troubleshooting/'
);

export const troubleshootingAnswersRestCrudThunksForSupplier = new RestCrudThunks(
	'work_order_troubleshooting_answers',
	'work_order_troubleshooting_answer',
	'troubleshottingAnswer',
	'troubleshottingAnswers',
	TROUBLESHOOTING_ANSWERS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'troubleshooting/'
);

import * as React from 'react';

import { Route, Redirect, withRouter } from 'react-router-dom';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';
import SubnavTabs from '../subnav_tabs/SubnavTabs';
import { connect } from 'react-redux';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { authorizedRolesCombinations, checkAuthorization } from '../roles/roles';

const style = require('./SupplierAdminPage.less');

class SupplierAdminPage extends React.Component<any, null> {
	handleTabChange = (key) => {
		const { history } = this.props;
		history.push(`/supplier/admin/${key}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	render() {
		const { companyConfig, currentUser } = this.props;
		const tabs = [
			{
				key: 'users',
				name: 'Users',
			},
			{
				key: 'companySettings',
				name: 'Settings',
			},
			{
				key: 'accessControl',
				name: 'Access Control',
			},
		];
		if (checkAuthorization(authorizedRolesCombinations.SUPER_ADMIN_ONLY)(currentUser.roles)) {
			tabs.push({
				key: 'impersonation',
				name: 'Impersonation',
			});
		}

		return (
			<div>
				<Route
					path="/supplier/admin"
					exact={true}
					render={(props) => (
						<Redirect
							to={{
								pathname: '/supplier/admin/users',
							}}
						/>
					)}
				/>
				<div
					style={{
						padding: '24px',
						paddingBottom: 0,
						background: nullSafeGet('detail.config.theme.navblock', companyConfig),
						marginBottom: 8,
					}}
				>
					<h4
						style={{
							color: 'rgba(255, 255, 255, 0.9)',
							marginRight: 8,
							marginBottom: 24,
						}}
					>
						Admin
					</h4>
					<SubnavTabs tabs={tabs} onChange={this.handleTabChange} isActive={this.checkTabActive} />
				</div>
				{this.props.childRoutes.map((r, i) => (
					<RouteWithSubRoutes key={i} {...r} />
				))}
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	companyConfig: state.company_config,
	currentUser: state.session.currentUser,
});
const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SupplierAdminPage));

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Layout, Row, Col, Card, Button } from 'antd';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { metersRestCrudThunksForBuyer } from '../../thunks/meters_thunks';
import { EmptyState } from '../empty_state/EmptyState';
import { locationsRestCrudThunksForBuyer } from '../../thunks/locations_thunks';
import {
	fetchSupplierFacilitiesInPrivateNetworkForBuyer,
	supplierFacilitiesRestCrudThunksForBuyer,
} from '../../thunks/supplier_facilities_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { regionsRestCrudThunksForBuyer } from '../../thunks/regions_thunks';
import SubnavBar from '../subnav_bar/SubnavBar';
import { AdvancedFilters } from '../advanced_filters/AdvancedFilters';
import {
	nullSafeGet,
	nullSafeGetOrElse,
	changeFilterValueToArrayOfIds,
	parseQueryParameters,
} from '../../utils/DataAccessUtils';
import RegionsLocationsTreeSelect from '../regions_tree_select/BuyerRegionsLocationsTreeSelect';
import { METERS_CRUD_ACTION_CREATORS } from '../../actions/meters_actions';
import { meterTypesRestCrudThunksForBuyer } from '../../thunks/meter_types_thunks';
import OWAsyncTreeSelect from '../ow_async_tree_select/OWAsyncTreeSelect';

const { Content } = Layout;

const queryString = require('qs');
const style = require('./MetersIndexPage.less');

const METERS_OVERVIEW_TARGET_COLLECTION = 'metersIndex';

class MetersIndexPage extends React.Component<any, any> {
	initialPagination: any = { current: 1, pageSize: 10 };
	initialFilters: any = {};

	constructor(props) {
		super(props);
		const { updateMetersFilters, location } = props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		const { current, pageSize, total, sort_by, order, ...meterFilters } = queryParams;
		this.initialPagination = { current: current ? current : 1, pageSize: pageSize ? pageSize : 10 };
		this.initialFilters = meterFilters && Object.keys(meterFilters).length > 0 ? meterFilters : {};
		updateMetersFilters(this.initialFilters, METERS_OVERVIEW_TARGET_COLLECTION);
	}

	render() {
		const {
			history,
			clearAndUpdateFilters,
			meters,
			meterTypes,
			locations,
			updateMetersFilters,
			location,
			fetchLocations,
			fetchMultipleLocations,
			fetchMeterTypes,
			fetchMultipleMeterTypes,
		} = this.props;
		const onRow = (record) => ({
			onClick: () => {
				let searchString = location.search;
				if (searchString[0] === '?') {
					searchString = searchString.slice(1);
				}
				let backUrl = `/buyer/meters/detail/${record.id}/details`;
				if (searchString && searchString.length > 0) {
					backUrl = backUrl.concat(`?backlinkParams=${encodeURIComponent(searchString)}`);
				}
				history.push(backUrl);
			},
		});

		const columns = [
			{
				title: 'Location',
				dataIndex: ['location', 'name'],
				sorter: true,
			},
			{
				title: 'Asset',
				dataIndex: ['asset', 'name'],
				sorter: true,
			},
			{
				title: 'Meter Name',
				dataIndex: 'name',
				sorter: true,
			},
		];
		return (
			<Content className="metersIndexPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited buyer meters index page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '0.5em -8px' }}>
					<Col span={24}>
						<SubnavBar
							left={
								<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
									<AdvancedFilters
										initialFilters={this.initialFilters}
										preAppliedFilters={{}}
										updateFilters={updateMetersFilters}
										filterConfig={[
											{
												label: 'Location',
												stateSlice: locations,
												hideFilter: true,
												filtersValueAccessor: (filtersStateSlice) => {
													const locationIds = nullSafeGet(
														`${METERS_OVERVIEW_TARGET_COLLECTION}.filters.locationIds`,
														filtersStateSlice
													);
													const finalIds = nullSafeGetOrElse(
														`${METERS_OVERVIEW_TARGET_COLLECTION}.filters.regionIds`,
														filtersStateSlice,
														locationIds
													);
													return changeFilterValueToArrayOfIds(finalIds);
												},
												handleChange: (ids) => {
													updateMetersFilters(
														{
															locationIds: ids.join(',') || undefined,
															regionIds: undefined,
														},
														METERS_OVERVIEW_TARGET_COLLECTION
													);
												},
												keyAccessor: (loc) => loc.id,
												valueAccessor: (loc) => loc.id,
												targetCollectionName: 'metersAdvancedFilters',
												fetch: fetchLocations,
												fetchMultiple: fetchMultipleLocations,
												renderItem: (loc) => loc.name,
												sortBy: { sort_by: 'name', order: 'ascend' },
											},
											{
												label: 'Meter Type',
												mode: 'multiple',
												stateSlice: meterTypes,
												filtersValueAccessor: (filtersStateSlice) => {
													const ids = nullSafeGet(
														`${METERS_OVERVIEW_TARGET_COLLECTION}.filters.meterTypeIds`,
														filtersStateSlice
													);
													return changeFilterValueToArrayOfIds(ids);
												},
												keyAccessor: (meterType) => meterType.id,
												valueAccessor: (meterType) => meterType.id,
												handleChange: (ids) => {
													updateMetersFilters(
														{
															meterTypeIds: ids.join(',') || undefined,
														},
														METERS_OVERVIEW_TARGET_COLLECTION
													);
												},
												targetCollectionName: 'metersAdvancedFilters',
												fetch: fetchMeterTypes,
												fetchMultiple: fetchMultipleMeterTypes,
												renderItem: (meterType) => meterType.name,
												sortBy: { sort_by: 'name', order: 'ascend' },
												component: OWAsyncTreeSelect,
											},
										]}
										filtersTargetCollectionName={METERS_OVERVIEW_TARGET_COLLECTION}
										clearAndUpdateFilters={clearAndUpdateFilters}
										filtersStateSlice={meters}
										inputParamName={'name'}
									>
										<Form.Item label="Location">
											<RegionsLocationsTreeSelect
												mode="multiple"
												filtersSlice={meters}
												filtersValueAccessor={(filtersStateSlice) => {
													const locationIds = nullSafeGet(
														`${METERS_OVERVIEW_TARGET_COLLECTION}.filters.locationIds`,
														filtersStateSlice
													);
													const finalIds = nullSafeGetOrElse(
														`${METERS_OVERVIEW_TARGET_COLLECTION}.filters.regionIds`,
														filtersStateSlice,
														locationIds
													);
													return changeFilterValueToArrayOfIds(finalIds);
												}}
												onChange={(ids) => {
													updateMetersFilters(
														{
															locationIds: ids.join(',') || undefined,
															regionIds: undefined,
														},
														METERS_OVERVIEW_TARGET_COLLECTION
													);
												}}
												targetCollectionName={'metersAdvancedFilters'}
											/>
										</Form.Item>
									</AdvancedFilters>
								</div>
							}
							right={
								<Button
									size="large"
									type="primary"
									onClick={() => this.props.history.push('/buyer/meters/overview/new')}
								>
									Add Meter
								</Button>
							}
						/>
					</Col>
				</Row>
				<Row style={{ margin: '0.5em -8px' }}>
					<Col span={24}>
						<Card bodyStyle={{ padding: 8 }}>
							<PaginatedReduxTable
								updateQueryParams={true}
								emptyState={
									<EmptyState
										graphic={
											<img
												style={{ marginBottom: 8 }}
												src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
											/>
										}
										headline={"You haven't created any meters yet."}
										body={
											<div style={{ textAlign: 'center' }}>
												<div style={{ maxWidth: 440, marginBottom: 16 }}></div>
											</div>
										}
									/>
								}
								collectionName="meters"
								onRow={onRow}
								showHeader={true}
								targetCollectionName="metersIndex"
								columns={columns}
								keyAccessor={(el) => el.id}
								initialFilters={this.initialFilters}
								initialPagination={this.initialPagination}
								fetchData={metersRestCrudThunksForBuyer.read}
							/>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	meters: state.meters,
	meterTypes: state.meter_types,
	locations: state.locations,
	regions: state.regions,
	supplierFacilities: state.supplier_facilities,
});

const mapDispatchToProps = (dispatch) => ({
	updateMetersFilters: (filters, targetCollection) =>
		dispatch(METERS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(METERS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			locationsRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleMeterTypes: (ids, targetCollectionName) =>
		dispatch(meterTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchMeterTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			meterTypesRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchSupplierFacilitiesInPrivateNetwork: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			fetchSupplierFacilitiesInPrivateNetworkForBuyer(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleSupplierFacilities: (ids, targetCollectionName) =>
		dispatch(supplierFacilitiesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchRegions: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			regionsRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleRegions: (ids, targetCollectionName) =>
		dispatch(regionsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
});

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(MetersIndexPage));

import React, { FC, useCallback } from 'react';
import '@ant-design/compatible/assets/index.css';
import {
	Modal,
	Input,
	Alert,
	Select,
	InputNumber,
	Switch,
	Row,
	Col,
	Form,
	Button,
	message,
} from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { connect } from 'react-redux';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { equipmentTypesRestCrudThunksForSupplier } from '../../thunks/equipment_types_thunks';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { useForm } from 'antd/lib/form/Form';
import { equipmentPerStockLocationsRestCrudThunksForSupplier } from '../../thunks/equipment_per_stock_locations_thunks';
import { vendorsRestCrudThunksForSupplier } from '../../thunks/vendors_thunks';
import { renderEquipmenTypeSelect } from '../purchase_order_form/ReplaceEquipmentLineItemModal';

const FormItem = Form.Item;
const PURCHASE_ORDER_FORM_STOCK_LOCATION_DROPDOWN = 'purchaseOrderFormStockLocationDropdown';

interface StockLocationAddEquipmentFormProps extends FormComponentProps {
	onCancel: any;
	createErrors: any;
	updateErrors: any;
	creating: boolean;
	updating: boolean;
	stockLocations: any;
	showStockLocation: any;

	fetchStockLocations: any;
	fetchMultipleStockLocations: any;
	fetchEquipment: any;
	fetchEquipmentTypes: any;
	fetchMultipleEquipments: any;
	equipmentTypes: any;

	onSuccess: any;
	createEquipment: any;
	stockLocationId: any;

	vendors: any;
	fetchVendors: any;
	fetchMultipleVendors: any;
}

const StockLocationAddEquipmentForm: FC<StockLocationAddEquipmentFormProps> = ({
	onCancel,
	createErrors,
	updateErrors,
	equipmentTypes,
	fetchEquipmentTypes,
	fetchMultipleEquipments,
	stockLocations,
	fetchStockLocations,
	fetchMultipleStockLocations,
	showStockLocation,
	creating,
	fetchEquipment,
	onSuccess,
	createEquipment,
	stockLocationId,
	vendors,
	fetchVendors,
	fetchMultipleVendors,
}): React.ReactElement => {
	const [form] = useForm();

	const handleEquipmentItemChange = useCallback(
		(equipmentTypeId) => {
			fetchEquipment(equipmentTypeId).then((eq) => {
				if (eq.cost) {
					form.setFieldsValue({ [`cost`]: eq.cost.toString() });
				} else {
					form.setFieldsValue({ [`cost`]: undefined });
				}
				form.setFieldsValue({ [`name`]: eq.modelName });
				form.setFieldsValue({ [`equipmentType`]: eq });
			});
		},
		[fetchEquipment, form]
	);

	const handleAddEquipmentSubmit = useCallback(
		(values) => {
			const equipmentPerStockLocationId = {
				...values,
				...(values.cost && { cost: parseFloat(values.cost) }),
				name: values.name,
				images: values.equipmentType.images,
				isRental: !!values.isRental,
				...(!showStockLocation && { stockLocationId: parseInt(stockLocationId) }),
			};

			createEquipment(equipmentPerStockLocationId).then(() => {
				onSuccess();
				message.success(`Added a new equipmentType.`);
			});
		},
		[createEquipment, onSuccess, showStockLocation, stockLocationId]
	);

	return (
		<Modal
			visible
			width={600}
			title={'Add Equipment To Stock Location'}
			okText={'Add Equipment'}
			onCancel={onCancel}
			closable={false}
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button
					type="primary"
					size="large"
					style={{ marginLeft: '16px' }}
					key="submit"
					htmlType="submit"
					form="add-equipment-form"
					loading={creating}
				>
					Add Equipment
				</Button>,
			]}
		>
			<Form
				form={form}
				id="add-equipment-form"
				layout="vertical"
				requiredMark={false}
				onFinish={handleAddEquipmentSubmit}
			>
				{createErrors.length > 0 ? (
					<FormItem>
						<Alert message={createErrors.join(' ')} type="error" />
					</FormItem>
				) : null}
				{updateErrors.length > 0 ? (
					<FormItem>
						<Alert message={updateErrors.join(' ')} type="error" />
					</FormItem>
				) : null}
				<Form.Item name="equipmentType" hidden />
				{showStockLocation && (
					<FormItem
						name="stockLocationId"
						label="Stock Location"
						rules={[{ required: true, message: 'Stock location is required.' }]}
					>
						<OWAsyncSelect
							stateSlice={stockLocations}
							targetCollectionName={PURCHASE_ORDER_FORM_STOCK_LOCATION_DROPDOWN}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleStockLocations(ids, targetCollectionName);
							}}
							fetchData={(searchText, targetCollectionName) => {
								fetchStockLocations({ name: searchText }, targetCollectionName);
							}}
							renderRecord={(stockLocation) => (
								<Select.Option key={stockLocation.id} value={stockLocation.id}>
									{stockLocation.name}
								</Select.Option>
							)}
						/>
					</FormItem>
				)}
				<FormItem
					name="equipmentTypeId"
					label="Equipment Type"
					rules={[{ required: true, message: 'Please select an equipment type.' }]}
				>
					<OWAsyncSelect
						stateSlice={equipmentTypes}
						targetCollectionName="equipmentTypesAtStockLocationIndex"
						fetchMultiple={(ids, targetCollectionName) => {
							fetchMultipleEquipments(ids, targetCollectionName);
						}}
						fetchData={(
							searchText,
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						) => {
							fetchEquipmentTypes(
								{ name: searchText },
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							);
						}}
						onChange={handleEquipmentItemChange}
						renderRecord={renderEquipmenTypeSelect}
						sortBy={{ sort_by: 'name', order: 'ascend' }}
					/>
				</FormItem>
				<FormItem name="name" label="Name">
					<Input style={{ width: '100%' }} />
				</FormItem>
				<FormItem name="serialNumber" label="Serial Number">
					<Input style={{ width: '100%' }} />
				</FormItem>
				<FormItem name="cost" label="Cost">
					<InputNumber style={{ width: '100%' }} />
				</FormItem>
				<FormItem name="isRental" label="Is Rental">
					<Switch />
				</FormItem>
				<Form.Item
					name="vendorId"
					label="Vendor"
					rules={[{ required: true, message: 'Please select a vendor.' }]}
				>
					<OWAsyncSelect
						style={{ maxWidth: 640 }}
						targetCollectionName="equipmentVendors"
						stateSlice={vendors}
						fetchMultiple={(ids, targetCollectionName) => {
							fetchMultipleVendors(ids, targetCollectionName);
						}}
						fetchData={(search, targetCollectionName) => {
							fetchVendors({ search }, targetCollectionName);
						}}
						//placeholder={'Select Vendor'}
						renderRecord={(vendor) => (
							<Select.Option key={vendor.id} value={vendor.id}>
								{vendor.name}
							</Select.Option>
						)}
						sortBy={{ sort_by: 'name', order: 'ascend' }}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	createErrors: state.equipment_per_stock_locations.createErrors,
	updateErrors: state.equipment_per_stock_locations.updateErrors,
	creating: state.equipment_per_stock_locations.creating,
	updating: state.equipment_per_stock_locations.updating,
	stockLocations: state.stock_locations,
	vendors: state.vendors,

	equipmentTypes: state.equipment_types,
});

const mapDispatchToProps = (dispatch) => ({
	fetchEquipment: (id) => dispatch(equipmentTypesRestCrudThunksForSupplier.readOne(id)),
	fetchEquipmentTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			equipmentTypesRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleEquipments: (ids, targetCollectionName) =>
		dispatch(equipmentTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchStockLocations: (params, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.read(params, targetCollectionName)),
	fetchMultipleStockLocations: (ids, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	createEquipment: (entity) =>
		dispatch(equipmentPerStockLocationsRestCrudThunksForSupplier.create(entity)),
	fetchVendors: (params, targetCollectionName) =>
		dispatch(vendorsRestCrudThunksForSupplier.read(params, targetCollectionName)),
	fetchMultipleVendors: (ids, targetCollectionName) =>
		dispatch(vendorsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StockLocationAddEquipmentForm);

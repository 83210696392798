import React, { FC, useCallback } from 'react';
import { Tag } from 'antd';

export interface IEachFilterPill {
	displayText: string;
	fieldName: string;
	value: string;
	type: string;
	notClosable?: boolean;
	onClick?: () => void;
	clearFilter: (fieldName: string, value: string, type: string) => void;
}

const EachFilterPill: FC<IEachFilterPill> = ({
	displayText,
	fieldName,
	value,
	type,
	clearFilter,
	onClick,
	notClosable,
}): React.ReactElement => {
	const onClearFilter = useCallback(
		() => clearFilter(fieldName, value, type),
		[fieldName, value, type, clearFilter]
	);

	const onPillClick = useCallback(
		() => notClosable && onClick && onClick(),
		[notClosable, onClick]
	);

	return (
		<Tag
			closable={!notClosable}
			onClose={onClearFilter}
			style={{ fontSize: '14px', padding: '4px 12px' }}
			onClick={onPillClick}
		>
			{displayText}
		</Tag>
	);
};

export default EachFilterPill;

import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router';
import { Layout } from 'antd';
import ScrollToTopOnMount from '../scroll_to_top_on_mount/ScrollToTopOnMount';
import SubnavTabs from '../subnav_tabs/SubnavTabs';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';

const { Content } = Layout;
require('./SuppliersOverviewPage.less');

class SuppliersOverviewPage extends React.Component<any, any> {
	handleTabChange = (key) => {
		const { history, userType } = this.props;
		history.push(`/${userType}/suppliers/overview/${key}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	render() {
		const { companyConfig, userType } = this.props;
		const tabs = [
			{
				key: 'privateNetwork',
				name: 'Private Network',
			},
			{
				key: 'locations',
				name: 'By Locations',
			},
			{
				key: 'wrenchhub',
				name: 'WrenchHub',
				accessPermissionName: PERMISSION_NAMES.MODIFY_SUPPLIERS,
			},
		];

		return (
			<div>
				<ScrollToTopOnMount />
				<div>
					<Route
						path={`/${userType}/suppliers/overview`}
						exact={true}
						render={(props) => (
							<Redirect
								to={{
									pathname: `/${userType}/suppliers/overview/privateNetwork`,
								}}
							/>
						)}
					/>
					<Content className="suppliersPage" style={{ padding: '0 0.5em' }}>
						{/*<ScrollToTopOnMount/>*/}
						{/*<BackTop/>*/}

						<div
							style={{
								margin: '0 -8px',
								padding: '24px',
								paddingBottom: 0,
								background: nullSafeGet('detail.config.theme.navblock', companyConfig),
								marginBottom: 8,
							}}
						>
							<h4
								style={{
									color: 'rgba(255, 255, 255, 0.9)',
									marginRight: 8,
									marginBottom: 24,
								}}
							>
								Suppliers
							</h4>
							<SubnavTabs
								tabs={tabs}
								onChange={this.handleTabChange}
								isActive={this.checkTabActive}
							/>
						</div>
						{this.props.childRoutes.map((r, i) => (
							<RouteWithSubRoutes key={i} {...r} />
						))}
					</Content>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	companyConfig: state.company_config,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SuppliersOverviewPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

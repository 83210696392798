import { PPSL_AUDITS_CRUD_ACTION_CREATORS } from '../actions/ppsl_audits_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { parseJSON } from './fetch_utils';
import { logoutSuccess } from '../actions/session_actions';
import { message } from 'antd';
import FetchUtils from './fetch_utils';

const fetchUtils = new FetchUtils();

export const ppslAuditsRestCrudThunksForBuyer = new RestCrudThunks(
	'parts_per_stock_location_audits',
	'parts_per_stock_location_audit',
	'ppslAudit',
	'ppslAudits',
	PPSL_AUDITS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'inventory/'
);

export const ppslAuditsCrudThunksForSupplier = new RestCrudThunks(
	'parts_per_stock_location_audits',
	'parts_per_stock_location_audit',
	'ppslAudit',
	'ppslAudits',
	PPSL_AUDITS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'inventory/'
);

const downloadPpslAuditsCSV =
	(roleType) =>
	(params, filters = null) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/inventory/parts_per_stock_location_audits/csv_report_generator`;

		// if sorting present, fix order naming and add to query params

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.message);
							message.error(d.data);
						} else {
							resolve(d.data);
							const hide = message.loading(d.data, 0);
							setTimeout(hide, 7000);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};

export const downloadPpslAuditsCSVForSupplier = downloadPpslAuditsCSV(ROLE_TYPES.SUPPLIER);

const CREATED_AT_NEWEST = {
	label: 'Newest',
	selectVal: 'latest',
	value: {
		sort_by: 'createdAt',
		order: 'desc',
	},
};
const CREATED_AT_OLDEST = {
	label: 'Oldest',
	selectVal: 'oldest',
	value: {
		sort_by: 'createdAt',
		order: 'ascend',
	},
};

const APPROVED_AT_NEWEST = {
	label: 'Recently Approved',
	selectVal: 'approvedLatest',
	value: {
		sort_by: 'approvedAt',
		order: 'desc',
	},
};

//Defaults
const PRSorters = [CREATED_AT_NEWEST, CREATED_AT_OLDEST, APPROVED_AT_NEWEST];

export default PRSorters;

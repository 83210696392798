import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { equipmentCatalogsRestCrudThunksForSupplier } from '../../thunks/equipment_catalogs_thunks';

const EquipmentCatalogDetailsPage: FC<any> = ({
	companyConfig,
	fetching,
	equipmentCatalog,
	childRoutes,
	history,
	match,
	getEquipmentCatalog,
}): React.ReactElement => {
	const tabs = useMemo(
		() => [
			{
				key: 'details',
				name: 'Overview',
			},
			{
				key: 'purchaseOrders',
				name: 'Purchase Orders',
			},
		],
		[]
	);

	const ecId = useMemo(() => match.params.id, [match.params.id]);

	useEffect(() => ecId && getEquipmentCatalog(ecId), [ecId, getEquipmentCatalog]);

	const handleTabChange = useCallback(
		(key) => {
			const ecId = match.params.id;
			history.push(`/supplier/equipments/equipmentCatalogs/detail/${ecId}/${key}`);
		},
		[history, match.params.id]
	);

	const checkTabActive = useCallback(
		(key) => {
			const pathname = history.location.pathname;
			return pathname.indexOf(key) !== -1;
		},
		[history.location.pathname]
	);

	const title = useMemo(
		() =>
			`${nullSafeGetOrElse('equipmentType.modelName', equipmentCatalog, '')} (${nullSafeGetOrElse(
				'partEquipmentVendor.name',
				equipmentCatalog,
				''
			)})`,
		[equipmentCatalog]
	);

	return (
		<DetailPageHeader
			exactPath={'/supplier/equipments/equipmentCatalogs/detail/:id'}
			redirectPath={`/supplier/equipments/equipmentCatalogs/detail/${ecId}/details`}
			backLinkText={'Back to Part Catalogs'}
			backLinkTo={`/supplier/equipments/overview/priceList`}
			title={fetching ? null : title}
			subtitle={null}
			actions={null}
			tabs={tabs}
			backgroundColor={nullSafeGet('detail.config.theme.navblock', companyConfig)}
			handleTabChange={handleTabChange}
			checkTabActive={checkTabActive}
			childRoutes={childRoutes}
		/>
	);
};

const mapStateToProps = (state, ownProps) => ({
	companyConfig: state.company_config,
	fetching: state.equipment_catalogs.fetching,
	equipmentCatalog: state.equipment_catalogs.detail,
});

const mapDispatchToProps = (dispatch) => ({
	getEquipmentCatalog: (id) => dispatch(equipmentCatalogsRestCrudThunksForSupplier.readOne(id)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(EquipmentCatalogDetailsPage)
);

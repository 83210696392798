import { Button, Checkbox, Col, Pagination, Row, Tooltip } from 'antd';
import React from 'react';
import { defaultPagination } from '../config/pagination/pagination';
import TableSelector from '../TableSelector/TableSelector';

export default function TableFooter({
	updatePagination,
	pagination,
	enableMultiSelect,
	records,
	selectItems,
}) {
	return (
		<Row
			style={{
				width: '100%',
				justifyContent: 'flex-end',
			}}
		>
			<Col style={{ padding: '16px' }}>
				{pagination ? (
					<Pagination
						{...defaultPagination}
						{...pagination}
						onChange={(current, pageSize) => updatePagination({ current, pageSize })}
					/>
				) : null}
			</Col>
		</Row>
	);
}

export function ShowAllFooter({
	updatePagination,
	pagination,
	filters,
	sorters,
	location,
	history,
	openMoreInDefault,
	userType,
}) {
	let f = '';
	if (filters || sorters) {
		const allParms = { ...(filters || {}), ...(sorters || {}) };
		let filterArray = Object.keys(allParms).map((key, i) => key.concat('=', allParms[key]));
		if (filterArray.length > 1) {
			f = f.concat(filterArray.reduce((s1, s2) => s1.concat('&', s2)));
		} else if (filterArray.length === 1) {
			f = filterArray[0];
		}
	}
	const openMoreUrl = (
		openMoreInDefault
			? `/${userType}/invoices/overview/all`
			: `${location.pathname.slice(0, location.pathname.lastIndexOf('/'))}/invoices`
	).concat('?', f);
	return (
		<div style={{ width: '100%', padding: '8px' }}>
			{pagination.total > 3 ? (
				<Button
					onClick={() => history.push(openMoreUrl)}
					type="link"
					style={{
						width: '100%',
						borderBottomLeftRadius: 4,
						borderBottomRightRadius: 4,
						padding: 12,
						height: 40,
						borderTop: '1px solid #e8e8e8',
						textAlign: 'center',
					}}
				>
					See all
				</Button>
			) : (
				<div />
			)}
		</div>
	);
}

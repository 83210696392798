import React, { FC } from 'react';
import AccessPermissionChecker from '../common/AccessPermissionChecker';

require('./TwoLineList.less');

export const TwoLineList: FC<any> = ({ listItems, lineOneRender, lineTwoRender }) => {
	return (
		<div className="twoLineList">
			{listItems &&
				listItems.map((listItem, idx) => (
					<AccessPermissionChecker name={listItem.accessPermissionName}>
						<div key={idx} className="twoLineList__listItem">
							<div className="twoLineList__firstLine">{lineOneRender(listItem)}</div>
							<div className="twoLineList__secondLine">{lineTwoRender(listItem)}</div>
						</div>
					</AccessPermissionChecker>
				))}
		</div>
	);
};

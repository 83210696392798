import {
	SUPPLIER_FACILITIES_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS,
	SUPPLIER_FACILITIES_SEARCH_ACTION_CONSTANTS,
	ADD_WARRANTY_SUPPLIER_TO_TARGET_COLLECTION,
} from '../actions/supplier_facilities_actions';
import {
	standardInitialSearchStateGenerator,
	standardInitialStateGenerator,
	standardReducerGenerator,
	standardSearchReducerGenerator,
} from './standard_reducer_utils';

const initialState: any = Object.assign(
	standardInitialSearchStateGenerator(),
	standardInitialStateGenerator([
		'bidderFormSearchResults',
		'suppliersOverviewVisibleSuppliers',
		'supplierFacilitiesAssociationDropdown',
		'supplierFacilitiesAdminPanel',
		'supplierFacilitiesAnalyticsDropdown',
		'associatedSupplierFacilitiesNearbyForSpendCategory',
		'PLANNED_MAINTENANCE_TEMPLATE_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE',
		'PLANNED_MAINTENANCE_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE',
		'SUPPLIER_WORK_ORDER_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE',
		'ASSET_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE',
		'WORK_ORDER_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE',
		'TRANSFER_WORK_ORDER_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE',
		'PLANNED_MAINTENANCE_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE',
		'walkthroughSupplierFacilitiesInPrivateNetwork',
		'workOrdersAdvancedFilters',
		'invoicesAdvancedFilters',
		'quotesAdvancedFilters',
		'plannedMaintenanceAdvancedFilters',
		'scorecardAdvancedFilters',
		'BUDGETS_FORM_SUPPLIERS_AUTOCOMPLETE',
		'allSupplierFacilitiesEver',
		'vendorScorecard',
		'troubleshootingFormIndex',
	])
);

const supplierFacilitiesSearchReducer = standardSearchReducerGenerator(
	SUPPLIER_FACILITIES_SEARCH_ACTION_CONSTANTS
);
export const customReducer = (oldState = initialState, action) => {
	Object.freeze(oldState);
	switch (action.type) {
		case ADD_WARRANTY_SUPPLIER_TO_TARGET_COLLECTION:
			const newRecordsIds = action.supplierId
				? [...oldState[action.targetCollectionName].recordsIds, action.supplierId]
				: [...oldState[action.targetCollectionName].recordsIds];
			const newCount =
				oldState[action.targetCollectionName].count + 1
					? oldState[action.targetCollectionName].count + 1
					: oldState[action.targetCollectionName].count;
			return Object.assign({}, oldState, {
				[action.targetCollectionName]: {
					...oldState[action.targetCollectionName],
					recordsIds: newRecordsIds,
					count: newCount,
				},
			});
		default:
			return supplierFacilitiesSearchReducer(oldState, action);
	}
};
export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState, 'id', customReducer);

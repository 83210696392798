import { LOCATION_TYPES_ADDITIONAL_FIELDS_CRUD_ACTION_CREATORS } from '../actions/location_types_additional_fields_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';

export const locationTypesAdditionalFieldsRestCrudThunksForBuyer = new RestCrudThunks(
	'all_additional_fields',
	'all_additional_field',
	'allAdditionalField',
	'allAdditionalFields',
	LOCATION_TYPES_ADDITIONAL_FIELDS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'location/location_types/'
);
export const locationTypesAdditionalFieldsRestCrudThunksForSupplier = new RestCrudThunks(
	'all_additional_fields',
	'all_additional_field',
	'allAdditionalField',
	'allAdditionalFields',
	LOCATION_TYPES_ADDITIONAL_FIELDS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'location/location_types/'
);

import React, { FC } from 'react';
import { dateFormatter, DATE_FORMAT, optionalFilter } from '../../utils/DataFormatterUtils';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { QUOTE_STATUS_DISPLAY_NAMES } from '../../constants/QuoteStatuses';
import { SupplierFacilityName } from '../name_component/supplierFacility/supplierFacilityNameComponent';
import { UserName } from '../name_component/user/UserNameComponent';
import DetailRow from '../detail_row/DetailRow';
import QuoteUploader from '../quote_uploader/QuoteUploader';
import { Col, Row } from 'antd';
import DateTimeHover from '../date_time_component/DateTime';

const QuotesAdditionalDetails: FC<any> = ({ quote, passthroughItems = [] }): React.ReactElement => {
	const detailItems = [
		{ key: 'Status', value: QUOTE_STATUS_DISPLAY_NAMES[quote.status] },
		{
			key: 'Work Order #',
			value: optionalFilter(
				nullSafeGet('workOrderId', quote) ? `${nullSafeGet('workOrderId', quote)}` : null
			),
		},
		{ key: 'Quote #', value: optionalFilter(nullSafeGetOrElse('quoteNumber', quote, quote.id)) },
		quote.submittedByContact
			? {
					key: 'Submitted By',
					value: <UserName contact={quote.submittedByContact} mode={'inline'} placement={'top'} />,
			  }
			: {},
		{ key: 'Submitted', value: <DateTimeHover timestamp={nullSafeGet('submittedAt', quote)} /> },
		...passthroughItems,
	];

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: 20,
				width: '100%',
				marginBottom: '20px',
			}}
		>
			<Row>
				<SupplierFacilityName
					facility={nullSafeGet('supplierFacility', quote)}
					mode={'card'}
					placement={'top'}
					allowHover={false}
				/>
			</Row>
			{nullSafeGet('scope', quote) ? (
				<div
					style={{ fontSize: 16 }}
					className="richTextWrapper"
					dangerouslySetInnerHTML={{ __html: quote.scope }}
				/>
			) : null}
			<DetailRow details={detailItems} />
		</div>
	);
};

export default QuotesAdditionalDetails;

import { PURCHASE_ORDER_STATUSES } from '../../constants/PurchaseOrderStatuses';
import { PURCHASE_REQUEST_STATUSES } from '../../constants/PurchaseRequestStatuses';
import { getLineItemVendor, nullSafeGet } from '../../utils/DataAccessUtils';

const getItemLabel = (record) => (record.isEquipmentLine ? 'Equipment' : 'Part');

export const getDisabledMessage = ({
	record,
	canCreatePO,
	selectedVendor,
	selectedSL,
	selectedLocation,
}) => {
	if (!record || !canCreatePO) return;

	const itemLabel = getItemLabel(record);
	const itemLabelPlural = itemLabel === 'Part' ? 'Parts' : itemLabel;

	if (record.status !== PURCHASE_REQUEST_STATUSES.approved) {
		return `${itemLabel} should be in Approved status`;
	} else if (
		nullSafeGet('id', getLineItemVendor(record)) &&
		nullSafeGet('id', selectedVendor) &&
		getLineItemVendor(record).id !== nullSafeGet('id', selectedVendor)
	) {
		return `You have already selected ${itemLabelPlural} from vendor - ${
			selectedVendor.name
		}. This ${itemLabel} will ship from a different vendor - ${getLineItemVendor(record).name}`;
	} else if (
		nullSafeGet('id', selectedSL) &&
		nullSafeGet('stockLocation.id', record) !== nullSafeGet('id', selectedSL)
	) {
		return `You have already selected ${itemLabelPlural} from ${
			selectedSL.name
		}. This ${itemLabel} will ship to ${
			nullSafeGet('stockLocation.name', record) || nullSafeGet('location.name', record) || '--'
		}`;
	} else if (
		nullSafeGet('id', selectedLocation) &&
		nullSafeGet('location.id', record) !== nullSafeGet('id', selectedLocation)
	) {
		return `You have already selected ${itemLabelPlural} from ${
			selectedLocation.name
		}. This ${itemLabel} will ship to ${
			nullSafeGet('stockLocation.name', record) || nullSafeGet('location.name', record) || '--'
		}`;
	}
};

export const isPOLineItemWithoutAssetTypeIdToLocation = (record) =>
	nullSafeGet('equipmentTypeId', record) &&
	nullSafeGet('supplierPurchaseOrder.locationId', record) &&
	!nullSafeGet('equipmentType.assetTypeId', record);

export const getPOLineItemDisabledMessage = ({
	record,
	isLocationSelect,
	isStockLocationSelect,
}) => {
	if (!record) return;

	if (record.status === PURCHASE_ORDER_STATUSES.received) {
		return 'Already received items cannot be selected';
	} else if (record.status === PURCHASE_ORDER_STATUSES.cancelled) {
		return 'Cancelled items cannot be selected';
	} else if (isPOLineItemWithoutAssetTypeIdToLocation(record)) {
		return 'Equipment is not associated with an asset type';
	} else if (isLocationSelect && !nullSafeGet('supplierPurchaseOrder.locationId', record)) {
		return 'You have already selected items from Location, you cannot select from a stock location';
	} else if (
		isStockLocationSelect &&
		!nullSafeGet('supplierPurchaseOrder.stockLocationId', record)
	) {
		return 'You have already selected items from stock Location, you cannot select from a location';
	}

	return '';
};

import * as React from 'react';
import { Avatar, Button, Popconfirm, Popover, Radio, Row, Spin } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import {
	deleteCapexCategoryAssociationForBuyer,
	deleteCapexCategoryAssociationForSupplier,
} from '../../thunks/capex_association_thunks';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import CapExAdditionalInfoPopup from '../invoices_detail_details_page/CapExAdditionalInfoPopup';
import {
	workOrdersRestCrudThunksForBuyer,
	workOrdersRestCrudThunksForSupplier,
} from '../../thunks/work_orders_thunks';
import {
	approvalRulesRestCrudThunksForBuyer,
	approvalRulesRestCrudThunksForSupplier,
	hasApprovalHierarchyChanged,
} from '../../thunks/company_approval_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';

interface CapexSwitchProps {
	quote: any;
	refresh: any;
	companyConfig: any;
	currentUser: any;

	updateWorkOrderForm: any;
	deleteCapExAssociation: any;
	fetchBuyerCompanyApprovalSettings: any;
	getApprovalHierarchy: any;
	willApprovalHierarchyChange: any;
}

const CapexSwitch: FC<CapexSwitchProps> = ({
	quote,
	refresh,
	companyConfig,
	fetchBuyerCompanyApprovalSettings,

	updateWorkOrderForm,
	deleteCapExAssociation,
	willApprovalHierarchyChange,
}) => {
	const [expenseTypeLoading, setExpenseTypeLoading] = useState(false);
	const [capExAdditionalInfoVisible, setCapExAdditionalInfoVisible] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [popButtonLoading, setPopButtonLoading] = useState(false);
	const isCapex = nullSafeGetOrElse('workOrder.isCapex', quote, false);
	const refreshQuote = () => refresh();

	const updateIsCapexInWorkOrder = (isCapex) => {
		const needUpdate =
			(quote.workOrder.isCapex && !isCapex) || (!quote.workOrder.isCapex && isCapex);
		if (needUpdate) {
			setExpenseTypeLoading(true);
			updateWorkOrderForm({ ...quote.workOrder, isCapex })
				.then(() => refreshQuote())
				.finally(() => setExpenseTypeLoading(false));
		}
	};
	const deleteCapexCategoryAssociation = () => {
		if (quote.capExCategoryAssociationId) {
			Promise.all([
				deleteCapExAssociation(quote.capExCategoryAssociationId),
				updateIsCapexInWorkOrder(false),
			]);
		} else {
			updateIsCapexInWorkOrder(false);
		}
	};
	const showCapExAdditionalInfo = () => setCapExAdditionalInfoVisible(true);
	const isCapexCategoryEnabled = () => {
		return nullSafeGetOrElse('config.capExConfig.acceptCapExCategory', companyConfig, false);
	};
	const changeExpenseType = (value) => {
		if (isCapexCategoryEnabled()) {
			if (value) {
				showCapExAdditionalInfo();
			} else {
				deleteCapexCategoryAssociation();
			}
		} else {
			updateIsCapexInWorkOrder(value);
		}
	};
	const onChangeExpenseType = (visible, value) => {
		setButtonLoading(true);
		if (visible) {
			setPopButtonLoading(true);
			willApprovalHierarchyChange(nullSafeGet('workOrder.id', quote), { isCapex: value }).then(
				(result) => {
					const quoteChanged =
						nullSafeGet('quoteApprovalChanges.approversAlreadyPresent', result) &&
						nullSafeGetOrElse('quoteApprovalChanges.changedHierarchy.length', result, 0) > 0;
					const invoiceChanged =
						nullSafeGet('invoiceApprovalChanges.approversAlreadyPresent', result) &&
						nullSafeGetOrElse('invoiceApprovalChanges.changedHierarchy.length', result, 0) > 0;
					if (quoteChanged || invoiceChanged) {
						setPopButtonLoading(false);
					} else {
						changeExpenseType(value);
					}
				}
			);
		} else {
			setButtonLoading(false);
		}
	};
	const hideCapExAdditionalInfo = () => {
		setCapExAdditionalInfoVisible(false);
		updateIsCapexInWorkOrder(true);
	};
	const capExCategoryAssociationSuccess = () => {
		hideCapExAdditionalInfo();
		quote.workOrder.isCapex && refreshQuote();
	};

	return (
		<div className="flex flex-col content-start justify-start">
			{capExAdditionalInfoVisible && (
				<CapExAdditionalInfoPopup
					workOrder={quote.workOrder}
					onCancel={hideCapExAdditionalInfo}
					onSuccess={capExCategoryAssociationSuccess}
				/>
			)}
			<div className="mr-3 flex flex-row items-center gap-2.5">
				<Spin spinning={expenseTypeLoading || buttonLoading}>
					<Radio.Group value={isCapex} style={{ display: 'flex' }}>
						{isCapex === false ? (
							<Radio.Button
								style={{ flexWrap: 'nowrap' }}
								value={false}
								onClick={() => {}}
								disabled={expenseTypeLoading || buttonLoading}
							>
								R&M
							</Radio.Button>
						) : (
							<Popconfirm
								placement="topLeft"
								title={'Warning: This will change the approval hierarchy'}
								okText="Proceed"
								okButtonProps={{ loading: popButtonLoading }}
								cancelText="Cancel"
								onVisibleChange={(visible) => onChangeExpenseType(visible, false)}
								onConfirm={() => {
									changeExpenseType(false);
								}}
							>
								<Radio.Button
									style={{ flexWrap: 'nowrap' }}
									value={false}
									disabled={expenseTypeLoading || buttonLoading}
								>
									R&M
								</Radio.Button>
							</Popconfirm>
						)}
						{isCapex === true ? (
							<Radio.Button
								style={{ flexWrap: 'nowrap' }}
								value={true}
								onClick={() => {}}
								disabled={expenseTypeLoading || buttonLoading}
							>
								CapEx
							</Radio.Button>
						) : (
							<Popconfirm
								placement="topLeft"
								title={'Warning: This will change the approval hierarchy'}
								okText="Proceed"
								cancelText="Cancel"
								okButtonProps={{ loading: popButtonLoading }}
								onVisibleChange={(visible) => onChangeExpenseType(visible, true)}
								onConfirm={() => {
									changeExpenseType(true);
								}}
							>
								<Radio.Button style={{ flexWrap: 'nowrap' }} value={true}>
									CapEx
								</Radio.Button>
							</Popconfirm>
						)}
					</Radio.Group>
				</Spin>
				<Popover
					content={
						<div>
							<Row>
								<span style={{ fontSize: '20px' }}>Capital Expenditures (CapEx)</span>
							</Row>
							<Row>
								<span>Costs associated with buying new assets or upgrading existing assets</span>
							</Row>
							<Row style={{ height: '20px' }}></Row>
							<Row>
								<span style={{ fontSize: '20px' }}>Repair and Maintenance (R&M)</span>
							</Row>
							<Row>
								<span>Costs associated with repair and maintenance of existing assets</span>
							</Row>
						</div>
					}
					placement={'topRight'}
				>
					<InfoCircleOutlined translate="" />
				</Popover>
			</div>
			{isCapex && isCapexCategoryEnabled() && (
				<div className="mt-2 flex flex-row items-center justify-end">
					<span style={{ color: 'rgba(0, 0, 0, 0.45' }}>CapEx Category: </span>
					<div style={{ color: 'rgba(0, 0, 0, 0.65' }} className="ml-2">
						{quote.capExCategory || '--'}
					</div>
					<Button
						type="link"
						size="large"
						icon={<EditOutlined translate="" onClick={showCapExAdditionalInfo} />}
					/>
				</div>
			)}
		</div>
	);
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	willApprovalHierarchyChange: (workOrderId, params) =>
		dispatch(hasApprovalHierarchyChanged(ownProps.userType, workOrderId, params)),
	fetchBuyerCompanyApprovalSettings: () =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? approvalRulesRestCrudThunksForSupplier.read(
						{ approvalHierarchyType: 'proposal' },
						'approvalRulesIndex'
				  )
				: approvalRulesRestCrudThunksForBuyer.read(
						{ approvalHierarchyType: 'proposal' },
						'approvalRulesIndex'
				  )
		),
	updateWorkOrderForm: (workOrder) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? workOrdersRestCrudThunksForSupplier.update(workOrder)
				: workOrdersRestCrudThunksForBuyer.update(workOrder)
		),
	deleteCapExAssociation: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? deleteCapexCategoryAssociationForSupplier(id)
				: deleteCapexCategoryAssociationForBuyer(id)
		),
});

const ComponentWithoutUserType = withRouter(connect(null, mapDispatchToProps)(CapexSwitch));

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Card } from 'antd';
import ProposalForm from '../proposal_form/ProposalForm';
import { proposalStatusChangeForSupplier } from '../../thunks/proposals_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { nullSafeGet } from '../../utils/DataAccessUtils';

const { Content } = Layout;

const BuyerNewWorkOrderProposalPage: React.FC<any> = ({ match, history }): React.ReactElement => {
	const workOrderId = useMemo(() => nullSafeGet('params.id', match), []);

	return (
		<Content>
			<LogOnMountWithStandardEventProperties eventType="visited new work order proposal page" />
			{/*<ScrollToTopOnMount/>*/}
			{/*<BackTop/>*/}
			<Row style={{ margin: '0.5em -8px' }} gutter={16}>
				<Col span={24}>
					<Card>
						<div>
							<h5 style={{ marginBottom: 32 }}>Create Proposal</h5>
							<ProposalForm
								formData={{
									workOrderId,
								}}
								onSuccess={(rec) => {
									history.push(`/buyer/workOrders/detail/${workOrderId}/quotes`);
								}}
							/>
						</div>
					</Card>
				</Col>
			</Row>
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	updateStatusToPending: (entity) => dispatch(proposalStatusChangeForSupplier(entity)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(BuyerNewWorkOrderProposalPage)
);

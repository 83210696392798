import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { CloseOutlined, PlusOutlined, StarOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
	Input,
	Alert,
	Button,
	Steps,
	Row,
	Col,
	Card,
	Table,
	Layout,
	Spin,
	Rate,
	Divider,
} from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import EditSupplierLicensesForm from '../edit_supplier_licenses_form/EditSupplierLicensesForm';
import { KeyValueDisplay } from '../key_value_display/KeyValueDisplay';
import { dateFormatter, floatToPercentageString } from '../../utils/DataFormatterUtils';
import { EmptyState } from '../empty_state/EmptyState';
import { AddressDisplay } from '../address_display/AddressDisplay';
import FileUploader from '../file_uploader/FileUploader';
import EditSupplierCertificationsForm from '../edit_supplier_certifications_form/EditSupplierCertificationsForm';
import EditSupplierInsuranceCoverageForm from '../edit_supplier_insurance_coverage_form/EditSupplierInsuranceCoverageForm';
import {
	supplierFacilityReviewsRestCrudThunksForSupplier,
	sendReferralReviewsForSupplier,
} from '../../thunks/supplier_facility_reviews_thunks';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { SupplierReview } from '../supplier_review/SupplierReview';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { suppliersRestCrudThunksForSupplier } from '../../thunks/suppliers_thunks';
import SupplierCompanyForm from '../supplier_company_form/SupplierCompanyForm';
import SupplierContactForm from '../supplier_contact_form/SupplierContactForm';
import { LocationAutocomplete } from '../location_autocomplete/LocationAutocomplete';
import { supplierFacilitiesRestCrudThunksForSupplier } from '../../thunks/supplier_facilities_thunks';
import SupplierClaimSignupForm from '../supplier_claim_sign_up_form/SupplierClaimSignUpForm';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { retrieveCachedUserDetails } from '../../thunks/session_thunks';

const style = require('./SupplierClaimSignUpFlowForm.less');

const Step = Steps.Step;
const { Content } = Layout;

interface SupplierClaimSignUpFlowFormProps extends FormComponentProps {
	history: any;
	onSubmit: any;
	onCancel: any;
	redirectForwardUrl?: string;
	onSuccess?: any;
	creating: boolean;
	updating: boolean;
	formData: any;
	createErrors: any[];
	updateErrors: any[];
	currentUser: any;
	match: any;
	supplierFacilityReviews: any;
	supplierFacilitiesFetching: boolean;
	supplierFacility: any;
	fetchSupplierCompany: any;
	fetchSupplierFacility: any;
	fetchSupplierFacilityReviews: any;
	updateSupplierCompany: any;
	sendReferralReviews: any;
	signUp: any;
}

let emailAddressUuid = 3;
let serviceAreaSelfPerformingUuid = 1;
let serviceAreaNotSelfPerformingUuid = 1;

class SupplierClaimSignUpFlowForm extends React.Component<SupplierClaimSignUpFlowFormProps, any> {
	constructor(props) {
		super(props);
		this.state = {
			isSignedUp: false,
			activeStep: 1,
			totalSteps: 6,
			loading: true,
			editingLicenses: false,
			editingCertifications: false,
			editingInsuranceCoverage: false,
			supplierCompany: undefined,
			supplierAssociated: true,
		};
		this.goNext = this.goNext.bind(this);
		this.goPrev = this.goPrev.bind(this);
		this.goSkip = this.goSkip.bind(this);
	}

	componentDidMount() {
		const {
			formData,
			match,
			currentUser,
			fetchSupplierCompany,
			fetchSupplierFacility,
			fetchSupplierFacilityReviews,
		} = this.props;

		if (currentUser.email !== undefined) {
			this.setState({ activeStep: 2, isSignedUp: true });
		}

		const supplierFacilityId = nullSafeGet('facility', currentUser)
			? nullSafeGet('facility.id', currentUser)
			: undefined;

		if (supplierFacilityId !== undefined) {
			fetchSupplierFacility(supplierFacilityId).then((supplierFacility) => {
				fetchSupplierCompany(supplierFacility.supplierCompanyId).then((supplierCompany) => {
					this.setState({ supplierCompany: supplierCompany, loading: false });
				});
			});

			fetchSupplierFacilityReviews(supplierFacilityId);
		}
	}

	componentWillReceiveProps(newProps) {
		if (
			this.props.currentUser !== undefined &&
			nullSafeGet('facility.id', this.props.currentUser) !==
				nullSafeGet('facility.id', newProps.currentUser)
		) {
			const {
				currentUser,
				fetchSupplierCompany,
				fetchSupplierFacility,
				fetchSupplierFacilityReviews,
				formData,
			} = newProps;

			const supplierFacilityId = nullSafeGet('facility', currentUser)
				? nullSafeGet('facility.id', currentUser)
				: undefined;

			if (supplierFacilityId !== undefined) {
				fetchSupplierFacility(supplierFacilityId).then((supplierFacility) => {
					fetchSupplierCompany(supplierFacility.supplierCompanyId).then((supplierCompany) => {
						this.setState({ supplierCompany: supplierCompany, loading: false });
					});
				});

				fetchSupplierFacilityReviews(supplierFacilityId);
			} else {
				this.setState({ supplierAssociated: false });
			}
		}
	}

	handleEditToggle = (toggleKey) => {
		const currentState = this.state[toggleKey];
		this.setState({ [toggleKey]: !currentState });
	};

	handleEditCancel = (toggleKey) => {
		this.setState({ [toggleKey]: false });
	};

	handleServiceAreaSubmit = () => {
		const { form, updateSupplierCompany } = this.props;
		const { supplierCompany } = this.state;
		form.validateFields((err, values) => {
			if (!err) {
				const serviceAreaSelfPerformingKeys = form.getFieldValue('serviceAreaSelfPerformingKeys');
				const serviceAreaNotSelfPerformingKeys = form.getFieldValue(
					'serviceAreaNotSelfPerformingKeys'
				);
				const serviceAreaSelfPerforming = serviceAreaSelfPerformingKeys
					.map((k) => form.getFieldValue(`serviceAreas-${k}-SelfPerforming`))
					.filter((sa) => sa !== undefined);
				const serviceAreaSelfPerformingParsed = serviceAreaSelfPerformingKeys
					.map((k) => form.getFieldValue(`serviceAreas-${k}-SelfPerformingParsedAddress`))
					.filter((sa) => sa !== undefined);
				const serviceAreaNotSelfPerforming = serviceAreaNotSelfPerformingKeys
					.map((k) => form.getFieldValue(`serviceAreas-${k}-NotSelfPerforming`))
					.filter((sa) => sa !== undefined);
				const serviceAreaNotSelfPerformingParsed = serviceAreaNotSelfPerformingKeys
					.map((k) => form.getFieldValue(`serviceAreas-${k}-NotSelfPerformingParsedAddress`))
					.filter((sa) => sa !== undefined);
				const updatedSupplier = {
					...supplierCompany,
					serviceAreaSelfPerforming: serviceAreaSelfPerforming,
					serviceAreaNotSelfPerforming: serviceAreaNotSelfPerforming,
					serviceAreaSelfPerformingParsedAddress: serviceAreaSelfPerformingParsed,
					serviceAreaNotSelfPerformingParsedAddress: serviceAreaNotSelfPerformingParsed,
				};
				updateSupplierCompany(updatedSupplier);
			}
		});
	};

	handleRequestReviewSubmit = () => {
		const { form, sendReferralReviews, currentUser } = this.props;

		form.validateFields((err, values) => {
			if (!err) {
				// sending emails out for reviewd
				const emailAddressKeys = form.getFieldValue('emailAddressKeys');
				const emailAddresses = emailAddressKeys
					.map((k) => form.getFieldValue(`emailAddresses-${k}`))
					.filter((ea) => ea !== undefined && ea != '');
				const reviewReferral = {
					referrerEmail: currentUser.email,
					refereeEmails: emailAddresses,
				};
				sendReferralReviews(reviewReferral);
			}
		});
	};

	goNext(validationSteps) {
		const { form, formData } = this.props;
		const { activeStep, totalSteps } = this.state;
		form.validateFields(validationSteps, (err, values) => {
			if (err) {
				return;
			}
		});
	}

	goSkip() {
		const { activeStep } = this.state;
		this.setState({ activeStep: activeStep + 1 });
		return true;
	}

	goPrev() {
		const { activeStep } = this.state;
		if (activeStep === 1) {
			return false;
		}
		this.setState({ activeStep: activeStep - 1 });
		return true;
	}

	handleFileAttachmentUploadChange = (newAttachments) => {
		const { form, formData } = this.props;
		form.setFieldsValue({ buyerAttachments: newAttachments });
	};

	addServiceAreaSelfPerforming = () => {
		const { form } = this.props;
		const serviceAreaSelfPerformingKeys = form.getFieldValue('serviceAreaSelfPerformingKeys');
		const nextKeys = serviceAreaSelfPerformingKeys.concat(serviceAreaSelfPerformingUuid);
		form.setFieldsValue({
			serviceAreaSelfPerformingKeys: nextKeys,
		});
		serviceAreaSelfPerformingUuid++;
	};

	removeServiceAreaSelfPerforming = (k) => {
		const { form } = this.props;
		const serviceAreaSelfPerformingKeys = form.getFieldValue('serviceAreaSelfPerformingKeys');
		if (serviceAreaSelfPerformingKeys.length === 1) {
			return;
		}
		form.setFieldsValue({
			serviceAreaSelfPerformingKeys: serviceAreaSelfPerformingKeys.filter((key) => key !== k),
		});
	};

	addServiceAreaNotSelfPerforming = () => {
		const { form } = this.props;
		const serviceAreaNotSelfPerformingKeys = form.getFieldValue('serviceAreaNotSelfPerformingKeys');
		const nextKeys = serviceAreaNotSelfPerformingKeys.concat(serviceAreaNotSelfPerformingUuid);
		form.setFieldsValue({
			serviceAreaNotSelfPerformingKeys: nextKeys,
		});
		serviceAreaNotSelfPerformingUuid++;
	};

	removeServiceAreaNotSelfPerforming = (k) => {
		const { form } = this.props;
		const serviceAreaNotSelfPerformingKeys = form.getFieldValue('serviceAreaNotSelfPerformingKeys');
		if (serviceAreaNotSelfPerformingKeys.length === 1) {
			return;
		}
		form.setFieldsValue({
			serviceAreaNotSelfPerformingKeys: serviceAreaNotSelfPerformingKeys.filter((key) => key !== k),
		});
	};

	addEmailAddress = () => {
		const { form } = this.props;
		const emailAddressKeys = form.getFieldValue('emailAddressKeys');
		const nextKeys = emailAddressKeys.concat(emailAddressUuid);
		form.setFieldsValue({
			emailAddressKeys: nextKeys,
		});
		emailAddressUuid++;
	};

	removeEmailAddress = (k) => {
		const { form } = this.props;
		const emailAddressKeys = form.getFieldValue('emailAddressKeys');
		if (emailAddressKeys.length === 1) {
			return;
		}
		form.setFieldsValue({
			emailAddressKeys: emailAddressKeys.filter((key) => key !== k),
		});
	};

	render() {
		const {
			createErrors,
			updateErrors,
			creating,
			updating,
			form,
			formData,
			currentUser,
			supplierFacility,
			supplierFacilitiesFetching,
			supplierFacilityReviews,
		} = this.props;

		const { getFieldDecorator, getFieldValue } = form;
		const {
			activeStep,
			totalSteps,
			editingLicenses,
			editingCertifications,
			editingInsuranceCoverage,
			isSignedUp,
			loading,
			supplierAssociated,
		} = this.state;
		const keyExtractor = (item) => `${item.id}`;
		const isLoading = creating || updating;
		const BACKEND_URI = getBackendUri();
		const uploadHeaders = { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token };

		const getNextText = (activeStep) => {
			if (activeStep === 3) {
				return 'Update Service Area';
			} else if (activeStep === 4) {
				return 'Request Reviews';
			}
			return 'Next';
		};

		const policyDocColumns = [
			{
				title: 'Policy',
				dataIndex: 'policyName',
			},
			{
				title: 'Amount',
				dataIndex: 'amount',
				render: (text, record) => <span>${text.toLocaleString('en')}</span>,
			},
			{
				title: 'Expires',
				dataIndex: 'expiration',
				render: (text, record) => dateFormatter(text, 'MMM D, YYYY'),
			},
			{
				title: 'Attachment',
				dataIndex: 'policyFile',
				render: (text, record) =>
					text ? (
						<FileUploader roleType="supplier" defaultFileList={[text]} uploaderType="readOnly" />
					) : (
						'--'
					),
			},
		];

		const serviceAreaSelfPerformingKeysInitialValue = [0];
		getFieldDecorator('serviceAreaSelfPerformingKeys', {
			initialValue: serviceAreaSelfPerformingKeysInitialValue,
		});

		const serviceAreaSelfPerformingKeys = getFieldValue('serviceAreaSelfPerformingKeys');

		const serviceAreaSelfPerformingFormItems = serviceAreaSelfPerformingKeys.map(
			(k, serviceAreaIndex) => {
				const handleLocationAutocompleteSelect = (addr, lonLat, parsedAddress) => {
					const { form } = this.props;
					form.setFieldsValue({
						[`serviceAreas-${k}-SelfPerforming`]: addr,
						[`serviceAreas-${k}-SelfPerformingParsedAddress`]: parsedAddress,
					});
				};
				return (
					<div key={k}>
						<div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
							<div style={{ fontSize: '16px', color: 'black' }}>
								Service Area {serviceAreaIndex + 1}
							</div>
							<div style={{ flex: 1, marginLeft: 16 }}>
								{getFieldDecorator(`serviceAreas-${k}-SelfPerformingParsedAddress`)}
								{getFieldDecorator(`serviceAreas-${k}-SelfPerforming`, {
									validateTrigger: ['onChange', 'onBlur'],
								})(
									<LocationAutocomplete
										lat={this.props.form.getFieldValue('latitude')}
										lon={this.props.form.getFieldValue('longitude')}
										initialValue={''}
										searchOptions={['regions']}
										placeholder="Select a state/county/postal code"
										onSelect={handleLocationAutocompleteSelect}
									/>
								)}
							</div>
							<div>
								{serviceAreaSelfPerformingKeys.length > 1 ? (
									<CloseOutlined
										className="dynamic-delete-button"
										onClick={() => this.removeServiceAreaSelfPerforming(k)}
									/>
								) : null}
							</div>
						</div>
					</div>
				);
			}
		);

		const serviceAreaNotSelfPerformingKeysInitialValue = [0];
		getFieldDecorator('serviceAreaNotSelfPerformingKeys', {
			initialValue: serviceAreaNotSelfPerformingKeysInitialValue,
		});

		const serviceAreaNotSelfPerformingKeys = getFieldValue('serviceAreaNotSelfPerformingKeys');

		const serviceAreaNotSelfPerformingFormItems = serviceAreaNotSelfPerformingKeys.map(
			(k, serviceAreaIndex) => {
				const handleLocationAutocompleteSelect = (addr, lonLat, parsedAddress) => {
					const { form } = this.props;
					form.setFieldsValue({
						[`serviceAreas-${k}-NotSelfPerforming`]: addr,
						[`serviceAreas-${k}-NotSelfPerformingParsedAddress`]: parsedAddress,
					});
				};

				return (
					<div key={k}>
						<div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
							<div style={{ fontSize: '16px', color: 'black' }}>
								Service Area {serviceAreaIndex + 1}
							</div>
							<div style={{ flex: 1, marginLeft: 16 }}>
								<input type="hidden" value="nodrop" />
								{getFieldDecorator(`serviceAreas-${k}-NotSelfPerformingParsedAddress`)}
								<div style={{ marginLeft: 16 }}>
									{getFieldDecorator(`serviceAreas-${k}-NotSelfPerforming`, {
										validateTrigger: ['onChange', 'onBlur'],
									})(
										<LocationAutocomplete
											initialValue={''}
											searchOptions={['regions']}
											placeholder="Select a state/county/postal code"
											onSelect={handleLocationAutocompleteSelect}
										/>
									)}
								</div>
							</div>
							<div>
								{serviceAreaNotSelfPerformingKeys.length > 1 ? (
									<CloseOutlined
										className="dynamic-delete-button"
										onClick={() => this.removeServiceAreaNotSelfPerforming(k)}
									/>
								) : null}
							</div>
						</div>
					</div>
				);
			}
		);

		const step1 = () => {
			return (
				<div>
					<Row style={{ margin: '0.5em -8px' }} gutter={16}>
						<Col span={24}>
							<SupplierClaimSignupForm
								facilityId={formData.supplierFacilityId}
								onSubmit={this.goNext}
							/>
						</Col>
					</Row>
				</div>
			);
		};

		const step2 = () => {
			return (
				<div>
					{loading && supplierFacilitiesFetching && supplierAssociated ? (
						<PageLoadingPlaceholder />
					) : (
						<Row style={{ margin: '0.5em -8px' }} gutter={16}>
							<Col span={24}>
								{isSignedUp ? (
									<SupplierCompanyForm
										formData={supplierFacility}
										minimal={true}
										newAccountSetupMode={true}
										onSubmit={this.goNext}
									/>
								) : null}
							</Col>
						</Row>
					)}
				</div>
			);
		};

		const step3 = () => {
			return (
				<div>
					<Row style={{ margin: '0.5em -8px' }} gutter={16}>
						<Col span={24}>
							<h6 style={{ marginBottom: 24 }}>Areas where you perform service yourself</h6>
							{[
								serviceAreaSelfPerformingFormItems,
								<Form.Item>
									<Button
										type="dashed"
										onClick={() => this.addServiceAreaSelfPerforming()}
										style={{ width: '100%' }}
									>
										<PlusOutlined /> Add Service Area
									</Button>
								</Form.Item>,
							]}
						</Col>
					</Row>
					<Row style={{ margin: '0.5em -8px' }} gutter={16}>
						<Col span={24}>
							<h6 style={{ marginBottom: 24 }}>Areas where you subcontract service</h6>
							{[
								serviceAreaNotSelfPerformingFormItems,
								<Form.Item>
									<Button
										type="dashed"
										onClick={() => this.addServiceAreaNotSelfPerforming()}
										style={{ width: '100%' }}
									>
										<PlusOutlined /> Add Service Area
									</Button>
								</Form.Item>,
							]}
						</Col>
					</Row>
					<Button style={{ opacity: 1 }} size="large" onClick={this.goPrev}>
						Back
					</Button>
					<Button style={{ opacity: 1, marginLeft: 16 }} size="large" onClick={this.goSkip}>
						Skip
					</Button>
					<Button
						type="primary"
						size="large"
						style={{ marginLeft: 16 }}
						loading={isLoading}
						onClick={() => this.goNext([])}
					>
						{getNextText(activeStep)}
					</Button>
				</div>
			);
		};

		const reviews = getRecordsForTargetCollection(supplierFacilityReviews, 'supplierDetailReviews');
		const numOfReviews = reviews.length;
		const averageRating =
			reviews.length > 0
				? (reviews.reduce((acc, r) => acc + r.rating, 0) / numOfReviews).toFixed(1)
				: '0';
		const roundedRatings = reviews.map((r) => Math.round(r.rating));
		const ratingsByStar = [1, 2, 3, 4, 5].reduce((acc, el) => {
			acc[el] = roundedRatings.filter((r) => r === el).length;
			return acc;
		}, {});

		const emailAddressKeysInitialValue = [0, 1, 2];
		getFieldDecorator('emailAddressKeys', { initialValue: emailAddressKeysInitialValue });

		const emailAddressKeys = getFieldValue('emailAddressKeys');

		const emailAddressFormItems = emailAddressKeys.map((k, emailAddressIndex) => {
			return (
				<div key={k} style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
					<div style={{ fontSize: '16px', color: 'black' }}>
						Customer Email {emailAddressIndex + 1}
					</div>
					<div style={{ marginLeft: 16 }}>
						{getFieldDecorator(`emailAddresses-${k}`, {
							validateTrigger: ['onChange', 'onBlur'],
						})(<Input style={{ width: 400 }} />)}
					</div>
					<div>
						{emailAddressKeys.length > 1 ? (
							<CloseOutlined
								className="dynamic-delete-button"
								onClick={() => this.removeEmailAddress(k)}
							/>
						) : null}
					</div>
				</div>
			);
		});

		const step4 = () => {
			return (
				<div>
					<Row style={{ margin: '0.5em -8px' }} gutter={16}>
						<Col span={24}>
							{reviews.length > 0 ? (
								<Card title="Your Current Reviews">
									<div>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												marginLeft: 16,
												marginTop: 8,
												marginBottom: 32,
											}}
										>
											<div>
												<div style={{ fontSize: 22, fontWeight: 'bold' }}>{averageRating}</div>
												<div style={{ marginBottom: 4 }}>
													<Rate
														style={{ fontSize: 18 }}
														allowHalf={true}
														value={Math.round(parseFloat(averageRating) * 2) / 2}
														disabled={true}
													/>
												</div>
												<div>{numOfReviews} reviews</div>
											</div>
											<Divider
												style={{
													height: 80,
													marginLeft: 32,
													marginRight: 32,
												}}
												type="vertical"
											/>
											<div>
												{[5, 4, 3, 2, 1].map((el, idx) => (
													<div style={{ fontSize: 12 }}>
														<span>{el}</span>
														<StarOutlined
															style={{
																color: 'rgba(0, 0, 0, 0.1)',
																marginLeft: 4,
															}}
														/>
														<div
															style={{
																display: 'inline-block',
																position: 'relative',
																width: 104,
																marginLeft: 8,
																borderRadius: '5px',
																height: 8,
																overflow: 'hidden',
																background: 'rgba(0,0,0,0.1)',
															}}
														>
															<span
																style={{
																	display: 'inline-block',
																	position: 'absolute',
																	width:
																		104 * (numOfReviews ? ratingsByStar[el] / numOfReviews : 0),
																	height: 8,
																	background: 'rgb(250, 219, 20)',
																}}
															/>
														</div>
														<span style={{ marginLeft: 8 }}>
															{numOfReviews
																? floatToPercentageString(ratingsByStar[el] / numOfReviews, 0)
																: floatToPercentageString(0, 0)}
															, {ratingsByStar[el]}
														</span>
													</div>
												))}
											</div>
										</div>
										<Divider />
										<div>
											{reviews.map((review, idx) => (
												<div key={review.id} style={{ marginLeft: 16, marginBottom: 24 }}>
													<SupplierReview userType="buyer" review={review} />
												</div>
											))}
										</div>
									</div>
								</Card>
							) : null}
						</Col>
					</Row>
					{emailAddressFormItems}
					<Form.Item>
						<Button type="dashed" onClick={() => this.addEmailAddress()} style={{ width: '100%' }}>
							<PlusOutlined /> Add Email Address
						</Button>
					</Form.Item>
					<Button style={{ opacity: 1 }} size="large" onClick={this.goPrev}>
						Back
					</Button>
					<Button style={{ opacity: 1, marginLeft: 16 }} size="large" onClick={this.goSkip}>
						Skip
					</Button>
					<Button
						type="primary"
						size="large"
						style={{ marginLeft: 16 }}
						loading={isLoading}
						onClick={() =>
							this.goNext([`emailAddresses-1`, `emailAddresses-2`, `emailAddresses-3`])
						}
					>
						{getNextText(activeStep)}
					</Button>
				</div>
			);
		};

		const step5 = () => {
			return (
				<div>
					<Row style={{ margin: '0.5em -8px' }}>
						<Col span={24}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									marginBottom: 24,
								}}
							>
								<h5 style={{ marginBottom: 0 }}>Licenses</h5>
								{supplierFacility.licenses && supplierFacility.licenses.length > 0 ? (
									<Button onClick={() => this.handleEditToggle('editingLicenses')}>
										{editingLicenses ? 'Cancel' : 'Edit'}
									</Button>
								) : null}
							</div>
							{supplierFacilitiesFetching ? (
								<Spin />
							) : editingLicenses ? (
								<EditSupplierLicensesForm
									successCallback={(resp) => this.handleEditToggle('editingLicenses')}
									cancelCallback={() => this.handleEditCancel('editingLicenses')}
									supplierFacility={supplierFacility}
									licenses={supplierFacility.licenses}
								/>
							) : supplierFacility.licenses && supplierFacility.licenses.length > 0 ? (
								<div>
									{supplierFacility.licenses.map((license, idx) => (
										<Card
											key={idx}
											title={<span style={{ fontSize: 18 }}>{license.licenseType}</span>}
											extra={license.number}
											type="inner"
										>
											<KeyValueDisplay
												keyValueStore={{
													Issuer: license.issuer,
													Qualifier: license.qualifier,
													Expiration: dateFormatter(license.expiration, 'MMM D, YYYY'),
													Attachment: license.licenseFile,
												}}
												renderFuncs={{
													Attachment: (file) =>
														file ? (
															<FileUploader
																roleType="supplier"
																defaultFileList={file ? [file] : []}
																uploaderType="readOnly"
															/>
														) : (
															'--'
														),
												}}
											/>
										</Card>
									))}
								</div>
							) : (
								<EmptyState
									height={160}
									headline={"You haven't added any licenses yet."}
									body={
										<div style={{ textAlign: 'center' }}>
											<Button
												type="primary"
												icon={<PlusOutlined />}
												onClick={() => this.handleEditToggle('editingLicenses')}
											>
												Add licenses
											</Button>
										</div>
									}
								/>
							)}
						</Col>
					</Row>

					<Row gutter={16}>
						<Col span={24}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									marginTop: 32,
									marginBottom: 24,
								}}
							>
								<h5 style={{ marginBottom: 0 }}>Certifications</h5>
								{supplierFacility.certifications && supplierFacility.certifications.length > 0 ? (
									<Button onClick={() => this.handleEditToggle('editingCertifications')}>
										{editingCertifications ? 'Cancel' : 'Edit'}
									</Button>
								) : null}
							</div>
							{supplierFacilitiesFetching ? (
								<Spin />
							) : editingCertifications ? (
								<EditSupplierCertificationsForm
									successCallback={(resp) => this.handleEditToggle('editingCertifications')}
									cancelCallback={() => this.handleEditCancel('editingCertifications')}
									supplierFacility={supplierFacility}
									certifications={supplierFacility.certifications}
								/>
							) : supplierFacility.certifications && supplierFacility.certifications.length > 0 ? (
								<div>
									{supplierFacility.certifications.map((certification, idx) => (
										<Card
											key={idx}
											title={
												<span style={{ fontSize: 18 }}>{certification.certificationType}</span>
											}
											extra={certification.number}
											type="inner"
										>
											<KeyValueDisplay
												keyValueStore={{
													Issuer: certification.issuer,
													Qualifier: certification.qualifier,
													Expiration: dateFormatter(certification.expiration, 'MMM D, YYYY'),
													Attachment: certification.certificationFile,
												}}
												renderFuncs={{
													Attachment: (file) =>
														file ? (
															<FileUploader
																roleType="supplier"
																defaultFileList={file ? [file] : []}
																uploaderType="readOnly"
															/>
														) : (
															'--'
														),
												}}
											/>
										</Card>
									))}
								</div>
							) : (
								<EmptyState
									height={160}
									headline={"You haven't added any certifications yet."}
									body={
										<div style={{ textAlign: 'center' }}>
											<Button
												type="primary"
												icon={<PlusOutlined />}
												onClick={() => this.handleEditToggle('editingCertifications')}
											>
												Add certifications
											</Button>
										</div>
									}
								/>
							)}
						</Col>
					</Row>

					<Row gutter={16}>
						<Col span={24}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									marginTop: 32,
									marginBottom: 24,
								}}
							>
								<h5 style={{ marginBottom: 0 }}>Insurance Coverage</h5>
								{supplierFacility.insurances && supplierFacility.insurances.length > 0 ? (
									<Button onClick={() => this.handleEditToggle('editingInsuranceCoverage')}>
										{editingInsuranceCoverage ? 'Cancel' : 'Edit'}
									</Button>
								) : null}
							</div>
							{supplierFacilitiesFetching ? (
								<Spin />
							) : editingInsuranceCoverage ? (
								<EditSupplierInsuranceCoverageForm
									successCallback={(resp) => this.handleEditToggle('editingInsuranceCoverage')}
									cancelCallback={() => this.handleEditCancel('editingInsuranceCoverage')}
									supplierFacility={supplierFacility}
									insuranceCoverages={supplierFacility.insurances}
								/>
							) : supplierFacility.insurances && supplierFacility.insurances.length > 0 ? (
								<div>
									{supplierFacility.insurances.map((insurance, idx) => (
										<Card
											type="inner"
											title={<span style={{ fontSize: 18 }}>{insurance.companyName}</span>}
										>
											<div style={{ padding: 16, display: 'flex' }}>
												<div>
													<div
														style={{
															fontSize: 16,
															marginBottom: 4,
														}}
													>
														{insurance.companyContact.name}
													</div>
													<div
														style={{
															fontSize: 14,
															color: 'rgba(0,0,0,0.45)',
														}}
													>
														{insurance.companyContact.phone}
													</div>
												</div>
												<div
													style={{
														fontSize: 14,
														marginLeft: 32,
														color: 'rgba(0,0,0,0.45)',
													}}
												>
													<AddressDisplay address={insurance.companyContact.address} />
												</div>
											</div>
											<Table
												pagination={false}
												dataSource={insurance.policyDocs}
												columns={policyDocColumns}
											/>
										</Card>
									))}
								</div>
							) : (
								<EmptyState
									height={160}
									headline={"You haven't uploaded any insurance information yet."}
									body={
										<div style={{ textAlign: 'center' }}>
											<Button
												type="primary"
												icon={<PlusOutlined />}
												onClick={() => this.handleEditToggle('editingInsuranceCoverage')}
											>
												Add insurance coverage
											</Button>
										</div>
									}
								/>
							)}
						</Col>
					</Row>

					<Button style={{ opacity: 1 }} size="large" onClick={this.goPrev}>
						Back
					</Button>
					<Button style={{ opacity: 1, marginLeft: 16 }} size="large" onClick={this.goSkip}>
						Skip
					</Button>
					<Button
						type="primary"
						size="large"
						style={{ marginLeft: 16 }}
						loading={isLoading}
						onClick={() => this.goNext([])}
					>
						{getNextText(activeStep)}
					</Button>
				</div>
			);
		};

		const step6 = () => {
			return (
				<div>
					<Row style={{ margin: '0.5em -8px' }} gutter={16}>
						<Col span={24}>
							{isSignedUp ? (
								<SupplierContactForm
									newAccountSetupMode={true}
									formData={currentUser}
									goPrev={this.goPrev}
								/>
							) : null}
						</Col>
					</Row>
				</div>
			);
		};

		const steps = [
			{
				shortTitle: 'Sign Up',
				title: 'Welcome to OpenWrench!',
				subtitle: "Set up your account to get started (it's free!).",
				content: step1(),
			},
			{
				shortTitle: 'Company',
				title: "Let's set your company",
				subtitle: "Let's start by getting your company set up.",
				content: step2(),
			},
			{
				shortTitle: 'Service Coverage',
				title: "Buyers can't hire you if they can't find you.",
				subtitle:
					'Please list the states, counties, cities or zip codes where you provide service so that potential customers can find your company.',
				content: step3(),
			},
			{
				shortTitle: 'Reviews',
				title:
					'92% of business-to-business buyers are more likely to purchase after reading a trusted review.',
				subtitle: 'Invite your best customers to leave you a review on OpenWrench.',
				content: step4(),
			},
			{
				shortTitle: 'Licenses and Insurance',
				title:
					'78% of buyers on OpenWrench want to see license and insurance coverage before making a purchase.',
				subtitle:
					"Add your licenses, certifications, insurance coverage so buyers don't need to ask.",
				content: step5(),
			},
			{
				shortTitle: 'Your Account',
				title: "Last step! Let's set up your profile.",
				subtitle: null,
				content: step6(),
			},
		];

		return (
			<Row className="supplierClaimSignUpForm" style={{ minHeight: '100%', display: 'flex' }}>
				<Col md={8} sm={24}>
					<div
						style={{
							minHeight: '100%',
							padding: '40px 80px',
							background: 'linear-gradient(150deg, #4a1e87 15%, rgb(57, 90, 155) 100%)',
						}}
					>
						<img
							width="auto"
							height="48px"
							src="https://s3.amazonaws.com/emptor-data-assets/images/emptor-logo/openwrenchlogo-white.svg"
						/>
						<div className="supplierClaimSignUpForm__steps">
							<Steps progressDot={true} direction="vertical" current={activeStep - 1}>
								{steps.map((item) => (
									<Step key={item.shortTitle} title={item.shortTitle} />
								))}
							</Steps>
						</div>
					</div>
				</Col>
				<Col md={16} sm={24}>
					<Card style={{ minHeight: '100%' }} bodyStyle={{ padding: '40px 80px' }}>
						<div>
							{createErrors.length > 0 ? (
								<Alert style={{ marginBottom: 24 }} message={createErrors.join(' ')} type="error" />
							) : null}
							{updateErrors.length > 0 ? (
								<Alert style={{ marginBottom: 24 }} message={updateErrors.join(' ')} type="error" />
							) : null}
							{steps.map((step, idx) => (
								<div style={{ display: idx === activeStep - 1 ? 'block' : 'none' }}>
									<div style={{ marginBottom: 40 }}>
										<h4 className="supplierClaimSignUpFormStepTitle">{step.title}</h4>
										{step.subtitle ? (
											<div className="supplierClaimSignUpForm__subtitle">{step.subtitle}</div>
										) : null}
									</div>
									<div className="preferredSupplierFlowSteps">{step.content}</div>
								</div>
							))}
						</div>
					</Card>
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,
	createErrors: state.supplier_facilities.createErrors,
	updateErrors: state.supplier_facilities.updateErrors,
	formData: ownProps.formData,
	creating: state.supplier_facilities.creating,
	updating: state.supplier_facilities.updating,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	supplierFacilityReviews: state.supplier_facility_reviews,
	supplierFacilitiesFetching: state.supplier_facilities.fetching,
	supplierFacility: state.supplier_facilities.detail,
});

const mapDispatchToProps = (dispatch) => ({
	fetchSupplierCompany: (id) => dispatch(suppliersRestCrudThunksForSupplier.readOne(id)),
	fetchSupplierFacility: (id) => dispatch(supplierFacilitiesRestCrudThunksForSupplier.readOne(id)),
	fetchSupplierFacilityReviews: (supplierFacilityId) =>
		dispatch(
			supplierFacilityReviewsRestCrudThunksForSupplier.read(
				{ supplierFacilityId },
				'supplierDetailReviews'
			)
		),
	updateSupplierCompany: (entity) => dispatch(suppliersRestCrudThunksForSupplier.update(entity)),
	sendReferralReviews: (entity) => dispatch(sendReferralReviewsForSupplier(entity)),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<SupplierClaimSignUpFlowFormProps>()(SupplierClaimSignUpFlowForm))
);

import { FIELD_TECHS_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/field_techs_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = standardInitialStateGenerator([
	'fieldTechsIndex',
	'scheduleServiceCallDropdown',
	'scheduleServiceCallAdditionalTechsDropdown',
	'primaryTechForLocation',
	'workOrdersAdvancedFilters',
	'KPI_SCORECARD_TECHS',
]);
export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState, 'email');

import * as React from 'react';
import { Layout, Card, Button, Modal, Table, message, Popconfirm } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import {
	partsRequestedWorkOrderForSupplier,
	workOrdersRestCrudThunksForSupplier,
} from '../../thunks/work_orders_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import PartsWithQuantityRowDisplay from './PartsWithQuantityRowDisplay';
import AddPartsModal from './AddPartsModal';
import {
	removeEquipmentFromServiceCallForSupplier,
	removePartFromServiceCallForSupplier,
} from '../../thunks/service_calls_thunks';
import EquipmentInServiceCallRowDisplay from './EquipmentInServiceCallRowDisplay';
import AddEquipmentModal from './AddEquipmentModal';
import PurchaseRequestForm from '../purchase_request_form/PurchaseRequestForm';
import { capitalizeEachWord } from '../../utils/DataFormatterUtils';
import {
	getEntityById,
	nullSafeGet,
	nullSafeGetOrElse,
	renderCurrency,
} from '../../utils/DataAccessUtils';
import { purchaseRequestsRestCrudThunksForSupplier } from '../../thunks/purchase_requests_thunks';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import { requestedPartsRestCrudThunksForSupplier } from '../../thunks/requested_parts_thunks';
import RequestedPartsSectionInWorkOrder from './RequestedPartsSectionInWorkOrder';
import PurchaseRequestsSectionInWorkOrder from './PurchaseRequestsSectionInWorkOrder';
import { LIMIT_ONE_PAGINATION } from '../../utils/DataConstants';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { PERMISSION_NAMES, isSupplierAllowedToAccess } from '../../utils/AuthUtils';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import EditWOPartQuantityModal from './EditWOPartQuantityModal';
import { FINISHED_WORK_ORDER_DISPLAY_STATUSES } from '../../constants/WorkOrderDisplayStatuses';

const { Content } = Layout;
const BACK_LINK_TEXT = 'Back to work order';

class SupplierWorkOrderDetailChargesPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			addPartsModalVisible: false,
			addEquipmentsModalVisible: false,
			requestedPart: {},
			requestedEquipment: {},
			createPRVisible: false,
			loading: false,
			woStockLocationId: undefined,
			requestedPartsAvailable: false,
			requestedPartQuantityVisible: false,
			currentRequestedPart: null,
			purchaseRequestsAvailable: false,
			releaseText: '',
			woPartQuantityModalVisible: false,
			currentEditingPart: null,
		};
	}

	fetchWorkOrder = (): any => {
		return new Promise((resolve, reject) => {
			const { getWorkOrder, match } = this.props;
			const workOrderId = match.params.id;
			getWorkOrder(workOrderId).then((workOrder) => {
				// workOrder.purchaseRequestIds = [23]
				resolve(workOrder);
				if (workOrder.purchaseRequestIds && workOrder.purchaseRequestIds.length > 0) {
				}
			});
		});
	};

	checkForRequestedParts = () => {
		const { fetchRequestedParts, match } = this.props;
		const workOrderId = match.params.id;

		fetchRequestedParts({ workOrderId }, null, LIMIT_ONE_PAGINATION).then((res) =>
			this.setState({ requestedPartsAvailable: res && res.length > 0 })
		);
	};

	checkForPurchaseRequests = () => {
		const { fetchPurchaseRequests, match } = this.props;
		const workOrderId = match.params.id;

		fetchPurchaseRequests({ workOrderId }, null, LIMIT_ONE_PAGINATION).then((res) =>
			this.setState({ purchaseRequestsAvailable: res && res.length > 0 })
		);
	};

	componentDidMount() {
		const { fetchStockLocations, workOrder } = this.props;
		if (workOrder && workOrder.id) {
			const locationId = workOrder.locationId;
			locationId &&
				fetchStockLocations({
					locationId,
				}).then((stockLocations) => {
					stockLocations &&
						stockLocations.length > 0 &&
						this.setState({ woStockLocationId: stockLocations[0].id });
				});
		} else {
			this.fetchWorkOrder().then((workOrder) => {
				const locationId = workOrder.locationId;
				locationId &&
					fetchStockLocations({
						locationId,
					}).then((stockLocations) => {
						stockLocations &&
							stockLocations.length > 0 &&
							this.setState({ woStockLocationId: stockLocations[0].id });
					});
			});
		}
		this.checkForRequestedParts();
		this.checkForPurchaseRequests();
	}

	refreshChargesPage = () => {
		this.fetchWorkOrder();
		this.checkForRequestedParts();
		this.checkForPurchaseRequests();
	};

	showAddPartsModal = () => this.setState({ addPartsModalVisible: true });

	hideAddPartsModal = () => this.setState({ addPartsModalVisible: false });

	changeStatusToPartsRequestedAndRefreshChargesPage = () => {
		const { workOrder, partsRequested } = this.props;
		this.setState({ loading: true });
		partsRequested(workOrder)
			.then(() => this.refreshChargesPage())
			.catch((err) => message.error('Unable to update status!', err))
			.finally(() => this.setState({ loading: false }));
	};

	addPartsSuccess = () => {
		this.hideAddPartsModal();
		this.changeStatusToPartsRequestedAndRefreshChargesPage();
	};

	showAddEquipmentsModal = () => this.setState({ addEquipmentsModalVisible: true });

	hideAddEquipmentsModal = () => this.setState({ addEquipmentsModalVisible: false });

	addEquipmentsSuccess = () => {
		this.hideAddEquipmentsModal();
		this.refreshChargesPage();
	};

	onRemovePart = (partWithServiceCall) => () => {
		const { removePartFromServiceCall } = this.props;

		const entity = {
			serviceCallId: partWithServiceCall.serviceCall.id,
			partId: partWithServiceCall.part.partId,
			...(nullSafeGet('part.locationId', partWithServiceCall) && {
				locationId: partWithServiceCall.part.locationId,
			}),
			...(nullSafeGet('part.partsPerStockLocationId', partWithServiceCall) && {
				partsPerStockLocationId: partWithServiceCall.part.partsPerStockLocationId,
			}),
		};
		removePartFromServiceCall(entity).then(() => this.refreshChargesPage());
	};

	hideEditPartModal = () => {
		this.setState({
			currentEditingPart: null,
			woPartQuantityModalVisible: false,
		});
	};

	onPartQuantityEditSuccess = () => {
		this.hideEditPartModal();
		this.refreshChargesPage();
	};

	editPartQuantity = (partWithServiceCall) => () => {
		this.setState({
			currentEditingPart: partWithServiceCall,
			woPartQuantityModalVisible: true,
		});
	};

	onRemoveEquipment = (equipmentWithServiceCall) => () => {
		const { removeEquipmentFromServiceCall } = this.props;
		removeEquipmentFromServiceCall({
			serviceCallId: equipmentWithServiceCall.serviceCall.id,
			equipmentPerStockLocationId: equipmentWithServiceCall.equipmentType.id,
		}).then(() => this.fetchWorkOrder());
	};

	onCreatePartPurchaseRequest = ({
		part,
		quantity,
		stockLocationId,
		locationId,
		disableLocation,
	}) => {
		this.hideAddPartsModal();
		const price =
			nullSafeGet('defaultPartCatalog.listPrice', part) ||
			nullSafeGet('defaultPartCatalog.unitCost', part) ||
			nullSafeGet('cost', part);
		this.setState({
			requestedPart: {
				part,
				partId: nullSafeGet('id', part),
				partCatalog: {
					...nullSafeGetOrElse('defaultPartCatalog', part, {}),
					...(nullSafeGet('defaultPartCatalog.id', part) && {
						partEquipmentVendor: nullSafeGet('defaultVendor', part),
					}),
				},
				partCatalogId: part.defaultPartCatalogId,
				quantity: quantity || 1,
				unitCost: price || undefined,
				cost: !!price ? price * (quantity || 1) : undefined,
				stockLocationId,
				locationId,
				disableLocation,
			},
			createPRVisible: true,
		});
	};

	onCreateEquipmentPurchaseRequest = (equipmentType, stockLocationId) => {
		this.hideAddEquipmentsModal();
		const price =
			nullSafeGet('defaultEquipmentCatalog.listPrice', equipmentType) ||
			nullSafeGet('defaultEquipmentCatalog.unitCost', equipmentType) ||
			nullSafeGet('cost', equipmentType);
		this.setState({
			requestedEquipment: {
				equipmentType,
				quantity: 1,
				equipmentTypeId: nullSafeGet('id', equipmentType),
				equipmentCatalog: {
					...nullSafeGetOrElse('defaultEquipmentCatalog', equipmentType, {}),
					...(nullSafeGet('defaultEquipmentCatalog.id', equipmentType) && {
						partEquipmentVendor: nullSafeGet('defaultVendor', equipmentType),
					}),
				},
				equipmentCatalogId: equipmentType.defaultEquipmentCatalogId,
				unitCost: price || undefined,
				cost: price || undefined,
				stockLocationId,
			},
			createPRVisible: true,
		});
	};

	getPRTitlePrefix = () => {
		const { requestedPart, requestedEquipment } = this.state;
		const partNumber = nullSafeGet('part.partNumber', requestedPart)
			? `#${nullSafeGetOrElse('part.partNumber', requestedPart, '')} - `
			: '';
		const partName = nullSafeGet('part.name', requestedPart)
			? `'${nullSafeGetOrElse('part.name', requestedPart, '')}'`
			: '';
		const equipmentNumber = nullSafeGet(`equipmentType.equipmentTypeNumber`, requestedEquipment)
			? `#${nullSafeGet(`equipmentType.equipmentTypeNumber`, requestedEquipment)} - `
			: '';
		const equipmentName = nullSafeGet('equipmentType.modelName', requestedEquipment)
			? `'${nullSafeGetOrElse('equipmentType.modelName', requestedEquipment, '')}'`
			: '';
		return nullSafeGet('partId', requestedPart)
			? `Part ${partNumber} ${partName}`
			: `Equipment ${equipmentNumber} ${equipmentName}`;
	};

	hideCreatePRModal = () =>
		this.setState({
			createPRVisible: false,
			requestedPart: {},
			requestedEquipment: {},
		});

	onCreatePurchaseRequestSuccess = (record) => {
		const { workOrder, updateWorkOrder, partsRequested } = this.props;
		this.setState({ loading: true });
		updateWorkOrder({
			...workOrder,
			purchaseRequestIds: [...(workOrder.purchaseRequestIds || []), record.id],
		})
			.then(() => partsRequested(workOrder).then((values) => this.refreshChargesPage()))
			.finally(() => this.setState({ loading: false }));
		this.hideCreatePRModal();
	};

	onPartOrEquipmentRow = (urlPrefix) => (record) => ({
		onClick: () => {
			const { history, location } = this.props;
			const path = `/supplier/${urlPrefix}/detail/${record.id}/details`;
			history.push(getLinkWIthBackLinkParams(location, BACK_LINK_TEXT, path));
		},
	});

	getTechnicianName = () => {
		const { currentUser } = this.props;
		return currentUser.nameGiven && currentUser.nameFamily
			? capitalizeEachWord([currentUser.nameGiven, currentUser.nameFamily].join(' '))
			: '';
	};

	getPRName = () => {
		const { workOrder } = this.props;
		const prefix = this.getPRTitlePrefix();

		return `${prefix} requested by ${this.getTechnicianName()} for work order : #${workOrder.id}`;
	};

	render() {
		const { workOrder, workOrdersFetching, companyConfig, currentUser } = this.props;

		const allParts = workOrder.associatedServiceCalls
			? workOrder.associatedServiceCalls.reduce((acc, sc, idx, arr) => {
					const partsInServiceCall = sc.partsWithQuantity.map((part) => ({
						serviceCallNumber: arr.length - idx,
						serviceCall: sc,
						part,
					}));
					return [...acc, ...partsInServiceCall];
			  }, [])
			: [];

		const partsTotalCost = allParts.reduce((acc, part) => {
			const totalCost = parseFloat(nullSafeGetOrElse('part.totalPartsCost', part, 0));
			return acc + totalCost;
		}, 0);

		const allEquipments = workOrder.associatedServiceCalls
			? workOrder.associatedServiceCalls.reduce((acc, sc, idx, arr) => {
					const equipmentsInServiceCall = sc.equipmentPerStockLocations.map((equipmentType) => ({
						serviceCallNumber: arr.length - idx,
						serviceCall: sc,
						equipmentType,
					}));
					return [...acc, ...equipmentsInServiceCall];
			  }, [])
			: [];

		const defaultServiceCall = workOrder.lastServiceCall;

		const canViewCost = isSupplierAllowedToAccess(
			PERMISSION_NAMES.CAN_VIEW_PART_EQUIPMENT_COST_IN_WO,
			companyConfig,
			nullSafeGet('roles', currentUser)
		);

		const partsColumns = [
			{
				title: 'Part',
				dataIndex: 'partId',
				render: (text, record) => <PartsWithQuantityRowDisplay partWithServiceCall={record} />,
			},
			{
				title: 'Quantity',
				dataIndex: ['part', 'quantity'],
				render: (qty) =>
					qty ? (
						qty
					) : (
						<div className="flex flex-row items-center">
							<div>0</div>
							<div className="ml-1 text-gray-400">{`(Returned)`}</div>
						</div>
					),
			},
			...(canViewCost
				? [
						{
							title: 'Cost',
							dataIndex: ['part', 'totalPartsCost'],
							render: (cost) => renderCurrency(currentUser)(cost),
						},
				  ]
				: []),
			{
				render: (_, record) =>
					FINISHED_WORK_ORDER_DISPLAY_STATUSES.includes(workOrder.displayStatus) ? null : (
						<div className="flex flex-row items-center">
							<EditOutlined
								title="Edit"
								className="hover:cursor-pointer"
								translate=""
								onClick={this.editPartQuantity(record)}
							/>
							<div className="ml-2">
								<Popconfirm
									onConfirm={this.onRemovePart(record)}
									title="Are you sure you want to remove this part?"
									okText="Yes"
									cancelText="No"
								>
									<DeleteOutlined title="Delete" style={{ marginLeft: 8 }} translate="" />
								</Popconfirm>
							</div>
						</div>
					),
			},
		];

		const equipmentColumns = [
			{
				title: 'ID',
				dataIndex: 'id',
				sorter: true,
				render: (text, record) => (
					<EquipmentInServiceCallRowDisplay
						equipmentWithServiceCall={record}
						successCallback={this.fetchWorkOrder}
						onConfirmRemove={this.onRemoveEquipment(record)}
					/>
				),
			},
		];

		return workOrdersFetching || this.state.loading ? (
			<PageLoadingPlaceholder />
		) : (
			<Content
				className="supplierWorkOrderDetailChargesPagePage"
				style={{
					padding: '0 0.5em',
				}}
			>
				<LogOnMountWithStandardEventProperties eventType="visited supplier work order charges page" />
				<div>
					{this.state.purchaseRequestsAvailable && (
						<div className="rowSpacing">
							<PurchaseRequestsSectionInWorkOrder />
						</div>
					)}
					{this.state.requestedPartsAvailable && (
						<div className="rowSpacing">
							<RequestedPartsSectionInWorkOrder
								onRefresh={this.refreshChargesPage}
								defaultServiceCall={defaultServiceCall}
							/>
						</div>
					)}
					<div className="rowSpacing">
						<Card
							title={`Parts`}
							extra={
								<div className="flex flex-row items-center">
									{nullSafeGet('0', allParts) ? (
										<AccessPermissionChecker
											name={PERMISSION_NAMES.CAN_VIEW_PART_EQUIPMENT_COST_IN_WO}
										>
											<div className="mr-2">
												{`Total Cost: ${renderCurrency(currentUser)(partsTotalCost)}`}
											</div>
										</AccessPermissionChecker>
									) : null}
									<Button onClick={this.showAddPartsModal}>Add parts</Button>
								</div>
							}
						>
							<Table
								columns={partsColumns}
								rowKey={(part) => part['partId']}
								dataSource={allParts}
								showHeader={!!nullSafeGet('0', allParts)}
								loading={false}
								locale={{
									emptyText: 'No parts added',
								}}
							/>
						</Card>
					</div>
					<div className="rowSpacing">
						<Card
							title={`Equipment`}
							extra={<Button onClick={this.showAddEquipmentsModal}>Add equipment</Button>}
						>
							<Table
								columns={equipmentColumns}
								rowKey={(equipmentPerStockLocation) => equipmentPerStockLocation['id']}
								dataSource={allEquipments}
								showHeader={false}
								loading={false}
								locale={{
									emptyText: 'No equipment added',
								}}
							/>
						</Card>
					</div>
				</div>
				{this.state.addPartsModalVisible && (
					<AddPartsModal
						serviceCall={defaultServiceCall}
						woStockLocationId={this.state.woStockLocationId}
						onCancel={this.hideAddPartsModal}
						successCallback={this.addPartsSuccess}
						openCreatePurchaseRequest={this.onCreatePartPurchaseRequest}
					/>
				)}
				{this.state.addEquipmentsModalVisible && (
					<AddEquipmentModal
						serviceCall={defaultServiceCall}
						woStockLocationId={this.state.woStockLocationId}
						onCancel={this.hideAddEquipmentsModal}
						successCallback={this.addEquipmentsSuccess}
						openCreatePurchaseRequest={this.onCreateEquipmentPurchaseRequest}
					/>
				)}
				{this.state.createPRVisible && (
					<Modal
						visible={this.state.createPRVisible}
						closable
						width="95%"
						title="Create Purchase Request"
						onCancel={this.hideCreatePRModal}
						footer={null}
					>
						<div>
							<h5 style={{ marginBottom: 24 }}>New Purchase Request</h5>
							<PurchaseRequestForm
								isModal
								formData={{
									name: this.getPRName(),
									workOrder,
									workOrderId: workOrder.id,
									...(workOrder.isWarranty &&
										workOrder.assetId && { warrantyAssetId: workOrder.assetId }),
									description: `${this.getPRTitlePrefix()} requested in work order #${
										workOrder.id
									} (${workOrder.title}). Requested by ${this.getTechnicianName()}`,
									stockLocationId: nullSafeGet('state.requestedPart.stockLocationId', this),
									locationId: nullSafeGet('state.requestedPart.locationId', this),
									disableLocation: nullSafeGetOrElse(
										'state.requestedPart.disableLocation',
										this,
										false
									),
								}}
								requestedParts={
									nullSafeGet('state.requestedPart.partId', this) ? [this.state.requestedPart] : []
								}
								requestedEquipments={
									nullSafeGet('state.requestedEquipment.equipmentTypeId', this)
										? [this.state.requestedEquipment]
										: []
								}
								onSuccess={this.onCreatePurchaseRequestSuccess}
							/>
						</div>
					</Modal>
				)}
				{this.state.woPartQuantityModalVisible ? (
					<EditWOPartQuantityModal
						partWithServiceCall={this.state.currentEditingPart}
						onSuccess={this.onPartQuantityEditSuccess}
						onCancel={this.hideEditPartModal}
					/>
				) : null}
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	workOrdersFetching: state.work_orders.fetching,
	workOrder: getEntityById(ownProps.match.params.id, state.work_orders),
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
	location: ownProps.location,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	getWorkOrder: (id) => dispatch(workOrdersRestCrudThunksForSupplier.readOne(id)),
	removePartFromServiceCall: (partData) => dispatch(removePartFromServiceCallForSupplier(partData)),
	removeEquipmentFromServiceCall: (equipmentData) =>
		dispatch(removeEquipmentFromServiceCallForSupplier(equipmentData)),
	updateWorkOrder: (workOrder) => dispatch(workOrdersRestCrudThunksForSupplier.update(workOrder)),
	fetchPurchaseRequests: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			purchaseRequestsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	partsRequested: (workOrder) => dispatch(partsRequestedWorkOrderForSupplier(workOrder)),
	fetchStockLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			stockLocationsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchRequestedParts: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			requestedPartsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SupplierWorkOrderDetailChargesPage)
);

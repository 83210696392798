import React, { FC, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EmptyState } from '../empty_state/EmptyState';
import {
	isSameSelectedLocation,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { REQUESTED_PARTS_CRUD_ACTION_CREATORS } from '../../actions/requested_parts_actions';
import {
	addShipmentToRequestedQuantityForSupplier,
	addShipmentsBulkToRequestedQuantityForSupplier,
	requestedPartsRestCrudThunksForSupplier,
	shipmentsCsvReportGeneratorForSupplier,
} from '../../thunks/requested_parts_thunks';
import HyperLink, { ENTITY_TYPE } from '../common/HyperLink';
import { Button, Popover } from 'antd';
import ReadyToShipModal from './ReadyToShipModal';
import { DATE_FORMAT, dateFormatter } from '../../utils/DataFormatterUtils';
import { partsRestCrudThunksForSupplier } from '../../thunks/parts_thunks';
import PartLocationDisplay from './PartLocationDisplay';
import { DownloadOutlined } from '@ant-design/icons';
import FeildTechSLManagePermissionChecker, {
	canFieldManageStockLocation,
} from '../common/FeildTechSLManagePermissionChecker';
import { locationsRestCrudThunksForSupplier } from '../../thunks/locations_thunks';
import DateTimeHover from '../date_time_component/DateTime';
import StockLocationShipmentsPrintable from './StockLocationShipmentsPrintable';

const SHIPMENTS_TC = 'stockLocationShipmentsIndex';
const AF_TC = 'requestedPartsAdvancedFilters';

const StockLocationShipmentsPage: FC<any> = ({
	match,
	updateFilters,
	clearAndUpdateFilters,
	updateSorters,
	requestedParts,
	fetchRequestedParts,
	parts,
	fetchParts,
	fetchMultipleParts,
	addShipment,
	addShipmentBulk,
	exportCsv,
	currentUser,
	stockLocation,
	locations,
	fetchLocations,
	fetchMultipleLocations,
}): React.ReactElement => {
	const stockLocationId = useMemo(() => match.params.id, [match.params.id]);

	const [currentRecord, setCurrentRecord] = useState(null);
	const [shipmentModalVisible, setShipmentModalVisible] = useState(false);

	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [selectedLocation, setSelectedLocation] = useState<any>({ id: undefined, name: undefined });
	const [selectedRecords, setSelectedRecords] = useState([]);

	const [isBulkShipment, setIsBulkShipment] = useState(false);

	const [downloading, setDownloading] = useState(false);

	const getStatSliceProp = useCallback(
		(propName) => nullSafeGetOrElse(`${SHIPMENTS_TC}.${propName}`, requestedParts, {}),
		[requestedParts]
	);

	const refreshRequestedParts = useCallback(() => {
		fetchRequestedParts(
			{},
			SHIPMENTS_TC,
			getStatSliceProp('pagination'),
			getStatSliceProp('sorting'),
			getStatSliceProp('filters')
		);
	}, [fetchRequestedParts, getStatSliceProp]);

	const onSelectCancel = useCallback(() => {
		setSelectedLocation({});
		setSelectedRowKeys([]);
		setSelectedRecords([]);
	}, []);

	const hideShipmentModal = useCallback(() => {
		setCurrentRecord(null);
		setShipmentModalVisible(false);
		setIsBulkShipment(false);
		onSelectCancel();
	}, [onSelectCancel]);

	const onAddShipment = useCallback(
		(record) => () => {
			setCurrentRecord(record);
			setShipmentModalVisible(true);
		},
		[]
	);

	const onShipmentSuccess = useCallback(() => {
		refreshRequestedParts();
		hideShipmentModal();
	}, [hideShipmentModal, refreshRequestedParts]);

	const canShip = useCallback(
		(record) =>
			!record.shipped &&
			nullSafeGetOrElse('requestedQuantity', record, 0) <=
				nullSafeGetOrElse('partsPerStockLocation.onHandQuantity', record, 0),
		[]
	);

	const shipmentsColumns = useMemo(
		() => [
			{
				title: 'Work Order',
				dataIndex: 'workOrder',
				render: (_) => (
					<div className="flex flex-col">
						<HyperLink
							text={`#${_.id}`}
							entityId={_.id}
							entityType={ENTITY_TYPE.WORK_ORDER_PARTS_AND_EQUPIPMENTS}
							backLinkText="Back to Shipments"
						/>
						<div className="mt-2">
							{_.createdAt ? dateFormatter(_.createdAt, DATE_FORMAT) : '--'}
						</div>
					</div>
				),
				key: 'workOrderCreatedAt',
				sorter: true,
			},
			{
				title: 'Ship To',
				dataIndex: ['workOrder', 'location', 'name'],
				sorter: true,
				key: 'shipTo',
			},
			{
				title: 'Part Number',
				dataIndex: ['partsPerStockLocation', 'part', 'partNumber'],
				render: (_) => _ || '--',
			},
			{
				title: 'Part Name',
				dataIndex: ['partsPerStockLocation', 'part', 'name'],
			},
			{
				title: 'Located at',
				dataIndex: 'partsPerStockLocation',
				render: (ppsl) => <PartLocationDisplay ppsl={ppsl} />,
			},
			{
				title: 'Requested Qty',
				dataIndex: 'requestedQuantity',
			},
			{
				title: 'On Hand Qty',
				dataIndex: ['partsPerStockLocation', 'onHandQuantity'],
			},
			{
				title: 'Requested At',
				dataIndex: 'createdAt',
				render: (_) =>
					_ ? <DateTimeHover timestamp={_} showDate={true} showTime={false} /> : '--',
				key: 'createdAt',
				sorter: true,
			},
			{
				title: 'Pending PR Qty',
				dataIndex: 'pendingPRQuantity',
				render: (text, record) =>
					text ? (
						<HyperLink
							text={text}
							newTabPath={`/supplier/purchaseRequests/overview/requests?stockLocationIds=${stockLocationId}&partIds=${nullSafeGet(
								'partsPerStockLocation.partId',
								record
							)}&statuses=${encodeURIComponent('requested,approved,orderInProgress')}`}
						/>
					) : (
						0
					),
			},
			{
				title: 'Pending PO Qty',
				dataIndex: 'pendingPOQuantity',
				render: (text, record) =>
					text ? (
						<HyperLink
							text={text}
							newTabPath={`/supplier/purchaseOrders/overview/orders?stockLocationIds=${stockLocationId}&partIds=${nullSafeGet(
								'partsPerStockLocation.partId',
								record
							)}&status=${encodeURIComponent('ordered')}`}
						/>
					) : (
						0
					),
			},
			{
				dataIndex: 'shipped',
				render: (_, record) => (
					<div className="flex flex-col items-center">
						<FeildTechSLManagePermissionChecker>
							<Button type="primary" disabled={!canShip(record)} onClick={onAddShipment(record)}>
								Ready to ship
							</Button>
						</FeildTechSLManagePermissionChecker>
					</div>
				),
			},
		],
		[canShip, onAddShipment, stockLocationId]
	);

	const filterConfig = useMemo(
		() => [
			{
				fieldName: 'readyToShip',
				type: 'radioGroup',

				items: [
					{
						label: 'All',
						value: 'undefined',
					},
					{
						label: 'Ready to Ship',
						value: 'true',
					},
					{
						label: 'Not Ready',
						value: 'false',
					},
				],
				defaultValue: undefined,
				showOutsideDropdown: true,
			},
			{
				label: 'Ship To',
				fieldName: 'shipToLocationIds',
				mode: 'multiple',
				stateSlice: locations,
				targetCollectionName: AF_TC,
				fetch: fetchLocations,
				fetchMultiple: fetchMultipleLocations,
			},
			{
				label: 'Part',
				fieldName: 'partIds',
				mode: 'multiple',
				stateSlice: parts,
				targetCollectionName: AF_TC,
				fetch: fetchParts,
				fetchMultiple: fetchMultipleParts,
				renderItem: (part) => (
					<div className="flex flex-row items-center">
						<div>{part.name}</div>
						<div>
							{part.partNumber && (
								<span className="text-gray-400">&nbsp;{`- #${part.partNumber}`}</span>
							)}
						</div>
					</div>
				),
			},
		],
		[fetchLocations, fetchMultipleLocations, fetchMultipleParts, fetchParts, locations, parts]
	);

	const onSelectChange = useCallback((newSelectedRowKeys: React.Key[], selectedRows) => {
		setSelectedRowKeys(newSelectedRowKeys);
		setSelectedRecords(selectedRows);
		if (selectedRows.length > 0) {
			const record = selectedRows[0];
			setSelectedLocation(nullSafeGetOrElse('workOrder.location', record, {}));
		} else {
			setSelectedLocation({});
		}
	}, []);

	const rowSelection = useMemo(
		() => ({
			selectedRowKeys,
			preserveSelectedRowKeys: true,
			onChange: onSelectChange,
			getCheckboxProps: (record) => ({
				disabled:
					!canShip(record) ||
					!isSameSelectedLocation(nullSafeGet('workOrder.location', record), selectedLocation),
			}),
		}),
		[canShip, onSelectChange, selectedLocation, selectedRowKeys]
	);

	const canBulkShip = useMemo(() => selectedRecords.length > 0, [selectedRecords.length]);

	const onShipBulk = useCallback(() => {
		setIsBulkShipment(true);
		setShipmentModalVisible(true);
	}, []);

	const onSingleShipment = useCallback(
		(values) =>
			new Promise((resolve, reject) => {
				addShipment(currentRecord, values)
					.then((res) => resolve(res))
					.catch((err) => reject(err));
			}),
		[addShipment, currentRecord]
	);

	const onBulkShipment = useCallback(
		(values) =>
			new Promise((resolve, reject) => {
				addShipmentBulk({
					...values,
					ids: selectedRowKeys,
				})
					.then((res) => resolve(res))
					.catch((err) => reject(err));
			}),
		[addShipmentBulk, selectedRowKeys]
	);

	const onSubmitShipment = useCallback(
		(values) => (isBulkShipment ? onBulkShipment(values) : onSingleShipment(values)),
		[isBulkShipment, onBulkShipment, onSingleShipment]
	);

	const downloadCSV = useCallback(() => {
		setDownloading(true);
		exportCsv({
			...getStatSliceProp('sorting'),
			...getStatSliceProp('filters'),
		}).finally(() => setDownloading(false));
	}, [exportCsv, getStatSliceProp]);

	return (
		<>
			<PaginatedReduxTableWithHeader
				targetCollectionName={SHIPMENTS_TC}
				updateFilters={updateFilters}
				stateSlice={requestedParts}
				clearAndUpdateFilters={clearAndUpdateFilters}
				updateSorters={updateSorters}
				filterConfig={filterConfig}
				entityCollectionName="requested_parts"
				tableColumns={shipmentsColumns}
				showHeader
				preAppliedFilters={{ stockLocationId, shipped: false }}
				fetchData={requestedPartsRestCrudThunksForSupplier.read}
				hasDefaultHeaderPage
				rightActions={
					canBulkShip ? (
						<div className="flex-start flex flex-row">
							<Button onClick={onSelectCancel} size="large">
								Cancel
							</Button>
							<Button className="ml-2" size="large" type="primary" onClick={onShipBulk}>
								Ship Selected Parts
							</Button>
						</div>
					) : (
						<div className="flex-start flex flex-row gap-x-4">
							<StockLocationShipmentsPrintable
								requestedParts={requestedParts}
								stockLocation={stockLocation}
							/>
							<Popover content="Export CSV" trigger="hover">
								<Button
									loading={downloading}
									size="large"
									icon={<DownloadOutlined translate="" />}
									className="inline-block-visible-md"
									onClick={downloadCSV}
								>
									<span className="inline-block-visible-xxl">Export CSV</span>
								</Button>
							</Popover>
						</div>
					)
				}
				rowSelection={canFieldManageStockLocation(currentUser, stockLocation) ? rowSelection : null}
				emptyState={
					<EmptyState
						graphic={
							<img
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
								alt="No shipments found!"
							/>
						}
						headline={'No shipments found!'}
						body={
							<div style={{ textAlign: 'center' }}>
								<div style={{ maxWidth: 440, marginBottom: 16 }}>
									No shipments found for this stock location
								</div>
							</div>
						}
					/>
				}
			/>
			{shipmentModalVisible && (
				<ReadyToShipModal
					onCancel={hideShipmentModal}
					successCallback={onShipmentSuccess}
					onSubmitShipment={onSubmitShipment}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	requestedParts: state.requested_parts,
	parts: state.parts,
	stockLocation: state.stock_locations.detail,
	currentUser: state.session.currentUser,
	locations: state.locations,
});

const mapDispatchToProps = (dispatch) => ({
	updateFilters: (filters, targetCollection) =>
		dispatch(REQUESTED_PARTS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			REQUESTED_PARTS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)
		),
	updateSorters: (sortBy, order, targetCollection) =>
		dispatch(REQUESTED_PARTS_CRUD_ACTION_CREATORS.updateSorting(sortBy, order, targetCollection)),
	fetchRequestedParts: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			requestedPartsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleParts: (ids, targetCollectionName) =>
		dispatch(partsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchParts: (params, targetCollectionName, pagination, sorting, filters, addToTargetCollection) =>
		dispatch(
			partsRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	addShipment: (entity, params) =>
		dispatch(addShipmentToRequestedQuantityForSupplier(entity, params)),
	addShipmentBulk: (data) => dispatch(addShipmentsBulkToRequestedQuantityForSupplier(data)),
	exportCsv: (params) => dispatch(shipmentsCsvReportGeneratorForSupplier(params)),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			locationsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StockLocationShipmentsPage));

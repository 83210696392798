import React, { FC, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button } from 'antd';
import { vendorsRestCrudThunksForSupplier } from '../../thunks/vendors_thunks';
import { EmptyState } from '../empty_state/EmptyState';
import { VendorRowDisplay } from '../vendor_row_display/VendorRowDisplay';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { VENDORS_CRUD_ACTION_CREATORS } from '../../actions/vendors_actions';
import { DEFAULT_NAME_SORTER } from '../../utils/DataConstants';
import { getIsActiveFilterField } from '../../utils/FilterUtils';

const TC_NAME = 'vendorsIndex';

const VendorsIndexPage: FC<any> = ({
	updateFilters,
	vendors,
	clearAndUpdateFilters,
	history,
}): React.ReactElement => {
	const onRow = useCallback(
		(record) => ({
			onClick: () => history.push(`/supplier/vendors/detail/${record.id}`),
		}),
		[history]
	);

	const columns = useMemo(
		() => [
			{
				title: 'Name',
				dataIndex: 'name',
				sorter: true,
				render: (text, record) => <VendorRowDisplay vendor={record} />,
			},
		],
		[]
	);

	const filterConfig = useMemo(
		() => [getIsActiveFilterField(updateFilters, TC_NAME, true)],
		[updateFilters]
	);

	return (
		<PaginatedReduxTableWithHeader
			targetCollectionName={TC_NAME}
			updateFilters={updateFilters}
			stateSlice={vendors}
			clearAndUpdateFilters={clearAndUpdateFilters}
			filterConfig={filterConfig}
			entityCollectionName="vendors"
			tableColumns={columns}
			onTableRow={onRow}
			fetchData={vendorsRestCrudThunksForSupplier.read}
			initialSorters={DEFAULT_NAME_SORTER}
			preAppliedFilters={{ isActive: true }}
			rightActions={
				<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_VENDORS}>
					<div>
						<Button
							style={{ marginLeft: 16 }}
							type="primary"
							size="large"
							onClick={() => history.push('/supplier/vendors/overview/new')}
						>
							New Vendor
						</Button>
					</div>
				</AccessPermissionChecker>
			}
			emptyState={
				<EmptyState
					graphic={
						<img
							style={{ marginBottom: 8 }}
							src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
							alt={`Looks like your team has not added any vendors yet.`}
						/>
					}
					headline={"Nothing's here!"}
					body={
						<div style={{ textAlign: 'center' }}>
							<div style={{ maxWidth: 440, marginBottom: 16 }}>
								Looks like your team has not added any vendors yet.
							</div>
							<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_VENDORS}>
								<Button
									type="primary"
									onClick={() => history.push('/supplier/vendors/overview/new')}
								>
									Add your first vendor
								</Button>
							</AccessPermissionChecker>
						</div>
					}
				/>
			}
		/>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	vendors: state.vendors,
});

const mapDispatchToProps = (dispatch) => ({
	updateFilters: (filters, targetCollection) =>
		dispatch(VENDORS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(VENDORS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)),
});

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(VendorsIndexPage));

import { Button, Form, message, Modal } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { purchaseOrderReceiptsRestCrudThunksForSupplier } from '../../thunks/purchase_order_receipt_thunks';
import {
	getConfigValueDefaultingToTrue,
	getCurrency,
	nullSafeGet,
	nullSafeGetOrElse,
	roundOffToDecimal,
} from '../../utils/DataAccessUtils';
import ReceiptFormFields from './ReceiptFormFields';
import { useForm } from 'antd/lib/form/Form';
import ReturnEquipmentSerialNumbersModal from './ReturnEquipmentSerialNumbersModal';
import { createMultipleEpslAsDeletedForSupplier } from '../../thunks/equipment_per_stock_locations_thunks';
import { createMultipleAssetsAsDeletedForSupplier } from '../../thunks/assets_thunks';
import { getPOLineItemUnitCostValue } from '../../utils/InventoryUtils';

const ReceivePurchaseOrderLineItemModal = ({
	lineItem,
	onCancel,
	onPOReceiptSuccess,
	onEquipmentCreateSuccess,
	isReturn,
	createPurchaseOrderReceipt,
	currentUser,
	companyConfig,
	createEpslsBulkAsDeleted,
	createAssetsBulkAsDeleted,
}): React.ReactElement => {
	const [form] = useForm();

	const currencyId = nullSafeGet('id', getCurrency({ currentUser }));

	const [saving, setSaving] = useState(false);
	const [formValues, setFormValues] = useState<any>({});
	const [showSerialNumberModal, setShowSerialNumberModal] = useState(false);

	const isEquipmentLine = useMemo(() => !!lineItem.isEquipmentLine, [lineItem]);

	const partMaxQuantity = useMemo(
		() =>
			isReturn
				? lineItem.partReceivedQuantity || 0
				: lineItem.partQuantity - (lineItem.partReceivedQuantity || 0),
		[isReturn, lineItem]
	);

	const equipmentMaxQuantity = useMemo(
		() =>
			isReturn
				? lineItem.equipmentReceivedQuantity || 0
				: lineItem.equipmentQuantity - (lineItem.equipmentReceivedQuantity || 0),
		[isReturn, lineItem]
	);

	const maxValue = useMemo(
		() => (!isEquipmentLine ? partMaxQuantity : equipmentMaxQuantity),
		[equipmentMaxQuantity, isEquipmentLine, partMaxQuantity]
	);

	const acceptSerialNumber = useMemo(
		() =>
			getConfigValueDefaultingToTrue(
				companyConfig,
				'inventoryConfig',
				'acceptSerialNumberForEquipmentReceiveInPO'
			),
		[companyConfig]
	);

	const getReceiptEntity = useCallback(
		(values, equipmentSerialNumbers?) => ({
			...values,
			purchaseOrderLineItemId: lineItem.id,
			purchaseOrderId: lineItem.supplierPurchaseOrderId,
			receiptNumber: '',
			...(values.assetTypeId && { assetTypeId: values.assetTypeId }),
			...(values.price && { pricePerQuantity: values.price }),
			...(values.invoiceTotal && { invoiceTotal: `${values.invoiceTotal}` }),
			...(values.exchangeRate && { exchangeRate: `${values.exchangeRate}` }),
			...(lineItem.partId && { partId: lineItem.partId }),
			...(lineItem.equipmentTypeId && { equipmentTypeId: lineItem.equipmentTypeId }),
			receivedQuantity: isReturn ? -1 * values.quantity : values.quantity,
			...(equipmentSerialNumbers && { equipmentSerialNumbers }),
		}),
		[isReturn, lineItem]
	);

	const createReceipt = useCallback(
		(values, equipmentSerialNumbers?) => {
			setSaving(true);
			createPurchaseOrderReceipt(getReceiptEntity(values, equipmentSerialNumbers))
				.then((res) => onPOReceiptSuccess && onPOReceiptSuccess(res))
				.catch((err) => message.error(err))
				.finally(() => setSaving(false));
		},
		[createPurchaseOrderReceipt, getReceiptEntity, onPOReceiptSuccess]
	);

	const hideEquipmentSerialNumbersModal = useCallback(() => setShowSerialNumberModal(false), []);

	const onSerialNumbersSuccess = useCallback(
		(serialNumbers) => {
			hideEquipmentSerialNumbersModal();
			createReceipt(formValues, serialNumbers);
		},
		[createReceipt, formValues, hideEquipmentSerialNumbersModal]
	);

	const createDeletedEpsls = useCallback(
		(values) => {
			const entities = Array.from(Array(values.quantity).keys()).map((_) => ({
				stockLocationId: nullSafeGet('supplierPurchaseOrder.stockLocationId', lineItem),
				equipmentTypeId: lineItem.equipmentTypeId,
				name: nullSafeGetOrElse('equipmentType.modelName', lineItem, ''),
				isRental: false,
				vendorId: nullSafeGet('supplierPurchaseOrder.partEquipmentVendorId', lineItem),
				images: [],
				supplierFacilityId: lineItem.supplierFacilityId,
				supplierCompanyId: lineItem.supplierCompanyId,
			}));

			setSaving(true);
			return createEpslsBulkAsDeleted(entities);
		},
		[createEpslsBulkAsDeleted, lineItem]
	);

	const createDeletedAssets = useCallback(
		(values) => {
			const entities = Array.from(Array(values.quantity).keys()).map((_) => ({
				locationId: nullSafeGet('supplierPurchaseOrder.locationId', lineItem),
				name: nullSafeGetOrElse('equipmentType.modelName', lineItem, ''),
				isActive: true,
				images: [],
				assetTypeId: values.assetTypeId,
				assetTypeDetails: [],
				isLeased: false,
				buyerCompanyId: nullSafeGet('supplierPurchaseOrder.location.buyerCompanyId', lineItem),
			}));

			setSaving(true);
			return createAssetsBulkAsDeleted(entities);
		},
		[createAssetsBulkAsDeleted, lineItem]
	);

	const createDeletedEntities = useCallback(
		(values) => {
			return new Promise((resolve, reject) => {
				const stockLocationId = nullSafeGet('supplierPurchaseOrder.stockLocationId', lineItem);
				const locationId = nullSafeGet('supplierPurchaseOrder.locationId', lineItem);
				if (stockLocationId) {
					createDeletedEpsls(values)
						.then((res) => resolve(res))
						.catch((err) => reject(err));
				} else if (locationId) {
					createDeletedAssets(values)
						.then((res) => resolve(res))
						.catch((err) => reject(err));
				} else {
					reject('Unable to find stock location/location of purchase order!');
				}
			});
		},
		[createDeletedAssets, createDeletedEpsls, lineItem]
	);

	const onSubmit = useCallback(
		(values) => {
			if (isReturn && isEquipmentLine && values.quantity < equipmentMaxQuantity) {
				setFormValues(values);
				setShowSerialNumberModal(true);
			} else {
				if (!isReturn && acceptSerialNumber && isEquipmentLine) {
					setSaving(true);
					createDeletedEntities(values)
						.then(
							(res) =>
								onEquipmentCreateSuccess && onEquipmentCreateSuccess(getReceiptEntity(values), res)
						)
						.catch((err) => message.error(err))
						.finally(() => setSaving(false));
				} else {
					createReceipt(values);
				}
			}
		},
		[
			isReturn,
			isEquipmentLine,
			equipmentMaxQuantity,
			acceptSerialNumber,
			createDeletedEntities,
			onEquipmentCreateSuccess,
			getReceiptEntity,
			createReceipt,
		]
	);

	const existingUnitCost = useMemo(
		() => getPOLineItemUnitCostValue(lineItem, isEquipmentLine ? 'equipment' : 'part')['unitCost'],
		[isEquipmentLine, lineItem]
	);

	const initialValues = useMemo(
		() => ({
			quantity: maxValue,
			invoiceTotal: existingUnitCost
				? roundOffToDecimal(existingUnitCost * maxValue, 2)
				: undefined,
			invoiceCurrency: nullSafeGetOrElse(
				'supplierPurchaseOrder.currencyId',
				lineItem,
				nullSafeGetOrElse('partCurrencyId', lineItem, currencyId)
			),
			price: existingUnitCost ? roundOffToDecimal(existingUnitCost, 2) : undefined,
		}),
		[maxValue, existingUnitCost, lineItem, currencyId]
	);

	const actionName = useMemo(() => (isReturn ? 'Return' : 'Receive'), [isReturn]);

	const titleText = useMemo(
		() =>
			`${actionName} ${
				isEquipmentLine
					? nullSafeGetOrElse('equipmentType.modelName', lineItem, 'Equipment')
					: nullSafeGetOrElse('part.name', lineItem, 'Part')
			}`,
		[actionName, isEquipmentLine, lineItem]
	);

	return (
		<Modal
			visible={true}
			width={600}
			title={titleText}
			closable
			onCancel={onCancel}
			forceRender
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button
					type="primary"
					size="large"
					style={{ marginLeft: '16px' }}
					key="submit"
					htmlType="submit"
					form="receive-po-line-item-form"
					loading={saving}
				>
					{actionName}
				</Button>,
			]}
		>
			<Form
				form={form}
				id="receive-po-line-item-form"
				layout="vertical"
				requiredMark={false}
				initialValues={initialValues}
				onFinish={onSubmit}
			>
				<ReceiptFormFields
					lineItem={lineItem}
					showAdvancedOptions
					isReturn={isReturn}
					maxValue={maxValue}
					form={form}
					existingUnitCost={existingUnitCost}
					defaultCurrencyId={nullSafeGet('invoiceCurrency', initialValues)}
				/>
			</Form>
			{showSerialNumberModal && (
				<ReturnEquipmentSerialNumbersModal
					quantity={formValues.quantity}
					onCancel={hideEquipmentSerialNumbersModal}
					onSuccess={onSerialNumbersSuccess}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	createPurchaseOrderReceipt: (entity) =>
		dispatch(purchaseOrderReceiptsRestCrudThunksForSupplier.create(entity)),
	createEpslsBulkAsDeleted: (entities) =>
		dispatch(createMultipleEpslAsDeletedForSupplier(entities)),
	createAssetsBulkAsDeleted: (entities) =>
		dispatch(createMultipleAssetsAsDeletedForSupplier(entities)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(ReceivePurchaseOrderLineItemModal)
);

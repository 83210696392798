import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getCurrency, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import moment from 'moment';
import { CSV_EXPORT_DEFAULTS, DEPRECIATION_METHODS } from '../../utils/DataConstants';
import {
	JOURNAL_TYPE,
	addNBVs,
	getDecliningBalanceProjection,
	getJournalValue,
	getStraightLineProjection,
} from './depreciation_utils';
import { Button, Card, Table } from 'antd';
import { EditOutlined, ExportOutlined } from '@ant-design/icons';
import DarkLineChart from '../charts/DarkLineChart';
import { ExportToCsv } from 'export-to-csv';
import DepreciationModelsPlaygroundModal from './DepreciationModelsPlaygroundModal';
import { HorizontalKeyValueDisplay } from '../horizontal_key_value_display/HorizontalKeyValueDisplay';
import { depreciationClassesRestCrudThunksForSupplier } from '../../thunks/depreciation_class_thunks';
import { DATE_ONLY_FORMAT, dateFormatter } from '../../utils/DataFormatterUtils';
import { assetNbvsRestCrudThunksForSupplier } from '../../thunks/asset_nbvs_thunks';
import { journalHistoriesRestCrudThunksForSupplier } from '../../thunks/journal_histories_thunks';
import { defaultFiscalEnd } from '../stock_locations_detail_details_page/burden_rate_utils';
import DateTimeHover from '../date_time_component/DateTime';

const defaultProjectDate = moment().add('years', 1);

const getDateProp = (type) =>
	type === JOURNAL_TYPE.IMPAIRMENT ? 'impairmentDate' : 'depreciationRunAt';

const isFiscalStartMonthJournal = (journal, fiscalMonth) => {
	const propName = getDateProp(journal);
	return moment(journal[propName]).month() === fiscalMonth - 1;
};

const renderJournalType = (val) => {
	switch (val) {
		case JOURNAL_TYPE.EXISTING_DEPRECIATION:
			return 'Existing Depreciation';
		case JOURNAL_TYPE.IMPAIRMENT:
			return 'Impairment';
		case JOURNAL_TYPE.DEPRECIATION:
			return 'Depreciation';
		default:
			return 'Projected';
	}
};

const AssetDepreciationModelsPage: FC<any> = ({
	match,
	asset,
	currentUser,
	fetchDepreciationClass,
	fetchAssetNBV,
	fetchJournalHistories,
	companyConfig,
}) => {
	const assetId = useMemo(() => nullSafeGet('params.id', match), [match]);
	const [journalHistories, setJournalHistories] = useState([]);

	const fiscal = useMemo(
		() => nullSafeGetOrElse('detail.inventoryConfig.fiscalDate', companyConfig, defaultFiscalEnd),
		[companyConfig]
	);

	const [editModalVisible, setEditModalVisible] = useState(false);
	const [parameters, setParameters] = useState<any>({
		depreciationMethod: DEPRECIATION_METHODS.DECLINING,
		depreciationRate: 5,
		tillDate: defaultProjectDate,
	});

	const [loading, setLoading] = useState(false);
	const [currentNBV, setCurrentNBV] = useState(0);
	const [fiscalNBV, setFiscalNBV] = useState(0);

	const capitalisedCost = useMemo(() => nullSafeGet('capitalisedCost', asset), [asset]);

	const installDate = useMemo(() => nullSafeGet('installDate', asset), [asset]);

	const depreciationClassId = useMemo(
		() => nullSafeGet('assetType.depreciationClassId', asset),
		[asset]
	);

	const refreshDepreciationClass = useCallback(() => {
		return new Promise((resolve) =>
			depreciationClassId
				? fetchDepreciationClass(depreciationClassId)
						.then(
							(res) =>
								res &&
								setParameters({
									...res,
									tillDate: defaultProjectDate,
								})
						)
						.finally(() => resolve(true))
				: resolve(true)
		);
	}, [depreciationClassId, fetchDepreciationClass]);

	const refreshNBV = useCallback(() => {
		return new Promise((resolve) =>
			fetchAssetNBV({ assetId })
				.then((res) => setCurrentNBV(res && res.length > 0 ? res[0].netBookValue : capitalisedCost))
				.finally(() => resolve(true))
		);
	}, [assetId, capitalisedCost, fetchAssetNBV]);

	const refreshJournalHistories = useCallback(() => {
		return new Promise((resolve) =>
			fetchJournalHistories({ assetId })
				.then((res) =>
					setJournalHistories(addNBVs(asset.capitalisedCost, res, asset.existingDepreciation))
				)
				.finally(() => resolve(true))
		);
	}, [asset, assetId, fetchJournalHistories]);

	useEffect(() => {
		const fiscalMonthEntries = journalHistories.filter((_) =>
			isFiscalStartMonthJournal(_, fiscal.month)
		);
		const journalHistoryWithoutExistingDepreciation = journalHistories.filter(
			(_) => _.entityType !== JOURNAL_TYPE.EXISTING_DEPRECIATION
		);
		fiscalMonthEntries.length > 0
			? setFiscalNBV(nullSafeGet('0.initialValue', fiscalMonthEntries))
			: journalHistoryWithoutExistingDepreciation.length > 0
			? setFiscalNBV(
					nullSafeGet(
						`${journalHistoryWithoutExistingDepreciation.length - 1}.initialValue`,
						journalHistoryWithoutExistingDepreciation
					)
			  )
			: setFiscalNBV(currentNBV);
	}, [currentNBV, fiscal, journalHistories]);

	const refreshValues = useCallback(() => {
		setLoading(true);
		Promise.all([refreshNBV(), refreshDepreciationClass(), refreshJournalHistories()]).finally(() =>
			setLoading(false)
		);
	}, [refreshDepreciationClass, refreshJournalHistories, refreshNBV]);

	useEffect(() => {
		refreshValues();
	}, [refreshValues]);

	const currency = useMemo(() => getCurrency({ currentUser }), [currentUser]);

	const depreciationConfig = useMemo(
		() => ({
			xAxisName: 'date',
			yAxisName: 'nvb',
			position: 'date*nbv',
			fillArea: false,
			lineColor: 'white',
			cols: {
				date: { alias: 'Month' },
				nbv: { alias: 'Book Value' },
			},
			yAxisLabel: {
				formatter: (val) => currency.format(val),
				textStyle: {
					fontSize: '12',
					fontFamily: 'Roboto',
					fill: 'rgba(255, 255, 255, 0.85)',
				},
			},
			xAxisLabel: {
				textStyle: {
					fontSize: '12',
					fontFamily: 'Roboto',
					fill: 'rgba(255, 255, 255, 0.85)',
				},
			},
		}),
		[currency]
	);

	const renderCurrency = useCallback((value) => currency.format(value), [currency]);

	const showEditModal = useCallback(() => setEditModalVisible(true), []);
	const hideEditModal = useCallback(() => setEditModalVisible(false), []);

	const onEditParameters = useCallback(
		(values) => {
			hideEditModal();
			setParameters(values);
		},
		[hideEditModal]
	);

	const getValueForKey = useCallback(
		(key, method) => (parameters.depreciationMethod === method ? `${parameters[key]}` : undefined),
		[parameters]
	);

	const values = useMemo(() => {
		switch (parameters.depreciationMethod) {
			case DEPRECIATION_METHODS.DECLINING:
				return getDecliningBalanceProjection({
					capitalisedCost,
					nbv: fiscalNBV,
					tillDate: parameters.tillDate.format(),
					depreciationRate: parameters.depreciationRate,
					fiscal,
				});
			default:
				return getStraightLineProjection({
					installDate,
					salvageValue: parameters.salvageValue,
					capitalisedCost,
					nbv: fiscalNBV,
					tillDate: parameters.tillDate.format(),
					usefulLifeInMonths: parameters.usefulLifeInMonths,
				});
		}
	}, [capitalisedCost, fiscalNBV, installDate, parameters, fiscal]);

	const valuesWithJournalHistory = useMemo(
		() => [
			...values,
			...journalHistories.map((_) => {
				const propName = getDateProp(_.entityType);
				const date = nullSafeGet(propName, _);
				return {
					..._,
					depreciation: getJournalValue(_),
					date:
						_.entityType === JOURNAL_TYPE.EXISTING_DEPRECIATION
							? '--'
							: dateFormatter(date, DATE_ONLY_FORMAT),
					nbv: _.netBookValue,
				};
			}),
		],
		[journalHistories, values]
	);

	const latestValue = useMemo(
		() => (nullSafeGetOrElse('length', values, 0) > 0 ? values[0].nbv : 0),
		[values]
	);

	const paramterDetails = useMemo(
		() => ({
			'Capitalised Cost': renderCurrency(capitalisedCost),
			'Current Net Book Value': renderCurrency(currentNBV),
			'Depreciation Method':
				parameters.depreciationMethod === DEPRECIATION_METHODS.DECLINING
					? 'Declining Balance'
					: 'Straight Line',
			'Projected until': (
				<DateTimeHover
					timestamp={nullSafeGetOrElse('tillDate', parameters, moment())}
					showTime={false}
				/>
			),
			...(getValueForKey('depreciationRate', DEPRECIATION_METHODS.DECLINING) && {
				'Depreciation Rate': `${getValueForKey(
					'depreciationRate',
					DEPRECIATION_METHODS.DECLINING
				)}%`,
			}),
			...(getValueForKey('usefulLifeInMonths', DEPRECIATION_METHODS.STRAIGHT_LINE) && {
				'Useful Life In Months': getValueForKey(
					'usefulLifeInMonths',
					DEPRECIATION_METHODS.STRAIGHT_LINE
				),
			}),
			...(getValueForKey('salvageValue', DEPRECIATION_METHODS.STRAIGHT_LINE) && {
				'Salvage Value': renderCurrency(
					getValueForKey('salvageValue', DEPRECIATION_METHODS.STRAIGHT_LINE)
				),
			}),
			'Projected Net book Value': renderCurrency(latestValue),
		}),
		[capitalisedCost, currentNBV, getValueForKey, latestValue, parameters, renderCurrency]
	);

	const csvTitle = useMemo(() => `${asset.name} - Projected Depreciation`, [asset.name]);

	const columns = useMemo(
		() => [
			{
				title: 'Month',
				dataIndex: 'date',
			},
			{
				title: 'Type',
				dataIndex: 'entityType',
				render: renderJournalType,
			},
			{
				title: 'Depreciation',
				dataIndex: 'depreciation',
				render: renderCurrency,
			},
			{
				title: 'Projected Net book value',
				dataIndex: 'nbv',
				render: renderCurrency,
			},
		],
		[renderCurrency]
	);

	const onExportDepreciation = useCallback(() => {
		const csvExporter = new ExportToCsv({
			...CSV_EXPORT_DEFAULTS,
			filename: csvTitle,
			title: csvTitle,
			headers: columns.map((_) => _.title).filter((_) => !!_),
		});
		csvExporter.generateCsv(
			values.map((row) => ({
				date: row.date,
				type: renderJournalType(row.entityType),
				depreciation: currency.format(row.depreciation),
				nbv: currency.format(row.nbv),
			}))
		);
	}, [csvTitle, columns, values, currency]);

	return (
		<div className="m-6">
			<Card
				loading={loading}
				title="Projection Details"
				extra={
					<Button icon={<EditOutlined translate="" />} type="ghost" onClick={showEditModal}>
						Edit Parameters
					</Button>
				}
			>
				<HorizontalKeyValueDisplay rowStyle={{ minWidth: '25%' }} keyValueStore={paramterDetails} />
			</Card>
			<div className="mt-4">
				<Card loading={loading} className="materialCard" bodyStyle={{ padding: 0 }}>
					<DarkLineChart
						style={{ padding: '24px 16px' }}
						height={180}
						color={nullSafeGet('lineColor', depreciationConfig)}
						data={[...values].reverse()}
						xAxisName={nullSafeGet('xAxisName', depreciationConfig)}
						yAxisName={nullSafeGet('yAxisName', depreciationConfig)}
						yAxisLabel={nullSafeGet('yAxisLabel', depreciationConfig)}
						xAxisLabel={nullSafeGet('xAxisLabel', depreciationConfig)}
						fillArea={nullSafeGet('fillArea', depreciationConfig)}
						cols={nullSafeGet('cols', depreciationConfig)}
						position={nullSafeGet('position', depreciationConfig)}
					/>
				</Card>
			</div>
			<div className="mt-4">
				<Card
					loading={loading}
					title="Projected Depreciation"
					extra={
						<Button icon={<ExportOutlined translate="" />} onClick={onExportDepreciation}>
							Export Projected depreciation
						</Button>
					}
				>
					<Table
						dataSource={valuesWithJournalHistory}
						showHeader
						pagination={false}
						columns={columns}
					/>
				</Card>
			</div>

			{editModalVisible && (
				<DepreciationModelsPlaygroundModal
					depreciationClass={parameters}
					onCancel={hideEditModal}
					handleSubmit={onEditParameters}
				/>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	asset: state.assets.detail,
	currentUser: state.session.currentUser,
	companyConfig: state.company_config,
});

const mapDispatchToProps = (dispatch) => ({
	fetchDepreciationClass: (id) =>
		dispatch(depreciationClassesRestCrudThunksForSupplier.readOne(id)),
	fetchAssetNBV: (params) => dispatch(assetNbvsRestCrudThunksForSupplier.read(params)),
	fetchJournalHistories: (params) =>
		dispatch(journalHistoriesRestCrudThunksForSupplier.read(params)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(AssetDepreciationModelsPage)
);

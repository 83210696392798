import { Button, Row } from 'antd';
import React, { useState } from 'react';

const ELEMENTSTOSHOW = 8;
export default function LocationandSupplierEditCard({ data = {}, type, label }) {
	const typeData = data[type];
	const [expanded, setexpanded] = useState(false);
	const locationData = (expanded && typeData) || typeData.slice(0, ELEMENTSTOSHOW);
	if (locationData && !locationData.length) {
		locationData.push(`All ${label}`);
	}

	return (
		<div>
			{(locationData.length && (
				<div
					style={{
						marginTop: 8,
						display: 'grid',
						rowGap: 8,
						gridTemplateColumns: 'auto auto auto',
					}}
				>
					{locationData.map((item) => (
						<div className="keyValueTable__value" style={{ marginRight: 12 }}>
							{item}
						</div>
					))}
				</div>
			)) ||
				null}
			{typeData.length > ELEMENTSTOSHOW && (
				<Row>
					<Button
						style={{ marginTop: 8, marginLeft: 0, paddingLeft: 0 }}
						type="link"
						onClick={() => setexpanded(!expanded)}
					>
						{expanded ? 'See Less' : 'See More'}
					</Button>
				</Row>
			)}
		</div>
	);
}

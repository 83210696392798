import React, { FC } from 'react';
import { Popover, Radio } from 'antd';
import RadioGroupInfo from './RadioGroupInfo';

interface IOWRadioGroup {
	items: any[];
	value?: any;
	valueAccessor: (x: any) => any;
	onChange?: (val: any) => void;
	renderRecord: (x: any) => any;
	info?: any;
	disabled?: boolean;
	className?: any;
}

const OWRadioGroup: FC<IOWRadioGroup> = ({
	items,
	value,
	valueAccessor,
	onChange,
	renderRecord,
	info,
	disabled,
	className,
}): React.ReactElement => {
	return (
		<div className={`${className ? className : ''} flex flex-row items-center`}>
			<Radio.Group disabled={disabled} onChange={(e) => onChange(e.target.value)} value={value}>
				{items.map((item, idx) => (
					<Popover
						content={item.disabled && item.disabledMessage ? item.disabledMessage : ''}
						trigger="hover"
						key={`radio-${valueAccessor(item)}-${idx}`}
					>
						<Radio.Button
							style={{ flexWrap: 'nowrap', height: 40, lineHeight: '40px' }}
							value={valueAccessor(item)}
							disabled={item.disabled}
							key={idx}
						>
							{renderRecord(item)}
						</Radio.Button>
					</Popover>
				))}
			</Radio.Group>
			{info && <RadioGroupInfo menus={info} />}
		</div>
	);
};

export default OWRadioGroup;

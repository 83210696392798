import React, { FC, useCallback, useMemo, useState } from 'react';
import { equipmentPerStockLocationsRestCrudThunksForSupplier } from '../../thunks/equipment_per_stock_locations_thunks';
import EquipmentPerStockLocationRowDisplay from '../equipment_per_stock_location_row_display/EquipmentPerStockLocationRowDisplay';
import { EquipmentRentalRowDisplay } from '../equipment_rental_row_display/EquipmentRentalRowDisplay';
import { Button, Select } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import QRCode from 'qrcode.react';
import { EmptyState } from '../empty_state/EmptyState';
import { PlusOutlined } from '@ant-design/icons';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import StockLocationAddEquipmentForm from '../stock_location_add_equipment_form/StockLocationAddEquipmentForm';
import { EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS } from '../../actions/equipment_per_stock_locations_actions';
import { fetchWithDifferntSearchName, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { assetTypesRestCrudThunksForSupplier } from '../../thunks/asset_types_thunks';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import { equipmentTypesRestCrudThunksForSupplier } from '../../thunks/equipment_types_thunks';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import {
	DEFAULT_NAME_SORTER,
	FILTER_CONFIG_NAMES,
	FILTER_FIELD_TYPE,
} from '../../utils/DataConstants';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import { renderEquipmenTypeSelect } from '../purchase_order_form/ReplaceEquipmentLineItemModal';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';

const EPSL_TC = 'stockLocationAssociatedEquipments';
const EPSL_ADVANCED_FILTERS_TC = 'epslAdvancedFilters';

const EquipmentsIndexPage: FC<any> = ({
	history,
	location,
	fetchEquipmentsPerStockLocation,
	equipmentsPerStockLocation,
	updateEquipmentsPerStocklocationFilters,
	clearAndUpdateFilters,
	assetTypes,
	stockLocations,
	equipmentTypes,
	fetchStockLocations,
	fetchMultipleStockLocations,
	fetchAssetTypes,
	fetchMultipleAssetTypes,
	fetchEquipmentTypes,
	fetchMultipleEquipments,
}): React.ReactElement => {
	const [addEquipmentFormVisible, setAddEquipmentFormVisible] = useState(false);

	const getLink = useCallback(
		(link) => getLinkWIthBackLinkParams(location, 'Back to Equipment', link),
		[location]
	);

	const equipmentsColumns = useMemo(
		() => [
			{
				title: 'Equipment',
				dataIndex: 'name',
				render: (_, record) => (
					<EquipmentPerStockLocationRowDisplay equipmentPerStockLocation={record} />
				),
				key: 'name',
				sorter: true,
			},
			{
				title: 'Stock Location',
				dataIndex: 'stockLocation',
				key: 'stockLocationName',
				sorter: true,
				render: (sl) => nullSafeGetOrElse('name', sl, '--'),
			},
			{
				dataIndex: 'isRental',
				render: (text) => <EquipmentRentalRowDisplay isRental={text} />,
			},
			{
				dataIndex: 'equipmentUUID',
				render: (text) => (text ? <QRCode size={64} value={text} /> : null),
			},
		],
		[]
	);

	const onEquipmentRow = useCallback(
		(record) => ({
			onClick: () =>
				history.push(getLink(`/supplier/equipments/equipmentPerStockLocation/detail/${record.id}`)),
		}),
		[getLink, history]
	);

	const getStatSliceProp = useCallback(
		(propName) => nullSafeGetOrElse(`${EPSL_TC}.${propName}`, equipmentsPerStockLocation, {}),
		[equipmentsPerStockLocation]
	);

	const refreshEquipmentsPerStockLocation = useCallback(() => {
		fetchEquipmentsPerStockLocation(
			{},
			EPSL_TC,
			getStatSliceProp('pagination'),
			getStatSliceProp('sorting'),
			getStatSliceProp('filters')
		);
	}, [fetchEquipmentsPerStockLocation, getStatSliceProp]);

	const onAddEquipment = useCallback(() => setAddEquipmentFormVisible(true), []);

	const hideAddEquipment = useCallback(() => setAddEquipmentFormVisible(false), []);

	const onAddEquipmentSuccess = useCallback(() => {
		hideAddEquipment();
		refreshEquipmentsPerStockLocation();
	}, [hideAddEquipment, refreshEquipmentsPerStockLocation]);

	const filterConfig = useMemo(
		() => [
			{
				label: 'Stock Location',
				fieldName: 'stockLocationIds',
				mode: 'multiple',
				stateSlice: stockLocations,
				targetCollectionName: EPSL_ADVANCED_FILTERS_TC,
				fetch: fetchStockLocations,
				fetchMultiple: fetchMultipleStockLocations,
			},
			{
				label: 'Equipment Type',
				mode: 'multiple',
				fieldName: 'equipmentTypeIds',
				stateSlice: equipmentTypes,
				targetCollectionName: EPSL_ADVANCED_FILTERS_TC,
				fetch: fetchWithDifferntSearchName(fetchEquipmentTypes, 'search'),
				fetchMultiple: fetchMultipleEquipments,
				renderRecord: renderEquipmenTypeSelect,
				component: OWAsyncSelect,
			},
			{
				label: 'Asset Type',
				fieldName: FILTER_CONFIG_NAMES.ASSET_TYPE,
				mode: 'multiple',
				stateSlice: assetTypes,
				targetCollectionName: EPSL_ADVANCED_FILTERS_TC,
				fetch: fetchAssetTypes,
				fetchMultiple: fetchMultipleAssetTypes,
			},
			{
				fieldName: 'isRental',
				type: FILTER_FIELD_TYPE.RADIO_GROUP,
				items: [
					{
						label: 'All',
						value: 'undefined',
					},
					{
						label: 'Rented',
						value: 'true',
					},
				],
				defaultValue: undefined,
			},
			{
				fieldName: 'isDisbursed',
				type: FILTER_FIELD_TYPE.RADIO_GROUP,
				items: [
					{
						label: 'All',
						value: 'undefined',
					},
					{
						label: 'Not Disbursed',
						value: 'false',
					},
					{
						label: 'Disbursed',
						value: 'true',
					},
				],
				defaultValue: undefined,
			},
		],
		[
			assetTypes,
			equipmentTypes,
			fetchAssetTypes,
			fetchEquipmentTypes,
			fetchMultipleAssetTypes,
			fetchMultipleEquipments,
			fetchMultipleStockLocations,
			fetchStockLocations,
			stockLocations,
		]
	);

	return (
		<>
			<PaginatedReduxTableWithHeader
				targetCollectionName={EPSL_TC}
				updateFilters={updateEquipmentsPerStocklocationFilters}
				stateSlice={equipmentsPerStockLocation}
				clearAndUpdateFilters={clearAndUpdateFilters}
				filterConfig={filterConfig}
				entityCollectionName="equipment_per_stock_locations"
				tableColumns={equipmentsColumns}
				onTableRow={onEquipmentRow}
				fetchData={equipmentPerStockLocationsRestCrudThunksForSupplier.read}
				initialSorters={DEFAULT_NAME_SORTER}
				showHeader
				rightActions={
					<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PARTS_AND_EQUIPMENTS}>
						<div className="flex-start flex flex-row">
							<Button
								className="ml-2"
								size="large"
								icon={<PlusOutlined translate="" />}
								type="primary"
								onClick={onAddEquipment}
							>
								Add Equipment
							</Button>
						</div>
					</AccessPermissionChecker>
				}
				emptyState={
					<EmptyState
						graphic={
							<img
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
								alt="The tool shed is looking pretty empty."
							/>
						}
						headline={'The tool shed is looking pretty empty.'}
						body={
							<div style={{ textAlign: 'center' }}>
								<div style={{ maxWidth: 440, marginBottom: 16 }}>
									You haven't added any equipment to this location yet.
								</div>
							</div>
						}
					/>
				}
			/>
			{addEquipmentFormVisible && (
				<StockLocationAddEquipmentForm
					showStockLocation
					onCancel={hideAddEquipment}
					onSuccess={onAddEquipmentSuccess}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	location: ownProps.location,
	assetTypes: state.asset_types,
	stockLocations: state.stock_locations,
	equipmentTypes: state.equipment_types,
	equipmentsPerStockLocation: state.equipment_per_stock_locations,
});

const mapDispatchToProps = (dispatch) => ({
	fetchEquipmentsPerStockLocation: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			equipmentPerStockLocationsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	updateEquipmentsPerStocklocationFilters: (filters, targetCollection) =>
		dispatch(
			EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)
		),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			EQUIPMENT_PER_STOCK_LOCATIONS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(
				filters,
				targetCollectionName
			)
		),
	fetchMultipleAssetTypes: (ids, targetCollectionName) =>
		dispatch(assetTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchAssetTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			assetTypesRestCrudThunksForSupplier.readLite(
				{ ...params, no_pagination: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleStockLocations: (ids, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchStockLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			stockLocationsRestCrudThunksForSupplier.readLite(
				{ ...params, no_pagination: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleEquipments: (ids, targetCollectionName) =>
		dispatch(equipmentTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchEquipmentTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			equipmentTypesRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EquipmentsIndexPage));

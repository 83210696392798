import * as React from 'react';

import { connect } from 'react-redux';
import { Layout, Row, Col, BackTop, Button, Card } from 'antd';
import { Link } from 'react-router-dom';
import Result from 'ant-design-pro/lib/Result';
import { checkUserTypeToken, signUpCompletion } from '../../thunks/session_thunks';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { accountActivationSuccess } from '../../actions/session_actions';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { ROLE_TYPES } from '../../utils/DataConstants';

const { Content } = Layout;
const style = require('./SupplierSignupCompletionPage.less');

class SupplierSignupCompletionPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			signupCompletionSuccessful: false,
		};
	}

	componentDidMount() {
		const { supplierSignUpCompletion, accountActivationCompletion, match, refreshCurrentUser } =
			this.props;
		supplierSignUpCompletion()
			.then((t) => {
				this.setState({ signupCompletionSuccessful: true });
				refreshCurrentUser().then(() => {
					accountActivationCompletion();
				});
			})
			.catch((r) => {
				this.setState({ signupCompletionSuccessful: false });
			});
	}

	render() {
		const { signupCompletionSuccessful } = this.state;
		const { loading } = this.props;
		return loading ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="signUpCompletionPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited supplier signup completion page" />
				<BackTop />
				<Row align="middle" style={{ height: '100vh', margin: '0 -8px' }} gutter={16}>
					<Col span={12} offset={6}>
						<Card>
							{signupCompletionSuccessful ? (
								<Result
									type="success"
									title={<div>You're all set.</div>}
									description={
										<div>
											<p className="signupForm__successExplanation">
												Thanks for verifying your email address. <br />
												Your account has new superpowers.
											</p>
										</div>
									}
									actions={
										<div style={{ marginBottom: 24 }}>
											<Link to="/supplier/bids/overview">
												<Button type="primary">Back home</Button>
											</Link>
										</div>
									}
								/>
							) : (
								<Result
									type="error"
									title={<div>Something's gone awry.</div>}
									description={
										<div>
											<p className="signupForm__successExplanation">
												Please contact your company's admin or reach out at support@emptor.ai to
												activate your account.
											</p>
										</div>
									}
									actions={
										<div style={{ marginBottom: 24 }}>
											<Link to="/supplier/workOrders">
												<Button type="primary">Back home</Button>
											</Link>
										</div>
									}
								/>
							)}
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	loading: state.session.loading,
	match: ownProps.match,
});

const mapDispatchToProps = (dispatch) => ({
	supplierSignUpCompletion: () => dispatch(signUpCompletion('supplier')()),
	accountActivationCompletion: () => dispatch(accountActivationSuccess()),
	refreshCurrentUser: () => dispatch(checkUserTypeToken(ROLE_TYPES.SUPPLIER)()),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(SupplierSignupCompletionPage)
);

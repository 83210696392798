import React, { FC } from 'react';
import { nullSafeGet } from '../../utils/DataAccessUtils';

const LocationNameWithIconDisplay: FC<any> = ({ location }): React.ReactElement => {
	return (
		<div
			style={{
				backgroundColor: '#f5f5f5',
				padding: '4px 12px',
				borderRadius: '16px',
				color: 'rgba(0, 0, 0, 0.65)',
			}}
		>
			<i className="icons8-font icons8-location-marker" />
			<span>{nullSafeGet('name', location)}</span>
		</div>
	);
};

export default LocationNameWithIconDisplay;

import * as React from 'react';

import '@ant-design/compatible/assets/index.css';
import { Modal, Select, Form, Row, Col, Empty } from 'antd';
import { connect } from 'react-redux';
import {
	supplierFacilitiesRestCrudThunksForBuyer,
	supplierFacilitiesRestCrudThunksForSupplier,
} from '../../thunks/supplier_facilities_thunks';
import {
	spendCategoriesRestCrudThunksForBuyer,
	spendCategoriesRestCrudThunksForSupplier,
} from '../../thunks/spend_categories_thunks';
import {
	fetchLocationsWithRegionsForBuyer,
	fetchLocationsWithRegionsForSupplier,
	locationsRestCrudThunksForBuyer,
	locationsRestCrudThunksForSupplier,
} from '../../thunks/locations_thunks';
import {
	problemTypesHeirarchicalForBuyer,
	problemTypesHeirarchicalForSupplier,
	problemTypesRestCrudThunksForBuyer,
	problemTypesRestCrudThunksForSupplier,
} from '../../thunks/problem_types_thunks';
import {
	regionsRestCrudThunksForBuyer,
	regionsRestCrudThunksForSupplier,
} from '../../thunks/regions_thunks';

import { LocationDropdownRecord } from '../name_component/location/LocationDropdownRecord';
import { useState } from 'react';
import {
	copyRankForSuppliersForBuyer,
	copyRankForSuppliersForSupplier,
	getSupplierPrivateNetworksForLocationForBuyer,
	getSupplierPrivateNetworksForLocationForSupplier,
} from '../../thunks/supplier_private_networks_thunks';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { withRouter } from 'react-router';
import { ROLE_TYPES } from '../../utils/DataConstants';

const FormItem = Form.Item;

function CopyRankingComponent(props) {
	const [form] = Form.useForm();
	const {
		selectedLocation,
		locations,
		visible,
		onCancel,
		copyRankings,
		fetchLocations,
		onSuccess,
		problemType = {},
		fetchSupplierPrivateNetworksForLocation,
	} = props;
	const onSubmit = (values) => {
		setConfirmLoading(true);
		copyRankings({
			fromLocation: values.locationId,
			toLocation: selectedLocation.id,
			problemType: problemType.id,
		})
			.then(onSuccess)
			.finally(() => {
				setConfirmLoading(false);
			});
	};

	const [confirmLoading, setConfirmLoading] = useState(false);
	if (!visible) return null;
	return (
		<Modal
			visible={visible}
			width={600}
			okText="Submit"
			onCancel={onCancel}
			onOk={() => {
				form.validateFields(['locationId']).then(() => {
					if (problemType.hierarchyName) {
						setConfirmLoading(true);
						fetchSupplierPrivateNetworksForLocation(form.getFieldValue('locationId'), {
							problemTypeId: problemType.id,
							offset: 0,
							limit: 10,
						}).then(({ data, count }) => {
							const { supplierPrivateNetworkSeq, problemType } = data[0] || {};
							const newRankings = JSON.parse(
								JSON.stringify(
									supplierPrivateNetworkSeq.sort((a, b) => {
										return a.rank - b.rank;
									})
								)
							);

							Modal.confirm({
								width: 1000,
								title: (
									<div>
										<span>
											Copy supplier assignments from
											<b> {locations.records[form.getFieldValue('locationId')].name}</b> to
											<b> {selectedLocation.name}</b>
											{problemType.hierarchyName ? (
												<span>
													{' '}
													for <i>{problemType.hierarchyName}</i>
												</span>
											) : (
												''
											)}
										</span>
									</div>
								),
								content: (
									<Row style={{ height: '50vh', overflow: 'scroll' }}>
										<Col span={24}>
											<div className=" my-4 text-base  font-semibold">
												New rankings would be as follows
											</div>
											<div className="PrivateNetworkLocationsPage__secondaryEntity__list__container">
												{(newRankings &&
													newRankings.length &&
													newRankings.map((item, index) => (
														<div className="flex  items-center justify-start p-4">
															<div className="flex basis-2/4 items-center">
																<div
																	title={nullSafeGet('supplierFacility.displayName', item)}
																	className=" PrivateNetworkLocationsPage__secondaryEntity__list__item__text text-ellipsis line-clamp-1"
																>
																	{nullSafeGet('supplierFacility.displayName', item)}
																</div>
															</div>
															<div className="ml-16 basis-2/4 opacity-50">{`Rank ${
																index + 1
															}`}</div>
														</div>
													))) || (
													<div className="py-4">
														<Empty
															image={Empty.PRESENTED_IMAGE_SIMPLE}
															description={
																'You haven’t assigned any service providers to this problem type yet'
															}
														/>
													</div>
												)}
											</div>
										</Col>
									</Row>
								),
								onOk() {
									form.submit();
								},
								onCancel() {
									setConfirmLoading(false);
								},
								okText: 'Confirm',
							});
						});
					} else {
						Modal.confirm({
							content: <span>This cannot be undone. Proceed with caution.</span>,
							title: (
								<div>
									<span>
										Copy supplier assignments from '
										<b>{locations.records[form.getFieldValue('locationId')].name}</b> to
										<b> {selectedLocation.name}</b>{' '}
									</span>
								</div>
							),
							onOk() {
								form.submit();
							},
							onCancel() {
								setConfirmLoading(false);
							},
							okText: 'Confirm',
						});
					}
				});
			}}
			confirmLoading={confirmLoading}
			title={
				<span>
					Copy supplier assignments
					{problemType.hierarchyName ? (
						<span>
							{' '}
							for <i>{problemType.hierarchyName}</i>
						</span>
					) : (
						''
					)}
				</span>
			}
			destroyOnClose
		>
			<Form
				form={form}
				layout="vertical"
				className="supplierForm"
				initialValues={{}}
				onFinish={onSubmit}
			>
				<div className="mb-6">
					<FormItem
						name="locationId"
						label="Select a location"
						help="Location from where the rankings will be copied over"
						required
						rules={[{ required: true, message: 'Please select a location' }]}
					>
						<OWAsyncSelect
							emptyWhenDisabled={false}
							stateSlice={locations}
							targetCollectionName="SUPPLIER_PRIVATE_NETWORK_FORM_LOCATIONS_AUTOCOMPLETE"
							fetchMultiple={(ids, targetCollectionName) => {}}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchLocations(
									{ name: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							renderRecord={(record) => (
								<Select.Option
									disabled={record.id === selectedLocation.id}
									key={record.id}
									value={record.id}
								>
									<LocationDropdownRecord record={record} />
								</Select.Option>
							)}
							sortBy={{ sort_by: 'name', order: 'ascend' }}
						/>
					</FormItem>
				</div>
			</Form>
		</Modal>
	);
}

const mapStateToProps = (state) => ({
	supplierFacilitiesFetching: state.supplier_facilities.fetching,
	spendCategories: state.spend_categories,
	problemTypes: state.problem_types,
	locations: state.locations,
	createErrors: state.supplier_private_networks.createErrors,
	updateErrors: state.supplier_private_networks.updateErrors,
	creating: state.supplier_private_networks.creating,
	updating: state.supplier_private_networks.updating,
	supplierPrivateNetworkFetching: state.supplier_private_networks.fetching,
	supplierPrivateNetworkByFacilityFetching:
		state.supplier_private_networks.supplierPrivateNetworkBySupplierFacility.fetching,
	supplierPrivateNetworkByFacilityRecords:
		state.supplier_private_networks.supplierPrivateNetworkBySupplierFacility.records,

	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getSupplierFacility: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? supplierFacilitiesRestCrudThunksForSupplier.readOne(id)
				: supplierFacilitiesRestCrudThunksForBuyer.readOne(id)
		),
	fetchSpendCategories: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? spendCategoriesRestCrudThunksForSupplier.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: spendCategoriesRestCrudThunksForBuyer.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultipleSpendCategories: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? spendCategoriesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: spendCategoriesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchProblemTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? problemTypesHeirarchicalForSupplier(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: problemTypesHeirarchicalForBuyer(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultipleProblemTypes: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? problemTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: problemTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: locationsRestCrudThunksForBuyer.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: locationsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchLocationsWithHierarchy: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? fetchLocationsWithRegionsForSupplier(
						{ ...params, noIsActiveFilter: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: fetchLocationsWithRegionsForBuyer(
						{ ...params, noIsActiveFilter: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultipleRegions: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? regionsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: regionsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	copyRankings: ({ fromLocation, toLocation, problemType }) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? copyRankForSuppliersForSupplier({ fromLocation, toLocation, problemType })
				: copyRankForSuppliersForBuyer({ fromLocation, toLocation, problemType })
		),
	fetchSupplierPrivateNetworksForLocation: (locationId, params) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? getSupplierPrivateNetworksForLocationForSupplier(locationId, params)
				: getSupplierPrivateNetworksForLocationForBuyer(locationId, params)
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(CopyRankingComponent)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import * as React from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Alert, Button, Upload, Select, message } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import { supplierFacilitiesRestCrudThunksForSupplier } from '../../thunks/supplier_facilities_thunks';
import { getObjectValues, nullSafeGet } from '../../utils/DataAccessUtils';
import { spendCategoriesRestCrudThunksForSupplier } from '../../thunks/spend_categories_thunks';
import { retrieveCachedUserDetails } from '../../thunks/session_thunks';
import { imageUploadValidation } from '../../utils/ImageUtils';

const style = require('./EditSupplierCoreInfoForm.less');
const FormItem = Form.Item;
interface EditSupplierCoreInfoFormProps extends FormComponentProps {
	loading: boolean;
	displayName: string;
	logoURL: string;
	updateSupplier: any;
	successCallback: any;
	cancelCallback: any;
	supplierFacility: any;

	errors: string[];
}

class EditSupplierCoreInfoForm extends React.Component<EditSupplierCoreInfoFormProps, any> {
	state = {
		uploadLoading: false,
		logoURL: null,
	};

	componentDidMount() {
		const { logoURL } = this.props;
		this.setState({ logoURL: logoURL });
		this.props.form.setFieldsValue({ logoURL: logoURL });
	}

	handleUploadChange = (info) => {
		if (info.file.status === 'uploading') {
			this.setState({ uploadLoading: true });
			return;
		}
		if (info.file.status === 'done') {
			const companyLogoURL = nullSafeGet('file.response.data', info);
			this.props.form.setFieldsValue({ logoURL: companyLogoURL });
			this.setState({
				logoURL: companyLogoURL,
				uploadLoading: false,
			});
		}
	};

	handleSubmit = (e) => {
		const { updateSupplier, successCallback, supplierFacility, form } = this.props;
		e.preventDefault();
		form.validateFields((err, values) => {
			if (!err) {
				const updatedSupplier = {
					...supplierFacility,
					name: values.displayName,
					displayName: values.displayName,
					logoURL: values.logoURL,
				};
				updateSupplier(updatedSupplier).then((resp) => successCallback(resp));
			}
		});
	};

	render() {
		const { getFieldDecorator } = this.props.form;

		const { loading, errors, cancelCallback, displayName } = this.props;

		const { logoURL } = this.state;

		getFieldDecorator('logoURL', {
			initialValue: null,
			rules: [{ required: true, message: "Please upload your company's logo." }],
		});
		const BACKEND_URI = getBackendUri();
		const uploadHeaders = { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token };
		const uploadButton = (
			<div>
				<LegacyIcon type={this.state.uploadLoading ? 'loading' : 'plus'} />
				<div className="ant-upload-text">Upload</div>
			</div>
		);

		return (
			<Form layout="vertical" onSubmit={this.handleSubmit} className="editSupplierCoreInfoForm">
				{errors && errors.length > 0
					? errors.map((msg, idx) => (
							<FormItem key={idx}>
								<Alert message={msg} type="error" />
							</FormItem>
					  ))
					: null}
				<FormItem label="What name do your customers call your company?">
					{getFieldDecorator('displayName', {
						rules: [
							{
								required: true,
								message:
									"Please input your company's name as used in advertising and sales materials!",
							},
						],
						initialValue: displayName,
					})(<Input style={{ maxWidth: 500 }} />)}
				</FormItem>
				<FormItem
					label="Upload your company's logo. Square 300x300 pixel images look best."
					required={true}
				>
					<Upload
						name="file"
						listType="picture-card"
						className="avatar-uploader uploader_polyfill_supplierHero"
						showUploadList={false}
						headers={uploadHeaders}
						action={`${BACKEND_URI}/api/v1/supplier/file/public/company_logo/upload`}
						beforeUpload={imageUploadValidation}
						onChange={this.handleUploadChange}
					>
						{logoURL ? <img src={logoURL} style={{ maxHeight: 104 }} alt="" /> : uploadButton}
					</Upload>
				</FormItem>
				<FormItem>
					<Button type="primary" htmlType="submit" loading={loading}>
						Update supplier info
					</Button>
					<Button style={{ marginLeft: 16 }} onClick={cancelCallback}>
						Cancel
					</Button>
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	loading: state.supplier_facilities.loading,
	errors: state.supplier_facilities.updateErrors,
	spendCategories: state.spend_categories,
	description: ownProps.description,
	successCallback: ownProps.successCallback,
	cancelCallback: ownProps.cancelCallback,
	supplierFacility: ownProps.supplierFacility,
});

const mapDispatchToProps = (dispatch) => ({
	updateSupplier: (supplierFacility) =>
		dispatch(supplierFacilitiesRestCrudThunksForSupplier.update(supplierFacility)),
	fetchSpendCategories: (params, targetCollectionName) =>
		dispatch(spendCategoriesRestCrudThunksForSupplier.read(params, targetCollectionName)),
	fetchMultipleSpendCategories: (ids, targetCollectionName) =>
		dispatch(spendCategoriesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
});

export default withRouter<any, any>(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<EditSupplierCoreInfoFormProps>()(EditSupplierCoreInfoForm))
);

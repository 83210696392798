import React, { FC, useEffect, useMemo, useState } from 'react';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { EmptyState } from '../empty_state/EmptyState';
import HyperLink, { ENTITY_TYPE } from '../common/HyperLink';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { PURCHASE_ORDER_LINE_ITEMS_CRUD_ACTION_CREATORS } from '../../actions/purchase_order_line_items_actions';
import { purchaseOrderLineItemsRestCrudThunksForSupplier } from '../../thunks/purchase_order_line_items_thunks';
import PurchaseOrderStatusDisplay from '../purchase_order_status_display/PurchaseOrderStatusDisplay';
import { getPurchaseOrderId, nullSafeGet } from '../../utils/DataAccessUtils';
import { equipmentCatalogsRestCrudThunksForSupplier } from '../../thunks/equipment_catalogs_thunks';
import { Spin } from 'antd';

const TC_NAME = 'purchaseOrderLineItemsIndex';

const EquipmentCatalogPurchaseOrdersPage: FC<any> = ({
	updateFilters,
	clearAndUpdateFilters,
	purchaseOrderLineItems,
	match,
	fetchEquipmentCatalog,
	catalogLoading,
}): React.ReactElement => {
	const [equipmentCatalog, setEquipmentCatalog] = useState(null);

	useEffect(() => {
		fetchEquipmentCatalog(match.params.id).then((res) => setEquipmentCatalog(res));
	}, [fetchEquipmentCatalog, match.params.id]);

	const equipmentTypeId = useMemo(
		() => nullSafeGet('equipmentTypeId', equipmentCatalog),
		[equipmentCatalog]
	);
	const partEquipmentVendorId = useMemo(
		() => nullSafeGet('partEquipmentVendorId', equipmentCatalog),
		[equipmentCatalog]
	);

	const columns = useMemo(
		() => [
			{
				title: 'PO',
				dataIndex: 'supplierPurchaseOrder',
				render: (_) => (
					<HyperLink
						text={getPurchaseOrderId(_)}
						entityType={ENTITY_TYPE.PURCHASE_ORDER}
						entityId={nullSafeGet('id', _)}
						backLinkText="Back to Equipment"
					/>
				),
			},
			{
				title: 'Ordered Qty',
				dataIndex: 'equipmentQuantity',
				render: (_) => _ || 0,
			},
			{
				title: 'Received Qty',
				dataIndex: 'equipmentReceivedQuantity',
				render: (_) => _ || 0,
			},
			{
				title: 'Status',
				render: (_, record) => <PurchaseOrderStatusDisplay status={record.status} />,
			},
		],
		[]
	);

	return !equipmentTypeId || catalogLoading ? (
		<div className="flex flex-row justify-center">
			<Spin />
		</div>
	) : (
		<PaginatedReduxTableWithHeader
			targetCollectionName={TC_NAME}
			updateFilters={updateFilters}
			stateSlice={purchaseOrderLineItems}
			clearAndUpdateFilters={clearAndUpdateFilters}
			filterConfig={[]}
			entityCollectionName="purchase_order_line_items"
			tableColumns={columns}
			fetchData={purchaseOrderLineItemsRestCrudThunksForSupplier.read}
			rightActions={null}
			showHeader
			hideFilters
			preAppliedFilters={{ equipmentTypeId, partEquipmentVendorId }}
			emptyState={
				<EmptyState
					graphic={
						<img
							alt="No Data"
							style={{ marginBottom: 8 }}
							src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
						/>
					}
					headline={'No Purchase Orders found'}
					body={null}
				/>
			}
		/>
	);
};

const mapStateToProps = (state) => ({
	purchaseOrderLineItems: state.purchase_order_line_items,
	equipmentCatalog: state.equipment_catalogs.detail,
	catalogLoading: state.equipment_catalogs.fetching,
});

const mapDispatchToProps = (dispatch) => ({
	updateFilters: (filters, targetCollection) =>
		dispatch(
			PURCHASE_ORDER_LINE_ITEMS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)
		),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			PURCHASE_ORDER_LINE_ITEMS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(
				filters,
				targetCollectionName
			)
		),
	fetchEquipmentCatalog: (id) => dispatch(equipmentCatalogsRestCrudThunksForSupplier.readOne(id)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(EquipmentCatalogPurchaseOrdersPage)
);

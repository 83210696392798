import * as React from 'react';
import { Layout, Row, Col, Card, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import {
	getCurrency,
	isTextElementEmpty,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import { vendorsRestCrudThunksForSupplier } from '../../thunks/vendors_thunks';
import { KeyValueDisplay } from '../key_value_display/KeyValueDisplay';
import { ROLE_TYPES } from '../../utils/DataConstants';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { EmptyState } from '../empty_state/EmptyState';
import { EquipmentRowDisplay } from '../equipment_row_display/EquipmentRowDisplay';
import { AddressDisplay } from '../address_display/AddressDisplay';
import { ContactCardDisplay } from '../contact_card_display/ContactCardDisplay';
import { PartCatalogRowDisplay } from '../part_catalog_row_display/PartCatalogRowDisplay';
import { partCatalogsRestCrudThunksForSupplier } from '../../thunks/part_catalogs_thunks';
import { equipmentCatalogsRestCrudThunksForSupplier } from '../../thunks/equipment_catalogs_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import SupplierVendorInformationForm from '../supplier_vendor_information_form/SupplierVendorInformationForm';
import OWEditableNotesComponent from '../common/OWEditableNotesComponent';

const { Content } = Layout;

const VENDOR_ASSOCIATED_EQUIPMENTS_TARGET_COLLECTION =
	'VENDOR_ASSOCIATED_EQUIPMENTS_TARGET_COLLECTION';
const VENDOR_ASSOCIATED_PARTS_TARGET_COLLECTION = 'VENDOR_ASSOCIATED_PARTS_TARGET_COLLECTION';

class VendorsDetailPage extends React.Component<any, any> {
	componentDidMount() {
		const {
			getVendor,

			match,
		} = this.props;
		const vendorId = match.params.id;
		getVendor(vendorId);
	}

	updateFields = (values = {}) => {
		const { updateVendor, vendor } = this.props;

		const dataModel = {
			...vendor,
			...values,
		};
		updateVendor(dataModel)
			.then((data) => {
				this.setState({ data });
				message.success('Details updated succesfully');
			})
			.catch((err) => {
				message.error('Something went wrong, Please try again!');
			});
	};
	handleSaveNotes = (propName) => (notes) => {
		const { vendor, updateVendor } = this.props;
		const updatedVendor = {
			...vendor,
			[propName]: notes,
		};
		return updateVendor(updatedVendor);
	};

	render() {
		const { vendor, vendorsFetching, history, match, currentUser } = this.props;
		const currency = getCurrency({ currentUser });

		const vendorDetails = vendorsFetching
			? {}
			: {
					Currency: vendor.currencyId,
					Address: <AddressDisplay address={vendor.address} />,
					Email: vendor.email,
					'Vendor ID': vendor.vendorId || '__',
			  };

		const partsColumns = [
			{
				title: 'Part',
				dataIndex: 'title',
				render: (text, record) => <PartCatalogRowDisplay showPhoto={true} partCatalog={record} />,
			},
			{
				title: 'Price',
				dataIndex: 'unitCost',
				render: (price, record) => currency.format(price),
			},
		];
		const equipmentsColumns = [
			{
				title: 'Name',
				dataIndex: 'name',
				render: (text, record) => (
					<EquipmentRowDisplay equipmentType={nullSafeGet('equipmentType', record)} />
				),
			},
		];

		const onEquipmentRow = (record) => ({
			onClick: () => history.push(`/supplier/equipments/detail/${record.equipmentTypeId}`),
		});

		const onPartRow = (record) => ({
			onClick: () => history.push(`/supplier/parts/detail/${record.partId}`),
		});

		const vendorId = match.params.id;

		return vendorsFetching ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="vendorsDetailDetailsPage">
				<LogOnMountWithStandardEventProperties eventType="visited vendor detail page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '16px 4px' }} gutter={12}>
					<Col span={16}>
						<Row className="rowSpacing" gutter={16}>
							<Col span={24}>
								<Card>
									<SupplierVendorInformationForm
										vendorId={vendorId}
										data={vendor}
										onSubmit={this.updateFields}
									/>
								</Card>
							</Col>
						</Row>
						<div className="rowSpacing">
							<Card title="Parts">
								<PaginatedReduxTable
									showHeader={false}
									emptyState={
										<EmptyState
											graphic={
												<img
													style={{ marginBottom: 8 }}
													src="https://s3.amazonaws.com/mock-data-assets/categories/images/sunset.svg"
													alt="You haven't added any parts sold by this vendor yet."
												/>
											}
											headline={'Nothing much to see here.'}
											body={
												<div style={{ textAlign: 'center' }}>
													<div style={{ maxWidth: 440, marginBottom: 16 }}>
														You haven't added any parts sold by this vendor yet.
													</div>
												</div>
											}
										/>
									}
									collectionName="part_catalogs"
									targetCollectionName={VENDOR_ASSOCIATED_PARTS_TARGET_COLLECTION}
									columns={partsColumns}
									onRow={onPartRow}
									keyAccessor={(el) => el.id}
									initialFilters={{ partEquipmentVendorId: vendorId }}
									initialPagination={{ current: 1, pageSize: 10 }}
									fetchData={partCatalogsRestCrudThunksForSupplier.read}
								/>
							</Card>
						</div>

						<div className="rowSpacing">
							<Card title="Equipment">
								<PaginatedReduxTable
									showHeader={false}
									emptyState={
										<EmptyState
											graphic={
												<img
													style={{ marginBottom: 8 }}
													src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
													alt="You haven't added any equipment sold by this vendor yet."
												/>
											}
											headline={'The tool shed is looking pretty empty.'}
											body={
												<div style={{ textAlign: 'center' }}>
													<div style={{ maxWidth: 440, marginBottom: 16 }}>
														You haven't added any equipment sold by this vendor yet.
													</div>
												</div>
											}
										/>
									}
									collectionName="equipment_catalogs"
									targetCollectionName={VENDOR_ASSOCIATED_EQUIPMENTS_TARGET_COLLECTION}
									columns={equipmentsColumns}
									onRow={onEquipmentRow}
									keyAccessor={(el) => el.id}
									initialFilters={{ partEquipmentVendorId: vendorId }}
									initialPagination={{ current: 1, pageSize: 10 }}
									fetchData={equipmentCatalogsRestCrudThunksForSupplier.read}
								/>
							</Card>
						</div>
					</Col>
					<Col span={8}>
						<div className="rowSpacing">
							<Card bodyStyle={{ padding: 0 }}>
								<div className="materialCard__body">
									<div className="materialCard__supportingText">
										<KeyValueDisplay keyWidth={120} keyValueStore={vendorDetails} />
									</div>
								</div>
							</Card>
						</div>
						<div className="rowSpacing">
							<OWEditableNotesComponent
								id="notes"
								tooltipMessage="Notes"
								title="Notes"
								okTextSuffix={'Notes'}
								allowEdit={true}
								notes={nullSafeGetOrElse('notes', vendor, '')}
								onSaveNotes={this.handleSaveNotes('notes')}
								useRichText
							/>
						</div>

						<div className="rowSpacing">
							<OWEditableNotesComponent
								id="paymentTerms"
								tooltipMessage="Payment Terms"
								title="Payment Terms"
								okTextSuffix={'Payment Terms'}
								allowEdit={true}
								notes={nullSafeGetOrElse('paymentTerms', vendor, '')}
								onSaveNotes={this.handleSaveNotes('paymentTerms')}
								useRichText={false}
							/>
						</div>
						<div className="rowSpacing">
							<OWEditableNotesComponent
								id="shippingTerms"
								tooltipMessage="Shipping Terms"
								title="Shipping Terms"
								okTextSuffix={'Shipping Terms'}
								allowEdit={true}
								notes={nullSafeGetOrElse('shippingTerms', vendor, '')}
								onSaveNotes={this.handleSaveNotes('shippingTerms')}
								useRichText={false}
							/>
						</div>
						{nullSafeGet('primaryContact', vendor) ? (
							<div className="rowSpacing">
								<ContactCardDisplay
									roleType={ROLE_TYPES.SUPPLIER}
									title="Point of Contact"
									contact={vendor.primaryContact}
								/>
							</div>
						) : null}
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	vendorsFetching: state.vendors.fetching,
	vendor: state.vendors.detail,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	getVendor: (id) => dispatch(vendorsRestCrudThunksForSupplier.readOne(id)),
	updateVendor: (entity) => dispatch(vendorsRestCrudThunksForSupplier.update(entity)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(VendorsDetailPage)
);

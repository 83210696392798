import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Layout, Spin, Row, Col, Card, Table } from 'antd';
import { currencyFormatter, floatToPercentageString } from '../../utils/DataFormatterUtils';
import {
	getCurrency,
	collectAllParentsFromTreeData,
	collectObjectsWithValidPropFromTreeData,
	filterTreeData,
	flattenTreeData,
	nullSafeGet,
	nullSafeGetOrElse,
	removeEmptyChildrenFromTreeData,
	sortTreeData,
} from '../../utils/DataAccessUtils';
import { BarChart } from '../charts/BarChart';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { withRouter } from 'react-router';
import AnalyticsControlBar from '../analytics_control_bar/AnalyticsControlBar';
import { clearLocationFilters, updateLocationFilters } from '../../actions/spend_analytics_actions';
import {
	fetchSpendDataByLocationWithRegions,
	fetchSpendDataByMonth,
} from '../../thunks/spend_analytics_thunks';
import { EmptyState } from '../empty_state/EmptyState';
import { StackedAreaChart } from '../charts/StackedAreaChart';
import moment from 'moment';
import { subcategoriesDisplayNamesMapper } from '../../utils/AnalyticsUtils';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { CSV_EXPORT_DEFAULTS } from '../../utils/DataConstants';
import { ExportToCsv } from 'export-to-csv';

require('g2');
const DataSet = require('@antv/data-set');
require('./SpendAnalyticsOverviewLocationsPage.less');
const { Content } = Layout;

const spendByMonthConfigFunc = (currency) => ({
	xAxisName: 'yearMonthKey',
	yAxisName: 'monthlyTotal',
	position: 'yearMonthKey*monthlyTotal',
	fillArea: false,
	transformer: (data) => {
		const ds = new DataSet();
		const dv = ds.createView().source(data);
		dv.transform({
			type: 'fold',
			fields: [
				subcategoriesDisplayNamesMapper['laborMonthlyTotal'],
				subcategoriesDisplayNamesMapper['materialMonthlyTotal'],
				subcategoriesDisplayNamesMapper['freightMonthlyTotal'],
				subcategoriesDisplayNamesMapper['travelMonthlyTotal'],
				subcategoriesDisplayNamesMapper['miscMonthlyTotal'],
				subcategoriesDisplayNamesMapper['taxMonthlyTotal'],
			], // expand fields into separate records
			key: 'subcategory', // new key field
			value: 'monthlyTotal', // new value field
		});
		return dv;
	},
	color: 'subcategory',
	cols: {
		yearMonthKey: { alias: 'Month' },
		monthlyTotal: { alias: 'Spend' },
	},
	yAxisLabel: {
		formatter: (val) => (val > 0 ? currency.symbolFormat(val, 0) : currency.symbolFormat(0, 0)),
		textStyle: {
			fontSize: '12',
			fontFamily: 'Roboto',
			fill: 'rgba(0,0,0,0.85)',
		},
	},
	xAxisLabel: {
		formatter: (val) => moment(val, 'YYYY-MM').format('MMM YYYY'),
		textStyle: {
			fontSize: '12',
			fontFamily: 'Roboto',
			fill: 'rgba(0,0,0,0.85)',
		},
	},
});
const spendByLocationChartConfigFunc = (currency) => ({
	xAxisName: 'fieldName',
	yAxisName: 'subTotal',
	position: 'fieldName*subTotal',
	fillArea: false,
	color: 'fieldName',
	cols: {
		fieldName: { alias: 'Location' },
		subTotal: { alias: 'Spend' },
	},
	transformer: (data) => {
		const ds = new DataSet();
		const dv = ds.createView().source(data);
		dv.transform({
			type: 'sort-by',
			fields: ['subTotal'],
			order: 'ASC', // default is ASC,DESC is reversed order.
		});
		return dv;
	},
	yAxisLabel: {
		formatter: (val) => (val > 0 ? currency.symbolFormat(val, 0) : currency.symbolFormat(0, 0)),
		textStyle: {
			fontSize: '12',
			fontFamily: 'Roboto',
			fill: 'rgba(0,0,0,0.85)',
		},
	},
	xAxisLabel: {
		textStyle: {
			fontSize: '12',
			fontFamily: 'Roboto',
			fill: 'rgba(0,0,0,0.85)',
		},
	},
});

const defaultSortOrder: 'ascend' | 'descend' = 'descend';
const tooltipTemplate = `
								<li data-index={index}>
										<!-- The marker for each record -->
										<span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>
										{name}: \${value}
								</li>
		`;

const CSV_TITLE = 'Spend Analytics by Locations';

const exportReduceFunction = (obj) => ({
	name: obj.name,
	total: currencyFormatter(0, 'USD')(nullSafeGetOrElse('invoiceCosts.subTotal', obj, 0)),
	percentage: floatToPercentageString(
		nullSafeGetOrElse('invoiceCosts.percentageOfTotal', obj, 0) / 100
	),
});

const SpendAnalyticsOverviewLocationsPage: FC<any> = ({
	fetchSpendData,
	fetchTimeSeriesSpendData,
	analytics,
	saveLocationFilters,
	clearAllLocationFilters,
	accountActivated,
	currentUser,
}): React.ReactElement => {
	const [initialLoading, setInitialLoading] = useState(true);
	const [search, setSearch] = useState('');
	const [filteredTreeData, setFilteredTreeData] = useState([]);
	const [locationIds, setLocationIds] = useState([]);

	useEffect(() => {
		if (analytics.locationFilters.startDate && analytics.locationFilters.endDate) {
			const promise1 = fetchSpendData(analytics.locationFilters);
			const promise2 = fetchTimeSeriesSpendData(analytics.locationFilters);
			if (initialLoading) {
				Promise.all([promise1, promise2]).then(() => setInitialLoading(false));
			}
		}
	}, [analytics.locationFilters]);

	const filterByLocationIds = useCallback(
		(allData) => {
			if (locationIds.length > 0) {
				return filterTreeData(allData, (obj) => locationIds.includes(obj.id));
			} else {
				return allData;
			}
		},
		[locationIds]
	);

	const spendByLocationDisplayData = useMemo(() => {
		const spendByLocation = nullSafeGetOrElse('spendByLocation', analytics, []);

		const displayData = !!search
			? filteredTreeData
			: filterByLocationIds(nullSafeGetOrElse('data', spendByLocation, []));
		return sortTreeData(
			displayData,
			(a, b) =>
				parseFloat(nullSafeGetOrElse('invoiceCosts.subTotal', b, 0)) -
				parseFloat(nullSafeGetOrElse('invoiceCosts.subTotal', a, 0))
		);
	}, [analytics, filterByLocationIds, filteredTreeData, search]);

	const onDownloadAnalytics = useCallback(() => {
		const csvExporter = new ExportToCsv({
			...CSV_EXPORT_DEFAULTS,
			filename: CSV_TITLE,
			title: CSV_TITLE,
			headers: ['Location', 'Total Spent', 'Percentage'],
		});
		csvExporter.generateCsv(flattenTreeData(spendByLocationDisplayData, exportReduceFunction));
	}, [spendByLocationDisplayData]);

	const { spendByLocation, spendByMonth, locationFilters } = analytics;
	const currency = getCurrency({ currentUser });
	const spendByMonthData = useMemo(
		() =>
			spendByMonth.data &&
			spendByMonth.data.map((el) => ({
				...el,
				monthlyTotal: parseFloat(el.monthlyTotal),
				[subcategoriesDisplayNamesMapper['freightMonthlyTotal']]: parseFloat(
					el.freightMonthlyTotal
				),
				[subcategoriesDisplayNamesMapper['laborMonthlyTotal']]: parseFloat(el.laborMonthlyTotal),
				[subcategoriesDisplayNamesMapper['materialMonthlyTotal']]: parseFloat(
					el.materialMonthlyTotal
				),
				[subcategoriesDisplayNamesMapper['miscMonthlyTotal']]: parseFloat(el.miscMonthlyTotal),
				[subcategoriesDisplayNamesMapper['taxMonthlyTotal']]: parseFloat(el.taxMonthlyTotal),
				[subcategoriesDisplayNamesMapper['travelMonthlyTotal']]: parseFloat(el.travelMonthlyTotal),
			})),
		[spendByMonth]
	);

	const defaultSortOrder: 'ascend' | 'descend' = 'descend';
	const tooltipTemplate = `
				<li data-index={index}>
					<!-- The marker for each record -->
					<span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>
					{name}: {value}
				</li>
	`;

	const spendByLocationColumns = useMemo(
		() => [
			{
				title: 'Location',
				dataIndex: 'name',
				render: (text) => (
					<Ellipsis tooltip={true} style={{ display: 'inline' }} length={28}>
						{text}
					</Ellipsis>
				),
			},
			{
				title: `Total Spent (${currency.id})`,
				dataIndex: ['invoiceCosts', 'subTotal'],
				key: 'totalSpend',
				defaultSortOrder: defaultSortOrder,
				sorter: (a, b) => a.subTotal - b.subTotal,
				render: (amount) => currency.symbolFormat(amount, 0),
			},
			{
				title: '% of Total',
				dataIndex: ['invoiceCosts', 'percentageOfTotal'],
				key: 'percentageOfTotal',
				render: (num) => floatToPercentageString((num || 0) / 100),
				width: 100,
			},
		],
		[]
	);
	const spendByMonthConfig = spendByMonthConfigFunc(currency);
	const spendByLocationChartConfig = spendByLocationChartConfigFunc(currency);

	const spendByLocationData = collectObjectsWithValidPropFromTreeData(
		spendByLocationDisplayData,
		'invoiceCosts'
	)
		.map((_) => _.invoiceCosts)
		.map((el) => ({
			...el,
			subTotal: parseFloat(el.subTotal),
			percentageOfTotal: parseFloat(el.percentageOfTotal),
		}))
		.sort((a, b) => parseFloat(a.subTotal) - parseFloat(b.subTotal));

	const onSearch = (e) => {
		const spendByLocation = nullSafeGetOrElse('spendByLocation', analytics, []);

		const search = e.target.value;
		const allData = filterByLocationIds(nullSafeGetOrElse('data', spendByLocation, []));

		setFilteredTreeData(
			!!search
				? filterTreeData(allData, (obj) => obj.name.toLowerCase().includes(search.toLowerCase()))
				: []
		);
		setSearch(search);
	};

	const removeLocationIdsAndUpdateFilter = useCallback(
		(filters) => {
			const { locationIds = [], ...otherFilters } = filters;
			setLocationIds(locationIds);
			saveLocationFilters(otherFilters);
		},
		[saveLocationFilters]
	);

	return (
		<Content
			className="analyticsOverviewLocationsPage"
			style={{ padding: '0 0.5em', position: 'relative' }}
		>
			<LogOnMountWithStandardEventProperties eventType="visited spend analytics locations page" />
			{/*<BackTop/>*/}
			{accountActivated ? (
				<div>
					<Row key={1} style={{ margin: '0.5em -8px' }} gutter={16}>
						<Col span={24}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<AnalyticsControlBar
									updateFilters={removeLocationIdsAndUpdateFilter}
									locationIds={locationIds}
									clearFilters={clearAllLocationFilters}
									problemTypeIds={locationFilters.problemTypeIds}
									supplierFacilityIds={locationFilters.supplierFacilityIds}
									startDate={locationFilters.startDate}
									endDate={locationFilters.endDate}
									isCapex={locationFilters.isCapex}
									isPM={locationFilters.isPM}
									workCreatedAtStartDate={locationFilters.workCreatedAtStartDate}
									workCreatedAtEndDate={locationFilters.workCreatedAtEndDate}
									workCompletedAtStartDate={locationFilters.workCompletedAtStartDate}
									workCompletedAtEndDate={locationFilters.workCompletedAtEndDate}
									showSearch
									showDownload
									onDownload={onDownloadAnalytics}
									search={search}
									onSearch={onSearch}
									filterConfig={{
										workCreatedAt: true,
										workCompletedAt: true,
										capEx: true,
										isSpendAnalytics: true,
										isPM: true,
										problemTypes: true,
									}}
									searchPlaceholder={'Search Locations'}
								/>
								<div></div>
							</div>
						</Col>
					</Row>

					{spendByMonth.loading || initialLoading || spendByLocationDisplayData.length > 0 ? (
						[
							<>
								<Row key={2} style={{ margin: '0.5em -8px' }} gutter={16}>
									<Col span={24}>
										<Card>
											<Spin spinning={spendByLocation.loading || initialLoading}>
												{spendByLocationDisplayData && spendByLocationDisplayData.length > 0 ? (
													<Table
														columns={spendByLocationColumns}
														rowKey={(el) => el['id']}
														dataSource={removeEmptyChildrenFromTreeData(spendByLocationDisplayData)}
														expandable={{
															...(!!search && {
																expandedRowKeys: collectAllParentsFromTreeData(
																	filteredTreeData,
																	(_) => _.id
																),
															}),
														}}
													/>
												) : null}
											</Spin>
										</Card>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<Card>
											<Spin spinning={spendByLocation.loading || initialLoading}>
												{spendByLocationData && spendByLocationData.length > 0 ? (
													<BarChart
														height={spendByLocationData.length * 32}
														chartPadding={[40, 40, 40, 200]}
														data={spendByLocationChartConfig.transformer(spendByLocationData)}
														showTooltipTitle={false}
														tooltipItemTemplate={tooltipTemplate}
														xAxisName={nullSafeGet('xAxisName', spendByLocationChartConfig)}
														yAxisName={nullSafeGet('yAxisName', spendByLocationChartConfig)}
														xAxisLabel={nullSafeGet('xAxisLabel', spendByLocationChartConfig)}
														yAxisLabel={nullSafeGet('yAxisLabel', spendByLocationChartConfig)}
														fillArea={nullSafeGet('fillArea', spendByLocationChartConfig)}
														cols={nullSafeGet('cols', spendByLocationChartConfig)}
														color={nullSafeGet('color', spendByLocationChartConfig)}
														position={nullSafeGet('position', spendByLocationChartConfig)}
													/>
												) : null}
											</Spin>
										</Card>
									</Col>
								</Row>
							</>,

							<Row key={3} style={{ margin: '0.5em -8px' }} gutter={16}>
								<Col span={24}>
									<Card>
										<Spin spinning={spendByMonth.loading || initialLoading}>
											{spendByMonth.data && spendByMonth.data.length > 0 ? (
												<StackedAreaChart
													height={260}
													chartPadding={[40, 40, 40, 80]}
													data={spendByMonthConfig.transformer(spendByMonthData)}
													tooltipItemTemplate={tooltipTemplate}
													showTooltipTitle={false}
													xAxisName={nullSafeGet('xAxisName', spendByMonthConfig)}
													yAxisName={nullSafeGet('yAxisName', spendByMonthConfig)}
													yAxisLabel={nullSafeGet('yAxisLabel', spendByMonthConfig)}
													xAxisLabel={nullSafeGet('xAxisLabel', spendByMonthConfig)}
													fillArea={nullSafeGet('fillArea', spendByMonthConfig)}
													cols={nullSafeGet('cols', spendByMonthConfig)}
													color={nullSafeGet('color', spendByMonthConfig)}
													position={nullSafeGet('position', spendByMonthConfig)}
												/>
											) : null}
										</Spin>
									</Card>
								</Col>
							</Row>,
						]
					) : (
						<EmptyState
							height={undefined}
							headline={'No invoices found'}
							body={
								<div
									style={{
										maxWidth: 488,
										textAlign: 'center',
									}}
								>
									We didn't find any invoice data. Try changing your filters or expanding the
									selected time period.
								</div>
							}
						/>
					)}
				</div>
			) : (
				<EmptyState
					graphic={
						<img
							style={{ marginBottom: 8 }}
							src="https://s3.amazonaws.com/mock-data-assets/categories/images/letter owl.svg"
							alt="You haven't activated your account yet."
						/>
					}
					headline={"You haven't activated your account yet."}
					body={
						<div style={{ textAlign: 'center' }}>
							<div style={{ maxWidth: 560, marginBottom: 16 }}>
								Please check your email. We sent you a note with an activation link.
							</div>
						</div>
					}
				/>
			)}
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	accountActivated: state.session.accountActivated,
	currentUser: state.session.currentUser,
	analytics: state.spend_analytics,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	saveLocationFilters: (filters) => dispatch(updateLocationFilters(filters)),
	clearAllLocationFilters: (filters) => dispatch(clearLocationFilters()),
	fetchSpendData: (params, filters = null, pagination = null, sorting = null) =>
		dispatch(
			fetchSpendDataByLocationWithRegions(ownProps.userType)(params, filters, pagination, sorting)
		),
	fetchTimeSeriesSpendData: (params, filters = null, pagination = null, sorting = null) =>
		dispatch(fetchSpendDataByMonth(ownProps.userType)(params, filters, pagination, sorting)),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SpendAnalyticsOverviewLocationsPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

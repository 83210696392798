import * as React from 'react';
import { Layout, DatePicker, Button, List, message, Popover } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	getAllInvoiceDocumentsForSupplier,
	getInvoiceCheckInImagesForSupplier,
	getInvoiceCheckOutImagesForSupplier,
	invoicesRestCrudThunksForSupplier,
	sendInvoiceByEmailForSupplier,
} from '../../thunks/invoices_thunks';
import { compareStrings, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { Link } from 'react-router-dom';
import SupplierSendInvoiceByEmailForm from '../supplier_send_invoice_by_email_form/SupplierSendInvoiceByEmailForm';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import InvoiceEditButton from '../invoice_edit_button/InvoiceEditButton';

const queryString = require('qs');
const style = require('./SupplierInvoicesDetailPage.less');
const ListItem = List.Item;
const ListItemMeta = ListItem.Meta;
const { Content } = Layout;
const { RangePicker } = DatePicker;
const dateFormat = 'MM-DD-YYYY';

interface InvoicesDetailPageState {}

interface InvoicesDetailPageProps {
	match: any;
}

class SupplierInvoicesDetailPage extends React.Component<any, InvoicesDetailPageState> {
	state = {
		sendInvoiceByEmailModalVisible: false,
		documentsReceived: false,
		checkInImagesReceived: false,
		checkOutImagesReceived: false,
		documents: [],
		checkInImages: [],
		checkOutImages: [],
		loading: true,
		backLinkTo: 'supplier/invoices',
		backLinkText: 'Back to invoices',
	};

	constructor(props) {
		super(props);
	}

	formRefs = {
		sendInvoiceByEmailForm: null,
	};

	componentDidMount() {
		const {
			getInvoice,
			getAllDocuments,
			getCheckInImages,
			getCheckOutImages,

			location,
			match,
		} = this.props;
		const invoiceId = match.params.id;
		getInvoice(invoiceId).then((invoice) => {
			let searchString = location.search;
			if (searchString[0] === '?') {
				searchString = searchString.slice(1);
			}
			const queryParams = queryString.parse(searchString);
			if (queryParams.backlinkParams) {
				const { pathName, backLinkText, ...otherParams } = queryString.parse(
					queryParams.backlinkParams
				);
				const finalPath = pathName || `/supplier/invoices/overview/all`;
				this.setState({
					loading: false,
					backLinkTo: `${finalPath}?${new URLSearchParams(otherParams).toString()}`,
					...(backLinkText && { backLinkText }),
				});
			} else {
				this.setState({
					loading: false,
					backLinkTo: `/supplier/invoices/overview/all`,
				});
			}
		});

		getAllDocuments(invoiceId).then((documents) => {
			this.setState({ documents: documents, documentsReceived: true });
		});

		getCheckInImages(invoiceId).then((checkInImages) => {
			this.setState({ checkInImages: checkInImages, checkInImagesReceived: true });
		});

		getCheckOutImages(invoiceId).then((checkOutImages) => {
			this.setState({ checkOutImages: checkOutImages, checkOutImagesReceived: true });
		});
	}

	handleSendByEmailSubmit = () => {
		const { sendInvoiceByEmail } = this.props;
		const form = this.formRefs['sendInvoiceByEmailForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}
			const checkInImagesString =
				values.checkInImages && values.checkInImages.length > 0
					? values.checkInImages.map((p) => {
							const fileName = nullSafeGetOrElse('response.data.fileName', p, '');
							const fileId = nullSafeGetOrElse('response.data.fileId', p, '');
							return `${fileId}/${fileName}`;
					  })
					: [];
			const checkOutImagesString =
				values.checkOutImages && values.checkOutImages.length > 0
					? values.checkOutImages.map((p) => {
							const fileName = nullSafeGetOrElse('response.data.fileName', p, '');
							const fileId = nullSafeGetOrElse('response.data.fileId', p, '');
							return `${fileId}/${fileName}`;
					  })
					: [];
			const data = {
				checkInImages: checkInImagesString,
				checkOutImages: checkOutImagesString,
				documents: values.documents,
			};
			sendInvoiceByEmail(values.invoiceId, values.email, data).then(() => {
				this.setState({
					sendInvoiceByEmailModalVisible: false,
				});
				message.success(`Invoice sent to ${values.email}.`);
			});
		});
	};

	handleSendByEmailCancel = (e) => {
		this.setState({
			sendInvoiceByEmailModalVisible: false,
		});
	};

	handleTabChange = (key) => {
		const { history, match } = this.props;
		const invoiceId = match.params.id;
		history.push(`/supplier/invoices/detail/${invoiceId}/${key}`);
	};

	handleDelete = () => {
		const { history, deleteInvoice, invoice } = this.props;
		deleteInvoice(invoice).then(() => {
			history.push('/supplier/invoices/overview/all');
		});
	};

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	handleSendByEmail = () => {
		this.setState({
			sendInvoiceByEmailModalVisible: true,
		});
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	editInvoice = () => {
		const { match, history } = this.props;
		const invoiceId = match.params.id;
		history.push(`/supplier/invoices/edit/${invoiceId}`);
	};

	viewWorkOrder = () => {
		const { invoice, history } = this.props;
		history.push(`/supplier/workOrders/detail/${invoice.workOrderId}/details`);
	};

	render() {
		const { invoice, invoicesFetching, match, companyConfig, currentUser } = this.props;
		const invoiceId = match.params.id;
		const isInternalTech = nullSafeGetOrElse('facility.status', currentUser, '') === 'private';
		const tabs = [
			{
				key: 'details',
				name: 'Overview',
			},
		];

		const invoiceActions = [
			<Link target={'_'} to={`/supplier/workOrders/detail/${invoice.workOrderId}/details`}>
				<Button ghost={true}>
					View Work Order <i className="icons8-font icons8-external-link ml-2" />
				</Button>
			</Link>,
			<InvoiceEditButton invoice={invoice} ghost={true} />,
		];

		return (
			<div className="invoicesDetailPage">
				{this.state.documentsReceived &&
				this.state.checkInImagesReceived &&
				this.state.checkOutImagesReceived ? (
					<SupplierSendInvoiceByEmailForm
						wrappedComponentRef={this.saveFormRef('sendInvoiceByEmailForm')}
						visible={this.state.sendInvoiceByEmailModalVisible}
						onCancel={this.handleSendByEmailCancel}
						onSubmit={this.handleSendByEmailSubmit}
						invoiceId={invoiceId}
						documents={this.state.documents}
						checkInImages={this.state.checkInImages}
						checkOutImages={this.state.checkOutImages}
					/>
				) : null}

				<DetailPageHeader
					exactPath={'/supplier/invoices/detail/:id'}
					redirectPath={`/supplier/invoices/detail/${invoiceId}/details`}
					backLinkText={this.state.backLinkText}
					backLinkTo={this.state.backLinkTo}
					title={invoicesFetching ? null : nullSafeGet('workOrder.title', invoice)}
					subtitle={null}
					actions={invoiceActions}
					tabs={tabs}
					handleTabChange={this.handleTabChange}
					checkTabActive={this.checkTabActive}
					backgroundColor={nullSafeGet('detail.config.theme.navblock', companyConfig)}
					childRoutes={this.props.childRoutes}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	invoicesFetching: state.invoices.fetching,
	invoice: state.invoices.detail,
	match: ownProps.match,
	history: ownProps.history,
	companyConfig: state.company_config,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	getInvoice: (id) => dispatch(invoicesRestCrudThunksForSupplier.readOne(id)),
	sendInvoiceByEmail: (id, email, data) => dispatch(sendInvoiceByEmailForSupplier(id, email, data)),
	deleteInvoice: (entity) =>
		dispatch(invoicesRestCrudThunksForSupplier.delete(entity, 'id', null, true)),
	getAllDocuments: (id) => dispatch(getAllInvoiceDocumentsForSupplier(id)),
	getCheckInImages: (id) => dispatch(getInvoiceCheckInImagesForSupplier(id)),
	getCheckOutImages: (id) => dispatch(getInvoiceCheckOutImagesForSupplier(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SupplierInvoicesDetailPage));

import React from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Alert, Button, Select } from 'antd';
import { connect } from 'react-redux';
import {
	locationTypesRestCrudThunksForBuyer,
	locationTypesRestCrudThunksForSupplier,
} from '../../thunks/location_types_thunks';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import BackButton from '../back_button/BackButton';
import { ROLE_TYPES } from '../../utils/DataConstants';

require('./LocationTypeForm.less');
const FormItem = Form.Item;
const REQUIRED_FIELDS_KEYS = 'requiredFieldsKeys';
const OPTIONAL_FIELDS_KEYS = 'optionalFieldsKeys';

interface LocationTypeFormProps extends FormComponentProps {
	creating: boolean;
	updating: boolean;
	formData: any;
	history: any;
	redirectForwardUrl?: string;
	onSuccess?: any;
	createErrors: any[];
	updateErrors: any[];
	currentUser: any;
	create: any;
	update: any;
}

class LocationTypeForm extends React.Component<LocationTypeFormProps, any> {
	componentDidMount() {
		this.setState({
			requiredFieldsUuid: nullSafeGetOrElse('formData.requiredFields', this.props, []).length,
			optionalFieldsUuid: nullSafeGetOrElse('formData.optionalFields', this.props, []).length,
		});
	}

	handleSubmit = (e, submitAction) => {
		const { history, onSuccess, redirectForwardUrl } = this.props;
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const requiredFieldsKeys = values[REQUIRED_FIELDS_KEYS];
				const optionalFieldsKeys = values[OPTIONAL_FIELDS_KEYS];
				const requiredFields = requiredFieldsKeys
					.filter((s) => values[`requiredFields-${s}-key`] && values[`requiredFields-${s}-type`])
					.map((k) => ({
						key: values[`requiredFields-${k}-key`],
						type: values[`requiredFields-${k}-type`],
					}));
				const optionalFields = optionalFieldsKeys
					.filter((s) => values[`optionalFields-${s}-key`] && values[`optionalFields-${s}-type`])
					.map((k) => ({
						key: values[`optionalFields-${k}-key`],
						type: values[`optionalFields-${k}-type`],
					}));
				let locationType = {
					id: values.id,
					name: values.name,
					buyerFacilityId: values.buyerFacilityId,
					requiredFields,
					optionalFields,
				};

				submitAction(locationType).then((record) => {
					if (redirectForwardUrl) {
						history.push(redirectForwardUrl);
					}
					if (onSuccess) {
						onSuccess(record);
					}
				});
			}
		});
	};

	addRequiredField = () => {
		const { requiredFieldsUuid } = this.state;
		this.addField(REQUIRED_FIELDS_KEYS)(requiredFieldsUuid);
		this.setState({ requiredFieldsUuid: requiredFieldsUuid + 1 });
	};

	addOptionalField = () => {
		const { optionalFieldsUuid } = this.state;
		this.addField(OPTIONAL_FIELDS_KEYS)(optionalFieldsUuid);
		this.setState({ optionalFieldsUuid: optionalFieldsUuid + 1 });
	};

	removeRequiredField = (k) => {
		this.removeField(REQUIRED_FIELDS_KEYS)(k);
	};

	removeOptionalField = (k) => {
		this.removeField(OPTIONAL_FIELDS_KEYS)(k);
	};

	addField = (fieldName) => (uuid) => {
		const { form } = this.props;
		// can use data-binding to get
		const keys = form.getFieldValue(fieldName);
		const nextKeys = [...keys, uuid];
		// can use data-binding to set
		// important! notify form to detect changes
		form.setFieldsValue({
			[fieldName]: nextKeys,
		});
	};

	removeField = (fieldName) => (k) => {
		const { form } = this.props;
		// can use data-binding to get
		const keys = form.getFieldValue(fieldName);
		const nextKeys = keys.filter((key) => key !== k);

		// can use data-binding to set
		form.setFieldsValue({
			[fieldName]: nextKeys,
		});
	};

	render() {
		const {
			updating,
			creating,
			form,
			update,
			create,
			createErrors,
			formData,
			updateErrors,
			currentUser,
		} = this.props;
		const { getFieldDecorator } = form;
		const isUpdate = formData && formData.id !== undefined;
		const submitAction = isUpdate ? update : create;
		const submitText = isUpdate ? 'Update location type' : 'Create location type';
		const isLoading = updating || creating;
		getFieldDecorator(`id`, { initialValue: formData.id });
		getFieldDecorator('buyerFacilityId', {
			initialValue: formData.buyerFacilityId || nullSafeGet('facility.id', currentUser),
		});
		getFieldDecorator(REQUIRED_FIELDS_KEYS, {
			initialValue: nullSafeGetOrElse('requiredFields', formData, []).map((e, idx) => idx),
		});
		getFieldDecorator(OPTIONAL_FIELDS_KEYS, {
			initialValue: nullSafeGetOrElse('optionalFields', formData, []).map((e, idx) => idx),
		});
		const requiredFieldsKeys = form.getFieldValue(REQUIRED_FIELDS_KEYS);
		const optionalFieldsKeys = form.getFieldValue(OPTIONAL_FIELDS_KEYS);

		const getFieldItems = (type) => {
			let fieldsKeys;
			let labelText;
			let keyPrefix;
			let removeAction;
			if (type === 'required') {
				fieldsKeys = requiredFieldsKeys;
				labelText = 'Required Fields';
				keyPrefix = 'requiredFields';
				removeAction = this.removeRequiredField;
			} else {
				fieldsKeys = optionalFieldsKeys;
				labelText = 'Optional Fields';
				keyPrefix = 'optionalFields';
				removeAction = this.removeOptionalField;
			}

			return fieldsKeys.map((k, idx) => (
				<div>
					<div style={{ display: 'flex' }}>
						<div
							style={{
								marginRight: 16,
								paddingTop: idx === 0 ? 31 : 0,
							}}
						>
							{idx + 1}
						</div>
						<Form.Item
							label={idx === 0 ? 'Name' : null}
							required={false}
							style={{ width: 200, marginRight: 16 }}
						>
							{form.getFieldDecorator(`${keyPrefix}-${k}-key`, {
								validateTrigger: ['onChange', 'onBlur'],
								initialValue:
									nullSafeGet(`${keyPrefix}-${k}-key`, formData) ||
									nullSafeGet(`${keyPrefix}.${k}.key`, formData),
							})(<Input />)}
						</Form.Item>
						<Form.Item label={idx === 0 ? 'Type' : null} required={false} style={{ width: 100 }}>
							{form.getFieldDecorator(`${keyPrefix}-${k}-type`, {
								initialValue:
									nullSafeGet(`${keyPrefix}-${k}-type`, formData) ||
									nullSafeGet(`${keyPrefix}.${k}.type`, formData) ||
									'string',
							})(
								<Select>
									<Select.Option value="string">Text</Select.Option>
									<Select.Option value="number">Number</Select.Option>
									<Select.Option value="currency">Currency</Select.Option>
									<Select.Option value="date">Date</Select.Option>
								</Select>
							)}
						</Form.Item>
						<div
							style={{
								paddingTop: idx === 0 ? 35 : 5,
							}}
						>
							<MinusCircleOutlined
								translate=""
								className="dynamic-delete-button"
								onClick={() => removeAction(k)}
							/>
						</div>
					</div>
				</div>
			));
		};

		return (
			<Form
				layout="vertical"
				onSubmit={(e) => this.handleSubmit(e, submitAction)}
				className="supplierForm"
			>
				{createErrors.length > 0 ? (
					<FormItem>
						<Alert message={createErrors.join(' ')} type="error" />
					</FormItem>
				) : null}
				{updateErrors.length > 0 ? (
					<FormItem>
						<Alert message={updateErrors.join(' ')} type="error" />
					</FormItem>
				) : null}
				<FormItem label="Name">
					{getFieldDecorator('name', {
						initialValue: formData.name,
					})(<Input style={{ maxWidth: 300 }} disabled={isUpdate} />)}
				</FormItem>
				<Form.Item label="Required Fields" style={{ marginBottom: 0 }}>
					{getFieldItems('required')}
				</Form.Item>
				<Form.Item>
					<Button type="dashed" onClick={() => this.addRequiredField()}>
						<PlusOutlined translate="" /> Add required field
					</Button>
				</Form.Item>
				<Form.Item label="Optional Fields" style={{ marginBottom: 0 }}>
					{getFieldItems('optional')}
				</Form.Item>
				<Form.Item>
					<Button type="dashed" onClick={() => this.addOptionalField()}>
						<PlusOutlined translate="" /> Add optional field
					</Button>
				</Form.Item>
				<FormItem>
					<Button
						type="primary"
						htmlType="submit"
						loading={isLoading}
						className="contactForm__button"
					>
						{submitText}
					</Button>
					<span style={{ marginLeft: '16px' }}>
						<BackButton buttonText="Cancel" />
					</span>
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	formData: ownProps.formData,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,

	createErrors: state.location_types.createErrors,
	updateErrors: state.location_types.updateErrors,
	creating: state.location_types.creating,
	updating: state.location_types.updating,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	update: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationTypesRestCrudThunksForSupplier.update(entity)
				: locationTypesRestCrudThunksForBuyer.update(entity)
		),
	create: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationTypesRestCrudThunksForSupplier.create(entity)
				: locationTypesRestCrudThunksForBuyer.create(entity)
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(LocationTypeForm)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(Form.create<LocationTypeFormProps>()(ComponentWithoutUserType));

import React, { FC, useCallback, useMemo, useState } from 'react';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { SUPPLIER_EXCHANGE_RATES_CRUD_ACTION_CREATORS } from '../../actions/supplier_exchange_rates_actions';
import { supplierExchangeRatesRestCrudThunksForSupplier } from '../../thunks/supplier_exchange_rates_thunks';
import { Button } from 'antd';
import { EmptyState } from '../empty_state/EmptyState';
import AddEditMonthlyExchangeRateModal from './AddEditExchangeRateModal';
import { getCurrency, nullSafeGet } from '../../utils/DataAccessUtils';
import ExchangeRateHistoryModal from './ExchangeRateHistoryModal';

const TC_NAME = 'supplierExchangeRatesIndex';

const SupplierExchangeRatesPage: FC<any> = ({
	updateFilters,
	clearAndUpdateFilters,
	exchangeRates,
	fetchCurrencyExchangeRates,
	currentUser,
}): React.ReactElement => {
	const [editingRecord, setEditingRecord] = useState(null);
	const [erModalVisibile, setErModalVisible] = useState(false);
	const [historyModalVisible, setHistoryModalVisible] = useState(false);

	const _showErModal = () => setErModalVisible(true);
	const _hideErModal = () => {
		setErModalVisible(false);
		setEditingRecord(null);
	};

	const _showHistoryModal = () => setHistoryModalVisible(true);
	const _hideHistoryModal = () => {
		setHistoryModalVisible(false);
		setEditingRecord(null);
	};

	const refreshExchangeRates = useCallback(
		() => fetchCurrencyExchangeRates(null, TC_NAME),
		[fetchCurrencyExchangeRates]
	);

	const onEdit = useCallback(
		(record) => () => {
			setEditingRecord(record);
			_showErModal();
		},
		[]
	);

	const onShowHistory = useCallback(
		(record) => () => {
			setEditingRecord(record);
			_showHistoryModal();
		},
		[]
	);

	const onAddOrEditSuccess = useCallback(() => {
		_hideErModal();
		refreshExchangeRates();
	}, [refreshExchangeRates]);

	const userCurrencyId = useMemo(
		() => nullSafeGet('id', getCurrency({ currentUser })),
		[currentUser]
	);

	const getCurrencyTitle = useCallback(
		(record) => `${record.currencyId} -> ${userCurrencyId}`,
		[userCurrencyId]
	);

	const columns = useMemo(
		() => [
			{
				title: 'Currency',
				dataIndex: 'currencyId',
				render: (_, record) => <div>{getCurrencyTitle(record)}</div>,
			},
			{
				title: 'Exchange Rate',
				dataIndex: 'exchangeRate',
			},
			{
				title: '',
				dataIndex: '',
				render: (_, record) => (
					<div>
						<Button type="ghost" onClick={onEdit(record)}>
							Edit
						</Button>
						<Button className="ml-2" type="ghost" onClick={onShowHistory(record)}>
							Show History
						</Button>
					</div>
				),
			},
		],
		[onEdit, onShowHistory, getCurrencyTitle]
	);

	const createExchangeRateButton = useMemo(
		() => (
			<Button type="primary" onClick={_showErModal}>
				Add Exchange Rate
			</Button>
		),
		[]
	);

	return (
		<>
			<PaginatedReduxTableWithHeader
				targetCollectionName={TC_NAME}
				updateFilters={updateFilters}
				stateSlice={exchangeRates}
				clearAndUpdateFilters={clearAndUpdateFilters}
				filterConfig={[]}
				entityCollectionName="supplier_currency_exchange_rates"
				tableColumns={columns}
				fetchData={supplierExchangeRatesRestCrudThunksForSupplier.read}
				rightActions={createExchangeRateButton}
				hideSearch
				showHeader
				emptyState={
					<EmptyState
						graphic={
							<img
								alt="No Data"
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
							/>
						}
						headline={'No exchange rates found'}
						body={<div className="text-center">{createExchangeRateButton}</div>}
					/>
				}
			/>
			{erModalVisibile ? (
				<AddEditMonthlyExchangeRateModal
					exchangeRate={editingRecord}
					onCancel={_hideErModal}
					onSuccess={onAddOrEditSuccess}
				/>
			) : null}
			{historyModalVisible ? (
				<ExchangeRateHistoryModal
					exchangeRate={editingRecord}
					currencyTitle={getCurrencyTitle(editingRecord)}
					onCancel={_hideHistoryModal}
				/>
			) : null}
		</>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	exchangeRates: state.supplier_currency_exchange_rates,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	fetchCurrencyExchangeRates: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			supplierExchangeRatesRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	updateFilters: (filters, targetCollection) =>
		dispatch(SUPPLIER_EXCHANGE_RATES_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			SUPPLIER_EXCHANGE_RATES_CRUD_ACTION_CREATORS.clearAndUpdateFilters(
				filters,
				targetCollectionName
			)
		),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(SupplierExchangeRatesPage)
);

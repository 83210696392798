import React from 'react';
import { EmptyState } from '../empty_state/EmptyState';

const filterViewMap = {
	work_orders: 'Work Orders',
	site_surveys: 'Site Surveys',
	invoices: 'Invoices',
	assets: 'Assets',
	meters: 'Meters',
	locations: 'Locations',
};

const SavedFilterViewEmptyState = ({ entityType }): React.ReactElement => {
	return (
		<EmptyState
			height={120}
			headline={'No saved views found'}
			body={
				<div style={{ maxWidth: 488, textAlign: 'center' }}>
					{`You don't have any saved views for ${filterViewMap[entityType]}. 
    Please save filters to view here.`}
				</div>
			}
		/>
	);
};

export default SavedFilterViewEmptyState;

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import '@ant-design/compatible/assets/index.css';
import { Layout, Row, Col, Card } from 'antd';
import { resendConfirmation } from '../../thunks/session_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { TextButton } from '../text_button/TextButton';
import { EmptyState } from '../empty_state/EmptyState';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;
const style = require('./SupplierNewAccountSetupCompletionPage.less');

class SupplierNewAccountSetupCompletionPage extends React.Component<any, any> {
	resendConfirmation = () => {
		const { resendEmailAuthentication } = this.props;
		resendEmailAuthentication(ROLE_TYPES.SUPPLIER);
	};

	render() {
		const { currentUser } = this.props;
		return (
			<Content className="supplierNewAccountSetupCompletionPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited supplier new account setup completion page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<Card>
							<h4>One last thing, {currentUser.nameGiven}.</h4>
							<EmptyState
								graphic={
									<img
										style={{ marginBottom: 8 }}
										src="https://s3.amazonaws.com/mock-data-assets/categories/images/letter owl.svg"
									/>
								}
								headline={"You haven't activated your account yet."}
								body={
									<div style={{ textAlign: 'center' }}>
										<div style={{ maxWidth: 560 }}>
											<p style={{ fontSize: 18 }}>
												In order to make sure your company's data is secure, you'll need to use the
												link we just emailed you to activate your account before jumping in.
											</p>
											<p style={{ fontSize: 18 }}>
												Can't find the activation email?{' '}
												<TextButton onClick={this.resendConfirmation}>
													Request a new one.
												</TextButton>
											</p>
											<p style={{ fontSize: 18 }}>
												If you have any questions about the platform or run into any issues, please
												use the chat box in the bottom right corner to drop us a line. We're here to
												help!
											</p>
										</div>
									</div>
								}
							/>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	resendEmailAuthentication: (role) => dispatch(resendConfirmation(role)()),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SupplierNewAccountSetupCompletionPage)
);

import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Layout, Row, Col, Card } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import EditableCellTable from '../editable_cell_table/EditableCellTable';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { getConfigValueDefaultingToTrue, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import {
	companyConfigRestCrudThunks,
	companyConfigRestCrudThunksForSupplier,
} from '../../thunks/company_config_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';

const { Content } = Layout;

const SupplierInventorySettingsPage: FC<any> = ({
	currentUser,
	companySettings,
	updateCompanySettings,
	getCompanySettings,
	companySettingsFetching,
}): React.ReactElement => {
	useEffect(() => {
		getCompanySettings(currentUser);
	}, [currentUser, getCompanySettings]);

	// const accountingSettingsArray = useMemo(
	// 	() => [
	// 		{
	// 			settings: 'Allow Monthly currency exchange rate',
	// 			key: 'allowMonthlyCurrencyExchangeRate',
	// 			indicator: nullSafeGetOrElse(
	// 				'inventoryConfig.allowMonthlyCurrencyExchangeRate',
	// 				companySettings,
	// 				false
	// 			),
	// 		},
	// 	],
	// 	[companySettings]
	// );

	const workOrderSettingsArray = useMemo(
		() => [
			{
				settings: 'Allow Reserved Parts',
				key: 'allowReservedParts',
				indicator: nullSafeGetOrElse('inventoryConfig.allowReservedParts', companySettings, false),
			},
			{
				settings: 'Auto Consume requested parts',
				key: 'autoConsumeShippedItems',
				indicator: nullSafeGetOrElse(
					'inventoryConfig.autoConsumeShippedItems',
					companySettings,
					false
				),
			},
			{
				settings: 'Default vendor mandatory for creating new part',
				key: 'defaultVendorRequiredOnCreatingNewPart',
				indicator: getConfigValueDefaultingToTrue(
					companySettings,
					'inventoryConfig',
					'defaultVendorRequiredOnCreatingNewPart'
				),
			},
			{
				settings: 'Auto return unconsumed parts on work order closing',
				key: 'autoReturnUnconsumedPartsOnWorkOrderClosing',
				indicator: nullSafeGetOrElse(
					'inventoryConfig.autoReturnUnconsumedPartsOnWorkOrderClosing',
					companySettings,
					false
				),
			},
			{
				settings: 'Create purchase requests immediately for stocked parts (Skip Backorders)',
				key: 'skipBackordersForStockedPart',
				indicator: nullSafeGetOrElse(
					'inventoryConfig.skipBackordersForStockedPart',
					companySettings,
					false
				),
			},
		],
		[companySettings]
	);

	const puchaseRequestSettings = useMemo(
		() => [
			{
				settings: 'Auto Approve Purchase Requests For Stocked Parts',
				key: 'autoApprovePurchaseRequestForStockedPart',
				indicator: nullSafeGetOrElse(
					'inventoryConfig.autoApprovePurchaseRequestForStockedPart',
					companySettings,
					false
				),
			},
			{
				settings: 'Auto Approve Purchase Requests For Non Stocked Parts',
				key: 'autoApprovePurchaseRequestForNonStockedPart',
				indicator: nullSafeGetOrElse(
					'inventoryConfig.autoApprovePurchaseRequestForNonStockedPart',
					companySettings,
					false
				),
			},
		],
		[companySettings]
	);

	const purchaseOrderSttings = useMemo(
		() => [
			{
				settings: 'Disable Purchase Orders',
				key: 'disablePurchaseOrders',
				indicator: nullSafeGetOrElse(
					'inventoryConfig.disablePurchaseOrders',
					companySettings,
					false
				),
			},
			{
				settings: 'Auto Create Purchase Order For Stocked Parts',
				key: 'autoSendPurchaseOrderOnPurchaseRequestApprovalForStockedPart',
				indicator: nullSafeGetOrElse(
					'inventoryConfig.autoSendPurchaseOrderOnPurchaseRequestApprovalForStockedPart',
					companySettings,
					false
				),
			},
			{
				settings: 'Auto Create Purchase Order For Non Stocked Parts',
				key: 'autoSendPurchaseOrderOnPurchaseRequestApprovalForNonStockedPart',
				indicator: nullSafeGetOrElse(
					'inventoryConfig.autoSendPurchaseOrderOnPurchaseRequestApprovalForNonStockedPart',
					companySettings,
					false
				),
			},
			{
				settings: 'Automatically Email Purchase Order to vendor',
				key: 'autoSendPurchaseOrderAsEmail',
				indicator: nullSafeGetOrElse(
					'inventoryConfig.autoSendPurchaseOrderAsEmail',
					companySettings,
					false
				),
			},
			{
				settings: 'Accept serial numbers while receiving equipment',
				key: 'acceptSerialNumberForEquipmentReceiveInPO',
				indicator:
					typeof nullSafeGetOrElse('inventoryConfig', companySettings, {})
						.acceptSerialNumberForEquipmentReceiveInPO === 'undefined'
						? true
						: companySettings.inventoryConfig.acceptSerialNumberForEquipmentReceiveInPO,
			},
		],
		[companySettings]
	);

	const workOrderSettings = useMemo(
		() => [
			{
				settings: 'Auto assign Technician to service call based on Routing',
				key: 'autoAssignTechnicianToServiceCallBasedOnRouting',
				indicator: nullSafeGetOrElse(
					'config.autoAssignTechnicianToServiceCallBasedOnRouting',
					companySettings,
					false
				),
			},
		],
		[companySettings]
	);

	const transferSetting = useMemo(
		() => [
			{
				settings: 'Transfer notes mandatory',
				key: 'transferNoteMandatory',
				indicator: nullSafeGetOrElse(
					'inventoryConfig.transferNoteMandatory',
					companySettings,
					false
				),
			},
		],
		[companySettings]
	);

	const updateSettings = useCallback(
		(newSetting) => {
			const updatedSettings = {
				...companySettings,
				inventoryConfig: {
					...nullSafeGetOrElse('inventoryConfig', companySettings, {}),
					[newSetting.key]: newSetting.indicator,
				},
			};
			updateCompanySettings(updatedSettings);
		},
		[companySettings, updateCompanySettings]
	);

	const updateGeneralSettings = useCallback(
		(newSetting) => {
			const updatedSettings = {
				...companySettings,
				config: {
					...nullSafeGetOrElse('config', companySettings, {}),
					[newSetting.key]: newSetting.indicator,
				},
			};
			updateCompanySettings(updatedSettings);
		},
		[companySettings, updateCompanySettings]
	);

	const overallSettingsArray = useMemo(
		() => [
			// {
			// 	title: 'Accounting',
			// 	data: accountingSettingsArray,
			// },
			{
				title: 'Inventory Configurations - Work Orders',
				data: workOrderSettingsArray,
			},
			{
				title: 'Inventory Configurations - Purchase Requests',
				data: puchaseRequestSettings,
			},
			{
				title: 'Inventory Configurations - Purchase Orders',
				data: purchaseOrderSttings,
			},
			{
				title: 'Inventory Configurations - Transfers',
				data: transferSetting,
			},
			{
				title: 'Work Order Configurations',
				data: workOrderSettings,
				handleSave: updateGeneralSettings,
			},
		],
		[
			puchaseRequestSettings,
			purchaseOrderSttings,
			transferSetting,
			updateGeneralSettings,
			workOrderSettings,
			workOrderSettingsArray,
		]
	);

	const getColumns = useCallback(
		(title) => [
			{
				title: title || 'Configuration',
				dataIndex: 'settings',
				width: '640px',
				render: (text) => (
					<span style={{ fontSize: 16 }}>
						{text[0] ? text[0].concat(text.slice(1).toLowerCase()) : ''}
					</span>
				),
			},
			{
				dataIndex: 'indicator',
				width: '80px',
				editable: true,
			},
			{
				dataIndex: 'numerical',
				render: () => '',
			},
		],
		[]
	);

	return companySettingsFetching ? (
		<PageLoadingPlaceholder />
	) : (
		<Content className="buyerAdminSettingsPage">
			<LogOnMountWithStandardEventProperties eventType="visited supplier inventory settings page" />

			{overallSettingsArray.map((configurationGroup, index) => (
				<Row className="rowSpacing" key={index}>
					<Col style={{ maxWidth: 800, margin: 'auto' }} span={24}>
						<Card title={configurationGroup.title}>
							<EditableCellTable
								handleSave={configurationGroup.handleSave || updateSettings}
								columns={getColumns(configurationGroup.title)}
								data={configurationGroup.data}
							/>
						</Card>
					</Col>
				</Row>
			))}
		</Content>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
	companySettings: state.company_config.detail,
	companySettingsFetching: state.company_config.fetching,
});

const mapDispatchToProps = (dispatch) => ({
	getCompanySettings: (contact) =>
		dispatch(companyConfigRestCrudThunks(ROLE_TYPES.SUPPLIER, contact)),
	updateCompanySettings: (settings) =>
		dispatch(companyConfigRestCrudThunksForSupplier.update(settings)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(SupplierInventorySettingsPage)
);

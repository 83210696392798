import { BUYER_FACILITIES_CRUD_ACTION_CREATORS } from '../actions/buyer_facilities_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';

export const buyerFacilitiesRestCrudThunksForBuyer = new RestCrudThunks(
	'buyer_facilities',
	'buyer_facility',
	'buyerFacility',
	'buyerFacilities',
	BUYER_FACILITIES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER
);
export const buyerFacilitiesRestCrudThunksForSupplier = new RestCrudThunks(
	'buyer_facilities',
	'buyer_facility',
	'buyerFacility',
	'buyerFacilities',
	BUYER_FACILITIES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER
);

import { Button, Card } from 'antd';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { purchaseOrderReceiptsRestCrudThunksForSupplier } from '../../thunks/purchase_order_receipt_thunks';
import {
	getCurrency,
	getEntityById,
	nullSafeGet,
	nullSafeGetOrElse,
	renderCurrencyByCurrencyId,
} from '../../utils/DataAccessUtils';
import { dateFormatter, DATE_FORMAT } from '../../utils/DataFormatterUtils';
import { EmptyState } from '../empty_state/EmptyState';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import EditReceiptModal from './EditReceiptModal';
import PartEquipmentNameWithType from '../equipment_per_stock_location_row_display/PartEquipmentNameWithType';

const DEFAULT_BACK_LINK_TEXT = 'Back to Receipts';
const TC_NAME = 'purchaseOrderReceiptsIndex';

const PurchaseOrderDetailReceiptsPage: FC<any> = ({
	purchaseOrder,
	currentUser,
	purchaseOrderReceipts,
	fetchReceipts,
	match,
}): React.ReactElement => {
	const [editModalVisible, setEditModalVisible] = useState(false);
	const [currentEditingRecord, setCurrentEditingRecord] = useState(null);

	const currencyId = nullSafeGet('id', getCurrency({ currentUser }));

	const purchaseOrderId = useMemo(() => match.params.id, [match.params.id]);

	const initialPagination = useMemo(
		() => ({
			current: 1,
			pageSize: 5,
		}),
		[]
	);

	const getContact = useCallback(
		(receipt, propName) =>
			`${nullSafeGetOrElse(`${propName}.nameGiven`, receipt, '')} ${nullSafeGetOrElse(
				`${propName}.nameFamily`,
				receipt,
				''
			)}`,
		[]
	);

	const showEditModal = useCallback(() => setEditModalVisible(true), []);
	const hideEditModal = useCallback(() => setEditModalVisible(false), []);

	const onEditReceipt = useCallback(
		(receipt) => () => {
			showEditModal();
			setCurrentEditingRecord(receipt);
		},
		[showEditModal]
	);

	const onCancel = useCallback(() => {
		hideEditModal();
		setCurrentEditingRecord(null);
	}, [hideEditModal]);

	const displayIfExists = useCallback(
		(propName, detail) =>
			detail ? (
				<div className="flex flex-row items-center">
					{propName ? <div className="text-gray-400">{`${propName} : `}</div> : null}
					<div>{detail}</div>
				</div>
			) : null,
		[]
	);

	const columns = useMemo(
		() => [
			{
				title: 'ID',
				render: (_, record) =>
					record.equipmentType
						? nullSafeGetOrElse('equipmentType.equipmentTypeNumber', record, '--')
						: nullSafeGetOrElse('part.partNumber', record, '--'),
			},
			{
				title: 'Name',
				render: (_, record) => (
					<PartEquipmentNameWithType
						record={{
							...record,
							isEquipmentLine: !!record.equipmentType,
						}}
						backText={DEFAULT_BACK_LINK_TEXT}
					/>
				),
			},
			{
				title: 'Received Qty',
				dataIndex: 'receivedQuantity',
				render: (_) => `${_} ${_ < 0 ? '(Return)' : ''}`,
			},
			{
				title: 'Unit Price',
				dataIndex: 'pricePerQuantity',
				render: (_, record) =>
					renderCurrencyByCurrencyId(nullSafeGetOrElse('invoiceCurrency', record, currencyId))(_),
			},
			{
				title: 'Invoice Detail',
				render: (_, record) => (
					<div className="flex flex-col">
						{displayIfExists(
							'',
							nullSafeGet('invoiceNumber', record) ? `#${record.invoiceNumber}` : null
						)}
						{displayIfExists(
							'Line Total',
							nullSafeGet('invoiceTotal', record)
								? renderCurrencyByCurrencyId(nullSafeGet('invoiceCurrency', record))(
										record.invoiceTotal
								  )
								: null
						)}
						{displayIfExists('Exchange Rate', nullSafeGet('exchangeRate', record))}
					</div>
				),
			},
			{
				title: 'Received by',
				dataIndex: 'receivedBy',
				render: (_, record) => (
					<div className="flex flex-col">
						<div>{`${getContact(record, 'receiveByContact')}`}</div>
						<div className="text-gray-400">
							{`at ${dateFormatter(nullSafeGet('createdAt', record), DATE_FORMAT)}`}
						</div>
					</div>
				),
			},
			{
				title: 'Updated by',
				dataIndex: 'updatedBy',
				render: (_, record) => (
					<div className="flex flex-col">
						<div>{`${getContact(record, 'updatedByContact')}`}</div>
						<div className="text-gray-400">
							{`at ${dateFormatter(nullSafeGet('updatedAt', record), DATE_FORMAT)}`}
						</div>
					</div>
				),
			},
			{
				render: (_, record) => (
					<Button type="ghost" onClick={onEditReceipt(record)}>
						Edit
					</Button>
				),
			},
		],
		[currencyId, displayIfExists, getContact, onEditReceipt]
	);

	const getStatSliceProp = useCallback(
		(propName) => nullSafeGetOrElse(`${TC_NAME}.${propName}`, purchaseOrderReceipts, {}),
		[purchaseOrderReceipts]
	);

	const refreshReceipts = useCallback(() => {
		fetchReceipts(
			{
				purchaseOrderId,
			},
			TC_NAME,
			getStatSliceProp('pagination'),
			getStatSliceProp('sorting'),
			getStatSliceProp('filters')
		);
	}, [fetchReceipts, getStatSliceProp, purchaseOrderId]);

	const onUpdateSuccess = useCallback(() => {
		refreshReceipts();
		hideEditModal();
	}, [hideEditModal, refreshReceipts]);

	return (
		<Card bodyStyle={{ padding: 8 }}>
			<PaginatedReduxTable
				mode="list"
				updateQueryParams={true}
				emptyState={
					<EmptyState
						graphic={
							<img
								alt="No data"
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
							/>
						}
						headline={'No receipts found'}
					/>
				}
				collectionName="purchase_order_receipts"
				targetCollectionName={TC_NAME}
				columns={columns}
				showHeader={true}
				keyAccessor={(el) => el.id}
				initialPagination={initialPagination}
				additionalParams={{
					purchaseOrderId,
				}}
				fetchData={purchaseOrderReceiptsRestCrudThunksForSupplier.read}
			/>
			{editModalVisible && (
				<EditReceiptModal
					receipt={currentEditingRecord}
					onCancel={onCancel}
					onSuccess={onUpdateSuccess}
				/>
			)}
		</Card>
	);
};

const mapStateToProps = (state, ownProps) => ({
	purchaseOrder: getEntityById(ownProps.match.params.id, state.purchase_orders),
	purchaseOrderReceipts: state.purchase_order_receipts,
	match: ownProps.match,
	history: ownProps.history,
	location: ownProps.location,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	fetchReceipts: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			purchaseOrderReceiptsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderDetailReceiptsPage)
);

import React, { FC, useEffect, useMemo, useState } from 'react';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { Button, Card, Col, Drawer, Layout, Row, Table } from 'antd';
import MetersDetailDetailsPage from '../meters_detail_details_page/MetersDetailDetailsPage';
import SubnavBar from '../subnav_bar/SubnavBar';
import { Link } from 'react-router-dom';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import AssetDowntimeStatus from '../assets_detail_page/AssetDowntimeStatus';
import AssetActiveWorkOrdersCard from '../asset_page_display/AssetActiveWorkOrdersCard';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { EmptyState } from '../empty_state/EmptyState';
import {
	workOrdersRestCrudThunksForBuyer,
	workOrdersRestCrudThunksForSupplier,
} from '../../thunks/work_orders_thunks';
import { MeterRowDisplay } from '../meter_row_display/MeterRowDisplay';
import AssetTile from '../asset_status_form/AssetTile';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import AssetDetailsCard from '../asset_details_card/AssetDetailsCard';
import { TwoLineList } from '../two_line_list/TwoLineList';
import LocationCardDisplay from '../location_card_display/LocationCardDisplay';
import { WarrantyCardDisplay } from '../warranty_card_display/WarrantyCardDisplay';
import {
	getProtectedImageUriForBuyer,
	getProtectedImageUriForSupplier,
} from '../../utils/FileAccessUtils';
import { WorkOrderRowDisplay } from '../work_order_row_display/WorkOrderRowDisplay';
import { BUYER_ONLY_NO_STORE_ASSOCIATE } from '../roles/roles';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { assetDowntimeGet, assetsRestCrudThunksForBuyer } from '../../thunks/assets_thunks';
import {
	warrantiesRestCrudThunksForBuyer,
	warrantiesRestCrudThunksForSupplier,
} from '../../thunks/warranties_thunks';
import QRCode from 'qrcode.react';
import AssetDetailPageHeader from '../asset_detail_page_header/AssetDetailPageHeader';
import AssetInvoice from '../asset_invoice/AssetInvoice';
import { getBuyerCompanySettingValue } from '../../utils/AuthUtils';
import posthog from 'posthog-js';

const { Content } = Layout;

require('./AssetDetailPageDisplay.less');

const ASSET_ASSOCIATED_INACTIVE_WORK_ORDERS_TARGET_COLLECTION = 'assetAssociatedInActiveWorkOrders';

const AssetDetailPageDisplay: FC<any> = ({
	//From Parent
	asset,
	loading = false,
	isDrawer = false,
	hideDeletedDetails,

	//Mapped State to props
	history,
	warranty,
	companyConfig,
	currentUser,
	location,
	userType,

	//mapDispatchToProps
	getAssetDowntime,
	getWarranty,
	getAsset,
}): React.ReactElement => {
	const [meterDrawerVisible, setMeterDrawerVisible] = useState(false);
	const [meterId, setMeterId] = useState();
	const [downTimeHistory, setDownTimeHistory] = useState([]);

	const assetId = useMemo(() => nullSafeGet('id', asset), [asset]);

	const getAssetDetails = () => {
		if (assetId) {
			const warranty = nullSafeGet('warrantyIds.0', asset);
			getAssetDowntime({ assetId }, userType).then((data = []) => {
				setDownTimeHistory(data);
			});

			if (warranty) {
				getWarranty(warranty);
			}
		}
	};

	useEffect(() => {
		getAssetDetails();
	}, [assetId]);

	const handleMeterDrawerOpen = (id) => {
		setMeterDrawerVisible(true);
		setMeterId(id);
	};

	const handleMeterDrawerClose = () => {
		setMeterDrawerVisible(false);
		setMeterId(undefined);
	};

	const openDowntimeHistoryTab = () => {
		history.push(`/${userType}/assets/detail/${assetId}/downtimeHistory`);
	};

	const handleAssetStatusChangeSubmit = () => {
		getAsset(assetId).then(() => {
			getAssetDetails();
		});
	};

	let photoUris = [];
	if (asset.images) {
		photoUris = asset.images.map((locImg) => {
			const [imgFileId, imgFileName] = locImg.split('/');
			return userType === 'supplier'
				? getProtectedImageUriForSupplier(imgFileId, imgFileName, 1200, 800)
				: getProtectedImageUriForBuyer(imgFileId, imgFileName, 1200, 800);
		});
	}
	const workOrderColumns = [
		{
			title: 'Name',
			dataIndex: 'title',
			render: (text, record) => <WorkOrderRowDisplay workOrder={record} />,
		},
	];

	const onWorkOrderRow = (record) => ({
		onClick: () => history.push(`/${userType}/workOrders/detail/${record.id}`),
	});

	const onMeterRow = (record) => ({
		onClick: () => handleMeterDrawerOpen(record.id),
	});

	const newWorkOrderLink =
		currentUser.roles && currentUser.roles.some((role) => BUYER_ONLY_NO_STORE_ASSOCIATE.has(role))
			? 'newWorkOrder'
			: 'requestService';

	const allowArea = useMemo(
		() =>
			getBuyerCompanySettingValue(userType)(
				'assetConfig.allowAreaAssociationWithAssets',
				companyConfig,
				currentUser,
				false
			),
		[companyConfig, currentUser, userType]
	);

	const removeAssetNumberToQRCodeAssociation = useMemo(
		() =>
			getBuyerCompanySettingValue(userType)(
				'assetConfig.removeAssetNumberToQRCodeAssociation',
				companyConfig,
				currentUser,
				false
			),
		[companyConfig, currentUser, userType]
	);
	const skipCount = posthog.isFeatureEnabled('OptimiseWorkOrderAPIforLargeData');
	const canShowDowntimeDetailsAndLink = useMemo(
		() => (hideDeletedDetails ? !asset.isDeleted : true),
		[asset.isDeleted, hideDeletedDetails]
	);

	return loading ? (
		<PageLoadingPlaceholder />
	) : (
		<Content
			className="assetDetailPageDisplay"
			style={{
				padding: '0 0.5em',
			}}
		>
			<LogOnMountWithStandardEventProperties eventType="visited buyer asset detail page" />

			<Drawer
				width={1000}
				placement="right"
				closable={true}
				onClose={handleMeterDrawerClose}
				visible={meterDrawerVisible}
			>
				<MetersDetailDetailsPage id={meterId} />
			</Drawer>
			{isDrawer ? (
				<AssetDetailPageHeader
					asset={asset}
					photoUris={photoUris}
					userType={userType}
					hideLinkButton={!canShowDowntimeDetailsAndLink}
				/>
			) : null}
			{isDrawer ? null : (
				<Row style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<SubnavBar
							left={null}
							right={
								<div className="mb-2 flex flex-row items-center justify-end">
									<Link
										to={getLinkWIthBackLinkParams(
											location,
											'Back to Asset Detail',
											`/${userType}/assets/${assetId}/${newWorkOrderLink}`
										)}
									>
										<Button type="primary" size="large">
											Request Service
										</Button>
									</Link>
								</div>
							}
						/>
					</Col>
				</Row>
			)}
			<Row gutter={16}>
				<Col lg={24} xl={16}>
					{asset.description && asset.description.length > 0 ? (
						<div className="rowSpacing">
							<Card bodyStyle={{ padding: 0 }}>
								<div className="materialCard__body">
									<div className="materialCard__primaryTitle">
										<div className="materialCard__title">{asset.description}</div>
									</div>

									<div
										style={{ marginBottom: 24 }}
										className="richTextWrapper"
										dangerouslySetInnerHTML={{ __html: asset.notes }}
									/>
								</div>
							</Card>
						</div>
					) : null}
					{canShowDowntimeDetailsAndLink ? (
						<div className="rowSpacing">
							<AssetDowntimeStatus
								showDetailsButton
								downtimeHistory={downTimeHistory || []}
								onSeeDetail={openDowntimeHistoryTab}
							/>
						</div>
					) : null}
					<div className="rowSpacing">
						<AssetActiveWorkOrdersCard assetId={assetId} />
					</div>
					<div className="rowSpacing">
						<Card title="Work History">
							<PaginatedReduxTable
								showHeader={false}
								emptyState={
									<EmptyState
										graphic={
											<img
												alt={`There aren't any past work orders.`}
												style={{ marginBottom: 8 }}
												src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
											/>
										}
										headline={'No news is good news.'}
										body={
											<div style={{ textAlign: 'center' }}>
												<div style={{ maxWidth: 440, marginBottom: 16 }}>
													There aren't any past work orders.
												</div>
											</div>
										}
									/>
								}
								collectionName="work_orders"
								targetCollectionName={ASSET_ASSOCIATED_INACTIVE_WORK_ORDERS_TARGET_COLLECTION}
								columns={workOrderColumns}
								onRow={onWorkOrderRow}
								keyAccessor={(el) => el.id}
								initialFilters={{
									assetId: assetId,
									isActive: false,
									no_count: skipCount,
								}}
								initialPagination={{ current: 1, pageSize: 10 }}
								fetchData={
									userType === 'supplier'
										? workOrdersRestCrudThunksForSupplier.read
										: workOrdersRestCrudThunksForBuyer.read
								}
							/>
						</Card>
					</div>
					<div className="rowSpacing">
						<Card title="Meters">
							<Table
								dataSource={asset.meters}
								showHeader={false}
								pagination={false}
								onRow={onMeterRow}
								columns={[
									{
										title: 'ID',
										dataIndex: 'id',
										render: (text, record) => <MeterRowDisplay meter={record} />,
									},
								]}
							/>
						</Card>
					</div>
				</Col>
				<Col lg={24} xl={8}>
					{canShowDowntimeDetailsAndLink ? (
						<div className="rowSpacing">
							<AssetTile
								hideEditButton={isDrawer}
								downTimeHistory={downTimeHistory}
								userType={userType}
								asset={asset}
								onSuccess={handleAssetStatusChangeSubmit}
							/>
						</div>
					) : null}
					<div className="rowSpacing">
						<Card title="Asset Label" className="materialCard" bodyStyle={{ padding: 0 }}>
							<div className="materialCard__body" style={{ display: 'flex' }}>
								<div style={{ marginRight: 40 }}>
									{removeAssetNumberToQRCodeAssociation ? (
										asset.isUserAssignedUUID && asset.assetUUID && asset.assetUUID.length > 0 ? (
											<QRCode size={96} value={asset.assetUUID} />
										) : (
											<span>QR code is not assigned</span>
										)
									) : asset.assetUUID && asset.assetUUID.length > 0 ? (
										<QRCode size={96} value={asset.assetUUID} />
									) : null}
								</div>
								{!removeAssetNumberToQRCodeAssociation &&
								nullSafeGetOrElse('assetNumber', asset, '').trim() ? (
									<div>
										<div style={{ marginBottom: 8 }}>
											<h3>{asset.assetNumber}</h3>
										</div>
										<div className="twoLineList__secondLine">Asset Number</div>
									</div>
								) : null}
							</div>
						</Card>
					</div>
					<div className="rowSpacing">
						<AssetInvoice asset={asset} />
					</div>
					<AssetDetailsCard asset={asset} />
					{nullSafeGetOrElse('assetModel.specs', asset, []).length > 0 ? (
						<div className="rowSpacing">
							<Card title="Asset Model Specs" className="materialCard" bodyStyle={{ padding: 0 }}>
								<div className="materialCard__body">
									<TwoLineList
										listItems={nullSafeGet('assetModel.specs', asset)}
										lineOneRender={(record) => record.value || '--'}
										lineTwoRender={(record) => record.key}
									/>
								</div>
							</Card>
						</div>
					) : null}
					{allowArea && nullSafeGet('area', asset) ? (
						<div className="rowSpacing">
							<Card title="Area">{nullSafeGet('area.name', asset)}</Card>
						</div>
					) : null}
					{nullSafeGet('location', asset) ? (
						<div className="rowSpacing">
							<LocationCardDisplay location={asset.location} />
						</div>
					) : null}
					{asset.warrantyId ? (
						<div className="rowSpacing">
							<WarrantyCardDisplay warranty={warranty} />
						</div>
					) : null}
				</Col>
			</Row>
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	warrantiesFetching: state.warranties.fetching,
	warranty: nullSafeGet('warrantyIds.0', state.assets.detail) ? state.warranties.detail : undefined,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getWarranty: (id) =>
		dispatch(
			ownProps.userType === 'supplier'
				? warrantiesRestCrudThunksForSupplier.readOne(id)
				: warrantiesRestCrudThunksForBuyer.readOne(id)
		),
	getAssetDowntime: (params, userType) => dispatch(assetDowntimeGet(userType)(params)),
	getAsset: (id) => dispatch(assetsRestCrudThunksForBuyer.readOne(id)),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(AssetDetailPageDisplay)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import React, { FC, useMemo } from 'react';
import { connect } from 'react-redux';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';

export const isSupplierInventoryConfigEnabled = (config, name) =>
	nullSafeGetOrElse(`inventoryConfig.${name}`, config, false);

const SupplierInventoryConfigPermissionChecker: FC<any> = ({
	companyConfig,
	name,
	children,
	disableIfTurnedOn,
}) => {
	const isAllowed = useMemo(() => {
		const configValue = isSupplierInventoryConfigEnabled(companyConfig, name);
		return disableIfTurnedOn ? !configValue : configValue;
	}, [companyConfig, disableIfTurnedOn, name]);

	return isAllowed ? <>{children}</> : null;
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
	companyConfig: state.company_config.detail,
});

export default connect(mapStateToProps)(SupplierInventoryConfigPermissionChecker);

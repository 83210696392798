import React, { useCallback, useMemo } from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { getPurchaseOrderId, nullSafeGet, stopDefaultEvents } from '../../utils/DataAccessUtils';
import PurchaseRequestStatusDisplay from '../purchase_request_status_display/PurchaseRequestStatusDisplay';
import PartOrEquipmentAvatar from '../common/PartOrEquipmentAvatar';
import HyperLink from '../common/HyperLink';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

require('./EquipmentLineItemRowDisplay.less');

const EquipmentLineItemRowDisplay: React.FC<any> = ({ equipmentLineItem, history, location }) => {
	const equipmentType = equipmentLineItem.equipmentType;

	const purchaseOrder = useMemo(
		() => nullSafeGet('associatedPurchaseOrderLineItem.supplierPurchaseOrder', equipmentLineItem),
		[equipmentLineItem]
	);

	const openPurchaseOrder = useCallback(
		(e) => {
			stopDefaultEvents(e);
			const path = `/supplier/purchaseOrders/detail/${nullSafeGet('id', purchaseOrder)}/details`;
			history.push(getLinkWIthBackLinkParams(location, 'Back to purchase request', path));
		},
		[purchaseOrder, history, location]
	);

	return (
		<div className="equipmentLineItemRowDisplay" style={{ justifyContent: 'space-between' }}>
			<div className="equipmentLineItemRowDisplay">
				<div style={{ marginRight: 24 }}>
					<PartOrEquipmentAvatar item={equipmentType} />
				</div>
				<div>
					<div className="equipmentLineItemRowDisplay__rowTitle">
						<Ellipsis tooltip={true} length={56}>
							{equipmentType.modelName}
						</Ellipsis>
					</div>
					<div className="equipmentLineItemRowDisplay__rowSubtitle">
						{nullSafeGet('equipmentTypeNumber', equipmentType) && (
							<div className="equipmentLineItemRowDisplay__rowInlineGroup">
								Equipment Type # {equipmentType.equipmentTypeNumber}
							</div>
						)}
					</div>
					{nullSafeGet('manufacturerId', equipmentType) && (
						<div className="equipmentLineItemRowDisplay__rowSubtitle">
							<div className="equipmentLineItemRowDisplay__rowInlineGroup">
								Manufacturer ID: {equipmentType.manufacturerId}
							</div>
						</div>
					)}
					{nullSafeGet('description', equipmentType) && (
						<div className="equipmentLineItemRowDisplay__rowSubtitle">
							<div className="equipmentLineItemRowDisplay__rowInlineGroup">
								<Ellipsis tooltip={true} length={64}>
									{equipmentType.description}
								</Ellipsis>
							</div>
						</div>
					)}
					{purchaseOrder && (
						<div className="partLineItemRowDisplay__rowSubtitle" style={{ marginTop: '8px' }}>
							<HyperLink
								text={`PO ${getPurchaseOrderId(purchaseOrder)}`}
								onClick={openPurchaseOrder}
							/>
						</div>
					)}
					<div className="partLineItemRowDisplay__rowSubtitle" style={{ marginTop: '8px' }}>
						<PurchaseRequestStatusDisplay status={equipmentLineItem.status} />
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	location: ownProps.location,
});

export default withRouter<any, any>(connect(mapStateToProps)(EquipmentLineItemRowDisplay));

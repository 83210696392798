import { bulkArchiveWorkOrders } from '../../thunks/work_orders_thunks';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { isAdminUser, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { aggregateCounts, joinList } from '../../utils/DataFormatterUtils';
import { Button, Popconfirm, Popover } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import { BUYER_ADMIN, SUPER_ADMIN, SUPPLIER_ADMIN } from '../roles/roles';

function BulkArchiveButton({
	filters,
	counts,
	refreshTable,
	currentUser,

	bulkArchive,
}) {
	const displayStatuses = nullSafeGetOrElse('displayStatuses', filters, '').split(',');
	const workOrderAmount = useMemo(() => aggregateCounts(filters, counts), [filters, counts]);
	const archiveString = `Archives ${
		workOrderAmount > 1 ? 'these' : 'this'
	} ${workOrderAmount} ${joinList(
		displayStatuses.filter((s) => s === 'Completed' || s === 'Cancelled'),
		'&'
	).toLowerCase()} work order${workOrderAmount > 1 ? 's' : ''}.`;
	const isAdmin =
		currentUser.roles.includes(BUYER_ADMIN) ||
		currentUser.roles.includes(SUPER_ADMIN) ||
		currentUser.roles.includes(SUPPLIER_ADMIN);
	const showBulkArchive = useMemo(
		() =>
			isAdmin &&
			displayStatuses.every((s) => s === 'Completed' || s === 'Cancelled') &&
			workOrderAmount > 0 &&
			(filters.hasBuyerArchived === false || filters.hasSupplierArchived === false),
		[isAdmin, displayStatuses, workOrderAmount]
	);

	return showBulkArchive ? (
		<Popconfirm
			title={<div>{archiveString} Proceed?</div>}
			onConfirm={() =>
				bulkArchive({
					...filters,
					hasBuyerArchived: undefined,
					hasSupplierArchived: undefined,
				}).then(() => {
					refreshTable();
				})
			}
			okText={'Archive'}
		>
			<Popover content={archiveString}>
				<Button size="large" style={{ color: '#FF0000' }} icon={<InboxOutlined translate="" />}>
					<span className="inline-block-visible-xxl">Archive&nbsp;All</span>
				</Button>
			</Popover>
		</Popconfirm>
	) : null;
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	bulkArchive: (filters) => dispatch(bulkArchiveWorkOrders(ownProps.userType)(filters)),
});

const BulkArchiveComp = withRouter(connect(mapStateToProps, mapDispatchToProps)(BulkArchiveButton));

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	(dispatch, ownProps) => ({})
)(BulkArchiveComp);

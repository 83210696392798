export const CLIENTS = {
	OPEN_WRENCH_FACILITIES_MOBILE_CLIENT: 'OPEN_WRENCH_FACILITIES_MOBILE_CLIENT',
	OPEN_WRENCH_SERVICE_MANAGER_MOBILE_CLIENT: 'OPEN_WRENCH_SERVICE_MANAGER_MOBILE_CLIENT',
	OPEN_WRENCH_WEB_CLIENT: 'OPEN_WRENCH_WEB_CLIENT',
};
export const CLIENT_TYPES = {
	MOBILE: 'MOBILE',
	WEB: 'WEB',
};
export const ROLE_TYPES = {
	BUYER: 'BUYER',
	SUPPLIER: 'SUPPLIER',
};

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Card } from 'antd';
import { createInvoiceBasedOnWorkOrder } from '../../thunks/invoices_thunks';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import SupplierSimpleInvoiceForm from '../supplier_simple_invoice_form/SupplierSimpleInvoiceForm';

const queryString = require('qs');
const { Content } = Layout;

class NewSupplierSimpleInvoicePage extends React.Component<any, any> {
	state = {
		invoiceFormData: {},
		invoiceFormDataLoading: true,
	};

	componentDidMount() {
		const { createInvoiceWithWorkOrderId, match, location, saveFormData } = this.props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}

		const queryParams = queryString.parse(searchString);
		const workOrderId = queryParams.workOrderId || match.params.id;

		createInvoiceWithWorkOrderId(workOrderId, {}).then((invoice) => {
			this.setState({ invoiceFormData: { ...invoice }, invoiceFormDataLoading: false });
		});
	}

	render() {
		const { history, invoicesFormData } = this.props;

		return this.state.invoiceFormDataLoading ? (
			<PageLoadingPlaceholder />
		) : (
			<Content>
				<LogOnMountWithStandardEventProperties eventType="visited supplier new invoice page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<Card>
							<div>
								<h5 style={{ marginBottom: 24 }}>Create Invoice</h5>
								<SupplierSimpleInvoiceForm
									formData={this.state.invoiceFormData}
									onSuccess={(rec) =>
										history.push(
											`/supplier/workOrders/detail/${rec.workOrderId}/invoices/uploadPDF`
										)
									}
								/>
							</div>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	createInvoiceWithWorkOrderId: (workOrderId, entity) =>
		dispatch(createInvoiceBasedOnWorkOrder(workOrderId)(entity)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(NewSupplierSimpleInvoicePage)
);

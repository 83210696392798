//Templates for generating standard columns, via dynamic generated filters
import { ContactAvatar } from '../contact_avatar/ContactAvatar';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import * as React from 'react';
import { fetchFacilityActiveUsers } from '../../thunks/users_thunks';

const default_columns_enum = {
	Company: {
		filterList: [
			{
				object: 'Company',
				name: 'Name',
				datapath: 'location.buyerCompany',
				displayAs: 'list',
			},
		],
		filterSearch: true,
	},
	Location: {
		filterList: [
			{
				object: 'Facility',
				name: 'Name',
				datapath: 'location',
				displayAs: 'list',
			} /*
            {
                object: 'Facility',
                name: 'State',
                datapath:  'buyerFacility',
                displayAs: 'list'
            },*/,
		],
		filterSearch: true,
	},
	'Customer Contact': {
		filterList: [
			{
				object: 'Contact', //filters_enum[object]
				name: 'Users', //filters_enum[object][name] <- Just for organization
				datapath: 'location.facilityInChargeContacts', //path from record to the data object
				displayAs: 'list',
			},
		],
		filterSearch: true,
		array: true,
	},
	Dispatcher: {
		filterList: [
			{
				object: 'Contact', //filters_enum[object]
				name: 'Users', //filters_enum[object][name] <- Just for organization
				datapath: 'dispatcherContact', //path from record to the data object
				displayAs: 'list',
				displayOption: true, //This is the option that will be displayed in the options menu
			} /*{
                object: 'Contact',
                name: 'Roles',
                datapath:'dispatcherContact',
                displayAs: 'list'
            }*/,
			,
		],
		filterSearch: true,
	},
	'Primary Technician': {
		filterList: [
			{
				object: 'Contact', //filters_enum[object]
				name: 'Users', //filters_enum[object][name] <- Just for organization
				datapath: 'primaryTechContact', //path from record to the data object
				displayAs: 'list',
				displayOption: true,
			},
		],
		filterSearch: true,
		render: (text, record) =>
			record.primaryTechContact ? (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<ContactAvatar
						hidePopover={true}
						style={{ marginRight: 16 }}
						contact={record.primaryTechContact}
						userType={ROLE_TYPES.SUPPLIER}
					/>
					<div>
						<div>
							{nullSafeGet('primaryTechContact.nameGiven', record)}{' '}
							{nullSafeGet('primaryTechContact.nameFamily', record)}
						</div>
					</div>
				</div>
			) : (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					{nullSafeGetOrElse('primaryTech', record, '--')}
				</div>
			),
	},
	CCs: {
		filterList: [
			{
				object: 'Contact', //filters_enum[object]
				name: 'Users', //filters_enum[object][name] <- Just for organization
				datapath: 'ccListContacts', //path from record to the data object
				displayAs: 'list',
				displayOption: true,
			},
		],
		filterSearch: true,
		array: true,
	},
};

export default default_columns_enum;

import {
	CLEAR_KPI_VENDOR_FILTERS,
	FETCH_ALL_MONTHLY_KPIS_FAILURE,
	FETCH_ALL_MONTHLY_KPIS_START,
	FETCH_ALL_MONTHLY_KPIS_SUCCESS,
	FETCH_CHECK_IN_COMPLIANCE_KPI_BY_MONTH_FAILURE,
	FETCH_CHECK_IN_COMPLIANCE_KPI_BY_MONTH_START,
	FETCH_CHECK_IN_COMPLIANCE_KPI_BY_MONTH_SUCCESS,
	FETCH_TIME_TO_ACCEPT_KPI_BY_MONTH_START,
	FETCH_TIME_TO_ACCEPT_KPI_BY_MONTH_SUCCESS,
	FETCH_TIME_TO_ACCEPT_KPI_BY_MONTH_FAILURE,
	FETCH_TIME_TO_SCHEDULE_KPI_BY_MONTH_START,
	FETCH_TIME_TO_SCHEDULE_KPI_BY_MONTH_SUCCESS,
	FETCH_TIME_TO_SCHEDULE_KPI_BY_MONTH_FAILURE,
	FETCH_FIRST_TIME_RESOLUTION_KPI_BY_MONTH_FAILURE,
	FETCH_FIRST_TIME_RESOLUTION_KPI_BY_MONTH_START,
	FETCH_FIRST_TIME_RESOLUTION_KPI_BY_MONTH_SUCCESS,
	FETCH_ON_TIME_ARRIVAL_KPI_BY_MONTH_FAILURE,
	FETCH_ON_TIME_ARRIVAL_KPI_BY_MONTH_START,
	FETCH_ON_TIME_ARRIVAL_KPI_BY_MONTH_SUCCESS,
	FETCH_TIMELY_INVOICING_KPI_BY_MONTH_FAILURE,
	FETCH_TIMELY_INVOICING_KPI_BY_MONTH_START,
	FETCH_TIMELY_INVOICING_KPI_BY_MONTH_SUCCESS,
	FETCH_TIMELY_WORK_COMPLETION_KPI_BY_MONTH_FAILURE,
	FETCH_TIMELY_WORK_COMPLETION_KPI_BY_MONTH_START,
	FETCH_TIMELY_WORK_COMPLETION_KPI_BY_MONTH_SUCCESS,
	FETCH_WORK_ORDERS_KPI_BY_MONTH_FAILURE,
	FETCH_WORK_ORDERS_KPI_BY_MONTH_START,
	FETCH_WORK_ORDERS_KPI_BY_MONTH_SUCCESS,
	UPDATE_KPI_VENDOR_FILTERS,
} from '../actions/kpi_analytics_actions';
import { KPIAnalyticsStoreState } from '../types/models';

const initialState: KPIAnalyticsStoreState = {
	workOrdersByMonth: { data: [], loading: false, errors: [] },
	timelyWorkCompletionByMonth: { data: [], loading: false, errors: [] },
	onTimeArrivalByMonth: { data: [], loading: false, errors: [] },
	checkInComplianceByMonth: { data: [], loading: false, errors: [] },
	timeToAcceptByMonth: { data: [], loading: false, errors: [] },
	timeToScheduleByMonth: { data: [], loading: false, errors: [] },
	firstTimeResolutionByMonth: { data: [], loading: false, errors: [] },
	timelyInvoicingByMonth: { data: [], loading: false, errors: [] },
	monthlyKPIsBySupplier: { loading: false, errors: [] },
	kpiVendorFilters: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (oldState = initialState, action): KPIAnalyticsStoreState => {
	Object.freeze(oldState);
	switch (action.type) {
		case FETCH_ALL_MONTHLY_KPIS_START:
			return {
				...oldState,
				monthlyKPIsBySupplier: {
					...oldState.monthlyKPIsBySupplier,
					loading: true,
				},
			};
		case FETCH_ALL_MONTHLY_KPIS_FAILURE:
			return {
				...oldState,
				monthlyKPIsBySupplier: {
					...oldState.monthlyKPIsBySupplier,
					loading: false,
					errors: [...oldState.monthlyKPIsBySupplier.errors, ...action.errors],
				},
			};
		case FETCH_ALL_MONTHLY_KPIS_SUCCESS:
			return {
				...oldState,
				monthlyKPIsBySupplier: {
					...oldState.monthlyKPIsBySupplier,
					...action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_WORK_ORDERS_KPI_BY_MONTH_START:
			return {
				...oldState,
				workOrdersByMonth: {
					...oldState.workOrdersByMonth,
					loading: true,
				},
			};
		case FETCH_WORK_ORDERS_KPI_BY_MONTH_FAILURE:
			return {
				...oldState,
				workOrdersByMonth: {
					...oldState.workOrdersByMonth,
					loading: false,
					errors: [...oldState.workOrdersByMonth.errors, ...action.errors],
				},
			};
		case FETCH_WORK_ORDERS_KPI_BY_MONTH_SUCCESS:
			return {
				...oldState,
				workOrdersByMonth: {
					...oldState.workOrdersByMonth,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_TIMELY_WORK_COMPLETION_KPI_BY_MONTH_START:
			return {
				...oldState,
				timelyWorkCompletionByMonth: {
					...oldState.timelyWorkCompletionByMonth,
					loading: true,
				},
			};
		case FETCH_TIMELY_WORK_COMPLETION_KPI_BY_MONTH_FAILURE:
			return {
				...oldState,
				timelyWorkCompletionByMonth: {
					...oldState.timelyWorkCompletionByMonth,
					loading: false,
					errors: [...oldState.timelyWorkCompletionByMonth.errors, ...action.errors],
				},
			};
		case FETCH_TIMELY_WORK_COMPLETION_KPI_BY_MONTH_SUCCESS:
			return {
				...oldState,
				timelyWorkCompletionByMonth: {
					...oldState.timelyWorkCompletionByMonth,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_ON_TIME_ARRIVAL_KPI_BY_MONTH_START:
			return {
				...oldState,
				onTimeArrivalByMonth: {
					...oldState.onTimeArrivalByMonth,
					loading: true,
				},
			};
		case FETCH_ON_TIME_ARRIVAL_KPI_BY_MONTH_FAILURE:
			return {
				...oldState,
				onTimeArrivalByMonth: {
					...oldState.onTimeArrivalByMonth,
					loading: false,
					errors: [...oldState.onTimeArrivalByMonth.errors, ...action.errors],
				},
			};
		case FETCH_ON_TIME_ARRIVAL_KPI_BY_MONTH_SUCCESS:
			return {
				...oldState,
				onTimeArrivalByMonth: {
					...oldState.onTimeArrivalByMonth,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_CHECK_IN_COMPLIANCE_KPI_BY_MONTH_START:
			return {
				...oldState,
				checkInComplianceByMonth: {
					...oldState.checkInComplianceByMonth,
					loading: true,
				},
			};
		case FETCH_CHECK_IN_COMPLIANCE_KPI_BY_MONTH_FAILURE:
			return {
				...oldState,
				checkInComplianceByMonth: {
					...oldState.checkInComplianceByMonth,
					loading: false,
					errors: [...oldState.checkInComplianceByMonth.errors, ...action.errors],
				},
			};
		case FETCH_CHECK_IN_COMPLIANCE_KPI_BY_MONTH_SUCCESS:
			return {
				...oldState,
				checkInComplianceByMonth: {
					...oldState.checkInComplianceByMonth,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_TIME_TO_ACCEPT_KPI_BY_MONTH_START:
			return {
				...oldState,
				timeToAcceptByMonth: {
					...oldState.timeToAcceptByMonth,
					loading: true,
				},
			};
		case FETCH_TIME_TO_ACCEPT_KPI_BY_MONTH_FAILURE:
			return {
				...oldState,
				timeToAcceptByMonth: {
					...oldState.timeToAcceptByMonth,
					loading: false,
					errors: [...oldState.timeToAcceptByMonth.errors, ...action.errors],
				},
			};
		case FETCH_TIME_TO_ACCEPT_KPI_BY_MONTH_SUCCESS:
			return {
				...oldState,
				timeToAcceptByMonth: {
					...oldState.timeToAcceptByMonth,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_TIME_TO_SCHEDULE_KPI_BY_MONTH_START:
			return {
				...oldState,
				timeToScheduleByMonth: {
					...oldState.timeToScheduleByMonth,
					loading: true,
				},
			};
		case FETCH_TIME_TO_SCHEDULE_KPI_BY_MONTH_FAILURE:
			return {
				...oldState,
				timeToScheduleByMonth: {
					...oldState.timeToScheduleByMonth,
					loading: false,
					errors: [...oldState.timeToScheduleByMonth.errors, ...action.errors],
				},
			};
		case FETCH_TIME_TO_SCHEDULE_KPI_BY_MONTH_SUCCESS:
			return {
				...oldState,
				timeToScheduleByMonth: {
					...oldState.timeToScheduleByMonth,
					data: action.data,
					loading: false,
					errors: [],
				},
			};

		case FETCH_FIRST_TIME_RESOLUTION_KPI_BY_MONTH_START:
			return {
				...oldState,
				firstTimeResolutionByMonth: {
					...oldState.firstTimeResolutionByMonth,
					loading: true,
				},
			};
		case FETCH_FIRST_TIME_RESOLUTION_KPI_BY_MONTH_FAILURE:
			return {
				...oldState,
				firstTimeResolutionByMonth: {
					...oldState.firstTimeResolutionByMonth,
					loading: false,
					errors: [...oldState.firstTimeResolutionByMonth.errors, ...action.errors],
				},
			};
		case FETCH_FIRST_TIME_RESOLUTION_KPI_BY_MONTH_SUCCESS:
			return {
				...oldState,
				firstTimeResolutionByMonth: {
					...oldState.firstTimeResolutionByMonth,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case FETCH_TIMELY_INVOICING_KPI_BY_MONTH_START:
			return {
				...oldState,
				timelyInvoicingByMonth: {
					...oldState.timelyInvoicingByMonth,
					loading: true,
				},
			};
		case FETCH_TIMELY_INVOICING_KPI_BY_MONTH_FAILURE:
			return {
				...oldState,
				timelyInvoicingByMonth: {
					...oldState.timelyInvoicingByMonth,
					loading: false,
					errors: [...oldState.timelyInvoicingByMonth.errors, ...action.errors],
				},
			};
		case FETCH_TIMELY_INVOICING_KPI_BY_MONTH_SUCCESS:
			return {
				...oldState,
				timelyInvoicingByMonth: {
					...oldState.timelyInvoicingByMonth,
					data: action.data,
					loading: false,
					errors: [],
				},
			};
		case UPDATE_KPI_VENDOR_FILTERS:
			return {
				...oldState,
				kpiVendorFilters: {
					...oldState.kpiVendorFilters,
					...action.filters,
				},
			};
		case CLEAR_KPI_VENDOR_FILTERS:
			return {
				...oldState,
				kpiVendorFilters: {},
			};
		default:
			return oldState;
	}
};

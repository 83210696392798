import * as React from 'react';
import { BLACKS_AND_WHITES } from '../../utils/DataFormatterUtils';
import {
	PURCHASE_REQUEST_STATUS_DISPLAY_BACKGROUND_COLORS,
	PURCHASE_REQUEST_STATUS_DISPLAY_TEXT_COLORS,
	PURCHASE_REQUEST_STATUSES,
} from '../../constants/PurchaseRequestStatuses';
import { PURCHASE_REQUEST_STATUS_DISPLAY_NAMES } from '../../constants/PurchaseRequestStatuses';

export default ({ status, darkMode = false, style = {}, textStyle = {}, ...props }) => {
	if (!Object.keys(PURCHASE_REQUEST_STATUSES).includes(status)) {
		return null;
	}
	let statusColor = PURCHASE_REQUEST_STATUS_DISPLAY_TEXT_COLORS[status];
	let statusDisplayName = PURCHASE_REQUEST_STATUS_DISPLAY_NAMES[status];
	let statusBackgroundColor = PURCHASE_REQUEST_STATUS_DISPLAY_BACKGROUND_COLORS[status];

	// statusColor = BLACKS_AND_WHITES.blackPrimary;
	// statusBackgroundColor = '#f2f2f2';

	if (darkMode) {
		statusColor = BLACKS_AND_WHITES.whiteSecondary;
	}

	return (
		<span
			{...props}
			style={{
				color: statusColor,
				background: statusBackgroundColor,
				padding: '4px 12px',
				borderRadius: 16,
				...style,
				...textStyle,
			}}
		>
			{statusDisplayName}
		</span>
	);
};

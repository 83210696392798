import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { partCatalogsRestCrudThunksForSupplier } from '../../thunks/part_catalogs_thunks';

const PartCatalogDetailsPage: FC<any> = ({
	companyConfig,
	fetching,
	partCatalog,
	childRoutes,
	history,
	match,
	getPartCatalog,
}): React.ReactElement => {
	const tabs = useMemo(
		() => [
			{
				key: 'details',
				name: 'Overview',
			},
			{
				key: 'purchaseOrders',
				name: 'Purchase Orders',
			},
		],
		[]
	);

	const pcId = useMemo(() => match.params.id, [match.params.id]);

	useEffect(() => pcId && getPartCatalog(pcId), [pcId, getPartCatalog]);

	const handleTabChange = useCallback(
		(key) => {
			const pcId = match.params.id;
			history.push(`/supplier/parts/partCatalogs/detail/${pcId}/${key}`);
		},
		[history, match.params.id]
	);

	const checkTabActive = useCallback(
		(key) => {
			const pathname = history.location.pathname;
			return pathname.indexOf(key) !== -1;
		},
		[history.location.pathname]
	);

	const title = useMemo(
		() =>
			`${nullSafeGetOrElse('part.name', partCatalog, '')} (${nullSafeGetOrElse(
				'partEquipmentVendor.name',
				partCatalog,
				''
			)})`,
		[partCatalog]
	);

	return (
		<DetailPageHeader
			exactPath={'/supplier/parts/partCatalogs/detail/:id'}
			redirectPath={`/supplier/parts/partCatalogs/detail/${pcId}/details`}
			backLinkText={'Back to Part Catalogs'}
			backLinkTo={`/supplier/parts/overview/priceList`}
			title={fetching ? null : title}
			subtitle={null}
			actions={null}
			tabs={tabs}
			backgroundColor={nullSafeGet('detail.config.theme.navblock', companyConfig)}
			handleTabChange={handleTabChange}
			checkTabActive={checkTabActive}
			childRoutes={childRoutes}
		/>
	);
};

const mapStateToProps = (state, ownProps) => ({
	companyConfig: state.company_config,
	fetching: state.part_catalogs.fetching,
	partCatalog: state.part_catalogs.detail,
});

const mapDispatchToProps = (dispatch) => ({
	getPartCatalog: (id) => dispatch(partCatalogsRestCrudThunksForSupplier.readOne(id)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(PartCatalogDetailsPage)
);

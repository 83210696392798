import { Button, Pagination } from 'antd';
import React, { useMemo } from 'react';
import { ROLE_TYPES } from '../../../utils/DataConstants';
import { getDefaultWorkOrderTab } from '../../../utils/HistoryUtils';
import { defaultPagination } from '../config/pagination/pagination';

export default function TableFooter({ updatePagination, pagination, userType }) {
	if (!pagination.hasOwnProperty('total') || pagination.total === undefined) {
		return (
			<div>
				<CustomPagination pagination={pagination} updatePagination={updatePagination} />
			</div>
		);
	}
	return (
		<div>
			<Pagination
				{...defaultPagination}
				{...pagination}
				onChange={(current, pageSize) => updatePagination({ current, pageSize })}
			/>
		</div>
	);
}

export const CustomPagination = ({ updatePagination, pagination }) => {
	const { current, pageSize } = pagination;
	const handlePrevious = () => {
		if (current > 1) {
			updatePagination({ current: current - 1, pageSize });
		}
	};

	const handleNext = () => {
		updatePagination({ current: current + 1, pageSize });
	};

	return (
		<div
			style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '16px' }}
		>
			<Button style={{ marginRight: '8px' }} onClick={handlePrevious} disabled={current <= 1}>
				Previous
			</Button>
			<span>Page {current}</span>
			<Button style={{ marginLeft: '8px' }} onClick={handleNext}>
				Next
			</Button>
		</div>
	);
};

export function ShowAllFooterWorkOrders({
	updatePagination,
	pagination,
	filters,
	sorters,
	location,
	history,
	openMoreInDefault,
	userType,
}) {
	let f = '';
	if (filters || sorters) {
		const allParms = { ...(filters || {}), ...(sorters || {}) };
		let filterArray = Object.keys(allParms).map((key, i) => key.concat('=', allParms[key]));
		if (filterArray.length > 1) {
			f = f.concat(filterArray.reduce((s1, s2) => s1.concat('&', s2)));
		} else if (filterArray.length === 1) {
			f = filterArray[0];
		}
	}

	let openMoreUrl = (
		openMoreInDefault
			? `/${userType}/workOrders/overview/${getDefaultWorkOrderTab(userType, filters)}`
			: `${location.pathname.slice(0, location.pathname.lastIndexOf('/'))}/workOrders`
	).concat('?', f);
	return (
		<div style={{ width: '100%', padding: '8px' }}>
			{pagination.total > 3 ? (
				<Button
					onClick={() => history.push(openMoreUrl)}
					type="link"
					style={{
						width: '100%',
						borderBottomLeftRadius: 4,
						borderBottomRightRadius: 4,
						padding: 12,
						height: 40,
						borderTop: '1px solid #e8e8e8',
						textAlign: 'center',
					}}
				>
					See all
				</Button>
			) : (
				<div />
			)}
		</div>
	);
}

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, Button, message } from 'antd';
import BackButton from '../back_button/BackButton';
import * as React from 'react';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import {
	locationsRestCrudThunksForBuyer,
	locationsRestCrudThunksForSupplier,
} from '../../thunks/locations_thunks';
import {
	spendCategoriesRestCrudThunksForBuyer,
	spendCategoriesRestCrudThunksForSupplier,
} from '../../thunks/spend_categories_thunks';
import {
	problemTypesHeirarchicalForBuyer,
	problemTypesHeirarchicalForSupplier,
	problemTypesRestCrudThunksForBuyer,
	problemTypesRestCrudThunksForSupplier,
} from '../../thunks/problem_types_thunks';
import {
	workOrderAttributesRestCrudThunksForBuyer,
	workOrderAttributesRestCrudThunksForSupplier,
} from '../../thunks/work_order_attributes_thunks';
import {
	workOrderPrioritiesRestCrudThunksForBuyer,
	workOrderPrioritiesRestCrudThunksForSupplier,
} from '../../thunks/work_order_priorities_thunks';

import {
	plannedMaintenanceTemplatesRestCrudThunksForBuyer,
	plannedMaintenanceTemplatesRestCrudThunksForSupplier,
} from '../../thunks/planned_maintenance_templates_thunks';
import { debounce } from '../../utils/PerformanceUtils';
import {
	fetchSupplierFacilitiesInPrivateNetworkForBuyer,
	fetchSupplierFacilitiesInPrivateNetworkForSupplier,
	supplierFacilitiesRestCrudThunksForBuyer,
	supplierFacilitiesRestCrudThunksForSupplier,
} from '../../thunks/supplier_facilities_thunks';
import ProblemTypeTreeSelect from '../problem_type_tree_select';
import { getBuyerCompanyApprovalTiers } from '../../thunks/company_approval_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { BUYER_ADMIN_ONLY } from '../roles/roles';
import _ from 'lodash';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import OWRichTextEditor from '../../rich_text_editor/OWRichTextEditor';
import FileUploader from '../file_uploader/FileUploader';

const PLANNED_MAINTENANCE_TEMPLATE_FORM_PROBLEM_TYPES_AUTOCOMPLETE =
	'PLANNED_MAINTENANCE_TEMPLATE_FORM_PROBLEM_TYPES_AUTOCOMPLETE';
const PLANNED_MAINTENANCE_TEMPLATE_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE =
	'PLANNED_MAINTENANCE_TEMPLATE_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE';

interface WorkOrderFormProps extends FormComponentProps {
	history: any;

	onSubmit: any;
	onCancel: any;
	redirectForwardUrl?: string;
	onSuccess?: any;
	creating: boolean;
	updating: boolean;
	formData: any;
	createErrors: any[];
	updateErrors: any[];
	fetchMultipleSpendCategories: any;
	fetchSpendCategories: any;
	spendCategories: any;
	fetchMultipleSupplierFacilitiesInPrivateNetwork: any;
	fetchSupplierFacilitiesInPrivateNetwork: any;
	supplierFacilities: any;
	fetchMultipleProblemTypes: any;
	fetchProblemType: any;
	fetchProblemTypes: any;
	problemTypes: any;
	fetchWorkOrderAttributes: any;
	fetchMultipleWorkOrderPriorities: any;
	fetchWorkOrderPriorities: any;
	workOrderPriorities: any;
	currentUser: any;
	update: any;
	create: any;
	fetchApprovalHeirarchy: any;
	userType: any;
}

const FORM_FIELD_WIDTH_STYLE = { maxWidth: 600 };

class PlannedMaintenanceTemplateForm extends React.Component<WorkOrderFormProps, any> {
	constructor(props) {
		super(props);
		this.state = {
			nte: null,
			spendCategoryId: null,
			approvalHeirarchy: {},
			editorRef: React.createRef(),
		};
	}

	componentDidMount() {
		const { currentUser, fetchApprovalHeirarchy } = this.props;
		fetchApprovalHeirarchy().then((data) => {
			const tiers = nullSafeGet('0.tierDetails', data);
			if (tiers && tiers.length > 0) {
				const userTier =
					_.find(tiers, (item) => _.find(item.approvers, { email: currentUser.email })) || tiers[0];
				this.setState({
					approvalHeirarchy: { amount: userTier.amountUpto || 1000000000 },
				});
			}
		});
	}

	handleDescriptionChange = debounce(
		(description) => {
			const { form } = this.props;
			form.setFieldsValue({ description });
		},
		500,
		false
	);

	handleNTEValueChangeDebounced = () =>
		debounce(
			(nte) => {
				this.setState({ nte: nte });
			},
			500,
			false
		);

	handleNTEValueChange = (e) => {
		const inputString = e.target.value;
		this.handleNTEValueChangeDebounced()(inputString);
	};

	handleSpendCategoryChange = (spendCategoryId) => {
		const { fetchWorkOrderAttributes } = this.props;
		if (spendCategoryId) {
			fetchWorkOrderAttributes({
				spendCategoryId: spendCategoryId,
			}).then((workOrderAttributes) => {
				if (workOrderAttributes.length > 0) {
					this.setState({
						nte: workOrderAttributes[0].NTEAmount,
					});
				}
			});
		}
	};

	handleProblemTypeChange = (problemTypeId) => {
		const { fetchWorkOrderAttributes, fetchProblemType } = this.props;
		if (problemTypeId) {
			fetchProblemType(problemTypeId).then((problemType) => {
				this.setState({
					spendCategoryId: problemType.spendCategoryId,
				});
				fetchWorkOrderAttributes({
					problemTypeId: problemTypeId,
				}).then((workOrderAttributes) => {
					if (workOrderAttributes.length > 0) {
						this.setState({
							nte: workOrderAttributes[0].NTEAmount,
						});
					}
				});
			});
		}
	};

	getDescription = () =>
		nullSafeGet('state.editorRef.current', this) && this.state.editorRef.current.getContent();

	handleSubmit = (e, submitAction) => {
		const { form, history, redirectForwardUrl, onSuccess } = this.props;
		e.preventDefault();
		form.validateFields((err, values) => {
			if (!err) {
				const plannedMaintenanceTemplate = {
					id: values.id,
					title: values.title,
					templateId: values.templateId,
					description: this.getDescription(),
					workOrderPriorityId: parseInt(values.workOrderPriorityId, 10),
					nte: values.nte ? parseFloat(values.nte) : undefined,
					problemTypeId: parseInt(values.problemTypeId, 10),
					...(values.supplierFacilityId && {
						supplierFacilityId: parseInt(values.supplierFacilityId, 10),
					}),
					spendCategoryId: this.state.spendCategoryId
						? parseInt(this.state.spendCategoryId, 10)
						: undefined,
					supplierContractId: values.supplierContractId,
					supplierContractDocuments: values.supplierContractDocuments,
				};

				submitAction(plannedMaintenanceTemplate)
					.then((record) => {
						if (redirectForwardUrl) {
							history.push(redirectForwardUrl);
						}
						if (onSuccess) {
							onSuccess(record);
						}
					})
					.catch((err) => message.error(err));
			}
		});
	};

	handleFileManualUpload = (supplierContractDocuments) => {
		const { form } = this.props;
		form.setFieldsValue({ supplierContractDocuments });
	};

	handleFileListChange = (info) => {
		if (info.file.status === undefined) {
			info.fileList.splice(-1, 1);
		}
		return info;
	};

	render() {
		const {
			creating,
			updating,
			create,
			update,
			form,
			formData,
			fetchSupplierFacilitiesInPrivateNetwork,
			fetchMultipleSupplierFacilitiesInPrivateNetwork,
			supplierFacilities,
			problemTypes,
			workOrderPriorities,
			fetchProblemTypes,
			fetchMultipleProblemTypes,
			fetchWorkOrderPriorities,
			fetchMultipleWorkOrderPriorities,
			currentUser,
			userType,
		} = this.props;
		const { getFieldDecorator } = form;
		const isUpdate = formData && formData.id !== undefined;
		const loading = creating || updating;
		const submitAction = isUpdate ? update : create;
		const submitText = isUpdate ? 'Update template' : 'Create template';

		getFieldDecorator('id', { initialValue: formData.id });
		getFieldDecorator('description', { initialValue: formData.description });
		getFieldDecorator('supplierContractDocuments', {
			initialValue: formData.supplierContractDocuments || [],
		});

		const isAdmin = currentUser.roles.some((role) => BUYER_ADMIN_ONLY.has(role));
		const { approvalHeirarchy } = this.state;

		return (
			<Form
				layout="vertical"
				onSubmit={(e) => this.handleSubmit(e, submitAction)}
				className="supplierForm"
				style={FORM_FIELD_WIDTH_STYLE}
			>
				<Form.Item label="Title">
					{getFieldDecorator('title', {
						initialValue: formData.title,
						rules: [{ required: true, message: 'Please give this template a name.' }],
					})(<Input />)}
				</Form.Item>
				<Form.Item label="Template ID">
					{getFieldDecorator('templateId', {
						initialValue: formData.templateId,
					})(<Input />)}
				</Form.Item>
				<Form.Item label="Description">
					<div
						style={{
							padding: '8px 16px',
							border: '1px solid #d9d9d9',
							borderRadius: 4,
							overflow: 'hidden',
							...FORM_FIELD_WIDTH_STYLE,
						}}
					>
						<OWRichTextEditor
							initialEditorState={formData.description}
							editorRef={this.state.editorRef}
						/>
					</div>
				</Form.Item>

				<Form.Item required={true} label="Problem Type">
					{getFieldDecorator('problemTypeId', {
						initialValue: formData.problemTypeId,
						rules: [{ required: true, message: 'This field is required.' }],
					})(
						<ProblemTypeTreeSelect
							mode="default"
							placeholder="Type to search for problem type"
							stateSlice={problemTypes}
							targetCollectionName={PLANNED_MAINTENANCE_TEMPLATE_FORM_PROBLEM_TYPES_AUTOCOMPLETE}
							onChange={this.handleProblemTypeChange}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleProblemTypes(ids, targetCollectionName);
							}}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) =>
								fetchProblemTypes(
									{ name: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								)
							}
							renderRecord={(d) => (
								<Select.Option value={d.id} key={d.id}>
									<div>{d.hierarchyName}</div>
								</Select.Option>
							)}
							sortBy={{ sort_by: 'name', order: 'ascend' }}
						/>
					)}
				</Form.Item>
				<Form.Item label="Priority">
					{getFieldDecorator('workOrderPriorityId', {
						initialValue: formData.workOrderPriorityId,
						rules: [{ required: true, message: 'Please select a priority.' }],
					})(
						<OWAsyncSelect
							stateSlice={workOrderPriorities}
							targetCollectionName={
								PLANNED_MAINTENANCE_TEMPLATE_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE
							}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleWorkOrderPriorities(ids, targetCollectionName);
							}}
							fetchData={(searchText, targetCollectionName) => {
								fetchWorkOrderPriorities({ name: searchText }, targetCollectionName);
							}}
							renderRecord={(workOrderPriority) => (
								<Select.Option key={workOrderPriority.id} value={workOrderPriority.id}>
									{workOrderPriority.name}
								</Select.Option>
							)}
						/>
					)}
				</Form.Item>
				<Form.Item
					label="Not To Exceed Amount"
					validateStatus={approvalHeirarchy.status}
					help={
						approvalHeirarchy.status
							? `This NTE change bypasses the requirement of a quote. Based on approval hierarchy, you can set NTE upto $${approvalHeirarchy.amount}`
							: ''
					}
				>
					{getFieldDecorator('nte', {
						initialValue: formData.nte || this.state.nte,
						validateTrigger: 'onChange',
						rules: [
							{
								validator: (rule, value, callback) => {
									if (
										approvalHeirarchy.amount &&
										parseInt(value) > parseInt(approvalHeirarchy.amount) &&
										parseInt(value) !== formData.nte
									) {
										this.setState({
											approvalHeirarchy: {
												...approvalHeirarchy,
												status: isAdmin ? 'warning' : 'error',
											},
										});
										callback(isAdmin ? undefined : '');
									} else {
										this.setState({
											approvalHeirarchy: {
												...approvalHeirarchy,
												status: null,
											},
										});
										callback();
									}
								},
							},
						],
					})(<Input onChange={this.handleNTEValueChange} onClick={(e) => e.stopPropagation()} />)}
				</Form.Item>
				<Form.Item label="Assign To">
					{getFieldDecorator('supplierFacilityId', {
						initialValue: formData.supplierFacilityId,
					})(
						<OWAsyncSelect
							placeholder="Rank 1 supplier will get picked based on problem type and location"
							allowClear={true}
							stateSlice={supplierFacilities}
							targetCollectionName={
								PLANNED_MAINTENANCE_TEMPLATE_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE
							}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleSupplierFacilitiesInPrivateNetwork(ids, targetCollectionName);
							}}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchSupplierFacilitiesInPrivateNetwork(
									{ name: searchText, offset: 0, limit: 100 },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							renderRecord={(supplierFacility) => (
								<Select.Option key={supplierFacility.id} value={supplierFacility.id}>
									{supplierFacility.name}
								</Select.Option>
							)}
							additionalFilters={{
								problemTypeId: this.state.problemTypeId,
							}}
							sortBy={{ sort_by: 'name', order: 'ascend' }}
						/>
					)}
				</Form.Item>
				<Form.Item label="Contract ID">
					{getFieldDecorator('supplierContractId', {
						initialValue: formData.supplierContractId,
					})(<Input />)}
				</Form.Item>
				<Form.Item label="Contract Documents">
					{getFieldDecorator(`supplierContractDocuments`, {
						valuePropName: 'fileList',
					})(
						<div>
							<FileUploader
								roleType={userType}
								defaultFileList={formData.supplierContractDocuments || []}
								handleUploadSuccess={this.handleFileManualUpload}
								handleFileListChange={this.handleFileListChange}
								accept="application/pdf"
								uploaderType={'dragger'}
							/>
						</div>
					)}
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						loading={loading}
						className="contactForm__button"
					>
						{submitText}
					</Button>
					<span style={{ marginLeft: '16px' }}>
						<BackButton buttonText="Cancel" />
					</span>
				</Form.Item>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,
	createErrors: state.work_orders.createErrors,
	updateErrors: state.work_orders.updateErrors,
	formData: ownProps.formData,
	creating: state.work_orders.creating,
	updating: state.work_orders.updating,
	spendCategories: state.spend_categories,
	problemTypes: state.problem_types,
	supplierFacilities: state.supplier_facilities,
	workOrderPriorities: state.work_order_priorities,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: locationsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchMultipleSpendCategories: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? spendCategoriesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: spendCategoriesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchSpendCategories: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? spendCategoriesRestCrudThunksForSupplier.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: spendCategoriesRestCrudThunksForBuyer.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultipleProblemTypes: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? problemTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: problemTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchProblemTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? problemTypesHeirarchicalForSupplier(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: problemTypesHeirarchicalForBuyer(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchProblemType: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? problemTypesRestCrudThunksForSupplier.readOne(id)
				: problemTypesRestCrudThunksForBuyer.readOne(id)
		),
	fetchWorkOrderAttributes: (params) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? workOrderAttributesRestCrudThunksForSupplier.read(params)
				: workOrderAttributesRestCrudThunksForBuyer.read(params)
		),
	fetchSupplierFacilitiesInPrivateNetwork: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? fetchSupplierFacilitiesInPrivateNetworkForSupplier(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters
				  )
				: fetchSupplierFacilitiesInPrivateNetworkForBuyer(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters
				  )
		),
	fetchMultipleSupplierFacilitiesInPrivateNetwork: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? supplierFacilitiesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: supplierFacilitiesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchMultipleWorkOrderPriorities: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? workOrderPrioritiesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: workOrderPrioritiesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchWorkOrderPriorities: (params) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? workOrderPrioritiesRestCrudThunksForSupplier.read(
						params,
						PLANNED_MAINTENANCE_TEMPLATE_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE
				  )
				: workOrderPrioritiesRestCrudThunksForBuyer.read(
						params,
						PLANNED_MAINTENANCE_TEMPLATE_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE
				  )
		),
	update: (plannedMaintenanceTemplate) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? plannedMaintenanceTemplatesRestCrudThunksForSupplier.update(plannedMaintenanceTemplate)
				: plannedMaintenanceTemplatesRestCrudThunksForBuyer.update(plannedMaintenanceTemplate)
		),
	create: (plannedMaintenanceTemplate) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? plannedMaintenanceTemplatesRestCrudThunksForSupplier.create(plannedMaintenanceTemplate)
				: plannedMaintenanceTemplatesRestCrudThunksForBuyer.create(plannedMaintenanceTemplate)
		),
	fetchApprovalHeirarchy: (id) => dispatch(getBuyerCompanyApprovalTiers(ownProps.userType, null)()),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(PlannedMaintenanceTemplateForm)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(Form.create()(ComponentWithoutUserType));

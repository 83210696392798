import RestCrudThunks from './restCrudThunksGenerator';
import {
	APPROVAL_HIERARCHIES_CRUD_ACTION_CREATORS,
	APPROVAL_RULES_CRUD_ACTION_CREATORS,
	patchBuyerApprovalTiers,
	patchBuyerApprovalTiersFailure,
	patchBuyerApprovalTiersSuccess,
} from '../actions/company_approval_actions';
import { ROLE_TYPES } from '../utils/DataConstants';
import FetchUtils, { parseJSON } from './fetch_utils';
import {
	fetchBuyerApprovalTiers,
	fetchBuyerApprovalTiersFailure,
	fetchBuyerApprovalTiersSuccess,
} from '../actions/company_approval_actions';
import {
	fetchSupplierApprovalTiers,
	fetchSupplierApprovalTiersFailure,
	fetchSupplierApprovalTiersSuccess,
} from '../actions/company_approval_actions';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { logoutSuccess } from '../actions/session_actions';

const BASE_URL = getBackendUri();
const fetchUtils = new FetchUtils();

export const approvalsRestCrudThunksForBuyer = new RestCrudThunks(
	'approval_hierarchies',
	'approval_hierarchy',
	'approvalHierarchy',
	'approvalHierarchies',
	APPROVAL_HIERARCHIES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	''
);
export const approvalsRestCrudThunksForSupplier = new RestCrudThunks(
	'approval_hierarchies',
	'approval_hierarchy',
	'approvalHierarchy',
	'approvalHierarchies',
	APPROVAL_HIERARCHIES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	''
);

export const approvalRulesRestCrudThunksForBuyer = new RestCrudThunks(
	'approval_hierarchy_association',
	'approval_hierarchy_association',
	'approvalHierarchyAssociation',
	'approvalHierarchyAssociations',
	APPROVAL_RULES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	''
);
export const approvalRulesRestCrudThunksForSupplier = new RestCrudThunks(
	'approval_hierarchy_association',
	'approval_hierarchy_association',
	'approvalHierarchyAssociation',
	'approvalHierarchyAssociations',
	APPROVAL_RULES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	''
);

export const getProposalApprovalHierarchiesByAttributes = (userRole, params) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(APPROVAL_RULES_CRUD_ACTION_CREATORS.fetchStart());

			fetchUtils
				.get(BASE_URL + `/api/v1/${userRole}/approval_hierarchies/proposal/by_attributes`, params)
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(APPROVAL_RULES_CRUD_ACTION_CREATORS.fetchError([d.message], ''));
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(APPROVAL_RULES_CRUD_ACTION_CREATORS.fetchError([d.message], ''));
					reject(d.message);
				});
		});
	};
};

export const hasApprovalHierarchyChanged = (userRole, workOrderId, params) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(APPROVAL_RULES_CRUD_ACTION_CREATORS.fetchStart());

			fetchUtils
				.get(
					BASE_URL +
						`/api/v1/${userRole}/approval_hierarchies/has_hierarchy_changed/${workOrderId}`,
					params
				)
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(APPROVAL_RULES_CRUD_ACTION_CREATORS.fetchError([d.message], ''));
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(APPROVAL_RULES_CRUD_ACTION_CREATORS.fetchError([d.message], ''));
					reject(d.message);
				});
		});
	};
};
export const getBuyerCompanyApprovalAssociations = (userRole, params) => () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(APPROVAL_RULES_CRUD_ACTION_CREATORS.fetchStart());

			fetchUtils
				.get(BASE_URL + `/api/v1/${userRole}/approval_hierarchy_association`, params)
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(APPROVAL_RULES_CRUD_ACTION_CREATORS.fetchError([d.message], ''));
						reject(d.message);
					} else {
						dispatch(APPROVAL_RULES_CRUD_ACTION_CREATORS.fetchOneSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(APPROVAL_RULES_CRUD_ACTION_CREATORS.fetchError([d.message], ''));
					reject(d.message);
				});
		});
	};
};
export const editBuyerCompanyApprovalAssociations = (entity) => () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(APPROVAL_RULES_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.put(BASE_URL + `/api/v1/buyer/approval_hierarchy_association`, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: entity,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(APPROVAL_RULES_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(APPROVAL_RULES_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(APPROVAL_RULES_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};
export const updateBuyerCompanyApprovalAssociations = (entity, targetCollectionName) => () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(APPROVAL_RULES_CRUD_ACTION_CREATORS.createStart(entity));

			fetchUtils
				.post(BASE_URL + `/api/v1/buyer/approval_hierarchy_association`, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: entity,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(APPROVAL_RULES_CRUD_ACTION_CREATORS.createError(d.message, entity));
						reject(d.message);
					} else {
						console.log(targetCollectionName);
						dispatch(
							APPROVAL_RULES_CRUD_ACTION_CREATORS.createSuccess(d.data, targetCollectionName)
						);
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(APPROVAL_RULES_CRUD_ACTION_CREATORS.createError(d.message, entity));
					reject(d.message);
				});
		});
	};
};
export const updateApprovalAssociationRanks = (entities) => () => {
	const apiUrl = `/api/v1/buyer/approval_hierarchy_association/bulk_update_ranks`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.put(BASE_URL + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: entities,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						dispatch(APPROVAL_RULES_CRUD_ACTION_CREATORS.updateBulkSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};
export const deleteApprovalAssociation = (entity, id) => () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(APPROVAL_RULES_CRUD_ACTION_CREATORS.deleteStart(entity));

			fetchUtils
				.delete(BASE_URL + `/api/v1/buyer/approval_hierarchy_association/${id}`, {})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(APPROVAL_RULES_CRUD_ACTION_CREATORS.deleteError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(APPROVAL_RULES_CRUD_ACTION_CREATORS.deleteSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(APPROVAL_RULES_CRUD_ACTION_CREATORS.deleteError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

export const getBuyerCompanyApprovalTiers = (userRole, id) => () => {
	let fetchApprovalTiers;
	let fetchApprovalTiersSuccess;
	let fetchApprovalTiersFailure;
	let params = { id };
	if (userRole === 'buyer') {
		fetchApprovalTiers = fetchBuyerApprovalTiers;
		fetchApprovalTiersSuccess = fetchBuyerApprovalTiersSuccess;
		fetchApprovalTiersFailure = fetchBuyerApprovalTiersFailure;
	} else if (userRole === 'supplier') {
		fetchApprovalTiers = fetchSupplierApprovalTiers;
		fetchApprovalTiersSuccess = fetchSupplierApprovalTiersSuccess;
		fetchApprovalTiersFailure = fetchSupplierApprovalTiersFailure;
	} else {
		throw new Error('invalid user role provided');
	}

	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(fetchApprovalTiers());

			fetchUtils
				.get(BASE_URL + `/api/v1/${userRole}/approval_hierarchies`, params)
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(fetchApprovalTiersFailure([d.message]));
						reject(d.message);
					} else {
						dispatch(fetchApprovalTiersSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(fetchApprovalTiersFailure(d.message));
					reject(d.message);
				});
		});
	};
};

export const updateBuyerCompanyApprovalTiers = (tiers) => () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(APPROVAL_HIERARCHIES_CRUD_ACTION_CREATORS.updateStart(tiers));

			fetchUtils
				.post(BASE_URL + `/api/v1/buyer/approval_hierarchies`, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: tiers,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(APPROVAL_HIERARCHIES_CRUD_ACTION_CREATORS.updateError(d.message, tiers));
						reject(d.message);
					} else {
						dispatch(APPROVAL_HIERARCHIES_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(APPROVAL_HIERARCHIES_CRUD_ACTION_CREATORS.updateError(d.message, tiers));
					reject(d.message);
				});
		});
	};
};
export const editBuyerCompanyApprovalTiers = (tiers) => () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(APPROVAL_HIERARCHIES_CRUD_ACTION_CREATORS.updateStart(tiers));

			fetchUtils
				.put(BASE_URL + `/api/v1/buyer/approval_hierarchies`, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: tiers,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(APPROVAL_HIERARCHIES_CRUD_ACTION_CREATORS.updateError(d.message, tiers));
						reject(d.message);
					} else {
						dispatch(APPROVAL_HIERARCHIES_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(APPROVAL_HIERARCHIES_CRUD_ACTION_CREATORS.updateError(d.message, tiers));
					reject(d.message);
				});
		});
	};
};
export const deleteBuyerCompanyApprovalTiers = (tiers, id) => () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(APPROVAL_HIERARCHIES_CRUD_ACTION_CREATORS.deleteStart(tiers));

			fetchUtils
				.delete(BASE_URL + `/api/v1/buyer/approval_hierarchies/${id}`, {})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(APPROVAL_HIERARCHIES_CRUD_ACTION_CREATORS.deleteError(d.message, tiers));
						reject(d.message);
					} else {
						dispatch(APPROVAL_HIERARCHIES_CRUD_ACTION_CREATORS.deleteSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(APPROVAL_HIERARCHIES_CRUD_ACTION_CREATORS.deleteError(d.message, tiers));
					reject(d.message);
				});
		});
	};
};
export const patchBuyerCompanyApprovalTiers = (id, mirrorId, type) => () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(APPROVAL_HIERARCHIES_CRUD_ACTION_CREATORS.updateStart({}));

			fetchUtils
				.patch(BASE_URL + `/api/v1/buyer/approval_hierarchies/mirror`, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: { id, mirrorId, approvalHierarchyType: type },
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(patchBuyerApprovalTiersFailure(d.message));
						reject(d.message);
					} else {
						dispatch(patchBuyerApprovalTiersSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(APPROVAL_HIERARCHIES_CRUD_ACTION_CREATORS.updateError(d.message, {}));
					reject(d.message);
				});
		});
	};
};

export const forwardApprovalToUser = (userType) => (entityType, entity, email) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(APPROVAL_HIERARCHIES_CRUD_ACTION_CREATORS.updateStart(entity));
			console.log('test');
			const baseUrl = getBackendUri();
			fetchUtils
				.post(
					baseUrl +
						`/api/v1/${userType}/approval_hierarchies/forward_approval_to/${entityType}/${entity.id}/${email}`,
					{}
				)
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(APPROVAL_HIERARCHIES_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(APPROVAL_HIERARCHIES_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(APPROVAL_HIERARCHIES_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};
export const forwardApprovalToUserForBuyer = forwardApprovalToUser('buyer');
export const forwardApprovalToUserForSupplier = forwardApprovalToUser('supplier');

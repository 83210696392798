import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Input, Button, Row, Modal, Form, Select } from 'antd';

import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { KeyValueDisplay } from '../key_value_display/KeyValueDisplay';
import UserListItemComponent from '../user_list_item_component/UserListItemComponent';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { debounce } from '../../utils/PerformanceUtils';
import {
	buyerContactsRestCrudThunksForBuyer,
	buyerContactsRestCrudThunksForSupplier,
} from '../../thunks/buyer_contacts_thunks';
import MultiEmailComponentAntdV4 from '../MultiEmailComponent/MultiEmailComponentAntdV4';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { withRouter } from 'react-router';

const LOCATION_FORM_BUYER_CONTACTS_AUTOCOMPLETE = 'LOCATION_FORM_BUYER_CONTACTS_AUTOCOMPLETE';

function BuyerSupplierRelationshipForm(props) {
	const { allowedToEdit = true } = props;
	const [editable, setEditable] = useState(false);

	useEffect(() => {
		const { fetchBuyerContacts } = props;
		fetchBuyerContacts();
	}, []);

	const [form] = Form.useForm();

	const handleSubmit = (values) => {
		props.onSubmit(values);
		form.resetFields();
		setEditable(false);
	};

	const handleCancel = () => {
		form.resetFields();
		setEditable(false);
	};
	const { data, buyerContacts } = props;

	useEffect(() => {
		if (editable) {
			form.setFieldsValue(data);
		}
	}, [form, data, editable]);

	const detailsMap = {
		'Points of Contacts': (nullSafeGet('pointsOfContactForSupplierContacts', data) || []).map(
			(item) => (
				<span>
					<UserListItemComponent
						key={item.email}
						user={{ contact: item }}
						className="UserRow"
						allowRemove={false}
					/>
				</span>
			)
		),
	};

	const buyerContactsDropdownRecords = getRecordsForTargetCollection(
		buyerContacts,
		LOCATION_FORM_BUYER_CONTACTS_AUTOCOMPLETE
	);
	const debouncedSearchBuyerContacts = debounce(
		(searchText) => {
			const { fetchBuyerContacts } = props;
			fetchBuyerContacts({ email: searchText && searchText.trim() });
		},
		500,
		false
	);

	const searchBuyerContacts = (searchText) => {
		debouncedSearchBuyerContacts(searchText);
	};
	return (
		<>
			<Row justify="space-between">
				<h5 style={{ display: 'flex', alignItems: 'center' }}>Buyer Contact Details</h5>
				{allowedToEdit ? (
					<span>
						<Button onClick={() => setEditable(!editable)}>{editable ? 'Cancel' : 'Edit'}</Button>{' '}
					</span>
				) : null}
			</Row>

			<KeyValueDisplay keyWidth={120} keyValueStore={detailsMap} />
			<Modal
				width={600}
				okText="Update Supplier"
				onCancel={handleCancel}
				onOk={form.submit}
				closable={false}
				visible={editable}
				okButtonProps={{ loading: !editable }}
			>
				<Form
					form={form}
					initialValues={data}
					onFinish={handleSubmit}
					layout="vertical"
					labelAlign="left"
				>
					<h5>Update Contact Details</h5>
					<Form.Item label="Points of Contacts" name="pointsOfContactForSupplier">
						<Select
							mode="multiple"
							allowClear
							placeholder="Please select"
							onSearch={searchBuyerContacts}
						>
							{buyerContactsDropdownRecords.map((d) => (
								<Select.Option key={d.email} value={d.email}>
									{d.email}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
}

const mapStateToProps = (state) => ({
	buyerContactsFetching: state.buyer_contacts.fetching,
	buyerContacts: state.buyer_contacts,
	userType: (state as any).session.userType,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchBuyerContacts: (params) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? buyerContactsRestCrudThunksForSupplier.read(
						params,
						LOCATION_FORM_BUYER_CONTACTS_AUTOCOMPLETE
				  )
				: buyerContactsRestCrudThunksForBuyer.read(
						params,
						LOCATION_FORM_BUYER_CONTACTS_AUTOCOMPLETE
				  )
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(BuyerSupplierRelationshipForm)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

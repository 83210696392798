import * as React from 'react';
import { Layout, Card } from 'antd';

const style = require('./EmptorTermsAndConditionsPage.less');

export default class EmptorTermsAndConditionsPage extends React.Component<any, any> {
	render() {
		return (
			<Layout.Content className="emptorTermsAndConditionsPage">
				<Card style={{ maxWidth: 1200, margin: 'auto' }}>
					<h1>OpenWrench's Master Subscription Agreement</h1>
					<p>
						This Master Subscription Agreement ("Agreement") between Emptor.ai, Inc. ("OpenWrench")
						and the company or other legal entity ("Customer") that has executed an Order Form (as
						defined below) is made as of the last signature date ("Effective Date") on the Order
						Form that references this Agreement.
					</p>
					<h2>DEFINITIONS</h2>
					<p>
						"Affiliate" means any entity which directly or indirectly controls, is controlled by, or
						is under common control with the subject entity; and "control" for the purposes of this
						definition means direct or indirect ownership or control of more than 50% of the voting
						interest of the subject entity, provided that any such Affiliate shall be deemed an
						Affiliate only for so long as such control lasts.
					</p>
					<p>
						"Confidential Information" means all confidential and proprietary information of a
						disclosing party disclosed by or on behalf of such party to the receiving party, whether
						orally or in writing, that is designated as confidential or that reasonably should be
						understood to be confidential given the nature of the information and the circumstances
						of disclosure, including the terms and conditions of this Agreement (including pricing
						and other terms reflected in all Order Forms hereunder), business and marketing plans,
						technology and technical information, product designs, and business processes.
						Notwithstanding anything to the contrary, the Hosted Applications and OpenWrench
						Platform are deemed to be Confidential Information of OpenWrench. Confidential
						Information shall not include any information that: (i) is or becomes generally known to
						the public without breach of any obligation owed to the disclosing party; (ii) was known
						to the receiving party without restriction prior to its disclosure by the disclosing
						party and without breach of any obligation owed to the disclosing party; (iii) was
						independently developed by the receiving party without either use of or reference to any
						Confidential Information or breach of any obligation owed to the disclosing party; or
						(iv) is received from a third party without restriction and without breach of any
						obligation owed to the disclosing party.
					</p>
					<p>
						"OpenWrench Platform" means any software and hardware that enables OpenWrench to provide
						Customer with access to and use of the Hosted Applications as contemplated by this
						Agreement.
					</p>
					<p>
						"Customer Data" means any data, information or material provided or submitted by
						Customer or on behalf of Customer to the OpenWrench Platform in the course of using the
						Hosted Applications.
					</p>
					<p>
						"Documentation" means the OpenWrench product documentation relating to the operation and
						use of the Hosted Applications, including technical program or interface documentation,
						operating instructions, update notes, and support knowledge base, as made available and
						updated from time to time by OpenWrench.
					</p>
					<p>
						"Hosted Application(s)" means applications and associated content (as identified on an
						Order Form) to be provided by OpenWrench to Customer as a subscription service and made
						accessible on a website designated by OpenWrench.
					</p>
					<p>
						"Order Form" means an order form mutually executed by the parties evidencing the
						purchase of subscriptions to the Hosted Applications specifying, among other things, the
						Subscription Term, the applicable fees, and the billing period as agreed to between the
						parties. Each Order Form, once mutually executed, shall be governed by and become part
						of this Agreement, and is hereby incorporated by this reference.
					</p>
					<p>
						"Protected Health Information" has the meaning given to it in the Health Insurance
						Portability and Accountability Act ("HIPAA").
					</p>
					<p>
						"Protected Information" means Protected Health Information and Regulated Information.
					</p>
					<p>
						"Regulated Information" means an individual's first name and last name (or first initial
						and last name) in combination with any one or more of the following data elements that
						relate to such individual: (i) Social Security number; (ii) driver's license number or
						state-issued identification card number; or (iii) financial account number, or credit or
						debit card number, with or without any required security code, access code, personal
						identification number or password, that would permit access to an individual's financial
						account.
					</p>
					<p>
						"Subscription Term" means the period(s) during which Customer is authorized to use the
						Hosted Applications pursuant to an Order Form.
					</p>
					<p>
						"Support" means the OpenWrench technical support as specified on the Order Form in
						accordance with the terms in Exhibit A-1.
					</p>
					<p>
						"Updates" means OpenWrench's updates of the Hosted Applications for repairs,
						enhancements or new features applied by OpenWrench to Customer's instances, including
						updates to the Documentation as a result of such updates, at no additional fee during
						the Subscription Term. Updates shall not include additional functionality or upgrades to
						the Hosted Applications that OpenWrench requires a separate charge from its other
						customers generally.
					</p>
					<p>
						"Users" means employees of Customer and its representatives, consultants, contractors,
						subcontractors, or agents who are authorized to use the Hosted Applications and have
						been supplied unique user identifications and passwords by Customer.
					</p>
					<h2>EMPTOR'S OBLIGATIONS</h2>
					<p>
						Provision of the Hosted Applications. OpenWrench will make available to Customer, and
						Customer is authorized to use, the Hosted Applications during the Subscription Term as
						set forth in an applicable Order Form for its internal business purposes in accordance
						with the Documentation.
					</p>
					<p>
						Support, Uptime & Updates. OpenWrench shall: (i) provide the level of support specified
						in the Order Form in accordance with Exhibit A-1; (ii) provide Updates at no additional
						charge as part of Customer's subscription during the Subscription Term in accordance
						with Exhibit A-1 and (iii) make the Hosted Applications available in accordance with
						Exhibit A-2.
					</p>

					<p>
						Security. OpenWrench shall maintain a written information security program of policies,
						procedures and controls ("Security Program") governing the processing, storage,
						transmission and security of Customer Data. The Security Program as of the Effective
						Date is set forth in Exhibit A-3. The Security Program shall include industry standard
						practices designed to protect Customer Data from unauthorized access, acquisition, use,
						disclosure, or destruction. OpenWrench may periodically review and update the Security
						Program to address new and evolving security technologies, changes to industry standard
						practices, and changing security threats, provided that any such update does not
						materially reduce the overall level of security provided to Customer as described
						herein.
					</p>

					<p>
						Breach Notification. Unless notification is restricted by law, OpenWrench shall report
						to Customer's support contacts designated in OpenWrench's customer support portal
						("Support Portal") any unauthorized acquisition, access, use, disclosure or destruction
						of Customer Data ("Breach") promptly without undue delay after OpenWrench determines
						that a Breach has occurred. Unless prohibited by law, OpenWrench shall share information
						about the nature of the Breach that is reasonably requested by Customer to enable
						Customer to notify affected individuals, government agencies and/or credit bureaus.
						Customer has sole control over the content of Customer Data that it enters into the
						OpenWrench Platform and is responsible for determining whether to notify impacted
						individuals and the applicable regulatory bodies or enforcement commissions and for
						providing such notice.
					</p>

					<h2>CUSTOMER'S USE OF THE HOSTED APPLICATIONS</h2>
					<p>
						User Accounts. Customer is responsible for activity occurring under its User accounts
						and shall ensure that it and its Users abide by all local, state, national and foreign
						laws, treaties and regulations applicable to Customer's use of the Hosted Applications.
						Customer shall: (i) notify OpenWrench promptly of any unauthorized use of any password
						or account or any other known or suspected breach of security; (ii) notify OpenWrench
						promptly and use reasonable efforts to promptly stop any unauthorized use, copying, or
						distribution of the Hosted Applications that is known or suspected by Customer or its
						Users; (iii) not impersonate another OpenWrench user or provide false identity
						information to gain access to or use the Hosted Applications or OpenWrench Platform; and
						(iv) restrict each User account to only one authorized User at a time.
					</p>
					<p>
						Restrictions. Customer shall not (i) license, sublicense, sell, resell, transfer, rent,
						lease, assign (except as provided in Section 11.3 (Assignment)), distribute, disclose,
						or otherwise commercially exploit or make available to any third party the Hosted
						Applications; (ii) copy, modify or make derivative works based upon the Hosted
						Applications; (iii) "frame" or "mirror" the Hosted Applications on any other server or
						device; (iv) access the Hosted Applications for any benchmarking or competitive purposes
						or use the Hosted Applications for application service provider, timesharing or service
						bureau purposes, or any purpose other than its own internal use, (v) decompile,
						disassemble, reverse engineer or attempt to discover any source code or underlying ideas
						or algorithms of the Hosted Applications (except to the extent reverse engineering
						restrictions are prohibited by applicable law), (vi) remove, obscure or modify a
						copyright or other proprietary rights notice in the Hosted Applications; (vii) use the
						Hosted Applications to send or store infringing, obscene, threatening, libelous, or
						otherwise unlawful material, including material that violates third party privacy
						rights; (viii) use the Hosted Applications to create, use, send, store, or run material
						containing software viruses, worms, Trojan horses or otherwise engage in any malicious
						act or disrupt the security, integrity or operation of the Hosted Applications or the
						OpenWrench Platform; (ix) attempt to gain or permit unauthorized access to the Hosted
						Applications or its related systems or networks; (x) use the Hosted Applications other
						than in compliance with all applicable laws and regulations or (xi) permit or assist any
						other party (including any User) to do any of the foregoing.
					</p>
					<p>
						Protected Information. The intended purpose of the Hosted Applications is to optimize
						Customer's corporate spend management processes and Customer acknowledges and agrees
						that use of the Hosted Applications does not require Customer to provide any Protected
						Information to or through the Hosted Applications or OpenWrench Platform. Protected
						Information should not be stored by any Hosted Applications or OpenWrench Platform, and
						OpenWrench shall have no liability to Customer or its suppliers, Users or any other
						party related to any Protected Information. Customer shall not (and shall ensure that
						its suppliers and Users do not) upload, provide or submit any Protected Information to
						the Hosted Applications or OpenWrench Platform. OpenWrench may upon notice suspend all
						or portion of Customer's or its supplier's access to the Hosted Applications if
						OpenWrench has a good faith belief that Customer or its supplier has breached the
						restrictions in this Section.
					</p>
					<p>
						OpenWrench shall provide Customer with reasonable prior notice to cure before exercising
						any suspension under this Section.
					</p>
					<h2>Third Party Interactions.</h2>
					<p>
						Supplier Interactions. During the Subscription Term, Customer may enter into
						correspondence with and purchase goods and/or services from suppliers on or through the
						Hosted Applications. Any such activities and associated terms are solely between
						Customer and the applicable third party supplier. Customer agrees that OpenWrench shall
						have no liability, obligation or responsibility for any such correspondence or purchase
						between Customer and any such third party supplier.
					</p>
					<h2>ORDERING</h2>
					<p>
						Billing and Payment of Fees. Customer shall pay subscription fees annually in advance
						for use of the Hosted Applications. All payment obligations are non-cancellable and all
						amounts paid are nonrefundable except as otherwise specified in this Agreement.
						OpenWrench shall issue invoices to Customer as specified in the Order Form and Customer
						agrees to pay such amounts not subject to a good faith dispute as specified in the Order
						Form and if any such invoice is more than 30 days overdue, OpenWrench may, without
						limiting its other rights and remedies, suspend the Hosted Applications until such
						invoice is paid in full. OpenWrench shall provide prior written notice to Customer of
						the payment delinquency before exercising any suspension right. Customer agrees to pay
						OpenWrench in the currency specified on the Order Form.
					</p>

					<p>
						Customer agrees to provide OpenWrench with complete and accurate billing and contact
						information and to update this information promptly upon any change to it. If Customer
						believes its bill is incorrect, Customer must contact OpenWrench in writing within 60
						days of the date of the invoice containing the amount in question to be eligible to
						receive an adjustment or credit.
					</p>

					<p>
						Taxes. OpenWrench's fees are exclusive of all taxes, levies, or duties imposed by taxing
						authorities, including for example, value-added, sales, use or withholding taxes,
						assessable by any jurisdiction whatsoever (collectively, "Taxes") and Customer shall be
						responsible for payment of all Taxes associated with this Agreement and all Order Forms,
						except that OpenWrench is solely responsible for taxes assessable against OpenWrench
						based on OpenWrench's net income, property and employees. If Customer is legally
						entitled to an exemption from any sales, use, or similar transaction tax, upon signing
						an Order Form, Customer shall provide to OpenWrench with a legally sufficient tax
						exemption certificate for each taxing jurisdiction, and OpenWrench shall not charge
						Customer any taxes from which it is exempt. If any deduction or withholding is required
						by law, Customer shall notify OpenWrench and shall pay OpenWrench any additional amounts
						necessary to ensure that the net amount that OpenWrench receives, after any deduction
						and withholding, equals the amount OpenWrench would have received if no deduction or
						withholding had been required. Customer shall also provide to OpenWrench documentation
						showing that the withheld and deducted amounts have been paid to the relevant taxing
						authority.
					</p>

					<h2>PROPRIETARY RIGHTS</h2>

					<p>
						OpenWrench's Intellectual Property Rights. As between OpenWrench and Customer, all
						rights, title, and interest in and to all intellectual property rights in the Hosted
						Applications and OpenWrench Platform (including all derivatives, modifications and
						enhancements thereof) are and shall be owned exclusively by OpenWrench notwithstanding
						any other provision in this Agreement or Order Form. This Agreement is not a sale and
						does not convey to Customer any rights of ownership in or related to the Hosted
						Applications or OpenWrench Platform. The OpenWrench name, logo and product names
						associated with the Hosted Applications or OpenWrench Platform are trademarks of
						OpenWrench, and no right or license is granted to use them. All rights not expressly
						granted to Customer are reserved by OpenWrench. OpenWrench alone shall own all rights,
						title and interest in and to any suggestions, enhancement requests, feedback,
						recommendations or other information provided by Customer or any third party relating
						thereto.
					</p>

					<p>
						Customer Data. As between Customer and OpenWrench, Customer exclusively owns all rights,
						title and interest in and to all Customer Data. Customer shall have sole responsibility
						for the accuracy, quality, integrity, legality, reliability, appropriateness, and
						intellectual property ownership of and right to use all Customer Data, and hereby
						warrants that that it has and will have all rights and consents necessary to allow
						OpenWrench to use all such data as contemplated by this Agreement. Customer hereby
						grants to OpenWrench a royalty-free, fully-paid, non- exclusive, non-transferable
						(except as set forth in Section 11.3 (Assignment)), sub-licensable, worldwide right to
						use and process Customer Data solely for the purpose of providing to Customer the Hosted
						Applications and any other activities expressly agreed to by Customer.
					</p>

					<h2>CONFIDENTIAL INFORMATION</h2>

					<p>
						Obligations. The receiving party shall not disclose or use any Confidential Information
						of the disclosing party for any purpose outside the scope of this Agreement, except with
						the disclosing party's prior written permission. Each party agrees to protect the
						confidentiality of the Confidential Information of the other party in the same manner
						that it protects the confidentiality of its own proprietary and confidential information
						of like kind (but in no event using less than reasonable care). If the receiving party
						is compelled by law to disclose Confidential Information of the disclosing party, it
						shall provide the disclosing party with prior written notice of such compelled
						disclosure (to the extent legally permitted) and reasonable assistance, at disclosing
						party's cost, if the disclosing party wishes to contest the disclosure, and any
						information so disclosed shall continue to be treated as Confidential Information for
						all other purposes.
					</p>

					<p>
						Remedies. Except as expressly provided in this Agreement, if the receiving party
						discloses or uses (or threatens to disclose or use) any Confidential Information of the
						disclosing party in breach of confidentiality protections hereunder, the disclosing
						party shall have the right, in addition to any other remedies available to it, to seek
						injunctive relief to enjoin such acts, it being specifically acknowledged by the parties
						that any other available remedies may be inadequate.
					</p>

					<p>
						Use of Aggregate Data. Customer agrees that OpenWrench may collect, use and disclose
						quantitative data derived from the use of the Hosted Applications for industry analysis,
						benchmarking, analytics, marketing, and other business purposes. All data collected,
						used, and disclosed will be in aggregate form only and will not identify Customer or its
						Users.
					</p>

					<h2>WARRANTIES</h2>

					<p>
						OpenWrench's Obligations. OpenWrench warrants that during the Subscription Term (i)
						Customer's production instances of the Hosted Applications shall materially conform to
						the Documentation and (ii) that the functionality of the Hosted Applications at the time
						of the Order Form shall not materially decrease during the Subscription Term.
					</p>

					<p>
						Procedure. To submit a warranty claim under this Section, Customer shall (1) reference
						this Section and (2) submit a support request to resolve the non-conformity as provided
						in the Subscription Schedule. If the non-conformity persists without relief more than
						thirty (30) days after written notice of a warranty claim provided to OpenWrench under
						this Section, then Customer may terminate the affected Hosted Applications and
						OpenWrench, as its sole liability in connection with a breach of this warranty, shall
						refund to Customer any prepaid subscription fees covering the remainder of the
						Subscription Term of the affected subscription after the effective date of termination.
						Notwithstanding the foregoing, this warranty shall not apply to any non-conformity due
						to any modification of or defect in the Hosted Applications that is made or caused by
						someone other than OpenWrench (or someone acting at OpenWrench's direction).
					</p>

					<h2>INDEMNIFICATION</h2>
					<p>
						EMPTOR'S OBLIGATIONS. Subject to this Agreement, OpenWrench shall: defend Customer, its
						officers, directors and employees against any third party suit, claim, or demand (each a
						"Claim") that alleges the Hosted Applications used in accordance with this Agreement and
						the applicable Order Form infringe any issued patent, copyright, trademark or
						misappropriation of any trade secret of, such third party; and (ii) pay any
						court-ordered award of damages or settlement amount to the extent arising from such
						Claims. Notwithstanding the foregoing, if OpenWrench reasonably believes that Customer's
						use of any portion of the Hosted Applications is likely to be enjoined by reason of any
						Claims then OpenWrench may, at its expense and in its sole discretion: (i) procure for
						Customer the right to continue using the Hosted Applications; (ii) replace the same with
						other products of substantially equivalent functions and efficiency that are not subject
						to any Claims of infringement; or (iii) modify the applicable Hosted Applications so
						that there is no longer any infringement, provided that such modification does not
						materially and adversely affect the functional capabilities of the Hosted Applications
						as set out herein or in the applicable Order Form. If (i), (ii), and (iii) above are not
						available on commercially reasonable terms in OpenWrench's judgment, OpenWrench may
						terminate the affected Hosted Applications and refund to Customer the fees paid by
						Customer covering the remaining portion of the applicable Subscription Term for the
						affected Hosted Applications after the date of termination. The foregoing
						indemnification obligation of OpenWrench shall not apply: (1) if the Hosted Application
						is modified by any party other than OpenWrench, but solely to the extent the alleged
						infringement is related to such modification; (2) the Hosted Application is combined
						with other non- OpenWrench products, applications, or processes not authorized by
						OpenWrench, but solely to the extent the alleged infringement is related to such
						combination; (3) to the extent the Claim arises in connection with any unauthorized use
						of the Hosted Application, or use that is not in compliance with all applicable laws and
						related Documentation; (4) to any third party products, processes or materials that are
						not provided by OpenWrench; or (5) to any Claims arising as a result of the content of
						the Customer Data. THIS SECTION SETS FORTH EMPTOR'S SOLE LIABILITY AND CUSTOMER'S SOLE
						AND EXCLUSIVE REMEDY WITH RESPECT TO ANY CLAIM OF INTELLECTUAL PROPERTY INFRINGEMENT.
					</p>
					<p>
						CUSTOMER'S OBLIGATIONS. Customer shall defend OpenWrench, its officers, directors, and
						employees against any expense, liability, loss, damage or costs (including reasonable
						attorneys' fees), each to the extent payable to a third party, incurred in connection
						with Claims made or brought against OpenWrench by a third party arising from or relating
						to the Customer Data or a dispute between Customer and its suppliers arising from
						Customer's use of the Hosted Applications to exchange information with or conduct
						business with such supplier. To the extent affected by the following, Customer's
						indemnification obligation shall not apply: (1) if the Customer Data is modified by
						OpenWrench or by any party under OpenWrench's control, without Customer's authorization
						but solely to the extent the Claim is caused by such modification or (2) to any use or
						disclosure of the Customer Data by OpenWrench not contemplated by this Agreement.{' '}
					</p>
					<p>
						PROCESS. Each party's indemnity obligations are subject to the following: (i) the
						indemnified party shall promptly notify the indemnifier in writing of any Claims; (ii)
						the indemnifier shall have sole control of the defense and all related settlement
						negotiations with respect to any Claims (provided that the indemnifier may not settle
						any Claims that require the indemnified party to admit any civil or criminal liability
						or incur any financial obligation without the indemnified party's consent, which consent
						shall not be unreasonably withheld); and (iii) the indemnified party shall cooperate
						fully to the extent necessary at the indemnifier's cost in such defense and settlement.
					</p>

					<h2>DISCLAIMER AND LIMITATIONS OF LIABILITY</h2>

					<p>
						DISCLAIMER OF WARRANTIES. EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT, EMPTOR DOES
						NOT MAKE ANY OTHER REPRESENTATION, WARRANTY, OR GUARANTY, AS TO THE RELIABILITY,
						TIMELINESS, QUALITY, SUITABILITY, AVAILABILITY, ACCURACY OR COMPLETENESS OF THE SERVICES
						PROVIDED OR OFFERED HEREUNDER. EXCEPT AS EXPRESSLY SET FORTH HEREIN, THE SERVICES
						PROVIDED TO CUSTOMER HEREUNDER ARE PROVIDED STRICTLY ON AN "AS IS" BASIS AND ALL
						CONDITIONS, REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR
						OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY,
						FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT OF THIRD PARTY RIGHTS OR ANY
						WARRANTIES ARISING FROM USAGE OF TRADE, COURSE OF DEALING OR COURSE OF PERFORMANCE ARE
						HEREBY DISCLAIMED TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
					</p>

					<p>
						LIMITATIONS OF LIABILITY. TO THE EXTENT PERMITTED BY LAW, NEITHER PARTY'S TOTAL AND
						AGGREGATED LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT OR THE SERVICES
						PROVIDED HEREUNDER WHETHER BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE) OR ANY OTHER
						LEGAL OR EQUITABLE THEORY, SHALL EXCEED THE AMOUNTS ACTUALLY PAID BY AND/OR DUE FROM
						CUSTOMER IN THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO
						SUCH LIABILITY UNDER THIS AGREEMENT. THE EXISTENCE OF MORE THAN ONE CLAIM SHALL NOT
						ENLARGE THIS LIMIT. THE LIMITATIONS IN THIS SECTION SHALL NOT APPLY TO CUSTOMER'S
						OBLIGATION TO PAY FEES LEGALLY OWED UNDER THIS AGREEMENT, EACH PARTY'S INDEMNIFICATION
						OBLIGATIONS UNDER SECTION 8, OR INFRINGEMENT BY A PARTY OF THE OTHER PARTY'S
						INTELLECTUAL PROPERTY RIGHTS.
					</p>

					<p>
						EXCLUSION OF DAMAGES. IN NO EVENT SHALL EITHER PARTY BE LIABLE UNDER THE AGREEMENT FOR
						ANY INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES
						OF ANY TYPE OR KIND (INCLUDING LOSS OF DATA, REVENUE, PROFITS, USE OR OTHER ECONOMIC
						ADVANTAGE), REGARDLESS OF THE CAUSE, ARISING OUT OF OR IN CONNECTION WITH THE AGREEMENT
						OR THE SERVICES PROVIDED HEREUNDER, EVEN IF THE PARTY FROM WHICHDAMAGES ARE BEING SOUGHT
						HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
					</p>

					<p>
						GROSS NEGLIGENCE; WILLFUL MISCONDUCT. NOTHING HEREIN SHALL LIMIT A PARTY'S LIABILITY IN
						AN ACTION IN TORT (SEPARATE AND DISTINCT FROM A CAUSE OF ACTION FOR BREACH OF THIS
						AGREEMENT) FOR THE PARTY'S GROSS NEGLIGENCE OR WILLFUL MISCONDUCT.
					</p>

					<h2>TERM; TERMINATION</h2>

					<p>
						Term. The Agreement commences on the Effective Date and continues until all Order Forms
						subject to this Agreement have expired or terminated, unless this Agreement is earlier
						terminated in accordance with this Section 10. User subscriptions commence on the
						subscription start date specified in the relevant Order Form and continue for the
						Subscription Term specified therein. Unless otherwise provided in the Order Form, user
						subscriptions shall automatically renew for additional periods of one year on the same
						terms unless either party gives the other notice of non-renewal or a new price quote at
						least 30 days prior to the end of the relevant Subscription Term.
					</p>

					<p>
						Termination. A party may immediately terminate this Agreement for cause: (i) upon 30
						days written notice of a material breach to the other party if such breach remains
						uncured at the expiration of such period or (ii) if the other party becomes the subject
						of a petition in bankruptcy or any other proceeding relating to insolvency,
						receivership, liquidation or assignment for the benefit of creditors that is not
						dismissed within sixty (60) days of its commencement or an assignment for the benefit of
						creditors. Upon any termination for cause by Customer, OpenWrench shall refund any
						prepaid fees covering the remainder of the Subscription Term after the effective date of
						termination. Termination shall not relieve Customer of the obligation to pay any fees
						accrued or payable to OpenWrench prior to the effective date of termination.
					</p>

					<p>
						Return of Customer Data. Upon Customer's written request within 30 days after the
						effective date of termination, OpenWrench shall make available for download a file of
						Customer Data in comma separated value (.csv) format along with attachments in their
						native format. After such 30-day period, OpenWrench shall have no obligation to maintain
						or provide any Customer Data and may thereafter, unless legally prohibited, delete all
						Customer Data in its systems or otherwise in its possession or under its control.
					</p>

					<p>
						Transition Services. Upon termination of the Agreement, at Customer's election,
						OpenWrench shall provide transition services to facilitate the orderly and complete
						transfer of the Customer Data to Customer or to any replacement provider designated by
						Customer ("Transition Services"), provided that the scope and fees of the Transition
						Services shall be mutually agreed to by the parties in a statement of work prior to
						commencing Transition Services. Notwithstanding the provisions of this subsection, in no
						event shall OpenWrench be required to disclose any of its Confidential Information or
						provide a license under any of its intellectual property to Customer or any third party
						as part of the Transition Services. For the avoidance of doubt, Customer shall continue
						to pay the subscription fees for the use of the Hosted Applications during the
						transition period.
					</p>

					<p>
						Survival. Upon expiration or termination of the Agreement, Sections 1 (Definitions), 3.2
						(Restrictions), 4.1 (Billing and Payment of Fees), 5 (Proprietary Rights), 6
						(Confidential Information), 8 (Indemnification), 9 (Disclaimer and Limitations of
						Liability), 10 (Term; Termination), and 11 (General Provisions) of this Agreement shall
						survive.
					</p>

					<h2>GENERAL PROVISIONS</h2>

					<p>
						Compliance with Laws and Export Control. Each party shall comply with all applicable
						laws and government regulations, including the export laws and regulations of the United
						States and other applicable jurisdictions, in connection with providing and using the
						Hosted Applications and/or OpenWrench Platform. Without limiting the foregoing, (i) each
						party represents that it is not named on any government list of persons or entities
						prohibited from receiving exports, and (ii) Customer shall not, and shall ensure that
						Users do not violate any export embargo, prohibition, restriction or other similar law
						in connection with this Agreement.
					</p>

					<p>
						Notice. Except as provided elsewhere in this Agreement, either party may give notice by
						written communication sent by next-day mail delivered by a nationally recognized
						delivery service: (i) if to Customer, to Customer's address on record in OpenWrench's
						account information or (ii) if to OpenWrench, to 111 Monterey Blvd., San Francisco, CA
						94131, addressed to the attention of: Legal Dept. Such notice shall be deemed to have
						been given upon the expiration of 48 hours after mailing.
					</p>

					<p>
						Assignment. Neither party may assign any of its rights or obligations hereunder, whether
						by operation of law or otherwise, without the prior written consent of the other party
						(not to be unreasonably withheld). Notwithstanding the foregoing, either party may
						assign this Agreement in its entirety (including all Order Forms), without consent of
						the other party, to its Affiliate or in connection with a merger, acquisition, corporate
						reorganization, or sale of all or substantially all of its assets. Subject to the
						foregoing, this Agreement shall bind and inure to the benefit of the parties, their
						respective successors and permitted assigns.
					</p>

					<p>
						Dispute Resolution. This Agreement shall be governed by California law and controlling
						United States federal law, without regard to the choice or conflicts of law provisions
						of any jurisdiction and without regard to the United Nations Convention on the
						International Sale of Goods or the Uniform Computer Information Transactions Act. Any
						disputes, actions, claims or causes of action arising out of or in connection with this
						Agreement ("Dispute") shall be subject to the exclusive jurisdiction of the state and
						federal courts located in San Francisco, California (and the parties hereby consent to
						jurisdiction and venue in the U.S. federal courts located in the Northern District of
						California). However, notwithstanding the above, any Dispute shall be submitted to and
						finally settled by arbitration in San Francisco, California for any arbitration, using
						the English language in accordance with the Arbitration Rules and Procedures of the
						Judicial Arbitration and Mediation Services, Inc. (JAMS) then in effect, by one or more
						commercial arbitrator(s) with substantial experience in the industry and in resolving
						complex commercial contract disputes. Judgment upon the award so rendered may be entered
						in a court having jurisdiction or application may be made to such court for judicial
						acceptance of any award and an order of enforcement, as the case may be. Notwithstanding
						the foregoing, each party shall have the right to institute an action in any court of
						proper jurisdiction for injunctive relief. The prevailing party in any dispute arising
						under this Agreement shall be awarded its reasonable attorney fees and costs.
					</p>

					<p>
						Entirety. The Agreement comprises the entire agreement between Customer and OpenWrench
						and supersedes all prior or contemporaneous negotiations, discussions or agreements,
						whether written or oral, between the parties regarding the subject matter contained
						herein. In the event of any conflict between this Agreement and the Order Form, the
						Order Form shall govern. No text or information set forth on any other purchase order,
						preprinted form or document shall add to or vary the terms and conditions of this
						Agreement. If any provision of this Agreement is held by a court of competent
						jurisdiction to be invalid or unenforceable, then such provision(s) shall be construed,
						as nearly as possible, to reflect the intentions of the invalid or unenforceable
						provision(s), with all other provisions remaining in full force and effect. Customer
						agrees that Customer's purchase of any subscription is neither contingent upon the
						delivery of any future functionality or features nor dependent upon any oral or written
						comments made by OpenWrench with respect to future functionality or features. No joint
						venture, partnership, employment, or agency relationship exists between Customer and
						OpenWrench as a result of the Agreement or use of the Hosted Applications or OpenWrench
						Platform. The failure of a party to enforce any right or provision in this Agreement
						shall not constitute a waiver of such right or provision.
					</p>

					<p>
						Force Majeure. No party shall be liable or responsible to the other party, nor be deemed
						to have defaulted under or breached this Agreement, for any failure or delay in
						fulfilling or performing any term of this Agreement (excluding Customer's failure to pay
						amounts owed when due), when and to the extent such failure or delay is caused by or
						results from acts beyond the affected party's reasonable control, including without
						limitation: strikes, lock-outs or other industrial disputes (whether involving its own
						workforce or a third party's), trespassing, sabotage, theft or other criminal acts,
						cyber-attacks, failure of energy sources or transport network, acts of God, export bans,
						sanctions and other government actions, war, terrorism, riot, civil commotion,
						interference by civil or military authorities, national or international calamity, armed
						conflict, malicious damage, breakdown of plant or machinery, nuclear, chemical or
						biological contamination, explosions, collapse of building structures, fires, floods,
						storms, earthquakes, epidemics or similar events, natural disasters or extreme adverse
						weather conditions (each a "Force Majeure Event"). The party suffering a Force Majeure
						Event shall use reasonable efforts to mitigate against the effects of such Force Majeure
						Event.
					</p>
				</Card>
			</Layout.Content>
		);
	}
}

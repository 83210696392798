import { ASSET_NBVS_CRUD_ACTION_CREATORS } from '../actions/asset_nbvs_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { parseJSON } from './fetch_utils';
import { logoutSuccess } from '../actions/session_actions';
import { message } from 'antd';
import FetchUtils from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';

const fetchUtils = new FetchUtils();

export const assetNbvsRestCrudThunksForBuyer = new RestCrudThunks(
	'net_book_value_details',
	'asset_nbv',
	'assetNbv',
	'assetNbvs',
	ASSET_NBVS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'asset/'
);
export const assetNbvsRestCrudThunksForSupplier = new RestCrudThunks(
	'net_book_value_details',
	'asset_nbv',
	'assetNbv',
	'assetNbvs',
	ASSET_NBVS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'asset/'
);

const downloadAssetNBVCSV =
	(roleType) =>
	(params, filters = null) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/asset/net_book_value_details/csv_report_generator`;

		// if sorting present, fix order naming and add to query params

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.message);
							message.error(d.data);
						} else {
							resolve(d.data);
							const hide = message.loading(d.data, 0);
							setTimeout(hide, 7000);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};

export const downloadAssetNBVCSVForBuyer = downloadAssetNBVCSV(ROLE_TYPES.BUYER);
export const downloadAssetNBVCSVForSupplier = downloadAssetNBVCSV(ROLE_TYPES.SUPPLIER);

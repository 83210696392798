import * as React from 'react';
import { Layout, Row, Col, BackTop, Card, Button, Timeline, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ScrollToTopOnMount from '../scroll_to_top_on_mount/ScrollToTopOnMount';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { getObjectValues, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { dateFormatter } from '../../utils/DataFormatterUtils';
import moment from 'moment';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import OWResult from '../OWResult';
import ProgressBarComponent from '../progress_bar_component/ProgressBarComponent';
import { WORK_ORDER_STATUSES } from '../../constants/WorkOrderStatuses';
import { INVOICE_STATUSES } from '../../constants/InvoiceStatuses';
import { AttentionDashboard } from '../attention_dashboard/AttentionDashboard';
import { WorkOrderRowDisplay } from '../work_order_row_display/WorkOrderRowDisplay';
import { InvoiceRowDisplay } from '../invoice_row_display/InvoiceRowDisplay';
import {
	projectsRestCrudThunksForBuyer,
	projectsRestCrudThunksForSupplier,
} from '../../thunks/projects_thunks';
import { EmptyState } from '../empty_state/EmptyState';
import { ROLE_TYPES } from '../../utils/DataConstants';
import DateTimeHover from '../date_time_component/DateTime';

const { Content } = Layout;

const queryString = require('qs');

class ProjectsDetailOverviewPage extends React.Component<any, any> {
	formRefs: any;

	constructor(props) {
		super(props);
		this.formRefs = {};
		this.state = {
			loading: true,
			onlyReview: false,
			error: null,
		};

		const { location } = props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);

		if (queryParams.backlinkParams) {
			const { tab } = queryString.parse(queryParams.backlinkParams);
			this.setState({
				tab: tab ? tab : 'active',
			});
		}
	}

	refreshProject = () => {
		const { getProject, match, currentUser } = this.props;
		const projectId = match.params.id;
		const userType = nullSafeGetOrElse('userType', currentUser, 'buyer');
		const project = getProject(userType, projectId)
			.then((proj) => {
				this.setState({ loading: false });
				return proj;
			})
			.catch((e) => {
				this.setState({
					loading: false,
					error: e,
				});
			});
		return project;
	};

	componentDidMount() {
		this.refreshProject();
	}

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	render() {
		const { match, currentUser, location, history, project } = this.props;
		const { loading, error } = this.state;

		const baseURL = location.pathname.slice(0, location.pathname.lastIndexOf('/'));
		let projectDetails = loading
			? {}
			: {
					ID: `# ${nullSafeGet('id', project)}`,
					Budget: '$'.concat(nullSafeGetOrElse('budget', project, '0').toLocaleString()),
					['Created By']: nullSafeGetOrElse('createdBy', project, ''),
					['Created']: (
						<DateTimeHover timestamp={nullSafeGet('createdAt', project)} showTime={false} />
					),
					['Due']: <DateTimeHover timestamp={nullSafeGet('dueDate', project)} showTime={false} />,
			  };

		const filteredProjectDetails = Object.keys(projectDetails).reduce((acc, el) => {
			const val = projectDetails[el];
			if (val !== null && val !== 'undefined' && val !== undefined && val !== '') {
				acc[el] = projectDetails[el];
			}
			return acc;
		}, {});

		const workOrderColumns = [
			{
				title: '',
				dataIndex: 'createdAt',
				defaultSortOrder: 'descend',
				render: (text, record) => <WorkOrderRowDisplay simpler={true} workOrder={record} />,
			},
		];
		const invoiceColumns = [
			{
				title: 'Name',
				dataIndex: 'name',
				sorter: true,
				render: (text, record) => <InvoiceRowDisplay invoice={record} />,
			},
		];
		const onRow = (record) => ({
			onClick: () => {},
		});

		const keyValues = Object.keys(filteredProjectDetails).map((key) => {
			return (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						paddingRight: '20px',
						paddingTop: '12px',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'nowrap',
							color: 'rgba(0, 0, 0, 0.45)',
							whiteSpace: 'nowrap',
						}}
					>
						{key}
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'nowrap',
							color: 'rgba(0, 0, 0, 0.65)',
							fontSize: '16px',
							whiteSpace: 'nowrap',
						}}
					>
						{filteredProjectDetails[key]}
					</div>
				</div>
			);
		});

		if (error)
			return (
				<OWResult
					error={error}
					title="You no longer have access to this project."
					subTitle="Either its deleted or been reassigned."
				/>
			);
		let WONumber = 0;
		if (!loading && project && nullSafeGet('workOrderProjectMetrics', project)) {
			WONumber = getObjectValues(nullSafeGet('workOrderProjectMetrics', project)).reduce(
				(n1, n2) => n1 + n2
			);
			if (WONumber === 0)
				return (
					<EmptyState
						headline={'There are no work orders in this project!'}
						body={'Add work orders from the work order edit page.'}
					></EmptyState>
				);
		}
		return loading ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="ProjectsDetailOverviewPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited project overview page" />
				<ScrollToTopOnMount />
				<BackTop />
				<Row
					style={{
						marginLeft: '0.5em',
						marginRight: '0.5em',
						marginTop: '0.5em',
					}}
				>
					<Card title="details" style={{ width: '100%' }}>
						<div className="materialCard__supportingText" style={{ marginTop: '-12px' }}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									fontSize: '16px',
									color: 'rgba(0,0,0,0.65)',
								}}
							>
								{nullSafeGet('description', project)}
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									flexWrap: 'wrap',
									justifyContent: 'space-between',
								}}
							>
								{keyValues.map((keyValue) => keyValue)}
							</div>
						</div>
					</Card>
				</Row>
				<Row
					style={{
						display: 'flex',
						gap: '1em',
						flexGrow: 1,
						marginTop: '1em',
						marginLeft: '0.5em',
						marginRight: '0.5em',
					}}
				>
					<Col
						style={{
							display: 'flex',
							flexGrow: 1,
							flexWrap: 'nowrap',
						}}
					>
						<Card
							style={{ width: '100%' }}
							bodyStyle={{
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
								gap: '0px',
								justifyContent: 'space-between',
								paddingTop: '8px',
								paddingBottom: '8px',
							}}
						>
							<ProgressBarComponent
								//General
								header={'Work Orders'}
								items={[]}
								datapath={'status'}
								statusList={getObjectValues(WORK_ORDER_STATUSES)} // ['first','second','third']
								//Style Config
								barHeight={10}
								fixedGap={2}
								total={WONumber} //|'num'
								basis={'objects'} //|'amount'
								subTotalPath={null}
								subTotal={null}
								lineLabels={{
									display: 'Never',
								}}
								sectionLabels={{
									display: 'Always', //'Hover'|'Never'
								}}
								sectionList={[
									{
										label: 'Closed',
										statuses: [
											WORK_ORDER_STATUSES.invoiced,
											WORK_ORDER_STATUSES.internalTechWorkComplete,
											WORK_ORDER_STATUSES.internalTechWorkReviewedAndCompleted,
											WORK_ORDER_STATUSES.workReviewedAndCompleted,
											WORK_ORDER_STATUSES.paymentMade,
											WORK_ORDER_STATUSES.paymentAccepted,
										],
										itemQuantity: nullSafeGetOrElse(
											'workOrderProjectMetrics.workOrdersClosed',
											project,
											0
										),
										color: 'rgba(83,41,142,1)',
										onClick: () => {
											history.push(
												baseURL.concat('/workOrders?', 'displayStatuses=Completed,Cancelled')
											);
										},
									},
									{
										label: 'In Progress',
										color: 'rgba(83,41,142,0.5)',
										itemQuantity:
											nullSafeGetOrElse(
												'workOrderProjectMetrics.workOrdersInProgress',
												project,
												0
											) + nullSafeGetOrElse('workOrderProjectMetrics.workOrdersOnHold', project, 0),
										onClick: () => {
											history.push(
												baseURL.concat('/workOrders?', 'displayStatuses=InProgress,OnHold')
											);
										},
									},
									{
										label: 'Opened',
										statuses: [
											WORK_ORDER_STATUSES.unassigned,
											WORK_ORDER_STATUSES.assignedToInternalTech,
											WORK_ORDER_STATUSES.pendingConfirmationByServiceProvider,
										],
										color: 'rgba(83,41,142,0.1)',
										itemQuantity: nullSafeGetOrElse(
											'workOrderProjectMetrics.workOrdersNotStarted',
											project,
											0
										),
										onClick: () => {
											history.push(baseURL.concat('/workOrders?', 'displayStatuses=Pending,Open'));
										},
									},
								]}
							/>
							<ProgressBarComponent
								//General
								header={'Budget'}
								items={[]}
								datapath={'status'}
								statusList={getObjectValues(INVOICE_STATUSES)} // ['first','second','third']
								//Style Config
								style={{ marginTop: 10 }}
								barHeight={10}
								fixedGap={0}
								total={project.budget} //|'num'
								basis={'amount'} //|'amount'
								subTotalPath={'amount'}
								otherColor={'rgba(49,130,66,.1)'}
								negativeColor={'orange'}
								otherOnClick={() => {
									history.push(baseURL.concat('/invoices', ''));
								}}
								lineLabels={{
									display: 'Always',
								}}
								sectionLabels={{
									display: 'Always', //'Hover'|'Never'
								}}
								sectionList={[
									{
										label: 'Approved',
										statuses: [INVOICE_STATUSES.paid, INVOICE_STATUSES.approved],
										color: 'rgba(49,130,66,1)',
										subTotal: nullSafeGetOrElse(
											'invoiceProjectMetrics.totalInvoicesAmountApproved',
											project,
											0
										),
										onClick: () => {
											history.push(
												baseURL.concat('/invoices?', 'statuses=Approved,Processing,Paid')
											);
										},
									},
									{
										label: 'Pending',
										statuses: [
											INVOICE_STATUSES.pending,
											INVOICE_STATUSES.transferred,
											INVOICE_STATUSES.processing,
										],
										color: 'rgba(49,130,66,0.5)',
										subTotal:
											nullSafeGetOrElse(
												'invoiceProjectMetrics.totalInvoiceAmountAccrued',
												project,
												0
											) -
											nullSafeGetOrElse(
												'invoiceProjectMetrics.totalInvoicesAmountApproved',
												project,
												0
											),
										onClick: () => {
											history.push(baseURL.concat('/invoices?', 'statuses=Pending'));
										},
									},
								]}
							/>
						</Card>
					</Col>
				</Row>
				<Row style={{ width: '100%', gap: '.5em' }}></Row>
				<Row style={{ width: '100%', marginLeft: '0.5em' }}>
					<AttentionDashboard
						onRow={onRow}
						workOrderColumns={workOrderColumns}
						invoiceColumns={invoiceColumns}
						additionalFilters={{ project: nullSafeGet('id', projectDetails) }}
						globalFilters={{
							buyerProjectId: project.id,
						}}
					/>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	project: state.projects.detail,
	fetching: state.projects.fetching,
	workOrder: state.work_orders.detail,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	getProject: (userType, id) =>
		dispatch(
			userType === ROLE_TYPES.SUPPLIER
				? projectsRestCrudThunksForSupplier.readOne(id)
				: projectsRestCrudThunksForBuyer.readOne(id)
		),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectsDetailOverviewPage));

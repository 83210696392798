import React from 'react';
import { getCurrency, nullSafeGet } from '../../../utils/DataAccessUtils';
import WorkOrderDisplayStatusDisplay from '../../work_order_display_status_display/WorkOrderDisplayStatusDisplay';
import { ROLE_TYPES } from '../../../utils/DataConstants';
import QuoteDisplayStatusDisplay from '../../quote_display_status_display/QuoteDisplayStatusDisplay';
import { QUOTE_STATUS_DISPLAY_NAMES } from '../../../constants/QuoteStatuses';
import InvoiceDisplayStatusDisplay from '../../invoice_display_status_display/InvoiceDisplayStatusDisplay';
import { INVOICE_STATUS_DISPLAY_NAMES } from '../../../constants/InvoiceStatuses';

export default function NotificationTemplate({ notification, userType }) {
	switch (notification.notificationType) {
		case 'WorkOrderNotes':
		case 'WorkOrderNotesMentions':
			const noteText =
				ROLE_TYPES.SUPPLIER === userType
					? nullSafeGet('supplierPushNotification.noteText', notification)
					: nullSafeGet('buyerPushNotification.noteText', notification);
			return noteText ? (
				<div className="mt-4 rounded-xl bg-slate-200 p-3 text-base text-gray-500 ">
					"{noteText}"
				</div>
			) : null;

		case 'InvoiceApproval':
		case 'InvoiceApprovalReminder':
		case 'Invoiced':
			return nullSafeGet('invoice.id', notification) &&
				nullSafeGet('workOrder.id', notification) ? (
				<div className="mt-2 grid gap-y-3 gap-x-4 rounded-xl border-2 border-solid border-slate-200 p-3 text-base text-gray-500">
					<div className="font-semibold">{nullSafeGet('workOrder.title', notification)}</div>
					<div className="flex flex-row  items-center gap-x-4">
						<div className="rowInlineGroup">
							<span className="rowGrayChip">
								<i className="icons8-font icons8-location-marker text-md" />
								<span>{nullSafeGet('workOrder.location.name', notification)}</span>
							</span>
						</div>
						<div className="rowInlineGroup" style={{ display: 'inline-flex' }}>
							<InvoiceDisplayStatusDisplay
								status={INVOICE_STATUS_DISPLAY_NAMES[nullSafeGet('invoice.status', notification)]}
							/>
						</div>
						{getCurrency({ workOrder: nullSafeGet('workOrder', notification) }).format(
							nullSafeGet('invoice.invoiceTotalAfterTax', notification)
						)}
					</div>
				</div>
			) : null;

		case 'ProposalApproval':
		case 'ProposalApprovalReminder':
		case 'RFP_RequestProposal':
		case 'RFP_Participating':
		case 'RFP_NotParticipating':
		case 'RFP_Awarded':
		case 'RFP_Declined':
		case 'RFP_RequestForProposalEnded':
		case 'WaitingForQuote':
		case 'Proposal':
		case 'ProposalAwarded':
		case 'ProposalDeclined':
			return nullSafeGet('quote.id', notification) && nullSafeGet('workOrder.id', notification) ? (
				<div className="mt-2 grid gap-y-3 gap-x-4 rounded-xl border-2 border-solid border-slate-200 p-3 text-base text-gray-500">
					<div className="font-semibold">{nullSafeGet('workOrder.title', notification)}</div>
					<div className="flex flex-row  items-center gap-x-4">
						<div className="rowInlineGroup">
							<span className="rowGrayChip">
								<i className="icons8-font icons8-location-marker text-md" />
								<span>{nullSafeGet('workOrder.location.name', notification)}</span>
							</span>
						</div>
						<div className="rowInlineGroup" style={{ display: 'inline-flex' }}>
							<QuoteDisplayStatusDisplay
								status={QUOTE_STATUS_DISPLAY_NAMES[nullSafeGet('quote.status', notification)]}
							/>
						</div>
						{getCurrency({ workOrder: nullSafeGet('workOrder', notification) }).format(
							nullSafeGet('quote.totalAfterTax', notification)
						)}
					</div>
				</div>
			) : null;

		default:
			return nullSafeGet('workOrder.id', notification) ? (
				<div className="mt-2 grid gap-y-3 gap-x-4 rounded-xl border-2 border-solid border-slate-200 p-3 text-base text-gray-500">
					<div className="font-semibold">{nullSafeGet('workOrder.title', notification)}</div>
					<div className="flex flex-row  items-center gap-x-4">
						<div className="rowInlineGroup">
							<span className="rowGrayChip">
								<i className="icons8-font icons8-location-marker text-md" />
								<span>{nullSafeGet('workOrder.location.name', notification)}</span>
							</span>
						</div>
						<div className="rowInlineGroup" style={{ display: 'inline-flex' }}>
							<WorkOrderDisplayStatusDisplay
								status={nullSafeGet('workOrder.displayStatus', notification)}
							/>
						</div>
					</div>
				</div>
			) : null;
	}
}

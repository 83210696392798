import React, { FC, useCallback, useMemo, useState } from 'react';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { Button, Popover, message } from 'antd';
import { DownloadOutlined, EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { EmptyState } from '../empty_state/EmptyState';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { EQUIPMENT_TYPES_PER_STOCK_LOCATION_CRUD_ACTION_CREATORS } from '../../actions/equipment_types_per_stock_location_actions';
import {
	equipmentTypesPerStockLocationRestCrudThunksForSupplier,
	fetchAllEquipmentTypesPerStockLocactionForSupplier,
} from '../../thunks/equipment_types_per_stock_location_thunks';
import AddEquipmentLevelModal from './AddEquipmentLevelModal';
import {
	nullSafeGet,
	nullSafeGetOrElse,
	renderCurrency,
	stopDefaultEvents,
} from '../../utils/DataAccessUtils';
import { EquipmentRowDisplay } from '../equipment_row_display/EquipmentRowDisplay';
import BulkTransferEquipmentTypeModal from './BulkTransferEquipmentTypeModal';
import {
	getRecordsForCompositeTargetCollection,
	getRecordsForTargetCollection,
} from '../../reducers/standard_reducer_utils';
import { QuantityRowDisplay } from '../part_quantity_row_display/PartQuantityRowDisplay';
import FeildTechSLManagePermissionChecker, {
	canFieldManageStockLocation,
} from '../common/FeildTechSLManagePermissionChecker';
import EditEquipmentLevelBulk from './EditEquipmentLevelBulk';
import UploadWithSampleModal from '../parts_index_page/UploadWithSampleModal';
import {
	EQUIPMENT_FLATFILE_HEADER,
	getFlatfileRecordForEquipmentLevel,
	getRecordForExport,
	getStockLocationEquipmentTypeLevelExportFields,
} from './stock_location_constants';
import { ExportToCsv } from 'export-to-csv';
import { CSV_EXPORT_DEFAULTS } from '../../utils/DataConstants';
import moment from 'moment';
import { DATE_FORMAT } from '../../utils/DataFormatterUtils';

const TC_NAME = 'equipmentTypesPerStockLocationIndex';

const StockLocationsEquipmentLevelPage: FC<any> = ({
	match,
	history,
	updateFilters,
	equipmentTypesPerStockLocation,
	clearAndUpdateFilters,
	fetchEquipmentTypesPerStockLocation,
	currentUser,
	stockLocation,
	fetchAllEquipmentTypesPerStockLocation,
	createBulk,
}): React.ReactElement => {
	const [addEquipmentVisible, setAddEquipmentVisible] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [transferModalVisible, setTransferModalVisible] = useState(false);

	const stockLocationId = useMemo(() => match.params.id, [match.params.id]);

	const [fetchingEqLevels, setFetchingEqLevels] = useState(false);
	const [equipmentLevels, setEquipmentLevels] = useState([]);

	const [downloading, setDownloading] = useState(false);
	const [exportingCsv, setExportingCsv] = useState(false);
	const [uploadPopupVisible, setUploadPopupVisible] = useState(false);

	const editAllowed = useMemo(
		() => getRecordsForTargetCollection(equipmentTypesPerStockLocation, TC_NAME).length > 0,
		[equipmentTypesPerStockLocation]
	);

	const filterConfig = useMemo(
		() => [
			{
				fieldName: 'isThresholdReached',
				type: 'radioGroup',
				items: [
					{
						label: 'All',
						value: 'undefined',
					},
					{
						label: 'Less than min quantity',
						value: 'true',
					},
				],
				defaultValue: undefined,
				targetCollectionName: TC_NAME,
				showOutsideDropdown: true,
			},
			{
				fieldName: 'isOutOfStock',
				type: 'radioGroup',
				items: [
					{
						label: 'All',
						value: 'undefined',
					},
					{
						label: 'Out of stock',
						value: 'true',
					},
				],
				defaultValue: undefined,
				targetCollectionName: TC_NAME,
				showOutsideDropdown: true,
			},
		],
		[]
	);

	const onQtyClick = useCallback(
		(record) => (e) => {
			stopDefaultEvents(e);
			history.push(
				`/supplier/stockLocations/detail/${stockLocationId}/receivedEquipment?stockLocationId=${stockLocationId}&isDisbursed=false&equipmentTypeIds=${nullSafeGet(
					'equipmentType.id',
					record
				)}`
			);
		},
		[history, stockLocationId]
	);

	const columns = useMemo(
		() => [
			{
				title: 'Equipment Type',
				dataIndex: 'equipmentType',
				render: (_) => <EquipmentRowDisplay equipmentType={_} />,
			},
			{
				title: 'Min / Max',
				render: (_, record) => (
					<div className="flex flex-col">
						<div>{`Min - ${nullSafeGetOrElse('minQuantity', record, '--')}`}</div>
						<div>{`Max - ${nullSafeGetOrElse('maxQuantity', record, '--')}`}</div>
					</div>
				),
			},
			{
				title: 'Total Qty',
				dataIndex: 'onHandQuantity',
				render: (quantity, record) => (
					<QuantityRowDisplay
						min={record.minQuantity}
						max={record.maxQuantity}
						quantity={quantity}
						onClick={quantity ? onQtyClick(record) : undefined}
					/>
				),
			},
			{
				title: 'Requested Qty',
				render: () => 0,
			},
			{
				title: 'Available Qty',
				dataIndex: 'onHandQuantity',
			},
			{
				title: 'Total Cost',
				dataIndex: 'totalStockedEquipmentCost',
				render: renderCurrency(currentUser),
			},
		],
		[currentUser, onQtyClick]
	);

	const getStatSliceProp = useCallback(
		(propName) => nullSafeGetOrElse(`${TC_NAME}.${propName}`, equipmentTypesPerStockLocation, {}),
		[equipmentTypesPerStockLocation]
	);

	const refreshEquipmentTypes = useCallback(() => {
		fetchEquipmentTypesPerStockLocation(
			{},
			TC_NAME,
			getStatSliceProp('pagination'),
			getStatSliceProp('sorting'),
			getStatSliceProp('filters')
		);
	}, [fetchEquipmentTypesPerStockLocation, getStatSliceProp]);

	const hideAddEquipment = useCallback(() => setAddEquipmentVisible(false), []);

	const onAddEquipment = useCallback(() => setAddEquipmentVisible(true), []);

	const onAddEquipmentSuccess = useCallback(() => {
		hideAddEquipment();
		refreshEquipmentTypes();
	}, [hideAddEquipment, refreshEquipmentTypes]);

	const canTransfer = useMemo(() => selectedRowKeys.length > 0, [selectedRowKeys.length]);

	const onSelectChange = useCallback((newSelectedRowKeys: React.Key[]) => {
		setSelectedRowKeys(newSelectedRowKeys);
	}, []);

	const rowSelection = useMemo(
		() => ({
			selectedRowKeys,
			preserveSelectedRowKeys: true,
			onChange: onSelectChange,
			getCheckboxProps: (record) => ({
				disabled: !record.onHandQuantity,
			}),
		}),
		[onSelectChange, selectedRowKeys]
	);

	const onTransferClick = useCallback(() => setTransferModalVisible(true), []);

	const hideTransferModal = useCallback(() => setTransferModalVisible(false), []);

	const onSelectCancel = useCallback(() => setSelectedRowKeys([]), []);

	const selectedRecords = useMemo(() => {
		const records = getRecordsForCompositeTargetCollection(equipmentTypesPerStockLocation, TC_NAME);
		return records.filter((_) => selectedRowKeys.includes(_.id));
	}, [equipmentTypesPerStockLocation, selectedRowKeys]);

	const onTransferEquipmentsSuccess = useCallback(() => {
		hideTransferModal();
		onSelectCancel();
		refreshEquipmentTypes();
	}, [hideTransferModal, onSelectCancel, refreshEquipmentTypes]);

	const fetchCurrentSLEntities = useCallback(
		() => fetchAllEquipmentTypesPerStockLocation({ stockLocationId }),
		[fetchAllEquipmentTypesPerStockLocation, stockLocationId]
	);

	const onBulkEdit = useCallback(() => {
		setFetchingEqLevels(true);
		setEquipmentLevels([]);
		fetchCurrentSLEntities()
			.then((res) => setEquipmentLevels(res))
			.finally(() => setFetchingEqLevels(false));
	}, [fetchCurrentSLEntities]);

	const onBulkEditSuccess = useCallback(() => {
		setEquipmentLevels([]);
		refreshEquipmentTypes();
	}, [refreshEquipmentTypes]);

	const showUploadPopup = useCallback(() => {
		setEquipmentLevels([]);
		setUploadPopupVisible(true);
	}, []);
	const hideUploadPopup = useCallback(() => setUploadPopupVisible(false), []);

	const exportAsCsv = useCallback(
		(data, headers) => {
			const date = moment().format(DATE_FORMAT);
			const csvTitle = `${stockLocation.name}_${date}_equipment_level`;
			const csvExporter = new ExportToCsv({
				...CSV_EXPORT_DEFAULTS,
				filename: csvTitle,
				title: csvTitle,
				headers,
			});

			csvExporter.generateCsv(data);
		},
		[stockLocation.name]
	);

	const onDownloadSample = useCallback(() => {
		setDownloading(true);
		fetchCurrentSLEntities()
			.then((els) => {
				const data =
					els && els.length > 0
						? els.map((_) => getFlatfileRecordForEquipmentLevel(_))
						: [getFlatfileRecordForEquipmentLevel()];
				exportAsCsv(data, [
					'Record ID',
					'OW Equipment Type ID',
					'Name',
					'Equipment Type #',
					'On Hand Quantity',
					'Min Qty',
					'Max Qty',
				]);
			})
			.finally(() => setDownloading(false));
	}, [exportAsCsv, fetchCurrentSLEntities]);

	const downloadCSV = useCallback(() => {
		setExportingCsv(true);
		fetchCurrentSLEntities()
			.then((res) => {
				if (res.length > 0) {
					const headerFields = getStockLocationEquipmentTypeLevelExportFields(currentUser);
					const data = res.map((_) => getRecordForExport(_, headerFields));
					exportAsCsv(
						data,
						headerFields.map((_) => _.label)
					);
				} else message.error('No equipment type level to export!');
			})
			.finally(() => setExportingCsv(false));
	}, [currentUser, exportAsCsv, fetchCurrentSLEntities]);

	const onDataUpload = useCallback(
		(results) => {
			return new Promise((resolve, reject) => {
				createBulk(
					results.validData.map((_) => ({
						id: _.id ? parseInt(_.id) : undefined,
						stockLocationId: parseInt(stockLocationId),
						supplierFacilityId: parseInt(nullSafeGet('supplierFacilityId', stockLocation)),
						supplierCompanyId: parseInt(nullSafeGet('supplierCompanyId', stockLocation)),
						equipmentTypeId: parseInt(_.equipmentTypeId),
						minQuantity: parseInt(_.minQuantity),
						maxQuantity: parseInt(_.maxQuantity),
					}))
				)
					.then((res) => {
						resolve('Updated successfully!');
						setTimeout(() => {
							hideUploadPopup();
							message.success('Updated successfully!');
							refreshEquipmentTypes();
						}, 0);
					})
					.catch(() => {
						reject('Unable to upload! Please contact support!');
					});
			});
		},
		[createBulk, hideUploadPopup, refreshEquipmentTypes, stockLocation, stockLocationId]
	);

	return (
		<>
			<PaginatedReduxTableWithHeader
				targetCollectionName={TC_NAME}
				updateFilters={updateFilters}
				stateSlice={equipmentTypesPerStockLocation}
				clearAndUpdateFilters={clearAndUpdateFilters}
				filterConfig={filterConfig}
				entityCollectionName="equipment_types_per_stock_location"
				tableColumns={columns}
				rowSelection={canFieldManageStockLocation(currentUser, stockLocation) ? rowSelection : null}
				showHeader
				preAppliedFilters={{ stockLocationId }}
				fetchData={equipmentTypesPerStockLocationRestCrudThunksForSupplier.read}
				hasDefaultHeaderPage
				initialSorters={{
					sort_by: 'modelName',
					order: 'ascend',
				}}
				rightActions={
					canTransfer ? (
						<div>
							<Button onClick={onSelectCancel} size="large">
								Cancel
							</Button>
							<Button className="ml-2" size="large" type="primary" onClick={onTransferClick}>
								Transfer
							</Button>
						</div>
					) : (
						<div className="flex-start flex flex-row">
							<FeildTechSLManagePermissionChecker>
								<Popover content="Download or Upload Equipment Level" trigger="hover">
									<Button
										size="large"
										className="inline-block-visible-md"
										icon={<UploadOutlined translate="" />}
										onClick={showUploadPopup}
									>
										<span className="inline-block-visible-xxl">Upload</span>
									</Button>
								</Popover>
								<Popover content="Export CSV" trigger="hover">
									<Button
										size="large"
										icon={<DownloadOutlined translate="" />}
										className="inline-block-visible-md ml-2"
										onClick={downloadCSV}
										loading={exportingCsv}
									>
										<span className="inline-block-visible-xxl">Export CSV</span>
									</Button>
								</Popover>
							</FeildTechSLManagePermissionChecker>
							<FeildTechSLManagePermissionChecker>
								<Popover
									content={editAllowed ? 'Edit Equipment Level' : 'No Equipment available to edit'}
									trigger="hover"
								>
									<Button
										size="large"
										className="inline-block-visible-md ml-2"
										loading={fetchingEqLevels}
										icon={<EditOutlined translate="" />}
										onClick={onBulkEdit}
										disabled={!editAllowed}
									>
										Edit
									</Button>
								</Popover>
							</FeildTechSLManagePermissionChecker>
							<FeildTechSLManagePermissionChecker>
								<Popover content="Add Equipment" trigger="hover">
									<Button
										size="large"
										className="inline-block-visible-md ml-2"
										icon={<PlusOutlined translate="" />}
										type="primary"
										onClick={onAddEquipment}
									>
										Add
									</Button>
								</Popover>
							</FeildTechSLManagePermissionChecker>
						</div>
					)
				}
				emptyState={
					<EmptyState
						graphic={
							<img
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/sunset.svg"
								alt="No Equipment Level to display"
							/>
						}
						headline={'No Equipment Level to display.'}
						body={
							<div style={{ textAlign: 'center' }}>
								<div style={{ maxWidth: 440, marginBottom: 16 }}>
									You haven't added any equipment to this location yet.
								</div>
							</div>
						}
					/>
				}
			/>
			{addEquipmentVisible && (
				<AddEquipmentLevelModal
					onCancel={hideAddEquipment}
					onSuccess={onAddEquipmentSuccess}
					stockLocationId={stockLocationId}
				/>
			)}
			{transferModalVisible && (
				<BulkTransferEquipmentTypeModal
					onCancel={hideTransferModal}
					stockLocationId={stockLocationId}
					selectedEquipmentTypes={selectedRecords}
					onSuccess={onTransferEquipmentsSuccess}
				/>
			)}
			{!fetchingEqLevels && (
				<EditEquipmentLevelBulk
					equipmentLevels={equipmentLevels}
					onSuccess={onBulkEditSuccess}
					onDataUpload={onDataUpload}
				/>
			)}
			{uploadPopupVisible && (
				<UploadWithSampleModal
					type="Equipment Level"
					title="Upload Equipment Level"
					downloadText="Do you want to download the existing equipment level?"
					sampleFileText="Download Equipment Level"
					onCancel={hideUploadPopup}
					onDownloadSample={onDownloadSample}
					downloading={downloading}
					fields={EQUIPMENT_FLATFILE_HEADER}
					onDataLoad={onDataUpload}
					showWarningMessage
				/>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	equipmentTypesPerStockLocation: state.equipment_types_per_stock_location,
	currentUser: state.session.currentUser,
	stockLocation: state.stock_locations.detail,
});

const mapDispatchToProps = (dispatch) => ({
	fetchEquipmentTypesPerStockLocation: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			equipmentTypesPerStockLocationRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchAllEquipmentTypesPerStockLocation: (params) =>
		dispatch(
			fetchAllEquipmentTypesPerStockLocactionForSupplier(params, {
				sort_by: 'modelName',
				order: 'ascend',
			})
		),
	updateFilters: (filters, targetCollection) =>
		dispatch(
			EQUIPMENT_TYPES_PER_STOCK_LOCATION_CRUD_ACTION_CREATORS.updateFilters(
				filters,
				targetCollection
			)
		),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			EQUIPMENT_TYPES_PER_STOCK_LOCATION_CRUD_ACTION_CREATORS.clearAndUpdateFilters(
				filters,
				targetCollectionName
			)
		),
	createBulk: (entities) =>
		dispatch(equipmentTypesPerStockLocationRestCrudThunksForSupplier.createBulk(entities)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(StockLocationsEquipmentLevelPage)
);

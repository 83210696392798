import * as React from 'react';
import { Layout, Row, Col, Card, Button, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AddressDisplay } from '../address_display/AddressDisplay';
import { isAdminUser } from '../../utils/DataAccessUtils';
import {
	optionalFilter,
	stringArrToList,
	userRoleTextFormatter,
} from '../../utils/DataFormatterUtils';
import { Link } from 'react-router-dom';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { FlexibleImageAvatar } from '../flexible-image-avatar/FlexibleImageAvatar';
import { getProtectedImageUriForBuyer } from '../../utils/FileAccessUtils';
import { checkUserTypeToken } from '../../thunks/session_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { TwoLineList } from '../two_line_list/TwoLineList';
import { buyerRoleUpdateUser } from '../../thunks/buyer_contacts_thunks';
import { locationsRestCrudThunksForBuyer } from '../../thunks/locations_thunks';
import BuyerLocationUpdateForm from '../buyer_location_update_form/BuyerLocationUpdateForm';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';

const { Content } = Layout;
require('./MyBuyerProfilePage.less');

class MyBuyerProfilePage extends React.Component<any, any> {
	formRefs: any;

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			locations: [],
			updatingLocation: false,
		};
		this.handleBuyerRoleUpdateSubmit = this.handleBuyerRoleUpdateSubmit.bind(this);
		this.handleBuyerRoleUpdateCancel = this.handleBuyerRoleUpdateCancel.bind(this);
		this.formRefs = {
			roleUpdateForm: null,
		};
	}

	componentDidMount() {
		const { refreshCurrentUser, fetchLocations } = this.props;
		fetchLocations({}).then((locations) => {
			this.setState({ locations: locations });
			refreshCurrentUser().then(() => {
				this.setState({ loading: false });
			});
		});
	}

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	showRoleUpdateModal = (record) => (e) => {
		const { locations } = this.state;
		this.setState({
			roleUpdateVisible: true,
			userContact: {
				contact: {
					...record,
				},
				role: record['roles'],
				locationIds: locations.map((l) => l.id),
			},
		});
	};

	handleBuyerRoleUpdateSubmit() {
		const { roleUpdateUser, history } = this.props;
		const form = this.formRefs['roleUpdateForm'].props.form;
		this.setState({ updatingLocation: true });
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			const updateRole = {
				userId: values.email,
				roles: [values.roles],
				locationIds: values.locationIds,
			};

			roleUpdateUser(updateRole)
				.then(() => {
					this.setState({ roleUpdateVisible: false });
					form.resetFields();
					message.success(`Location Access Updated`);
					history.push(`/buyer/locations/overview/all`);
				})
				.catch((err) => message.error(err))
				.finally(() => this.setState({ updatingLocation: false }));
		});
	}

	handleBuyerRoleUpdateCancel(e) {
		this.setState({
			roleUpdateVisible: false,
		});
	}

	render() {
		const { buyer, match, currentUser } = this.props;
		const buyerContactId = match.params.id;
		const dateFormat = 'MMM D, YYYY';
		const { loading } = this.state;
		const userDetails = [
			{ key: 'Roles', value: stringArrToList(currentUser.roles.map(userRoleTextFormatter)) },
			{ key: 'Email', value: currentUser.email },
			{ key: 'Work Phone', value: optionalFilter(currentUser.phoneWork) },
			{ key: 'Mobile Phone', value: optionalFilter(currentUser.phoneMobile) },
			{ key: 'Fax', value: optionalFilter(currentUser.fax) },
		];
		const facilityDetails = [
			{
				key: 'Facility',
				value: (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div style={{ marginRight: 8 }}>
							<FlexibleImageAvatar
								displayName={currentUser.facility.displayName}
								showPopover={false}
								popoverText={currentUser.facility.displayName}
								imageUrl={currentUser.facility.logoURL}
								width="32px"
								height="32px"
							/>
						</div>
						<div style={{ fontSize: 16 }}>{currentUser.facility.displayName}</div>
					</div>
				),
			},
			{
				key: 'Address',
				value: <AddressDisplay address={currentUser.facility.primaryAddress} />,
			},
		];

		let imgFileId, imgFileName, photoUri;
		if (currentUser.photoLink) {
			[imgFileId, imgFileName] = currentUser.photoLink.split('/');
			photoUri = getProtectedImageUriForBuyer(imgFileId, imgFileName, 'auto', 240);
		}

		return (
			<Content className="buyerProfilePage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited my buyer profile page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<BuyerLocationUpdateForm
					wrappedComponentRef={this.saveFormRef('roleUpdateForm')}
					visible={this.state.roleUpdateVisible}
					formData={this.state.userContact}
					onCancel={this.handleBuyerRoleUpdateCancel}
					onSubmit={this.handleBuyerRoleUpdateSubmit}
					submitting={this.state.updatingLocation}
				/>
				{loading ? (
					<PageLoadingPlaceholder />
				) : (
					<div>
						<Row style={{ margin: '0.5em -8px' }} gutter={16}>
							<Col span={24}>
								<Card>
									<div className="buyerProfilePage__header">
										<div style={{ float: 'left', display: 'flex', alignItems: 'center' }}>
											<div style={{ marginRight: 16 }}>
												<FlexibleImageAvatar
													displayName={currentUser.nameGiven + ' ' + currentUser.nameFamily}
													showPopover={false}
													popoverText={''}
													imageUrl={photoUri}
													width="64px"
													height="64px"
												/>
											</div>
											<div
												style={{
													height: 80,
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'center',
												}}
											>
												<h4 style={{ marginBottom: 0 }}>
													{currentUser.nameGiven} {currentUser.nameFamily}
												</h4>
												{currentUser.department && currentUser.title ? (
													<div className="materialCard__title">
														{currentUser.title}, {currentUser.department}
													</div>
												) : null}
											</div>
										</div>
										<div
											style={{
												float: 'right',
												width: '60%',
												display: 'flex',
												justifyContent: 'flex-end',
												maxWidth: 550,
											}}
										>
											{isAdminUser(currentUser) ? null : (
												<AccessPermissionChecker
													name={PERMISSION_NAMES.MODIFY_SELF_LOCATION_ACCESS}
												>
													<Button
														style={{ marginRight: 16 }}
														onClick={this.showRoleUpdateModal(currentUser)}
													>
														Edit Location Access
													</Button>
												</AccessPermissionChecker>
											)}
											<Link to="/buyer/myProfile/edit">
												<Button>Edit Profile</Button>
											</Link>
										</div>
									</div>
								</Card>
							</Col>
						</Row>

						<Row style={{ margin: '-0.5em -8px 0.5em -8px' }} gutter={16}>
							<Col span={24}>
								<Row style={{ margin: '0.5em -8px' }} gutter={16}>
									<Col span={24}>
										<Card title="Account Details">
											<TwoLineList
												listItems={userDetails}
												lineOneRender={(record) => record.value}
												lineTwoRender={(record) => record.key}
											/>
										</Card>
									</Col>
								</Row>
								<Row style={{ margin: '0.5em -8px' }} gutter={16}>
									<Col span={24}></Col>
								</Row>
							</Col>
						</Row>
					</div>
				)}
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	currentUser: state.session.currentUser,

	locations: state.locations,
});

const mapDispatchToProps = (dispatch) => ({
	refreshCurrentUser: () => dispatch(checkUserTypeToken(ROLE_TYPES.BUYER)()),
	roleUpdateUser: (entity) => dispatch(buyerRoleUpdateUser(entity)),
	fetchLocations: (params) => dispatch(locationsRestCrudThunksForBuyer.read(params)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyBuyerProfilePage));

import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Alert, Button, DatePicker, Select, InputNumber, Radio, Checkbox } from 'antd';
import { connect } from 'react-redux';
import {
	bulkCreatePlannedMaintenanceSchedules,
	bulkUpdatePlannedMaintenanceSchedules,
	plannedMaintenanceScheduleDeleteForBuyer,
	plannedMaintenanceScheduleDeleteForSupplier,
} from '../../thunks/planned_maintenance_schedules_thunks';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import BackButton from '../back_button/BackButton';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import {
	locationsRestCrudThunksForBuyer,
	locationsRestCrudThunksForSupplier,
} from '../../thunks/locations_thunks';
import {
	plannedMaintenanceTemplatesRestCrudThunksForBuyer,
	plannedMaintenanceTemplatesRestCrudThunksForSupplier,
} from '../../thunks/planned_maintenance_templates_thunks';
import {
	assetsRestCrudThunksForBuyer,
	assetsRestCrudThunksForSupplier,
} from '../../thunks/assets_thunks';
import {
	assetTypesRestCrudThunksForBuyer,
	assetTypesRestCrudThunksForSupplier,
} from '../../thunks/asset_types_thunks';

import {
	fetchSupplierFacilitiesInPrivateNetworkForBuyer,
	fetchSupplierFacilitiesInPrivateNetworkForSupplier,
	supplierFacilitiesRestCrudThunksForBuyer,
	supplierFacilitiesRestCrudThunksForSupplier,
} from '../../thunks/supplier_facilities_thunks';
import { debounce } from '../../utils/PerformanceUtils';
import { getBuyerCompanyApprovalTiers } from '../../thunks/company_approval_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { BUYER_ADMIN_ONLY } from '../roles/roles';
import _ from 'lodash';
import { cross_join } from '../../utils/ArrayUtils';
import { Option } from 'antd/es/mentions';
import { DAYS_OF_THE_WEEK } from '../../constants/times';
import {
	breakdownFrequencyText,
	capitalize,
	getFrequencyUnit,
	getNumberSuffix,
	getWeekOfTheMonth,
	isLastDayOfTheMonth,
} from '../../utils/DataFormatterUtils';
import BuyerRegionsLocationsTreeSelect from '../regions_tree_select/BuyerRegionsLocationsTreeSelect';
import AssetNumberDisplay from '../asset_row_display/AssetNumberDisplay';
import FileUploader from '../file_uploader/FileUploader';
import OWDatePicker from '../ow_date_picker/OWDatePicker';

const moment = require('moment');

require('./AddPlannedMaintenanceScheduleForm.less');
const PLANNED_MAINTENANCE_SCHEDULE_FORM_LOCATIONS_AUTOCOMPLETE =
	'PLANNED_MAINTENANCE_SCHEDULE_FORM_LOCATIONS_AUTOCOMPLETE';
const PLANNED_MAINTENANCE_SCHEDULE_FORM_ASSETS_AUTOCOMPLETE =
	'PLANNED_MAINTENANCE_SCHEDULE_FORM_ASSETS_AUTOCOMPLETE';
const PLANNED_MAINTENANCE_SCHEDULE_FORM_ASSET_TYPES_AUTOCOMPLETE =
	'PLANNED_MAINTENANCE_SCHEDULE_FORM_ASSET_TYPES_AUTOCOMPLETE';
const PLANNED_MAINTENANCE_SCHEDULE_FORM_PLANNED_MAINTENANCE_TEMPLATES_AUTOCOMPLETE =
	'PLANNED_MAINTENANCE_SCHEDULE_FORM_PLANNED_MAINTENANCE_TEMPLATES_AUTOCOMPLETE';
const PLANNED_MAINTENANCE_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE =
	'PLANNED_MAINTENANCE_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE';

const FORM_FIELD_WIDTH_STYLE = { maxWidth: 600 };

interface AddPlannedMaintenanceScheduleFormProps extends FormComponentProps {
	creating: boolean;
	updating: boolean;
	formData: any;
	history: any;
	redirectForwardUrl?: string;
	onSuccess?: any;
	plannedMaintenanceTemplates: any;
	fetchPlannedMaintenanceTemplates: any;
	fetchMultiplePlannedMaintenanceTemplates: any;
	fetchLocations: any;
	fetchMultipleLocations: any;
	fetchAssets: any;
	fetchMultipleAssets: any;
	fetchAssetTypes: any;
	fetchMultipleAssetTypes: any;
	locations: any;
	assets: any;
	assetTypes: any;
	createErrors: any[];
	updateErrors: any[];
	currentUser: any;
	create: any;
	update: any;
	deleteEntity: any;
	fetchMultipleSupplierFacilitiesInPrivateNetwork: any;
	fetchSupplierFacilitiesInPrivateNetwork: any;
	supplierFacilities: any;
	fetchApprovalHeirarchy: any;
	userType: string;
}

class AddPlannedMaintenanceScheduleForm extends React.Component<
	AddPlannedMaintenanceScheduleFormProps,
	any
> {
	constructor(props) {
		super(props);
		this.state = {
			frequencyUnit: getFrequencyUnit(props.formData.frequency),
			nte: null,
			approvalHeirarchy: {},
		};
	}
	componentDidMount() {
		const { currentUser, fetchApprovalHeirarchy } = this.props;
		fetchApprovalHeirarchy().then((data) => {
			const tiers = nullSafeGet('0.tierDetails', data);
			if (tiers && tiers.length > 0) {
				const userTier =
					_.find(tiers, (item) => _.find(item.approvers, { email: currentUser.email })) || tiers[0];
				this.setState({
					approvalHeirarchy: { amount: userTier.amountUpto || 1000000000 },
				});
			}
		});
	}
	handleNTEValueChangeDebounced = () =>
		debounce(
			(nte) => {
				this.setState({ nte: nte });
			},
			500,
			false
		);

	handleNTEValueChange = (e) => {
		const inputString = e.target.value;
		this.handleNTEValueChangeDebounced()(inputString);
	};
	handleSubmit = (e, submitAction) => {
		const { history, redirectForwardUrl, onSuccess, formData, deleteEntity } = this.props;
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			console.log(values);
			if (!err) {
				const monthConfig =
					values.frequencyUnit === 'months'
						? values.monthConfig !== 'undefined'
							? values.monthConfig
							: undefined
						: undefined;
				const weekDays =
					values.frequencyUnit === 'weeks'
						? values.weekDays.map((day) => _.lowerCase(day))
						: undefined;
				const locationIds = values.locationIds
					? [...(values.locationIds || []), ...values.existingLocationIds]
					: [];
				const assetIds = values.assetIds
					? [...(values.assetIds || []), ...values.existingAssetIds]
					: [];
				const assetTypeIds = values.assetTypeIds
					? [...(values.assetTypeIds || []), ...values.existingAssetTypeIds]
					: [];

				const nte = values.nte ? parseFloat(values.nte) : undefined;
				const supplierFacilityId = values.supplierFacilityId;

				const missingLocationIds = () => {
					if (
						values.basedOn === 'location' &&
						values.basedOn === formData.basedOn &&
						values.title === formData.title &&
						values.frequency === formData.frequencyDays
					) {
						return (formData.locationIds || []).filter(
							(item) => (values.locationIds || []).indexOf(item) < 0
						);
					} else if (formData.basedOn === 'location') {
						return formData.locationIds || [];
					} else {
						return [];
					}
				};

				const missingAssetIds = () => {
					if (
						values.basedOn === 'asset' &&
						values.basedOn === formData.basedOn &&
						values.title === formData.title &&
						values.frequency === formData.frequencyDays
					) {
						return (formData.assetIds || []).filter(
							(item) => (values.assetIds || []).indexOf(item) < 0
						);
					} else if (formData.basedOn === 'asset') {
						return formData.assetIds || [];
					} else {
						return [];
					}
				};

				const missingAssetTypeLocationIds = () => {
					if (
						values.basedOn === 'assetType' &&
						values.basedOn === formData.basedOn &&
						values.title === formData.title &&
						values.frequency === formData.frequencyDays
					) {
						return (formData.locationIds || []).filter(
							(item) => (values.locationIds || []).indexOf(item) < 0
						);
					} else if (formData.basedOn === 'assetType') {
						return formData.locationIds || [];
					} else {
						return [];
					}
				};

				const missingAssetTypeIds = () => {
					if (
						values.basedOn === 'assetType' &&
						values.basedOn === formData.basedOn &&
						values.title === formData.title &&
						values.frequency === formData.frequencyDays
					) {
						return (formData.assetTypeIds || []).filter(
							(item) => (values.assetTypeIds || []).indexOf(item) < 0
						);
					} else if (formData.basedOn === 'assetType') {
						return formData.assetTypeIds || [];
					} else {
						return [];
					}
				};

				const locationDeletePromises = missingLocationIds().map((locationId) => {
					let plannedMaintenanceSchedule = {
						title: formData.title,
						startDate: formData.startDate,
						endDate: formData.endDate,
						plannedMaintenanceTemplateId: formData.plannedMaintenanceTemplateId,
						frequency: 'every' + formData.frequencyDays + values.frequencyUnit,
						weekDays,
						monthConfig,
						status: formData.status,
						basedOn: formData.basedOn,
						supplierFacilityId: formData.supplierFacilityId,
						nte: formData.nte,
						locationId,
					};

					deleteEntity(plannedMaintenanceSchedule);
				});

				const assetDeletePromises = missingAssetIds().map((assetId) => {
					let plannedMaintenanceSchedule = {
						title: formData.title,
						startDate: formData.startDate,
						endDate: formData.endDate,
						plannedMaintenanceTemplateId: formData.plannedMaintenanceTemplateId,
						frequency: 'every' + formData.frequencyDays + values.frequencyUnit,
						weekDays,
						monthConfig,
						status: formData.status,
						basedOn: formData.basedOn,
						supplierFacilityId: formData.supplierFacilityId,
						nte: formData.nte,
						assetId,
					};
					deleteEntity(plannedMaintenanceSchedule);
				});

				console.log(cross_join([missingAssetTypeIds(), missingAssetTypeLocationIds()]));

				const assetTypeDeletePromises = cross_join([
					missingAssetTypeIds(),
					missingAssetTypeLocationIds(),
				]).map((assetTypeLocationComb) => {
					let plannedMaintenanceSchedule = {
						title: formData.title,
						startDate: formData.startDate,
						endDate: formData.endDate,
						plannedMaintenanceTemplateId: formData.plannedMaintenanceTemplateId,
						frequency: 'every' + formData.frequencyDays + values.frequencyUnit,
						weekDays,
						monthConfig,
						status: formData.status,
						basedOn: formData.basedOn,
						supplierFacilityId: formData.supplierFacilityId,
						nte: formData.nte,
						assetTypeId: assetTypeLocationComb[0],
						locationId: assetTypeLocationComb[1],
					};
					deleteEntity(plannedMaintenanceSchedule);
				});

				const commonScheduleValues = {
					title: values.title,
					startDate: moment(values.startAndEndDates[0]).format(`YYYY-MM-DD`),
					endDate: values.startAndEndDates[1]
						? moment(values.startAndEndDates[1]).format(`YYYY-MM-DD`)
						: undefined,
					plannedMaintenanceTemplateId: values.plannedMaintenanceTemplateId,
					frequency: 'every' + values.frequency + values.frequencyUnit,
					weekDays,
					monthConfig,
					status: values.status,
					basedOn: values.basedOn,
					supplierFacilityId,
					nte,
					createConfig: values.createConfig,
					supplierContractId: values.supplierContractId,
					supplierContractDocuments: values.supplierContractDocuments,
				};

				if (values.basedOn === 'asset') {
					Promise.all(
						locationDeletePromises.concat(assetDeletePromises).concat(assetTypeDeletePromises)
					).then(() => {
						const plannedMaintenanceSchedule = {
							...commonScheduleValues,
							assetIds: assetIds,
						};
						submitAction(plannedMaintenanceSchedule).then((record) => {
							if (redirectForwardUrl) {
								history.push(redirectForwardUrl);
							}
							if (onSuccess) {
								onSuccess(record[0], 'asset');
							}
						});
					});
				} else if (values.basedOn === 'location') {
					Promise.all(
						locationDeletePromises.concat(assetDeletePromises).concat(assetTypeDeletePromises)
					).then(() => {
						const plannedMaintenanceSchedule = {
							...commonScheduleValues,
							locationIds: locationIds,
						};
						submitAction(plannedMaintenanceSchedule).then((record) => {
							if (redirectForwardUrl) {
								history.push(redirectForwardUrl);
							}
							if (onSuccess) {
								onSuccess(record[0], 'location');
							}
						});
					});
				} else if (values.basedOn === 'assetType') {
					Promise.all(
						locationDeletePromises.concat(assetDeletePromises).concat(assetTypeDeletePromises)
					).then(() => {
						const plannedMaintenanceSchedule = {
							...commonScheduleValues,
							assetTypeIds: assetTypeIds,
							locationIds: locationIds,
						};
						submitAction(plannedMaintenanceSchedule).then((record) => {
							if (redirectForwardUrl) {
								history.push(redirectForwardUrl);
							}
							if (onSuccess) {
								onSuccess(record[0], 'assetType');
							}
						});
					});
				}
			}
		});
	};

	handleFileManualUpload = (supplierContractDocuments) => {
		const { form } = this.props;
		form.setFieldsValue({ supplierContractDocuments });
	};

	handleFileListChange = (info) => {
		if (info.file.status === undefined) {
			info.fileList.splice(-1, 1);
		}
		return info;
	};

	render() {
		const {
			updating,
			creating,
			form,
			create,
			createErrors,
			updateErrors,
			plannedMaintenanceTemplates,
			fetchPlannedMaintenanceTemplates,
			fetchMultiplePlannedMaintenanceTemplates,
			locations,
			assets,
			assetTypes,
			fetchLocations,
			fetchMultipleLocations,
			fetchAssets,
			fetchMultipleAssets,
			fetchAssetTypes,
			fetchMultipleAssetTypes,
			formData,
			currentUser,
			fetchMultipleSupplierFacilitiesInPrivateNetwork,
			fetchSupplierFacilitiesInPrivateNetwork,
			supplierFacilities,
			userType,
		} = this.props;
		const { getFieldDecorator, getFieldValue } = form;
		const { approvalHeirarchy, frequencyUnit } = this.state;
		const isUpdate = formData && formData.plannedMaintenanceTemplateId !== undefined;
		const submitAction = create;
		const submitText = isUpdate ? 'Update schedule' : 'Create schedule';
		const isLoading = updating || creating;
		const DATE_FORMAT = 'MM-DD-YYYY';
		const isAdmin = currentUser.roles.some((role) => BUYER_ADMIN_ONLY.has(role));

		const frequencies = ['days', 'weeks', 'months', 'years'];
		const frequencyBreakdown = isUpdate
			? formData.frequency && breakdownFrequencyText(frequencyUnit)[formData.frequency]
			: { amount: 1 };
		const startAndEndDates = form.getFieldValue('startAndEndDates');
		const referenceDay =
			startAndEndDates && startAndEndDates[0] ? moment(startAndEndDates[0]) : moment();
		const dayOfTheWeek = DAYS_OF_THE_WEEK[referenceDay.day()];
		const weekOfTheMonth = getWeekOfTheMonth(referenceDay);
		const basedOn = formData.basedon;
		const schedules = nullSafeGet('plannedMaintenanceSchedules', formData);

		const hasLocationOrAssetTypes =
			nullSafeGetOrElse('length', form.getFieldValue('locationIds'), 0) > 0 ||
			nullSafeGetOrElse('length', form.getFieldValue('assetTypeIds'), 0) > 0;

		const firstSchedule = schedules[0];
		const createConfig = nullSafeGet('createConfig', firstSchedule) || 'create';
		const isSameCreateConfig = schedules.every(
			(sched) =>
				nullSafeGetOrElse('createConfig', sched, 'create') ===
				nullSafeGet('createConfig', firstSchedule)
		);

		const monthConfig =
			firstSchedule && firstSchedule.monthConfig ? firstSchedule.monthConfig : 'undefined';

		const weekDays = firstSchedule.weekDays ? firstSchedule.weekDays.map((d) => capitalize(d)) : [];
		const selectedWeekDays = weekDays || [DAYS_OF_THE_WEEK[referenceDay.day()]];

		const locationsUpdated =
			form.getFieldValue('locationIds') &&
			form.getFieldValue('locationIds').filter((id) => !formData.locationIds.includes(id)).length >
				0;
		const assetsUpdated =
			form.getFieldValue('assetIds') &&
			form.getFieldValue('assetIds').filter((id) => !formData.assetIds.includes(id)).length > 0;
		const assetTypeUpdated =
			form.getFieldValue('assetTypeIds') &&
			form.getFieldValue('assetTypeIds').filter((id) => !formData.assetTypeIds.includes(id))
				.length > 0;
		const isListUpdated = locationsUpdated || assetsUpdated || assetTypeUpdated;

		getFieldDecorator('frequency', { initialValue: formData.frequency });
		getFieldDecorator('basedOn', { initialValue: formData.basedOn });
		if (basedOn === 'asset') {
			getFieldDecorator('asset', { initialValue: formData.asset });
			getFieldDecorator('assetId', { initialValue: formData.assetId });
		} else if (basedOn === 'location') {
			getFieldDecorator('location', { initialValue: formData.location });
			getFieldDecorator('locationId', { initialValue: formData.locationId });
		} else if (basedOn === 'assetType') {
			getFieldDecorator('assetType', { initialValue: formData.assetType });
			getFieldDecorator('assetTypeId', { initialValue: formData.assetTypeId });
		}
		getFieldDecorator('plannedMaintenanceTemplateId', {
			initialValue: formData.plannedMaintenanceTemplateId,
		});
		getFieldDecorator('status', { initialValue: formData.status || 'active' });
		getFieldDecorator('supplierContractDocuments', {
			initialValue: formData.supplierContractDocuments || [],
		});

		return (
			<Form
				layout="vertical"
				onSubmit={(e) => this.handleSubmit(e, submitAction)}
				className="supplierForm"
				style={FORM_FIELD_WIDTH_STYLE}
			>
				{createErrors.length > 0 ? (
					<Form.Item>
						<Alert message={createErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				{updateErrors.length > 0 ? (
					<Form.Item>
						<Alert message={updateErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				<Form.Item label="Name">
					{getFieldDecorator('title', {
						initialValue: formData.title,
						rules: [{ required: true, message: 'Please give the PM schedule a title.' }],
					})(<Input disabled={true} />)}
				</Form.Item>
				<Form.Item label="Scheduled Start and End Dates">
					{form.getFieldDecorator('startAndEndDates', {
						rules: [
							{
								type: 'array',
								required: true,
								message: 'Please select a start date!',
							},
						],
						initialValue: formData.startDate
							? [
									moment.parseZone(formData.startDate),
									formData.endDate ? moment.parseZone(formData.endDate) : undefined,
							  ]
							: [undefined, undefined],
					})(<OWDatePicker format={DATE_FORMAT} showTime={false} allowEmpty={[false, true]} />)}
				</Form.Item>
				{getFieldValue('basedOn') === 'meters' ? (
					<Form.Item label="Trigger">
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<label style={{ marginRight: 8, whiteSpace: 'nowrap' }}>
								Create work order when reading
							</label>
							{getFieldDecorator('triggerOperator', {
								rules: [{ required: true, message: 'This field is required.' }],
							})(
								<Select style={{ marginRight: 8, width: 80 }}>
									<Select.Option value="=" key="=">
										=
									</Select.Option>
									<Select.Option value=">" key=">">
										{'>'}
									</Select.Option>
									<Select.Option value="<" key="<">
										{'<'}
									</Select.Option>
								</Select>
							)}
							{getFieldDecorator('triggerValue', {
								rules: [{ required: true, message: 'This field is required.' }],
							})(<Input style={{ width: 320 }} />)}
						</div>
					</Form.Item>
				) : (
					<Form.Item label="Frequency">
						<div>
							<label style={{ marginRight: 8 }}>Every</label>
							{getFieldDecorator('frequency', {
								initialValue: (frequencyBreakdown && frequencyBreakdown.amount) || 7,
								rules: [{ required: true, message: 'This field is required.' }],
							})(<InputNumber min={1} max={365} disabled={true} />)}
							{getFieldDecorator('frequencyUnit', {
								initialValue: frequencyUnit,
								rules: [{ required: true, message: 'This field is required.' }],
							})(
								<Select
									disabled={true}
									style={{ maxWidth: 100, marginLeft: 8 }}
									onChange={(frequencyUnit) => this.setState({ frequencyUnit })}
								>
									{frequencies.map((f) => (
										<Option key={f} value={f}>
											{f}
										</Option>
									))}
								</Select>
							)}
						</div>
					</Form.Item>
				)}
				{this.state.frequencyUnit === 'weeks' ? (
					<Form.Item label={'Repeat On'}>
						{getFieldDecorator('weekDays', {
							initialValue: selectedWeekDays,
							rules: [{ required: true, message: 'This field is required.' }],
						})(<Checkbox.Group options={DAYS_OF_THE_WEEK} disabled={true} />)}
					</Form.Item>
				) : this.state.frequencyUnit === 'months' ? (
					<Form.Item>
						{getFieldDecorator('monthConfig', {
							initialValue: monthConfig,
							rules: [{ required: true, message: 'This field is required.' }],
						})(
							<Select disabled={true}>
								<Option
									key={'monthConfig'}
									value={weekOfTheMonth.concat('_', _.lowerCase(dayOfTheWeek))}
								>
									On the {weekOfTheMonth} {dayOfTheWeek} of the month.
								</Option>
								{isLastDayOfTheMonth(referenceDay) ? (
									<Option key={'lastDayOfTheMonth'} value={'last_day'}>
										On the last day of the month.
									</Option>
								) : null}
								<Option key={'undefined'} value={'undefined'}>
									On the {referenceDay.date()}
									{getNumberSuffix(referenceDay.date())} day of the month.
								</Option>
							</Select>
						)}
					</Form.Item>
				) : null}
				<Form.Item label="Planned Maintenance Template">
					{getFieldDecorator('plannedMaintenanceTemplateId', {
						initialValue: formData.plannedMaintenanceTemplateId,
						rules: [{ required: true, message: 'This field is required.' }],
					})(
						<OWAsyncSelect
							disabled={true}
							stateSlice={plannedMaintenanceTemplates}
							targetCollectionName={
								PLANNED_MAINTENANCE_SCHEDULE_FORM_PLANNED_MAINTENANCE_TEMPLATES_AUTOCOMPLETE
							}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultiplePlannedMaintenanceTemplates(ids, targetCollectionName);
							}}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchPlannedMaintenanceTemplates(
									{ title: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							renderRecord={(plannedMaintenanceTemplate) => (
								<Select.Option
									key={plannedMaintenanceTemplate.id}
									value={plannedMaintenanceTemplate.id}
								>
									{plannedMaintenanceTemplate.title}
								</Select.Option>
							)}
							sortBy={{ sort_by: 'title', order: 'ascend' }}
						/>
					)}
				</Form.Item>
				<Form.Item>
					{getFieldDecorator('basedOn', {
						initialValue: formData.basedOn || 'location',
						rules: [{ required: true, message: 'This field is required.' }],
					})(
						<Radio.Group disabled={true}>
							<Radio value="asset">Based on Asset</Radio>
							<Radio value="location">Based on Location</Radio>
							<Radio value="assetType">Based on Asset Type</Radio>
						</Radio.Group>
					)}
				</Form.Item>

				{getFieldValue('basedOn') === 'location' ? (
					<div>
						<Form.Item label="Existing Locations">
							{getFieldDecorator('existingLocationIds', {
								initialValue: formData.locationIds,
								rules: [
									{
										required: getFieldValue('basedOn') === 'location',
										message: 'This field is required.',
									},
								],
							})(
								<OWAsyncSelect
									mode="multiple"
									disabled={true}
									emptyWhenDisabled={false}
									stateSlice={locations}
									targetCollectionName={PLANNED_MAINTENANCE_SCHEDULE_FORM_LOCATIONS_AUTOCOMPLETE}
									fetchMultiple={(ids, targetCollectionName) => {
										fetchMultipleLocations(ids, targetCollectionName);
									}}
									fetchData={(
										searchText,
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									) => {
										fetchLocations(
											{ name: searchText },
											targetCollectionName,
											pagination,
											sorting,
											filters,
											addToTargetCollection
										);
									}}
									renderRecord={(location) => (
										<Select.Option
											key={location.id}
											value={location.id}
											disabled={formData.locationIds.includes(location.id)}
										>
											<div
												style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
											>
												<span>{location.name}</span>
												<span style={{ marginRight: 8 }}>
													{formData.locationIds.includes(location.id) ? 'Scheduled' : null}
												</span>
											</div>
										</Select.Option>
									)}
									sortBy={{ sort_by: 'name', order: 'ascend' }}
								/>
							)}
						</Form.Item>
						<Form.Item label="Add Locations">
							{getFieldDecorator('locationIds', {
								initialValue: [],
								rules: [
									{
										required: getFieldValue('basedOn') === 'location',
										message: 'This field is required.',
									},
								],
							})(
								<BuyerRegionsLocationsTreeSelect
									mode="multiple"
									disabled={getFieldValue('basedOn') !== 'location'}
									emptyWhenDisabled={true}
									filtersSlice={locations}
									hiddenRecords={formData.locationIds}
									filtersValueAccessor={() => form.getFieldValue('locationIds')}
									targetCollectionName="PLANNED_MAINTENANCE_SCHEDULE_FORM_ADD_LOCATIONS_AUTOCOMPLETE"
									renderRecord={(location) => (
										<Select.Option
											key={location.id}
											value={location.id}
											disabled={formData.locationIds.includes(location.id)}
										>
											<div
												style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
											>
												<span>{location.name}</span>
												<span style={{ marginRight: 8 }}>
													{formData.locationIds.includes(location.id) ? 'Scheduled' : null}
												</span>
											</div>
										</Select.Option>
									)}
									sortBy={{ sort_by: 'name', order: 'ascend' }}
								/>
							)}
						</Form.Item>
					</div>
				) : null}
				{getFieldValue('basedOn') === 'asset' ? (
					<div>
						<Form.Item label="Existing Assets">
							{getFieldDecorator('existingAssetIds', {
								initialValue: formData.assetIds,
								rules: [
									{
										required: getFieldValue('basedOn') === 'asset',
										message: 'This field is required.',
									},
								],
							})(
								<OWAsyncSelect
									mode="multiple"
									disabled={true}
									emptyWhenDisabled={false}
									stateSlice={assets}
									targetCollectionName={PLANNED_MAINTENANCE_SCHEDULE_FORM_ASSETS_AUTOCOMPLETE}
									fetchMultiple={(ids, targetCollectionName) => {
										fetchMultipleAssets(ids, targetCollectionName);
									}}
									fetchData={(
										searchText,
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									) => {
										fetchAssets(
											{ search: searchText },
											targetCollectionName,
											pagination,
											sorting,
											filters,
											addToTargetCollection
										);
									}}
									renderRecord={(d) => (
										<Select.Option
											value={d.id}
											key={d.id}
											disabled={formData.assetIds.includes(d.id)}
										>
											<div
												style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
											>
												<div className="flex flex-col">
													<div>
														{nullSafeGetOrElse('location.name', d, '')} - {d.name}
													</div>
													<AssetNumberDisplay asset={d} />
												</div>
												<span style={{ marginRight: 8 }}>
													{formData.assetIds.includes(d.id) ? 'Scheduled' : null}
												</span>
											</div>
										</Select.Option>
									)}
									sortBy={{ sort_by: 'name', order: 'ascend' }}
								/>
							)}
						</Form.Item>
						<Form.Item label="Add Assets">
							{getFieldDecorator('assetIds', {
								initialValue: [],
								rules: [
									{
										required: getFieldValue('basedOn') === 'asset',
										message: 'This field is required.',
									},
								],
							})(
								<OWAsyncSelect
									mode="multiple"
									disabled={getFieldValue('basedOn') !== 'asset'}
									emptyWhenDisabled={true}
									stateSlice={assets}
									targetCollectionName={PLANNED_MAINTENANCE_SCHEDULE_FORM_ASSETS_AUTOCOMPLETE}
									fetchMultiple={(ids, targetCollectionName) => {
										fetchMultipleAssets(ids, targetCollectionName);
									}}
									fetchData={(
										searchText,
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									) => {
										fetchAssets(
											{ search: searchText },
											targetCollectionName,
											pagination,
											sorting,
											filters,
											addToTargetCollection
										);
									}}
									renderRecord={(d) => (
										<Select.Option
											value={d.id}
											key={d.id}
											disabled={formData.assetIds.includes(d.id)}
										>
											<div
												style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
											>
												<div className="flex flex-col">
													<div>
														{nullSafeGetOrElse('location.name', d, '')} - {d.name}
													</div>
													<AssetNumberDisplay asset={d} />
												</div>
												<span style={{ marginRight: 8 }}>
													{formData.assetIds.includes(d.id) ? 'Scheduled' : null}
												</span>
											</div>
										</Select.Option>
									)}
									sortBy={{ sort_by: 'name', order: 'ascend' }}
									hiddenRecords={formData.assetIds}
								/>
							)}
						</Form.Item>
					</div>
				) : null}
				{getFieldValue('basedOn') === 'assetType' ? (
					<div>
						<Form.Item label="Existing Asset Types">
							{getFieldDecorator('existingAssetTypeIds', {
								initialValue: formData.assetTypeIds,
								rules: [
									{
										required: false,
										message: 'This field is required.',
									},
								],
							})(
								<OWAsyncSelect
									mode="multiple"
									disabled={true}
									emptyWhenDisabled={false}
									stateSlice={assetTypes}
									targetCollectionName={PLANNED_MAINTENANCE_SCHEDULE_FORM_ASSET_TYPES_AUTOCOMPLETE}
									fetchMultiple={(ids, targetCollectionName) => {
										fetchMultipleAssetTypes(ids, targetCollectionName);
									}}
									fetchData={(
										searchText,
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									) => {
										fetchAssetTypes(
											{ name: searchText },
											targetCollectionName,
											pagination,
											sorting,
											filters,
											addToTargetCollection
										);
									}}
									renderRecord={(d) => (
										<Select.Option
											value={d.id}
											key={d.id}
											disabled={formData.assetTypeIds.includes(d.id)}
										>
											<div>{d.name}</div>
										</Select.Option>
									)}
									sortBy={{ sort_by: 'name', order: 'ascend' }}
								/>
							)}
						</Form.Item>
						<Form.Item label="Add Asset Types">
							{getFieldDecorator('assetTypeIds', {
								initialValue: [],
								rules: [
									{
										required: getFieldValue('basedOn') === 'assetType' && !hasLocationOrAssetTypes,
										message: 'This field is required.',
									},
								],
							})(
								<OWAsyncSelect
									mode="multiple"
									disabled={getFieldValue('basedOn') !== 'assetType'}
									emptyWhenDisabled={true}
									stateSlice={assetTypes}
									targetCollectionName={PLANNED_MAINTENANCE_SCHEDULE_FORM_ASSET_TYPES_AUTOCOMPLETE}
									fetchMultiple={(ids, targetCollectionName) => {
										fetchMultipleAssetTypes(ids, targetCollectionName);
									}}
									fetchData={(
										searchText,
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									) => {
										fetchAssetTypes(
											{ name: searchText },
											targetCollectionName,
											pagination,
											sorting,
											filters,
											addToTargetCollection
										);
									}}
									renderRecord={(d) => (
										<Select.Option
											value={d.id}
											key={d.id}
											disabled={formData.assetTypeIds.includes(d.id)}
										>
											<div>{d.name}</div>
										</Select.Option>
									)}
									sortBy={{ sort_by: 'name', order: 'ascend' }}
									hiddenRecords={formData.assetTypeIds}
								/>
							)}
						</Form.Item>
						<Form.Item label="Existing Locations">
							{getFieldDecorator('existingLocationIds', {
								initialValue: formData.locationIds,
								rules: [
									{
										required: false,
										message: 'This field is required.',
									},
								],
							})(
								<OWAsyncSelect
									mode="multiple"
									disabled={true}
									emptyWhenDisabled={false}
									stateSlice={locations}
									targetCollectionName={PLANNED_MAINTENANCE_SCHEDULE_FORM_LOCATIONS_AUTOCOMPLETE}
									fetchMultiple={(ids, targetCollectionName) => {
										fetchMultipleLocations(ids, targetCollectionName);
									}}
									fetchData={(
										searchText,
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									) => {
										fetchLocations(
											{ name: searchText },
											targetCollectionName,
											pagination,
											sorting,
											filters,
											addToTargetCollection
										);
									}}
									renderRecord={(d) => (
										<Select.Option
											value={d.id}
											key={d.id}
											disabled={formData.locationIds.includes(d.id)}
										>
											<div>{d.name}</div>
										</Select.Option>
									)}
									sortBy={{ sort_by: 'name', order: 'ascend' }}
								/>
							)}
						</Form.Item>
						<Form.Item label="Add Locations">
							{getFieldDecorator('locationIds', {
								initialValue: [],
								rules: [
									{
										required: getFieldValue('basedOn') === 'assetType' && !hasLocationOrAssetTypes,
										message: 'This field is required.',
									},
								],
							})(
								<OWAsyncSelect
									mode="multiple"
									disabled={getFieldValue('basedOn') !== 'assetType'}
									emptyWhenDisabled={true}
									hiddenRecords={formData.locationIds}
									stateSlice={locations}
									targetCollectionName="PLANNED_MAINTENANCE_SCHEDULE_FORM_ADD_LOCATIONS_AUTOCOMPLETE"
									fetchMultiple={(ids, targetCollectionName) => {
										fetchMultipleLocations(ids, targetCollectionName);
									}}
									fetchData={(
										searchText,
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									) => {
										fetchLocations(
											{ name: searchText },
											targetCollectionName,
											pagination,
											sorting,
											filters,
											addToTargetCollection
										);
									}}
									renderRecord={(location) => (
										<Select.Option
											key={location.id}
											value={location.id}
											disabled={formData.locationIds.includes(location.id)}
										>
											<div
												style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
											>
												<span>{location.name}</span>
												<span style={{ marginRight: 8 }}>
													{formData.locationIds.includes(location.id) ? 'Scheduled' : null}
												</span>
											</div>
										</Select.Option>
									)}
									sortBy={{ sort_by: 'name', order: 'ascend' }}
								/>
							)}
						</Form.Item>
					</div>
				) : null}
				{getFieldValue('basedOn') === 'meter' ? (
					<Form.Item label="Meters">
						{getFieldDecorator('meterIds', {
							initialValue: formData.meterIds,
							rules: [
								{
									required: getFieldValue('basedOn') === 'meter',
									message: 'This field is required.',
								},
							],
						})(
							<OWAsyncSelect
								mode="multiple"
								disabled={getFieldValue('basedOn') !== 'meter'}
								emptyWhenDisabled={true}
								stateSlice={assets}
								targetCollectionName={PLANNED_MAINTENANCE_SCHEDULE_FORM_ASSETS_AUTOCOMPLETE}
								fetchMultiple={(ids, targetCollectionName) => {
									fetchMultipleAssets(ids, targetCollectionName);
								}}
								fetchData={(
									searchText,
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								) => {
									fetchAssets(
										{ search: searchText },
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									);
								}}
								renderRecord={(d) => (
									<Select.Option value={d.id} key={d.id}>
										<div>
											{nullSafeGetOrElse('location.name', d, '')} - {d.name}
										</div>
									</Select.Option>
								)}
								sortBy={{ sort_by: 'name', order: 'ascend' }}
							/>
						)}
					</Form.Item>
				) : null}
				{getFieldValue('basedOn') === 'meter' ? (
					<Form.Item label="Meters">
						{getFieldDecorator('meterIds', {
							initialValue: formData.meterIds,
							rules: [
								{
									required: getFieldValue('basedOn') === 'meter',
									message: 'This field is required.',
								},
							],
						})(
							<OWAsyncSelect
								mode="multiple"
								disabled={getFieldValue('basedOn') !== 'meter'}
								emptyWhenDisabled={true}
								stateSlice={assets}
								targetCollectionName={PLANNED_MAINTENANCE_SCHEDULE_FORM_ASSETS_AUTOCOMPLETE}
								fetchMultiple={(ids, targetCollectionName) => {
									fetchMultipleAssets(ids, targetCollectionName);
								}}
								fetchData={(
									searchText,
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								) => {
									fetchAssets(
										{ search: searchText },
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									);
								}}
								renderRecord={(d) => (
									<Select.Option value={d.id} key={d.id}>
										<div>
											{nullSafeGetOrElse('location.name', d, '')} - {d.name}
										</div>
									</Select.Option>
								)}
								sortBy={{ sort_by: 'name', order: 'ascend' }}
							/>
						)}
					</Form.Item>
				) : null}
				<Form.Item
					label="Assign To"
					help={
						'If left empty, rank 1 supplier will get picked based on problem type and location.'
					}
				>
					{getFieldDecorator('supplierFacilityId', {
						initialValue: undefined,
					})(
						<OWAsyncSelect
							allowClear={true}
							stateSlice={supplierFacilities}
							targetCollectionName={PLANNED_MAINTENANCE_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleSupplierFacilitiesInPrivateNetwork(ids, targetCollectionName);
							}}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchSupplierFacilitiesInPrivateNetwork(
									{ name: searchText, offset: 0, limit: 100 },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							renderRecord={(supplierFacility) => (
								<Select.Option key={supplierFacility.id} value={supplierFacility.id}>
									{supplierFacility.name}
								</Select.Option>
							)}
							additionalFilters={{
								problemTypeId: this.state.problemTypeId,
							}}
							sortBy={{ sort_by: 'name', order: 'ascend' }}
						/>
					)}
				</Form.Item>
				<Form.Item
					label="Not To Exceed Amount"
					validateStatus={approvalHeirarchy.status}
					help={
						approvalHeirarchy.status
							? `This NTE change bypasses the requirement of a quote. Based on approval hierarchy, you can set NTE upto $${approvalHeirarchy.amount}`
							: ''
					}
				>
					{getFieldDecorator('nte', {
						initialValue: undefined,
						validateTrigger: 'onChange',
						rules: [
							{
								validator: (rule, value, callback) => {
									if (
										approvalHeirarchy.amount &&
										parseInt(value) > parseInt(approvalHeirarchy.amount) &&
										parseInt(value) !== formData.nte
									) {
										this.setState({
											approvalHeirarchy: {
												...approvalHeirarchy,
												status: isAdmin ? 'warning' : 'error',
											},
										});
										callback(isAdmin ? undefined : '');
									} else {
										this.setState({
											approvalHeirarchy: {
												...approvalHeirarchy,
												status: null,
											},
										});
										callback();
									}
								},
							},
						],
					})(<Input onChange={this.handleNTEValueChange} onClick={(e) => e.stopPropagation()} />)}
				</Form.Item>
				<Form.Item label={'Scheduling Configuration'}>
					{getFieldDecorator('createConfig', {
						initialValue: isSameCreateConfig ? createConfig : 'create',
					})(
						<Select>
							<Option value={'create'}>Always schedule new work order.</Option>
							<Option value={'cancel_existing_and_create'}>
								Cancel existing and schedule a new work order.
							</Option>
							<Option value={'create_after_prior_completion'}>
								Wait until work order is completed.
							</Option>
							<Option value={'dont_create'}>Skip until work order is completed.</Option>
						</Select>
					)}
				</Form.Item>
				<Form.Item label="Contract ID">
					{getFieldDecorator('supplierContractId', {
						initialValue: formData.supplierContractId,
					})(<Input />)}
				</Form.Item>
				<Form.Item label="Contract Documents">
					{getFieldDecorator(`supplierContractDocuments`, {
						valuePropName: 'fileList',
					})(
						<FileUploader
							roleType={userType}
							defaultFileList={formData.supplierContractDocuments || []}
							handleUploadSuccess={this.handleFileManualUpload}
							handleFileListChange={this.handleFileListChange}
							accept="application/pdf"
							uploaderType={'dragger'}
						/>
					)}
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						loading={isLoading}
						className="contactForm__button"
						disabled={!isListUpdated}
					>
						{submitText}
					</Button>
					<span style={{ marginLeft: '16px' }}>
						<BackButton buttonText="Cancel" />
					</span>
				</Form.Item>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	formData: ownProps.formData,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,

	locations: state.locations,
	assets: state.assets,
	assetTypes: state.asset_types,
	plannedMaintenanceTemplates: state.planned_maintenance_templates,
	createErrors: state.planned_maintenance_schedules.createErrors,
	updateErrors: state.planned_maintenance_schedules.updateErrors,
	creating: state.planned_maintenance_schedules.creating,
	updating: state.planned_maintenance_schedules.updating,
	currentUser: state.session.currentUser,
	supplierFacilities: state.supplier_facilities,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	create: (entity) => dispatch(bulkCreatePlannedMaintenanceSchedules(ownProps.userType)(entity)),
	update: (entity) => dispatch(bulkUpdatePlannedMaintenanceSchedules(ownProps.userType)(entity)),
	deleteEntity: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? plannedMaintenanceScheduleDeleteForSupplier(entity)
				: plannedMaintenanceScheduleDeleteForBuyer(entity)
		),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: locationsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.readLite(
						{ ...params, no_pagination: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: locationsRestCrudThunksForBuyer.readLite(
						{ ...params, no_pagination: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultipleAssets: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: assetsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchAssets: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetsRestCrudThunksForSupplier.readLite(
						{ ...params },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: assetsRestCrudThunksForBuyer.readLite(
						{ ...params },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultipleAssetTypes: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: assetTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchAssetTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetTypesRestCrudThunksForSupplier.readLite(
						{ ...params, no_pagination: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: assetTypesRestCrudThunksForBuyer.readLite(
						{ ...params, no_pagination: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultiplePlannedMaintenanceTemplates: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? plannedMaintenanceTemplatesRestCrudThunksForSupplier.readMultiple(
						ids,
						targetCollectionName
				  )
				: plannedMaintenanceTemplatesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchPlannedMaintenanceTemplates: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? plannedMaintenanceTemplatesRestCrudThunksForSupplier.readLite(
						{ ...params, no_pagination: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: plannedMaintenanceTemplatesRestCrudThunksForBuyer.readLite(
						{ ...params, no_pagination: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchSupplierFacilitiesInPrivateNetwork: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? fetchSupplierFacilitiesInPrivateNetworkForSupplier(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters
				  )
				: fetchSupplierFacilitiesInPrivateNetworkForBuyer(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters
				  )
		),
	fetchMultipleSupplierFacilitiesInPrivateNetwork: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? supplierFacilitiesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: supplierFacilitiesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchApprovalHeirarchy: (id) => dispatch(getBuyerCompanyApprovalTiers(ownProps.userType, null)()),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(AddPlannedMaintenanceScheduleForm)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(Form.create()(ComponentWithoutUserType));

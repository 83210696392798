import { USER_NOTIFICATIONS_CRUD_ACTION_CREATORS } from '../actions/user_notifications_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import FetchUtils, { parseJSON } from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { logoutSuccess } from '../actions/session_actions';

export const userNotificationsRestCrudThunksForBuyer = new RestCrudThunks(
	'user_notifications',
	'user_notification',
	'userNotification',
	'userNotifications',
	USER_NOTIFICATIONS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'notification/'
);
export const userNotificationsRestCrudThunksForSupplier = new RestCrudThunks(
	'user_notifications',
	'user_notification',
	'userNotification',
	'userNotifications',
	USER_NOTIFICATIONS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'notification/'
);

const fetchUtils = new FetchUtils();
const baseUrl = getBackendUri();

const markAllUserNotificationsRead = (userType) => (entity) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(USER_NOTIFICATIONS_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.patch(baseUrl + `/api/v1/${userType}/notification/user_notifications/has_seen_until`, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: {
						...entity,
					},
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(USER_NOTIFICATIONS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(USER_NOTIFICATIONS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(USER_NOTIFICATIONS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};
export const markAllUserNotificationsReadForBuyer = markAllUserNotificationsRead(ROLE_TYPES.BUYER);
export const markAllUserNotificationsReadForSupplier = markAllUserNotificationsRead(
	ROLE_TYPES.SUPPLIER
);

const markUserNotificationsRead = (userType) => (notificationType, entityId) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(USER_NOTIFICATIONS_CRUD_ACTION_CREATORS.updateStart({}));

			fetchUtils
				.patch(
					baseUrl +
						`/api/v1/${userType}/notification/user_notifications/has_seen_by_type/${notificationType}/${entityId}`,
					{
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: {},
					}
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(USER_NOTIFICATIONS_CRUD_ACTION_CREATORS.updateError(d.message, {}));
						reject(d.message);
					} else {
						dispatch(USER_NOTIFICATIONS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(USER_NOTIFICATIONS_CRUD_ACTION_CREATORS.updateError(d.message, {}));
					reject(d.message);
				});
		});
	};
};
export const markUserNotificationsReadForBuyer = markUserNotificationsRead(ROLE_TYPES.BUYER);
export const markUserNotificationsReadForSupplier = markUserNotificationsRead(ROLE_TYPES.SUPPLIER);

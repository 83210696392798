import React from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Alert, Button, Card } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import FileUploader, { fileUploadValidation } from '../file_uploader/FileUploader';
import {
	assetModelsRestCrudThunksForBuyer,
	assetModelsRestCrudThunksForSupplier,
} from '../../thunks/asset_models_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';

require('./AssetModelForm.less');
const SPEC_FIELDS_KEYS = 'specFieldsKeys';

interface AssetModelFormProps extends FormComponentProps {
	creating: boolean;
	updating: boolean;
	formData: any;
	history: any;
	redirectForwardUrl?: string;
	onSuccess: any;
	onCancel: any;
	assetTypeId: any;
	createErrors: any[];
	updateErrors: any[];
	currentUser: any;
	create: any;
	update: any;
	assetModel: any;
	userType: any;
}

let specFieldsUuid = 2;

class AssetModelForm extends React.Component<AssetModelFormProps, any> {
	handleSubmit = (e, submitAction) => {
		const { onSuccess } = this.props;
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const specFieldsKeys = values[SPEC_FIELDS_KEYS];
				const specs = specFieldsKeys.map((k) => ({
					key: values[`specs-${k}-key`],
					value: values[`specs-${k}-value`],
				}));
				const assetModel = {
					id: values.id,
					assetTypeId: values.assetTypeId,
					modelName: values.modelName,
					specs,
					manuals: values.manuals,
				};

				submitAction(assetModel).then((record) => {
					if (onSuccess) {
						onSuccess();
					}
				});
			}
		});
	};

	handleCancel = () => {
		const { onCancel } = this.props;
		if (onCancel) {
			onCancel();
		}
	};

	addSpecField = () => {
		this.addField(SPEC_FIELDS_KEYS)(specFieldsUuid);
		specFieldsUuid++;
	};

	removeSpecField = (k) => {
		this.removeField(SPEC_FIELDS_KEYS)(k);
	};

	addField = (fieldName) => (uuid) => {
		const { form } = this.props;
		// can use data-binding to get
		const keys = form.getFieldValue(fieldName);
		const nextKeys = [...keys, uuid];
		// can use data-binding to set
		// important! notify form to detect changes
		form.setFieldsValue({
			[fieldName]: nextKeys,
		});
	};

	removeField = (fieldName) => (k) => {
		const { form } = this.props;
		// can use data-binding to get
		const keys = form.getFieldValue(fieldName);
		const nextKeys = keys.filter((key) => key !== k);

		// can use data-binding to set
		form.setFieldsValue({
			[fieldName]: nextKeys,
		});
	};

	handleFileManualUpload = (newAttachments) => {
		const { form, formData } = this.props;
		form.setFieldsValue({ manuals: newAttachments });
	};

	handleFileListChange = (info) => {
		if (info.file.status === undefined) {
			info.fileList.splice(-1, 1);
		}
		return info;
	};

	render() {
		const {
			updating,
			creating,
			form,
			update,
			create,
			createErrors,
			formData,
			assetTypeId,
			updateErrors,
			userType,
		} = this.props;
		const { getFieldDecorator } = form;
		const isUpdate = formData && formData.id !== undefined;
		const submitAction = isUpdate ? update : create;
		const title = isUpdate ? 'Update asset model' : 'Create asset model';
		const submitText = isUpdate ? 'Update asset model' : 'Create asset model';
		const isLoading = updating || creating;
		getFieldDecorator(`id`, { initialValue: formData.id });
		getFieldDecorator('assetTypeId', { initialValue: formData.assetTypeId || assetTypeId });
		getFieldDecorator('manuals', { initialValue: formData.manuals || [] });
		getFieldDecorator(SPEC_FIELDS_KEYS, {
			initialValue: nullSafeGetOrElse('specs', formData, []).map((e, idx) => idx),
		});
		const specFieldsKeys = form.getFieldValue(SPEC_FIELDS_KEYS);

		const getFieldItems = (type) => {
			let fieldsKeys;
			let keyPrefix;
			let removeAction;
			fieldsKeys = specFieldsKeys;
			keyPrefix = 'specs';
			removeAction = this.removeSpecField;

			return fieldsKeys.map((k, idx) => (
				<div key={`speck-${k}`}>
					<div style={{ display: 'flex' }}>
						<div
							style={{
								marginRight: 16,
								paddingTop: idx === 0 ? 31 : 0,
							}}
						>
							{idx + 1}
						</div>
						<Form.Item
							label={idx === 0 ? 'Key' : null}
							required={false}
							style={{ marginRight: 16 }}
						>
							{form.getFieldDecorator(`${keyPrefix}-${k}-key`, {
								validateTrigger: ['onChange', 'onBlur'],
								initialValue:
									nullSafeGet(`${keyPrefix}-${k}-key`, formData) ||
									nullSafeGet(`${keyPrefix}.${k}.key`, formData),
							})(<Input />)}
						</Form.Item>
						<Form.Item label={idx === 0 ? 'Value' : null} required={false}>
							{form.getFieldDecorator(`${keyPrefix}-${k}-value`, {
								validateTrigger: ['onChange', 'onBlur'],
								initialValue:
									nullSafeGet(`${keyPrefix}-${k}-value`, formData) ||
									nullSafeGet(`${keyPrefix}.${k}.value`, formData),
							})(<Input />)}
						</Form.Item>
						<div
							style={{
								paddingTop: idx === 0 ? 31 : 0,
							}}
						>
							<MinusCircleOutlined
								translate=""
								className="dynamic-delete-button"
								onClick={() => removeAction(k)}
							/>
						</div>
					</div>
				</div>
			));
		};

		return (
			<div style={{ margin: '24px 24px 0' }}>
				<h5 style={{ marginBottom: 24 }}>{title}</h5>
				<Card bordered={false}>
					<Form
						layout="vertical"
						onSubmit={(e) => this.handleSubmit(e, submitAction)}
						className="supplierForm"
					>
						{createErrors.length > 0 ? (
							<Form.Item>
								<Alert message={createErrors.join(' ')} type="error" />
							</Form.Item>
						) : null}
						{updateErrors.length > 0 ? (
							<Form.Item>
								<Alert message={updateErrors.join(' ')} type="error" />
							</Form.Item>
						) : null}
						<Form.Item label="Name">
							{getFieldDecorator('modelName', {
								initialValue: formData.modelName,
							})(<Input style={{ maxWidth: 300 }} />)}
						</Form.Item>
						<Form.Item label="Specifications" style={{ marginBottom: 0 }}>
							{getFieldItems('specs')}
						</Form.Item>
						<Form.Item>
							<Button type="dashed" onClick={() => this.addSpecField()}>
								<PlusOutlined translate="" /> Add specs
							</Button>
						</Form.Item>
						<Form.Item label="Manuals">
							<FileUploader
								key={(formData.manuals || []).map((manual) => manual.fileId).join(',') + 'form'}
								roleType={userType}
								defaultFileList={formData.manuals || []}
								handleUploadSuccess={this.handleFileManualUpload}
								handleFileListChange={this.handleFileListChange}
								beforeUpload={(f) => fileUploadValidation(f, 'application/pdf')}
								uploaderType={'dragger'}
							/>
						</Form.Item>
						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								loading={isLoading}
								className="contactForm__button"
							>
								{submitText}
							</Button>
							<span style={{ marginLeft: '16px' }}>
								<Button onClick={this.handleCancel}>Cancel</Button>
							</span>
						</Form.Item>
					</Form>
				</Card>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	formData: ownProps.formData,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,
	onCancel: ownProps.onCancel,
	assetTypeId: ownProps.assetTypeId,

	assetModel: state.asset_models.detail,
	createErrors: state.asset_models.createErrors,
	updateErrors: state.asset_models.updateErrors,
	creating: state.asset_models.creating,
	updating: state.asset_models.updating,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	update: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetModelsRestCrudThunksForSupplier.update(entity)
				: assetModelsRestCrudThunksForBuyer.update(entity)
		),
	create: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetModelsRestCrudThunksForSupplier.create(entity)
				: assetModelsRestCrudThunksForBuyer.create(entity)
		),
});

const AssetModelFormWR = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Form.create<AssetModelFormProps>()(AssetModelForm))
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(AssetModelFormWR);

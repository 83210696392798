import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Layout, Row, Col, Card } from 'antd';
import { PipelineFilter } from '../InvoicesTable/pipelineFilters/pipelineFilters';
import {
	changeFilterValueToArrayOfIds,
	changeFilterValueToArrayOfValues,
	getEndDateFieldName,
	getFiltersWithoutPaginationAndSorting,
	getStartDateFieldName,
	getTupleNameFieldName,
	getTupleValueFieldName,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import SubnavBar from '../subnav_bar/SubnavBar';
import { AdvancedFilters } from '../advanced_filters/AdvancedFilters';
import queryString from 'querystring';
import { DEFAULT_SORTER, FILTER_FIELD_TYPE, FILTER_VALUE_TYPE } from '../../utils/DataConstants';
import SelectedFilters from '../selected_filters_pills/SelectedFilters';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { withRouter } from 'react-router';
import { formatDateForParam } from '../../utils/DataFormatterUtils';
import moment from 'moment';
import OWAsyncTreeSelect from '../ow_async_tree_select/OWAsyncTreeSelect';
import OWDateRangePicker from '../ow_date_range_picker/OWDateRangePicker';
import OWRadioGroup from '../ow_radio_group/OWRadioGroup';
import OWAsyncTupleSelect from '../ow_async_select/OWAsyncTupleSelect';
import OWSelect from '../ow_radio_group/OWSelect';

const { Content } = Layout;

const getFilterValue = (val, config) => {
	switch (config.type) {
		case FILTER_FIELD_TYPE.RADIO_GROUP:
			return {
				[config.fieldName]: val === 'undefined' ? undefined : val,
			};
		case FILTER_FIELD_TYPE.DATE_RANGE:
			return {
				[getStartDateFieldName(config.fieldName)]:
					val && val.length > 0 && val[0] ? formatDateForParam(val[0]) : undefined,
				[getEndDateFieldName(config.fieldName)]:
					val && val.length > 1 && val[1] ? formatDateForParam(val[1]) : undefined,
			};
		case FILTER_FIELD_TYPE.TUPLE:
			return {
				[getTupleNameFieldName(config.fieldName)]:
					val && val.length > 0 && val[0] ? val[0] : undefined,
				[getTupleValueFieldName(config.fieldName)]:
					val && val.length > 1 && val[1] ? val[1] : undefined,
			};
		case FILTER_FIELD_TYPE.OW_MULTI_SELECT:
		default:
			return {
				[config.fieldName]: config.mode === 'multiple' ? val.join(',') || undefined : val,
			};
	}
};

const getFilterValueAccessor = (stateSlice, tcName, config) => {
	switch (config.type) {
		case FILTER_FIELD_TYPE.DATE_RANGE:
			const startDate = nullSafeGet(
				`${tcName}.filters.${getStartDateFieldName(config.fieldName)}`,
				stateSlice
			);
			const endDate = nullSafeGet(
				`${tcName}.filters.${getEndDateFieldName(config.fieldName)}`,
				stateSlice
			);
			return [startDate && moment(startDate), endDate && moment(endDate)];
		case FILTER_FIELD_TYPE.TUPLE:
			const name = nullSafeGet(
				`${tcName}.filters.${getTupleNameFieldName(config.fieldName)}`,
				stateSlice
			);
			const value = nullSafeGet(
				`${tcName}.filters.${getTupleValueFieldName(config.fieldName)}`,
				stateSlice
			);
			return [name, value];
		case FILTER_FIELD_TYPE.RADIO_GROUP:
			const filters = nullSafeGet(`${tcName}.filters`, stateSlice);
			return typeof filters[config.fieldName] === 'undefined'
				? 'undefined'
				: `${filters[config.fieldName]}`;
		case FILTER_FIELD_TYPE.OW_MULTI_SELECT:
		default:
			const val = nullSafeGet(`${tcName}.filters.${config.fieldName}`, stateSlice);
			const accesor =
				config.mode === 'multiple'
					? config.valueType === FILTER_VALUE_TYPE.STRING
						? changeFilterValueToArrayOfValues(val)
						: changeFilterValueToArrayOfIds(val)
					: val;
			return accesor;
	}
};

const valueAccessor = (config) => (entity) => {
	switch (config.type) {
		case FILTER_FIELD_TYPE.DATE_RANGE:
			return entity;
		case FILTER_FIELD_TYPE.OW_MULTI_SELECT:
		case FILTER_FIELD_TYPE.RADIO_GROUP:
			return entity.value;
		case FILTER_FIELD_TYPE.TUPLE:
		default:
			return entity.id;
	}
};

const renderItem = (config) => (entity) => {
	switch (config.type) {
		case FILTER_FIELD_TYPE.DATE_RANGE:
			return entity;
		case FILTER_FIELD_TYPE.OW_MULTI_SELECT:
		case FILTER_FIELD_TYPE.RADIO_GROUP:
			return entity.label;
		case FILTER_FIELD_TYPE.TUPLE:
		default:
			return entity.name;
	}
};

const sortBy = { sort_by: 'name', order: 'ascend' };

const getComponent = (config) => {
	switch (config.type) {
		case FILTER_FIELD_TYPE.DATE_RANGE:
			return OWDateRangePicker;
		case FILTER_FIELD_TYPE.TUPLE:
			return OWAsyncTupleSelect;
		case FILTER_FIELD_TYPE.RADIO_GROUP:
			return OWRadioGroup;
		case FILTER_FIELD_TYPE.OW_MULTI_SELECT:
			return OWSelect;
		default:
			return OWAsyncTreeSelect;
	}
};

interface PaginatedReduxTableWithHeaderProps {
	targetCollectionName: String;
	updateFilters: (filter: any, tcName: String) => void;
	updateSorters?: (sort_by: String, order: String, tcName: String) => void;
	refreshCounts?: (filter: any) => void;
	clearAndUpdateFilters: (filter: any, tcName: String) => void;
	stateSlice: any;
	pipeLineFilters?: any[];
	sorters?: any[];
	location?: any;
	filterConfig: any[];
	rightActions: any;
	emptyState: any;
	entityCollectionName: String;
	tableColumns: any[];
	fetchData: any;
	showHeader?: boolean;
	onTableRow?: any;
	rowSelection?: any;
	preAppliedFilters?: any;
	hasDefaultHeaderPage: boolean;
	initialPagination: any;
	initialSorters: any;
	hideFilters?: Boolean;
	hideSearch?: Boolean;
	additionalFilters?: any;
	components?: any;
}

const PaginatedReduxTableWithHeader: FC<PaginatedReduxTableWithHeaderProps> = ({
	targetCollectionName,
	stateSlice,
	updateFilters,
	updateSorters,
	refreshCounts,
	clearAndUpdateFilters,
	pipeLineFilters,
	sorters,
	location,
	filterConfig = [],
	rightActions,
	emptyState,
	entityCollectionName,
	tableColumns,
	showHeader,
	onTableRow,
	fetchData,
	rowSelection,
	preAppliedFilters = {},
	hasDefaultHeaderPage,
	initialPagination = {},
	initialSorters = {},
	hideFilters,
	additionalFilters,
	components,
	hideSearch,
}): React.ReactElement => {
	const queryParams = useMemo(() => {
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		return queryString.parse(searchString);
	}, [location.search]);

	const derivedPagination = useMemo(() => {
		const { current: initialCurrent, pageSize: initialPageSize } = initialPagination;
		const defaultPagination = {
			current: initialCurrent || 1,
			pageSize: initialPageSize || 10,
		};
		const { current, pageSize } = queryParams;
		return {
			...defaultPagination,
			...(current && { current }),
			...(pageSize && { pageSize }),
		};
	}, [initialPagination, queryParams]);

	const initialFilters = useMemo(
		() => getFiltersWithoutPaginationAndSorting(queryParams),
		[queryParams]
	);

	const initialSorting = useMemo(() => {
		const { sort_by: querySortBy, order: queryOrder } = queryParams;
		const { sort_by: initialSortBy, order: initialOrder } = initialSorters;
		const sort_by = initialSortBy || querySortBy;
		const order = initialOrder || queryOrder;
		return {
			...DEFAULT_SORTER,
			...(sort_by && { sort_by }),
			...(order && { order }),
		};
	}, [initialSorters, queryParams]);

	useEffect(() => {
		refreshCounts && refreshCounts({ ...preAppliedFilters, ...initialFilters });
	}, []);

	const updatePipeLineFilters = useCallback(
		(filter) => updateFilters(filter, targetCollectionName),
		[targetCollectionName, updateFilters]
	);

	const pipeLineFilterValue = useMemo(
		() => nullSafeGet(`${targetCollectionName}.filters`, stateSlice),
		[stateSlice, targetCollectionName]
	);

	const applyFilters = useCallback(
		(filters) => {
			updateFilters(filters, targetCollectionName);
			refreshCounts && refreshCounts(filters);
		},
		[refreshCounts, targetCollectionName, updateFilters]
	);

	const applySorting = useCallback(
		({ sort_by, order }) => updateSorters && updateSorters(sort_by, order, targetCollectionName),
		[targetCollectionName, updateSorters]
	);

	const activeSorters = useMemo(
		() => nullSafeGetOrElse(`${targetCollectionName}.sorting`, stateSlice, {}),
		[stateSlice, targetCollectionName]
	);

	const clearFilters = useCallback(
		(filters, tc) => {
			clearAndUpdateFilters(filters, tc);
			refreshCounts && refreshCounts(filters);
			applySorting(DEFAULT_SORTER);
		},
		[applySorting, clearAndUpdateFilters, refreshCounts]
	);

	const updatedFilterConfig = useMemo(
		() =>
			filterConfig.map((config) => ({
				...config,
				keyAccessor: config.keyAccessor || config.valueAccessor || valueAccessor(config),
				valueAccessor: config.valueAccessor || valueAccessor(config),
				renderItem: config.renderItem || renderItem(config),
				labelAccessor: config.labelAccessor || config.renderItem || renderItem(config),
				renderRecord: config.renderRecord || config.renderItem || renderItem(config),
				sortBy: config.sortyBy || sortBy,
				component: config.component || getComponent(config),
				handleChange: (val) => applyFilters(getFilterValue(val, config)),
				filtersValueAccessor: (ss) => getFilterValueAccessor(ss, targetCollectionName, config),
			})),
		[applyFilters, filterConfig, targetCollectionName]
	);

	return (
		<Content
			className="paginated-redux-table-with-header"
			style={{
				padding: '0 0.5em',
				...(hasDefaultHeaderPage && { marginTop: '-8px' }),
			}}
		>
			<LogOnMountWithStandardEventProperties eventType={`Visited ${entityCollectionName} page`} />
			{pipeLineFilters && pipeLineFilters.length > 0 ? (
				<Row style={{ margin: '0.5em 0' }} gutter={16}>
					<PipelineFilter
						items={pipeLineFilters}
						subItems={{}}
						onChange={updatePipeLineFilters}
						value={pipeLineFilterValue}
						counts={stateSlice.counts}
					/>
				</Row>
			) : null}
			<Row style={{ margin: '0.5em -8px' }} gutter={16}>
				<Col span={24}>
					<SubnavBar
						left={
							!hideFilters ? (
								<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
									<AdvancedFilters
										updateFilters={applyFilters}
										applySorting={applySorting}
										sorters={activeSorters}
										sorterItems={sorters}
										defaultSorters={initialSorters}
										filterConfig={updatedFilterConfig}
										filtersTargetCollectionName={targetCollectionName}
										clearAndUpdateFilters={clearFilters}
										filtersStateSlice={stateSlice}
										preAppliedFilters={preAppliedFilters}
										initialPagination={{ current: 1, pageSize: 250 }}
										showSearch={!hideSearch}
										initialFilters={initialFilters}
									/>
								</div>
							) : null
						}
						right={rightActions}
					/>
				</Col>
				{additionalFilters ? additionalFilters : null}
			</Row>
			<div className="flex w-full flex-1 flex-row">
				<SelectedFilters
					updateFilters={applyFilters}
					filterValues={nullSafeGetOrElse(`${targetCollectionName}.filters`, stateSlice, {})}
					filterConfig={updatedFilterConfig}
				/>
			</div>
			<Row style={{ margin: '0.5em -8px' }} gutter={16}>
				<Col span={24}>
					<Card bodyStyle={{ padding: 8 }}>
						<PaginatedReduxTable
							mode="list"
							updateQueryParams
							emptyState={emptyState}
							collectionName={entityCollectionName}
							targetCollectionName={targetCollectionName}
							columns={tableColumns}
							showHeader={!!showHeader}
							keyAccessor={(el) => el.id}
							onRow={onTableRow}
							initialPagination={derivedPagination}
							initialSorting={initialSorting}
							initialFilters={{ ...initialFilters, ...preAppliedFilters }}
							fetchData={fetchData}
							rowSelection={rowSelection}
							components={components}
						/>
					</Card>
				</Col>
			</Row>
		</Content>
	);
};

export default withRouter<any, any>(PaginatedReduxTableWithHeader);

import { VENDORS_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/vendors_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = standardInitialStateGenerator([
	'PART_CATALOG_FORM_VENDORS_AUTOCOMPLETE',
	'vendorsIndex',
	'PART_VENDORS_AUTOCOMPLETE',
	'EQUIPMENT_VENDORS_AUTOCOMPLETE',
	'EQUIPMENT_CATALOG_FORM_VENDORS_AUTOCOMPLETE',
	'purchaseOrdersAdvancedFilters',
	'partCatalogsAdvancedFilters',
	'equipmentCatalogsAdvancedFilters',
	'poLineItemsAdvancedFilters',
	'prLineItemsAdvancedFilters',
	'defaultPartEquipmentVendors',
	'backordersAdvancedFilters',
	'assetPartWarrantyVendors',
	'assetVendors',
	'equipmentVendors',
]);
export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState);

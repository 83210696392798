import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	clearWorkManufacturerFilters,
	updateWorkManufacturerFilters,
} from '../../actions/work_analytics_actions';
import { fetchWorkOrderCountByManufacturer } from '../../thunks/work_analytics_thunks';
import WorkAnalyticsCategoryBreakdownPage from '../work_analytics_category_breakdown_page/WorkAnalyticsCategoryBreakdownPage';
import { capitalizeEachWord } from '../../utils/DataFormatterUtils';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const DataSet = require('@antv/data-set');

class WorkAnalyticsOverviewManufacturersPage extends React.Component<any, any> {
	render() {
		const { analytics, userType } = this.props;
		const { workOrderCountByManufacturer, manufacturerFilters } = analytics;

		return [
			<LogOnMountWithStandardEventProperties eventType="visited work analytics manufacturers page" />,
			<WorkAnalyticsCategoryBreakdownPage
				updateFilters={updateWorkManufacturerFilters}
				clearFilters={clearWorkManufacturerFilters}
				workOrderCountByCategory={workOrderCountByManufacturer}
				filters={manufacturerFilters}
				fetchWorkOrderCountsByCategory={fetchWorkOrderCountByManufacturer(userType)}
				fieldDisplayName="Manufacturer"
				chartConfig={{
					xAxisName: 'fieldName',
					yAxisName: 'subTotal',
					position: 'fieldName*subTotal',
					fillArea: false,
					color: 'fieldName',
					cols: {
						fieldName: { alias: 'Manufacturer' },
						subTotal: { alias: '# Work Orders' },
					},
					yAxisLabel: {
						textStyle: {
							fontSize: '14',
							fontFamily: 'Roboto',
							fontWeight: 400,
							fill: 'rgba(0,0,0,0.65)',
						},
					},
					xAxisLabel: {
						textStyle: {
							fontSize: '14',
							fontFamily: 'Roboto',
							fontWeight: 400,
							fill: 'rgba(0,0,0,0.65)',
						},
					},
					transformer: (data) => {
						const ds = new DataSet();
						const dv = ds.createView().source(data);
						dv.transform({
							type: 'map',
							callback: (row) => {
								row.fieldName = capitalizeEachWord(row.fieldName);
								return row;
							},
						}).transform({
							type: 'sort-by',
							fields: ['subTotal'],
							order: 'ASC', // default is ASC,DESC is reversed order.
						});
						return dv;
					},
				}}
			/>,
		];
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	accountActivated: state.session.accountActivated,
	currentUser: state.session.currentUser,
	analytics: state.work_analytics,
});

const mapDispatchToProps = (dispatch) => ({});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(WorkAnalyticsOverviewManufacturersPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import React from 'react';
import { Button, Row, Col } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import WorkOrderCreateButton from '../common/WorkOrderCreateButton';

const ADHeader = ({ editDashboard, addWidget, name }): React.ReactElement => {
	return (
		<Row
			style={{
				padding: '24px 0 24px 24px',
				width: '100%',
			}}
			justify="space-between"
			align="middle"
		>
			<Col
				style={{
					fontSize: '22px',
					color: 'rgba(0, 0, 0, 0.85)',
				}}
			>
				<div>{`Welcome back${name ? `, ${name}!` : '!'}`}</div>
			</Col>
			<Col>
				<Button
					type="link"
					onClick={addWidget}
					size="large"
					style={{ marginLeft: '16px' }}
					icon={<PlusOutlined translate="" />}
				>
					Add View
				</Button>
				<Button
					type="link"
					onClick={editDashboard}
					size="large"
					icon={<EditOutlined translate="" />}
				>
					Edit
				</Button>
				<WorkOrderCreateButton backText="Back to Dashboard" />
			</Col>
		</Row>
	);
};

export default ADHeader;

const filters_enum = {
	//User Contact Object
	Contact: {
		//Users Filter
		Users: {
			key: 'Contact-Users', // Contact-Users|
			type: 'list',

			text: 'Users',
			value: '',

			valuepath: 'email',
			labelpath: ['nameGiven', 'nameFamily'],
			sublabelpath: ['email'],
			filters: [{ type: 'Contact-Users', text: 'None', value: '*' }],
		},

		//Roles Filter
		Roles: {
			key: 'Contact-Roles',
			type: 'list',

			text: 'Roles', //Header at top of list
			value: '',

			valuepath: 'title', //Path to filter data (path must lead to a string)
			labelpath: ['title'], //Path to label data (path must lead to a string)
			filters: [],
		},
	},
	//Company Object
	Company: {
		//Company Name Filter
		Name: {
			key: 'Company-Name',
			type: 'list',

			text: 'Company Name', //Header at top of list
			value: '',

			valuepath: 'displayName', //Path to filter data
			labelpath: ['displayName'], //Path to label data
			filters: [],
		},
	},

	Facility: {
		Name: {
			key: 'Facility-Name',
			type: 'list',

			text: 'State', //Header at top of list
			value: '',

			valuepath: 'name', //Path to filter data
			labelpath: ['name'], //Path to label data
			filters: [],
		},
		State: {
			key: 'Facility-State',
			type: 'list',

			text: 'State', //Header at top of list
			value: '',

			valuepath: 'primaryAddress.city', //Path to filter data
			labelpath: ['primaryAddress.city'], //Path to label data
			filters: [],
		},
	},
};
export default filters_enum;

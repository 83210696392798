import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router';
import { Layout } from 'antd';
import { requestsForProposalRestCrudThunksForBuyer } from '../../thunks/requests_for_proposal_thunks';
import ScrollToTopOnMount from '../scroll_to_top_on_mount/ScrollToTopOnMount';
import { REQUESTS_FOR_PROPOSAL_CRUD_ACTION_CREATORS } from '../../actions/requests_for_proposal_actions';
import SubnavTabs from '../subnav_tabs/SubnavTabs';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';
import { nullSafeGet } from '../../utils/DataAccessUtils';

const { Content } = Layout;

const style = require('./RequestsForProposalOverviewPage.less');

const DATE_FORMAT = 'MMM D, YYYY';

const DRAFT_EVENTS = 'draftEvents';
const ACTIVE_EVENTS = 'activeEvents';
const CLOSED_EVENTS = 'closedEvents';
const AWARDED_EVENTS = 'awardedEvents';
const ALL_EVENTS = 'allEvents';
const MY_EVENTS = 'myEvents';
const SOURCING_EVENTS_OVERVIEW_TARGET_COLLECTION = 'requestsForProposalOverview';
const DRAFT_FILTER = { biddingStatus: 'draft' };
const ACTIVE_FILTER = { biddingStatus: 'active' };
const CLOSED_FILTER = { biddingStatus: 'closed' };
const AWARDED_FILTER = { biddingStatus: 'award' };

class RequestsForProposalPage extends React.Component<any, any> {
	componentDidMount() {
		const { getRequestsForProposalsCountBy, currentUser } = this.props;
		const MY_FILTER = { ownerEmail: currentUser.email };
		getRequestsForProposalsCountBy(DRAFT_FILTER, DRAFT_EVENTS);
		getRequestsForProposalsCountBy(ACTIVE_FILTER, ACTIVE_EVENTS);
		getRequestsForProposalsCountBy(CLOSED_FILTER, CLOSED_EVENTS);
		getRequestsForProposalsCountBy(AWARDED_FILTER, AWARDED_EVENTS);
		getRequestsForProposalsCountBy(MY_FILTER, MY_EVENTS);
		getRequestsForProposalsCountBy({}, ALL_EVENTS);
	}

	handleTabChange = (key) => {
		const { history } = this.props;
		history.push(`/buyer/sourcing/overview/${key}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	render() {
		const { companyConfig } = this.props;
		const tabs = [
			{
				key: 'all',
				name: 'All',
			},
		];

		return (
			<div>
				<ScrollToTopOnMount />

				<div>
					<Route
						path="/buyer/requestsForProposal/overview"
						exact={true}
						render={(props) => (
							<Redirect
								to={{
									pathname: `/buyer/requestsForProposal/overview/all`,
								}}
							/>
						)}
					/>
					<Content className="requestsForProposalPage" style={{ padding: '0 0.5em' }}>
						{/*<ScrollToTopOnMount/>*/}
						{/*<BackTop/>*/}

						<div
							style={{
								margin: '0 -8px',
								padding: '24px',
								paddingBottom: 0,
								background: nullSafeGet('detail.config.theme.navblock', companyConfig),
								marginBottom: 8,
							}}
						>
							<h4
								style={{
									color: 'rgba(255, 255, 255, 0.9)',
									marginRight: 8,
									marginBottom: 24,
								}}
							>
								Requests for Proposal
							</h4>
							<SubnavTabs
								tabs={tabs}
								onChange={this.handleTabChange}
								isActive={this.checkTabActive}
							/>
						</div>
						{this.props.childRoutes.map((r, i) => (
							<RouteWithSubRoutes key={i} {...r} />
						))}
					</Content>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	companyConfig: state.company_config,

	currentUser: state.session.currentUser,
	requestsForProposal: state.requests_for_proposal,
});

const mapDispatchToProps = (dispatch) => ({
	getRequestsForProposalsCountBy: (params, countName) =>
		dispatch(requestsForProposalRestCrudThunksForBuyer.countBy(params, countName)),
	updateRequestsForProposalFilters: (filters, targetCollection) =>
		dispatch(REQUESTS_FOR_PROPOSAL_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(RequestsForProposalPage)
);

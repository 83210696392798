import * as React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Layout, Card } from 'antd';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { generateLocationMeterReadingsPDFForBuyer } from '../../thunks/assets_thunks';
import { Document, Page } from 'react-pdf';

const Content = Layout.Content;

const style = require('./LocationsDetailMetersPage.less');

class LocationsDetailMetersPage extends React.Component<any, any> {
	state = {
		numPages: null,
		pageNumber: 1,
		pdfFile: { url: null, httpHeaders: null },
	};

	componentDidMount() {
		const { match } = this.props;
		const locationId = match.params.id;
		this.setState({
			pdfFile: generateLocationMeterReadingsPDFForBuyer(locationId),
		});
	}

	onDocumentLoadSuccess = ({ numPages }) => {
		this.setState({ numPages });
	};

	render() {
		return (
			<Content className="locationsDetailSuppliersPage">
				<LogOnMountWithStandardEventProperties eventType="visited buyer location detail meters page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Card>
					{this.state.pdfFile.url ? (
						<div>
							<Document file={this.state.pdfFile} onLoadSuccess={this.onDocumentLoadSuccess}>
								{Array.from(new Array(this.state.numPages), (el, index) => (
									<Page width={1100} key={`page_${index + 1}`} pageNumber={index + 1} />
								))}
							</Document>
							<p>
								Page {this.state.pageNumber} of {this.state.numPages}
							</p>
						</div>
					) : null}
				</Card>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationsDetailMetersPage));

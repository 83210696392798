import { Card } from 'antd';
import React, { FC, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { EmptyState } from '../empty_state/EmptyState';
import { PurchaseRequestRowDisplay } from '../purchase_request_row_display/PurchaseRequestRowDisplay';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import { purchaseRequestsLiteRestCrudThunksForSupplier } from '../../thunks/purchase_requests_thunks';
import { getEntityById } from '../../utils/DataAccessUtils';

const TC_NAME = 'workOrdersPurchaseRequests';

const PurchaseRequestsSectionInWorkOrder: FC<any> = ({
	workOrder,
	history,
	location,
}): React.ReactElement => {
	const openPRDetailsPage = useCallback(
		(id) => () =>
			history.push(
				getLinkWIthBackLinkParams(
					location,
					'Back to Work Order',
					`/supplier/purchaseRequests/detail/${id}/details`
				)
			),
		[history, location]
	);

	const purchaseRequestsColumns = useMemo(
		() => [
			{
				title: 'ID',
				render: (_, record) => (
					<div onClick={openPRDetailsPage(record.id)}>
						<PurchaseRequestRowDisplay purchaseRequest={record} />
					</div>
				),
			},
		],
		[openPRDetailsPage]
	);

	return (
		<Card title={`Purchase requests`}>
			<PaginatedReduxTable
				mode="list"
				emptyState={
					<EmptyState
						graphic={
							<img
								alt="No purchase requests found"
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
							/>
						}
						headline="No purchase requests found"
						body={null}
					/>
				}
				collectionName="purchase_requests"
				targetCollectionName={TC_NAME}
				columns={purchaseRequestsColumns}
				showHeader={false}
				keyAccessor={(el) => el.id}
				initialFilters={{
					workOrderId: workOrder.id,
				}}
				initialPagination={{ pageSize: 3 }}
				fetchData={purchaseRequestsLiteRestCrudThunksForSupplier.read}
			/>
		</Card>
	);
};

const mapStateToProps = (state, ownProps) => ({
	workOrder: getEntityById(ownProps.match.params.id, state.work_orders),
});

export default withRouter<any, any>(connect(mapStateToProps)(PurchaseRequestsSectionInWorkOrder));

import * as React from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	plannedMaintenanceTemplatesRestCrudThunksForBuyer,
	plannedMaintenanceTemplatesRestCrudThunksForSupplier,
} from '../../thunks/planned_maintenance_templates_thunks';
import { Link } from 'react-router-dom';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import { generateBackLink } from '../../utils/HistoryUtils';
import { clearNavbarState, updateNavbarState } from '../../actions/navbar_actions';
import { ROLE_TYPES } from '../../utils/DataConstants';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';

const getDefaultBackLink = (ut) => `/${ut}/plannedMaintenance/overview/templates/overview`;
const DEFAULT_BACK_TEXT = 'Back to PM Templates';

const queryString = require('qs');

class PlannedMaintenanceTemplatesDetailPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			backLinkTo: getDefaultBackLink(nullSafeGetOrElse('props.userType', this, ROLE_TYPES.BUYER)),
			backLinkText: DEFAULT_BACK_TEXT,
		};
	}

	componentDidMount() {
		const { getPlannedMaintenanceTemplate, match, updateNavbar, location, userType } = this.props;
		const plannedMaintenanceTemplateId = match.params.id;
		getPlannedMaintenanceTemplate(plannedMaintenanceTemplateId).then((pm) => {
			let searchString = location.search;
			if (searchString[0] === '?') {
				searchString = searchString.slice(1);
			}
			const queryParams = queryString.parse(searchString);
			if (queryParams.backlinkParams) {
				const { pathName, backLinkText } = queryString.parse(queryParams.backlinkParams);
				let backPath = pathName || this.state.backLinkTo;
				let backText = backLinkText || this.state.backLinkText;
				updateNavbar(backText, generateBackLink(backPath, location));
			} else {
				updateNavbar(
					this.state.backLinkText,
					generateBackLink(getDefaultBackLink(userType), location)
				);
			}
		});
	}

	handleTabChange = (key) => {
		const { history, match, userType } = this.props;
		const plannedMaintenanceTemplateId = match.params.id;
		history.push(
			`/${userType}/planned_maintenance_schedules/templates/detail/${plannedMaintenanceTemplateId}/${key}`
		);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	handleDelete = () => {
		const { history, deletePlannedMaintenanceTemplate, plannedMaintenanceTemplate, userType } =
			this.props;
		deletePlannedMaintenanceTemplate(plannedMaintenanceTemplate).then(() => {
			history.push(`/${userType}/plannedMaintenance/overview/templates/overview`);
		});
	};

	render() {
		const {
			plannedMaintenanceTemplate,
			plannedMaintenanceTemplatesFetching,
			match,
			userType,
			companyConfig,
		} = this.props;
		const pmTemplateId = match.params.id;
		const tabs = [
			{
				key: 'details',
				name: 'Overview',
			},
		];
		return (
			<div className="plannedMaintenanceTemplatesDetailPage">
				<DetailPageHeader
					exactPath={`/${userType}/plannedMaintenance/templates/detail/:id`}
					redirectPath={`/${userType}/plannedMaintenance/templates/detail/${pmTemplateId}/details`}
					backLinkText={DEFAULT_BACK_TEXT}
					backLinkTo={`/${userType}/plannedMaintenance/overview/templates/overview`}
					title={plannedMaintenanceTemplatesFetching ? null : plannedMaintenanceTemplate.title}
					backgroundColor={nullSafeGet('detail.config.theme.navblock', companyConfig)}
					subtitle={
						plannedMaintenanceTemplatesFetching ? null : (
							<div className="plannedMaintenanceTemplatesDetailPage__subtitleRow">
								<div>
									<span className="plannedMaintenanceTemplatesDetailPage__subtitle">
										{nullSafeGet('workOrderPriority.name', plannedMaintenanceTemplate)}
									</span>
								</div>
								<div className="plannedMaintenanceTemplatesDetailPage__subtitle">
									<i className="icons8-font icons8-maintenance-filled" />
									<span>
										{' '}
										{nullSafeGet('problemType.hierarchyName', plannedMaintenanceTemplate)}
									</span>
								</div>
							</div>
						)
					}
					actions={[
						<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PM}>
							<Link
								to={`/${userType}/plannedMaintenance/templates/edit/${pmTemplateId}`}
								style={{ marginRight: 16 }}
								key={1}
							>
								<Button ghost={true}>Edit</Button>
							</Link>
						</AccessPermissionChecker>,
						<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PM}>
							<Button key={2} ghost={true} onClick={this.handleDelete}>
								Delete
							</Button>
						</AccessPermissionChecker>,
					]}
					tabs={tabs}
					handleTabChange={this.handleTabChange}
					checkTabActive={this.checkTabActive}
					childRoutes={this.props.childRoutes}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	match: ownProps.match,
	history: ownProps.history,
	plannedMaintenanceTemplate: state.planned_maintenance_templates.detail,
	plannedMaintenanceTemplatesFetching: state.planned_maintenance_templates.fetching,
	companyConfig: state.company_config,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getPlannedMaintenanceTemplate: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? plannedMaintenanceTemplatesRestCrudThunksForSupplier.readOne(id)
				: plannedMaintenanceTemplatesRestCrudThunksForBuyer.readOne(id)
		),
	deletePlannedMaintenanceTemplate: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? plannedMaintenanceTemplatesRestCrudThunksForSupplier.delete(entity, 'id', null, true)
				: plannedMaintenanceTemplatesRestCrudThunksForBuyer.delete(entity, 'id', null, true)
		),
	updateNavbar: (backlinkTitle, backlinkTo, navbarBackgroundColor) =>
		dispatch(updateNavbarState(backlinkTitle, backlinkTo, navbarBackgroundColor)),
	clearNavbar: () => dispatch(clearNavbarState()),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(PlannedMaintenanceTemplatesDetailPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import React, { FC, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { ROLE_TYPES } from '../../utils/DataConstants';
import {
	refreshWalkthroughCountsForBuyer,
	refreshWalkthroughCountsForSupplier,
	walkthroughsRestCrudThunksForBuyer,
	walkthroughsRestCrudThunksForSupplier,
} from '../../thunks/walkthroughs_thunks';
import { WALKTHROUGHS_CRUD_ACTION_CREATORS } from '../../actions/walkthroughs_actions';
import { WalkthroughRowDisplay } from '../walkthrough_row_display/WalkthroughRowDisplay';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import { EmptyState } from '../empty_state/EmptyState';
import WalkthroughPipeLineFilters from './walkthroughs_pipeline_filters';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import { Button } from 'antd';
import { getStatSliceProp } from '../../utils/DataAccessUtils';
import ScheduleWalkthroughModalForm from '../schedule_walkthrough_modal_form/ScheduleWalkthroughModalForm';

const TC_NAME = 'siteSurveyAssociatedWalkthroughs';

const SiteSurveyWalkthroughsPage: FC<any> = ({
	updateFilters,
	clearAndUpdateFilters,
	refreshCounts,
	walkthroughs,
	location,
	history,
	userType,
	match,
	fetchWalkthroughs,
}): React.ReactElement => {
	const [scheduleVisible, setScheduleVisible] = useState(false);

	const _showSchedule = () => setScheduleVisible(true);
	const _hideSchedule = () => setScheduleVisible(false);

	const onScheduleSuccess = useCallback(() => {
		_hideSchedule();
		const filters = getStatSliceProp('filters', TC_NAME, walkthroughs);
		fetchWalkthroughs(
			{},
			TC_NAME,
			getStatSliceProp('pagination', TC_NAME, walkthroughs),
			getStatSliceProp('sorting', TC_NAME, walkthroughs),
			filters
		);
		refreshCounts(filters);
	}, [fetchWalkthroughs, refreshCounts, walkthroughs]);

	const siteSurveyId = useMemo(() => match.params.id, [match.params.id]);

	const columns = useMemo(
		() => [
			{
				title: 'Name',
				dataIndex: 'title',
				render: (_, record) => <WalkthroughRowDisplay walkthrough={record} />,
			},
		],
		[]
	);

	const onWalkthroughsRow = useCallback(
		(record) => ({
			onClick: () => {
				let searchString = location.search;
				if (searchString[0] === '?') {
					searchString = searchString.slice(1);
				}
				const path = `/${userType}/siteSurveys/walkthrough/detail/${record.id}/details`;
				history.push(getLinkWIthBackLinkParams(location, 'Back to Site Survey', path));
			},
		}),
		[history, location, userType]
	);

	return (
		<>
			<PaginatedReduxTableWithHeader
				targetCollectionName={TC_NAME}
				updateFilters={updateFilters}
				stateSlice={walkthroughs}
				refreshCounts={refreshCounts}
				pipeLineFilters={WalkthroughPipeLineFilters}
				clearAndUpdateFilters={clearAndUpdateFilters}
				filterConfig={[]}
				entityCollectionName="walkthroughs"
				tableColumns={columns}
				fetchData={
					userType === ROLE_TYPES.SUPPLIER
						? walkthroughsRestCrudThunksForSupplier.read
						: walkthroughsRestCrudThunksForBuyer.read
				}
				rightActions={
					<AccessPermissionChecker name={PERMISSION_NAMES.ADD_WALKTHROUGHS}>
						<Button type="primary" size="large" onClick={_showSchedule}>
							Schedule Walkthrough
						</Button>
					</AccessPermissionChecker>
				}
				onTableRow={onWalkthroughsRow}
				preAppliedFilters={{ siteSurveyId }}
				emptyState={
					<EmptyState
						graphic={
							<img
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
								alt="You haven't conducted any walkthroughs yet."
							/>
						}
						headline={"You haven't conducted any walkthroughs yet."}
						body={
							<div style={{ textAlign: 'center' }}>
								<div style={{ maxWidth: 440, marginBottom: 16 }}></div>
							</div>
						}
					/>
				}
			/>
			{scheduleVisible ? (
				<ScheduleWalkthroughModalForm onCancel={_hideSchedule} onSuccess={onScheduleSuccess} />
			) : null}
		</>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
	walkthroughs: state.walkthroughs,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	refreshCounts: (filters) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? refreshWalkthroughCountsForSupplier(filters)
				: refreshWalkthroughCountsForBuyer(filters)
		),
	updateFilters: (filters, targetCollection) =>
		dispatch(WALKTHROUGHS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	updateSorters: (sortBy, order, targetCollection) =>
		dispatch(WALKTHROUGHS_CRUD_ACTION_CREATORS.updateSorting(sortBy, order, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			WALKTHROUGHS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)
		),
	fetchWalkthroughs: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? walkthroughsRestCrudThunksForSupplier.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: walkthroughsRestCrudThunksForBuyer.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SiteSurveyWalkthroughsPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

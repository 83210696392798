import React, { FC, useCallback, useMemo } from 'react';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { List, Input, Radio } from 'antd';

interface ITaskListRO {
	tasks: any;
}

const DefaultTitle = '---';

const TaskListRO: FC<ITaskListRO> = ({ tasks }): React.ReactElement => {
	const groups = useMemo(() => {
		const groupings = {};
		const sortedTasks = tasks.sort((a, b) => {
			const id1 = a.orderingId || 0;
			const id2 = b.orderingId || 0;
			return id1 - id2;
		});
		sortedTasks.map((task) => {
			const title = task.groupTitle || DefaultTitle;
			groupings[title] = groupings[title] ? [...groupings[title], task] : [task];
		});
		return groupings;
	}, [tasks]);

	const getFormField = useCallback((task) => {
		return task.questionType === 'multipleChoice' ? (
			nullSafeGetOrElse('multipleChoicesWithLabel.length', task, 0) !== 0 ? (
				<Radio.Group>
					{task.multipleChoicesWithLabel.map((choice, idx) => (
						<Radio value={choice.value} key={`rg-${idx}`} disabled={true}>
							{`${choice.value}${choice.description ? ` (${choice.description}) ` : ''}`}
						</Radio>
					))}
				</Radio.Group>
			) : (
				''
			)
		) : (
			<Input.TextArea disabled={true} placeholder="" />
		);
	}, []);

	return (
		<List itemLayout="vertical">
			{Object.keys(groups as any).map((key, idx) => (
				<List.Item key={idx}>
					{key !== DefaultTitle && <List.Item.Meta title={key} />}
					<List
						itemLayout="vertical"
						style={{ padding: `0 ${key !== DefaultTitle ? '24px' : ''}` }}
						dataSource={groups[key]}
						renderItem={(item) => {
							const task = item as any;
							return (
								<div>
									<List.Item>
										<List.Item.Meta title={task.title} />
										<div>{getFormField(task)}</div>
									</List.Item>
								</div>
							);
						}}
					/>
				</List.Item>
			))}
		</List>
	);
};

export default TaskListRO;

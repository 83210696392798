import React, { FC, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	addImpairmentsToJournalForSupplier,
	assetsRestCrudThunksForSupplier,
} from '../../thunks/assets_thunks';
import { getCurrency, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { Button, Card, Table } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { DATE_ONLY_FORMAT, dateFormatter } from '../../utils/DataFormatterUtils';

const renderDate = (date) => (date ? dateFormatter(date, DATE_ONLY_FORMAT) : '--');

const PendingJournalsCardDisplay: FC<any> = ({
	fetchAsset,
	addToJournal,
	asset,
	currentUser,
	loading,
	updating,
	onSuccess,
	match,
}): React.ReactElement => {
	const currency = useMemo(() => getCurrency({ currentUser }), [currentUser]);
	const renderCurrencyValue = useCallback((val) => (val ? currency.format(val) : '__'), [currency]);

	const impairmentColumns = useMemo(
		() => [
			{
				title: 'Impairment Date',
				dataIndex: 'impairmentDate',
				render: renderDate,
			},
			{
				title: 'Impairment Value',
				dataIndex: 'impairmentAdjustment',
				render: renderCurrencyValue,
			},
			{
				title: 'Added On',
				dataIndex: 'addedDate',
				render: renderDate,
			},
		],
		[renderCurrencyValue]
	);

	const assetId = useMemo(() => nullSafeGet('params.id', match), [match]);

	const onAddToJournal = useCallback(() => {
		addToJournal(asset).then(() => {
			fetchAsset(assetId);
			onSuccess && onSuccess();
		});
	}, [addToJournal, asset, assetId, fetchAsset, onSuccess]);

	return nullSafeGetOrElse('pendingImpairments', asset, []).length > 0 ? (
		<Card
			title="Pending Impairment Adjustments"
			extra={
				<Button
					loading={updating}
					icon={<PlusCircleOutlined translate="" />}
					type="primary"
					onClick={onAddToJournal}
				>
					Add to Journal
				</Button>
			}
			style={{ marginBottom: 16 }}
		>
			<Table
				loading={loading}
				dataSource={nullSafeGetOrElse('pendingImpairments', asset, [])}
				columns={impairmentColumns}
			/>
		</Card>
	) : null;
};

const mapStateToProps = (state) => ({
	updating: state.assets.updating,
	loading: state.assets.fetching,
	asset: state.assets.detail,
	journalHistories: state.journal_histories,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	fetchAsset: (id) => dispatch(assetsRestCrudThunksForSupplier.readOne(id)),
	addToJournal: (entity) => dispatch(addImpairmentsToJournalForSupplier(entity)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(PendingJournalsCardDisplay)
);

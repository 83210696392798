import { STOCK_LOCATIONS_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/stock_locations_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = standardInitialStateGenerator([
	'stockLocationsIndex',
	'purchaseOrderFormStockLocationDropdown',
	'scheduleServiceCallStockLocationDropdown',
	'stockLocationDropdown',
	'stockLocationsWithPpslIndex',
	'transferPartsStockLocationIndex',
	'epslAdvancedFilters',
	'purchaseRequestsAdvancedFilters',
	'purchaseOrdersAdvancedFilters',
	'poLineItemsAdvancedFilters',
	'prLineItemsAdvancedFilters',
	'fieldTechsDropdown',
	'prLocationCheckIndex',
]);
export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState);

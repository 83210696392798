import React, { FC, forwardRef, useMemo } from 'react';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { getProtectedImageUriForSupplier } from '../../utils/FileAccessUtils';
import QRCode from 'qrcode.react';

const STOCK_LOCATION_ASSOCIATED_PARTS_PER_STOCK_LOCATIONS_TARGET_COLLECTION =
	'stockLocationAssociatedPartsPerStockLocations';

const PartsLabels: FC<any> = forwardRef(
	(
		{
			stockLocation,
			stockLocationsFetching,
			partsPerStockLocations,
			partsPerStockLocationsFetching,
		},
		ref: any
	): React.ReactElement => {
		const loading = useMemo(
			() => stockLocationsFetching || partsPerStockLocationsFetching,
			[partsPerStockLocationsFetching, stockLocationsFetching]
		);
		const ppsls = useMemo(
			() =>
				getRecordsForTargetCollection(
					partsPerStockLocations,
					STOCK_LOCATION_ASSOCIATED_PARTS_PER_STOCK_LOCATIONS_TARGET_COLLECTION
				),
			[partsPerStockLocations]
		);

		return (
			<div ref={ref}>
				{loading ? null : (
					<div className="printPartsPerStockLocationsWrapper">
						<LogOnMountWithStandardEventProperties eventType="visited supplier print part per stock location labels page" />
						<div style={{ marginBottom: '2cm' }}>
							<h3
								style={{
									marginBottom: 0,
									display: 'inline-block',
									verticalAlign: 'top',
									fontSize: '24pt',
								}}
							>
								Parts Labels for {stockLocation.name}
							</h3>
						</div>
						<div className="printPartsPerStockLocationsInnerWrapper">
							{ppsls.map((ppsl) => {
								let photoUris = [];
								if (ppsl.part.images) {
									photoUris = ppsl.part.images.map((partImg) => {
										const [imgFileId, imgFileName] = partImg.split('/');
										return getProtectedImageUriForSupplier(imgFileId, imgFileName, 1200, 800);
									});
								}
								return (
									<div style={{ breakInside: 'avoid', marginBottom: '1cm', width: '100%' }}>
										<div style={{ marginBottom: '12pt' }}>
											<QRCode
												style={{
													display: 'inline-block',
													verticalAlign: 'top',
													margin: '0 12pt 0 0',
												}}
												size={64}
												value={ppsl.partsPerStockLocationUUID}
											/>
											{photoUris.length > 0 ? (
												// eslint-disable-next-line jsx-a11y/img-redundant-alt
												<img
													style={{
														display: 'inline-block',
														verticalAlign: 'top',
													}}
													src={`${photoUris[0]}`}
													width="auto"
													height="64px"
													alt="Unable to render image"
												/>
											) : null}
										</div>
										<div style={{ display: 'inline-block' }}>
											<h4>{nullSafeGet('part.name', ppsl)}</h4>
											<p style={{ fontSize: '16pt' }}>
												{nullSafeGet('part.manufacturerName', ppsl)}{' '}
												{nullSafeGet('part.manufacturerId', ppsl) ||
													nullSafeGet('part.serialNumber', ppsl)}
											</p>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				)}
			</div>
		);
	}
);

export default PartsLabels;

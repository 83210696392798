import React from 'react';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import WorkOrderDisplayStatusDisplay from '../work_order_display_status_display/WorkOrderDisplayStatusDisplay';
import {
	getWorkOrderBasePriorityBackgroundColor,
	getWorkOrderBasePriorityPillBackgroundColor,
} from '../../utils/DataFormatterUtils';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import { STOPLIGHT_COLORS } from '../../utils/DataFormatterUtils';
import { Col, Row } from 'antd';

const moment = require('moment');
require('../work_order_row_display/WorkOrderRowDisplay.less');

const AssetWORowDisplay: React.FC<any> = ({ record, simpler, location, userType }) => {
	if (!record) {
		return null;
	}

	const getLink = () =>
		getLinkWIthBackLinkParams(
			location,
			'Back to asset detail',
			`/${userType || 'buyer'}/workOrders/detail/${record.id}/details`
		);

	return (
		<Row className="index-row-wrapper" style={{ display: 'flex', flexWrap: 'nowrap' }}>
			<Col style={{ display: 'flex', flexGrow: 1 }}>
				<Link className="index-padding" to={getLink()} style={{ width: '100%' }}>
					<div className="rowColumn">
						<div className="rowPrimary">
							<div
								style={{
									maxWidth: '100%',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
									color: 'rgba(0,0,0,.65)',
								}}
							>
								{record.title}
							</div>
						</div>
						<div className="rowSecondary">
							<div className="rowInlineGroup" style={{ display: 'inline-flex' }}>
								<span className="rowGrayChip">
									<i className="icons8-font icons8-location-marker" />
									<span>{nullSafeGet('location.name', record)}</span>
								</span>
							</div>
							<div className="rowInlineGroup" style={{ display: 'inline-flex' }}>
								<WorkOrderDisplayStatusDisplay status={record.displayStatus} />
							</div>
							<div className="rowInlineGroup" style={{ display: simpler ? 'none' : 'inline-flex' }}>
								<span
									style={{
										color: getWorkOrderBasePriorityBackgroundColor(record.woPriority),
										backgroundColor: getWorkOrderBasePriorityPillBackgroundColor(record.woPriority),
										padding: '4px 12px',
										borderRadius: 16,
									}}
								>
									{nullSafeGet('woPriority.name', record)}
								</span>
							</div>
							{record.isCapex ? (
								<div
									className="rowInlineGroup"
									style={{ display: simpler ? 'none' : 'inline-flex' }}
								>
									<span
										className={'rowGrayChip'}
										style={{
											padding: '4px 12px',
											borderRadius: 16,
										}}
									>
										CapEx
									</span>
								</div>
							) : null}
							<div className="rowInlineGroup" style={{ display: simpler ? 'none' : 'inline-flex' }}>
								<i className="icons8-font icons8-maintenance-filled" />
								<span>{nullSafeGet('problemType.hierarchyName', record)}</span>
							</div>
							{nullSafeGet('supplierFacility.displayName', record) ? (
								<div
									className="rowInlineGroup"
									style={{ display: simpler ? 'none' : 'inline-flex' }}
								>
									<i className="icons8-font icons8-company" />
									{nullSafeGet('supplierFacility.displayName', record)}
								</div>
							) : null}
							<div className="rowInlineGroup" style={{ display: simpler ? 'none' : 'inline-flex' }}>
								<span>WO #{nullSafeGet('id', record)}</span>
							</div>
							{nullSafeGet('asset.downtimeStatus', record) === 'offline' ? (
								<div
									className="rowInlineGroup"
									style={{ display: simpler ? 'none' : 'inline-flex' }}
								>
									<span
										style={{
											color: STOPLIGHT_COLORS.pureRed,
											backgroundColor: '#f9e6e6',
											padding: '4px 12px',
											borderRadius: 16,
										}}
									>
										Asset Offline
									</span>
								</div>
							) : null}
							{nullSafeGet('buyerProjects.length', record) === 1 ? (
								<div
									className="rowInlineGroup"
									style={{ display: simpler ? 'none' : 'inline-flex' }}
								>
									<span>
										<i className="icons8-font icons8-blueprint" />
										{nullSafeGet('buyerProjects', record)[0].title}
									</span>
								</div>
							) : nullSafeGet('buyerProjects.length', record) > 1 ? (
								<div
									className="rowInlineGroup"
									style={{ display: simpler ? 'none' : 'inline-flex' }}
								>
									<span>
										<i className="icons8-font icons8-blueprint" />
										{nullSafeGet('buyerProjects.length', record)} Projects
									</span>
								</div>
							) : null}
						</div>
						<div className="rowTertiary">
							<span style={{ whiteSpace: 'nowrap', color: 'rgba(0,0,0,0.45)', fontSize: 14 }}>
								{moment(record.dueDate).diff(moment(), 'milliseconds') < 0 &&
								record.displayStatus !== 'Completed' &&
								record.displayStatus !== 'Cancelled' ? (
									<span
										style={{
											color: 'rgb(163, 40, 40)',
											backgroundColor: 'rgb(249, 230, 230)',
											padding: '4px 12px',
											borderRadius: 16,
										}}
									>
										Past due {moment(record.dueDate).fromNow()}
									</span>
								) : (
									<span>Reported {moment(record.createdAt).fromNow()}</span>
								)}
							</span>
						</div>
					</div>
				</Link>
			</Col>
		</Row>
	);
};

const AssetWORow = withRouter(connect()(AssetWORowDisplay));

export default connect(
	(state) => ({
		userType: state.session.userType,
	}),
	() => ({})
)(AssetWORow);

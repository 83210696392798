import {
	checkHasApprovalHierarchy,
	compareStrings,
	getBudgetPriorityValue,
	getCurrency,
	nullSafeGet,
	nullSafeGetOrElse,
	roundOffToDecimal,
} from '../../utils/DataAccessUtils';
import { Button, Col, message, Popover, Row } from 'antd';
import moment from 'moment';
import * as React from 'react';
import { useMemo, useRef, useState } from 'react';
import ApprovalHierarchyDisplayQuoteModal from './ApprovalHierarchyDisplayQuoteModal';
import DeclineQuoteForm from '../decline_quote_form/DeclineQuoteForm';
import { connect } from 'react-redux';
import { UserName } from '../name_component/user/UserNameComponent';
import { CURRENCIES, ROLE_TYPES } from '../../utils/DataConstants';
import {
	isAllowedToPerform,
	isInternalTech,
	isSupplierAllowedToAccess,
	PERMISSION_NAMES,
} from '../../utils/AuthUtils';
import ForwardApproval from '../forward_approval/ForwardApproval';
import {
	forwardApprovalToUserForBuyer,
	forwardApprovalToUserForSupplier,
} from '../../thunks/company_approval_thunks';
import { QUOTE_STATUSES } from '../../constants/QuoteStatuses';
import BudgetChart from '../charts/BudgetChart';
import _ from 'lodash';

function ApprovalHierarchyDisplayQuote({
	quote,
	currentUser,
	approveQuote,
	declineQuote,
	syncQuote,
	appendWorkOrderNotes,
	refresh,
	style = {},
	allowedToApprove = true,

	history,
	companyConfig,
	userType,

	forwardApprovalToUser,
}) {
	const defaultActionsAllowed =
		userType === ROLE_TYPES.SUPPLIER
			? isSupplierAllowedToAccess(
					PERMISSION_NAMES.CAN_APPROVE_QUOTES,
					companyConfig,
					currentUser.roles
			  )
			: isAllowedToPerform('canApproveQuotes', companyConfig, currentUser.roles);
	const [showModal, setShowModal] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [showDeclineModal, setShowDeclineModal] = useState(false);
	const status = nullSafeGet('status', quote);
	const reason = useRef();
	const setReason = (str) => (reason.current = str);
	const showStatus = (status) => {
		switch (status) {
			case 'pending':
				return 'Pending Approvers';
			case 'declined':
				return 'Approvers/Disputers';
			case 'awarded':
				return 'Approvers';
			case 'voided':
				return 'Approver List';
			default:
				return 'Approver List';
		}
	};
	const hasApprovalHierarchy = checkHasApprovalHierarchy(quote);
	const hasApprovers = (tiers) =>
		tiers.flatMap((tier) => tier.approvers).some((approvers) => approvers.length > 0);
	const userTier = (tiers) =>
		hasApprovalHierarchy
			? tiers.filter((tier) =>
					tier.approvers.some((approver) => compareStrings(approver.email, currentUser.email))
			  ).length > 0
				? tiers.find((tier) =>
						tier.approvers.some((approver) => compareStrings(approver.email, currentUser.email))
				  )
				: undefined
			: tiers[0];

	const currency = getCurrency({ workOrder: nullSafeGet('workOrder', quote) });
	const buyerCompanyCurrency = getCurrency({
		buyerCompany: nullSafeGet('workOrder.buyerCompany', quote),
	});
	const exchangeRate =
		currency.id === buyerCompanyCurrency.id
			? 1
			: nullSafeGetOrElse('workOrder.currencyExchangeRateLocationToSupplier', quote, 1) *
			  nullSafeGetOrElse('workOrder.currencyExchangeRateBuyerToLocation', quote, 1);
	let quoteTotal = nullSafeGet('totalAfterTax', quote) / exchangeRate;
	let lessThanQuote = true;
	let highestValidAmount = 0;
	let highestApprovedAmount = 0;
	const highestApprovalTierAmount = (tiers) => {
		let highestTiers = [...tiers]
			.filter((tier) => {
				let approvedByUsers = nullSafeGet('approvedByUsers', tier);
				let declinedByUsers = nullSafeGet('declinedByUsers', tier);
				if (approvedByUsers && declinedByUsers) {
					return approvedByUsers.concat(declinedByUsers).length > 0;
				} else {
					return false;
				}
			})
			.map((tier) => {
				return nullSafeGetOrElse('amountUpto', tier, Infinity);
			});
		if (highestTiers.length > 0) {
			return highestTiers[highestTiers.length - 1];
		}
		return 0;
	};

	let lastAmount = 0;
	const tiers = nullSafeGet('approvalHierarchy.tierDetails', quote)
		? nullSafeGet('approvalHierarchy.tierDetails', quote)
				//Sort tiers primarily by tier.amountUpto, secondarily by tier.label
				.sort((a, b) =>
					a.amountUpto && b.amountUpto
						? parseFloat(a.amountUpto) > parseFloat(b.amountUpto)
							? 1
							: parseInt(a.label) > parseInt(b.label)
							? 1
							: -1
						: a.amountUpto === undefined
						? 1
						: -1
				)
				//Setup tier objects
				.map((tier) => {
					if (nullSafeGetOrElse('approvedByUsers', tier, []).length > 0) {
						highestApprovedAmount = parseInt(nullSafeGetOrElse('amountUpto', tier, Infinity));
					}
					return {
						...tier,
						approvedByUsers: nullSafeGetOrElse('approvedByUsers', tier, []).filter(
							(user, i, users) => i === users.findIndex((u) => compareStrings(u.email, user.email))
						),
						declinedByUsers: nullSafeGetOrElse('declinedByUsers', tier, []).filter(
							(user, i, users) => i === users.findIndex((u) => compareStrings(u.email, user.email))
						),
					};
				})
				.map((tier, i, tiers) => {
					let message;
					let isVisible = () => {
						//We also want to display tiers which fit one of the following criteria:
						let amount = parseInt(nullSafeGet('amountUpto', tier));

						//1.This tier has either approvers or decliners and is >= the highest amount approved
						if (
							highestApprovedAmount > amount &&
							tier.approvedByUsers.length === 0 &&
							tier.declinedByUsers.length === 0
						) {
							//Not shown because a higher tier already approved
							message = 'A higher tier has already approved';
							return false;
						}
						if (
							highestApprovedAmount === amount &&
							tier.approvers.length === 0 &&
							tier.approvedByUsers.length === 0
						) {
							//Not shown because an equal tier has already approved and this tier has no approvers
							message =
								'An equal tier has approved and this tier has no approvers for this location';
							return false;
						}
						if (!lessThanQuote) {
							let lastAmount = parseInt(nullSafeGetOrElse('amountUpto', tiers[i - 1], 0));
							//3. This tier has already been approved or declined
							if (tier.approvedByUsers.length > 0 || tier.declinedByUsers.length > 0) {
								return true;
							} else {
								//4. This tier is the same amount as the previous tier
								if (highestValidAmount === amount && highestValidAmount === lastAmount) {
									return true;
								} else {
									message = `Approval from this tier isn't required for quotes less than ${currency.format(
										(lastAmount ? lastAmount : amount) * exchangeRate
									)}`;
									return false;
								}
							}
						}

						if (amount && quoteTotal) {
							if (parseFloat(amount) > parseFloat(quoteTotal)) {
								//2. This tier is one tier over the quote and contains potential approvers
								highestValidAmount = amount;
								if (nullSafeGetOrElse('approvers.length', tier, 0) > 0) {
									lessThanQuote = false;
								}
							}
						} else {
							//1. This tier is less than the quote
							lessThanQuote = false;
						}
						return true;
					};
					lastAmount =
						nullSafeGetOrElse('amountUpto', tiers[i - 1], 0) === nullSafeGet('amountUpto', tier)
							? lastAmount
							: nullSafeGetOrElse('amountUpto', tiers[i - 1], 0);
					return { ...tier, isVisible: isVisible(), message, lastAmount };
				})
		: [];
	const highestAmount = highestApprovalTierAmount(tiers);
	let myTier = userTier(tiers);
	const myTierAmount = nullSafeGet('amountUpTo', myTier);
	const currentQuote = {
		...quote,
		approvalHierarchy: { ...quote.approvalHierarchy, tierDetails: tiers },
		highestAmount: highestAmount,
	};
	const allowApproval = useMemo(() => {
		if (status !== 'awarded') {
			if (hasApprovalHierarchy && myTier !== undefined) {
				if (myTierAmount === undefined || parseFloat(myTierAmount) >= highestAmount) {
					if (
						!nullSafeGetOrElse('approvedByUsers', myTier, []).some((user) =>
							compareStrings(currentUser.email, user.email)
						)
					) {
						return true;
					} else {
						setReason('Your tier has already approved.');
						return false;
					}
				}
			} else if (!hasApprovalHierarchy && myTier !== undefined) {
				//no approval hierarchy
				if (
					nullSafeGetOrElse('approvedByUsers', myTier, []).some((user) =>
						compareStrings(currentUser.email, user.email)
					)
				) {
					setReason('You have already approved.');
					return false;
				} else if (nullSafeGetOrElse('approvedByUsers', myTier, []).length > 1) {
					setReason('Someone has already approved.');
					return false;
				} else {
					setReason(`You are not allowed to approve/dispute quote.`);
					return defaultActionsAllowed;
				}
			} else if (!hasApprovalHierarchy && myTier === undefined) {
				setReason(`You are not allowed to approve/dispute quote.`);
				return defaultActionsAllowed;
			}
			setReason('You are not in the approval hierarchy.');
		} else {
			setReason('This quote has already been approved.');
		}
		return false;
	}, []);
	const allowDecline = useMemo(() => {
		if (status !== 'awarded' && status !== 'declined') {
			if (hasApprovalHierarchy && myTier) {
				if (myTierAmount === undefined || parseFloat(myTierAmount) >= highestAmount) {
					if (
						!nullSafeGetOrElse('approvedByUsers', myTier, []).some((user) =>
							compareStrings(currentUser.email, user.email)
						)
					) {
						return true;
					} else {
						setReason('Your tier has already approved.');
						return false;
					}
				}
			} else if (!hasApprovalHierarchy && myTier !== undefined) {
				if (
					nullSafeGetOrElse('approvedByUsers', myTier, []).some((user) =>
						compareStrings(currentUser.email, user.email)
					)
				) {
					setReason('You have already approved.');
					return false;
				} else if (nullSafeGetOrElse('approvedByUsers', myTier, []).length > 1) {
					setReason('Someone has already approved.');
					return false;
				} else {
					setReason(`You are not allowed to approve/dispute quote.`);
					return defaultActionsAllowed;
				}
			} else if (!hasApprovalHierarchy && myTier === undefined) {
				setReason(`You are not allowed to approve/dispute quote.`);
				return defaultActionsAllowed;
			}
		}
		if (status === 'declined') {
			setReason('This quote has already been declined.');
		}
		return false;
	}, []);

	const handleApproveQuote = (quote) => {
		let myTier = userTier(tiers)
			? userTier(tiers)
			: {
					approvers: [],
					approvedByUsers: [],
					declinedByUsers: [],
					approvedByType: 'any',
					label: '1',
			  };

		//Add this user to approvedByUsers list
		myTier.approvedByUsers.push({
			email: currentUser.email.toLowerCase(),
			approverType: 'user',
			contact: currentUser,
		});
		let newquote = {
			...quote,
			approvalHierarchy: {
				...quote.approvalHierarchy,
				tierDetails: quote.approvalHierarchy.tierDetails.map((tier) => {
					return tier.id === myTier.id ? { ...myTier } : tier;
				}),
			},
		};
		setButtonLoading(true);
		message.open({
			key: 'quoteDetailMessageUpdating',
			type: 'loading',
			content: 'Approving...',
			duration: 60,
		});
		approveQuote(newquote).then((resultQuote) => {
			setButtonLoading(false);
			message.destroy('quoteDetailMessageUpdating');
			message.success('Approved!');
			refresh();
		});
	};
	const handleDisputeQuote = (quote) => {
		setShowDeclineModal(true);
	};
	const handleSubmitDisputeQuote = (values) => {
		let myTier = userTier(tiers)
			? userTier(tiers)
			: {
					approvers: [],
					approvedByUsers: [],
					declinedByUsers: [],
					approvedByType: 'any',
					label: '1',
			  };

		//Add this user to declining user list
		myTier.declinedByUsers.push({
			email: currentUser.email.toLowerCase(),
			approverType: 'user',
			contact: currentUser,
		});

		//If this user is currently approving, remove them from approving list
		myTier.approvedByUsers = myTier.approvedByUsers.filter((user) => {
			return user.email.toLowerCase() !== currentUser.email.toLowerCase();
		});
		const note = {
			text: 'Quote Declined: '.concat(values.note),
			noteAddedBy: nullSafeGet('email', currentUser),
			noteAddedAt: moment.utc(),
		};
		const workOrderNoteDetails = {
			id: nullSafeGet('workOrder.id', quote),
			locationId: nullSafeGet('workOrder.locationId', quote),
			note,
		};

		let newquote = {
			...currentQuote,
			approvalHierarchy: {
				...currentQuote.approvalHierarchy,
				tierDetails: currentQuote.approvalHierarchy.tierDetails.map((tier) => {
					return tier.id === myTier.id ? { ...myTier } : tier;
				}),
			},
			declineNote: {
				text: values.note,
				noteAddedBy: nullSafeGet('email', currentUser),
				noteAddedAt: moment.utc(),
			},
		};

		setButtonLoading(true);
		declineQuote(newquote).then((resultQuote) => {
			appendWorkOrderNotes(workOrderNoteDetails).then(() => {
				message.success(`Quote declined.`);
				setShowDeclineModal(false);
				setButtonLoading(false);
				refresh();
			});
		});
	};
	const contactType = nullSafeGetOrElse('contactType', currentUser, 'buyer');
	const isInternalTech = nullSafeGetOrElse('facility.status', currentUser, '') === 'private';

	const lastDeclineNote = currentQuote.declineNotes && currentQuote.declineNotes.slice(-1)[0];
	const declineNote = lastDeclineNote ? (
		<Col className={'pb-4'}>
			<div>
				<span style={{ fontSize: 16 }}>
					Declined by{' '}
					<UserName mode={'inline'} contact={nullSafeGet(`noteAddedByContact`, lastDeclineNote)} />{' '}
					{moment(nullSafeGet(`noteAddedAt`, lastDeclineNote)).fromNow()}
				</span>
			</div>
			<div
				style={{
					display: 'inline-flex',
					background: '#fafafa',
					color: 'rgba(0, 0, 0, 0.65)',
					borderRadius: '30px',
					padding: '8px 16px',
				}}
			>
				<span
					style={{
						fontSize: 16,
					}}
				>
					{nullSafeGet(`text`, lastDeclineNote)}
				</span>
			</div>
			{contactType === 'supplier' && !isInternalTech ? (
				<div className={'mt-2 flex gap-4'}>
					<Button
						type="primary"
						key="Edit"
						onClick={() => history.push(`/supplier/quotes/edit/${currentQuote.id}`)}
					>
						Edit Quote
					</Button>
					<Button
						key="Note"
						onClick={() =>
							history.push(`/supplier/workOrders/detail/${currentQuote.workOrderId}/notes`)
						}
					>
						Send a note
					</Button>
				</div>
			) : null}
		</Col>
	) : null;
	const showApproval = [QUOTE_STATUSES.pending, QUOTE_STATUSES.disputed].some(
		(status) => status === quote.status
	);

	const isExternal = contactType === 'supplier' && !isInternalTech;
	const focusedBudget = !isExternal ? nullSafeGetOrElse('workOrder.budgets.0', quote, {}) : {};
	const amount = quoteTotal;

	const { budget, budgetConsumed, thresholdPercentage } = focusedBudget;
	const consumed = parseFloat(budgetConsumed);
	const threshold = (parseFloat(thresholdPercentage) / 100) * budget;
	const overPercentage = Math.abs(roundOffToDecimal(((consumed + amount) / budget) * 100 - 100, 0));

	const isExceedingBudget = consumed + amount > budget;
	const isExceedingThreshold = consumed + amount > threshold && !isExceedingBudget;

	const hasAlert = isExceedingBudget || isExceedingThreshold;

	const budgetMessage = isExceedingBudget
		? `Approving this quote will put you ${overPercentage}% over budget.`
		: isExceedingThreshold
		? `Approving this quote will put you ${overPercentage}% from your budget limit.`
		: undefined;
	const icon = isExceedingBudget ? (
		<i className="icons8-font icons8-error" style={{ marginRight: 8, color: 'red' }} />
	) : (
		<i className="icons8-font icons8-error" style={{ marginRight: 8, color: 'orange' }} />
	);

	const showAlert = hasAlert && compareStrings(currentQuote.status, 'pending');
	const quoteString =
		currentQuote && currentQuote.status
			? compareStrings(currentQuote.status, 'pending')
				? showAlert && allowApproval
					? 'Are you sure you want to approve this quote?'
					: 'This quote is on hold until it has been fully approved'
				: compareStrings(currentQuote.status, 'awarded')
				? 'This quote has been approved'
				: compareStrings(currentQuote.status, 'declined')
				? 'This quote has been declined'
				: 'This quote has been voided'
			: null;

	const locationCurrency =
		CURRENCIES[nullSafeGetOrElse('workOrder.locationCurrencyId', quote, 'USD')];

	const approvalForwardingEnabled =
		userType === 'supplier'
			? _.get(
					currentUser,
					'facility.privateBuyerCompanySettings.config.approvalHierarchyConfig.addUserToProposalApprovalHierarchyOnTheGo',
					true
			  )
			: _.get(
					companyConfig,
					'config.approvalHierarchyConfig.addUserToProposalApprovalHierarchyOnTheGo',
					true
			  );
	const showForwardForApproval =
		(hasApprovalHierarchy || hasApprovers(tiers)) &&
		status === 'pending' &&
		approvalForwardingEnabled;
	return (
		<div className={'w-10/12 flex-col'}>
			<Row style={{ flexWrap: 'nowrap' }}>
				<Col style={{ flexGrow: 1 }}>
					<div className={'flex-row'}>
						<DeclineQuoteForm
							visible={showDeclineModal}
							onCancel={() => setShowDeclineModal(false)}
							onSubmit={handleSubmitDisputeQuote}
						/>
						<ApprovalHierarchyDisplayQuoteModal
							tiers={tiers}
							hasApprovalHierarchy={hasApprovalHierarchy}
							currentQuote={currentQuote}
							setVisible={setShowModal}
							visible={showModal}
							style={style}
							reason={reason}
							allowApproval={allowApproval}
							exchangeRate={exchangeRate}
							currency={currency}
							currentUser={currentUser}
							refresh={refresh}
							syncQuote={syncQuote}
						/>
						{showAlert && allowApproval ? (
							<div style={{ marginBottom: 8 }}>
								<h5 style={{ display: 'flex' }}>
									{icon} {budgetMessage}
								</h5>
								<span style={{ fontSize: 16 }}>{quoteString}</span>
							</div>
						) : (
							<div>
								<h5 style={{ display: 'flex' }}>{quoteString}</h5>
							</div>
						)}
					</div>
					{status === 'declined' ? declineNote : null}
					{allowedToApprove ? (
						<div
							style={{
								display: 'inline-flex',
								position: 'relative',
							}}
						>
							<div style={{ gap: '16px', alignContent: 'flex-start' }} className={'flex flex-wrap'}>
								{showApproval ? (
									allowApproval ? (
										<Button
											type="primary"
											key="approve"
											loading={buttonLoading}
											onClick={() => {
												setButtonLoading(true);
												handleApproveQuote(currentQuote);
											}}
										>
											Approve Quote
										</Button>
									) : (
										<Popover
											disabled={allowApproval}
											title={`Why can't I approve?`}
											content={nullSafeGet('current', reason)}
										>
											<Button
												type="primary"
												key="approve"
												loading={buttonLoading}
												disabled={true}
												onClick={() => {
													setButtonLoading(true);
													handleApproveQuote(currentQuote);
												}}
											>
												Approve Quote
											</Button>
										</Popover>
									)
								) : null}
								{showApproval ? (
									allowDecline ? (
										<Button
											key="reject"
											loading={buttonLoading}
											onClick={() => {
												setButtonLoading(true);
												handleDisputeQuote(currentQuote);
											}}
										>
											Decline Quote
										</Button>
									) : (
										<Popover
											title={`Why can't I decline?`}
											content={nullSafeGet('current', reason)}
										>
											<Button
												key="reject"
												loading={buttonLoading}
												disabled={true}
												onClick={() => {
													setButtonLoading(true);
													handleDisputeQuote(currentQuote);
												}}
											>
												Decline Quote
											</Button>
										</Popover>
									)
								) : null}
								{showForwardForApproval ? (
									<ForwardApproval
										disabled={!(hasApprovalHierarchy && myTier !== undefined)}
										reason={reason}
										workOrder={quote.workOrder}
										entity={quote}
										refresh={refresh}
										forwardApprovalToUser={forwardApprovalToUser}
										buttonLoading={buttonLoading}
									/>
								) : null}
								{hasApprovalHierarchy || hasApprovers(tiers) ? (
									<div
										className={'inline-flex flex-row items-center'}
										style={{ cursor: 'pointer', fontSize: '16px' }}
										onClick={() => setShowModal(true)}
									>
										<i className="icons8-font icons8-multiple-users" />
										<span style={{ marginLeft: '8px', textDecoration: 'underline' }}>
											{showStatus(status)}
										</span>
									</div>
								) : null}
							</div>
						</div>
					) : null}
				</Col>
				{focusedBudget && hasAlert && allowApproval ? (
					<Col style={{ marginLeft: -40, marginBottom: -80 }}>
						<BudgetChart
							currency={locationCurrency}
							data={[
								{
									budget: focusedBudget.title,
									'Budget Used': consumed,
									'This Quote': quoteTotal,
									'Remaining Budget': budget - (consumed + quoteTotal),
								},
							]}
							consumed={consumed > 0}
							remaining={budget - (consumed + quoteTotal) > 0}
							budgetTotal={budget}
							recordType={'Quote'}
						/>
					</Col>
				) : null}
			</Row>
		</div>
	);
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
	forwardApprovalToUser: (entity, email) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? forwardApprovalToUserForSupplier('proposal', entity, email)
				: forwardApprovalToUserForBuyer('proposal', entity, email)
		),
});
const ComponentWithoutUserType = connect(
	mapStateToProps,
	mapDispatchToProps
)(ApprovalHierarchyDisplayQuote);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
		companyConfig: (state as any).company_config.detail,
	}),
	() => ({})
)(ComponentWithoutUserType);

import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { ASSET_MODELS_CRUD_ACTION_CREATORS } from '../actions/asset_models_actions';

export const assetModelsRestCrudThunksForBuyer = new RestCrudThunks(
	'asset_models',
	'asset_model',
	'assetModel',
	'assetModels',
	ASSET_MODELS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'asset/'
);
export const assetModelsRestCrudThunksForSupplier = new RestCrudThunks(
	'asset_models',
	'asset_model',
	'assetModel',
	'assetModels',
	ASSET_MODELS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'asset/'
);

import { PLANNED_MAINTENANCE_TEMPLATES_CRUD_ACTION_CREATORS } from '../actions/planned_maintenance_templates_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';

export const plannedMaintenanceTemplatesRestCrudThunksForBuyer = new RestCrudThunks(
	'planned_maintenance_templates',
	'planned_maintenance_template',
	'plannedMaintenanceTemplate',
	'plannedMaintenanceTemplates',
	PLANNED_MAINTENANCE_TEMPLATES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'pm/'
);
export const plannedMaintenanceTemplatesRestCrudThunksForSupplier = new RestCrudThunks(
	'planned_maintenance_templates',
	'planned_maintenance_template',
	'plannedMaintenanceTemplate',
	'plannedMaintenanceTemplates',
	PLANNED_MAINTENANCE_TEMPLATES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'pm/'
);

import { INVOICES_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/invoices_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = standardInitialStateGenerator([
	'invoicesIndex',
	'invoicesTable',
	'workOrderAssociatedInvoices',
	'ATTENTION_DASHBOARD_PENDING_APPROVAL_INVOICES',
]);
export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState);

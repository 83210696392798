import { Layout } from 'antd';
import React, { FC, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EmptyState } from '../empty_state/EmptyState';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import { assetsRestCrudThunksForSupplier } from '../../thunks/assets_thunks';
import { getEntityById } from '../../utils/DataAccessUtils';

const DEFAULT_BACK_LINK_TEXT = 'Back to Received Assets';
const TC_NAME = 'purchaseOrderReceivedAssets';
const { Content } = Layout;

const PurchaseRequestReceivedAssetsPage: FC<any> = ({
	location,
	history,
	match,
}): React.ReactElement => {
	const purchaseRequestId = useMemo(() => match.params.id, [match.params.id]);

	const onAssetRow = useCallback(
		(record) => ({
			onClick: () => {
				const link = getLinkWIthBackLinkParams(
					location,
					DEFAULT_BACK_LINK_TEXT,
					`/supplier/assets/detail/${record.id}/details`
				);
				console.log(link);
				history.push(link);
			},
		}),
		[history, location]
	);

	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
		},
		{
			title: 'Asset ID',
			dataIndex: 'buyerSpecificAssetId',
			render: (_, asset) => _ || asset.id,
		},
		{
			title: 'Serial Number',
			dataIndex: 'serialNumber',
		},
		{
			title: 'Asset Number',
			dataIndex: 'assetNumber',
		},
		{
			title: 'Asset Type',
			dataIndex: ['assetType', 'name'],
		},
		{
			title: 'Location',
			dataIndex: ['location', 'name'],
		},
	];

	return (
		<Content
			className="paginated-redux-table-with-header"
			style={{
				padding: '0 0.5em',
			}}
		>
			<PaginatedReduxTable
				mode="list"
				updateQueryParams={true}
				emptyState={
					<EmptyState
						graphic={
							<img
								alt="No data"
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
							/>
						}
						headline={'No asset found'}
					/>
				}
				showHeader
				collectionName="assets"
				onRow={onAssetRow}
				targetCollectionName={TC_NAME}
				columns={columns}
				keyAccessor={(el) => el.id}
				additionalParams={{
					purchaseRequestId,
				}}
				fetchData={assetsRestCrudThunksForSupplier.read}
			/>
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	purchaseOrder: getEntityById(ownProps.match.params.id, state.purchase_requests),
});

export default withRouter<any, any>(connect(mapStateToProps)(PurchaseRequestReceivedAssetsPage));

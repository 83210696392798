import { SUPPLIER_FACILITY_REVIEWS_CRUD_ACTION_CREATORS } from '../actions/supplier_facility_reviews_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { getBackendUri } from '../utils/EnvConfigUtils';
import FetchUtils, { parseJSON } from './fetch_utils';
import { logoutSuccess } from '../actions/session_actions';

const fetchUtils = new FetchUtils();

export const supplierFacilityReviewsRestCrudThunksForBuyer = new RestCrudThunks(
	'supplier_facility_reviews',
	'supplier_facility_review',
	'supplierFacilityReview',
	'supplierFacilityReviews',
	SUPPLIER_FACILITY_REVIEWS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER
);
export const supplierFacilityReviewsRestCrudThunksForSupplier = new RestCrudThunks(
	'supplier_facility_reviews',
	'supplier_facility_review',
	'supplierFacilityReview',
	'supplierFacilityReviews',
	SUPPLIER_FACILITY_REVIEWS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER
);
export const supplierFacilityReviewsRestCrudThunksForPublic = new RestCrudThunks(
	'supplier_facility_reviews',
	'supplier_facility_review',
	'supplierFacilityReview',
	'supplierFacilityReviews',
	SUPPLIER_FACILITY_REVIEWS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.PUBLIC
);

const sendReferralReviews = (roleType) => (entity) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/reviews/referral/to_buyers`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(SUPPLIER_FACILITY_REVIEWS_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.post(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: entity,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(SUPPLIER_FACILITY_REVIEWS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(SUPPLIER_FACILITY_REVIEWS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(SUPPLIER_FACILITY_REVIEWS_CRUD_ACTION_CREATORS.createError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

export const sendReferralReviewsForBuyer = sendReferralReviews('buyer');
export const sendReferralReviewsForSupplier = sendReferralReviews('supplier');

import { DEPRECIATION_CLASS_CRUD_ACTION_CREATORS } from '../actions/depreciation_class_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';

export const depreciationClassesRestCrudThunksForBuyer = new RestCrudThunks(
	'depreciation_classes',
	'depreciation_class',
	'depreciationClass',
	'depreciationClasses',
	DEPRECIATION_CLASS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'asset/'
);
export const depreciationClassesRestCrudThunksForSupplier = new RestCrudThunks(
	'depreciation_classes',
	'depreciation_class',
	'depreciationClass',
	'depreciationClasses',
	DEPRECIATION_CLASS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'asset/'
);

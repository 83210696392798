import React, { FC } from 'react';
import { Route, Redirect } from 'react-router-dom';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';

const SupplierRFPPage: FC<any> = ({ childRoutes }): React.ReactElement => {
	return (
		<div>
			<Route
				path="/supplier/requestsForProposal"
				exact={true}
				render={(props) => (
					<Redirect
						to={{
							pathname: '/supplier/requestsForProposal/overview',
						}}
					/>
				)}
			/>
			{childRoutes.map((r, i) => (
				<RouteWithSubRoutes key={i} {...r} />
			))}
		</div>
	);
};

export default SupplierRFPPage;

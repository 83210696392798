import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Table, Input, Radio, message, Button, Card } from 'antd';
import { ContactAvatar } from '../contact_avatar/ContactAvatar';
import {
	disableUser,
	fetchFacilityActiveUsers,
	fetchFacilityUsers,
	reinviteUser,
} from '../../thunks/users_thunks';
import { statusIndicatorFormatter } from '../../utils/DataFormatterUtils';
import { getObjectValues, hasAccessToAllLocations, nullSafeGet } from '../../utils/DataAccessUtils';
import BuyerNewUserInviteForm from '../buyer_new_user_invite_form/BuyerNewUserInviteForm';
import {
	buyerSignUpInvite,
	buyerRoleUpdateUser,
	buyerUpdateUserContact,
	adminChangePassword,
} from '../../thunks/buyer_contacts_thunks';
import { ROLES } from '../roles/roles';
import SubnavBar from '../subnav_bar/SubnavBar';
import { debounce } from '../../utils/PerformanceUtils';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { checkUserTypeToken } from '../../thunks/session_thunks';
import queryString from 'querystring';
import PasswordUpdateForm from '../password_update_form/PasswordUpdateForm';
import BuyerAdminRoleUpdateForm from '../buyer_role_update_form/BuyerAdminRoleUpdateForm';
const { Content } = Layout;

require('./BuyerAdminUsersPage.less');

const initState = {
	filteredInfo: null,
	sortedInfo: null,
	visible: false,
	roleUpdateVisible: false,
	invitedUsers: new Set([]),
	userContact: null,
	displayMode: 'active',
	changePasswordVisible: false,
	searchText: undefined,
};

interface SourcingPageState {
	filteredInfo: any;
	sortedInfo: any;
	visible: boolean;
	roleUpdateVisible: boolean;
	invitedUsers: any;
	userContact: any;
	displayMode: any;
	changePasswordVisible: any;
	passwordFormVisible: boolean;
	searchText: any;
}

class BuyerAdminUsersPage extends React.Component<any, SourcingPageState> {
	formRefs: any;
	initialPagination: any = {
		current: 1,
		pageSize: 10,
		showSizeChanger: true,
		pageSizeOptions: ['5', '10', '25', '50'],
		onChange: (current, size) => this.handleSizeChange(current, size),
	};
	constructor(props) {
		super(props);
		const { location } = this.props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		const { current, pageSize, ...other } = queryParams;
		this.state = {
			...initState,
		};
		this.initialPagination = { ...this.initialPagination, current: current, pageSize: pageSize };
		this.handleChange = this.handleChange.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
		this.clearAll = this.clearAll.bind(this);
		this.handleBuyerUserInviteCancel = this.handleBuyerUserInviteCancel.bind(this);
		this.handleBuyerUserInviteSubmit = this.handleBuyerUserInviteSubmit.bind(this);
		this.showInviteUserModal = this.showInviteUserModal.bind(this);
		this.handleBuyerRoleUpdateSubmit = this.handleBuyerRoleUpdateSubmit.bind(this);
		this.handleBuyerRoleUpdateCancel = this.handleBuyerRoleUpdateCancel.bind(this);
		this.showRoleUpdateModal = this.showRoleUpdateModal.bind(this);
		this.showChangePasswordModal = this.showChangePasswordModal.bind(this);
		this.handleChangePasswordSubmit = this.handleChangePasswordSubmit.bind(this);
		this.handleChangePasswordCancel = this.handleChangePasswordCancel.bind(this);
		this.formRefs = {
			userInviteForm: null,
			roleUpdateForm: null,
			changePasswordForm: null,
		};
	}

	handleSizeChange = (current, size) => {
		const { history, location, fetchBuyerActiveUsers, fetchBuyerUsers } = this.props;
		let backUrl = `/buyer/admin/users?`;
		let searchParams = new URLSearchParams(location.search);
		searchParams.set('current', current);
		searchParams.set('pageSize', size);
		history.push(backUrl.concat(searchParams.toString()));
		this.initialPagination = { ...this.initialPagination, current: current, pageSize: size };
		if (this.state.displayMode === 'active') {
			fetchBuyerActiveUsers();
		} else {
			fetchBuyerUsers();
		}
	};
	handlePageChange = (current, size) => {
		const { history, fetchBuyerActiveUsers, fetchBuyerUsers } = this.props;

		let backUrl = `/buyer/admin/users`;
		history.push(backUrl);
		if (this.state.displayMode === 'active') {
			fetchBuyerActiveUsers();
		} else {
			fetchBuyerUsers();
		}
	};
	componentDidMount() {
		const { fetchBuyerActiveUsers, fetchBuyerUsers } = this.props;
		if (this.state.displayMode === 'active') {
			fetchBuyerActiveUsers();
		} else {
			fetchBuyerUsers();
		}
	}

	showInviteUserModal(e) {
		this.setState({
			visible: true,
		});
	}

	showRoleUpdateModal = (record) => (e) => {
		this.setState({
			roleUpdateVisible: true,
			userContact: record,
		});
	};
	showChangePasswordModal = (record) => (e) => {
		this.setState({
			changePasswordVisible: true,
			userContact: record,
		});
	};

	handleChange(pagination, filters, sorter) {
		this.setState({
			filteredInfo: filters,
			sortedInfo: sorter,
		});
	}

	clearFilters() {
		this.setState({ filteredInfo: null });
	}

	clearAll() {
		this.setState({
			filteredInfo: null,
			sortedInfo: null,
		});
	}

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	handleBuyerUserInviteSubmit(values) {
		const { inviteNewUser } = this.props;
		const contact = {
			...values,
			nameFamily: values.nameFamily || '',
			roles: [values.roles],
			hasAccessToAllLocations: hasAccessToAllLocations(values.roles, values) || false,
		};
		if (!contact.hasAccessToAllLocations) {
			contact['locationIds'] = values.locationIds;
		}

		inviteNewUser(contact).then(() => {
			this.setState({ visible: false });
			message.success(`Invite sent to ${contact.nameGiven} at ${contact.email}.`);
		});
	}

	handleBuyerUserInviteCancel(e) {
		this.setState({
			visible: false,
		});
	}

	handleBuyerRoleUpdateSubmit(values) {
		const { updateUserContact, refreshCurrentUser, fetchBuyerActiveUsers, fetchBuyerUsers } =
			this.props;

		const updateContact = {
			...values,
			userId: values.email,
			roles: [values.roles],
		};
		if (!values.hasAccessToAllLocations) {
			updateContact['locationIds'] = values.locationIds;
		}

		updateUserContact(updateContact).then(() => {
			refreshCurrentUser().then(() => {
				this.setState({ roleUpdateVisible: false });
				let refreshRecords =
					this.state.displayMode === 'active' ? fetchBuyerActiveUsers : fetchBuyerUsers;
				refreshRecords().then(() => {
					message.success(`Role Updated`);
				});
			});
		});
	}

	handleBuyerRoleUpdateCancel(e) {
		this.setState({
			roleUpdateVisible: false,
		});
	}

	handleChangePasswordSubmit() {
		const { adminChangePassword, refreshCurrentUser, fetchBuyerActiveUsers, fetchBuyerUsers } =
			this.props;
		const form = this.formRefs['changePasswordForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			const updateContact = {
				userName: values.email,
				newPassword: values.newPassword,
			};

			adminChangePassword(updateContact).then(() => {
				refreshCurrentUser().then(() => {
					let refreshRecords =
						this.state.displayMode === 'active' ? fetchBuyerActiveUsers : fetchBuyerUsers;
					refreshRecords().then(() => {
						this.setState({ changePasswordVisible: false });
						form.resetFields();
						message.success(`Password Changed`);
					});
				});
			});
		});
	}

	handleChangePasswordCancel(e) {
		const form = this.formRefs['changePasswordForm'].props.form;
		form.resetFields();
		this.setState({
			changePasswordVisible: false,
		});
	}

	debouncedCallback = debounce(
		(searchText) => {
			const { fetchBuyerActiveUsersWithFilter, fetchBuyerUsersWithFilter } = this.props;
			if (this.state.displayMode === 'active') {
				fetchBuyerActiveUsersWithFilter({ search: searchText && searchText.trim() });
			} else {
				fetchBuyerUsersWithFilter({ search: searchText && searchText.trim() });
			}
			this.setState({ searchText: searchText && searchText.trim() });
		},
		400,
		false
	);

	handleSearchDebounced = (e) => {
		const searchText = e.target.value;
		this.debouncedCallback(searchText);
	};

	switchDisplayMode = (e) => {
		const mode = nullSafeGet('target.value', e);
		this.setState({ displayMode: mode });
		const {
			fetchBuyerActiveUsers,
			fetchBuyerUsers,
			fetchBuyerActiveUsersWithFilter,
			fetchBuyerUsersWithFilter,
		} = this.props;
		const { searchText } = this.state;
		if (mode === 'active') {
			searchText
				? fetchBuyerActiveUsersWithFilter({ search: searchText })
				: fetchBuyerActiveUsers();
		} else {
			searchText ? fetchBuyerUsersWithFilter({ search: searchText }) : fetchBuyerUsers();
		}
	};
	isDisplayModeActive = (mode) => this.state.displayMode === mode;

	render() {
		let filteredInfo = this.state.filteredInfo;
		let sortedInfo = this.state.sortedInfo;
		const { buyerUsers, disableBuyerUser, reinviteBuyerUser, currentUser } = this.props;
		const { invitedUsers } = this.state;
		const buyerUsersRecords = getObjectValues(buyerUsers.records).map((el) => ({
			...el,
			key: el.contact.email,
		}));
		filteredInfo = filteredInfo || {};
		sortedInfo = sortedInfo || {};

		const columns = [
			{
				title: 'Name',
				dataIndex: ['contact', 'nameGiven'],
				render: (text, record) => (
					<div>
						<ContactAvatar userType="buyer" contact={record.contact} />
						<span style={{ marginLeft: 16 }}>
							{record.contact.nameGiven} {record.contact.nameFamily}
						</span>
					</div>
				),
			},
			{
				title: 'Email',
				dataIndex: ['contact', 'email'],
			},
			{
				title: 'Roles',
				dataIndex: 'role',
				render: (text, record) => (
					<div>{record.role.map((r) => ROLES[r].displayName).join(', ')}</div>
				),
			},
			{
				title: 'Account Status',
				dataIndex: ['account', 'isActive'],
				render: (text, record) => statusIndicatorFormatter(text),
			},
			{
				title: '',
				dataIndex: ['account', 'userName'],
				render: (text, record) =>
					record.account.userName === currentUser.email ? null : record.account.isActive ? (
						<Row style={{ display: 'flex', gap: 8, width: '100%', justifyContent: 'start' }}>
							{(!record.isSSOUser && (
								<Button
									loading={buyerUsers.loadingUsers.has(record.account.userName)}
									onClick={this.showChangePasswordModal(record)}
								>
									Change Password
								</Button>
							)) ||
								null}
							<Button
								loading={buyerUsers.loadingUsers.has(record.account.userName)}
								onClick={this.showRoleUpdateModal(record)}
							>
								Edit
							</Button>
							<Button
								danger
								loading={buyerUsers.loadingUsers.has(record.account.userName)}
								onClick={() => disableBuyerUser(record.account.userName)}
							>
								Disable
							</Button>
						</Row>
					) : invitedUsers.has(record.account.userName) ? (
						<Row style={{ display: 'flex', gap: 8, width: '100%', justifyContent: 'start' }}>
							{(!record.isSSOUser && (
								<Button
									loading={buyerUsers.loadingUsers.has(record.account.userName)}
									onClick={this.showChangePasswordModal(record)}
								>
									Change Password
								</Button>
							)) ||
								null}
							<Button
								loading={buyerUsers.loadingUsers.has(record.account.userName)}
								onClick={this.showRoleUpdateModal(record)}
							>
								Edit
							</Button>
							<Button disabled={true}>Invite sent</Button>
						</Row>
					) : (
						<Row style={{ display: 'flex', gap: 8, width: '100%', justifyContent: 'start' }}>
							{(!record.isSSOUser && (
								<Button
									loading={buyerUsers.loadingUsers.has(record.account.userName)}
									onClick={this.showChangePasswordModal(record)}
								>
									Change Password
								</Button>
							)) ||
								null}
							<Button
								loading={buyerUsers.loadingUsers.has(record.account.userName)}
								onClick={this.showRoleUpdateModal(record)}
							>
								Edit
							</Button>
							<Button
								loading={buyerUsers.loadingUsers.has(record.account.userName)}
								onClick={() =>
									reinviteBuyerUser(record.account.userName).then((userName) => {
										let newInvitedUsers = new Set(invitedUsers);
										newInvitedUsers.add(userName);
										this.setState({ invitedUsers: newInvitedUsers });
									})
								}
							>
								Reactivate
							</Button>
						</Row>
					),
			},
		];

		return (
			<Content className="adminUsersPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited buyer admin users page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				{this.state.visible ? (
					<BuyerNewUserInviteForm
						wrappedComponentRef={this.saveFormRef('userInviteForm')}
						visible={this.state.visible}
						onCancel={this.handleBuyerUserInviteCancel}
						onSubmit={this.handleBuyerUserInviteSubmit}
					/>
				) : null}
				{this.state.roleUpdateVisible ? (
					<BuyerAdminRoleUpdateForm
						wrappedComponentRef={this.saveFormRef('roleUpdateForm')}
						visible={this.state.roleUpdateVisible}
						formData={this.state.userContact}
						onCancel={this.handleBuyerRoleUpdateCancel}
						onSubmit={this.handleBuyerRoleUpdateSubmit}
					/>
				) : null}

				{this.state.changePasswordVisible ? (
					<PasswordUpdateForm
						wrappedComponentRef={this.saveFormRef('changePasswordForm')}
						visible={this.state.changePasswordVisible}
						formData={this.state.userContact}
						onCancel={this.handleChangePasswordCancel}
						onSubmit={this.handleChangePasswordSubmit}
					/>
				) : null}

				<Row style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<SubnavBar
							left={
								<div>
									<Input.Search
										placeholder="Filter by name or email"
										style={{ width: 240 }}
										onChange={this.handleSearchDebounced}
									/>
									<Radio.Group
										onChange={this.switchDisplayMode}
										value={this.state.displayMode}
										style={{ marginLeft: 16, marginTop: 4 }}
									>
										<Radio.Button value="active">Active</Radio.Button>
										<Radio.Button value="all">All</Radio.Button>
									</Radio.Group>
								</div>
							}
							right={
								<div>
									<Button
										style={{ marginLeft: 16 }}
										type="primary"
										size="large"
										onClick={this.showInviteUserModal}
									>
										Invite New User
									</Button>
								</div>
							}
						/>
					</Col>
				</Row>
				<Row style={{ margin: '0.5em 0' }}>
					<Col span={24}>
						<Card bodyStyle={{ padding: 8 }}>
							<Table
								columns={columns}
								dataSource={buyerUsersRecords}
								loading={buyerUsers.loading}
								pagination={this.initialPagination}
							/>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	buyerUsers: state.buyer_users,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	fetchBuyerUsers: () => dispatch(fetchFacilityUsers('buyer')()),
	fetchBuyerActiveUsers: () => dispatch(fetchFacilityActiveUsers('buyer')()),
	fetchBuyerUsersWithFilter: (filter) => dispatch(fetchFacilityUsers('buyer', filter)()),
	fetchBuyerActiveUsersWithFilter: (filter) =>
		dispatch(fetchFacilityActiveUsers('buyer', filter)()),
	disableBuyerUser: (userName) => dispatch(disableUser('buyer')(userName)),
	inviteNewUser: (contact) => dispatch(buyerSignUpInvite(contact)),
	roleUpdateUser: (entity) => dispatch(buyerRoleUpdateUser(entity)),
	updateUserContact: (entity) => dispatch(buyerUpdateUserContact(entity)),
	reinviteBuyerUser: (userName) => dispatch(reinviteUser('buyer')(userName)),
	refreshCurrentUser: () => dispatch(checkUserTypeToken(ROLE_TYPES.BUYER)()),
	adminChangePassword: (entity) => dispatch(adminChangePassword(entity)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BuyerAdminUsersPage));

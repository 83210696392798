export const FETCH_NEW_WORK_ORDERS_TECH_KPI_BY_MONTH_START =
	'FETCH_NEW_WORK_ORDERS_TECH_KPI_BY_MONTH_START';
export const FETCH_NEW_WORK_ORDERS_TECH_KPI_BY_MONTH_SUCCESS =
	'FETCH_NEW_WORK_ORDERS_TECH_KPI_BY_MONTH_SUCCESS';
export const FETCH_NEW_WORK_ORDERS_TECH_KPI_BY_MONTH_FAILURE =
	'FETCH_NEW_WORK_ORDERS_TECH_KPI_BY_MONTH_FAILURE';

export const FETCH_COMPLETED_WORK_ORDERS_TECH_KPI_BY_MONTH_START =
	'FETCH_COMPLETED_WORK_ORDERS_TECH_KPI_BY_MONTH_START';
export const FETCH_COMPLETED_WORK_ORDERS_TECH_KPI_BY_MONTH_SUCCESS =
	'FETCH_COMPLETED_WORK_ORDERS_TECH_KPI_BY_MONTH_SUCCESS';
export const FETCH_COMPLETED_WORK_ORDERS_TECH_KPI_BY_MONTH_FAILURE =
	'FETCH_COMPLETED_WORK_ORDERS_TECH_KPI_BY_MONTH_FAILURE';

export const FETCH_STALE_WORK_ORDERS_TECH_KPI_BY_MONTH_START =
	'FETCH_STALE_WORK_ORDERS_TECH_KPI_BY_MONTH_START';
export const FETCH_STALE_WORK_ORDERS_TECH_KPI_BY_MONTH_SUCCESS =
	'FETCH_STALE_WORK_ORDERS_TECH_KPI_BY_MONTH_SUCCESS';
export const FETCH_STALE_WORK_ORDERS_TECH_KPI_BY_MONTH_FAILURE =
	'FETCH_STALE_WORK_ORDERS_TECH_KPI_BY_MONTH_FAILURE';

export const FETCH_PLANNED_MAINTENANCE_RATE_TECH_KPI_BY_MONTH_START =
	'FETCH_PLANNED_MAINTENANCE_RATE_TECH_KPI_BY_MONTH_START';
export const FETCH_PLANNED_MAINTENANCE_RATE_TECH_KPI_BY_MONTH_SUCCESS =
	'FETCH_PLANNED_MAINTENANCE_RATE_TECH_KPI_BY_MONTH_SUCCESS';
export const FETCH_PLANNED_MAINTENANCE_RATE_TECH_KPI_BY_MONTH_FAILURE =
	'FETCH_PLANNED_MAINTENANCE_RATE_TECH_KPI_BY_MONTH_FAILURE';

export const FETCH_CORRECTIVE_MAINTENANCE_RATE_TECH_KPI_BY_MONTH_START =
	'FETCH_CORRECTIVE_MAINTENANCE_RATE_TECH_KPI_BY_MONTH_START';
export const FETCH_CORRECTIVE_MAINTENANCE_RATE_TECH_KPI_BY_MONTH_SUCCESS =
	'FETCH_CORRECTIVE_MAINTENANCE_RATE_TECH_KPI_BY_MONTH_SUCCESS';
export const FETCH_CORRECTIVE_MAINTENANCE_RATE_TECH_KPI_BY_MONTH_FAILURE =
	'FETCH_CORRECTIVE_MAINTENANCE_RATE_TECH_KPI_BY_MONTH_FAILURE';

export const FETCH_CHECK_IN_RATE_TECH_KPI_BY_MONTH_START =
	'FETCH_CHECK_IN_RATE_TECH_KPI_BY_MONTH_START';
export const FETCH_CHECK_IN_RATE_TECH_KPI_BY_MONTH_SUCCESS =
	'FETCH_CHECK_IN_RATE_TECH_KPI_BY_MONTH_SUCCESS';
export const FETCH_CHECK_IN_RATE_TECH_KPI_BY_MONTH_FAILURE =
	'FETCH_CHECK_IN_RATE_TECH_KPI_BY_MONTH_FAILURE';

export const FETCH_FIRST_TIME_RESOLUTION_TECH_KPI_BY_MONTH_START =
	'FETCH_FIRST_TIME_RESOLUTION_TECH_KPI_BY_MONTH_START';
export const FETCH_FIRST_TIME_RESOLUTION_TECH_KPI_BY_MONTH_SUCCESS =
	'FETCH_FIRST_TIME_RESOLUTION_TECH_KPI_BY_MONTH_SUCCESS';
export const FETCH_FIRST_TIME_RESOLUTION_TECH_KPI_BY_MONTH_FAILURE =
	'FETCH_FIRST_TIME_RESOLUTION_TECH_KPI_BY_MONTH_FAILURE';

export const FETCH_ASSET_DOWNTIME_TECH_KPI_BY_MONTH_START =
	'FETCH_ASSET_DOWNTIME_TECH_KPI_BY_MONTH_START';
export const FETCH_ASSET_DOWNTIME_TECH_KPI_BY_MONTH_SUCCESS =
	'FETCH_ASSET_DOWNTIME_TECH_KPI_BY_MONTH_SUCCESS';
export const FETCH_ASSET_DOWNTIME_TECH_KPI_BY_MONTH_FAILURE =
	'FETCH_ASSET_DOWNTIME_TECH_KPI_BY_MONTH_FAILURE';

export const FETCH_RESPONSE_TIME_TECH_KPI_BY_MONTH_START =
	'FETCH_RESPONSE_TIME_TECH_KPI_BY_MONTH_START';
export const FETCH_RESPONSE_TIME_TECH_KPI_BY_MONTH_SUCCESS =
	'FETCH_RESPONSE_TIME_TECH_KPI_BY_MONTH_SUCCESS';
export const FETCH_RESPONSE_TIME_TECH_KPI_BY_MONTH_FAILURE =
	'FETCH_RESPONSE_TIME_TECH_KPI_BY_MONTH_FAILURE';

export const FETCH_COMPLETION_TIME_TECH_KPI_BY_MONTH_START =
	'FETCH_COMPLETION_TIME_TECH_KPI_BY_MONTH_START';
export const FETCH_COMPLETION_TIME_TECH_KPI_BY_MONTH_SUCCESS =
	'FETCH_COMPLETION_TIME_TECH_KPI_BY_MONTH_SUCCESS';
export const FETCH_COMPLETION_TIME_TECH_KPI_BY_MONTH_FAILURE =
	'FETCH_COMPLETION_TIME_TECH_KPI_BY_MONTH_FAILURE';

export const FETCH_ALL_MONTHLY_TECH_KPIS_START = 'FETCH_ALL_MONTHLY_TECH_KPIS_START';
export const FETCH_ALL_MONTHLY_TECH_KPIS_SUCCESS = 'FETCH_ALL_MONTHLY_TECH_KPIS_SUCCESS';
export const FETCH_ALL_MONTHLY_TECH_KPIS_FAILURE = 'FETCH_ALL_MONTHLY_TECH_KPIS_FAILURE';

export const FETCH_NEW_WORK_ORDERS_TECH_KPI_TOTAL_START =
	'FETCH_NEW_WORK_ORDERS_TECH_KPI_TOTAL_START';
export const FETCH_NEW_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS =
	'FETCH_NEW_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS';
export const FETCH_NEW_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE =
	'FETCH_NEW_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE';

export const FETCH_NEW_CREATED_BY_TECH_WORK_ORDERS_TECH_KPI_TOTAL_START =
	'FETCH_NEW_CREATED_BY_TECH_WORK_ORDERS_TECH_KPI_TOTAL_START';
export const FETCH_NEW_CREATED_BY_TECH_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS =
	'FETCH_NEW_CREATED_BY_TECH_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS';
export const FETCH_NEW_CREATED_BY_TECH_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE =
	'FETCH_NEW_CREATED_BY_TECH_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE';

export const FETCH_PM_WORK_ORDERS_TECH_KPI_TOTAL_START =
	'FETCH_PM_WORK_ORDERS_TECH_KPI_TOTAL_START';
export const FETCH_PM_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS =
	'FETCH_PM_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS';
export const FETCH_PM_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE =
	'FETCH_PM_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE';

export const FETCH_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_START =
	'FETCH_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_START';
export const FETCH_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS =
	'FETCH_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS';
export const FETCH_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE =
	'FETCH_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE';

export const FETCH_PM_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_START =
	'FETCH_PM_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_START';
export const FETCH_PM_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS =
	'FETCH_PM_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS';
export const FETCH_PM_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE =
	'FETCH_PM_sCOMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE';

export const FETCH_STALE_WORK_ORDERS_TECH_KPI_TOTAL_START =
	'FETCH_STALE_WORK_ORDERS_TECH_KPI_TOTAL_START';
export const FETCH_STALE_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS =
	'FETCH_STALE_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS';
export const FETCH_STALE_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE =
	'FETCH_STALE_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE';

export const FETCH_PLANNED_MAINTENANCE_RATE_TECH_KPI_TOTAL_START =
	'FETCH_PLANNED_MAINTENANCE_RATE_TECH_KPI_TOTAL_START';
export const FETCH_PLANNED_MAINTENANCE_RATE_TECH_KPI_TOTAL_SUCCESS =
	'FETCH_PLANNED_MAINTENANCE_RATE_TECH_KPI_TOTAL_SUCCESS';
export const FETCH_PLANNED_MAINTENANCE_RATE_TECH_KPI_TOTAL_FAILURE =
	'FETCH_PLANNED_MAINTENANCE_RATE_TECH_KPI_TOTAL_FAILURE';

export const FETCH_CORRECTIVE_MAINTENANCE_RATE_TECH_KPI_TOTAL_START =
	'FETCH_CORRECTIVE_MAINTENANCE_RATE_TECH_KPI_TOTAL_START';
export const FETCH_CORRECTIVE_MAINTENANCE_RATE_TECH_KPI_TOTAL_SUCCESS =
	'FETCH_CORRECTIVE_MAINTENANCE_RATE_TECH_KPI_TOTAL_SUCCESS';
export const FETCH_CORRECTIVE_MAINTENANCE_RATE_TECH_KPI_TOTAL_FAILURE =
	'FETCH_CORRECTIVE_MAINTENANCE_RATE_TECH_KPI_TOTAL_FAILURE';

export const FETCH_CHECK_IN_RATE_TECH_KPI_TOTAL_START = 'FETCH_CHECK_IN_RATE_TECH_KPI_TOTAL_START';
export const FETCH_CHECK_IN_RATE_TECH_KPI_TOTAL_SUCCESS =
	'FETCH_CHECK_IN_RATE_TECH_KPI_TOTAL_SUCCESS';
export const FETCH_CHECK_IN_RATE_TECH_KPI_TOTAL_FAILURE =
	'FETCH_CHECK_IN_RATE_TECH_KPI_TOTAL_FAILURE';

export const FETCH_FIRST_TIME_RESOLUTION_TECH_KPI_TOTAL_START =
	'FETCH_FIRST_TIME_RESOLUTION_TECH_KPI_TOTAL_START';
export const FETCH_FIRST_TIME_RESOLUTION_TECH_KPI_TOTAL_SUCCESS =
	'FETCH_FIRST_TIME_RESOLUTION_TECH_KPI_TOTAL_SUCCESS';
export const FETCH_FIRST_TIME_RESOLUTION_TECH_KPI_TOTAL_FAILURE =
	'FETCH_FIRST_TIME_RESOLUTION_TECH_KPI_TOTAL_FAILURE';

export const FETCH_ASSET_DOWNTIME_TECH_KPI_TOTAL_START =
	'FETCH_ASSET_DOWNTIME_TECH_KPI_TOTAL_START';
export const FETCH_ASSET_DOWNTIME_TECH_KPI_TOTAL_SUCCESS =
	'FETCH_ASSET_DOWNTIME_TECH_KPI_TOTAL_SUCCESS';
export const FETCH_ASSET_DOWNTIME_TECH_KPI_TOTAL_FAILURE =
	'FETCH_ASSET_DOWNTIME_TECH_KPI_TOTAL_FAILURE';

export const FETCH_RESPONSE_TIME_TECH_KPI_TOTAL_START = 'FETCH_RESPONSE_TIME_TECH_KPI_TOTAL_START';
export const FETCH_RESPONSE_TIME_TECH_KPI_TOTAL_SUCCESS =
	'FETCH_RESPONSE_TIME_TECH_KPI_TOTAL_SUCCESS';
export const FETCH_RESPONSE_TIME_TECH_KPI_TOTAL_FAILURE =
	'FETCH_RESPONSE_TIME_TECH_KPI_TOTAL_FAILURE';

export const FETCH_COMPLETION_TIME_TECH_KPI_TOTAL_START =
	'FETCH_COMPLETION_TIME_TECH_KPI_TOTAL_START';
export const FETCH_COMPLETION_TIME_TECH_KPI_TOTAL_SUCCESS =
	'FETCH_COMPLETION_TIME_TECH_KPI_TOTAL_SUCCESS';
export const FETCH_COMPLETION_TIME_TECH_KPI_TOTAL_FAILURE =
	'FETCH_COMPLETION_TIME_TECH_KPI_TOTAL_FAILURE';

export const FETCH_AVERAGE_SATISFACTION_RATING_TECH_KPI_TOTAL_START =
	'FETCH_AVERAGE_SATISFACTION_RATING_TECH_KPI_TOTAL_START';
export const FETCH_AVERAGE_SATISFACTION_RATING_TECH_KPI_TOTAL_SUCCESS =
	'FETCH_AVERAGE_SATISFACTION_RATING_TECH_KPI_TOTAL_SUCCESS';
export const FETCH_AVERAGE_SATISFACTION_RATING_TECH_KPI_TOTAL_FAILURE =
	'FETCH_AVERAGE_SATISFACTION_RATING_TECH_KPI_TOTAL_FAILURE';

export const FETCH_ALL_TOTAL_TECH_KPIS_START = 'FETCH_ALL_TOTAL_TECH_KPIS_START';
export const FETCH_ALL_TOTAL_TECH_KPIS_SUCCESS = 'FETCH_ALL_TOTAL_TECH_KPIS_SUCCESS';
export const FETCH_ALL_TOTAL_TECH_KPIS_FAILURE = 'FETCH_ALL_TOTAL_TECH_KPIS_FAILURE';

export const UPDATE_TECH_KPI_FILTERS = 'UPDATE_TECH_KPI_FILTERS';
export const CLEAR_TECH_KPI_FILTERS = 'CLEAR_TECH_KPI_FILTERS';

export function fetchNewWorkOrdersTechKPIByMonthStart() {
	return {
		type: FETCH_NEW_WORK_ORDERS_TECH_KPI_BY_MONTH_START,
	};
}

export function fetchNewWorkOrdersTechKPIByMonthSuccess(data) {
	return {
		type: FETCH_NEW_WORK_ORDERS_TECH_KPI_BY_MONTH_SUCCESS,
		data,
	};
}

export function fetchNewWorkOrdersTechKPIByMonthFailure(errors) {
	return {
		type: FETCH_NEW_WORK_ORDERS_TECH_KPI_BY_MONTH_FAILURE,
		errors,
	};
}

export function fetchCompletedWorkOrdersTechKPIByMonthStart() {
	return {
		type: FETCH_COMPLETED_WORK_ORDERS_TECH_KPI_BY_MONTH_START,
	};
}

export function fetchCompletedWorkOrdersTechKPIByMonthSuccess(data) {
	return {
		type: FETCH_COMPLETED_WORK_ORDERS_TECH_KPI_BY_MONTH_SUCCESS,
		data,
	};
}

export function fetchCompletedWorkOrdersTechKPIByMonthFailure(errors) {
	return {
		type: FETCH_COMPLETED_WORK_ORDERS_TECH_KPI_BY_MONTH_FAILURE,
		errors,
	};
}

export function fetchStaleWorkOrdersTechKPIByMonthStart() {
	return {
		type: FETCH_STALE_WORK_ORDERS_TECH_KPI_BY_MONTH_START,
	};
}

export function fetchStaleWorkOrdersTechKPIByMonthSuccess(data) {
	return {
		type: FETCH_STALE_WORK_ORDERS_TECH_KPI_BY_MONTH_SUCCESS,
		data,
	};
}

export function fetchStaleWorkOrdersTechKPIByMonthFailure(errors) {
	return {
		type: FETCH_STALE_WORK_ORDERS_TECH_KPI_BY_MONTH_FAILURE,
		errors,
	};
}

export function fetchPlannedMaintenanceRateTechKPIByMonthStart() {
	return {
		type: FETCH_PLANNED_MAINTENANCE_RATE_TECH_KPI_BY_MONTH_START,
	};
}

export function fetchPlannedMaintenanceRateTechKPIByMonthSuccess(data) {
	return {
		type: FETCH_PLANNED_MAINTENANCE_RATE_TECH_KPI_BY_MONTH_SUCCESS,
		data,
	};
}

export function fetchPlannedMaintenanceRateTechKPIByMonthFailure(errors) {
	return {
		type: FETCH_PLANNED_MAINTENANCE_RATE_TECH_KPI_BY_MONTH_FAILURE,
		errors,
	};
}

export function fetchCorrectiveMaintenanceRateTechKPIByMonthStart() {
	return {
		type: FETCH_CORRECTIVE_MAINTENANCE_RATE_TECH_KPI_BY_MONTH_START,
	};
}

export function fetchCorrectiveMaintenanceRateTechKPIByMonthSuccess(data) {
	return {
		type: FETCH_CORRECTIVE_MAINTENANCE_RATE_TECH_KPI_BY_MONTH_SUCCESS,
		data,
	};
}

export function fetchCorrectiveMaintenanceRateTechKPIByMonthFailure(errors) {
	return {
		type: FETCH_CORRECTIVE_MAINTENANCE_RATE_TECH_KPI_BY_MONTH_FAILURE,
		errors,
	};
}

export function fetchCheckInRateTechKPIByMonthStart() {
	return {
		type: FETCH_CHECK_IN_RATE_TECH_KPI_BY_MONTH_START,
	};
}

export function fetchCheckInRateTechKPIByMonthSuccess(data) {
	return {
		type: FETCH_CHECK_IN_RATE_TECH_KPI_BY_MONTH_SUCCESS,
		data,
	};
}

export function fetchCheckInRateTechKPIByMonthFailure(errors) {
	return {
		type: FETCH_CHECK_IN_RATE_TECH_KPI_BY_MONTH_FAILURE,
		errors,
	};
}

export function fetchFirstTimeResolutionTechKPIByMonthStart() {
	return {
		type: FETCH_FIRST_TIME_RESOLUTION_TECH_KPI_BY_MONTH_START,
	};
}

export function fetchFirstTimeResolutionTechKPIByMonthSuccess(data) {
	return {
		type: FETCH_FIRST_TIME_RESOLUTION_TECH_KPI_BY_MONTH_SUCCESS,
		data,
	};
}

export function fetchFirstTimeResolutionTechKPIByMonthFailure(errors) {
	return {
		type: FETCH_FIRST_TIME_RESOLUTION_TECH_KPI_BY_MONTH_FAILURE,
		errors,
	};
}

export function fetchAssetDownTimeTechKPIByMonthStart() {
	return {
		type: FETCH_ASSET_DOWNTIME_TECH_KPI_BY_MONTH_START,
	};
}

export function fetchAssetDownTimeTechKPIByMonthSuccess(data) {
	return {
		type: FETCH_ASSET_DOWNTIME_TECH_KPI_BY_MONTH_SUCCESS,
		data,
	};
}

export function fetchAssetDownTimeTechKPIByMonthFailure(errors) {
	return {
		type: FETCH_ASSET_DOWNTIME_TECH_KPI_BY_MONTH_FAILURE,
		errors,
	};
}

export function fetchResponseTimeTechKPIByMonthStart() {
	return {
		type: FETCH_RESPONSE_TIME_TECH_KPI_BY_MONTH_START,
	};
}

export function fetchResponseTimeTechKPIByMonthSuccess(data) {
	return {
		type: FETCH_RESPONSE_TIME_TECH_KPI_BY_MONTH_SUCCESS,
		data,
	};
}

export function fetchResponseTimeTechKPIByMonthFailure(errors) {
	return {
		type: FETCH_RESPONSE_TIME_TECH_KPI_BY_MONTH_FAILURE,
		errors,
	};
}

export function fetchCompletionTimeTechKPIByMonthStart() {
	return {
		type: FETCH_COMPLETION_TIME_TECH_KPI_BY_MONTH_START,
	};
}

export function fetchCompletionTimeTechKPIByMonthSuccess(data) {
	return {
		type: FETCH_COMPLETION_TIME_TECH_KPI_BY_MONTH_SUCCESS,
		data,
	};
}

export function fetchCompletionTimeTechKPIByMonthFailure(errors) {
	return {
		type: FETCH_COMPLETION_TIME_TECH_KPI_BY_MONTH_FAILURE,
		errors,
	};
}

export function fetchAllMonthlyTechKpisStart() {
	return {
		type: FETCH_ALL_MONTHLY_TECH_KPIS_START,
	};
}

export function fetchAllMonthlyTechKpisSuccess(data) {
	return {
		type: FETCH_ALL_MONTHLY_TECH_KPIS_SUCCESS,
		data,
	};
}

export function fetchAllMonthlyTechKpisFailure(errors) {
	return {
		type: FETCH_ALL_MONTHLY_TECH_KPIS_FAILURE,
		errors,
	};
}

export function fetchNewWorkOrdersTechKPITotalStart() {
	return {
		type: FETCH_NEW_WORK_ORDERS_TECH_KPI_TOTAL_START,
	};
}

export function fetchNewWorkOrdersTechKPITotalSuccess(data) {
	return {
		type: FETCH_NEW_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS,
		data,
	};
}

export function fetchNewWorkOrdersTechKPITotalFailure(errors) {
	return {
		type: FETCH_NEW_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE,
		errors,
	};
}

export function fetchNewCreatedByTechWorkOrdersTechKPITotalStart() {
	return {
		type: FETCH_NEW_CREATED_BY_TECH_WORK_ORDERS_TECH_KPI_TOTAL_START,
	};
}

export function fetchNewCreatedByTechWorkOrdersTechKPITotalSuccess(data) {
	return {
		type: FETCH_NEW_CREATED_BY_TECH_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS,
		data,
	};
}

export function fetchNewCreatedByTechWorkOrdersTechKPITotalFailure(errors) {
	return {
		type: FETCH_NEW_CREATED_BY_TECH_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE,
		errors,
	};
}

export function fetchPMWorkOrdersTechKPITotalStart() {
	return {
		type: FETCH_PM_WORK_ORDERS_TECH_KPI_TOTAL_START,
	};
}

export function fetchPMWorkOrdersTechKPITotalSuccess(data) {
	return {
		type: FETCH_PM_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS,
		data,
	};
}

export function fetchPMWorkOrdersTechKPITotalFailure(errors) {
	return {
		type: FETCH_PM_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE,
		errors,
	};
}

export function fetchCompletedWorkOrdersTechKPITotalStart() {
	return {
		type: FETCH_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_START,
	};
}

export function fetchCompletedWorkOrdersTechKPITotalSuccess(data) {
	return {
		type: FETCH_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS,
		data,
	};
}

export function fetchCompletedWorkOrdersTechKPITotalFailure(errors) {
	return {
		type: FETCH_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE,
		errors,
	};
}

export function fetchPMCompletedWorkOrdersTechKPITotalStart() {
	return {
		type: FETCH_PM_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_START,
	};
}

export function fetchPMCompletedWorkOrdersTechKPITotalSuccess(data) {
	return {
		type: FETCH_PM_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS,
		data,
	};
}

export function fetchPMCompletedWorkOrdersTechKPITotalFailure(errors) {
	return {
		type: FETCH_PM_COMPLETED_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE,
		errors,
	};
}

export function fetchStaleWorkOrdersTechKPITotalStart() {
	return {
		type: FETCH_STALE_WORK_ORDERS_TECH_KPI_TOTAL_START,
	};
}

export function fetchStaleWorkOrdersTechKPITotalSuccess(data) {
	return {
		type: FETCH_STALE_WORK_ORDERS_TECH_KPI_TOTAL_SUCCESS,
		data,
	};
}

export function fetchStaleWorkOrdersTechKPITotalFailure(errors) {
	return {
		type: FETCH_STALE_WORK_ORDERS_TECH_KPI_TOTAL_FAILURE,
		errors,
	};
}

export function fetchPlannedMaintenanceRateTechKPITotalStart() {
	return {
		type: FETCH_PLANNED_MAINTENANCE_RATE_TECH_KPI_TOTAL_START,
	};
}

export function fetchPlannedMaintenanceRateTechKPITotalSuccess(data) {
	return {
		type: FETCH_PLANNED_MAINTENANCE_RATE_TECH_KPI_TOTAL_SUCCESS,
		data,
	};
}

export function fetchPlannedMaintenanceRateTechKPITotalFailure(errors) {
	return {
		type: FETCH_PLANNED_MAINTENANCE_RATE_TECH_KPI_TOTAL_FAILURE,
		errors,
	};
}

export function fetchCorrectiveMaintenanceRateTechKPITotalStart() {
	return {
		type: FETCH_CORRECTIVE_MAINTENANCE_RATE_TECH_KPI_TOTAL_START,
	};
}

export function fetchCorrectiveMaintenanceRateTechKPITotalSuccess(data) {
	return {
		type: FETCH_CORRECTIVE_MAINTENANCE_RATE_TECH_KPI_TOTAL_SUCCESS,
		data,
	};
}

export function fetchCorrectiveMaintenanceRateTechKPITotalFailure(errors) {
	return {
		type: FETCH_CORRECTIVE_MAINTENANCE_RATE_TECH_KPI_TOTAL_FAILURE,
		errors,
	};
}

export function fetchCheckInRateTechKPITotalStart() {
	return {
		type: FETCH_CHECK_IN_RATE_TECH_KPI_TOTAL_START,
	};
}

export function fetchCheckInRateTechKPITotalSuccess(data) {
	return {
		type: FETCH_CHECK_IN_RATE_TECH_KPI_TOTAL_SUCCESS,
		data,
	};
}

export function fetchCheckInRateTechKPITotalFailure(errors) {
	return {
		type: FETCH_CHECK_IN_RATE_TECH_KPI_TOTAL_FAILURE,
		errors,
	};
}

export function fetchFirstTimeResolutionTechKPITotalStart() {
	return {
		type: FETCH_FIRST_TIME_RESOLUTION_TECH_KPI_TOTAL_START,
	};
}

export function fetchFirstTimeResolutionTechKPITotalSuccess(data) {
	return {
		type: FETCH_FIRST_TIME_RESOLUTION_TECH_KPI_TOTAL_SUCCESS,
		data,
	};
}

export function fetchFirstTimeResolutionTechKPITotalFailure(errors) {
	return {
		type: FETCH_FIRST_TIME_RESOLUTION_TECH_KPI_TOTAL_FAILURE,
		errors,
	};
}

export function fetchAssetDownTimeTechKPITotalStart() {
	return {
		type: FETCH_ASSET_DOWNTIME_TECH_KPI_TOTAL_START,
	};
}

export function fetchAssetDownTimeTechKPITotalSuccess(data) {
	return {
		type: FETCH_ASSET_DOWNTIME_TECH_KPI_TOTAL_SUCCESS,
		data,
	};
}

export function fetchAssetDownTimeTechKPITotalFailure(errors) {
	return {
		type: FETCH_ASSET_DOWNTIME_TECH_KPI_TOTAL_FAILURE,
		errors,
	};
}

export function fetchResponseTimeTechKPITotalStart() {
	return {
		type: FETCH_RESPONSE_TIME_TECH_KPI_TOTAL_START,
	};
}

export function fetchResponseTimeTechKPITotalSuccess(data) {
	return {
		type: FETCH_RESPONSE_TIME_TECH_KPI_TOTAL_SUCCESS,
		data,
	};
}

export function fetchResponseTimeTechKPITotalFailure(errors) {
	return {
		type: FETCH_RESPONSE_TIME_TECH_KPI_TOTAL_FAILURE,
		errors,
	};
}

export function fetchCompletionTimeTechKPITotalStart() {
	return {
		type: FETCH_COMPLETION_TIME_TECH_KPI_TOTAL_START,
	};
}

export function fetchCompletionTimeTechKPITotalSuccess(data) {
	return {
		type: FETCH_COMPLETION_TIME_TECH_KPI_TOTAL_SUCCESS,
		data,
	};
}

export function fetchCompletionTimeTechKPITotalFailure(errors) {
	return {
		type: FETCH_COMPLETION_TIME_TECH_KPI_TOTAL_FAILURE,
		errors,
	};
}

export function fetchAverageSatisfactionRatingTechKPITotalStart() {
	return {
		type: FETCH_AVERAGE_SATISFACTION_RATING_TECH_KPI_TOTAL_START,
	};
}

export function fetchAverageSatisfactionRatingTechKPITotalSuccess(data) {
	return {
		type: FETCH_AVERAGE_SATISFACTION_RATING_TECH_KPI_TOTAL_SUCCESS,
		data,
	};
}

export function fetchAverageSatisfactionRatingTechKPITotalFailure(errors) {
	return {
		type: FETCH_AVERAGE_SATISFACTION_RATING_TECH_KPI_TOTAL_FAILURE,
		errors,
	};
}

export function fetchAllTotalTechKpisStart() {
	return {
		type: FETCH_ALL_TOTAL_TECH_KPIS_START,
	};
}

export function fetchAllTotalTechKpisSuccess(data) {
	return {
		type: FETCH_ALL_TOTAL_TECH_KPIS_SUCCESS,
		data,
	};
}

export function fetchAllTotalTechKpisFailure(errors) {
	return {
		type: FETCH_ALL_TOTAL_TECH_KPIS_FAILURE,
		errors,
	};
}

export function updateTechKPIFilters(filters) {
	return {
		type: UPDATE_TECH_KPI_FILTERS,
		filters,
	};
}

export function clearTechKPIFilters() {
	return {
		type: CLEAR_TECH_KPI_FILTERS,
	};
}

import { Alert, Button, Col, Form, InputNumber, Modal, Row, Select, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import React, { FC, useCallback } from 'react';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { connect } from 'react-redux';
import { equipmentTypesRestCrudThunksForSupplier } from '../../thunks/equipment_types_thunks';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import { equipmentTypesPerStockLocationRestCrudThunksForSupplier } from '../../thunks/equipment_types_per_stock_location_thunks';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { renderEquipmenTypeSelect } from '../purchase_order_form/ReplaceEquipmentLineItemModal';

const AddEquipmentLevelModal: FC<any> = ({
	onCancel,
	onSuccess,
	stockLocationId,
	creating,
	createErrors,
	updateErrors,
	equipmentTypes,
	fetchEquipmentTypes,
	fetchMultipleEquipments,
	createEquipment,
}): React.ReactElement => {
	const [form] = useForm();

	const handleAddEquipmentSubmit = useCallback(
		(values) => {
			const equipmentPerStockLocationId = {
				...values,
				stockLocationId: parseInt(stockLocationId),
			};

			createEquipment(equipmentPerStockLocationId).then(() => {
				onSuccess();
				message.success(`Added a new equipmentType.`);
			});
		},
		[createEquipment, onSuccess, stockLocationId]
	);

	return (
		<Modal
			visible
			width={600}
			title={'Add Equipment Level To Stock Location'}
			okText={'Add Equipment'}
			onCancel={onCancel}
			closable={false}
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button
					type="primary"
					size="large"
					style={{ marginLeft: '16px' }}
					key="submit"
					htmlType="submit"
					form="add-equipment-form"
					loading={creating}
				>
					Add Equipment
				</Button>,
			]}
		>
			<Form
				form={form}
				id="add-equipment-form"
				layout="vertical"
				requiredMark={false}
				onFinish={handleAddEquipmentSubmit}
			>
				{createErrors.length > 0 ? (
					<FormItem>
						<Alert message={createErrors.join(' ')} type="error" />
					</FormItem>
				) : null}
				{updateErrors.length > 0 ? (
					<FormItem>
						<Alert message={updateErrors.join(' ')} type="error" />
					</FormItem>
				) : null}
				<Form.Item name="equipment" hidden />
				<FormItem
					name="equipmentTypeId"
					label="Equipment Type"
					rules={[{ required: true, message: 'This field is required.' }]}
				>
					<OWAsyncSelect
						stateSlice={equipmentTypes}
						targetCollectionName="equipmentTypesAtStockLocationIndex"
						fetchMultiple={(ids, targetCollectionName) => {
							fetchMultipleEquipments(ids, targetCollectionName);
						}}
						fetchData={(
							name,
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						) => {
							fetchEquipmentTypes(
								{ search: name },
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							);
						}}
						renderRecord={renderEquipmenTypeSelect}
						sortBy={{ sort_by: 'name', order: 'ascend' }}
					/>
				</FormItem>
				<FormItem name="minQuantity" label="Min Quantity">
					<InputNumber style={{ width: '100%' }} />
				</FormItem>
				<FormItem name="maxQuantity" label="Max Quantity">
					<InputNumber style={{ width: '100%' }} />
				</FormItem>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	createErrors: state.equipment_types_per_stock_location.createErrors,
	updateErrors: state.equipment_types_per_stock_location.updateErrors,
	creating: state.equipment_types_per_stock_location.creating,
	updating: state.equipment_types_per_stock_location.updating,

	equipmentTypes: state.equipment_types,
});

const mapDispatchToProps = (dispatch) => ({
	fetchEquipment: (id) => dispatch(equipmentTypesRestCrudThunksForSupplier.readOne(id)),
	fetchEquipmentTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			equipmentTypesRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleEquipments: (ids, targetCollectionName) =>
		dispatch(equipmentTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchStockLocations: (params, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.read(params, targetCollectionName)),
	fetchMultipleStockLocations: (ids, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	createEquipment: (entity) =>
		dispatch(equipmentTypesPerStockLocationRestCrudThunksForSupplier.create(entity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEquipmentLevelModal);

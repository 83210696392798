import React, { FC, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import { ROLE_TYPES } from '../../utils/DataConstants';
import { BUYER_ONLY_NO_STORE_ASSOCIATE } from '../roles/roles';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { Button } from 'antd';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';

const WorkOrderCreateButton: FC<any> = ({
	currentUser,
	userType,
	location,
	backText,
}): React.ReactElement => {
	const BACK_TEXT = useMemo(() => backText || 'Back to Work Orders', [backText]);

	const getFinalLink = useCallback(
		(path) => getLinkWIthBackLinkParams(location, BACK_TEXT, path),
		[location, BACK_TEXT]
	);

	const buyerCreateButton = useMemo(
		() =>
			currentUser.roles &&
			currentUser.roles.some((role) => BUYER_ONLY_NO_STORE_ASSOCIATE.has(role)) ? (
				<Link to={getFinalLink('/buyer/workOrders/newWorkOrder')}>
					<Button type="primary" size="large">
						Create <span className="inline-block-visible-xl">&nbsp;Work Order</span>
					</Button>
				</Link>
			) : (
				<Link
					to={
						[2466].includes(nullSafeGet('facility.buyerCompanyId', currentUser))
							? getFinalLink('/buyer/workOrders/requestServiceForm')
							: getFinalLink('/buyer/workOrders/requestService')
					}
				>
					<Button type="primary" size="large">
						Request <span className="inline-block-visible-xl">&nbsp;Service</span>
					</Button>
				</Link>
			),
		[currentUser, getFinalLink]
	);

	const supplierCreateButton = useMemo(
		() => (
			<Link
				to={
					[276242].includes(nullSafeGet('facility.supplierCompanyId', currentUser))
						? getFinalLink('/supplier/workOrders/overview/new')
						: getFinalLink('/supplier/workOrders/newWorkOrder')
				}
			>
				<Button type="primary" size="large">
					Create <span className="inline-block-visible-xl">&nbsp;Work Order</span>
				</Button>
			</Link>
		),
		[currentUser, getFinalLink]
	);

	return userType === ROLE_TYPES.SUPPLIER ? supplierCreateButton : buyerCreateButton;
};

const mapStateToProps = (state, ownProps) => ({
	currentUser: state.session.currentUser,
	location: ownProps.location,
});

const WOCreateButton = withRouter(connect(mapStateToProps)(WorkOrderCreateButton));

export default connect(
	(state) => ({
		userType: (state as unknown as any).session.userType,
	}),
	() => ({})
)(WOCreateButton);

import * as React from 'react';
import { Layout, Row, Col, Card, Steps, Button, Divider, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import FileUploader from '../file_uploader/FileUploader';
import {
	markNotBiddingRFP,
	requestsForProposalRestCrudThunksForSupplier,
} from '../../thunks/requests_for_proposal_thunks';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { WorkOrderRowDisplay } from '../work_order_row_display/WorkOrderRowDisplay';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { proposalsRestCrudThunksForSupplier } from '../../thunks/proposals_thunks';
import { Link } from 'react-router-dom';
import { currencyFormatter } from '../../utils/DataFormatterUtils';
import { EmptyState } from '../empty_state/EmptyState';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { clearNavbarState, updateNavbarState } from '../../actions/navbar_actions';
import { generateBackLink } from '../../utils/HistoryUtils';

const { Content } = Layout;
const Step = Steps.Step;
const style = require('./SupplierRFPDetailDetailsPage.less');
const dateFormat = 'MM-DD-YYYY';

class SupplierRFPDetailDetailsPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			buttonLoading: false,
		};
	}
	componentDidMount() {
		const { getRequestForProposal, match, getProposals, updateNavbar, location } = this.props;
		const requestForProposalId = match.params.id;
		getRequestForProposal(requestForProposalId);
		getProposals({ requestForProposalId }, 'RFP_DETAIL_PROPOSALS');
		updateNavbar(
			'Back to Opportunities',
			generateBackLink('/supplier/opportunities/overview/all', location)
		);
	}
	refresh() {
		const { getRequestForProposal, match } = this.props;
		const requestForProposalId = match.params.id;
		getRequestForProposal(requestForProposalId);
	}

	handleTabChange = (key) => {
		const { history, match } = this.props;
		const requestForProposalId = parseInt(match.params.id, 10);
		history.push(`/supplier/requestsForProposal/detail/${requestForProposalId}/${key}`);
	};
	componentWillUnmount(): void {
		this.props.clearNavbar();
	}

	render() {
		const {
			requestForProposal,
			requestsForProposalFetching,
			proposalsFetching,
			currentUser,
			history,
			match,
			proposals,
			markNotBidding,
		} = this.props;
		const fetching = requestsForProposalFetching || proposalsFetching;
		const requestForProposalId = parseInt(match.params.id, 10);

		const stepStatusMapper = {
			draft: 0,
			active: 1,
			closed: 2,
			award: 3,
		};

		const rfpCurrentStep =
			requestsForProposalFetching || !requestForProposal.status
				? undefined
				: stepStatusMapper[requestForProposal.status];

		const associatedProposals = getRecordsForTargetCollection(proposals, 'RFP_DETAIL_PROPOSALS');
		const { bidderStatus } = requestForProposal;

		return fetching || !requestForProposal.id ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="rfpDetailDetailsPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited supplier rfp detail page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row
					style={{
						margin: '16px 4px 0',
					}}
					gutter={16}
				>
					<Col span={16}>
						<Row style={{ margin: '0.5em -8px' }} gutter={16}>
							<Col span={24}>
								<Card>
									<h5>
										{bidderStatus === 'notBidding'
											? `You are not participating, would you like to bid this work?`
											: `Are you planning to bid on this work?`}
									</h5>
									<div className={'mt-2'}>
										<Link
											to={`/supplier/opportunities/detail/${requestForProposalId}/newProposal?workOrderId=${requestForProposal.workOrder.id}`}
										>
											<Button loading={this.state.buttonLoading} type="primary">
												Yes, I plan to bid
											</Button>
										</Link>
										{bidderStatus === 'notBidding' ? (
											''
										) : (
											<Button
												loading={this.state.buttonLoading}
												className={'ml-4'}
												onClick={() => {
													this.setState({ buttonLoading: true });
													markNotBidding(requestForProposal)
														.then(() => {
															this.refresh();
														})
														.catch((err) => {
															message.error(err);
														})
														.finally(() => {
															this.setState({ buttonLoading: false });
														});
												}}
											>
												No, I don't want this work
											</Button>
										)}
									</div>
								</Card>
							</Col>
						</Row>
						{requestForProposal.description && requestForProposal.description !== '<p><br></p>' ? (
							<Row style={{ margin: '0.5em -8px' }} gutter={16}>
								<Col span={24}>
									<Card title="About the RFP">
										<div
											style={{ fontSize: 16, marginBottom: 24 }}
											className="richTextWrapper"
											dangerouslySetInnerHTML={{ __html: requestForProposal.description }}
										/>
									</Card>
								</Col>
							</Row>
						) : null}
						{associatedProposals && associatedProposals.length > 0 ? (
							<div>
								{associatedProposals.map((proposal) => (
									<div key={proposal.id} className="rowSpacing">
										<Card>
											<div
												style={{
													marginBottom: 24,
													display: 'flex',
													justifyContent: 'space-between',
													alignItems: 'center',
												}}
											>
												<h5 style={{ marginBottom: 0 }}>Your Proposal</h5>
												<Button
													onClick={() => {
														history.push(
															`/supplier/opportunities/editProposal/${requestForProposalId}?proposalId=${proposal.id}`
														);
													}}
												>
													Edit
												</Button>
											</div>
											<div className="sectionHeaderWrapper" style={{ marginBottom: 8 }}>
												<span className="sectionHeaderText">Labor</span>
												<span className="sectionHeaderText2">
													{currencyFormatter(0)(proposal.laborTotalBeforeTax)}
												</span>
											</div>
											<Divider />
											<div className="sectionHeaderWrapper" style={{ marginBottom: 8 }}>
												<span className="sectionHeaderText">Materials</span>
												<span className="sectionHeaderText2">
													{currencyFormatter(0)(proposal.materialTotalBeforeTax)}
												</span>
											</div>
											<Divider />
											<div className="sectionHeaderWrapper">
												<span className="sectionHeaderText">Travel</span>
												<span className="sectionHeaderText2">
													{currencyFormatter(0)(parseFloat(proposal.travelTotalBeforeTax) || 0)}
												</span>
											</div>
											<Divider />
											<div className="sectionHeaderWrapper">
												<span className="sectionHeaderText">Freight</span>
												<span className="sectionHeaderText2">
													{currencyFormatter(0)(parseFloat(proposal.freightTotalBeforeTax) || 0)}
												</span>
											</div>
											<Divider />
											<div className="sectionHeaderWrapper">
												<span className="sectionHeaderText">Miscellaneous</span>
												<span className="sectionHeaderText2">
													{currencyFormatter(0)(parseFloat(proposal.miscTotalBeforeTax) || 0)}
												</span>
											</div>
											<Divider />
											<div className="sectionHeaderWrapper">
												<span className="sectionHeaderText">Total Before Tax</span>
												<span className="sectionHeaderText2">
													{currencyFormatter(0)(proposal.totalBeforeTax)}
												</span>
											</div>
											<Divider />
											<div className="sectionHeaderWrapper">
												<span className="sectionHeaderText">Tax</span>
												<span className="sectionHeaderText2">
													{currencyFormatter(0)(proposal.tax)}
												</span>
											</div>
											<Divider />
											<div className="sectionHeaderWrapper">
												<span className="sectionHeaderText" style={{ fontWeight: 600 }}>
													Total After Tax
												</span>
												<span className="sectionHeaderText2" style={{ fontWeight: 600 }}>
													{currencyFormatter(0)(proposal.totalAfterTax)}
												</span>
											</div>
										</Card>
									</div>
								))}
							</div>
						) : (
							<Row style={{ margin: '0.5em -8px' }} gutter={16}>
								<Col span={24}>
									<Card>
										<EmptyState
											graphic={
												<img
													style={{ marginBottom: 8 }}
													src="https://s3.amazonaws.com/mock-data-assets/categories/images/bank terminal.svg"
												/>
											}
											headline={"You haven't submitted a proposal yet."}
											body={
												<div style={{ textAlign: 'center' }}>
													<div style={{ maxWidth: 440, marginBottom: 16 }}>
														Interested in this opportunity?
													</div>
													<Link
														to={`/supplier/opportunities/detail/${requestForProposalId}/newProposal?workOrderId=${requestForProposal.workOrder.id}`}
														style={{ marginRight: 16 }}
													>
														<Button type="primary">Create a proposal</Button>
													</Link>
												</div>
											}
										/>
									</Card>
								</Col>
							</Row>
						)}
					</Col>

					<Col span={8}>
						<Row style={{ margin: '0.5em -8px' }} gutter={16}>
							<div
								onClick={() =>
									history.push(`/supplier/workOrders/detail/${requestForProposal.workOrder.id}`)
								}
							>
								<Card title="About the Problem">
									<WorkOrderRowDisplay workOrder={requestForProposal.workOrder} />
								</Card>
							</div>
						</Row>
						{requestForProposal.attachments && requestForProposal.attachments.length > 0 ? (
							<Row style={{ margin: '0.5em -8px' }}>
								<Col span={24}>
									<Card title="File Attachments">
										<FileUploader
											roleType="supplier"
											defaultFileList={requestForProposal.attachments || []}
											uploaderType={'readOnly'}
										/>
									</Card>
								</Col>
							</Row>
						) : null}
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	requestsForProposalFetching: state.requests_for_proposal.fetching,
	requestForProposal: state.requests_for_proposal.detail,
	proposals: state.proposals,
	proposalsFetching: state.proposals.fetching,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	markNotBidding: (entity) => dispatch(markNotBiddingRFP(ownProps.userType)(entity)),
	getRequestForProposal: (id) => dispatch(requestsForProposalRestCrudThunksForSupplier.readOne(id)),
	getProposals: (params, targetCollection) =>
		dispatch(proposalsRestCrudThunksForSupplier.read(params, targetCollection)),
	updateNavbar: (backlinkTitle, backlinkTo, navbarBackgroundColor) =>
		dispatch(updateNavbarState(backlinkTitle, backlinkTo, navbarBackgroundColor)),
	clearNavbar: () => dispatch(clearNavbarState()),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SupplierRFPDetailDetailsPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

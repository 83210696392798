import { INVOICES_CRUD_ACTION_CREATORS } from '../actions/invoices_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import FetchUtils, { parseJSON } from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { logoutSuccess, softError } from '../actions/session_actions';
import { message } from 'antd';
import { retrieveCachedUserDetails } from './session_thunks';
import { PROPOSALS_CRUD_ACTION_CREATORS } from '../actions/proposals_actions';

export const invoicesRestCrudThunksForBuyer = new RestCrudThunks(
	'invoices',
	'invoice',
	'invoice',
	'invoices',
	INVOICES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'invoice/'
);

export const invoicesRestCrudThunksForSupplier = new RestCrudThunks(
	'invoices',
	'invoice',
	'invoice',
	'invoices',
	INVOICES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'invoice/'
);

const fetchUtils = new FetchUtils();
const BASE_URL = getBackendUri();

const getBuyerInvoiceStatusUrl = (status, eventId) =>
	`/api/v1/buyer/invoice/invoices/${status}/${eventId}`;

export const createInvoiceByInternalTech = (entity) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/supplier/invoice/invoices/by_internal_tech`;
	return (dispatch) => {
		dispatch(INVOICES_CRUD_ACTION_CREATORS.createStart(entity));
		return new Promise((resolve, reject) => {
			fetchUtils
				.post(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: entity,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(INVOICES_CRUD_ACTION_CREATORS.createError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(INVOICES_CRUD_ACTION_CREATORS.createSuccess(entity));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(INVOICES_CRUD_ACTION_CREATORS.createError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

export const invoiceStatusChangeForBuyer = (status, entity) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(INVOICES_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.patch(BASE_URL + getBuyerInvoiceStatusUrl(status, entity.id), {})
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(INVOICES_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(INVOICES_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(INVOICES_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

export const fetchTaxLineItemsForBuyer = (id) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/buyer/invoice/tax_line_items/CAD`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const fetchTaxLineItemsForSupplier = (id) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/supplier/invoice/tax_line_items/CAD`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const downloadInvoicePDFForSupplier = (id) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/supplier/invoice/invoices/${id}/generate_pdf`;
	return fetchUtils
		.fileRequest(baseUrl + apiUrl, {})
		.then((response) => {
			const dataType = response.type;
			const binaryData = [];
			binaryData.push(response);
			const downloadLink = document.createElement('a');
			downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
			downloadLink.setAttribute('download', `invoice-${id}`);
			document.body.appendChild(downloadLink);
			downloadLink.click();
			downloadLink.remove();
		})
		.catch((err) => console.log(err));
};
export const generateInvoicePDFForSupplier = (id) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/supplier/invoice/invoices/${id}/generate_pdf`;
	return {
		url: baseUrl + apiUrl,
		httpHeaders: { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token },
	};
};

export const sendInvoiceByEmailForSupplier = (id, email, data) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/supplier/invoice/invoices/${id}/send_email/${email}`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.post(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const getAllInvoiceDocumentsForSupplier = (id) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/supplier/invoice/invoices/${id}/all_documents`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const getInvoiceCheckInImagesForSupplier = (id) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/supplier/invoice/invoices/${id}/checkin_images`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const getInvoiceCheckOutImagesForSupplier = (id) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/supplier/invoice/invoices/${id}/checkout_images`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const getInvoiceNumber = (id) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/supplier/invoice/invoices?invoiceNumber=${id}`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};
export const getInvoiceNumberBuyer = (id) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/buyer/invoice/invoices?invoiceNumber=${id}`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const updateInvoiceStatusBulk =
	(roleType) =>
	(ids, status, additionalParams = {}) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/invoice/status/action/bulk`;
		const entity = {
			ids: ids.map((id) => parseInt(id)),
			status: status,
			...additionalParams,
		};
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(INVOICES_CRUD_ACTION_CREATORS.updateStart(entity));
				fetchUtils
					.post(baseUrl + apiUrl, {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entity,
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (d.status === 'error') {
							dispatch(INVOICES_CRUD_ACTION_CREATORS.updateError(d.message, entity));
							reject('An error occurred in updating the selected invoices.' || d.message);
						} else {
							dispatch(INVOICES_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(INVOICES_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject('An error occurred in updating the selected invoices.' || d.message);
					});
			});
		};
	};

const updateInvoiceStatus =
	(invoiceStatus) =>
	(roleType) =>
	(entity, primaryKey = 'id') => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/invoice/status/${invoiceStatus}`;
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(INVOICES_CRUD_ACTION_CREATORS.createStart(entity));

				fetchUtils
					.post(baseUrl + apiUrl, {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entity,
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(INVOICES_CRUD_ACTION_CREATORS.createError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(INVOICES_CRUD_ACTION_CREATORS.createSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(INVOICES_CRUD_ACTION_CREATORS.createError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};

const approveInvoice = updateInvoiceStatus('approved');
const disputeInvoice = updateInvoiceStatus('disputed');
const markInvoicePaid = updateInvoiceStatus('paid');
const markInvoiceProcessing = updateInvoiceStatus('processing');
const markInvoicePending = updateInvoiceStatus('pending');
const markInvoiceDraft = updateInvoiceStatus('draft');
const publishInvoice = updateInvoiceStatus('pending');
const voidInvoice = updateInvoiceStatus('void');
export const approveInvoiceForBuyer = approveInvoice(ROLE_TYPES.BUYER);
export const approveInvoiceForSupplier = approveInvoice(ROLE_TYPES.SUPPLIER);
export const disputeInvoiceForBuyer = disputeInvoice(ROLE_TYPES.BUYER);
export const disputeInvoiceForSupplier = disputeInvoice(ROLE_TYPES.SUPPLIER);
export const markInvoiceProcessingForBuyer = markInvoiceProcessing(ROLE_TYPES.BUYER);
export const markInvoiceProcessingForSupplier = markInvoiceProcessing(ROLE_TYPES.SUPPLIER);
export const markInvoicePendingForBuyer = markInvoicePending(ROLE_TYPES.BUYER);
export const markInvoicePendingForSupplier = markInvoicePending(ROLE_TYPES.SUPPLIER);
export const markInvoiceDraftForSupplier = markInvoiceDraft(ROLE_TYPES.SUPPLIER);
export const markInvoicePaidForBuyer = markInvoicePaid(ROLE_TYPES.BUYER);
export const publishInvoiceForSupplier = publishInvoice(ROLE_TYPES.SUPPLIER);
export const markInvoicePaidForSupplier = markInvoicePaid(ROLE_TYPES.SUPPLIER);
export const voidInvoiceForSupplier = voidInvoice(ROLE_TYPES.SUPPLIER);

const updateInvoiceField =
	(field) =>
	(roleType) =>
	(entity, primaryKey = 'id') => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/invoice/update_field/${field}`;
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(INVOICES_CRUD_ACTION_CREATORS.updateStart(entity));

				fetchUtils
					.post(baseUrl + apiUrl, {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entity,
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(INVOICES_CRUD_ACTION_CREATORS.updateError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(INVOICES_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(INVOICES_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};

const updateInvoiceGLCode = updateInvoiceField('gl_code');
export const updateInvoiceGLCodeForBuyer = updateInvoiceGLCode(ROLE_TYPES.BUYER);

const updateInvoicePONumber = updateInvoiceField('po_number');
export const updateInvoicePONumberForBuyer = updateInvoicePONumber(ROLE_TYPES.BUYER);

export const createInvoiceBasedOnWorkOrder =
	(workOrderId) =>
	(entity, primaryKey = 'id') => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/supplier/invoice/invoices/work_order/${workOrderId}`;
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(INVOICES_CRUD_ACTION_CREATORS.createStart(entity));

				fetchUtils
					.post(baseUrl + apiUrl, {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entity,
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(INVOICES_CRUD_ACTION_CREATORS.createError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(INVOICES_CRUD_ACTION_CREATORS.createSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(INVOICES_CRUD_ACTION_CREATORS.createError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};

const createInvoiceForSupplierBasedOnWorkOrder = (userType) => (entity, workOrderId) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${userType}/invoice/invoices/work_order/${workOrderId}`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(INVOICES_CRUD_ACTION_CREATORS.createStart(entity));

			fetchUtils
				.post(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: entity,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(INVOICES_CRUD_ACTION_CREATORS.createError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(INVOICES_CRUD_ACTION_CREATORS.createSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(INVOICES_CRUD_ACTION_CREATORS.createError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

export const createInvoiceForSupplierBasedOnWorkOrderForBuyer =
	createInvoiceForSupplierBasedOnWorkOrder(ROLE_TYPES.BUYER);
export const createInvoiceForSupplierBasedOnWorkOrderForSupplier =
	createInvoiceForSupplierBasedOnWorkOrder(ROLE_TYPES.SUPPLIER);

const downloadInvoiceCSV =
	(roleType) =>
	(params, filters = null) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/invoice/invoices/csv_report_generator`;

		// if sorting present, fix order naming and add to query params

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.message);
							message.error(d.data);
						} else {
							resolve(d.data);
							const hide = message.loading(d.data, 0);
							setTimeout(hide, 7000);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};

export const downloadInvoiceCSVForBuyer = downloadInvoiceCSV(ROLE_TYPES.BUYER);
export const downloadInvoiceCSVForSupplier = downloadInvoiceCSV(ROLE_TYPES.SUPPLIER);

const downloadInvoiceFormatCSV =
	(roleType) =>
	(formatName, params, filters = null) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/invoice/invoices/csv_report_generator/${formatName}`;

		// if sorting present, fix order naming and add to query params

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.message);
							message.error(d.data);
						} else {
							resolve(d.data);
							const hide = message.loading(d.data, 0);
							setTimeout(hide, 7000);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};

export const downloadInvoiceFormatCSVForBuyer = downloadInvoiceFormatCSV(ROLE_TYPES.BUYER);
export const downloadInvoiceFormatCSVForSupplier = downloadInvoiceFormatCSV(ROLE_TYPES.SUPPLIER);

const invoiceDownloadFormat =
	(roleType) =>
	(params, filters = null) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/invoice/invoice_download_formats`;

		// if sorting present, fix order naming and add to query params

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.message);
							message.error(d.data);
						} else {
							resolve(d.data);
							const hide = message.loading(d.data, 0);
							setTimeout(hide, 7000);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};

export const invoiceDownloadFormatForBuyer = invoiceDownloadFormat(ROLE_TYPES.BUYER);
export const invoiceDownloadFormatForSupplier = invoiceDownloadFormat(ROLE_TYPES.SUPPLIER);

const getCapExCategories =
	(roleType) =>
	(params = null, filters = null) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/categories/capex`;

		// if sorting present, fix order naming and add to query params

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.message);
						} else {
							resolve(d.data);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};

export const fetchCapExCategoriesForBuyer = getCapExCategories(ROLE_TYPES.BUYER);
export const fetchCapExCategoriesForSupplier = getCapExCategories(ROLE_TYPES.SUPPLIER);

export const syncInvoiceApprovalHierarchy = (userType) => (entity) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(INVOICES_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.patch(
					BASE_URL + `/api/v1/${userType}/invoice/invoices/approval_hierarchy_update/${entity.id}`,
					{}
				)
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(INVOICES_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(INVOICES_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(INVOICES_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};
export const syncInvoiceApprovalHierarchyForBuyer = syncInvoiceApprovalHierarchy('buyer');
export const syncInvoiceApprovalHierarchyForSupplier = syncInvoiceApprovalHierarchy('supplier');

import { nullSafeGet } from './DataAccessUtils';
import { STOPLIGHT_COLORS } from './DataFormatterUtils';

export const assetInvoiceDetailsDisplayColor = (asset) => {
	const maintenanceTotal = nullSafeGet('invoicesTotal', asset);
	if (nullSafeGet('originalPrice', asset)) {
		const originalPrice = parseFloat(nullSafeGet('originalPrice', asset));
		if (originalPrice * 0.95 <= maintenanceTotal) {
			return STOPLIGHT_COLORS.pureRed;
		} else if (
			originalPrice * 0.75 <= maintenanceTotal &&
			originalPrice * 0.95 >= maintenanceTotal
		) {
			return STOPLIGHT_COLORS.pureYellow;
		} else {
			return STOPLIGHT_COLORS.pureGreen;
		}
	}

	return 'black';
};

export const getAssetCopy = (asset) => ({
	...asset,
	id: undefined,
	assetNumber: undefined,
	assetUUID: undefined,
	name: `Copy of ${asset.name}`,
	description: `Copy of ${asset.name} ${asset.description || ''}`,
});

import React, { FC, useCallback, useMemo, useState } from 'react';
import { Button, message, Modal } from 'antd';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createAssetFromEquipmentTypeBulkForSupplier } from '../../thunks/assets_thunks';
import BulkTransferFromStockLocationForm from './BulkTransferFromStockLocationForm';
import { bulkTransferEquipmentTypeFromStockLocationForSupplier } from '../../thunks/equipment_per_stock_locations_thunks';

const FORM_ID = 'transfer_equipment_types_form';

const BulkTransferEquipmentTypeModal: FC<any> = ({
	onCancel,
	stockLocationId,
	createAssetFromEquipmentTypeBulk,
	selectedEquipmentTypes,
	onSuccess,
	bulkTransferToStockLocation,
}): React.ReactElement => {
	const [loading, setLoading] = useState(false);

	const onTransferToSL = useCallback(
		(values) => {
			const toStockLocationId = values.toStockLocationId;
			const entities = selectedEquipmentTypes.map((et) => ({
				equipmentTypeId: et.equipmentTypeId,
				fromStockLocationId: parseInt(stockLocationId),
				toStockLocationId,
				...(values.transferNote && { transferNote: values.transferNote }),
			}));
			setLoading(true);
			bulkTransferToStockLocation(entities)
				.then((res) => onSuccess(res))
				.catch((err) => message.error(err))
				.finally(() => setLoading(false));
		},
		[bulkTransferToStockLocation, onSuccess, selectedEquipmentTypes, stockLocationId]
	);

	const onTransferToLocation = useCallback(
		(values) => {
			const locationId = values.locationId;
			const entities = selectedEquipmentTypes.map((et) => ({
				equipmentTypeId: et.equipmentTypeId,
				fromStockLocationId: parseInt(stockLocationId),
				locationId,
				...(values.transferNote && { transferNote: values.transferNote }),
			}));
			setLoading(true);
			createAssetFromEquipmentTypeBulk(entities)
				.then((res) => onSuccess(res))
				.catch((err) => message.error(err))
				.finally(() => setLoading(false));
		},
		[createAssetFromEquipmentTypeBulk, onSuccess, selectedEquipmentTypes, stockLocationId]
	);

	const isLocationEnabled = useMemo(
		() => selectedEquipmentTypes.every((_) => !!nullSafeGet('equipmentType.assetTypeId', _)),
		[selectedEquipmentTypes]
	);

	return (
		<Modal
			visible={true}
			width={600}
			title={`Transfer Equipment`}
			closable
			onCancel={onCancel}
			forceRender
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button
					type="primary"
					size="large"
					style={{ marginLeft: '16px' }}
					key="submit"
					htmlType="submit"
					form={FORM_ID}
					loading={loading}
				>
					Transfer Equipment
				</Button>,
			]}
		>
			<BulkTransferFromStockLocationForm
				FORM_ID={FORM_ID}
				onTransferToSL={onTransferToSL}
				onTransferToLocation={onTransferToLocation}
				stockLocationId={stockLocationId}
				isLocationEnabled={isLocationEnabled}
				equipmentTypeModelName={nullSafeGet('0.equipmentType.modelName', selectedEquipmentTypes)}
			/>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	saving: state.parts_per_stock_locations.updating || state.equipment_per_stock_locations.updating,
});

const mapDispatchToProps = (dispatch) => ({
	createAssetFromEquipmentTypeBulk: (entities) =>
		dispatch(createAssetFromEquipmentTypeBulkForSupplier(entities)),
	bulkTransferToStockLocation: (entities) =>
		dispatch(bulkTransferEquipmentTypeFromStockLocationForSupplier(entities)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(BulkTransferEquipmentTypeModal)
);

import { DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Popover } from 'antd';
import React from 'react';
import WorkOrderCreateButton from '../../common/WorkOrderCreateButton';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import BulkArchiveButton from '../../bulk_archive_button/BulkArchiveButton';

function RightActions({
	downloadCSV,
	switchDisplayMode,
	isDisplayModeActive,
	filters,
	counts,
	refreshTable,
}) {
	const dontShowExport = !downloadCSV;
	return (
		<>
			<BulkArchiveButton filters={filters} counts={counts} refreshTable={refreshTable} />
			<Popover
				content={
					dontShowExport
						? 'Due to large data volume, exporting is not feasible. Please contact support.'
						: null
				}
			>
				<Button
					size="large"
					icon={<DownloadOutlined translate="" />}
					className="inline-block-visible-md"
					onClick={downloadCSV}
					disabled={dontShowExport}
				>
					<span className="inline-block-visible-xxl">Export CSV</span>
				</Button>
			</Popover>
			{/*
			<Button.Group size="large" className="inline-block-visible-xxl">
				<Button onClick={() => switchDisplayMode('list')} disabled={isDisplayModeActive('list')}>
					<i className="icons8-font icons8-bulleted-list-2" />
				</Button>
				<Button onClick={() => switchDisplayMode('map')} disabled={isDisplayModeActive('map')}>
					<i className="icons8-font icons8-location-marker" />
				</Button>
			</Button.Group>
			*/}
			<WorkOrderCreateButton />
		</>
	);
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

const RightActionComp = withRouter(connect(mapStateToProps, mapDispatchToProps)(RightActions));

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	(dispatch, ownProps) => ({})
)(RightActionComp);

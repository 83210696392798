import { Row } from 'antd';
import { nullSafeGet, nullSafeGetOrElse } from '../../../utils/DataAccessUtils';
import React, { useCallback, useMemo } from 'react';
import {
	CLEAR_DISPLAY_STATUS_FILTER,
	CLEAR_STATUS_FILTER,
} from '../config/pipelineFilters/pipelineFilters';
import OneLineComponent from '../../common/OneLineComponent';

const style = require('./pipelineFilters.less');

interface PipelineProps {
	items: any;
	subItems: any;
	value: any;
	onChange: any;
	style: any;
}

export function PipelineFilter(props) {
	const { items, onChange, subItems, value, counts, mode } = props;

	const isMultipleMode = useMemo(() => mode === 'multiple', [mode]);

	const displayStatusFieldName = useMemo(
		() => (isMultipleMode ? 'displayStatuses' : 'displayStatus'),
		[isMultipleMode]
	);

	const statusFieldName = useMemo(() => (isMultipleMode ? 'statuses' : 'status'), [isMultipleMode]);

	const displayStatusValue = useMemo(
		() =>
			isMultipleMode
				? nullSafeGetOrElse(displayStatusFieldName, value, '')
						.split(',')
						.filter((_) => !!_)
				: nullSafeGetOrElse(displayStatusFieldName, value, undefined),
		[displayStatusFieldName, isMultipleMode, value]
	);

	const statusValue = useMemo(
		() =>
			isMultipleMode
				? nullSafeGetOrElse(statusFieldName, value, '')
						.split(',')
						.filter((_) => !!_)
				: nullSafeGetOrElse(statusFieldName, value, undefined),
		[statusFieldName, isMultipleMode, value]
	);

	const isFilterActive = useMemo(
		() => (isMultipleMode ? displayStatusValue.length > 0 : displayStatusValue !== undefined),
		[isMultipleMode, displayStatusValue]
	);

	const isActive = useCallback(
		(item, fieldValue) => {
			return (
				isFilterActive &&
				(isMultipleMode ? fieldValue.includes(item.name) : fieldValue === item.name)
			);
		},
		[isFilterActive, isMultipleMode]
	);

	const onValueChange = useCallback(
		(item) => () => {
			if (isMultipleMode) {
				const newVal = isActive(item, displayStatusValue)
					? displayStatusValue.filter((_) => _ !== item.name)
					: [...displayStatusValue, item.name];
				const updatedVal = newVal.length > 0 ? newVal.join(',') : undefined;
				onChange({
					[displayStatusFieldName]: updatedVal,
					[statusFieldName]: undefined,
				});
			} else {
				const newVal = isActive(item, displayStatusValue)
					? { ...CLEAR_STATUS_FILTER, ...CLEAR_DISPLAY_STATUS_FILTER }
					: { [displayStatusFieldName]: item.name };
				onChange(newVal);
			}
		},
		[
			statusFieldName,
			displayStatusFieldName,
			isMultipleMode,
			onChange,
			displayStatusValue,
			isActive,
		]
	);

	const onSubMenuValueChange = useCallback(
		(subItem) => {
			if (isMultipleMode) {
				const newVal = isActive(subItem, statusValue)
					? statusValue.filter((_) => _ !== subItem.name)
					: [...statusValue, subItem.name];
				const updatedVal = newVal.length > 0 ? newVal.join(',') : undefined;
				onChange({ [statusFieldName]: updatedVal });
			} else {
				const newVal = isActive
					? CLEAR_STATUS_FILTER
					: {
							status: subItem.filter.status,
					  };
				onChange({ [statusFieldName]: newVal });
			}
		},
		[isActive, isMultipleMode, statusFieldName, statusValue, onChange]
	);

	const subMenus = useMemo(
		() =>
			isMultipleMode
				? displayStatusValue.reduce((items, val) => {
						return subItems[val] ? [...items, ...subItems[val]] : [...items];
				  }, [])
				: subItems[displayStatusValue],
		[isMultipleMode, displayStatusValue, subItems]
	);

	const pipelines = items.map((item, i) => {
		let stepLinerClassName;
		if (i === 0) {
			stepLinerClassName = 'pipelineCounts__stepLiner--first';
		} else if (i === items.length - 1) {
			stepLinerClassName = 'pipelineCounts__stepLiner--last';
		} else {
			stepLinerClassName = 'pipelineCounts__stepLiner';
		}

		return (
			<div
				key={item.name}
				onClick={onValueChange(item)}
				className={`pipelineCounts__step cursor-pointer ${
					isActive(item, displayStatusValue) ? 'active' : ''
				}`}
			>
				<div
					className={`${stepLinerClassName} ${isActive(item, displayStatusValue) ? 'active' : ''}`}
				/>
				<div className="pipelineCounts__name">
					<span className="inline-block-visible-xl">
						{item.icon ? (
							<i
								style={{
									...item.iconStyle,
									marginRight: '8px',
									color: isActive(item, displayStatusValue)
										? 'white'
										: nullSafeGet('iconStyle.color', item),
								}}
								className={`icons8-font ${item.icon}`}
							/>
						) : null}
					</span>
					<span>{item.displayName}</span>
				</div>
				<div className="pipelineCounts__count">{counts[item.name]}</div>
			</div>
		);
	});

	let subFilters = [];
	if (isFilterActive) {
		subFilters = subMenus.map((subItem, i, currentSubItems) => {
			let stepLinerClassName;
			if (i === 0) {
				stepLinerClassName = 'pillFilters__stepLiner--first';
			} else if (i === currentSubItems.length - 1) {
				stepLinerClassName = 'pillFilters__stepLiner--last';
			} else {
				stepLinerClassName = 'pillFilters__stepLiner';
			}

			return (
				<div
					key={subItem.name}
					onClick={() => onSubMenuValueChange(subItem)}
					className={`pillFilters__step cursor-pointer ${
						isActive(subItem, statusValue) ? 'active' : ''
					}`}
				>
					<div className={`${stepLinerClassName} `} />
					<div className="pillFilters__name">
						<span className="inline-block-visible-xl">
							{subItem.icon ? (
								<i
									style={{ ...subItem.iconStyle, marginRight: '8px' }}
									className={`icons8-font ${subItem.icon}`}
								/>
							) : null}
						</span>
						<span>{subItem.displayName}</span>
					</div>
					<div className="pillFilters__count">{counts[subItem.name]}</div>
				</div>
			);
		});
	}

	return (
		<Row>
			<Row key={1}>{pipelines.map((pipe) => pipe)}</Row>
			<OneLineComponent
				childrenToRender={subFilters}
				childrenText={subMenus.map((subItem) => `${subItem.displayName}+++`)}
				fontSize={14}
				additionalWidth={60}
				style={{ marginTop: 6 }}
			/>
		</Row>
	);
}

import React, { FC, useCallback, useMemo } from 'react';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { TROUBLESHOOTING_CRUD_ACTION_CREATORS } from '../../actions/troubleshooting_actions';
import { fetchTroubleshootingHydratedForBuyer } from '../../thunks/troubleshooting_thunks';
import { Button } from 'antd';
import { EmptyState } from '../empty_state/EmptyState';
import { stopDefaultEvents } from '../../utils/DataAccessUtils';

const TC_NAME = 'troubleshootingIndex';

const TroubleshootingOverviewAllPage: FC<any> = ({
	updateFilters,
	troubleshooting,
	clearAndUpdateFilters,
	history,
}): React.ReactElement => {
	const onRow = useCallback(
		(record) => ({
			onClick: () => history.push(`/buyer/admin/troubleshooting/detail/${record.id}`),
		}),
		[history]
	);

	const onNewTroubleshooting = useCallback(() => {
		history.push(`/buyer/admin/troubleshooting/new`);
	}, [history]);

	const createNewButton = useMemo(
		() => (
			<Button style={{ marginLeft: 16 }} type="primary" size="large" onClick={onNewTroubleshooting}>
				New Troubleshooting
			</Button>
		),
		[onNewTroubleshooting]
	);

	const onEdit = useCallback(
		(id) => (e) => {
			stopDefaultEvents(e);
			history.push(`/buyer/admin/troubleshooting/edit/${id}`);
		},
		[history]
	);

	const columns = useMemo(
		() => [
			{
				title: 'Name',
				dataIndex: 'name',
				render: (_) => _ || '--',
			},
			{
				title: 'Location',
				dataIndex: ['location', 'name'],
				render: (_) => _ || '--',
			},
			{
				title: 'Problem Type',
				dataIndex: ['problemType', 'name'],
				render: (_) => _ || '--',
			},
			{
				title: 'Asset',
				dataIndex: ['asset', 'name'],
				render: (_) => _ || '--',
			},
			{
				title: 'Workorder Priority',
				dataIndex: ['workorderPriority', 'name'],
				render: (_) => _ || '--',
			},
			{
				dataIndex: 'id',
				render: (_) => (
					<Button type="ghost" onClick={onEdit(_)}>
						Edit
					</Button>
				),
			},
		],
		[]
	);

	return (
		<PaginatedReduxTableWithHeader
			targetCollectionName={TC_NAME}
			updateFilters={updateFilters}
			stateSlice={troubleshooting}
			clearAndUpdateFilters={clearAndUpdateFilters}
			filterConfig={[]}
			entityCollectionName="troubleshooting"
			tableColumns={columns}
			onTableRow={onRow}
			fetchData={fetchTroubleshootingHydratedForBuyer}
			rightActions={<div>{createNewButton}</div>}
			showHeader
			emptyState={
				<EmptyState
					graphic={
						<img
							alt="No Data"
							style={{ marginBottom: 8 }}
							src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
						/>
					}
					headline={'No troubleshooting found'}
					body={<div style={{ textAlign: 'center' }}>{createNewButton}</div>}
				/>
			}
		/>
	);
};

const mapStateToProps = (state) => ({
	troubleshooting: state.troubleshooting,
});

const mapDispatchToProps = (dispatch) => ({
	updateFilters: (filters, targetCollection) =>
		dispatch(TROUBLESHOOTING_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			TROUBLESHOOTING_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)
		),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(TroubleshootingOverviewAllPage)
);

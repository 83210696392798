import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { budgetsRestCrudThunksForBuyer } from '../../thunks/budget_thunks';
import BudgetForm from '../budget_form/BudgetForm';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';

const queryString = require('qs');

class NewBudgetPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			backLinkParams: '',
		};
	}

	render() {
		const { history, fetching } = this.props;

		//noinspection TypeScriptUnresolvedVariable
		return fetching ? (
			<PageLoadingPlaceholder />
		) : (
			[
				<LogOnMountWithStandardEventProperties eventType="visited buyer budget form" />,
				<EditPage
					title="Create Budget"
					formComponentProps={{
						formData: {},
						isEditForm: false,
						onSuccess: (rec) => history.push(`/buyer/budgets/detail/${rec.id}/details`),
					}}
					fetching={fetching}
					FormComponent={BudgetForm}
				/>,
			]
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		history: ownProps.history,
		budget: state.budgets.detail,
		fetching: state.budgets.fetching,
		match: ownProps.match,
	};
};

const mapDispatchToProps = (dispatch) => ({
	getBudget: (id) => dispatch(budgetsRestCrudThunksForBuyer.readOne(id)),
});

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(NewBudgetPage));

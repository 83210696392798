import {
	BUYER_ADMIN_ONLY,
	BUYER_STORE_ASSOCIATE,
	BUYER_USER,
	SUPPLIER_ACCOUNTING_MANAGER,
	SUPPLIER_ADMIN_ONLY,
	SUPPLIER_INVENTORY_MANAGER,
	SUPPLIER_MANAGER,
	SUPPLIER_TECH,
	SUPPLIER_USER,
} from '../components/roles/roles';
import { nullSafeGet, nullSafeGetOrElse } from './DataAccessUtils';
import { ROLE_TYPES } from './DataConstants';

export const checkAccountSetupNeeded = (user) => !(user.facility && user.facility.id);

export const isInternalTech = (currentUser) =>
	nullSafeGetOrElse('facility.status', currentUser, '') === 'private';

export const isAllowedToPerform = (permissionName, companySetting, roles) => {
	if (roles.some((_) => BUYER_ADMIN_ONLY.has(_))) {
		return true;
	} else {
		const permissionConfig = nullSafeGetOrElse(
			`config.${permissionName}`,
			companySetting,
			getBuyerAccessDefaultConfig(permissionName)
		);
		if (roles.includes(BUYER_USER)) {
			return permissionConfig.managersAllowed;
		} else if (roles.includes(BUYER_STORE_ASSOCIATE)) {
			return permissionConfig.storeAssociatesAllowed;
		}
	}

	return false;
};

export function validateDomain(email, companyNameArr) {
	if (companyNameArr.indexOf(email.split('@')[1]) === -1) {
		return false;
	}
	return true;
}

export const CONFIG_NAMES = {
	DISABLE_PURCHASE_ORDERS: 'disablePurchaseOrders',
	ALLOW_MONTHLY_CURRENCY_EXCHANGE_RATE: 'allowMonthlyCurrencyExchangeRate',
};

export const PERMISSION_NAMES = {
	SHOW_LOCATION_DETAILS: 'showLocationDetails',
	MODIFY_LOCATIONS: 'modifyLocations',
	MODIFY_LOCATION_TYPES: 'modifyLocationTypes',
	MODIFY_AREAS: 'modifyAreas',
	MODIFY_SELF_LOCATION_ACCESS: 'modifySelfLocationAccess',
	SHOW_INTERNAL_TECH_NOTES: 'showInternalTechNotes',
	MODIFY_ASSETS: 'modifyAssets',
	MODIFY_ASSET_TYPES: 'modifyAssetTypes',
	SHOW_ASSET_PRICE: 'showAssetPrice',
	CAN_MARK_WORK_ORDER_COMPLETED: 'canMarkWorkOrderCompleted',
	CAN_REVIEW_WORK_ORDER_COMPLETED: 'canReviewWorkOrderCompletion',
	ACCESS_ANALYTICS: 'accessAnalytics',
	MODIFY_PM: 'modifyPlannedMaintenance',
	MODIFY_SUPPLIERS: 'modifySuppliers',
	MODIFY_PARTS_AND_EQUIPMENTS: 'modifyPartsAndEquipments',
	MODIFY_VENDORS: 'modifyVendors',
	ACCESS_PR_AND_PO: 'accessPurchaseRequestAndPurchaseOrders',
	ACCESS_DEPRECIATION: 'accessDepreciationModule',
	CAN_BACKDATE_WORK_ORDER: 'canBackDateWorkOrder',
	CAN_UPLOAD_INVOICES_FOR_SUPPLIER: 'canUploadInvoicesForSupplier',
	CAN_UPLOAD_QUOTES_FOR_SUPPLIER: 'canUploadQuotesForSupplier',
	CAN_APPROVE_WORK_ORDERS: 'canApproveWorkOrders',
	CAN_APPROVE_QUOTES: 'canApproveQuotes',
	CAN_APPROVE_INVOICES: 'canApproveInvoices',
	EDIT_WORK_ORDERS: 'editWorkOrder',
	CANCEL_WORK_ORDERS: 'cancelWorkOrder',
	REOPEN_WORK_ORDERS: 'reopenWorkOrder',
	MODIFY_SITE_SURVEYS: 'modifySiteSurveys',
	MODIFY_SITE_SURVEY_TEMPLATES: 'modifySiteSurveyTemplates',
	ADD_WALKTHROUGHS: 'addWalkthroughs',
	DELETE_WALKTHROUGHS: 'deleteWalkthroughs',
	DELETE_STOCK_LOCATIONS: 'deleteStockLocations',
	CAN_SHOW_ALL_SUPPLIERS_IN_WO_CREATION: 'supplierShowAllEnabledInWOCreation',
	CAN_VIEW_PART_EQUIPMENT_COST_IN_WO: 'viewPartEquipmentPriceInWorkOrder',
};

export const getSupplierAccessDefaultConfig = (permissionName) => {
	const ALL_RESTRICTED_DEFAULT = {
		managersAllowed: false,
		techsAllowed: false,
		usersAllowed: false,
		inventoryManagerAllowed: false,
		accountingManagerAllowed: false,
	};

	const ALL_ALLOWED_DEFAUT = {
		managersAllowed: true,
		techsAllowed: true,
		usersAllowed: true,
		inventoryManagerAllowed: true,
		accountingManagerAllowed: true,
	};

	switch (permissionName) {
		case PERMISSION_NAMES.CAN_UPLOAD_INVOICES_FOR_SUPPLIER:
		case PERMISSION_NAMES.CAN_UPLOAD_QUOTES_FOR_SUPPLIER:
		case PERMISSION_NAMES.CAN_APPROVE_WORK_ORDERS:
		case PERMISSION_NAMES.CAN_APPROVE_INVOICES:
		case PERMISSION_NAMES.CAN_APPROVE_QUOTES:
		case PERMISSION_NAMES.CAN_BACKDATE_WORK_ORDER:
		case PERMISSION_NAMES.DELETE_STOCK_LOCATIONS:
		case PERMISSION_NAMES.MODIFY_AREAS:
		case PERMISSION_NAMES.MODIFY_LOCATION_TYPES:
		case PERMISSION_NAMES.MODIFY_ASSET_TYPES:
		case PERMISSION_NAMES.CAN_VIEW_PART_EQUIPMENT_COST_IN_WO:
			return { ...ALL_RESTRICTED_DEFAULT };
		case PERMISSION_NAMES.MODIFY_SITE_SURVEYS:
		case PERMISSION_NAMES.MODIFY_SITE_SURVEY_TEMPLATES:
		case PERMISSION_NAMES.ADD_WALKTHROUGHS:
		case PERMISSION_NAMES.DELETE_WALKTHROUGHS:
		case PERMISSION_NAMES.REOPEN_WORK_ORDERS:
		default:
			return { ...ALL_ALLOWED_DEFAUT };
	}
};

export const getBuyerAccessDefaultConfig = (permissionKey?) => {
	const ALL_RESTRICTED_DEFAULT = {
		managersAllowed: false,
		storeAssociatesAllowed: false,
	};

	const ALL_ALLOWED_DEFAUT = {
		managersAllowed: true,
		storeAssociatesAllowed: true,
	};

	const ONLY_MANAGERS_ALLOWED_DEFAULT = {
		managersAllowed: true,
		storeAssociatesAllowed: false,
	};

	switch (permissionKey) {
		case PERMISSION_NAMES.CAN_MARK_WORK_ORDER_COMPLETED:
		case PERMISSION_NAMES.CAN_APPROVE_WORK_ORDERS:
		case PERMISSION_NAMES.CAN_APPROVE_QUOTES:
		case PERMISSION_NAMES.CAN_APPROVE_INVOICES:
		case PERMISSION_NAMES.EDIT_WORK_ORDERS:
		case PERMISSION_NAMES.CAN_SHOW_ALL_SUPPLIERS_IN_WO_CREATION:
			return ONLY_MANAGERS_ALLOWED_DEFAULT;
		case PERMISSION_NAMES.CAN_UPLOAD_INVOICES_FOR_SUPPLIER:
		case PERMISSION_NAMES.CAN_UPLOAD_QUOTES_FOR_SUPPLIER:
		case PERMISSION_NAMES.MODIFY_SUPPLIERS:
		case PERMISSION_NAMES.MODIFY_AREAS:
		case PERMISSION_NAMES.MODIFY_LOCATION_TYPES:
		case PERMISSION_NAMES.MODIFY_ASSET_TYPES:
			return { ...ALL_RESTRICTED_DEFAULT };
		case PERMISSION_NAMES.CAN_REVIEW_WORK_ORDER_COMPLETED:
		case PERMISSION_NAMES.CANCEL_WORK_ORDERS:
		case PERMISSION_NAMES.REOPEN_WORK_ORDERS:
		case PERMISSION_NAMES.MODIFY_SITE_SURVEYS:
		case PERMISSION_NAMES.MODIFY_SITE_SURVEY_TEMPLATES:
		case PERMISSION_NAMES.ADD_WALKTHROUGHS:
		case PERMISSION_NAMES.DELETE_WALKTHROUGHS:
		case PERMISSION_NAMES.MODIFY_SELF_LOCATION_ACCESS:
		case PERMISSION_NAMES.SHOW_LOCATION_DETAILS:
		default:
			return { ...ALL_ALLOWED_DEFAUT };
	}
};

export const getSupplierConfigForPermission = (permissionName, companySetting) => {
	const keys = {
		[PERMISSION_NAMES.MODIFY_LOCATIONS]: ['locations'],
		[PERMISSION_NAMES.MODIFY_LOCATION_TYPES]: ['locations'],
		[PERMISSION_NAMES.MODIFY_AREAS]: ['locations'],
		[PERMISSION_NAMES.SHOW_INTERNAL_TECH_NOTES]: ['locations'],
		[PERMISSION_NAMES.DELETE_STOCK_LOCATIONS]: ['locations'],
		[PERMISSION_NAMES.MODIFY_ASSETS]: ['assets'],
		[PERMISSION_NAMES.MODIFY_ASSET_TYPES]: ['assets'],
		[PERMISSION_NAMES.SHOW_ASSET_PRICE]: ['assets'],
		[PERMISSION_NAMES.CAN_MARK_WORK_ORDER_COMPLETED]: ['approvals'],
		[PERMISSION_NAMES.CAN_REVIEW_WORK_ORDER_COMPLETED]: ['approvals'],
		[PERMISSION_NAMES.ACCESS_ANALYTICS]: ['others'],
		[PERMISSION_NAMES.MODIFY_PM]: ['others'],
		[PERMISSION_NAMES.MODIFY_SUPPLIERS]: ['others'],
		[PERMISSION_NAMES.MODIFY_PARTS_AND_EQUIPMENTS]: ['others'],
		[PERMISSION_NAMES.MODIFY_VENDORS]: ['others'],
		[PERMISSION_NAMES.ACCESS_PR_AND_PO]: ['others'],
		[PERMISSION_NAMES.ACCESS_DEPRECIATION]: ['others'],
		[PERMISSION_NAMES.CAN_BACKDATE_WORK_ORDER]: ['others'],
		[PERMISSION_NAMES.CAN_UPLOAD_INVOICES_FOR_SUPPLIER]: ['others'],
		[PERMISSION_NAMES.CAN_UPLOAD_QUOTES_FOR_SUPPLIER]: ['others'],
		[PERMISSION_NAMES.REOPEN_WORK_ORDERS]: ['others'],
		[PERMISSION_NAMES.CAN_VIEW_PART_EQUIPMENT_COST_IN_WO]: ['others'],
		[PERMISSION_NAMES.CAN_APPROVE_WORK_ORDERS]: ['approvals'],
		[PERMISSION_NAMES.CAN_APPROVE_QUOTES]: ['approvals'],
		[PERMISSION_NAMES.CAN_APPROVE_INVOICES]: ['approvals'],
		[PERMISSION_NAMES.MODIFY_SITE_SURVEYS]: ['siteSurveys'],
		[PERMISSION_NAMES.MODIFY_SITE_SURVEY_TEMPLATES]: ['siteSurveys'],
		[PERMISSION_NAMES.ADD_WALKTHROUGHS]: ['siteSurveys'],
		[PERMISSION_NAMES.DELETE_WALKTHROUGHS]: ['siteSurveys'],
	};

	const keyName = [...(keys[permissionName] || []), ...[permissionName]].join('.');

	return nullSafeGetOrElse(
		`config.accessPermissions.${keyName}`,
		companySetting,
		getSupplierAccessDefaultConfig(permissionName)
	);
};

export const isSupplierAllowedToAccess = (permissionName, companySetting, roles) => {
	if (roles.some((_) => SUPPLIER_ADMIN_ONLY.has(_))) {
		return true;
	} else {
		const permissionConfig = getSupplierConfigForPermission(permissionName, companySetting);
		const roleName = {
			[SUPPLIER_TECH]: 'techsAllowed',
			[SUPPLIER_USER]: 'usersAllowed',
			[SUPPLIER_MANAGER]: 'managersAllowed',
			[SUPPLIER_INVENTORY_MANAGER]: 'inventoryManagerAllowed',
			[SUPPLIER_ACCOUNTING_MANAGER]: 'accountingManagerAllowed',
		};

		return roles.map((_) => nullSafeGet(`${roleName[_]}`, permissionConfig)).some((_) => !!_);
	}
};

export const getBuyerConfigForPermission = (permissionName, companySetting) => {
	const keys = {
		[PERMISSION_NAMES.CAN_MARK_WORK_ORDER_COMPLETED]: [],
		[PERMISSION_NAMES.CAN_REVIEW_WORK_ORDER_COMPLETED]: [],
		[PERMISSION_NAMES.SHOW_LOCATION_DETAILS]: ['accessPermissions', 'locations'],
		[PERMISSION_NAMES.MODIFY_LOCATIONS]: ['accessPermissions', 'locations'],
		[PERMISSION_NAMES.MODIFY_LOCATION_TYPES]: ['accessPermissions', 'locations'],
		[PERMISSION_NAMES.MODIFY_AREAS]: ['accessPermissions', 'locations'],
		[PERMISSION_NAMES.MODIFY_SELF_LOCATION_ACCESS]: ['accessPermissions', 'locations'],
		[PERMISSION_NAMES.SHOW_INTERNAL_TECH_NOTES]: ['accessPermissions', 'locations'],
		[PERMISSION_NAMES.MODIFY_ASSETS]: ['accessPermissions', 'assets'],
		[PERMISSION_NAMES.MODIFY_ASSET_TYPES]: ['accessPermissions', 'assets'],
		[PERMISSION_NAMES.SHOW_ASSET_PRICE]: ['accessPermissions', 'assets'],
		[PERMISSION_NAMES.ACCESS_ANALYTICS]: ['accessPermissions', 'others'],
		[PERMISSION_NAMES.CAN_UPLOAD_INVOICES_FOR_SUPPLIER]: ['accessPermissions', 'others'],
		[PERMISSION_NAMES.CAN_UPLOAD_QUOTES_FOR_SUPPLIER]: ['accessPermissions', 'others'],
		[PERMISSION_NAMES.MODIFY_SUPPLIERS]: ['accessPermissions', 'others'],
		[PERMISSION_NAMES.EDIT_WORK_ORDERS]: ['accessPermissions', 'others'],
		[PERMISSION_NAMES.CANCEL_WORK_ORDERS]: ['accessPermissions', 'others'],
		[PERMISSION_NAMES.REOPEN_WORK_ORDERS]: ['accessPermissions', 'others'],
		[PERMISSION_NAMES.CAN_SHOW_ALL_SUPPLIERS_IN_WO_CREATION]: ['accessPermissions', 'others'],
		[PERMISSION_NAMES.MODIFY_SITE_SURVEYS]: ['accessPermissions', 'siteSurveys'],
		[PERMISSION_NAMES.MODIFY_SITE_SURVEY_TEMPLATES]: ['accessPermissions', 'siteSurveys'],
		[PERMISSION_NAMES.ADD_WALKTHROUGHS]: ['accessPermissions', 'siteSurveys'],
		[PERMISSION_NAMES.DELETE_WALKTHROUGHS]: ['accessPermissions', 'siteSurveys'],
	};

	const keyName = [...(keys[permissionName] || []), ...[permissionName]].join('.');
	return nullSafeGetOrElse(
		`config.${keyName}`,
		companySetting,
		getBuyerAccessDefaultConfig(permissionName)
	);
};

export const isBuyerAllowedToAccess = (permissionName, companySetting, roles) => {
	if (roles.some((_) => BUYER_ADMIN_ONLY.has(_))) {
		return true;
	} else {
		const permissionConfig = getBuyerConfigForPermission(permissionName, companySetting);

		const roleName = {
			[BUYER_USER]: 'managersAllowed',
			[BUYER_STORE_ASSOCIATE]: 'storeAssociatesAllowed',
		};

		return roles.map((_) => nullSafeGet(`${roleName[_]}`, permissionConfig)).some((_) => !!_);
	}
};

export const isUserAllowedToAccess = (userType) => (permissionName, companySetting, roles) =>
	userType === ROLE_TYPES.SUPPLIER
		? isSupplierAllowedToAccess(permissionName, companySetting, roles)
		: isBuyerAllowedToAccess(permissionName, companySetting, roles);

export const getBuyerCompanySettingValue =
	(userType) =>
	(name, companyConfig, currentUser, defaultValue = false) =>
		userType === ROLE_TYPES.SUPPLIER
			? nullSafeGetOrElse(
					`facility.privateBuyerCompanySettings.config.${name}`,
					currentUser,
					defaultValue
			  )
			: nullSafeGetOrElse(`config.${name}`, companyConfig, defaultValue);

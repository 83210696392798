import { Card, Timeline } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ArrowRightOutlined, EditOutlined } from '@ant-design/icons';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { UserName } from '../name_component/user/UserNameComponent';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import DateTimeHover from '../date_time_component/DateTime';
import { locationAudits } from '../../thunks/locations_thunks';

const fieldMap = {
	name: 'Name',
	isActive: 'Is Active',
	description: 'Description',
	buyerFacilityId: 'Buyer Facility',
	images: 'Images',
	operatingHours: 'Operating Hours',
	phoneNumber: 'Phone Number',
	currencyId: 'Currency',
	storeId: 'Store',
	brandId: 'Brand',
	regionId: 'Region',
	facilityInChargeEmail: 'Facility In Charge Emails',
	facilityInChargeEmails: 'Facility In Charge Emails',
	sendApprovedInvoicesToEmails: 'Send Approved Invoices To Emails',
	primaryContactsEmails: 'Primary Contacts Emails',
	locationTypeId: 'Location Type',
	locationTypeDetails: 'Location Type Details',
	notes: 'Notes',
	internalNotes: 'Internal Notes',
	internalAttachments: 'Internal Attachments',
	isLeased: 'Is Leased',
	leaseStartDate: 'Lease Start Date',
	leaseEndDate: 'Lease End Date',
	openDate: 'Open Date',
	closeDate: 'Close Date',
	fieldTechEmails: 'Field Tech Emails',
	storeAssociateEmails: 'Store Associate Emails',
};

function LocationAudit({ match, locationAudits, userType }) {
	const [data, setData] = useState([]);
	useEffect(() => {
		const locationId = match.params.id;
		locationAudits(userType, { locationId, offset: 0, limit: 10 }).then((res = []) => {
			setData(res.filter((item) => nullSafeGetOrElse('fieldValueChanges.length', item, 0) > 0));
		});
	}, [locationAudits, match.params.id, userType]);

	const getDescription = (item) => {
		const getFieldsChangeCopy = (field) => {
			switch (field.fieldName) {
				case 'regionId':
					return (
						<span className="inline-block rounded-md bg-violet-100 p-2 text-sm text-gray-500">
							{fieldMap[field.fieldName] || field.fieldName} :{' '}
							{nullSafeGet('fromRegion.name', item) || field.oldValue || `' '`}{' '}
							<ArrowRightOutlined translate="" />{' '}
							{nullSafeGet('toRegion.name', item) || field.newValue || `' '`}
						</span>
					);
				case 'brandId':
					return (
						<span className="inline-block rounded-md bg-violet-100 p-2 text-sm text-gray-500">
							{fieldMap[field.fieldName] || field.fieldName} :{' '}
							{nullSafeGet('fromBrand.name', item) || field.oldValue || `' '`}{' '}
							<ArrowRightOutlined translate="" />{' '}
							{nullSafeGet('toBrand.name', item) || field.newValue || `' '`}
						</span>
					);
				case 'locationTypeId':
					return (
						<span className="inline-block rounded-md bg-violet-100 p-2 text-sm text-gray-500">
							{fieldMap[field.fieldName] || field.fieldName} :{' '}
							{nullSafeGet('fromLocationType.name', item) || field.oldValue || `' '`}{' '}
							<ArrowRightOutlined translate="" />{' '}
							{nullSafeGet('toLocationType.name', item) || field.newValue || `' '`}
						</span>
					);
				case 'fieldTechEmails':
				case 'facilityInChargeEmails':
				case 'sendApprovedInvoicesToEmails':
				case 'primaryContactsEmails':
				case 'facilityInChargeEmail':
				case 'storeAssociateEmails':
					const oldEmails = (field.oldValue || '').split(', ').map((email) => email.trim());
					const newEmails = (field.newValue || '').split(', ').map((email) => email.trim());

					const addedEmails = newEmails.filter((email) => !oldEmails.includes(email));
					const removedEmails = oldEmails.filter((email) => !newEmails.includes(email));

					return (
						<span className="inline-block rounded-md bg-violet-100 p-2 text-sm text-gray-500">
							{fieldMap[field.fieldName] || field.fieldName} Changed :
							<div>
								{(addedEmails.length && (
									<div className="mt-1 inline-block rounded-md bg-violet-50 p-1 text-sm text-gray-500">
										<i>Added :</i> {addedEmails.join(',')}{' '}
									</div>
								)) ||
									null}

								{(removedEmails.length && (
									<div className="mt-1 inline-block rounded-md bg-violet-50 p-1 text-sm text-gray-500">
										<i>Removed :</i> {removedEmails.join(',')}{' '}
									</div>
								)) ||
									null}
							</div>
						</span>
					);
				case 'leaseStartDate':
				case 'leaseEndDate':
				case 'openDate':
				case 'closeDate':
					return (
						<span className="inline-block rounded-md bg-violet-100 p-2 text-sm text-gray-500">
							{fieldMap[field.fieldName] || field.fieldName} :{' '}
							{(field.oldValue && (
								<DateTimeHover showTime={false} showDate timestamp={field.oldValue} />
							)) ||
								`' '`}{' '}
							<ArrowRightOutlined translate="" />{' '}
							{(field.newValue && (
								<DateTimeHover showTime={false} showDate timestamp={field.newValue} />
							)) ||
								`' '`}
						</span>
					);

				default:
					return (
						<span className="inline-block rounded-md bg-violet-100 p-2 text-sm text-gray-500">
							{fieldMap[field.fieldName] || field.fieldName} : {field.oldValue || `' '`}{' '}
							<ArrowRightOutlined translate="" /> {field.newValue}
						</span>
					);
			}
		};

		const getFieldsChange = (item) => {
			return (
				(item.fieldValueChanges && (
					<div className="mt-3 flex flex-wrap gap-2">
						{item.fieldValueChanges.map((field) =>
							field.fieldName === 'internalNotes' || field.fieldName === 'internalAttachments' ? (
								<AccessPermissionChecker name={PERMISSION_NAMES.SHOW_INTERNAL_TECH_NOTES}>
									{getFieldsChangeCopy(field)}
								</AccessPermissionChecker>
							) : (
								getFieldsChangeCopy(field)
							)
						)}
					</div>
				)) ||
				null
			);
		};

		return (
			<div>
				Edited by <UserName contact={item.createdByContact} mode={'inline'} placement={'top'} />
				{getFieldsChange(item)}
			</div>
		);
	};
	const getIcon = (item) => {
		return <EditOutlined translate="" />;
	};
	return (
		<div className="flex flex-col p-4">
			<Card>
				<div className="flex">
					<Timeline mode="left">
						{data.map((item) => (
							<Timeline.Item dot={getIcon(item)} key={item.id} className="mb-5">
								<div className="mb-5">
									<div className=" text-base ">{getDescription(item)}</div>
									<span className="mt-3 inline-block text-sm text-gray-500">
										<DateTimeHover timestamp={nullSafeGet('createdAt', item)} showDate={true} />
									</span>
								</div>
							</Timeline.Item>
						))}
					</Timeline>
				</div>
			</Card>
		</div>
	);
}

const mapStateToProps = (state) => ({
	userType: state.session.userType,
});

const mapDispatchToProps = (dispatch) => ({
	locationAudits: (userType, params) => dispatch(locationAudits(userType)(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationAudit);

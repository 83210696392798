import React from 'react';
import { Button, Popconfirm, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	acceptWorkOrderForSupplier,
	cancelWorkOrderForSupplier,
	declineWorkOrderForSupplier,
	declineWorkOrderForPrivateSupplier,
	transferWorkOrderForSupplier,
	workOrdersRestCrudThunksForSupplier,
	transferWorkOrderForPrivateSupplier,
	reopenWorkOrderForSupplier,
} from '../../thunks/work_orders_thunks';
import { getWorkOrderBasePriorityBackgroundColor } from '../../utils/DataFormatterUtils';
import WorkOrderStatusDisplay from '../work_order_status_display/WorkOrderStatusDisplay';
import { getEntityById, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import TransferWorkOrderModalForm from '../transfer_work_order_modal_form/TransferWorkOrderModalForm';
import moment from 'moment';
import SupplierChangeStatusWithNoteWorkForm from '../supplier_change_status_with_note_work_form/SupplierChangeStatusWithNoteWorkForm';
import DeclineWorkorderForm from '../decline_workorder_form/DeclineWorkorderForm';
import { WORK_ORDER_STATUSES } from '../../constants/WorkOrderStatuses';
import { Link } from 'react-router-dom';
import CancelWorkOrder from '../cancel_work_order/CancelWorkOrder';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import NextPreviousButton from '../next_previous_button/NextPreviousButton';
import { updatePaginationWorkOrders } from '../../thunks/work_orders_thunks_wrapper';
import { WORK_ORDERS_CRUD_ACTION_CREATORS } from '../../actions/work_orders_actions';

const queryString = require('qs');
require('./SupplierWorkOrdersDetailPage.less');

class SupplierWorkOrdersDetailPage extends React.Component<any, any> {
	state = {
		loading: true,
		backLinkTo: '/supplier/workOrders',
		backLinkText: 'Back to work orders',
		reopenModalVisible: false,
		cancelModalVisible: false,
		transferWorkOrderFormVisible: false,
		declineWorkOrderFormVisible: false,
		isPrivateSupplier: false,
		tab: 'active',
		allowedToDenyWork: false,
		allowedToTransferWork: true,
		fromTargetCollectionName: null,
	};

	formRefs: any = {
		transferWorkOrderForm: null,
	};

	constructor(props) {
		super(props);
		this.handleReopenSubmit = this.handleReopenSubmit.bind(this);
		this.handleReopenCancel = this.handleReopenCancel.bind(this);
		this.showReopenModal = this.showReopenModal.bind(this);
	}

	componentDidMount() {
		const { getWorkOrder, location, match, currentUser, workOrder, getWorkOrderDelta } = this.props;
		const workOrderId = match.params.id;

		const isPrivateSupplier = nullSafeGet('facility.status', currentUser) === 'private';
		const internalTechConfig =
			currentUser && currentUser.facility && currentUser.facility.internalTechConfig;

		const { allowedToTransferWork, allowedToDenyWork } = internalTechConfig || {
			allowedToTransferWork: true,
			allowedToDenyWork: false,
		};
		this.setState({
			isPrivateSupplier: isPrivateSupplier,
			allowedToTransferWork,
			allowedToDenyWork,
		});

		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		if (queryParams.targetCollectionName) {
			this.setState({ fromTargetCollectionName: queryParams.targetCollectionName });
		}
		if (queryParams.backlinkParams) {
			const { tab, pathName, backLinkText, ...otherParams } = queryString.parse(
				queryParams.backlinkParams
			);
			const finalPath = pathName || `/supplier/workOrders/overview/${tab ? tab : 'active'}`;
			this.setState({
				tab: tab ? tab : 'active',
				loading: false,
				...(backLinkText && { backLinkText }),
				backLinkTo: `${finalPath}?${new URLSearchParams(otherParams).toString()}`,
			});
		} else {
			this.setState({
				loading: false,
				backLinkTo: `/supplier/workOrders/overview/active`,
			});
		}
		if (workOrder && workOrder.id) {
			getWorkOrderDelta(workOrderId);
		} else {
			getWorkOrder(workOrderId);
		}
	}

	handleTabChange = (key) => {
		const { history, match } = this.props;
		const workOrderId = match.params.id;
		history.push(`/supplier/workOrders/detail/${workOrderId}/${key}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	handleEdit = () => {
		const { history, workOrder, location } = this.props;
		history.push(
			getLinkWIthBackLinkParams(
				location,
				'Back to Site Survey Detail',
				`/supplier/workOrders/edit/${workOrder.id}`
			)
		);
	};

	handleArchive = () => {
		const { history, archiveWorkOrder, workOrder } = this.props;
		const { backLinkTo } = this.state;
		archiveWorkOrder(workOrder).then(() => {
			history.push(backLinkTo);
		});
	};

	handleUnarchive = () => {
		const { history, unarchiveWorkOrder, workOrder } = this.props;
		const { backLinkTo } = this.state;
		unarchiveWorkOrder(workOrder).then(() => {
			history.push(backLinkTo);
		});
	};

	handleAccept = () => {
		const { history, acceptWorkOrder, workOrder } = this.props;
		acceptWorkOrder(workOrder);
	};

	handleTransfer = () => {
		this.setState({
			transferWorkOrderFormVisible: true,
		});
	};

	handleTransferWorkOrderCancel = (e) => {
		this.setState({
			transferWorkOrderFormVisible: false,
		});
	};

	handleTransferWorkOrderSubmit = () => {
		const { transferWorkOrder, transferWorkOrderForPrivateSupplier, history, updateWorkOrder } =
			this.props;
		const form = this.formRefs['transferWorkOrderForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}
			this.state.isPrivateSupplier
				? transferWorkOrderForPrivateSupplier(values).then(() => {
						this.setState({ transferWorkOrderFormVisible: false });
						message.success(`Work order transferred`);
				  })
				: transferWorkOrder(values)
						.then(() => {
							this.setState({ transferWorkOrderFormVisible: false });
							message.success(`Work order transferred`);
						})
						.then(() => {
							history.push('/supplier/workOrders/overview/active');
						});
		});
	};

	handleDecline = () => {
		this.setState({
			declineWorkOrderFormVisible: true,
		});
	};

	handleDeclineWorkOrderCancel = (e) => {
		this.setState({
			declineWorkOrderFormVisible: false,
		});
	};

	handleDeclineWorkOrderSubmit = () => {
		const { history, declineWorkOrder, currentUser, declineWorkOrderPrivate, workOrder } =
			this.props;
		const isPrivateSupplier = nullSafeGet('facility.status', currentUser) === 'private';
		const declineRoute = isPrivateSupplier ? declineWorkOrderPrivate : declineWorkOrder;
		const { backLinkTo } = this.state;
		const form = this.formRefs['declineWorkOrderForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}
			const workOrderNoteDetails = {
				id: workOrder.id,
				...(values.note && {
					note: {
						text: values.note,
						noteAddedBy: currentUser.email,
						noteAddedAt: moment(),
					},
				}),
			};
			declineRoute(workOrderNoteDetails).then(() => {
				history.push(backLinkTo);
			});
		});
	};

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	getLocationText = () => {
		const { isPrivateSupplier } = this.state;
		const { workOrder } = this.props;

		return `${
			(!isPrivateSupplier && `${nullSafeGet('location.buyerCompany.name', workOrder)} -`) || ''
		} ${nullSafeGet('location.name', workOrder)} `;
	};

	handleReopen = () => {
		const { history, workOrder, reopenWorkOrder } = this.props;
		reopenWorkOrder(workOrder).then(() => {
			history.push(`/supplier/workOrders/detail/${workOrder.id}`);
		});
	};

	handleReopenSubmit() {
		const { reopenWorkOrder, currentUser, workOrder } = this.props;

		const form = this.formRefs['reopenForm'].props.form;

		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			const note = {
				text: values.note,
				noteAddedBy: currentUser.email,
				noteAddedAt: moment(),
			};

			const workOrderNoteDetails = {
				id: values.workOrderId,
				locationId: workOrder.locationId,
				note,
			};

			reopenWorkOrder(workOrderNoteDetails).then(() => {
				this.setState({ reopenModalVisible: false });
				message.success(`re-opened for work.`);
			});
		});
	}

	handleReopenCancel(e) {
		this.setState({
			reopenModalVisible: false,
		});
	}

	showReopenModal(e) {
		this.setState({
			reopenModalVisible: true,
		});
	}

	render() {
		const {
			workOrder,
			match,
			currentUser,
			location,
			history,
			work_orders,
			cloneTargetCollection,
			updatePagination,
		} = this.props;
		const workOrderId = match.params.id;
		const hasUnreadNotes = nullSafeGetOrElse('unreadNotesNotificationIds.length', workOrder, 0) > 0;
		const tabs = this.state.isPrivateSupplier
			? [
					{
						key: 'details',
						name: 'Overview',
					},
					{
						key: 'charges',
						name: 'Parts & Equipment',
					},
					{
						key: 'invoices',
						name: 'Invoices',
					},
					{
						key: 'quotes',
						name: 'Quotes',
					},
					{
						key: 'notes',
						name: hasUnreadNotes ? (
							<>
								Notes <span className="Notification_badge ml-1 align-middle" />
							</>
						) : (
							'Notes'
						),
					},
			  ]
			: [
					{
						key: 'details',
						name: 'Overview',
					},
					{
						key: 'charges',
						name: 'Parts & Equipment',
					},
					{
						key: 'quotes',
						name: 'Proposals',
					},
					{
						key: 'invoices',
						name: 'Invoices',
					},
					{
						key: 'notes',
						name: hasUnreadNotes ? (
							<>
								Notes <span className="Notification_badge ml-1 align-middle" />
							</>
						) : (
							'Notes'
						),
					},
			  ];

		const differentAssignedSupplier = this.state.isPrivateSupplier
			? nullSafeGet('facility.id', currentUser) !== workOrder.supplierFacilityId
			: false;

		const isAssignedToInternalTech =
			nullSafeGetOrElse('supplierFacility.status', workOrder, '') === 'private';

		const requestQuotesAction =
			workOrder.displayStatus !== 'Completed' &&
			workOrder.displayStatus !== 'Cancelled' &&
			(isAssignedToInternalTech || workOrder.status === WORK_ORDER_STATUSES.unassigned) &&
			nullSafeGetOrElse('rfpDataIds.length', workOrder, 0) > 0 ? (
				<Link
					to={`/supplier/requestsForProposal/detail/${workOrder.rfpDataIds[0]}/details`}
					style={{ marginRight: 16, marginBottom: 16 }}
				>
					<Button ghost={true}>View RFP</Button>
				</Link>
			) : (
				<Link
					to={`/supplier/requestsForProposal/overview/new?workOrderId=${workOrderId}`}
					style={{ marginRight: 16, marginBottom: 16 }}
				>
					<Button ghost={true}>Request Quotes</Button>
				</Link>
			);

		const privateTransferAction =
			this.state.isPrivateSupplier && this.state.allowedToTransferWork
				? [
						<Button
							key={3}
							ghost={true}
							style={{ marginRight: 16, marginBottom: 16 }}
							onClick={this.handleTransfer}
						>
							Transfer
						</Button>,
				  ]
				: [];

		const privateDenyAction =
			this.state.isPrivateSupplier && this.state.allowedToDenyWork
				? [
						<Popconfirm
							onConfirm={this.handleDecline}
							placement="topLeft"
							title="Are you sure you want to decline?"
							okText="Yes"
							cancelText="No"
						>
							<Button key={2} ghost={true} style={{ marginRight: 16, marginBottom: 16 }}>
								Decline
							</Button>
						</Popconfirm>,
				  ]
				: [];

		const privateEditAction = this.state.isPrivateSupplier
			? [
					<Button
						key={4}
						ghost={true}
						style={{ marginRight: 16, marginBottom: 16 }}
						onClick={this.handleEdit}
					>
						Edit
					</Button>,
			  ]
			: [];

		const privateCancelAction = this.state.isPrivateSupplier
			? [
					<CancelWorkOrder
						cancelButton={
							<Button key={5} ghost={true} style={{ marginRight: 16, marginBottom: 16 }}>
								Cancel
							</Button>
						}
						workOrder={workOrder}
					/>,
			  ]
			: [];

		const privateReopenAction = this.state.isPrivateSupplier
			? [
					<AccessPermissionChecker name={PERMISSION_NAMES.REOPEN_WORK_ORDERS}>
						<Button
							key={6}
							ghost={true}
							style={{ marginRight: 16, marginBottom: 16 }}
							onClick={this.showReopenModal}
						>
							Re-open
						</Button>
					</AccessPermissionChecker>,
			  ]
			: [];

		const priorityColor = getWorkOrderBasePriorityBackgroundColor(workOrder.woPriority);
		return (
			<div className="supplierWorkOrdersDetailPage">
				<SupplierChangeStatusWithNoteWorkForm
					wrappedComponentRef={this.saveFormRef('reopenForm')}
					visible={this.state.reopenModalVisible}
					workOrderId={workOrder.id}
					onCancel={this.handleReopenCancel}
					onSubmit={this.handleReopenSubmit}
					formTitle={'Not done yet. Re-open?'}
					formText={'Re-open'}
				/>
				<TransferWorkOrderModalForm
					formData={workOrder}
					wrappedComponentRef={this.saveFormRef('transferWorkOrderForm')}
					visible={this.state.transferWorkOrderFormVisible}
					onCancel={this.handleTransferWorkOrderCancel}
					onSubmit={this.handleTransferWorkOrderSubmit}
				/>
				<DeclineWorkorderForm
					formData={workOrder}
					wrappedComponentRef={this.saveFormRef('declineWorkOrderForm')}
					visible={this.state.declineWorkOrderFormVisible}
					onCancel={this.handleDeclineWorkOrderCancel}
					onSubmit={this.handleDeclineWorkOrderSubmit}
				/>
				<DetailPageHeader
					exactPath={'/supplier/workOrders/detail/:id'}
					redirectPath={`/supplier/workOrders/detail/${workOrderId}/details`}
					backLinkText={this.state.backLinkText}
					backLinkTo={this.state.backLinkTo}
					title={workOrder.title}
					subtitle={
						<div className="flex flex-col flex-wrap">
							<div>
								{nullSafeGet('id', workOrder) && (
									<span className="workOrdersDetailPage__subtitle">
										WO# {nullSafeGet('id', workOrder)}
									</span>
								)}
							</div>
							<div className="flex flex-row flex-wrap">
								<WorkOrderStatusDisplay
									className="workOrdersDetailPage__subtitle"
									status={workOrder.status}
									displayStatus={workOrder.displayStatus}
									darkMode={true}
								/>
								<span className="workOrdersDetailPage__subtitle">
									{nullSafeGet('woPriority.name', workOrder)}
								</span>
							</div>
						</div>
					}
					subtext={<div className="body1"> {this.getLocationText()}</div>}
					actions={
						workOrder.hasSupplierArchived ? (
							<Button
								ghost={true}
								style={{ marginBottom: 16, marginRight: 16 }}
								onClick={this.handleUnarchive}
							>
								Unarchive
							</Button>
						) : workOrder.displayStatus === 'Open' ? (
							this.state.isPrivateSupplier ? (
								privateTransferAction
									.concat(requestQuotesAction)
									.concat(privateDenyAction)
									.concat(privateEditAction)
									.concat(privateCancelAction)
							) : (
								[
									<Button
										key={1}
										ghost={true}
										style={{ marginBottom: 16, marginRight: 16 }}
										onClick={this.handleAccept}
									>
										Accept
									</Button>,
									<Popconfirm
										onConfirm={this.handleDecline}
										placement="topLeft"
										title="Are you sure you want to decline?"
										okText="Yes"
										cancelText="No"
									>
										<Button key={2} ghost={true} style={{ marginBottom: 16, marginRight: 16 }}>
											Decline
										</Button>
									</Popconfirm>,
								]
							)
						) : workOrder.displayStatus === 'Pending' ? (
							[
								<Button
									key={1}
									ghost={true}
									style={{ marginBottom: 16, marginRight: 16 }}
									onClick={this.handleEdit}
								>
									Edit
								</Button>,
								<Button
									key={2}
									ghost={true}
									style={{ marginBottom: 16, marginRight: 16 }}
									onClick={this.handleArchive}
								>
									Archive
								</Button>,
							]
								.concat(requestQuotesAction)
								.concat(privateTransferAction)
								.concat(privateDenyAction)
								.concat(privateCancelAction)
						) : workOrder.displayStatus === 'Completed' ||
						  workOrder.displayStatus === 'Cancelled' ? (
							[
								<Link
									to={getLinkWIthBackLinkParams(
										location,
										'Back to Site Survey Detail',
										`/supplier/workOrders/editSafe/${workOrderId}`
									)}
									style={{ marginBottom: 16, marginRight: 16 }}
								>
									<Button ghost={true}>Edit</Button>
								</Link>,
								<Button
									ghost={true}
									style={{ marginBottom: 16, marginRight: 16 }}
									onClick={this.handleArchive}
								>
									Archive
								</Button>,
							].concat(privateReopenAction)
						) : (
							[
								<Button
									ghost={true}
									style={{ marginBottom: 16, marginRight: 16 }}
									onClick={this.handleArchive}
								>
									Archive
								</Button>,
							]
								.concat(privateTransferAction)
								.concat(privateDenyAction)
								.concat(privateEditAction)
								.concat(privateCancelAction)
						)
					}
					tabs={tabs}
					backgroundColor={priorityColor}
					handleTabChange={this.handleTabChange}
					checkTabActive={this.checkTabActive}
					childRoutes={this.props.childRoutes}
					tabsRightActions={() => (
						<NextPreviousButton
							key={this.state.fromTargetCollectionName}
							location={location}
							history={history}
							activeId={workOrder.id}
							getEnitityUrl={(id) => `/supplier/workOrders/detail/${id}/details`}
							stateSlice={work_orders}
							targetCollectionName={this.state.fromTargetCollectionName}
							updatePagination={updatePagination}
							cloneTargetCollection={cloneTargetCollection}
							entityName="work order"
						/>
					)}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	workOrdersFetching: state.work_orders.fetching,
	match: ownProps.match,
	location: ownProps.location,
	history: ownProps.history,
	currentUser: state.session.currentUser,
	workOrder: getEntityById(ownProps.match.params.id, state.work_orders),
	navbar: state.navbar,
	work_orders: state.work_orders,
});

const mapDispatchToProps = (dispatch) => ({
	getWorkOrder: (id) => dispatch(workOrdersRestCrudThunksForSupplier.readOne(id)),
	getWorkOrderDelta: (id) => dispatch(workOrdersRestCrudThunksForSupplier.readDelta(id)),
	archiveWorkOrder: (entity) =>
		dispatch(workOrdersRestCrudThunksForSupplier.archive(entity, 'id', true)),
	unarchiveWorkOrder: (entity) =>
		dispatch(workOrdersRestCrudThunksForSupplier.unarchive(entity, 'id', true)),
	acceptWorkOrder: (entity) => dispatch(acceptWorkOrderForSupplier(entity, 'id')),
	declineWorkOrder: (entity) => dispatch(declineWorkOrderForSupplier(entity, 'id')),
	declineWorkOrderPrivate: (entity) => dispatch(declineWorkOrderForPrivateSupplier(entity, 'id')),
	transferWorkOrder: (entity) => dispatch(transferWorkOrderForSupplier(entity, 'id')),
	transferWorkOrderForPrivateSupplier: (entity) =>
		dispatch(transferWorkOrderForPrivateSupplier(entity, 'id')),
	cancelWorkOrder: (entity) => dispatch(cancelWorkOrderForSupplier(entity)),
	reopenWorkOrder: (entity) => dispatch(reopenWorkOrderForSupplier(entity)),
	updateWorkOrder: (entity) => dispatch(workOrdersRestCrudThunksForSupplier.update(entity)),
	updatePagination: (pagination, targetCollectionName) =>
		dispatch(
			updatePaginationWorkOrders('supplier')(
				false,
				undefined,
				targetCollectionName,
				pagination,
				true
			)
		),
	cloneTargetCollection: (from, to) =>
		dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.cloneTargetCollection(from, to)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SupplierWorkOrdersDetailPage)
);

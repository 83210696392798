//timeseries
export const FETCH_WORK_ORDER_COUNT_BY_MONTH_START = 'FETCH_WORK_ORDER_COUNT_BY_MONTH_START';
export const FETCH_WORK_ORDER_COUNT_BY_MONTH_SUCCESS = 'FETCH_WORK_ORDER_COUNT_BY_MONTH_SUCCESS';
export const FETCH_WORK_ORDER_COUNT_BY_MONTH_FAILURE = 'FETCH_WORK_ORDER_COUNT_BY_MONTH_FAILURE';

export const FETCH_WORK_ORDER_COUNT_BY_WEEK_START = 'FETCH_WORK_ORDER_COUNT_BY_WEEK_START';
export const FETCH_WORK_ORDER_COUNT_BY_WEEK_SUCCESS = 'FETCH_WORK_ORDER_COUNT_BY_WEEK_SUCCESS';
export const FETCH_WORK_ORDER_COUNT_BY_WEEK_FAILURE = 'FETCH_WORK_ORDER_COUNT_BY_WEEK_FAILURE';

export const FETCH_WORK_ORDER_COUNT_BY_DAY_START = 'FETCH_WORK_ORDER_COUNT_BY_DAY_START';
export const FETCH_WORK_ORDER_COUNT_BY_DAY_SUCCESS = 'FETCH_WORK_ORDER_COUNT_BY_DAY_SUCCESS';
export const FETCH_WORK_ORDER_COUNT_BY_DAY_FAILURE = 'FETCH_WORK_ORDER_COUNT_BY_DAY_FAILURE';

//start tabular fetch
export const FETCH_WORK_ORDER_COUNT_BY_CATEGORY_START = 'FETCH_WORK_ORDER_COUNT_BY_CATEGORY_START';
export const FETCH_WORK_ORDER_COUNT_BY_PROBLEM_TYPE_START =
	'FETCH_WORK_ORDER_COUNT_BY_PROBLEM_TYPE_START';
export const FETCH_WORK_ORDER_COUNT_BY_MANUFACTURER_START =
	'FETCH_WORK_ORDER_COUNT_BY_MANUFACTURER_START';
export const FETCH_WORK_ORDER_COUNT_BY_ASSET_TYPE_START =
	'FETCH_WORK_ORDER_COUNT_BY_ASSET_TYPE_START';
export const FETCH_WORK_ORDER_COUNT_BY_VENDOR_START = 'FETCH_WORK_ORDER_COUNT_BY_VENDOR_START';
export const FETCH_WORK_ORDER_COUNT_BY_LOCATION_START = 'FETCH_WORK_ORDER_COUNT_BY_LOCATION_START';
export const FETCH_WORK_ORDER_COUNT_BY_REGION_START = 'FETCH_WORK_ORDER_COUNT_BY_REGION_START';
export const FETCH_WORK_ORDER_COUNT_BY_FACILITY_START = 'FETCH_WORK_ORDER_COUNT_BY_FACILITY_START';
export const FETCH_WORK_ORDER_COUNT_TIME_SERIES_BY_CONTRACT_STATUS_START =
	'FETCH_WORK_ORDER_COUNT_TIME_SERIES_BY_CONTRACT_STATUS_START';

//tabular fetch success
export const FETCH_WORK_ORDER_COUNT_BY_CATEGORY_SUCCESS =
	'FETCH_WORK_ORDER_COUNT_BY_CATEGORY_SUCCESS';
export const FETCH_WORK_ORDER_COUNT_BY_PROBLEM_TYPE_SUCCESS =
	'FETCH_WORK_ORDER_COUNT_BY_PROBLEM_TYPE_SUCCESS';
export const FETCH_WORK_ORDER_COUNT_BY_MANUFACTURER_SUCCESS =
	'FETCH_WORK_ORDER_COUNT_BY_MANUFACTURER_SUCCESS';
export const FETCH_WORK_ORDER_COUNT_BY_ASSET_TYPE_SUCCESS =
	'FETCH_WORK_ORDER_COUNT_BY_ASSET_TYPE_SUCCESS';
export const FETCH_WORK_ORDER_COUNT_BY_VENDOR_SUCCESS = 'FETCH_WORK_ORDER_COUNT_BY_VENDOR_SUCCESS';
export const FETCH_WORK_ORDER_COUNT_BY_LOCATION_SUCCESS =
	'FETCH_WORK_ORDER_COUNT_BY_LOCATION_SUCCESS';
export const FETCH_WORK_ORDER_COUNT_BY_REGION_SUCCESS = 'FETCH_WORK_ORDER_COUNT_BY_REGION_SUCCESS';
export const FETCH_WORK_ORDER_COUNT_BY_FACILITY_SUCCESS =
	'FETCH_WORK_ORDER_COUNT_BY_FACILITY_SUCCESS';
export const FETCH_WORK_ORDER_COUNT_TIME_SERIES_BY_CONTRACT_STATUS_SUCCESS =
	'FETCH_WORK_ORDER_COUNT_TIME_SERIES_BY_CONTRACT_STATUS_SUCCESS';

//tabular fetch failure
export const FETCH_WORK_ORDER_COUNT_BY_CATEGORY_FAILURE =
	'FETCH_WORK_ORDER_COUNT_BY_CATEGORY_FAILURE';
export const FETCH_WORK_ORDER_COUNT_BY_PROBLEM_TYPE_FAILURE =
	'FETCH_WORK_ORDER_COUNT_BY_PROBLEM_TYPE_FAILURE';
export const FETCH_WORK_ORDER_COUNT_BY_MANUFACTURER_FAILURE =
	'FETCH_WORK_ORDER_COUNT_BY_MANUFACTURER_FAILURE';
export const FETCH_WORK_ORDER_COUNT_BY_ASSET_TYPE_FAILURE =
	'FETCH_WORK_ORDER_COUNT_BY_ASSET_TYPE_FAILURE';
export const FETCH_WORK_ORDER_COUNT_BY_VENDOR_FAILURE = 'FETCH_WORK_ORDER_COUNT_BY_VENDOR_FAILURE';
export const FETCH_WORK_ORDER_COUNT_BY_LOCATION_FAILURE =
	'FETCH_WORK_ORDER_COUNT_BY_LOCATION_FAILURE';
export const FETCH_WORK_ORDER_COUNT_BY_REGION_FAILURE = 'FETCH_WORK_ORDER_COUNT_BY_REGION_FAILURE';
export const FETCH_WORK_ORDER_COUNT_BY_FACILITY_FAILURE =
	'FETCH_WORK_ORDER_COUNT_BY_FACILITY_FAILURE';
export const FETCH_WORK_ORDER_COUNT_TIME_SERIES_BY_CONTRACT_STATUS_FAILURE =
	'FETCH_WORK_ORDER_COUNT_TIME_SERIES_BY_CONTRACT_STATUS_FAILURE';

//filter actions
export const UPDATE_WORK_MANUFACTURER_FILTERS = 'UPDATE_WORK_MANUFACTURER_FILTERS';
export const CLEAR_WORK_MANUFACTURER_FILTERS = 'CLEAR_WORK_MANUFACTURER_FILTERS';

export const UPDATE_WORK_PROBLEM_TYPE_FILTERS = 'UPDATE_WORK_PROBLEM_TYPE_FILTERS';
export const CLEAR_WORK_PROBLEM_TYPE_FILTERS = 'CLEAR_WORK_PROBLEM_TYPE_FILTERS';

export const UPDATE_WORK_ASSET_TYPE_FILTERS = 'UPDATE_WORK_ASSET_TYPE_FILTERS';
export const CLEAR_WORK_ASSET_TYPE_FILTERS = 'CLEAR_WORK_ASSET_TYPE_FILTERS';

export const UPDATE_WORK_LOCATION_FILTERS = 'UPDATE_WORK_LOCATION_FILTERS';
export const CLEAR_WORK_LOCATION_FILTERS = 'CLEAR_WORK_LOCATION_FILTERS';

export const UPDATE_WORK_REGION_FILTERS = 'UPDATE_WORK_REGION_FILTERS';
export const CLEAR_WORK_REGION_FILTERS = 'CLEAR_WORK_REGION_FILTERS';

export const UPDATE_WORK_CATEGORY_FILTERS = 'UPDATE_WORK_CATEGORY_FILTERS';
export const CLEAR_WORK_CATEGORY_FILTERS = 'CLEAR_WORK_CATEGORY_FILTERS';

export const UPDATE_WORK_PRIORITY_FILTERS = 'UPDATE_WORK_PRIORITY_FILTERS';
export const CLEAR_WORK_PRIORITY_FILTERS = 'CLEAR_WORK_PRIORITY_FILTERS';

export const UPDATE_WORK_VENDOR_FILTERS = 'UPDATE_WORK_VENDOR_FILTERS';
export const CLEAR_WORK_VENDOR_FILTERS = 'CLEAR_WORK_VENDOR_FILTERS';

export const UPDATE_WORK_FACILITY_FILTERS = 'UPDATE_WORK_FACILITY_FILTERS';
export const CLEAR_WORK_FACILITY_FILTERS = 'CLEAR_WORK_FACILITY_FILTERS';

export function fetchWorkOrderCountByMonthStart() {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_MONTH_START,
	};
}

export function fetchWorkOrderCountByWeekStart() {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_WEEK_START,
	};
}

export function fetchWorkOrderCountByDayStart() {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_DAY_START,
	};
}

export function fetchWorkOrderCountByAssetTypeStart() {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_ASSET_TYPE_START,
	};
}

export function fetchWorkOrderCountByManufacturerStart() {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_MANUFACTURER_START,
	};
}

export function fetchWorkOrderCountByProblemTypeStart() {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_PROBLEM_TYPE_START,
	};
}

export function fetchWorkOrderCountByCategoryStart() {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_CATEGORY_START,
	};
}

export function fetchWorkOrderCountByVendorStart() {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_VENDOR_START,
	};
}

export function fetchWorkOrderCountByLocationStart() {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_LOCATION_START,
	};
}

export function fetchWorkOrderCountByRegionStart() {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_REGION_START,
	};
}

export function fetchWorkOrderCountByFacilityStart() {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_FACILITY_START,
	};
}

export function fetchWorkOrderCountByAssetTypeSuccess(data) {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_ASSET_TYPE_SUCCESS,
		data,
	};
}

export function fetchWorkOrderCountByManufacturerSuccess(data) {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_MANUFACTURER_SUCCESS,
		data,
	};
}

export function fetchWorkOrderCountByProblemTypeSuccess(data) {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_PROBLEM_TYPE_SUCCESS,
		data,
	};
}

export function fetchWorkOrderCountByMonthSuccess(data) {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_MONTH_SUCCESS,
		data,
	};
}

export function fetchWorkOrderCountByWeekSuccess(data) {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_WEEK_SUCCESS,
		data,
	};
}

export function fetchWorkOrderCountByDaySuccess(data) {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_DAY_SUCCESS,
		data,
	};
}

export function fetchWorkOrderCountByCategorySuccess(data) {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_ASSET_TYPE_SUCCESS,
		data,
	};
}

export function fetchWorkOrderCountByVendorSuccess(data) {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_VENDOR_SUCCESS,
		data,
	};
}

export function fetchWorkOrderCountByLocationSuccess(data) {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_LOCATION_SUCCESS,
		data,
	};
}

export function fetchWorkOrderCountByRegionSuccess(data) {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_REGION_SUCCESS,
		data,
	};
}

export function fetchWorkOrderCountByFacilitySuccess(data) {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_FACILITY_SUCCESS,
		data,
	};
}

export function fetchWorkOrderCountByAssetTypeFailure() {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_ASSET_TYPE_FAILURE,
	};
}

export function fetchWorkOrderCountByManufacturerFailure() {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_MANUFACTURER_FAILURE,
	};
}

export function fetchWorkOrderCountByProblemTypeFailure() {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_PROBLEM_TYPE_FAILURE,
	};
}

export function fetchWorkOrderCountByMonthFailure(errors) {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_MONTH_FAILURE,
		errors,
	};
}

export function fetchWorkOrderCountByWeekFailure(errors) {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_WEEK_FAILURE,
		errors,
	};
}

export function fetchWorkOrderCountByDayFailure(errors) {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_DAY_FAILURE,
		errors,
	};
}

export function fetchWorkOrderCountByCategoryFailure(errors) {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_CATEGORY_FAILURE,
		errors,
	};
}

export function fetchWorkOrderCountByVendorFailure(errors) {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_VENDOR_FAILURE,
		errors,
	};
}

export function fetchWorkOrderCountByLocationFailure(errors) {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_LOCATION_FAILURE,
		errors,
	};
}

export function fetchWorkOrderCountByRegionFailure(errors) {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_REGION_FAILURE,
		errors,
	};
}

export function fetchWorkOrderCountByFacilityFailure(errors) {
	return {
		type: FETCH_WORK_ORDER_COUNT_BY_FACILITY_FAILURE,
		errors,
	};
}

export function updateWorkProblemTypeFilters(filters) {
	return {
		type: UPDATE_WORK_PROBLEM_TYPE_FILTERS,
		filters,
	};
}

export function clearWorkProblemTypeFilters() {
	return {
		type: CLEAR_WORK_PROBLEM_TYPE_FILTERS,
	};
}

export function updateWorkManufacturerFilters(filters) {
	return {
		type: UPDATE_WORK_MANUFACTURER_FILTERS,
		filters,
	};
}

export function clearWorkManufacturerFilters() {
	return {
		type: CLEAR_WORK_MANUFACTURER_FILTERS,
	};
}

export function updateWorkAssetTypeFilters(filters) {
	return {
		type: UPDATE_WORK_ASSET_TYPE_FILTERS,
		filters,
	};
}

export function clearWorkAssetTypeFilters() {
	return {
		type: CLEAR_WORK_ASSET_TYPE_FILTERS,
	};
}

export function updateWorkCategoryFilters(filters) {
	return {
		type: UPDATE_WORK_CATEGORY_FILTERS,
		filters,
	};
}

export function clearWorkCategoryFilters() {
	return {
		type: CLEAR_WORK_CATEGORY_FILTERS,
	};
}

export function updateWorkPriorityFilters(filters) {
	return {
		type: UPDATE_WORK_PRIORITY_FILTERS,
		filters,
	};
}

export function clearWorkPriorityFilters() {
	return {
		type: CLEAR_WORK_PRIORITY_FILTERS,
	};
}

export function updateWorkLocationFilters(filters) {
	return {
		type: UPDATE_WORK_LOCATION_FILTERS,
		filters,
	};
}

export function clearWorkLocationFilters() {
	return {
		type: CLEAR_WORK_LOCATION_FILTERS,
	};
}

export function updateWorkRegionFilters(filters) {
	return {
		type: UPDATE_WORK_REGION_FILTERS,
		filters,
	};
}

export function clearWorkRegionFilters() {
	return {
		type: CLEAR_WORK_REGION_FILTERS,
	};
}

export function updateWorkVendorFilters(filters) {
	return {
		type: UPDATE_WORK_VENDOR_FILTERS,
		filters,
	};
}

export function clearWorkVendorFilters() {
	return {
		type: CLEAR_WORK_VENDOR_FILTERS,
	};
}

export function updateWorkFacilityFilters(filters) {
	return {
		type: UPDATE_WORK_FACILITY_FILTERS,
		filters,
	};
}

export function clearWorkFacilityFilters() {
	return {
		type: CLEAR_WORK_FACILITY_FILTERS,
	};
}

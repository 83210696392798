import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { MinusCircleOutlined } from '@ant-design/icons';
import { Layout, Row, Col, Card, Table, Button, Affix, Spin, Tooltip } from 'antd';
import { PurchaseRequestPartLineItemRowDisplay } from '../purchase_request_part_line_item_row_display/PurchaseRequestPartLineItemRowDisplay';
import {
	getLineItemVendor,
	getObjectValues,
	getTotalValue,
	isPRLineItemSelectionDisabled,
	nullSafeGet,
	nullSafeGetOrElse,
	renderCurrency,
} from '../../utils/DataAccessUtils';
import SubnavBar from '../subnav_bar/SubnavBar';
import PurchaseOrderForm from '../purchase_order_form/PurchaseOrderForm';
import { PurchaseRequestEquipmentLineItemRowDisplay } from '../purchase_request_equipment_line_item_row_display/PurchaseRequestEquipmentLineItemRowDisplay';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { purchaseRequestLineItemsRestCrudThunksForSupplier } from '../../thunks/purchase_request_line_items_thunks';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { EmptyState } from '../empty_state/EmptyState';
import { getRecordsForCompositeTargetCollection } from '../../reducers/standard_reducer_utils';
import { getDisabledMessage } from '../purchase_requests_index_page/po_create_utils';
import LocationStockLocationNameWithType from '../equipment_per_stock_location_row_display/LocationStockLocationNameWithType';

const { Content } = Layout;
require('./NewPurchaseOrderPage.less');

const PART_PR_LINE_ITEMS_TC = 'partPRLineItemsIndex';
const EQUIPMENT_PR_LINE_ITEMS_TC = 'equipmentPRLineItemsIndex';

const PRLineItemParams = {
	isOrdered: false,
	status: 'approved',
};

const NewPurchaseOrderPage: FC<any> = ({
	history,
	fetchPRLineItems,
	currentUser,
	purchaseRequestLineItems,
}): React.ReactElement => {
	const [selectedPartRowKeys, setSelectedPartRowKeys] = useState([]);
	const [selectedPartRows, setSelectedPartRows] = useState([]);
	const [selectedEquipmentRowKeys, setSelectedEquipmentRowKeys] = useState([]);
	const [selectedEquipmentRows, setSelectedEquipmentRows] = useState([]);
	const [poPartLineItemsInCart, setPoPartLineItemsInCart] = useState([]);
	const [poEquipmentLineItemsInCart, setPoEquipmentLineItemsInCart] = useState([]);
	const [selectedVendor, setSelectedVendor] = useState<any>({ id: undefined, name: undefined });
	const [selectedSL, setSelectedSL] = useState<any>({ id: undefined, name: undefined });
	const [selectedLocation, setSelectedLocation] = useState<any>({ id: undefined, name: undefined });
	const [checkingOut, setCheckingOut] = useState(false);
	const [checkingAvailableItems, setCheckingAvailableItems] = useState(true);
	const [partsAvailable, setPartsAvailable] = useState(false);
	const [equipmentsAvailable, setEquipmentsAvailable] = useState(false);

	useEffect(() => {
		const pagination = { pageSize: 1 };
		setCheckingAvailableItems(true);
		const p1 = fetchPRLineItems(
			{
				...PRLineItemParams,
				isEquipmentLine: false,
			},
			PART_PR_LINE_ITEMS_TC,
			pagination
		);

		const p2 = fetchPRLineItems(
			{
				...PRLineItemParams,
				isEquipmentLine: true,
			},
			EQUIPMENT_PR_LINE_ITEMS_TC,
			pagination
		);

		Promise.all([p1, p2])
			.then(([parts, equips]) => {
				parts && parts.length > 0 && setPartsAvailable(true);
				equips && equips.length > 0 && setEquipmentsAvailable(true);
			})
			.finally(() => setCheckingAvailableItems(false));
	}, [fetchPRLineItems]);

	const getRecordFromTargetCollection = useCallback(
		(id) => {
			const records = [
				...getRecordsForCompositeTargetCollection(purchaseRequestLineItems, PART_PR_LINE_ITEMS_TC),
				...getRecordsForCompositeTargetCollection(
					purchaseRequestLineItems,
					EQUIPMENT_PR_LINE_ITEMS_TC
				),
			];
			return records.find((_) => _.id === id);
		},
		[purchaseRequestLineItems]
	);

	const CustomRow = (props) => {
		const id = props['data-row-key'];
		const record = getRecordFromTargetCollection(id);
		const message = getDisabledMessage({
			record,
			canCreatePO: true,
			selectedVendor,
			selectedSL,
			selectedLocation,
		});
		return message ? (
			<Tooltip title={message} placement="topLeft">
				<tr {...props} />
			</Tooltip>
		) : (
			<tr {...props} />
		);
	};

	useEffect(() => {
		const lineItems = [...selectedPartRows, ...selectedEquipmentRows];
		if (lineItems.length > 0) {
			const record = lineItems.find((_) => !!getLineItemVendor(_).id) || lineItems[0];
			setSelectedVendor({
				...getLineItemVendor(record),
			});
			setSelectedSL(nullSafeGetOrElse('stockLocation', record, {}));
			setSelectedLocation(nullSafeGetOrElse('location', record, {}));
		} else {
			setSelectedVendor({});
			setSelectedSL({});
			setSelectedLocation({});
		}
	}, [selectedEquipmentRows, selectedPartRows]);

	const handlePartSelectChange = useCallback((selectedPartRowKeys, selectedRows) => {
		setSelectedPartRowKeys(selectedPartRowKeys);
		setSelectedPartRows(selectedRows);
	}, []);

	const handleEquipmentSelectChange = useCallback((selectedEquipmentRowKeys, selectedRows) => {
		setSelectedEquipmentRowKeys(selectedEquipmentRowKeys);
		setSelectedEquipmentRows(selectedRows);
	}, []);

	const handlePartAddToCart = useCallback(() => {
		const newPOLineItems = selectedPartRows.map((requestedPart) => ({
			partCost:
				requestedPart.partCost !== undefined ? parseFloat(requestedPart.partCost) : undefined,
			partCatalog: requestedPart.partCatalog,
			partQuantity: requestedPart.partQuantity,
			partId: requestedPart.partId,
			part: requestedPart.part,
			prLineItemIds: [requestedPart.id],
			warrantyAssetId: nullSafeGet('supplierPurchaseRequest.warrantyAssetId', requestedPart),
			warrantyAsset: nullSafeGet('supplierPurchaseRequest.warrantyAsset', requestedPart),
			supplierPurchaseRequestLineItems: [requestedPart],
		}));
		const combinedPOLineItems = newPOLineItems.concat(poPartLineItemsInCart);
		const recordsByPartId = combinedPOLineItems.reduce((acc, el) => {
			const combinedEl = acc[el.partId];
			if (combinedEl) {
				acc[el.partId] = {
					...combinedEl,
					...(el.warrantyAssetId && { warrantyAssetId: el.warrantyAssetId }),
					...(el.warrantyAsset && { warrantyAsset: el.warrantyAsset }),
					partQuantity: el.partQuantity + combinedEl.partQuantity,
					partCost: getTotalValue(el.partCost, combinedEl.partCost),
					prLineItemIds: combinedEl.prLineItemIds.concat(el.prLineItemIds),
					supplierPurchaseRequestLineItems: combinedEl.supplierPurchaseRequestLineItems.concat(
						el.supplierPurchaseRequestLineItems
					),
				};
			} else {
				acc[el.partId] = el;
			}
			return acc;
		}, {});
		const reducedPOLineItems = getObjectValues(recordsByPartId);
		setSelectedPartRowKeys([]);
		setPoPartLineItemsInCart(reducedPOLineItems);
	}, [poPartLineItemsInCart, selectedPartRows]);

	const handleRemovePartFromCart = useCallback((removedPartId) => {
		setPoPartLineItemsInCart((_) => _.filter((lineItem) => lineItem.partId !== removedPartId));
	}, []);

	const handleRemoveEquipmentFromCart = useCallback((removedEquipmentId) => {
		setPoEquipmentLineItemsInCart((_) =>
			_.filter((lineItem) => lineItem.equipmentTypeId !== removedEquipmentId)
		);
	}, []);

	const handleEditPart = useCallback((record) => {
		setPoPartLineItemsInCart((_) =>
			_.map((lineItem) => (lineItem.partId === record.partId ? record : lineItem))
		);
	}, []);

	const handleEditEquipment = useCallback((record) => {
		setPoEquipmentLineItemsInCart((_) =>
			_.map((lineItem) => (lineItem.equipmentTypeId === record.equipmentTypeId ? record : lineItem))
		);
	}, []);

	const removeLineItemCosts = useCallback(() => {
		setPoPartLineItemsInCart((_) =>
			_.map((lineItem) => ({
				...lineItem,
				partCost: undefined,
			}))
		);
		setPoEquipmentLineItemsInCart((_) =>
			_.map((lineItem) => ({
				...lineItem,
				equipmentCost: undefined,
			}))
		);
	}, []);

	const handleStartCheckingOut = useCallback(() => setCheckingOut(true), []);

	const handleEquipmentAddToCart = useCallback(() => {
		const newPOLineItems = selectedEquipmentRows.map((requestedEquipment) => ({
			equipmentCost:
				requestedEquipment.equipmentCost !== undefined
					? parseFloat(requestedEquipment.equipmentCost)
					: undefined,
			equipmentCatalog: requestedEquipment.equipmentCatalog,
			equipmentQuantity: requestedEquipment.equipmentQuantity,
			equipmentTypeId: requestedEquipment.equipmentTypeId,
			equipmentType: requestedEquipment.equipmentType,
			prLineItemIds: [requestedEquipment.id],
			warrantyAssetId: nullSafeGet('supplierPurchaseRequest.warrantyAssetId', requestedEquipment),
			warrantyAsset: nullSafeGet('supplierPurchaseRequest.warrantyAsset', requestedEquipment),
			supplierPurchaseRequestLineItems: [requestedEquipment],
		}));
		const combinedPOLineItems = newPOLineItems.concat(poEquipmentLineItemsInCart);
		const recordsByEquipmentId = combinedPOLineItems.reduce((acc, el) => {
			const combinedEl = acc[el.equipmentTypeId];
			if (combinedEl) {
				acc[el.equipmentTypeId] = {
					...combinedEl,
					...(el.warrantyAssetId && { warrantyAssetId: el.warrantyAssetId }),
					...(el.warrantyAsset && { warrantyAsset: el.warrantyAsset }),
					equipmentQuantity: el.equipmentQuantity + combinedEl.equipmentQuantity,
					equipmentCost: getTotalValue(el.equipmentCost, combinedEl.equipmentCost),
					prLineItemIds: combinedEl.prLineItemIds.concat(el.prLineItemIds),
					supplierPurchaseRequestLineItems: combinedEl.supplierPurchaseRequestLineItems.concat(
						el.supplierPurchaseRequestLineItems
					),
				};
			} else {
				acc[el.equipmentTypeId] = el;
			}
			return acc;
		}, {});
		const reducedPOLineItems = getObjectValues(recordsByEquipmentId);
		setSelectedEquipmentRowKeys([]);
		setPoEquipmentLineItemsInCart(reducedPOLineItems);
	}, [poEquipmentLineItemsInCart, selectedEquipmentRows]);

	const prPartLineItemColumns = useMemo(
		() => [
			{
				title: 'Part',
				dataIndex: 'partId',
				render: (text, record) => (
					<PurchaseRequestPartLineItemRowDisplay
						titleLength={24}
						purchaseRequestPartLineItem={record}
					/>
				),
			},
			{
				title: 'Vendor',
				dataIndex: ['partCatalog', 'partEquipmentVendor', 'name'],
			},
			{
				title: 'Location',
				render: (_, record) => (
					<LocationStockLocationNameWithType
						record={record}
						backLinkText={'Back to Purchase Orders'}
					/>
				),
			},
			{
				title: 'Cost',
				dataIndex: 'partCost',
				render: (_) => renderCurrency(currentUser)(_),
			},
		],
		[currentUser]
	);

	const prPartLineItemColumns2 = useMemo(
		() => [
			{
				dataIndex: 'partId',
				render: (text, record) => (
					<PurchaseRequestPartLineItemRowDisplay
						titleLength={20}
						purchaseRequestPartLineItem={record}
					/>
				),
			},
			{
				title: 'Cost',
				dataIndex: 'partQuantity',
				render: (amount, record) => renderCurrency(currentUser)(record.partCost),
			},
			{
				title: 'Actions',
				dataIndex: 'id',
				render: (id, record) => (
					<MinusCircleOutlined
						translate=""
						className="dynamic-delete-button"
						onClick={() => handleRemovePartFromCart(record.partId)}
					/>
				),
			},
		],
		[currentUser, handleRemovePartFromCart]
	);

	const prEquipmentLineItemColumns = useMemo(
		() => [
			{
				title: 'Equipment Type',
				dataIndex: 'equipmentTypeNumber',
				render: (text, record) => (
					<PurchaseRequestEquipmentLineItemRowDisplay
						titleLength={24}
						purchaseRequestEquipmentLineItem={record}
					/>
				),
			},
			{
				title: 'Vendor',
				dataIndex: ['equipmentCatalog', 'partEquipmentVendor', 'name'],
			},
			{
				title: 'Location',
				render: (_, record) => (
					<LocationStockLocationNameWithType
						record={record}
						backLinkText={'Back to Purchase Orders'}
					/>
				),
			},
			{
				title: 'Cost',
				dataIndex: 'equipmentCost',
				render: (_) => renderCurrency(currentUser)(_),
			},
		],
		[currentUser]
	);

	const prEquipmentLineItemColumns2 = useMemo(
		() => [
			{
				title: 'Equipment Type ID',
				dataIndex: 'equipmentTypeId',
				render: (text, record) => (
					<PurchaseRequestEquipmentLineItemRowDisplay
						titleLength={20}
						purchaseRequestEquipmentLineItem={record}
					/>
				),
			},
			{
				title: 'Cost',
				dataIndex: 'partQuantity',
				render: (amount, record) => renderCurrency(currentUser)(record.equipmentCost),
			},
			{
				title: 'Actions',
				dataIndex: 'id',
				render: (id, record) => (
					<MinusCircleOutlined
						translate=""
						className="dynamic-delete-button"
						onClick={() => handleRemoveEquipmentFromCart(record.equipmentTypeId)}
					/>
				),
			},
		],
		[currentUser, handleRemoveEquipmentFromCart]
	);

	const prPartLineItemIdsInCartArr = poPartLineItemsInCart.reduce((acc, el) => {
		return acc.concat(el.prLineItemIds);
	}, []);
	const prPartLineItemIdsInCart = new Set(prPartLineItemIdsInCartArr);

	const prEquipmentLineItemIdsInCartArr = poEquipmentLineItemsInCart.reduce((acc, el) => {
		return acc.concat(el.prLineItemIds);
	}, []);
	const prEquipmentLineItemIdsInCart = new Set(prEquipmentLineItemIdsInCartArr);

	const rowPartSelection = {
		selectedPartRowKeys,
		onChange: handlePartSelectChange,
		getCheckboxProps: (record) => ({
			disabled:
				isPRLineItemSelectionDisabled({
					record,
					selectedVendor,
					selectedSL,
					selectedLocation,
				}) || prPartLineItemIdsInCart.has(record.id),
		}),
	};

	const rowEquipmentSelection = {
		selectedEquipmentRowKeys,
		onChange: handleEquipmentSelectChange,
		getCheckboxProps: (record) => ({
			disabled:
				(nullSafeGet('id', getLineItemVendor(record)) &&
					nullSafeGet('id', selectedVendor) &&
					getLineItemVendor(record).id !== nullSafeGet('id', selectedVendor)) ||
				(nullSafeGet('id', selectedSL) &&
					nullSafeGet('stockLocation.id', record) !== nullSafeGet('id', selectedSL)) ||
				(nullSafeGet('id', selectedLocation) &&
					nullSafeGet('location.id', record) !== nullSafeGet('id', selectedLocation)) ||
				prEquipmentLineItemIdsInCart.has(record.id),
		}),
	};

	return (
		<Content style={{ padding: 8 }}>
			<>
				<LogOnMountWithStandardEventProperties eventType="visited new purchase order page" />
				<Row gutter={16} className="rowSpacing">
					<Col span={24}>
						<SubnavBar
							left={
								<h4 style={{ margin: '16px 0 24px' }}>
									{checkingOut ? `Create Purchase Order` : `Add Requested Items to Cart`}
								</h4>
							}
							right={null}
						/>
					</Col>
				</Row>
				{!checkingOut ? (
					<div>
						{partsAvailable && (
							<Row gutter={16} className="rowSpacing">
								<Col span={poPartLineItemsInCart.length > 0 ? 14 : 24}>
									<SubnavBar
										style={{ marginBottom: 12 }}
										left={<h5 style={{ marginBottom: 0 }}>Requested Parts</h5>}
										right={
											selectedPartRowKeys.length > 0 ? (
												<Button onClick={handlePartAddToCart} type="primary">
													Add parts to cart
												</Button>
											) : null
										}
									/>
									<Card>
										<PaginatedReduxTable
											tableLayoutFixed={true}
											emptyState={
												<EmptyState
													graphic={
														<img
															style={{ marginBottom: 8 }}
															src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
															alt="No Part requested."
														/>
													}
													headline={'No Part requested.'}
												/>
											}
											collectionName="purchase_request_line_items"
											targetCollectionName={PART_PR_LINE_ITEMS_TC}
											columns={prPartLineItemColumns}
											keyAccessor={(el) => el.id}
											initialFilters={{ ...PRLineItemParams, isEquipmentLine: false }}
											initialPagination={{
												current: 1,
												pageSize: 10,
											}}
											components={{
												body: {
													row: CustomRow,
												},
											}}
											rowSelection={rowPartSelection}
											fetchData={purchaseRequestLineItemsRestCrudThunksForSupplier.read}
										/>
									</Card>
								</Col>
								{poPartLineItemsInCart.length > 0 ? (
									<Col span={10}>
										<Affix offsetTop={24} style={{ right: 16 }}>
											<SubnavBar
												style={{ marginBottom: 12 }}
												left={<h5 style={{ marginBottom: 0 }}>Parts in Cart</h5>}
												right={
													<Button type="primary" onClick={handleStartCheckingOut}>
														Create Purchase Order
													</Button>
												}
											/>
											<Card>
												{selectedVendor && selectedVendor.name ? (
													<div className="rowSpacing">
														<Col span={24}>
															<h5 style={{ marginBottom: 0 }}>{selectedVendor.name}</h5>
														</Col>
													</div>
												) : null}
												<Table
													columns={prPartLineItemColumns2}
													rowKey={(part) => part['partId']}
													dataSource={poPartLineItemsInCart}
													showHeader={false}
													pagination={false}
													loading={false}
												/>
											</Card>
										</Affix>
									</Col>
								) : null}
							</Row>
						)}
						{equipmentsAvailable && (
							<Row gutter={16} className="rowSpacing">
								<Col span={poEquipmentLineItemsInCart.length > 0 ? 14 : 24}>
									<SubnavBar
										style={{ marginBottom: 12 }}
										left={<h5 style={{ marginBottom: 0 }}>Requested Equipment</h5>}
										right={
											selectedEquipmentRowKeys.length > 0 ? (
												<Button onClick={handleEquipmentAddToCart} type="primary">
													Add equipment to cart
												</Button>
											) : null
										}
									/>
									<Card>
										<PaginatedReduxTable
											tableLayoutFixed={true}
											emptyState={
												<EmptyState
													graphic={
														<img
															style={{ marginBottom: 8 }}
															src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
															alt="No Equipment requested."
														/>
													}
													headline={'No Equipment requested.'}
												/>
											}
											collectionName="purchase_request_line_items"
											targetCollectionName={EQUIPMENT_PR_LINE_ITEMS_TC}
											columns={prEquipmentLineItemColumns}
											keyAccessor={(el) => el.id}
											initialFilters={{ ...PRLineItemParams, isEquipmentLine: true }}
											initialPagination={{
												current: 1,
												pageSize: 10,
											}}
											components={{
												body: {
													row: CustomRow,
												},
											}}
											rowSelection={rowEquipmentSelection}
											fetchData={purchaseRequestLineItemsRestCrudThunksForSupplier.read}
										/>
									</Card>
								</Col>
								{poEquipmentLineItemsInCart.length > 0 ? (
									<Col span={10}>
										<Affix offsetTop={24} style={{ right: 16 }}>
											<SubnavBar
												style={{ marginBottom: 12 }}
												left={<h5 style={{ marginBottom: 0 }}>Equipment in Cart</h5>}
												right={
													poPartLineItemsInCart.length === 0 ? (
														<Button type="primary" onClick={handleStartCheckingOut}>
															Create Purchase Order
														</Button>
													) : null
												}
											/>
											<Card>
												{selectedVendor && selectedVendor.name ? (
													<div className="rowSpacing">
														<Col span={24}>
															<h5 style={{ marginBottom: 0 }}>{selectedVendor.name}</h5>
														</Col>
													</div>
												) : null}
												<Table
													columns={prEquipmentLineItemColumns2}
													rowKey={(equipment) => equipment['equipmentTypeId']}
													dataSource={poEquipmentLineItemsInCart}
													showHeader={false}
													pagination={false}
													loading={false}
												/>
											</Card>
										</Affix>
									</Col>
								) : null}
							</Row>
						)}
						{checkingAvailableItems ? (
							<div className="flex flex-row justify-center">
								<Spin />
							</div>
						) : !partsAvailable && !equipmentsAvailable ? (
							<EmptyState
								graphic={
									<img
										style={{ marginBottom: 8 }}
										src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
										alt="No Equipment requested."
									/>
								}
								headline={'No Requested items to display.'}
							/>
						) : null}
					</div>
				) : null}
				{checkingOut ? (
					<Row gutter={16} className="rowSpacing">
						<Col span={24}>
							<PurchaseOrderForm
								formData={{
									partSupplierPurchaseOrderLineItems: poPartLineItemsInCart,
									equipmentSupplierPurchaseOrderLineItems: poEquipmentLineItemsInCart,
									partEquipmentVendor: selectedVendor,
									partEquipmentVendorId: nullSafeGet('id', selectedVendor),
									stockLocationId: nullSafeGet('id', selectedSL),
									locationId: nullSafeGet('id', selectedLocation),
								}}
								handleUpdateLineItems={(poPartLineItemsInCart, poEquipmentLineItemsInCart) => {
									setPoPartLineItemsInCart(poPartLineItemsInCart);
									setPoEquipmentLineItemsInCart(poEquipmentLineItemsInCart);
								}}
								handleRemovePartFromCart={handleRemovePartFromCart}
								handleEditPart={handleEditPart}
								handleRemoveEquipmentFromCart={handleRemoveEquipmentFromCart}
								handleEditEquipment={handleEditEquipment}
								removeLineItemCosts={removeLineItemCosts}
								onSuccess={(rec) => history.push(`/supplier/purchaseOrders/detail/${rec.id}`)}
							/>
						</Col>
					</Row>
				) : null}
			</>
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	purchaseRequestLineItems: state.purchase_request_line_items,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	fetchPRLineItems: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			purchaseRequestLineItemsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewPurchaseOrderPage));

import { crudActionCreatorsGenerator } from './crud_action_creators_generator';
import { crudActionConstantsGenerator } from './crud_action_constants_generator';

export const FETCH_BUYER_APPROVAL_TIERS = 'FETCH_BUYER_APPROVAL_TIERS';
export function fetchBuyerApprovalTiers() {
	return {
		type: FETCH_BUYER_APPROVAL_TIERS,
	};
}

export const FETCH_BUYER_APPROVAL_TIERS_SUCCESS = 'FETCH_BUYER_APPROVAL_TIERS_SUCCESS';
export function fetchBuyerApprovalTiersSuccess(records) {
	return {
		type: FETCH_BUYER_APPROVAL_TIERS_SUCCESS,
		records,
	};
}

export const FETCH_BUYER_APPROVAL_TIERS_FAILURE = 'FETCH_BUYER_APPROVAL_TIERS_FAILURE';
export function fetchBuyerApprovalTiersFailure(errors) {
	return {
		type: FETCH_BUYER_APPROVAL_TIERS_FAILURE,
		errors,
	};
}
export const PATCH_BUYER_APPROVAL_TIERS = 'PATCH_BUYER_APPROVAL_TIERS';
export function patchBuyerApprovalTiers() {
	return {
		type: PATCH_BUYER_APPROVAL_TIERS,
	};
}

export const PATCH_BUYER_APPROVAL_TIERS_SUCCESS = 'PATCH_BUYER_APPROVAL_TIERS_SUCCESS';
export function patchBuyerApprovalTiersSuccess() {
	return {
		type: PATCH_BUYER_APPROVAL_TIERS_SUCCESS,
	};
}

export const PATCH_BUYER_APPROVAL_TIERS_FAILURE = 'PATCH_BUYER_APPROVAL_TIERS_FAILURE';
export function patchBuyerApprovalTiersFailure() {
	return {
		type: PATCH_BUYER_APPROVAL_TIERS_FAILURE,
	};
}

export const FETCH_SUPPLIER_APPROVAL_TIERS = 'FETCH_SUPPLIER_APPROVAL_TIERS';
export function fetchSupplierApprovalTiers() {
	return {
		type: FETCH_SUPPLIER_APPROVAL_TIERS,
	};
}

export const FETCH_SUPPLIER_APPROVAL_TIERS_SUCCESS = 'FETCH_SUPPLIER_APPROVAL_TIERS_SUCCESS';
export function fetchSupplierApprovalTiersSuccess(records) {
	return {
		type: FETCH_SUPPLIER_APPROVAL_TIERS_SUCCESS,
		records,
	};
}

export const FETCH_SUPPLIER_APPROVAL_TIERS_FAILURE = 'FETCH_SUPPLIER_APPROVAL_TIERS_FAILURE';
export function fetchSupplierApprovalTiersFailure(errors) {
	return {
		type: FETCH_SUPPLIER_APPROVAL_TIERS_FAILURE,
		errors,
	};
}

export const APPROVAL_HIERARCHIES_CRUD_ACTION_CONSTANTS =
	crudActionConstantsGenerator('APPROVAL_HIERARCHIES');
export const APPROVAL_HIERARCHIES_CRUD_ACTION_CREATORS = crudActionCreatorsGenerator(
	APPROVAL_HIERARCHIES_CRUD_ACTION_CONSTANTS
);
export const APPROVAL_RULES_CRUD_ACTION_CONSTANTS = crudActionConstantsGenerator('APPROVAL_RULES');
export const APPROVAL_RULES_CRUD_ACTION_CREATORS = crudActionCreatorsGenerator(
	APPROVAL_RULES_CRUD_ACTION_CONSTANTS
);

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Modal, Alert, Button, Select, message, Steps } from 'antd';
import BackButton from '../back_button/BackButton';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { imageUploadValidation, normFile } from '../../utils/ImageUtils';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import {
	checkIsWarrantyWorkOrder,
	getCurrency,
	getImageCountErrorRules,
	getWarrantyStatus,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';

import { locationsRestCrudThunksForBuyer } from '../../thunks/locations_thunks';
import { debounce } from '../../utils/PerformanceUtils';
import { assetsRestCrudThunksForBuyer } from '../../thunks/assets_thunks';
import { problemTypesRestCrudThunksForBuyer } from '../../thunks/problem_types_thunks';
import { getProtectedImageUriForBuyer } from '../../utils/FileAccessUtils';
import { spendCategoriesRestCrudThunksForBuyer } from '../../thunks/spend_categories_thunks';
import { workOrderAttributesRestCrudThunksForBuyer } from '../../thunks/work_order_attributes_thunks';
import { workOrderPrioritiesRestCrudThunksForBuyer } from '../../thunks/work_order_priorities_thunks';
import { workOrdersRestCrudThunksForBuyer } from '../../thunks/work_orders_thunks';
import moment from 'moment';
import { TwoLineList } from '../two_line_list/TwoLineList';
import { ProtectedImageThumbnails } from '../protected_image_thumbnails/ProtectedImageThumbnails';
import { ROLE_TYPES } from '../../utils/DataConstants';
import {
	fetchSupplierFacilitiesInPrivateNetworkForBuyer,
	supplierFacilitiesRestCrudThunksForBuyer,
} from '../../thunks/supplier_facilities_thunks';
import FileUploader from '../file_uploader/FileUploader';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { WORK_ORDER_STATUSES } from '../../constants/WorkOrderStatuses';
import { TextButton } from '../text_button/TextButton';
import BuyerLocationUpdateForm from '../buyer_location_update_form/BuyerLocationUpdateForm';
import { buyerRoleUpdateUser } from '../../thunks/buyer_contacts_thunks';
import { buyersRestCrudThunksForBuyer } from '../../thunks/buyers_thunks';
import { retrieveCachedUserDetails } from '../../thunks/session_thunks';
import OWRadioGroup from '../ow_radio_group/OWRadioGroup';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { getExchangeRate } from '../../thunks/currencies_thunks';
import AssetNumberDisplay from '../asset_row_display/AssetNumberDisplay';
import OWUpload from '../OWUpload';
import { getBuyerCompanySettingValue } from '../../utils/AuthUtils';

const WORK_ORDER_FORM_LOCATIONS_AUTOCOMPLETE = 'WORK_ORDER_FORM_LOCATIONS_AUTOCOMPLETE';
const WORK_ORDER_FORM_ASSETS_AUTOCOMPLETE = 'WORK_ORDER_FORM_ASSETS_AUTOCOMPLETE';
const WORK_ORDER_FORM_PROBLEM_TYPES_AUTOCOMPLETE = 'WORK_ORDER_FORM_PROBLEM_TYPES_AUTOCOMPLETE';
const WORK_ORDER_FORM_SPEND_CATEGORIES_AUTOCOMPLETE =
	'WORK_ORDER_FORM_SPEND_CATEGORIES_AUTOCOMPLETE';
const WORK_ORDER_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE =
	'WORK_ORDER_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE';
const WORK_ORDER_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE =
	'WORK_ORDER_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE';

const Step = Steps.Step;

interface ServiceRequestFormProps extends FormComponentProps {
	history: any;
	onSubmit: any;
	onCancel: any;
	redirectForwardUrl?: string;
	onSuccess?: any;
	creating: boolean;
	updating: boolean;
	formData: any;
	createErrors: any[];
	updateErrors: any[];
	fetchLocation: any;
	fetchLocations: any;
	fetchMultipleLocations: any;
	companyConfig: any;
	fetchAsset: any;
	fetchAssets: any;
	fetchMultipleAssets: any;
	locationsFetching: any;
	locations: any;
	assetsFetching: any;
	assets: any;
	fetchProblemType: any;
	fetchProblemTypes: any;
	fetchMultipleProblemTypes: any;
	problemTypesFetching: any;
	problemTypes: any;
	fetchSpendCategory: any;
	fetchSpendCategories: any;
	fetchMultipleSpendCategories: any;
	spendCategories: any;
	spendCategoriesFetching: any;
	fetchWorkOrderAttributes: any;
	workOrderPriorities: any;
	fetchWorkOrderPriority: any;
	fetchWorkOrderPriorities: any;
	fetchMultipleWorkOrderPriorities: any;
	workOrderPrioritiesFetching: any;
	supplierFacilities: any;
	supplierFacilitiesFetching: any;
	fetchSupplierFacility: any;
	fetchMultipleSupplierFacilities: any;
	fetchSupplierFacilitiesInPrivateNetwork: any;
	currentUser: any;
	submitServiceRequestForm: any;
	roleUpdateUser: any;
	fetchBuyer: any;
	fetchExchangeRate: any;
}

class NewServiceRequestForm extends React.Component<ServiceRequestFormProps, any> {
	formRefs: any;

	constructor(props) {
		super(props);
		this.state = {
			activeStep: 1,
			totalSteps: 2,
			userContact: {},
			previewVisible: false,
			uploadLoading: false,
			previewImage: false,
			locations: [],
			location: null,
			asset: null,
			problemTypes: [],
			problemType: null,
			spendCategory: null,
			inputValues: {},
			nteAmount: 0,
			workOrderPriorityId: null,
			workOrderPriority: null,
			photoInfo: [],
			allPhotos: [],
			supplier: null,
			assetSelectionDisabled: true,
			locationUpdateVisible: false,
			industryId: 1,
			exchangeRate: 1,
			numPicturesRequired: 0,
			updatingLocation: false,
		};
		this.formRefs = {
			locationUpdateForm: null,
		};
		this.goNext = this.goNext.bind(this);
		this.goPrev = this.goPrev.bind(this);
		this.handleBuyerLocationUpdateSubmit = this.handleBuyerLocationUpdateSubmit.bind(this);
		this.handleBuyerLocationUpdateCancel = this.handleBuyerLocationUpdateCancel.bind(this);
	}

	componentDidMount() {
		const {
			formData,
			fetchLocation,
			fetchLocations,
			fetchAsset,
			fetchAssets,
			fetchBuyer,
			fetchProblemType,
			fetchProblemTypes,
			fetchSpendCategories,
			fetchWorkOrderPriority,
			fetchWorkOrderPriorities,
			fetchSupplierFacilitiesInPrivateNetwork,
			currentUser,
		} = this.props;
		const locationId = formData.locationId;
		const assetId = formData.assetId;
		const problemTypeId = formData.problemTypeId;
		const spendCategoryId = formData.spendCategoryId;
		const workOrderPriorityId = formData.workOrderPriorityId;

		fetchLocations({}).then((locations) => this.setState({ locations: locations }));
		// TODO: HYDRATE THESE IN THE ROUTE AND REMOVE THIS SHIT
		if (locationId) {
			fetchLocation(locationId).then((l) => this.setState({ location: l }));
		}

		locationId ? fetchAsset({ locationId: locationId }) : fetchAssets({});

		if (assetId) {
			fetchAsset(assetId).then((a) => this.setState({ asset: a }));
		}

		if (locationId || assetId) {
			locationId && assetId
				? fetchProblemTypes({ locationId: locationId, assetId: assetId }).then((problemTypes) =>
						this.setState({ problemTypes: problemTypes })
				  )
				: fetchProblemType({ locationId: locationId });
		} else {
			fetchProblemTypes({}).then((problemTypes) => this.setState({ problemTypes: problemTypes }));
		}

		if (problemTypeId) {
			fetchProblemType(problemTypeId).then((problemType) =>
				this.setState({
					problemType,
					numPicturesRequired: nullSafeGetOrElse(
						'serviceRequestNumPicturesRequired',
						problemType,
						0
					),
				})
			);
		}

		fetchSpendCategories({});

		fetchWorkOrderPriorities({});

		if (workOrderPriorityId) {
			fetchWorkOrderPriority(workOrderPriorityId).then((w) =>
				this.setState({ workOrderPriority: w })
			);
		}

		if (spendCategoryId && locationId) {
			fetchSupplierFacilitiesInPrivateNetwork({ spendCategoryId, locationId }).then((s) =>
				s.length > 0 ? this.setState({ supplier: s[0] }) : this.setState({ supplier: null })
			);
		} else {
			fetchSupplierFacilitiesInPrivateNetwork({ spendCategoryId, locationId });
		}

		fetchBuyer(nullSafeGet('facility.buyerCompanyId', currentUser)).then((buyer) => {
			this.setState({ industryId: buyer.industryId });
		});
	}

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	refreshLocations = () => {
		const { fetchLocations } = this.props;
		fetchLocations({}, WORK_ORDER_FORM_LOCATIONS_AUTOCOMPLETE).then((locations) =>
			this.setState({ locations: locations })
		);
	};

	provideLocationAccess = (record) => () => {
		const { locations } = this.state;
		this.setState({
			locationUpdateVisible: true,
			userContact: {
				contact: {
					...record,
				},
				role: record['roles'],
				locationIds: locations.map((l) => l.id),
			},
		});
	};

	handleBuyerLocationUpdateSubmit() {
		const { roleUpdateUser } = this.props;
		const form = this.formRefs['locationUpdateForm'].props.form;
		this.setState({ updatingLocation: true });
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			const updateRole = {
				userId: values.email,
				roles: [values.roles],
				locationIds: values.locationIds,
			};

			roleUpdateUser(updateRole)
				.then(() => {
					this.setState({ locationUpdateVisible: false });
					form.resetFields();
					this.refreshLocations();
					message.success(`Location Access Granted`);
				})
				.catch((err) => message.error(err))
				.finally(() => this.setState({ updatingLocation: false }));
		});
	}

	handleBuyerLocationUpdateCancel(e) {
		this.setState({
			locationUpdateVisible: false,
		});
	}

	handleSubmit = () => {
		const { form, history, redirectForwardUrl, onSuccess, currentUser, submitServiceRequestForm } =
			this.props;
		const { spendCategoryId, exchangeRate, supplier, location } = this.state;
		form.validateFields((err, values) => {
			if (!err) {
				// put image data into proper format to persist
				const picturesInfo = this.state.allPhotos.map((p) => {
					const fileName = nullSafeGetOrElse('response.data.fileName', p, '');
					const fileId = nullSafeGetOrElse('response.data.fileId', p, '');
					return `${fileId}/${fileName}`;
				});

				const contractorDetails = {
					contractorContacts: [],
				};
				const locationCurrencyId = getCurrency({ location }).id;
				const supplierCurrencyId = getCurrency({ supplier }).id;

				let workOrder = {
					...values,
					title: values.title,
					description: values.additionalInfo,
					problemTypeId: parseInt(values.problemTypeId, 10),
					woPriorityId: parseInt(values.woPriorityId, 10),
					spendCategoryId: parseInt(spendCategoryId, 10),
					locationId: parseInt(values.locationId, 10),
					assetId: values.assetId ? parseInt(values.assetId, 10) : null,
					...(values.assetId && {
						isAssetDown: values.isAssetDown === 'true' ? true : false,
					}),
					status: WORK_ORDER_STATUSES.pendingApproval,
					displayStatus: 'pending',
					images: picturesInfo,

					currencyExchangeRateLocationToSupplier: exchangeRate,
					nte: nullSafeGetOrElse('nte', values, 0) * exchangeRate,
					currencyId: supplierCurrencyId,
					nteInLocationCurrency: nullSafeGet('nte', values),
					locationCurrencyId: locationCurrencyId,
					//nte: values.NTEAmount ? parseFloat(values.NTEAmount) : undefined,
					createdBy: currentUser.email,
					contractorDetails: contractorDetails,
					buyerAttachments: values.buyerAttachments,
					notes: [],
					isWarranty: checkIsWarrantyWorkOrder(
						nullSafeGet('asset.warranties.0', this.state),
						nullSafeGet('id', this.state.supplier)
					),
				};

				submitServiceRequestForm(workOrder).then((record) => {
					if (redirectForwardUrl) {
						history.push(redirectForwardUrl);
					}
					if (onSuccess) {
						onSuccess(record);
					}
				});
			}
		});
	};

	goNext(validationSteps) {
		const { form } = this.props;
		const { activeStep, totalSteps } = this.state;
		form.validateFields(validationSteps, (err) => {
			if (err) {
				return;
			}
			if (activeStep >= totalSteps) {
				this.handleSubmit();
			} else {
				this.setState({ activeStep: activeStep + 1 });
			}
		});
	}

	goPrev() {
		const { activeStep } = this.state;
		if (activeStep === 1) {
			return false;
		}
		this.setState({ activeStep: activeStep - 1 });
		return true;
	}

	handlePreview = (obj) => {
		let thumbUrl = null;
		let fullUrl = null;
		let photoInfo = null;
		if (obj.response.data) {
			const imgFileId = obj.response.data.fileId;
			const imgFileName = obj.response.data.fileName;
			thumbUrl = getProtectedImageUriForBuyer(imgFileId, imgFileName, 100, 100);
			fullUrl = getProtectedImageUriForBuyer(imgFileId, imgFileName, 800, 800);
			photoInfo = {
				uid: imgFileId,
				size: 1,
				name: imgFileName,
				filename: imgFileName,
				url: fullUrl,
				status: 'done',
				thumbUrl: thumbUrl,
				response: { data: { fileName: imgFileName, fileId: imgFileId } },
				type: 'image/jpeg',
			};
		}

		this.setState({
			photoInfo: [photoInfo],
			previewImage: fullUrl || thumbUrl,
			previewVisible: true,
		});
	};

	isAreaFilterAllowed = () => {
		const { companyConfig, currentUser } = this.props;
		return getBuyerCompanySettingValue(ROLE_TYPES.BUYER)(
			'assetConfig.allowAreaAssociationWithAssets',
			companyConfig,
			currentUser,
			false
		);
	};

	refreshAssets = (search = '') => {
		const { locationId, areaId, problemTypeId } = this.state;
		const areaFilterAllowed = this.isAreaFilterAllowed();
		const { fetchAssets } = this.props;
		fetchAssets({
			search: search || undefined,
			locationId,
			problemTypeId,
			...(areaFilterAllowed && areaId && { areaIdOrNull: areaId }),
		}).then((assets) =>
			assets.length > 0 ? this.setState({ assetSelectionDisabled: false }) : null
		);
	};

	handleCancel = () => this.setState({ previewVisible: false });

	handleLocationChange = (locationId) => {
		const { fetchLocation, fetchProblemTypes } = this.props;
		if (locationId) {
			fetchLocation(locationId).then((loc) =>
				this.setState({
					location: loc,
				})
			);
			this.refreshAssets();
			fetchProblemTypes({ locationId: locationId });
		}
	};

	debouncedSearchAssets = debounce(
		(searchText) => {
			searchText = searchText && searchText.trim();
			this.refreshAssets(searchText);
		},
		500,
		false
	);

	searchAssets = (searchText) => {
		this.debouncedSearchAssets(searchText);
	};

	handleAssetChange = (assetId) => {
		const { fetchAsset, fetchSupplierFacilitiesInPrivateNetwork, fetchProblemTypes, form } =
			this.props;
		const { location } = this.state;
		if (assetId) {
			fetchAsset(assetId).then((asset) => {
				fetchSupplierFacilitiesInPrivateNetwork({
					problemTypeId: nullSafeGet('problemType.id', this.state),
					locationId: nullSafeGet('id', this.state.location),
				}).then((s) => {
					const assetWarrantyStatus = getWarrantyStatus(nullSafeGet('warranties.0', asset));
					const assetSupplier = nullSafeGet('warranties.0.provider.supplierFacility', asset);
					const assetWarrantyNTE = nullSafeGet('warranties.0.NTEAmount', asset);
					this.setState(
						{
							asset,
							supplier:
								assetWarrantyStatus === 'Active' && assetSupplier
									? assetSupplier
									: this.state.supplier,
							nte:
								assetWarrantyStatus === 'Active' && assetWarrantyNTE
									? assetWarrantyNTE
									: this.state.nte,
						},
						() => {
							form.setFieldsValue({
								nte: this.state.nte,
								supplierFacilityId: nullSafeGet('supplier.id', this.state),
							});
						}
					);
				});
			});
			fetchProblemTypes({ locationId: location ? location.id : undefined, assetId: assetId });
		}
	};

	debouncedSearchProblemTypes = debounce(
		(searchText) => {
			const { fetchProblemTypes } = this.props;
			const { location, asset } = this.state;
			searchText = searchText && searchText.trim();
			location && asset
				? fetchProblemTypes({
						name: searchText,
						locationId: location.id,
						assetId: asset.id,
				  })
				: location
				? fetchProblemTypes({ name: searchText, locationId: location.id })
				: fetchProblemTypes({ name: searchText });
		},
		500,
		false
	);

	searchProblemTypes = (searchText) => {
		this.debouncedSearchProblemTypes(searchText);
	};

	handleProblemTypeChange = (problemTypeId) => {
		const {
			fetchProblemType,
			fetchWorkOrderAttributes,
			fetchSpendCategory,
			fetchWorkOrderPriority,
			fetchSupplierFacilitiesInPrivateNetwork,
		} = this.props;
		if (problemTypeId) {
			fetchProblemType(problemTypeId).then((problemType) => {
				this.setState({
					problemType,
					numPicturesRequired: nullSafeGetOrElse(
						'serviceRequestNumPicturesRequired',
						problemType,
						0
					),
				});
				if (problemType.spendCategoryId) {
					fetchSpendCategory(problemType.spendCategoryId).then((spendCategory) =>
						this.setState({ spendCategoryId: spendCategory.id, spendCategory: spendCategory })
					);
					if (this.state.location) {
						fetchSupplierFacilitiesInPrivateNetwork({
							spendCategoryId: problemType.spendCategoryId,
							locationId: this.state.location.id,
						}).then((s) => {
							if (s.length > 0) {
								this.setState({ supplier: s[0] });
							} else {
								this.setState({ supplier: null });
							}
						});
					}
				}
				fetchWorkOrderAttributes({ problemTypeId: problemTypeId }).then((workOrderAttributes) => {
					if (workOrderAttributes.length > 0) {
						this.setState({
							nteAmount: workOrderAttributes[0].NTEAmount,
							workOrderPriorityId: workOrderAttributes[0].workOrderPriorityId,
						});
						// if (workOrderAttributes[0].spendCategoryId) {
						//     fetchSpendCategory(workOrderAttributes[0].spendCategoryId).then(spendCategory =>
						//         this.setState({spendCategory: spendCategory})
						//     );
						// }
						if (workOrderAttributes[0].workOrderPriorityId) {
							fetchWorkOrderPriority(workOrderAttributes[0].workOrderPriorityId).then(
								(workOrderPriority) => this.setState({ workOrderPriority: workOrderPriority })
							);
						}
						fetchSupplierFacilitiesInPrivateNetwork({
							spendCategoryId: nullSafeGet('spendCategoryId', problemType),
							locationId: nullSafeGet('id', this.state.location),
						}).then((s) =>
							s.length > 0 ? this.setState({ supplier: s[0] }) : this.setState({ supplier: null })
						);
					}
				});
			});
		}
	};

	handleImageUpdate = (info) => {
		if (info.file.status === 'done') {
			this.setState({ allPhotos: info.fileList });
		} else if (info.file.status === undefined) {
			info.fileList = this.state.allPhotos;
		}
	};

	handleInputValueChangeDebounced = (inputKey) =>
		debounce(
			(inputString) => {
				let newInputValues = this.state.inputValues;
				newInputValues[`${inputKey}`] = inputString;
				this.setState({ inputValues: newInputValues });
			},
			500,
			false
		);

	handleInputValueChange = (inputKey) => (e) => {
		const inputString = e.target.value;
		this.handleInputValueChangeDebounced(inputKey)(inputString);
	};

	handleNTEValueChangeDebounced = () =>
		debounce(
			(nteAmount) => {
				this.setState({ nteAmount: nteAmount });
			},
			500,
			false
		);

	handleNTEValueChange = (e) => {
		const inputString = e.target.value;
		this.handleNTEValueChangeDebounced()(inputString);
	};

	debouncedSearchSpendCategories = debounce(
		(searchText) => {
			const { fetchSpendCategories } = this.props;
			fetchSpendCategories({
				name: searchText && searchText.trim(),
			});
		},
		500,
		false
	);

	searchSpendCategories = (searchText) => {
		this.debouncedSearchSpendCategories(searchText);
	};

	handleSpendCategoryChange = (spendCategoryId) => {
		const {
			fetchWorkOrderAttributes,
			fetchSpendCategory,
			fetchWorkOrderPriority,
			fetchSupplierFacilitiesInPrivateNetwork,
		} = this.props;
		if (spendCategoryId) {
			fetchSpendCategory(spendCategoryId).then((spendCategory) => {
				this.setState({
					spendCategory: spendCategory,
					spendCategoryId: spendCategoryId,
				});
			});
			fetchWorkOrderAttributes({
				problemTypeId: nullSafeGet('problemType.id', this.state),
				spendCategoryId: spendCategoryId,
			}).then((workOrderAttributes) => {
				if (workOrderAttributes.length > 0) {
					this.setState({
						nteAmount: workOrderAttributes[0].NTEAmount,
						workOrderPriorityId: workOrderAttributes[0].workOrderPriorityId,
					});
					if (workOrderAttributes[0].workOrderPriorityId) {
						fetchWorkOrderPriority(workOrderAttributes[0].workOrderPriorityId).then(
							(workOrderPriority) => this.setState({ workOrderPriority: workOrderPriority })
						);
					}
				}
			});
			if (this.state.location) {
				fetchSupplierFacilitiesInPrivateNetwork({
					spendCategoryId: spendCategoryId,
					locationId: this.state.location.id,
				}).then((s) =>
					s.length > 0 ? this.setState({ supplier: s[0] }) : this.setState({ supplier: null })
				);
			}
		}
	};

	debouncedSearchWorkOrderPriorities = debounce(
		(searchText) => {
			const { fetchWorkOrderPriorities } = this.props;
			fetchWorkOrderPriorities({ name: searchText && searchText.trim() });
		},
		500,
		false
	);

	searchWorkOrderPriorities = (searchText) => {
		this.debouncedSearchWorkOrderPriorities(searchText);
	};

	handleWorkOrderPriorityChange = (workOrderPriorityId) => {
		const { fetchWorkOrderPriority } = this.props;
		if (workOrderPriorityId) {
			fetchWorkOrderPriority(workOrderPriorityId).then((workOrderPriority) =>
				this.setState({
					workOrderPriority: workOrderPriority,
					workOrderPriorityId: workOrderPriorityId,
				})
			);
		}
	};

	debouncedSearchSupplierInPrivateNetwork = debounce(
		(searchText) => {
			const { fetchSupplierFacilitiesInPrivateNetwork } = this.props;
			fetchSupplierFacilitiesInPrivateNetwork({
				name: searchText && searchText.trim(),
				spendCategoryId: nullSafeGet('id', this.state.spendCategory),
				locationId: nullSafeGet('id', this.state.location),
			});
		},
		500,
		false
	);

	searchSupplierInPrivateNetwork = (searchText) => {
		this.debouncedSearchSupplierInPrivateNetwork(searchText);
	};

	handleSupplierChange = (supplierFacilityId) => {
		const { fetchSupplierFacility, fetchExchangeRate, currentUser } = this.props;
		const { location } = this.state;
		if (supplierFacilityId) {
			fetchSupplierFacility(supplierFacilityId).then((supplier) => {
				const locationCurrencyId = getCurrency({ location }).id;
				const supplierCurrencyId = getCurrency({ supplier }).id;
				if (locationCurrencyId !== supplierCurrencyId) {
					fetchExchangeRate(supplierCurrencyId, locationCurrencyId, currentUser.contactType).then(
						(rate) =>
							this.setState({
								exchangeRate: nullSafeGetOrElse('conversionRate', rate, 1),
							})
					);
				} else {
					this.setState({ exchangeRate: 1 });
				}

				const nte = checkIsWarrantyWorkOrder(
					nullSafeGet('asset.warranties.0', this.state),
					supplierFacilityId
				)
					? nullSafeGet('asset.warranties.0.NTEAmount', this.state)
					: this.state.nte;
				this.setState({ nte, supplier });
			});
		}
	};

	handleFileAttachmentUploadChange = (newAttachments) => {
		const { form } = this.props;
		form.setFieldsValue({ buyerAttachments: newAttachments });
	};

	render() {
		const {
			createErrors,
			updateErrors,
			creating,
			updating,
			form,
			formData,
			companyConfig,
			locations,
			fetchAssets,
			fetchMultipleAssets,
			assets,
			fetchProblemTypes,
			fetchMultipleProblemTypes,
			problemTypes,
			fetchLocations,
			fetchMultipleLocations,
			fetchSpendCategories,
			fetchMultipleSpendCategories,
			spendCategories,
			fetchWorkOrderPriorities,
			fetchMultipleWorkOrderPriorities,
			workOrderPriorities,
			currentUser,
		} = this.props;

		const { getFieldDecorator, getFieldValue } = form;
		const { activeStep, totalSteps, previewVisible, previewImage } = this.state;
		const isLoading = creating || updating;

		getFieldDecorator('problemTypeId', {
			initialValue: nullSafeGet('problemType.id', this.state),
		});

		const BACKEND_URI = getBackendUri();
		const uploadHeaders = { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token };

		const problemTypePictureCount = nullSafeGetOrElse('state.numPicturesRequired', this, 0);
		const companyConfigPictureCount = nullSafeGetOrElse(
			'config.newServiceRequest.numImagesRequired',
			companyConfig,
			0
		);
		const minPhotosCount = problemTypePictureCount
			? problemTypePictureCount
			: companyConfigPictureCount;

		const imageCountErrorMessage = `You need to upload at least ${minPhotosCount} photo${
			minPhotosCount > 1 ? 's' : ''
		}`;

		const getNextText = (activeStep) => {
			if (activeStep < totalSteps) {
				return 'Next';
			} else if (activeStep === totalSteps) {
				return 'Create Work Order';
			}
			return 'Next';
		};

		const areaFilterAllowed = this.isAreaFilterAllowed();

		const step1 = () => {
			return (
				<div>
					<Form.Item required={true} label="Location">
						{getFieldDecorator('locationId', {
							initialValue: nullSafeGet('location.id', this.state),
							rules: [{ required: true, message: 'This field is required.' }],
						})(
							<OWAsyncSelect
								stateSlice={locations}
								onChange={this.handleLocationChange}
								style={{ maxWidth: 400 }}
								placeholder="Type to search for location"
								targetCollectionName={WORK_ORDER_FORM_LOCATIONS_AUTOCOMPLETE}
								fetchMultiple={(ids, targetCollectionName) => {
									fetchMultipleLocations(ids, targetCollectionName);
								}}
								fetchData={(
									searchText,
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								) => {
									fetchLocations(
										{ name: searchText },
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									);
								}}
								renderRecord={(location) => (
									<Select.Option key={location.id} value={location.id}>
										{location.name}
									</Select.Option>
								)}
								sortBy={{ sort_by: 'name', order: 'ascend' }}
							/>
						)}
						<div style={{ marginTop: 8 }}>
							If you don't see your location, you can edit your access{' '}
							<TextButton onClick={this.provideLocationAccess(currentUser)}>here</TextButton>.
						</div>
					</Form.Item>
					{nullSafeGetOrElse('config.newServiceRequest.showAssets', companyConfig, true) ? (
						<Form.Item label="Affected Asset">
							{getFieldDecorator('assetId', {
								initialValue: nullSafeGet('asset.id', this.state),
							})(
								<OWAsyncSelect
									stateSlice={assets}
									onChange={this.handleAssetChange}
									style={{ maxWidth: 400 }}
									allowClear={true}
									disabled={this.state.assetSelectionDisabled}
									additionalFilters={{
										locationId: nullSafeGetOrElse('locationId', this.state, formData.locationId),
										problemTypeId: nullSafeGetOrElse(
											'problemTypeId',
											this.state,
											formData.problemTypeId
										),
										...(areaFilterAllowed &&
											nullSafeGetOrElse('areaId', this.state, formData.areaId) && {
												areaIdOrNull: nullSafeGetOrElse('areaId', this.state, formData.areaId),
											}),
									}}
									placeholder="Type to search for asset"
									targetCollectionName={WORK_ORDER_FORM_ASSETS_AUTOCOMPLETE}
									fetchMultiple={(ids, targetCollectionName) => {
										fetchMultipleAssets(ids, targetCollectionName);
									}}
									fetchData={(
										searchText,
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									) => {
										fetchAssets(
											{ search: searchText },
											targetCollectionName,
											pagination,
											sorting,
											filters,
											addToTargetCollection
										);
									}}
									renderRecord={(asset) => (
										<Select.Option key={asset.id} value={asset.id}>
											<div className="flex flex-col">
												<Ellipsis tooltip={true} length={32}>
													{asset.name}
												</Ellipsis>
												<AssetNumberDisplay asset={asset} />
											</div>
											{getWarrantyStatus(nullSafeGet('warranties.0', asset)) === 'Active' ? (
												<div style={{ marginTop: '8px', marginBottom: '8px', fontWeight: 500 }}>
													Under Warranty
												</div>
											) : null}
										</Select.Option>
									)}
									sortBy={{ sort_by: 'name', order: 'ascend' }}
								/>
							)}
						</Form.Item>
					) : null}
					{getFieldValue('assetId') && (
						<Form.Item label="Is the asset functioning?">
							{form.getFieldDecorator('isAssetDown', {
								initialValue: formData.isAssetDown ? 'true' : 'false',
							})(
								<OWRadioGroup
									valueAccessor={(item) => item.value}
									renderRecord={(item) => item.label}
									items={[
										{
											label: 'Yes',
											value: 'false',
										},
										{
											label: 'No',
											value: 'true',
										},
									]}
								/>
							)}
						</Form.Item>
					)}

					<Form.Item required={true} label="Problem Type">
						{getFieldDecorator('problemTypeId', {
							initialValue: nullSafeGet('problemType.id', this.state),
							rules: [{ required: true, message: 'This field is required.' }],
						})(
							<OWAsyncSelect
								stateSlice={problemTypes}
								onChange={this.handleProblemTypeChange}
								style={{ maxWidth: 400 }}
								additionalFilters={{
									locationId: nullSafeGet('location.id', this.state),
									assetId: nullSafeGet('asset.id', this.state),
									industryId: nullSafeGetOrElse('industryId', this.state, 1),
								}}
								placeholder="Type to search for problem type"
								targetCollectionName={WORK_ORDER_FORM_PROBLEM_TYPES_AUTOCOMPLETE}
								fetchMultiple={(ids, targetCollectionName) => {
									fetchMultipleProblemTypes(ids, targetCollectionName);
								}}
								fetchData={(
									searchText,
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								) => {
									fetchProblemTypes(
										{ name: searchText },
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									);
								}}
								renderRecord={(problemType) => (
									<Select.Option key={problemType.id} value={problemType.id}>
										{problemType.hierarchyName}
									</Select.Option>
								)}
								sortBy={{ sort_by: 'name', order: 'ascend' }}
							/>
						)}
					</Form.Item>
					{nullSafeGetOrElse('problemTypes.length', this.state, 0) > 1 ? null : (
						<Form.Item required={true} label="Trade">
							{getFieldDecorator('spendCategoryId', {
								initialValue: nullSafeGet('spendCategoryId', this.state),
								rules: [{ required: true, message: 'This field is required.' }],
							})(
								<OWAsyncSelect
									stateSlice={spendCategories}
									onChange={this.handleSpendCategoryChange}
									style={{ maxWidth: 400 }}
									placeholder="Type to search for trade"
									targetCollectionName={WORK_ORDER_FORM_SPEND_CATEGORIES_AUTOCOMPLETE}
									fetchMultiple={(ids, targetCollectionName) => {
										fetchMultipleSpendCategories(ids, targetCollectionName);
									}}
									fetchData={(
										searchText,
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									) => {
										fetchSpendCategories(
											{ name: searchText },
											targetCollectionName,
											pagination,
											sorting,
											filters,
											addToTargetCollection
										);
									}}
									renderRecord={(spendCategory) => (
										<Select.Option key={spendCategory.id} value={spendCategory.id}>
											{spendCategory.name}
										</Select.Option>
									)}
									sortBy={{ sort_by: 'name', order: 'ascend' }}
								/>
							)}
						</Form.Item>
					)}
					<Form.Item required={true} label="Priority">
						{getFieldDecorator('woPriorityId', {
							initialValue: nullSafeGet('id', this.state.workOrderPriority),
							rules: [{ required: true, message: 'This field is required.' }],
						})(
							<OWAsyncSelect
								stateSlice={workOrderPriorities}
								onChange={this.handleWorkOrderPriorityChange}
								style={{ maxWidth: 400 }}
								placeholder="Type to search for priorities"
								targetCollectionName={WORK_ORDER_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE}
								fetchMultiple={(ids, targetCollectionName) => {
									fetchMultipleWorkOrderPriorities(ids, targetCollectionName);
								}}
								fetchData={(
									searchText,
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								) => {
									fetchWorkOrderPriorities(
										{ name: searchText },
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									);
								}}
								renderRecord={(d) => (
									<Select.Option key={d.id} value={d.id}>
										{d.name}
									</Select.Option>
								)}
							/>
						)}
					</Form.Item>
					<Form.Item required={true} label="Short description of problem">
						{getFieldDecorator('title', {
							initialValue: this.state.inputValues[`title`],
							rules: [
								{ required: true, message: 'This field is required.' },
								{
									type: 'array',
									max: 200,
									transform(value) {
										if (!value) return [];
										return value.split('');
									},
									message: 'Please keep the description to less than 200 characters',
								},
							],
						})(
							<Input
								style={{ maxWidth: 640 }}
								onChange={this.handleInputValueChange(`title`)}
								onClick={(e) => e.stopPropagation()}
							/>
						)}
					</Form.Item>
					<Form.Item label="Anything else we should know">
						{getFieldDecorator('additionalInfo', {
							initialValue: this.state.inputValues[`additionalInfo`],
						})(
							<Input.TextArea
								style={{ maxWidth: 640 }}
								onChange={this.handleInputValueChange(`additionalInfo`)}
								onClick={(e) => e.stopPropagation()}
							/>
						)}
					</Form.Item>
					<Form.Item label="Attach photos">
						{getFieldDecorator(`images`, {
							valuePropName: 'fileList',
							getValueFromEvent: normFile,
							initialValue: this.state.allPhotos,
							rules: getImageCountErrorRules(minPhotosCount, imageCountErrorMessage),
						})(
							<OWUpload
								accept="image/*"
								name="file"
								listType="picture-card"
								className="workorder-image-uploader"
								multiple={true}
								headers={uploadHeaders}
								onPreview={this.handlePreview}
								action={`${BACKEND_URI}/api/v1/buyer/file/upload`}
								beforeUpload={imageUploadValidation}
								onChange={this.handleImageUpdate}
							>
								<div style={{ display: 'inline' }}>
									<LegacyIcon type={this.state.uploadLoading ? 'loading' : 'plus'} />
									<div className="ant-upload-text">Upload</div>
								</div>
								<Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
									<img alt="example" style={{ width: '100%' }} src={previewImage} />
								</Modal>
							</OWUpload>
						)}
					</Form.Item>
					<Form.Item label="Attach files" style={{ width: 400 }}>
						{form.getFieldDecorator('buyerAttachments', {
							initialValue: formData.buyerAttachments || [],
						})(
							<FileUploader
								defaultFileList={formData.buyerAttachments || []}
								roleType="buyer"
								handleUploadSuccess={this.handleFileAttachmentUploadChange}
								showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
								uploaderType="dragger"
							/>
						)}
					</Form.Item>
					<Form.Item style={{ marginBottom: 0, display: 'flex' }}>
						<Button
							type="primary"
							size="large"
							loading={isLoading}
							onClick={() =>
								this.goNext([
									`title`,
									`locationId`,
									`assetId`,
									`images`,
									`problemTypeId`,
									`additionalInfo`,
									`woPriorityId`,
								])
							}
						>
							{getNextText(activeStep)}
						</Button>
					</Form.Item>
				</div>
			);
		};

		const serviceRequestDetails = [
			{ key: 'Title', value: nullSafeGetOrElse('title', this.state.inputValues, '') },
			{ key: 'Location', value: nullSafeGetOrElse('name', this.state.location, '') },
			{ key: 'Asset', value: nullSafeGetOrElse('name', this.state.asset, '') },
			...(nullSafeGetOrElse('name', this.state.asset, '')
				? [
						{
							key: 'Is the Asset Under Warranty?',
							value: checkIsWarrantyWorkOrder(
								nullSafeGet('asset.warranties.0', this.state),
								nullSafeGet('supplierFacilityId', formData)
							)
								? 'No'
								: 'Yes',
						},
						{
							key: 'Is The Asset functioning?',
							value: formData.isAssetDown === 'true' ? 'No' : 'Yes',
						},
				  ]
				: []),
			{ key: 'Problem Type', value: nullSafeGetOrElse('name', this.state.problemType, '') },
			{
				key: 'Additional Info',
				value: nullSafeGetOrElse('additionalInfo', this.state.inputValues, ''),
			},
		];

		const photoStrings = this.state.allPhotos.map((p) => {
			const fileName = nullSafeGetOrElse('response.data.fileName', p, '');
			const fileId = nullSafeGetOrElse('response.data.fileId', p, '');
			return `${fileId}/${fileName}`;
		});

		const step2 = () => {
			return (
				<div>
					<div style={{ marginBottom: 24 }}>
						<TwoLineList
							listItems={serviceRequestDetails.filter((field) => field.value !== '')}
							lineOneRender={(record) => record.value}
							lineTwoRender={(record) => record.key}
						/>
						<ProtectedImageThumbnails
							style={{ marginTop: 16 }}
							imageWidth={64}
							imageHeight={64}
							photoStrings={photoStrings}
							role={ROLE_TYPES.BUYER}
						/>
					</div>
					{/*<Card title="Potential Duplicates">*/}
					{/*<PaginatedReduxTable*/}
					{/*mode={this.state.displayMode}*/}
					{/*longitudeAccessor={(el) => nullSafeGet('location.buyerFacility.longitude', el)}*/}
					{/*latitudeAccessor={(el) => nullSafeGet('location.buyerFacility.latitude', el)}*/}
					{/*popupRenderFunc={popupRenderFunc}*/}
					{/*showHeader={false}*/}
					{/*emptyState={*/}
					{/*<div style={{textAlign: 'center'}}>*/}
					{/*<div style={{maxWidth: 440, marginBottom: 16}}>*/}
					{/*Could not find any potential duplicate work orders*/}
					{/*</div>*/}
					{/*</div>*/}
					{/*}*/}
					{/*collectionName="work_orders"*/}
					{/*targetCollectionName={WORK_ORDERS_OVERVIEW_TARGET_COLLECTION}*/}
					{/*columns={columns}*/}
					{/*onRow={onRow}*/}
					{/*keyAccessor={(el) => el.id}*/}
					{/*initialPagination={{current: 1, pageSize: 5}}*/}
					{/*fetchData={workOrdersRestCrudThunksForBuyer.read}*/}
					{/*initialFilters={POSSIBLE_DUPLICATE_FILTER}*/}
					{/*initialSorting={{*/}
					{/*sort_by: 'id',*/}
					{/*order: 'desc'*/}
					{/*}}*/}
					{/*/>*/}
					{/*</Card>*/}
					<Form.Item style={{ marginBottom: 0, display: 'flex' }}>
						<Button style={{ opacity: 1 }} size="large" onClick={this.goPrev}>
							Back
						</Button>
						<BackButton buttonText="Cancel Work Order" size="large" style={{ marginLeft: 16 }} />
						<Button
							type="primary"
							size="large"
							style={{ marginLeft: 16 }}
							loading={isLoading}
							onClick={() => this.goNext([])}
						>
							{getNextText(activeStep)}
						</Button>
					</Form.Item>
				</div>
			);
		};

		const steps = [
			{
				title: 'Service Request Details',
				subtitle: nullSafeGet('config.newWorkOrder.step1.instructions', companyConfig),
				content: step1(),
			},
			{
				title: 'Review and Confirm',
				subtitle: 'Please review the details of your request before submitting.',
				content: step2(),
			},
		];

		getFieldDecorator('buyerCompanyId', {
			initialValue: formData.buyerCompanyId || nullSafeGet('facility.buyerCompanyId', currentUser),
		});
		getFieldDecorator('createdBy', {
			initialValue: formData.createdBy || nullSafeGet('email', currentUser),
		});

		getFieldDecorator('NTEAmount', { initialValue: 500 });
		getFieldDecorator('currentDateTime', { initialValue: moment(new Date()) });

		return (
			<div>
				<BuyerLocationUpdateForm
					wrappedComponentRef={this.saveFormRef('locationUpdateForm')}
					visible={this.state.locationUpdateVisible}
					formData={this.state.userContact}
					onCancel={this.handleBuyerLocationUpdateCancel}
					onSubmit={this.handleBuyerLocationUpdateSubmit}
					submitting={this.state.updatingLocation}
				/>
				<Form layout="vertical" className="serviceRequestForm">
					{createErrors.length > 0 ? (
						<Form.Item>
							<Alert message={createErrors.join(' ')} type="error" />
						</Form.Item>
					) : null}
					{updateErrors.length > 0 ? (
						<Form.Item>
							<Alert message={updateErrors.join(' ')} type="error" />
						</Form.Item>
					) : null}
					<div className="serviceRequestForm__steps">
						<Steps current={activeStep - 1}>
							{steps.map((item) => (
								<Step key={item.title} title={item.title} />
							))}
						</Steps>
					</div>
					{steps.map((step, idx) => (
						<div style={{ display: idx === activeStep - 1 ? 'block' : 'none' }}>
							<h5 className="serviceRequestFlowStepTitle">{step.subtitle}</h5>
							<div className="serviceRequestFlowSteps">{step.content}</div>
						</div>
					))}
				</Form>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,
	createErrors: state.work_orders.createErrors,
	updateErrors: state.work_orders.updateErrors,
	formData: ownProps.formData,
	creating: state.work_orders.creating,
	updating: state.work_orders.updating,
	companyConfig: state.company_config.detail,
	locationsFetching: state.locations.fetching,
	locations: state.locations,
	assetsFetching: state.assets.fetching,
	assets: state.assets,
	problemTypesFetching: state.problem_types.fetching,
	problemTypes: state.problem_types,
	spendCategories: state.spend_categories,
	spendCategoriesFetching: state.spend_categories.fetching,
	workOrderPriorities: state.work_order_priorities,
	workOrderPrioritiesFetching: state.work_order_priorities.fetching,
	supplierFacilities: state.supplier_facilities,
	supplierFacilitiesFetching: state.supplier_facilities.fetching,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	fetchLocation: (id) => dispatch(locationsRestCrudThunksForBuyer.readOne(id)),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			locationsRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchAsset: (id) => dispatch(assetsRestCrudThunksForBuyer.readOne(id)),
	fetchMultipleAssets: (ids, targetCollectionName) =>
		dispatch(assetsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchAssets: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			assetsRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchProblemType: (id) => dispatch(problemTypesRestCrudThunksForBuyer.readOne(id)),
	fetchMultipleProblemTypes: (ids, targetCollectionName) =>
		dispatch(problemTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchProblemTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			problemTypesRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchSpendCategory: (id) => dispatch(spendCategoriesRestCrudThunksForBuyer.readOne(id)),
	fetchMultipleSpendCategories: (ids, targetCollectionName) =>
		dispatch(spendCategoriesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchSpendCategories: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			spendCategoriesRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchWorkOrderAttributes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			workOrderAttributesRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchWorkOrderPriority: (id) => dispatch(workOrderPrioritiesRestCrudThunksForBuyer.readOne(id)),
	fetchMultipleWorkOrderPriorities: (ids, targetCollectionName) =>
		dispatch(workOrderPrioritiesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchWorkOrderPriorities: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			workOrderPrioritiesRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchSupplierFacility: (id) => dispatch(supplierFacilitiesRestCrudThunksForBuyer.readOne(id)),
	fetchSupplierFacilitiesInPrivateNetwork: (params) =>
		dispatch(
			fetchSupplierFacilitiesInPrivateNetworkForBuyer(
				params,
				WORK_ORDER_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE
			)
		),
	submitServiceRequestForm: (workOrder) =>
		dispatch(workOrdersRestCrudThunksForBuyer.create(workOrder)),
	roleUpdateUser: (entity) => dispatch(buyerRoleUpdateUser(entity)),
	fetchBuyer: (id) => dispatch(buyersRestCrudThunksForBuyer.readOne(id)),
	fetchExchangeRate: (c1, c2, userType) => dispatch(getExchangeRate(c1, c2)(userType)),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<ServiceRequestFormProps>()(NewServiceRequestForm))
);

import React from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { FlexibleImageAvatar } from '../flexible-image-avatar/FlexibleImageAvatar';
import { getProtectedImageUriForSupplier } from '../../utils/FileAccessUtils';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { Typography } from 'antd';

require('./EquipmentRowDisplay.less');

const { Text } = Typography;

export const EquipmentRowDisplay: React.FC<any> = ({ equipmentType, ...props }) => {
	const previewImage = nullSafeGet('images.0', equipmentType)
		? equipmentType.images.map((locImg) => {
				const [imgFileId, imgFileName] = locImg.split('/');
				return getProtectedImageUriForSupplier(imgFileId, imgFileName, 1200, 800);
		  })[0]
		: null;

	return (
		<div className="equipmentRowDisplay" style={{ justifyContent: 'space-between' }} {...props}>
			<div className="equipmentRowDisplay">
				<div style={{ marginRight: 24 }}>
					{previewImage ? (
						<FlexibleImageAvatar
							displayName={nullSafeGet('modelName', equipmentType)}
							showPopover={false}
							popoverText={nullSafeGet('modelName', equipmentType)}
							squareMode={true}
							width="120px"
							height="80px"
							imageUrl={previewImage}
						/>
					) : (
						<FlexibleImageAvatar
							displayName={nullSafeGet('modelName', equipmentType)}
							showPopover={false}
							popoverText={nullSafeGet('modelName', equipmentType)}
							squareMode={true}
							width="120px"
							height="80px"
							imageUrl="https://s3.amazonaws.com/emptor-data-assets/images/empty-state/icons8-maintenance-filled-100.png"
						/>
					)}
				</div>
				<div>
					<div className="equipmentRowDisplay__rowTitle">
						<Ellipsis tooltip={true} length={56} className={'pb-0.5'}>
							{nullSafeGet('modelName', equipmentType)}
						</Ellipsis>
					</div>
					<div className="equipmentRowDisplay__rowSubtitle flex flex-row items-center">
						{nullSafeGet('manufacturerId', equipmentType) ? (
							<div className="equipmentRowDisplay__rowInlineGroup">
								Model : {nullSafeGet('manufacturerId', equipmentType)}
							</div>
						) : null}
						{nullSafeGet('equipmentTypeNumber', equipmentType) ? (
							<div className="equipmentRowDisplay__rowInlineGroup">
								Equipment Type #{nullSafeGet('equipmentTypeNumber', equipmentType)}
							</div>
						) : null}
						{nullSafeGet('aliasEquipmentTypeNumber', equipmentType) ? (
							<div className="equipmentRowDisplay__rowInlineGroup">
								Alias Equipment Type #{nullSafeGet('aliasEquipmentTypeNumber', equipmentType)}
							</div>
						) : null}
						{nullSafeGet('assetType.name', equipmentType) ? (
							<div className="equipmentRowDisplay__rowInlineGroup">
								Asset Type : {nullSafeGet('assetType.name', equipmentType)}
							</div>
						) : null}
					</div>
					<div className="equipmentRowDisplay__rowSubtitle" style={{ display: 'inline-flex' }}>
						<Ellipsis tooltip={true} length={64}>
							{nullSafeGet('description', equipmentType)}
						</Ellipsis>
					</div>
					{equipmentType.isActive ? null : (
						<div className="mt-1 flex flex-row">
							<Text className={'mr-1'} type={'danger'}>
								Inactive
							</Text>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { showDate, showHour } from '../../utils/DataFormatterUtils';
import { ImageThumbnails } from '../image_thumbnails/ImageThumbnails';
import { getFileMimeTypeFromString } from '../../utils/FileAccessUtils';
import { ContactAvatar } from '../contact_avatar/ContactAvatar';
import { getObjectValues, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { Popover, Spin } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import {
	contactsRestCrudThunksForBuyer,
	contactsRestCrudThunksForSupplier,
} from '../../thunks/contacts_thunks';
import moment from 'moment';

const SimpleMessageBubble = ({
	currentUser,
	n,
	idx,
	subscribers = [],
	all_contacts,
	getContacts,
	userType,
	showReadReceipts = true,
	alwaysShowDate = false,
}) => {
	const getName = useCallback((userContact) => {
		return `-- ${nullSafeGet('noteAddedByContact.nameGiven', userContact)} ${nullSafeGet(
			'noteAddedByContact.nameFamily',
			userContact
		)}`;
	}, []);

	const isMe = useMemo(
		() => nullSafeGet('noteAddedBy', n) === nullSafeGet('email', currentUser),
		[n, currentUser]
	);

	return (
		<li className={isMe ? 'me' : 'him'} key={idx}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<div style={{ fontSize: 16, whiteSpace: 'pre-wrap' }}>{n.text}</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'flex-end',
					}}
				>
					<div className={`name mr-2`}>{getName(n)}</div>
					<div
						style={{
							display: 'flex',
							fontSize: '10px',
							color: isMe ? 'rgba(209,230,250,1)' : '#aaa',
						}}
					>
						{moment(n.noteAddedAt).format('MMM DD, h:mm a')}
					</div>
				</div>
			</div>
			{n.photo ? <ImageThumbnails photoStrings={[n.photo]} /> : null}
			<div className="time">
				{isMe
					? [
							<div key={1} style={{ marginRight: 8 }} className={'justify-center'}>
								<ContactAvatar contact={n.noteAddedByContact} userType={userType} />
							</div>,
					  ]
					: [
							<div key={1} style={{ marginLeft: 8 }}>
								<ContactAvatar contact={n.noteAddedByContact} userType={userType} />
							</div>,
					  ]}
			</div>
		</li>
	);
};

const mapStateToProps = (state) => ({
	all_contacts: state.all_contacts,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getContacts: (ids) => {
		if (ownProps.userType === 'supplier') {
			return dispatch(
				contactsRestCrudThunksForSupplier.readMultiple(ids, 'contactsIndex', null, null, null, true)
			);
		}
		return dispatch(
			contactsRestCrudThunksForBuyer.readMultiple(ids, 'contactsIndex', null, null, null, true)
		);
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(SimpleMessageBubble);

//Fetch Buyers and Suppliers
export const FETCH_BUYER_AND_SUPPLIER_USERS = 'FETCH_BUYER_AND_SUPPLIER_USERS';
export function fetchBuyerAndSupplierUsers() {
	return {
		type: FETCH_BUYER_AND_SUPPLIER_USERS,
	};
}
export const FETCH_BUYER_AND_SUPPLIER_USERS_SUCCESS = 'FETCH_BUYER_AND_SUPPLIER_USERS_SUCCESS';
export function fetchBuyerAndSupplierUsersSuccess(records) {
	return {
		type: FETCH_BUYER_AND_SUPPLIER_USERS_SUCCESS,
		records,
	};
}
export const FETCH_BUYER_AND_SUPPLIER_USERS_FAILURE = 'FETCH_BUYER_AND_SUPPLIER_USERS_FAILURE';
export function fetchBuyerAndSupplierUsersFailure(errors) {
	return {
		type: FETCH_BUYER_AND_SUPPLIER_USERS_FAILURE,
		errors,
	};
}

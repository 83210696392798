import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';

export default class SupplierMetersPage extends React.Component<any, null> {
	render() {
		return (
			<div>
				<Route
					path="/supplier/meters"
					exact={true}
					render={(props) => (
						<Redirect
							to={{
								pathname: '/supplier/meters/overview',
							}}
						/>
					)}
				/>
				{this.props.childRoutes.map((r, i) => (
					<RouteWithSubRoutes key={i} {...r} />
				))}
			</div>
		);
	}
}

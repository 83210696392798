import React, { FC, useCallback, useEffect, useState } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Modal, Form } from 'antd';
import { connect } from 'react-redux';
import { Document, Page, pdfjs } from 'react-pdf';
import { generatePurchaseOrderPDFForSupplier } from '../../thunks/purchase_orders_thunks';
import MultiEmailComponentAntdV4 from '../MultiEmailComponent/MultiEmailComponentAntdV4';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FormItem = Form.Item;

const SupplierSendPurchaseOrderByEmailForm: FC<any> = ({
	purchaseOrderId,
	onCancel,
	onSubmit,
	loading,
}): React.ReactElement => {
	const [form] = Form.useForm();

	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [pdfFile, setPdfFile] = useState<any>({ url: null, httpHeaders: null });

	useEffect(() => {
		!pdfFile.url && setPdfFile(generatePurchaseOrderPDFForSupplier(purchaseOrderId));
	}, [pdfFile.url, purchaseOrderId]);

	const onDocumentLoadSuccess = useCallback(({ numPages, ...props }) => {
		setNumPages(numPages);
	}, []);

	return (
		<Modal
			visible={true}
			width={1000}
			title="Send Purchase Order by Email"
			okText="Send"
			onCancel={onCancel}
			onOk={form.submit}
			confirmLoading={loading}
			closable={false}
		>
			<Form
				form={form}
				layout="vertical"
				onFinish={onSubmit}
				className="sendPurchaseOrderByEmailForm"
			>
				<FormItem name="purchaseOrderId" hidden initialValue={purchaseOrderId} />
				<MultiEmailComponentAntdV4
					fieldProps={{
						preserve: false,
						tooltip: 'You can enter multiple emails seperated by comma',
						label: 'Send order to email',
						name: 'emails',
						rules: [
							{
								required: true,
								message:
									'Please enter the email of the person you want to send the purchase order to',
							},
						],
					}}
				/>
				{pdfFile.url ? (
					<div className="po-pdf">
						<Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
							<Page pageNumber={pageNumber} />
						</Document>
						<p>
							Page {pageNumber} of {numPages}
						</p>
					</div>
				) : null}
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	createErrors: state.supplier_contacts.createErrors,
	updateErrors: state.supplier_contacts.updateErrors,
	creating: state.supplier_contacts.creating,
	updating: state.supplier_contacts.updating,
	currentUser: state.session.currentUser,
	purchaseOrderId: ownProps.purchaseOrderId,
	documents: ownProps.documents,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SupplierSendPurchaseOrderByEmailForm);

import * as React from 'react';
import { Popover } from 'antd';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { FC, useMemo, useState } from 'react';
import { LocationCard } from './LocationCard';
import {
	getProtectedImageUriForBuyer,
	getProtectedImageUriForSupplier,
} from '../../../utils/FileAccessUtils';
import { connect } from 'react-redux';

interface LocationNameComponentProps {
	location: any;
	mode?: 'card' | 'inline' | 'container' | 'avatar';
	secondaryField?: string;
	showImages?: boolean;
	size?: 'default' | 'large';
	allowHover?: boolean;
	popoverMode?: 'simple' | 'full';
	placement?: string;
	userType?: String;
}

const LocationName: FC<LocationNameComponentProps> = ({
	location,
	mode = 'card',
	secondaryField = 'email',
	showImages = true,
	size = 'default',
	allowHover = true,
	popoverMode = 'full',
	placement = 'leftTop',
	userType = 'buyer',
}) => {
	//const subTitle = nullSafeGet(secondaryField, contact);
	const onClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const getProtectedImageMethod = useMemo(
		() =>
			userType === 'supplier' ? getProtectedImageUriForSupplier : getProtectedImageUriForBuyer,
		[userType]
	);

	const displayImageURL =
		location.images &&
		location.images[0] &&
		getProtectedImageMethod(
			location.images[0].split('/')[0],
			location.images[0].split('/')[1],
			'auto',
			120
		);

	const [isVertical, setIsVertical] = useState(false);
	const img = useMemo(() => {
		let i = new Image();
		if (displayImageURL) {
			i.src = displayImageURL;
			i.onload = () => setIsVertical(i.height / i.width >= 1);
		}
		return i;
	}, [displayImageURL]);

	const popoverContent = (
		<LocationCard
			location={location}
			name={location.name}
			subTitle={''}
			showImages={true}
			size={size}
			mode={popoverMode}
			isVertical={isVertical}
			img={img}
		/>
	);
	const element =
		mode === 'card' ? (
			<span>
				<LocationCard
					location={location}
					name={location.name}
					subTitle={''}
					showImages={true}
					size={size}
					mode={popoverMode}
					isVertical={isVertical}
					img={img}
				/>
			</span>
		) : mode === 'inline' ? (
			<span>{location.name}</span>
		) : mode === 'container' ? (
			<Ellipsis tooltip={false} fullWidthRecognition={true}>
				{location.name}
			</Ellipsis>
		) : mode === 'avatar' ? (
			<span>
				<LocationCard
					location={location}
					name={location.name}
					subTitle={''}
					showImages={true}
					size={size}
					mode={popoverMode}
					isVertical={isVertical}
					img={img}
				/>
			</span>
		) : (
			<span></span>
		);

	return allowHover ? (
		<Popover placement={placement} content={popoverContent}>
			{element}
		</Popover>
	) : (
		element
	);
};

const LocationNameComponent = connect()(LocationName);

export default connect(
	(state) => ({
		userType: state.session.userType,
	}),
	(dispatch, ownProps) => ({})
)(LocationNameComponent);

import * as React from 'react';
import { Button, Result } from 'antd';
import { MAPBOX_API_KEY } from '../../utils/ClientsideApiKeys';
import { debounce } from '../../utils/PerformanceUtils';

import mapboxgl from 'mapbox-gl';
import { ErrorBoundary } from '@sentry/react';
const isEqual = require('fast-deep-equal');

const CENTER_OF_UNITED_STATES = [-98.5795, 39.8283];

class MapboxGalleryComponent extends React.Component<any, any> {
	map: any;
	mapContainer: any;
	// @ts-ignore
	markers: mapboxgl.Marker[];

	state = {
		showSearchWithinArea: false,
		bounds: {},
	};

	debouncedMoveEndCallback = debounce(
		(bounds) => {
			if (!this.props.hideSearchWithinArea) {
				this.setState({ showSearchWithinArea: true, bounds });
			}
		},
		250,
		false
	);

	handleSearchWithinArea = () => {
		const { bounds } = this.state;
		this.props.handleSearchAgain(bounds);
		this.setState({ showSearchWithinArea: false });
	};

	componentDidMount() {
		const {
			startingLongitude = CENTER_OF_UNITED_STATES[0],
			startingLatitude = CENTER_OF_UNITED_STATES[1],
			startingZoomLevel = 2,
			geolocatedEntities,
			popupRenderFunc = (gle) =>
				`<div style="width: 25px; height: 25px; border-radius: 50%;"><img width=100%" height="auto" src="${gle.logoURL}" /></div><div>${gle.displayName}</div>`,
			longitudeAccessor = (el) => el.longitude,
			latitudeAccessor = (el) => el.latitude,
			showNavControls,
			baseLayerStyle,
			zoomLevel,
		} = this.props;
		// tslint-disable-next-line
		mapboxgl.accessToken = MAPBOX_API_KEY;
		this.map = new mapboxgl.Map({
			container: this.mapContainer,
			style: `mapbox://styles/mapbox/${baseLayerStyle}-v9`,
			center: [startingLongitude, startingLatitude],
			zoom: startingZoomLevel,
			attributionControl: false,
		});
		this.map.on('moveend', () => {
			this.debouncedMoveEndCallback(this.map.getBounds());
		});
		if (showNavControls) {
			this.map.addControl(new mapboxgl.NavigationControl({ showCompass: false }));
		}

		this.markers = geolocatedEntities.map((gle) => {
			const popup = new mapboxgl.Popup().setHTML(popupRenderFunc(gle));
			const marker = new mapboxgl.Marker()
				.setLngLat([longitudeAccessor(gle), latitudeAccessor(gle)])
				.setPopup(popup)
				.addTo(this.map);
			return marker;
		});
		const filteredGeolocatedEntities = geolocatedEntities.filter(
			(gle) => longitudeAccessor(gle) && latitudeAccessor(gle)
		);
		if (filteredGeolocatedEntities.length > 0) {
			const minLongitude = Math.min.apply(null, filteredGeolocatedEntities.map(longitudeAccessor));
			const maxLongitude = Math.max.apply(null, filteredGeolocatedEntities.map(longitudeAccessor));
			const minLatitude = Math.min.apply(null, filteredGeolocatedEntities.map(latitudeAccessor));
			const maxLatitude = Math.max.apply(null, filteredGeolocatedEntities.map(latitudeAccessor));
			this.map.fitBounds(
				[
					[minLongitude, minLatitude],
					[maxLongitude, maxLatitude],
				],
				{ padding: 40 }
			);
		}
	}

	componentWillReceiveProps(nextProps) {
		const {
			geolocatedEntities,
			popupRenderFunc = (gle) =>
				`<div style="width: 25px; height: 25px; border-radius: 50%;"><img width=100%" height="auto" src="${gle.logoURL}" /></div><div>${gle.displayName}</div>`,
			longitudeAccessor = (el) => el.longitude,
			latitudeAccessor = (el) => el.latitude,
		} = nextProps;
		// FIXME: this is not quite right. Should do minimal manipulation possible instead of removing all and then adding back.
		if (!isEqual(geolocatedEntities, this.props.geolocatedEntities) && this.map && this.markers) {
			this.markers.forEach((marker) => {
				marker.getPopup().remove();
				marker.remove();
			});
			this.markers = geolocatedEntities.map((gle) => {
				const popup = new mapboxgl.Popup().setHTML(popupRenderFunc(gle));
				const marker = new mapboxgl.Marker()
					.setLngLat([longitudeAccessor(gle), latitudeAccessor(gle)])
					.setPopup(popup)
					.addTo(this.map);
				return marker;
			});
			const filteredGeolocatedEntities = geolocatedEntities.filter(
				(gle) => longitudeAccessor(gle) && latitudeAccessor(gle)
			);
			if (filteredGeolocatedEntities.length > 0) {
				const minLongitude = Math.min.apply(
					null,
					filteredGeolocatedEntities.map(longitudeAccessor)
				);
				const maxLongitude = Math.max.apply(
					null,
					filteredGeolocatedEntities.map(longitudeAccessor)
				);
				const minLatitude = Math.min.apply(null, filteredGeolocatedEntities.map(latitudeAccessor));
				const maxLatitude = Math.max.apply(null, filteredGeolocatedEntities.map(latitudeAccessor));
				this.map.fitBounds(
					[
						[minLongitude, minLatitude],
						[maxLongitude, maxLatitude],
					],
					{
						padding: 40,
					}
				);
			}
		}
	}

	componentWillUnmount() {
		this.map.remove();
	}

	render() {
		const { showSearchWithinArea } = this.state;

		const style = {
			position: 'absolute' as 'absolute',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			width: '100%',
		};

		return (
			<div style={style} ref={(el) => (this.mapContainer = el)}>
				{showSearchWithinArea ? (
					<Button
						style={{
							position: 'absolute',
							left: '50%',
							top: '8px',
							transform: 'translateX(-50%)',
							zIndex: 2,
						}}
						onClick={this.handleSearchWithinArea}
					>
						Redo search in area
					</Button>
				) : null}
			</div>
		);
	}
}

export default class MapboxGallery extends React.Component<any, any> {
	render(): React.ReactNode {
		return (
			<ErrorBoundary fallback={null}>
				<MapboxGalleryComponent {...this.props} />
			</ErrorBoundary>
		);
	}
}

import React, { FC, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Tooltip } from 'antd';
import { EmptyState } from '../empty_state/EmptyState';
import { usersRestCrudThunksForSupplier } from '../../thunks/users_thunks';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { getContactName } from '../../utils/DataFormatterUtils';
import {
	FILTER_CONFIG_NAMES,
	FILTER_FIELD_TYPE,
	FILTER_VALUE_TYPE,
} from '../../utils/DataConstants';
import { partsRestCrudThunksForSupplier } from '../../thunks/parts_thunks';
import { equipmentTypesRestCrudThunksForSupplier } from '../../thunks/equipment_types_thunks';
import {
	fetchWithDifferntSearchName,
	getLineItemVendor,
	getPurchaseRequestId,
	isPRLineItemSelectionDisabled,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import {
	downloadPRLineItemsCSVForSupplier,
	purchaseRequestLineItemsRestCrudThunksForSupplier,
} from '../../thunks/purchase_request_line_items_thunks';
import { PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS } from '../../actions/purchase_request_line_items_actions';
import HyperLink, { ENTITY_TYPE } from '../common/HyperLink';
import PurchaseRequestStatusDisplay from '../purchase_request_status_display/PurchaseRequestStatusDisplay';
import { vendorsRestCrudThunksForSupplier } from '../../thunks/vendors_thunks';
import { getRecordsForCompositeTargetCollection } from '../../reducers/standard_reducer_utils';
import PartEquipmentNameWithType from '../equipment_per_stock_location_row_display/PartEquipmentNameWithType';
import { getDisabledMessage } from './po_create_utils';
import { locationsRestCrudThunksForSupplier } from '../../thunks/locations_thunks';
import LocationStockLocationNameWithType from '../equipment_per_stock_location_row_display/LocationStockLocationNameWithType';
import PRToPOPage from './PRToPOPage';
import { isSupplierInventoryConfigEnabled } from '../common/SupplierInventoryConfigPermissionChecker';
import { CONFIG_NAMES } from '../../utils/AuthUtils';
import { DownloadOutlined } from '@ant-design/icons';
import { renderEquipmenTypeSelect } from '../purchase_order_form/ReplaceEquipmentLineItemModal';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';

const TC_NAME = 'purchaseRequestLineItemsIndex';
const PR_LINE_ITEMS_ADVANCED_FILTERS = 'prLineItemsAdvancedFilters';
const BACK_TEXT = 'Back To Requests';

export const PR_LINE_ITEM_COLUMNS = [
	{
		title: 'PR',
		dataIndex: 'supplierPurchaseRequest',
		render: (_) => (
			<HyperLink
				text={getPurchaseRequestId(_)}
				entityType={ENTITY_TYPE.PURCHASE_REQUEST}
				entityId={_.id}
				backLinkText="Back to Requests"
			/>
		),
		props: {
			useDefault: true,
		},
	},
	{
		title: 'Part/Eq ID',
		render: (_, record) =>
			record.isEquipmentLine
				? nullSafeGetOrElse('equipmentType.equipmentTypeNumber', record, '--')
				: nullSafeGetOrElse('part.partNumber', record, '--'),
	},
	{
		title: 'Name',
		render: (_, record) => <PartEquipmentNameWithType record={record} backText={BACK_TEXT} />,
	},
	{
		title: 'Vendor',
		render: (_, record) => nullSafeGetOrElse('name', getLineItemVendor(record), '--'),
	},
	{
		title: 'Location',
		render: (_, record) => (
			<LocationStockLocationNameWithType record={record} backLinkText={BACK_TEXT} />
		),
	},
	{
		title: 'Quantity',
		dataIndex: 'equipmentQuantity',
		render: (_, record) =>
			record.isEquipmentLine
				? nullSafeGetOrElse('equipmentQuantity', record, 0)
				: nullSafeGetOrElse('partQuantity', record, 0),
	},
	{
		title: 'Status',
		render: (_, record) => <PurchaseRequestStatusDisplay status={record.status} />,
	},
];

const PRLineItemsComponent: FC<any> = ({
	purchaseRequestLineItems,
	clearAndUpdateFilters,
	updateFilters,
	users,
	fetchUsers,
	fetchMultipleUsers,
	stockLocations,
	fetchStockLocations,
	fetchMultipleStockLocations,
	equipmentTypes,
	fetchEquipmentTypes,
	fetchMultipleEquipments,
	parts,
	fetchParts,
	fetchMultipleParts,
	locations,
	fetchLocations,
	fetchMultipleLocations,
	isApproved,
	companyConfig,
	exportPRLineItemsCSV,
}) => {
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [selectedVendor, setSelectedVendor] = useState<any>({ id: undefined, name: undefined });
	const [selectedSL, setSelectedSL] = useState<any>({ id: undefined, name: undefined });
	const [selectedLocation, setSelectedLocation] = useState<any>({ id: undefined, name: undefined });
	const [poFormVisible, setPoFormVisible] = useState(false);
	const [selectedRecords, setSelectedRecords] = useState([]);

	const [exporting, setExporting] = useState(false);

	const getRecordFromTargetCollection = useCallback(
		(id) => {
			const records = getRecordsForCompositeTargetCollection(purchaseRequestLineItems, TC_NAME);
			return records.find((_) => _.id === id);
		},
		[purchaseRequestLineItems]
	);

	const onSelectChange = useCallback((newSelectedRowKeys: React.Key[], selectedRows) => {
		setSelectedRowKeys(newSelectedRowKeys);
		setSelectedRecords(selectedRows);
		if (selectedRows.length > 0) {
			const record = selectedRows.find((_) => !!getLineItemVendor(_).id) || selectedRows[0];
			setSelectedVendor({
				...getLineItemVendor(record),
			});
			setSelectedSL(nullSafeGetOrElse('stockLocation', record, {}));
			setSelectedLocation(nullSafeGetOrElse('location', record, {}));
		} else {
			setSelectedVendor({});
			setSelectedSL({});
			setSelectedLocation({});
		}
	}, []);

	const canCreatePO = useMemo(() => selectedRowKeys.length > 0, [selectedRowKeys.length]);

	const rowSelection = useMemo(
		() => ({
			selectedRowKeys,
			preserveSelectedRowKeys: true,
			onChange: onSelectChange,
			getCheckboxProps: (record) => ({
				disabled: isPRLineItemSelectionDisabled({
					record,
					selectedLocation,
					selectedSL,
					selectedVendor,
				}),
			}),
		}),
		[onSelectChange, selectedLocation, selectedRowKeys, selectedSL, selectedVendor]
	);

	const poModuleDisabled = isSupplierInventoryConfigEnabled(
		companyConfig,
		CONFIG_NAMES.DISABLE_PURCHASE_ORDERS
	);

	const onSelectCancel = useCallback(() => {
		setSelectedVendor({});
		setSelectedSL({});
		setSelectedLocation({});
		setSelectedRowKeys([]);
		setSelectedRecords([]);
		setPoFormVisible(false);
	}, []);

	const filterConfig = useMemo(
		() => [
			...(!isApproved
				? [
						{
							label: 'Status',
							fieldName: 'statuses',
							mode: 'multiple',
							type: FILTER_FIELD_TYPE.OW_MULTI_SELECT,
							items: [
								{
									label: 'All',
									value: 'undefined',
								},
								{
									label: 'Requested',
									value: 'requested',
								},
								{
									label: 'Approved',
									value: 'approved',
								},
								{
									label: 'Fulfilled',
									value: 'fulfilled',
								},
								{
									label: 'Partially Fulfilled',
									value: 'partially_fulfilled',
								},
								{
									label: 'Order In Progress',
									value: 'orderInProgress',
								},
							],
							defaultValue: undefined,
							valueType: FILTER_VALUE_TYPE.STRING,
						},
				  ]
				: []),
			{
				fieldName: 'isEquipmentLine',
				type: FILTER_FIELD_TYPE.RADIO_GROUP,
				items: [
					{
						label: 'All',
						value: 'undefined',
					},
					{
						label: 'Parts',
						value: 'false',
					},
					{
						label: 'Equipment',
						value: 'true',
					},
				],
				defaultValue: undefined,
				showOutsideDropdown: true,
			},
			...(!isApproved
				? [
						{
							fieldName: 'isOrdered',
							type: FILTER_FIELD_TYPE.RADIO_GROUP,
							items: [
								{
									label: 'All',
									value: 'undefined',
								},
								{
									label: 'Not Ordered',
									value: `false`,
								},
								{
									label: 'Ordered',
									value: `true`,
								},
							],
							defaultValue: undefined,
							showOutsideDropdown: true,
						},
				  ]
				: []),
			{
				label: 'Stock Location',
				fieldName: 'stockLocationIds',
				mode: 'multiple',
				stateSlice: stockLocations,
				targetCollectionName: PR_LINE_ITEMS_ADVANCED_FILTERS,
				fetch: fetchStockLocations,
				fetchMultiple: fetchMultipleStockLocations,
			},
			{
				label: 'Location',
				fieldName: 'locationIds',
				mode: 'multiple',
				stateSlice: locations,
				targetCollectionName: PR_LINE_ITEMS_ADVANCED_FILTERS,
				fetch: fetchLocations,
				fetchMultiple: fetchMultipleLocations,
			},
			{
				label: 'Part',
				fieldName: 'partIds',
				mode: 'multiple',
				stateSlice: parts,
				targetCollectionName: PR_LINE_ITEMS_ADVANCED_FILTERS,
				fetch: fetchParts,
				fetchMultiple: fetchMultipleParts,
				renderItem: (part) => (
					<div className="flex flex-row items-center">
						<div>{part.name}</div>
						<div>
							{part.partNumber && (
								<span className="text-gray-400">&nbsp;{`- #${part.partNumber}`}</span>
							)}
						</div>
					</div>
				),
			},
			{
				label: 'Equipment Type',
				mode: 'multiple',
				fieldName: 'equipmentTypeIds',
				stateSlice: equipmentTypes,
				targetCollectionName: PR_LINE_ITEMS_ADVANCED_FILTERS,
				fetch: fetchWithDifferntSearchName(fetchEquipmentTypes, 'search'),
				fetchMultiple: fetchMultipleEquipments,
				renderRecord: renderEquipmenTypeSelect,
				component: OWAsyncSelect,
			},
			{
				label: 'Requested by',
				fieldName: 'createdByEmails',
				mode: 'multiple',
				valueType: FILTER_VALUE_TYPE.STRING,
				stateSlice: users,
				valueAccessor: (user) => nullSafeGet('contact.email', user),
				targetCollectionName: PR_LINE_ITEMS_ADVANCED_FILTERS,
				fetch: fetchUsers,
				fetchMultiple: fetchMultipleUsers,
				renderItem: (user) => getContactName(user.contact),
			},
			{
				label: 'Approved by',
				fieldName: 'approvedByEmails',
				mode: 'multiple',
				valueType: FILTER_VALUE_TYPE.STRING,
				stateSlice: users,
				valueAccessor: (user) => nullSafeGet('contact.email', user),
				targetCollectionName: PR_LINE_ITEMS_ADVANCED_FILTERS,
				fetch: fetchUsers,
				fetchMultiple: fetchMultipleUsers,
				renderItem: (user) => getContactName(user.contact),
			},
			{
				label: 'Requested Between',
				labelPrefix: 'Requested',
				fieldName: FILTER_CONFIG_NAMES.CREATED_AT,
				type: FILTER_FIELD_TYPE.DATE_RANGE,
			},
			{
				label: 'Approved Between',
				labelPrefix: 'Approved',
				fieldName: FILTER_CONFIG_NAMES.APPROVED_AT,
				type: FILTER_FIELD_TYPE.DATE_RANGE,
			},
		],
		[
			equipmentTypes,
			fetchEquipmentTypes,
			fetchLocations,
			fetchMultipleEquipments,
			fetchMultipleLocations,
			fetchMultipleParts,
			fetchMultipleStockLocations,
			fetchMultipleUsers,
			fetchParts,
			fetchStockLocations,
			fetchUsers,
			isApproved,
			locations,
			parts,
			stockLocations,
			users,
		]
	);

	const onCreatePO = useCallback(() => setPoFormVisible(true), []);

	const CustomRow = (props) => {
		const id = props['data-row-key'];
		const record = getRecordFromTargetCollection(id);
		const message = getDisabledMessage({
			record,
			canCreatePO,
			selectedVendor,
			selectedSL,
			selectedLocation,
		});
		return message ? (
			<Tooltip title={message} placement="topLeft">
				<tr {...props} />
			</Tooltip>
		) : (
			<tr {...props} />
		);
	};

	const downloadCSV = useCallback(() => {
		const filters = nullSafeGetOrElse(`${TC_NAME}.filters`, purchaseRequestLineItems, {});
		setExporting(true);
		exportPRLineItemsCSV(filters).finally(() => setExporting(false));
	}, [exportPRLineItemsCSV, purchaseRequestLineItems]);

	return !poFormVisible ? (
		<PaginatedReduxTableWithHeader
			targetCollectionName={TC_NAME}
			updateFilters={updateFilters}
			stateSlice={purchaseRequestLineItems}
			clearAndUpdateFilters={clearAndUpdateFilters}
			filterConfig={filterConfig}
			entityCollectionName="purchase_request_line_items"
			tableColumns={PR_LINE_ITEM_COLUMNS}
			fetchData={purchaseRequestLineItemsRestCrudThunksForSupplier.read}
			preAppliedFilters={{ ...(isApproved && { status: 'approved' }) }}
			rowSelection={poModuleDisabled ? null : rowSelection}
			components={{
				body: {
					row: CustomRow,
				},
			}}
			rightActions={
				canCreatePO ? (
					<div className="flex-start flex flex-row">
						<Button onClick={onSelectCancel} size="large">
							Cancel
						</Button>
						<Button className="ml-2" size="large" type="primary" onClick={onCreatePO}>
							Create Purchase Order
						</Button>
					</div>
				) : (
					<Button
						size="large"
						icon={<DownloadOutlined translate="" />}
						className="inline-block-visible-md"
						loading={exporting}
						onClick={downloadCSV}
					>
						<span className="inline-block-visible-xl">Export CSV</span>
					</Button>
				)
			}
			showHeader
			emptyState={
				<EmptyState
					graphic={
						<img
							alt="No purchase requests found"
							style={{ marginBottom: 8 }}
							src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
						/>
					}
					headline={'No purchase requests found'}
				/>
			}
		/>
	) : (
		<PRToPOPage
			selectedRecords={selectedRecords}
			onCancel={onSelectCancel}
			selectedVendor={selectedVendor}
			selectedSL={selectedSL}
			selectedLocation={selectedLocation}
		/>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	purchaseRequestLineItems: state.purchase_request_line_items,
	supplierUsers: state.supplier_users,
	stockLocations: state.stock_locations,
	users: state.users,
	parts: state.parts,
	vendors: state.vendors,
	equipmentTypes: state.equipment_types,
	locations: state.locations,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	updateFilters: (filters, targetCollection) =>
		dispatch(
			PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)
		),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(
				filters,
				targetCollectionName
			)
		),
	fetchMultipleStockLocations: (ids, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchStockLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			stockLocationsRestCrudThunksForSupplier.readLite(
				{ ...params, no_pagination: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleUsers: (ids, targetCollectionName) =>
		dispatch(usersRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchUsers: (params, targetCollectionName, pagination, sorting, filters, addToTargetCollection) =>
		dispatch(
			usersRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleParts: (ids, targetCollectionName) =>
		dispatch(partsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchParts: (params, targetCollectionName, pagination, sorting, filters, addToTargetCollection) =>
		dispatch(
			partsRestCrudThunksForSupplier.readLite(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleEquipments: (ids, targetCollectionName) =>
		dispatch(equipmentTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchEquipmentTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			equipmentTypesRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchVendors: (params, targetCollectionName) =>
		dispatch(
			vendorsRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName
			)
		),
	fetchMultipleVendors: (ids, targetCollectionName) =>
		dispatch(vendorsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			locationsRestCrudThunksForSupplier.readLite(
				{ ...params, no_pagination: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	exportPRLineItemsCSV: (params, filters) =>
		dispatch(downloadPRLineItemsCSVForSupplier(params, filters)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(PRLineItemsComponent)
);

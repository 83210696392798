import * as React from 'react';
import { Layout, Steps } from 'antd';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router';
import { requestsForProposalRestCrudThunksForSupplier } from '../../thunks/requests_for_proposal_thunks';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';
import SubnavTabs from '../subnav_tabs/SubnavTabs';
import {
	requestForProposalStatusIndicatorFormatter,
	timeUntilOrSince,
} from '../../utils/DataFormatterUtils';
import moment from 'moment';
import { nullSafeGet } from '../../utils/DataAccessUtils';

const style = require('./SupplierRFPDetailPage.less');
const { Content } = Layout;
const Step = Steps.Step;

class SupplierRFPDetailPage extends React.Component<any, any> {
	componentDidMount() {
		const {
			getRequestForProposal,

			match,
		} = this.props;
		const requestForProposalId = match.params.id;
		getRequestForProposal(requestForProposalId);
	}

	handleTabChange = (key) => {
		const { history, match } = this.props;
		const requestForProposalId = parseInt(match.params.id, 10);
		history.push(`/supplier/opportunities/detail/${requestForProposalId}/${key}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	render() {
		const { requestForProposal, requestsForProposalFetching, companyConfig, match } = this.props;
		const requestForProposalId = parseInt(match.params.id, 10);

		const tabs = [
			{
				key: 'details',
				name: 'Overview',
			},
		];

		return requestsForProposalFetching && !requestForProposal.id ? (
			<div>
				<Route
					path="/supplier/opportunities/detail/:id"
					exact={true}
					render={(props) => (
						<Redirect
							to={{
								pathname: `/supplier/opportunities/detail/${requestForProposalId}/details`,
							}}
						/>
					)}
				/>
				{this.props.childRoutes.map((r, i) => (
					<RouteWithSubRoutes key={i} {...r} />
				))}
				<PageLoadingPlaceholder />
			</div>
		) : (
			<div>
				<Route
					path="/supplier/opportunities/detail/:id"
					exact={true}
					render={(props) => (
						<Redirect
							to={{
								pathname: `/supplier/opportunities/detail/${requestForProposalId}/details`,
							}}
						/>
					)}
				/>
				<Content className="rfpDetailPage" style={{ padding: '0 0.5em' }}>
					{/*<ScrollToTopOnMount/>*/}
					{/*<BackTop/>*/}

					<div
						style={{
							margin: '0 -8px',
							padding: '24px',
							paddingBottom: 0,
							background: nullSafeGet('detail.config.theme.navblock', companyConfig),
							marginBottom: 8,
						}}
					>
						<div style={{ marginBottom: 24 }}>
							<h4 className="rfpDetailPage__title">
								{!requestForProposal.title ? '' : requestForProposal.title}
							</h4>
							<div className="rfpDetailPage__subtitleRow">
								<div className="rfpDetailPage__subtitle">
									{requestForProposalStatusIndicatorFormatter(requestForProposal.status)}
								</div>
								<div className="rfpDetailPage__subtitle">
									{moment().diff(requestForProposal.dueDate, 'h') < 0 ? (
										<div>Responses due {timeUntilOrSince(requestForProposal.dueDate)}</div>
									) : (
										<div>Bidding closed {timeUntilOrSince(requestForProposal.dueDate)}</div>
									)}
								</div>
							</div>
						</div>
						<SubnavTabs
							tabs={tabs}
							onChange={this.handleTabChange}
							isActive={this.checkTabActive}
						/>
					</div>
					{this.props.childRoutes.map((r, i) => (
						<RouteWithSubRoutes key={i} {...r} />
					))}
				</Content>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	requestsForProposalFetching: state.requests_for_proposal.fetching,
	requestForProposal: state.requests_for_proposal.detail,
	currentUser: state.session.currentUser,
	companyConfig: state.company_config,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	updateRequestForProposal: (entity) =>
		dispatch(requestsForProposalRestCrudThunksForSupplier.update(entity)),
	getRequestForProposal: (id) => dispatch(requestsForProposalRestCrudThunksForSupplier.readOne(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SupplierRFPDetailPage));

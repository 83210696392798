import React, { FC } from 'react';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import ReviewAndMarkCompleteComponent from '../supplier_work_orders_detail_details_page/ReviewAndMarkCompleteComponent';
import ApprovalHierarchyDisplayWorkOrder from '../approval_hierarchy_display_work_order/ApprovalHierarchyDisplayWorkOrder';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
	approveWorkOrderForBuyer,
	approveWorkOrderForSupplier,
	syncWorkOrderApprovalHierarchyForBuyer,
	syncWorkOrderApprovalHierarchyForSupplier,
	unsnoozeWorkOrderForBuyer,
	unsnoozeWorkOrderForSupplier,
} from '../../thunks/work_orders_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';
import {
	PRE_SUPPLIER_ASSIGNMENT_STATUSES,
	WORK_ORDER_STATUSES,
} from '../../constants/WorkOrderStatuses';
import UndeferComponent from '../undefer_component/UndeferComponent';

const WorkOrderActions: FC<any> = ({
	workOrder,
	currentUser,
	companyConfig,
	showApproveModal,
	showReminderModal,
	userType,
	refreshWorkOrder,
	approveWorkOrder,
	syncWorkOrder,
	unsnoozeWorkOrder,
	reviewAndCompleteDisabled,
}): React.ReactElement => {
	const isPendingApproval = nullSafeGet('displayStatus', workOrder) === 'Pending';
	const isSnoozed =
		nullSafeGet('status', workOrder) === 'Snoozed' ||
		nullSafeGet('status', workOrder) === 'DeferredForBudget';
	const getStatus = (supplierFacilityId, oldStatus) => {
		const privateSupplier = nullSafeGetOrElse('supplierFacility.status', workOrder, false);
		let newStatus;
		if (
			PRE_SUPPLIER_ASSIGNMENT_STATUSES.includes(oldStatus) &&
			supplierFacilityId &&
			privateSupplier
		) {
			newStatus = WORK_ORDER_STATUSES.assignedToInternalTech;
		} else if (PRE_SUPPLIER_ASSIGNMENT_STATUSES.includes(oldStatus) && supplierFacilityId) {
			newStatus = WORK_ORDER_STATUSES.pendingConfirmationByServiceProvider;
		} else if (
			oldStatus === WORK_ORDER_STATUSES.supplierInitiatedPendingApproval &&
			supplierFacilityId
		) {
			newStatus = WORK_ORDER_STATUSES.confirmedByServiceProvider;
		} else if (!supplierFacilityId) {
			newStatus = WORK_ORDER_STATUSES.unassigned;
		} else {
			newStatus = oldStatus;
		}
		return newStatus;
	};
	const nextStatus = getStatus(workOrder.supplierFacilityId, workOrder.status);

	return (
		<div className={'mb-4'}>
			{isPendingApproval ? (
				<ApprovalHierarchyDisplayWorkOrder
					workOrder={workOrder}
					currentUser={currentUser}
					companyConfig={companyConfig}
					style={{}}
					allowedToApprove={true}
					userType={userType}
					refreshWorkOrder={refreshWorkOrder}
					approveWorkOrder={approveWorkOrder}
					syncWorkOrder={syncWorkOrder(nextStatus)}
				/>
			) : isSnoozed ? (
				<UndeferComponent
					companyConfig={companyConfig}
					workOrder={workOrder}
					currentUser={currentUser}
					unsnoozeWorkOrder={unsnoozeWorkOrder}
					refreshWorkOrder={refreshWorkOrder}
				/>
			) : !reviewAndCompleteDisabled ? (
				<ReviewAndMarkCompleteComponent
					workOrder={workOrder}
					showApproveModal={showApproveModal}
					showReminderModal={showReminderModal}
				/>
			) : null}
		</div>
	);
};

const mapStateToProps = (state, ownProps) => ({});
const mapDispatchToProps = (dispatch, ownProps) => ({
	approveWorkOrder: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? approveWorkOrderForSupplier(entity)
				: approveWorkOrderForBuyer(entity)
		),
	syncWorkOrder: (status) => (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? syncWorkOrderApprovalHierarchyForSupplier(entity, status)
				: syncWorkOrderApprovalHierarchyForBuyer(entity, status)
		),
	unsnoozeWorkOrder: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? unsnoozeWorkOrderForSupplier(entity)
				: unsnoozeWorkOrderForBuyer(entity)
		),
});
const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(WorkOrderActions)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

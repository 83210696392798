import * as React from 'react';
import { Layout, DatePicker, Button, List } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { metersRestCrudThunksForSupplier } from '../../thunks/meters_thunks';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import { Link } from 'react-router-dom';

const style = require('./SupplierMetersDetailPage.less');
const queryString = require('qs');
const ListItem = List.Item;
const ListItemMeta = ListItem.Meta;
const { Content } = Layout;
const { RangePicker } = DatePicker;
const dateFormat = 'MM-DD-YYYY';

interface MetersDetailPageState {}

interface MetersDetailPageProps {
	match: any;
}

class SupplierMetersDetailPage extends React.Component<any, MetersDetailPageState> {
	state = {
		loading: true,
		backLinkTo: '/supplier/meters',
	};

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const { getMeter, match, location } = this.props;
		const meterId = match.params.id;
		getMeter(meterId).then((_) => {
			let searchString = location.search;
			if (searchString[0] === '?') {
				searchString = searchString.slice(1);
			}
			const queryParams = queryString.parse(searchString);
			if (queryParams.backlinkParams) {
				this.setState({
					loading: false,
					backLinkTo: `/supplier/meters/overview/all?${queryParams.backlinkParams}`,
				});
			} else {
				this.setState({
					loading: false,
					backLinkTo: `/supplier/meters/overview/all`,
				});
			}
		});
	}

	handleTabChange = (key) => {
		const { history, match } = this.props;
		const meterId = match.params.id;
		history.push(`/supplier/meters/detail/${meterId}/${key}`);
	};

	handleDelete = () => {
		const { history, deleteMeter, meter } = this.props;
		deleteMeter(meter).then(() => {
			history.push('/supplier/meters/overview/all');
		});
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	render() {
		const { meter, metersFetching, match, companyConfig } = this.props;
		const meterId = match.params.id;
		const tabs = [
			{
				key: 'details',
				name: 'Overview',
			},
		];
		return (
			<div className="metersDetailPage">
				<DetailPageHeader
					exactPath={'/supplier/meters/detail/:id'}
					redirectPath={`/supplier/meters/detail/${meterId}/details`}
					backLinkText={'Back to meters'}
					backLinkTo={this.state.backLinkTo}
					title={metersFetching ? null : nullSafeGet('name', meter)}
					subtitle={
						<div className="metersDetailPage__subtitleRow">
							<div className="metersDetailPage__subtitle">
								{nullSafeGet('meterType.name', meter)}
							</div>
							<div className="metersDetailPage__subtitle">
								{nullSafeGet('location.name', meter)}
							</div>
							<div className="metersDetailPage__subtitle">{nullSafeGet('asset.name', meter)}</div>
						</div>
					}
					actions={[
						<Link key={1} to={`/supplier/meters/edit/${meterId}`} style={{ marginRight: 16 }}>
							<Button ghost={true}>Edit</Button>
						</Link>,
						<Button key={2} ghost={true} onClick={this.handleDelete}>
							Delete
						</Button>,
					]}
					tabs={tabs}
					backgroundColor={nullSafeGet('detail.config.theme.navblock', companyConfig)}
					handleTabChange={this.handleTabChange}
					checkTabActive={this.checkTabActive}
					childRoutes={this.props.childRoutes}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	metersFetching: state.meters.fetching,
	meter: state.meters.detail,
	match: ownProps.match,
	history: ownProps.history,
	companyConfig: state.company_config,
});

const mapDispatchToProps = (dispatch) => ({
	getMeter: (id) => dispatch(metersRestCrudThunksForSupplier.readOne(id)),
	deleteMeter: (entity) =>
		dispatch(metersRestCrudThunksForSupplier.delete(entity, 'id', null, true)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(SupplierMetersDetailPage)
);

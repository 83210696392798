export const NOTIFICATION_SHOWN = 'NOTIFICATION_SHOWN';
export const NOTIFICATION_CLOSED = 'NOTIFICATION_CLOSED';

export const notificationShown = (notificationName) => ({
	notificationName,
	type: NOTIFICATION_SHOWN,
});
export const notificationClosed = (notificationName) => ({
	notificationName,
	type: NOTIFICATION_CLOSED,
});

import React, { FC, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, message } from 'antd';
import { EmptyState } from '../empty_state/EmptyState';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import {
	fetchWithDifferntSearchName,
	nullSafeGetOrElse,
	renderCurrency,
} from '../../utils/DataAccessUtils';
import { ExportToCsv } from 'export-to-csv';
import { CSV_EXPORT_DEFAULTS } from '../../utils/DataConstants';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import { vendorsRestCrudThunksForSupplier } from '../../thunks/vendors_thunks';
import {
	downloadEquipmentCatalogPriceCSVForSupplier,
	equipmentCatalogsRestCrudThunksForSupplier,
	updateEquipmentCatalogsListPriceForSupplier,
} from '../../thunks/equipment_catalogs_thunks';
import { EQUIPMENT_CATALOGS_CRUD_ACTION_CREATORS } from '../../actions/equipment_catalogs_actions';
import { equipmentTypesRestCrudThunksForSupplier } from '../../thunks/equipment_types_thunks';
import UploadWithSampleModal from '../parts_index_page/UploadWithSampleModal';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import { renderEquipmenTypeSelect } from '../purchase_order_form/ReplaceEquipmentLineItemModal';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';

const TC_NAME = 'EQUIPMENT_CATALOGS_FOR_EQUIPMENT';
const CSV_TITLE = 'Sample_Price_List';
const EQUIPMENT_CATALOGS_ADVANCED_FILTERS = 'equipmentCatalogsAdvancedFilters';

const EquipmentsPriceListIndexPage: FC<any> = ({
	updateFilters,
	clearAndUpdateFilters,
	equipmentCatalogs,
	currentUser,
	updatePriceList,
	downloadPriceList,
	history,
	location,
	equipmentTypes,
	fetchEquipmentTypes,
	fetchMultipleEquipments,
	vendors,
	fetchVendors,
	fetchMultipleVendors,
	fetchPriceList,
}) => {
	const [downloading, setDownloading] = useState(false);
	const [uploadPopupVisible, setUploadPopupVisible] = useState(false);

	const showUpload = useCallback(() => setUploadPopupVisible(true), []);
	const hideUpload = useCallback(() => setUploadPopupVisible(false), []);

	const onRow = useCallback(
		(record) => ({
			onClick: () =>
				history.push(
					getLinkWIthBackLinkParams(
						location,
						'Back to Price List',
						`/supplier/equipments/equipmentCatalogs/detail/${record.id}`
					)
				),
		}),
		[history, location]
	);

	const columns = useMemo(
		() => [
			{
				title: 'Equipment Type #',
				dataIndex: ['equipmentType', 'equipmentTypeNumber'],
			},
			{
				title: 'Equipment Model Name',
				dataIndex: ['equipmentType', 'modelName'],
				key: 'equipmentModelName',
				sorter: true,
			},
			{
				title: 'Vendor Name',
				dataIndex: ['partEquipmentVendor', 'name'],
				key: 'vendorName',
				sorter: true,
			},
			{
				title: 'List Price',
				dataIndex: 'listPrice',
				render: renderCurrency(currentUser),
				key: 'listPrice',
				sorter: true,
			},
			{
				title: 'Last Price',
				dataIndex: 'unitCost',
				render: renderCurrency(currentUser),
				key: 'unitCost',
				sorter: true,
			},
		],
		[currentUser]
	);

	const filterConfig = useMemo(
		() => [
			{
				label: 'Equipment Type',
				fieldName: 'equipmentTypeIds',
				mode: 'multiple',
				stateSlice: equipmentTypes,
				targetCollectionName: EQUIPMENT_CATALOGS_ADVANCED_FILTERS,
				fetch: fetchWithDifferntSearchName(fetchEquipmentTypes, 'search'),
				fetchMultiple: fetchMultipleEquipments,
				renderRecord: renderEquipmenTypeSelect,
				component: OWAsyncSelect,
			},
			{
				label: 'Vendor',
				fieldName: 'partEquipmentVendorIds',
				mode: 'multiple',
				stateSlice: vendors,
				targetCollectionName: EQUIPMENT_CATALOGS_ADVANCED_FILTERS,
				fetch: fetchVendors,
				fetchMultiple: fetchMultipleVendors,
			},
		],
		[
			equipmentTypes,
			fetchEquipmentTypes,
			fetchMultipleEquipments,
			fetchMultipleVendors,
			fetchVendors,
			vendors,
		]
	);

	const download = useCallback(() => {
		const filters = nullSafeGetOrElse(`${TC_NAME}.filters`, equipmentCatalogs, {});
		setDownloading(true);
		downloadPriceList({}, filters).finally(() => setDownloading(false));
	}, [downloadPriceList, equipmentCatalogs]);

	const fields: any[] = useMemo(
		() => [
			{
				label: 'Equipment Type #',
				key: 'equipmentTypeNumber',
			},
			{
				label: 'Price',
				key: 'price',
			},
			{
				label: 'Effective Date (MM/DD/YYYY)',
				key: 'effectiveDate',
			},
			{
				label: 'Vendor ID',
				key: 'vendorId',
			},
		],
		[]
	);

	const onDownloadSample = useCallback(() => {
		const csvExporter = new ExportToCsv({
			...CSV_EXPORT_DEFAULTS,
			filename: CSV_TITLE,
			title: CSV_TITLE,
			headers: fields.map((_) => _.label),
		});
		csvExporter.generateCsv([
			{
				equipmentTypeNumber: 1234,
				price: 10,
				effectiveDate: '01/31/2023',
				vendorId: 123,
			},
			{
				equipmentTypeNumber: 4321,
				price: 20,
				effectiveDate: '02/27/2023',
				vendorId: 123,
			},
		]);
	}, [fields]);

	const getStatSliceProp = useCallback(
		(propName) => nullSafeGetOrElse(`${TC_NAME}.${propName}`, equipmentCatalogs, {}),
		[equipmentCatalogs]
	);

	const refreshPriceList = useCallback(() => {
		fetchPriceList(
			{},
			TC_NAME,
			getStatSliceProp('pagination'),
			getStatSliceProp('sorting'),
			getStatSliceProp('filters')
		);
	}, [fetchPriceList, getStatSliceProp]);

	const onDataUpload = useCallback(
		(results) => {
			return new Promise((resolve, reject) => {
				updatePriceList(results.validData)
					.then((res) => {
						resolve('Updated successfully!');
						setTimeout(() => {
							hideUpload();
							message.success('Updated successfully!');
							refreshPriceList();
						}, 0);
					})
					.catch((err) => {
						const genericError = 'Unable to upload! Please contact support!';
						const message = nullSafeGetOrElse('error', err, []).reduce((acc, obj) => {
							const suffix = `${obj.reason} for equipment type # ${obj.equipmentTypeNumber}`;
							return acc ? acc + '; ' + suffix : suffix;
						}, '');
						reject(message || genericError);
					});
			});
		},
		[hideUpload, refreshPriceList, updatePriceList]
	);

	return (
		<>
			<PaginatedReduxTableWithHeader
				targetCollectionName={TC_NAME}
				updateFilters={updateFilters}
				stateSlice={equipmentCatalogs}
				clearAndUpdateFilters={clearAndUpdateFilters}
				filterConfig={filterConfig}
				entityCollectionName="equipment_catalogs"
				tableColumns={columns}
				onTableRow={onRow}
				showHeader
				fetchData={equipmentCatalogsRestCrudThunksForSupplier.read}
				rightActions={
					<div className="flex flex-row items-center">
						<Button
							className="ml-2"
							type="ghost"
							size="large"
							icon={<DownloadOutlined translate="" />}
							loading={downloading}
							onClick={download}
						>
							Export CSV
						</Button>
						<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PARTS_AND_EQUIPMENTS}>
							<Button
								className="ml-2"
								type="primary"
								size="large"
								icon={<UploadOutlined translate="" />}
								onClick={showUpload}
							>
								Upload
							</Button>
						</AccessPermissionChecker>
					</div>
				}
				emptyState={
					<EmptyState
						graphic={
							<img
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
								alt="Looks like your team has not added any price list yet."
							/>
						}
						headline={"Nothing's here!"}
						body={
							<div style={{ textAlign: 'center' }}>
								<div style={{ maxWidth: 440, marginBottom: 16 }}>
									Looks like your team has not added any price list yet.
								</div>
							</div>
						}
					/>
				}
			/>
			{uploadPopupVisible && (
				<UploadWithSampleModal
					type="Price List"
					title="Upload Price List"
					onCancel={hideUpload}
					onDownloadSample={onDownloadSample}
					fields={fields}
					onDataLoad={onDataUpload}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
	equipmentCatalogs: state.equipment_catalogs,
	equipmentTypes: state.equipment_types,
	vendors: state.vendors,
});

const mapDispatchToProps = (dispatch) => ({
	updateFilters: (filters, targetCollection) =>
		dispatch(EQUIPMENT_CATALOGS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			EQUIPMENT_CATALOGS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)
		),
	updatePriceList: (entities) => dispatch(updateEquipmentCatalogsListPriceForSupplier(entities)),
	downloadPriceList: (params, filters) =>
		dispatch(downloadEquipmentCatalogPriceCSVForSupplier(params, filters)),
	fetchMultipleEquipments: (ids, targetCollectionName) =>
		dispatch(equipmentTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchEquipmentTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			equipmentTypesRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleVendors: (ids, targetCollectionName) =>
		dispatch(vendorsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchVendors: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			vendorsRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchPriceList: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			equipmentCatalogsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(EquipmentsPriceListIndexPage)
);

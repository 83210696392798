import { Button, Checkbox, Form, Modal } from 'antd';
import React, { FC } from 'react';

const FORM_ID = 'wo-print-options-form';

const labelCol = { span: 22 };

const WorkOrderPrintOptionsModal: FC<any> = ({ onCancel, onSubmit }): React.ReactElement => {
	const [form] = Form.useForm();

	const initialValues = {
		includeImages: true,
		includeServiceCalls: true,
		includeNotes: true,
	};

	return (
		<Modal
			visible={true}
			width={400}
			title={'Include following in pdf'}
			closable
			onCancel={onCancel}
			forceRender
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button type="primary" size="large" key="submit" htmlType="submit" form={FORM_ID}>
					Print
				</Button>,
			]}
		>
			<Form
				id={FORM_ID}
				form={form}
				layout="horizontal"
				requiredMark={false}
				initialValues={initialValues}
				onFinish={onSubmit}
			>
				<Form.Item
					label="Include Images"
					name="includeImages"
					valuePropName="checked"
					labelAlign="left"
					labelCol={labelCol}
				>
					<Checkbox />
				</Form.Item>
				<Form.Item
					name="includeServiceCalls"
					label="Include Service Calls"
					valuePropName="checked"
					labelAlign="left"
					labelCol={labelCol}
				>
					<Checkbox />
				</Form.Item>
				<Form.Item
					name="includeNotes"
					label="Include Notes"
					valuePropName="checked"
					labelAlign="left"
					labelCol={labelCol}
				>
					<Checkbox />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default WorkOrderPrintOptionsModal;

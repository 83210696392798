import RestCrudThunks from './restCrudThunksGenerator';
import { INVOICE_DOWNLOAD_FORMATS_CRUD_ACTION_CREATORS } from '../actions/invoice_download_formats_actions';
import { ROLE_TYPES } from '../utils/DataConstants';

export const invoiceDownloadFormatsRestCrudThunksForBuyer = new RestCrudThunks(
	'invoice_download_formats',
	'invoice_download_format',
	'invoiceDownloadFormat',
	'invoiceDownloadFormats',
	INVOICE_DOWNLOAD_FORMATS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'invoice/'
);

export const invoiceDownloadFormatsRestCrudThunksForSupplier = new RestCrudThunks(
	'invoice_download_formats',
	'invoice_download_format',
	'invoiceDownloadFormat',
	'invoiceDownloadFormats',
	INVOICE_DOWNLOAD_FORMATS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'invoice/'
);

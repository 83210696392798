import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { partsPerStockLocationsRestCrudThunksForSupplier } from '../../thunks/parts_per_stock_locations_thunks';
import { Link } from 'react-router-dom';
import { Button, Popover, message } from 'antd';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import PPSLAdjustmentModal from './PPSLAdjustmentModal';
import { ppslAuditsCrudThunksForSupplier } from '../../thunks/ppsl_audits_thunks';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';

const PPSL_AUDITS_TC_NAME = 'ppslAuditsIndex';

const PartsPerStockLocationDetailPage: FC<any> = ({
	companyConfig,
	ppsl,
	childRoutes,
	history,
	match,
	getPpsl,
	location,
	ppslAudits,
	fetchPpslAudits,
	fetching,
	deletePpsl,
}): React.ReactElement => {
	const [adjustModalVisible, setAdjustModalVisible] = useState(false);

	const [deleting, setDeleting] = useState(false);

	const _showAdjustModal = () => setAdjustModalVisible(true);
	const _hideAdjustModal = () => setAdjustModalVisible(false);

	const tabs = useMemo(
		() => [
			{
				key: 'details',
				name: 'Overview',
			},
			{
				key: 'workOrders',
				name: 'Work Orders',
			},
		],
		[]
	);

	const stockLocationId = useMemo(() => nullSafeGet('stockLocation.id', ppsl), [ppsl]);

	const partsPerStockLocationId = useMemo(() => match.params.id, [match.params.id]);

	const DEFAULT_BACK_LINK = useMemo(
		() => `/supplier/stockLocations/detail/${stockLocationId}/parts`,
		[stockLocationId]
	);

	useEffect(
		() => partsPerStockLocationId && getPpsl(partsPerStockLocationId),
		[partsPerStockLocationId, getPpsl]
	);

	const getStatSliceProp = useCallback(
		(propName) => nullSafeGetOrElse(`${PPSL_AUDITS_TC_NAME}.${propName}`, ppslAudits, {}),
		[ppslAudits]
	);

	const refreshPpslAudits = useCallback(() => {
		fetchPpslAudits(
			{ partsPerStockLocationId },
			PPSL_AUDITS_TC_NAME,
			getStatSliceProp('pagination'),
			getStatSliceProp('sorting'),
			getStatSliceProp('filters')
		);
	}, [fetchPpslAudits, getStatSliceProp, partsPerStockLocationId]);

	const handleTabChange = useCallback(
		(key) => {
			const ppslId = match.params.id;
			history.push(`/supplier/parts/partsPerStockLocation/detail/${ppslId}/${key}`);
		},
		[history, match.params.id]
	);

	const checkTabActive = useCallback(
		(key) => {
			const pathname = history.location.pathname;
			return pathname.indexOf(key) !== -1;
		},
		[history.location.pathname]
	);

	const onAdjustSuccess = useCallback(() => {
		_hideAdjustModal();
		refreshPpslAudits();
		partsPerStockLocationId && getPpsl(partsPerStockLocationId);
	}, [getPpsl, partsPerStockLocationId, refreshPpslAudits]);

	const delistDisabledByQuantity = useMemo(
		() =>
			nullSafeGetOrElse('onHandQuantity', ppsl, 0) > 0 ||
			nullSafeGetOrElse('requestedQuantity', ppsl, 0) > 0,
		[ppsl]
	);

	const delistDisabled = useMemo(
		() => delistDisabledByQuantity || ppsl.isAssociatedWithWO || fetching,
		[delistDisabledByQuantity, fetching, ppsl.isAssociatedWithWO]
	);

	const onDelete = useCallback(() => {
		setDeleting(true);
		deletePpsl(ppsl)
			.then(() => {
				history.push(DEFAULT_BACK_LINK);
			})
			.catch((err) => message.error(err))
			.finally(() => setDeleting(false));
	}, [DEFAULT_BACK_LINK, deletePpsl, history, ppsl]);

	return (
		<>
			<DetailPageHeader
				exactPath={'/supplier/parts/partsPerStockLocation/detail/:id'}
				redirectPath={`/supplier/parts/partsPerStockLocation/detail/${partsPerStockLocationId}/details`}
				backLinkText={'Back to Stock Location'}
				backLinkTo={DEFAULT_BACK_LINK}
				title={nullSafeGetOrElse('part.name', ppsl, '')}
				subtitle={
					<div className="flex flex-col">
						<div>{`Part #${nullSafeGetOrElse('part.partNumber', ppsl, '--')}`}</div>
						<div>{`@ ${nullSafeGetOrElse('stockLocation.name', ppsl, '')}`}</div>
					</div>
				}
				actions={[
					<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PARTS_AND_EQUIPMENTS}>
						<Button ghost={true} onClick={_showAdjustModal}>
							Adjust
						</Button>
					</AccessPermissionChecker>,
					<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PARTS_AND_EQUIPMENTS}>
						<Link
							key={1}
							to={`/supplier/parts/partsPerStockLocation/edit/${partsPerStockLocationId}`}
						>
							<Button className="ml-4" ghost={true}>
								Edit
							</Button>
						</Link>
					</AccessPermissionChecker>,
					<Link
						className="ml-4"
						key={1}
						to={getLinkWIthBackLinkParams(
							location,
							'Back to Parts per Stock Location',
							`/supplier/parts/detail/${nullSafeGet('partId', ppsl)}/details`
						)}
					>
						<Button ghost={true}>View Part</Button>
					</Link>,
					<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PARTS_AND_EQUIPMENTS}>
						<Popover
							content={
								delistDisabledByQuantity
									? `Part cannot be delisted as it has valid quantity!`
									: ppsl.isAssociatedWithWO
									? `Part cannot be delisted as it is associated with work order(s)!`
									: ''
							}
							trigger="hover"
						>
							<Button
								className="ml-4"
								ghost={true}
								onClick={onDelete}
								disabled={delistDisabled}
								loading={deleting}
							>
								Delist
							</Button>
						</Popover>
					</AccessPermissionChecker>,
				]}
				tabs={tabs}
				backgroundColor={nullSafeGet('detail.config.theme.navblock', companyConfig)}
				handleTabChange={handleTabChange}
				checkTabActive={checkTabActive}
				childRoutes={childRoutes}
			/>
			{adjustModalVisible ? (
				<PPSLAdjustmentModal ppsl={ppsl} onCancel={_hideAdjustModal} onSuccess={onAdjustSuccess} />
			) : null}
		</>
	);
};

const mapStateToProps = (state) => ({
	companyConfig: state.company_config,
	fetching: state.parts_per_stock_locations.fetching,
	ppsl: state.parts_per_stock_locations.detail,
	ppslAudits: state.ppsl_audits,
});

const mapDispatchToProps = (dispatch) => ({
	getPpsl: (id) => dispatch(partsPerStockLocationsRestCrudThunksForSupplier.readOne(id)),
	deletePpsl: (entity) => dispatch(partsPerStockLocationsRestCrudThunksForSupplier.delete(entity)),
	fetchPpslAudits: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ppslAuditsCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(PartsPerStockLocationDetailPage)
);

import { ContactAvatar } from '../contact_avatar/ContactAvatar';
import * as React from 'react';
import { Col, List, Row, Select } from 'antd';
import UserListItemComponent from '../user_list_item_component/UserListItemComponent';

export default class UserListComponent extends React.Component<any, any> {
	constructor(props) {
		super(props);
	}
	removeUser = (user) => {
		this.props.removeUser(user);
	};
	render() {
		const {
			users = [],
			token,
			allowRemove,
			trailingItems = [],
			rightItems,
			style,
			emptyState = null,
			contactType,
		} = this.props;

		return users.length === 0 && trailingItems.length === 0 ? (
			<Row className={'UserRow ml-1.5'}>{emptyState}</Row>
		) : (
			<List className="UsersList" style={style}>
				{users.map((user) => {
					return user.contact !== undefined ? (
						<Row style={{ marginLeft: '8px' }} key={user.contact.email}>
							<UserListItemComponent
								key={user.contact.email}
								user={user}
								token={token}
								style={style}
								className="UserRow"
								rightItems={rightItems}
								allowRemove={allowRemove}
								removeUser={this.removeUser}
								contactType={contactType}
							/>
						</Row>
					) : (
						<Row>
							<UserListItemComponent
								key={user.email}
								user={user}
								token={token}
								style={style}
								className="UserRow"
								rightItems={rightItems}
								allowRemove={allowRemove}
								removeUser={this.removeUser}
								contactType={contactType}
							/>
						</Row>
					);
				})}
				{trailingItems.map((el) => {
					return el;
				})}
			</List>
		);
	}
}

import { Badge, Card, Collapse, Tag } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import AssetDowntimeStatus from './AssetDowntimeStatus';
import DowntimeWorkOrdersTable from './DowntimeWorkOrdersTable';
import { dateFormatter, DATE_FORMAT } from '../../utils/DataFormatterUtils';
import { assetDowntimeGet } from '../../thunks/assets_thunks';
import { BUYER_ADMIN_ONLY } from '../roles/roles';
import { EditOutlined } from '@ant-design/icons';
import AssetStatusForm from '../asset_status_form/AssetStatusForm';
import AssetEntryEditForm from '../asset_status_form/AssetEntryEditForm';

const { Panel } = Collapse;

const AssetDowntimeHistoryIndexPage: FC<any> = ({
	asset,
	getAssetDowntime,
	userType,
	currentUser,
	match,
}) => {
	const [editFormVisible, setEditFormVisible] = useState(null);
	const [downTimeHistory, setDownTimeHistory] = useState([]);

	const getHistory = () => {
		const assetId = match.params.id;
		getAssetDowntime({ assetId }, userType).then((data = []) => {
			setDownTimeHistory(data.sort((a, b) => b.startTime - a.startTime));
		});
	};
	useEffect(() => {
		getHistory();
	}, []);

	const getCardTitle = useCallback(
		(dtHistory) =>
			dtHistory.endTime || dtHistory.reason === 'falsePositive'
				? `Incident from ${dateFormatter(dtHistory.startTime, DATE_FORMAT)} ${
						dtHistory.endTime ? `to ${dateFormatter(dtHistory.endTime, DATE_FORMAT)}` : ''
				  }`
				: `Ongoing incident started at ${dateFormatter(dtHistory.startTime, DATE_FORMAT)}`,
		[]
	);
	const isAdmin = currentUser.roles.some((role) => BUYER_ADMIN_ONLY.has(role));

	return (
		<div className="flex flex-col p-4">
			<div className="mb-4">
				<AssetDowntimeStatus downtimeHistory={downTimeHistory || []} />
			</div>
			{editFormVisible && (
				<AssetEntryEditForm
					downTimeObj={editFormVisible}
					visible={editFormVisible}
					asset={asset}
					onCancel={() => setEditFormVisible(null)}
					onSuccess={() => {
						setEditFormVisible(null);
						getHistory();
					}}
					editEntryMode
				/>
			)}
			{downTimeHistory && downTimeHistory.length > 0 ? (
				<Card>
					<Collapse defaultActiveKey="dtHistory-0" ghost>
						{downTimeHistory.map((dtHistory, idx) => (
							<Panel
								header={
									<div className="flex flex-row">
										{getCardTitle(dtHistory)}
										{dtHistory.reason === 'falsePositive' ? (
											<div className="ml-2 inline-block">
												<Tag color="magenta">Offline by mistake</Tag>
											</div>
										) : null}
									</div>
								}
								key={`dtHistory-${idx}`}
								extra={
									isAdmin ? (
										<EditOutlined translate="" onClick={() => setEditFormVisible(dtHistory)} />
									) : null
								}
							>
								<DowntimeWorkOrdersTable dtHistory={dtHistory} userType={userType} />
							</Panel>
						))}
					</Collapse>
				</Card>
			) : null}
		</div>
	);
};

const mapStateToProps = (state) => ({
	asset: state.assets.detail,
	userType: state.session.userType,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	getAssetDowntime: (params, userType) => dispatch(assetDowntimeGet(userType)(params)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(AssetDowntimeHistoryIndexPage)
);

import {
	DEFAULT_PAGE_SIZE,
	standardInitialStateGenerator,
	standardReducerGenerator,
} from './standard_reducer_utils';
import {
	FETCH_BUYER_COMPANY_PRIVATE_NETWORK_WITH_LOCATIONS_SUCCESS,
	FETCH_DISTINCT_SUPPLIER_PRIVATE_NETWORKS_SUCCESS,
	FETCH_HYDRATED_DISTINCT_SUPPLIER_PRIVATE_NETWORKS_SUCCESS,
	FETCH_SUPPLIER_PRIVATE_NETWORKS_FOR_LOCATION_SUCCESS,
	FETCH_SUPPLIERS_FOR_BUYERS_SUCCESS,
	ADD_SUPPLIERS_FOR_BUYERS_SUCCESS,
	SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS,
} from '../actions/supplier_private_networks_actions';

const baseInitialState: any = standardInitialStateGenerator([
	'WORK_ORDER_FORM_SUPPLIER_PRIVATE_NETWORKS_AUTOCOMPLETE',
	'supplierPrivateNetworksIndex',
	'supplierPrivateNetworksHydratedIndex',
	'supplierPrivateNetworkAnalyticsDropdown',
	'supplierPrivateNetworkBySupplierFacility',
	'supplierPrivateNetworksSearch',
]);
const initialState = {
	...baseInitialState,
	distinctRecords: {},
	searchRecords: {},
	inviteRecord: {},
	locationDetailCount: 0,
	locationDetailPagination: {
		current: 1,
		pageSize: DEFAULT_PAGE_SIZE,
	},
	locationDetailSorting: {},
	locationDetailFilters: {},
	locationDetailRecords: [],
	buyerCompanyDetailCount: 0,
	buyerCompanyDetailPagination: {
		current: 1,
		pageSize: DEFAULT_PAGE_SIZE,
	},
	buyerCompanyDetailSorting: {},
	buyerCompanyDetailFilters: {},
	buyerCompanyDetailRecords: [],
};
const supplierPrivateNetworksReducerCrudLogic = standardReducerGenerator(
	CRUD_ACTION_CONSTANTS,
	initialState
);

export default (oldState = initialState, action): any => {
	Object.freeze(oldState);
	let newRecords;
	let newRecordsIds;
	let newState;
	switch (action.type) {
		case FETCH_DISTINCT_SUPPLIER_PRIVATE_NETWORKS_SUCCESS:
			newRecords = action.records.reduce((acc, el) => {
				acc[`${el.supplierFacilityId}`] = el;
				return acc;
			}, {});
			newRecordsIds = action.records.map((el) => el.supplierFacilityId);

			// add new records to base collection
			newState = {
				...oldState,
				fetching: false,
				fetchErrors: [],
				count: action.count,
				distinctRecords: { ...oldState.distinctRecords, ...newRecords },
			};

			// if update targeting child collection, replace records
			if (action.targetCollectionName) {
				newState[action.targetCollectionName] = {
					recordsIds: newRecordsIds,
					records: action.records,
					count: action.count,
					fetching: false,
					fetchErrors: [],
				};
				// store pagination, sorting, and filters state of table if applicable
				if (action.pagination) {
					newState[action.targetCollectionName].pagination = action.pagination;
				}
				if (action.sorting) {
					newState[action.targetCollectionName].sorting = action.sorting;
				}
				if (action.filters) {
					newState[action.targetCollectionName].filters = action.filters;
				}
			} else {
				newState.recordsIds = newRecordsIds;
				if (action.pagination) {
					newState.pagination = action.pagination;
				}
				if (action.sorting) {
					newState.sorting = action.sorting;
				}
				if (action.filters) {
					newState.filters = action.filters;
				}
			}
			return newState;
		case FETCH_HYDRATED_DISTINCT_SUPPLIER_PRIVATE_NETWORKS_SUCCESS:
			newRecords = action.records.reduce((acc, el) => {
				acc[`${el.supplierFacilityId}`] = el;
				return acc;
			}, {});
			newRecordsIds = action.records.map((el) => el.supplierFacilityId);

			// add new records to base collection
			newState = {
				...oldState,
				fetching: false,
				fetchErrors: [],
				count: action.count,
				distinctRecords: { ...oldState.distinctRecords, ...newRecords },
			};

			// if update targeting child collection, replace records
			if (action.targetCollectionName) {
				newState[action.targetCollectionName] = {
					recordsIds: newRecordsIds,
					count: action.count,
					fetching: false,
					fetchErrors: [],
				};
				// store pagination, sorting, and filters state of table if applicable
				if (action.pagination) {
					newState[action.targetCollectionName].pagination = action.pagination;
				}
				if (action.sorting) {
					newState[action.targetCollectionName].sorting = action.sorting;
				}
				if (action.filters) {
					newState[action.targetCollectionName].filters = action.filters;
				}
			} else {
				newState.recordsIds = newRecordsIds;
				if (action.pagination) {
					newState.pagination = action.pagination;
				}
				if (action.sorting) {
					newState.sorting = action.sorting;
				}
				if (action.filters) {
					newState.filters = action.filters;
				}
			}
			return newState;
		case FETCH_SUPPLIER_PRIVATE_NETWORKS_FOR_LOCATION_SUCCESS:
			return {
				...oldState,
				fetching: false,
				fetchErrors: [],
				locationDetailCount: action.count,
				locationDetailPagination: action.pagination,
				locationDetailSorting: action.sorting,
				locationDetailFilters: action.filters,
				locationDetailRecords: action.records,
			};
		case FETCH_BUYER_COMPANY_PRIVATE_NETWORK_WITH_LOCATIONS_SUCCESS:
			return {
				...oldState,
				fetching: false,
				fetchErrors: [],
				buyerCompanyDetailCount: action.count,
				buyerCompanyDetailPagination: action.pagination,
				buyerCompanyDetailSorting: action.sorting,
				buyerCompanyDetailFilters: action.filters,
				buyerCompanyDetailRecords: action.records,
			};
		case FETCH_SUPPLIERS_FOR_BUYERS_SUCCESS:
			return {
				...oldState,
				fetching: false,
				fetchErrors: [],
				searchRecords: action,
			};
		case ADD_SUPPLIERS_FOR_BUYERS_SUCCESS:
			return {
				...oldState,
				fetching: false,
				fetchErrors: [],
				inviteRecord: action,
			};
		default:
			return supplierPrivateNetworksReducerCrudLogic(oldState, action);
	}
};

import { Card, Popover } from 'antd';
import React, { FC, useMemo } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { assetInvoiceDetailsDisplayColor } from '../../utils/assetUtils';
import { getCurrency, nullSafeGet, roundOffToDecimal } from '../../utils/DataAccessUtils';
import { withRouter } from 'react-router';
import { Form } from '@ant-design/compatible';
import { InfoCircleOutlined } from '@ant-design/icons';

const AssetInvoice: FC<any> = ({
	asset,
	userType,
	currentUser,
	card = true,
}): React.ReactElement => {
	const { originalPrice } = asset;
	const currency = useMemo(() => getCurrency({ currentUser }), [currentUser]);

	const total = useMemo(() => nullSafeGet('invoicesTotal', asset), [asset]);

	const totalSpent = useMemo(
		() => (
			<div
				className="mt-4 text-center text-4xl font-semibold"
				style={{ color: assetInvoiceDetailsDisplayColor(asset) }}
			>
				{currency.format(total, 0)}
			</div>
		),
		[total, asset]
	);
	const ratio = useMemo(
		() => roundOffToDecimal((total / parseFloat(originalPrice)) * 100, 0),
		[total, originalPrice]
	);
	return card ? (
		<Card>
			{totalSpent}
			<div className="text-center text-base font-normal text-gray-500">
				spent on maintenance
				{originalPrice && total ? (
					<Popover
						style={{ marginTop: '-8px' }}
						content={<div>{ratio}% of purchase price spent</div>}
					>
						<span style={{ fontSize: '12px', color: 'rgba(0,0,0,0.6)', marginLeft: 8 }}>
							<InfoCircleOutlined translate="" />
						</span>
					</Popover>
				) : null}
			</div>
			{total > 0 ? (
				<Link to={`/${userType}/invoices/overview/all?assetIds=${asset.id}`}>
					<div className="mt-8 text-center text-violet-700">More</div>
				</Link>
			) : null}
		</Card>
	) : (
		<span style={{ color: assetInvoiceDetailsDisplayColor(asset) }}>
			{currency.format(total, 0)}
		</span>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(AssetInvoice)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(Form.create()(ComponentWithoutUserType));

import { crudActionCreatorsGenerator } from './crud_action_creators_generator';
import { crudActionConstantsGenerator } from './crud_action_constants_generator';
import { updateNavbarState } from './navbar_actions';

export const WORK_ORDERS_CRUD_ACTION_CONSTANTS = crudActionConstantsGenerator('WORK_ORDERS');
const WORK_ORDERS_CRUD_ACTION_CREATORS = crudActionCreatorsGenerator(
	WORK_ORDERS_CRUD_ACTION_CONSTANTS
);

const queryString = require('qs');

const originalFn = WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess;
WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess = function (workOrder) {
	return function (dispatch, getState) {
		const { navbar } = getState();
		const { backlinkTitle, navbarBackgroundColor } = navbar;
		if (navbar && navbar.backlinkTo) {
			const [path, queryParams] = navbar.backlinkTo.split('?');
			const urlSearchParams = new URLSearchParams(queryParams);
			urlSearchParams.delete('preserve');
			const backlinkTo = `${path}?${urlSearchParams.toString()}`;
			dispatch(updateNavbarState(backlinkTitle, backlinkTo, navbarBackgroundColor));
		}
		dispatch(originalFn(workOrder));
	};
};

export { WORK_ORDERS_CRUD_ACTION_CREATORS };

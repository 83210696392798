import { DEPRECIATION_CLASS_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/depreciation_class_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = standardInitialStateGenerator([
	'depreciationClassIndex',
	'assetTypesDropdown',
	'assetsAdvancedFilters',
	'assetTypesAdvancedFilters',
]);
export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState);

import * as React from 'react';
import { Card } from 'antd';

const style = require('./SimpleRecordContainer.less');

export const SimpleRecordContainer = ({ items, mode = 'row' }) => {
	return (
		<div className={mode === 'row' ? 'simpleRowRecordContainer' : 'simpleCardRecordContainer'}>
			{items.map((item) => (
				<div className={mode === 'row' ? 'rowDisplay' : 'cardDisplay'}>{item}</div>
			))}
		</div>
	);
};

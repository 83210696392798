import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { Button, Card, Popover, Select } from 'antd';
import { getElementAt, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { dateFormatter } from '../../utils/DataFormatterUtils';

const AssetDowntimeStatus: FC<any> = ({
	downtimeHistory,
	onSeeDetail,
	showDetailsButton,
}): React.ReactElement => {
	const ref = useRef(null);

	const [displayDays, setDisplayDays] = useState(90);
	const [parentWidth, setParentWidth] = useState(0);

	const setParentDimentions = useCallback(() => {
		ref.current && setParentWidth(ref.current.offsetWidth);
	}, []);

	useEffect(() => {
		setParentDimentions();
	}, [setParentDimentions]);

	useEffect(() => {
		window.addEventListener('resize', setParentDimentions);
		return () => {
			window.removeEventListener('resize', setParentDimentions);
		};
	}, [setParentDimentions]);

	const handleChange = useCallback((val) => setDisplayDays(val), []);

	const findHistory = useCallback(
		(date) =>
			downtimeHistory
				.filter((history) => history.reason !== 'falsePositive')
				.find((history) =>
					date.isBetween(history.startTime, history.endTime || moment(), 'days', [])
				),
		[downtimeHistory]
	);

	const days = useMemo(() => {
		return Array(displayDays)
			.fill(0)
			.map((_, idx) => {
				const date = moment().subtract(idx, 'days');
				return {
					date,
					history: findHistory(date),
				};
			})
			.reverse();
	}, [displayDays, findHistory]);

	const gapCount = useMemo(() => {
		switch (displayDays) {
			case 7:
				return 48;
			case 90:
				return 89;
			default:
				return 87;
		}
	}, [displayDays]);

	const eachWidth = useMemo(
		() => (parentWidth - gapCount) / displayDays,
		[displayDays, parentWidth, gapCount]
	);

	const getOfflineStatus = useCallback(
		(history, date) => {
			const woCount = nullSafeGetOrElse('associatedWorkOrderIds', history, []).length;
			return (
				<Popover
					content={
						<div className="flex flex-col">
							<div>{`Date: ${dateFormatter(date, 'MMM DD, yyyy')}`}</div>
							{woCount && <div>{`${woCount} work order${woCount > 1 ? 's' : ''}`}</div>}
							{showDetailsButton ? (
								<Button type="link" onClick={onSeeDetail}>
									See details
								</Button>
							) : null}
						</div>
					}
				>
					<div
						style={{
							height: '24px',
							width: `${eachWidth}px`,
							backgroundColor: '#D65645',
						}}
						className="cursor-pointer"
					/>
				</Popover>
			);
		},
		[showDetailsButton, onSeeDetail, eachWidth]
	);

	return (
		<Card
			title={
				<div className="flex flex-row items-center">
					<div className="mr-2">Downtime history for</div>
					<Select
						defaultValue={90}
						style={{ width: 240 }}
						onChange={handleChange}
						options={[
							{
								value: 7,
								label: 'Last 1 week',
							},
							{
								value: 30,
								label: 'Last 1 month',
							},
							{
								value: 90,
								label: 'Last 3 months',
							},
						]}
					/>
				</div>
			}
		>
			<div className="flex flex-row justify-between" ref={ref}>
				{days.map(({ date, history }) =>
					history ? (
						getOfflineStatus(history, date)
					) : (
						<Popover content={<div>{`Date: ${dateFormatter(date, 'MMM DD, yyyy')}`}</div>}>
							<div
								style={{
									height: '24px',
									width: `${eachWidth}px`,
									backgroundColor: '#00AA66',
								}}
							></div>
						</Popover>
					)
				)}
			</div>
			<div className="mt-2 flex flex-row justify-between">
				<div>{dateFormatter(days[0].date as unknown as string, 'MMM DD, yyyy')}</div>
				<div>{dateFormatter(getElementAt(days, -1).date as unknown as string, 'MMM DD, yyyy')}</div>
			</div>
		</Card>
	);
};

export default AssetDowntimeStatus;

import { REGIONS_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/regions_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = standardInitialStateGenerator([
	'LOCATION_FORM_REGIONS_AUTOCOMPLETE',
	'workOrdersAdvancedFilters',
	'techAnalyticsAdvancedFilters',
	'locationsAdvancedFilters',
	'assetsAdvancedFilters',
	'siteSurveysAdvancedFilters',
	'invoicesAdvancedFilters',
	'quotesAdvancedFilters',
	'metersAdvancedFilters',
	'SUPPLIER_PRIVATE_NETWORK_FORM_LOCATIONS_AUTOCOMPLETE',
	'regionsWithChildrenDropdown',
	'supplierPrivateNetworksAdvancedFilters',
]);
export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState);

import * as React from 'react';
const style = require('./SubnavBar.less');
export default ({ left, right, leftStyle = {}, rightStyle = {}, ...props }) => (
	<div className="controlsBar" {...props}>
		<div style={leftStyle} className="controlsBar__leftGroup">
			{left}
		</div>
		<div style={rightStyle} className="controlsBar__rightGroup">
			{right}
		</div>
	</div>
);

import * as React from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { Redirect, Route, withRouter } from 'react-router';
import ScrollToTopOnMount from '../scroll_to_top_on_mount/ScrollToTopOnMount';
import SubnavTabs from '../subnav_tabs/SubnavTabs';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';
import { nullSafeGet } from '../../utils/DataAccessUtils';

const G2 = require('g2');
const DataSet = require('@antv/data-set');
const { Content } = Layout;

class WorkAnalyticsOverviewPage extends React.Component<any, any> {
	handleTabChange = (key) => {
		const { history, userType } = this.props;
		history.push(`/${userType}/analytics/work/overview/${key}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	render() {
		const { companyConfig, userType } = this.props;
		const tabs = [
			{
				key: 'priorities',
				name: 'Priorities',
			},
			{
				key: 'vendors',
				name: 'Vendors',
			},
			{
				key: 'locations',
				name: 'Locations',
			},
			{
				key: 'problemTypes',
				name: 'Problem Types',
			},
			{
				key: 'assetTypes',
				name: 'Asset Types',
			},
			{
				key: 'manufacturers',
				name: 'Manufacturers',
			},
		];

		return (
			<div>
				<ScrollToTopOnMount />
				<div>
					<Route
						path={`/${userType}/analytics/work/overview`}
						exact={true}
						render={(props) => (
							<Redirect
								to={{
									pathname: `/${userType}/analytics/work/overview/priorities`,
								}}
							/>
						)}
					/>
					<Content className="workAnalyticsPage" style={{ padding: '0 0.5em' }}>
						{/*<ScrollToTopOnMount/>*/}
						{/*<BackTop/>*/}

						<div
							style={{
								margin: '0 -8px',
								padding: '24px',
								paddingBottom: 0,
								background: nullSafeGet('detail.config.theme.navblock', companyConfig),
								marginBottom: 8,
							}}
						>
							<h4
								style={{
									color: 'rgba(255, 255, 255, 0.9)',
									marginRight: 8,
									marginBottom: 24,
								}}
							>
								Work Analytics
							</h4>
							<SubnavTabs
								tabs={tabs}
								onChange={this.handleTabChange}
								isActive={this.checkTabActive}
							/>
						</div>
						{this.props.childRoutes.map((r, i) => (
							<RouteWithSubRoutes key={i} {...r} />
						))}
					</Content>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	companyConfig: state.company_config,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(WorkAnalyticsOverviewPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

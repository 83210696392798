import { Button } from 'antd';
import React, { FC, useEffect, useState } from 'react';

const ButtonWithTimer: FC<any> = ({
	disableUntilSeconds,
	children,
	...props
}): React.ReactElement => {
	const [timeRemaining, setTimeRemaining] = useState(disableUntilSeconds);

	useEffect(() => {
		timeRemaining &&
			setTimeout(() => {
				setTimeRemaining((tr) => (tr > 0 ? tr - 1 : tr));
			}, 1000);
	}, [timeRemaining]);

	return (
		<Button disabled={!!timeRemaining} {...props}>
			{children} {timeRemaining ? `(${timeRemaining})` : ''}
		</Button>
	);
};

export default ButtonWithTimer;

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import WorkOrderEditForm from '../work_order_edit_form/WorkOrderEditForm';
import { workOrdersRestCrudThunksForBuyer } from '../../thunks/work_orders_thunks';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { goBackLink, recursiveRemovePreserve } from '../../utils/HistoryUtils';

const style = require('./EditWorkOrderPage.less');
const queryString = require('qs');

class EditWorkOrderPage extends React.Component<any, any> {
	componentDidMount() {
		const { getWorkOrder, match, location } = this.props;
		const workOrderId = match.params.id;
		getWorkOrder(workOrderId);
	}

	render() {
		const { history, workOrder, fetching, location } = this.props;
		const defaultUrlPath = `/buyer/workOrders/detail/${workOrder.id}/details`;

		return fetching ? (
			<PageLoadingPlaceholder />
		) : (
			[
				<LogOnMountWithStandardEventProperties eventType="visited buyer edit work order page" />,
				<EditPage
					title="Edit Work Order"
					formComponentProps={{
						formData: workOrder,
						isApprovalForm: false,
						onSuccess: (rec) => {
							let string = goBackLink(location, defaultUrlPath);
							const [path, queryParams] = string.split('?');
							let urlSearchParams = queryString.parse(queryParams);
							history.push(`${path}?${recursiveRemovePreserve(urlSearchParams)}`);
						},
					}}
					FormComponent={WorkOrderEditForm}
				/>,
			]
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		history: ownProps.history,
		workOrder: state.work_orders.detail,
		fetching: state.work_orders.fetching,
		match: ownProps.match,
	};
};

const mapDispatchToProps = (dispatch) => ({
	getWorkOrder: (id) => dispatch(workOrdersRestCrudThunksForBuyer.readOne(id)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(EditWorkOrderPage)
);

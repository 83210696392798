import { Button, Col, Popconfirm, Row } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	cancelWorkOrderForBuyer,
	workOrdersRestCrudThunksForBuyer,
} from '../../../thunks/work_orders_thunks';
import {
	checkHasApprovalHierarchy,
	isInApprovalHierarchy,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../../utils/DataAccessUtils';
import { BUYER_ONLY_NO_STORE_ASSOCIATE } from '../../roles/roles';
import { WorkOrderRowDisplay } from '../../work_order_row_display/WorkOrderRowDisplay';
import { getLinkWIthBackLinkParams } from '../../../utils/HistoryUtils';
import AccessPermissionChecker from '../../common/AccessPermissionChecker';
import { isUserAllowedToAccess, PERMISSION_NAMES } from '../../../utils/AuthUtils';
import CancelWorkOrder from '../../cancel_work_order/CancelWorkOrder';

const style = require('./WorkOrderTableRow.less');

const WorkOrderTableRow = ({
	record,
	currentUser,
	companyConfig,
	cancelWorkOrder,
	handleArchive,
	refreshTable,
	unarchiveWorkOrder,
	deleteWorkOrder,
	location,
	backText,
	targetCollectionName,
}) => {
	const BACK_TEXT = backText || 'Back to work orders';
	const isApprovalAllowed = checkHasApprovalHierarchy(record)
		? isInApprovalHierarchy(currentUser, nullSafeGet('approvalHierarchy', record))
		: isUserAllowedToAccess('buyer')(
				PERMISSION_NAMES.CAN_APPROVE_WORK_ORDERS,
				companyConfig,
				currentUser.roles
		  );

	const getWorkOrderActions = (workOrder) => {
		if (workOrder === undefined) return [];
		const unarchive =
			currentUser && currentUser.roles.some((role) => BUYER_ONLY_NO_STORE_ASSOCIATE.has(role)) ? (
				<div className={'ActionWrapper'}>
					<Button
						className={'ActionButton delete'}
						key="unarchive"
						onClick={(e) =>
							unarchiveWorkOrder(workOrder, e).then((e) => {
								refreshTable(location);
							})
						}
					>
						<i className="icons8-font icons8-trash-can"></i> Unarchive
					</Button>
				</div>
			) : null;
		const del =
			currentUser && currentUser.roles.some((role) => BUYER_ONLY_NO_STORE_ASSOCIATE.has(role)) ? (
				<div className={'ActionWrapper'}>
					<Button
						className={'ActionButton delete'}
						key="delete"
						onClick={(e) =>
							deleteWorkOrder(workOrder, e).then((e) => {
								refreshTable(location);
							})
						}
					>
						<i className="icons8-font icons8-trash-can"></i> Delete
					</Button>
				</div>
			) : null;
		if (workOrder.hasBuyerArchived) {
			return [unarchive, del];
		}
		const cancel =
			currentUser && currentUser.roles.some((role) => BUYER_ONLY_NO_STORE_ASSOCIATE.has(role)) ? (
				<div className={'ActionWrapper'} onClick={(e) => e.stopPropagation()}>
					<CancelWorkOrder
						cancelButton={
							<Button className={'ActionButton cancel'} key="cancel">
								<i className="icons8-font icons8-font-margin-right icons8-cancel"></i> Cancel
							</Button>
						}
						workOrder={workOrder}
						refresh={() => refreshTable(location)}
					/>
				</div>
			) : nullSafeGetOrElse('config.newServiceRequest.allowCancel', companyConfig, false) ? (
				<div className={'ActionWrapper'} onClick={(e) => e.stopPropagation()}>
					<CancelWorkOrder
						cancelButton={
							<Button className={'ActionButton cancel'} key="cancel">
								<i className="icons8-font icons8-font-margin-right icons8-cancel"></i> Cancel
							</Button>
						}
						workOrder={workOrder}
						refresh={() => refreshTable(location)}
					/>
				</div>
			) : null;
		const archive = (
			<div className={'ActionWrapper'}>
				<Button
					className={'ActionButton delete'}
					key="archive"
					onClick={(e) =>
						handleArchive(workOrder, e).then((e) => {
							refreshTable(location);
						})
					}
				>
					<i className="icons8-font icons8-font-margin-right icons8-trash-can"></i> Archive
				</Button>
			</div>
		);
		const edit =
			currentUser && currentUser.roles.some((role) => BUYER_ONLY_NO_STORE_ASSOCIATE.has(role)) ? (
				<AccessPermissionChecker name={PERMISSION_NAMES.EDIT_WORK_ORDERS}>
					<div className={'ActionWrapper'}>
						<Link
							className={'ActionLink'}
							to={getLinkWIthBackLinkParams(
								location,
								BACK_TEXT,
								`/buyer/workOrders/edit/${workOrder.id}`
							)}
						>
							<Button className={'ActionButton edit'} key="edit">
								<i className="icons8-font icons8-font-margin-right icons8-edit"></i> Edit
							</Button>
						</Link>
					</div>
				</AccessPermissionChecker>
			) : null;
		const approve = isApprovalAllowed ? (
			<div className={'ActionWrapper'}>
				<Link
					className={'ActionLink'}
					to={getLinkWIthBackLinkParams(
						location,
						BACK_TEXT,
						`/buyer/workOrders/approve/${workOrder.id}`
					)}
				>
					<Button className={'ActionButton approve'} key="new">
						<i className="icons8-font icons8-font-margin-right icons8-green-check-mark"></i> Approve
					</Button>
				</Link>
			</div>
		) : null;
		switch (workOrder.displayStatus) {
			case 'Pending':
				return [approve, cancel];
			case 'Open':
				return [edit, cancel];
			case 'InProgress':
				return [edit, cancel];
			case 'OnHold':
				return [cancel];
			case 'Completed':
				return [archive];
			case 'Cancelled':
				return [archive];
			default:
				return null;
		}
	};

	const getLink = useCallback(() => {
		const path = `/buyer/workOrders/detail/${nullSafeGet('id', record)}/details${
			targetCollectionName ? `?targetCollectionName=${targetCollectionName}` : ''
		}`;
		return getLinkWIthBackLinkParams(location, BACK_TEXT, path, {
			preserve: true,
		});
	}, [BACK_TEXT, location, record]);

	return (
		<Row className="index-row-wrapper" style={{ display: 'flex', flexWrap: 'nowrap' }}>
			<Col style={{ display: 'flex', flexGrow: 1 }}>
				<Link className="index-padding" style={{ width: '100%' }} to={getLink()}>
					<WorkOrderRowDisplay workOrder={record} />
				</Link>
			</Col>
			<Col
				className={'ActionRow'}
				style={{
					gridTemplateColumns: `repeat(${
						nullSafeGetOrElse('length', getWorkOrderActions(record), 2) > 1 ? 2 : 1
					}, auto[col-end])`,
				}}
			>
				{getWorkOrderActions(record)}
			</Col>
		</Row>
	);
};

const mapDispatchToProps = (dispatch) => ({
	cancelWorkOrder: (entity) => dispatch(cancelWorkOrderForBuyer(entity)),
	handleArchive: (entity) => dispatch(workOrdersRestCrudThunksForBuyer.archive(entity, 'id', true)),
	unarchiveWorkOrder: (entity) =>
		dispatch(workOrdersRestCrudThunksForBuyer.unarchive(entity, 'id', true)),
	deleteWorkOrder: (entity) =>
		dispatch(workOrdersRestCrudThunksForBuyer.delete(entity, 'id', null, true)),
});

export default connect(null, mapDispatchToProps)(WorkOrderTableRow);

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Card } from 'antd';
import SupplierFacilityForm from '../supplier_facility_form/SupplierFacilityForm';
import { supplierFacilitiesRestCrudThunksForSupplier } from '../../thunks/supplier_facilities_thunks';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;
const style = require('./SupplierFacilityEditPage.less');

class SupplierFacilityEditPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = { loading: true };
	}

	componentDidMount() {
		const { fetchSupplierFacility, match } = this.props;
		const supplierFacilityId = match.params.id;
		fetchSupplierFacility(supplierFacilityId).then(() => this.setState({ loading: false }));
	}

	render() {
		const { currentUser, match, supplierFacility } = this.props;
		const { loading } = this.state;
		const supplierCompanyId = currentUser.facility.supplierCompanyId;

		return (
			<Content className="supplierFacilityEditPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited supplier edit facility page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				{loading ? (
					<PageLoadingPlaceholder />
				) : (
					<Row style={{ margin: '0.5em -8px' }} gutter={16}>
						<Col span={24}>
							<Card>
								<div>
									<h1 style={{ fontSize: 24 }}>Edit Facility</h1>
									<SupplierFacilityForm
										formData={supplierFacility}
										redirectForwardUrl="/admin/facilities"
										supplierCompanyId={supplierCompanyId}
									/>
								</div>
							</Card>
						</Col>
					</Row>
				)}
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	match: ownProps.match,
	supplierFacilitiesFetching: state.supplier_facilities.fetching,
	supplierFacility: state.supplier_facilities.detail,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	fetchSupplierFacility: (id) => dispatch(supplierFacilitiesRestCrudThunksForSupplier.readOne(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SupplierFacilityEditPage));

import * as React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { useMemo, useState } from 'react';
import { Popover } from 'antd';
import { CardLoadingPlaceholder } from '../card_loading_placeholder/CardLoadingPlaceholder';

const OWInsightPopover: React.FC<any> = ({
	title, //Title of the popover
	filters, //Filters to be passed to the backend

	breakdown = 'status', //Name of the parameter being checked
	labels, //Labels are optional, when provided they are used
	getCount, //Call used when labels are provided (more efficient)
	getInfo, //Less efficient call used when no labels are provided

	restCrud, //restCrud for retrieving data
	defaultData, //used when data doesn't need to be called

	icon,
	children,
	...props
}) => {
	const style = require('./OWInsightPopover.less');

	const convertData = (data) => {
		let info = {};
		data.map((d) =>
			d[breakdown]
				? !Object.keys(info).includes(d[breakdown])
					? (info[d[breakdown]] = 1)
					: info[d[breakdown]]++
				: null
		);
		return info;
	};

	const [loading, setLoading] = useState(false);
	const [lockVisible, setLockVisible] = useState(false);
	const [items, setItems] = useState(defaultData ? convertData(defaultData) : {});
	const count = (
		labels
			? Object.entries(items)
					.map((item) => ({ [item[0]]: item[1] }))
					.filter((obj) => Object.keys(labels).includes(Object.keys(obj)[0]))
					.map((obj) => Object.values(obj)[0])
			: Object.values(items)
	).reduce((a: number, b: number) => a + b, 0);

	useMemo(() => {
		if (!defaultData) {
			setLoading(true);
			labels
				? Promise.all(
						Object.keys(labels).map((label) =>
							getCount(restCrud, { ...filters, [breakdown]: label }).then((data) => {
								return { [label]: data };
							})
						)
				  ).then((data) => {
						setLoading(false);
						setItems(data.reduce((o, obj) => Object.assign(o, obj), {}));
				  })
				: getInfo(restCrud, { ...filters }).then((data) => {
						let info = convertData(data);
						setLoading(false);
						setItems(info);
				  });
		}
	}, []);

	return loading || count === 0 ? null : (
		<Popover
			className={'owInfoPopover mr-1 ml-1'}
			//title={title?title:undefined}
			arrow={'center'}
			content={
				<div className={'owInfoPopoverInner'}>
					<div className={'owInfoHeader'}>
						<div className={'owInfoTitle'}>{title}</div>
					</div>
					<div className={'owInfoWrapper'}>
						<div className={'owKeyColumn'}>
							{Object.keys(items).map((key) =>
								labels ? (
									Object.keys(labels).includes(key) ? (
										<div>{labels[key]}</div>
									) : null
								) : (
									<div>{key}</div>
								)
							)}
						</div>
						<div className={'owValueColumn'}>
							{Object.keys(items).map((key) =>
								labels ? (
									Object.keys(labels).includes(key) ? (
										<div>{items[key]}</div>
									) : null
								) : (
									<div>{items[key]}</div>
								)
							)}
						</div>
					</div>
				</div>
			}
		>
			{children ? (
				children
			) : (
				<div className={'owInlineInfo'}>
					<div
						className={'owInlineNumber'}
						style={{
							minWidth: 10,
							margin: '0px',
						}}
					>
						{count}
					</div>
					<i
						style={{
							fontSize: 20,
							verticalAlign: 'middle',
							cursor: 'pointer',
							color: '#999',
							margin: '0px 2px',
							paddingLeft: '4px',
							paddingRight: '4px',
						}}
						className={`owInlineIcon icons8-font ${icon}`}
					/>
				</div>
			)}
		</Popover>
	);
};

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) => ({
	getInfo: (restCrud, params, countName) => (restCrud ? dispatch(restCrud.read(params)) : null),
	getCount: (restCrud, params, countName) =>
		restCrud ? dispatch(restCrud.countBy(params, countName)) : null,
});

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(OWInsightPopover));

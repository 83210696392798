import { DatePicker, Form } from 'antd';
import React, { FC, useMemo } from 'react';
import { BE_DATE_ONLY_FORMAT } from '../../utils/DataFormatterUtils';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { connect } from 'react-redux';

const TransferAssetFormFields: FC<any> = ({ companyConfig }): React.ReactElement => {
	const transferNoteRequired = useMemo(
		() => nullSafeGetOrElse('inventoryConfig.transferNoteMandatory', companyConfig, false),
		[companyConfig]
	);

	return (
		<>
			<Form.Item name="transferDate" label="Effective transfer date">
				<DatePicker
					style={{ width: '100%' }}
					disabledDate={(current) => current && current > moment()}
					format={BE_DATE_ONLY_FORMAT}
					placeholder={'Effective transfer date'}
				/>
			</Form.Item>
			<Form.Item
				name="transferNote"
				label="Transfer Note"
				rules={[{ required: transferNoteRequired, message: 'Please enter transfer note.' }]}
			>
				<TextArea />
			</Form.Item>
		</>
	);
};

const mapStateToProps = (state) => ({
	companyConfig: state.company_config.detail,
});

export default connect(mapStateToProps)(TransferAssetFormFields);

import { BUYER_FACILITIES_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/buyer_facilities_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = standardInitialStateGenerator([
	'buyerFacilitiesAssociationDropdown',
	'buyerFacilitiesAnalyticsDropdown',
	'buyerFacilitiesAdminPanel',
	'plannedMaintenanceAdvancedFilters',
]);
export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState);

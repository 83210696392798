import * as React from 'react';
import { Hits } from 'react-instantsearch-dom';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Table, Row, Col, Card, Collapse, Switch, Layout, Input, Rate, Button } from 'antd';
import { FlexibleImageAvatar } from '../flexible-image-avatar/FlexibleImageAvatar';
import { Link } from 'react-router-dom';
import { TruncatedText } from '../truncated_text/TruncatedText';
import { withRouter } from 'react-router';
import { AddressDisplay } from '../address_display/AddressDisplay';
import { debounce } from '../../utils/PerformanceUtils';
import { getObjectValues } from '../../utils/DataAccessUtils';
import MapboxGallery from '../mapboxgallery/MapboxGallery';
import { spendCategoriesRestCrudThunksForPublic } from '../../thunks/spend_categories_thunks';
import { SUPPLIER_FACILITIES_SEARCH_ACTION_CREATORS } from '../../actions/supplier_facilities_actions';
import { supplierFacilitiesSearchThunks } from '../../thunks/search_thunks';
import { connect } from 'react-redux';
import PaginatedReduxSearchResultsTable from '../paginated_redux_search_results_table/PaginatedReduxSearchResultsTable';
import { capitalizeEachWord } from '../../utils/DataFormatterUtils';
import SidebarMultiselectFilter from '../sidebar_multiselect_filter/SidebarMultiselectFilter';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const queryString = require('qs');
const style = require('./PublicWrenchHubPage.less');

const Content = Layout.Content;

function SupplierFacility({ hit }) {
	return (
		<Card key={hit.id}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					marginBottom: '1.5rem',
				}}
			>
				{hit.logo_url && hit.logo_url !== 'NULL' ? (
					<FlexibleImageAvatar
						displayName={hit.name}
						showPopover={false}
						popoverText={hit.name}
						width="64px"
						height="64px"
						imageUrl={hit.logo_url}
					/>
				) : (
					<FlexibleImageAvatar
						displayName={hit.name}
						showPopover={false}
						popoverText={hit.name}
						width="64px"
						height="64px"
						imageUrl="https://s3.amazonaws.com/emptor-data-assets/images/empty-state/icons8-city-filled-100.png"
					/>
				)}
			</div>
			<div>
				<Link to={`/public/suppliers/detail/${hit.id}`}>
					<div
						className="--giga-text"
						style={{
							fontWeight: 'bold',
							textAlign: 'center',
							marginBottom: '1.5rem',
							minHeight: 58,
						}}
					>
						{hit.display_name}
					</div>
				</Link>
				{/*<ReviewSummary*/}
				{/*style={{marginBottom: 4}}*/}
				{/*rating={Math.random() * 5}*/}
				{/*numReviews={Math.floor(Math.random() * 100)}*/}
				{/*/>*/}
				{/*<div style={{minHeight: 80}}>*/}

				{/*</div>*/}
				{/*<div*/}
				{/*style={{*/}
				{/*display: 'flex',*/}
				{/*justifyContent: 'space-between',*/}
				{/*alignItems: 'center',*/}
				{/*marginBottom: '1rem'*/}
				{/*}}*/}
				{/*>*/}
				{/*<div>*/}
				{/*<div style={{fontSize: 16}}>*/}
				{/*{capitalize(review.buyerReviewer.nameGiven)} {capitalize(getInitial(review.buyerReviewer.nameFamily))}.*/}
				{/*</div>*/}

				{/*{review.buyerReviewer.department ? (*/}
				{/*<div style={{color: 'rgba(0,0,0,0.45)'}}>*/}
				{/*{capitalizeEachWord(review.buyerReviewer.department)}*/}
				{/*</div>*/}
				{/*) : (*/}
				{/*null*/}
				{/*)}*/}
				{/*</div>*/}
				{/*<div>*/}
				{/*<Icon className="verifiedCheckmark"*/}
				{/*type="check"/> Verified review*/}
				{/*</div>*/}
				{/*</div>*/}
				<div
					style={{
						minHeight: 128,
						marginBottom: '1rem',
					}}
				>
					{/*<div*/}
					{/*style={{*/}
					{/*fontSize: 16,*/}
					{/*marginBottom: '0.5rem',*/}
					{/*fontWeight: 'bold'*/}
					{/*}}*/}
					{/*>*/}
					{/*{review.title}*/}
					{/*</div>*/}
					<div style={{ fontSize: 16 }}>
						{hit.about && hit.about !== 'NULL' ? (
							<TruncatedText truncatedHeight={120}>{hit.about}</TruncatedText>
						) : null}
					</div>
				</div>
				<div style={{ textAlign: 'center' }}>
					<Button>Request a quote</Button>
				</div>
			</div>
		</Card>
	);
}

function Search() {
	return (
		<div className="container">
			<Hits hitComponent={SupplierFacility} />
		</div>
	);
}

const columns = [
	{
		title: 'Name',
		dataIndex: 'display_name',
		render: (text, record) => (
			<Link to={`/public/suppliers/detail/${record.id}`}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<div style={{ marginRight: 24 }}>
						{record.logo_url ? (
							<FlexibleImageAvatar
								displayName={text}
								showPopover={false}
								popoverText={text}
								squareMode={true}
								width="120px"
								height="80px"
								imageUrl={record.logo_url}
							/>
						) : (
							<FlexibleImageAvatar
								displayName={record.name}
								showPopover={false}
								popoverText={record.name}
								squareMode={true}
								width="120px"
								height="80px"
								imageUrl="https://s3.amazonaws.com/emptor-data-assets/images/empty-state/icons8-city-filled-100.png"
							/>
						)}
					</div>
					<div>
						<div
							style={{
								fontSize: 18,
								wordBreak: 'break-word',
							}}
						>
							{text}
						</div>
						<div style={{ minWidth: 140 }}>
							<Rate disabled={true} allowHalf={true} value={record.avg_ratings} />
						</div>
						<div style={{ marginBottom: 0, fontSize: 14 }}>
							<div style={{ wordBreak: 'normal', whiteSpace: 'normal', color: 'rgba(0,0,0,0.65)' }}>
								{record._tags
									.map((categoryName) => capitalizeEachWord(categoryName))
									.slice(0, 2)
									.join(', ')}
								{record._tags.length > 3 ? ' and more' : null}
							</div>
						</div>
					</div>
				</div>
			</Link>
		),
	},
	{
		title: 'Address',
		dataIndex: 'primary_address',
		render: (text, record) => (
			<div style={{ maxWidth: 200, wordBreak: 'normal' }}>
				{record.primary_address.streetAddress1 ? (
					<AddressDisplay address={text} />
				) : (
					<div style={{ marginBottom: 8 }}>
						Serving {record.primary_address.city} and the Surrounding Region
					</div>
				)}
				<div>{record.primary_phone}</div>
			</div>
		),
	},
];

class PublicWrenchHubPage extends React.Component<any, any> {
	componentDidMount() {
		const { location, updateSupplierFacilitySearchFilters, fetchSpendCategories } = this.props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		if (queryParams.query) {
			updateSupplierFacilitySearchFilters({ query: queryParams.query });
		}
		fetchSpendCategories({});
	}

	componentWillUnmount() {
		const { clearSearchFilters } = this.props;
		clearSearchFilters();
	}

	debouncedCallback = debounce(
		(searchText) => {
			const { updateSupplierFacilitySearchFilters } = this.props;
			updateSupplierFacilitySearchFilters({ query: searchText && searchText.trim() });
		},
		400,
		false
	);

	handleSearchDebounced = (e) => {
		const searchText = e.target.value;
		this.debouncedCallback(searchText);
	};

	handleCategoryChange = (value) => {
		const { updateSupplierFacilitySearchFilters } = this.props;
		let formattedValue = value;
		if (formattedValue !== undefined) {
			formattedValue = formattedValue.map((v) => v.toLowerCase());
		}
		updateSupplierFacilitySearchFilters({ _tags: formattedValue });
	};

	handleSwitchChange = (checked, fieldName) => {
		const { updateSupplierFacilitySearchFilters } = this.props;
		if (checked) {
			updateSupplierFacilitySearchFilters({ [fieldName]: checked });
		} else {
			updateSupplierFacilitySearchFilters({ [fieldName]: undefined });
		}
	};

	handleSearchAgain = (bounds) => {
		const { updateSupplierFacilitySearchFilters } = this.props;
		updateSupplierFacilitySearchFilters({
			insideBoundingBox: [[bounds._sw.lat, bounds._sw.lng, bounds._ne.lat, bounds._ne.lng]],
		});
	};

	render() {
		const { history, location, supplierFacilities, supplierFacilitiesFetching, spendCategories } =
			this.props;
		const fetching = supplierFacilitiesFetching;
		const visibleSuppliers = supplierFacilities.searchResults;
		const supplierRecordsWithGeoLoc = visibleSuppliers
			.map((el) => ({
				...el,
				longitude: el._geoloc ? el._geoloc.lng : undefined,
				latitude: el._geoloc ? el._geoloc.lat : undefined,
			}))
			.filter((el) => el.longitude !== undefined && el.latitude !== undefined);
		const currentPagination = supplierFacilities.searchPagination;
		const nationalSuppliers =
			currentPagination.current === 1 ? supplierFacilities.nationalSearchResults : [];
		const spendCategoriesRecordsList = getObjectValues(spendCategories.records).sort((a, b) =>
			a.name < b.name ? -1 : 1
		);
		const spendCategoriesRecordsMap = spendCategories.records;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);

		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
				sm: { span: 12 },
				md: { span: 12 },
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 12 },
				md: { span: 12 },
			},
		};
		const settings = {
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: 5,
			slidesToScroll: 3,
			initialSlide: 0,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 2,
						infinite: true,
						dots: false,
					},
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		};

		return (
			<Content className="searchPage" style={{ position: 'relative', padding: '64px 0.5em 0px' }}>
				<LogOnMountWithStandardEventProperties eventType="visited public wrenchhub page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				{/*<Row>*/}
				{/*<div style={{padding: '0 48px'}}>*/}
				{/*<Slider {...settings}>*/}
				{/*{*/}
				{/*spendCategoriesRecordsList.map((category, idx2) => (*/}
				{/*<div*/}
				{/*className="sliderCard"*/}
				{/*key={idx2}*/}
				{/*>*/}
				{/*<Card*/}
				{/*hoverable={true}*/}
				{/*cover={*/}
				{/*<div*/}
				{/*style={{backgroundImage: `url(${getResizedImageUrl(category.imageUrl, 416, 320)})`}}*/}
				{/*className="categoryGridImage"*/}
				{/*/>*/}
				{/*}*/}
				{/*>*/}
				{/*<Card.Meta*/}
				{/*title={<div style={{textAlign: 'center'}}>{category.name}</div>}*/}
				{/*/>*/}
				{/*</Card>*/}
				{/*</div>*/}
				{/*))*/}
				{/*}*/}
				{/*</Slider>*/}
				{/*</div>*/}
				{/*</Row>*/}
				<div
					style={{
						height: 320,
						background: 'rgb(75, 77, 174)',
						position: 'absolute',
						top: 0,
						left: 0,
						right: 0,
					}}
				></div>
				<div
					style={{
						maxWidth: 1200,
						margin: 'auto',
					}}
				>
					<Row style={{ margin: '40px -8px 0.5em' }} justify="space-between" gutter={16}>
						<h1 style={{ position: 'relative', color: 'rgba(255, 255, 255, 0.9)' }}>WrenchHub</h1>
						<div />
					</Row>
					<Row style={{ margin: '0.5em -8px' }} gutter={16}>
						<Col sm={24} md={16}>
							<Row style={{ margin: '0 0 0.5em' }} gutter={16}>
								<Input.Search
									style={{ height: 56, fontSize: 18, marginBottom: 0, padding: 0 }}
									size="large"
									className="extraBigSearch"
									placeholder="Search for a service provider..."
									defaultValue={queryParams.query}
									onChange={this.handleSearchDebounced}
								/>
							</Row>
							<Row style={{ margin: '0.5em 0' }} gutter={16}>
								<Card style={{ padding: 0, width: '100%' }} bodyStyle={{ padding: '16px 24px' }}>
									{nationalSuppliers.length > 0 ? (
										<Table
											columns={columns}
											showHeader={false}
											pagination={false}
											rowKey={(el) => el['id']}
											dataSource={nationalSuppliers}
										/>
									) : null}
									<PaginatedReduxSearchResultsTable
										collectionName="supplier_facilities"
										columns={columns}
										showHeader={false}
										keyAccessor={(el) => el.id}
										initialPagination={{ current: 1, pageSize: 10 }}
										fetchData={supplierFacilitiesSearchThunks.search}
									/>
								</Card>
							</Row>
						</Col>
						<Col sm={24} md={8}>
							<Row style={{ margin: '0 -8px' }} gutter={16}>
								<Col span={24}>
									<Card style={{ height: 400 }}>
										<MapboxGallery
											showNavControls={true}
											popupRenderFunc={(gle) => {
												let addressDisplayParts = [];
												if (gle.primary_address.street_address_1) {
													addressDisplayParts.push(
														`<div>${gle.primary_address.street_address1}</div>`
													);
												}
												if (gle.primary_address.street_address_2) {
													addressDisplayParts.push(
														`<div>${gle.primary_address.street_address2}</div>`
													);
												}
												addressDisplayParts.push(`<div>`);
												if (gle.primary_address.city) {
													addressDisplayParts.push(`<span>${gle.primary_address.city}</span>`);
												}
												if (gle.primary_address.region) {
													if (gle.primary_address.city) {
														addressDisplayParts.push(
															`<span>, ${gle.primary_address.region}</span>`
														);
													} else {
														addressDisplayParts.push(`<span>${gle.primary_address.region}</span>`);
													}
												}
												if (gle.primary_address.postcode) {
													addressDisplayParts.push(` <span>${gle.primary_address.postcode}</span>`);
												}
												addressDisplayParts.push(`</div>`);
												if (gle.primary_address.country) {
													addressDisplayParts.push(`<div>${gle.primary_address.country}</div>`);
												}
												const addressDisplay = addressDisplayParts.join('');
												const logoImage = gle.logo_url
													? `<div style="margin-right: 16px; width: 64px; height: 64px; background: url(${gle.logo_url}) center center / contain no-repeat white;"></div>`
													: `<div style="margin-right: 16px; width: 64px; height: 64px; background: url(https://s3.amazonaws.com/emptor-data-assets/images/empty-state/icons8-city-filled-100.png) center center / contain no-repeat white;"></div>`;
												return `
                                                 <div style="padding: 16px; display: flex; align-items: center;">
                                                    ${logoImage}
                                                    <div>
                                                        <div style="font-weight: bold">
                                                            <a href="/public/suppliers/detail/${gle.id}">${gle.display_name}</a>
                                                        </div>
                                                        <div style="color: rgba(0,0,0,0.65)">
                                                            ${addressDisplay}
                                                        </div>
                                                    </div>
                                                </div>
                                                `;
											}}
											geolocatedEntities={supplierRecordsWithGeoLoc}
											baseLayerStyle="light"
											handleSearchAgain={this.handleSearchAgain}
										/>
									</Card>
								</Col>
							</Row>
							<Row style={{ margin: '0.5em -8px' }} gutter={16}>
								<Col span={24}>
									<Collapse defaultActiveKey={['1']}>
										<Collapse.Panel header="Services Provided" key="1">
											<div style={{ padding: '0 8px' }}>
												<SidebarMultiselectFilter
													handleChange={this.handleCategoryChange}
													collectionName="supplier_facilities"
													facetName="_tags"
													filterKey="value"
													valueKey="value"
													displayKey="value"
													placeholderNoun="categories"
													uniqueKey="id"
													limit={10}
												/>
											</div>
										</Collapse.Panel>
									</Collapse>
								</Col>
							</Row>
							<Row style={{ margin: '0.5em -8px' }} gutter={16}>
								<Col span={24}>
									<Collapse defaultActiveKey={['2']}>
										<Collapse.Panel header="Diversity Status" key="2">
											<div style={{ padding: '0 8px' }}>
												<Form>
													<Form.Item {...formItemLayout} label="Minority owned">
														<Switch
															onChange={(checked) =>
																this.handleSwitchChange(checked, 'is_minority_owned')
															}
														/>
													</Form.Item>
													<Form.Item {...formItemLayout} label="Women owned">
														<Switch
															onChange={(checked) =>
																this.handleSwitchChange(checked, 'is_women_owned')
															}
														/>
													</Form.Item>
													{/*<Form.Item*/}
													{/*{...formItemLayout}*/}
													{/*label="LGBT owned"*/}
													{/*>*/}
													{/*<Switch*/}
													{/*onChange={(checked) => this.handleSwitchChange(checked, 'is_lgbt_owned')}*/}
													{/*/>*/}
													{/*</Form.Item>*/}
													<Form.Item {...formItemLayout} label="Veteran owned">
														<Switch
															onChange={(checked) =>
																this.handleSwitchChange(checked, 'is_veteran_owned')
															}
														/>
													</Form.Item>
													{/*<Form.Item*/}
													{/*{...formItemLayout}*/}
													{/*label="Disabled owned"*/}
													{/*>*/}
													{/*<Switch*/}
													{/*onChange={(checked) => this.handleSwitchChange(checked, 'is_disabled_owned')}*/}
													{/*/>*/}
													{/*</Form.Item>*/}
													<Form.Item {...formItemLayout} label="Small business">
														<Switch
															onChange={(checked) =>
																this.handleSwitchChange(checked, 'is_small_business_enterprise')
															}
														/>
													</Form.Item>
												</Form>
											</div>
										</Collapse.Panel>
									</Collapse>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	supplierFacilities: state.supplier_facilities,
	supplierFacilitiesFetching: state.supplier_facilities.searching,
	spendCategories: state.spend_categories,
});

const mapDispatchToProps = (dispatch) => ({
	fetchSpendCategories: (params) => dispatch(spendCategoriesRestCrudThunksForPublic.read(params)),
	clearSearchFilters: () =>
		dispatch(SUPPLIER_FACILITIES_SEARCH_ACTION_CREATORS.clearAllSearchFilters()),
	updateSupplierFacilitySearchFilters: (searchFilters) =>
		dispatch(SUPPLIER_FACILITIES_SEARCH_ACTION_CREATORS.updateSearchFilters(searchFilters)),
	searchSupplierFacilities: (params, pagination, sorting, filters) =>
		dispatch(supplierFacilitiesSearchThunks.search(params, pagination, sorting, filters)),
	searchSupplierFacilitiesFacets: (facetName, params, pagination, sorting, filters) =>
		dispatch(
			supplierFacilitiesSearchThunks.searchFacet(facetName, params, pagination, sorting, filters)
		),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicWrenchHubPage));

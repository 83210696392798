import React, { useMemo } from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { FlexibleImageAvatar } from '../flexible-image-avatar/FlexibleImageAvatar';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { getProtectedImageUriForSupplier } from '../../utils/FileAccessUtils';
import DateTimeHover from '../date_time_component/DateTime';

require('./PartsWithQuantityRowDisplay.less');

const PartsWithQuantityRowDisplay = ({ partWithServiceCall }): React.ReactElement => {
	const partInServiceCall = useMemo(() => partWithServiceCall.part, [partWithServiceCall]);

	const part = useMemo(
		() =>
			partInServiceCall.partsPerStockLocation
				? partInServiceCall.partsPerStockLocation.part
				: partInServiceCall.part,
		[partInServiceCall]
	);

	const previewImage = useMemo(
		() =>
			nullSafeGet('images', part) && part.images.length > 0
				? part.images.map((locImg) => {
						const [imgFileId, imgFileName] = locImg.split('/');
						return getProtectedImageUriForSupplier(imgFileId, imgFileName, 1200, 800);
				  })[0]
				: null,
		[part]
	);

	return (
		<div className="partPerStockLocationRowDisplay">
			<div style={{ marginRight: 24 }}>
				{previewImage ? (
					<FlexibleImageAvatar
						displayName={nullSafeGet('name', part)}
						showPopover={false}
						popoverText={nullSafeGet('name', part)}
						squareMode={true}
						width="120px"
						height="80px"
						imageUrl={previewImage}
					/>
				) : (
					<FlexibleImageAvatar
						displayName={nullSafeGet('name', part)}
						showPopover={false}
						popoverText={nullSafeGet('name', part)}
						squareMode={true}
						width="120px"
						height="80px"
						imageUrl="https://s3.amazonaws.com/emptor-data-assets/images/empty-state/icons8-screw-filled-100.png"
					/>
				)}
			</div>
			<div>
				<div className="partPerStockLocationRowDisplay__rowTitle">
					<Ellipsis tooltip={true} length={56} className={'pb-0.5'}>
						{nullSafeGet('name', part)}
					</Ellipsis>
				</div>
				{nullSafeGet('partNumber', part) && (
					<div className="partPerStockLocationRowDisplay__rowSubtitle">
						<div className="partPerStockLocationRowDisplay__rowInlineGroup">
							Part Number : {nullSafeGet('partNumber', part)}
						</div>
					</div>
				)}
				{nullSafeGet('manufacturerName', part) && (
					<div className="partPerStockLocationRowDisplay__rowSubtitle">
						{nullSafeGet('manufacturerName', part) ? (
							<div className="partPerStockLocationRowDisplay__rowInlineGroup">
								Manufacturer : {nullSafeGet('manufacturerName', part)}
							</div>
						) : null}
					</div>
				)}
				{nullSafeGet('description', part) && (
					<div className="partPerStockLocationRowDisplay__rowSubtitle">
						<Ellipsis tooltip={true} length={80}>
							{nullSafeGet('description', part)}
						</Ellipsis>
					</div>
				)}
				{nullSafeGet('partsPerStockLocation.stockLocation', partInServiceCall) && (
					<div className="partPerStockLocationRowDisplay__rowSubtitle">
						<Ellipsis tooltip={true} length={80}>
							{`Stock Location : ${nullSafeGetOrElse(
								'partsPerStockLocation.stockLocation.name',
								partInServiceCall,
								'--'
							)}`}
						</Ellipsis>
					</div>
				)}
				{nullSafeGet('location', partInServiceCall) && (
					<div className="partPerStockLocationRowDisplay__rowSubtitle">
						<Ellipsis tooltip={true} length={80}>
							{`Location : ${nullSafeGetOrElse('location.name', partInServiceCall, '--')}`}
						</Ellipsis>
					</div>
				)}
				{/* <div className="partPerStockLocationRowDisplay__rowSubtitle">
					{`Service Call ${partWithServiceCall.serviceCallNumber}
					(${dateFormatter(serviceCall.createdAt, DATE_FORMAT)})`}
				</div> */}
				{nullSafeGet('trackingNumber', partInServiceCall) && (
					<div className="partPerStockLocationRowDisplay__rowSubtitle">
						<div className="flex flex-row items-center">
							{`Tracking No. - ${nullSafeGetOrElse('trackingNumber', partInServiceCall, '--')}`}
							{nullSafeGet('markShippedAt', partInServiceCall) && (
								<div className="ml-2">
									{nullSafeGet('markShippedAt', partInServiceCall) ? (
										<>
											Shipped At :{' '}
											<DateTimeHover timestamp={nullSafeGet('markShippedAt', partInServiceCall)} />
										</>
									) : (
										'--'
									)}
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
		/* <Row align="middle" gutter={16}>
				<Col className="partQuantity">
					{nullSafeGet('quantity', partInServiceCall) ? (
						partInServiceCall.quantity
					) : (
						<div className="flex flex-row items-center">
							<div>0</div>
							<div className="ml-1 text-gray-400">{`(Returned)`}</div>
						</div>
					)}
				</Col>
				<Col>
					
				</Col>
			</Row> */
	);
};

export default PartsWithQuantityRowDisplay;

import React, { FC, useCallback, useMemo, useState } from 'react';
import HyperLink from '../common/HyperLink';
import { nullSafeGetOrElse, stopDefaultEvents } from '../../utils/DataAccessUtils';
import RequestedQuantityWorkOrdersModal from './RequestedQuantityWorkOrdersModal';

const RequestedQuantityDisplay: FC<any> = ({ ppslId, record, backText }): React.ReactElement => {
	const [wosModalVisible, setWosModalVisible] = useState(false);

	const onQtyClick = useCallback((e) => {
		stopDefaultEvents(e);
		setWosModalVisible(true);
	}, []);

	const hideRequestedQtyModal = useCallback((e) => {
		stopDefaultEvents(e);
		setWosModalVisible(false);
	}, []);

	const quantity = useMemo(() => nullSafeGetOrElse('requestedQuantity', record, 0), [record]);

	return (
		<>
			{quantity ? <HyperLink text={quantity} onClick={onQtyClick} /> : quantity}
			{wosModalVisible && (
				<RequestedQuantityWorkOrdersModal
					ppslId={ppslId}
					partsPerStockLocation={record}
					backText={backText}
					onCancel={hideRequestedQtyModal}
				/>
			)}
		</>
	);
};

export default RequestedQuantityDisplay;

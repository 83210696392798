import { Form, Input, Select } from 'antd';
import React from 'react';

const DEFAULT_FIELD_PROPS = {
	preserve: false,
	label: 'Email',
	name: 'email',
	rules: [
		{
			required: true,
			message: 'Please input email.',
		},
	],
};

export default function EmailComponentAntdV4({ fieldProps = {}, ...props }) {
	const emailValidator = (rule, value, callback) => {
		if (!value) {
			callback();
		}
		const emailRegex = /^([a-zA-Z0-9+_\.-]+)@([\da-zA-Z\.-]+)\.([a-zA-Z\.]{2,6})$/;
		if (value.match(emailRegex)) {
			callback();
		} else {
			callback(value + ' is not a valid email');
		}
	};
	const formProps = { ...DEFAULT_FIELD_PROPS, ...fieldProps };
	return (
		<Form.Item
			{...formProps}
			rules={[...(formProps.rules || []), { validator: emailValidator }]}
			validateTrigger="onBlur"
		>
			<Input placeholder="Enter email" {...props} />
		</Form.Item>
	);
}

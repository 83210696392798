import * as React from 'react';
import { Avatar, Popover, Row } from 'antd';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { FC, useMemo, useState } from 'react';
import { SupplierFacilityCard } from './SupplierFacilityCard';
import { getProtectedImageUriForBuyer } from '../../../utils/FileAccessUtils';
import { nullSafeGet } from '../../../utils/DataAccessUtils';
import { AddressDisplay } from '../../address_display/AddressDisplay';

interface SupplierFacilityNameComponentProps {
	facility: any;
	mode?: 'card' | 'inline' | 'container' | 'avatar';
	secondaryField?: string;
	size?: 'default' | 'large';
	allowHover?: boolean;
	popoverMode?: 'simple' | 'full';
	placement?: string;
}

export const SupplierFacilityName: FC<SupplierFacilityNameComponentProps> = ({
	facility,
	mode = 'card',
	secondaryField = 'email',
	size = 'default',
	allowHover = true,
	popoverMode = 'full',
	placement = 'leftTop',
}) => {
	const onClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};
	const displayName = nullSafeGet('displayName', facility);

	const popoverContent = (
		<SupplierFacilityCard
			facility={facility}
			name={displayName}
			subTitle={
				<AddressDisplay
					hidePostcode={true}
					hideCountry={true}
					hideStreetAddress={true}
					address={nullSafeGet('primaryAddress', facility)}
					style={{ color: 'rgba(0,0,0,0.45)' }}
				/>
			}
			size={size}
			mode={popoverMode}
		/>
	);
	const element =
		mode === 'card' ? (
			<span>
				<SupplierFacilityCard
					facility={facility}
					name={displayName}
					subTitle={''}
					size={size}
					mode={popoverMode}
				/>
			</span>
		) : mode === 'inline' ? (
			<span>{nullSafeGet('name', facility)}</span>
		) : mode === 'container' ? (
			<Ellipsis tooltip={false} fullWidthRecognition={true}>
				{nullSafeGet('name', facility)}
			</Ellipsis>
		) : mode === 'avatar' ? (
			<span>
				<SupplierFacilityCard
					facility={facility}
					name={displayName}
					subTitle={''}
					size={size}
					mode={popoverMode}
				/>
			</span>
		) : (
			<span></span>
		);

	return allowHover ? (
		<Popover placement={placement} content={popoverContent}>
			{element}
		</Popover>
	) : (
		element
	);
};

import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Drawer, Row } from 'antd';
import { connect } from 'react-redux';
import './index.less';
import {
	getSupplierPrivateNetworksForLocationForBuyer,
	getSupplierPrivateNetworksForLocationForSupplier,
	supplierPrivateNetworksV2ForBuyer,
	supplierPrivateNetworksV2ForSupplier,
	updateRankForSuppliersForBuyer,
	updateRankForSuppliersForSupplier,
} from '../../thunks/supplier_private_networks_thunks';

import {
	problemTypesHeirarchicalForBuyer,
	problemTypesHeirarchicalForSupplier,
} from '../../thunks/problem_types_thunks';

import {
	locationsRestCrudThunksForBuyer,
	locationsRestCrudThunksForSupplier,
} from '../../thunks/locations_thunks';

import LocationList from './LocationList';
import ProblemTypesList from './ProblemTypesList';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { withRouter } from 'react-router';

export const PrivateNetworkLocationsPage = ({
	problemTypes,
	fetchSupplierPrivateNetworksForLocation,
	fetchProblemTypes,
	fetchSupplierPrivateNetworkV2,
	fetchLocations,
	locations,
	updateRank,
}) => {
	const [selected, setSelected] = useState({ id: null, name: null });
	return (
		<Row>
			<Col span={8}>
				<div
					style={{ borderRight: 'solid 1px rgb(229 231 235)' }}
					className="h-full  border-r border-owgray bg-white"
				>
					<div className="h-full p-6">
						<LocationList
							fetchLocations={fetchLocations}
							setSelected={setSelected}
							selected={selected}
							locations={getRecordsForTargetCollection(locations, 'rankingPageLocations')}
							total={locations['rankingPageLocations'].pagination.total}
						/>
					</div>
				</div>
			</Col>
			<Col span={16}>
				<div className="block h-full w-full rounded border-2 ">
					<ProblemTypesList
						selectedLocation={selected.id}
						updateRank={updateRank}
						fetchProblemTypes={fetchProblemTypes}
						selected={selected}
						problemTypes={problemTypes}
						fetchSupplierPrivateNetworksForLocation={fetchSupplierPrivateNetworksForLocation}
						fetchSupplierPrivateNetworkV2={fetchSupplierPrivateNetworkV2}
					/>
				</div>
			</Col>
		</Row>
	);
};

const mapStateToProps = (state) => ({
	problemTypes: state.problem_types,
	locations: state.locations,
	userType: (state as any).session.userType,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchLocations: (params) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.read(params, 'rankingPageLocations')
				: locationsRestCrudThunksForBuyer.read(params, 'rankingPageLocations')
		),
	fetchSupplierPrivateNetworksForLocation: (locationId, params) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? getSupplierPrivateNetworksForLocationForSupplier(locationId, params)
				: getSupplierPrivateNetworksForLocationForBuyer(locationId, params)
		),
	fetchProblemTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? problemTypesHeirarchicalForSupplier(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: problemTypesHeirarchicalForBuyer(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchSupplierPrivateNetworkV2: (
		additionalParams,
		targetCollectionName,
		pagination,
		sorting,
		filters
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? supplierPrivateNetworksV2ForSupplier(
						additionalParams,
						targetCollectionName,
						pagination,
						sorting,
						filters
				  )
				: supplierPrivateNetworksV2ForBuyer(
						additionalParams,
						targetCollectionName,
						pagination,
						sorting,
						filters
				  )
		),
	updateRank: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? updateRankForSuppliersForSupplier(entity)
				: updateRankForSuppliersForBuyer(entity)
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(PrivateNetworkLocationsPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

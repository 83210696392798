import * as React from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { EmptorTag } from '../emptor_tag/EmptorTag';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { currencyFormatter } from '../../utils/DataFormatterUtils';

const style = require('./InvoiceRowDisplay.less');

export const PendingInvoiceRowDisplay: React.FC<any> = ({ invoice, ...props }) => (
	<div className="invoiceRowDisplay" {...props} style={{ height: '55px', margin: '-8px -2px' }}>
		<div>
			<div className="invoiceRowDisplay__rowTitle">
				<Ellipsis tooltip={true} length={64} style={{ color: 'rgba(0,0,0,0.65)' }}>
					{nullSafeGet('workOrder.title', invoice)}
				</Ellipsis>
			</div>
			<div className="invoiceRowDisplay__rowSubtitle">
				<div className="invoiceRowDisplay__rowInlineGroup" style={{ display: 'inline-flex' }}>
					<span className="invoiceRowDisplay__grayChip">
						<i className="icons8-font icons8-location-marker" />
						<span>{nullSafeGet('workOrder.location.name', invoice)}</span>
					</span>
				</div>
				<div className="invoiceRowDisplay__rowInlineGroup">
					{currencyFormatter(2)(invoice.invoiceTotalAfterTax)}
				</div>
			</div>
		</div>
	</div>
);

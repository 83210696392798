import { Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSearchStringForBackNavigationWithBackText } from '../../../utils/DataAccessUtils';
import { ROLE_TYPES } from '../../../utils/DataConstants';
import { InvoiceRowDisplay } from '../../invoice_row_display/InvoiceRowDisplay';

function InvoicesTableRow({ location, record, userType, backText, selections }) {
	const routePrefix = userType === ROLE_TYPES.SUPPLIER ? 'supplier' : 'buyer';

	const getLink = () => {
		const searchString = getSearchStringForBackNavigationWithBackText(
			backText || 'Back to invoices',
			location
		);
		const backUrl = `/${routePrefix}/invoices/detail/${record.id}/details${
			!!searchString ? `?backlinkParams=${encodeURIComponent(searchString)}` : ''
		}`;
		return backUrl;
	};
	return (
		<Row>
			<Link to={getLink()} style={{ width: '100%' }}>
				<InvoiceRowDisplay invoice={record} />
			</Link>
		</Row>
	);
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesTableRow);

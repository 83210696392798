import * as React from 'react';
import { getObjectValues, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import DateTimeHover from '../date_time_component/DateTime';
const moment = require('moment');
const style = require('../WorkOrderTable/rowDisplay/RowDisplay.less');

export const ProjectsRowDisplay: React.FC<any> = ({ project }) => {
	let incompleteNumber =
		getObjectValues(nullSafeGet('workOrderProjectMetrics', project)).reduce((n1, n2) => n1 + n2) -
		nullSafeGetOrElse('workOrderProjectMetrics.workOrdersCompleted', project, 0);
	let budgetApproved = nullSafeGetOrElse(
		'invoiceProjectMetrics.totalInvoicesAmountApproved',
		project,
		0
	);
	let closedNumber = nullSafeGetOrElse('workOrderProjectMetrics.workOrdersClosed', project, 0);
	let budgetSpent = (budgetApproved * 100) / nullSafeGet('budget', project);
	let budgetText = '';
	if (isNaN(budgetSpent)) {
		budgetText = undefined;
	} else {
		if (budgetSpent > 100) {
			budgetText = (budgetSpent - 100).toFixed(2).toString().concat('% Over Budget');
		} else {
			budgetText = (100 - budgetSpent).toFixed(2).toString().concat('% Budget Remaining');
		}
	}
	if (!project) {
		return null;
	}
	return (
		<div className="index-padding" style={{ display: 'flex', width: '100%' }}>
			<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', width: '100%' }}>
				<div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap' }}>
					<div className="workOrderRowDisplay__rowTitle">
						<div
							style={{
								maxWidth: '100%',
								overflowX: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								color: 'rgba(0,0,0,.65)',
							}}
						>
							{project.title}
						</div>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'nowrap',
							gap: '24px',
							alignItems: 'baseline',
						}}
						className="workOrderRowDisplay__rowInlineGroup"
					>
						{getObjectValues(nullSafeGet('workOrderProjectMetrics', project)).reduce(
							(n1, n2) => n1 + n2
						) > 0 ? (
							[
								<span style={{ whiteSpace: 'nowrap', color: 'rgba(0,0,0,0.45)', fontSize: 14 }}>
									{closedNumber.toString().concat(
										' of ',
										getObjectValues(nullSafeGet('workOrderProjectMetrics', project))
											.reduce((n1, n2) => n1 + n2)
											.toString()
											.concat(' Work Orders Closed')
									)}
								</span>,
								<span
									style={{
										display: 'block',
										whiteSpace: 'nowrap',
										color: 'rgba(0,0,0,0.45)',
										fontSize: 14,
									}}
								>
									{budgetText}
								</span>,
							]
						) : (
							<span style={{ whiteSpace: 'nowrap', color: 'rgba(0,0,0,0.45)', fontSize: 14 }}>
								No work orders added
							</span>
						)}
						<span
							style={{
								display: 'block',
								whiteSpace: 'nowrap',
								color: 'rgba(0,0,0,0.45)',
								fontSize: 14,
							}}
						>
							{`Created `}{' '}
							<DateTimeHover timestamp={nullSafeGet('createdAt', project)} showTime={false} />
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import AssetModelForm from '../asset_template_form/AssetTemplateForm';
import { EditPage } from '../edit_page/EditPage';
import { assetModelsRestCrudThunksForBuyer } from '../../thunks/asset_models_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const style = require('./EditAssetModelPage.less');

class EditAssetModelPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const { getAssetModel, match } = this.props;
		const assetModelId = match.params.id;
		getAssetModel(assetModelId);
	}

	render() {
		const { history, assetModel, fetching } = this.props;

		//noinspection TypeScriptUnresolvedVariable
		return [
			<LogOnMountWithStandardEventProperties eventType="visited edit asset model page" />,
			<EditPage
				title="Edit Asset Model"
				formComponentProps={{
					formData: assetModel,
					onSuccess: (rec) => history.push(`/buyer/assets/models/detail/${rec.id}`),
				}}
				fetching={fetching}
				FormComponent={AssetModelForm}
			/>,
		];
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		history: ownProps.history,
		assetModel: state.asset_models.detail,
		fetching: state.assets.fetching,
		match: ownProps.match,
	};
};

const mapDispatchToProps = (dispatch) => ({
	getAssetModel: (id) => dispatch(assetModelsRestCrudThunksForBuyer.readOne(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditAssetModelPage));

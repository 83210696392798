import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Alert } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { connect } from 'react-redux';

const FormItem = Form.Item;

interface DeclineWorkorderFormProps extends FormComponentProps {
	onSubmit: any;
	onCancel: any;
	visible: boolean;
	createErrors: any;
	updateErrors: any;
	creating: boolean;
	updating: boolean;
	workOrder: any;
	currentUser: any;
}

class DeclineWorkorderForm extends React.Component<DeclineWorkorderFormProps, any> {
	render() {
		const { visible, onCancel, onSubmit, form, workOrder, createErrors, updateErrors } = this.props;
		const { getFieldDecorator } = form;
		getFieldDecorator('workOrder', { initialValue: workOrder });
		return (
			<Modal
				visible={visible}
				width={600}
				title="Decline Work Order"
				okText="Decline"
				onCancel={onCancel}
				onOk={onSubmit}
				closable={false}
			>
				<Form layout="vertical" className="supplierForm">
					{createErrors.length > 0 ? (
						<FormItem>
							<Alert message={createErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					{updateErrors.length > 0 ? (
						<FormItem>
							<Alert message={updateErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					<FormItem label="Notes">{getFieldDecorator('note', {})(<Input.TextArea />)}</FormItem>
				</Form>
			</Modal>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	createErrors: state.work_orders.createErrors,
	updateErrors: state.work_orders.updateErrors,
	creating: state.work_orders.creating,
	updating: state.work_orders.updating,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create<DeclineWorkorderFormProps>()(DeclineWorkorderForm));

import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import {
	fetchBuyerCompanyPrivateNetworkWithLocationsSuccess,
	fetchDistinctSupplierPrivateNetworksSuccess,
	fetchHydratedDistinctSupplierPrivateNetworksSuccess,
	fetchSupplierPrivateNetworksForLocationSuccess,
	fetchSuppliersForBuyersSuccess,
	SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS,
	addSuppliersForBuyersSuccess,
} from '../actions/supplier_private_networks_actions';
import FetchUtils, { parseJSON } from './fetch_utils';
import { logoutSuccess } from '../actions/session_actions';
import { getBackendUri } from '../utils/EnvConfigUtils';
import * as React from 'react';
import { message } from 'antd';
import { TextButton } from '../components/text_button/TextButton';
export const supplierPrivateNetworksRestCrudThunksForBuyer = new RestCrudThunks(
	'supplier_private_networks',
	'supplier_private_network',
	'supplierPrivateNetwork',
	'supplierPrivateNetworks',
	SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'spend_category/'
);
export const supplierPrivateNetworksRestCrudThunksForSupplier = new RestCrudThunks(
	'supplier_private_networks',
	'supplier_private_network',
	'supplierPrivateNetwork',
	'supplierPrivateNetworks',
	SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'spend_category/'
);

const fetchUtils = new FetchUtils();
const baseUrl = getBackendUri();

const supplierPrivateNetworkGroupedUpdate = (supplierFacilityId, roleType) => (entity) => {
	const apiUrl = `/api/v1/${roleType}/spend_category/supplier_private_networks/update_by_supplier/${supplierFacilityId}`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.put(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: entity,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

export const supplierPrivateNetworkGroupedUpdateForBuyer = (supplierFacilityId) =>
	supplierPrivateNetworkGroupedUpdate(supplierFacilityId, ROLE_TYPES.BUYER);
export const supplierPrivateNetworkGroupedUpdateForSupplier = (supplierFacilityId) =>
	supplierPrivateNetworkGroupedUpdate(supplierFacilityId, ROLE_TYPES.SUPPLIER);

export const fetchSuppliersForBuyers =
	(userType = 'buyer') =>
	(name, email) =>
	(targetCollectionName) => {
		const apiUrl = `/api/v1/${userType}/supplier_facilities/name_and_email/${name}/${email}`;

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.fetchStart(targetCollectionName));

				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							{}
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(
								SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.fetchError(
									d.message,
									targetCollectionName
								)
							);
							reject(d.message);
						} else {
							dispatch(fetchSuppliersForBuyersSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(
							SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.fetchError(
								d.message,
								targetCollectionName
							)
						);
						reject(d.message);
					});
			});
		};
	};
export const addNewSupplierForBuyers =
	(userType = 'buyer') =>
	(entity) => {
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.updateStart(entity));

				fetchUtils
					.post(baseUrl + `/api/v1/${userType}/supplier_facilities/buyer_added_supplier`, {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entity,
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(
								SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.updateError(d.message, entity)
							);
							reject(d.message);
						} else {
							dispatch(addSuppliersForBuyersSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};

export const inviteSupplierUserFromBuyer =
	(userType) => (supplierFacilityId, fromEmail, toEmail, firstName, lastName) => {
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.post(
						baseUrl +
							`/api/v1/${userType}/spend_category/supplier_private_networks/${supplierFacilityId}/invite/${fromEmail}/${toEmail}/name/${firstName}/${lastName}`,
						{
							headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
							data: {},
						}
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.message);
						} else {
							resolve(d.data);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};

const supplierPrivateNetworkGroupedDelete =
	(supplierFacilityId, roleType, showUndo = false) =>
	(entity) => {
		const apiUrl = `/api/v1/${roleType}/spend_category/supplier_private_networks/delete_by_supplier/${supplierFacilityId}`;

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.deleteStart(entity));

				const deleteRecord = () =>
					fetchUtils
						.delete(baseUrl + apiUrl, {
							headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						})
						.then(parseJSON)
						.then(({ json, status, ok }) => {
							const d = json;
							if (status === 401) {
								dispatch(logoutSuccess());
							}
							if (d.status === 'error') {
								dispatch(
									SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.deleteError(d.message, entity)
								);
								reject(d.message);
							} else {
								dispatch(SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.deleteSuccess(d.data));
								resolve(d.data);
							}
						})
						.catch((d) => {
							dispatch(
								SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.deleteError(d.message, entity)
							);
							reject(d.message);
						});

				const deleteTimout = setTimeout(deleteRecord, 4000);
				const cancelDelete = () => clearTimeout(deleteTimout);
				if (showUndo) {
					const hideUndo = message.success(
						<span>
							Supplier removed from private network.{' '}
							<TextButton
								onClick={() => {
									cancelDelete();
									hideUndo();
								}}
							>
								Undo
							</TextButton>
						</span>
					);
				}
			});
		};
	};

export const supplierPrivateNetworkGroupedDeleteForBuyer = (supplierFacilityId, showUndo) =>
	supplierPrivateNetworkGroupedDelete(supplierFacilityId, ROLE_TYPES.BUYER, showUndo);
export const supplierPrivateNetworkGroupedDeleteForSupplier = (supplierFacilityId, showUndo) =>
	supplierPrivateNetworkGroupedDelete(supplierFacilityId, ROLE_TYPES.SUPPLIER, showUndo);

const supplierPrivateNetworksGetDistinct =
	(roleType) =>
	(params, targetCollectionName = null, pagination = null, sorting = null, filters = null) => {
		const apiUrl = `/api/v1/${roleType}/spend_category/supplier_private_networks/distinct_with_locations`;
		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.fetchStart(targetCollectionName));

				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(
								SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.fetchError(
									d.message,
									targetCollectionName
								)
							);
							reject(d.message);
						} else {
							dispatch(
								fetchDistinctSupplierPrivateNetworksSuccess(
									d.data,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(
							SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.fetchError(
								d.message,
								targetCollectionName
							)
						);
						reject(d.message);
					});
			});
		};
	};

export const supplierPrivateNetworksGetDistinctForBuyer = supplierPrivateNetworksGetDistinct(
	ROLE_TYPES.BUYER
);
export const supplierPrivateNetworksGetDistinctForSupplier = supplierPrivateNetworksGetDistinct(
	ROLE_TYPES.SUPPLIER
);

const hydratedSupplierPrivateNetworksGetDistinct =
	(roleType) =>
	(params, targetCollectionName = null, pagination = null, sorting = null, filters = null) => {
		const apiUrl = `/api/v1/${roleType}/spend_category/supplier_private_networks/distinct_hydrated_with_locations`;
		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.fetchStart(targetCollectionName));

				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(
								SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.fetchError(
									d.message,
									targetCollectionName
								)
							);
							reject(d.message);
						} else {
							dispatch(
								fetchHydratedDistinctSupplierPrivateNetworksSuccess(
									d.data,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(
							SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.fetchError(
								d.message,
								targetCollectionName
							)
						);
						reject(d.message);
					});
			});
		};
	};

export const hydratedSupplierPrivateNetworksGetDistinctForBuyer =
	hydratedSupplierPrivateNetworksGetDistinct(ROLE_TYPES.BUYER);

const getSupplierPrivateNetworksForLocation =
	(roleType) =>
	(
		locationId,
		params,
		targetCollectionName = null,
		pagination = null,
		sorting = null,
		filters = null
	) => {
		const apiUrl = `/api/v1/${roleType}/spend_category/supplier_private_networks/${locationId}/grouped_problem_type`;
		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.fetchStart(targetCollectionName));

				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(
								SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.fetchError(
									d.message,
									targetCollectionName
								)
							);
							reject(d.message);
						} else {
							dispatch(
								fetchSupplierPrivateNetworksForLocationSuccess(
									d.data,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters
								)
							);
							resolve(d);
						}
					})
					.catch((d) => {
						dispatch(
							SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.fetchError(
								d.message,
								targetCollectionName
							)
						);
						reject(d.message);
					});
			});
		};
	};
export const getSupplierPrivateNetworksForLocationForBuyer = getSupplierPrivateNetworksForLocation(
	ROLE_TYPES.BUYER
);
export const getSupplierPrivateNetworksForLocationForSupplier =
	getSupplierPrivateNetworksForLocation(ROLE_TYPES.SUPPLIER);

const getBuyerCompanyPrivateNetworksWithLocations =
	(roleType) =>
	(params, targetCollectionName = null, pagination = null, sorting = null, filters = null) => {
		const apiUrl = `/api/v1/${roleType}/spend_category/supplier_private_networks/company_with_locations`;
		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.fetchStart(targetCollectionName));

				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(
								SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.fetchError(
									d.message,
									targetCollectionName
								)
							);
							reject(d.message);
						} else {
							dispatch(
								fetchBuyerCompanyPrivateNetworkWithLocationsSuccess(
									d.data,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(
							SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.fetchError(
								d.message,
								targetCollectionName
							)
						);
						reject(d.message);
					});
			});
		};
	};

export const getBuyerCompanyPrivateNetworksWithLocationsForSupplier =
	getBuyerCompanyPrivateNetworksWithLocations(ROLE_TYPES.SUPPLIER);

// V2 APIS //
const supplierPrivateNetworksV2 =
	(roleType) =>
	(params, targetCollectionName = null, pagination = null, sorting = null, filters = null) => {
		const apiUrl = `/api/v1/${roleType}/private_network/buyer_supplier_relationships`;
		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.fetchStart(targetCollectionName));
				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(
								SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.fetchError(
									d.message,
									targetCollectionName
								)
							);
							reject(d.message);
						} else {
							dispatch(
								fetchDistinctSupplierPrivateNetworksSuccess(
									d.data,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(
							SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.fetchError(
								d.message,
								targetCollectionName
							)
						);
						reject(d.message);
					});
			});
		};
	};

export const supplierPrivateNetworksV2ForBuyer = supplierPrivateNetworksV2(ROLE_TYPES.BUYER);
export const supplierPrivateNetworksV2ForSupplier = supplierPrivateNetworksV2(ROLE_TYPES.SUPPLIER);

const supplierPrivateNetworksV2WithSupplierFacilityContacts =
	(roleType) =>
	(params, targetCollectionName = null, pagination = null, sorting = null, filters = null) => {
		const apiUrl = `/api/v1/${roleType}/private_network/buyer_supplier_relationships_with_supplier_facility_contacts`;
		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.fetchStart(targetCollectionName));
				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(
								SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.fetchError(
									d.message,
									targetCollectionName
								)
							);
							reject(d.message);
						} else {
							dispatch(
								fetchDistinctSupplierPrivateNetworksSuccess(
									d.data,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(
							SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.fetchError(
								d.message,
								targetCollectionName
							)
						);
						reject(d.message);
					});
			});
		};
	};

export const supplierPrivateNetworksV2WithSupplierFacilityContactsForBuyer =
	supplierPrivateNetworksV2WithSupplierFacilityContacts(ROLE_TYPES.BUYER);
export const supplierPrivateNetworksV2WithSupplierFacilityContactsForSupplier =
	supplierPrivateNetworksV2WithSupplierFacilityContacts(ROLE_TYPES.SUPPLIER);

const supplierPrivateNetworkV2Update = (roleType) => (entity) => {
	const apiUrl = `/api/v1/${roleType}/private_network/buyer_supplier_relationships`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.put(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: entity,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

export const supplierPrivateNetworkV2UpdateForBuyer = supplierPrivateNetworkV2Update(
	ROLE_TYPES.BUYER
);
export const supplierPrivateNetworkV2UpdateForSupplier = supplierPrivateNetworkV2Update(
	ROLE_TYPES.SUPPLIER
);
const requestFilesV2 = (roleType) => (data) => {
	const apiUrl = `/api/v1/${roleType}/private_network/buyer_supplier_relationships/${data.supplierFacilityId}/request/${data.type}/${data.fromEmail}`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.post(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						dispatch(SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const requestFilesV2ForBuyer = requestFilesV2(ROLE_TYPES.BUYER);
export const requestFilesV2ForSupplier = requestFilesV2(ROLE_TYPES.SUPPLIER);

const updateRankForSuppliers = (roleType) => (entity) => {
	console.log('1', roleType);
	const apiUrl = `/api/v1/${roleType}/spend_category/supplier_private_networks`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.post(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: entity,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const updateRankForSuppliersForBuyer = updateRankForSuppliers(ROLE_TYPES.BUYER);
export const updateRankForSuppliersForSupplier = updateRankForSuppliers(ROLE_TYPES.SUPPLIER);

const copyRankForSuppliers =
	(roleType) =>
	({ fromLocation, toLocation, problemType }) => {
		const apiUrl = `/api/v1/${roleType}/spend_category/supplier_private_networks/copy/${fromLocation}/${toLocation}${
			problemType ? `?problemType=${problemType}` : ''
		}`;
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.post(baseUrl + apiUrl, {
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					})
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.message);
						} else {
							resolve(d.data);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};

export const copyRankForSuppliersForBuyer = copyRankForSuppliers(ROLE_TYPES.BUYER);
export const copyRankForSuppliersForSupplier = copyRankForSuppliers(ROLE_TYPES.SUPPLIER);

const deleteBuyerSupplierRelationshipBySupplierFacilityId = (roleType) => (supplierFacilityId) => {
	const apiUrl = `/api/v1/${roleType}/private_network/buyer_supplier_relationships/delete_by_supplier/${supplierFacilityId}`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.delete(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const deleteBuyerSupplierRelationshipBySupplierFacilityIdForBuyer =
	deleteBuyerSupplierRelationshipBySupplierFacilityId(ROLE_TYPES.BUYER);
export const deleteBuyerSupplierRelationshipBySupplierFacilityIdForSupplier =
	deleteBuyerSupplierRelationshipBySupplierFacilityId(ROLE_TYPES.SUPPLIER);

const createSupplierPrivateNetworkBulk = (roleType) => (data) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/spend_category/supplier_private_networks/bulk`;

	// if sorting present, fix order naming and add to query params

	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.updateStart({}));

			fetchUtils
				.put(
					baseUrl + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data,
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.updateError(d.message, {}));
						reject(d.message);
					} else {
						dispatch(SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.updateError(d.message, {}));
					reject(d.message);
				});
		});
	};
};

export const createSupplierPrivateNetworkBulkForBuyer = createSupplierPrivateNetworkBulk(
	ROLE_TYPES.BUYER
);
export const createSupplierPrivateNetworkBulkForSupplier = createSupplierPrivateNetworkBulk(
	ROLE_TYPES.SUPPLIER
);

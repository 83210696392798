import * as React from 'react';

import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Input, Alert, Button, Divider } from 'antd';
import { connect } from 'react-redux';
import { logIn } from '../../thunks/session_thunks';

import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter, Redirect } from 'react-router';
import { demoLoginCredentials } from '../../mock_data/mocks';
import { Link } from 'react-router-dom';
import { getHomePageByRole } from '../../utils/EnvConfigUtils';
import { isHomePageUrl } from '../../utils/HistoryUtils';
import LoginButton from '../LoginButton';
import { SSODomainForBuyer } from '../../thunks/domain_thunks';
import { validateDomain } from '../../utils/AuthUtils';

const style = require('./BuyerLoginForm.less');
const FormItem = Form.Item;

interface LoginProps {
	isAuthenticated: boolean;
	userType: string;
	logIn: any;
	loading: boolean;
	currentUser: any;
	reviewModalMode?: boolean;
	successCallback?: any;

	redirectToUrl?: string;
	username: string;
	password: string;
	errors: string[];
}

interface LoginFormProps extends FormComponentProps {
	isAuthenticated: boolean;
	userType: string;
	logIn: any;
	loading: boolean;
	currentUser: any;
	reviewModalMode?: boolean;
	successCallback?: any;
	getAndValidateDomain?: any;
	redirectToUrl?: string;
	returnTo?: any;
	username: string;
	password: string;
	errors: string[];
	featureFlags: {};
}

class BuyerLoginForm extends React.Component<LoginFormProps, any> {
	constructor(props) {
		super(props);
		this.state = {
			shouldLoginViaSSO: false,
		};
	}
	componentDidMount() {}

	handleSubmit = (e) => {
		const { getAndValidateDomain } = this.props;
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			getAndValidateDomain(values.username, true)
				.then((domains = []) => {
					if (!err) {
						this.props
							.logIn(values.username.trim(), values.password.trim())

							.then((user) => {
								if (this.props.successCallback) {
									this.props.successCallback(user);
								}
							});
					}
				})
				.catch((err) => {
					this.setState({ shouldLoginViaSSO: err });
				});
		});
	};

	handleDemoLogin = (e) => {
		this.props.logIn(demoLoginCredentials.username.trim(), demoLoginCredentials.password.trim());
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { isAuthenticated, loading, reviewModalMode, errors, returnTo, featureFlags } =
			this.props;
		const homePage = getHomePageByRole('buyer', {
			...featureFlags,
			page: 'buyerlOginform',
		});
		const { shouldLoginViaSSO } = this.state;
		return isAuthenticated && !reviewModalMode ? (
			<Redirect
				to={
					returnTo && !isHomePageUrl(returnTo.pathname)
						? returnTo
						: {
								pathname: homePage,
								state: { from: '/login/buyer' },
						  }
				}
			/>
		) : (
			<Form onSubmit={this.handleSubmit} className="loginForm">
				{errors.length > 0
					? errors.map((error, idx) => (
							<FormItem key={idx}>
								<Alert message={error} type="error" />
							</FormItem>
					  ))
					: null}
				{shouldLoginViaSSO ? (
					<FormItem key={'sso'}>
						<Alert message="Please login with SSO" type="warning" />
					</FormItem>
				) : null}

				<FormItem>
					{getFieldDecorator('username', {
						rules: [{ required: true, message: 'Please input your email!' }],
					})(
						<Input
							prefix={<UserOutlined style={{ fontSize: 14 }} translate="" />}
							size="large"
							placeholder="Email"
						/>
					)}
				</FormItem>
				<FormItem>
					{getFieldDecorator('password', {
						rules: [{ required: true, message: 'Please input your password!' }],
					})(
						<Input.Password
							prefix={<LockOutlined style={{ fontSize: 14 }} translate="" />}
							size="large"
							visibilityToggle={true}
							type="password"
							placeholder="Password"
						/>
					)}
				</FormItem>
				<FormItem>
					<Button
						type="primary"
						htmlType="submit"
						loading={loading}
						size="large"
						className="loginForm__button"
					>
						Log in {reviewModalMode ? 'and post review' : null}
					</Button>
					{reviewModalMode ? null : (
						<span style={{ fontSize: 16 }}>
							<Link className="loginForm__forgot" to="/buyer/forgotPassword">
								Forgot password
							</Link>{' '}
							Or <Link to="/buyer/signup">register now!</Link>
						</span>
					)}
				</FormItem>
				<Divider>
					<span className=" text-sm font-light text-slate-600">or continue with </span>
				</Divider>
				<LoginButton />
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	loading: state.session.loading,
	errors: state.session.errors,
	isAuthenticated: state.session.isAuthenticated,
	currentUser: state.session.currentUser,
	userType: state.session.currentUser,
	reviewModalMode: ownProps.reviewModalMode,
	successCallback: ownProps.successCallback,
	featureFlags: state.session.featureFlags,
});

const mapDispatchToProps = (dispatch) => ({
	logIn: (username, password) => dispatch(logIn('buyer')(username, password)),
	getAndValidateDomain: (username, isLogin) => dispatch(SSODomainForBuyer(username, isLogin)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(Form.create<LoginFormProps>()(BuyerLoginForm))
);

import { STOPLIGHT_COLORS } from '../../utils/DataFormatterUtils';

const WalkthroughPipeLineFilters = [
	{
		displayName: 'Not Started',
		name: 'notStarted',
		filter: { status: 'notStarted' },
		icon: 'icons8-wrench',
		iconStyle: { color: STOPLIGHT_COLORS.pureYellow },
	},
	{
		displayName: 'Incomplete',
		name: 'incomplete',
		filter: { status: 'incomplete' },
		icon: 'icons8-pause-squared',
		iconStyle: { color: STOPLIGHT_COLORS.pureYellow },
	},
	{
		displayName: 'Completed',
		name: 'complete',
		filter: { status: 'complete' },
		icon: 'icons8-green-check-mark',
		iconStyle: { color: STOPLIGHT_COLORS.pureGreen },
	},
];

export default WalkthroughPipeLineFilters;

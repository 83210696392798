import React, { FC, useCallback, useMemo, useState } from 'react';
import { removePartFromServiceCallForSupplier } from '../../thunks/service_calls_thunks';
import { Button, Form, InputNumber, message, Modal } from 'antd';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { useForm } from 'antd/lib/form/Form';

const FORM_NAME = 'edit-wo-part-quantity-modal';

const EditWOPartQuantityModal: FC<any> = ({
	partWithServiceCall,
	removePartFromServiceCall,
	onSuccess,
	onCancel,
}): React.ReactElement => {
	const [form] = useForm();

	const [removing, setRemoving] = useState(false);

	const existingQuantity = useMemo(
		() => nullSafeGetOrElse('part.quantity', partWithServiceCall, 0),
		[partWithServiceCall]
	);

	const onSubmit = useCallback(
		(values) => {
			setRemoving(true);

			const entity = {
				serviceCallId: partWithServiceCall.serviceCall.id,
				partId: partWithServiceCall.part.partId,
				...(nullSafeGet('part.locationId', partWithServiceCall) && {
					locationId: partWithServiceCall.part.locationId,
				}),
				...(nullSafeGet('part.partsPerStockLocationId', partWithServiceCall) && {
					partsPerStockLocationId: partWithServiceCall.part.partsPerStockLocationId,
				}),
				quantity: existingQuantity - values.quantity,
			};
			removePartFromServiceCall(entity)
				.then(() => onSuccess())
				.catch((err) => message.error(err))
				.finally(() => setRemoving(false));
		},
		[existingQuantity, onSuccess, partWithServiceCall, removePartFromServiceCall]
	);

	return (
		<Modal
			visible={true}
			width={600}
			title={'Update Part Quantity'}
			closable
			onCancel={onCancel}
			forceRender
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button
					type="primary"
					size="large"
					style={{ marginLeft: '16px' }}
					key="submit"
					htmlType="submit"
					form={FORM_NAME}
					loading={removing}
				>
					Update Quantity
				</Button>,
			]}
		>
			<Form form={form} id={FORM_NAME} layout="vertical" requiredMark={false} onFinish={onSubmit}>
				<Form.Item
					name="quantity"
					label="Quantity"
					initialValue={existingQuantity}
					rules={[
						{
							type: 'number',
							min: 1,
							message: `You must enter at least one`,
						},
						{
							type: 'number',
							max: existingQuantity,
							message: `You can't enter more than ${existingQuantity}`,
						},
						{
							required: true,
						},
					]}
				>
					<InputNumber style={{ width: '100%' }} min="1" />
				</Form.Item>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	removePartFromServiceCall: (partData) => dispatch(removePartFromServiceCallForSupplier(partData)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(EditWOPartQuantityModal)
);

import * as React from 'react';
import { MAPBOX_API_KEY } from '../../utils/ClientsideApiKeys';
import { Button, Result } from 'antd';
import { ErrorBoundary } from '@sentry/react';

let mapboxgl = require('mapbox-gl');

const CENTER_OF_UNITED_STATES = [-98.5795, 39.8283];

class MapboxComponent extends React.Component<any, any> {
	map: any;
	mapContainer: any;
	marker: any;

	componentWillReceiveProps(nextProps) {
		const {
			longitude: newLongitude,
			latitude: newLatitude,
			circleRadiusInMiles: newCircleRadiusInMiles,
			zoomLevel,
		} = nextProps;
		const {
			longitude: oldLongitude,
			latitude: oldLatitude,
			circleRadiusInMiles: oldCircleRadiusInMiles,
		} = this.props;
		if (
			newCircleRadiusInMiles !== oldCircleRadiusInMiles ||
			(newLongitude &&
				newLatitude &&
				(newLongitude !== oldLongitude || newLatitude !== oldLatitude))
		) {
			this.marker.remove();
			this.marker = new mapboxgl.Marker().setLngLat([newLongitude, newLatitude]).addTo(this.map);

			this.map.easeTo({
				center: [newLongitude, newLatitude],
				zoom: zoomLevel,
			});

			if (newCircleRadiusInMiles) {
				this.map.getSource('source_circle_500').setData({
					type: 'FeatureCollection',
					features: [
						{
							type: 'Feature',
							geometry: {
								type: 'Point',
								coordinates: [newLongitude, newLatitude],
							},
						},
					],
				});

				const metersToPixelsAtMaxZoom = (meters, latitude) =>
					meters / 0.075 / Math.cos((latitude * Math.PI) / 180);

				this.map.removeLayer('circle500');
				this.map.addLayer({
					id: 'circle500',
					type: 'circle',
					source: 'source_circle_500',
					paint: {
						'circle-radius': {
							stops: [
								[0, 0],
								[20, metersToPixelsAtMaxZoom(newCircleRadiusInMiles * 1609.34, newLatitude)],
							],
							base: 2,
						},
						'circle-color': '#6027b0',
						'circle-opacity': 0.6,
					},
				});
			}
		}
	}

	componentDidMount() {
		const {
			startingLongitude = CENTER_OF_UNITED_STATES[0],
			startingLatitude = CENTER_OF_UNITED_STATES[1],
			startingZoomLevel = 2,
			longitude,
			latitude,
			circleRadiusInMiles,
			baseLayerStyle,
			zoomLevel,
		} = this.props;
		// tslint-disable-next-line
		mapboxgl.accessToken = MAPBOX_API_KEY;
		this.map = new mapboxgl.Map({
			container: this.mapContainer,
			style: `mapbox://styles/mapbox/${baseLayerStyle}-v9`,
			center: [startingLongitude, startingLatitude],
			zoom: startingZoomLevel,
			attributionControl: false,
		});

		this.map.on('load', () => {
			if (longitude && latitude) {
				this.marker = new mapboxgl.Marker().setLngLat([longitude, latitude]).addTo(this.map);

				this.map.easeTo({
					center: [longitude, latitude],
					zoom: zoomLevel,
				});

				if (circleRadiusInMiles) {
					this.map.addSource('source_circle_500', {
						type: 'geojson',
						data: {
							type: 'FeatureCollection',
							features: [
								{
									type: 'Feature',
									geometry: {
										type: 'Point',
										coordinates: [longitude, latitude],
									},
								},
							],
						},
					});

					const metersToPixelsAtMaxZoom = (meters, latitude) =>
						meters / 0.075 / Math.cos((latitude * Math.PI) / 180);

					this.map.addLayer({
						id: 'circle500',
						type: 'circle',
						source: 'source_circle_500',
						paint: {
							'circle-radius': {
								stops: [
									[0, 0],
									[20, metersToPixelsAtMaxZoom(circleRadiusInMiles * 1609.34, latitude)],
								],
								base: 2,
							},
							'circle-color': '#6027b0',
							'circle-opacity': 0.6,
						},
					});
				}
			}
		});
	}

	componentWillUnmount() {
		this.map && this.map.remove && this.map.remove();
	}

	render() {
		const style = {
			position: 'absolute' as 'absolute',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			width: '100%',
		};

		return <div style={style} ref={(el) => (this.mapContainer = el)} />;
	}
}

export default class Mapbox extends React.Component<any, any> {
	render(): React.ReactNode {
		return (
			<ErrorBoundary fallback={null}>
				<MapboxComponent {...this.props} />
			</ErrorBoundary>
		);
	}
}

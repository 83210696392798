import * as React from 'react';
import { Layout, Row, Col, Card, Button, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { KeyValueDisplay } from '../key_value_display/KeyValueDisplay';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import {
	acceptWorkOrderForSupplier,
	appendWorkOrderNotesForSupplier,
	backDataWorkOrderForSupplier,
	cancelWorkOrderForSupplier,
	declineWorkOrderForSupplier,
	manualCheckInServiceCallForSupplier,
	manualCheckOutServiceCallForSupplier,
	markCompletedByThirdPartyForSupplier,
	partsOrderedWorkOrderForSupplier,
	partsReceivedWorkOrderForSupplier,
	partsRequestedWorkOrderForSupplier,
	reviewInternalTechWorkOrderAndMarkCompleteForSupplier,
	reviewWorkOrderAndMarkCompleteForSupplier,
	reviewWorkOrderAndMarkUnsatisfactoryForSupplier,
	transferWorkOrderForSupplier,
	workOrdersRestCrudThunksForSupplier,
} from '../../thunks/work_orders_thunks';
import {
	getCurrency,
	getEntityById,
	getPONumbers,
	isAdminUser,
	isCheckInModalMandatory,
	nullSafeGet,
	nullSafeGetOrElse,
	stopDefaultEvents,
} from '../../utils/DataAccessUtils';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { dateFormatter } from '../../utils/DataFormatterUtils';
import {
	NOT_SCHEDULABLE_STATUSES,
	SCHEDULE_TECH_STATUSES,
	WORK_ORDER_STATUSES,
} from '../../constants/WorkOrderStatuses';
import { ProtectedImageThumbnails } from '../protected_image_thumbnails/ProtectedImageThumbnails';
import moment from 'moment';
import FileUploader from '../file_uploader/FileUploader';
import ScheduleServiceCallModalForm from '../schedule_service_call_modal_form/ScheduleServiceCallModalForm';
import {
	rescheduleServiceCallForSupplier,
	scheduleServiceCallForSupplier,
} from '../../thunks/work_orders_thunks';
import ManualCheckInModalForm from '../manual_checkin_modal_form/ManualCheckInModalForm';
import ManualCheckOutModalForm from '../manual_checkout_modal_form/ManualCheckOutModalForm';
import SupplierLocationCardDisplay from '../supplier_location_card_display/SupplierLocationCardDisplay';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import PartsOrderedOrReceivedForm from '../parts_ordered_form/PartsOrderedOrReceivedForm';
import PartsRequestedForm from '../parts_requested_form/PartsRequestedForm';
import TransferWorkOrderModalForm from '../transfer_work_order_modal_form/TransferWorkOrderModalForm';
import SupplierApproveReviewWorkForm from '../supplier_approve_review_work_form/SupplierApproveReviewWorkForm';
import SupplierRejectReviewWorkForm from '../supplier_reject_review_work_form/SupplierRejectReviewWorkForm';
import OWResult from '../OWResult';
import { markUserNotificationsReadForSupplier } from '../../thunks/user_notifications_thunks';
import { UserName } from '../name_component/user/UserNameComponent';
import { PERMISSION_NAMES, isInternalTech } from '../../utils/AuthUtils';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import WorkOrderPrintable from '../WorkOrderPrintable';
import QuestionnaireSurvey from '../PostCompletionQuestionnaire/QuestionnaireSurvey';
import { updateWorkOrderResolutionTypeForSupplier } from '../../thunks/resolution_types_thunks';
import { EditOutlined } from '@ant-design/icons';
import BackDateWorkOrderModalForm from '../backdate_work_order_modal_form copy/BackDateWorkOrderModalForm';
import EstimatedDateWorkOrderModalForm from '../estimated_work_order_modal_form';
import RichTextEditorModal from '../common/RichTextEditorModal';
import ReminderForm from '../reminder_form/ReminderForm';
import WorkOrderActions from '../work_order_actions/WorkOrderActions';
import WorkOrderInternalContact from '../work_orders_detail_details_page/WorkOrderInternalContact';
import AssetStatusForm from '../asset_status_form/AssetStatusForm';
import { assetDowntimeGet } from '../../thunks/assets_thunks';
import TroubleshootingDetailDisplay from '../work_orders_detail_details_page/TroubleshootingDetailDisplay';
import AssetCardDisplay from '../asset_card_display/AssetCardDisplay';
import ReviewAndMarkCompleteComponent from './ReviewAndMarkCompleteComponent';
import ServiceProviderCardDisplay from '../service_provider_card_display/ServiceProviderCardDisplay';
import DateTimeHover from '../date_time_component/DateTime';
import SupplierServiceCallDetails from '../work_orders_detail_details_page/SupplierServiceCallDetails';
import HyperLink from '../common/HyperLink';

const { Content } = Layout;

const queryString = require('qs');
require('./SupplierWorkOrdersDetailDetailsPage.less');

class SupplierWorkOrdersDetailPage extends React.Component<any, any> {
	formRefs: any = {
		scheduleServiceCallForm: null,
		rescheduleServiceCallForm: null,
		scheduleServiceCallForTechAssignedForm: null,
	};

	state = {
		loading: false,
		scheduleServiceCallFormVisible: false,
		rescheduleServiceCallFormVisible: false,
		manualCheckInFormVisible: false,
		manualCheckOutFormVisible: false,
		partsOrderedModalVisible: false,
		partsReceivedModalVisible: false,
		partsRequestedModalVisible: false,
		transferWorkOrderFormVisible: false,
		backDateWorkOrderFormVisible: false,
		estimatedDateWorkOrderFormVisible: false,
		scheduleServiceCallForTechAssignedFormVisible: false,
		approveWorkModalVisible: false,
		rejectWorkModalVisible: false,
		startWorkModalVisible: false,
		finishWorkModalVisible: false,
		locationDrawerVisible: true,
		reminderModalVisible: false,
		onlyReview: false,
		tab: 'active',
		error: null,
		addDetailModalVisible: false,
		addingDetails: false,
		startingWork: false,
		downTimeHistory: [],
		assetStatusChangeModalVisible: false,
	};

	constructor(props) {
		super(props);

		const { location } = props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);

		if (queryParams.backlinkParams) {
			const { tab } = queryString.parse(queryParams.backlinkParams);
			this.setState({
				tab: tab ? tab : 'active',
			});
		}

		this.showApproveModal = this.showApproveModal.bind(this);
		this.showReviewModal = this.showReviewModal.bind(this);
		this.showRejectModal = this.showRejectModal.bind(this);
		this.showReminderModal = this.showReminderModal.bind(this);
		this.handleApproveWorkSubmit = this.handleApproveWorkSubmit.bind(this);
		this.handleApproveWorkCancel = this.handleApproveWorkCancel.bind(this);
		this.handleRejectWorkSubmit = this.handleRejectWorkSubmit.bind(this);
		this.handleRejectWorkCancel = this.handleRejectWorkCancel.bind(this);
		this.handlePartsOrdered = this.handlePartsOrdered.bind(this);
		this.handlePartsOrderedSubmit = this.handlePartsOrderedSubmit.bind(this);
		this.handlePartsOrderedCancel = this.handlePartsOrderedCancel.bind(this);
		this.handlePartsReceived = this.handlePartsReceived.bind(this);
		this.handlePartsReceivedCancel = this.handlePartsReceivedCancel.bind(this);
		this.handlePartsReceivedSubmit = this.handlePartsReceivedSubmit.bind(this);
		this.handleStartWork = this.handleStartWork.bind(this);
	}

	refreshWorkOrder = () => {
		const {
			getWorkOrder,

			match,
		} = this.props;
		const workOrderId = match.params.id;
		const workOrder = getWorkOrder(workOrderId)
			.then((wo) => {
				this.setState({ loading: false });
				return wo;
			})
			.catch((e) => {
				this.setState({ loading: false, error: e });
			});
		return workOrder;
	};

	componentDidMount() {
		const { markUserNotificationsRead, workOrder } = this.props;

		const handleMountActions = (workOrder) => {
			const { match } = this.props;
			const action = match.params.action;
			if (action === 'schedule') {
				this.setState({ scheduleServiceCallFormVisible: true });
			} else if (action === 'reschedule') {
				this.setState({ rescheduleServiceCallFormVisible: true });
			} else if (action === 'partsOrdered') {
				this.setState({ partsOrderedModalVisible: true });
			} else if (action === 'partsRequested') {
				this.setState({ partsRequestedModalVisible: true });
			} else if (action === 'accept') {
				this.handleAccept(workOrder);
			} else if (action === 'decline') {
				this.handleDecline(workOrder);
			} else if (action === 'edit') {
				this.handleEdit(workOrder);
			} else if (action === 'archive') {
				this.handleArchive(workOrder);
			} else if (action === 'unarchive') {
				this.handleUnarchive(workOrder);
			} else if (action === 'transfer') {
				this.setState({ transferWorkOrderFormVisible: true });
			} else if (action === 'approve') {
				this.handleApprove(workOrder);
			} else if (action === 'cancel') {
				this.handleCancel(workOrder);
			} else if (action === 'approveWork') {
				this.setState({ approveWorkModalVisible: true });
			} else if (action === 'rejectWork') {
				this.setState({ rejectWorkModalVisible: true });
			} else if (action === 'reviewWork') {
				this.setState({ onlyReview: false, approveWorkModalVisible: true });
			} else if (action === 'reminder') {
				this.setState({ reminderModalVisible: true });
			}

			nullSafeGet('id', workOrder) && markUserNotificationsRead(workOrder.id);
		};
		if (workOrder && workOrder.id) {
			handleMountActions(workOrder);
		} else {
			this.refreshWorkOrder().then((workOrder) => {
				handleMountActions(workOrder);
			});
		}
	}
	componentDidUpdate(prevProps) {
		const { workOrder, markUserNotificationsRead } = this.props;
		const { workOrder: prevWorkOrder } = prevProps;
		if (workOrder && prevWorkOrder && workOrder.id !== prevWorkOrder.id) {
			markUserNotificationsRead(workOrder.id);
		}
	}

	handleAssetStatusChange = () => {
		const { workOrder, getAssetDowntime } = this.props;
		const asset = nullSafeGet('asset', workOrder);
		getAssetDowntime({ assetId: asset.id }, ROLE_TYPES.SUPPLIER).then((data = []) => {
			this.setState({ downTimeHistory: data, assetStatusChangeModalVisible: true });
		});
	};
	handleAssetStatusCancel = () => {
		this.setState({ assetStatusChangeModalVisible: false });
	};
	handleAssetStatusSuccess = () => {
		this.setState({ assetStatusChangeModalVisible: false });
		this.refreshWorkOrder();
	};

	handleEdit = (workOrder) => {
		const { history } = this.props;
		history.push(`/supplier/workOrders/edit/${workOrder.id}`);
	};

	handleArchive = (workOrder) => {
		const { history, archiveWorkOrder } = this.props;
		const { tab } = this.state;
		archiveWorkOrder(workOrder).then(() => {
			history.push(`/supplier/workOrders/overview/${tab}`);
		});
	};

	handleUnarchive = (workOrder) => {
		const { history, unarchiveWorkOrder } = this.props;
		const { tab } = this.state;
		unarchiveWorkOrder(workOrder).then(() => {
			history.push(`/supplier/workOrders/overview/${tab}`);
		});
	};

	handleAccept = (workOrder) => {
		const { acceptWorkOrder } = this.props;
		acceptWorkOrder(workOrder);
	};

	handleDecline = (workOrder) => {
		const { history, declineWorkOrder } = this.props;
		const { tab } = this.state;
		declineWorkOrder(workOrder).then(() => {
			history.push(`/supplier/workOrders/overview/${tab}`);
		});
	};

	handleCancel = (workOrder) => {
		const { cancelWorkOrder } = this.props;
		cancelWorkOrder(workOrder);
	};

	handleTransfer = () => {
		this.setState({
			transferWorkOrderFormVisible: true,
		});
	};
	handlebackDate = () => {
		this.setState({
			backDateWorkOrderFormVisible: true,
		});
	};
	handleEstimatedDate = () => {
		this.setState({
			estimatedDateWorkOrderFormVisible: true,
		});
	};

	handleTransferWorkOrderCancel = (e) => {
		this.setState({
			transferWorkOrderFormVisible: false,
		});
	};
	handlebackDateWorkOrderCancel = (e) => {
		this.setState({
			backDateWorkOrderFormVisible: false,
		});
	};
	handleEstimatedDateWorkOrderCancel = (e) => {
		this.setState({
			estimatedDateWorkOrderFormVisible: false,
		});
	};

	handleTransferWorkOrderSubmit = () => {
		const { transferWorkOrder, history } = this.props;
		const { tab } = this.state;
		const form = this.formRefs['transferWorkOrderForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}
			transferWorkOrder(values)
				.then(() => {
					this.setState({ transferWorkOrderFormVisible: false });
					message.success(`Work order transferred`);
				})
				.then(() => {
					history.push(`/supplier/workOrders/overview/${tab}`);
				});
		});
	};

	handlebackDaterWorkOrderSubmit = () => {
		const { backDataWorkOrder, workOrder } = this.props;

		const form = this.formRefs['backDaterWorkOrderForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			backDataWorkOrder(workOrder, values.reportedAt.utc().format()).then(() => {
				this.setState({ backDateWorkOrderFormVisible: false });
				message.success(`Work order Updated`);
				this.refreshWorkOrder();
			});
		});
	};
	handleEstimatedDateWorkOrderSubmit = () => {
		const { updateWorkOrder, workOrder } = this.props;

		const form = this.formRefs['estimatedDaterWorkOrderForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			const newWorkOrder = {
				...workOrder,
				estimatedCompletionDate: values.estimatedCompletionDate.utc().format(),
			};
			updateWorkOrder(newWorkOrder).then(() => {
				this.setState({ estimatedDateWorkOrderFormVisible: false });
				message.success(`Work order Updated`);
				this.refreshWorkOrder();
			});
		});
	};

	handleFileAttachmentUploadChange = (newAttachments) => {
		const { workOrder, updateWorkOrder, currentUser } = this.props;
		const isPrivateSupplier = nullSafeGet('facility.status', currentUser) === 'private';
		const newWorkOrder = {
			...workOrder,
			...(isPrivateSupplier
				? { buyerAttachments: newAttachments }
				: { supplierAttachments: newAttachments }),
		};
		updateWorkOrder(newWorkOrder);
	};

	handlePartsOrdered = (e) => {
		this.setState({
			partsOrderedModalVisible: true,
		});
		e.stopPropagation();
		e.preventDefault();
	};

	handlePartsReceived = (e) => {
		this.setState({
			partsReceivedModalVisible: true,
		});
		e.stopPropagation();
		e.preventDefault();
	};

	handlePartsOrderedSubmit = () => {
		const { currentUser, partsOrderedWorkOrder, workOrder } = this.props;
		const form = this.formRefs['partsOrderedForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			if (values.note) {
				const note = {
					text: values.note,
					noteAddedBy: currentUser.email,
					noteAddedAt: moment(),
				};
				partsOrderedWorkOrder({ ...workOrder, note }).then(() => {
					this.setState({ partsOrderedModalVisible: false });
					this.refreshWorkOrder();
					message.success(`Parts ordered.`);
				});
			} else {
				partsOrderedWorkOrder(workOrder).then(() => {
					this.setState({ partsOrderedModalVisible: false });
					this.refreshWorkOrder();
					message.success(`Parts ordered.`);
				});
			}
		});
	};

	handlePartsReceivedSubmit = () => {
		const { currentUser, partsReceivedWorkOrder, workOrder } = this.props;
		const form = this.formRefs['partsReceivedForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			if (values.note) {
				const note = {
					text: values.note,
					noteAddedBy: currentUser.email,
					noteAddedAt: moment(),
				};
				partsReceivedWorkOrder({ ...workOrder, note }).then(() => {
					this.setState({ partsReceivedModalVisible: false });
					this.refreshWorkOrder();
					message.success(`Parts Received.`);
				});
			} else {
				partsReceivedWorkOrder(workOrder).then(() => {
					this.setState({ partsReceivedModalVisible: false });
					this.refreshWorkOrder();
					message.success(`Parts ordered.`);
				});
			}
		});
	};

	handlePartsOrderedCancel = (e) => {
		this.setState({
			partsOrderedModalVisible: false,
		});
	};

	handlePartsReceivedCancel = (e) => {
		this.setState({
			partsReceivedModalVisible: false,
		});
	};

	handlePartsRequested = (e) => {
		this.setState({
			partsRequestedModalVisible: true,
		});
		e.stopPropagation();
		e.preventDefault();
	};

	handlePartsRequestedSubmit = () => {
		const { partsRequestedWorkOrder, currentUser, workOrder } = this.props;
		const form = this.formRefs['partsRequestedForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			if (values.note) {
				const note = {
					text: values.note,
					noteAddedBy: currentUser.email,
					noteAddedAt: moment(),
				};

				partsRequestedWorkOrder({ ...workOrder, note: note }).then(() => {
					this.setState({ partsRequestedModalVisible: false });
					this.refreshWorkOrder();
					message.success(`Parts requested.`);
				});
			} else {
				partsRequestedWorkOrder(workOrder).then(() => {
					this.setState({ partsRequestedModalVisible: false });
					this.refreshWorkOrder();
					message.success(`Parts requested.`);
				});
			}
		});
	};

	handlePartsRequestedCancel = (e) => {
		this.setState({
			partsRequestedModalVisible: false,
		});
	};

	handleSchedule = (e) => {
		this.setState({
			scheduleServiceCallFormVisible: true,
		});
		e.stopPropagation();
		e.preventDefault();
	};

	handleScheduleForTechAssigned = (e) => {
		stopDefaultEvents(e);
		this.setState({
			scheduleServiceCallForTechAssignedFormVisible: true,
		});
	};

	handleReschedule = (e) => {
		this.setState({
			rescheduleServiceCallFormVisible: true,
		});
		e.stopPropagation();
		e.preventDefault();
	};

	handleManualCheckIn = (e?) => {
		this.setState({
			manualCheckInFormVisible: true,
		});
		e && stopDefaultEvents(e);
	};

	handleManualCheckOut = (e) => {
		this.setState({
			manualCheckOutFormVisible: true,
		});
		e.stopPropagation();
		e.preventDefault();
	};

	handleScheduleServiceCallSubmit = () => {
		const { scheduleServiceCall } = this.props;
		const form = this.formRefs['scheduleServiceCallForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			scheduleServiceCall(values).then(() => {
				this.setState({ scheduleServiceCallFormVisible: false });
				this.refreshWorkOrder();
				message.success(`Service call scheduled.`);
			});
		});
	};

	handleScheduleServiceCallCancel = (e) => {
		this.setState({
			scheduleServiceCallFormVisible: false,
		});
	};

	handleRescheduleServiceCallSubmit = () => {
		const { rescheduleServiceCall } = this.props;
		const form = this.formRefs['rescheduleServiceCallForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			rescheduleServiceCall(values).then(() => {
				this.setState({ rescheduleServiceCallFormVisible: false });
				this.refreshWorkOrder();
				message.success(`Service call rescheduled.`);
			});
		});
	};

	handleScheduleServiceCallForTechAssignedSubmit = () => {
		const { scheduleServiceCall } = this.props;
		const form = this.formRefs['scheduleServiceCallForTechAssignedForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			scheduleServiceCall(values).then(() => {
				this.setState({ scheduleServiceCallForTechAssignedFormVisible: false });
				this.refreshWorkOrder();
				message.success(`Service call scheduled.`);
			});
		});
	};

	handleRescheduleServiceCallCancel = (e) => {
		this.setState({
			rescheduleServiceCallFormVisible: false,
		});
	};

	handleScheduleForTechAssignedCancel = () =>
		this.setState({
			scheduleServiceCallForTechAssignedFormVisible: false,
		});

	scheduleServiceCallWithManualCheckin = (serviceCall) => {
		const { manualCheckIn, scheduleServiceCall, currentUser } = this.props;

		this.setState({ startingWork: true });
		scheduleServiceCall(serviceCall)
			.then((updatedWorkOrder) => {
				if (isCheckInModalMandatory(currentUser)) {
					this.handleManualCheckIn();
				} else {
					const lastServiceCall = nullSafeGetOrElse('lastServiceCall', updatedWorkOrder, {});
					const updatedServiceCall = {
						...lastServiceCall,
						checkInByEmail: currentUser.email,
						checkInTime: moment(),
						checkInNotes: undefined,
						checkInPhotos: [],
					};
					manualCheckIn(updatedServiceCall).then(() => {
						this.refreshWorkOrder();
						message.success(`Work started.`);
					});
				}
			})
			.finally(() => this.setState({ startingWork: false }));
	};

	handleStartWork = () => {
		const { workOrder, currentUser } = this.props;

		const serviceCall = {
			workOrderId: workOrder.id,
			supplierFacilityId: workOrder.supplierFacilityId,
			serviceScheduledAt: moment(),
			leadTechnicianEmail: currentUser.email,
			additionalTechnicianEmails: [],
			stockLocationIds: [],
		};

		this.scheduleServiceCallWithManualCheckin(serviceCall);
	};

	handleStartWorkForTechAssigned = () => {
		const { workOrder } = this.props;

		if (!workOrder.lastServiceCall) {
			return;
		}

		const serviceCall = {
			...workOrder.lastServiceCall,
			serviceScheduledAt: moment(),
		};

		this.scheduleServiceCallWithManualCheckin(serviceCall);
	};

	handleManualCheckInSubmit = () => {
		const { manualCheckIn } = this.props;
		const form = this.formRefs['manualCheckInForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			manualCheckIn(values).then(() => {
				this.setState({ manualCheckInFormVisible: false });
				this.refreshWorkOrder();
				message.success(`Manually checked in.`);
			});
		});
	};

	handleManualCheckInCancel = (e) => {
		this.setState({
			manualCheckInFormVisible: false,
		});
	};

	handleManualCheckOutSubmit = () => {
		const { manualCheckOut, updateWorkOrderResolutionType, workOrder, updateWorkOrder } =
			this.props;
		const form = this.formRefs['manualCheckOutForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}
			if (values.estimatedCompletionDate) {
				const newWorkOrder = {
					...workOrder,
					estimatedCompletionDate: values.estimatedCompletionDate.utc().format(),
				};
				updateWorkOrder(newWorkOrder)
					.then(() => {
						this.setState({ estimatedDateWorkOrderFormVisible: false });
						message.success(`Work order Updated`);
						this.refreshWorkOrder();
					})
					.then(() => {
						if (values.resolutionTypeId) {
							updateWorkOrderResolutionType(workOrder.id, values.resolutionTypeId).then(() => {
								manualCheckOut(values).then(() => {
									this.setState({ manualCheckOutFormVisible: false });
									this.refreshWorkOrder();
									message.success(`Manually checked out.`);
								});
							});
						} else {
							manualCheckOut(values).then(() => {
								this.setState({ manualCheckOutFormVisible: false });
								this.refreshWorkOrder();
								message.success(`Manually checked out.`);
							});
						}
					});
			} else {
				if (values.resolutionTypeId) {
					updateWorkOrderResolutionType(workOrder.id, values.resolutionTypeId).then(() => {
						manualCheckOut(values).then(() => {
							this.setState({ manualCheckOutFormVisible: false });
							this.refreshWorkOrder();
							message.success(`Manually checked out.`);
						});
					});
				} else {
					manualCheckOut(values).then(() => {
						this.setState({ manualCheckOutFormVisible: false });
						this.refreshWorkOrder();
						message.success(`Manually checked out.`);
					});
				}
			}
		});
	};

	handleManualCheckOutCancel = (e) => {
		this.setState({
			manualCheckOutFormVisible: false,
		});
	};

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	//Approve/Reject Work
	handleApprove = (workOrder) => {
		const { history } = this.props;
		history.push(`/supplier/workOrders/approve/${workOrder.id}`);
	};

	handleApproveWorkCancel(e) {
		this.setState({
			approveWorkModalVisible: false,
		});
	}

	showApproveModal(e) {
		this.setState({
			onlyReview: false,
			approveWorkModalVisible: true,
		});
	}

	showReviewModal(e) {
		this.setState({
			onlyReview: true,
			approveWorkModalVisible: true,
		});
	}

	handleRejectWorkSubmit() {
		const { reviewWorkOrderAndMarkUnsatisfactory, currentUser } = this.props;
		const form = this.formRefs['supplierRejectReviewWorkForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			const note = {
				text: values.note,
				noteAddedBy: currentUser.email,
				noteAddedAt: moment(),
			};
			const workOrderStatusChangeDetails = {
				id: values.workOrderId,
				note,
			};

			reviewWorkOrderAndMarkUnsatisfactory(workOrderStatusChangeDetails).then(() => {
				this.setState({ rejectWorkModalVisible: false });
				message.success(`Work rejected.`);
			});
		});
	}

	handleRejectWorkCancel(e) {
		this.setState({
			rejectWorkModalVisible: false,
		});
	}

	showRejectModal(e) {
		this.setState({
			rejectWorkModalVisible: true,
		});
	}

	handleReminderSubmit = () => {
		const { appendWorkOrderNotes, currentUser, workOrder } = this.props;
		const form = this.formRefs['reminderForm'].props.form;

		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			const note = {
				text: values.note,
				noteAddedBy: currentUser.email,
				noteAddedAt: moment(),
			};
			const workOrderNoteDetails = {
				id: values.workOrderId,
				locationId: workOrder.locationId,
				note,
			};

			appendWorkOrderNotes(workOrderNoteDetails).then(() => {
				this.setState({ reminderModalVisible: false });
				message.success(`reminder sent.`);
			});
		});
	};

	handleReminderCancel = () => {
		this.setState({
			reminderModalVisible: false,
		});
	};

	showReminderModal = () => {
		this.setState({
			reminderModalVisible: true,
		});
	};

	markWorkOrderWaitingForReview = () => {
		const { markCompletedByThirdParty, workOrder } = this.props;

		return new Promise((resolve, reject) => {
			markCompletedByThirdParty({
				id: workOrder.id,
			})
				.then(() => {
					this.setState({ approveWorkModalVisible: false });
					message.success(`Work approved.`);
					resolve(true);
				})
				.catch((err) => {
					message.error(err);
					reject(err);
				});
		});
	};

	handleApproveWorkSubmit() {
		const {
			reviewWorkOrderAndMarkComplete,
			reviewInternalTechWorkOrderAndMarkComplete,
			workOrder,
			currentUser,
		} = this.props;

		const form = this.formRefs['supplierApproveReviewWorkForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			const note = values.note
				? {
						text: values.note,
						noteAddedBy: currentUser.email,
						noteAddedAt: moment(),
				  }
				: undefined;

			const isInternalServiceTeamWO =
				nullSafeGet('supplierFacility.status', workOrder) === 'private';

			const workOrderStatusChangeDetails = {
				id: workOrder.id,
				...(!!note && { note }),
			};

			const reviewMethod = isInternalServiceTeamWO
				? reviewInternalTechWorkOrderAndMarkComplete
				: reviewWorkOrderAndMarkComplete;

			reviewMethod(workOrderStatusChangeDetails)
				.then(() => {
					this.setState({ approveWorkModalVisible: false });
					message.success(`Work approved.`);
				})
				.catch((err) => message.error(err));
		});
	}

	showAddDetailModal = () => this.setState({ addDetailModalVisible: true });

	hideAddDetailModal = () => this.setState({ addDetailModalVisible: false });

	onAddDetailSubmit = (description) => {
		this.setState({ addingDetails: true });
		const { workOrder, updateWorkOrder } = this.props;

		updateWorkOrder({
			...workOrder,
			description,
		}).finally(() => {
			this.setState({
				addingDetails: false,
			});
			this.hideAddDetailModal();
		});
	};

	render() {
		const { workOrder, currentUser, companyConfig, history, userType } = this.props;

		const { loading, error } = this.state;

		const internalTechAllowedToComplete = nullSafeGetOrElse(
			'facility.internalTechConfig',
			currentUser,
			{}
		).allowedOneClickCompletion;

		const reviewAndCompleteAllowed = isInternalTech(currentUser)
			? typeof internalTechAllowedToComplete === 'undefined'
				? true
				: internalTechAllowedToComplete
			: nullSafeGetOrElse(
					'buyerCompany.buyerCompanySettings.config.workOrder.thirdPartySuppliersAllowedOneClickCompletion',
					workOrder,
					false
			  );

		const isPrivateSupplier = nullSafeGet('facility.status', currentUser) === 'private';
		const differentAssignedSupplier = isPrivateSupplier
			? nullSafeGet('facility.id', currentUser) !== workOrder.supplierFacilityId
			: false;
		let optionalDetails = {};
		if (workOrder.isWarranty) {
			optionalDetails['Warranty Status'] = 'Under Warranty';
		}
		const supplierCurrency = getCurrency({ workOrder });
		const workOrderDetails =
			(workOrder.id && {
				'Work Order ID': `# ${nullSafeGet('id', workOrder)}`,
				...(nullSafeGet('woNumber', workOrder) && {
					'Alias Work Order #': nullSafeGet('woNumber', workOrder),
				}),
				Reported: <DateTimeHover timestamp={nullSafeGet('createdAt', workOrder)} />,
				Due: <DateTimeHover timestamp={nullSafeGet('dueDate', workOrder)} />,
				NTE: nullSafeGet('nte', workOrder)
					? supplierCurrency.format(nullSafeGet('nte', workOrder))
					: nullSafeGet('nte', workOrder),
				Trade: nullSafeGet('spendCategory.name', workOrder),
				'Problem Type': nullSafeGet('problemType.hierarchyName', workOrder),
				Area: nullSafeGet('area.hierarchyName', workOrder),
				...(getPONumbers(workOrder) && {
					'Purchase Order #': getPONumbers(workOrder),
				}),
				...(isInternalTech(currentUser) && {
					'Expense Type': nullSafeGet('isCapex', workOrder) ? 'CapEx' : 'Repair & Maintenance',
				}),
				...(isInternalTech(currentUser) &&
					nullSafeGetOrElse(
						'buyerCompany.buyerCompanySettings.config.capExConfig.acceptCapExCategory',
						workOrder,
						false
					) &&
					nullSafeGet('isCapex', workOrder) && {
						'CapEx Category': workOrder.capExCategory || '--',
					}),
				...optionalDetails,
				...(nullSafeGet('assetId', workOrder) &&
					nullSafeGet('isAssetDown', workOrder) && {
						'Asset Reported As': (
							<span>
								{nullSafeGet('isAssetDown', workOrder) ? 'Offline' : 'Online'}{' '}
								{nullSafeGet('asset.downtimeStatus', workOrder) === 'online' ? (
									<span className="text-xs " style={{ color: 'green' }}>
										(online now)
									</span>
								) : nullSafeGet('isAssetDown', workOrder) ? (
									<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_ASSETS}>
										<EditOutlined
											onClick={this.handleAssetStatusChange}
											translate=""
											className="cursor-pointer text-base"
										/>
									</AccessPermissionChecker>
								) : null}
							</span>
						),
					}),
				...(nullSafeGet('completedByContact', workOrder)
					? {
							'Marked Complete By': (
								<UserName
									contact={nullSafeGet('completedByContact', workOrder)}
									mode={'inline'}
									placement={'right'}
								/>
							),
					  }
					: {}),
				...(nullSafeGet('completedAt', workOrder)
					? { Completed: <DateTimeHover timestamp={nullSafeGet('completedAt', workOrder)} /> }
					: {}),
				...(nullSafeGet('resolutionTypeId', workOrder)
					? {
							'Resolution Type': nullSafeGet('resolutionType.resolutionCode', workOrder)
								? `${nullSafeGet('resolutionType.resolutionCode', workOrder)} - ${nullSafeGet(
										'resolutionType.resolutionType',
										workOrder
								  )}`
								: nullSafeGet('resolutionType.resolutionType', workOrder),
					  }
					: {}),
				estimatedCompletionDate: (
					<DateTimeHover
						timestamp={
							(nullSafeGet('estimatedCompletionDate', workOrder) &&
								dateFormatter(workOrder.estimatedCompletionDate, 'D MMM YYYY')) ||
							dateFormatter(workOrder.dueDate, 'D MMM YYYY')
						}
						showDate={true}
						showTime={false}
					/>
				),
			}) ||
			{};

		const filteredWorkOrderDetails = Object.keys(workOrderDetails).reduce((acc, el) => {
			const val = workOrderDetails[el];
			if (val !== null && val !== 'undefined' && val !== undefined && val !== '') {
				acc[el] = workOrderDetails[el];
			}
			return acc;
		}, {});

		if (error)
			return (
				<OWResult
					error={error}
					title="You no longer have access to this work order."
					subTitle="Either its deleted or been reassigned."
				/>
			);
		return loading ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="workOrdersDetailDetailsPage" key={nullSafeGet('id', workOrder)}>
				<LogOnMountWithStandardEventProperties eventType="visited supplier work order detail page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<AssetStatusForm
					downTimeHistory={this.state.downTimeHistory}
					asset={nullSafeGet('asset', workOrder)}
					onCancel={this.handleAssetStatusCancel}
					onSuccess={this.handleAssetStatusSuccess}
					visible={this.state.assetStatusChangeModalVisible}
				/>
				{this.state.approveWorkModalVisible ? (
					<SupplierApproveReviewWorkForm
						wrappedComponentRef={this.saveFormRef('supplierApproveReviewWorkForm')}
						visible
						workOrderId={workOrder.id}
						onCancel={this.handleApproveWorkCancel}
						onSubmit={this.handleApproveWorkSubmit}
						onlyReview={this.state.onlyReview}
					/>
				) : null}
				<SupplierRejectReviewWorkForm
					wrappedComponentRef={this.saveFormRef('supplierRejectReviewWorkForm')}
					visible={this.state.rejectWorkModalVisible}
					workOrderId={workOrder.id}
					onCancel={this.handleRejectWorkCancel}
					onSubmit={this.handleRejectWorkSubmit}
				/>
				<ReminderForm
					wrappedComponentRef={this.saveFormRef('reminderForm')}
					visible={this.state.reminderModalVisible}
					workOrderId={workOrder.id}
					onCancel={this.handleReminderCancel}
					onSubmit={this.handleReminderSubmit}
				/>
				{this.state.scheduleServiceCallForTechAssignedFormVisible && (
					<ScheduleServiceCallModalForm
						formData={{
							...nullSafeGetOrElse('lastServiceCall', workOrder, {}),
							workOrder: workOrder,
						}}
						wrappedComponentRef={this.saveFormRef('scheduleServiceCallForTechAssignedForm')}
						visible={this.state.scheduleServiceCallForTechAssignedFormVisible}
						onCancel={this.handleScheduleForTechAssignedCancel}
						onSubmit={this.handleScheduleServiceCallForTechAssignedSubmit}
					/>
				)}
				<ScheduleServiceCallModalForm
					formData={{
						...nullSafeGetOrElse('lastServiceCall', workOrder, {}),
						workOrder: workOrder,
					}}
					isReschedule={true}
					wrappedComponentRef={this.saveFormRef('rescheduleServiceCallForm')}
					visible={this.state.rescheduleServiceCallFormVisible}
					onCancel={this.handleRescheduleServiceCallCancel}
					onSubmit={this.handleRescheduleServiceCallSubmit}
				/>
				<ScheduleServiceCallModalForm
					formData={{
						workOrder: workOrder,
						workOrderId: workOrder.id,
						supplierFacilityId: nullSafeGet('supplierFacilityId', workOrder),
					}}
					wrappedComponentRef={this.saveFormRef('scheduleServiceCallForm')}
					visible={this.state.scheduleServiceCallFormVisible}
					onCancel={this.handleScheduleServiceCallCancel}
					onSubmit={this.handleScheduleServiceCallSubmit}
				/>
				<ManualCheckInModalForm
					formData={nullSafeGetOrElse('lastServiceCall', workOrder, {})}
					wrappedComponentRef={this.saveFormRef('manualCheckInForm')}
					visible={this.state.manualCheckInFormVisible}
					onCancel={this.handleManualCheckInCancel}
					onSubmit={this.handleManualCheckInSubmit}
				/>
				<ManualCheckOutModalForm
					formData={nullSafeGetOrElse('lastServiceCall', workOrder, {})}
					wrappedComponentRef={this.saveFormRef('manualCheckOutForm')}
					visible={this.state.manualCheckOutFormVisible}
					onCancel={this.handleManualCheckOutCancel}
					onSubmit={this.handleManualCheckOutSubmit}
					privateSupplier={nullSafeGet('facility.status', currentUser) === 'private' ? true : false}
				/>
				<PartsOrderedOrReceivedForm
					wrappedComponentRef={this.saveFormRef('partsOrderedForm')}
					title="Parts Ordered"
					visible={this.state.partsOrderedModalVisible}
					workOrderId={workOrder.id}
					onCancel={this.handlePartsOrderedCancel}
					onSubmit={this.handlePartsOrderedSubmit}
				/>
				<PartsOrderedOrReceivedForm
					wrappedComponentRef={this.saveFormRef('partsReceivedForm')}
					title="Parts Received"
					visible={this.state.partsReceivedModalVisible}
					workOrderId={workOrder.id}
					onCancel={this.handlePartsReceivedCancel}
					onSubmit={this.handlePartsReceivedSubmit}
				/>
				<PartsRequestedForm
					wrappedComponentRef={this.saveFormRef('partsRequestedForm')}
					visible={this.state.partsRequestedModalVisible}
					workOrderId={workOrder.id}
					onCancel={this.handlePartsRequestedCancel}
					onSubmit={this.handlePartsRequestedSubmit}
				/>
				<TransferWorkOrderModalForm
					formData={workOrder}
					wrappedComponentRef={this.saveFormRef('transferWorkOrderForm')}
					visible={this.state.transferWorkOrderFormVisible}
					onCancel={this.handleTransferWorkOrderCancel}
					onSubmit={this.handleTransferWorkOrderSubmit}
				/>
				<BackDateWorkOrderModalForm
					formData={workOrder}
					wrappedComponentRef={this.saveFormRef('backDaterWorkOrderForm')}
					visible={this.state.backDateWorkOrderFormVisible}
					onCancel={this.handlebackDateWorkOrderCancel}
					onSubmit={this.handlebackDaterWorkOrderSubmit}
				/>
				<EstimatedDateWorkOrderModalForm
					formData={workOrder}
					wrappedComponentRef={this.saveFormRef('estimatedDaterWorkOrderForm')}
					visible={this.state.estimatedDateWorkOrderFormVisible}
					onCancel={this.handleEstimatedDateWorkOrderCancel}
					onSubmit={this.handleEstimatedDateWorkOrderSubmit}
				/>
				{this.state.addDetailModalVisible && (
					<RichTextEditorModal
						id="work-order-description"
						title={`Update Additional info`}
						onCancel={this.hideAddDetailModal}
						loading={this.state.addingDetails}
						okText="Update Additional info"
						onSubmit={this.onAddDetailSubmit}
						useRichText
						notes={nullSafeGet('description', workOrder)}
					/>
				)}
				<Row gutter={16}>
					<Col lg={24} xl={16}>
						<div className="rowSpacing">
							<Card
								bodyStyle={{ padding: 0 }}
								title="Work Order Details"
								extra={
									<div className="flex flex-row items-center">
										{!workOrder.description || workOrder.description.length === 0 ? (
											<Button
												className="list_button mr-2"
												size="small"
												type="link"
												onClick={this.showAddDetailModal}
											>
												Add Details
											</Button>
										) : null}
										<WorkOrderPrintable workOrder={workOrder} companyConfig={companyConfig} />
									</div>
								}
							>
								<div className="materialCard__body">
									{isInternalTech(currentUser) ? (
										<WorkOrderActions
											workOrder={workOrder}
											showApproveModal={this.showApproveModal}
											showReminderModal={this.showReminderModal}
											currentUser={currentUser}
											companyConfig={nullSafeGet('detail', companyConfig)}
											refreshWorkOrder={this.refreshWorkOrder}
											reviewAndCompleteDisabled={!reviewAndCompleteAllowed}
										/>
									) : reviewAndCompleteAllowed ? (
										<ReviewAndMarkCompleteComponent
											workOrder={workOrder}
											isThirdPartySupplier
											markWorkOrderCompleteByThirdParty={this.markWorkOrderWaitingForReview}
										/>
									) : null}
									{workOrder.description && workOrder.description.length > 0 ? (
										<div className="flex flex-row items-start">
											<div className="materialCard__primaryTitle">
												<div
													className="richTextWrapper"
													dangerouslySetInnerHTML={{ __html: workOrder.description }}
												/>
											</div>
											<Button
												type="link"
												icon={<EditOutlined translate="" onClick={this.showAddDetailModal} />}
											/>
										</div>
									) : null}
									{workOrder.images && workOrder.images.length > 0 ? (
										<div className="materialCard__subtitle">
											{
												<ProtectedImageThumbnails
													style={{ marginBottom: 32 }}
													imageWidth={160}
													imageHeight={160}
													photoStrings={workOrder.images}
													role={ROLE_TYPES.SUPPLIER}
												/>
											}
										</div>
									) : null}
									<div className="materialCard__supportingText">
										<KeyValueDisplay
											horizontal={true}
											keyWidth={120}
											keyValueStore={filteredWorkOrderDetails}
											keyAliases={{
												Reported: (
													<span>
														Reported{' '}
														<AccessPermissionChecker
															name={PERMISSION_NAMES.CAN_BACKDATE_WORK_ORDER}
														>
															<EditOutlined
																onClick={this.handlebackDate}
																translate=""
																className="font-base cursor-pointer"
															/>
														</AccessPermissionChecker>
													</span>
												),
												estimatedCompletionDate: (
													<span>
														Estimated Completion Date{' '}
														{['Cancelled', 'Completed'].includes(workOrder.displayStatus) ? null : (
															<EditOutlined
																onClick={this.handleEstimatedDate}
																translate=""
																className="font-base cursor-pointer"
															/>
														)}
													</span>
												),
											}}
										/>
									</div>
								</div>
							</Card>
						</div>

						<TroubleshootingDetailDisplay workOrder={workOrder} />

						{nullSafeGet('associatedServiceCalls', workOrder) &&
						nullSafeGet('associatedServiceCalls', workOrder).length > 0 ? (
							<div className="rowSpacing">
								<Card title="Service Calls">
									{SCHEDULE_TECH_STATUSES.includes(workOrder.status) ? (
										<div>
											{differentAssignedSupplier ? (
												<div style={{ fontSize: 16, maxWidth: 480 }}>
													Ready to schedule service call for{' '}
													{nullSafeGet('supplierFacility.name', workOrder)}?
												</div>
											) : (
												<div style={{ fontSize: 16, maxWidth: 480 }}>
													Ready to schedule service call?
												</div>
											)}
											<div style={{ marginTop: 16, marginBottom: 40 }}>
												<Button type="primary" key="schedule" onClick={this.handleSchedule}>
													Schedule Service
												</Button>
												{differentAssignedSupplier ? null : nullSafeGetOrElse(
														'detail.config.partsOrderingConfig.needPartsRequisitionBeforeOrdering',
														companyConfig,
														false
												  ) &&
												  workOrder.status !== WORK_ORDER_STATUSES.partsRequested &&
												  workOrder.status !== WORK_ORDER_STATUSES.partsOnOrder ? (
													<Button
														key="partsRequested"
														onClick={this.handlePartsRequested}
														style={{ marginLeft: 16 }}
													>
														Request Parts
													</Button>
												) : workOrder.status !== WORK_ORDER_STATUSES.partsOnOrder ? (
													<Button
														key="partsOnOrder"
														onClick={this.handlePartsOrdered}
														style={{ marginLeft: 16 }}
													>
														Parts Ordered
													</Button>
												) : (
													<Button
														key="partsReceived"
														onClick={this.handlePartsReceived}
														style={{ marginLeft: 16 }}
													>
														Parts Received
													</Button>
												)}
											</div>
										</div>
									) : workOrder.status === WORK_ORDER_STATUSES.techAssigned ? (
										<div>
											{differentAssignedSupplier ? (
												<div style={{ fontSize: 16, maxWidth: 480 }}>
													Ready to schedule service call for{' '}
													{nullSafeGet('supplierFacility.name', workOrder)}?
												</div>
											) : (
												<div style={{ fontSize: 16, maxWidth: 480 }}>
													Ready to schedule service call?
												</div>
											)}
											<div style={{ marginTop: 16, marginBottom: 40 }}>
												<Button
													type="primary"
													key="schedule"
													onClick={this.handleScheduleForTechAssigned}
												>
													Schedule Service
												</Button>
												{differentAssignedSupplier ? null : (
													<Button
														key="startWork"
														loading={this.state.startingWork}
														style={{ marginLeft: 16 }}
														onClick={this.handleStartWorkForTechAssigned}
													>
														Start Work
													</Button>
												)}
												<Button
													key="partsOnOrder"
													onClick={this.handlePartsOrdered}
													style={{ marginLeft: 16 }}
												>
													Parts Ordered
												</Button>
											</div>
										</div>
									) : null}
									<SupplierServiceCallDetails
										workOrder={workOrder}
										handleManualCheckOut={this.handleManualCheckOut}
										showApproveModal={this.showApproveModal}
										showRejectModal={this.showRejectModal}
										handleReschedule={this.handleReschedule}
										handleManualCheckIn={this.handleManualCheckIn}
									/>
								</Card>
							</div>
						) : (
							//Internal Technicians?
							<div className="rowSpacing">
								<Card title="Service Calls">
									<div>
										{differentAssignedSupplier ? (
											<div style={{ fontSize: 16, maxWidth: 480 }}>
												{nullSafeGet('supplierFacility.name', workOrder)} hasn't scheduled a service
												call for this work order yet.
											</div>
										) : (
											<div style={{ fontSize: 16, maxWidth: 480 }}>
												You haven't scheduled a service call for this work order yet.
											</div>
										)}
										{NOT_SCHEDULABLE_STATUSES.includes(workOrder.status) ? null : (
											<div style={{ marginTop: 16, marginBottom: 40 }}>
												<Button type="primary" key="schedule" onClick={this.handleSchedule}>
													Schedule Service
												</Button>
												{differentAssignedSupplier ? null : (
													<Button
														key="startWork"
														loading={this.state.startingWork}
														style={{ marginLeft: 16 }}
														onClick={this.handleStartWork}
													>
														Start Work
													</Button>
												)}
												{differentAssignedSupplier ? null : nullSafeGetOrElse(
														'detail.config.partsOrderingConfig.needPartsRequisitionBeforeOrdering',
														companyConfig,
														false
												  ) && workOrder.status !== WORK_ORDER_STATUSES.partsRequested ? (
													<Button
														key="partsRequested"
														onClick={this.handlePartsRequested}
														style={{ marginLeft: 16 }}
													>
														Request Parts
													</Button>
												) : workOrder.status !== WORK_ORDER_STATUSES.partsOnOrder ? (
													<Button
														key="partsOnOrder"
														onClick={this.handlePartsOrdered}
														style={{ marginLeft: 16 }}
													>
														Parts Ordered
													</Button>
												) : (
													<Button
														key="partsReceived"
														onClick={this.handlePartsReceived}
														style={{ marginLeft: 16 }}
													>
														Parts Received
													</Button>
												)}
											</div>
										)}
									</div>
								</Card>
							</div>
						)}
					</Col>
					<Col lg={24} xl={8}>
						{workOrder.id ? (
							isPrivateSupplier ? (
								<>
									<div className="rowSpacing">
										<Card title="Your Attachments">
											<FileUploader
												roleType="supplier"
												defaultFileList={workOrder.buyerAttachments || []}
												handleUploadSuccess={this.handleFileAttachmentUploadChange}
												uploaderType={'dragger'}
											/>
										</Card>
									</div>
									{nullSafeGet('supplierAttachments.0', workOrder) ? (
										<div className="rowSpacing">
											<Card title="Supplier Attachments">
												<FileUploader
													roleType="supplier"
													defaultFileList={workOrder.supplierAttachments || []}
													uploaderType={'readOnly'}
												/>
											</Card>
										</div>
									) : null}
								</>
							) : (
								<div className="rowSpacing">
									<Card title="Your Attachments">
										<FileUploader
											roleType="supplier"
											defaultFileList={workOrder.supplierAttachments || []}
											handleUploadSuccess={this.handleFileAttachmentUploadChange}
											uploaderType={'dragger'}
										/>
									</Card>
								</div>
							)
						) : null}
						{isPrivateSupplier && workOrder.id ? (
							<QuestionnaireSurvey workOrder={workOrder} />
						) : null}
						{!workOrder.id || isPrivateSupplier ? null : workOrder.buyerAttachments === undefined ||
						  workOrder.buyerAttachments.length === 0 ? null : (
							<div className="rowSpacing">
								<Card title="Customer Attachments">
									<FileUploader
										roleType="supplier"
										defaultFileList={workOrder.buyerAttachments || []}
										uploaderType={'readOnly'}
									/>
								</Card>
							</div>
						)}
						{nullSafeGet('location', workOrder) ? (
							<div className="rowSpacing">
								<SupplierLocationCardDisplay
									location={workOrder.location}
									onViewLocationPage={() =>
										history.push(
											`/supplier/locations/detail/${nullSafeGet('location.id', workOrder)}`
										)
									}
								/>
							</div>
						) : null}
						{nullSafeGet('plannedMaintenanceSchedule.title', workOrder) ? (
							<div className="rowSpacing">
								<Card title="Planned Maintenance Schedule">
									<div className="mb-4">
										{isAdminUser(currentUser) ? (
											<HyperLink
												text={
													<span className="text-lg font-bold text-black">
														{workOrder.plannedMaintenanceSchedule.title}
													</span>
												}
												newTabPath={`/${userType}/plannedMaintenance/detail/${encodeURIComponent(
													nullSafeGet('plannedMaintenanceSchedule.title', workOrder)
												)}/${encodeURIComponent(
													nullSafeGet('plannedMaintenanceSchedule.frequency', workOrder)
												)}/${encodeURIComponent(
													nullSafeGet(
														'plannedMaintenanceSchedule.plannedMaintenanceTemplateId',
														workOrder
													)
												)}/${encodeURIComponent(
													nullSafeGet('plannedMaintenanceSchedule.basedOn', workOrder)
												)}/details`}
											/>
										) : (
											<span className="text-lg font-bold text-black">
												{workOrder.plannedMaintenanceSchedule.title}
											</span>
										)}
									</div>

									<div className="flex flex-row items-center justify-between">
										<div className="plannedMaintenanceSchedulesRowDisplay__rowInlineGroup">
											<i className="icons8-font icons8-settings"></i>{' '}
											{workOrder.plannedMaintenanceSchedule.basedOn === 'asset'
												? 'Asset Based'
												: workOrder.plannedMaintenanceSchedule.basedOn === 'assetType'
												? 'Asset Type Based'
												: 'Location Based'}
										</div>
										<div className="plannedMaintenanceSchedulesRowDisplay__rowInlineGroup">
											<i className="icons8-font icons8-calendar"></i>{' '}
											{workOrder.plannedMaintenanceSchedule.frequency}
										</div>
									</div>

									{nullSafeGet(
										'plannedMaintenanceSchedule.supplierContractDocuments',
										workOrder
									) ? (
										<div className="mt-4  font-medium">
											Contract Details:
											<FileUploader
												roleType={userType}
												defaultFileList={
													workOrder.plannedMaintenanceSchedule.supplierContractDocuments || []
												}
												uploaderType={'readOnly'}
											/>
										</div>
									) : null}
								</Card>
							</div>
						) : null}
						{nullSafeGet('asset', workOrder) ? (
							<div className="rowSpacing">
								<AssetCardDisplay asset={workOrder.asset} />
							</div>
						) : null}
						{nullSafeGet('supplierFacility', workOrder) ? (
							<div className="rowSpacing">
								<ServiceProviderCardDisplay workOrder={workOrder} />
							</div>
						) : null}
						<div className="rowSpacing">
							<WorkOrderInternalContact workOrder={workOrder} />
						</div>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	workOrdersFetching: state.work_orders.fetching,
	workOrder: getEntityById(ownProps.match.params.id, state.work_orders),
	currentUser: state.session.currentUser,
	companyConfig: state.company_config,
	match: ownProps.match,
	history: ownProps.history,
	userType: state.session.userType,
});

const mapDispatchToProps = (dispatch) => ({
	scheduleServiceCall: (serviceCall) => dispatch(scheduleServiceCallForSupplier(serviceCall)),
	rescheduleServiceCall: (serviceCall) => dispatch(rescheduleServiceCallForSupplier(serviceCall)),
	reviewWorkOrderAndMarkComplete: (entity) =>
		dispatch(reviewWorkOrderAndMarkCompleteForSupplier(entity)),
	reviewInternalTechWorkOrderAndMarkComplete: (entity) =>
		dispatch(reviewInternalTechWorkOrderAndMarkCompleteForSupplier(entity)),
	markCompletedByThirdParty: (entity) => dispatch(markCompletedByThirdPartyForSupplier(entity)),
	reviewWorkOrderAndMarkUnsatisfactory: (entity) =>
		dispatch(reviewWorkOrderAndMarkUnsatisfactoryForSupplier(entity)),
	manualCheckIn: (serviceCall) => dispatch(manualCheckInServiceCallForSupplier(serviceCall)),
	manualCheckOut: (serviceCall) => dispatch(manualCheckOutServiceCallForSupplier(serviceCall)),
	getWorkOrder: (id) => dispatch(workOrdersRestCrudThunksForSupplier.readOne(id)),
	updateWorkOrder: (entity) => dispatch(workOrdersRestCrudThunksForSupplier.update(entity)),
	appendWorkOrderNotes: (entity) => dispatch(appendWorkOrderNotesForSupplier(entity)),
	partsOrderedWorkOrder: (entity) => dispatch(partsOrderedWorkOrderForSupplier(entity, 'id')),
	partsReceivedWorkOrder: (entity) => dispatch(partsReceivedWorkOrderForSupplier(entity, 'id')),
	partsRequestedWorkOrder: (entity) => dispatch(partsRequestedWorkOrderForSupplier(entity, 'id')),
	archiveWorkOrder: (entity) =>
		dispatch(workOrdersRestCrudThunksForSupplier.archive(entity, 'id', true)),
	unarchiveWorkOrder: (entity) =>
		dispatch(workOrdersRestCrudThunksForSupplier.unarchive(entity, 'id', true)),
	acceptWorkOrder: (entity) => dispatch(acceptWorkOrderForSupplier(entity, 'id')),
	declineWorkOrder: (entity) => dispatch(declineWorkOrderForSupplier(entity, 'id')),
	transferWorkOrder: (entity) => dispatch(transferWorkOrderForSupplier(entity, 'id')),
	cancelWorkOrder: (entity) => dispatch(cancelWorkOrderForSupplier(entity)),
	markUserNotificationsRead: (entityId) =>
		dispatch(markUserNotificationsReadForSupplier('WorkOrder', entityId)),
	updateWorkOrderResolutionType: (workOrderId, resolutionTypeId) =>
		dispatch(updateWorkOrderResolutionTypeForSupplier(workOrderId, resolutionTypeId)),
	backDataWorkOrder: (entity, reportedAt) =>
		dispatch(backDataWorkOrderForSupplier(entity, reportedAt)),
	getAssetDowntime: (params, userType) => dispatch(assetDowntimeGet(userType)(params)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SupplierWorkOrdersDetailPage)
);

import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

export default function NoAddWorKOrderRightActions({
	downloadCSV,
	switchDisplayMode,
	isDisplayModeActive,
	currentUser,
}) {
	return (
		<>
			<Button
				size="large"
				icon={<DownloadOutlined />}
				className="inline-block-visible-md"
				onClick={downloadCSV}
			>
				<span className="inline-block-visible-xxl">Export CSV</span>
			</Button>
		</>
	);
}

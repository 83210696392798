export const SAVE_SOURCING_FORM_DATA = 'SAVE_SOURCING_FORM_DATA';
export function saveSourcingFormData(formData) {
	return {
		type: SAVE_SOURCING_FORM_DATA,
		formData,
	};
}

export const SOURCING_FORM_SUBMITTING = 'SOURCING_FORM_SUBMITTING';
export function sourcingFormSubmitting() {
	return {
		type: SOURCING_FORM_SUBMITTING,
	};
}

export const SOURCING_FORM_DONE_SUBMITTING = 'SOURCING_FORM_DONE_SUBMITTING';
export function sourcingFormDoneSubmitting() {
	return {
		type: SOURCING_FORM_DONE_SUBMITTING,
	};
}

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FC } from 'react';
import SupplierAssetDetailPageDisplay from '../supplier_asset_detail_page_display/SupplierAssetDetailPageDisplay';
import { isInternalTech } from '../../utils/AuthUtils';
import AssetDetailPageDisplay from '../asset_detail_page_display/AssetDetailPageDisplay';
import { getEntityById } from '../../utils/DataAccessUtils';

const SupplierAssetsDetailsDetailPage: FC<any> = ({
	asset,
	assetsFetching,
	currentUser,
}): React.ReactElement => {
	const isExternalSupplier = !isInternalTech(currentUser);
	return isExternalSupplier ? (
		<SupplierAssetDetailPageDisplay asset={asset} loading={!asset.id && assetsFetching} />
	) : (
		<AssetDetailPageDisplay asset={asset} loading={!asset.id && assetsFetching} />
	);
};

const mapStateToProps = (state, ownProps) => ({
	assetsFetching: state.assets.fetching,
	asset: getEntityById(ownProps.match.params.id, state.assets),
	currentUser: state.session.currentUser,
});

export default withRouter<any, any>(connect(mapStateToProps)(SupplierAssetsDetailsDetailPage));

import React, { FC } from 'react';
import { connect } from 'react-redux';
import { DatePicker } from 'antd';
import moment from 'moment';

const OWDatePicker: FC<any> = ({
	disabledStart = moment('1/1/1970', 'MM/DD/YYYY'),
	disabledEnd = moment('1/1/2038', 'MM/DD/YYYY'),
	otherDisabledDates = [],
	...props
}) => {
	const disabledDate = (current) => {
		// Can not select days greater/less than database can handle
		return (
			current &&
			(current > disabledEnd ||
				current < disabledStart ||
				otherDisabledDates.some((d) => d === current))
		);
	};

	return <DatePicker.RangePicker {...props} disabledDate={disabledDate} />;
};

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OWDatePicker);

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Card } from 'antd';
import { readBiddersForBuyer } from '../../thunks/bidders_thunks';
import { BIDDERS_CRUD_ACTION_CREATORS } from '../../actions/bidders_actions';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import RFPBidderForm from '../rfp_bidder_form/RFPBidderForm';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const queryString = require('qs');
const { Content } = Layout;

class RFPBidderEditPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const { match, saveFormData, getBiddersForRequestForProposal } = this.props;
		const requestForProposalId = match.params.id;
		getBiddersForRequestForProposal(requestForProposalId).then((bidders) => {
			const formData = {
				bidders: bidders.map((b) => ({ ...b, supplierFacilityId: b.supplierFacility.id })),
			};
			saveFormData(formData);
		});
	}

	componentWillUnmount() {
		const { clearBidders } = this.props;
		clearBidders();
	}

	render() {
		const { match, history, biddersFormData, biddersFetching } = this.props;
		const fetching = biddersFetching;
		const requestForProposalId = match.params.id;
		return fetching ? (
			<PageLoadingPlaceholder />
		) : (
			<Content>
				<LogOnMountWithStandardEventProperties eventType="visited buyer edit rfp page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<Card>
							<div>
								<h5 style={{ marginBottom: 32 }}>Edit Bidders</h5>
								<RFPBidderForm
									formData={biddersFormData}
									requestForProposalId={requestForProposalId}
									redirectForwardUrl={`/buyer/requestsForProposal/detail/${requestForProposalId}`}
								/>
							</div>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	biddersFormData: state.bidders.formData,

	biddersFetching: state.bidders.fetching,
});

const mapDispatchToProps = (dispatch) => ({
	saveFormData: (formData) => dispatch(BIDDERS_CRUD_ACTION_CREATORS.saveFormData(formData)),
	clearBidders: () => dispatch(BIDDERS_CRUD_ACTION_CREATORS.clearFormData()),
	getBiddersForRequestForProposal: (rfpId) => dispatch(readBiddersForBuyer(rfpId)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(RFPBidderEditPage)
);

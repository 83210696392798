import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { TASK_LISTS_CRUD_ACTION_CREATORS } from '../actions/task_lists_actions';

export const taskListsRestCrudThunksForBuyer = new RestCrudThunks(
	'task_lists',
	'task_list',
	'taskList',
	'taskLists',
	TASK_LISTS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'task/'
);

export const taskListsRestCrudThunksForSupplier = new RestCrudThunks(
	'task_lists',
	'task_list',
	'taskList',
	'taskLists',
	TASK_LISTS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'task/'
);

import React, { FC, useCallback, useMemo, useState } from 'react';
import {
	createWithApprovedStatus,
	purchaseRequestLineItemsRestCrudThunksForSupplier,
} from '../../thunks/purchase_request_line_items_thunks';
import { Button, Modal, Popover, message } from 'antd';
import { connect } from 'react-redux';
import { PR_LINE_ITEM_COLUMNS } from '../purchase_requests_index_page/PRLineItemsComponent';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { EmptyState } from '../empty_state/EmptyState';
import { getPOLineItemsFromSelectedRecords } from '../purchase_requests_index_page/pr_to_po_utils';
import { getCurrency, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import AddPartInPRForm from '../purchase_request_form/AddPartInPRForm';
import AddEquipmentInPRForm from '../purchase_request_form/AddEquipmentInPRForm';
import { PURCHASE_REQUEST_STATUSES } from '../../constants/PurchaseRequestStatuses';

const PR_TC_NAME = 'purchaseRequestLineItemsIndex';

const AddLineItemsToPOModal: FC<any> = ({
	isEquipmentLine,
	stockLocationId,
	locationId,
	partEquipmentVendorId,
	existingPartLineItems,
	existingEquipmentLineItems,
	onCancel,
	onAddLineItemsSuccess,
	currentUser,
	addNewPRLineItem,
}): React.ReactElement => {
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [selectedRecords, setSelectedRecords] = useState([]);

	const [addPartVisible, setAddPartVisible] = useState(false);
	const [addEquipmentVisible, setAddEquipmentVisible] = useState(false);

	const [addingNewLineItem, setAddingNewLineItem] = useState(false);

	const title = useMemo(() => (isEquipmentLine ? 'Add Equipment' : 'Add Parts'), [isEquipmentLine]);

	const onSelectChange = useCallback((newSelectedRowKeys: React.Key[], selectedRows) => {
		setSelectedRowKeys(newSelectedRowKeys);
		setSelectedRecords(selectedRows);
	}, []);

	const allPRLineItemIds = useMemo(
		() =>
			[...(existingPartLineItems || []), ...(existingEquipmentLineItems || [])].reduce(
				(acc, lineItem) => [...acc, ...(lineItem.prLineItemIds || [])],
				[]
			),
		[existingEquipmentLineItems, existingPartLineItems]
	);

	const rowSelection = useMemo(
		() => ({
			selectedRowKeys,
			preserveSelectedRowKeys: true,
			onChange: onSelectChange,
			getCheckboxProps: (record) => ({
				disabled: allPRLineItemIds.includes(record.id),
			}),
		}),
		[allPRLineItemIds, onSelectChange, selectedRowKeys]
	);

	const onAddLineItems = useCallback(() => {
		const pli = existingPartLineItems.reduce(
			(acc, item) => ({
				...acc,
				[item.partId]: {
					...item,
					unitCost: nullSafeGet('partCost', item)
						? item.partCost / nullSafeGetOrElse('partQuantity', item, 1)
						: undefined,
				},
			}),
			{}
		);

		const eli = existingEquipmentLineItems.reduce(
			(acc, item) => ({
				...acc,
				[item.equipmentTypeId]: {
					...item,
					unitCost: nullSafeGet('equipmentCost', item)
						? item.equipmentCost / nullSafeGetOrElse('equipmentQuantity', item, 1)
						: undefined,
				},
			}),
			{}
		);

		const { partLineItems, equipmentLineItems } = getPOLineItemsFromSelectedRecords(
			selectedRecords,
			pli,
			eli
		);

		onAddLineItemsSuccess({
			partLineItems,
			equipmentLineItems,
		});
	}, [existingEquipmentLineItems, existingPartLineItems, onAddLineItemsSuccess, selectedRecords]);

	const purchaseRequests = useMemo(() => {
		const allPRs = [...existingPartLineItems, ...existingEquipmentLineItems]
			.flatMap((poLi) =>
				nullSafeGetOrElse('supplierPurchaseRequestLineItems', poLi, []).map(
					(prLi) => prLi.supplierPurchaseRequest
				)
			)
			.filter(
				(value, index, self) => !!value && index === self.findIndex((_) => _.id === value.id)
			);
		/*
				1. Equipment cannot be added to PRs which has work order association
				2. Part cannot be added to a Location, if the PR isn't from a WO
				*/
		return isEquipmentLine
			? allPRs.filter((_) => !_.workOrderId)
			: !!locationId
			? allPRs.filter((_) => !!_.workOrderId)
			: allPRs;
	}, [existingEquipmentLineItems, existingPartLineItems, isEquipmentLine, locationId]);

	const isValidPRAvailable = useMemo(
		() => !!nullSafeGet('0.id', purchaseRequests),
		[purchaseRequests]
	);

	const addNewPartEquipmentErrorMessage = useMemo(
		() =>
			isEquipmentLine
				? 'Equipment cannot be added to purchase requests with work order'
				: 'Parts cannot be added to location without work order',
		[isEquipmentLine]
	);

	const _showAddEquipment = useCallback(() => setAddEquipmentVisible(true), []);
	const _showAddPart = useCallback(() => setAddPartVisible(true), []);

	const _hideAddEquipment = useCallback(() => setAddEquipmentVisible(false), []);
	const _hideAddPart = useCallback(() => setAddPartVisible(false), []);

	const afterAddItem = useCallback(
		(prLi) => {
			message.success('Added successfully!');
			_hideAddEquipment();
			_hideAddPart();
			if (prLi.id) {
				setSelectedRowKeys((ids) => [...ids, ...[prLi.id]]);
				setSelectedRecords((records) => [...records, ...[prLi]]);
			}
		},
		[_hideAddEquipment, _hideAddPart]
	);

	const onAddNewPartLineItem = useCallback(
		(prLineItems) => {
			const userCurrencyId = getCurrency({ currentUser }).id;
			setAddingNewLineItem(true);
			const prLineItem = prLineItems[0];

			const entity = {
				id: prLineItem.partLineItemId,
				isEquipmentLine: false,
				partId: prLineItem.partId,
				partCatalogId: prLineItem.partCatalogId,
				partQuantity: parseInt(prLineItem.quantity),
				partUnitCost: prLineItem.unitCost ? parseFloat(prLineItem.unitCost) : 0,
				partCost: prLineItem.cost ? parseFloat(prLineItem.cost) : undefined,
				partCurrencyId: nullSafeGetOrElse(
					'partCatalog.partEquipmentVendor.currencyId',
					prLineItem,
					userCurrencyId
				),
				partUomId: 1,
				equipmentCurrencyId: userCurrencyId,
				equipmentUomId: 1,
				status: PURCHASE_REQUEST_STATUSES.approved,
				supplierPurchaseRequestId: prLineItem.supplierPurchaseRequestId,
			};
			addNewPRLineItem(entity)
				.then((prLi) => {
					console.log(prLi);
					afterAddItem(prLi);
				})
				.catch((err) => {
					console.log(err);
					message.error(err);
				})
				.finally(() => setAddingNewLineItem(false));
		},
		[addNewPRLineItem, afterAddItem, currentUser]
	);

	const onAddNewEquipmentTypeLineItem = useCallback(
		(prLineItems) => {
			const userCurrencyId = getCurrency({ currentUser }).id;
			setAddingNewLineItem(true);
			const prLineItem = prLineItems[0];
			const entity = {
				id: prLineItem.equipmentLineItemId,
				isEquipmentLine: true,
				equipmentTypeId: prLineItem.equipmentTypeId,
				equipmentCatalogId: prLineItem.equipmentCatalogId,
				equipmentQuantity: parseInt(prLineItem.quantity, 10),
				equipmentUnitCost: prLineItem.unitCost ? parseFloat(prLineItem.unitCost) : 0,
				equipmentCost: prLineItem.cost ? parseFloat(prLineItem.cost) : undefined,
				partCurrencyId: userCurrencyId,
				partUomId: 1,
				equipmentCurrencyId: nullSafeGetOrElse(
					'equipmentCatalog.partEquipmentVendor.currencyId',
					prLineItem,
					userCurrencyId
				),
				equipmentUomId: 1,
				status: PURCHASE_REQUEST_STATUSES.approved,
				supplierPurchaseRequestId: prLineItem.supplierPurchaseRequestId,
			};
			addNewPRLineItem(entity)
				.then((prLi) => {
					afterAddItem(prLi);
				})
				.catch((err) => message.error(err))
				.finally(() => setAddingNewLineItem(false));
		},
		[addNewPRLineItem, afterAddItem, currentUser]
	);

	const addNewButton = useMemo(
		() => (
			<Popover content={isValidPRAvailable ? '' : addNewPartEquipmentErrorMessage} trigger="hover">
				<Button
					type="primary"
					className="ml-2"
					onClick={isEquipmentLine ? _showAddEquipment : _showAddPart}
					disabled={!isValidPRAvailable}
				>
					{`Add New ${isEquipmentLine ? 'Equipment' : 'Part'}`}
				</Button>
			</Popover>
		),
		[
			_showAddEquipment,
			_showAddPart,
			addNewPartEquipmentErrorMessage,
			isEquipmentLine,
			isValidPRAvailable,
		]
	);

	return (
		<Modal
			visible={true}
			width={'80%'}
			title={
				<div className="flex flex-row items-center justify-between">
					<div>{title}</div>
					{addNewButton}
				</div>
			}
			closable={false}
			onCancel={onCancel}
			forceRender
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button
					type="primary"
					size="large"
					className="ml-2"
					onClick={onAddLineItems}
					disabled={!selectedRecords.length}
				>
					{title}
				</Button>,
			]}
		>
			<PaginatedReduxTable
				mode="list"
				rowSelection={rowSelection}
				emptyState={
					<EmptyState
						graphic={
							<img
								alt="No valid purchase requests"
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
							/>
						}
						headline={'No valid purchase requests found'}
						body={addNewButton}
					/>
				}
				collectionName="purchase_request_line_items"
				targetCollectionName={PR_TC_NAME}
				columns={PR_LINE_ITEM_COLUMNS}
				showHeader
				keyAccessor={(el) => el.id}
				initialFilters={{
					...(stockLocationId && { stockLocationId }),
					...(locationId && { locationId }),
					partEquipmentVendorIdOrNull: partEquipmentVendorId,
					isEquipmentLine: !!isEquipmentLine,
					status: 'approved',
				}}
				fetchData={purchaseRequestLineItemsRestCrudThunksForSupplier.read}
			/>
			{addPartVisible && (
				<AddPartInPRForm
					purchaseRequests={purchaseRequests}
					allParts={[]}
					partData={{
						supplierPurchaseRequestId: nullSafeGet('0.id', purchaseRequests),
						vendorId: partEquipmentVendorId,
					}}
					onSuccess={onAddNewPartLineItem}
					onCancel={_hideAddPart}
					loading={addingNewLineItem}
				/>
			)}
			{addEquipmentVisible && (
				<AddEquipmentInPRForm
					purchaseRequests={purchaseRequests}
					allEquipments={[]}
					equipmentData={{
						supplierPurchaseRequestId: nullSafeGet('0.id', purchaseRequests),
						vendorId: partEquipmentVendorId,
					}}
					onSuccess={onAddNewEquipmentTypeLineItem}
					onCancel={_hideAddEquipment}
					loading={addingNewLineItem}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	purchaseRequestLineItems: state.purchase_request_line_items,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	fetchPRLineItems: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			purchaseRequestLineItemsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	addNewPRLineItem: (entity) => dispatch(createWithApprovedStatus(entity, PR_TC_NAME)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddLineItemsToPOModal);

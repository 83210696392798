import * as React from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../protected_route/ProtectedRoute';

export default class RouteWithSubRoutes extends React.Component<any, any> {
	constructor(props) {
		super(props);
	}

	render() {
		const r = this.props;
		return r.authProtected ? (
			<ProtectedRoute
				exact={r.exact}
				path={r.path}
				component={r.component}
				authorizedRoles={r.authorizedRoles}
				parentRoutes={r.parentRoutes ? [...r.parentRoutes, r] : [r]}
				childRoutes={r.childRoutes}
			/>
		) : (
			<Route
				exact={r.exact}
				path={r.path}
				render={(props) => (
					<r.component
						{...props}
						parentRoutes={r.parentRoutes ? [...r.parentRoutes, r] : [r]}
						childRoutes={r.childRoutes}
					/>
				)}
			/>
		);
	}
}

import { getBackendUri } from '../utils/EnvConfigUtils';
import FetchUtils, { parseJSON } from './fetch_utils';
import { logoutSuccess } from '../actions/session_actions';
import {
	fetchWorkOrdersKPIByMonthStart,
	fetchWorkOrdersKPIByMonthSuccess,
	fetchWorkOrdersKPIByMonthFailure,
	fetchCheckInComplianceKPIByMonthStart,
	fetchCheckInComplianceKPIByMonthSuccess,
	fetchCheckInComplianceKPIByMonthFailure,
	fetchTimelyInvoicingKPIByMonthFailure,
	fetchTimelyInvoicingKPIByMonthSuccess,
	fetchFirstTimeResolutionKPIByMonthFailure,
	fetchOnTimeArrivalKPIByMonthSuccess,
	fetchTimelyWorkCompletionKPIByMonthSuccess,
	fetchOnTimeArrivalKPIByMonthFailure,
	fetchFirstTimeResolutionKPIByMonthStart,
	fetchOnTimeArrivalKPIByMonthStart,
	fetchTimelyWorkCompletionKPIByMonthStart,
	fetchTimelyWorkCompletionKPIByMonthFailure,
	fetchFirstTimeResolutionKPIByMonthSuccess,
	fetchTimelyInvoicingKPIByMonthStart,
	fetchAllMonthlyKpisStart,
	fetchAllMonthlyKpisFailure,
	fetchAllMonthlyKpisSuccess,
	fetchTimeToAcceptKPIByMonthStart,
	fetchTimeToAcceptKPIByMonthSuccess,
	fetchTimeToAcceptKPIByMonthFailure,
	fetchTimeToScheduleKPIByMonthStart,
	fetchTimeToScheduleKPIByMonthSuccess,
	fetchTimeToScheduleKPIByMonthFailure,
} from '../actions/kpi_analytics_actions';

const fetchUtils = new FetchUtils();
const baseUrl = getBackendUri();
const apiUrl = (userType) => `/api/v1/${userType}/kpi/kpi_reportings`;

const fetchKPIByMonth =
	(startAction, successAction, failureAction, apiSubstring) =>
	(userType) =>
	(p, filters = null, pagination = null, sorting = null) => {
		let params = Object.assign({}, p);
		// if pagination present, transform into limit/offset format and add to query params
		const startDate = params.startDate;
		const endDate = params.endDate;
		if (!startDate || !endDate) {
			return (dispatch) => {
				return new Promise((resolve, reject) => {
					resolve([]);
				});
			};
		}

		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		let queryUri = baseUrl + apiUrl(userType) + '/' + apiSubstring;

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(startAction());

				fetchUtils
					.get(
						queryUri,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (!ok || d.status === 'error') {
							dispatch(failureAction([d.message]));
							reject(d.message);
						} else {
							dispatch(successAction(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(failureAction([d.message]));
						reject(d.message);
					});
			});
		};
	};

export const fetchWorkOrdersKPIByMonth = fetchKPIByMonth(
	fetchWorkOrdersKPIByMonthStart,
	fetchWorkOrdersKPIByMonthSuccess,
	fetchWorkOrdersKPIByMonthFailure,
	'monthly_work_order_kpis'
);
export const fetchCheckInComplianceKPIByMonth = fetchKPIByMonth(
	fetchCheckInComplianceKPIByMonthStart,
	fetchCheckInComplianceKPIByMonthSuccess,
	fetchCheckInComplianceKPIByMonthFailure,
	'monthly_check_in_kpis'
);

export const fetchTimeToAcceptKPIByMonth = fetchKPIByMonth(
	fetchTimeToAcceptKPIByMonthStart,
	fetchTimeToAcceptKPIByMonthSuccess,
	fetchTimeToAcceptKPIByMonthFailure,
	'monthly_time_to_accept_kpis'
);
export const fetchTimeToScheduleKPIByMonth = fetchKPIByMonth(
	fetchTimeToScheduleKPIByMonthStart,
	fetchTimeToScheduleKPIByMonthSuccess,
	fetchTimeToScheduleKPIByMonthFailure,
	'monthly_time_to_schedule_kpis'
);

export const fetchFirstTimeResolutionKPIByMonth = fetchKPIByMonth(
	fetchFirstTimeResolutionKPIByMonthStart,
	fetchFirstTimeResolutionKPIByMonthSuccess,
	fetchFirstTimeResolutionKPIByMonthFailure,
	'monthly_resolved_first_time_kpis'
);
export const fetchOnTimeArrivalKPIByMonth = fetchKPIByMonth(
	fetchOnTimeArrivalKPIByMonthStart,
	fetchOnTimeArrivalKPIByMonthSuccess,
	fetchOnTimeArrivalKPIByMonthFailure,
	'monthly_on_time_arrival_kpis'
);
export const fetchTimelyInvoicingKPIByMonth = fetchKPIByMonth(
	fetchTimelyInvoicingKPIByMonthStart,
	fetchTimelyInvoicingKPIByMonthSuccess,
	fetchTimelyInvoicingKPIByMonthFailure,
	'monthly_invoiced_within_30days_time_kpis'
);
export const fetchTimelyWorkCompletionKPIByMonth = fetchKPIByMonth(
	fetchTimelyWorkCompletionKPIByMonthStart,
	fetchTimelyWorkCompletionKPIByMonthSuccess,
	fetchTimelyWorkCompletionKPIByMonthFailure,
	'monthly_work_completed_before_due_kpis'
);

export const fetchAllKPIs =
	(userType) =>
	(p, filters = null, pagination = null, sorting = null) => {
		let params = Object.assign({}, p);
		// if pagination present, transform into limit/offset format and add to query params
		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		let queryUri = baseUrl + apiUrl(userType) + '/monthly_kpis';

		delete params.startDate;
		delete params.endDate;

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(fetchAllMonthlyKpisStart());

				fetchUtils
					.get(
						queryUri,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (!ok || d.status === 'error') {
							dispatch(fetchAllMonthlyKpisFailure([d.message]));
							reject(d.message);
						} else {
							dispatch(fetchAllMonthlyKpisSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(fetchAllMonthlyKpisFailure([d.message]));
						reject(d.message);
					});
			});
		};
	};

import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Layout, Button, Row, Col, InputNumber, message } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { nullSafeGetOrElse, nullSafeGet, getCurrency } from '../../utils/DataAccessUtils';
import { debounce } from '../../utils/PerformanceUtils';
import {
	proposalsRestCrudThunksForBuyer,
	proposalsRestCrudThunksForSupplier,
} from '../../thunks/proposals_thunks';
import FormDots from '../common/FormDots';
import {
	workOrdersRestCrudThunksForBuyer,
	workOrdersRestCrudThunksForSupplier,
} from '../../thunks/work_orders_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { isInternalTech } from '../../utils/AuthUtils';
import OWRichTextEditor from '../../rich_text_editor/OWRichTextEditor';

require('./ProposalForm.less');

interface ProposalFormProps extends FormComponentProps {
	formData: any;
	onSuccess: any;
	creating: boolean;
	updating: boolean;
	createErrors: string[];
	updateErrors: string[];
	redirectForwardUrl: string;
	redirectBackwardUrl: string;
	currentUser: any;
	history: any;
	createProposal: any;
	updateProposal: any;
	getWorkOrder: any;
	workOrder: any;
	userType: any;
}

class ProposalForm extends React.Component<ProposalFormProps, any> {
	state = {
		editorRef: React.createRef(),
	};

	componentDidMount() {
		const { getWorkOrder, formData } = this.props;
		getWorkOrder(formData.workOrderId).then((workOrder) => {});
	}

	getScope = () =>
		nullSafeGet('state.editorRef.current', this) &&
		(this.state.editorRef.current as any).getContent();

	handleSubmit = (e) => {
		const {
			form,
			formData,
			redirectForwardUrl,
			currentUser,
			history,
			onSuccess,
			createProposal,
			updateProposal,
			userType,
			workOrder,
		} = this.props;
		e.preventDefault();

		form.validateFields((err, values) => {
			if (!err) {
				const submitAction = values.id ? updateProposal : createProposal;

				const taxRate =
					nullSafeGetOrElse('totalBeforeTax', values, 0) > 0
						? (nullSafeGetOrElse('tax', values, 0) * 100.0) / nullSafeGet('totalBeforeTax', values)
						: 0.0;

				const supplierFacilityId =
					userType === ROLE_TYPES.BUYER || isInternalTech(currentUser)
						? nullSafeGet('supplierFacilityId', workOrder)
						: values.supplierFacilityId || nullSafeGet('facility.id', currentUser);

				const proposal = {
					id: values.id,
					scope: this.getScope(),
					status: 'pending',
					taxRate: `${taxRate}`,
					tax: `${nullSafeGetOrElse('tax', values, 0)}`,
					totalBeforeTax: `${nullSafeGetOrElse('totalBeforeTax', values, 0)}`,
					totalAfterTax: `${nullSafeGetOrElse('totalAfterTax', values, 0)}`,
					laborTotalBeforeTax: `${nullSafeGetOrElse('laborTotalBeforeTax', values, 0)}`,
					materialTotalBeforeTax: `${nullSafeGetOrElse('materialTotalBeforeTax', values, 0)}`,
					freightTotalBeforeTax: `${nullSafeGetOrElse('freightTotalBeforeTax', values, 0)}`,
					travelTotalBeforeTax: `${nullSafeGetOrElse('travelTotalBeforeTax', values, 0)}`,
					miscTotalBeforeTax: `${parseFloat(nullSafeGetOrElse('miscTotalBeforeTax', values, 0))}`,
					incurredCostTotalBeforeTax: `${nullSafeGetOrElse(
						'incurredCostTotalBeforeTax',
						values,
						0
					)}`,
					workOrderId: parseInt(formData.workOrderId, 10),
					requestForProposalId: formData.requestForProposalId
						? parseInt(formData.requestForProposalId, 10)
						: undefined,
					supplierFacilityId,
					declineNotes: formData.declineNotes,
					isBuyerUploaded: userType === ROLE_TYPES.BUYER || isInternalTech(currentUser),
				};

				submitAction(proposal)
					.then((record) => {
						if (redirectForwardUrl) {
							history.push(redirectForwardUrl);
						}
						if (onSuccess) {
							onSuccess(record);
						}
					})
					.catch((err) => {
						if (err && err.startsWith('Proposal already exists for this WorkOrder')) {
							message.error(err);
							history.goBack();
						}
					});
			}
		});
	};

	recalculateTotals = debounce(() => {
		const { form } = this.props;
		const subtotals = form.getFieldsValue([
			'laborTotalBeforeTax',
			'materialTotalBeforeTax',
			'freightTotalBeforeTax',
			'travelTotalBeforeTax',
			'miscTotalBeforeTax',
			'incurredCostTotalBeforeTax',
			'tax',
		]);
		form.setFieldsValue({
			totalBeforeTax:
				parseFloat(nullSafeGetOrElse('laborTotalBeforeTax', subtotals, 0)) +
				parseFloat(nullSafeGetOrElse('materialTotalBeforeTax', subtotals, 0)) +
				parseFloat(nullSafeGetOrElse('freightTotalBeforeTax', subtotals, 0)) +
				parseFloat(nullSafeGetOrElse('travelTotalBeforeTax', subtotals, 0)) +
				parseFloat(nullSafeGetOrElse('miscTotalBeforeTax', subtotals, 0)) +
				parseFloat(nullSafeGetOrElse('incurredCostTotalBeforeTax', subtotals, 0)),
			totalAfterTax:
				parseFloat(nullSafeGetOrElse('laborTotalBeforeTax', subtotals, 0)) +
				parseFloat(nullSafeGetOrElse('materialTotalBeforeTax', subtotals, 0)) +
				parseFloat(nullSafeGetOrElse('freightTotalBeforeTax', subtotals, 0)) +
				parseFloat(nullSafeGetOrElse('travelTotalBeforeTax', subtotals, 0)) +
				parseFloat(nullSafeGetOrElse('miscTotalBeforeTax', subtotals, 0)) +
				parseFloat(nullSafeGetOrElse('incurredCostTotalBeforeTax', subtotals, 0)) +
				parseFloat(nullSafeGetOrElse('tax', subtotals, 0)),
		});
	}, 200);

	render() {
		const {
			formData,
			creating,
			updating,
			updateErrors,
			createErrors,
			form,
			workOrder,
			currentUser,
			userType,
		} = this.props;
		const submitting = creating || updating;
		const errorMessagesText = createErrors.concat(updateErrors).join('; ');
		const isUpdate = formData.hasOwnProperty('id') && formData.id !== undefined;
		let submitText = isUpdate ? 'Update proposal' : 'Create proposal';
		const { getFieldDecorator, getFieldValue } = form;
		const currency = isUpdate
			? getCurrency({ workOrder })
			: getCurrency({ supplier: nullSafeGet('supplierFacility', workOrder) });

		getFieldDecorator('id', { initialValue: formData.id });

		getFieldDecorator(`totalBeforeTax`, {
			initialValue: nullSafeGet(`totalBeforeTax`, formData),
		});

		getFieldDecorator(`totalAfterTax`, {
			initialValue: nullSafeGet(`totalAfterTax`, formData),
		});
		const isThirdParty = () => userType === ROLE_TYPES.SUPPLIER && !isInternalTech(currentUser);
		return (
			<Layout.Content>
				<Row className="supplierProposalForm" gutter={16}>
					<Col span={24}>
						<Form hideRequiredMark={true} layout="vertical">
							{createErrors.length > 0 || updateErrors.length > 0 ? (
								<Form.Item label="">
									<Alert message={errorMessagesText} type="error" />
								</Form.Item>
							) : null}
							<Row>
								{isUpdate ? (
									formData.status === 'awarded' ? (
										<Alert
											style={{
												flex: 1,
												borderWidth: 0,
												marginBottom: 8,
											}}
											type={'warning'}
											showIcon={true}
											message={
												<span style={{ paddingLeft: '8px' }}>
													If you edit an approved quote, it will have to go back through the
													approval process.
												</span>
											}
										/>
									) : null
								) : isThirdParty ? (
									<Alert
										style={{
											flex: 1,
											borderWidth: 0,
											marginBottom: 8,
											backgroundColor: '#ebfbff',
										}}
										type={'info'}
										showIcon={true}
										message={
											<span style={{ paddingLeft: '8px' }}>
												Want to submit a quote in another currency?{' '}
												<a href={'/supplier/company/me'} target={'_blank'}>
													Click here
												</a>{' '}
												to change your company currency.
											</span>
										}
									/>
								) : null}
							</Row>
							<div
								className="supplierProposalForm__sectionHead"
								style={{ marginBottom: 32, flexDirection: 'column', alignItems: 'start' }}
							>
								<div className="supplierProposalForm__sectionHeadPrimary">Scope</div>
								{getFieldDecorator('scope', {
									initialValue: nullSafeGet(`scope`, formData),
								})(
									<OWRichTextEditor
										initialEditorState={nullSafeGet(`scope`, formData)}
										editorRef={this.state.editorRef}
									/>
								)}
							</div>
							<div style={{ marginBottom: 32 }}>
								<div className="supplierProposalForm__sectionHead">
									<div className="supplierProposalForm__sectionHeadPrimary">Incurred Costs</div>
									<FormDots />
									<div className="supplierProposalForm__sectionHeadSecondary">
										{getFieldDecorator(`incurredCostTotalBeforeTax`, {
											validateTrigger: ['onBlur'],
											initialValue: nullSafeGet(`incurredCostTotalBeforeTax`, formData),
										})(
											<InputNumber
												step={1}
												onChange={this.recalculateTotals}
												style={{ width: 240 }}
												addonAfter={currency.id}
											/>
										)}
									</div>
								</div>
							</div>
							<div style={{ marginBottom: 32 }}>
								<div className="supplierProposalForm__sectionHead">
									<div className="supplierProposalForm__sectionHeadPrimary">Labor</div>
									<FormDots />
									<div className="supplierProposalForm__sectionHeadSecondary">
										{getFieldDecorator(`laborTotalBeforeTax`, {
											validateTrigger: ['onBlur'],
											initialValue: nullSafeGet(`laborTotalBeforeTax`, formData),
										})(
											<InputNumber
												step={1}
												onChange={this.recalculateTotals}
												style={{ width: 240 }}
												addonAfter={currency.id}
											/>
										)}
									</div>
								</div>
							</div>
							<div style={{ marginBottom: 32 }}>
								<div className="supplierProposalForm__sectionHead">
									<div className="supplierProposalForm__sectionHeadPrimary">Materials</div>
									<FormDots />
									<div className="supplierProposalForm__sectionHeadSecondary">
										{getFieldDecorator(`materialTotalBeforeTax`, {
											validateTrigger: ['onBlur'],
											initialValue: nullSafeGet(`materialTotalBeforeTax`, formData),
										})(
											<InputNumber
												step={1}
												onChange={this.recalculateTotals}
												style={{ width: 240 }}
												addonAfter={currency.id}
											/>
										)}
									</div>
								</div>
							</div>
							<div style={{ marginBottom: 32 }}>
								<div className="supplierProposalForm__sectionHead">
									<div className="supplierProposalForm__sectionHeadPrimary">Travel</div>
									<FormDots />
									<div className="supplierProposalForm__sectionHeadSecondary">
										{getFieldDecorator(`travelTotalBeforeTax`, {
											validateTrigger: ['onBlur'],
											initialValue: nullSafeGet(`travelTotalBeforeTax`, formData),
										})(
											<InputNumber
												step={1}
												onChange={this.recalculateTotals}
												style={{ width: 240 }}
												addonAfter={currency.id}
											/>
										)}
									</div>
								</div>
							</div>
							<div style={{ marginBottom: 32 }}>
								<div className="supplierProposalForm__sectionHead">
									<div className="supplierProposalForm__sectionHeadPrimary">Freight</div>
									<FormDots />
									<div className="supplierProposalForm__sectionHeadSecondary">
										{getFieldDecorator(`freightTotalBeforeTax`, {
											validateTrigger: ['onBlur'],
											initialValue: nullSafeGet(`freightTotalBeforeTax`, formData),
										})(
											<InputNumber
												step={1}
												onChange={this.recalculateTotals}
												style={{ width: 240 }}
												addonAfter={currency.id}
											/>
										)}
									</div>
								</div>
							</div>
							<div style={{ marginBottom: 32 }}>
								<div className="supplierProposalForm__sectionHead">
									<div className="supplierProposalForm__sectionHeadPrimary">Miscellaneous</div>
									<FormDots />
									<div className="supplierProposalForm__sectionHeadSecondary">
										{getFieldDecorator(`miscTotalBeforeTax`, {
											validateTrigger: ['onBlur'],
											initialValue: nullSafeGet(`miscTotalBeforeTax`, formData),
										})(
											<InputNumber
												step={1}
												onChange={this.recalculateTotals}
												style={{ width: 240 }}
												addonAfter={currency.id}
											/>
										)}
									</div>
								</div>
							</div>
							<div style={{ marginBottom: 32 }}>
								<div className="supplierProposalForm__sectionHead">
									<div className="supplierProposalForm__sectionHeadPrimary">Total Before Tax</div>
									<FormDots />
									<div className="supplierProposalForm__sectionHeadSecondary">
										{currency.format(getFieldValue('totalBeforeTax'))}
									</div>
								</div>
							</div>
							<div style={{ marginBottom: 32 }}>
								<div className="supplierProposalForm__sectionHead">
									<div className="supplierProposalForm__sectionHeadPrimary">Tax</div>
									<FormDots />
									<div className="supplierProposalForm__sectionHeadSecondary">
										{getFieldDecorator(`tax`, {
											validateTrigger: ['onBlur'],
											initialValue: nullSafeGet(`tax`, formData),
										})(
											<InputNumber
												step={1}
												onChange={this.recalculateTotals}
												style={{ width: 240 }}
												addonAfter={currency.id}
											/>
										)}
									</div>
								</div>
							</div>
							<div style={{ marginBottom: 32 }}>
								<div className="supplierProposalForm__sectionHead">
									<div className="supplierProposalForm__sectionHeadPrimary">Total With Tax</div>
									<FormDots />
									<div className="supplierProposalForm__sectionHeadSecondary">
										{currency.format(getFieldValue('totalAfterTax'))}
									</div>
								</div>
							</div>
							<Form.Item label="">
								<Button
									type={'primary'}
									onClick={(e) => {
										this.handleSubmit(e);
									}}
									loading={submitting}
								>
									{submitText}
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Layout.Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	formData: ownProps.formData,
	onSuccess: ownProps.onSuccess,
	updating: state.proposals.updating,
	creating: state.proposals.creating,
	createErrors: state.proposals.createErrors,
	updateErrors: state.proposals.updateErrors,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	redirectBackwardUrl: ownProps.redirectBackwardUrl,
	currentUser: state.session.currentUser,
	history: ownProps.history,
	workOrder: state.work_orders.detail,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getWorkOrder: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? workOrdersRestCrudThunksForSupplier.readOne(id)
				: workOrdersRestCrudThunksForBuyer.readOne(id)
		),
	createProposal: (proposal) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? proposalsRestCrudThunksForSupplier.create(proposal)
				: proposalsRestCrudThunksForBuyer.create(proposal)
		),
	updateProposal: (proposal) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? proposalsRestCrudThunksForSupplier.update(proposal)
				: proposalsRestCrudThunksForBuyer.update(proposal)
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ProposalForm)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(Form.create<ProposalFormProps>()(ComponentWithoutUserType));

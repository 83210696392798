import { Form, InputNumber, Modal, Select, message } from 'antd';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { partsRestCrudThunksForSupplier } from '../../thunks/parts_thunks';
import {
	getCurrency,
	getObjectValues,
	getPurchaseRequestId,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import { partCatalogsRestCrudThunksForSupplier } from '../../thunks/part_catalogs_thunks';
import ModalFormFooter from '../common/ModalFormFooter';
import { vendorsRestCrudThunksForSupplier } from '../../thunks/vendors_thunks';
import { unitsOfMeasureRestCrudThunksForSupplier } from '../../thunks/units_of_measure_thunks';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { v4 as uuiv4 } from 'uuid';
import PartEquipmentCostFormField from '../common/PartEquipmentCostFormField';
import { PERMISSION_NAMES, isSupplierAllowedToAccess } from '../../utils/AuthUtils';

const FORM_ID = 'add-part-form';
const PART_VENDORS_TC_NAME = 'PART_VENDORS_AUTOCOMPLETE';
const PARTS_TC_NAME = 'stockLocationAssociatedParts';

const AddPartInPRFrom: FC<any> = ({
	onCancel,
	parts,
	fetchPart,
	fetchParts,
	fetchMultipleParts,
	fetchPartsCatalog,
	fetchVendors,
	fetchMultipleVendors,
	createVendor,
	createPartsPerCatalog,
	currentUser,
	fetchUnitsOfMeasure,
	unitsOfMeasure,
	createPart,
	onSuccess,
	allParts,
	partData,
	vendors,
	purchaseRequests,
	loading,
	companyConfig,
}): React.ReactElement => {
	const [form] = Form.useForm();
	const [addingPart, setAddingPart] = useState(false);
	const userCurrencyId = nullSafeGet('currencyId', getCurrency({ currentUser }));

	const { setFieldsValue } = form;

	const partId = Form.useWatch('partId', form) || nullSafeGet('partId', partData);
	const vendorId = Form.useWatch('vendorId', form) || nullSafeGet('vendorId', partData);
	const unitCost = Form.useWatch('unitCost', form) || nullSafeGet('unitCost', partData);
	const quantity = Form.useWatch('quantity', form) || nullSafeGet('quantity', partData);

	const currencyId = useMemo(() => {
		const vendorRecords = getRecordsForTargetCollection(vendors, PART_VENDORS_TC_NAME);
		return nullSafeGetOrElse(
			'currencyId',
			vendorRecords.find((_) => _.id === vendorId),
			userCurrencyId
		);
	}, [userCurrencyId, vendorId, vendors]);

	const partRecord = useMemo(() => {
		const partsRecords = getRecordsForTargetCollection(parts, PARTS_TC_NAME);
		return partsRecords.find((_) => _.id === partId) || nullSafeGet('part', partData);
	}, [partData, partId, parts]);

	useEffect(() => {
		nullSafeGet('defaultVendorId', partRecord) &&
			!vendorId &&
			setFieldsValue({ vendorId: nullSafeGet('defaultVendorId', partRecord) });
	}, [partRecord, setFieldsValue, vendorId]);

	useEffect(() => {
		setFieldsValue({
			cost: (unitCost || 0) * (quantity || 0),
		});
	}, [unitCost, quantity, setFieldsValue]);

	useEffect(() => {
		fetchUnitsOfMeasure();
	}, [fetchUnitsOfMeasure]);

	const supplierCompanyId = useMemo(
		() => nullSafeGet('facility.supplierCompanyId', currentUser),
		[currentUser]
	);
	const supplierFacilityId = useMemo(() => nullSafeGet('facility.id', currentUser), [currentUser]);

	const defaultUnitOfMeasureId = useMemo(() => {
		const unitsOfMeasureRecords =
			unitsOfMeasure && unitsOfMeasure.records ? getObjectValues(unitsOfMeasure.records) : [];
		const eachUnit = unitsOfMeasureRecords.find((_) => _.displayNameSingular === 'each');
		return nullSafeGetOrElse('id', eachUnit, 1);
	}, [unitsOfMeasure]);

	const getPartCatalogFromVendorId = useCallback(
		(vendorId) => {
			return new Promise((resolve, reject) => {
				if (vendorId) {
					fetchPartsCatalog({
						partId,
						partEquipmentVendorId: vendorId,
					})
						.then((res) => {
							if (res && res.length > 0) {
								resolve(res[0]);
							} else {
								createPartsPerCatalog({
									partEquipmentVendorId: vendorId,
									currencyId: 'USD',
									unitCost: unitCost ? `${unitCost}` : undefined,
									partId,
									supplierFacilityId,
									supplierCompanyId,
								})
									.then((ppc) => resolve(ppc))
									.catch((err) => reject(err));
							}
						})
						.catch((err) =>
							createPartsPerCatalog({
								partEquipmentVendorId: vendorId,
								currencyId: 'USD',
								unitCost,
								partId,
								supplierFacilityId,
								supplierCompanyId,
							})
								.then((ppc) => resolve(ppc))
								.catch((err) => reject(err))
						);
				} else resolve(null);
			});
		},
		[
			partId,
			unitCost,
			fetchPartsCatalog,
			createPartsPerCatalog,
			supplierCompanyId,
			supplierFacilityId,
		]
	);

	const getPartRecord = useCallback((): any => {
		return new Promise((resolve, reject) => {
			partRecord
				? resolve(partRecord)
				: fetchPart(partId)
						.then((res) => resolve(res))
						.catch((err) => reject(err));
		});
	}, [partRecord, fetchPart, partId]);

	const isEditingPart = useMemo(
		() => partData && allParts.find((_) => _.id === partData.id),
		[partData, allParts]
	);

	const handleSubmit = useCallback(
		(values) => {
			setAddingPart(true);
			Promise.all([getPartCatalogFromVendorId(values.vendorId), getPartRecord()])
				.then(([partCatalog, part]) => {
					const newRecord = {
						...values,
						part,
						...(partCatalog && { partCatalog }),
						...(partCatalog && { partCatalogId: (partCatalog as any).id }),
					};

					const newParts = isEditingPart
						? allParts.map((_) => (_.id === partData.id ? newRecord : _))
						: [...allParts, ...[{ ...newRecord, id: uuiv4() }]];

					onSuccess && onSuccess(newParts);
				})
				.catch((err) => message.error(err))
				.finally(() => setAddingPart(false));
		},
		[getPartCatalogFromVendorId, getPartRecord, isEditingPart, allParts, onSuccess, partData]
	);

	const prSelectionExist = useMemo(() => !!nullSafeGet('0', purchaseRequests), [purchaseRequests]);

	const canCreatePartOrEquipment = useMemo(
		() =>
			isSupplierAllowedToAccess(
				PERMISSION_NAMES.MODIFY_PARTS_AND_EQUIPMENTS,
				companyConfig,
				currentUser.roles
			),
		[companyConfig, currentUser.roles]
	);

	const canCreateVendors = isSupplierAllowedToAccess(
		PERMISSION_NAMES.MODIFY_VENDORS,
		companyConfig,
		currentUser.roles
	);

	return (
		<Modal
			visible={true}
			title={'Add Part to Purchase Request'}
			onCancel={onCancel}
			bodyStyle={{ height: '480px' }}
			footer={null}
			closable={true}
		>
			<Form
				layout="vertical"
				onFinish={handleSubmit}
				initialValues={partData || {}}
				id={FORM_ID}
				form={form}
			>
				{prSelectionExist ? (
					<Form.Item
						name="supplierPurchaseRequestId"
						label="Purchase Request"
						rules={[
							{
								required: true,
								message: 'Please select a purchase request.',
							},
						]}
					>
						<Select disabled={purchaseRequests.length === 1}>
							{purchaseRequests.map((pr) => (
								<Select.Option value={pr.id} key={pr.id}>
									<div>{`${getPurchaseRequestId(pr)} - ${pr.name}`}</div>
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				) : null}
				<Form.Item
					name="partId"
					label="Part"
					rules={[
						{
							required: true,
							message: 'Please select a part.',
						},
					]}
				>
					<OWAsyncSelect
						stateSlice={parts}
						targetCollectionName={PARTS_TC_NAME}
						fetchMultiple={(ids, targetCollectionName) => {
							fetchMultipleParts(ids, targetCollectionName);
						}}
						fetchData={(
							search,
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						) => {
							fetchParts(
								{ search },
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							);
						}}
						createNewEntity={
							canCreatePartOrEquipment
								? (name) =>
										createPart({
											name,
											uomId: defaultUnitOfMeasureId,
											supplierCompanyId,
											supplierFacilityId,
											images: [],
										})
								: undefined
						}
						renderRecord={(part) => (
							<Select.Option key={part.id} value={part.id}>
								<div className="space-between flex flex-row items-center">
									<div>{part.name}</div>
									<div>
										{part.partNumber && (
											<div className="text-gray-400">&nbsp;{` - #${part.partNumber}`}</div>
										)}
									</div>
								</div>
							</Select.Option>
						)}
						sortBy={{ sort_by: 'name', order: 'ascend' }}
					/>
				</Form.Item>
				<Form.Item
					name="quantity"
					label="Quantity"
					rules={[
						{
							required: true,
							message: 'Please add quantity.',
						},
					]}
				>
					<InputNumber style={{ width: '100%' }} />
				</Form.Item>
				<Form.Item name="vendorId" label="Vendor">
					<OWAsyncSelect
						disabled={prSelectionExist}
						stateSlice={vendors}
						valueAccessor={(el) => el.id}
						targetCollectionName={PART_VENDORS_TC_NAME}
						createNewEntity={
							canCreateVendors ? (name) => createVendor({ name, currencyId: 'USD' }) : undefined
						}
						fetchData={(searchText, targetCollectionName) =>
							fetchVendors(
								{
									name: searchText,
								},
								targetCollectionName
							)
						}
						fetchMultiple={(ids, targetCollectionName) => {
							fetchMultipleVendors(ids, targetCollectionName);
						}}
						placeholder="Select Vendor"
						renderRecord={(vendor) => (
							<Select.Option key={vendor.id} value={vendor.id}>
								{vendor.name}
							</Select.Option>
						)}
					/>
				</Form.Item>
				<PartEquipmentCostFormField
					entityId={partId}
					unitCost={unitCost}
					entityName="partId"
					vendorId={vendorId}
					fetchMethod={fetchPartsCatalog}
					setFieldsValue={setFieldsValue}
					form={form}
					currencyId={currencyId}
				/>
				{quantity && unitCost && (
					<Form.Item name="cost" label="Total Cost">
						<InputNumber disabled step={0.01} style={{ width: '100%' }} addonAfter={currencyId} />
					</Form.Item>
				)}
				<ModalFormFooter
					okText={isEditingPart ? 'Save Changes' : 'Add Part'}
					cancelText="Cancel"
					loading={addingPart || loading}
					formId={FORM_ID}
					onCancelClick={onCancel}
				/>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	parts: state.parts,
	currentUser: state.session.currentUser,
	unitsOfMeasure: state.units_of_measure,
	vendors: state.vendors,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	createPart: (entity) => dispatch(partsRestCrudThunksForSupplier.create(entity)),
	updatePart: (entity) => dispatch(partsRestCrudThunksForSupplier.update(entity)),
	fetchPart: (id) => dispatch(partCatalogsRestCrudThunksForSupplier.readOne(id)),
	fetchParts: (params, targetCollectionName, pagination, sorting, filters, addToTargetCollection) =>
		dispatch(
			partsRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleParts: (ids, targetCollectionName) =>
		dispatch(partsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchVendors: (params, targetCollectionName) =>
		dispatch(
			vendorsRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName
			)
		),
	fetchMultipleVendors: (ids, targetCollectionName) =>
		dispatch(vendorsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchPartsCatalog: (params, targetCollectionName) =>
		dispatch(partCatalogsRestCrudThunksForSupplier.read(params, targetCollectionName)),
	createVendor: (entity) => dispatch(vendorsRestCrudThunksForSupplier.create(entity)),
	createPartsPerCatalog: (entity, targetCollectionName) =>
		dispatch(partCatalogsRestCrudThunksForSupplier.create(entity, 'id', targetCollectionName)),
	fetchUnitsOfMeasure: (params) => dispatch(unitsOfMeasureRestCrudThunksForSupplier.read(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPartInPRFrom);

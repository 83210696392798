import { getBackendUri } from '../utils/EnvConfigUtils';
import FetchUtils, { parseJSON } from './fetch_utils';

import {
	fetchBuyerAndSupplierUsers,
	fetchBuyerAndSupplierUsersSuccess,
	fetchBuyerAndSupplierUsersFailure,
} from '../actions/work_orders_subscribers_actions';
import { WORK_ORDERS_CRUD_ACTION_CREATORS } from '../actions/work_orders_actions';
import { logoutSuccess } from '../actions/session_actions';

const fetchUtils = new FetchUtils();
const BASE_URL = getBackendUri();

export const fetchAllUsersForBuyers =
	(search, woId, params = {}) =>
	() => {
		let fetchUsers = fetchBuyerAndSupplierUsers;
		let fetchUsersSuccess = fetchBuyerAndSupplierUsersSuccess;
		let fetchUsersFailure = fetchBuyerAndSupplierUsersFailure;

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(fetchUsers());

				fetchUtils
					.get(
						BASE_URL + `/api/v1/buyer/work_order/work_orders/subscribers/find_subscriber/${woId}`,
						{ search, ...params }
					)
					.then((response) => response.json())
					.then((d) => {
						if (d.status === 'error') {
							dispatch(fetchUsersFailure([d.message]));
							reject(d.message);
						} else {
							dispatch(fetchUsersSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(fetchUsersFailure(d.message));
						reject(d.message);
					});
			});
		};
	};
export const fetchAllUsersForSuppliers =
	(search, woId, params = {}) =>
	() => {
		let fetchUsers = fetchBuyerAndSupplierUsers;
		let fetchUsersSuccess = fetchBuyerAndSupplierUsersSuccess;
		let fetchUsersFailure = fetchBuyerAndSupplierUsersFailure;

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(fetchUsers());

				fetchUtils
					.get(
						BASE_URL +
							`/api/v1/supplier/work_order/work_orders/subscribers/find_subscriber/${woId}`,
						{ search, ...params }
					)
					.then((response) => response.json())
					.then((d) => {
						if (d.status === 'error') {
							dispatch(fetchUsersFailure([d.message]));
							reject(d.message);
						} else {
							dispatch(fetchUsersSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(fetchUsersFailure(d.message));
						reject(d.message);
					});
			});
		};
	};

//For a Buyer to add a subscriber who is a Buyer
export const addBuyerSubscribersForBuyers =
	(users, params = {}) =>
	(entity) => {
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));

				fetchUtils
					.post(
						BASE_URL + `/api/v1/buyer/work_order/work_orders/subscribers/add_buyer_subscribers`,
						{
							headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
							data: users,
						}
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};
//For a Buyer to add a subscriber who is a Supplier
export const addSupplierSubscribersForBuyers =
	(obj, params = {}) =>
	(entity) => {
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));
				fetchUtils
					.post(
						BASE_URL + `/api/v1/buyer/work_order/work_orders/subscribers/add_supplier_subscribers`,
						{
							headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
							data: obj,
						}
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};
//For a Supplier to add a subscriber who is a Buyer
export const addBuyerSubscribersForSuppliers =
	(users, params = {}) =>
	(entity) => {
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));

				fetchUtils
					.post(
						BASE_URL + `/api/v1/supplier/work_order/work_orders/subscribers/add_buyer_subscribers`,
						{
							headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
							data: users,
						}
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};
//For a Supplier to add a subscriber who is a Supplier
export const addSupplierSubscribersForSuppliers =
	(obj, params = {}) =>
	(entity) => {
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));
				fetchUtils
					.post(
						BASE_URL +
							`/api/v1/supplier/work_order/work_orders/subscribers/add_supplier_subscribers`,
						{
							headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
							data: obj,
						}
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};

//**** Remove Subscribers Section ****

//Remove Buyer Subscribers for Buyer
export const removeBuyerSubscriberForBuyers =
	(obj, params = {}) =>
	(entity) => {
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));
				fetchUtils
					.post(
						BASE_URL + `/api/v1/buyer/work_order/work_orders/subscribers/remove_buyer_subscribers`,
						{
							headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
							data: obj,
						}
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};

//Remove Supplier Subscriber for Suppliers
export const removeSupplierSubscriberForBuyers =
	(obj, params = {}) =>
	(entity) => {
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));
				fetchUtils
					.post(
						BASE_URL +
							`/api/v1/buyer/work_order/work_orders/subscribers/remove_supplier_subscribers`,
						{
							headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
							data: obj,
						}
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};

//Remove Supplier Subscriber for Suppliers
export const removeSupplierSubscriberForSuppliers =
	(obj, params = {}) =>
	(entity) => {
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateStart(entity));
				fetchUtils
					.post(
						BASE_URL +
							`/api/v1/supplier/work_order/work_orders/subscribers/remove_supplier_subscribers`,
						{
							headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
							data: obj,
						}
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};

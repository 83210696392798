import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Alert, DatePicker, Checkbox } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { connect } from 'react-redux';
import moment from 'moment';
import { nullSafeGet } from '../../utils/DataAccessUtils';

const FormItem = Form.Item;
export const DATE_FORMAT = 'MMMM D, YYYY h:mm a';

interface MeterReadingModalFormProps extends FormComponentProps {
	isEdit: any;
	onSubmit: any;
	onCancel: any;
	meter: any;
	createErrors: any;
	updateErrors: any;
	currentUser: any;
	visible: boolean;
	formData: any;
}

const disabledDate = (current) => {
	// Can not select days before today and today
	return current > moment().endOf('day');
};

class MeterReadingModalForm extends React.Component<MeterReadingModalFormProps, any> {
	render() {
		const {
			isEdit,
			visible,
			onCancel,
			onSubmit,
			currentUser,
			meter,
			form,
			formData,
			createErrors,
			updateErrors,
		} = this.props;
		const { getFieldDecorator } = form;
		const okText = isEdit ? 'Edit Reading' : 'Add Reading';
		const titleText = isEdit ? 'Edit Reading' : 'Add Reading';

		const dateFormat = 'dddd, MMM D, YYYY, h:mm A';

		getFieldDecorator(`id`, { initialValue: formData.id });
		getFieldDecorator(`meterId`, { initialValue: formData.meterId });
		getFieldDecorator(`recordedAt`, { initialValue: formData.recordedAt || new Date() });
		getFieldDecorator(`recordedBy`, { initialValue: formData.recordedBy || currentUser.email });
		getFieldDecorator(`buyerCompanyId`, {
			initialValue: formData.buyerCompanyId || nullSafeGet('facility.buyerCompanyId', currentUser),
		});

		// const getInputElement = (valueType) => {
		//     switch (valueType) {
		//         case "text":
		//             return <Input style={{width: 400}} />;
		//         case "number":
		//             return <InputNumber style={{width: 400}} />;
		//         case "date":
		//             return <DatePicker/>;
		//         default:
		//             return <Input/>;
		//     }
		// };

		return (
			<Modal
				visible={visible}
				width={600}
				title={titleText}
				okText={okText}
				onCancel={onCancel}
				onOk={onSubmit}
				closable={false}
			>
				<Form layout="vertical" className="supplierForm">
					{createErrors.length > 0 ? (
						<FormItem>
							<Alert message={createErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					{updateErrors.length > 0 ? (
						<FormItem>
							<Alert message={updateErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					<FormItem>
						{getFieldDecorator(`value`, { initialValue: formData.value })(
							<Input placeholder={'Current Value'} />
						)}
					</FormItem>
					{meter.seriesType === 'comparable' ? (
						<FormItem label={'Comparison Value'}>
							{getFieldDecorator(`expectedValue`, {
								initialValue: formData.expectedValue || meter.defaultExpectedValue,
							})(<Input />)}
						</FormItem>
					) : null}
					{meter.valueType === 'number' && meter.seriesType === 'baseline' ? (
						<FormItem>
							{getFieldDecorator(`isBaseline`, {
								initialValue: formData.isBaseline,
								valuePropName: 'checked',
							})(<Checkbox>Is value new baseline?</Checkbox>)}
						</FormItem>
					) : null}
					<FormItem label={'Recorded at'}>
						{getFieldDecorator('recordedAt', {
							initialValue: formData.recordedAt ? moment(formData.recordedAt) : moment(new Date()),
							rules: [{ required: true, message: 'This field is required.' }],
						})(<DatePicker style={{ width: 300 }} format={dateFormat} showTime={true} />)}
					</FormItem>
				</Form>
			</Modal>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	isEdit: ownProps.isEdit,
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	currentUser: state.session.currentUser,
	createErrors: state.meters.createErrors,
	updateErrors: state.meters.updateErrors,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create<MeterReadingModalFormProps>()(MeterReadingModalForm));

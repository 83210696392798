import { Button, Card } from 'antd';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import AssetStatusForm from './AssetStatusForm';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';

export default function AssetTile({ asset, downTimeHistory, userType, onSuccess, hideEditButton }) {
	const [assetStatusChangeModalVisible, setAssetStatusChangeModalVisible] = React.useState(false);
	const [downTimeObj, setDownTimeObj] = React.useState<any>({});

	React.useEffect(() => {
		if (downTimeHistory.length > 0) {
			setDownTimeObj(
				downTimeHistory.find((item) => !item.endTime && item.reason !== 'falsePositive') || {}
			);
		}
	}, [downTimeHistory]);

	const sortedHistory = downTimeHistory
		.filter((history) => history.reason !== 'falsePositive')
		.sort((a, b) => a.id - b.id);
	const lastEntry = sortedHistory[sortedHistory.length - 1];

	return (
		<>
			<AssetStatusForm
				downTimeHistory={downTimeHistory}
				asset={asset}
				onCancel={() => setAssetStatusChangeModalVisible(false)}
				onSuccess={() => {
					setAssetStatusChangeModalVisible(false);
					onSuccess();
				}}
				visible={assetStatusChangeModalVisible}
			/>
			<Card
				extra={
					hideEditButton ? null : (
						<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_ASSETS}>
							<Button
								type="link"
								onClick={() => {
									setAssetStatusChangeModalVisible(true);
								}}
							>
								Edit
							</Button>
						</AccessPermissionChecker>
					)
				}
			>
				<div
					className="mt-4 text-center text-7xl font-semibold"
					style={{ color: asset.downtimeStatus === 'offline' ? 'red' : 'green' }}
				>
					{asset.downtimeStatus === 'offline' ? 'Offline' : 'Online'}
					<div className="text-base font-normal text-gray-500">
						{asset.downtimeStatus === 'offline'
							? downTimeObj.startTime
								? `for ${moment(downTimeObj.startTime).fromNow(true)}`
								: null
							: lastEntry
							? `for ${moment(lastEntry.endTime).fromNow(true)} `
							: null}
					</div>
				</div>
				<Link to={`/${userType}/assets/detail/${asset.id}/downtimeHistory`}>
					<div className="mt-8 text-center text-violet-700">More</div>
				</Link>
			</Card>
		</>
	);
}

import { Button, Form, message, Modal, Popover } from 'antd';
import * as React from 'react';
import { getObjectValues, nullSafeGet } from '../../utils/DataAccessUtils';
import SelectUsers from '../select_users/SelectUsers';
import {
	fetchAllUsersForBuyers,
	fetchAllUsersForSuppliers,
} from '../../thunks/work_orders_subscribers_thunks';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { useState } from 'react';
import { debounce } from '../../utils/PerformanceUtils';
import { ROLE_TYPES } from '../../utils/DataConstants';

function ForwardApproval({
	workOrder,
	entity,
	fetchUsers,
	searched_subscribers,
	buttonLoading,
	disabled = false,
	reason,

	refresh,
	forwardApprovalToUser,
}) {
	const [form] = Form.useForm();
	const [search, setSearch] = useState('');
	const [selectValue, setSelectValue] = useState([]);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [forwardApprovalVisible, setForwardApprovalVisible] = useState(false);

	React.useMemo(() => {
		if (forwardApprovalVisible) setSelectValue(undefined);
	}, [forwardApprovalVisible]);

	const onSubmit = () => {
		form.validateFields().then((values) => {
			const approver = JSON.parse(nullSafeGet('approver', values));
			message.open({
				key: 'MessageUpdating',
				type: 'loading',
				content: 'Forwarding...',
				duration: 10,
			});
			forwardApprovalToUser(entity, nullSafeGet('email', approver)).then((result) => {
				refresh();
				message.open({
					key: 'MessageUpdating',
					type: 'success',
					content: 'Complete!',
					duration: 2,
				});
			});
			setForwardApprovalVisible(false);
		});
	};
	const debouncedCallback = debounce(
		(searchText) => {
			fetchUsers(searchText && searchText.trim(), nullSafeGet('id', workOrder));
		},
		400,
		false
	);
	const handleAddApproverSearchDebounced = (value = '') => {
		const searchText = value && value.trim();
		setSearch(searchText);
		debouncedCallback(searchText);
	};
	const handleOnChange = (val) => {
		if (val) {
			let updatedVal = JSON.parse(val);
			setSelectValue(val);
			setSelectedUsers(updatedVal);
		} else {
			setSelectValue([]);
			setSelectedUsers([]);
		}
	};

	const filteredUsers = getObjectValues(searched_subscribers.records).map((el) => ({
		...el,
		key: el.email,
	}));

	return (
		<span>
			{disabled ? (
				<Popover
					title={`Why can't I forward for approval?`}
					content={nullSafeGet('current', reason)}
				>
					<Button
						loading={buttonLoading}
						style={{ marginRight: 16 }}
						type={'default'}
						disabled={true}
						onClick={() => {
							setForwardApprovalVisible(true);
						}}
					>
						<span>Forward for Approval</span>
					</Button>
				</Popover>
			) : (
				<Button
					loading={buttonLoading}
					style={{ marginRight: 16 }}
					type={'default'}
					onClick={() => {
						setForwardApprovalVisible(true);
					}}
				>
					<span>Forward for Approval</span>
				</Button>
			)}
			{forwardApprovalVisible ? (
				<Modal
					visible={true}
					width={600}
					title={'Forward Approval To'}
					closable={true}
					maskClosable={true}
					bodyStyle={{ maxHeight: '500px' }}
					onCancel={() => {
						setForwardApprovalVisible(false);
					}}
					onOk={form.submit}
					okText={'Forward'}
				>
					<Form form={form} preserve={false} initialValues={{}} onFinish={onSubmit}>
						<Form.Item
							name={'approver'}
							rules={[
								{ required: true, message: 'Please select a user to forward the approval to.' },
							]}
						>
							<SelectUsers
								mode={'default'}
								style={{ width: '100%' }}
								placeholder="Begin to type a name or email to start searching"
								onSearch={handleAddApproverSearchDebounced}
								onFocus={() => handleAddApproverSearchDebounced()}
								onChange={handleOnChange}
								search={search}
								selectValue={selectValue}
								filteredUsers={filteredUsers}
							/>
						</Form.Item>
					</Form>
				</Modal>
			) : null}
		</span>
	);
}
const mapStateToProps = (state, ownProps) => ({
	searched_subscribers: state.searched_subscribers,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchUsers: (search, woId, params) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? fetchAllUsersForSuppliers(search, woId, params)()
				: fetchAllUsersForBuyers(search, woId, params)()
		),
});
const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ForwardApproval)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

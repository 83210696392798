import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Popconfirm, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	requestForProposalStatusChangeForBuyer,
	requestForProposalStatusChangeForSupplier,
	requestsForProposalRestCrudThunksForBuyer,
	requestsForProposalRestCrudThunksForSupplier,
} from '../../thunks/requests_for_proposal_thunks';
import { Link } from 'react-router-dom';
import {
	requestForProposalStatusIndicatorFormatter,
	timeUntilOrSince,
} from '../../utils/DataFormatterUtils';
import moment from 'moment';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { ROLE_TYPES } from '../../utils/DataConstants';
import DateTimeHover from '../date_time_component/DateTime';

const RFPDetailPage: FC<any> = ({
	getRequestForProposal,
	match,
	history,
	deleteRequestForProposal,
	requestForProposal,
	publishRequestForProposal,
	closeRequestForProposal,
	requestsForProposalFetching,
	childRoutes,
	companyConfig,
	userType,
	location,
}): React.ReactElement => {
	const [popConfirmVisible, setPopConfirmVisible] = useState(false);

	const popConfirmToggle = useCallback(() => setPopConfirmVisible((val) => !val), []);

	useEffect(() => setPopConfirmVisible(true), []);

	useEffect(() => {
		const requestForProposalId = match.params.id;
		getRequestForProposal(requestForProposalId);
	}, [getRequestForProposal, match.params.id]);

	const handleTabChange = useCallback(
		(key) => {
			const requestForProposalId = parseInt(match.params.id, 10);
			history.push(`/${userType}/requestsForProposal/detail/${requestForProposalId}/${key}`);
		},
		[history, match.params.id, userType]
	);

	const handleDelete = useCallback(() => {
		deleteRequestForProposal(requestForProposal).then(() => {
			history.push(`/${userType}/requestsForProposal/overview/all`);
		});
	}, [deleteRequestForProposal, history, requestForProposal, userType]);

	const checkTabActive = useCallback(
		(key) => {
			const pathname = history.location.pathname;
			return pathname.indexOf(key) !== -1;
		},
		[history.location.pathname]
	);

	const publishEvent = useCallback(() => {
		publishRequestForProposal(requestForProposal).then(() =>
			message.success('RFP has been published.')
		);
	}, [publishRequestForProposal, requestForProposal]);

	const endEvent = useCallback(() => {
		closeRequestForProposal(requestForProposal).then(() => message.success('RFP has ended.'));
	}, [closeRequestForProposal, requestForProposal]);

	const requestForProposalId = useMemo(() => parseInt(match.params.id, 10), [match.params.id]);

	const tabs = useMemo(
		() => [
			{
				key: 'details',
				name: 'Overview',
			},
			{
				key: 'compareBids',
				name: 'Bids',
			},
		],
		[]
	);

	const changeBiddingStatusButton = useMemo(() => {
		switch (requestForProposal.status) {
			case 'draft':
				return (
					<Popconfirm
						onConfirm={publishEvent}
						placement="topLeft"
						title="Publish event and notify bidders?"
						okText="Publish event"
						cancelText="Cancel"
						onCancel={popConfirmToggle}
						visible={popConfirmVisible}
					>
						<Button ghost={true} onClick={popConfirmToggle}>
							Publish RFP
						</Button>
					</Popconfirm>
				);
			case 'active':
				return (
					<Popconfirm
						onConfirm={endEvent}
						placement="topLeft"
						title="Close event and notify bidders?"
						okText="Close event"
						cancelText="Cancel"
					>
						<Button ghost={true}>End RFP</Button>
					</Popconfirm>
				);
			case 'closed':
				return null;
			default:
				return null;
		}
	}, [endEvent, popConfirmToggle, popConfirmVisible, publishEvent, requestForProposal.status]);

	return (
		<div className="rfpDetailPage">
			<DetailPageHeader
				exactPath={`/${userType}/requestsForProposal/detail/:id`}
				redirectPath={`/${userType}/requestsForProposal/detail/${requestForProposalId}/details`}
				backLinkText={'Back to RFPs'}
				backLinkTo={`/${userType}/requestsForProposal`}
				title={requestsForProposalFetching ? null : requestForProposal.title}
				subtitle={
					<div className="rfpDetailPage__subtitleRow">
						<div className="rfpDetailPage__subtitle">
							{requestForProposalStatusIndicatorFormatter(requestForProposal.status)}
						</div>
						<div className="rfpDetailPage__subtitle">
							{moment().diff(requestForProposal.dueDate, 'h') < 0 ? (
								<div>
									Responses due{' '}
									<DateTimeHover
										timestamp={nullSafeGet('dueDate', requestForProposal)}
										showYear={false}
									/>
								</div>
							) : (
								<div>
									Bidding closed{' '}
									<DateTimeHover
										timestamp={nullSafeGet('dueDate', requestForProposal)}
										showYear={false}
									/>
								</div>
							)}
						</div>
					</div>
				}
				actions={[
					changeBiddingStatusButton,
					<Link
						to={`/${userType}/requestsForProposal/edit/${requestForProposalId}`}
						style={{ marginLeft: 16, marginRight: 16 }}
					>
						<Button ghost={true}>Edit</Button>
					</Link>,
					<Button ghost={true} onClick={handleDelete}>
						Delete
					</Button>,
				]}
				backgroundColor={nullSafeGet('detail.config.theme.navblock', companyConfig)}
				tabs={tabs}
				handleTabChange={handleTabChange}
				checkTabActive={checkTabActive}
				childRoutes={childRoutes}
			/>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => ({
	requestsForProposalFetching: state.requests_for_proposal.fetching,
	requestForProposal: state.requests_for_proposal.detail,
	companyConfig: state.company_config,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	location: ownProps.location,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	updateRequestForProposal: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? requestsForProposalRestCrudThunksForSupplier.update(entity)
				: requestsForProposalRestCrudThunksForBuyer.update(entity)
		),
	getRequestForProposal: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? requestsForProposalRestCrudThunksForSupplier.readOne(id)
				: requestsForProposalRestCrudThunksForBuyer.readOne(id)
		),
	deleteRequestForProposal: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? requestsForProposalRestCrudThunksForSupplier.delete(entity, 'id', null, true)
				: requestsForProposalRestCrudThunksForBuyer.delete(entity, 'id', null, true)
		),
	publishRequestForProposal: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? requestForProposalStatusChangeForSupplier('active', entity)
				: requestForProposalStatusChangeForBuyer('active', entity)
		),
	closeRequestForProposal: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? requestForProposalStatusChangeForSupplier('closed', entity)
				: requestForProposalStatusChangeForBuyer('closed', entity)
		),
});

const RFPDetailConnectedPage = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(RFPDetailPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(RFPDetailConnectedPage);

import * as React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'antd';
import {
	getAllLeafNodes,
	getObjectValues,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import { HolderOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { editBuyerCompanyApprovalAssociations } from '../../thunks/company_approval_thunks';
import { problemTypesHeirarchicalForBuyer } from '../../thunks/problem_types_thunks';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { useCallback, useEffect } from 'react';
import { multiValueFilterFieldNames } from '../../utils/DataConstants';

const style = require('./ApprovalHierarchyRowDisplay.less');

function ApprovalHierarchyRowDisplay(props) {
	const { id, approvalHierarchy, rank } = nullSafeGetOrElse('record', props, {});
	const {
		isCapex,
		isPM,
		woPriorities,

		problemTypes,
		problemTypeIds,
	} = nullSafeGetOrElse('record', props, {});
	const { type, autoApproveServiceRequests } = props;

	const allChildrenSelected = (record, validIds) =>
		record.hasChildren
			? record.children.every((child) => allChildrenSelected(child, validIds))
			: validIds.includes(record.id);

	const recursiveSelectedChildren = (record, validIds) => {
		if (nullSafeGetOrElse('children.length', record, 0) > 0) {
			if (allChildrenSelected(record, validIds)) {
				// All children (and their children) are selected.
				return record;
			} else {
				//Not every child is selected
				if (record.hasChildren) {
					return record.children.flatMap((child) => recursiveSelectedChildren(child, validIds));
				}
			}
		} else {
			//This is selected
			if (validIds.includes(record.id)) {
				return [record];
			}
		}
		return [];
	};
	const renderHierarchicalValues = (stateSlice) => {
		const selectedRecords = getObjectValues(stateSlice.records).flatMap((record) =>
			recursiveSelectedChildren(record, problemTypeIds)
		);
		return selectedRecords.map((record) => record.name);
	};

	const conditions = [
		{
			name: 'Expenditure Type',
			value: isCapex === undefined ? undefined : isCapex ? 'CapEx' : 'R&M',
		},
		{
			name: 'Work Order Type',
			value: isPM === undefined ? undefined : isPM ? 'Planned' : 'Reactive',
		},
		{
			name: 'Work Order Priorities',
			value:
				woPriorities === undefined || woPriorities.length === 0
					? undefined
					: woPriorities.map((val) => val.name),
		},
		{
			name: 'Work Order Problem Types',
			value:
				problemTypes === undefined || problemTypes.length === 0
					? undefined
					: renderHierarchicalValues(nullSafeGet('problemTypes', props)),
		},
	];
	const conditionDisplay = conditions
		.filter((rule) => rule.value !== undefined && rule.value !== [])
		.map((rule, i, rules) => (
			<Row className={'flex'}>
				<Col className={'flex pr-1'} style={{ fontWeight: 600 }}>
					{rule.name}
				</Col>
				<Col className={'flex pr-1'}>is</Col>
				<Col className={'flex'} style={{ fontStyle: 'italic' }}>
					{Array.isArray(rule.value) ? rule.value.join(', ') : rule.value}
				</Col>
				{rules.length - 2 > i ? (
					','
				) : rules.length - 1 > i ? (
					<Col className={'flex pl-1'}>and</Col>
				) : null}
			</Row>
		));

	return props.isDefault ? (
		<Row
			className={'draggable-item flex w-full items-center justify-between gap-12 py-4 px-2'}
			style={{ borderBottom: '1px solid rgb(240,240,240)' }}
		>
			<Col className={'flex w-4'} />
			<Col className={'flex w-5'} />
			<Col className={'flex w-28'}>Everything {props.rules.length > 0 ? 'else' : ''}</Col>
			<Col className={'flex w-64'} />
			<Col className={'ml-7 flex w-20'}>uses</Col>
			<Col className={'flex flex-grow'}>
				{type === 'workOrder' ? (
					autoApproveServiceRequests ? (
						`Auto-Approval`
					) : (
						`Facility Contacts (Access Control)`
					)
				) : (
					<a
						href={`https://app.useopenwrench.com/buyer/approvalHierarchies/detail/templates/${nullSafeGet(
							'id',
							approvalHierarchy
						)}/details`}
						target="_blank"
						rel="noreferrer"
					>
						{nullSafeGet('name', approvalHierarchy)}
					</a>
				)}
			</Col>
			<Col>{type === 'workOrder' ? null : <Button onClick={() => props.edit()}>Edit</Button>}</Col>
			<div style={{ width: 32 }}></div>
		</Row>
	) : (
		<Row
			className={'draggable-item flex w-full items-center justify-between gap-12 py-4 px-2'}
			style={{ borderBottom: '1px solid rgb(240,240,240)' }}
		>
			<Col key={`step-${id}`} className={'flex w-4'}>
				<HolderOutlined className="icon" translate={undefined} />
			</Col>
			<Col key={`rank-${id}`} className={'flex w-5'}>
				{rank}
			</Col>
			<Col className={'flex w-3'}>If</Col>
			<Col key={`conditions-${id}`} className={'flex w-96 flex-col'}>
				{conditionDisplay}
			</Col>
			<Col className={'flex w-20'}>then use</Col>
			<Col className={'flex flex-grow'}>
				<a
					href={`https://app.useopenwrench.com/buyer/approvalHierarchies/detail/templates/${nullSafeGet(
						'id',
						approvalHierarchy
					)}/details`}
					target="_blank"
					rel="noreferrer"
				>
					{nullSafeGet('name', approvalHierarchy)}
				</a>
			</Col>
			<Col>
				<Button onClick={() => props.edit()}>Edit</Button>
			</Col>
			<div
				onClick={props.delete}
				className={'x'}
				style={{
					display: 'flex',
					border: 'none',
					boxShadow: 'none',
					outline: 'none',
				}}
			>
				<MinusCircleOutlined translate="" className="dynamic-delete-button" />
			</div>
		</Row>
	);
}
const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	problemTypes: state.problem_types,
});
const mapDispatchToProps = (dispatch) => ({
	updateBuyerCompanyApprovalAssociations: (entity) =>
		dispatch(editBuyerCompanyApprovalAssociations(entity)),
	fetchProblemTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			problemTypesHeirarchicalForBuyer(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ApprovalHierarchyRowDisplay)
);

import * as React from 'react';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { Card, Col, Row } from 'antd';
import { ContactAvatar } from '../contact_avatar/ContactAvatar';

const style = require('./DrawerContactCardDisplay.less');

export const DrawerContactCardDisplay: React.FC<any> = ({ contact, title, roleType }) => {
	return (
		<Card title={title} bodyStyle={{ padding: '12px' }} className={'DrawerContactCardDisplay'}>
			<div className="mb-2 flex flex-row items-center">
				<ContactAvatar
					size="large"
					hidePopover={true}
					style={{ marginRight: 10, marginTop: 4 }}
					contact={contact}
					userType={roleType}
				/>
				<div>
					<div className="materialCard__title">
						{nullSafeGetOrElse('nameGiven', contact, '')}{' '}
						{nullSafeGetOrElse('nameFamily', contact, '')}
					</div>
					<div className="materialCard__subtitle">
						{nullSafeGetOrElse('title', contact, '')} {nullSafeGetOrElse('department', contact, '')}
					</div>
				</div>
			</div>
			{nullSafeGet('phoneWork', contact) ? (
				<div className="mt-1 flex items-center">
					<div className="IconDisplay grid justify-items-center">
						<i style={{ fontSize: 24 }} className={`icons8-font icons8-phone`} />
					</div>
					<div className={'SecondaryText'}>{nullSafeGet('phoneWork', contact)}</div>
				</div>
			) : null}
			{nullSafeGet('phoneMobile', contact) ? (
				<div className="mt-1 flex items-center">
					<div className="IconDisplay grid justify-items-center">
						<i style={{ fontSize: 24 }} className={`icons8-font icons8-cell-phone`} />
					</div>
					<div className={'SecondaryText'}>{nullSafeGet('phoneMobile', contact)}</div>
				</div>
			) : null}
			{nullSafeGet('email', contact) ? (
				<div className="mt-1 flex">
					<div className="IconDisplay grid justify-items-center">
						<i style={{ fontSize: 24 }} className={`icons8-font icons8-email`} />
					</div>
					<div className={'SecondaryText self-center'} style={{ overflowWrap: 'anywhere' }}>
						{nullSafeGet('email', contact)}
					</div>
				</div>
			) : null}
		</Card>
	);
};

import * as React from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { siteSurveysRestCrudThunksForBuyer } from '../../thunks/site_surveys_thunks';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import queryString from 'querystring';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import { updateNavbarState } from '../../actions/navbar_actions';
import { getLinkWIthBackLinkParams, goBackLink } from '../../utils/HistoryUtils';
import { siteSurvey } from '../../mock_data/mocks';

const style = require('./SiteSurveysDetailPage.less');

class SiteSurveysDetailPage extends React.Component<any, any> {
	state = {
		backLinkTo: 'buyer/siteSurveys',
		backlinkParams: '',
	};
	componentDidMount() {
		const { getSiteSurvey, location, match, updateNavbar } = this.props;
		const siteSurveyId = match.params.id;
		getSiteSurvey(siteSurveyId).then((siteSurvey) => {
			let searchString = location.search;
			if (searchString[0] === '?') {
				searchString = searchString.slice(1);
			}
			const queryParams = queryString.parse(searchString);
			if (queryParams.backlinkParams) {
				updateNavbar(
					'Back To Site Surveys',
					`/buyer/siteSurveys/overview/all?${queryParams.backlinkParams}`
				);
				this.setState({ backlinkParams: queryParams.backlinkParams });
			}
			this.setState({ loading: false });
		});
	}

	handleTabChange = (key) => {
		const { history, match } = this.props;
		const siteSurveyId = match.params.id;
		history.push(`/buyer/siteSurveys/detail/${siteSurveyId}/${key}`);
	};

	handleDelete = () => {
		const { history, deleteSiteSurvey, siteSurvey, location } = this.props;
		deleteSiteSurvey(siteSurvey).then(() => {
			history.push(goBackLink(location, `/buyer/siteSurveys/overview/all`));
		});
	};
	handleEdit = () => {
		const { history, siteSurvey, location } = this.props;
		history.push(
			getLinkWIthBackLinkParams(
				location,
				'Back to Site Survey Detail',
				`/buyer/siteSurveys/overview/edit/${siteSurvey.id}`
			)
		);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	render() {
		const { siteSurvey, siteSurveysFetching, match, companyConfig } = this.props;
		const siteSurveyId = match.params.id;
		const tabs = [
			{
				key: 'details',
				name: 'Overview',
			},
			{
				key: 'walkthroughs',
				name: 'Walkthroughs',
			},
		];
		return (
			<div className="siteSurveysDetailPage">
				<DetailPageHeader
					exactPath={'/buyer/siteSurveys/detail/:id'}
					redirectPath={`/buyer/siteSurveys/detail/${siteSurveyId}/details`}
					backLinkText={'Back to site surveys'}
					backLinkTo={`/buyer/siteSurveys/overview/all`}
					title={siteSurveysFetching ? null : siteSurvey.title}
					subtitle={
						<div className="siteSurveysDetailPage__subtitleRow">
							<div className="siteSurveysDetailPage__subtitle">
								{nullSafeGet('location.name', siteSurvey)}
							</div>
						</div>
					}
					actions={[
						<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_SITE_SURVEYS}>
							<Button key={1} ghost={true} onClick={this.handleEdit} style={{ marginRight: 16 }}>
								Edit
							</Button>
							<Button key={1} ghost={true} onClick={this.handleDelete}>
								Delete
							</Button>
						</AccessPermissionChecker>,
					]}
					tabs={tabs}
					backgroundColor={nullSafeGet('detail.config.theme.navblock', companyConfig)}
					handleTabChange={this.handleTabChange}
					checkTabActive={this.checkTabActive}
					childRoutes={this.props.childRoutes}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	siteSurveysFetching: state.site_surveys.fetching,
	siteSurvey: state.site_surveys.detail,
	match: ownProps.match,
	history: ownProps.history,
	companyConfig: state.company_config,
	location: ownProps.location,
});

const mapDispatchToProps = (dispatch) => ({
	getSiteSurvey: (id) => dispatch(siteSurveysRestCrudThunksForBuyer.readOne(id)),
	deleteSiteSurvey: (entity) =>
		dispatch(siteSurveysRestCrudThunksForBuyer.delete(entity, 'id', null, true)),
	updateNavbar: (backlinkTitle, backlinkTo, navbarBackgroundColor) =>
		dispatch(updateNavbarState(backlinkTitle, backlinkTo, navbarBackgroundColor)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(SiteSurveysDetailPage)
);

import React from 'react';
import { Button, Popover, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { equipmentTypesRestCrudThunksForSupplier } from '../../thunks/equipment_types_thunks';
import { Link } from 'react-router-dom';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import { getBackLinkStates } from '../../utils/HistoryUtils';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { CONFIG_NAMES, PERMISSION_NAMES } from '../../utils/AuthUtils';
import { isSupplierInventoryConfigEnabled } from '../common/SupplierInventoryConfigPermissionChecker';

require('./EquipmentsDetailPage.less');
const DEFAULT_BACK_LINK = '/supplier/equipments/overview/templates';

class EquipmentsDetailPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			lightboxIsOpen: false,
			currentIndex: 0,
			backLinkTo: DEFAULT_BACK_LINK,
			backLinkText: 'Back to equipment Types',
		};
	}

	goToPrevious = () => {
		this.setState({ currentIndex: this.state.currentIndex - 1 });
	};

	goToNext = () => {
		this.setState({ currentIndex: this.state.currentIndex + 1 });
	};

	componentDidMount() {
		const { getEquipment, location, match } = this.props;
		const equipmentTypeId = match.params.id;
		this.setState(getBackLinkStates(location, DEFAULT_BACK_LINK));
		getEquipment(equipmentTypeId);
	}

	handleTabChange = (key) => {
		const { history, match } = this.props;
		const equipmentTypeId = match.params.id;
		history.push(`/supplier/equipments/detail/${equipmentTypeId}/${key}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	handleDelete = () => {
		const { history, deleteEquipment, equipmentType } = this.props;
		deleteEquipment(equipmentType)
			.then(() => {
				history.push(DEFAULT_BACK_LINK);
			})
			.catch((err) => message.error(err));
	};

	render() {
		const { equipmentType, equipmentsFetching, match, companyConfig } = this.props;
		const equipmentTypeId = match.params.id;
		const poDisabled = isSupplierInventoryConfigEnabled(
			companyConfig,
			CONFIG_NAMES.DISABLE_PURCHASE_ORDERS
		);
		const tabs = [
			{
				key: 'details',
				name: 'Overview',
			},
			{
				key: 'prices',
				name: 'Price History',
			},
			{
				key: 'purchaseRequests',
				name: 'Purchase Requests',
			},
			...(!poDisabled
				? [
						{
							key: 'purchaseOrders',
							name: 'Purchase Orders',
						},
				  ]
				: []),
			{
				key: 'workOrders',
				name: 'Work Orders',
			},
		];
		return (
			<DetailPageHeader
				exactPath={'/supplier/equipments/detail/:id'}
				redirectPath={`/supplier/equipments/detail/${equipmentTypeId}/details`}
				backLinkText={this.state.backLinkText}
				backLinkTo={this.state.backLinkTo}
				title={
					equipmentsFetching ? null : (
						<div>
							<div>{equipmentType.modelName}</div>
							{!equipmentType.isActive ? <div className="text-sm">Inactive</div> : null}
						</div>
					)
				}
				subtitle={null}
				actions={[
					<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PARTS_AND_EQUIPMENTS}>
						<Link
							key={1}
							to={`/supplier/equipments/edit/${equipmentTypeId}`}
							style={{ marginRight: 16 }}
						>
							<Button ghost={true}>Edit</Button>
						</Link>
					</AccessPermissionChecker>,
					<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PARTS_AND_EQUIPMENTS}>
						<Popover
							content={
								equipmentType.isAssociatedWithOtherEntities
									? `Equipment type cannot be deleted as it is asssociated with other entities!`
									: ''
							}
							trigger="hover"
						>
							<Button
								key={2}
								ghost={true}
								onClick={this.handleDelete}
								disabled={equipmentsFetching || equipmentType.isAssociatedWithOtherEntities}
							>
								Delete
							</Button>
						</Popover>
					</AccessPermissionChecker>,
				]}
				tabs={tabs}
				backgroundColor={nullSafeGet('config.theme.navblock', companyConfig)}
				handleTabChange={this.handleTabChange}
				checkTabActive={this.checkTabActive}
				childRoutes={this.props.childRoutes}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	equipmentsFetching: state.equipment_types.fetching,
	equipmentType: state.equipment_types.detail,
	match: ownProps.match,
	history: ownProps.history,
	location: ownProps.location,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	getEquipment: (id) => dispatch(equipmentTypesRestCrudThunksForSupplier.readOne(id)),
	deleteEquipment: (entity) =>
		dispatch(equipmentTypesRestCrudThunksForSupplier.delete(entity, 'id', null, true)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EquipmentsDetailPage));

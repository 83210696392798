import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Alert, Select } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { connect } from 'react-redux';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import {
	locationsRestCrudThunksForBuyer,
	locationsRestCrudThunksForSupplier,
	readAllLocationsWithoutRoleChecksForBuyer,
	readAllLocationsWithoutRoleChecksForSupplier,
} from '../../thunks/locations_thunks';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { ROLE_TYPES } from '../../utils/DataConstants';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import { EmptyState } from '../empty_state/EmptyState';

const FormItem = Form.Item;

interface BuyerLocationUpdateFormProps extends FormComponentProps {
	onSubmit: any;
	onCancel: any;
	visible: boolean;
	createErrors: any;
	updateErrors: any;
	creating: boolean;
	updating: boolean;
	currentUser: any;
	formData: any;

	fetchLocations: any;
	fetchMultipleLocations: any;
	locations: any;
	submitting: any;
}

class BuyerLocationUpdateForm extends React.Component<BuyerLocationUpdateFormProps, any> {
	render() {
		const {
			visible,
			onCancel,
			onSubmit,
			form,
			formData,
			currentUser,
			createErrors,
			updateErrors,
			locations,
			fetchLocations,
			fetchMultipleLocations,
			submitting,
		} = this.props;
		const { getFieldDecorator } = form;
		getFieldDecorator('buyerFacilityId', { initialValue: nullSafeGet('facility.id', currentUser) });
		getFieldDecorator('email', { initialValue: nullSafeGet('contact.email', formData) });
		getFieldDecorator('nameGiven', { initialValue: nullSafeGet('contact.nameGiven', formData) });
		getFieldDecorator('nameFamily', { initialValue: nullSafeGet('contact.nameFamily', formData) });
		getFieldDecorator('nameSuffix', { initialValue: nullSafeGet('contact.nameSuffix', formData) });
		getFieldDecorator('roles', {
			initialValue: nullSafeGet('role', formData) === undefined ? 'BUYER_USER' : formData.role[0],
		});

		return (
			<AccessPermissionChecker
				name={PERMISSION_NAMES.MODIFY_SELF_LOCATION_ACCESS}
				permissionDeniedContent={
					<Modal
						visible={visible}
						width={600}
						title={''}
						footer={null}
						onOk={onSubmit}
						onCancel={onCancel}
						closable={true}
					>
						<EmptyState
							graphic={
								<img
									style={{ marginBottom: 8 }}
									src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
									alt="Permission denied"
								/>
							}
							headline={'Permission denied'}
							body={
								<div style={{ textAlign: 'center' }}>
									<div style={{ maxWidth: 440, marginBottom: 16 }}>
										Looks like you do not have permission to add/remove location. Please request
										admin to provide access.
									</div>
								</div>
							}
						/>
					</Modal>
				}
			>
				<Modal
					visible={visible}
					width={600}
					title={'Edit Location Access'}
					okText={'Update Location Access'}
					onCancel={onCancel}
					onOk={onSubmit}
					closable={false}
					confirmLoading={submitting}
				>
					<Form layout="vertical" className="supplierForm">
						{createErrors.length > 0 ? (
							<FormItem>
								<Alert message={createErrors.join(' ')} type="error" />
							</FormItem>
						) : null}
						{updateErrors.length > 0 ? (
							<FormItem>
								<Alert message={updateErrors.join(' ')} type="error" />
							</FormItem>
						) : null}
						{form.getFieldValue(`roles`) === 'BUYER_STORE_ASSOCIATE' ||
						form.getFieldValue(`roles`) === 'BUYER_USER' ? (
							<FormItem required={true} label="Locations">
								{getFieldDecorator('locationIds', {
									initialValue:
										nullSafeGet('locationIds', formData) === undefined ? [] : formData.locationIds,
									rules: [{ required: true, message: 'This field is required.' }],
								})(
									<OWAsyncSelect
										mode="multiple"
										stateSlice={locations}
										targetCollectionName="adminStoreAssociateIndex"
										fetchMultiple={(ids, targetCollectionName) => {
											fetchMultipleLocations(ids, targetCollectionName);
										}}
										fetchData={(
											searchText,
											targetCollectionName,
											pagination,
											sorting,
											filters,
											addToTargetCollection
										) => {
											fetchLocations(
												{ name: searchText },
												targetCollectionName,
												pagination,
												sorting,
												filters,
												addToTargetCollection
											);
										}}
										renderRecord={(location) => (
											<Select.Option key={location.id} value={location.id}>
												{location.name}
											</Select.Option>
										)}
										sortBy={{ sort_by: 'name', order: 'ascend' }}
									/>
								)}
							</FormItem>
						) : null}
					</Form>
				</Modal>
			</AccessPermissionChecker>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	createErrors: state.buyer_contacts.createErrors,
	updateErrors: state.buyer_contacts.updateErrors,
	creating: state.buyer_contacts.creating,
	updating: state.buyer_contacts.updating,
	currentUser: state.session.currentUser,
	formData: ownProps.formData,
	isLocationChange: ownProps.isLocationChange,
	locations: state.locations,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? readAllLocationsWithoutRoleChecksForSupplier(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: readAllLocationsWithoutRoleChecksForBuyer(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: locationsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
});

const ComponentWithoutUserType = connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create<BuyerLocationUpdateFormProps>()(BuyerLocationUpdateForm));

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

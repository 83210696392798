import React from 'react';
import NotificationsIndexPage from '../notifications_index_page/NotificationsIndexPage';
import { BUYER_NOTIFICATION_APPROVALS_TYPES_MAP } from '../../utils/NotificationUtils';
import { Radio } from 'antd';
import { isNil } from 'lodash';

export default function NotificationsApprovals() {
	return (
		<NotificationsIndexPage
			key="NotificationsApprovals"
			preAppliedFilters={{
				isActionRequired: true,
			}}
			notificationTypes={BUYER_NOTIFICATION_APPROVALS_TYPES_MAP.map((filter) => {
				if (filter.children && filter.children.length > 0) {
					return filter.children.map((child) => child.key);
				}
				return filter.key;
			}).flat()}
			showUnreadFilter={false}
			showFilters={false}
			typeFilters={BUYER_NOTIFICATION_APPROVALS_TYPES_MAP}
			extraFilters={({ updateFilters, targetCollection, activeFilters }) => {
				const items = [
					{
						key: 'pendingOnMe',
						label: 'Waiting on me',
						value: { isActionRequired: true, pendingApproval: undefined },
					},
					{
						key: 'pendingOnTeam',
						label: 'Waiting on Others',
						value: { pendingApproval: true, isActionRequired: false },
					},
					{
						key: 'approvalCompleted',
						label: 'Approved/Declined',
						value: { isActionRequired: false, pendingApproval: false },
					},
				];
				const selectedVal = isNil(activeFilters)
					? 'approvalCompleted'
					: activeFilters.isActionRequired === false && activeFilters.pendingApproval === false
					? 'approvalCompleted'
					: activeFilters.isActionRequired === false && activeFilters.pendingApproval
					? 'pendingOnTeam'
					: 'pendingOnMe';

				return (
					<Radio.Group value={selectedVal}>
						{items.map((item) => (
							<Radio.Button
								style={{ flexWrap: 'nowrap', height: 40, lineHeight: '40px' }}
								key={item.key}
								onClick={() => updateFilters(item.value, targetCollection)}
								value={item.key}
							>
								{item.label}
							</Radio.Button>
						))}
					</Radio.Group>
				);
			}}
		/>
	);
}

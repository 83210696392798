import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	workOrdersRestCrudThunksForBuyer,
	workOrdersRestCrudThunksForSupplier,
} from '../../thunks/work_orders_thunks';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import IncompleteWorkorderTimeline from './IncompleteWorkOrderTimeline';
import CompletedWorkOrderTimeline from './CompletedWorkOrdersTimeline';
import { Card, Spin, Timeline } from 'antd';
import { UserName } from '../name_component/user/UserNameComponent';
import moment from 'moment';
import TruncateText from '../common/TruncateText';
import { Link } from 'react-router-dom';
import { dateFormatter } from '../../utils/DataFormatterUtils';
import { ROLE_TYPES } from '../../utils/DataConstants';

const DEFAULT_BACK_LINK_TEXT = 'Back to asset downtime history';

const DowntimeWorkOrdersTable: FC<any> = ({
	dtHistory,
	fetchMultipleWorkOrders,
	history,
	location,
	userType,
	fetchMultipleWorkOrdersSupplier,
}): React.ReactElement => {
	const [fetching, setFetching] = useState(false);
	const [workOrders, setWorkOrders] = useState([]);

	useEffect(() => {
		setFetching(true);
		const action =
			ROLE_TYPES.SUPPLIER === userType ? fetchMultipleWorkOrdersSupplier : fetchMultipleWorkOrders;
		action(nullSafeGetOrElse('associatedWorkOrderIds', dtHistory, []))
			.then((res) => setWorkOrders(res))
			.finally(() => setFetching(false));
	}, [dtHistory, fetchMultipleWorkOrders, fetchMultipleWorkOrdersSupplier, userType]);

	return (
		<Card>
			{fetching ? (
				<div className="flex justify-center">
					<Spin />
				</div>
			) : (
				<>
					<Timeline>
						<Timeline.Item color={'red'}>
							Marked as Offline
							<div className="text-sm">
								by{' '}
								<UserName
									contact={dtHistory.assetMarkedOfflineByContact}
									mode={'inline'}
									placement={'top'}
								/>{' '}
								at {moment(dtHistory.startTime).format('MMMM Do YYYY, h:mm a')}
							</div>
						</Timeline.Item>

						{dtHistory.assetMarkedOnlineByContact && (
							<Timeline.Item color={'green'}>
								Marked as Online{' '}
								<div className="text-sm">
									by{' '}
									<UserName
										contact={dtHistory.assetMarkedOnlineByContact}
										mode={'inline'}
										placement={'top'}
									/>{' '}
									at {moment(dtHistory.startTime).format('MMMM Do YYYY, h:mm a')}
								</div>
							</Timeline.Item>
						)}
					</Timeline>
					{dtHistory.note && (
						<div className="m-4 rounded bg-slate-100 p-4">
							<div>
								<span>Note: {dtHistory.note}</span>
							</div>
						</div>
					)}
					{(workOrders && workOrders.length && (
						<div className="m-4 ml-4">
							Work Orders associated:
							<ol>
								{workOrders.map((workOrder) => (
									<li key={workOrder.id}>
										<div className="mb-2 flex flex-col  p-2">
											<div>
												<TruncateText tooltip length={40} text={workOrder.title} /> {`(`}
												<Link
													to={getLinkWIthBackLinkParams(
														location,
														DEFAULT_BACK_LINK_TEXT,
														`/${userType}/workOrders/detail/${workOrder.id}/details`
													)}
												>
													#{workOrder.id}
												</Link>{' '}
												{')'}
											</div>
											<div className="text-sm">
												{`reported at ${moment(workOrder.createdAt).format(
													'MMMM Do YYYY, h:mm a'
												)}`}
											</div>
										</div>
									</li>
								))}
							</ol>
						</div>
					)) ||
						null}
				</>
			)}
		</Card>
	);
};

const mapStateToProps = (_, ownProps) => ({
	history: ownProps.history,
	location: ownProps.location,
});

const mapDispatchToProps = (dispatch) => ({
	fetchMultipleWorkOrders: (ids, targetCollectionName) =>
		dispatch(workOrdersRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchMultipleWorkOrdersSupplier: (ids, targetCollectionName) =>
		dispatch(workOrdersRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DowntimeWorkOrdersTable));

import React, { useCallback, useMemo } from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import {
	getPurchaseOrderId,
	nullSafeGet,
	nullSafeGetOrElse,
	stopDefaultEvents,
} from '../../utils/DataAccessUtils';
import PurchaseRequestStatusDisplay from '../purchase_request_status_display/PurchaseRequestStatusDisplay';
import PartOrEquipmentAvatar from '../common/PartOrEquipmentAvatar';
import HyperLink from '../common/HyperLink';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';

require('./PartLineItemRowDisplay.less');

const PartLineItemRowDisplay: React.FC<any> = ({ partLineItem, history, location }) => {
	const purchaseOrder = useMemo(
		() => nullSafeGet('associatedPurchaseOrderLineItem.supplierPurchaseOrder', partLineItem),
		[partLineItem]
	);

	const openPurchaseOrder = useCallback(
		(e) => {
			stopDefaultEvents(e);
			const path = `/supplier/purchaseOrders/detail/${nullSafeGet('id', purchaseOrder)}/details`;
			history.push(getLinkWIthBackLinkParams(location, 'Back to purchase request', path));
		},
		[location, purchaseOrder, history]
	);

	return (
		<div className="partLineItemRowDisplay" style={{ justifyContent: 'space-between' }}>
			<div className="partLineItemRowDisplay">
				<div style={{ marginRight: 24 }}>
					<PartOrEquipmentAvatar item={nullSafeGetOrElse('part', partLineItem, [])} />
				</div>
				<div>
					<div className="partLineItemRowDisplay__rowTitle">
						<Ellipsis tooltip={true} length={56}>
							{nullSafeGet('part.name', partLineItem)}
						</Ellipsis>
					</div>
					<div className="partLineItemRowDisplay__rowSubtitle">
						{nullSafeGet('part.partNumber', partLineItem) && (
							<div className="partCatalogRowDisplay__rowInlineGroup">
								Part Number: {nullSafeGet('part.partNumber', partLineItem)}
							</div>
						)}
						{nullSafeGet('part.manufacturerName', partLineItem) && (
							<div className="partCatalogRowDisplay__rowInlineGroup">
								Manufacturer: {nullSafeGet('part.manufacturerName', partLineItem)}
							</div>
						)}
						<div className="partLineItemRowDisplay__rowInlineGroup">
							<Ellipsis tooltip={true} length={80}>
								{nullSafeGet('part.description', partLineItem)}
							</Ellipsis>
						</div>
					</div>
					{purchaseOrder && (
						<div className="partLineItemRowDisplay__rowSubtitle" style={{ marginTop: '8px' }}>
							<HyperLink
								text={`PO ${getPurchaseOrderId(purchaseOrder)}`}
								onClick={openPurchaseOrder}
							/>
						</div>
					)}
					<div className="partLineItemRowDisplay__rowSubtitle" style={{ marginTop: '8px' }}>
						<PurchaseRequestStatusDisplay status={partLineItem.status} />
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	location: ownProps.location,
});

export default withRouter<any, any>(connect(mapStateToProps)(PartLineItemRowDisplay));

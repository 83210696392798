import { Button, Card, Col, Form, Input, Layout, Row } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { buyersRestCrudThunksForBuyer } from '../../thunks/buyers_thunks';
import { manuallyAssociateBuyerContactWithFacility } from '../../thunks/buyer_contacts_thunks';
import { buyerFacilitiesRestCrudThunksForBuyer } from '../../thunks/buyer_facilities_thunks';
import {
	addWorkspaceToCurrentUser,
	changeWorkspace,
	checkUserTypeToken,
	getFacilityByCompanyID,
	initiateChangeWorkspace,
} from '../../thunks/session_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { getHomePageByRole } from '../../utils/EnvConfigUtils';
import { LocationAutocomplete } from '../location_autocomplete/LocationAutocomplete';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

export class NewFacilities extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
		};
	}
	formRef = React.createRef<FormInstance>();

	onFinish = (values: any) => {
		const {
			currentUser = {},
			addWorkspaceToCurrentUser,
			changeWorkspace,
			initiateChangeWorkspace,
			history,
			create,
			getFacilityByCompanyID,
			associateBuyerContactWithFacility,
			refreshCurrentUser,
		} = this.props;
		const primaryAddress = Object.keys(values)
			.filter((k) => k.indexOf('primaryAddress') > -1)
			.reduce((acc, k) => {
				const [_, x] = k.split('-');
				acc[x] = values[k];
				return acc;
			}, {});
		const buyer = {
			...values,
			displayName: values.displayName || values.name,
			primaryContactEmail: values.primaryContactEmail || currentUser.email,
			primaryAddress,
		};

		this.setState({ isLoading: true });
		create(buyer)
			.then((buyerCompany) => {
				getFacilityByCompanyID(buyerCompany.id, buyerCompany.name, ROLE_TYPES.BUYER).then(
					(buyerFacilities) => {
						addWorkspaceToCurrentUser(buyerFacilities.id, ROLE_TYPES.BUYER).then(() => {
							changeWorkspace(buyerFacilities.id, ROLE_TYPES.BUYER, getHomePageByRole('buyer', {}));
						});
					}
				);
			})
			.catch((e) => {
				console.log(e);
				this.setState({ isLoading: false });
			});
	};

	onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};

	handleLocationAutocompleteSelect = (addr, lonLat, parsedAddress) => {
		this.formRef.current!.setFieldsValue({
			'primaryAddress-streetAddress1': parsedAddress.streetAddress1,
			'primaryAddress-city': parsedAddress.city,
			'primaryAddress-region': parsedAddress.region,
			'primaryAddress-postcode': parsedAddress.postcode,
			'primaryAddress-country': parsedAddress.country,
			longitude: lonLat.lng,
			latitude: lonLat.lat,
		});
	};

	render() {
		const { isLoading } = this.state;
		return (
			<Layout.Content>
				<LogOnMountWithStandardEventProperties eventType="visited buyer new facility creation page" />
				<Row gutter={16}>
					<Col span={24}>
						<Card style={{ overflow: 'visible' }}>
							<div style={{ padding: 24 }}>
								<h4 style={{ marginBottom: 16 }}>Create a new Facility</h4>
								<p style={{ fontSize: 18, marginBottom: 16 }}>
									Creating a new facility will enable you to switch b/w them seamlessly with same
									account and manage them.
								</p>
								<p style={{ fontSize: 16, marginBottom: 32 }}>
									Lets start with some basic details about the new Facility you want to create.
								</p>
								<Form
									ref={this.formRef}
									name="basic"
									initialValues={{ status: 'active' }}
									onFinish={this.onFinish}
									onFinishFailed={this.onFinishFailed}
									autoComplete="off"
									layout="vertical"
								>
									<Form.Item
										label="Facility name"
										name="name"
										rules={[
											{
												required: true,
												message: "Please input your facility(company)'s legal entity name!",
											},
										]}
									>
										<Input />
									</Form.Item>

									<Form.Item label="Primary Address">
										<LocationAutocomplete
											initialValue={''}
											placeholder="Where is your facility's primary location?"
											onSelect={this.handleLocationAutocompleteSelect}
										/>
									</Form.Item>
									<Form.Item name="primaryAddress-streetAddress1" hidden />
									<Form.Item name="primaryAddress-city" hidden />
									<Form.Item name="primaryAddress-region" hidden />
									<Form.Item name="primaryAddress-postcode" hidden />
									<Form.Item name="primaryAddress-country" hidden />
									<Form.Item name="longitude" hidden />
									<Form.Item name="latitude" hidden />
									<Form.Item name="status" hidden />
									<Form.Item>
										<Button type="primary" htmlType="submit" loading={isLoading}>
											Create facility
										</Button>
									</Form.Item>
								</Form>
							</div>
						</Card>
					</Col>
				</Row>
			</Layout.Content>
		);
	}
}

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});
const mapDispatchToProps = (dispatch) => ({
	initiateChangeWorkspace: (id, userType) => dispatch(initiateChangeWorkspace(id)),
	getFacilityByCompanyID: (companyID, name, userType) =>
		dispatch(getFacilityByCompanyID(companyID, name, userType)),
	create: (entity) => dispatch(buyersRestCrudThunksForBuyer.create(entity)),
	update: (entity) => dispatch(buyersRestCrudThunksForBuyer.update(entity)),
	associateBuyerContactWithFacility: (contact, facilityId) =>
		dispatch(manuallyAssociateBuyerContactWithFacility(contact, facilityId)),
	refreshCurrentUser: () => dispatch(checkUserTypeToken(ROLE_TYPES.BUYER)()),
	addWorkspaceToCurrentUser: (facilityId, userType) =>
		dispatch(addWorkspaceToCurrentUser(facilityId, userType)),
	changeWorkspace: (id, userType, landingURL) =>
		dispatch(changeWorkspace(id, userType, landingURL)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewFacilities);

import * as React from 'react';
import BuyerResetPasswordForm from '../buyer_reset_password_form/BuyerResetPasswordForm';
import { Layout, Row, Col, BackTop, Card } from 'antd';
import { withRouter } from 'react-router';
import { parseQueryParams } from '../../utils/DataAccessUtils';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;
const style = require('./BuyerResetPasswordPage.less');
const style2 = require('../../styles/blah.less');

class BuyerResetPasswordPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
	}

	render() {
		const params = parseQueryParams(this.props.location.search);
		const token = params.resetToken;
		return (
			<Content className="resetPasswordPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited buyer reset password page" />
				<BackTop />
				<Row align="middle" style={{ height: '100vh', margin: '0 -8px' }} gutter={16}>
					<Col span={8} offset={8}>
						<Card title="Choose a new password">
							<BuyerResetPasswordForm token={token} />
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

export default withRouter(BuyerResetPasswordPage);

import {
	loggingIn,
	doneLoggingIn,
	loginSuccess,
	loginFailure,
	loggingOut,
	doneLoggingOut,
	logoutSuccess,
	logoutFailure,
	signingUp,
	doneSigningUp,
	signupFailure,
	checkTokenSuccess,
	checkTokenFailure,
	requestingPasswordReset,
	requestPasswordResetSuccess,
	requestPasswordResetFailure,
	changingPassword,
	doneChangingPassword,
	passwordChangeSuccess,
	passwordChangeFailure,
	signUpCompleting,
	signUpCompletionSuccess,
	signUpCompletionFailure,
	resendConfirmationMailFailure,
	resendConfirmationMailStart,
	resendConfirmationMailSuccess,
	switchingWorkspaceSuccess,
	switchingWorkspaceFailure,
	switchingWorkspace,
} from '../actions/session_actions';
import 'whatwg-fetch';
import queryString from 'qs';
import { getBackendUri } from '../utils/EnvConfigUtils';
import FetchUtils, { parseJSON } from './fetch_utils';
import { companyConfigRestCrudThunks } from './company_config_thunks';
import IntercomService from '../utils/IntercomService';
import { nullSafeGet, nullSafeGetOrElse } from '../utils/DataAccessUtils';
import ThirdPartyService from '../utils/ThirdPartyService';
import { TOKEN_DEFAULT_EXPIRY } from '../constants/times';
import { message } from 'antd';

const fetchUtils = new FetchUtils();

const BASE_URL = getBackendUri();
const getLoginUrl = (role) => `/api/v1/${role}/authenticate`;
const getResetPasswordUrl = (role) => `/api/v1/${role}/resetPassword`;
const getRequestPasswordResetUrl = (role) => `/api/v1/${role}/forgotPassword`;
const getSignUpUrl = (role) => `/api/v1/${role}/signUp`;
const getSignUpCompletionUrl = (role) => `/api/v1/${role}/signUpCompletion`;
const getAuthRenewUrl = (role) => `/api/v1/${role}/authRenew`;
const getUserObjUrl = (role) => `/api/v1/${role}/get_user`;
const getAuth0JwtValidateURL = (role) => `/api/v1/${role}/auth/jwt`;
const getLogOutUrl = (role) => `/api/v1/${role}/signOut`;
const getChangePasswordUrl = (role) => `/api/v1/${role}/changePassword`;
const getResendConfirmationUrl = (role) => `/api/v1/${role}/signUpCompletion/resend`;

const USER_LOCALSTORAGE_KEYS = [
	'token',
	'accountActivated',
	'userType',
	'expiresOn',
	'user',
	'contact',
	'workspaceId',
	'impersonation',
	'isActive',
	'isApproved',
];
export const retrieveCachedUserDetails = (
	keys: Array<string> = USER_LOCALSTORAGE_KEYS
): {
	token?: string;
	accountActivated?: boolean;
	isActive?: boolean;
	isApproved?: boolean;
	userType?: string;
	expiresOn?: string;
	user?: any;
	contact?: any;
	workspaceId?: any;
	impersonation?: any;
} => {
	const userObj = {};
	keys.map((key) => {
		let result;
		try {
			result = JSON.parse(localStorage.getItem(key));
		} catch (error) {
			result = localStorage.getItem(key);
		}
		userObj[key] = result;
	});
	return userObj;
};
export const clearCachedUserDetails = (keys: Array<string> = USER_LOCALSTORAGE_KEYS): void => {
	keys.map((key) => {
		localStorage.removeItem(key);
	});
};
export const updateActiveWorkspaceinCache = (id, contactObj) => {
	localStorage.setItem('workspaceId', id);
	localStorage.setItem('contact', JSON.stringify(contactObj));
};
export const storeCachedUserDetails = (userObj, userType) => {
	localStorage.setItem('token', userObj.token);
	localStorage.setItem('token_expiry', `${new Date().getTime() + TOKEN_DEFAULT_EXPIRY}`); // 6 hours
	localStorage.setItem('accountActivated', userObj.accountActivated);
	localStorage.setItem('isActive', `${userObj.isActive}`);
	localStorage.setItem('isApproved', `${userObj.isApproved}`);
	localStorage.setItem('userType', userType);
	localStorage.setItem('expiresOn', new Date(userObj.expiresOn).toJSON());
	localStorage.setItem('user', JSON.stringify(userObj.user));
	localStorage.setItem('contact', JSON.stringify(userObj.contact));
	localStorage.setItem('workspaceId', JSON.stringify(userObj.workspaceId));
	localStorage.setItem('isReadOnlyUser', nullSafeGet('contact.readOnlyAccess', userObj));
};
const activateImpersonationInCacheStorage = (json, userType, email) => {
	const originalObj = retrieveCachedUserDetails();
	const activeWorkspaceId = getActiveWorkspace(json);
	storeCachedUserDetails({ ...originalObj, ...json, workspaceId: activeWorkspaceId }, userType);
	localStorage.setItem('or_token', originalObj.token);
	localStorage.setItem('or_accountActivated', `${originalObj.accountActivated}`);
	localStorage.setItem('or_isActive', `${originalObj.isActive}`);
	localStorage.setItem('or_isApproved', `${originalObj.isApproved}`);
	localStorage.setItem('or_userType', userType);
	localStorage.setItem('or_expiresOn', new Date(originalObj.expiresOn).toJSON());
	localStorage.setItem('or_user', JSON.stringify(originalObj.user));
	localStorage.setItem('or_contact', JSON.stringify(originalObj.contact));
	localStorage.setItem('or_workspaceId', JSON.stringify(originalObj.workspaceId));
	localStorage.setItem('impersonation', email);
};
export const getImpersonatorUser = () => {
	const userObj = {};
	USER_LOCALSTORAGE_KEYS.map((key) => {
		let result;
		try {
			result = JSON.parse(localStorage.getItem(`or_${key}`));
		} catch (error) {
			result = localStorage.getItem(`or_${key}`);
		}
		userObj[key] = result;
	});
	return userObj;
};
const deActivateImpersonationInCacheStorage = (userType) => {
	storeCachedUserDetails(getImpersonatorUser(), userType);
	localStorage.removeItem('impersonation');
	localStorage.removeItem('or_token');
	localStorage.removeItem('or_token');
	localStorage.removeItem('or_accountActivated');
	localStorage.removeItem('or_userType');
	localStorage.removeItem('or_expiresOn');
	localStorage.removeItem('or_user');
	localStorage.removeItem('or_contact');
	localStorage.removeItem('or_workspaceId');
};

const getActiveWorkspace = (json) => {
	return nullSafeGet('contact.facility.id', json);
	// TODO:  in case the activeWorkspace is mismatched b/w the userObj and localStorage. in case of signup maybe.
	// const currentActiveBackendWorkspace = nullSafeGet('contact.facility.facilityId',json);
	// const activeWorkspaceIdInlocalStorage = parseInt(retrieveCachedUserDetails(["workspaceId"]).workspaceId);
	// if(activeWorkspaceIdInlocalStorage && currentActiveBackendWorkspace !== activeWorkspaceIdInlocalStorage){
	//     return activeWorkspaceIdInlocalStorage
	// }
	// return currentActiveBackendWorkspace
};

const getPosthogFeatureFlags = (email) => {
	return new Promise((resolve, reject) => resolve({}));
	// 	fetch('https://app.posthog.com/decide?v=2', {
	// 		method: 'POST',
	// 		body: JSON.stringify({
	// 			api_key: 'phc_mbelib77jLLIGafTlfFiDTZSKmWbkI7kjxCNybo3NvB',
	// 			distinct_id: email,
	// 		}),
	// 	})
	// 		.then(parseJSON)
	// 		.then((res) => {
	// 			const result = nullSafeGetOrElse('json.featureFlags', res, {});
	// 			resolve(result);
	// 		})
	// 		.catch((err) => {
	// 			resolve({});
	// 		})
	// );
};

export const InitUser = (userType, searchString = '', token?) => {
	return async (dispatch) => {
		const updateUserObj = async (userObj) => {
			if (searchString[0] === '?') {
				searchString = searchString.slice(1);
			}
			const queryParams = queryString.parse(searchString);
			if (!(userObj.user && userObj.contact)) {
				// Case : now we are reading all info from localstorage, but we are adding 2 new properties in the localstorage this happens post login/signup calls.
				// for users who will get this code and are already logged in , wont have these new props in localstorage. so lets call checkUserTypeToken, which will set these new properties in localstorage for next refresh
				dispatch(checkUserTypeToken(userType)());
			} else if (
				queryParams.workspaceId &&
				getActiveWorkspace(userObj) !== parseInt(queryParams.workspaceId)
			) {
				dispatch(changeWorkspace(parseInt(queryParams.workspaceId), userType));
			} else {
				dispatch(companyConfigRestCrudThunks(userType, userObj.contact));
				const featureFlags = await getPosthogFeatureFlags(nullSafeGet('contact.email', userObj));
				dispatch(
					checkTokenSuccess(
						userObj.token,
						userObj.expiresOn,
						userObj.accountActivated,
						{ ...userObj.user, ...userObj.contact },
						userType,
						userObj.workspaceId || getActiveWorkspace(userObj),
						featureFlags,
						userObj.isActive,
						userObj.isApproved
					)
				);
				// register authenticated user with Intercom messenger
				/* tslint:disable */

				// ThirdPartyInit
				ThirdPartyService.boot({ user: userObj });
			}
		};
		try {
			const userObj: any = await getUserObj(userType, token);
			storeCachedUserDetails({ ...userObj, workspaceId: getActiveWorkspace(userObj) }, userType);
			await updateUserObj(userObj);
		} catch (error) {
			updateUserObj(
				retrieveCachedUserDetails([
					'token',
					'contact',
					'accountActivated',
					'user',
					'workspaceId',
					'isActive',
					'isApproved',
				])
			);
		}
	};
};

// this action refreshes UserToken and userCompanyAssociation

export const getUserObj = (userType, token?) => {
	return new Promise((resolve, reject) => {
		function doCheck(role: string) {
			return fetch(BASE_URL + getUserObjUrl(role), {
				headers: { 'X-Auth-Token': token || retrieveCachedUserDetails(['token']).token },
				method: 'GET',
			});
		}

		doCheck(userType)
			.then(parseJSON)
			.then((response) => {
				if (!response.ok) {
					reject((response && response.statusText) || 'Something wrong happened, try again');
				} else {
					resolve(response.json);
				}
			})
			.catch((errs) => {
				reject(errs);
			});
	});
};

export const loginViaAuth0 = (userType, token, claims) => {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			function doCheck(role: string) {
				return fetch(BASE_URL + getAuth0JwtValidateURL(role), {
					headers: { Authorization: `Bearer ${token}` },
					body: JSON.stringify({ claims }),
					method: 'POST',
				});
			}

			doCheck(userType)
				.then(parseJSON)
				.then((response) => {
					if (!response.ok) {
						reject((response && response.statusText) || 'Something wrong happened, try again');
					} else {
						resolve({ response });
						dispatch(InitUser(userType, '', response.json.token));
					}
				})
				.catch((errs) => {
					reject(errs);
				});
		});
};

export const checkUserTypeToken = (userType) => () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			function doCheck(role: string) {
				return fetch(BASE_URL + getAuthRenewUrl(role), {
					headers: { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token },
					method: 'POST',
				});
			}

			doCheck(userType)
				.then(parseJSON)
				.then(async (response) => {
					if (!response.ok) {
						dispatch(
							checkTokenFailure([
								(response && response.statusText) || 'Something wrong happened, try again',
							])
						);
						reject((response && response.statusText) || 'Something wrong happened, try again');
					} else {
						const json = response.json;
						const activeWorkspaceId = getActiveWorkspace(json);
						storeCachedUserDetails({ ...json, workspaceId: activeWorkspaceId }, userType);
						dispatch(companyConfigRestCrudThunks(userType, json.contact));
						const featureFlags = await getPosthogFeatureFlags(nullSafeGet('contact.email', json));
						dispatch(
							checkTokenSuccess(
								json.token,
								json.expiresOn,
								json.accountActivated,
								{ ...json.user, ...json.contact },
								userType,
								activeWorkspaceId,
								featureFlags,
								json.isActive,
								json.isApproved
							)
						);
						// register authenticated user with Intercom messenger
						/* tslint:disable */
						ThirdPartyService.boot({ user: json });
						/* tslint:enable */
						resolve(response);
					}
				})
				.catch((errs) => {
					const message = errs instanceof Error ? errs.message : errs;
					dispatch(
						checkTokenFailure([
							typeof message !== 'string' ? 'Something wrong happened, try again' : message,
						])
					);
					reject(errs);
				});
		});
	};
};

export const logIn = (userType) => (username, password) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(loggingIn());

			fetch(BASE_URL + getLoginUrl(userType), {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					email: username && username.trim(),
					password: password,
				}),
			})
				.then(parseJSON)
				.then(async ({ json, status, ok }) => {
					if (!ok) {
						if (json.message) {
							dispatch(loginFailure([json.message]));
						} else {
							dispatch(loginFailure(['Invalid username or password. Please try again.']));
						}
					} else {
						const activeWorkspaceId = getActiveWorkspace(json);
						storeCachedUserDetails({ ...json, workspaceId: activeWorkspaceId }, userType);
						dispatch(companyConfigRestCrudThunks(userType, json.contact));

						const featureFlags = await getPosthogFeatureFlags(nullSafeGet('contact.email', json));
						dispatch(
							loginSuccess(
								json.token,
								json.expiresOn,
								json.accountActivated,
								{ ...json.user, ...json.contact },
								userType,
								activeWorkspaceId,
								featureFlags,
								json.isActive,
								json.isApproved
							)
						);
						resolve({ ...json.user, ...json.contact });

						ThirdPartyService.boot({ user: json });
					}

					dispatch(doneLoggingIn());
				})
				.catch((errs) => {
					dispatch(loginFailure(['Invalid username or password. Please try again.']));
					dispatch(doneLoggingIn());
					reject(errs);
				});
		});
	};
};

export const signUpCompletion = (userType) => () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(signUpCompleting());

			fetch(BASE_URL + getSignUpCompletionUrl(userType), {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'X-Auth-Token': retrieveCachedUserDetails(['token']).token,
				},
			})
				.then((response) => {
					if (!response.ok) {
						reject(response);
						throw Error((response && response.statusText) || 'Something wrong happened, try again');
					}
					return response;
				})
				.then((response) => response.json())
				.then((response) => {
					dispatch(signUpCompletionSuccess(response.token));
					resolve(response.token);
				})
				.catch((errs) => {
					dispatch(
						signUpCompletionFailure([
							'An error occurred while validating your email. Please try again.',
						])
					);
					reject(errs);
				});
		});
	};
};

export const changePassword = (userType) => (currentPassword, newPassword) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(changingPassword());

			fetch(BASE_URL + getChangePasswordUrl(userType), {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-Auth-Token': retrieveCachedUserDetails(['token']).token,
				},
				body: JSON.stringify({
					currentPassword,
					newPassword,
				}),
			})
				.then(parseJSON)
				.then(async ({ json, status, ok }) => {
					if (!ok) {
						if (json.message) {
							dispatch(passwordChangeFailure([json.message]));
						} else {
							dispatch(passwordChangeFailure(['An error occurred while changing your password.']));
						}
						dispatch(doneChangingPassword());
					} else {
						dispatch(passwordChangeSuccess(json.token));
						dispatch(doneChangingPassword());
						resolve(json);
					}
				})
				.catch((errs) => {
					dispatch(passwordChangeFailure(['An error occurred while changing your password.']));
					dispatch(doneChangingPassword());
					reject(errs);
				});
		});
	};
};

export const resetPassword = (userType) => (newPassword, token) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(changingPassword());

			fetch(BASE_URL + getResetPasswordUrl(userType), {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-Auth-Token': retrieveCachedUserDetails(['token']).token || token,
				},
				body: JSON.stringify({
					newPassword,
				}),
			})
				.then((response) => {
					if (!response.ok) {
						message.error('Could not update password! Make sure to use the most recent reset link');
						reject((response && response.statusText) || 'Something wrong happened, try again');
					}
					return response;
				})
				.then((response) => response.json())
				.then((response) => {
					dispatch(passwordChangeSuccess(response.token));
					dispatch(doneChangingPassword());
					resolve(response);
				})
				.catch((errs) => {
					dispatch(
						passwordChangeFailure([
							'An error occurred while resetting your password. Please try again.',
						])
					);
					dispatch(doneChangingPassword());
					reject(errs);
				});
		});
	};
};

export const requestPasswordReset = (userType) => (username) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(requestingPasswordReset());

			fetch(BASE_URL + getRequestPasswordResetUrl(userType), {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					email: username && username.trim(),
				}),
			})
				.then(parseJSON)
				.then((response) => {
					if (!response.ok) {
						reject(response.json.message);
						dispatch(requestPasswordResetFailure([response.json.message]));
					} else {
						dispatch(requestPasswordResetSuccess());
						resolve(response);
					}
				})
				.catch((errs) => {
					dispatch(requestPasswordResetFailure([errs]));
					reject(errs);
				});
		});
	};
};

export const resendConfirmation = (userType) => () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(resendConfirmationMailStart());

			fetch(BASE_URL + getResendConfirmationUrl(userType), {
				method: 'POST',
				headers: {
					'X-Auth-Token': retrieveCachedUserDetails(['token']).token,
				},
			})
				.then(parseJSON)
				.then((response) => {
					if (!response.ok) {
						reject(response.json.message);
						dispatch(resendConfirmationMailFailure([response.json.message]));
					} else {
						dispatch(resendConfirmationMailSuccess());
						resolve(response);
					}
				})
				.catch((errs) => {
					dispatch(resendConfirmationMailFailure([errs]));
					reject(errs);
				});
		});
	};
};

export const logOut = (userType) => () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(loggingOut());

			fetch(BASE_URL + getLogOutUrl(userType), {
				method: 'POST',
				headers: {
					'X-Auth-Token': retrieveCachedUserDetails(['token']).token,
				},
			})
				.then((response) => {
					if (!response.ok) {
						throw Error((response && response.statusText) || 'Something wrong happened, try again');
					}

					clearCachedUserDetails();

					// shutdown intercom to clear user session, then reboot it
					IntercomService.reboot();

					dispatch(logoutSuccess());
					dispatch(doneLoggingOut());
					resolve(response);
				})
				.catch((errs) => {
					dispatch(logoutFailure([errs]));
					dispatch(doneLoggingOut());
					reject(errs);
				});
		});
	};
};

export const signUp =
	(userType) => (username, password, nameGiven, nameFamily, gender, roles, facilityId) => {
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(signingUp());

				fetch(BASE_URL + getSignUpUrl(userType), {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						email: username && username.trim(),
						password,
						nameGiven,
						nameFamily,
						gender,
						roles,
						facilityId,
					}),
				})
					.then(parseJSON)
					.then(async (response) => {
						if (!response.ok) {
							throw Error(
								(response && response.statusText) || 'Something wrong happened, try again'
							);
						} else {
							// workspace needs to be handled here.
							const activeWorkspaceId = getActiveWorkspace(response.json);
							storeCachedUserDetails({ ...response.json }, userType);
							const featureFlags = await getPosthogFeatureFlags(
								nullSafeGet('json.contact.email', response)
							);
							dispatch(
								loginSuccess(
									response.json.token,
									response.json.expiresOn,
									response.json.accountActivated,
									{ ...response.json.user, ...response.json.contact },
									userType,
									activeWorkspaceId,
									featureFlags,
									response.json.isActive,
									response.json.isApproved
								)
							);
							dispatch(doneSigningUp());
							resolve({
								...response.json.user,
								...response.json.contact,
								email: username && username.trim(),
								nameGiven,
								nameFamily,
							});

							ThirdPartyService.boot({ user: response.json });
						}
					})
					.catch((errs) => {
						dispatch(
							signupFailure([
								'Your account already exists but the password is invalid. Please try again.',
							])
						);
						dispatch(doneSigningUp());
						reject(errs);
					});
			});
		};
	};

export const activateWorkspace = (workspaceId) => (dispatch) => {
	// this action just changes workspace in store - assuming backend has been synced already.
	dispatch(switchingWorkspace());
	setTimeout(() => {
		dispatch(switchingWorkspaceSuccess(workspaceId));
		window.location.reload();
		// dispatch(switchingWorkspaceFailure({}));
	}, 2000);
};
export const initiateChangeWorkspace = (workspaceId) => ({
	type: 'INITIATE_WORKSPACE_CHANGE',
	id: workspaceId,
});

export const cancelChangeWorkspace = (workspaceId) => ({
	type: 'CANCEL_WORKSPACE_CHANGE',
});

export const changeWorkspace = (facilityId, userType, landingURL?) => (dispatch, getState) => {
	// this changes workspace in backend
	const activeWorkspaceIdInStore = getState().session.workspaceId;
	if (activeWorkspaceIdInStore !== facilityId) {
		dispatch(switchingWorkspace());

		return new Promise((resolve, reject) => {
			const baseUrl = getBackendUri();

			const apiUrl = `/api/v1/${userType}/${userType}_contacts/change_current_facility/${facilityId}`;
			fetchUtils
				.post(
					baseUrl + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(switchingWorkspaceFailure({}));
						reject(d.message);
					} else {
						updateActiveWorkspaceinCache(facilityId, d.data);
						dispatch(switchingWorkspaceSuccess(facilityId));
						if (landingURL) {
							window.location.assign(landingURL);
						} else {
							window.location.reload();
						}
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	}
};
export const addWorkspaceToCurrentUser = (facilityId, userType) => (dispatch, getState) => {
	// this changes workspace in backend

	return new Promise((resolve, reject) => {
		const baseUrl = getBackendUri();

		const apiUrl = `/api/v1/${userType}/${userType}_contacts/add_access_to_facility/${facilityId}`;
		fetchUtils
			.post(
				baseUrl + apiUrl,
				Object.assign({
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
				})
			)
			.then(parseJSON)
			.then(({ json, status, ok }) => {
				const d = json;
				if (d.status === 'error') {
					reject(d.message);
				} else {
					resolve(d);
				}
			})
			.catch((d) => {
				reject(d.message);
			});
	});
};

export const searchWorkspaces = (search, userType, page) => (dispatch) => {
	return new Promise((resolve, reject) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${userType}/${userType}_contacts/search_facility_access`;
		fetchUtils
			.get(
				baseUrl + apiUrl,
				Object.assign(
					{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
					{ search, limit: 10, offset: page - 1 }
				)
			)
			.then(parseJSON)
			.then(({ json, status, ok }) => {
				const d = json;
				if (status === 401) {
					dispatch(logoutSuccess());
				}
				if (d.status === 'error') {
					reject(d.message);
				} else {
					resolve(d);
				}
			})
			.catch((d) => {
				reject(d.message);
			});
	});
};
export const getFacilityByCompanyID = (companyID, name, userType) => (dispatch) => {
	return new Promise((resolve, reject) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${userType}/${userType}_facilities/by_${userType}_company_name_id/${companyID}/${name} `;
		fetchUtils
			.get(
				baseUrl + apiUrl,
				Object.assign({
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
				})
			)
			.then(parseJSON)
			.then(({ json, status, ok }) => {
				const d = json;
				if (status === 401) {
					dispatch(logoutSuccess());
				}
				if (d.status === 'error') {
					reject(d.message);
				} else {
					resolve(d.data);
				}
			})
			.catch((d) => {
				reject(d.message);
			});
	});
};

export const syncWorkspace = () => (dispatch, getState) => {
	const activeWorkspaceIdInStore = getState().session.workspaceId;
	const isAuthenticated = getState().session.isAuthenticated;
	const latestActiveWorskapceId = retrieveCachedUserDetails(['workspaceId']).workspaceId;
	if (isAuthenticated && activeWorkspaceIdInStore !== latestActiveWorskapceId) {
		return dispatch(activateWorkspace(latestActiveWorskapceId));
	}
};

export const impersonationActivate = (userType, email) => (dispatch) => {
	email = email && email.trim();
	return new Promise((resolve, reject) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${userType}/get_token`;
		fetchUtils
			.post(
				baseUrl + apiUrl,

				{
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: { email },
				}
			)
			.then(parseJSON)
			.then(({ json, status, ok }) => {
				const d = json;
				if (status === 401) {
					dispatch(logoutSuccess());
				}
				if (d.status === 'error') {
					reject(d.message);
				} else {
					// response is of login ,
					// do everything what is needed after login
					activateImpersonationInCacheStorage(json, userType, email);

					resolve(d);
				}
			})
			.catch((d) => {
				reject(d.message);
			});
	});
};

export const impersonationDeactivate = (userType) => (dispatch) => {
	return new Promise((resolve) => {
		deActivateImpersonationInCacheStorage(userType);
		resolve(userType);
	});
};

import * as React from 'react';
import { Image } from 'antd';

export class ImageThumbnails extends React.Component<any> {
	state = {
		lightboxIsOpen: false,
		currentIndex: 0,
	};

	handleViewChange = (idx) => {
		this.setState({ currentIndex: idx });
	};

	openLightbox = (currentIndex = 0) => {
		this.setState({ lightboxIsOpen: true, currentIndex });
	};

	closeLightbox = () => {
		this.setState({ lightboxIsOpen: false });
	};

	render() {
		const { photoStrings = [], imageWidth, imageHeight, style = {}, ...props } = this.props;
		const photoUris = photoStrings.map((photoString) => ({ src: photoString }));
		return (
			<div className="ProtectedImageThumbnails" style={{ display: 'flex', ...style }} {...props}>
				<Image.PreviewGroup>
					{photoUris.map((photoUri, idx) => (
						<Image
							key={idx}
							src={photoUri.src}
							style={{
								marginLeft: idx === 0 ? 0 : 8,
								width: imageWidth,
								height: imageHeight,
							}}
						/>
					))}
				</Image.PreviewGroup>
			</div>
		);
	}
}

import React from 'react';
import * as BizCharts from 'bizcharts';
import { Guide } from 'bizcharts';
import DataSet from '@antv/data-set';
import { STOPLIGHT_COLORS } from '../../utils/DataFormatterUtils';

const { Chart, Coord, Axis, Geom, Tooltip, Legend, View, Label } = BizCharts;

class BudgetChart extends React.Component {
	render() {
		const { data, budgetTotal, currency, remaining, consumed, recordType } = this.props;

		console.log(data);
		const ds = new DataSet();
		const dv = ds.createView().source(data);
		dv.transform({
			type: 'fold',
			fields: [
				...(consumed ? ['Budget Used'] : []),
				`This ${recordType}`,
				...(remaining ? ['Remaining Budget'] : []),
			],
			// 展开字段集
			key: 'type',
			// key字段
			value: 'spend',
			// value字段
			retains: ['budget'], // 保留字段集，默认为除fields以外的所有字段
		});
		return (
			<Chart height={200} data={dv} forceFit>
				<Coord transpose />
				<Legend clickable={false} />
				<Tooltip />
				<Geom
					type="intervalStack"
					position="budget*spend"
					color={['type', [...(consumed ? ['#53298e'] : []), STOPLIGHT_COLORS.pureRed, '#b7b7b7']]}
					tooltip={[
						'budget*spend*type',
						(budget, spend, type) => {
							return {
								name: type,
								title: budget,
								value: currency.format(spend),
							};
						},
					]}
				>
					<Label
						content="spend"
						offset={0}
						position={'middle'}
						htmlTemplate={(text, item, index) => {
							return `<span style="color: white">${
								((parseFloat(text) / budgetTotal) * 100).toFixed(0) + '%'
							}</span>`;
						}}
					/>
				</Geom>
				<Guide>
					<Guide.Line
						top={true} // 指定 guide 是否绘制在 canvas 最上层，默认为 false, 即绘制在最下层
						start={[1, budgetTotal]} // 辅助线起始位置，值为原始数据值，支持 callback
						end={[-1, budgetTotal]} // 辅助线结束位置，值为原始数据值，支持 callback
						lineStyle={{
							stroke: '#999', // 线的颜色
							lineDash: [0, 2, 2], // 虚线的设置
							lineWidth: 1, // 线的宽度
						}} // 图形样式配置
						text={{
							position: 'start', // 文本的显示位置
							autoRotate: false, // 是否沿线的角度排布，默认为 true
							content: 'Budget', // 文本的内容
							offsetX: -24, // x 方向的偏移量
							offsetY: -4, // y 方向的偏移量
						}}
					/>
				</Guide>
			</Chart>
		);
	}
}

export default BudgetChart;

//Pipeline Filters
import { BLACKS_AND_WHITES, STOPLIGHT_COLORS } from '../../../../utils/DataFormatterUtils';

const DRAFT_INVOICES = 'Draft';
const PENDING_INVOICES = 'Pending';
const DISPUTED_INVOICES = 'Disputed';
const APPROVED_INVOICES = 'Approved';
const PROCESSING_INVOICES = 'Processing';
const PAID_INVOICES = 'Paid';
const DRAFT_FILTER = { status: DRAFT_INVOICES };
const PENDING_FILTER = { status: PENDING_INVOICES };
const DISPUTED_FILTER = { status: DISPUTED_INVOICES };
const APPROVED_INVOICES_FILTER = { status: APPROVED_INVOICES };
const PROCESSING_FILTER = { status: PROCESSING_INVOICES };
const PAID_FILTER = { status: PAID_INVOICES };

export const pipelineFilters = [
	{
		displayName: 'Pending',
		filter: PENDING_FILTER,
		name: PENDING_INVOICES,
		icon: 'icons8-edit',
		iconStyle: { color: '#4a1e87' },
	},
	{
		displayName: 'Disputed',
		filter: DISPUTED_FILTER,
		name: DISPUTED_INVOICES,
		icon: 'icons8-cancel',
		iconStyle: { color: STOPLIGHT_COLORS.pureRed },
	},
	{
		displayName: 'Approved',
		filter: APPROVED_INVOICES_FILTER,
		name: APPROVED_INVOICES,
		icon: 'icons8-approve',
		iconStyle: { color: STOPLIGHT_COLORS.pureYellow },
	},
	{
		displayName: 'Processing',
		filter: PROCESSING_FILTER,
		name: PROCESSING_INVOICES,
		icon: 'icons8-clock-filled',
		iconStyle: { color: STOPLIGHT_COLORS.yellowGreen },
	},
	{
		displayName: 'Paid',
		filter: PAID_FILTER,
		name: PAID_INVOICES,
		icon: 'icons8-double-tick-filled',
		iconStyle: { color: STOPLIGHT_COLORS.pureGreen },
	},
];

export const SuplierPipelinefilters = [
	{
		displayName: 'Draft',
		filter: DRAFT_FILTER,
		name: 'Draft',
		icon: 'icons8-edit',
		iconStyle: { color: '#4a1e87' },
	},
	...pipelineFilters,
];

export const CLEAR_STATUS_FILTER = { displayStatus: undefined };
export const CLEAR_DISPLAY_STATUS_FILTER = { status: undefined };

import * as React from 'react';
import { Layout, Button, message, Popover } from 'antd';
import { Document, Page } from 'react-pdf';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import {
	downloadInvoicePDFForSupplier,
	invoicesRestCrudThunksForSupplier,
	markInvoiceDraftForSupplier,
	markInvoicePaidForSupplier,
	publishInvoiceForSupplier,
} from '../../thunks/invoices_thunks';
import { getEntityById, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { EmptyState } from '../empty_state/EmptyState';
import { Link } from 'react-router-dom';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import {
	appendWorkOrderNotesForSupplier,
	workOrdersRestCrudThunksForSupplier,
} from '../../thunks/work_orders_thunks';
import { WORK_ORDER_STATUSES } from '../../constants/WorkOrderStatuses';
import { SUPER_ADMIN, SUPPLIER_ADMIN } from '../roles/roles';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import InvoicePageDisplay from '../invoice_page_display/InvoicePageDisplay';

const { Content } = Layout;

const style = require('./SupplierWorkOrdersDetailInvoicesPage.less');

class SupplierWorkOrderInvoicesDetailPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			generatingPDF: false,
			pdfFile: { url: null, httpHeaders: null },
			isGreaterThanNTE: false,
			invoiceExists: false,
		};
	}

	refreshInvoice = () => {
		const { getInvoices, match } = this.props;
		const workOrderId = match.params.id;
		getInvoices({ workOrderId }, 'workOrderAssociatedInvoices');
	};

	componentDidMount() {
		const { getInvoices, match, getCount } = this.props;
		const workOrderId = match.params.id;
		getInvoices({ workOrderId }, 'workOrderAssociatedInvoices');
		getCount(workOrderId, 'draft').then((d) => this.setState({ invoiceExists: d > 0 }));
	}

	handleFileAttachmentUploadChange = (invoice) => (newAttachments) => {
		const { updateInvoice } = this.props;
		const newInvoice = {
			...invoice,
			attachments: newAttachments,
		};
		updateInvoice(newInvoice);
	};

	onDocumentLoadSuccess = ({ numPages }) => {
		this.setState({ numPages });
	};

	render() {
		const { workOrder, invoices, invoicesFetching, currentUser, match } = this.props;
		const workOrderId = match.params.id;
		const isInternalTech = nullSafeGetOrElse('facility.status', currentUser, '') === 'private';

		const supplierFacilityId = nullSafeGet('facility.id', currentUser);

		const associatedInvoices = getRecordsForTargetCollection(
			invoices,
			'workOrderAssociatedInvoices'
		);

		const disableSimpleInvoiceEntry = nullSafeGetOrElse(
			'buyerCompany.buyerCompanySettings.config.suppliersConfig.disableSimpleInvoiceEntry',
			workOrder,
			false
		);

		const isEmptyState =
			associatedInvoices.some((invoice) => invoice.status === 'draft') && isInternalTech;

		if (isEmptyState) {
			return (
				<EmptyState
					graphic={
						<img
							style={{ marginBottom: 8 }}
							src="https://s3.amazonaws.com/mock-data-assets/categories/images/bank terminal.svg"
							alt=""
						/>
					}
					headline={
						!isInternalTech
							? "You haven't billed for this work order yet."
							: "You haven't been billed for this work order yet."
					}
					body={
						!isInternalTech ? (
							<div style={{ textAlign: 'center' }}>
								<Link
									to={`/supplier/workOrders/detail/${workOrderId}/invoices/new`}
									style={{ marginRight: 8 }}
								>
									<Button type="primary">Create invoice</Button>
								</Link>
								{!disableSimpleInvoiceEntry && (
									<Link
										to={`/supplier/workOrders/detail/${workOrderId}/invoices/createSimple`}
										style={{ marginRight: 8 }}
									>
										<Button>Upload invoice PDF</Button>
									</Link>
								)}
							</div>
						) : null
					}
				/>
			);
		}

		return invoicesFetching ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="invoicesDetailDetailsPage">
				<LogOnMountWithStandardEventProperties eventType="visited supplier work order invoices page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				{associatedInvoices.length > 0 ? (
					<div>
						{this.state.pdfFile.url ? (
							<div>
								<Document file={this.state.pdfFile} onLoadSuccess={this.onDocumentLoadSuccess}>
									{Array.from(new Array(this.state.numPages), (el, index) => (
										<Page width={1100} key={`page_${index + 1}`} pageNumber={index + 1} />
									))}
								</Document>
								<p>
									Page {this.state.pageNumber} of {this.state.numPages}
								</p>
							</div>
						) : null}
						{associatedInvoices.map((invoice) => (
							<InvoicePageDisplay
								invoice={invoice}
								refreshInvoice={this.refreshInvoice}
								showViewInvoicePage={true}
							/>
						))}
					</div>
				) : (
					<EmptyState
						graphic={
							<img
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/bank terminal.svg"
								alt=""
							/>
						}
						headline={
							!isInternalTech
								? "You haven't billed for this work order yet."
								: "You haven't been billed for this work order yet."
						}
						body={
							!isInternalTech ||
							(nullSafeGet('supplierFacilityId', workOrder) &&
								supplierFacilityId !== workOrder.supplierFacilityId) ? (
								this.state.invoiceExists ? (
									<span>
										Your service provider has drafted an invoice, it just hasn't been published yet.
									</span>
								) : (
									<AccessPermissionChecker
										name={PERMISSION_NAMES.CAN_UPLOAD_INVOICES_FOR_SUPPLIER}
										exclude3rdPartySuppliers
									>
										<div style={{ textAlign: 'center' }}>
											<Link
												to={`/supplier/workOrders/detail/${workOrderId}/invoices/${
													isInternalTech ? 'newForSupplier' : 'new'
												}`}
												style={{ marginRight: 8 }}
											>
												<Button type="primary">Create invoice</Button>
											</Link>
											{disableSimpleInvoiceEntry ? (
												<Popover
													content={`${nullSafeGet(
														'buyerCompany.displayName',
														workOrder
													)} has disabled the simple invoice entry`}
												>
													<Button disabled={true}>Upload invoice PDF</Button>
												</Popover>
											) : (
												<Link
													to={`/supplier/workOrders/detail/${workOrderId}/invoices/createSimple`}
													style={{ marginRight: 8 }}
												>
													<Button>Upload invoice PDF</Button>
												</Link>
											)}
										</div>
									</AccessPermissionChecker>
								)
							) : null
						}
					/>
				)}
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	workOrdersFetching: state.work_orders.fetching,
	workOrder: getEntityById(ownProps.match.params.id, state.work_orders),
	invoicesFetching: state.invoices.fetching,
	invoices: state.invoices,
	invoicesCreating: state.invoices.creating,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	getWorkOrder: (id) => dispatch(workOrdersRestCrudThunksForSupplier.readOne(id)),
	getInvoices: (params, targetCollectionName) =>
		dispatch(invoicesRestCrudThunksForSupplier.read(params, targetCollectionName)),
	updateInvoice: (entity) => dispatch(invoicesRestCrudThunksForSupplier.update(entity)),
	publishInvoice: (id) => dispatch(publishInvoiceForSupplier({ id })),
	getCount: (workOrderId, status) =>
		dispatch(invoicesRestCrudThunksForSupplier.countBy({ workOrderId, status }, status)),
	appendWorkOrderNotes: (entity) => dispatch(appendWorkOrderNotesForSupplier(entity)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SupplierWorkOrderInvoicesDetailPage)
);

import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Alert, Button } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { supplierFacilitiesRestCrudThunksForSupplier } from '../../thunks/supplier_facilities_thunks';
import { SOCIAL_MEDIA_TYPES } from '../../constants/social_media';
import { capitalize } from '../../utils/DataFormatterUtils';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';

const style = require('./EditSupplierSocialMediaForm.less');
const FormItem = Form.Item;

interface EditSupplierSocialMediaFormProps extends FormComponentProps {
	loading: boolean;
	socialMedia: string;
	updateSupplier: any;
	successCallback: any;
	cancelCallback: any;
	supplierFacility: any;

	errors: string[];
}

class EditSupplierSocialMediaForm extends React.Component<EditSupplierSocialMediaFormProps, any> {
	handleSubmit = (e) => {
		const { updateSupplier, successCallback, supplierFacility, form } = this.props;
		e.preventDefault();
		form.validateFields((err, values) => {
			if (!err) {
				const updatedSupplier = {
					...supplierFacility,
					socialMedia: SOCIAL_MEDIA_TYPES.reduce((acc, socialMediaType) => {
						acc[socialMediaType.key] = values[socialMediaType.key];
						return acc;
					}, {}),
				};
				updateSupplier(updatedSupplier).then((resp) => successCallback(resp));
			}
		});
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { loading, errors, cancelCallback, socialMedia } = this.props;
		return (
			<Form layout="vertical" onSubmit={this.handleSubmit} className="editSupplierSocialMediaForm">
				{errors && errors.length > 0
					? errors.map((msg, idx) => (
							<FormItem key={idx}>
								<Alert message={msg} type="error" />
							</FormItem>
					  ))
					: null}
				{SOCIAL_MEDIA_TYPES.map((socialMediaType) => (
					<FormItem label={`${capitalize(socialMediaType.type)} handle`}>
						{getFieldDecorator(socialMediaType.key, {
							initialValue: nullSafeGetOrElse(socialMediaType.key, socialMedia, ''),
						})(<Input placeholder={`Enter your ${capitalize(socialMediaType.type)} handle.`} />)}
					</FormItem>
				))}
				<FormItem>
					<Button type="primary" htmlType="submit" loading={loading}>
						Update social media links
					</Button>
					<Button style={{ marginLeft: 16 }} onClick={cancelCallback}>
						Cancel
					</Button>
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	loading: state.supplier_facilities.loading,
	errors: state.supplier_facilities.updateErrors,
	socialMedia: ownProps.socialMedia,
	successCallback: ownProps.successCallback,
	cancelCallback: ownProps.cancelCallback,
	supplierFacility: ownProps.supplierFacility,
});

const mapDispatchToProps = (dispatch) => ({
	updateSupplier: (supplierFacility) =>
		dispatch(supplierFacilitiesRestCrudThunksForSupplier.update(supplierFacility)),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<EditSupplierSocialMediaFormProps>()(EditSupplierSocialMediaForm))
);

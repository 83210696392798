import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { workOrdersRestCrudThunksForBuyer } from '../../thunks/work_orders_thunks';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import WorkOrderPendingEditForm from '../work_order_pending_edit_form/WorkOrderPendingEditForm';

const style = require('./PendingEditWorkOrderPage.less');

class PendingEditWorkOrderPage extends React.Component<any, any> {
	componentDidMount() {
		const { getWorkOrder, match } = this.props;
		const workOrderId = match.params.id;
		getWorkOrder(workOrderId);
	}

	render() {
		const { history, workOrder, fetching } = this.props;

		return [
			<LogOnMountWithStandardEventProperties eventType="visited buyer pending edit work order page" />,
			<EditPage
				title="Edit Work Order"
				formComponentProps={{
					formData: workOrder,
					isApprovalForm: false,
					onSuccess: (rec) => history.push(`/buyer/workOrders/detail/${rec.id}`),
				}}
				fetching={fetching}
				FormComponent={WorkOrderPendingEditForm}
			/>,
		];
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		history: ownProps.history,
		workOrder: state.work_orders.detail,
		fetching: state.work_orders.fetching,
		match: ownProps.match,
	};
};

const mapDispatchToProps = (dispatch) => ({
	getWorkOrder: (id) => dispatch(workOrdersRestCrudThunksForBuyer.readOne(id)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(PendingEditWorkOrderPage)
);

import {
	crudActionCreatorsGenerator,
	searchActionCreatorsGenerator,
} from './crud_action_creators_generator';
import {
	crudActionConstantsGenerator,
	searchConstantsGenerator,
} from './crud_action_constants_generator';

export const PLANNED_MAINTENANCE_SEARCH_ACTION_CONSTANTS = searchConstantsGenerator(
	'PLANNED_MAINTENANCE_SCHEDULES_GROUPED'
);
export const PLANNED_MAINTENANCE_SEARCH_ACTION_CREATORS = searchActionCreatorsGenerator(
	PLANNED_MAINTENANCE_SEARCH_ACTION_CONSTANTS
);
export const PLANNED_MAINTENANCE_SCHEDULE_GROUPS_CRUD_ACTION_CONSTANTS =
	crudActionConstantsGenerator('PLANNED_MAINTENANCE_SCHEDULES_GROUPED');
export const PLANNED_MAINTENANCE_SCHEDULE_GROUPS_CRUD_ACTION_CREATORS = crudActionCreatorsGenerator(
	PLANNED_MAINTENANCE_SCHEDULE_GROUPS_CRUD_ACTION_CONSTANTS
);
export const PLANNED_MAINTENANCE_SCHEDULES_FETCH_GROUPED_SUCCESS =
	'PLANNED_MAINTENANCE_SCHEDULES_FETCH_GROUPED_SUCCESS';
export const plannedMaintenanceSchedulesFetchGroupedSuccess = (
	records,
	count,
	targetCollectionName,
	pagination,
	sorting,
	filters
) => {
	return {
		count,
		records,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		type: PLANNED_MAINTENANCE_SCHEDULES_FETCH_GROUPED_SUCCESS,
	};
};

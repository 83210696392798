import * as React from 'react';
import { getObjectValues, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { Button, Col, Dropdown, Menu, Modal, Pagination, Row, Select, Table, Tooltip } from 'antd';
import default_columns_enum from './ColumnsEnum';
import { isEqual, union } from 'lodash';
import TableSelectComponent from './TableSelectComponent';
import FilterDropdown from './FilterDropdown';
import { FilterFilled, FilterOutlined, SearchOutlined } from '@ant-design/icons';

const style = require('./TableWrapperComponent.less');

export default class TableWrapperComponent extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			columns: props.columns,
			filteredDataSource: props.dataSource,
			filters: {},
			filterText: {},
			active: {},
			selectedRowKeys: [],
			records: [],
			pagination: props.pagination,
			menu: {},

			showModal: false,
			modalHeader: null,
			selectedTitle: null,

			buttonLoading: false,
		};
		this.setFilters = this.setFilters.bind(this);
		this.handleFilterTextChange = this.handleFilterTextChange.bind(this);
		this.selectNone = this.selectNone.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
	}
	componentWillReceiveProps(nextProps) {
		const { active, filteredDataSource, selectedRowKeys } = this.state;
		if (
			!nextProps.columns.every((col, i) =>
				isEqual(col.props.update, this.props.columns[i].props.update)
			)
		) {
			let columns = this.getDefaultColumns(nextProps.columns, filteredDataSource);
			let cols = this.getColumnFilters(columns, filteredDataSource);
			cols.map((col) => {
				let activeFilters = nullSafeGetOrElse(col.title, { ...active }, {});
				if (activeFilters) {
					col.props.active = activeFilters;
					col.filterDropdownVisible = false;
				}
			});
			let menu = this.setupMenu(columns);
			this.setState({ columns: cols, menu: menu });
		}
		if (!isEqual(this.props.dataSource, nextProps.dataSource)) {
			const { columns } = this.state;
			let filteredData = this.filterDataSource(nextProps.dataSource, columns);
			let cols = this.getColumnFilters(columns, filteredData);
			let records = this.setupRecords(nextProps.dataSource, selectedRowKeys);
			this.setState({ columns: cols, filteredDataSource: filteredData, records: records });
		}
	}

	componentDidMount() {
		const { dataSource } = this.props;
		const { columns, filteredDataSource } = this.state;
		let records = this.setupRecords(dataSource, []); //Gets list of selected records
		let cols = this.getDefaultColumns(columns, filteredDataSource);
		cols = this.getColumnFilters(cols, filteredDataSource);
		let menu = this.setupMenu(columns);
		this.setState({ columns: cols, records: records, menu: menu });
	}
	onChange(pagination, filters, sorter, extra: { currentDataSource; action }) {}
	clearFilters() {
		const { dataSource } = this.props;
		const { columns } = this.state;
		let cols = this.getDefaultColumns(columns, dataSource);
		cols = this.getColumnFilters(cols, dataSource);
		this.setState({ columns: cols, filteredDataSource: dataSource, active: {} });
	}
	clearAll() {
		const { dataSource } = this.props;
		const { columns } = this.state;
		let cols = this.getDefaultColumns(columns, dataSource);
		cols = this.getColumnFilters(cols, dataSource);
		this.setState({
			columns: cols,
			filteredDataSource: dataSource,
			active: {},
			selectedRowKeys: [],
			records: [],
		});
	}
	setFilters(title, activeFilters, visible) {
		const { dataSource } = this.props;
		const { active, columns } = this.state;
		let a = { ...active };
		a[title] = activeFilters;
		let cols = columns.map((col) => {
			col.props.active = nullSafeGetOrElse(col.title, a, {});
			col.filterDropdownVisible = col.title === title ? visible : false;
			return col;
		});

		let filteredData = this.filterDataSource(dataSource, cols);
		cols = this.getColumnFilters(cols, filteredData);
		this.setState({ filteredDataSource: filteredData, columns: cols, active: a });
	}
	filterDataSource(dataSource, cols) {
		let filteredData = dataSource.filter((record) => {
			return cols.every((col) => {
				return Object.keys(col.props.active).length === 0
					? true
					: getObjectValues(col.props.active).some((filter) => {
							return filter.active ? filter.onFilter(filter.value, record) : true;
					  });
			});
		});

		return filteredData;
	}
	setupMenu(columns) {
		let self = this;
		const defaultMenuItems = [
			{
				title: 'Dispatcher',
				subTitle: 'Update Dispatcher',
				methodType: 'update',
				column: columns.find((c) => c.dataIndex === 'dispatcher'),
			},
			{
				title: 'Primary Tech',
				subTitle: 'Update Primary Tech',
				methodType: 'update',
				column: columns.find((c) => c.dataIndex === 'primaryTech'),
			},
			{
				title: 'Add CCs',
				subTitle: 'Add CCs',
				methodType: 'add',
				column: columns.find((c) => c.dataIndex === 'ccList'),
			},
			{
				title: 'Remove CCs',
				subTitle: 'Remove CCs',
				methodType: 'remove',
				column: columns.find((c) => c.dataIndex === 'ccList'),
			},
		];
		/*
		let items = columns
			.filter((column) => nullSafeGet('props.update.canUpdate', column))
			.map((column, i) => {
				return {
					key: i,
					label: column.title,
				};
			});
			*/
		let items = defaultMenuItems
			.filter((item) => nullSafeGet('column.props.update.canUpdate', item))
			.map((item, i) => {
				return {
					key: i,
					label: nullSafeGet('title', item),
					subTitle: nullSafeGet('subTitle', item),
					dataIndex: nullSafeGet('column.dataIndex', item),
					methodType: nullSafeGet('methodType', item),
				};
			});
		return (
			<Menu
				items={items}
				placement={'bottomLeft'}
				onClick={({ item, key, keyPath, event }) => {
					self.openUpdateModal(items[key]);
				}}
				style={{ margin: 0, padding: 0 }}
			></Menu>
		);
	}

	openUpdateModal = (item) => {
		const { records, selectedRowKeys, columns } = this.state;

		let ModalHeader = (
			<div>
				{item.subTitle} for {nullSafeGetOrElse('length', selectedRowKeys, ' 0 ')} records
			</div>
		);
		this.setState({
			showModal: true,
			modalHeader: ModalHeader,
			methodType: item.methodType,
			selectedTitle: item.label,
			selectType: item.type,
			dataIndex: item.dataIndex,
		});
	};

	getDefaultColumns(columns, filteredDataSource) {
		columns
			.filter((column) => nullSafeGetOrElse('props.useDefault', column, false))
			.map((column) => {
				if (Object.keys(default_columns_enum).includes(column.title)) {
					let defaultCol = default_columns_enum[column.title];
					column['props'] = { ...column.props, ...defaultCol };
					if (!column.hasOwnProperty('filters')) {
						column.filters = [];
					}
					if (
						column.props.hasOwnProperty('filterSearch') &&
						!column.hasOwnProperty('filterSearch')
					) {
						column.filterSearch = column.props.filterSearch;
					}
				}
				column.props.active = {};
			});

		return columns;
	}
	handleFilterTextChange(title, text) {
		const { filterText } = this.state;
		let newText = { ...filterText };
		newText[title] = text.toLowerCase();
		this.setState({ filterText: newText });
	}
	getColumnFilters(columns, filteredDataSource) {
		const { active, filterText } = this.state;
		const { setFilters, handleFilterTextChange } = this;
		columns = columns.map((col) => {
			let activeFilters = nullSafeGetOrElse('props.active', col, {});
			let filterDropdown = (
				<FilterDropdown
					key={col.title.concat('-FilterDropdown')}
					identifier={col.title.concat('-FilterDropdown')}
					column={col}
					filterText={nullSafeGetOrElse(col.title, filterText, '')}
					handleFilterText={handleFilterTextChange}
					filteredDataSource={filteredDataSource}
					setFilters={setFilters}
					activeFilters={activeFilters}
					syncActive={nullSafeGetOrElse('filterDropdownVisible', col, false)}
				/>
			);
			return {
				...col,
				key: col.title.concat('-Column'),
				filterDropdown: filterDropdown,
				onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange.bind(this, col),
				filterIcon: this.filterIcon.bind(null, col),
			};
		});
		return columns;
	}
	filterIcon = (col, filtered) => {
		return isEqual(nullSafeGetOrElse('props.active', col, {}), {}) ? (
			<FilterOutlined style={{ color: '@primary-color' }} />
		) : (
			<FilterFilled style={{ color: '@primary-color' }} />
		);
	};
	onFilterDropdownVisibleChange = (col, open) => {
		const { active, columns, filteredDataSource } = this.state;
		let a = { ...active };
		col.filterDropdownVisible = open;
		let cols = this.getColumnFilters(columns, filteredDataSource);
		this.setState({ columns: cols, active: a });
	};
	setupRecords(records, keys) {
		return records.filter((record) => keys.includes(record.key));
	}
	setSelectedRowKeys = (newSelectedRowKeys) => {
		let records = this.setupRecords(this.props.dataSource, newSelectedRowKeys);
		this.setState({ selectedRowKeys: newSelectedRowKeys, records: records });
	};
	onSelectChange = (newSelectedRowKeys) => {
		this.setSelectedRowKeys(newSelectedRowKeys);
	};
	selectAll = () => {
		const { filteredDataSource, selectedRowKeys } = this.state;
		let selectedKeys = filteredDataSource.map((record) => record.key);
		this.onSelectChange(union(selectedRowKeys, selectedKeys));
	};
	selectNone = () => {
		this.setSelectedRowKeys([]);
	};

	hideModal = () => {
		this.setState({ showModal: false });
	};

	render() {
		const { dataSource, loading } = this.props;
		const {
			selectedRowKeys,
			menu,
			showModal,
			columns,
			pagination,
			records,
			currentDataSource,
			filteredDataSource,
			active,
		} = this.state;
		const rowSelection = {
			preserveSelectedRowKeys: true,
			selectedRowKeys,
			onChange: this.onSelectChange,
			selections: [
				{
					key: 'all',
					text: 'All',
					onSelect: () => {
						let selectedKeys = filteredDataSource.map((record) => record.key);
						this.setSelectedRowKeys(union(selectedRowKeys, selectedKeys));
					},
				},
				{
					key: 'currentPage',
					text: 'Current Page',
					onSelect: (changableRowKeys) => {
						this.setSelectedRowKeys(union(selectedRowKeys, changableRowKeys));
					},
				},
				{
					key: 'none',
					text: 'None',
					onSelect: (changableRowKeys) => {
						this.setSelectedRowKeys([]);
					},
				},
			],
		};
		let cols = this.getColumnFilters(columns, filteredDataSource);
		let disabled = nullSafeGetOrElse('length', selectedRowKeys, 0) < 1;
		let filteredRowKeys = filteredDataSource.map((record) => record.key);
		let rowDifference = 0;
		filteredRowKeys.map((key) => (selectedRowKeys.includes(key) ? 0 : rowDifference++));
		let clearItems = [];
		if (nullSafeGetOrElse('length', selectedRowKeys, 0) > 0) {
			clearItems.push({ key: 'ClearSelect', label: 'Clear Selections' });
		}
		if (!isEqual(active, {}) && !getObjectValues(active).every((a) => isEqual(a, {}))) {
			clearItems.push({ key: 'ClearFilters', label: 'Clear Filters' });
		}
		return (
			<Row className={'TableWrapperComponent'}>
				<Modal
					visible={showModal}
					width={600}
					title={this.state.modalHeader}
					closable={true}
					maskClosable={true}
					footer={null}
					onCancel={this.hideModal}
					destroyOnClose={true}
				>
					<div>
						<TableSelectComponent
							methodType={this.state.methodType}
							columnTitle={this.state.selectedTitle}
							dataIndex={this.state.dataIndex}
							hideModal={this.hideModal}
							list={records}
							columns={columns}
						/>
					</div>
				</Modal>
				<Row
					style={{
						margin: '2px 0px',
						alignItems: 'center',
						gap: '12px',
					}}
				>
					<Dropdown overlay={menu} disabled={disabled}>
						{disabled ? (
							<Tooltip placement={'right'} title={'Select records that you want to update'}>
								<Button type={'primary'} disabled={disabled} style={{ alignItems: 'center' }}>
									Update
								</Button>
							</Tooltip>
						) : (
							<Button type={'primary'} style={{ alignItems: 'center' }}>
								Update
							</Button>
						)}
					</Dropdown>
					<Dropdown
						overlay={
							<Menu
								style={{ margin: 0, padding: 0 }}
								items={clearItems}
								onClick={({ item, key, keyPath, event }) => {
									if (key === 'ClearSelect') {
										this.selectNone();
									} else if (key === 'ClearFilters') {
										this.clearFilters();
									}
								}}
							/>
						}
						style={{ display: clearItems.length === 0 ? 'none' : 'flex' }}
					>
						<Button
							style={{ display: clearItems.length === 0 ? 'none' : 'flex', alignItems: 'center' }}
							onClick={this.clearAll.bind(this)}
						>
							Clear
						</Button>
					</Dropdown>
					<div
						style={{
							marginLeft: '12px',
						}}
					>
						{!loading && selectedRowKeys && nullSafeGetOrElse('length', selectedRowKeys, 0) > 0 ? (
							<div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
								<div style={{ display: 'flex', marginLeft: '-6px' }}>
									<span
										style={{
											display: 'flex',
											minWidth: '32px',
											justifyContent: 'flex-end',
											marginRight: '3.4px',
										}}
									>
										{selectedRowKeys.length}
									</span>
									<span>record{selectedRowKeys.length > 1 ? 's' : ''} selected...</span>
								</div>
								{rowDifference > 0 ? (
									<a style={{ display: 'flex' }} onClick={this.selectAll.bind(this)}>
										add {rowDifference}{' '}
										{isEqual(active, {}) || getObjectValues(active).every((a) => isEqual(a, {}))
											? ''
											: 'filtered '}
										record{rowDifference > 1 ? 's' : ''} to selection
									</a>
								) : null}
							</div>
						) : null}
					</div>
				</Row>
				<Row>
					<Table
						columns={cols}
						showHeader={nullSafeGetOrElse('showHeader', this.props, true)}
						rowSelection={rowSelection}
						loading={loading}
						dataSource={filteredDataSource}
						pagination={{ ...pagination }}
						onChange={this.onChange.bind(this)}
						tableLayout={'fixed'}
						onRow={this.props.onRow}
						rowClassName={'tableWrapperRow'}
					/>
				</Row>
			</Row>
		);
	}
}

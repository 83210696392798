import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import WorkOrderTable from '../WorkOrderTable';
import { ASSIGNED_FILTERS } from '../WorkOrderTable/config/assigned';
import { ACTIVE_WORK_ORDER_TAB_FILTERS } from '../WorkOrderTable/config/tabFilters/tabFilters';
import { SUPPLIER_TECH } from '../roles/roles';
import {
	INTERNAL_SERVICE_TEAM_WO_FILTERS_ORDER,
	THIRD_PARTY_SUPPLIER_WO_FILTERS_ORDER,
} from '../../utils/WorkOrderUtils';

function SupplierWorkOrdersActiveIndexPage({ currentUser }) {
	const isInternalTech = useMemo(
		() => nullSafeGetOrElse('facility.status', currentUser, '') === 'private',
		[currentUser]
	);

	const showMineAsInitialFilter =
		isInternalTech &&
		nullSafeGetOrElse('roles', currentUser, []).some((_) => _ === SUPPLIER_TECH) &&
		nullSafeGetOrElse(
			'facility.privateBuyerCompanySettings.config.workOrder.showOnlyAssignedWorkOrdersInDefaultTechnicianView',
			currentUser,
			false
		);
	const advancedFiltersConfig = useMemo(
		() =>
			isInternalTech
				? INTERNAL_SERVICE_TEAM_WO_FILTERS_ORDER
				: THIRD_PARTY_SUPPLIER_WO_FILTERS_ORDER,
		[isInternalTech]
	);

	let intialFilters = {};

	if (showMineAsInitialFilter) {
		intialFilters = {
			assigned: 'me',
			supplierFacilityId: nullSafeGet('facility.id', currentUser),
			fieldTechnicianEmail: nullSafeGet('email', currentUser),
			notSupplierFacilityId: undefined,
		};
	}

	return (
		<WorkOrderTable
			handleQueryParams
			initialFilters={intialFilters}
			preAppliedFilters={{
				hasSupplierArchived: false,
			}}
			filterConfig={{
				assignedFilters: ASSIGNED_FILTERS(currentUser),
				tabFilters: ACTIVE_WORK_ORDER_TAB_FILTERS,
				filters: advancedFiltersConfig,
			}}
		/>
	);
}
export default withRouter(
	connect(
		(state) => ({
			currentUser: (state as any).session.currentUser,
		}),
		() => ({})
	)(SupplierWorkOrdersActiveIndexPage)
);

import * as React from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Alert, Button, Upload, Select, message, InputNumber, Switch } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { getObjectValues, isNonEmptyObject, nullSafeGet } from '../../utils/DataAccessUtils';
import BackButton from '../back_button/BackButton';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import { vendorsRestCrudThunksForSupplier } from '../../thunks/vendors_thunks';
import { LocationAutocomplete } from '../location_autocomplete/LocationAutocomplete';
import { retrieveCachedUserDetails } from '../../thunks/session_thunks';
import { imageUploadValidation } from '../../utils/ImageUtils';
import PhoneNumberComponent from '../PhoneNumberComponent/PhoneNumberComponentAntdV3';
import { capitalize, checkAndFormatPhoneNumber } from '../../utils/DataFormatterUtils';
import { Option } from 'antd/es/mentions';

require('./VendorForm.less');

interface VendorFormProps extends FormComponentProps {
	creating: boolean;
	updating: boolean;
	formData: any;
	history: any;
	redirectForwardUrl?: string;
	onSuccess?: any;
	createErrors: any[];
	updateErrors: any[];
	currentUser: any;
	create: any;
	update: any;
	currencies: any;
}

class VendorForm extends React.Component<VendorFormProps, any> {
	state = {
		uploadLoading: false,
		logoUrl: null,
	};

	componentDidMount() {
		const { formData } = this.props;
		this.props.form.setFieldsValue({ logoUrl: formData.logoUrl });
	}

	handleUploadChange = (info) => {
		if (info.file.status === 'uploading') {
			this.setState({ uploadLoading: true });
			return;
		}
		if (info.file.status === 'done') {
			const companyLogoURL = nullSafeGet('file.response.data', info);
			this.props.form.setFieldsValue({ logoUrl: companyLogoURL });
			this.setState({
				logoUrl: companyLogoURL,
				uploadLoading: false,
			});
		}
	};

	handleSubmit = (e, submitAction) => {
		const { history, onSuccess, redirectForwardUrl, formData } = this.props;
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const address = Object.keys(values)
					.filter((k) => k.indexOf('address') > -1)
					.reduce((acc, k) => {
						const [_, x] = k.split('-');
						acc[x] = values[k];
						return acc;
					}, {});

				const vendor = {
					...formData,
					id: values.id,
					name: values.name,
					isActive: values.isActive,
					vendorId: values.vendorId,
					currencyId: values.currencyId,
					paymentTerms: formData.paymentTerms,
					shippingTerms: formData.shippingTerms,
					...(isNonEmptyObject(address) && { address }),
					email: values.email,
					logoUrl: values.logoUrl,
					phoneNumber: checkAndFormatPhoneNumber(values.phoneNumber),
					defaultWarrantyMonths: values.warrantyValue
						? values.warrantyUnit === 'years'
							? values.warrantyValue * 12
							: values.warrantyValue
						: undefined,
				};

				submitAction(vendor)
					.then((record) => {
						if (redirectForwardUrl) {
							history.push(redirectForwardUrl);
						}
						if (onSuccess) {
							onSuccess(record);
						}
					})
					.catch((err) => message.error(err));
			}
		});
	};

	handleLocationAutocompleteSelect = (addr, lonLat, parsedAddress) => {
		const { form } = this.props;
		form.setFieldsValue({
			'address-streetAddress1': parsedAddress.streetAddress1,
			'address-city': parsedAddress.city,
			'address-region': parsedAddress.region,
			'address-postcode': parsedAddress.postcode,
			'address-country': parsedAddress.country,
			longitude: lonLat.lng,
			latitude: lonLat.lat,
		});
	};

	handlePreview = (file) => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true,
		});
	};

	handleCancel = () => this.setState({ previewVisible: false });

	render() {
		const {
			updating,
			creating,
			form,
			currencies,
			update,
			create,
			createErrors,
			formData,
			updateErrors,
		} = this.props;
		const { getFieldDecorator } = form;
		const { logoUrl } = this.state;
		const isUpdate = formData && formData.id !== undefined;
		const submitAction = isUpdate ? update : create;
		const submitText = isUpdate ? 'Update vendor' : 'Create vendor';
		const isLoading = updating || creating;
		const createRawAddress = (addr) => {
			if (!addr || addr === undefined) {
				return '';
			}
			return `${addr.streetAddress1}  ${addr.city}, ${addr.region}, ${addr.postcode}, ${addr.country}`;
		};

		const rawAddress = createRawAddress(nullSafeGet('address', formData));
		getFieldDecorator('logoUrl', {
			initialValue: null,
		});
		const BACKEND_URI = getBackendUri();
		const uploadHeaders = { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token };

		const uploadButton = (
			<div>
				<LegacyIcon type={this.state.uploadLoading ? 'loading' : 'plus'} />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		let defaultWarrantyValue = formData.defaultWarrantyMonths;
		let defaultWarrantyUnit = 'months';
		if (defaultWarrantyValue && defaultWarrantyValue % 12 === 0) {
			defaultWarrantyValue = defaultWarrantyValue / 12;
			defaultWarrantyUnit = 'years';
		}

		const currencyRecordsList = getObjectValues(currencies.records);
		const currencyRecordsMap = currencies.records;

		getFieldDecorator('id', { initialValue: formData.id });
		getFieldDecorator(`address-streetAddress1`, {
			initialValue: nullSafeGet('address.streetAddress1', formData),
		});
		getFieldDecorator(`address-streetAddress2`, {
			initialValue: nullSafeGet('address.streetAddress2', formData),
		});
		getFieldDecorator(`address-city`, { initialValue: nullSafeGet('address.city', formData) });
		getFieldDecorator(`address-region`, { initialValue: nullSafeGet('address.region', formData) });
		getFieldDecorator(`address-postcode`, {
			initialValue: nullSafeGet('address.postcode', formData),
		});
		getFieldDecorator(`address-country`, {
			initialValue: nullSafeGet('address.country', formData),
		});
		const warrantyUnits = ['months', 'years'];
		return (
			<Form
				layout="vertical"
				onSubmit={(e) => this.handleSubmit(e, submitAction)}
				className="supplierForm"
			>
				{createErrors.length > 0 ? (
					<Form.Item>
						<Alert message={createErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				{updateErrors.length > 0 ? (
					<Form.Item>
						<Alert message={updateErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				<Form.Item label="Name">
					{getFieldDecorator('name', {
						initialValue: formData.name,
						rules: [{ required: true, message: 'This field is required.' }],
					})(<Input style={{ maxWidth: 640 }} />)}
				</Form.Item>
				<Form.Item label="Is Active">
					{getFieldDecorator('isActive', {
						initialValue: formData.isActive === undefined ? true : formData.isActive,
						valuePropName: 'checked',
					})(<Switch />)}
				</Form.Item>
				<Form.Item label="Vendor ID">
					{getFieldDecorator('vendorId', {
						initialValue: formData.vendorId,
					})(<Input style={{ maxWidth: 640 }} />)}
				</Form.Item>
				{/*
				<Form.Item label="Payment Terms">
					{getFieldDecorator('paymentTerms', {
						initialValue: formData.paymentTerms,
					})(<Input style={{ maxWidth: 640 }} />)}
				</Form.Item>
				<Form.Item label="Shipping Terms">
					{getFieldDecorator('shippingTerms', {
						initialValue: formData.shippingTerms,
					})(<Input style={{ maxWidth: 640 }} />)}
				</Form.Item>
				*/}
				<Form.Item label="Address">
					<div style={{ maxWidth: 800 }}>
						<LocationAutocomplete
							initialValue={rawAddress}
							placeholder="Search for and select an address"
							onSelect={this.handleLocationAutocompleteSelect}
						/>
					</div>
				</Form.Item>

				<Form.Item label="Currency">
					{form.getFieldDecorator('currencyId', {
						rules: [{ required: true, message: 'Please select a currency for this event.' }],
						initialValue: formData.currencyId,
					})(
						<Select
							showSearch={true}
							style={{ width: 320 }}
							optionFilterProp="children"
							filterOption={(input, option) =>
								currencyRecordsMap[option.props.value].displayNamePlural
									.toLowerCase()
									.indexOf(input.toLowerCase()) >= 0
							}
							placeholder="Currency"
						>
							{currencyRecordsList.map((currency) => (
								<Select.Option key={currency.id} value={currency.id}>
									{capitalize(currency.displayNamePlural)} ({currency.abbreviation})
								</Select.Option>
							))}
						</Select>
					)}
				</Form.Item>
				<Form.Item label="Email">
					{getFieldDecorator('email', {
						initialValue: formData.email,
					})(<Input style={{ maxWidth: 320 }} />)}
				</Form.Item>
				<PhoneNumberComponent
					form={form}
					label="Phone Number"
					fieldProps={{
						initialValue: formData.phoneNumber,
						name: 'phoneNumber',
					}}
					style={{ maxWidth: 320 }}
				/>
				<Form.Item label="Upload your vendor's logo. Square 300x300 pixel images look best.">
					<Upload
						name="file"
						listType="picture-card"
						className="avatar-uploader"
						showUploadList={false}
						headers={uploadHeaders}
						action={`${BACKEND_URI}/api/v1/supplier/file/public/company_logo/upload`}
						beforeUpload={imageUploadValidation}
						onChange={this.handleUploadChange}
					>
						{logoUrl ? <img src={logoUrl} style={{ maxHeight: 104 }} alt="" /> : uploadButton}
					</Upload>
				</Form.Item>
				<Form.Item label="Default Warranty Period">
					<div>
						{getFieldDecorator('warrantyValue', {
							initialValue: defaultWarrantyValue,
							rules: [{ required: false, message: 'This field is required.' }],
						})(<InputNumber min={1} max={365} />)}
						{getFieldDecorator('warrantyUnit', {
							initialValue: defaultWarrantyUnit || 'months',
							rules: [{ required: false, message: 'This field is required.' }],
						})(
							<Select style={{ maxWidth: 100, marginLeft: 8 }}>
								{warrantyUnits.map((f) => (
									<Option key={f} value={f}>
										{f}
									</Option>
								))}
							</Select>
						)}
					</div>
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						loading={isLoading}
						className="contactForm__button"
					>
						{submitText}
					</Button>
					<span style={{ marginLeft: '16px' }}>
						<BackButton buttonText="Cancel" />
					</span>
				</Form.Item>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	formData: ownProps.formData,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,

	createErrors: state.vendors.createErrors,
	updateErrors: state.vendors.updateErrors,
	creating: state.vendors.creating,
	updating: state.vendors.updating,
	currentUser: state.session.currentUser,
	currencies: state.currencies,
});

const mapDispatchToProps = (dispatch) => ({
	update: (entity) => dispatch(vendorsRestCrudThunksForSupplier.update(entity)),
	create: (entity) => dispatch(vendorsRestCrudThunksForSupplier.create(entity)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Form.create<VendorFormProps>()(VendorForm))
);

import { DISPATCHERS_CRUD_ACTION_CREATORS } from '../actions/dispatchers_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { WORK_ORDERS_CRUD_ACTION_CREATORS } from '../actions/work_orders_actions';
import FetchUtils, { parseJSON } from './fetch_utils';
import { logoutSuccess } from '../actions/session_actions';
import { message } from 'antd';
import { capitalize } from '../utils/DataFormatterUtils';

export const dispatchersRestCrudThunksForBuyer = new RestCrudThunks(
	'supplier_dispatchers',
	'supplier_dispatcher',
	'supplierDispatcher',
	'supplierDispatchers',
	DISPATCHERS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'work_order/'
);

export const dispatchersRestCrudThunksForSupplier = new RestCrudThunks(
	'supplier_dispatchers',
	'supplier_dispatcher',
	'supplierDispatcher',
	'supplierDispatchers',
	DISPATCHERS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'work_order/'
);

export const dispatchersRestCrudThunksForSupplierBulk = new RestCrudThunks(
	'supplier_dispatchers/bulk',
	'supplier_dispatcher',
	'supplierDispatcher',
	'supplierDispatchers',
	DISPATCHERS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'work_order/'
);

const fetchUtils = new FetchUtils();

export const addSupplierDispatcherCCs = (ids, entity) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/supplier/work_order/supplier_dispatchers/bulk_add_ccs/${ids}`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(DISPATCHERS_CRUD_ACTION_CREATORS.updateStart(entity));
			fetchUtils
				.patch(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: entity,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(DISPATCHERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(DISPATCHERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(DISPATCHERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

export const removeSupplierDispatcherCCs = (ids, entity) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/supplier/work_order/supplier_dispatchers/bulk_remove_ccs/${ids}`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(DISPATCHERS_CRUD_ACTION_CREATORS.updateStart(entity));
			fetchUtils
				.patch(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: entity,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(DISPATCHERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(DISPATCHERS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(DISPATCHERS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

import { SPEND_CATEGORIES_CRUD_ACTION_CREATORS } from '../actions/spend_categories_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';

export const spendCategoriesRestCrudThunksForBuyer = new RestCrudThunks(
	'spend_categories',
	'spend_category',
	'spendCategory',
	'spendCategories',
	SPEND_CATEGORIES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER
);
export const spendCategoriesRestCrudThunksForSupplier = new RestCrudThunks(
	'spend_categories',
	'spend_category',
	'spendCategory',
	'spendCategories',
	SPEND_CATEGORIES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER
);
export const spendCategoriesRestCrudThunksForPublic = new RestCrudThunks(
	'spend_categories',
	'spend_category',
	'spendCategory',
	'spendCategories',
	SPEND_CATEGORIES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.PUBLIC
);

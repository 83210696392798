import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { SITE_SURVEY_TEMPLATES_CRUD_ACTION_CREATORS } from '../actions/site_survey_templates_actions';

export const siteSurveyTemplatesRestCrudThunksForBuyer = new RestCrudThunks(
	'site_survey_templates',
	'site_survey_template',
	'siteSurveyTemplate',
	'siteSurveyTemplates',
	SITE_SURVEY_TEMPLATES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'site_survey/'
);

export const siteSurveyTemplatesRestCrudThunksForSupplier = new RestCrudThunks(
	'site_survey_templates',
	'site_survey_template',
	'siteSurveyTemplate',
	'siteSurveyTemplates',
	SITE_SURVEY_TEMPLATES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'site_survey/'
);

import { Button, Form, Input, Modal, message } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { equipmentPerStockLocationsRestCrudThunksForSupplier } from '../../thunks/equipment_per_stock_locations_thunks';

const FORM_NAME = 'serial-number-form';

const EditSerialNumberModal = ({ epsl, saving, onCancel, update }): React.ReactElement => {
	const initialValues = useMemo(
		() => ({
			serialNumber: epsl.serialNumber,
		}),
		[epsl.serialNumber]
	);

	const onSubmit = useCallback(
		(values) => {
			update({
				...epsl,
				serialNumber: values.serialNumber,
			})
				.then(() => onCancel())
				.catch(() => message.error('Unable to update serial number!'));
		},
		[epsl, onCancel, update]
	);

	return (
		<Modal
			visible={true}
			width={600}
			title="Update Serial Number"
			closable
			onCancel={onCancel}
			forceRender
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button
					type="primary"
					size="large"
					style={{ marginLeft: '16px' }}
					key="submit"
					htmlType="submit"
					form={FORM_NAME}
					loading={saving}
				>
					Update
				</Button>,
			]}
		>
			<Form
				id={FORM_NAME}
				layout="vertical"
				requiredMark={false}
				initialValues={initialValues}
				onFinish={onSubmit}
			>
				<Form.Item
					name="serialNumber"
					label="Serial Number"
					rules={[
						{
							required: true,
							message: 'Please enter serial number!',
						},
					]}
				>
					<Input style={{ width: '100%' }} />
				</Form.Item>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	saving: state.equipment_per_stock_locations.updating,
	epsl: state.equipment_per_stock_locations.detail,
});

const mapDispatchToProps = (dispatch) => ({
	update: (entity) => dispatch(equipmentPerStockLocationsRestCrudThunksForSupplier.update(entity)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(EditSerialNumberModal)
);

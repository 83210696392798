import * as React from 'react';
import { Layout, Row, Col, Card, Rate, Alert, Button } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { supplierSettingsRestCrudThunksForSupplier } from '../../thunks/supplier_settings_thunks';
import {
	SUPPLIER_NOTIFICATION_APPROVALS_DISPLAY_NAMES,
	SUPPLIER_NOTIFICATION_NOTES_DISPLAY_NAMES,
	SUPPLIER_NOTIFICATION_RFP_STATUS_DISPLAY_NAMES,
	SUPPLIER_NOTIFICATION_WORK_ORDER_STATUS_DISPLAY_NAMES,
} from '../../constants/WorkOrderStatuses';
import EditableCellTable from '../editable_cell_table/EditableCellTable';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { nullSafeGet } from '../../utils/DataAccessUtils';

const { Content } = Layout;

require('./SupplierNotificationsSettingsPage.less');

const DISABLED_TYPES = [
	'WorkOrderNotesMentions',
	'WorkOrderApproval',
	'InvoiceApproval',
	'ProposalApproval',
];

const getNotificationsArray = (notifications, displayNamesMap) =>
	Object.keys(notifications).map((k) => ({
		...notifications[k],
		key: k,
		displayName: displayNamesMap[k],
	}));

const getDefaultNotifications = (displayNamesMap) => {
	const defaultNotifications = {};
	Object.keys(displayNamesMap).forEach((key) => {
		defaultNotifications[key] = { email: true, inApp: true };
	});
	return defaultNotifications;
};

const SupplierNotificationsSettingsPage = (props) => {
	React.useEffect(() => {
		const { getSupplierSettings, currentUser } = props;
		getSupplierSettings(encodeURIComponent(currentUser.email));
	}, []);

	const { supplierSettingsFetching, supplierSettings, updateSupplierSettings, currentUser } = props;

	const isPrivateSupplier = nullSafeGet('facility.status', currentUser) === 'private';

	const workOrderNotifications = {
		...getDefaultNotifications(SUPPLIER_NOTIFICATION_WORK_ORDER_STATUS_DISPLAY_NAMES),
		...supplierSettings.workOrderNotifications,
	};
	const workOrderNotificationsArray = getNotificationsArray(
		workOrderNotifications,
		SUPPLIER_NOTIFICATION_WORK_ORDER_STATUS_DISPLAY_NAMES
	);

	const rfpNotifications = {
		...getDefaultNotifications(SUPPLIER_NOTIFICATION_RFP_STATUS_DISPLAY_NAMES),
		...supplierSettings.rfpNotifications,
	};
	const rfpNotificationsArray = getNotificationsArray(
		rfpNotifications,
		SUPPLIER_NOTIFICATION_RFP_STATUS_DISPLAY_NAMES
	);

	const approvalNotifications = {
		...getDefaultNotifications(SUPPLIER_NOTIFICATION_APPROVALS_DISPLAY_NAMES),
		...supplierSettings.approvalNotifications,
	};
	const approvalNotificationsArray = getNotificationsArray(
		approvalNotifications,
		SUPPLIER_NOTIFICATION_APPROVALS_DISPLAY_NAMES
	);

	const notesNotifications = {
		...getDefaultNotifications(SUPPLIER_NOTIFICATION_NOTES_DISPLAY_NAMES),
		...supplierSettings.notesNotifications,
	};
	const notesNotificationsArray = getNotificationsArray(
		notesNotifications,
		SUPPLIER_NOTIFICATION_NOTES_DISPLAY_NAMES
	);

	const setEmailsForImportantOnly = () => {
		const NOTIFICATION_CATEGORIES = {
			approvalNotifications: approvalNotifications,
			notesNotifications: notesNotifications,
			workOrderNotifications: workOrderNotifications,
			rfpNotifications: rfpNotifications,
		};
		const newsupplierSettings = {
			...supplierSettings,
		};
		Object.keys(NOTIFICATION_CATEGORIES).forEach((category) => {
			const newCategory = {};
			Object.keys(NOTIFICATION_CATEGORIES[category]).forEach((key) => {
				newCategory[key] = {
					...NOTIFICATION_CATEGORIES[category][key],
					email: !!NOTIFICATION_CATEGORIES[category][key].important,
				};
			});
			newsupplierSettings[category] = newCategory;
		});

		updateSupplierSettings(newsupplierSettings);
	};
	const setMobilePushForImportantOnly = () => {
		const NOTIFICATION_CATEGORIES = {
			approvalNotifications: approvalNotifications,
			notesNotifications: notesNotifications,
			workOrderNotifications: workOrderNotifications,
			rfpNotifications: rfpNotifications,
		};
		const newsupplierSettings = {
			...supplierSettings,
		};
		Object.keys(NOTIFICATION_CATEGORIES).forEach((category) => {
			const newCategory = {};
			Object.keys(NOTIFICATION_CATEGORIES[category]).forEach((key) => {
				newCategory[key] = {
					...NOTIFICATION_CATEGORIES[category][key],
					inApp: !!NOTIFICATION_CATEGORIES[category][key].important,
				};
			});
			newsupplierSettings[category] = newCategory;
		});

		updateSupplierSettings(newsupplierSettings);
	};

	const updateWorkOrderNotificationsSettings = (newSetting) => {
		const newsupplierSettings = {
			...supplierSettings,
			workOrderNotifications: {
				...supplierSettings.workOrderNotifications,
				[newSetting.key]: {
					sms: typeof newSetting.sms === 'undefined' ? false : newSetting.sms,
					email: newSetting.email,
					inApp: newSetting.inApp,
					important: newSetting.important,
				},
			},
		};
		updateSupplierSettings(newsupplierSettings);
	};

	const updateRFPNotificationsSettings = (newSetting) => {
		const newsupplierSettings = {
			...supplierSettings,
			rfpNotifications: {
				...supplierSettings.rfpNotifications,
				[newSetting.key]: {
					sms: typeof newSetting.sms === 'undefined' ? false : newSetting.sms,
					email: newSetting.email,
					inApp: newSetting.inApp,
					important: newSetting.important,
				},
			},
		};
		updateSupplierSettings(newsupplierSettings);
	};

	const updateNotesNotificationsSettings = (newSetting) => {
		const newsupplierSettings = {
			...supplierSettings,
			notesNotifications: {
				...supplierSettings.notesNotifications,
				[newSetting.key]: {
					sms: typeof newSetting.sms === 'undefined' ? false : newSetting.sms,
					email: newSetting.email,
					inApp: newSetting.inApp,
					important: newSetting.important,
				},
			},
		};
		updateSupplierSettings(newsupplierSettings);
	};

	const updateApprovalNotificationsSettings = (newSetting) => {
		const newsupplierSettings = {
			...supplierSettings,
			approvalNotifications: {
				...supplierSettings.approvalNotifications,
				[newSetting.key]: {
					sms: typeof newSetting.sms === 'undefined' ? false : newSetting.sms,
					email: newSetting.email,
					inApp: newSetting.inApp,
					important: newSetting.important,
				},
			},
		};
		updateSupplierSettings(newsupplierSettings);
	};

	const getColumns = (displayName, handleSave) => [
		{
			title: 'Important',
			dataIndex: 'important',
			width: '100px',
			render: (important, record) => (
				<Rate
					count={1}
					value={important ? 1 : 0}
					onChange={(value) => handleSave({ ...record, important: value === 1 })}
				/>
			),
		},
		{
			title: displayName,
			dataIndex: 'displayName',
			width: '640px',
			render: (text) => <span style={{ fontSize: 16 }}>{text}</span>,
		},
		{
			title: 'Email',
			dataIndex: 'email',
			width: '80px',
			editable: true,
			defaultValue: true,
		},
		{
			title: 'Mobile Push Notification',
			dataIndex: 'inApp',
			width: '210px',
			editable: true,
			defaultValue: true,
		},
	];

	return supplierSettingsFetching ? (
		<PageLoadingPlaceholder />
	) : (
		<Content className="buyerNotificationsSettingsPage">
			<Row className="rowSpacing">
				<Col style={{ maxWidth: 960, margin: 'auto' }} span={24}>
					<Alert
						style={{ borderRadius: 5 }}
						message="Marking a notification as important will make it appear in the Important section on the dashboard and inbox "
						description={
							<>
								You can click here to only send Important notifications to your{' '}
								<Button size="small" className="mt-3" onClick={setEmailsForImportantOnly}>
									Emails
								</Button>{' '}
								or{' '}
								<Button size="small" onClick={setMobilePushForImportantOnly}>
									Push Notification
								</Button>
								.
							</>
						}
						type="info"
						closable
					/>
				</Col>
			</Row>

			<Row className="rowSpacing">
				<Col style={{ maxWidth: 960, margin: 'auto' }} span={24}>
					<Card>
						<EditableCellTable
							showHeader={true}
							handleSave={updateNotesNotificationsSettings}
							columns={getColumns('Notes Notifications', updateNotesNotificationsSettings)}
							data={notesNotificationsArray}
							rowClassName={(record) => (DISABLED_TYPES.includes(record.key) ? 'disabled-row' : '')}
						/>
					</Card>
				</Col>
			</Row>
			<Row className="rowSpacing">
				<Col style={{ maxWidth: 960, margin: 'auto' }} span={24}>
					<Card>
						<EditableCellTable
							showHeader={true}
							handleSave={updateWorkOrderNotificationsSettings}
							columns={getColumns('Work Order Notifications', updateWorkOrderNotificationsSettings)}
							data={workOrderNotificationsArray}
							rowClassName={(record) => (DISABLED_TYPES.includes(record.key) ? 'disabled-row' : '')}
						/>
					</Card>
				</Col>
			</Row>
			<Row className="rowSpacing">
				<Col style={{ maxWidth: 960, margin: 'auto' }} span={24}>
					<Card>
						<EditableCellTable
							showHeader={true}
							handleSave={updateRFPNotificationsSettings}
							columns={getColumns(
								'Request for Proposal Notifications',
								updateRFPNotificationsSettings
							)}
							data={rfpNotificationsArray}
							rowClassName={(record) => (DISABLED_TYPES.includes(record.key) ? 'disabled-row' : '')}
						/>
					</Card>
				</Col>
			</Row>
			{/* {(isPrivateSupplier && (
				<Row className="rowSpacing ">
					<Col style={{ maxWidth: 960, margin: 'auto' }} span={24}>
						<Card>
							<EditableCellTable
								showHeader={true}
								handleSave={updateApprovalNotificationsSettings}
								columns={getColumns('Approval Notifications', updateApprovalNotificationsSettings)}
								data={approvalNotificationsArray}
								rowClassName={(record) =>
									DISABLED_TYPES.includes(record.key) ? 'disabled-row' : ''
								}
							/>
						</Card>
					</Col>
				</Row>
			)) ||
				null} */}
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	currentUser: state.session.currentUser,

	supplierSettings: state.supplier_settings.detail,
	supplierSettingsFetching: state.supplier_settings.fetching,
});

const mapDispatchToProps = (dispatch) => ({
	getSupplierSettings: (email) =>
		dispatch(supplierSettingsRestCrudThunksForSupplier.readOne(email)),
	updateSupplierSettings: (settings) =>
		dispatch(supplierSettingsRestCrudThunksForSupplier.update(settings)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(SupplierNotificationsSettingsPage)
);

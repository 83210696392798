import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { LOCATION_DETAILS_CRUD_ACTION_CREATORS } from '../actions/location_details_actions';

export const locationDetailsRestCrudThunksForSupplier = new RestCrudThunks(
	'details',
	'detail',
	'locationDetail',
	'locationDetails',
	LOCATION_DETAILS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'location/'
);

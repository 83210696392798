import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Card, Col, Layout, Row, Spin, Tag } from 'antd';
import { getCurrency, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import {
	floatToPercentageString,
	formatDateForParam,
	secondsToStr,
} from '../../utils/DataFormatterUtils';
import { KPILineChart } from '../charts/KPILineChart';
import { ColumnLineComboChart } from '../charts/ColumnLineComboChart';
import { withRouter } from 'react-router';
import {
	fetchSupplierFacilitiesIfEverScorecardInPrivateNetworkForBuyer,
	fetchSupplierFacilitiesIfEverScorecardInPrivateNetworkForSupplier,
	supplierFacilitiesRestCrudThunksForBuyer,
	supplierFacilitiesRestCrudThunksForSupplier,
} from '../../thunks/supplier_facilities_thunks';
import AnalyticsControlBar from '../analytics_control_bar/AnalyticsControlBar';
import { clearKPIVendorFilters, updateKPIVendorFilters } from '../../actions/kpi_analytics_actions';
import {
	fetchAllKPIs,
	fetchCheckInComplianceKPIByMonth,
	fetchFirstTimeResolutionKPIByMonth,
	fetchOnTimeArrivalKPIByMonth,
	fetchTimeToAcceptKPIByMonth,
	fetchTimeToScheduleKPIByMonth,
	fetchTimelyInvoicingKPIByMonth,
	fetchTimelyWorkCompletionKPIByMonth,
	fetchWorkOrdersKPIByMonth,
} from '../../thunks/kpi_analytics_thunks';
import { debounce } from '../../utils/PerformanceUtils';
import { EmptyState } from '../empty_state/EmptyState';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { ROLE_TYPES } from '../../utils/DataConstants';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { generateBackLink } from '../../utils/HistoryUtils';
import { clearNavbarState, updateNavbarState } from '../../actions/navbar_actions';

const queryString = require('qs');

const style = require('./KPIAnalyticsVendorDetailPage.less');
const isEqual = require('fast-deep-equal');

const Content = Layout.Content;
const metricLineChartConfig = {
	xAxisName: 'yearMonthKey',
	yAxisName: 'monthlyAverage',
	position: 'yearMonthKey*monthlyAverage',
	fillArea: false,
	cols: {
		yearMonthKey: { alias: 'Month' },
		monthlyAverage: {
			alias: 'Metric',
			min: 0,
			max: 1,
			formatter: (val = 0) => (100 * val).toFixed(0) + '%',
		},
	},
	yAxisLabel: {
		textStyle: {
			fontSize: '12',
			fontFamily: 'Roboto',
			fill: 'rgba(0,0,0,0.85)',
		},
	},
	xAxisLabel: {
		textStyle: {
			fontSize: '12',
			fontFamily: 'Roboto',
			fill: 'rgba(0,0,0,0.85)',
		},
		formatter: (val, item) => {
			let [year, month] = val.split('-');
			const date = moment({ year, month: month - 1 });
			return date.format('MMM YYYY');
		},
	},
};
const metricLineByTimeChartConfig = {
	xAxisName: 'yearMonthKey',
	yAxisName: 'monthlyAverage',
	position: 'yearMonthKey*monthlyAverage',
	fillArea: false,
	cols: {
		yearMonthKey: { alias: 'Month' },
		monthlyAverage: {
			alias: 'Metric',
			formatter: (val) => secondsToStr(val || 0),
		},
	},
	yAxisLabel: {
		textStyle: {
			fontSize: '12',
			fontFamily: 'Roboto',
			fill: 'rgba(0,0,0,0.85)',
		},
	},
	xAxisLabel: {
		textStyle: {
			fontSize: '12',
			fontFamily: 'Roboto',
			fill: 'rgba(0,0,0,0.85)',
		},
		formatter: (val, item) => {
			let [year, month] = val.split('-');
			const date = moment({ year, month: month - 1 });
			return date.format('MMM YYYY');
		},
	},
};
const workOrderChartConfig = {
	xAxisName: 'yearMonthKey',
	yAxisName: 'totalWOs',
	position1: 'yearMonthKey*totalWOs',
	position2: 'yearMonthKey*averageWOCost',
	fillArea: false,
	cols: {
		yearMonthKey: { alias: 'Month' },
		totalWOs: { alias: '# Work Orders' },
		averageWOCost: { alias: 'Average Work Order Cost' },
	},
	yAxisLabel: {
		textStyle: {
			fontSize: '12',
			fontFamily: 'Roboto',
			fill: 'rgba(0,0,0,0.85)',
		},
	},
	xAxisLabel: {
		textStyle: {
			fontSize: '12',
			fontFamily: 'Roboto',
			fill: 'rgba(0,0,0,0.85)',
		},
		formatter: (val, item) => {
			let [year, month] = val.split('-');
			const date = moment({ year, month: month - 1 });
			return date.format('MMM YYYY');
		},
	},
};

class KPIAnalyticsVendorDetailPage extends React.Component<any, any> {
	state = {
		initialLoading: true,
		workOrderData: {},
	};

	debouncedCallback = debounce(
		() => {
			const {
				kpiAnalytics,
				getWorkOrdersKPIByMonth,
				getCheckInComplianceKPIByMonth,
				getFirstTimeResolutionKPIByMonth,
				getOnTimeArrivalKPIByMonth,
				getTimelyInvoicingKPIByMonth,
				getTimelyWorkCompletionKPIByMonth,
				getTimeToAcceptKPIByMonth,
				getTimeToScheduleKPIByMonth,
				fetchSupplierWoData,
				getKPIs,
			} = this.props;
			const { initialLoading } = this.state;
			const { kpiVendorFilters } = kpiAnalytics;
			const startDate =
				kpiVendorFilters.startDate && formatDateForParam(kpiVendorFilters.startDate);
			const endDate = kpiVendorFilters.endDate && formatDateForParam(kpiVendorFilters.endDate);
			const filters = { ...kpiVendorFilters, startDate, endDate };
			const promises = [
				fetchSupplierWoData(filters),
				getWorkOrdersKPIByMonth(filters),
				getCheckInComplianceKPIByMonth(filters),
				getTimeToAcceptKPIByMonth(filters),
				getTimeToScheduleKPIByMonth(filters),
				getFirstTimeResolutionKPIByMonth(filters),
				getOnTimeArrivalKPIByMonth(filters),
				getTimelyInvoicingKPIByMonth(filters),
				getTimelyWorkCompletionKPIByMonth(filters),
				getKPIs(filters),
			];

			Promise.allSettled(promises).then((data) => {
				this.setState({
					workOrderData: nullSafeGetOrElse('0.value.0', data, {}),
				});

				this.setState({ initialLoading: false });
			});
		},
		500,
		false
	);

	componentWillReceiveProps(nextProps) {
		const { kpiAnalytics } = this.props;
		if (!isEqual(nextProps.kpiAnalytics.kpiVendorFilters, kpiAnalytics.kpiVendorFilters)) {
			this.debouncedCallback();
		}
	}

	componentDidMount() {
		const { fetchSupplierFacility, match, saveKPIVendorFilters, location, updateNavbar } =
			this.props;
		const supplierFacilityId = match.params.id;
		fetchSupplierFacility(supplierFacilityId);

		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		saveKPIVendorFilters({
			supplierFacilityIds: [parseInt(supplierFacilityId, 10)],
			startDate: moment().subtract(6, 'months'),
			endDate: moment(),
			problemTypeIds: queryParams.problemTypeIds,
		});
		updateNavbar(
			`Back to Vendors`,
			generateBackLink(`/buyer/analytics/kpi/overview/vendors`, location)
		);
	}

	componentWillUnmount() {
		const { clearAllKPIVendorFilters, clearNavbar } = this.props;
		clearAllKPIVendorFilters();
		clearNavbar();
	}

	render() {
		const {
			supplierFacility,
			saveKPIVendorFilters,
			companyConfig,
			match,
			clearAllKPIVendorFilters,
			kpiAnalytics,
			currentUser,
			userType,
			history,
			location,
		} = this.props;
		const { initialLoading } = this.state;
		const {
			workOrdersByMonth,
			timelyWorkCompletionByMonth,
			onTimeArrivalByMonth,
			timeToAcceptByMonth,
			timeToScheduleByMonth,
			checkInComplianceByMonth,
			firstTimeResolutionByMonth,
			timelyInvoicingByMonth,
		} = kpiAnalytics;
		const getKPIColor = (val, baseline) => {
			if (val === null || val === undefined) {
				return 'rgba(0,0,0,0.45)';
			} else if (val >= baseline) {
				return 'green';
			} else {
				return 'red';
			}
		};
		const currency = getCurrency({ currentUser });
		const supplierFacilityId = parseInt(match.params.id, 10);
		const timeMetrics = [
			{
				name: 'Time To Accept',
				data: timeToAcceptByMonth.data,
				loading: timeToAcceptByMonth.loading,
				baseline: 0.95,
				key: 'monthlyTimeToAcceptKPIs.monthlyAverage',
			},
			{
				name: 'Time To Schedule Visit',
				data: timeToScheduleByMonth.data,
				loading: timeToScheduleByMonth.loading,
				baseline: 0.95,
				key: 'monthlyTimeToScheduleVisitKPIs.monthlyAverage',
			},
		];

		const metrics = [
			{
				name: 'Arrived On Time',
				data: onTimeArrivalByMonth.data,
				loading: onTimeArrivalByMonth.loading,
				baseline: 0,
				key: 'monthlyOnTimeArrivalKPIs.monthlyAverage',
			},
			{
				name: 'Checked In / Out',
				data: checkInComplianceByMonth.data,
				loading: checkInComplianceByMonth.loading,
				baseline: 0,
				key: 'monthlyCheckInKPIs.monthlyAverage',
			},
			{
				name: 'Completed On Time',
				data: timelyWorkCompletionByMonth.data,
				loading: timelyWorkCompletionByMonth.loading,
				baseline: 0,
				key: 'monthlyWorkCompleteWithinDueDateKPIs.monthlyAverage',
			},

			{
				name: 'Resolved Issue First Time',
				data: firstTimeResolutionByMonth.data,
				loading: firstTimeResolutionByMonth.loading,
				baseline: 0,
				key: 'monthlyResolvedFirstTimeKPIs.monthlyAverage',
			},
			{
				name: 'Invoiced Within 30 Days',
				data: timelyInvoicingByMonth.data,
				loading: timelyInvoicingByMonth.loading,
				baseline: 0,
				key: 'monthlyInvoicedIn30DaysKPIs.monthlyAverage',
			},
		];
		const { workOrderData } = this.state;
		return (
			<Content className="kpiAnalyticsVendorDetailPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited kpi analytics vendor detail page" />
				<div
					style={{
						margin: '0 -8px',
						padding: '24px',
						paddingBottom: 0,
						background: nullSafeGet('detail.config.theme.navblock', companyConfig),
						marginBottom: 8,
					}}
				>
					<h4
						style={{
							color: 'rgba(255, 255, 255, 0.9)',
							marginRight: 8,
							paddingBottom: 24,
						}}
					>
						{supplierFacility.displayName}
					</h4>
				</div>
				<Row style={{ margin: '16px 4px' }} gutter={16}>
					<AnalyticsControlBar
						updateFilters={(filters = {}) => {
							saveKPIVendorFilters({ ...filters, supplierFacilityIds: [supplierFacilityId] });
						}}
						locationIds={kpiAnalytics.kpiVendorFilters.locationIds}
						startDate={kpiAnalytics.kpiVendorFilters.startDate}
						endDate={kpiAnalytics.kpiVendorFilters.endDate}
						hideSuppliersControl
						filterConfig={{ problemTypes: true, capEx: false }}
						problemTypeIds={kpiAnalytics.kpiVendorFilters.problemTypeIds}
						dateRangeLabel="Date Range"
					/>
				</Row>

				<Row style={{ margin: '16px 0px 0px 0px' }} gutter={16}>
					<Col span={12}>
						<Card
							loading={initialLoading}
							title="completed work orders"
							actions={[
								<Link
									target="_blank"
									to={`/${userType}/workOrders/overview/all?current=1&pageSize=5&sort_by=createdAt&order=desc&displayStatuses=Completed&supplierFacilityIds=${supplierFacilityId}`}
								>
									<Button type="link">
										See details <i className="icons8-font icons8-external-link ml-2 inline-block" />
									</Button>
								</Link>,
							]}
							extra={
								<div className="text-base text-gray-500">
									LIFETIME: {workOrderData.lifeTimeCompletedWorkOrders}
								</div>
							}
						>
							<div className="flex h-40 flex-col items-center justify-center">
								<div className="text-center text-8xl font-normal ">
									{workOrderData.dateRangeCompletedWorkOrders}
								</div>
							</div>
						</Card>
					</Col>
					<Col span={12}>
						<Card
							loading={initialLoading}
							title="Open work orders"
							actions={[
								<Link
									target="_blank"
									to={`/${userType}/workOrders/overview/all?current=1&pageSize=5&sort_by=createdAt&order=desc&displayStatuses=Open%2CInProgress%2COnHold&supplierFacilityIds=${supplierFacilityId}`}
								>
									<Button type="link">
										See details <i className="icons8-font icons8-external-link ml-2 inline-block" />
									</Button>
								</Link>,
							]}
							extra={
								<div className="text-base text-gray-500">
									LIFETIME: {workOrderData.lifeTimeOpenWorkOrders}
								</div>
							}
						>
							<div className="flex h-40 flex-col items-center justify-center">
								<div className="text-center text-8xl font-normal ">
									{workOrderData.dateRangeOpenWorkOrders}
								</div>
							</div>
						</Card>
					</Col>
				</Row>
				<Row style={{ margin: '16px 0px 0px 0px' }} gutter={16}>
					<Col span={12}>
						<Card bodyStyle={{ padding: '16px 24px' }} style={{ padding: 0 }}>
							<div className="kpiLineChartTitle">
								<div>Work Orders</div>
								<div className="text-base">
									{nullSafeGet(
										'monthlyKPIsBySupplier.monthlyWorkOrderKPIs.totalWOs',
										kpiAnalytics
									) > 0
										? `${nullSafeGet(
												'monthlyKPIsBySupplier.monthlyWorkOrderKPIs.totalWOs',
												kpiAnalytics
										  )} WOs (avg. cost ${currency.format(
												nullSafeGet(
													'monthlyKPIsBySupplier.monthlyWorkOrderKPIs.averageWOCost',
													kpiAnalytics
												),
												0
										  )})`
										: 'No work orders in current month'}
								</div>
							</div>
							<Spin spinning={workOrdersByMonth.loading || initialLoading}>
								{workOrdersByMonth.data && workOrdersByMonth.data.length > 0 ? (
									<ColumnLineComboChart
										height={300}
										chartPadding={[40, 40, 40, 40]}
										data={workOrdersByMonth.data}
										xAxisName={nullSafeGet('xAxisName', workOrderChartConfig)}
										yAxisName={nullSafeGet('yAxisName', workOrderChartConfig)}
										yAxisLabel={nullSafeGet('yAxisLabel', workOrderChartConfig)}
										xAxisLabel={nullSafeGet('xAxisLabel', workOrderChartConfig)}
										fillArea={nullSafeGet('fillArea', workOrderChartConfig)}
										cols={nullSafeGet('cols', workOrderChartConfig)}
										position1={nullSafeGet('position1', workOrderChartConfig)}
										position2={nullSafeGet('position2', workOrderChartConfig)}
									/>
								) : (
									<EmptyState
										height={300}
										headline={'KPI data not available'}
										body={<div style={{ maxWidth: 488, textAlign: 'center' }}></div>}
									/>
								)}
							</Spin>
						</Card>
					</Col>
					{timeMetrics.map((metric) => (
						<Col span={12}>
							<Card
								key={metric.name}
								bodyStyle={{ padding: '16px 24px' }}
								style={{ marginBottom: 16, padding: 0 }}
								className="cursor-pointer"
							>
								<div className="kpiLineChartTitle mb-7">
									<div>{metric.name}</div>
									<div>
										{secondsToStr(nullSafeGet(`monthlyKPIsBySupplier.${metric.key}`, kpiAnalytics))}
									</div>
								</div>
								<Spin spinning={metric.loading || initialLoading}>
									{metric.data && metric.data.length > 0 ? (
										<KPILineChart
											height={300}
											chartPadding={[40, 40, 40, 40]}
											data={metric.data}
											baselineValue={metric.baseline}
											baselineText="Service Level Agreement"
											xAxisName={nullSafeGet('xAxisName', metricLineByTimeChartConfig)}
											yAxisName={nullSafeGet('yAxisName', metricLineByTimeChartConfig)}
											yAxisLabel={nullSafeGet('yAxisLabel', metricLineByTimeChartConfig)}
											xAxisLabel={nullSafeGet('xAxisLabel', metricLineByTimeChartConfig)}
											fillArea={nullSafeGet('fillArea', metricLineByTimeChartConfig)}
											cols={nullSafeGet('cols', metricLineByTimeChartConfig)}
											position={nullSafeGet('position', metricLineByTimeChartConfig)}
										/>
									) : (
										<EmptyState
											height={300}
											headline={'KPI data not available'}
											body={<div style={{ maxWidth: 488, textAlign: 'center' }}></div>}
										/>
									)}
								</Spin>
							</Card>
						</Col>
					))}
					{metrics.map((metric) => (
						<Col span={12}>
							<Card
								key={metric.name}
								bodyStyle={{ padding: '16px 24px' }}
								style={{ marginBottom: 16, padding: 0 }}
								className="cursor-pointer"
							>
								<div className="kpiLineChartTitle mb-7">
									<div>{metric.name}</div>
									<div>
										{floatToPercentageString(
											nullSafeGet(`monthlyKPIsBySupplier.${metric.key}`, kpiAnalytics),
											0
										)}
									</div>
								</div>
								<Spin spinning={metric.loading || initialLoading}>
									{metric.data && metric.data.length > 0 ? (
										<KPILineChart
											height={300}
											chartPadding={[40, 40, 40, 40]}
											data={metric.data}
											baselineValue={metric.baseline}
											baselineText="Service Level Agreement"
											xAxisName={nullSafeGet('xAxisName', metricLineChartConfig)}
											yAxisName={nullSafeGet('yAxisName', metricLineChartConfig)}
											yAxisLabel={nullSafeGet('yAxisLabel', metricLineChartConfig)}
											xAxisLabel={nullSafeGet('xAxisLabel', metricLineChartConfig)}
											fillArea={nullSafeGet('fillArea', metricLineChartConfig)}
											cols={nullSafeGet('cols', metricLineChartConfig)}
											position={nullSafeGet('position', metricLineChartConfig)}
										/>
									) : (
										<EmptyState
											height={300}
											headline={'KPI data not available'}
											body={<div style={{ maxWidth: 488, textAlign: 'center' }}></div>}
										/>
									)}
								</Spin>
							</Card>
						</Col>
					))}
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	supplierFacilitiesFetching: state.supplier_facilities.fetching,
	supplierFacility: state.supplier_facilities.detail,
	companyConfig: state.company_config,
	match: ownProps.match,
	history: ownProps.history,
	kpiAnalytics: state.kpi_analytics,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchSupplierFacility: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? supplierFacilitiesRestCrudThunksForSupplier.readOne(id)
				: supplierFacilitiesRestCrudThunksForBuyer.readOne(id)
		),
	saveKPIVendorFilters: (filters) => dispatch(updateKPIVendorFilters(filters)),
	clearAllKPIVendorFilters: () => dispatch(clearKPIVendorFilters()),
	getWorkOrdersKPIByMonth: (params) =>
		dispatch(fetchWorkOrdersKPIByMonth(ownProps.userType)(params)),
	getCheckInComplianceKPIByMonth: (params) =>
		dispatch(fetchCheckInComplianceKPIByMonth(ownProps.userType)(params)),
	getTimeToAcceptKPIByMonth: (params) =>
		dispatch(fetchTimeToAcceptKPIByMonth(ownProps.userType)(params)),
	getTimeToScheduleKPIByMonth: (params) =>
		dispatch(fetchTimeToScheduleKPIByMonth(ownProps.userType)(params)),
	getFirstTimeResolutionKPIByMonth: (params) =>
		dispatch(fetchFirstTimeResolutionKPIByMonth(ownProps.userType)(params)),
	getOnTimeArrivalKPIByMonth: (params) =>
		dispatch(fetchOnTimeArrivalKPIByMonth(ownProps.userType)(params)),
	getTimelyInvoicingKPIByMonth: (params) =>
		dispatch(fetchTimelyInvoicingKPIByMonth(ownProps.userType)(params)),
	getTimelyWorkCompletionKPIByMonth: (params) =>
		dispatch(fetchTimelyWorkCompletionKPIByMonth(ownProps.userType)(params)),
	getKPIs: (params, targetCollectionName) =>
		dispatch(fetchAllKPIs(ownProps.userType)(params, targetCollectionName)),
	fetchSupplierWoData: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? fetchSupplierFacilitiesIfEverScorecardInPrivateNetworkForSupplier(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: fetchSupplierFacilitiesIfEverScorecardInPrivateNetworkForBuyer(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	updateNavbar: (backlinkTitle, backlinkTo, navbarBackgroundColor) =>
		dispatch(updateNavbarState(backlinkTitle, backlinkTo, navbarBackgroundColor)),
	clearNavbar: () => dispatch(clearNavbarState()),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(KPIAnalyticsVendorDetailPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { assetNbvsRestCrudThunksForSupplier } from '../../thunks/asset_nbvs_thunks';
import { Card } from 'antd';
import { getCurrency, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { HorizontalKeyValueDisplay } from '../horizontal_key_value_display/HorizontalKeyValueDisplay';
import { DATE_ONLY_FORMAT, dateFormatter } from '../../utils/DataFormatterUtils';

const NBVCardDisplay: FC<any> = ({
	match,
	fetchAssetNBV,
	loading,
	currentUser,
	asset,
	rerender,
}): React.ReactElement => {
	const [nbvDetail, setNbVDetail] = useState<any>(undefined);
	const assetId = useMemo(() => nullSafeGet('params.id', match), [match]);

	useEffect(() => {
		assetId &&
			!rerender &&
			fetchAssetNBV({ assetId }).then((res) => res && res.length > 0 && setNbVDetail(res[0]));
	}, [assetId, fetchAssetNBV, rerender]);

	const currency = useMemo(() => getCurrency({ currentUser }), [currentUser]);

	const totalDepreciationValue = useMemo(
		() =>
			parseFloat(nullSafeGetOrElse('totalDepreciation', nbvDetail, 0)) +
			parseFloat(nullSafeGetOrElse('existingDepreciation', asset, 0)),
		[asset, nbvDetail]
	);

	const totalDepreciationCurrency = useMemo(
		() => currency.format(totalDepreciationValue),
		[currency, totalDepreciationValue]
	);

	const impairments = useMemo(
		() =>
			nullSafeGetOrElse('impairments', asset, []).reduce(
				(acc, obj) => acc + parseFloat(obj.impairmentAdjustment),
				0
			),
		[asset]
	);

	const assetDetail = useMemo(
		() => ({
			capitalisedCost: asset.capitalisedCost,
			totalDepreciation: asset.totalDepreciation || 0,
			netBookValue:
				nullSafeGetOrElse('capitalisedCost', asset, 0) - totalDepreciationValue - impairments,
			impairments,
		}),
		[asset, totalDepreciationValue, impairments]
	);

	const getCurrencyValueForProp = useCallback(
		(propName) => {
			const val = nullSafeGet(propName, nbvDetail)
				? nullSafeGet(propName, nbvDetail)
				: nullSafeGetOrElse(propName, assetDetail, 0);
			return currency.format(val > 0 ? val : 0);
		},
		[assetDetail, currency, nbvDetail]
	);

	const displayDetail = useMemo(
		() => ({
			'Capitalized Cost': getCurrencyValueForProp('capitalisedCost'),
			'Total Depreciation': totalDepreciationCurrency,
			'Total Impairment': getCurrencyValueForProp('impairments'),
			'Net Book Value': getCurrencyValueForProp('netBookValue'),
		}),
		[getCurrencyValueForProp, totalDepreciationCurrency]
	);

	const calculatedAsOf = useMemo(() => {
		const date = nullSafeGet('calculatedAsOf', nbvDetail);
		return date ? `Calculated as of ${dateFormatter(date, DATE_ONLY_FORMAT)}` : '';
	}, [nbvDetail]);

	return (
		<Card title="Net book value" loading={loading} extra={calculatedAsOf}>
			{!loading ? (
				<HorizontalKeyValueDisplay keyWidth={160} keyValueStore={displayDetail} />
			) : (
				'No Data found'
			)}
		</Card>
	);
};

const mapStateToProps = (state) => ({
	asset: state.assets.detail,
	loading: state.asset_net_book_values.fetching,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	fetchAssetNBV: (params) => dispatch(assetNbvsRestCrudThunksForSupplier.read(params)),
});

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(NBVCardDisplay));

import * as React from 'react';
import { Layout, Card } from 'antd';

const style = require('./EmptorDataProtectionPolicyPage.less');

export default class EmptorDataProtectionPolicyPage extends React.Component<any, any> {
	render() {
		return (
			<Layout.Content className="emptorDataProtectionPolicyPage">
				<Card style={{ maxWidth: 1200, margin: 'auto' }}>
					<h1>Data Protection Policy</h1>
					<p>
						This policy refers to all data collected from employees, candidates, users, customers,
						vendors, or other parties that provide information to OpenWrench. OpenWrench employees
						must follow this policy. Contractors, consultants, partners and any other external
						entity are also covered. Generally, our policy refers to anyone we collaborate with or
						who acts on our behalf and may need access to OpenWrench data.
					</p>
					<p>
						As part of our operations, we obtain and process information, some of which can be used
						to identify individuals (personally-identifiable information, or PII). Our company
						collects this information in a transparent way and only with the full cooperation and
						knowledge of interested parties.
					</p>
					<p>Once this information is available to us, the following rules apply.</p>
					<h2>The data will be:</h2>
					<ul>
						<li>Accurate and kept up-to-date</li>
						<li>Collected fairly and for lawful purposes only</li>
						<li>Processed by the company within its legal and moral boundaries</li>
						<li>
							Protected against any unauthorized or illegal access by internal or external parties
						</li>
					</ul>
					<h2>The data will not be:</h2>
					<ul>
						<li>Communicated informally</li>
						<li>
							Stored for more than the amount of time specified in our terms of service, privacy
							policy, customer contracts, or other binding agreements
						</li>
						<li>
							Transferred to organizations, states, or countries that do not have adequate data
							protection policies
						</li>
						<li>
							Distributed to any party other than the ones agreed upon by the data’s owner
							(exempting legitimate requests from law enforcement authorities)
						</li>
					</ul>
					<h2>To exercise data protection we’re committed to:</h2>
					<ul>
						<li>Restrict and monitor access to sensitive data</li>
						<li>Develop transparent data collection procedures</li>
						<li>Train employees in online privacy and security measures</li>
						<li>Build secure networks to protect online data from cyberattacks</li>
						<li>Establish clear procedures for reporting privacy breaches or data misuse</li>
						<li>Include contract clauses or communicate statements on how we handle data</li>
						<li>
							Establish data protection practices (document shredding, secure locks, data
							encryption, frequent backups, access authorization etc).
						</li>
					</ul>
				</Card>
			</Layout.Content>
		);
	}
}

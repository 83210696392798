import { ASSETS_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/assets_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = standardInitialStateGenerator([
	'assetsIndex',
	'WORK_ORDER_FORM_ASSETS_AUTOCOMPLETE',
	'SUPPLIER_WORK_ORDER_FORM_ASSETS_AUTOCOMPLETE',
	'SERVICE_REQUEST_FORM_ASSETS_AUTOCOMPLETE',
	'locationAssociatedAssets',
	'PLANNED_MAINTENANCE_SCHEDULE_FORM_ASSETS_AUTOCOMPLETE',
	'METER_FORM_ASSETS_AUTOCOMPLETE',
	'plannedMaintenanceAdvancedFilters',
	'assetsDowntime',
	'troubleshootingFormIndex',
	'purchaseOrderReceivedAssets',
	'invoicesAdvancedFilters',
	'bulkEditAssets',
]);

export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState);

import * as React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import '@ant-design/compatible/assets/index.css';
import { Input, Spin } from 'antd';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import Mapbox from '../mapbox/Mapbox';

const style = require('./LocationAutocomplete.less');

export class LocationAutocomplete extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = { address: props.initialValue || '' };
	}

	componentWillReceiveProps(newProps) {
		if (this.props.initialValue !== newProps.initialValue) {
			this.setState({
				address: newProps.initialValue,
			});
		}
	}

	handleChange = (address) => {
		this.setState({ address });
	};

	handleBlur = (e) => {
		const { onSelect } = this.props;
		geocodeByAddress(this.state.address)
			.then((results) => {
				if (results.length === 1) {
					getLatLng(results[0]).then((latLng) => {
						const parsedAddressComponents =
							results[0] &&
							results[0].address_components.reduce((acc, ac) => {
								ac.types.map((t) => (acc[t] = ac.long_name));
								return acc;
							}, {});
						const parsedAddress = {
							streetAddress1: [
								parsedAddressComponents.street_number,
								parsedAddressComponents.route,
							].join(' '),
							city: nullSafeGetOrElse(
								'locality',
								parsedAddressComponents,
								nullSafeGetOrElse(
									'sublocality',
									parsedAddressComponents,
									nullSafeGetOrElse(
										'neighborhood',
										parsedAddressComponents,
										nullSafeGetOrElse(
											'administrative_area_level_3',
											parsedAddressComponents,
											parsedAddressComponents.administrative_area_level_2
										)
									)
								)
							),
							region: parsedAddressComponents.administrative_area_level_1,
							postcode: parsedAddressComponents.postal_code,
							country: parsedAddressComponents.country,
						};
						onSelect(this.state.address, latLng, parsedAddress);
					});
				}
			})
			.catch((error) => console.log(error));
	};

	handleSelect = (address) => {
		const { onSelect } = this.props;
		this.setState({ address });
		geocodeByAddress(address)
			.then((results) =>
				getLatLng(results[0]).then((latLng) => {
					const parsedAddressComponents =
						results[0] &&
						results[0].address_components.reduce((acc, ac) => {
							ac.types.map((t) => (acc[t] = ac.long_name));
							return acc;
						}, {});
					const parsedAddress = {
						streetAddress1: [
							parsedAddressComponents.street_number,
							parsedAddressComponents.route,
						].join(' '),
						city: nullSafeGetOrElse(
							'locality',
							parsedAddressComponents,
							nullSafeGetOrElse(
								'sublocality',
								parsedAddressComponents,
								nullSafeGetOrElse(
									'neighborhood',
									parsedAddressComponents,
									nullSafeGetOrElse(
										'administrative_area_level_3',
										parsedAddressComponents,
										parsedAddressComponents.administrative_area_level_2
									)
								)
							)
						),
						region: parsedAddressComponents.administrative_area_level_1,
						postcode: parsedAddressComponents.postal_code,
						country: parsedAddressComponents.country,
					};
					onSelect(address, latLng, parsedAddress);
				})
			)
			.catch((error) => console.log(error));
	};

	render() {
		const { placeholder, lat, lon, searchOptions } = this.props;
		return (
			<div>
				<PlacesAutocomplete
					value={this.state.address}
					onChange={this.handleChange}
					onSelect={this.handleSelect}
				>
					{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
						<div className="locationAutocomplete__container">
							<Input
								size={this.props.size}
								{...getInputProps({
									onBlur: this.handleBlur,
									placeholder: placeholder,
									className: 'location-search-input',
									autoComplete: 'random',
								})}
							/>
							{loading ? (
								<Spin style={{ position: 'absolute', right: 8, top: 12 }} size="small" />
							) : null}
							{suggestions.length > 0 ? (
								<div className="locationAutocomplete__dropdownContainer">
									{suggestions.map((suggestion) => {
										const className = suggestion.active
											? 'locationAutocomplete__suggestionItem--active'
											: 'locationAutocomplete__suggestionItem';
										// inline style for demonstration purpose
										return (
											<div
												{...getSuggestionItemProps(suggestion, {
													className,
													style,
												})}
											>
												<span>{suggestion.description}</span>
											</div>
										);
									})}
								</div>
							) : null}
						</div>
					)}
				</PlacesAutocomplete>
				{lon && lat ? (
					<div style={{ height: 240, width: 240, marginTop: 16, position: 'relative' }}>
						<Mapbox longitude={lon} latitude={lat} baseLayerStyle="light" zoomLevel={7} />
					</div>
				) : null}
			</div>
		);
	}
}

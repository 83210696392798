import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Card } from 'antd';
import { BIDDERS_CRUD_ACTION_CREATORS } from '../../actions/bidders_actions';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import ProposalForm from '../proposal_form/ProposalForm';

const queryString = require('qs');
const { Content } = Layout;

class NewProposalPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
	}

	render() {
		const { location, match, history } = this.props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		const workOrderId = queryParams.workOrderId;
		const requestForProposalId = match.params.id;
		const formData = {
			workOrderId: workOrderId,
			requestForProposalId: requestForProposalId,
		};
		return (
			<Content>
				<LogOnMountWithStandardEventProperties eventType="visited new proposal page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<Card>
							<div>
								<h5 style={{ marginBottom: 32 }}>Create Proposal</h5>
								<ProposalForm
									formData={formData}
									onSuccess={(rec) =>
										history.push(`/supplier/opportunities/detail/${rec.requestForProposal.id}`)
									}
								/>
							</div>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	biddersFormData: state.bidders.formData,
});

const mapDispatchToProps = (dispatch) => ({
	saveFormData: (formData) => dispatch(BIDDERS_CRUD_ACTION_CREATORS.saveFormData(formData)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewProposalPage));

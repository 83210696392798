import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Card } from 'antd';
import ProposalForm from '../proposal_form/ProposalForm';
import { createQuoteBasedOnWorkOrder } from '../../thunks/quotes_thunks';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const queryString = require('qs');
const { Content } = Layout;

class NewSupplierQuotePage extends React.Component<any, any> {
	state = {
		quoteFormData: {},
		quoteFormDataLoading: true,
	};

	componentDidMount() {
		const { createQuoteWithWorkOrderId, match, location, saveFormData } = this.props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}

		const queryParams = queryString.parse(searchString);
		const workOrderId = queryParams.workOrderId || match.params.id;

		createQuoteWithWorkOrderId(workOrderId, {}).then((quote) => {
			this.setState({ quoteFormData: { ...quote }, quoteFormDataLoading: false });
		});
	}

	render() {
		const { history, location, match, quotesFormData } = this.props;

		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}

		const queryParams = queryString.parse(searchString);
		const workOrderId = queryParams.workOrderId || match.params.id;

		return this.state.quoteFormDataLoading ? (
			<PageLoadingPlaceholder />
		) : (
			<Content>
				<LogOnMountWithStandardEventProperties eventType="visited supplier new quote page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<Card>
							<div>
								<h5 style={{ marginBottom: 24 }}>Create Quote</h5>
								<ProposalForm
									formData={this.state.quoteFormData}
									onSuccess={(rec) =>
										history.push(`/supplier/workOrders/detail/${workOrderId}/quotes`)
									}
								/>
							</div>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	createQuoteWithWorkOrderId: (workOrderId, entity) =>
		dispatch(createQuoteBasedOnWorkOrder(workOrderId)(entity)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(NewSupplierQuotePage)
);

import { Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	getSearchStringForBackNavigationWithBackText,
	nullSafeGet,
} from '../../../utils/DataAccessUtils';
import { ROLE_TYPES } from '../../../utils/DataConstants';
import { QuoteRowDisplay } from '../../quote_row_display/QuoteRowDisplay';
import { getLinkWIthBackLinkParams } from '../../../utils/HistoryUtils';

function QuotesTableRow({
	location,
	record,
	userType,
	backText,
	selections,
	targetCollectionName,
}) {
	const routePrefix = userType === ROLE_TYPES.SUPPLIER ? 'supplier' : 'buyer';
	const BACK_TEXT = backText || 'Back to quotes';

	const getLink = () => {
		const path = `/${routePrefix}/quotes/detail/${nullSafeGet('id', record)}/details${
			targetCollectionName ? `?targetCollectionName=${targetCollectionName}` : ''
		}`;
		return getLinkWIthBackLinkParams(location, BACK_TEXT, path, {
			preserve: true,
		});
	};
	return (
		<Row>
			<Link to={getLink()} style={{ width: '100%' }}>
				<QuoteRowDisplay quote={record} />
			</Link>
		</Row>
	);
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(QuotesTableRow);

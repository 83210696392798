import { Button, Form, Modal, Select } from 'antd';
import React, { FC, useCallback, useMemo } from 'react';
import { getPurchaseRequestId, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';

const FORM_ID = 'choose-pr-form';

const ChoosePRForPOLineItemModal: FC<any> = ({
	onCancel,
	onSuccess,
	record,
	diffQuantity,
}): React.ReactElement => {
	const [form] = Form.useForm();

	const onSubmit = useCallback(
		(values) => {
			onSuccess(values.affectedPurchaseRequestLineItemId);
		},
		[onSuccess]
	);

	const prLineItems = useMemo(
		() => nullSafeGetOrElse('supplierPurchaseRequestLineItems', record, []),
		[record]
	);

	const initialValues = useMemo(
		() => ({
			affectedPurchaseRequestLineItemId: nullSafeGet('0.id', prLineItems),
		}),
		[prLineItems]
	);

	const diffMoreThanOne = useMemo(() => Math.abs(diffQuantity) > 1, [diffQuantity]);

	const reduceQuantity = useMemo(() => diffQuantity < 0, [diffQuantity]);

	const entityName = useMemo(
		() => (record.isEquipmentLine ? 'equipment' : `part${diffMoreThanOne ? 's' : ''} `),
		[diffMoreThanOne, record.isEquipmentLine]
	);

	const prLabel = useMemo(
		() =>
			reduceQuantity
				? `Which of the following PR should be reduced by ${Math.abs(diffQuantity)} ${entityName}?`
				: `Which of the following PR should have the ${diffQuantity} new ${entityName} get${
						diffMoreThanOne ? '' : 's'
				  } added to?`,
		[diffMoreThanOne, diffQuantity, entityName, reduceQuantity]
	);

	return (
		<Modal
			visible={true}
			width={600}
			title="Choose Purchase Request"
			closable
			onCancel={onCancel}
			forceRender
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button type="primary" size="large" key="submit" htmlType="submit" form={FORM_ID}>
					Update
				</Button>,
			]}
		>
			<Form
				id={FORM_ID}
				form={form}
				layout="vertical"
				requiredMark={false}
				initialValues={initialValues}
				onFinish={onSubmit}
			>
				<Form.Item
					name="affectedPurchaseRequestLineItemId"
					label={prLabel}
					rules={[
						{
							required: true,
							message: 'Please choose a purchase request to update',
						},
					]}
				>
					<Select style={{ width: '100%' }} placeholder="Select a purchae request">
						{prLineItems.map((poLi) => {
							const existingQuantity = poLi.isEquipmentLine
								? poLi.equipmentQuantity
								: poLi.partQuantity;
							return (
								<Select.Option
									value={poLi.id}
									key={poLi.id}
									disabled={reduceQuantity && existingQuantity < Math.abs(diffQuantity)}
								>
									<Ellipsis tooltip={true} length={50}>
										{`${getPurchaseRequestId(
											nullSafeGet('supplierPurchaseRequest', poLi)
										)} - ${nullSafeGetOrElse('supplierPurchaseRequest.name', poLi, '--')}`}
									</Ellipsis>
									<div>{`Existing Qty: ${existingQuantity}`}</div>
								</Select.Option>
							);
						})}
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default ChoosePRForPOLineItemModal;

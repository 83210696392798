import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import '@ant-design/compatible/assets/index.css';
import { Layout, Row, Col, Card } from 'antd';
import {
	automaticallyAssociateBuyerContactWithFacility,
	manuallyAssociateBuyerContactWithFacility,
} from '../../thunks/buyer_contacts_thunks';
import { buyerFacilitiesRestCrudThunksForBuyer } from '../../thunks/buyer_facilities_thunks';
import { debounce } from '../../utils/PerformanceUtils';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import BuyerCompanyForm from '../buyer_company_form/BuyerCompanyForm';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { getHomePageByRole } from '../../utils/EnvConfigUtils';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;
const style = require('./BuyerNewAccountSetupAssociationPage.less');

class BuyerNewAccountSetupAssociationPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			associated: false,
			loading: true,
			selectedFacilityId: null,
		};
	}

	componentDidMount() {
		const { associateBuyerContactWithFacilityByDomain, getBuyerFacilities, currentUser } =
			this.props;
		if (currentUser.facility && currentUser.facility.id) {
			this.setState({ associated: true, loading: false });
		} else {
			associateBuyerContactWithFacilityByDomain(currentUser)
				.then((res) => {
					this.setState({ associated: true, loading: false });
				})
				.catch((err) => {
					this.setState({ associated: false, loading: false });
				});
		}
	}

	debouncedCallback = debounce(
		(searchText) => {
			const { getBuyerFacilities } = this.props;
			getBuyerFacilities({ displayName: searchText.length > 0 ? searchText.trim() : null });
		},
		500,
		false
	);

	handleSearchDebounced = (searchText) => {
		this.debouncedCallback(searchText);
	};

	handleChange = (selectedFacility) => {
		if (selectedFacility) {
			this.setState({ selectedFacilityId: selectedFacility.key });
		} else {
			this.setState({ selectedFacilityId: undefined });
		}
	};

	handleSubmit = () => {
		const { currentUser, history, associateBuyerContactWithFacility, featureFlags } = this.props;
		const { selectedFacilityId } = this.state;
		associateBuyerContactWithFacility(currentUser, selectedFacilityId).then(() => {
			const homePage = getHomePageByRole('buyer', featureFlags);
			history.push(homePage);
		});
	};

	render() {
		const { buyerFacilities, buyerContacts, featureFlags } = this.props;
		const { associated, loading } = this.state;
		const buyerFacilitiesFetching = buyerFacilities.fetching;
		const buyerContactsFetching = buyerContacts.fetching;
		const buyerFacilitiesRecords = getRecordsForTargetCollection(
			buyerFacilities,
			'buyerFacilitiesAssociationDropdown'
		);
		const homePage = getHomePageByRole('buyer', featureFlags);
		return loading ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="buyerNewAccountSetupAssociationPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited buyer new account setup association page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}

				<Row style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<Card style={{ overflow: 'visible' }}>
							{associated ? (
								<Redirect to={homePage} />
							) : (
								<div style={{ padding: 24 }}>
									<h4 style={{ marginBottom: 16 }}>Set up your company.</h4>
									<p style={{ fontSize: 18, marginBottom: 32 }}>
										It doesn't look like your company has been set up yet. Let's get that taken care
										of first.
									</p>
									<BuyerCompanyForm minimal={true} newAccountSetupMode={true} />
								</div>
							)}
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	currentUser: state.session.currentUser,
	buyerFacilities: state.buyer_facilities,
	buyerContacts: state.buyer_contacts,
	featureFlags: state.session.featureFlags,
});

const mapDispatchToProps = (dispatch) => ({
	associateBuyerContactWithFacilityByDomain: (contact) =>
		dispatch(automaticallyAssociateBuyerContactWithFacility(contact)),
	associateBuyerContactWithFacility: (contact, facilityId) =>
		dispatch(manuallyAssociateBuyerContactWithFacility(contact, facilityId)),
	getBuyerFacilities: (params) =>
		dispatch(
			buyerFacilitiesRestCrudThunksForBuyer.read(params, 'buyerFacilitiesAssociationDropdown')
		),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(BuyerNewAccountSetupAssociationPage)
);

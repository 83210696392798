import React, { useCallback, useMemo, useState } from 'react';
import { Button, Form, Input, Modal, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

const FORM_NAME = 'add-shipment-form';

const ReadyToShipModal = ({
	onSubmitShipment,
	onCancel,
	successCallback,
	requestedPart,
}): React.ReactElement => {
	const [form] = useForm();

	const [saving, setSaving] = useState(false);

	const isUpdateShipment = useMemo(() => !!requestedPart, [requestedPart]);

	const onSubmit = useCallback(
		(values) => {
			setSaving(true);
			onSubmitShipment(values)
				.then(() => successCallback && successCallback())
				.catch((err) => {
					console.log(err);
					message.error('Unable to add shipment!');
				})
				.finally(() => setSaving(false));
		},
		[onSubmitShipment, successCallback]
	);

	return (
		<Modal
			visible={true}
			width={600}
			title={isUpdateShipment ? 'Update Tracking Number' : 'Add Shipment'}
			closable
			onCancel={onCancel}
			forceRender
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button
					type="primary"
					size="large"
					style={{ marginLeft: '16px' }}
					key="submit"
					htmlType="submit"
					form={FORM_NAME}
					loading={saving}
				>
					{isUpdateShipment ? 'Update' : 'Add Shipment'}
				</Button>,
			]}
		>
			<Form
				form={form}
				id={FORM_NAME}
				initialValues={requestedPart}
				layout="vertical"
				requiredMark={false}
				onFinish={onSubmit}
			>
				<Form.Item name="trackingNumber" label="Tracking Number">
					<Input style={{ width: '100%' }} />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default withRouter<any, any>(connect()(ReadyToShipModal));

import { Button, Card, Tooltip, message } from 'antd';
import React, { FC, useCallback, useMemo, useState } from 'react';

import RichTextEditorModal from './RichTextEditorModal';

interface OWEditableNotesComponentProps {
	id: string;
	tooltipMessage: string;
	title: string;
	allowEdit?: boolean;
	loading?: boolean;
	notes: string;
	onSaveNotes?: (notes: string) => any;
	onSaveSuccess?: (result: any) => any;
	okTextSuffix?: string;
	useRichText?: boolean;
}

const OWEditableNotesComponent: FC<OWEditableNotesComponentProps> = ({
	id,
	tooltipMessage,
	title,
	allowEdit,
	loading,
	notes,
	onSaveNotes,
	onSaveSuccess,
	okTextSuffix = 'Notes',
	useRichText = true,
}) => {
	const [saving, setSaving] = useState(false);

	const isUpdate = useMemo(() => !!(notes || '').trim(), [notes]);

	const [notesModalVisible, setNotesModalVisible] = useState(false);

	const openEditNotesModal = useCallback(() => setNotesModalVisible(true), []);

	const hideNotesModal = useCallback(() => setNotesModalVisible(false), []);

	const onSubmit = useCallback(
		(text) => {
			setSaving(true);
			onSaveNotes &&
				onSaveNotes(text)
					.then((res) => onSaveSuccess && onSaveSuccess(res))
					.catch(() => message.error('Failed to save notes'))
					.finally(() => {
						setSaving(false);
						hideNotesModal();
					});
		},
		[hideNotesModal, onSaveNotes, onSaveSuccess]
	);

	return (
		<>
			<Tooltip placement="top" title={tooltipMessage}>
				<Card
					title={title}
					extra={
						!loading &&
						allowEdit && (
							<Button type="link" onClick={openEditNotesModal}>
								{isUpdate ? 'Edit' : 'Add'}
							</Button>
						)
					}
					loading={loading}
				>
					{useRichText ? (
						<div
							className={'richTextWrapper'}
							dangerouslySetInnerHTML={{
								__html: notes || '',
							}}
						/>
					) : (
						<div>{notes || ''}</div>
					)}
				</Card>
			</Tooltip>

			{notesModalVisible && (
				<RichTextEditorModal
					id={id}
					title={isUpdate ? `Edit ${title}` : `Add ${title}`}
					onCancel={hideNotesModal}
					loading={saving}
					okText={isUpdate ? `Update ${okTextSuffix}` : `Add ${okTextSuffix}`}
					onSubmit={onSubmit}
					useRichText={useRichText}
					notes={notes}
				/>
			)}
		</>
	);
};

export default OWEditableNotesComponent;

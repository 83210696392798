import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';

require('./PurchaseRequestsPage.less');
export default class PurchaseRequestsPage extends React.Component<any, null> {
	render() {
		return (
			<AccessPermissionChecker name={PERMISSION_NAMES.ACCESS_PR_AND_PO} isPage>
				<div>
					<Route
						path="/supplier/purchaseRequests"
						exact={true}
						render={(props) => (
							<Redirect
								to={{
									pathname: '/supplier/purchaseRequests/overview',
								}}
							/>
						)}
					/>
					{this.props.childRoutes.map((r, i) => (
						<RouteWithSubRoutes key={i} {...r} />
					))}
				</div>
			</AccessPermissionChecker>
		);
	}
}

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PartForm from '../part_form/PartForm';
import { partsRestCrudThunksForSupplier } from '../../thunks/parts_thunks';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const style = require('./EditPartPage.less');

class EditPartPage extends React.Component<any, any> {
	componentDidMount() {
		const { getPart, match } = this.props;
		const id = match.params.id;
		getPart(id);
	}

	render() {
		const { history, part, fetching } = this.props;

		//noinspection TypeScriptUnresolvedVariable
		return [
			<LogOnMountWithStandardEventProperties eventType="visited supplier edit part page" />,
			<EditPage
				title="Edit Part"
				formComponentProps={{
					formData: part,
					onSuccess: (rec) => history.push(`/supplier/parts/detail/${rec.id}`),
				}}
				fetching={fetching}
				FormComponent={PartForm}
			/>,
		];
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		history: ownProps.history,
		part: state.parts.detail,
		fetching: state.parts.fetching,
		match: ownProps.match,
	};
};

const mapDispatchToProps = (dispatch) => ({
	getPart: (id) => dispatch(partsRestCrudThunksForSupplier.readOne(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditPartPage));

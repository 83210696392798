import * as React from 'react';
import { Spin, Select } from 'antd';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { debounce } from '../../utils/PerformanceUtils';
import { ReactNode } from 'react';
import { equalsIngnoreCase, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import * as _ from 'lodash';
import { PlusCircleOutlined } from '@ant-design/icons';

const CREATE_TEXT = 'Create';

interface OWAsyncSelectProps {
	stateSlice: any;
	targetCollectionName: string;
	placeholder?: string;
	disabledPlaceholder?: string;
	valueAccessor?: any;
	additionalFilters?: any;
	initialPagination?: any;
	allowClear?: boolean;
	size?: 'large' | 'small';
	style?: any;
	mode?: 'multiple' | 'tags';
	value?: any;
	onChange?: any;
	loading?: boolean;
	disabled?: boolean;
	emptyWhenDisabled?: boolean;
	sortBy?: any;
	dropdownRender?: any;
	labelAccessor?: any;
	hiddenRecords?: any;

	fetchMultiple(ids: string[] | number[], targetCollectionName?: string): void;

	renderRecord(data: any, index?: any): ReactNode;

	fetchData(
		searchText: string,
		targetCollectionName: string,
		pagination?: any,
		sorting?: any,
		filters?: any,
		addToTargetCollection?: boolean
	): void;

	newEntityLabelChecker?: (entity: any) => any;
	createNewEntity?: (entity: any) => any;
}

const defaultPagination = { current: 1, pageSize: 50 };

export default class OWAsyncSelect extends React.Component<OWAsyncSelectProps, any> {
	constructor(props) {
		super(props);
		const value = props.value;
		this.state = {
			value: value,
			creatingEntity: false,
		};
	}

	componentWillReceiveProps(nextProps) {
		// Should be a controlled component.
		if ('value' in nextProps) {
			this.setState({ value: nextProps.value });
		}
		if (!_.isEqual(nextProps.additionalFilters, this.props.additionalFilters)) {
			this.props.fetchData(
				this.state.searchText,
				nextProps.targetCollectionName,
				this.props.initialPagination || defaultPagination,
				nextProps.sortBy,
				nextProps.additionalFilters,
				false
			);
		}

		return null;
	}

	handleLoadMore = debounce(() => {
		const { stateSlice, loading, targetCollectionName } = this.props;
		if (loading) {
			return;
		}
		let pagination = targetCollectionName
			? nullSafeGet(`${targetCollectionName}.pagination`, stateSlice)
			: nullSafeGet(`pagination`, stateSlice);
		const newCurrent = pagination.current + 1;
		const newPagination = { current: newCurrent, pageSize: pagination.pageSize };
		const count = nullSafeGet(`${this.props.targetCollectionName}.count`, this.props.stateSlice);
		if ((newCurrent - 1) * pagination.pageSize <= count) {
			this.props.fetchData(
				this.state.searchText,
				this.props.targetCollectionName,
				newPagination,
				this.props.sortBy,
				this.props.additionalFilters,
				true
			);
			this.setState({ pagination: newPagination });
		}
	}, 500);

	componentDidMount() {
		const { value } = this.state;
		const { targetCollectionName } = this.props;
		if (value) {
			this.props.fetchMultiple(Array.isArray(value) ? value : [value]);
			this.props.fetchData(
				'',
				targetCollectionName,
				this.props.initialPagination || defaultPagination,
				this.props.sortBy,
				this.props.additionalFilters,
				false
			);
		} else {
			this.props.fetchData(
				'',
				targetCollectionName,
				this.props.initialPagination || defaultPagination,
				this.props.sortBy,
				this.props.additionalFilters,
				false
			);
		}
	}

	checkAndCreateIfCreateEntry = (val) => {
		const possibleCreateEntry = val
			? Array.isArray(val)
				? val.filter((_) => !_.value)
				: !val.value
				? [val]
				: null
			: null;
		possibleCreateEntry &&
			possibleCreateEntry.length > 0 &&
			possibleCreateEntry[0].label &&
			possibleCreateEntry[0].label.length > 1 &&
			nullSafeGet('props.children', possibleCreateEntry[0].label[1]) &&
			nullSafeGet('props.children', possibleCreateEntry[0].label[1]).startsWith(CREATE_TEXT) &&
			this.addNewItem();
	};

	handleValueChange = (val) => {
		this.checkAndCreateIfCreateEntry(val);
		let updatedVal = val
			? Array.isArray(val)
				? val.filter((_) => !!_.value).map((v) => v.value)
				: val.value
			: val;
		if (!('value' in this.props)) {
			this.setState({ value: updatedVal });
		}
		this.triggerChange(updatedVal);
	};

	triggerChange = (changedValue) => {
		// Should provide an event to pass value to Form.
		const onChange = this.props.onChange;
		if (onChange) {
			onChange(changedValue);
		}
	};

	handleSearch = debounce(
		(searchText) => {
			this.props.fetchData(
				searchText && searchText.trim(),
				this.props.targetCollectionName,
				this.props.initialPagination || defaultPagination,
				this.props.sortBy,
				this.props.additionalFilters,
				false
			);
			this.setState({
				searchText: searchText && searchText.trim(),
				pagination: this.props.initialPagination || defaultPagination,
			});
		},
		200,
		false
	);

	triggerNewItemValueChange = (record) => {
		const { valueAccessor = (el) => el.id, mode } = this.props;
		const newValue = { value: valueAccessor(record) };
		const newSelectedVal =
			mode === 'multiple'
				? [...(this.state.value || []).map((_) => ({ value: _ })), ...[newValue]]
				: newValue;
		this.handleValueChange(newSelectedVal);
	};

	addNewItem = () => {
		const { createNewEntity } = this.props;
		this.setState({ creatingEntity: true });
		const newVal = this.state.searchText;
		createNewEntity(newVal)
			.then((record) => {
				this.setState({ searchText: '' });
				if (record) {
					this.triggerNewItemValueChange(record);
					this.handleSearch(newVal);
				} else this.handleSearch('');
			})
			.finally(() => this.setState({ creatingEntity: false }));
	};

	render() {
		const { value } = this.state;
		const {
			disabled,
			allowClear = false,
			loading,
			emptyWhenDisabled,
			valueAccessor = (el) => el && el.id,
			stateSlice,
			targetCollectionName,
			mode,
			placeholder,
			disabledPlaceholder,
			size,
			style,
			renderRecord,
			dropdownRender,
			createNewEntity,
			labelAccessor = (el) => el && el.name,
			hiddenRecords = [],
			newEntityLabelChecker,
		} = this.props;

		const selectedVals = value ? (Array.isArray(value) ? value : [value]) : [];
		const selectedRecords = selectedVals
			.map((v) => stateSlice.records[v])
			.filter((r) => r && valueAccessor(r));
		const labels =
			selectedRecords.length > 0
				? selectedRecords.map((s) => (labelAccessor ? labelAccessor(s) : s.name))
				: [];
		const keysAndLabels = selectedVals.map((e, i) => {
			return { key: e, label: labels[i] };
		});

		const dropdownRecords = getRecordsForTargetCollection(stateSlice, targetCollectionName).filter(
			(record = {}) => !hiddenRecords.includes(record.id)
		);
		const visibleRecords = selectedRecords.concat(
			dropdownRecords.filter((r) => !selectedVals.some((val) => valueAccessor(r) == val))
		);
		const fetching = stateSlice.fetching;
		const noVisibleRecordsWithoutSearch =
			nullSafeGetOrElse('searchText.length', this.state, 0) === 0 && visibleRecords.length === 0;

		const labelCompare = newEntityLabelChecker || labelAccessor;

		const canShowCreateNewEntity =
			(dropdownRecords.length < 1 ||
				!dropdownRecords.find((_) => equalsIngnoreCase(labelCompare(_), this.state.searchText))) &&
			(this.state.creatingEntity || this.state.searchText) &&
			!!createNewEntity &&
			!fetching;

		return (
			<div className="flex flex-col items-start justify-start">
				<Select
					notFoundContent={fetching ? <Spin size="small" /> : null}
					dropdownRender={dropdownRender}
					placeholder={
						this.state.creatingEntity
							? 'Creating...'
							: disabled || noVisibleRecordsWithoutSearch
							? disabledPlaceholder
							: placeholder
					}
					filterOption={false}
					loading={loading || this.state.creatingEntity}
					showSearch={true}
					onPopupScroll={(e) => {
						if (
							nullSafeGetOrElse('target.scrollHeight', e, 0) ==
							nullSafeGet('target.scrollTop', e) + nullSafeGet('target.offsetHeight', e)
						) {
							this.handleLoadMore();
						}
					}}
					mode={mode}
					searchValue={this.state.searchValue}
					onSearch={this.handleSearch}
					onChange={this.handleValueChange}
					size={size}
					style={style}
					allowClear={allowClear}
					labelInValue={true}
					value={disabled && emptyWhenDisabled ? [] : keysAndLabels}
					disabled={disabled || (noVisibleRecordsWithoutSearch && !createNewEntity)}
				>
					{disabled && emptyWhenDisabled
						? []
						: visibleRecords.map((d) => renderRecord(d, dropdownRecords))}
					{canShowCreateNewEntity && (
						<div className="flex cursor-pointer flex-row border-0 border-t-2 border-dashed border-gray-300 font-bold">
							<PlusCircleOutlined translate="" />
							<span className="ml-2">{`${this.state.creatingEntity ? 'Creating' : CREATE_TEXT} "${
								this.state.searchText
							}"`}</span>
						</div>
					)}
				</Select>
			</div>
		);
	}
}

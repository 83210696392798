import * as React from 'react';
import { BLACKS_AND_WHITES, STOPLIGHT_COLORS } from '../../utils/DataFormatterUtils';

export default ({ status, darkMode = false, style = {}, textStyle = {}, ...props }) => {
	let statusColor;
	let statusDisplayName;
	let statusBackgroundColor;

	switch (status) {
		case 'Pending':
			statusColor = '#4a1e87';
			statusBackgroundColor = '#e8def8';
			statusDisplayName = 'Requested';
			break;
		case 'Open':
			statusColor = STOPLIGHT_COLORS.pureRed;
			statusDisplayName = 'Open';
			statusBackgroundColor = '#f9e6e6';
			break;
		case 'Deferred':
			statusColor = 'rgb(213, 125, 5)';
			statusDisplayName = 'Deferred';
			statusBackgroundColor = 'rgb(254, 234, 205)';
			break;
		case 'InProgress':
			statusColor = 'rgb(11, 28, 111)';
			statusDisplayName = 'In Progress';
			statusBackgroundColor = 'rgb(225, 229, 249)';
			break;
		case 'OnHold':
			statusColor = 'rgb(137, 107, 12)';
			statusDisplayName = 'On Hold';
			statusBackgroundColor = '#fcf3d9';
			break;
		case 'Completed':
			statusColor = STOPLIGHT_COLORS.pureGreen;
			statusDisplayName = 'Completed';
			statusBackgroundColor = '#e6f9e7';
			break;
		case 'Cancelled':
			statusColor = BLACKS_AND_WHITES.blackPrimary;
			statusDisplayName = 'Cancelled';
			statusBackgroundColor = '#f2f2f2';
			break;
		default:
			statusColor = BLACKS_AND_WHITES.blackPrimary;
			statusDisplayName = 'Unknown';
			statusBackgroundColor = '#f2f2f2';
	}

	if (darkMode) {
		statusColor = BLACKS_AND_WHITES.whiteSecondary;
	}

	return (
		<span
			{...props}
			style={{
				display: 'flex',
				flexWrap: 'nowrap',
				whiteSpace: 'nowrap',
				color: statusColor,
				background: statusBackgroundColor,
				padding: '4px 12px',
				borderRadius: 16,
				...style,
				...textStyle,
			}}
		>
			{statusDisplayName}
		</span>
	);
};

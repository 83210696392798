import * as React from 'react';
import { Layout, Row, Col, BackTop, Card, Button, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { KeyValueDisplay } from '../key_value_display/KeyValueDisplay';
import ScrollToTopOnMount from '../scroll_to_top_on_mount/ScrollToTopOnMount';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import {
	reviewWorkOrderAndMarkCompleteForBuyer,
	reviewWorkOrderAndMarkUnsatisfactoryForBuyer,
	workOrdersRestCrudThunksForBuyer,
	reviewInternalTechWorkOrderAndMarkCompleteForBuyer,
	reviewInternalWorkOrderAndMarkUnresolvedForBuyer,
	appendWorkOrderNotesForBuyer,
	cancelWorkOrderForBuyer,
	planWorkOrderForBuyer,
	startWorkOrderForBuyer,
	finishWorkOrderForBuyer,
	partsOrderedWorkOrderForBuyer,
	partsReceivedWorkOrderForBuyer,
} from '../../thunks/work_orders_thunks';
import { getProtectedImageUriForBuyer } from '../../utils/FileAccessUtils';
import {
	getCurrency,
	getEntityById,
	getPONumbers,
	isAdminUser,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { dateFormatter } from '../../utils/DataFormatterUtils';
import { WORK_ORDER_STATUSES, SELF_MANAGEMENT_STATUSES } from '../../constants/WorkOrderStatuses';
import AssetCardDisplay from '../asset_card_display/AssetCardDisplay';
import LocationCardDisplay from '../location_card_display/LocationCardDisplay';
import { ProtectedImageThumbnails } from '../protected_image_thumbnails/ProtectedImageThumbnails';
import moment from 'moment';
import ApproveWorkForm from '../approve_review_work_form/ApproveReviewWorkForm';
import RejectWorkForm from '../reject_work_form/RejectWorkForm';
import FileUploader from '../file_uploader/FileUploader';
import ReminderForm from '../reminder_form/ReminderForm';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import PartsOrderedOrReceivedForm from '../parts_ordered_form/PartsOrderedOrReceivedForm';
import StartWorkForm from '../start_work_form/StartWorkForm';
import FinishWorkForm from '../finish_work_form/FinishWorkForm';
import { BUYER_ONLY_NO_STORE_ASSOCIATE } from '../roles/roles';
import OWResult from '../OWResult';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import ServiceProviderCardDisplay from '../service_provider_card_display/ServiceProviderCardDisplay';
import { markUserNotificationsReadForBuyer } from '../../thunks/user_notifications_thunks';
import { UserName } from '../name_component/user/UserNameComponent';
import WorkOrderPrintable from '../WorkOrderPrintable';
import QuestionnaireSurvey from '../PostCompletionQuestionnaire/QuestionnaireSurvey';
import RichTextEditorModal from '../common/RichTextEditorModal';
import { EditOutlined } from '@ant-design/icons';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import WorkOrderActions from '../work_order_actions/WorkOrderActions';
import WorkOrderInternalContact from './WorkOrderInternalContact';
import { assetDowntimeGet } from '../../thunks/assets_thunks';
import AssetStatusForm from '../asset_status_form/AssetStatusForm';
import TroubleshootingDetailDisplay from './TroubleshootingDetailDisplay';
import TimeAgoHover from '../date_time_component/DateTime';
import DateTimeHover from '../date_time_component/DateTime';
import ServiceCallDetails from './ServiceCallDetails';
import HyperLink from '../common/HyperLink';

const { Content } = Layout;

require('./WorkOrdersDetailDetailsPage.less');
const queryString = require('qs');

class WorkOrdersDetailPage extends React.Component<any, any> {
	formRefs: any;

	constructor(props) {
		super(props);
		this.formRefs = {};
		this.state = {
			loading: false,
			approveWorkModalVisible: false,
			rejectWorkModalVisible: false,
			reminderModalVisible: false,
			partsOrderedModalVisible: false,
			startWorkModalVisible: false,
			finishWorkModalVisible: false,
			locationDrawerVisible: true,
			onlyReview: false,
			tab: 'active',
			error: null,
			addDetailModalVisible: false,
			addingDetails: false,
			downTimeHistory: [],
		};

		const { location } = props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);

		if (queryParams.backlinkParams) {
			const { tab } = queryString.parse(queryParams.backlinkParams);
			this.setState({
				tab: tab ? tab : 'active',
			});
		}

		this.showApproveModal = this.showApproveModal.bind(this);
		this.showReviewModal = this.showReviewModal.bind(this);
		this.showRejectModal = this.showRejectModal.bind(this);
		this.showReminderModal = this.showReminderModal.bind(this);
		this.handleApproveWorkSubmit = this.handleApproveWorkSubmit.bind(this);
		this.handleApproveWorkCancel = this.handleApproveWorkCancel.bind(this);
		this.handleRejectWorkSubmit = this.handleRejectWorkSubmit.bind(this);
		this.handleRejectWorkCancel = this.handleRejectWorkCancel.bind(this);
		this.handleReminderSubmit = this.handleReminderSubmit.bind(this);
		this.handleReminderCancel = this.handleReminderCancel.bind(this);
		this.handlePartsOrdered = this.handlePartsOrdered.bind(this);
		this.handlePartsOrderedSubmit = this.handlePartsOrderedSubmit.bind(this);
		this.handlePartsOrderedCancel = this.handlePartsOrderedCancel.bind(this);
		this.handlePartsReceived = this.handlePartsReceived.bind(this);
		this.handlePartsReceivedSubmit = this.handlePartsReceivedSubmit.bind(this);
		this.handlePartsReceivedCancel = this.handlePartsReceivedCancel.bind(this);
		this.handleStartWork = this.handleStartWork.bind(this);
		this.handleStartWorkSubmit = this.handleStartWorkSubmit.bind(this);
		this.handleStartWorkCancel = this.handleStartWorkCancel.bind(this);
		this.handleFinishWork = this.handleFinishWork.bind(this);
		this.handleFinishWorkSubmit = this.handleFinishWorkSubmit.bind(this);
		this.handleFinishWorkCancel = this.handleFinishWorkCancel.bind(this);
	}

	refreshWorkOrder = () => {
		const { getWorkOrder, match } = this.props;
		const workOrderId = match.params.id;
		const workOrder = getWorkOrder(workOrderId)
			.then((wo) => {
				this.setState({ loading: false });
				return wo;
			})
			.catch((e) => {
				this.setState({ loading: false, error: e });
			});
		return workOrder;
	};

	componentDidMount() {
		const { markUserNotificationsRead, workOrder } = this.props;
		const handleMountActions = (workOrder) => {
			const { match } = this.props;
			const action = match.params.action;

			if (action === 'approve') {
				this.handleApprove(workOrder);
			} else if (action === 'edit') {
				this.handleEdit(workOrder);
			} else if (action === 'cancel') {
				this.handleCancel(workOrder);
			} else if (action === 'archive') {
				this.handleArchive(workOrder);
			} else if (action === 'unarchive') {
				this.handleUnarchive(workOrder);
			} else if (action === 'approveWork') {
				this.setState({ approveWorkModalVisible: true });
			} else if (action === 'rejectWork') {
				this.setState({ rejectWorkModalVisible: true });
			} else if (action === 'reviewWork') {
				this.setState({ onlyReview: true, approveWorkModalVisible: true });
			} else if (action === 'reminder') {
				this.setState({ reminderModalVisible: true });
			}

			nullSafeGet('id', workOrder) && markUserNotificationsRead(workOrder.id);
		};
		if (workOrder && workOrder.id) {
			handleMountActions(workOrder);
		} else {
			this.refreshWorkOrder().then((workOrder) => {
				handleMountActions(workOrder);
			});
		}
	}
	componentDidUpdate(prevProps) {
		const { workOrder, markUserNotificationsRead } = this.props;
		const { workOrder: prevWorkOrder } = prevProps;
		if (workOrder && prevWorkOrder && workOrder.id !== prevWorkOrder.id) {
			markUserNotificationsRead(workOrder.id);
		}
	}

	handleEdit = (workOrder) => {
		const { history } = this.props;
		history.push(`/buyer/workOrders/edit/${workOrder.id}`);
	};

	handleApprove = (workOrder) => {
		const { history } = this.props;
		history.push(`/buyer/workOrders/approve/${workOrder.id}`);
	};

	handleAssetStatusChange = () => {
		const { workOrder, getAssetDowntime } = this.props;
		const asset = nullSafeGet('asset', workOrder);
		getAssetDowntime({ assetId: asset.id }, ROLE_TYPES.BUYER).then((data = []) => {
			this.setState({ downTimeHistory: data, assetStatusChangeModalVisible: true });
		});
	};
	handleAssetStatusCancel = () => {
		this.setState({ assetStatusChangeModalVisible: false });
	};
	handleAssetStatusSuccess = () => {
		this.setState({ assetStatusChangeModalVisible: false });
		this.refreshWorkOrder();
	};

	handlePartsOrdered = (e) => {
		this.setState({
			partsOrderedModalVisible: true,
		});
		e.stopPropagation();
		e.preventDefault();
	};

	handlePartsReceived = (e) => {
		this.setState({
			partsReceivedModalVisible: true,
		});
		e.stopPropagation();
		e.preventDefault();
	};

	handlePartsOrderedSubmit = () => {
		const { currentUser, partsOrderedWorkOrder, workOrder } = this.props;
		const form = this.formRefs['partsOrderedForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			if (values.note) {
				const note = {
					text: values.note,
					noteAddedBy: currentUser.email,
					noteAddedAt: moment(),
				};
				partsOrderedWorkOrder({ ...workOrder, note }).then(() => {
					this.setState({ partsOrderedModalVisible: false });
					this.refreshWorkOrder();
					message.success(`Parts ordered.`);
				});
			} else {
				partsOrderedWorkOrder(workOrder).then(() => {
					this.setState({ partsOrderedModalVisible: false });
					this.refreshWorkOrder();
					message.success(`Parts ordered.`);
				});
			}
		});
	};

	handlePartsReceivedSubmit = () => {
		const { currentUser, partsReceivedWorkOrder, workOrder } = this.props;
		const form = this.formRefs['partsReceivedForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			if (values.note) {
				const note = {
					text: values.note,
					noteAddedBy: currentUser.email,
					noteAddedAt: moment(),
				};
				partsReceivedWorkOrder({ ...workOrder, note }).then(() => {
					this.setState({ partsReceivedModalVisible: false });
					this.refreshWorkOrder();
					message.success(`Parts Received.`);
				});
			} else {
				partsReceivedWorkOrder(workOrder).then(() => {
					this.setState({ partsReceivedModalVisible: false });
					this.refreshWorkOrder();
					message.success(`Parts Received.`);
				});
			}
		});
	};

	handlePartsOrderedCancel = () => {
		this.setState({
			partsOrderedModalVisible: false,
		});
	};

	handlePartsReceivedCancel = () => {
		this.setState({
			partsReceivedModalVisible: false,
		});
	};

	handleStartWork = (e) => {
		this.setState({
			startWorkModalVisible: true,
		});
		e.stopPropagation();
		e.preventDefault();
	};

	handleStartWorkSubmit = () => {
		const { startWorkOrder, currentUser, workOrder } = this.props;
		const form = this.formRefs['startWorkForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			if (values.note) {
				const note = {
					text: values.note,
					noteAddedBy: currentUser.email,
					noteAddedAt: moment(),
				};

				startWorkOrder({ ...workOrder, note: note }).then(() => {
					this.setState({ startWorkModalVisible: false });
					this.refreshWorkOrder();
					message.success(`Work started.`);
				});
			} else {
				startWorkOrder(workOrder).then(() => {
					this.setState({ startWorkModalVisible: false });
					this.refreshWorkOrder();
					message.success(`Work started.`);
				});
			}
		});
	};

	handleStartWorkCancel = () => {
		this.setState({
			startWorkModalVisible: false,
		});
	};

	handleFinishWork = (e) => {
		this.setState({
			finishWorkModalVisible: true,
		});
		e.stopPropagation();
		e.preventDefault();
	};

	handleFinishWorkSubmit = () => {
		const { finishWorkOrder, currentUser } = this.props;
		const form = this.formRefs['finishWorkForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			if (values.note) {
				const note = {
					text: values.note,
					noteAddedBy: currentUser.email,
					noteAddedAt: moment(),
				};
				finishWorkOrder({ id: values.workOrderId, note }).then(() => {
					this.setState({ finishWorkModalVisible: false });
					this.refreshWorkOrder();
					message.success(`Work finished.`);
				});
			} else {
				finishWorkOrder({ id: values.workOrderId }).then(() => {
					this.setState({ finishWorkModalVisible: false });
					this.refreshWorkOrder();
					message.success(`Work finished.`);
				});
			}
		});
	};

	handleFinishWorkCancel = () => {
		this.setState({
			finishWorkModalVisible: false,
		});
	};

	handleArchive = (workOrder) => {
		const { history, archiveWorkOrder } = this.props;
		const { tab } = this.state;
		archiveWorkOrder(workOrder).then(() => {
			history.push(`/buyer/workOrders/overview/${tab}`);
		});
	};

	handleCancel = (workOrder) => {
		const { history, cancelWorkOrder } = this.props;
		cancelWorkOrder(workOrder).then(() => {
			history.push(`/buyer/workOrders/detail/${workOrder.id}`);
		});
	};

	handleUnarchive = (workOrder) => {
		const { history, unarchiveWorkOrder } = this.props;
		const { tab } = this.state;
		unarchiveWorkOrder(workOrder).then(() => {
			history.push(`/buyer/workOrders/overview/${tab}`);
		});
	};

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	handleApproveWorkSubmit() {
		const {
			reviewWorkOrderAndMarkComplete,
			reviewInternalTechWorkOrderAndMarkComplete,
			workOrder,
			currentUser,
		} = this.props;
		const form = this.formRefs['approveWorkForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			const note = values.note
				? {
						text: values.note,
						noteAddedBy: currentUser.email,
						noteAddedAt: moment(),
				  }
				: undefined;

			const workOrderStatusChangeDetails = {
				id: values.workOrderId,
				note,
			};

			nullSafeGetOrElse('supplierFacility.status', workOrder, 'active') === 'private'
				? reviewInternalTechWorkOrderAndMarkComplete(workOrderStatusChangeDetails).then(() => {
						this.setState({ approveWorkModalVisible: false });
						message.success(`Work reviewed.`);
				  })
				: reviewWorkOrderAndMarkComplete(workOrderStatusChangeDetails).then(() => {
						this.setState({ approveWorkModalVisible: false });
						message.success(`Work approved.`);
				  });
		});
	}

	handleFileAttachmentUploadChange = (newAttachments) => {
		const { workOrder, updateWorkOrder } = this.props;
		const newWorkOrder = {
			...workOrder,
			buyerAttachments: newAttachments,
		};
		updateWorkOrder(newWorkOrder);
	};

	handleApproveWorkCancel(e) {
		this.setState({
			approveWorkModalVisible: false,
		});
	}

	showApproveModal(e) {
		this.setState({
			approveWorkModalVisible: true,
		});
	}

	showReviewModal(e) {
		this.setState({
			onlyReview: true,
			approveWorkModalVisible: true,
		});
	}

	handleRejectWorkSubmit() {
		const { reviewWorkOrderAndMarkUnsatisfactory, currentUser } = this.props;
		const form = this.formRefs['rejectWorkForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			const note = {
				text: values.note,
				noteAddedBy: currentUser.email,
				noteAddedAt: moment(),
			};
			const workOrderStatusChangeDetails = {
				id: values.workOrderId,
				note,
			};

			reviewWorkOrderAndMarkUnsatisfactory(workOrderStatusChangeDetails).then(() => {
				this.setState({ rejectWorkModalVisible: false });
				message.success(`Work rejected.`);
			});
		});
	}

	handleRejectWorkCancel(e) {
		this.setState({
			rejectWorkModalVisible: false,
		});
	}

	showRejectModal(e) {
		this.setState({
			rejectWorkModalVisible: true,
		});
	}

	handleReminderSubmit() {
		const { appendWorkOrderNotes, currentUser, workOrder } = this.props;
		const form = this.formRefs['reminderForm'].props.form;

		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			const note = {
				text: values.note,
				noteAddedBy: currentUser.email,
				noteAddedAt: moment(),
			};
			const workOrderNoteDetails = {
				id: values.workOrderId,
				locationId: workOrder.locationId,
				note,
			};

			appendWorkOrderNotes(workOrderNoteDetails).then(() => {
				this.setState({ reminderModalVisible: false });
				message.success(`reminder sent.`);
			});
		});
	}

	handleReminderCancel(e) {
		this.setState({
			reminderModalVisible: false,
		});
	}

	showReminderModal(e) {
		this.setState({
			reminderModalVisible: true,
		});
	}

	showAddDetailModal = () => this.setState({ addDetailModalVisible: true });

	hideAddDetailModal = () => this.setState({ addDetailModalVisible: false });

	onAddDetailSubmit = (description) => {
		this.setState({ addingDetails: true });
		const { workOrder, updateWorkOrder } = this.props;

		updateWorkOrder({
			...workOrder,
			description,
		}).finally(() => {
			this.setState({
				addingDetails: false,
			});
			this.hideAddDetailModal();
		});
	};

	render() {
		const { workOrder, currentUser, companyConfig, userType } = this.props;
		const { loading, error } = this.state;
		let photoUris = [];
		if (workOrder.images) {
			photoUris = workOrder.images.map((locImg) => {
				const [imgFileId, imgFileName] = locImg.split('/');
				return getProtectedImageUriForBuyer(imgFileId, imgFileName, 1200, 800);
			});
		}

		let optionalDetails = {};
		if (workOrder.buyerProjects && workOrder.buyerProjects.length > 0) {
			optionalDetails['Projects'] = workOrder.buyerProjects.map((project, i, projects) =>
				projects.length - 1 === i ? project.title : project.title.concat(', ')
			);
		}
		if (nullSafeGet('isWarranty', workOrder)) {
			optionalDetails['Warranty Status'] = 'Under Warranty';
		}
		const workOrderCurrency = getCurrency({ workOrder });
		let workOrderDetails = loading
			? {}
			: {
					'Work Order ID': `# ${nullSafeGet('id', workOrder)}`,
					...(nullSafeGet('woNumber', workOrder) && {
						'Alias Work Order #': nullSafeGet('woNumber', workOrder),
					}),
					Reported: <DateTimeHover timestamp={workOrder.createdAt} />,
					Due: <DateTimeHover timestamp={workOrder.dueDate} />,
					NTE: nullSafeGet('nte', workOrder)
						? workOrderCurrency.format(nullSafeGet('nte', workOrder))
						: nullSafeGet('nte', workOrder),
					Trade: nullSafeGet('spendCategory.name', workOrder),
					'Problem Type': nullSafeGet('problemType.hierarchyName', workOrder),
					Area: nullSafeGet('area.hierarchyName', workOrder),
					...(getPONumbers(workOrder) && {
						'Purchase Order #': getPONumbers(workOrder),
					}),
					'Expense Type': nullSafeGet('isCapex', workOrder) ? 'CapEx' : 'Repair & Maintenance',
					...(nullSafeGetOrElse('config.capExConfig.acceptCapExCategory', companyConfig, false) &&
						nullSafeGet('isCapex', workOrder) && {
							'CapEx Category': workOrder.capExCategory || '--',
						}),
					...optionalDetails,
					...(nullSafeGet('assetId', workOrder) &&
						nullSafeGet('isAssetDown', workOrder) && {
							'Asset Reported As': (
								<span>
									{nullSafeGet('isAssetDown', workOrder) ? 'Offline' : 'Online'}{' '}
									{nullSafeGet('asset.downtimeStatus', workOrder) === 'online' ? (
										<span className="text-xs " style={{ color: 'green' }}>
											(online now)
										</span>
									) : nullSafeGet('isAssetDown', workOrder) ? (
										<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_ASSETS}>
											<EditOutlined
												onClick={this.handleAssetStatusChange}
												className="cursor-pointer text-base"
												translate=""
											/>
										</AccessPermissionChecker>
									) : null}
								</span>
							),
						}),
					...(nullSafeGet('markedCompleteByContact', workOrder)
						? {
								'Marked Complete By': (
									<UserName
										contact={nullSafeGet('markedCompleteByContact', workOrder)}
										mode={'inline'}
										placement={'right'}
									/>
								),
						  }
						: {}),
					...(nullSafeGet('completedAt', workOrder)
						? { Completed: <DateTimeHover timestamp={nullSafeGet('completedAt', workOrder)} /> }
						: {}),
					...(nullSafeGet('resolutionTypeId', workOrder)
						? {
								'Resolution Type': nullSafeGet('resolutionType.resolutionCode', workOrder)
									? `${nullSafeGet('resolutionType.resolutionCode', workOrder)} - ${nullSafeGet(
											'resolutionType.resolutionType',
											workOrder
									  )}`
									: nullSafeGet('resolutionType.resolutionType', workOrder),
						  }
						: {}),
					'Estimated Completion Date': (
						<DateTimeHover
							timestamp={
								(nullSafeGet('estimatedCompletionDate', workOrder) &&
									dateFormatter(workOrder.estimatedCompletionDate, 'D MMM YYYY')) ||
								dateFormatter(workOrder.dueDate, 'D MMM YYYY')
							}
							showDate={true}
							showTime={false}
						/>
					),
			  };

		const filteredWorkOrderDetails = Object.keys(workOrderDetails).reduce((acc, el) => {
			const val = workOrderDetails[el];
			if (val !== null && val !== 'undefined' && val !== undefined && val !== '') {
				acc[el] = workOrderDetails[el];
			}
			return acc;
		}, {});

		if (error)
			return (
				<OWResult
					error={error}
					title="You no longer have access to this work order."
					subTitle="Either its deleted or been reassigned."
				/>
			);

		return loading ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="workOrdersDetailDetailsPage" key={nullSafeGet('id', workOrder)}>
				<LogOnMountWithStandardEventProperties eventType="visited buyer work order detail page" />
				<ScrollToTopOnMount />
				{this.state.approveWorkModalVisible && (
					<ApproveWorkForm
						wrappedComponentRef={this.saveFormRef('approveWorkForm')}
						visible={this.state.approveWorkModalVisible}
						workOrderId={workOrder.id}
						onCancel={this.handleApproveWorkCancel}
						onSubmit={this.handleApproveWorkSubmit}
						onlyReview={this.state.onlyReview}
					/>
				)}
				<RejectWorkForm
					wrappedComponentRef={this.saveFormRef('rejectWorkForm')}
					visible={this.state.rejectWorkModalVisible}
					workOrderId={workOrder.id}
					onCancel={this.handleRejectWorkCancel}
					onSubmit={this.handleRejectWorkSubmit}
				/>
				<ReminderForm
					wrappedComponentRef={this.saveFormRef('reminderForm')}
					visible={this.state.reminderModalVisible}
					workOrderId={workOrder.id}
					onCancel={this.handleReminderCancel}
					onSubmit={this.handleReminderSubmit}
				/>
				<PartsOrderedOrReceivedForm
					wrappedComponentRef={this.saveFormRef('partsOrderedForm')}
					title="Parts Ordered"
					visible={this.state.partsOrderedModalVisible}
					workOrderId={workOrder.id}
					onCancel={this.handlePartsOrderedCancel}
					onSubmit={this.handlePartsOrderedSubmit}
				/>
				<PartsOrderedOrReceivedForm
					wrappedComponentRef={this.saveFormRef('partsReceivedForm')}
					title="Parts Received"
					visible={this.state.partsReceivedModalVisible}
					workOrderId={workOrder.id}
					onCancel={this.handlePartsReceivedCancel}
					onSubmit={this.handlePartsReceivedSubmit}
				/>
				<StartWorkForm
					wrappedComponentRef={this.saveFormRef('startWorkForm')}
					visible={this.state.startWorkModalVisible}
					workOrderId={workOrder.id}
					onCancel={this.handleStartWorkCancel}
					onSubmit={this.handleStartWorkSubmit}
				/>
				<FinishWorkForm
					wrappedComponentRef={this.saveFormRef('finishWorkForm')}
					visible={this.state.finishWorkModalVisible}
					workOrderId={workOrder.id}
					onCancel={this.handleFinishWorkCancel}
					onSubmit={this.handleFinishWorkSubmit}
				/>
				<AssetStatusForm
					downTimeHistory={this.state.downTimeHistory}
					asset={nullSafeGet('asset', workOrder)}
					onCancel={this.handleAssetStatusCancel}
					onSuccess={this.handleAssetStatusSuccess}
					visible={this.state.assetStatusChangeModalVisible}
				/>
				<BackTop />
				{this.state.addDetailModalVisible && (
					<RichTextEditorModal
						id="work-order-description"
						title={`Update Additional info`}
						onCancel={this.hideAddDetailModal}
						loading={this.state.addingDetails}
						okText="Update Additional info"
						onSubmit={this.onAddDetailSubmit}
						useRichText
						notes={nullSafeGet('description', workOrder)}
					/>
				)}
				<Row gutter={16}>
					<Col lg={24} xl={16}>
						<div className="rowSpacing">
							<Card
								title="Work Order Details"
								className="rowSpacing"
								extra={
									<div className="flex flex-row items-center">
										{!workOrder.description || workOrder.description.length === 0 ? (
											<Button
												className="list_button mr-2"
												size="small"
												type="link"
												onClick={this.showAddDetailModal}
											>
												Add Details
											</Button>
										) : null}
										<WorkOrderPrintable workOrder={workOrder} companyConfig={companyConfig} />
									</div>
								}
							>
								<div style={{ padding: 0 }} className="materialCard__body">
									<WorkOrderActions
										workOrder={workOrder}
										showApproveModal={this.showApproveModal}
										showReminderModal={this.showReminderModal}
										currentUser={currentUser}
										companyConfig={companyConfig}
										refreshWorkOrder={this.refreshWorkOrder}
									/>
									{workOrder.description && workOrder.description.length > 0 ? (
										<div className="flex flex-row items-start">
											<div className="materialCard__primaryTitle">
												<div
													style={{ fontSize: 16 }}
													className="richTextWrapper"
													dangerouslySetInnerHTML={{ __html: workOrder.description }}
												/>
											</div>
											<Button
												type="link"
												icon={<EditOutlined translate="" onClick={this.showAddDetailModal} />}
											/>
										</div>
									) : null}
									{workOrder.images && workOrder.images.length > 0 ? (
										<div className="materialCard__subtitle">
											{
												<ProtectedImageThumbnails
													style={{ marginBottom: 32 }}
													imageWidth={160}
													imageHeight={160}
													photoStrings={workOrder.images}
													role={ROLE_TYPES.BUYER}
												/>
											}
										</div>
									) : null}
									<div className="materialCard__supportingText">
										<KeyValueDisplay
											horizontal={true}
											keyWidth={120}
											keyValueStore={filteredWorkOrderDetails}
										/>
									</div>
								</div>
							</Card>
						</div>

						<TroubleshootingDetailDisplay workOrder={workOrder} />

						{currentUser &&
						currentUser.roles.some((role) => BUYER_ONLY_NO_STORE_ASSOCIATE.has(role)) ? (
							!SELF_MANAGEMENT_STATUSES.includes(workOrder.status) ||
							workOrder.status === WORK_ORDER_STATUSES.finishedWork ||
							nullSafeGet('supplierFacility', workOrder) ? null : (
								<div className="rowSpacing">
									<Card>
										<div style={{ fontSize: 16, maxWidth: 480 }}>
											Not assigned to a supplier. Tracking it yourself?
										</div>
										<div>
											{workOrder.status === WORK_ORDER_STATUSES.unassigned ? (
												<div style={{ marginTop: 16, marginBottom: 40 }}>
													{/*<Button*/}
													{/*type="primary"*/}
													{/*key="plan"*/}
													{/*onClick={this.handlePlan}*/}
													{/*>*/}
													{/*Plan*/}
													{/*</Button>*/}
													<Button type="primary" key="start" onClick={this.handleStartWork}>
														Start Work
													</Button>
													<Button
														key="finish"
														onClick={this.handleFinishWork}
														style={{ marginLeft: 16 }}
													>
														Finish Work
													</Button>
													<Button
														key="partsOnOrder"
														onClick={this.handlePartsOrdered}
														style={{ marginLeft: 16 }}
													>
														Parts Ordered
													</Button>
												</div>
											) : workOrder.status === WORK_ORDER_STATUSES.plannedWork ? (
												<div style={{ marginTop: 16, marginBottom: 40 }}>
													<Button type="primary" key="start" onClick={this.handleStartWork}>
														Start Work
													</Button>
													<Button
														key="finish"
														onClick={this.handleFinishWork}
														style={{ marginLeft: 16 }}
													>
														Finish Work
													</Button>
													<Button
														key="partsOnOrder"
														onClick={this.handlePartsOrdered}
														style={{ marginLeft: 16 }}
													>
														Parts Ordered
													</Button>
												</div>
											) : workOrder.status === WORK_ORDER_STATUSES.startedWork ? (
												<div style={{ marginTop: 16, marginBottom: 40 }}>
													<Button type="primary" key="finish" onClick={this.handleFinishWork}>
														Finish Work
													</Button>
													<Button
														key="partsOnOrder"
														onClick={this.handlePartsOrdered}
														style={{ marginLeft: 16 }}
													>
														Parts Ordered
													</Button>
												</div>
											) : workOrder.status === WORK_ORDER_STATUSES.partsOnOrder ||
											  WORK_ORDER_STATUSES.partsReceived ? (
												<div style={{ marginTop: 16, marginBottom: 40 }}>
													{workOrder.status === WORK_ORDER_STATUSES.partsOnOrder && (
														<Button
															type="primary"
															key="partsReceived"
															onClick={this.handlePartsReceived}
														>
															Parts Received
														</Button>
													)}
													<Button
														key="finish"
														onClick={this.handleFinishWork}
														style={{
															marginLeft:
																workOrder.status === WORK_ORDER_STATUSES.partsOnOrder ? 16 : 0,
														}}
													>
														Finish Work
													</Button>
												</div>
											) : null}
										</div>
									</Card>
								</div>
							)
						) : null}
						<ServiceCallDetails
							workOrder={workOrder}
							showApproveModal={this.showApproveModal}
							showReviewModal={this.showReviewModal}
							showRejectModal={this.showRejectModal}
						/>
					</Col>
					<Col lg={24} xl={8}>
						{nullSafeGet('location', workOrder) ? (
							<div className="rowSpacing">
								<LocationCardDisplay location={workOrder.location} />
							</div>
						) : null}
						{nullSafeGet('plannedMaintenanceSchedule.title', workOrder) ? (
							<div className="rowSpacing">
								<Card title="Planned Maintenance Schedule">
									<div className="mb-2">
										{isAdminUser(currentUser) ? (
											<HyperLink
												text={
													<span className="materialCard__title text-gray-500">
														{workOrder.plannedMaintenanceSchedule.title}
													</span>
												}
												newTabPath={`/${userType}/plannedMaintenance/detail/${encodeURIComponent(
													nullSafeGet('plannedMaintenanceSchedule.title', workOrder)
												)}/${encodeURIComponent(
													nullSafeGet('plannedMaintenanceSchedule.frequency', workOrder)
												)}/${encodeURIComponent(
													nullSafeGet(
														'plannedMaintenanceSchedule.plannedMaintenanceTemplateId',
														workOrder
													)
												)}/${encodeURIComponent(
													nullSafeGet('plannedMaintenanceSchedule.basedOn', workOrder)
												)}/details`}
											/>
										) : (
											<span className="materialCard__title text-gray-500">
												{workOrder.plannedMaintenanceSchedule.title}
											</span>
										)}
									</div>

									<div className="flex flex-row items-center justify-between">
										<div className="materialCard__subtitle">
											<i className="icons8-font icons8-settings"></i>{' '}
											{workOrder.plannedMaintenanceSchedule.basedOn === 'asset'
												? 'Asset Based'
												: workOrder.plannedMaintenanceSchedule.basedOn === 'assetType'
												? 'Asset Type Based'
												: 'Location Based'}
										</div>
										<div className="materialCard__subtitle">
											<i className="icons8-font icons8-calendar"></i>{' '}
											{workOrder.plannedMaintenanceSchedule.frequency}
										</div>
									</div>

									{nullSafeGet(
										'plannedMaintenanceSchedule.supplierContractDocuments',
										workOrder
									) ? (
										<div className="materialCard__subtitle mt-4 text-lg underline">
											Contract Details:
											<FileUploader
												roleType={userType}
												defaultFileList={
													workOrder.plannedMaintenanceSchedule.supplierContractDocuments || []
												}
												uploaderType={'readOnly'}
											/>
										</div>
									) : null}
								</Card>
							</div>
						) : null}
						{nullSafeGet('asset', workOrder) ? (
							<div className="rowSpacing">
								<AssetCardDisplay asset={workOrder.asset} />
							</div>
						) : null}
						{loading ? null : (
							<div className="rowSpacing">
								<Card title="Your Attachments">
									<FileUploader
										roleType="buyer"
										defaultFileList={workOrder.buyerAttachments || []}
										handleUploadSuccess={this.handleFileAttachmentUploadChange}
										uploaderType={'dragger'}
										multiple
									/>
								</Card>
							</div>
						)}
						{workOrder && workOrder.id && <QuestionnaireSurvey workOrder={workOrder} />}
						{loading ? null : workOrder.supplierAttachments === undefined ||
						  workOrder.supplierAttachments.length === 0 ? null : (
							<div className="rowSpacing">
								<Card title="Supplier Attachments">
									<FileUploader
										roleType="supplier"
										defaultFileList={workOrder.supplierAttachments || []}
										uploaderType={'readOnly'}
									/>
								</Card>
							</div>
						)}
						{nullSafeGet('supplierFacility', workOrder) ? (
							<div className="rowSpacing">
								<ServiceProviderCardDisplay workOrder={workOrder} />
							</div>
						) : null}
						<div className="rowSpacing">
							<WorkOrderInternalContact workOrder={workOrder} />
						</div>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	workOrder: getEntityById(ownProps.match.params.id, state.work_orders),
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
	companyConfig: state.company_config.detail,
	userType: state.session.userType,
});

const mapDispatchToProps = (dispatch) => ({
	getWorkOrder: (id) => dispatch(workOrdersRestCrudThunksForBuyer.readOne(id)),
	updateWorkOrder: (entity) => dispatch(workOrdersRestCrudThunksForBuyer.update(entity)),
	reviewWorkOrderAndMarkComplete: (entity) =>
		dispatch(reviewWorkOrderAndMarkCompleteForBuyer(entity)),
	reviewWorkOrderAndMarkUnsatisfactory: (entity) =>
		dispatch(reviewWorkOrderAndMarkUnsatisfactoryForBuyer(entity)),
	reviewInternalTechWorkOrderAndMarkComplete: (entity) =>
		dispatch(reviewInternalTechWorkOrderAndMarkCompleteForBuyer(entity)),
	reviewInternalWorkOrderAndMarkUnresolved: (entity) =>
		dispatch(reviewInternalWorkOrderAndMarkUnresolvedForBuyer(entity)),
	appendWorkOrderNotes: (entity) => dispatch(appendWorkOrderNotesForBuyer(entity)),
	cancelWorkOrder: (entity) => dispatch(cancelWorkOrderForBuyer(entity)),
	planWorkOrder: (entity) => dispatch(planWorkOrderForBuyer(entity)),
	startWorkOrder: (entity) => dispatch(startWorkOrderForBuyer(entity)),
	finishWorkOrder: (entity) => dispatch(finishWorkOrderForBuyer(entity)),
	partsOrderedWorkOrder: (entity) => dispatch(partsOrderedWorkOrderForBuyer(entity, 'id')),
	partsReceivedWorkOrder: (entity) => dispatch(partsReceivedWorkOrderForBuyer(entity, 'id')),
	archiveWorkOrder: (entity) =>
		dispatch(workOrdersRestCrudThunksForBuyer.archive(entity, 'id', true)),
	unarchiveWorkOrder: (entity) =>
		dispatch(workOrdersRestCrudThunksForBuyer.unarchive(entity, 'id', true)),
	markUserNotificationsRead: (entityId) =>
		dispatch(markUserNotificationsReadForBuyer('WorkOrder', entityId)),
	getAssetDowntime: (params, userType) => dispatch(assetDowntimeGet(userType)(params)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkOrdersDetailPage));

import Home from '../Home';

import { authorizedRolesCombinations } from '../roles/roles';

// Auth
import LoginPage from '../login_page/LoginPage';
import SupplierLoginPage from '../supplier_login_page/SupplierLoginPage';
import BuyerLoginPage from '../buyer_login_page/BuyerLoginPage';
import BuyerSignupPage from '../buyer_signup_page/BuyerSignupPage';
import SupplierSignupPage from '../supplier_signup_page/SupplierSignupPage';
import BuyerChangePasswordPage from '../buyer_change_password_page/BuyerChangePasswordPage';
import SupplierChangePasswordPage from '../supplier_change_password_page/SupplierChangePasswordPage';
import BuyerResetPasswordPage from '../buyer_reset_password_page/BuyerResetPasswordPage';
import SupplierResetPasswordPage from '../supplier_reset_password_page/SupplierResetPasswordPage';
import BuyerForgotPasswordPage from '../buyer_forgot_password_page/BuyerForgotPasswordPage';
import SupplierForgotPasswordPage from '../supplier_forgot_password_page/SupplierForgotPasswordPage';
import EmptorTermsAndConditionsPage from '../emptor_terms_and_conditions_page/EmptorTermsAndConditionsPage';

// Analytics
import WorkAnalyticsPage from '../work_analytics_page/WorkAnalyticsPage';
import WorkAnalyticsOverviewPage from '../work_analytics_overview_page/WorkAnalyticsOverviewPage';
import WorkAnalyticsOverviewPrioritiesPage from '../work_analytics_overview_priorities_page/WorkAnalyticsOverviewPrioritiesPage';
import SpendAnalyticsOverviewLocationsPage from '../spend_analytics_overview_locations_page/SpendAnalyticsOverviewLocationsPage';
import SpendAnalyticsOverviewVendorsPage from '../spend_analytics_overview_vendors_page/SpendAnalyticsOverviewVendorsPage';
import SpendAnalyticsOverviewCategoriesPage from '../spend_analytics_overview_categories_page/SpendAnalyticsOverviewCategoriesPage';
import SpendAnalyticsOverviewPage from '../spend_analytics_overview_page/SpendAnalyticsOverviewPage';
import SpendAnalyticsPage from '../spend_analytics_page/SpendAnalyticsPage';

// Profile Pages
import MyBuyerProfilePage from '../my_buyer_profile_page/MyBuyerProfilePage';
import MySupplierProfilePage from '../my_supplier_profile_page/MySupplierProfilePage';

// Suppliers
import SuppliersPage from '../suppliers_page/SuppliersPage';

import BuyerReferralsPage from '../buyer_referrals_page/BuyerReferralsPage';
import SupplierSignupCompletionPage from '../supplier_signup_completion_page/SupplierSignupCompletionPage';
import BuyerSignupCompletionPage from '../buyer_signup_completion_page/BuyerSignupCompletionPage';
import BuyerAdminPage from '../buyer_admin_page/BuyerAdminPage';
import BuyerAdminUsersPage from '../buyer_admin_users_page/BuyerAdminUsersPage';
import SupplierAdminPage from '../supplier_admin_page/SupplierAdminPage';
import SupplierAdminUsersPage from '../supplier_admin_users_page/SupplierAdminUsersPage';
import BuyerNewAccountSetupPage from '../buyer_new_account_setup_page/BuyerNewAccountSetupPage';
import SupplierNewAccountSetupPage from '../supplier_new_account_setup_page/SupplierNewAccountSetupPage';
import BuyerNewAccountSetupAssociationPage from '../buyer_new_account_setup_association_page/BuyerNewAccountSetupAssociationPage';
import BuyerNewAccountSetupProfilePage from '../buyer_new_account_setup_profile_page/BuyerNewAccountSetupProfilePage';
import BuyerNewAccountSetupCompletionPage from '../buyer_new_account_setup_completion_page/BuyerNewAccountSetupCompletionPage';
import MyBuyerEditProfilePage from '../my_buyer_edit_profile_page/MyBuyerEditProfilePage';
import SupplierNewAccountSetupProfilePage from '../supplier_new_account_setup_profile_page/SupplierNewAccountSetupProfilePage';
import SupplierNewAccountSetupCompletionPage from '../supplier_new_account_setup_completion_page/SupplierNewAccountSetupCompletionPage';
import EmptorDataProtectionPolicyPage from '../emptor_data_protection_policy_page/EmptorDataProtectionPolicyPage';
import MySupplierEditProfilePage from '../my_supplier_edit_profile_page/MySupplierEditProfilePage';
import SupplierSuppliersDetailPage from '../supplier_suppliers_detail_page/SupplierSuppliersDetailPage';
import SuppliersPublicDetailPage from '../suppliers_public_detail_page/SuppliersPublicDetailPage';
import SupplierFacilityEditPage from '../supplier_facility_edit_page/SupplierFacilityEditPage';
import SupplierFacilityCreatePage from '../supplier_facility_create_page/SupplierFacilityCreatePage';
import BuyerBuyersDetailPage from '../buyer_buyers_detail_page/BuyerBuyersDetailPage';
import NewLocationTypePage from '../new_location_type_page/NewLocationTypePage';
import NewAssetTypePage from '../new_asset_template_page/NewAssetTemplatePage';
import NewAssetPage from '../new_asset_page/NewAssetPage';
import NewLocationPage from '../new_location_page/NewLocationPage';
import LocationsPage from '../locations_page/LocationsPage';
import LocationsOverviewPage from '../locations_overview_page/LocationsOverviewPage';
import AssetsPage from '../assets_page/AssetsPage';
import AssetsOverviewPage from '../assets_overview_page/AssetsOverviewPage';
import LocationsIndexPage from '../locations_index_page/LocationsIndexPage';
import LocationTypesIndexPage from '../location_types_index_page/LocationTypesIndexPage';
import AssetsIndexPage from '../assets_index_page/AssetsIndexPage';
import AssetTemplatesIndexPage from '../asset_templates_index_page/AssetTemplatesIndexPage';
import WorkOrdersPage from '../work_orders_page/WorkOrdersPage';
import WorkOrdersOverviewPage from '../work_orders_overview_page/WorkOrdersOverviewPage';
import WorkOrdersActiveIndexPage from '../work_orders_active_index_page/WorkOrdersActiveIndexPage';
import WorkOrdersAllIndexPage from '../work_orders_all_index_page/WorkOrdersAllIndexPage';
import LocationsDetailPage from '../locations_detail_page/LocationsDetailPage';
import LocationsDetailDetailsPage from '../locations_detail_details_page/LocationsDetailDetailsPage';
import EditLocationPage from '../edit_location_page/EditLocationPage';
import AssetsDetailPage from '../assets_detail_page/AssetsDetailPage';
import AssetsDetailDetailsPage from '../assets_detail_details_page/AssetsDetailDetailsPage';
import EditAssetPage from '../edit_asset_page/EditAssetPage';
import AssetTemplatesDetailPage from '../asset_templates_detail_page/AssetTemplatesDetailPage';
import AssetTemplatesDetailDetailsPage from '../asset_templates_detail_details_page/AssetTemplatesDetailDetailsPage';
import EditAssetTemplatePage from '../edit_asset_template_page/EditAssetTemplatePage';
import LocationTypesDetailPage from '../location_types_detail_page/LocationTypesDetailPage';
import LocationTypesDetailDetailsPage from '../location_types_detail_details_page/LocationTypesDetailDetailsPage';
import EditLocationTypePage from '../edit_location_type_page/EditLocationTypePage';
import NewWorkOrderPage from '../new_work_order_page/NewWorkOrderPage';
import SuppliersOverviewPage from '../suppliers_overview_page/SuppliersOverviewPage';
import PublicWrenchHubPage from '../public_wrench_hub_page/PublicWrenchHubPage';
import BuyerWrenchHubPage from '../buyer_wrench_hub_page/BuyerWrenchHubPage';
import LocationDetailsSuppliersPage from '../locations_detail_suppliers_page/LocationsDetailSuppliersPage';
import PrivateNetworkIndexPage from '../private_network_index_page/PrivateNetworkIndexPage';
import PrivateNetworkLocationsPage from '../private_network_locations_page';
import WorkOrdersDetailPage from '../work_orders_detail_page/WorkOrdersDetailPage';
import WorkOrdersDetailDetailsPage from '../work_orders_detail_details_page/WorkOrdersDetailDetailsPage';
import EditWorkOrderPage from '../edit_work_order_page/EditWorkOrderPage';
import EditSafeWorkOrderPage from '../edit_safe_work_order_page/EditSafeWorkOrderPage';

import NewWarrantyPage from '../new_warranty_page/NewWarrantyPage';
import RequestsForProposalPage from '../requests_for_proposal_page/RequestsForProposalPage';
import RequestsForProposalOverviewPage from '../requests_for_proposal_overview_page/RequestsForProposalOverviewPage';
import NewRequestForProposalPage from '../new_request_for_proposal_page/NewRequestForProposalPage';
import RequestsForProposalIndexPage from '../requests_for_proposal_index_page/RequestsForProposalIndexPage';
import InvoicesPage from '../invoices_page/InvoicesPage';
import InvoicesOverviewPage from '../invoices_overview_page/InvoicesOverviewPage';
import InvoicesIndexPage from '../invoices_index_page/InvoicesIndexPage';
import InvoicesDetailPage from '../invoices_detail_page/InvoicesDetailPage';
import InvoicesDetailDetailsPage from '../invoices_detail_details_page/InvoicesDetailDetailsPage';
import QuotesPage from '../quotes_page/QuotesPage';
import QuotesOverviewPage from '../quotes_overview_page/QuotesOverviewPage';
import QuotesIndexPage from '../quotes_index_page/QuotesIndexPage';
import QuotesDetailPage from '../quotes_detail_page/QuotesDetailPage';
import QuotesDetailDetailsPage from '../quotes_detail_details_page/QuotesDetailDetailsPage';
import RFPDetailPage from '../rfp_detail_page/RFPDetailPage';
import RFPDetailDetailsPage from '../rfp_detail_details_page/RFPDetailDetailsPage';
import KPIAnalyticsPage from '../kpi_analytics_page/KPIAnalyticsPage';
import KPIAnalyticsOverviewPage from '../kpi_analytics_overview_page/KPIAnalyticsOverviewPage';
import KPIAnalyticsOverviewVendorsPage from '../kpi_analytics_overview_vendors_page/KPIAnalyticsOverviewVendorsPage';
import KPIAnalyticsVendorDetailPage from '../kpi_analytics_vendor_detail_page/KPIAnalyticsVendorDetailPage';
import EditRequestForProposalPage from '../edit_request_for_proposal_page/EditRequestForProposalPage';
import PlannedMaintenancePage from '../planned_maintenance_page/PlannedMaintenancePage';
import PlannedMaintenanceSchedulesOverviewPage from '../planned_maintenance_schedules_overview_page/PlannedMaintenanceSchedulesOverviewPage';
import PlannedMaintenanceSchedulesIndexPage from '../planned_maintenance_schedules_index_page/PlannedMaintenanceSchedulesIndexPage';
import PlannedMaintenanceSchedulesDetailPage from '../planned_maintenance_schedules_detail_page/PlannedMaintenanceSchedulesDetailPage';
import PlannedMaintenanceSchedulesDetailDetailsPage from '../planned_maintenance_schedules_detail_details_page/PlannedMaintenanceSchedulesDetailDetailsPage';
import NewPlannedMaintenanceSchedulePage from '../new_planned_maintenance_schedule_page/NewPlannedMaintenanceSchedulePage';
import RFPBidderEditPage from '../rfp_bidder_edit_page/RFPBidderEditPage';
import PlannedMaintenanceTemplatesIndexPage from '../planned_maintenance_templates_index_page/PlannedMaintenanceTemplatesIndexPage';
import NewPlannedMaintenanceTemplatePage from '../new_planned_maintenance_template_page/NewPlannedMaintenanceTemplatePage';
import PlannedMaintenanceTemplatesDetailPage from '../planned_maintenance_templates_detail_page/PlannedMaintenanceTemplatesDetailPage';
import PlannedMaintenanceTemplatesDetailDetailsPage from '../planned_maintenance_templates_detail_details_page/PlannedMaintenanceTemplatesDetailDetailsPage';
import SupplierWorkOrdersPage from '../supplier_work_orders_page/SupplierWorkOrdersPage';
import SupplierWorkOrdersDetailPage from '../supplier_work_orders_detail_page/SupplierWorkOrdersDetailPage';
import SupplierWorkOrdersDetailDetailsPage from '../supplier_work_orders_detail_details_page/SupplierWorkOrdersDetailDetailsPage';
import SupplierWorkOrdersOverviewPage from '../supplier_work_orders_overview_page/SupplierWorkOrdersOverviewPage';
import SupplierWorkOrdersIndexPage from '../supplier_work_orders_all_index_page/SupplierWorkOrdersAllIndexPage';
import SupplierWorkOrdersActiveIndexPage from '../supplier_work_orders_active_index_page/SupplierWorkOrdersActiveIndexPage';
import SupplierNeedEmailAuthenticationPage from '../supplier_need_email_authentication_page/SupplierNeedEmailAuthenticationPage';
import BuyerNeedEmailAuthenticationPage from '../buyer_need_email_authentication_page/BuyerNeedEmailAuthenticationPage';
import WorkOrdersDetailInvoicesPage from '../work_orders_detail_invoices_page/WorkOrdersDetailInvoicesPage';
import WorkOrdersDetailNotesPage from '../work_orders_detail_notes_page/WorkOrdersDetailNotesPage';
import SupplierWorkOrdersDetailInvoicesPage from '../supplier_work_orders_detail_invoices_page/SupplierWorkOrdersDetailInvoicesPage';
import SupplierWorkOrdersDetailNotesPage from '../supplier_work_orders_detail_notes_page/SupplierWorkOrdersDetailNotesPage';
import EditPlannedMaintenanceTemplatePage from '../edit_planned_maintenance_template_page/EditPlannedMaintenanceTemplatePage';
import WorkOrdersArchivedIndexPage from '../work_orders_archived_index_page/WorkOrdersArchivedIndexPage';
import SupplierWorkOrdersArchivedIndexPage from '../supplier_work_orders_archived_index_page/SupplierWorkOrdersArchivedIndexPage';
import SupplierNewAccountSetupPostSignUpPage from '../supplier_new_account_setup_post_signup_page/SupplierNewAccountSetupPostSignUpPage';
import SupplierInvoicesPage from '../supplier_invoices_page/SupplierInvoicesPage';
import SupplierInvoicesOverviewPage from '../supplier_invoices_overview_page/SupplierInvoicesOverviewPage';
import SupplierInvoicesIndexPage from '../supplier_invoices_index_page/SupplierInvoicesIndexPage';
import SupplierInvoicesDetailPage from '../supplier_invoices_detail_page/SupplierInvoicesDetailPage';
import SupplierInvoicesDetailDetailsPage from '../supplier_invoices_detail_details_page/SupplierInvoicesDetailDetailsPage';
import SupplierQuotesPage from '../supplier_quotes_page/SupplierQuotesPage';
import SupplierQuotesOverviewPage from '../supplier_quotes_overview_page/SupplierQuotesOverviewPage';
import SupplierQuotesIndexPage from '../supplier_quotes_index_page/SupplierQuotesIndexPage';
import SupplierQuotesDetailPage from '../supplier_quotes_detail_page/SupplierQuotesDetailPage';
import SupplierQuotesDetailDetailsPage from '../supplier_quotes_detail_details_page/SupplierQuotesDetailDetailsPage';
import FieldTechsPage from '../field_techs_page/FieldTechsPage';
import FieldTechsOverviewPage from '../field_techs_overview_page/FieldTechsOverviewPage';
import FieldTechsIndexPage from '../field_techs_index_page/FieldTechsIndexPage';
import StockLocationsIndexPage from '../stock_locations_index_page/StockLocationsIndexPage';
import StockLocationsOverviewPage from '../stock_locations_overview_page/StockLocationsOverviewPage';
import StockLocationsPage from '../stock_locations_page/StockLocationsPage';
import StockLocationsDetailPage from '../stock_locations_detail_page/StockLocationsDetailPage';
import PartsPage from '../parts_page/PartsPage';
import PartsOverviewPage from '../parts_overview_page/PartsOverviewPage';
import PartsIndexPage from '../parts_index_page/PartsIndexPage';
import PartsDetailPage from '../parts_detail_page/PartsDetailPage';
import PartsDetailDetailsPage from '../parts_detail_details_page/PartsDetailDetailsPage';
import EquipmentsPage from '../equipments_page/EquipmentsPage';
import EquipmentsOverviewPage from '../equipments_overview_page/EquipmentsOverviewPage';
import EquipmentsIndexPage from '../equipments_index_page/EquipmentsIndexPage';
import EquipmentsDetailPage from '../equipments_detail_page/EquipmentsDetailPage';
import EquipmentsDetailDetailsPage from '../equipments_detail_details_page/EquipmentsDetailDetailsPage';
import FieldTechsDetailPage from '../field_techs_detail_page/FieldTechsDetailPage';
import FieldTechsDetailDetailsPage from '../field_techs_detail_details_page/FieldTechsDetailDetailsPage';
import EditFieldTechPage from '../edit_field_tech_page/EditFieldTechPage';
import EditEquipmentPage from '../edit_equipment_page/EditEquipmentPage';
import EditPartPage from '../edit_part_page/EditPartPage';
import EditStockLocationPage from '../edit_stock_location_page/EditStockLocationPage';
import NewFieldTechPage from '../new_field_tech_page/NewFieldTechPage';
import NewEquipmentPage from '../new_equipment_page/NewEquipmentPage';
import NewPartPage from '../new_part_page/NewPartPage';
import NewStockLocationPage from '../new_stock_location_page/NewStockLocationPage';
import NewSupplierInvoicePage from '../new_supplier_invoice_page/NewSupplierInvoicePage';
import EditSupplierInvoicePage from '../edit_supplier_invoice_page/EditSupplierInvoicePage';
import NewSupplierQuotePage from '../new_supplier_quote_page/NewSupplierQuotePage';
import EditQuotePage from '../edit_quote_page/EditQuotePage';
import SupplierWorkOrdersDetailChargesPage from '../supplier_work_orders_detail_charges_page/SupplierWorkOrdersDetailChargesPage';
import NewServiceRequestPage from '../new_service_request_page/NewServiceRequestPage';
import SupplierRequestsForProposalPage from '../supplier_requests_for_proposal_page/SupplierRequestsForProposalPage';
import SupplierRequestsForProposalOverviewPage from '../supplier_requests_for_proposal_overview_page/SupplierRequestsForProposalOverviewPage';
import SupplierRequestsForProposalIndexPage from '../supplier_requests_for_proposal_index_page/SupplierRequestsForProposalIndexPage';
import SupplierRFPDetailPage from '../supplier_rfp_detail_page/SupplierRFPDetailPage';
import SupplierRFPDetailDetailsPage from '../supplier_rfp_detail_details_page/SupplierRFPDetailDetailsPage';
import NewProposalPage from '../new_proposal_page/NewProposalPage';
import EditProposalPage from '../edit_proposal_page/EditProposalPage';
import SupplierClaimSignUpPage from '../supplier_claim_signup_page/SupplierClaimSignUpPage';
import PrintPartsPerStockLocationLabels from '../print_parts_per_stock_location_labels/PrintPartsPerStockLocationLabels';
import PrintEquipmentLabels from '../print_equipment_labels/PrintEquipmentLabels';
import BuyerSettingsPage from '../buyer_settings_page/BuyerSettingsPage';
import BuyerNotificationsSettingsPage from '../buyer_notifications_settings_page/BuyerNotificationsSettingsPage';
import EditBuyerNotificationsSettingsPage from '../edit_buyer_notifications_settings_page/EditBuyerNotificationsSettingsPage';
import EditPlannedMaintenanceSchedulePage from '../edit_planned_maintenance_schedule_page/EditPlannedMaintenanceSchedulePage';
import SupplierNewWorkOrderPage from '../supplier_new_work_order_page/SupplierNewWorkOrderPage';
import EditSupplierWorkOrderPage from '../edit_supplier_work_order_page/EditSupplierWorkOrderPage';
import ApproveWorkOrderPage from '../approve_work_order_page/ApproveWorkOrderPage';
import BuyerNotificationsPage from '../buyer_notifications_page/BuyerNotificationsPage';
import BuyerNotificationsOverviewPage from '../buyer_notifications_overview_page/BuyerNotificationsOverviewPage';
import SupplierNotificationsPage from '../supplier_notifications_page/SupplierNotificationsPage';
import SupplierNotificationsOverviewPage from '../supplier_notifications_overview_page/SupplierNotificationsOverviewPage';
import SupplierClientsPage from '../supplier_clients_page/SupplierClientsPage';
import SupplierClientsOverviewPage from '../supplier_clients_overview_page/SupplierClientsOverviewPage';
import SupplierClientsIndexPage from '../supplier_clients_index_page/SupplierClientsIndexPage';
import BuyerAdminSettingsPage from '../buyer_admin_settings_page/BuyerAdminSettingsPage';
import BuyerAdminApprovalPage from '../buyer_admin_approval_page/BuyerAdminApprovalPage';
import ApprovalHierarchyIndexPage from '../approval_hierarchy_index_page/ApprovalHierarchyIndexPage';

import AssetModelsDetailPage from '../asset_models_detail_page/AssetModelsDetailPage';
import AssetModelsDetailDetailsPage from '../asset_models_detail_details_page/AssetModelsDetailDetailsPage';
import EditAssetModelPage from '../edit_asset_model_page/EditAssetModelPage';
import VendorsDetailPage from '../vendors_detail_page/VendorsDetailPage';
import VendorsIndexPage from '../vendors_index_page/VendorsIndexPage';
import NewVendorPage from '../new_vendor_page/NewVendorPage';
import VendorsDetailDetailsPage from '../vendors_detail_details_page/VendorsDetailDetailsPage';
import EditVendorPage from '../edit_vendor_page/EditVendorPage';
import VendorsPage from '../vendors_page/VendorsPage';
import VendorsOverviewPage from '../vendors_overview_page/VendorsOverviewPage';
import PurchaseRequestsPage from '../purchase_requests_page/PurchaseRequestsPage';
import PurchaseRequestsOverviewPage from '../purchase_requests_overview_page/PurchaseRequestsOverviewPage';
import PurchaseRequestsIndexPage from '../purchase_requests_index_page/PurchaseRequestsIndexPage';
import PurchaseRequestsDetailPage from '../purchase_requests_detail_page/PurchaseRequestsDetailPage';
import PurchaseRequestsDetailDetailsPage from '../purchase_requests_detail_details_page/PurchaseRequestsDetailDetailsPage';
import PurchaseOrdersPage from '../purchase_orders_page/PurchaseOrdersPage';
import PurchaseOrdersOverviewPage from '../purchase_orders_overview_page/PurchaseOrdersOverviewPage';
import PurchaseOrdersIndexPage from '../purchase_orders_index_page/PurchaseOrdersIndexPage';
import PurchaseOrdersDetailPage from '../purchase_orders_detail_page/PurchaseOrdersDetailPage';
import PurchaseOrdersDetailDetailsPage from '../purchase_orders_detail_details_page/PurchaseOrdersDetailDetailsPage';
import NewPurchaseOrderPage from '../new_purchase_order_page/NewPurchaseOrderPage';
import NewPurchaseRequestPage from '../new_purchase_request_page/NewPurchaseRequestPage';
import EditPurchaseRequestPage from '../edit_purchase_request_page/EditPurchaseRequestPage';
import EditPurchaseOrderPage from '../edit_purchase_order_page/EditPurchaseOrderPage';
import WorkOrdersDetailQuotesPage from '../work_orders_detail_quotes_page/WorkOrdersDetailQuotesPage';
import AssetsDetailManualsPage from '../assets_detail_manuals_page/AssetsDetailManualsPage';
import SupplierWorkOrdersDetailQuotesPage from '../supplier_work_orders_detail_quotes_page/SupplierWorkOrdersDetailQuotesPage';
import NewWorkOrderProposalPage from '../new_work_order_proposal_page/NewWorkOrderProposalPage';
import EditWorkOrderProposalPage from '../edit_work_order_proposal_page/EditWorkOrderProposalPage';
import NewServiceRequestWizardPage from '../new_service_request_wizard_page/NewServiceRequestWizardPage';
import SupplierAnalyticsPage from '../supplier_analytics_page/SupplierAnalyticsPage';
import SupplierFieldTechsAnalyticsPage from '../supplier_field_techs_analytics_page/SupplierFieldTechsAnalyticsPage';
import SupplierFieldTechsAnalyticsOverviewPage from '../supplier_field_techs_analytics_overview_page/SupplierFieldTechsAnalyticsOverviewPage';
import SupplierFieldTechsAnalyticsDetailPage from '../supplier_field_techs_analytics_detail_page/SupplierFieldTechsAnalyticsDetailPage';
import NewWorkOrderWizardPage from '../new_work_order_wizard_page/NewWorkOrderWizardPage';
import SupplierNewWorkOrderWizardPage from '../supplier_new_work_order_wizard_page/SupplierNewWorkOrderWizardPage';
import SiteSurveysPage from '../site_surveys_page/SiteSurveysPage';
import SiteSurveysOverviewPage from '../site_surveys_overview_page/SiteSurveysOverviewPage';
import SiteSurveysIndexPage from '../site_surveys_index_page/SiteSurveysIndexPage';
import SiteSurveysDetailPage from '../site_surveys_detail_page/SiteSurveysDetailPage';
import SiteSurveysDetailDetailsPage from '../site_surveys_detail_details_page/SiteSurveysDetailDetailsPage';
import WalkthroughsDetailPage from '../walkthroughs_detail_page/WalkthroughsDetailPage';
import WalkthroughsDetailDetailsPage from '../walkthroughs_detail_details_page/WalkthroughsDetailDetailsPage';

import SiteSurveyTemplatesIndexPage from '../site_survey_templates_index_page/SiteSurveyTemplatesIndexPage';
import NewSiteSurveyTemplatePage from '../new_site_survey_template_page/NewSiteSurveyTemplatePage';
import SiteSurveyTemplatesDetailPage from '../site_survey_templates_detail_page/SiteSurveyTemplatesDetailPage';
import SiteSurveyTemplatesDetailDetailsPage from '../site_survey_templates_detail_details_page/SiteSurveyTemplatesDetailDetailsPage';
import NewSiteSurveyPage from '../new_site_survey_page/NewSiteSurveyPage';
import SupplierWalkthroughPage from '../supplier_walkthroughs_page/SupplierWalkthroughPage';
import SupplierWalkthroughOverviewPage from '../supplier_walkthroughs_overview_page/SupplierWalkthroughOverviewPage';
import SupplierWalkthroughIndexPage from '../supplier_walkthroughs_index_page/SupplierWalkthroughIndexPage';
import SupplierWalkthroughDetailPage from '../supplier_walkthroughs_detail_page/SupplierWalkthroughDetailPage';
import SupplierWalkthroughDetailDetailsPage from '../supplier_walkthroughs_detail_details_page/SupplierWalkthroughDetailDetailsPage';
import SpendAnalyticsOverviewProblemTypesPage from '../spend_analytics_overview_problem_types_page/SpendAnalyticsOverviewProblemTypesPage';
import WorkAnalyticsOverviewProblemTypesPage from '../work_analytics_overview_problem_types_page/WorkAnalyticsOverviewProblemTypesPage';
import WorkAnalyticsOverviewManufacturersPage2 from '../work_analytics_overview_manufacturers_page2/WorkAnalyticsOverviewManufacturersPage2';
import WorkAnalyticsOverviewVendorsPage2 from '../work_analytics_overview_vendors_page2/WorkAnalyticsOverviewVendorsPage2';
import WorkAnalyticsOverviewAssetTypesPage2 from '../work_analytics_overview_asset_types_page2/WorkAnalyticsOverviewAssetTypesPage2';
import WorkAnalyticsOverviewLocationsPage from '../work_analytics_overview_locations_page/WorkAnalyticsOverviewLocationsPage';
import SupplierServiceCallCalendarPage from '../supplier_service_call_calendar_page/SupplierServiceCallCalendarPage';
import TestTreeComponentPage from '../regions_tree_select/BuyerRegionsLocationsTreeSelect';
import SupplierLocationsPage from '../supplier_locations_page/SupplierLocationsPage';
import SupplierLocationsDetailPage from '../supplier_locations_detail_page/SupplierLocationsDetailPage';
import SupplierLocationsDetailDetailsPage from '../supplier_locations_detail_details_page/SupplierLocationsDetailDetailsPage';
import SupplierAssetsPage from '../supplier_assets_page/SupplierAssetsPage';
import SupplierAssetsDetailPage from '../supplier_assets_detail_page/SupplierAssetsDetailPage';
import SupplierAssetsDetailDetailsPage from '../supplier_assets_detail_details_page/SupplierAssetsDetailDetailsPage';
import SupplierAssetsDetailManualsPage from '../supplier_assets_detail_manuals_page/SupplierAssetsDetailManualsPage';
import SupplierAssetModelsDetailPage from '../supplier_asset_models_detail_page/SupplierAssetModelsDetailPage';
import SupplierAssetModelsDetailDetailsPage from '../supplier_asset_models_detail_details_page/SupplierAssetModelsDetailDetailsPage';
import SupplierAssetsIndexPage from '../supplier_assets_index_page/SupplierAssetsIndexPage';
import SupplierNewAssetPage from '../supplier_new_asset_page/SupplierNewAssetPage';
import SupplierEditAssetPage from '../supplier_edit_asset_page/SupplierEditAssetPage';
import MetersPage from '../meters_page/MetersPage';
import MetersDetailPage from '../meters_detail_page/MetersDetailPage';
import MetersDetailDetailsPage from '../meters_detail_details_page/MetersDetailDetailsPage';
import MetersOverviewPage from '../meters_overview_page/MetersOverviewPage';
import MetersIndexPage from '../meters_index_page/MetersIndexPage';
import NewMeterPage from '../new_meter_page/NewMeterPage';
import MeterTypesIndexPage from '../meter_types_index_page/MeterTypesIndexPage';
import NewMeterTypePage from '../new_meter_type_page/NewMeterTypePage';
import MeterTypesDetailPage from '../meter_types_detail_page/MeterTypesDetailPage';
import MeterTypesDetailDetailsPage from '../meter_types_detail_details_page/MeterTypesDetailDetailsPage';
import LocationsDetailMetersPage from '../locations_detail_meters_page/LocationsDetailMetersPage';
import SupplierMetersPage from '../supplier_meters_page/SupplierMetersPage';
import SupplierMetersDetailPage from '../supplier_meters_detail_page/SupplierMetersDetailPage';
import SupplierMetersDetailDetailsPage from '../supplier_meters_detail_details_page/SupplierMetersDetailDetailsPage';
import SupplierMetersOverviewPage from '../supplier_meters_overview_page/SupplierMetersOverviewPage';
import SupplierMetersIndexPage from '../supplier_meters_index_page/SupplierMetersIndexPage';
import EditMeterPage from '../edit_meter_page/EditMeterPage';
import SupplierEditMeterPage from '../supplier_edit_meter_page/SupplierEditMeterPage';
import SupplierSettingsPage from '../supplier_settings_page/SupplierSettingsPage';
import SupplierNotificationsSettingsPage from '../supplier_notifications_settings_page/SupplierNotificationsSettingsPage';
import EditSupplierNotificationsSettingsPage from '../edit_supplier_notifications_settings_page/EditSupplierNotificationsSettingsPage';
import SupplierSiteSurveysPage from '../supplier_site_surveys_page/SupplierSiteSurveysPage';
import SupplierSiteSurveysOverviewPage from '../supplier_site_surveys_overview_page/SupplierSiteSurveysOverviewPage';
import SupplierSiteSurveysIndexPage from '../supplier_site_surveys_index_page/SupplierSiteSurveysIndexPage';
import NewSupplierSiteSurveyPage from '../new_supplier_site_survey_page/NewSupplierSiteSurveyPage';
import NewSupplierSiteSurveyTemplatePage from '../new_supplier_site_survey_template_page/NewSupplierSiteSurveyTemplatePage';
import SupplierSiteSurveyTemplatesIndexPage from '../supplier_site_survey_templates_index_page/SupplierSiteSurveyTemplatesIndexPage';
import SupplierSiteSurveysDetailPage from '../supplier_site_surveys_detail_page/SupplierSiteSurveysDetailPage';
import SupplierSiteSurveysDetailDetailsPage from '../supplier_site_surveys_detail_details_page/SupplierSiteSurveysDetailDetailsPage';
import SupplierSiteSurveyTemplatesDetailPage from '../supplier_site_survey_templates_detail_page/SupplierSiteSurveyTemplatesDetailPage';
import SupplierSiteSurveyTemplatesDetailDetailsPage from '../supplier_site_survey_templates_detail_details_page/SupplierSiteSurveyTemplatesDetailDetailsPage';
import SupplierSiteSurveysWalkthroughDetailPage from '../supplier_site_surveys_walkthroughs_detail_page/SupplierSiteSurveysWalkthroughDetailPage';
import BuyerSuppliersDetailPage from '../buyer_suppliers_detail_page/BuyerSuppliersDetailPage';
import NewSupplierSimpleInvoicePage from '../new_supplier_simple_invoice_page/NewSupplierSimpleInvoicePage';
import SupplierUploadInvoicePDFPage from '../supplier_upload_invoice_pdf_page/SupplierUploadInvoicePDFPage';
import SupplierUploadQuotePDFPage from '../supplier_upload_quote_pdf_page/SupplierUploadQuotePDFPage';
import NewSupplierSimpleQuotePage from '../new_supplier_simple_quote_page/NewSupplierSimpleQuotePage';
import PendingEditWorkOrderPage from '../pending_edit_work_order_page/PendingEditWorkOrderPage';
import NewFacilitiesBuyer from '../new_facility_buyer';
import NewFacilitiesSupplier from '../new_facility_supplier';
import ImpersonationComponent from '../super_admin_impersonation';
import ProjectsPage from '../projects_page/ProjectsPage';
import ProjectsIndexPage from '../projects_index_page/ProjectsIndexPage';
import ProjectsDetailOverviewPage from '../projects_details_overview_page/ProjectsDetailOverviewPage';
import ProjectsOverviewPage from '../projects_overview_page/ProjectsOverviewPage';
import ProjectsDetailPage from '../projects_details_page/ProjectsDetailPage';
import ProjectEditPage from '../project_edit_page/ProjectEditPage';
import ProjectNewPage from '../project_new_page/ProjectNewPage';
import ProjectsWorkOrdersPage from '../projects_work_orders_page/ProjectsWorkOrdersPage';
import ProjectInvoicesPage from '../projects_invoices_page/ProjectInvoicesPage';
import WorkOrdersSavedFiltersViewPage from '../work_orders_saved_filters_view_pages/WorkOrdersSavedFiltersViewPage';
import WorkOrdersSavedFiltersViewOverviewPage from '../work_orders_saved_filters_view_pages/WorkOrdersSavedFiltersViewOverviewPage';
import WorkOrdersSavedFiltersViewIndexPage from '../work_orders_saved_filters_view_pages/WorkOrdersSavedFiltersViewIndexPage';
import SupplierWorkOrdersSavedFiltersViewPage from '../supplier_work_orders_saved_filter_views_pages/SupplierWorkOrdersSavedFiltersViewPage';
import SupplierWorkOrdersSavedFiltersViewOverviewPage from '../supplier_work_orders_saved_filter_views_pages/SupplierWorkOrdersSavedFiltersViewOverviewPage';
import SupplierWorkOrdersSavedFiltersViewIndexPage from '../supplier_work_orders_saved_filter_views_pages/SupplierWorkOrdersSavedFiltersViewIndexPage';
import InvoicesSavedFiltersViewPage from '../invoices_saved_filter_view_pages/InvoicesSavedFiltersViewPage';
import InvoicesSavedFiltersViewOverviewPage from '../invoices_saved_filter_view_pages/InvoicesSavedFiltersViewOverviewPage';
import InvoicesSavedFiltersViewIndexPage from '../invoices_saved_filter_view_pages/InvoicesSavedFiltersViewIndexPage';
import SupplierInvoicesSavedFiltersViewPage from '../supplier_invoices_saved_filter_view_pages/SupplierInvoicesSavedFiltersViewPage';
import SupplierInvoicesSavedFiltersViewOverviewPage from '../supplier_invoices_saved_filter_view_pages/SupplierInvoicesSavedFiltersViewOverviewPage';
import SupplierInvoicesSavedFiltersViewIndexPage from '../supplier_invoices_saved_filter_view_pages/SupplierInvoicesSavedFiltersViewIndexPage';
import AttentionDashboardPage from '../attention_dashboard_pages/AttentionDashboardPage';
import AttentionDashboardOverviewPage from '../attention_dashboard_pages/AttentionDashboardOverviewPage';
import AttentionDashboardIndexPage from '../attention_dashboard_pages/AttentionDashboardIndexPage';
import SupplierAttentionDashboardPage from '../supplier_attention_dashboard_pages/SupplierAttentionDashboardPage';
import SupplierAttentionDashboardOverviewPage from '../supplier_attention_dashboard_pages/SupplierAttentionDashboardOverviewPage';
import SupplierAttentionDashboardIndexPage from '../supplier_attention_dashboard_pages/SupplierAttentionDashboardIndexPage';
import PurchaseOrderDetailReceiptsPage from '../purchase_orders_detail_page/PurchaseOrderDetailReceiptsPage';
import PartsDetailPricesPage from '../parts_detail_details_page/PartsDetailPricesPage';
import EquipmentDetailPricesPage from '../equipments_detail_details_page/EquipmentDetailPricesPage';
import PurchaseOrderDetailPRsPage from '../purchase_orders_detail_page/PurchaseOrderDetailPRsPage';
import RegionsOverviewIndexPage from '../regions_overview_index_page/RegionsOverviewIndexPage';
import AssetDowntimeHistoryIndexPage from '../assets_detail_page/AssetDowntimeHistoryIndexPage';
import NewBuyerInvoicePage from '../new_buyer_invoice_page/NewBuyerInvoicePage';
import EditBuyerInvoicePage from '../edit_buyer_invoice_page/EditBuyerInvoicePage';
import EditSpecificPlannedMaintenanceSchedulePage from '../edit_specific_planned_maintenance_schedule_page/EditSpecificPlannedMaintenanceSchedulePage';
import SpecificPlannedMaintenanceSchedulePage from '../specific_planned_maintenance_schedule_page/SpecificPlannedMaintenanceSchedulePage';
import SupplierRFPPage from '../supplier_rfp_pages/SupplierRFPPage';
import SupplierRFPOverviewPage from '../supplier_rfp_pages/SupplierRFPOverviewPage';
import SupplierRFPIndexPage from '../supplier_rfp_pages/SupplierRFPIndexPage';
import InternalSupplierRFPDetailDetailsPage from '../supplier_rfp_pages/InternalSupplierRFPDetailDetailsPage';
import RFPDetailBidsPage from '../rfp_detail_bids_page/RFPDetailBidsPage';
import StockLocationsPartsPage from '../stock_locations_detail_details_page/StockLocationsPartsPage';
import StockLocationEquipmentsPage from '../stock_locations_detail_details_page/StockLocationEquipmentsPage';
import EditSafeSupplierWorkOrderPage from '../edit_safe_supplier_work_order_page/EditSafeSupplierWorkOrderPage';
import EquipmentsTemplateIndexPage from '../equipments_index_page/EquipmentsTemplateIndexPage';
import AssetHistoryDetailPage from '../asset_history_details_page/AssetHistoryDetailPage';
import DepreciationPage from '../depreciation_pages/DepreciationPage';
import DepreciationOverviewPage from '../depreciation_pages/DepreciationOverviewPage';
import DepreciationClassIndexPage from '../depreciation_pages/DepreciationClassIndexPage';
import ImpairmentAdjustmentIndexPage from '../depreciation_pages/ImpairmentAdjustmentIndexPage';
import DepreciationBurdenRatesIndexPage from '../depreciation_pages/DepreciationBurdenRatesIndexPage';
import DepreciationJournalRunsPage from '../depreciation_pages/DepreciationJournalRunsPage';
import SupplierAssetDepreciationJournalPage from '../supplier_assets_detail_page/SupplierAssetDepreciationJournalPage';
import AssetDepreciationModelsPage from '../supplier_assets_detail_page/AssetDepreciationModelsPage';
import DepreciationClassDetailsPage from '../depreciation_pages/DepreciationClassDetailsPage';
import DepreciationClassDetailDetailPage from '../depreciation_pages/DepreciationClassDetailDetailPage';
import ProjectTemplatesIndexPage from '../project_templates_index_page/ProjectTemplatesIndexPage';
import NewProjectTemplatePage from '../new_project_template_page/NewProjectTemplatePage';
import ProjectTemplatesDetailPage from '../project_templates_detail_page/ProjectTemplatesDetailPage';
import ProjectTemplatesDetailDetailsPage from '../project_templates_detail_details_page/ProjectTemplatesDetailDetailsPage';
import EditProjectTemplatePage from '../edit_project_template_page/EditProjectTemplatePage';
import SupplierProjectsPage from '../supplier_projects_page/SupplierProjectsPage';
import ApprovalHierarchyDetailPage from '../approval_hierarchy_detail_page/ApprovalHierarchyDetailPage';
import ApprovalHierarchyPage from '../approval_hierarchy_page/ApprovalHierarchyPage';
import ApprovalHierarchyOverviewPage from '../approval_hierarchy_overview_page/ApprovalHierarchyOverviewPage';
import PartsPriceListIndexPage from '../parts_index_page/PartsPriceListIndexPage';
import PartCatalogDetailsPage from '../parts_index_page/PartCatalogDetailsPage';
import PartCatalogDetailsDetailPage from '../parts_index_page/PartCatalogDetailsDetailPage';
import EquipmentsPriceListIndexPage from '../equipments_index_page/EquipmentsPriceListIndexPage';
import EquipmentCatalogDetailsPage from '../equipments_index_page/EquipmentCatalogDetailsPage';
import EquipmentCatalogDetailsDetailPage from '../equipments_index_page/EquipmentCatalogDetailsDetailPage';
import PurchaseOrderLineItemsIndexPage from '../purchase_orders_index_page/PurchaseOrderLineItemsIndexPage';
import PurchaseRequestLineItemsIndexPage from '../purchase_requests_index_page/PurchaseRequestLineItemsIndexPage';
import EquipmentPerStockLocationDetailPage from '../equipments_index_page/EquipmentPerStockLocationDetailPage';
import EquipmentPerStockLocationDetailsDetailPage from '../equipments_index_page/EquipmentPerStockLocationDetailsDetailPage';
import PurchaseOrdersReceivedEquipmentsPage from '../purchase_orders_detail_page/PurchaseOrdersReceivedEquipmentsPage';
import StockLocationDisbursedEquipmentsPage from '../stock_locations_detail_details_page/StockLocationDisbursedEquipmentsPage';
import PartsPurchaseRequestsPage from '../parts_detail_details_page/PartsPurchaseRequestsPage';
import PartsPurchaseOrdersList from '../parts_detail_details_page/PartsPurchaseOrdersList';
import EquipmentPurchaseRequestsPage from '../equipments_detail_details_page/EquipmentPurchaseRequestsPage';
import EquipmentPurchaseOrdersPage from '../equipments_detail_details_page/EquipmentPurchaseOrdersPage';
import EquipmentCatalogPurchaseOrdersPage from '../equipments_index_page/EquipmentCatalogPurchaseOrdersPage';
import PartCatalogPurchaseOrdersPage from '../parts_index_page/PartCatalogPurchaseOrdersPage';
import PartWorkOrdersListPage from '../parts_detail_details_page/PartWorkOrdersListPage';
import EquipmentPerStockLocationWorkOrdersPage from '../equipments_index_page/EquipmentPerStockLocationWorkOrdersPage';
import EquipmentWorkOrdersPage from '../equipments_detail_details_page/EquipmentWorkOrdersPage';
import PartsPerStockLocationDetailPage from '../stock_locations_detail_details_page/PartsPerStockLocationDetailPage';
import PartsPerStockLocationWorkOrdersPage from '../stock_locations_detail_details_page/PartsPerStockLocationWorkOrdersPage';
import PartsPerStockLocationDetailsDetailPage from '../stock_locations_detail_details_page/PartsPerStockLocationDetailsDetailPage';
import NewPartsPerStockLocationPage from '../stock_locations_detail_details_page/NewPartsPerStockLocationPage';
import EditPartsPerStockLocationPage from '../stock_locations_detail_details_page/EditPartsPerStockLocationPage';
import StockLocationShipmentsPage from '../stock_locations_detail_details_page/StockLocationShipmentsPage';
import StockLocationBackordersPage from '../stock_locations_detail_details_page/StockLocationBackordersPage';
import ReadyToOrderListPage from '../purchase_requests_index_page/ReadyToOrderListPage';
import SupplierInventorySettingsPage from '../inventory/SupplierInventorySettingsPage';
import ApprovalRulesIndexPage from '../approval_hierarchy_quotes_index_page/ApprovalRulesIndexPage';
import StockLocationsEquipmentLevelPage from '../stock_locations_detail_details_page/StockLocationsEquipmentLevelPage';
import AssetNewWorkOrderWizardPage from '../assets_work_order_components/AssetNewWorkOrderWizardPage';
import SupplierAssetNewWorkOrderWizardPage from '../assets_work_order_components/SupplierAssetNewWorkOrderWizardPage';
import SupplierAdminAccessControlPage from '../supplier_settings_page/SupplierAdminAccessControlPage';
import BuyerAdminAccessControlPage from '../buyer_admin_settings_page/BuyerAdminAccessControlPage';
import BrandsOverviewIndexPage from '../brands_overview_index_page/BrandsOverviewIndexPage';
import AssetNewServiceRequestWizardPage from '../assets_work_order_components/AssetNewServiceRequestWizardPage';
import ResolutionTypeConfigPage from '../resolution_type_config_page/ResolutionTypeConfigPage';
import BuyerNewWorkOrderProposalPage from '../new_work_order_proposal_page/BuyerNewWorkOrderProposalPage';
import StockLocationShippedPage from '../stock_locations_detail_details_page/StockLocationShippedPage';
import ApprovedPurchaseRequestLineItemsIndexPage from '../purchase_requests_index_page/ApprovedPurchaseRequestLineItemsIndexPage';
import AssetAudit from '../assets_detail_page/AssetAudit';
import SupplierApproveWorkOrderPage from '../supplier_approve_work_order_page/SupplierApproveWorkOrderPage';
import BudgetIndexPage from '../budget_index_page/BudgetIndexPage';
import BuyerAdminBudgetsPage from '../buyer_admin_budgets_page/BuyerAdminBudgetsPage';
import BudgetDetailPage from '../budget_detail_page/BudgetDetailPage';
import BudgetDetailDetailsPage from '../budget_detail_details_page/BudgetDetailDetailsPage';
import NewBudgetPage from '../new_budget_page/NewBudgetPage';
import AssetsAnalyticsPage from '../downtime_analytics_page/DowntimeAnalyticsPage';
import DowntimeAnalyticsOverviewPage from '../downtime_analytics_overview_page/DowntimeAnalyticsOverviewPage';
import DowntimeAnalyticsOverviewLocationsPage from '../downtime_analytics_overview_locations_page/DowntimeAnalyticsOverviewLocationsPage';
import ComingSoon from '../ComingSoon';
import DowntimeAnalyticsOverviewCurrentPage from '../downtime_anayltics_overview_page/DowntimeAnalyticsOverviewCurrentPage';
import AssetDowntimeTable from '../asset_downtime_table/AssetDowntimeTable';
import SupplierLocationsIndexPage from '../supplier_locations_index_page/SupplierLocationsIndexPage';
import DowntimeAnalyticsOverviewManufacturersPage from '../downtime_analytics_overview_manufacturers_page/DowntimeAnalyticsOverviewManufacturersPage';
import DowntimeAnalyticsOverviewAssetTypesPage from '../downtime_analytics_overview_asset_types_page/DowntimeAnalyticsOverviewAssetTypesPage';
import TroubleshootingDetailPage from '../buyer_admin_settings_page/TroubleshootingDetailPage';
import TroubleshootingDetailDetailsPage from '../buyer_admin_settings_page/TroubleshootingDetailDetailsPage';
import EditTroubleshootingPage from '../buyer_admin_settings_page/EditTroubleshootingPage';
import TroubleshootingPage from '../buyer_admin_settings_page/TroubleshootingPage';
import TroubleshootingOverviewPage from '../buyer_admin_settings_page/TroubleshootingOverviewPage';
import TroubleshootingOverviewAllPage from '../buyer_admin_settings_page/TroubleshootingOverviewAllPage';
import EditBudgetPage from '../edit_budget_page/EditBudgetPage';
import BudgetDetailLocationsPage from '../budget_detail_locations_page/BudgetDetailLocationsPage';
import NewTroubleshootingPage from '../buyer_admin_settings_page/NewTroubleshootingPage';
import EditSiteSurveyPage from '../edit_site_survey_page/EditSiteSurveyPage';
import NotificationsTasks from '../NotificationsTasks';
import NotificationsApprovals from '../NotificationsApprovals';
import NotificationsNotes from '../NotificationsNotes';
import NotificationsStatusUpdates from '../NotificationsStatusUpdates';

import PurchaseOrderReceivedAssetsPage from '../purchase_orders_detail_page/PurchaseOrderReceivedAssetsPage';
import SupplierReferralsPage from '../supplier_referrals_page/SupplierReferralsPage';
import AccountingTaxRatePage from '../depreciation_pages/AccountingTaxRatePage';
import SupplierExchangeRatesPage from '../depreciation_pages/SupplierExchangeRatesPage';
import SiteSurveyWalkthroughsPage from '../site_surveys_detail_details_page/SiteSurveyWalkthroughsPage';
import LocationAudit from '../locations_detail_details_page/LocationAudit';
import PurchaseRequestReceivedEquipmentPage from '../purchase_requests_detail_details_page/PurchaseRequestReceivedEquipmentPage';
import PurchaseRequestReceivedAssetsPage from '../purchase_requests_detail_details_page/PurchaseRequestReceivedAssetsPage';

export const routes = [
	{
		path: '/',
		exact: true,
		component: Home,
		authProtected: false,
		childRoutes: [],
	},
	{
		path: '/terms_of_service',
		exact: true,
		component: EmptorTermsAndConditionsPage,
		authProtected: false,
		childRoutes: [],
	},
	{
		path: '/data_protection_policy',
		exact: true,
		component: EmptorDataProtectionPolicyPage,
		authProtected: false,
		childRoutes: [],
	},
	{
		path: '/buyer/treecomponent',
		exact: true,
		component: TestTreeComponentPage,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		authProtected: true,
		childRoutes: [],
	},
	/**
	 * Auth routes
	 */
	{
		path: '/login',
		exact: true,
		component: LoginPage,
		authProtected: false,
		childRoutes: [],
	},
	{
		path: '/supplier/login',
		exact: true,
		component: SupplierLoginPage,
		authProtected: false,
		childRoutes: [],
	},
	{
		path: '/buyer/login',
		exact: true,
		component: BuyerLoginPage,
		authProtected: false,
		childRoutes: [],
	},
	{
		path: '/buyer/signup',
		exact: true,
		component: BuyerSignupPage,
		authProtected: false,
		childRoutes: [],
	},
	{
		path: '/supplier/vendors',
		exact: false,
		component: VendorsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/vendors/overview',
				exact: false,
				component: VendorsOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/vendors/overview/all',
						exact: true,
						component: VendorsIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/vendors/overview/new',
						exact: true,
						component: NewVendorPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
				],
			},
			{
				path: '/supplier/vendors/detail/:id',
				exact: false,
				component: VendorsDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/vendors/detail/:id/details',
						exact: true,
						component: VendorsDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/vendors/edit/:id',
				exact: true,
				component: EditVendorPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [],
			},
		],
	},
	{
		path: '/supplier/purchaseRequests',
		exact: false,
		component: PurchaseRequestsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/purchaseRequests/overview',
				exact: false,
				component: PurchaseRequestsOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/purchaseRequests/overview/all',
						exact: true,
						component: PurchaseRequestsIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/purchaseRequests/overview/requests',
						exact: true,
						component: PurchaseRequestLineItemsIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/purchaseRequests/overview/approved',
						exact: true,
						component: ApprovedPurchaseRequestLineItemsIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/purchaseRequests/overview/readyToOrder',
						exact: true,
						component: ReadyToOrderListPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/purchaseRequests/overview/new',
						exact: true,
						component: NewPurchaseRequestPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
				],
			},
			{
				path: '/supplier/purchaseRequests/detail/:id',
				exact: false,
				component: PurchaseRequestsDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/purchaseRequests/detail/:id/details',
						exact: true,
						component: PurchaseRequestsDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/purchaseRequests/detail/:id/receivedEquipments',
						exact: true,
						component: PurchaseRequestReceivedEquipmentPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/purchaseRequests/detail/:id/receivedAssets',
						exact: true,
						component: PurchaseRequestReceivedAssetsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/purchaseRequests/edit/:id',
				exact: true,
				component: EditPurchaseRequestPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [],
			},
		],
	},
	{
		path: '/supplier/purchaseOrders',
		exact: false,
		component: PurchaseOrdersPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/purchaseOrders/overview',
				exact: false,
				component: PurchaseOrdersOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/purchaseOrders/overview/all',
						exact: true,
						component: PurchaseOrdersIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/purchaseOrders/overview/orders',
						exact: true,
						component: PurchaseOrderLineItemsIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/purchaseOrders/overview/new',
						exact: true,
						component: NewPurchaseOrderPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
				],
			},
			{
				path: '/supplier/purchaseOrders/detail/:id',
				exact: false,
				component: PurchaseOrdersDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/purchaseOrders/detail/:id/details',
						exact: true,
						component: PurchaseOrdersDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/purchaseOrders/detail/:id/receipts',
						exact: true,
						component: PurchaseOrderDetailReceiptsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/purchaseOrders/detail/:id/purchaseRequests',
						exact: true,
						component: PurchaseOrderDetailPRsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/purchaseOrders/detail/:id/receivedEquipments',
						exact: true,
						component: PurchaseOrdersReceivedEquipmentsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/purchaseOrders/detail/:id/receivedAssets',
						exact: true,
						component: PurchaseOrderReceivedAssetsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/purchaseOrders/edit/:id',
				exact: true,
				component: EditPurchaseOrderPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [],
			},
		],
	},
	{
		path: '/supplier/signup',
		exact: true,
		component: SupplierSignupPage,
		authProtected: false,
		childRoutes: [],
	},
	{
		path: '/supplier/claimSignup',
		exact: true,
		component: SupplierClaimSignUpPage,
		authProtected: false,
		childRoutes: [],
	},
	{
		path: '/supplier/signupCompletion',
		exact: true,
		component: SupplierSignupCompletionPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [],
	},
	{
		path: '/buyer/signupCompletion',
		exact: true,
		component: BuyerSignupCompletionPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		childRoutes: [],
	},
	{
		path: '/supplier/needEmailAuthentication',
		exact: true,
		component: SupplierNeedEmailAuthenticationPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [],
	},
	{
		path: '/buyer/needEmailAuthentication',
		exact: true,
		component: BuyerNeedEmailAuthenticationPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		childRoutes: [],
	},
	{
		path: '/buyer/forgotPassword',
		exact: true,
		component: BuyerForgotPasswordPage,
		authProtected: false,
		childRoutes: [],
	},
	{
		path: '/supplier/forgotPassword',
		exact: true,
		component: SupplierForgotPasswordPage,
		authProtected: false,
		childRoutes: [],
	},
	{
		path: '/buyer/resetPassword',
		exact: true,
		component: BuyerResetPasswordPage,
		authProtected: false,
		childRoutes: [],
	},
	{
		path: '/supplier/resetPassword',
		exact: true,
		component: SupplierResetPasswordPage,
		authProtected: false,
		childRoutes: [],
	},
	{
		path: '/supplier/changePassword',
		exact: false,
		component: SupplierChangePasswordPage,
		authProtected: false,
		childRoutes: [],
	},
	{
		path: '/buyer/changePassword',
		exact: false,
		component: BuyerChangePasswordPage,
		authProtected: false,
		childRoutes: [],
	},
	/**
	 * Buyer
	 */

	{
		path: '/buyer/referral',
		exact: true,
		component: BuyerReferralsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		childRoutes: [],
	},
	{
		path: '/buyer/newAccountSetup',
		exact: false,
		component: BuyerNewAccountSetupPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		childRoutes: [
			{
				path: '/buyer/newAccountSetup/association',
				exact: true,
				component: BuyerNewAccountSetupAssociationPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [],
				breadcrumbs: [{ title: 'New Account Setup' }],
			},
			{
				path: '/buyer/newAccountSetup/profile',
				exact: true,
				component: BuyerNewAccountSetupProfilePage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [],
				breadcrumbs: [{ title: 'New Account Setup' }],
			},
			{
				path: '/buyer/newAccountSetup/completion',
				exact: true,
				component: BuyerNewAccountSetupCompletionPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [],
				breadcrumbs: [{ title: 'New Account Setup' }],
			},
		],
		breadcrumbs: [{ title: 'New Account Setup' }],
	},
	{
		path: '/buyer/myProfile',
		exact: true,
		component: MyBuyerProfilePage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		childRoutes: [],
	},
	{
		path: '/buyer/myProfile/edit',
		exact: true,
		component: MyBuyerEditProfilePage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		childRoutes: [],
	},
	// {
	//     path: '/buyer/dashboard',
	//     exact: false,
	//     component: BuyerHomePage,
	//     authProtected: true,
	//     authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
	//     childRoutes: [],
	//     breadcrumbs: [
	//         {title: 'Dashboard'}
	//     ]
	// },
	{
		path: '/search',
		exact: true,
		component: PublicWrenchHubPage,
		authProtected: false,
		authorizedRoles: authorizedRolesCombinations.ALL_ROLES,
		childRoutes: [],
		breadcrumbs: [],
	},
	{
		path: '/buyer/buyers/:id',
		exact: true,
		component: BuyerBuyersDetailPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		childRoutes: [],
		breadcrumbs: [{ title: 'Company Detail' }],
	},
	{
		path: '/buyer/settings',
		exact: false,
		component: BuyerSettingsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		childRoutes: [
			{
				path: '/buyer/settings/notifications',
				exact: true,
				component: BuyerNotificationsSettingsPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [],
			},
			{
				path: '/buyer/settings/inbox/edit',
				exact: true,
				component: EditBuyerNotificationsSettingsPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [],
			},
		],
	},
	{
		path: '/supplier/settings',
		exact: false,
		component: SupplierSettingsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/settings/notifications',
				exact: true,
				component: SupplierNotificationsSettingsPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
			},
			{
				path: '/supplier/settings/inbox/edit',
				exact: true,
				component: EditSupplierNotificationsSettingsPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
			},
		],
	},
	{
		path: '/buyer/invoices',
		exact: false,
		component: InvoicesPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		childRoutes: [
			{
				path: '/buyer/invoices/overview',
				exact: false,
				component: InvoicesOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/invoices/overview/new',
						exact: true,
						component: NewBuyerInvoicePage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ADMIN_ONLY,
						childRoutes: [],
					},
					{
						path: '/buyer/invoices/overview/all',
						exact: true,
						component: InvoicesIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
					},
				],
			},
			{
				path: '/buyer/invoices/detail/:id',
				exact: false,
				component: InvoicesDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/invoices/detail/:id/details',
						exact: true,
						component: InvoicesDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/buyer/invoices/edit/:id',
				exact: true,
				component: EditBuyerInvoicePage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ADMIN_ONLY,
				childRoutes: [],
				breadcrumbs: [],
			},
			{
				path: '/buyer/invoices/savedViews',
				exact: false,
				component: InvoicesSavedFiltersViewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/invoices/savedViews/overview',
						exact: false,
						component: InvoicesSavedFiltersViewOverviewPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [
							{
								path: '/buyer/invoices/savedViews/overview/all',
								exact: true,
								component: InvoicesSavedFiltersViewIndexPage,
								authProtected: true,
								authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
								childRoutes: [],
								breadcrumbs: [],
							},
						],
						breadcrumbs: [],
					},
				],
			},
		],
	},
	{
		path: '/buyer/quotes',
		exact: false,
		component: QuotesPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
		childRoutes: [
			{
				path: '/buyer/quotes/overview',
				exact: false,
				component: QuotesOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
				childRoutes: [
					{
						path: '/buyer/quotes/overview/all',
						exact: true,
						component: QuotesIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
						childRoutes: [],
					},
				],
			},
			{
				path: '/buyer/quotes/detail/:id',
				exact: false,
				component: QuotesDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
				childRoutes: [
					{
						path: '/buyer/quotes/detail/:id/details',
						exact: true,
						component: QuotesDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/buyer/quotes/edit/:id',
				exact: true,
				component: EditQuotePage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [],
				breadcrumbs: [],
			},
		],
	},
	{
		path: '/buyer/siteSurveys',
		exact: false,
		component: SiteSurveysPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		childRoutes: [
			{
				path: '/buyer/siteSurveys/overview',
				exact: false,
				component: SiteSurveysOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/siteSurveys/overview/all',
						exact: true,
						component: SiteSurveysIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Site Surveys' }],
					},
					{
						path: '/buyer/siteSurveys/overview/new',
						exact: true,
						component: NewSiteSurveyPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
					},
					{
						path: '/buyer/siteSurveys/overview/edit/:id',
						exact: true,
						component: EditSiteSurveyPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
					},
					{
						path: '/buyer/siteSurveys/overview/templates/new',
						exact: true,
						component: NewSiteSurveyTemplatePage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
					},
					{
						path: '/buyer/siteSurveys/overview/templates/overview',
						exact: true,
						component: SiteSurveyTemplatesIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/buyer/siteSurveys/detail/:id',
				exact: false,
				component: SiteSurveysDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/siteSurveys/detail/:id/details',
						exact: true,
						component: SiteSurveysDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/siteSurveys/detail/:id/walkthroughs',
						exact: true,
						component: SiteSurveyWalkthroughsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/buyer/siteSurveys/templates/detail/:id',
				exact: false,
				component: SiteSurveyTemplatesDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/siteSurveys/templates/detail/:id/details',
						exact: true,
						component: SiteSurveyTemplatesDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			// {
			//     path: '/buyer/siteSurveys/templates/edit/:id',
			//     exact: true,
			//     component: EditLocationTypePage,
			//     authProtected: true,
			//     authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
			//     childRoutes: []
			// },
			{
				path: '/buyer/siteSurveys/walkthrough/detail/:id',
				exact: false,
				component: WalkthroughsDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/siteSurveys/walkthrough/detail/:id/details',
						exact: true,
						component: WalkthroughsDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
		],
	},
	{
		path: '/buyer/requestsForProposal',
		exact: false,
		component: RequestsForProposalPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
		childRoutes: [
			{
				path: '/buyer/requestsForProposal/overview',
				exact: false,
				component: RequestsForProposalOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
				childRoutes: [
					{
						path: '/buyer/requestsForProposal/overview/all',
						exact: true,
						component: RequestsForProposalIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
						childRoutes: [],
						breadcrumbs: [{ title: 'Request For Proposal' }],
					},
					{
						path: '/buyer/requestsForProposal/overview/new',
						exact: false,
						component: NewRequestForProposalPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
						childRoutes: [],
						breadcrumbs: [
							{ title: 'Request For Proposal', to: '/buyer/requestsForProposal' },
							{ title: 'New' },
						],
					},
				],
				breadcrumbs: [{ title: 'Request For Proposal' }],
			},
			{
				path: '/buyer/requestsForProposal/detail/:id',
				exact: false,
				component: RFPDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
				childRoutes: [],
				breadcrumbs: [
					{ title: 'Request For Proposal', to: '/buyer/requestsForProposal' },
					{ title: 'Event Details' },
				],
			},
			{
				path: '/buyer/requestsForProposal/detail/:id/details',
				exact: true,
				component: RFPDetailDetailsPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
				childRoutes: [],
				breadcrumbs: [
					{ title: 'Request For Proposal', to: '/buyer/requestsForProposal' },
					{ title: 'Event Details' },
				],
			},
			{
				path: '/buyer/requestsForProposal/detail/:id/compareBids',
				exact: true,
				component: RFPDetailBidsPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
				childRoutes: [],
				breadcrumbs: [
					{ title: 'Request For Proposal', to: '/buyer/requestsForProposal' },
					{ title: 'Compare Bids' },
				],
			},
			{
				path: '/buyer/requestsForProposal/edit/:id',
				exact: true,
				component: EditRequestForProposalPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
				childRoutes: [],
				breadcrumbs: [
					{ title: 'Request For Proposal', to: '/buyer/requestsForProposal' },
					{ title: 'Event Details' },
				],
			},
			{
				path: '/buyer/requestsForProposal/detail/:id/edit/requestForProposalBidderAssoc',
				exact: true,
				component: RFPBidderEditPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
				childRoutes: [],
				breadcrumbs: [
					{ title: 'Request For Proposal', to: '/buyer/requestsForProposal' },
					{ title: 'Bidders' },
				],
			},
		],
	},
	{
		path: '/buyer/plannedMaintenance',
		exact: false,
		component: PlannedMaintenancePage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
		childRoutes: [
			{
				path: '/buyer/plannedMaintenance/templates/detail/:id',
				exact: false,
				component: PlannedMaintenanceTemplatesDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
				childRoutes: [
					{
						path: '/buyer/plannedMaintenance/templates/detail/:id/details',
						exact: true,
						component: PlannedMaintenanceTemplatesDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/buyer/plannedMaintenance/templates/edit/:id',
				exact: true,
				component: EditPlannedMaintenanceTemplatePage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
				childRoutes: [],
			},
			{
				path: '/buyer/plannedMaintenance/edit/:id',
				exact: true,
				component: EditSpecificPlannedMaintenanceSchedulePage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
				childRoutes: [],
			},
			{
				path: '/buyer/plannedMaintenance/:title/:frequency/:plannedMaintenanceTemplateId/:basedOn/new',
				exact: true,
				component: SpecificPlannedMaintenanceSchedulePage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
				childRoutes: [],
			},
			{
				path: '/buyer/plannedMaintenance/detail/:title/:frequency/:plannedMaintenanceTemplateId/:basedOn',
				exact: false,
				component: PlannedMaintenanceSchedulesDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
				childRoutes: [
					{
						path: '/buyer/plannedMaintenance/detail/:title/:frequency/:plannedMaintenanceTemplateId/:basedOn/details',
						exact: true,
						component: PlannedMaintenanceSchedulesDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/buyer/plannedMaintenance/overview',
				exact: false,
				component: PlannedMaintenanceSchedulesOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
				childRoutes: [
					{
						path: '/buyer/plannedMaintenance/overview/all',
						exact: true,
						component: PlannedMaintenanceSchedulesIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/plannedMaintenance/overview/templates/overview',
						exact: true,
						component: PlannedMaintenanceTemplatesIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/buyer/plannedMaintenance/new',
				exact: true,
				component: NewPlannedMaintenanceSchedulePage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
				childRoutes: [],
			},
			{
				path: '/buyer/plannedMaintenance/edit/:title/:frequency/:plannedMaintenanceTemplateId/:basedOn',
				exact: true,
				component: EditPlannedMaintenanceSchedulePage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
				childRoutes: [],
			},
			{
				path: '/buyer/plannedMaintenance/templates/new',
				exact: true,
				component: NewPlannedMaintenanceTemplatePage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
				childRoutes: [],
			},
		],
	},
	{
		path: '/buyer/locations',
		exact: false,
		component: LocationsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		childRoutes: [
			{
				path: '/buyer/locations/detail/:id',
				exact: false,
				component: LocationsDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/locations/detail/:id/details',
						exact: true,
						component: LocationsDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/locations/detail/:id/meters',
						exact: true,
						component: LocationsDetailMetersPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/locations/detail/:id/suppliers',
						exact: true,
						component: LocationDetailsSuppliersPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/locations/detail/:id/audit',
						exact: true,
						component: LocationAudit,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/buyer/locations/edit/:id',
				exact: true,
				component: EditLocationPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [],
			},
			{
				path: '/buyer/locations/templates/detail/:id',
				exact: false,
				component: LocationTypesDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/locations/templates/detail/:id/details',
						exact: true,
						component: LocationTypesDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/buyer/locations/templates/edit/:id',
				exact: true,
				component: EditLocationTypePage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [],
			},
			{
				path: '/buyer/locations/overview',
				exact: false,
				component: LocationsOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/locations/overview/all',
						exact: true,
						component: LocationsIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/locations/overview/templates/new',
						exact: true,
						component: NewLocationTypePage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
					},
					{
						path: '/buyer/locations/overview/templates/overview',
						exact: true,
						component: LocationTypesIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/locations/overview/new',
						exact: true,
						component: NewLocationPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
					},
					{
						path: '/buyer/locations/overview/regions/overview',
						exact: true,
						component: RegionsOverviewIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
					},
					{
						path: '/buyer/locations/overview/brands/overview',
						exact: true,
						component: BrandsOverviewIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
					},
				],
				breadcrumbs: [],
			},
		],
	},
	{
		path: '/buyer/projects',
		exact: false,
		component: ProjectsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		childRoutes: [
			{
				path: '/buyer/projects/detail/:id',
				exact: false,
				component: ProjectsDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/projects/detail/:id/details/:action?',
						exact: true,
						component: ProjectsDetailOverviewPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/projects/detail/:id/workOrders',
						exact: true,
						component: ProjectsWorkOrdersPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/projects/detail/:id/invoices',
						exact: true,
						component: ProjectInvoicesPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/buyer/projects/edit/:id',
				exact: true,
				component: ProjectEditPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [],
			},
			{
				path: '/buyer/projects/newProject',
				exact: true,
				component: ProjectNewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [],
			},
			{
				path: '/buyer/projects/overview',
				exact: false,
				component: ProjectsOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/projects/overview/active',
						exact: true,
						component: ProjectsIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/projects/overview/archived',
						exact: true,
						component: ProjectsIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/projects/overview/templates/new',
						exact: true,
						component: NewProjectTemplatePage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
					},
					{
						path: '/buyer/projects/overview/templates/overview',
						exact: true,
						component: ProjectTemplatesIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
				breadcrumbs: [],
			},
			{
				path: '/buyer/projects/templates/detail/:id',
				exact: false,
				component: ProjectTemplatesDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/projects/templates/detail/:id/details',
						exact: true,
						component: ProjectTemplatesDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/buyer/projects/templates/edit/:id',
				exact: false,
				component: EditProjectTemplatePage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [],
			},
		],
	},
	{
		path: '/buyer/dashboards',
		exact: false,
		component: AttentionDashboardPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		childRoutes: [
			{
				path: '/buyer/dashboards/overview',
				exact: false,
				component: AttentionDashboardOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/dashboards/overview/all',
						exact: true,
						component: AttentionDashboardIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
				breadcrumbs: [],
			},
		],
	},
	{
		path: '/buyer/workOrders',
		exact: false,
		component: WorkOrdersPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		childRoutes: [
			{
				path: '/buyer/workOrders/detail/:id',
				exact: false,
				component: WorkOrdersDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/workOrders/detail/:id/details/:action?',
						exact: true,
						component: WorkOrdersDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/workOrders/detail/:id/quotes',
						exact: true,
						component: WorkOrdersDetailQuotesPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/workOrders/detail/:id/newProposal',
						exact: true,
						component: BuyerNewWorkOrderProposalPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Work Order', to: '/buyer/workOrders' }],
					},
					{
						path: '/buyer/workOrders/detail/:id/editProposal/:proposalId',
						exact: true,
						component: EditWorkOrderProposalPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Work Order', to: '/buyer/workOrders' }],
					},
					{
						path: '/buyer/workOrders/detail/:id/invoices',
						exact: true,
						component: WorkOrdersDetailInvoicesPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/workOrders/detail/:id/invoices/new',
						exact: true,
						component: NewBuyerInvoicePage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/workOrders/detail/:workOrderId/invoices/:id/edit',
						exact: true,
						component: EditBuyerInvoicePage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/workOrders/detail/:id/notes',
						exact: true,
						component: WorkOrdersDetailNotesPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/buyer/workOrders/edit/:id',
				exact: true,
				component: EditWorkOrderPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [],
			},
			{
				path: '/buyer/workOrders/editSafe/:id',
				exact: true,
				component: EditSafeWorkOrderPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [],
			},
			{
				path: '/buyer/workOrders/pending/edit/:id',
				exact: true,
				component: PendingEditWorkOrderPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [],
			},
			{
				path: '/buyer/workOrders/approve/:id',
				exact: true,
				component: ApproveWorkOrderPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [],
			},
			{
				path: '/buyer/workOrders/newWorkOrder',
				exact: false,
				component: NewWorkOrderWizardPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [],
			},
			{
				path: '/buyer/workOrders/requestService',
				exact: false,
				component: NewServiceRequestWizardPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [],
			},
			{
				path: '/buyer/workOrders/requestServiceForm',
				exact: false,
				component: NewServiceRequestPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [],
			},
			{
				path: '/buyer/workOrders/overview',
				exact: false,
				component: WorkOrdersOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/workOrders/overview/all',
						exact: true,
						component: WorkOrdersAllIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/workOrders/overview/active',
						exact: true,
						component: WorkOrdersActiveIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/workOrders/overview/archived',
						exact: true,
						component: WorkOrdersArchivedIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/workOrders/overview/new',
						exact: true,
						component: NewWorkOrderPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
					},
					{
						path: '/buyer/workOrders/overview/newWorkOrder',
						exact: false,
						component: NewWorkOrderWizardPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
					},
					{
						path: '/buyer/workOrders/overview/requestService',
						exact: false,
						component: NewServiceRequestWizardPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
					},
					{
						path: '/buyer/workOrders/overview/requestServiceForm',
						exact: false,
						component: NewServiceRequestPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
					},
				],
				breadcrumbs: [],
			},
			{
				path: '/buyer/workOrders/savedViews',
				exact: false,
				component: WorkOrdersSavedFiltersViewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/workOrders/savedViews/overview',
						exact: false,
						component: WorkOrdersSavedFiltersViewOverviewPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [
							{
								path: '/buyer/workOrders/savedViews/overview/all',
								exact: true,
								component: WorkOrdersSavedFiltersViewIndexPage,
								authProtected: true,
								authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
								childRoutes: [],
								breadcrumbs: [],
							},
						],
						breadcrumbs: [],
					},
				],
			},
		],
	},
	{
		path: '/buyer/inbox',
		exact: false,
		component: BuyerNotificationsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		childRoutes: [
			{
				path: '/buyer/inbox/overview',
				exact: false,
				component: BuyerNotificationsOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/inbox/overview/important',
						exact: true,
						component: NotificationsTasks,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/inbox/overview/approvals',
						exact: true,
						component: NotificationsApprovals,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/inbox/overview/notes',
						exact: true,
						component: NotificationsNotes,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/inbox/overview/status',
						exact: true,
						component: NotificationsStatusUpdates,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
				breadcrumbs: [],
			},
		],
	},
	{
		path: '/buyer/meters',
		exact: false,
		component: MetersPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		childRoutes: [
			{
				path: '/buyer/meters/detail/:id',
				exact: false,
				component: MetersDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/meters/detail/:meterId/details',
						exact: true,
						component: MetersDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/buyer/meters/edit/:id',
				exact: true,
				component: EditMeterPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [],
			},
			{
				path: '/buyer/meters/types/detail/:id',
				exact: false,
				component: MeterTypesDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/meters/types/detail/:id/details',
						exact: true,
						component: MeterTypesDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/buyer/meters/overview',
				exact: false,
				component: MetersOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/meters/overview/all',
						exact: true,
						component: MetersIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/meters/overview/types/new',
						exact: true,
						component: NewMeterTypePage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
					},
					{
						path: '/buyer/meters/overview/types/overview',
						exact: true,
						component: MeterTypesIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/meters/overview/new',
						exact: true,
						component: NewMeterPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
					},
				],
				breadcrumbs: [],
			},
		],
	},
	{
		path: '/buyer/assets',
		exact: false,
		component: AssetsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		childRoutes: [
			{
				path: '/buyer/assets/detail/:id',
				exact: false,
				component: AssetsDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/assets/detail/:id/details',
						exact: true,
						component: AssetsDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/assets/detail/:id/downtimeHistory',
						exact: true,
						component: AssetDowntimeHistoryIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/assets/detail/:id/audit',
						exact: true,
						component: AssetAudit,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/assets/detail/:id/manuals',
						exact: true,
						component: AssetsDetailManualsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/assets/detail/:id/warranties/new',
						exact: true,
						component: NewWarrantyPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
					},
					// {
					//     path: '/buyer/assets/detail/:assetId/warranties/:warrantyId/edit',
					//     exact: true,
					//     component: EditWarrantyPage,
					//     authProtected: true,
					//     authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
					//     childRoutes: []
					// },
				],
			},
			{
				path: '/buyer/assets/:id/newWorkOrder',
				exact: false,
				component: AssetNewWorkOrderWizardPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [],
			},
			{
				path: '/buyer/assets/:id/requestService',
				exact: false,
				component: AssetNewServiceRequestWizardPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [],
			},
			{
				path: '/buyer/assets/edit/:id',
				exact: true,
				component: EditAssetPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [],
			},
			{
				path: '/buyer/assets/templates/detail/:id',
				exact: false,
				component: AssetTemplatesDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/assets/templates/detail/:id/details',
						exact: true,
						component: AssetTemplatesDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/buyer/assets/templates/edit/:id',
				exact: true,
				component: EditAssetTemplatePage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [],
			},
			{
				path: '/buyer/assets/models/detail/:id',
				exact: false,
				component: AssetModelsDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/assets/models/detail/:id/details',
						exact: true,
						component: AssetModelsDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/buyer/assets/models/edit/:id',
				exact: true,
				component: EditAssetModelPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [],
			},
			{
				path: '/buyer/assets/overview',
				exact: false,
				component: AssetsOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/assets/overview/all',
						exact: true,
						component: AssetsIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/assets/overview/templates/new',
						exact: true,
						component: NewAssetTypePage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
					},
					{
						path: '/buyer/assets/overview/templates/overview',
						exact: true,
						component: AssetTemplatesIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/assets/overview/new',
						exact: true,
						component: NewAssetPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
					},
					{
						path: '/buyer/assets/overview/downtime',
						exact: true,
						component: AssetDowntimeTable,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
					},
				],
				breadcrumbs: [],
			},
		],
	},
	{
		path: '/buyer/analytics/work',
		exact: false,
		component: WorkAnalyticsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		childRoutes: [
			{
				path: '/buyer/analytics/work/overview',
				exact: false,
				component: WorkAnalyticsOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/analytics/work/overview/priorities',
						exact: true,
						component: WorkAnalyticsOverviewPrioritiesPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
					{
						path: '/buyer/analytics/work/overview/vendors',
						exact: true,
						component: WorkAnalyticsOverviewVendorsPage2,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
					{
						path: '/buyer/analytics/work/overview/problemTypes',
						exact: true,
						component: WorkAnalyticsOverviewProblemTypesPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
					{
						path: '/buyer/analytics/work/overview/manufacturers',
						exact: true,
						component: WorkAnalyticsOverviewManufacturersPage2,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
					{
						path: '/buyer/analytics/work/overview/assetTypes',
						exact: true,
						component: WorkAnalyticsOverviewAssetTypesPage2,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
					{
						path: '/buyer/analytics/work/overview/locations',
						exact: true,
						component: WorkAnalyticsOverviewLocationsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
				],
			},
		],
	},
	{
		path: '/buyer/analytics/assets',
		exact: false,
		component: AssetsAnalyticsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		childRoutes: [
			{
				path: '/buyer/analytics/assets/overview',
				exact: false,
				component: DowntimeAnalyticsOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/analytics/assets/overview/current',
						exact: true,
						component: DowntimeAnalyticsOverviewCurrentPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
					{
						path: '/buyer/analytics/assets/overview/locations',
						exact: true,
						component: DowntimeAnalyticsOverviewLocationsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
					{
						path: '/buyer/analytics/assets/overview/problemTypes',
						exact: true,
						component: ComingSoon,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
					{
						path: '/buyer/analytics/assets/overview/manufacturers',
						exact: true,
						component: DowntimeAnalyticsOverviewManufacturersPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
					{
						path: '/buyer/analytics/assets/overview/assetTypes',
						exact: true,
						component: DowntimeAnalyticsOverviewAssetTypesPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
				],
			},
		],
	},
	{
		path: '/buyer/analytics/kpi',
		exact: false,
		component: KPIAnalyticsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
		childRoutes: [
			{
				path: '/buyer/analytics/kpi/overview',
				exact: false,
				component: KPIAnalyticsOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
				childRoutes: [
					{
						path: '/buyer/analytics/kpi/overview/vendors',
						exact: true,
						component: KPIAnalyticsOverviewVendorsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
				],
				breadcrumbs: [{ title: 'Analytics' }],
			},
			{
				path: '/buyer/analytics/kpi/vendors/detail/:id',
				exact: true,
				component: KPIAnalyticsVendorDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
				childRoutes: [],
				breadcrumbs: [{ title: 'Analytics' }],
			},
		],
	},
	{
		path: '/buyer/analytics/spend',
		exact: false,
		component: SpendAnalyticsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		childRoutes: [
			{
				path: '/buyer/analytics/spend/overview',
				exact: false,
				component: SpendAnalyticsOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
				childRoutes: [
					{
						path: '/buyer/analytics/spend/overview/categories',
						exact: true,
						component: SpendAnalyticsOverviewCategoriesPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
					{
						path: '/buyer/analytics/spend/overview/problemTypes',
						exact: true,
						component: SpendAnalyticsOverviewProblemTypesPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
					{
						path: '/buyer/analytics/spend/overview/vendors',
						exact: true,
						component: SpendAnalyticsOverviewVendorsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
					{
						path: '/buyer/analytics/spend/overview/locations',
						exact: true,
						component: SpendAnalyticsOverviewLocationsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
				],
				breadcrumbs: [{ title: 'Analytics' }],
			},
		],
	},
	{
		path: '/buyer/suppliers',
		exact: false,
		component: SuppliersPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
		childRoutes: [
			{
				path: '/buyer/suppliers/overview',
				exact: false,
				component: SuppliersOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
				childRoutes: [
					{
						path: '/buyer/suppliers/overview/wrenchhub',
						exact: true,
						component: BuyerWrenchHubPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/suppliers/overview/privateNetwork',
						exact: true,
						component: PrivateNetworkIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/buyer/suppliers/overview/locations',
						exact: true,
						component: PrivateNetworkLocationsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
				breadcrumbs: [],
			},
			{
				path: '/buyer/suppliers/detail/:id',
				exact: true,
				component: BuyerSuppliersDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ONLY_NO_STORE_ASSOCIATE,
				childRoutes: [],
				breadcrumbs: [],
			},
		],
	},
	{
		path: '/public/suppliers/detail/:id',
		exact: true,
		component: SuppliersPublicDetailPage,
		authProtected: false,
		childRoutes: [],
		breadcrumbs: [{ title: 'Suppliers' }, { title: 'Detail' }],
	},
	{
		path: '/buyer/users',
		exact: true,
		component: BuyerAdminUsersPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ADMIN_ONLY,
		childRoutes: [],
		breadcrumbs: [{ title: 'Admin', to: '/buyer/admin' }, { title: 'Users' }],
	},
	{
		path: '/buyer/admin/troubleshooting/detail/:id',
		exact: false,
		component: TroubleshootingDetailPage,
		authorizedRoles: authorizedRolesCombinations.SUPER_ADMIN_ONLY,
		childRoutes: [
			{
				path: '/buyer/admin/troubleshooting/detail/:id/details',
				exact: true,
				component: TroubleshootingDetailDetailsPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPER_ADMIN_ONLY,
				childRoutes: [],
				breadcrumbs: [],
			},
		],
		breadcrumbs: [{ title: 'Troubleshooting', to: '/buyer/admin' }, { title: 'Company Settings' }],
	},
	{
		path: '/buyer/budgets/detail/:id',
		exact: false,
		component: BudgetDetailPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ADMIN_ONLY,
		breadcrumbs: [],
		childRoutes: [
			{
				path: '/buyer/budgets/detail/:id/details',
				exact: true,
				component: BudgetDetailDetailsPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ADMIN_ONLY,
				childRoutes: [],
			},
			{
				path: '/buyer/budgets/detail/:id/locations/:locationId',
				exact: true,
				component: BudgetDetailLocationsPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ADMIN_ONLY,
				childRoutes: [],
			},
		],
	},
	{
		path: '/buyer/admin/troubleshooting/new',
		exact: true,
		component: NewTroubleshootingPage,
		authorizedRoles: authorizedRolesCombinations.SUPER_ADMIN_ONLY,
		childRoutes: [],
		breadcrumbs: [],
	},
	{
		path: '/buyer/admin/troubleshooting/edit/:id',
		exact: true,
		component: EditTroubleshootingPage,
		authorizedRoles: authorizedRolesCombinations.SUPER_ADMIN_ONLY,
		childRoutes: [],
		breadcrumbs: [],
	},
	{
		path: '/buyer/budgets/edit/:id',
		exact: true,
		component: EditBudgetPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ADMIN_ONLY,
		childRoutes: [],
	},
	{
		path: '/buyer/budgets/new',
		exact: true,
		component: NewBudgetPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ADMIN_ONLY,
		childRoutes: [],
	},
	{
		path: '/buyer/admin',
		exact: false,
		component: BuyerAdminPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ADMIN_ONLY,
		childRoutes: [
			{
				path: '/buyer/admin/users',
				exact: true,
				component: BuyerAdminUsersPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ADMIN_ONLY,
				childRoutes: [],
				breadcrumbs: [{ title: 'Admin', to: '/buyer/admin' }, { title: 'Users' }],
			},
			{
				path: '/buyer/admin/companySettings',
				exact: true,
				component: BuyerAdminSettingsPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ADMIN_ONLY,
				childRoutes: [],
				breadcrumbs: [{ title: 'Admin', to: '/buyer/admin' }, { title: 'Company Settings' }],
			},
			{
				path: '/buyer/admin/budgets',
				exact: false,
				component: BuyerAdminBudgetsPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ADMIN_ONLY,
				childRoutes: [
					{
						path: '/buyer/admin/budgets/overview',
						exact: false,
						component: BudgetIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ADMIN_ONLY,
						childRoutes: [],
					},
				],
			},
			{
				path: '/buyer/admin/accessControl',
				exact: true,
				component: BuyerAdminAccessControlPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ADMIN_ONLY,
				childRoutes: [],
				breadcrumbs: [
					{ title: 'Access Control', to: '/buyer/admin' },
					{ title: 'Company Settings' },
				],
			},
			{
				path: '/buyer/admin/troubleshooting',
				exact: false,
				component: TroubleshootingPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPER_ADMIN_ONLY,
				childRoutes: [
					{
						path: '/buyer/admin/troubleshooting/overview',
						exact: false,
						component: TroubleshootingOverviewPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPER_ADMIN_ONLY,
						childRoutes: [
							{
								path: '/buyer/admin/troubleshooting/overview/all',
								exact: true,
								component: TroubleshootingOverviewAllPage,
								authProtected: true,
								authorizedRoles: authorizedRolesCombinations.SUPER_ADMIN_ONLY,
								childRoutes: [],
							},
						],
					},
				],
				breadcrumbs: [
					{ title: 'Troubleshooting', to: '/buyer/admin' },
					{ title: 'Company Settings' },
				],
			},
			{
				path: '/buyer/admin/resolutionTypes',
				exact: true,
				component: ResolutionTypeConfigPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ADMIN_ONLY,
				childRoutes: [],
				breadcrumbs: [
					{ title: 'Access Control', to: '/buyer/admin' },
					{ title: 'Company Settings' },
				],
			},
			{
				path: '/buyer/admin/approvalConfig',
				exact: true,
				component: BuyerAdminApprovalPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ADMIN_ONLY,
				childRoutes: [],
				breadcrumbs: [{ title: 'Admin', to: '/buyer/admin' }, { title: 'Approval Configuration' }],
			},
			{
				path: '/buyer/admin/impersonation',
				exact: true,
				component: ImpersonationComponent,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPER_ADMIN_ONLY,
				childRoutes: [],
				breadcrumbs: [{ title: 'Admin', to: '/buyer/admin' }, { title: 'Impersonate a User' }],
			},
		],
		breadcrumbs: [{ title: 'Admin' }],
	},
	{
		path: '/buyer/approvalHierarchies',
		exact: false,
		component: ApprovalHierarchyPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ADMIN_ONLY,
		childRoutes: [
			{
				path: '/buyer/approvalHierarchies/detail/templates/:id',
				exact: false,
				component: ApprovalHierarchyDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ADMIN_ONLY,
				childRoutes: [
					{
						path: '/buyer/approvalHierarchies/detail/templates/:id/details',
						exact: true,
						component: BuyerAdminApprovalPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ADMIN_ONLY,
						childRoutes: [],
						breadcrumbs: [
							{ title: 'Admin', to: '/buyer/admin' },
							{ title: 'Quote Approval Configuration' },
						],
					},
					{
						path: '/buyer/approvalHierarchies/detail/templates/new',
						exact: true,
						component: BuyerAdminApprovalPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ADMIN_ONLY,
						childRoutes: [],
						breadcrumbs: [
							{ title: 'Admin', to: '/buyer/admin' },
							{ title: 'Quote Approval Configuration' },
						],
					},
				],
			},
			{
				path: '/buyer/approvalHierarchies/overview',
				exact: false,
				component: ApprovalHierarchyOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.BUYER_ADMIN_ONLY,
				childRoutes: [
					{
						path: '/buyer/approvalHierarchies/overview/rules/:type',
						exact: false,
						component: ApprovalRulesIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ADMIN_ONLY,
						childRoutes: [],
					},
					{
						path: '/buyer/approvalHierarchies/overview/templates',
						exact: false,
						component: ApprovalHierarchyIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.BUYER_ADMIN_ONLY,
						childRoutes: [],
					},
				],
			},
		],
		breadcrumbs: [
			{ title: 'Admin', to: '/buyer/admin' },
			{ title: 'Quote Approval Configuration' },
		],
	},

	// NEW Facility

	{
		path: '/buyer/newFacilitySetup',
		exact: false,
		component: NewFacilitiesBuyer,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
		childRoutes: [],
		breadcrumbs: [{ title: 'New Facility' }],
	},
	{
		path: '/supplier/newFacilitySetup',
		exact: false,
		component: NewFacilitiesSupplier,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [],
		breadcrumbs: [{ title: 'New Facility' }],
	},

	/**
	 * Supplier
	 */
	{
		path: '/supplier/newAccountSetup',
		exact: false,
		component: SupplierNewAccountSetupPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/newAccountSetup/association',
				exact: true,
				component: SupplierNewAccountSetupPostSignUpPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [{ title: 'New Account Setup' }],
			},
			{
				path: '/supplier/newAccountSetup/profile',
				exact: true,
				component: SupplierNewAccountSetupProfilePage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [{ title: 'New Account Setup' }],
			},
			{
				path: '/supplier/newAccountSetup/completion',
				exact: true,
				component: SupplierNewAccountSetupCompletionPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [{ title: 'New Account Setup' }],
			},
		],
		breadcrumbs: [{ title: 'New Account Setup' }],
	},
	{
		path: '/supplier/referral',
		exact: true,
		component: SupplierReferralsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [],
	},
	{
		path: '/supplier/myProfile',
		exact: true,
		component: MySupplierProfilePage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [],
	},
	{
		path: '/supplier/myProfile/edit',
		exact: true,
		component: MySupplierEditProfilePage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [],
	},
	{
		path: '/supplier/projects',
		exact: false,
		component: SupplierProjectsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/projects/detail/:id',
				exact: false,
				component: ProjectsDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/projects/detail/:id/details/:action?',
						exact: true,
						component: ProjectsDetailOverviewPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/projects/detail/:id/workOrders',
						exact: true,
						component: ProjectsWorkOrdersPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/projects/detail/:id/invoices',
						exact: true,
						component: ProjectInvoicesPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/projects/edit/:id',
				exact: true,
				component: ProjectEditPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
			},
			{
				path: '/supplier/projects/newProject',
				exact: true,
				component: ProjectNewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
			},
			{
				path: '/supplier/projects/overview',
				exact: false,
				component: ProjectsOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/projects/overview/active',
						exact: true,
						component: ProjectsIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/projects/overview/archived',
						exact: true,
						component: ProjectsIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/projects/overview/templates/new',
						exact: true,
						component: NewProjectTemplatePage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/projects/overview/templates/overview',
						exact: true,
						component: ProjectTemplatesIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
				breadcrumbs: [],
			},
			{
				path: '/supplier/projects/templates/detail/:id',
				exact: false,
				component: ProjectTemplatesDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/projects/templates/detail/:id/details',
						exact: true,
						component: ProjectTemplatesDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/projects/templates/edit/:id',
				exact: false,
				component: EditProjectTemplatePage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
			},
		],
	},
	{
		path: '/supplier/dashboards',
		exact: false,
		component: SupplierAttentionDashboardPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/dashboards/overview',
				exact: false,
				component: SupplierAttentionDashboardOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/dashboards/overview/all',
						exact: true,
						component: SupplierAttentionDashboardIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
				breadcrumbs: [],
			},
		],
	},
	{
		path: '/supplier/workOrders',
		exact: false,
		component: SupplierWorkOrdersPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/workOrders/detail/:id',
				exact: false,
				component: SupplierWorkOrdersDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/workOrders/detail/:id/details/:action?',
						exact: true,
						component: SupplierWorkOrdersDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/workOrders/detail/:id/quotes',
						exact: true,
						component: SupplierWorkOrdersDetailQuotesPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/workOrders/detail/:id/quotes/createSimple',
						exact: true,
						component: NewSupplierSimpleQuotePage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/workOrders/detail/:id/quotes/uploadPDF',
						exact: true,
						component: SupplierUploadQuotePDFPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/workOrders/detail/:id/invoices',
						exact: true,
						component: SupplierWorkOrdersDetailInvoicesPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/workOrders/detail/:id/invoices/new',
						exact: true,
						component: NewSupplierInvoicePage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/workOrders/detail/:id/invoices/newForSupplier',
						exact: true,
						component: NewBuyerInvoicePage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/workOrders/detail/:workOrderId/invoices/:id/edit',
						exact: true,
						component: EditSupplierInvoicePage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/workOrders/detail/:id/invoices/createSimple',
						exact: true,
						component: NewSupplierSimpleInvoicePage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/workOrders/detail/:id/invoices/uploadPDF',
						exact: true,
						component: SupplierUploadInvoicePDFPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/workOrders/detail/:id/charges',
						exact: true,
						component: SupplierWorkOrdersDetailChargesPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/workOrders/detail/:id/notes',
						exact: true,
						component: SupplierWorkOrdersDetailNotesPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/workOrders/edit/:id',
				exact: true,
				component: EditSupplierWorkOrderPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
			},
			{
				path: '/supplier/workOrders/approve/:id',
				exact: true,
				component: SupplierApproveWorkOrderPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
			},
			{
				path: '/supplier/workOrders/editSafe/:id',
				exact: true,
				component: EditSafeSupplierWorkOrderPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
			},
			{
				path: '/supplier/workOrders/newWorkOrder',
				exact: false,
				component: SupplierNewWorkOrderWizardPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
			},
			{
				path: '/supplier/workOrders/overview',
				exact: false,
				component: SupplierWorkOrdersOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/workOrders/overview/all',
						exact: true,
						component: SupplierWorkOrdersIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/workOrders/overview/active',
						exact: true,
						component: SupplierWorkOrdersActiveIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/workOrders/overview/scheduled',
						exact: true,
						component: SupplierServiceCallCalendarPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/workOrders/overview/archived',
						exact: true,
						component: SupplierWorkOrdersArchivedIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/workOrders/overview/new',
						exact: true,
						component: SupplierNewWorkOrderPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/workOrders/overview/newWorkOrder',
						exact: false,
						component: SupplierNewWorkOrderWizardPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
				],
				breadcrumbs: [],
			},
			{
				path: '/supplier/workOrders/detail/:id/newProposal',
				exact: true,
				component: NewWorkOrderProposalPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [{ title: 'Work Order', to: '/supplier/workOrders' }],
			},
			{
				path: '/supplier/workOrders/detail/:id/editProposal/:proposalId',
				exact: true,
				component: EditWorkOrderProposalPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [{ title: 'Work Order', to: '/supplier/workOrders' }],
			},
			{
				path: '/supplier/workOrders/savedViews',
				exact: false,
				component: SupplierWorkOrdersSavedFiltersViewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/workOrders/savedViews/overview',
						exact: false,
						component: SupplierWorkOrdersSavedFiltersViewOverviewPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [
							{
								path: '/supplier/workOrders/savedViews/overview/all',
								exact: true,
								component: SupplierWorkOrdersSavedFiltersViewIndexPage,
								authProtected: true,
								authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
								childRoutes: [],
								breadcrumbs: [],
							},
						],
						breadcrumbs: [],
					},
				],
			},
		],
	},
	{
		path: '/supplier/siteSurveys',
		exact: false,
		component: SupplierSiteSurveysPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/siteSurveys/overview',
				exact: false,
				component: SupplierSiteSurveysOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/siteSurveys/overview/all',
						exact: true,
						component: SupplierSiteSurveysIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Site Surveys' }],
					},
					{
						path: '/supplier/siteSurveys/overview/new',
						exact: true,
						component: NewSupplierSiteSurveyPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/siteSurveys/overview/templates/new',
						exact: true,
						component: NewSupplierSiteSurveyTemplatePage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/siteSurveys/overview/templates/overview',
						exact: true,
						component: SupplierSiteSurveyTemplatesIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/siteSurveys/detail/:id',
				exact: false,
				component: SupplierSiteSurveysDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/siteSurveys/detail/:id/details',
						exact: true,
						component: SupplierSiteSurveysDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/siteSurveys/detail/:id/walkthroughs',
						exact: true,
						component: SiteSurveyWalkthroughsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/siteSurveys/templates/detail/:id',
				exact: false,
				component: SupplierSiteSurveyTemplatesDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/siteSurveys/templates/detail/:id/details',
						exact: true,
						component: SupplierSiteSurveyTemplatesDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			// {
			//     path: '/supplier/siteSurveys/templates/edit/:id',
			//     exact: true,
			//     component: EditLocationTypePage,
			//     authProtected: true,
			//     authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
			//     childRoutes: []
			// },
			{
				path: '/supplier/siteSurveys/walkthrough/detail/:id',
				exact: false,
				component: SupplierSiteSurveysWalkthroughDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/siteSurveys/walkthrough/detail/:id/details',
						exact: true,
						component: SupplierWalkthroughDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
		],
	},
	{
		path: '/supplier/requestsForProposal',
		exact: false,
		component: SupplierRFPPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/requestsForProposal/overview',
				exact: false,
				component: SupplierRFPOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/requestsForProposal/overview/all',
						exact: true,
						component: SupplierRFPIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Request For Proposal' }],
					},
					{
						path: '/supplier/requestsForProposal/overview/new',
						exact: false,
						component: NewRequestForProposalPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [
							{ title: 'Request For Proposal', to: '/supplier/requestsForProposal' },
							{ title: 'New' },
						],
					},
				],
				breadcrumbs: [{ title: 'Request For Proposal' }],
			},
			{
				path: '/supplier/requestsForProposal/detail/:id',
				exact: false,
				component: RFPDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [
					{ title: 'Request For Proposal', to: '/supplier/requestsForProposal' },
					{ title: 'Event Details' },
				],
			},
			{
				path: '/supplier/requestsForProposal/detail/:id/details',
				exact: true,
				component: InternalSupplierRFPDetailDetailsPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [
					{ title: 'Request For Proposal', to: '/supplier/requestsForProposal' },
					{ title: 'Event Details' },
				],
			},
			{
				path: '/supplier/requestsForProposal/detail/:id/compareBids',
				exact: true,
				component: RFPDetailBidsPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [
					{ title: 'Request For Proposal', to: '/supplier/requestsForProposal' },
					{ title: 'Compare Bids' },
				],
			},
			{
				path: '/supplier/requestsForProposal/edit/:id',
				exact: true,
				component: EditRequestForProposalPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [
					{ title: 'Request For Proposal', to: '/supplier/requestsForProposal' },
					{ title: 'Event Details' },
				],
			},
		],
	},
	{
		path: '/supplier/plannedMaintenance',
		exact: false,
		component: PlannedMaintenancePage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/plannedMaintenance/templates/detail/:id',
				exact: false,
				component: PlannedMaintenanceTemplatesDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/plannedMaintenance/templates/detail/:id/details',
						exact: true,
						component: PlannedMaintenanceTemplatesDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/plannedMaintenance/templates/edit/:id',
				exact: true,
				component: EditPlannedMaintenanceTemplatePage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
			},
			{
				path: '/supplier/plannedMaintenance/edit/:id',
				exact: true,
				component: EditSpecificPlannedMaintenanceSchedulePage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
			},
			{
				path: '/supplier/plannedMaintenance/:title/:frequency/:plannedMaintenanceTemplateId/:basedOn/new',
				exact: true,
				component: SpecificPlannedMaintenanceSchedulePage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
			},
			{
				path: '/supplier/plannedMaintenance/detail/:title/:frequency/:plannedMaintenanceTemplateId/:basedOn',
				exact: false,
				component: PlannedMaintenanceSchedulesDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/plannedMaintenance/detail/:title/:frequency/:plannedMaintenanceTemplateId/:basedOn/details',
						exact: true,
						component: PlannedMaintenanceSchedulesDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/plannedMaintenance/overview',
				exact: false,
				component: PlannedMaintenanceSchedulesOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/plannedMaintenance/overview/all',
						exact: true,
						component: PlannedMaintenanceSchedulesIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/plannedMaintenance/overview/templates/overview',
						exact: true,
						component: PlannedMaintenanceTemplatesIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/plannedMaintenance/new',
				exact: true,
				component: NewPlannedMaintenanceSchedulePage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
			},
			{
				path: '/supplier/plannedMaintenance/edit/:title/:frequency/:plannedMaintenanceTemplateId/:basedOn',
				exact: true,
				component: EditPlannedMaintenanceSchedulePage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
			},
			{
				path: '/supplier/plannedMaintenance/templates/new',
				exact: true,
				component: NewPlannedMaintenanceTemplatePage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
			},
		],
	},
	{
		path: '/supplier/walkthroughs',
		exact: false,
		component: SupplierWalkthroughPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/walkthroughs/overview',
				exact: false,
				component: SupplierWalkthroughOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/walkthroughs/overview/all',
						exact: true,
						component: SupplierWalkthroughIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Walkthroughs' }],
					},
				],
				breadcrumbs: [{ title: 'Walkthroughs' }],
			},
			{
				path: '/supplier/walkthroughs/detail/:id',
				exact: false,
				component: SupplierWalkthroughDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [
					{ title: 'Walkthrough', to: '/supplier/walkthroughs' },
					{ title: 'Event Details' },
				],
			},
			{
				path: '/supplier/walkthroughs/detail/:id/details',
				exact: true,
				component: SupplierWalkthroughDetailDetailsPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [
					{ title: 'Walkthrough', to: '/supplier/walkthroughs' },
					{ title: 'Event Details' },
				],
			},
		],
	},
	{
		path: '/supplier/opportunities',
		exact: false,
		component: SupplierRequestsForProposalPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/opportunities/overview',
				exact: false,
				component: SupplierRequestsForProposalOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/opportunities/overview/all',
						exact: true,
						component: SupplierRequestsForProposalIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Opportunities' }],
					},
				],
				breadcrumbs: [{ title: 'Opportunities' }],
			},
			{
				path: '/supplier/opportunities/detail/:id',
				exact: false,
				component: SupplierRFPDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [
					{ title: 'Request For Proposal', to: '/supplier/opportunities' },
					{ title: 'Event Details' },
				],
			},
			{
				path: '/supplier/opportunities/detail/:id/details',
				exact: true,
				component: SupplierRFPDetailDetailsPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [
					{ title: 'Request For Proposal', to: '/supplier/opportunities' },
					{ title: 'Event Details' },
				],
			},
			{
				path: '/supplier/opportunities/detail/:id/newProposal',
				exact: true,
				component: NewProposalPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [
					{ title: 'Request For Proposal', to: '/supplier/opportunities' },
					{ title: 'Compare Bids' },
				],
			},
			{
				path: '/supplier/opportunities/editProposal/:id',
				exact: true,
				component: EditProposalPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [
					{ title: 'Request For Proposal', to: '/supplier/opportunities' },
					{ title: 'Event Details' },
				],
			},
		],
	},
	{
		path: '/supplier/locations',
		exact: false,
		component: SupplierLocationsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/locations/detail/:id',
				exact: false,
				component: SupplierLocationsDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/locations/detail/:id/details',
						exact: true,
						component: SupplierLocationsDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/locations/detail/:id/audit',
						exact: true,
						component: LocationAudit,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/locations/edit/:id',
				exact: true,
				component: EditLocationPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
			},
			{
				path: '/supplier/locations/templates/detail/:id',
				exact: false,
				component: LocationTypesDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/locations/templates/detail/:id/details',
						exact: true,
						component: LocationTypesDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/locations/templates/edit/:id',
				exact: true,
				component: EditLocationTypePage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
			},
			{
				path: '/supplier/locations/overview',
				exact: false,
				component: LocationsOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/locations/overview/all',
						exact: true,
						component: SupplierLocationsIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/locations/overview/new',
						exact: true,
						component: NewLocationPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/locations/overview/templates/new',
						exact: true,
						component: NewLocationTypePage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/locations/overview/templates/overview',
						exact: true,
						component: LocationTypesIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/locations/overview/regions/overview',
						exact: true,
						component: RegionsOverviewIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/locations/overview/brands/overview',
						exact: true,
						component: BrandsOverviewIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
				],
				breadcrumbs: [],
			},
		],
	},
	{
		path: '/supplier/assets',
		exact: false,
		component: SupplierAssetsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/assets/detail/:id',
				exact: false,
				component: SupplierAssetsDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/assets/detail/:id/details',
						exact: true,
						component: SupplierAssetsDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/assets/detail/:id/depreciationModels',
						exact: true,
						component: AssetDepreciationModelsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/assets/detail/:id/depreciationJournal',
						exact: true,
						component: SupplierAssetDepreciationJournalPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/assets/detail/:id/downtimeHistory',
						exact: true,
						component: AssetDowntimeHistoryIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/assets/detail/:id/manuals',
						exact: true,
						component: SupplierAssetsDetailManualsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/assets/detail/:id/history',
						exact: true,
						component: AssetHistoryDetailPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/assets/detail/:id/audit',
						exact: true,
						component: AssetAudit,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					// {
					//     path: '/buyer/assets/detail/:assetId/warranties/:warrantyId/edit',
					//     exact: true,
					//     component: EditWarrantyPage,
					//     authProtected: true,
					//     authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
					//     childRoutes: []
					// },
				],
			},
			{
				path: '/supplier/assets/:id/newWorkOrder',
				exact: false,
				component: SupplierAssetNewWorkOrderWizardPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
			},
			{
				path: '/supplier/assets/edit/:id',
				exact: true,
				component: SupplierEditAssetPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
			},
			{
				path: '/supplier/assets/templates/detail/:id',
				exact: false,
				component: AssetTemplatesDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/assets/templates/detail/:id/details',
						exact: true,
						component: AssetTemplatesDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/assets/templates/edit/:id',
				exact: true,
				component: EditAssetTemplatePage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
			},
			{
				path: '/supplier/assets/models/detail/:id',
				exact: false,
				component: SupplierAssetModelsDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/assets/models/detail/:id/details',
						exact: true,
						component: SupplierAssetModelsDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/assets/overview',
				exact: false,
				component: AssetsOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/assets/overview/all',
						exact: true,
						component: SupplierAssetsIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/assets/overview/new',
						exact: true,
						component: SupplierNewAssetPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/assets/overview/templates/overview',
						exact: true,
						component: AssetTemplatesIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/assets/overview/templates/new',
						exact: true,
						component: NewAssetTypePage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/assets/overview/downtime',
						exact: true,
						component: AssetDowntimeTable,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
				],
				breadcrumbs: [],
			},
		],
	},
	{
		path: '/supplier/depreciation',
		exact: false,
		component: DepreciationPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/depreciation/overview',
				exact: false,
				component: DepreciationOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/depreciation/overview/depreciationClass',
						exact: true,
						component: DepreciationClassIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/depreciation/overview/impairment',
						exact: true,
						component: ImpairmentAdjustmentIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/depreciation/overview/burdenRates',
						exact: false,
						component: DepreciationBurdenRatesIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/depreciation/overview/taxRates',
						exact: false,
						component: AccountingTaxRatePage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/depreciation/overview/journalRuns',
						exact: true,
						component: DepreciationJournalRunsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/depreciation/overview/exchangeRates',
						exact: true,
						component: SupplierExchangeRatesPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/depreciation/depreciationClass/detail/:id',
				exact: false,
				component: DepreciationClassDetailsPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/depreciation/depreciationClass/detail/:id/details',
						exact: true,
						component: DepreciationClassDetailDetailPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
		],
	},
	{
		path: '/supplier/analytics/fieldTechAnalytics/detail/:email',
		exact: true,
		component: SupplierFieldTechsAnalyticsDetailPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [],
	},
	{
		path: '/supplier/analytics/fieldTechAnalytics',
		exact: false,
		component: SupplierAnalyticsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/analytics/fieldTechAnalytics/overview',
				exact: false,
				component: SupplierFieldTechsAnalyticsPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/analytics/fieldTechAnalytics/overview/detail',
						exact: true,
						component: SupplierFieldTechsAnalyticsOverviewPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
				],
			},
		],
	},
	{
		path: '/supplier/analytics/assets',
		exact: false,
		component: AssetsAnalyticsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/analytics/assets/overview',
				exact: false,
				component: DowntimeAnalyticsOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/analytics/assets/overview/current',
						exact: true,
						component: DowntimeAnalyticsOverviewCurrentPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
					{
						path: '/supplier/analytics/assets/overview/locations',
						exact: true,
						component: DowntimeAnalyticsOverviewLocationsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
					{
						path: '/supplier/analytics/assets/overview/problemTypes',
						exact: true,
						component: ComingSoon,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
					{
						path: '/supplier/analytics/assets/overview/manufacturers',
						exact: true,
						component: DowntimeAnalyticsOverviewManufacturersPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
					{
						path: '/supplier/analytics/assets/overview/assetTypes',
						exact: true,
						component: DowntimeAnalyticsOverviewAssetTypesPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
				],
			},
		],
	},
	{
		path: '/supplier/analytics/work',
		exact: false,
		component: WorkAnalyticsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/analytics/work/overview',
				exact: false,
				component: WorkAnalyticsOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/analytics/work/overview/priorities',
						exact: true,
						component: WorkAnalyticsOverviewPrioritiesPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
					{
						path: '/supplier/analytics/work/overview/vendors',
						exact: true,
						component: WorkAnalyticsOverviewVendorsPage2,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
					{
						path: '/supplier/analytics/work/overview/problemTypes',
						exact: true,
						component: WorkAnalyticsOverviewProblemTypesPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
					{
						path: '/supplier/analytics/work/overview/manufacturers',
						exact: true,
						component: WorkAnalyticsOverviewManufacturersPage2,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
					{
						path: '/supplier/analytics/work/overview/assetTypes',
						exact: true,
						component: WorkAnalyticsOverviewAssetTypesPage2,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
					{
						path: '/supplier/analytics/work/overview/locations',
						exact: true,
						component: WorkAnalyticsOverviewLocationsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
				],
			},
		],
	},
	{
		path: '/supplier/analytics/kpi',
		exact: false,
		component: KPIAnalyticsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/analytics/kpi/overview',
				exact: false,
				component: KPIAnalyticsOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/analytics/kpi/overview/vendors',
						exact: true,
						component: KPIAnalyticsOverviewVendorsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
				],
				breadcrumbs: [{ title: 'Analytics' }],
			},
			{
				path: '/supplier/analytics/kpi/vendors/detail/:id',
				exact: true,
				component: KPIAnalyticsVendorDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [{ title: 'Analytics' }],
			},
		],
	},
	{
		path: '/supplier/analytics/spend',
		exact: false,
		component: SpendAnalyticsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/analytics/spend/overview',
				exact: false,
				component: SpendAnalyticsOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/analytics/spend/overview/categories',
						exact: true,
						component: SpendAnalyticsOverviewCategoriesPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
					{
						path: '/supplier/analytics/spend/overview/problemTypes',
						exact: true,
						component: SpendAnalyticsOverviewProblemTypesPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
					{
						path: '/supplier/analytics/spend/overview/vendors',
						exact: true,
						component: SpendAnalyticsOverviewVendorsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
					{
						path: '/supplier/analytics/spend/overview/locations',
						exact: true,
						component: SpendAnalyticsOverviewLocationsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [{ title: 'Analytics' }],
					},
				],
				breadcrumbs: [{ title: 'Analytics' }],
			},
		],
	},
	{
		path: '/supplier/fieldTechs',
		exact: false,
		component: FieldTechsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/fieldTechs/overview',
				exact: false,
				component: FieldTechsOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/fieldTechs/overview/all',
						exact: true,
						component: FieldTechsIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/fieldTechs/overview/new',
						exact: true,
						component: NewFieldTechPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
				],
			},
			{
				path: '/supplier/fieldTechs/detail/:id',
				exact: false,
				component: FieldTechsDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/fieldTechs/detail/:id/details',
						exact: true,
						component: FieldTechsDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/fieldTechs/edit/:id',
				exact: true,
				component: EditFieldTechPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [],
			},
		],
	},
	{
		path: '/supplier/meters',
		exact: false,
		component: SupplierMetersPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/meters/detail/:id',
				exact: false,
				component: SupplierMetersDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/meters/detail/:meterId/details',
						exact: true,
						component: SupplierMetersDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/meters/edit/:id',
				exact: true,
				component: SupplierEditMeterPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
			},
			// {
			//     path: '/supplier/meters/types/detail/:id',
			//     exact: false,
			//     component: MeterTypesDetailPage,
			//     authProtected: true,
			//     authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
			//     childRoutes: [
			//         {
			//             path: '/supplier/meters/types/detail/:id/details',
			//             exact: true,
			//             component: MeterTypesDetailDetailsPage,
			//             authProtected: true,
			//             authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
			//             childRoutes: [],
			//             breadcrumbs: []
			//         },
			//     ]
			// },
			{
				path: '/supplier/meters/overview',
				exact: false,
				component: SupplierMetersOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/meters/overview/all',
						exact: true,
						component: SupplierMetersIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					// {
					//     path: '/supplier/meters/overview/types/new',
					//     exact: true,
					//     component: SupplierNewMeterTypePage,
					//     authProtected: true,
					//     authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
					//     childRoutes: []
					// },
					// {
					//     path: '/supplier/meters/overview/types/overview',
					//     exact: true,
					//     component: SupplierMeterTypesIndexPage,
					//     authProtected: true,
					//     authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
					//     childRoutes: [],
					//     breadcrumbs: []
					// },
					// {
					//     path: '/supplier/meters/overview/new',
					//     exact: true,
					//     component: SupplierNewMeterPage,
					//     authProtected: true,
					//     authorizedRoles: authorizedRolesCombinations.BUYER_ONLY,
					//     childRoutes: []
					// },
				],
				breadcrumbs: [],
			},
		],
	},
	{
		path: '/supplier/equipments',
		exact: false,
		component: EquipmentsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/equipments/overview',
				exact: false,
				component: EquipmentsOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/equipments/overview/all',
						exact: true,
						component: EquipmentsIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/equipments/overview/templates',
						exact: true,
						component: EquipmentsTemplateIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/equipments/overview/priceList',
						exact: true,
						component: EquipmentsPriceListIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/equipments/overview/new',
						exact: true,
						component: NewEquipmentPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
				],
			},
			{
				path: '/supplier/equipments/equipmentPerStockLocation/detail/:id',
				exact: false,
				component: EquipmentPerStockLocationDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/equipments/equipmentPerStockLocation/detail/:id/details',
						exact: true,
						component: EquipmentPerStockLocationDetailsDetailPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/equipments/equipmentPerStockLocation/detail/:id/workOrders',
						exact: true,
						component: EquipmentPerStockLocationWorkOrdersPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/equipments/equipmentCatalogs/detail/:id',
				exact: false,
				component: EquipmentCatalogDetailsPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/equipments/equipmentCatalogs/detail/:id/details',
						exact: true,
						component: EquipmentCatalogDetailsDetailPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/equipments/equipmentCatalogs/detail/:id/purchaseOrders',
						exact: true,
						component: EquipmentCatalogPurchaseOrdersPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/equipments/detail/:id',
				exact: false,
				component: EquipmentsDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/equipments/detail/:id/details',
						exact: true,
						component: EquipmentsDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/equipments/detail/:id/prices',
						exact: true,
						component: EquipmentDetailPricesPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/equipments/detail/:id/purchaseRequests',
						exact: true,
						component: EquipmentPurchaseRequestsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/equipments/detail/:id/purchaseOrders',
						exact: true,
						component: EquipmentPurchaseOrdersPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/equipments/detail/:id/workOrders',
						exact: true,
						component: EquipmentWorkOrdersPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/equipments/edit/:id',
				exact: true,
				component: EditEquipmentPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [],
			},
		],
	},
	{
		path: '/supplier/parts',
		exact: false,
		component: PartsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/parts/overview',
				exact: false,
				component: PartsOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/parts/overview/all',
						exact: true,
						component: PartsIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/parts/overview/priceList',
						exact: true,
						component: PartsPriceListIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/parts/overview/new',
						exact: true,
						component: NewPartPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
				],
			},
			{
				path: '/supplier/parts/partCatalogs/detail/:id',
				exact: false,
				component: PartCatalogDetailsPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/parts/partCatalogs/detail/:id/details',
						exact: true,
						component: PartCatalogDetailsDetailPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/parts/partCatalogs/detail/:id/purchaseOrders',
						exact: true,
						component: PartCatalogPurchaseOrdersPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/parts/detail/:id',
				exact: false,
				component: PartsDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/parts/detail/:id/details',
						exact: true,
						component: PartsDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/parts/detail/:id/prices',
						exact: true,
						component: PartsDetailPricesPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/parts/detail/:id/purchaseRequests',
						exact: true,
						component: PartsPurchaseRequestsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/parts/detail/:id/purchaseOrders',
						exact: true,
						component: PartsPurchaseOrdersList,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/parts/detail/:id/workOrders',
						exact: true,
						component: PartWorkOrdersListPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/parts/edit/:id',
				exact: true,
				component: EditPartPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [],
			},
			{
				path: '/supplier/parts/partsPerStockLocation/detail/:id',
				exact: false,
				component: PartsPerStockLocationDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/parts/partsPerStockLocation/detail/:id/details',
						exact: true,
						component: PartsPerStockLocationDetailsDetailPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/parts/partsPerStockLocation/detail/:id/workOrders',
						exact: true,
						component: PartsPerStockLocationWorkOrdersPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/parts/partsPerStockLocation/edit/:id',
				exact: true,
				component: EditPartsPerStockLocationPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [],
			},
		],
	},
	{
		path: '/supplier/stockLocations',
		exact: false,
		component: StockLocationsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/stockLocations/overview',
				exact: false,
				component: StockLocationsOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/stockLocations/overview/all',
						exact: true,
						component: StockLocationsIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/stockLocations/overview/new',
						exact: true,
						component: NewStockLocationPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
				],
			},
			{
				path: '/supplier/stockLocations/printPartsPerStockLocationLabels/:id/',
				exact: true,
				component: PrintPartsPerStockLocationLabels,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [],
			},
			{
				path: '/supplier/stockLocations/printEquipmentLabels/:id/',
				exact: true,
				component: PrintEquipmentLabels,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [],
			},
			{
				path: '/supplier/stockLocations/detail/:id',
				exact: false,
				component: StockLocationsDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/stockLocations/detail/:id/parts/new',
						exact: true,
						component: NewPartsPerStockLocationPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/stockLocations/detail/:id/parts',
						exact: true,
						component: StockLocationsPartsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/stockLocations/detail/:id/equipment',
						exact: true,
						component: StockLocationsEquipmentLevelPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/stockLocations/detail/:id/receivedEquipment',
						exact: true,
						component: StockLocationEquipmentsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/stockLocations/detail/:id/disbursedEquipments',
						exact: true,
						component: StockLocationDisbursedEquipmentsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/stockLocations/detail/:id/shipments',
						exact: true,
						component: StockLocationShipmentsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/stockLocations/detail/:id/shipped',
						exact: true,
						component: StockLocationShippedPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/stockLocations/detail/:id/backorders',
						exact: true,
						component: StockLocationBackordersPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/stockLocations/edit/:id',
				exact: true,
				component: EditStockLocationPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [],
			},
		],
	},
	{
		path: '/supplier/invoices',
		exact: false,
		component: SupplierInvoicesPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/invoices/overview',
				exact: false,
				component: SupplierInvoicesOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/invoices/overview/all',
						exact: true,
						component: SupplierInvoicesIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/invoices/overview/new',
						exact: true,
						component: NewSupplierInvoicePage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
				],
			},
			{
				path: '/supplier/invoices/detail/:id',
				exact: false,
				component: SupplierInvoicesDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/invoices/detail/:id/details',
						exact: true,
						component: SupplierInvoicesDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/invoices/edit/:id',
				exact: true,
				component: EditSupplierInvoicePage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [],
			},
			{
				path: '/supplier/invoices/savedViews',
				exact: false,
				component: SupplierInvoicesSavedFiltersViewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/invoices/savedViews/overview',
						exact: false,
						component: SupplierInvoicesSavedFiltersViewOverviewPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [
							{
								path: '/supplier/invoices/savedViews/overview/all',
								exact: true,
								component: SupplierInvoicesSavedFiltersViewIndexPage,
								authProtected: true,
								authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
								childRoutes: [],
								breadcrumbs: [],
							},
						],
						breadcrumbs: [],
					},
				],
			},
		],
	},
	{
		path: '/supplier/quotes',
		exact: false,
		component: SupplierQuotesPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/quotes/overview',
				exact: false,
				component: SupplierQuotesOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/quotes/overview/all',
						exact: true,
						component: SupplierQuotesIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
					{
						path: '/supplier/quotes/overview/new',
						exact: true,
						component: NewSupplierQuotePage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
				],
			},
			{
				path: '/supplier/quotes/detail/:id',
				exact: false,
				component: SupplierQuotesDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/quotes/detail/:id/details',
						exact: true,
						component: SupplierQuotesDetailDetailsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
			},
			{
				path: '/supplier/quotes/edit/:id',
				exact: true,
				component: EditQuotePage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [],
			},
		],
	},

	{
		path: '/supplier/inbox',
		exact: false,
		component: SupplierNotificationsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/inbox/overview',
				exact: false,
				component: SupplierNotificationsOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/inbox/overview/important',
						exact: true,
						component: NotificationsTasks,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/inbox/overview/approvals',
						exact: true,
						component: NotificationsApprovals,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/inbox/overview/notes',
						exact: true,
						component: NotificationsNotes,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/inbox/overview/status',
						exact: true,
						component: NotificationsStatusUpdates,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
				breadcrumbs: [],
			},
		],
	},
	{
		path: '/supplier/clients',
		exact: false,
		component: SupplierClientsPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/clients/overview',
				exact: false,
				component: SupplierClientsOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/clients/overview/all',
						exact: true,
						component: SupplierClientsIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
					},
				],
			},
		],
	},
	{
		path: '/supplier/company/:id',
		exact: true,
		component: SupplierSuppliersDetailPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [],
		breadcrumbs: [{ title: 'Company Detail' }],
	},
	{
		path: '/supplier/suppliers',
		exact: false,
		component: SuppliersPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
		childRoutes: [
			{
				path: '/supplier/suppliers/overview',
				exact: false,
				component: SuppliersOverviewPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [
					{
						path: '/supplier/suppliers/overview/wrenchhub',
						exact: true,
						component: BuyerWrenchHubPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/suppliers/overview/privateNetwork',
						exact: true,
						component: PrivateNetworkIndexPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
					{
						path: '/supplier/suppliers/overview/locations',
						exact: true,
						component: PrivateNetworkLocationsPage,
						authProtected: true,
						authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
						childRoutes: [],
						breadcrumbs: [],
					},
				],
				breadcrumbs: [],
			},
			{
				path: '/supplier/suppliers/detail/:id',
				exact: true,
				component: BuyerSuppliersDetailPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ONLY,
				childRoutes: [],
				breadcrumbs: [],
			},
		],
	},
	{
		path: '/supplier/users',
		exact: true,
		component: SupplierAdminUsersPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ADMIN_ONLY,
		childRoutes: [],
		breadcrumbs: [{ title: 'Admin', to: '/supplier/admin' }, { title: 'Users' }],
	},
	{
		path: '/supplier/admin',
		exact: false,
		component: SupplierAdminPage,
		authProtected: true,
		authorizedRoles: authorizedRolesCombinations.SUPPLIER_ADMIN_ONLY,
		childRoutes: [
			{
				path: '/supplier/admin/users',
				exact: true,
				component: SupplierAdminUsersPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ADMIN_ONLY,
				childRoutes: [],
				breadcrumbs: [{ title: 'Admin', to: '/supplier/admin' }, { title: 'Users' }],
			},
			{
				path: '/supplier/admin/impersonation',
				exact: true,
				component: ImpersonationComponent,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPER_ADMIN_ONLY,
				childRoutes: [],
				breadcrumbs: [{ title: 'Admin', to: '/supplier/admin' }, { title: 'Impersonate a User' }],
			},
			{
				path: '/supplier/admin/facilities/edit/:id',
				exact: true,
				component: SupplierFacilityEditPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ADMIN_ONLY,
				childRoutes: [],
				breadcrumbs: [
					{ title: 'Admin', to: '/supplier/admin' },
					{ title: 'Facilities', to: '/supplier/admin/facilities' },
					{ title: 'Edit' },
				],
			},
			{
				path: '/supplier/admin/facilities/new',
				exact: true,
				component: SupplierFacilityCreatePage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ADMIN_ONLY,
				childRoutes: [],
				breadcrumbs: [
					{ title: 'Admin', to: '/supplier/admin' },
					{ title: 'Facilities', to: '/supplier/admin/facilities' },
					{ title: 'Add' },
				],
			},
			{
				path: '/supplier/admin/companySettings',
				exact: true,
				component: SupplierInventorySettingsPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ADMIN_ONLY,
				childRoutes: [],
				breadcrumbs: [{ title: 'Inventory', to: '/supplier/admin' }, { title: 'Settings' }],
			},
			{
				path: '/supplier/admin/accessControl',
				exact: true,
				component: SupplierAdminAccessControlPage,
				authProtected: true,
				authorizedRoles: authorizedRolesCombinations.SUPPLIER_ADMIN_ONLY,
				childRoutes: [],
				breadcrumbs: [{ title: 'Access Control', to: '/supplier/admin' }, { title: 'Settings' }],
			},
		],
		breadcrumbs: [{ title: 'Admin' }],
	},
];

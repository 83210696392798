import React, { useState } from 'react';
import { pipelineFilters, SuplierPipelinefilters } from '../config/pipelineFilters/pipelineFilters';
import { PipelineFilter } from '../pipelineFilters/pipelineFilters';
import { Col, Row } from 'antd';
import RightActions from './RightActions';
import Filters from '../filters/filters';
import SaveFiltersActions from '../../saved_filter_views/SaveFiltersActions';
import SelectedFilters from '../../selected_filters_pills/SelectedFilters';
import { ROLE_TYPES, VIEW_ENTITY_TYPES } from '../../../utils/DataConstants';
import { nullSafeGet, nullSafeGetOrElse } from '../../../utils/DataAccessUtils';

export default function TableHeader({
	sorters,
	applySorting,
	applyFilters,
	filters,
	downloadCSV,
	counts,
	filterConfig,
	clearFilters,
	userType,
	downloadInvoiceFormatCSV,
	targetCollectionName,
	invoiceDownloadFormatRecords,
	applyFiltersFromView,
	enableViews,
	preAppliedFilters,
}) {
	const filterConfiguration = filterConfig || {};
	return (
		<div style={{ margin: '8px 4px', display: 'grid', rowGap: '8px' }}>
			<PipelineFilter
				items={userType === ROLE_TYPES.SUPPLIER ? SuplierPipelinefilters : pipelineFilters}
				subItems={{}}
				onChange={applyFilters}
				value={filters}
				counts={counts}
			/>
			<Row justify="space-between">
				<Col xl={18} xxl={16} style={{ display: 'flex', alignItems: 'flex-start', gap: '8px' }}>
					<Filters
						filterConfig={filterConfiguration.filters}
						applyFilters={applyFilters}
						applySorting={applySorting}
						filters={filters}
						clearFilters={clearFilters}
						targetCollectionName={targetCollectionName}
						userType={userType}
						sorters={sorters}
						preAppliedFilters={preAppliedFilters}
					/>
					{enableViews && (
						<SaveFiltersActions
							preAppliedFilters={preAppliedFilters}
							activeFIlters={filters}
							activeSorter={sorters}
							userType={userType}
							entityType={VIEW_ENTITY_TYPES.INVOICES}
							applyFiltersFromView={applyFiltersFromView}
						/>
					)}
				</Col>
				<Col
					xl={6}
					xxl={8}
					style={{
						textAlign: 'right',
						display: 'flex',
						alignItems: 'flex-start',
						justifyContent: 'flex-end',
						gap: '8px',
					}}
				>
					<RightActions
						downloadInvoiceFormatCSV={downloadInvoiceFormatCSV}
						invoiceDownloadFormatRecords={invoiceDownloadFormatRecords}
						downloadCSV={downloadCSV}
					/>
				</Col>
			</Row>

			<SelectedFilters
				updateFilters={applyFilters}
				filterValues={filters}
				filterConfig={filterConfiguration.filters}
			/>
		</div>
	);
}

import * as React from 'react';
import { BLACKS_AND_WHITES, STOPLIGHT_COLORS } from '../../utils/DataFormatterUtils';

const Color = require('color');

export default ({ status, darkMode = false, style = {}, textStyle = {}, ...props }) => {
	let statusColor;
	let statusDisplayName;
	let statusBackgroundColor;

	switch (status) {
		case 'Draft':
			statusColor = '#4a1e87';
			statusBackgroundColor = '#e8def8';
			statusDisplayName = 'Draft';
			break;
		case 'Pending':
			statusColor = STOPLIGHT_COLORS.yellowRed;
			statusDisplayName = 'Pending';
			statusBackgroundColor = 'rgb(251, 239, 229)';
			break;
		case 'Disputed':
			statusColor = STOPLIGHT_COLORS.pureRed;
			statusDisplayName = 'Disputed';
			statusBackgroundColor = '#f9e6e6';
			break;
		case 'Approved':
			statusColor = STOPLIGHT_COLORS.pureYellow;
			statusDisplayName = 'Approved';
			statusBackgroundColor = 'rgb(252, 243, 217)';
			break;
		case 'Processing':
			statusColor = STOPLIGHT_COLORS.yellowGreen;
			statusDisplayName = 'Processing';
			statusBackgroundColor = 'rgb(242, 249, 225)';
			break;
		case 'Paid':
			statusColor = STOPLIGHT_COLORS.pureGreen;
			statusDisplayName = 'Paid';
			statusBackgroundColor = 'rgb(230, 249, 231)';
			break;
		default:
			statusColor = BLACKS_AND_WHITES.blackPrimary;
			statusDisplayName = 'Unknown';
			statusBackgroundColor = '#f2f2f2';
	}

	if (darkMode) {
		statusColor = BLACKS_AND_WHITES.whiteSecondary;
	}

	return (
		<span
			{...props}
			style={{
				color: statusColor,
				background: statusBackgroundColor,
				padding: '4px 12px',
				borderRadius: 16,
				...style,
				...textStyle,
			}}
		>
			{statusDisplayName}
		</span>
	);
};

import { PURCHASE_ORDER_LINE_ITEMS_CRUD_ACTION_CREATORS } from '../actions/purchase_order_line_items_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import FetchUtils, { parseJSON } from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { logoutSuccess } from '../actions/session_actions';
import { message } from 'antd';

const fetchUtils = new FetchUtils();

//export const purchaseRequestsRestCrudThunksForBuyer = new RestCrudThunks('purchase_requests', 'purchase_request', 'purchaseRequest', 'purchaseRequests', PURCHASE_REQUESTS_CRUD_ACTION_CREATORS, null, ROLE_TYPES.BUYER);
export const purchaseOrderLineItemsRestCrudThunksForSupplier = new RestCrudThunks(
	'purchase_order_line_items',
	'purchase_order_line_item',
	'purchaseOrderLineItem',
	'purchaseOrderLineItems',
	PURCHASE_ORDER_LINE_ITEMS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'inventory/'
);

export const receiveLineItemQuantity = (lineItemId, quantity) => {
	const apiUrl = `/api/v1/supplier/inventory/purchase_order_line_items/receive_quantity/${lineItemId}/${quantity}`;
	return new Promise((resolve, reject) => {
		fetchUtils
			.patch(getBackendUri() + apiUrl, {
				headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
			})
			.then(parseJSON)
			.then((d) => {
				if (d.status === 'error') {
					reject(d.message);
				} else {
					resolve(d.json.data);
				}
			})
			.catch((d) => {
				reject(d.message);
			});
	});
};

const updateLineItemQuantity = (roleType) => (lineItemId, data) => {
	const apiUrl = `/api/v1/${roleType}/inventory/purchase_order_line_items/${lineItemId}/update_quantity`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.patch(getBackendUri() + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data,
				})
				.then(parseJSON)
				.then(({ json, status }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const updateLineItemQuantityForSupplier = updateLineItemQuantity(ROLE_TYPES.SUPPLIER);

export const replaceLineItemInPO = (entity) => {
	const apiUrl = `/api/v1/supplier/inventory/purchase_order_line_items/replace_line_item`;
	return (dispatch) => {
		dispatch(PURCHASE_ORDER_LINE_ITEMS_CRUD_ACTION_CREATORS.updateStart(entity));
		return new Promise((resolve, reject) => {
			fetchUtils
				.put(getBackendUri() + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: entity,
				})
				.then(parseJSON)
				.then(({ json, status }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
						dispatch(PURCHASE_ORDER_LINE_ITEMS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					} else {
						resolve(d.data);
						dispatch(PURCHASE_ORDER_LINE_ITEMS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
					}
				})
				.catch((d) => {
					reject(d.message);
					dispatch(PURCHASE_ORDER_LINE_ITEMS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
				});
		});
	};
};

const downloadPOLineItemsCSV =
	(roleType) =>
	(params, filters = null) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/inventory/purchase_order_line_items/csv_report_generator`;

		// if sorting present, fix order naming and add to query params

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.message);
							message.error(d.data);
						} else {
							resolve(d.data);
							const hide = message.loading(d.data, 0);
							setTimeout(hide, 7000);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};

export const downloadPOLineItemsCSVForSupplier = downloadPOLineItemsCSV(ROLE_TYPES.SUPPLIER);

const deletePOLineItem = (roleType) => (entity, id) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(PURCHASE_ORDER_LINE_ITEMS_CRUD_ACTION_CREATORS.deleteStart(entity));

			fetchUtils
				.delete(
					getBackendUri() + `/api/v1/${roleType}/inventory/purchase_order_line_items/${id}`,
					{}
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(PURCHASE_ORDER_LINE_ITEMS_CRUD_ACTION_CREATORS.deleteError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(PURCHASE_ORDER_LINE_ITEMS_CRUD_ACTION_CREATORS.deleteSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(PURCHASE_ORDER_LINE_ITEMS_CRUD_ACTION_CREATORS.deleteError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

export const deletePOLineItemForSupplier = deletePOLineItem(ROLE_TYPES.SUPPLIER);

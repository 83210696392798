import { FIELD_TECHS_CRUD_ACTION_CREATORS } from '../actions/field_techs_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import FetchUtils, { parseJSON } from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { logoutSuccess } from '../actions/session_actions';
const fetchUtils = new FetchUtils();
const baseUrl = getBackendUri();

export const fieldTechsRestCrudThunksForBuyer = new RestCrudThunks(
	'field_techs',
	'field_tech',
	'fieldTech',
	'fieldTechs',
	FIELD_TECHS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'field_tech/'
);

export const fieldTechsRestCrudThunksForSupplier = new RestCrudThunks(
	'field_techs',
	'field_tech',
	'fieldTech',
	'fieldTechs',
	FIELD_TECHS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'field_tech/'
);

export const allowFieldTechToManageStockLocations = (roleType) => (entity) => {
	const apiURL = `/api/v1/${roleType}/field_tech/field_techs/allow_field_tech_to_manage_stock_location/${entity.email}`;
	const baseUrl = getBackendUri();
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(FIELD_TECHS_CRUD_ACTION_CREATORS.updateStart(entity));
			fetchUtils
				.put(baseUrl + apiURL, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: entity,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (d.status === 'error') {
						dispatch(FIELD_TECHS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject('An error occurred in updating field tech.' || d.message);
					} else {
						dispatch(FIELD_TECHS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(FIELD_TECHS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject('An error occurred in updating field tech.' || d.message);
				});
		});
	};
};

export const allowFieldTechToManageStockLocationsForSupplier = allowFieldTechToManageStockLocations(
	ROLE_TYPES.SUPPLIER
);

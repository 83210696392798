import * as React from 'react';

import { CloseOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Row, Col, Input, Alert, Button, DatePicker } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { supplierFacilitiesRestCrudThunksForSupplier } from '../../thunks/supplier_facilities_thunks';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import moment from 'moment';
import FileUploader from '../file_uploader/FileUploader';
import { LocationAutocomplete } from '../location_autocomplete/LocationAutocomplete';

const style = require('./EditSupplierInsuranceCoverageForm.less');
const FormItem = Form.Item;

interface EditSupplierInsuranceCoverageProps {
	loading: boolean;
	insuranceCoverages: any[];
	updateSupplier: any;
	successCallback: any;
	cancelCallback: any;
	supplierFacility: any;

	errors: string[];
}

interface EditSupplierInsuranceCoverageFormProps extends FormComponentProps {
	loading: boolean;
	insuranceCoverages: any[];
	updateSupplier: any;
	successCallback: any;
	cancelCallback: any;
	supplierFacility: any;

	errors: string[];
}

const DATE_FORMAT = 'MM-DD-YYYY';
let insurancePolicyUuid = 1;
let policyDocUuid = 2;

const createRawAddress = (addr) => {
	if (!addr || addr === undefined) {
		return '';
	}
	return `${addr.streetAddress1}  ${addr.city}, ${addr.region}, ${addr.postcode}, ${addr.country}`;
};

class EditSupplierInsuranceCoverageForm extends React.Component<
	EditSupplierInsuranceCoverageFormProps,
	any
> {
	handleSubmit = (e) => {
		const { updateSupplier, successCallback, supplierFacility, form } = this.props;
		e.preventDefault();
		form.validateFields((err, values) => {
			if (!err) {
				const insuranceCoverageKeys = form.getFieldValue('insuranceCoverageKeys');
				const insurances = insuranceCoverageKeys
					.map((k) => {
						let insuranceCoverage = {
							companyName: undefined,
							companyContact: undefined,
							policyDocs: undefined,
							isVerified: false,
						};
						const companyContactAddress = Object.keys(values)
							.filter((key) => key.indexOf(`insuranceCoverages-${k}-companyContact-address`) > -1)
							.reduce((acc, key) => {
								const keys = key.split('-');
								const lastKey = keys[keys.length - 1];
								acc[lastKey] = values[key];
								return acc;
							}, {});

						const policyDocKeys = form.getFieldValue(`insuranceCoverages-${k}-policyDocKeys`);
						const policyDocs = policyDocKeys
							.map((c) => ({
								policyName: form.getFieldValue(
									`insuranceCoverages-${k}-policyDocs-${c}-policyName`
								),
								amount: parseFloat(
									form.getFieldValue(`insuranceCoverages-${k}-policyDocs-${c}-amount`)
								),
								expiration: form.getFieldValue(
									`insuranceCoverages-${k}-policyDocs-${c}-expiration`
								),
								policyFile: form.getFieldValue(
									`insuranceCoverages-${k}-policyDocs-${c}-attachment`
								),
							}))
							.filter((el) => el.policyName !== undefined);

						insuranceCoverage.companyName = form.getFieldValue(
							`insuranceCoverages-${k}-companyName`
						);
						insuranceCoverage.companyContact = {
							name: form.getFieldValue(`insuranceCoverages-${k}-companyContact-name`),
							address: companyContactAddress,
							phone: form.getFieldValue(`insuranceCoverages-${k}-companyContact-phone`),
						};
						insuranceCoverage.policyDocs = policyDocs;
						return insuranceCoverage;
					})
					.filter((el) => el.companyName !== undefined);
				const updatedSupplier = {
					...supplierFacility,
					insurances,
				};
				updateSupplier(updatedSupplier).then((resp) => successCallback(resp));
			}
		});
	};

	removeInsuranceCoverage = (k) => {
		const { form } = this.props;
		// can use data-binding to get
		const insuranceCoverageKeys = form.getFieldValue('insuranceCoverageKeys');
		// We need at least one insuranceCoverage
		if (insuranceCoverageKeys.length === 1) {
			return;
		}

		// can use data-binding to set
		form.setFieldsValue({
			insuranceCoverageKeys: insuranceCoverageKeys.filter((key) => key !== k),
		});
	};

	removeInsurancePolicyDoc = (insuranceCoverageId, policyDocId) => {
		const { form } = this.props;
		// can use data-binding to get
		const policyDocs = form.getFieldValue(
			`insuranceCoverages-${insuranceCoverageId}-policyDocKeys`
		);
		// We need at least one policyDoc for multiple policyDocs insuranceCoverages
		if (policyDocs.length === 1) {
			return;
		}

		// can use data-binding to set
		form.setFieldsValue({
			[`insuranceCoverages-${insuranceCoverageId}-policyDocKeys`]: policyDocs.filter(
				(policyDoc) => policyDoc !== policyDocId
			),
		});
	};

	addInsurancePolicyDoc = (insuranceCoverageId) => {
		const { form } = this.props;
		// can use data-binding to get
		const policyDocs = form.getFieldValue(
			`insuranceCoverages-${insuranceCoverageId}-policyDocKeys`
		);
		const nextInsurancePolicyDocs = policyDocs.concat(policyDocUuid);
		// can use data-binding to set
		// important! notify form to detect changes
		form.setFieldsValue({
			[`insuranceCoverages-${insuranceCoverageId}-policyDocKeys`]: nextInsurancePolicyDocs,
		});
		policyDocUuid++;
	};

	handleAttachmentUploadChange = (fieldName) => (newAttachments) => {
		const { form } = this.props;
		if (newAttachments.length > 0) {
			const attachment = newAttachments[0];
			form.setFieldsValue({ [fieldName]: attachment });
		} else {
			form.setFieldsValue({ [fieldName]: undefined });
		}
	};

	handleLocationAutocompleteSelect = (fieldNamePrefix) => (addr, lonLat, parsedAddress) => {
		const { form } = this.props;
		const values = form.getFieldsValue();
		form.setFieldsValue({
			[`${fieldNamePrefix}-streetAddress1`]: parsedAddress.streetAddress1,
			[`${fieldNamePrefix}-city`]: parsedAddress.city,
			[`${fieldNamePrefix}-region`]: parsedAddress.region,
			[`${fieldNamePrefix}-postcode`]: parsedAddress.postcode,
			[`${fieldNamePrefix}-country`]: parsedAddress.country,
			[`${fieldNamePrefix}-longitude`]: lonLat.lng,
			[`${fieldNamePrefix}-latitude`]: lonLat.lat,
		});
	};

	addInsuranceCoverage = () => {
		const { form } = this.props;
		// can use data-binding to get
		const insuranceCoverageKeys = form.getFieldValue('insuranceCoverageKeys');
		const nextKeys = insuranceCoverageKeys.concat(insurancePolicyUuid);
		// can use data-binding to set
		// important! notify form to detect changes
		form.setFieldsValue({
			insuranceCoverageKeys: nextKeys,
		});
		insurancePolicyUuid++;
	};

	render() {
		const { getFieldDecorator, getFieldValue } = this.props.form;
		const { loading, errors, cancelCallback, insuranceCoverages } = this.props;

		const insuranceCoverageKeysInitialValue =
			insuranceCoverages.length > 0 ? insuranceCoverages.map((el, idx) => idx) : [0];
		getFieldDecorator('insuranceCoverageKeys', { initialValue: insuranceCoverageKeysInitialValue });

		const insuranceCoverageKeys = getFieldValue('insuranceCoverageKeys');

		insuranceCoverageKeys.map((k) => {
			['streetAddress1', 'city', 'region', 'postcode', 'country', 'longitude', 'latitude'].map(
				(addrPart) => {
					getFieldDecorator(`insuranceCoverages-${k}-companyContact-address-${addrPart}`, {
						initialValue: nullSafeGet(
							`${k}.companyContact.address.${addrPart}`,
							insuranceCoverages
						),
					});
				}
			);
		});

		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
				sm: { span: 3 },
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 21 },
			},
		};
		const formItemLayoutWithOutLabel = {
			wrapperCol: {
				xs: { span: 24, offset: 0 },
				sm: { span: 21, offset: 3 },
			},
		};

		const expirationDateValidator = (rule, value, callback) => {
			const now = moment();
			if (value && value < now) {
				callback('Cannot upload expired insurance coverage.');
			}
			callback();
		};

		insuranceCoverageKeys.forEach((k) => {
			const policyDocs = nullSafeGetOrElse(`${k}.policyDocs`, insuranceCoverages, []);
			const defaultPolicyDocKeys =
				policyDocs.length > 0 ? policyDocs.map((el, idx) => idx) : [0, 1];
			getFieldDecorator(`insuranceCoverages-${k}-policyDocKeys`, {
				initialValue: nullSafeGetOrElse(
					`${k}-policyDocKeys`,
					insuranceCoverages,
					defaultPolicyDocKeys
				),
			});
		});

		const formItems = insuranceCoverageKeys.map((k, insuranceCoverageIndex) => {
			return (
				<div key={k} className="insuranceCoverages-container">
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div style={{ fontSize: '16px', color: 'black', marginBottom: '24px' }}>
							Insurance Policy {insuranceCoverageIndex + 1}
						</div>

						<div className="remove-insuranceCoverages-button">
							{insuranceCoverageKeys.length > 1 ? (
								<CloseOutlined
									className="dynamic-delete-button"
									onClick={() => this.removeInsuranceCoverage(k)}
								/>
							) : null}
						</div>
					</div>

					<h6
						style={{
							margin: 16,
							fontWeight: 400,
							color: '#6027b0',
						}}
					>
						Issuing company details
					</h6>
					<Form.Item {...formItemLayout} label="Company" required={false}>
						{getFieldDecorator(`insuranceCoverages-${k}-companyName`, {
							validateTrigger: ['onChange', 'onBlur'],
							initialValue: nullSafeGet(`${k}.companyName`, insuranceCoverages),
							rules: [
								{
									required: true,
									message: 'Please give insurance policy issuer company name.',
								},
							],
						})(<Input style={{ width: '90%' }} placeholder="" />)}
					</Form.Item>
					<Form.Item {...formItemLayout} label="Contact Name" required={false}>
						{getFieldDecorator(`insuranceCoverages-${k}-companyContact-name`, {
							validateTrigger: ['onChange', 'onBlur'],
							initialValue: nullSafeGet(`${k}.companyContact.name`, insuranceCoverages),
							rules: [
								{
									required: true,
									whitespace: true,
									message: 'Please give insurance policy issuer company email.',
								},
							],
						})(<Input style={{ width: '90%' }} />)}
					</Form.Item>
					<FormItem label="Address" {...formItemLayout}>
						<div style={{ width: '90%' }}>
							<LocationAutocomplete
								initialValue={createRawAddress(
									nullSafeGet(`${k}.companyContact.address`, insuranceCoverages)
								)}
								placeholder=""
								onSelect={this.handleLocationAutocompleteSelect(
									`insuranceCoverages-${k}-companyContact-address`
								)}
							/>
						</div>
					</FormItem>
					<Form.Item {...formItemLayout} label="Phone" required={false}>
						{getFieldDecorator(`insuranceCoverages-${k}-companyContact-phone`, {
							validateTrigger: ['onChange', 'onBlur'],
							initialValue: nullSafeGet(`${k}.companyContact.phone`, insuranceCoverages),
							rules: [
								{
									required: true,
									whitespace: true,
									message: 'Please give insurance policy issuer company phone.',
								},
							],
						})(<Input style={{ width: '90%' }} />)}
					</Form.Item>

					<h6
						style={{
							marginTop: 32,
							marginLeft: 16,
							fontWeight: 400,
							color: '#6027b0',
						}}
					>
						Coverage details
					</h6>

					{getFieldValue(`insuranceCoverages-${k}-policyDocKeys`).map((c, policyDocIndex) => (
						<Row key={policyDocIndex}>
							<Col span={1} offset={1}>
								<div
									style={
										policyDocIndex === 0
											? {
													height: 86,
													display: 'flex',
													marginTop: 16,
													justifyContent: 'center',
													alignItems: 'center',
											  }
											: {
													height: 36,
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
											  }
									}
								>
									{policyDocIndex + 1}
								</div>
							</Col>

							<Col span={4}>
								<Form.Item label={policyDocIndex === 0 ? 'Type' : ''}>
									{getFieldDecorator(`insuranceCoverages-${k}-policyDocs-${c}-policyName`, {
										validateTrigger: ['onChange', 'onBlur'],
										initialValue: nullSafeGet(
											`${k}.policyDocs.${c}.policyName`,
											insuranceCoverages
										),
										rules: [
											{
												required: true,
												whitespace: true,
												message: 'Please add policy type.',
											},
										],
									})(<Input />)}
								</Form.Item>
							</Col>
							<Col span={4} offset={1}>
								<Form.Item label={policyDocIndex === 0 ? 'Amount' : ''}>
									{getFieldDecorator(`insuranceCoverages-${k}-policyDocs-${c}-amount`, {
										validateTrigger: ['onChange', 'onBlur'],
										initialValue: nullSafeGet(`${k}.policyDocs.${c}.amount`, insuranceCoverages),
										rules: [
											{
												required: true,
												message: 'Please add coverage amount.',
											},
										],
									})(<Input prefix="$" />)}
								</Form.Item>
							</Col>

							<Col span={4} offset={1}>
								<Form.Item label={policyDocIndex === 0 ? 'Expiration' : ''}>
									{getFieldDecorator(`insuranceCoverages-${k}-policyDocs-${c}-expiration`, {
										rules: [
											{
												type: 'object',
												required: true,
												message: "Please select your policy's expiration date!",
											},
											{ validator: expirationDateValidator },
										],
										initialValue: nullSafeGet(`${k}.policyDocs.${c}.expiration`, insuranceCoverages)
											? moment(nullSafeGet(`${k}.policyDocs.${c}.expiration`, insuranceCoverages))
											: undefined,
									})(<DatePicker format={DATE_FORMAT} />)}
								</Form.Item>
							</Col>

							<Col span={6} offset={1}>
								<Form.Item label={policyDocIndex === 0 ? 'Attachment' : ''}>
									{getFieldDecorator(`insuranceCoverages-${k}-policyDocs-${c}-attachment`, {
										initialValue: nullSafeGet(
											`${k}.policyDocs.${c}.policyFile`,
											insuranceCoverages
										),
										validateTrigger: ['onChange', 'onBlur'],
									})(
										<FileUploader
											roleType="supplier"
											handleUploadSuccess={this.handleAttachmentUploadChange(
												`insuranceCoverages-${k}-policyDocs-${c}-attachment`
											)}
											showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
											defaultFileList={
												nullSafeGet(`${k}.policyDocs.${c}.policyFile`, insuranceCoverages) &&
												nullSafeGet(`${k}.policyDocs.${c}.policyFile.fileName`, insuranceCoverages)
													? [nullSafeGet(`${k}.policyDocs.${c}.policyFile`, insuranceCoverages)]
													: []
											}
											multiple={false}
										/>
									)}
								</Form.Item>
							</Col>

							<Col
								span={1}
								style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
							>
								<div
									style={
										policyDocIndex === 0
											? {
													padding: 12,
													height: 92,
													marginTop: 12,
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
											  }
											: {
													padding: 12,
													height: 36,
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
											  }
									}
								>
									{getFieldValue(`insuranceCoverages-${k}-policyDocKeys`).length > 1 ? (
										<MinusCircleOutlined
											className="dynamic-delete-button"
											onClick={() => this.removeInsurancePolicyDoc(k, c)}
										/>
									) : null}
								</div>
							</Col>
						</Row>
					))}
					<Form.Item>
						<Button
							type="dashed"
							onClick={() => this.addInsurancePolicyDoc(k)}
							style={{ width: '100%' }}
						>
							<PlusOutlined /> Add coverage
						</Button>
					</Form.Item>
				</div>
			);
		});
		return (
			<Form onSubmit={this.handleSubmit} className="editSupplierInsuranceCoverageForm">
				{errors && errors.length > 0
					? errors.map((msg, idx) => (
							<FormItem key={idx}>
								<Alert message={msg} type="error" />
							</FormItem>
					  ))
					: null}
				{formItems}
				<Form.Item label="">
					<Button
						type="dashed"
						onClick={this.addInsuranceCoverage}
						style={{ width: '100%', height: 217 }}
					>
						<PlusOutlined /> Add Insurance Policy
					</Button>
				</Form.Item>
				<FormItem>
					<Button type="primary" htmlType="submit" loading={loading}>
						Update Insurance Coverage
					</Button>
					<Button style={{ marginLeft: 16 }} onClick={cancelCallback}>
						Cancel
					</Button>
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	loading: state.supplier_facilities.loading,
	errors: state.supplier_facilities.updateErrors,
	insuranceCoverages: ownProps.insuranceCoverages,
	successCallback: ownProps.successCallback,
	cancelCallback: ownProps.cancelCallback,
	supplierFacility: ownProps.supplierFacility,
});

const mapDispatchToProps = (dispatch) => ({
	updateSupplier: (supplierFacility) =>
		dispatch(supplierFacilitiesRestCrudThunksForSupplier.update(supplierFacility)),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<EditSupplierInsuranceCoverageFormProps>()(EditSupplierInsuranceCoverageForm))
);

import React, { FC, useCallback, useMemo, useState } from 'react';
import HyperLink from '../common/HyperLink';
import ReservedQuantityWorkOrdersModal from './ReservedQuantityWorkOrdersModal';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';

const ReservedQtyDisplay: FC<any> = ({ record, backText }): React.ReactElement => {
	const [reserQtyModalVisible, setReservedQtyModalVisible] = useState(false);

	const onReserveQtyClick = useCallback(
		(record) => () => {
			setReservedQtyModalVisible(true);
		},
		[]
	);

	const hideReservedQtyModal = useCallback(() => setReservedQtyModalVisible(false), []);

	const reservedQty = useMemo(
		() =>
			nullSafeGetOrElse('onHandQuantity', record, 0) -
			nullSafeGetOrElse('availableQuantity', record, 0),
		[record]
	);

	return (
		<>
			{reservedQty ? (
				<HyperLink text={reservedQty} onClick={onReserveQtyClick(record)} />
			) : (
				reservedQty
			)}
			{reserQtyModalVisible && (
				<ReservedQuantityWorkOrdersModal
					partsPerStockLocation={record}
					backText={backText}
					onCancel={hideReservedQtyModal}
				/>
			)}
		</>
	);
};

export default ReservedQtyDisplay;

import * as React from 'react';
import { Layout } from 'antd';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { CURRENT_ENV_ROLE_TYPE, getHomePageByRole } from '../utils/EnvConfigUtils';

const { Content } = Layout;

class Home extends React.Component<any, any> {
	render() {
		const { userType, featureFlags } = this.props;
		const homePage = userType
			? getHomePageByRole(userType, {
					...featureFlags,
					page: 'Home',
			  })
			: `/${CURRENT_ENV_ROLE_TYPE}/login`;

		return (
			// TODO: Refactor to redirect to different pages based on role
			<Redirect to={homePage} />
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	userType: state.session.userType,
	featureFlags: state.session.featureFlags,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Home);

import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { ContactAvatar } from '../../contact_avatar/ContactAvatar';
import { Col, Row } from 'antd';
import * as React from 'react';
import { nullSafeGet, nullSafeGetOrElse } from '../../../utils/DataAccessUtils';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { getProtectedImageUriForBuyer } from '../../../utils/FileAccessUtils';
import useOnScreen from '../../../hooks/onScreen';

interface LocationCardProps {
	location: any;
	name: any;
	subTitle?: string;
	showImages?: boolean;
	size?: 'default' | 'large';
	mode?: 'full' | 'simple' | 'avatar';
	details?: any;
	isVertical?: boolean;
	img?: any;
}

function formatPhoneNumber(phoneNumberString) {
	var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		var intlCode = match[1] ? '+1 ' : '';
		return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
	}
	return null;
}

const REGION_DISPLAY = {
	key: 'region',
	display: 'Region',
	formatValue: (value) => value,
};
const FULL_ADDRESS_DISPLAY = {
	key: 'buyerFacility.primaryAddress',
	display: '',
	formatValue: (value) => (
		<>
			<div className="">{nullSafeGet('streetAddress1', value)}</div>
			{nullSafeGet('streetAddress2', value) ? (
				<div className="">{nullSafeGet('streetAddress2', value)}</div>
			) : null}
			<div className="">
				{nullSafeGet('city', value)}, {nullSafeGet('region', value)}{' '}
				{nullSafeGet('postcode', value)}
			</div>
		</>
	),
	lines: 2,
};
const CITY_DISPLAY = {
	key: 'buyerFacility.primaryAddress.city',
	display: 'City',
	formatValue: (value) => value,
};
const STATE_DISPLAY = {
	key: 'buyerFacility.primaryAddress.region',
	display: 'State',
	formatValue: (value) => value,
};
const CITY_STATE_DISPLAY = {
	key: 'buyerFacility.primaryAddress',
	display: 'City/State',
	formatValue: (value) => `${nullSafeGet('city', value)}, ${nullSafeGet('region', value)}`,
};

const STREET_ADDRESS_DISPLAY = {
	key: 'buyerFacility.primaryAddress.streetAddress1',
	display: 'Street Address',
	formatValue: (value) => value,
};

const ISACTIVE_DISPLAY = {
	key: 'isActive',
	display: 'Is Active',
	formatValue: (value) => (value ? 'true' : 'false'),
};
const STORE_ID_NAME_DISPLAY = {
	key: '',
	display: '',
	formatValue: (value) =>
		`${nullSafeGet('locationType.name', value)} ${nullSafeGet('storeId', value)}`,
};

export const LocationCard: FC<LocationCardProps> = ({
	location,
	name,
	subTitle,
	showImages = true,
	size = 'default',
	mode = 'simple',
	details = [STORE_ID_NAME_DISPLAY, FULL_ADDRESS_DISPLAY],
	isVertical = false,
	img,
}) => {
	const lineHeight = '18.4';
	const displayImageURL =
		location.images &&
		location.images[0] &&
		getProtectedImageUriForBuyer(
			location.images[0].split('/')[0],
			location.images[0].split('/')[1],
			'auto',
			120
		);

	const onClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};
	const imageRef = useRef();
	const detailsRef = useRef();
	const [maxTitleWidth, setMaxTitleWidth] = useState(0);
	useEffect(() => {
		const w1 = imageRef.current ? imageRef.current.getBoundingClientRect().width : 0;
		const w2 = detailsRef.current.getBoundingClientRect().width;
		if (w1 > w2) {
			setMaxTitleWidth(w1);
		} else {
			setMaxTitleWidth(w2);
		}
	}, [imageRef, detailsRef]);

	const detailList = details
		.filter((obj) => nullSafeGet(obj.key, location) !== null)
		.map((obj) => ({
			...obj,
			value: obj.hasOwnProperty('formatValue')
				? obj.formatValue(obj.key ? nullSafeGet(obj.key, location) : location)
				: nullSafeGet(obj.key, location),
		}));

	return (
		<span
			onClick={onClick}
			className={`flex ${isVertical ? 'flex-row gap-4' : 'flex-col gap-2'} flex-nowrap`}
		>
			{showImages && location.images && location.images.length > 0 && isVertical !== undefined ? (
				<div className={'flex flex-row'} style={{ height: '120px' }}>
					<img ref={imageRef} src={displayImageURL} />
				</div>
			) : null}
			<div className={'flex flex-col'}>
				<div className={'flex flex-row'}>
					<div className={'flex flex-col'}>
						<Row
							style={{
								justifyContent: 'flex-start',
								color: 'rgba(0,0,0,0.65)',
								fontSize: size === 'large' || !subTitle ? 17.5 : 14,
								maxWidth: `${maxTitleWidth > 120 ? maxTitleWidth : 120}px`,
								minWidth: `50px`,
							}}
						>
							<Ellipsis lines={1}>{name}</Ellipsis>
						</Row>
						<Row
							style={{
								justifyContent: 'flex-start',
								color: 'rgba(0,0,0,0.45)',
								fontSize: size === 'large' ? 14 : 12,
							}}
						>
							<Ellipsis length={24}>{subTitle}</Ellipsis>
						</Row>
					</div>
				</div>
				{mode === 'full' && detailList.length > 0 ? (
					<Row
						ref={detailsRef}
						style={{
							gap: 8,
							borderTop: '1px solid #e8e8e8',
							width: '100%',
							marginTop: 4,
							paddingTop: 4,
						}}
					>
						<Col>
							{detailList.map((obj) => (
								<Row
									style={{
										display: 'flex',
										color: 'rgba(0,0,0,0.45)',
										fontSize: size === 'large' ? 14 : 12,
										alignItems: 'flex-start',
										lineHeight: lineHeight + 'px',
										height: parseInt(lineHeight) * (obj.lines || 1),
									}}
								>
									<Ellipsis fullWidthRecognition={true} lines={obj.lines || 1}>
										{obj.value}
									</Ellipsis>
								</Row>
							))}
						</Col>
					</Row>
				) : null}
			</div>
		</span>
	);
};

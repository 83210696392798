import * as React from 'react';
import { connect } from 'react-redux';
import { Button, message } from 'antd';
import { withRouter } from 'react-router';
import {
	cancelWorkOrderForBuyer,
	workOrdersRestCrudThunksForBuyer,
	reopenWorkOrderForBuyer,
	snoozeWorkOrderForBuyer,
} from '../../thunks/work_orders_thunks';
import { getWorkOrderBasePriorityBackgroundColor } from '../../utils/DataFormatterUtils';
import WorkOrderStatusDisplay from '../work_order_status_display/WorkOrderStatusDisplay';
import {
	checkHasApprovalHierarchy,
	getEntityById,
	isInApprovalHierarchy,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import { Link } from 'react-router-dom';
import { BUYER_ONLY_NO_STORE_ASSOCIATE } from '../roles/roles';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import ChangeStatusWithNoteWorkForm from '../change_status_with_note_work_form/ChangeStatusWithNoteWorkForm';
import moment from 'moment';
import { WORK_ORDER_STATUSES } from '../../constants/WorkOrderStatuses';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { isUserAllowedToAccess, PERMISSION_NAMES } from '../../utils/AuthUtils';
import DeferWorkOrderModal from '../defer_work_order_modal/DeferWorkOrderModal';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import CancelWorkOrder from '../cancel_work_order/CancelWorkOrder';
import NextPreviousButton from '../next_previous_button/NextPreviousButton';
import { updatePaginationWorkOrders } from '../../thunks/work_orders_thunks_wrapper';
import { WORK_ORDERS_CRUD_ACTION_CREATORS } from '../../actions/work_orders_actions';

require('./WorkOrdersDetailPage.less');
const queryString = require('qs');
interface WorkOrdersDetailPageState {}

class WorkOrdersDetailPage extends React.Component<any, WorkOrdersDetailPageState> {
	formRefs: any;

	state = {
		loading: true,
		reopenModalVisible: false,
		cancelModalVisible: false,
		backLinkTo: '/buyer/workOrders',
		backLinkText: 'Back to work orders',
		backLinkParams: '',
		tab: 'active',
		deferModalVisible: false,
		buttonLoading: false,
		fromTargetCollectionName: null,
	};

	constructor(props) {
		super(props);
		this.formRefs = {};
		this.handleReopenSubmit = this.handleReopenSubmit.bind(this);
		this.handleReopenCancel = this.handleReopenCancel.bind(this);
		this.showReopenModal = this.showReopenModal.bind(this);
		this.handleDeferSubmit = this.handleDeferSubmit.bind(this);
		this.handleDeferCancel = this.handleDeferCancel.bind(this);
		this.showDeferModal = this.showDeferModal.bind(this);
	}

	componentDidMount() {
		const { getWorkOrder, location, workOrder, match, getWorkOrderDelta } = this.props;
		const workOrderId = match.params.id;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		if (queryParams.targetCollectionName) {
			this.setState({ fromTargetCollectionName: queryParams.targetCollectionName });
		}
		if (queryParams.backlinkParams) {
			const { tab, pathName, backLinkText, ...otherParams } = queryString.parse(
				queryParams.backlinkParams
			);
			const finalPath = pathName || `/buyer/workOrders/overview/${tab ? tab : 'active'}`;
			this.setState({
				tab: tab ? tab : 'active',
				loading: false,
				backLinkText: backLinkText ? backLinkText : '',
				backLinkTo: `${finalPath}?${new URLSearchParams(otherParams).toString()}`,
				backLinkParams: new URLSearchParams(queryParams).toString(),
			});
		} else {
			this.setState({
				loading: false,
				backLinkTo: `/buyer/workOrders/overview/active`,
				backLinkParams: '',
			});
		}
		if (workOrder && workOrder.id) {
			getWorkOrderDelta(workOrderId);
		} else {
			getWorkOrder(workOrderId);
		}
	}
	componentDidUpdate(prevProps, prevState) {
		const { workOrder, getWorkOrderDelta } = this.props;
		const { workOrder: prevWorkOrder } = prevProps;
		if (workOrder && prevWorkOrder && workOrder.id !== prevWorkOrder.id) {
			getWorkOrderDelta(workOrder.id);
		}
	}

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};
	getLocationText = () => {
		const { workOrder } = this.props;
		return `${nullSafeGet('location.name', workOrder) || ''} `;
	};

	handleTabChange = (key) => {
		const { history, match } = this.props;
		const workOrderId = match.params.id;
		history.push(`/buyer/workOrders/detail/${workOrderId}/${key}`);
	};

	handleDelete = () => {
		const { history, deleteWorkOrder, workOrder } = this.props;
		const { backLinkTo } = this.state;
		deleteWorkOrder(workOrder).then(() => {
			history.push(backLinkTo);
		});
	};

	handleArchive = () => {
		const { history, archiveWorkOrder, workOrder } = this.props;
		const { backLinkTo } = this.state;
		archiveWorkOrder(workOrder).then(() => {
			history.push(backLinkTo);
		});
	};

	handleReopen = () => {
		const { history, workOrder, reopenWorkOrder } = this.props;
		reopenWorkOrder(workOrder).then(() => {
			history.push(`/buyer/workOrders/detail/${workOrder.id}`);
		});
	};

	handleUnarchive = () => {
		const { history, unarchiveWorkOrder, workOrder } = this.props;
		const { backLinkTo } = this.state;
		unarchiveWorkOrder(workOrder).then(() => {
			history.push(backLinkTo);
		});
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	handleReopenSubmit() {
		const { reopenWorkOrder, currentUser, workOrder } = this.props;
		const form = this.formRefs['reopenForm'].props.form;

		this.setState({ buttonLoading: true });
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			const note = {
				text: values.note,
				noteAddedBy: currentUser.email,
				noteAddedAt: moment(),
			};
			const workOrderNoteDetails = {
				id: values.workOrderId,
				locationId: workOrder.locationId,
				note,
			};

			reopenWorkOrder(workOrderNoteDetails).then(() => {
				this.setState({ reopenModalVisible: false, buttonLoading: false });
				message.success(`re-opened for work.`);
			});
		});
	}

	handleReopenCancel(e) {
		this.setState({
			reopenModalVisible: false,
		});
	}

	showReopenModal(e) {
		this.setState({
			reopenModalVisible: true,
		});
	}

	showDeferModal(e) {
		this.setState({
			deferModalVisible: true,
		});
	}

	handleDeferCancel(e) {
		this.setState({
			deferModalVisible: false,
		});
	}

	handleDeferSubmit() {
		const { snoozeWorkOrder } = this.props;
		const form = this.formRefs['deferForm'].props.form;
		this.setState({ buttonLoading: true });
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			const entity = {
				id: values.workOrderId,
				snoozeUntil: values.deferDate,
			};

			snoozeWorkOrder(entity).then(() => {
				this.setState({ deferModalVisible: false, buttonLoading: false });
				message.success(`Deferred Work Order.`);
			});
		});
	}

	render() {
		const {
			updatePagination,
			workOrder,
			match,
			currentUser,
			companyConfig,
			location,
			history,
			work_orders,
			cloneTargetCollection,
		} = this.props;

		const { loading } = this.state;
		const workOrderId = match.params.id;
		const privateSupplier = nullSafeGet('supplierFacility.status', workOrder) === 'private';
		const hasUnreadNotes = nullSafeGetOrElse('unreadNotesNotificationIds.length', workOrder, 0) > 0;
		const isAssignedToInternalTech =
			nullSafeGetOrElse('supplierFacility.status', workOrder, '') === 'private';
		const tabs = privateSupplier
			? [
					{
						key: 'details',
						name: 'Overview',
					},
					{
						key: 'notes',
						name: hasUnreadNotes ? (
							<>
								Notes <span className="Notification_badge ml-1 align-middle" />
							</>
						) : (
							'Notes'
						),
					},
			  ]
			: [
					{
						key: 'details',
						name: 'Overview',
					},
					{
						key: 'notes',
						name: hasUnreadNotes ? (
							<>
								Notes <span className="Notification_badge ml-1 align-middle" />
							</>
						) : (
							'Notes'
						),
					},
					{
						key: 'quotes',
						name: 'Quotes',
					},
					{
						key: 'invoices',
						name: 'Invoices',
					},
			  ];

		const isApprovalAllowed = checkHasApprovalHierarchy(workOrder)
			? isInApprovalHierarchy(currentUser, nullSafeGet('approvalHierarchy', workOrder))
			: isUserAllowedToAccess('buyer')(
					PERMISSION_NAMES.CAN_APPROVE_WORK_ORDERS,
					companyConfig,
					currentUser.roles
			  );
		const showDeferred = nullSafeGetOrElse(
			'config.workOrder.allowWorkOrderToBeDeferred',
			companyConfig,
			false
		);
		const workOrderActions = () => {
			let actions = [];
			if (
				showDeferred &&
				isApprovalAllowed &&
				(workOrder.status === WORK_ORDER_STATUSES.unassigned ||
					workOrder.status === WORK_ORDER_STATUSES.assignedToInternalTech ||
					workOrder.status === WORK_ORDER_STATUSES.pendingApproval)
			) {
				actions.push(
					<Button
						ghost={true}
						style={{ marginBottom: 16, marginRight: 16 }}
						key="reopen"
						onClick={this.showDeferModal}
					>
						Defer
					</Button>
				);
			}
			if (
				currentUser.roles &&
				currentUser.roles.some((role) => BUYER_ONLY_NO_STORE_ASSOCIATE.has(role))
			) {
				if (workOrder.displayStatus === 'Completed') {
					actions.push(
						<AccessPermissionChecker name={PERMISSION_NAMES.EDIT_WORK_ORDERS}>
							<Link
								to={getLinkWIthBackLinkParams(
									location,
									'Back to Work Order Detail',
									`/buyer/workOrders/editSafe/${workOrderId}`
								)}
								style={{ marginRight: 16, marginBottom: 16 }}
							>
								<Button ghost={true}>Edit</Button>
							</Link>
						</AccessPermissionChecker>
					);
				} else if (workOrder.displayStatus !== 'Pending') {
					actions.push(
						<AccessPermissionChecker name={PERMISSION_NAMES.EDIT_WORK_ORDERS}>
							<Link
								to={getLinkWIthBackLinkParams(
									location,
									'Back to Work Order Detail',
									`/buyer/workOrders/edit/${workOrderId}`
								)}
								style={{ marginRight: 16, marginBottom: 16 }}
							>
								<Button ghost={true}>Edit</Button>
							</Link>
						</AccessPermissionChecker>
					);
				}

				if (workOrder.hasBuyerArchived) {
					actions = actions.concat([
						<Button
							ghost={true}
							style={{ marginRight: 16, marginBottom: 16 }}
							onClick={this.handleUnarchive}
						>
							Unarchive
						</Button>,
						<Button ghost={true} style={{ marginBottom: 16 }} onClick={this.handleDelete}>
							Delete
						</Button>,
					]);
					return actions;
				}

				if (
					workOrder.displayStatus !== 'Completed' &&
					workOrder.displayStatus !== 'Cancelled' &&
					(isAssignedToInternalTech || workOrder.status === WORK_ORDER_STATUSES.unassigned) &&
					nullSafeGetOrElse('rfpDataIds.length', workOrder, 0) === 0
				) {
					actions.push(
						<Link
							to={`/buyer/requestsForProposal/overview/new?workOrderId=${workOrderId}`}
							style={{ marginRight: 16, marginBottom: 16 }}
						>
							<Button ghost={true}>Request Quotes</Button>
						</Link>
					);
				}
				if (
					workOrder.displayStatus !== 'Completed' &&
					workOrder.displayStatus !== 'Cancelled' &&
					nullSafeGetOrElse('rfpDataIds.length', workOrder, 0) > 0
				) {
					const latestRfpId = workOrder.rfpDataIds[0];
					actions.push(
						<Link
							to={`/buyer/requestsForProposal/detail/${latestRfpId}/details`}
							style={{ marginRight: 16, marginBottom: 16 }}
						>
							<Button ghost={true}>View RFP</Button>
						</Link>
					);
				}
				if (
					['Open', 'Pending', 'InProgress', 'OnHold'].some(
						(status) => workOrder.displayStatus === status
					)
				) {
					actions.push(
						<AccessPermissionChecker name={PERMISSION_NAMES.CANCEL_WORK_ORDERS}>
							<CancelWorkOrder
								workOrder={workOrder}
								refresh={() => {
									history.push(`/buyer/workOrders/detail/${workOrder.id}`);
								}}
							/>
						</AccessPermissionChecker>
					);
				}

				if (workOrder.displayStatus === 'Completed' || workOrder.displayStatus === 'Cancelled') {
					actions.push(
						<AccessPermissionChecker name={PERMISSION_NAMES.REOPEN_WORK_ORDERS}>
							<Button
								ghost={true}
								style={{ marginBottom: 16, marginRight: 16 }}
								key="reopen"
								onClick={this.showReopenModal}
							>
								Re-open
							</Button>
						</AccessPermissionChecker>,
						<Button ghost={true} style={{ marginBottom: 16 }} onClick={this.handleArchive}>
							Archive
						</Button>
					);
				}
				return actions;
			} else {
				if (
					workOrder.displayStatus === 'Pending' ||
					(workOrder.displayStatus === 'Open' && workOrder.status === 'Unassigned')
				) {
					actions.push(
						<AccessPermissionChecker name={PERMISSION_NAMES.EDIT_WORK_ORDERS}>
							<Link
								to={`/buyer/workOrders/pending/edit/${workOrderId}`}
								style={{ marginRight: 16, marginBottom: 16 }}
							>
								<Button ghost={true}>Edit</Button>
							</Link>
						</AccessPermissionChecker>
					);
				} else {
					actions.push(
						<AccessPermissionChecker name={PERMISSION_NAMES.EDIT_WORK_ORDERS}>
							<Link
								to={`/buyer/workOrders/editSafe/${workOrderId}`}
								style={{ marginRight: 16, marginBottom: 16 }}
							>
								<Button ghost={true}>Edit</Button>
							</Link>
						</AccessPermissionChecker>
					);
				}

				if (workOrder.hasBuyerArchived) {
					actions = actions.concat([
						<Button
							ghost={true}
							style={{ marginRight: 16, marginBottom: 16 }}
							onClick={this.handleUnarchive}
						>
							Unarchive
						</Button>,
					]);
					return actions;
				}

				if (nullSafeGetOrElse('config.newServiceRequest.allowCancel', companyConfig, false)) {
					if (
						['Open', 'Pending', 'InProgress', 'OnHold'].some(
							(status) => workOrder.displayStatus === status
						)
					) {
						actions.push(
							<AccessPermissionChecker name={PERMISSION_NAMES.CANCEL_WORK_ORDERS}>
								<CancelWorkOrder
									workOrder={workOrder}
									refresh={() => {
										history.push(`/buyer/workOrders/detail/${workOrder.id}`);
									}}
								/>
							</AccessPermissionChecker>
						);
					}
				}

				if (workOrder.displayStatus === 'Completed' || workOrder.displayStatus === 'Cancelled') {
					if (nullSafeGetOrElse('config.newServiceRequest.allowReopen', companyConfig, false)) {
						actions.push(
							<AccessPermissionChecker name={PERMISSION_NAMES.REOPEN_WORK_ORDERS}>
								<Button
									ghost={true}
									style={{ marginBottom: 16, marginRight: 16 }}
									key="reopen"
									onClick={this.showReopenModal}
								>
									Re-open
								</Button>
							</AccessPermissionChecker>
						);
					}
					actions.push(
						<Button ghost={true} style={{ marginBottom: 16 }} onClick={this.handleArchive}>
							Archive
						</Button>
					);
				}
				return actions;
			}
		};

		const priorityColor = getWorkOrderBasePriorityBackgroundColor(workOrder.woPriority);
		return (
			<div className="workOrdersDetailPage">
				{this.state.reopenModalVisible ? (
					<ChangeStatusWithNoteWorkForm
						wrappedComponentRef={this.saveFormRef('reopenForm')}
						visible={this.state.reopenModalVisible}
						workOrderId={workOrder.id}
						onCancel={this.handleReopenCancel}
						onSubmit={this.handleReopenSubmit}
						formTitle={'Not done yet. Re-open?'}
						formText={'Re-open'}
						loading={this.state.buttonLoading}
					/>
				) : null}
				<DeferWorkOrderModal
					wrappedComponentRef={this.saveFormRef('deferForm')}
					visible={this.state.deferModalVisible}
					workOrderId={workOrder.id}
					onCancel={this.handleDeferCancel}
					onSubmit={this.handleDeferSubmit}
					formTitle={'Defer this work order?'}
					formText={'Defer'}
					buttonLoading={this.state.buttonLoading}
				/>

				<DetailPageHeader
					exactPath={'/buyer/workOrders/detail/:id'}
					redirectPath={`/buyer/workOrders/detail/${workOrderId}/details`}
					backLinkText={this.state.backLinkText || 'Back to work orders'}
					backLinkTo={this.state.backLinkTo}
					title={
						loading ? null : (
							<div className="flex justify-between">
								<span>{workOrder.title} </span>
							</div>
						)
					}
					subtitle={
						<div className="flex flex-col flex-wrap">
							<div>
								{nullSafeGet('id', workOrder) && (
									<span className="workOrdersDetailPage__subtitle">
										WO# {nullSafeGet('id', workOrder)}
									</span>
								)}
							</div>
							<div className="flex flex-row flex-wrap">
								<WorkOrderStatusDisplay
									className="workOrdersDetailPage__subtitle"
									status={workOrder.status}
									displayStatus={workOrder.displayStatus}
									darkMode={true}
								/>
								<span className="workOrdersDetailPage__subtitle">
									{nullSafeGet('woPriority.name', workOrder)}
								</span>
							</div>
						</div>
					}
					subtext={<div className="body1"> {this.getLocationText()}</div>}
					actions={workOrderActions()}
					tabs={tabs}
					backgroundColor={priorityColor}
					handleTabChange={this.handleTabChange}
					checkTabActive={this.checkTabActive}
					childRoutes={this.props.childRoutes}
					tabsRightActions={() => (
						<NextPreviousButton
							key={this.state.fromTargetCollectionName}
							location={location}
							history={history}
							activeId={workOrder.id}
							getEnitityUrl={(id) => `/buyer/workOrders/detail/${id}/details`}
							stateSlice={work_orders}
							targetCollectionName={this.state.fromTargetCollectionName}
							updatePagination={updatePagination}
							cloneTargetCollection={cloneTargetCollection}
							entityName="work order"
						/>
					)}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	match: ownProps.match,
	history: ownProps.history,
	currentUser: state.session.currentUser,
	companyConfig: state.company_config.detail,
	workOrder: getEntityById(ownProps.match.params.id, state.work_orders),
	work_orders: state.work_orders,
});

const mapDispatchToProps = (dispatch) => ({
	getWorkOrder: (id) => dispatch(workOrdersRestCrudThunksForBuyer.readOne(id)),
	getWorkOrderDelta: (id) => dispatch(workOrdersRestCrudThunksForBuyer.readDelta(id)),
	deleteWorkOrder: (entity) =>
		dispatch(workOrdersRestCrudThunksForBuyer.delete(entity, 'id', null, true)),
	cancelWorkOrder: (entity) => dispatch(cancelWorkOrderForBuyer(entity)),
	reopenWorkOrder: (entity) => dispatch(reopenWorkOrderForBuyer(entity)),
	snoozeWorkOrder: (entity) => dispatch(snoozeWorkOrderForBuyer(entity)),
	archiveWorkOrder: (entity) =>
		dispatch(workOrdersRestCrudThunksForBuyer.archive(entity, 'id', true)),
	unarchiveWorkOrder: (entity) =>
		dispatch(workOrdersRestCrudThunksForBuyer.unarchive(entity, 'id', true)),
	updatePagination: (pagination, targetCollectionName) =>
		dispatch(
			updatePaginationWorkOrders('buyer')(false, undefined, targetCollectionName, pagination, true)
		),
	cloneTargetCollection: (from, to) =>
		dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.cloneTargetCollection(from, to)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkOrdersDetailPage));

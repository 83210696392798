import * as React from 'react';
import { Layout, Row, Col, Card } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import {
	invoicesRestCrudThunksForSupplier,
	publishInvoiceForSupplier,
} from '../../thunks/invoices_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import InvoiceUploader from '../invoice_uploader/InvoiceUploader';
import {
	appendWorkOrderNotesForSupplier,
	workOrdersRestCrudThunksForSupplier,
} from '../../thunks/work_orders_thunks';
import InvoicePageDisplay from '../invoice_page_display/InvoicePageDisplay';
import { markUserNotificationsReadForSupplier } from '../../thunks/user_notifications_thunks';

const { Content } = Layout;

const style = require('./SupplierInvoicesDetailDetailsPage.less');

class InvoicesDetailPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			generatingPDF: false,
			pdfFile: { url: null, httpHeaders: null },
			buttonLoading: false,
		};
	}

	componentDidMount() {
		const { getWorkOrder, getInvoice, match, markUserNotificationsRead } = this.props;
		const invoiceId = match.params.id;
		getInvoice(invoiceId).then((invoice) => {
			getWorkOrder(invoice.workOrderId);
			markUserNotificationsRead(invoice.id);
		});
	}

	refreshInvoice = () => {
		const { getWorkOrder, getInvoice, match } = this.props;
		const invoiceId = match.params.id;
		return getInvoice(invoiceId).then((invoice) => {
			getWorkOrder(invoice.workOrderId);
			return invoice;
		});
	};

	handleFileAttachmentUploadChange = (newAttachments) => {
		const { updateInvoice, invoice } = this.props;
		const newInvoice = {
			...invoice,
			attachments: newAttachments,
		};
		updateInvoice(newInvoice);
	};

	handleInvoicePDFUploadChangeForDeletedInvoice = (invoiceFileUrl) => {
		const { updateInvoice, getInvoice, invoice } = this.props;

		const newInvoice = {
			...invoice,
			invoicePDFLink: invoiceFileUrl,
			isDeleted: false,
		};

		updateInvoice(newInvoice).then(() => {
			getInvoice(invoice.id);
		});
	};

	render() {
		const { workOrdersFetching, invoice, invoicesFetching } = this.props;

		if (invoice.isDeleted) {
			return (
				<Row gutter={12}>
					<Col span={24}>
						<Card>
							<div>
								<h5 style={{ marginBottom: '4px' }}>Upload an invoice file</h5>
								<div>
									<p
										style={{
											fontSize: 16,
											marginBottom: '0.5em',
										}}
									>
										Add an invoice file (PDF) to finish creating the invoice.
									</p>
									<InvoiceUploader
										roleType="supplier"
										invoiceId={invoice.id}
										buttonType="primary"
										defaultFileList={[]}
										multiple={false}
										handleUploadSuccess={this.handleInvoicePDFUploadChangeForDeletedInvoice}
									/>
								</div>
							</div>
						</Card>
					</Col>
				</Row>
			);
		}

		return invoicesFetching || workOrdersFetching ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="invoicesDetailDetailsPage">
				<LogOnMountWithStandardEventProperties eventType="visited supplier invoice detail page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<InvoicePageDisplay invoice={invoice} refreshInvoice={this.refreshInvoice} />
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	workOrdersFetching: state.work_orders.fetching,
	workOrder: state.work_orders.detail,
	invoicesFetching: state.invoices.fetching,
	invoice: state.invoices.detail,
	invoicesCreating: state.invoices.creating,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	appendWorkOrderNotes: (entity) => dispatch(appendWorkOrderNotesForSupplier(entity)),
	getInvoice: (id) => dispatch(invoicesRestCrudThunksForSupplier.readOne(id)),
	getWorkOrder: (id) => dispatch(workOrdersRestCrudThunksForSupplier.readOne(id)),
	updateInvoice: (entity) => dispatch(invoicesRestCrudThunksForSupplier.update(entity)),
	publishInvoice: (id) => dispatch(publishInvoiceForSupplier({ id })),
	markUserNotificationsRead: (entityId) =>
		dispatch(markUserNotificationsReadForSupplier('Invoice', entityId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoicesDetailPage));

import * as React from 'react';
import { Card } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { buyerSettingsRestCrudThunksForBuyer } from '../../thunks/buyer_settings_thunks';

const style = require('./EditSupplierNotificationsSettingsPage.less');

class EditSupplierNotificationsSettingsPage extends React.Component<any, any> {
	componentDidMount() {
		const { getBuyerSettings, currentUser } = this.props;
		getBuyerSettings(currentUser.email);
	}

	render() {
		return (
			<div>
				<Card style={{ margin: '24px 24px 0' }} bordered={false}></Card>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		history: ownProps.history,
		currentUser: state.session.currentUser,
		buyerSettings: state.buyer_settings.detail,
	};
};

const mapDispatchToProps = (dispatch) => ({
	getBuyerSettings: (email) => dispatch(buyerSettingsRestCrudThunksForBuyer.readOne(email)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(EditSupplierNotificationsSettingsPage)
);

import {
	CURRENCIES_CRUD_ACTION_CREATORS,
	fetchCurrencies,
	fetchCurrenciesError,
	fetchCurrenciesSuccess,
} from '../actions/currencies_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import FetchUtils from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';

const fetchUtils = new FetchUtils();
const BASE_URL = getBackendUri();

export const currenciesRestCrudThunks = new RestCrudThunks(
	'currencies',
	'currency',
	'currency',
	'currencies',
	CURRENCIES_CRUD_ACTION_CREATORS
);

export const getExchangeRate =
	(c1, c2) =>
	(userType = 'buyer') => {
		let currencyString = c1;
		if (c1 && c2) {
			currencyString = c1.concat('/', c2);
		}
		const route = `${userType}/currency_exchange/latest/`.concat(currencyString);

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(fetchCurrencies());
				fetchUtils
					.get(BASE_URL + `/api/v1/${route}`, {})
					.then((response) => response.json())
					.then((d) => {
						if (d.status === 'error') {
							dispatch(fetchCurrenciesError([d.message]));
							reject(d.message);
						} else {
							dispatch(fetchCurrenciesSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(fetchCurrenciesError(d.message));
						reject(d.message);
					});
			});
		};
	};

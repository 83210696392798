import * as React from 'react';
import { Button, Col, Row } from 'antd';
import Mapbox from '../mapbox/Mapbox';

const style = require('./SelectSupplierCard.less');

export default class SelectSupplierCard extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = { mapHover: false };
	}
	handleSupplierSelected = () => {
		const { supplier, handleSupplierSelected } = this.props;
		handleSupplierSelected(supplier);
	};
	handleExistingSupplierSelected = () => {
		const { supplier, handleExistingSupplierSelected } = this.props;
		handleExistingSupplierSelected(supplier);
	};

	render() {
		const { supplier, currentSuppliers } = this.props;
		const currentSupplierIds = Object.keys(currentSuppliers.distinctRecords);

		return (
			<Row className="selectSupplierCard" style={{ flexWrap: 'nowrap' }}>
				<Col className="selectSupplierContainer">
					<div className="selectSupplierLogo"></div>
				</Col>
				<Col className="selectSupplierInfoContainer">
					<Row
						className="selectSupplierNameContainer"
						style={{ overflowX: 'hidden', overflowY: 'hidden' }}
					>
						{supplier.displayName}
					</Row>
					{Object.keys(supplier).includes('primaryAddress') ? (
						<div>
							<Row className="selectSupplierAddressContainer1" style={{ flexWrap: 'nowrap' }}>
								{supplier.primaryAddress.streetAddress1}
							</Row>
							<Row className="selectSupplierAddressContainer2" style={{ flexWrap: 'nowrap' }}>
								{supplier.primaryAddress.city.concat(', ', supplier.primaryAddress.region)}
							</Row>
						</div>
					) : null}
					<Row className="selectSupplierTitleContainer">
						{currentSupplierIds.includes(supplier.id.toString()) ? (
							<span style={{ color: 'red', fontSize: '12.5px' }}>
								This supplier is currently in your private network
							</span>
						) : (
							supplier.type
						)}
					</Row>
				</Col>
				<Col className="selectSupplierButtonContainer">
					<Button
						type="primary"
						onClick={
							currentSupplierIds.includes(supplier.id.toString())
								? this.handleExistingSupplierSelected
								: this.handleSupplierSelected
						}
					>
						Yes, this one
					</Button>
				</Col>
			</Row>
		);
	}
}

import React from 'react';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import WorkOrderDisplayStatusDisplay from '../work_order_display_status_display/WorkOrderDisplayStatusDisplay';
import {
	getWorkOrderBasePriorityBackgroundColor,
	getWorkOrderBasePriorityPillBackgroundColor,
} from '../../utils/DataFormatterUtils';
import warrantyDisplay from '../common/WarrantyDisplay';
import { WORK_ORDER_STATUSES } from '../../constants/WorkOrderStatuses';
import DateTimeHover from '../date_time_component/DateTime';
import { WORK_ORDER_DISPLAY_STATUSES } from '../../constants/WorkOrderDisplayStatuses';

const moment = require('moment');
require('./WorkOrderRowDisplay.less');

export const WorkOrderRowDisplay: React.FC<any> = ({ workOrder, simpler }) => {
	if (!workOrder) {
		return null;
	}
	const hasEstimatedCompletionDate = workOrder.estimatedCompletionDate !== undefined;
	const isDueDateSameAsCompletionDate = hasEstimatedCompletionDate
		? moment(workOrder.dueDate).diff(workOrder.estimatedCompletionDate, 'hours') === 0
		: true;
	const isPastDueAndEstimatedCompletionDate =
		moment(workOrder.dueDate).diff(moment(), 'milliseconds') < 0 && isDueDateSameAsCompletionDate
			? true
			: moment(workOrder.estimatedCompletionDate).diff(moment(), 'milliseconds') < 0;

	const isNotClosed =
		workOrder.displayStatus !== 'Completed' && workOrder.displayStatus !== 'Cancelled';

	return (
		<div className="rowColumn">
			<div className="rowPrimary">
				{nullSafeGetOrElse('unreadNotesNotificationIds.length', workOrder, 0) > 0 && (
					<span className="Notification_badge mr-1 self-start" />
				)}
				<Ellipsis
					tooltip={true}
					fullWidthRecognition={true}
					lines={1}
					style={{
						color: 'rgba(0,0,0,0.65)',
						paddingBottom: '1px',
						marginRight: '5%',
						overflow: 'hidden',
					}}
				>
					{workOrder.title}
				</Ellipsis>
			</div>
			<div className="rowSecondary">
				<div className="rowInlineGroup">
					<span className="rowGrayChip">
						<i className="icons8-font icons8-location-marker" />
						<span>{nullSafeGet('location.name', workOrder)}</span>
					</span>
				</div>
				{nullSafeGet('isWarranty', workOrder) ? (
					<div className="rowInlineGroup" style={{ display: simpler ? 'none' : 'inline-flex' }}>
						{warrantyDisplay('Active')}
					</div>
				) : null}
				<div className="rowInlineGroup" style={{ display: 'inline-flex' }}>
					<WorkOrderDisplayStatusDisplay status={workOrder.displayStatus} />
				</div>
				<div className="rowInlineGroup" style={{ display: simpler ? 'none' : 'inline-flex' }}>
					<span
						style={{
							color: getWorkOrderBasePriorityBackgroundColor(workOrder.woPriority),
							backgroundColor: getWorkOrderBasePriorityPillBackgroundColor(workOrder.woPriority),
							padding: '4px 12px',
							borderRadius: 16,
						}}
					>
						{nullSafeGet('woPriority.name', workOrder)}
					</span>
				</div>
				{workOrder.isCapex ? (
					<div className="rowInlineGroup" style={{ display: simpler ? 'none' : 'inline-flex' }}>
						<span
							className={'rowGrayChip'}
							style={{
								padding: '4px 12px',
								borderRadius: 16,
							}}
						>
							CapEx
						</span>
					</div>
				) : null}
				<div className="rowInlineGroup" style={{ display: simpler ? 'none' : 'inline-flex' }}>
					<i className="icons8-font icons8-maintenance-filled" />
					<span>{nullSafeGet('problemType.hierarchyName', workOrder)}</span>
				</div>
				{nullSafeGet('supplierFacility.displayName', workOrder) ? (
					<div className="rowInlineGroup" style={{ display: simpler ? 'none' : 'inline-flex' }}>
						<i className="icons8-font icons8-company" />
						{nullSafeGet('supplierFacility.displayName', workOrder)}
					</div>
				) : null}
				<div className="rowInlineGroup" style={{ display: 'inline-flex' }}>
					<span>WO #{nullSafeGet('id', workOrder)}</span>
				</div>
				{nullSafeGet('poNumber', workOrder) ? (
					<div className="rowInlineGroup" style={{ display: 'inline-flex' }}>
						<span>PO #{nullSafeGet('poNumber', workOrder)}</span>
					</div>
				) : null}
				{nullSafeGet('buyerProjects.length', workOrder) === 1 ? (
					<div className="rowInlineGroup" style={{ display: simpler ? 'none' : 'inline-flex' }}>
						<span>
							<i className="icons8-font icons8-blueprint" />
							{nullSafeGet('buyerProjects', workOrder)[0].title}
						</span>
					</div>
				) : nullSafeGet('buyerProjects.length', workOrder) > 1 ? (
					<div className="rowInlineGroup" style={{ display: simpler ? 'none' : 'inline-flex' }}>
						<span>
							<i className="icons8-font icons8-blueprint" />
							{nullSafeGet('buyerProjects.length', workOrder)} Projects
						</span>
					</div>
				) : null}
			</div>
			<div className="rowTertiary">
				{isNotClosed ? (
					isDueDateSameAsCompletionDate ? (
						isPastDueAndEstimatedCompletionDate ? (
							<span
								style={{
									color: 'rgb(163, 40, 40)',
									backgroundColor: 'rgb(249, 230, 230)',
									padding: '4px 12px',
									borderRadius: 16,
								}}
							>
								Past due <DateTimeHover timestamp={workOrder.dueDate} showTime={false} />
							</span>
						) : (
							<span>
								Reported <DateTimeHover timestamp={workOrder.createdAt} showTime={false} />
							</span>
						)
					) : isPastDueAndEstimatedCompletionDate ? (
						[
							<span
								style={{
									color: 'rgb(163, 40, 40)',
									backgroundColor: 'rgb(249, 230, 230)',
									padding: '4px 12px',
									borderRadius: 16,
								}}
							>
								Past due {moment(workOrder.dueDate).fromNow()}
							</span>,
							<span
								style={{
									color: 'rgb(163, 40, 40)',
									backgroundColor: 'rgb(249, 230, 230)',
									padding: '4px 12px',
									borderRadius: 16,
								}}
							>
								Estimated completion {moment(workOrder.estimatedCompletionDate).fromNow()}
							</span>,
						]
					) : (
						[
							<span>Reported {moment(workOrder.createdAt).fromNow()}</span>,
							<span>
								Estimated completion <DateTimeHover timestamp={workOrder.estimatedCompletionDate} />
							</span>,
						]
					)
				) : (
					<span>
						Reported <DateTimeHover timestamp={workOrder.createdAt} />
					</span>
				)}

				{workOrder.displayStatus === WORK_ORDER_DISPLAY_STATUSES.inProgress &&
				nullSafeGet('lastServiceCall.checkInTime', workOrder) &&
				!nullSafeGet('lastServiceCall.checkOutTime', workOrder) ? (
					<span style={{ whiteSpace: 'nowrap', color: 'rgba(0,0,0,0.45)', fontSize: 14 }}>
						<span>
							Checked in at{' '}
							<DateTimeHover
								timestamp={nullSafeGet('lastServiceCall.checkInTime', workOrder)}
								showDate
							/>
						</span>
					</span>
				) : [
						WORK_ORDER_STATUSES.techScheduled,
						WORK_ORDER_STATUSES.techRescheduled,
						WORK_ORDER_STATUSES.techWorkingOnSite,
				  ].includes(workOrder.status) &&
				  nullSafeGet('lastServiceCall.serviceScheduledAt', workOrder) ? (
					<span style={{ whiteSpace: 'nowrap', color: 'rgba(0,0,0,0.45)', fontSize: 14 }}>
						<span>
							Service scheduled{' '}
							<DateTimeHover
								timestamp={nullSafeGet('lastServiceCall.serviceScheduledAt', workOrder)}
								showDate
							/>
						</span>
					</span>
				) : null}

				{workOrder.displayStatus === WORK_ORDER_DISPLAY_STATUSES.complete &&
				nullSafeGet('completedAt', workOrder) ? (
					<span style={{ whiteSpace: 'nowrap', color: 'rgba(0,0,0,0.45)', fontSize: 14 }}>
						<span>
							Completed at{' '}
							<DateTimeHover timestamp={nullSafeGet('completedAt', workOrder)} showDate />
						</span>
					</span>
				) : null}
			</div>
		</div>
	);
};

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { assetsRestCrudThunksForBuyer } from '../../thunks/assets_thunks';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import AssetForm from '../asset_form/AssetForm';
const style = require('./EditAssetPage.less');

class EditAssetPage extends React.Component<any, any> {
	state = {
		loading: true,
		assetDetail: {},
	};

	componentDidMount() {
		const { getAsset, match } = this.props;
		const assetId = match.params.id;
		getAsset(assetId).then((asset) => {
			this.setState({
				loading: false,
				assetDetail: asset,
			});
		});
	}

	render() {
		const { history } = this.props;

		//noinspection TypeScriptUnresolvedVariable
		return [
			<LogOnMountWithStandardEventProperties eventType="visited edit asset page" />,
			<EditPage
				title="Edit Asset"
				formComponentProps={{
					formData: this.state.assetDetail,
					onSuccess: (rec) => history.goBack(),
				}}
				fetching={this.state.loading}
				FormComponent={AssetForm}
			/>,
		];
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		history: ownProps.history,
		match: ownProps.match,
	};
};

const mapDispatchToProps = (dispatch) => ({
	getAsset: (id) => dispatch(assetsRestCrudThunksForBuyer.readOne(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditAssetPage));

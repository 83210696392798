import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';
import { PROBLEM_TYPES_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/problem_types_actions';

const initialState: any = standardInitialStateGenerator([
	'WORK_ORDER_FORM_PROBLEM_TYPES_AUTOCOMPLETE',
	'WORK_ORDER_PROBLEM_TYPES_TOP_LEVEL_AUTOCOMPLETE',
	'WORK_ORDER_FORM_PROBLEM_TYPES_NEXT_LEVEL_AUTOCOMPLETE',
	'SUPPLIER_WORK_ORDER_FORM_PROBLEM_TYPES_AUTOCOMPLETE',
	'ASSET_TYPE_FORM_PROBLEM_TYPES_AUTOCOMPLETE',
	'SUPPLIER_PRIVATE_NETWORK_FORM_PROBLEM_TYPES_AUTOCOMPLETE',
	'PLANNED_MAINTENANCE_TEMPLATE_FORM_PROBLEM_TYPES_AUTOCOMPLETE',
	'workOrdersAdvancedFilters',
	'plannedMaintenanceAdvancedFilters',
	'analyticsProblemTypesDropDown',
	'approvalHierarchyProblemTypeDisplay',
	'troubleshootingFormIndex',
	'scorecardAdvancedFilters',
]);
export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState);

import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { WORK_ORDER_ATTRIBUTES_CRUD_ACTION_CREATORS } from '../actions/work_order_attributes_actions';

export const workOrderAttributesRestCrudThunksForBuyer = new RestCrudThunks(
	'work_order_attributes',
	'work_order_attribute',
	'workOrderAttribute',
	'workOrderAttributes',
	WORK_ORDER_ATTRIBUTES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'spend_category/'
);
export const workOrderAttributesRestCrudThunksForSupplier = new RestCrudThunks(
	'work_order_attributes',
	'work_order_attribute',
	'workOrderAttribute',
	'workOrderAttributes',
	WORK_ORDER_ATTRIBUTES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'spend_category/'
);

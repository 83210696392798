import React, { FC } from 'react';
import { Popover } from 'antd';
import { getInitial } from '../../utils/DataFormatterUtils';

const style = require('./FlexibleImageAvatar.less');

interface FlexibleImageAvatarProps {
	displayName: string;
	showPopover: boolean;
	imageUrl: string;
	popoverText: string;
	squareMode?: boolean;
	whiteBackground?: boolean;
	width: string;
	height: string;
}

export const FlexibleImageAvatar: FC<FlexibleImageAvatarProps> = (props) => {
	const {
		displayName,
		squareMode,
		whiteBackground = true,
		showPopover,
		imageUrl,
		popoverText,
		width,
		height,
	} = props;
	if (!displayName && !imageUrl) {
		return null;
	}
	let avatarImageStyle = {
		width: width,
		height: height,
		background: `url("${imageUrl}") ${whiteBackground ? 'white' : ''} no-repeat`,
		backgroundSize: 'contain',
		backgroundPosition: 'center',
	};
	let avatarPlaceholderStyle = {
		width: width,
		height: height,
	};
	if (squareMode) {
		avatarImageStyle['borderRadius'] = 0;
		avatarImageStyle['boxShadow'] = 'none';
		avatarPlaceholderStyle['borderRadius'] = 0;
		avatarPlaceholderStyle['boxShadow'] = 'none';
	}

	if (showPopover) {
		return (
			<Popover content={popoverText}>
				{imageUrl ? (
					<div className="flexibleImageAvatar__image" style={avatarImageStyle} />
				) : (
					<div className="flexibleImageAvatar__initials" style={{ width, height }}>
						{getInitial(displayName)}
					</div>
				)}
			</Popover>
		);
	} else {
		return imageUrl ? (
			<div className="flexibleImageAvatar__image" style={avatarImageStyle} />
		) : (
			<div className="flexibleImageAvatar__initials" style={{ width, height }}>
				{getInitial(displayName)}
			</div>
		);
	}
};

import * as React from 'react';
import { Button } from 'antd';
import { withRouter } from 'react-router';

const style = require('./NotFoundPage.less');

class NotFoundPage extends React.Component<any, any> {
	render() {
		const { history } = this.props;
		const goBack = () => history.goBack();
		return (
			<div className="notFoundPage">
				<img src="https://gw.alipayobjects.com/zos/rmsportal/KpnpchXsobRgLElEozzI.svg" />
				<div className="notFoundPage__textWrapper">
					<h3>Oops! This is awkward.</h3>
					<p className="notFoundPage__body">The page you've requested doesn't exist.</p>
					<p className="notFoundPage__body">
						It might be outdated or removed. The address (URL) might also be incorrect.
					</p>
					<Button type="primary" size="large" onClick={goBack}>
						Go back
					</Button>
				</div>
			</div>
		);
	}
}

export default withRouter(NotFoundPage);

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Card } from 'antd';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { requestsForProposalRestCrudThunksForSupplier } from '../../thunks/requests_for_proposal_thunks';

import { REQUESTS_FOR_PROPOSAL_CRUD_ACTION_CREATORS } from '../../actions/requests_for_proposal_actions';
import { EmptyState } from '../empty_state/EmptyState';
import { RFPRowDisplay } from '../rfp_row_display/RFPRowDisplay';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import * as queryString from 'querystring';

const { Content } = Layout;

const REQUESTS_FOR_PROPOSAL_OVERVIEW_TARGET_COLLECTION = 'requestsForProposalIndex';

const RequestsForProposalIndexPage: FC<any> = ({
	location,
	history,
	accountActivated,
}): React.ReactElement => {
	const [initialPagination, setInitialPagination] = useState<any>({ current: 1, pageSize: 10 });

	useEffect(() => {
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		const { current, pageSize } = queryParams;
		setInitialPagination((iP) => ({
			...iP,
			current: current ? current : iP.current,
			pageSize: pageSize ? pageSize : iP.pageSize,
		}));
	}, [location.search]);

	const columns = useMemo(
		() => [
			{
				title: 'Name',
				dataIndex: 'title',
				render: (text, record) => <RFPRowDisplay rfp={record} />,
			},
		],
		[]
	);

	const onRow = useCallback(
		(record) => ({
			onClick: () => history.push(`/supplier/requestsForProposal/detail/${record.id}`),
		}),
		[history]
	);

	return (
		<Content className="requestsForProposalPage" style={{ padding: '0 0.5em' }}>
			<LogOnMountWithStandardEventProperties eventType="visited supplier rfp index page" />

			<Row style={{ margin: '0.5em -8px' }}>
				<Col span={24}>
					<Card bodyStyle={{ padding: 8 }}>
						{accountActivated ? (
							<PaginatedReduxTable
								updateQueryParams={true}
								emptyState={
									<EmptyState
										graphic={
											<img
												style={{ marginBottom: 8 }}
												src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
												alt="Looks like your team has not created any requests for proposal yet."
											/>
										}
										headline={"It's a little lonely in here."}
										body={
											<div style={{ textAlign: 'center' }}>
												<div style={{ maxWidth: 440, marginBottom: 16 }}>
													Looks like your team has not created any requests for proposal yet.
												</div>
											</div>
										}
									/>
								}
								collectionName="requests_for_proposal"
								targetCollectionName={REQUESTS_FOR_PROPOSAL_OVERVIEW_TARGET_COLLECTION}
								columns={columns}
								onRow={onRow}
								showHeader={false}
								keyAccessor={(el) => el.id}
								initialPagination={initialPagination}
								fetchData={requestsForProposalRestCrudThunksForSupplier.read}
							/>
						) : (
							<EmptyState
								graphic={
									<img
										style={{ marginBottom: 8 }}
										src="https://s3.amazonaws.com/mock-data-assets/categories/images/letter owl.svg"
										alt="Please check your email. We sent you a note with an activation link."
									/>
								}
								headline={"You haven't activated your account yet."}
								body={
									<div style={{ textAlign: 'center' }}>
										<div style={{ maxWidth: 560, marginBottom: 16 }}>
											Please check your email. We sent you a note with an activation link.
										</div>
									</div>
								}
							/>
						)}
					</Card>
				</Col>
			</Row>
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	currentUser: state.session.currentUser,
	accountActivated: state.session.accountActivated,
	requestsForProposal: state.requests_for_proposal,
});

const mapDispatchToProps = (dispatch) => ({
	getRequestsForProposalCountBy: (params, countName) =>
		dispatch(requestsForProposalRestCrudThunksForSupplier.countBy(params, countName)),
	updateRequestForProposalFilters: (filters, targetCollection) =>
		dispatch(REQUESTS_FOR_PROPOSAL_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(RequestsForProposalIndexPage)
);

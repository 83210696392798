import { SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS } from '../actions/supplier_contacts_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { getBackendUri } from '../utils/EnvConfigUtils';
import FetchUtils from './fetch_utils';
import { checkUserTypeToken } from './session_thunks';
import { BUYER_CONTACTS_CRUD_ACTION_CREATORS } from '../actions/buyer_contacts_actions';

export const supplierContactsRestCrudThunksForBuyer = new RestCrudThunks(
	'supplier_contacts',
	'supplier_contact',
	'supplierContact',
	'supplierContacts',
	SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER
);
export const supplierContactsRestCrudThunksForSupplier = new RestCrudThunks(
	'supplier_contacts',
	'supplier_contact',
	'supplierContact',
	'supplierContacts',
	SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER
);

const fetchUtils = new FetchUtils();
const baseUrl = getBackendUri();

export function automaticallyAssociateSupplierContactWithFacility(contact: any) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS.updateStart(contact));

			fetchUtils
				.patch(
					baseUrl +
						`/api/v1/supplier/supplier_contacts/${encodeURIComponent(
							contact.email
						)}/update_facility_based_domain`,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					})
				)
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS.updateError(d.message, contact));
						reject(d.message);
					} else {
						dispatch(SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS.updateSuccess(contact));
						dispatch(checkUserTypeToken(ROLE_TYPES.SUPPLIER)()).then(() => {
							resolve(d.data);
						});
					}
				})
				.catch((d) => {
					dispatch(SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS.updateError(d.message, contact));
					reject(d.message);
				});
		});
	};
}

export function manuallyAssociateSupplierContactWithFacility(contact: any, facilityId: number) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS.updateStart(contact));

			fetchUtils
				.patch(
					baseUrl +
						`/api/v1/supplier/supplier_contacts/${encodeURIComponent(
							contact.email
						)}/update_facility_id_assoc_domain/${encodeURIComponent(facilityId.toString())}`,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					})
				)
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS.updateError(d.message, contact));
						reject(d.message);
					} else {
						dispatch(SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS.updateSuccess(contact));
						dispatch(checkUserTypeToken(ROLE_TYPES.SUPPLIER)());
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS.updateError(d.message, contact));
					reject(d.message);
				});
		});
	};
}

export function supplierSignUpInvite(contact: any) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS.createStart(contact));

			fetchUtils
				.post(
					baseUrl + `/api/v1/supplier/supplier_sign_up_invite/${encodeURIComponent(contact.email)}`,
					{
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: contact,
					}
				)
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS.createError(d.message, contact));
						reject(d.message);
					} else {
						dispatch(SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS.createSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS.createError(d.message, contact));
					reject(d.message);
				});
		});
	};
}

export function supplierRoleUpdateUser(contact: any) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS.createStart(contact));

			fetchUtils
				.post(baseUrl + `/api/v1/supplier/assignRolesToContact`, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: contact,
				})
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS.createError(d.message, contact));
						reject(d.message);
					} else {
						dispatch(SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS.createSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS.createError(d.message, contact));
					reject(d.message);
				});
		});
	};
}
export function adminChangePassword(entity) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS.createStart(entity));

			fetchUtils
				.post(baseUrl + `/api/v1/supplier/admin/changeUserPassword`, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: entity,
				})
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS.createError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS.createSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS.createError(d.message, entity));
					reject(d.message);
				});
		});
	};
}

export function supplierUpdateUserContact(contact: any) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS.createStart(contact));

			fetchUtils
				.post(baseUrl + `/api/v1/supplier/updateContact`, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: contact,
				})
				.then((response) => response.json())
				.then((d) => {
					if (d.status === 'error') {
						dispatch(SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS.createError(d.message, contact));
						reject(d.message);
					} else {
						dispatch(SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS.createSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(SUPPLIER_CONTACTS_CRUD_ACTION_CREATORS.createError(d.message, contact));
					reject(d.message);
				});
		});
	};
}

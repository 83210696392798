import React, { FC, useState } from 'react';
import { Button, Table } from 'antd';
import { bidderStatusTextFormatter } from '../../utils/DataFormatterUtils';
import { Link } from 'react-router-dom';
import { FlexibleImageAvatar } from '../flexible-image-avatar/FlexibleImageAvatar';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { remindBiddersToMakeProposal } from '../../thunks/bidders_thunks';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';

const BidderList: FC<any> = (props) => {
	const [buttonLoading, setButtonLoading] = useState({});
	let { bidders, remindBidders, requestForProposal } = props;
	const bidderColumns = [
		{
			title: 'Name',
			dataIndex: ['supplierFacility', 'name'],
			key: 'supplierFacility.name',
			render: (text, record) => (
				<Link to={`/buyer/suppliers/detail/${record.supplierFacility.id}`}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div style={{ marginRight: 16 }}>
							<FlexibleImageAvatar
								displayName={text}
								showPopover={false}
								popoverText={text}
								width="40px"
								height="40px"
								imageUrl={record.supplierFacility.logoURL}
							/>
						</div>
						<div style={{ fontSize: 18 }}>{text}</div>
					</div>
				</Link>
			),
		},
		{
			title: 'Status',
			dataIndex: 'bidderStatus',
			key: 'bidderStatus',
			render: (text, record, idx) => {
				return (
					<span className={`bidder__statusText ${text}`} style={{ marginRight: 16 }}>
						{bidderStatusTextFormatter(text)}
					</span>
				);
			},
		},
		{
			title: 'Actions',
			dataIndex: 'bidderStatus',
			key: 'bidderStatus',
			render: (text, record, idx) => {
				return text === 'noResponse' &&
					nullSafeGetOrElse('status', requestForProposal, 'draft') === 'active' ? (
					<Button
						loading={buttonLoading[idx] ? buttonLoading[idx] : false}
						onClick={() => {
							setButtonLoading({ [idx]: true });
							remindBidders(record.requestForProposalId, record.supplierFacilityId).then(() =>
								setButtonLoading({ [idx]: false })
							);
						}}
					>
						Send Reminder
					</Button>
				) : null;
			},
		},
	];
	return (
		<Table columns={bidderColumns} dataSource={bidders} pagination={false} showHeader={false} />
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	remindBidders: (id, supplierFacilityId) =>
		dispatch(remindBiddersToMakeProposal(ownProps.userType)(id, supplierFacilityId)),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(BidderList)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(Form.create()(ComponentWithoutUserType));

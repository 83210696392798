import React, { FC, forwardRef, useMemo } from 'react';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { getProtectedImageUriForSupplier } from '../../utils/FileAccessUtils';
import QRCode from 'qrcode.react';

const STOCK_LOCATION_ASSOCIATED_EQUIPMENT_TARGET_COLLECTION = 'stockLocationAssociatedEquipments';

const EquipmentsLabels: FC<any> = forwardRef(
	(
		{
			stockLocation,
			stockLocationsFetching,
			equipmentPerStockLocations,
			equipmentPerStockLocationsFetching,
		},
		ref: any
	): React.ReactElement => {
		const loading = useMemo(
			() => stockLocationsFetching || equipmentPerStockLocationsFetching,
			[equipmentPerStockLocationsFetching, stockLocationsFetching]
		);
		const es = useMemo(
			() =>
				getRecordsForTargetCollection(
					equipmentPerStockLocations,
					STOCK_LOCATION_ASSOCIATED_EQUIPMENT_TARGET_COLLECTION
				),
			[equipmentPerStockLocations]
		);

		return (
			<div ref={ref}>
				{loading ? null : (
					<div className="printEquipmentsWrapper">
						<LogOnMountWithStandardEventProperties eventType="visited supplier print equipment labels page" />
						<div style={{ marginBottom: '2cm' }}>
							<h3
								style={{
									marginBottom: 0,
									display: 'inline-block',
									verticalAlign: 'top',
									fontSize: '24pt',
								}}
							>
								Equipment Labels for {stockLocation.name}
							</h3>
						</div>
						<div className="printEquipmentsInnerWrapper">
							{es.map((e) => {
								let photoUris = [];
								if (e.images) {
									photoUris = e.images.map((equipmentImg) => {
										const [imgFileId, imgFileName] = equipmentImg.split('/');
										return getProtectedImageUriForSupplier(imgFileId, imgFileName, 1200, 800);
									});
								}
								return (
									<div style={{ breakInside: 'avoid', marginBottom: '1cm', width: '100%' }}>
										<div style={{ marginBottom: '12pt' }}>
											<QRCode
												style={{
													display: 'inline-block',
													verticalAlign: 'top',
													margin: '0 12pt 0 0',
												}}
												size={64}
												value={e.equipmentUUID}
											/>
											{photoUris.length > 0 ? (
												// eslint-disable-next-line jsx-a11y/alt-text
												<img
													style={{
														display: 'inline-block',
														verticalAlign: 'top',
													}}
													src={`${photoUris[0]}`}
													width="auto"
													height="64px"
												/>
											) : null}
										</div>
										<div style={{ display: 'inline-block' }}>
											<h4>{nullSafeGet('name', e)}</h4>
											<p style={{ fontSize: '16pt' }}>Serial# {nullSafeGet('serialNumber', e)}</p>
											<p style={{ fontSize: '12pt' }}>
												{nullSafeGet('equipmentType.modelName', e)}{' '}
												{nullSafeGet('equipmentType.manufacturerName', e)}
											</p>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				)}
			</div>
		);
	}
);

export default EquipmentsLabels;

import { Form, Select } from 'antd';
import React from 'react';

export default function MultiEmailComponentAntdV4({ fieldProps, ...props }) {
	const emailValidator = (rule, values, callback) => {
		if (!values) {
			callback();
		}
		const emailRegex = /^([a-zA-Z0-9+_\.-]+)@([\da-zA-Z\.-]+)\.([a-zA-Z\.]{2,6})$/;

		const invalidInputs = values && values.filter((value) => !value.match(emailRegex));
		if (invalidInputs.length === 0 || values === undefined) {
			callback();
		} else if (invalidInputs.length === 1) {
			callback(invalidInputs.join('') + ' is not a valid email');
		} else {
			callback(
				invalidInputs.slice(0, -1).join(', ') +
					' and ' +
					invalidInputs.slice(-1) +
					' are not valid emails'
			);
		}
	};
	return (
		<Form.Item
			{...fieldProps}
			rules={[...(fieldProps.rules || []), { validator: emailValidator }]}
			normalize={(e) => {
				const filteredArray = e.map((item, index) => {
					if (!item.match(/^(.+)@(.+)$/)) {
						return undefined;
					}

					return item.replace(/[<>]/g, '');
				});
				return filteredArray.filter((item) => item);
			}}
		>
			<Select
				tokenSeparators={[',', ' ', ';', ':']}
				optionFilterProp="label"
				placeholder="Enter emails"
				mode="tags"
				open={false}
				allowClear
				{...props}
			/>
		</Form.Item>
	);
}

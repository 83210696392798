import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Alert, Select, Form, Spin, Switch } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { connect } from 'react-redux';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import SupplierRegionsLocationsTreeSelect from '../regions_tree_select/SupplierRegionsLocationsTreeSelect';
import { fetchUserForSupplier } from '../../thunks/users_thunks';
import { ROLES, SUPPLIER_ADMIN, SUPPLIER_ASSIGNABLE_ROLES, SUPPLIER_TECH } from '../roles/roles';
import StockLocationFormFields from './StockLocationFormFields';

const FormItem = Form.Item;

interface SupplierRoleUpdateFormProps extends FormComponentProps {
	onSubmit: any;
	onCancel: any;
	visible: boolean;
	createErrors: any;
	updateErrors: any;
	creating: boolean;
	updating: boolean;
	currentUser: any;
	formData: any;

	locations: any;
	fetchUser: any;
}

const SupplierRoleUpdateForm: FC<SupplierRoleUpdateFormProps> = ({
	visible,
	onCancel,
	onSubmit,
	formData,
	currentUser,
	createErrors,
	updateErrors,
	locations,
	fetchUser,
	creating,
	updating,
}): React.ReactElement => {
	const [form] = Form.useForm();

	const [user, setUser] = useState();
	const [loading, setLoading] = useState(false);

	const email = useMemo(() => nullSafeGet('contact.email', formData), [formData]);
	const { setFieldsValue } = form;
	const roles = Form.useWatch('roles', form);
	const hasAccessToAllLocations = Form.useWatch('hasAccessToAllLocations', form);

	const refreshUser = useCallback(() => {
		setLoading(true);
		fetchUser(email)
			.then((user) => {
				setUser(user);
				setFieldsValue({
					email: nullSafeGet('contact.email', user),
					nameGiven: nullSafeGet('contact.nameGiven', user),
					nameFamily: nullSafeGet('contact.nameFamily', user),
					roles:
						nullSafeGetOrElse('role', user, []).length > 0
							? nullSafeGet('role', user)[0]
							: 'SUPPLIER_USER',
					locationIds:
						nullSafeGet('locationIds', user) === undefined ? [] : nullSafeGet('locationIds', user),
					hasAccessToAllLocations: nullSafeGetOrElse('hasAccessToAllLocations', user, false),
					isSharedContact: nullSafeGetOrElse('contact.isSharedContact', user, false),
					readOnlyAccess: nullSafeGetOrElse('contact.readOnlyAccess', user, false),
				});
			})
			.finally(() => setLoading(false));
	}, [email, fetchUser, setFieldsValue]);

	useEffect(() => email && refreshUser(), [email, fetchUser, refreshUser]);

	useEffect(() => {
		setFieldsValue({ supplierFacilityId: nullSafeGet('facility.id', currentUser) });
	}, [currentUser, setFieldsValue]);

	const handleSubmit = useCallback(
		(values) => {
			const updatedValues = {
				...(user || {}),
				...values,
				...(values.hasAccessToAllLocations && { locationIds: [] }),
			};

			onSubmit && onSubmit(updatedValues);
		},
		[onSubmit, user]
	);

	return (
		<Modal
			visible={visible}
			width={600}
			title={'Edit User Information'}
			okText={'Update'}
			onCancel={onCancel}
			onOk={form.submit}
			closable={false}
			confirmLoading={creating || updating}
		>
			{loading ? (
				<Spin />
			) : (
				<Form
					form={form}
					layout="vertical"
					className="supplierForm"
					preserve={false}
					onFinish={handleSubmit}
				>
					{createErrors.length > 0 ? (
						<FormItem>
							<Alert message={createErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					{updateErrors.length > 0 ? (
						<FormItem>
							<Alert message={updateErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					<FormItem label="Email" name="email">
						<Input style={{ maxWidth: 300 }} disabled={true} />
					</FormItem>
					<FormItem
						label={'Name'}
						className={'flex flex-row'}
						required={true}
						rules={[
							{
								required: true,
								message: 'Please input name.',
							},
						]}
					>
						<div className={'flex flex-row'}>
							<FormItem name={'nameGiven'}>
								<Input placeholder="Given name" style={{ maxWidth: 200 }} disabled={false} />
							</FormItem>
							<FormItem name={'nameFamily'}>
								<Input
									placeholder="Family name"
									style={{ maxWidth: 200, marginLeft: 8 }}
									disabled={false}
								/>
							</FormItem>
						</div>
					</FormItem>
					<FormItem
						label="Roles"
						name="roles"
						required={true}
						rules={[
							{
								required: true,
								message: 'Please select a role',
							},
						]}
					>
						<Select style={{ maxWidth: 200 }}>
							{Array.from(SUPPLIER_ASSIGNABLE_ROLES).map((name, idx) => {
								const entity = ROLES[name];
								return (
									<Select.Option value={name} key={`role-${idx}`}>
										{entity.displayName}
									</Select.Option>
								);
							})}
						</Select>
					</FormItem>
					{roles === SUPPLIER_TECH ? (
						<>
							<div className="flex flex-row">
								<FormItem name="hasAccessToAllLocations" valuePropName="checked">
									<Switch
										checked={!!form.getFieldValue('hasAccessToAllLocations')}
										onChange={(checked) =>
											form.setFieldsValue({ hasAccessToAllLocations: !!checked })
										}
									/>
									<label style={{ marginLeft: 8 }}>Select All Locations</label>
								</FormItem>
							</div>
							<FormItem
								name="locationIds"
								label="Locations"
								rules={[
									{
										type: 'array',
										required: !hasAccessToAllLocations,
										message: 'Please select a location',
									},
								]}
							>
								<SupplierRegionsLocationsTreeSelect
									mode="multiple"
									disabled={hasAccessToAllLocations}
									filtersSlice={locations}
									filtersValueAccessor={() => form.getFieldValue('locationIds')}
									targetCollectionName={'buyerUsersLocations'}
								/>
							</FormItem>
							<StockLocationFormFields user={user} setFieldsValue={setFieldsValue} />
						</>
					) : null}
					<div className="flex flex-row items-baseline">
						<FormItem name="isSharedContact" valuePropName="checked">
							<Switch />
						</FormItem>
						<label className="ml-2">Shared Account</label>
					</div>
					{roles !== SUPPLIER_ADMIN && (
						<div className="flex flex-row items-baseline">
							<FormItem name="readOnlyAccess" valuePropName="checked">
								<Switch />
							</FormItem>
							<label className="ml-2">Read Only Access</label>
						</div>
					)}
				</Form>
			)}
		</Modal>
	);
};

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	createErrors: state.supplier_contacts.createErrors,
	updateErrors: state.supplier_contacts.updateErrors,
	creating: state.supplier_contacts.creating,
	updating: state.supplier_contacts.updating,
	currentUser: state.session.currentUser,
	formData: ownProps.formData,

	locations: state.locations,
});

const mapDispatchToProps = (dispatch) => ({
	fetchUser: (email) => dispatch(fetchUserForSupplier(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupplierRoleUpdateForm);

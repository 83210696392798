import React, { FC, useEffect, useMemo, useState } from 'react';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { Button, Card, Col, Drawer, Layout, Row, Table } from 'antd';
import SubnavBar from '../subnav_bar/SubnavBar';
import { Link } from 'react-router-dom';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import AssetActiveWorkOrdersCard from '../asset_page_display/AssetActiveWorkOrdersCard';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { EmptyState } from '../empty_state/EmptyState';
import { workOrdersRestCrudThunksForSupplier } from '../../thunks/work_orders_thunks';
import { MeterRowDisplay } from '../meter_row_display/MeterRowDisplay';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import AssetDetailsCard from '../asset_details_card/AssetDetailsCard';
import { TwoLineList } from '../two_line_list/TwoLineList';
import { WarrantyCardDisplay } from '../warranty_card_display/WarrantyCardDisplay';
import {
	getProtectedImageUriForBuyer,
	getProtectedImageUriForSupplier,
} from '../../utils/FileAccessUtils';
import { WorkOrderRowDisplay } from '../work_order_row_display/WorkOrderRowDisplay';
import { BUYER_ONLY_NO_STORE_ASSOCIATE } from '../roles/roles';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
	warrantiesRestCrudThunksForBuyer,
	warrantiesRestCrudThunksForSupplier,
} from '../../thunks/warranties_thunks';
import QRCode from 'qrcode.react';
import AssetDetailPageHeader from '../asset_detail_page_header/AssetDetailPageHeader';
import SupplierMetersDetailDetailsPage from '../supplier_meters_detail_details_page/SupplierMetersDetailDetailsPage';
import SupplierLocationCardDisplay from '../supplier_location_card_display/SupplierLocationCardDisplay';
import { isInternalTech } from '../../utils/AuthUtils';
import posthog from 'posthog-js';

const { Content } = Layout;

require('./SupplierAssetDetailPageDisplay.less');

const ASSET_ASSOCIATED_INACTIVE_WORK_ORDERS_TARGET_COLLECTION = 'assetAssociatedInActiveWorkOrders';

const SupplierAssetDetailPageDisplay: FC<any> = ({
	//From Parent
	asset,
	loading = false,
	isDrawer = false,

	//Mapped State to props
	history,
	warranty,
	companyConfig,
	currentUser,
	location,
	userType,

	//mapDispatchToProps
	getWarranty,
}): React.ReactElement => {
	const [meterDrawerVisible, setMeterDrawerVisible] = useState(false);
	const [meterId, setMeterId] = useState();
	const isExternalSupplier = !isInternalTech(currentUser);

	const assetId = useMemo(() => nullSafeGet('id', asset), [asset]);

	const getAssetDetails = () => {
		if (assetId) {
			const warranty = nullSafeGet('warrantyIds.0', asset);

			if (warranty && !isExternalSupplier) {
				getWarranty(warranty);
			}
		}
	};

	useEffect(() => {
		getAssetDetails();
	}, []);

	const handleMeterDrawerOpen = (id) => {
		setMeterDrawerVisible(true);
		setMeterId(id);
	};

	const handleMeterDrawerClose = () => {
		setMeterDrawerVisible(false);
		setMeterId(undefined);
	};

	const removeAssetNumberToQRCodeAssociation = nullSafeGetOrElse(
		'config.assetConfig.removeAssetNumberToQRCodeAssociation',
		companyConfig,
		false
	);

	let photoUris = [];
	if (asset.images) {
		photoUris = asset.images.map((locImg) => {
			const [imgFileId, imgFileName] = locImg.split('/');
			return userType === 'supplier'
				? getProtectedImageUriForSupplier(imgFileId, imgFileName, 1200, 800)
				: getProtectedImageUriForBuyer(imgFileId, imgFileName, 1200, 800);
		});
	}
	const workOrderColumns = [
		{
			title: 'Name',
			dataIndex: 'title',
			render: (text, record) => <WorkOrderRowDisplay workOrder={record} />,
		},
	];

	const onWorkOrderRow = (record) => ({
		onClick: () => history.push(`/${userType}/workOrders/detail/${record.id}`),
	});

	const onMeterRow = (record) => ({
		onClick: () => handleMeterDrawerOpen(record.id),
	});

	const newWorkOrderLink =
		currentUser.roles && currentUser.roles.some((role) => BUYER_ONLY_NO_STORE_ASSOCIATE.has(role))
			? 'newWorkOrder'
			: 'requestService';

	const skipCount = posthog.isFeatureEnabled('OptimiseWorkOrderAPIforLargeData');

	return loading ? (
		<PageLoadingPlaceholder />
	) : (
		<Content
			className="supplierAssetDetailPageDisplay"
			style={{
				padding: '0 0.5em',
			}}
		>
			<LogOnMountWithStandardEventProperties eventType="visited supplier asset detail page" />
			{/*<ScrollToTopOnMount/>*/}
			{/*<BackTop/>*/}

			<Drawer
				width={1000}
				placement="right"
				closable={true}
				onClose={handleMeterDrawerClose}
				visible={meterDrawerVisible}
			>
				<SupplierMetersDetailDetailsPage id={meterId} />
			</Drawer>
			{isDrawer ? (
				<AssetDetailPageHeader
					asset={asset}
					photoUris={photoUris}
					userType={userType}
					isExternalSupplier={isExternalSupplier}
				/>
			) : null}
			{isDrawer ? null : (
				<Row style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<SubnavBar
							left={null}
							right={
								<div className="mb-2 flex flex-row items-center justify-end">
									<Link
										to={getLinkWIthBackLinkParams(
											location,
											'Back to Asset Detail',
											`/${userType}/assets/${assetId}/${newWorkOrderLink}`
										)}
									>
										<Button type="primary" size="large">
											Request Service
										</Button>
									</Link>
								</div>
							}
						/>
					</Col>
				</Row>
			)}
			<Row gutter={16}>
				<Col span={16}>
					{asset.description && asset.description.length > 0 ? (
						<div className="rowSpacing">
							<Card bodyStyle={{ padding: 0 }}>
								<div className="materialCard__body">
									<div className="materialCard__primaryTitle">
										<div className="materialCard__title">{asset.description}</div>
									</div>
									<div
										style={{ marginBottom: 24 }}
										className="richTextWrapper"
										dangerouslySetInnerHTML={{ __html: asset.notes }}
									/>
								</div>
							</Card>
						</div>
					) : null}
					<div className="rowSpacing">
						<AssetActiveWorkOrdersCard assetId={assetId} />
					</div>
					<div className="rowSpacing">
						<Card title="Work History">
							<PaginatedReduxTable
								showHeader={false}
								emptyState={
									<EmptyState
										graphic={
											<img
												style={{ marginBottom: 8 }}
												src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
												alt="There aren't any past work orders."
											/>
										}
										headline={'No news is good news.'}
										body={
											<div style={{ textAlign: 'center' }}>
												<div style={{ maxWidth: 440, marginBottom: 16 }}>
													There aren't any past work orders.
												</div>
											</div>
										}
									/>
								}
								collectionName="work_orders"
								targetCollectionName={ASSET_ASSOCIATED_INACTIVE_WORK_ORDERS_TARGET_COLLECTION}
								columns={workOrderColumns}
								onRow={onWorkOrderRow}
								keyAccessor={(el) => el.id}
								initialFilters={{
									assetId: assetId,
									isActive: false,
									no_count: skipCount,
								}}
								initialPagination={{ current: 1, pageSize: 10 }}
								fetchData={workOrdersRestCrudThunksForSupplier.read}
							/>
						</Card>
					</div>
					<div className="rowSpacing">
						<Card title="Meters">
							<Table
								dataSource={asset.meters}
								showHeader={false}
								pagination={false}
								onRow={onMeterRow}
								columns={[
									{
										title: 'ID',
										dataIndex: 'id',
										render: (text, record) => <MeterRowDisplay meter={record} />,
									},
								]}
							/>
						</Card>
					</div>
				</Col>
				<Col span={8}>
					<div className="rowSpacing">
						<Card title="Asset Label" className="materialCard" bodyStyle={{ padding: 0 }}>
							<div className="materialCard__body" style={{ display: 'flex' }}>
								<div style={{ marginRight: 40 }}>
									{removeAssetNumberToQRCodeAssociation ? (
										asset.isUserAssignedUUID && asset.assetUUID && asset.assetUUID.length > 0 ? (
											<QRCode size={96} value={asset.assetUUID} />
										) : (
											<span>QR code is not assigned</span>
										)
									) : asset.assetUUID && asset.assetUUID.length > 0 ? (
										<QRCode size={96} value={asset.assetUUID} />
									) : null}
								</div>
								{!removeAssetNumberToQRCodeAssociation &&
								(asset.assetNumber || asset.assetNumber !== '') ? (
									<div>
										<div style={{ marginBottom: 8 }}>
											<h3>{asset.assetNumber}</h3>
										</div>
										<div className="twoLineList__secondLine">Asset Number</div>
									</div>
								) : null}
							</div>
						</Card>
					</div>
					<AssetDetailsCard asset={asset} />
					{nullSafeGetOrElse('assetModel.specs', asset, []).length > 0 ? (
						<div className="rowSpacing">
							<Card title="Asset Model Specs" className="materialCard" bodyStyle={{ padding: 0 }}>
								<div className="materialCard__body">
									<TwoLineList
										listItems={nullSafeGet('assetModel.specs', asset)}
										lineOneRender={(record) => record.value}
										lineTwoRender={(record) => record.key}
									/>
								</div>
							</Card>
						</div>
					) : null}
					{nullSafeGet('location', asset) ? (
						<div className="rowSpacing">
							<SupplierLocationCardDisplay
								location={asset.location}
								onViewLocationPage={() =>
									history.push(`/supplier/locations/detail/${nullSafeGet('location.id', asset)}`)
								}
							/>
						</div>
					) : null}
					{asset.warrantyId && !isExternalSupplier ? (
						<div className="rowSpacing">
							<WarrantyCardDisplay warranty={warranty} />
						</div>
					) : null}
				</Col>
			</Row>
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	warrantiesFetching: state.warranties.fetching,
	warranty: nullSafeGet('warrantyIds.0', state.assets.detail) ? state.warranties.detail : undefined,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getWarranty: (id) =>
		dispatch(
			ownProps.userType === 'supplier'
				? warrantiesRestCrudThunksForSupplier.readOne(id)
				: warrantiesRestCrudThunksForBuyer.readOne(id)
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SupplierAssetDetailPageDisplay)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import React, { FC, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { DEPRECIATION_METHODS } from '../../utils/DataConstants';
import { getCurrency, nullSafeGet } from '../../utils/DataAccessUtils';
import { Card, Layout, Table } from 'antd';
import { HorizontalKeyValueDisplay } from '../horizontal_key_value_display/HorizontalKeyValueDisplay';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import { EmptyState } from '../empty_state/EmptyState';

const { Content } = Layout;

const DepreciationClassDetailDetailPage: FC<any> = ({
	depreciationClass,
	currentUser,
	fetching,
	userType,
	history,
	location,
}): React.ReactElement => {
	const currency = useMemo(() => getCurrency({ currentUser }), [currentUser]);

	const renderCurrency = useCallback((value) => currency.format(value), [currency]);

	const getValueForKey = useCallback(
		(key, method) =>
			depreciationClass.depreciationMethod === method ? `${depreciationClass[key]}` : undefined,
		[depreciationClass]
	);

	const depreciationClassDetail = useMemo(
		() => ({
			'Depreciation Method':
				depreciationClass.depreciationMethod === DEPRECIATION_METHODS.DECLINING
					? 'Declining Balance'
					: 'Straight Line',
			...(getValueForKey('depreciationRate', DEPRECIATION_METHODS.DECLINING) && {
				'Depreciation Rate': `${getValueForKey(
					'depreciationRate',
					DEPRECIATION_METHODS.DECLINING
				)}%`,
			}),
			...(depreciationClass.depreciationMethod === DEPRECIATION_METHODS.STRAIGHT_LINE && {
				'Time Period': `${depreciationClass.isVariable ? 'Variable' : 'Fixed'}`,
			}),
			...(depreciationClass.isVariable && {
				'Variable Type': `Location's end of lease`,
			}),
			...(getValueForKey('usefulLifeInMonths', DEPRECIATION_METHODS.STRAIGHT_LINE) && {
				[`Useful Life In Months ${depreciationClass.isVariable ? '(Default)' : ''}`]:
					getValueForKey('usefulLifeInMonths', DEPRECIATION_METHODS.STRAIGHT_LINE),
			}),
			...(getValueForKey('salvageValue', DEPRECIATION_METHODS.STRAIGHT_LINE) && {
				'Salvage Value': renderCurrency(
					getValueForKey('salvageValue', DEPRECIATION_METHODS.STRAIGHT_LINE)
				),
			}),
			...(depreciationClass.depreciationMethod === DEPRECIATION_METHODS.DECLINING && {
				'Depreciation Period': `Monthly`,
			}),
		}),
		[
			depreciationClass.depreciationMethod,
			depreciationClass.isVariable,
			getValueForKey,
			renderCurrency,
		]
	);

	const columns = useMemo(
		() => [
			{
				render: (_, record) => <div className="flex flex-col">{record.name}</div>,
			},
		],
		[]
	);

	const onRow = useCallback(
		(record) => ({
			onClick: () => {
				const path = `/${userType}/assets/templates/detail/${record.id}/details`;
				history.push(getLinkWIthBackLinkParams(location, 'Back to Depreciation Class', path));
			},
		}),
		[history, location, userType]
	);

	return (
		<Content
			style={{
				padding: '0 0.5em',
			}}
		>
			<Card loading={fetching} title="Details">
				<HorizontalKeyValueDisplay
					rowStyle={{ minWidth: '25%' }}
					keyValueStore={depreciationClassDetail}
				/>
			</Card>
			<div className="mt-2">
				<Card title="Associated Asset Types" loading={fetching}>
					{nullSafeGet('0.assetTypes', depreciationClass) ? (
						<EmptyState
							graphic={
								<img
									style={{ marginBottom: 8 }}
									src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
									alt="No asset types are associated with this Class"
								/>
							}
							headline={"It's a little lonely in here."}
							body={
								<div style={{ textAlign: 'center' }}>
									<div style={{ maxWidth: 440, marginBottom: 16 }}>
										No asset types are associated with this Class
									</div>
								</div>
							}
						/>
					) : (
						<Table
							dataSource={depreciationClass.assetTypes}
							showHeader={false}
							onRow={onRow}
							columns={columns}
						/>
					)}
				</Card>
			</div>
		</Content>
	);
};

const mapStateToProps = (state) => ({
	fetching: state.depreciation_classes.fetching,
	depreciationClass: state.depreciation_classes.detail,
});

const DCDetailPage = withRouter<any, any>(
	connect(mapStateToProps)(DepreciationClassDetailDetailPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(DCDetailPage);

import * as React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Alert, Modal, Form } from 'antd';
import { connect } from 'react-redux';
import Result from 'ant-design-pro/lib/Result';
import { changePassword } from '../../thunks/session_thunks';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';

const style = require('./ChangePasswordModal.less');
const FormItem = Form.Item;

function ChangePasswordModal(props) {
	const [form] = Form.useForm();
	const passwordNeedsUpdate = nullSafeGet('currentUser.passwordResetRequired', props);
	const userType = nullSafeGetOrElse('currentUser.contactType', props, 'buyer');
	const [passwordChanged, setPasswordChanged] = useState(false);
	const [confirmDirty, setConfirmDirty] = useState(false);
	const handleSubmit = (e) => {
		form
			.validateFields()
			.then((values) => {
				props.changePassword(userType, values.currentPassword, values.newPassword).then(() => {
					setPasswordChanged(true);
				});
			})
			.catch(() => {});
	};

	const checkPassword = (rule, value, callback) => {
		const otherValue = form.getFieldValue('confirmNewPassword');
		const currentPassword = form.getFieldValue('currentPassword');
		if (value && otherValue && value !== otherValue) {
			callback("The new passwords you entered don't match!");
		} else if (value && currentPassword && value === currentPassword) {
			callback("Your new password can't be the same as your current password.");
		} else {
			callback();
		}
	};

	const checkConfirm = (rule, value, callback) => {
		const otherValue = form.getFieldValue('newPassword');
		if (value && otherValue && confirmDirty && value !== otherValue) {
			callback("The new passwords you entered don't match!");
		} else {
			callback();
		}
	};

	const handleConfirmBlur = (e) => {
		const value = e.target.value;
		setConfirmDirty(confirmDirty || !!value);
		form.validateFields(['confirmNewPassword'], {});
	};

	const formItemLayout = {
		labelCol: {
			sm: { span: 24 },
			md: { span: 8 },
		},
		wrapperCol: {
			sm: { span: 24 },
			md: { span: 14 },
		},
	};
	const formItemLayout2 = {
		wrapperCol: {
			sm: { span: 24 },
			md: { span: 12, offset: 8 },
		},
	};
	const formItemLayout3 = {
		wrapperCol: {
			sm: { span: 24 },
			md: { span: 14, offset: 8 },
		},
	};
	const { loading, errors } = props;

	return passwordNeedsUpdate ? (
		<Modal
			title={'Please update your password'}
			visible={true}
			width={600}
			cancelButtonProps={{ hidden: true }}
			okText={'Update Password'}
			okButtonProps={{ loading: loading, hidden: passwordChanged }}
			onOk={form.submit}
			closable={false}
		>
			{passwordChanged ? (
				<Result
					type="success"
					title={<div>Your password has been reset.</div>}
					description={
						<div>
							<p className="signupForm__successExplanation">
								We've updated your password as requested.
							</p>
						</div>
					}
					actions={
						<div style={{ marginBottom: 24 }}>
							<Link to={`/${userType}/login`}>
								<Button type="primary">Back to log in</Button>
							</Link>
						</div>
					}
				/>
			) : (
				<Form form={form} onFinish={handleSubmit} className="changePasswordForm">
					{errors.length > 0
						? errors.map((error, idx) => (
								<FormItem {...formItemLayout3} key={idx}>
									<Alert message={error} type="error" />
								</FormItem>
						  ))
						: null}
					<FormItem
						{...formItemLayout}
						label="Current Password"
						hasFeedback={true}
						name={'currentPassword'}
						rules={[{ required: true, message: 'Please input your password!' }]}
					>
						<Input type="password" />
					</FormItem>
					<FormItem
						{...formItemLayout}
						label="New Password"
						hasFeedback={true}
						name={'newPassword'}
						rules={[
							{
								required: true,
								message: 'Please input your new password!',
							},
							{
								validator: checkPassword,
							},
						]}
					>
						<Input type="password" />
					</FormItem>
					<FormItem
						{...formItemLayout}
						label="Confirm New Password"
						hasFeedback={true}
						name={'confirmNewPassword'}
						rules={[
							{
								required: true,
								message: 'Please confirm your new password!',
							},
							{
								validator: checkConfirm,
							},
						]}
						validateTrigger={['onBlur', 'onChange', 'onFocus']}
					>
						<Input type="password" onBlur={handleConfirmBlur} />
					</FormItem>
				</Form>
			)}
		</Modal>
	) : null;
}

const mapStateToProps = (state, ownProps) => ({
	loading: state.session.loading,
	errors: state.session.errors,
});

const mapDispatchToProps = (dispatch) => ({
	changePassword: (userType, currentPassword, newPassword) =>
		dispatch(changePassword(userType)(currentPassword, newPassword)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal);

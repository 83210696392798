import React from 'react';
import NotificationsIndexPage from '../notifications_index_page/NotificationsIndexPage';
import { NOTIFICATION_NOTES_TYPES } from '../../utils/NotificationUtils';
import { connect } from 'react-redux';

function NotificationsNotes() {
	return (
		<NotificationsIndexPage
			key={'NotificationsNotes'}
			preAppliedFilters={{
				hasSeen: false,
			}}
			notificationTypes={NOTIFICATION_NOTES_TYPES.map((filter) => filter.key).flat()}
			typeFilters={NOTIFICATION_NOTES_TYPES}
		/>
	);
}

const mapStateToProps = (state, ownProps) => ({
	currentUser: state.session.currentUser,
	userType: state.session.userType,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsNotes);

import { SUPPLIER_SETTINGS_CRUD_ACTION_CREATORS } from '../actions/supplier_settings_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';

export const supplierSettingsRestCrudThunksForSupplier = new RestCrudThunks(
	'supplier_settings',
	'supplier_setting',
	'supplierSetting',
	'supplierSettings',
	SUPPLIER_SETTINGS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false
);

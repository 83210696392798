import React, { FC, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Popover, message } from 'antd';
import {
	downloadEquipmentTypesCSVForSupplier,
	equipmentTypesRestCrudThunksForSupplier,
	fetchAllEquipmentForSupplier,
} from '../../thunks/equipment_types_thunks';
import { EmptyState } from '../empty_state/EmptyState';
import { EquipmentRowDisplay } from '../equipment_row_display/EquipmentRowDisplay';
import { EQUIPMENT_TYPES_CRUD_ACTION_CREATORS } from '../../actions/equipment_types_actions';
import { assetTypesRestCrudThunksForSupplier } from '../../thunks/asset_types_thunks';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import UploadWithSampleModal from '../parts_index_page/UploadWithSampleModal';
import {
	EQUIPMENT_MASTER_FLATFILE_HEADER,
	getFlatfileRecordForEquipment,
} from '../stock_locations_detail_details_page/stock_location_constants';
import moment from 'moment';
import { DATE_FORMAT } from '../../utils/DataFormatterUtils';
import { ExportToCsv } from 'export-to-csv';
import { CSV_EXPORT_DEFAULTS, FILTER_CONFIG_NAMES } from '../../utils/DataConstants';
import { getCurrency, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { getIsActiveFilterField } from '../../utils/FilterUtils';

require('./EquipmentsIndexPage.less');

const EQUIPMENTS_TC = 'equipmentsIndex';

const getMissngEquipmentTypeNumbers = (existing, newList) =>
	existing
		.filter(
			(et) =>
				!newList.find(
					(record) =>
						`${et.id}` === `${record.id}` ||
						nullSafeGetOrElse('equipmentTypeNumber', et, -1) === record.equipmentTypeNumber
				)
		)
		.map((_) => nullSafeGetOrElse('equipmentTypeNumber', _, nullSafeGet('id', _)));

const EquipmentsTemplateIndexPage: FC<any> = ({
	location,
	history,
	updateEquipmentsFilters,
	clearAndUpdateFilters,
	equipmentTypes,
	assetTypes,
	fetchAssetTypes,
	fetchMultipleAssetTypes,
	fetchAllEquipment,
	createBulk,
	fetchEquipmentTypes,
	currentUser,
	exportCSVToEmail,
}): React.ReactElement => {
	const [downloading, setDownloading] = useState(false);
	const [exportingCsv, setExportingCsv] = useState(false);
	const [equipmentTypesForUpload, setEquipmentTypesForUpload] = useState([]);

	const [uploadPopupVisible, setUploadPopupVisible] = useState(false);

	const userCurrency = useMemo(() => getCurrency({ currentUser }).id, [currentUser]);

	const onRow = useCallback(
		(record) => ({
			onClick: () =>
				history.push(
					getLinkWIthBackLinkParams(
						location,
						'Back to Equipment Type',
						`/supplier/equipments/detail/${record.id}`
					)
				),
		}),
		[history, location]
	);

	const getStatSliceProp = useCallback(
		(propName) => nullSafeGetOrElse(`${EQUIPMENTS_TC}.${propName}`, equipmentTypes, {}),
		[equipmentTypes]
	);

	const refreshEquipments = useCallback(() => {
		fetchEquipmentTypes(
			{},
			EQUIPMENTS_TC,
			getStatSliceProp('pagination'),
			getStatSliceProp('sorting'),
			getStatSliceProp('filters')
		);
	}, [fetchEquipmentTypes, getStatSliceProp]);

	const columns = useMemo(
		() => [
			{
				title: 'Name',
				dataIndex: 'modelName',
				sorter: true,
				render: (_, record) => <EquipmentRowDisplay equipmentType={record} />,
			},
		],
		[]
	);

	const filterConfig = useMemo(
		() => [
			{
				label: 'Asset Type',
				fieldName: FILTER_CONFIG_NAMES.ASSET_TYPE,
				mode: 'multiple',
				stateSlice: assetTypes,
				targetCollectionName: 'assetTypesIndex',
				fetch: fetchAssetTypes,
				fetchMultiple: fetchMultipleAssetTypes,
			},
			...[getIsActiveFilterField(updateEquipmentsFilters, EQUIPMENTS_TC, true)],
		],
		[assetTypes, fetchAssetTypes, fetchMultipleAssetTypes, updateEquipmentsFilters]
	);

	const showUploadPopup = useCallback(() => {
		setDownloading(true);
		fetchAllEquipment()
			.then((res) => {
				setEquipmentTypesForUpload(res);
				setUploadPopupVisible(true);
			})
			.finally(() => setDownloading(false));
	}, [fetchAllEquipment]);

	const hideUploadPopup = useCallback(() => setUploadPopupVisible(false), []);

	const exportAsCsv = useCallback(
		(ets) => {
			const date = moment().format(DATE_FORMAT);
			const csvTitle = `Equipment_Master_List_${date}`;
			const csvExporter = new ExportToCsv({
				...CSV_EXPORT_DEFAULTS,
				filename: csvTitle,
				title: csvTitle,
				headers: EQUIPMENT_MASTER_FLATFILE_HEADER([userCurrency]).map((_) => _.label),
			});

			const data =
				ets.length > 0
					? ets.map((_) => getFlatfileRecordForEquipment(_, userCurrency))
					: [getFlatfileRecordForEquipment({}, userCurrency)];
			csvExporter.generateCsv(data);
		},
		[userCurrency]
	);

	const onDownloadSample = useCallback(() => {
		exportAsCsv(equipmentTypesForUpload);
	}, [equipmentTypesForUpload, exportAsCsv]);

	const downloadCSV = useCallback(() => {
		setExportingCsv(true);
		exportCSVToEmail()
			.catch((err) => message.error(err))
			.finally(() => setExportingCsv(false));
	}, [exportCSVToEmail]);

	const supplierFacilityId = useMemo(() => nullSafeGet('facility.id', currentUser), [currentUser]);

	const onDataUpload = useCallback(
		(results) => {
			return new Promise((resolve, reject) => {
				const missingNumbers = getMissngEquipmentTypeNumbers(
					equipmentTypesForUpload,
					results.validData
				);

				if (missingNumbers.length > 0) {
					reject(
						`Some equipment types are missing from existing list. Please include them! Equipment type # ${missingNumbers.join(
							', '
						)} `
					);
				} else {
					createBulk(
						results.validData.map((_) => ({
							..._,
							id: _.id ? parseInt(_.id) : undefined,
							cost: _.cost ? parseFloat(_.cost) : undefined,
							supplierFacilityId,
							images: [],
						}))
					)
						.then((res) => {
							resolve('Updated successfully!');
							setTimeout(() => {
								hideUploadPopup();
								message.success('Updated successfully!');
								refreshEquipments();
							}, 0);
						})
						.catch((err) => {
							reject(err || 'Unable to upload! Please contact support!');
						});
				}
			});
		},
		[createBulk, equipmentTypesForUpload, hideUploadPopup, refreshEquipments, supplierFacilityId]
	);

	return (
		<>
			<PaginatedReduxTableWithHeader
				targetCollectionName={EQUIPMENTS_TC}
				updateFilters={updateEquipmentsFilters}
				stateSlice={equipmentTypes}
				clearAndUpdateFilters={clearAndUpdateFilters}
				filterConfig={filterConfig}
				entityCollectionName="equipment_types"
				tableColumns={columns}
				onTableRow={onRow}
				fetchData={equipmentTypesRestCrudThunksForSupplier.read}
				initialSorters={{
					sort_by: 'modelName',
					order: 'ascend',
				}}
				preAppliedFilters={{ isActive: true }}
				rightActions={
					<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PARTS_AND_EQUIPMENTS}>
						<div className="flex flex-row items-center">
							<Popover content="Download or Upload Equipment Types" trigger="hover">
								<Button
									size="large"
									className="inline-block-visible-md ml-2"
									icon={<UploadOutlined translate="" />}
									loading={downloading}
									onClick={showUploadPopup}
								>
									<span className="inline-block-visible-xxl">Upload</span>
								</Button>
							</Popover>
							<Popover content="Export CSV" trigger="hover">
								<Button
									size="large"
									icon={<DownloadOutlined translate="" />}
									className="inline-block-visible-md ml-2"
									onClick={downloadCSV}
									loading={exportingCsv}
								>
									<span className="inline-block-visible-xxl">Export CSV</span>
								</Button>
							</Popover>
							<Button
								className="ml-2"
								type="primary"
								size="large"
								onClick={() => history.push('/supplier/equipments/overview/new')}
							>
								New Equipment Type
							</Button>
						</div>
					</AccessPermissionChecker>
				}
				emptyState={
					<EmptyState
						graphic={
							<img
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/ficus.svg"
								alt="Add your first equipment"
							/>
						}
						headline={"It's a little lonely in here."}
						body={
							<div style={{ textAlign: 'center' }}>
								<div style={{ maxWidth: 440, marginBottom: 16 }}>
									Looks like your team has not added any equipment yet.
								</div>
								<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PARTS_AND_EQUIPMENTS}>
									<Button
										type="primary"
										onClick={() => history.push('/supplier/equipments/overview/new')}
									>
										Add your first equipment
									</Button>
								</AccessPermissionChecker>
							</div>
						}
					/>
				}
			/>
			{uploadPopupVisible && (
				<UploadWithSampleModal
					type="Equipment Type"
					title="Upload Equipment Types"
					downloadText="Do you want to download the existing Equipment Types List?"
					sampleFileText="Download Equipment Types"
					onCancel={hideUploadPopup}
					onDownloadSample={onDownloadSample}
					fields={EQUIPMENT_MASTER_FLATFILE_HEADER([userCurrency])}
					onDataLoad={onDataUpload}
					showWarningMessage
				/>
			)}
		</>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	equipmentTypes: state.equipment_types,
	assetTypes: state.asset_types,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	fetchEquipmentTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			equipmentTypesRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleAssetTypes: (ids, targetCollectionName) =>
		dispatch(assetTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchAssetTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			assetTypesRestCrudThunksForSupplier.readLite(
				{ ...params, no_pagination: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	updateEquipmentsFilters: (filters, targetCollection) =>
		dispatch(EQUIPMENT_TYPES_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			EQUIPMENT_TYPES_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)
		),
	fetchAllEquipment: (params) => dispatch(fetchAllEquipmentForSupplier(params)),
	createBulk: (entities) => dispatch(equipmentTypesRestCrudThunksForSupplier.createBulk(entities)),
	exportCSVToEmail: (params, filters) =>
		dispatch(downloadEquipmentTypesCSVForSupplier(params, filters)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(EquipmentsTemplateIndexPage)
);

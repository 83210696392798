import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import '@ant-design/compatible/assets/index.css';
import { Layout } from 'antd';
import SupplierClaimSignUpFlowForm from '../supplier_claim_sign_up_flow_form/SupplierClaimSignUpFlowForm';
import * as queryString from 'querystring';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;
const style = require('./SupplierClaimSignUpPage.less');

class SupplierClaimSignUpPage extends React.Component<any, any> {
	render() {
		const { location } = this.props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		const supplierFacilityId = queryParams.supplierFacilityId;

		return (
			<Content className="supplierClaimSignUpPage">
				<LogOnMountWithStandardEventProperties eventType="visited supplier claim signup page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<SupplierClaimSignUpFlowForm formData={{ supplierFacilityId }} />
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(SupplierClaimSignUpPage)
);

import React from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { EmptorTag } from '../emptor_tag/EmptorTag';
import WarrantyDisplay from '../common/WarrantyDisplay';
import { checkIsWarrantyActive, getWarrantyStatus, nullSafeGet } from '../../utils/DataAccessUtils';
import { Button, Typography } from 'antd';
import OfflineTag from '../common/OfflineTag';

require('./AssetRowDisplay.less');
const { Text } = Typography;

export const AssetRowDisplay: React.FC<any> = ({ asset, ...props }) => {
	const warrantyStatus = getWarrantyStatus(nullSafeGet('warranties.0', asset));
	const partWarranty = nullSafeGet('partWarranty', asset);

	return (
		<div className="assetRowDisplay" {...props}>
			<div className="flex flex-col gap-3">
				<div className="assetRowDisplay__rowTitle flex flex-row items-center">
					<Ellipsis tooltip={true} length={56}>
						{asset.name}
					</Ellipsis>
				</div>
				<div className="flex text-gray-500">
					<div className="mr-4">{`Asset ID ${asset.buyerSpecificAssetId || asset.id}`}</div>
				</div>
				<div className="flex text-gray-500">
					<div className="mr-4">
						<span className="rowGrayChip">
							<i className="icons8-font icons8-location-marker mr- mr-1" />
							<span>{nullSafeGet('location.name', asset)}</span>
						</span>
					</div>
					{asset.downtimeStatus === 'offline' ? (
						<div className="mr-4">
							<OfflineTag />
						</div>
					) : null}
					{checkIsWarrantyActive(nullSafeGet('warranties.0', asset)) ? (
						<div className="mr-4">{WarrantyDisplay(warrantyStatus, 'Service')}</div>
					) : null}
					{checkIsWarrantyActive(partWarranty) ? (
						<div className="mr-4">{WarrantyDisplay(getWarrantyStatus(partWarranty), 'Part')}</div>
					) : null}
					{asset.manufacturer ? <div className="mr-4">{asset.manufacturer}</div> : null}
					{asset.modelNumber ? <div className="mr-4">{asset.modelNumber}</div> : null}
					{asset.isActive ? null : (
						<div className="mr-4">
							<Text className={'mr-1'} type={'danger'}>
								Inactive
							</Text>
						</div>
					)}
				</div>
			</div>
			<div className="flex flex-row items-center group-hover:hidden">
				<div className="flex flex-col items-end gap-3">
					{asset.assetNumber && (
						<div className="flex text-gray-500 ">{`Asset Number ${asset.assetNumber}`}</div>
					)}
					<div className="uppercase text-gray-400">{nullSafeGet('assetType.name', asset)}</div>
					<EmptorTag className="assetRowDisplay__pill">
						{asset.totalNotClosedWOCount > 0
							? asset.totalNotClosedWOCount + ' Open WOs'
							: 'No Open WOs'}
					</EmptorTag>
				</div>
			</div>
		</div>
	);
};

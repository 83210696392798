import React from 'react';
import WorkOrderTable from '../WorkOrderTable';
import { BUYER_WO_FILTERS_ORDER } from '../../utils/WorkOrderUtils';

export default function WorkOrdersActiveIndexPage() {
	return (
		<WorkOrderTable
			handleQueryParams
			preAppliedFilters={{ hasBuyerArchived: false }}
			filterConfig={{
				filters: BUYER_WO_FILTERS_ORDER,
			}}
		/>
	);
}

import * as React from 'react';

import { FormInstance } from 'antd/es/form/Form';
import { Input, Button, Form, Layout, Row, Col, Card } from 'antd';
import { connect } from 'react-redux';
import { suppliersRestCrudThunksForSupplier } from '../../thunks/suppliers_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { spendCategoriesRestCrudThunksForSupplier } from '../../thunks/spend_categories_thunks';
import { supplierFacilitiesRestCrudThunksForSupplier } from '../../thunks/supplier_facilities_thunks';
import { manuallyAssociateSupplierContactWithFacility } from '../../thunks/supplier_contacts_thunks';
import { LocationAutocomplete } from '../location_autocomplete/LocationAutocomplete';
import {
	initiateChangeWorkspace,
	checkUserTypeToken,
	retrieveCachedUserDetails,
	addWorkspaceToCurrentUser,
	changeWorkspace,
} from '../../thunks/session_thunks';
import { WORK_ORDERS_CRUD_ACTION_CREATORS } from '../../actions/work_orders_actions';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { getHomePageByRole } from '../../utils/EnvConfigUtils';

const FormItem = Form.Item;

interface SupplierCompanyFormProps extends FormComponentProps {
	history: any;
	creating: boolean;
	updating: boolean;
	minimal: boolean;
	onSubmit: any;
	spendCategories: any;
	formData: any;
	fetchSpendCategories: any;
	fetchMultipleSpendCategories: any;
	refreshCurrentUser: any;
	newAccountSetupMode: boolean;
	currentUser: any;
	associateSupplierContactWithFacility: any;
	getSupplierFacilities: any;
	create: any;
	update: any;
	initiateChangeWorkspace: Function;
	addWorkspaceToCurrentUser: Function;
	changeWorkspace: Function;
}

class SupplierCompanyForm extends React.Component<SupplierCompanyFormProps, any> {
	state = {
		isLoading: false,
	};
	formRef = React.createRef<FormInstance>();

	onFinish = (values) => {
		const {
			spendCategories,
			create,
			addWorkspaceToCurrentUser,
			changeWorkspace,
			currentUser,
			getSupplierFacilities,
		} = this.props;
		const primaryAddress = Object.keys(values)
			.filter((k) => k.indexOf('primaryAddress') > -1)
			.reduce((acc, k) => {
				const [_, x] = k.split('-');
				acc[x] = values[k];
				return acc;
			}, {});

		const spendCategoriesRecordsMap = spendCategories.records;
		const categoryIds = nullSafeGetOrElse('spendCategories', values, []);
		const categoryNames = nullSafeGetOrElse('spendCategories', values, []).map(
			(id) => spendCategoriesRecordsMap[id].name
		);

		const supplier = {
			...values,
			displayName: values.displayName || values.name,
			primaryContactEmail: values.primaryContactEmail || currentUser.email,
			poEmail: values.previouslyClaimed
				? values.poEmail || values.primaryContactEmail
				: values.primaryContactEmail,
			primaryAddress,
			categoryIds,
			categoryNames,
			verifications: [],
			licenses: [],
			certifications: [],
			insurances: [],
			faqs: [],
		};

		this.setState({ isLoading: true });
		create(supplier)
			.then((supplierCompany) => {
				getSupplierFacilities(supplierCompany.id).then((supplierFacilities) => {
					const newSupplierFacility = supplierFacilities.find(
						(facility) =>
							supplierCompany.name === facility.name &&
							Math.abs(supplierCompany.latitude - facility.latitude) < 0.00001 &&
							Math.abs(supplierCompany.longitude - facility.longitude) < 0.00001 //~36 ft
					);
					if (newSupplierFacility) {
						addWorkspaceToCurrentUser(newSupplierFacility.id, ROLE_TYPES.SUPPLIER).then(() => {
							changeWorkspace(
								newSupplierFacility.id,
								ROLE_TYPES.SUPPLIER,
								getHomePageByRole(ROLE_TYPES.SUPPLIER, {})
							);
						});
					}
				});
			})
			.catch((e) => {
				console.log(e);
				this.setState({ isLoading: false });
			});
	};

	handleLocationAutocompleteSelect = (addr, lonLat, parsedAddress) => {
		this.formRef.current!.setFieldsValue({
			'primaryAddress-streetAddress1': parsedAddress.streetAddress1,
			'primaryAddress-city': parsedAddress.city,
			'primaryAddress-region': parsedAddress.region,
			'primaryAddress-postcode': parsedAddress.postcode,
			'primaryAddress-country': parsedAddress.country,
			longitude: lonLat.lng,
			latitude: lonLat.lat,
		});
	};

	render() {
		const { isLoading } = this.state;

		const { minimal } = this.props;
		return (
			<Layout.Content>
				<LogOnMountWithStandardEventProperties eventType="visited supplier new facility creation page" />
				<Row gutter={16}>
					<Col span={24}>
						<Card style={{ overflow: 'visible' }}>
							<div style={{ padding: 24 }}>
								<h4 style={{ marginBottom: 16 }}>Create a new facility</h4>
								<p style={{ fontSize: 18, marginBottom: 16 }}>
									Creating a new facility will enable you to switch b/w them seamlessly with same
									account and manage them.
								</p>
								<p style={{ fontSize: 16, marginBottom: 32 }}>
									Lets start with some basic details about the new facility you want to create.
								</p>
								<Form
									initialValues={{ status: 'active' }}
									ref={this.formRef}
									layout="vertical"
									onFinish={this.onFinish}
								>
									<Form.Item
										label="facility name"
										name="name"
										rules={[{ required: true, message: 'Your facility needs a name!' }]}
									>
										<Input placeholder="Acme Plumbing, Inc." style={{ maxWidth: 500 }} />
									</Form.Item>
									<Form.Item required={true} label="Address">
										<LocationAutocomplete
											size={minimal ? 'large' : undefined}
											placeholder="9035 Village Dr, Yosemite Valley, CA 95389"
											onSelect={this.handleLocationAutocompleteSelect}
										/>
									</Form.Item>
									<FormItem
										label="facility phone number"
										name="primaryPhone"
										rules={[
											{
												required: true,
												message: 'What phone number do you want your customers to call?',
											},
										]}
									>
										<Input
											placeholder="(209) 372-0200"
											style={{ maxWidth: 350 }}
											size={minimal ? 'large' : undefined}
										/>
									</FormItem>
									<Form.Item name="primaryAddress-streetAddress1" hidden />
									<Form.Item name="primaryAddress-city" hidden />
									<Form.Item name="primaryAddress-region" hidden />
									<Form.Item name="primaryAddress-postcode" hidden />
									<Form.Item name="primaryAddress-country" hidden />
									<Form.Item name="longitude" hidden />
									<Form.Item name="latitude" hidden />
									<Form.Item name="status" hidden />
									<FormItem>
										<Button
											type="primary"
											htmlType="submit"
											loading={isLoading}
											className="supplierForm__button"
										>
											Create new facility
										</Button>
									</FormItem>
								</Form>
							</div>
						</Card>
					</Col>
				</Row>
			</Layout.Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	currentUser: state.session.currentUser,
	minimal: ownProps.minimal,
	spendCategories: state.spend_categories,
});

const mapDispatchToProps = (dispatch) => ({
	saveFormData: (formData) => dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.saveFormData(formData)),
	getSupplierFacilities: (supplierCompanyId) =>
		dispatch(supplierFacilitiesRestCrudThunksForSupplier.read({ supplierCompanyId })),
	fetchSpendCategories: (params, targetCollectionName) =>
		dispatch(spendCategoriesRestCrudThunksForSupplier.read(params, targetCollectionName)),
	fetchMultipleSpendCategories: (ids, targetCollectionName) =>
		dispatch(spendCategoriesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	create: (entity) => dispatch(suppliersRestCrudThunksForSupplier.create(entity)),
	update: (entity) => dispatch(suppliersRestCrudThunksForSupplier.update(entity)),
	associateSupplierContactWithFacility: (contact, facilityId) =>
		dispatch(manuallyAssociateSupplierContactWithFacility(contact, facilityId)),
	refreshCurrentUser: () => dispatch(checkUserTypeToken(ROLE_TYPES.SUPPLIER)()),
	initiateChangeWorkspace: (id) => dispatch(initiateChangeWorkspace(id)),
	addWorkspaceToCurrentUser: (facilityId, userType) =>
		dispatch(addWorkspaceToCurrentUser(facilityId, userType)),
	changeWorkspace: (id, userType, landingURL) =>
		dispatch(changeWorkspace(id, userType, landingURL)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupplierCompanyForm);

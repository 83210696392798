import * as React from 'react';
import { List } from 'antd';
import { connect } from 'react-redux';
import { PaginationProps } from 'antd/es/pagination';
import { getEntitiesById } from '../../utils/DataAccessUtils';
import { isEqual } from 'lodash';
import { ListItemLayout } from 'antd/lib/list';

interface ReduxListProps {
	collectionName: string;
	targetCollectionName: string;
	data: any[];
	listItemRenderFunction: any;
	additionalParams: any;
	filters: any;
	sorting: any;
	initialPagination: PaginationProps;
	initialFilters: any;
	initialSorting: { sort_by: string; order: string } | {};
	pagination: PaginationProps;
	itemLayout: ListItemLayout;
	fetching: boolean;
	fetchData: (
		params: any,

		targetCollectionName: string | null,
		pagination: any | null,
		sorting: { sort_by: string; order: string } | {},
		filters: any
	) => any;
}

class ReduxList extends React.Component<ReduxListProps, any> {
	constructor(props) {
		super(props);
		this.handlePaginationChange = this.handlePaginationChange.bind(this);
	}

	handlePaginationChange(newCurrentPage) {
		const {
			fetchData,
			targetCollectionName,
			pagination,
			filters,
			sorting,
			additionalParams = {},
		} = this.props;
		const newPagination = {
			...pagination,
			current: newCurrentPage === undefined ? 1 : newCurrentPage,
		};
		fetchData(additionalParams, targetCollectionName, newPagination, sorting, filters);
	}

	componentDidMount() {
		const {
			fetchData,
			targetCollectionName,
			initialPagination,
			initialFilters,
			initialSorting,
			additionalParams,
		} = this.props;
		fetchData(
			additionalParams || {},
			targetCollectionName,
			initialPagination,
			initialSorting,
			initialFilters
		);
	}

	componentWillReceiveProps(nextProps) {
		const { fetchData, fetching, targetCollectionName, additionalParams, filters, sorting } =
			this.props;
		const filtersChanged = !isEqual(nextProps.filters, filters);
		const sortingChanged = !isEqual(nextProps.sorting, sorting);
		const additionalParamsChanged = !isEqual(nextProps.additionalParams, additionalParams);
		const propsChanged = filtersChanged || sortingChanged || additionalParamsChanged;
		if (propsChanged && !fetching) {
			fetchData(
				nextProps.additionalParams || {},
				targetCollectionName,
				nextProps.pagination,
				nextProps.sorting,
				nextProps.filters
			);
		}
	}

	render() {
		const { data, pagination, listItemRenderFunction, fetching, itemLayout } = this.props;
		return (
			<List
				itemLayout={itemLayout}
				dataSource={data}
				renderItem={listItemRenderFunction}
				pagination={{
					...pagination,
					onChange: this.handlePaginationChange,
				}}
				loading={fetching}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const { targetCollectionName, collectionName } = ownProps;
	const relevantState = state[collectionName];
	if (!relevantState) {
		throw new Error(
			'collection not found in root reducer. please check name for typos or add reducer for collection'
		);
	}
	if (targetCollectionName && !relevantState[targetCollectionName]) {
		throw new Error(
			'target collection not found in root reducer. please check name for typos or add reducer for collection'
		);
	}
	let mappedProps = {
		collectionName: ownProps.collectionName,
		targetCollectionName: ownProps.targetCollectionName,
		listItemRenderFunction: ownProps.listItemRenderFunction,
		itemLayout: ownProps.itemLayout,
		data: null,
		pagination: null,
		filters: null,
		sorting: null,
		fetching: null,
	};

	if (targetCollectionName) {
		mappedProps.data = getEntitiesById(
			relevantState[ownProps.targetCollectionName].recordsIds,
			relevantState.records
		);
		mappedProps.pagination = relevantState[ownProps.targetCollectionName].pagination;
		mappedProps.filters = relevantState[ownProps.targetCollectionName].filters;
		mappedProps.sorting = relevantState[ownProps.targetCollectionName].sorting;
		mappedProps.fetching = relevantState[ownProps.targetCollectionName].fetching;
	} else {
		mappedProps.data = getEntitiesById(relevantState.recordsIds, relevantState.records);
		mappedProps.pagination = relevantState.pagination;
		mappedProps.filters = relevantState.filters;
		mappedProps.sorting = relevantState.sorting;
		mappedProps.fetching = relevantState.fetching;
	}

	return mappedProps;
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchData: (params, targetCollectionName, pagination, sorting, filters) => {
		dispatch(ownProps.fetchData(params, targetCollectionName, pagination, sorting, filters));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxList);

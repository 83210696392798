import * as React from 'react';
import { Card, Spin } from 'antd';
import { FC } from 'react';

export const NewPage: FC<any> = ({ title, fetching, FormComponent, formComponentProps }) => {
	return fetching ? (
		<div
			style={{
				minHeight: '100vh',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Spin size="large" />
		</div>
	) : (
		<div style={{ margin: '24px 24px 0' }}>
			<h4 style={{ marginBottom: 24 }}>{title}</h4>
			<Card bordered={false}>
				<FormComponent {...formComponentProps} />
			</Card>
		</div>
	);
};

import { Modal } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { EmptyState } from '../empty_state/EmptyState';
import { requestedPartsRestCrudThunksForSupplier } from '../../thunks/requested_parts_thunks';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import { stopDefaultEvents } from '../../utils/DataAccessUtils';
import { WorkOrderRowDisplay } from '../work_order_row_display/WorkOrderRowDisplay';

const REQUESTED_PARTS_TC = 'requestedPartsIndex';

const RequestedQuantityWorkOrdersModal = ({
	ppslId,
	partsPerStockLocation,
	onCancel,
	history,
	location,
	backText,
}): React.ReactElement => {
	const requestedPartsColumns = useMemo(
		() => [
			{
				dataIndex: ['workOrder'],
				render: (_) => <WorkOrderRowDisplay workOrder={_} simpler />,
			},
		],
		[]
	);

	const onRow = useCallback(
		(record) => ({
			onClick: (e) => {
				stopDefaultEvents(e);
				history.push(
					getLinkWIthBackLinkParams(
						location,
						backText,
						`/supplier/workOrders/detail/${record.workOrderId}/charges`
					)
				);
			},
		}),
		[backText, history, location]
	);

	return (
		<Modal
			visible={true}
			width={600}
			title="Requested Work Orders"
			closable
			onCancel={onCancel}
			footer={null}
		>
			<PaginatedReduxTable
				mode="list"
				emptyState={
					<EmptyState
						graphic={
							<img
								alt="No requested parts found"
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
							/>
						}
						headline="No requested parts found"
						body={null}
					/>
				}
				onRow={onRow}
				collectionName="requested_parts"
				targetCollectionName={REQUESTED_PARTS_TC}
				columns={requestedPartsColumns}
				showHeader={false}
				keyAccessor={(el) => el.id}
				initialFilters={{
					partsPerStockLocationId: ppslId || partsPerStockLocation.id,
				}}
				fetchData={requestedPartsRestCrudThunksForSupplier.read}
			/>
		</Modal>
	);
};

export default withRouter<any, any>(connect()(RequestedQuantityWorkOrdersModal));

import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Switch, Input, Alert, Button, Select } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import BackButton from '../back_button/BackButton';
import { fieldTechsRestCrudThunksForSupplier } from '../../thunks/field_techs_thunks';
import ProblemTypeTreeSelect from '../problem_type_tree_select';
import { problemTypesHeirarchicalForSupplier } from '../../thunks/problem_types_thunks';

require('./FieldTechForm.less');

interface FieldTechFormProps extends FormComponentProps {
	creating: boolean;
	updating: boolean;
	formData: any;
	history: any;
	redirectForwardUrl?: string;
	onSuccess?: any;
	createErrors: any[];
	updateErrors: any[];
	currentUser: any;
	create: any;
	update: any;
	problemTypes: any;
	fetchProblemTypes: any;
}

class FieldTechForm extends React.Component<FieldTechFormProps, any> {
	state = {
		canAccessAllProblemTypes: false,
	};

	componentDidMount() {
		const { formData } = this.props;

		const canAccessAllProblemTypes = nullSafeGetOrElse('problemTypeIds', formData, []).length === 0;
		this.setState({ canAccessAllProblemTypes });
	}

	handleSubmit = (e, submitAction) => {
		const { history, onSuccess, redirectForwardUrl } = this.props;
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const fieldTech = {
					...values,
					problemTypeIds: this.state.canAccessAllProblemTypes ? [] : values.problemTypeIds,
					hourlyRate: values.hourlyRate ? parseFloat(values.hourlyRate) : undefined,
				};

				submitAction(fieldTech).then((record) => {
					if (redirectForwardUrl) {
						history.push(redirectForwardUrl);
					}
					if (onSuccess) {
						onSuccess(record);
					}
				});
			}
		});
	};

	onProblemTypeToggle = (val) => {
		this.setState({ canAccessAllProblemTypes: val });
	};

	render() {
		const {
			updating,
			creating,
			form,
			update,
			create,
			createErrors,
			formData,
			updateErrors,
			currentUser,
			problemTypes,
			fetchProblemTypes,
		} = this.props;
		const { getFieldDecorator } = form;
		const isUpdate = formData && formData.email !== undefined;
		const submitAction = isUpdate ? update : create;
		const submitText = isUpdate ? 'Update field technician' : 'Create field technician';
		const isLoading = updating || creating;

		getFieldDecorator('supplierFacilityId', {
			initialValue: formData.supplierFacilityId || nullSafeGet('facility.id', currentUser),
		});
		getFieldDecorator('supplierCompanyId', {
			initialValue:
				formData.supplierCompanyId || nullSafeGet('facility.supplierCompanyId', currentUser),
		});

		getFieldDecorator('id', { initialValue: formData.id });

		return (
			<Form
				layout="vertical"
				onSubmit={(e) => this.handleSubmit(e, submitAction)}
				className="supplierForm"
			>
				{createErrors.length > 0 ? (
					<Form.Item>
						<Alert message={createErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				{updateErrors.length > 0 ? (
					<Form.Item>
						<Alert message={updateErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				<Form.Item label="Email">
					{getFieldDecorator('email', {
						initialValue: formData.email,
						rules: [{ required: true, message: 'This field is required.' }],
					})(<Input style={{ maxWidth: 640 }} disabled={formData.email ? true : false} />)}
				</Form.Item>
				<Form.Item label="Name">
					{getFieldDecorator('name', {
						initialValue: formData.name,
						rules: [{ required: true, message: 'This field is required.' }],
					})(<Input style={{ maxWidth: 640 }} disabled={formData.name ? true : false} />)}
				</Form.Item>
				<Form.Item label="Role">
					{getFieldDecorator('role', {
						initialValue: formData.role,
					})(<Input style={{ maxWidth: 640 }} />)}
				</Form.Item>
				<Form.Item label="Problem Types">
					{getFieldDecorator('hasAccessToAllProblemTypes', {
						initialValue: this.state.canAccessAllProblemTypes,
						valuePropName: 'checked',
					})(
						<>
							<Switch
								checked={this.state.canAccessAllProblemTypes}
								onChange={this.onProblemTypeToggle}
							/>
							<label style={{ marginLeft: 8 }}>Select All Problem Types</label>
						</>
					)}
				</Form.Item>
				<Form.Item>
					{getFieldDecorator('problemTypeIds', {
						initialValue: formData.problemTypeIds,
					})(
						<ProblemTypeTreeSelect
							style={{ maxWidth: 640 }}
							allowClear={true}
							disabled={this.state.canAccessAllProblemTypes}
							mode="multiple"
							stateSlice={problemTypes}
							targetCollectionName="analyticsProblemTypesDropDown"
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) =>
								fetchProblemTypes(
									{ name: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								)
							}
							fetchMultiple={() => {}}
							renderRecord={(pt) => (
								<Select.Option key={pt.id} value={pt.id}>
									{pt.name}
								</Select.Option>
							)}
						/>
					)}
				</Form.Item>
				<Form.Item label="Notes">
					{getFieldDecorator('notesOnTech', {
						initialValue: formData.notesOnTech,
					})(<Input.TextArea style={{ maxWidth: 640 }} />)}
				</Form.Item>
				<Form.Item label="Hourly Rate">
					{getFieldDecorator('hourlyRate', {
						initialValue: formData.hourlyRate,
					})(<Input style={{ maxWidth: 640 }} />)}
				</Form.Item>
				<Form.Item label="Available for work?">
					{getFieldDecorator('isAvailable', {
						initialValue: formData.isAvailable === undefined ? false : formData.isAvailable,
						valuePropName: 'checked',
					})(<Switch />)}
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						loading={isLoading}
						className="contactForm__button"
					>
						{submitText}
					</Button>
					<span style={{ marginLeft: '16px' }}>
						<BackButton buttonText="Cancel" />
					</span>
				</Form.Item>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	formData: ownProps.formData,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,

	createErrors: state.field_techs.createErrors,
	updateErrors: state.field_techs.updateErrors,
	creating: state.field_techs.creating,
	updating: state.field_techs.updating,
	currentUser: state.session.currentUser,
	problemTypes: state.problem_types,
});

const mapDispatchToProps = (dispatch) => ({
	update: (entity) => dispatch(fieldTechsRestCrudThunksForSupplier.update(entity)),
	create: (entity) => dispatch(fieldTechsRestCrudThunksForSupplier.create(entity)),
	fetchProblemTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) => {
		return dispatch(
			problemTypesHeirarchicalForSupplier(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		);
	},
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Form.create<FieldTechFormProps>()(FieldTechForm))
);

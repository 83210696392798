export const FETCH_ALL_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const fetchAllMessagesSuccess = (channelId, messages) => ({
	type: FETCH_ALL_MESSAGES_SUCCESS,
	messages,
	channelId,
});

export const SOMEONE_STARTED_TYPING = 'SOMEONE_STARTED_TYPING';
export const someoneStartedTyping = (channelId, message) => ({
	type: SOMEONE_STARTED_TYPING,
	message,
	channelId,
});

export const SOMEONE_STOPPED_TYPING = 'SOMEONE_STOPPED_TYPING';
export const someoneStoppedTyping = (channelId) => ({
	type: SOMEONE_STOPPED_TYPING,
	channelId,
});

export const FETCH_ALL_MESSAGES_START = 'FETCH_MESSAGES_START';
export const fetchAllMessagesStart = (channelId) => ({
	type: FETCH_ALL_MESSAGES_START,
	channelId,
});

export const FETCH_ALL_MESSAGES_ERROR = 'FETCH_MESSAGES_ERROR';
export const fetchAllMessagesError = (error, channelId) => ({
	type: FETCH_ALL_MESSAGES_ERROR,
	channelId,
	error,
});

export const RECEIVE_NEW_MESSAGE = 'RECEIVE_NEW_MESSAGE';
export const receiveNewMessage = (channelId, message) => ({
	type: RECEIVE_NEW_MESSAGE,
	message,
	channelId,
});

export const RECEIVE_LOADING_MESSAGE = 'RECEIVE_LOADING_MESSAGE';
export const receiveLoadingMessage = (channelId, message) => ({
	type: RECEIVE_LOADING_MESSAGE,
	message,
	channelId,
});

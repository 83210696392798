import { Button, Col, List, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import ProblemTypeTreeSelect from '../problem_type_tree_select';

import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import ProblemTypeItem from './ProblemTypeItem';
import { CopyOutlined } from '@ant-design/icons';
import CopyRankingComponent from './CopyRankingComponent';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';

export default function ProblemTypesList({
	fetchSupplierPrivateNetworksForLocation,
	fetchProblemTypes,
	selected,
	problemTypes,
	fetchSupplierPrivateNetworkV2,
	updateRank,
	selectedLocation,
}) {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(1);
	const [isCopying, setIsCopying] = useState(false);

	const getData = () => {
		setLoading(true);
		fetchSupplierPrivateNetworksForLocation(selected.id, { offset: 0, limit: 10 }).then(
			({ data, count }) => {
				setLoading(false);
				setData(data);
				setTotal(count);
				setPage(1);
			}
		);
	};
	useEffect(() => {
		if (selected.id) {
			getData();
		}
	}, [selected.id, fetchSupplierPrivateNetworksForLocation]);

	return (
		<div className="h-full p-6" id="ProblemTypeListComponent">
			<CopyRankingComponent
				visible={isCopying}
				onCancel={() => setIsCopying(false)}
				selectedLocation={selected}
				onSuccess={() => {
					getData();
					setIsCopying(false);
				}}
			/>
			<Row className="mb-5 ">
				<Col span={12}>
					<div className="mb-5 text-2xl text-black">{selected.name}</div>
				</Col>

				<Col span={12}>
					<Row justify="end">
						<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_SUPPLIERS}>
							<Button
								onClick={() => setIsCopying(true)}
								type="ghost"
								shape="default"
								icon={<CopyOutlined />}
								size="large"
							>
								Copy from another location
							</Button>
						</AccessPermissionChecker>
					</Row>
				</Col>
				<Col span={12} className="pr-4">
					<ProblemTypeTreeSelect
						key={selected.id}
						style={{ width: '100%' }}
						stateSlice={problemTypes}
						targetCollectionName={'workOrdersAdvancedFilters'}
						fetchData={(
							searchText,
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						) =>
							fetchProblemTypes(
								{ name: searchText },
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							)
						}
						onChange={(value) => {
							setLoading(true);
							fetchSupplierPrivateNetworksForLocation(selected.id, {
								problemTypeId: value,
								offset: 0,
								limit: 10,
							}).then(({ data, count }) => {
								setLoading(false);
								setData(data);
								setTotal(count);
								setPage(1);
								document.getElementById('ProblemTypeListComponent').scrollIntoView();
							});
						}}
						allowClear
						placeholder={'Select Problem Types'}
						renderRecord={(problemType) => (
							<Select.Option key={problemType.id} value={problemType.id}>
								{problemType.hierarchyName}
							</Select.Option>
						)}
					/>
				</Col>
				<Col span={12}></Col>
			</Row>

			{(loading && <PageLoadingPlaceholder />) || (
				<List
					key={selected.id}
					pagination={{
						current: page,
						pageSize: 10,
						total,
						onChange: (current) => {
							setPage(current);
							setLoading(true);
							document.getElementById('ProblemTypeListComponent').scrollIntoView();
							fetchSupplierPrivateNetworksForLocation(selected.id, {
								offset: (current - 1) * 10,
								limit: 10,
							}).then(({ data, count }) => {
								setLoading(false);
								setData(data);
								setTotal(count);
								document.getElementById('ProblemTypeListComponent').scrollIntoView();
							});
						},
						showSizeChanger: false,
					}}
					loading={loading}
					itemLayout="horizontal"
					dataSource={data}
					renderItem={(item: any, index) => (
						<ProblemTypeItem
							refreshData={getData}
							key={`${selected.id}_${item.problemTypeId}_${index}`}
							data={item}
							updateRank={updateRank}
							fetchSupplierPrivateNetworkV2={fetchSupplierPrivateNetworkV2}
							selectedLocation={selected}
						/>
					)}
				/>
			)}
		</div>
	);
}

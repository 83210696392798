import React, { FC } from 'react';
import { dateFormatter, optionalFilter } from '../../utils/DataFormatterUtils';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { INVOICE_STATUS_DISPLAY_NAMES } from '../../constants/InvoiceStatuses';
import { SupplierFacilityName } from '../name_component/supplierFacility/supplierFacilityNameComponent';
import DetailRow from '../detail_row/DetailRow';
import { Row } from 'antd';
import DateTimeHover from '../date_time_component/DateTime';

const dateFormat = 'MMM D, YYYY';

const InvoicesAdditionalDetails: FC<any> = ({
	invoice,
	contactType = 'buyer',
	passthroughItems = [],
}): React.ReactElement => {
	const detailItems = [
		{ key: 'Status', value: INVOICE_STATUS_DISPLAY_NAMES[invoice.status] },
		{
			key: 'Work Order #',
			value: optionalFilter(
				nullSafeGet('workOrderId', invoice) ? `${nullSafeGet('workOrderId', invoice)}` : null
			),
		},
		{
			key: 'Invoice #',
			value: optionalFilter(nullSafeGet('invoiceNumber', invoice)),
		},
		{
			key: 'Payment Terms',
			value: optionalFilter(invoice.paymentTerms),
		},
		{
			key: 'Due Date',
			value: (
				<DateTimeHover
					timestamp={nullSafeGet('dueDate', invoice)}
					showDate={true}
					showTime={false}
				/>
			),
		},
		...passthroughItems,
	];

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: 20,
				width: '100%',
				margin: '20px 0px',
			}}
		>
			<Row>
				<SupplierFacilityName
					facility={nullSafeGet('supplierFacility', invoice)}
					mode={'card'}
					placement={'top'}
					allowHover={false}
				/>
			</Row>
			{invoice.scope && invoice.scope.length > 0 ? (
				<div style={{ padding: 0 }} className="materialCard__body">
					<div className="materialCard__primaryTitle">
						<div
							style={{ fontSize: 16 }}
							className="richTextWrapper"
							dangerouslySetInnerHTML={{ __html: invoice.scope }}
						/>
					</div>
				</div>
			) : null}
			<DetailRow details={detailItems} />
		</div>
	);
};

export default InvoicesAdditionalDetails;

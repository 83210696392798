import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router';
import { Layout } from 'antd';
import ScrollToTopOnMount from '../scroll_to_top_on_mount/ScrollToTopOnMount';
import SubnavTabs from '../subnav_tabs/SubnavTabs';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';
import { nullSafeGet } from '../../utils/DataAccessUtils';

const { Content } = Layout;

const style = require('./SiteSurveysOverviewPage.less');

const DATE_FORMAT = 'MMM D, YYYY';

class SiteSurveysPage extends React.Component<any, any> {
	handleTabChange = (key) => {
		const { history } = this.props;
		history.push(`/buyer/siteSurveys/overview/${key}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	render() {
		const { companyConfig } = this.props;
		const tabs = [
			{
				key: 'all',
				name: 'All',
			},
			{
				key: 'templates/overview',
				name: 'Templates',
			},
		];

		return (
			<div>
				<ScrollToTopOnMount />

				<div>
					<Route
						path="/buyer/siteSurveys/overview"
						exact={true}
						render={(props) => (
							<Redirect
								to={{
									pathname: `/buyer/siteSurveys/overview/all`,
								}}
							/>
						)}
					/>
					<Content className="siteSurveysPage" style={{ padding: '0 0.5em' }}>
						{/*<ScrollToTopOnMount/>*/}
						{/*<BackTop/>*/}

						<div
							style={{
								margin: '0 -8px',
								padding: '24px',
								paddingBottom: 0,
								background: nullSafeGet('detail.config.theme.navblock', companyConfig),
								marginBottom: 8,
							}}
						>
							<h4
								style={{
									color: 'rgba(255, 255, 255, 0.9)',
									marginRight: 8,
									marginBottom: 24,
								}}
							>
								Site Surveys
							</h4>
							<SubnavTabs
								tabs={tabs}
								onChange={this.handleTabChange}
								isActive={this.checkTabActive}
							/>
						</div>
						{this.props.childRoutes.map((r, i) => (
							<RouteWithSubRoutes key={i} {...r} />
						))}
					</Content>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	companyConfig: state.company_config,

	currentUser: state.session.currentUser,
	siteSurveys: state.site_surveys,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(SiteSurveysPage));

import moment from 'moment';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';

interface IdefaultFiscalEnd {
	month: number;
	date: number;
}

const defaultFiscalEnd: IdefaultFiscalEnd = {
	month: 7,
	date: 31,
};

const toTwoDigits = (number) => (number > 9 ? `${number}` : `0${number}`);

const getEndDate = (year, fiscal) => {
	return moment(`${year}${toTwoDigits(fiscal.month)}${toTwoDigits(fiscal.date)}`, 'YYYYMMDD');
};

const getFiscalRangeForYear = (year, fiscal) => {
	const endDate = getEndDate(year, fiscal);
	const startDate = endDate.clone().subtract(1, 'years').add(1, 'days');
	return {
		year,
		startDate,
		endDate,
		rate: 0,
	};
};

export const getDefaultRate = (fiscal) => {
	const currentYear = moment().year();
	const currentYearEndDate = getEndDate(currentYear, fiscal);
	const calCulatedYear = moment().isAfter(currentYearEndDate) ? currentYear + 1 : currentYear;

	return getFiscalRangeForYear(calCulatedYear, fiscal);
};

const getCurrentBurdenRateFromStockLocation = (stockLocation, fiscal) => {
	const currentBR = getDefaultRate(fiscal);
	const slBRs = nullSafeGetOrElse('burdenRates', stockLocation, []);
	return slBRs.find((_) => _.year === currentBR.year) || currentBR;
};

export const getCurrentTaxRateFromStockLocation = (stockLocation, fiscal) => {
	const currentTR = getDefaultRate(fiscal);
	const slTRs = nullSafeGetOrElse('taxRates', stockLocation, []);
	return slTRs.find((_) => _.year === currentTR.year) || currentTR;
};

export {
	defaultFiscalEnd,
	toTwoDigits,
	getFiscalRangeForYear,
	getCurrentBurdenRateFromStockLocation,
};

import { Form } from '@ant-design/compatible';
import { Input } from 'antd';
import React, { useState } from 'react';
import { isValidPhoneNumber } from '../../utils/DataFormatterUtils';
const AsYouTypeFormatter = require('google-libphonenumber').AsYouTypeFormatter;

const PhoneNumberInputComponent = ({ form, fieldName, ...props }) => {
	const [value, setValue] = useState(props.value);
	const handlePhoneNumberEntry = (e) => {
		const formatter = new AsYouTypeFormatter('US');
		const phoneString = e.target.value.replace(/[^0-9+]/g, '').split('');
		phoneString.map((c) => formatter.inputDigit(c));
		setValue(formatter.currentOutput_.toString());
		form.setFieldsValue({ [fieldName]: formatter.currentOutput_.toString() });
		form.validateFields([fieldName]);
	};
	return (
		<Input
			style={props.style}
			{...props.inputProps}
			onChange={handlePhoneNumberEntry}
			value={value}
		/>
	);
};

export default function PhoneNumberComponent({ form, fieldProps, ...props }) {
	const phoneValidator = (rule, value, callback) => {
		!value || value.length === 0
			? callback()
			: isValidPhoneNumber(value)
			? callback()
			: callback('Not a valid phone number.');
	};
	const inputProps = {
		placeholder: 'Enter phone number',
		...props,
	};

	return (
		<Form.Item label={props.label || 'Phone Number'}>
			{form.getFieldDecorator(fieldProps.name, {
				...fieldProps,
				rules: [...(fieldProps.rules || []), { validator: phoneValidator }],
				validateTrigger: ['onChange'],
			})(
				<PhoneNumberInputComponent
					form={form}
					fieldName={fieldProps.name}
					inputProps={inputProps}
					handleChange={(e) => console.log(e)}
				/>
			)}
		</Form.Item>
	);
}

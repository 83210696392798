import * as React from 'react';
import { MinusCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Row, Input, Table, Button, List, Alert, Rate } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { debounce } from '../../utils/PerformanceUtils';
import BackButton from '../back_button/BackButton';
import { Link } from 'react-router-dom';
import { FlexibleImageAvatar } from '../flexible-image-avatar/FlexibleImageAvatar';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { capitalizeEachWord } from '../../utils/DataFormatterUtils';
import ClientShowMoreList from '../ClientShowMoreList/ClientShowMoreList';
import { BIDDERS_CRUD_ACTION_CREATORS } from '../../actions/bidders_actions';
import { biddersRestCrudThunksForBuyer } from '../../thunks/bidders_thunks';
import { requestsForProposalRestCrudThunksForBuyer } from '../../thunks/requests_for_proposal_thunks';
import { SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS } from '../../actions/supplier_private_networks_actions';
import { hydratedSupplierPrivateNetworksGetDistinctForBuyer } from '../../thunks/supplier_private_networks_thunks';

const style = require('./RFPBidderForm.less');
const SUPPLIER_PRIVATE_NETWORKS_HYDRATED_INDEX = 'supplierPrivateNetworksHydratedIndex';

interface RFPBidderProps {
	formData: any;
	creating: boolean;
	updating: boolean;
	createErrors: string[];
	updateErrors: string[];
	updateSupplierFacilitiesFilters: any;
	currentUser: any;
	history: any;
	location: any;
	saveFormData: any;
	createBidder: any;
	updateBidder: any;
	destroyBidder: any;
	redirectForwardUrl: any;
	requestForProposalId: any;
	requestForProposal: any;
	getRequestForProposal: any;
}

interface RFPBidderProps extends FormComponentProps {
	formData: any;
	creating: boolean;
	updating: boolean;
	createErrors: string[];
	updateErrors: string[];
	updateSupplierFacilitiesFilters: any;
	currentUser: any;
	history: any;
	location: any;
	saveFormData: any;
	createBidder: any;
	updateBidder: any;
	destroyBidder: any;
	redirectForwardUrl: any;
	requestForProposalId: any;
	requestForProposal: any;
	getRequestForProposal: any;
}

class RFPBidderForm extends React.Component<RFPBidderProps, any> {
	componentDidMount() {
		const { getRequestForProposal, requestForProposalId } = this.props;
		getRequestForProposal(requestForProposalId);
	}

	debouncedCallback = debounce(
		(searchText) => {
			const { updateSupplierFacilitiesFilters } = this.props;
			updateSupplierFacilitiesFilters(
				{ displayName: searchText && searchText.trim() },
				SUPPLIER_PRIVATE_NETWORKS_HYDRATED_INDEX
			);
		},
		500,
		false
	);

	handleSearchDebounced = (e) => {
		const searchText = e.target.value;
		this.debouncedCallback(searchText);
	};

	handleSubmit = (e = null) => {
		const {
			currentUser,
			createBidder,
			updateBidder,
			destroyBidder,
			history,
			formData,
			saveFormData,
			redirectForwardUrl,
			requestForProposalId,
			requestForProposal,
		} = this.props;
		if (e) {
			e.preventDefault();
		}
		return new Promise((resolve, reject) => {
			if (!formData.bidders) {
				resolve("we didn't do anything");
				return;
			}
			const existingBidders = formData.bidders.filter((el) => el.hasOwnProperty('createdAt'));
			const newBidders = formData.bidders
				.filter((el) => !el.hasOwnProperty('createdAt'))
				.map((bidder) => {
					return {
						...bidder,
						requestForProposalId: parseInt(requestForProposalId, 10),
						workOrderId: parseInt(requestForProposal.workOrder.id, 10),
					};
				});
			const removedBidders = formData.removedBidders || [];
			saveFormData(formData);
			const creates = newBidders.map((el) => createBidder(el));
			const updates = existingBidders.map((el) => updateBidder(el));
			const deletes = removedBidders.map((el) =>
				destroyBidder(el, 'requestForProposalId', 'supplierFacilityId')
			);
			const allActions = [...creates, ...updates, ...deletes];
			if (allActions.length > 0) {
				Promise.all(allActions)
					.then((vals) => {
						resolve('great success');
						history.push(redirectForwardUrl);
					})
					.catch((err) => {
						reject(err);
					});
			} else {
				resolve("we didn't do anything");
				history.push(redirectForwardUrl);
			}
		});
	};

	render() {
		const { formData, creating, updating, createErrors, updateErrors, saveFormData } = this.props;

		const isUpdate =
			formData.bidders && formData.bidders.some((el) => el.hasOwnProperty('createdAt'));
		const isLoading = creating || updating;
		const errorMessagesText = createErrors.concat(updateErrors).join('; ');

		let submitText = '';
		let formTitleText = '';

		submitText = isUpdate ? 'Update bidders' : 'Add bidders';
		formTitleText = isUpdate
			? 'Edit bidders'
			: 'Add the suppliers you want to invite to participate in your event';

		const addBidder = (supplierFacility, supplierContact) => {
			formData.bidders = formData.bidders ? formData.bidders : [];
			const bidder = formData.bidders.find(
				(bidder) => bidder.supplierFacilityId === supplierFacility.id
			);
			const otherBidders = formData.bidders.filter(
				(bidder) => bidder.supplierFacilityId !== supplierFacility.id
			);
			if (bidder) {
				const newBidder = {
					...bidder,
					primaryEmails: bidder.primaryEmails.concat([supplierContact.email]),
					primaryContacts: bidder.primaryContacts.concat([supplierContact]),
				};
				formData.bidders = [...otherBidders, newBidder];
			} else {
				const newBidder = {
					supplierFacilityId: supplierFacility.id,
					supplierFacility: supplierFacility,
					primaryEmails: [supplierContact.email],
					primaryContacts: [supplierContact],
					bidderStatus: 'noResponse',
					awardStatus: 'pending',
				};
				formData.bidders = [...otherBidders, newBidder];
			}
			saveFormData(formData);
		};

		const getFileId = (imageUrl) => imageUrl.split('/')[0];
		const getFileName = (imageUrl) => imageUrl.split('/')[1];

		const removeBidder = (bidderAssoc, supplierContact) => {
			const bidder = formData.bidders.find(
				(b) => b.supplierFacilityId === bidderAssoc.supplierFacilityId
			);
			const otherBidders = formData.bidders.filter(
				(b) => b.supplierFacilityId !== bidderAssoc.supplierFacilityId
			);
			const newBidder = {
				...bidder,
				primaryEmails: bidderAssoc.primaryEmails.filter((el) => el !== supplierContact.email),
				primaryContacts: bidderAssoc.primaryContacts.filter(
					(el) => el.email !== supplierContact.email
				),
			};
			if (newBidder.primaryEmails.length === 0) {
				if (newBidder.hasOwnProperty('createdAt')) {
					if (!formData.removedBidders) {
						formData.removedBidders = [];
					}
					formData.removedBidders.push(newBidder);
				}
				formData.bidders = otherBidders;
			} else {
				formData.bidders = [...otherBidders, newBidder];
			}
			saveFormData(formData);
		};

		const hasBidder = (supplierFacility, supplierContact) => {
			return (
				formData.bidders &&
				formData.bidders.some((el) => {
					const hasFacility = el.supplierFacilityId === supplierFacility.id;
					const hasContact = el.primaryEmails.some((spce) => spce === supplierContact.email);
					return hasFacility && hasContact;
				})
			);
		};

		const columns = [
			{
				title: 'Name',
				dataIndex: ['supplierFacility', 'displayName'],
				render: (text, record) => (
					<div>
						<Link to={`/buyer/suppliers/detail/${record.supplierFacilityId}`} target="_blank">
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ marginRight: 24 }}>
									<FlexibleImageAvatar
										displayName={text}
										showPopover={false}
										popoverText={text}
										width="48px"
										height="48px"
										imageUrl={record.supplierFacility.logoURL}
									/>
								</div>
								<div>
									<div style={{ fontSize: 18, wordBreak: 'break-word' }}>{text}</div>
									<div style={{ minWidth: 140 }}>
										<Rate
											disabled={true}
											allowHalf={true}
											defaultValue={record.supplierFacility.averageRatings}
											style={{ fontSize: 16 }}
										/>
									</div>
									<div style={{ fontSize: 12 }}>{record.supplierFacility.categoryNames}</div>
								</div>
							</div>
						</Link>
						<div style={{ marginTop: 16, marginLeft: 72 }}>
							<ClientShowMoreList
								dataSource={record.supplierFacility.supplierContacts}
								itemLayout="horizontal"
								showAll={true}
								initialNumVisibleRecords={3}
								renderItem={(item) => (
									<List.Item
										actions={[
											<Button
												type="primary"
												disabled={hasBidder(record.supplierFacility, item)}
												onClick={() => addBidder(record.supplierFacility, item)}
											>
												{hasBidder(record, item) ? 'Invited' : 'Invite'}
											</Button>,
										]}
										extra=""
									>
										<List.Item.Meta
											avatar={null}
											title={
												item.nameGiven.length === 0 && item.nameFamily.length === 0 ? (
													item.email
												) : (
													<span>
														{capitalizeEachWord(
															[item.nameGiven, item.nameFamily, item.suffix].join(' ')
														)}
													</span>
												)
											}
											description={
												item.title && item.department ? (
													<span>
														{capitalizeEachWord(item.title)}, {capitalizeEachWord(item.department)}
													</span>
												) : null
											}
										/>
										<div></div>
									</List.Item>
								)}
							/>
						</div>
					</div>
				),
			},
		];

		const columns2 = [
			{
				title: 'Name',
				dataIndex: 'displayName',
				render: (text, record) => (
					<div>
						<Link to={`/buyer/suppliers/detail/${record.id}`} target="_blank">
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ marginRight: 24 }}>
									<FlexibleImageAvatar
										displayName={text}
										showPopover={false}
										popoverText={text}
										width="48px"
										height="48px"
										imageUrl={record.supplierFacility.logoURL}
									/>
								</div>
								<div>
									<div
										style={{
											fontSize: 18,
											wordBreak: 'break-word',
										}}
									>
										{record.supplierFacility.displayName}
									</div>
									<div style={{ minWidth: 140 }}>
										<Rate
											disabled={true}
											allowHalf={true}
											defaultValue={record.supplierFacility.averageRatings}
											style={{ fontSize: 16 }}
										/>
									</div>
								</div>
							</div>
						</Link>
						<div style={{ marginTop: 16, marginLeft: 72 }}>
							<ClientShowMoreList
								dataSource={record.primaryContacts}
								itemLayout="horizontal"
								showAll={true}
								initialNumVisibleRecords={3}
								renderItem={(item) => (
									<List.Item
										actions={[
											<MinusCircleOutlined
												className="dynamic-delete-button"
												onClick={() => removeBidder(record, item)}
											/>,
										]}
										extra=""
									>
										<List.Item.Meta
											avatar={null}
											title={
												<span>
													{item.nameGiven && item.nameFamily
														? capitalizeEachWord(
																[item.nameGiven, item.nameFamily, item.suffix].join(' ')
														  )
														: item.email}
												</span>
											}
											description={
												item.title && item.department ? (
													<span>
														{capitalizeEachWord(item.title)}, {capitalizeEachWord(item.department)}
													</span>
												) : null
											}
										/>
										<div></div>
									</List.Item>
								)}
							/>
						</div>
					</div>
				),
			},
		];

		return (
			<Form className="bidderForm" layout="vertical">
				<Row style={{ margin: '40px 0' }} gutter={16}>
					<Col span={12}>
						<div style={{ width: '100%' }}>
							{createErrors.length > 0 || updateErrors.length > 0 ? (
								<div style={{ marginBottom: 24 }}>
									<Alert message={errorMessagesText} type="error" />
								</div>
							) : null}
							{formData.bidders && formData.bidders.length > 0 ? (
								<div style={{ minHeight: '473px' }}>
									<Table
										columns={columns2}
										showHeader={false}
										rowKey={(el) => el['supplierFacilityId']}
										dataSource={formData.bidders}
									/>
								</div>
							) : (
								<div style={{ minHeight: '473px' }}>
									<div style={{ fontSize: '16px', margin: '16px 0' }}>
										You haven't added any bidders to this event yet.
									</div>
									<div style={{ display: 'flex', justifyContent: 'center' }}>
										<img
											style={{ margin: '32px 0', maxWidth: '400px' }}
											width="100%"
											height="auto"
											src="https://gw.alipayobjects.com/zos/rmsportal/KpnpchXsobRgLElEozzI.svg"
										/>
									</div>
								</div>
							)}
						</div>
					</Col>
					<Col span={12} style={{ padding: '0 40px' }}>
						<Input.Search
							placeholder="Search for more suppliers..."
							style={{ width: 300, marginTop: 8, marginBottom: 16 }}
							size="large"
							onChange={this.handleSearchDebounced}
						/>
						<PaginatedReduxTable
							collectionName="supplier_private_networks"
							targetCollectionName={SUPPLIER_PRIVATE_NETWORKS_HYDRATED_INDEX}
							columns={columns}
							showHeader={false}
							useDistinctCollection={true}
							keyAccessor={(el) => el.supplierFacilityId}
							initialPagination={{ current: 1, pageSize: 4 }}
							fetchData={hydratedSupplierPrivateNetworksGetDistinctForBuyer}
						/>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								loading={isLoading}
								onClick={this.handleSubmit}
								className="bidderForm__button"
							>
								{submitText}
							</Button>
							<span style={{ marginLeft: '16px' }}>
								<BackButton buttonText="Cancel" />
							</span>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	formData: ownProps.formData,

	createErrors: state.bidders.createErrors,
	updateErrors: state.bidders.updateErrors,
	currentUser: state.session.currentUser,
	history: ownProps.history,
	location: ownProps.location,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	requestForProposalId: ownProps.requestForProposalId,
	requestsForProposalFetching: state.requests_for_proposal.fetching,
	requestForProposal: state.requests_for_proposal.detail,
});

const mapDispatchToProps = (dispatch) => ({
	saveFormData: (formData) => dispatch(BIDDERS_CRUD_ACTION_CREATORS.saveFormData(formData)),
	createBidder: (entity) => dispatch(biddersRestCrudThunksForBuyer.create(entity)),
	updateBidder: (entity) => dispatch(biddersRestCrudThunksForBuyer.update(entity)),
	destroyBidder: (entity, primaryKeyName, secondPrimaryKeyName) =>
		dispatch(biddersRestCrudThunksForBuyer.delete(entity, primaryKeyName, secondPrimaryKeyName)),
	getRequestForProposal: (id) => dispatch(requestsForProposalRestCrudThunksForBuyer.readOne(id)),
	updateSupplierFacilitiesFilters: (filters, targetCollectionName) => {
		dispatch(
			SUPPLIER_PRIVATE_NETWORKS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollectionName)
		);
	},
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Form.create<RFPBidderProps>()(RFPBidderForm))
);

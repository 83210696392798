import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import {
	buyerContactsRestCrudThunksForBuyer,
	buyerContactsRestCrudThunksForSupplier,
} from '../../thunks/buyer_contacts_thunks';
import { connect } from 'react-redux';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { withRouter } from 'react-router';
import PhoneNumberComponentAntdV4 from '../PhoneNumberComponent/PhoneNumberComponentAntdV4';
import SingleEmailComponent from '../single_email_component/SingleEmailComponent';

const LOCATION_FORM_BUYER_CONTACTS_AUTOCOMPLETE = 'LOCATION_FORM_BUYER_CONTACTS_AUTOCOMPLETE';

function BuyerVendorInformationForm(props) {
	useEffect(() => {
		const { fetchBuyerContacts } = props;
		fetchBuyerContacts();
	}, []);

	const [form] = Form.useForm();

	const handleSubmit = (values) => {
		props.onSubmit(values);
		form.resetFields();
	};

	const handleCancel = () => {
		form.resetFields();
	};
	const { data, buyerContacts, visible, onDismiss } = props;

	return (
		<>
			<Modal
				width={500}
				okText="Add Contact"
				onCancel={onDismiss}
				onOk={form.submit}
				closable={true}
				visible={visible}
				onDismiss={onDismiss}
				okButtonProps={{ loading: !visible }}
			>
				<Form
					form={form}
					initialValues={data}
					onFinish={handleSubmit}
					layout="vertical"
					labelAlign="left"
					preserve={false}
				>
					<h5>Create Contact</h5>
					<Form.Item label="Name" name="name" required={true}>
						<Input />
					</Form.Item>
					<SingleEmailComponent
						fieldProps={{
							preserve: false,
							label: 'Email',
							name: 'email',
						}}
					/>
					<PhoneNumberComponentAntdV4
						form={form}
						fieldProps={{ label: 'Phone Number', name: 'phoneNumber' }}
					/>
				</Form>
			</Modal>
		</>
	);
}
const mapStateToProps = (state) => ({
	buyerContactsFetching: state.buyer_contacts.fetching,
	buyerContacts: state.buyer_contacts,
	userType: (state as any).session.userType,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchBuyerContacts: (params) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? buyerContactsRestCrudThunksForSupplier.read(
						params,
						LOCATION_FORM_BUYER_CONTACTS_AUTOCOMPLETE
				  )
				: buyerContactsRestCrudThunksForBuyer.read(
						params,
						LOCATION_FORM_BUYER_CONTACTS_AUTOCOMPLETE
				  )
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(BuyerVendorInformationForm)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

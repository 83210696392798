export const UPDATE_NAVBAR_STATE = 'UPDATE_NAVBAR_STATE';
export const updateNavbarState = (backlinkTitle, backlinkTo, navbarBackgroundColor) => ({
	type: UPDATE_NAVBAR_STATE,
	backlinkTitle,
	backlinkTo,
	navbarBackgroundColor,
});

export const CLEAR_NAVBAR_STATE = 'CLEAR_NAVBAR_STATE';
export const clearNavbarState = () => ({
	type: CLEAR_NAVBAR_STATE,
});

import * as React from 'react';
import { Layout, Row, Col, Card } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { assetTypesRestCrudThunksForBuyer } from '../../thunks/asset_types_thunks';
import { TwoLineList } from '../two_line_list/TwoLineList';
import { assetModelsRestCrudThunksForBuyer } from '../../thunks/asset_models_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;

const style = require('./AssetModelsDetailDetailsPage.less');
const ASSET_MODELS_FOR_TYPES = 'ASSET_MODELS_FOR_TYPES';

interface AssetModelsDetailPageProps {
	match: any;
}

class AssetModelsDetailPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			assetModelModalFormVisible: true,
			assetModelFormData: {},
		};
	}

	componentDidMount() {
		const {
			getAssetModel,

			match,
		} = this.props;
		const assetModelId = match.params.id;
		getAssetModel(assetModelId);
	}

	render() {
		const { assetModel, assetModelsFetching, match } = this.props;

		return assetModelsFetching ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="assetModelsDetailDetailsPage">
				<LogOnMountWithStandardEventProperties eventType="visited asset model detail page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '-0.5em -8px 0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<Row style={{ margin: '0.5em -8px' }} gutter={16}>
							<Col span={24}>
								<Card>
									<div className="assetModelsDetailDetailsPage__listSection">
										<div className="assetModelsDetailDetailsPage__listSectionTitle">
											Required Fields
										</div>
										{assetModel.specs && assetModel.specs.length === 0 ? (
											'No specs available'
										) : (
											<TwoLineList
												listItems={assetModel.specs}
												lineOneRender={(record) => record.key}
												lineTwoRender={(record) => record.value}
											/>
										)}
									</div>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	assetModelsFetching: state.asset_models.fetching,
	assetModel: state.asset_models.detail,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	getAssetType: (id) => dispatch(assetTypesRestCrudThunksForBuyer.readOne(id)),
	deleteAssetModel: (entity) => dispatch(assetModelsRestCrudThunksForBuyer.delete(entity)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(AssetModelsDetailPage)
);

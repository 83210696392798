import * as React from 'react';
import BuyerLoginForm from '../buyer_login_form/BuyerLoginForm';
import { Layout, Row, Col } from 'antd';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;
const style = require('./BuyerLoginPage.less');
const style2 = require('../../styles/blah.less');

export default class LoginPage extends React.Component<any, any> {
	render() {
		const from = nullSafeGet('location.state.from', this.props);
		return (
			<Content
				className="loginPage"
				style={{
					height: '100vh',
					display: 'flex',
					backgroundColor: '#220e3f',
					alignItems: 'center',
				}}
			>
				<LogOnMountWithStandardEventProperties eventType="visited buyer login page" />
				{/*<BackTop/>*/}
				<Row
					style={{
						maxWidth: 800,
						boxShadow: 'rgba(0,0,0,0.5) 10px 10px 50px',
						width: '100%',
						margin: 'auto',
					}}
				>
					<Col sm={24} md={10}>
						<div style={{ background: '#391769', padding: 40, height: '100%' }}>
							<div
								style={{
									width: '100%',
									height: 80,
									backgroundSize: 'contain',
									backgroundRepeat: 'no-repeat',
									backgroundPosition: 'center',
									backgroundImage: `url('https://s3.amazonaws.com/emptor-data-assets/images/openwrench-logos/openwrenchlogo-white-facilities.png')`,
								}}
							/>
						</div>
					</Col>
					<Col sm={24} md={14}>
						<div style={{ padding: '56px 40px', background: 'white' }}>
							<h4 className="mb-6">Log In</h4>
							<BuyerLoginForm username="" password="" returnTo={from} />
						</div>
					</Col>
				</Row>
			</Content>
		);
	}
}

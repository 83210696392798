import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { depreciationClassesRestCrudThunksForSupplier } from '../../thunks/depreciation_class_thunks';
import { Button } from 'antd';
import DepreciationClassModal from './DepreciationClassModal';

const DepreciationClassDetailsPage: FC<any> = ({
	companyConfig,
	fetching,
	depreciationClass,
	childRoutes,
	history,
	match,
	getDepreciationClass,
}): React.ReactElement => {
	const [editModalVisible, setEditModalVisible] = useState(false);

	const tabs = useMemo(
		() => [
			{
				key: 'details',
				name: 'Overview',
			},
		],
		[]
	);

	const _showEditModal = useCallback(() => setEditModalVisible(true), []);
	const _hideEditModal = useCallback(() => setEditModalVisible(false), []);

	const dcId = useMemo(() => match.params.id, [match.params.id]);

	const refresshDepreciationClass = useCallback(
		() => dcId && getDepreciationClass(dcId),
		[dcId, getDepreciationClass]
	);

	const onEditSuccess = useCallback(() => {
		refresshDepreciationClass();
		_hideEditModal();
	}, [_hideEditModal, refresshDepreciationClass]);

	useEffect(() => dcId && getDepreciationClass(dcId), [dcId, getDepreciationClass]);

	const handleTabChange = useCallback(
		(key) => {
			const dcId = match.params.id;
			history.push(`/supplier/depreciation/depreciationClass/detail/${dcId}/${key}`);
		},
		[history, match.params.id]
	);

	const checkTabActive = useCallback(
		(key) => {
			const pathname = history.location.pathname;
			return pathname.indexOf(key) !== -1;
		},
		[history.location.pathname]
	);

	return (
		<>
			<DetailPageHeader
				exactPath={'/supplier/depreciation/depreciationClass/detail/:id'}
				redirectPath={`/supplier/depreciation/depreciationClass/detail/${dcId}/details`}
				backLinkText={'Back to Depreciation Classes'}
				backLinkTo={`/supplier/depreciation/overview/depreciationClass`}
				title={fetching ? null : depreciationClass.name}
				subtitle={null}
				actions={
					<Button ghost={true} onClick={_showEditModal}>
						Edit
					</Button>
				}
				tabs={tabs}
				backgroundColor={nullSafeGet('detail.config.theme.navblock', companyConfig)}
				handleTabChange={handleTabChange}
				checkTabActive={checkTabActive}
				childRoutes={childRoutes}
			/>
			{editModalVisible && (
				<DepreciationClassModal
					depreciationClass={depreciationClass}
					onSuccess={onEditSuccess}
					onCancel={_hideEditModal}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	companyConfig: state.company_config,
	fetching: state.depreciation_classes.fetching,
	depreciationClass: state.depreciation_classes.detail,
});

const mapDispatchToProps = (dispatch) => ({
	getDepreciationClass: (id) => dispatch(depreciationClassesRestCrudThunksForSupplier.readOne(id)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(DepreciationClassDetailsPage)
);

import * as React from 'react';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { Card } from 'antd';
import { ContactAvatar } from '../contact_avatar/ContactAvatar';
import { checkAndFormatPhoneNumber } from '../../utils/DataFormatterUtils';

export const ContactCardDisplay: React.FC<any> = ({ contact, title, roleType }) => {
	return (
		<Card title={title}>
			<div className="materialCard__primaryTitle" style={{ display: 'flex' }}>
				<ContactAvatar
					size="large"
					hidePopover={true}
					style={{ marginRight: 16 }}
					contact={contact}
					userType={roleType}
				/>
				<div>
					<div className="materialCard__title">
						{nullSafeGet('nameGiven', contact)} {nullSafeGet('nameFamily', contact)}
					</div>
					<div className="materialCard__subtitle">
						{nullSafeGet('title', contact)} {nullSafeGet('department', contact)}
					</div>
				</div>
			</div>
			{nullSafeGet('phoneWork', contact) ? (
				<div
					className="materialCard__supportingText materialCard__flexCentVert"
					style={{ marginBottom: 8 }}
				>
					<div className="materialCard__iconLabel">
						<i style={{ fontSize: 24 }} className={`icons8-font icons8-phone`} />
					</div>
					<div>{checkAndFormatPhoneNumber(nullSafeGet('phoneWork', contact))}</div>
				</div>
			) : null}
			{nullSafeGet('phoneMobile', contact) ? (
				<div
					className="materialCard__supportingText materialCard__flexCentVert"
					style={{ marginBottom: 8 }}
				>
					<div className="materialCard__iconLabel">
						<i style={{ fontSize: 24 }} className={`icons8-font icons8-cell-phone`} />
					</div>
					<div>{checkAndFormatPhoneNumber(nullSafeGet('phoneMobile', contact))}</div>
				</div>
			) : null}
			{nullSafeGet('email', contact) ? (
				<div
					className="materialCard__supportingText materialCard__flexCentVert"
					style={{ marginBottom: 8 }}
				>
					<div className="materialCard__iconLabel">
						<i style={{ fontSize: 24 }} className={`icons8-font icons8-email`} />
					</div>
					<div>{nullSafeGet('email', contact)}</div>
				</div>
			) : null}
		</Card>
	);
};

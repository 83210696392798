import React, { FC } from 'react';
import { Button } from 'antd';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { isInternalTech, PERMISSION_NAMES } from '../../utils/AuthUtils';
import AccessPermissionChecker from '../common/AccessPermissionChecker';

const QuoteEditButton: FC<any> = ({
	currentUser,
	userType,
	companyConfig,

	quote,
	buttonText = 'Edit',
	...buttonProps
}): React.ReactElement => {
	const isExternalSupplier = userType === ROLE_TYPES.SUPPLIER && !isInternalTech(currentUser);
	const isBuyerUploaded = nullSafeGetOrElse('isBuyerUploaded', quote, false);
	const isEditableStatus =
		quote.status === 'pending' || quote.status === 'declined' || quote.status === 'awarded';

	return isEditableStatus ? (
		isBuyerUploaded ? (
			<AccessPermissionChecker name={PERMISSION_NAMES.CAN_UPLOAD_QUOTES_FOR_SUPPLIER}>
				<Button {...buttonProps}>{buttonText}</Button>
			</AccessPermissionChecker>
		) : isExternalSupplier ? (
			<Button {...buttonProps}>{buttonText}</Button>
		) : null
	) : null;
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	currentUser: state.session.currentUser,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({});

const WrapperComponent = withRouter(connect(mapStateToProps, mapDispatchToProps)(QuoteEditButton));

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	(dispatch, ownProps) => ({})
)(WrapperComponent);

import { crudActionCreatorsGenerator } from './crud_action_creators_generator';
import { crudActionConstantsGenerator } from './crud_action_constants_generator';

export const FETCH_SOURCING_EVENT_GROUP_SENT_CONVERSATIONS_SUCCESS =
	'FETCH_SOURCING_EVENT_GROUP_SENT_CONVERSATIONS_SUCCESS';
export const fetchSourcingEventGroupSentConversationsSuccess = (conversations) => ({
	type: FETCH_SOURCING_EVENT_GROUP_SENT_CONVERSATIONS_SUCCESS,
	conversations,
});
export const FETCH_SOURCING_EVENT_GROUP_INBOX_CONVERSATIONS_SUCCESS =
	'FETCH_SOURCING_EVENT_GROUP_INBOX_CONVERSATIONS_SUCCESS';
export const fetchSourcingEventGroupInboxConversationsSuccess = (conversations) => ({
	type: FETCH_SOURCING_EVENT_GROUP_INBOX_CONVERSATIONS_SUCCESS,
	conversations,
});

export const FETCH_SOURCING_EVENT_GROUP_MESSAGE_THREAD_SUCCESS =
	'FETCH_SOURCING_EVENT_GROUP_MESSAGE_THREAD_SUCCESS';
export const fetchSourcingEventGroupMessageThreadSuccess = (thread) => ({
	type: FETCH_SOURCING_EVENT_GROUP_MESSAGE_THREAD_SUCCESS,
	thread,
});

export const SOURCING_EVENT_GROUP_MESSAGES_CRUD_ACTION_CONSTANTS = crudActionConstantsGenerator(
	'SOURCING_EVENT_GROUP_MESSAGES'
);
export const SOURCING_EVENT_GROUP_MESSAGES_CRUD_ACTION_CREATORS = crudActionCreatorsGenerator(
	SOURCING_EVENT_GROUP_MESSAGES_CRUD_ACTION_CONSTANTS
);

import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { PROBLEM_TYPES_CRUD_ACTION_CREATORS } from '../actions/problem_types_actions';
import { parseJSON } from './fetch_utils';
import { logoutSuccess } from '../actions/session_actions';
import FetchUtils from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { validateDomain } from '../utils/AuthUtils';

const fetchUtils = new FetchUtils();

export const SSODomain =
	(roleType) =>
	(username = '', isLogin = false) => {
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				// this is because the backend api is not ready yet. once the api is ready. fetch it from backend only @arnav
				const domains = ['onemedical.com'];
				const loginExcpetedDomains = ['sunnyside.shop'];

				if (!isLogin) {
					domains.push(...loginExcpetedDomains);
				}

				if (validateDomain(username.trim(), domains)) {
					reject('Please login with SSO');
				} else {
					resolve(domains);
				}

				// 	fetchUtils
				// 		.get(
				// 			baseUrl + apiUrl,
				// 			Object.assign(
				// 				{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
				// 				params
				// 			)
				// 		)
				// 		.then(parseJSON)
				// 		.then(({ json, status, ok }) => {
				// 			const d = json;
				// 			if (d.status === 'error') {
				// 				reject(d.message);
				// 			} else {
				// 				resolve(d.data);
				// 			}
				// 		})
				// 		.catch((d) => {
				// 			reject(d.message);
				// 		});
			});
		};
	};

export const SSODomainForBuyer = SSODomain('buyer');
export const SSODomainForSupplier = SSODomain('supplier');

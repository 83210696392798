import React, { useState } from 'react';
import { Modal, Input, Form, Radio, Select, Space } from 'antd';
import { connect } from 'react-redux';
import { submitQuestionnaire } from '../../thunks/work_orders_thunks';
import { nullSafeGet } from '../../utils/DataAccessUtils';

function PostCompletionQuestionnaire({
	questionsList,
	workOrderId,
	successCb,
	submitQuestionnaire,
	onCancel,
	userType,
	submitting,
}) {
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	if (nullSafeGet('questions.length', questionsList) < 1) return null;

	const getQuestionItem = (question: any = {}) => {
		let inputField;
		if (question.questionType === 'yesNo') {
			inputField = (
				<Radio.Group>
					<Radio value="yes">Yes</Radio>
					<Radio value="no">No</Radio>
				</Radio.Group>
			);
		} else if (question.questionType === 'multipleChoice') {
			if (question.multipleChoices.length > 8) {
				inputField = (
					<Select>
						{question.multipleChoices.map((choice, idx) => (
							<Select.Option key={idx} value={choice.value}>
								{choice.description}
							</Select.Option>
						))}
					</Select>
				);
			} else {
				inputField = (
					<Radio.Group>
						<Space direction="vertical">
							{question.multipleChoices.map((choice, idx) => (
								<Radio value={choice.value} key={idx} className="mr-2">
									{choice.description}
								</Radio>
							))}
						</Space>
					</Radio.Group>
				);
			}
		} else {
			inputField = <Input.TextArea autoSize={{ minRows: 2 }} placeholder="" />;
		}
		return inputField;
	};
	return (
		<Modal
			visible
			width={'50vw'}
			title={questionsList.title}
			okText={'Submit'}
			closable={false}
			onOk={form.submit}
			onCancel={onCancel}
			confirmLoading={loading || submitting}
		>
			{questionsList.description && questionsList.description.length > 0 ? (
				<div className="mt-1  leading-6 text-gray-600">
					<div className="" dangerouslySetInnerHTML={{ __html: questionsList.description }} />
				</div>
			) : null}
			<Form
				preserve={false}
				form={form}
				layout="vertical"
				name="form_in_modal"
				validateMessages={{
					required: 'Required to answer to this question.',
				}}
				onFinish={(values) => {
					const response = {
						workOrderId,
						title: questionsList.title,
						answers: questionsList.questions.map((question, i) => ({
							question,
							answer: values[i],
							comments: '',
						})),
					};
					setLoading(true);
					submitQuestionnaire(userType, response).finally(() => {
						successCb();
						setLoading(false);
					});
				}}
			>
				{questionsList.questions.map &&
					questionsList.questions.map((question, id) => (
						<Form.Item
							label={<span className="font-bold">{question.title}</span>}
							name={id}
							rules={[...(question.required ? [{ required: true }] : [])]}
						>
							{getQuestionItem(question)}
						</Form.Item>
					))}
			</Form>
		</Modal>
	);
}

const mapStateToProps = (state, ownProps) => ({
	currentUser: state.session.currentUser,
	userType: state.session.userType,
});

const mapDispatchToProps = (dispatch) => ({
	submitQuestionnaire: (userType, id) => dispatch(submitQuestionnaire(userType)(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostCompletionQuestionnaire);

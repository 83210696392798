import * as React from 'react';
import { connect } from 'react-redux';
import { Layout, Spin, Row, Col, Card, Table } from 'antd';
import { currencyFormatter, floatToPercentageString } from '../../utils/DataFormatterUtils';

import {
	collectAllParentsFromTreeData,
	collectObjectsWithValidPropFromTreeData,
	filterTreeData,
	flattenTreeData,
	getCurrency,
	nullSafeGet,
	nullSafeGetOrElse,
	removeEmptyChildrenFromTreeData,
	sortTreeData,
} from '../../utils/DataAccessUtils';
import { BarChart } from '../charts/BarChart';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { withRouter } from 'react-router';
import AnalyticsControlBar from '../analytics_control_bar/AnalyticsControlBar';
import {
	clearProblemTypeFilters,
	updateProblemTypeFilters,
} from '../../actions/spend_analytics_actions';
import {
	fetchSpendDataByMonth,
	fetchSpendDataByProblemTypeWithChildren,
} from '../../thunks/spend_analytics_thunks';
import { debounce } from '../../utils/PerformanceUtils';
import { EmptyState } from '../empty_state/EmptyState';
import { StackedAreaChart } from '../charts/StackedAreaChart';
import moment from 'moment';
import { subcategoriesDisplayNamesMapper } from '../../utils/AnalyticsUtils';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { ExportToCsv } from 'export-to-csv';
import { CSV_EXPORT_DEFAULTS } from '../../utils/DataConstants';

require('./SpendAnalyticsOverviewProblemTypesPage.less');
require('g2');
const DataSet = require('@antv/data-set');
const { Content } = Layout;
const isEqual = require('fast-deep-equal');
const spendByMonthConfigFunc = (currency) => ({
	xAxisName: 'yearMonthKey',
	yAxisName: 'monthlyTotal',
	position: 'yearMonthKey*monthlyTotal',
	fillArea: false,
	transformer: (data) => {
		const ds = new DataSet();
		const dv = ds.createView().source(data);
		dv.transform({
			type: 'fold',
			fields: [
				subcategoriesDisplayNamesMapper['laborMonthlyTotal'],
				subcategoriesDisplayNamesMapper['materialMonthlyTotal'],
				subcategoriesDisplayNamesMapper['freightMonthlyTotal'],
				subcategoriesDisplayNamesMapper['travelMonthlyTotal'],
				subcategoriesDisplayNamesMapper['miscMonthlyTotal'],
				subcategoriesDisplayNamesMapper['taxMonthlyTotal'],
			], // expand fields into separate records
			key: 'subcategory', // new key field
			value: 'monthlyTotal', // new value field
		});
		return dv;
	},
	color: 'subcategory',
	cols: {
		yearMonthKey: { alias: 'Month' },
		monthlyTotal: { alias: 'Spend' },
	},
	yAxisLabel: {
		formatter: (val) => (val > 0 ? currency.symbolFormat(val, 0) : currency.symbolFormat(0, 0)),
		textStyle: {
			fontSize: '12',
			fontFamily: 'Roboto',
			fill: 'rgba(0,0,0,0.85)',
		},
	},
	xAxisLabel: {
		formatter: (val) => moment(val, 'YYYY-MM').format('MMM YYYY'),
		textStyle: {
			fontSize: '12',
			fontFamily: 'Roboto',
			fill: 'rgba(0,0,0,0.85)',
		},
	},
});
const spendByProblemTypeChartConfigFunc = (currency) => ({
	xAxisName: 'fieldName',
	yAxisName: 'subTotal',
	position: 'fieldName*subTotal',
	fillArea: false,
	color: 'fieldName',
	cols: {
		fieldName: { alias: 'Spend Category' },
		subTotal: { alias: 'Spend' },
	},
	yAxisLabel: {
		formatter: (val) => (val > 0 ? currency.symbolFormat(val, 0) : currency.symbolFormat(0, 0)),
		textStyle: {
			fontSize: '12',
			fontFamily: 'Roboto',
			fill: 'rgba(0,0,0,0.85)',
		},
	},
	xAxisLabel: {
		textStyle: {
			fontSize: '12',
			fontFamily: 'Roboto',
			fill: 'rgba(0,0,0,0.85)',
		},
	},
});

const CSV_TITLE = 'Spend Analytics by Problem Types';

const exportReduceFunction = (obj) => ({
	name: obj.hierarchyName,
	total: currencyFormatter(0, 'USD')(nullSafeGetOrElse('invoiceCosts.subTotal', obj, 0)),
	percentage: floatToPercentageString(
		nullSafeGetOrElse('invoiceCosts.percentageOfTotal', obj, 0) / 100
	),
});

class SpendAnalyticsOverviewProblemTypesPage extends React.Component<any, any> {
	state = {
		initialLoading: true,
		search: '',
		filteredTreeData: [],
		problemTypeIds: [],
	};
	debouncedCallback = debounce(
		() => {
			const { fetchSpendData, fetchTimeSeriesSpendData, analytics } = this.props;
			const { initialLoading } = this.state;
			const promise1 = fetchSpendData(analytics.problemTypeFilters);
			const promise2 = fetchTimeSeriesSpendData(analytics.problemTypeFilters);
			if (initialLoading) {
				Promise.all([promise1, promise2]).then(() => this.setState({ initialLoading: false }));
			}
		},
		500,
		false
	);

	componentDidMount() {
		const { fetchSpendData, fetchTimeSeriesSpendData, analytics } = this.props;
		const { initialLoading } = this.state;
		if (analytics.problemTypeFilters.startDate && analytics.problemTypeFilters.endDate) {
			const promise1 = fetchSpendData(analytics.problemTypeFilters);
			const promise2 = fetchTimeSeriesSpendData(analytics.problemTypeFilters);
			if (initialLoading) {
				Promise.all([promise1, promise2]).then(() => this.setState({ initialLoading: false }));
			}
		}
	}

	componentWillReceiveProps(nextProps) {
		const { analytics } = this.props;
		if (!isEqual(nextProps.analytics.problemTypeFilters, analytics.problemTypeFilters)) {
			this.debouncedCallback();
		}
	}

	filterByProblemTypeId = (allData) => {
		const problemTypeIds = nullSafeGetOrElse('state.problemTypeIds', this, []);
		if (problemTypeIds.length > 0) {
			return filterTreeData(allData, (obj) => problemTypeIds.includes(obj.id));
		} else {
			return allData;
		}
	};

	getDisplayData = () => {
		const spendByProblemType = nullSafeGetOrElse('props.analytics.spendByProblemType', this, []);

		const displayData = !!this.state.search
			? nullSafeGetOrElse('state.filteredTreeData', this, [])
			: this.filterByProblemTypeId(nullSafeGetOrElse('data', spendByProblemType, []));
		return sortTreeData(
			displayData,
			(a, b) =>
				parseFloat(nullSafeGetOrElse('invoiceCosts.subTotal', b, 0)) -
				parseFloat(nullSafeGetOrElse('invoiceCosts.subTotal', a, 0))
		);
	};

	onDownloadAnalytics = () => {
		const csvExporter = new ExportToCsv({
			...CSV_EXPORT_DEFAULTS,
			filename: CSV_TITLE,
			title: CSV_TITLE,
			headers: ['Problem Type', 'Total Spent', 'Percentage'],
		});
		csvExporter.generateCsv(flattenTreeData(this.getDisplayData(), exportReduceFunction));
	};

	onSearch = (e) => {
		const { analytics } = this.props;
		const { spendByProblemType } = analytics;

		const search = e.target.value;
		const allData = this.filterByProblemTypeId(nullSafeGetOrElse('data', spendByProblemType, []));

		this.setState({
			filteredTreeData: !!search
				? filterTreeData(allData, (obj) => obj.name.toLowerCase().includes(search.toLowerCase()))
				: [],
			search,
		});
	};

	removeProblemTypeIdsAndUpdateFilter = (filters) => {
		const { saveProblemTypeFilters } = this.props;
		const { problemTypeIds, ...otherFilters } = filters;
		this.setState({
			problemTypeIds,
		});
		saveProblemTypeFilters(otherFilters);
	};

	render() {
		const { clearAllProblemTypeFilters, analytics, accountActivated, currentUser } = this.props;
		const { initialLoading } = this.state;
		const { spendByProblemType, spendByMonth, problemTypeFilters } = analytics;
		const currency = getCurrency({ currentUser });

		const spendByProblemTypeData = collectObjectsWithValidPropFromTreeData(
			this.getDisplayData(),
			'invoiceCosts'
		)
			.map((_) => _.invoiceCosts)
			.map((el) => ({
				...el,
				subTotal: parseFloat(el.subTotal),
				percentageOfTotal: parseFloat(el.percentageOfTotal),
			}))
			.sort((a, b) => parseFloat(a.subTotal) - parseFloat(b.subTotal));

		const spendByMonthData =
			spendByMonth.data &&
			spendByMonth.data.map((el) => ({
				...el,
				monthlyTotal: parseFloat(el.monthlyTotal),
				[subcategoriesDisplayNamesMapper['freightMonthlyTotal']]: parseFloat(
					el.freightMonthlyTotal
				),
				[subcategoriesDisplayNamesMapper['laborMonthlyTotal']]: parseFloat(el.laborMonthlyTotal),
				[subcategoriesDisplayNamesMapper['materialMonthlyTotal']]: parseFloat(
					el.materialMonthlyTotal
				),
				[subcategoriesDisplayNamesMapper['miscMonthlyTotal']]: parseFloat(el.miscMonthlyTotal),
				[subcategoriesDisplayNamesMapper['taxMonthlyTotal']]: parseFloat(el.taxMonthlyTotal),
				[subcategoriesDisplayNamesMapper['travelMonthlyTotal']]: parseFloat(el.travelMonthlyTotal),
			}));

		const defaultSortOrder: 'ascend' | 'descend' = 'descend';
		const tooltipTemplate = `
                    <li data-index={index}>
                        <!-- The marker for each record -->
                        <span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>
                        {name}: {value}
                    </li>
        `;

		const spendByProblemTypeColumns = [
			{
				title: 'Problem Type',
				dataIndex: 'name',
				key: 'name',
				width: '30%',
				render: (text) => (
					<Ellipsis tooltip={true} style={{ display: 'inline' }} length={28}>
						{text}
					</Ellipsis>
				),
			},
			{
				title: `Total Spent (${currency.id})`,
				dataIndex: ['invoiceCosts', 'subTotal'],
				key: 'totalSpend',
				defaultSortOrder: defaultSortOrder,
				sorter: (a, b) =>
					nullSafeGetOrElse('invoiceCosts.subTotal', a, 0) -
					nullSafeGetOrElse('invoiceCosts.subTotal', b, 0),
				render: (amount) => currency.symbolFormat(amount, 0),
			},
			{
				title: '% of Total',
				dataIndex: ['invoiceCosts', 'percentageOfTotal'],
				key: 'percentageOfTotal',
				render: (num) => floatToPercentageString((num || 0) / 100),
				width: 100,
			},
		];
		const spendByMonthConfig = spendByMonthConfigFunc(currency);
		const spendByProblemTypeChartConfig = spendByProblemTypeChartConfigFunc(currency);

		return (
			<Content
				className="analyticsOverviewProblemTypesPage"
				style={{ padding: '0 0.5em', position: 'relative' }}
			>
				<LogOnMountWithStandardEventProperties eventType="visited spend analytics problem types page" />
				{/*<BackTop/>*/}
				{accountActivated ? (
					<div>
						<Row key={1} style={{ margin: '0.5em -8px' }} gutter={16}>
							<Col span={24}>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									<AnalyticsControlBar
										updateFilters={this.removeProblemTypeIdsAndUpdateFilter}
										problemTypeIds={this.state.problemTypeIds}
										clearFilters={clearAllProblemTypeFilters}
										locationIds={problemTypeFilters.locationIds}
										startDate={problemTypeFilters.startDate}
										supplierFacilityIds={problemTypeFilters.supplierFacilityIds}
										endDate={problemTypeFilters.endDate}
										isCapex={problemTypeFilters.isCapex}
										isPM={problemTypeFilters.isPM}
										workCreatedAtStartDate={problemTypeFilters.workCreatedAtStartDate}
										workCreatedAtEndDate={problemTypeFilters.workCreatedAtEndDate}
										workCompletedAtStartDate={problemTypeFilters.workCompletedAtStartDate}
										workCompletedAtEndDate={problemTypeFilters.workCompletedAtEndDate}
										onDownload={this.onDownloadAnalytics}
										showSearch
										showDownload
										search={this.state.search}
										onSearch={this.onSearch}
										filterConfig={{
											workCreatedAt: true,
											workCompletedAt: true,
											capEx: true,
											isSpendAnalytics: true,
											problemTypes: true,
											isPM: true,
										}}
										searchPlaceholder={'Search Problem types'}
									/>
									<div></div>
								</div>
							</Col>
						</Row>

						{spendByMonth.loading || initialLoading || this.getDisplayData().length > 0 ? (
							[
								<>
									<Row key={2} style={{ margin: '0.5em -8px' }} gutter={16}>
										<Col span={24}>
											<Card>
												<Spin spinning={spendByProblemType.loading || initialLoading}>
													<Table
														columns={spendByProblemTypeColumns}
														rowKey={(el) => el['id']}
														dataSource={removeEmptyChildrenFromTreeData(this.getDisplayData())}
														expandable={{
															...(!!this.state.search && {
																expandedRowKeys: collectAllParentsFromTreeData(
																	this.state.filteredTreeData,
																	(_) => _.id
																),
															}),
														}}
													/>
												</Spin>
											</Card>
										</Col>
									</Row>
									<Row>
										<Col span={24}>
											<Card>
												<Spin spinning={spendByProblemType.loading || initialLoading}>
													{spendByProblemTypeData && spendByProblemTypeData.length > 0 ? (
														<BarChart
															height={spendByProblemTypeData.length * 32}
															chartPadding={[40, 40, 40, 200]}
															data={spendByProblemTypeData}
															showTooltipTitle={false}
															tooltipItemTemplate={tooltipTemplate}
															xAxisName={nullSafeGet('xAxisName', spendByProblemTypeChartConfig)}
															yAxisName={nullSafeGet('yAxisName', spendByProblemTypeChartConfig)}
															xAxisLabel={nullSafeGet('xAxisLabel', spendByProblemTypeChartConfig)}
															yAxisLabel={nullSafeGet('yAxisLabel', spendByProblemTypeChartConfig)}
															fillArea={nullSafeGet('fillArea', spendByProblemTypeChartConfig)}
															cols={nullSafeGet('cols', spendByProblemTypeChartConfig)}
															color={nullSafeGet('color', spendByProblemTypeChartConfig)}
															position={nullSafeGet('position', spendByProblemTypeChartConfig)}
														/>
													) : null}
												</Spin>
											</Card>
										</Col>
									</Row>
								</>,

								<Row key={3} style={{ margin: '0.5em -8px' }} gutter={16}>
									<Col span={24}>
										<Card>
											<Spin spinning={spendByMonth.loading || initialLoading}>
												{spendByMonth.data && spendByMonth.data.length > 0 ? (
													<StackedAreaChart
														height={260}
														chartPadding={[40, 40, 40, 80]}
														data={spendByMonthConfig.transformer(spendByMonthData)}
														tooltipItemTemplate={tooltipTemplate}
														showTooltipTitle={false}
														xAxisName={nullSafeGet('xAxisName', spendByMonthConfig)}
														yAxisName={nullSafeGet('yAxisName', spendByMonthConfig)}
														yAxisLabel={nullSafeGet('yAxisLabel', spendByMonthConfig)}
														xAxisLabel={nullSafeGet('xAxisLabel', spendByMonthConfig)}
														fillArea={nullSafeGet('fillArea', spendByMonthConfig)}
														cols={nullSafeGet('cols', spendByMonthConfig)}
														color={nullSafeGet('color', spendByMonthConfig)}
														position={nullSafeGet('position', spendByMonthConfig)}
													/>
												) : null}
											</Spin>
										</Card>
									</Col>
								</Row>,
							]
						) : (
							<EmptyState
								height={undefined}
								headline={'No invoices found'}
								body={
									<div style={{ maxWidth: 488, textAlign: 'center' }}>
										We didn't find any invoice data. Try changing your filters or expanding the
										selected time period.
									</div>
								}
							/>
						)}
					</div>
				) : (
					<EmptyState
						graphic={
							<img
								alt="You haven't activated your account yet."
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/letter owl.svg"
							/>
						}
						headline={"You haven't activated your account yet."}
						body={
							<div style={{ textAlign: 'center' }}>
								<div style={{ maxWidth: 560, marginBottom: 16 }}>
									Please check your email. We sent you a note with an activation link.
								</div>
							</div>
						}
					/>
				)}
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	accountActivated: state.session.accountActivated,
	currentUser: state.session.currentUser,
	analytics: state.spend_analytics,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	saveProblemTypeFilters: (filters) => dispatch(updateProblemTypeFilters(filters)),
	clearAllProblemTypeFilters: (filters) => dispatch(clearProblemTypeFilters()),
	fetchSpendData: (params, filters = null, pagination = null, sorting = null) =>
		dispatch(
			fetchSpendDataByProblemTypeWithChildren(ownProps.userType)(
				params,
				filters,
				pagination,
				sorting
			)
		),
	fetchTimeSeriesSpendData: (params, filters = null, pagination = null, sorting = null) =>
		dispatch(fetchSpendDataByMonth(ownProps.userType)(params, filters, pagination, sorting)),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SpendAnalyticsOverviewProblemTypesPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

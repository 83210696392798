import { LOCATIONS_CRUD_ACTION_CREATORS } from '../actions/locations_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { parseJSON } from './fetch_utils';
import { logoutSuccess } from '../actions/session_actions';
import FetchUtils from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { REGIONS_CRUD_ACTION_CREATORS } from '../actions/regions_actions';
import { getParamsWithPaginationAndSorting } from '../utils/DataAccessUtils';

export const locationsRestCrudThunksForBuyer = new RestCrudThunks(
	'locations',
	'location',
	'location',
	'locations',
	LOCATIONS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'location/'
);
export const locationsRestCrudThunksForSupplier = new RestCrudThunks(
	'locations',
	'location',
	'location',
	'locations',
	LOCATIONS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'location/'
);

const fetchUtils = new FetchUtils();
const baseUrl = getBackendUri();
const readAllLocationsWithoutRoleChecks =
	(roleType) =>
	(
		params,
		targetCollectionName = null,
		pagination = null,
		sorting = null,
		filters = null,
		addToTargetCollection = false
	) => {
		// if pagination present, transform into limit/offset format and add to query params
		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(LOCATIONS_CRUD_ACTION_CREATORS.fetchStart(targetCollectionName));

				fetchUtils
					.get(
						baseUrl + `/api/v1/${roleType}/location/without_role_check/locations`,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(LOCATIONS_CRUD_ACTION_CREATORS.fetchError(d.message, targetCollectionName));
							reject(d.message);
						} else {
							dispatch(
								LOCATIONS_CRUD_ACTION_CREATORS.fetchSuccess(
									d.data,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters,
									addToTargetCollection
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(LOCATIONS_CRUD_ACTION_CREATORS.fetchError(d.message, targetCollectionName));
						reject(d.message);
					});
			});
		};
	};

const fetchLocationsWithRegions =
	(roleType) =>
	(
		params,
		targetCollectionName = null,
		pagination = null,
		sorting = null,
		filters = null,
		addToTargetCollection = false
	) => {
		// if pagination present, transform into limit/offset format and add to query params
		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				const suffixUrl = '/location/regions_with_sub_regions_and_locations';
				fetchUtils
					.get(
						baseUrl + `/api/v1/${roleType}` + suffixUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.message);
						} else {
							const locations = d.data.filter((el) => el.childrenIds && el.childrenIds.length == 0);
							const regions = d.data.filter((el) => el.childrenIds && el.childrenIds.length > 0);
							dispatch(
								LOCATIONS_CRUD_ACTION_CREATORS.fetchSuccess(
									locations,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters,
									addToTargetCollection
								)
							);
							dispatch(
								REGIONS_CRUD_ACTION_CREATORS.fetchSuccess(
									regions,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters,
									addToTargetCollection
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};
export const fetchLocationsWithRegionsForBuyer = fetchLocationsWithRegions(ROLE_TYPES.BUYER);
export const fetchLocationsWithRegionsForSupplier = fetchLocationsWithRegions(ROLE_TYPES.SUPPLIER);

export const readAllLocationsWithoutRoleChecksForBuyer = readAllLocationsWithoutRoleChecks(
	ROLE_TYPES.BUYER
);
export const readAllLocationsWithoutRoleChecksForSupplier = readAllLocationsWithoutRoleChecks(
	ROLE_TYPES.SUPPLIER
);

const fetchLocationsWithRegionsAndSubRegions =
	(roleType) =>
	(
		params,
		targetCollectionName = null,
		pagination = null,
		sorting = null,
		filters = null,
		addToTargetCollection = false
	) => {
		// if pagination present, transform into limit/offset format and add to query params
		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				const suffixUrl =
					params.hasOwnProperty('includeInactive') && params.includeInactive === true
						? '/location/regions_with_sub_regions_and_locations'
						: '/location/regions_with_sub_regions_and_locations';
				fetchUtils
					.get(
						baseUrl + `/api/v1/${roleType}` + suffixUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.message);
						} else {
							dispatch(
								LOCATIONS_CRUD_ACTION_CREATORS.fetchSuccess(
									d.data,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters,
									addToTargetCollection
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};
export const fetchLocationsWithRegionsAndSubRegionsForBuyer =
	fetchLocationsWithRegionsAndSubRegions(ROLE_TYPES.BUYER);
export const fetchLocationsWithRegionsAndSubRegionsForSupplier =
	fetchLocationsWithRegionsAndSubRegions(ROLE_TYPES.SUPPLIER);

const fetchLocationsWithBuyerCompanies =
	(roleType) =>
	(
		params,
		targetCollectionName = null,
		pagination = null,
		sorting = null,
		filters = null,
		addToTargetCollection = false
	) => {
		// if pagination present, transform into limit/offset format and add to query params
		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				const suffixUrl = '/location/buyer_companies_with_locations';
				fetchUtils
					.get(
						baseUrl + `/api/v1/supplier` + suffixUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.message);
						} else {
							dispatch(
								LOCATIONS_CRUD_ACTION_CREATORS.fetchSuccess(
									d.data,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters,
									addToTargetCollection
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};

export const fetchLocationsWithBuyerCompaniesForSupplier = fetchLocationsWithBuyerCompanies(
	ROLE_TYPES.SUPPLIER
);

const fetchLocationsWithPurchaseRequestLineItemCount =
	(roleType) =>
	(
		params,
		targetCollectionName = null,
		pagination = null,
		sorting = null,
		filters = null,
		addToTargetCollection = false
	) => {
		params = getParamsWithPaginationAndSorting(params, pagination, sorting, filters);

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.get(
						baseUrl +
							`/api/v1/${roleType}/location/locations/with_purchase_request_line_item_count`,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.message);
						} else {
							dispatch(
								LOCATIONS_CRUD_ACTION_CREATORS.fetchSuccess(
									d.data,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters,
									addToTargetCollection
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};

export const fetchLocationsWithPurchaseRequestLineItemCountForSupplier =
	fetchLocationsWithPurchaseRequestLineItemCount(ROLE_TYPES.SUPPLIER);

const fetchAllLocations =
	(roleType) =>
	(params, sorting = null) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/location/all_locations_lite`;

		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.data);
						} else {
							resolve(d.data);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};

export const fetchAllLocationsForBuyer = fetchAllLocations(ROLE_TYPES.BUYER);

const fetchLocationsWithStockLocations =
	(roleType) =>
	(
		params,
		targetCollectionName = null,
		pagination = null,
		sorting = null,
		filters = null,
		addToTargetCollection = false
	) => {
		// if pagination present, transform into limit/offset format and add to query params
		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		const apiUrl = `/api/v1/${roleType}/location/locations/lite/with_stock_location`;

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(LOCATIONS_CRUD_ACTION_CREATORS.fetchStart(targetCollectionName));

				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(LOCATIONS_CRUD_ACTION_CREATORS.fetchError(d.message, targetCollectionName));
							reject(d.message);
						} else {
							dispatch(
								LOCATIONS_CRUD_ACTION_CREATORS.fetchSuccess(
									d.data,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters,
									addToTargetCollection
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(LOCATIONS_CRUD_ACTION_CREATORS.fetchError(d.message, targetCollectionName));
						reject(d.message);
					});
			});
		};
	};

export const fetchLocationsWithStockLocationsForSupplier = fetchLocationsWithStockLocations(
	ROLE_TYPES.SUPPLIER
);

export const locationAudits = (roleType) => (params) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/location/location_audits`;

	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(baseUrl + apiUrl, params)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const assetAuditForBuyer = locationAudits(ROLE_TYPES.BUYER);
export const assetAuditForSupplier = locationAudits(ROLE_TYPES.SUPPLIER);

import { Button, Form, Input, Modal, message } from 'antd';
import React, { FC, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import {
	regionsRestCrudThunksForBuyer,
	regionsRestCrudThunksForSupplier,
} from '../../thunks/regions_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';

const FORM_ID = 'add-region-form';

const AddRegionPopup: FC<any> = ({
	onCancel,
	onSuccess,
	createRegion,
	parentData,
}): React.ReactElement => {
	const [saving, setSaving] = useState(false);

	const handleSubmit = useCallback(
		(values) => {
			setSaving(true);
			createRegion({
				name: values.name,
				...(parentData && { parentId: parentData.id }),
			})
				.then(() => onSuccess())
				.catch((err) => message.error(err))
				.finally(() => setSaving(false));
		},
		[createRegion, onSuccess, parentData]
	);

	return (
		<Modal
			visible={true}
			title={parentData ? `Add region under '${parentData.name}'` : `Add new region`}
			onCancel={onCancel}
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button
					type="primary"
					size="large"
					style={{ marginLeft: '16px' }}
					key="submit"
					htmlType="submit"
					form={FORM_ID}
					loading={saving}
				>
					Save
				</Button>,
			]}
			closable={true}
		>
			<Form id={FORM_ID} layout="vertical" requiredMark={false} onFinish={handleSubmit}>
				<Form.Item
					name="name"
					label="Name"
					rules={[{ required: true, message: 'Please enter a name for the region!' }]}
				>
					<Input type="text" placeholder="Enter Name of this region" />
				</Form.Item>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	createRegion: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? regionsRestCrudThunksForSupplier.create(entity)
				: regionsRestCrudThunksForBuyer.create(entity)
		),
});

const ComponentWithoutUserType = connect(mapStateToProps, mapDispatchToProps)(AddRegionPopup);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import * as React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { DownloadOutlined, FilterOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, DatePicker, Button, Drawer, Switch, Input } from 'antd';
import moment from 'moment';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import {
	locationsRestCrudThunksForBuyer,
	locationsRestCrudThunksForSupplier,
} from '../../thunks/locations_thunks';
import {
	fetchSupplierFacilitiesIfEverInPrivateNetworkForBuyer,
	fetchSupplierFacilitiesIfEverInPrivateNetworkForSupplier,
	supplierFacilitiesRestCrudThunksForBuyer,
	supplierFacilitiesRestCrudThunksForSupplier,
} from '../../thunks/supplier_facilities_thunks';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import BuyerRegionsLocationsTreeSelect from '../regions_tree_select/BuyerRegionsLocationsTreeSelect';

import { getDefaultDateRanges, stringArrToList } from '../../utils/DataFormatterUtils';
import { FILTER_CONFIG_NAMES, FILTER_FIELD_TYPE, ROLE_TYPES } from '../../utils/DataConstants';
import {
	problemTypesHeirarchicalForBuyer,
	problemTypesHeirarchicalForSupplier,
} from '../../thunks/problem_types_thunks';
import { BUYER_ADMIN, SUPER_ADMIN } from '../roles/roles';
import AnalyticsSelectedFilters from '../analytics_control_bar/AnalyticsSelectedFilters';
import {
	assetTypesRestCrudThunksForBuyer,
	assetTypesRestCrudThunksForSupplier,
} from '../../thunks/asset_types_thunks';
import OWAsyncTreeSelect from '../ow_async_tree_select/OWAsyncTreeSelect';
import OWSelect from '../ow_radio_group/OWSelect';
import OWDatePicker from '../ow_date_picker/OWDatePicker';

interface DowntimeAnalyticsControlBarFormProps extends FormComponentProps {
	startDate: any;
	endDate: any;

	locationIds: any;

	fetchMultipleLocations: any;
	fetchLocations: any;

	hideLocationsControl: any;
	locations: any;
	showSearch?: boolean;
	showDownload: boolean;
	onSearch?: any;
	search?: any;
	filterConfig: any;
	onDownload: any;
	currentUser: any;
	dateRangeDefaultLabel: any;
	updateFilters: any;
	assetTypes: any;
	fetchAssetTypes: any;
	assetTypeIds: any;
	filters: any;
}

class DowntimeAnalyticsControlBar extends React.Component<
	DowntimeAnalyticsControlBarFormProps,
	any
> {
	state = {
		editing: false,
		allowLocationEdit: false,
		allowSupplierEdit: false,
	};

	getFilterValues = () => {
		const { startDate, endDate, filters } = this.props;
		const { locationIds, assetTypeIds, manufacturer } = filters;
		return {
			startDate: startDate,
			endDate: endDate,
			locationIds,
			assetTypeIds,
			manufacturer,
		};
	};

	componentDidMount() {
		const { startDate, endDate, updateFilters } = this.props;

		if (!startDate || !endDate) {
			updateFilters(this.getFilterValues());
		}
	}

	handleSubmit = (e) => {
		const { updateFilters } = this.props;
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const newFilters = {
					...values,
					locationIds: values.selectAllLocations
						? undefined
						: values.locationIds && values.locationIds.sort(),
					startDate: nullSafeGet('startAndEndDates.0', values),
					endDate: nullSafeGet('startAndEndDates.1', values),
				};
				updateFilters(newFilters);
				this.setState({ editing: false });
			}
		});
	};

	handleCancel = (e) => {
		e.preventDefault();
		this.props.form.resetFields();
		this.setState({ editing: false });
	};

	updateFiltersFromDisplay = (updatedFilter) => {
		const { updateFilters } = this.props;
		updateFilters({ ...this.getFilterValues(), ...updatedFilter });
	};

	getLocationsString(locationIds = [], locations) {
		const locationNames = locationIds.map((id) => nullSafeGet(`${id}.name`, locations.records));
		if (locationIds.length === 0) {
			return 'no locations';
		} else if (locationIds.length > 0 && locationIds.length < 3) {
			return stringArrToList(locationNames);
		} else {
			return `${locationIds.length} locations`;
		}
	}

	getSupplierFacilitiesString(supplierFacilityIds = [], supplierFacilities) {
		const supplierFacilityNames = supplierFacilityIds.map((id) =>
			nullSafeGet(`${id}.displayName`, supplierFacilities.records)
		);
		if (supplierFacilityIds.length === 0) {
			return 'no suppliers';
		} else if (supplierFacilityIds.length > 0 && supplierFacilityIds.length < 3) {
			return stringArrToList(supplierFacilityNames);
		} else {
			return `${supplierFacilityIds.length} suppliers`;
		}
	}

	handleAllLocationsChange = (value) => {
		this.setState({ allowLocationEdit: !value });
	};

	handleAllSuppliersChange = (value) => {
		this.setState({ allowSupplierEdit: !value });
	};

	openDrawer = () => this.setState({ editing: true });

	render() {
		const {
			startDate,
			endDate,

			form,
			hideLocationsControl,
			locations,
			showSearch,
			showDownload,
			onSearch,
			search,
			filterConfig,
			onDownload,
			currentUser,
			dateRangeDefaultLabel,
			assetTypes,
			fetchAssetTypes,
			filters,
		} = this.props;
		const { locationIds, assetTypeIds, manufacturer } = filters;
		const { editing } = this.state;
		const RANGE_PICKER_DATE_FORMAT = 'MM-DD-YYYY';

		const dateRangeLabel = dateRangeDefaultLabel || 'Downtime Date Range';

		const isAdmin =
			currentUser.roles.includes(BUYER_ADMIN) || currentUser.roles.includes(SUPER_ADMIN);
		const hasAccessToAllLocations = nullSafeGet('hasAccessToAllLocations', currentUser) || isAdmin;
		const FilterControls = (
			<Form
				layout="vertical"
				hideRequiredMark={true}
				style={{ maxWidth: 800 }}
				onSubmit={this.handleSubmit}
			>
				<h5>Filters</h5>
				<Form.Item label={dateRangeLabel}>
					{form.getFieldDecorator('startAndEndDates', {
						initialValue: startDate && endDate ? [moment(startDate), moment(endDate)] : undefined,
					})(
						<OWDatePicker
							key={
								startDate && endDate
									? startDate.format('YYMMDD') + endDate.format('YYMMDD')
									: undefined
							}
							ranges={getDefaultDateRanges()}
							format={RANGE_PICKER_DATE_FORMAT}
							showTime={false}
						/>
					)}
				</Form.Item>
				{hideLocationsControl ? null : (
					<>
						<Form.Item style={{ marginBottom: '0px' }} label="Locations">
							{form.getFieldDecorator('selectAllLocations', {
								initialValue: !this.state.allowLocationEdit,
								valuePropName: 'checked',
							})(<Switch onChange={this.handleAllLocationsChange} />)}
							<label style={{ marginLeft: 8 }}>
								Select {hasAccessToAllLocations ? 'All Locations' : 'All Assigned Locations'}
							</label>
						</Form.Item>
						<Form.Item>
							{form.getFieldDecorator('locationIds', {
								initialValue: this.state.allowLocationEdit ? locationIds : [],
							})(
								<BuyerRegionsLocationsTreeSelect
									mode="multiple"
									disabled={!this.state.allowLocationEdit}
									filtersSlice={locations}
									filtersValueAccessor={() => form.getFieldValue('locationIds')}
									targetCollectionName={'locationsAnalyticsDropdown'}
								/>
							)}
						</Form.Item>
					</>
				)}
				{nullSafeGet('assetTypes', filterConfig) ? (
					<Form.Item label="Asset Types">
						{form.getFieldDecorator('assetTypeIds', {
							initialValue: assetTypeIds,
						})(
							<OWAsyncTreeSelect
								allowClear={true}
								mode="multiple"
								stateSlice={assetTypes}
								targetCollectionName="analyticsDropdown"
								fetchData={(
									searchText,
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								) =>
									fetchAssetTypes(
										{ name: searchText },
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									)
								}
								fetchMultiple={(ids, targetCollectionName) => {}}
								renderRecord={(sf) => (
									<Select.Option key={sf.id} value={sf.id}>
										{sf.name}
									</Select.Option>
								)}
							/>
						)}
					</Form.Item>
				) : null}
				{nullSafeGet('manufacturer', filterConfig) ? (
					<Form.Item label="Manufacturer">
						{form.getFieldDecorator('manufacturer', {
							initialValue: manufacturer,
						})(<Input.Search placeholder="Search for a manufacturer" />)}
					</Form.Item>
				) : null}
				<Form.Item>
					<Button type="primary" htmlType="submit">
						Update filters
					</Button>
					<Button onClick={this.handleCancel} style={{ marginLeft: 16 }}>
						Cancel
					</Button>
				</Form.Item>
			</Form>
		);

		return (
			<div
				style={{
					width: '100%',
				}}
			>
				<div className="flex flex-row items-center justify-between">
					<div className="flex flex-row items-center">
						{showSearch && (
							<Input.Search
								placeholder={'Search Problem type'}
								value={search}
								style={{ width: 180 }}
								className="mr-2"
								onChange={onSearch}
							/>
						)}
						<Button
							size="large"
							icon={<FilterOutlined translate="" />}
							onClick={() => this.setState({ editing: !this.state.editing })}
						>
							Filters
						</Button>
					</div>
					{showDownload && (
						<Button
							size="large"
							icon={<DownloadOutlined translate="" />}
							className="inline-block"
							onClick={onDownload}
						>
							<span className="inline-block">Export CSV</span>
						</Button>
					)}
				</div>
				<AnalyticsSelectedFilters
					filterValues={this.getFilterValues()}
					filterConfig={[
						...(hideLocationsControl
							? []
							: [
									{
										label: hasAccessToAllLocations ? 'Location' : 'Assigned Location',
										fieldName: 'locationIds',
										stateSlice: locations,
										valueAccessor: (loc) => loc.id,
										renderItem: (loc) => loc.name,
										pillMandatory: true,
									},
							  ]),
						{
							label: dateRangeLabel,
							fieldName: 'default',
							type: FILTER_FIELD_TYPE.DATE_RANGE,
							// mandatory: true,
							renderRecord: (item) => item,
							valueAccessor: (item) => item,
						},
						{
							label: 'Asset Type',
							fieldName: FILTER_CONFIG_NAMES.ASSET_TYPE,
							stateSlice: assetTypes,
							valueAccessor: (pt) => pt.id,
							renderItem: (pt) => pt.name,
						},
						// {
						// 	label: 'Manufacturers',
						// 	fieldName: 'manufacturers',
						// 	stateSlice: manufacturers || [],
						// 	valueAccessor: (pt) => pt.name,
						// 	renderItem: (pt) => pt.name,
						// },
					]}
					updateFilters={this.updateFiltersFromDisplay}
					openFilters={this.openDrawer}
				/>
				<Drawer closable={false} width={800} visible={editing}>
					{FilterControls}
				</Drawer>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	currentUser: state.session.currentUser,
	locations: state.locations,
	supplierFacilities: state.supplier_facilities,
	updateFilters: ownProps.updateFilters,
	clearFilters: ownProps.clearFilters,
	startDate: ownProps.startDate,
	endDate: ownProps.endDate,
	locationIds: ownProps.locationIds,
	supplierFacilityIds: ownProps.supplierFacilityIds,
	problemTypes: state.problem_types,
	assetTypes: state.asset_types,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: locationsRestCrudThunksForBuyer.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: locationsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchMultipleSupplierFacilities: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? supplierFacilitiesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: supplierFacilitiesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchSupplierFacilitiesIfEverInPrivateNetwork: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? fetchSupplierFacilitiesIfEverInPrivateNetworkForSupplier(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: fetchSupplierFacilitiesIfEverInPrivateNetworkForBuyer(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchProblemTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) => {
		return dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? problemTypesHeirarchicalForSupplier(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: problemTypesHeirarchicalForBuyer(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		);
	},
	fetchAssetTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) => {
		return dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetTypesRestCrudThunksForSupplier.readLite(
						{ ...params, no_pagination: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: assetTypesRestCrudThunksForBuyer.readLite(
						{ ...params, no_pagination: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		);
	},
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(DowntimeAnalyticsControlBar)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(Form.create()(ComponentWithoutUserType));

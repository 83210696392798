import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
	findRecord,
	getAllLeafNodes,
	getFilterFromConfig,
	isMatchingField,
} from '../../utils/DataAccessUtils';
import { FILTER_FIELD_TYPE, multiValueFilterFieldNames } from '../../utils/DataConstants';
import { findIfDateRangeLabelAvailable } from '../../utils/DataFormatterUtils';
import OneLineComponent from '../common/OneLineComponent';
import EachFilterPill from '../selected_filters_pills/EachFilterPill';

interface EachRecord {
	id: string;
	displayText: string;
	fieldName: string;
	value: string;
	type: string;
	notClosable?: boolean;
}

const defaultValueAccessor = (obj) => `${obj.id}`;
const getId = (fieldName, value) => `${fieldName}-${value}`;
const DATE_DISPLAY_FORMAT = 'MMM D, YYYY';

const getStartDate = (fieldName) =>
	fieldName !== 'default' ? `${fieldName}StartDate` : 'startDate';
const getEndDate = (fieldName) => (fieldName !== 'default' ? `${fieldName}EndDate` : 'endDate');

const AnalyticsSelectedFilters: FC<any> = ({
	updateFilters,
	filterValues,
	filterConfig,
	openFilters,
}): React.ReactElement => {
	const [pills, setPills] = useState<EachRecord[]>([]);

	const clearGeneralFilter = useCallback(
		(fieldName, val) => {
			const filter = getFilterFromConfig(filterConfig, fieldName);
			const valueAccessor = filter.valueAccessor || defaultValueAccessor;
			let record = filter.stateSlice.records[val];
			if (!record) {
				const allKeys = Object.keys(filter.stateSlice.records);
				for (let i = 0; i < allKeys.length; i++) {
					if (filter.stateSlice.records[allKeys[i]].children) {
						record = findRecord(filter.stateSlice.records[allKeys[i]].children, valueAccessor, val);
						if (record) break;
					}
				}
			}

			const finalValues =
				record.children && record.children.length > 0
					? getAllLeafNodes(record.children).map((_) => valueAccessor(_))
					: [val];

			const existingFilterValue = filterValues[fieldName];
			const filteredArray = multiValueFilterFieldNames.includes(fieldName)
				? existingFilterValue.filter((_) => !finalValues.includes(_))
				: undefined;
			const newVal = filteredArray.length > 0 ? filteredArray.join(',') : undefined;
			updateFilters({ [fieldName]: newVal });
		},
		[updateFilters, filterConfig, filterValues]
	);

	const clearRadioGroupFilter = useCallback(
		(fieldName) => {
			updateFilters({ [fieldName]: undefined });
		},
		[updateFilters]
	);

	const clearDateRangeFilter = useCallback(
		(fieldName) =>
			updateFilters({
				[getStartDate(fieldName)]: undefined,
				[getEndDate(fieldName)]: undefined,
			}),
		[updateFilters]
	);

	const clearFilter = useCallback(
		(fieldName, val, type) => {
			switch (type) {
				case FILTER_FIELD_TYPE.RADIO_GROUP:
					clearRadioGroupFilter(fieldName);
					break;
				case FILTER_FIELD_TYPE.DATE_RANGE:
					clearDateRangeFilter(fieldName);
					break;
				default:
					clearGeneralFilter(fieldName, val);
			}
		},
		[clearDateRangeFilter, clearGeneralFilter, clearRadioGroupFilter]
	);

	const allFilterDisplays: React.ReactElement[] = useMemo(
		() =>
			[...pills]
				.reverse()
				.map((pill) => <EachFilterPill key={pill.id} {...pill} clearFilter={clearFilter} />),
		[pills, clearFilter]
	);

	const getDefaultFilterValues = useCallback(
		(filter) => {
			const valueAccessor = filter.valueAccessor || defaultValueAccessor;
			const filterValue = filterValues[filter.fieldName];

			let selectedValues = filterValue
				? multiValueFilterFieldNames.includes(filter.fieldName)
					? filterValue
					: [filterValue]
				: [];

			const isAllChildrenSelected = (records) => {
				let selected = true;
				for (let i = 0; i < records.length; i++) {
					const record = records[i];
					if (record.children && record.children.length > 0) {
						selected = isAllChildrenSelected(record.children);
						if (!selected) break;
					} else if (!selectedValues.includes(valueAccessor(record))) {
						selected = false;
						break;
					}
				}

				return selected;
			};

			const filterParentsOnly = (selectedValues, record) => {
				let newSelectedValues = [...selectedValues];
				if (record.children && record.children.length > 0) {
					for (let i = 0; i < record.children.length; i++) {
						const child = record.children[i];
						if (child.children && child.children.length > 0) {
							newSelectedValues = filterParentsOnly(newSelectedValues, child);
						}
					}
					if (isAllChildrenSelected(record.children)) {
						const childrenValues = record.children.map((_) => valueAccessor(_));
						newSelectedValues = newSelectedValues.filter((val) => !childrenValues.includes(val));
						newSelectedValues = Array.from(new Set([...newSelectedValues, valueAccessor(record)]));
					}
				}

				return newSelectedValues;
			};

			const records = filter.stateSlice.records;

			Object.keys(records).map((key) => {
				const record = records[key];
				selectedValues = filterParentsOnly(selectedValues, record);
				return undefined;
			});

			const getAllRecords = (records) => {
				let flattenedRecords = records.map((record) => ({ ...record, children: undefined }));
				records.map((record) => {
					if (record.children && record.children.length > 0) {
						flattenedRecords = [...flattenedRecords, ...getAllRecords(record.children)];
					}
					return undefined;
				});

				return flattenedRecords;
			};

			const allRecords = Object.keys(records).reduce((acc, key) => {
				const val = records[key];
				const newArray = [...acc, { ...val, children: undefined }];
				return val.children && val.children.length > 0
					? [...newArray, ...getAllRecords(val.children)]
					: newArray;
			}, []);

			return selectedValues
				.map((val) => allRecords.find((_) => valueAccessor(_) === val))
				.filter((r) => r && valueAccessor(r));
		},
		[filterValues]
	);

	const getRadioGroupValue = useCallback(
		(filter) => {
			const filterValue = filterValues[filter.fieldName];
			const selectedRecord = filter.items.find((_) => `${_.value}` === `${filterValue}`);

			return filterValue !== undefined ? [selectedRecord] : [];
		},
		[filterValues]
	);

	const getDateRangeValue = useCallback(
		(filter) => {
			const startDate = filterValues[getStartDate(filter.fieldName)];
			const endDate = filterValues[getEndDate(filter.fieldName)];
			const dateRangeLabel =
				startDate && endDate && findIfDateRangeLabelAvailable(startDate, endDate);
			return dateRangeLabel
				? [dateRangeLabel]
				: startDate && endDate
				? [`${startDate.format(DATE_DISPLAY_FORMAT)} - ${endDate.format(DATE_DISPLAY_FORMAT)}`]
				: [];
		},
		[filterValues]
	);

	const getDisplayText = useCallback((filter, selectedRecord) => {
		return `${filter.label ? `${filter.label}: ` : ''}${
			filter.renderRecord ? filter.renderRecord(selectedRecord) : filter.renderItem(selectedRecord)
		}`;
	}, []);

	const getSelectedRecords = useCallback(
		(filter) => {
			switch (filter.type) {
				case FILTER_FIELD_TYPE.RADIO_GROUP:
					return getRadioGroupValue(filter);
				case FILTER_FIELD_TYPE.DATE_RANGE:
					return getDateRangeValue(filter);
				default:
					return getDefaultFilterValues(filter);
			}
		},
		[getDateRangeValue, getRadioGroupValue, getDefaultFilterValues]
	);

	useEffect(() => {
		filterConfig.map((filter) => {
			const selectedRecords = getSelectedRecords(filter);
			const fieldName = filter.fieldName;

			const activePillData =
				selectedRecords.length > 0
					? selectedRecords.map((selectedRecord) => {
							const displayText = getDisplayText(filter, selectedRecord);
							const value = filter.valueAccessor && filter.valueAccessor(selectedRecord);
							const id = getId(fieldName, value);
							return {
								id,
								displayText,
								value,
								fieldName,
								type: filter.type,
								notClosable: filter.mandatory,
								onClick: openFilters,
							};
					  })
					: [
							...(filter.pillMandatory
								? [
										{
											id: `${fieldName}-all`,
											displayText: `All ${filter.label}s`,
											value: 'all',
											fieldName,
											type: filter.type,
											notClosable: true,
											onClick: openFilters,
										},
								  ]
								: []),
					  ];

			setPills((pills) => {
				const activeIds = activePillData.map((_) => _.id);
				const filteredPills = pills.filter((_) =>
					isMatchingField(_.id, fieldName) ? activeIds.includes(_.id) : true
				);
				return activePillData.reduce(
					(newPills, pillData) =>
						newPills.find((_) => _.id === pillData.id) ? [...newPills] : [...newPills, pillData],
					filteredPills
				);
			});

			return undefined;
		});
	}, [filterConfig, clearFilter, getDisplayText, getSelectedRecords, openFilters]);

	const allTagText = useMemo(() => [...pills].reverse().map((_) => _.displayText), [pills]);

	if (allFilterDisplays.length < 1) return null;

	return (
		<div style={{ marginTop: 12 }}>
			{allFilterDisplays.length > 0 ? (
				<OneLineComponent
					style={{}}
					childrenToRender={allFilterDisplays}
					childrenText={allTagText}
					fontSize={14}
					additionalWidth={46}
				/>
			) : (
				<></>
			)}
		</div>
	);
};

export default AnalyticsSelectedFilters;

import * as React from 'react';
import { Layout, Row, Button, Modal, List, Select, Tag } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { getEntityById, getObjectValues, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { workOrdersRestCrudThunksForSupplier } from '../../thunks/work_orders_thunks';
import {
	fetchAllUsersForSuppliers,
	addBuyerSubscribersForSuppliers,
	addSupplierSubscribersForSuppliers,
	removeSupplierSubscriberForSuppliers,
} from '../../thunks/work_orders_subscribers_thunks';
import { ContactAvatar } from '../contact_avatar/ContactAvatar';
import SupplierNewWorkOrderNoteForm from '../supplier_new_work_order_note_form/SupplierNewWorkOrderNoteForm';
import { EmptyState } from '../empty_state/EmptyState';
import { fetchAllWorkOrderNotesForSupplier } from '../../thunks/messages_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { debounce } from '../../utils/PerformanceUtils';
import AvatarGroup from '../avatar_group/AvatarGroup';
import MessageBubble from '../MessageBubble';
import RemoveSubscriberButton from '../work_orders_detail_notes_page/RemoveSubscriberButton';
import { ROLE_TYPES } from '../../utils/DataConstants';

const { Content } = Layout;
const { Option } = Select;

const style = require('./SupplierWorkOrdersDetailNotesPage.less');

const secondaryFieldStyle = { fontSize: '1.15em', color: '#606060' };
const primaryFieldStyle = { fontSize: '1.2em', color: 'black' };

class SupplierWorkOrderNotesDetailPage extends React.Component<any, any> {
	state = {
		fetching: true,
		subscriberModalVisible: false,
		addSubscriberModalVisible: false,
		search: '',
		selectedUsers: [],
		selectValue: [],
		addingSubscribers: false,
	};

	componentDidMount() {
		const { getWorkOrder, fetchMessages, match } = this.props;
		const workOrderId = match.params.id;
		const promise2 = fetchMessages(workOrderId);
		Promise.all([promise2]).then(() => {
			this.setState({ fetching: false }, () => {
				const element = document.getElementById('chatBox');
				if (element) {
					element.scrollTop = element.scrollHeight;
				}
			});
		});
	}

	handleSubscriberModalClose = () => {
		this.setState({ subscriberModalVisible: false });
	};

	handleSubscriberModalOpen = () => {
		this.setState({ subscriberModalVisible: true });
	};
	handleAddSubscriberModalClose = () => {
		this.setState({ addSubscriberModalVisible: false });
	};
	handleAddSubscriberModalOpen = () => {
		this.setState({ addSubscriberModalVisible: true });
		this.setState({ search: '', selectedUsers: [] });
	};
	handleSubscriberRemoved = (item) => () => {
		const { removeBuyerSubscriber, removeSupplierSubscriber, match, workOrder } = this.props;
		if (item.contactType == ROLE_TYPES.BUYER) {
			let obj = { id: parseInt(match.params.id), removeBuyerSubscriberEmails: [item.email] };
			removeBuyerSubscriber(obj, workOrder);
		} else {
			let obj = { id: parseInt(match.params.id), removeSupplierSubscriberEmails: [item.email] };
			removeSupplierSubscriber(obj, workOrder);
		}
	};
	handleAddSubscribers = () => {
		const { selectedUsers } = this.state;
		const { match, workOrder, addBuyerSubscribers, addSupplierSubscribers } = this.props;
		this.setState({ addingSubscribers: true });
		let objBuyer = {
			id: parseInt(match.params.id),
			addBuyerSubscriberEmails: selectedUsers
				.filter((user) => user.contactType === 'buyer')
				.map((user) => user.email),
		};
		let objSupplier = {
			id: parseInt(match.params.id),
			addSupplierSubscriberEmails: selectedUsers
				.filter((user) => user.contactType === 'supplier')
				.map((user) => user.email),
		};
		const subscribersAddition = [
			...(objBuyer.addBuyerSubscriberEmails.length > 0
				? [addBuyerSubscribers(objBuyer, workOrder)]
				: []),
			...(objSupplier.addSupplierSubscriberEmails.length > 0
				? [addSupplierSubscribers(objSupplier, workOrder)]
				: []),
		];
		Promise.all(subscribersAddition).then(() => {
			this.setState({
				selectValue: [],
				addingSubscribers: false,
			});
			this.handleAddSubscriberModalClose();
		});
	};
	handleOnChange = (val) => {
		let updatedVal = Array.isArray(val) ? val.map((v) => JSON.parse(v)) : JSON.parse(val);
		if (!('value' in this.props)) {
			this.setState({ value: updatedVal });
		}

		this.setState({
			selectValue: updatedVal.map((val) => JSON.stringify(val)),
			selectedUsers: updatedVal,
		});
		this.triggerChange(updatedVal);
	};
	triggerChange = (changedValue) => {
		// Should provide an event to pass value to Form.
		const onChange = this.props.onChange;
		if (onChange) {
			onChange(changedValue);
		}
	};
	componentDidUpdate = () => {};
	debouncedCallback = debounce(
		(searchText) => {
			const { fetchUsers, workOrder } = this.props;
			fetchUsers(searchText && searchText.trim(), workOrder['id']);
		},
		400,
		false
	);
	handleAddSubscriberSearchDebounced = (value = '') => {
		const searchText = value;
		this.setState({ search: searchText && searchText.trim() });
		this.debouncedCallback(searchText);
	};

	render() {
		const { workOrder, currentUser, messages, match, searched_subscribers } = this.props;
		const { fetching } = this.state;

		const workOrderId = match.params.id;
		const notes = nullSafeGetOrElse(`${workOrderId}.messages`, messages, []);
		const isTyping = nullSafeGetOrElse(`${workOrderId}.isTyping`, messages, false);
		const isTypingText = nullSafeGetOrElse(`${workOrderId}.isTypingText`, messages, false);
		const actualNotes = notes.filter(
			(n) =>
				(n.text && n.text.length > 0) ||
				(n.photo && n.photo.length > 0) ||
				(n.video && n.video.length > 0)
		);

		let subscribers = (workOrder.buyerSubscribers || []).concat(
			workOrder.supplierSubscribers || []
		);
		const filteredUsers = getObjectValues(searched_subscribers.records).map((el) => ({
			...el,
			key: el.email,
		}));

		const optionStyleSupplier = {
			backgroundColor: 'rgb(37, 150, 190,0.05)',
			color: 'red',
			'&:hover': {
				backgroundColor: 'rgb(37, 150, 190,0.15)',
			},
		};
		const optionStyleBuyer = {
			backgroundColor: 'rgb(124, 72, 189,0.05)',
			'&:hover': {
				backgroundColor: 'rgb(124, 72, 189,0.15)',
			},
		};

		return fetching ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="workOrdersDetailNotesPage">
				<LogOnMountWithStandardEventProperties eventType="visited supplier work order notes page" />
				<div style={{ textAlign: 'right', padding: '0', height: '0px' }}>
					<Modal
						width={600}
						title={
							<div>
								<span style={{}}>Work Order {workOrderId}</span>
								<Button
									style={{ float: 'right', marginRight: '32px', marginTop: '-4px' }}
									onClick={this.handleAddSubscriberModalOpen}
									type={'primary'}
								>
									Add People
								</Button>
							</div>
						}
						footer={null}
						closable={true}
						onCancel={this.handleSubscriberModalClose}
						visible={this.state.subscriberModalVisible}
					>
						<List
							itemLayout="horizontal"
							dataSource={subscribers}
							renderItem={(item) => (
								<List.Item>
									<List.Item.Meta
										avatar={
											<ContactAvatar
												hidePopover={true}
												placement="bottomRight"
												userType="supplier"
												contact={item}
											/>
										}
										// @ts-ignore
										title={`${item.nameGiven} ${item.nameFamily}`}
										// @ts-ignore
										description={item.email}
									/>
									<RemoveSubscriberButton
										subscriber={item}
										onRemove={this.handleSubscriberRemoved}
										workOrder={workOrder}
									/>
								</List.Item>
							)}
						/>
					</Modal>
					<Modal
						width={600}
						title={
							<div>
								<span>Add Someone to Work Order {workOrderId}</span>
							</div>
						}
						footer={
							<div style={{ display: 'block', width: '100%' }}>
								<Button
									loading={this.state.addingSubscribers}
									onClick={this.handleAddSubscribers}
									type={'primary'}
								>
									Add Subscribers
								</Button>
							</div>
						}
						closable={true}
						onCancel={this.handleAddSubscriberModalClose}
						visible={this.state.addSubscriberModalVisible}
					>
						<Row key={0}>
							<Select
								mode="multiple"
								style={{ width: '100%' }}
								placeholder="Begin to type a name or email to start searching"
								onSearch={this.handleAddSubscriberSearchDebounced}
								allowClear={true}
								autoClearSearchValue={true}
								onFocus={() => {
									this.handleAddSubscriberSearchDebounced();
								}}
								onChange={this.handleOnChange}
								autoFocus={true}
								value={this.state.selectValue}
								optionLabelProp="label"
								dropdownClassName={style.subscriberDropdown}
								filterOption={() => true}
								notFoundContent={
									this.state.search.length > 0 ? (
										this.state.search.includes('@') ? (
											this.state.search
												.slice(this.state.search.indexOf('@'), this.state.search.length)
												.includes('.') ? (
												<Option key={this.state.search} value={this.state.search}>
													<div style={{ display: 'inline-block' }}>eMail Icon</div>
													<div style={{ display: 'inline-block' }}>
														Invite {this.state.search} to join OpenWrench
													</div>
												</Option>
											) : (
												<div>Sorry! We couldn't find this user. Try searching by name!</div>
											)
										) : (
											<div>Sorry! We couldn't find this user. Try searching by email!</div>
										)
									) : null
								}
							>
								{filteredUsers.length > 0
									? filteredUsers.map((user) => (
											<Option
												disabled={subscribers.some(
													(u) =>
														nullSafeGetOrElse('email', u, '').toLowerCase() ===
														nullSafeGetOrElse('email', user, '').toLowerCase()
												)}
												key={user.email}
												value={JSON.stringify(user)}
												title={user.nameGiven.concat(' ', user.nameFamily)}
												{...{ label: user.nameGiven.concat(' ', user.nameFamily) }}
												style={
													user.contactType === 'supplier' ? optionStyleSupplier : optionStyleBuyer
												}
												className={
													user.contactType === 'supplier'
														? style.subscriberOptionSupplier
														: style.subscriberOptionBuyer
												}
											>
												<ContactAvatar
													hidePopover={true}
													placement="bottomRight"
													userType="supplier"
													contact={user}
													className={'contactAvatar'}
													style={{
														marginBottom: '6px',
														backgroundColor:
															user.contactType === 'supplier' ? '#48a7bd' : '#7c48bd',
													}}
												/>
												<div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
													<div style={{ display: 'inline-block', paddingLeft: '12px' }}>
														<div style={{ display: 'block' }}>
															<div
																style={
																	this.state.search.includes('@')
																		? secondaryFieldStyle
																		: primaryFieldStyle
																}
															>
																{user.nameGiven} {user.nameFamily}
																{subscribers.some(
																	(u) =>
																		nullSafeGetOrElse('email', u, '').toLowerCase() ===
																		nullSafeGetOrElse('email', user, '').toLowerCase()
																) && (
																	<span className="px-2">
																		<Tag>Already in this channel</Tag>
																	</span>
																)}
															</div>
															<div
																style={
																	this.state.search.includes('@')
																		? primaryFieldStyle
																		: secondaryFieldStyle
																}
															>
																{user.email}
															</div>
														</div>
													</div>
												</div>
											</Option>
									  ))
									: null}
							</Select>
						</Row>
					</Modal>
				</div>
				<div className="flex flex-row justify-end pr-4">
					<AvatarGroup
						userType="supplier"
						avatars={subscribers}
						onClick={this.handleSubscriberModalOpen}
						onAddClick={this.handleAddSubscriberModalOpen}
					/>
				</div>
				<div
					id="chatBox"
					style={{ maxHeight: 'calc(100vh - 356px)', overflowY: 'auto', marginTop: '0px' }}
					className="no-scrollbar"
				>
					{actualNotes.length > 0 ? (
						<ul>
							{actualNotes.map((n, idx) => (
								<MessageBubble
									currentUser={currentUser}
									n={n}
									idx={idx}
									subscribers={subscribers}
									userType="supplier"
								/>
							))}
							{isTyping ? (
								<li className="him" key={'typing'}>
									<span style={{ fontSize: 16 }}>{isTypingText}</span>
								</li>
							) : null}
						</ul>
					) : (
						<EmptyState
							graphic={
								<img
									style={{ marginBottom: 8 }}
									src="https://s3.amazonaws.com/mock-data-assets/categories/images/letter owl.svg"
									alt="Add a note to this work order using the box below."
								/>
							}
							headline={'No notes have been added yet'}
							body={
								<div style={{ textAlign: 'center' }}>
									<div style={{ maxWidth: 440, marginBottom: 16 }}>
										Add a note to this work order using the box below.
									</div>
								</div>
							}
						/>
					)}
				</div>
				<div style={{ position: 'absolute', left: 8, right: 8, bottom: 0 }}>
					<SupplierNewWorkOrderNoteForm
						workOrder={workOrder}
						fetchMessages={this.props.fetchMessages}
					/>
				</div>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	workOrdersFetching: state.work_orders.fetching,
	workOrder: getEntityById(ownProps.match.params.id, state.work_orders),
	messages: state.messages,
	currentUser: state.session.currentUser,
	searched_subscribers: state.searched_subscribers,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	getWorkOrder: (id) => dispatch(workOrdersRestCrudThunksForSupplier.readOne(id)),
	fetchMessages: (workOrderId) => dispatch(fetchAllWorkOrderNotesForSupplier(workOrderId)),
	fetchUsers: (search, woId, filters) =>
		dispatch(fetchAllUsersForSuppliers(search, woId, filters)()),
	addBuyerSubscribers: (obj, workOrder) =>
		dispatch(addBuyerSubscribersForSuppliers(obj)(workOrder)),
	addSupplierSubscribers: (obj, workOrder) =>
		dispatch(addSupplierSubscribersForSuppliers(obj)(workOrder)),
	removeSupplierSubscriber: (obj, workOrder) =>
		dispatch(removeSupplierSubscriberForSuppliers(obj)(workOrder)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SupplierWorkOrderNotesDetailPage)
);

import React, { FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Card } from 'antd';
import RequestForProposalForm from '../request_for_proposal_form/RequestForProposalForm';
import { readBiddersForBuyer, readBiddersForSupplier } from '../../thunks/bidders_thunks';
import { BIDDERS_CRUD_ACTION_CREATORS } from '../../actions/bidders_actions';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { ROLE_TYPES } from '../../utils/DataConstants';

const queryString = require('qs');
const { Content } = Layout;

const NewRequestForProposalPage: FC<any> = ({
	location,
	history,
	biddersFormData,
	clearBidders,
	userType,
}): React.ReactElement => {
	useEffect(() => {
		return () => clearBidders();
	}, [clearBidders]);

	const workOrderId = useMemo(() => {
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		return queryParams.workOrderId;
	}, [location.search]);

	const updateBiddersFormData = useMemo(
		() => ({
			...biddersFormData,
			workOrderId,
		}),
		[biddersFormData, workOrderId]
	);

	return (
		<Content>
			<LogOnMountWithStandardEventProperties eventType={`visited ${userType} new rfp page`} />
			<Row style={{ margin: '0.5em -8px' }} gutter={16}>
				<Col span={24}>
					<Card>
						<div>
							<h5 style={{ marginBottom: 32 }}>New Request For Proposal</h5>
							<RequestForProposalForm
								formData={updateBiddersFormData}
								onSuccess={(rec) =>
									history.push(`/${userType}/requestsForProposal/detail/${rec.id}`)
								}
							/>
						</div>
					</Card>
				</Col>
			</Row>
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	biddersFormData: state.bidders.formData,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	saveFormData: (formData) => dispatch(BIDDERS_CRUD_ACTION_CREATORS.saveFormData(formData)),
	clearBidders: () => dispatch(BIDDERS_CRUD_ACTION_CREATORS.clearFormData()),
	getBiddersForRequestForProposal: (rfpId) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? readBiddersForSupplier(rfpId)
				: readBiddersForBuyer(rfpId)
		),
});

const NewRFPPage = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(NewRequestForProposalPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(NewRFPPage);

import * as React from 'react';
import { Route, withRouter } from 'react-router-dom';
import OWAsyncRadio from '../visual_radio_input/VisualRadioInput';
import { spendCategoriesRestCrudThunksForBuyer } from '../../thunks/spend_categories_thunks';
import { connect } from 'react-redux';
import { locationsRestCrudThunksForBuyer } from '../../thunks/locations_thunks';
import {
	problemTypesRestCrudThunksForBuyer,
	problemTypesTopLevelForBuyer,
} from '../../thunks/problem_types_thunks';
import { assetsRestCrudThunksForBuyer } from '../../thunks/assets_thunks';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { MenuOutlined } from '@ant-design/icons';
import { Button, Drawer, Input, Menu, Modal, Progress, Table, message } from 'antd';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import { imageUploadValidation, normFile } from '../../utils/ImageUtils';
import { getProtectedImageUriForBuyer } from '../../utils/FileAccessUtils';
import { TwoLineList } from '../two_line_list/TwoLineList';
import { ProtectedImageThumbnails } from '../protected_image_thumbnails/ProtectedImageThumbnails';
import { ROLE_TYPES, WO_STEP_NAMES } from '../../utils/DataConstants';
import {
	checkIsWarrantyWorkOrder,
	filterKeys,
	getCurrency,
	getFiltersFromLocation,
	getImageCountErrorRules,
	getWarrantyStatus,
	nullSafeGet,
	nullSafeGetOrElse,
	mergeTroubleshootingRoutes,
	getElementAt,
} from '../../utils/DataAccessUtils';
import OWRadio from '../static_visual_radio_input/StaticVisualRadioInput';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { WORK_ORDERS_CRUD_ACTION_CREATORS } from '../../actions/work_orders_actions';
import { workOrderPrioritiesRestCrudThunksForBuyer } from '../../thunks/work_order_priorities_thunks';
import { buyersRestCrudThunksForBuyer } from '../../thunks/buyers_thunks';
import { workOrdersRestCrudThunksForBuyer } from '../../thunks/work_orders_thunks';
import { WORK_ORDER_STATUSES } from '../../constants/WorkOrderStatuses';
import BuyerLocationUpdateForm from '../buyer_location_update_form/BuyerLocationUpdateForm';
import { buyerRoleUpdateUser } from '../../thunks/buyer_contacts_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { areasRestCrudThunksForBuyer, areasTopLevelForBuyer } from '../../thunks/areas_thunks';
import { workOrderAttributesRestCrudThunksForBuyer } from '../../thunks/work_order_attributes_thunks';
import { retrieveCachedUserDetails } from '../../thunks/session_thunks';
import { getBackLinkStates, getQueryStringFromObject } from '../../utils/HistoryUtils';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import TroubleshootingDisplay from '../common/TroubleshootingDisplay';
import { troubleshootingRestCrudThunksForBuyer } from '../../thunks/troubleshooting_thunks';
import AssetNumberDisplay from '../asset_row_display/AssetNumberDisplay';
import { troubleshootingAnswersRestCrudThunksForBuyer } from '../../thunks/troubleshooting_answer_thunks';
import {
	getExistingTsAnswersFromStateForWOTsRoute,
	getTroubleshootingAnswersForNecessaryWOSteps,
	getUpdatedTroubleshootingStates,
	getUpdatedTsAnswersState,
} from '../../utils/TroubleshootingUtils';
import { WorkOrderRowDisplay } from '../work_order_row_display/WorkOrderRowDisplay';
import { getExchangeRate } from '../../thunks/currencies_thunks';
import OWUpload from '../OWUpload';
import { getAssetFilterForWOCreation } from '../../utils/WorkOrderUtils';

require('./NewServiceRequestWizardPage.less');

const DependentFieldOrder = [
	{
		key: 'locations',
		default: undefined,
	},
	{
		key: 'areaLevel1Id',
		default: undefined,
	},
	{
		key: 'area2Available',
		default: false,
	},
	{
		key: 'areaLevel2Id',
		default: undefined,
	},
	{
		key: 'area3Available',
		default: false,
	},
	{
		key: 'areaLevel3Id',
		default: undefined,
	},
	{
		key: 'area4Available',
		default: false,
	},
	{
		key: 'problemTypeLevel1Id',
		default: undefined,
	},
	{
		key: 'problemTypeLevel2Available',
		default: false,
	},
	{
		key: 'problemTypeLevel2Id',
		default: undefined,
	},
	{
		key: 'problemTypeLevel3Available',
		default: false,
	},
	{
		key: 'problemTypeLevel3Id',
		default: undefined,
	},
	{
		key: 'problemTypeLevel4Available',
		default: false,
	},
	{
		key: 'asset',
		default: undefined,
	},
];

interface NewServiceRequestWizardProps extends FormComponentProps {
	onSubmit: any;
	onCancel: any;
	redirectForwardUrl: any;
	saveFormData: any;
	visible: boolean;
	createErrors: any;
	updateErrors: any;
	creating: boolean;
	updating: boolean;
	location: any;
	asset: any;
	currentUser: any;
	companyConfig: any;
}
const duplicateWorkOrderColumns = [
	{
		title: 'Name',
		dataIndex: 'id',
		render: (text, item) => <WorkOrderRowDisplay workOrder={item} />,
	},
];

const DEFAULT_BACK_PATH = '/buyer/workOrders';

class NewServiceRequestWizardPage extends React.Component<any, any> {
	formRefs: any;

	constructor(props) {
		super(props);
		this.formRefs = {
			locationUpdateForm: null,
		};
		this.state = {
			allPhotos: [],
			willChooseDates: false,
			chosenDates: [],
			willUploadPicture: false,
			uploadLoading: false,
			pictureURL: null,
			area: {},
			locations: [],
			locationUpdateVisible: false,
			duplicateWorkOrders: [],
			userContact: {},
			previewVisible: false,
			previewImage: false,
			navMenuVisible: false,
			location: {},
			woPriority: {},
			userLocation: {},
			problemTypePriorLevelId: null,
			problemTypePriorLevel: null,
			spendCategoryId: null,
			industryId: 1,
			priorityIsRequired: true,
			submittedByNameIsRequired: false,
			currentRoute: '',
			assetsAvailable: false,
			backLinkParams: '',
			backLinkTo: DEFAULT_BACK_PATH,
			troubleshootingRoutes: [],
			troubleshootingAnswers: [],
			tsAnswerUploading: false,
			currencyExchangeRateLocationToSupplier: 1,
			currencyExchangeRateBuyerToLocation: 1,
			numPicturesRequired: 0,
			updatingLocation: false,
		};
	}

	componentDidMount() {
		document.body.classList.add('newServiceRequestWizardBody');

		const { fetchLocations, form, companyConfig, fetchBuyer, history, currentUser, location } =
			this.props;
		this.setState(getBackLinkStates(location, DEFAULT_BACK_PATH));

		fetchBuyer(nullSafeGet('facility.buyerCompanyId', currentUser)).then((buyer) => {
			this.setState({ industryId: buyer.industryId });
		});

		if (
			nullSafeGetOrElse('config.newServiceRequest.showPriorities', companyConfig, {}).value ===
			false
		) {
			this.setState({ priorityIsRequired: false });
		}

		if (nullSafeGetOrElse('isSharedContact', currentUser, false)) {
			this.setState({ submittedByNameIsRequired: true });
		}

		fetchLocations({}).then((locations) => {
			if (locations && locations.length === 1) {
				this.setState({ locations, userLocation: locations[0] });
				if (nullSafeGet('config.newServiceRequest.showSingleLocation', companyConfig)) {
					history.push('/buyer/workOrders/requestService/userLocation');
				} else {
					form.setFieldsValue({ locationId: locations[0].id });
					this.handleLocation(locations[0].id);
				}
			} else {
				history.push('/buyer/workOrders/requestService/locations');
			}
		});
	}

	componentWillMount() {
		document.body.classList.add('newServiceRequestWizardBody');
	}

	componentWillUnmount() {
		document.body.classList.remove('newServiceRequestWizardBody');
		const { clearFormData } = this.props;
		clearFormData();
	}

	removeTroubleshootingRoutes = (currentRoute) => {
		const routeHierarchy = [
			WO_STEP_NAMES.LOCATIONS,
			WO_STEP_NAMES.AREA_TOP_LEVEL,
			WO_STEP_NAMES.AREA_NEXT_LEVEL,
			WO_STEP_NAMES.AREA_NEXT_BRANCH,
			WO_STEP_NAMES.PROBLEM_TYPE_TOP_LEVEL,
			WO_STEP_NAMES.PROBLEM_TYPE_NEXT_LEVEL,
			WO_STEP_NAMES.PROBLEM_TYPE_NEXT_BRANCH,
			WO_STEP_NAMES.ASSET,
			WO_STEP_NAMES.ASSET_CONDITION,
			WO_STEP_NAMES.PRIORITY,
			WO_STEP_NAMES.SUPPLIER,
		];
		this.setState(getUpdatedTroubleshootingStates(routeHierarchy, currentRoute, this.state));
	};

	onRouteChanged = () => {
		const pathName = nullSafeGetOrElse('props.location.pathname', this, '');
		const currentRoute = getElementAt(pathName.split('/'), -1);
		this.removeTroubleshootingRoutes(currentRoute);
		const params = getFiltersFromLocation(this.props.location);
		const allowedStateKeys = [
			'area2Available',
			'areaPriorLevelId',
			'areaPriorLevel',
			'problemTypeLevel2Available',
			'problemTypeLevel3Available',
			'problemTypePriorLevelId',
			'problemTypePriorLevel',
			'spendCategoryId',
		];
		const newState = {
			...filterKeys(params, allowedStateKeys, true),
			currentRoute,
		};
		this.setState(newState);
	};

	getTroubleshootingParams = () => {
		const problemTypeId =
			this.state.problemTypeLevel3Id ||
			this.state.problemTypeLevel2Id ||
			this.state.problemTypeLevel1Id;
		return {
			...(nullSafeGet('state.location.id', this) && {
				locationId: nullSafeGet('state.location.id', this),
			}),
			...(problemTypeId && { problemTypeId }),
			...(nullSafeGet('state.asset.assetTypeId', this) && {
				assetTypeId: nullSafeGet('state.asset.assetTypeId', this),
			}),
			...(nullSafeGet('state.asset.id', this) && {
				assetId: nullSafeGet('state.asset.id', this),
			}),
			...(nullSafeGet('state.woPriority.id', this) && {
				workOrderPriorityId: nullSafeGet('state.woPriority.id', this),
			}),
			...(nullSafeGet('state.supplier.facility.id', this) && {
				supplierFacilityId: nullSafeGet('state.supplier.facility.id', this),
			}),
		};
	};

	checkTroubleShooting = (args, previousStep) => {
		const { fetchTroubleshooting } = this.props;
		const params = this.getTroubleshootingParams();
		fetchTroubleshooting(params).then((res) => {
			if (res && res.length > 0) {
				const existingTroubleshootingRoutes = nullSafeGetOrElse(
					'state.troubleshootingRoutes',
					this,
					[]
				);
				if (existingTroubleshootingRoutes.find((_) => _.id === res[0].id)) {
					this.proceedToNextStep(args);
				} else {
					this.setState({
						troubleshootingRoutes: [
							...existingTroubleshootingRoutes,
							...[
								{
									...res[0],
									args,
									previousStep,
								},
							],
						],
					});
					this.proceedToNextStep({
						validationSteps: [],
						destination: `troubleshooting-${res[0].id}`,
					});
				}
			} else {
				this.proceedToNextStep(args);
			}
		});
	};

	componentDidUpdate(prevProps): void {
		if (this.props.location !== prevProps.location) {
			this.onRouteChanged();
		}
	}

	updateTroubleshootingAnswerIfAvailable = (workOrderId) => {
		const { createTroubleshootingAnswersBulk } = this.props;
		const answers = nullSafeGetOrElse('troubleshootingAnswers', this.state, []);
		return new Promise((resolve, reject) => {
			if (answers.length > 0) {
				this.setState({ tsAnswerUploading: true });
				createTroubleshootingAnswersBulk(
					answers.map((_) => ({
						..._,
						workOrderId,
					}))
				)
					.then((res) => resolve(res))
					.catch((err) => {
						message.error(err);
						reject(err);
					})
					.finally(() => this.setState({ tsAnswerUploading: false }));
			} else {
				resolve(true);
			}
		});
	};

	handleSubmit = () => {
		const { form, formData, history, currentUser, submitServiceRequestForm } = this.props;
		const { location } = this.state;
		form.validateFields((err, values) => {
			if (!err) {
				// put image data into proper format to persist
				const picturesInfo = this.state.allPhotos.map((p) => {
					const fileName = nullSafeGetOrElse('response.data.fileName', p, '');
					const fileId = nullSafeGetOrElse('response.data.fileId', p, '');
					return `${fileId}/${fileName}`;
				});
				const locationCurrencyId = getCurrency({ location }).id;

				const contractorDetails = {
					contractorContacts: [],
				};

				const DEFAULT_SPEND_CATEGORY = '17';

				const workOrder = {
					title: values.title,
					woPriorityId: nullSafeGet('id', this.state.woPriority)
						? parseInt(nullSafeGet('id', this.state.woPriority), 10)
						: null,
					problemTypeId: nullSafeGet('id', this.state.problemType)
						? parseInt(nullSafeGet('id', this.state.problemType), 10)
						: null,
					spendCategoryId: parseInt(
						nullSafeGetOrElse('spendCategoryId', this.state, DEFAULT_SPEND_CATEGORY),
						10
					),
					locationId: parseInt(values.locationId, 10),
					areaId: nullSafeGet('id', this.state.area)
						? parseInt(nullSafeGet('id', this.state.area), 10)
						: null,
					assetId: formData.assetId ? parseInt(formData.assetId, 10) : null,
					...(formData.assetId && {
						isAssetDown: formData.isAssetDown === 'true' ? true : false,
					}),
					images: picturesInfo,
					locationCurrencyId: locationCurrencyId,
					status: WORK_ORDER_STATUSES.pendingApproval,
					displayStatus: 'pending',
					description: formData.additionalInfo,
					buyerFacilityId: nullSafeGet('buyerFacilityId', location),
					buyerCompanyId: values.buyerCompanyId,
					createdBy: currentUser.email,
					supplierFacilityId: nullSafeGet('supplierFacilityId', this.state),
					isWarranty: checkIsWarrantyWorkOrder(
						nullSafeGet('asset.warranties.0', this.state),
						nullSafeGet('supplierFacilityId', this.state)
					),
					contractorDetails: contractorDetails,
					submittedByName: formData.submittedByName,
					notes: [],
					buyerAttachments: formData.buyerAttachments,
					nte: this.state.nte,
				};

				submitServiceRequestForm(workOrder).then((rec) => {
					this.updateTroubleshootingAnswerIfAvailable(rec.id).then(() =>
						history.push(
							`/buyer/workOrders/detail/${rec.id}`.concat('?', this.state.backLinkParams)
						)
					);
				});
			} else {
				console.log(err);
			}
		});
	};

	handleUploadPicture = (value) => {
		value.target.value === 'yes'
			? this.setState({
					willUploadPicture: true,
			  })
			: this.setState({
					willUploadPicture: false,
			  });
	};

	provideLocationAccess = (record) => {
		const { locations } = this.state;
		this.setState({
			locationUpdateVisible: true,
			userContact: {
				contact: {
					...record,
				},
				role: record['roles'],
				locationIds: locations.map((l) => l.id),
			},
		});
	};

	proceedToNextStep = (values) => {
		const { validationSteps, destination, useReplace = false, params = {} } = values;
		const { form, history } = this.props;
		form.validateFields(validationSteps, (err) => {
			if (err) {
				console.log(err);
				return;
			}
			window.scrollTo(0, 0);
			const navigateFunction = useReplace ? history.replace : history.push;
			const queryString = getQueryStringFromObject(params);
			const navigateTo = `/buyer/workOrders/requestService/${destination}${
				queryString ? `?${queryString}` : ''
			}`;
			navigateFunction(navigateTo);
		});
	};

	goNext = (
		validationSteps,
		destination,
		stepName,
		ingnoreTroubleshooting = false,
		useReplace = false,
		params = {}
	) => {
		const args = {
			validationSteps,
			destination,
			useReplace,
			params,
		};

		const requiredSteps = [
			WO_STEP_NAMES.LOCATIONS,
			WO_STEP_NAMES.PROBLEM_TYPE_TOP_LEVEL,
			WO_STEP_NAMES.PROBLEM_TYPE_NEXT_LEVEL,
			WO_STEP_NAMES.PROBLEM_TYPE_NEXT_BRANCH,
			WO_STEP_NAMES.ASSET,
			WO_STEP_NAMES.PRIORITY,
			WO_STEP_NAMES.SUPPLIER,
		];

		!ingnoreTroubleshooting && requiredSteps.includes(stepName)
			? this.checkTroubleShooting(args, stepName)
			: this.proceedToNextStep(args);
	};

	cancelRequest = () => this.props.history.push(this.state.backLinkTo);

	goBack = () => {
		const { history } = this.props;
		history.goBack();
	};

	goNextAfterBriefPause = (
		validationSteps,
		destination,
		currentStep,
		ignoreTroubleshooting = false
	) => {
		setTimeout(
			() => this.goNext(validationSteps, destination, currentStep, ignoreTroubleshooting),
			350
		);
	};

	resetFieldAFter = (changedState) => {
		const index = DependentFieldOrder.findIndex((_) => _.key === changedState);
		const newState =
			index > -1
				? DependentFieldOrder.slice(index + 1).reduce((obj, field) => {
						obj[field.key] = field.default;
						return obj;
				  }, {})
				: {};
		this.setState(newState);
	};

	changeId = (key, value) => {
		this.setState({ [key]: value });
		this.resetFieldAFter(key);
	};

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	handleBuyerLocationUpdateCancel = () => {
		this.setState({
			locationUpdateVisible: false,
		});
	};

	handleBuyerLocationUpdateSubmit = () => {
		const { roleUpdateUser } = this.props;
		const form = this.formRefs['locationUpdateForm'].props.form;
		this.setState({ updatingLocation: true });
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			const updateRole = {
				userId: values.email,
				roles: [values.roles],
				locationIds: values.locationIds,
			};

			roleUpdateUser(updateRole)
				.then(() => {
					this.setState({ locationUpdateVisible: false });
					this.goNext([], WO_STEP_NAMES.LOCATIONS, '');
				})
				.catch((err) => message.error(err))
				.finally(() => this.setState({ updatingLocation: false }));
		});
	};

	handlePreview = (obj) => {
		let thumbUrl = null;
		let fullUrl = null;
		let photoInfo = null;
		if (obj && obj.response && obj.response.data) {
			const imgFileId = obj.response.data.fileId;
			const imgFileName = obj.response.data.fileName;
			thumbUrl = getProtectedImageUriForBuyer(imgFileId, imgFileName, 100, 100);
			fullUrl = getProtectedImageUriForBuyer(imgFileId, imgFileName, 800, 800);
			photoInfo = {
				uid: imgFileId,
				size: 1,
				name: imgFileName,
				filename: imgFileName,
				url: fullUrl,
				status: 'done',
				thumbUrl: thumbUrl,
				response: { data: { fileName: imgFileName, fileId: imgFileId } },
				type: 'image',
			};
		}
		this.setState({
			photoInfo: [photoInfo],
			previewImage: fullUrl || thumbUrl,
			previewVisible: true,
		});
	};

	handleImageUpdate = (info) => {
		this.setState({ allPhotos: info.fileList });
	};

	handleUserLocation = (resp) => {
		const { userLocation } = this.state;
		const { form, currentUser, saveFormData } = this.props;
		if (resp == 1) {
			form.setFieldsValue({ locationId: userLocation.id });
			saveFormData({ locationId: userLocation.id });
			this.handleLocation(userLocation.id);
		} else {
			this.provideLocationAccess(currentUser);
		}
	};

	handleDuplicate = (resp) => {
		if (resp == 1) {
			this.props.history.push('/buyer/workOrders');
		} else {
			this.goNextAfterBriefPause([], WO_STEP_NAMES.ASSET_CONDITION, WO_STEP_NAMES.DUPLICATE_CHECK);
		}
	};

	handleNumPicturesRequiredForProblemType = (problemType) => {
		this.setState({
			numPicturesRequired: nullSafeGetOrElse('serviceRequestNumPicturesRequired', problemType, 0),
		});
	};

	handleProblemTypeChange: any = (problemTypeId) => {
		const { fetchProblemType, fetchWorkOrderAttributes, fetchWorkOrderPriority } = this.props;
		const { currencyExchangeRateBuyerToLocation } = this.state;
		if (problemTypeId) {
			const problemTypePromise = fetchProblemType(problemTypeId);
			const workOrderAttributePromise = fetchWorkOrderAttributes({ problemTypeId });

			return new Promise((resolve, reject) => {
				Promise.all([problemTypePromise, workOrderAttributePromise])
					.then((values) => {
						const problemType = values[0];
						this.handleNumPicturesRequiredForProblemType(problemType);
						this.setState({ problemType });

						const workOrderAttributes = values[1];
						if (nullSafeGetOrElse('length', workOrderAttributes, 0) > 0) {
							if (workOrderAttributes[0].NTEAmount !== undefined) {
								this.setNTE(workOrderAttributes[0].NTEAmount * currencyExchangeRateBuyerToLocation);
							}
						}
						if (workOrderAttributes.length > 0) {
							if (workOrderAttributes[0].workOrderPriorityId) {
								fetchWorkOrderPriority(workOrderAttributes[0].workOrderPriorityId).then(
									(workOrderPriority) => this.setState({ woPriority: workOrderPriority })
								);
							}
						}

						resolve(problemType);
					})
					.catch((err) => reject(err));
			});
		}
	};

	checkAssetAndProceed = (problemTypeId, currentStep, ignoreTroubleshooting = false) => {
		const { fetchAssets, companyConfig, currentUser } = this.props;
		fetchAssets(
			getAssetFilterForWOCreation(ROLE_TYPES.BUYER)(companyConfig, currentUser, this.state),
			null,
			{
				current: 1,
				pageSize: 1,
			}
		).then((assets) => {
			const assetsAvailable = !!(assets && assets.length > 0);

			this.goNext(
				[],
				assetsAvailable ? WO_STEP_NAMES.ASSET : WO_STEP_NAMES.TITLE,
				currentStep,
				ignoreTroubleshooting
			);
			this.setState({ assetsAvailable });
		});
	};

	handleProblemTypeTopLevelChange = (problemTypeId) => {
		this.changeId('problemTypeLevel1Id', problemTypeId);
		this.handleProblemTypeChange(problemTypeId).then((problemType) => {
			if (problemType.hasChildren) {
				this.goNext(
					[],
					WO_STEP_NAMES.PROBLEM_TYPE_NEXT_LEVEL,
					WO_STEP_NAMES.PROBLEM_TYPE_TOP_LEVEL,
					false,
					false,
					{
						problemTypeLevel2Available: true,
						problemTypePriorLevelId: problemTypeId,
						problemTypePriorLevel: problemType.name,
						spendCategoryId: problemType.spendCategoryId,
					}
				);
			} else {
				this.setState({
					problemTypeLevel2Available: false,
					spendCategoryId: problemType.spendCategoryId,
					problemTypePriorLevelId: undefined,
					problemTypePriorLevel: undefined,
				});
				this.checkAssetAndProceed(problemTypeId, WO_STEP_NAMES.PROBLEM_TYPE_TOP_LEVEL);
			}
		});
	};

	handleProblemTypeTopLevelClick = (problemTypeId, ignoreTroubleshooting) => {
		this.setState({ problemTypeLevel1Id: problemTypeId });
		this.handleProblemTypeChange(problemTypeId).then((problemType) => {
			if (problemType.hasChildren) {
				this.goNext(
					[],
					WO_STEP_NAMES.PROBLEM_TYPE_NEXT_LEVEL,
					WO_STEP_NAMES.PROBLEM_TYPE_TOP_LEVEL,
					ignoreTroubleshooting,
					false,
					{
						problemTypeLevel2Available: true,
						problemTypePriorLevelId: problemTypeId,
						problemTypePriorLevel: problemType.name,
						spendCategoryId: problemType.spendCategoryId,
					}
				);
			} else {
				this.setState({
					problemTypeLevel2Available: false,
					problemTypePriorLevel: problemType.name,
					spendCategoryId: problemType.spendCategoryId,
				});
				this.checkAssetAndProceed(
					problemTypeId,
					WO_STEP_NAMES.PROBLEM_TYPE_TOP_LEVEL,
					ignoreTroubleshooting
				);
			}
		});
	};

	handleProblemTypeNextLevelChange = (problemTypeId, ignoreTroubleshooting) => {
		this.changeId('problemTypeLevel2Id', problemTypeId);
		this.handleProblemTypeChange(problemTypeId).then((problemType) => {
			if (problemType.hasChildren) {
				this.goNext(
					[],
					WO_STEP_NAMES.PROBLEM_TYPE_NEXT_BRANCH,
					WO_STEP_NAMES.PROBLEM_TYPE_NEXT_LEVEL,
					ignoreTroubleshooting,
					false,
					{
						problemTypeLevel3Available: true,
						problemTypePriorLevelId: problemTypeId,
						problemTypePriorLevel: problemType.name,
						spendCategoryId: problemType.spendCategoryId,
					}
				);
			} else {
				this.setState({
					problemTypeLevel3Available: false,
					problemTypePriorLevelId: undefined,
					spendCategoryId: problemType.spendCategoryId,
					problemTypePriorLevel: problemType.name,
				});
				this.checkAssetAndProceed(
					problemTypeId,
					WO_STEP_NAMES.PROBLEM_TYPE_NEXT_LEVEL,
					ignoreTroubleshooting
				);
			}
		});
	};

	handleProblemTypeNextLevelBranchChange = (problemTypeId) => {
		this.changeId('problemTypeLevel3Id', problemTypeId);
		this.handleProblemTypeChange(problemTypeId).then((problemType) => {
			this.setState(
				{
					problemTypePriorLevelId: problemTypeId,
					problemTypePriorLevel: problemType.name,
					spendCategoryId: problemType.spendCategoryId,
				},
				() => {
					if (problemType.hasChildren) {
						this.goNext(
							[],
							WO_STEP_NAMES.PROBLEM_TYPE_NEXT_LEVEL,
							WO_STEP_NAMES.PROBLEM_TYPE_NEXT_BRANCH
						);
					} else {
						this.checkAssetAndProceed(problemTypeId, WO_STEP_NAMES.PROBLEM_TYPE_NEXT_BRANCH);
					}
				}
			);
		});
	};

	handleAreaTopLevelChange = (areaId, ignoreTroubleshooting) => {
		const { fetchArea } = this.props;
		this.changeId('areaLevel1Id', areaId);
		fetchArea(areaId).then((area) => {
			this.setState({ area: area });
			if (area.hasChildren) {
				this.goNext(
					[],
					WO_STEP_NAMES.AREA_NEXT_LEVEL,
					WO_STEP_NAMES.AREA_TOP_LEVEL,
					ignoreTroubleshooting,
					false,
					{
						area2Available: true,
						areaPriorLevelId: areaId,
						areaPriorLevel: area.name,
					}
				);
			} else {
				this.setState(
					{
						area2Available: false,
						areaPriorLevelId: undefined,
						areaPriorLevel: undefined,
					},
					() =>
						this.goNext(
							[],
							WO_STEP_NAMES.PROBLEM_TYPE_TOP_LEVEL,
							WO_STEP_NAMES.AREA_TOP_LEVEL,
							ignoreTroubleshooting
						)
				);
			}
		});
	};

	handleAreaNextLevelChange = (areaId, ignoreTroubleshooting) => {
		const { fetchArea } = this.props;
		this.changeId('areaLevel2Id', areaId);
		fetchArea(areaId).then((area) => {
			this.setState({ area: area });
			if (area.hasChildren) {
				this.goNext(
					[],
					WO_STEP_NAMES.AREA_NEXT_BRANCH,
					WO_STEP_NAMES.AREA_NEXT_LEVEL,
					ignoreTroubleshooting,
					false,
					{
						area3Available: true,
						areaPriorLevelId: areaId,
						areaPriorLevel: area.name,
					}
				);
			} else {
				this.setState(
					{
						area3Available: false,
						areaPriorLevelId: undefined,
						areaPriorLevel: undefined,
					},
					() =>
						this.goNext(
							[],
							WO_STEP_NAMES.PROBLEM_TYPE_TOP_LEVEL,
							WO_STEP_NAMES.AREA_NEXT_LEVEL,
							ignoreTroubleshooting
						)
				);
			}
		});
	};

	handleAreaNextLevelBranchChange = (areaId) => {
		const { fetchArea } = this.props;
		this.changeId('areaLevel3Id', areaId);
		fetchArea(areaId).then((area) => {
			this.setState({ area: area });
			if (area.hasChildren) {
				this.goNext(
					[],
					WO_STEP_NAMES.AREA_NEXT_LEVEL,
					WO_STEP_NAMES.AREA_NEXT_BRANCH,
					false,
					false,
					{
						area4Available: true,
						areaPriorLevelId: areaId,
						areaPriorLevel: area.name,
					}
				);
			} else {
				this.setState(
					{
						area4Available: false,
						areaPriorLevelId: undefined,
						areaPriorLevel: undefined,
					},
					() =>
						this.goNext([], WO_STEP_NAMES.PROBLEM_TYPE_TOP_LEVEL, WO_STEP_NAMES.AREA_NEXT_BRANCH)
				);
			}
		});
	};

	handleBuyerCurrencyToLocationExchangeRate = (location) => {
		const { fetchExchangeRate, currentUser } = this.props;
		const locationCurrencyId = getCurrency({ location }).id;
		const buyerCurrencyId = getCurrency({ currentUser }).id;
		if (locationCurrencyId !== buyerCurrencyId) {
			fetchExchangeRate(locationCurrencyId, buyerCurrencyId, currentUser.contactType).then(
				(rate) => {
					this.setState({
						currencyExchangeRateBuyerToLocation: nullSafeGetOrElse('conversionRate', rate, 1),
					});
				}
			);
		} else {
			this.setState({ currencyExchangeRateBuyerToLocation: 1 });
		}
	};

	handleLocation = (locationId) => {
		const { fetchLocation, fetchAreas } = this.props;
		const areasPromise = fetchAreas({ locationId });
		const locationPromise = fetchLocation(locationId);
		this.resetFieldAFter('locations');
		Promise.all([areasPromise, locationPromise]).then(([areas, location]) => {
			this.handleBuyerCurrencyToLocationExchangeRate(location);
			this.setState({ location, areas, area: {} }, () => {
				if (areas && areas.length > 0) {
					this.goNextAfterBriefPause([], WO_STEP_NAMES.AREA_TOP_LEVEL, WO_STEP_NAMES.LOCATIONS);
				} else {
					this.goNextAfterBriefPause(
						[],
						WO_STEP_NAMES.PROBLEM_TYPE_TOP_LEVEL,
						WO_STEP_NAMES.LOCATIONS
					);
				}
			});
		});
	};

	setNTE = (nte) => {
		this.setState({ nte });
	};

	handleLocationClick = (locationId, ignoreTroubleshooting) => {
		const { fetchAreas } = this.props;
		fetchAreas({ locationId }).then((areas) => {
			if (areas && areas.length > 0) {
				this.goNextAfterBriefPause(
					[],
					WO_STEP_NAMES.AREA_TOP_LEVEL,
					WO_STEP_NAMES.LOCATIONS,
					ignoreTroubleshooting
				);
			} else {
				this.goNextAfterBriefPause(
					[],
					WO_STEP_NAMES.PROBLEM_TYPE_TOP_LEVEL,
					WO_STEP_NAMES.LOCATIONS,
					ignoreTroubleshooting
				);
			}
		});
	};

	handleWorkOrderPriority = (woPriorityId) => {
		const { fetchWorkOrderPriority, currentUser } = this.props;
		fetchWorkOrderPriority(woPriorityId).then((woPriority) => {
			this.setState({ woPriority }, () => {
				if (nullSafeGetOrElse('isSharedContact', currentUser, false)) {
					this.goNextAfterBriefPause([], WO_STEP_NAMES.SUBMITTED_BY, WO_STEP_NAMES.PRIORITY);
				} else {
					this.goNextAfterBriefPause([], WO_STEP_NAMES.COMPLETION, WO_STEP_NAMES.PRIORITY);
				}
			});
		});
	};

	handleWorkOrderPriorityClick = () => {
		const { currentUser } = this.props;
		if (nullSafeGetOrElse('isSharedContact', currentUser, false)) {
			this.goNextAfterBriefPause([], WO_STEP_NAMES.SUBMITTED_BY, WO_STEP_NAMES.PRIORITY);
		} else {
			this.goNextAfterBriefPause([], WO_STEP_NAMES.COMPLETION, WO_STEP_NAMES.PRIORITY);
		}
	};

	handleAsset = (assetId) => {
		const { fetchAsset, fetchWorkOrders } = this.props;
		const { currencyExchangeRateBuyerToLocation } = this.state;
		fetchAsset(assetId).then((asset) => {
			const assetWarrantyStatus = getWarrantyStatus(nullSafeGet('warranties.0', asset));
			const assetSupplierId = nullSafeGet('warranties.0.provider.supplierFacilityId', asset);
			const assetWarrantyNTE = nullSafeGet('warranties.0.NTEAmount', asset);
			if (assetWarrantyStatus === 'Active' && assetWarrantyNTE) {
				this.setNTE(assetWarrantyNTE * currencyExchangeRateBuyerToLocation);
			}
			this.setState(
				{
					asset,
					supplierFacilityId:
						assetWarrantyStatus === 'Active' && assetSupplierId
							? assetSupplierId
							: nullSafeGet('supplierFacilityId', this.state),
				},
				() => {
					fetchWorkOrders({
						assetId,
						locationId: nullSafeGet('location.id', this.state),
						isPM: false,
						displayStatuses: ['Pending', 'Open', 'InProgress', 'OnHold'],
					}).then((workOrders) => {
						if (!workOrders || workOrders.length === 0) {
							this.goNext([], WO_STEP_NAMES.ASSET_CONDITION, WO_STEP_NAMES.ASSET);
						} else {
							this.setState({ duplicateWorkOrders: workOrders });
							this.goNext([], WO_STEP_NAMES.DUPLICATE_CHECK, WO_STEP_NAMES.ASSET);
						}
					});
				}
			);
		});
	};

	handleAssetClick = (assetId) => {
		const { fetchWorkOrders } = this.props;
		fetchWorkOrders({
			assetId,
			locationId: nullSafeGet('location.id', this.state),
			isPM: false,
		}).then((workOrders) => {
			workOrders = workOrders.filter((wo) => ['Open', 'Pending'].includes(wo.displayStatus));
			if (!workOrders || workOrders.length === 0) {
				this.goNext([], WO_STEP_NAMES.ASSET_CONDITION, WO_STEP_NAMES.ASSET);
			} else {
				this.setState({ duplicateWorkOrders: workOrders });
				this.goNext([], WO_STEP_NAMES.DUPLICATE_CHECK, WO_STEP_NAMES.ASSET);
			}
		});
	};

	handleAssetCondition = () => {
		this.goNextAfterBriefPause([], WO_STEP_NAMES.TITLE, WO_STEP_NAMES.ASSET_CONDITION);
	};

	handleNavMenuClose = () => {
		this.setState({ navMenuVisible: false });
	};

	handleNavMenuOpen = () => {
		this.setState({ navMenuVisible: true });
	};

	handleNavMenuClick = (e) => {
		const methods = {
			areasTopLevel: () => this.handleLocationClick(nullSafeGet('location.id', this.state), true),
			areasNextLevel: () => this.handleAreaTopLevelChange(this.state.areaLevel1Id, true),
			areasNextBranch: () => this.handleAreaNextLevelChange(this.state.areaLevel2Id, true),
			problemTypeNextLevel: () =>
				this.handleProblemTypeTopLevelClick(this.state.problemTypeLevel1Id, true),
			problemTypeNextBranch: () =>
				this.handleProblemTypeNextLevelChange(this.state.problemTypeLevel2Id, true),
		};
		methods[e.key] ? methods[e.key]() : this.goNext([], e.key, '');
	};

	handleCancel = () => this.setState({ previewVisible: false });

	isTroubleshootingAllowedToProceed = (checkSteps) => {
		const tsRoutes = nullSafeGetOrElse('state.troubleshootingRoutes', this, []);
		const allowedToProceed =
			tsRoutes
				.filter((_) => checkSteps.includes(_.previousStep))
				.filter((_) => !nullSafeGetOrElse('troubleshootingSteps', _, [{}])[0].canProceedToNextStep)
				.length === 0;

		return allowedToProceed;
	};

	isLocationFieldsSelected = () => {
		const {
			userLocation,
			location,
			areas,
			areaLevel1Id,
			area2Available,
			area3Available,
			areaLevel2Id,
			areaLevel3Id,
		} = this.state;
		const locationFieldSelected =
			userLocation.id || location.id
				? areas && areas.length > 0
					? areaLevel1Id
						? area2Available
							? areaLevel2Id
								? area3Available
									? areaLevel3Id
										? true
										: false
									: true
								: false
							: true
						: false
					: true
				: false;

		const checkSteps = [WO_STEP_NAMES.LOCATIONS];

		return locationFieldSelected && this.isTroubleshootingAllowedToProceed(checkSteps);
	};

	isProblemTypeAndAssetSelected = () => {
		const {
			problemTypeLevel1Id,
			problemTypeLevel2Available,
			problemTypeLevel2Id,
			problemTypeLevel3Available,
			problemTypeLevel3Id,
		} = this.state;
		const problemTypeSelected = problemTypeLevel1Id
			? problemTypeLevel2Available
				? problemTypeLevel2Id
					? problemTypeLevel3Available
						? problemTypeLevel3Id
							? true
							: false
						: true
					: false
				: true
			: false;

		const checkSteps = [
			WO_STEP_NAMES.PROBLEM_TYPE_TOP_LEVEL,
			WO_STEP_NAMES.PROBLEM_TYPE_NEXT_LEVEL,
			WO_STEP_NAMES.PROBLEM_TYPE_NEXT_BRANCH,
		];
		return problemTypeSelected && this.isTroubleshootingAllowedToProceed(checkSteps);
	};

	isPriorityVisible = () => {
		const { companyConfig } = this.props;
		return (
			nullSafeGetOrElse('config.newServiceRequest.showPriorities', companyConfig, {}).value ===
			false
		);
	};

	isPrioritySelected = () =>
		nullSafeGet('woPriority.id', this.state) &&
		this.isTroubleshootingAllowedToProceed([WO_STEP_NAMES.PRIORITY]);

	render() {
		const {
			currentUser,
			location,
			areas,
			fetchAreas,
			fetchMultipleAreas,
			fetchAreasTopLevel,
			assets,
			fetchAssets,
			fetchMultipleAssets,
			locations,
			creating,
			fetchLocations,
			fetchMultipleLocations,
			formData,
			workOrderPriorities,
			fetchWorkOrderPriorities,
			fetchMultipleWorkOrderPriorities,
			problemTypes,
			fetchProblemTypes,
			fetchMultipleProblemTypes,
			fetchProblemTypesTopLevel,
			form,
			companyConfig,
		} = this.props;
		const { getFieldDecorator, getFieldValue } = form;
		const { previewVisible, previewImage } = this.state;
		const BACKEND_URI = getBackendUri();
		const uploadHeaders = { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token };

		const { locationTsAnswer, problemTypeTsAnswer, priorityTsAnswer } =
			getTroubleshootingAnswersForNecessaryWOSteps(this.state);

		const workOrderDetails = [
			{ key: 'Location', value: nullSafeGetOrElse('name', this.state.location, '') },
			...locationTsAnswer,
			{ key: 'Area', value: nullSafeGetOrElse('name', this.state.area, '') },
			{ key: 'Problem Type', value: nullSafeGetOrElse('name', this.state.problemType, '') },
			...problemTypeTsAnswer,
			{ key: 'Asset', value: nullSafeGetOrElse('name', this.state.asset, '') },
			...(nullSafeGetOrElse('name', this.state.asset, '')
				? [
						{
							key: 'Is the Asset Under Warranty?',
							value: checkIsWarrantyWorkOrder(
								nullSafeGet('asset.warranties.0', this.state),
								nullSafeGet('supplierFacilityId', formData)
							)
								? 'No'
								: 'Yes',
						},
						{
							key: 'Is the Asset functioning?',
							value: formData.isAssetDown === 'true' ? 'No' : 'Yes',
						},
				  ]
				: []),
			{ key: 'Title', value: nullSafeGetOrElse('title', formData, '') },
			{ key: 'Priority', value: nullSafeGetOrElse('woPriority.name', this.state, '') },
			...priorityTsAnswer,
			{ key: 'Submitted By', value: nullSafeGetOrElse('submittedByName', formData, '') },
		];

		const photoStrings = (getFieldValue('images') || []).map((p) => {
			const fileName = nullSafeGetOrElse('response.data.fileName', p, '');
			const fileId = nullSafeGetOrElse('response.data.fileId', p, '');
			return `${fileId}/${fileName}`;
		});

		const problemTypePictureCount = nullSafeGetOrElse('state.numPicturesRequired', this, 0);
		const companyConfigPictureCount = nullSafeGetOrElse(
			'config.newServiceRequest.numImagesRequired',
			companyConfig,
			0
		);
		const minPhotosCount = problemTypePictureCount
			? problemTypePictureCount
			: companyConfigPictureCount;

		const imageCountErrorMessage = `You need to upload at least ${minPhotosCount} photo${
			minPhotosCount > 1 ? 's' : ''
		}`;

		const imageCountMatched =
			minPhotosCount <= nullSafeGetOrElse('length', getFieldValue('images'), 0);

		const getTroubleshootingSteps = () => {
			const troubleshootingRoutes = nullSafeGetOrElse('state.troubleshootingRoutes', this, []);
			return troubleshootingRoutes.reduce((steps, route) => {
				const existingAnswer = getExistingTsAnswersFromStateForWOTsRoute(this.state, route);
				return {
					...steps,
					[`troubleshooting-${route.id}`]: {
						id: `t-${route.id}`,
						label: 'Troubleshooting',
						component: (
							<TroubleshootingDisplay
								troubleshootingSteps={route.troubleshootingSteps}
								onBack={this.goBack}
								onNextStep={(answers) => {
									if (nullSafeGet('0', answers)) {
										const troubleshootingAnswers = getUpdatedTsAnswersState(
											this.state,
											route,
											answers
										);
										this.setState({ troubleshootingAnswers });
									}
									route.args && this.proceedToNextStep(route.args);
								}}
								onCancel={this.cancelRequest}
								existingAnswer={existingAnswer}
							/>
						),
					},
				};
			}, {});
		};

		const defaultNavMenuSteps = [
			{
				title:
					this.state.userLocation.id &&
					nullSafeGet('config.newServiceRequest.showSingleLocation', companyConfig)
						? 'userLocation'
						: 'locations',
				enabled: true,
			},
			...(this.state.areas && this.state.areas.length > 0
				? [
						{
							title: 'areasTopLevel',
							enabled: true,
						},
				  ]
				: []),
			...(this.state.area2Available
				? [
						{
							title: 'areasNextLevel',
							enabled: true,
						},
				  ]
				: []),
			...(this.state.area3Available
				? [
						{
							title: 'areasNextBranch',
							enabled: true,
						},
				  ]
				: []),
			{
				title: 'problemTypeTopLevel',
				enabled: this.isLocationFieldsSelected(),
			},
			...(this.state.problemTypeLevel2Available
				? [
						{
							title: 'problemTypeNextLevel',
							enabled: true,
						},
				  ]
				: []),
			...(this.state.problemTypeLevel3Available
				? [
						{
							title: 'problemTypeNextBranch',
							enabled: true,
						},
				  ]
				: []),
			...(this.state.assetsAvailable &&
			nullSafeGet('config.newServiceRequest.showAssets', companyConfig)
				? [
						{
							title: 'asset',
							enabled: this.isLocationFieldsSelected() && this.isProblemTypeAndAssetSelected(),
						},
				  ]
				: []),
			...(this.state.asset
				? [
						{
							title: 'assetCondition',
							enabled: true,
						},
				  ]
				: []),
			{
				title: 'title',
				enabled: this.isLocationFieldsSelected() && this.isProblemTypeAndAssetSelected(),
			},
			{
				title: 'photos',
				enabled:
					this.isLocationFieldsSelected() &&
					this.isProblemTypeAndAssetSelected() &&
					nullSafeGet('title', formData),
			},
			...(this.isPriorityVisible()
				? []
				: [
						{
							title: 'priority',
							enabled:
								this.isLocationFieldsSelected() &&
								this.isProblemTypeAndAssetSelected() &&
								nullSafeGet('title', formData) &&
								imageCountMatched,
						},
				  ]),
			...(nullSafeGetOrElse('isSharedContact', currentUser, false)
				? [
						{
							title: 'submittedBy',
							enabled:
								this.isLocationFieldsSelected() &&
								this.isProblemTypeAndAssetSelected() &&
								nullSafeGet('title', formData) &&
								imageCountMatched &&
								(this.state.priorityIsRequired ? this.isPrioritySelected() : true),
						},
				  ]
				: []),
			{
				title: 'completion',
				enabled:
					this.isLocationFieldsSelected() &&
					this.isProblemTypeAndAssetSelected() &&
					nullSafeGet('title', formData) &&
					imageCountMatched &&
					(this.state.priorityIsRequired ? this.isPrioritySelected() : true),
			},
		];

		const isImageUploading = (getFieldValue('images') || []).some((_) => _.status === 'uploading');

		const getNavSteps = () => {
			const allNavSteps = mergeTroubleshootingRoutes(
				defaultNavMenuSteps,
				nullSafeGetOrElse('state.troubleshootingRoutes', this, []),
				(route) => defaultNavMenuSteps.map((_) => _.title).indexOf(route.previousStep),
				(route) => ({
					title: `troubleshooting-${route.id}`,
					enabled: true,
				})
			);

			return allNavSteps;
		};

		const defaultSteps = {
			userLocation: {
				id: 1,
				label: 'Location',
				component: (
					<div key={0} className="newServiceRequestWizardPage__section">
						<h3 className="newServiceRequestWizardPage__title">
							It looks like you're at {this.state.userLocation.name}.
						</h3>
						<h3 className="newServiceRequestWizardPage__title">Is that where you need service?</h3>
						{nullSafeGet('config.newWorkOrder.step1.instructions', companyConfig) &&
						nullSafeGet('config.newWorkOrder.step1.instructions', companyConfig) !==
							"Tell us a bit about the issue you'd like addressed." ? (
							<h5>{nullSafeGet('config.newWorkOrder.step1.instructions', companyConfig)}</h5>
						) : null}
						<Form.Item style={{ width: '100%' }}>
							{getFieldDecorator(`isUserLocation`, {
								initialValue: nullSafeGet('isUserLocation', formData),
							})(
								<OWRadio
									fieldName="isUserLocation"
									onChange={this.handleUserLocation}
									onClick={this.handleUserLocation}
									renderRecord={(location) => location.name}
									records={{
										1: { id: 1, name: "Yes, that's right." },
										2: { id: 2, name: 'No, somewhere else.' },
									}}
									sorter={(records) => records.sort((a, b) => (a.id > b.id ? 1 : -1))}
								/>
							)}
						</Form.Item>
					</div>
				),
			},
			locations: {
				id: 2,
				label: 'Location',
				component: (
					<div key={1} className="newServiceRequestWizardPage__section">
						<h3 className="newServiceRequestWizardPage__title">Which location needs service?</h3>
						{nullSafeGet('config.newWorkOrder.step1.instructions', companyConfig) &&
						nullSafeGet('config.newWorkOrder.step1.instructions', companyConfig) !==
							"Tell us a bit about the issue you'd like addressed." ? (
							<h5>{nullSafeGet('config.newWorkOrder.step1.instructions', companyConfig)}</h5>
						) : null}
						<Form.Item style={{ width: '100%' }}>
							{getFieldDecorator(`locationId`, {
								rules: [
									{
										required: true,
										message: 'Please pick the location that needs service!',
									},
								],
								initialValue: nullSafeGet('locationId', formData),
							})(
								<OWAsyncRadio
									mode="default"
									fieldName="locationId"
									stateSlice={locations}
									onChange={this.handleLocation}
									onClick={this.handleLocationClick}
									targetCollectionName={'WORK_ORDER_FORM_LOCATIONS_AUTOCOMPLETE'}
									fetchMultiple={(ids, targetCollectionName) =>
										fetchMultipleLocations(ids, targetCollectionName)
									}
									fetchData={(
										searchText,
										targetCollectionName,
										pagination = null,
										sorting = null,
										filters = null
									) =>
										fetchLocations(
											{ name: searchText },
											targetCollectionName,
											pagination,
											sorting,
											filters
										)
									}
									renderRecord={(location) => (
										<div className="flex flex-row items-center lg:flex-col">
											{nullSafeGet('brand.logoURL', location) && (
												<img
													src={nullSafeGet('brand.logoURL', location)}
													className="mr-2 h-8  w-auto lg:mb-2 lg:h-auto lg:w-20"
												/>
											)}
											{location.name}
										</div>
									)}
								/>
							)}
						</Form.Item>
						<Button size="large" type="ghost" onClick={() => this.goBack()}>
							Back
						</Button>
					</div>
				),
			},
			areasTopLevel: {
				id: 3,
				label: 'Area',
				component: (
					<div key={2} className="newServiceRequestWizardPage__section">
						<h3 className="newServiceRequestWizardPage__title">Which area is the problem in?</h3>
						<Form.Item style={{ width: '100%' }}>
							{getFieldDecorator(`areasTopLevel`, {
								rules: [
									{
										required: false,
									},
								],
								initialValue: nullSafeGet('areaLevel1Id', this.state),
							})(
								<OWAsyncRadio
									mode="default"
									fieldName="areasTopLevel"
									stateSlice={areas}
									additionalFilters={{
										locationId: nullSafeGet('location.id', this.state),
										brandId: nullSafeGet('location.brandId', this.state),
									}}
									onChange={this.handleAreaTopLevelChange}
									onClick={this.handleAreaTopLevelChange}
									targetCollectionName={'WORK_ORDER_FORM_AREAS_TOP_LEVEL_AUTOCOMPLETE'}
									fetchMultiple={(ids, targetCollectionName) =>
										fetchMultipleAreas(ids, targetCollectionName)
									}
									fetchData={(
										searchText,
										targetCollectionName,
										pagination = null,
										sorting = null,
										filters = null
									) =>
										fetchAreasTopLevel(
											{
												name: searchText,
												industryId: nullSafeGetOrElse('industryId', this.state, 1),
											},
											targetCollectionName,
											pagination,
											sorting,
											filters
										)
									}
									renderRecord={(area) => area.name}
								/>
							)}
						</Form.Item>
						<Button size="large" type="ghost" onClick={() => this.goBack()}>
							Back
						</Button>
					</div>
				),
			},
			areasNextLevel: {
				id: 4,
				label: 'Area - 2',
				component: (
					<div key={3} className="newServiceRequestWizardPage__section">
						<h3 className="newServiceRequestWizardPage__title">Which area is the problem in?</h3>
						<Form.Item style={{ width: '100%' }}>
							{getFieldDecorator(`areaId`, {
								rules: [
									{
										required: false,
									},
								],
								initialValue: nullSafeGet('areaLevel2Id', this.state),
							})(
								nullSafeGet('currentRoute', this.state) === 'areasNextLevel' ? (
									<OWAsyncRadio
										mode="default"
										fieldName="area"
										stateSlice={areas}
										additionalFilters={{
											locationId: nullSafeGet('location.id', this.state),
											brandId: nullSafeGet('location.brandId', this.state),
										}}
										onChange={this.handleAreaNextLevelChange}
										onClick={this.handleAreaNextLevelChange}
										targetCollectionName={'WORK_ORDER_FORM_AREAS_AUTOCOMPLETE'}
										fetchMultiple={(ids, targetCollectionName) =>
											fetchMultipleAreas(ids, targetCollectionName)
										}
										fetchData={(
											searchText,
											targetCollectionName,
											pagination = null,
											sorting = null,
											filters = null
										) =>
											fetchAreas(
												{
													name: searchText,
													parentId: nullSafeGet('areaPriorLevelId', this.state),
												},
												targetCollectionName,
												pagination,
												sorting,
												filters
											)
										}
										renderRecord={(area) => area.name}
									/>
								) : (
									<div />
								)
							)}
						</Form.Item>
						<Button size="large" type="ghost" onClick={() => this.goBack()}>
							Back
						</Button>
					</div>
				),
			},
			areasNextBranch: {
				id: 5,
				label: 'Area - 3',
				component: (
					<div key={4} className="newServiceRequestWizardPage__section">
						<h3 className="newServiceRequestWizardPage__title">Which area is the problem in?</h3>
						<Form.Item style={{ width: '100%' }}>
							{getFieldDecorator(`areaIdNextLevel`, {
								rules: [
									{
										required: false,
									},
								],
								initialValue: nullSafeGet('areaLevel3Id', this.state),
							})(
								nullSafeGet('currentRoute', this.state) === 'areasNextBranch' ? (
									<OWAsyncRadio
										mode="default"
										fieldName="area"
										stateSlice={areas}
										additionalFilters={{
											locationId: nullSafeGet('location.id', this.state),
											brandId: nullSafeGet('location.brandId', this.state),
										}}
										onChange={this.handleAreaNextLevelBranchChange}
										onClick={this.handleAreaNextLevelBranchChange}
										targetCollectionName={'WORK_ORDER_FORM_AREAS_NEXT_LEVEL_AUTOCOMPLETE'}
										fetchMultiple={(ids, targetCollectionName) =>
											fetchMultipleAreas(ids, targetCollectionName)
										}
										fetchData={(
											searchText,
											targetCollectionName,
											pagination = null,
											sorting = null,
											filters = null
										) =>
											fetchAreas(
												{
													name: searchText,
													parentId: nullSafeGet('areaPriorLevelId', this.state),
												},
												targetCollectionName,
												pagination,
												sorting,
												filters
											)
										}
										renderRecord={(area) => area.name}
									/>
								) : (
									<div />
								)
							)}
						</Form.Item>
						<Button size="large" type="ghost" onClick={() => this.goBack()}>
							Back
						</Button>
					</div>
				),
			},
			problemTypeTopLevel: {
				id: 6,
				label: 'Problem Type',
				component: (
					<div key={5} className="newServiceRequestWizardPage__section">
						<h3 className="newServiceRequestWizardPage__title">What kind of problem is it?</h3>
						<Form.Item style={{ width: '100%' }}>
							{getFieldDecorator(`problemTypeTopLevel`, {
								rules: [
									{
										required: false,
									},
								],
								initialValue: nullSafeGet('problemTypeLevel1Id', this.state),
							})(
								<OWAsyncRadio
									mode="default"
									fieldName="problemTypeTopLevel"
									stateSlice={problemTypes}
									additionalFilters={{
										areaId: nullSafeGetOrElse('area.id', this.state, undefined),
										brandId: nullSafeGet('location.brandId', this.state),
									}}
									onChange={this.handleProblemTypeTopLevelChange}
									onClick={this.handleProblemTypeTopLevelClick}
									targetCollectionName={'WORK_ORDER_PROBLEM_TYPES_TOP_LEVEL_AUTOCOMPLETE'}
									fetchMultiple={(ids, targetCollectionName) =>
										fetchMultipleProblemTypes(ids, targetCollectionName)
									}
									fetchData={(
										searchText,
										targetCollectionName,
										pagination = null,
										sorting = null,
										filters = null
									) =>
										fetchProblemTypesTopLevel(
											{
												name: searchText,
												industryId: nullSafeGetOrElse('industryId', this.state, 1),
											},
											targetCollectionName,
											pagination,
											sorting,
											filters
										)
									}
									renderRecord={(problemType) => problemType.name}
								/>
							)}
						</Form.Item>
						<Button size="large" type="ghost" onClick={() => this.goBack()}>
							Back
						</Button>
					</div>
				),
			},
			problemTypeNextLevel: {
				id: 7,
				label: 'Problem Type - 2',
				component: (
					<div key={6} className="newServiceRequestWizardPage__section">
						<h3 className="newServiceRequestWizardPage__title">What kind of problem is it?</h3>
						<Form.Item style={{ width: '100%' }}>
							{getFieldDecorator(`problemTypeId`, {
								rules: [
									{
										required: false,
									},
								],
								initialValue: nullSafeGet('problemTypeLevel2Id', this.state),
							})(
								nullSafeGet('currentRoute', this.state) === 'problemTypeNextLevel' ? (
									<OWAsyncRadio
										mode="default"
										fieldName="problemType"
										stateSlice={problemTypes}
										additionalFilters={{
											areaId: nullSafeGetOrElse('area.id', this.state, undefined),
											brandId: nullSafeGet('location.brandId', this.state),
										}}
										onChange={this.handleProblemTypeNextLevelChange}
										onClick={this.handleProblemTypeNextLevelChange}
										targetCollectionName={'WORK_ORDER_FORM_PROBLEM_TYPES_AUTOCOMPLETE'}
										fetchMultiple={(ids, targetCollectionName) =>
											fetchMultipleProblemTypes(ids, targetCollectionName)
										}
										fetchData={(
											searchText,
											targetCollectionName,
											pagination = null,
											sorting = null,
											filters = null
										) =>
											fetchProblemTypes(
												{
													name: searchText,
													parentId: nullSafeGet('problemTypePriorLevelId', this.state),
												},
												targetCollectionName,
												pagination,
												sorting,
												filters
											)
										}
										renderRecord={(problemType) => problemType.name}
									/>
								) : (
									<div />
								)
							)}
						</Form.Item>
						<Button size="large" type="ghost" onClick={() => this.goBack()}>
							Back
						</Button>
					</div>
				),
			},
			problemTypeNextBranch: {
				id: 8,
				label: 'Problem Type - 3',
				component: (
					<div key={7} className="newServiceRequestWizardPage__section">
						<h3 className="newServiceRequestWizardPage__title">What kind of problem is it?</h3>
						<Form.Item style={{ width: '100%' }}>
							{getFieldDecorator(`problemTypeIdNextLevel`, {
								rules: [
									{
										required: false,
									},
								],
								initialValue: nullSafeGet('problemTypeLevel3Id', this.state),
							})(
								nullSafeGet('currentRoute', this.state) === 'problemTypeNextBranch' ? (
									<OWAsyncRadio
										mode="default"
										fieldName="problemType"
										stateSlice={problemTypes}
										additionalFilters={{
											areaId: nullSafeGetOrElse('area.id', this.state, undefined),
											brandId: nullSafeGet('location.brandId', this.state),
										}}
										onChange={this.handleProblemTypeNextLevelBranchChange}
										onClick={this.handleProblemTypeNextLevelBranchChange}
										targetCollectionName={'WORK_ORDER_FORM_PROBLEM_TYPES_NEXT_LEVEL_AUTOCOMPLETE'}
										fetchMultiple={(ids, targetCollectionName) =>
											fetchMultipleProblemTypes(ids, targetCollectionName)
										}
										fetchData={(
											searchText,
											targetCollectionName,
											pagination = null,
											sorting = null,
											filters = null
										) =>
											fetchProblemTypes(
												{
													name: searchText,
													parentId: nullSafeGet('problemTypePriorLevelId', this.state),
												},
												targetCollectionName,
												pagination,
												sorting,
												filters
											)
										}
										renderRecord={(problemType) => problemType.name}
									/>
								) : (
									<div />
								)
							)}
						</Form.Item>
						<Button size="large" type="ghost" onClick={() => this.goBack()}>
							Back
						</Button>
					</div>
				),
			},
			asset: {
				id: 9,
				label: 'Asset',
				component: (
					<div key={8} className="newServiceRequestWizardPage__section">
						<h3 className="newServiceRequestWizardPage__title" style={{ marginBottom: 16 }}>
							Is the problem affecting a particular piece of equipment?
						</h3>
						<div style={{ textAlign: 'center', marginBottom: 24 }}>
							<Button
								onClick={() => this.goNext([], WO_STEP_NAMES.TITLE, WO_STEP_NAMES.ASSET)}
								type="link"
							>
								Skip
							</Button>
						</div>
						<Form.Item style={{ width: '100%' }}>
							{getFieldDecorator(`assetId`, {
								initialValue: nullSafeGet('assetId', formData),
							})(
								<OWAsyncRadio
									mode="default"
									fieldName="assetId"
									stateSlice={assets}
									onChange={this.handleAsset}
									onClick={this.handleAssetClick}
									targetCollectionName={'SERVICE_REQUEST_FORM_ASSETS_AUTOCOMPLETE'}
									keepFetchedOrder={true}
									fetchMultiple={(ids, targetCollectionName) =>
										fetchMultipleAssets(ids, targetCollectionName)
									}
									fetchData={(
										searchText,
										targetCollectionName,
										pagination = null,
										sorting = {
											sort_by: 'assetNumber',
											order: 'ascend',
										},
										filters = null
									) =>
										fetchAssets(
											{
												search: searchText,
											},
											targetCollectionName,
											pagination,
											sorting,
											filters
										)
									}
									renderRecord={(asset) => (
										<>
											<div className="flex flex-col">
												<Ellipsis tooltip={true} length={12}>
													{asset.name}
												</Ellipsis>
												<AssetNumberDisplay asset={asset} style={{ marginTop: 8 }} />
											</div>
											{getWarrantyStatus(nullSafeGet('warranties.0', asset)) === 'Active' ? (
												<div style={{ marginTop: '8px', marginBottom: '8px', fontWeight: 500 }}>
													Under Warranty
												</div>
											) : null}
										</>
									)}
									additionalFilters={getAssetFilterForWOCreation(ROLE_TYPES.BUYER)(
										companyConfig,
										currentUser,
										this.state
									)}
								/>
							)}
						</Form.Item>
						<div className="flex flex-row">
							<Button size="large" type="ghost" onClick={() => this.goBack()}>
								Back
							</Button>
							<Button
								size="large"
								type="primary"
								className="ml-4"
								onClick={() =>
									this.goNextAfterBriefPause(['assetId'], WO_STEP_NAMES.TITLE, WO_STEP_NAMES.ASSET)
								}
							>
								Next step
							</Button>
						</div>
					</div>
				),
			},
			assetCondition: {
				id: 10,
				label: 'Asset Status',
				component: (
					<div key={10} className="newServiceRequestWizardPage__section">
						<h3 className="newServiceRequestWizardPage__title" style={{ marginBottom: 16 }}>
							{`Is the asset functioning?`}
						</h3>
						<Form.Item style={{ width: '100%' }}>
							{getFieldDecorator(`isAssetDown`, {
								initialValue: nullSafeGetOrElse('isAssetDown', formData, ''),
							})(
								<OWRadio
									fieldName="assetId"
									onChange={this.handleAssetCondition}
									onClick={this.handleAssetCondition}
									renderRecord={(location) => location.name}
									records={{
										false: {
											id: 'false',
											name: 'Yes',
										},
										true: {
											id: 'true',
											name: 'No',
										},
									}}
									sorter={(records) => records.sort((a, b) => (a.id > b.id ? 1 : -1))}
								/>
							)}
						</Form.Item>
						<div className="flex flex-row">
							<Button size="large" type="ghost" onClick={() => this.goBack()}>
								Back
							</Button>
						</div>
					</div>
				),
			},
			duplicateCheck: {
				id: 11,
				label: 'Possible Duplicates',
				component: (
					<div key={11} className="newServiceRequestWizardPage__section">
						<h3 className="newServiceRequestWizardPage__title">
							We found potential duplicate requests. Do you still want to open a new request?
						</h3>
						<Table
							pagination={false}
							showHeader={false}
							dataSource={nullSafeGetOrElse('duplicateWorkOrders', this.state, [])}
							columns={duplicateWorkOrderColumns}
						/>
						<Form.Item style={{ width: '100%' }}>
							{getFieldDecorator(`isDuplicate`, {
								initialValue: nullSafeGet('isDuplicate', formData),
							})(
								<OWRadio
									fieldName="isDuplicate"
									onChange={this.handleDuplicate}
									onClick={this.handleDuplicate}
									renderRecord={(option) => option.name}
									records={{
										1: { id: 1, name: 'No, cancel this request' },
										2: { id: 2, name: 'Yes, this is a different issue' },
									}}
									sorter={(records) => records.sort((a, b) => (a.id > b.id ? 1 : -1))}
								/>
							)}
						</Form.Item>
						<Button size="large" type="ghost" onClick={() => this.goBack()}>
							Back
						</Button>
					</div>
				),
			},
			title: {
				id: 12,
				label: 'Description',
				component: (
					<div key={12} className="newServiceRequestWizardPage__section">
						<h3 className="newServiceRequestWizardPage__title">
							In a few words, what's the problem?
						</h3>
						<Form.Item style={{ width: '100%', marginBottom: 40 }}>
							{getFieldDecorator(`title`, {
								rules: [{ required: true, message: 'Please describe the problem.' }],
								initialValue: nullSafeGet('title', formData),
							})(<Input showCount maxLength={120} size="large" />)}
						</Form.Item>
						<div className="flex flex-row">
							<Button size="large" type="ghost" onClick={() => this.goBack()}>
								Back
							</Button>
							<Button
								size="large"
								type="primary"
								className="ml-4"
								onClick={() =>
									this.goNextAfterBriefPause(['title'], WO_STEP_NAMES.PHOTOS, WO_STEP_NAMES.TITLE)
								}
							>
								Next step
							</Button>
						</div>
					</div>
				),
			},
			photos: {
				id: 13,
				label: 'Photos',
				component: (
					<div key={13} className="newServiceRequestWizardPage__section">
						<h3 className="newServiceRequestWizardPage__title">Want to add some photos?</h3>
						<Form.Item
							style={{
								marginBottom: 20,
								width: '100%',
							}}
							className="text-center"
						>
							{getFieldDecorator(`images`, {
								valuePropName: 'fileList',
								getValueFromEvent: normFile,
								initialValue: this.state.allPhotos,
								rules: getImageCountErrorRules(minPhotosCount, imageCountErrorMessage),
							})(
								<OWUpload
									accept="image/*"
									name="file"
									listType="picture-card"
									className="workorder-image-uploader"
									multiple={true}
									headers={uploadHeaders}
									onPreview={this.handlePreview}
									action={`${BACKEND_URI}/api/v1/buyer/file/upload`}
									beforeUpload={imageUploadValidation}
									onChange={this.handleImageUpdate}
								>
									<div style={{ display: 'inline' }}>
										<LegacyIcon type={this.state.uploadLoading ? 'loading' : 'plus'} />
										<div className="ant-upload-text">Upload</div>
									</div>
									<Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
										<img alt="example" style={{ width: '100%' }} src={previewImage} />
									</Modal>
								</OWUpload>
							)}
						</Form.Item>
						<div className="flex flex-row">
							<Button size="large" type="ghost" onClick={() => this.goBack()}>
								Back
							</Button>
							<Button
								size="large"
								type="primary"
								className="ml-4"
								loading={isImageUploading}
								onClick={() => {
									const priorityConfig = nullSafeGetOrElse(
										'config.newServiceRequest.showPriorities',
										companyConfig,
										{}
									);
									if (priorityConfig.value === false) {
										form.setFieldsValue({
											woPriorityId: nullSafeGetOrElse(
												'woPriority.id',
												this.state,
												nullSafeGetOrElse('defaultPriorityId', priorityConfig, 3)
											),
										});
										this.handleWorkOrderPriority(
											nullSafeGetOrElse(
												'woPriority.id',
												this.state,
												nullSafeGetOrElse('defaultPriorityId', priorityConfig, 3)
											)
										);
									} else {
										this.goNextAfterBriefPause(
											['images'],
											WO_STEP_NAMES.PRIORITY,
											WO_STEP_NAMES.PHOTOS
										);
									}
								}}
							>
								Next step
							</Button>
						</div>
					</div>
				),
			},
			priority: {
				id: 14,
				label: 'Priority',
				component: (
					<div key={14} className="newServiceRequestWizardPage__section">
						<h3 className="newServiceRequestWizardPage__title">
							How quickly does this need to get fixed?
						</h3>
						<Form.Item
							style={{
								marginBottom: 20,
								width: '100%',
							}}
						>
							{getFieldDecorator(`woPriorityId`, {
								initialValue: nullSafeGet('woPriorityId', formData),
								rules: [
									{
										required: this.state.priorityIsRequired,
										message: 'Please select a priority level.',
									},
								],
							})(
								<OWAsyncRadio
									mode="default"
									fieldName="woPriorityId"
									stateSlice={workOrderPriorities}
									onChange={this.handleWorkOrderPriority}
									onClick={this.handleWorkOrderPriorityClick}
									targetCollectionName={'WORK_ORDER_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE'}
									fetchMultiple={(ids, targetCollectionName) =>
										fetchMultipleWorkOrderPriorities(ids, targetCollectionName)
									}
									fetchData={(
										searchText,
										targetCollectionName,
										pagination = null,
										sorting = {
											sort_by: 'expectedResolutionInSeconds',
											order: 'descend',
										},
										filters = null
									) =>
										fetchWorkOrderPriorities(
											{ name: searchText },
											targetCollectionName,
											pagination,
											sorting,
											filters
										)
									}
									renderRecord={(workOrderPriority) => workOrderPriority.name}
									keepFetchedOrder={true}
								/>
							)}
						</Form.Item>
						<div className="flex flex-row">
							<Button size="large" type="ghost" onClick={() => this.goBack()}>
								Back
							</Button>
							<Button
								size="large"
								type="primary"
								className="ml-4"
								onClick={() => {
									if (nullSafeGetOrElse('isSharedContact', currentUser, false)) {
										this.goNextAfterBriefPause(
											['woPriorityId'],
											WO_STEP_NAMES.SUBMITTED_BY,
											WO_STEP_NAMES.PRIORITY
										);
									} else {
										this.goNextAfterBriefPause(
											['woPriorityId'],
											WO_STEP_NAMES.COMPLETION,
											WO_STEP_NAMES.PRIORITY
										);
									}
								}}
							>
								Next step
							</Button>
						</div>
					</div>
				),
			},
			submittedBy: {
				id: 15,
				label: 'Submitted By',
				component: (
					<div key={15} className="newServiceRequestWizardPage__section">
						<h3 className="newServiceRequestWizardPage__title">Can you provide your name?</h3>
						<Form.Item style={{ width: '60%', marginBottom: 40 }}>
							{getFieldDecorator(`submittedByName`, {
								rules: [
									{
										required: this.state.submittedByNameIsRequired,
										message: 'Please provide your full name.',
									},
								],
								initialValue: nullSafeGet('submittedByName', formData),
							})(<Input size="large" />)}
						</Form.Item>
						<div className="flex flex-row">
							<Button size="large" type="ghost" onClick={() => this.goBack()}>
								Back
							</Button>
							<Button
								size="large"
								type="primary"
								className="ml-4"
								onClick={() =>
									this.goNextAfterBriefPause(
										['submittedByName'],
										WO_STEP_NAMES.COMPLETION,
										WO_STEP_NAMES.SUBMITTED_BY
									)
								}
							>
								Next step
							</Button>
						</div>
					</div>
				),
			},
			completion: {
				id: 16,
				label: 'Summary',
				component: (
					<div key={16} className="newServiceRequestWizardPage__section">
						<h3 className="newServiceRequestWizardPage__title">Summary</h3>
						<div style={{ marginBottom: 24 }}>
							<TwoLineList
								listItems={workOrderDetails.filter(
									(field) => field.value !== null && field.value !== undefined && field.value !== ''
								)}
								lineOneRender={(record) => record.value}
								lineTwoRender={(record) => record.key}
							/>
							<ProtectedImageThumbnails
								style={{ marginTop: 16 }}
								imageWidth={64}
								imageHeight={64}
								photoStrings={photoStrings}
								role={ROLE_TYPES.BUYER}
							/>
						</div>
						<div className="flex flex-row">
							<Button size="large" type="ghost" onClick={() => this.goBack()}>
								Back
							</Button>
							<Button
								onClick={this.handleSubmit}
								type="primary"
								size="large"
								className="ml-4"
								loading={creating || this.state.tsAnswerUploading}
							>
								Request Service
							</Button>
						</div>
					</div>
				),
			},
		};

		const getSteps = () => {
			const allStepKeys = mergeTroubleshootingRoutes(
				Object.keys(defaultSteps),
				nullSafeGetOrElse('state.troubleshootingRoutes', this, []),
				(route) => Object.keys(defaultSteps).indexOf(route.previousStep),
				(route) => `troubleshooting-${route.id}`
			);

			const troubleshootingSteps = getTroubleshootingSteps();

			const allSteps = allStepKeys.reduce((steps, key) => {
				return {
					...steps,
					[key]: key.startsWith('troubleshooting') ? troubleshootingSteps[key] : defaultSteps[key],
				};
			}, {});

			return allSteps;
		};

		const allNavSteps = getNavSteps();
		const allSteps = getSteps();
		const currentStepKey = location.pathname.split('/')[location.pathname.split('/').length - 1];
		const currentStepIndex = allNavSteps.map((_) => _.title).indexOf(currentStepKey);
		const currentCompletionPercentage = ((currentStepIndex * 1.0) / allNavSteps.length) * 100;

		return (
			<div className="newServiceRequestWizardPage">
				<LogOnMountWithStandardEventProperties eventType="visited buyer new service request page" />
				<Drawer
					width={240}
					title="Overview"
					bodyStyle={{ padding: '16px 0' }}
					placement="left"
					closable={true}
					onClose={this.handleNavMenuClose}
					visible={this.state.navMenuVisible}
				>
					<Menu
						onClick={this.handleNavMenuClick}
						style={{ width: 240 }}
						defaultSelectedKeys={[currentStepKey]}
						mode="inline"
					>
						{allNavSteps.map((k) => {
							const step = allSteps[k.title];
							return (
								<Menu.Item disabled={!k.enabled} key={k.title}>
									{step.label}
								</Menu.Item>
							);
						})}
					</Menu>
				</Drawer>
				<BuyerLocationUpdateForm
					wrappedComponentRef={this.saveFormRef('locationUpdateForm')}
					visible={this.state.locationUpdateVisible}
					formData={this.state.userContact}
					onCancel={this.handleBuyerLocationUpdateCancel}
					onSubmit={this.handleBuyerLocationUpdateSubmit}
					submitting={this.state.updatingLocation}
				/>
				<div
					style={{
						position: 'absolute',
						display: 'flex',
						alignItems: 'center',
						zIndex: 3,
						right: 16,
						top: 8,
					}}
				>
					<Button type="link" onClick={() => this.props.history.push(this.state.backLinkTo)}>
						Cancel Request
					</Button>
				</div>
				<div className="newServiceRequestWizardPage__inner">
					{Object.keys(allSteps).map((k) => {
						const step = allSteps[k];
						return (
							<Route
								key={step.id}
								path={`/buyer/workOrders/requestService/${k}`}
								render={() => step.component}
								exact={true}
							/>
						);
					})}
				</div>
				<div
					style={{
						position: 'absolute',
						display: 'flex',
						alignItems: 'center',
						zIndex: 3,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'white',
						border: '1px solid #f5f5f5',
						width: '100%',
					}}
				>
					<Button
						style={{ display: 'inline-block', margin: '8px 16px 8px 8px' }}
						size="large"
						icon={<MenuOutlined translate="" />}
						onClick={this.handleNavMenuOpen}
					>
						Overview
					</Button>
					<Progress
						style={{ margin: '8px 16px 8px 8px' }}
						strokeLinecap="square"
						strokeColor="#6027b0"
						percent={currentCompletionPercentage}
						showInfo={false}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	redirectForwardUrl: ownProps.redirectForwardUrl,
	formData: state.work_orders.formData,
	currentUser: state.session.currentUser,
	companyConfig: state.company_config.detail,
	spendCategories: state.spend_categories,
	problemTypes: state.problem_types,
	locations: state.locations,
	creating: state.work_orders.creating,
	workOrderPriorities: state.work_order_priorities,
	assets: state.assets,
	areas: state.areas,
	location: ownProps.location,
});

const mapDispatchToProps = (dispatch) => ({
	saveFormData: (formData) => dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.saveFormData(formData)),
	clearFormData: () => dispatch(WORK_ORDERS_CRUD_ACTION_CREATORS.clearFormData()),
	fetchWorkOrderPriority: (id) => dispatch(workOrderPrioritiesRestCrudThunksForBuyer.readOne(id)),
	fetchWorkOrderPriorities: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			workOrderPrioritiesRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleWorkOrderPriorities: (ids, targetCollectionName) =>
		dispatch(workOrderPrioritiesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchBuyer: (id) => dispatch(buyersRestCrudThunksForBuyer.readOne(id)),
	fetchArea: (id) => dispatch(areasRestCrudThunksForBuyer.readOne(id)),
	fetchAreas: (params, targetCollectionName, pagination, sorting, filters, addToTargetCollection) =>
		dispatch(
			areasRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchAreasTopLevel: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			areasTopLevelForBuyer(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleAreas: (ids, targetCollectionName) =>
		dispatch(areasRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchLocation: (id) => dispatch(locationsRestCrudThunksForBuyer.readOne(id)),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			locationsRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchWorkOrders: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			workOrdersRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchAsset: (id) => dispatch(assetsRestCrudThunksForBuyer.readOne(id)),
	fetchAssets: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			assetsRestCrudThunksForBuyer.readLite(
				{ ...params, view: 'workOrderCreation' },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleAssets: (ids, targetCollectionName) =>
		dispatch(assetsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchSpendCategories: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			spendCategoriesRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleSpendCategories: (ids, targetCollectionName) =>
		dispatch(spendCategoriesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchMultipleProblemTypes: (ids, targetCollectionName) =>
		dispatch(problemTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchProblemType: (id) => dispatch(problemTypesRestCrudThunksForBuyer.readOne(id)),
	fetchProblemTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			problemTypesRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchProblemTypesTopLevel: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			problemTypesTopLevelForBuyer(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchWorkOrderAttributes: (params) =>
		dispatch(workOrderAttributesRestCrudThunksForBuyer.read(params)),
	submitServiceRequestForm: (workOrder) =>
		dispatch(workOrdersRestCrudThunksForBuyer.create(workOrder)),
	roleUpdateUser: (entity) => dispatch(buyerRoleUpdateUser(entity)),
	fetchTroubleshooting: (params) => dispatch(troubleshootingRestCrudThunksForBuyer.read(params)),
	createTroubleshootingAnswersBulk: (entities) =>
		dispatch(troubleshootingAnswersRestCrudThunksForBuyer.createBulk(entities)),
	fetchExchangeRate: (c1, c2, userType) => dispatch(getExchangeRate(c1, c2)(userType)),
});

export default withRouter<any, any>(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(
		Form.create<NewServiceRequestWizardProps>({
			onValuesChange: (props, changedFields) => {
				props.saveFormData(changedFields);
			},
		})(NewServiceRequestWizardPage)
	)
);

import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { Button } from 'antd';
import React, { FC } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

require('./AssetDetailPageHeader.less');

const AssetDetailPageHeader: FC<any> = ({
	asset,
	photoUris,
	history,
	userType,
	isExternalSupplier = false,
	hideLinkButton,
}): React.ReactElement => {
	return (
		<div className={'assetDetailPageHeader'}>
			<div
				className={
					nullSafeGetOrElse('length', photoUris, []) > 0 || !asset.isActive
						? userType === 'supplier'
							? 'supplierImageLayout'
							: 'buyerImageLayout'
						: userType === 'supplier'
						? 'supplierNoImageLayout'
						: 'buyerNoImageLayout'
				}
			>
				{!asset.isActive ? (
					<div className="inactiveBanner">
						<div className="inactiveLabel">Inactive</div>
					</div>
				) : null}
				{photoUris.map((photoUri, idx) => (
					<div
						key={idx}
						className={`imageTile imageTile${idx}`}
						style={{ backgroundImage: `url(${photoUri})` }}
					/>
				))}
				<div
					className={`assetPageLayout ${
						(photoUris.length && photoUris.length > 0) || !asset.isActive
							? 'imageLayout__gradientOverlay'
							: 'noImageLayout__gradientOverlay'
					}`}
					style={{ width: '100%' }}
				>
					<div className="textContainer">
						<div className="leftItems">
							<div className="pageDisplay__overtitle">{nullSafeGet('assetType.name', asset)}</div>
							<h4 className="pageDisplay__title">{asset.name}</h4>
						</div>
						<div className="rightItems">
							{isExternalSupplier || hideLinkButton ? null : (
								<Button
									style={{ color: 'white' }}
									type="ghost"
									onClick={() => history.push(`/${userType}/assets/detail/${asset.id}`)}
								>
									View Asset Page
								</Button>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(AssetDetailPageHeader)
);

import React, { FC } from 'react';
import { Popover, Row } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface EachMenu {
	title: String;
	description: String;
}

interface RadioGroupInfoProps {
	menus: EachMenu[];
}

const RadioGroupInfo: FC<RadioGroupInfoProps> = ({ menus }): React.ReactElement => {
	return (
		<Popover
			content={
				<div>
					{menus.map((menu, idx) => (
						<div key={idx}>
							<Row>
								<span style={{ fontWeight: 'bold' }}>{menu.title}</span>
							</Row>
							<Row>
								<span>{menu.description}</span>
							</Row>

							<Row style={{ height: '20px' }}></Row>
						</div>
					))}
				</div>
			}
		>
			<InfoCircleOutlined translate="" style={{ marginLeft: '12px' }} />
		</Popover>
	);
};

export default RadioGroupInfo;

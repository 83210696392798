import React, { FC, useMemo } from 'react';
import { currencyFormatter } from '../../utils/DataFormatterUtils';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';

const DepreciationClassRowDisplay: FC<any> = ({ record }) => {
	const additionalDetails = useMemo(() => {
		switch (record.depreciationMethod) {
			case 'declining':
				return (
					<div className="text-m flex flex-row items-center text-gray-400">
						<div>Declining Balance</div>
						<div className="ml-4">{`Rate : ${record.depreciationRate}%`}</div>
					</div>
				);
			case 'straightLine':
				return (
					<div className="text-m flex flex-row items-center text-gray-400">
						<div>Straight Line</div>
						<div className="ml-4">{`Useful Life : ${record.usefulLifeInMonths} months`}</div>
						<div className="ml-4">{`Salvage value : ${currencyFormatter(2)(
							record.salvageValue
						)}`}</div>
					</div>
				);
			default:
				return <></>;
		}
	}, [record]);

	const assetCount = useMemo(() => nullSafeGetOrElse('assetTypes.length', record, 0), [record]);

	return (
		<div className="flex flex-col">
			<div className="text-xl text-gray-900">{record.name}</div>
			{additionalDetails}
			<div className="text-m text-gray-400">
				{`${assetCount || 'No'} asset type${assetCount > 1 ? 's' : ''} associated`}
			</div>
		</div>
	);
};

export default DepreciationClassRowDisplay;

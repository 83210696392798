import React, { FC, useCallback, useMemo, useState } from 'react';
import { Modal, DatePicker, Form, message, Button, Select } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import {
	siteSurveysRestCrudThunksForBuyer,
	siteSurveysRestCrudThunksForSupplier,
} from '../../thunks/site_surveys_thunks';
import {
	walkthroughsRestCrudThunksForBuyer,
	walkthroughsRestCrudThunksForSupplier,
} from '../../thunks/walkthroughs_thunks';
import { withRouter } from 'react-router';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import {
	fetchSupplierFacilitiesInPrivateNetworkForBuyer,
	supplierFacilitiesRestCrudThunksForBuyer,
} from '../../thunks/supplier_facilities_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';

const FormItem = Form.Item;
export const DATE_FORMAT = 'MMMM D, YYYY h:mm a';

const disabledDate = (current) => {
	// Can not select days before today and today
	return current < moment().startOf('day');
};

const FORM_NAME = 'schedule_walkthrough_form';

const ScheduleWalkthroughModalForm: FC<any> = ({
	createWalkthrough,
	getSiteSurvey,
	match,
	currentUser,
	onSuccess,
	onCancel,
	userType,
	supplierFacilities,
	fetchMultipleSupplierFacilitiesInPrivateNetwork,
	fetchSupplierFacilitiesInPrivateNetwork,
}): React.ReactElement => {
	const [loading, setLoading] = useState(false);

	const siteSurveyId = useMemo(() => match.params.id, [match.params.id]);

	const onSubmit = useCallback(
		(values) => {
			setLoading(true);
			getSiteSurvey(siteSurveyId)
				.then((survey) => {
					const updateValues = {
						...values,
						title: survey.title + ' walkthrough',
						status: 'incomplete',
						locationId: survey.locationId,
						siteSurveyId: survey.id,
						scheduledBy: currentUser.email,
					};

					createWalkthrough(updateValues)
						.then(() => {
							onSuccess && onSuccess();
						})
						.catch((err) => message.error(err))
						.finally(() => setLoading(false));
				})
				.catch((err) => {
					message.error(err);
					setLoading(false);
				});
		},
		[createWalkthrough, currentUser.email, getSiteSurvey, onSuccess, siteSurveyId]
	);

	return (
		<Modal
			visible={true}
			width={600}
			title="Schedule Walkthrough"
			onCancel={onCancel}
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button
					type="primary"
					size="large"
					key="submit"
					htmlType="submit"
					form={FORM_NAME}
					loading={loading}
				>
					Schedule
				</Button>,
			]}
			closable={false}
		>
			<Form layout="vertical" onFinish={onSubmit} id={FORM_NAME}>
				<FormItem
					label="Due By"
					name="dueAt"
					rules={[
						{
							required: true,
							message: 'Please select the walkthrough due date.',
						},
					]}
				>
					<DatePicker
						disabledDate={disabledDate}
						style={{ width: '100%' }}
						showTime={true}
						format={DATE_FORMAT}
					/>
				</FormItem>
				{userType === ROLE_TYPES.BUYER ? (
					<Form.Item label="Supplier" name="supplierFacilityId">
						<OWAsyncSelect
							stateSlice={supplierFacilities}
							targetCollectionName="walkthroughSupplierFacilitiesInPrivateNetwork"
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleSupplierFacilitiesInPrivateNetwork(ids, targetCollectionName);
							}}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchSupplierFacilitiesInPrivateNetwork(
									{ name: searchText, offset: 0, limit: 100 },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							renderRecord={(supplierFacility) => (
								<Select.Option key={supplierFacility.id} value={supplierFacility.id}>
									{supplierFacility.name}
								</Select.Option>
							)}
						/>
					</Form.Item>
				) : null}
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
	supplierFacilities: state.supplier_facilities,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getSiteSurvey: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? siteSurveysRestCrudThunksForSupplier.readOne(id)
				: siteSurveysRestCrudThunksForBuyer.readOne(id)
		),
	createWalkthrough: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? walkthroughsRestCrudThunksForSupplier.create(entity)
				: walkthroughsRestCrudThunksForBuyer.create(entity)
		),
	fetchMultipleSupplierFacilitiesInPrivateNetwork: (ids, targetCollectionName) =>
		dispatch(supplierFacilitiesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchSupplierFacilitiesInPrivateNetwork: (params) =>
		dispatch(
			fetchSupplierFacilitiesInPrivateNetworkForBuyer(
				params,
				'walkthroughSupplierFacilitiesInPrivateNetwork'
			)
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ScheduleWalkthroughModalForm)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

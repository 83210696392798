import { WORK_ORDERS_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/work_orders_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = standardInitialStateGenerator([
	'workOrdersIndex',
	'workOrdersTable',
	'archivedWorkOrdersIndex',
	'allWorkOrdersIndex',
	'locationAssociatedActiveWorkOrders',
	'locationAssociatedActiveSupplierWorkOrders',
	'locationAssociatedInActiveWorkOrders',
	'locationAssociatedInActiveSupplierWorkOrders',
	'assetAssociatedActiveWorkOrders',
	'assetAssociatedActiveSupplierWorkOrders',
	'assetAssociatedInActiveWorkOrders',
	'assetAssociatedInActiveSupplierWorkOrders',
	'fieldTechAssociatedOpenWorkOrders',
	'fieldTechAssociatedWorkHistory',
	'ATTENTION_DASHBOARD_STALE_WORK_ORDERS',
	'ATTENTION_DASHBOARD_EMERGENCY_WORK_ORDERS',
	'ATTENTION_DASHBOARD_PENDING_QUOTE_WORK_ORDERS',
	'ATTENTION_DASHBOARD_PENDING_APPROVAL_WORK_ORDERS',
	'ATTENTION_DASHBOARD_COMPLETED_NOT_INVOICED',
	'asset_downtime_work_orders',
	'asset_search_work_orders',
]);
export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState);

import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Alert, Button, Select } from 'antd';
import { connect } from 'react-redux';
import { meterTypesRestCrudThunksForBuyer } from '../../thunks/meter_types_thunks';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import BackButton from '../back_button/BackButton';

const style = require('./MeterTypeForm.less');

interface MeterTypeFormProps extends FormComponentProps {
	creating: boolean;
	updating: boolean;
	formData: any;
	history: any;
	redirectForwardUrl?: string;
	onSuccess: any;
	createErrors: any[];
	updateErrors: any[];
	currentUser: any;
	create: any;
	update: any;
}

let requiredFieldsUuid = 2;
let optionalFieldsUuid = 2;

class MeterTypeForm extends React.Component<MeterTypeFormProps, any> {
	handleSubmit = (e, submitAction) => {
		const { history, onSuccess, redirectForwardUrl } = this.props;
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				let meterType = {
					id: values.id,
					name: values.name,
					buyerCompanyId: values.buyerCompanyId,
					valueUnit: values.valueUnit,
					valueType: values.valueType,
				};

				submitAction(meterType).then((record) => {
					if (redirectForwardUrl) {
						history.push(redirectForwardUrl);
					}
					if (onSuccess) {
						onSuccess(record);
					}
				});
			}
		});
	};

	render() {
		const {
			updating,
			creating,
			form,
			update,
			create,
			createErrors,
			formData,
			updateErrors,
			currentUser,
		} = this.props;
		const { getFieldDecorator, setFieldsValue } = form;
		const isUpdate = formData && formData.id !== undefined;
		const submitAction = isUpdate ? update : create;
		const submitText = isUpdate ? 'Update meter type' : 'Create meter type';
		const isLoading = updating || creating;
		getFieldDecorator(`id`, { initialValue: formData.id });
		getFieldDecorator('buyerCompanyId', {
			initialValue: formData.buyerCompanyId || nullSafeGet('facility.buyerCompanyId', currentUser),
		});

		return (
			<Form
				layout="vertical"
				onSubmit={(e) => this.handleSubmit(e, submitAction)}
				className="supplierForm"
			>
				{createErrors.length > 0 ? (
					<Form.Item>
						<Alert message={createErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				{updateErrors.length > 0 ? (
					<Form.Item>
						<Alert message={updateErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				<Form.Item label="Name">
					{getFieldDecorator('name', {
						initialValue: formData.name,
					})(<Input style={{ maxWidth: 300 }} disabled={isUpdate} />)}
				</Form.Item>
				<Form.Item label="Value Type">
					{getFieldDecorator('valueType', {
						initialValue: formData.valueType,
					})(
						<Select style={{ maxWidth: 300 }}>
							<Select.Option value="string">Text</Select.Option>
							<Select.Option value="number">Number</Select.Option>
							<Select.Option value="date">Date</Select.Option>
						</Select>
					)}
				</Form.Item>
				<Form.Item label="Unit of Measure">
					{getFieldDecorator('valueUnit', {
						initialValue: formData.valueUnit,
					})(<Input style={{ maxWidth: 300 }} disabled={isUpdate} />)}
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						loading={isLoading}
						className="contactForm__button"
					>
						{submitText}
					</Button>
					<span style={{ marginLeft: '16px' }}>
						<BackButton buttonText="Cancel" />
					</span>
				</Form.Item>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	formData: ownProps.formData,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,

	problemTypes: state.problem_types,
	spendCategories: state.spend_categories,
	createErrors: state.meter_types.createErrors,
	updateErrors: state.meter_types.updateErrors,
	creating: state.meter_types.creating,
	updating: state.meter_types.updating,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	update: (entity) => dispatch(meterTypesRestCrudThunksForBuyer.update(entity)),
	create: (entity) => dispatch(meterTypesRestCrudThunksForBuyer.create(entity)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Form.create<MeterTypeFormProps>()(MeterTypeForm))
);

import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
	Switch,
	Input,
	Modal,
	Alert,
	Spin,
	Button,
	Select,
	Checkbox,
	TimePicker,
	Popover,
	message,
	DatePicker,
} from 'antd';
import { connect } from 'react-redux';
import {
	locationsRestCrudThunksForBuyer,
	locationsRestCrudThunksForSupplier,
} from '../../thunks/locations_thunks';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import {
	getObjectValues,
	nullSafeGet,
	nullSafeGetOrElse,
	getCurrency,
	mergeTypeFields,
	mergeTypeFieldsInit,
} from '../../utils/DataAccessUtils';
import BackButton from '../back_button/BackButton';
import {
	locationTypesRestCrudThunksForBuyer,
	locationTypesRestCrudThunksForSupplier,
} from '../../thunks/location_types_thunks';
import { debounce } from '../../utils/PerformanceUtils';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { imageUploadValidation, normFile } from '../../utils/ImageUtils';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import { LocationAutocomplete } from '../location_autocomplete/LocationAutocomplete';
import { getProtectedImageUriForBuyer } from '../../utils/FileAccessUtils';
import {
	buyerFacilitiesRestCrudThunksForBuyer,
	buyerFacilitiesRestCrudThunksForSupplier,
} from '../../thunks/buyer_facilities_thunks';
import {
	buyerContactsRestCrudThunksForBuyer,
	buyerContactsRestCrudThunksForSupplier,
} from '../../thunks/buyer_contacts_thunks';
import { DAYS_OF_THE_WEEK } from '../../constants/times';
import { capitalize } from '../../utils/DataFormatterUtils';
import moment from 'moment';
import {
	fetchRegionsWithChildrenForBuyer,
	fetchRegionsWithChildrenForSupplier,
	regionsRestCrudThunksForBuyer,
	regionsRestCrudThunksForSupplier,
} from '../../thunks/regions_thunks';
import { retrieveCachedUserDetails } from '../../thunks/session_thunks';
import ProblemTypeTreeSelect from '../problem_type_tree_select';
import { BUYER_SUPPLIER_ADMIN_ROLES } from '../roles/roles';
import MultiEmailComponentAntdV3 from '../MultiEmailComponent/MultiEmailComponentAntdV3';
import { CURRENCIES, ROLE_TYPES } from '../../utils/DataConstants';
import PhoneNumberComponent from '../PhoneNumberComponent/PhoneNumberComponentAntdV3';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import {
	brandsRestCrudThunksForBuyer,
	brandsRestCrudThunksForSupplier,
} from '../../thunks/brands_thunks';
import OWDateRangePicker from '../ow_date_range_picker/OWDateRangePicker';
import OWUpload from '../OWUpload';

const LOCATION_FORM_LOCATION_TYPES_AUTOCOMPLETE = 'LOCATION_FORM_LOCATION_TYPES_AUTOCOMPLETE';
const LOCATION_FORM_REGIONS_AUTOCOMPLETE = 'LOCATION_FORM_REGIONS_AUTOCOMPLETE';
const LOCATION_FORM_BUYER_CONTACTS_AUTOCOMPLETE = 'LOCATION_FORM_BUYER_CONTACTS_AUTOCOMPLETE';
const REGIONS_WITH_CHILDREN = 'regionsWithChildrenDropdown';

const dateFormat = 'MMMM DD, YYYY';

interface LocationFormProps extends FormComponentProps {
	creating: boolean;
	updating: boolean;
	formData: any;
	history: any;
	locationTypesFetching: any;
	locationTypes: any;
	regionsFetching: any;
	regions: any;
	fetchRegion: any;
	fetchRegions: any;
	createRegion: any;
	buyerContactsFetching: any;
	buyerContacts: any;
	currencies: any;
	fetchLocationType: any;
	fetchLocationTypes: any;
	fetchBuyerContacts: any;
	redirectForwardUrl?: string;
	onSuccess?: any;
	createErrors: any[];
	updateErrors: any[];
	currentUser: any;
	create: any;
	createBuyerFacility: any;
	updateBuyerFacility: any;
	fetchRegionsWithChildren: any;
	update: any;
	userType: any;
	brands: any;
	fetchBrands: any;
}

class LocationForm extends React.Component<LocationFormProps, any> {
	state = {
		region: {},
		regionName: null,
		locationType: {
			optionalFields: [],
			requiredFields: [],
		},
		previewVisible: false,
		previewImage: null,
		uploadLoading: false,
		allPhotos: [],
		isLoading: false,
	};

	componentDidMount() {
		const { formData, fetchLocationType, fetchLocationTypes, fetchRegions, fetchBuyerContacts } =
			this.props;
		const locationTypeId = formData.locationTypeId;

		fetchBuyerContacts();
		fetchLocationTypes({ limit: 50, offset: 0 });
		fetchRegions({ limit: 50, offset: 0 });
		// TODO: HYDRATE THESE IN THE ROUTE AND REMOVE
		if (locationTypeId) {
			fetchLocationType(locationTypeId).then((lt) => {
				const currentLocationTypeDetails = formData.locationTypeDetails || [];
				const mergedLocationType = mergeTypeFieldsInit(currentLocationTypeDetails, lt);

				this.setState({
					locationType: mergedLocationType,
				});
			});
		}

		let photosInfo;
		if (formData.images) {
			photosInfo = formData.images.map((p, idx) => {
				const [imgFileId, imgFileName] = p.split('/');
				const thumbUri = getProtectedImageUriForBuyer(imgFileId, imgFileName, 100, 100);
				const fullUri = getProtectedImageUriForBuyer(imgFileId, imgFileName, 400, 400);
				return {
					uid: idx,
					size: 1,
					name: imgFileName,
					filename: imgFileName,
					url: fullUri,
					status: 'done',
					thumbUrl: thumbUri,
					response: { data: { fileName: imgFileName, fileId: imgFileId } },
					type: 'image/jpeg',
				};
			});
		} else {
			photosInfo = [];
		}
		this.setState({ allPhotos: photosInfo });
	}

	saveLocation = (values, submitAction) => {
		const {
			history,
			redirectForwardUrl,
			currentUser,
			onSuccess,
			updateBuyerFacility,
			createBuyerFacility,
			form,
			formData,
		} = this.props;

		const { locationType } = this.state;
		this.setState({ isLoading: true });

		// put image data into proper format to persist
		const picturesInfo = values.images.map((p) => {
			const fileName = nullSafeGetOrElse('response.data.fileName', p, '');
			const fileId = nullSafeGetOrElse('response.data.fileId', p, '');
			return `${fileId}/${fileName}`;
		});

		// construct operating hours json from flattened fields
		const operatingHours = DAYS_OF_THE_WEEK.reduce((acc, day) => {
			if (values[`${day}-from`] && values[`${day}-to`]) {
				acc[day] = {
					from: values[`${day}-from`].format('h:mm a'),
					to: values[`${day}-to`].format('h:mm a'),
				};
			}
			return acc;
		}, {});

		// construct address json from flattened fields
		const primaryAddress = Object.keys(values)
			.filter((k) => k.indexOf('primaryAddress') > -1)
			.reduce((acc, k) => {
				const [_, x] = k.split('-');
				acc[x] = values[k];
				return acc;
			}, {});

		// Combine required and optional fields into one array
		const locationTypeDetails = (locationType.requiredFields || [])
			.concat(locationType.optionalFields || [])
			.map((field) => ({
				key: form.getFieldValue(`${field.key}-key`),
				valueType: form.getFieldValue(`${field.key}-valueType`),
				valueAsString: form.getFieldValue(`${field.key}-valueAsString`),
			}));

		let buyerFacility = {
			id: values.buyerFacilityId,
			name: values.name,
			displayName: values.name,
			primaryContactEmail: currentUser.email,
			primaryAddress,
			latitude: values.latitude,
			longitude: values.longitude,
			status: 'active',
			buyerCompanyId: nullSafeGet('facility.buyerCompanyId', currentUser),
			currencyId: values.currencyId || 'USD',
		};

		let location = {
			id: values.id,
			name: values.name,
			description: values.description,
			isActive: values.isActive,
			buyerFacilityId: values.buyerFacilityId,
			images: picturesInfo,
			operatingHours,
			phoneNumber: values.phoneNumber,
			currencyId: values.currencyId || 'USD',
			storeId: values.storeId,
			brandId: values.brandId ? parseInt(values.brandId, 10) : undefined,
			regionId: values.regionId,
			facilityInChargeEmail: values.facilityInChargeEmail,
			facilityInChargeEmails: values.facilityInChargeEmails,
			sendApprovedInvoicesToEmails: values.sendApprovedInvoicesToEmails,
			primaryContactsEmails: values.primaryContactsEmails,
			locationTypeId: parseInt(values.locationTypeId, 10),
			locationTypeDetails: locationTypeDetails.filter(
				(field) => field.valueAsString !== undefined && field.valueAsString !== ''
			),
			notes: formData.notes,
			internalNotes: formData.internalNotes,
			internalAttachments: values.internalAttachments,
			isLeased: values.isLeased,
			leaseStartDate: values.leaseStartDate,
			leaseEndDate: values.leaseEndDate,
			openDate: values.openDate,
			closeDate: values.closeDate,
		};

		const handleSubmitSuccess = (newBuyerFacility) => {
			location.buyerFacilityId = newBuyerFacility.id;
			submitAction(location)
				.then((record) => {
					if (redirectForwardUrl) {
						history.push(redirectForwardUrl);
					}
					if (onSuccess) {
						onSuccess(record);
					}
				})
				.catch((err) => message.error(err))
				.finally(() => this.setState({ isLoading: false }));
		};

		if (!values.buyerFacilityId) {
			createBuyerFacility(buyerFacility)
				.then(handleSubmitSuccess)
				.catch((err) => {
					message.error(err);
					this.setState({ isLoading: false });
				});
		} else {
			updateBuyerFacility(buyerFacility)
				.then(handleSubmitSuccess)
				.catch((err) => {
					message.error(err);
					this.setState({ isLoading: false });
				});
		}
	};

	handleSubmit = (e, submitAction) => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.saveLocation(values, submitAction);
			} else {
				this.props.form.validateFields((err, values) => {
					/*
					Doing a second time validation on error, because antd form throws an error for email fields on first time validation
					Fix proposed is to use the Form.useForm which we already use in other place.
					That needs to be worked on immediately after. This is only a temporary solution
					*/
					if (!err) this.saveLocation(values, submitAction);
				});
			}
		});
	};

	handlePreview = (file) => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true,
		});
	};

	handleImageUpdate = (info) => {
		if (info.file.status === 'done') {
			this.setState({ allPhotos: info.fileList });
		} else if (info.file.status === undefined) {
			info.fileList = this.state.allPhotos;
		}
	};

	debouncedSearchLocationTypes = debounce(
		(searchText) => {
			const { fetchLocationTypes } = this.props;
			fetchLocationTypes({ name: searchText && searchText.trim() });
		},
		500,
		false
	);

	handleLocationTypeChange = (locationTypeId) => {
		const { fetchLocationType } = this.props;
		const { locationType } = this.state;
		if (locationTypeId) {
			fetchLocationType(locationTypeId).then((newLocationType) => {
				const updatedLocationType = mergeTypeFields(locationType, newLocationType);
				this.setState({ locationType: updatedLocationType });
			});
		}
	};

	regionNameChange = (text) => {
		this.setState({
			regionName: text,
		});
	};

	debouncedSearchRegions = debounce(
		(searchText) => {
			const { fetchRegions } = this.props;
			this.regionNameChange(searchText && searchText.trim());
			fetchRegions({ name: searchText && searchText.trim() });
		},
		500,
		false
	);

	addRegion = () => {
		const { regionName } = this.state;
		const { createRegion, currentUser, fetchRegions } = this.props;
		const buyerCompanyId = nullSafeGet('facility.buyerCompanyId', currentUser);
		if (regionName && buyerCompanyId) {
			createRegion({ name: regionName, buyerCompanyId }).then(() => {
				fetchRegions({ limit: 50, offset: 0 });
			});
		}
	};

	handleRegionChange = (regionId) => {
		const { fetchRegion } = this.props;
		if (regionId) {
			fetchRegion(regionId).then((r) => this.setState({ region: r }));
		}
	};

	debouncedSearchBuyerContacts = debounce(
		(searchText) => {
			const { fetchBuyerContacts } = this.props;
			fetchBuyerContacts({ email: searchText && searchText.trim() });
		},
		500,
		false
	);

	searchBuyerContacts = (searchText) => {
		this.debouncedSearchBuyerContacts(searchText);
	};

	searchLocationTypes = (searchText) => {
		this.debouncedSearchLocationTypes(searchText);
	};

	searchRegions = (searchText) => {
		this.debouncedSearchRegions(searchText);
	};

	handleCancel = () => this.setState({ previewVisible: false });

	handleLocationAutocompleteSelect = (addr, lonLat, parsedAddress) => {
		const { form } = this.props;

		form.setFieldsValue({
			addressInput: parsedAddress,
			'primaryAddress-streetAddress1': parsedAddress.streetAddress1,
			'primaryAddress-city': parsedAddress.city,
			'primaryAddress-region': parsedAddress.region,
			'primaryAddress-postcode': parsedAddress.postcode,
			'primaryAddress-country': parsedAddress.country,
			longitude: lonLat.lng,
			latitude: lonLat.lat,
		});
	};

	handleUploadChange = (info) => {
		if (info.file.status === 'uploading') {
			this.setState({ uploadLoading: true });
			return;
		}
		if (info.file.status === 'done') {
			this.setState({
				uploadLoading: false,
			});
		}
	};

	handleLeaseDateChange = (values) => {
		const { form } = this.props;
		console.log(values);
		if (values) {
			const [leaseStartDate, leaseEndDate] = values;
			form.setFieldsValue({ leaseStartDate, leaseEndDate });
		} else {
			form.setFieldsValue({
				leaseStartDate: undefined,
				leaseEndDate: undefined,
			});
		}
	};

	render() {
		const {
			form,
			update,
			create,
			createErrors,
			formData,
			locationTypes,
			locationTypesFetching,
			updateErrors,
			currentUser,
			regions,
			fetchRegionsWithChildren,
			userType,
			brands,
			fetchBrands,
		} = this.props;
		const { locationType, previewImage, previewVisible } = this.state;
		const { getFieldDecorator, getFieldValue } = form;
		const userRoles = nullSafeGetOrElse('roles', currentUser, []);
		const isAdmin = userRoles.some((_) => BUYER_SUPPLIER_ADMIN_ROLES.includes(_));
		const isUpdate = formData && formData.id !== undefined;
		const submitAction = isUpdate ? update : create;
		const submitText = isUpdate ? 'Update location' : 'Create location';
		const locationTypesDropdownRecords = getRecordsForTargetCollection(
			locationTypes,
			LOCATION_FORM_LOCATION_TYPES_AUTOCOMPLETE
		);
		const currencyRecordsList = getObjectValues(CURRENCIES);
		const currencyRecordsMap = getObjectValues(CURRENCIES);
		const createRawAddress = (addr) => {
			if (!addr || addr === undefined) {
				return '';
			}
			return `${addr.streetAddress1}  ${addr.city}, ${addr.region}, ${addr.postcode}, ${addr.country}`;
		};
		const rawAddress = createRawAddress(nullSafeGet('buyerFacility.primaryAddress', formData));

		const BACKEND_URI = getBackendUri();
		const uploadHeaders = { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token };

		let initialLocationDetailsMap = {};
		if (formData.locationTypeDetails) {
			formData.locationTypeDetails.forEach((ltd) => {
				initialLocationDetailsMap[`${ltd.key}-key`] = ltd.key;
				initialLocationDetailsMap[`${ltd.key}-valueType`] = ltd.valueType;
				initialLocationDetailsMap[`${ltd.key}-valueAsString`] = ltd.valueAsString;
			});
		}

		const getFieldItems = (lt) => {
			if (!lt || !lt.id) {
				return null;
			}

			return [...(lt.requiredFields || []), ...(lt.optionalFields || [])].map((field) => {
				getFieldDecorator(`${field.key}-key`, { initialValue: field.key });
				getFieldDecorator(`${field.key}-valueType`, { initialValue: field.type });

				return (
					<Form.Item label={field.key} key={field.key}>
						{form.getFieldDecorator(`${field.key}-valueAsString`, {
							validateTrigger: ['onChange', 'onBlur'],
							initialValue: initialLocationDetailsMap[`${field.key}-valueAsString`],
							rules: lt.requiredFields.some((reqField) => reqField.key === field.key)
								? [{ required: true, message: 'This field is required.' }]
								: [],
						})(<Input />)}
					</Form.Item>
				);
			});
		};

		getFieldDecorator(`id`, { initialValue: formData.id });
		getFieldDecorator(`buyerFacilityId`, { initialValue: formData.buyerFacilityId });
		getFieldDecorator(`longitude`, {
			initialValue: nullSafeGet('buyerFacility.longitude', formData),
		});
		getFieldDecorator(`latitude`, {
			initialValue: nullSafeGet('buyerFacility.latitude', formData),
		});
		getFieldDecorator(`primaryAddress-streetAddress1`, {
			initialValue: nullSafeGet('buyerFacility.primaryAddress.streetAddress1', formData),
		});
		getFieldDecorator(`primaryAddress-streetAddress2`, {
			initialValue: nullSafeGet('buyerFacility.primaryAddress.streetAddress2', formData),
		});
		getFieldDecorator(`primaryAddress-city`, {
			initialValue: nullSafeGet('buyerFacility.primaryAddress.city', formData),
		});
		getFieldDecorator(`primaryAddress-region`, {
			initialValue: nullSafeGet('buyerFacility.primaryAddress.region', formData),
		});
		getFieldDecorator(`primaryAddress-postcode`, {
			initialValue: nullSafeGet('buyerFacility.primaryAddress.postcode', formData),
		});
		getFieldDecorator(`primaryAddress-country`, {
			initialValue: nullSafeGet('buyerFacility.primaryAddress.country', formData),
		});
		getFieldDecorator('status', { initialValue: 'active' });
		getFieldDecorator('internalAttachments', { initialValue: formData.internalAttachments || [] });
		const sendApprovedInvoicesToEmailsInitialValue =
			formData.sendApprovedInvoicesToEmails && formData.sendApprovedInvoicesToEmails.length > 0
				? formData.sendApprovedInvoicesToEmails
				: [];
		getFieldDecorator('sendApprovedInvoicesToEmails', {
			initialValue: sendApprovedInvoicesToEmailsInitialValue,
		});
		const facilityInChargeEmailsInitialValue =
			formData.facilityInChargeEmails && formData.facilityInChargeEmails.length > 0
				? formData.facilityInChargeEmails
				: [formData.facilityInChargeEmail || currentUser.email];
		getFieldDecorator('facilityInChargeEmails', {
			initialValue: facilityInChargeEmailsInitialValue,
		});
		const primaryContactsEmailsInitialValue =
			formData.primaryContactsEmails && formData.primaryContactsEmails.length > 0
				? formData.primaryContactsEmails
				: [currentUser.email];
		getFieldDecorator('primaryContactsEmails', {
			initialValue: primaryContactsEmailsInitialValue,
		});

		getFieldDecorator('leaseStartDate', {
			initialValue: formData.leaseStartDate,
		});
		getFieldDecorator('leaseEndDate', {
			initialValue: formData.leaseEndDate,
		});

		return (
			<Form
				layout="vertical"
				onSubmit={(e) => this.handleSubmit(e, submitAction)}
				className="supplierForm"
				style={{ maxWidth: 600 }}
			>
				{createErrors.length > 0 ? (
					<Form.Item>
						<Alert message={createErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				{updateErrors.length > 0 ? (
					<Form.Item>
						<Alert message={updateErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				<Form.Item label="Name">
					{getFieldDecorator('name', {
						initialValue: formData.name,
						rules: [{ required: true, message: 'Please give the location a name.' }],
					})(<Input />)}
				</Form.Item>
				<Form.Item required={true} label="Address">
					<div>
						{getFieldDecorator('addressInput', {
							initialValue: nullSafeGet('buyerFacility.primaryAddress', formData),
							rules: [{ required: true, message: 'Please search for and select an address' }],
						})(
							<LocationAutocomplete
								initialValue={rawAddress}
								placeholder="Search for and select an address"
								onSelect={this.handleLocationAutocompleteSelect}
							/>
						)}
					</div>
				</Form.Item>
				<Form.Item label="Store ID">
					{getFieldDecorator('storeId', {
						initialValue: formData.storeId,
						rules: [{ required: true, message: 'Please specify location id.' }],
					})(<Input />)}
				</Form.Item>
				<Form.Item label="Brand">
					{getFieldDecorator('brandId', {
						initialValue: formData.brandId,
					})(
						<OWAsyncSelect
							stateSlice={brands}
							targetCollectionName={'brandsIndex'}
							fetchMultiple={(ids, targetCollectionName) => {}}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchBrands(
									{ brandName: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							renderRecord={(brand) => (
								<Select.Option key={brand.id} value={brand.id}>
									{brand.brandName}
								</Select.Option>
							)}
							sortBy={{ sort_by: 'title', order: 'ascend' }}
						/>
					)}
				</Form.Item>
				<Form.Item label="Region">
					{getFieldDecorator('regionId', {
						initialValue: formData.regionId,
					})(
						<ProblemTypeTreeSelect
							allowClear={true}
							stateSlice={regions}
							targetCollectionName={REGIONS_WITH_CHILDREN}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) =>
								fetchRegionsWithChildren(
									{ name: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								)
							}
							fetchMultiple={() => {}}
							renderRecord={(sf) => (
								<Select.Option key={sf.id} value={sf.id}>
									{sf.name}
								</Select.Option>
							)}
						/>
					)}
				</Form.Item>
				<Form.Item label="Is Active">
					{getFieldDecorator('isActive', {
						initialValue: formData.isActive === undefined ? true : formData.isActive,
						valuePropName: 'checked',
					})(<Switch />)}
				</Form.Item>
				<MultiEmailComponentAntdV3
					form={form}
					formData={formData}
					label={<span>Primary Contact's Email</span>}
					fieldProps={{
						key: `primary-contact-emails`,
						rules: [
							...(isAdmin
								? [
										{
											required: true,
											message:
												'Please give email for primary point of contact on-site at this location.',
										},
								  ]
								: []),
						],
						preserve: false,
						name: 'primaryContactsEmails',
					}}
					disabled={!isAdmin}
				/>
				<MultiEmailComponentAntdV3
					form={form}
					formData={formData}
					label={<span>Facilities Contact's Emails</span>}
					fieldProps={{
						key: `facility-incharge-emails`,
						rules: [
							...(isAdmin
								? [
										{
											required: true,
											message:
												'Please give email for person in charge of the maintenance and repairs for this location.',
										},
								  ]
								: []),
						],
						preserve: false,
						name: 'facilityInChargeEmails',
					}}
					disabled={!isAdmin}
				/>
				<MultiEmailComponentAntdV3
					form={form}
					formData={formData}
					label={
						<span>
							Send Approved Invoices To{' '}
							<Popover content={'This field can only be edited by admins.'}>
								<InfoCircleOutlined translate="" style={{ fontSize: 12, marginLeft: 2 }} />
							</Popover>
						</span>
					}
					fieldProps={{
						key: `send-approved-invoices-to`,
						rules: [
							{
								required: false,
								message:
									'Please enter an email for approved invoices to be sent to for this location.',
							},
						],
						preserve: false,
						tooltip:
							'These email addresses will be emailed approved invoices. You can enter multiple emails seperated by comma',
						name: 'sendApprovedInvoicesToEmails',
					}}
					disabled={!isAdmin}
				/>

				<Form.Item label="Currency">
					{form.getFieldDecorator('currencyId', {
						rules: [{ required: true, message: 'Please select a currency for this event.' }],
						initialValue: getCurrency({ location: formData }).id || 'USD',
					})(
						<Select
							showSearch={true}
							optionFilterProp="children"
							filterOption={(input, option) =>
								currencyRecordsMap[option.props.value].displayNamePlural
									.toLowerCase()
									.indexOf(input.toLowerCase()) >= 0
							}
							placeholder="Currency"
						>
							{currencyRecordsList.map((currency) => (
								<Select.Option key={currency.id} value={currency.id}>
									{capitalize(currency.displayNamePlural)} ({currency.id})
								</Select.Option>
							))}
						</Select>
					)}
				</Form.Item>
				<Form.Item label="Location Type">
					{getFieldDecorator('locationTypeId', {
						initialValue: formData.locationTypeId,
						rules: [{ required: true, message: 'This field is required.' }],
					})(
						<Select
							notFoundContent={locationTypesFetching ? <Spin size="small" /> : null}
							placeholder="Type to search for location type"
							filterOption={false}
							showSearch={true}
							onSearch={this.searchLocationTypes}
							onChange={this.handleLocationTypeChange}
						>
							{locationTypesDropdownRecords.map((d) => (
								<Select.Option value={d.id} key={d.id}>
									{d.name}
								</Select.Option>
							))}
						</Select>
					)}
				</Form.Item>
				{getFieldItems(locationType)}
				<PhoneNumberComponent
					form={form}
					label="Phone Number"
					fieldProps={{
						initialValue: formData.phoneNumber,
						name: 'phoneNumber',
					}}
				/>
				<Form.Item label="Photos">
					{getFieldDecorator(`images`, {
						valuePropName: 'fileList',
						getValueFromEvent: normFile,
						initialValue: this.state.allPhotos,
					})(
						<OWUpload
							accept="image/*"
							name="file"
							listType="picture-card"
							className="avatar-uploader"
							multiple={true}
							headers={uploadHeaders}
							onPreview={this.handlePreview}
							action={`${BACKEND_URI}/api/v1/${userType}/file/upload`}
							beforeUpload={imageUploadValidation}
							onChange={this.handleImageUpdate}
						>
							<div style={{ display: 'inline' }}>
								<LegacyIcon type={this.state.uploadLoading ? 'loading' : 'plus'} />
								<div className="ant-upload-text">Upload</div>
							</div>
							<Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
								<img alt="example" style={{ width: '100%' }} src={previewImage} />
							</Modal>
						</OWUpload>
					)}
				</Form.Item>
				<Form.Item label="Is Leased?">
					{getFieldDecorator('isLeased', {
						initialValue: formData.isLeased === undefined ? false : formData.isLeased,
						valuePropName: 'checked',
					})(<Switch />)}
				</Form.Item>
				{form.getFieldValue(`isLeased`)
					? [
							<Form.Item label="Lease Start and End Date">
								{getFieldDecorator('leasePeriod', {
									initialValue: [
										formData.leaseStartDate ? moment(formData.leaseStartDate) : undefined,
										formData.leaseEndDate ? moment(formData.leaseEndDate) : undefined,
									],
									rules: [
										{
											required: true,
											message: 'Must enter the Lease start or end date.',
											validator: (rule, value, callback) => {
												if (value === null || !value[1]) {
													callback('error');
													return Promise.reject('');
												}
												return Promise.resolve();
											},
										},
									],
								})(<OWDateRangePicker format={dateFormat} onChange={this.handleLeaseDateChange} />)}
							</Form.Item>,
					  ]
					: null}
				<Form.Item label="Open Date">
					{getFieldDecorator('openDate', {
						initialValue: formData.openDate ? moment(formData.openDate) : undefined,
					})(
						<DatePicker
							disabledDate={(current) => current && current > moment()}
							format="DD-MM-YYYY"
							placeholder={'Open Date'}
						/>
					)}
				</Form.Item>
				<Form.Item label="Close Date">
					{getFieldDecorator('closeDate', {
						initialValue: formData.closeDate ? moment(formData.closeDate) : undefined,
					})(
						<DatePicker
							disabledDate={(current) => current && current > moment()}
							format="DD-MM-YYYY"
							placeholder={'Close Date'}
						/>
					)}
				</Form.Item>
				<table style={{ marginBottom: 16 }}>
					{DAYS_OF_THE_WEEK.map((day) => (
						<tr key={day} style={{ lineHeight: '48px' }}>
							<td>
								<span style={{ fontSize: 16 }}>{day}</span>
							</td>
							<td style={{ paddingRight: 8, paddingLeft: 32 }}>
								{getFieldDecorator(`${day}-isOpen`, {
									valuePropName: 'checked',
									initialValue: nullSafeGet(`operatingHours.${day}.from`, formData) !== undefined,
								})(<Checkbox />)}
							</td>
							{getFieldValue(`${day}-isOpen`) ? (
								[
									<td key={1}>
										{getFieldDecorator(`${day}-from`, {
											initialValue: nullSafeGet(`operatingHours.${day}.from`, formData)
												? moment(nullSafeGet(`operatingHours.${day}.from`, formData), 'h:mm a')
												: undefined,
										})(
											<TimePicker
												style={{ width: 160 }}
												use12Hours={true}
												format="h:mm a"
												minuteStep={15}
											/>
										)}
									</td>,
									<td key={2} style={{ paddingRight: 8, paddingLeft: 8 }}>
										to
									</td>,
									<td key={3}>
										{getFieldDecorator(`${day}-to`, {
											initialValue: nullSafeGet(`operatingHours.${day}.to`, formData)
												? moment(nullSafeGet(`operatingHours.${day}.to`, formData), 'h:mm a')
												: undefined,
										})(
											<TimePicker
												style={{ width: 160 }}
												use12Hours={true}
												format="h:mm a"
												minuteStep={15}
											/>
										)}
									</td>,
								]
							) : (
								<td>Closed</td>
							)}
						</tr>
					))}
				</table>
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						loading={this.state.isLoading}
						className="contactForm__button"
					>
						{submitText}
					</Button>
					<span style={{ marginLeft: '16px' }}>
						<BackButton buttonText="Cancel" />
					</span>
				</Form.Item>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	formData: ownProps.formData,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,
	locationTypesFetching: state.location_types.fetching,
	locationTypes: state.location_types,
	regionsFetching: state.regions.fetching,
	regions: state.regions,
	buyerContactsFetching: state.buyer_contacts.fetching,
	buyerContacts: state.buyer_contacts,
	currencies: state.currencies,

	createErrors: state.locations.createErrors,
	updateErrors: state.locations.updateErrors,
	creating: state.locations.creating,
	updating: state.locations.updating,
	currentUser: state.session.currentUser,
	brands: state.brands,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	update: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.update(entity)
				: locationsRestCrudThunksForBuyer.update(entity)
		),
	create: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.create(entity)
				: locationsRestCrudThunksForBuyer.create(entity)
		),
	createBuyerFacility: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? buyerFacilitiesRestCrudThunksForSupplier.create(entity)
				: buyerFacilitiesRestCrudThunksForBuyer.create(entity)
		),
	updateBuyerFacility: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? buyerFacilitiesRestCrudThunksForSupplier.update(entity)
				: buyerFacilitiesRestCrudThunksForBuyer.update(entity)
		),
	fetchLocationType: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationTypesRestCrudThunksForSupplier.readOne(id)
				: locationTypesRestCrudThunksForBuyer.readOne(id)
		),
	fetchLocationTypes: (params) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationTypesRestCrudThunksForSupplier.read(
						params,
						LOCATION_FORM_LOCATION_TYPES_AUTOCOMPLETE
				  )
				: locationTypesRestCrudThunksForBuyer.read(
						params,
						LOCATION_FORM_LOCATION_TYPES_AUTOCOMPLETE
				  )
		),
	fetchRegion: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? regionsRestCrudThunksForSupplier.readOne(id)
				: regionsRestCrudThunksForBuyer.readOne(id)
		),
	fetchRegions: (params) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? regionsRestCrudThunksForSupplier.read(params, LOCATION_FORM_REGIONS_AUTOCOMPLETE)
				: regionsRestCrudThunksForBuyer.read(params, LOCATION_FORM_REGIONS_AUTOCOMPLETE)
		),
	fetchRegionsWithChildren: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? fetchRegionsWithChildrenForSupplier(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: fetchRegionsWithChildrenForBuyer(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	createRegion: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? regionsRestCrudThunksForSupplier.create(entity)
				: regionsRestCrudThunksForBuyer.create(entity)
		),
	fetchBuyerContacts: (params) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? buyerContactsRestCrudThunksForSupplier.read(
						params,
						LOCATION_FORM_BUYER_CONTACTS_AUTOCOMPLETE
				  )
				: buyerContactsRestCrudThunksForBuyer.read(
						params,
						LOCATION_FORM_BUYER_CONTACTS_AUTOCOMPLETE
				  )
		),
	fetchBrands: (params) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? brandsRestCrudThunksForSupplier.read(params, 'brandsIndex')
				: brandsRestCrudThunksForBuyer.read(params, 'brandsIndex')
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Form.create()(LocationForm))
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

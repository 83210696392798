import { capitalize } from './DataFormatterUtils';
import { nullSafeGet } from './DataAccessUtils';

export const mappingTemplateTypesForDisplay = (record) =>
	record.type.toLowerCase() === 'string' ? 'text' : record.type;

export const mappingApprovalTypesForLinks = (record) =>
	record.approvalHierarchyType === 'proposal' ? 'quoteApprovalConfig' : 'invoiceApprovalConfig';

export const mappingApprovalRuleComparisonData = (record) => ({
	isCapex: record.isCapex,
	isPM: record.isPM,
	woPriorityIds: record.woPriorityIds,
	problemTypeIds: record.problemTypeIds,
});

export const mappingApprovalRulesURLForTypes = (record) =>
	nullSafeGet('params.type', record) === 'quote'
		? 'proposal'
		: nullSafeGet('params.type', record) === 'invoice'
		? 'invoice'
		: 'workOrder';

export const mappingApprovalRulesURLForTargetCollection = (record) =>
	nullSafeGet('params.type', record) === 'quote'
		? 'Quote'
		: nullSafeGet('params.type', record) === 'invoice'
		? 'Invoice'
		: 'WorkOrder';

export const mappingURLParamsToAddApprovalButton = (s) => (s === 'workOrder' ? 'work order' : s);

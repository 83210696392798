import OWRadioGroup from '../components/ow_radio_group/OWRadioGroup';
import { nullSafeGet } from './DataAccessUtils';
import { FILTER_FIELD_TYPE } from './DataConstants';

export const INITIAL_ACTIVE_FILTERS = {
	noActiveFilter: true,
	isActive: 'true',
};

export const INITIAL_IS_ACTIVE_FILTERS = {
	noIsActiveFilter: true,
	isActive: 'true',
};

export const IS_ACTIVE_FILTER_ITEMS = (isReduxTableWithHeader) => [
	{
		label: 'All',
		value: isReduxTableWithHeader ? 'undefined' : undefined,
	},
	{
		label: 'Active',
		value: 'true',
	},
	{
		label: 'Inactive',
		value: 'false',
	},
];

export const getIsActiveFilterField: any = (
	updateFilters,
	tcName,
	isReduxTableWithHeader = false
) => ({
	fieldName: 'isActive',
	type: FILTER_FIELD_TYPE.RADIO_GROUP,
	items: IS_ACTIVE_FILTER_ITEMS(isReduxTableWithHeader),
	filtersValueAccessor: (filtersStateSlice) =>
		nullSafeGet(`${tcName}.filters.isActive`, filtersStateSlice),
	defaultValue: isReduxTableWithHeader ? true : 'true',
	keyAccessor: (item) => item.value,
	valueAccessor: (item) => item.value,
	handleChange: (isActive) => {
		console.log('isActive');
		console.log(isActive);
		updateFilters({ isActive }, tcName);
	},
	renderRecord: (item) => item.label,
	component: OWRadioGroup,
	showOutsideDropdown: true,
});

import React, { FC } from 'react';

const style = require('./HorizontalKeyValueDisplay.less');

export const HorizontalKeyValueDisplay: FC<any> = (props) => {
	const obj = props.keyValueStore;
	const renderFuncs = props.renderFuncs;
	const keyAliases = props.keyAliases;
	const rowStyle = props.rowStyle || {};

	return (
		<div className="horizontalKeyValueTable">
			{Object.keys(obj).reduce((acc, k, idx) => {
				const v = obj[k];
				acc.push(
					<div className="horizontalKeyValueTable__row" style={rowStyle} key={idx}>
						<div key={'k' + idx} className="horizontalKeyValueTable__key">
							{keyAliases && keyAliases[k] ? keyAliases[k] : k}
						</div>
						<div key={'v' + idx} className="horizontalKeyValueTable__value">
							{renderFuncs && renderFuncs[k] ? renderFuncs[k](v) : v}
						</div>
					</div>
				);
				return acc;
			}, [])}
		</div>
	);
};

import React, { useCallback, useMemo, useState } from 'react';
import { Button, Form, InputNumber, Modal, message } from 'antd';
import { getEntityById, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { updatePartsOnServiceCallWithPartsPerStockLocationForSupplier } from '../../thunks/service_calls_thunks';
import { useForm } from 'antd/lib/form/Form';
import { scheduleServiceCallForSupplier } from '../../thunks/work_orders_thunks';
import moment from 'moment';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { releaseRequestedQuantityForSupplier } from '../../thunks/requested_parts_thunks';

const FORM_NAME = 'requested-parts-form';

const RequestedPartsQuantityModal = ({
	releaseText,
	requestedPart,
	serviceCall,
	onCancel,
	successCallback,
	updatePartsWithQuantity,
	scheduleServiceCall,
	currentUser,
	workOrder,
	releaseRequestedPart,
}): React.ReactElement => {
	const [form] = useForm();

	const [saving, setSaving] = useState(false);

	const supplierFacilityId = useMemo(() => nullSafeGet('facility.id', currentUser), [currentUser]);

	const title = useMemo(
		() =>
			`${releaseText ? releaseText : 'Consume'} ${nullSafeGetOrElse(
				'part.name',
				requestedPart,
				'Part'
			)}`,
		[releaseText, requestedPart]
	);

	const getServiceCallId = useCallback(() => {
		return new Promise((resolve, reject) => {
			nullSafeGet('id', serviceCall)
				? resolve(nullSafeGet('id', serviceCall))
				: scheduleServiceCall({
						serviceScheduledAt: moment().format(),
						supplierFacilityId,
						workOrderId: workOrder.id,
				  })
						.then((sc) =>
							nullSafeGet('lastServiceCall.id', sc)
								? resolve(nullSafeGet('lastServiceCall.id', sc))
								: reject('Service call id not found')
						)
						.catch((err) => reject(err));
		});
	}, [serviceCall, scheduleServiceCall, supplierFacilityId, workOrder.id]);

	const existingParts = useMemo(
		() => nullSafeGetOrElse('partsWithQuantity', serviceCall, []),
		[serviceCall]
	);

	const existingPartQuantity = useMemo(() => {
		const matchingRecord = existingParts.find(
			(_) =>
				_.partId === nullSafeGet('partId', requestedPart) &&
				((!!_.partsPerStockLocationId &&
					_.partsPerStockLocationId === nullSafeGet('partsPerStockLocationId', requestedPart)) ||
					(!!_.locationId && _.locationId === nullSafeGet('locationId', requestedPart)))
		);
		return nullSafeGetOrElse('quantity', matchingRecord, 0);
	}, [existingParts, requestedPart]);

	const consumePart = useCallback(
		(quantity) => {
			setSaving(true);
			getServiceCallId().then((serviceCallId) =>
				updatePartsWithQuantity({
					serviceCallId,
					stockLocationId: nullSafeGet('partsPerStockLocation.stockLocationId', requestedPart),
					partsPerStockLocationId: nullSafeGet('partsPerStockLocation.id', requestedPart),
					partId: nullSafeGet('partId', requestedPart),
					//If there is no ppsl id it means it is shipped to a location flow
					//For this no need to add the existing quantity
					quantity: quantity + existingPartQuantity,
					...(nullSafeGet('id', requestedPart) && {
						workOrderRequestedPartId: nullSafeGet('id', requestedPart),
					}),
				})
					.then(() => successCallback && successCallback())
					.catch(() => message.error('Unable to consume part at this moment!'))
					.finally(() => setSaving(false))
			);
		},
		[
			existingPartQuantity,
			getServiceCallId,
			requestedPart,
			successCallback,
			updatePartsWithQuantity,
		]
	);

	const releasePart = useCallback(
		(requestedQuantity) => {
			setSaving(true);
			releaseRequestedPart(requestedPart, {
				requestedQuantity,
			})
				.then(() => successCallback && successCallback())
				.catch(() => message.error(`Unable to ${releaseText} part at this moment!`))
				.finally(() => setSaving(false));
		},
		[releaseRequestedPart, releaseText, requestedPart, successCallback]
	);

	const isRelease = useMemo(() => !!releaseText, [releaseText]);

	const onSubmit = useCallback(
		(values) => (isRelease ? releasePart(values.quantity) : consumePart(values.quantity)),
		[consumePart, isRelease, releasePart]
	);

	return (
		<Modal
			visible={true}
			width={600}
			title={title}
			closable
			onCancel={onCancel}
			forceRender
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button
					type="primary"
					size="large"
					style={{ marginLeft: '16px' }}
					key="submit"
					htmlType="submit"
					form={FORM_NAME}
					loading={saving}
				>
					{releaseText ? releaseText : 'Consume'}
				</Button>,
			]}
		>
			<Form form={form} id={FORM_NAME} layout="vertical" requiredMark={false} onFinish={onSubmit}>
				<Form.Item
					name="quantity"
					label="Quantity"
					initialValue={nullSafeGet('requestedQuantity', requestedPart)}
					rules={[
						{
							type: 'number',
							min: 1,
							max: nullSafeGet('requestedQuantity', requestedPart),
							message: `You do not have enough quantity to add`,
						},
						{
							required: true,
						},
					]}
				>
					<InputNumber
						style={{ width: '100%' }}
						min="1"
						disabled={!requestedPart.partsPerStockLocationId}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state, ownProps) => ({
	workOrder: getEntityById(ownProps.match.params.id, state.work_orders),
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	updatePartsWithQuantity: (entity) =>
		dispatch(updatePartsOnServiceCallWithPartsPerStockLocationForSupplier(entity)),
	releaseRequestedPart: (entity, quantity) =>
		dispatch(releaseRequestedQuantityForSupplier(entity, quantity)),
	scheduleServiceCall: (serviceCall) => dispatch(scheduleServiceCallForSupplier(serviceCall)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(RequestedPartsQuantityModal)
);

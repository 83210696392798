import * as React from 'react';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {
	Table,
	Row,
	Col,
	Card,
	Collapse,
	Switch,
	Layout,
	Input,
	Button,
	message,
	Spin,
} from 'antd';
import { FlexibleImageAvatar } from '../flexible-image-avatar/FlexibleImageAvatar';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { AddressDisplay } from '../address_display/AddressDisplay';
import { debounce } from '../../utils/PerformanceUtils';
import { getObjectValues, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import MapboxGallery from '../mapboxgallery/MapboxGallery';
import {
	spendCategoriesRestCrudThunksForBuyer,
	spendCategoriesRestCrudThunksForSupplier,
} from '../../thunks/spend_categories_thunks';
import { SUPPLIER_FACILITIES_SEARCH_ACTION_CREATORS } from '../../actions/supplier_facilities_actions';
import { supplierFacilitiesSearchThunks } from '../../thunks/search_thunks';
import { connect } from 'react-redux';
import PaginatedReduxSearchResultsTable from '../paginated_redux_search_results_table/PaginatedReduxSearchResultsTable';
import { capitalizeEachWord } from '../../utils/DataFormatterUtils';
import SidebarMultiselectFilter from '../sidebar_multiselect_filter/SidebarMultiselectFilter';
import { EmptyState } from '../empty_state/EmptyState';
import SupplierPrivateNetworkCreateForm from '../supplier_private_network_create_form/SupplierPrivateNetworkCreateForm';
import {
	createSupplierPrivateNetworkBulkForBuyer,
	createSupplierPrivateNetworkBulkForSupplier,
	supplierPrivateNetworksGetDistinctForBuyer,
	supplierPrivateNetworksGetDistinctForSupplier,
	supplierPrivateNetworksRestCrudThunksForBuyer,
	supplierPrivateNetworksRestCrudThunksForSupplier,
} from '../../thunks/supplier_private_networks_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { ROLE_TYPES } from '../../utils/DataConstants';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import { getAllSupplierPrivateNetworkEntities } from '../supplier_private_network_create_form/supplier_private_network_utils';

const queryString = require('qs');
require('./BuyerWrenchHubPage.less');

const Content = Layout.Content;

class BuyerWrenchHubPage extends React.Component<any, any> {
	formRefs: any = {
		supplierPrivateNetworkCreateForm: null,
	};
	initialPagination: any = { current: 1, pageSize: 10, showSizeChanger: false };

	constructor(props) {
		super(props);
		this.state = {
			supplierPrivateNetworkCreateFormVisible: false,
			supplierFacilityId: undefined,
			privateNetwork: [],
			privateNetworkFetching: true,
			creatingPrivateNetwork: false,
		};
	}

	componentDidMount() {
		const {
			location,
			updateSupplierFacilitySearchFilters,
			fetchSpendCategories,
			fetchDistinctSupplierPrivateNetwork,
		} = this.props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		if (queryParams.query) {
			updateSupplierFacilitySearchFilters({ query: queryParams.query });
		}
		fetchSpendCategories({});
		fetchDistinctSupplierPrivateNetwork({}).then((spns) => {
			this.setState({ privateNetwork: spns, privateNetworkFetching: false });
		});
	}

	componentWillUnmount() {
		const { clearSearchFilters } = this.props;
		clearSearchFilters();
	}

	debouncedCallback = debounce(
		(searchText) => {
			const { updateSupplierFacilitySearchFilters } = this.props;
			updateSupplierFacilitySearchFilters({ query: searchText && searchText.trim() });
		},
		400,
		false
	);

	handleSearchDebounced = (e) => {
		const searchText = e.target.value;
		this.debouncedCallback(searchText);
	};

	handleCategoryChange = (value) => {
		const { updateSupplierFacilitySearchFilters } = this.props;
		let formattedValue = value;
		if (formattedValue !== undefined) {
			formattedValue = formattedValue.map((v) => v.toLowerCase());
		}
		updateSupplierFacilitySearchFilters({ _tags: formattedValue });
	};

	handleSwitchChange = (checked, fieldName) => {
		const { updateSupplierFacilitySearchFilters } = this.props;
		if (checked) {
			updateSupplierFacilitySearchFilters({ [fieldName]: checked });
		} else {
			updateSupplierFacilitySearchFilters({ [fieldName]: undefined });
		}
	};

	handleSearchAgain = (bounds) => {
		const { updateSupplierFacilitySearchFilters } = this.props;
		updateSupplierFacilitySearchFilters({
			insideBoundingBox: [[bounds._sw.lat, bounds._sw.lng, bounds._ne.lat, bounds._ne.lng]],
		});
	};

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	handleSupplierPrivateNetworkCreateSubmit = () => {
		const { createSupplierPrivateNetworkBulk } = this.props;
		const form = this.formRefs['supplierPrivateNetworkCreateForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			this.setState({ creatingPrivateNetwork: true });

			const allSNs = getAllSupplierPrivateNetworkEntities(values);

			createSupplierPrivateNetworkBulk(allSNs)
				.then(() => {
					this.setState({ supplierPrivateNetworkCreateFormVisible: false });
					message.success(`Supplier added to your private network.`);
				})
				.finally(() => this.setState({ creatingPrivateNetwork: false }));
		});
	};

	handleSupplierPrivateNetworkCreateCancel = (e) => {
		this.setState({
			supplierPrivateNetworkCreateFormVisible: false,
		});
		this.formRefs.supplierPrivateNetworkCreateForm.props.form.resetFields();
	};

	showSupplierPrivateNetworkCreateModal = (record) => {
		this.setState({
			supplierPrivateNetworkCreateFormVisible: true,
			supplierFacilityId: record.id,
		});
	};

	render() {
		const {
			history,
			location,
			supplierFacilities,
			supplierFacilitiesFetching,
			spendCategories,
			userType,
		} = this.props;
		const fetching = supplierFacilitiesFetching;
		const visibleSuppliers = supplierFacilities.searchResults;
		const supplierRecordsWithGeoLoc = visibleSuppliers
			.map((el) => ({
				...el,
				longitude: el._geoloc ? el._geoloc.lng : undefined,
				latitude: el._geoloc ? el._geoloc.lat : undefined,
			}))
			.filter((el) => el.longitude !== undefined && el.latitude !== undefined);
		const currentPagination = supplierFacilities.searchPagination;
		const nationalSuppliers =
			currentPagination.current === 1 ? supplierFacilities.nationalSearchResults : [];
		const spendCategoriesRecordsList = getObjectValues(spendCategories.records).sort((a, b) =>
			a.name < b.name ? -1 : 1
		);
		const spendCategoriesRecordsMap = spendCategories.records;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);

		const columns = [
			{
				title: 'Name',
				dataIndex: 'displayName',
				render: (text, record) => (
					<Link to={`/${userType}/suppliers/detail/${record.id}`}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<div style={{ marginRight: 24 }}>
								{record.logoUrl ? (
									<FlexibleImageAvatar
										displayName={text}
										showPopover={false}
										popoverText={text}
										squareMode={true}
										width="120px"
										height="80px"
										imageUrl={record.logoUrl}
									/>
								) : (
									<FlexibleImageAvatar
										displayName={record.name}
										showPopover={false}
										popoverText={record.name}
										squareMode={true}
										width="120px"
										height="80px"
										imageUrl="https://s3.amazonaws.com/emptor-data-assets/images/empty-state/icons8-city-filled-100.png"
									/>
								)}
							</div>
							<div style={{ flex: 1 }}>
								<div
									style={{
										display: 'flex',
									}}
								>
									<div
										style={{
											fontSize: 18,
											wordBreak: 'break-word',
											marginRight: 8,
										}}
									>
										{text}
									</div>
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
									}}
								>
									<div>
										<div style={{ marginBottom: 0, fontSize: 14 }}>
											{/*<div>*/}
											{/*    <Rate*/}
											{/*        disabled={true}*/}
											{/*        allowHalf={true}*/}
											{/*        value={record.avgRatings}*/}
											{/*    />*/}
											{/*</div>*/}
											<div
												style={{
													wordBreak: 'normal',
													whiteSpace: 'normal',
													color: 'rgba(0,0,0,0.65)',
												}}
											>
												{record._tags
													.map((categoryName) => capitalizeEachWord(categoryName))
													.slice(0, 2)
													.join(', ')}
												{record._tags.length > 3 ? ' and more' : null}
											</div>
										</div>
										<AddressDisplay
											hidePostcode={true}
											hideCountry={true}
											hideStreetAddress={true}
											address={record.primaryAddress}
											style={{
												color: 'rgba(0,0,0,0.45)',
											}}
										/>
									</div>
									<div>
										<Button
											type="primary"
											disabled={this.state.privateNetwork
												.map((pn) => pn.supplierFacilityId)
												.includes(record.id)}
											onClick={(e) => {
												e.stopPropagation();
												e.preventDefault();
												this.showSupplierPrivateNetworkCreateModal(record);
											}}
										>
											Add to Network
										</Button>
									</div>
								</div>
							</div>
						</div>
					</Link>
				),
			},
		];
		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
				sm: { span: 12 },
				md: { span: 12 },
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 12 },
				md: { span: 12 },
			},
		};
		const settings = {
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: 5,
			slidesToScroll: 3,
			initialSlide: 0,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 2,
						infinite: true,
						dots: false,
					},
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		};

		return (
			<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_SUPPLIERS} isPage>
				<Content className="searchPage" style={{ position: 'relative', padding: '0 0.5em' }}>
					<LogOnMountWithStandardEventProperties eventType={`visited ${userType} wrenchhub page`} />
					{/*<ScrollToTopOnMount/>*/}
					{/*<BackTop/>*/}
					<SupplierPrivateNetworkCreateForm
						userType={userType}
						wrappedComponentRef={this.saveFormRef('supplierPrivateNetworkCreateForm')}
						visible={this.state.supplierPrivateNetworkCreateFormVisible}
						supplierFacilityId={this.state.supplierFacilityId}
						onCancel={this.handleSupplierPrivateNetworkCreateCancel}
						onSubmit={this.handleSupplierPrivateNetworkCreateSubmit}
						loading={this.state.creatingPrivateNetwork}
					/>
					{this.state.privateNetworkLoaded ? (
						<Spin />
					) : (
						<div
							style={{
								maxWidth: 1200,
								margin: 'auto',
							}}
						>
							<Row style={{ margin: '0.5em -8px' }} gutter={16}>
								<Col sm={24} md={16}>
									<Row>
										<Input.Search
											style={{
												height: 56,
												fontSize: 18,
												marginBottom: 0,
												padding: 0,
												margin: '0 0 0.5em',
											}}
											size="large"
											className="extraBigSearch"
											placeholder="Search for a service provider..."
											defaultValue={queryParams.query}
											onChange={this.handleSearchDebounced}
										/>

										<Card
											style={{ padding: 0, width: '100%' }}
											bodyStyle={{ padding: '16px 24px' }}
										>
											{nationalSuppliers.length > 0 ? (
												<Table
													columns={columns}
													showHeader={false}
													pagination={false}
													rowKey={(el) => el['id']}
													dataSource={nationalSuppliers}
												/>
											) : null}
											<PaginatedReduxSearchResultsTable
												collectionName="supplier_facilities"
												columns={columns}
												showHeader={false}
												keyAccessor={(el) => el.id}
												initialPagination={this.initialPagination}
												fetchData={supplierFacilitiesSearchThunks.search}
												emptyState={
													<EmptyState
														graphic={
															<img
																height="240px"
																width="auto"
																src="https://s3.amazonaws.com/emptor-data-assets/images/illustrations/fogg-no-messages-3.svg"
															/>
														}
														headline={'They must be in here somewhere...'}
														body={
															<div style={{ textAlign: 'center' }}>
																<div style={{ maxWidth: 440, marginBottom: 16 }}>
																	Your search didn't turn up any suppliers. Try changing your
																	filters or widening your search area.
																</div>
															</div>
														}
													/>
												}
											/>
										</Card>
									</Row>
								</Col>
								<Col sm={24} md={8}>
									<Row>
										<Col span={24}>
											<Card style={{ height: 400 }}>
												<MapboxGallery
													showNavControls={true}
													popupRenderFunc={(gle) => {
														let addressDisplayParts = [];
														if (gle.primaryAddress.streetAddress1) {
															addressDisplayParts.push(
																`<div>${gle.primaryAddress.streetAddress1}</div>`
															);
														}
														if (gle.primaryAddress.streetAddress2) {
															addressDisplayParts.push(
																`<div>${gle.primaryAddress.streetAddress2}</div>`
															);
														}
														addressDisplayParts.push(`<div>`);
														if (gle.primaryAddress.city) {
															addressDisplayParts.push(`<span>${gle.primaryAddress.city}</span>`);
														}
														if (gle.primaryAddress.region) {
															if (gle.primaryAddress.city) {
																addressDisplayParts.push(
																	`<span>, ${gle.primaryAddress.region}</span>`
																);
															} else {
																addressDisplayParts.push(
																	`<span>${gle.primaryAddress.region}</span>`
																);
															}
														}
														if (gle.primaryAddress.postcode) {
															addressDisplayParts.push(
																` <span>${gle.primaryAddress.postcode}</span>`
															);
														}
														addressDisplayParts.push(`</div>`);
														if (gle.primaryAddress.country) {
															addressDisplayParts.push(`<div>${gle.primaryAddress.country}</div>`);
														}
														const addressDisplay = addressDisplayParts.join('');
														const logoImage = gle.logoUrl
															? `<div style="margin-right: 16px; width: 64px; height: 64px; background: url(${gle.logoUrl}) center center / contain no-repeat white;"></div>`
															: `<div style="margin-right: 16px; width: 64px; height: 64px; background: url(https://s3.amazonaws.com/emptor-data-assets/images/empty-state/icons8-city-filled-100.png) center center / contain no-repeat white;"></div>`;
														return `
																									<div style="padding: 16px; display: flex; align-items: center;">
																											${logoImage}
																											<div>
																													<div style="font-weight: bold">
																															<a href="/${userType}/suppliers/detail/${gle.id}">${gle.displayName}</a>
																													</div>
																													<div style="color: rgba(0,0,0,0.65)">
																															${addressDisplay}
																													</div>
																											</div>
																									</div>
																									`;
													}}
													geolocatedEntities={supplierRecordsWithGeoLoc}
													baseLayerStyle="light"
													handleSearchAgain={this.handleSearchAgain}
												/>
											</Card>
										</Col>
									</Row>
									<Row style={{ margin: '0.5em 0' }}>
										<Col span={24}>
											<Collapse defaultActiveKey={['1']}>
												<Collapse.Panel header="Services Provided" key="1">
													<div style={{ padding: '0 8px' }}>
														<SidebarMultiselectFilter
															handleChange={this.handleCategoryChange}
															collectionName="supplier_facilities"
															facetName="_tags"
															filterKey="value"
															valueKey="value"
															displayKey="value"
															placeholderNoun="categories"
															uniqueKey="id"
															limit={10}
														/>
													</div>
												</Collapse.Panel>
											</Collapse>
										</Col>
									</Row>
									<Row style={{ margin: '0.5em -8px' }} gutter={16}>
										<Col span={24}>
											<Collapse defaultActiveKey={['2']}>
												<Collapse.Panel header="Diversity Status" key="2">
													<div style={{ padding: '0 8px' }}>
														<Form>
															<Form.Item {...formItemLayout} label="Minority owned">
																<Switch
																	onChange={(checked) =>
																		this.handleSwitchChange(checked, 'isMinorityOwned')
																	}
																/>
															</Form.Item>
															<Form.Item {...formItemLayout} label="Women owned">
																<Switch
																	onChange={(checked) =>
																		this.handleSwitchChange(checked, 'isWomenOwned')
																	}
																/>
															</Form.Item>
															{/*<Form.Item*/}
															{/*{...formItemLayout}*/}
															{/*label="LGBT owned"*/}
															{/*>*/}
															{/*<Switch*/}
															{/*onChange={(checked) => this.handleSwitchChange(checked, 'isLgbtOwned')}*/}
															{/*/>*/}
															{/*</Form.Item>*/}
															<Form.Item {...formItemLayout} label="Veteran owned">
																<Switch
																	onChange={(checked) =>
																		this.handleSwitchChange(checked, 'isVeteranOwned')
																	}
																/>
															</Form.Item>
															{/*<Form.Item*/}
															{/*{...formItemLayout}*/}
															{/*label="Disabled owned"*/}
															{/*>*/}
															{/*<Switch*/}
															{/*onChange={(checked) => this.handleSwitchChange(checked, 'isDisabledOwned')}*/}
															{/*/>*/}
															{/*</Form.Item>*/}
															<Form.Item {...formItemLayout} label="Small business">
																<Switch
																	onChange={(checked) =>
																		this.handleSwitchChange(checked, 'isSmallBusinessEnterprise')
																	}
																/>
															</Form.Item>
														</Form>
													</div>
												</Collapse.Panel>
											</Collapse>
										</Col>
									</Row>
								</Col>
							</Row>
						</div>
					)}
				</Content>
			</AccessPermissionChecker>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	supplierFacilities: state.supplier_facilities,
	supplierFacilitiesFetching: state.supplier_facilities.searching,
	spendCategories: state.spend_categories,
	userType: (state as any).session.userType,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchSpendCategories: (params) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? spendCategoriesRestCrudThunksForSupplier.read(params)
				: spendCategoriesRestCrudThunksForBuyer.read(params)
		),
	clearSearchFilters: () =>
		dispatch(SUPPLIER_FACILITIES_SEARCH_ACTION_CREATORS.clearAllSearchFilters()),
	createSupplierPrivateNetwork: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? supplierPrivateNetworksRestCrudThunksForSupplier.create(entity)
				: supplierPrivateNetworksRestCrudThunksForBuyer.create(entity)
		),
	createSupplierPrivateNetworkBulk: (entities) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? createSupplierPrivateNetworkBulkForSupplier(entities)
				: createSupplierPrivateNetworkBulkForBuyer(entities)
		),
	updateSupplierFacilitySearchFilters: (searchFilters) =>
		dispatch(SUPPLIER_FACILITIES_SEARCH_ACTION_CREATORS.updateSearchFilters(searchFilters)),
	searchSupplierFacilities: (params, pagination, sorting, filters) =>
		dispatch(supplierFacilitiesSearchThunks.search(params, pagination, sorting, filters)),
	searchSupplierFacilitiesFacets: (facetName, params, pagination, sorting, filters) =>
		dispatch(
			supplierFacilitiesSearchThunks.searchFacet(facetName, params, pagination, sorting, filters)
		),
	fetchDistinctSupplierPrivateNetwork: (params) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? supplierPrivateNetworksGetDistinctForSupplier(params, 'supplierPrivateNetworksIndex', {})
				: supplierPrivateNetworksGetDistinctForBuyer(params, 'supplierPrivateNetworksIndex', {})
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(BuyerWrenchHubPage)
);

export default connect(
	(state, ownProps) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

export const IS_STALE_TABS = {
	selector: 'isStale',
	items: [
		{
			selectVal: undefined,
			label: 'All',
			value: { isStale: undefined },
		},
		{
			selectVal: 'false',
			label: 'Fresh',
			value: { isStale: 'false' },
		},
		{
			selectVal: 'true',
			label: 'Stale',
			value: { isStale: 'true' },
		},
	],
};

export const IS_PLANNED_TABS = {
	selector: 'isPM',
	items: [
		{
			selectVal: undefined,
			label: 'All',
			value: { isPM: undefined },
		},
		{
			selectVal: 'true',
			label: 'Planned',
			value: { isPM: 'true' },
		},
		{
			selectVal: 'false',
			label: 'Reactive',
			value: { isPM: 'false' },
		},
	],
};

export const IS_CAPEX_TABS = {
	selector: 'isCapex',
	items: [
		{
			selectVal: undefined,
			label: 'All',
			value: { isCapex: undefined },
		},
		{
			selectVal: 'false',
			label: 'R&M',
			value: { isCapex: 'false' },
		},
		{
			selectVal: 'true',
			label: 'CapEx',
			value: { isCapex: 'true' },
		},
	],
};

//TabFilter Collections
export const WORK_ORDER_TAB_FILTERS = [IS_STALE_TABS, IS_PLANNED_TABS];

export const ACTIVE_WORK_ORDER_TAB_FILTERS = [IS_STALE_TABS, IS_PLANNED_TABS];
export const ARCHIVED_WORK_ORDER_TAB_FILTERS = [IS_PLANNED_TABS];
export const ALL_WORK_ORDER_TAB_FILTERS = [IS_PLANNED_TABS];

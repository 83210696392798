import { Button, Form, Input, Modal, message } from 'antd';
import React, { FC, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import {
	regionsRestCrudThunksForBuyer,
	regionsRestCrudThunksForSupplier,
} from '../../thunks/regions_thunks';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { ROLE_TYPES } from '../../utils/DataConstants';

const FORM_ID = 'edit-region-form';

const EditRegionPopup: FC<any> = ({
	onCancel,
	onSuccess,
	updateRegion,
	region,
}): React.ReactElement => {
	const [saving, setSaving] = useState(false);

	const handleSubmit = useCallback(
		(values) => {
			setSaving(true);
			updateRegion({
				...region,
				name: values.name,
			})
				.then(() => onSuccess())
				.catch((err) => message.error(err))
				.finally(() => setSaving(false));
		},
		[region, updateRegion, onSuccess]
	);

	return (
		<Modal
			visible={true}
			title={'Edit Region'}
			onCancel={onCancel}
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button
					type="primary"
					size="large"
					style={{ marginLeft: '16px' }}
					key="submit"
					htmlType="submit"
					form={FORM_ID}
					loading={saving}
				>
					Save
				</Button>,
			]}
			closable={true}
		>
			<Form id={FORM_ID} layout="vertical" requiredMark={false} onFinish={handleSubmit}>
				<Form.Item
					name="name"
					label="Name"
					initialValue={nullSafeGet('name', region)}
					rules={[{ required: true, message: 'Please enter a name for the region!' }]}
				>
					<Input type="text" placeholder="Enter Name of this region" />
				</Form.Item>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	updateRegion: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? regionsRestCrudThunksForSupplier.update(entity)
				: regionsRestCrudThunksForBuyer.update(entity)
		),
});

const ComponentWithoutUserType = connect(mapStateToProps, mapDispatchToProps)(EditRegionPopup);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

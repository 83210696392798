import React, { useMemo, useState } from 'react';
import { Upload } from 'antd';
import FetchUtils, { parseJSON } from '../../thunks/fetch_utils';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import { connect } from 'react-redux';

const fetchUtils = new FetchUtils();

const OWUpload = (props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const { uploaderType } = props;
	const UploadComponent = useMemo(() => {
		if (uploaderType === 'dragger') {
			return Upload.Dragger;
		}
		return Upload;
	}, [uploaderType]);
	return (
		<div>
			<UploadComponent
				{...props}
				disabled={loading}
				customRequest={({ file, onError, onSuccess, onProgress }) => {
					const fileType = file.type;
					const fileName = file.name;

					setLoading(true);
					const preSignedURLEndpoint = `${getBackendUri()}/api/v1/${
						props.userType
					}/file/upload/get_presigned_url`;
					fetchUtils
						.post(`${preSignedURLEndpoint}`, {
							data: {
								fileType,
								fileName,
							},
						})
						.then(parseJSON)
						.then(({ json }) => {
							const returnData = json.data;
							const signedRequest = returnData.presignedUrl;

							fetch(signedRequest, {
								method: 'PUT',
								headers: {
									'Content-Type': fileType,
								},
								body: file,
							})
								.then(parseJSON)
								.then((result) => {
									const onSuccessPayload = {
										data: returnData,
									};

									onSuccess(onSuccessPayload);
								})
								.catch((error) => {
									onError(error);
									alert('ERROR ' + JSON.stringify(error));
								});
						})
						.catch((error) => {
							setLoading(false);
							alert(JSON.stringify(error));
						})
						.finally(() => {
							setLoading(false);
						});
				}}
			/>
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		userType: state.session.userType,
	};
};

export default connect(mapStateToProps)(OWUpload);

import * as React from 'react';
import { Layout, Button, List } from 'antd';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';
import SubnavTabs from '../subnav_tabs/SubnavTabs';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { siteSurveyTemplatesRestCrudThunksForSupplier } from '../../thunks/site_survey_templates_thunks';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';

const { Content } = Layout;

require('../site_survey_templates_detail_page/SiteSurveyTemplatesDetailPage.less');

class SupplierSiteSurveyTemplatesDetailPage extends React.Component<any, any> {
	componentDidMount() {
		const { getSiteSurveyTemplate, match } = this.props;
		const siteSurveyTemplateId = match.params.id;
		getSiteSurveyTemplate(siteSurveyTemplateId);
	}

	handleTabChange = (key) => {
		const { history, match } = this.props;
		const siteSurveyTemplateId = match.params.id;
		history.push(`/supplier/siteSurveys/templates/detail/${siteSurveyTemplateId}/${key}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	handleDelete = () => {
		const { history, deleteSiteSurveyTemplate, siteSurveyTemplate } = this.props;
		deleteSiteSurveyTemplate(siteSurveyTemplate).then(() => {
			history.push('/supplier/siteSurveys/overview/templates/overview');
		});
	};

	render() {
		const { siteSurveyTemplate, siteSurveyTemplatesFetching, companyConfig, match } = this.props;
		const siteSurveyTemplateId = match.params.id;
		const tabs = [
			{
				key: 'details',
				name: 'Overview',
			},
		];
		return (
			<div>
				<Route
					path="/supplier/siteSurveys/templates/detail/:id"
					exact={true}
					render={(props) => (
						<Redirect
							to={{
								pathname: `/supplier/siteSurveys/templates/detail/${siteSurveyTemplateId}/details`,
							}}
						/>
					)}
				/>
				<Content className="siteSurveyTemplatesDetailPage" style={{ padding: '0 0.5em' }}>
					{/*<ScrollToTopOnMount/>*/}
					{/*<BackTop/>*/}

					<div
						style={{
							margin: '0 -8px',
							padding: '24px',
							paddingBottom: 0,
							background: nullSafeGet('detail.config.theme.navblock', companyConfig),
							marginBottom: 8,
						}}
					>
						<h4
							style={{
								fontSize: 32,
								minHeight: 41,
								color: 'rgba(255, 255, 255, 0.9)',
								marginRight: 8,
								marginBottom: 24,
							}}
						>
							{siteSurveyTemplatesFetching ? null : siteSurveyTemplate.title}
						</h4>
						<SubnavTabs
							tabs={tabs}
							onChange={this.handleTabChange}
							isActive={this.checkTabActive}
						/>
						<div style={{ position: 'absolute', top: 68, right: 64 }}>
							<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_SITE_SURVEY_TEMPLATES}>
								<Button ghost={true} onClick={this.handleDelete}>
									Delete
								</Button>
							</AccessPermissionChecker>
						</div>
					</div>
					{this.props.childRoutes.map((r, i) => (
						<RouteWithSubRoutes key={i} {...r} />
					))}
				</Content>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	siteSurveyTemplatesFetching: state.site_survey_templates.fetching,
	siteSurveyTemplate: state.site_survey_templates.detail,
	companyConfig: state.company_config,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	getSiteSurveyTemplate: (id) => dispatch(siteSurveyTemplatesRestCrudThunksForSupplier.readOne(id)),
	deleteSiteSurveyTemplate: (entity) =>
		dispatch(siteSurveyTemplatesRestCrudThunksForSupplier.delete(entity, 'id', null, true)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(SupplierSiteSurveyTemplatesDetailPage)
);

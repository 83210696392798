import React from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { vendorsRestCrudThunksForSupplier } from '../../thunks/vendors_thunks';
import { Link } from 'react-router-dom';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import { getBackLinkStates } from '../../utils/HistoryUtils';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';

const DEFAULT_BACK_LINK = 'Back to vendors';

class VendorsDetailPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			lightboxIsOpen: false,
			currentIndex: 0,
			backLinkTo: '/supplier/vendors',
			backLinkText: DEFAULT_BACK_LINK,
		};
	}

	goToPrevious = () => {
		this.setState({ currentIndex: this.state.currentIndex - 1 });
	};

	goToNext = () => {
		this.setState({ currentIndex: this.state.currentIndex + 1 });
	};

	componentDidMount() {
		const { getVendor, match, location } = this.props;
		const vendorId = match.params.id;
		getVendor(vendorId);
		this.setState(getBackLinkStates(location, DEFAULT_BACK_LINK));
	}

	handleTabChange = (key) => {
		const { history, match } = this.props;
		const vendorId = match.params.id;
		history.push(`/supplier/vendors/detail/${vendorId}/${key}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	handleDelete = () => {
		const { history, deleteVendor, vendor } = this.props;
		deleteVendor(vendor).then(() => {
			history.push('/supplier/vendors/overview/all');
		});
	};

	render() {
		const { vendor, vendorsFetching, match, companyConfig } = this.props;
		const vendorId = match.params.id;
		const tabs = [
			{
				key: 'details',
				name: 'Overview',
			},
		];
		return [
			<DetailPageHeader
				exactPath={'/supplier/vendors/detail/:id'}
				redirectPath={`/supplier/vendors/detail/${vendorId}/details`}
				backLinkText={this.state.backLinkText}
				backLinkTo={this.state.backLinkTo}
				title={
					vendorsFetching ? null : (
						<div>
							<div>{vendor.name}</div>
							{!vendor.isActive ? <div className="text-sm">Inactive</div> : null}
						</div>
					)
				}
				subtitle={null}
				actions={[
					<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_VENDORS}>
						<Link to={`/supplier/vendors/edit/${vendorId}`} key={1} style={{ marginRight: 16 }}>
							<Button ghost={true}>Edit</Button>
						</Link>
					</AccessPermissionChecker>,
				]}
				tabs={tabs}
				backgroundColor={nullSafeGet('detail.config.theme.navblock', companyConfig)}
				handleTabChange={this.handleTabChange}
				checkTabActive={this.checkTabActive}
				childRoutes={this.props.childRoutes}
			/>,
		];
	}
}

const mapStateToProps = (state, ownProps) => ({
	vendorsFetching: state.vendors.fetching,
	vendor: state.vendors.detail,
	match: ownProps.match,
	history: ownProps.history,
	companyConfig: state.company_config,
});

const mapDispatchToProps = (dispatch) => ({
	getVendor: (id) => dispatch(vendorsRestCrudThunksForSupplier.readOne(id)),
	deleteVendor: (entity) =>
		dispatch(vendorsRestCrudThunksForSupplier.delete(entity, 'id', null, true)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VendorsDetailPage));

import { DOWNTIME_ANALYTICS_CRUD_ACTION_CREATORS } from '../actions/downtime_analytics_actions';
import { ROLE_TYPES } from '../utils/DataConstants';
import RestCrudThunks from './restCrudThunksGenerator';

export const downtimeAnalyticsRestCrudThunksForBuyer = new RestCrudThunks(
	'analytics_overview',
	'asset',
	'asset',
	'assets',
	DOWNTIME_ANALYTICS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'asset/asset_downtimes/'
);
export const downtimeAnalyticsRestCrudThunksForSupplier = new RestCrudThunks(
	'analytics_overview',
	'asset',
	'asset',
	'assets',
	DOWNTIME_ANALYTICS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'asset/asset_downtimes/'
);

// /asset/asset_downtimes/analytics_overview

import { Form, Select } from 'antd';
import React, { useState } from 'react';
import {
	checkAndFormatPhoneNumber,
	formatPhoneNumber,
	isValidPhoneNumber,
} from '../../utils/DataFormatterUtils';

const AsYouTypeFormatter = require('google-libphonenumber').AsYouTypeFormatter;

const returnInvalidPhoneNumber = (phoneNumber) => {
	try {
		return isValidPhoneNumber(phoneNumber) ? null : phoneNumber;
	} catch (error) {
		console.warn('Validation error:', error.message);
		return phoneNumber;
	}
};
export const phoneNumberValidator = (rule, values, callback) => {
	const seenNumbers = new Set();
	const invalidNumbers = [];
	const duplicateNumbers = [];

	(values || []).forEach((value) => {
		if (seenNumbers.has(value)) {
			duplicateNumbers.push(value);
		} else {
			seenNumbers.add(value);
			const validationResult = returnInvalidPhoneNumber(value);
			if (validationResult) invalidNumbers.push(validationResult);
		}
	});

	const errorMessage = [
		...(invalidNumbers.length > 0
			? [
					invalidNumbers.join(', ') +
						(invalidNumbers.length > 1
							? ' are not valid phone numbers'
							: ' is not a valid phone number'),
			  ]
			: []),
		...(duplicateNumbers.length > 0
			? [
					duplicateNumbers.join(', ') +
						(duplicateNumbers.length > 1 ? ' are duplicate numbers' : ' is a duplicate number'),
			  ]
			: []),
	].join('. ');

	errorMessage ? callback(errorMessage) : callback();
};

const getEntryTypeProps = (type) => {
	return {
		fieldProps: {
			label: 'Phone Numbers',
			tooltip: 'You can enter multiple phone numbers separated by comma.',
			validator: phoneNumberValidator,
			normalize: (e) => {
				const filteredArray = e.map((item, index) => {
					if (item) {
						return checkAndFormatPhoneNumber(item);
					}
				});
				return filteredArray.filter((item) => item);
			},
		},
		props: {
			tokenSeparators: [',', ';', ':'],
			placeholder: 'Enter phone numbers',
		},
	};
};

const defaultProps = {
	optionFilterProp: 'label',
	mode: 'tags',
	open: false,
	allowClear: true,
};

export default function MultiPhoneNumberComponent({ type, fieldProps, ...props }) {
	const entryData = getEntryTypeProps(type);
	const formProps = { ...entryData.fieldProps, ...fieldProps };
	const selectProps = { ...defaultProps, ...entryData.props, ...props };

	const [searchValue, setSearchValue] = useState('');

	return (
		<Form.Item
			preserve={false}
			{...formProps}
			rules={[...(fieldProps.rules || []), { validator: formProps.validator }]}
		>
			<Select
				{...selectProps}
				mode="tags"
				searchValue={searchValue}
				onSearch={(value) => {
					const formatter = new AsYouTypeFormatter('US');
					const phoneString = value.replace(/[^0-9+]/g, '').split('');
					phoneString.map((c) => formatter.inputDigit(c));
					setSearchValue(formatter.currentOutput_.toString());
				}}
				onSelect={(value) => {
					setSearchValue('');
				}}
			/>
		</Form.Item>
	);
}

import { PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/purchase_request_line_items_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = standardInitialStateGenerator([
	'partPRLineItemsIndex',
	'equipmentPRLineItemsIndex',
	'purchaseRequestLineItemsIndex',
	'partsPRLineItemsIndes',
	'equipmentsPRLineItemsIndex',
	'readyToOrderIndexPage',
]);
export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState);

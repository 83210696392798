import { Button, Row, Select, Switch } from 'antd';
import { ContactAvatar } from '../contact_avatar/ContactAvatar';
import * as React from 'react';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { getObjectValues, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { Form } from '@ant-design/compatible';
import _ from 'lodash';

const FormItem = Form.Item;

const { Option } = Select;

export default class TableSelectComponent extends React.Component<any, any> {
	constructor(props) {
		super(props);

		let column = this.props.columns.find((column) => column.dataIndex === this.props.dataIndex);
		this.state = {
			records: props.list,
			column: column,
			selectType: nullSafeGetOrElse('props.update.type', column, 'single'),
			stateSlice: column.props.update.stateSlice,
			targetCollectionName: nullSafeGet('props.update.targetCollectionName', props),
			columns: this.props.columns,
			values: nullSafeGetOrElse('props.update.type', column, 'single') === 'single' ? '' : [],
			optionList: [],
			buttonLoading: false,
			selectAll: false,
		};
	}

	componentWillReceiveProps(nextProps) {
		if (!_.isEqual(nextProps.columns, this.props.columns)) {
			let column = nextProps.columns.find((column) => column.dataIndex === this.props.dataIndex);
			this.setState({
				columns: nextProps.columns,
				column: column,
				stateSlice: column.props.update.stateSlice,
			});
		}
	}

	componentDidMount() {
		const { records, column } = this.state;
		const { methodType } = this.props;
		let vals = getObjectValues(records).map((record) => nullSafeGet(column.dataIndex, record));
		let initialVal = vals[0];
		if (methodType === 'update' || methodType === 'add') {
			if (initialVal) {
				if (Array.isArray(initialVal)) {
					let commonVals = [
						...initialVal.filter((val) => vals.every((array) => array.includes(val))),
					];
					this.setState({ values: commonVals });
				} else {
					if (vals.every((val) => val === initialVal)) {
						this.setState({ value: [initialVal] });
					} else {
						this.setState({ value: [] });
					}
				}
			}
		}
		if (methodType === 'remove' || methodType === 'add') {
			this.setState({ values: [] });
		} else if (vals.filter((obj) => obj === initialVal).length === vals.length) {
			if (initialVal) {
				if (typeof initialVal === 'string') {
					this.setState({ values: [initialVal] });
				} else {
					this.setState({ values: [...initialVal] });
				}
			} else {
				this.setState({ values: [] });
			}
		}
	}

	handleSubmit() {}
	onChange = () => {};

	onSelectChange(value) {
		let values;
		if (this.state.selectType === 'single') {
			values = value;
		} else {
			values = value;
		}
		this.setState({ values: values });
	}
	render() {
		const { columnTitle, dataIndex } = this.props;
		const { records, columns, buttonLoading } = this.state;
		let column = columns.find((column) => column.dataIndex === dataIndex);

		let self = this;

		let fetchDataMethod = nullSafeGet('props.update.fetchData', column);
		let fetchMultipleMethod = nullSafeGet('props.update.fetchMultiple', column);
		const allowSelectAll = columnTitle === 'Remove CCs';

		return (
			<div>
				<Form>
					{allowSelectAll ? (
						<FormItem label={'Clear All Users'}>
							<Switch
								checked={this.state.selectAll}
								onChange={(checked) => this.setState({ selectAll: checked })}
							/>
						</FormItem>
					) : null}
					<FormItem label={column.title}>
						<OWAsyncSelect
							style={{ width: '390px' }}
							mode={nullSafeGetOrElse('props.update.type', column, 'single')}
							stateSlice={this.state.stateSlice}
							valueAccessor={(el) => el.email}
							value={this.state.values}
							allowClear={true}
							disabled={this.state.selectAll}
							onChange={this.onSelectChange.bind(this)}
							targetCollectionName={nullSafeGet('props.update.targetCollectionName', column)}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchDataMethod(
									{ search: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							fetchMultiple={() => {}}
							renderRecord={(ft) => (
								<Select.Option key={ft.email} value={ft.email}>
									{nullSafeGet('name', ft)} {nullSafeGet('nameGiven', ft)}{' '}
									{nullSafeGet('nameFamily', ft)} ({nullSafeGet('email', ft)})
								</Select.Option>
							)}
						/>
					</FormItem>
				</Form>

				<Row style={{ justifyContent: 'flex-end' }}>
					<Button
						type={'primary'}
						loading={buttonLoading}
						onClick={() => {
							const emails = Object.keys(nullSafeGetOrElse('state.stateSlice.records', this, {}));
							const values = this.state.selectAll ? emails : this.state.values;
							column.props.update
								.updateMethod(this.props.methodType)
								.bind(this, column.dataIndex, values, records, self)
								.apply();
							this.setState({ buttonLoading: true });
						}}
					>
						Update {nullSafeGetOrElse('length', records, ' 0 ')} Records
					</Button>
				</Row>
			</div>
		);
	}
}

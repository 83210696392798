import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import AssetDetailPageDisplay from '../asset_detail_page_display/AssetDetailPageDisplay';
import { FC } from 'react';
import { getEntityById } from '../../utils/DataAccessUtils';

require('./AssetsDetailDetailsPage.less');

const AssetsDetailsDetailPage: FC<any> = ({ asset, assetsFetching }): React.ReactElement => {
	return <AssetDetailPageDisplay asset={asset} loading={!asset.id && assetsFetching} />;
};

const mapStateToProps = (state, ownProps) => ({
	assetsFetching: state.assets.fetching,
	asset: getEntityById(ownProps.match.params.id, state.assets),
	match: ownProps.match,
	userType: state.session.userType,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(AssetsDetailsDetailPage)
);

import * as React from 'react';
import { Layout, Row, Col, Card, Steps } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import BidderList from '../bidder_list/BidderList';
import FileUploader from '../file_uploader/FileUploader';
import { requestsForProposalRestCrudThunksForBuyer } from '../../thunks/requests_for_proposal_thunks';

import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { EmptyState } from '../empty_state/EmptyState';
import { biddersRestCrudThunksForBuyer } from '../../thunks/bidders_thunks';
import { WorkOrderRowDisplay } from '../work_order_row_display/WorkOrderRowDisplay';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;
const Step = Steps.Step;
const style = require('./RFPDetailDetailsPage.less');

const dateFormat = 'MM-DD-YYYY';
const RFP_DETAIL_BIDDERS = 'RFP_DETAIL_BIDDERS';

class RFPDetailDetailsPage extends React.Component<any, any> {
	componentDidMount() {
		const {
			getRequestForProposal,
			getBidders,

			match,
		} = this.props;
		const requestForProposalId = match.params.id;
		getRequestForProposal(requestForProposalId);
		getBidders({ requestForProposalId }, RFP_DETAIL_BIDDERS);
	}

	handleFileAttachmentUploadChange = (newAttachments) => {
		const { requestForProposal, updateRequestForProposal } = this.props;
		const newRequestForProposal = {
			...requestForProposal,
			attachments: newAttachments,
		};
		updateRequestForProposal(newRequestForProposal);
	};

	handleTabChange = (key) => {
		const { history, match } = this.props;
		const requestForProposalId = parseInt(match.params.id, 10);
		history.push(`/buyer/requestsForProposal/detail/${requestForProposalId}/${key}`);
	};

	render() {
		const {
			requestForProposal,
			requestsForProposalFetching,
			bidders,
			biddersFetching,
			currentUser,
			history,
			match,
		} = this.props;
		const fetching = [requestsForProposalFetching, biddersFetching].some(
			(fetchingStatus) => fetchingStatus === true
		);
		const requestForProposalId = parseInt(match.params.id, 10);
		const associatedBidders = getRecordsForTargetCollection(bidders, RFP_DETAIL_BIDDERS);

		const stepStatusMapper = {
			draft: 0,
			active: 1,
			closed: 2,
			award: 3,
		};

		const rfpCurrentStep =
			requestsForProposalFetching || !requestForProposal.status
				? undefined
				: stepStatusMapper[requestForProposal.status];

		return fetching ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="rfpDetailDetailsPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited buyer rfp detail page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}

				<Row style={{ margin: '-0.5em -8px 0.5em -8px' }} gutter={16}>
					<Col span={16}>
						{requestForProposal.description && requestForProposal.description !== '<p><br></p>' ? (
							<Row style={{ margin: '0.5em -8px' }} gutter={16}>
								<Col span={24}>
									<Card title="About the RFP">
										<div
											style={{ fontSize: 16, marginBottom: 24 }}
											className="richTextWrapper"
											dangerouslySetInnerHTML={{ __html: requestForProposal.description }}
										/>
									</Card>
								</Col>
							</Row>
						) : null}
						<Row style={{ margin: '0.5em -8px' }} gutter={16}>
							<Col span={24}>
								<div
									onClick={() =>
										history.push(`/buyer/workOrders/detail/${requestForProposal.workOrder.id}`)
									}
								>
									<Card title="About the Problem">
										<WorkOrderRowDisplay workOrder={requestForProposal.workOrder} />
									</Card>
								</div>
							</Col>
						</Row>
						<Row style={{ margin: '0.5em -8px' }} gutter={16}>
							<Col span={24}>
								<Card title="Bidders">
									{biddersFetching || associatedBidders.length === 0 ? (
										<EmptyState
											graphic={
												<img
													style={{ marginBottom: 8 }}
													src="https://s3.amazonaws.com/mock-data-assets/categories/images/Lamp.svg"
												/>
											}
											headline={"It's a little lonely in here."}
											body={
												<div style={{ textAlign: 'center', maxWidth: 480 }}>
													This RFP isn't going to bid on itself. How about inviting some service
													providers from your network to quote?
												</div>
											}
										/>
									) : (
										<BidderList
											bidders={associatedBidders}
											requestForProposal={requestForProposal}
										/>
									)}
								</Card>
							</Col>
						</Row>
					</Col>

					<Col span={8}>
						{requestsForProposalFetching ? null : (
							<Row style={{ margin: '0.5em -8px' }} gutter={16}>
								<Col span={24}>
									<Card title="File Attachments">
										<FileUploader
											roleType="buyer"
											defaultFileList={requestForProposal.attachments || []}
											handleUploadSuccess={this.handleFileAttachmentUploadChange}
											uploaderType={'dragger'}
										/>
									</Card>
								</Col>
							</Row>
						)}
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	requestsForProposalFetching: state.requests_for_proposal.fetching,
	requestForProposal: state.requests_for_proposal.detail,
	bidders: state.bidders,
	biddersFetching: state.bidders.fetching,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	updateRequestForProposal: (entity) =>
		dispatch(requestsForProposalRestCrudThunksForBuyer.update(entity)),
	getRequestForProposal: (id) => dispatch(requestsForProposalRestCrudThunksForBuyer.readOne(id)),
	getBidders: (params, targetCollectionName, pagination, sorting, filters) =>
		dispatch(
			biddersRestCrudThunksForBuyer.read(params, targetCollectionName, pagination, sorting, filters)
		),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RFPDetailDetailsPage));

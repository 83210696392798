import {
	PLANNED_MAINTENANCE_SCHEDULE_GROUPS_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS,
	PLANNED_MAINTENANCE_SEARCH_ACTION_CREATORS,
} from '../actions/planned_maintenance_schedule_groups_actions';
import {
	compositeInitialStateGenerator,
	compositeReducerGenerator,
	standardSearchReducerGenerator,
} from './standard_reducer_utils';

const initialState: any = Object.assign(
	compositeInitialStateGenerator(
		['plannedMaintenanceScheduleGroupsIndex'],
		['title', 'frequency', 'plannedMaintenanceTemplateId']
	)
);

const plannedMaintenanceSearchReducer = standardSearchReducerGenerator(
	PLANNED_MAINTENANCE_SEARCH_ACTION_CREATORS
);

export const customReducer = (oldState = initialState, action) => {
	Object.freeze(oldState);
	let newState;
	switch (action.type) {
		case CRUD_ACTION_CONSTANTS.updateFilters:
			newState = {
				...oldState,
			};
			if (action.targetCollectionName) {
				newState[action.targetCollectionName] = {
					...newState[action.targetCollectionName],
					pagination: {
						...newState[action.targetCollectionName].pagination,
						current: 1,
					},
					filters: {
						...newState[action.targetCollectionName].filters,
						...action.filters,
					},
				};
			} else {
				newState.filters = {
					...newState.filters,
					...action.filters,
				};
				newState.pagination = {
					...newState.pagination,
					...action.pagination,
					current: 1,
				};
			}
			return newState;
		case CRUD_ACTION_CONSTANTS.clearAllFilters:
			newState = {
				...oldState,
			};
			if (action.targetCollectionName) {
				newState[action.targetCollectionName] = {
					...newState[action.targetCollectionName],
					pagination: {
						...newState[action.targetCollectionName].pagination,
						current: 1,
					},
					filters: {},
				};
			} else {
				newState.filters = {};
				newState.pagination = {
					...newState.pagination,
					current: 1,
				};
			}
			return newState;
		case CRUD_ACTION_CONSTANTS.clearAndUpdateFilters:
			newState = {
				...oldState,
			};
			if (action.targetCollectionName) {
				newState[action.targetCollectionName] = {
					...newState[action.targetCollectionName],
					pagination: {
						...action.pagination,
						current: 1,
					},
					filters: {
						...action.filters,
					},
				};
			} else {
				newState.filters = {
					...action.filters,
				};
				newState.pagination = {
					...newState.pagination,
					current: 1,
				};
			}
			return newState;
		default:
			return plannedMaintenanceSearchReducer(oldState, action);
	}
};
export default compositeReducerGenerator(
	CRUD_ACTION_CONSTANTS,
	initialState,
	['title', 'frequency', 'plannedMaintenanceTemplateId'],
	customReducer
);

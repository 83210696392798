import * as React from 'react';
import { Layout, Row, Col, Card, Tooltip, Button } from 'antd';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import {
	getProtectedImageUriForBuyer,
	getProtectedImageUriForSupplier,
} from '../../utils/FileAccessUtils';
import { isTextElementEmpty, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import Mapbox from '../mapbox/Mapbox';
import AssetCardDisplay from '../asset_card_display/AssetCardDisplay';
import { WorkOrderRowDisplay } from '../work_order_row_display/WorkOrderRowDisplay';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { EmptyState } from '../empty_state/EmptyState';
import {
	workOrdersRestCrudThunksForBuyer,
	workOrdersRestCrudThunksForSupplier,
} from '../../thunks/work_orders_thunks';
import { AssetRowDisplay } from '../asset_row_display/AssetRowDisplay';
import {
	assetsRestCrudThunksForBuyer,
	assetsRestCrudThunksForSupplier,
} from '../../thunks/assets_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';
import FileUploader from '../file_uploader/FileUploader';
import { DrawerContactCardDisplay } from '../drawer_contact_card_display/DrawerContactCardDisplay';
import { LocationsDrawerOperatingHoursCardDisplay } from '../locations_drawer_operating_hours_card_display/LocationsDrawerOperatingHoursCardDisplay';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import { connect } from 'react-redux';
import posthog from 'posthog-js';
import LocationDetailsCard from '../locations_detail_details_page/LocationDetailsCard';

const { Content } = Layout;

require('./LocationPageDisplay.less');

const LOCATION_ASSOCIATED_ACTIVE_WORK_ORDERS_TARGET_COLLECTION =
	'locationAssociatedActiveWorkOrders';
const LOCATION_ASSOCIATED_INACTIVE_WORK_ORDERS_TARGET_COLLECTION =
	'locationAssociatedInActiveWorkOrders';
const LOCATION_ASSOCIATED_ASSETS_TARGET_COLLECTION = 'locationAssociatedAssets';

class LocationDetailPage extends React.Component<any, any> {
	render() {
		const { record, locationsFetching, history, userType } = this.props;
		const locationId = record.id;
		let photoUris = [];

		const isSupplier = userType === ROLE_TYPES.SUPPLIER;

		if (record.images) {
			photoUris = record.images.map((locImg) => {
				const [imgFileId, imgFileName] = locImg.split('/');
				return isSupplier
					? getProtectedImageUriForSupplier(imgFileId, imgFileName, 1200, 'auto')
					: getProtectedImageUriForBuyer(imgFileId, imgFileName, 1200, 'auto');
			});
		}
		const workOrderColumns = [
			{
				title: 'Name',
				dataIndex: 'title',
				render: (text, record) => <WorkOrderRowDisplay workOrder={record} />,
			},
		];
		const assetsColumns = [
			{
				title: 'Name',
				dataIndex: 'name',
				render: (text, record) => <AssetRowDisplay asset={record} />,
			},
		];

		const onWorkOrderRow = (record) => ({
			onClick: () => history.push(`/${userType}/workOrders/detail/${record.id}`),
		});

		const onAssetRow = (record) => ({
			onClick: () => history.push(`/${userType}/assets/detail/${record.id}`),
		});

		const facilityInChargeContacts = nullSafeGetOrElse('facilityInChargeContacts', record, []);
		const primaryContactsContacts = nullSafeGetOrElse('primaryContactsContacts', record, []);

		const skipCount = posthog.isFeatureEnabled('OptimiseWorkOrderAPIforLargeData');

		return locationsFetching ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="locationPageDisplay">
				<div
					className={
						(photoUris.length && photoUris.length > 0) || !record.isActive
							? 'imageLayout'
							: 'noImageLayout'
					}
				>
					{!record.isActive ? (
						<div className="inactiveBanner">
							<div className="inactiveLabel">Inactive</div>
						</div>
					) : null}
					{photoUris.map((photoUri, idx) => (
						<div
							key={idx}
							className={`imageTile imageTile${idx}`}
							style={{ backgroundImage: `url(${photoUri})` }}
						/>
					))}
					<div
						className={`layout ${
							(photoUris.length && photoUris.length > 0) || !record.isActive
								? 'imageLayout__gradientOverlay'
								: 'noImageLayout__gradientOverlay'
						}`}
					>
						<div className="textContainer">
							<div className="leftItems">
								<div className="locationPageDisplay__overtitle">
									{nullSafeGet('locationType.name', record)} {nullSafeGet('storeId', record)}
								</div>
								<h4 className="locationPageDisplay__title">{record.name}</h4>
							</div>
							<div className="rightItems">
								<Button
									style={{ color: 'white' }}
									type="ghost"
									onClick={() => history.push(`/${userType}/locations/detail/${record.id}`)}
								>
									View Location Page
								</Button>
							</div>
						</div>
					</div>
				</div>

				<Row style={{ margin: '16px 4px' }} gutter={12}>
					<Col span={16}>
						<div className="rowSpacing">
							<Card className="materialCard" bodyStyle={{ padding: 0 }}>
								<div style={{ height: 300, position: 'absolute', top: 0, left: 0, right: 0 }}>
									<Mapbox
										longitude={nullSafeGet('buyerFacility.longitude', record)}
										latitude={nullSafeGet('buyerFacility.latitude', record)}
										baseLayerStyle="light"
										zoomLevel={7}
									/>
								</div>
								<div style={{ height: 300, position: 'relative' }} />
								<div className="px-6 py-4">
									<div className="materialCard__title">
										{nullSafeGet('buyerFacility.primaryAddress.streetAddress1', record)}
									</div>
									{nullSafeGet('buyerFacility.primaryAddress.streetAddress2', record) ? (
										<div className="materialCard__title">
											{nullSafeGet('buyerFacility.primaryAddress.streetAddress2', record)}
										</div>
									) : null}
									<div className="materialCard__subtitle">
										{nullSafeGet('buyerFacility.primaryAddress.city', record)},{' '}
										{nullSafeGet('buyerFacility.primaryAddress.region', record)}{' '}
										{nullSafeGet('buyerFacility.primaryAddress.postcode', record)}
									</div>
								</div>
							</Card>
						</div>

						<div className="rowSpacing">
							<Card title="Active Work Orders">
								<PaginatedReduxTable
									tableLayoutFixed={true}
									showHeader={false}
									emptyState={
										<EmptyState
											graphic={
												<img
													style={{ marginBottom: 8 }}
													src="https://s3.amazonaws.com/mock-data-assets/categories/images/sunset.svg"
												/>
											}
											headline={"All's well on the home front."}
											body={
												<div style={{ textAlign: 'center' }}>
													<div style={{ maxWidth: 440, marginBottom: 16 }}>
														There aren't any active work orders for this location.
													</div>
												</div>
											}
										/>
									}
									collectionName="work_orders"
									targetCollectionName={LOCATION_ASSOCIATED_ACTIVE_WORK_ORDERS_TARGET_COLLECTION}
									columns={workOrderColumns}
									onRow={onWorkOrderRow}
									keyAccessor={(el) => el.id}
									initialFilters={{ locationId: locationId, isActive: true }}
									initialPagination={{ current: 1, pageSize: 3 }}
									fetchData={
										isSupplier
											? workOrdersRestCrudThunksForSupplier.read
											: workOrdersRestCrudThunksForBuyer.read
									}
								/>
							</Card>
						</div>

						<div className="rowSpacing">
							<Card title="Work History">
								<PaginatedReduxTable
									tableLayoutFixed={true}
									showHeader={false}
									emptyState={
										<EmptyState
											graphic={
												<img
													style={{ marginBottom: 8 }}
													src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
												/>
											}
											headline={'No news is good news.'}
											body={
												<div style={{ textAlign: 'center' }}>
													<div style={{ maxWidth: 440, marginBottom: 16 }}>
														There aren't any past work orders.
													</div>
												</div>
											}
										/>
									}
									collectionName="work_orders"
									targetCollectionName={LOCATION_ASSOCIATED_INACTIVE_WORK_ORDERS_TARGET_COLLECTION}
									columns={workOrderColumns}
									onRow={onWorkOrderRow}
									keyAccessor={(el) => el.id}
									initialFilters={{
										locationId: locationId,
										isActive: false,
										hasBuyerArchived: false,
										no_count: skipCount,
									}}
									initialPagination={{ current: 1, pageSize: 3 }}
									fetchData={
										isSupplier
											? workOrdersRestCrudThunksForSupplier.read
											: workOrdersRestCrudThunksForBuyer.read
									}
								/>
							</Card>
						</div>
						<div className="rowSpacing">
							<Card title="Assets">
								<PaginatedReduxTable
									tableLayoutFixed={true}
									showHeader={false}
									emptyState={
										<EmptyState
											graphic={
												<img
													style={{ marginBottom: 8 }}
													src="https://s3.amazonaws.com/mock-data-assets/categories/images/ficus.svg"
												/>
											}
											headline={"It's lonely in here."}
											body={
												<div style={{ textAlign: 'center' }}>
													<div style={{ maxWidth: 440, marginBottom: 16 }}>
														You haven't added any assets to this location yet.
													</div>
												</div>
											}
										/>
									}
									collectionName="assets"
									targetCollectionName={LOCATION_ASSOCIATED_ASSETS_TARGET_COLLECTION}
									columns={assetsColumns}
									onRow={onAssetRow}
									keyAccessor={(el) => el.id}
									initialFilters={{ locationId: locationId }}
									initialPagination={{ current: 1, pageSize: 3 }}
									fetchData={
										isSupplier
											? assetsRestCrudThunksForSupplier.read
											: assetsRestCrudThunksForBuyer.read
									}
								/>
							</Card>
						</div>
					</Col>
					<Col span={8}>
						{nullSafeGet('brand.logoURL', record) ? (
							<div className="rowSpacing">
								<Card title={`Brand: ${nullSafeGet('brand.brandName', record)}`}>
									<img
										src={nullSafeGet('brand.logoURL', record)}
										alt=""
										className="h-auto w-20  object-contain"
									/>
								</Card>
							</div>
						) : null}
						<AccessPermissionChecker name={PERMISSION_NAMES.SHOW_LOCATION_DETAILS}>
							<LocationDetailsCard location={record} />
						</AccessPermissionChecker>
						{
							<div className="rowSpacing">
								<Tooltip placement="top" title="Only visible to your team">
									<Card title="Internal Attachments">
										<FileUploader
											roleType={userType}
											defaultFileList={record.internalAttachments || []}
											showUploadList={{ showPreviewIcon: true, showRemoveIcon: false }}
											uploaderType="readOnly"
											multiple={false}
										/>
									</Card>
								</Tooltip>
							</div>
						}
						{!isTextElementEmpty(record.internalNotes) ? (
							<AccessPermissionChecker name={PERMISSION_NAMES.SHOW_INTERNAL_TECH_NOTES}>
								<div className="rowSpacing">
									<Tooltip placement="top" title="Only visible to your team">
										<Card title="Internal Notes">
											<div
												className="richTextWrapper"
												dangerouslySetInnerHTML={{ __html: record.internalNotes }}
											/>
										</Card>
									</Tooltip>
								</div>
							</AccessPermissionChecker>
						) : null}
						{!isTextElementEmpty(record.notes) ? (
							<div className="rowSpacing">
								<Card title="Location Notes">
									<div
										className="richTextWrapper"
										dangerouslySetInnerHTML={{ __html: record.notes }}
									/>
								</Card>
							</div>
						) : null}
						<div className="rowSpacing">
							<LocationsDrawerOperatingHoursCardDisplay operatingHours={record.operatingHours} />
						</div>
						{facilityInChargeContacts.length > 0 ? (
							<div className="rowSpacing">
								<Card title="Facilities Contacts" bodyStyle={{ padding: '12px' }}>
									{facilityInChargeContacts.map((contact, idx) => (
										<div className="rowSpacing" key={idx}>
											<DrawerContactCardDisplay roleType={userType} contact={contact} />
										</div>
									))}
								</Card>
							</div>
						) : nullSafeGet('facilityInChargeContact', record) ? (
							<div className="rowSpacing">
								<DrawerContactCardDisplay
									roleType={userType}
									title="Facilities Contact"
									contact={record.facilityInChargeContact}
								/>
							</div>
						) : null}
						{primaryContactsContacts.length > 0 ? (
							<div className="rowSpacing">
								<Card title="Primary Contacts" bodyStyle={{ padding: '12px' }}>
									{primaryContactsContacts.map((contact, idx) => (
										<div className="rowSpacing" key={idx}>
											<DrawerContactCardDisplay roleType={userType} contact={contact} />
										</div>
									))}
								</Card>
							</div>
						) : null}
						{nullSafeGet('asset', record) ? (
							<div className="rowSpacing">
								<AssetCardDisplay asset={record.asset} />
							</div>
						) : null}
					</Col>
				</Row>
			</Content>
		);
	}
}

const ComponentWithoutUserType = withRouter(LocationDetailPage);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import {
	crudActionCreatorsGenerator,
	searchActionCreatorsGenerator,
} from './crud_action_creators_generator';
import {
	crudActionConstantsGenerator,
	searchConstantsGenerator,
} from './crud_action_constants_generator';

export const SUPPLIER_FACILITIES_SEARCH_ACTION_CONSTANTS =
	searchConstantsGenerator('SUPPLIER_FACILITIES');
export const SUPPLIER_FACILITIES_SEARCH_ACTION_CREATORS = searchActionCreatorsGenerator(
	SUPPLIER_FACILITIES_SEARCH_ACTION_CONSTANTS
);
export const SUPPLIER_FACILITIES_CRUD_ACTION_CONSTANTS =
	crudActionConstantsGenerator('SUPPLIER_FACILITIES');
export const SUPPLIER_FACILITIES_CRUD_ACTION_CREATORS = crudActionCreatorsGenerator(
	SUPPLIER_FACILITIES_CRUD_ACTION_CONSTANTS
);

export const ADD_WARRANTY_SUPPLIER_TO_TARGET_COLLECTION =
	'ADD_WARRANTY_SUPPLIER_TO_TARGET_COLLECTION';
export function addWarrantySupplierToTargetCollection(supplierId, targetCollectionName) {
	return {
		type: ADD_WARRANTY_SUPPLIER_TO_TARGET_COLLECTION,
		supplierId,
		targetCollectionName,
	};
}

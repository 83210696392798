import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import '@ant-design/compatible/assets/index.css';

import { Button, Col, Row, message, Alert } from 'antd';
import {
	clearFiltersInvoices,
	fetchInvoices,
	updateFiltersInvoices,
	updatePaginationInvoices,
	updateSortingInvoices,
	replaceFiltersAndSortingInvoices,
	downloadInvoicesCSV,
	fetchInvoiceDownloadFormats,
	downloadInvoiceFormatCSV,
	updateSelection,
	updateInvoiceStatusBulkWrapper,
} from '../../thunks/invoices_thunks_wrapper';
import {
	changeFilterValueToArrayOfIds,
	getEndDateFieldName,
	getEntitiesById,
	getSelectionObject,
	getStartDateFieldName,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import TableHeader from './TableHeader';
import InvoicesListTable from './InvoicesListTable';
import TableFooter from './TableFooter';
import {
	getRecordsForTargetCollection,
	standardInitialStateGenerator,
} from '../../reducers/standard_reducer_utils';
import OWAsyncTreeSelect from '../ow_async_tree_select/OWAsyncTreeSelect';
import {
	fetchSupplierFacilitiesInPrivateNetworkForBuyer,
	fetchSupplierFacilitiesInPrivateNetworkForSupplier,
} from '../../thunks/supplier_facilities_thunks';
import OWRadioGroup from '../ow_radio_group/OWRadioGroup';
import { FILTER_CONFIG_NAMES, FILTER_FIELD_TYPE, ROLE_TYPES } from '../../utils/DataConstants';
import { unionWith, differenceWith, isEqual } from 'lodash';
import TableSelectionRow from './TableSelectionRow/TableSelectionRow';
import {
	INVOICE_MULTI_SELECT_ACTION_CONFIG_FOR_BUYER,
	INVOICE_MULTI_SELECT_ACTION_CONFIG_FOR_SUPPLIER,
} from './config/multiSelect/multiSelect';
import moment from 'moment';
import OWDateRangePicker from '../ow_date_range_picker/OWDateRangePicker';
import { formatDateForParam } from '../../utils/DataFormatterUtils';
import DeclineInvoiceForm from '../decline_invoice_form/DeclineInvoiceForm';
import {
	assetsRestCrudThunksForBuyer,
	assetsRestCrudThunksForSupplier,
} from '../../thunks/assets_thunks';
import { isInternalTech } from '../../utils/AuthUtils';

const TARGETCOLLECTIONNAME = 'invoicesTable';

function InvoicesTable({
	data,
	error,
	openMoreInDefault,
	updatePagination,
	fetchAssets,
	fetchMultipleAssets,
	assets,
	invoices,
	invoiceSlice,
	fetchData,
	location,
	updateSorting,
	updateFilters,
	clearFilters,
	companyConfig,
	currentUser,
	downloadInvoicesCSV,
	CustomFooter,
	CustomHeader,
	renderInvoice,
	tableStyle,
	history,
	renderEmptyState,
	invoiceDownloadFormats,
	downloadInvoiceFormatCSV,
	fetchInvoiceDownloadFormats,
	targetCollectionName,
	userType,
	locations,
	supplierFacilities,
	fetchSupplierFacilitiesInPrivateNetwork,
	filterConfig,
	enableViews,
	replaceFiltersAndSorting,
	preAppliedFilters,
	enableMultiSelect,
	updateSelections,
	updateInvoiceStatuses,
	errors,
	enableBannerMessages,
	//loading,
	enableMessaging,
	backText,
}) {
	const {
		filters: activeFilters,
		pagination,
		sorting: activeSorters,
	} = invoices || standardInitialStateGenerator([targetCollectionName]);
	React.useEffect(() => {
		fetchData(location);
		fetchInvoiceDownloadFormats();
	}, []);

	//Statuses
	const { fetching, updating } = invoiceSlice;
	if (enableMessaging) {
		updating
			? message.open({
					key: 'invoiceTableMessageUpdating',
					type: 'loading',
					content: 'Updating...',
					duration: 60,
			  })
			: message.destroy('invoiceTableMessageUpdating');
	}

	const COMMON_FILTER_CONFIGS = useMemo(
		() => [
			{
				label: 'Location',
				fieldName: 'locationIds',
				mode: 'multiple',
				stateSlice: locations,
				hideFilter: true,
				filtersValueAccessor: (activeFilters) => {
					const locationIds = nullSafeGet(`locationIds`, activeFilters);
					const finalIds = nullSafeGetOrElse(`regionIds`, activeFilters, locationIds);
					return changeFilterValueToArrayOfIds(finalIds);
				},
				keyAccessor: (loc) => loc.id,
				valueAccessor: (loc) => loc.id,
				handleChange: (ids) => {
					updateFilters({
						locationIds: ids.join(',') || undefined,
						regionIds: undefined,
					});
				},
				targetCollectionName: 'invoicesAdvancedFilters',
				fetchMultiple: () => {},
				renderItem: (loc) => loc.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
			},
			{
				label: 'Asset',
				fieldName: FILTER_CONFIG_NAMES.ASSET,
				mode: 'multiple',
				hideFilter: true,
				stateSlice: assets,
				filtersValueAccessor: (activeFilters) => {
					const assetIds = nullSafeGet(`assetIds`, activeFilters);
					return changeFilterValueToArrayOfIds(assetIds);
				},
				keyAccessor: (asset) => asset.id,
				valueAccessor: (asset) => asset.id,
				handleChange: (ids) => {
					updateFilters({ assetIds: ids.join(',') || undefined });
				},
				targetCollectionName: 'invoicesAdvancedFilters',
				fetch: fetchAssets,
				fetchMultiple: fetchMultipleAssets,
				renderItem: (asset) => asset.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
				component: OWAsyncTreeSelect,
			},
			{
				label: 'Published Between',
				labelPrefix: 'Published',
				fieldName: FILTER_CONFIG_NAMES.PUBLISHED_AT,
				type: FILTER_FIELD_TYPE.DATE_RANGE,
				filtersValueAccessor: (filtersStateSlice) => {
					const startDate =
						filtersStateSlice[getStartDateFieldName(FILTER_CONFIG_NAMES.PUBLISHED_AT)];
					const endDate = filtersStateSlice[getEndDateFieldName(FILTER_CONFIG_NAMES.PUBLISHED_AT)];
					return [startDate && moment(startDate), endDate && moment(endDate)];
				},
				handleChange: (values) => {
					updateFilters({
						[getStartDateFieldName(FILTER_CONFIG_NAMES.PUBLISHED_AT)]:
							values && values.length > 1 && values[0] ? formatDateForParam(values[0]) : undefined,
						[getEndDateFieldName(FILTER_CONFIG_NAMES.PUBLISHED_AT)]:
							values && values.length > 1 && values[1] ? formatDateForParam(values[1]) : undefined,
					});
				},
				renderRecord: (item) => item,
				valueAccessor: (item) => item,
				component: OWDateRangePicker,
			},
			{
				label: 'Approved Between',
				labelPrefix: 'Approved',
				fieldName: FILTER_CONFIG_NAMES.APPROVED_AT,
				type: FILTER_FIELD_TYPE.DATE_RANGE,
				filtersValueAccessor: (filtersStateSlice) => {
					const startDate =
						filtersStateSlice[getStartDateFieldName(FILTER_CONFIG_NAMES.APPROVED_AT)];
					const endDate = filtersStateSlice[getEndDateFieldName(FILTER_CONFIG_NAMES.APPROVED_AT)];
					return [startDate && moment(startDate), endDate && moment(endDate)];
				},
				handleChange: (values) => {
					updateFilters({
						[getStartDateFieldName(FILTER_CONFIG_NAMES.APPROVED_AT)]:
							values && values.length > 1 && values[0] ? formatDateForParam(values[0]) : undefined,
						[getEndDateFieldName(FILTER_CONFIG_NAMES.APPROVED_AT)]:
							values && values.length > 1 && values[1] ? formatDateForParam(values[1]) : undefined,
					});
				},
				renderRecord: (item) => item,
				valueAccessor: (item) => item,
				component: OWDateRangePicker,
			},
			{
				label: 'Marked Paid Between',
				labelPrefix: 'Marked paid',
				fieldName: FILTER_CONFIG_NAMES.MARKED_PAID_AT,
				type: FILTER_FIELD_TYPE.DATE_RANGE,
				filtersValueAccessor: (filtersStateSlice) => {
					const startDate =
						filtersStateSlice[getStartDateFieldName(FILTER_CONFIG_NAMES.MARKED_PAID_AT)];
					const endDate =
						filtersStateSlice[getEndDateFieldName(FILTER_CONFIG_NAMES.MARKED_PAID_AT)];
					return [startDate && moment(startDate), endDate && moment(endDate)];
				},
				handleChange: (values) => {
					updateFilters({
						[getStartDateFieldName(FILTER_CONFIG_NAMES.MARKED_PAID_AT)]:
							values && values.length > 1 && values[0] ? formatDateForParam(values[0]) : undefined,
						[getEndDateFieldName(FILTER_CONFIG_NAMES.MARKED_PAID_AT)]:
							values && values.length > 1 && values[1] ? formatDateForParam(values[1]) : undefined,
					});
				},
				renderRecord: (item) => item,
				valueAccessor: (item) => item,
				component: OWDateRangePicker,
			},
		],
		[locations, updateFilters, assets]
	);
	const [showDeclineModal, setShowDeclineModal] = useState(false);
	const handleSubmitDisputeInvoice = (values) => {
		updateInvoiceStatuses(
			invoices.selections.map((record) => record.id),
			'disputed',
			Object.keys(invoices.selections[0]),
			{
				disputeNote: {
					text: values.note || '',
					noteAddedBy: nullSafeGet('email', currentUser),
					noteAddedAt: moment.utc(),
				},
			}
		)
			.then((data) => {
				setShowDeclineModal(false);
				message.destroy('invoiceTableMessageUpdating');
				message.open({
					key: 'invoiceTableSuccess',
					type: 'success',
					content: data.length.toString().concat(' records updated'),
					duration: 2,
				});
			})
			.catch((error) => {
				message.open({
					key: 'invoiceTableError',
					type: 'error',
					content: error,
					duration: 10,
				});
			});
	};
	const assignBulkActionFunction = (action) => {
		switch (nullSafeGet('toStatus', action)) {
			case 'disputed':
				return () => setShowDeclineModal(true);
			default:
				return () =>
					updateInvoiceStatuses(
						invoices.selections.map((record) => record.id),
						action.toStatus,
						Object.keys(invoices.selections[0])
					)
						.then((data) => {
							message.destroy('invoiceTableMessageUpdating');
							message.open({
								key: 'invoiceTableSuccess',
								type: 'success',
								content: data.length.toString().concat(' records updated'),
								duration: 2,
							});
						})
						.catch((error) => {
							message.open({
								key: 'invoiceTableError',
								type: 'error',
								content: error,
								duration: 10,
							});
						});
		}
	};
	let actions = (
		userType === 'buyer'
			? INVOICE_MULTI_SELECT_ACTION_CONFIG_FOR_BUYER
			: INVOICE_MULTI_SELECT_ACTION_CONFIG_FOR_SUPPLIER
	).map((action) => {
		return {
			...action,
			fn: assignBulkActionFunction(action),
		};
	});

	let selectItems = enableMultiSelect
		? {
				onSelectCurrentPage: (records) => {
					let keys = unionWith(
						invoices.selections,
						records.map((rec) => getSelectionObject(rec)),
						isEqual
					);
					updateSelections(targetCollectionName, keys);
				},
				onDeselectCurrentPage: (records) => {
					let keys = differenceWith(
						invoices.selections,
						records.map((rec) => getSelectionObject(rec)),
						isEqual
					);
					updateSelections(targetCollectionName, keys);
				},
				onSelectNone: (e) => {
					updateSelections(targetCollectionName, []);
				},
				selections: invoices.selections,
				rowSelection: {
					onSelect: (record, selected, selectedRows, e) => {
						let isSelected = invoices.selections
							.map((item) => item.id)
							.includes(record.id.toString());
						let keys = !isSelected
							? unionWith(invoices.selections, [getSelectionObject(record)], isEqual)
							: differenceWith(invoices.selections, [getSelectionObject(record)], isEqual);
						updateSelections(targetCollectionName, keys);
					},
					selectedRowKeys: invoices.selections.map((item) => item.id),
					preserveSelectedRowKeys: true,
				},
		  }
		: null;

	const BUYER_FILTER_CONFIGS = useMemo(
		() => [
			{
				label: 'Supplier',
				fieldName: 'supplierFacilityIds',
				mode: 'multiple',
				stateSlice: supplierFacilities,
				filtersValueAccessor: (filtersStateSlice) =>
					changeFilterValueToArrayOfIds(nullSafeGet(`supplierFacilityIds`, filtersStateSlice)),
				keyAccessor: (supplierFacility) => supplierFacility.id,
				valueAccessor: (supplierFacility) => supplierFacility.id,
				handleChange: (ids) => {
					updateFilters({
						supplierFacilityIds: ids.join(',') || undefined,
					});
				},
				targetCollectionName: 'invoicesAdvancedFilters',
				fetch: fetchSupplierFacilitiesInPrivateNetwork,
				fetchMultiple: () => {},
				renderItem: (supplierFacility) => supplierFacility.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
				component: OWAsyncTreeSelect,
			},
			{
				fieldName: 'isCapex',
				type: 'radioGroup',
				items: [
					{
						label: 'All',
						value: undefined,
					},
					{
						label: 'R&M',
						value: 'false',
					},
					{
						label: 'CapEx',
						value: 'true',
					},
				],
				filtersValueAccessor: (filtersStateSlice) => {
					const val = filtersStateSlice.isCapex;
					return typeof val === 'undefined' ? undefined : `${val}`;
				},
				keyAccessor: (item) => item.value,
				valueAccessor: (item) => item.value,
				handleChange: (isCapex) => {
					const val = isCapex ? isCapex === 'true' : undefined;
					updateFilters({ isCapex: val });
				},
				targetCollectionName: 'workOrdersAdvancedFilters',
				renderRecord: (item) => item.label,
				component: OWRadioGroup,
				info: [
					{
						title: 'Repair and Maintenance (R&M)',
						description: 'Costs associated with repair and maintenance of existing assets',
					},
					{
						title: 'Capital Expenditures (CapEx)',
						description: 'Costs associated with buying new assets or upgrading existing assets',
					},
				],
			},
		],
		[supplierFacilities, fetchSupplierFacilitiesInPrivateNetwork, updateFilters]
	);

	const SUPPLIER_FILTER_CONFIGS = useMemo(
		() => [
			{
				label: 'Created Between',
				labelPrefix: 'Created',
				fieldName: FILTER_CONFIG_NAMES.CREATED_AT,
				type: FILTER_FIELD_TYPE.DATE_RANGE,
				filtersValueAccessor: (filtersStateSlice) => {
					const startDate =
						filtersStateSlice[getStartDateFieldName(FILTER_CONFIG_NAMES.CREATED_AT)];
					const endDate = filtersStateSlice[getEndDateFieldName(FILTER_CONFIG_NAMES.CREATED_AT)];
					return [startDate && moment(startDate), endDate && moment(endDate)];
				},
				handleChange: (values) => {
					updateFilters({
						[getStartDateFieldName(FILTER_CONFIG_NAMES.CREATED_AT)]:
							values && values.length > 1 && values[0] ? formatDateForParam(values[0]) : undefined,
						[getEndDateFieldName(FILTER_CONFIG_NAMES.CREATED_AT)]:
							values && values.length > 1 && values[1] ? formatDateForParam(values[1]) : undefined,
					});
				},
				renderRecord: (item) => item,
				valueAccessor: (item) => item,
				component: OWDateRangePicker,
			},
		],
		[updateFilters]
	);

	const FILTER_CONFIG = useMemo(() => {
		const defaultConfig: any[] =
			userType === ROLE_TYPES.SUPPLIER
				? [
						...COMMON_FILTER_CONFIGS,
						...SUPPLIER_FILTER_CONFIGS,
						...(isInternalTech(currentUser) ? BUYER_FILTER_CONFIGS : []),
				  ]
				: [...COMMON_FILTER_CONFIGS, ...BUYER_FILTER_CONFIGS];
		const advancedFilterConfig =
			filterConfig && filterConfig.filters && filterConfig.filters.length > 0
				? filterConfig.filters.map((filter) => defaultConfig.find((_) => _.fieldName === filter))
				: defaultConfig;
		return {
			...(filterConfig || {}),
			filters: advancedFilterConfig.filter((_) => !!_),
		};
	}, [
		userType,
		filterConfig,
		COMMON_FILTER_CONFIGS,
		SUPPLIER_FILTER_CONFIGS,
		BUYER_FILTER_CONFIGS,
	]);

	return (
		<div>
			{CustomHeader ? (
				<CustomHeader
					applySorting={updateSorting}
					applyFilters={(e) => updateFilters(e)}
					filters={activeFilters}
					sorters={activeSorters}
					downloadCSV={() => downloadInvoicesCSV({}, activeFilters)}
					counts={invoiceSlice.counts}
					filterConfig={FILTER_CONFIG}
					clearFilters={clearFilters}
					targetCollectionName={targetCollectionName}
					userType={userType}
					pagination={pagination}
					fetching={fetching}
				/>
			) : (
				<TableHeader
					applySorting={updateSorting}
					applyFilters={(e) => updateFilters(e)}
					filters={activeFilters}
					sorters={activeSorters}
					downloadCSV={() => downloadInvoicesCSV({}, activeFilters)}
					counts={invoiceSlice.counts}
					filterConfig={FILTER_CONFIG}
					clearFilters={clearFilters}
					targetCollectionName={targetCollectionName}
					enableViews={enableViews}
					userType={userType}
					applyFiltersFromView={replaceFiltersAndSorting}
					preAppliedFilters={preAppliedFilters}
					downloadInvoiceFormatCSV={(key) => downloadInvoiceFormatCSV(key, {}, activeFilters)}
					invoiceDownloadFormatRecords={getRecordsForTargetCollection(
						invoiceDownloadFormats,
						'invoiceFormatsIndex'
					)}
					fetching={fetching}
				/>
			)}

			<div style={{ marginTop: 16, marginLeft: 4, background: 'white' }}>
				{enableBannerMessages &&
				nullSafeGet('filters.statuses', invoiceSlice[targetCollectionName]) === 'Draft' ? (
					<Row>
						<Alert
							style={{ width: '100%', padding: '8px 9px', borderWidth: 0 }}
							type={'warning'}
							showIcon
							message={
								<span style={{ paddingLeft: '16px' }}>
									Drafted invoices can't be seen by your customers. Please make sure to submit your
									invoice once work has been completed.
								</span>
							}
						/>
					</Row>
				) : null}
				{enableMultiSelect ? (
					<TableSelectionRow
						selectItems={selectItems}
						actions={actions}
						records={data}
						counts={invoiceSlice.counts}
						updatePagination={updatePagination}
						pagination={pagination}
						updating={updating}
						errors={errors}
						currentUser={currentUser}
					/>
				) : null}
				<DeclineInvoiceForm
					visible={showDeclineModal}
					onCancel={() => setShowDeclineModal(false)}
					onSubmit={handleSubmitDisputeInvoice}
				/>
				<InvoicesListTable
					fetching={fetching}
					data={data}
					currentUser={currentUser}
					companyConfig={companyConfig}
					renderInvoice={renderInvoice}
					refreshTable={fetchData}
					tableStyle={tableStyle}
					renderEmptyState={renderEmptyState}
					location={location}
					userType={userType}
					selectItems={selectItems}
					enableMultiSelect={enableMultiSelect}
					backText={backText}
				/>
				{CustomFooter ? (
					<CustomFooter
						updatePagination={updatePagination}
						pagination={{ ...pagination, current: 1, pageSize: 3 }}
						filters={activeFilters}
						sorter={activeSorters}
						location={location}
						history={history}
						enableMultiSelect={enableMultiSelect}
						openMoreInDefault={openMoreInDefault}
						userType={userType}
					/>
				) : (
					<Row justify="end">
						<TableFooter
							records={data}
							//enableMultiSelect={enableMultiSelect}
							updatePagination={updatePagination}
							pagination={
								pagination && pagination.total && pagination.total !== 0 ? pagination : null
							}
							//selectItems={selectItems}
						/>
					</Row>
				)}
			</div>
		</div>
	);
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	handleQueryParams: ownProps.handleQueryParams,
	locations: state.locations,
	currentUser: state.session.currentUser,
	errors: state.session.errors,
	loading: state.session.loading,
	spendCategories: state.spend_categories,
	problemTypes: state.problem_types,
	regions: state.regions,
	areas: state.areas,
	supplierFacilities: state.supplier_facilities,
	invoices: state.invoices[ownProps.targetCollectionName || TARGETCOLLECTIONNAME],
	invoiceSlice: state.invoices,
	assets: state.assets,
	companyConfig: state.company_config.detail,
	targetCollectionName: ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
	data: getEntitiesById(
		state.invoices[ownProps.targetCollectionName || TARGETCOLLECTIONNAME]
			? state.invoices[ownProps.targetCollectionName || TARGETCOLLECTIONNAME].recordsIds || []
			: [],
		state.invoices.records
	),
	invoiceDownloadFormats: state.invoice_download_formats,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchData: (location) =>
		dispatch(
			fetchInvoices(ownProps.userType)(
				ownProps.handleQueryParams,
				ownProps.history,
				location,
				ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
				ownProps.preAppliedFilters,
				ownProps.initialPagination,
				ownProps.initialSorting,
				ownProps.initialFilters,
				ownProps.ignoreRefreshCounts
			)
		),
	updatePagination: (pagination) =>
		dispatch(
			updatePaginationInvoices(ownProps.userType)(
				ownProps.handleQueryParams,
				ownProps.history,
				ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
				pagination,
				ownProps.ignoreRefreshCounts
			)
		),
	updateSorting: (sorting) =>
		dispatch(
			updateSortingInvoices(ownProps.userType)(
				ownProps.handleQueryParams,
				ownProps.history,
				ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
				sorting,
				ownProps.ignoreRefreshCounts
			)
		),
	updateFilters: (filters) =>
		dispatch(
			updateFiltersInvoices(ownProps.userType)(
				ownProps.handleQueryParams,
				ownProps.history,
				ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
				filters,
				ownProps.ignoreRefreshCounts
			)
		),
	updateSelections: (targetCollectionName, selections) =>
		dispatch(
			updateSelection(ownProps.userType)(
				ownProps.handleQueryParams,
				ownProps.history,
				ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
				selections
			)
		),
	replaceFiltersAndSorting: (filters, sorting) =>
		dispatch(
			replaceFiltersAndSortingInvoices(ownProps.userType)(
				ownProps.handleQueryParams,
				ownProps.history,
				ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
				filters,
				sorting,
				ownProps.ignoreRefreshCounts
			)
		),
	clearFilters: () =>
		dispatch(
			clearFiltersInvoices(ownProps.userType)(
				ownProps.handleQueryParams,
				ownProps.history,
				ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
				ownProps.preAppliedFilters,
				ownProps.ignoreRefreshCounts
			)
		),
	fetchSupplierFacilitiesInPrivateNetwork: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) => {
		const fnToCall =
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? fetchSupplierFacilitiesInPrivateNetworkForSupplier
				: fetchSupplierFacilitiesInPrivateNetworkForBuyer;
		return dispatch(
			fnToCall(
				params,
				targetCollectionName || TARGETCOLLECTIONNAME,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		);
	},
	downloadInvoicesCSV: (params, filters) =>
		dispatch(downloadInvoicesCSV(ownProps.userType)(params, filters)),
	fetchInvoiceDownloadFormats: (params) =>
		dispatch(fetchInvoiceDownloadFormats(ownProps.userType)(params)),
	downloadInvoiceFormatCSV: (formatName, params, filters) =>
		dispatch(downloadInvoiceFormatCSV(ownProps.userType)(formatName, params, filters)),
	updateInvoiceStatuses: (ids, status, invoiceParams, additionalParams = {}) =>
		dispatch(
			updateInvoiceStatusBulkWrapper(ownProps.userType)(
				ids,
				status,
				TARGETCOLLECTIONNAME,
				invoiceParams,
				additionalParams
			)
		),
	fetchAssets: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) => {
		const fnToCall =
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetsRestCrudThunksForSupplier.readLite
				: assetsRestCrudThunksForBuyer.readLite;
		return dispatch(
			fnToCall(
				params,
				targetCollectionName || TARGETCOLLECTIONNAME,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		);
	},
	fetchMultipleAssets: (ids, targetCollectionName) => {
		const fnToCall =
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetsRestCrudThunksForSupplier.readMultiple
				: assetsRestCrudThunksForBuyer.readMultiple;
		return dispatch(fnToCall(ids, targetCollectionName || TARGETCOLLECTIONNAME));
	},
});

const InvoicesHOC = withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoicesTable));

export default connect(
	(state) => ({
		userType: state.session.userType,
	}),
	(dispatch, ownProps) => ({})
)(InvoicesHOC);

import { Popover, Select } from 'antd';
import { Form } from '@ant-design/compatible';
import React from 'react';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';

//THIS FILE EXISTS SEPARATELY DUE TO THE CHANGES IN ANTD FORM ITEMS, EVENTUALLY IT SHOULD BE PHASED OUT

export default function MultiEmailComponentAntdV3({ form, formData, label, fieldProps, ...props }) {
	const emailValidator = (rule, values, callback) => {
		const emailRegex = /^([a-zA-Z0-9+_\.-]+)@([\da-zA-Z\.-]+)\.([a-zA-Z\.]{2,6})$/;
		const invalidInputs = values.filter((value) => !value.match(emailRegex));
		if (invalidInputs.length === 0) {
			callback();
		} else if (invalidInputs.length === 1) {
			callback(invalidInputs.join('') + ' is not a valid email');
		} else {
			callback(
				invalidInputs.slice(0, -1).join(', ') +
					' and ' +
					invalidInputs.slice(-1) +
					' are not valid emails'
			);
		}
	};
	return (
		<Form.Item label={label} style={{ display: 'block' }}>
			{form.getFieldDecorator(fieldProps.name, {
				...fieldProps,
				rules: [...(fieldProps.rules || []), { validator: emailValidator }],
				normalize: (e) => {
					if (e) {
						const filteredArray = e.map((item, index) => {
							if (!item.match(/^(.+)@(.+)$/)) {
								return undefined;
							}
							return item.replace(/[<>]/g, '');
						});
						return filteredArray.filter((item) => item);
					}
					return [];
				},
				initialValue: formData[fieldProps.name],
			})(
				<Select
					tokenSeparators={[',', ' ', ';', ':']}
					optionFilterProp="label"
					placeholder="Enter emails"
					mode="tags"
					open={false}
					allowClear
					{...props}
					style={{ display: 'block' }}
				/>
			)}
		</Form.Item>
	);
}

import * as React from 'react';
import { Layout, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { assetsRestCrudThunksForSupplier } from '../../thunks/assets_thunks';
import { Document, Page } from 'react-pdf';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { getFileLink } from '../../utils/FileAccessUtils';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;

class SupplierAssetsDetailManualsPage extends React.Component<any, any> {
	state = {
		loading: true,
		numPages: null,
		pdfFiles: [],
	};

	componentDidMount() {
		const { getAsset, match } = this.props;
		const assetId = match.params.id;

		getAsset(assetId).then((asset) => {
			const manuals = nullSafeGetOrElse('assetModel.manuals', asset, []);
			const fileManuals = manuals.map((file) => {
				let url;
				if (file.fileId) {
					// Component will show file.url as link
					const fileId = nullSafeGetOrElse('fileId', file, '');
					const fileName = nullSafeGetOrElse('fileName', file, '');
					const fileUrl = getFileLink(fileId, fileName, 'supplier');
					url = fileUrl;
				}

				return {
					url: url,
					httpHeaders: null,
				};
			});

			console.log(fileManuals);

			this.setState({
				loading: false,
				pdfFiles: fileManuals,
			});
		});
	}

	onDocumentLoadSuccess = ({ numPages }) => {
		this.setState({ numPages });
	};

	render() {
		const { match } = this.props;

		const { loading } = this.state;

		const pdfDocuments = this.state.pdfFiles.map((pdfFile) => {
			const doc = (
				<Document file={pdfFile} onLoadSuccess={this.onDocumentLoadSuccess}>
					{Array.from(new Array(this.state.numPages), (el, index) => (
						<Page width={1100} key={`page_${index + 1}`} pageNumber={index + 1} />
					))}
				</Document>
			);
			return doc;
		});

		return loading ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="assetsDetailDetailsPage">
				<LogOnMountWithStandardEventProperties eventType="visited supplier asset detail manual page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}

				<Row style={{ margin: '16px 4px' }} gutter={12}>
					<Col span={24}>
						<div className="rowSpacing">
							{this.state.pdfFiles.length > 0 ? <div>{pdfDocuments}</div> : null}
						</div>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	asset: state.assets.detail,
	currentUser: state.session.currenUser,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	getAsset: (id) => dispatch(assetsRestCrudThunksForSupplier.readOne(id)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(SupplierAssetsDetailManualsPage)
);

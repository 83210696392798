import { JOURNAL_RUNS_CRUD_ACTION_CREATORS } from '../actions/journal_runs_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { getBackendUri } from '../utils/EnvConfigUtils';
import FetchUtils, { parseJSON } from './fetch_utils';
import { logoutSuccess } from '../actions/session_actions';

const fetchUtils = new FetchUtils();
const BASE_URL = getBackendUri();

export const journalRunsRestCrudThunksForBuyer = new RestCrudThunks(
	'depreciation_run_histories',
	'journal_run',
	'journalRun',
	'journalRuns',
	JOURNAL_RUNS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'asset/'
);
export const journalRunsRestCrudThunksForSupplier = new RestCrudThunks(
	'depreciation_run_histories',
	'journal_run',
	'journalRun',
	'journalRuns',
	JOURNAL_RUNS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'asset/'
);

const rollbackJournalRun =
	(userType) =>
	(entity: any = {}) => {
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(JOURNAL_RUNS_CRUD_ACTION_CREATORS.updateStart(entity));

				fetchUtils
					.post(
						BASE_URL + `/api/v1/${userType}/asset/depreciation_run_histories/rollback/${entity.id}`,
						{
							headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
							data: entity,
						}
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(JOURNAL_RUNS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(JOURNAL_RUNS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(JOURNAL_RUNS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};

export const rollbackJournalRunForSupplier = rollbackJournalRun(ROLE_TYPES.SUPPLIER);
export const rollbackJournalRunForBuyer = rollbackJournalRun(ROLE_TYPES.BUYER);

const rerunJournalRun =
	(userType) =>
	(entity: any = {}) => {
		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(JOURNAL_RUNS_CRUD_ACTION_CREATORS.updateStart(entity));

				fetchUtils
					.post(
						BASE_URL + `/api/v1/${userType}/asset/depreciation_run_histories/rerun/${entity.id}`,
						{
							headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
							data: entity,
						}
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(JOURNAL_RUNS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
							reject(d.message);
						} else {
							dispatch(JOURNAL_RUNS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(JOURNAL_RUNS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					});
			});
		};
	};

export const rerunJournalRunForSupplier = rerunJournalRun(ROLE_TYPES.SUPPLIER);
export const rerunJournalRunForBuyer = rerunJournalRun(ROLE_TYPES.BUYER);

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { metersRestCrudThunksForBuyer } from '../../thunks/meters_thunks';
import MeterForm from '../meter_form/MeterForm';

const style = require('./EditMeterPage.less');

class EditMeterPage extends React.Component<any, any> {
	state = {
		loading: true,
		meterDetail: {},
	};

	componentDidMount() {
		const { getMeter, match } = this.props;
		const meterId = match.params.id;
		getMeter(meterId).then((meter) => {
			this.setState({
				loading: false,
				meterDetail: meter,
			});
		});
	}

	render() {
		const { history } = this.props;

		//noinspection TypeScriptUnresolvedVariable
		return [
			<LogOnMountWithStandardEventProperties eventType="visited edit meter page" />,
			<EditPage
				title="Edit Meter"
				formComponentProps={{
					formData: this.state.meterDetail,
					onSuccess: (rec) => history.push(`/buyer/meters/detail/${rec.id}`),
				}}
				fetching={this.state.loading}
				FormComponent={MeterForm}
			/>,
		];
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		history: ownProps.history,
		match: ownProps.match,
	};
};

const mapDispatchToProps = (dispatch) => ({
	getMeter: (id) => dispatch(metersRestCrudThunksForBuyer.readOne(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditMeterPage));

import { METER_TYPES_CRUD_ACTION_CREATORS } from '../actions/meter_types_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';

export const meterTypesRestCrudThunksForBuyer = new RestCrudThunks(
	'meter_types',
	'meter_type',
	'meterType',
	'meterTypes',
	METER_TYPES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'asset/'
);
export const meterTypesRestCrudThunksForSupplier = new RestCrudThunks(
	'meter_types',
	'meter_type',
	'meterType',
	'meterTypes',
	METER_TYPES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'asset/'
);

import * as React from 'react';
import { Col, Progress, Row, Tooltip } from 'antd';
import { getObjectValues, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { useCallback, useEffect, useRef, useState, useLayoutEffect } from 'react';
import { isEqual } from 'lodash';
import { CaretUpOutlined } from '@ant-design/icons';

const style = require('./ProgressBarComponent.less');

function ProgressItem(props) {
	const { barHeight, gap, color } = props;
	return (
		<div
			style={{
				height: barHeight,
				backgroundColor: color,
				display: 'flex',
				flexDirection: 'row',
				flexGrow: 1,
				gap: gap,
			}}
		></div>
	);
}

function SectionComponent(props) {
	const {
		items,
		barHeight,
		gap,
		color,
		display,
		basis,
		subTotal,
		lineLabel,
		parent,
		onClick,
		itemQuantity,
		setSection,
	} = props;

	let sectionWidth = display ? (basis === 'amount' ? subTotal : itemQuantity) : 0;

	let progressItems = [];
	let i = 0;
	const sectionRef = useRef(null);
	const [width, setWidth] = useState(0);
	useEffect(() => {
		setWidth(sectionRef.current.clientWidth);
	}, [parent]);

	const [sectionHover, setSectionHover] = useState(false);
	const handleMouseEnter = useCallback((e) => {
		e.preventDefault();
		setSectionHover(true);
		setSection(props.label);
	}, []);
	const handleMouseOut = useCallback((e) => {
		e.preventDefault();
		if (e.toElement && !isChildElement(e.toElement, sectionRef.current)) {
			setSectionHover(false);
		}
	}, []);
	useEffect(() => {
		const node = sectionRef.current;
		if (node) {
			node.addEventListener('mouseenter', handleMouseEnter);
			node.addEventListener('mouseout', handleMouseOut);
			return () => {
				node.removeEventListener('mouseenter', handleMouseEnter);
				node.removeEventListener('mouseout', handleMouseOut);
			};
		}
	});
	while (itemQuantity && itemQuantity > i) {
		progressItems.push(
			ProgressItem({
				barHeight: barHeight,
				gap: gap,
				color: color,
				parent: { props },
			})
		);
		i++;
	}

	return (
		<div
			ref={sectionRef}
			className={'Section'}
			onClick={onClick}
			style={{
				display: 'flex',
				flexDirection: 'row',
				paddingTop: '12px',
				paddingBottom: '28px',
				flexGrow: sectionWidth,
				boxShadow: sectionHover ? '0px 0px 40px 20px rgba(0,0,0,.06)' : '',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexGrow: 1,
					flexDirection: 'row',
					gap: gap,
					backgroundColor: basis === 'amount' ? color : null,
					height: barHeight,
				}}
			>
				{progressItems.map((progressItem) => progressItem)}
			</div>
		</div>
	);
}

export default function ProgressBarComponent(props) {
	const {
		header,
		barHeight,
		fixedGap,
		lineLabels,
		items,
		datapath,
		statusList,
		sectionList,
		total,
		basis,
		subTotalPath,
		style,
		otherColor,
		negativeColor,
	} = props;

	const ref = useRef(null);
	const main = useRef(null);
	const [componentHover, setComponentHover] = useState(false);
	const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
	const handleMouseEnter = useCallback((e) => {
		e.preventDefault();
		setMousePosition({ x: e.clientX, y: e.clientY });
		setComponentHover(true);
	}, []);
	const handleMouseOut = useCallback((e) => {
		e.preventDefault();
		if (
			!e.toElement ||
			!(isChildElement(e.toElement, main.current) || isChildElement(e.toElement, ref.current))
		) {
			setComponentHover(false);
		}
	}, []);
	const handleMouseMove = useCallback((e) => {
		e.preventDefault();
		setMousePosition({ x: e.clientX, y: e.clientY });
	}, []);
	const handleMouseWheel = useCallback((e) => {
		setMousePosition({ x: e.clientX, y: e.clientY });
	}, []);
	useEffect(() => {
		const node = ref.current;
		if (node) {
			node.addEventListener('mouseenter', handleMouseEnter);
			node.addEventListener('mouseout', handleMouseOut);
			node.addEventListener('mousemove', handleMouseMove);
			node.addEventListener('wheel', handleMouseWheel);
			return () => {
				node.removeEventListener('mouseenter', handleMouseEnter);
				node.removeEventListener('mouseout', handleMouseOut);
				node.removeEventListener('mouseout', handleMouseMove);
				node.removeEventListener('wheel', handleMouseWheel);
			};
		}
	});
	const [currentSection, setCurrentSection] = useState(null);
	const setSection = useCallback((section) => {
		setCurrentSection(section);
	}, []);
	const [width, setWidth] = useState(0);
	useEffect(() => {
		setWidth(ref.current.clientWidth);
	});
	let budget = total;
	let diff = total;
	let gap = fixedGap ? fixedGap : 0;
	let sum = 0;
	let sections = sectionList.map((section) => {
		let itemQuantity = section.hasOwnProperty('itemQuantity') ? section.itemQuantity : 0;
		let quantity, sectionWidth;
		if (section.hasOwnProperty('subTotal') && subTotalPath && total) {
			quantity = section.subTotal;
		} else {
			quantity = itemQuantity;
		}
		sum += parseFloat(nullSafeGetOrElse('display', section, true) === true ? quantity : 0);

		if (diff - sum > 0) {
			sectionWidth = (quantity / total) * width;
		} else {
			let rem = 0;
			rem = sum - diff;
			sectionWidth = (rem / total) * width;
		}
		return {
			...section,
			gap: fixedGap,
			barHeight: barHeight,
			display: section.hasOwnProperty('display') ? section.display : true,
			lineLabel: {
				...lineLabels,
				label: '$'.concat(nullSafeGetOrElse('subTotal', section, 0).toLocaleString()),
			},
			quantity: quantity,
			sectionWidth: sectionWidth,
		};
	});
	let isOverBudget = false;
	//Setup Total
	if (basis === 'amount' && total) {
		let subTotals = sections
			.filter((section) => section.display)
			.map((section) => section.subTotal);
		let remaining =
			total -
			subTotals.reduce(
				(currentSubTotal, prevSubTotal) => parseFloat(currentSubTotal) + parseFloat(prevSubTotal)
			);
		if (total - remaining > total) {
			diff = total - remaining;
		}
		if (remaining < 0) {
			isOverBudget = true;
		} else {
			sections.push({
				label: 'Remainder',
				statuses: [null],
				items: [],
				gap: fixedGap,
				barHeight: barHeight,
				display: true,
				color: otherColor ? otherColor : 'white',
				subTotal: remaining,
				basis: 'amount',
				onClick: nullSafeGet('otherOnClick', props),
				lineLabel: { ...lineLabels, label: '$'.concat(total.toLocaleString()) },
				quantity: remaining,
				hasCarrot: true,
				carrotStyle: { right: 0 },
				subCarrotStyle: { transform: 'translate(50%,-25%)' },
			});
		}
	}
	let budgetWidth;
	if (sum === 0) {
		budgetWidth = width;
	} else {
		budgetWidth = (total / sum) * width;
	}
	if (budgetWidth > width) {
		budgetWidth = width;
	}
	let renderSections = sections.map((section) => {
		let itemQuantity = section.hasOwnProperty('itemQuantity') ? section.itemQuantity : 0;
		let quantity, sectionWidth;
		if (section.hasOwnProperty('subTotal') && subTotalPath && diff) {
			quantity = section.subTotal;
			sectionWidth = section.subTotal / diff;
		} else {
			quantity = itemQuantity;
			sectionWidth = (itemQuantity / diff) * width;
		}
		return section.display
			? SectionComponent({
					...section,
					quantity: quantity,
					itemQuantity: itemQuantity,
					sectionWidth: sectionWidth,
					basis: basis,
					parent: { ref: ref, props: { ...props, width: width } },
					setSection: setSection,
			  })
			: null;
	});

	return (
		<div
			style={{
				...style,
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				gap: gap,
			}}
		>
			<MouseHoverToolTip
				style={{ display: componentHover ? 'block' : 'none' }}
				coords={mousePosition}
				sections={sections}
				basis={basis}
				budget={budget}
				budgetWidth={budgetWidth}
				isOverBudget={isOverBudget}
				overAmount={sum - budget}
				currentSection={currentSection}
			/>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					fontSize: '16px',
				}}
			>
				{header}
			</div>
			<div
				ref={ref}
				className={'Progress'}
				style={{
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
					gap: gap,
					boxShadow: componentHover ? '0px 0px 40px 2px rgba(0,0,0,.02)' : '',
				}}
			>
				{renderSections.map((section) => section)}
			</div>
			{basis === 'amount' ? (
				<div ref={main} style={{ position: 'absolute', width: '100%' }}>
					<span
						style={{ position: 'absolute', marginLeft: budgetWidth - 7.5, top: '40px' }}
						onMouseMove={(e) => setSection('Budget')}
					>
						<CaretUpOutlined color={'black'} />
					</span>
				</div>
			) : null}
		</div>
	);
}
function isChildElement(el, parentEl, maxI = 10, currentI = 0) {
	if (el && parentEl && el.isEqualNode(parentEl)) {
		return true;
	} else {
		return maxI > currentI ? isChildElement(el.parentNode, parentEl, maxI, currentI + 1) : false;
	}
}

function MouseHoverToolTip(props) {
	return (
		<div
			style={{
				...props.style,
				position: 'fixed',
				width: 'max-content',
				backgroundColor: 'white',
				padding: '12px',
				zIndex: '1000',
				left: (props.coords.x + 8).toString().concat('px'),
				top: (props.coords.y + 8).toString().concat('px'),
				boxShadow: '0px 0px 40px 20px rgba(0,0,0,.04)',
			}}
		>
			{props.basis === 'amount' ? (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						padding: '4px',
						fontWeight: props.currentSection === 'Budget' ? 800 : 400,
					}}
				>
					<div
						style={{
							display: 'flex',
							width: '20px',
							height: '20px',
							backgroundColor: 'white',
							padding: '3px',
						}}
					>
						<CaretUpOutlined color={'black'} />
					</div>
					<div
						style={{
							display: 'flex',
							width: '80px',
							marginLeft: '12px',
						}}
					>
						Budget
					</div>
					<div style={{ display: 'flex', marginLeft: '16px' }}>
						{parseFloat(props.budget).toLocaleString('en-US', {
							style: 'currency',
							currency: 'USD',
						})}
					</div>
				</div>
			) : null}
			{props.sections
				.filter((section) => section.display)
				.map((section) => {
					return (
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								padding: '4px',
								borderTop: section.label === 'Remainder' ? '1px solid black' : null,
								fontWeight: props.currentSection === section.label ? 800 : 400,
							}}
						>
							<div
								style={{
									display: 'flex',
									width: '20px',
									height: '20px',
									backgroundColor: section.color,
								}}
							></div>
							<div style={{ display: 'flex', width: '80px', marginLeft: '12px' }}>
								{section.label}
							</div>
							<div style={{ display: 'flex', marginLeft: '16px' }}>
								{props.basis === 'amount'
									? parseFloat(section.quantity).toLocaleString('en-US', {
											style: 'currency',
											currency: 'USD',
									  })
									: section.quantity}{' '}
							</div>
						</div>
					);
				})}
			{props.basis === 'amount' && props.isOverBudget == true ? (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						padding: '4px',
						borderTop: '1px solid rgb(100,100,100)',
					}}
				>
					<div
						style={{
							display: 'flex',
							width: '20px',
							height: '20px',
							backgroundColor: 'white',
						}}
					></div>
					<div style={{ display: 'flex', width: '80px', marginLeft: '12px' }}>Over-Budget</div>
					<div style={{ display: 'flex', marginLeft: '16px' }}>
						{parseFloat(props.overAmount).toLocaleString('en-US', {
							style: 'currency',
							currency: 'USD',
						})}
					</div>
				</div>
			) : null}
		</div>
	);
}

import React, { FC, useMemo } from 'react';

const TruncateText: FC<any> = ({ text, length, tooltip }): React.ReactElement => {
	const trucateSize = useMemo(() => length || text.length, [text, length]);

	return text.length > trucateSize ? (
		<span title={tooltip ? text : ''}>{`${text.substring(0, trucateSize)}...`}</span>
	) : (
		<span>{text}</span>
	);
};

export default TruncateText;

import React, { FC, useMemo } from 'react';
import { STOPLIGHT_COLORS } from '../../utils/DataFormatterUtils';

const ShipmentStatusDisplay: FC<any> = ({ shipped }): React.ReactElement => {
	const style = useMemo(
		() =>
			shipped
				? {
						background: '#e6f9e7',
						color: STOPLIGHT_COLORS.pureGreen,
				  }
				: {
						background: '#e8def8',
						color: '#4a1e87',
				  },
		[shipped]
	);

	return (
		<span
			style={{
				...style,
				padding: '4px 12px',
				borderRadius: 16,
				whiteSpace: 'nowrap',
			}}
		>
			{shipped ? 'Shipped' : 'Not Shipped'}
		</span>
	);
};

export default ShipmentStatusDisplay;

import React, { FC } from 'react';
import { Select } from 'antd';

interface IOWSelect {
	items: any[];
	value?: any;
	valueAccessor: (x: any) => any;
	onChange?: (val: any) => void;
	renderRecord: (x: any) => any;
	disabled?: boolean;
	className?: any;
	mode?: any;
}

const OWSelect: FC<IOWSelect> = ({
	items,
	value,
	valueAccessor,
	onChange,
	renderRecord,
	disabled,
	className,
	mode,
}): React.ReactElement => {
	return (
		<div className={`${className ? className : ''} flex flex-row items-center`}>
			<Select mode={mode} disabled={disabled} onChange={onChange} value={value}>
				{items.map((item, idx) => (
					<Select.Option value={valueAccessor(item)} key={idx}>
						{renderRecord(item)}
					</Select.Option>
				))}
			</Select>
		</div>
	);
};

export default OWSelect;

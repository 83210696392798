import { FC } from 'react';
import { ContactAvatar } from '../../contact_avatar/ContactAvatar';
import { Col, Row } from 'antd';
import * as React from 'react';
import { nullSafeGet, nullSafeGetOrElse } from '../../../utils/DataAccessUtils';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';

interface UserCardProps {
	contact: any;
	userType: any;
	name: any;
	subTitle?: string;
	showAvatar?: boolean;
	size?: 'default' | 'large';
	mode?: 'full' | 'simple' | 'avatar';
	details?: any;
}

function formatPhoneNumber(phoneNumberString) {
	var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		var intlCode = match[1] ? '+1 ' : '';
		return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
	}
	return null;
}

const TITLE_DISPLAY = {
	key: 'title',
	display: 'Title',
	formatValue: (value) => value,
};
const DEPARTMENT_DISPLAY = {
	key: 'department',
	display: 'Department',
	formatValue: (value) => value,
};
const MOBILE_PHONE_DISPLAY = {
	key: 'phoneMobile',
	display: 'Mobile',
	formatValue: (value) => formatPhoneNumber(value),
};
const WORK_PHONE_DISPLAY = {
	key: 'phoneWork',
	display: 'Work',
	formatValue: (value) => formatPhoneNumber(value),
};
const EMAIL_DISPLAY = {
	key: 'email',
	display: 'Email',
	formatValue: (value) => value,
};

export const UserCard: FC<UserCardProps> = ({
	contact,
	userType,
	name,
	subTitle,
	showAvatar = true,
	size = 'default',
	mode = 'simple',
	details = [TITLE_DISPLAY, DEPARTMENT_DISPLAY, MOBILE_PHONE_DISPLAY, WORK_PHONE_DISPLAY],
}) => {
	const onClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const email = nullSafeGet('email', contact);
	const detailList = details
		.filter((obj) => !!contact.hasOwnProperty(obj.key) && contact[obj.key])
		.map((obj) => ({
			...obj,
			value: obj.hasOwnProperty('formatValue')
				? obj.formatValue(contact[obj.key])
				: contact[obj.key],
		}));

	return mode === 'avatar' ? (
		<ContactAvatar
			hidePopover={true}
			style={{
				fontSize: size === 'large' ? 17.5 : 14,
				minWidth: size === 'large' ? 40 : 32,
			}}
			size={size === 'large' ? 40 : 32}
			contact={contact}
			userType={userType}
		/>
	) : (
		<span
			onClick={onClick}
			style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}
		>
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				{showAvatar ? (
					<ContactAvatar
						hidePopover={true}
						style={{
							marginRight: 8,
							fontSize: size === 'large' ? 17.5 : 14,
							minWidth: size === 'large' ? 40 : 32,
						}}
						size={size === 'large' ? 40 : 32}
						contact={contact}
						userType={userType}
					/>
				) : null}
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
					<Row
						style={{
							justifyContent: 'flex-start',
							color: 'rgba(0,0,0,0.65)',
							fontSize: size === 'large' || !subTitle ? 17.5 : 14,
						}}
					>
						<Ellipsis fullWidthRecognition={true} lines={1}>
							{name}
						</Ellipsis>
					</Row>
					<Row
						style={{
							justifyContent: 'flex-start',
							color: 'rgba(0,0,0,0.45)',
							fontSize: size === 'large' ? 14 : 12,
						}}
					>
						<Ellipsis fullWidthRecognition={true} lines={1}>
							{subTitle}
						</Ellipsis>
					</Row>
				</div>
			</div>
			{mode === 'full' && detailList.length > 0 ? (
				<Row
					style={{
						gap: 8,
						borderTop: '1px solid #e8e8e8',
						width: '100%',
						marginTop: 4,
						paddingTop: 4,
					}}
				>
					<Col>
						{detailList.map((obj) => (
							<Row
								style={{
									color: 'rgba(0,0,0,0.45)',
									fontSize: size === 'large' ? 14 : 12,
									justifyContent: 'flex-end',
								}}
							>
								{obj.display}
							</Row>
						))}
					</Col>
					<Col>
						{detailList.map((obj) => (
							<Row
								style={{
									color: 'rgba(0,0,0,0.65)',
									fontSize: size === 'large' ? 14 : 12,
								}}
							>
								<Ellipsis fullWidthRecognition={true} lines={1}>
									{obj.value}
								</Ellipsis>
							</Row>
						))}
					</Col>
				</Row>
			) : null}
		</span>
	);
};

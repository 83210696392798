import { PURCHASE_REQUESTS_CRUD_ACTION_CREATORS } from '../actions/purchase_requests_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { getBackendUri } from '../utils/EnvConfigUtils';
import FetchUtils from './fetch_utils';
import { parseJSON } from './fetch_utils';
import PRPipeLineFilters from '../components/purchase_requests_index_page/PRPipelineFilters';
import { message } from 'antd';

const fetchUtils = new FetchUtils();
const BASE_URL = getBackendUri();

//export const purchaseRequestsRestCrudThunksForBuyer = new RestCrudThunks('purchase_requests', 'purchase_request', 'purchaseRequest', 'purchaseRequests', PURCHASE_REQUESTS_CRUD_ACTION_CREATORS, null, ROLE_TYPES.BUYER);
export const purchaseRequestsRestCrudThunksForSupplier = new RestCrudThunks(
	'purchase_requests',
	'purchase_request',
	'purchaseRequest',
	'purchaseRequests',
	PURCHASE_REQUESTS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'inventory/'
);

export const purchaseRequestsLiteRestCrudThunksForSupplier = new RestCrudThunks(
	'purchase_requests_lite',
	'purchase_request',
	'purchaseRequest',
	'purchaseRequests',
	PURCHASE_REQUESTS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'inventory/'
);

export const purchaseRequestStatusChangeForSupplier = (
	id,
	status,
	equipmentReceiptValues = undefined
) => {
	const apiUrl = `/api/v1/supplier/inventory/purchase_requests`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.patch(
					BASE_URL + apiUrl + `/${id}/${status}`,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: { equipmentReceiptValues },
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (d.status === 'error') {
						dispatch(PURCHASE_REQUESTS_CRUD_ACTION_CREATORS.updateError(d.message, id));
						reject(d.message);
					} else {
						dispatch(PURCHASE_REQUESTS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(PURCHASE_REQUESTS_CRUD_ACTION_CREATORS.updateError(d.message, id));
					reject(d.message);
				});
		});
	};
};

export const fetchAssociatedPRsOfPurchaseOrderForSupplier = (poId) => {
	// if pagination present, transform into limit/offset format and add to query params

	const apiUrl = `/api/v1/supplier/inventory/purchase_requests/from_po_id/${poId}`;

	return new Promise((resolve, reject) => {
		fetchUtils
			.get(
				BASE_URL + apiUrl,
				Object.assign({
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
				})
			)
			.then(parseJSON)
			.then(({ json, status, ok }) => {
				const d = json;
				if (d.status === 'error') {
					reject(d.message);
				} else {
					resolve(d.data);
				}
			})
			.catch((d) => {
				reject(d.message);
			});
	});
};

export const refreshPRCountsForSupplier = (filters) => (dispatch) => {
	const apiUrl = `/api/v1/supplier/inventory/purchase_requests/count_by_status`;

	const data = {
		...(filters || {}),
		statuses: PRPipeLineFilters.map((_) => _.name).join(','),
		status: undefined,
	};
	fetchUtils
		.get(
			BASE_URL + apiUrl,
			Object.assign(
				{
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
				},
				data
			)
		)
		.then(parseJSON)
		.then(({ json, status, ok }) => {
			const d = json;
			if (d.status === 'error') {
				message.error(d.message);
			} else {
				if (d.data && d.data.length > 0) {
					d.data.map((category) =>
						dispatch(
							PURCHASE_REQUESTS_CRUD_ACTION_CREATORS.countBySuccess(
								category.count,
								category.categoryName
							)
						)
					);
				}
			}
		})
		.catch((d) => {
			message.error(d.message);
		});
};

export const bulkApprovePurchaseRequestForSupplier = (ids) => {
	const apiUrl = `/api/v1/supplier/inventory/purchase_requests/bulk_approve/${ids.join(',')}`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.put(
					BASE_URL + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (d.status === 'error') {
						dispatch(PURCHASE_REQUESTS_CRUD_ACTION_CREATORS.updateError(d.message, {}));
						reject(d.message);
					} else {
						dispatch(PURCHASE_REQUESTS_CRUD_ACTION_CREATORS.updateBulkSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(PURCHASE_REQUESTS_CRUD_ACTION_CREATORS.updateError(d.message, {}));
					reject(d.message);
				});
		});
	};
};

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button } from 'antd';
import {
	assetTypesRestCrudThunksForBuyer,
	assetTypesRestCrudThunksForSupplier,
	downloadAssetTypesCSVForBuyer,
	downloadAssetTypesCSVForSupplier,
} from '../../thunks/asset_types_thunks';
import { EmptyState } from '../empty_state/EmptyState';
import { ASSET_TYPES_CRUD_ACTION_CREATORS } from '../../actions/asset_types_actions';
import { ROLE_TYPES } from '../../utils/DataConstants';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import {
	depreciationClassesRestCrudThunksForBuyer,
	depreciationClassesRestCrudThunksForSupplier,
} from '../../thunks/depreciation_class_thunks';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { DownloadOutlined } from '@ant-design/icons';

const ASSET_TYPES_OVERVIEW_TARGET_COLLECTION = 'assetTypesIndex';

require('./AssetTemplatesIndexPage.less');

const AssetTemplatesIndexPage: FC<any> = ({
	location,
	updateAssetTypesFilters,
	history,
	clearAndUpdateFilters,
	assetTypes,
	userType,
	depreciationClasses,
	fetchDepreciationClasses,
	fetchMultipleDepreciationClasses,
	downloadAssetTypesCSV,
}): React.ReactElement => {
	const [showDCFilter, setShowDCFilter] = useState(false);

	useEffect(() => {
		fetchDepreciationClasses({}, null, { pageSize: 1 }).then(
			(res) => nullSafeGet('length', res) > 0 && setShowDCFilter(true)
		);
	}, []);

	const onRow = useCallback(
		(record) => ({
			onClick: () => {
				const backUrl = `/${userType}/assets/templates/detail/${record.id}/details`;
				history.push(getLinkWIthBackLinkParams(location, 'Back to Asset Type', backUrl));
			},
		}),
		[history, location, userType]
	);

	const downloadCSV = useCallback(() => {
		const filters = assetTypes[ASSET_TYPES_OVERVIEW_TARGET_COLLECTION].filters;
		downloadAssetTypesCSV({}, filters);
	}, []);

	const columns = useMemo(
		() => [
			{
				render: (_, record) => (
					<div className="flex flex-col">
						{record.name}
						<div className="text-m text-gray-400">
							{nullSafeGet('depreciationClass.name', record)
								? `Depreciation Class : ${nullSafeGet('depreciationClass.name', record)}`
								: null}
						</div>
					</div>
				),
			},
		],
		[]
	);

	const filterConfig = useMemo(
		() => [
			...(showDCFilter
				? [
						{
							label: 'Depreciation Class',
							fieldName: 'depreciationClassIds',
							mode: 'multiple',
							stateSlice: depreciationClasses,
							targetCollectionName: 'assetTypesAdvancedFilters',
							fetch: fetchDepreciationClasses,
							fetchMultiple: fetchMultipleDepreciationClasses,
						},
				  ]
				: []),
		],
		[depreciationClasses, fetchDepreciationClasses, fetchMultipleDepreciationClasses, showDCFilter]
	);

	return (
		<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_ASSET_TYPES} isPage>
			<PaginatedReduxTableWithHeader
				targetCollectionName={ASSET_TYPES_OVERVIEW_TARGET_COLLECTION}
				updateFilters={updateAssetTypesFilters}
				stateSlice={assetTypes}
				clearAndUpdateFilters={clearAndUpdateFilters}
				filterConfig={filterConfig}
				entityCollectionName="asset_types"
				tableColumns={columns}
				onTableRow={onRow}
				initialPagination={{ current: 1, pageSize: 10 }}
				fetchData={
					userType === ROLE_TYPES.SUPPLIER
						? assetTypesRestCrudThunksForSupplier.read
						: assetTypesRestCrudThunksForBuyer.read
				}
				rightActions={
					<div>
						<Button
							style={{ marginRight: 12 }}
							size="large"
							icon={<DownloadOutlined translate="" />}
							className="inline-block-visible-md"
							onClick={downloadCSV}
						>
							<span className="inline-block-visible-xxl">Export CSV</span>
						</Button>

						<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_ASSETS}>
							<Button
								type="primary"
								size="large"
								onClick={() => history.push(`/${userType}/assets/overview/templates/new`)}
							>
								Add Asset Type
							</Button>
						</AccessPermissionChecker>
					</div>
				}
				emptyState={
					<EmptyState
						graphic={
							<img
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
								alt="Looks like your team has not added any asset templates yet."
							/>
						}
						headline={"It's a little lonely in here."}
						body={
							<div style={{ textAlign: 'center' }}>
								<div style={{ maxWidth: 440, marginBottom: 16 }}>
									Looks like your team has not added any asset templates yet.
								</div>
								<Button
									type="primary"
									onClick={() => history.push(`/${userType}/assets/overview/templates/new`)}
								>
									Add your first asset type
								</Button>
							</div>
						}
					/>
				}
			/>
		</AccessPermissionChecker>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	assetTypes: state.asset_types,
	depreciationClasses: state.depreciation_classes,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	updateAssetTypesFilters: (filters, targetCollection) =>
		dispatch(ASSET_TYPES_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(ASSET_TYPES_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)),
	fetchDepreciationClasses: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) => {
		const fnToCall =
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? depreciationClassesRestCrudThunksForSupplier.readLite
				: depreciationClassesRestCrudThunksForBuyer.readLite;
		return dispatch(
			fnToCall(
				{ ...params, no_pagination: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		);
	},
	fetchMultipleDepreciationClasses: (ids, targetCollectionName) =>
		dispatch(depreciationClassesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	downloadAssetTypesCSV: (params, filters) => {
		const fnToCall =
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? downloadAssetTypesCSVForSupplier
				: downloadAssetTypesCSVForBuyer;
		return dispatch(fnToCall(params, filters));
	},
});

const AssetTemplatesPage = withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(AssetTemplatesIndexPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(AssetTemplatesPage);

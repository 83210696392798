import * as React from 'react';
import { BLACKS_AND_WHITES } from '../../utils/DataFormatterUtils';
import {
	PURCHASE_ORDER_STATUS_DISPLAY_BACKGROUND_COLORS,
	PURCHASE_ORDER_STATUS_DISPLAY_TEXT_COLORS,
	PURCHASE_ORDER_STATUSES,
} from '../../constants/PurchaseOrderStatuses';
import { PURCHASE_ORDER_STATUS_DISPLAY_NAMES } from '../../constants/PurchaseOrderStatuses';

const PurchaseOrderStatusDisplay = ({
	status,
	darkMode = false,
	style = {},
	textStyle = {},
	...props
}) => {
	if (!Object.keys(PURCHASE_ORDER_STATUSES).includes(status)) {
		return null;
	}
	let statusColor = PURCHASE_ORDER_STATUS_DISPLAY_TEXT_COLORS[status];
	let statusDisplayName = PURCHASE_ORDER_STATUS_DISPLAY_NAMES[status];
	let statusBackgroundColor = PURCHASE_ORDER_STATUS_DISPLAY_BACKGROUND_COLORS[status];

	if (darkMode) {
		statusColor = BLACKS_AND_WHITES.whiteSecondary;
	}

	return (
		<span
			{...props}
			style={{
				color: statusColor,
				background: statusBackgroundColor,
				padding: '4px 12px',
				borderRadius: 16,
				whiteSpace: 'nowrap',
				...style,
				...textStyle,
			}}
		>
			{statusDisplayName}
		</span>
	);
};

export default PurchaseOrderStatusDisplay;

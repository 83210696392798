import { UNITS_OF_MEASURE_CRUD_ACTION_CREATORS } from '../actions/units_of_measure_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';

export const unitsOfMeasureRestCrudThunksForBuyer = new RestCrudThunks(
	'units_of_measure',
	'unit_of_measure',
	'unitOfMeasure',
	'unitsOfMeasure',
	UNITS_OF_MEASURE_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER
);
export const unitsOfMeasureRestCrudThunksForSupplier = new RestCrudThunks(
	'units_of_measure',
	'unit_of_measure',
	'unitOfMeasure',
	'unitsOfMeasure',
	UNITS_OF_MEASURE_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER
);

import React, { FC } from 'react';
import {
	capitalize,
	capitalizeEachWord,
	dateFormatter,
	getInitial,
} from '../../utils/DataFormatterUtils';
import Rate from 'antd/es/rate';
import { ContactAvatar } from '../contact_avatar/ContactAvatar';

export const SupplierReview: FC<any> = (props) => {
	const { review, userType } = props;

	return (
		<div>
			<div style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
				<div>
					<ContactAvatar
						hidePopover={true}
						style={{ marginRight: 16 }}
						contact={review.buyerContact}
						userType={userType}
					/>
				</div>
				<div style={{ marginLeft: 8 }}>
					<div style={{ fontSize: 16 }}>
						{capitalize(review.buyerContact.nameGiven)}{' '}
						{capitalize(getInitial(review.buyerContact.nameFamily))}.
					</div>

					{review.buyerContact.title && review.buyerContact.department ? (
						<div style={{ color: 'rgba(0,0,0,0.45)' }}>
							{capitalizeEachWord(review.buyerContact.title)},{' '}
							{capitalizeEachWord(review.buyerContact.department)}
						</div>
					) : null}
				</div>
			</div>
			<div style={{ marginBottom: 4 }}>
				<Rate
					style={{ minWidth: 120, fontSize: 15 }}
					disabled={true}
					allowHalf={true}
					key={review.rating}
					defaultValue={review.rating}
				/>
				<span style={{ fontSize: 16, fontWeight: 'bold' }}>{review.reviewTitle}</span>
			</div>
			<div style={{ marginBottom: 8 }}>
				<span>{dateFormatter(review.createdAt)}</span>
			</div>
			<div style={{ fontSize: 16 }}>{review.review}</div>
		</div>
	);
};

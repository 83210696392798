import { getBackendUri } from '../utils/EnvConfigUtils';
import FetchUtils, { parseJSON } from './fetch_utils';
import {
	fetchCreditsError,
	fetchCreditsStart,
	fetchCreditsSuccess,
} from '../actions/credits_actions';
import { logoutSuccess } from '../actions/session_actions';

const baseUrl = getBackendUri();
const apiUrl = '/api/v1/supplier/credits';
const fetchUtils = new FetchUtils();

export function buyCredits(customerId: string, numCredits: number) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(fetchCreditsStart());

			fetchUtils
				.post(
					baseUrl + `/api/v1/supplier/charge_payments/${customerId}/amount/${numCredits}`,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					const d = json;
					if (d.status === 'error') {
						dispatch(fetchCreditsError(d.message));
						reject(d.message);
					} else {
						dispatch(fetchCreditsSuccess(d.data.totalCreditsBought, d.data.availableCredits));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(fetchCreditsError(d.message));
					reject(d.message);
				});
		});
	};
}

export function fetchCredits() {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(fetchCreditsStart());

			fetchUtils
				.get(
					baseUrl + apiUrl + '/get_details',
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					const d = json;
					if (d.status === 'error') {
						dispatch(fetchCreditsError(d.message));
						reject(d.message);
					} else {
						dispatch(fetchCreditsSuccess(d.data.totalCreditsBought, d.data.availableCredits));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(fetchCreditsError(d.message));
					reject(d.message);
				});
		});
	};
}

export function addCredits(numCredits: number) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(fetchCreditsStart());

			fetchUtils
				.post(
					baseUrl + apiUrl + `/buy/${numCredits}`,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					const d = json;
					if (d.status === 'error') {
						dispatch(fetchCreditsError(d.message));
						reject(d.message);
					} else {
						dispatch(fetchCreditsSuccess(d.data.totalCreditsBought, d.data.availableCredits));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(fetchCreditsError(d.message));
					reject(d.message);
				});
		});
	};
}

export function useCredits(numCredits: number) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(fetchCreditsStart());

			fetchUtils
				.post(
					baseUrl + apiUrl + `/use/${numCredits}`,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					const d = json;
					if (d.status === 'error') {
						dispatch(fetchCreditsError(d.message));
						reject(d.message);
					} else {
						dispatch(fetchCreditsSuccess(d.data.totalCreditsBought, d.data.availableCredits));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(fetchCreditsError(d.message));
					reject(d.message);
				});
		});
	};
}

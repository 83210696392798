import matchAll from 'string.prototype.matchall';
import { nullSafeGet } from './DataAccessUtils';

/**
 * RegEx grouped results. Example - "@[Full Name](123abc)"
 * We have 4 groups here:
 * - The whole original string - "@[Full Name](123abc)"
 * - Mention trigger - "@"
 * - Name - "Full Name"
 * - Id - "123abc"
 */
const mentionRegEx = /((.)\[([^[]*)]\(([^(^)]*)\))/gi;

const defaultPlainStringGenerator = ({ trigger }, { name }) => `${trigger}${name}`;

const isMentionPartType = (partType) => {
	return partType.trigger != null;
};

/**
 * Method for generating part for plain text
 *
 * @param text - plain text that will be added to the part
 * @param positionOffset - position offset from the very beginning of text
 */
const generatePlainTextPart = (text: string, positionOffset = 0) => ({
	text,
	position: {
		start: positionOffset,
		end: positionOffset + text.length,
	},
});

/**
 * Method for generating part for mention
 *
 * @param mentionPartType
 * @param mention - mention data
 * @param positionOffset - position offset from the very beginning of text
 */
const generateMentionPart = (mentionPartType, mention, positionOffset = 0) => {
	const text = mentionPartType.getPlainString
		? mentionPartType.getPlainString(mention)
		: defaultPlainStringGenerator(mentionPartType, mention);

	return {
		text,
		position: {
			start: positionOffset,
			end: positionOffset + text.length,
		},
		partType: mentionPartType,
		data: mention,
	};
};

/**
 * Generates part for matched regex result
 *
 * @param partType - current part type (pattern or mention)
 * @param result - matched regex result
 * @param positionOffset - position offset from the very beginning of text
 */
const generateRegexResultPart = (partType, result, positionOffset = 0) => ({
	text: result[0],
	position: {
		start: positionOffset,
		end: positionOffset + result[0].length,
	},
	partType,
});

// /**
//  * Method for generation mention value that accepts mention regex
//  *
//  * @param trigger
//  * @param suggestion
//  */
// const getMentionValue = (trigger: string, suggestion: Suggestion) => `${trigger}[${suggestion.name}](${suggestion.id})`;

const getMentionDataFromRegExMatchResult = ([, original, trigger, name, id]) => ({
	original,
	trigger,
	name,
	id,
});

/**
 * Recursive function for deep parse MentionInput's value and get plainText with parts
 *
 * @param value - the MentionInput's value
 * @param partTypes - All provided part types
 * @param positionOffset - offset from the very beginning of plain text
 */
const parseValue = (value, partTypes, positionOffset = 0) => {
	if (value == null) {
		value = '';
	}

	let plainText = '';
	let parts = [];

	// We don't have any part types so adding just plain text part
	if (partTypes.length === 0) {
		plainText += value;
		parts.push(generatePlainTextPart(value, positionOffset));
	} else {
		const [partType, ...restPartTypes] = partTypes;

		const regex = isMentionPartType(partType) ? mentionRegEx : partType.pattern;

		const matches: any = Array.from(matchAll(value || '', regex));

		// In case when we didn't get any matches continue parsing value with rest part types
		if (matches.length === 0) {
			return parseValue(value, restPartTypes, positionOffset);
		}

		// In case when we have some text before matched part parsing the text with rest part types
		if (matches[0].index != 0) {
			const text = value.substr(0, matches[0].index);

			const plainTextAndParts = parseValue(text, restPartTypes, positionOffset);
			parts = parts.concat(plainTextAndParts.parts);
			plainText += plainTextAndParts.plainText;
		}

		// Iterating over all found pattern matches
		for (let i = 0; i < matches.length; i++) {
			const result = matches[i];

			if (isMentionPartType(partType)) {
				const mentionData = getMentionDataFromRegExMatchResult(result);

				// Matched pattern is a mention and the mention doesn't match current mention type
				// We should parse the mention with rest part types
				if (mentionData.trigger !== partType.trigger) {
					const plainTextAndParts = parseValue(
						mentionData.original,
						restPartTypes,
						positionOffset + plainText.length
					);
					parts = parts.concat(plainTextAndParts.parts);
					plainText += plainTextAndParts.plainText;
				} else {
					const part: any = generateMentionPart(
						partType,
						mentionData,
						positionOffset + plainText.length
					);

					parts.push(part);

					plainText += part.text;
				}
			} else {
				const part = generateRegexResultPart(partType, result, positionOffset + plainText.length);

				parts.push(part);

				plainText += part.text;
			}

			// Check if the result is not at the end of whole value so we have a text after matched part
			// We should parse the text with rest part types
			if (result.index + result[0].length !== value.length) {
				// Check if it is the last result
				const isLastResult = i === matches.length - 1;

				// So we should to add the last substring of value after matched mention
				const text = value.slice(
					result.index + result[0].length,
					isLastResult ? undefined : matches[i + 1].index
				);

				const plainTextAndParts = parseValue(
					text,
					restPartTypes,
					positionOffset + plainText.length
				);
				parts = parts.concat(plainTextAndParts.parts);
				plainText += plainTextAndParts.plainText;
			}
		}
	}

	// Exiting from parseValue
	return {
		plainText,
		parts,
	};
};

// /**
//  * Function for generation value from parts array
//  *
//  * @param parts
//  */
// const getValueFromParts = (parts) => parts
//   .map(item => (item.data ? item.data.original : item.text))
//   .join('');

/**
 * Replace all mention values in value to some specified format
 *
 * @param value - value that is generated by MentionInput component
 * @param replacer - function that takes mention object as parameter and returns string
 */
const replaceMentionValues = (value: string, replacer: (mention) => string) =>
	value.replace(mentionRegEx, (fullMatch, original, trigger, name, id) =>
		replacer({
			original,
			trigger,
			name,
			id,
		})
	);

const parseMessageToElements = (messageText): any => {
	const { plainText: text, parts } = parseValue(messageText || '', [
		{
			trigger: '@',
		},
	]);

	const elements = parts.map((part) => {
		if (nullSafeGet('partType.trigger', part) === '@') {
			// user mention
			return { type: 'user', value: nullSafeGet('data.id', part) };
		}
		return { type: 'text', value: part.text };
	});
	return { text, elements };
};

export { parseValue, mentionRegEx, replaceMentionValues, parseMessageToElements };

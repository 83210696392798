import { PAYMENTS_ERROR, PAYMENTS_START, PAYMENTS_SUCCESS } from '../actions/payments_actions';
import { PaymentsStoreState } from '../types/models';

const initialState: PaymentsStoreState = {
	paymentToken: null,
	customerId: null,
	loading: false,
	errors: [],
};

export default (oldState = initialState, action): PaymentsStoreState => {
	Object.freeze(oldState);
	switch (action.type) {
		case PAYMENTS_START:
			return {
				...oldState,
				loading: true,
			};
		case PAYMENTS_ERROR:
			return {
				...oldState,
				errors: [action.error],
				loading: false,
			};
		case PAYMENTS_SUCCESS:
			return {
				...oldState,
				paymentToken: action.paymentToken,
				customerId: action.customerId,
				loading: false,
			};
		default:
			return oldState;
	}
};

import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { equipmentPerStockLocationsRestCrudThunksForSupplier } from '../../thunks/equipment_per_stock_locations_thunks';

const EquipmentPerStockLocationDetailPage: FC<any> = ({
	companyConfig,
	fetching,
	epsl,
	childRoutes,
	history,
	match,
	getEpsl,
}): React.ReactElement => {
	const tabs = useMemo(
		() => [
			{
				key: 'details',
				name: 'Overview',
			},
			{
				key: 'workOrders',
				name: 'Work Orders',
			},
		],
		[]
	);

	const epslId = useMemo(() => match.params.id, [match.params.id]);

	useEffect(() => epslId && getEpsl(epslId), [epslId, getEpsl]);

	const handleTabChange = useCallback(
		(key) => {
			const epslId = match.params.id;
			history.push(`/supplier/equipments/equipmentPerStockLocation/detail/${epslId}/${key}`);
		},
		[history, match.params.id]
	);

	const checkTabActive = useCallback(
		(key) => {
			const pathname = history.location.pathname;
			return pathname.indexOf(key) !== -1;
		},
		[history.location.pathname]
	);

	return (
		<DetailPageHeader
			exactPath={'/supplier/equipments/equipmentPerStockLocation/detail/:id'}
			redirectPath={`/supplier/equipments/equipmentPerStockLocation/detail/${epslId}/details`}
			backLinkText={'Back to Equipment'}
			backLinkTo={`/supplier/equipments/overview/all`}
			title={nullSafeGetOrElse('name', epsl, '')}
			subtitle={`@ ${nullSafeGetOrElse('stockLocation.name', epsl, '')}`}
			actions={null}
			tabs={tabs}
			backgroundColor={nullSafeGet('detail.config.theme.navblock', companyConfig)}
			handleTabChange={handleTabChange}
			checkTabActive={checkTabActive}
			childRoutes={childRoutes}
		/>
	);
};

const mapStateToProps = (state) => ({
	companyConfig: state.company_config,
	fetching: state.equipment_per_stock_locations.fetching,
	epsl: state.equipment_per_stock_locations.detail,
});

const mapDispatchToProps = (dispatch) => ({
	getEpsl: (id) => dispatch(equipmentPerStockLocationsRestCrudThunksForSupplier.readOne(id)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(EquipmentPerStockLocationDetailPage)
);

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import '@ant-design/compatible/assets/index.css';
import { Layout, Row, Col, Card } from 'antd';
import { TextButton } from '../text_button/TextButton';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { resendConfirmation } from '../../thunks/session_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;
const style = require('./BuyerNewAccountSetupCompletionPage.less');

class BuyerNewAccountSetupCompletionPage extends React.Component<any, any> {
	resendConfirmation = () => {
		const { resendEmailAuthentication } = this.props;
		resendEmailAuthentication(ROLE_TYPES.BUYER);
	};

	render() {
		const { currentUser } = this.props;
		return (
			<Content className="buyerNewAccountSetupCompletionPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited buyer new account setup completion page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<Card>
							<div style={{ padding: 24 }}>
								<h4>Hang on, {currentUser.nameGiven}. Just one more quick step!</h4>
								<p style={{ fontSize: 18, maxWidth: 640 }}>
									You should be receiving an email from us any minute now asking you to verify your
									account. It will look something like this:
								</p>
								<img
									style={{ marginBottom: 18 }}
									width="100%"
									height="auto"
									src="https://s3.amazonaws.com/emptor-data-assets/images/emptor-landing-page/confirmemailscreenshot.png"
								/>
								<p style={{ fontSize: 18, maxWidth: 640 }}>
									Please follow the link in the email so we know you are who you say you are.
								</p>
								<p style={{ fontSize: 18, maxWidth: 640 }}>
									Don't see the email? Check your <b>spam folder</b> or{' '}
									<TextButton onClick={this.resendConfirmation}>
										click here to request a new activation link.
									</TextButton>
								</p>
							</div>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	resendEmailAuthentication: (role) => dispatch(resendConfirmation(role)()),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(BuyerNewAccountSetupCompletionPage)
);

import * as React from 'react';
import { Layout, Button } from 'antd';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';
import SubnavTabs from '../subnav_tabs/SubnavTabs';
import { locationsRestCrudThunksForSupplier } from '../../thunks/locations_thunks';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import {
	getProtectedImageUriForSupplier,
	getProtectedImageUriFromString,
} from '../../utils/FileAccessUtils';
import { ROLE_TYPES } from '../../utils/DataConstants';
import Carousel, { Modal as Lightbox, ModalGateway } from 'react-images';
import { generateBackLink } from '../../utils/HistoryUtils';
import { clearNavbarState, updateNavbarState } from '../../actions/navbar_actions';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import { Link } from 'react-router-dom';

require('./SupplierLocationsDetailPage.less');
const queryString = require('qs');
const { Content } = Layout;

class SupplierLocationsDetailPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			lightboxIsOpen: false,
			currentIndex: 0,
		};
	}

	handleViewChange = (idx) => {
		this.setState({ currentIndex: idx });
	};

	openLightbox = (currentIndex = 0) => {
		this.setState({ lightboxIsOpen: true, currentIndex });
	};

	closeLightbox = () => {
		this.setState({ lightboxIsOpen: false });
	};

	componentDidMount() {
		const { getLocation, updateNavbar, match, location } = this.props;
		const locationId = match.params.id;
		getLocation(locationId).then((woLocation) => {
			let searchString = location.search;
			if (searchString[0] === '?') {
				searchString = searchString.slice(1);
			}
			updateNavbar(
				'Back to Locations',
				generateBackLink('/supplier/locations/overview/all', location)
			);
			const queryParams = queryString.parse(searchString);
			if (queryParams.backlinkParams) {
				this.setState({
					loading: false,
					backLinkTo: `/supplier/locations/overview/all?${queryParams.backlinkParams}`,
				});
			} else {
				this.setState({
					loading: false,
					backLinkTo: `/supplier/locations/overview/all`,
				});
			}
		});
	}
	componentWillUnmount(): void {
		this.props.clearNavbar();
	}

	handleTabChange = (key) => {
		const { history, match } = this.props;
		const locationId = match.params.id;
		history.push(`/supplier/locations/detail/${locationId}/${key}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	render() {
		const { wolocation, match } = this.props;
		const locationId = match.params.id;
		const tabs = [
			{
				key: 'details',
				name: 'Overview',
			},
			{
				key: 'audit',
				name: 'Audit Logs',
			},
		];
		let photoUris = [];
		let lightboxPhotoUris = [];
		if (wolocation.images) {
			photoUris = wolocation.images.slice(0, 4).map((locImg) => {
				const [imgFileId, imgFileName] = locImg.split('/');
				return getProtectedImageUriForSupplier(imgFileId, imgFileName, 1200, 800);
			});
			lightboxPhotoUris = wolocation.images.map((photoString) => ({
				src: getProtectedImageUriFromString(ROLE_TYPES.SUPPLIER)(photoString, 2400, 1600),
			}));
		}
		return (
			<div>
				<Route
					path="/supplier/locations/detail/:id"
					exact={true}
					render={(props) => (
						<Redirect
							to={{
								pathname: `/supplier/locations/detail/${locationId}/details`,
							}}
						/>
					)}
				/>
				<Content className="supplierLocationsDetailPage">
					{/*<ScrollToTopOnMount/>*/}
					{/*<BackTop/>*/}
					<ModalGateway>
						{this.state.lightboxIsOpen ? (
							<Lightbox onClose={this.closeLightbox}>
								<Carousel
									trackProps={{
										onViewChange: this.handleViewChange,
									}}
									views={lightboxPhotoUris}
									currentIndex={this.state.currentIndex}
								/>
							</Lightbox>
						) : null}
					</ModalGateway>

					<div className="imageLayout">
						{photoUris.map((photoUri, idx) => (
							<div
								key={idx}
								className={`imageTile imageTile${idx}`}
								style={{ backgroundImage: `url(${photoUri})` }}
							/>
						))}
						<div
							className={`imageLayout__gradientOverlay ${
								wolocation.isActive ? '' : 'imageLayout__gradientOverlay--darker'
							}`}
						>
							{!wolocation.isActive ? <div className="locationInactiveLabel">Inactive</div> : null}
							<div className="supplierLocationsDetailPage__overtitle">
								{nullSafeGet('locationType.name', wolocation)} {nullSafeGet('storeId', wolocation)}
							</div>
							<h4 className="supplierLocationsDetailPage__title">{wolocation.name}</h4>
							<SubnavTabs
								tabs={tabs}
								onChange={this.handleTabChange}
								isActive={this.checkTabActive}
							/>
							<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_LOCATIONS}>
								<div style={{ position: 'absolute', top: 40, right: 40 }}>
									<Link to={`/supplier/locations/edit/${locationId}`} style={{ marginRight: 16 }}>
										<Button ghost={true}>Edit</Button>
									</Link>
								</div>
							</AccessPermissionChecker>
							<div style={{ position: 'absolute', bottom: 40, right: 40 }}>
								{lightboxPhotoUris.length > 0 ? (
									<Button
										style={{ color: 'white' }}
										type="ghost"
										onClick={() => this.openLightbox()}
									>
										View Photos
									</Button>
								) : null}
							</div>
						</div>
					</div>
					{this.props.childRoutes.map((r, i) => (
						<RouteWithSubRoutes key={i} {...r} />
					))}
				</Content>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	locationsFetching: state.locations.fetching,
	wolocation: state.locations.detail,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	getLocation: (id) => dispatch(locationsRestCrudThunksForSupplier.readOne(id)),
	updateNavbar: (backlinkTitle, backlinkTo, navbarBackgroundColor) =>
		dispatch(updateNavbarState(backlinkTitle, backlinkTo, navbarBackgroundColor)),
	clearNavbar: () => dispatch(clearNavbarState()),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SupplierLocationsDetailPage)
);

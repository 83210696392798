import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import '@ant-design/compatible/assets/index.css';

import { Col, ConfigProvider, Pagination, Row, Table } from 'antd';
import {
	fetchEntities,
	updatePaginationEntities,
	updateSortingEntities,
	updateFiltersEntities,
	replaceFiltersAndSortingEntities,
	clearFiltersEntities,
} from '../../thunks/thunks_wrapper';
import {
	changeFilterValueToArrayOfIds,
	changeFilterValueToArrayOfValues,
	getEndDateFieldName,
	getEntitiesById,
	getStartDateFieldName,
	getTupleNameFieldName,
	getTupleValueFieldName,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import { standardInitialStateGenerator } from '../../reducers/standard_reducer_utils';
import { PAGE_SIZE_OPTIONS } from '../../constants/pagination';
import { EmptyState } from '../empty_state/EmptyState';
import { PipelineFilter } from '../InvoicesTable/pipelineFilters/pipelineFilters';
import SubnavBar from '../subnav_bar/SubnavBar';
import { AdvancedFilters } from '../advanced_filters/AdvancedFilters';
import SelectedFilters from '../selected_filters_pills/SelectedFilters';
import { FILTER_FIELD_TYPE, FILTER_VALUE_TYPE } from '../../utils/DataConstants';
import { formatDateForParam } from '../../utils/DataFormatterUtils';
import moment from 'moment';
import OWDateRangePicker from '../ow_date_range_picker/OWDateRangePicker';
import OWAsyncTupleSelect from '../ow_async_select/OWAsyncTupleSelect';
import OWRadioGroup from '../ow_radio_group/OWRadioGroup';
import OWSelect from '../ow_radio_group/OWSelect';
import OWAsyncTreeSelect from '../ow_async_tree_select/OWAsyncTreeSelect';

const TARGETCOLLECTIONNAME = 'workOrdersTable';
const defaultPagination = {
	current: 1,
	pageSize: 10,
	pageSizeOptions: PAGE_SIZE_OPTIONS,
	showSizeChanger: true,
};

const getFilterValue = (val, config) => {
	switch (config.type) {
		case FILTER_FIELD_TYPE.RADIO_GROUP:
			return {
				[config.fieldName]: val === 'undefined' ? undefined : val,
			};
		case FILTER_FIELD_TYPE.DATE_RANGE:
			return {
				[getStartDateFieldName(config.fieldName)]:
					val && val.length > 0 && val[0] ? formatDateForParam(val[0]) : undefined,
				[getEndDateFieldName(config.fieldName)]:
					val && val.length > 1 && val[1] ? formatDateForParam(val[1]) : undefined,
			};
		case FILTER_FIELD_TYPE.TUPLE:
			return {
				[getTupleNameFieldName(config.fieldName)]:
					val && val.length > 0 && val[0] ? val[0] : undefined,
				[getTupleValueFieldName(config.fieldName)]:
					val && val.length > 1 && val[1] ? val[1] : undefined,
			};
		case FILTER_FIELD_TYPE.OW_MULTI_SELECT:
		default:
			return {
				[config.fieldName]: config.mode === 'multiple' ? val.join(',') || undefined : val,
			};
	}
};

const getFilterValueAccessor = (stateSlice, tcName, config) => {
	switch (config.type) {
		case FILTER_FIELD_TYPE.DATE_RANGE:
			const startDate = nullSafeGet(
				`${tcName}.filters.${getStartDateFieldName(config.fieldName)}`,
				stateSlice
			);
			const endDate = nullSafeGet(
				`${tcName}.filters.${getEndDateFieldName(config.fieldName)}`,
				stateSlice
			);
			return [startDate && moment(startDate), endDate && moment(endDate)];
		case FILTER_FIELD_TYPE.TUPLE:
			const name = nullSafeGet(
				`${tcName}.filters.${getTupleNameFieldName(config.fieldName)}`,
				stateSlice
			);
			const value = nullSafeGet(
				`${tcName}.filters.${getTupleValueFieldName(config.fieldName)}`,
				stateSlice
			);
			return [name, value];
		case FILTER_FIELD_TYPE.RADIO_GROUP:
			const filters = nullSafeGet(`${tcName}.filters`, stateSlice);
			return typeof filters[config.fieldName] === 'undefined'
				? 'undefined'
				: `${filters[config.fieldName]}`;
		case FILTER_FIELD_TYPE.OW_MULTI_SELECT:
		default:
			const val = nullSafeGet(`${tcName}.filters.${config.fieldName}`, stateSlice);
			const accesor =
				config.mode === 'multiple'
					? config.valueType === FILTER_VALUE_TYPE.STRING
						? changeFilterValueToArrayOfValues(val)
						: changeFilterValueToArrayOfIds(val)
					: val;
			return accesor;
	}
};

const valueAccessor = (config) => (entity) => {
	switch (config.type) {
		case FILTER_FIELD_TYPE.DATE_RANGE:
			return entity;
		case FILTER_FIELD_TYPE.OW_MULTI_SELECT:
		case FILTER_FIELD_TYPE.RADIO_GROUP:
			return entity.value;
		case FILTER_FIELD_TYPE.TUPLE:
		default:
			return entity.id;
	}
};

const renderItem = (config) => (entity) => {
	switch (config.type) {
		case FILTER_FIELD_TYPE.DATE_RANGE:
			return entity;
		case FILTER_FIELD_TYPE.OW_MULTI_SELECT:
		case FILTER_FIELD_TYPE.RADIO_GROUP:
			return entity.label;
		case FILTER_FIELD_TYPE.TUPLE:
		default:
			return entity.name;
	}
};

const sortBy = { sort_by: 'name', order: 'ascend' };

const getComponent = (config) => {
	switch (config.type) {
		case FILTER_FIELD_TYPE.DATE_RANGE:
			return OWDateRangePicker;
		case FILTER_FIELD_TYPE.TUPLE:
			return OWAsyncTupleSelect;
		case FILTER_FIELD_TYPE.RADIO_GROUP:
			return OWRadioGroup;
		case FILTER_FIELD_TYPE.OW_MULTI_SELECT:
			return OWSelect;
		default:
			return OWAsyncTreeSelect;
	}
};

function ReduxDataTable({
	data,
	updatePagination,
	stateSlice,
	fetchData,
	location,
	updateSorting,
	updateFilters,
	clearFilters,
	currentUser,
	downloadCSV,
	CustomFooter,
	CustomHeader,
	tableStyle,
	history,
	emptyState,
	preAppliedFilters,
	userType,
	targetCollectionName,
	filterConfig,
	tableColumns,
	onTableRow,
	pipeLineFilters,
	hideFilters,
	sorters,
	hideSearch,
	rightActions,
	additionalFilters,
	rowSelection,
}) {
	const {
		fetching,
		filters: activeFilters,
		pagination,
		sorting: activeSorters,
	} = stateSlice[targetCollectionName] || standardInitialStateGenerator([targetCollectionName]);

	React.useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const preserve = queryParams.has('preserve');

		if (!preserve || !(data && data.length)) {
			fetchData(location);
		}
	}, []);

	const updatedFilterConfig = useMemo(
		() =>
			filterConfig.map((config) => ({
				...config,
				keyAccessor: config.keyAccessor || config.valueAccessor || valueAccessor(config),
				valueAccessor: config.valueAccessor || valueAccessor(config),
				renderItem: config.renderItem || renderItem(config),
				labelAccessor: config.labelAccessor || config.renderItem || renderItem(config),
				renderRecord: config.renderRecord || config.renderItem || renderItem(config),
				sortBy: config.sortyBy || sortBy,
				component: config.component || getComponent(config),
				handleChange: (val) => updateFilters(getFilterValue(val, config)),
				filtersValueAccessor: (ss) => getFilterValueAccessor(ss, targetCollectionName, config),
			})),
		[updateFilters, filterConfig, targetCollectionName]
	);

	return (
		<div className="TableHoc px-2">
			{CustomHeader ? (
				<CustomHeader
					applySorting={updateSorting}
					applyFilters={(e) => updateFilters(e)}
					filters={activeFilters}
					sorters={activeSorters}
					currentUser={currentUser}
					isDisplayModeActive={(e) => e === 'map'}
					downloadCSV={() => downloadCSV({}, activeFilters)}
					counts={stateSlice.counts}
					clearFilters={clearFilters}
					preAppliedFilters={preAppliedFilters}
					filterConfig={updatedFilterConfig}
					targetCollectionName={targetCollectionName}
					userType={userType}
					pagination={pagination}
					fetching={fetching}
					refreshTable={fetchData}
				/>
			) : (
				<div className="flex flex-col gap-x-2 gap-y-3">
					{pipeLineFilters && pipeLineFilters.length > 0 ? (
						<Row>
							<PipelineFilter
								items={pipeLineFilters}
								subItems={{}}
								onChange={(filter) => updateFilters(filter, targetCollectionName)}
								value={nullSafeGet(`${targetCollectionName}.filters`, stateSlice)}
								counts={stateSlice.counts}
							/>
						</Row>
					) : null}
					<Row style={{ margin: '0.5em -8px' }} gutter={16}>
						<Col span={24}>
							<SubnavBar
								left={
									!hideFilters ? (
										<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
											<AdvancedFilters
												updateFilters={updateFilters}
												applySorting={updateSorting}
												sorters={activeSorters}
												sorterItems={sorters}
												filterConfig={updatedFilterConfig}
												filtersTargetCollectionName={targetCollectionName}
												clearAndUpdateFilters={clearFilters}
												filtersStateSlice={stateSlice}
												preAppliedFilters={preAppliedFilters}
												initialPagination={{ current: 1, pageSize: 250 }}
												showSearch={!hideSearch}
												initialFilters={activeFilters}
											/>
										</div>
									) : null
								}
								right={rightActions}
							/>
						</Col>
						{additionalFilters ? additionalFilters : null}
					</Row>
					<div className="mb-3 flex w-full flex-1 flex-row">
						<SelectedFilters
							updateFilters={updateFilters}
							filterValues={nullSafeGetOrElse(`${targetCollectionName}.filters`, stateSlice, {})}
							filterConfig={updatedFilterConfig}
						/>
					</div>
				</div>
			)}

			<div style={{ padding: 8, background: 'white' }}>
				<ConfigProvider
					renderEmpty={() =>
						emptyState ? emptyState : <EmptyState currentUser={currentUser} /> || undefined
					}
				>
					<Table
						rowClassName="clickable-paginated-index-row"
						style={{ tableLayout: 'fixed', ...tableStyle }}
						className="table-fixed-100"
						showHeader={false}
						pagination={false}
						loading={fetching}
						dataSource={data}
						columns={tableColumns}
						onRow={onTableRow}
						rowSelection={rowSelection}
						rowKey={(el) => el.id}
					/>
				</ConfigProvider>
				{CustomFooter ? (
					<CustomFooter
						updatePagination={updatePagination}
						pagination={{ ...pagination, current: 1, pageSize: 3 }}
						filters={activeFilters}
						sorters={activeSorters}
						location={location}
						history={history}
						userType={userType}
					/>
				) : (
					<div className="flex flex-row justify-end py-4">
						<Pagination
							{...defaultPagination}
							{...pagination}
							onChange={(current, pageSize) => updatePagination({ current, pageSize })}
						/>
					</div>
				)}
			</div>
		</div>
	);
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	handleQueryParams: ownProps.handleQueryParams,
	currentUser: state.session.currentUser,
	companyConfig: state.company_config.detail,
	targetCollectionName: ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
	data: getEntitiesById(
		ownProps.stateSlice[ownProps.targetCollectionName || TARGETCOLLECTIONNAME]
			? ownProps.stateSlice[ownProps.targetCollectionName || TARGETCOLLECTIONNAME].recordsIds || []
			: [],
		ownProps.stateSlice.records
	),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchData: (location) =>
		dispatch(
			fetchEntities(ownProps.userType, ownProps.entityCollectionName, ownProps.fetchData)(
				ownProps.handleQueryParams,
				ownProps.history,
				location,
				ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
				ownProps.preAppliedFilters,
				ownProps.initialPagination,
				ownProps.initialSorting,
				ownProps.initialFilters,
				ownProps.ignoreRefreshCounts
			)
		),
	updatePagination: (pagination) =>
		dispatch(
			updatePaginationEntities(
				ownProps.userType,
				ownProps.entityCollectionName,
				ownProps.fetchData
			)(
				ownProps.handleQueryParams,
				ownProps.history,
				ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
				pagination,
				ownProps.ignoreRefreshCounts
			)
		),
	updateSorting: (sorting) =>
		dispatch(
			updateSortingEntities(ownProps.userType, ownProps.entityCollectionName, ownProps.fetchData)(
				ownProps.handleQueryParams,
				ownProps.history,
				ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
				sorting,
				true
			)
		),
	updateFilters: (filters) =>
		dispatch(
			updateFiltersEntities(ownProps.userType, ownProps.entityCollectionName, ownProps.fetchData)(
				ownProps.handleQueryParams,
				ownProps.history,
				ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
				filters,
				ownProps.ignoreRefreshCounts
			)
		),
	replaceFiltersAndSorting: (filters, sorting) =>
		dispatch(
			replaceFiltersAndSortingEntities(
				ownProps.userType,
				ownProps.entityCollectionName,
				ownProps.fetchData
			)(
				ownProps.handleQueryParams,
				ownProps.history,
				ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
				filters,
				sorting,
				ownProps.ignoreRefreshCounts
			)
		),
	clearFilters: () =>
		dispatch(
			clearFiltersEntities(ownProps.userType, ownProps.entityCollectionName, ownProps.fetchData)(
				ownProps.handleQueryParams,
				ownProps.history,
				ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
				ownProps.preAppliedFilters,
				ownProps.ignoreRefreshCounts
			)
		),
});

const WorkOrderHOC = withRouter(connect(mapStateToProps, mapDispatchToProps)(ReduxDataTable));

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	(dispatch, ownProps) => ({})
)(WorkOrderHOC);

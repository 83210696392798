import { Button, Col, Form, Input, message, Modal, Popover, Row } from 'antd';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { UploadOutlined } from '@ant-design/icons';
import UploadWithSampleModal from '../parts_index_page/UploadWithSampleModal';
import { CSV_EXPORT_DEFAULTS } from '../../utils/DataConstants';
import { ExportToCsv } from 'export-to-csv';
import { purchaseOrderReceiptsRestCrudThunksForSupplier } from '../../thunks/purchase_order_receipt_thunks';
import { updateAssetsBulkForSupplier } from '../../thunks/assets_thunks';
import { updateMultipleEpslForSupplier } from '../../thunks/equipment_per_stock_locations_thunks';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';

const CSV_TITLE = 'Serial_Numbers_Sample';

const FORM_ID = 'update-serial-numbers-form';

const FIELD_NAME_PREFIX = 'equipment-value-';

const FIELD_NAME = {
	SERIAL_NUMBER: 'serial-number',
	ASSET_NUMBER: 'asset-number',
};

const getFieldName = (epsl) => (fieldName) => `${fieldName}-${FIELD_NAME_PREFIX}${epsl.id}`;

const UpdateSerialAmdAssetNumbersModal: FC<any> = ({
	prLineItems,
	receiptEntities,
	createdEntities = [],
	entityType = 'equipment',
	onCancel,
	createPOReceiptBulk,
	onSuccess,
	onReceiptValuesSuccessForPR,
}): React.ReactElement => {
	const [form] = Form.useForm();

	const { setFieldsValue, getFieldsValue } = form;

	const isAssetReceive = useMemo(() => entityType === 'asset', [entityType]);

	const [uploadPopupVisible, setUploadPopupVisible] = useState(false);
	const [saving, setSaving] = useState(false);

	const showUpload = useCallback(() => setUploadPopupVisible(true), []);
	const hideUpload = useCallback(() => setUploadPopupVisible(false), []);

	const getFormValuesFromEntity = useCallback(
		(values, entity) => ({
			serialNumber: values[getFieldName(entity)(FIELD_NAME.SERIAL_NUMBER)],
			assetNumber: values[getFieldName(entity)(FIELD_NAME.ASSET_NUMBER)],
		}),
		[]
	);

	const getUpdatedEntityValues = useCallback(
		(entities, values) =>
			entities.map((_) => ({
				id: _.id,
				...getFormValuesFromEntity(values, _),
			})),
		[getFormValuesFromEntity]
	);

	const getEquipmentReceiptValues = useCallback(
		(id, values) =>
			getUpdatedEntityValues(
				createdEntities.filter((_) =>
					isAssetReceive ? _.assetTypeId === id : _.equipmentTypeId === id
				),
				values
			),
		[createdEntities, getUpdatedEntityValues, isAssetReceive]
	);

	const submitForEpsl = useCallback(
		(values) =>
			createPOReceiptBulk(
				receiptEntities.map((receipt) => ({
					...receipt,
					...(receipt.equipmentTypeId && {
						equipmentPerStockLocationReceiptValues: getEquipmentReceiptValues(
							receipt.equipmentTypeId,
							values
						),
					}),
				}))
			),
		[createPOReceiptBulk, getEquipmentReceiptValues, receiptEntities]
	);

	const submitForAssets = useCallback(
		(values) =>
			createPOReceiptBulk(
				receiptEntities.map((receipt) => ({
					...receipt,
					...(receipt.equipmentTypeId && {
						assetReceiptValues: getEquipmentReceiptValues(receipt.assetTypeId, values),
					}),
				}))
			),
		[createPOReceiptBulk, getEquipmentReceiptValues, receiptEntities]
	);

	const submitReceiptValue = useCallback(
		(values) => {
			const equipmentReceiptValues = prLineItems.map((prLineItem) => ({
				prLineItemId: prLineItem.id,
				equipmentReceiptValues: getEquipmentReceiptValues(
					isAssetReceive
						? nullSafeGet('equipmentType.assetTypeId', prLineItem)
						: prLineItem.equipmentTypeId,
					values
				),
			}));
			return onReceiptValuesSuccessForPR(equipmentReceiptValues);
		},
		[getEquipmentReceiptValues, isAssetReceive, onReceiptValuesSuccessForPR, prLineItems]
	);

	const onSubmit = useCallback(
		(values) => {
			const promiseToCall = nullSafeGet('0', receiptEntities)
				? isAssetReceive
					? submitForAssets
					: submitForEpsl
				: submitReceiptValue;
			setSaving(true);
			promiseToCall(values)
				.then(() => onSuccess && onSuccess())
				.catch((err) => message.error(err))
				.finally(() => setSaving(false));
		},
		[isAssetReceive, onSuccess, receiptEntities, submitForAssets, submitForEpsl, submitReceiptValue]
	);

	const allSerialNumberFieldNames = useMemo(
		() => createdEntities.map((_) => getFieldName(_)(FIELD_NAME.SERIAL_NUMBER)),
		[createdEntities]
	);
	const allAssetNumberFieldNames = useMemo(
		() => createdEntities.map((_) => getFieldName(_)(FIELD_NAME.ASSET_NUMBER)),
		[createdEntities]
	);

	const getAutoGeneratedKey = useCallback(
		(fieldName) => `OW-${fieldName.split(FIELD_NAME_PREFIX)[1]}`,
		[]
	);

	const onAutoGenerate = useCallback(() => {
		const allSerialNumberValues = getFieldsValue(allSerialNumberFieldNames);
		const updatedValues = Object.keys(allSerialNumberValues).reduce(
			(acc, fieldName) =>
				!!acc[fieldName]
					? acc
					: {
							...acc,
							[fieldName]: getAutoGeneratedKey(fieldName),
					  },
			allSerialNumberValues
		);
		setFieldsValue(updatedValues);
	}, [allSerialNumberFieldNames, getAutoGeneratedKey, getFieldsValue, setFieldsValue]);

	const fields = useMemo(
		() => [
			{
				label: 'Equipment Type #',
				key: 'equipmentTypeNumber',
			},
			{
				label: `${isAssetReceive ? 'Asset' : 'Equipment'} Name`,
				key: 'name',
			},
			{
				label: 'Serial Number',
				key: 'serialNumber',
			},
			{
				label: 'Asset Number',
				key: 'assetNumber',
			},
		],
		[isAssetReceive]
	);

	const onDownloadSample = useCallback(() => {
		const csvExporter = new ExportToCsv({
			...CSV_EXPORT_DEFAULTS,
			filename: CSV_TITLE,
			title: CSV_TITLE,
			headers: fields.map((_) => _.label),
		});
		csvExporter.generateCsv(
			createdEntities.map((entity) => {
				return {
					equipmentTypeId: nullSafeGetOrElse('equipmentType.equipmentTypeId', entity, '--'),
					name: isAssetReceive
						? nullSafeGet('name', entity)
						: nullSafeGet('equipmentType.modelName', entity),
					serialNumber: '',
					assetNumber: '',
				};
			}, [])
		);
	}, [createdEntities, fields, isAssetReceive]);

	const onUploadSerialAndAssetNumbers = useCallback(
		(uploadedValues) => {
			const values = createdEntities.reduce(
				(acc, epsl, idx) =>
					idx < uploadedValues.length
						? {
								...acc,
								[getFieldName(epsl)(FIELD_NAME.SERIAL_NUMBER)]: uploadedValues[idx].serialNumber,
								[getFieldName(epsl)(FIELD_NAME.ASSET_NUMBER)]: uploadedValues[idx].assetNumber,
						  }
						: acc,
				{}
			);
			setFieldsValue(values);
		},
		[createdEntities, setFieldsValue]
	);

	const onDataLoad = useCallback(
		(results) => {
			onUploadSerialAndAssetNumbers(results.validData);
			setTimeout(() => hideUpload(), 0);
			return new Promise((resolve) => resolve('Serial/Asset Numbers updated successfully'));
		},
		[hideUpload, onUploadSerialAndAssetNumbers]
	);

	return (
		<Modal
			visible={true}
			width={600}
			title="Update Serial/Asset Numbers"
			closable
			onCancel={onCancel}
			forceRender
			footer={null}
		>
			<Form form={form} id={FORM_ID} layout="horizontal" requiredMark={false} onFinish={onSubmit}>
				<Row>
					<Col span={8}></Col>
					<Col span={8} className="p-2">
						<div className="flex flex-row items-center justify-center">
							<div>Serial Number</div>
							<Popover trigger="hover" content="Generate Serial Numbers">
								<Button
									type="link"
									onClick={onAutoGenerate}
									icon={<i className={'icons8-refresh mr-1'} />}
								/>
							</Popover>
						</div>
					</Col>
					<Col span={8} className="p-2">
						<div className="flex flex-row items-center justify-center">
							<div>Asset Number</div>
							<div style={{ height: 32 }}></div>
						</div>
					</Col>
				</Row>
				{createdEntities.map((entity, idx) => (
					<Row className="flex flex-row items-baseline">
						<Col span={8} className="pr-2">
							<Ellipsis tooltip={true} fullWidthRecognition={true} lines={1}>
								{`${idx + 1}. ${
									isAssetReceive
										? nullSafeGet('name', entity)
										: nullSafeGet('equipmentType.modelName', entity)
								}`}
							</Ellipsis>
						</Col>
						<Col span={8} className="pr-2">
							<Form.Item
								key={getFieldName(entity)(FIELD_NAME.SERIAL_NUMBER)}
								name={getFieldName(entity)(FIELD_NAME.SERIAL_NUMBER)}
								rules={[
									({ getFieldValue }) => ({
										message: 'You need enter at least one of serial number/asset number',
										validator: (obj, value) => {
											const field = nullSafeGetOrElse('field', obj, '');
											const id = field.split(FIELD_NAME_PREFIX)[1];
											if (id) {
												const assetNumber = getFieldValue(
													getFieldName({ id })(FIELD_NAME.ASSET_NUMBER)
												);
												return !value && !assetNumber ? Promise.reject() : Promise.resolve();
											} else {
												return Promise.resolve();
											}
										},
									}),
									({ getFieldsValue }) => ({
										message: 'You have already entered this serial number!',
										validator: (_, value) => {
											const allValues = getFieldsValue(allSerialNumberFieldNames);
											const allSerialNumbers = Object.values(allValues);
											const found = allSerialNumbers.find((x) => x === value);
											return found && allSerialNumbers.indexOf(found) !== idx
												? Promise.reject()
												: Promise.resolve();
										},
									}),
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={8} className="pl-2">
							<Form.Item
								labelAlign="left"
								key={getFieldName(entity)(FIELD_NAME.ASSET_NUMBER)}
								name={getFieldName(entity)(FIELD_NAME.ASSET_NUMBER)}
								rules={[
									({ getFieldValue }) => ({
										message: 'You need enter at least one of asset number/serial number',
										validator: (obj, value) => {
											const field = nullSafeGetOrElse('field', obj, '');
											const id = field.split(FIELD_NAME_PREFIX)[1];
											if (id) {
												const serialNumber = getFieldValue(
													getFieldName({ id })(FIELD_NAME.SERIAL_NUMBER)
												);
												return !value && !serialNumber ? Promise.reject() : Promise.resolve();
											} else {
												return Promise.resolve();
											}
										},
									}),
									({ getFieldsValue }) => ({
										message: 'You have already entered this asset number!',
										validator: (_, value) => {
											const allValues = getFieldsValue(allAssetNumberFieldNames);
											const allAssetNumbers = Object.values(allValues);
											const found = allAssetNumbers.find((x) => x === value);
											return found && allAssetNumbers.indexOf(found) !== idx
												? Promise.reject()
												: Promise.resolve();
										},
									}),
								]}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>
				))}

				<Row
					justify="space-between"
					align="middle"
					style={{
						borderTop: 'solid 1px rgba(0, 0, 0, 0.1)',
						paddingTop: '8px',
						margin: '0 0 -8px',
					}}
				>
					<div>
						<Button onClick={showUpload} icon={<UploadOutlined translate="" />}>
							Upload Serial Numbers
						</Button>
					</div>
					<div>
						<Button size="large" onClick={onCancel}>
							Cancel
						</Button>
						<Button
							type="primary"
							size="large"
							style={{ marginLeft: '16px' }}
							key="submit"
							htmlType="submit"
							form={FORM_ID}
							loading={saving}
						>
							Update
						</Button>
					</div>
					{uploadPopupVisible && (
						<UploadWithSampleModal
							type="Serial numbers"
							title="Upload Serial Numbers"
							onCancel={hideUpload}
							onDownloadSample={onDownloadSample}
							fields={fields}
							onDataLoad={onDataLoad}
							sampleFileText="Download Template File"
						/>
					)}
				</Row>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	createPOReceiptBulk: (entities) =>
		dispatch(purchaseOrderReceiptsRestCrudThunksForSupplier.createBulk(entities)),
	updateAssetsBulk: (entities) => dispatch(updateAssetsBulkForSupplier(entities)),
	updateEpslsBulk: (entities) => dispatch(updateMultipleEpslForSupplier(entities)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(UpdateSerialAmdAssetNumbersModal)
);

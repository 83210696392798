import { SOURCING_EVENT_SUPPLIER_ASSOCS_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/sourcing_event_supplier_assocs_actions';
import {
	compositeInitialStateGenerator,
	compositeReducerGenerator,
} from './standard_reducer_utils';

const initialState: any = compositeInitialStateGenerator(
	['associatedSourcingEventSupplierAssocs'],
	['sourcingEventId', 'supplierFacilityId']
);

export default compositeReducerGenerator(CRUD_ACTION_CONSTANTS, initialState, [
	'sourcingEventId',
	'supplierFacilityId',
]);

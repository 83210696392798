import { Select, Tag } from 'antd';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { ContactAvatar } from '../contact_avatar/ContactAvatar';
import * as React from 'react';

const secondaryFieldStyle = { fontSize: '1.15em', color: '#606060' };
const primaryFieldStyle = { fontSize: '1.2em', color: 'black' };

const optionStyleSupplier = {
	//backgroundColor: 'rgb(37, 150, 190,0.05)',
	color: 'red',
	'&:hover': {
		backgroundColor: 'rgb(37, 150, 190,0.15)',
	},
};
const optionStyleBuyer = {
	//backgroundColor: 'rgb(124, 72, 189,0.05)',
	'&:hover': {
		backgroundColor: 'red',
	},
};

export default function SelectUsers({
	mode,
	style,
	placeholder,
	onSearch = () => {},
	onFocus = () => {},
	onChange = (val: any) => {},

	search,
	selectValue,

	filteredUsers,
	disabledUsers = [],

	disabledUserMessage = 'Already in this channel',
}) {
	return (
		<Select
			mode={mode}
			showSearch
			style={{ width: '100%' }}
			placeholder={placeholder}
			onSearch={onSearch}
			allowClear={true}
			autoClearSearchValue={true}
			onFocus={onFocus}
			onChange={onChange}
			autoFocus={true}
			value={selectValue}
			optionLabelProp="label"
			filterOption={(input, option) => true}
			notFoundContent={
				search.length > 0 ? (
					search.includes('@') ? (
						search.slice(search.indexOf('@'), search.length).includes('.') ? (
							<Select.Option key={search} value={search}>
								<div style={{ display: 'inline-block' }}>eMail Icon</div>
								<div style={{ display: 'inline-block' }}>Invite {search} to join OpenWrench</div>
							</Select.Option>
						) : (
							<div>Sorry! We couldn't find this user. Try searching by name!</div>
						)
					) : (
						<div>Sorry! We couldn't find this user. Try searching by email!</div>
					)
				) : null
			}
		>
			{filteredUsers.length > 0
				? filteredUsers.map((user) => (
						<Select.Option
							disabled={disabledUsers.some(
								(u) =>
									nullSafeGetOrElse('email', u, '').toLowerCase() ===
									nullSafeGetOrElse('email', user, '').toLowerCase()
							)}
							key={user.email}
							value={JSON.stringify(user)}
							title={user.nameGiven.concat(' ', user.nameFamily)}
							{...{ label: user.nameGiven.concat(' ', user.nameFamily) }}
							style={user.contactType === 'supplier' ? optionStyleSupplier : optionStyleBuyer}
							className={
								user.contactType === 'supplier'
									? style.subscriberOptionSupplier
									: style.subscriberOptionBuyer
							}
						>
							<ContactAvatar
								hidePopover={true}
								placement="bottomRight"
								userType="buyer"
								contact={user}
								className={'contactAvatar'}
								style={{
									marginBottom: '6px',
									backgroundColor: user.contactType === 'supplier' ? '#48a7bd' : '#7c48bd',
								}}
							/>
							<div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
								<div style={{ display: 'inline-block', paddingLeft: '12px' }}>
									<div style={{ display: 'block' }}>
										<div style={search.includes('@') ? secondaryFieldStyle : primaryFieldStyle}>
											{user.nameGiven} {user.nameFamily}
											{disabledUsers.some(
												(u) =>
													nullSafeGetOrElse('email', u, '').toLowerCase() ===
													nullSafeGetOrElse('email', user, '').toLowerCase()
											) && (
												<span className="px-2">
													<Tag>{disabledUserMessage}</Tag>
												</span>
											)}
										</div>
										<div style={search.includes('@') ? primaryFieldStyle : secondaryFieldStyle}>
											{user.email}
										</div>
									</div>
								</div>
							</div>
						</Select.Option>
				  ))
				: null}
		</Select>
	);
}

import * as React from 'react';

import { CloseOutlined, PlusOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Input, Alert, Button } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { supplierFacilitiesRestCrudThunksForSupplier } from '../../thunks/supplier_facilities_thunks';
import { nullSafeGet } from '../../utils/DataAccessUtils';

const style = require('./EditSupplierFAQForm.less');
const FormItem = Form.Item;

interface EditSupplierFAQProps {
	loading: boolean;
	faqs: any[];
	updateSupplier: any;
	successCallback: any;
	cancelCallback: any;
	supplierFacility: any;

	errors: string[];
}

interface EditSupplierFAQFormProps extends FormComponentProps {
	loading: boolean;
	faqs: any[];
	updateSupplier: any;
	successCallback: any;
	cancelCallback: any;
	supplierFacility: any;

	errors: string[];
}

let faqUuid = 1;

class EditSupplierFAQForm extends React.Component<EditSupplierFAQFormProps, any> {
	handleSubmit = (e) => {
		const { updateSupplier, successCallback, supplierFacility, form } = this.props;
		e.preventDefault();
		form.validateFields((err, values) => {
			if (!err) {
				const faqKeys = form.getFieldValue('faqKeys');
				const faqs = faqKeys
					.map((k) => ({
						question: form.getFieldValue(`faqs-${k}-question`),
						answer: form.getFieldValue(`faqs-${k}-answer`),
					}))
					.filter((el) => el.question !== undefined);
				const updatedSupplier = {
					...supplierFacility,
					faqs,
				};
				updateSupplier(updatedSupplier).then((resp) => successCallback(resp));
			}
		});
	};

	removeFAQ = (k) => {
		const { form } = this.props;
		// can use data-binding to get
		const faqKeys = form.getFieldValue('faqKeys');
		// We need at least one question
		if (faqKeys.length === 1) {
			return;
		}

		// can use data-binding to set
		form.setFieldsValue({
			faqKeys: faqKeys.filter((key) => key !== k),
		});
	};

	addFAQ = () => {
		const { form } = this.props;
		// can use data-binding to get
		const faqKeys = form.getFieldValue('faqKeys');
		const nextKeys = faqKeys.concat(faqUuid);
		// can use data-binding to set
		// important! notify form to detect changes
		form.setFieldsValue({
			faqKeys: nextKeys,
		});
		faqUuid++;
	};

	render() {
		const { getFieldDecorator, getFieldValue } = this.props.form;
		const { loading, errors, cancelCallback, faqs } = this.props;

		const faqKeysInitialValue = faqs.length > 0 ? faqs.map((el, idx) => idx) : [0];
		getFieldDecorator('faqKeys', { initialValue: faqKeysInitialValue });

		const faqKeys = getFieldValue('faqKeys');

		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
				sm: { span: 4 },
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 20 },
			},
		};
		const formItemLayoutWithOutLabel = {
			wrapperCol: {
				xs: { span: 24, offset: 0 },
				sm: { span: 20, offset: 4 },
			},
		};
		const formItems = faqKeys.map((k, faqIndex) => {
			return (
				<div key={k} className="faq-container">
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div style={{ fontSize: '16px', color: 'black', marginBottom: '24px' }}>
							Frequently Asked Question {faqIndex + 1}
						</div>

						<div className="remove-faq-button">
							{faqKeys.length > 1 ? (
								<CloseOutlined
									className="dynamic-delete-button"
									onClick={() => this.removeFAQ(k)}
								/>
							) : null}
						</div>
					</div>
					<Form.Item {...formItemLayout} label="Question" required={false}>
						{getFieldDecorator(`faqs-${k}-question`, {
							validateTrigger: ['onChange', 'onBlur'],
							initialValue: nullSafeGet(`${k}.question`, faqs),
							rules: [
								{
									required: true,
									whitespace: true,
									message: 'Please pose a question to answer.',
								},
							],
						})(<Input style={{ width: '90%' }} />)}
					</Form.Item>
					<Form.Item {...formItemLayout} label="Answer" required={false}>
						{getFieldDecorator(`faqs-${k}-answer`, {
							validateTrigger: ['onChange', 'onBlur'],
							initialValue: nullSafeGet(`${k}.answer`, faqs),
							rules: [
								{
									required: true,
									whitespace: true,
									message: 'Please answer the question.',
								},
							],
						})(<Input.TextArea autoSize={true} style={{ width: '90%' }} />)}
					</Form.Item>
				</div>
			);
		});
		return (
			<Form onSubmit={this.handleSubmit} className="editSupplierFAQForm">
				{errors && errors.length > 0
					? errors.map((msg, idx) => (
							<FormItem key={idx}>
								<Alert message={msg} type="error" />
							</FormItem>
					  ))
					: null}
				{formItems}
				<Form.Item label="">
					<Button type="dashed" onClick={this.addFAQ} style={{ width: '100%', height: 217 }}>
						<PlusOutlined /> Add FAQ
					</Button>
				</Form.Item>
				<FormItem>
					<Button type="primary" htmlType="submit" loading={loading}>
						Update FAQ
					</Button>
					<Button style={{ marginLeft: 16 }} onClick={cancelCallback}>
						Cancel
					</Button>
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	loading: state.supplier_facilities.loading,
	errors: state.supplier_facilities.updateErrors,
	faqs: ownProps.faqs,
	successCallback: ownProps.successCallback,
	cancelCallback: ownProps.cancelCallback,
	supplierFacility: ownProps.supplierFacility,
});

const mapDispatchToProps = (dispatch) => ({
	updateSupplier: (supplierFacility) =>
		dispatch(supplierFacilitiesRestCrudThunksForSupplier.update(supplierFacility)),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<EditSupplierFAQFormProps>()(EditSupplierFAQForm))
);

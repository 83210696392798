import { ASSETS_CRUD_ACTION_CREATORS } from '../actions/assets_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { parseJSON } from './fetch_utils';
import { logoutSuccess } from '../actions/session_actions';
import { message } from 'antd';
import FetchUtils from './fetch_utils';
import { retrieveCachedUserDetails } from './session_thunks';

const fetchUtils = new FetchUtils();

export const generateLocationMeterReadingsPDFForBuyer = (id) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/buyer/asset/meter_readings/by_location/${id}/generate_pdf`;
	return {
		url: baseUrl + apiUrl,
		httpHeaders: { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token },
	};
};

export const assetsRestCrudThunksForBuyer = new RestCrudThunks(
	'assets',
	'asset',
	'asset',
	'assets',
	ASSETS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'asset/'
);
export const assetsRestCrudThunksForSupplier = new RestCrudThunks(
	'assets',
	'asset',
	'asset',
	'assets',
	ASSETS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'asset/'
);

export const assetsRestCrudThunksForDowntime = new RestCrudThunks(
	'assets',
	'asset',
	'asset',
	'assets',
	ASSETS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'asset/asset_downtimes/analytics/'
);
export const assetsRestCrudThunksForDowntimeForSupplier = new RestCrudThunks(
	'assets',
	'asset',
	'asset',
	'assets',
	ASSETS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'asset/asset_downtimes/analytics/'
);

const downloadAssetCSV =
	(roleType) =>
	(params, filters = null) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/asset/assets/csv_report_generator`;

		// if sorting present, fix order naming and add to query params

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.message);
							message.error(d.data);
						} else {
							resolve(d.data);
							const hide = message.loading(d.data, 0);
							setTimeout(hide, 7000);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};

const createAssetFromEquipmentPerStockLocationBulk = (roleType) => (entities) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/asset/assets/from_equipment_per_stock_location/bulk`;

	// if sorting present, fix order naming and add to query params

	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(ASSETS_CRUD_ACTION_CREATORS.createStart({}));

			fetchUtils
				.post(
					baseUrl + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entities,
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(ASSETS_CRUD_ACTION_CREATORS.createError(d.message, {}));
						reject(d.message);
					} else {
						dispatch(ASSETS_CRUD_ACTION_CREATORS.createSuccess({}));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(ASSETS_CRUD_ACTION_CREATORS.createError(d.message, {}));
					reject(d.message);
				});
		});
	};
};

export const downloadAssetCSVForBuyer = downloadAssetCSV(ROLE_TYPES.BUYER);
export const downloadAssetCSVForSupplier = downloadAssetCSV(ROLE_TYPES.SUPPLIER);

export const createAssetFromEquipmentPerStockLocationBulkForSupplier =
	createAssetFromEquipmentPerStockLocationBulk(ROLE_TYPES.SUPPLIER);

const createAssetFromEquipmentTypeBulk = (roleType) => (entities) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/asset/assets/from_equipment_type_and_stock_location/bulk`;

	// if sorting present, fix order naming and add to query params

	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(ASSETS_CRUD_ACTION_CREATORS.createStart({}));

			fetchUtils
				.post(
					baseUrl + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entities,
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(ASSETS_CRUD_ACTION_CREATORS.createError(d.message, {}));
						reject(d.message);
					} else {
						dispatch(ASSETS_CRUD_ACTION_CREATORS.createSuccess({}));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(ASSETS_CRUD_ACTION_CREATORS.createError(d.message, {}));
					reject(d.message);
				});
		});
	};
};

export const createAssetFromEquipmentTypeBulkForSupplier = createAssetFromEquipmentTypeBulk(
	ROLE_TYPES.SUPPLIER
);

const fetchAssociatedAsset = (roleType) => (epslId) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/asset/assets/by_equipment_per_stock_location_id/${epslId}`;

	// if sorting present, fix order naming and add to query params

	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(ASSETS_CRUD_ACTION_CREATORS.fetchStart());

			fetchUtils
				.get(baseUrl + apiUrl, {})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(ASSETS_CRUD_ACTION_CREATORS.fetchError(d.message, ''));
						reject(d.message);
					} else {
						dispatch(ASSETS_CRUD_ACTION_CREATORS.fetchOneSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(ASSETS_CRUD_ACTION_CREATORS.fetchError(d.message, ''));
					reject(d.message);
				});
		});
	};
};

export const fetchAssociatedAssetOfEquipmentPerStockLocationForSupplier = fetchAssociatedAsset(
	ROLE_TYPES.SUPPLIER
);

const fetchAssetsGroupedByLocation =
	(roleType) =>
	(
		params,
		targetCollectionName = null,
		pagination = null,
		sorting = null,
		filters = null,
		addToTargetCollection = false
	) => {
		// if pagination present, transform into limit/offset format and add to query params
		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(ASSETS_CRUD_ACTION_CREATORS.fetchStart());

				const suffixUrl = '/asset/assets/by_grouped_location';
				fetchUtils
					.get(
						getBackendUri() + `/api/v1/${roleType}` + suffixUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							dispatch(ASSETS_CRUD_ACTION_CREATORS.fetchError(d.message, ''));
							reject(d.message);
						} else {
							dispatch(
								ASSETS_CRUD_ACTION_CREATORS.fetchSuccess(
									d.data,
									d.count,
									targetCollectionName,
									{
										...pagination,
										total: d.count,
									},
									sorting,
									filters,
									addToTargetCollection
								)
							);
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(ASSETS_CRUD_ACTION_CREATORS.fetchError(d.message, ''));
						reject(d.message);
					});
			});
		};
	};

export const fetchAssetsGroupedByLocationForBuyer = fetchAssetsGroupedByLocation(ROLE_TYPES.BUYER);
export const fetchAssetsGroupedByLocationForSupplier = fetchAssetsGroupedByLocation(
	ROLE_TYPES.SUPPLIER
);

const updateAssetImpairments = (roleType) => (data) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/asset/assets/update_impairments`;

	// if sorting present, fix order naming and add to query params

	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(ASSETS_CRUD_ACTION_CREATORS.updateStart({}));

			fetchUtils
				.put(
					baseUrl + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data,
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(ASSETS_CRUD_ACTION_CREATORS.updateError(d.message, {}));
						reject(d.message);
					} else {
						dispatch(ASSETS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(ASSETS_CRUD_ACTION_CREATORS.updateError(d.message, {}));
					reject(d.message);
				});
		});
	};
};

export const updateAssetImpairmentsForBuyer = updateAssetImpairments(ROLE_TYPES.BUYER);
export const updateAssetImpairmentsForSupplier = updateAssetImpairments(ROLE_TYPES.SUPPLIER);

const addImpairmentsToJournal = (roleType) => (data) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/asset/assets/${data.id}/add_impairments_to_journal`;

	// if sorting present, fix order naming and add to query params

	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(ASSETS_CRUD_ACTION_CREATORS.updateStart({}));

			fetchUtils
				.put(
					baseUrl + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data,
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(ASSETS_CRUD_ACTION_CREATORS.updateError(d.message, {}));
						reject(d.message);
					} else {
						dispatch(ASSETS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(ASSETS_CRUD_ACTION_CREATORS.updateError(d.message, {}));
					reject(d.message);
				});
		});
	};
};

export const addImpairmentsToJournalForBuyer = addImpairmentsToJournal(ROLE_TYPES.BUYER);
export const addImpairmentsToJournalForSupplier = addImpairmentsToJournal(ROLE_TYPES.SUPPLIER);

export const assetAudits = (roleType) => (params) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/asset/asset_audits`;

	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(baseUrl + apiUrl, params)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const assetAuditForBuyer = assetAudits(ROLE_TYPES.BUYER);
export const assetAuditForSupplier = assetAudits(ROLE_TYPES.SUPPLIER);

export const assetDowntime = (roleType) => (data) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/asset/asset_downtimes`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			//dispatch(ASSETS_CRUD_ACTION_CREATORS.updateStart({}));

			fetchUtils
				.post(
					baseUrl + apiUrl,
					Object.assign({
						data: data,
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						//dispatch(ASSETS_CRUD_ACTION_CREATORS.updateError(d.message, {}));
						reject(d.message);
					} else {
						//dispatch(ASSETS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					//dispatch(ASSETS_CRUD_ACTION_CREATORS.updateError(d.message, {}));
					reject(d.message);
				});
		});
	};
};
export const assetDowntimeUpdate = (roleType) => (data) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/asset/asset_downtimes`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			// dispatch(ASSETS_CRUD_ACTION_CREATORS.updateStart({}));

			fetchUtils
				.put(
					baseUrl + apiUrl,
					Object.assign({
						data: data,
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						// dispatch(ASSETS_CRUD_ACTION_CREATORS.updateError(d.message, {}));
						reject(d.message);
					} else {
						// dispatch(ASSETS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					// dispatch(ASSETS_CRUD_ACTION_CREATORS.updateError(d.message, {}));
					reject(d.message);
				});
		});
	};
};

export const assetDowntimeGet = (roleType) => (params) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/asset/asset_downtimes`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(baseUrl + apiUrl, params)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const assetDowntimeForBuyer = assetDowntime(ROLE_TYPES.BUYER);
export const assetDowntimeForSupplier = assetDowntime(ROLE_TYPES.SUPPLIER);

// ANALYTICS API CALLS

export const assetDowntimeMDRGet = (roleType) => (params) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/asset/asset_downtimes/mean_downtime_report`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(baseUrl + apiUrl, params)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const assetDowntimeMTTRGet = (roleType) => (params) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/asset/asset_downtimes/mean_time_to_respond`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(baseUrl + apiUrl, params)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const assetDowntimeMTBFGet = (roleType) => (params) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/asset/asset_downtimes/mean_time_between_failures`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(baseUrl + apiUrl, params)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const downloadDowntimeAssetCSV =
	(roleType) =>
	(params, filters = null) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/asset/asset_downtimes/analytics/assets/csv_report_generator`;

		// if sorting present, fix order naming and add to query params

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.message);
							message.error(d.data);
						} else {
							resolve(d.data);
							const hide = message.loading(d.data, 0);
							setTimeout(hide, 7000);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};

const updateAssetsBulk = (roleType) => (data) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/asset/assets/bulk`;

	// if sorting present, fix order naming and add to query params

	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(ASSETS_CRUD_ACTION_CREATORS.updateStart({}));

			fetchUtils
				.put(
					baseUrl + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data,
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(ASSETS_CRUD_ACTION_CREATORS.updateError(d.message, {}));
						reject(d.message);
					} else {
						dispatch(ASSETS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(ASSETS_CRUD_ACTION_CREATORS.updateError(d.message, {}));
					reject(d.message);
				});
		});
	};
};

export const updateAssetsBulkForSupplier = updateAssetsBulk(ROLE_TYPES.SUPPLIER);

const bulkTransferAssetsToLocations = (roleType) => (data) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/asset/assets/bulk_transfer/locations`;

	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(ASSETS_CRUD_ACTION_CREATORS.updateStart({}));

			fetchUtils
				.post(
					baseUrl + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data,
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(ASSETS_CRUD_ACTION_CREATORS.updateError(d.message, {}));
						reject(d.message);
					} else {
						dispatch(ASSETS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(ASSETS_CRUD_ACTION_CREATORS.updateError(d.message, {}));
					reject(d.message);
				});
		});
	};
};

export const bulkTransferAssetsToLocationsForSupplier = bulkTransferAssetsToLocations(
	ROLE_TYPES.SUPPLIER
);

const getAssetsBulkTransferStatus = (roleType) => () => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/asset/assets/bulk_transfer/locations/status`;

	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(
					baseUrl + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
						message.error(d.data);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const getAssetsBulkTransferStatusForSupplier = getAssetsBulkTransferStatus(
	ROLE_TYPES.SUPPLIER
);

const moveAssetToLocation = (roleType) => (assetId, data) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/asset/assets/${assetId}/transfer_to_location`;

	// if sorting present, fix order naming and add to query params

	return (dispatch) => {
		const entity = { id: assetId };
		return new Promise((resolve, reject) => {
			dispatch(ASSETS_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.post(
					baseUrl + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data,
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(ASSETS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(ASSETS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(ASSETS_CRUD_ACTION_CREATORS.createError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

export const moveAssetToLocationForBuyer = moveAssetToLocation(ROLE_TYPES.BUYER);
export const moveAssetToLocationForSupplier = moveAssetToLocation(ROLE_TYPES.SUPPLIER);

const createMultipleAssetsAsDeleted = (roleType) => (entities) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/asset/assets/create_multiple_as_deleted`;

	// if sorting present, fix order naming and add to query params

	return (dispatch) => {
		const entity = {};
		return new Promise((resolve, reject) => {
			dispatch(ASSETS_CRUD_ACTION_CREATORS.updateStart(entity));

			fetchUtils
				.post(
					baseUrl + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entities,
					})
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(ASSETS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject(d.message);
					} else {
						dispatch(ASSETS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(ASSETS_CRUD_ACTION_CREATORS.createError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

export const createMultipleAssetsAsDeletedForSupplier = createMultipleAssetsAsDeleted(
	ROLE_TYPES.SUPPLIER
);

const getAllAssetsForBulkUpdate =
	(roleType) =>
	(params = {}) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/asset/assets/lite/bulk_transfers`;

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.message);
							message.error(d.data);
						} else {
							resolve(d.data);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};

export const getAllAssetsForBulkUpdateForSupplier = getAllAssetsForBulkUpdate(ROLE_TYPES.SUPPLIER);

import React, { FC, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router';
import { Layout } from 'antd';
import ScrollToTopOnMount from '../scroll_to_top_on_mount/ScrollToTopOnMount';
import SubnavTabs from '../subnav_tabs/SubnavTabs';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';
import { nullSafeGet } from '../../utils/DataAccessUtils';
const { Content } = Layout;
require('./EquipmentsOverviewPage.less');

const EquipmentsOverviewPage: FC<any> = ({ history, companyConfig, childRoutes }) => {
	const handleTabChange = useCallback(
		(key) => history.push(`/supplier/equipments/overview/${key}`),
		[history]
	);

	const checkTabActive = useCallback(
		(key) => {
			const pathname = history.location.pathname;
			return pathname.indexOf(key) !== -1;
		},
		[history.location.pathname]
	);

	const tabs = useMemo(
		() => [
			{
				key: 'all',
				name: 'All',
			},
			{
				key: 'templates',
				name: 'Types',
			},
			{
				key: 'priceList',
				name: 'Price List',
			},
		],
		[]
	);

	return (
		<div>
			<ScrollToTopOnMount />
			<div>
				<Route
					path="/supplier/equipments/overview"
					exact={true}
					render={(props) => (
						<Redirect
							to={{
								pathname: '/supplier/equipments/overview/all',
							}}
						/>
					)}
				/>
				<Content className="equipmentsPage" style={{ padding: '0 0.5em' }}>
					{/*<ScrollToTopOnMount/>*/}
					{/*<BackTop/>*/}

					<div
						style={{
							margin: '0 -8px',
							padding: '24px',
							paddingBottom: 0,
							background: nullSafeGet('detail.config.theme.navblock', companyConfig),
							marginBottom: 8,
						}}
					>
						<h4
							style={{
								color: 'rgba(255, 255, 255, 0.9)',
								marginRight: 8,
								marginBottom: 24,
							}}
						>
							Equipment
						</h4>
						<SubnavTabs tabs={tabs} onChange={handleTabChange} isActive={checkTabActive} />
					</div>
					{childRoutes.map((r, i) => (
						<RouteWithSubRoutes key={i} {...r} />
					))}
				</Content>
			</div>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => ({
	companyConfig: state.company_config,
});
const mapDispatchToProps = (dispatch) => ({});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(EquipmentsOverviewPage)
);

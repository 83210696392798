import { Layout } from 'antd';
import React, { FC, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getEntityById, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { EmptyState } from '../empty_state/EmptyState';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import EquipmentPerStockLocationRowDisplay from '../equipment_per_stock_location_row_display/EquipmentPerStockLocationRowDisplay';
import { EquipmentRentalRowDisplay } from '../equipment_rental_row_display/EquipmentRentalRowDisplay';
import QRCode from 'qrcode.react';
import { equipmentPerStockLocationsRestCrudThunksForSupplier } from '../../thunks/equipment_per_stock_locations_thunks';

const DEFAULT_BACK_LINK_TEXT = 'Back to Received Equipment';
const TC_NAME = 'stockLocationAssociatedEquipments';
const { Content } = Layout;

const PurchaseOrdersReceivedEquipmentsPage: FC<any> = ({
	location,
	history,
	match,
}): React.ReactElement => {
	const purchaseOrderId = useMemo(() => match.params.id, [match.params.id]);

	const onEquipmentRow = useCallback(
		(record) => ({
			onClick: () =>
				history.push(
					getLinkWIthBackLinkParams(
						location,
						DEFAULT_BACK_LINK_TEXT,
						`/supplier/equipments/equipmentPerStockLocation/detail/${record.id}`
					)
				),
		}),
		[history, location]
	);

	const columns = useMemo(
		() => [
			{
				title: 'Equipment',
				dataIndex: 'name',
				render: (_, record) => (
					<EquipmentPerStockLocationRowDisplay equipmentPerStockLocation={record} />
				),
			},
			{
				title: 'Stock Location',
				dataIndex: 'stockLocation',
				render: (sl) => nullSafeGetOrElse('name', sl, '--'),
			},
			{
				title: 'Rental',
				dataIndex: 'isRental',
				render: (text) => <EquipmentRentalRowDisplay isRental={text} />,
			},
			{
				title: '',
				dataIndex: 'equipmentUUID',
				render: (text) => <QRCode size={64} value={text} />,
			},
		],
		[]
	);

	return (
		<Content
			className="paginated-redux-table-with-header"
			style={{
				padding: '0 0.5em',
			}}
		>
			<PaginatedReduxTable
				mode="list"
				updateQueryParams={true}
				emptyState={
					<EmptyState
						graphic={
							<img
								alt="No data"
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
							/>
						}
						headline={'No equipment found'}
					/>
				}
				collectionName="equipment_per_stock_locations"
				onRow={onEquipmentRow}
				targetCollectionName={TC_NAME}
				columns={columns}
				keyAccessor={(el) => el.id}
				additionalParams={{ purchaseOrderId }}
				fetchData={equipmentPerStockLocationsRestCrudThunksForSupplier.read}
			/>
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	purchaseOrder: getEntityById(ownProps.match.params.id, state.purchase_orders),
});

export default withRouter<any, any>(connect(mapStateToProps)(PurchaseOrdersReceivedEquipmentsPage));

import { Button, Row } from 'antd';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

const ADSavedViewEmptyState = ({ history, roleType }): React.ReactElement => {
	const navigateToWorkOrders = useCallback(
		() => history.push(`/${roleType}/workOrders/overview/all`),
		[roleType, history]
	);

	const navigateToInvoices = useCallback(
		() => history.push(`/${roleType}/invoices/overview/all`),
		[roleType, history]
	);

	return (
		<Row justify="center">
			<Row>
				<h5>No Views Found</h5>
			</Row>
			<Row
				style={{
					color: 'rgba(0,0,0,.25)',
					fontSize: '16px',
					maxWidth: '440px',
					marginBottom: '16px',
					textAlign: 'center',
				}}
			>
				You don't have any saved views. Please save filters to view here.
			</Row>
			<Row>
				<div
					style={{
						maxWidth: 488,
						textAlign: 'center',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Button onClick={navigateToWorkOrders}>Create Work Order Views</Button>
					<Button style={{ marginTop: '16px' }} onClick={navigateToInvoices}>
						Create Invoice Views
					</Button>
				</div>
			</Row>
		</Row>
	);
};

const mapStateToProps = (_, ownProps) => ({
	history: ownProps.history,
});

export default withRouter(connect(mapStateToProps, null)(ADSavedViewEmptyState));

import {
	PLANNED_MAINTENANCE_SCHEDULES_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS,
	PLANNED_MAINTENANCE_SCHEDULES_FETCH_GROUPED_SUCCESS,
} from '../actions/planned_maintenance_schedules_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = Object.assign(
	standardInitialStateGenerator([
		'workOrdersAdvancedFilters',
		'plannedMaintenanceSchedulesIndex',
		'PLANNED_MAINTENANCE_SCHEDULE_DETAIL',
	]),
	{
		groupedPlannedMaintenanceSchedules: [],
	}
);

const plannedMaintenanceSchedulesReducerCrudLogic = standardReducerGenerator(
	CRUD_ACTION_CONSTANTS,
	initialState
);

export default (oldState = initialState, action): any => {
	Object.freeze(oldState);
	switch (action.type) {
		case PLANNED_MAINTENANCE_SCHEDULES_FETCH_GROUPED_SUCCESS:
			return Object.assign({}, oldState, {
				groupedPlannedMaintenanceSchedules: action.records,
				fetching: false,
				fetchErrors: [],
			});
		default:
			return plannedMaintenanceSchedulesReducerCrudLogic(oldState, action);
	}
};

import { SUPPLIER_EXCHANGE_RATES_CRUD_ACTION_CREATORS } from '../actions/supplier_exchange_rates_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import FetchUtils, { parseJSON } from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { logoutSuccess } from '../actions/session_actions';

const fetchUtils = new FetchUtils();

export const supplierExchangeRatesRestCrudThunksForBuyer = new RestCrudThunks(
	'supplier_currency_exchange_rates',
	'supplier_currency_exchange_rate',
	'supplierExchangeRate',
	'supplierExchangeRates',
	SUPPLIER_EXCHANGE_RATES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'supplier_currency_exchange_rate/'
);
export const supplierExchangeRatesRestCrudThunksForSupplier = new RestCrudThunks(
	'supplier_currency_exchange_rates',
	'supplier_currency_exchange_rate',
	'supplierExchangeRate',
	'supplierExchangeRates',
	SUPPLIER_EXCHANGE_RATES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'supplier_currency_exchange_rate/'
);

export const fetchSupplierCurrencyExchangeRateById = (roleType) => (currencyId) => {
	// if pagination present, transform into limit/offset format and add to query params
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/${roleType}/supplier_currency_exchange_rate/supplier_currency_exchange_rates/${currencyId}`;

	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.get(
					baseUrl + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					})
				)
				.then(parseJSON)
				.then(({ json, status }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
					} else {
						resolve(d.data);
					}
				})
				.catch((d) => {
					reject(d.message);
				});
		});
	};
};

export const fetchSupplierCurrencyExchangeRateByIdForSupplier =
	fetchSupplierCurrencyExchangeRateById(ROLE_TYPES.SUPPLIER);

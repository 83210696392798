import React, { FC, useCallback, useMemo } from 'react';
import DateTimeHover from '../date_time_component/DateTime';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { ProtectedImageThumbnails } from '../protected_image_thumbnails/ProtectedImageThumbnails';
import { connect } from 'react-redux';
import { UserName } from '../name_component/user/UserNameComponent';
import moment from 'moment';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { Button, Card, Timeline } from 'antd';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import { ContactAvatar } from '../contact_avatar/ContactAvatar';

const ServiceCallDetails: FC<any> = ({
	workOrder,
	showApproveModal,
	showReviewModal,
	showRejectModal,
	currentUser,
	isReadOnly,
}): React.ReactElement => {
	const userType = useMemo(() => currentUser.userType, [currentUser.userType]);

	const getServiceCallDetails = useCallback(
		(serviceCall) => [
			...(serviceCall.serviceScheduledAt
				? [
						<div style={{ fontSize: 16 }}>
							Scheduled to arrive by{' '}
							<DateTimeHover
								timestamp={nullSafeGet('serviceScheduledAt', serviceCall)}
								dateFormat={'h:mm a on MMM D'}
								showDate
								parseZone={false}
							/>
							.
						</div>,
				  ]
				: []),
			...(serviceCall.checkInTime
				? [
						<div style={{ fontSize: 16 }}>
							<div>
								Started work at{' '}
								<DateTimeHover
									timestamp={nullSafeGet('checkInTime', serviceCall)}
									dateFormat={'h:mm a on MMM D'}
									showDate
								/>
								.
								{serviceCall.checkInGeoLocation ? null : nullSafeGet(
										`checkInByContact`,
										serviceCall
								  ) ? (
									<span style={{ color: 'rgba(0,0,0,0.45)' }}>
										{' '}
										(Checked in remotely by{' '}
										<UserName
											mode={'inline'}
											contact={nullSafeGet(`checkInByContact`, serviceCall)}
										/>
										)
									</span>
								) : null}
							</div>
							{serviceCall.checkInNotes && serviceCall.checkInNotes.length > 0 ? (
								<div className="serviceCallNotesBlurb">{serviceCall.checkInNotes}</div>
							) : null}
							{serviceCall.checkInImages && serviceCall.checkInImages.length > 0 ? (
								<ProtectedImageThumbnails
									style={{ marginTop: 16 }}
									imageWidth={64}
									imageHeight={64}
									photoStrings={serviceCall.checkInImages}
									role={userType}
								/>
							) : null}
						</div>,
				  ]
				: []),
			...(serviceCall.checkOutTime
				? [
						<div style={{ fontSize: 16 }}>
							<div>
								Ended work at{' '}
								<DateTimeHover
									timestamp={nullSafeGet('checkOutTime', serviceCall)}
									dateFormat={'h:mm a on MMM D'}
									showDate
								/>
								.
								{serviceCall.checkOutGeoLocation ? null : nullSafeGet(
										`checkOutByContact`,
										serviceCall
								  ) ? (
									<span style={{ color: 'rgba(0,0,0,0.45)' }}>
										{' '}
										(Checked out remotely by{' '}
										<UserName
											mode={'inline'}
											contact={nullSafeGet(`checkOutByContact`, serviceCall)}
										/>
										)
									</span>
								) : null}
							</div>
							{serviceCall.checkOutNotes && serviceCall.checkOutNotes.length > 0 ? (
								<div className="serviceCallNotesBlurb">{serviceCall.checkOutNotes}</div>
							) : null}

							{serviceCall.checkOutImages && serviceCall.checkOutImages.length > 0 ? (
								<ProtectedImageThumbnails
									style={{ marginTop: 16 }}
									imageWidth={64}
									imageHeight={64}
									photoStrings={serviceCall.checkOutImages}
									role={userType}
								/>
							) : null}
						</div>,
				  ]
				: []),
			...(serviceCall.checkOutTime
				? [
						<div style={{ fontSize: 16 }}>
							{serviceCall.checkInTime && serviceCall.checkOutTime ? (
								<div>
									On site for{' '}
									{moment(serviceCall.checkInTime).from(moment(serviceCall.checkOutTime), true)}
								</div>
							) : null}
							{!isReadOnly &&
							serviceCall.checkOutStatus === 'WaitingForReview' &&
							workOrder.status === 'WaitingForReview'
								? [
										<AccessPermissionChecker
											name={PERMISSION_NAMES.CAN_REVIEW_WORK_ORDER_COMPLETED}
										>
											<Button
												style={{ marginTop: 16 }}
												type="primary"
												key="approve"
												onClick={showApproveModal}
											>
												Approve Work
											</Button>
										</AccessPermissionChecker>,
										<AccessPermissionChecker
											name={PERMISSION_NAMES.CAN_REVIEW_WORK_ORDER_COMPLETED}
										>
											<Button
												key="reject"
												style={{ marginLeft: 16, marginTop: 16 }}
												onClick={showRejectModal}
											>
												Reject Work
											</Button>
										</AccessPermissionChecker>,
								  ]
								: !isReadOnly &&
								  serviceCall.checkOutStatus === 'InternalTechWorkComplete' &&
								  workOrder.status === 'InternalTechWorkComplete'
								? [
										<AccessPermissionChecker
											name={PERMISSION_NAMES.CAN_REVIEW_WORK_ORDER_COMPLETED}
										>
											<Button
												style={{ marginTop: 16 }}
												type="primary"
												key="approve"
												onClick={showReviewModal}
											>
												Review Work
											</Button>
										</AccessPermissionChecker>,
								  ]
								: null}
						</div>,
				  ]
				: []),
		],
		[isReadOnly, showApproveModal, showRejectModal, showReviewModal, userType, workOrder.status]
	);

	return nullSafeGetOrElse('associatedServiceCalls', workOrder, []).map((sc, scIdx) => (
		<div className="rowSpacing">
			<Card
				title={`Service Call ${
					nullSafeGetOrElse('associatedServiceCalls', workOrder, []).length - scIdx
				}`}
			>
				<div style={{ marginBottom: 24 }}>
					<div className="serviceCallSupplierName" style={{ marginBottom: 8 }}>
						{nullSafeGet('supplierFacility.name', sc)}
					</div>
					{sc.leadTechnicianEmail ? (
						<div
							className="materialCard__primaryTitle"
							style={{
								marginBottom: 0,
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<ContactAvatar
								size="large"
								hidePopover={true}
								style={{ marginRight: 16 }}
								contact={sc.leadTechnicianEmailContact}
								userType={userType}
							/>
							<div>
								<div className="materialCard__title">
									{nullSafeGet('leadTechnicianEmailContact.nameGiven', sc)}{' '}
									{nullSafeGet('leadTechnicianEmailContact.nameFamily', sc)}
								</div>
								<div className="materialCard__subtitle">
									{nullSafeGet('leadTechnicianEmailContact.title', sc)}{' '}
									{nullSafeGet('leadTechnicianEmailContact.department', sc)}
								</div>
							</div>
						</div>
					) : null}
				</div>
				<Timeline>
					{getServiceCallDetails(sc).map((scd, idx) => (
						<Timeline.Item key={idx}>{scd}</Timeline.Item>
					))}
				</Timeline>
			</Card>
		</div>
	));
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

export default connect(mapStateToProps)(ServiceCallDetails);

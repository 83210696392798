import { FC, useCallback, useMemo, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getCurrency, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { Button, Card, Col, Divider, message, Row } from 'antd';
import { UserName } from '../name_component/user/UserNameComponent';
import moment from 'moment';
import InvoiceActions from '../common/InvoiceActions';
import InvoicesAdditionalDetails from '../invoices_components/InvoicesAdditionalDetails';
import { getTimeDurationFromSeconds, optionalFilter } from '../../utils/DataFormatterUtils';
import * as React from 'react';
import InvoiceDraftBanner from '../invoice_draft_banner/InvoiceDraftBanner';
import { isInternalTech } from '../../utils/AuthUtils';
import {
	downloadInvoicePDFForSupplier,
	invoicesRestCrudThunksForBuyer,
	invoicesRestCrudThunksForSupplier,
	updateInvoiceGLCodeForBuyer,
	updateInvoicePONumberForBuyer,
} from '../../thunks/invoices_thunks';
import {
	appendWorkOrderNotesForBuyer,
	appendWorkOrderNotesForSupplier,
} from '../../thunks/work_orders_thunks';
import InvoiceUploadButton from '../invoice_upload_button/InvoiceUploadButton';
import { ROLE_TYPES } from '../../utils/DataConstants';
import DateTimeHover from '../date_time_component/DateTime';
import EditGLCodeForm from '../edit_gl_code_form/EditGLCodeForm';
import EditPONumberForm from '../edit_po_number_form/EditPONumberForm';
import InvoiceEditButton from '../invoice_edit_button/InvoiceEditButton';

const style = require('./InvoicePageDisplay.less');

const InvoicePageDisplay: FC<any> = ({
	invoice,
	refreshInvoice,
	showViewInvoicePage = false,
	invoicesUpdating = false,

	currentUser,
	history,
	userType,

	updateInvoice,
	appendWorkOrderNotes,
	updatePONumber,
	updateGLCode,
}) => {
	const [generatingPDF, setGeneratingPDF] = useState(false);
	const [editPONumberFormVisible, setEditPONumberFormVisible] = useState(false);
	const [editGLCodeFormVisible, setEditGLCodeFormVisible] = useState(false);
	const formRefs = {
		editGLCodeForm: null,
		editPONumberForm: null,
	};
	const saveFormRef = (formName) => (formRef) => {
		formRefs[formName] = formRef;
	};

	const isBuyerOrInternalTech = useMemo(
		() => userType === ROLE_TYPES.BUYER || isInternalTech(currentUser),
		[userType, currentUser]
	);
	const workOrder = useMemo(() => nullSafeGet('workOrder', invoice), [invoice]);
	const invoiceId = useMemo(() => nullSafeGet('id', invoice), [invoice]);
	const isBuyerInvoice = useMemo(() => invoice.isBuyerUploaded || false, [invoice]);
	const currency = useMemo(
		() => getCurrency({ workOrder: nullSafeGet('workOrder', invoice) }),
		[invoice]
	);

	const generateInvoicePDF = useCallback(
		(id) => {
			setGeneratingPDF(true);
			downloadInvoicePDFForSupplier(id)
				.catch(() => {
					message.error('An error occurred in downloading the invoice. Please try again!');
				})
				.finally(() => {
					setGeneratingPDF(false);
				});
		},
		[downloadInvoicePDFForSupplier]
	);

	const handleInvoicePDFUploadChange = useCallback(
		(invoice) => (invoiceFileUrl) => {
			const newInvoice = {
				...invoice,
				invoicePDFLink: invoiceFileUrl,
			};
			updateInvoice(newInvoice)
				.catch(() => {
					message.error('An error occurred in uploading the invoice. Please try again!');
				})
				.then(() => {
					refreshInvoice();
				});
		},
		[updateInvoice, refreshInvoice]
	);

	const handleEditPONumberSubmit = useCallback(() => {
		const form = formRefs['editPONumberForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}
			const poNumber = {
				...values,
			};
			updatePONumber(poNumber)
				.catch(() => {
					message.error('An error occurred updating the PO Number. Please try again!');
				})
				.then(() => {
					setEditPONumberFormVisible(false);
					form.resetFields();
				});
		});
	}, [formRefs, updatePONumber]);

	const handleEditGLCodeSubmit = useCallback(() => {
		const form = formRefs['editGLCodeForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			const glCode = {
				...values,
			};

			updateGLCode(glCode)
				.catch(() => {
					message.error('An error occurred updating the GL Code. Please try again!');
				})
				.then(() => {
					setEditGLCodeFormVisible(false);
					form.resetFields();
				});
		});
	}, [formRefs, updateGLCode]);

	const buyerAndInternalSupplierPassThroughItems = useMemo(
		() => [
			invoice.createdByContact
				? {
						key: 'Submitted By',
						value: (
							<UserName contact={invoice.createdByContact} mode={'inline'} placement={'top'} />
						),
				  }
				: {},
			invoice.approvedByContact
				? {
						key: 'Approved By',
						value: (
							<UserName contact={invoice.approvedByContact} mode={'inline'} placement={'top'} />
						),
				  }
				: {},
			invoice.approvedAt
				? {
						key: 'Approved On',
						value: (
							<DateTimeHover
								timestamp={nullSafeGet('approvedAt', invoice)}
								showDate={true}
								showTime={false}
							/>
						),
				  }
				: {},
			invoice.processedByContact
				? {
						key: 'Marked Processing By',
						value: (
							<UserName contact={invoice.processedByContact} mode={'inline'} placement={'top'} />
						),
				  }
				: {},
			invoice.processedAt
				? {
						key: 'Marked Processing On',
						value: (
							<DateTimeHover
								timestamp={nullSafeGet('processedAt', invoice)}
								showDate={true}
								showTime={false}
							/>
						),
				  }
				: {},
			invoice.markedPaidByContact
				? {
						key: 'Marked Paid By',
						value: (
							<UserName contact={invoice.markedPaidByContact} mode={'inline'} placement={'top'} />
						),
				  }
				: {},
			invoice.markedPaidAt
				? {
						key: 'Marked Paid On',
						value: (
							<DateTimeHover
								timestamp={nullSafeGet('markedPaidAt', invoice)}
								showDate={true}
								showTime={false}
							/>
						),
				  }
				: {},
		],
		[invoice]
	);

	const lastDisputeNote = useMemo(
		() => invoice.disputeNotes && invoice.disputeNotes.slice(-1)[0],
		[invoice]
	);
	const disputeNote = useMemo(
		() =>
			lastDisputeNote ? (
				<Col>
					<div>
						<span style={{ fontSize: 16 }}>
							Disputed by{' '}
							<UserName
								mode={'inline'}
								contact={nullSafeGet(`noteAddedByContact`, lastDisputeNote)}
							/>{' '}
							{moment(nullSafeGet(`noteAddedAt`, lastDisputeNote)).fromNow()}
						</span>
					</div>
					<div
						style={{
							display: 'inline-flex',
							background: '#fafafa',
							color: 'rgba(0, 0, 0, 0.65)',
							borderRadius: '30px',
							padding: '8px 16px',
						}}
					>
						<span
							style={{
								fontSize: 16,
							}}
						>
							{nullSafeGet(`text`, lastDisputeNote)}
						</span>
					</div>
					{!isBuyerOrInternalTech ? (
						<div className={'mt-2 flex gap-4'}>
							<Button
								type="primary"
								key="Edit"
								onClick={() => history.push(`/${userType}/invoices/edit/${invoiceId}`)}
							>
								Edit Invoice
							</Button>
							<Button
								key="Note"
								onClick={() =>
									history.push(`/${userType}/workOrders/detail/${invoice.workOrderId}/notes`)
								}
							>
								Send a note
							</Button>
						</div>
					) : null}
				</Col>
			) : null,
		[history, invoice, invoiceId, isBuyerOrInternalTech, lastDisputeNote, userType]
	);

	return (
		<Row style={{ margin: '16px 8px' }} gutter={12} key={invoice.id}>
			{isBuyerOrInternalTech
				? [
						<EditGLCodeForm
							formData={invoice}
							wrappedComponentRef={saveFormRef('editGLCodeForm')}
							visible={editGLCodeFormVisible}
							confirming={invoicesUpdating}
							onCancel={() => setEditGLCodeFormVisible(false)}
							onSubmit={handleEditGLCodeSubmit}
						/>,
						<EditPONumberForm
							formData={invoice}
							wrappedComponentRef={saveFormRef('editPONumberForm')}
							visible={editPONumberFormVisible}
							confirming={invoicesUpdating}
							onCancel={() => setEditPONumberFormVisible(false)}
							onSubmit={handleEditPONumberSubmit}
						/>,
				  ]
				: null}
			<Col span={24}>
				<Card>
					{showViewInvoicePage ? (
						<Row justify={'space-between'}>
							<Col />
							<Col>
								<Button
									onClick={() => {
										history.push(`/${userType}/invoices/detail/${invoice.id}`);
									}}
								>
									View Invoice Page
								</Button>
								<InvoiceEditButton invoice={invoice} />
							</Col>
						</Row>
					) : null}
					<InvoiceActions
						invoice={invoice}
						refreshInvoice={refreshInvoice}
						updateInvoice={updateInvoice}
						style={style}
						appendWorkOrderNotes={appendWorkOrderNotes}
						showEditAndViewButton={false}
					/>
					<InvoiceDraftBanner
						invoice={invoice}
						generateInvoicePDF={generateInvoicePDF}
						handleInvoicePDFUploadChange={handleInvoicePDFUploadChange}
						generatingPDF={generatingPDF}
					/>
					<InvoicesAdditionalDetails
						invoice={invoice}
						passthroughItems={[
							<InvoiceUploadButton
								handleInvoicePDFUploadChange={handleInvoicePDFUploadChange}
								invoice={invoice}
								generatingPDF={generatingPDF}
								generateInvoicePDF={generateInvoicePDF}
							/>,
							userType === ROLE_TYPES.BUYER
								? {
										key: 'GL Code',
										value: (
											<div>
												{optionalFilter(invoice.glCode)}
												<Button
													onClick={() => setEditGLCodeFormVisible(true)}
													size="large"
													type="link"
												>
													<i className="icons8-font icons8-edit">Edit</i>
												</Button>
											</div>
										),
								  }
								: {},
							userType === ROLE_TYPES.BUYER
								? {
										key: 'PO Number',
										value: (
											<div>
												{optionalFilter(invoice.poNumber)}
												<Button
													onClick={() => setEditPONumberFormVisible(true)}
													size="large"
													type="link"
												>
													<i className="icons8-font icons8-edit">Edit</i>
												</Button>
											</div>
										),
								  }
								: {},
							{
								key: 'File',
								value: (
									<InvoiceUploadButton
										handleInvoicePDFUploadChange={handleInvoicePDFUploadChange}
										invoice={invoice}
										generatingPDF={generatingPDF}
										generateInvoicePDF={generateInvoicePDF}
									/>
								),
								className: 'detailRow__item firstRow lastCol',
							},
							{
								key: 'Submitted By Company',
								value: isBuyerInvoice
									? nullSafeGet('buyerCompany.displayName', workOrder)
									: nullSafeGet('supplierFacility.displayName', workOrder),
							},
							{
								key: 'Submitted On',
								value: (
									<DateTimeHover
										timestamp={nullSafeGet('createdAt', invoice)}
										showDate={true}
										showTime={false}
									/>
								),
							},
							...(isBuyerOrInternalTech ? buyerAndInternalSupplierPassThroughItems : []),
						]}
					/>
					<Divider />
					<div className="sectionHeaderWrapper" style={{ marginBottom: 8 }}>
						<span className="sectionHeaderText">Labor</span>
						<span className="sectionHeaderText2">
							{currency.format(invoice.laborTotalBeforeTax)}
						</span>
					</div>
					{nullSafeGetOrElse('laborLineItems', invoice, []).map((lli, idx) => (
						<div
							className="sectionHeaderWrapper2"
							style={{
								marginBottom:
									idx === nullSafeGetOrElse('laborLineItems', invoice, []).length - 1 ? 16 : 8,
							}}
						>
							<span className="lineItemDetailText">
								{lli.numberOfTech} {lli.roleType} * {getTimeDurationFromSeconds(lli.seconds)} @{' '}
								{currency.format(parseFloat(lli.hourlyRate))} / hour
							</span>
							<span className="lineItemDetailText">{currency.format(parseFloat(lli.amount))}</span>
						</div>
					))}
					<Divider />

					<div className="sectionHeaderWrapper" style={{ marginBottom: 8 }}>
						<span className="sectionHeaderText">Materials</span>
						<span className="sectionHeaderText2">
							{currency.format(invoice.materialTotalBeforeTax)}
						</span>
					</div>
					{nullSafeGetOrElse('materialLineItems', invoice, []).map((mli, idx) => (
						<div
							className="sectionHeaderWrapper2"
							style={{
								marginBottom:
									idx === nullSafeGetOrElse('laborLineItems', invoice, []).length - 1 ? 16 : 8,
							}}
						>
							<span className="lineItemDetailText">
								{mli.quantity} {mli.unitType} of {mli.description}
								{mli.partNumber ? ` (${mli.partNumber})` : null} @{' '}
								{currency.format(parseFloat(mli.unitPrice))} / {mli.unitType}
							</span>
							<span className="lineItemDetailText">{currency.format(parseFloat(mli.amount))}</span>
						</div>
					))}
					<Divider />
					<div className="sectionHeaderWrapper">
						<span className="sectionHeaderText">Travel</span>
						<span className="sectionHeaderText2">
							{currency.format(parseFloat(invoice.travelTotalBeforeTax) || 0)}
						</span>
					</div>
					<Divider />
					<div className="sectionHeaderWrapper">
						<span className="sectionHeaderText">Freight</span>
						<span className="sectionHeaderText2">
							{currency.format(parseFloat(invoice.freightTotalBeforeTax) || 0)}
						</span>
					</div>
					<Divider />
					<div className="sectionHeaderWrapper">
						<span className="sectionHeaderText">Miscellaneous</span>
						<span className="sectionHeaderText2">
							{currency.format(parseFloat(invoice.miscTotalBeforeTax) || 0)}
						</span>
					</div>
					{nullSafeGetOrElse('miscLineItems', invoice, []).map((mli, idx) => (
						<div
							className="sectionHeaderWrapper2"
							style={{
								marginBottom:
									idx === nullSafeGetOrElse('miscLineItems', invoice, []).length - 1 ? 16 : 8,
							}}
						>
							<span className="lineItemDetailText">{mli.description}</span>
							<span className="lineItemDetailText">{currency.format(parseFloat(mli.amount))}</span>
						</div>
					))}
					<Divider />
					<div className="sectionHeaderWrapper">
						<span className="sectionHeaderText">Total Before Tax</span>
						<span className="sectionHeaderText2">
							{currency.format(invoice.invoiceTotalBeforeTax)}
						</span>
					</div>
					<Divider />
					<div className="sectionHeaderWrapper">
						<span className="sectionHeaderText">Tax</span>
						<span className="sectionHeaderText2">{currency.format(invoice.invoiceTax)}</span>
					</div>
					{nullSafeGetOrElse('taxLineItems', invoice, []).map((lli, idx) => (
						<div
							className="sectionHeaderWrapper2"
							style={{
								marginBottom:
									idx === nullSafeGetOrElse('taxLineItems', invoice, []).length - 1 ? 16 : 8,
							}}
						>
							<span className="lineItemDetailText">{lli.taxType}</span>
							<span className="lineItemDetailText">{currency.format(parseFloat(lli.amount))}</span>
						</div>
					))}
					<Divider />
					<div className="sectionHeaderWrapper">
						<span className="sectionHeaderText" style={{ fontWeight: 600 }}>
							Total After Tax
						</span>
						<span className="sectionHeaderText2" style={{ fontWeight: 600 }}>
							{currency.format(invoice.invoiceTotalAfterTax)}
						</span>
					</div>
				</Card>
			</Col>
		</Row>
	);
};

const mapStateToProps = (state, ownProps) => ({
	currentUser: state.session.currentUser,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	updateInvoice: (entity) =>
		ownProps.userType === ROLE_TYPES.SUPPLIER
			? dispatch(invoicesRestCrudThunksForSupplier.update(entity))
			: dispatch(invoicesRestCrudThunksForBuyer.update(entity)),
	appendWorkOrderNotes: (entity) =>
		ownProps.userType === ROLE_TYPES.SUPPLIER
			? dispatch(appendWorkOrderNotesForSupplier(entity))
			: dispatch(appendWorkOrderNotesForBuyer(entity)),
	updateGLCode: (entity) => dispatch(updateInvoiceGLCodeForBuyer(entity)),
	updatePONumber: (entity) => dispatch(updateInvoicePONumberForBuyer(entity)),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(InvoicePageDisplay)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import {
	checkHasApprovalHierarchy,
	compareStrings,
	getCurrency,
	nullSafeGet,
	nullSafeGetOrElse,
	roundOffToDecimal,
} from '../../utils/DataAccessUtils';
import { Button, Col, message, Popover, Row } from 'antd';
import * as React from 'react';
import { useMemo, useRef, useState } from 'react';
import ApprovalHierarchyDisplayWorkOrderModal from './ApprovalHierarchyDisplayWorkOrderModal';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { isUserAllowedToAccess, PERMISSION_NAMES } from '../../utils/AuthUtils';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import {
	forwardApprovalToUserForBuyer,
	forwardApprovalToUserForSupplier,
} from '../../thunks/company_approval_thunks';
import ForwardApproval from '../forward_approval/ForwardApproval';
import DeferWorkOrderModal from '../defer_work_order_modal/DeferWorkOrderModal';
import { deferWorkOrderForBuyer, deferWorkOrderForSupplier } from '../../thunks/work_orders_thunks';
import { Form } from '@ant-design/compatible';
import { CURRENCIES, ROLE_TYPES } from '../../utils/DataConstants';
import BudgetChart from '../charts/BudgetChart';
import _ from 'lodash';

const ApprovalHierarchyDisplayWorkOrder: React.FC<any> = ({
	workOrder,
	currentUser,
	companyConfig,
	style = {},
	allowedToApprove = true,
	userType,
	refreshWorkOrder,
	syncWorkOrder,
	deferWorkOrder,

	history,
	location,

	forwardApprovalToUser,
}): React.ReactElement => {
	let searchString = location.search;
	if (searchString[0] === '?') {
		searchString = searchString.slice(1);
	}
	const defaultActionsAllowed = isUserAllowedToAccess(userType)(
		PERMISSION_NAMES.CAN_APPROVE_WORK_ORDERS,
		companyConfig,
		currentUser.roles
	);
	const [showModal, setShowModal] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [deferModalVisible, setDeferModalVisible] = useState(false);

	const formRefs = {};
	const saveFormRef = (formName) => (formRef) => {
		formRefs[formName] = formRef;
	};
	const handleDeferSubmit = () => {
		const form = formRefs['deferForm'].props.form;

		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			const entity = {
				id: values.workOrderId,
				snoozeUntil: values.deferDate,
			};

			setButtonLoading(true);
			deferWorkOrder(entity).then(() => {
				setDeferModalVisible(false);
				setButtonLoading(false);
				message.success(`Deferred Work Order.`);
			});
		});
	};
	const cancelDefer = () => {
		setDeferModalVisible(false);
	};

	const status = nullSafeGet('displayStatus', workOrder);
	const reason = useRef();
	const setReason = (str) => (reason.current = str);
	const showStatus = (status) => {
		switch (status) {
			case 'Pending':
				return 'Pending Approvers';
			default:
				return 'Approver List';
		}
	};
	const hasApprovalHierarchy = checkHasApprovalHierarchy(workOrder);
	const hasApprovers = (tiers) =>
		tiers.flatMap((tier) => tier.approvers).some((approvers) => approvers.length > 0);
	const userTier = (tiers) =>
		hasApprovalHierarchy
			? tiers.filter((tier) =>
					tier.approvers.some((approver) => compareStrings(approver.email, currentUser.email))
			  ).length > 0
				? tiers.find((tier) =>
						tier.approvers.some((approver) => compareStrings(approver.email, currentUser.email))
				  )
				: undefined
			: tiers[0];

	const currency = getCurrency({ workOrder });
	const buyerCompanyCurrency = getCurrency({
		buyerCompany: nullSafeGet('buyerCompany', workOrder),
	});
	const exchangeRate =
		currency.id === buyerCompanyCurrency.id
			? 1
			: nullSafeGetOrElse('currencyExchangeRateLocationToSupplier', workOrder, 1) *
			  nullSafeGetOrElse('currencyExchangeRateBuyerToLocation', workOrder, 1);
	let nteTotal = nullSafeGetOrElse('nte', workOrder, 0) / exchangeRate;
	let lessThanNte = true;
	let highestValidAmount = 0;
	let highestApprovedAmount = 0;
	const highestApprovalTierAmount = (tiers) => {
		let highestTiers = [...tiers]
			.filter((tier) => {
				let approvedByUsers = nullSafeGet('approvedByUsers', tier);
				let declinedByUsers = nullSafeGet('declinedByUsers', tier);
				if (approvedByUsers && declinedByUsers) {
					return approvedByUsers.concat(declinedByUsers).length > 0;
				} else {
					return false;
				}
			})
			.map((tier) => {
				return nullSafeGetOrElse('amountUpto', tier, Infinity);
			});
		if (highestTiers.length > 0) {
			return highestTiers[highestTiers.length - 1];
		}
		return 0;
	};

	let lastAmount = 0;
	const tiers = nullSafeGet('approvalHierarchy.tierDetails', workOrder)
		? nullSafeGet('approvalHierarchy.tierDetails', workOrder)
				//Sort tiers primarily by tier.amountUpto, secondarily by tier.label
				.sort((a, b) =>
					a.amountUpto && b.amountUpto
						? parseFloat(a.amountUpto) > parseFloat(b.amountUpto)
							? 1
							: parseInt(a.label) > parseInt(b.label)
							? 1
							: -1
						: a.amountUpto === undefined
						? 1
						: -1
				)
				//Setup tier objects
				.map((tier) => {
					if (nullSafeGetOrElse('approvedByUsers', tier, []).length > 0) {
						highestApprovedAmount = parseInt(nullSafeGetOrElse('amountUpto', tier, Infinity));
					}
					return {
						...tier,
						approvedByUsers: nullSafeGetOrElse('approvedByUsers', tier, []).filter(
							(user, i, users) => i === users.findIndex((u) => compareStrings(u.email, user.email))
						),
						declinedByUsers: nullSafeGetOrElse('declinedByUsers', tier, []).filter(
							(user, i, users) => i === users.findIndex((u) => compareStrings(u.email, user.email))
						),
					};
				})
				.map((tier, i, tiers) => {
					let message;
					let isVisible = () => {
						//We also want to display tiers which fit one of the following criteria:
						let amount = parseInt(nullSafeGet('amountUpto', tier));

						//1.This tier has either approvers or decliners and is >= the highest amount approved
						if (
							highestApprovedAmount > amount &&
							tier.approvedByUsers.length === 0 &&
							tier.declinedByUsers.length === 0
						) {
							//Not shown because a higher tier already approved
							message = 'A higher tier has already approved';
							return false;
						}
						if (
							highestApprovedAmount === amount &&
							tier.approvers.length === 0 &&
							tier.approvedByUsers.length === 0
						) {
							//Not shown because an equal tier has already approved and this tier has no approvers
							message =
								'An equal tier has approved and this tier has no approvers for this location';
							return false;
						}
						if (!lessThanNte) {
							let lastAmount = parseInt(nullSafeGetOrElse('amountUpto', tiers[i - 1], 0));
							//3. This tier has already been approved or declined
							if (tier.approvedByUsers.length > 0 || tier.declinedByUsers.length > 0) {
								return true;
							} else {
								//4. This tier is the same amount as the previous tier
								if (highestValidAmount === amount && highestValidAmount === lastAmount) {
									return true;
								} else {
									message = `Approval from this tier isn't required for work orders with an nte less than ${currency.format(
										(lastAmount ? lastAmount : amount) * exchangeRate
									)}`;
									return false;
								}
							}
						}

						if (amount && nteTotal) {
							if (parseFloat(amount) > parseFloat(nteTotal)) {
								//2. This tier is one tier over the work order nte and contains potential approvers
								highestValidAmount = amount;
								if (nullSafeGetOrElse('approvers.length', tier, 0) > 0) {
									lessThanNte = false;
								}
							}
						} else {
							//1. This tier is less than the work order nte
							lessThanNte = false;
						}
						return true;
					};
					lastAmount =
						nullSafeGetOrElse('amountUpto', tiers[i - 1], 0) === nullSafeGet('amountUpto', tier)
							? lastAmount
							: nullSafeGetOrElse('amountUpto', tiers[i - 1], 0);
					return { ...tier, isVisible: isVisible(), message, lastAmount };
				})
		: [];
	const highestAmount = highestApprovalTierAmount(tiers);
	let myTier = userTier(tiers);
	const myTierAmount = nullSafeGet('amountUpTo', myTier);
	const showApproval = workOrder.displayStatus === 'Pending';
	const allowApproval = useMemo(() => {
		if (status !== 'approved') {
			if (hasApprovalHierarchy && myTier !== undefined) {
				//Has approval Hierarchy and a tier
				if (myTierAmount === undefined || parseFloat(myTierAmount) >= highestAmount) {
					if (
						!nullSafeGetOrElse('approvedByUsers', myTier, []).some((user) =>
							compareStrings(currentUser.email, user.email)
						)
					) {
						return true;
					} else {
						setReason('Your tier has already approved.');
						return false;
					}
				}
			} else if (!hasApprovalHierarchy && myTier !== undefined) {
				//No approval Hierarchy
				if (
					nullSafeGetOrElse('approvedByUsers', myTier, []).some((user) =>
						compareStrings(currentUser.email, user.email)
					)
				) {
					setReason('You have already approved.');
					return false;
				} else if (nullSafeGetOrElse('approvedByUsers', myTier, []).length > 1) {
					setReason('Someone has already approved.');
					return false;
				} else {
					setReason(`You are not allowed to approve this work order.`);
					return defaultActionsAllowed;
				}
			} else if (!hasApprovalHierarchy && myTier === undefined) {
				setReason(`You are not allowed to approve this work order.`);
				return defaultActionsAllowed;
			}
			setReason('You are not in the approval hierarchy.');
		} else {
			setReason('This work order has already been approved.');
		}
		return false;
	}, []);

	const focusedBudget = nullSafeGetOrElse('budgets.0', workOrder, {});
	const amount = nteTotal;

	const { budget, budgetConsumed, thresholdPercentage } = focusedBudget;
	const consumed = parseFloat(budgetConsumed);
	const threshold = (parseFloat(thresholdPercentage) / 100) * budget;
	const overPercentage = Math.abs(roundOffToDecimal(((consumed + amount) / budget) * 100 - 100, 0));
	const isExceedingBudget = consumed + amount > budget;
	const isExceedingThreshold = consumed + amount > threshold && !isExceedingBudget;

	const hasAlert = (isExceedingBudget || isExceedingThreshold) && amount > 0;

	const budgetMessage = isExceedingBudget
		? `Approving this work order will put you ${overPercentage}% over budget.`
		: isExceedingThreshold
		? `Approving this work order will put you ${overPercentage}% from your budget limit.`
		: undefined;
	const icon = isExceedingBudget ? (
		<i className="icons8-font icons8-error" style={{ marginRight: 8, color: 'red' }} />
	) : (
		<i className="icons8-font icons8-error" style={{ marginRight: 8, color: 'orange' }} />
	);

	const approvalForwardingEnabled =
		userType === 'supplier'
			? _.get(
					currentUser,
					'facility.privateBuyerCompanySettings.config.approvalHierarchyConfig.addUserToWorkOrderApprovalHierarchyOnTheGo',
					true
			  )
			: _.get(
					companyConfig,
					'config.approvalHierarchyConfig.addUserToWorkOrderApprovalHierarchyOnTheGo',
					true
			  );
	const showForwardForApproval =
		approvalForwardingEnabled && (hasApprovalHierarchy || hasApprovers(tiers));
	const showAlert = hasAlert && compareStrings(workOrder.displayStatus, 'Pending');
	const workOrderString =
		workOrder && workOrder.displayStatus
			? compareStrings(workOrder.displayStatus, 'Pending')
				? showAlert && allowApproval
					? 'Are you sure you want to approve this work order?'
					: 'This work order is on hold until it has been fully approved'
				: null
			: null;

	const isInApprovalHierarchy = hasApprovalHierarchy && myTier !== undefined;
	const locationCurrency = CURRENCIES[nullSafeGetOrElse('locationCurrencyId', workOrder, 'USD')];
	const showDeferred = nullSafeGetOrElse(
		'config.workOrder.allowWorkOrderToBeDeferred',
		companyConfig,
		false
	);
	return (
		<Row className={'flex-nowrap'}>
			<Col>
				<div className={'flex-row'}>
					<DeferWorkOrderModal
						wrappedComponentRef={saveFormRef('deferForm')}
						visible={deferModalVisible}
						workOrderId={workOrder.id}
						onCancel={cancelDefer}
						onSubmit={handleDeferSubmit}
						formTitle={'Defer this work order?'}
						formText={'Defer'}
						buttonLoading={buttonLoading}
					/>
					<ApprovalHierarchyDisplayWorkOrderModal
						currentWorkOrder={workOrder}
						tiers={tiers}
						hasApprovalHierarchy={hasApprovalHierarchy}
						setVisible={setShowModal}
						visible={showModal}
						style={style}
						reason={reason}
						allowApproval={allowApproval}
						currency={currency}
						currentUser={currentUser}
						refresh={refreshWorkOrder}
						syncWorkOrder={syncWorkOrder}
					/>
					{showAlert && allowApproval ? (
						<div style={{ marginBottom: 8 }}>
							<h5 style={{ display: 'flex' }}>
								{icon} {budgetMessage}
							</h5>
							<div style={{ marginBottom: 8 }}>
								<span style={{ fontSize: 16 }}>{workOrderString}</span>
							</div>
						</div>
					) : (
						<div>
							<h5 style={{ display: 'flex' }}>{workOrderString}</h5>
						</div>
					)}
				</div>
				{allowedToApprove ? (
					<div
						style={{
							display: 'inline-flex',
							position: 'relative',
						}}
					>
						<div style={{ gap: '16px', alignContent: 'flex-start' }} className={'flex flex-wrap'}>
							{hasAlert && showDeferred ? (
								allowApproval ? (
									<Button
										type="primary"
										key="defer"
										loading={buttonLoading}
										onClick={() => {
											setDeferModalVisible(true);
										}}
									>
										Defer Work Order
									</Button>
								) : (
									<Popover
										disabled={allowApproval}
										title={`Why can't I defer?`}
										content={nullSafeGet('current', reason)}
									>
										<Button type="primary" key="defer" disabled={true}>
											Defer Work Order
										</Button>
									</Popover>
								)
							) : null}
							{showApproval ? (
								allowApproval ? (
									<Button
										type={hasAlert && showDeferred ? 'default' : 'primary'}
										key="approve"
										loading={buttonLoading}
										onClick={() => {
											setButtonLoading(true);
											history.push(
												getLinkWIthBackLinkParams(
													location,
													'',
													`/${userType}/workOrders/approve/${workOrder.id}`
												)
											);
										}}
									>
										Approve Work Order
									</Button>
								) : (
									<Popover
										disabled={allowApproval}
										title={`Why can't I approve?`}
										content={nullSafeGet('current', reason)}
									>
										<Button
											type="primary"
											key="approve"
											loading={buttonLoading}
											disabled={true}
											onClick={() => {
												setButtonLoading(true);
												history.push(
													getLinkWIthBackLinkParams(
														location,
														'',
														`/${userType}/workOrders/approve/${workOrder.id}`
													)
												);
											}}
										>
											Approve Work Order
										</Button>
									</Popover>
								)
							) : null}
							{showForwardForApproval ? (
								<ForwardApproval
									disabled={!(hasApprovalHierarchy && myTier !== undefined)}
									reason={reason}
									workOrder={workOrder}
									entity={workOrder}
									refresh={refreshWorkOrder}
									forwardApprovalToUser={forwardApprovalToUser}
									buttonLoading={buttonLoading}
								/>
							) : null}
							{hasApprovalHierarchy || hasApprovers(tiers) ? (
								<div
									className={'inline-flex flex-row items-center'}
									style={{ cursor: 'pointer', fontSize: '16px' }}
									onClick={() => setShowModal(true)}
								>
									<i className="icons8-font icons8-multiple-users" />
									<span style={{ marginLeft: '8px', textDecoration: 'underline' }}>
										{showStatus(status)}
									</span>
								</div>
							) : null}
						</div>
						<Col style={{ marginBottom: -60, marginLeft: -40, marginTop: -8 }}>
							{focusedBudget && hasAlert && allowApproval ? (
								<BudgetChart
									currency={locationCurrency}
									data={[
										{
											budget: focusedBudget.title,
											'Budget Used': consumed,
											'This Work Order': amount,
											'Remaining Budget': budget - (consumed + amount),
										},
									]}
									consumed={consumed > 0}
									remaining={budget - (consumed + amount) > 0}
									budgetTotal={budget}
									recordType={'Work Order'}
								/>
							) : null}
						</Col>
					</div>
				) : null}
			</Col>
		</Row>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	location: ownProps.location,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
	forwardApprovalToUser: (entity, email) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? forwardApprovalToUserForSupplier('workOrder', entity, email)
				: forwardApprovalToUserForBuyer('workOrder', entity, email)
		),
	deferWorkOrder: (entity) =>
		ownProps.userType === ROLE_TYPES.SUPPLIER
			? dispatch(deferWorkOrderForSupplier(entity))
			: dispatch(deferWorkOrderForBuyer(entity)),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ApprovalHierarchyDisplayWorkOrder)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
		companyConfig: (state as any).company_config.detail,
	}),
	() => ({})
)(Form.create()(ComponentWithoutUserType));

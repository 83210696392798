import * as React from 'react';
import { Layout, Row, Col, Card } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { meterTypesRestCrudThunksForBuyer } from '../../thunks/meter_types_thunks';
import { TwoLineList } from '../two_line_list/TwoLineList';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;

const style = require('./MeterTypesDetailDetailsPage.less');

class MeterTypesDetailPage extends React.Component<any, any> {
	componentDidMount() {
		const {
			getMeterType,

			match,
		} = this.props;
		const meterTypeId = match.params.id;
		getMeterType(meterTypeId);
	}

	render() {
		const { meterType, meterTypesFetching, match } = this.props;
		const meterTypeId = match.params.id;
		const meterTypeDetails = [
			{
				key: 'Value Unit',
				value: meterType.valueUnit,
			},
			{
				key: 'Value Type',
				value: meterType.valueType,
			},
		];

		return meterTypesFetching ? (
			<PageLoadingPlaceholder />
		) : (
			<div>
				<LogOnMountWithStandardEventProperties eventType="visited meter type detail page" />
				<Content className="meterTypesDetailDetailsPage">
					{/*<ScrollToTopOnMount/>*/}
					{/*<BackTop/>*/}
					<Row style={{ margin: '16px 12px' }}>
						<Col span={24}>
							<Card>
								<div className="meterTypesDetailDetailsPage__listSection">
									{meterType.id ? (
										<TwoLineList
											listItems={meterTypeDetails}
											lineOneRender={(record) => record.value}
											lineTwoRender={(record) => record.key}
										/>
									) : null}
								</div>
							</Card>
						</Col>
					</Row>
				</Content>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	meterTypesFetching: state.meter_types.fetching,
	meterType: state.meter_types.detail,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	getMeterType: (id) => dispatch(meterTypesRestCrudThunksForBuyer.readOne(id)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(MeterTypesDetailPage)
);

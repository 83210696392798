import * as React from 'react';
import { Layout, Row, Col, Card, Button } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AddressDisplay } from '../address_display/AddressDisplay';
import { KeyValueDisplay } from '../key_value_display/KeyValueDisplay';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { capitalize, currencyFormatter, optionalFilter } from '../../utils/DataFormatterUtils';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { FlexibleImageAvatar } from '../flexible-image-avatar/FlexibleImageAvatar';
import { getProtectedImageUriForSupplier } from '../../utils/FileAccessUtils';
import { fieldTechsRestCrudThunksForSupplier } from '../../thunks/field_techs_thunks';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { EmptyState } from '../empty_state/EmptyState';
import { WorkOrderRowDisplay } from '../work_order_row_display/WorkOrderRowDisplay';
import { workOrdersRestCrudThunksForSupplier } from '../../thunks/work_orders_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import EditManageStockLocationsModal from './EditManageStockLocationsModal';
import HyperLink, { ENTITY_TYPE } from '../common/HyperLink';

const { Content } = Layout;

require('./FieldTechsDetailDetailsPage.less');

const FIELD_TECH_ASSOCIATED_OPEN_WORK_ORDERS_TARGET_COLLECTION =
	'fieldTechAssociatedOpenWorkOrders';
const FIELD_TECH_ASSOCIATED_WORK_HISTORY_TARGET_COLLECTION = 'fieldTechAssociatedWorkHistory';

class FieldTechsDetailDetailsPage extends React.Component<any, any> {
	initialPaginationActiveWorkOrders: any = {
		current: 1,
		pageSize: 3,
		pageSizeOptions: ['3', '5', '10', '25', '50'],
	};
	initialPaginationWorkHistory: any = {
		current: 1,
		pageSize: 3,
		pageSizeOptions: ['3', '5', '10', '25', '50'],
	};
	constructor(props) {
		super(props);
		this.state = {
			displayMode: 'list',
			showEditSL: false,
		};
	}

	refreshFieldTech = () => {
		const { getFieldTech, match } = this.props;
		const fieldTechId = match.params.id;
		getFieldTech(fieldTechId);
	};

	componentDidMount() {
		this.refreshFieldTech();
	}

	hideEditManageStockLocations = () => this.setState({ showEditSL: false });

	editManageStockLocations = () => this.setState({ showEditSL: true });

	handleManageStockLocationSuccess = () => {
		this.hideEditManageStockLocations();
		this.refreshFieldTech();
	};

	render() {
		const { fieldTech, fieldTechsFetching, history, currentUser } = this.props;

		const fieldTechDetails = {
			Role: nullSafeGet('role', fieldTech) ? capitalize(nullSafeGet('role', fieldTech)) : '--',
			'Hourly Rate': currencyFormatter()(nullSafeGet('hourlyRate', fieldTech)),
		};

		const contactDetails = {
			Email: nullSafeGet('supplierContact.email', fieldTech),
			'Work Phone': optionalFilter(nullSafeGet('supplierContact.phoneWork', fieldTech)),
			'Mobile Phone': optionalFilter(nullSafeGet('supplierContact.phoneMobile', fieldTech)),
			Fax: optionalFilter(nullSafeGet('supplierContact.fax', fieldTech)),
		};

		const facilityDetailsRenderFuncs = {
			Facility: (facility) => (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<div style={{ marginRight: 8 }}>
						<FlexibleImageAvatar
							displayName={currentUser.facility.displayName}
							showPopover={false}
							popoverText={currentUser.facility.displayName}
							imageUrl={currentUser.facility.logoURL}
							width="32px"
							height="32px"
						/>
					</div>
					<div style={{ fontSize: 16 }}>{facility.displayName}</div>
				</div>
			),
			Address: (address) => <AddressDisplay address={address} />,
		};

		let imgFileId, imgFileName, photoUri;
		if (currentUser.photoLink) {
			[imgFileId, imgFileName] = currentUser.photoLink.split('/');
			photoUri = getProtectedImageUriForSupplier(imgFileId, imgFileName, 'auto', 240);
		}
		const popupRenderFunc = (record) => `
            <div style="max-width: 480px; padding: 8px;">
                <a href="/supplier/workOrders/detail/${record.id}">
                    <div
                        class="workOrderRowDisplay__rowTitle"
                        style="margin-bottom: 8px;"
                    >
                      ${record.title}
                    </div>
                    <div class="workOrderRowDisplay__rowSubtitle">
                        <div class="workOrderRowDisplay__rowInlineGroup">
                            <i class="icons8-font icons8-location-marker"></i>
                            <span>${nullSafeGet('location.storeId', record)}</span>
                        </div>
                        <div class="workOrderRowDisplay__rowInlineGroup">
                            <i class="icons8-font icons8-maintenance-filled"></i>
                            <span>${nullSafeGet('spendCategory.name', record)}</span>
                        </div>
                    </div>
                </a>
            </div>
        `;
		const columns = [
			{
				title: 'Name',
				dataIndex: 'title',
				render: (text, record) => <WorkOrderRowDisplay workOrder={record} />,
			},
		];

		const onRow = (record) => ({
			onClick: () => history.push(`/supplier/workOrders/detail/${record.id}`),
		});

		return (
			<Content className="fieldTechDetailDetailsPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited supplier field tech detail page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				{fieldTechsFetching ? (
					<PageLoadingPlaceholder />
				) : (
					<div>
						<Row style={{ margin: '0 -0.5em' }} gutter={16}>
							<Col span={16}>
								<div className="rowSpacing">
									<Card title="Active Work Orders">
										<PaginatedReduxTable
											mode={this.state.displayMode}
											longitudeAccessor={(el) =>
												nullSafeGet('location.buyerFacility.longitude', el)
											}
											latitudeAccessor={(el) => nullSafeGet('location.buyerFacility.latitude', el)}
											popupRenderFunc={popupRenderFunc}
											showHeader={false}
											emptyState={
												<EmptyState
													graphic={
														<img
															style={{ marginBottom: 8 }}
															src="https://s3.amazonaws.com/mock-data-assets/categories/images/lighthouse.svg"
															alt="No open work orders found"
														/>
													}
													headline={'No open work orders found'}
													body={
														<div style={{ textAlign: 'center' }}>
															<div style={{ maxWidth: 450, marginBottom: 16 }}></div>
														</div>
													}
												/>
											}
											collectionName="work_orders"
											targetCollectionName={
												FIELD_TECH_ASSOCIATED_OPEN_WORK_ORDERS_TARGET_COLLECTION
											}
											columns={columns}
											onRow={onRow}
											keyAccessor={(el) => el.id}
											initialFilters={{
												hasSupplierArchived: false,
												isActive: true,
												technicianEmail: nullSafeGet('email', fieldTech),
											}}
											initialPagination={this.initialPaginationActiveWorkOrders}
											fetchData={workOrdersRestCrudThunksForSupplier.read}
										/>
									</Card>
								</div>
								<div className="rowSpacing">
									<Card title="Work History">
										<PaginatedReduxTable
											mode={this.state.displayMode}
											longitudeAccessor={(el) =>
												nullSafeGet('location.buyerFacility.longitude', el)
											}
											latitudeAccessor={(el) => nullSafeGet('location.buyerFacility.latitude', el)}
											popupRenderFunc={popupRenderFunc}
											showHeader={false}
											emptyState={
												<EmptyState
													graphic={
														<img
															style={{ marginBottom: 8 }}
															src="https://s3.amazonaws.com/mock-data-assets/categories/images/lighthouse.svg"
															alt="No work history found"
														/>
													}
													headline={'No work history found'}
													body={
														<div style={{ textAlign: 'center' }}>
															<div style={{ maxWidth: 450, marginBottom: 16 }}></div>
														</div>
													}
												/>
											}
											collectionName="work_orders"
											targetCollectionName={FIELD_TECH_ASSOCIATED_WORK_HISTORY_TARGET_COLLECTION}
											columns={columns}
											onRow={onRow}
											keyAccessor={(el) => el.id}
											initialFilters={{
												hasSupplierArchived: false,
												isActive: false,
												technicianEmail: nullSafeGet('email', fieldTech),
											}}
											initialPagination={this.initialPaginationWorkHistory}
											fetchData={workOrdersRestCrudThunksForSupplier.read}
										/>
									</Card>
								</div>
							</Col>
							<Col span={8}>
								<div className="rowSpacing">
									<Card
										title="Managed Stock Locations"
										extra={
											<Button type="link" onClick={this.editManageStockLocations}>
												Edit
											</Button>
										}
									>
										{nullSafeGet('managedStockLocations.0', fieldTech) ? (
											<div>
												{fieldTech.managedStockLocations.map((sl) => (
													<HyperLink
														key={`sl-${sl.id}`}
														text={sl.name}
														entityType={ENTITY_TYPE.STOCK_LOCATION}
														entityId={sl.id}
														backLinkText={'Back to Field Tech Detail'}
													/>
												))}
											</div>
										) : nullSafeGet('canManageAllStockLocations', fieldTech) ? (
											'All accessible stock locations'
										) : (
											'No Stock Location Managed'
										)}
									</Card>
								</div>
								<div className="rowSpacing">
									<Card title="Problem Types">
										{nullSafeGet('problemTypes.0', fieldTech) ? (
											<div>
												{fieldTech.problemTypes.map((pt) => (
													<div>{pt.name}</div>
												))}
											</div>
										) : (
											'All Problem Types'
										)}
									</Card>
								</div>
								{fieldTech.notesOnTech ? (
									<div className="rowSpacing">
										<Card title="Field Tech Notes">
											<div
												className="richTextWrapper"
												dangerouslySetInnerHTML={{ __html: fieldTech.notesOnTech }}
											/>
										</Card>
									</div>
								) : null}
								<div className="rowSpacing">
									<Card title="Field Tech Details">
										<KeyValueDisplay keyValueStore={fieldTechDetails} />
									</Card>
								</div>
								<div className="rowSpacing">
									<Card title="Contact Info">
										<KeyValueDisplay keyValueStore={contactDetails} />
									</Card>
								</div>
							</Col>
						</Row>
					</div>
				)}
				{this.state.showEditSL && (
					<EditManageStockLocationsModal
						onCancel={this.hideEditManageStockLocations}
						onSuccess={this.handleManageStockLocationSuccess}
					/>
				)}
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	currentUser: state.session.currentUser,

	fieldTechsFetching: state.field_techs.fetching,
	fieldTech: state.field_techs.detail,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	getFieldTech: (id) => dispatch(fieldTechsRestCrudThunksForSupplier.readOne(id)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(FieldTechsDetailDetailsPage)
);

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router';
import {
	locationTypesRestCrudThunksForBuyer,
	locationTypesRestCrudThunksForSupplier,
} from '../../thunks/location_types_thunks';
import { Link } from 'react-router-dom';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { ROLE_TYPES } from '../../utils/DataConstants';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import { getBackLinkStates } from '../../utils/HistoryUtils';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';

require('./LocationTypesDetailPage.less');

const LocationTypesDetailPage: FC<any> = ({
	userType,
	getLocationType,
	match,
	history,
	deleteLocationType,
	locationType,
	locationTypesFetching,
	companyConfig,
	childRoutes,
	location,
}): React.ReactElement => {
	const defaultPath = useMemo(
		() => `/${userType}/locations/overview/templates/overview`,
		[userType]
	);

	const [backLinkTo, setBackLinkTo] = useState(defaultPath);

	const locationTemplateId = useMemo(() => match.params.id, [match.params.id]);

	useEffect(() => {
		getLocationType(locationTemplateId);

		const { backLinkTo: link } = getBackLinkStates(location, defaultPath) as any;
		link && setBackLinkTo(link);
	}, [defaultPath, getLocationType, location, locationTemplateId]);

	const handleTabChange = useCallback(
		(key) => {
			history.push(`/${userType}/locations/templates/detail/${locationTemplateId}/${key}`);
		},
		[history, locationTemplateId, userType]
	);

	const checkTabActive = useCallback(
		(key) => {
			const pathname = history.location.pathname;
			return pathname.indexOf(key) !== -1;
		},
		[history.location.pathname]
	);

	const handleDelete = useCallback(() => {
		deleteLocationType(locationType).then(() => {
			history.push(defaultPath);
		});
	}, [defaultPath, deleteLocationType, history, locationType]);

	const tabs = useMemo(
		() => [
			{
				key: 'details',
				name: 'Overview',
			},
		],
		[]
	);

	return (
		<div>
			<Route
				path={`/${userType}/locations/templates/detail/:id`}
				exact={true}
				render={(props) => (
					<Redirect
						to={{
							pathname: `/${userType}/locations/templates/detail/${locationTemplateId}/details`,
						}}
					/>
				)}
			/>

			<DetailPageHeader
				exactPath={`/${userType}/locations/templates/detail/:id`}
				redirectPath={`/${userType}/locations/templates/detail/${locationTemplateId}/details`}
				backLinkText={'Back to Templates'}
				backLinkTo={backLinkTo}
				title={locationTypesFetching ? null : locationType.name}
				subtitle={null}
				subtext={null}
				actions={
					<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_LOCATIONS}>
						<div>
							<Link
								to={`/${userType}/locations/templates/edit/${locationTemplateId}`}
								style={{ marginRight: 16 }}
							>
								<Button ghost={true}>Edit</Button>
							</Link>
							<Button ghost={true} onClick={handleDelete}>
								Delete
							</Button>
						</div>
					</AccessPermissionChecker>
				}
				tabs={tabs}
				backgroundColor={nullSafeGet('detail.config.theme.navblock', companyConfig)}
				handleTabChange={handleTabChange}
				checkTabActive={checkTabActive}
				childRoutes={childRoutes}
			/>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => ({
	locationTypesFetching: state.location_types.fetching,
	locationType: state.location_types.detail,
	companyConfig: state.company_config,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getLocationType: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationTypesRestCrudThunksForSupplier.readOne(id)
				: locationTypesRestCrudThunksForBuyer.readOne(id)
		),
	deleteLocationType: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationTypesRestCrudThunksForSupplier.delete(entity, 'id', null, true)
				: locationTypesRestCrudThunksForBuyer.delete(entity, 'id', null, true)
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(LocationTypesDetailPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

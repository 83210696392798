import * as React from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { EmptorTag } from '../emptor_tag/EmptorTag';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';

const style = require('./AssetModelRowDisplay.less');

export const AssetModelRowDisplay: React.FC<any> = ({ assetModel, ...props }) => (
	<div className="assetModelRowDisplay" {...props}>
		<div>
			<div className="assetModelRowDisplay__rowTitle">
				<Ellipsis tooltip={true} length={56}>
					{assetModel.modelName}
				</Ellipsis>
			</div>
		</div>
		<div className="assetModelRowDisplay__rightSide">
			<EmptorTag className="assetModelRowDisplay__pill">
				{nullSafeGetOrElse('manuals.length', assetModel, 0) > 0
					? nullSafeGet('manuals.length', assetModel) + ' Manuals'
					: 'No Manual'}
			</EmptorTag>
		</div>
	</div>
);

import * as React from 'react';
import WorkOrderTable from '../WorkOrderTable/index';
import InvoicesTable from '../InvoicesTable/index';
import { Card, Col, Row } from 'antd';
import { ShowAllFooter } from '../InvoicesTable/TableFooter';
import { ShowAllFooterWorkOrders } from '../WorkOrderTable/TableFooter';
import { EmergencyWORowDisplay } from '../WorkOrderTable/rowDisplay/EmergecyWORowDisplay';
import { PendingApprovalWORowDisplay } from '../WorkOrderTable/rowDisplay/PendingApprovalWORowDisplay';
import { StaleWORowDisplay } from '../WorkOrderTable/rowDisplay/StaleWORowDisplay';
import { PendingInvoiceRowDisplay } from '../pending_invoice_row_display/PendingInvoiceRowDisplay';
import {
	EmergencyWorkOrderTableEmptyState,
	StaleWorkOrderTableEmptyState,
	CompletedNotInvoicedWorkOrderTableEmptyState,
	PendingApprovalWorkOrderTableEmptyState,
	QuoteApprovalWorkOrderTableEmptyState,
} from '../WorkOrderTable/emptyState/WorkOrderTableEmptyState';
import { InvoicesPendingApprovalTableEmptyState } from '../InvoicesTable/empyState/InvoicesTableEmptyState';
import { FETCH_DATA_PREFIX_FOR_TARGET_COLLECTION } from '../../utils/DataConstants';

const ATTENTION_DASHBOARD_STALE_WORK_ORDERS_TARGET_COLLECTION = `${FETCH_DATA_PREFIX_FOR_TARGET_COLLECTION}ATTENTION_DASHBOARD_STALE_WORK_ORDERS`;
const ATTENTION_DASHBOARD_EMERGENCY_WORK_ORDERS_TARGET_COLLECTION = `${FETCH_DATA_PREFIX_FOR_TARGET_COLLECTION}ATTENTION_DASHBOARD_EMERGENCY_WORK_ORDERS`;
const ATTENTION_DASHBOARD_PENDING_QUOTE_WORK_ORDERS_TARGET_COLLECTION = `${FETCH_DATA_PREFIX_FOR_TARGET_COLLECTION}ATTENTION_DASHBOARD_PENDING_QUOTE_WORK_ORDERS`;
const ATTENTION_DASHBOARD_PENDING_APPROVAL_WORK_ORDERS_TARGET_COLLECTION = `${FETCH_DATA_PREFIX_FOR_TARGET_COLLECTION}ATTENTION_DASHBOARD_PENDING_APPROVAL_WORK_ORDERS`;
const ATTENTION_DASHBOARD_PENDING_APPROVAL_INVOICES_TARGET_COLLECTION = `${FETCH_DATA_PREFIX_FOR_TARGET_COLLECTION}ATTENTION_DASHBOARD_PENDING_APPROVAL_INVOICES`;
const ATTENTION_DASHBOARD_COMPLETED_NOT_INVOICED_TARGET_COLLECTION = `${FETCH_DATA_PREFIX_FOR_TARGET_COLLECTION}ATTENTION_DASHBOARD_COMPLETED_NOT_INVOICED`;

export function AttentionDashboard(props) {
	const { globalFilters } = props;
	return (
		<Row gutter={16} style={{ marginTop: '1em', width: '100%' }}>
			<Col xs={24} sm={24} md={24} lg={24} xl={12}>
				<div className="rowSpacing2" style={{ minHeight: '408.734px' }}>
					<Card title={'Emergency Work Orders'} bodyStyle={{ padding: 0 }}>
						<WorkOrderTable
							CustomHeader={(record) => <div style={{ display: 'none' }} />}
							preAppliedFilters={{
								...globalFilters,
								woPriorityIds: 1,
								displayStatuses: 'Open,Pending,InProgress,OnHold',
							}}
							tableStyle={{
								display: 'flex',
								flexDirection: 'column',
								cursor: 'pointer',
								userSelect: 'none',
								padding: '8px 8px',
								marginTop: '-10px',
								marginBottom: '-9px',
							}}
							initialPagination={{ current: 1, pageSize: 3 }}
							targetCollectionName={ATTENTION_DASHBOARD_EMERGENCY_WORK_ORDERS_TARGET_COLLECTION}
							CustomWorkOrderRowDisplay={EmergencyWORowDisplay}
							CustomFooter={ShowAllFooterWorkOrders}
							renderEmptyState={EmergencyWorkOrderTableEmptyState}
							ignoreRefreshCounts
						/>
					</Card>
				</div>
				<div className="rowSpacing2" style={{ minHeight: '408.734px' }}>
					<Card title={'Stale Work Orders'} bodyStyle={{ padding: 0 }}>
						<WorkOrderTable
							CustomHeader={(record) => <div style={{ display: 'none' }} />}
							preAppliedFilters={{
								...globalFilters,
								isStale: true,
								displayStatuses: 'Open,Pending,InProgress,OnHold',
							}}
							tableStyle={{
								display: 'flex',
								flexDirection: 'column',
								cursor: 'pointer',
								userSelect: 'none',
								padding: '8px 8px',
								marginTop: '-10px',
								marginBottom: '-9px',
							}}
							initialPagination={{ current: 1, pageSize: 3 }}
							targetCollectionName={ATTENTION_DASHBOARD_STALE_WORK_ORDERS_TARGET_COLLECTION}
							CustomWorkOrderRowDisplay={StaleWORowDisplay}
							CustomFooter={ShowAllFooterWorkOrders}
							renderEmptyState={StaleWorkOrderTableEmptyState}
							ignoreRefreshCounts
						/>
					</Card>
				</div>
				<div className="rowSpacing2" style={{ minHeight: '408.734px' }}>
					<Card title={'Completed Work Orders Not Invoiced'} bodyStyle={{ padding: 0 }}>
						<WorkOrderTable
							CustomHeader={(record) => <div style={{ display: 'none' }} />}
							preAppliedFilters={{
								...globalFilters,
								displayStatuses: 'Completed',
								statuses: 'WorkReviewedAndCompleted',
							}}
							tableStyle={{
								display: 'flex',
								flexDirection: 'column',
								cursor: 'pointer',
								userSelect: 'none',
								padding: '8px 8px',
								marginTop: '-10px',
								marginBottom: '-9px',
							}}
							initialPagination={{ current: 1, pageSize: 3 }}
							targetCollectionName={ATTENTION_DASHBOARD_COMPLETED_NOT_INVOICED_TARGET_COLLECTION}
							CustomWorkOrderRowDisplay={PendingApprovalWORowDisplay}
							CustomFooter={ShowAllFooterWorkOrders}
							renderEmptyState={CompletedNotInvoicedWorkOrderTableEmptyState}
							ignoreRefreshCounts
						/>
					</Card>
				</div>
			</Col>
			<Col xs={24} sm={24} md={24} lg={24} xl={12}>
				<div className="rowSpacing2" style={{ minHeight: '408.734px' }}>
					<Card title={'Invoices Waiting for Approval'} bodyStyle={{ padding: 0 }}>
						<InvoicesTable
							CustomHeader={() => <div style={{ display: 'flex', marginTop: '0px' }}></div>}
							preAppliedFilters={{
								...globalFilters,
								statuses: 'Pending',
							}}
							tableStyle={{
								display: 'flex',
								flexDirection: 'column',
								cursor: 'pointer',
								userSelect: 'none',
								padding: '8px 8px',
								marginTop: '-15px',
								marginBottom: '-11px',
							}}
							targetCollectionName={ATTENTION_DASHBOARD_PENDING_APPROVAL_INVOICES_TARGET_COLLECTION}
							renderWorkOrder={(record) => <PendingInvoiceRowDisplay invoice={record} />}
							initialPagination={{ current: 1, pageSize: 3 }}
							CustomFooter={ShowAllFooter}
							renderEmptyState={InvoicesPendingApprovalTableEmptyState}
							ignoreRefreshCounts
						/>
					</Card>
				</div>
				<div className="rowSpacing2" style={{ minHeight: '408.734px' }}>
					<Card title={'Quotes Waiting for Approval'} bodyStyle={{ padding: 0 }}>
						<WorkOrderTable
							CustomHeader={(record) => <div style={{ display: 'none' }} />}
							preAppliedFilters={{
								...globalFilters,
								displayStatuses: 'OnHold',
								statuses: 'WaitingForProposalApproval',
							}}
							tableStyle={{
								display: 'flex',
								flexDirection: 'column',
								cursor: 'pointer',
								userSelect: 'none',
								padding: '8px 8px',
								marginTop: '-10px',
								marginBottom: '-9px',
							}}
							initialPagination={{ current: 1, pageSize: 3 }}
							targetCollectionName={ATTENTION_DASHBOARD_PENDING_QUOTE_WORK_ORDERS_TARGET_COLLECTION}
							CustomWorkOrderRowDisplay={PendingApprovalWORowDisplay}
							CustomFooter={ShowAllFooterWorkOrders}
							renderEmptyState={QuoteApprovalWorkOrderTableEmptyState}
							ignoreRefreshCounts
						/>
					</Card>
				</div>
			</Col>
		</Row>
	);
}

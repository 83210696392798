import React, { FC, useState, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { locationsRestCrudThunksForSupplier } from '../../thunks/locations_thunks';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import UploadWithSampleModal from '../parts_index_page/UploadWithSampleModal';
import {
	getBulkTransferToLocationFlatfileHeader,
	getBulkTransferToSLFlatfileHeader,
} from './asset_bulk_transfer_utils';
import { ExportToCsv } from 'export-to-csv';
import { CSV_EXPORT_DEFAULTS } from '../../utils/DataConstants';
import { Button, Dropdown, Menu, Spin, message } from 'antd';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import moment from 'moment';
import {
	bulkTransferAssetsToLocationsForSupplier,
	getAllAssetsForBulkUpdateForSupplier,
	getAssetsBulkTransferStatusForSupplier,
} from '../../thunks/assets_thunks';
import {
	bulkTransferAssetsToStockLocationsForSupplier,
	getAssetsBulkTransferToStockLocationStatusForSupplier,
} from '../../thunks/equipment_per_stock_locations_thunks';
import { BE_DATE_ONLY_FORMAT } from '../../utils/DataFormatterUtils';
import { equipmentTypesRestCrudThunksForSupplier } from '../../thunks/equipment_types_thunks';
import { getEquipmentTypeFlatfileOptions } from '../stock_locations_detail_details_page/stock_location_constants';

const BulkTransferAssetsButton: FC<any> = ({
	fetchAllLocations,
	fetchAllStockLocations,
	getTransferStatus,
	bulkTransferAssets,
	onSuccess,
	getTransferToSLStatus,
	bulkTransferAssetsToStockLocation,
	fetchAllEquipmentTypes,
	fetchAllAssets,
	companyConfig,
}): React.ReactElement => {
	const [transferToStockLocationPopupVisible, setTransferToStockLocationPopupVisible] =
		useState(false);
	const [transferToLocationPopupVisible, setTransferToLocationPopupVisible] = useState(false);

	const [initiatingTransfer, setInitiatingTransfer] = useState(false);

	const transferNoteRequired = useMemo(
		() => nullSafeGetOrElse('inventoryConfig.transferNoteMandatory', companyConfig, false),
		[companyConfig]
	);

	const [allLocations, setAllLocations] = useState([]);
	const [allStockLocations, setAllStockLocations] = useState([]);
	const [allEquipmentTypes, setAllEquipmentTypes] = useState([]);
	const [allAssets, setAllAssets] = useState([]);

	const canTransfer = useCallback(
		(toStockLocation) => {
			const methodToCall = toStockLocation ? getTransferToSLStatus : getTransferStatus;
			return new Promise((resolve, reject) => {
				methodToCall()
					.then((res) => {
						if (res && res.length > 0) {
							message.error('Bulk transfer already in progress!');
							reject(false);
						} else {
							resolve(true);
						}
					})
					.catch(() => resolve(true));
			});
		},
		[getTransferStatus, getTransferToSLStatus]
	);

	const _showTransferToLocationPopup = useCallback(() => {
		setInitiatingTransfer(true);
		canTransfer(false)
			.then((res) => {
				if (res) {
					fetchAllLocations()
						.then((res) => {
							setAllLocations(res);
							setTransferToLocationPopupVisible(true);
						})
						.catch((err) => message.error(err))
						.finally(() => setInitiatingTransfer(false));
				} else {
					setInitiatingTransfer(false);
				}
			})
			.catch(() => setInitiatingTransfer(false));
	}, [canTransfer, fetchAllLocations]);

	const _hideTransferToLocationPopup = () => setTransferToLocationPopupVisible(false);

	const _showTransferToSLPopup = useCallback(() => {
		setInitiatingTransfer(true);
		canTransfer(true)
			.then((res) => {
				if (res) {
					const promises = [fetchAllStockLocations(), fetchAllEquipmentTypes(), fetchAllAssets()];
					Promise.allSettled(promises)
						.then((res) => {
							const [
								{ value: stockLocations = [] },
								{ value: equipmentTypes = [] },
								{ value: assetTypes = [] },
							] = res;

							setAllStockLocations(stockLocations);
							setAllEquipmentTypes(equipmentTypes);
							setAllAssets(assetTypes);
							setTransferToStockLocationPopupVisible(true);
						})
						.catch((err) => message.error(err))
						.finally(() => setInitiatingTransfer(false));
				} else {
					setInitiatingTransfer(false);
				}
			})
			.catch(() => setInitiatingTransfer(false));
	}, [canTransfer, fetchAllAssets, fetchAllEquipmentTypes, fetchAllStockLocations]);

	const _hideTransferToSLPopup = () => setTransferToStockLocationPopupVisible(false);

	const bulkTransferMenu = useMemo(
		() => (
			<Menu>
				<Menu.Item onClick={_showTransferToLocationPopup}>To Location</Menu.Item>
				<Menu.Item onClick={_showTransferToSLPopup}>To Stock Location</Menu.Item>
			</Menu>
		),
		[_showTransferToLocationPopup, _showTransferToSLPopup]
	);

	const onTransferToLocation = useCallback(
		(results) => {
			return new Promise((resolve, reject) => {
				bulkTransferAssets(
					results.validData.map((obj) => ({
						...obj,
						transferDate: obj.transferDate ? obj.transferDate : undefined,
					}))
				)
					.then(() => {
						const msg = 'Transfer initiated successfully!';
						resolve(msg);
						setTimeout(() => {
							_hideTransferToLocationPopup();
							message.success(msg);
							onSuccess && onSuccess();
						}, 0);
					})
					.catch((err) => {
						reject(err || 'Unable to transfer! Please contact support!');
					});
			});
		},
		[bulkTransferAssets, onSuccess]
	);

	const onTransferToStockLocation = useCallback(
		(results) => {
			return new Promise((resolve, reject) => {
				bulkTransferAssetsToStockLocation(
					results.validData.map((obj) => ({
						...obj,
						transferDate: obj.transferDate ? obj.transferDate : undefined,
					}))
				)
					.then(() => {
						const msg = 'Transfer initiated successfully!';
						resolve(msg);
						setTimeout(() => {
							_hideTransferToSLPopup();
							message.success(msg);
							onSuccess && onSuccess();
						}, 0);
					})
					.catch((err) => {
						reject(err || 'Unable to transfer! Please contact support!');
					});
			});
		},
		[bulkTransferAssetsToStockLocation, onSuccess]
	);

	const allLocationNames = useMemo(
		() =>
			allLocations.map((_) => ({
				label: _.name,
				value: _.id,
			})),
		[allLocations]
	);

	const allStockLocationNames = useMemo(
		() =>
			allStockLocations.map((_) => ({
				label: _.name,
				value: _.id,
			})),
		[allStockLocations]
	);
	const allEquipmentTypesNames = useMemo(
		() => getEquipmentTypeFlatfileOptions(allEquipmentTypes),
		[allEquipmentTypes]
	);

	const getEquipmentTypeOptionsByAssetId = (assetId) => {
		const asset = allAssets.find((asset) => asset.id == assetId);
		if (asset) {
			return allEquipmentTypesNames.filter((option) => option.assetTypeId == asset.assetTypeId);
		}
		return [];
	};
	const flatfileConfig = useMemo(
		() =>
			getBulkTransferToSLFlatfileHeader(
				allStockLocationNames,
				allEquipmentTypesNames,
				transferNoteRequired
			),
		[allEquipmentTypesNames, allStockLocationNames, transferNoteRequired]
	);

	const onRecordChange = (record) => {
		const assetId = record['assetId'];
		const newOptions = getEquipmentTypeOptionsByAssetId(assetId);

		return { ...record, equipmentTypeId: newOptions[0] };
	};

	const onDownloadSLSample = useCallback(() => {
		const csvTitle = `bulk_transfer_to_stock_location_sample`;
		const csvExporter = new ExportToCsv({
			...CSV_EXPORT_DEFAULTS,
			filename: csvTitle,
			title: csvTitle,
			headers: flatfileConfig.map((_) => _.label),
		});

		const sl1 = nullSafeGetOrElse('0.name', allStockLocations, 'Stock location - 1');
		const sl2 = nullSafeGetOrElse('1.name', allStockLocations, 'Stock location - 2');

		const data = [
			{
				assetId: '1234',
				stockLocationId: sl1,
				equipmentTypeId: nullSafeGetOrElse('0.modelName', allEquipmentTypes, 'Equipment Type - 1'),
				transferNote: '',
				transferDate: moment().subtract(1, 'months').endOf('month').format(BE_DATE_ONLY_FORMAT),
			},
			{
				assetId: '5678',
				stockLocationId: sl2,
				equipmentTypeId: nullSafeGetOrElse('0.modelName', allEquipmentTypes, 'Equipment Type - 2'),
				transferNote: 'This is requested by Mr.XXX!',
				transferDate: '',
			},
		];

		csvExporter.generateCsv(data);
	}, [flatfileConfig, allStockLocations, allEquipmentTypes]);

	const onDownloadLocationSample = useCallback(() => {
		const csvTitle = `bulk_transfer_to_location_sample`;
		const csvExporter = new ExportToCsv({
			...CSV_EXPORT_DEFAULTS,
			filename: csvTitle,
			title: csvTitle,
			headers: getBulkTransferToLocationFlatfileHeader(allLocationNames, transferNoteRequired).map(
				(_) => _.label
			),
		});

		const loc1 = nullSafeGetOrElse('0.name', allLocations, 'location - 1');
		const loc2 = nullSafeGetOrElse('1.name', allLocations, 'location - 2');

		const data = [
			{
				assetId: '1234',
				locationId: loc1,
				transferNote: '',
				transferDate: moment().subtract(1, 'months').endOf('month').format(BE_DATE_ONLY_FORMAT),
			},
			{
				assetId: '5678',
				locationId: loc2,
				transferNote: 'This is requested by Mr.XXX!',
				transferDate: '',
			},
		];

		csvExporter.generateCsv(data);
	}, [allLocationNames, allLocations, transferNoteRequired]);

	return (
		<>
			<Dropdown overlay={bulkTransferMenu}>
				<Button key={1} ghost={true}>
					<div className="flex flex-row items-center">
						<span>Bulk Transfer</span>
						<div className="ml-2">
							<DownOutlined translate="" />
						</div>
						{initiatingTransfer ? (
							<div className="ml-2">
								<Spin
									indicator={
										<LoadingOutlined translate="" style={{ fontSize: 16, color: '#fff' }} spin />
									}
								/>
							</div>
						) : null}
					</div>
				</Button>
			</Dropdown>
			{transferToStockLocationPopupVisible && (
				<UploadWithSampleModal
					type="Assets"
					title="Transfer assets to stock location"
					downloadText="Do you want to download sample file?"
					sampleFileText="Download Sample file"
					onCancel={_hideTransferToSLPopup}
					onDownloadSample={onDownloadSLSample}
					fields={flatfileConfig}
					onDataLoad={onTransferToStockLocation}
					onRecordInit={onRecordChange}
					onRecordChange={onRecordChange}
				/>
			)}
			{transferToLocationPopupVisible && (
				<UploadWithSampleModal
					type="Assets"
					title="Transfer assets to location"
					downloadText="Do you want to download sample file?"
					sampleFileText="Download Sample file"
					onCancel={_hideTransferToLocationPopup}
					onDownloadSample={onDownloadLocationSample}
					fields={getBulkTransferToLocationFlatfileHeader(allLocationNames, transferNoteRequired)}
					onDataLoad={onTransferToLocation}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	fetchAllLocations: () =>
		dispatch(locationsRestCrudThunksForSupplier.readLite({ no_pagination: true })),
	fetchAllAssets: (params) => dispatch(getAllAssetsForBulkUpdateForSupplier(params)),
	fetchAllStockLocations: () =>
		dispatch(stockLocationsRestCrudThunksForSupplier.readLite({ no_pagination: true })),
	fetchAllEquipmentTypes: () =>
		dispatch(equipmentTypesRestCrudThunksForSupplier.readLite({ no_pagination: true })),
	getTransferStatus: () => dispatch(getAssetsBulkTransferStatusForSupplier()),
	bulkTransferAssets: (entities) => dispatch(bulkTransferAssetsToLocationsForSupplier(entities)),
	getTransferToSLStatus: () => dispatch(getAssetsBulkTransferToStockLocationStatusForSupplier()),
	bulkTransferAssetsToStockLocation: (entities) =>
		dispatch(bulkTransferAssetsToStockLocationsForSupplier(entities)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkTransferAssetsButton);

export const CHANGE_NOTIFICATION_VOLUME = 'CHANGE_NOTIFICATION_VOLUME';
export const PLAY_NOTIFICATION = 'PLAY_NOTIFICATION';

export const changeNotificationVolume = (volume) => ({
	type: CHANGE_NOTIFICATION_VOLUME,
	volume,
});

export const playNotification = (notification) => ({
	type: PLAY_NOTIFICATION,
	notification,
});

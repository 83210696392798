import * as React from 'react';
import { Layout, Row, Col, Card, Divider, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import {
	disputeQuoteForBuyer,
	quotesRestCrudThunksForBuyer,
	markQuotePaidForBuyer,
	markQuoteProcessingForBuyer,
	markQuotePendingForBuyer,
} from '../../thunks/quotes_thunks';
import { getTimeDurationFromSeconds } from '../../utils/DataFormatterUtils';
import {
	getCurrency,
	getEntityById,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { appendWorkOrderNotesForBuyer } from '../../thunks/work_orders_thunks';
import {
	proposalStatusChangeForBuyer,
	syncProposalApprovalHierarchy,
} from '../../thunks/proposals_thunks';
import ApprovalHierarchyDisplayQuote from '../approval_hierarchy_display_quote/ApprovalHierarchyDisplayQuote';
import CapexSwitch from '../capex_switch/CapexSwitch';
import QuotesAdditionalDetails from '../quotes_components/QuotesAdditionalDetails';
import QuoteUploader from '../quote_uploader/QuoteUploader';
import { BUYER_ADMIN, SUPER_ADMIN } from '../roles/roles';
import { markUserNotificationsReadForBuyer } from '../../thunks/user_notifications_thunks';

const { Content } = Layout;

const style = require('./QuotesDetailDetailsPage.less');

class QuotesDetailDetailsPage extends React.Component<any, any> {
	formRefs: any;

	constructor(props) {
		super(props);

		this.formRefs = {
			editGLCodeForm: null,
			editPONumberForm: null,
		};

		this.state = {
			editGLCodeFormVisible: false,
			editPONumberFormVisible: false,
			showDeclineModal: false,
			approvalModalVisible: false,
			buttonLoading: false,
			tierDetails: undefined,
			expenseTypeLoading: false,
			capExAdditionalInfoVisible: false,
		};
	}

	refreshQuote = () => {
		const { getQuote, match } = this.props;
		const quoteId = match.params.id;
		getQuote(quoteId);
	};

	componentDidMount() {
		const { getQuote, match, markUserNotificationsRead, quote } = this.props;
		const quoteId = match.params.id;
		if (!(quote && quote.id)) {
			getQuote(quoteId).then(() => markUserNotificationsRead(quoteId));
		} else {
			markUserNotificationsRead(quoteId);
		}
	}
	componentDidUpdate(prevProps) {
		const { quote, markUserNotificationsRead } = this.props;
		const { quote: prevQuote } = prevProps;
		if (quote && prevQuote && quote.id !== prevQuote.id) {
			markUserNotificationsRead(quote.id);
		}
	}

	handlePaidQuote = () => {
		const { markQuotePaid, quote } = this.props;
		markQuotePaid(quote.id).then(() => {
			message.success(`Quote paid.`);
		});
	};

	handleProcessingQuote = () => {
		const { markQuoteProcessing, quote } = this.props;
		markQuoteProcessing(quote.id).then(() => {
			message.success(`Quote is being processed.`);
		});
	};

	handlePendingQuote = () => {
		const { markQuotePending, quote } = this.props;
		markQuotePending(quote.id).then(() => {
			message.success(`Quote made pending.`);
		});
	};

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	showEditGLCode = (invoice) => {
		this.setState({
			editGLCodeFormVisible: true,
			selectedInvoice: invoice,
		});
	};

	handleEditGLCodeSubmit = () => {
		const { updateGLCode } = this.props;
		const form = this.formRefs['editGLCodeForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			const glCode = {
				...values,
			};

			updateGLCode(glCode).then(() => {
				this.setState({ editGLCodeFormVisible: false, selectedInvoice: {} });
				form.resetFields();
			});
		});
	};

	handleEditGLCodeCancel = (e) => {
		this.setState({
			editGLCodeFormVisible: false,
		});
	};

	showEditPONumber = (invoice) => {
		this.setState({
			editPONumberFormVisible: true,
			selectedInvoice: invoice,
		});
	};

	handleEditPONumberSubmit = () => {
		const { updatePONumber } = this.props;
		const form = this.formRefs['editPONumberForm'].props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}

			const poNumber = {
				...values,
			};

			updatePONumber(poNumber).then(() => {
				this.setState({ editPONumberFormVisible: false, selectedInvoice: {} });
				form.resetFields();
			});
		});
	};

	handleEditPONumberCancel = (e) => {
		this.setState({
			editPONumberFormVisible: false,
		});
	};

	handleQuotePDFUploadChange = (quoteFileUrl) => {
		const { updateQuote, getQuote, quote } = this.props;

		const newQuote = {
			...quote,
			proposalPdfLink: quoteFileUrl,
		};

		this.refreshQuote();
	};

	render() {
		const {
			quotesFetching,
			quote,
			currentUser,
			approveQuote,
			declineQuote,
			appendWorkOrderNotes,
			companyConfig,
			syncQuote,
			history,
		} = this.props;
		const { workOrder } = quote;
		const isAdmin =
			currentUser.roles.includes(BUYER_ADMIN) || currentUser.roles.includes(SUPER_ADMIN);
		const hasPDF = quote.proposalPdfLink && quote.proposalPdfLink.length > 0;
		const currency = getCurrency({ workOrder: nullSafeGet('workOrder', quote) });

		const pdfElement = hasPDF ? (
			<p className={'mb-1'}>
				<a href={quote.proposalPdfLink} target="_blank" rel="noreferrer">
					Quote PDF
				</a>
			</p>
		) : null;
		const uploadQuote = isAdmin ? (
			<QuoteUploader
				roleType="supplier"
				quoteId={quote && quote.id}
				defaultFileList={[]}
				multiple={false}
				handleUploadSuccess={this.handleQuotePDFUploadChange}
			/>
		) : null;

		return (
			<Content className="quotesDetailDetailsPage" key={nullSafeGet('id', quote)}>
				<LogOnMountWithStandardEventProperties eventType="visited buyer quotes page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '16px 8px' }} gutter={12} key={'quote'}>
					<Col span={24}>
						<Card>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									gap: '20px',
								}}
							>
								<div
									style={{
										display: 'flex',
										position: 'relative',
										height: 'fit-content',
										flexDirection: 'row',
									}}
								>
									<div
										style={{
											position: 'relative',
											flexDirection: 'column',
											width: '100%',
										}}
									>
										<Row className="w-full justify-between">
											<ApprovalHierarchyDisplayQuote
												workOrder={workOrder}
												quote={quote}
												refresh={this.refreshQuote}
												currentUser={currentUser}
												approveQuote={approveQuote}
												declineQuote={declineQuote}
												syncQuote={syncQuote}
												appendWorkOrderNotes={appendWorkOrderNotes}
												style={style}
												history={history}
											/>
											<CapexSwitch
												quote={quote}
												refresh={this.refreshQuote}
												companyConfig={companyConfig}
											/>
										</Row>
									</div>
								</div>
								<div className={'flex flex-row justify-between'}>
									<QuotesAdditionalDetails
										quote={quote}
										passthroughItems={[
											pdfElement || uploadQuote
												? {
														key: 'File',
														value: (
															<div>
																{pdfElement}
																{uploadQuote}
															</div>
														),
														className: 'detailRow__item firstRow lastCol',
												  }
												: {},
										]}
									/>
								</div>
							</div>
							<Divider />
							<div className="sectionHeaderWrapper">
								<span className="sectionHeaderText">Incurred Costs</span>
								<span className="sectionHeaderText2">
									{currency.format(
										parseFloat(nullSafeGet(`incurredCostTotalBeforeTax`, quote)) || 0
									)}
								</span>
							</div>
							<Divider />
							<div className="sectionHeaderWrapper" style={{ marginBottom: 8 }}>
								<span className="sectionHeaderText">Labor</span>
								<span className="sectionHeaderText2">
									{currency.format(quote.laborTotalBeforeTax)}
								</span>
							</div>
							{nullSafeGetOrElse('laborLineItems', quote, []).map((lli, idx) => (
								<div
									className="sectionHeaderWrapper2"
									style={{
										marginBottom:
											idx === nullSafeGetOrElse('laborLineItems', quote, []).length - 1 ? 16 : 8,
									}}
								>
									<span className="lineItemDetailText">
										{lli.numberOfTech} {lli.roleType} * {getTimeDurationFromSeconds(lli.seconds)} @{' '}
										{currency.format(parseFloat(lli.hourlyRate))} / hour
									</span>
									<span className="lineItemDetailText">
										{currency.format(parseFloat(lli.amount))}
									</span>
								</div>
							))}
							<Divider />

							<div className="sectionHeaderWrapper" style={{ marginBottom: 8 }}>
								<span className="sectionHeaderText">Materials</span>
								<span className="sectionHeaderText2">
									{currency.format(quote.materialTotalBeforeTax)}
								</span>
							</div>
							{nullSafeGetOrElse('materialLineItems', quote, []).map((mli, idx) => (
								<div
									className="sectionHeaderWrapper2"
									style={{
										marginBottom:
											idx === nullSafeGetOrElse('laborLineItems', quote, []).length - 1 ? 16 : 8,
									}}
								>
									<span className="lineItemDetailText">
										{mli.quantity} {mli.unitType} of {mli.description}
										{mli.partNumber ? ` (${mli.partNumber})` : null} @{' '}
										{currency.format(parseFloat(mli.unitPrice))} / {mli.unitType}
									</span>
									<span className="lineItemDetailText">
										{currency.format(parseFloat(mli.amount))}
									</span>
								</div>
							))}
							<Divider />
							<div className="sectionHeaderWrapper">
								<span className="sectionHeaderText">Travel</span>
								<span className="sectionHeaderText2">
									{currency.format(parseFloat(quote.travelTotalBeforeTax) || 0)}
								</span>
							</div>
							<Divider />
							<div className="sectionHeaderWrapper">
								<span className="sectionHeaderText">Freight</span>
								<span className="sectionHeaderText2">
									{currency.format(parseFloat(quote.freightTotalBeforeTax) || 0)}
								</span>
							</div>
							<Divider />
							<div className="sectionHeaderWrapper">
								<span className="sectionHeaderText">Miscellaneous</span>
								<span className="sectionHeaderText2">
									{currency.format(parseFloat(nullSafeGet(`miscTotalBeforeTax`, quote)) || 0)}
								</span>
							</div>
							<Divider />
							<div className="sectionHeaderWrapper">
								<span className="sectionHeaderText">Total Before Tax</span>
								<span className="sectionHeaderText2">{currency.format(quote.totalBeforeTax)}</span>
							</div>
							<Divider />
							<div className="sectionHeaderWrapper">
								<span className="sectionHeaderText">Tax</span>
								<span className="sectionHeaderText2">{currency.format(quote.tax)}</span>
							</div>
							<Divider />
							<div className="sectionHeaderWrapper">
								<span className="sectionHeaderText" style={{ fontWeight: 600 }}>
									Total After Tax
								</span>
								<span className="sectionHeaderText2" style={{ fontWeight: 600 }}>
									{currency.format(quote.totalAfterTax)}
								</span>
							</div>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	quotesFetching: state.proposals.fetching,
	quote: getEntityById(ownProps.match.params.id, state.proposals),
	quotes: state.proposals,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
	workOrder: state.work_orders.detail,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	appendWorkOrderNotes: (entity) => dispatch(appendWorkOrderNotesForBuyer(entity)),
	updateQuote: (entity) => dispatch(quotesRestCrudThunksForBuyer.update(entity)),
	getQuote: (id) => dispatch(quotesRestCrudThunksForBuyer.readOne(id)),
	approveQuote: (entity) => dispatch(proposalStatusChangeForBuyer('awarded', entity)),
	declineQuote: (entity) => dispatch(proposalStatusChangeForBuyer('declined', entity)),
	syncQuote: (entity) => dispatch(syncProposalApprovalHierarchy(entity)),
	markQuoteProcessing: (id) => dispatch(markQuoteProcessingForBuyer({ id })),
	markQuotePending: (id) => dispatch(markQuotePendingForBuyer({ id })),
	markQuotePaid: (id) => dispatch(markQuotePaidForBuyer({ id })),
	disputeQuote: (id) => dispatch(disputeQuoteForBuyer({ id })),
	markUserNotificationsRead: (entityId) =>
		dispatch(markUserNotificationsReadForBuyer('Quote', entityId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuotesDetailDetailsPage));

import React, { FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, message } from 'antd';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import SupplierAssetForm from '../supplier_asset_form/SupplierAssetForm';
import { getLocationSearch } from '../../utils/HistoryUtils';
import { assetsRestCrudThunksForSupplier } from '../../thunks/assets_thunks';
import { getAssetCopy } from '../../utils/assetUtils';
import { EditPage } from '../edit_page/EditPage';

const { Content } = Layout;
const queryString = require('qs');

const SupplierNewAssetPage: FC<any> = ({ history, location, getAsset }): React.ReactElement => {
	const [assetFetching, setAssetFetching] = useState(false);
	const [assetData, setAssetData] = useState({});

	const copyAssetData = (asset) => setAssetData(getAssetCopy(asset));

	const getAssetData = useCallback(
		(id) => {
			setAssetFetching(true);
			getAsset(id)
				.then(copyAssetData)
				.catch((err) => message.error(err))
				.finally(() => setAssetFetching(false));
		},
		[getAsset]
	);

	useEffect(() => {
		const queryParams = queryString.parse(getLocationSearch(location));
		queryParams.copyFromAssetId && getAssetData(queryParams.copyFromAssetId);
	}, [getAssetData, location]);

	return (
		<Content>
			<LogOnMountWithStandardEventProperties eventType="visited new supplier asset page" />
			<EditPage
				title="Create Asset"
				formComponentProps={{
					formData: assetData,
					onSuccess: (rec) => history.push(`/supplier/assets/detail/${rec.id}`),
				}}
				fetching={assetFetching}
				FormComponent={SupplierAssetForm}
			/>
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	asset: state.assets.detail,
});

const mapDispatchToProps = (dispatch) => ({
	getAsset: (id) => dispatch(assetsRestCrudThunksForSupplier.readOne(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SupplierNewAssetPage));

import { LOCATION_TYPES_CRUD_ACTION_CREATORS } from '../actions/location_types_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { PROJECT_TEMPLATES_CRUD_ACTION_CREATORS } from '../actions/project_templates_actions';

export const projectTemplatesRestCrudThunksForBuyer = new RestCrudThunks(
	'project_templates',
	'project_template',
	'projectTemplate',
	'projectTemplates',
	PROJECT_TEMPLATES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'project/'
);
export const projectTemplatesRestCrudThunksForSupplier = new RestCrudThunks(
	'project_templates',
	'project_template',
	'projectTemplate',
	'projectTemplates',
	PROJECT_TEMPLATES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'project/'
);

import React, { FC, useCallback, useMemo, useState } from 'react';
import { getCurrency, nullSafeGet } from '../../utils/DataAccessUtils';
import { Button, Form, Modal, Select, message } from 'antd';
import PartRowDisplay from '../part_row_display/PartRowDisplay';
import { partsRestCrudThunksForSupplier } from '../../thunks/parts_thunks';
import { partCatalogsRestCrudThunksForSupplier } from '../../thunks/part_catalogs_thunks';
import { connect } from 'react-redux';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { replaceLineItemInPO } from '../../thunks/purchase_order_line_items_thunks';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { replaceLineItemInPR } from '../../thunks/purchase_request_line_items_thunks';

const FORM_ID = 'replace_line_item_form';
const PARTS_TC_NAME = 'stockLocationAssociatedParts';

const ReplacePartLineItemModal: FC<any> = ({
	lineItem,
	vendorId,
	onCancel,
	onSuccess,
	fetchPartsCatalog,
	createPartsPerCatalog,
	currentUser,
	parts,
	fetchMultipleParts,
	fetchParts,
	isPRLineItem,
	replacePOLineItem,
	replacePRLineItem,
}): React.ReactElement => {
	const [form] = Form.useForm();

	const partId = Form.useWatch('partId', form);

	const userCurrencyId = useMemo(
		() => nullSafeGet('id', getCurrency({ currentUser })),
		[currentUser]
	);

	const [replacingLineItem, setReplacingLineItem] = useState(false);

	const getPartCatalogFromVendorId = useCallback(() => {
		return new Promise((resolve, reject) => {
			if (!vendorId) {
				resolve({});
				return;
			}

			fetchPartsCatalog({
				partId,
				partEquipmentVendorId: vendorId,
			})
				.then((res) => {
					if (res && res.length > 0) {
						resolve(res[0]);
					} else {
						createPartsPerCatalog({
							partEquipmentVendorId: vendorId,
							currencyId: userCurrencyId,
							partId,
						})
							.then((ppc) => resolve(ppc))
							.catch((err) => reject(err));
					}
				})
				.catch((err) => {
					console.log(err);
					createPartsPerCatalog({
						partEquipmentVendorId: vendorId,
						currencyId: userCurrencyId,
						partId,
					})
						.then((ppc) => resolve(ppc))
						.catch((err) => reject(err));
				});
		});
	}, [fetchPartsCatalog, partId, vendorId, createPartsPerCatalog, userCurrencyId]);

	const partRecord = useMemo(() => {
		const partsRecords = getRecordsForTargetCollection(parts, PARTS_TC_NAME);
		return partsRecords.find((_) => _.id === partId);
	}, [partId, parts]);

	const handleSubmit = useCallback(() => {
		setReplacingLineItem(true);
		const replaceMethod = isPRLineItem ? replacePRLineItem : replacePOLineItem;
		getPartCatalogFromVendorId()
			.then((partCatalog) => {
				replaceMethod({
					...lineItem,
					partId,
					...(nullSafeGet('id', partCatalog) && {
						partCatalogId: nullSafeGet('id', partCatalog),
					}),
					...(nullSafeGet('partUomId', partRecord) && {
						partUomId: nullSafeGet('partUomId', partRecord),
					}),
					...(nullSafeGet('partCurrencyId', partRecord) && {
						partCurrencyId: nullSafeGet('partCurrencyId', partRecord),
					}),
				})
					.then((res) => onSuccess && onSuccess(res))
					.catch((err) => message.error(err))
					.finally(() => setReplacingLineItem(false));
			})
			.catch((err) => {
				message.error(err);
				setReplacingLineItem(false);
			});
	}, [
		getPartCatalogFromVendorId,
		isPRLineItem,
		lineItem,
		onSuccess,
		partId,
		partRecord,
		replacePOLineItem,
		replacePRLineItem,
	]);

	return (
		<Modal
			visible={true}
			title={`Replace part in Purchase Order`}
			onCancel={onCancel}
			footer={[
				<Button onClick={onCancel} size="large">
					Cancel
				</Button>,
				<Button
					type="primary"
					size="large"
					style={{ marginLeft: '16px' }}
					key="submit"
					htmlType="submit"
					form={FORM_ID}
					loading={replacingLineItem}
				>
					Replace Part
				</Button>,
			]}
			closable={true}
		>
			<Form layout="vertical" onFinish={handleSubmit} id={FORM_ID} form={form}>
				<Form.Item label={`Replace Part`}>
					<PartRowDisplay part={nullSafeGet('part', lineItem)} />
				</Form.Item>
				<Form.Item
					name="partId"
					label="With Part"
					rules={[
						{
							required: true,
							message: 'Please select a part.',
						},
					]}
				>
					<OWAsyncSelect
						stateSlice={parts}
						targetCollectionName={PARTS_TC_NAME}
						fetchMultiple={(ids, targetCollectionName) => {
							fetchMultipleParts(ids, targetCollectionName);
						}}
						fetchData={(
							search,
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						) => {
							fetchParts(
								{ search },
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							);
						}}
						renderRecord={(part) => (
							<Select.Option key={part.id} value={part.id} disabled={part.id === lineItem.partId}>
								<div className="space-between flex flex-row items-center">
									<div>{part.name}</div>
									<div>
										{part.partNumber && (
											<div className="text-gray-400">&nbsp;{` - #${part.partNumber}`}</div>
										)}
									</div>
								</div>
							</Select.Option>
						)}
						sortBy={{ sort_by: 'name', order: 'ascend' }}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	parts: state.parts,
	currentUser: state.session.currentUser,
	unitsOfMeasure: state.units_of_measure,
	vendors: state.vendors,
});

const mapDispatchToProps = (dispatch) => ({
	fetchParts: (params, targetCollectionName, pagination, sorting, filters, addToTargetCollection) =>
		dispatch(
			partsRestCrudThunksForSupplier.readLite(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleParts: (ids, targetCollectionName) =>
		dispatch(partsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchPartsCatalog: (params, targetCollectionName) =>
		dispatch(partCatalogsRestCrudThunksForSupplier.read(params, targetCollectionName)),
	createPartsPerCatalog: (entity, targetCollectionName) =>
		dispatch(partCatalogsRestCrudThunksForSupplier.create(entity, 'id', targetCollectionName)),
	replacePOLineItem: (entity) => dispatch(replaceLineItemInPO(entity)),
	replacePRLineItem: (entity) => dispatch(replaceLineItemInPR(entity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReplacePartLineItemModal);

import React from 'react';
import WorkOrderTable from '../WorkOrderTable';
import { BUYER_WO_FILTERS_ORDER } from '../../utils/WorkOrderUtils';
import posthog from 'posthog-js';

export default function WorkOrdersArchivedIndexPage() {
	const skipCount = posthog.isFeatureEnabled('OptimiseWorkOrderAPIforLargeData');
	return (
		<WorkOrderTable
			handleQueryParams
			preAppliedFilters={{ hasBuyerArchived: true, no_count: skipCount }}
			filterConfig={{
				filters: BUYER_WO_FILTERS_ORDER,
			}}
			ignoreRefreshCounts={skipCount}
			hideExportButton={skipCount}
			targetCollectionName="archivedWorkOrdersIndex"
		/>
	);
}

import { COMPANY_CONFIG_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/company_config_actions';
import { CompanyConfigState } from '../types/models';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const defaultConfig = {
	theme: {
		logo: 'https://emptor-data-assets.s3.amazonaws.com/images/emptor-logo/openwrenchlogo-white.png',
		sidebar: 'rgb(24, 14, 40)',
		navblock: 'rgb(83, 41, 142)',
		logoMinimized:
			'https://s3.amazonaws.com/emptor-data-assets/images/openwrench-logos/openwrenchlogocollapsed-white.png',
	},
	workOrder: {
		stale: {
			markStale: true,
			afterXDays: 14,
		},
		archive: {
			mirrorForInternalTeam: false,
		},
		showOnlyAssignedWorkOrdersInDefaultTechnicianView: false,
	},
	newWorkOrder: {
		step1: {
			instructions: "Tell us a bit about the issue you'd like addressed.",
		},
	},
	approvalConfig: {
		approveCompletedWorkOrders: {
			autoApprove: false,
		},
		autoApproveServiceRequests: false,
		autoApproveSupplierInitiatedWorkOrders: false,
	},
	approvalHierarchyConfig: {
		addUserToWorkOrderApprovalHierarchyOnTheGo: true,
		addUserToProposalApprovalHierarchyOnTheGo: true,
		addUserToInvoiceApprovalHierarchyOnTheGo: true,
	},
	newServiceRequest: {
		step1: {
			instructions: "Tell us a bit about the issue you'd like addressed.",
		},
		showArea: false,
		showAssets: true,
		showSingleLocation: true,
	},
};

const initialState: CompanyConfigState = {
	...standardInitialStateGenerator([]),
	detail: {
		config: {
			...defaultConfig,
		},
	},
};

export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState);

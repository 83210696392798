export const FETCH_CREDITS_START = 'FETCH_CREDITS_START';
export function fetchCreditsStart() {
	return {
		type: FETCH_CREDITS_START,
	};
}

export const FETCH_CREDITS_SUCCESS = 'FETCH_CREDITS_SUCCESS';
export function fetchCreditsSuccess(totalCreditsPurchasedToDate, availableCredits) {
	return {
		type: FETCH_CREDITS_SUCCESS,
		totalCreditsPurchasedToDate,
		availableCredits,
	};
}

export const FETCH_CREDITS_ERROR = 'FETCH_CREDITS_ERROR';
export function fetchCreditsError(error) {
	return {
		type: FETCH_CREDITS_ERROR,
		error,
	};
}

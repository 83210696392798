import * as React from 'react';
import { ReactNode } from 'react';
import { getObjectValues, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { Input } from 'antd';

require('./StaticVisualRadioInput.less');

interface OWAsyncRadioProps {
	placeholder?: string;
	valueAccessor?: any;
	records: any;
	outerStyle?: any;
	optionStyle?: any;
	size?: 'default' | 'large' | 'small';
	style?: any;
	fieldName: any;
	value?: any;
	onChange?: any;
	onClick?: any;
	loading?: boolean;
	disabled?: boolean;
	emptyWhenDisabled?: boolean;
	sortBy?: any;
	sorter?: any;
	renderRecord(data: any): ReactNode;
}

export default class OWRadio extends React.Component<OWAsyncRadioProps, any> {
	constructor(props) {
		super(props);
		const value = props.value;
		this.state = {
			value: value,
			filteredRecords: [],
			searchText: '',
		};
	}

	componentDidMount(): void {
		this.setState({
			filteredRecords: this.props.records,
		});
	}

	componentWillReceiveProps(nextProps) {
		// Should be a controlled component.
		if ('value' in nextProps) {
			this.setState({
				value: nextProps.value,
				filteredRecords: nextProps.records,
			});
		}
		return null;
	}

	handleValueChange = (e) => {
		if (!('value' in this.props)) {
			this.setState({ value: e.target.value });
		}
		this.triggerChange(e.target.value);
	};

	handleSameValueClicked = (e) => {
		const { onClick } = this.props;
		if (nullSafeGet('target.value', e) == this.state.value && onClick) {
			onClick(e.target.value);
		}
	};

	triggerChange = (changedValue) => {
		// Should provide an event to pass value to Form.
		const onChange = this.props.onChange;
		if (onChange) {
			onChange(changedValue);
		}
	};

	handleSearch = (e) => {
		const searchText = e.target.value && e.target.value.trim();
		const { records } = this.props;
		const filteredRecords = !!searchText
			? Object.keys(records)
					.filter((_) => {
						const record = records[_];
						return nullSafeGetOrElse('name', record, '')
							.toLowerCase()
							.includes(searchText.toLowerCase());
					})
					.reduce((acc, id) => {
						return {
							...acc,
							[id]: records[id],
						};
					}, {})
			: records;
		this.setState({ searchText, filteredRecords });
	};

	render() {
		const { value } = this.state;
		const {
			fieldName,
			records,
			sorter = (records) => records.sort((a, b) => (a.name > b.name ? 1 : -1)),
			valueAccessor = (el) => el.id,
			renderRecord,
			optionStyle = { height: 228 },
		} = this.props;
		const { filteredRecords } = this.state;
		const selectedVals = Array.isArray(value) ? value : [value];
		const selectedRecords = selectedVals
			.map((v) => records[v])
			.filter((r) => r && valueAccessor(r));
		const dropdownRecords = getObjectValues(filteredRecords);
		const visibleRecords = selectedRecords.concat(
			dropdownRecords.filter((r) => !selectedVals.some((val) => valueAccessor(r) == val))
		);
		const sortedVisibleRecords = sorter(visibleRecords);
		return (
			<>
				{sortedVisibleRecords.length <= 8 &&
				(!this.state.searchText || this.state.searchText.length === 0) ? null : (
					<div style={{ paddingBottom: 16, textAlign: 'center' }}>
						<Input.Search size="large" style={{ width: 320 }} onChange={this.handleSearch} />
					</div>
				)}
				{sortedVisibleRecords.some((option) => option.icon) ? (
					<div className="radio-container  radio-container--center state-in-box">
						{sortedVisibleRecords.map((option) => (
							<div key={valueAccessor(option)} className={option.disabled ? `disabled` : ''}>
								<div
									className={`radio-outer ${
										this.state.value == valueAccessor(option) ? 'checked' : ''
									} ${option.disabled ? 'disabled' : ''}`}
									style={optionStyle}
								>
									<input
										key={valueAccessor(option)}
										type="radio"
										name={fieldName}
										id={valueAccessor(option)}
										required={option.required}
										disabled={option.disabled}
										checked={this.state.value == valueAccessor(option)}
										onChange={this.handleValueChange}
										onClick={this.handleSameValueClicked}
										value={valueAccessor(option)}
									/>
									<label htmlFor={valueAccessor(option)}>
										<span data-pop-class="radio-pop">
											<div>
												<div className="state-in-box-inner">
													<div className="state-map">
														<i className={`icons8-font ${option.icon}`} />
													</div>
													<p>{renderRecord(option)}</p>
												</div>
											</div>
										</span>
									</label>
									<br />
									<div className="radio-box">
										<div className="radio-round"></div>
									</div>
								</div>
								<span className="feature-list"></span>
							</div>
						))}
					</div>
				) : (
					<div className="radio-container">
						{sortedVisibleRecords.map((option) => (
							<div key={valueAccessor(option)} className={option.disabled ? `disabled` : ''}>
								<div
									className={`radio-outer ${
										this.state.value == valueAccessor(option) ? 'checked' : ''
									} ${option.disabled ? 'disabled' : ''}`}
									style={optionStyle}
								>
									<input
										key={valueAccessor(option)}
										type="radio"
										name={fieldName}
										id={valueAccessor(option)}
										required={option.required}
										disabled={option.disabled}
										checked={this.state.value == valueAccessor(option)}
										onChange={this.handleValueChange}
										onClick={this.handleSameValueClicked}
										value={valueAccessor(option)}
									/>
									<label htmlFor={valueAccessor(option)}>
										<span data-pop-class="radio-pop">
											<p>{renderRecord(option)}</p>
										</span>
									</label>
									<br />
									<div className="radio-box">
										<div className="radio-round"></div>
									</div>
								</div>
								<span className="feature-list"></span>
							</div>
						))}
					</div>
				)}
			</>
		);
	}
}

import React from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
	Input,
	Alert,
	Button,
	Select,
	InputNumber,
	Card,
	Table,
	message,
	Col,
	Row,
	Popconfirm,
} from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import {
	getCurrency,
	nullSafeGeWithZero,
	nullSafeGet,
	nullSafeGetOrElse,
	stopDefaultEvents,
} from '../../utils/DataAccessUtils';
import BackButton from '../back_button/BackButton';
import { purchaseRequestsRestCrudThunksForSupplier } from '../../thunks/purchase_requests_thunks';
import { purchaseOrdersRestCrudThunksForSupplier } from '../../thunks/purchase_orders_thunks';
import { PurchaseRequestPartLineItemRowDisplay } from '../purchase_request_part_line_item_row_display/PurchaseRequestPartLineItemRowDisplay';
import { currencyFormatter } from '../../utils/DataFormatterUtils';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import { AddressDisplay } from '../address_display/AddressDisplay';
import { vendorsRestCrudThunksForSupplier } from '../../thunks/vendors_thunks';
import { associatePurchaseRequestLineItemToPurchaseOrderForSupplier } from '../../thunks/purchase_request_line_items_thunks';
import {
	deletePOLineItemForSupplier,
	purchaseOrderLineItemsRestCrudThunksForSupplier,
} from '../../thunks/purchase_order_line_items_thunks';
import { debounce } from '../../utils/PerformanceUtils';
import { PurchaseRequestEquipmentLineItemRowDisplay } from '../purchase_request_equipment_line_item_row_display/PurchaseRequestEquipmentLineItemRowDisplay';
import FormDots from '../common/FormDots';
import EditPOLineItemModal from './EditPOLineItemModal';
import { locationsRestCrudThunksForSupplier } from '../../thunks/locations_thunks';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import InfoWithKeyValuePair from '../supplier_assets_detail_page/InfoWithKeyValuePair';
import PRWarrantyAssetDetail from '../purchase_requests_detail_details_page/PRWarrantyAssetDetail';
import { brandsRestCrudThunksForSupplier } from '../../thunks/brands_thunks';
import OWRichTextEditor from '../../rich_text_editor/OWRichTextEditor';
import { equipmentCatalogsRestCrudThunksForSupplier } from '../../thunks/equipment_catalogs_thunks';
import { partCatalogsRestCrudThunksForSupplier } from '../../thunks/part_catalogs_thunks';
import AddLineItemsToPOModal from './AddLineItemsToPOModal';
import ChangeVendorCurrencyComponent from '../vendors_detail_details_page/ChangeVendorCurrencyComponent';
import ChoosePRForPOLineItemModal from './ChoosePRForPOLineItemModal';

const PURCHASE_ORDER_FORM_STOCK_LOCATION_DROPDOWN = 'purchaseOrderFormStockLocationDropdown';

require('./PurchaseOrderForm.less');

const VENDORS_TC = 'vendorsIndex';

interface PurchaseOrderFormProps extends FormComponentProps {
	creating: boolean;
	updating: boolean;
	formData: any;
	history: any;
	redirectForwardUrl?: string;
	onSuccess?: any;
	createErrors: any[];
	updateErrors: any[];
	purchaseOrders: any;
	purchaseRequests: any;
	stockLocations: any;
	onLineItemRemoved: any;
	vendors: any;
	addMoreLineItems: any;
	fetchStockLocations: any;
	fetchMultipleStockLocations: any;
	fetchPurchaseRequests: any;
	fetchVendor: any;
	currentUser: any;
	create: any;
	update: any;
	createLineItemsBulk: any;
	associatePurchaseRequestLineItemToPurchaseOrder: any;
	handleRemovePartFromCart: any;
	handleEditPart: any;
	handleUpdateLineItems: any;
	handleRemoveEquipmentFromCart: any;
	handleEditEquipment: any;
	onCancel?: any;
	fetchVendors: any;
	fetchMultipleVendors: any;
	locations: any;
	fetchLocations: any;
	fetchMultipleLocations: any;
	brands: any;
	fetchBrands: any;
	fetchMultipleBrands: any;
	currencies: any;
	fetchPartCatalog: any;
	fetchEquipmentCatalog: any;
	removeLineItemCosts: any;
	deletePOLineItem: any;
}

const WarrantyAssetCostFieldDisplay: React.FC<any> = ({
	currencyId,
	record,
	cost,
}): React.ReactElement => {
	const currency = getCurrency({ currencyId });
	return record.warrantyAssetId ? (
		<div className="flex flex-row items-center">
			<div>{currency.format(0)}</div>
			<InfoWithKeyValuePair
				details={{
					'Warranty Asset': (
						<PRWarrantyAssetDetail
							id={record.warrantyAssetId}
							asset={record.warrantyAsset}
							backLinkText={'Back To Purchase Order'}
						/>
					),
				}}
			/>
		</div>
	) : cost !== undefined ? (
		currency.format(cost)
	) : (
		'--'
	);
};

class PurchaseOrderForm extends React.Component<PurchaseOrderFormProps, any> {
	state = {
		selectedRowKeys: [],
		editingLineItemDetails: {},
		editLineItemModalVisible: false,
		creatingPO: false,
		editorRef: React.createRef(),
		showAddPRLineItemsModal: false,
		addPRLineItemsIsEquipmentLine: false,
		currencyId: '',
		deletedLineItems: [],
	};

	getVendor = (vendorId) => {
		const { vendors, fetchVendor } = this.props;
		return new Promise((resolve, reject) => {
			const vendorRecords = getRecordsForTargetCollection(vendors, VENDORS_TC);
			const vendor = vendorRecords.find((_) => _.id === vendorId);
			if (vendor) {
				resolve(vendor);
			} else {
				fetchVendor(vendorId)
					.then((res) => resolve(res))
					.catch((err) => reject(err));
			}
		});
	};

	populateCosts = (vendorId) => {
		if (!vendorId) return;

		const { formData, fetchPartCatalog, fetchEquipmentCatalog, removeLineItemCosts } = this.props;

		this.getVendor(vendorId).then((vendor) => {
			if (
				nullSafeGet('currencyId', vendor) &&
				this.state.currencyId !== nullSafeGet('currencyId', vendor)
			) {
				this.setState({ currencyId: nullSafeGet('currencyId', vendor) });
				removeLineItemCosts && removeLineItemCosts();
				this.recalculateTotals();
				return;
			}

			const parts = nullSafeGetOrElse('partSupplierPurchaseOrderLineItems', formData, []);
			const partIds = parts.map((_) => _.partId).join(',');
			partIds &&
				fetchPartCatalog({
					partIds,
					partEquipmentVendorId: vendorId,
				}).then((catalogs) => this.handleCostFromPartCatalog(parts, catalogs));

			const equipmentTypes = nullSafeGetOrElse(
				'equipmentSupplierPurchaseOrderLineItems',
				formData,
				[]
			);
			const equipmentTypeIds = equipmentTypes.map((_) => _.equipmentTypeId).join(',');
			equipmentTypeIds &&
				fetchEquipmentCatalog({
					equipmentTypeIds,
					partEquipmentVendorId: vendorId,
				}).then((catalogs) => this.handleCostFromEquipmentCatalog(equipmentTypes, catalogs));
		});
	};

	componentDidMount() {
		const { formData, currentUser } = this.props;
		console.log(
			formData.currencyId ||
				nullSafeGet('partEquipmentVendor.currencyId', formData) ||
				nullSafeGet('id', getCurrency({ currentUser }))
		);
		this.setState({
			currencyId:
				formData.currencyId ||
				nullSafeGet('partEquipmentVendor.currencyId', formData) ||
				nullSafeGet('id', getCurrency({ currentUser })),
		});
		this.recalculateTotals();
		this.populateCosts(nullSafeGet('partEquipmentVendorId', formData));
	}

	isAssetAvailable = (poLineItem) =>
		nullSafeGetOrElse('supplierPurchaseRequestLineItems', poLineItem, [])
			.map((_) => _.supplierPurchaseRequest)
			.some((_) => !!_ && !!_.asset);

	getDescription = () =>
		nullSafeGet('state.editorRef.current', this) &&
		(this.state.editorRef.current as any).getContent();

	deleteLineItemsIfAny = () => {
		const { deletePOLineItem } = this.props;
		return new Promise((resolve, reject) => {
			const lineItemsToDelete = nullSafeGetOrElse('deletedLineItems', this.state, []);
			if (lineItemsToDelete.length > 0) {
				const promises = lineItemsToDelete
					.map((poLi) => (poLi.id ? deletePOLineItem(poLi, poLi.id) : undefined))
					.filter((_) => !!_);
				Promise.all(promises)
					.then(() => resolve(true))
					.catch((err) => reject(err));
			} else {
				resolve(true);
			}
		});
	};

	handleSubmit = (e, submitAction) => {
		const {
			history,
			onSuccess,
			redirectForwardUrl,
			currentUser,
			createLineItemsBulk,
			formData,
			associatePurchaseRequestLineItemToPurchaseOrder,
		} = this.props;
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const partItems = formData.partSupplierPurchaseOrderLineItems.map((partsKey) => {
					let partItem = {
						isEquipmentLine: false,
						partId: partsKey.partId,
						...(partsKey.partCatalog && { partCatalogId: partsKey.partCatalog.id }),
						partQuantity: parseInt(partsKey.partQuantity, 10),
						partCost:
							typeof partsKey.partCost !== 'undefined' ? parseFloat(partsKey.partCost) : undefined,
						partCurrencyId: this.state.currencyId,
						partUomId: 1,
						equipmentCurrencyId: this.state.currencyId,
						equipmentUomId: 1,
						supplierFacilityId: values.supplierFacilityId,
						supplierCompanyId: values.supplierCompanyId,
						prLineItemIds: partsKey.prLineItemIds,
						...(partsKey.warrantyAssetId && { warrantyAssetId: partsKey.warrantyAssetId }),
						hasAsset: this.isAssetAvailable(partsKey),
					};
					return partItem;
				});

				const equipmentItems = formData.equipmentSupplierPurchaseOrderLineItems.map(
					(equipmentsKey) => {
						let equipmentItem = {
							isEquipmentLine: true,
							partCurrencyId: this.state.currencyId,
							partUomId: 1,
							equipmentTypeId: equipmentsKey.equipmentTypeId,
							...(equipmentsKey.equipmentCatalog && {
								equipmentCatalogId: equipmentsKey.equipmentCatalog.id,
							}),
							equipmentQuantity: parseInt(equipmentsKey.equipmentQuantity, 10),
							equipmentCost:
								typeof equipmentsKey.equipmentCost !== 'undefined'
									? parseFloat(equipmentsKey.equipmentCost)
									: undefined,
							equipmentCurrencyId: this.state.currencyId,
							equipmentUomId: 1,
							supplierFacilityId: values.supplierFacilityId,
							supplierCompanyId: values.supplierCompanyId,
							prLineItemIds: equipmentsKey.prLineItemIds,
							...(equipmentsKey.warrantyAssetId && {
								warrantyAssetId: equipmentsKey.warrantyAssetId,
							}),
							hasAsset: this.isAssetAvailable(equipmentsKey),
						};
						return equipmentItem;
					}
				);

				if ([...partItems, ...equipmentItems].length < 1) {
					message.error('Purchase order should contain at least one part/equipment!');
					return;
				}

				if (
					[...partItems.map((_) => _.partCost), ...equipmentItems.map((_) => _.equipmentCost)].some(
						(_) => typeof _ === 'undefined'
					)
				) {
					message.error('Parts and Equipment should have cost!');
					return;
				}

				const purchase_order = {
					id: values.id,
					partEquipmentVendorId: values.partEquipmentVendorId,
					description: this.getDescription(),
					...(values.stockLocationId && { stockLocationId: values.stockLocationId }),
					...(values.locationId && { locationId: values.locationId }),
					supplierFacilityId: values.supplierFacilityId,
					supplierCompanyId: values.supplierCompanyId,
					freightTotalBeforeTax: values.freightTotalBeforeTax
						? parseFloat(values.freightTotalBeforeTax)
						: undefined,
					miscTotalBeforeTax: values.miscTotalBeforeTax
						? parseFloat(values.miscTotalBeforeTax)
						: undefined,
					totalBeforeTax: values.totalBeforeTax ? parseFloat(values.totalBeforeTax) : undefined,
					tax: values.tax ? parseFloat(values.tax) : undefined,
					totalCost: values.totalCost ? parseFloat(values.totalCost) : undefined,
					currencyId: this.state.currencyId,
					status: 'new',
					brandId: values.brandId,
					paymentTerms: values.paymentTerms,
					shippingTerms: values.shippingTerms,
					createdByEmail: currentUser.email,
				};

				this.setState({ creatingPO: true });

				this.deleteLineItemsIfAny()
					.then(() => {
						submitAction(purchase_order).then((record) => {
							const updatedPartItems = partItems.map((partItem) => {
								return {
									...partItem,
									supplierPurchaseOrderId: record.id,
								};
							});

							const updatedEquipmentItems = equipmentItems.map((equipmentItem) => {
								return {
									...equipmentItem,
									supplierPurchaseOrderId: record.id,
								};
							});

							createLineItemsBulk(updatedPartItems.concat(updatedEquipmentItems))
								.then((lineItems) => {
									lineItems.map((lineItem) => {
										[...partItems, ...equipmentItems]
											.filter(
												(i) =>
													(!!i.partId && i.partId === lineItem.partId) ||
													(!!i.equipmentTypeId && i.equipmentTypeId === lineItem.equipmentTypeId)
											)
											.map((partItem) => {
												if (partItem.prLineItemIds) {
													associatePurchaseRequestLineItemToPurchaseOrder(
														partItem.prLineItemIds.join(','),
														lineItem.id
													);
												}
											});
									});
									if (redirectForwardUrl) {
										history.push(redirectForwardUrl);
									}
									if (onSuccess) {
										onSuccess(record);
									}
								})
								.finally(() => this.setState({ creatingPO: false }));
						});
					})
					.catch((err) => {
						message.error(err);
						this.setState({ creatingPO: false });
					});
			}
		});
	};

	hideEditLineItemModal = () => this.setState({ editLineItemModalVisible: false });

	handleEditPOPartLineItem = (record) => {
		this.setState({
			editingLineItemDetails: {
				type: 'part',
				cost: record.partCost,
				quantity: record.partQuantity,
				title: `Edit Part`,
				record,
			},
			editLineItemModalVisible: true,
		});
	};

	handleEditPOEquipmentLineItem = (record) => {
		this.setState({
			editingLineItemDetails: {
				type: 'equipment',
				cost: record.equipmentCost,
				quantity: record.equipmentQuantity,
				title: `Edit Equipment`,
				record,
			},
			editLineItemModalVisible: true,
		});
	};

	onEditLineItemSuccess = (totalQuantity, totalCost) => {
		const { handleEditPart, handleEditEquipment } = this.props;
		const { editingLineItemDetails } = this.state;
		if (nullSafeGet('type', editingLineItemDetails) === 'part') {
			handleEditPart({
				...nullSafeGet('record', editingLineItemDetails),
				partQuantity: totalQuantity,
				partCost: totalCost,
			});
		} else if (nullSafeGet('type', editingLineItemDetails) === 'equipment') {
			handleEditEquipment({
				...nullSafeGet('record', editingLineItemDetails),
				equipmentQuantity: totalQuantity,
				equipmentCost: totalCost,
			});
		}
		this.hideEditLineItemModal();
		this.recalculateTotals();
	};

	recalculateTotals = debounce(() => {
		const { form, formData } = this.props;
		let accCost = 0.0;

		formData.partSupplierPurchaseOrderLineItems.forEach(
			(partItem) => (accCost = accCost + parseFloat(nullSafeGetOrElse('partCost', partItem, 0)))
		);

		formData.equipmentSupplierPurchaseOrderLineItems.forEach(
			(equipmentItem) =>
				(accCost = accCost + parseFloat(nullSafeGetOrElse('equipmentCost', equipmentItem, 0)))
		);

		const subtotals = form.getFieldsValue([
			'totalBeforeTax',
			'freightTotalBeforeTax',
			'miscTotalBeforeTax',
			'tax',
		]);

		form.setFieldsValue({
			totalBeforeTax:
				accCost +
				parseFloat(nullSafeGetOrElse('freightTotalBeforeTax', subtotals, 0)) +
				parseFloat(nullSafeGetOrElse('miscTotalBeforeTax', subtotals, 0)),
			totalCost:
				accCost +
				parseFloat(nullSafeGetOrElse('freightTotalBeforeTax', subtotals, 0)) +
				parseFloat(nullSafeGetOrElse('miscTotalBeforeTax', subtotals, 0)) +
				parseFloat(nullSafeGetOrElse('tax', subtotals, 0)),
		});
	}, 200);

	handleCostFromPartCatalog = (parts, catalogs) => {
		const { handleEditPart } = this.props;
		if (catalogs && catalogs.length > 0) {
			catalogs.map((cat) => {
				const part = parts.find((_) => _.partId === cat.partId);
				if (part && !part.partCost && cat.listPrice) {
					handleEditPart({
						...part,
						partCatalog: cat,
						partCost: cat.listPrice * nullSafeGetOrElse('partQuantity', part, 0),
					});
					this.recalculateTotals();
				}
			});
		}
	};

	handleCostFromEquipmentCatalog = (equipmentType, catalogs) => {
		const { handleEditEquipment } = this.props;
		if (catalogs && catalogs.length > 0) {
			catalogs.map((cat) => {
				const entity = equipmentType.find((_) => _.equipmentTypeId === cat.equipmentTypeId);
				if (entity && !entity.equipmentCost && cat.listPrice) {
					handleEditEquipment({
						...entity,
						equipmentCatalog: cat,
						equipmentCost: cat.listPrice * nullSafeGetOrElse('equipmentQuantity', entity, 0),
					});
					this.recalculateTotals();
				}
			});
		}
	};

	handleVendorChange = (vendorId) => {
		const { form } = this.props;
		this.getVendor(vendorId).then((vendor) => {
			const { setFieldsValue } = form;
			setFieldsValue({
				...(nullSafeGet('paymentTerms', vendor) && {
					paymentTerms: nullSafeGet('paymentTerms', vendor),
				}),
				...(nullSafeGet('shippingTerms', vendor) && {
					shippingTerms: nullSafeGet('shippingTerms', vendor),
				}),
			});

			this.populateCosts(vendorId);
		});
	};

	handleAddPRLineItem = (isEquipmentLine) => () => {
		this.setState({
			showAddPRLineItemsModal: true,
			addPRLineItemsIsEquipmentLine: !!isEquipmentLine,
		});
	};

	handleDeleteLineItem = (record) => {
		const { formData, handleUpdateLineItems } = this.props;
		const deletedItems = nullSafeGetOrElse('deletedLineItems', this.state, []);
		this.setState({ deletedLineItems: [...deletedItems, ...[record]] });

		const partLineItems = nullSafeGetOrElse('partSupplierPurchaseOrderLineItems', formData, []);
		const equipmentLineItems = nullSafeGetOrElse(
			'equipmentSupplierPurchaseOrderLineItems',
			formData,
			[]
		);

		const filterFn = (li) =>
			li.id
				? li.id !== record.id
				: li.isEquipmentLine
				? li.equipmentTypeId !== record.equipmentTypeId
				: li.partId !== record.partId;

		handleUpdateLineItems &&
			handleUpdateLineItems(partLineItems.filter(filterFn), equipmentLineItems.filter(filterFn));

		this.recalculateTotals();
	};

	onAddLineItemsSuccess = (addedItems) => {
		const { handleUpdateLineItems } = this.props;
		this.onAddPRLineItemsCancel();
		handleUpdateLineItems &&
			handleUpdateLineItems(addedItems.partLineItems, addedItems.equipmentLineItems);
		this.recalculateTotals();
	};

	onAddPRLineItemsCancel = () =>
		this.setState({
			showAddPRLineItemsModal: false,
		});

	onVendorUpdate = (vendor) => {
		this.handleVendorChange(vendor.id);
	};

	render() {
		const {
			form,
			update,
			create,
			stockLocations,
			fetchStockLocations,
			fetchMultipleStockLocations,
			locations,
			fetchLocations,
			fetchMultipleLocations,
			createErrors,
			formData,
			updateErrors,
			currentUser,
			onCancel,
			vendors,
			fetchVendors,
			fetchMultipleVendors,
			brands,
			fetchBrands,
			fetchMultipleBrands,
		} = this.props;
		const { getFieldDecorator, getFieldValue } = form;
		const isUpdate = formData && formData.id !== undefined;
		const submitAction = isUpdate ? update : create;
		const submitText = isUpdate ? 'Update purchase order' : 'Create purchase order';
		const stockLocationId = getFieldValue('stockLocationId');
		const locationId = getFieldValue('locationId');

		const canAddNewPRItems = !!nullSafeGet('id', formData);

		const poPartLineItemsColumns = [
			{
				title: 'Part Number',
				dataIndex: ['part', 'partNumber'],
			},
			{
				title: 'Part Detail',
				dataIndex: 'partId',
				render: (text, record) => (
					<PurchaseRequestPartLineItemRowDisplay
						titleLength={32}
						purchaseRequestPartLineItem={record}
					/>
				),
			},
			{
				title: 'Quantity',
				dataIndex: 'partQuantity',
				render: (_, record) => (
					<div className="flex flex-row items-center">
						<div style={{ whiteSpace: 'nowrap' }}>
							{nullSafeGetOrElse('partQuantity', record, '--')}{' '}
							{nullSafeGetOrElse('partQuantity', record, 0) > 1
								? nullSafeGet('partUom.displayNamePlural', record)
								: nullSafeGet('partUom.displayNameSingular', record)}
						</div>
					</div>
				),
			},
			{
				title: 'Unit Cost',
				render: (_, record) => (
					<WarrantyAssetCostFieldDisplay
						currencyId={this.state.currencyId}
						record={record}
						cost={
							record.partCost !== undefined
								? nullSafeGetOrElse('partCost', record, 0) /
								  nullSafeGetOrElse('partQuantity', record, 1)
								: undefined
						}
					/>
				),
			},
			{
				title: 'Total Cost',
				dataIndex: 'partCost',
				render: (_, record) => (
					<WarrantyAssetCostFieldDisplay
						currencyId={this.state.currencyId}
						record={record}
						cost={_}
					/>
				),
			},
			{
				title: 'Actions',
				dataIndex: 'id',
				render: (id, record) => (
					<div className="flex flex-row items-center">
						<EditOutlined
							title="Edit Cost"
							translate=""
							onClick={() => this.handleEditPOPartLineItem(record)}
						/>
						<div className="ml-2">
							<Popconfirm
								title={'Are you sure, you want to remove this part?'}
								onConfirm={(e) => {
									stopDefaultEvents(e);
									this.handleDeleteLineItem(record);
								}}
								onCancel={(e) => stopDefaultEvents(e)}
								okText="Yes"
							>
								<DeleteOutlined title="Delete" translate="" />
							</Popconfirm>
						</div>
					</div>
				),
			},
		];

		const poEquipmentLineItemsColumns = [
			{
				title: 'Equipment Type #',
				dataIndex: ['equipmentType', 'equipmentTypeNumber'],
			},
			{
				title: 'Equipment Detail',
				dataIndex: 'equipmentTypeId',
				render: (text, record) => (
					<PurchaseRequestEquipmentLineItemRowDisplay
						titleLength={32}
						purchaseRequestEquipmentLineItem={record}
					/>
				),
			},
			{
				title: 'Quantity',
				dataIndex: 'equipmentQuantity',
			},
			{
				title: 'Unit Cost',
				render: (_, record) => (
					<WarrantyAssetCostFieldDisplay
						currencyId={this.state.currencyId}
						record={record}
						cost={
							record.equipmentCost !== undefined
								? nullSafeGetOrElse('equipmentCost', record, 0) /
								  nullSafeGetOrElse('equipmentQuantity', record, 1)
								: undefined
						}
					/>
				),
			},
			{
				title: 'Total Cost',
				dataIndex: 'equipmentCost',
				render: (_, record) => (
					<WarrantyAssetCostFieldDisplay
						currencyId={this.state.currencyId}
						record={record}
						cost={_}
					/>
				),
			},
			{
				title: 'Actions',
				dataIndex: 'id',
				render: (id, record) => (
					<div className="flex flex-row items-center">
						<EditOutlined
							title="Edit Cost"
							translate=""
							onClick={() => this.handleEditPOEquipmentLineItem(record)}
						/>
						<div className="ml-2">
							<Popconfirm
								title={'Are you sure, you want to remove this equipment?'}
								onConfirm={(e) => {
									stopDefaultEvents(e);
									this.handleDeleteLineItem(record);
								}}
								onCancel={(e) => stopDefaultEvents(e)}
								okText="Yes"
							>
								<DeleteOutlined title="Delete" translate="" />
							</Popconfirm>
						</div>
					</div>
				),
			},
		];

		const location = locationId
			? nullSafeGet(`${locationId}`, locations.records)
			: nullSafeGet(`${stockLocationId}.location`, stockLocations.records);

		const shipToPrefix = nullSafeGet('locationType.name', location)
			? `${nullSafeGet('locationType.name', location)} ${location.storeId}`
			: null;

		const shipToAddress = locationId
			? nullSafeGet(`${locationId}.buyerFacility.primaryAddress`, locations.records)
			: nullSafeGet(`${stockLocationId}.address`, stockLocations.records)
			? nullSafeGet(`${stockLocationId}.address`, stockLocations.records)
			: nullSafeGet(
					`${stockLocationId}.location.buyerFacility.primaryAddress`,
					stockLocations.records
			  )
			? nullSafeGet(
					`${stockLocationId}.location.buyerFacility.primaryAddress`,
					stockLocations.records
			  )
			: null;

		const defaultBrandId = locationId
			? nullSafeGet(`${locationId}.brandId`, locations.records)
			: nullSafeGet(`${stockLocationId}.location.brandId`, stockLocations.records);

		getFieldDecorator('id', { initialValue: formData.id });
		getFieldDecorator('partEquipmentVendorId', { initialValue: formData.partEquipmentVendorId });
		getFieldDecorator('supplierFacilityId', {
			initialValue: formData.supplierFacilityId || nullSafeGet('facility.id', currentUser),
		});
		getFieldDecorator('supplierCompanyId', {
			initialValue:
				formData.supplierCompanyId || nullSafeGet('facility.supplierCompanyId', currentUser),
		});

		getFieldDecorator(`totalBeforeTax`, {
			initialValue: nullSafeGet(`totalBeforeTax`, formData),
		});

		getFieldDecorator(`totalCost`, {
			initialValue: nullSafeGet(`totalCost`, formData),
		});

		getFieldDecorator(`brandId`, {
			initialValue: nullSafeGet(`brandId`, formData) || defaultBrandId,
		});

		return (
			<Form
				layout="vertical"
				onSubmit={(e) => this.handleSubmit(e, submitAction)}
				className="purchaseOrderForm"
			>
				{createErrors.length > 0 ? (
					<Form.Item>
						<Alert message={createErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				{updateErrors.length > 0 ? (
					<Form.Item>
						<Alert message={updateErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}

				<div className="rowSpacing">
					<div style={{ marginBottom: 12 }}>
						<h5 style={{ marginBottom: 0 }}>Details</h5>
					</div>
					<Card>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<Form.Item label="Vendor" style={{ flex: 1, marginRight: 24 }}>
								{nullSafeGet('partEquipmentVendorId', formData) ? (
									<div style={{ lineHeight: '38px' }}>
										{nullSafeGet(`partEquipmentVendor.name`, formData)}
									</div>
								) : (
									getFieldDecorator('partEquipmentVendorId', {
										initialValue: formData.partEquipmentVendorId,
										rules: [
											{
												required: true,
												message: 'Please select a vendor',
											},
										],
									})(
										<OWAsyncSelect
											style={{ maxWidth: 300 }}
											stateSlice={vendors}
											targetCollectionName={VENDORS_TC}
											onChange={this.handleVendorChange}
											fetchMultiple={(ids, targetCollectionName) => {
												fetchMultipleVendors(ids, targetCollectionName);
											}}
											fetchData={(searchText, targetCollectionName) => {
												fetchVendors({ name: searchText }, targetCollectionName);
											}}
											renderRecord={(v) => (
												<Select.Option key={v.id} value={v.id}>
													{v.name}
												</Select.Option>
											)}
										/>
									)
								)}
							</Form.Item>
							<Form.Item label="Currency" style={{ flex: 1, marginRight: 24 }}>
								{this.state.currencyId ? (
									<div className="flex flex-row items-center">
										<div>{this.state.currencyId}</div>
										{getFieldValue('partEquipmentVendorId') ? (
											<ChangeVendorCurrencyComponent
												vendorId={getFieldValue('partEquipmentVendorId')}
												onSuccess={this.onVendorUpdate}
											/>
										) : null}
									</div>
								) : null}
							</Form.Item>
							<Form.Item label="Ship to" style={{ flex: 1, marginRight: 24 }}>
								{formData.locationId
									? getFieldDecorator('locationId', {
											initialValue: formData.locationId,
									  })(
											<OWAsyncSelect
												disabled
												key="locationDropdown"
												style={{ maxWidth: 300 }}
												stateSlice={locations}
												targetCollectionName={'locationsIndex'}
												fetchMultiple={(ids, targetCollectionName) => {
													fetchMultipleLocations(ids, targetCollectionName);
												}}
												fetchData={(searchText, targetCollectionName) => {
													fetchLocations({ name: searchText }, targetCollectionName);
												}}
												renderRecord={(location) => (
													<Select.Option key={location.id} value={location.id}>
														{location.name}
													</Select.Option>
												)}
											/>
									  )
									: getFieldDecorator('stockLocationId', {
											initialValue: formData.stockLocationId,
									  })(
											<OWAsyncSelect
												disabled
												key="stockLocationDropdown"
												style={{ maxWidth: 300 }}
												stateSlice={stockLocations}
												targetCollectionName={PURCHASE_ORDER_FORM_STOCK_LOCATION_DROPDOWN}
												fetchMultiple={(ids, targetCollectionName) => {
													fetchMultipleStockLocations(ids, targetCollectionName);
												}}
												fetchData={(searchText, targetCollectionName) => {
													fetchStockLocations({ name: searchText }, targetCollectionName);
												}}
												renderRecord={(stockLocation) => (
													<Select.Option key={stockLocation.id} value={stockLocation.id}>
														{stockLocation.name}
													</Select.Option>
												)}
											/>
									  )}
							</Form.Item>
							<Form.Item label="Brand" style={{ flex: 1, marginRight: 24 }}>
								{getFieldDecorator('brandId', {
									initialValue: formData.brandId || defaultBrandId,
								})(
									<OWAsyncSelect
										style={{ maxWidth: 300 }}
										stateSlice={brands}
										targetCollectionName={'poBrandsIndex'}
										fetchMultiple={(ids, targetCollectionName) => {
											fetchMultipleBrands(ids, targetCollectionName);
										}}
										fetchData={(searchText, targetCollectionName) => {
											fetchBrands({ name: searchText }, targetCollectionName);
										}}
										renderRecord={(b) => (
											<Select.Option key={b.id} value={b.id}>
												{b.brandName}
											</Select.Option>
										)}
									/>
								)}
							</Form.Item>
							<Form.Item label="Shipping Address" style={{ flex: 1, marginRight: 24 }}>
								{shipToAddress ? (
									<AddressDisplay
										prefix={shipToPrefix}
										style={{ lineHeight: 'auto' }}
										address={shipToAddress}
									/>
								) : null}
							</Form.Item>
						</div>
					</Card>
				</div>
				<div className="rowSpacing">
					<Row>
						<Col style={{ paddingRight: 12, marginBottom: 12 }} span={16}>
							<Card style={{ height: '100%' }}>
								<Form.Item label="Description" style={{ flex: 1, marginRight: 24 }}>
									{getFieldDecorator('description', {
										initialValue: nullSafeGet('description', formData),
										valuePropName: 'initialEditorState',
									})(
										<OWRichTextEditor
											initialEditorState={formData.description}
											editorRef={this.state.editorRef}
										/>
									)}
								</Form.Item>
							</Card>
						</Col>
						<Col span={8}>
							<div className="rowSpacing">
								<Card>
									<Form.Item label="Payment terms" style={{ flex: 1, marginRight: 24 }}>
										{getFieldDecorator('paymentTerms', {
											initialValue:
												nullSafeGet('paymentTerms', formData) ||
												nullSafeGet('partEquipmentVendor.paymentTerms', formData),
											//valuePropName: 'initialEditorState',
										})(<Input.TextArea />)}
									</Form.Item>
								</Card>
							</div>
							<div className="rowSpacing">
								<Card>
									<Form.Item label="Shipping terms" style={{ flex: 1 }}>
										{getFieldDecorator('shippingTerms', {
											initialValue:
												nullSafeGet('shippingTerms', formData) ||
												nullSafeGet('partEquipmentVendor.shippingTerms', formData),
											//valuePropName: 'initialEditorState',
										})(<Input.TextArea />)}
									</Form.Item>
								</Card>
							</div>
						</Col>
					</Row>
				</div>

				<div className="rowSpacing">
					<Card
						title="Parts"
						extra={
							canAddNewPRItems && [
								<Button className="ml-2" onClick={this.handleAddPRLineItem(false)}>
									Add Part
								</Button>,
							]
						}
					>
						{nullSafeGetOrElse('partSupplierPurchaseOrderLineItems', formData, []).length > 0 ? (
							<Table
								columns={poPartLineItemsColumns}
								rowKey={(part) => part['partId']}
								dataSource={formData.partSupplierPurchaseOrderLineItems || []}
								loading={false}
							/>
						) : (
							<div className="flex flex-row justify-center">No part added!</div>
						)}
					</Card>
				</div>

				<div className="rowSpacing">
					<Card
						title="Equipment"
						extra={
							canAddNewPRItems && [
								<Button className="ml-2" onClick={this.handleAddPRLineItem(true)}>
									Add Equipment
								</Button>,
							]
						}
					>
						{nullSafeGetOrElse('equipmentSupplierPurchaseOrderLineItems', formData, []).length >
						0 ? (
							<Table
								columns={poEquipmentLineItemsColumns}
								rowKey={(equipment) => equipment['equipmentTypeId']}
								dataSource={formData.equipmentSupplierPurchaseOrderLineItems || []}
								loading={false}
							/>
						) : (
							<div className="flex flex-row justify-center">No equipment added!</div>
						)}
					</Card>
				</div>
				<div className="supplierPurchaseOrderForm">
					<div style={{ marginBottom: 32 }}>
						<div className="supplierPurchaseOrderForm__sectionHead">
							<div className="supplierPurchaseOrderForm__sectionHeadPrimary">Freight</div>
							<FormDots />
							<div className="supplierPurchaseOrderForm__sectionHeadSecondary">
								{getFieldDecorator(`freightTotalBeforeTax`, {
									validateTrigger: ['onBlur'],
									initialValue: nullSafeGetOrElse(`freightTotalBeforeTax`, formData, 0),
									rules: [
										{
											required: true,
											message: 'Please specify freight charges if any.',
										},
									],
								})(
									<InputNumber step={1} onChange={this.recalculateTotals} style={{ width: 240 }} />
								)}
							</div>
						</div>
					</div>
					<div style={{ marginBottom: 32 }}>
						<div className="supplierPurchaseOrderForm__sectionHead">
							<div className="supplierPurchaseOrderForm__sectionHeadPrimary">Miscellaneous</div>
							<FormDots />
							<div className="supplierPurchaseOrderForm__sectionHeadSecondary">
								{getFieldDecorator(`miscTotalBeforeTax`, {
									validateTrigger: ['onBlur'],
									initialValue: nullSafeGetOrElse(`miscTotalBeforeTax`, formData, 0),
									rules: [
										{
											required: true,
											message: 'Please specify miscellaneous charges if any.',
										},
									],
								})(
									<InputNumber step={1} onChange={this.recalculateTotals} style={{ width: 240 }} />
								)}
							</div>
						</div>
					</div>
					<div style={{ marginBottom: 32 }}>
						<div className="supplierPurchaseOrderForm__sectionHead">
							<div className="supplierPurchaseOrderForm__sectionHeadPrimary">Total Before Tax</div>
							<FormDots />
							<div className="supplierPurchaseOrderForm__sectionHeadSecondary">
								{currencyFormatter()(getFieldValue('totalBeforeTax'))}
							</div>
						</div>
					</div>
					<div style={{ marginBottom: 32 }}>
						<div className="supplierPurchaseOrderForm__sectionHead">
							<div className="supplierPurchaseOrderForm__sectionHeadPrimary">Tax</div>
							<FormDots />
							<div className="supplierPurchaseOrderForm__sectionHeadSecondary">
								{getFieldDecorator(`tax`, {
									validateTrigger: ['onBlur'],
									initialValue: nullSafeGetOrElse(`tax`, formData, 0),
									rules: [
										{
											required: true,
											message: 'Please specify tax if any.',
										},
									],
								})(
									<InputNumber step={1} onChange={this.recalculateTotals} style={{ width: 240 }} />
								)}
							</div>
						</div>
					</div>
					<div style={{ marginBottom: 32 }}>
						<div className="supplierPurchaseOrderForm__sectionHead">
							<div className="supplierPurchaseOrderForm__sectionHeadPrimary">Total With Tax</div>
							<FormDots />
							<div className="supplierPurchaseOrderForm__sectionHeadSecondary">
								{currencyFormatter()(getFieldValue('totalCost'))}
							</div>
						</div>
					</div>
				</div>
				<Form.Item>
					<Button type="primary" htmlType="submit" loading={this.state.creatingPO}>
						{submitText}
					</Button>
					<span style={{ marginLeft: '16px' }}>
						{onCancel ? (
							<Button onClick={onCancel}>Cancel</Button>
						) : (
							<BackButton buttonText="Cancel" />
						)}
					</span>
				</Form.Item>
				{this.state.editLineItemModalVisible ? (
					<EditPOLineItemModal
						cost={nullSafeGeWithZero('editingLineItemDetails.cost', this.state)}
						quantity={nullSafeGet('editingLineItemDetails.quantity', this.state)}
						title={nullSafeGet('editingLineItemDetails.title', this.state)}
						record={nullSafeGet('editingLineItemDetails.record', this.state)}
						partEquipmentVendorId={
							form.getFieldsValue(['partEquipmentVendorId']).partEquipmentVendorId
						}
						onCancel={this.hideEditLineItemModal}
						onSuccess={this.onEditLineItemSuccess}
						poCurrencyId={this.state.currencyId}
					/>
				) : null}

				{this.state.showAddPRLineItemsModal && (
					<AddLineItemsToPOModal
						isEquipmentLine={this.state.addPRLineItemsIsEquipmentLine}
						stockLocationId={stockLocationId}
						locationId={locationId}
						partEquipmentVendorId={
							form.getFieldsValue(['partEquipmentVendorId']).partEquipmentVendorId
						}
						existingPartLineItems={formData.partSupplierPurchaseOrderLineItems || []}
						existingEquipmentLineItems={formData.equipmentSupplierPurchaseOrderLineItems || []}
						onCancel={this.onAddPRLineItemsCancel}
						onAddLineItemsSuccess={this.onAddLineItemsSuccess}
					/>
				)}
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	formData: ownProps.formData,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,

	createErrors: state.purchase_orders.createErrors,
	updateErrors: state.purchase_orders.updateErrors,
	creating: state.purchase_orders.creating,
	updating: state.purchase_orders.updating,
	purchaseRequests: state.purchase_requests,
	purchaseOrders: state.purchase_orders,
	stockLocations: state.stock_locations,
	vendors: state.vendors,
	currentUser: state.session.currentUser,
	onLineItemRemoved: ownProps.onLineItemRemoved,
	locations: state.locations,
	brands: state.brands,
	currencies: state.currencies,
});

const mapDispatchToProps = (dispatch) => ({
	update: (entity) => dispatch(purchaseOrdersRestCrudThunksForSupplier.update(entity)),
	create: (entity) => dispatch(purchaseOrdersRestCrudThunksForSupplier.create(entity)),
	createLineItemsBulk: (entities) =>
		dispatch(purchaseOrderLineItemsRestCrudThunksForSupplier.createBulk(entities)),
	associatePurchaseRequestLineItemToPurchaseOrder: (ids, purchaseOrderId) =>
		dispatch(associatePurchaseRequestLineItemToPurchaseOrderForSupplier(ids, purchaseOrderId)),
	fetchPurchaseRequests: (params, targetCollectionName) =>
		dispatch(purchaseRequestsRestCrudThunksForSupplier.read(params, targetCollectionName)),
	fetchStockLocations: (params, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.read(params, targetCollectionName)),
	fetchMultipleStockLocations: (ids, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchVendor: (id) => dispatch(vendorsRestCrudThunksForSupplier.readOne(id)),
	fetchVendors: (params, targetCollectionName) =>
		dispatch(
			vendorsRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName
			)
		),
	fetchMultipleVendors: (ids, targetCollectionName) =>
		dispatch(vendorsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchLocations: (params, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForSupplier.read(params, targetCollectionName)),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchBrands: (params, targetCollectionName) =>
		dispatch(brandsRestCrudThunksForSupplier.read(params, targetCollectionName)),
	fetchMultipleBrands: (ids, targetCollectionName) =>
		dispatch(brandsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchPartCatalog: (params) => dispatch(partCatalogsRestCrudThunksForSupplier.read(params)),
	fetchEquipmentCatalog: (params) =>
		dispatch(equipmentCatalogsRestCrudThunksForSupplier.read(params)),
	deletePOLineItem: (entity) => dispatch(deletePOLineItemForSupplier(entity, entity.id)),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<PurchaseOrderFormProps>()(PurchaseOrderForm))
);

import { PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS } from '../actions/purchase_request_line_items_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import FetchUtils, { parseJSON } from './fetch_utils';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { logoutSuccess } from '../actions/session_actions';
import { message } from 'antd';

const fetchUtils = new FetchUtils();
const BASE_URL = getBackendUri();

//export const purchaseRequestsRestCrudThunksForBuyer = new RestCrudThunks('purchase_requests', 'purchase_request', 'purchaseRequest', 'purchaseRequests', PURCHASE_REQUESTS_CRUD_ACTION_CREATORS, null, ROLE_TYPES.BUYER);
export const purchaseRequestLineItemsRestCrudThunksForSupplier = new RestCrudThunks(
	'purchase_request_line_items',
	'purchase_request_line_item',
	'purchaseRequestLineItem',
	'purchaseRequestLineItems',
	PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'inventory/'
);

export const disassociatePurchaseRequestLineItemFromPurchaseOrderForSupplier = (ids) => {
	const apiUrl = `/api/v1/supplier/inventory/purchase_request_line_items/remove_po_line_item`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.patch(
					BASE_URL + apiUrl + `/${ids}`,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					})
				)
				.then(parseJSON)
				.then(({ json, status }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS.updateError(d.message, ids));
						reject(d.message);
					} else {
						dispatch(PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS.updateError(d.message, ids));
					reject(d.message);
				});
		});
	};
};

export const createWithApprovedStatus = (entity, targetCollectionName) => {
	const apiUrl = `/api/v1/supplier/inventory/purchase_request_line_items/add_with_approved_status`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS.createStart(entity));
			fetchUtils
				.post(
					BASE_URL + apiUrl,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
						data: entity,
					})
				)
				.then(parseJSON)
				.then(({ json, status }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(
							PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS.createError(d.message, entity)
						);
						reject(d.message);
					} else {
						dispatch(
							PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS.createSuccess(
								d.data,
								targetCollectionName
							)
						);
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS.createError(d.message, entity));
					reject(d.message);
				});
		});
	};
};

export const associatePurchaseRequestLineItemToPurchaseOrderForSupplier = (
	ids,
	purchaseOrderId
) => {
	const apiUrl = `/api/v1/supplier/inventory/purchase_request_line_items/associate_po_line_item`;
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			fetchUtils
				.patch(
					BASE_URL + apiUrl + `/${ids}/${purchaseOrderId}`,
					Object.assign({
						headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					})
				)
				.then(parseJSON)
				.then(({ json, status }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS.updateError(d.message, ids));
						reject(d.message);
					} else {
						dispatch(PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS.updateError(d.message, ids));
					reject(d.message);
				});
		});
	};
};

const downloadPRLineItemsCSV =
	(roleType) =>
	(params, filters = null) => {
		const baseUrl = getBackendUri();
		const apiUrl = `/api/v1/${roleType}/inventory/purchase_request_line_items/csv_report_generator`;

		// if sorting present, fix order naming and add to query params

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				fetchUtils
					.get(
						baseUrl + apiUrl,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							params
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (d.status === 'error') {
							reject(d.message);
							message.error(d.data);
						} else {
							resolve(d.data);
							const hide = message.loading(d.data, 0);
							setTimeout(hide, 7000);
						}
					})
					.catch((d) => {
						reject(d.message);
					});
			});
		};
	};

export const downloadPRLineItemsCSVForSupplier = downloadPRLineItemsCSV(ROLE_TYPES.SUPPLIER);

export const replaceLineItemInPR = (entity) => {
	const apiUrl = `/api/v1/supplier/inventory/purchase_request_line_items/replace_line_item`;
	return (dispatch) => {
		dispatch(PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS.updateStart(entity));
		return new Promise((resolve, reject) => {
			fetchUtils
				.put(getBackendUri() + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: entity,
				})
				.then(parseJSON)
				.then(({ json, status }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						reject(d.message);
						dispatch(
							PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS.updateError(d.message, entity)
						);
					} else {
						resolve(d.data);
						dispatch(PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS.updateSuccess(d.data));
					}
				})
				.catch((d) => {
					reject(d.message);
					dispatch(PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS.updateError(d.message, entity));
				});
		});
	};
};

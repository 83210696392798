import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Row } from 'antd';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { KeyValueDisplay } from '../key_value_display/KeyValueDisplay';
import MultiPhoneNumberComponent from '../MultiPhoneNumberComponent';
import {
	buyerContactsRestCrudThunksForBuyer,
	buyerContactsRestCrudThunksForSupplier,
} from '../../thunks/buyer_contacts_thunks';
import { connect } from 'react-redux';
import MultiEmailComponentAntdV4 from '../MultiEmailComponent/MultiEmailComponentAntdV4';
import { formatPhoneNumber } from '../../utils/DataFormatterUtils';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { withRouter } from 'react-router';

const LOCATION_FORM_BUYER_CONTACTS_AUTOCOMPLETE = 'LOCATION_FORM_BUYER_CONTACTS_AUTOCOMPLETE';

function BuyerSupplierInformationForm(props) {
	const [editable, setEditable] = useState(false);

	useEffect(() => {
		const { fetchBuyerContacts } = props;
		fetchBuyerContacts();
	}, []);

	const [form] = Form.useForm();

	const handleSubmit = (values) => {
		props.onSubmit(values);
		form.resetFields();
		setEditable(false);
	};

	const handleCancel = () => {
		form.resetFields();
		setEditable(false);
	};
	const { data, buyerContacts } = props;

	useEffect(() => {
		if (editable) {
			form.setFieldsValue(data);
		}
	}, [form, data, editable]);

	const detailsMap = {
		Alias: <>{nullSafeGet('supplierFacilityAlias', data)} </>,
		...(nullSafeGet('supplierPhoneNumbers', data)
			? {
					'Phone Numbers': data.supplierPhoneNumbers
						.map((item) => formatPhoneNumber(item))
						.join(', '),
			  }
			: {}),
		'Work order notifications sent to ': nullSafeGetOrElse('workOrdersRoutedTo', data, []).join(
			', '
		),
		'Proposal notifications sent to': nullSafeGetOrElse(
			'requestForProposalRoutedTo',
			data,
			[]
		).join(', '),
		'Vendor Code': nullSafeGetOrElse('vendorCode', data, '--'),
	};

	return (
		<>
			<Row justify="space-between">
				<h5 style={{ display: 'flex', alignItems: 'center' }}>Service Provider Contact Details</h5>
				<Button onClick={() => setEditable(!editable)}>{editable ? 'Cancel' : 'Edit'}</Button>{' '}
			</Row>
			<KeyValueDisplay keyWidth={120} keyValueStore={detailsMap} />
			<Modal
				width={600}
				okText="Update Supplier"
				onCancel={handleCancel}
				onOk={form.submit}
				closable={false}
				visible={editable}
				okButtonProps={{ loading: !editable }}
			>
				<Form
					form={form}
					initialValues={data}
					onFinish={handleSubmit}
					layout="vertical"
					labelAlign="left"
					preserve={false}
				>
					<h5>Update {nullSafeGet('supplierFacility.displayName', data)} Contact Details</h5>
					<Form.Item
						label="Alias"
						name="supplierFacilityAlias"
						rules={[
							{
								validator: (rule, value, callback) => {
									if (value.trim().length > 0) {
										callback();
									} else {
										callback('Please input a supplier alias.');
									}
								},
							},
						]}
					>
						<Input />
					</Form.Item>
					<MultiPhoneNumberComponent
						type={'phoneNumber'}
						fieldProps={{
							name: 'supplierPhoneNumbers',
						}}
					/>
					<MultiEmailComponentAntdV4
						fieldProps={{
							preserve: false,
							tooltip:
								'These supplier email addresses will be notified whenever a work order is created/updated.You can enter multiple emails seperated by comma',
							label: 'Work order notifications sent to',
							name: 'workOrdersRoutedTo',
						}}
					/>
					<MultiEmailComponentAntdV4
						fieldProps={{
							preserve: false,
							tooltip:
								'These supplier email addresses will be notified when you request for a proposal.You can enter multiple emails seperated by comma',
							label: 'Proposal notifications sent to',
							name: 'requestForProposalRoutedTo',
						}}
					/>
					<Form.Item label="Vendor Code" name="vendorCode">
						<Input />
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
}
const mapStateToProps = (state) => ({
	buyerContactsFetching: state.buyer_contacts.fetching,
	buyerContacts: state.buyer_contacts,
	userType: (state as any).session.userType,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchBuyerContacts: (params) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? buyerContactsRestCrudThunksForSupplier.read(
						params,
						LOCATION_FORM_BUYER_CONTACTS_AUTOCOMPLETE
				  )
				: buyerContactsRestCrudThunksForBuyer.read(
						params,
						LOCATION_FORM_BUYER_CONTACTS_AUTOCOMPLETE
				  )
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(BuyerSupplierInformationForm)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import { nullSafeGet, nullSafeGetOrElse } from '../../../../utils/DataAccessUtils';
import { SUPPLIER_TECH } from '../../../roles/roles';

export const ASSIGNED_FILTERS = (currentUser) => {
	const isInternalTech = nullSafeGet('facility.status', currentUser) === 'private';

	const showMineAsInitialFilter =
		isInternalTech &&
		nullSafeGetOrElse('roles', currentUser, []).some((_) => _ === SUPPLIER_TECH) &&
		nullSafeGetOrElse(
			'facility.privateBuyerCompanySettings.config.workOrder.showOnlyAssignedWorkOrdersInDefaultTechnicianView',
			currentUser,
			false
		);

	return {
		selector: 'assigned',
		items: [
			...(showMineAsInitialFilter
				? []
				: [
						{
							selectVal: undefined,
							label: 'All',
							value: {
								assigned: undefined,
								supplierFacilityId: undefined,
								fieldTechnicianEmail: undefined,
								notSupplierFacilityId: undefined,
							},
						},
				  ]),
			{
				selectVal: 'me',
				label: 'Mine',
				value: {
					assigned: 'me',
					supplierFacilityId: nullSafeGet('facility.id', currentUser),
					fieldTechnicianEmail: nullSafeGet('email', currentUser),
					notSupplierFacilityId: undefined,
				},
			},
			{
				selectVal: 'team',
				label: 'My Team',
				value: {
					assigned: 'team',
					supplierFacilityId: nullSafeGet('facility.id', currentUser),
					fieldTechnicianEmail: undefined,
					notSupplierFacilityId: undefined,
				},
			},
			...(isInternalTech
				? [
						{
							selectVal: 'serviceprovider',
							label: 'Service Provider',
							value: {
								assigned: 'serviceprovider',
								supplierFacilityId: undefined,
								fieldTechnicianEmail: undefined,
								notSupplierFacilityId: nullSafeGet('facility.id', currentUser),
							},
						},
				  ]
				: []),
		],
	};
};

import React, { FC, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button } from 'antd';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { EmptyState } from '../empty_state/EmptyState';
import { DEPRECIATION_CLASS_CRUD_ACTION_CREATORS } from '../../actions/depreciation_class_actions';
import { depreciationClassesRestCrudThunksForSupplier } from '../../thunks/depreciation_class_thunks';
import { nullSafeGetOrElse, stopDefaultEvents } from '../../utils/DataAccessUtils';
import DepreciationClassModal from './DepreciationClassModal';
import { DEFAULT_NAME_SORTER, FILTER_FIELD_TYPE } from '../../utils/DataConstants';
import DepreciationClassRowDisplay from './DepreciationClassRowDisplay';

const DC_TC_NAME = 'depreciationClassIndex';

const DepreciationClassIndexPage: FC<any> = ({
	depreciationClasses,
	updateFilters,
	clearAndUpdateFilters,
	fetchDepreciationClasses,
	history,
}): React.ReactElement => {
	const [newDCModalVisible, setNewDCModalVisible] = useState(false);
	const [currentEditingRecord, setCurrentEditingRecord] = useState(null);

	const onNewDepreciationClassClick = useCallback(() => setNewDCModalVisible(true), []);

	const hideDCModal = useCallback(() => {
		setNewDCModalVisible(false);
		setCurrentEditingRecord(null);
	}, []);

	const onEdit = useCallback(
		(record) => (e) => {
			stopDefaultEvents(e);
			setCurrentEditingRecord(record);
			setNewDCModalVisible(true);
		},
		[]
	);

	const getStatSliceProp = useCallback(
		(propName) => nullSafeGetOrElse(`${DC_TC_NAME}.${propName}`, depreciationClasses, {}),
		[depreciationClasses]
	);

	const refreshDepreciationClasses = useCallback(() => {
		fetchDepreciationClasses(
			{},
			DC_TC_NAME,
			getStatSliceProp('pagination'),
			getStatSliceProp('sorting'),
			getStatSliceProp('filters')
		);
	}, [fetchDepreciationClasses, getStatSliceProp]);

	const onCreateOrUpdateSuccess = useCallback(() => {
		hideDCModal();
		refreshDepreciationClasses();
	}, [hideDCModal, refreshDepreciationClasses]);

	const columns = useMemo(
		() => [
			{
				title: 'Name',
				dataIndex: 'name',
				render: (_, record) => <DepreciationClassRowDisplay record={record} />,
			},
			{
				title: '',
				dataIndex: '',
				render: (_, record) => (
					<div>
						<Button onClick={onEdit(record)} type="ghost">
							Edit
						</Button>
					</div>
				),
			},
		],
		[onEdit]
	);

	const onRow = useCallback(
		(record) => ({
			onClick: () => history.push(`/supplier/depreciation/depreciationClass/detail/${record.id}`),
		}),
		[history]
	);

	const filterConfig = useMemo(
		() => [
			{
				fieldName: 'depreciationMethod',
				type: FILTER_FIELD_TYPE.RADIO_GROUP,
				items: [
					{
						label: 'All',
						value: 'undefined',
					},
					{
						label: 'Declining Balance',
						value: 'declining',
					},
					{
						label: 'Straight Line',
						value: 'straightLine',
					},
				],
				defaultValue: undefined,
			},
		],
		[]
	);

	return (
		<>
			<PaginatedReduxTableWithHeader
				targetCollectionName={DC_TC_NAME}
				updateFilters={updateFilters}
				stateSlice={depreciationClasses}
				clearAndUpdateFilters={clearAndUpdateFilters}
				filterConfig={filterConfig}
				entityCollectionName="depreciation_classes"
				tableColumns={columns}
				fetchData={depreciationClassesRestCrudThunksForSupplier.read}
				initialSorters={DEFAULT_NAME_SORTER}
				onTableRow={onRow}
				rightActions={
					<div>
						<Button
							style={{ marginLeft: 16 }}
							type="primary"
							size="large"
							onClick={onNewDepreciationClassClick}
						>
							New Depreciation Class
						</Button>
					</div>
				}
				emptyState={
					<EmptyState
						graphic={
							<img
								alt="No Data"
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
							/>
						}
						headline={'No depreciation classes found'}
						body={
							<div style={{ textAlign: 'center' }}>
								<Button type="primary" onClick={onNewDepreciationClassClick}>
									Create Depreciation Class
								</Button>
							</div>
						}
					/>
				}
			/>
			{newDCModalVisible && (
				<DepreciationClassModal
					depreciationClass={currentEditingRecord}
					onSuccess={onCreateOrUpdateSuccess}
					onCancel={hideDCModal}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	depreciationClasses: state.depreciation_classes,
});

const mapDispatchToProps = (dispatch) => ({
	fetchDepreciationClasses: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			depreciationClassesRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	updateFilters: (filters, targetCollection) =>
		dispatch(DEPRECIATION_CLASS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			DEPRECIATION_CLASS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)
		),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(DepreciationClassIndexPage)
);

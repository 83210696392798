import { Card, Timeline } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
	ArrowRightOutlined,
	EditOutlined,
	PlusCircleOutlined,
	RightCircleOutlined,
} from '@ant-design/icons';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { UserName } from '../name_component/user/UserNameComponent';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import { assetAudits } from '../../thunks/assets_thunks';
import { Link } from 'react-router-dom';
import DateTimeHover from '../date_time_component/DateTime';

const fieldMap = {
	name: 'Name',
	isActive: 'Is Active',
	description: 'Description',
	manufacturer: 'Manufacturer',
	modelNumber: 'Model Number',
	serialNumber: 'Serial Number',
	assetNumber: 'Asset Number',
	locationId: 'Location',
	assetTypeId: 'Asset Type',
	assetModelId: 'Asset Model',
	installDate: 'Install Date',
	isLeased: 'Is Leased',
	purchaseDate: 'Purchase Date',
	lifeExpectancyInMonths: 'Life Expectancy In Months',
	originalPrice: 'Original Price',
	capitalisedCost: 'Capitalised Cost',
	leaseStartDate: 'Lease Start Date',
	leaseEndDate: 'Lease End Date',
	warrantyPeriod: 'Warranty Period',
	NTEAmount: 'NTE Amount',
	provider: 'Provider',
	assetTypeDetails: 'Asset Type Details',
	images: 'Images',
	assetUUID: 'Asset UUID',
	buyerFacilityId: 'Buyer Facility',
	warrantyId: 'Warranty',
	id: 'Id',
	downtimeStatus: 'Downtime Status',
};

function AssetAudit({ match, assetAudits, userType }) {
	const [data, setData] = useState([]);
	useEffect(() => {
		const assetId = match.params.id;
		assetAudits(userType, { assetId, offset: 0, limit: 10 }).then((res) => {
			setData(res);
		});
	}, []);

	const getDescription = (item) => {
		const getFieldsChange = (item) => {
			return (
				(item.fieldValueChanges && (
					<div className="mt-3 flex flex-wrap gap-2">
						{item.fieldValueChanges.map((field) =>
							field.fieldName === 'originalPrice' || field.fieldName === 'capitalisedCost' ? (
								<AccessPermissionChecker name={PERMISSION_NAMES.SHOW_ASSET_PRICE}>
									<span className="inline-block rounded-md bg-violet-100 p-1 text-sm text-gray-500">
										{fieldMap[field.fieldName] || field.fieldName} : {field.oldValue || `' '`}{' '}
										<ArrowRightOutlined translate="" /> {field.newValue}
									</span>
								</AccessPermissionChecker>
							) : (
								<span className="inline-block rounded-md bg-violet-100 p-1 text-sm text-gray-500">
									{fieldMap[field.fieldName] || field.fieldName} : {field.oldValue || `' '`}{' '}
									<ArrowRightOutlined translate="" /> {field.newValue}
								</span>
							)
						)}
					</div>
				)) ||
				null
			);
		};

		switch (item.auditType) {
			case 'asset_create':
				return 'Asset created';
			case 'manual_edit':
				return (
					<div>
						Edited by <UserName contact={item.createdByContact} mode={'inline'} placement={'top'} />
						{getFieldsChange(item)}
					</div>
				);
			case 'location_transfer':
				return (
					<div>
						Location transferred from{' '}
						<span className="inline-block rounded-md bg-violet-200 p-1 text-sm text-gray-600">
							{nullSafeGetOrElse('fromLocation.name', item, '')}
						</span>{' '}
						to{' '}
						<span className="inline-block rounded-md bg-violet-200 p-1 text-sm text-gray-600">
							{nullSafeGetOrElse('toLocation.name', item, '')}
						</span>{' '}
						by <UserName contact={item.createdByContact} mode={'inline'} placement={'top'} />
						{getFieldsChange(item)}
					</div>
				);
			case 'location_to_stock_location_transfer':
				return (
					<div>
						Transferred from{' '}
						<span className="inline-block rounded-md bg-violet-200 p-1 text-sm text-gray-600">
							{nullSafeGetOrElse('fromLocation.name', item, '')}
						</span>{' '}
						to stock location{' '}
						<span className="inline-block rounded-md bg-violet-200 p-1 text-sm text-gray-600">
							{nullSafeGetOrElse('transferredToStockLocation.name', item, '')}
						</span>{' '}
						by <UserName contact={item.createdByContact} mode={'inline'} placement={'top'} />
						{getFieldsChange(item)}
					</div>
				);
			case 'stock_location_transfer':
				return (
					<div>
						Stock location transferred from{' '}
						<span className="inline-block rounded-md bg-violet-200 p-1 text-sm text-gray-600">
							{nullSafeGetOrElse('transferredFromStockLocation.name', item, '')}
						</span>{' '}
						to{' '}
						<span className="inline-block rounded-md bg-violet-200 p-1 text-sm text-gray-600">
							{nullSafeGetOrElse('transferredToStockLocation.name', item, '')}
						</span>{' '}
						by <UserName contact={item.createdByContact} mode={'inline'} placement={'top'} />
						{getFieldsChange(item)}
					</div>
				);
			case 'stock_location_to_location_transfer':
				return (
					<div>
						Transferred from stock location{' '}
						<span className="inline-block rounded-md bg-violet-200 p-1 text-sm text-gray-600">
							{nullSafeGetOrElse('transferredFromStockLocation.name', item, '')}
						</span>{' '}
						to location{' '}
						<span className="inline-block rounded-md bg-violet-200 p-1 text-sm text-gray-600">
							{nullSafeGetOrElse('toLocation.name', item, '')}
						</span>{' '}
						by <UserName contact={item.createdByContact} mode={'inline'} placement={'top'} />
						{getFieldsChange(item)}
					</div>
				);
			case 'work_order_create':
				return (
					<div>
						Work Order created{' '}
						<Link
							to={`/${userType}/workOrders/detail/${nullSafeGetOrElse(
								'workOrder.id',
								item,
								''
							)}/details`}
						>
							<span className="inline-block rounded-md bg-violet-200 p-1 text-sm text-gray-600">
								{nullSafeGetOrElse('workOrder.title', item, '')}
							</span>{' '}
						</Link>
						by <UserName contact={item.createdByContact} mode={'inline'} placement={'top'} />
						{getFieldsChange(item)}
					</div>
				);
		}
	};
	const getIcon = (item) => {
		switch (item.auditType) {
			case 'asset_create':
				return <PlusCircleOutlined translate="" />;
			case 'manual_edit':
				return <EditOutlined translate="" />;
			default:
				return <RightCircleOutlined translate="" />;
		}
	};
	return (
		<div className="flex flex-col p-4">
			<Card>
				<div className="flex">
					<Timeline mode="left">
						{data.map((item) => (
							<Timeline.Item dot={getIcon(item)} key={item.id} className="mb-5">
								<div className="mb-5">
									<div className=" text-base ">{getDescription(item)}</div>
									<span className="mt-3 inline-block text-sm text-gray-500">
										<DateTimeHover timestamp={nullSafeGet('createdAt', item)} showDate={true} />
									</span>
								</div>
							</Timeline.Item>
						))}
					</Timeline>
				</div>
			</Card>
		</div>
	);
}

const mapStateToProps = (state) => ({
	asset: state.assets.detail,
	userType: state.session.userType,
});

const mapDispatchToProps = (dispatch) => ({
	assetAudits: (userType, params) => dispatch(assetAudits(userType)(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetAudit);

import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import FetchUtils, { parseJSON } from './fetch_utils';
import { RESOLUTION_TYPES_CRUD_ACTION_CREATORS } from '../actions/resolution_types_actions';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { INVOICES_CRUD_ACTION_CREATORS } from '../actions/invoices_actions';

export const resolutionTypesRestCrudThunksForBuyer = new RestCrudThunks(
	'resolution_types',
	'resolution_type',
	'resolutionTypes',
	'resolutionType',
	RESOLUTION_TYPES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'work_order/'
);
export const resolutionTypesRestCrudThunksForSupplier = new RestCrudThunks(
	'resolution_types',
	'resolution_type',
	'resolutionTypes',
	'resolutionType',
	RESOLUTION_TYPES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'work_order/'
);

const fetchUtils = new FetchUtils();
export const updateWorkOrderResolutionTypeForSupplier = (workOrderId, resolutionTypeId) => {
	const baseUrl = getBackendUri();
	const apiUrl = `/api/v1/supplier/work_order/work_orders/${workOrderId}/resolution_type/${resolutionTypeId}`;
	const entity = {
		resolutionTypeId: resolutionTypeId,
	};
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(RESOLUTION_TYPES_CRUD_ACTION_CREATORS.updateStart(entity));
			fetchUtils
				.put(baseUrl + apiUrl, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
					data: entity,
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (d.status === 'error') {
						dispatch(RESOLUTION_TYPES_CRUD_ACTION_CREATORS.updateError(d.message, entity));
						reject('An error occurred in updating resolution types.' || d.message);
					} else {
						dispatch(RESOLUTION_TYPES_CRUD_ACTION_CREATORS.updateSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(RESOLUTION_TYPES_CRUD_ACTION_CREATORS.updateError(d.message, entity));
					reject('An error occurred in updating resolution types.' || d.message);
				});
		});
	};
};

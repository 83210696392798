import * as React from 'react';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { ContactAvatar } from '../contact_avatar/ContactAvatar';
import { UserNotificationAvatar } from '../user_notification_avatar/UserNotificationAvatar';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { connect } from 'react-redux';
import {
	userNotificationsRestCrudThunksForBuyer,
	userNotificationsRestCrudThunksForSupplier,
} from '../../thunks/user_notifications_thunks';
import { withRouter } from 'react-router';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import NotificationTemplate from './templates';
import { Avatar, Divider } from 'antd';
import { getAvatarStyle } from '../../utils/ImageUtils';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';

const moment = require('moment');

const UserNotificationRowDisplay: React.FC<any> = ({
	history,
	notification,
	userType,
	updateUserNotificationForBuyer,
	updateUserNotificationForSupplier,
	style,
	showTemplate = false,
	onUpdate,
	location,
}) => {
	const handleUserNotificationClick = () => {
		const appDomainRegex =
			userType === ROLE_TYPES.BUYER
				? /https:\/\/app\.useopenwrench\.com(.+)/i
				: /https:\/\/partners\.useopenwrench\.com(.+)/i;

		const notificationCapture = nullSafeGet(
			`${userType}PushNotification.webAppUrl`,
			notification
		).match(appDomainRegex);
		const notificationUrl = notificationCapture[1];
		const updateUserNotification =
			userType === ROLE_TYPES.BUYER
				? updateUserNotificationForBuyer
				: updateUserNotificationForSupplier;
		const newNotification = {
			...notification,
			hasSeen: true,
		};
		updateUserNotification(newNotification).then(() => {
			onUpdate && onUpdate();
		});
		if (notificationUrl && notificationUrl.length > 0) {
			history.push(getLinkWIthBackLinkParams(location, 'Back to Inbox', notificationUrl));
		}
	};

	if (!notification) {
		return null;
	}

	const hasContact = nullSafeGetOrElse(
		`${userType}PushNotification.initiatedBuyerContact`,
		notification,
		nullSafeGet(`${userType}PushNotification.initiatedSupplierContact`, notification)
	);
	const getInitialsfromNotification = (str = '') => {
		const words = str.split(' ');
		if (words.length > 1) {
			return words[0].charAt(0) + words[1].charAt(0);
		} else {
			return words[0].charAt(0);
		}
	};
	return (
		<>
			<div
				onClick={handleUserNotificationClick}
				style={{
					...style,
					fontWeight: `${notification.hasSeen}` === 'false' ? 'bold' : '',
				}}
				className="flex cursor-pointer grid-cols-3 flex-row gap-x-6 gap-y-3  text-gray-600"
			>
				<div className="shrink-0">
					{hasContact ? (
						<ContactAvatar
							size={40}
							userType={userType}
							contact={nullSafeGetOrElse(
								`${userType}PushNotification.initiatedBuyerContact`,
								notification,
								nullSafeGet(`${userType}PushNotification.initiatedSupplierContact`, notification)
							)}
						/>
					) : (
						<Avatar
							size={40}
							style={getAvatarStyle(getInitialsfromNotification(notification.title))}
						>
							{getInitialsfromNotification(notification.title)}
						</Avatar>
					)}
				</div>
				<div className="flex-grow">
					<div className="flex  flex-row">
						<Ellipsis
							tooltip={true}
							fullWidthRecognition={true}
							lines={2}
							className="break-words text-lg"
							style={{ wordBreak: 'break-word' }}
						>
							{notification.title}
						</Ellipsis>
						{notification.hasSeen === false ? (
							<span className="Notification_badge ml-2  mt-2 mr-2 self-start" />
						) : null}
					</div>
					{showTemplate && <NotificationTemplate notification={notification} userType={userType} />}
				</div>
				<div className="mt-[4px] flex shrink-0 flex-row items-start">
					<UserNotificationAvatar userType={userType} notification={notification} />
					<svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
						<circle cx={1} cy={1} r={1} />
					</svg>
					<span className="leading-2 ml-2 inline-block text-sm text-gray-500">
						{moment(notification.datetime).fromNow()}
					</span>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
	updateUserNotificationForBuyer: (entity) =>
		dispatch(userNotificationsRestCrudThunksForBuyer.update(entity)),
	updateUserNotificationForSupplier: (entity) =>
		dispatch(userNotificationsRestCrudThunksForSupplier.update(entity)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserNotificationRowDisplay));

import React, { FC, useMemo } from 'react';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { Card } from 'antd';
import { TwoLineList } from '../two_line_list/TwoLineList';
import { connect } from 'react-redux';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { isInternalTech } from '../../utils/AuthUtils';
import DateTimeHover from '../date_time_component/DateTime';
import { checkAndFormatPhoneNumber } from '../../utils/DataFormatterUtils';

const LocationDetailsCard: FC<any> = ({ location, currentUser }): React.ReactElement => {
	const userType = useMemo(() => currentUser.userType, [currentUser.userType]);

	const isThirdPartySupplier = useMemo(
		() => userType === ROLE_TYPES.SUPPLIER && !isInternalTech(currentUser),
		[currentUser, userType]
	);

	const locDetails = useMemo(
		() => [
			...(location.phoneNumber
				? [{ key: 'Location Phone', value: checkAndFormatPhoneNumber(location.phoneNumber) }]
				: []),
			...(nullSafeGet('region', location)
				? [{ key: 'Region', value: nullSafeGet('name', location.region) }]
				: []),
			...(!isThirdPartySupplier && nullSafeGet('buyerFacility.currencyId', location)
				? [{ key: 'Currency', value: nullSafeGet('buyerFacility.currencyId', location) }]
				: []),
			...(!isThirdPartySupplier && nullSafeGet('isLeased', location)
				? [{ key: 'Lease Type', value: 'Leased' }]
				: []),
			...(!isThirdPartySupplier && nullSafeGet('leaseStartDate', location)
				? [
						{
							key: 'Lease Start Date',
							value: (
								<DateTimeHover
									timestamp={nullSafeGet('leaseStartDate', location)}
									showDate={true}
									showTime={false}
								/>
							),
						},
				  ]
				: []),
			...(!isThirdPartySupplier && nullSafeGet('leaseEndDate', location)
				? [
						{
							key: 'Lease End Date',
							value: (
								<DateTimeHover
									timestamp={nullSafeGet('leaseEndDate', location)}
									showDate={true}
									showTime={false}
								/>
							),
						},
				  ]
				: []),
			...(!isThirdPartySupplier && nullSafeGet('openDate', location)
				? [
						{
							key: 'Open Date',
							value: (
								<DateTimeHover
									timestamp={nullSafeGet('openDate', location)}
									showDate={true}
									showTime={false}
								/>
							),
						},
				  ]
				: []),
			...(!isThirdPartySupplier && nullSafeGet('closeDate', location)
				? [
						{
							key: 'Close Date',
							value: (
								<DateTimeHover
									timestamp={nullSafeGet('closeDate', location)}
									showDate={true}
									showTime={false}
								/>
							),
						},
				  ]
				: []),
			...nullSafeGetOrElse('locationTypeDetails', location, []),
		],
		[isThirdPartySupplier, location]
	);

	return locDetails.length > 0 ? (
		<div className="rowSpacing">
			<Card title="Location Details" className="materialCard" bodyStyle={{ padding: 0 }}>
				<div className="materialCard__body">
					<TwoLineList
						listItems={locDetails}
						lineOneRender={(record) => record.value || record.valueAsString || '--'}
						lineTwoRender={(record) => record.key}
					/>
				</div>
			</Card>
		</div>
	) : null;
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

export default connect(mapStateToProps)(LocationDetailsCard);

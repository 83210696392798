import React, { FC } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Col, Select } from 'antd';
import { dateFormatter } from '../../utils/DataFormatterUtils';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';

const BudgetCycleComponent: FC<any> = ({ history, budget }): React.ReactElement => {
	const budgetOptions = nullSafeGetOrElse('allCycles', budget, []).map((cycle) => (
		<Select.Option value={cycle.cycle}>
			{dateFormatter(cycle.startDate, 'MMM D, YYYY')} -{' '}
			{dateFormatter(cycle.endDate, 'MMM D, YYYY')}
		</Select.Option>
	));

	const onCycleChange = (value) => {
		history.push(`${history.location.pathname}?${new URLSearchParams({ cycle: value })}`);
	};

	return (
		<Col>
			<span style={{ fontSize: 20, marginRight: 12 }}>Period</span>
			<Select style={{ width: 220 }} value={budget.cycle} onSelect={onCycleChange}>
				{budgetOptions}
			</Select>
		</Col>
	);
};

const mapStateToProps = (state, ownProps) => ({
	budget: state.budgets.detail,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(BudgetCycleComponent)
);

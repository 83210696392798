import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import '@ant-design/compatible/assets/index.css';
import { Layout } from 'antd';
import PostSignUpSupplierForm from '../post_signup_supplier_form/PostSignUpSupplierForm';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;
const style = require('./SupplierNewAccountSetupPostSignUpPage.less');

class SupplierNewAccountSetupPostSignUpPage extends React.Component<any, any> {
	render() {
		const { currentUser } = this.props;
		return (
			<Content className="supplierNewAccountSetupPreferredSupplierPage">
				<LogOnMountWithStandardEventProperties eventType="visited supplier new account setup post signup page" />
				<PostSignUpSupplierForm formData={currentUser} />
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SupplierNewAccountSetupPostSignUpPage)
);

import * as React from 'react';
import { Chart, Geom, Axis, Tooltip, Legend } from 'bizcharts';
import { FC } from 'react';

interface StackedBarChartProps {
	cols: any;
	height: number;
	fillArea: boolean;
	position: string;
	data: any;
	chartPadding?: [number, number, number, number];
	xAxisLabel?: any;
	yAxisLabel?: any;
	color?: string;
	xAxisName: string;
	yAxisName: string;
	xAxisTitle?: string;
	yAxisTitle?: string;
	tooltipTemplate?: any;
	showTooltipTitle?: boolean;
}

export const StackedBarChart: FC<StackedBarChartProps> = ({
	chartPadding,
	showTooltipTitle,
	tooltipTemplate,
	cols,
	color,
	height,
	data,
	fillArea,
	xAxisName,
	xAxisTitle,
	yAxisName,
	xAxisLabel,
	yAxisLabel,
	yAxisTitle,
	position,
}) => {
	return (
		<div>
			<Chart height={height} padding="auto" data={data} scale={cols} forceFit={true}>
				<Legend />
				<Axis name="Month" />
				<Axis name="Work Orders by Priority" />
				<Tooltip showTitle={showTooltipTitle} />
				<Geom
					type="intervalStack"
					position={position}
					color={color}
					style={{
						stroke: '#fff',
						lineWidth: 1,
					}}
				/>
			</Chart>
		</div>
	);
};

import { NOTIFICATION_CLOSED, NOTIFICATION_SHOWN } from '../actions/one_time_notifications_actions';
export const DASHBOARD_GREETING_NOTIFICATION_NAME = 'dashboardWelcome';

const initialState: any = {
	[DASHBOARD_GREETING_NOTIFICATION_NAME]: {
		shown: false,
		closed: false,
	},
};

export default (oldState = initialState, action): any => {
	Object.freeze(oldState);
	switch (action.type) {
		case NOTIFICATION_SHOWN:
			return {
				...oldState,
				[action.notificationName]: {
					...oldState[action.notificationName],
					shown: true,
				},
			};
		case NOTIFICATION_CLOSED:
			return {
				...oldState,
				[action.notificationName]: {
					...oldState[action.notificationName],
					closed: true,
				},
			};
		default:
			return oldState;
	}
};

export const QUOTE_STATUSES = {
	pending: 'pending',
	expired: 'expired',
	approved: 'awarded',
	disputed: 'declined',
};

export const QUOTE_STATUS_DISPLAY_NAMES = {
	[QUOTE_STATUSES.pending]: 'Pending',
	[QUOTE_STATUSES.expired]: 'Expired',
	[QUOTE_STATUSES.disputed]: 'Declined',
	[QUOTE_STATUSES.approved]: 'Approved',
};

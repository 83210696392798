import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Button, Card } from 'antd';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { meterTypesRestCrudThunksForBuyer } from '../../thunks/meter_types_thunks';
import { EmptyState } from '../empty_state/EmptyState';
import SubnavBar from '../subnav_bar/SubnavBar';
import { AdvancedFilters } from '../advanced_filters/AdvancedFilters';
import { METER_TYPES_CRUD_ACTION_CREATORS } from '../../actions/meter_types_actions';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import queryString from 'querystring';
import { parseQueryParameters } from '../../utils/DataAccessUtils';

const { Content } = Layout;

const METER_TYPES_OVERVIEW_TARGET_COLLECTION = 'meterTypesIndex';

const style = require('./MeterTypesIndexPage.less');

class MeterTypesIndexPage extends React.Component<any, any> {
	initialPagination: any = { current: 1, pageSize: 5 };
	constructor(props) {
		super(props);
		const { location } = this.props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		const { current, pageSize, ...other } = queryParams;
		this.initialPagination = {
			...this.initialPagination,
			current: current ? current : this.initialPagination.current,
			pageSize: pageSize ? pageSize : this.initialPagination.pageSize,
		};
	}

	render() {
		const { history, clearAndUpdateFilters, meterTypes, location, updateMeterTypesFilters } =
			this.props;
		const onRow = (record) => ({
			onClick: () => history.push(`/buyer/meters/types/detail/${record.id}`),
		});
		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				defaultSortOrder: 'ascend',
				sorter: true,
				render: (text, record) => text,
			},
		];
		let initialFilters = parseQueryParameters(location.search);

		return (
			<Content className="metersIndexPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited meter type index page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<SubnavBar
							left={
								<div>
									<AdvancedFilters
										filterConfig={[]}
										filtersTargetCollectionName={METER_TYPES_OVERVIEW_TARGET_COLLECTION}
										clearAndUpdateFilters={clearAndUpdateFilters}
										filtersStateSlice={meterTypes}
										initialFilters={initialFilters}
										updateFilters={updateMeterTypesFilters}
										inputParamName={'name'}
									/>
								</div>
							}
							right={
								<Button
									type="primary"
									size="large"
									onClick={() => this.props.history.push('/buyer/meters/overview/types/new')}
								>
									Add Meter Type
								</Button>
							}
						/>
					</Col>
				</Row>
				<Row style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<Card bodyStyle={{ padding: 8 }}>
							<PaginatedReduxTable
								showHeader={false}
								updateQueryParams={true}
								emptyState={
									<EmptyState
										graphic={
											<img
												style={{ marginBottom: 8 }}
												src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
											/>
										}
										headline={"It's a little lonely in here."}
										body={
											<div style={{ textAlign: 'center' }}>
												<div style={{ maxWidth: 440, marginBottom: 16 }}>
													Looks like your team has not added any meter types yet.
												</div>
												<Button
													type="primary"
													onClick={() =>
														this.props.history.push('/buyer/meters/overview/types/new')
													}
												>
													Add your first meter type
												</Button>
											</div>
										}
									/>
								}
								onRow={onRow}
								collectionName="meter_types"
								targetCollectionName={METER_TYPES_OVERVIEW_TARGET_COLLECTION}
								columns={columns}
								keyAccessor={(el) => el.id}
								initialPagination={this.initialPagination}
								fetchData={meterTypesRestCrudThunksForBuyer.read}
							/>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	meterTypes: state.meter_types,
});

const mapDispatchToProps = (dispatch) => ({
	updateMeterTypesFilters: (filters, targetCollection) =>
		dispatch(METER_TYPES_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(METER_TYPES_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(MeterTypesIndexPage)
);

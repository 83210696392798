import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { Button, Table } from 'antd';
import BurdenRateModal from './BurdenRateModal';
import { Layout, Row, Col, Card } from 'antd';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { defaultFiscalEnd, getDefaultRate, getFiscalRangeForYear } from './burden_rate_utils';
import { DATE_ONLY_FORMAT } from '../../utils/DataFormatterUtils';
import FeildTechSLManagePermissionChecker from '../common/FeildTechSLManagePermissionChecker';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';

const { Content } = Layout;

const StockLocationBurdenRatesPage: FC<any> = ({
	stockLocationId,
	stockLocation,
	companyConfig,
	loading,
	fetchStockLocation,
}): React.ReactElement => {
	const [currentEditingRecord, setCurrentEditingRecord] = useState(null);
	const [editRateModalVisible, setEditRateModalVisible] = useState(false);

	useEffect(() => {
		stockLocationId && fetchStockLocation(stockLocationId);
	}, [fetchStockLocation, stockLocationId]);

	const fiscal = useMemo(
		() => nullSafeGetOrElse('detail.inventoryConfig.fiscalDate', companyConfig, defaultFiscalEnd),
		[companyConfig]
	);

	const burdenRates = useMemo(() => {
		const existingRates = nullSafeGetOrElse('burdenRates', stockLocation, []);
		const currentFiscalRate = getDefaultRate(fiscal);
		const cleanedRates = existingRates.map((record) => ({
			...getFiscalRangeForYear(record.year, fiscal),
			rate: record.rate,
		}));
		const allRates = !!cleanedRates.find((_) => _.year === currentFiscalRate.year)
			? cleanedRates
			: [...[currentFiscalRate], ...cleanedRates];
		return allRates.sort((a, b) => b.year - a.year);
	}, [fiscal, stockLocation]);

	const editYear = useCallback(
		(record) => () => {
			setCurrentEditingRecord(record);
			setEditRateModalVisible(true);
		},
		[]
	);

	const hideEditRateModal = useCallback(() => setEditRateModalVisible(false), []);

	const columns = useMemo(
		() => [
			{
				title: 'Period',
				render: (_, record) => {
					const { startDate, endDate } = record;
					return `${startDate.format(DATE_ONLY_FORMAT)} - ${endDate.format(DATE_ONLY_FORMAT)}`;
				},
			},
			{
				title: 'Burden Rate',
				dataIndex: 'rate',
				render: (rate) => `${rate || 0}%`,
			},
			{
				render: (_, record) => (
					<FeildTechSLManagePermissionChecker>
						<Button type="ghost" onClick={editYear(record)}>
							Edit
						</Button>
					</FeildTechSLManagePermissionChecker>
				),
			},
		],
		[editYear]
	);

	return (
		<Content style={{ padding: '0 0.5em', marginTop: '-8px' }}>
			<LogOnMountWithStandardEventProperties
				eventType={`Visited Stock locations burden rate page`}
			/>
			<Row style={{ margin: '0.5em -8px', marginTop: 16 }} gutter={16}>
				<Col span={24}>
					<Card bodyStyle={{ padding: 8, marginTop: 8 }}>
						<Table
							loading={loading}
							showHeader
							columns={columns}
							dataSource={burdenRates}
							pagination={{ current: 1, pageSize: 10 }}
						/>
						{editRateModalVisible && (
							<BurdenRateModal
								stockLocation={stockLocation}
								burdenRate={currentEditingRecord}
								onCancel={hideEditRateModal}
							/>
						)}
					</Card>
				</Col>
			</Row>
		</Content>
	);
};

const mapStateToProps = (state) => ({
	stockLocation: state.stock_locations.detail,
	loading: state.stock_locations.fetching,
	companyConfig: state.company_config,
});

const mapDispatchToProps = (dispatch) => ({
	fetchStockLocation: (id) => dispatch(stockLocationsRestCrudThunksForSupplier.readOne(id)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(StockLocationBurdenRatesPage)
);

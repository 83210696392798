import * as React from 'react';
import { Select } from 'antd';

interface SorterProps {
	items: any;
	value: any;
	onChange: any;
	style?: any;
}

const deduceValuefromConfig = (sortVal, items) => {
	const sort = items.find((el) => el.selectVal === sortVal);
	return sort.value;
};
const deduceConfigfromVal = (selectedValue, items) => {
	const sort = items.find(
		(el) => el.value.sort_by === selectedValue.sort_by && el.value.order === selectedValue.order
	);
	return sort && sort.selectVal;
};
export class Sorter extends React.Component<SorterProps, any> {
	render() {
		const { items, value, onChange, style } = this.props;

		return (
			<Select
				style={{
					...style,
					minWidth: '100px',
				}}
				value={deduceConfigfromVal(value, items)}
				onSelect={(sortVal) => {
					onChange(deduceValuefromConfig(sortVal, items));
				}}
				placeholder="Sort by"
			>
				{items.map((sort, i) => (
					<Select.Option key={i} value={sort.selectVal}>
						{sort.label}
					</Select.Option>
				))}
			</Select>
		);
	}
}

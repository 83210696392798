import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { ContactAvatar } from '../../contact_avatar/ContactAvatar';
import { Avatar, Col, Row } from 'antd';
import * as React from 'react';
import { nullSafeGet, nullSafeGetOrElse } from '../../../utils/DataAccessUtils';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { getProtectedImageUriForBuyer } from '../../../utils/FileAccessUtils';
import { FlexibleImageAvatar } from '../../flexible-image-avatar/FlexibleImageAvatar';
import { AddressDisplay } from '../../address_display/AddressDisplay';

interface SupplierFacilityCardProps {
	facility: any;
	name: any;
	subTitle?: any;
	size?: 'default' | 'large';
	mode?: 'full' | 'simple' | 'avatar';
	details?: any;
	showAvatar?: boolean;
}

const CITY_STATE_DISPLAY = {
	key: '',
	display: '',
	formatValue: (value) => (
		<>
			<AddressDisplay
				hidePostcode={true}
				hideCountry={true}
				hideStreetAddress={true}
				address={nullSafeGet('primaryAddress', value)}
				style={{
					color: 'rgba(0,0,0,0.45)',
				}}
			/>
		</>
	),
	lines: 2,
};

const FULL_ADDRESS_DISPLAY = {
	key: '',
	display: '',
	formatValue: (value) => (
		<>
			<AddressDisplay
				hidePostcode={false}
				hideCountry={false}
				hideStreetAddress={false}
				address={nullSafeGet('primaryAddress', value)}
				style={{
					color: 'rgba(0,0,0,0.45)',
				}}
			/>
		</>
	),
	lines: 2,
};

const STORE_ID_NAME_DISPLAY = {
	key: '',
	display: '',
	formatValue: (value) =>
		`${nullSafeGet('locationType.name', value)} ${nullSafeGet('storeId', value)}`,
};

export const SupplierFacilityCard: FC<SupplierFacilityCardProps> = ({
	facility,
	name,
	subTitle,
	size = 'default',
	mode = 'simple',
	details = [],
	showAvatar = true,
}) => {
	const onClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const detailList = details
		.filter((obj) => nullSafeGet(obj.key, facility) !== null)
		.map((obj) => ({
			...obj,
			value: obj.hasOwnProperty('formatValue')
				? obj.formatValue(obj.key ? nullSafeGet(obj.key, facility) : facility)
				: nullSafeGet(obj.key, facility),
		}));
	const logo = nullSafeGetOrElse(
		'logoURL',
		facility,
		'https://s3.amazonaws.com/emptor-data-assets/images/empty-state/icons8-city-filled-100.png'
	);

	return mode === 'avatar' ? (
		<FlexibleImageAvatar
			displayName={name}
			showPopover={false}
			popoverText={name}
			squareMode={true}
			width="40px"
			height="40px"
			imageUrl={logo}
		/>
	) : (
		<span
			onClick={onClick}
			style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}
		>
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<div style={{ marginRight: 12 }}>
					{showAvatar ? (
						<FlexibleImageAvatar
							displayName={name}
							showPopover={false}
							popoverText={name}
							squareMode={true}
							width="40px"
							height="40px"
							imageUrl={logo}
						/>
					) : null}
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
					<Row
						style={{
							justifyContent: 'flex-start',
							color: 'rgba(0,0,0,0.65)',
							fontSize: size === 'large' || !subTitle ? 17.5 : 14,
						}}
					>
						<Ellipsis fullWidthRecognition={true} lines={1}>
							{name}
						</Ellipsis>
					</Row>
					<Row
						style={{
							justifyContent: 'flex-start',
							color: 'rgba(0,0,0,0.45)',
							fontSize: size === 'large' ? 14 : 12,
						}}
					>
						<Ellipsis fullWidthRecognition={true} lines={1}>
							{subTitle}
						</Ellipsis>
					</Row>
				</div>
			</div>
			{mode === 'full' && detailList.length > 0 ? (
				<Row
					style={{
						gap: 8,
						borderTop: '1px solid #e8e8e8',
						width: '100%',
						marginTop: 4,
						paddingTop: 4,
					}}
				>
					<Col>
						{detailList.map((obj) => (
							<Row
								style={{
									color: 'rgba(0,0,0,0.45)',
									fontSize: size === 'large' ? 14 : 12,
									justifyContent: 'flex-end',
								}}
							>
								{obj.display}
							</Row>
						))}
					</Col>
					<Col>
						{detailList.map((obj) => (
							<Row
								style={{
									color: 'rgba(0,0,0,0.65)',
									fontSize: size === 'large' ? 14 : 12,
								}}
							>
								<Ellipsis fullWidthRecognition={true} lines={obj.lines || 1}>
									{obj.value}
								</Ellipsis>
							</Row>
						))}
					</Col>
				</Row>
			) : null}
		</span>
	);
};

import * as React from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Alert, Button } from 'antd';
import { connect } from 'react-redux';
import {
	buyerContactsRestCrudThunksForBuyer,
	buyerSignUpInvite,
} from '../../thunks/buyer_contacts_thunks';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { getProtectedImageUriForBuyer } from '../../utils/FileAccessUtils';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import BackButton from '../back_button/BackButton';
import { retrieveCachedUserDetails } from '../../thunks/session_thunks';
import { imageUploadValidation } from '../../utils/ImageUtils';
import PhoneNumberComponent from '../PhoneNumberComponent/PhoneNumberComponentAntdV3';
import OWUpload from '../OWUpload';

const style = require('./BuyerContactForm.less');
const FormItem = Form.Item;

interface BuyerContactFormProps extends FormComponentProps {
	creating: boolean;
	updating: boolean;
	minimal: boolean;
	sourcingEventId: number;
	formData: any;
	history: any;
	addTeamMember?: any;
	newAccountSetupMode: any;
	redirectForwardUrl?: string;
	inviteNewUser: any;
	addContactToEventBuyerTeamFormData: any;
	createAndAddToEventBuyerTeamFormData: any;
	createErrors: any[];
	updateErrors: any[];
	currentUser: any;
	create: any;
	update: any;
}

class BuyerContactForm extends React.Component<BuyerContactFormProps, any> {
	handleSubmit = (e, submitAction) => {
		const { history, newAccountSetupMode, redirectForwardUrl } = this.props;
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				let contact = {
					...values,
				};

				if (newAccountSetupMode) {
					submitAction(contact).then(() => {
						history.push('/buyer/newAccountSetup/completion');
					});
				} else {
					submitAction(contact).then(() => {
						if (redirectForwardUrl) {
							history.push(redirectForwardUrl);
						}
					});
				}
			}
		});
	};

	componentDidMount() {
		const { formData } = this.props;
		this.setState({ photoLink: formData.photoLink });
	}

	state = {
		uploadLoading: false,
		photoLink: null,
	};

	createAndAddToEventBuyerTeamFormData = (contact) => {
		const { inviteNewUser, addTeamMember, sourcingEventId, addContactToEventBuyerTeamFormData } =
			this.props;
		return inviteNewUser(contact).then((completeContact) => {
			addTeamMember(contact, 'watcher');
		});
	};

	handleUploadChange = (info) => {
		if (info.file.status === 'uploading') {
			this.setState({ uploadLoading: true });
			return;
		}
		if (info.file.status === 'done') {
			const fileName = nullSafeGetOrElse('file.response.data.fileName', info, '');
			const fileId = nullSafeGetOrElse('file.response.data.fileId', info, '');
			this.setState({
				uploadLoading: false,
				photoLink: `${fileId}/${fileName}`,
			});
			this.props.form.setFieldsValue({ photoLink: `${fileId}/${fileName}` });
		}
	};

	render() {
		const BACKEND_URI = getBackendUri();

		const {
			updating,
			sourcingEventId,
			inviteNewUser,
			creating,
			form,
			update,
			create,
			createErrors,
			formData,
			updateErrors,
			currentUser,
			minimal,
		} = this.props;
		const { photoLink } = this.state;
		const { getFieldDecorator, setFieldsValue } = form;
		const uploadHeaders = { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token };

		const MINIMAL_FORM_FIELDS_REQUIRED = ['email', 'nameGiven', 'nameFamily'];
		const FULL_FORM_FIELDS_REQUIRED = MINIMAL_FORM_FIELDS_REQUIRED.concat([
			'department',
			'title',
			'phoneWork',
			'phoneMobile',
		]);
		const minimalFieldsRequired = new Set(MINIMAL_FORM_FIELDS_REQUIRED);
		const fullFieldsRequired = new Set(FULL_FORM_FIELDS_REQUIRED);

		const fieldsRequired = minimal ? minimalFieldsRequired : fullFieldsRequired;

		const isUpdate = formData && formData.email !== undefined;
		let submitAction = null;
		let submitText = '';
		if (sourcingEventId) {
			submitAction = this.createAndAddToEventBuyerTeamFormData;
			submitText = 'Invite user';
		} else if (minimal && !sourcingEventId) {
			submitAction = inviteNewUser;
			submitText = 'Invite user';
		} else {
			submitAction = isUpdate ? update : create;
			submitText = isUpdate ? 'Update contact' : 'Create contact';
		}

		const isLoading = updating || creating;
		getFieldDecorator('photoLink', { initialValue: formData.photoLink });
		getFieldDecorator('buyerFacilityId', {
			initialValue:
				formData.buyerFacilityId ||
				nullSafeGet('facility.id', formData) ||
				nullSafeGet('facility.id', currentUser),
		});
		const uploadButton = (
			<div>
				<LegacyIcon type={this.state.uploadLoading ? 'loading' : 'plus'} />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		let imgFileId, imgFileName, photoUri;
		if (photoLink) {
			[imgFileId, imgFileName] = photoLink.split('/');
			photoUri = getProtectedImageUriForBuyer(imgFileId, imgFileName, 'auto', 240);
		}

		return (
			<Form
				layout="vertical"
				onSubmit={(e) => this.handleSubmit(e, submitAction)}
				className="supplierForm"
			>
				{createErrors.length > 0 ? (
					<FormItem>
						<Alert message={createErrors.join(' ')} type="error" />
					</FormItem>
				) : null}
				{updateErrors.length > 0 ? (
					<FormItem>
						<Alert message={updateErrors.join(' ')} type="error" />
					</FormItem>
				) : null}
				<FormItem label="Email">
					{getFieldDecorator('email', {
						initialValue: formData.email,
					})(<Input style={{ maxWidth: 300 }} disabled={isUpdate} />)}
				</FormItem>
				<FormItem label="Name" required={true}>
					{getFieldDecorator('nameGiven', {
						rules: [
							{
								required: fieldsRequired.has('nameGiven'),
								message: 'Please input name.',
							},
						],
						initialValue: formData.nameGiven,
					})(<Input placeholder="Given name" style={{ maxWidth: 200 }} />)}
					{getFieldDecorator('nameFamily', {
						rules: [
							{
								required: fieldsRequired.has('nameFamily'),
								message: 'Please input name.',
							},
						],
						initialValue: formData.nameFamily,
					})(<Input placeholder="Family name" style={{ maxWidth: 200, marginLeft: 8 }} />)}
					{getFieldDecorator('nameSuffix', {
						initialValue: formData.nameSuffix,
					})(<Input placeholder="Suffix" style={{ maxWidth: 65, marginLeft: 8 }} />)}
				</FormItem>
				{minimal ? null : (
					<div>
						<FormItem label="Department">
							{getFieldDecorator('department', {
								rules: [
									{
										required: fieldsRequired.has('department'),
										message: 'Please input department.',
									},
								],
								initialValue: formData.department,
							})(<Input placeholder="Department" style={{ maxWidth: 250 }} />)}
						</FormItem>
						<FormItem label="Title">
							{getFieldDecorator('title', {
								rules: [
									{
										required: fieldsRequired.has('title'),
										message: 'Please input title.',
									},
								],
								initialValue: formData.title,
							})(<Input placeholder="Job title" style={{ maxWidth: 250 }} />)}
						</FormItem>
						<PhoneNumberComponent
							form={form}
							label="Work Phone Number"
							fieldProps={{
								initialValue: formData.phoneWork,
								name: 'phoneWork',
							}}
							style={{ maxWidth: 200 }}
						/>
						<PhoneNumberComponent
							form={form}
							label="Mobile Phone Number"
							fieldProps={{
								initialValue: formData.phoneMobile,
								name: 'phoneMobile',
							}}
							style={{ maxWidth: 200 }}
						/>
						<FormItem label="Fax">
							{getFieldDecorator('fax', {
								initialValue: formData.fax,
							})(<Input style={{ maxWidth: 200 }} />)}
						</FormItem>
						<FormItem label="Profile photo">
							<OWUpload
								name="file"
								listType="picture-card"
								className="avatar-uploader"
								showUploadList={false}
								headers={uploadHeaders}
								action={`${BACKEND_URI}/api/v1/buyer/file/upload`}
								beforeUpload={imageUploadValidation}
								onChange={this.handleUploadChange}
							>
								{photoLink ? (
									<img src={photoUri} style={{ maxHeight: 104 }} alt="" />
								) : (
									uploadButton
								)}
							</OWUpload>
						</FormItem>
					</div>
				)}
				<FormItem>
					<Button
						type="primary"
						htmlType="submit"
						loading={isLoading}
						className="contactForm__button"
					>
						{submitText}
					</Button>
					<span style={{ marginLeft: '16px' }}>
						<BackButton buttonText="Cancel" />
					</span>
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	minimal: ownProps.minimal,
	sourcingEventId: ownProps.sourcingEventId,
	addTeamMember: ownProps.addTeamMember,

	formData: ownProps.formData,
	newAccountSetupMode: ownProps.newAccountSetupMode,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	createErrors: state.buyer_contacts.createErrors,
	updateErrors: state.buyer_contacts.updateErrors,
	creating: state.buyer_contacts.creating,
	updating: state.buyer_contacts.updating,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	update: (entity) => dispatch(buyerContactsRestCrudThunksForBuyer.update(entity)),
	create: (entity) => dispatch(buyerContactsRestCrudThunksForBuyer.create(entity)),
	inviteNewUser: (contact) => dispatch(buyerSignUpInvite(contact)),
});

export default withRouter<any, any>(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<BuyerContactFormProps>()(BuyerContactForm))
);

import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import '@ant-design/compatible/assets/index.css';
import {
	problemTypesHeirarchicalForBuyer,
	problemTypesHeirarchicalForSupplier,
} from '../../thunks/problem_types_thunks';
import {
	fetchSupplierFacilitiesInPrivateNetworkForBuyer,
	fetchSupplierFacilitiesInPrivateNetworkForSupplier,
} from '../../thunks/supplier_facilities_thunks';
import {
	workOrderPrioritiesRestCrudThunksForBuyer,
	workOrderPrioritiesRestCrudThunksForSupplier,
} from '../../thunks/work_order_priorities_thunks';

import { Row } from 'antd';
import {
	clearFiltersWorkOrders,
	downloadWorkOrderCSV,
	fetchWorkOrders,
	updateFiltersWorkOrders,
	updatePaginationWorkOrders,
	updateSortingWorkOrders,
	replaceFiltersAndSortingWorkOrders,
} from '../../thunks/work_orders_thunks_wrapper';
import {
	changeFilterValueToArrayOfIds,
	getEndDateFieldName,
	getEntitiesById,
	getStartDateFieldName,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import TableHeader from './TableHeader';
import WOTable from './WOTable';
import TableFooter from './TableFooter';
import OWAsyncTreeSelect from '../ow_async_tree_select/OWAsyncTreeSelect';
import ProblemTypeTreeSelect from '../problem_type_tree_select';
import OWRadioGroup from '../ow_radio_group/OWRadioGroup';
import { FILTER_CONFIG_NAMES, FILTER_FIELD_TYPE, ROLE_TYPES } from '../../utils/DataConstants';
import { fieldTechsRestCrudThunksForSupplier } from '../../thunks/field_techs_thunks';
import { spendCategoriesRestCrudThunksForSupplier } from '../../thunks/spend_categories_thunks';
import { standardInitialStateGenerator } from '../../reducers/standard_reducer_utils';
import OWDateRangePicker from '../ow_date_range_picker/OWDateRangePicker';
import moment from 'moment';
import { formatDateForParam } from '../../utils/DataFormatterUtils';
import {
	assetTypesRestCrudThunksForBuyer,
	assetTypesRestCrudThunksForSupplier,
} from '../../thunks/asset_types_thunks';
import {
	resolutionTypesRestCrudThunksForBuyer,
	resolutionTypesRestCrudThunksForSupplier,
} from '../../thunks/resolution_types_thunks';
import {
	plannedMaintenanceSchedulesRestCrudThunksForBuyer,
	plannedMaintenanceSchedulesRestCrudThunksForSupplier,
} from '../../thunks/planned_maintenance_schedules_thunks';

const TARGETCOLLECTIONNAME = 'workOrdersTable';

function WorkOrdersTable({
	data,
	error,
	openMoreInDefault,
	updatePagination,
	workOrders,
	workOrderSlice,
	fetchData,
	location,
	updateSorting,
	updateFilters,
	replaceFiltersAndSorting,
	clearFilters,
	companyConfig,
	currentUser,
	downloadWorkOrderCSV,
	CustomFooter,
	CustomHeader,
	CustomWorkOrderRowDisplay,
	tableStyle,
	history,
	renderEmptyState,
	fetchLocations,
	fetchWorkOrderPriorities,
	fetchProblemTypes,
	preAppliedFilters,
	fetchSupplierFacilitiesInPrivateNetwork,
	fetchFieldTechs,
	fetchMultipleFieldTechs,
	filterConfig,
	locations,
	fieldTechs,
	workOrderPriorities,
	problemTypes,
	supplierFacilities,
	userType,
	targetCollectionName,
	spendCategories,
	fetchSpendCategories,
	fetchMultipleSpendCategories,
	backText,
	assetTypes,
	fetchAssetTypes,
	fetchMultipleAssetTypes,
	resolutionTypes,
	fetchResolutionTypes,
	fetchMultipleResolutionTypes,
	fetchPMSchedule,
	fetchMultiplePMSchedule,
	planned_maintenance_schedules,
	hideExportButton,
}) {
	const {
		fetching,
		filters: activeFilters,
		pagination,
		sorting: activeSorters,
	} = workOrders || standardInitialStateGenerator([targetCollectionName]);

	React.useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const preserve = queryParams.has('preserve');

		if (!preserve || !(data && data.length)) {
			fetchData(location);
		}
	}, []);

	const staleDays = useMemo(
		() => nullSafeGetOrElse('config.workOrder.stale.afterXDays', companyConfig, 14),
		[companyConfig]
	);

	const COMMON_FILTER_CONFIGS = useMemo(
		() => [
			{
				label: 'Location',
				fieldName: 'locationIds',
				mode: 'multiple',
				stateSlice: locations,
				hideFilter: true,
				filtersValueAccessor: (activeFilters) => {
					const locationIds = nullSafeGet(`locationIds`, activeFilters);
					const finalIds = nullSafeGetOrElse(`regionIds`, activeFilters, locationIds);
					return changeFilterValueToArrayOfIds(finalIds);
				},
				keyAccessor: (loc) => loc.id,
				valueAccessor: (loc) => loc.id,
				handleChange: (ids) => {
					updateFilters({
						locationIds: ids.join(',') || undefined,
						regionIds: undefined,
					});
				},
				targetCollectionName: 'workOrdersAdvancedFilters',
				fetch: fetchLocations, //This value is undefined, why have it?
				fetchMultiple: () => {},
				renderItem: (loc) => loc.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
			},
			{
				label: 'Priority',
				fieldName: 'woPriorityIds',
				mode: 'multiple',
				stateSlice: workOrderPriorities,
				filtersValueAccessor: (filtersStateSlice) => {
					const woPriorityIds = nullSafeGet(`woPriorityIds`, filtersStateSlice);
					return changeFilterValueToArrayOfIds(woPriorityIds);
				},
				keyAccessor: (workOrderPriority) => workOrderPriority.id,
				valueAccessor: (workOrderPriority) => workOrderPriority.id,
				handleChange: (ids) => {
					updateFilters({
						woPriorityIds: ids.join(',') || undefined,
					});
				},
				targetCollectionName: 'workOrdersAdvancedFilters',
				fetch: fetchWorkOrderPriorities,
				fetchMultiple: () => {},
				renderItem: (workOrderPriority) => workOrderPriority.name,
				component: OWAsyncTreeSelect,
			},
			{
				label: 'Asset Type',
				fieldName: FILTER_CONFIG_NAMES.ASSET_TYPE,
				mode: 'multiple',
				stateSlice: assetTypes,
				filtersValueAccessor: (filtersStateSlice) => {
					const assetTypeIds = nullSafeGet(`assetTypeIds`, filtersStateSlice);
					return changeFilterValueToArrayOfIds(assetTypeIds);
				},
				keyAccessor: (assetType) => assetType.id,
				valueAccessor: (assetType) => assetType.id,
				handleChange: (ids) => {
					updateFilters({
						assetTypeIds: ids.join(',') || undefined,
					});
				},
				targetCollectionName: 'workOrdersAdvancedFilters',
				fetch: fetchAssetTypes,
				fetchMultiple: fetchMultipleAssetTypes,
				renderItem: (assetType) => assetType.name,
				component: OWAsyncTreeSelect,
			},
			{
				label: 'PM Schedule',
				fieldName: FILTER_CONFIG_NAMES.PM_SCHEDULE,
				stateSlice: planned_maintenance_schedules,
				filtersValueAccessor: (filtersStateSlice) => {
					const id = nullSafeGet(FILTER_CONFIG_NAMES.PM_SCHEDULE, filtersStateSlice);
					return id;
				},
				hideFilter: true,
				keyAccessor: (item) => item.id,
				valueAccessor: (item) => `${item.id}`,
				handleChange: (id) => {
					updateFilters({
						[FILTER_CONFIG_NAMES.PM_SCHEDULE]: id || undefined,
					});
				},
				targetCollectionName: 'workOrdersAdvancedFilters',
				fetch: fetchPMSchedule,
				fetchMultiple: fetchMultiplePMSchedule,
				renderItem: (item) => item.title,
			},
			{
				label: 'Problem Type',
				mode: 'multiple',
				fieldName: 'problemTypeIds',
				stateSlice: problemTypes,
				filtersValueAccessor: (filtersStateSlice) => {
					const problemTypeIds = nullSafeGet(`problemTypeIds`, filtersStateSlice);
					return changeFilterValueToArrayOfIds(problemTypeIds);
				},
				keyAccessor: (problemType) => problemType.id,
				valueAccessor: (problemType) => problemType.id,
				handleChange: (ids) => {
					updateFilters({
						problemTypeIds: ids.join(',') || undefined,
					});
				},
				targetCollectionName: 'workOrdersAdvancedFilters',
				fetch: fetchProblemTypes,
				fetchMultiple: () => {},
				renderItem: (problemType) => problemType.hierarchyName,
				sortBy: { sort_by: 'name', order: 'ascend' },
				component: ProblemTypeTreeSelect,
			},
			{
				label: 'Supplier',
				fieldName: 'supplierFacilityIds',
				mode: 'multiple',
				stateSlice: supplierFacilities,
				filtersValueAccessor: (filtersStateSlice) =>
					changeFilterValueToArrayOfIds(nullSafeGet(`supplierFacilityIds`, filtersStateSlice)),
				keyAccessor: (supplierFacility) => supplierFacility.id,
				valueAccessor: (supplierFacility) => supplierFacility.id,
				handleChange: (ids) => {
					updateFilters({
						supplierFacilityIds: ids.join(',') || undefined,
					});
				},
				targetCollectionName: 'workOrdersAdvancedFilters',
				fetch: fetchSupplierFacilitiesInPrivateNetwork,
				fetchMultiple: () => {},
				renderItem: (supplierFacility) => supplierFacility.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
				component: OWAsyncTreeSelect,
			},
			{
				label: 'Resolution Type',
				fieldName: FILTER_CONFIG_NAMES.RESOULTION_TYPE,
				mode: 'multiple',
				stateSlice: resolutionTypes,
				filtersValueAccessor: (filtersStateSlice) =>
					changeFilterValueToArrayOfIds(
						nullSafeGet(FILTER_CONFIG_NAMES.RESOULTION_TYPE, filtersStateSlice)
					),
				keyAccessor: (rt) => rt.id,
				valueAccessor: (rt) => rt.id,
				handleChange: (ids) => {
					updateFilters({
						[FILTER_CONFIG_NAMES.RESOULTION_TYPE]: ids.join(',') || undefined,
					});
				},
				targetCollectionName: 'workOrdersAdvancedFilters',
				fetch: fetchResolutionTypes,
				fetchMultiple: fetchMultipleResolutionTypes,
				renderRecord: (rt) => (
					<div className="flex flex-row items-center">
						{rt.resolutionCode ? `${rt.resolutionCode} - ` : null}
						{rt.resolutionType}
					</div>
				),
				labelAccessor: (rt) => (
					<div className="flex flex-row items-center">
						{rt.resolutionCode ? `${rt.resolutionCode} - ` : null}
						{rt.resolutionType}
					</div>
				),
				sortBy: { sort_by: 'name', order: 'ascend' },
				component: OWAsyncTreeSelect,
			},
			{
				label: 'Created Between',
				labelPrefix: 'Created',
				fieldName: FILTER_CONFIG_NAMES.CREATED_AT,
				type: FILTER_FIELD_TYPE.DATE_RANGE,
				filtersValueAccessor: (filtersStateSlice) => {
					const startDate =
						filtersStateSlice[getStartDateFieldName(FILTER_CONFIG_NAMES.CREATED_AT)];
					const endDate = filtersStateSlice[getEndDateFieldName(FILTER_CONFIG_NAMES.CREATED_AT)];
					return [startDate && moment(startDate), endDate && moment(endDate)];
				},
				handleChange: (values) => {
					updateFilters({
						[getStartDateFieldName(FILTER_CONFIG_NAMES.CREATED_AT)]:
							values && values.length > 1 && values[0] ? formatDateForParam(values[0]) : undefined,
						[getEndDateFieldName(FILTER_CONFIG_NAMES.CREATED_AT)]:
							values && values.length > 1 && values[1] ? formatDateForParam(values[1]) : undefined,
					});
				},
				renderRecord: (item) => item,
				valueAccessor: (item) => item,
				component: OWDateRangePicker,
			},
			{
				label: 'Completed Between',
				labelPrefix: 'Completed',
				fieldName: FILTER_CONFIG_NAMES.COMPLETED_AT,
				type: FILTER_FIELD_TYPE.DATE_RANGE,
				filtersValueAccessor: (filtersStateSlice) => {
					const startDate =
						filtersStateSlice[getStartDateFieldName(FILTER_CONFIG_NAMES.COMPLETED_AT)];
					const endDate = filtersStateSlice[getEndDateFieldName(FILTER_CONFIG_NAMES.COMPLETED_AT)];
					return [startDate && moment(startDate), endDate && moment(endDate)];
				},
				handleChange: (values) => {
					updateFilters({
						[getStartDateFieldName(FILTER_CONFIG_NAMES.COMPLETED_AT)]:
							values && values.length > 1 && values[0] ? formatDateForParam(values[0]) : undefined,
						[getEndDateFieldName(FILTER_CONFIG_NAMES.COMPLETED_AT)]:
							values && values.length > 1 && values[1] ? formatDateForParam(values[1]) : undefined,
					});
				},
				renderRecord: (item) => item,
				valueAccessor: (item) => item,
				component: OWDateRangePicker,
			},
			{
				fieldName: 'isStale',
				type: FILTER_FIELD_TYPE.RADIO_GROUP,
				items: [
					{
						label: 'All',
						value: undefined,
					},
					{
						label: 'Fresh',
						value: 'false',
					},
					{
						label: 'Stale',
						value: 'true',
					},
				],
				filtersValueAccessor: (filtersStateSlice) => {
					const val = filtersStateSlice.isStale;
					return typeof val === 'undefined' ? undefined : `${val}`;
				},
				keyAccessor: (item) => item.value,
				valueAccessor: (item) => item.value,
				handleChange: (isStale) => {
					const val = isStale ? isStale === 'true' : undefined;
					updateFilters({ isStale: val });
				},
				targetCollectionName: 'workOrdersAdvancedFilters',
				renderRecord: (item) => item.label,
				component: OWRadioGroup,
				info: [
					{
						title: 'Fresh',
						description: `Work orders less than ${staleDays} days old.`,
					},
					{
						title: 'Stale',
						description: `Work orders older than ${staleDays} days.`,
					},
				],
			},
			{
				fieldName: 'isPM',
				type: 'radioGroup',
				items: [
					{
						label: 'All',
						value: undefined,
					},
					{
						label: 'Planned',
						value: 'true',
					},
					{
						label: 'Reactive',
						value: 'false',
					},
				],
				filtersValueAccessor: (filtersStateSlice) => {
					const val = filtersStateSlice.isPM;
					return typeof val === 'undefined' ? undefined : `${val}`;
				},
				keyAccessor: (item) => item.value,
				valueAccessor: (item) => item.value,
				handleChange: (isPM) => {
					const val = isPM ? isPM === 'true' : undefined;
					updateFilters({ isPM: val });
				},
				targetCollectionName: 'workOrdersAdvancedFilters',
				renderRecord: (item) => item.label,
				component: OWRadioGroup,
				info: [
					{
						title: 'Planned',
						description: `Scheduled maintenance work generated based on planned maintenance system.`,
					},
					{
						title: 'Reactive',
						description: `On demand maintenance work requested by your team or suggested by a service provider.`,
					},
				],
			},
			{
				fieldName: 'isWarranty',
				type: 'radioGroup',
				items: [
					{
						label: 'All',
						value: 'undefined',
					},
					{
						label: 'Under Warranty',
						value: 'true',
					},
				],
				filtersValueAccessor: (filtersStateSlice) => {
					return typeof filtersStateSlice.isWarranty === 'undefined'
						? 'undefined'
						: `${filtersStateSlice.isWarranty}`;
				},
				defaultValue: undefined,
				keyAccessor: (item) => item.value,
				valueAccessor: (item) => item.value,
				handleChange: (isWarranty) => {
					const val = isWarranty === 'undefined' ? undefined : true;
					updateFilters({ isWarranty: val });
				},
				targetCollectionName: 'workOrdersAdvancedFilters',
				renderRecord: (item) => item.label,
				component: OWRadioGroup,
				info: [
					{
						title: 'Under Warranty',
						description: `Work Orders for assets that are under warranty`,
					},
				],
			},
			{
				fieldName: 'pastDue',
				type: 'radioGroup',
				items: [
					{
						label: 'All',
						value: 'undefined',
					},
					{
						label: 'Past Due',
						value: 'true',
					},
				],
				filtersValueAccessor: (filtersStateSlice) => {
					return typeof filtersStateSlice.pastDue === 'undefined'
						? 'undefined'
						: `${filtersStateSlice.pastDue}`;
				},
				defaultValue: undefined,
				keyAccessor: (item) => item.value,
				valueAccessor: (item) => item.value,
				handleChange: (pastDue) => {
					const val = pastDue === 'undefined' ? undefined : true;
					updateFilters({ pastDue: val });
				},
				targetCollectionName: 'workOrdersAdvancedFilters',
				renderRecord: (item) => item.label,
				component: OWRadioGroup,
				info: [
					{
						title: 'Past Due',
						description: `Work Orders which are past their due date`,
					},
				],
			},
			{
				fieldName: 'pastSchedule',
				type: 'radioGroup',
				items: [
					{
						label: 'All',
						value: 'undefined',
					},
					{
						label: 'Past Schedule',
						value: 'true',
					},
				],
				filtersValueAccessor: (filtersStateSlice) => {
					return typeof filtersStateSlice.pastSchedule === 'undefined'
						? 'undefined'
						: `${filtersStateSlice.pastSchedule}`;
				},
				defaultValue: undefined,
				keyAccessor: (item) => item.value,
				valueAccessor: (item) => item.value,
				handleChange: (pastSchedule) => {
					const val = pastSchedule === 'undefined' ? undefined : true;
					updateFilters({ pastSchedule: val });
				},
				targetCollectionName: 'workOrdersAdvancedFilters',
				renderRecord: (item) => item.label,
				component: OWRadioGroup,
				info: [
					{
						title: 'Past Schedule',
						description: `Work Orders which are past their schedule`,
					},
				],
			},
			{
				fieldName: FILTER_CONFIG_NAMES.IS_PAST_ESTIMATED_COMPLETION,
				type: 'radioGroup',
				items: [
					{
						label: 'All',
						value: 'undefined',
					},
					{
						label: 'Past Estimated Completion',
						value: 'true',
					},
				],
				filtersValueAccessor: (filtersStateSlice) => {
					return typeof filtersStateSlice.pastEstimatedCompletion === 'undefined'
						? 'undefined'
						: `${filtersStateSlice.pastEstimatedCompletion}`;
				},
				defaultValue: undefined,
				keyAccessor: (item) => item.value,
				valueAccessor: (item) => item.value,
				handleChange: (pastEstimatedCompletion) => {
					const val = pastEstimatedCompletion === 'undefined' ? undefined : true;
					updateFilters({ pastEstimatedCompletion: val });
				},
				targetCollectionName: 'workOrdersAdvancedFilters',
				renderRecord: (item) => item.label,
				component: OWRadioGroup,
				info: [
					{
						title: 'Past Estimated Completion',
						description: `Work Orders which are past their estimated completion date`,
					},
				],
			},
			{
				fieldName: FILTER_CONFIG_NAMES.IS_ASSET_DOWN,
				type: 'radioGroup',
				items: [
					{
						label: 'All',
						value: undefined,
					},
					{
						label: 'Asset Reported Offline',
						value: 'true',
					},
				],
				filtersValueAccessor: (filtersStateSlice) =>
					typeof filtersStateSlice.isAssetDown === 'undefined'
						? undefined
						: `${filtersStateSlice.isAssetDown}`,
				keyAccessor: (item) => item.value,
				valueAccessor: (item) => item.value,
				handleChange: (isAssetDown) =>
					updateFilters({
						isAssetDown: isAssetDown ? isAssetDown === 'true' : undefined,
					}),
				targetCollectionName: 'workOrdersAdvancedFilters',
				renderRecord: (item) => item.label,
				component: OWRadioGroup,
				info: [
					{
						title: 'Asset Reported as Offline',
						description: 'Asset was reported offline during work order creation.',
					},
				],
			},
		],
		[
			locations,
			fetchLocations,
			workOrderPriorities,
			fetchWorkOrderPriorities,
			assetTypes,
			fetchAssetTypes,
			fetchMultipleAssetTypes,
			problemTypes,
			fetchProblemTypes,
			supplierFacilities,
			fetchSupplierFacilitiesInPrivateNetwork,
			resolutionTypes,
			fetchResolutionTypes,
			fetchMultipleResolutionTypes,
			staleDays,
			updateFilters,
			planned_maintenance_schedules,
			fetchPMSchedule,
			fetchMultiplePMSchedule,
		]
	);

	const BUYER_FILTER_CONFIGS = useMemo(
		() => [
			{
				fieldName: 'isCapex',
				type: 'radioGroup',
				items: [
					{
						label: 'All',
						value: undefined,
					},
					{
						label: 'R&M',
						value: 'false',
					},
					{
						label: 'CapEx',
						value: 'true',
					},
				],
				filtersValueAccessor: (filtersStateSlice) => {
					const val = filtersStateSlice.isCapex;
					return typeof val === 'undefined' ? undefined : `${val}`;
				},
				keyAccessor: (item) => item.value,
				valueAccessor: (item) => item.value,
				handleChange: (isCapex) => {
					const val = isCapex ? isCapex === 'true' : undefined;
					updateFilters({ isCapex: val });
				},
				targetCollectionName: 'workOrdersAdvancedFilters',
				renderRecord: (item) => item.label,
				component: OWRadioGroup,
				info: [
					{
						title: 'Repair and Maintenance (R&M)',
						description: 'Costs associated with repair and maintenance of existing assets',
					},
					{
						title: 'Capital Expenditures (CapEx)',
						description: 'Costs associated with buying new assets or upgrading existing assets',
					},
				],
			},
		],
		[updateFilters]
	);

	const SUPPLIER_FILTER_CONFIG = useMemo(
		() => [
			{
				label: 'Technician',
				fieldName: 'fieldTechnicianEmail',
				stateSlice: fieldTechs,
				filtersValueAccessor: (filtersStateSlice) =>
					nullSafeGet(`fieldTechnicianEmail`, filtersStateSlice),
				keyAccessor: (fieldTech) => fieldTech.email,
				valueAccessor: (fieldTech) => fieldTech.email,
				handleChange: (technicianEmail) => {
					updateFilters({ fieldTechnicianEmail: technicianEmail });
				},
				targetCollectionName: 'workOrdersAdvancedFilters',
				fetch: fetchFieldTechs,
				fetchMultiple: fetchMultipleFieldTechs,
				renderItem: (fieldTech) => fieldTech.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
			},
			{
				label: 'Trade',
				fieldName: 'spendCategoryIds',
				mode: 'multiple',
				stateSlice: spendCategories,
				filtersValueAccessor: (filtersStateSlice) => {
					const ids = nullSafeGet(`spendCategoryIds`, filtersStateSlice);
					return changeFilterValueToArrayOfIds(ids);
				},
				keyAccessor: (spendCategory) => spendCategory.id,
				valueAccessor: (spendCategory) => spendCategory.id,
				handleChange: (ids) => updateFilters({ spendCategoryIds: ids.join(',') || undefined }),
				targetCollectionName: 'workOrdersAdvancedFilters',
				fetch: fetchSpendCategories,
				fetchMultiple: fetchMultipleSpendCategories,
				renderItem: (spendCategory) => spendCategory.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
				component: OWAsyncTreeSelect,
			},
		],
		[
			fieldTechs,
			updateFilters,
			fetchFieldTechs,
			fetchMultipleFieldTechs,
			spendCategories,
			fetchSpendCategories,
			fetchMultipleSpendCategories,
		]
	);

	const FILTER_CONFIG = useMemo(() => {
		const defaultConfig: any[] =
			userType === ROLE_TYPES.SUPPLIER
				? [...COMMON_FILTER_CONFIGS, ...SUPPLIER_FILTER_CONFIG]
				: [...COMMON_FILTER_CONFIGS, ...BUYER_FILTER_CONFIGS];
		const advancedFilterConfig =
			filterConfig && filterConfig.filters && filterConfig.filters.length > 0
				? filterConfig.filters.map((filter) => defaultConfig.find((_) => _.fieldName === filter))
				: defaultConfig;
		return {
			...filterConfig,
			filters: advancedFilterConfig.filter((_) => !!_),
		};
	}, [userType, filterConfig, COMMON_FILTER_CONFIGS, BUYER_FILTER_CONFIGS, SUPPLIER_FILTER_CONFIG]);

	return (
		<div className="TableHoc">
			{CustomHeader ? (
				<CustomHeader
					applySorting={updateSorting}
					applyFilters={(e) => updateFilters(e)}
					filters={activeFilters}
					sorters={activeSorters}
					currentUser={currentUser}
					isDisplayModeActive={(e) => e === 'map'}
					downloadCSV={hideExportButton ? null : () => downloadWorkOrderCSV({}, activeFilters)}
					counts={nullSafeGetOrElse(`${targetCollectionName}.counts`, workOrderSlice, {})}
					clearFilters={clearFilters}
					preAppliedFilters={preAppliedFilters}
					filterConfig={FILTER_CONFIG}
					targetCollectionName={targetCollectionName}
					userType={userType}
					pagination={pagination}
					fetching={fetching}
					refreshTable={fetchData}
				/>
			) : (
				<TableHeader
					applySorting={updateSorting}
					applyFilters={(e) => updateFilters(e)}
					filters={activeFilters}
					sorters={activeSorters}
					currentUser={currentUser}
					isDisplayModeActive={(e) => e === 'map'}
					switchDisplayMode={(e) => {}}
					downloadCSV={hideExportButton ? null : () => downloadWorkOrderCSV({}, activeFilters)}
					counts={nullSafeGetOrElse(`${targetCollectionName}.counts`, workOrderSlice, {})}
					clearFilters={clearFilters}
					filterConfig={FILTER_CONFIG}
					targetCollectionName={targetCollectionName}
					preAppliedFilters={preAppliedFilters}
					applyFiltersFromView={replaceFiltersAndSorting}
					userType={userType}
					fetching={fetching}
					companyConfig={companyConfig}
					refreshTable={fetchData}
				/>
			)}

			<div style={{ marginTop: 16, background: 'white' }}>
				<WOTable
					fetching={fetching}
					data={data}
					currentUser={currentUser}
					companyConfig={companyConfig}
					CustomWorkOrderRowDisplay={CustomWorkOrderRowDisplay}
					refreshTable={fetchData}
					tableStyle={tableStyle}
					renderEmptyState={renderEmptyState}
					userType={userType}
					location={location}
					backText={backText}
					targetCollectionName={targetCollectionName}
				/>
				{CustomFooter ? (
					<CustomFooter
						updatePagination={updatePagination}
						pagination={{ ...pagination, current: 1, pageSize: 3 }}
						filters={activeFilters}
						sorters={activeSorters}
						location={location}
						history={history}
						userType={userType}
						openMoreInDefault={openMoreInDefault}
					/>
				) : (
					<Row style={{ padding: 16 }} justify="end">
						<TableFooter
							updatePagination={updatePagination}
							pagination={pagination}
							userType={userType}
						/>
					</Row>
				)}
			</div>
		</div>
	);
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	handleQueryParams: ownProps.handleQueryParams,
	locations: state.locations,
	currentUser: state.session.currentUser,
	workOrderPriorities: state.work_order_priorities,
	assetTypes: state.asset_types,
	spendCategories: state.spend_categories,
	problemTypes: state.problem_types,
	regions: state.regions,
	areas: state.areas,
	resolutionTypes: state.resolution_types,
	supplierFacilities: state.supplier_facilities,
	fieldTechs: state.field_techs,
	planned_maintenance_schedules: state.planned_maintenance_schedules,
	workOrders: state.work_orders[ownProps.targetCollectionName || TARGETCOLLECTIONNAME],
	workOrderSlice: state.work_orders,
	companyConfig: state.company_config.detail,
	targetCollectionName: ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
	data: getEntitiesById(
		state.work_orders[ownProps.targetCollectionName || TARGETCOLLECTIONNAME]
			? state.work_orders[ownProps.targetCollectionName || TARGETCOLLECTIONNAME].recordsIds || []
			: [],
		state.work_orders.records
	),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchData: (location) =>
		dispatch(
			fetchWorkOrders(ownProps.userType)(
				ownProps.handleQueryParams,
				ownProps.history,
				location,
				ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
				ownProps.preAppliedFilters,
				ownProps.initialPagination,
				ownProps.initialSorting,
				ownProps.initialFilters,
				ownProps.ignoreRefreshCounts
			)
		),
	updatePagination: (pagination) =>
		dispatch(
			updatePaginationWorkOrders(ownProps.userType)(
				ownProps.handleQueryParams,
				ownProps.history,
				ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
				pagination,
				ownProps.ignoreRefreshCounts
			)
		),
	updateSorting: (sorting) =>
		dispatch(
			updateSortingWorkOrders(ownProps.userType)(
				ownProps.handleQueryParams,
				ownProps.history,
				ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
				sorting,
				true
			)
		),
	updateFilters: (filters) =>
		dispatch(
			updateFiltersWorkOrders(ownProps.userType)(
				ownProps.handleQueryParams,
				ownProps.history,
				ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
				filters,
				ownProps.ignoreRefreshCounts
			)
		),
	replaceFiltersAndSorting: (filters, sorting) =>
		dispatch(
			replaceFiltersAndSortingWorkOrders(ownProps.userType)(
				ownProps.handleQueryParams,
				ownProps.history,
				ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
				filters,
				sorting,
				ownProps.ignoreRefreshCounts
			)
		),
	clearFilters: () =>
		dispatch(
			clearFiltersWorkOrders(ownProps.userType)(
				ownProps.handleQueryParams,
				ownProps.history,
				ownProps.targetCollectionName || TARGETCOLLECTIONNAME,
				ownProps.preAppliedFilters,
				ownProps.ignoreRefreshCounts
			)
		),
	downloadWorkOrderCSV: (params, filters) =>
		dispatch(downloadWorkOrderCSV(ownProps.userType)(params, filters)),
	fetchWorkOrderPriorities: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) => {
		const fnToCall =
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? workOrderPrioritiesRestCrudThunksForSupplier
				: workOrderPrioritiesRestCrudThunksForBuyer;
		return dispatch(
			fnToCall.read(
				params,
				targetCollectionName || TARGETCOLLECTIONNAME,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		);
	},
	fetchAssetTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) => {
		const fnToCall =
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetTypesRestCrudThunksForSupplier
				: assetTypesRestCrudThunksForBuyer;
		return dispatch(
			fnToCall.readLite(
				{ ...params, no_pagination: true },
				targetCollectionName || TARGETCOLLECTIONNAME,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		);
	},
	fetchMultipleAssetTypes: (ids, targetCollectionName) => {
		const fnToCall =
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetTypesRestCrudThunksForSupplier
				: assetTypesRestCrudThunksForBuyer;
		return dispatch(fnToCall.readMultiple(ids, targetCollectionName));
	},
	fetchMultipleResolutionTypes: (ids, targetCollectionName) => {
		const fnToCall =
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? resolutionTypesRestCrudThunksForSupplier
				: resolutionTypesRestCrudThunksForBuyer;
		return dispatch(fnToCall.readMultiple(ids, targetCollectionName));
	},
	fetchResolutionTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) => {
		const fnToCall =
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? resolutionTypesRestCrudThunksForSupplier
				: resolutionTypesRestCrudThunksForBuyer;
		return dispatch(
			fnToCall.read(
				params,
				targetCollectionName || TARGETCOLLECTIONNAME,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		);
	},
	fetchProblemTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) => {
		const fnToCall =
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? problemTypesHeirarchicalForSupplier
				: problemTypesHeirarchicalForBuyer;
		return dispatch(
			fnToCall(
				params,
				targetCollectionName || TARGETCOLLECTIONNAME,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		);
	},
	fetchSupplierFacilitiesInPrivateNetwork: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) => {
		const fnToCall =
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? fetchSupplierFacilitiesInPrivateNetworkForSupplier
				: fetchSupplierFacilitiesInPrivateNetworkForBuyer;
		return dispatch(
			fnToCall(
				params,
				targetCollectionName || TARGETCOLLECTIONNAME,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		);
	},
	fetchPMSchedule: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) => {
		const fnToCall =
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? plannedMaintenanceSchedulesRestCrudThunksForSupplier
				: plannedMaintenanceSchedulesRestCrudThunksForBuyer;
		return dispatch(
			fnToCall.read(
				params,
				targetCollectionName || TARGETCOLLECTIONNAME,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		);
	},
	fetchMultiplePMSchedule: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) => {
		const fnToCall =
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? plannedMaintenanceSchedulesRestCrudThunksForSupplier
				: plannedMaintenanceSchedulesRestCrudThunksForBuyer;
		return dispatch(
			fnToCall.readMultiple(
				params,
				targetCollectionName || TARGETCOLLECTIONNAME,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		);
	},
	fetchMultipleFieldTechs: (ids, targetCollectionName) =>
		dispatch(fieldTechsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchFieldTechs: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			fieldTechsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchSpendCategories: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			spendCategoriesRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleSpendCategories: (ids, targetCollectionName) =>
		dispatch(spendCategoriesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
});

const WorkOrderHOC = withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkOrdersTable));

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	(dispatch, ownProps) => ({})
)(WorkOrderHOC);

import React, { FC, forwardRef } from 'react';
import { Col, Row } from 'antd';

const style = require('./KeyValueDisplay.less');

export const KeyValueDisplay: FC<any> = forwardRef((props, ref: any) => {
	const obj = props.keyValueStore;
	const renderFuncs = props.renderFuncs;
	const keyAliases = props.keyAliases;

	return props.horizontal ? (
		<Row gutter={16}>
			{Object.keys(obj).reduce((acc, k, idx) => {
				const v = obj[k];
				acc.push(
					<Col
						xl={8}
						lg={12}
						md={24}
						style={{ marginTop: 20 }}
						className="keyValueTable__row"
						key={idx}
					>
						<div className="keyValueTable__key">
							{keyAliases && keyAliases[k] ? keyAliases[k] : k}
						</div>
						<div className="keyValueTable__value">
							{renderFuncs && renderFuncs[k] ? renderFuncs[k](v) : v}
						</div>
					</Col>
				);
				return acc;
			}, [])}
		</Row>
	) : (
		<div className="keyValueTable">
			{Object.keys(obj).reduce((acc, k, idx) => {
				const v = obj[k];
				acc.push(
					<div
						className="keyValueTable__row"
						style={
							props.horizontal
								? {
										minWidth: '33%',
										marginTop: 24,
								  }
								: undefined
						}
						key={idx}
					>
						<div className="keyValueTable__key">
							{keyAliases && keyAliases[k] ? keyAliases[k] : k}
						</div>
						<div className="keyValueTable__value">
							{renderFuncs && renderFuncs[k] ? renderFuncs[k](v) : v}
						</div>
					</div>
				);
				return acc;
			}, [])}
		</div>
	);
});

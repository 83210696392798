import { PrinterOutlined } from '@ant-design/icons';
import { Button, Popover, Table } from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { shipmentsPDFReportGeneratorForSupplier } from '../../thunks/requested_parts_thunks';
import { connect } from 'react-redux';

const SHIPMENTS_TC = 'stockLocationShipmentsIndex';

function StockLocationShipmentsPrintable({
	stockLocation,
	requestedParts,
	readRequestedPartsAsExport,
}) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);

	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		documentTitle: `Openwrench - Pending Shipments ${stockLocation.name} `,
		bodyClass: 'printWo',
	});

	const { sorting, filters } = requestedParts[SHIPMENTS_TC];
	const startPrint = () => {
		setLoading(true);
		readRequestedPartsAsExport({
			...sorting,
			order: sorting.order === 'ascend' ? 'asc' : 'desc',
			...filters,
			no_pagination: true,
		})
			.then((data) => {
				setData(data);
				handlePrint();
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const shipmentsColumns = useMemo(
		() => [
			{
				title: 'Work Order',
				dataIndex: 'workOrderId',
				render: (_) => `#${_}` || '--',
			},
			{
				title: 'Ship To',
				dataIndex: 'locationName',
			},
			{
				title: 'Part Number',
				dataIndex: 'partNumber',
				render: (_) => _ || '--',
			},
			{
				title: 'Part Name',
				dataIndex: 'partName',
			},
			{
				title: 'Located at',
				dataIndex: 'locatedAt',
			},
			{
				title: 'Requested Qty',
				dataIndex: 'actualRequestedQuantity',
			},
			{
				title: 'On Hand Qty',
				dataIndex: 'onHandQuantity',
			},
			{
				title: 'Requested At',
				dataIndex: 'requestedAt',
			},
			{
				title: 'Pending PR Qty',
				dataIndex: 'purchaseRequestQuantity',
				render: (text) => text || 0,
			},
			{
				title: 'Pending PO Qty',
				dataIndex: 'purchaseOrderQuantity',
				render: (text) => text || 0,
			},
		],
		[]
	);

	return (
		<div>
			<Popover content="Print Shipments List" trigger="hover">
				<Button
					loading={loading}
					size="large"
					icon={<PrinterOutlined translate="" />}
					className="inline-block-visible-md"
					onClick={startPrint}
				>
					<span className="inline-block-visible-xxl">Print</span>
				</Button>
			</Popover>
			<div className=" hidden h-0 overflow-hidden" id="printdiv">
				<div ref={componentRef}>
					<Table dataSource={data} columns={shipmentsColumns} showHeader pagination={false} />
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
	readRequestedPartsAsExport: (params) => dispatch(shipmentsPDFReportGeneratorForSupplier(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StockLocationShipmentsPrintable);

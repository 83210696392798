import React from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Layout, Row, Col, Card, Button, Tag } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import {
	getCurrency,
	nullSafeGet,
	nullSafeGetOrElse,
	renderCurrencyByCurrencyId,
} from '../../utils/DataAccessUtils';
import { partsRestCrudThunksForSupplier } from '../../thunks/parts_thunks';
import { ProtectedImageThumbnails } from '../protected_image_thumbnails/ProtectedImageThumbnails';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { optionalFilter } from '../../utils/DataFormatterUtils';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { EmptyState } from '../empty_state/EmptyState';
import { partCatalogsRestCrudThunksForSupplier } from '../../thunks/part_catalogs_thunks';
import { QuantityRowDisplay } from '../part_quantity_row_display/PartQuantityRowDisplay';
import { partsPerStockLocationsRestCrudThunksForSupplier } from '../../thunks/parts_per_stock_locations_thunks';
import PartCatalogModalForm from '../part_catalog_modal_form/PartCatalogModalForm';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { HorizontalKeyValueDisplay } from '../horizontal_key_value_display/HorizontalKeyValueDisplay';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import ReservedQtyDisplay from '../stock_locations_detail_details_page/ReservedQtyDisplay';
import RequestedQuantityDisplay from '../stock_locations_detail_details_page/RequestedQuantityDisplay';
import HyperLink, { ENTITY_TYPE } from '../common/HyperLink';

const { Content } = Layout;

const PART_CATALOGS_FOR_PART = 'PART_CATALOGS_FOR_PART';
const PARTS_PER_STOCK_LOCATIONS_FOR_PART = 'PARTS_PER_STOCK_LOCATIONS_FOR_PART';
const BACK_TEXT = 'Back to Part Detail';

class PartsDetailPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		const { match } = this.props;
		const partId = match.params.id;
		this.state = {
			partCatalogModalFormVisible: false,
			partCatalogFormData: { partId: parseInt(partId) },
		};
	}

	componentDidMount() {
		const { getPart, match } = this.props;
		const partId = match.params.id;
		getPart(partId);
	}

	handlePartCatalogModalFormOpen = () => {
		this.setState({
			partCatalogModalFormVisible: true,
		});
	};

	handlePartCatalogModalFormClose = () => {
		const { match } = this.props;
		const partId = match.params.id;
		this.setState({
			partCatalogModalFormVisible: false,
			partCatalogFormData: { partId: parseInt(partId) },
		});
	};

	handleEditPartCatalog = (entity) => {
		this.setState({
			partCatalogModalFormVisible: true,
			partCatalogFormData: { ...entity },
		});
	};

	onPartCatalogDetail = (entity) => () => {
		const { history, location } = this.props;
		history.push(
			getLinkWIthBackLinkParams(
				location,
				BACK_TEXT,
				`/supplier/parts/partCatalogs/detail/${entity.id}`
			)
		);
	};

	render() {
		const { part, partsFetching, match, currentUser } = this.props;

		const currency = getCurrency({ currentUser });
		const partId = match.params.id;

		const partDetails = partsFetching
			? {}
			: {
					'Part Number': optionalFilter(part.partNumber),
					...(part.aliasPartId && { 'Alias Part Number': part.aliasPartId }),
					Manufacturer: optionalFilter(part.manufacturerName),
					'Manufacturer ID': optionalFilter(part.manufacturerId),
					Cost: currency.format(part.cost),
					'Unit of Measure': optionalFilter(nullSafeGet('unitOfMeasure.displayNameSingular', part)),
					...(part.defaultVendor && {
						'Default Vendor': nullSafeGet('defaultVendor.name', part),
					}),
			  };

		const partCatalogsColumns = [
			{
				title: 'Vendor',
				dataIndex: ['partEquipmentVendor'],
				render: (vendor) => (
					<div className="flex flex-row">
						<div>{nullSafeGet('name', vendor)}</div>
						{vendor.id === nullSafeGet('defaultVendorId', part) ? (
							<div className="ml-2">
								<Tag>Default</Tag>
							</div>
						) : null}
					</div>
				),
			},
			{
				title: 'Last Price',
				dataIndex: 'unitCost',
				render: (_, record) =>
					renderCurrencyByCurrencyId(
						nullSafeGetOrElse('partEquipmentVendor.currencyId', record, currency.id)
					)(_),
			},
			{
				title: 'List Price',
				dataIndex: 'listPrice',
				render: (_, record) =>
					renderCurrencyByCurrencyId(
						nullSafeGetOrElse('partEquipmentVendor.currencyId', record, currency.id)
					)(_),
			},
			{
				render: (_, record) => (
					<div className="flex flex-row items-center">
						<Button
							onClick={() => this.handleEditPartCatalog(record)}
							type="link"
							icon={<EditOutlined translate="" />}
						/>
						<Button
							onClick={() => this.props.deletePartCatalog(record)}
							type="link"
							icon={<DeleteOutlined translate="" />}
						/>
						<Button className="ml-2" onClick={this.onPartCatalogDetail(record)} type="ghost">
							Show Detail
						</Button>
					</div>
				),
			},
		];

		const reservedQuantityAvailable = false;

		const getStockLocationLink = (sl) =>
			`/supplier/stockLocations/detail/${nullSafeGet('id', sl)}/parts${
				nullSafeGet('partNumber', part) ? `?search=${part.partNumber}` : ''
			}`;

		const partsPerStockLocationColumns = [
			{
				title: 'Stock Location',
				dataIndex: ['stockLocation'],
				render: (sl) => (
					<HyperLink
						text={nullSafeGet('name', sl)}
						entityId={nullSafeGet('id', sl)}
						newTabPath={getStockLocationLink(sl)}
						backLinkText={BACK_TEXT}
					/>
				),
			},
			{
				title: 'Available Qty',
				dataIndex: 'availableQuantity',
				render: (text, record) => (
					<QuantityRowDisplay
						min={nullSafeGet('minQuantity', record)}
						max={nullSafeGet('maxQuantity', record)}
						quantity={text}
					/>
				),
			},
			{
				title: 'On Hand Qty',
				dataIndex: 'onHandQuantity',
			},
			...(reservedQuantityAvailable
				? [
						{
							title: 'Reserved Qty',
							render: (_, record) => <ReservedQtyDisplay record={record} backText={BACK_TEXT} />,
						},
				  ]
				: []),
			{
				title: 'Requested Qty',
				render: (_, record) => <RequestedQuantityDisplay record={record} backText={BACK_TEXT} />,
			},
		];

		return partsFetching ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="partsDetailDetailsPage">
				<LogOnMountWithStandardEventProperties eventType="visited supplier part detail page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				{this.state.partCatalogModalFormVisible ? (
					<PartCatalogModalForm
						visible={this.state.partCatalogModalFormVisible}
						formData={this.state.partCatalogFormData}
						onSuccess={this.handlePartCatalogModalFormClose}
						onCancel={this.handlePartCatalogModalFormClose}
						targetCollectionName={PART_CATALOGS_FOR_PART}
					/>
				) : null}
				<Row style={{ margin: '16px 4px' }} gutter={12}>
					<Col span={24}>
						<div className="rowSpacing">
							<Card bodyStyle={{ padding: 0 }}>
								<div className="materialCard__body">
									{part.description && part.description.length > 0 ? (
										<div className="materialCard__primaryTitle">
											<div className="materialCard__title">{part.description}</div>
										</div>
									) : null}
									{part.images && part.images.length > 0 ? (
										<div className="materialCard__subtitle">
											{
												<ProtectedImageThumbnails
													style={{ marginBottom: 32 }}
													imageWidth={160}
													imageHeight={160}
													photoStrings={part.images}
													role={ROLE_TYPES.SUPPLIER}
												/>
											}
										</div>
									) : null}
									<div className="materialCard__supportingText">
										<HorizontalKeyValueDisplay keyWidth={160} keyValueStore={partDetails} />
									</div>
								</div>
							</Card>
						</div>
					</Col>
					<Col span={24}>
						<div className="rowSpacing">
							<Card
								title="Vendors"
								extra={
									<Button
										onClick={this.handlePartCatalogModalFormOpen}
										type="link"
										icon={<PlusOutlined translate="" />}
									>
										Add Vendor
									</Button>
								}
							>
								<PaginatedReduxTable
									showHeader
									emptyState={
										<EmptyState
											graphic={
												<img
													alt="Nothing much to see here."
													style={{ marginBottom: 8 }}
													src="https://s3.amazonaws.com/mock-data-assets/categories/images/sunset.svg"
												/>
											}
											headline={'Nothing much to see here.'}
											body={
												<div style={{ textAlign: 'center' }}>
													<div style={{ maxWidth: 440, marginBottom: 16 }}>
														You haven't added any parts sold by this vendor yet.
													</div>
												</div>
											}
										/>
									}
									collectionName="part_catalogs"
									targetCollectionName={PART_CATALOGS_FOR_PART}
									columns={partCatalogsColumns}
									keyAccessor={(el) => el.id}
									initialFilters={{ partId: partId }}
									initialPagination={{ current: 1, pageSize: 10 }}
									fetchData={partCatalogsRestCrudThunksForSupplier.read}
								/>
							</Card>
						</div>
						<div className="rowSpacing">
							<Card title="Stocked At">
								<PaginatedReduxTable
									showHeader
									emptyState={
										<EmptyState
											graphic={
												<img
													alt="You should get out more."
													style={{ marginBottom: 8 }}
													src="https://s3.amazonaws.com/mock-data-assets/categories/images/sunset.svg"
												/>
											}
											headline={'You should get out more.'}
											body={
												<div style={{ textAlign: 'center' }}>
													<div style={{ maxWidth: 440, marginBottom: 16 }}>
														You haven't stocked this part anywhere yet.
													</div>
												</div>
											}
										/>
									}
									collectionName="parts_per_stock_locations"
									targetCollectionName={PARTS_PER_STOCK_LOCATIONS_FOR_PART}
									columns={partsPerStockLocationColumns}
									keyAccessor={(el) => el.id}
									initialFilters={{ partId: partId }}
									initialPagination={{ current: 1, pageSize: 10 }}
									fetchData={partsPerStockLocationsRestCrudThunksForSupplier.read}
								/>
							</Card>
						</div>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	partsFetching: state.parts.fetching,
	part: state.parts.detail,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	getPart: (id) => dispatch(partsRestCrudThunksForSupplier.readOne(id)),
	deletePartCatalog: (entity) =>
		dispatch(partCatalogsRestCrudThunksForSupplier.delete(entity, 'id', null, true)),
});

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(PartsDetailPage));

import * as React from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import {
	plannedMaintenanceTemplatesRestCrudThunksForBuyer,
	plannedMaintenanceTemplatesRestCrudThunksForSupplier,
} from '../../thunks/planned_maintenance_templates_thunks';
import { plannedMaintenanceFrequencyTextFormatter } from '../../utils/DataFormatterUtils';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import { clearNavbarState, updateNavbarState } from '../../actions/navbar_actions';
import { generateBackLink } from '../../utils/HistoryUtils';
import { ROLE_TYPES } from '../../utils/DataConstants';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';

require('./PlannedMaintenanceSchedulesDetailPage.less');

const DEFAULT_BACK_TEXT = 'Back to PM Schedules';

const queryString = require('qs');

class PlannedMaintenanceSchedulesDetailPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			backLinkTo: `/${props.userType}/plannedMaintenance/overview/all`,
			backLinkText: DEFAULT_BACK_TEXT,
		};
	}
	componentDidMount() {
		const { getPlannedMaintenanceTemplate, match, updateNavbar, location, userType } = this.props;
		const plannedMaintenanceTemplateId = match.params.plannedMaintenanceTemplateId;
		getPlannedMaintenanceTemplate(plannedMaintenanceTemplateId).then((pm) => {
			let searchString = location.search;
			if (searchString[0] === '?') {
				searchString = searchString.slice(1);
			}
			const queryParams = queryString.parse(searchString);
			if (queryParams.backlinkParams) {
				const { tab, pathName, backLinkText, ...otherParams } = queryString.parse(
					queryParams.backlinkParams
				);
				let backPath = pathName || this.state.backLinkTo;
				let backText = backLinkText || this.state.backLinkText;
				updateNavbar(backText, generateBackLink(backPath, location));
			} else {
				updateNavbar(
					this.state.backLinkText,
					generateBackLink(`/${userType}/plannedMaintenance/overview/all`, location)
				);
			}
		});
	}

	handleTabChange = (key) => {
		const { history, match, userType } = this.props;
		const plannedMaintenanceScheduleId = match.params.id;
		history.push(`/${userType}/plannedMaintenance/detail/${plannedMaintenanceScheduleId}/${key}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	handleDelete = () => {
		const { history, deletePlannedMaintenanceSchedule, plannedMaintenanceSchedule, userType } =
			this.props;
		deletePlannedMaintenanceSchedule(plannedMaintenanceSchedule).then(() => {
			history.push(`/${userType}/plannedMaintenance/overview/all`);
		});
	};

	render() {
		const {
			plannedMaintenanceTemplatesFetching,
			plannedMaintenanceTemplate,
			match,
			userType,
			companyConfig,
		} = this.props;
		const plannedMaintenanceScheduleTitle = decodeURIComponent(match.params.title);
		const plannedMaintenanceScheduleFrequency = decodeURIComponent(match.params.frequency);
		const plannedMaintenanceTemplateId = decodeURIComponent(
			match.params.plannedMaintenanceTemplateId
		);
		const basedOn = decodeURIComponent(match.params.basedOn);
		const tabs = [
			{
				key: 'details',
				name: 'Overview',
			},
		];
		return (
			<div className="plannedMaintenanceSchedulesDetailPage">
				<DetailPageHeader
					exactPath={`/${userType}/plannedMaintenance/detail/:title/:frequency/:plannedMaintenanceTemplateId/:basedOn`}
					redirectPath={`/${userType}/plannedMaintenance/detail/${encodeURIComponent(
						plannedMaintenanceScheduleTitle
					)}/${encodeURIComponent(plannedMaintenanceScheduleFrequency)}/${encodeURIComponent(
						plannedMaintenanceTemplateId
					)}/${encodeURIComponent(basedOn)}/details`}
					backLinkText={DEFAULT_BACK_TEXT}
					backLinkTo={`/${userType}/plannedMaintenance`}
					title={plannedMaintenanceScheduleTitle}
					backgroundColor={nullSafeGet('detail.config.theme.navblock', companyConfig)}
					subtitle={
						<div className="plannedMaintenanceSchedulesDetailPage__subtitleRow">
							<div className="plannedMaintenanceSchedulesDetailPage__subtitle">
								{plannedMaintenanceFrequencyTextFormatter(plannedMaintenanceScheduleFrequency)}
							</div>
							<div className="plannedMaintenanceSchedulesDetailPage__subtitle">
								{plannedMaintenanceTemplatesFetching ? null : (
									<div>
										<i className="icons8-font icons8-maintenance-filled" />
										<span>
											{' '}
											{nullSafeGet('problemType.hierarchyName', plannedMaintenanceTemplate)}
										</span>
									</div>
								)}
							</div>
						</div>
					}
					actions={[
						<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PM}>
							<Link
								key={1}
								to={`/${userType}/plannedMaintenance/edit/${encodeURIComponent(
									plannedMaintenanceScheduleTitle
								)}/${plannedMaintenanceScheduleFrequency}/${plannedMaintenanceTemplateId}/${basedOn}`}
								style={{ marginRight: 16 }}
							>
								<Button ghost={true}>Edit</Button>
							</Link>
						</AccessPermissionChecker>,
					]}
					tabs={tabs}
					handleTabChange={this.handleTabChange}
					checkTabActive={this.checkTabActive}
					childRoutes={this.props.childRoutes}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	plannedMaintenanceTemplate: state.planned_maintenance_templates.detail,
	plannedMaintenanceTemplatesFetching: state.planned_maintenance_templates.fetching,
	match: ownProps.match,
	history: ownProps.history,
	userType: (state as any).session.userType,
	companyConfig: state.company_config,
});

const mapDispatchToProps = (dispatch, ownProps) => {
	console.log(ownProps);
	return {
		getPlannedMaintenanceTemplate: (id) =>
			dispatch(
				ownProps.userType === ROLE_TYPES.SUPPLIER
					? plannedMaintenanceTemplatesRestCrudThunksForSupplier.readOne(id)
					: plannedMaintenanceTemplatesRestCrudThunksForBuyer.readOne(id)
			),
		updateNavbar: (backlinkTitle, backlinkTo, navbarBackgroundColor) =>
			dispatch(updateNavbarState(backlinkTitle, backlinkTo, navbarBackgroundColor)),
		clearNavbar: () => dispatch(clearNavbarState()),
	};
};

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(PlannedMaintenanceSchedulesDetailPage)
);
export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

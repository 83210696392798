import { crudActionCreatorsGenerator } from './crud_action_creators_generator';
import { crudActionConstantsGenerator } from './crud_action_constants_generator';

export const CURRENCIES_CRUD_ACTION_CONSTANTS = crudActionConstantsGenerator('CURRENCIES');
export const CURRENCIES_CRUD_ACTION_CREATORS = crudActionCreatorsGenerator(
	CURRENCIES_CRUD_ACTION_CONSTANTS
);

export const FETCH_CURRENCIES = 'FETCH_CURRENCIES';
export function fetchCurrencies() {
	return {
		type: FETCH_CURRENCIES,
	};
}

export const FETCH_CURRENCIES_SUCCESS = 'FETCH_CURRENCIES_SUCCESS';
export function fetchCurrenciesSuccess(records) {
	return {
		type: FETCH_CURRENCIES_SUCCESS,
		records,
	};
}
export const FETCH_CURRENCIES_ERROR = 'FETCH_CURRENCIES_ERROR';
export function fetchCurrenciesError(errors) {
	return {
		type: FETCH_CURRENCIES_ERROR,
		errors,
	};
}

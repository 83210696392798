import React, { FC, useMemo } from 'react';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { EmptyState } from '../empty_state/EmptyState';
import { purchaseRequestLineItemsRestCrudThunksForSupplier } from '../../thunks/purchase_request_line_items_thunks';
import HyperLink, { ENTITY_TYPE } from '../common/HyperLink';
import { getLineItemVendor, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import LocationStockLocationNameWithType from '../equipment_per_stock_location_row_display/LocationStockLocationNameWithType';
import PurchaseRequestStatusDisplay from '../purchase_request_status_display/PurchaseRequestStatusDisplay';
import { PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS } from '../../actions/purchase_request_line_items_actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

const TC_NAME = 'equipmentsPRLineItemsIndex';

const EquipmentPurchaseRequestsPage: FC<any> = ({
	updateFilters,
	clearAndUpdateFilters,
	purchaseRequestLineItems,
	match,
}): React.ReactElement => {
	const equipmentTypeId = useMemo(() => match.params.id, [match.params.id]);

	const columns = useMemo(
		() => [
			{
				title: 'PR',
				dataIndex: 'supplierPurchaseRequestId',
				render: (_) => (
					<HyperLink
						text={`#${_}`}
						entityType={ENTITY_TYPE.PURCHASE_REQUEST}
						entityId={_}
						backLinkText="Back to Equipment"
					/>
				),
			},
			{
				title: 'Vendor',
				render: (_, record) => nullSafeGetOrElse('name', getLineItemVendor(record), '--'),
			},
			{
				title: 'Location',
				render: (_, record) => (
					<LocationStockLocationNameWithType
						record={record}
						backLinkText="Back To Purchase Requests"
					/>
				),
			},
			{
				title: 'Quantity',
				dataIndex: 'equipmentQuantity',
				render: (_) => _ || 0,
			},
			{
				title: 'Status',
				render: (_, record) => <PurchaseRequestStatusDisplay status={record.status} />,
			},
		],
		[]
	);

	return (
		<PaginatedReduxTableWithHeader
			targetCollectionName={TC_NAME}
			updateFilters={updateFilters}
			stateSlice={purchaseRequestLineItems}
			clearAndUpdateFilters={clearAndUpdateFilters}
			filterConfig={[]}
			entityCollectionName="purchase_request_line_items"
			tableColumns={columns}
			fetchData={purchaseRequestLineItemsRestCrudThunksForSupplier.read}
			rightActions={null}
			showHeader
			hideFilters
			preAppliedFilters={{ equipmentTypeId }}
			emptyState={
				<EmptyState
					graphic={
						<img
							alt="No Data"
							style={{ marginBottom: 8 }}
							src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
						/>
					}
					headline={'No Purchase Requests found'}
					body={null}
				/>
			}
		/>
	);
};

const mapStateToProps = (state) => ({
	purchaseRequestLineItems: state.purchase_request_line_items,
});

const mapDispatchToProps = (dispatch) => ({
	updateFilters: (filters, targetCollection) =>
		dispatch(
			PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)
		),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			PURCHASE_REQUEST_LINE_ITEMS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(
				filters,
				targetCollectionName
			)
		),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(EquipmentPurchaseRequestsPage)
);

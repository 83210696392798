import * as React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Alert, Button, Divider } from 'antd';
import { connect } from 'react-redux';
import { logIn } from '../../thunks/session_thunks';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter, Redirect } from 'react-router';
import { demoLoginCredentials } from '../../mock_data/mocks';
import { Link } from 'react-router-dom';
import { getHomePageByRole } from '../../utils/EnvConfigUtils';
import { isHomePageUrl } from '../../utils/HistoryUtils';

const style = require('./SupplierLoginForm.less');
const FormItem = Form.Item;

interface LoginProps {
	isAuthenticated: boolean;
	logIn: any;
	loading: boolean;
	currentUser: any;
	returnTo?: any;
	username: string;
	password: string;
	errors: string[];
}

interface LoginFormProps extends FormComponentProps {
	isAuthenticated: boolean;
	logIn: any;
	loading: boolean;
	currentUser: any;
	returnTo?: any;
	username: string;
	password: string;
	errors: string[];
	featureFlags: {};
}

class SupplierLoginForm extends React.Component<LoginFormProps, any> {
	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.props.logIn(values.username.trim(), values.password.trim());
			}
		});
	};

	handleDemoLogin = (e) => {
		this.props.logIn(demoLoginCredentials.username.trim(), demoLoginCredentials.password.trim());
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { isAuthenticated, returnTo, loading, errors, featureFlags } = this.props;
		const homePage = getHomePageByRole('supplier', featureFlags);
		return isAuthenticated ? (
			<Redirect
				to={
					returnTo && !isHomePageUrl(returnTo.pathname)
						? returnTo
						: {
								pathname: homePage,
								state: { from: '/login/supplier' },
						  }
				}
			/>
		) : (
			<Form onSubmit={this.handleSubmit} className="loginForm">
				{errors.length > 0
					? errors.map((error, idx) => (
							<FormItem key={idx}>
								<Alert message={error} type="error" />
							</FormItem>
					  ))
					: null}
				<FormItem>
					{getFieldDecorator('username', {
						rules: [{ required: true, message: 'Please input your email!' }],
					})(
						<Input
							prefix={<UserOutlined style={{ fontSize: 14 }} translate="" />}
							size="large"
							placeholder="Email"
						/>
					)}
				</FormItem>
				<FormItem>
					{getFieldDecorator('password', {
						rules: [{ required: true, message: 'Please input your password!' }],
					})(
						<Input.Password
							prefix={<LockOutlined style={{ fontSize: 14 }} translate="" />}
							size="large"
							type="password"
							visibilityToggle={true}
							placeholder="Password"
						/>
					)}
				</FormItem>
				<FormItem>
					<Button
						type="primary"
						htmlType="submit"
						loading={loading}
						size="large"
						className="loginForm__button"
					>
						Log in
					</Button>

					<span style={{ fontSize: 16 }}>
						<Link className="loginForm__forgot" to="/supplier/forgotPassword">
							Forgot password
						</Link>
						Or <Link to="/supplier/signup">register now!</Link>
					</span>
				</FormItem>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	loading: state.session.loading,
	errors: state.session.errors,
	isAuthenticated: state.session.isAuthenticated,
	currentUser: state.session.currentUser,
	featureFlags: state.session.featureFlags,
});

const mapDispatchToProps = (dispatch) => ({
	logIn: (username, password) => dispatch(logIn('supplier')(username, password)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Form.create<LoginFormProps>()(SupplierLoginForm))
);

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Button, Card } from 'antd';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import {
	plannedMaintenanceSchedulesGroupedRestCrudThunksForBuyer,
	plannedMaintenanceSchedulesGroupedRestCrudThunksForSupplier,
} from '../../thunks/planned_maintenance_schedule_groups_thunks';
import { EmptyState } from '../empty_state/EmptyState';
import SubnavBar from '../subnav_bar/SubnavBar';
import { PlannedMaintenanceScheduleGroupRowDisplay } from '../planned_maintenance_schedule_group_row_display/PlannedMaintenanceScheduleGroupRowDisplay';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { AdvancedFilters } from '../advanced_filters/AdvancedFilters';
import { Form } from '@ant-design/compatible';

import {
	changeFilterValueToArrayOfIds,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import {
	locationsRestCrudThunksForBuyer,
	locationsRestCrudThunksForSupplier,
} from '../../thunks/locations_thunks';
import {
	regionsRestCrudThunksForBuyer,
	regionsRestCrudThunksForSupplier,
} from '../../thunks/regions_thunks';
import { PAGE_SIZE_OPTIONS } from '../../constants/pagination';
import { PLANNED_MAINTENANCE_SCHEDULE_GROUPS_CRUD_ACTION_CREATORS } from '../../actions/planned_maintenance_schedule_groups_actions';
import ProblemTypeTreeSelect from '../problem_type_tree_select';
import {
	problemTypesRestCrudThunksForBuyer,
	problemTypesRestCrudThunksForSupplier,
} from '../../thunks/problem_types_thunks';
import {
	plannedMaintenanceTemplatesRestCrudThunksForBuyer,
	plannedMaintenanceTemplatesRestCrudThunksForSupplier,
} from '../../thunks/planned_maintenance_templates_thunks';
import {
	assetsRestCrudThunksForBuyer,
	assetsRestCrudThunksForSupplier,
	fetchAssetsGroupedByLocationForBuyer,
	fetchAssetsGroupedByLocationForSupplier,
} from '../../thunks/assets_thunks';
import OWAsyncTreeSelect from '../ow_async_tree_select/OWAsyncTreeSelect';
import {
	assetTypesRestCrudThunksForBuyer,
	assetTypesRestCrudThunksForSupplier,
} from '../../thunks/asset_types_thunks';
import SelectedFilters from '../selected_filters_pills/SelectedFilters';
import OWRadioGroup from '../ow_radio_group/OWRadioGroup';
import LocationsTreeSelect from '../locations_tree_select/LocationsTreeSelect';
import { FILTER_CONFIG_NAMES, ROLE_TYPES } from '../../utils/DataConstants';
import BuyerRegionsLocationsTreeSelect from '../regions_tree_select/BuyerRegionsLocationsTreeSelect';
import SupplierRegionsLocationsTreeSelect from '../regions_tree_select/SupplierRegionsLocationsTreeSelect';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';

const { Content } = Layout;

require('./PlannedMaintenanceSchedulesIndexPage.less');
const queryString = require('qs');
const PLANNED_MAINTENANCE_SCHEDULES_OVERVIEW_TARGET_COLLECTION =
	'plannedMaintenanceScheduleGroupsIndex';

class PlannedMaintenanceSchedulesIndexPage extends React.Component<any, any> {
	initialPagination: any = { current: 1, pageSize: 10 };
	initialFilters: any = {};
	preAppliedFilters: any = {};

	constructor(props) {
		super(props);
		const { location, updatePlannedMaintenanceFilters, history, userType } = this.props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		const { current, pageSize, ...plannedMaintenanceFilters } = queryParams;
		this.initialPagination = {
			...this.initialPagination,
			current: current ? current : this.initialPagination.current,
			pageSize: pageSize ? pageSize : this.initialPagination.pageSize,
		};
		this.initialFilters = plannedMaintenanceFilters;
		updatePlannedMaintenanceFilters(
			this.initialFilters,
			PLANNED_MAINTENANCE_SCHEDULES_OVERVIEW_TARGET_COLLECTION
		);
		history.push(
			`/${userType}/plannedMaintenance/overview/all?${new URLSearchParams(location.search)}`
		);
	}
	render() {
		const {
			history,
			location,
			assets,
			assetTypes,
			locations,
			regions,
			problemTypes,
			plannedMaintenanceScheduleGroups,
			plannedMaintenanceTemplates,
			activeFilters,
			fetchAssets,
			fetchAssetsByLocation,
			fetchAssetTypes,
			fetchMultipleAssetTypes,
			fetchLocations,
			fetchMultipleLocations,
			fetchRegions,
			fetchProblemTypes,
			fetchMultipleProblemTypes,
			fetchMultipleRegions,
			fetchPlannedMaintenanceTemplates,
			fetchMultiplePlannedMaintenanceTemplates,
			updatePlannedMaintenanceFilters,
			clearAndUpdateFilters,
			userType,
		} = this.props;

		const RegionsLocationsTreeSelect =
			userType === ROLE_TYPES.SUPPLIER
				? SupplierRegionsLocationsTreeSelect
				: BuyerRegionsLocationsTreeSelect;

		const columns = [
			{
				title: 'Template Name',
				dataIndex: 'plannedMaintenanceTemplateId',
				render: (text, record) => (
					<PlannedMaintenanceScheduleGroupRowDisplay plannedMaintenanceScheduleGroup={record} />
				),
			},
		];
		const { locationIds, assetTypeIds, assetIds } = nullSafeGet(
			`${PLANNED_MAINTENANCE_SCHEDULES_OVERVIEW_TARGET_COLLECTION}.filters`,
			plannedMaintenanceScheduleGroups
		);
		const filterConfig = [
			{
				label: 'Assets',
				fieldName: FILTER_CONFIG_NAMES.ASSET,
				mode: 'multiple',
				hideFilter: locationIds || assetTypeIds,
				stateSlice: assets,
				filtersValueAccessor: (filtersStateSlice) => {
					const ids = nullSafeGet(
						`${PLANNED_MAINTENANCE_SCHEDULES_OVERVIEW_TARGET_COLLECTION}.filters.assetIds`,
						filtersStateSlice
					);
					return changeFilterValueToArrayOfIds(ids);
				},
				keyAccessor: (asset) => asset.id,
				valueAccessor: (asset) => asset.id,
				labelAccessor: (asset) => asset.name,
				handleChange: (ids) => {
					updatePlannedMaintenanceFilters(
						{
							assetIds: ids.join(',') || undefined,
							basedOn: nullSafeGetOrElse('length', ids, 0) > 0 ? 'asset' : undefined,
						},
						PLANNED_MAINTENANCE_SCHEDULES_OVERVIEW_TARGET_COLLECTION
					);
				},
				targetCollectionName: 'locationAssociatedAssets',
				fetch: fetchAssetsByLocation,
				fetchMultiple: () => {},
				renderItem: (asset) => asset.name,
				renderRecord: (asset) => asset.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
				component: LocationsTreeSelect,
			},
			{
				label: 'Asset Types',
				fieldName: FILTER_CONFIG_NAMES.ASSET_TYPE,
				hideFilter: locationIds || assetIds,
				mode: 'multiple',
				stateSlice: assetTypes,
				filtersValueAccessor: (filtersStateSlice) => {
					const assetTypeIds = nullSafeGet(
						`${PLANNED_MAINTENANCE_SCHEDULES_OVERVIEW_TARGET_COLLECTION}.filters.assetTypeIds`,
						filtersStateSlice
					);
					return changeFilterValueToArrayOfIds(assetTypeIds);
				},
				keyAccessor: (assetType) => assetType.id,
				valueAccessor: (assetType) => assetType.id,
				handleChange: (ids) => {
					updatePlannedMaintenanceFilters(
						{
							assetTypeIds: ids.join(',') || undefined,
							basedOn: nullSafeGetOrElse('length', ids, 0) > 0 ? 'assetType' : undefined,
						},
						PLANNED_MAINTENANCE_SCHEDULES_OVERVIEW_TARGET_COLLECTION
					);
				},
				targetCollectionName: 'plannedMaintenanceAdvancedFilters',
				fetch: fetchAssetTypes,
				fetchMultiple: fetchMultipleAssetTypes,
				renderItem: (assetType) => assetType.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
				component: OWAsyncTreeSelect,
			},
			{
				label: 'Location',
				fieldName: 'locationIds',
				mode: 'multiple',
				stateSlice: locations,
				hideFilter: true,
				filtersValueAccessor: (filtersStateSlice) => {
					const ids = nullSafeGet(
						`${PLANNED_MAINTENANCE_SCHEDULES_OVERVIEW_TARGET_COLLECTION}.filters.locationIds`,
						filtersStateSlice
					);
					return changeFilterValueToArrayOfIds(ids);
				},
				keyAccessor: (loc) => loc.id,
				valueAccessor: (loc) => loc.id,
				handleChange: (ids) => {
					updatePlannedMaintenanceFilters(
						{
							locationIds: ids.join(',') || undefined,
							basedOn: nullSafeGetOrElse('length', ids, 0) > 0 ? 'location' : undefined,
						},
						PLANNED_MAINTENANCE_SCHEDULES_OVERVIEW_TARGET_COLLECTION
					);
				},
				targetCollectionName: 'plannedMaintenanceAdvancedFilters',
				fetch: fetchLocations,
				fetchMultiple: fetchMultipleLocations,
				renderItem: (loc) => loc.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
			},
			{
				fieldName: 'Based On',
				hideFilter: true,
				type: 'radioGroup',
				items: [
					{
						label: 'Location',
						value: undefined,
					},
					{
						label: 'Asset',
						value: 'false',
					},
					{
						label: 'Asset Type',
						value: 'true',
					},
				],
				filtersValueAccessor: (filtersStateSlice) => {
					const val = filtersStateSlice.isCapex;
					return typeof val === 'undefined' ? undefined : `${val}`;
				},
				keyAccessor: (item) => item.value,
				valueAccessor: (item) => item.value,
				handleChange: (basedOn) => {
					const val = basedOn ? basedOn === 'true' : undefined;
					updatePlannedMaintenanceFilters({ basedOn: val });
				},
				targetCollectionName: 'plannedMaintenanceAdvancedFilters',
				renderRecord: (item) => item.label,
				component: OWRadioGroup,
			},
			{
				label: 'Region',
				mode: 'multiple',
				stateSlice: regions,
				hideFilter: true,
				filtersValueAccessor: (filtersStateSlice) => {
					const ids = nullSafeGet(
						`${PLANNED_MAINTENANCE_SCHEDULES_OVERVIEW_TARGET_COLLECTION}.filters.regionIds`,
						filtersStateSlice
					);
					return changeFilterValueToArrayOfIds(ids);
				},
				keyAccessor: (region) => region.id,
				valueAccessor: (region) => region.id,
				handleChange: (ids) => {
					updatePlannedMaintenanceFilters(
						{
							regionIds: ids.join(',') || undefined,
						},
						PLANNED_MAINTENANCE_SCHEDULES_OVERVIEW_TARGET_COLLECTION
					);
				},
				targetCollectionName: 'plannedMaintenanceAdvancedFilters',
				fetch: fetchRegions,
				fetchMultiple: fetchMultipleRegions,
				renderItem: (region) => region.name,
				sortBy: { sort_by: 'name', order: 'ascend' },
			},
			{
				label: 'Planned Maintenance Template',
				mode: 'multiple',
				fieldName: 'plannedMaintenanceTemplateIds',
				stateSlice: plannedMaintenanceTemplates,
				filtersValueAccessor: (filtersStateSlice) => {
					const plannedMaintenanceTemplateIds = nullSafeGet(
						`${PLANNED_MAINTENANCE_SCHEDULES_OVERVIEW_TARGET_COLLECTION}.filters.plannedMaintenanceTemplateIds`,
						filtersStateSlice
					);
					return changeFilterValueToArrayOfIds(plannedMaintenanceTemplateIds);
				},
				keyAccessor: (plannedMaintenanceTemplate) => plannedMaintenanceTemplate.id,
				valueAccessor: (plannedMaintenanceTemplate) => plannedMaintenanceTemplate.id,
				labelAccessor: (plannedMaintenanceTemplate) => plannedMaintenanceTemplate.title,
				handleChange: (ids) => {
					updatePlannedMaintenanceFilters(
						{
							plannedMaintenanceTemplateIds: ids.join(',') || undefined,
						},
						PLANNED_MAINTENANCE_SCHEDULES_OVERVIEW_TARGET_COLLECTION
					);
				},
				targetCollectionName: 'plannedMaintenanceAdvancedFilters',
				fetch: fetchPlannedMaintenanceTemplates,
				fetchMultiple: fetchMultiplePlannedMaintenanceTemplates,
				renderItem: (plannedMaintenanceTemplate) => plannedMaintenanceTemplate.title,
				sortBy: { sort_by: 'title', order: 'ascend' },
				component: OWAsyncTreeSelect,
			},
			{
				label: 'Problem Type',
				mode: 'multiple',
				fieldName: 'problemTypeIds',
				stateSlice: problemTypes,
				filtersValueAccessor: (filtersStateSlice) => {
					const problemTypeIds = nullSafeGet(
						`${PLANNED_MAINTENANCE_SCHEDULES_OVERVIEW_TARGET_COLLECTION}.filters.problemTypeIds`,
						filtersStateSlice
					);
					return changeFilterValueToArrayOfIds(problemTypeIds);
				},
				keyAccessor: (problemType) => problemType.id,
				valueAccessor: (problemType) => problemType.id,
				handleChange: (ids) => {
					updatePlannedMaintenanceFilters(
						{
							problemTypeIds: ids.join(',') || undefined,
						},
						PLANNED_MAINTENANCE_SCHEDULES_OVERVIEW_TARGET_COLLECTION
					);
				},
				targetCollectionName: 'plannedMaintenanceAdvancedFilters',
				fetch: fetchProblemTypes,
				fetchMultiple: fetchMultipleProblemTypes,
				renderItem: (problemType) => problemType.hierarchyName,
				sortBy: { sort_by: 'name', order: 'ascend' },
				component: ProblemTypeTreeSelect,
			},
		];

		const onRow = (record) => ({
			onClick: () => {
				let searchString = location.search;
				if (searchString[0] === '?') {
					searchString = searchString.slice(1);
				}
				let backUrl = `/${userType}/plannedMaintenance/detail/${encodeURIComponent(
					record.title
				)}/${encodeURIComponent(record.frequency)}/${encodeURIComponent(
					record.plannedMaintenanceTemplateId
				)}/${encodeURIComponent(record.basedOn)}/details`;
				if (searchString && searchString.length > 0) {
					backUrl = backUrl.concat(`?backlinkParams=${encodeURIComponent(searchString)}`);
				}
				history.push(backUrl);
			},
		});

		return (
			<Content className="plannedMaintenanceSchedulesIndexPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties
					eventType={`visited ${userType} pm schedule index page`}
				/>
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '0.5em -8px' }}>
					<Col span={24}>
						<SubnavBar
							left={
								<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
									<AdvancedFilters
										updateFilters={updatePlannedMaintenanceFilters}
										filterConfig={filterConfig}
										filtersTargetCollectionName={
											PLANNED_MAINTENANCE_SCHEDULES_OVERVIEW_TARGET_COLLECTION
										}
										clearAndUpdateFilters={clearAndUpdateFilters}
										filtersStateSlice={plannedMaintenanceScheduleGroups}
										preAppliedFilters={{}}
										initialFilters={this.initialFilters}
										inputParamName={'title'}
									>
										{assetTypeIds || assetIds ? null : (
											<Form.Item label="Location">
												<RegionsLocationsTreeSelect
													mode="multiple"
													filtersSlice={plannedMaintenanceScheduleGroups}
													filtersValueAccessor={(filtersStateSlice) => {
														const locationIds = nullSafeGet(
															`${PLANNED_MAINTENANCE_SCHEDULES_OVERVIEW_TARGET_COLLECTION}.filters.locationIds`,
															filtersStateSlice
														);
														const finalIds = nullSafeGetOrElse(
															`${PLANNED_MAINTENANCE_SCHEDULES_OVERVIEW_TARGET_COLLECTION}.filters.regionIds`,
															filtersStateSlice,
															locationIds
														);
														return changeFilterValueToArrayOfIds(finalIds);
													}}
													onChange={(ids) => {
														fetchAssets(
															{ locationIds: ids.join(',') || undefined },
															'plannedMaintenanceAdvancedFilters'
														);
														updatePlannedMaintenanceFilters(
															{
																locationIds: ids.join(',') || undefined,
																regionIds: undefined,
																basedOn:
																	nullSafeGetOrElse('length', ids, 0) > 0 ? 'location' : undefined,
															},
															PLANNED_MAINTENANCE_SCHEDULES_OVERVIEW_TARGET_COLLECTION
														);
													}}
													targetCollectionName={'plannedMaintenanceAdvancedFilters'}
													renderItem={(location) => location.name}
												/>
											</Form.Item>
										)}
									</AdvancedFilters>
								</div>
							}
							right={
								<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PM}>
									<Button
										size="large"
										type="primary"
										onClick={() => this.props.history.push(`/${userType}/plannedMaintenance/new`)}
									>
										Create Schedule
									</Button>
								</AccessPermissionChecker>
							}
						/>
					</Col>
				</Row>
				<SelectedFilters
					updateFilters={(filters) => {
						const currentFilters = { ...activeFilters, ...filters };
						const { locationIds, assetIds, assetTypeIds } = currentFilters;
						const basedOn = locationIds
							? 'location'
							: assetIds
							? 'asset'
							: assetTypeIds
							? 'assetType'
							: undefined;
						updatePlannedMaintenanceFilters(
							{ ...filters, basedOn },
							PLANNED_MAINTENANCE_SCHEDULES_OVERVIEW_TARGET_COLLECTION
						);
					}}
					filterValues={activeFilters}
					filterConfig={filterConfig}
				/>
				<Row style={{ margin: '0.5em -8px' }}>
					<Col span={24}>
						<Card bodyStyle={{ padding: 8 }}>
							<PaginatedReduxTable
								//tableLayoutFixed={true}
								updateQueryParams={true}
								showHeader={false}
								emptyState={
									<EmptyState
										graphic={
											<img
												style={{ marginBottom: 8 }}
												src="https://s3.amazonaws.com/mock-data-assets/categories/images/calendar.svg"
												alt={`Your team hasn't scheduled any planned maintenance yet. Create your first
												to stay on top of your PMs.`}
											/>
										}
										headline={'Proper planning prevents poor performance.'}
										body={
											<div style={{ textAlign: 'center' }}>
												<div style={{ maxWidth: 440, marginBottom: 16 }}>
													Your team hasn't scheduled any planned maintenance yet. Create your first
													to stay on top of your PMs.
												</div>
												<Button
													type="primary"
													onClick={() =>
														this.props.history.push(`/${userType}/plannedMaintenance/new`)
													}
												>
													Create schedule
												</Button>
											</div>
										}
									/>
								}
								collectionName="planned_maintenance_schedules/grouped"
								targetCollectionName="plannedMaintenanceScheduleGroupsIndex"
								columns={columns}
								onRow={onRow}
								keyAccessor={(record) =>
									record['title'] +
									',' +
									record['frequency'] +
									',' +
									record['plannedMaintenanceTemplateId']
								}
								initialFilters={this.initialFilters}
								hiddenPagination={{ showSizeChanger: true, pageSizeOptions: PAGE_SIZE_OPTIONS }}
								initialPagination={this.initialPagination}
								fetchData={
									userType === ROLE_TYPES.SUPPLIER
										? plannedMaintenanceSchedulesGroupedRestCrudThunksForSupplier.read
										: plannedMaintenanceSchedulesGroupedRestCrudThunksForBuyer.read
								}
							/>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	regions: state.regions,
	locations: state.locations,
	assets: state.assets,
	assetTypes: state.asset_types,
	problemTypes: state.problem_types,
	supplierFacilities: state.supplier_facilities,
	plannedMaintenanceTemplates: state.planned_maintenance_templates,
	activeFilters:
		state['planned_maintenance_schedules/grouped'][
			PLANNED_MAINTENANCE_SCHEDULES_OVERVIEW_TARGET_COLLECTION
		].filters,

	plannedMaintenanceSchedules: state.planned_maintenance_schedules,
	plannedMaintenanceScheduleGroups: state['planned_maintenance_schedules/grouped'],
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	updatePlannedMaintenanceFilters: (filters, targetCollection) =>
		dispatch(
			PLANNED_MAINTENANCE_SCHEDULE_GROUPS_CRUD_ACTION_CREATORS.updateFilters(
				filters,
				targetCollection
			)
		),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			PLANNED_MAINTENANCE_SCHEDULE_GROUPS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(
				filters,
				targetCollectionName
			)
		),
	fetchPlannedMaintenanceSchedulesGrouped: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters
	) => {
		const fnToCall =
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? plannedMaintenanceSchedulesGroupedRestCrudThunksForSupplier.read
				: plannedMaintenanceSchedulesGroupedRestCrudThunksForBuyer.read;
		return dispatch(fnToCall(params, null, pagination, sorting, filters));
	},
	fetchMultiplePlannedMaintenanceTemplates: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? plannedMaintenanceTemplatesRestCrudThunksForSupplier.readMultiple(
						ids,
						targetCollectionName
				  )
				: plannedMaintenanceTemplatesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchPlannedMaintenanceTemplates: (params, targetCollectionName, pagination, sorting, filters) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? plannedMaintenanceTemplatesRestCrudThunksForSupplier.readLite(
						{ ...params, no_pagination: true },
						targetCollectionName,
						pagination,
						sorting,
						filters
				  )
				: plannedMaintenanceTemplatesRestCrudThunksForBuyer.readLite(
						{ ...params, no_pagination: true },
						targetCollectionName,
						pagination,
						sorting,
						filters
				  )
		),
	fetchMultipleLocations: (ids, targetCollectionName) => {
		const fnToCall =
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.readMultiple
				: locationsRestCrudThunksForBuyer.readMultiple;
		return dispatch(fnToCall(ids, targetCollectionName));
	},
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) => {
		const fnToCall =
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.readLite
				: locationsRestCrudThunksForBuyer.readLite;
		return dispatch(
			fnToCall(
				{ ...params, no_pagination: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		);
	},
	fetchRegions: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? regionsRestCrudThunksForSupplier.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: regionsRestCrudThunksForBuyer.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultipleRegions: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? regionsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: regionsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchMultipleProblemTypes: (ids, targetCollectionName) => {
		const fnToCall =
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? problemTypesRestCrudThunksForSupplier.readMultiple
				: problemTypesRestCrudThunksForBuyer.readMultiple;
		return dispatch(fnToCall(ids, targetCollectionName));
	},
	fetchProblemTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) => {
		const fnToCall =
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? problemTypesRestCrudThunksForSupplier.read
				: problemTypesRestCrudThunksForBuyer.read;
		return dispatch(
			fnToCall(params, targetCollectionName, pagination, sorting, filters, addToTargetCollection)
		);
	},
	fetchMultipleAssetTypes: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: assetTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchAssetTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetTypesRestCrudThunksForSupplier.readLite(
						{ ...params, no_pagination: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: assetTypesRestCrudThunksForBuyer.readLite(
						{ ...params, no_pagination: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchAssets: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetsRestCrudThunksForSupplier.readLite(
						{ ...params },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: assetsRestCrudThunksForBuyer.readLite(
						{ ...params },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchAssetsByLocation: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? fetchAssetsGroupedByLocationForSupplier(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: fetchAssetsGroupedByLocationForBuyer(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(PlannedMaintenanceSchedulesIndexPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

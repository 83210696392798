import * as React from 'react';

import { Modal, Input, DatePicker, Form, Select, Card, Button } from 'antd';

import { connect } from 'react-redux';
import OWRadioGroup from '../ow_radio_group/OWRadioGroup';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { workOrdersRestCrudThunksForBuyer } from '../../thunks/work_orders_thunks';
import { assetDowntime, assetDowntimeGet, assetDowntimeUpdate } from '../../thunks/assets_thunks';
import moment from 'moment';
import { BACKEND_DATE_FORMAT } from '../../utils/DataFormatterUtils';
import OWDatePicker from '../ow_date_picker/OWDatePicker';

const AssetStatusForm = (props) => {
	const {
		asset = {},
		creating,
		updating,
		workOrders,
		fetchWorkOrders,
		userType,
		updateAssetDowntime,
		onCancel,
		onSuccess,
		visible,
		downTimeObj = {},
	} = props;

	const [loading, setLoading] = React.useState(false);
	const initialStatus = nullSafeGet('downtimeStatus', asset);
	const [form] = Form.useForm();
	const isCurrentOffline = Form.useWatch('isAssetDown', form);
	const currentReason = Form.useWatch('reason', form);
	const statusChanged = initialStatus !== isCurrentOffline;
	const startTimeValue = Form.useWatch('startTime', form);

	const handleSubmit = (values) => {
		const obj = {
			...downTimeObj,
			reason: values.reason,
			note: values.note,
			associatedWorkOrderIds: values.associatedWorkOrderIds,
			isAssetDown: values.isAssetDown,
			assetId: asset.id,
			startTime:
				nullSafeGet('startAndEndDates.0', values) ||
				nullSafeGet('startTime', values) ||
				nullSafeGet('startTime', downTimeObj),
			endTime: nullSafeGet('startAndEndDates.1', values),
		};
		setLoading(true);

		updateAssetDowntime(userType, obj).then(() => {
			form.resetFields();
			setLoading(false);
			onSuccess();
		});
	};
	console.log('downTimeObj.startTime', downTimeObj.startTime);
	console.log('downTimeObj.endTime', downTimeObj.endTime);
	console.log('downTimeObj.isAssetDown', downTimeObj.isAssetDown);

	const { startTime, endTime, ...formData } = downTimeObj;
	return (
		<Modal
			visible={visible}
			width={600}
			title="Change Asset Status"
			okText={'Confirm'}
			onCancel={() => {
				form.resetFields();
				onCancel();
			}}
			onOk={form.submit}
			closable={false}
			confirmLoading={loading}
			destroyOnClose
		>
			<Form
				layout="vertical"
				className="supplierForm"
				form={form}
				onFinish={handleSubmit}
				initialValues={formData}
			>
				<Form.Item
					label="Is the asset functioning?"
					name="isAssetDown"
					required
					rules={[{ required: true, message: 'Please select' }]}
				>
					<OWRadioGroup
						valueAccessor={(item) => item.value}
						renderRecord={(item) => item.label}
						items={[
							{
								label: 'Yes',
								value: false,
							},
							{
								label: 'No',
								value: true,
							},
						]}
					/>
				</Form.Item>

				<>
					{!isCurrentOffline && (
						<>
							<Form.Item
								label="Is it resolved?"
								name="reason"
								required
								rules={[{ required: true, message: 'Please select' }]}
							>
								<OWRadioGroup
									valueAccessor={(item) => item.value}
									renderRecord={(item) => item.label}
									items={[
										{
											label: 'Back online',
											value: 'backOnline',
										},
										{
											label: 'Marked offline by mistake',
											value: 'falsePositive',
										},
									]}
								/>
							</Form.Item>
							{currentReason !== 'falsePositive' && (
								<Form.Item
									label="During what period the asset was offline "
									name="startAndEndDates"
									initialValue={[
										downTimeObj.startTime ? moment(downTimeObj.startTime) : moment(),
										downTimeObj.endTime ? moment(downTimeObj.endTime) : moment(),
									]}
									key="range"
								>
									<OWDatePicker
										format="DD-MM-YYYY hh:mm A"
										showTime
										disabled={[false, isCurrentOffline]}
										placeholder={['Start Time', 'End Time']}
									/>
								</Form.Item>
							)}
							<Form.Item label="Notes" name="note">
								<Input.TextArea />
							</Form.Item>
						</>
					)}

					{isCurrentOffline && (
						<>
							<Form.Item
								label="Since when the downtime start "
								name="startTime"
								key="dateonly"
								help={
									startTimeValue ? (
										<div className="mb-4 mt-2 text-sm text-gray-500">
											{moment(startTimeValue).fromNow()}
										</div>
									) : null
								}
								rules={[{ required: true, message: 'Please provide a start time' }]}
								initialValue={downTimeObj.startTime ? moment(downTimeObj.startTime) : moment()}
							>
								<DatePicker
									disabledDate={(current) => current && current > moment()}
									format="DD-MM-YYYY hh:mm A"
									showTime
									placeholder={'Start Time'}
								/>
							</Form.Item>

							<Form.Item
								label="Select Work Orders which are working on this asset "
								name="associatedWorkOrderIds"
								help={
									<div className="mb-4 mt-2 text-sm text-gray-500">
										List is pre-filtered by asset and start time
									</div>
								}
								initialValue={downTimeObj.associatedWorkOrderIds}
							>
								<OWAsyncSelect
									mode="multiple"
									style={{ maxWidth: 640 }}
									targetCollectionName="asset_search_work_orders"
									stateSlice={workOrders}
									fetchMultiple={(ids, targetCollectionName) => {}}
									fetchData={(
										searchText,
										targetCollectionName,
										pagination,
										sorting,
										filters = {}
									) => {
										fetchWorkOrders(
											{
												search: searchText,
												...filters,
											},
											targetCollectionName
										);
									}}
									placeholder={'Search with title or work order id '}
									renderRecord={(wo) => (
										<Select.Option key={wo.id} value={wo.id}>
											#{wo.id} - {wo.title}
										</Select.Option>
									)}
									sortBy={{ sort_by: 'name', order: 'ascend' }}
									additionalFilters={{
										createdAtStartDate: startTimeValue
											? moment(startTimeValue).format(BACKEND_DATE_FORMAT)
											: null,
										assetId: asset.id,
										isPM: false,
									}}
								/>
							</Form.Item>
						</>
					)}
				</>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	workOrders: state.work_orders,
	userType: state.session.userType,
});
const mapDispatchToProps = (dispatch) => ({
	fetchWorkOrders: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			workOrdersRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	createAssetDowntime: (roleType, data) => dispatch(assetDowntime(roleType)(data)),
	updateAssetDowntime: (roleType, data) => dispatch(assetDowntimeUpdate(roleType)(data)),
	getAssetDowntime: (params, userType) => dispatch(assetDowntimeGet(userType)(params)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AssetStatusForm);

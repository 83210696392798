import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { assetTypesRestCrudThunksForSupplier } from '../../thunks/asset_types_thunks';
import { assetModelsRestCrudThunksForSupplier } from '../../thunks/asset_models_thunks';
import { DatePicker, Form, Input, Select } from 'antd';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import moment from 'moment';
import { BE_DATE_ONLY_FORMAT } from '../../utils/DataFormatterUtils';
import { nullSafeGet } from '../../utils/DataAccessUtils';

const TransferToLocationFormFields: FC<any> = ({
	assetTypes,
	fetchAssetType,
	assetModels,
	fetchMultipleAssetModels,
	fetchAssetModels,
	fetchAssetTypes,
	fetchMultipleAssetTypes,
	form,
	initialAssetNumber,
	initialAssetTypeId,
	hideAdditionalDetails,
	showTransferDate,
	equipmentTypeModelName,
}): React.ReactElement => {
	const [disableAssetModel, setDissableAssetModel] = useState(true);

	const assetTypeId = Form.useWatch('assetTypeId', form);
	const assetModelId = Form.useWatch('assetModelId', form);

	const { setFieldsValue } = form;

	useEffect(() => {
		assetTypeId &&
			fetchAssetType(assetTypeId).then((assetType) => {
				setFieldsValue({ assetType });
				setDissableAssetModel(false);
			});
	}, [assetTypeId, fetchAssetType, setFieldsValue]);

	useEffect(() => {
		assetTypeId &&
			equipmentTypeModelName &&
			fetchAssetModels({
				assetTypeId,
				modelName: equipmentTypeModelName.trim(),
			}).then((res) => {
				nullSafeGet('0.id', res) && !assetModelId && setFieldsValue({ assetModelId: res[0].id });
			});
	}, [assetModelId, assetTypeId, equipmentTypeModelName, fetchAssetModels, setFieldsValue]);

	// const getRequiredFieldItems = useCallback(() => {
	// 	if (!assetType || !assetType.id) {
	// 		return null;
	// 	}
	// 	return assetType.requiredFields.map((field, idx) => {
	// 		setFieldsValue({
	// 			[`requiredFields-${field.key}-key`]: field.key,
	// 			[`requiredFields-${field.key}-valueType`]: field.type,
	// 		});
	// 		return (
	// 			<Form.Item
	// 				key={`req-fields-${idx}`}
	// 				label={field.key}
	// 				name={`requiredFields-${field.key}-valueAsString`}
	// 				validateTrigger={['onChange', 'onBlur']}
	// 				rules={[{ required: true, message: 'This field is required.' }]}
	// 			>
	// 				<Input style={{ maxWidth: 640 }} />
	// 			</Form.Item>
	// 		);
	// 	});
	// }, [assetType, setFieldsValue]);

	// const getOptionalFieldItems = useCallback(() => {
	// 	if (!assetType || !assetType.id) {
	// 		return null;
	// 	}
	// 	return assetType.optionalFields.map((field, idx) => {
	// 		setFieldsValue({
	// 			[`optionalFields-${field.key}-key`]: field.key,
	// 			[`optionalFields-${field.key}-valueType`]: field.type,
	// 		});
	// 		return (
	// 			<Form.Item
	// 				key={`optional-fields-${idx}`}
	// 				name={`optionalFields-${field.key}-valueAsString`}
	// 				label={field.key}
	// 			>
	// 				<Input style={{ maxWidth: 640 }} />
	// 			</Form.Item>
	// 		);
	// 	});
	// }, [assetType, setFieldsValue]);

	return (
		<>
			<Form.Item hidden name="assetType" />
			{showTransferDate ? (
				<Form.Item name="transferDate" label="Effective transfer date">
					<DatePicker
						style={{ width: '100%' }}
						disabledDate={(current) => current && current > moment()}
						format={BE_DATE_ONLY_FORMAT}
						placeholder={'Effective transfer date'}
					/>
				</Form.Item>
			) : null}
			<Form.Item
				name="assetTypeId"
				label="Asset Type"
				initialValue={initialAssetTypeId}
				rules={[{ required: true, message: 'Please choose an asset type.' }]}
			>
				<OWAsyncSelect
					style={{ width: '100%' }}
					stateSlice={assetTypes}
					valueAccessor={(el) => el.id}
					targetCollectionName="assetTypesIndex"
					fetchData={(
						name,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
					) =>
						fetchAssetTypes(
							{
								name: name || undefined,
							},
							targetCollectionName,
							pagination,
							sorting,
							filters,
							addToTargetCollection
						)
					}
					fetchMultiple={(ids, targetCollectionName) => {
						fetchMultipleAssetTypes(ids, targetCollectionName);
					}}
					renderRecord={(at) => (
						<Select.Option key={at.id} value={at.id}>
							{at.name}
						</Select.Option>
					)}
				/>
			</Form.Item>
			{!hideAdditionalDetails ? (
				<>
					<Form.Item initialValue={initialAssetNumber} name="assetNumber" label="Asset Number">
						<Input />
					</Form.Item>
					<Form.Item name="assetModelId" label="Asset Model">
						<OWAsyncSelect
							style={{ width: '100%' }}
							targetCollectionName="ASSET_MODELS_AUTOCOMPLETE"
							stateSlice={assetModels}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleAssetModels(ids, targetCollectionName);
							}}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchAssetModels(
									{
										name: searchText,
										assetTypeId,
									},
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							placeholder={'Select Model'}
							renderRecord={(model) => (
								<Select.Option key={model.id} value={model.id}>
									{model.modelName}
								</Select.Option>
							)}
							disabled={disableAssetModel}
							additionalFilters={{ assetTypeId }}
							sortBy={{ sort_by: 'name', order: 'ascend' }}
						/>
					</Form.Item>
				</>
			) : null}
			{/* {getRequiredFieldItems()}
    {getOptionalFieldItems()} */}
		</>
	);
};

const mapStateToProps = (state, ownProps) => ({
	onSuccess: ownProps.onSuccess,

	assetTypesFetching: state.asset_types.fetching,
	assetTypes: state.asset_types,
	assetModelsFetching: state.asset_models.fetching,
	assetModels: state.asset_models,
	creating: state.assets.creating,
	updating: state.assets.updating,
});

const mapDispatchToProps = (dispatch) => ({
	fetchAssetType: (id) => dispatch(assetTypesRestCrudThunksForSupplier.readOne(id)),
	fetchAssetTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			assetTypesRestCrudThunksForSupplier.readLite(
				{ ...params, no_pagination: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleAssetTypes: (ids, targetCollectionName) =>
		dispatch(assetTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchAssetModel: (id) => dispatch(assetModelsRestCrudThunksForSupplier.readOne(id)),
	fetchAssetModels: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			assetModelsRestCrudThunksForSupplier.readLite(
				{ ...params, no_pagination: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleAssetModels: (ids, targetCollectionName) =>
		dispatch(assetModelsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(TransferToLocationFormFields)
);

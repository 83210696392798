import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router';
import { Layout } from 'antd';
import ScrollToTopOnMount from '../scroll_to_top_on_mount/ScrollToTopOnMount';
import SubnavTabs from '../subnav_tabs/SubnavTabs';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { CONFIG_NAMES } from '../../utils/AuthUtils';
import { isSupplierInventoryConfigEnabled } from '../common/SupplierInventoryConfigPermissionChecker';
const { Content } = Layout;
const style = require('./PurchaseRequestsOverviewPage.less');

class PurchaseRequestsOverviewPage extends React.Component<any, any> {
	handleTabChange = (key) => {
		const { history } = this.props;
		history.push(`/supplier/purchaseRequests/overview/${key}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	render() {
		const { companyConfig } = this.props;

		const poDisabled = isSupplierInventoryConfigEnabled(
			companyConfig,
			CONFIG_NAMES.DISABLE_PURCHASE_ORDERS
		);

		const tabs = [
			{
				key: 'all',
				name: 'All',
			},
			{
				key: 'requests',
				name: 'Request List',
			},
			...(!poDisabled
				? [
						{
							key: 'approved',
							name: 'Approved Requests',
						},
						{
							key: 'readyToOrder',
							name: 'Ready To Order',
						},
				  ]
				: []),
		];

		return (
			<div>
				<ScrollToTopOnMount />
				<div>
					<Route
						path="/supplier/purchaseRequests/overview"
						exact={true}
						render={(props) => (
							<Redirect
								to={{
									pathname: '/supplier/purchaseRequests/overview/all',
								}}
							/>
						)}
					/>
					<Content className="purchaseRequestsPage" style={{ padding: '0 0.5em' }}>
						{/*<ScrollToTopOnMount/>*/}
						{/*<BackTop/>*/}

						<div
							style={{
								margin: '0 -8px',
								padding: '24px',
								paddingBottom: 0,
								background: nullSafeGet('config.theme.navblock', companyConfig),
								marginBottom: 8,
							}}
						>
							<h4
								style={{
									color: 'rgba(255, 255, 255, 0.9)',
									marginRight: 8,
									marginBottom: 24,
								}}
							>
								Purchase Requests
							</h4>
							<SubnavTabs
								tabs={tabs}
								onChange={this.handleTabChange}
								isActive={this.checkTabActive}
							/>
						</div>
						{this.props.childRoutes.map((r, i) => (
							<RouteWithSubRoutes key={i} {...r} />
						))}
					</Content>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	companyConfig: state.company_config.detail,
});
const mapDispatchToProps = (dispatch) => ({});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(PurchaseRequestsOverviewPage)
);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
	changeFilterValueToArrayOfIds,
	changeFilterValueToArrayOfValues,
	findRecord,
	getAllLeafNodes,
	getEndDateFieldName,
	getFilterFromConfig,
	getStartDateFieldName,
	getTupleNameFieldName,
	getTupleValueFieldName,
	isMatchingField,
	nullSafeGet,
} from '../../utils/DataAccessUtils';
import {
	FILTER_CONFIG_NAMES,
	FILTER_FIELD_TYPE,
	FILTER_VALUE_TYPE,
	ROLE_TYPES,
	multiValueFilterFieldNames,
} from '../../utils/DataConstants';
import { findIfDateRangeLabelAvailable } from '../../utils/DataFormatterUtils';
import OneLineComponent from '../common/OneLineComponent';
import EachFilterPill from './EachFilterPill';
import moment from 'moment';
import innerText from 'react-innertext';
import {
	fetchLocationsWithBuyerCompaniesForSupplier,
	fetchLocationsWithRegionsAndSubRegionsForBuyer,
	locationsRestCrudThunksForBuyer,
	locationsRestCrudThunksForSupplier,
} from '../../thunks/locations_thunks';
import { connect } from 'react-redux';
import {
	assetTypesRestCrudThunksForBuyer,
	assetTypesRestCrudThunksForSupplier,
} from '../../thunks/asset_types_thunks';
import {
	workOrderPrioritiesRestCrudThunksForBuyer,
	workOrderPrioritiesRestCrudThunksForSupplier,
} from '../../thunks/work_order_priorities_thunks';
import {
	problemTypesHeirarchicalForBuyer,
	problemTypesHeirarchicalForSupplier,
	problemTypesRestCrudThunksForBuyer,
	problemTypesRestCrudThunksForSupplier,
} from '../../thunks/problem_types_thunks';
import {
	supplierFacilitiesRestCrudThunksForBuyer,
	supplierFacilitiesRestCrudThunksForSupplier,
} from '../../thunks/supplier_facilities_thunks';
import {
	assetsRestCrudThunksForBuyer,
	assetsRestCrudThunksForSupplier,
} from '../../thunks/assets_thunks';
import {
	plannedMaintenanceTemplatesRestCrudThunksForBuyer,
	plannedMaintenanceTemplatesRestCrudThunksForSupplier,
} from '../../thunks/planned_maintenance_templates_thunks';
import {
	resolutionTypesRestCrudThunksForBuyer,
	resolutionTypesRestCrudThunksForSupplier,
} from '../../thunks/resolution_types_thunks';
import {
	fieldTechsRestCrudThunksForBuyer,
	fieldTechsRestCrudThunksForSupplier,
} from '../../thunks/field_techs_thunks';
import {
	spendCategoriesRestCrudThunksForBuyer,
	spendCategoriesRestCrudThunksForSupplier,
} from '../../thunks/spend_categories_thunks';
import {
	locationTypesRestCrudThunksForBuyer,
	locationTypesRestCrudThunksForSupplier,
} from '../../thunks/location_types_thunks';
import {
	brandsRestCrudThunksForBuyer,
	brandsRestCrudThunksForSupplier,
} from '../../thunks/brands_thunks';
import {
	regionsRestCrudThunksForBuyer,
	regionsRestCrudThunksForSupplier,
} from '../../thunks/regions_thunks';
import {
	plannedMaintenanceSchedulesRestCrudThunksForBuyer,
	plannedMaintenanceSchedulesRestCrudThunksForSupplier,
} from '../../thunks/planned_maintenance_schedules_thunks';
import {
	equipmentTypesRestCrudThunksForBuyer,
	equipmentTypesRestCrudThunksForSupplier,
} from '../../thunks/equipment_types_thunks';
import {
	partsRestCrudThunksForBuyer,
	partsRestCrudThunksForSupplier,
} from '../../thunks/parts_thunks';
import {
	stockLocationsRestCrudThunksForBuyer,
	stockLocationsRestCrudThunksForSupplier,
} from '../../thunks/stock_locations_thunks';

interface AdvancedFilterDisplayProps {
	updateFilters: any;
	filterValues: any;
	filterConfig: any;
	fetchMultipleLocations: any;
	fetchMultipleAssetTypes: any;
	fetchMultipleWOPriorities: any;
	fetchMultipleProblemTypes: any;
	fetchMultipleSupplierFacilities: any;
	fetchMultipleAssets: any;
	fetchMultiplePMs: any;
	fetchMultipleFieldTechs: any;
	fetchMultipleSpendCategories: any;
	fetchMultipleResolutionTypes: any;
	fetchMultipleBrands: any;
	fetchMultipleRegions: any;
	fetchMultipleLocationTypes: any;

	fetchLocationsWithHierarchy: any;
	fetchProblemTypesHierarchical: any;
	fetchMultiplePMSchedules: any;
	fetchMultipleEquipmentTypes: any;
	fetchMultipleParts: any;
	fetchMultipleStockLocations: any;
}

interface EachRecord {
	id: string;
	displayText: string;
	fieldName: string;
	value: string;
	type: string;
}

const defaultValueAccessor = (obj) => `${obj.id}`;
const getId = (fieldName, value) => `${fieldName}-${value}`;
const DATE_DISPLAY_FORMAT = 'MMM D, YYYY';

const convertToArray = (filter) => (values) =>
	filter.valueType === FILTER_VALUE_TYPE.STRING
		? changeFilterValueToArrayOfValues(values)
		: changeFilterValueToArrayOfIds(values);

const getDisplayTextFromRenderRecord = (element) => innerText(element);

const SelectedFilters: FC<AdvancedFilterDisplayProps> = ({
	updateFilters,
	filterValues,
	filterConfig,
	fetchMultipleLocations,
	fetchMultipleAssetTypes,
	fetchMultipleWOPriorities,
	fetchMultipleProblemTypes,
	fetchMultipleSupplierFacilities,
	fetchMultipleAssets,
	fetchMultiplePMs,
	fetchMultipleFieldTechs,
	fetchMultipleSpendCategories,
	fetchMultipleResolutionTypes,
	fetchMultipleBrands,
	fetchMultipleRegions,
	fetchMultipleLocationTypes,

	fetchLocationsWithHierarchy,
	fetchProblemTypesHierarchical,
	fetchMultiplePMSchedules,
	fetchMultipleEquipmentTypes,
	fetchMultipleParts,
	fetchMultipleStockLocations,
}): React.ReactElement => {
	const [pills, setPills] = useState<EachRecord[]>([]);
	const [_, setFetchingInitiatedFieldNames] = useState({});

	const multipValueApiMap = useMemo(
		() => ({
			[FILTER_CONFIG_NAMES.LOCATION]: fetchMultipleLocations,
			[FILTER_CONFIG_NAMES.ASSET_TYPE]: fetchMultipleAssetTypes,
			[FILTER_CONFIG_NAMES.PRIORITY]: fetchMultipleWOPriorities,
			[FILTER_CONFIG_NAMES.PROBLEM_TYPE]: fetchMultipleProblemTypes,
			[FILTER_CONFIG_NAMES.SUPPLIER]: fetchMultipleSupplierFacilities,
			[FILTER_CONFIG_NAMES.ASSET]: fetchMultipleAssets,
			[FILTER_CONFIG_NAMES.PM_TEMPLATE]: fetchMultiplePMs,
			[FILTER_CONFIG_NAMES.TECHNICIAN]: fetchMultipleFieldTechs,
			[FILTER_CONFIG_NAMES.TRADE]: fetchMultipleSpendCategories,
			[FILTER_CONFIG_NAMES.RESOULTION_TYPE]: fetchMultipleResolutionTypes,
			[FILTER_CONFIG_NAMES.BRAND]: fetchMultipleBrands,
			[FILTER_CONFIG_NAMES.REGION]: fetchMultipleRegions,
			[FILTER_CONFIG_NAMES.LOCAATION_TYPE]: fetchMultipleLocationTypes,
			[FILTER_CONFIG_NAMES.PM_SCHEDULE]: fetchMultiplePMSchedules,
			[FILTER_CONFIG_NAMES.EQUIPMENT_TYPE]: fetchMultipleEquipmentTypes,
			[FILTER_CONFIG_NAMES.PART]: fetchMultipleParts,
			[FILTER_CONFIG_NAMES.STOCK_LOCATION]: fetchMultipleStockLocations,
		}),
		[]
	);

	const apiMap = useMemo(
		() => ({
			[FILTER_CONFIG_NAMES.LOCATION]: fetchLocationsWithHierarchy,
			[FILTER_CONFIG_NAMES.PROBLEM_TYPE]: fetchProblemTypesHierarchical,
		}),
		[]
	);

	useEffect(() => {
		if (filterConfig) {
			const apiNeedFieldNames = filterConfig.reduce((acc, filter) => {
				if (filter.stateSlice) {
					const filterValue = filterValues[filter.fieldName];
					let selectedValues = filterValue
						? multiValueFilterFieldNames.includes(filter.fieldName)
							? convertToArray(filter)(filterValue)
							: [filterValue]
						: [];

					const records = filter.stateSlice.records;
					if (Object.values(records).length === 0 && selectedValues.length > 0) {
						return [
							...acc,
							{
								fieldName: filter.fieldName,
								selectedValues,
								targetCollectionName: filter.targetCollectionName,
							},
						];
					}
				}
				return acc;
			}, []);
			setFetchingInitiatedFieldNames((fieldNames) => {
				const notInitiatedYet = apiNeedFieldNames.filter((_) => !fieldNames[_.fieldName]);
				notInitiatedYet.map((field) => {
					const { fieldName, selectedValues, targetCollectionName } = field;
					apiMap[fieldName]
						? apiMap[fieldName]({}, targetCollectionName)
						: multipValueApiMap[fieldName] &&
						  multipValueApiMap[fieldName](selectedValues, targetCollectionName);
				});
				return {
					...fieldNames,
					...notInitiatedYet.reduce((acc, fn) => ({ ...acc, [fn.fieldName]: true }), []),
				};
			});
		}
	}, [filterConfig, filterValues]);

	const clearGeneralFilter = useCallback(
		(fieldName, val) => {
			const filter = getFilterFromConfig(filterConfig, fieldName);
			const valueAccessor = filter.valueAccessor || defaultValueAccessor;
			let record = filter.stateSlice.records[val];
			if (!record) {
				const allKeys = Object.keys(filter.stateSlice.records);
				for (let i = 0; i < allKeys.length; i++) {
					if (filter.stateSlice.records[allKeys[i]].children) {
						record = findRecord(filter.stateSlice.records[allKeys[i]].children, valueAccessor, val);
						if (record) break;
					}
				}
			}

			const finalValues =
				record && record.children && record.children.length > 0
					? getAllLeafNodes(record.children).map((_) => valueAccessor(_))
					: [val];

			const existingFilterValue = filterValues[fieldName];
			const filteredArray = multiValueFilterFieldNames.includes(fieldName)
				? convertToArray(filter)(existingFilterValue).filter((_) => !finalValues.includes(_))
				: [];
			const newVal = filteredArray.length > 0 ? filteredArray.join(',') : undefined;
			updateFilters({ [fieldName]: newVal });
		},
		[updateFilters, filterConfig, filterValues]
	);

	const clearRadioGroupFilter = useCallback(
		(fieldName) => {
			updateFilters({ [fieldName]: undefined });
		},
		[updateFilters]
	);

	const clearOWMultiSelectFilter = useCallback(
		(fieldName, val) => {
			const filter = getFilterFromConfig(filterConfig, fieldName);
			const existingFilterValue = filterValues[fieldName];
			updateFilters({
				[fieldName]:
					filter.mode === 'multiple'
						? existingFilterValue
								.split(',')
								.filter((_) => _ !== val)
								.join(',')
						: undefined,
			});
		},
		[filterConfig, filterValues, updateFilters]
	);

	const clearDateRangeFilter = useCallback(
		(fieldName) =>
			updateFilters({
				[getStartDateFieldName(fieldName)]: undefined,
				[getEndDateFieldName(fieldName)]: undefined,
			}),
		[updateFilters]
	);

	const clearTupleFilter = useCallback(
		(fieldName) =>
			updateFilters({
				[getTupleNameFieldName(fieldName)]: undefined,
				[getTupleValueFieldName(fieldName)]: undefined,
			}),
		[updateFilters]
	);

	const clearFilter = useCallback(
		(fieldName, val, type) => {
			switch (type) {
				case FILTER_FIELD_TYPE.RADIO_GROUP:
					clearRadioGroupFilter(fieldName);
					break;
				case FILTER_FIELD_TYPE.OW_MULTI_SELECT:
					clearOWMultiSelectFilter(fieldName, val);
					break;
				case FILTER_FIELD_TYPE.DATE_RANGE:
					clearDateRangeFilter(fieldName);
					break;
				case FILTER_FIELD_TYPE.TUPLE:
					clearTupleFilter(fieldName);
					break;
				case FILTER_FIELD_TYPE.SEARCH:
					updateFilters({ [fieldName]: undefined });
					break;
				default:
					clearGeneralFilter(fieldName, val);
			}
		},
		[
			clearDateRangeFilter,
			clearGeneralFilter,
			clearOWMultiSelectFilter,
			clearRadioGroupFilter,
			clearTupleFilter,
			updateFilters,
		]
	);

	const getDefaultFilterValues = useCallback(
		(filter) => {
			const valueAccessor = filter.valueAccessor || defaultValueAccessor;
			const filterValue = filterValues[filter.fieldName];

			let selectedValues = filterValue
				? multiValueFilterFieldNames.includes(filter.fieldName)
					? convertToArray(filter)(filterValue)
					: [filterValue]
				: [];

			const isAllChildrenSelected = (records) => {
				let selected = true;
				for (let i = 0; i < records.length; i++) {
					const record = records[i];
					if (record.children && record.children.length > 0) {
						selected = isAllChildrenSelected(record.children);
						if (!selected) break;
					} else if (!selectedValues.includes(valueAccessor(record))) {
						selected = false;
						break;
					}
				}

				return selected;
			};

			const filterParentsOnly = (selectedValues, record) => {
				let newSelectedValues = [...selectedValues];
				if (record.children && record.children.length > 0) {
					for (let i = 0; i < record.children.length; i++) {
						const child = record.children[i];
						if (child.children && child.children.length > 0) {
							newSelectedValues = filterParentsOnly(newSelectedValues, child);
						}
					}
					if (isAllChildrenSelected(record.children)) {
						const childrenValues = record.children.map((_) => valueAccessor(_));
						newSelectedValues = newSelectedValues.filter((val) => !childrenValues.includes(val));
						newSelectedValues = Array.from(new Set([...newSelectedValues, valueAccessor(record)]));
					}
				}

				return newSelectedValues;
			};

			const records = filter.stateSlice.records;
			Object.keys(records).map((key) => {
				const record = records[key];
				selectedValues = filterParentsOnly(selectedValues, record);
				return undefined;
			});

			const getAllRecords = (records) => {
				let flattenedRecords = records.map((record) => ({ ...record, children: undefined }));
				records.map((record) => {
					if (record.children && record.children.length > 0) {
						flattenedRecords = [...flattenedRecords, ...getAllRecords(record.children)];
					}
					return undefined;
				});

				return flattenedRecords;
			};

			const allRecords = Object.keys(records).reduce((acc, key) => {
				const val = records[key];
				const newArray = [...acc, { ...val, children: undefined }];
				return val.children && val.children.length > 0
					? [...newArray, ...getAllRecords(val.children)]
					: newArray;
			}, []);

			return selectedValues
				.map((val) => allRecords.find((_) => valueAccessor(_) === val))
				.filter((r) => r && valueAccessor(r));
		},
		[filterValues]
	);

	const getRadioGroupValue = useCallback(
		(filter) => {
			const filterValue = filterValues[filter.fieldName];
			const selectedRecord = filter.items.find((_) => `${_.value}` === `${filterValue}`);

			return filterValue !== undefined ? [selectedRecord] : [];
		},
		[filterValues]
	);

	const getOWMultiSelectValue = useCallback(
		(filter) => {
			const filterValue = filterValues[filter.fieldName];
			const allValues = filterValue ? filterValue.split(',') : [];
			const selectedRecords = filter.items.filter((_) => allValues.includes(`${_.value}`));

			return filterValue !== undefined ? selectedRecords : [];
		},
		[filterValues]
	);

	const getDateRangeValue = useCallback(
		(filter) => {
			const startDateFieldName = getStartDateFieldName(filter.fieldName);
			const endDateFieldName = getEndDateFieldName(filter.fieldName);
			const startDate =
				filterValues[startDateFieldName] && moment(filterValues[startDateFieldName]);
			const endDate = filterValues[endDateFieldName] && moment(filterValues[endDateFieldName]);
			if (startDate && endDate) {
				const dateRangeLabel = findIfDateRangeLabelAvailable(startDate, endDate);
				return dateRangeLabel
					? [`${filter.labelPrefix ? `${filter.labelPrefix} in` : ''} ${dateRangeLabel}`]
					: [
							`${filter.labelPrefix ? `${filter.labelPrefix} between` : ''} ${startDate.format(
								DATE_DISPLAY_FORMAT
							)} and ${endDate.format(DATE_DISPLAY_FORMAT)}`,
					  ];
			} else if (startDate) {
				return [
					`${filter.labelPrefix ? `${filter.labelPrefix} after` : ''} ${startDate.format(
						DATE_DISPLAY_FORMAT
					)}`,
				];
			} else if (endDate) {
				return [
					`${filter.labelPrefix ? `${filter.labelPrefix} before` : ''} ${endDate.format(
						DATE_DISPLAY_FORMAT
					)}`,
				];
			} else return [];
		},
		[filterValues]
	);

	const getTupleValue = useCallback(
		(filter) => {
			const keyName = getTupleNameFieldName(filter.fieldName) || '';
			const valueName = getTupleValueFieldName(filter.fieldName) || '';

			const labelRecord = getDefaultFilterValues({
				...filter,
				fieldName: keyName,
			});
			const labelStr = labelRecord && labelRecord.length > 0 && nullSafeGet('0.name', labelRecord);
			const valueStr = filterValues[valueName];
			return labelStr && valueStr ? [`${labelStr} : ${valueStr}`] : [];
		},
		[filterValues, getDefaultFilterValues]
	);

	const getDisplayText = useCallback((filter, selectedRecord) => {
		if (filter.type === FILTER_FIELD_TYPE.TUPLE) {
			return selectedRecord;
		} else {
			return `${!filter.labelPrefix && filter.label ? `${filter.label}: ` : ''}${
				filter.renderRecord
					? getDisplayTextFromRenderRecord(filter.renderRecord(selectedRecord))
					: filter.renderItem(selectedRecord)
			}`;
		}
	}, []);

	const allFilterDisplays: React.ReactElement[] = useMemo(
		() =>
			[...pills]
				.reverse()
				.map((pill) => <EachFilterPill key={pill.id} {...pill} clearFilter={clearFilter} />),
		[pills, clearFilter]
	);

	const getSelectedRecords = useCallback(
		(filter) => {
			switch (filter.type) {
				case FILTER_FIELD_TYPE.RADIO_GROUP:
					return getRadioGroupValue(filter);
				case FILTER_FIELD_TYPE.OW_MULTI_SELECT:
					return getOWMultiSelectValue(filter);
				case FILTER_FIELD_TYPE.DATE_RANGE:
					return getDateRangeValue(filter);
				case FILTER_FIELD_TYPE.TUPLE:
					return getTupleValue(filter);
				case FILTER_FIELD_TYPE.SEARCH:
					return filterValues[filter.fieldName] !== undefined
						? [filterValues[filter.fieldName]]
						: [];
				default:
					return getDefaultFilterValues(filter);
			}
		},
		[
			getRadioGroupValue,
			getOWMultiSelectValue,
			getDateRangeValue,
			getTupleValue,
			filterValues,
			getDefaultFilterValues,
		]
	);

	const getValueForId = useCallback((filter, selectedRecord) => {
		switch (filter.type) {
			case FILTER_FIELD_TYPE.TUPLE:
				return selectedRecord;
			default:
				return filter.valueAccessor && filter.valueAccessor(selectedRecord);
		}
	}, []);

	useEffect(() => {
		filterConfig.map((filter) => {
			const selectedRecords = getSelectedRecords(filter);
			const fieldName = filter.fieldName;

			const activePillData = selectedRecords.map((selectedRecord) => {
				const displayText = getDisplayText(filter, selectedRecord);
				const value = getValueForId(filter, selectedRecord);
				const id = getId(fieldName, value);
				return {
					id,
					displayText,
					value,
					fieldName,
					type: filter.type,
				};
			});

			setPills((pills) => {
				const activeIds = activePillData.map((_) => _.id);
				const filteredPills = pills.filter((_) =>
					isMatchingField(_.id, fieldName) ? activeIds.includes(_.id) : true
				);
				return activePillData.reduce(
					(newPills, pillData) =>
						newPills.find((_) => _.id === pillData.id) ? [...newPills] : [...newPills, pillData],
					filteredPills
				);
			});

			return undefined;
		});
	}, [
		filterConfig,
		clearFilter,
		clearRadioGroupFilter,
		getDisplayText,
		getRadioGroupValue,
		getSelectedRecords,
		getValueForId,
	]);

	const allTagText = useMemo(() => [...pills].reverse().map((_) => _.displayText), [pills]);

	if (allFilterDisplays.length < 1) return null;

	return (
		<div className="flex w-full flex-row">
			{allFilterDisplays.length > 0 ? (
				<OneLineComponent
					style={{}}
					childrenToRender={allFilterDisplays}
					childrenText={allTagText}
					fontSize={14}
					additionalWidth={46}
				/>
			) : (
				<></>
			)}
		</div>
	);
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: locationsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchMultipleAssetTypes: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: assetTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchMultipleWOPriorities: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? workOrderPrioritiesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: workOrderPrioritiesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchMultipleProblemTypes: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? problemTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: problemTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchMultipleSupplierFacilities: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? supplierFacilitiesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: supplierFacilitiesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchMultipleAssets: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: assetsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchMultiplePMs: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? plannedMaintenanceTemplatesRestCrudThunksForSupplier.readMultiple(
						ids,
						targetCollectionName
				  )
				: plannedMaintenanceTemplatesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchLocationsWithHierarchy: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? fetchLocationsWithBuyerCompaniesForSupplier(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: fetchLocationsWithRegionsAndSubRegionsForBuyer(
						{ ...params, noIsActiveFilter: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchProblemTypesHierarchical: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? problemTypesHeirarchicalForSupplier(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: problemTypesHeirarchicalForBuyer(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultiplePMSchedules: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? plannedMaintenanceSchedulesRestCrudThunksForSupplier.readMultiple(
						ids,
						targetCollectionName
				  )
				: plannedMaintenanceSchedulesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),

	fetchMultipleFieldTechs: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? fieldTechsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: fieldTechsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchMultipleSpendCategories: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? spendCategoriesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: spendCategoriesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchMultipleResolutionTypes: (ids, targetCollectionName) => {
		const fnToCall =
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? resolutionTypesRestCrudThunksForSupplier
				: resolutionTypesRestCrudThunksForBuyer;
		return dispatch(fnToCall.readMultiple(ids, targetCollectionName));
	},
	fetchMultipleLocationTypes: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: locationTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchMultipleBrands: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? brandsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: brandsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchMultipleRegions: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? regionsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: regionsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchMultipleEquipmentTypes: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? equipmentTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: equipmentTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchMultipleParts: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? partsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: partsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchMultipleStockLocations: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? stockLocationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: stockLocationsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
});

const ComponentWithoutUserType = connect(null, mapDispatchToProps)(SelectedFilters);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import { Button, Drawer, Empty, List, message, Row, Typography } from 'antd';
import ReactDragListView from 'react-drag-listview';
import React, { FC, useEffect, useState } from 'react';
import AddSupplierList from './AddSupplierList';
import { TextButton } from '../text_button/TextButton';
import CopyRankingComponent from './CopyRankingComponent';
import ProblemTypeItemRow from './ProblemTypeItemRow';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import {
	createSupplierPrivateNetworkBulkForBuyer,
	createSupplierPrivateNetworkBulkForSupplier,
} from '../../thunks/supplier_private_networks_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { nullSafeGet } from '../../utils/DataAccessUtils';

const ProblemTypeItem: FC<any> = ({
	selectedLocation,
	data,
	fetchSupplierPrivateNetworkV2,
	refreshData,
	updateRank,
	updateSupplierPrivateNetworkBulk,
}) => {
	const { supplierPrivateNetworkSeq, problemType } = data;
	const [drawer, setDrawer] = useState(false);
	const [copyModal, setCopyModal] = useState(false);
	const [supplierPrivateNetworkSeqCopy, setsupplierPrivateNetworkSeqCopy] = useState(
		JSON.parse(
			JSON.stringify(
				supplierPrivateNetworkSeq.sort((a, b) => {
					return a.rank - b.rank;
				})
			)
		)
	);
	useEffect(() => {
		setsupplierPrivateNetworkSeqCopy(
			JSON.parse(
				JSON.stringify(
					supplierPrivateNetworkSeq.sort((a, b) => {
						return a.rank - b.rank;
					})
				)
			)
		);
	}, [supplierPrivateNetworkSeq]);

	return (
		<div className="PrivateNetworkLocationsPage__secondaryEntity__list">
			<CopyRankingComponent
				visible={copyModal}
				onCancel={() => setCopyModal(false)}
				selectedLocation={selectedLocation}
				onSuccess={() => {
					refreshData();
					setCopyModal(false);
				}}
				problemType={problemType}
				originalRankings={supplierPrivateNetworkSeqCopy}
			/>
			<List
				// loading={loading}
				header={
					<Row align="middle" justify="space-between" className="list__header">
						<div>
							<Typography.Text strong className="grabbable">
								{problemType.hierarchyName}
							</Typography.Text>
							<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_SUPPLIERS}>
								<Button
									type="ghost"
									className=" ml-2"
									size="small"
									onClick={() => setCopyModal(true)}
								>
									Copy From
								</Button>
							</AccessPermissionChecker>
						</div>
						<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_SUPPLIERS}>
							<Button
								className="list_button"
								size="small"
								type="link"
								onClick={() => setDrawer(true)}
							>
								Add Supplier
							</Button>
						</AccessPermissionChecker>
					</Row>
				}
				className="draggable"
			>
				<ReactDragListView
					nodeSelector="div.draggable-item"
					lineClassName="dragLine"
					onDragEnd={(fromIndex, toIndex) => {
						const originalOrder = supplierPrivateNetworkSeqCopy;
						const reorder = (list, startIndex, endIndex) => {
							const result = Array.from(list);
							const [removed] = result.splice(startIndex, 1);
							result.splice(endIndex, 0, removed);
							return result;
						};
						const supplierPrivateNetworkSeqnew = reorder(
							supplierPrivateNetworkSeqCopy,
							fromIndex,
							toIndex
						);
						setsupplierPrivateNetworkSeqCopy(
							supplierPrivateNetworkSeqnew.map((item: any, index) => ({
								...item,
								rank: index + 1,
							}))
						);
						const changeOrder = () => {
							const entities = supplierPrivateNetworkSeqnew.map((item: any, index) => ({
								...item,
								rank: index + 1,
							}));
							updateSupplierPrivateNetworkBulk(entities);
						};

						const changeOrderTimeout = setTimeout(changeOrder, 4000);
						const cancelCall = () => clearTimeout(changeOrderTimeout);

						const hideUndo = message.success(
							<span>
								Updating the rank of the suppliers
								<TextButton
									className="ml-2 cursor-pointer text-buyer underline"
									onClick={() => {
										cancelCall();
										hideUndo();
										setsupplierPrivateNetworkSeqCopy(originalOrder);
									}}
								>
									Undo
								</TextButton>
							</span>
						);
					}}
				>
					<div className="PrivateNetworkLocationsPage__secondaryEntity__list__container">
						{(supplierPrivateNetworkSeqCopy &&
							supplierPrivateNetworkSeqCopy.length &&
							supplierPrivateNetworkSeqCopy.map((item, index) => (
								<ProblemTypeItemRow key={item.id} item={item} index={index} />
							))) || (
							<div className="py-4">
								<Empty
									image={Empty.PRESENTED_IMAGE_SIMPLE}
									description={
										'You haven’t assigned any service providers to this problem type yet'
									}
								/>
							</div>
						)}
					</div>
				</ReactDragListView>
			</List>
			<Drawer
				closable={false}
				placement="right"
				onClose={() => {
					setDrawer(null);
				}}
				visible={drawer}
			>
				<AddSupplierList
					setDrawer={setDrawer}
					fetchAllSuppliers={fetchSupplierPrivateNetworkV2}
					suppliers={supplierPrivateNetworkSeqCopy}
					problemType={problemType}
					selectedItem={{
						...supplierPrivateNetworkSeqCopy[0],
						locationId: nullSafeGet('id', selectedLocation),
						problemTypeId: nullSafeGet('problemTypeId', data),
						rank: undefined,
						supplierFacilityId: undefined,
						supplierFacility: undefined,
					}}
					updateRank={updateRank}
					onSubmit={(data) => {
						setsupplierPrivateNetworkSeqCopy(data);
					}}
				/>
			</Drawer>
		</div>
	);
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	updateSupplierPrivateNetworkBulk: (entities) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? createSupplierPrivateNetworkBulkForSupplier(entities)
				: createSupplierPrivateNetworkBulkForBuyer(entities)
		),
});

const ComponentWithoutUserType = withRouter(connect(null, mapDispatchToProps)(ProblemTypeItem));

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import * as React from 'react';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';

const style = require('./PlannedMaintenanceTemplateRowDisplay.less');

export const PlannedMaintenanceTemplateRowDisplay: React.FC<any> = ({
	plannedMaintenanceTemplate,
}) => {
	if (!plannedMaintenanceTemplate) {
		return null;
	}
	return (
		<div className="plannedMaintenanceTemplateRowDisplay__row">
			<div>
				<div className="plannedMaintenanceTemplateRowDisplay__rowTitle">
					<Ellipsis tooltip={true} length={64}>
						{plannedMaintenanceTemplate.title}
					</Ellipsis>
				</div>
				<div className="plannedMaintenanceTemplateRowDisplay__rowSubtitle">
					<div className="plannedMaintenanceTemplateRowDisplay__rowInlineGroup">
						<i className="icons8-font icons8-maintenance-filled" />
						<span>{nullSafeGet('problemType.hierarchyName', plannedMaintenanceTemplate)}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

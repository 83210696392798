import React, { FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import AssetTemplateForm from '../asset_template_form/AssetTemplateForm';
import {
	assetTypesRestCrudThunksForBuyer,
	assetTypesRestCrudThunksForSupplier,
} from '../../thunks/asset_types_thunks';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { ROLE_TYPES } from '../../utils/DataConstants';

require('./EditAssetTemplatePage.less');

const EditAssetTemplatePage: FC<any> = ({
	getAssetType,
	match,
	history,
	assetTemplate,
	fetching,
	userType,
}): React.ReactElement => {
	const assetTemplateId = useMemo(() => match.params.id, [match.params.id]);

	useEffect(() => getAssetType(assetTemplateId), [assetTemplateId, getAssetType]);

	return (
		<>
			<LogOnMountWithStandardEventProperties eventType="visited edit asset template page" />,
			<EditPage
				title="Edit Asset Type"
				formComponentProps={{
					formData: assetTemplate,
					onSuccess: (rec) => history.push(`/${userType}/assets/templates/detail/${rec.id}`),
				}}
				fetching={fetching}
				FormComponent={AssetTemplateForm}
			/>
			,
		</>
	);
};

const mapStateToProps = (state, ownProps) => {
	return {
		history: ownProps.history,
		assetTemplate: state.asset_types.detail,
		fetching: state.asset_types.fetching,
		match: ownProps.match,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	getAssetType: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetTypesRestCrudThunksForSupplier.readOne(id)
				: assetTypesRestCrudThunksForBuyer.readOne(id)
		),
});

const EditAssetTemplatePageWR = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(EditAssetTemplatePage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(EditAssetTemplatePageWR);

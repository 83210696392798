import React, { FC, useMemo } from 'react';
import { nullSafeGet } from '../../utils/DataAccessUtils';

const PartLocationDisplay: FC<any> = ({ ppsl }): React.ReactElement => {
	const aisle = useMemo(() => nullSafeGet('aisle', ppsl), [ppsl]);

	const bay = useMemo(() => nullSafeGet('bay', ppsl), [ppsl]);

	const level = useMemo(() => nullSafeGet('level', ppsl), [ppsl]);

	const bin = useMemo(() => nullSafeGet('bin', ppsl), [ppsl]);

	return (
		<div className="flex flex-col">
			{aisle && <div>{`Aisle - ${aisle}`}</div>}
			{bay && <div>{`Bay - ${bay}`}</div>}
			{level && <div>{`Level - ${level}`}</div>}
			{bin && <div>{`Bin - ${bin}`}</div>}
			{!aisle && !bay && !level && !bin ? <div>--</div> : null}
		</div>
	);
};

export default PartLocationDisplay;

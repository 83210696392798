import * as React from 'react';
import BuyerChangePasswordForm from '../buyer_change_password_form/BuyerChangePasswordForm';

import { Layout, Row, Col, BackTop, Card } from 'antd';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;
const style = require('./BuyerChangePasswordPage.less');
const style2 = require('../../styles/blah.less');

class BuyerChangePasswordPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Content className="changePasswordPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited buyer change password page" />
				<BackTop />
				<Row align="middle" style={{ height: '100vh', margin: '0 -8px' }} gutter={16}>
					<Col span={12} offset={6}>
						<Card title="Change password">
							<BuyerChangePasswordForm />
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

export default BuyerChangePasswordPage;

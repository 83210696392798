import * as React from 'react';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { Alert, Button, DatePicker, Layout, List, message, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
	projectsRestCrudThunksForBuyer,
	projectsRestCrudThunksForSupplier,
} from '../../thunks/projects_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';

const queryString = require('qs');
const ListItem = List.Item;
const ListItemMeta = ListItem.Meta;
const { Content } = Layout;
const { RangePicker } = DatePicker;
const dateFormat = 'MM-DD-YYYY';

class ProjectsDetailPage extends React.Component<any, any> {
	formRefs: any;

	state = {
		loading: true,
		reopenModalVisible: false,
		cancelModalVisible: false,
		backLinkTo: '/buyer/projects',
		buttonLoading: false,
	};

	constructor(props) {
		super(props);
		this.formRefs = {};
	}

	componentDidMount() {
		const { getProject, location, match, currentUser, userType } = this.props;
		const projectId = match.params.id;
		getProject(userType, projectId).then((project) => {
			let searchString = location.search;
			if (searchString[0] === '?') {
				searchString = searchString.slice(1);
			}
			const queryParams = queryString.parse(searchString);
			if (queryParams.backlinkParams) {
				const { tab, pathName, backLinkText, ...otherParams } = queryString.parse(
					queryParams.backlinkParams
				);
				const finalPath = pathName || `/${userType}/projects/overview/${tab ? tab : 'active'}`;
				this.setState({
					loading: false,
					backLinkTo: `${finalPath}?${new URLSearchParams(otherParams).toString()}`,
				});
			} else {
				this.setState({
					loading: false,
					backLinkTo: `/${userType}/projects/overview/`,
				});
			}
		});
	}

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	handleTabChange = (key) => {
		const { history, match, currentUser, userType } = this.props;
		const projectId = match.params.id;
		history.push(`/${userType}/projects/detail/${projectId}/${key}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	handleDelete = () => {
		const { history, deleteProject, project, currentUser, userType } = this.props;
		this.setState({ buttonLoading: true });
		deleteProject(userType, project)
			.then(() => {
				history.push(this.state.backLinkTo);
			})
			.catch((e) => {
				message.error(`Failed to delete project.`);
			})
			.finally(() => {
				this.setState({ buttonLoading: false });
			});
	};

	handleArchive = () => {
		const { history, archiveProject, project, currentUser, userType } = this.props;
		this.setState({ buttonLoading: true });
		archiveProject(userType, project)
			.then((e) => {
				history.push(this.state.backLinkTo);
			})
			.catch((e) => {
				message.error(`Failed to archive project.`);
			})
			.finally(() => {
				this.setState({ buttonLoading: false });
			});
	};

	handleUnarchive = () => {
		const { history, unarchiveProject, project, currentUser, userType } = this.props;
		this.setState({ buttonLoading: true });
		unarchiveProject(userType, project)
			.then(() => {
				history.push(this.state.backLinkTo);
			})
			.catch((e) => {
				message.error(`Failed to unarchive project.`);
			})
			.finally(() => {
				this.setState({ buttonLoading: false });
			});
	};

	render() {
		const { project, match, currentUser, companyConfig, userType, updateErrors } = this.props;
		const { loading, buttonLoading } = this.state;
		const projectsId = match.params.id;

		const tabs = [
			{
				key: 'details',
				name: 'Overview',
			},
			{
				key: 'workOrders',
				name: 'Work Orders',
			},
			{
				key: 'invoices',
				name: 'Invoices',
			},
		];

		const projectActions = () => {
			let actions = [];
			actions.push(
				<Link
					to={`/${userType}/projects/edit/${projectsId}`}
					style={{ marginRight: 16, marginBottom: 16 }}
				>
					<Button loading={buttonLoading} ghost={true}>
						Edit
					</Button>
				</Link>
			);
			actions.push(
				<Popconfirm
					onConfirm={this.handleDelete}
					placement="topLeft"
					title="Are you sure you want to delete this project?"
					okText="Yes"
					cancelText="No"
				>
					<Button
						loading={buttonLoading}
						ghost={true}
						style={{ marginBottom: 16, marginRight: 16 }}
						key={'Delete'}
					>
						Delete
					</Button>
				</Popconfirm>
			);
			if (!project.isArchived) {
				actions.push(
					<Button
						loading={buttonLoading}
						ghost={true}
						style={{ marginBottom: 16, marginRight: 16 }}
						key={'Archive'}
						onClick={this.handleArchive}
					>
						Archive
					</Button>
				);
			} else {
				actions.push(
					<Button
						loading={buttonLoading}
						ghost={true}
						style={{ marginBottom: 16, marginRight: 16 }}
						key={'Archive'}
						onClick={this.handleUnarchive}
					>
						Unarchive
					</Button>
				);
			}
			return actions;
		};

		return loading ? (
			<PageLoadingPlaceholder />
		) : (
			<div className="workOrdersDetailPage">
				{updateErrors.length > 0 ? <Alert message={updateErrors.join(' ')} type="error" /> : null}
				<DetailPageHeader
					exactPath={`/${userType}/projects/detail/:id/details`}
					redirectPath={`/${userType}/projects/detail/${projectsId}/details`}
					backLinkText={'Back to projects'}
					backLinkTo={this.state.backLinkTo}
					title={
						loading ? null : (
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'flex-start',
									alignItems: 'baseline',
									gap: '16px',
									flexWrap: 'nowrap',
								}}
							>
								<div
									style={{
										display: 'flex',
										marginBottom: '-8px',
									}}
								>
									{nullSafeGet('title', project)}
								</div>
							</div>
						)
					}
					subtitle={<div></div>}
					subtext={<div></div>}
					actions={projectActions()}
					tabs={tabs}
					handleTabChange={this.handleTabChange}
					checkTabActive={this.checkTabActive}
					childRoutes={this.props.childRoutes}
					backgroundColor={nullSafeGet('detail.config.theme.navblock', companyConfig)}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	createErrors: state.projects.createErrors,
	updateErrors: state.projects.updateErrors,
	project: state.projects.detail,
	match: ownProps.match,
	history: ownProps.history,
	currentUser: state.session.currentUser,
	companyConfig: state.company_config,
});

const mapDispatchToProps = (dispatch) => ({
	getProject: (userType, id) =>
		dispatch(
			userType === ROLE_TYPES.SUPPLIER
				? projectsRestCrudThunksForSupplier.readOne(id)
				: projectsRestCrudThunksForBuyer.readOne(id)
		),
	deleteProject: (userType, entity) =>
		dispatch(
			userType === ROLE_TYPES.SUPPLIER
				? projectsRestCrudThunksForSupplier.delete(entity, 'id', null, true)
				: projectsRestCrudThunksForBuyer.delete(entity, 'id', null, true)
		),
	archiveProject: (userType, entity) =>
		dispatch(
			userType === ROLE_TYPES.SUPPLIER
				? projectsRestCrudThunksForSupplier.archive(entity, 'id', true)
				: projectsRestCrudThunksForBuyer.archive(entity, 'id', true)
		),
	unarchiveProject: (userType, entity) =>
		dispatch(
			userType === ROLE_TYPES.SUPPLIER
				? projectsRestCrudThunksForSupplier.unarchive(entity, 'id', true)
				: projectsRestCrudThunksForBuyer.unarchive(entity, 'id', true)
		),
});
const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ProjectsDetailPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

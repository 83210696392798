import React, { FC } from 'react';
import { Layout, Row, Col, Card } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import {
	locationTypesRestCrudThunksForBuyer,
	locationTypesRestCrudThunksForSupplier,
} from '../../thunks/location_types_thunks';
import { TwoLineList } from '../two_line_list/TwoLineList';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { mappingTemplateTypesForDisplay } from '../../utils/DataMappingUtils';
import { ROLE_TYPES } from '../../utils/DataConstants';

const { Content } = Layout;

require('./LocationTypesDetailDetailsPage.less');

const LocationTypesDetailDetailsPage: FC<any> = ({
	userType,
	locationType,
	locationTypesFetching,
}): React.ReactElement => {
	return locationTypesFetching ? (
		<PageLoadingPlaceholder />
	) : (
		<Content className="locationTypesDetailDetailsPage p-2">
			<LogOnMountWithStandardEventProperties
				eventType={`visited ${userType} location type detail page`}
			/>
			<Row style={{ margin: '-0.5em -8px 0.5em -8px' }} gutter={16}>
				<Col span={24}>
					<Card>
						<div className="locationTypesDetailDetailsPage__listSection">
							<div className="locationTypesDetailDetailsPage__listSectionTitle">
								Required Fields
							</div>
							{locationType.requiredFields && locationType.requiredFields.length === 0 ? (
								'No required fields'
							) : (
								<TwoLineList
									listItems={locationType.requiredFields}
									lineOneRender={(record) => record.key}
									lineTwoRender={(record) => mappingTemplateTypesForDisplay(record)}
								/>
							)}
						</div>
						<div className="locationTypesDetailDetailsPage__listSection">
							<div className="locationTypesDetailDetailsPage__listSectionTitle">
								Optional Fields
							</div>
							{locationType.optionalFields && locationType.optionalFields.length === 0 ? (
								'No optional fields'
							) : (
								<TwoLineList
									listItems={locationType.optionalFields}
									lineOneRender={(record) => record.key}
									lineTwoRender={(record) => mappingTemplateTypesForDisplay(record)}
								/>
							)}
						</div>
					</Card>
				</Col>
			</Row>
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	locationTypesFetching: state.location_types.fetching,
	locationType: state.location_types.detail,
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getLocationType: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationTypesRestCrudThunksForSupplier.readOne(id)
				: locationTypesRestCrudThunksForBuyer.readOne(id)
		),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(LocationTypesDetailDetailsPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

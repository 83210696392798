import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Button, Card } from 'antd';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import {
	plannedMaintenanceTemplatesRestCrudThunksForBuyer,
	plannedMaintenanceTemplatesRestCrudThunksForSupplier,
} from '../../thunks/planned_maintenance_templates_thunks';
import { EmptyState } from '../empty_state/EmptyState';
import SubnavBar from '../subnav_bar/SubnavBar';
import { changeFilterValueToArrayOfIds, nullSafeGet } from '../../utils/DataAccessUtils';
import { PlannedMaintenanceTemplateRowDisplay } from '../planned_maintenance_template_row_display/PlannedMaintenanceTemplateRowDisplay';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import * as queryString from 'querystring';
import { AdvancedFilters } from '../advanced_filters/AdvancedFilters';
import ProblemTypeTreeSelect from '../problem_type_tree_select';
import {
	problemTypesRestCrudThunksForBuyer,
	problemTypesRestCrudThunksForSupplier,
} from '../../thunks/problem_types_thunks';
import { PLANNED_MAINTENANCE_TEMPLATES_CRUD_ACTION_CREATORS } from '../../actions/planned_maintenance_templates_actions';
import SelectedFilters from '../selected_filters_pills/SelectedFilters';
import { ROLE_TYPES } from '../../utils/DataConstants';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';

const { Content } = Layout;
require('./PlannedMaintenanceTemplatesIndexPage.less');
const PLANNED_MAINTENANCE_SCHEDULE_TEMPLATES_OVERVIEW_TARGET_COLLECTION =
	'plannedMaintenanceTemplatesIndex';

class PlannedMaintenanceTemplatesIndexPage extends React.Component<any, any> {
	initialPagination: any = { current: 1, pageSize: 10 };
	initialFilters: any = {};
	preAppliedFilters: any = {};

	constructor(props) {
		super(props);
		const { location, history, updatePlannedMaintenanceTemplateFilters, userType } = this.props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		const { current, pageSize, ...plannedMaintenanceTemplateFilters } = queryParams;
		this.initialPagination = {
			...this.initialPagination,
			current: current ? current : this.initialPagination.current,
			pageSize: pageSize ? pageSize : this.initialPagination.pageSize,
		};
		this.initialFilters = plannedMaintenanceTemplateFilters;
		this.state = {
			displayMode: 'list',
		};
		updatePlannedMaintenanceTemplateFilters(
			this.initialFilters,
			PLANNED_MAINTENANCE_SCHEDULE_TEMPLATES_OVERVIEW_TARGET_COLLECTION
		);
		history.push(
			`/${userType}/plannedMaintenance/overview/templates/overview?${new URLSearchParams(
				location.search
			)}`
		);
	}

	handleSizeChange = (current, size) => {
		const { location, history, userType } = this.props;

		let backUrl = `/${userType}/plannedMaintenance/overview/templates/overview`;
		history.push(backUrl);
	};
	switchDisplayMode = (mode) => this.setState({ displayMode: mode });
	isDisplayModeActive = (mode) => this.state.displayMode === mode;

	render() {
		const {
			history,
			location,
			problemTypes,
			plannedMaintenanceTemplates,
			fetchProblemTypes,
			fetchMultipleProblemTypes,
			updatePlannedMaintenanceTemplateFilters,
			clearAndUpdateFilters,
			activeFilters,
			userType,
		} = this.props;

		const filterConfig = [
			{
				label: 'Problem Type',
				mode: 'multiple',
				fieldName: 'problemTypeIds',
				stateSlice: problemTypes,
				filtersValueAccessor: (filtersStateSlice) => {
					const problemTypeIds = nullSafeGet(
						`${PLANNED_MAINTENANCE_SCHEDULE_TEMPLATES_OVERVIEW_TARGET_COLLECTION}.filters.problemTypeIds`,
						filtersStateSlice
					);
					return changeFilterValueToArrayOfIds(problemTypeIds);
				},
				keyAccessor: (problemType) => problemType.id,
				valueAccessor: (problemType) => problemType.id,
				handleChange: (ids) => {
					updatePlannedMaintenanceTemplateFilters(
						{
							problemTypeIds: ids.join(',') || undefined,
						},
						PLANNED_MAINTENANCE_SCHEDULE_TEMPLATES_OVERVIEW_TARGET_COLLECTION
					);
				},
				targetCollectionName: 'plannedMaintenanceAdvancedFilters',
				fetch: fetchProblemTypes,
				fetchMultiple: fetchMultipleProblemTypes,
				renderItem: (problemType) => problemType.hierarchyName,
				sortBy: { sort_by: 'name', order: 'ascend' },
				component: ProblemTypeTreeSelect,
			},
		];

		const popupRenderFunc = (record) => `
            <div>
                <a href="/${userType}/plannedMaintenance/detail/${record.id}">
                    <div class="plannedMaintenanceTemplatesIndexPage__row" style="align-items: flex-start;">
                        <div style="margin-right: 24px;">
                            <div
                                class="plannedMaintenanceTemplatesIndexPage__rowTitle"
                            >
                                ${record.name}
                            </div>
                            <div
                                class="plannedMaintenanceTemplatesIndexPage__rowSubtitle"
                            >
                                    ${nullSafeGet(
																			'buyerFacility.primaryAddress.streetAddress1',
																			record
																		)}
                            </div>
                        </div>
                        <div class="emptorTag plannedMaintenanceTemplatesIndexPage__pill">
                            ${
															record.totalNotClosedWOCount > 0
																? record.totalNotClosedWOCount + ' Open WOs'
																: 'No Open WOs'
														}
                        </div>
                    </div>
                    <div
                        class="plannedMaintenanceTemplatesIndexPage__rowSubtitle"
                    >
                        <div class="plannedMaintenanceTemplatesIndexPage__rowInlineGroup">
                            ${nullSafeGet(
															'buyerFacility.primaryAddress.city',
															record
														)}, ${nullSafeGet(
			'buyerFacility.primaryAddress.region',
			record
		)} ${nullSafeGet('buyerFacility.primaryAddress.postcode', record)}
                        </div>
                    </div>
                </a>
            </div>
        `;

		const columns = [
			{
				title: 'Template Name',
				dataIndex: 'plannedMaintenanceTemplateId',
				render: (text, record) => (
					<PlannedMaintenanceTemplateRowDisplay plannedMaintenanceTemplate={record} />
				),
			},
		];

		const onRow = (record) => ({
			onClick: () => {
				let searchString = location.search;
				if (searchString[0] === '?') {
					searchString = searchString.slice(1);
				}
				let backUrl = `/${userType}/plannedMaintenance/templates/detail/${encodeURIComponent(
					record.id
				)}/details`;
				if (searchString && searchString.length > 0) {
					backUrl = backUrl.concat(`?backlinkParams=${encodeURIComponent(searchString)}`);
				}
				history.push(backUrl);
			},
		});

		return (
			<Content className="plannedMaintenanceTemplatesIndexPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties
					eventType={`visited ${userType} new pm templates index page`}
				/>
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '0.5em -8px' }}>
					<Col span={24}>
						<SubnavBar
							left={
								<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
									<AdvancedFilters
										updateFilters={updatePlannedMaintenanceTemplateFilters}
										filterConfig={filterConfig}
										filtersTargetCollectionName={
											PLANNED_MAINTENANCE_SCHEDULE_TEMPLATES_OVERVIEW_TARGET_COLLECTION
										}
										clearAndUpdateFilters={clearAndUpdateFilters}
										filtersStateSlice={plannedMaintenanceTemplates}
										preAppliedFilters={{}}
										initialFilters={this.initialFilters}
										inputParamName={'title'}
									></AdvancedFilters>
								</div>
							}
							right={
								<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PM}>
									<Button
										size="large"
										type="primary"
										onClick={() =>
											this.props.history.push(`/${userType}/plannedMaintenance/templates/new`)
										}
									>
										Add Template
									</Button>
								</AccessPermissionChecker>
							}
						/>
					</Col>
				</Row>
				<SelectedFilters
					updateFilters={(filters) => {
						updatePlannedMaintenanceTemplateFilters(
							filters,
							PLANNED_MAINTENANCE_SCHEDULE_TEMPLATES_OVERVIEW_TARGET_COLLECTION
						);
					}}
					filterValues={activeFilters}
					filterConfig={filterConfig}
				/>
				<Row style={{ margin: '0.5em -8px' }}>
					<Col span={24}>
						<Card bodyStyle={{ padding: 8 }}>
							<PaginatedReduxTable
								mode={this.state.displayMode}
								updateQueryParams={true}
								longitudeAccessor={(el) => nullSafeGet('buyerFacility.longitude', el)}
								latitudeAccessor={(el) => nullSafeGet('buyerFacility.latitude', el)}
								emptyState={
									<EmptyState
										graphic={
											<img
												style={{ marginBottom: 8 }}
												src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
												alt=""
											/>
										}
										headline={"It's a little lonely in here."}
										body={
											<div style={{ textAlign: 'center' }}>
												<div style={{ maxWidth: 440, marginBottom: 16 }}>
													Looks like your team has not created any planned maintenance templates
													yet.
												</div>
												<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_PM}>
													<Button
														type="primary"
														onClick={() =>
															this.props.history.push(
																`/${userType}/plannedMaintenance/templates/new`
															)
														}
													>
														Add template
													</Button>
												</AccessPermissionChecker>
											</div>
										}
									/>
								}
								collectionName="planned_maintenance_templates"
								targetCollectionName={
									PLANNED_MAINTENANCE_SCHEDULE_TEMPLATES_OVERVIEW_TARGET_COLLECTION
								}
								columns={columns}
								popupRenderFunc={popupRenderFunc}
								showHeader={false}
								keyAccessor={(el) => el.id}
								onRow={onRow}
								initialPagination={this.initialPagination}
								fetchData={
									userType === ROLE_TYPES.SUPPLIER
										? plannedMaintenanceTemplatesRestCrudThunksForSupplier.read
										: plannedMaintenanceTemplatesRestCrudThunksForBuyer.read
								}
							/>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	problemTypes: state.problem_types,
	activeFilters:
		state['planned_maintenance_templates'][
			PLANNED_MAINTENANCE_SCHEDULE_TEMPLATES_OVERVIEW_TARGET_COLLECTION
		].filters,

	plannedMaintenanceTemplates: state.planned_maintenance_templates,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	updatePlannedMaintenanceTemplateFilters: (filters, targetCollection) =>
		dispatch(
			PLANNED_MAINTENANCE_TEMPLATES_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)
		),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			PLANNED_MAINTENANCE_TEMPLATES_CRUD_ACTION_CREATORS.clearAndUpdateFilters(
				filters,
				targetCollectionName
			)
		),
	fetchMultipleProblemTypes: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? problemTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: problemTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchProblemTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? problemTypesRestCrudThunksForSupplier.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: problemTypesRestCrudThunksForBuyer.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
});
const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(PlannedMaintenanceTemplatesIndexPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { VENDORS_CONTACTS_CRUD_ACTION_CREATORS } from '../actions/vendor_contacts_actions';

export const vendorContactsRestCrudThunksForSupplier = new RestCrudThunks(
	'vendor_contacts',
	'vendor_contact',
	'vendorContact',
	'vendorContacts',
	VENDORS_CONTACTS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'inventory/'
);

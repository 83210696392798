import { STOPLIGHT_COLORS } from '../../utils/DataFormatterUtils';

const PRPipeLineFilters = [
	{
		displayName: 'Requested',
		name: 'requested',
		filter: { status: 'requested' },
		icon: 'icons8-edit',
		iconStyle: { color: '#4a1e87' },
	},
	{
		displayName: 'Approved',
		name: 'approved',
		filter: { status: 'approved' },
		icon: 'icons8-approve',
		iconStyle: { color: STOPLIGHT_COLORS.pureYellow },
	},
	{
		displayName: 'Order In Progress',
		name: 'orderInProgress',
		filter: { status: 'orderInProgress' },
		icon: 'icons8-wrench',
		iconStyle: { color: STOPLIGHT_COLORS.yellowGreen },
	},
	{
		displayName: 'Partially Ordered',
		name: 'partially_ordered',
		filter: { status: 'partially_ordered' },
		icon: 'icons8-pause-squared',
		iconStyle: { color: STOPLIGHT_COLORS.pureYellow },
	},
	{
		displayName: 'Ordered',
		name: 'ordered',
		filter: { status: 'ordered' },
		icon: 'icons8-wrench',
		iconStyle: { color: STOPLIGHT_COLORS.yellowGreen },
	},
	{
		displayName: 'Partially Fulfilled',
		name: 'partially_fulfilled',
		filter: { status: 'partially_fulfilled' },
		icon: 'icons8-pause-squared',
		iconStyle: { color: STOPLIGHT_COLORS.pureYellow },
	},
	{
		displayName: 'Fulfilled',
		name: 'fulfilled',
		filter: { status: 'fulfilled' },
		icon: 'icons8-green-check-mark',
		iconStyle: { color: STOPLIGHT_COLORS.pureGreen },
	},
	{
		displayName: 'Cancelled',
		name: 'cancelled',
		filter: { status: 'cancelled' },
		icon: 'icons8-cancel',
		iconStyle: { color: STOPLIGHT_COLORS.pureRed },
	},
	{
		displayName: 'Denied',
		name: 'denied',
		filter: { status: 'denied' },
		icon: 'icons8-cancel',
		iconStyle: { color: STOPLIGHT_COLORS.pureRed },
	},
];

export default PRPipeLineFilters;

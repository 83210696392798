import { Button, ConfigProvider, Table } from 'antd';
import React from 'react';
import QuotesTableRow from '../QuotesTableRow';
import { BUYER_ONLY_NO_STORE_ASSOCIATE } from '../../roles/roles';
import { EmptyState } from '../../empty_state/EmptyState';
import { Link } from 'react-router-dom';
import { nullSafeGet, nullSafeGetOrElse } from '../../../utils/DataAccessUtils';

export default function QuotesListTable({
	fetching,
	data,
	currentUser,
	companyConfig,
	renderQuote,
	refreshTable,
	tableStyle,
	renderEmptyState,
	location,
	userType,
	selectItems,
	enableMultiSelect,
	backText,
	targetCollectionName,
}) {
	const columns = [
		{
			render: (text, record) =>
				renderQuote ? (
					renderQuote(record)
				) : (
					<QuotesTableRow
						record={record}
						location={location}
						userType={userType}
						selections={selectItems}
						backText={backText}
						targetCollectionName={targetCollectionName}
					/>
				),
		},
	];

	return (
		<ConfigProvider
			renderEmpty={() =>
				renderEmptyState ? (
					renderEmptyState(currentUser)
				) : currentUser.contactType === 'buyer' ? (
					(
						<>
							{currentUser.roles &&
							currentUser.roles.some((role) => BUYER_ONLY_NO_STORE_ASSOCIATE.has(role)) ? (
								<EmptyState
									graphic={
										<img
											style={{ marginBottom: 8 }}
											src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
										/>
									}
									headline={"It's a little lonely in here."}
									body={
										<div style={{ textAlign: 'center' }}>
											<div style={{ maxWidth: 440, marginBottom: 16 }}>
												Looks like your team doesn't have any quotes.
											</div>
											<Link to="/buyer/workOrders/newWorkOrder">
												<Button type="primary">Create your first work order</Button>
											</Link>
										</div>
									}
								/>
							) : (
								<EmptyState
									graphic={
										<img
											style={{ marginBottom: 8 }}
											src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
										/>
									}
									headline={"It's a little lonely in here."}
									body={
										<div style={{ textAlign: 'center' }}>
											<div style={{ maxWidth: 440, marginBottom: 16 }}>
												Looks like your team doesn't have any quotes.
											</div>
											<Link
												to={
													[2483, 2489, 2490].includes(
														nullSafeGet('facility.buyerCompanyId', currentUser)
													)
														? '/buyer/workOrders/requestService'
														: '/buyer/workOrders/requestServiceForm'
												}
											>
												<Button type="primary">Create your first service request</Button>
											</Link>
										</div>
									}
								/>
							)}
						</>
					) || undefined
				) : (
					<EmptyState
						graphic={
							<img
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
							/>
						}
						headline={"Looks like your team hasn't created any quotes."}
						body={
							<div style={{ textAlign: 'center' }}>
								<div style={{ maxWidth: 500, marginBottom: 16 }}>
									Create a quote for a work order from the work order detail page.
								</div>
							</div>
						}
					/>
				)
			}
		>
			<Table
				rowClassName={'clickable-index-row'}
				{...(enableMultiSelect
					? { rowSelection: nullSafeGetOrElse('rowSelection', selectItems, {}) }
					: {})}
				rowKey={(record) => {
					return record.id.toString();
				}}
				style={{ ...tableStyle }}
				showHeader={false}
				pagination={false}
				loading={fetching}
				dataSource={data}
				columns={columns}
			/>
		</ConfigProvider>
	);
}

import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { BUDGETS_CRUD_ACTION_CREATORS } from '../actions/budget_actions';
import { getParamsWithPaginationAndSorting } from '../utils/DataAccessUtils';
import FetchUtils, { parseJSON } from './fetch_utils';
import { logoutSuccess } from '../actions/session_actions';
import { LOCATIONS_CRUD_ACTION_CREATORS } from '../actions/locations_actions';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { APPROVAL_RULES_CRUD_ACTION_CREATORS } from '../actions/company_approval_actions';
import { changeBidderAwardStatus } from './bidders_thunks';

export const budgetsRestCrudThunksForBuyer = new RestCrudThunks(
	'budgets',
	'budget',
	'budget',
	'budgets',
	BUDGETS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'budget/'
);
export const budgetsRestCrudThunksForSupplier = new RestCrudThunks(
	'budgets',
	'budget',
	'budget',
	'budgets',
	BUDGETS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'budget/'
);

const fetchUtils = new FetchUtils();
const baseUrl = getBackendUri();

export const getBudgetCycle = (userRole) => (budgetId, params) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(BUDGETS_CRUD_ACTION_CREATORS.fetchStart());

			fetchUtils
				.get(
					baseUrl + `/api/v1/${userRole}/budget/budgets/${budgetId}`,
					Object.assign(
						{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
						params
					)
				)
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(BUDGETS_CRUD_ACTION_CREATORS.fetchError(d.message, budgetId));
						reject(d.message);
					} else {
						dispatch(BUDGETS_CRUD_ACTION_CREATORS.fetchOneSuccess(d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(BUDGETS_CRUD_ACTION_CREATORS.fetchError(d.message, budgetId));
					reject(d.message);
				});
		});
	};
};
export const getBudgetCycleForBuyer = getBudgetCycle(ROLE_TYPES.BUYER);

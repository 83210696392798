export const WORK_ORDER_DISPLAY_STATUSES = {
	pending: 'Pending',
	open: 'Open',
	inProgress: 'InProgress',
	hold: 'OnHold',
	complete: 'Completed',
	cancelled: 'Cancelled',
};

export const FINISHED_WORK_ORDER_DISPLAY_STATUSES = [
	WORK_ORDER_DISPLAY_STATUSES.complete,
	WORK_ORDER_DISPLAY_STATUSES.cancelled,
];

export const WORK_ORDER_DISPLAY_STATUS_DISPLAY_NAMES = {
	[WORK_ORDER_DISPLAY_STATUSES.pending]: 'Requested',
	[WORK_ORDER_DISPLAY_STATUSES.open]: 'Open',
	[WORK_ORDER_DISPLAY_STATUSES.inProgress]: 'In Progress',
	[WORK_ORDER_DISPLAY_STATUSES.hold]: 'On Hold',
	[WORK_ORDER_DISPLAY_STATUSES.complete]: 'Completed',
	[WORK_ORDER_DISPLAY_STATUSES.cancelled]: 'Cancelled',
};

export const FINISHED_WORK_ORDER_DISPLAY_STATUS_DISPLAY_NAMES = {
	[WORK_ORDER_DISPLAY_STATUSES.complete]: 'Completed',
	[WORK_ORDER_DISPLAY_STATUSES.cancelled]: 'Cancelled',
};

export const UNFINISHED_WORK_ORDER_DISPLAY_STATUS_DISPLAY_NAMES = {
	[WORK_ORDER_DISPLAY_STATUSES.pending]: 'Requested',
	[WORK_ORDER_DISPLAY_STATUSES.open]: 'Open',
	[WORK_ORDER_DISPLAY_STATUSES.inProgress]: 'In Progress',
	[WORK_ORDER_DISPLAY_STATUSES.hold]: 'On Hold',
};

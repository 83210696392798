import * as React from 'react';
import { Layout, Row, Col, Card, Button, Divider } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	quotesRestCrudThunksForSupplier,
	markQuotePaidForSupplier,
	publishQuoteForSupplier,
} from '../../thunks/quotes_thunks';
import { getTimeDurationFromSeconds } from '../../utils/DataFormatterUtils';
import {
	getCurrency,
	getEntityById,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import {
	appendWorkOrderNotesForSupplier,
	workOrdersRestCrudThunksForSupplier,
} from '../../thunks/work_orders_thunks';
import ApprovalHierarchyDisplayQuote from '../approval_hierarchy_display_quote/ApprovalHierarchyDisplayQuote';
import QuoteUploader from '../quote_uploader/QuoteUploader';
import {
	proposalStatusChangeForInternalSupplier,
	syncProposalApprovalHierarchy,
} from '../../thunks/proposals_thunks';
import QuotesAdditionalDetails from '../quotes_components/QuotesAdditionalDetails';
import { SUPER_ADMIN, SUPPLIER_ADMIN, SUPPLIER_USER } from '../roles/roles';
import { markUserNotificationsReadForSupplier } from '../../thunks/user_notifications_thunks';
import CapexSwitch from '../capex_switch/CapexSwitch';

const { Content } = Layout;

class SupplierQuotesDetailDetailsPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = { generatingPDF: false, pdfFile: { url: null, httpHeaders: null } };
	}

	refreshQuote = () => {
		const { getQuote, match } = this.props;
		const quoteId = match.params.id;
		getQuote(quoteId);
	};

	componentDidMount() {
		const { getQuote, match, markUserNotificationsRead, quote } = this.props;
		const quoteId = match.params.id;
		if (quote && quote.id) {
			markUserNotificationsRead(quoteId);
		} else {
			getQuote(quoteId).then(() => markUserNotificationsRead(quoteId));
		}
	}
	componentDidUpdate(prevProps) {
		const { quote, markUserNotificationsRead } = this.props;
		const { quote: prevQuote } = prevProps;
		if (quote && prevQuote && quote.id !== prevQuote.id) {
			markUserNotificationsRead(quote.id);
		}
	}

	handleFileAttachmentUploadChange = (newAttachments) => {
		const { updateQuote, quote } = this.props;
		const newQuote = {
			...quote,
			attachments: newAttachments,
		};
		updateQuote(newQuote);
	};

	handleQuotePDFUploadChange = (quoteFileUrl) => {
		const { updateQuote, getQuote, quote } = this.props;

		const newQuote = {
			...quote,
			proposalPdfLink: quoteFileUrl,
		};

		updateQuote(newQuote).then(() => {
			getQuote(quote.id);
		});
	};

	render() {
		const {
			quote = {},
			quoteFetching,
			currentUser,
			approveQuote,
			declineQuote,
			appendWorkOrderNotes,
			syncQuote,
			history,
		} = this.props;
		const isInternalTech = nullSafeGetOrElse('facility.status', currentUser, '') === 'private';
		const isSupplierAdminOrUser =
			currentUser.roles.includes(SUPPLIER_ADMIN) ||
			currentUser.roles.includes(SUPPLIER_USER) ||
			currentUser.roles.includes(SUPER_ADMIN);
		const hasPDF = quote.proposalPdfLink && quote.proposalPdfLink.length > 0;
		const currency = getCurrency({ workOrder: quote.workOrder });

		const approvalAllowed = nullSafeGetOrElse(
			'facility.internalTechConfig.allowedToApproveProposal',
			currentUser,
			false
		);

		const pdfElement = hasPDF ? (
			<p className={'mb-1'}>
				<a href={quote.proposalPdfLink} target="_blank" rel="noreferrer">
					Quote PDF
				</a>
				{!isInternalTech ? (
					<Button type="link" onClick={() => this.handleQuotePDFUploadChange(undefined)}>
						<i className="icons8-font icons8-trash-can"></i>
					</Button>
				) : null}
			</p>
		) : null;
		const uploadQuote =
			isInternalTech || isSupplierAdminOrUser ? (
				<QuoteUploader
					roleType="supplier"
					quoteId={quote && quote.id}
					defaultFileList={[]}
					multiple={false}
					handleUploadSuccess={this.handleQuotePDFUploadChange}
				/>
			) : null;
		return (
			<Content className="quotesDetailDetailsPage" key={nullSafeGet('id', quote)}>
				<LogOnMountWithStandardEventProperties eventType="visited supplier quotes page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '16px 8px' }} gutter={12} key={quote.id}>
					<Col span={24}>
						<Card>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<Row className="w-full">
									<div className="flex w-full flex-row items-center justify-between align-top">
										<ApprovalHierarchyDisplayQuote
											workOrder={quote.workOrder}
											quote={quote}
											refresh={this.refreshQuote}
											currentUser={currentUser}
											approveQuote={approveQuote}
											declineQuote={declineQuote}
											syncQuote={syncQuote}
											appendWorkOrderNotes={appendWorkOrderNotes}
											style={{}}
											allowedToApprove={isInternalTech && approvalAllowed}
											history={history}
										/>
										{isInternalTech && (
											<CapexSwitch
												quote={quote}
												refresh={this.refreshQuote}
												companyConfig={nullSafeGetOrElse(
													'workOrder.buyerCompany.buyerCompanySettings',
													quote,
													{}
												)}
											/>
										)}
									</div>
								</Row>
							</div>
							<div className={'mt-5 flex flex-row justify-between	'}>
								<QuotesAdditionalDetails
									quote={quote}
									passthroughItems={[
										pdfElement || uploadQuote
											? {
													key: 'File',
													value: (
														<div>
															{pdfElement}
															{uploadQuote}
														</div>
													),
													className: 'detailRow__item firstRow lastCol',
											  }
											: {},
									]}
								/>
							</div>
							<Divider />
							<div className="sectionHeaderWrapper">
								<span className="sectionHeaderText">Incurred Costs</span>
								<span className="sectionHeaderText2">
									{currency.format(
										parseFloat(nullSafeGet(`incurredCostTotalBeforeTax`, quote)) || 0
									)}
								</span>
							</div>
							<Divider />
							<div className="sectionHeaderWrapper" style={{ marginBottom: 8 }}>
								<span className="sectionHeaderText">Labor</span>
								<span className="sectionHeaderText2">
									{currency.format(quote.laborTotalBeforeTax)}
								</span>
							</div>
							{nullSafeGetOrElse('laborLineItems', quote, []).map((lli, idx) => (
								<div
									className="sectionHeaderWrapper2"
									style={{
										marginBottom:
											idx === nullSafeGetOrElse('laborLineItems', quote, []).length - 1 ? 16 : 8,
									}}
								>
									<span className="lineItemDetailText">
										{lli.numberOfTech} {lli.roleType} * {getTimeDurationFromSeconds(lli.seconds)} @{' '}
										{currency.format(parseFloat(lli.hourlyRate))} / hour
									</span>
									<span className="lineItemDetailText">
										{currency.format(parseFloat(lli.amount))}
									</span>
								</div>
							))}
							<Divider />

							<div className="sectionHeaderWrapper" style={{ marginBottom: 8 }}>
								<span className="sectionHeaderText">Materials</span>
								<span className="sectionHeaderText2">
									{currency.format(quote.materialTotalBeforeTax)}
								</span>
							</div>
							{nullSafeGetOrElse('materialLineItems', quote, []).map((mli, idx) => (
								<div
									className="sectionHeaderWrapper2"
									style={{
										marginBottom:
											idx === nullSafeGetOrElse('laborLineItems', quote, []).length - 1 ? 16 : 8,
									}}
								>
									<span className="lineItemDetailText">
										{mli.quantity} {mli.unitType} of {mli.description}
										{mli.partNumber ? ` (${mli.partNumber})` : null} @{' '}
										{currency.format(parseFloat(mli.unitPrice))} / {mli.unitType}
									</span>
									<span className="lineItemDetailText">
										{currency.format(parseFloat(mli.amount))}
									</span>
								</div>
							))}
							<Divider />
							<div className="sectionHeaderWrapper">
								<span className="sectionHeaderText">Travel</span>
								<span className="sectionHeaderText2">
									{currency.format(parseFloat(quote.travelTotalBeforeTax) || 0)}
								</span>
							</div>
							<Divider />
							<div className="sectionHeaderWrapper">
								<span className="sectionHeaderText">Freight</span>
								<span className="sectionHeaderText2">
									{currency.format(parseFloat(quote.freightTotalBeforeTax) || 0)}
								</span>
							</div>
							<Divider />
							<div className="sectionHeaderWrapper">
								<span className="sectionHeaderText">Miscellaneous</span>
								<span className="sectionHeaderText2">
									{currency.format(parseFloat(nullSafeGet(`miscTotalBeforeTax`, quote)) || 0)}
								</span>
							</div>
							<Divider />
							<div className="sectionHeaderWrapper">
								<span className="sectionHeaderText">Total Before Tax</span>
								<span className="sectionHeaderText2">{currency.format(quote.totalBeforeTax)}</span>
							</div>
							<Divider />
							<div className="sectionHeaderWrapper">
								<span className="sectionHeaderText">Tax</span>
								<span className="sectionHeaderText2">{currency.format(quote.tax)}</span>
							</div>
							<Divider />
							<div className="sectionHeaderWrapper">
								<span className="sectionHeaderText" style={{ fontWeight: 600 }}>
									Total After Tax
								</span>
								<span className="sectionHeaderText2" style={{ fontWeight: 600 }}>
									{currency.format(quote.totalAfterTax)}
								</span>
							</div>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	workOrdersFetching: state.work_orders.fetching,
	workOrder: state.work_orders.detail,
	quoteFetching: state.proposals.fetching,
	quote: getEntityById(ownProps.match.params.id, state.proposals),
	currentUser: state.session.currentUser,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	appendWorkOrderNotes: (entity) => dispatch(appendWorkOrderNotesForSupplier(entity)),
	getWorkOrder: (id) => dispatch(workOrdersRestCrudThunksForSupplier.readOne(id)),
	getQuote: (id) => dispatch(quotesRestCrudThunksForSupplier.readOne(id)),
	approveQuote: (entity) => dispatch(proposalStatusChangeForInternalSupplier(entity, 'awarded')),
	declineQuote: (entity) => dispatch(proposalStatusChangeForInternalSupplier(entity, 'declined')),
	syncQuote: (entity) => dispatch(syncProposalApprovalHierarchy(entity)),
	updateQuote: (entity) => dispatch(quotesRestCrudThunksForSupplier.update(entity)),
	markQuotePaid: (id) => dispatch(markQuotePaidForSupplier({ id })),
	publishQuote: (id) => dispatch(publishQuoteForSupplier({ id })),
	markUserNotificationsRead: (entityId) =>
		dispatch(markUserNotificationsReadForSupplier('Quote', entityId)),
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SupplierQuotesDetailDetailsPage)
);

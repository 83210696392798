import * as React from 'react';
import { getProtectedImageUriFromString } from '../../utils/FileAccessUtils';
import { Image } from 'antd';
import './ProtectedImageThumbnails.less';

export const ProtectedImageThumbnails: React.FC<any> = React.forwardRef(
	({ photoStrings = [], role, imageWidth, imageHeight, style = {}, ...rest }, ref) => {
		const photoUris = photoStrings.map((photoString) => ({
			src: getProtectedImageUriFromString(role)(photoString, 600, 800),
		}));
		return (
			<div className="ProtectedImageThumbnails" style={{ display: 'flex', ...style }} {...rest}>
				<Image.PreviewGroup>
					{photoUris.map((photoUri, idx) => (
						<Image
							key={idx}
							src={photoUri.src}
							style={{
								marginLeft: idx === 0 ? 0 : 8,
								width: imageWidth,
								height: imageHeight,
							}}
						/>
					))}
				</Image.PreviewGroup>
			</div>
		);
	}
);

import React, { FC, useMemo } from 'react';
import { Layout, Row, Col, Card, Table, Modal } from 'antd';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { DATE_FORMAT } from '../../utils/DataFormatterUtils';
import moment from 'moment';

const { Content } = Layout;

const ExchangeRateHistoryModal: FC<any> = ({
	exchangeRate,
	currencyTitle,
	onCancel,
}): React.ReactElement => {
	const orderedHistories = useMemo(() => {
		const history = nullSafeGetOrElse('histories', exchangeRate, []);
		return history.sort((a, b) => moment(b.fromTime).diff(moment(a.fromTime), 'seconds'));
	}, [exchangeRate]);

	const columns = useMemo(
		() => [
			{
				title: 'Period',
				render: (_, record) => {
					const { fromTime, toTime } = record;
					return `${moment(fromTime).format(DATE_FORMAT)} - ${moment(toTime).format(DATE_FORMAT)}`;
				},
			},
			{
				title: 'Exchange Rate',
				dataIndex: 'exchangeRate',
				render: (rate) => `${rate || 0}%`,
			},
		],
		[]
	);

	return (
		<Modal
			visible={true}
			width={600}
			title={`${currencyTitle} Exchange Rate History`}
			closable
			onCancel={onCancel}
			forceRender
			footer={null}
		>
			<Content style={{ padding: '0 0.5em', marginTop: '-8px' }}>
				<Row style={{ margin: '0.5em -8px', marginTop: 16 }} gutter={16}>
					<Col span={24}>
						<Card bodyStyle={{ padding: 8, marginTop: 8 }}>
							<Table
								showHeader
								columns={columns}
								dataSource={orderedHistories}
								pagination={{ current: 1, pageSize: 10 }}
							/>
						</Card>
					</Col>
				</Row>
			</Content>
		</Modal>
	);
};

export default ExchangeRateHistoryModal;

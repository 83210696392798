import { UserAddOutlined } from '@ant-design/icons';
import { Avatar, Button, Popover } from 'antd';
import React, { FC, useCallback, useMemo } from 'react';
import { ContactAvatar } from '../contact_avatar/ContactAvatar';

const AvatarGroup: FC<any> = ({ userType, avatars, onClick, onAddClick }): React.ReactElement => {
	const groupOnClick = useCallback(() => onClick && onClick(), [onClick]);

	const addClick = useCallback(() => onAddClick && onAddClick(), [onAddClick]);

	/*
	LOGIC of Next Line
	If there are upto 3 users, "+x" won't be shown. If there are more than 3 users, 
	we show 2 users and use the 3rd place to show "+x"
	Exactly 3 users -> It doesn't make sense to show 2 users and also "+1". 
	We might as well directly show all the 3 users.
	*/
	const moreCount = useMemo(() => (avatars.length <= 3 ? 0 : avatars.length - 2), [avatars]);

	const avatarUserType = useMemo(() => userType || 'buyer', [userType]);

	return (
		<div className="flex flex-row items-center">
			<Popover content={<div>Click to view all members of this channel</div>}>
				<div onClick={groupOnClick} className="flex cursor-pointer flex-row items-center pr-2">
					<Avatar.Group
						maxCount={avatars.length === 3 ? 3 : 2}
						maxPopoverTrigger="hover"
						size="large"
						maxStyle={{
							display: 'none',
							color: '#333',
							backgroundColor: 'transparent',
							cursor: 'pointer',
						}}
					>
						{avatars.map((c, i) => (
							<ContactAvatar
								key={`${i}-contact-avatar`}
								hidePopover={true}
								placement="bottomRight"
								userType={avatarUserType}
								contact={c}
							/>
						))}
					</Avatar.Group>
					{moreCount ? <div className="px-2">{`+${moreCount}`}</div> : null}
				</div>
			</Popover>
			<Popover content={<div>Click to add members to this channel</div>}>
				<Button
					onClick={addClick}
					shape="circle"
					type="ghost"
					icon={<UserAddOutlined translate="" style={{ fontSize: 16 }} />}
				/>
			</Popover>
		</div>
	);
};

export default AvatarGroup;

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
	companyConfigRestCrudThunks,
	companyConfigRestCrudThunksForBuyer,
} from '../../thunks/company_config_thunks';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ROLE_TYPES } from '../../utils/DataConstants';
import { Content } from 'antd/lib/layout/layout';
import { Card, Checkbox, Col, Row, Spin, Table, message } from 'antd';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { LoadingOutlined } from '@ant-design/icons';
import DirectPermissionConfigComponent from './DirectPermissionConfigComponent';
import { PERMISSION_NAMES, getBuyerAccessDefaultConfig } from '../../utils/AuthUtils';

const getFieldId = (field) => `${field.groupKey}-${field.permissionKey}-${field.roleKey}`;

const ROLE_LABLES = {
	managersAllowed: 'Manager',
	storeAssociatesAllowed: 'Store Associate',
};

const SA_DISABLED_PERMISSIONS = [PERMISSION_NAMES.CAN_SHOW_ALL_SUPPLIERS_IN_WO_CREATION];

const BuyerAdminAccessControlPage: FC<any> = ({
	currentUser,
	companySettings,
	updateCompanySettings,
	getCompanySettings,
	companySettingsFetching,
}): React.ReactElement => {
	const [currentUpdatingKey, setCurrentUpdatingKey] = useState(null);

	useEffect(() => {
		getCompanySettings(currentUser);
	}, [currentUser, getCompanySettings]);

	const permissionsChanged = useCallback(
		(key) => (e) => {
			if (currentUpdatingKey) {
				return;
			}

			const updatedSetting = {
				...companySettings,
				config: {
					...companySettings.config,
					accessPermissions: {
						...nullSafeGetOrElse('config.accessPermissions', companySettings, {}),
						[key.groupKey]: {
							...nullSafeGetOrElse(`config.accessPermissions.${key.groupKey}`, companySettings, {}),
							[key.permissionKey]: {
								...nullSafeGetOrElse(
									`config.accessPermissions.${key.groupKey}.${key.permissionKey}`,
									companySettings,
									getBuyerAccessDefaultConfig(key.permissionKey)
								),
								[key.roleKey]: e.target.checked,
							},
						},
					},
				},
			};

			setCurrentUpdatingKey(getFieldId(key));

			updateCompanySettings(updatedSetting)
				.catch(() => message.error('Unable to update Access Control!'))
				.finally(() => setCurrentUpdatingKey(null));
		},
		[companySettings, currentUpdatingKey, updateCompanySettings]
	);

	const isFieldDisabled = useCallback((roleKey, permissionKey) => {
		switch (roleKey) {
			case 'storeAssociatesAllowed':
				return SA_DISABLED_PERMISSIONS.includes(permissionKey);
			default:
				return false;
		}
	}, []);

	const getCheckBoxField = useCallback(
		(roleKey) => ({
			title: ROLE_LABLES[roleKey],
			dataIndex: roleKey,
			render: (_, record) => (
				<div className="flex flex-row">
					<Checkbox
						disabled={isFieldDisabled(roleKey, record.permissionKey)}
						checked={_}
						onChange={permissionsChanged({
							...record,
							roleKey,
						})}
					/>
					{currentUpdatingKey === getFieldId({ ...record, roleKey }) && (
						<div className="ml-2">
							<Spin indicator={<LoadingOutlined translate="" style={{ fontSize: 16 }} spin />} />
						</div>
					)}
				</div>
			),
		}),
		[currentUpdatingKey, isFieldDisabled, permissionsChanged]
	);

	const columns = useMemo(
		() => [
			{
				title: 'Permissions',
				dataIndex: 'label',
				width: '25%',
			},
			getCheckBoxField('storeAssociatesAllowed'),
			getCheckBoxField('managersAllowed'),
		],
		[getCheckBoxField]
	);

	const getExistingValues = useCallback(
		(groupName, permissionName) =>
			nullSafeGetOrElse(
				`config.accessPermissions.${groupName}.${permissionName}`,
				companySettings,
				getBuyerAccessDefaultConfig(permissionName)
			),
		[companySettings]
	);

	const permissionGroupings = useMemo(
		() => [
			{
				key: 'locations',
				label: 'Locations',
				values: [
					{
						key: PERMISSION_NAMES.SHOW_LOCATION_DETAILS,
						label: 'Show Location Details',
					},
					{
						key: PERMISSION_NAMES.MODIFY_LOCATIONS,
						label: 'Modify Locations',
					},
					{
						key: PERMISSION_NAMES.MODIFY_LOCATION_TYPES,
						label: 'Modify Location Types',
					},
					{
						key: PERMISSION_NAMES.MODIFY_AREAS,
						label: 'Modify Areas',
					},
					{
						key: PERMISSION_NAMES.MODIFY_SELF_LOCATION_ACCESS,
						label: 'Modify Self Location Access',
					},
					{
						key: PERMISSION_NAMES.SHOW_INTERNAL_TECH_NOTES,
						label: 'Show Internal Tech Notes',
					},
				],
			},
			{
				key: 'assets',
				label: 'Assets',
				values: [
					{
						key: PERMISSION_NAMES.MODIFY_ASSETS,
						label: 'Add/Modify Assets',
					},
					{
						key: PERMISSION_NAMES.MODIFY_ASSET_TYPES,
						label: 'Add/Modify Asset Types',
					},
					{
						key: PERMISSION_NAMES.SHOW_ASSET_PRICE,
						label: 'Show Asset Price',
					},
				],
			},
			{
				key: 'siteSurveys',
				label: 'Site Surveys',
				values: [
					{
						key: PERMISSION_NAMES.MODIFY_SITE_SURVEYS,
						label: 'Add/Modify Site Surveys',
					},
					{
						key: PERMISSION_NAMES.MODIFY_SITE_SURVEY_TEMPLATES,
						label: 'Add/Modify Site Surveys Templates',
					},
					{
						key: PERMISSION_NAMES.ADD_WALKTHROUGHS,
						label: 'Add Walkthroughs',
					},
					{
						key: PERMISSION_NAMES.DELETE_WALKTHROUGHS,
						label: 'Delete Walkthroughs',
					},
				],
			},
			{
				key: 'others',
				label: 'Others',
				values: [
					{
						key: PERMISSION_NAMES.ACCESS_ANALYTICS,
						label: 'Access Analytics',
					},
					{
						key: PERMISSION_NAMES.CAN_UPLOAD_INVOICES_FOR_SUPPLIER,
						label: 'Can Upload Invoices For Supplier',
					},
					{
						key: PERMISSION_NAMES.CAN_UPLOAD_QUOTES_FOR_SUPPLIER,
						label: 'Can Upload Quotes For Supplier',
					},
					{
						key: PERMISSION_NAMES.MODIFY_SUPPLIERS,
						label: 'Modify Suppliers',
					},
					{
						key: PERMISSION_NAMES.EDIT_WORK_ORDERS,
						label: 'Can Edit Work Orders',
					},
					{
						key: PERMISSION_NAMES.CANCEL_WORK_ORDERS,
						label: 'Can Cancel Work Orders',
					},
					{
						key: PERMISSION_NAMES.REOPEN_WORK_ORDERS,
						label: 'Can Re-open Work Orders',
					},
					{
						key: PERMISSION_NAMES.CAN_SHOW_ALL_SUPPLIERS_IN_WO_CREATION,
						label: 'Can Show All Suppliers In WO Creation',
					},
				],
			},
		],
		[]
	);

	const dataSource = useMemo(
		() =>
			permissionGroupings.map((group) => ({
				...group,
				fields: group.values.map((permission) => ({
					...permission,
					groupKey: group.key,
					permissionKey: permission.key,
					...getExistingValues(group.key, permission.key),
				})),
			})),
		[getExistingValues, permissionGroupings]
	);

	return (
		<Content style={{ padding: '0 0.5em' }}>
			<Row className="rowSpacing">
				{dataSource.map((group) => (
					<Col span={24} key={group.key} className="mb-2">
						<Card title={group.label} loading={companySettingsFetching}>
							<Table columns={columns} dataSource={group.fields} showHeader pagination={false} />
						</Card>
					</Col>
				))}
				<Col span={24} key={'approvals'} className="mb-2">
					<Card title="Approvals" loading={companySettingsFetching}>
						<DirectPermissionConfigComponent />
					</Card>
				</Col>
			</Row>
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	currentUser: state.session.currentUser,

	companySettings: state.company_config.detail,
	companySettingsFetching: state.company_config.fetching,
});

const mapDispatchToProps = (dispatch) => ({
	getCompanySettings: (contact) => dispatch(companyConfigRestCrudThunks(ROLE_TYPES.BUYER, contact)),
	updateCompanySettings: (settings) =>
		dispatch(companyConfigRestCrudThunksForBuyer.update(settings)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(BuyerAdminAccessControlPage)
);

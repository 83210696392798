import { getBackendUri } from '../utils/EnvConfigUtils';
import FetchUtils, { parseJSON } from './fetch_utils';
import { logoutSuccess } from '../actions/session_actions';
import {
	fetchWorkOrderCountByAssetTypeStart,
	fetchWorkOrderCountByAssetTypeSuccess,
	fetchWorkOrderCountByAssetTypeFailure,
	fetchWorkOrderCountByManufacturerStart,
	fetchWorkOrderCountByManufacturerSuccess,
	fetchWorkOrderCountByManufacturerFailure,
	fetchWorkOrderCountByProblemTypeStart,
	fetchWorkOrderCountByProblemTypeSuccess,
	fetchWorkOrderCountByProblemTypeFailure,
	fetchWorkOrderCountByVendorStart,
	fetchWorkOrderCountByVendorSuccess,
	fetchWorkOrderCountByVendorFailure,
	fetchWorkOrderCountByCategoryStart,
	fetchWorkOrderCountByCategorySuccess,
	fetchWorkOrderCountByCategoryFailure,
	fetchWorkOrderCountByFacilityStart,
	fetchWorkOrderCountByFacilitySuccess,
	fetchWorkOrderCountByFacilityFailure,
	fetchWorkOrderCountByMonthStart,
	fetchWorkOrderCountByMonthSuccess,
	fetchWorkOrderCountByMonthFailure,
	fetchWorkOrderCountByLocationSuccess,
	fetchWorkOrderCountByLocationFailure,
	fetchWorkOrderCountByLocationStart,
	fetchWorkOrderCountByWeekStart,
	fetchWorkOrderCountByWeekSuccess,
	fetchWorkOrderCountByWeekFailure,
	fetchWorkOrderCountByDayStart,
	fetchWorkOrderCountByDaySuccess,
	fetchWorkOrderCountByRegionStart,
	fetchWorkOrderCountByRegionSuccess,
	fetchWorkOrderCountByRegionFailure,
} from '../actions/work_analytics_actions';
import { crushFilters, formatDateParameters } from '../utils/DataAccessUtils';

const fetchUtils = new FetchUtils();
const baseUrl = getBackendUri();
const apiUrl = (userType) => `/api/v1/${userType}/work_order/work_order_reportings`;

const fetchWorkOrderCountData =
	(startAction, successAction, failureAction, apiSubstring) =>
	(userType) =>
	(p, filters = null, pagination = null, sorting = null) => {
		let params = { ...p };
		// if pagination present, transform into limit/offset format and add to query params
		const startDate = params.startDate;
		const endDate = params.endDate;
		if (!startDate || !endDate) {
			return (dispatch) => {
				new Promise((resolve, reject) => {
					resolve([]);
				});
			};
		}

		if (pagination) {
			const paginationQuery = {
				offset: (pagination.current - 1) * pagination.pageSize,
				limit: pagination.pageSize,
			};
			params = {
				...params,
				...paginationQuery,
			};
		}

		// if sorting present, fix order naming and add to query params
		if (sorting) {
			const sortingQuery = { ...sorting, order: sorting.order === 'ascend' ? 'asc' : 'desc' };
			params = {
				...params,
				...sortingQuery,
			};
		}

		if (filters) {
			params = {
				...params,
				...filters,
			};
		}

		let queryUri = baseUrl + apiUrl(userType) + '/' + apiSubstring;

		if (startDate) {
			queryUri += `/${startDate.format('YYYY-MM-DD')}`;
		}

		if (endDate) {
			queryUri += `/${endDate.format('YYYY-MM-DD')}`;
		}

		delete params.startDate;
		delete params.endDate;

		params = formatDateParameters(params);

		if (params.locationIds && params.locationIds.length === 0) {
			delete params.locationIds;
		}
		if (params.supplierFacilityIds && params.supplierFacilityIds.length === 0) {
			delete params.supplierFacilityIds;
		}

		return (dispatch) => {
			return new Promise((resolve, reject) => {
				dispatch(startAction());

				fetchUtils
					.get(
						queryUri,
						Object.assign(
							{ headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
							crushFilters(params)
						)
					)
					.then(parseJSON)
					.then(({ json, status, ok }) => {
						const d = json;
						if (status === 401) {
							dispatch(logoutSuccess());
						}
						if (!ok || d.status === 'error') {
							dispatch(failureAction([d.message]));
							reject(d.message);
						} else {
							dispatch(successAction(d.data));
							resolve(d.data);
						}
					})
					.catch((d) => {
						dispatch(failureAction([d.message]));
						reject(d.message);
					});
			});
		};
	};

export const fetchWorkOrderCountByMonth = fetchWorkOrderCountData(
	fetchWorkOrderCountByMonthStart,
	fetchWorkOrderCountByMonthSuccess,
	fetchWorkOrderCountByMonthFailure,
	'work_order_monthly_count'
);
export const fetchWorkOrderCountByWeek = fetchWorkOrderCountData(
	fetchWorkOrderCountByWeekStart,
	fetchWorkOrderCountByWeekSuccess,
	fetchWorkOrderCountByWeekFailure,
	'work_order_weekly_count'
);
export const fetchWorkOrderCountByDay = fetchWorkOrderCountData(
	fetchWorkOrderCountByDayStart,
	fetchWorkOrderCountByDaySuccess,
	fetchWorkOrderCountByWeekFailure,
	'work_order_daily_count'
);
export const fetchWorkOrderCountByCategory = fetchWorkOrderCountData(
	fetchWorkOrderCountByCategoryStart,
	fetchWorkOrderCountByCategorySuccess,
	fetchWorkOrderCountByCategoryFailure,
	'work_order_counts_by_spend_category'
);
export const fetchWorkOrderCountByVendor = fetchWorkOrderCountData(
	fetchWorkOrderCountByVendorStart,
	fetchWorkOrderCountByVendorSuccess,
	fetchWorkOrderCountByVendorFailure,
	'work_order_counts_by_supplier_facility'
);
export const fetchWorkOrderCountByLocation = fetchWorkOrderCountData(
	fetchWorkOrderCountByLocationStart,
	fetchWorkOrderCountByLocationSuccess,
	fetchWorkOrderCountByLocationFailure,
	'work_order_counts_by_location'
);
export const fetchWorkOrderCountByLocationWithRegions = fetchWorkOrderCountData(
	fetchWorkOrderCountByLocationStart,
	fetchWorkOrderCountByLocationSuccess,
	fetchWorkOrderCountByLocationFailure,
	'work_order_counts_by_location_with_regions'
);
export const fetchWorkOrderCountByRegion = fetchWorkOrderCountData(
	fetchWorkOrderCountByRegionStart,
	fetchWorkOrderCountByRegionSuccess,
	fetchWorkOrderCountByRegionFailure,
	'work_order_counts_by_region'
);
export const fetchWorkOrderCountByProblemType = fetchWorkOrderCountData(
	fetchWorkOrderCountByProblemTypeStart,
	fetchWorkOrderCountByProblemTypeSuccess,
	fetchWorkOrderCountByProblemTypeFailure,
	'work_order_counts_by_problem_type'
);
export const fetchWorkOrderCountByProblemTypeWithChildren = fetchWorkOrderCountData(
	fetchWorkOrderCountByProblemTypeStart,
	fetchWorkOrderCountByProblemTypeSuccess,
	fetchWorkOrderCountByProblemTypeFailure,
	'work_order_counts_by_problem_type_with_children'
);
export const fetchWorkOrderCountByAssetType = fetchWorkOrderCountData(
	fetchWorkOrderCountByAssetTypeStart,
	fetchWorkOrderCountByAssetTypeSuccess,
	fetchWorkOrderCountByAssetTypeFailure,
	'work_order_counts_by_asset_type'
);
export const fetchWorkOrderCountByManufacturer = fetchWorkOrderCountData(
	fetchWorkOrderCountByManufacturerStart,
	fetchWorkOrderCountByManufacturerSuccess,
	fetchWorkOrderCountByManufacturerFailure,
	'work_order_counts_by_manufacturer'
);
export const fetchWorkOrderCountByFacility = fetchWorkOrderCountData(
	fetchWorkOrderCountByFacilityStart,
	fetchWorkOrderCountByFacilitySuccess,
	fetchWorkOrderCountByFacilityFailure,
	'work_order_counts_by_buyer_facility'
);

import FetchUtils, { parseJSON } from './fetch_utils';
import { logoutSuccess } from '../actions/session_actions';
import {
	fetchAllMessagesError,
	fetchAllMessagesStart,
	fetchAllMessagesSuccess,
} from '../actions/messages_actions';
import { getBackendUri } from '../utils/EnvConfigUtils';
import { ROLE_TYPES } from '../utils/DataConstants';

const fetchUtils = new FetchUtils();
const baseUrl = getBackendUri();
const fetchAllWorkOrderNotes = (roleType) => (workOrderId) => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(fetchAllMessagesStart(workOrderId));

			fetchUtils
				.get(baseUrl + `/api/v1/${roleType}/work_order/work_order_notes/${workOrderId}`, {
					headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
				})
				.then(parseJSON)
				.then(({ json, status, ok }) => {
					const d = json;
					if (status === 401) {
						dispatch(logoutSuccess());
					}
					if (d.status === 'error') {
						dispatch(fetchAllMessagesError(d.message, workOrderId));
						reject(d.message);
					} else {
						dispatch(fetchAllMessagesSuccess(workOrderId, d.data));
						resolve(d.data);
					}
				})
				.catch((d) => {
					dispatch(fetchAllMessagesError(d.message, workOrderId));
					reject(d.message);
				});
		});
	};
};

export const fetchAllWorkOrderNotesForBuyer = fetchAllWorkOrderNotes(ROLE_TYPES.BUYER);
export const fetchAllWorkOrderNotesForSupplier = fetchAllWorkOrderNotes(ROLE_TYPES.SUPPLIER);

import * as React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Layout, Row, Button, Modal, List, Select } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { PageLoadingPlaceholder } from '../page_loading_placeholder/PageLoadingPlaceholder';
import { debounce } from '../../utils/PerformanceUtils';
import {
	getEntityById,
	getObjectValues,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import { workOrdersRestCrudThunksForBuyer } from '../../thunks/work_orders_thunks';
import {
	fetchAllUsersForBuyers,
	addBuyerSubscribersForBuyers,
	addSupplierSubscribersForBuyers,
	removeBuyerSubscriberForBuyers,
	removeSupplierSubscriberForBuyers,
} from '../../thunks/work_orders_subscribers_thunks';
import { ContactAvatar } from '../contact_avatar/ContactAvatar';
import BuyerNewWorkOrderNoteForm from '../buyer_new_work_order_note_form/BuyerNewWorkOrderNoteForm';
import { EmptyState } from '../empty_state/EmptyState';
import { fetchAllWorkOrderNotesForBuyer } from '../../thunks/messages_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import AvatarGroup from '../avatar_group/AvatarGroup';
import MessageBubble from '../MessageBubble';
import SelectUsers from '../select_users/SelectUsers';
import RemoveSubscriberButton from './RemoveSubscriberButton';
import { ROLE_TYPES } from '../../utils/DataConstants';

const { Content } = Layout;

require('./WorkOrdersDetailNotesPage.less');

class WorkOrderNotesDetailPage extends React.Component<any, any> {
	initialPagination: any;
	initialFilters: any;
	state = {
		fetching: true,
		subscriberModalVisible: false,
		addSubscriberModalVisible: false,
		search: '',
		selectedUsers: [],
		selectValue: [],
		addingSubscribers: false,
	};

	componentDidMount() {
		const { fetchMessages, match } = this.props;

		const workOrderId = match.params.id;
		const promise2 = fetchMessages(workOrderId);
		Promise.all([promise2]).then(() => {
			this.setState({ fetching: false }, () => {
				const element = document.getElementById('chatBox');
				if (element) {
					element.scrollTop = element.scrollHeight;
				}
			});
		});
	}

	handleSubscriberModalClose = () => {
		this.setState({ subscriberModalVisible: false });
	};

	handleSubscriberModalOpen = () => {
		this.setState({ subscriberModalVisible: true });
	};
	handleAddSubscriberModalClose = () => {
		this.setState({ addSubscriberModalVisible: false });
	};
	handleAddSubscriberModalOpen = () => {
		this.setState({ addSubscriberModalVisible: true });
		this.setState({ search: '', selectedUsers: [] });
	};

	handleSubscriberRemoved = (item) => () => {
		const { removeBuyerSubscriber, removeSupplierSubscriber, match, workOrder } = this.props;
		if (item.contactType === ROLE_TYPES.BUYER) {
			let obj = { id: parseInt(match.params.id), removeBuyerSubscriberEmails: [item.email] };
			removeBuyerSubscriber(obj, workOrder);
		} else {
			let obj = { id: parseInt(match.params.id), removeSupplierSubscriberEmails: [item.email] };
			removeSupplierSubscriber(obj, workOrder);
		}
	};
	handleAddSubscribers = () => {
		const { selectedUsers } = this.state;
		const { match, workOrder, addBuyerSubscribers, addSupplierSubscribers } = this.props;
		this.setState({ addingSubscribers: true });
		let objBuyer = {
			id: parseInt(match.params.id),
			addBuyerSubscriberEmails: selectedUsers
				.filter((user) => user.contactType === 'buyer')
				.map((user) => user.email),
		};
		let objSupplier = {
			id: parseInt(match.params.id),
			addSupplierSubscriberEmails: selectedUsers
				.filter((user) => user.contactType === 'supplier')
				.map((user) => user.email),
		};
		const subscribersAddition = [
			...(objBuyer.addBuyerSubscriberEmails.length > 0
				? [addBuyerSubscribers(objBuyer, workOrder)]
				: []),
			...(objSupplier.addSupplierSubscriberEmails.length > 0
				? [addSupplierSubscribers(objSupplier, workOrder)]
				: []),
		];
		Promise.all(subscribersAddition).then(() => {
			this.setState({
				selectValue: [],
				addingSubscribers: false,
			});
			this.handleAddSubscriberModalClose();
		});
	};
	handleOnChange = (val) => {
		let updatedVal = Array.isArray(val) ? val.map((v) => JSON.parse(v)) : JSON.parse(val);
		if (!('value' in this.props)) {
			this.setState({ value: updatedVal });
		}

		this.setState({
			selectValue: updatedVal.map((val) => JSON.stringify(val)),
			selectedUsers: updatedVal,
		});
		this.triggerChange(updatedVal);
	};
	triggerChange = (changedValue) => {
		// Should provide an event to pass value to Form.
		const onChange = this.props.onChange;
		if (onChange) {
			onChange(changedValue);
		}
	};
	componentDidUpdate = (prevprops) => {};
	debouncedCallback = debounce(
		(searchText) => {
			const { fetchUsers, workOrder } = this.props;
			fetchUsers(searchText && searchText.trim(), workOrder['id']);
		},
		400,
		false
	);
	handleAddSubscriberSearchDebounced = (value = '') => {
		const searchText = value && value.trim();
		this.setState({ search: searchText });
		this.debouncedCallback(searchText);
	};

	render() {
		const { workOrder, currentUser, messages, match, searched_subscribers } = this.props;
		const { fetching } = this.state;
		const workOrderId = match.params.id;
		const notes = nullSafeGetOrElse(`${workOrderId}.messages`, messages, []);
		const isTyping = nullSafeGetOrElse(`${workOrderId}.isTyping`, messages, false);
		const isTypingText = nullSafeGetOrElse(`${workOrderId}.isTypingText`, messages, false);
		const actualNotes = notes.filter(
			(n) =>
				(n.text && n.text.length > 0) ||
				(n.photo && n.photo.length > 0) ||
				(n.video && n.video.length > 0)
		);

		let subscribers = (workOrder.buyerSubscribers || []).concat(
			workOrder.supplierSubscribers || []
		);
		if (
			workOrder.supplierSubscribers &&
			workOrder.supplierSubscribers.length === 0 &&
			nullSafeGet('supplierFacility.id', workOrder)
		) {
			subscribers.push({
				nameGiven: nullSafeGet('supplierFacility.displayName', workOrder),
				nameFamily: '',
				email: nullSafeGet('supplierSubscriberEmails.0', workOrder),
			});
		}

		const filteredUsers = getObjectValues(searched_subscribers.records).map((el) => ({
			...el,
			key: el.email,
		}));

		return fetching ? (
			<PageLoadingPlaceholder />
		) : (
			<Content className="workOrdersDetailNotesPage">
				<LogOnMountWithStandardEventProperties eventType="visited buyer work order detail notes page" />
				<div style={{ textAlign: 'right', padding: '0', height: '0px' }}>
					<Modal
						width={600}
						title={
							<div>
								<span style={{}}>Work Order {workOrderId}</span>
								<Button
									style={{ float: 'right', marginRight: '32px', marginTop: '-4px' }}
									onClick={this.handleAddSubscriberModalOpen}
									type={'primary'}
								>
									Add People
								</Button>
							</div>
						}
						footer={null}
						closable={true}
						onCancel={this.handleSubscriberModalClose}
						visible={this.state.subscriberModalVisible}
					>
						<List
							itemLayout="horizontal"
							dataSource={subscribers}
							renderItem={(item) => (
								<List.Item>
									<List.Item.Meta
										avatar={
											<ContactAvatar
												hidePopover={true}
												placement="bottomRight"
												userType="buyer"
												contact={item}
											/>
										}
										// @ts-ignore
										title={`${item.nameGiven} ${item.nameFamily}`}
										// @ts-ignore
										description={item.email}
									/>
									<RemoveSubscriberButton
										subscriber={item}
										onRemove={this.handleSubscriberRemoved}
										workOrder={workOrder}
									/>
								</List.Item>
							)}
						/>
					</Modal>
					<Modal
						width={600}
						title={
							<div>
								<span>Add Someone to Work Order {workOrderId}</span>
							</div>
						}
						footer={
							<div style={{ display: 'block', width: '100%' }}>
								<Button
									loading={this.state.addingSubscribers}
									onClick={this.handleAddSubscribers}
									type={'primary'}
								>
									Add Subscribers
								</Button>
							</div>
						}
						closable={true}
						onCancel={this.handleAddSubscriberModalClose}
						visible={this.state.addSubscriberModalVisible}
					>
						<Row key={0}>
							<SelectUsers
								mode="multiple"
								style={{ width: '100%' }}
								placeholder="Begin to type a name or email to start searching"
								onSearch={this.handleAddSubscriberSearchDebounced}
								onFocus={() => this.handleAddSubscriberSearchDebounced()}
								onChange={this.handleOnChange}
								search={this.state.search}
								selectValue={this.state.selectValue}
								filteredUsers={filteredUsers}
								disabledUsers={subscribers}
							/>
						</Row>
					</Modal>
				</div>
				<div className="flex flex-row justify-end pr-4">
					<AvatarGroup
						avatars={subscribers}
						onClick={this.handleSubscriberModalOpen}
						onAddClick={this.handleAddSubscriberModalOpen}
					/>
				</div>
				<div
					id="chatBox"
					style={{ maxHeight: 'calc(100vh - 356px)', overflowY: 'auto', marginTop: '0px' }}
					className="no-scrollbar"
				>
					{actualNotes.length > 0 ? (
						<ul>
							{actualNotes.map((n, idx) => {
								return (
									<MessageBubble
										currentUser={currentUser}
										n={n}
										idx={idx}
										subscribers={subscribers}
										userType="buyer"
									/>
								);
							})}
							{isTyping ? (
								<li className="him" key={'typing'}>
									<span style={{ fontSize: 16 }}>{isTypingText}</span>
								</li>
							) : null}
						</ul>
					) : (
						<EmptyState
							graphic={
								<img
									style={{ marginBottom: 8 }}
									src="https://s3.amazonaws.com/mock-data-assets/categories/images/letter owl.svg"
									alt="Add a note to this work order using the box below."
								/>
							}
							headline={'No notes have been added yet'}
							body={
								<div style={{ textAlign: 'center' }}>
									<div style={{ maxWidth: 440, marginBottom: 16 }}>
										Add a note to this work order using the box below.
									</div>
								</div>
							}
						/>
					)}
				</div>
				<div style={{ position: 'absolute', left: 8, right: 8, bottom: 0 }}>
					<BuyerNewWorkOrderNoteForm
						workOrder={workOrder}
						fetchMessages={this.props.fetchMessages}
					/>
				</div>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	workOrder: getEntityById(ownProps.match.params.id, state.work_orders),
	messages: state.messages,
	currentUser: state.session.currentUser,
	searched_subscribers: state.searched_subscribers,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	getWorkOrder: (id) => dispatch(workOrdersRestCrudThunksForBuyer.readOne(id)),
	fetchMessages: (workOrderId) => dispatch(fetchAllWorkOrderNotesForBuyer(workOrderId)),
	fetchUsers: (search, woId, filters) => dispatch(fetchAllUsersForBuyers(search, woId, filters)()),
	addBuyerSubscribers: (obj, workOrder) => dispatch(addBuyerSubscribersForBuyers(obj)(workOrder)),
	addSupplierSubscribers: (obj, workOrder) =>
		dispatch(addSupplierSubscribersForBuyers(obj)(workOrder)),
	removeBuyerSubscriber: (obj, workOrder) =>
		dispatch(removeBuyerSubscriberForBuyers(obj)(workOrder)),
	removeSupplierSubscriber: (obj, workOrder) =>
		dispatch(removeSupplierSubscriberForBuyers(obj)(workOrder)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkOrderNotesDetailPage));

import React, { FC, useMemo } from 'react';
import { Avatar, Popover } from 'antd';
import { getInitials } from '../../utils/DataFormatterUtils';
import { getProtectedImageUri } from '../../utils/FileAccessUtils';
import { nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { getAvatarStyle } from '../../utils/ImageUtils';
import { connect } from 'react-redux';

const ContactAvatarComponent: FC<any> = ({
	userType,
	contact,
	size,
	hidePopover,
	style,
	...otherProps
}) => {
	const buttonSize = useMemo(() => size || 'default', [size]);

	const imgFileId = useMemo(() => {
		const arr = nullSafeGetOrElse('photoLink', contact, '').split('/');
		return arr.length > 0 && arr[0];
	}, [contact]);

	const imgFileName = useMemo(() => {
		const arr = nullSafeGetOrElse('photoLink', contact, '').split('/');
		return arr.length > 1 && arr[1];
	}, [contact]);

	const photoUri = useMemo(
		() =>
			imgFileId &&
			imgFileName &&
			getProtectedImageUri(userType)(imgFileId, imgFileName, 'auto', 240),
		[imgFileId, imgFileName, userType]
	);

	const cssStyle = useMemo(() => {
		const name =
			nullSafeGetOrElse('nameGiven', contact, '') + nullSafeGetOrElse('nameFamily', contact, '');
		return { ...(style || {}), ...getAvatarStyle(name) };
	}, [style, contact]);

	return contact ? (
		hidePopover ? (
			contact.photoLink ? (
				<Avatar
					{...otherProps}
					style={cssStyle}
					size={buttonSize}
					src={photoUri}
					alt={getInitials(contact.nameGiven, contact.nameFamily)}
				/>
			) : (
				<Avatar {...otherProps} style={cssStyle} size={buttonSize}>
					{getInitials(contact.nameGiven, contact.nameFamily)}
				</Avatar>
			)
		) : (
			<Popover {...otherProps} content={`${contact.nameGiven} ${contact.nameFamily}`}>
				{contact.photoLink ? (
					<Avatar style={cssStyle} size={buttonSize} src={photoUri} />
				) : (
					<Avatar style={cssStyle} size={buttonSize}>
						{getInitials(contact.nameGiven, contact.nameFamily)}
					</Avatar>
				)}
			</Popover>
		)
	) : null;
};

const mapStateToProps = (state) => ({
	userType: state.session.userType,
});

export const ContactAvatar = connect(mapStateToProps)(ContactAvatarComponent);

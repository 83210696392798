import moment from 'moment';
import {
	checkIsWarrantyWorkOrder,
	getConfigValueDefaultingToTrue,
	getCurrency,
	getObjectValues,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
	Input,
	Select,
	Button,
	DatePicker,
	Modal,
	Radio,
	Popover,
	Row,
	InputNumber,
	Spin,
} from 'antd';
import { imageUploadValidation, normFile } from '../../utils/ImageUtils';
import BackButton from '../back_button/BackButton';
import * as React from 'react';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import ProblemTypeTreeSelect from '../problem_type_tree_select';

import { locationsRestCrudThunksForBuyer } from '../../thunks/locations_thunks';
import { assetsRestCrudThunksForBuyer } from '../../thunks/assets_thunks';
import { spendCategoriesRestCrudThunksForBuyer } from '../../thunks/spend_categories_thunks';
import {
	problemTypesRestCrudThunksForBuyer,
	problemTypesHeirarchicalForBuyer,
} from '../../thunks/problem_types_thunks';
import { workOrderAttributesRestCrudThunksForBuyer } from '../../thunks/work_order_attributes_thunks';
import { workOrderPrioritiesRestCrudThunksForBuyer } from '../../thunks/work_order_priorities_thunks';
import { workOrdersRestCrudThunksForBuyer } from '../../thunks/work_orders_thunks';
import { getProtectedImageUriForBuyer } from '../../utils/FileAccessUtils';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import {
	fetchSupplierFacilitiesInPrivateNetworkForBuyer,
	supplierFacilitiesRestCrudThunksForBuyer,
} from '../../thunks/supplier_facilities_thunks';
import { suppliersRestCrudThunksForBuyer } from '../../thunks/suppliers_thunks';
import {
	PRE_SUPPLIER_ASSIGNMENT_STATUSES,
	WORK_ORDER_STATUSES,
} from '../../constants/WorkOrderStatuses';
import { buyersRestCrudThunksForBuyer } from '../../thunks/buyers_thunks';
import { areasRestCrudThunksForBuyer } from '../../thunks/areas_thunks';
import { retrieveCachedUserDetails } from '../../thunks/session_thunks';
import SupplierSelectionWorkOrderSelect from '../supplier_selection_work_order_select';
import { InfoCircleOutlined } from '@ant-design/icons';
import { projectsRestCrudThunksForBuyer } from '../../thunks/projects_thunks';
import { debounce } from '../../utils/PerformanceUtils';
import { BUYER_ADMIN_ONLY, BUYER_STORE_ASSOCIATE } from '../roles/roles';
import _ from 'lodash';
import { getExchangeRate } from '../../thunks/currencies_thunks';
import { proposalsRestCrudThunksForBuyer } from '../../thunks/proposals_thunks';
import {
	getBuyerCompanyApprovalTiers,
	getProposalApprovalHierarchiesByAttributes,
	hasApprovalHierarchyChanged,
} from '../../thunks/company_approval_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';
import OWRadioGroup from '../ow_radio_group/OWRadioGroup';
import ApprovalHierarchyChangeModal from '../ApprovalHierarchyChangeModal';
import { resolutionTypesRestCrudThunksForBuyer } from '../../thunks/resolution_types_thunks';
import OWRichTextEditor from '../../rich_text_editor/OWRichTextEditor';
import AssetNumberDisplay from '../asset_row_display/AssetNumberDisplay';
import OWUpload from '../OWUpload';
import { getBuyerCompanySettingValue } from '../../utils/AuthUtils';

const WORK_ORDER_FORM_LOCATIONS_AUTOCOMPLETE = 'WORK_ORDER_FORM_LOCATIONS_AUTOCOMPLETE';
const WORK_ORDER_FORM_ASSETS_AUTOCOMPLETE = 'WORK_ORDER_FORM_ASSETS_AUTOCOMPLETE';
const WORK_ORDER_FORM_AREAS_AUTOCOMPLETE = 'WORK_ORDER_FORM_AREAS_AUTOCOMPLETE';
const WORK_ORDER_FORM_PROBLEM_TYPES_AUTOCOMPLETE = 'WORK_ORDER_FORM_PROBLEM_TYPES_AUTOCOMPLETE';
const WORK_ORDER_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE =
	'WORK_ORDER_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE';
const WORK_ORDER_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE =
	'WORK_ORDER_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE';
const WORK_ORDER_FORM_PROJECTS_AUTOCOMPLETE = 'WORK_ORDER_FORM_PROJECTS_AUTOCOMPLETE';

interface WorkOrderSafeFormProps extends FormComponentProps {
	history: any;
	onSubmit: any;
	onCancel: any;
	redirectForwardUrl?: string;
	onSuccess?: any;
	isApprovalForm?: any;
	creating: boolean;
	updating: boolean;
	formData: any;
	createErrors: any[];
	updateErrors: any[];
	fetchMultipleLocations: any;
	fetchLocations: any;
	fetchLocation: any;
	locations: any;
	fetchMultipleAssets: any;
	fetchAssets: any;
	fetchBuyer: any;
	assets: any;
	fetchMultipleAreas: any;
	fetchAreas: any;
	areas: any;
	fetchMultipleProblemTypes: any;
	fetchProblemTypes: any;
	problemTypes: any;
	fetchMultipleSpendCategories: any;
	fetchSpendCategories: any;
	spendCategories: any;
	fetchWorkOrderAttributes: any;
	fetchMultipleWorkOrderPriorities: any;
	fetchWorkOrderPriorities: any;
	workOrderPriorities: any;
	supplierFacilities: any;
	fetchSupplierFacilitiesInPrivateNetwork: any;
	fetchMultipleSupplierFacilitiesInPrivateNetwork: any;
	currentUser: any;
	updateWorkOrderForm: any;
	fetchSupplierFacility: any;
	fetchSupplierCompany: any;
	fetchMultipleProjects: any;
	fetchProjects: any;
	projects: any;
	buyerProjectIds: any;
	getQuotes: any;
	fetchApprovalHeirarchy: any;
	fetchExchangeRate: any;
	willApprovalHierarchyChange: any;
	getApprovalHierarchy: any;
	resolutionTypes: any;
	fetchMultipleResolutionTypes: any;
	fetchResolutionTypes: any;
	companyConfig: any;
}

class WorkOrderEditSafeForm extends React.Component<WorkOrderSafeFormProps, any> {
	constructor(props) {
		super(props);
		this.state = {
			woChanges: {},
			locationId: null,
			assetId: null,
			areaId: null,
			problemTypeId: null,
			previewVisible: false,
			uploadLoading: false,
			previewImage: false,
			privateSupplier: false,
			allPhotos: [],
			industryId: 1,
			isCapex: nullSafeGetOrElse('formData.isCapex', props, false),
			buyerProjectIds: [],
			supplierFacilityId: nullSafeGet('formData.supplierFacilityId', props),
			supplier: nullSafeGetOrElse('supplierFacility', this.props.formData, {}),
			location: nullSafeGetOrElse('location', this.props.formData, {}),
			userApprovalInfo: {},
			exchangeRate: nullSafeGetOrElse(
				'currencyExchangeRateLocationToSupplier',
				this.props.formData,
				1
			),
			currencyExchangeRateBuyerToLocation: nullSafeGetOrElse(
				'currencyExchangeRateBuyerToLocation',
				this.props.formData,
				1
			),
			currentNTE: nullSafeGetOrElse('formData.nte', props, 0),
			locationNTE: nullSafeGetOrElse(
				'formData.nteInLocationCurrency',
				props,
				nullSafeGet('formData.nte', props) /
					nullSafeGetOrElse('currencyExchangeRateLocationToSupplier', this.props.formData, 1)
			),
			hasQuote: false,
			currentApprovalHierarchy: {},
			buttonLoading: false,
			editorRef: React.createRef(),
		};
	}
	confirmChange: any = () => {};
	cancelChange: any = () => {};

	componentDidMount() {
		const {
			fetchExchangeRate,
			formData,
			fetchBuyer,
			currentUser,
			form,
			getQuotes,
			getApprovalHierarchy,
		} = this.props;
		const { location, supplier, locationNTE } = this.state;

		getApprovalHierarchy({ isCapex: formData.isCapex, isPM: formData.isPM }).then((result) => {
			this.setState({ currentApprovalHierarchy: result });
			this.configureNTEWarningMessage();
		});

		fetchBuyer(nullSafeGet('facility.buyerCompanyId', currentUser)).then((buyer) => {
			this.setState({ industryId: buyer.industryId });
		});

		if (formData.locationId) {
			this.setState({ locationId: formData.locationId });
		}

		if (formData.assetId) {
			this.setState({ assetId: formData.assetId });
		}

		if (formData.areaId) {
			this.setState({ areaId: formData.areaId });
		}

		if (formData.problemTypeId) {
			this.setState({ problemTypeId: formData.problemTypeId });
		}
		if (formData.isCapex) {
			this.setState({ isCapex: formData.isCapex });
		}
		if (formData.buyerProjectIds) {
			this.setState({ buyerProjectIds: formData.buyerProjectIds });
		}

		let photosInfo;
		if (formData.images) {
			photosInfo = formData.images.map((p, idx) => {
				const [imgFileId, imgFileName] = p.split('/');
				const thumbUri = getProtectedImageUriForBuyer(imgFileId, imgFileName, 100, 100);
				const fullUri = getProtectedImageUriForBuyer(imgFileId, imgFileName, 400, 400);
				return {
					uid: idx,
					size: 1,
					name: imgFileName,
					filename: imgFileName,
					url: fullUri,
					status: 'done',
					thumbUrl: thumbUri,
					response: { data: { fileName: imgFileName, fileId: imgFileId } },
					type: 'image/jpeg',
				};
			});
		} else {
			photosInfo = [];
		}
		this.setState({ allPhotos: photosInfo });

		getQuotes(
			{
				workOrderId: formData.id,
				supplierFacilityId: formData.supplierFacilityId,
			},
			'workOrderAssociatedQuotes'
		).then((proposals) => {
			if (proposals.length > 0) {
				this.setState({ hasQuote: true });
			} else {
				const locationCurrency = getCurrency({ location });
				const supplierCurrency = getCurrency({ supplier });
				const workOrderCurrency = getCurrency({ workOrder: formData });
				if (supplierCurrency && workOrderCurrency && workOrderCurrency.id !== supplierCurrency.id) {
					//Should only be possible if Suppliers Currency has changed
					fetchExchangeRate(
						supplierCurrency.id,
						workOrderCurrency.id,
						currentUser.contactType
					).then((rate) =>
						this.setState(
							{
								exchangeRate: nullSafeGetOrElse('conversionRate', rate, 1),
								currentNTE: locationNTE * nullSafeGetOrElse('conversionRate', rate, 1),
							},
							() => {
								form.setFieldsValue({
									nte: this.state.currentNTE,
									nteInLocationCurrency: locationNTE,
								});
							}
						)
					);
				}
			}
		});
	}

	configureNTEWarningMessage = () => {
		const { currentUser } = this.props;
		const { currentApprovalHierarchy, nextApprovalHierarchy } = this.state;
		const tiers = nullSafeGet('tierDetails', nextApprovalHierarchy || currentApprovalHierarchy);
		if (tiers && tiers.length > 0) {
			const userTier =
				_.find(tiers, (item) => _.find(item.approvers, { email: currentUser.email })) || tiers[0];
			this.setState({
				userApprovalInfo: { amount: userTier.amountUpto || 1000000000 },
			});
		}
	};

	getStatus = (supplierFacilityId, oldStatus) => {
		const { privateSupplier } = this.state;
		let newStatus;
		if (
			PRE_SUPPLIER_ASSIGNMENT_STATUSES.includes(oldStatus) &&
			supplierFacilityId &&
			privateSupplier
		) {
			newStatus = WORK_ORDER_STATUSES.assignedToInternalTech;
		} else if (PRE_SUPPLIER_ASSIGNMENT_STATUSES.includes(oldStatus) && supplierFacilityId) {
			newStatus = WORK_ORDER_STATUSES.pendingConfirmationByServiceProvider;
		} else if (
			oldStatus === WORK_ORDER_STATUSES.supplierInitiatedPendingApproval &&
			supplierFacilityId
		) {
			newStatus = WORK_ORDER_STATUSES.confirmedByServiceProvider;
		} else if (!supplierFacilityId) {
			newStatus = WORK_ORDER_STATUSES.unassigned;
		} else {
			newStatus = oldStatus;
		}
		return newStatus;
	};

	getAdditionalInfo = () =>
		nullSafeGet('state.editorRef.current', this) &&
		(this.state.editorRef.current as any).getContent();

	handleSubmit = (e) => {
		const { form, formData, history, redirectForwardUrl, onSuccess, updateWorkOrderForm } =
			this.props;
		const {
			warranty,
			supplierFacilityId,
			exchangeRate,
			supplier,
			location,
			currentNTE,
			hasQuote,
			isCapex,
		} = this.state;

		const locationCurrency = getCurrency({ location });
		const supplierCurrency = getCurrency({ supplier });
		const workOrderCurrency = getCurrency({ workOrder: formData });

		e.preventDefault();
		form.validateFields((err, values) => {
			if (!err) {
				// put image data into proper format to persist
				const picturesInfo = values.images.map((p) => {
					const fileName = nullSafeGetOrElse('response.data.fileName', p, '');
					const fileId = nullSafeGetOrElse('response.data.fileId', p, '');
					return `${fileId}/${fileName}`;
				});
				let workOrder = {
					id: values.id,
					title: values.title,
					status: this.getStatus(values.supplierFacilityId, values.status),
					areaId: values.areaId ? parseInt(values.areaId, 10) : undefined,
					problemTypeId: parseInt(values.problemTypeId, 10),
					woPriorityId: parseInt(values.workOrderPriorityId, 10),
					locationId: parseInt(values.locationId, 10),
					assetId: values.assetId ? parseInt(values.assetId, 10) : undefined,
					...(values.assetId && {
						isAssetDown: values.isAssetDown === 'true' ? true : false,
					}),
					images: picturesInfo,
					isCapex: isCapex,
					dueDate: values.dueDate,
					nte: currentNTE !== null ? currentNTE : undefined,
					currencyId: !hasQuote ? supplierCurrency.id : workOrderCurrency.id,
					nteInLocationCurrency: values.nteInLocationCurrency
						? values.nteInLocationCurrency
						: undefined,
					locationCurrencyId: locationCurrency.id,
					currencyExchangeRateLocationToSupplier:
						locationCurrency.id === supplierCurrency.id ? 1 : exchangeRate,
					description: this.getAdditionalInfo(),
					buyerFacilityId: values.buyerFacilityId,
					buyerCompanyId: values.buyerCompanyId,
					createdBy: values.createdBy,
					supplierFacilityId: values.supplierFacilityId,
					contractorDetails: values.contractorDetails,
					notes: values.notes,
					poNumber: values.poNumber,
					buyerAttachments: values.buyerAttachments,
					supplierAttachments: values.supplierAttachments,
					buyerProjectIds: values.buyerProjectIds,
					isWarranty: checkIsWarrantyWorkOrder(warranty, supplierFacilityId),
					resolutionTypeId: values.resolutionType,
				};

				updateWorkOrderForm(workOrder).then((record) => {
					if (redirectForwardUrl) {
						history.push(redirectForwardUrl);
					}
					if (onSuccess) {
						onSuccess(record);
					}
				});
			}
		});
	};

	handlePreview = (obj) => {
		let thumbUrl = null;
		let fullUrl = null;
		let photoInfo = null;
		if (obj.response.data) {
			const imgFileId = obj.response.data.fileId;
			const imgFileName = obj.response.data.fileName;
			thumbUrl = getProtectedImageUriForBuyer(imgFileId, imgFileName, 100, 100);
			fullUrl = getProtectedImageUriForBuyer(imgFileId, imgFileName, 400, 400);
			photoInfo = {
				uid: imgFileId,
				size: 1,
				name: imgFileName,
				filename: imgFileName,
				url: fullUrl,
				status: 'done',
				thumbUrl: thumbUrl,
				response: { data: { fileName: imgFileName, fileId: imgFileId } },
				type: 'image/jpeg',
			};
		}

		this.setState({
			photoInfo: [photoInfo],
			previewImage: fullUrl || thumbUrl,
			previewVisible: true,
		});
	};

	handleCancel = () => this.setState({ previewVisible: false });

	handleImageUpdate = (info) => {
		if (info.file.status === 'done') {
			this.setState({ allPhotos: info.fileList });
		} else if (info.file.status === undefined) {
			info.fileList = this.state.allPhotos;
		}
	};

	isAreaFilterAllowed = () => {
		const { companyConfig, currentUser } = this.props;
		return getBuyerCompanySettingValue(ROLE_TYPES.BUYER)(
			'assetConfig.allowAreaAssociationWithAssets',
			companyConfig,
			currentUser,
			false
		);
	};

	handleLocationChange = (locationId) => {
		const { fetchLocation } = this.props;
		fetchLocation(locationId).then((loc) => {
			this.setState({ locationId: locationId, location: loc });
		});
	};

	handleAreaChange = (areaId) => {
		this.setState({ areaId: areaId });
	};

	handleProblemTypeChange = (problemTypeId) => {
		const { form } = this.props;
		this.confirmChange = () => {
			this.setState({ problemTypeId });
		};
		this.cancelChange = () => {
			form.setFieldsValue({ problemTypeId: this.state.problemTypeId });
		};
		this.changeApprovalHierarchyParamCheck({ problemTypeId: problemTypeId });
	};

	handleAssetChange = (assetId) => {
		this.setState({ assetId: assetId });
	};

	handleSupplierFacilityChange = (supplierFacilityId) => {
		const { fetchSupplierFacility } = this.props;
		if (supplierFacilityId) {
			fetchSupplierFacility(supplierFacilityId).then((supplierFacility) => {
				supplierFacility.status === 'private'
					? this.setState({ privateSupplier: true })
					: this.setState({ privateSupplier: false });
			});
		}
	};

	handleAdditionalInfoChange = debounce(
		(additionalInfo) => {
			const { form } = this.props;
			form.setFieldsValue({ additionalInfo });
		},
		500,
		false
	);

	handleExpenseTypeChange = (e) => {
		const { isCapex } = this.state;
		if (e.target.value !== isCapex) {
			this.setState({ isCapex: e.target.value });
		}
	};

	//param = {key: value}
	changeApprovalHierarchyParamCheck = (param) => {
		const { willApprovalHierarchyChange, formData, isApprovalForm, currentUser } = this.props;
		const { woChanges } = this.state;
		const changes = { ...woChanges, ...param };

		this.setState({ buttonLoading: true, pendingParam: param });
		willApprovalHierarchyChange(nullSafeGet('id', formData), changes).then((result) => {
			const quoteHierarchyChanged =
				nullSafeGetOrElse('quoteApprovalChanges.changedHierarchy.length', result, 0) > 0;
			const quoteChanged =
				nullSafeGet('quoteApprovalChanges.approversAlreadyPresent', result) &&
				nullSafeGetOrElse('quoteApprovalChanges.changedHierarchy.length', result, 0) > 0;
			const invoiceChanged =
				nullSafeGet('invoiceApprovalChanges.approversAlreadyPresent', result) &&
				nullSafeGetOrElse('invoiceApprovalChanges.changedHierarchy.length', result, 0) > 0;
			const workOrderHierarchyChanged =
				nullSafeGetOrElse('workOrderApprovalChanges.changedHierarchy.length', result, 0) > 0 &&
				isApprovalForm;

			this.setState({
				nextApprovalHierarchy: quoteHierarchyChanged
					? nullSafeGet('quoteApprovalChanges.changedHierarchy.0', result)
					: undefined,
				workOrderApprovalHierarchy: workOrderHierarchyChanged
					? nullSafeGet('workOrderApprovalChanges.changedHierarchy.0', result)
					: nullSafeGet('approvalHierarchy', formData),
			});
			if (quoteChanged || invoiceChanged || workOrderHierarchyChanged) {
				this.setState({
					showApprovalHierarchyChangeModal: true,
					approvalHierarchyChanges: [
						...(quoteChanged ? ['quote'] : []),
						...(invoiceChanged ? ['invoice'] : []),
						...(workOrderHierarchyChanged ? ['work order'] : []), //doesn't matter if approvers are present or not
					],
				});
			} else {
				this.changeApprovalHierarchyParamConfirm(param)();
				this.configureNTEWarningMessage();
			}
		});
	};
	changeApprovalHierarchyParamConfirm = (param) => () => {
		const { woChanges } = this.state;
		this.confirmChange();
		this.setState({
			...param,
			showApprovalHierarchyChangeModal: false,
			buttonLoading: false,
			pendingParam: undefined,
			woChanges: { ...woChanges, ...param },
		});
		this.configureNTEWarningMessage();
	};
	changeApprovalHierarchyParamDismiss = () => {
		this.cancelChange();
		this.configureNTEWarningMessage();
		this.setState({
			showApprovalHierarchyChangeModal: false,
			buttonLoading: false,
			pendingParam: undefined,
			nextApprovalHierarchy: undefined,
		});
	};

	render() {
		const {
			isApprovalForm,
			updating,
			form,
			formData,
			locations,
			assets,
			areas,
			problemTypes,
			workOrderPriorities,
			currentUser,
			fetchLocations,
			fetchMultipleLocations,
			fetchAssets,
			fetchMultipleAssets,
			fetchAreas,
			fetchMultipleAreas,
			fetchProblemTypes,
			fetchMultipleProblemTypes,
			fetchProjects,
			fetchMultipleProjects,
			fetchWorkOrderPriorities,
			fetchMultipleWorkOrderPriorities,
			supplierFacilities,
			fetchSupplierFacilitiesInPrivateNetwork,
			fetchMultipleSupplierFacilitiesInPrivateNetwork,
			projects,
			resolutionTypes,
			fetchMultipleResolutionTypes,
			fetchResolutionTypes,
			companyConfig,
		} = this.props;
		const { getFieldDecorator } = form;
		const {
			previewVisible,
			previewImage,
			supplier,
			location,
			currentNTE,
			locationNTE,
			exchangeRate,
			currencyExchangeRateBuyerToLocation,
			hasQuote,
			isCapex,
		} = this.state;
		const dateFormat = 'dddd, MMM D, YYYY, h:mm A';
		const BACKEND_URI = getBackendUri();
		const uploadHeaders = { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token };
		const submitLabel = isApprovalForm ? 'Approve work order' : 'Update work order';
		const isAdmin = currentUser.roles.some((role) => BUYER_ADMIN_ONLY.has(role));
		const { userApprovalInfo } = this.state;
		const locationCurrency = getCurrency({ location });
		const supplierCurrency = getCurrency({ supplier });
		const workOrderCurrency = getCurrency({ workOrder: formData });
		const resolutionsFetching = nullSafeGet('fetching', resolutionTypes);
		const hasResolutionTypes = resolutionsFetching
			? true
			: getObjectValues(nullSafeGetOrElse('records', resolutionTypes, {})).length > 0;
		getFieldDecorator('id', { initialValue: formData.id });
		getFieldDecorator('createdAt', { initialValue: formData.createdAt });
		getFieldDecorator('notes', { initialValue: formData.notes });
		getFieldDecorator('buyerCompanyId', {
			initialValue: formData.buyerCompanyId || nullSafeGet('facility.buyerCompanyId', currentUser),
		});
		getFieldDecorator('createdBy', {
			initialValue: formData.createdBy || nullSafeGet('email', currentUser),
		});
		getFieldDecorator('contractorDetails', {
			initialValue: formData.contractorDetails || {
				contractorContacts: [],
			},
		});

		getFieldDecorator('problemTypeId', {
			initialValue: formData.problemTypeId,
		});
		getFieldDecorator('isCapex', {
			initialValue: formData.isCapex,
		});
		getFieldDecorator('buyerProjectIds', {
			initialValue: formData.buyerProjectIds,
		});

		getFieldDecorator('buyerAttachments', { initialValue: formData.buyerAttachments || [] });
		getFieldDecorator('supplierAttachments', { initialValue: formData.supplierAttachments || [] });
		const calculatedDueDate = moment(
			new Date(
				new Date().getTime() +
					nullSafeGetOrElse('expectedResolutionInSeconds', formData.woPriority, 0) * 1000
			)
		);
		getFieldDecorator('dueDate', { initialValue: calculatedDueDate });

		const isStoreAssociate = nullSafeGetOrElse('roles', currentUser, []).includes(
			BUYER_STORE_ASSOCIATE
		);

		const poEntryEnabled = getConfigValueDefaultingToTrue(
			companyConfig,
			'config.workOrder',
			'allowPONUmberEntry'
		);

		const areaFilterAllowed = this.isAreaFilterAllowed();

		return (
			<Form layout="vertical" onSubmit={this.handleSubmit} className="supplierForm">
				<Form.Item label="Title">
					{getFieldDecorator('title', {
						initialValue: formData.title,
						rules: [{ required: true, message: 'This field is required.' }],
					})(<Input style={{ maxWidth: 800 }} disabled={true} />)}
				</Form.Item>
				<Form.Item label="Incident Location">
					{getFieldDecorator('locationId', {
						initialValue: formData.locationId,
						rules: [{ required: true, message: 'This field is required.' }],
					})(
						<OWAsyncSelect
							style={{ maxWidth: 800 }}
							disabled={true}
							disabledPlaceholder={formData.locationId}
							stateSlice={locations}
							targetCollectionName={WORK_ORDER_FORM_LOCATIONS_AUTOCOMPLETE}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleLocations(ids, targetCollectionName);
							}}
							onChange={this.handleLocationChange}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchLocations(
									{ name: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							renderRecord={(location) => (
								<Select.Option key={location.id} value={location.id}>
									{location.name}
								</Select.Option>
							)}
							sortBy={{ sort_by: 'name', order: 'ascend' }}
						/>
					)}
				</Form.Item>
				<Form.Item label="Area">
					{getFieldDecorator('areaId', {
						initialValue: formData.areaId,
					})(
						<OWAsyncSelect
							style={{ maxWidth: 800 }}
							stateSlice={areas}
							targetCollectionName={WORK_ORDER_FORM_AREAS_AUTOCOMPLETE}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleAreas(ids, targetCollectionName);
							}}
							onChange={this.handleAreaChange}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchAreas(
									{ name: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							renderRecord={(area) => (
								<Select.Option key={area.id} value={area.id}>
									{area.hierarchyName}
								</Select.Option>
							)}
							additionalFilters={{
								locationId: nullSafeGet('locationId', this.state),
								brandId: nullSafeGet('location.brandId', this.state),
							}}
							sortBy={{ sort_by: 'name', order: 'ascend' }}
						/>
					)}
				</Form.Item>
				<Form.Item required={true} label="Problem Type">
					{getFieldDecorator('problemTypeId', {
						initialValue: formData.problemTypeId,
						rules: [{ required: true, message: 'This field is required.' }],
					})(
						<ProblemTypeTreeSelect
							style={{ maxWidth: 800 }}
							mode="default"
							stateSlice={problemTypes}
							targetCollectionName={WORK_ORDER_FORM_PROBLEM_TYPES_AUTOCOMPLETE}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleProblemTypes(ids, targetCollectionName);
							}}
							onChange={this.handleProblemTypeChange}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) =>
								fetchProblemTypes(
									{ name: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								)
							}
							additionalFilters={{
								locationId: nullSafeGet('locationId', this.state),
								industryId: nullSafeGetOrElse('industryId', this.state, 1),
								brandId: nullSafeGet('location.brandId', this.state),
							}}
							sortBy={{ sort_by: 'name', order: 'ascend' }}
						/>
					)}
				</Form.Item>

				<Form.Item label="Affected Asset">
					{getFieldDecorator('assetId', {
						initialValue: formData.assetId,
					})(
						<OWAsyncSelect
							style={{ maxWidth: 800 }}
							stateSlice={assets}
							allowClear={true}
							targetCollectionName={WORK_ORDER_FORM_ASSETS_AUTOCOMPLETE}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleAssets(ids, targetCollectionName);
							}}
							onChange={this.handleAssetChange}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchAssets(
									{ search: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							renderRecord={(asset) => (
								<Select.Option key={asset.id} value={asset.id}>
									<div className="flex flex-col">
										<div>{asset.name}</div>
										<AssetNumberDisplay asset={asset} />
									</div>
								</Select.Option>
							)}
							additionalFilters={{
								locationId: nullSafeGetOrElse('locationId', this.state, formData.locationId),
								problemTypeId: nullSafeGetOrElse(
									'problemTypeId',
									this.state,
									formData.problemTypeId
								),
								...(areaFilterAllowed &&
									nullSafeGetOrElse('areaId', this.state, formData.areaId) && {
										areaIdOrNull: nullSafeGetOrElse('areaId', this.state, formData.areaId),
									}),
							}}
							sortBy={{ sort_by: 'name', order: 'ascend' }}
						/>
					)}
				</Form.Item>

				{nullSafeGet('assetId', formData) && (
					<Form.Item label="Is the asset functioning?">
						{form.getFieldDecorator('isAssetDown', {
							initialValue: formData.isAssetDown ? 'true' : 'false',
						})(
							<OWRadioGroup
								disabled
								valueAccessor={(item) => item.value}
								renderRecord={(item) => item.label}
								items={[
									{
										label: 'Yes',
										value: 'false',
									},
									{
										label: 'No',
										value: 'true',
									},
								]}
							/>
						)}
					</Form.Item>
				)}

				<Form.Item label="Additional Info">
					{getFieldDecorator('additionalInfo', {
						initialValue: formData.description,
					})(
						<OWRichTextEditor
							initialEditorState={formData.description}
							editorRef={this.state.editorRef}
						/>
					)}
				</Form.Item>
				<Form.Item label="Photos">
					{getFieldDecorator(`images`, {
						valuePropName: 'fileList',
						getValueFromEvent: normFile,
						initialValue: this.state.allPhotos,
					})(
						<OWUpload
							accept="image/*"
							name="file"
							listType="picture-card"
							className="workorder-image-uploader"
							multiple={true}
							headers={uploadHeaders}
							onPreview={this.handlePreview}
							action={`${BACKEND_URI}/api/v1/buyer/file/upload`}
							beforeUpload={imageUploadValidation}
							onChange={this.handleImageUpdate}
						>
							<div style={{ display: 'inline' }}>
								<LegacyIcon type={this.state.uploadLoading ? 'loading' : 'plus'} />
								<div className="ant-upload-text">Upload</div>
							</div>
							<Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
								<img alt="example" style={{ width: '100%' }} src={previewImage} />
							</Modal>
						</OWUpload>
					)}
				</Form.Item>
				<div className={'flex flex-row'}>
					<Form.Item
						label="NTE"
						validateStatus={userApprovalInfo.status}
						help={
							userApprovalInfo.status ? (
								<div
									style={{ width: 1000 }}
									className={'absolute flex flex-row flex-nowrap'}
								>{`This NTE change bypasses the requirement of a quote. Based on approval hierarchy, you can set NTE upto ${locationCurrency.format(
									userApprovalInfo.amount * currencyExchangeRateBuyerToLocation
								)}`}</div>
							) : (
								''
							)
						}
						extra={
							nullSafeGet('supplier.status', this.state) === 'private' ? (
								<div className="mt-2 text-xs text-gray-500">
									NTE cannot be set for an internal service provider
								</div>
							) : null
						}
					>
						{getFieldDecorator('nteInLocationCurrency', {
							initialValue: locationNTE,
							validateTrigger: 'onChange',
							rules: [
								{
									validator: (rule, value, callback) => {
										if (
											userApprovalInfo.amount &&
											parseFloat(value) / currencyExchangeRateBuyerToLocation >
												parseFloat(userApprovalInfo.amount) &&
											parseFloat(value) / currencyExchangeRateBuyerToLocation !== formData.nte
										) {
											this.setState({
												userApprovalInfo: {
													...userApprovalInfo,
													status: isAdmin ? 'warning' : 'error',
												},
											});
											callback(isAdmin ? undefined : '');
										} else {
											this.setState({
												userApprovalInfo: {
													...userApprovalInfo,
													status: null,
												},
											});
											callback();
										}
									},
								},
							],
						})(
							<InputNumber
								style={{ width: 200 }}
								disabled={
									nullSafeGet('supplier.status', this.state) === 'private' || isStoreAssociate
								}
								addonAfter={locationCurrency.id}
								onChange={(value) =>
									this.setState({
										locationNTE: value,
										currentNTE: (value as any) * exchangeRate,
									})
								}
							/>
						)}
					</Form.Item>
					{supplierCurrency && locationCurrency && supplierCurrency.id !== locationCurrency.id ? (
						<div className="flex flex-row" style={{ marginTop: 30 }}>
							<i
								className="icons8-font icons8-data-transfer"
								style={{ fontSize: 24, marginTop: 2, marginRight: 26 }}
							/>
							<InputNumber
								style={{ width: 200, height: 38 }}
								disabled={true}
								value={currentNTE.toFixed(2)}
								addonAfter={hasQuote ? workOrderCurrency.id : supplierCurrency.id}
							/>
						</div>
					) : null}
				</div>
				<ApprovalHierarchyChangeModal
					visible={this.state.showApprovalHierarchyChangeModal}
					onOk={this.changeApprovalHierarchyParamConfirm}
					param={this.state.pendingParam}
					onCancel={this.changeApprovalHierarchyParamDismiss}
					approvalHierarchyChanges={this.state.approvalHierarchyChanges}
				/>
				<Form.Item label="Expense Type">
					{getFieldDecorator('isCapex', {
						initialValue: formData.isCapex ? 'true' : 'false',
					})(
						<Row>
							<Spin spinning={this.state.buttonLoading}>
								<Radio.Group value={isCapex} style={{ display: 'flex' }}>
									{isCapex === false ? (
										<Radio.Button style={{ flexWrap: 'nowrap' }} value={false} onClick={() => {}}>
											R&M
										</Radio.Button>
									) : (
										<Radio.Button
											style={{ flexWrap: 'nowrap' }}
											value={false}
											onClick={() => this.changeApprovalHierarchyParamCheck({ isCapex: false })}
										>
											R&M
										</Radio.Button>
									)}
									{isCapex === true ? (
										<Radio.Button style={{ flexWrap: 'nowrap' }} value={true} onClick={() => {}}>
											CapEx
										</Radio.Button>
									) : (
										<Radio.Button
											style={{ flexWrap: 'nowrap' }}
											value={true}
											onClick={() => this.changeApprovalHierarchyParamCheck({ isCapex: true })}
										>
											CapEx
										</Radio.Button>
									)}
								</Radio.Group>
							</Spin>
							<Popover
								content={
									<div>
										<Row>
											<span style={{ fontSize: '20px' }}>Repair and Maintenance (R&M)</span>
										</Row>
										<Row>
											<span>Costs associated with repair and maintenance of existing assets</span>
										</Row>
										<Row style={{ height: '20px' }}></Row>
										<Row>
											<span style={{ fontSize: '20px' }}>Capital Expenditures (CapEx)</span>
										</Row>
										<Row>
											<span>
												Costs associated with buying new assets or upgrading existing assets
											</span>
										</Row>
									</div>
								}
							>
								<InfoCircleOutlined
									style={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }}
								/>
							</Popover>
						</Row>
					)}
				</Form.Item>
				<Form.Item label="Priority">
					{getFieldDecorator('workOrderPriorityId', {
						initialValue: formData.woPriorityId,
						rules: [{ required: true, message: 'This field is required.' }],
					})(
						<OWAsyncSelect
							style={{ maxWidth: 800 }}
							disabled={true}
							disabledPlaceholder={formData.woPriorityId}
							stateSlice={workOrderPriorities}
							targetCollectionName={WORK_ORDER_FORM_WORK_ORDER_PRIORITIES_AUTOCOMPLETE}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleWorkOrderPriorities(ids, targetCollectionName);
							}}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchWorkOrderPriorities(
									{ name: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							renderRecord={(workOrderPriority) => (
								<Select.Option key={workOrderPriority.id} value={workOrderPriority.id}>
									{workOrderPriority.name}
								</Select.Option>
							)}
						/>
					)}
				</Form.Item>
				<Form.Item required={true} label="Supplier">
					{getFieldDecorator('supplierFacilityId', {
						initialValue: formData.supplierFacilityId,
					})(
						<SupplierSelectionWorkOrderSelect
							style={{ maxWidth: 800 }}
							disabled={true}
							disabledPlaceholder={formData.supplierFacilityId}
							allowClear={true}
							stateSlice={supplierFacilities}
							targetCollectionName={
								WORK_ORDER_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE
							}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleSupplierFacilitiesInPrivateNetwork(ids, targetCollectionName);
							}}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) =>
								fetchSupplierFacilitiesInPrivateNetwork(
									{ name: searchText, offset: 0, limit: 100 },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								)
							}
							onChange={this.handleSupplierFacilityChange}
							additionalFilters={{
								problemTypeId: this.state.problemTypeId,
								locationId: this.state.locationId,
							}}
							sortBy={{ sort_by: 'name', order: 'ascend' }}
						/>
					)}
				</Form.Item>
				<Form.Item label="Due date">
					{getFieldDecorator('dueDate', {
						rules: [{ required: true, message: 'This field is required.' }],
					})(
						<DatePicker
							style={{ width: 300 }}
							format={dateFormat}
							showTime={true}
							disabled={true}
						/>
					)}
				</Form.Item>
				{poEntryEnabled ? (
					<Form.Item label="PO Number">
						{getFieldDecorator('poNumber', {
							initialValue: formData.poNumber,
						})(<Input style={{ maxWidth: 300 }} />)}
					</Form.Item>
				) : null}
				<Form.Item label="Project">
					{getFieldDecorator('buyerProjectIds', {
						initialValue: formData.buyerProjectIds,
					})(
						<OWAsyncSelect
							style={{ maxWidth: 800 }}
							mode={'multiple'}
							stateSlice={projects}
							targetCollectionName={WORK_ORDER_FORM_PROJECTS_AUTOCOMPLETE}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleProjects(ids, targetCollectionName);
							}}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) => {
								fetchProjects(
									{ name: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								);
							}}
							renderRecord={(project) => (
								<Select.Option key={project.id} value={project.id}>
									{project.title}
								</Select.Option>
							)}
						/>
					)}
				</Form.Item>
				{hasResolutionTypes ? (
					<Form.Item label="Resolution Type">
						{getFieldDecorator('resolutionType', {
							initialValue: formData.resolutionTypeId,
						})(
							<OWAsyncSelect
								style={{ maxWidth: 800 }}
								stateSlice={resolutionTypes}
								targetCollectionName={'RESOLUTION_TYPES_AUTOCOMPLETE'}
								fetchMultiple={(ids, targetCollectionName) => {
									fetchMultipleResolutionTypes(ids, targetCollectionName);
								}}
								onChange={this.handleResolutionTypeChange}
								fetchData={(
									searchText,
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								) => {
									fetchResolutionTypes(
										{ name: searchText },
										targetCollectionName,
										pagination,
										sorting,
										filters,
										addToTargetCollection
									);
								}}
								renderRecord={(resolutionType) => (
									<Select.Option key={resolutionType.id} value={resolutionType.id}>
										{resolutionType.resolutionCode ? `${resolutionType.resolutionCode} - ` : null}
										{resolutionType.resolutionType}
									</Select.Option>
								)}
								sortBy={{ sort_by: 'name', order: 'ascend' }}
								allowClear
							/>
						)}
					</Form.Item>
				) : null}
				{getFieldDecorator('status', {
					initialValue: formData.status,
				})}
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						loading={updating}
						className="contactForm__button"
					>
						{submitLabel}
					</Button>
					<span style={{ marginLeft: '16px' }}>
						<BackButton buttonText="Cancel" />
					</span>
				</Form.Item>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,
	isApprovalForm: ownProps.isApprovalForm,
	createErrors: state.work_orders.createErrors,
	updateErrors: state.work_orders.updateErrors,
	formData: ownProps.formData,
	creating: state.work_orders.creating,
	updating: state.work_orders.updating,
	locations: state.locations,
	assets: state.assets,
	areas: state.areas,
	problemTypes: state.problem_types,
	spendCategories: state.spend_categories,
	workOrderPriorities: state.work_order_priorities,
	projects: state.projects,
	supplierFacilities: state.supplier_facilities,
	currentUser: state.session.currentUser,
	resolutionTypes: state.resolution_types,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			locationsRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleResolutionTypes: (ids, targetCollectionName) =>
		dispatch(resolutionTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchResolutionTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			resolutionTypesRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleAssets: (ids, targetCollectionName) =>
		dispatch(assetsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchAssets: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			assetsRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchBuyer: (id) => dispatch(buyersRestCrudThunksForBuyer.readOne(id)),
	fetchMultipleAreas: (ids, targetCollectionName) =>
		dispatch(areasRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchAreas: (params, targetCollectionName, pagination, sorting, filters, addToTargetCollection) =>
		dispatch(
			areasRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleProblemTypes: (ids, targetCollectionName) =>
		dispatch(problemTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchProblemTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			problemTypesHeirarchicalForBuyer(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleSpendCategories: (ids, targetCollectionName) =>
		dispatch(spendCategoriesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchSpendCategories: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			spendCategoriesRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchWorkOrderAttributes: (params) =>
		dispatch(workOrderAttributesRestCrudThunksForBuyer.read(params)),
	fetchMultipleProjects: (ids, targetCollectionName) =>
		dispatch(projectsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchProjects: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			projectsRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleWorkOrderPriorities: (ids, targetCollectionName) =>
		dispatch(workOrderPrioritiesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchWorkOrderPriorities: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			workOrderPrioritiesRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchSupplierFacilitiesInPrivateNetwork: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters
	) =>
		dispatch(
			fetchSupplierFacilitiesInPrivateNetworkForBuyer(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters
			)
		),
	fetchMultipleSupplierFacilitiesInPrivateNetwork: (ids, targetCollectionName) =>
		dispatch(supplierFacilitiesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	updateWorkOrderForm: (workOrder) => dispatch(workOrdersRestCrudThunksForBuyer.update(workOrder)),
	fetchSupplierFacility: (id) => dispatch(supplierFacilitiesRestCrudThunksForBuyer.readOne(id)),
	fetchSupplierCompany: (id) => dispatch(suppliersRestCrudThunksForBuyer.readOne(id)),
	fetchExchangeRate: (c1, c2, userType) => dispatch(getExchangeRate(c1, c2)(userType)),
	getQuotes: (params, targetCollectionName) =>
		dispatch(proposalsRestCrudThunksForBuyer.read(params, targetCollectionName)),
	getApprovalHierarchy: (params) =>
		dispatch(getProposalApprovalHierarchiesByAttributes('buyer', params)),
	willApprovalHierarchyChange: (workOrderId, params) =>
		dispatch(hasApprovalHierarchyChanged('buyer', workOrderId, params)),
	fetchApprovalHeirarchy: (id) => dispatch(getBuyerCompanyApprovalTiers(ROLE_TYPES.BUYER, null)()),
	fetchLocation: (id) => dispatch(locationsRestCrudThunksForBuyer.readOne(id)),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<WorkOrderSafeFormProps>()(WorkOrderEditSafeForm))
);

import { nullSafeGet } from './DataAccessUtils';

/* tslint:disable */
const INTERCOM_VFA_APP_KEY = 'sx13nf0t';
const INTERCOM_GENERAL_APP_KEY = 'sre5lj21';

class IntercomService {
	APP_ID: string;

	init(): void {
		// do we have to init with a specific workspace key or not, basis on that we need to pick app key
		var w = window;
		var ic = w.Intercom;
		if (typeof ic === 'function') {
			ic('reattach_activator');
			ic('update', w.intercomSettings);
		} else {
			var d = document;
			var i = function () {
				//   @ts-ignore
				i.c(arguments);
			};
			//   @ts-ignore
			i.q = [];
			//   @ts-ignore
			i.c = function (args) {
				//   @ts-ignore
				i.q.push(args);
			};
			w.Intercom = i;
			var l = function () {
				var s = d.createElement('script');
				s.type = 'text/javascript';
				s.async = true;
				s.src = `https://widget.intercom.io/widget/${INTERCOM_GENERAL_APP_KEY}`;
				var x = d.getElementsByTagName('script')[0];
				x.parentNode.insertBefore(s, x);
			};
			if (document.readyState === 'complete') {
				l();
				//   @ts-ignore
			} else if (w.attachEvent) {
				//   @ts-ignore
				w.attachEvent('onload', l);
			} else {
				w.addEventListener('load', l, false);
			}
		}
	}
	_isVFAAccount(user: any): boolean {
		return nullSafeGet('facility.buyerCompany.isVFA', user);
	}
	boot({ user }: any): void {
		this.APP_ID = this._isVFAAccount(user) ? INTERCOM_VFA_APP_KEY : INTERCOM_GENERAL_APP_KEY;
		window['Intercom']('boot', {
			app_id: this.APP_ID,
			hide_default_launcher: true,
			...user,
		});
	}
	shutdown(): void {
		window['Intercom']('shutdown');
	}
	reboot(): void {
		this.shutdown();
		this.APP_ID = INTERCOM_GENERAL_APP_KEY;
		this.boot({ user: {} });
	}
}

export default new IntercomService();
/* tslint:enable */

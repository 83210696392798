import { Button, Form, Input, Modal, Row } from 'antd';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import UploadWithSampleModal from '../parts_index_page/UploadWithSampleModal';
import { CSV_EXPORT_DEFAULTS } from '../../utils/DataConstants';
import { ExportToCsv } from 'export-to-csv';

const CSV_TITLE = 'Serial_Numbers_Sample';

const FORM_ID = 'update-serial-numbers-form';

const FIELD_NAME_PREFIX = 'serial-number-';
const getFieldName = (idx) => `${FIELD_NAME_PREFIX}${idx}`;

const ReturnEquipmentSerialNumbersModal: FC<any> = ({
	quantity = 0,
	onCancel,
	onSuccess,
}): React.ReactElement => {
	const [form] = Form.useForm();

	const { setFieldsValue } = form;

	const [uploadPopupVisible, setUploadPopupVisible] = useState(false);

	const showUpload = useCallback(() => setUploadPopupVisible(true), []);
	const hideUpload = useCallback(() => setUploadPopupVisible(false), []);

	const onSubmit = useCallback(
		(values) => {
			onSuccess && onSuccess(Object.values(values));
		},
		[onSuccess]
	);

	const allFieldNames = useMemo(
		() => Array.from(Array(quantity).keys()).map((_) => getFieldName(_)),
		[quantity]
	);

	const fields = useMemo(
		() => [
			{
				label: 'S.No.',
				key: 'sno',
			},
			{
				label: 'Serial Number',
				key: 'serialNumber',
			},
		],
		[]
	);

	const onDownloadSample = useCallback(() => {
		const csvExporter = new ExportToCsv({
			...CSV_EXPORT_DEFAULTS,
			filename: CSV_TITLE,
			title: CSV_TITLE,
			headers: fields.map((_) => _.label),
		});
		csvExporter.generateCsv(
			allFieldNames.map((_, idx) => {
				return {
					sno: idx + 1,
					serialNumber: '',
				};
			}, [])
		);
	}, [allFieldNames, fields]);

	const onUploadSerialNumbers = useCallback(
		(serialNumbers) => {
			const values = allFieldNames.reduce(
				(acc, keyName, idx) =>
					idx < serialNumbers.length
						? {
								...acc,
								[keyName]: serialNumbers[idx].serialNumber,
						  }
						: acc,
				{}
			);
			setFieldsValue(values);
		},
		[allFieldNames, setFieldsValue]
	);

	const onDataLoad = useCallback(
		(results) => {
			onUploadSerialNumbers(results.validData);
			setTimeout(() => hideUpload(), 0);
			return new Promise((resolve) => resolve('Serial Numbers updated successfully'));
		},
		[hideUpload, onUploadSerialNumbers]
	);

	return (
		<Modal
			visible={true}
			width={600}
			title="Serial Numbers to be returned"
			closable
			onCancel={onCancel}
			forceRender
			footer={null}
		>
			<Form form={form} id={FORM_ID} layout="horizontal" requiredMark={false} onFinish={onSubmit}>
				{allFieldNames.map((fieldName, idx) => (
					<Form.Item
						labelAlign="left"
						labelCol={{ span: 2 }}
						key={fieldName}
						name={fieldName}
						label={`${idx + 1}.`}
						rules={[
							{
								required: true,
								message: `Please enter serial number`,
							},
							({ getFieldsValue }) => ({
								message: 'You have already entered this serial number!',
								validator: (_, value) => {
									const allValues = getFieldsValue(allFieldNames);
									const allSerialNumbers = Object.values(allValues);
									const found = allSerialNumbers.find((x) => x === value);
									return found && allSerialNumbers.indexOf(found) !== idx
										? Promise.reject()
										: Promise.resolve();
								},
							}),
						]}
					>
						<Input />
					</Form.Item>
				))}

				<Row
					justify="space-between"
					align="middle"
					style={{
						borderTop: 'solid 1px rgba(0, 0, 0, 0.1)',
						paddingTop: '8px',
						margin: '0 0 -8px',
					}}
				>
					<div>
						<Button onClick={showUpload} icon={<UploadOutlined translate="" />}>
							Upload Serial Numbers
						</Button>
					</div>
					<div>
						<Button size="large" onClick={onCancel}>
							Cancel
						</Button>
						<Button
							type="primary"
							size="large"
							style={{ marginLeft: '16px' }}
							key="submit"
							htmlType="submit"
							form={FORM_ID}
						>
							Proceed to Return
						</Button>
					</div>
					{uploadPopupVisible && (
						<UploadWithSampleModal
							type="Serial numbers"
							title="Upload Serial Numbers"
							onCancel={hideUpload}
							onDownloadSample={onDownloadSample}
							fields={fields}
							onDataLoad={onDataLoad}
							sampleFileText="Download Template File"
						/>
					)}
				</Row>
			</Form>
		</Modal>
	);
};

export default ReturnEquipmentSerialNumbersModal;

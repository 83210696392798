import React from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
	Switch,
	Spin,
	Input,
	Modal,
	Alert,
	Button,
	Select,
	DatePicker,
	InputNumber,
	Popover,
	message,
} from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import {
	getCurrency,
	mergeTypeFields,
	mergeTypeFieldsInit,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import { getBackendUri } from '../../utils/EnvConfigUtils';
import BackButton from '../back_button/BackButton';
import { getRecordsForTargetCollection } from '../../reducers/standard_reducer_utils';
import { debounce } from '../../utils/PerformanceUtils';
import { assetsRestCrudThunksForBuyer } from '../../thunks/assets_thunks';
import { assetTypesRestCrudThunksForBuyer } from '../../thunks/asset_types_thunks';
import { imageUploadValidation, normFile } from '../../utils/ImageUtils';
import { getProtectedImageUriForBuyer } from '../../utils/FileAccessUtils';
import { locationsRestCrudThunksForBuyer } from '../../thunks/locations_thunks';
import moment from 'moment';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { assetModelsRestCrudThunksForBuyer } from '../../thunks/asset_models_thunks';
import { retrieveCachedUserDetails } from '../../thunks/session_thunks';
import {
	fetchSupplierFacilitiesInPrivateNetworkForBuyer,
	supplierFacilitiesRestCrudThunksForBuyer,
} from '../../thunks/supplier_facilities_thunks';
import OWDateRangePicker from '../ow_date_range_picker/OWDateRangePicker';
import { getDefaultDatePeriods, DEFAULT_WARRANTY_PERIODS } from '../../utils/DataFormatterUtils';
import { InfoCircleOutlined } from '@ant-design/icons';
import { warrantiesRestCrudThunksForBuyer } from '../../thunks/warranties_thunks';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import {
	getBuyerCompanySettingValue,
	isBuyerAllowedToAccess,
	PERMISSION_NAMES,
} from '../../utils/AuthUtils';
import { partWarrantiesRestCrudThunksForBuyer } from '../../thunks/part_warranties_thunks';
import SameAssetNumberResolveModal from './SameAssetNumberResolveModal';
import TransferAssetToLocationForm from '../supplier_assets_detail_page/TransferAssetToLocationForm';
import OWUpload from '../OWUpload';
import { areasRestCrudThunksForBuyer } from '../../thunks/areas_thunks';
import { ROLE_TYPES } from '../../utils/DataConstants';

require('./AssetForm.less');
const ASSET_FORM_ASSET_TYPES_AUTOCOMPLETE = 'ASSET_FORM_ASSET_TYPES_AUTOCOMPLETE';
const ASSET_FORM_LOCATIONS_AUTOCOMPLETE = 'ASSET_FORM_LOCATIONS_AUTOCOMPLETE';
const ASSET_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE =
	'ASSET_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE';
const dateFormat = 'MMMM DD, YYYY';

const FORM_MAX_WIDTH = { width: 640, maxWidth: 640 };

interface AssetFormProps extends FormComponentProps {
	creating: boolean;
	updating: boolean;
	formData: any;
	history: any;
	fetchSpendCategories: any;
	fetchAssets: any;
	fetchAssetType: any;
	fetchAssetTypes: any;
	fetchAssetModel: any;
	fetchAssetModels: any;
	fetchMultipleAssetModels: any;
	spendCategories: any;
	assetTypesFetching: any;
	assetTypes: any;
	assetModelsFetching: any;
	assetModels: any;
	fetchLocations: any;
	fetchMultipleLocations: any;
	locationsFetching: any;
	locations: any;
	redirectForwardUrl?: string;
	onSuccess?: any;
	createErrors: any[];
	updateErrors: any[];
	currentUser: any;
	create: any;
	update: any;
	createWarranty: any;
	updateWarranty: any;
	deleteWarranty: any;
	createPartWarranty: any;
	updatePartWarranty: any;
	deletePartWarranty: any;
	fetchSupplierFacility: any;
	fetchSupplierFacilitiesInPrivateNetwork: any;
	supplierFacilities: any;
	supplierFacilitiesFetching: any;
	areas: any;
	fetchAreas: any;
	fetchMultipleAreas: any;
	companyConfig: any;
}

class AssetForm extends React.Component<AssetFormProps, any> {
	state = {
		assetType: { optionalFields: [], requiredFields: [] },
		previewVisible: false,
		uploadLoading: false,
		previewImage: null,
		allPhotos: [],
		disableAssetModel: true,
		conflictingAsset: {},
		conflictingAssetModalVisible: false,
		assetTypeId: null,
		hasWarranty: false,
		hasPartWarranty: false,
		supplierFacility: undefined,
		updateButtonLoading: false,
		transferModalVisible: false,
	};

	componentDidMount() {
		const { formData, fetchAssetType, fetchAssetTypes, fetchSupplierFacilitiesInPrivateNetwork } =
			this.props;

		const warranty = nullSafeGetOrElse('warranties.0', formData, undefined);

		// TODO: HYDRATE THESE IN THE ROUTE AND REMOVE THIS SHIT
		const assetTypeId = formData.assetTypeId;
		fetchAssetTypes({ limit: 50, offset: 0 });
		fetchSupplierFacilitiesInPrivateNetwork({});

		if (assetTypeId) {
			fetchAssetType(assetTypeId).then((at) => {
				const currentAssetTypeDetails = formData.assetTypeDetails || [];
				const mergedAssetType = mergeTypeFieldsInit(currentAssetTypeDetails, at);

				this.setState({
					assetType: mergedAssetType,
					assetTypeId: at.id,
					disableAssetModel: false,
				});
			});
		}

		let photosInfo;
		if (formData.images) {
			photosInfo = formData.images.map((p, idx) => {
				const [imgFileId, imgFileName] = p.split('/');
				const thumbUri = getProtectedImageUriForBuyer(imgFileId, imgFileName, 100, 100);
				const fullUri = getProtectedImageUriForBuyer(imgFileId, imgFileName, 400, 400);
				return {
					uid: idx,
					size: 1,
					name: imgFileName,
					filename: imgFileName,
					url: fullUri,
					status: 'done',
					thumbUrl: thumbUri,
					response: { data: { fileName: imgFileName, fileId: imgFileId } },
					type: 'image/jpeg',
				};
			});
		} else {
			photosInfo = [];
		}

		this.setState({
			allPhotos: photosInfo,
			hasWarranty: nullSafeGetOrElse('warranties', formData, []).length > 0,
			hasPartWarranty: !!formData.partWarrantyId,
			supplierFacility: nullSafeGet('provider', warranty) ? { ...warranty.provider } : undefined,
		});
	}

	handleServiceWarranty = (values) => {
		const { deleteWarranty, updateWarranty, createWarranty, formData } = this.props;
		const submitWarrantyAction = nullSafeGet('warranties.0', formData)
			? updateWarranty
			: createWarranty;
		const warranty = {
			id: values.warrantyId,
			issueDate: nullSafeGetOrElse('warrantyPeriod.0', values, undefined),
			expirationDate: nullSafeGetOrElse('warrantyPeriod.1', values, undefined),
			provider: this.state.supplierFacility,
			NTEAmount: values.NTEAmount ? parseInt(values.NTEAmount, 10) : undefined,
		};
		return new Promise((resolve, reject) => {
			if (this.state.hasWarranty) {
				return submitWarrantyAction(warranty)
					.then((warrantyRecord) => {
						resolve(warrantyRecord);
					})
					.catch((err) => reject(err));
			} else if (warranty.id) {
				//Warranty exists
				return deleteWarranty(warranty)
					.then(() => resolve(true))
					.catch((err) => reject(err));
			} else resolve(true);
		});
	};

	handlePartWarranty = (values) => {
		const { deletePartWarranty, currentUser, updatePartWarranty, createPartWarranty, formData } =
			this.props;
		const submitWarrantyAction = nullSafeGet('partWarrantyId', formData)
			? updatePartWarranty
			: createPartWarranty;
		const warranty = {
			id: nullSafeGet('partWarrantyId', formData),
			issueDate: nullSafeGetOrElse('partWarrantyPeriod.0', values, undefined),
			expirationDate: nullSafeGetOrElse('partWarrantyPeriod.1', values, undefined),
			vendorId: nullSafeGetOrElse('vendorId', values, undefined),
			buyerCompanyId: nullSafeGet('facility.supplierCompany.privateBuyerCompanyId', currentUser),
		};
		return new Promise((resolve, reject) => {
			if (this.state.hasPartWarranty) {
				return submitWarrantyAction(warranty)
					.then((warrantyRecord) => {
						resolve(warrantyRecord);
					})
					.catch((err) => reject(err));
			} else if (warranty.id) {
				//Part Warranty exists
				return deletePartWarranty(warranty)
					.then(() => resolve(true))
					.catch((err) => reject(err));
			} else resolve(true);
		});
	};

	handleWarranties = (values): any => {
		return new Promise((resolve, reject) => {
			Promise.all([this.handleServiceWarranty(values), this.handlePartWarranty(values)])
				.then((result) => {
					resolve({
						serviceWarranty: nullSafeGet('0', result),
						partWarranty: nullSafeGet('1', result),
					});
				})
				.catch((err) => reject(err));
		});
	};

	onFormFinish = (asset) => {
		const { history, onSuccess, redirectForwardUrl } = this.props;
		if (redirectForwardUrl) {
			history.push(redirectForwardUrl);
		}
		if (onSuccess) {
			onSuccess(asset);
		}
	};

	handleSubmit = (e, submitAction) => {
		const { form, fetchAssets, formData } = this.props;
		const { assetType } = this.state;
		if (e.preventDefault) {
			e.preventDefault();
		}
		this.setState({ updateButtonLoading: true });
		this.props.form.validateFields((err, values) => {
			if (!err) {
				// put image data into proper format to persist
				const picturesInfo = values.images.map((p) => {
					const fileName = nullSafeGetOrElse('response.data.fileName', p, '');
					const fileId = nullSafeGetOrElse('response.data.fileId', p, '');
					return `${fileId}/${fileName}`;
				});

				const assetTypeDetails = (assetType.requiredFields || [])
					.concat(assetType.optionalFields || [])
					.map((field) => ({
						key: form.getFieldValue(`${field.key}-key`),
						valueType: form.getFieldValue(`${field.key}-valueType`),
						valueAsString: form.getFieldValue(`${field.key}-valueAsString`),
					}));

				const asset = {
					id: values.id,
					name: values.name,
					assetUUID: values.assetUUID,
					description: values.description,
					isActive: values.isActive,
					manufacturer: values.manufacturer,
					upcNumber: values.upcNumber,
					modelNumber: values.modelNumber,
					serialNumber: values.serialNumber,
					assetNumber: values.assetNumber,
					buyerFacilityId: values.buyerFacilityId,
					images: picturesInfo,
					assetTypeId: parseInt(values.assetTypeId, 10),
					assetTypeDetails: assetTypeDetails.filter(
						(field) => field.valueAsString !== undefined && field.valueAsString !== ''
					),
					assetModelId: values.assetModelId ? parseInt(values.assetModelId, 10) : undefined,
					locationId: parseInt(values.locationId, 10),
					areaId: values.areaId ? parseInt(values.areaId) : undefined,
					installDate: values.installDate,
					isLeased: values.isLeased,
					purchaseDate: values.purchaseDate,
					lifeExpectancyInMonths: values.lifeExpectancyInMonths,
					...(!!values.originalPrice && { originalPrice: parseFloat(values.originalPrice) }),
					...(!!values.capitalisedCost && { capitalisedCost: parseFloat(values.capitalisedCost) }),
					leaseStartDate: values.leaseStartDate,
					leaseEndDate: values.leaseEndDate,
					partWarrantyId: formData.partWarrantyId,
					vendorId: formData.vendorId,
				};

				const submit = (asset) => {
					this.handleWarranties(values).then(({ serviceWarranty, partWarranty }) => {
						const submitAsset = {
							...asset,
							warrantyIds: nullSafeGet('id', serviceWarranty)
								? [parseInt((serviceWarranty as any).id)]
								: undefined,
							partWarrantyId: nullSafeGet('id', partWarranty)
								? parseInt(partWarranty.id)
								: undefined,
						};
						submitAction(submitAsset).then(this.onFormFinish);
					});
				};

				// check for assets at same location with duplicate asset number
				if (values.assetNumber) {
					fetchAssets({
						assetNumber: values.assetNumber,
						locationId: values.locationId,
					})
						.then((assets) => {
							if (assets.length > 0 && assets[0].id !== values.id) {
								this.setState({
									conflictingAsset: assets[0],
									conflictingAssetModalVisible: true,
									updateButtonLoading: false,
								});
							} else {
								submit(asset);
							}
						})
						.catch((err) => {
							message.error(err);
							console.log(err);
						});
				} else {
					submit(asset);
				}
			} else {
				this.setState({ updateButtonLoading: false });
			}
		});
	};

	handlePreview = (file) => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true,
		});
	};

	handleCancel = () => this.setState({ previewVisible: false });

	handleImageUpdate = (info) => {
		if (info.file.status === 'done') {
			this.setState({ allPhotos: info.fileList });
		} else if (info.file.status === undefined) {
			info.fileList = this.state.allPhotos;
		}
	};

	handleWarrantyDateChange = (values) => {
		const { form } = this.props;
		if (values) {
			const [start, end] = values;
			form.setFieldsValue({ warrantyPeriod: [start, end] });
		} else {
			form.setFieldsValue({ warrantyPeriod: [undefined, undefined] });
		}
	};

	handlePartWarrantyDateChange = (values) => {
		const { form } = this.props;
		if (values) {
			const [start, end] = values;
			form.setFieldsValue({ partWarrantyPeriod: [start, end] });
		} else {
			form.setFieldsValue({ partWarrantyPeriod: [undefined, undefined] });
		}
	};

	debouncedSearchSupplierInPrivateNetwork = debounce(
		(searchText) => {
			const { fetchSupplierFacilitiesInPrivateNetwork } = this.props;
			fetchSupplierFacilitiesInPrivateNetwork({
				name: searchText && searchText.trim(),
			});
		},
		500,
		false
	);
	searchSupplierInPrivateNetwork = (searchText) => {
		this.debouncedSearchSupplierInPrivateNetwork(searchText);
	};

	handleSupplierChange = (supplierFacilityId) => {
		const { fetchSupplierFacility } = this.props;
		if (supplierFacilityId) {
			fetchSupplierFacility(supplierFacilityId).then((supplierFacility) =>
				this.setState({
					supplierFacility: {
						name: supplierFacility.name,
						supplierFacilityId: supplierFacility.id,
					},
				})
			);
		}
	};

	handleNTEValueChangeDebounced = () =>
		debounce(
			(NTEAmount) => {
				this.setState({ NTEAmount: NTEAmount });
			},
			500,
			false
		);

	handleNTEValueChange = (input) => {
		this.handleNTEValueChangeDebounced()(input);
	};

	debouncedSearchAssetTypes = debounce(
		(searchText) => {
			const { fetchAssetTypes } = this.props;
			fetchAssetTypes({ name: searchText && searchText.trim() });
		},
		500,
		false
	);

	searchAssetTypes = (searchText) => {
		this.debouncedSearchAssetTypes(searchText);
	};

	handleAssetTypeChange = (assetTypeId) => {
		const { fetchAssetType } = this.props;
		const { assetType } = this.state;
		if (assetTypeId) {
			fetchAssetType(assetTypeId).then((newAssetType) => {
				const updatedAssetType = mergeTypeFields(assetType, newAssetType);
				this.setState({
					assetType: updatedAssetType,
					assetTypeId: updatedAssetType.id,
					disableAssetModel: false,
				});
			});
		}
	};

	handleHideConflictingAssetModal = () => {
		this.setState({ conflictingAssetModalVisible: false, conflictingAsset: {} });
	};

	handleConflictingAssetResolveSuccess = () => {
		const { form, update, create } = this.props;
		const submitAction = form.getFieldValue('id') ? update : create;
		const { conflictingAsset } = this.state;
		form.setFieldsValue({ assetUUID: (conflictingAsset as any).assetUUID });
		this.handleSubmit({}, submitAction);
		this.handleHideConflictingAssetModal();
	};

	showTransferModal = () => this.setState({ transferModalVisible: true });
	hideTransferModal = () => this.setState({ transferModalVisible: false });

	onTransferSuccess = (updatedAsset) => {
		const { form } = this.props;
		const { setFieldsValue } = form;
		this.hideTransferModal();
		const { locationId, areaId } = updatedAsset;
		setFieldsValue({
			locationId,
			areaId,
		});
	};

	render() {
		const {
			updating,
			creating,
			form,
			update,
			create,
			createErrors,
			assetTypes,
			assetTypesFetching,
			assetModels,
			fetchAssetModels,
			fetchMultipleAssetModels,
			locations,
			fetchLocations,
			fetchMultipleLocations,
			formData,
			updateErrors,
			currentUser,
			supplierFacilities,
			supplierFacilitiesFetching,
			areas,
			fetchAreas,
			fetchMultipleAreas,
			companyConfig,
		} = this.props;
		const { assetType, previewVisible, previewImage } = this.state;
		const { getFieldDecorator, getFieldValue } = form;
		const currency = getCurrency({ currentUser });
		const isUpdate = formData && formData.id !== undefined;
		const submitAction = isUpdate ? update : create;
		const submitText = isUpdate ? 'Update asset' : 'Create asset';
		const isLoading = updating || creating;
		const warranty = nullSafeGetOrElse('warranties.0', formData, undefined);

		const assetTypesDropdownRecords = getRecordsForTargetCollection(
			assetTypes,
			ASSET_FORM_ASSET_TYPES_AUTOCOMPLETE
		);
		const supplierPrivateNetworkDropdownRecords = getRecordsForTargetCollection(
			supplierFacilities,
			ASSET_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE
		);

		getFieldDecorator('buyerFacilityId', {
			initialValue: formData.buyerFacilityId || nullSafeGet('facility.id', currentUser),
		});
		const warrantyPeriod = form.getFieldValue('warrantyPeriod')
			? form.getFieldValue('warrantyPeriod')
			: [undefined, undefined];
		const BACKEND_URI = getBackendUri();
		const uploadHeaders = { 'X-Auth-Token': retrieveCachedUserDetails(['token']).token };
		let initialAssetDetailsMap = {};
		if (formData.assetTypeDetails) {
			formData.assetTypeDetails.forEach((ltd) => {
				initialAssetDetailsMap[`${ltd.key}-key`] = ltd.key;
				initialAssetDetailsMap[`${ltd.key}-valueType`] = ltd.valueType;
				initialAssetDetailsMap[`${ltd.key}-valueAsString`] = ltd.valueAsString;
			});
		}

		const getFieldItems = (at) => {
			if (!at || !at.id) {
				return null;
			}

			return [...(at.requiredFields || []), ...(at.optionalFields || [])].map((field) => {
				getFieldDecorator(`${field.key}-key`, { initialValue: field.key });
				getFieldDecorator(`${field.key}-valueType`, {
					initialValue: field.type || field.valueType,
				});
				const isRequired = (at.requiredFields || []).some((reqField) => reqField.key === field.key);
				return (
					<Form.Item label={field.key} key={field.key}>
						{form.getFieldDecorator(`${field.key}-valueAsString`, {
							validateTrigger: ['onChange', 'onBlur'],
							initialValue: initialAssetDetailsMap[`${field.key}-valueAsString`],
							rules: isRequired ? [{ required: true, message: 'This field is required.' }] : [],
						})(<Input />)}
					</Form.Item>
				);
			});
		};

		getFieldDecorator('id', { initialValue: formData.id });
		getFieldDecorator('assetUUID', { initialValue: formData.assetUUID });
		getFieldDecorator('warrantyId', {
			initialValue: nullSafeGetOrElse('warranties.0.id', formData, undefined),
		});

		const locationId = getFieldValue('locationId');
		const allowArea = getBuyerCompanySettingValue(ROLE_TYPES.BUYER)(
			'assetConfig.allowAreaAssociationWithAssets',
			companyConfig,
			{},
			false
		);

		return (
			<Form
				layout="vertical"
				onSubmit={(e) => this.handleSubmit(e, submitAction)}
				className="supplierForm"
				style={FORM_MAX_WIDTH}
			>
				{this.state.conflictingAssetModalVisible ? (
					<SameAssetNumberResolveModal
						conflictingAsset={this.state.conflictingAsset}
						onCancel={this.handleHideConflictingAssetModal}
						onSuccess={this.handleConflictingAssetResolveSuccess}
					/>
				) : null}

				{createErrors.length > 0 ? (
					<Form.Item>
						<Alert message={createErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				{updateErrors.length > 0 ? (
					<Form.Item>
						<Alert message={updateErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				<Form.Item label="Name">
					{getFieldDecorator('name', {
						initialValue: formData.name,
						rules: [{ required: true, message: 'Please give this asset a name.' }],
					})(<Input />)}
				</Form.Item>
				<Form.Item label="Is Active">
					{getFieldDecorator('isActive', {
						initialValue: formData.isActive === undefined ? true : formData.isActive,
						valuePropName: 'checked',
					})(<Switch />)}
				</Form.Item>
				<Form.Item label="Description">
					{getFieldDecorator('description', {
						initialValue: formData.description,
					})(<Input.TextArea />)}
				</Form.Item>
				<Form.Item label="Manufacturer">
					{getFieldDecorator('manufacturer', {
						initialValue: formData.manufacturer,
					})(<Input />)}
				</Form.Item>
				<Form.Item label="Model Number">
					{getFieldDecorator('modelNumber', {
						initialValue: formData.modelNumber,
					})(<Input />)}
				</Form.Item>
				<Form.Item label="Serial Number">
					{getFieldDecorator('serialNumber', {
						initialValue: formData.serialNumber,
					})(<Input />)}
				</Form.Item>
				<Form.Item label="Asset Number">
					{getFieldDecorator('assetNumber', {
						initialValue: formData.assetNumber,
					})(<Input />)}
				</Form.Item>
				<Form.Item
					label="Location"
					help={
						isUpdate ? (
							<div className="flex flex-row items-baseline">
								<Button type="link" onClick={this.showTransferModal}>
									Click here
								</Button>
								<div>to transfer this asset to a different location</div>
							</div>
						) : (
							''
						)
					}
				>
					{getFieldDecorator('locationId', {
						initialValue: formData.locationId,
						rules: [{ required: true, message: 'Please assign this asset to a location.' }],
					})(
						<OWAsyncSelect
							disabled={isUpdate}
							targetCollectionName={ASSET_FORM_LOCATIONS_AUTOCOMPLETE}
							stateSlice={locations}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleLocations(ids, targetCollectionName);
							}}
							fetchData={(search, targetCollectionName) => {
								fetchLocations(
									{
										search,
									},
									targetCollectionName
								);
							}}
							placeholder={'Select Location'}
							renderRecord={(d) => (
								<Select.Option value={d.id} key={d.id}>
									<div>{d.name}</div>
									<div style={{ color: 'rgba(0,0,0,0.45)' }}>
										{nullSafeGet('buyerFacility.primaryAddress.streetAddress1', d)},{' '}
										{nullSafeGet('buyerFacility.primaryAddress.city', d)}{' '}
										{nullSafeGet('buyerFacility.primaryAddress.region', d)}
									</div>
								</Select.Option>
							)}
							sortBy={{ sort_by: 'name', order: 'ascend' }}
						/>
					)}
				</Form.Item>
				{allowArea ? (
					<Form.Item label="Area">
						{getFieldDecorator('areaId', {
							initialValue: formData.areaId,
						})(
							<OWAsyncSelect
								targetCollectionName="assetsForm"
								stateSlice={areas}
								allowClear
								fetchMultiple={fetchMultipleAreas}
								fetchData={(search, ...args) => fetchAreas({ search }, ...args)}
								renderRecord={(area) => (
									<Select.Option key={area.id} value={area.id}>
										{area.name}
									</Select.Option>
								)}
								additionalFilters={{ locationId }}
								sortBy={{ sort_by: 'name', order: 'ascend' }}
							/>
						)}
					</Form.Item>
				) : null}
				<Form.Item label="Asset Type">
					{getFieldDecorator('assetTypeId', {
						initialValue: formData.assetTypeId,
						rules: [{ required: true, message: 'Please choose an asset type.' }],
					})(
						<Select
							notFoundContent={assetTypesFetching ? <Spin size="small" /> : null}
							placeholder="Type to search for asset type"
							filterOption={false}
							showSearch={true}
							onSearch={this.searchAssetTypes}
							onChange={this.handleAssetTypeChange}
						>
							{assetTypesDropdownRecords.map((d) => (
								<Select.Option value={d.id} key={d.id}>
									{d.name}
								</Select.Option>
							))}
						</Select>
					)}
				</Form.Item>
				<Form.Item label="Asset Model">
					{getFieldDecorator('assetModelId', {
						initialValue: formData.assetModelId,
					})(
						<OWAsyncSelect
							targetCollectionName="ASSET_MODELS_AUTOCOMPLETE"
							stateSlice={assetModels}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleAssetModels(ids, targetCollectionName);
							}}
							fetchData={(searchText, targetCollectionName) => {
								fetchAssetModels(
									{
										name: searchText,
										assetTypeId: nullSafeGet('assetTypeId', this.state),
									},
									targetCollectionName
								);
							}}
							placeholder={'Select Model'}
							renderRecord={(model) => (
								<Select.Option key={model.id} value={model.id}>
									{model.modelName}
								</Select.Option>
							)}
							disabled={this.state.disableAssetModel}
							additionalFilters={{
								assetTypeId: nullSafeGet('assetTypeId', this.state),
							}}
							sortBy={{ sort_by: 'name', order: 'ascend' }}
						/>
					)}
				</Form.Item>
				{getFieldItems(assetType)}
				<Form.Item label="Photos">
					{getFieldDecorator(`images`, {
						valuePropName: 'fileList',
						getValueFromEvent: normFile,
						initialValue: this.state.allPhotos,
					})(
						<OWUpload
							accept="image/*"
							name="file"
							listType="picture-card"
							className="avatar-uploader"
							multiple={true}
							headers={uploadHeaders}
							onPreview={this.handlePreview}
							action={`${BACKEND_URI}/api/v1/buyer/file/upload`}
							beforeUpload={imageUploadValidation}
							onChange={this.handleImageUpdate}
						>
							<div style={{ display: 'inline' }}>
								<LegacyIcon type={this.state.uploadLoading ? 'loading' : 'plus'} />
								<div className="ant-upload-text">Upload</div>
							</div>
							<Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
								<img alt="example" style={{ width: '100%' }} src={previewImage} />
							</Modal>
						</OWUpload>
					)}
				</Form.Item>
				<Form.Item label="Is Leased?">
					{getFieldDecorator('isLeased', {
						initialValue: formData.isLeased === undefined ? false : formData.isLeased,
						valuePropName: 'checked',
					})(<Switch />)}
				</Form.Item>
				<Form.Item label="Installation date">
					{getFieldDecorator('installDate', {
						initialValue: formData.installDate ? moment(formData.installDate) : undefined,
					})(
						<DatePicker
							format={dateFormat}
							onChange={(value) => {
								if (warrantyPeriod.every((val) => val === undefined)) {
									form.setFieldsValue({ warrantyPeriod: [value, undefined] });
								}
							}}
						/>
					)}
				</Form.Item>
				{form.getFieldValue(`isLeased`)
					? [
							<Form.Item label="Leased start date" key="leaseStartDate">
								{getFieldDecorator('leaseStartDate', {
									initialValue: formData.leaseStartDate
										? moment(formData.leaseStartDate)
										: undefined,
								})(<DatePicker format={dateFormat} />)}
							</Form.Item>,
							<Form.Item label="Lease end date" key="leaseEndDate">
								{getFieldDecorator('leaseEndDate', {
									initialValue: formData.leaseEndDate ? moment(formData.leaseEndDate) : undefined,
								})(<DatePicker format={dateFormat} />)}
							</Form.Item>,
					  ]
					: [
							<Form.Item label="Purchase date" key="purchaseDate">
								{getFieldDecorator('purchaseDate', {
									initialValue: formData.purchaseDate ? moment(formData.purchaseDate) : undefined,
								})(<DatePicker format={dateFormat} />)}
							</Form.Item>,
							<AccessPermissionChecker name={PERMISSION_NAMES.SHOW_ASSET_PRICE}>
								<Form.Item label="Purchase price" key="originalPrice">
									{getFieldDecorator('originalPrice', {
										initialValue: formData.originalPrice ? formData.originalPrice : undefined,
									})(<InputNumber addonAfter={currency.id} />)}
								</Form.Item>
							</AccessPermissionChecker>,
							<AccessPermissionChecker name={PERMISSION_NAMES.SHOW_ASSET_PRICE}>
								<Form.Item label="Capitalised Cost" key="capitalisedCost">
									{getFieldDecorator('capitalisedCost', {
										initialValue: formData.capitalisedCost ? formData.capitalisedCost : undefined,
									})(<InputNumber addonAfter={currency.id} />)}
								</Form.Item>
							</AccessPermissionChecker>,
					  ]}
				<Form.Item label="Expected useful life (months)">
					{getFieldDecorator('lifeExpectancyInMonths', {
						initialValue: formData.lifeExpectancyInMonths,
					})(<InputNumber />)}
				</Form.Item>
				<Form.Item label="Has Warranty">
					{getFieldDecorator('warranties', {
						initialValue: warranty,
						valuePropName: 'checked',
					})(<Switch onChange={(value) => this.setState({ hasWarranty: value })} />)}
				</Form.Item>
				<Form.Item
					label="Service Warranty Start and End Date"
					style={{ display: this.state.hasWarranty ? 'flex' : 'none' }}
				>
					{getFieldDecorator('warrantyPeriod', {
						initialValue: [
							nullSafeGet('issueDate', warranty) ? moment(warranty.issueDate) : undefined,
							nullSafeGet('expirationDate', warranty) ? moment(warranty.expirationDate) : undefined,
						],
						rules: [
							{
								required: this.state.hasWarranty,
								message: 'Must enter the warranty start or end date.',
								validator: (rule, value, callback) => {
									if (this.state.hasWarranty) {
										if (value === null || !(value[0] || value[1])) {
											callback('error');
											return Promise.reject('');
										}
									}
									return Promise.resolve();
								},
							},
						],
					})(
						<OWDateRangePicker
							format={dateFormat}
							onChange={this.handleWarrantyDateChange}
							ranges={getDefaultDatePeriods(DEFAULT_WARRANTY_PERIODS(warrantyPeriod))}
						/>
					)}
				</Form.Item>
				<Form.Item
					label={
						<span>
							<span>Warranty Service Provider</span>
							<Popover
								content={'Work orders for this asset will have this service provider by default.'}
							>
								<InfoCircleOutlined translate="" className={'ml-2'} />
							</Popover>
						</span>
					}
					style={{ display: this.state.hasWarranty ? 'flex' : 'none' }}
				>
					{getFieldDecorator('supplierFacilityId', {
						initialValue: nullSafeGet('supplierFacilityId', this.state.supplierFacility),
						rules: [
							{
								required: this.state.hasWarranty,
								message: 'Must select a default service provider for the warranty.',
								validator: (rule, value, callback) => {
									if (this.state.hasWarranty) {
										if (value) {
											return Promise.resolve();
										} else {
											callback('error');
											return Promise.reject('');
										}
									}
									return Promise.resolve();
								},
							},
						],
					})(
						<Select
							key={nullSafeGet('supplierFacilityId', this.state.supplierFacility)}
							notFoundContent={
								supplierFacilitiesFetching ? (
									<Spin size="small" />
								) : (
									'No service provider in your private network'
								)
							}
							placeholder="Type to search service providers in your private network"
							filterOption={false}
							showSearch={true}
							onSearch={this.searchSupplierInPrivateNetwork}
							onChange={this.handleSupplierChange}
						>
							{supplierPrivateNetworkDropdownRecords.map((d) => (
								<Select.Option value={d.id} key={d.id}>
									<div>{d.name}</div>
								</Select.Option>
							))}
						</Select>
					)}
				</Form.Item>
				<Form.Item
					label={
						<span>
							<span>Service Warranty Not-To-Exceed Amount</span>
							<Popover
								className={'max-w-md'}
								content={
									<div>
										<span>The NTE amount for work orders using the warranty service provider.</span>
										<br />
										<span>Leave blank to use normal NTE</span>
									</div>
								}
							>
								<InfoCircleOutlined translate="" className={'ml-2'} />
							</Popover>
						</span>
					}
					style={{ display: this.state.hasWarranty ? 'flex' : 'none' }}
				>
					{getFieldDecorator('NTEAmount', {
						initialValue: nullSafeGet('NTEAmount', warranty),
						rules: [
							{
								required: false,
								message: 'The NTE Amount must be a positive number.',
								validator: (rule, value, callback) => {
									if (this.state.hasWarranty) {
										if (parseInt(value) >= 0 || value === '' || value === undefined) {
											return Promise.resolve();
										} else {
											callback('error');
											return Promise.reject('');
										}
									}
									return Promise.resolve();
								},
							},
						],
					})(
						<InputNumber
							onChange={this.handleNTEValueChange}
							onClick={(e) => e.stopPropagation()}
							addonAfter={currency.id}
						/>
					)}
				</Form.Item>
				<Form.Item label="Has Part Warranty">
					{getFieldDecorator('partWarranty', {
						initialValue: formData.partWarranty,
						valuePropName: 'checked',
					})(<Switch onChange={(value) => this.setState({ hasPartWarranty: value })} />)}
				</Form.Item>
				<Form.Item
					label="Part Warranty Start and End Date"
					style={{ display: this.state.hasPartWarranty ? 'flex' : 'none' }}
				>
					{getFieldDecorator('partWarrantyPeriod', {
						initialValue: [
							nullSafeGet('partWarranty.issueDate', formData)
								? moment(formData.partWarranty.issueDate)
								: undefined,
							nullSafeGet('partWarranty.expirationDate', formData)
								? moment(formData.partWarranty.expirationDate)
								: undefined,
						],
						rules: [
							{
								required: this.state.hasPartWarranty,
								message: 'Must enter the part warranty start or end date.',
								validator: (rule, value, callback) => {
									if (this.state.hasPartWarranty) {
										if (value === null || !(value[0] || value[1])) {
											callback('error');
											return Promise.reject('');
										}
									}
									return Promise.resolve();
								},
							},
						],
					})(
						<OWDateRangePicker
							format={dateFormat}
							onChange={this.handlePartWarrantyDateChange}
							ranges={getDefaultDatePeriods(DEFAULT_WARRANTY_PERIODS(warrantyPeriod))}
						/>
					)}
				</Form.Item>
				{nullSafeGet('partWarranty.vendorId', formData) ? (
					<Form.Item
						label={
							<span>
								<span>Part Warranty Vendor</span>
							</span>
						}
						style={{ display: this.state.hasPartWarranty ? 'flex' : 'none' }}
					>
						{getFieldDecorator('vendorId', {
							initialValue: nullSafeGet('partWarranty.vendorId', formData),
							rules: [
								{
									required: this.state.hasPartWarranty,
									message: 'Must select a default vendor for the part warranty.',
									validator: (rule, value, callback) => {
										if (this.state.hasPartWarranty) {
											if (value) {
												return Promise.resolve();
											} else {
												callback('error');
												return Promise.reject('');
											}
										}
										return Promise.resolve();
									},
								},
							],
						})(
							<Select>
								<Select.Option
									key={nullSafeGet('partWarranty.vendorId', formData)}
									value={nullSafeGet('partWarranty.vendorId', formData)}
								>
									{nullSafeGet('partWarranty.vendor.name', formData)}
								</Select.Option>
							</Select>
						)}
					</Form.Item>
				) : null}
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						loading={isLoading || this.state.updateButtonLoading}
						className="contactForm__button"
					>
						{submitText}
					</Button>
					<span style={{ marginLeft: '16px' }}>
						<BackButton buttonText="Cancel" />
					</span>
				</Form.Item>
				{this.state.transferModalVisible ? (
					<TransferAssetToLocationForm
						asset={formData}
						onCancel={this.hideTransferModal}
						onSuccess={this.onTransferSuccess}
					/>
				) : null}
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	formData: ownProps.formData,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,
	supplierFacilities: state.supplier_facilities,
	supplierFacilitiesFetching: state.supplier_facilities.fetching,
	assetTypesFetching: state.asset_types.fetching,
	assetTypes: state.asset_types,
	assetModelsFetching: state.asset_models.fetching,
	assetModels: state.asset_models,
	locationsFetching: state.locations.fetching,
	locations: state.locations,
	areas: state.areas,
	createErrors: state.assets.createErrors,
	updateErrors: state.assets.updateErrors,
	creating: state.assets.creating,
	updating: state.assets.updating,
	currentUser: state.session.currentUser,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	update: (entity) => dispatch(assetsRestCrudThunksForBuyer.update(entity)),
	create: (entity) => dispatch(assetsRestCrudThunksForBuyer.create(entity)),
	updateWarranty: (entity) => dispatch(warrantiesRestCrudThunksForBuyer.update(entity)),
	createWarranty: (entity) => dispatch(warrantiesRestCrudThunksForBuyer.create(entity)),
	deleteWarranty: (entity) => dispatch(warrantiesRestCrudThunksForBuyer.delete(entity)),
	updatePartWarranty: (entity) => dispatch(partWarrantiesRestCrudThunksForBuyer.update(entity)),
	createPartWarranty: (entity) => dispatch(partWarrantiesRestCrudThunksForBuyer.create(entity)),
	deletePartWarranty: (entity) => dispatch(partWarrantiesRestCrudThunksForBuyer.delete(entity)),
	fetchLocations: (params, targetCollectionName) =>
		dispatch(
			locationsRestCrudThunksForBuyer.readLite(
				{ ...params, no_pagination: true },
				targetCollectionName
			)
		),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchAssets: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			assetsRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchAssetType: (id) => dispatch(assetTypesRestCrudThunksForBuyer.readOne(id)),
	fetchAssetTypes: (params) =>
		dispatch(
			assetTypesRestCrudThunksForBuyer.readLite(
				{ ...params, no_pagination: true },
				ASSET_FORM_ASSET_TYPES_AUTOCOMPLETE
			)
		),
	fetchAssetModel: (id) => dispatch(assetModelsRestCrudThunksForBuyer.readOne(id)),
	fetchAssetModels: (params, targetCollectionName) =>
		dispatch(assetModelsRestCrudThunksForBuyer.read(params, targetCollectionName)),
	fetchMultipleAssetModels: (ids, targetCollectionName) =>
		dispatch(assetModelsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchAreas: (params, targetCollectionName, pagination, sorting, filters, addToTargetCollection) =>
		dispatch(
			areasRestCrudThunksForBuyer.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleAreas: (ids, targetCollectionName) =>
		dispatch(areasRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)),
	fetchSupplierFacility: (id) => dispatch(supplierFacilitiesRestCrudThunksForBuyer.readOne(id)),
	fetchSupplierFacilitiesInPrivateNetwork: (params) =>
		dispatch(
			fetchSupplierFacilitiesInPrivateNetworkForBuyer(
				params,
				ASSET_FORM_SUPPLIER_FACILITIES_IN_PRIVATE_NETWORK_AUTOCOMPLETE
			)
		),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(Form.create<AssetFormProps>()(AssetForm))
);

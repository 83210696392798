import React, { FC, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Select } from 'antd';
import {
	purchaseOrdersRestCrudThunksForSupplier,
	refreshPOCountsForSupplier,
} from '../../thunks/purchase_orders_thunks';
import { EmptyState } from '../empty_state/EmptyState';
import PurchaseOrderRowDisplay from '../purchase_order_row_display/PurchaseOrderRowDisplay';
import { PURCHASE_ORDERS_CRUD_ACTION_CREATORS } from '../../actions/purchase_orders_actions';
import { vendorsRestCrudThunksForSupplier } from '../../thunks/vendors_thunks';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';

import POPipeLineFilters from './POPipelineFIlters';
import { usersRestCrudThunksForSupplier } from '../../thunks/users_thunks';
import { getContactName } from '../../utils/DataFormatterUtils';
import {
	FILTER_CONFIG_NAMES,
	FILTER_FIELD_TYPE,
	FILTER_VALUE_TYPE,
} from '../../utils/DataConstants';
import POSorters from './POSorters';
import { partsRestCrudThunksForSupplier } from '../../thunks/parts_thunks';
import { equipmentTypesRestCrudThunksForSupplier } from '../../thunks/equipment_types_thunks';
import {
	purchaseRequestsLiteRestCrudThunksForSupplier,
	purchaseRequestsRestCrudThunksForSupplier,
} from '../../thunks/purchase_requests_thunks';
import {
	fetchWithDifferntSearchName,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { locationsRestCrudThunksForSupplier } from '../../thunks/locations_thunks';
import { downloadPOLineItemsCSVForSupplier } from '../../thunks/purchase_order_line_items_thunks';
import { DownloadOutlined } from '@ant-design/icons';
import { getLinkWIthBackLinkParams } from '../../utils/HistoryUtils';
import { renderEquipmenTypeSelect } from '../purchase_order_form/ReplaceEquipmentLineItemModal';
import ReduxDataTable from '../redux_data_table/ReduxDataTable';

require('./PurchaseOrdersIndexPage.less');

const PO_INDEX_TARGET_COLLECTION = 'purchaseOrdersIndex';
const PO_ADVANCED_FILTERS = 'purchaseOrdersAdvancedFilters';

const PurchaseOrdersIndexPage: FC<any> = ({
	history,
	clearAndUpdateFilters,
	purchaseOrders,
	updatePOFilters,
	vendors,
	fetchVendors,
	fetchMultipleVendors,
	stockLocations,
	fetchStockLocations,
	fetchMultipleStockLocations,
	refreshCounts,
	users,
	fetchUsers,
	fetchMultipleUsers,
	updatePOSorters,
	equipmentTypes,
	fetchEquipmentTypes,
	fetchMultipleEquipments,
	parts,
	fetchParts,
	fetchMultipleParts,
	purchaseRequests,
	fetchPurchaseRequests,
	fetchMultiplePurchaseRequests,
	locations,
	fetchLocations,
	fetchMultipleLocations,
	exportPOLineItemsCSV,
	location,
}): React.ReactElement => {
	const [exporting, setExporting] = useState(false);

	const filterConfig = useMemo(
		() => [
			{
				label: 'Stock Location',
				fieldName: 'stockLocationIds',
				mode: 'multiple',
				stateSlice: stockLocations,
				targetCollectionName: PO_ADVANCED_FILTERS,
				fetch: fetchStockLocations,
				fetchMultiple: fetchMultipleStockLocations,
			},
			{
				label: 'Location',
				fieldName: 'locationIds',
				mode: 'multiple',
				stateSlice: locations,
				targetCollectionName: PO_ADVANCED_FILTERS,
				fetch: fetchLocations,
				fetchMultiple: fetchMultipleLocations,
			},
			{
				label: 'Part',
				fieldName: 'partId',
				stateSlice: parts,
				targetCollectionName: PO_ADVANCED_FILTERS,
				fetch: fetchParts,
				fetchMultiple: fetchMultipleParts,
				renderRecord: (part) => (
					<Select.Option key={part.id} value={part.id}>
						<div className="flex flex-row items-center">
							<div>{part.name}</div>
							<div>
								{part.partNumber && (
									<span className="text-gray-400">&nbsp;{`- #${part.partNumber}`}</span>
								)}
							</div>
						</div>
					</Select.Option>
				),
				component: OWAsyncSelect,
			},
			{
				label: 'Equipment Type',
				fieldName: 'equipmentTypeId',
				stateSlice: equipmentTypes,
				targetCollectionName: PO_ADVANCED_FILTERS,
				fetch: fetchWithDifferntSearchName(fetchEquipmentTypes, 'search'),
				fetchMultiple: fetchMultipleEquipments,
				renderRecord: renderEquipmenTypeSelect,
				component: OWAsyncSelect,
			},
			{
				label: 'Purchase Request',
				fieldName: 'purchaseRequestId',
				stateSlice: purchaseRequests,
				targetCollectionName: PO_ADVANCED_FILTERS,
				fetch: fetchWithDifferntSearchName(fetchPurchaseRequests, 'search'),
				fetchMultiple: fetchMultiplePurchaseRequests,
				renderRecord: (pr) => (
					<Select.Option key={pr.id} value={pr.id}>
						<Ellipsis key={pr.id} tooltip={true} length={50}>
							{`#${pr.id} - ${pr.name}`}
						</Ellipsis>
					</Select.Option>
				),
				component: OWAsyncSelect,
			},
			{
				label: 'Ordered by',
				fieldName: 'createdByEmails',
				mode: 'multiple',
				stateSlice: users,
				valueType: FILTER_VALUE_TYPE.STRING,
				valueAccessor: (user) => nullSafeGet('contact.email', user),
				targetCollectionName: PO_ADVANCED_FILTERS,
				fetch: fetchUsers,
				fetchMultiple: fetchMultipleUsers,
				renderItem: (user) => getContactName(user.contact),
			},
			{
				label: 'Vendor',
				fieldName: 'partEquipmentVendorIds',
				mode: 'multiple',
				stateSlice: vendors,
				targetCollectionName: PO_ADVANCED_FILTERS,
				fetch: fetchVendors,
				fetchMultiple: fetchMultipleVendors,
			},
			{
				label: 'Ordered Between',
				labelPrefix: 'Ordered',
				fieldName: FILTER_CONFIG_NAMES.CREATED_AT,
				type: FILTER_FIELD_TYPE.DATE_RANGE,
			},
		],
		[
			equipmentTypes,
			fetchEquipmentTypes,
			fetchLocations,
			fetchMultipleEquipments,
			fetchMultipleLocations,
			fetchMultipleParts,
			fetchMultiplePurchaseRequests,
			fetchMultipleStockLocations,
			fetchMultipleUsers,
			fetchMultipleVendors,
			fetchParts,
			fetchPurchaseRequests,
			fetchStockLocations,
			fetchUsers,
			fetchVendors,
			locations,
			parts,
			purchaseRequests,
			stockLocations,
			users,
			vendors,
		]
	);

	const onRow = useCallback(
		(record) => ({
			onClick: () => {
				const path = `/supplier/purchaseOrders/detail/${nullSafeGet('id', record)}/details`;
				history.push(
					getLinkWIthBackLinkParams(location, 'Back to Purchase Orders', path, {
						preserve: true,
						targetCollectionName: PO_INDEX_TARGET_COLLECTION,
					})
				);
			},
		}),
		[history, location]
	);

	const columns = useMemo(
		() => [
			{
				title: 'Name',
				dataIndex: 'id',
				sorter: true,
				render: (_, record) => <PurchaseOrderRowDisplay purchaseOrder={record} />,
			},
		],
		[]
	);

	const downloadCSV = useCallback(() => {
		const filters = nullSafeGetOrElse(`${PO_INDEX_TARGET_COLLECTION}.filters`, purchaseOrders, {});
		setExporting(true);
		exportPOLineItemsCSV(filters).finally(() => setExporting(false));
	}, [exportPOLineItemsCSV, purchaseOrders]);

	return (
		<ReduxDataTable
			targetCollectionName={PO_INDEX_TARGET_COLLECTION}
			updateFilters={updatePOFilters}
			stateSlice={purchaseOrders}
			refreshCounts={refreshCounts}
			pipeLineFilters={POPipeLineFilters}
			updateSorters={updatePOSorters}
			clearAndUpdateFilters={clearAndUpdateFilters}
			sorters={POSorters}
			filterConfig={filterConfig}
			entityCollectionName="purchase_orders"
			tableColumns={columns}
			onTableRow={onRow}
			fetchData={purchaseOrdersRestCrudThunksForSupplier}
			handleQueryParams
			rightActions={
				<div className="flex flex-row items-center">
					<Button
						size="large"
						icon={<DownloadOutlined translate="" />}
						className="inline-block-visible-md"
						loading={exporting}
						onClick={downloadCSV}
					>
						<span className="inline-block-visible-xl">Export CSV</span>
					</Button>
					<Button
						style={{ marginLeft: 16 }}
						type="primary"
						size="large"
						onClick={() => history.push('/supplier/purchaseOrders/overview/new')}
					>
						New Purchase Order
					</Button>
				</div>
			}
			emptyState={
				<EmptyState
					graphic={
						<img
							alt="No Data"
							style={{ marginBottom: 8 }}
							src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
						/>
					}
					headline={'No purchase orders found'}
					body={
						<div style={{ textAlign: 'center' }}>
							<Button
								type="primary"
								onClick={() => history.push('/supplier/purchaseOrders/overview/new')}
							>
								Create purchase order
							</Button>
						</div>
					}
				/>
			}
		/>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	purchaseOrders: state.purchase_orders,
	stockLocations: state.stock_locations,
	users: state.users,
	vendors: state.vendors,
	parts: state.parts,
	equipmentTypes: state.equipment_types,
	purchaseRequests: state.purchase_requests,
	locations: state.locations,
});

const mapDispatchToProps = (dispatch) => ({
	refreshCounts: (filters) => dispatch(refreshPOCountsForSupplier(filters)),
	updatePOFilters: (filters, targetCollection) =>
		dispatch(PURCHASE_ORDERS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	updatePOSorters: (sortBy, order, targetCollection) =>
		dispatch(PURCHASE_ORDERS_CRUD_ACTION_CREATORS.updateSorting(sortBy, order, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			PURCHASE_ORDERS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)
		),
	fetchVendors: (params, targetCollectionName) =>
		dispatch(
			vendorsRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName
			)
		),
	fetchMultipleVendors: (ids, targetCollectionName) =>
		dispatch(vendorsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchMultipleStockLocations: (ids, targetCollectionName) =>
		dispatch(stockLocationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchStockLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			stockLocationsRestCrudThunksForSupplier.readLite(
				{ ...params, no_pagination: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleUsers: (ids, targetCollectionName) =>
		dispatch(usersRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchUsers: (params, targetCollectionName, pagination, sorting, filters, addToTargetCollection) =>
		dispatch(
			usersRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleParts: (ids, targetCollectionName) =>
		dispatch(partsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchParts: (params, targetCollectionName, pagination, sorting, filters, addToTargetCollection) =>
		dispatch(
			partsRestCrudThunksForSupplier.readLite(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleEquipments: (ids, targetCollectionName) =>
		dispatch(equipmentTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchEquipmentTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			equipmentTypesRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultiplePurchaseRequests: (ids, targetCollectionName) =>
		dispatch(purchaseRequestsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchPurchaseRequests: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			purchaseRequestsLiteRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			locationsRestCrudThunksForSupplier.readLite(
				{ ...params, no_pagination: true },
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	exportPOLineItemsCSV: (params, filters) =>
		dispatch(downloadPOLineItemsCSVForSupplier(params, filters)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(PurchaseOrdersIndexPage)
);

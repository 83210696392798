import React, { FC, useCallback, useMemo, useState } from 'react';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import moment from 'moment';
import { timeUntilOrSince } from '../../utils/DataFormatterUtils';
import { Button, Popconfirm } from 'antd';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import {
	INTERNAL_TECH_COMPLETED_STATUSES,
	SERVICE_PROVIDER_COMPLETED_STATUSES,
	WORK_ORDER_STATUSES,
} from '../../constants/WorkOrderStatuses';
import DateTimeHover from '../date_time_component/DateTime';

const SERVICE_PROVIDER_COMPLETED_STATUS_EXCEPTIONS = [
	WORK_ORDER_STATUSES.workIncompleteWithReason,
	WORK_ORDER_STATUSES.workUnsatisfactory,
];

const ReviewAndMarkCompleteComponent: FC<any> = ({
	workOrder,
	showApproveModal,
	showReminderModal,
	isThirdPartySupplier,
	markWorkOrderCompleteByThirdParty,
}): React.ReactElement => {
	const [completing, setCompleting] = useState(false);

	const isInternalServiceTeamWO = useMemo(
		() => nullSafeGet('supplierFacility.status', workOrder) === 'private',
		[workOrder]
	);

	const question = useMemo(
		() =>
			isInternalServiceTeamWO
				? `Did your tech resolve this issue yet?`
				: `Did ${
						isThirdPartySupplier
							? 'you'
							: nullSafeGetOrElse('supplierFacility.name', workOrder, 'your service provider')
				  } resolve this issue yet?`,
		[isInternalServiceTeamWO, isThirdPartySupplier, workOrder]
	);

	const workOrderPastDue = useMemo(() => moment().diff(workOrder.dueDate) > 0, [workOrder.dueDate]);

	const canShowReviewButton = useMemo(
		() =>
			isInternalServiceTeamWO
				? !INTERNAL_TECH_COMPLETED_STATUSES.includes(workOrder.status)
				: SERVICE_PROVIDER_COMPLETED_STATUS_EXCEPTIONS.includes(workOrder.status) ||
				  !SERVICE_PROVIDER_COMPLETED_STATUSES.includes(workOrder.status),
		[isInternalServiceTeamWO, workOrder.status]
	);

	const onThirdPartyMarkComplete = useCallback(() => {
		setCompleting(true);
		markWorkOrderCompleteByThirdParty().finally(() => setCompleting(false));
	}, [markWorkOrderCompleteByThirdParty]);

	return canShowReviewButton ? (
		<AccessPermissionChecker name={PERMISSION_NAMES.CAN_MARK_WORK_ORDER_COMPLETED}>
			<div>
				<h5>{question}</h5>
				{workOrderPastDue ? (
					<div style={{ fontSize: 16, maxWidth: 480 }}>
						This work was due <DateTimeHover timestamp={nullSafeGet('dueDate', workOrder)} />.
					</div>
				) : null}
				<div style={{ marginTop: 16, marginBottom: 40 }}>
					{isThirdPartySupplier ? (
						<Popconfirm
							onConfirm={onThirdPartyMarkComplete}
							placement="topRight"
							title="Are you sure you want to mark this complete?"
							okText="Yes"
							cancelText="No"
						>
							<Button type="primary" key="approve" loading={completing}>
								Yes, mark complete
							</Button>
						</Popconfirm>
					) : (
						<Button type="primary" key="approve" onClick={showApproveModal}>
							{`Yes, ${
								isInternalServiceTeamWO || isThirdPartySupplier ? '' : 'review and'
							} mark complete`}
						</Button>
					)}
					{isThirdPartySupplier ? null : (
						<Button key="reminder" style={{ marginLeft: 16 }} onClick={showReminderModal}>
							No, send a reminder
						</Button>
					)}
				</div>
			</div>
		</AccessPermissionChecker>
	) : null;
};

export default ReviewAndMarkCompleteComponent;

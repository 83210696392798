import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';
import { connect } from 'react-redux';

require('./PlannedMaintenancePage.less');

class PlannedMaintenancePage extends React.Component<any, null> {
	render() {
		const { userType } = this.props;

		return (
			<div>
				<Route
					path={`/${userType}/plannedMaintenance`}
					exact={true}
					render={(props) => (
						<Redirect
							to={{
								pathname: `/${userType}/plannedMaintenance/overview`,
							}}
						/>
					)}
				/>
				{this.props.childRoutes.map((r, i) => (
					<RouteWithSubRoutes key={i} {...r} />
				))}
			</div>
		);
	}
}

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(PlannedMaintenancePage);

import * as React from 'react';
import { Table } from 'antd';
import { connect } from 'react-redux';
import { PaginationProps } from 'antd/es/pagination';
import { isEqual } from 'lodash';
import { debounce } from '../../utils/PerformanceUtils';

interface ReduxTableProps {
	collectionName: string;
	data: any[];
	columns: any[];
	showHeader: boolean | null;
	additionalParams: any;
	expandedRowRender: any;
	searchFilters: any;
	searchSorting: any;
	onRow?: any;
	emptyState?: any;
	initialPagination: PaginationProps;
	initialFilters: any;
	searchPagination: PaginationProps;
	searching: boolean;
	keyAccessor: (record: any) => any;
	fetchData: (
		params: any,
		searchPagination: any | null,
		searchSorting: { sort_by: string; order: string } | {},
		searchFilters: any
	) => any;
}

class ReduxSearchResultsTable extends React.Component<ReduxTableProps, any> {
	constructor(props) {
		super(props);
		this.handleTableChange = this.handleTableChange.bind(this);
	}

	getDefaultSortOrder(columns) {
		const defaultSortCol = columns.find((col) => col.hasOwnProperty('defaultSortOrder'));
		return defaultSortCol
			? {
					sort_by: defaultSortCol.dataIndex,
					order: defaultSortCol.defaultSortOrder,
			  }
			: {};
	}

	debouncedFetchData = debounce(
		(additionalParams, newPagination, newSorting, searchFilters) => {
			const { fetchData } = this.props;
			fetchData(additionalParams, newPagination, newSorting, searchFilters);
		},
		400,
		false
	);

	handleTableChange(tablePagination, tableFilters, tableSorting) {
		const {
			fetchData,
			searchPagination,
			searchFilters,
			searchSorting,
			additionalParams = {},
		} = this.props;
		const newPagination = {
			...searchPagination,
			current: tablePagination.current == undefined ? 1 : tablePagination.current,
		};
		let newSorting;
		if (tableSorting.columnKey && tableSorting.order) {
			newSorting = { ...searchSorting, sort_by: tableSorting.columnKey, order: tableSorting.order };
		} else {
			newSorting = { ...this.getDefaultSortOrder(this.props.columns) };
		}
		this.debouncedFetchData(additionalParams, newPagination, newSorting, searchFilters);
	}

	componentDidMount() {
		const { fetchData, initialPagination, columns, initialFilters, additionalParams } = this.props;
		const defaultSort = this.getDefaultSortOrder(columns);
		const sortProps = defaultSort ? { ...defaultSort } : {};
		this.debouncedFetchData(additionalParams || {}, initialPagination, sortProps, initialFilters);
	}

	componentWillReceiveProps(nextProps) {
		const {
			fetchData,
			searching,
			additionalParams,
			searchFilters,
			searchPagination,
			searchSorting,
		} = this.props;
		const searchFiltersChanged = !isEqual(nextProps.searchFilters, searchFilters);
		const searchPaginationChanged = !isEqual(nextProps.searchPagination, searchPagination);
		const searchSortingChanged = !isEqual(nextProps.searchSorting, searchSorting);
		const additionalParamsChanged = !isEqual(nextProps.additionalParams, additionalParams);
		const propsChanged =
			searchFiltersChanged ||
			searchPaginationChanged ||
			searchSortingChanged ||
			additionalParamsChanged;
		if (propsChanged && !searching) {
			this.debouncedFetchData(
				nextProps.additionalParams || {},
				nextProps.searchPagination,
				nextProps.searchSorting,
				nextProps.searchFilters
			);
		}
	}

	render() {
		const {
			columns,
			onRow,
			showHeader = true,
			emptyState,
			keyAccessor,
			data,
			searchPagination,
			searching,
			expandedRowRender,
		} = this.props;
		return data.length === 0 && emptyState && !searching ? (
			emptyState
		) : (
			<Table
				columns={columns}
				rowKey={keyAccessor}
				dataSource={data}
				showHeader={showHeader}
				pagination={searchPagination}
				expandedRowRender={expandedRowRender}
				loading={searching}
				onRow={onRow}
				onChange={this.handleTableChange}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const { collectionName } = ownProps;
	const relevantState = state[collectionName];

	if (!relevantState) {
		throw new Error(
			'collection not found in root reducer. please check name for typos or add reducer for collection'
		);
	}

	let mappedProps = {
		emptyState: ownProps.emptyState,
		collectionName: ownProps.collectionName,
		showHeader: ownProps.showHeader,
		keyAccessor: ownProps.keyAccessor,
		onRow: ownProps.onRow,
		data: relevantState.searchResults,
		searchPagination: relevantState.searchPagination,
		searchFilters: relevantState.searchFilters,
		searchSorting: relevantState.searchSorting,
		searching: relevantState.searching,
	};

	return mappedProps;
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchData: (params, pagination, sorting, filters) => {
		dispatch(ownProps.fetchData(params, pagination, sorting, filters));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(ReduxSearchResultsTable);

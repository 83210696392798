import * as React from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { currencyFormatter, dateFormatter } from '../../utils/DataFormatterUtils';
import DateTimeHover from '../date_time_component/DateTime';
import HyperLink from '../common/HyperLink';

const style = require('./PlannedMaintenanceScheduleRowDisplay.less');

export const PlannedMaintenanceScheduleRowDisplay: React.FC<any> = ({
	plannedMaintenanceSchedule,
	userType,
}) => {
	if (!plannedMaintenanceSchedule) {
		return null;
	}
	return (
		<div className="plannedMaintenanceSchedulesRowDisplay">
			<div>
				<div className="plannedMaintenanceSchedulesRowDisplay__rowTitle">
					<Ellipsis tooltip={true} length={64}>
						{plannedMaintenanceSchedule.basedOn === 'asset'
							? nullSafeGet('asset.name', plannedMaintenanceSchedule)
							: plannedMaintenanceSchedule.basedOn === 'assetType'
							? `${nullSafeGet('assetType.name', plannedMaintenanceSchedule)} - ${nullSafeGet(
									'location.name',
									plannedMaintenanceSchedule
							  )}`
							: nullSafeGet('location.name', plannedMaintenanceSchedule)}
					</Ellipsis>
				</div>
				<div className="plannedMaintenanceSchedulesRowDisplay__rowSubtitle">
					<div className="plannedMaintenanceSchedulesRowDisplay__rowInlineGroup">
						<i className="icons8-font icons8-location-marker"></i>{' '}
						{plannedMaintenanceSchedule.location.storeId}
					</div>
					{plannedMaintenanceSchedule.nte ||
					nullSafeGet('plannedMaintenanceTemplate.nte', plannedMaintenanceSchedule) ? (
						<div className="plannedMaintenanceSchedulesRowDisplay__rowInlineGroup">
							NTE:{' '}
							{currencyFormatter(2)(
								plannedMaintenanceSchedule.nte ||
									nullSafeGet('plannedMaintenanceTemplate.nte', plannedMaintenanceSchedule)
							)}
						</div>
					) : null}
					{plannedMaintenanceSchedule.supplierFacilityId ||
					nullSafeGet(
						'plannedMaintenanceTemplate.supplierFacilityId',
						plannedMaintenanceSchedule
					) ? (
						<div className="plannedMaintenanceSchedulesRowDisplay__rowInlineGroup">
							<i className="icons8-font icons8-company"></i>{' '}
							{nullSafeGet('routeToSupplier.displayName', plannedMaintenanceSchedule)}
						</div>
					) : null}
					{plannedMaintenanceSchedule.scheduleId ? (
						<div className="plannedMaintenanceSchedulesRowDisplay__rowSubtitle">
							{`Schedule ID : ${plannedMaintenanceSchedule.scheduleId}`}
						</div>
					) : null}
					<div className="plannedMaintenanceSchedulesRowDisplay__rowInlineGroup">
						{plannedMaintenanceSchedule.status === 'active' ? (
							plannedMaintenanceSchedule.nextScheduled ? (
								<>
									Next service on{' '}
									<DateTimeHover
										timestamp={nullSafeGet('nextScheduled', plannedMaintenanceSchedule)}
										showDate={true}
										showTime={false}
									/>
								</>
							) : nullSafeGet('createConfig', plannedMaintenanceSchedule) ===
							  'create_after_prior_completion' ? (
								'Next service will be scheduled once current work order is marked complete'
							) : null
						) : null}
					</div>
				</div>
				<div className="flex flex-row gap-x-6">
					<HyperLink
						text="All Work Orders"
						newTabPath={`/${userType}/workOrders/overview/all?plannedMaintenanceScheduleId=${plannedMaintenanceSchedule.id}`}
					/>
					<HyperLink
						text="Active Work Orders"
						newTabPath={`/${userType}/workOrders/overview/active?plannedMaintenanceScheduleId=${plannedMaintenanceSchedule.id}`}
					/>
				</div>
			</div>
			<div className="plannedMaintenanceSchedulesRowDisplay__rightSide">
				<div className="plannedMaintenanceSchedulesRowDisplay__rowTitle"></div>
			</div>
		</div>
	);
};

import { Card, Col, Layout, Row } from 'antd';
import React, { FC } from 'react';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { EmptyState } from '../empty_state/EmptyState';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	brandsRestCrudThunksForBuyer,
	brandsRestCrudThunksForSupplier,
} from '../../thunks/brands_thunks';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { ROLE_TYPES } from '../../utils/DataConstants';

const { Content } = Layout;

export const BrandDisplay: FC<any> = ({ brand }): React.ReactElement => {
	return (
		<div className="locationsIndexPage__row">
			<div>
				<div className="flex">
					{nullSafeGet('logoURL', brand) ? (
						<img
							src={nullSafeGet('logoURL', brand)}
							alt={nullSafeGet('brandName', brand)}
							className="mr-3 h-auto w-16 object-contain"
						/>
					) : null}
					<div>
						<div className="locationsIndexPage__rowTitle">
							<Ellipsis tooltip={true} length={64}>
								{brand.brandName}
							</Ellipsis>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const BrandsOverviewIndexPage: FC<any> = ({ userType }): React.ReactElement => {
	const column = [
		{
			title: 'Name',
			dataIndex: 'brandName',
			defaultSortOrder: 'ascend',
			render: (text, record) => <BrandDisplay brand={record} />,
		},
	];
	return (
		<Content className="locationsIndexPage" style={{ padding: '0 0.5em' }}>
			<Row style={{ margin: '0.5em -8px' }}>
				<Col span={24}>
					<Card bodyStyle={{ padding: 8 }} style={{ minHeight: 560 }}>
						<PaginatedReduxTable
							emptyState={
								<EmptyState
									graphic={
										<img
											alt="Looks like your team has not added any brands yet."
											style={{ marginBottom: 8 }}
											src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
										/>
									}
									headline={"It's a little lonely in here."}
									body={
										<div style={{ textAlign: 'center' }}>
											<div style={{ maxWidth: 440, marginBottom: 16 }}>
												Looks like your team has not added any brands yet.
											</div>
										</div>
									}
								/>
							}
							collectionName="brands"
							targetCollectionName="brandsIndex"
							columns={column}
							keyAccessor={(el) => el.id}
							fetchData={
								userType === ROLE_TYPES.SUPPLIER
									? brandsRestCrudThunksForSupplier.read
									: brandsRestCrudThunksForBuyer.read
							}
						/>
					</Card>
				</Col>
			</Row>
		</Content>
	);
};

const mapStateToProps = (state) => ({
	brands: state.brands,
	currentUser: state.session.currentUser,
});

const ComponentWithoutUserType = withRouter(connect(mapStateToProps)(BrandsOverviewIndexPage));

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

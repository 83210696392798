import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Card } from 'antd';
import SupplierContactForm from '../supplier_contact_form/SupplierContactForm';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;
const style = require('./MySupplierEditProfilePage.less');

class MySupplierEditProfilePage extends React.Component<any, any> {
	constructor(props) {
		super(props);
	}

	render() {
		const { currentUser } = this.props;
		return (
			<Content className="supplierNewAccountSetupProfilePage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited supplier edit profile page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<Card>
							<div>
								<h4 style={{ fontSize: 24 }}>Edit Profile</h4>
								<SupplierContactForm
									formData={currentUser}
									redirectForwardUrl="/supplier/myProfile"
								/>
							</div>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MySupplierEditProfilePage));

import React, { FC } from 'react';
const style = require('./TextButton.less');

export const TextButton: FC<any> = ({ children, ...otherProps }) => {
	return (
		<span className="textButton" {...otherProps}>
			{children}
		</span>
	);
};

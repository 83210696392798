import React, { FC, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { stockLocationsRestCrudThunksForSupplier } from '../../thunks/stock_locations_thunks';
import { EmptyState } from '../empty_state/EmptyState';
import PaginatedReduxTableWithHeader from '../common/PaginatedReduxTableWithHeader';
import { STOCK_LOCATIONS_CRUD_ACTION_CREATORS } from '../../actions/stock_locations_actions';
import { Button, Modal } from 'antd';
import {
	defaultFiscalEnd,
	getCurrentBurdenRateFromStockLocation,
	getDefaultRate,
} from '../stock_locations_detail_details_page/burden_rate_utils';
import BurdenRateModal from '../stock_locations_detail_details_page/BurdenRateModal';
import { nullSafeGet, nullSafeGetOrElse, stopDefaultEvents } from '../../utils/DataAccessUtils';
import { DATE_ONLY_FORMAT } from '../../utils/DataFormatterUtils';
import StockLocationBurdenRatesPage from '../stock_locations_detail_details_page/StockLocationBurdenRatesPage';

const DepreciationBurdenRatesIndexPage: FC<any> = ({
	stockLocations,
	updateFilters,
	clearAndUpdateFilters,
	companyConfig,
}): React.ReactElement => {
	const [currentEditingRecord, setCurrentEditingRecord] = useState(null);
	const [editRateModalVisible, setEditRateModalVisible] = useState(false);
	const [historyVisbile, setHistoryVisible] = useState(false);

	const onShowHistory = useCallback(
		(record) => () => {
			setHistoryVisible(true);
			setCurrentEditingRecord(record);
		},
		[]
	);

	const editStockLocation = useCallback(
		(record) => (e) => {
			stopDefaultEvents(e);
			setCurrentEditingRecord(record);
			setEditRateModalVisible(true);
		},
		[]
	);

	const hideEditRateModal = useCallback(() => setEditRateModalVisible(false), []);

	const onHistoryCancel = useCallback(() => {
		setCurrentEditingRecord(null);
		setHistoryVisible(false);
	}, []);

	const fiscal = useMemo(
		() => nullSafeGetOrElse('detail.inventoryConfig.fiscalDate', companyConfig, defaultFiscalEnd),
		[companyConfig]
	);

	const columns = useMemo(
		() => [
			{
				title: 'Stock Location',
				dataIndex: 'name',
			},
			{
				title: 'Period',
				render: () => {
					const { startDate, endDate } = getDefaultRate(fiscal);
					return `${startDate.format(DATE_ONLY_FORMAT)} - ${endDate.format(DATE_ONLY_FORMAT)}`;
				},
			},
			{
				title: 'Burden Rate',
				render: (_, record) => {
					const { rate } = getCurrentBurdenRateFromStockLocation(record, fiscal);
					return <div>{`${rate}%`}</div>;
				},
			},
			{
				render: (_, record) => (
					<div>
						<Button type="ghost" onClick={editStockLocation(record)}>
							Edit
						</Button>
						<Button className="ml-2" type="ghost" onClick={onShowHistory(record)}>
							Show History
						</Button>
					</div>
				),
			},
		],
		[editStockLocation, fiscal, onShowHistory]
	);

	return (
		<>
			<PaginatedReduxTableWithHeader
				targetCollectionName="stockLocationsIndex"
				updateFilters={updateFilters}
				stateSlice={stockLocations}
				clearAndUpdateFilters={clearAndUpdateFilters}
				filterConfig={[]}
				entityCollectionName="stock_locations"
				tableColumns={columns}
				fetchData={stockLocationsRestCrudThunksForSupplier.read}
				rightActions={null}
				showHeader
				emptyState={
					<EmptyState
						graphic={
							<img
								alt="No Data"
								style={{ marginBottom: 8 }}
								src="https://s3.amazonaws.com/mock-data-assets/categories/images/box.svg"
							/>
						}
						headline={'No Stock locations found'}
						body={null}
					/>
				}
			/>
			{editRateModalVisible && (
				<BurdenRateModal
					stockLocation={currentEditingRecord}
					burdenRate={getCurrentBurdenRateFromStockLocation(currentEditingRecord, fiscal)}
					onCancel={hideEditRateModal}
				/>
			)}
			{historyVisbile && (
				<Modal
					visible={true}
					width={600}
					title={`${nullSafeGetOrElse(
						'name',
						currentEditingRecord,
						'Stock Location'
					)} Burden Rate History`}
					closable
					onCancel={onHistoryCancel}
					forceRender
					footer={null}
				>
					<StockLocationBurdenRatesPage stockLocationId={nullSafeGet('id', currentEditingRecord)} />
				</Modal>
			)}
		</>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	stockLocations: state.stock_locations,
	companyConfig: state.company_config,
});

const mapDispatchToProps = (dispatch) => ({
	updateFilters: (filters, targetCollection) =>
		dispatch(STOCK_LOCATIONS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(
			STOCK_LOCATIONS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)
		),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(DepreciationBurdenRatesIndexPage)
);

import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { EQUIPMENT_CATALOG_CHANGES_CRUD_ACTION_CREATORS } from '../actions/equipment_catalog_changes_actions';

export const equipmentCatalogChangesRestCrudThunksForSupplier = new RestCrudThunks(
	'equipment_catalog_changes',
	'equipment_catalog_change',
	'equipmentCatalogChange',
	'equipmentCatalogsChanges',
	EQUIPMENT_CATALOG_CHANGES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'inventory/'
);

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Card } from 'antd';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { EmptyState } from '../empty_state/EmptyState';
import { walkthroughsRestCrudThunksForSupplier } from '../../thunks/walkthroughs_thunks';
import { WALKTHROUGHS_CRUD_ACTION_CREATORS } from '../../actions/walkthroughs_actions';
import { SupplierWalkthroughRowDisplay } from '../supplier_walkthrough_row_display/SupplierWalkthroughRowDisplay';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;
const style = require('./SupplierWalkthroughIndexPage.less');
const DATE_FORMAT = 'MMM D, YYYY';

const initState = {
	filteredInfo: null,
	sortedInfo: null,
	visible: false,
};

interface WalkthroughPageState {
	filteredInfo: any;
	sortedInfo: any;
	visible: boolean;
}

const WALKTHROUGHS_OVERVIEW_TARGET_COLLECTION = 'walkthroughsIndex';

class SupplierWalkthroughIndexPage extends React.Component<any, WalkthroughPageState> {
	initialPagination: any = { current: 1, pageSize: 10 };
	constructor(props) {
		super(props);
		this.state = {
			...initState,
		};
		this.handleChange = this.handleChange.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
		this.clearAll = this.clearAll.bind(this);
		this.handleOk = this.handleOk.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.showModal = this.showModal.bind(this);
	}

	showModal(e) {
		this.setState({
			visible: true,
		});
	}

	handleOk(e) {
		this.setState({
			visible: false,
		});
	}

	handleCancel(e) {
		this.setState({
			visible: false,
		});
	}

	handleChange(pagination, filters, sorter) {
		this.setState({
			filteredInfo: filters,
			sortedInfo: sorter,
		});
	}

	clearFilters() {
		this.setState({ filteredInfo: null });
	}

	clearAll() {
		this.setState({
			filteredInfo: null,
			sortedInfo: null,
		});
	}

	render() {
		let filteredInfo = this.state.filteredInfo;
		let sortedInfo = this.state.sortedInfo;
		const { history, walkthroughs, currentUser } = this.props;

		const MY_FILTER = { ownerEmail: currentUser.email };

		filteredInfo = filteredInfo || {};
		sortedInfo = sortedInfo || {};

		const columns = [
			{
				title: 'Name',
				dataIndex: 'title',
				render: (text, record) => <SupplierWalkthroughRowDisplay walkthrough={record} />,
			},
		];
		const onRow = (record) => ({
			onClick: () => history.push(`/supplier/walkthroughs/detail/${record.id}`),
		});

		return (
			<Content className="walkthroughsPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited supplier walkthrough index page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '0.5em -8px' }}>
					<Col span={24}>
						<Card>
							{
								<PaginatedReduxTable
									emptyState={
										<EmptyState
											graphic={
												<img
													style={{ marginBottom: 8 }}
													src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
												/>
											}
											headline={"It's a little lonely in here."}
											body={
												<div style={{ textAlign: 'center' }}>
													<div style={{ maxWidth: 440, marginBottom: 16 }}>
														Looks like your team has not created any walkthroughs yet.
													</div>
												</div>
											}
										/>
									}
									collectionName="walkthroughs"
									targetCollectionName={WALKTHROUGHS_OVERVIEW_TARGET_COLLECTION}
									columns={columns}
									onRow={onRow}
									showHeader={false}
									keyAccessor={(el) => el.id}
									initialPagination={this.initialPagination}
									fetchData={walkthroughsRestCrudThunksForSupplier.read}
								/>
							}
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	currentUser: state.session.currentUser,
	walkthroughs: state.walkthroughs,
});

const mapDispatchToProps = (dispatch) => ({
	getWalkthroughsCountBy: (params, countName) =>
		dispatch(walkthroughsRestCrudThunksForSupplier.countBy(params, countName)),
	updateWalkthroughFilters: (filters, targetCollection) =>
		dispatch(WALKTHROUGHS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(SupplierWalkthroughIndexPage)
);

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import '@ant-design/compatible/assets/index.css';
import { Layout, Row, Col, Card } from 'antd';
import SupplierContactForm from '../supplier_contact_form/SupplierContactForm';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';

const { Content } = Layout;
const style = require('./SupplierNewAccountSetupProfilePage.less');

class SupplierNewAccountSetupProfilePage extends React.Component<any, any> {
	render() {
		const { currentUser } = this.props;
		return (
			<Content className="supplierNewAccountSetupProfilePage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited supplier new account setup profile page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<Row style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<Card>
							<div>
								<h4 style={{ fontSize: 24 }}>We're almost ready to get started.</h4>

								<p style={{ fontSize: 18 }}>
									Before we jump in, let's add a few more details to your profile.
								</p>
								<SupplierContactForm newAccountSetupMode={true} formData={currentUser} />
							</div>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SupplierNewAccountSetupProfilePage)
);

import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Alert } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { connect } from 'react-redux';
import PostCompletionQuestionnaire from '../PostCompletionQuestionnaire';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { supplierGetQuestionnaire } from '../../thunks/work_orders_thunks';
import { isInternalTech } from '../../utils/AuthUtils';

const FormItem = Form.Item;

interface SupplierApproveReviewWorkFormProps extends FormComponentProps {
	onSubmit: any;
	onCancel: any;
	visible: boolean;
	createErrors: any;
	updateErrors: any;
	creating: boolean;
	updating: boolean;
	workOrderId: number;
	currentUser: any;
	onlyReview: boolean;
	getQuestionnaire: any;
}

class SupplierApproveReviewWorkForm extends React.Component<
	SupplierApproveReviewWorkFormProps,
	any
> {
	constructor(props) {
		super(props);
		this.state = {
			loaded: false,
			questionnaire: {},
		};
	}

	componentDidMount() {
		const { getQuestionnaire } = this.props;
		getQuestionnaire()
			.then((data = []) => {
				if (data.length) {
					this.setState({ loaded: true, questionnaire: data[data.length - 1] });
				}
			})
			.finally(() => this.setState({ loaded: true }));
	}
	render() {
		const {
			visible,
			onCancel,
			onSubmit,
			form,
			workOrderId,
			onlyReview,
			createErrors,
			updateErrors,
			creating,
			updating,
			currentUser,
		} = this.props;
		const { getFieldDecorator } = form;
		getFieldDecorator('workOrderId', { initialValue: workOrderId });

		const isThirdPartySupplier = !isInternalTech(currentUser);

		if (!this.state.loaded) return null;

		if (nullSafeGet('questions.length', this.state.questionnaire)) {
			return (
				<PostCompletionQuestionnaire
					successCb={() => {
						onSubmit({ workOrderId, note: '' });
					}}
					onCancel={onCancel}
					questionsList={this.state.questionnaire}
					workOrderId={workOrderId}
					submitting={creating || updating}
				/>
			);
		}

		//REMOVED ITEMS WITHIN THE FORM BELOW, check ApproveReviewWorkForm.tsx
		return (
			<Modal
				visible={visible}
				width={600}
				title={isThirdPartySupplier ? 'Complete Work' : onlyReview ? 'Review Work' : 'Approve Work'}
				okText={isThirdPartySupplier ? 'Complete' : onlyReview ? 'Review' : 'Approve'}
				onCancel={onCancel}
				onOk={onSubmit}
				closable={false}
				confirmLoading={creating || updating}
			>
				<Form layout="vertical" className="supplierForm">
					{createErrors.length > 0 ? (
						<FormItem>
							<Alert message={createErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					{updateErrors.length > 0 ? (
						<FormItem>
							<Alert message={updateErrors.join(' ')} type="error" />
						</FormItem>
					) : null}
					<FormItem
						label={isThirdPartySupplier ? 'Completed Notes' : 'Review'}
						required={onlyReview}
					>
						{getFieldDecorator('note', {
							rules: [
								{
									required: onlyReview,
									message: 'This field is required.',
								},
							],
						})(<Input.TextArea />)}
					</FormItem>
				</Form>
			</Modal>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	createErrors: state.work_orders.createErrors,
	updateErrors: state.work_orders.updateErrors,
	creating: state.work_orders.creating,
	updating: state.work_orders.updating,
	currentUser: state.session.currentUser,
	onlyReview: ownProps.onlyReview,
	userType: state.session.userType,
});

const mapDispatchToProps = (dispatch) => ({
	getQuestionnaire: (id) => dispatch(supplierGetQuestionnaire(id)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create<SupplierApproveReviewWorkFormProps>()(SupplierApproveReviewWorkForm));

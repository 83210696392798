import React from 'react';
import QuotesTable from '../QuotesTable';

export default function QuotesIndexPage() {
	return (
		<QuotesTable
			handleQueryParams
			enableViews
			//enableMultiSelect
			enableMessaging
			preAppliedFilters={{ isRFPNull: true }}
		/>
	);
}

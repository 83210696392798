import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Button, Card, message, Input } from 'antd';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { fieldTechsRestCrudThunksForSupplier } from '../../thunks/field_techs_thunks';
import { EmptyState } from '../empty_state/EmptyState';
import SubnavBar from '../subnav_bar/SubnavBar';
import { FieldTechRowDisplay } from '../field_tech_row_display/FieldTechRowDisplay';
import {
	capitalizeEachWord,
	currencyFormatter,
	formatDateForParam,
} from '../../utils/DataFormatterUtils';
import { SUPPLIER_ADMIN_ONLY } from '../roles/roles';
import SupplierNewUserInviteForm from '../supplier_new_user_invite_form/SupplierNewUserInviteForm';
import { supplierSignUpInvite } from '../../thunks/supplier_contacts_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import queryString from 'querystring';
import {
	changeFilterValueToArrayOfIds,
	getEndDateFieldName,
	getStartDateFieldName,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import { AdvancedFilters } from '../advanced_filters/AdvancedFilters';
import { getIsActiveFilterField, INITIAL_ACTIVE_FILTERS } from '../../utils/FilterUtils';
import { Form } from '@ant-design/compatible';
import RegionsLocationsTreeSelect from '../regions_tree_select/BuyerRegionsLocationsTreeSelect';
import { FILTER_CONFIG_NAMES, FILTER_FIELD_TYPE } from '../../utils/DataConstants';
import OWAsyncTreeSelect from '../ow_async_tree_select/OWAsyncTreeSelect';
import OWRadioGroup from '../ow_radio_group/OWRadioGroup';
import moment from 'moment';
import OWDateRangePicker from '../ow_date_range_picker/OWDateRangePicker';
import { ASSETS_CRUD_ACTION_CREATORS } from '../../actions/assets_actions';
import { FIELD_TECHS_CRUD_ACTION_CREATORS } from '../../actions/field_techs_actions';

const { Content } = Layout;

const style = require('./FieldTechsIndexPage.less');

const FIELD_TECH_TARGET_COLLECTION = 'fieldTechsIndex';

class FieldTechsIndexPage extends React.Component<any, any> {
	formRefs: any;
	initialPagination: any = { current: 1, pageSize: 5 };

	constructor(props) {
		super(props);

		const { location } = this.props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		const { current, pageSize, ...other } = queryParams;
		this.initialPagination = {
			...this.initialPagination,
			current: current ? current : this.initialPagination.current,
			pageSize: pageSize ? pageSize : this.initialPagination.pageSize,
		};
		this.state = {
			visible: false,
		};
		this.handleSupplierUserInviteCancel = this.handleSupplierUserInviteCancel.bind(this);
		this.handleSupplierUserInviteSubmit = this.handleSupplierUserInviteSubmit.bind(this);
		this.showModal = this.showModal.bind(this);
		this.formRefs = {
			userInviteForm: null,
			roleUpdateForm: null,
		};
	}
	setupPagination() {
		const { location } = this.props;
		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		const { current, pageSize, ...other } = queryParams;
	}

	showModal(e) {
		this.setState({
			visible: true,
		});
	}

	saveFormRef = (formName) => (formRef) => {
		this.formRefs[formName] = formRef;
	};

	handleSupplierUserInviteSubmit(values) {
		const { inviteNewUser } = this.props;
		const contact = {
			...values,
			nameFamily: values.nameFamily || '',
			roles: [values.roles],
		};

		inviteNewUser(contact).then(() => {
			this.setState({ visible: false });
			message.success(`Invite sent to ${contact.nameGiven} at ${contact.email}.`);
		});
	}

	handleSupplierUserInviteCancel(e) {
		this.setState({
			visible: false,
		});
	}

	render() {
		const { history, currentUser, updateFilters, clearAndUpdateFilters, fieldTechs } = this.props;

		const onRow = (record) => ({
			onClick: () =>
				history.push(`/supplier/fieldTechs/detail/${encodeURIComponent(record.email)}`),
		});

		const filterConfig = [];

		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				sorter: true,
				render: (text, record) => <FieldTechRowDisplay fieldTech={record} />,
			},
			{
				title: 'Role',
				dataIndex: 'role',
				sorter: true,
				render: (text, record) => capitalizeEachWord(text),
			},
			{
				title: 'Rate',
				dataIndex: 'hourlyRate',
				sorter: true,
				render: (text, record) => currencyFormatter()(text),
			},
		];

		return (
			<Content className="fieldTechsIndexPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited supplier field techs index page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<SupplierNewUserInviteForm
					wrappedComponentRef={this.saveFormRef('userInviteForm')}
					visible={this.state.visible}
					formData={{ roles: 'SUPPLIER_TECH' }}
					onCancel={this.handleSupplierUserInviteCancel}
					onSubmit={this.handleSupplierUserInviteSubmit}
				/>

				<Row style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<SubnavBar
							left={
								<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
									<AdvancedFilters
										updateFilters={updateFilters}
										filterConfig={filterConfig}
										filtersTargetCollectionName={FIELD_TECH_TARGET_COLLECTION}
										clearAndUpdateFilters={clearAndUpdateFilters}
										filtersStateSlice={fieldTechs}
										preAppliedFilters={{}}
										initialFilters={{}}
									/>
								</div>
							}
							right={null}
							// right={
							//     <div>
							//         <Button
							//             style={{marginLeft: 16}}
							//             type="primary"
							//             size="large"
							//             onClick={() => this.props.history.push('/supplier/fieldTechs/overview/new')}
							//         >
							//             New Field Technician
							//         </Button>
							//     </div>
							// }
						/>
					</Col>
				</Row>
				<Row style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<Card bodyStyle={{ padding: 8 }}>
							<PaginatedReduxTable
								updateQueryParams={true}
								emptyState={
									<EmptyState
										graphic={
											<img
												style={{ marginBottom: 8 }}
												src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
											/>
										}
										headline={"It's a little lonely in here."}
										body={
											<div style={{ textAlign: 'center' }}>
												<div style={{ maxWidth: 440, marginBottom: 16 }}>
													Looks like your team has not added any field techs yet.
												</div>
												{currentUser &&
												currentUser.roles.some((role) => SUPPLIER_ADMIN_ONLY.has(role)) ? (
													<Button type="primary" onClick={(e) => this.showModal(e)}>
														Invite your first field technician
													</Button>
												) : null}
											</div>
										}
									/>
								}
								collectionName="field_techs"
								onRow={onRow}
								showHeader={false}
								targetCollectionName="fieldTechsIndex"
								columns={columns}
								keyAccessor={(el) => el.id}
								initialPagination={this.initialPagination}
								fetchData={fieldTechsRestCrudThunksForSupplier.read}
								initialSorting={{
									sort_by: 'name',
									order: 'ascend',
								}}
							/>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	fieldTechs: state.field_techs,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
	updateFilters: (filters, targetCollection) =>
		dispatch(FIELD_TECHS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
	clearAndUpdateFilters: (filters, targetCollectionName) =>
		dispatch(FIELD_TECHS_CRUD_ACTION_CREATORS.clearAndUpdateFilters(filters, targetCollectionName)),
	inviteNewUser: (contact) => dispatch(supplierSignUpInvite(contact)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FieldTechsIndexPage));

export const CREATED_AT_NEWEST = {
	label: 'Newest',
	selectVal: 'latest',
	value: {
		sort_by: 'createdAt',
		order: 'desc',
	},
};
export const CREATED_AT_OLDEST = {
	label: 'Oldest',
	selectVal: 'oldest',
	value: {
		sort_by: 'createdAt',
		order: 'ascend',
	},
};
export const COST_HIGHEST = {
	label: 'Highest',
	selectVal: 'high',
	value: {
		sort_by: 'totalAfterTax',
		order: 'desc',
	},
};
export const COST_LOWEST = {
	label: 'Lowest',
	selectVal: 'low',
	value: {
		sort_by: 'totalAfterTax',
		order: 'ascend',
	},
};

//Defaults
export const QUOTES_SORTERS = [CREATED_AT_NEWEST, CREATED_AT_OLDEST, COST_HIGHEST, COST_LOWEST];

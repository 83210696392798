import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	quotesRestCrudThunksForBuyer,
	quotesRestCrudThunksForSupplier,
} from '../../thunks/quotes_thunks';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import ProposalForm from '../proposal_form/ProposalForm';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';
import { generateBackLink } from '../../utils/HistoryUtils';
import { updateNavbarState } from '../../actions/navbar_actions';
import { Form } from '@ant-design/compatible';
import { ROLE_TYPES } from '../../utils/DataConstants';

const style = require('./EditQuotePage.less');
const queryString = require('qs');

class EditQuotePage extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			backLinkText: 'Back To Quote ' + props.match.params.id,
		};
	}
	componentDidMount() {
		const { getQuote, match, location, updateNavbar, userType } = this.props;
		const quoteId = match.params.id;
		getQuote(quoteId).then((quote) => {
			let searchString = location.search;
			if (searchString[0] === '?') {
				searchString = searchString.slice(1);
			}
			updateNavbar(
				'Back to Quote ' + match.params.id,
				generateBackLink(`/${userType}/quotes/detail/` + match.params.id + '/details', location)
			);
			const queryParams = queryString.parse(searchString);
			if (queryParams.backlinkParams) {
				this.setState({
					loading: false,
					backLinkTo:
						`/${userType}/quotes/detail/` +
						match.params.id +
						`/details?${queryParams.backlinkParams}`,
				});
			} else {
				this.setState({
					loading: false,
					backLinkTo: `/${userType}/quotes/detail/` + match.params.id + `/details`,
				});
			}
		});
	}

	render() {
		const { history, location, quote, fetching, match, userType } = this.props;

		let searchString = location.search;
		if (searchString[0] === '?') {
			searchString = searchString.slice(1);
		}
		const queryParams = queryString.parse(searchString);
		//noinspection TypeScriptUnresolvedVariable
		return [
			<LogOnMountWithStandardEventProperties eventType="visited edit quote page" />,
			<DetailPageHeader
				exactPath={`/${userType}/quotes/edit/:id`}
				backLinkText={this.state.backLinkText || 'Back to Quote'}
				backLinkTo={this.state.backLinkTo}
				title={'Edit Quote ' + match.params.id}
				tabs={[]}
			/>,
			<EditPage
				title={`Edit Quote ${quote.id}`}
				formComponentProps={{
					formData: quote,
					onSuccess: (rec) => {
						if (!!queryParams.btwo) {
							history.push(`/${userType}/workOrders/detail/${rec.workOrderId}/quotes`);
						} else {
							history.push(`/${userType}/quotes/detail/${rec.id}`);
						}
					},
				}}
				fetching={fetching}
				FormComponent={ProposalForm}
			/>,
		];
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		history: ownProps.history,
		quote: state.proposals.detail,
		fetching: state.proposals.fetching,
		match: ownProps.match,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	getQuote: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? quotesRestCrudThunksForSupplier.readOne(id)
				: quotesRestCrudThunksForBuyer.readOne(id)
		),
	updateNavbar: (backlinkTitle, backlinkTo, navbarBackgroundColor) =>
		dispatch(updateNavbarState(backlinkTitle, backlinkTo, navbarBackgroundColor)),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(EditQuotePage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(Form.create()(ComponentWithoutUserType));

export const crudActionConstantsGenerator = (pluralCapitalizedSnakeCaseEntityName) => {
	return {
		fetchStart: `${pluralCapitalizedSnakeCaseEntityName}_FETCH_START`,
		fetchSuccess: `${pluralCapitalizedSnakeCaseEntityName}_FETCH_SUCCESS`,
		fetchOneSuccess: `${pluralCapitalizedSnakeCaseEntityName}_FETCH_ONE_SUCCESS`,
		fetchOneDeltaSuccess: `${pluralCapitalizedSnakeCaseEntityName}_FETCH_ONE_DELTA_SUCCESS`,
		fetchError: `${pluralCapitalizedSnakeCaseEntityName}_FETCH_ERROR`,

		updateStart: `${pluralCapitalizedSnakeCaseEntityName}_UPDATE_START`,
		updateSuccess: `${pluralCapitalizedSnakeCaseEntityName}_UPDATE_SUCCESS`,
		updateBulkSuccess: `${pluralCapitalizedSnakeCaseEntityName}_UPDATE_BULK_SUCCESS`,
		updateError: `${pluralCapitalizedSnakeCaseEntityName}_UPDATE_ERROR`,

		countByStart: `${pluralCapitalizedSnakeCaseEntityName}_COUNT_BY_START`,
		countBySuccess: `${pluralCapitalizedSnakeCaseEntityName}_COUNT_BY_SUCCESS`,
		countByError: `${pluralCapitalizedSnakeCaseEntityName}_COUNT_BY_ERROR`,

		createStart: `${pluralCapitalizedSnakeCaseEntityName}_CREATE_START`,
		createSuccess: `${pluralCapitalizedSnakeCaseEntityName}_CREATE_SUCCESS`,
		createBulkSuccess: `${pluralCapitalizedSnakeCaseEntityName}_CREATE_BULK_SUCCESS`,
		createError: `${pluralCapitalizedSnakeCaseEntityName}_CREATE_ERROR`,

		archiveStart: `${pluralCapitalizedSnakeCaseEntityName}_ARCHIVE_START`,
		archiveSuccess: `${pluralCapitalizedSnakeCaseEntityName}_ARCHIVE_SUCCESS`,
		archiveError: `${pluralCapitalizedSnakeCaseEntityName}_ARCHIVE_ERROR`,

		updateSelections: `${pluralCapitalizedSnakeCaseEntityName}_UPDATE_SELECTIONS`,

		unarchiveStart: `${pluralCapitalizedSnakeCaseEntityName}_UNARCHIVE_START`,
		unarchiveSuccess: `${pluralCapitalizedSnakeCaseEntityName}_UNARCHIVE_SUCCESS`,
		unarchiveError: `${pluralCapitalizedSnakeCaseEntityName}_UNARCHIVE_ERROR`,

		deleteStart: `${pluralCapitalizedSnakeCaseEntityName}_DELETE_START`,
		deleteSuccess: `${pluralCapitalizedSnakeCaseEntityName}_DELETE_SUCCESS`,
		deleteError: `${pluralCapitalizedSnakeCaseEntityName}_DELETE_ERROR`,

		saveFormData: `${pluralCapitalizedSnakeCaseEntityName}_SAVE_FORM_DATA`,
		clearFormData: `${pluralCapitalizedSnakeCaseEntityName}_CLEAR_FORM_DATA`,

		updateFilters: `${pluralCapitalizedSnakeCaseEntityName}_UPDATE_FILTERS`,
		updateSorting: `${pluralCapitalizedSnakeCaseEntityName}_UPDATE_SORTING`,
		clearAllFilters: `${pluralCapitalizedSnakeCaseEntityName}_CLEAR_ALL_FILTERS`,
		updateFiltersAndSorting: `${pluralCapitalizedSnakeCaseEntityName}_UPDATE_FILTERS_AND_SORTING`,

		clearAndUpdateFilters: `${pluralCapitalizedSnakeCaseEntityName}_CLEAR_AND_UPDATE_FILTERS`,

		clearTargetCollection: `${pluralCapitalizedSnakeCaseEntityName}_CLEAR_TARGET_COLLECTION`,

		cloneTargetCollection: `${pluralCapitalizedSnakeCaseEntityName}_CLONE_TARGET_COLLECTION`,
	};
};
export const searchConstantsGenerator = (pluralCapitalizedSnakeCaseEntityName) => {
	return {
		updateSearchFilters: `${pluralCapitalizedSnakeCaseEntityName}_UPDATE_SEARCH_FILTERS`,
		clearAllSearchFilters: `${pluralCapitalizedSnakeCaseEntityName}_CLEAR_ALL_SEARCH_FILTERS`,
		searchStart: `${pluralCapitalizedSnakeCaseEntityName}_SEARCH_START`,
		searchSuccess: `${pluralCapitalizedSnakeCaseEntityName}_SEARCH_SUCCESS`,
		receiveNationalSearchResults: `${pluralCapitalizedSnakeCaseEntityName}_RECEIVE_NATIONAL_SEARCH_RESULTS`,
		receiveFacetSearchResults: `${pluralCapitalizedSnakeCaseEntityName}_RECEIVE_FACET_SEARCH_RESULTS`,
		searchError: `${pluralCapitalizedSnakeCaseEntityName}_SEARCH_ERROR`,
	};
};

import { Button, Modal, Popover, Timeline } from 'antd';
import UserListComponent from '../user_list_component/UserListComponent';
import moment from 'moment';
import { timeUntilOrSince } from '../../utils/DataFormatterUtils';
import * as React from 'react';
import { useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import DateTimeHover from '../date_time_component/DateTime';

require('./ApprovalHierarchyDisplayQuoteModal.less');

export default function ApprovalHierarchyDisplayQuoteModal({
	currentQuote,
	tiers,
	hasApprovalHierarchy,
	style,
	setVisible,
	visible,
	reason,
	allowApproval,
	exchangeRate,
	currency,
	currentUser,
	syncQuote,
	refresh,
}) {
	const [showExpanded, setShowExpanded] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);

	const isSyncAvailable = nullSafeGetOrElse('isApprovalTierSyncAvailable', currentQuote, false);
	const syncApprovalHierarchyButton = isSyncAvailable ? (
		<span>
			<Button
				loading={buttonLoading}
				className={'ml-1'}
				type={'link'}
				size={'small'}
				icon={<i className={'icons8-refresh'} />}
				onClick={() => {
					setButtonLoading(true);
					syncQuote(currentQuote).then(() => {
						setButtonLoading(false);
						refresh();
					});
				}}
			>
				<span className={'ml-2'}>Refresh Approvers</span>
			</Button>
			<Popover
				content={
					'This approval hierarchy has been updated since the quote approval process started.'
				}
			>
				<InfoCircleOutlined />
			</Popover>
		</span>
	) : null;

	return (
		<Modal
			className={'ApprovalHierarchyDisplayModal'}
			visible={visible}
			width={720}
			title={'Approvers'}
			closable={true}
			maskClosable={true}
			bodyStyle={{ maxHeight: '500px' }}
			footer={
				hasApprovalHierarchy ? (
					<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
						<Button
							onClick={(e) => setShowExpanded(!showExpanded)}
							type={'link'}
							style={{
								color: '#6027b0',
							}}
							className={'ApprovalHierarchyDisplayModal-Expand'}
						>
							{showExpanded ? 'Show Required' : 'Show All'}
						</Button>
					</div>
				) : null
			}
			onCancel={() => setVisible(false)}
		>
			<Timeline style={{ marginTop: '0px' }} mode={'left'}>
				{tiers
					.filter((tier) => showExpanded || tier.isVisible)
					.map((tier, i, tiers) => {
						return (
							<Timeline.Item
								color={
									tier.approvedByUsers.length > 0
										? 'green'
										: tier.declinedByUsers.length > 0
										? 'red'
										: tier.message
										? 'rgba(0, 0, 0, 0.45)'
										: 'rgb(96, 39, 176)'
								}
							>
								<div
									className="ApprovalTier"
									style={{
										paddingTop: '10px',
									}}
								>
									<div
										className="ApprovalTierTitle"
										style={{
											display: 'flex',
											width: '100%',
											justifyContent: 'space-between',
										}}
									>
										<div
											style={{
												display: 'flex',
												backgroundColor: 'white',
												paddingRight: '8px',
												marginTop: '-8px',
												alignItems: 'center',
												gap: '6px',
											}}
										>
											{!tier.isVisible ? (
												<>
													<span style={{ fontSize: '16px', color: 'rgba(0,0,0,0.6)' }}>
														{tier.name ? (
															<>
																<span>{tier.name.concat(' ')}</span>
																<span style={{ color: 'rgba(0,0,0,0.45)' }}>
																	(Tier {tier.label ? tier.label : '1'})
																</span>
															</>
														) : (
															`Tier ${tier.label ? tier.label : '1'}`
														)}
													</span>
													<Popover
														style={{ marginTop: '-8px' }}
														content={tier.message}
														placement={'topLeft'}
													>
														<span style={{ fontSize: '12px', color: 'rgba(0,0,0,0.6)' }}>
															<InfoCircleOutlined />
														</span>
													</Popover>
												</>
											) : (
												<span style={{ fontSize: '16px' }}>
													{tier.name ? (
														<>
															<span>{tier.name.concat(' ')}</span>
															<span style={{ color: 'rgba(0,0,0,0.45)' }}>
																(Tier {tier.label ? tier.label : '1'})
															</span>
														</>
													) : (
														`Tier ${tier.label ? tier.label : '1'}`
													)}
												</span>
											)}
											<span
												className={'ml-1'}
												style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: 14 }}
											>
												{tier.amountUpto
													? `${currency.format(
															nullSafeGetOrElse('lastAmount', tier, 0) * exchangeRate,
															0
													  )} to ${currency.format(tier.amountUpto * exchangeRate, 0)}`
													: `Over ${currency.format(
															nullSafeGetOrElse('lastAmount', tier, 0) * exchangeRate,
															0
													  )}`}
											</span>
										</div>
										<div>
											{tier.approvedByUsers.length > 0 ? null : syncApprovalHierarchyButton}
										</div>
										<div
											style={{
												display: 'none',
												marginLeft: 'auto',
												backgroundColor: 'white',
												paddingLeft: '8px',
												paddingRight: '4px',
												marginTop: '-8px',
											}}
										>
											{tier.approvedByUsers.length > 0
												? 'Approved'
												: tier.declinedByUsers.length > 0
												? 'Declined'
												: 'Pending'}
										</div>
									</div>
									<div className="ApprovalLevelMain">
										<div className="ApprovalLevelMainBody">
											<UserListComponent
												users={tier.approvedByUsers}
												rightItems={tier.approvedByUsers.map((approver) => (
													<span>
														Approved <DateTimeHover timestamp={nullSafeGet('at', approver)} />
													</span>
												))}
												style={style}
												allowRemove={false}
												trailingItems={[]}
												contactType={currentUser.contactType}
											/>
											{tier.approvedByUsers.length === 0 && tier.declinedByUsers.length > 0 ? (
												<UserListComponent
													users={tier.declinedByUsers}
													rightItems={tier.declinedByUsers.map((approver) => (
														<span>
															Declined <DateTimeHover timestamp={nullSafeGet('at', approver)} />
														</span>
													))}
													style={style}
													allowRemove={false}
													trailingItems={[]}
													contactType={currentUser.contactType}
												/>
											) : null}
											{tier.approvedByUsers.length === 0 || showExpanded ? (
												tier.approvers.length > 0 ? (
													<UserListComponent
														users={tier.approvers.filter((approver) => {
															return !tier.approvedByUsers
																.concat(tier.declinedByUsers)
																.some(
																	(user) =>
																		user.email.toLowerCase() === approver.email.toLowerCase()
																);
														})}
														rightItems={
															tier.approvers.filter((approver) => {
																return !tier.approvedByUsers
																	.concat(tier.declinedByUsers)
																	.some(
																		(user) =>
																			user.email.toLowerCase() === approver.email.toLowerCase()
																	);
															}).length > 0
																? ''
																: 'Pending'
														}
														style={style}
														allowRemove={false}
														trailingItems={[]}
														contactType={currentUser.contactType}
													/>
												) : (
													<div className={'mt-1.5 ml-2'}>
														No approvers are assigned to this location.
													</div>
												)
											) : null}
										</div>
									</div>
								</div>
							</Timeline.Item>
						);
					})}
			</Timeline>
		</Modal>
	);
}

import { SITE_SURVEYS_CRUD_ACTION_CREATORS } from '../actions/site_surveys_actions';
import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';

export const siteSurveysRestCrudThunksForBuyer = new RestCrudThunks(
	'site_surveys',
	'site_survey',
	'siteSurvey',
	'siteSurveys',
	SITE_SURVEYS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'site_survey/'
);

export const siteSurveysRestCrudThunksForSupplier = new RestCrudThunks(
	'site_surveys',
	'site_survey',
	'siteSurvey',
	'siteSurveys',
	SITE_SURVEYS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'site_survey/'
);

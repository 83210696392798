export const FETCH_WORK_ORDERS_KPI_BY_MONTH_START = 'FETCH_WORK_ORDERS_KPI_BY_MONTH_START';
export const FETCH_WORK_ORDERS_KPI_BY_MONTH_SUCCESS = 'FETCH_WORK_ORDERS_KPI_BY_MONTH_SUCCESS';
export const FETCH_WORK_ORDERS_KPI_BY_MONTH_FAILURE = 'FETCH_WORK_ORDERS_KPI_BY_MONTH_FAILURE';

export const FETCH_TIMELY_WORK_COMPLETION_KPI_BY_MONTH_START =
	'FETCH_TIMELY_WORK_COMPLETION_KPI_BY_MONTH_START';
export const FETCH_TIMELY_WORK_COMPLETION_KPI_BY_MONTH_SUCCESS =
	'FETCH_TIMELY_WORK_COMPLETION_KPI_BY_MONTH_SUCCESS';
export const FETCH_TIMELY_WORK_COMPLETION_KPI_BY_MONTH_FAILURE =
	'FETCH_TIMELY_WORK_COMPLETION_KPI_BY_MONTH_FAILURE';

export const FETCH_ON_TIME_ARRIVAL_KPI_BY_MONTH_START = 'FETCH_ON_TIME_ARRIVAL_KPI_BY_MONTH_START';
export const FETCH_ON_TIME_ARRIVAL_KPI_BY_MONTH_SUCCESS =
	'FETCH_ON_TIME_ARRIVAL_KPI_BY_MONTH_SUCCESS';
export const FETCH_ON_TIME_ARRIVAL_KPI_BY_MONTH_FAILURE =
	'FETCH_ON_TIME_ARRIVAL_KPI_BY_MONTH_FAILURE';

export const FETCH_CHECK_IN_COMPLIANCE_KPI_BY_MONTH_START =
	'FETCH_CHECK_IN_COMPLIANCE_KPI_BY_MONTH_START';
export const FETCH_CHECK_IN_COMPLIANCE_KPI_BY_MONTH_SUCCESS =
	'FETCH_CHECK_IN_COMPLIANCE_KPI_BY_MONTH_SUCCESS';
export const FETCH_CHECK_IN_COMPLIANCE_KPI_BY_MONTH_FAILURE =
	'FETCH_CHECK_IN_COMPLIANCE_KPI_BY_MONTH_FAILURE';

export const FETCH_FIRST_TIME_RESOLUTION_KPI_BY_MONTH_START =
	'FETCH_FIRST_TIME_RESOLUTION_KPI_BY_MONTH_START';
export const FETCH_FIRST_TIME_RESOLUTION_KPI_BY_MONTH_SUCCESS =
	'FETCH_FIRST_TIME_RESOLUTION_KPI_BY_MONTH_SUCCESS';
export const FETCH_FIRST_TIME_RESOLUTION_KPI_BY_MONTH_FAILURE =
	'FETCH_FIRST_TIME_RESOLUTION_KPI_BY_MONTH_FAILURE';

export const FETCH_TIMELY_INVOICING_KPI_BY_MONTH_START =
	'FETCH_TIMELY_INVOICING_KPI_BY_MONTH_START';
export const FETCH_TIMELY_INVOICING_KPI_BY_MONTH_SUCCESS =
	'FETCH_TIMELY_INVOICING_KPI_BY_MONTH_SUCCESS';
export const FETCH_TIMELY_INVOICING_KPI_BY_MONTH_FAILURE =
	'FETCH_TIMELY_INVOICING_KPI_BY_MONTH_FAILURE';

export const UPDATE_KPI_VENDOR_FILTERS = 'UPDATE_KPI_VENDOR_FILTERS';
export const CLEAR_KPI_VENDOR_FILTERS = 'CLEAR_KPI_VENDOR_FILTERS';

export const FETCH_ALL_MONTHLY_KPIS_START = 'FETCH_ALL_MONTHLY_KPIS_START';
export const FETCH_ALL_MONTHLY_KPIS_SUCCESS = 'FETCH_ALL_MONTHLY_KPIS_SUCCESS';
export const FETCH_ALL_MONTHLY_KPIS_FAILURE = 'FETCH_ALL_MONTHLY_KPIS_FAILURE';

export const FETCH_TIME_TO_ACCEPT_KPI_BY_MONTH_START = ' FETCH_TIME_TO_ACCEPT_KPI_BY_MONTH_START';
export const FETCH_TIME_TO_ACCEPT_KPI_BY_MONTH_SUCCESS =
	' FETCH_TIME_TO_ACCEPT_KPI_BY_MONTH_SUCCESS';
export const FETCH_TIME_TO_ACCEPT_KPI_BY_MONTH_FAILURE =
	' FETCH_TIME_TO_ACCEPT_KPI_BY_MONTH_FAILURE';
export const FETCH_TIME_TO_SCHEDULE_KPI_BY_MONTH_START =
	' FETCH_TIME_TO_SCHEDULE_KPI_BY_MONTH_START';
export const FETCH_TIME_TO_SCHEDULE_KPI_BY_MONTH_SUCCESS =
	' FETCH_TIME_TO_SCHEDULE_KPI_BY_MONTH_SUCCESS';
export const FETCH_TIME_TO_SCHEDULE_KPI_BY_MONTH_FAILURE =
	' FETCH_TIME_TO_SCHEDULE_KPI_BY_MONTH_FAILURE';

export function fetchWorkOrdersKPIByMonthStart() {
	return {
		type: FETCH_WORK_ORDERS_KPI_BY_MONTH_START,
	};
}

export function fetchWorkOrdersKPIByMonthSuccess(data) {
	return {
		type: FETCH_WORK_ORDERS_KPI_BY_MONTH_SUCCESS,
		data,
	};
}

export function fetchWorkOrdersKPIByMonthFailure(errors) {
	return {
		type: FETCH_WORK_ORDERS_KPI_BY_MONTH_FAILURE,
		errors,
	};
}

export function fetchTimelyWorkCompletionKPIByMonthStart() {
	return {
		type: FETCH_TIMELY_WORK_COMPLETION_KPI_BY_MONTH_START,
	};
}

export function fetchTimelyWorkCompletionKPIByMonthSuccess(data) {
	return {
		type: FETCH_TIMELY_WORK_COMPLETION_KPI_BY_MONTH_SUCCESS,
		data,
	};
}

export function fetchTimelyWorkCompletionKPIByMonthFailure(errors) {
	return {
		type: FETCH_TIMELY_WORK_COMPLETION_KPI_BY_MONTH_FAILURE,
		errors,
	};
}

export function fetchOnTimeArrivalKPIByMonthStart() {
	return {
		type: FETCH_ON_TIME_ARRIVAL_KPI_BY_MONTH_START,
	};
}

export function fetchOnTimeArrivalKPIByMonthSuccess(data) {
	return {
		type: FETCH_ON_TIME_ARRIVAL_KPI_BY_MONTH_SUCCESS,
		data,
	};
}

export function fetchOnTimeArrivalKPIByMonthFailure(errors) {
	return {
		type: FETCH_ON_TIME_ARRIVAL_KPI_BY_MONTH_FAILURE,
		errors,
	};
}

export function fetchCheckInComplianceKPIByMonthStart() {
	return {
		type: FETCH_CHECK_IN_COMPLIANCE_KPI_BY_MONTH_START,
	};
}

export function fetchCheckInComplianceKPIByMonthSuccess(data) {
	return {
		type: FETCH_CHECK_IN_COMPLIANCE_KPI_BY_MONTH_SUCCESS,
		data,
	};
}

export function fetchCheckInComplianceKPIByMonthFailure(errors) {
	return {
		type: FETCH_CHECK_IN_COMPLIANCE_KPI_BY_MONTH_FAILURE,
		errors,
	};
}

export function fetchTimeToAcceptKPIByMonthStart() {
	return {
		type: FETCH_TIME_TO_ACCEPT_KPI_BY_MONTH_START,
	};
}

export function fetchTimeToAcceptKPIByMonthSuccess(data) {
	return {
		type: FETCH_TIME_TO_ACCEPT_KPI_BY_MONTH_SUCCESS,
		data,
	};
}

export function fetchTimeToAcceptKPIByMonthFailure(errors) {
	return {
		type: FETCH_TIME_TO_ACCEPT_KPI_BY_MONTH_FAILURE,
		errors,
	};
}

export function fetchTimeToScheduleKPIByMonthStart() {
	return {
		type: FETCH_TIME_TO_SCHEDULE_KPI_BY_MONTH_START,
	};
}

export function fetchTimeToScheduleKPIByMonthSuccess(data) {
	return {
		type: FETCH_TIME_TO_SCHEDULE_KPI_BY_MONTH_SUCCESS,
		data,
	};
}

export function fetchTimeToScheduleKPIByMonthFailure(errors) {
	return {
		type: FETCH_TIME_TO_SCHEDULE_KPI_BY_MONTH_FAILURE,
		errors,
	};
}

export function fetchFirstTimeResolutionKPIByMonthStart() {
	return {
		type: FETCH_FIRST_TIME_RESOLUTION_KPI_BY_MONTH_START,
	};
}

export function fetchFirstTimeResolutionKPIByMonthSuccess(data) {
	return {
		type: FETCH_FIRST_TIME_RESOLUTION_KPI_BY_MONTH_SUCCESS,
		data,
	};
}

export function fetchFirstTimeResolutionKPIByMonthFailure(errors) {
	return {
		type: FETCH_FIRST_TIME_RESOLUTION_KPI_BY_MONTH_FAILURE,
		errors,
	};
}

export function fetchTimelyInvoicingKPIByMonthStart() {
	return {
		type: FETCH_TIMELY_INVOICING_KPI_BY_MONTH_START,
	};
}

export function fetchTimelyInvoicingKPIByMonthSuccess(data) {
	return {
		type: FETCH_TIMELY_INVOICING_KPI_BY_MONTH_SUCCESS,
		data,
	};
}

export function fetchTimelyInvoicingKPIByMonthFailure(errors) {
	return {
		type: FETCH_TIMELY_INVOICING_KPI_BY_MONTH_FAILURE,
		errors,
	};
}

export function updateKPIVendorFilters(filters) {
	return {
		type: UPDATE_KPI_VENDOR_FILTERS,
		filters,
	};
}

export function clearKPIVendorFilters() {
	return {
		type: CLEAR_KPI_VENDOR_FILTERS,
	};
}

export function fetchAllMonthlyKpisStart() {
	return {
		type: FETCH_ALL_MONTHLY_KPIS_START,
	};
}

export function fetchAllMonthlyKpisSuccess(data) {
	return {
		type: FETCH_ALL_MONTHLY_KPIS_SUCCESS,
		data,
	};
}

export function fetchAllMonthlyKpisFailure(errors) {
	return {
		type: FETCH_ALL_MONTHLY_KPIS_FAILURE,
		errors,
	};
}

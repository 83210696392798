import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Alert, Spin, Select, Switch } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { connect } from 'react-redux';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import {
	supplierFacilitiesRestCrudThunksForBuyer,
	supplierFacilitiesRestCrudThunksForSupplier,
} from '../../thunks/supplier_facilities_thunks';
import {
	spendCategoriesRestCrudThunksForBuyer,
	spendCategoriesRestCrudThunksForSupplier,
} from '../../thunks/spend_categories_thunks';
import {
	fetchLocationsWithRegionsForBuyer,
	fetchLocationsWithRegionsForSupplier,
	locationsRestCrudThunksForBuyer,
	locationsRestCrudThunksForSupplier,
} from '../../thunks/locations_thunks';
import {
	problemTypesHeirarchicalForBuyer,
	problemTypesHeirarchicalForSupplier,
	problemTypesRestCrudThunksForBuyer,
	problemTypesRestCrudThunksForSupplier,
} from '../../thunks/problem_types_thunks';
import ProblemTypeTreeSelect from '../problem_type_tree_select';
import {
	regionsRestCrudThunksForBuyer,
	regionsRestCrudThunksForSupplier,
} from '../../thunks/regions_thunks';
import OWLocationAsyncTreeSelect from '../ow_location_async_tree_select/OWLocationAsyncTreeSelect';
import { LocationDropdownRecord } from '../name_component/location/LocationDropdownRecord';
import { ROLE_TYPES } from '../../utils/DataConstants';

const FormItem = Form.Item;

interface SupplierPrivateNetworkCreateFormProps extends FormComponentProps {
	onSubmit: any;
	onCancel: any;
	visible: boolean;
	supplierFacilityId: any;
	supplierFacility: any;
	createErrors: any;
	updateErrors: any;
	fetchSpendCategories: any;
	fetchMultipleSpendCategories: any;
	fetchProblemTypes: any;
	fetchMultipleProblemTypes: any;
	fetchMultipleLocations: any;
	spendCategories: any;
	problemTypes: any;
	fetchLocations: any;
	locations: any;
	currentUser: any;
	supplierFacilitiesFetching: boolean;
	getSupplierFacility: any;
	fetchLocationsWithHierarchy: any;
	fetchMultipleRegions: any;
	loading: any;
}

class SupplierPrivateNetworkCreateForm extends React.Component<
	SupplierPrivateNetworkCreateFormProps,
	any
> {
	constructor(props) {
		super(props);
		this.state = {
			allProblemTypesSelected: false,
			allLocationsSelected: false,
			noLocationOrProblemTypeAccess: false,
		};
		this.handleAllProblemTypesChange = this.handleAllProblemTypesChange.bind(this);
		this.handleAllLocationsChange = this.handleAllLocationsChange.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		const { getSupplierFacility } = this.props;
		if (
			nextProps.supplierFacilityId !== this.props.supplierFacilityId &&
			nextProps.supplierFacilityId
		) {
			getSupplierFacility(nextProps.supplierFacilityId);
		}
	}

	handleAllProblemTypesChange(checked) {
		this.setState({ allProblemTypesSelected: checked });
	}

	handleAllLocationsChange(checked) {
		this.setState({ allLocationsSelected: checked });
	}

	render() {
		const {
			supplierFacility,
			fetchProblemTypes,
			fetchMultipleProblemTypes,
			problemTypes,
			locations,
			supplierFacilitiesFetching,
			visible,
			onCancel,
			onSubmit,
			form,
			currentUser,
			createErrors,
			updateErrors,
			fetchLocationsWithHierarchy,
			fetchMultipleRegions,
			loading,
		} = this.props;
		const { getFieldDecorator, getFieldValue } = form;
		getFieldDecorator('buyerCompanyId', {
			initialValue: nullSafeGet('facility.buyerCompanyId', currentUser),
		});
		getFieldDecorator('supplierFacilityId', { initialValue: nullSafeGet('id', supplierFacility) });

		const locationIdMandatory =
			!this.state.allLocationsSelected &&
			(this.state.allProblemTypesSelected || !!nullSafeGet('0', getFieldValue('problemTypeIds')));
		const problemTypeMandaotry =
			!this.state.allProblemTypesSelected &&
			(this.state.allLocationsSelected || !!nullSafeGet('0', getFieldValue('locationIds')));

		return (
			<Modal
				visible={visible}
				width={600}
				okText="Add supplier"
				onCancel={onCancel}
				onOk={onSubmit}
				closable={false}
				confirmLoading={loading}
			>
				{supplierFacilitiesFetching ? (
					<Spin />
				) : (
					<Form layout="vertical" className="supplierForm">
						<h5>Add {supplierFacility.displayName} to Private Network</h5>
						{createErrors.length > 0 ? (
							<FormItem>
								<Alert message={createErrors.join(' ')} type="error" />
							</FormItem>
						) : null}
						{updateErrors.length > 0 ? (
							<FormItem>
								<Alert message={updateErrors.join(' ')} type="error" />
							</FormItem>
						) : null}

						<FormItem label="Problem Types">
							{[
								<FormItem>
									{getFieldDecorator('allProblemTypes', {
										initialValue: this.state.allProblemTypesSelected,
										valuePropName: 'checked',
									})(<Switch onChange={this.handleAllProblemTypesChange} />)}
									<label style={{ marginLeft: 8 }}>Select All Problem Types</label>
								</FormItem>,
								getFieldDecorator('problemTypeIds', {
									rules: [
										{
											type: 'array',
											required: problemTypeMandaotry,
											message: 'Please select a problem type',
										},
									],
								})(
									<ProblemTypeTreeSelect
										mode="multiple"
										stateSlice={problemTypes}
										targetCollectionName="SUPPLIER_PRIVATE_NETWORK_FORM_PROBLEM_TYPES_AUTOCOMPLETE"
										fetchMultiple={(ids, targetCollectionName) => {
											fetchMultipleProblemTypes(ids, targetCollectionName);
										}}
										fetchData={(
											searchText,
											targetCollectionName,
											pagination,
											sorting,
											filters,
											addToTargetCollection
										) =>
											fetchProblemTypes(
												{ name: searchText },
												targetCollectionName,
												pagination,
												sorting,
												filters,
												addToTargetCollection
											)
										}
										disabled={this.state.allProblemTypesSelected}
										emptyWhenDisabled={true}
										placeholder={
											this.state.allProblemTypesSelected
												? 'All Problem Types Selected'
												: 'Select Problem Types'
										}
										renderRecord={(problemType) => (
											<Select.Option key={problemType.id} value={problemType.id}>
												{problemType.hierarchyName}
											</Select.Option>
										)}
									/>
								),
							]}
						</FormItem>
						<FormItem label="Locations">
							{[
								<FormItem>
									{getFieldDecorator('allLocations', {
										initialValue: this.state.allLocationsSelected,
										valuePropName: 'checked',
									})(<Switch onChange={this.handleAllLocationsChange} />)}
									<label style={{ marginLeft: 8 }}>Select All Locations</label>
								</FormItem>,
								getFieldDecorator('locationIds', {
									rules: [
										{
											type: 'array',
											required: locationIdMandatory,
											message: 'Please select a location',
										},
									],
								})(
									<OWLocationAsyncTreeSelect
										mode="multiple"
										stateSlice={locations}
										disabled={this.state.allLocationsSelected}
										targetCollectionName="SUPPLIER_PRIVATE_NETWORK_FORM_LOCATIONS_AUTOCOMPLETE"
										fetchMultiple={(ids, targetCollectionName) => {
											fetchMultipleRegions(ids, targetCollectionName);
										}}
										fetchData={(
											searchText,
											targetCollectionName,
											pagination,
											sorting,
											filters,
											addToTargetCollection
										) => {
											return fetchLocationsWithHierarchy(
												{ name: searchText },
												targetCollectionName,
												pagination,
												sorting,
												filters,
												addToTargetCollection
											);
										}}
										emptyWhenDisabled={true}
										placeholder={
											this.state.allLocationsSelected
												? 'All Locations Selected'
												: 'Select Locations'
										}
										renderRecord={(record) => <LocationDropdownRecord record={record} />}
									/>
								),
							]}
						</FormItem>
					</Form>
				)}
			</Modal>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	visible: ownProps.visible,
	onCancel: ownProps.onCancel,
	onSubmit: ownProps.onSubmit,
	supplierFacilityId: ownProps.supplierFacilityId,
	supplierFacilitiesFetching: state.supplier_facilities.fetching,
	supplierFacility: state.supplier_facilities.detail,
	spendCategories: state.spend_categories,
	problemTypes: state.problem_types,
	locations: state.locations,
	createErrors: state.supplier_private_networks.createErrors,
	updateErrors: state.supplier_private_networks.updateErrors,

	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getSupplierFacility: (id) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? supplierFacilitiesRestCrudThunksForSupplier.readOne(id)
				: supplierFacilitiesRestCrudThunksForBuyer.readOne(id)
		),
	fetchSpendCategories: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? spendCategoriesRestCrudThunksForSupplier.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: spendCategoriesRestCrudThunksForBuyer.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultipleSpendCategories: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? spendCategoriesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: spendCategoriesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchProblemTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? problemTypesHeirarchicalForSupplier(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: problemTypesHeirarchicalForBuyer(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters
				  )
		),
	fetchMultipleProblemTypes: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? problemTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: problemTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: locationsRestCrudThunksForBuyer.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: locationsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchLocationsWithHierarchy: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? fetchLocationsWithRegionsForSupplier(
						{ ...params, includeInactive: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: fetchLocationsWithRegionsForBuyer(
						{ ...params, includeInactive: true },
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	fetchMultipleRegions: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? regionsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: regionsRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create<SupplierPrivateNetworkCreateFormProps>()(SupplierPrivateNetworkCreateForm));

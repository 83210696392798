import * as React from 'react';
import { connect } from 'react-redux';
import '@ant-design/compatible/assets/index.css';
import { Card, Col, Layout, Row, Spin, Select, DatePicker, Radio } from 'antd';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { floatToPercentageString } from '../../utils/DataFormatterUtils';
import { withRouter } from 'react-router';
import { debounce } from '../../utils/PerformanceUtils';
import { fieldTechsRestCrudThunksForSupplier } from '../../thunks/field_techs_thunks';
import {
	fetchAllTechKPITotals,
	fetchAssetDownTimeTechKPITotal,
	fetchAverageSatisfactionRatingKPITotal,
	fetchCheckInRateTechKPITotal,
	fetchCompletedWorkOrdersTechKPITotal,
	fetchCompletionTimeTechKPITotal,
	fetchCorrectiveMaintenanceRateTechKPITotal,
	fetchFirstTimeResolutionTechKPITotal,
	fetchNewWorkOrdersTechKPITotal,
	fetchPlannedMaintenanceRateTechKPITotal,
	fetchResponseTimeTechKPITotal,
	fetchStaleWorkOrdersTechKPITotal,
	fetchPMWorkOrdersTechKPITotal,
	fetchNewCreatedByTechWorkOrdersTechKPITotal,
	fetchPMCompletedWorkOrdersTechKPITotal,
} from '../../thunks/tech_kpi_analytics_thunks';
import {
	clearTechKPIFilters,
	updateTechKPIFilters,
} from '../../actions/tech_kpi_analytics_actions';
import moment from 'moment';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { locationsRestCrudThunksForSupplier } from '../../thunks/locations_thunks';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import SupplierRegionsLocationsTreeSelect from '../regions_tree_select/SupplierRegionsLocationsTreeSelect';

const Color = require('color');
const style = require('./SupplierFieldTechsAnalyticsDetailPage.less');
const isEqual = require('fast-deep-equal');

const Content = Layout.Content;

class KPITechAnalyticsDetailPage extends React.Component<any, any> {
	state = {
		initialLoading: true,
		month: null,
		locationIds: undefined,
		startDate: moment().startOf('month').format('YYYY-MM-DD'),
		endDate: moment().format('YYYY-MM-DD'),
	};

	debouncedCallback = debounce(
		() => {
			const {
				techKpiAnalytics,
				getNewWorkOrdersKPITotal,
				getNewCreatedByTechWorkOrdersKPITotal,
				getPMWorkOrdersKPITotal,
				getCompletedWorkOrdersKPITotal,
				getPMCompletedWorkOrdersKPITotal,
				getStaleWorkOrdersKPITotal,
				getPlannedMaintenanceRateKPITotal,
				getCorrectiveMaintenanceRateKPITotal,
				getCheckInRateKPITotal,
				getFirstTimeResolutionKPITotal,
				getAssetDownTimeKPITotal,
				getResponseTimeKPITotal,
				getCompletionTimeKPITotal,
				getAverageSatisfactionRating,
				getKPIs,
			} = this.props;
			const { initialLoading } = this.state;
			const promises = [
				getNewWorkOrdersKPITotal(techKpiAnalytics.techKpiFilters),
				getNewCreatedByTechWorkOrdersKPITotal(techKpiAnalytics.techKpiFilters),
				getPMWorkOrdersKPITotal(techKpiAnalytics.techKpiFilters),
				getCompletedWorkOrdersKPITotal(techKpiAnalytics.techKpiFilters),
				getPMCompletedWorkOrdersKPITotal(techKpiAnalytics.techKpiFilters),
				getStaleWorkOrdersKPITotal(techKpiAnalytics.techKpiFilters),
				getPlannedMaintenanceRateKPITotal(techKpiAnalytics.techKpiFilters),
				getCorrectiveMaintenanceRateKPITotal(techKpiAnalytics.techKpiFilters),
				getCheckInRateKPITotal(techKpiAnalytics.techKpiFilters),
				getFirstTimeResolutionKPITotal(techKpiAnalytics.techKpiFilters),
				getAssetDownTimeKPITotal(techKpiAnalytics.techKpiFilters),
				getResponseTimeKPITotal(techKpiAnalytics.techKpiFilters),
				getCompletionTimeKPITotal(techKpiAnalytics.techKpiFilters),
				getAverageSatisfactionRating(techKpiAnalytics.techKpiFilters),
				getKPIs(techKpiAnalytics.techKpiFilters),
			];
			if (initialLoading) {
				Promise.all(promises).then(() => {
					this.setState({
						initialLoading: false,
						month: techKpiAnalytics.techKpiFilters.month,
						startDate: techKpiAnalytics.techKpiFilters.startDate,
						endDate: techKpiAnalytics.techKpiFilters.endDate,
						locationIds: techKpiAnalytics.techKpiFilters.locationIds,
					});
				});
			}
		},
		500,
		false
	);

	componentWillReceiveProps(nextProps) {
		const { techKpiAnalytics } = this.props;
		if (!isEqual(nextProps.techKpiAnalytics.techKpiFilters, techKpiAnalytics.techKpiFilters)) {
			this.debouncedCallback();
		}
	}

	componentDidMount() {
		const {
			fetchFieldTech,

			match,
			techKpiAnalytics,
			saveKPIFilters,
		} = this.props;
		const fieldTechEmail = match.params.email;
		fetchFieldTech(fieldTechEmail);
		saveKPIFilters({
			techEmail: fieldTechEmail,
			startDate: techKpiAnalytics.techKpiFilters.startDate || this.state.startDate,
			endDate: techKpiAnalytics.techKpiFilters.endDate || this.state.endDate,
		});
	}

	componentWillUnmount() {
		const { clearKPIFilters } = this.props;
		clearKPIFilters();
	}

	changeDates = (values, dates) => {
		const { saveKPIFilters } = this.props;
		const startDate = dates[0];
		const endDate = dates[1];
		this.setState({ month: null, startDate: startDate, endDate: endDate });
		saveKPIFilters({ month: null, startDate: startDate, endDate: endDate });
	};

	changeLocationFilter = (value) => {
		const { saveKPIFilters } = this.props;
		const locationIds = Array.isArray(value)
			? value.length > 0
				? value
				: undefined
			: value || undefined;
		this.setState({ locationIds });
		saveKPIFilters({ locationIds });
	};

	changePMFilter = (value) => {
		const { saveKPIFilters } = this.props;
		this.setState({ isPM: value });
		saveKPIFilters({ isPM: value });
	};

	changeSupplierInitiatedFilter = (value) => {
		const { saveKPIFilters } = this.props;
		this.setState({ isSupplierInitiated: value });
		saveKPIFilters({ isSupplierInitiated: value });
	};

	render() {
		const {
			fieldTech,
			locations,
			companyConfig,
			match,
			techKpiAnalytics,
			saveKPIFilters,
			clearKPIFilters,
			form,

			fetchMultipleLocations,
			fetchLocations,
		} = this.props;
		const { initialLoading, startDate, endDate } = this.state;

		const RANGE_PICKER_DATE_FORMAT = 'YYYY-MM-DD';

		const {
			newWorkOrdersTotal,
			newTechCreatedWorkOrdersTotal,
			pmWorkOrdersTotal,
			completedWorkOrdersTotal,
			pmCompletedWorkOrdersTotal,
			staleWorkOrdersTotal,
			plannedMaintenanceRateTotal,
			correctiveMaintenanceRateTotal,
			checkInRateTotal,
			firstTimeRateTotal,
			assetDownTimeTotal,
			responseTimeTotal,
			completionTimeTotal,
			avgSatisfactionRatingTotal,
			techKPIsByTech,
		} = techKpiAnalytics;

		const getKPIColor = (val, baseline) => {
			if (val === null || val === undefined) {
				return 'rgba(0,0,0,0.45)';
			} else if (val >= baseline) {
				return 'green';
			} else {
				return 'red';
			}
		};

		const getReverseKPIColor = (val, baseline) => {
			if (val === null || val === undefined) {
				return 'rgba(0,0,0,0.45)';
			} else if (val >= baseline) {
				return 'red';
			} else {
				return 'green';
			}
		};

		const fieldTechEmail = match.params.email;

		const percentageDifference = (currentVal, previousVal) => {
			if (currentVal && previousVal) {
				return (currentVal - previousVal) / ((currentVal + previousVal) / 2);
			} else {
				return null;
			}
		};

		const fieldTechKPIs = [
			{
				displayName: 'New WOs',
				loading: newWorkOrdersTotal.loading,
				id: 'newWOs',
				color: 'standard',
				currentMonthValue:
					newWorkOrdersTotal.data.length > 0
						? nullSafeGetOrElse(
								'newWOs',
								newWorkOrdersTotal.data[newWorkOrdersTotal.data.length - 1],
								'--'
						  )
						: null,
				percentChange: percentageDifference(
					nullSafeGet('newWOs', newWorkOrdersTotal.data[newWorkOrdersTotal.data.length - 1]),
					nullSafeGet('newWOs', newWorkOrdersTotal.data[newWorkOrdersTotal.data.length - 2])
				),
				// last6Months: [{x: '2019-5-01', y: 0.5}, {x: '2019-6-01', y: 0.4}, {
				//     x: '2019-7-01',
				//     y: 0.3
				// }, {x: '2019-8-01', y: 0.25}, {x: '2019-9-01', y: 0.5}, {x: '2019-10-01', y: 0.45}]
			},
			{
				displayName: 'New Reactive WOs',
				loading: newWorkOrdersTotal.loading && pmWorkOrdersTotal.loading,
				id: 'newReactiveWOs',
				color: 'standard',
				currentMonthValue:
					newWorkOrdersTotal.data.length > 0
						? nullSafeGetOrElse(
								'newWOs',
								newWorkOrdersTotal.data[newWorkOrdersTotal.data.length - 1],
								0
						  ) -
								nullSafeGetOrElse(
									'pmWOs',
									pmWorkOrdersTotal.data[pmWorkOrdersTotal.data.length - 1],
									0
								) >
						  0
							? nullSafeGetOrElse(
									'newWOs',
									newWorkOrdersTotal.data[newWorkOrdersTotal.data.length - 1],
									0
							  ) -
							  nullSafeGetOrElse(
									'pmWOs',
									pmWorkOrdersTotal.data[pmWorkOrdersTotal.data.length - 1],
									0
							  )
							: '--'
						: null,
				percentChange: percentageDifference(
					nullSafeGet('newWOs', newWorkOrdersTotal.data[newWorkOrdersTotal.data.length - 1]) -
						nullSafeGetOrElse(
							'pmWOs',
							pmWorkOrdersTotal.data[pmWorkOrdersTotal.data.length - 1],
							0
						),
					nullSafeGet('newWOs', newWorkOrdersTotal.data[newWorkOrdersTotal.data.length - 2]) -
						nullSafeGetOrElse('pmWOs', pmWorkOrdersTotal.data[pmWorkOrdersTotal.data.length - 2], 0)
				),
				// last6Months: [{x: '2019-5-01', y: 0.5}, {x: '2019-6-01', y: 0.4}, {
				//     x: '2019-7-01',
				//     y: 0.3
				// }, {x: '2019-8-01', y: 0.25}, {x: '2019-9-01', y: 0.5}, {x: '2019-10-01', y: 0.45}]
			},
			{
				displayName: 'New Planned Maintenance WOs',
				loading: pmWorkOrdersTotal.loading,
				id: 'pmWOs',
				color: 'standard',
				currentMonthValue:
					pmWorkOrdersTotal.data.length > 0
						? nullSafeGetOrElse(
								'pmWOs',
								pmWorkOrdersTotal.data[pmWorkOrdersTotal.data.length - 1],
								'--'
						  )
						: null,
				percentChange: percentageDifference(
					nullSafeGet('pmWOs', pmWorkOrdersTotal.data[pmWorkOrdersTotal.data.length - 1]),
					nullSafeGet('pmWOs', pmWorkOrdersTotal.data[pmWorkOrdersTotal.data.length - 2])
				),
				// last6Months: [{x: '2019-5-01', y: 0.5}, {x: '2019-6-01', y: 0.4}, {
				//     x: '2019-7-01',
				//     y: 0.3
				// }, {x: '2019-8-01', y: 0.25}, {x: '2019-9-01', y: 0.5}, {x: '2019-10-01', y: 0.45}]
			},
			{
				displayName: 'New Tech Created WOs',
				loading: newTechCreatedWorkOrdersTotal.loading,
				id: 'newCreatedByTechWOs',
				color: 'standard',
				currentMonthValue:
					newTechCreatedWorkOrdersTotal.data.length > 0
						? nullSafeGetOrElse(
								'newCreatedByTechWOs',
								newTechCreatedWorkOrdersTotal.data[newTechCreatedWorkOrdersTotal.data.length - 1],
								'--'
						  )
						: null,
				percentChange: percentageDifference(
					nullSafeGet(
						'newCreatedByTechWOs',
						newTechCreatedWorkOrdersTotal.data[newTechCreatedWorkOrdersTotal.data.length - 1]
					),
					nullSafeGet(
						'newCreatedByTechWOs',
						newTechCreatedWorkOrdersTotal.data[newTechCreatedWorkOrdersTotal.data.length - 2]
					)
				),
				// last6Months: [{x: '2019-5-01', y: 0.5}, {x: '2019-6-01', y: 0.4}, {
				//     x: '2019-7-01',
				//     y: 0.3
				// }, {x: '2019-8-01', y: 0.25}, {x: '2019-9-01', y: 0.5}, {x: '2019-10-01', y: 0.45}]
			},
			{
				displayName: 'Completed WOs',
				loading: completedWorkOrdersTotal.loading,
				id: 'completedWOs',
				color: 'standard',
				currentMonthValue:
					completedWorkOrdersTotal.data.length > 0
						? nullSafeGetOrElse(
								'completedWOs',
								completedWorkOrdersTotal.data[completedWorkOrdersTotal.data.length - 1],
								'--'
						  )
						: null,
				percentChange: percentageDifference(
					nullSafeGet(
						'completedWOs',
						completedWorkOrdersTotal.data[completedWorkOrdersTotal.data.length - 1]
					),
					nullSafeGet(
						'completedWOs',
						completedWorkOrdersTotal.data[completedWorkOrdersTotal.data.length - 2]
					)
				),
				// last6Months: [{x: '2019-5-01', y: 0.5}, {x: '2019-6-01', y: 0.4}, {
				//     x: '2019-7-01',
				//     y: 0.3
				// }, {x: '2019-8-01', y: 0.25}, {x: '2019-9-01', y: 0.5}, {x: '2019-10-01', y: 0.45}]
			},
			{
				displayName: 'Completed Reactive WOs',
				loading: completedWorkOrdersTotal.loading && pmCompletedWorkOrdersTotal.loading,
				id: 'reactiveCompletedWOs',
				color: 'standard',
				currentMonthValue:
					completedWorkOrdersTotal.data.length > 0
						? nullSafeGetOrElse(
								'completedWOs',
								completedWorkOrdersTotal.data[completedWorkOrdersTotal.data.length - 1],
								0
						  ) -
								nullSafeGetOrElse(
									'completedPMWOs',
									pmCompletedWorkOrdersTotal.data[pmCompletedWorkOrdersTotal.data.length - 1],
									0
								) >
						  0
							? nullSafeGetOrElse(
									'completedWOs',
									completedWorkOrdersTotal.data[completedWorkOrdersTotal.data.length - 1],
									0
							  ) -
							  nullSafeGetOrElse(
									'completedPMWOs',
									pmCompletedWorkOrdersTotal.data[pmCompletedWorkOrdersTotal.data.length - 1],
									0
							  )
							: '--'
						: null,
				percentChange: percentageDifference(
					nullSafeGet(
						'completedWOs',
						completedWorkOrdersTotal.data[completedWorkOrdersTotal.data.length - 1]
					) -
						nullSafeGetOrElse(
							'completedPMWOs',
							pmCompletedWorkOrdersTotal.data[pmCompletedWorkOrdersTotal.data.length - 1],
							0
						),
					nullSafeGet(
						'completedWOs',
						completedWorkOrdersTotal.data[completedWorkOrdersTotal.data.length - 2]
					) -
						nullSafeGetOrElse(
							'completedPMWOs',
							pmCompletedWorkOrdersTotal.data[pmCompletedWorkOrdersTotal.data.length - 2],
							0
						)
				),
				// last6Months: [{x: '2019-5-01', y: 0.5}, {x: '2019-6-01', y: 0.4}, {
				//     x: '2019-7-01',
				//     y: 0.3
				// }, {x: '2019-8-01', y: 0.25}, {x: '2019-9-01', y: 0.5}, {x: '2019-10-01', y: 0.45}]
			},
			{
				displayName: 'Completed Planned Maintenance WOs',
				loading: pmCompletedWorkOrdersTotal.loading,
				id: 'completedPMWOs',
				color: 'standard',
				currentMonthValue:
					pmCompletedWorkOrdersTotal.data.length > 0
						? nullSafeGetOrElse(
								'completedPMWOs',
								pmCompletedWorkOrdersTotal.data[pmCompletedWorkOrdersTotal.data.length - 1],
								'--'
						  )
						: null,
				percentChange: percentageDifference(
					nullSafeGet(
						'completedPMWOs',
						pmCompletedWorkOrdersTotal.data[pmCompletedWorkOrdersTotal.data.length - 1]
					),
					nullSafeGet(
						'completedPMWOs',
						pmCompletedWorkOrdersTotal.data[pmCompletedWorkOrdersTotal.data.length - 2]
					)
				),
				// last6Months: [{x: '2019-5-01', y: 0.5}, {x: '2019-6-01', y: 0.4}, {
				//     x: '2019-7-01',
				//     y: 0.3
				// }, {x: '2019-8-01', y: 0.25}, {x: '2019-9-01', y: 0.5}, {x: '2019-10-01', y: 0.45}]
			},
			{
				displayName: 'Stale WOs',
				loading: staleWorkOrdersTotal.loading,
				id: 'staleWOs',
				color: 'reverse',
				currentMonthValue:
					staleWorkOrdersTotal.data.length > 0
						? nullSafeGetOrElse(
								'staleWOs',
								staleWorkOrdersTotal.data[staleWorkOrdersTotal.data.length - 1],
								'--'
						  )
						: null,
				percentChange: percentageDifference(
					nullSafeGet('staleWOs', staleWorkOrdersTotal.data[staleWorkOrdersTotal.data.length - 1]),
					nullSafeGet('staleWOs', staleWorkOrdersTotal.data[staleWorkOrdersTotal.data.length - 2])
				),
				// last6Months: [{x: '2019-5-01', y: 0.5}, {x: '2019-6-01', y: 0.4}, {
				//     x: '2019-7-01',
				//     y: 0.3
				// }, {x: '2019-8-01', y: 0.25}, {x: '2019-9-01', y: 0.5}, {x: '2019-10-01', y: 0.45}]
			},
			{
				displayName: '% Planned Maintenance',
				loading: plannedMaintenanceRateTotal.loading,
				id: 'plannedMaintenancePercentage',
				color: 'standard',
				currentMonthValue:
					plannedMaintenanceRateTotal.data.length > 0
						? floatToPercentageString(
								nullSafeGet(
									'plannedMaintenancePercentage',
									plannedMaintenanceRateTotal.data[plannedMaintenanceRateTotal.data.length - 1]
								)
						  )
						: null,
				percentChange: percentageDifference(
					nullSafeGet(
						'plannedMaintenancePercentage',
						plannedMaintenanceRateTotal.data[plannedMaintenanceRateTotal.data.length - 1]
					),
					nullSafeGet(
						'plannedMaintenancePercentage',
						plannedMaintenanceRateTotal.data[plannedMaintenanceRateTotal.data.length - 2]
					)
				),
				// last6Months: [{x: '2019-5-01', y: 0.5}, {x: '2019-6-01', y: 0.4}, {
				//     x: '2019-7-01',
				//     y: 0.3
				// }, {x: '2019-8-01', y: 0.25}, {x: '2019-9-01', y: 0.5}, {x: '2019-10-01', y: 0.45}]
			},
			{
				displayName: '% Corrective Maintenance',
				loading: correctiveMaintenanceRateTotal.loading,
				id: 'correctiveMaintenancePercentage',
				color: 'standard',
				currentMonthValue:
					correctiveMaintenanceRateTotal.data.length > 0
						? floatToPercentageString(
								nullSafeGet(
									'correctiveMaintenancePercentage',
									correctiveMaintenanceRateTotal.data[
										correctiveMaintenanceRateTotal.data.length - 1
									]
								)
						  )
						: null,
				percentChange: percentageDifference(
					nullSafeGet(
						'correctiveMaintenancePercentage',
						correctiveMaintenanceRateTotal.data[correctiveMaintenanceRateTotal.data.length - 1]
					),
					nullSafeGet(
						'correctiveMaintenancePercentage',
						correctiveMaintenanceRateTotal.data[correctiveMaintenanceRateTotal.data.length - 2]
					)
				),
				// last6Months: [{x: '2019-5-01', y: 0.5}, {x: '2019-6-01', y: 0.4}, {
				//     x: '2019-7-01',
				//     y: 0.3
				// }, {x: '2019-8-01', y: 0.25}, {x: '2019-9-01', y: 0.5}, {x: '2019-10-01', y: 0.45}]
			},
			{
				displayName: 'Checked In Rate',
				loading: checkInRateTotal.loading,
				id: 'checkInCheckOut',
				color: 'standard',
				currentMonthValue:
					checkInRateTotal.data.length > 0
						? floatToPercentageString(
								nullSafeGet('checkInRate', checkInRateTotal.data[checkInRateTotal.data.length - 1])
						  )
						: null,
				percentChange: percentageDifference(
					nullSafeGet('checkInRate', checkInRateTotal.data[checkInRateTotal.data.length - 1]),
					nullSafeGet('checkInRate', checkInRateTotal.data[checkInRateTotal.data.length - 2])
				),
				// last6Months: [{x: '2019-5-01', y: 0.5}, {x: '2019-6-01', y: 0.4}, {
				//     x: '2019-7-01',
				//     y: 0.3
				// }, {x: '2019-8-01', y: 0.25}, {x: '2019-9-01', y: 0.5}, {x: '2019-10-01', y: 0.45}]
			},
			{
				displayName: 'First Time Resolution Rate',
				loading: firstTimeRateTotal.loading,
				id: 'firstTimeFix',
				color: 'standard',
				currentMonthValue:
					firstTimeRateTotal.data.length > 0
						? floatToPercentageString(
								nullSafeGet(
									'firstTimeResolutionRate',
									firstTimeRateTotal.data[firstTimeRateTotal.data.length - 1]
								)
						  )
						: null,
				percentChange: percentageDifference(
					nullSafeGet(
						'firstTimeResolutionRate',
						firstTimeRateTotal.data[firstTimeRateTotal.data.length - 1]
					),
					nullSafeGet(
						'firstTimeResolutionRate',
						firstTimeRateTotal.data[firstTimeRateTotal.data.length - 2]
					)
				),
				// last6Months: [{x: '2019-5-01', y: 0.5}, {x: '2019-6-01', y: 0.4}, {
				//     x: '2019-7-01',
				//     y: 0.3
				// }, {x: '2019-8-01', y: 0.25}, {x: '2019-9-01', y: 0.5}, {x: '2019-10-01', y: 0.45}]
			},
			{
				displayName: 'Average Downtime',
				loading: assetDownTimeTotal.loading,
				id: 'downtime',
				color: 'reverse',
				currentMonthValue:
					assetDownTimeTotal.data.length > 0
						? nullSafeGetOrElse(
								'averageDownTimeAsString',
								assetDownTimeTotal.data[assetDownTimeTotal.data.length - 1],
								'--'
						  )
						: null,
				percentChange: percentageDifference(
					nullSafeGet(
						'averageDownTime',
						assetDownTimeTotal.data[assetDownTimeTotal.data.length - 1]
					),
					nullSafeGet(
						'averageDownTime',
						assetDownTimeTotal.data[assetDownTimeTotal.data.length - 2]
					)
				),
				// last6Months: [{x: '2019-5-01', y: 0.5}, {x: '2019-6-01', y: 0.4}, {
				//     x: '2019-7-01',
				//     y: 0.3
				// }, {x: '2019-8-01', y: 0.25}, {x: '2019-9-01', y: 0.5}, {x: '2019-10-01', y: 0.45}]
			},
			{
				displayName: 'Average Response Time',
				loading: responseTimeTotal.loading,
				id: 'responseTime',
				color: 'reverse',
				currentMonthValue:
					responseTimeTotal.data.length > 0
						? nullSafeGetOrElse(
								'averageResponseTimeAsString',
								responseTimeTotal.data[responseTimeTotal.data.length - 1],
								'--'
						  )
						: null,
				percentChange: percentageDifference(
					nullSafeGet(
						'averageResponseTime',
						responseTimeTotal.data[responseTimeTotal.data.length - 1]
					),
					nullSafeGet(
						'averageResponseTime',
						responseTimeTotal.data[responseTimeTotal.data.length - 2]
					)
				),
			},
			{
				displayName: 'Average Completion Time',
				loading: completionTimeTotal.loading,
				id: 'completionTime',
				color: 'reverse',
				currentMonthValue:
					completionTimeTotal.data.length > 0
						? nullSafeGetOrElse(
								'averageCompletionTimeAsString',
								completionTimeTotal.data[completionTimeTotal.data.length - 1],
								'--'
						  )
						: null,
				percentChange: percentageDifference(
					nullSafeGet(
						'averageCompletionTime',
						completionTimeTotal.data[completionTimeTotal.data.length - 1]
					),
					nullSafeGet(
						'averageCompletionTime',
						completionTimeTotal.data[completionTimeTotal.data.length - 2]
					)
				),
			},
			{
				displayName: 'Customer Satisfaction',
				loading: avgSatisfactionRatingTotal.loading,
				id: 'customerSatisfaction',
				currentMonthValue:
					avgSatisfactionRatingTotal.data.length > 0
						? nullSafeGetOrElse(
								'averageSatisfactionRatings',
								avgSatisfactionRatingTotal.data[avgSatisfactionRatingTotal.data.length - 1],
								'--'
						  )
						: null,
				percentChange: percentageDifference(
					nullSafeGet(
						'averageSatisfactionRatings',
						avgSatisfactionRatingTotal.data[avgSatisfactionRatingTotal.data.length - 1]
					),
					nullSafeGet(
						'averageSatisfactionRatings',
						avgSatisfactionRatingTotal.data[avgSatisfactionRatingTotal.data.length - 2]
					)
				),
			},
		];

		const getTrendIndicator = (percentageChange) => {
			return percentageChange >= 0 ? (
				<i className="icons8-font icons8-up"></i>
			) : (
				<i className="icons8-font icons8-down-arrow"></i>
			);
		};

		const defaultValue = [moment(startDate), moment(endDate)];
		return (
			<Content className="supplierFieldTechsAnalyticsDetailPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited supplier field tech analytics detail page" />
				<div
					style={{
						margin: '0 -8px',
						padding: '24px',
						paddingBottom: 0,
						background: nullSafeGet('detail.config.theme.navblock', companyConfig),
						marginBottom: 8,
					}}
				>
					<h4
						style={{
							color: 'rgba(255, 255, 255, 0.9)',
							marginRight: 8,
							paddingBottom: 24,
						}}
					>
						{fieldTech.name}
					</h4>
				</div>

				<Row key={1} style={{ margin: '0.5em 0' }} gutter={16}>
					<Col style={{ flex: '1' }} className="controlsBar__leftGroup">
						<h5 style={{ marginLeft: 0, marginBottom: 0 }}>Scorecard</h5>
					</Col>
					<Col style={{ flex: '4', textAlign: 'right' }} className="controlsBar__rightGroup">
						<Radio.Group
							className="inline-block-visible-md"
							onChange={(e) => {
								this.changeSupplierInitiatedFilter(nullSafeGet('target.value', e));
							}}
							value={nullSafeGet(`techKpiFilters.isSupplierInitiated`, techKpiAnalytics)}
							style={{ marginLeft: 12 }}
						>
							<Radio.Button value={undefined} style={{ height: 40, lineHeight: '40px' }}>
								All
							</Radio.Button>
							<Radio.Button style={{ height: 40, lineHeight: '40px' }} value="true">
								Tech Initiated
							</Radio.Button>
							<Radio.Button style={{ height: 40, lineHeight: '40px' }} value="false">
								Staff Initiated
							</Radio.Button>
						</Radio.Group>
						<Radio.Group
							className="inline-block-visible-md"
							onChange={(e) => {
								this.changePMFilter(nullSafeGet('target.value', e));
							}}
							value={nullSafeGet(`techKpiFilters.isPM`, techKpiAnalytics)}
							style={{ marginLeft: 12 }}
						>
							<Radio.Button value={undefined} style={{ height: 40, lineHeight: '40px' }}>
								All
							</Radio.Button>
							<Radio.Button style={{ height: 40, lineHeight: '40px' }} value="true">
								Planned
							</Radio.Button>
							<Radio.Button style={{ height: 40, lineHeight: '40px' }} value="false">
								Reactive
							</Radio.Button>
						</Radio.Group>
					</Col>
				</Row>
				<div className="flex flex-row justify-between">
					<div style={{ minWidth: 240, marginLeft: 8 }}>
						<SupplierRegionsLocationsTreeSelect
							mode="multiple"
							filtersSlice={locations}
							placeholder={'Filter by Location'}
							onChange={this.changeLocationFilter}
							filtersValueAccessor={() => this.state.locationIds}
							targetCollectionName={'locationsIndex'}
						/>
					</div>
					<div style={{ marginRight: 8 }}>
						<DatePicker.RangePicker
							style={{ marginLeft: 16, minWidth: 240 }}
							// @ts-ignore
							defaultValue={defaultValue}
							key={startDate && endDate ? startDate + endDate : undefined}
							format={RANGE_PICKER_DATE_FORMAT}
							showTime={false}
							onChange={this.changeDates}
						/>
					</div>
				</div>
				<Row key={3} style={{ margin: '0.5em 0' }} gutter={16}>
					{fieldTechKPIs.map((kpi, idx) => (
						<Col key={kpi.id} span={6} style={{ marginBottom: 16 }}>
							<Card style={{ height: '100%' }}>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'flex-start',
										marginBottom: 8,
									}}
								>
									<Spin spinning={kpi.loading || initialLoading}>
										<div className="analyticsCardSubtitle" style={{ flex: 1, marginRight: 16 }}>
											{kpi.displayName}
										</div>
									</Spin>
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'flex-end',
										marginBottom: 24,
									}}
								>
									<div style={{ lineHeight: 1, fontSize: '24px' }}>{kpi.currentMonthValue}</div>
								</div>
								<div>
									{kpi.percentChange !== undefined && kpi.percentChange !== null ? (
										<div
											style={{
												fontSize: 16,
												fontWeight: 300,
											}}
										>
											<span
												style={{
													color:
														kpi.color === 'reverse'
															? getReverseKPIColor(kpi.percentChange, 0)
															: getKPIColor(kpi.percentChange, 0),
												}}
											>
												{getTrendIndicator(kpi.percentChange)}
												{floatToPercentageString(Math.abs(kpi.percentChange), 0)}
											</span>
											<span
												style={{
													fontSize: 14,
													color: 'rgba(0, 0, 0, 0.45)',
												}}
											>
												{` over `}
												{moment
													.duration(moment(this.state.endDate).diff(moment(this.state.startDate)))
													.humanize()}
											</span>
										</div>
									) : null}
								</div>
							</Card>
						</Col>
					))}
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	fieldTechsFetching: state.field_techs.fetching,
	fieldTech: state.field_techs.detail,
	companyConfig: state.company_config,
	match: ownProps.match,
	history: ownProps.history,
	techKpiAnalytics: state.tech_kpi_analytics,
	locations: state.locations,
});

const mapDispatchToProps = (dispatch) => ({
	fetchFieldTech: (id) => dispatch(fieldTechsRestCrudThunksForSupplier.readOne(id)),
	saveKPIFilters: (filters) => dispatch(updateTechKPIFilters(filters)),
	clearKPIFilters: () => dispatch(clearTechKPIFilters()),
	getNewWorkOrdersKPITotal: (params) => dispatch(fetchNewWorkOrdersTechKPITotal(params)),
	getNewCreatedByTechWorkOrdersKPITotal: (params) =>
		dispatch(fetchNewCreatedByTechWorkOrdersTechKPITotal(params)),
	getPMWorkOrdersKPITotal: (params) => dispatch(fetchPMWorkOrdersTechKPITotal(params)),
	getCompletedWorkOrdersKPITotal: (params) =>
		dispatch(fetchCompletedWorkOrdersTechKPITotal(params)),
	getPMCompletedWorkOrdersKPITotal: (params) =>
		dispatch(fetchPMCompletedWorkOrdersTechKPITotal(params)),
	getStaleWorkOrdersKPITotal: (params) => dispatch(fetchStaleWorkOrdersTechKPITotal(params)),
	getPlannedMaintenanceRateKPITotal: (params) =>
		dispatch(fetchPlannedMaintenanceRateTechKPITotal(params)),
	getCorrectiveMaintenanceRateKPITotal: (params) =>
		dispatch(fetchCorrectiveMaintenanceRateTechKPITotal(params)),
	getCheckInRateKPITotal: (params) => dispatch(fetchCheckInRateTechKPITotal(params)),
	getFirstTimeResolutionKPITotal: (params) =>
		dispatch(fetchFirstTimeResolutionTechKPITotal(params)),
	getAssetDownTimeKPITotal: (params) => dispatch(fetchAssetDownTimeTechKPITotal(params)),
	getResponseTimeKPITotal: (params) => dispatch(fetchResponseTimeTechKPITotal(params)),
	getCompletionTimeKPITotal: (params) => dispatch(fetchCompletionTimeTechKPITotal(params)),
	getAverageSatisfactionRating: (params) =>
		dispatch(fetchAverageSatisfactionRatingKPITotal(params)),
	getKPIs: (params, targetCollectionName) =>
		dispatch(fetchAllTechKPITotals(params, targetCollectionName)),
	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			locationsRestCrudThunksForSupplier.read(
				params,
				targetCollectionName,
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchMultipleLocations: (ids, targetCollectionName) =>
		dispatch(locationsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(KPITechAnalyticsDetailPage)
);

import { Card } from 'antd';
import React, { FC, useMemo } from 'react';
import { ContactAvatar } from '../contact_avatar/ContactAvatar';
import { connect } from 'react-redux';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { ROLE_TYPES } from '../../utils/DataConstants';

require('./WorkOrdersDetailDetailsPage.less');

const getFacilityInchargeContactsForWOLocation = (workOrder) =>
	nullSafeGet('location.facilityInChargeContacts.0', workOrder)
		? nullSafeGet('location.facilityInChargeContacts', workOrder)
		: nullSafeGet('location.facilityInChargeEmails.0', workOrder)
		? nullSafeGetOrElse('location.facilityInChargeEmails', workOrder, []).map((email) => ({
				email,
		  }))
		: [];

export const getAllInternalContactsForWO = (workOrder) => {
	const facilityInchargeContacts = getFacilityInchargeContactsForWOLocation(workOrder);
	return nullSafeGet('isPM', workOrder) && facilityInchargeContacts.length > 0
		? facilityInchargeContacts
		: nullSafeGet('createdByContact', workOrder)
		? [nullSafeGet('createdByContact', workOrder)]
		: [];
};

const WorkOrderInternalContact: FC<any> = ({ userType, workOrder }): React.ReactElement => {
	const allContacts = useMemo(() => getAllInternalContactsForWO(workOrder), [workOrder]);

	const isSupplier = useMemo(() => userType === ROLE_TYPES.SUPPLIER, [userType]);

	return allContacts.map((contact, idx) => (
		<div key={`contact-${idx}`} className="mb-2">
			<Card title={isSupplier ? 'Customer Contact' : 'Internal Contact'}>
				{nullSafeGet('nameGiven', contact) || nullSafeGet('nameFamily', contact) ? (
					<div className="materialCard__flexCentVert" style={{ marginBottom: 24 }}>
						<ContactAvatar
							size="large"
							hidePopover={true}
							style={{ marginRight: 16 }}
							contact={contact}
							userType={userType}
						/>
						<div>
							<div className="materialCard__title">
								{nullSafeGetOrElse(
									'submittedByName',
									workOrder,
									`${nullSafeGetOrElse('nameGiven', contact, '')} ${nullSafeGetOrElse(
										'nameFamily',
										contact,
										''
									)}`
								)}
							</div>
							<div className="materialCard__subtitle">
								{nullSafeGet('title', contact)} {nullSafeGet('department', contact)}
							</div>
						</div>
					</div>
				) : null}
				{nullSafeGet('phoneWork', contact) && (
					<div
						className="materialCard__supportingText materialCard__flexCentVert"
						style={{ marginBottom: 8 }}
					>
						<div className="materialCard__iconLabel">
							<i style={{ fontSize: 24 }} className={`icons8-font icons8-phone`} />
						</div>
						<div>{nullSafeGet('phoneWork', contact)}</div>
					</div>
				)}
				{nullSafeGet('phoneMobile', contact) && (
					<div
						className="materialCard__supportingText materialCard__flexCentVert"
						style={{ marginBottom: 8 }}
					>
						<div className="materialCard__iconLabel">
							<i style={{ fontSize: 24 }} className={`icons8-font icons8-cell-phone`} />
						</div>
						<div>{nullSafeGet('phoneMobile', contact)}</div>
					</div>
				)}
				{nullSafeGet('email', contact) && (
					<div
						className="materialCard__supportingText materialCard__flexCentVert"
						style={{ marginBottom: 8 }}
					>
						<div className="materialCard__iconLabel">
							<i style={{ fontSize: 24 }} className={`icons8-font icons8-secured-letter-filled`} />
						</div>
						<div>{nullSafeGet('email', contact)}</div>
					</div>
				)}
			</Card>
		</div>
	));
};

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(WorkOrderInternalContact);

import { Button } from 'antd';
import React, { FC, useCallback, useMemo, useRef } from 'react';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FlatfileImporter } from '@flatfile/react';
import { FLAT_FILE_LICENSE_KEY } from '../../utils/DataConstants';
import { UploadOutlined } from '@ant-design/icons';
import CSVReader from 'react-csv-reader';

const FlatfileUploader: FC<any> = ({ currentUser, onDataUpload, loadingMessage }) => {
	const hiddenFileInput = useRef(null);

	const flateFileHeaders: any[] = useMemo(
		() => [
			{
				label: 'Asset ID',
				key: 'assetId',
				alternates: ['Asset ID'],
				validators: [
					{ validate: 'required' },
					{
						validate: 'regex_matches',
						regex: `d*`,
					},
				],
			},
			{
				label: 'Impairment Adjustment',
				key: 'impairmentAdjustment',
				alternates: ['Impairment Adjustment'],
				validators: [
					{ validate: 'required' },
					{
						validate: 'regex_matches',
						regex: `d*`,
					},
				],
			},
			{
				label: 'Date (MM/DD/YYYY)',
				key: 'date',
				alternates: ['Date (MM/DD/YYY)'],
				validators: [
					{ validate: 'required' },
					{
						validate: 'regex_matches',
						regex: `[0-9]{1,2}/[0-9]{1,2}/[0-9]{2,4}`,
					},
				],
			},
		],
		[]
	);

	const importer = useMemo(
		() =>
			new FlatfileImporter(FLAT_FILE_LICENSE_KEY, {
				type: 'Import type',
				fields: flateFileHeaders,
			}),
		[flateFileHeaders]
	);

	importer.setCustomer({
		companyId: nullSafeGet('facility.buyerCompanyId', currentUser),
		companyName: nullSafeGet('facility.name', currentUser),
		userId: currentUser.email,
	});

	const getArrayValue = useCallback(
		(arr, index) => (nullSafeGet('length', arr) > index ? arr[index] : undefined),
		[]
	);

	const papaparseOptions = {
		dynamicTyping: true,
		skipEmptyLines: true,
	};

	const onFileLoaded = useCallback(
		(data, fileInfo, originalFile) => {
			importer
				.requestDataFromUser({
					source: data
						.map((_) => ({
							data: {
								assetId: getArrayValue(_, 0),
								impairmentAdjustment: getArrayValue(_, 1),
								date: getArrayValue(_, 2),
							},
						}))
						.slice(1),
				})
				.then((results) => {
					onDataUpload(results.validData, fileInfo, originalFile)
						.then((message) => importer.displaySuccess(message))
						.catch((err) => importer.displayError(err));
					importer.displayLoader(loadingMessage);
				});
			return false;
		},
		[getArrayValue, importer, loadingMessage, onDataUpload]
	);

	const handleClick = useCallback(() => hiddenFileInput.current.click(), []);

	return (
		<>
			<Button size="large" type="primary" onClick={handleClick}>
				<UploadOutlined translate="" />
				Upload
			</Button>
			<CSVReader
				parserOptions={papaparseOptions}
				onFileLoaded={onFileLoaded}
				ref={hiddenFileInput}
				inputStyle={{ display: 'none' }}
			/>
		</>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
});

export default withRouter<any, any>(connect(mapStateToProps)(FlatfileUploader));

import React, { FC, useCallback, useMemo } from 'react';
import { Tag, Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

interface SavedViewRowDisplayProps {
	record: any;
	isAdmin: Boolean;
	onOpenView: (view) => void;
	onDelete: (record) => void;
}

const SavedFilterViewsRowDisplay: FC<SavedViewRowDisplayProps> = ({
	record,
	isAdmin,
	onOpenView,
	onDelete,
}): React.ReactElement => {
	const applyFilter = useCallback(() => {
		record && onOpenView && onOpenView(record);
	}, [record, onOpenView]);

	const isDeletetableView = useMemo(() => !record.isPublic || isAdmin, [isAdmin, record]);

	const onConfirmDelete = useCallback(() => {
		onDelete && onDelete(record);
	}, [record, onDelete]);

	return (
		<>
			<div
				style={{
					maxWidth: '80%',
					display: 'flex',
					flexDirection: 'row',
				}}
			>
				<span
					style={{
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
				>
					{record.name}
				</span>
				{record.isPublic && <Tag style={{ marginLeft: 8 }}>Public</Tag>}
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
				}}
			>
				<Button onClick={applyFilter}>Open</Button>
				{isDeletetableView ? (
					<Popconfirm
						onConfirm={onConfirmDelete}
						title="Are you sure you want to delete this view?"
						okText="Yes"
						cancelText="No"
					>
						<DeleteOutlined style={{ marginLeft: 8 }} translate="" />
					</Popconfirm>
				) : (
					<div style={{ width: 22 }} />
				)}
			</div>
		</>
	);
};

export default SavedFilterViewsRowDisplay;

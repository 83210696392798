import {
	checkHasApprovalHierarchy,
	compareStrings,
	getCurrency,
	nullSafeGet,
	nullSafeGetOrElse,
} from '../../utils/DataAccessUtils';
import { Button, message, Popover } from 'antd';
import moment from 'moment';
import * as React from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import ApprovalHierarchyDisplayInvoiceModal from './ApprovalHierarchyDisplayInvoiceModal';
import DeclineInvoiceForm from '../decline_invoice_form/DeclineInvoiceForm';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { UndoOutlined } from '@ant-design/icons';
import { ROLE_TYPES } from '../../utils/DataConstants';
import {
	isAllowedToPerform,
	isSupplierAllowedToAccess,
	PERMISSION_NAMES,
} from '../../utils/AuthUtils';
import ForwardApproval from '../forward_approval/ForwardApproval';
import {
	forwardApprovalToUserForBuyer,
	forwardApprovalToUserForSupplier,
} from '../../thunks/company_approval_thunks';
import _ from 'lodash';

function ApprovalHierarchyDisplayInvoice({
	workOrder,
	invoice,
	currentUser,
	approveInvoice,
	declineInvoice,
	syncInvoice,
	markInvoicePaid,
	markInvoiceProcessing,
	markInvoicePending,
	appendWorkOrderNotes,
	refresh,
	companyConfig,
	style = {},
	allowedToApprove = true,
	userType,

	forwardApprovalToUser,
}) {
	const INVOICE_STATUSES = {
		APPROVED: 'approved',
		PAID: 'paid',
		PROCESSING: 'processing',
		PENDING: 'pending',
		DISPUTED: 'disputed',
	};
	const defaultActionsAllowed =
		userType === ROLE_TYPES.SUPPLIER
			? isSupplierAllowedToAccess(
					PERMISSION_NAMES.CAN_APPROVE_INVOICES,
					companyConfig,
					currentUser.roles
			  )
			: isAllowedToPerform('canApproveInvoices', companyConfig, currentUser.roles);
	const [showModal, setShowModal] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [showDeclineModal, setShowDeclineModal] = useState(false);
	const status = nullSafeGet('status', invoice);
	const reason = useRef();
	const setReason = (str) => (reason.current = str);
	const showStatus = (status) => {
		switch (status) {
			case 'pending':
				return 'Pending Approvers';
			case 'disputed':
				return 'Approvers/Disputers';
			case 'approved':
				return 'Approvers';
			case 'voided':
				return 'Approver List';
			default:
				return 'Approver List';
		}
	};
	const hasApprovalHierarchy = checkHasApprovalHierarchy(invoice);
	const hasApprovers = (tiers) =>
		tiers.flatMap((tier) => tier.approvers).some((approvers) => approvers.length > 0);
	const userTier = (tiers) =>
		hasApprovalHierarchy
			? tiers.filter((tier) =>
					tier.approvers.some((approver) => compareStrings(approver.email, currentUser.email))
			  ).length > 0
				? tiers.find((tier) =>
						tier.approvers.some((approver) => compareStrings(approver.email, currentUser.email))
				  )
				: undefined
			: tiers[0];

	const currency = getCurrency({ workOrder: nullSafeGet('workOrder', invoice) });
	const buyerCompanyCurrency = getCurrency({
		buyerCompany: nullSafeGet('workOrder.buyerCompany', invoice),
	});
	const exchangeRate =
		currency.id === buyerCompanyCurrency.id
			? 1
			: nullSafeGetOrElse('workOrder.currencyExchangeRateLocationToSupplier', invoice, 1) *
			  nullSafeGetOrElse('workOrder.currencyExchangeRateBuyerToLocation', invoice, 1);
	let invoiceTotal = nullSafeGet('invoiceTotalAfterTax', invoice) / exchangeRate;
	let lessThanInvoice = true;
	let highestValidAmount = 0;
	let highestApprovedAmount = 0;
	const highestApprovalTierAmount = (tiers) => {
		let highestTiers = [...tiers]
			.filter((tier) => {
				let approvedByUsers = nullSafeGet('approvedByUsers', tier);
				let declinedByUsers = nullSafeGet('declinedByUsers', tier);
				if (approvedByUsers && declinedByUsers) {
					return approvedByUsers.concat(declinedByUsers).length > 0;
				} else {
					return false;
				}
			})
			.map((tier) => {
				return nullSafeGetOrElse('amountUpto', tier, Infinity);
			});
		if (highestTiers.length > 0) {
			return highestTiers[highestTiers.length - 1];
		}
		return 0;
	};

	let lastAmount = 0;
	const tiers = nullSafeGet('approvalHierarchy.tierDetails', invoice)
		? nullSafeGet('approvalHierarchy.tierDetails', invoice)
				//Sort tiers primarily by tier.amountUpto, secondarily by tier.label
				.sort((a, b) =>
					a.amountUpto && b.amountUpto
						? parseFloat(a.amountUpto) > parseFloat(b.amountUpto)
							? 1
							: parseInt(a.label) > parseInt(b.label)
							? 1
							: -1
						: a.amountUpto === undefined
						? 1
						: -1
				)
				//Setup tier objects
				.map((tier) => {
					if (nullSafeGetOrElse('approvedByUsers', tier, []).length > 0) {
						highestApprovedAmount = parseInt(nullSafeGetOrElse('amountUpto', tier, Infinity));
					}
					return {
						...tier,
						approvedByUsers: nullSafeGetOrElse('approvedByUsers', tier, []).filter(
							(user, i, users) => i === users.findIndex((u) => compareStrings(u.email, user.email))
						),
						declinedByUsers: nullSafeGetOrElse('declinedByUsers', tier, []).filter(
							(user, i, users) => i === users.findIndex((u) => compareStrings(u.email, user.email))
						),
					};
				})
				.map((tier, i, tiers) => {
					let message;
					let isVisible = () => {
						//We also want to display tiers which fit one of the following criteria:
						let amount = parseInt(nullSafeGet('amountUpto', tier));

						//1.This tier has either approvers or decliners and is >= the highest amount approved
						if (
							highestApprovedAmount > amount &&
							tier.approvedByUsers.length === 0 &&
							tier.declinedByUsers.length === 0
						) {
							//Not shown because a higher tier already approved
							message = 'A higher tier has already approved';
							return false;
						}
						if (
							highestApprovedAmount === amount &&
							tier.approvers.length === 0 &&
							tier.approvedByUsers.length === 0
						) {
							//Not shown because an equal tier has already approved and this tier has no approvers
							message =
								'An equal tier has approved and this tier has no approvers for this location';
							return false;
						}
						if (!lessThanInvoice) {
							let lastAmount = parseInt(nullSafeGetOrElse('amountUpto', tiers[i - 1], 0));
							//3. This tier has already been approved or declined
							if (tier.approvedByUsers.length > 0 || tier.declinedByUsers.length > 0) {
								return true;
							} else {
								//4. This tier is the same amount as the previous tier
								if (highestValidAmount === amount && highestValidAmount === lastAmount) {
									return true;
								} else {
									message = `Approval from this tier isn't required for invoices less than ${currency.format(
										(lastAmount ? lastAmount : amount) * exchangeRate
									)}`;
									return false;
								}
							}
						}

						if (amount && invoiceTotal) {
							if (parseFloat(amount) > parseFloat(invoiceTotal)) {
								//2. This tier is one tier over the invoice and contains potential approvers
								highestValidAmount = amount;
								if (nullSafeGetOrElse('approvers.length', tier, 0) > 0) {
									lessThanInvoice = false;
								}
							}
						} else {
							//1. This tier is less than the invoice
							lessThanInvoice = false;
						}
						return true;
					};
					lastAmount =
						nullSafeGetOrElse('amountUpto', tiers[i - 1], 0) === nullSafeGet('amountUpto', tier)
							? lastAmount
							: nullSafeGetOrElse('amountUpto', tiers[i - 1], 0);
					return { ...tier, isVisible: isVisible(), message, lastAmount };
				})
		: [];
	const highestAmount = highestApprovalTierAmount(tiers);
	let myTier = userTier(tiers);
	const myTierAmount = nullSafeGet('amountUpTo', myTier);
	const currentInvoice = {
		...invoice,
		approvalHierarchy: { ...invoice.approvalHierarchy, tierDetails: tiers },
		highestAmount: highestAmount,
	};
	const showApproval = [INVOICE_STATUSES.PENDING, INVOICE_STATUSES.DISPUTED].some(
		(status) => status === invoice.status
	);
	const showBackToApproved = [INVOICE_STATUSES.PROCESSING].some(
		(status) => status === invoice.status
	);
	const allowApproval = useMemo(() => {
		if (status !== 'approved') {
			if (hasApprovalHierarchy && myTier !== undefined) {
				//Has approval Hierarchy and a tier
				if (myTierAmount === undefined || parseFloat(myTierAmount) >= highestAmount) {
					if (
						!nullSafeGetOrElse('approvedByUsers', myTier, []).some((user) =>
							compareStrings(currentUser.email, user.email)
						)
					) {
						return true;
					} else {
						setReason('Your tier has already approved.');
						return false;
					}
				}
			} else if (!hasApprovalHierarchy && myTier !== undefined) {
				//No approval Hierarchy
				if (
					nullSafeGetOrElse('approvedByUsers', myTier, []).some((user) =>
						compareStrings(currentUser.email, user.email)
					)
				) {
					setReason('You have already approved.');
					return false;
				} else if (nullSafeGetOrElse('approvedByUsers', myTier, []).length > 1) {
					setReason('Someone has already approved.');
					return false;
				} else {
					setReason(`You are not allowed to approve/dispute invoice.`);
					return defaultActionsAllowed;
				}
			} else if (!hasApprovalHierarchy && myTier === undefined) {
				setReason(`You are not allowed to approve/dispute invoice.`);
				return defaultActionsAllowed;
			}
			setReason('You are not in the approval hierarchy.');
		} else {
			setReason('This invoice has already been approved.');
		}
		return false;
	}, []);
	const showDecline = [INVOICE_STATUSES.PENDING].some((status) => status === invoice.status);
	const allowDecline = useMemo(() => {
		if (status !== 'approved' && status !== 'disputed') {
			if (hasApprovalHierarchy && myTier) {
				//Has an approval hierarchy and a tier
				if (myTierAmount === undefined || parseFloat(myTierAmount) >= highestAmount) {
					if (
						!nullSafeGetOrElse('approvedByUsers', myTier, []).some((user) =>
							compareStrings(currentUser.email, user.email)
						)
					) {
						return true;
					} else {
						setReason('Your tier has already approved.');
						return false;
					}
				}
			} else if (!hasApprovalHierarchy && myTier !== undefined) {
				//No approval Hierarchy
				if (
					nullSafeGetOrElse('approvedByUsers', myTier, []).some((user) =>
						compareStrings(currentUser.email, user.email)
					)
				) {
					setReason('You have already approved.');
					return false;
				} else if (nullSafeGetOrElse('approvedByUsers', myTier, []).length > 1) {
					setReason('Someone has already approved.');
					return false;
				} else {
					setReason(`You are not allowed to approve/dispute invoice.`);
					return defaultActionsAllowed;
				}
			} else if (!hasApprovalHierarchy && myTier === undefined) {
				setReason(`You are not allowed to approve/dispute invoice.`);
				return defaultActionsAllowed;
			}
		}
		if (status === 'disputed') {
			setReason('This invoice has already been disputed.');
		}
		return false;
	}, []);

	const handleApproveInvoice = useCallback(
		(invoice) => {
			let myTier = userTier(tiers)
				? userTier(tiers)
				: {
						approvers: [],
						approvedByUsers: [],
						declinedByUsers: [],
						approvedByType: 'any',
						label: '1',
				  };

			//Add this user to approvedByUsers list
			myTier.approvedByUsers.push({
				email: currentUser.email.toLowerCase(),
				approverType: 'user',
				contact: currentUser,
			});
			let newinvoice = {
				...invoice,
				approvalHierarchy: {
					...invoice.approvalHierarchy,
					tierDetails: invoice.approvalHierarchy.tierDetails.map((tier) => {
						return tier.id === myTier.id ? { ...myTier } : tier;
					}),
				},
			};
			setButtonLoading(true);
			message.open({
				key: 'invoiceDetailMessageUpdating',
				type: 'loading',
				content: 'Approving...',
				duration: 60,
			});
			approveInvoice(newinvoice).then((resultInvoice) => {
				setButtonLoading(false);
				message.destroy('invoiceDetailMessageUpdating');
				message.success('Approved!');
				refresh();
			});
		},
		[invoice, approveInvoice]
	);
	const showPaid = [INVOICE_STATUSES.PROCESSING, INVOICE_STATUSES.APPROVED].some(
		(s) => s === status
	);
	const handlePaidInvoice = useCallback(() => {
		setButtonLoading(true);
		message.open({
			key: 'invoiceDetailMessageUpdating',
			type: 'loading',
			content: 'Marking invoice as paid...',
			duration: 60,
		});
		markInvoicePaid(invoice.id).then((resultInvoice) => {
			setButtonLoading(false);
			message.destroy('invoiceDetailMessageUpdating');
			message.success(`Invoice paid.`);
			refresh();
		});
	}, [invoice, markInvoicePaid]);
	const showProcessing = [INVOICE_STATUSES.APPROVED].some((s) => s === status);
	const showBackToProcessing = [INVOICE_STATUSES.PAID].some((s) => s === status);
	const handleProcessingInvoice = useCallback(() => {
		setButtonLoading(true);
		message.open({
			key: 'invoiceDetailMessageUpdating',
			type: 'loading',
			content: 'Marking invoice as processing...',
			duration: 60,
		});
		markInvoiceProcessing(invoice.id).then(() => {
			setButtonLoading(false);
			message.destroy('invoiceDetailMessageUpdating');
			message.success(`Invoice is being processed.`);
			refresh();
		});
	}, [invoice, markInvoiceProcessing]);
	const showBackToPending = [INVOICE_STATUSES.APPROVED].some((s) => s === status);
	const handlePendingInvoice = useCallback(() => {
		setButtonLoading(true);
		message.open({
			key: 'invoiceDetailMessageUpdating',
			type: 'loading',
			content: 'Marking invoice as pending...',
			duration: 60,
		});
		markInvoicePending(invoice.id).then(() => {
			setButtonLoading(false);
			message.destroy('invoiceDetailMessageUpdating');
			message.success(`Invoice is pending.`);
			refresh();
		});
	}, [invoice, markInvoicePending]);

	const handleDisputeInvoice = (invoice) => {
		setShowDeclineModal(true);
	};
	const handleSubmitDisputeInvoice = (values) => {
		let myTier = userTier(tiers)
			? userTier(tiers)
			: {
					approvers: [],
					approvedByUsers: [],
					declinedByUsers: [],
					approvedByType: 'any',
					label: '1',
			  };

		//Add this user to declining user list
		myTier.declinedByUsers.push({
			email: currentUser.email.toLowerCase(),
			approverType: 'user',
			contact: currentUser,
		});

		//If this user is currently approving, remove them from approving list
		myTier.approvedByUsers = myTier.approvedByUsers.filter((user) => {
			return user.email.toLowerCase() !== currentUser.email.toLowerCase();
		});

		let newinvoice = {
			...currentInvoice,
			approvalHierarchy: {
				...currentInvoice.approvalHierarchy,
				tierDetails: currentInvoice.approvalHierarchy.tierDetails.map((tier) => {
					return tier.id === myTier.id ? { ...myTier } : tier;
				}),
			},
		};

		setButtonLoading(true);
		declineInvoice(currentInvoice.id).then((resultInvoice) => {
			const note = {
				text: 'Invoice Disputed: '.concat(values.note),
				noteAddedBy: nullSafeGet('email', currentUser),
				noteAddedAt: moment.utc(),
			};
			const workOrderNoteDetails = {
				id: workOrder.id,
				locationId: workOrder.locationId,
				note,
			};

			appendWorkOrderNotes(workOrderNoteDetails).then(() => {
				message.success(`Invoice disputed.`);
				setShowDeclineModal(false);
				setButtonLoading(false);
				refresh();
			});
		});
	};

	const isInApprovalHierarchy = hasApprovalHierarchy && myTier !== undefined;
	const otherButtonsAllowed = defaultActionsAllowed || isInApprovalHierarchy;
	const allowMoveBackToPending =
		isInApprovalHierarchy || (!hasApprovalHierarchy && otherButtonsAllowed);

	const approvalForwardingEnabled =
		userType === 'supplier'
			? _.get(
					currentUser,
					'facility.privateBuyerCompanySettings.config.approvalHierarchyConfig.addUserToInvoiceApprovalHierarchyOnTheGo',
					true
			  )
			: _.get(
					companyConfig,
					'config.approvalHierarchyConfig.addUserToInvoiceApprovalHierarchyOnTheGo',
					true
			  );
	const showForwardForApproval =
		status === 'pending' &&
		(hasApprovalHierarchy || hasApprovers(tiers)) &&
		approvalForwardingEnabled;

	return (
		<div className={'flex-col'}>
			<div className={'flex-row'}>
				<DeclineInvoiceForm
					visible={showDeclineModal}
					onCancel={() => {
						setShowDeclineModal(false);
						setButtonLoading(false);
					}}
					onSubmit={handleSubmitDisputeInvoice}
				/>
				<ApprovalHierarchyDisplayInvoiceModal
					tiers={tiers}
					hasApprovalHierarchy={hasApprovalHierarchy}
					currentInvoice={currentInvoice}
					setVisible={setShowModal}
					visible={showModal}
					style={style}
					reason={reason}
					allowApproval={allowApproval}
					exchangeRate={exchangeRate}
					currency={currency}
					currentUser={currentUser}
					syncInvoice={syncInvoice}
					refresh={refresh}
				/>
				<h5 style={{ display: 'flex' }}>
					{currentInvoice && currentInvoice.status && otherButtonsAllowed
						? compareStrings(currentInvoice.status, 'pending')
							? 'This invoice is on hold until it has been fully approved'
							: compareStrings(currentInvoice.status, 'approved')
							? 'Has this invoice been paid?'
							: compareStrings(currentInvoice.status, 'disputed')
							? 'This invoice has been disputed'
							: compareStrings(currentInvoice.status, 'processing')
							? 'Has this invoice been paid?'
							: compareStrings(currentInvoice.status, 'paid')
							? 'Do you want to move this back to processing?'
							: 'This invoice has been voided.'
						: null}
				</h5>
			</div>
			{allowedToApprove ? (
				<div
					style={{
						display: 'inline-flex',
						position: 'relative',
					}}
				>
					<div>
						{showApproval && currentInvoice && currentInvoice.status !== 'approved' ? (
							allowApproval ? (
								<Button
									type="primary"
									key="approve"
									style={{ marginRight: 16 }}
									loading={buttonLoading}
									onClick={() => {
										setButtonLoading(true);
										handleApproveInvoice(currentInvoice);
									}}
								>
									Approve Invoice
								</Button>
							) : (
								<Popover
									disabled={allowApproval}
									title={`Why can't I approve?`}
									content={nullSafeGet('current', reason)}
								>
									<Button
										type="primary"
										key="approve"
										style={{ marginRight: 16 }}
										loading={buttonLoading}
										disabled={true}
										onClick={() => {
											setButtonLoading(true);
											handleApproveInvoice(currentInvoice);
										}}
									>
										Approve Invoice
									</Button>
								</Popover>
							)
						) : null}
						{showDecline && currentInvoice && currentInvoice.status !== 'approved' ? (
							allowDecline ? (
								<Button
									key="reject"
									style={{ marginRight: 16 }}
									loading={buttonLoading}
									onClick={() => {
										setButtonLoading(true);
										handleDisputeInvoice(currentInvoice);
									}}
								>
									Dispute Invoice
								</Button>
							) : (
								<Popover title={`Why can't I dispute?`} content={nullSafeGet('current', reason)}>
									<Button
										key="reject"
										style={{ marginRight: 16 }}
										loading={buttonLoading}
										disabled={true}
										onClick={() => {
											setButtonLoading(true);
											handleDisputeInvoice(currentInvoice);
										}}
									>
										Dispute Invoice
									</Button>
								</Popover>
							)
						) : null}
						{showPaid && otherButtonsAllowed ? (
							<Button
								type={'primary'}
								key="paid"
								style={{ marginRight: 16 }}
								loading={buttonLoading}
								onClick={() => {
									setButtonLoading(true);
									handlePaidInvoice();
								}}
							>
								Yes, it was paid
							</Button>
						) : null}
						{showProcessing && otherButtonsAllowed ? (
							<Button
								key="Processing"
								style={{ marginRight: 16 }}
								loading={buttonLoading}
								onClick={() => {
									setButtonLoading(true);
									handleProcessingInvoice();
								}}
							>
								It's being processed
							</Button>
						) : null}
						{showBackToPending && allowMoveBackToPending ? (
							<Button
								key="approve"
								style={{ marginRight: 16 }}
								loading={buttonLoading}
								onClick={() => {
									setButtonLoading(true);
									handlePendingInvoice();
								}}
								icon={<UndoOutlined translate="" />}
							>
								Move Back to Pending
							</Button>
						) : null}
						{showBackToProcessing && otherButtonsAllowed ? (
							<Button
								key="markProcessing"
								style={{ marginRight: 16 }}
								loading={buttonLoading}
								onClick={() => {
									setButtonLoading(true);
									handleProcessingInvoice();
								}}
								icon={<UndoOutlined translate="" />}
							>
								Move Back to Processing
							</Button>
						) : null}
						{showBackToApproved && otherButtonsAllowed ? (
							<Button
								key="approved"
								style={{ marginRight: 16 }}
								loading={buttonLoading}
								onClick={() => {
									setButtonLoading(true);
									handleApproveInvoice(currentInvoice);
								}}
								icon={<UndoOutlined translate="" />}
							>
								Moved back to approved
							</Button>
						) : null}
						{showForwardForApproval ? (
							<ForwardApproval
								disabled={!(hasApprovalHierarchy && myTier !== undefined)}
								reason={reason}
								workOrder={workOrder}
								entity={invoice}
								refresh={refresh}
								forwardApprovalToUser={forwardApprovalToUser}
								buttonLoading={buttonLoading}
							/>
						) : null}
						{hasApprovalHierarchy || hasApprovers(tiers) ? (
							<div
								className={'inline-flex flex-row items-center'}
								style={{ cursor: 'pointer', fontSize: '16px' }}
								onClick={() => setShowModal(true)}
							>
								<i className="icons8-font icons8-multiple-users" />
								<span style={{ marginLeft: '8px', textDecoration: 'underline' }}>
									{showStatus(status)}
								</span>
							</div>
						) : null}
					</div>
				</div>
			) : null}
		</div>
	);
}

const mapStateToProps = (state, ownProps) => ({});
const mapDispatchToProps = (dispatch, ownProps) => ({
	forwardApprovalToUser: (entity, email) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? forwardApprovalToUserForSupplier('invoice', entity, email)
				: forwardApprovalToUserForBuyer('invoice', entity, email)
		),
});

const ComponentWithoutUserType = connect(
	mapStateToProps,
	mapDispatchToProps
)(ApprovalHierarchyDisplayInvoice);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
		companyConfig: (state as any).company_config.detail,
	}),
	() => ({})
)(ComponentWithoutUserType);

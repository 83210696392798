import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { CONTACTS_CRUD_ACTION_CREATORS } from '../actions/contacts_actions';

export const contactsRestCrudThunksForBuyer = new RestCrudThunks(
	'',
	'contact',
	'contact',
	'contacts',
	CONTACTS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.BUYER,
	false,
	'common_contacts'
);
export const contactsRestCrudThunksForSupplier = new RestCrudThunks(
	'',
	'contact',
	'contact',
	'contacts',
	CONTACTS_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'common_contacts'
);

import * as React from 'react';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Alert, message, Modal } from 'antd';
import { connect } from 'react-redux';

import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import {
	appendWorkOrderNotesForSupplier,
	getSupplierMeetingToken,
	getSupplierOWLiveShortenedLink,
	supplierMarkNotificationSeen,
} from '../../thunks/work_orders_thunks';
import moment from 'moment';
import {
	receiveLoadingMessage,
	receiveNewMessage,
	someoneStartedTyping,
	someoneStoppedTyping,
} from '../../actions/messages_actions';
import { debounce } from '../../utils/PerformanceUtils';
import { throttle } from 'lodash';
import { getBackendUri, getWSSBackendUri } from '../../utils/EnvConfigUtils';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { getFileMimeTypeFromString } from '../../utils/FileAccessUtils';
import { playNotification } from '../../actions/sound_effects_actions';
import { retrieveCachedUserDetails } from '../../thunks/session_thunks';
import { imageUploadValidation } from '../../utils/ImageUtils';
import { WORK_ORDERS_CRUD_ACTION_CREATORS } from '../../actions/work_orders_actions';
import ChatInput from '../ChatInput';
import { parseMessageToElements } from '../../utils/ChatUtils';

const FormItem = Form.Item;

interface SupplierNewWorkOrderNoteFormProps extends FormComponentProps {
	workOrder: any;
	appendWorkOrderNotes: any;
	loading: boolean;
	currentUser: any;
	history: any;
	messages: any;
	playNotificationSound: any;
	startedTyping: any;
	receiveLoadingMessage: any;
	stoppedTyping: any;
	receiveMessage: any;
	getMeetingToken: any;
	owShortenedLink: any;
	successCallback?: any;
	fetchMessages?: any;
	redirectToUrl?: string;
	errors: string[];
	markNotesSeen?: any;
	updateWOUnreadNotification?: any;
}

class SupplierNewWorkOrderNoteForm extends React.Component<SupplierNewWorkOrderNoteFormProps, any> {
	state = {
		isTyping: false,
		isTypingText: '',
		photoLink: '',
		photoPreviewModalVisible: false,
		videoLink: '',
		videoPreviewModalVisible: false,
		connected: true,
	};

	sendMessage = (JSONString, retries = 3) => {
		const { appendWorkOrderNotes, workOrder, receiveLoadingMessage } = this.props;

		const entity = JSON.parse(JSONString);

		if (nullSafeGet('noteType', entity) === 'heartbeat') {
			console.log('___ Ignoring append notes for failed sockets for hearbeat ___ ');
		} else {
			appendWorkOrderNotes({ id: workOrder.id, note: entity }).then((data) => {
				if (!entity.text) {
					receiveLoadingMessage(workOrder.id, entity);
					const element = document.getElementById('chatBox');
					if (element) {
						element.scrollTop = element.scrollHeight;
					}
				}
			});
		}
	};

	componentDidMount() {
		const { workOrder, markNotesSeen, updateWOUnreadNotification } = this.props;

		if (nullSafeGetOrElse('unreadNotesNotificationIds.length', workOrder, 0) > 0) {
			markNotesSeen(nullSafeGet('unreadNotesNotificationIds', workOrder)).then((data) => {
				updateWOUnreadNotification();
			});
		}
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const { workOrder, receiveLoadingMessage, currentUser } = this.props;
				let messageText = values.noteText;

				const { elements, text } = parseMessageToElements(messageText);
				const entity = {
					text,
					noteAddedBy: currentUser.email,
					noteAddedByContact: currentUser,
					noteAddedAt: moment(),
					elements,
				};
				receiveLoadingMessage(workOrder.id, entity);
				setTimeout(() => {
					const element = document.getElementById('chatBox');
					if (element) {
						element.scrollTop = element.scrollHeight;
					}
				}, 50);

				this.sendMessage(JSON.stringify(entity));
				this.props.form.resetFields();
			}
		});
	};

	handlePhotoUploadChange = (info) => {
		if (info.file.status === 'uploading') {
			this.setState({ photoUploadLoading: true });
			return;
		}
		if (info.file.status === 'done') {
			const photoLink = nullSafeGetOrElse('file.response.data', info, '');
			this.setState({
				photoUploadLoading: false,
				photoLink: photoLink,
				photoPreviewModalVisible: true,
			});
		}
	};

	handleVideoUploadChange = (info) => {
		if (info.file.status === 'uploading') {
			this.setState({ videoUploadLoading: true });
			return;
		}
		if (info.file.status === 'done') {
			const videoLink = nullSafeGetOrElse('file.response.data', info, '');
			this.setState({
				videoUploadLoading: false,
				videoLink: videoLink,
				videoPreviewModalVisible: true,
			});
		}
	};

	onPhotoSubmit = () => {
		const { currentUser } = this.props;
		const entity = {
			text: '',
			photo: this.state.photoLink,
			noteAddedBy: currentUser.email,
			noteAddedAt: moment(),
		};
		this.sendMessage(JSON.stringify(entity));
		this.setState({ photoLink: '', photoPreviewModalVisible: false });
	};

	onPhotoCancel = () => {
		this.setState({ photoLink: '', photoPreviewModalVisible: false });
	};

	onVideoSubmit = () => {
		const { currentUser } = this.props;
		const entity = {
			text: '',
			video: this.state.videoLink,
			noteAddedBy: currentUser.email,
			noteAddedAt: moment(),
		};
		this.sendMessage(JSON.stringify(entity));
		this.setState({ videoLink: '', videoPreviewModalVisible: false });
	};

	onVideoCancel = () => {
		this.setState({ videoLink: '', videoPreviewModalVisible: false });
	};

	handleStartVideoCall = () => {
		const { workOrder, currentUser, getMeetingToken, owShortenedLink } = this.props;
		getMeetingToken(`${nullSafeGet('buyerCompanyId', workOrder)}-${workOrder.id}`).then(
			(meetingId) => {
				const longUrl = `https://live.useopenwrench.com/?meetingid=${meetingId}`;
				owShortenedLink(longUrl).then((url) => {
					const entity = {
						text: `${currentUser.nameGiven} just went live. Join the video call with them at ${url}`,
						noteAddedBy: currentUser.email,
						noteAddedAt: moment(),
					};
					this.sendMessage(JSON.stringify(entity));
				});
			}
		);
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { errors, workOrder, messages } = this.props;
		const token = retrieveCachedUserDetails(['token']).token;

		function beforeVideoUpload(file) {
			const videoMimeTypes = [
				'video/3gpp', //.3gp
				'video/mp4', //.mp4, .m4a, .m4p, .m4b, .m4r, .m4v
				'video/mpeg', //.m1v
				'video/ogg', //.ogg
				'video/quicktime', //.mov, .qt
				'video/webm', //.webm
				'video/x-m4v', //.m4v
				'video/ms-asf', //.asf, .wma, .wmv
				'video/x-ms-wmv', //.wmv
				'video/x-msvideo', //.avi
			];
			const isVideo = videoMimeTypes.indexOf(file.type) !== -1;
			if (!isVideo) {
				message.error("This file either isn't a video or is not in a file type we support.");
			}
			return isVideo;
		}

		return (
			<Form layout="inline" onSubmit={this.handleSubmit} className="supplierNewWorkOrderNoteForm">
				{errors.length > 0
					? errors.map((error, idx) => (
							<FormItem key={idx}>
								<Alert message={error} type="error" />
							</FormItem>
					  ))
					: null}
				<Modal
					visible={this.state.photoPreviewModalVisible}
					width={600}
					title="Send this photo?"
					okText="Send"
					onCancel={this.onPhotoCancel}
					onOk={this.onPhotoSubmit}
					closable={false}
				>
					<img src={this.state.photoLink} width="100%" height="auto" />
				</Modal>
				<Modal
					visible={this.state.videoPreviewModalVisible}
					width={600}
					title="Send this video?"
					okText="Send"
					onCancel={this.onVideoCancel}
					onOk={this.onVideoSubmit}
					closable={false}
				>
					<video width="100%" height="auto" controls>
						<source
							src={this.state.videoLink}
							type={getFileMimeTypeFromString(this.state.videoLink)}
						/>
						Your browser does not support the video tag.
					</video>
				</Modal>
				<div style={{ position: 'relative' }}>
					{getFieldDecorator('noteText', {
						initialValue: '',
						rules: [{ required: true, message: "Whoops! You can't send an empty note." }],
					})(
						<ChatInput
							handlePhotoUploadChange={this.handlePhotoUploadChange}
							handleStartVideoCall={this.handleStartVideoCall}
							workOrder={workOrder}
							handleVideoUploadChange={this.handleVideoUploadChange}
							handleSubmit={this.handleSubmit}
							currentUser={this.props.currentUser}
							token={token}
						/>
					)}
				</div>
				<div
					style={{
						height: 20,
						padding: '0 12px',
						marginBottom: 4,
						display: 'flex',
						justifyContent: 'space-between',
						color: 'rgba(0,0,0,0.45)',
						fontSize: 14,
					}}
				>
					<div>
						{this.props.form.getFieldValue('noteText') &&
						this.props.form.getFieldValue('noteText').length > 0 ? (
							<span>
								Press <span style={{ textTransform: 'uppercase' }}>Enter</span> to send
							</span>
						) : null}
					</div>
				</div>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,

	isAuthenticated: state.session.isAuthenticated,
	workOrder: ownProps.workOrder,
	messages: state.messages,
	successCallback: ownProps.successCallback,
	redirectToUrl: ownProps.redirectToUrl,
	loading: state.work_orders.updating,
	currentUser: state.session.currentUser,
	errors: state.session.errors,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	appendWorkOrderNotes: (workOrder) => dispatch(appendWorkOrderNotesForSupplier(workOrder)),
	receiveMessage: (channelId, message) => dispatch(receiveNewMessage(channelId, message)),
	receiveLoadingMessage: (channelId, message) =>
		dispatch(receiveLoadingMessage(channelId, message)),
	playNotificationSound: (notification) => dispatch(playNotification(notification)),
	startedTyping: (channelId, message) => dispatch(someoneStartedTyping(channelId, message)),
	stoppedTyping: (channelId) => dispatch(someoneStoppedTyping(channelId)),
	getMeetingToken: (roomName) => dispatch(getSupplierMeetingToken(roomName)),
	owShortenedLink: (longUrl) => dispatch(getSupplierOWLiveShortenedLink(longUrl)),
	markNotesSeen: (ids) => dispatch(supplierMarkNotificationSeen(ids)),
	updateWOUnreadNotification: () =>
		dispatch(
			WORK_ORDERS_CRUD_ACTION_CREATORS.updateSuccess({
				...ownProps.workOrder,
				unreadNotesNotificationIds: [],
			})
		),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<SupplierNewWorkOrderNoteFormProps>()(SupplierNewWorkOrderNoteForm))
);

import { ASSET_TYPES_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/asset_types_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = standardInitialStateGenerator([
	'assetTypesIndex',
	'workOrdersAdvancedFilters',
	'assetsAdvancedFilters',
	'NEW_WORK_ORDER_ASSET_TYPE_FILTERS',
	'ASSET_FORM_ASSET_TYPES_AUTOCOMPLETE',
	'METER_FORM_ASSET_TYPES_AUTOCOMPLETE',
	'PLANNED_MAINTENANCE_SCHEDULE_FORM_ASSET_TYPES_AUTOCOMPLETE',
	'plannedMaintenanceAdvancedFilters',
	'epslAdvancedFilters',
	'analyticsDropdown',
	'troubleshootingFormIndex',
]);
export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState);

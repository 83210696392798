export const getAuditFieldNameByKey = (key) => {
	const allNames = {
		maxQuantity: 'Max Quantity',
		minQuantity: 'Min Quantity',
		onHandQuantity: 'On Hand Quantity',
		transferred: 'Transferred',
		manual: 'Manual',
		purchase_order: 'Purchase Order',
		manual_count_adjustment: 'Manual count adjustment',
		initial_min_quantity_adjustment: 'Inital min qty adjustment',
		min_quantity_adjustment: 'Min qty adjustment',
		max_quantity_adjustment: 'Max qty adjustment',
		shipped_to_work_order: 'Shipped to WO',
		returned_from_work_order: 'Returned from WO',
		receive: 'Received',
		[`return`]: 'Returned',
		invoice_price_adjustment: 'Invoice price adjustment',
		totalStockedPartsCost: 'Total stocked parts cost',
		received: 'Received',
		initial_disbursement: 'Initial Disbursement',
		disbursement: 'Disbursement',
		manual_price_adjustment: 'Manual price adjustment',
	};
	return allNames[key] || key;
};

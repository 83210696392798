import * as BizCharts from 'bizcharts';
import React, { FC } from 'react';

const { Chart, Axis, Geom, Tooltip, Guide } = BizCharts;

interface LineChartProps {
	cols: any;
	height: number;
	data: any;
	fillArea: boolean;
	position: string;
	chartPadding?: [number, number, number, number];
	color?: string;
	color2?: string;
	xAxisName: string;
	yAxisName: string;
	xAxisLabel?: any;
	yAxisLabel?: any;
	tooltipTemplate?: any;
	baselineValue: number;
	baselineText: string;
}

export const KPILineChart: FC<LineChartProps> = ({
	chartPadding,
	tooltipTemplate,
	baselineValue,
	baselineText,
	data,
	cols,
	color,
	color2,
	height,
	fillArea,
	xAxisName,
	yAxisName,
	xAxisLabel,
	yAxisLabel,
	position,
}) => {
	return (
		<Chart height={height} padding="auto" data={data} scale={cols} forceFit={true}>
			<Axis name={xAxisName} label={xAxisLabel} />
			<Axis name={yAxisName} label={yAxisLabel} />
			<Tooltip
				crosshairs={{ type: 'y' }}
				itemTpl={
					tooltipTemplate
						? tooltipTemplate
						: `
                    <li data-index={index}>
                        <!-- The marker for each record -->
                        <span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>
                        {name}: {value}
                    </li>
                `
				}
			/>
			<Geom type="line" position={position} color={color} size={2} />
			{fillArea ? <Geom type="area" position={position} color={color} /> : null}
			<Geom
				type="point"
				position={position}
				color={color}
				size={4}
				shape={'circle'}
				style={{ stroke: '#fff', lineWidth: 1 }}
			/>
			<Guide>
				{/* <Guide.Line
					top={false} // 指定 guide 是否绘制在 canvas 最上层，默认为 false, 即绘制在最下层
					start={['min', baselineValue]} // 辅助线起始位置，值为原始数据值，支持 callback
					end={['max', baselineValue]} // 辅助线结束位置，值为原始数据值，支持 callback
					lineStyle={{
						stroke: '#999', // 线的颜色
						lineDash: [0, 2, 2], // 虚线的设置
						lineWidth: 1, // 线的宽度
					}} // 图形样式配置
					text={{
						position: 'start', // 文本的显示位置
						autoRotate: true, // 是否沿线的角度排布，默认为 true
						content: baselineText, // 文本的内容
						offsetX: 16, // x 方向的偏移量
						offsetY: -8, // y 方向的偏移量
					}}
				/> */}
				<Guide.RegionFilter
					top={true}
					start={['min', baselineValue]}
					end={['max', 'min']}
					color={color2 || '#FF4D4F'}
				/>
				<Guide.RegionFilter
					top={true}
					start={['min', 'max']}
					end={['max', baselineValue]}
					color={color || '#18a1cd'}
				/>
			</Guide>
		</Chart>
	);
};

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import RouteWithSubRoutes from '../route_with_sub_routes/RouteWithSubRoutes';

const InvoicesSavedFiltersViewPage = ({ childRoutes }): React.ReactElement => {
	return (
		<div>
			<Route
				path="/buyer/invoices/savedViews"
				exact={true}
				render={() => (
					<Redirect
						to={{
							pathname: '/buyer/invoices/savedViews/overview',
						}}
					/>
				)}
			/>
			{childRoutes.map((r, i) => (
				<RouteWithSubRoutes key={i} {...r} />
			))}
		</div>
	);
};

export default InvoicesSavedFiltersViewPage;

import * as React from 'react';
import { Checkbox, Col, Dropdown, Menu, Row, Select, Space } from 'antd';

export default function TableSelector({ records, selectItems, counts }) {
	const itemMenu = () => {
		const items = [
			{
				key: 'currentPage',
				label: (
					<span
						onClick={(e) =>
							currentPageKeys.every((val) => keys.includes(val))
								? selectItems.onDeselectCurrentPage(records)
								: selectItems.onSelectCurrentPage(records)
						}
					>
						Current Page
					</span>
				),
			},
			{
				key: 'none',
				label: <span onClick={selectItems.onSelectNone}>None</span>,
			},
		];
		return <Menu mode={'inline'} items={items}></Menu>;
	};
	let currentPageKeys = records.map((rec) => rec.id.toString());
	let keys = selectItems.rowSelection.selectedRowKeys;

	return (
		<Col style={{ padding: '16px 0px' }}>
			<Row
				style={{
					justifyContent: 'space-between',
					alignItems: 'center',
					gap: '0px',
					cursor: 'pointer',
				}}
			>
				<Checkbox
					onChange={(e) =>
						currentPageKeys.every((val) => keys.includes(val))
							? selectItems.onDeselectCurrentPage(records)
							: selectItems.onSelectCurrentPage(records)
					}
					checked={records.length !== 0 && currentPageKeys.every((val) => keys.includes(val))}
					disabled={records.length === 0}
				></Checkbox>

				{/*<Dropdown overlay={itemMenu()}>
					<DownOutlined style={{ fontSize: 10, padding: 4 }} />
				</Dropdown>*/}
			</Row>
		</Col>
	);
}

import moment from 'moment';
import { nullSafeGet, nullSafeGetOrElse } from './DataAccessUtils';
import { DATE_FORMAT, DATE_ONLY_FORMAT, TIME_ONLY_FORMAT } from './DataFormatterUtils';
import { WO_STEP_NAMES } from './DataConstants';

const STEP_INPUT_TYPE_MAP = {
	DATE_TIME: 'dateTime',
	DATE_ONLY: 'dateOnly',
	TIME_RANGE: 'timeRange',
	NUMBER: 'number',
	TEXT: 'text',
};

export const StepInputTypes = [
	{
		value: STEP_INPUT_TYPE_MAP.DATE_TIME,
		label: 'Date Time',
	},
	{
		value: STEP_INPUT_TYPE_MAP.DATE_ONLY,
		label: 'Date Only',
	},
	{
		value: STEP_INPUT_TYPE_MAP.TIME_RANGE,
		label: 'Time Range',
	},
	{
		value: STEP_INPUT_TYPE_MAP.NUMBER,
		label: 'Number',
	},
	{
		value: STEP_INPUT_TYPE_MAP.TEXT,
		label: 'Text',
	},
];

export const getStepInputLabel = (val) =>
	nullSafeGetOrElse(
		'label',
		StepInputTypes.find((_) => _.value === val),
		val
	);

export const getTroubleshootingBackendInput = (tsAnswer) => {
	const inputType = nullSafeGetOrElse(
		'troubleshootingStep.inputType',
		tsAnswer,
		STEP_INPUT_TYPE_MAP.TEXT
	);

	const ans = nullSafeGetOrElse('answer', tsAnswer, '');

	switch (inputType) {
		case STEP_INPUT_TYPE_MAP.DATE_TIME:
		case STEP_INPUT_TYPE_MAP.DATE_ONLY:
			return ans.utc().format();
		case STEP_INPUT_TYPE_MAP.TIME_RANGE:
			return ans.map((_) => _.utc().format()).join(',');
		case STEP_INPUT_TYPE_MAP.NUMBER:
			return `${ans}`;
		default:
			return ans;
	}
};

export const formatForTroubleshootingAnswerInput = (tsAnswer) => {
	const inputType = nullSafeGetOrElse(
		'troubleshootingStep.inputType',
		tsAnswer,
		STEP_INPUT_TYPE_MAP.TEXT
	);

	const ans = nullSafeGetOrElse('answer', tsAnswer, '');

	switch (inputType) {
		case STEP_INPUT_TYPE_MAP.DATE_TIME:
		case STEP_INPUT_TYPE_MAP.DATE_ONLY:
			return moment(ans);
		case STEP_INPUT_TYPE_MAP.TIME_RANGE:
			return ans.split(',').map((_) => moment(_));
		case STEP_INPUT_TYPE_MAP.NUMBER:
			return parseFloat(ans);
		default:
			return ans;
	}
};

export const getTroubleshootingAnswerDisplay = (tsAnswer) => {
	const inputType = nullSafeGetOrElse(
		'troubleshootingStep.inputType',
		tsAnswer,
		STEP_INPUT_TYPE_MAP.TEXT
	);

	const ans = nullSafeGetOrElse('answer', tsAnswer, '');

	switch (inputType) {
		case STEP_INPUT_TYPE_MAP.DATE_TIME:
			return moment(ans).format(DATE_FORMAT);
		case STEP_INPUT_TYPE_MAP.DATE_ONLY:
			return moment(ans).format(DATE_ONLY_FORMAT);
		case STEP_INPUT_TYPE_MAP.TIME_RANGE:
			return `From  ${ans
				.split(',')
				.map((_) => moment(_).format(TIME_ONLY_FORMAT))
				.join(' to ')}`;
		default:
			return ans;
	}
};

const getTroubleshootingAnswersForWOStep = (stepName, tsAttributes, tsAnswers) => {
	const tsAnswer = tsAnswers.find((tsAns) => {
		const tsAttr = tsAttributes.find((tsA) => tsA.id === tsAns.troubleshootingAttributeId);
		return nullSafeGet('previousStep', tsAttr) === stepName;
	});
	const answerSteps = nullSafeGetOrElse('troubleshootingAnswers', tsAnswer, []).map((_) => ({
		key: nullSafeGetOrElse('troubleshootingStep.title', _, ''),
		value: getTroubleshootingAnswerDisplay(_),
	}));
	return answerSteps.length > 0 ? answerSteps : undefined;
};

export const getTroubleshootingAnswersForNecessaryWOSteps = (state) => {
	const tsRoutes = nullSafeGetOrElse('troubleshootingRoutes', state, []);
	const tsAnswers = nullSafeGetOrElse('troubleshootingAnswers', state, []);
	const locationTsAnswer =
		getTroubleshootingAnswersForWOStep(WO_STEP_NAMES.LOCATIONS, tsRoutes, tsAnswers) ||
		getTroubleshootingAnswersForWOStep(WO_STEP_NAMES.ASSET, tsRoutes, tsAnswers) ||
		[];
	const problemTypeTsAnswer =
		getTroubleshootingAnswersForWOStep(WO_STEP_NAMES.PROBLEM_TYPE_TOP_LEVEL, tsRoutes, tsAnswers) ||
		getTroubleshootingAnswersForWOStep(
			WO_STEP_NAMES.PROBLEM_TYPE_NEXT_LEVEL,
			tsRoutes,
			tsAnswers
		) ||
		getTroubleshootingAnswersForWOStep(
			WO_STEP_NAMES.PROBLEM_TYPE_NEXT_BRANCH,
			tsRoutes,
			tsAnswers
		) ||
		[];
	const priorityTsAnswer =
		getTroubleshootingAnswersForWOStep(WO_STEP_NAMES.PRIORITY, tsRoutes, tsAnswers) || [];

	return {
		locationTsAnswer,
		problemTypeTsAnswer,
		priorityTsAnswer,
	};
};

export const getExistingTsAnswersFromStateForWOTsRoute = (state, route) => {
	const currentTsAnswer = nullSafeGetOrElse('troubleshootingAnswers', state, []).find(
		(_) => _.troubleshootingAttributeId === route.id
	);
	return nullSafeGetOrElse('troubleshootingAnswers', currentTsAnswer, []);
};

export const getUpdatedTsAnswersState = (state, route, answers) => {
	const currentTsAnswer = nullSafeGetOrElse('troubleshootingAnswers', state, []).find(
		(_) => _.troubleshootingAttributeId === route.id
	);
	const updatedTsAnswer = {
		troubleshootingAttributeId: route.id,
		troubleshootingAnswers: answers,
	};
	const existingAnswers = nullSafeGetOrElse('troubleshootingAnswers', state, []);
	return !!currentTsAnswer
		? existingAnswers.map((_) => (_.troubleshootingAttributeId === route.id ? updatedTsAnswer : _))
		: [...existingAnswers, ...[updatedTsAnswer]];
};

export const getUpdatedTroubleshootingStates = (routeHierarchy, currentRoute, state) => {
	const excludeRoutesIndex = routeHierarchy.indexOf(currentRoute);
	const excludeRoutes = excludeRoutesIndex >= 0 ? routeHierarchy.slice(excludeRoutesIndex) : [];
	const existingTroubleshootingRoutes = nullSafeGetOrElse('troubleshootingRoutes', state, []);
	const troubleshootingRoutes = existingTroubleshootingRoutes.filter(
		(_) => !excludeRoutes.includes(_.previousStep)
	);
	const existingTroubleshootingAnswers = nullSafeGetOrElse('troubleshootingAnswers', state, []);
	const troubleshootingAnswers = existingTroubleshootingAnswers.filter(
		(_) => !!troubleshootingRoutes.find((r) => r.id === _.troubleshootingAttributeId)
	);

	return {
		troubleshootingRoutes,
		troubleshootingAnswers,
	};
};

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col, Button, Card } from 'antd';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import { EmptyState } from '../empty_state/EmptyState';
import SubnavBar from '../subnav_bar/SubnavBar';
import { siteSurveyTemplatesRestCrudThunksForSupplier } from '../../thunks/site_survey_templates_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';

const { Content } = Layout;

class SupplierSiteSurveyTemplatesIndexPage extends React.Component<any, any> {
	render() {
		const { history } = this.props;
		const onRow = (record) => ({
			onClick: () => history.push(`/supplier/siteSurveys/templates/detail/${record.id}`),
		});

		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				defaultSortOrder: 'ascend',
				render: (text, record) => record.title,
			},
		];

		return (
			<Content className="siteSurveyTemplatesIndexPage" style={{ padding: '0 0.5em' }}>
				<LogOnMountWithStandardEventProperties eventType="visited supplier site survey template index page" />
				{/*<ScrollToTopOnMount/>*/}
				{/*<BackTop/>*/}
				<div style={{ margin: '0.5em 0px' }}>
					<SubnavBar
						left={<div></div>}
						right={
							<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_SITE_SURVEY_TEMPLATES}>
								<Button
									type="primary"
									size="large"
									onClick={() =>
										this.props.history.push('/supplier/siteSurveys/overview/templates/new')
									}
								>
									Add Site Survey Template
								</Button>
							</AccessPermissionChecker>
						}
					/>
				</div>
				<Row style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<Card bodyStyle={{ padding: 8 }}>
							<PaginatedReduxTable
								showHeader={false}
								emptyState={
									<EmptyState
										graphic={
											<img
												style={{ marginBottom: 8 }}
												src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
											/>
										}
										headline={"It's a little lonely in here."}
										body={
											<div style={{ textAlign: 'center' }}>
												<div style={{ maxWidth: 440, marginBottom: 16 }}>
													Looks like your team has not added any site surveys yet.
												</div>
												<Button
													type="primary"
													onClick={() =>
														this.props.history.push('/supplier/siteSurveys/overview/templates/new')
													}
												>
													Add your first site survey template
												</Button>
											</div>
										}
									/>
								}
								onRow={onRow}
								collectionName="site_survey_templates"
								targetCollectionName="siteSurveyTemplatesIndex"
								columns={columns}
								keyAccessor={(el) => el.id}
								initialPagination={{ current: 1, pageSize: 10 }}
								fetchData={siteSurveyTemplatesRestCrudThunksForSupplier.read}
							/>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(SupplierSiteSurveyTemplatesIndexPage)
);

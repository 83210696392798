import * as React from 'react';

import { connect } from 'react-redux';

import { Layout, Spin, Row, Col, Card, Table } from 'antd';
import { dateFormatter, ORDERED_STOPLIGHT_COLORS } from '../../utils/DataFormatterUtils';
import { getObjectValues, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { withRouter } from 'react-router';
import AnalyticsControlBar from '../analytics_control_bar/AnalyticsControlBar';
import { debounce } from '../../utils/PerformanceUtils';
import { EmptyState } from '../empty_state/EmptyState';
import { StackedBarChart } from '../charts/StackedBarChart';
import moment from 'moment';
import {
	clearWorkPriorityFilters,
	updateWorkPriorityFilters,
} from '../../actions/work_analytics_actions';
import {
	fetchWorkOrderCountByDay,
	fetchWorkOrderCountByMonth,
	fetchWorkOrderCountByWeek,
} from '../../thunks/work_analytics_thunks';
import {
	workOrderPrioritiesRestCrudThunksForBuyer,
	workOrderPrioritiesRestCrudThunksForSupplier,
} from '../../thunks/work_order_priorities_thunks';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { CSV_EXPORT_DEFAULTS, ROLE_TYPES } from '../../utils/DataConstants';
import { ExportToCsv } from 'export-to-csv';

const { Content } = Layout;
require('color');
require('g2');
require('./WorkAnalyticsOverviewPrioritiesPage.less');
const DataSet = require('@antv/data-set');
const isEqual = require('fast-deep-equal');

const defaultSortOrder: 'ascend' | 'descend' = 'descend';

class WorkAnalyticsOverviewPrioritiesPage extends React.Component<any, any> {
	state = {
		initialLoading: true,
	};
	debouncedCallback = debounce(
		() => {
			const {
				fetchWorkOrderCountsByMonth,
				fetchWorkOrderCountsByWeek,
				fetchWorkOrderCountsByDay,
				analytics,
			} = this.props;
			const { initialLoading } = this.state;
			if (analytics.priorityFilters.startDate && analytics.priorityFilters.endDate) {
				const promise1 = fetchWorkOrderCountsByMonth(analytics.priorityFilters);
				const promise2 = fetchWorkOrderCountsByWeek({
					...analytics.priorityFilters,
					startDate: moment().subtract(1, 'months'),
				});
				const promise3 = fetchWorkOrderCountsByDay({
					...analytics.priorityFilters,
					startDate: moment().subtract(1, 'months'),
				});
				if (initialLoading) {
					Promise.all([promise1, promise2, promise3]).then(() =>
						this.setState({ initialLoading: false })
					);
				}
			}
		},
		500,
		false
	);

	componentDidMount() {
		const {
			fetchWorkOrderCountsByMonth,
			fetchWorkOrderCountsByWeek,
			fetchWorkOrderCountsByDay,
			fetchWorkOrderPriorities,
			analytics,
		} = this.props;
		const { initialLoading } = this.state;
		const promise4 = fetchWorkOrderPriorities({});
		if (analytics.priorityFilters.startDate && analytics.priorityFilters.endDate) {
			const promise1 = fetchWorkOrderCountsByMonth(analytics.priorityFilters);
			const promise2 = fetchWorkOrderCountsByWeek({
				...analytics.priorityFilters,
				startDate: moment().subtract(1, 'months'),
			});
			const promise3 = fetchWorkOrderCountsByDay({
				...analytics.priorityFilters,
				startDate: moment().subtract(1, 'months'),
			});
			if (initialLoading) {
				Promise.all([promise1, promise2, promise3, promise4]).then(() =>
					this.setState({ initialLoading: false })
				);
			}
		}
	}

	componentWillReceiveProps(nextProps) {
		const { analytics } = this.props;
		if (!isEqual(nextProps.analytics.priorityFilters, analytics.priorityFilters)) {
			this.debouncedCallback();
		}
	}

	getWOPriorityNames = () => {
		const { workOrderPriorities } = this.props;
		return getObjectValues(workOrderPriorities.records).map((el) => el.name);
	};

	getColumns = () => {
		return (
			[
				{
					title: 'Month',
					dataIndex: 'month',
					render: (text) => dateFormatter(text, 'MMM YYYY'),
					defaultSortOrder: defaultSortOrder,
					sorter: (a, b) => {
						return moment(a.month).unix() - moment(b.month).unix();
					},
				},
			]
				// @ts-ignore
				.concat(
					(this.getWOPriorityNames() as any).map((wop) => ({
						title: wop,
						dataIndex: wop,
						render: (text) => (text ? text : 0),
					}))
				)
		);
	};

	getDisplayData = () => {
		const displayData = nullSafeGetOrElse(
			'props.analytics.workOrderCountByMonth.data',
			this,
			[]
		).map((row) => {
			let el = { month: row.yearMonthKey };
			row.countByPriority.forEach((count, i) => {
				const key = nullSafeGetOrElse(
					'workOrderPriority.name',
					count,
					nullSafeGetOrElse('workOrderPriorityId', count, i)
				);
				const value = nullSafeGet('workOrderCount', count);
				el[key] = value;
			});
			return el;
		});
		return displayData;
	};

	onDownloadAnalytics = () => {
		const title = 'Spend Analytics by Priorities';
		const headers = this.getColumns().map((_) => _.title);
		const csvExporter = new ExportToCsv({
			...CSV_EXPORT_DEFAULTS,
			filename: title,
			title,
			headers,
		});
		csvExporter.generateCsv(
			this.getDisplayData().map((obj) =>
				headers.reduce((acc, key) => {
					return {
						...acc,
						[key]:
							key.toLowerCase() === 'month'
								? dateFormatter(obj.month, 'MMM YYYY')
								: nullSafeGetOrElse(`${key}`, obj, 0),
					};
				}, {})
			)
		);
	};

	render() {
		const { saveWorkPriorityFilters, clearAllWorkPriorityFilters, analytics, workOrderPriorities } =
			this.props;
		const { initialLoading } = this.state;
		const { workOrderCountByMonth, workOrderCountByWeek, workOrderCountByDay, priorityFilters } =
			analytics;

		const workOrderPriorityNames = this.getWOPriorityNames();

		const workOrdersCountByMonthConfig = {
			xAxisName: 'yearMonthKey',
			yAxisName: 'workOrderCount',
			position: 'yearMonthKey*workOrderCount',
			fillArea: false,
			color: ['priority', ORDERED_STOPLIGHT_COLORS],
			transformer: (data) => {
				const ds = new DataSet();
				const dv = ds.createView().source(data);
				dv.transform({
					type: 'map',
					callback: (row) => {
						workOrderPriorityNames.forEach((wop) => {
							row[wop] = 0;
						});
						row.countByPriority.forEach((c) => {
							if (nullSafeGet('workOrderPriority.name', c)) {
								row[c.workOrderPriority.name] = c.workOrderCount;
							}
						});
						return row;
					},
				});
				dv.transform({
					type: 'fold',
					fields: workOrderPriorityNames, // expand fields into separate records
					key: 'priority', // new key field
					value: 'workOrderCount', // new value field
				});
				return dv;
			},
			cols: {
				yearMonthKey: { alias: 'Month' },
				workOrderCount: { alias: '# Work Orders' },
			},
			yAxisLabel: {
				formatter: (val) => `$${val}`,
				textStyle: {
					fontSize: '12',
					fontFamily: 'Roboto',
					fill: 'rgba(0,0,0,0.85)',
				},
			},
			xAxisLabel: {
				textStyle: {
					fontSize: '12',
					fontFamily: 'Roboto',
					fill: 'rgba(0,0,0,0.85)',
				},
			},
		};

		const latestWeekData = workOrderCountByWeek.data[workOrderCountByWeek.data.length - 1];
		const previousWeekData = workOrderCountByWeek.data[workOrderCountByWeek.data.length - 2];
		const last14DaysData = workOrderCountByDay.data.slice(-14);
		let workOrderPriorityCounts = [];
		if (latestWeekData && previousWeekData) {
			workOrderPriorityCounts = getObjectValues(workOrderPriorities.records).map((wop) => {
				const latestWeek = latestWeekData.countByPriority.find(
					(d) => d.workOrderPriorityId === wop.id
				) || { workOrderCount: 0 };
				const previousWeek = previousWeekData.countByPriority.find(
					(d) => d.workOrderPriorityId === wop.id
				) || { workOrderCount: 0 };
				const last14Days = last14DaysData.map((el) => {
					const workOrderCount = (
						el.countByPriority.find((d) => d.workOrderPriorityId === wop.id) || {
							workOrderCount: 0,
						}
					).workOrderCount;
					return { x: moment(el.date).format('YYYY-MM-DD'), y: workOrderCount };
				});
				return {
					id: wop.id,
					latestWeekCount: latestWeek.workOrderCount,
					difference: latestWeek.workOrderCount - previousWeek.workOrderCount,
					percentChange:
						previousWeek.workOrderCount === 0
							? 0
							: (latestWeek.workOrderCount - previousWeek.workOrderCount) /
							  (1.0 * previousWeek.workOrderCount),
					displayName: wop.name,
					last14Days,
				};
			});
		}

		const getTrendIndicator = (percentageChange) => {
			if (percentageChange === 0) {
				return null;
			}
			return percentageChange > 0 ? 'up' : 'down';
		};

		const tableData = this.getDisplayData();

		return (
			<Content
				className="analyticsOverviewFacilitiesPage"
				style={{ padding: '0 0.5em', position: 'relative' }}
			>
				<LogOnMountWithStandardEventProperties eventType="visited work analytics priorities page" />
				{/*<BackTop/>*/}
				<Row key={1} style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							<div></div>
							<AnalyticsControlBar
								updateFilters={saveWorkPriorityFilters}
								clearFilters={clearAllWorkPriorityFilters}
								supplierFacilityIds={priorityFilters.supplierFacilityIds}
								locationIds={priorityFilters.locationIds}
								startDate={priorityFilters.startDate}
								endDate={priorityFilters.endDate}
								isPM={priorityFilters.isPM}
								workCompletedAtStartDate={priorityFilters.workCompletedAtStartDate}
								workCompletedAtEndDate={priorityFilters.workCompletedAtEndDate}
								showDownload
								onDownload={this.onDownloadAnalytics}
								filterConfig={{ workCompletedAt: true }}
							/>
						</div>
					</Col>
				</Row>
				<Row style={{ margin: '0.5em -8px' }} gutter={16}>
					<Col span={24}>
						<Card bodyStyle={{ padding: '17.5px 24px' }}>
							<h5 style={{ marginBottom: 24 }}>New Work Order Count by Priority</h5>
							<Spin spinning={workOrderCountByMonth.loading || initialLoading}>
								{workOrderCountByMonth.data && workOrderCountByMonth.data.length > 0 ? (
									[
										<StackedBarChart
											height={400}
											chartPadding={[40, 40, 40, 40]}
											showTooltipTitle={false}
											xAxisName={nullSafeGet('xAxisName', workOrdersCountByMonthConfig)}
											yAxisName={nullSafeGet('yAxisName', workOrdersCountByMonthConfig)}
											xAxisLabel={nullSafeGet('xAxisLabel', workOrdersCountByMonthConfig)}
											yAxisLabel={nullSafeGet('yAxisLabel', workOrdersCountByMonthConfig)}
											fillArea={nullSafeGet('fillArea', workOrdersCountByMonthConfig)}
											data={
												workOrderPriorityNames.length > 0
													? workOrdersCountByMonthConfig.transformer(workOrderCountByMonth.data)
													: []
											}
											cols={nullSafeGet('cols', workOrdersCountByMonthConfig)}
											color={nullSafeGet('color', workOrdersCountByMonthConfig)}
											position={nullSafeGet('position', workOrdersCountByMonthConfig)}
										/>,
										<Table
											style={{ marginTop: 40 }}
											pagination={{ pageSize: 12 }}
											columns={this.getColumns()}
											rowKey={(el) => el['category']}
											dataSource={workOrderPriorityNames.length > 0 ? tableData : []}
										/>,
									]
								) : (
									<EmptyState
										height={120}
										headline={'No work orders found'}
										body={
											<div style={{ maxWidth: 488, textAlign: 'center' }}>
												We didn't find any work orders. Try changing your filters or expanding the
												selected time period.
											</div>
										}
									/>
								)}
							</Spin>
						</Card>
					</Col>
				</Row>
			</Content>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	accountActivated: state.session.accountActivated,
	workOrderPriorities: state.work_order_priorities,
	currentUser: state.session.currentUser,
	analytics: state.work_analytics,
	userType: (state as any).session.userType,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	saveWorkPriorityFilters: (filters) => dispatch(updateWorkPriorityFilters(filters)),
	clearAllWorkPriorityFilters: () => dispatch(clearWorkPriorityFilters()),
	fetchWorkOrderPriorities: (params, filters = null, pagination = null, sorting = null) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? workOrderPrioritiesRestCrudThunksForSupplier.read(params, filters, pagination, sorting)
				: workOrderPrioritiesRestCrudThunksForBuyer.read(params, filters, pagination, sorting)
		),
	fetchWorkOrderCountsByMonth: (params, filters = null, pagination = null, sorting = null) =>
		dispatch(fetchWorkOrderCountByMonth(ownProps.userType)(params, filters, pagination, sorting)),
	fetchWorkOrderCountsByWeek: (params, filters = null, pagination = null, sorting = null) =>
		dispatch(fetchWorkOrderCountByWeek(ownProps.userType)(params, filters, pagination, sorting)),
	fetchWorkOrderCountsByDay: (params, filters = null, pagination = null, sorting = null) =>
		dispatch(fetchWorkOrderCountByDay(ownProps.userType)(params, filters, pagination, sorting)),
});

const ComponentWithoutUserType = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(WorkAnalyticsOverviewPrioritiesPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import React, { FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { EditPage } from '../edit_page/EditPage';
import LogOnMountWithStandardEventProperties from '../log_on_mount_with_standard_event_properties/LogOnMountWithStandardEventProperties';
import { troubleshootingRestCrudThunksForBuyer } from '../../thunks/troubleshooting_thunks';
import TroubleshootingForm from './TroubleshootingForm';

const EditTroubleshootingPage: FC<any> = ({
	fetching,
	history,
	match,
	getTroubleshooting,
	troubleshootingAttribute,
	userType,
}): React.ReactElement => {
	const tsId = useMemo(() => match.params.id, [match.params.id]);

	useEffect(() => tsId && getTroubleshooting(tsId), [tsId, getTroubleshooting]);

	return (
		<>
			<LogOnMountWithStandardEventProperties eventType="visited supplier edit part page" />,
			<EditPage
				title="Edit Troubleshooting"
				formComponentProps={{
					formData: troubleshootingAttribute,
					onSuccess: (rec) => history.push(`/${userType}/admin/troubleshooting/detail/${rec.id}`),
				}}
				fetching={fetching}
				FormComponent={TroubleshootingForm}
			/>
		</>
	);
};

const mapStateToProps = (state) => ({
	fetching: state.troubleshooting.fetching,
	troubleshootingAttribute: state.troubleshooting.detail,
});

const mapDispatchToProps = (dispatch) => ({
	getTroubleshooting: (id) => dispatch(troubleshootingRestCrudThunksForBuyer.readOne(id)),
});

const ComponentWithoutUserType = withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(EditTroubleshootingPage)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FlatfileImporter } from '@flatfile/react';
import { FLAT_FILE_LICENSE_KEY } from '../../utils/DataConstants';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import {
	getFlatfileRecordForTransferToLocationEquipment,
	TRANSFER_TO_LOCATION_EQUIPMENT_FLATFILE_HEADER,
} from './stock_location_constants';
import {
	assetsRestCrudThunksForSupplier,
	createAssetFromEquipmentPerStockLocationBulkForSupplier,
} from '../../thunks/assets_thunks';
import { assetTypesRestCrudThunksForSupplier } from '../../thunks/asset_types_thunks';
import { locationsRestCrudThunksForSupplier } from '../../thunks/locations_thunks';
import { assetModelsRestCrudThunksForSupplier } from '../../thunks/asset_models_thunks';

const TransferToLocationReceivedEquipmentBulk: FC<any> = ({
	currentUser,
	selectedRecords,
	onSuccess,
	onDataUpload,
	fetchAssetTypes,
	fetchLocations,
	fetchAssetModels,
	companyConfig,
	createAssetFromEquipmentBulk,
	onClose,
	transferInitiated,
}): React.ReactElement => {
	const [assetTypes, setAssetTypes] = useState([]);
	const [locations, setLocations] = useState([]);
	const [assetModels, setAssetModels] = useState([]);
	const [init, initCompleted] = useState(false);
	const [requestedData, setRequestedData] = useState(false);

	const transferNoteRequired = useMemo(
		() => nullSafeGetOrElse('inventoryConfig.transferNoteMandatory', companyConfig, false),
		[companyConfig]
	);

	useEffect(() => {
		const promises = [fetchAssetTypes(), fetchLocations(), fetchAssetModels()];
		Promise.allSettled(promises)
			.then((res) => {
				const [{ value: assetTypes = [] }, { value: locations = [] }, { value: assetModels = [] }] =
					res;
				setAssetTypes(assetTypes);
				setLocations(locations);
				setAssetModels(assetModels);
			})
			.finally(() => {
				initCompleted(true);
				transferInitiated && transferInitiated();
			});
	}, []);

	const importer = useMemo(() => {
		return new FlatfileImporter(FLAT_FILE_LICENSE_KEY, {
			type: 'Import type',
			fields: TRANSFER_TO_LOCATION_EQUIPMENT_FLATFILE_HEADER(
				locations.map((_) => ({
					label: _.name,
					value: _.id,
				})),
				assetTypes.map((_) => ({
					label: _.name,
					value: _.id,
				})),
				assetModels.map((_) => ({
					label: _.modelName,
					value: _.id,
				})),
				transferNoteRequired
			),
		});
	}, [locations, assetTypes, assetModels, transferNoteRequired]);

	importer.setCustomer({
		companyId: nullSafeGet('facility.buyerCompanyId', currentUser),
		companyName: nullSafeGet('facility.name', currentUser),
		userId: currentUser.email,
	});

	const addPossibleAssetModelId = useCallback(
		(record) => {
			const assetTypeId = record['assetTypeId'];
			const equipmentTypeModelName = nullSafeGet(
				'equipmentType.modelName',
				selectedRecords.find((_) => _.id === record.id)
			);
			const matchingAssetModel =
				assetTypeId && equipmentTypeModelName
					? (assetModels || []).find(
							(_) =>
								_.assetTypeId === assetTypeId &&
								nullSafeGetOrElse('modelName', _, '').includes(equipmentTypeModelName.trim())
					  )
					: undefined;

			return { ...record, assetModelId: nullSafeGetOrElse('id', matchingAssetModel, undefined) };
		},
		[assetModels, selectedRecords]
	);

	importer.registerRecordHook((record) => {
		return addPossibleAssetModelId(record);
	});

	useEffect(() => {
		if (init && selectedRecords && selectedRecords.length > 0 && !requestedData) {
			setRequestedData(true);
			importer
				.requestDataFromUser({
					source: selectedRecords.map((_) => ({
						data: addPossibleAssetModelId(getFlatfileRecordForTransferToLocationEquipment(_)),
					})),
				})
				.then(({ validData }) => {
					const entities = validData.map(
						({ id, locationId, assetModelId, assetTypeId, assetNumber, transferNOte }) => ({
							equipmentPerStockLocationId: id,
							locationId,
							...(assetModelId && { assetModelId }),
							...(assetTypeId && { assetTypeId }),
							...(assetNumber && { assetNumber }),
							...(transferNOte && { transferNOte }),
						})
					);
					importer.displayLoader('Updating Equipment.');
					createAssetFromEquipmentBulk(entities).then((res) => {
						importer.displaySuccess('Equipment updated Successfully');
						onSuccess && onSuccess(res);
					});
				})
				.catch((err) => {
					onClose();
					importer.displayError(err);
				});
		}
	}, [
		onDataUpload,
		onSuccess,
		selectedRecords,
		init,
		createAssetFromEquipmentBulk,
		onClose,
		addPossibleAssetModelId,
		importer,
		requestedData,
	]);

	return <></>;
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	fetchAssetTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			assetTypesRestCrudThunksForSupplier.readLite(
				{ ...params, no_pagination: true },
				'bulkEditAssetsAssetTypes',
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),

	fetchLocations: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			locationsRestCrudThunksForSupplier.readLite(
				{ ...params, no_pagination: true },
				'bulkEditAssetsLocations',
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	fetchAssetModels: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			assetModelsRestCrudThunksForSupplier.readLite(
				{ ...params, no_pagination: true },
				'bulkEditAssetsAssetModels',
				pagination,
				sorting,
				filters,
				addToTargetCollection
			)
		),
	createAssetFromEquipmentBulk: (entities) =>
		dispatch(createAssetFromEquipmentPerStockLocationBulkForSupplier(entities)),
});

export default withRouter<any, any>(
	connect(mapStateToProps, mapDispatchToProps)(TransferToLocationReceivedEquipmentBulk)
);

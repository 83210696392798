import React from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Alert, Button, Select } from 'antd';
import { connect } from 'react-redux';
import {
	assetTypesRestCrudThunksForBuyer,
	assetTypesRestCrudThunksForSupplier,
} from '../../thunks/asset_types_thunks';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { withRouter } from 'react-router';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import BackButton from '../back_button/BackButton';
import {
	problemTypesHeirarchicalForBuyer,
	problemTypesHeirarchicalForSupplier,
	problemTypesRestCrudThunksForBuyer,
	problemTypesRestCrudThunksForSupplier,
} from '../../thunks/problem_types_thunks';
import ProblemTypeTreeSelect from '../problem_type_tree_select';
import { ROLE_TYPES } from '../../utils/DataConstants';
import {
	depreciationClassesRestCrudThunksForBuyer,
	depreciationClassesRestCrudThunksForSupplier,
} from '../../thunks/depreciation_class_thunks';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';

require('./AssetTemplateForm.less');
const REQUIRED_FIELDS_KEYS = 'requiredFieldsKeys';
const OPTIONAL_FIELDS_KEYS = 'optionalFieldsKeys';
const ASSET_TYPE_FORM_PROBLEM_TYPES_AUTOCOMPLETE = 'ASSET_TYPE_FORM_PROBLEM_TYPES_AUTOCOMPLETE';

interface AssetTemplateFormProps extends FormComponentProps {
	creating: boolean;
	updating: boolean;
	formData: any;
	history: any;
	fetchProblemTypes: any;
	fetchMultipleProblemTypes: any;
	problemTypes: any;
	redirectForwardUrl?: string;
	onSuccess: any;
	createErrors: any[];
	updateErrors: any[];
	currentUser: any;
	create: any;
	update: any;
	depreciationClasses: any;
	fetchDepreciationClasses: any;
	fetchMultipleDepreciationClasses: any;
}

class AssetTemplateForm extends React.Component<AssetTemplateFormProps, any> {
	componentDidMount() {
		this.setState({
			requiredFieldsUuid: nullSafeGetOrElse('formData.requiredFields', this.props, []).length,
			optionalFieldsUuid: nullSafeGetOrElse('formData.optionalFields', this.props, []).length,
		});
	}
	handleSubmit = (e, submitAction) => {
		const { history, onSuccess, redirectForwardUrl } = this.props;
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const requiredFieldsKeys = values[REQUIRED_FIELDS_KEYS];
				const optionalFieldsKeys = values[OPTIONAL_FIELDS_KEYS];

				const requiredFields = requiredFieldsKeys
					.filter((s) => values[`requiredFields-${s}-key`] && values[`requiredFields-${s}-type`])
					.map((k) => ({
						key: values[`requiredFields-${k}-key`],
						type: values[`requiredFields-${k}-type`],
					}));
				const optionalFields = optionalFieldsKeys
					.filter((s) => values[`optionalFields-${s}-key`] && values[`optionalFields-${s}-type`])
					.map((k) => ({
						key: values[`optionalFields-${k}-key`],
						type: values[`optionalFields-${k}-type`],
					}));
				let assetType = {
					id: values.id,
					name: values.name,
					depreciationClassId: values.depreciationClassId,
					requiredFields,
					optionalFields,
					allProblemTypeIds: values.allProblemTypeIds || [],
				};

				submitAction(assetType).then((record) => {
					if (redirectForwardUrl) {
						history.push(redirectForwardUrl);
					}
					if (onSuccess) {
						onSuccess(record);
					}
				});
			}
		});
	};

	addRequiredField = () => {
		const { requiredFieldsUuid } = this.state;
		this.addField(REQUIRED_FIELDS_KEYS)(requiredFieldsUuid);
		this.setState({ requiredFieldsUuid: requiredFieldsUuid + 1 });
	};

	addOptionalField = () => {
		const { optionalFieldsUuid } = this.state;
		this.addField(OPTIONAL_FIELDS_KEYS)(optionalFieldsUuid);
		this.setState({ optionalFieldsUuid: optionalFieldsUuid + 1 });
	};

	removeRequiredField = (k) => {
		this.removeField(REQUIRED_FIELDS_KEYS)(k);
	};

	removeOptionalField = (k) => {
		this.removeField(OPTIONAL_FIELDS_KEYS)(k);
	};

	addField = (fieldName) => (uuid) => {
		const { form } = this.props;
		// can use data-binding to get
		const keys = form.getFieldValue(fieldName);
		const nextKeys = [...keys, uuid];
		// can use data-binding to set
		// important! notify form to detect changes
		form.setFieldsValue({
			[fieldName]: nextKeys,
		});
	};

	removeField = (fieldName) => (k) => {
		const { form } = this.props;
		// can use data-binding to get
		const keys = form.getFieldValue(fieldName);
		const nextKeys = keys.filter((key) => key !== k);

		// can use data-binding to set
		form.setFieldsValue({
			[fieldName]: nextKeys,
		});
	};

	render() {
		const {
			updating,
			creating,
			form,
			update,
			create,
			createErrors,
			formData,
			updateErrors,
			problemTypes,
			fetchProblemTypes,
			fetchMultipleProblemTypes,
			depreciationClasses,
			fetchDepreciationClasses,
			fetchMultipleDepreciationClasses,
		} = this.props;
		const { getFieldDecorator } = form;
		const isUpdate = formData && formData.id !== undefined;
		const submitAction = isUpdate ? update : create;
		const submitText = isUpdate ? 'Update asset template' : 'Create asset template';
		const isLoading = updating || creating;
		getFieldDecorator(`id`, { initialValue: formData.id });
		getFieldDecorator(REQUIRED_FIELDS_KEYS, {
			initialValue: nullSafeGetOrElse('requiredFields', formData, []).map((e, idx) => idx),
		});
		getFieldDecorator(OPTIONAL_FIELDS_KEYS, {
			initialValue: nullSafeGetOrElse('optionalFields', formData, []).map((e, idx) => idx),
		});
		const requiredFieldsKeys = form.getFieldValue(REQUIRED_FIELDS_KEYS);
		const optionalFieldsKeys = form.getFieldValue(OPTIONAL_FIELDS_KEYS);

		const getFieldItems = (type) => {
			let fieldsKeys;
			let keyPrefix;
			let removeAction;
			if (type === 'required') {
				fieldsKeys = requiredFieldsKeys;
				keyPrefix = 'requiredFields';
				removeAction = this.removeRequiredField;
			} else {
				fieldsKeys = optionalFieldsKeys;
				keyPrefix = 'optionalFields';
				removeAction = this.removeOptionalField;
			}

			return fieldsKeys.map((k, idx) => (
				<div key={`req-field-${k}`}>
					<div style={{ display: 'flex' }}>
						<div
							style={{
								marginRight: 16,
								paddingTop: idx === 0 ? 31 : 0,
							}}
						>
							{idx + 1}
						</div>
						<Form.Item
							label={idx === 0 ? 'Name' : null}
							required={false}
							style={{ width: 200, marginRight: 16 }}
						>
							{form.getFieldDecorator(`${keyPrefix}-${k}-key`, {
								validateTrigger: ['onChange', 'onBlur'],
								initialValue:
									nullSafeGet(`${keyPrefix}-${k}-key`, formData) ||
									nullSafeGet(`${keyPrefix}.${k}.key`, formData),
							})(<Input />)}
						</Form.Item>
						<Form.Item label={idx === 0 ? 'Type' : null} required={false} style={{ width: 100 }}>
							{form.getFieldDecorator(`${keyPrefix}-${k}-type`, {
								initialValue:
									nullSafeGet(`${keyPrefix}-${k}-type`, formData) ||
									nullSafeGet(`${keyPrefix}.${k}.type`, formData),
							})(
								<Select>
									<Select.Option value="string">Text</Select.Option>
									<Select.Option value="number">Number</Select.Option>
									<Select.Option value="currency">Currency</Select.Option>
									<Select.Option value="date">Date</Select.Option>
								</Select>
							)}
						</Form.Item>
						<div
							style={{
								paddingTop: idx === 0 ? 35 : 5,
							}}
						>
							<MinusCircleOutlined
								translate=""
								className="dynamic-delete-button"
								onClick={() => removeAction(k)}
							/>
						</div>
					</div>
				</div>
			));
		};

		return (
			<Form
				layout="vertical"
				onSubmit={(e) => this.handleSubmit(e, submitAction)}
				className="supplierForm"
			>
				{createErrors.length > 0 ? (
					<Form.Item>
						<Alert message={createErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				{updateErrors.length > 0 ? (
					<Form.Item>
						<Alert message={updateErrors.join(' ')} type="error" />
					</Form.Item>
				) : null}
				<Form.Item label="Name">
					{getFieldDecorator('name', {
						initialValue: formData.name,
					})(<Input style={{ maxWidth: 300 }} disabled={isUpdate} />)}
				</Form.Item>
				<Form.Item label="Associated Problem Type">
					{getFieldDecorator('allProblemTypeIds', {
						initialValue: formData.allProblemTypeIds || [],
						rules: [
							{
								type: 'array',
								required: false,
								message: 'Please select a problem type',
							},
						],
					})(
						<ProblemTypeTreeSelect
							mode="multiple"
							stateSlice={problemTypes}
							targetCollectionName={ASSET_TYPE_FORM_PROBLEM_TYPES_AUTOCOMPLETE}
							fetchMultiple={(ids, targetCollectionName) => {
								fetchMultipleProblemTypes(ids, targetCollectionName);
							}}
							fetchData={(
								searchText,
								targetCollectionName,
								pagination,
								sorting,
								filters,
								addToTargetCollection
							) =>
								fetchProblemTypes(
									{ name: searchText },
									targetCollectionName,
									pagination,
									sorting,
									filters,
									addToTargetCollection
								)
							}
							placeholder={'Select Problem Types'}
							renderRecord={(problemType) => (
								<Select.Option key={problemType.id} value={problemType.id}>
									{problemType.hierarchyName}
								</Select.Option>
							)}
						/>
					)}
				</Form.Item>
				<Form.Item label="Depreciation class">
					{getFieldDecorator('depreciationClassId', {
						initialValue: formData.depreciationClassId,
					})(
						<OWAsyncSelect
							style={{ maxWidth: 640 }}
							stateSlice={depreciationClasses}
							targetCollectionName="assetTypesDropdown"
							fetchData={(searchText, targetCollectionName) => {
								fetchDepreciationClasses({ name: searchText }, targetCollectionName);
							}}
							fetchMultiple={fetchMultipleDepreciationClasses}
							placeholder={'Select Depreciation Class'}
							renderRecord={(dc) => (
								<Select.Option key={dc.id} value={dc.id}>
									{dc.name}
								</Select.Option>
							)}
						/>
					)}
				</Form.Item>
				<Form.Item label="Required Fields" style={{ marginBottom: 0 }}>
					{getFieldItems('required')}
				</Form.Item>
				<Form.Item>
					<Button type="dashed" onClick={() => this.addRequiredField()}>
						<PlusOutlined translate="" /> Add required field
					</Button>
				</Form.Item>
				<Form.Item label="Optional Fields" style={{ marginBottom: 0 }}>
					{getFieldItems('optional')}
				</Form.Item>
				<Form.Item>
					<Button type="dashed" onClick={() => this.addOptionalField()}>
						<PlusOutlined translate="" /> Add optional field
					</Button>
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						loading={isLoading}
						className="contactForm__button"
					>
						{submitText}
					</Button>
					<span style={{ marginLeft: '16px' }}>
						<BackButton buttonText="Cancel" />
					</span>
				</Form.Item>
			</Form>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	formData: ownProps.formData,
	redirectForwardUrl: ownProps.redirectForwardUrl,
	onSuccess: ownProps.onSuccess,

	problemTypes: state.problem_types,
	depreciationClasses: state.depreciation_classes,
	createErrors: state.asset_types.createErrors,
	updateErrors: state.asset_types.updateErrors,
	creating: state.asset_types.creating,
	updating: state.asset_types.updating,
	currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	update: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetTypesRestCrudThunksForSupplier.update(entity)
				: assetTypesRestCrudThunksForBuyer.update(entity)
		),
	create: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? assetTypesRestCrudThunksForSupplier.create(entity)
				: assetTypesRestCrudThunksForBuyer.create(entity)
		),
	fetchProblemTypes: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) => {
		const fnToCall =
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? problemTypesHeirarchicalForSupplier
				: problemTypesHeirarchicalForBuyer;
		return dispatch(
			fnToCall(params, targetCollectionName, pagination, sorting, filters, addToTargetCollection)
		);
	},
	fetchMultipleProblemTypes: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? problemTypesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: problemTypesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
	fetchDepreciationClasses: (params, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? depreciationClassesRestCrudThunksForSupplier.read(params, targetCollectionName)
				: depreciationClassesRestCrudThunksForBuyer.read(params, targetCollectionName)
		),
	fetchMultipleDepreciationClasses: (ids, targetCollectionName) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? depreciationClassesRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)
				: depreciationClassesRestCrudThunksForBuyer.readMultiple(ids, targetCollectionName)
		),
});

const AssetTemplateFormWR = withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Form.create<AssetTemplateFormProps>()(AssetTemplateForm))
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(AssetTemplateFormWR);

import RestCrudThunks from './restCrudThunksGenerator';
import { ROLE_TYPES } from '../utils/DataConstants';
import { PART_CATALOG_CHANGES_CRUD_ACTION_CREATORS } from '../actions/part_catalog_changes_actions';

export const partCatalogChangesRestCrudThunksForSupplier = new RestCrudThunks(
	'part_catalog_changes',
	'part_catalog_change',
	'partCatalogChange',
	'partCatalogsChanges',
	PART_CATALOG_CHANGES_CRUD_ACTION_CREATORS,
	null,
	ROLE_TYPES.SUPPLIER,
	false,
	'inventory/'
);

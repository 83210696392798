import * as BizCharts from 'bizcharts';
import React, { FC } from 'react';
import { STOPLIGHT_COLORS } from '../../utils/DataFormatterUtils';

const { Chart, Axis, Geom, Tooltip, Guide, View, Legend } = BizCharts;

interface LineChartProps {
	cols: any;
	height: number;
	data: any;
	fillArea: boolean;
	position: string;
	chartPadding?: [number, number, number, number];
	color?: string;
	color2?: string;
	xAxisName: string;
	yAxisName: string;
	xAxisLabel?: any;
	yAxisLabel?: any;
	tooltipTemplate?: any;
	currency: any;
	budget: any;
}

export const BudgetDetailChart: FC<LineChartProps> = ({
	chartPadding,
	tooltipTemplate,
	data,
	cols,
	color,
	color2,
	height,
	fillArea,
	xAxisName,
	yAxisName,
	xAxisLabel,
	yAxisLabel,
	position,
	currency,
	budget,
}) => {
	return (
		<Chart height={height} padding="auto" scale={cols} forceFit={true} data={data}>
			<Axis name={xAxisName} label={xAxisLabel} min={0} />
			<Axis name={yAxisName} label={yAxisLabel} />
			<Tooltip
				itemTpl={
					tooltipTemplate
						? tooltipTemplate
						: `
						<li data-index={index}>
							<!-- The marker for each record -->
							{value}
						</li>
					`
				}
			/>
			<Geom
				type="line"
				position={position}
				size={2}
				shape={'circle'}
				style={{ stroke: '#fff', lineWidth: 1 }}
				color={'black'}
				tooltip={[
					'dayKey*totalConsumedAmount',
					(dayKey, totalConsumedAmount) => {
						return {
							value: currency.format(totalConsumedAmount),
						};
					},
				]}
			/>

			<Guide>
				<Guide.Line
					top={false} // 指定 guide 是否绘制在 canvas 最上层，默认为 false, 即绘制在最下层
					start={['min', 0]} // 辅助线起始位置，值为原始数据值，支持 callback
					end={['max', budget.budget]} // 辅助线结束位置，值为原始数据值，支持 callback
					lineStyle={{
						stroke: '#999', // 线的颜色
						lineDash: [0, 2, 2], // 虚线的设置
						lineWidth: 1, // 线的宽度
					}} // 图形样式配置
					text={{
						position: 'start', // 文本的显示位置
						autoRotate: true, // 是否沿线的角度排布，默认为 true
						content: 'Expected Budget Spent', // 文本的内容
						offsetX: 64, // x 方向的偏移量
						offsetY: -8, // y 方向的偏移量
					}}
				/>
			</Guide>
		</Chart>
	);
};

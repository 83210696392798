import * as React from 'react';
import { withRouter } from 'react-router';
import AccessPermissionChecker from '../common/AccessPermissionChecker';

const style = require('./SubnavTabs.less');

class SubnavTabs extends React.Component<any, any> {
	render() {
		const { onChange, tabs, isActive, tabsRightActions } = this.props;
		return (
			<div className="tab-block flex flex-row justify-between" id="tab-block">
				<ul className="tab-mnu">
					{tabs.map((tab) => (
						<AccessPermissionChecker name={tab.accessPermissionName}>
							<li
								key={tab.key}
								onClick={() => onChange(tab.key)}
								className={isActive(tab.key) ? 'active' : ''}
							>
								{tab.name}
							</li>
						</AccessPermissionChecker>
					))}
				</ul>
				{(tabsRightActions && tabsRightActions()) || null}
			</div>
		);
	}
}

export default withRouter(SubnavTabs);

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchCapExCategoriesForBuyer } from '../../thunks/invoices_thunks';
import { Form, Input, Modal, Select, Spin } from 'antd';
import ModalFormFooter from '../common/ModalFormFooter';
import {
	capexAssociationRestCrudThunksForBuyer,
	capexAssociationRestCrudThunksForSupplier,
} from '../../thunks/capex_association_thunks';
import { nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import CapExCategoryInput from './CapExCategoryInput';
import { ROLE_TYPES } from '../../utils/DataConstants';

const FORM_ID = 'capex-category-form';

const CapExAdditionalInfoPopup: FC<any> = ({
	onCancel,
	saveCapExAssociation,
	workOrder,
	onSuccess,
}): React.ReactElement => {
	const [saving, setSaving] = useState(false);

	const initialValues = useMemo(
		() => ({
			name: workOrder.capExCategoryId || workOrder.capExCategory || '',
		}),
		[workOrder]
	);

	const handleSubmit = useCallback(
		(values) => {
			setSaving(true);
			saveCapExAssociation({
				workOrderId: workOrder.id,
				...(nullSafeGet('name.value', values) && { capExCategoryId: values.name.value }),
				capExCategory: nullSafeGet('name.value', values) ? values.name.label : values.name,
			})
				.then((res) => onSuccess(res))
				.finally(() => setSaving(false));
		},
		[onSuccess, saveCapExAssociation, workOrder]
	);

	return (
		<Modal
			visible={true}
			title="Add CapEx Category"
			onCancel={onCancel}
			footer={null}
			closable={true}
		>
			<Form
				id={FORM_ID}
				layout="vertical"
				initialValues={initialValues}
				requiredMark={false}
				onFinish={handleSubmit}
			>
				<Form.Item
					name="name"
					label="CapEx Category"
					rules={[
						{
							required: true,
							message: `Please select/enter a capEx category!`,
						},
					]}
				>
					<CapExCategoryInput />
				</Form.Item>
				<ModalFormFooter
					okText="Add Category"
					cancelText="Skip"
					loading={saving}
					formId={FORM_ID}
					onCancelClick={onCancel}
				/>
			</Form>
		</Modal>
	);
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	saveCapExAssociation: (entity) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? capexAssociationRestCrudThunksForSupplier.create(entity)
				: capexAssociationRestCrudThunksForBuyer.create(entity)
		),
});

const ComponentWithoutUserType = withRouter(
	connect(null, mapDispatchToProps)(CapExAdditionalInfoPopup)
);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

import * as React from 'react';
import { Layout, Button, List } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { meterTypesRestCrudThunksForBuyer } from '../../thunks/meter_types_thunks';
import { nullSafeGet } from '../../utils/DataAccessUtils';
import DetailPageHeader from '../detail_page_header/DetailPageHeader';

const style = require('./MeterTypesDetailPage.less');
const ListItem = List.Item;
const ListItemMeta = ListItem.Meta;
const { Content } = Layout;

class MeterTypesDetailPage extends React.Component<any, any> {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const {
			getMeterType,

			match,
		} = this.props;
		const meterTypeId = match.params.id;
		getMeterType(meterTypeId);
	}

	handleTabChange = (key) => {
		const { history, match } = this.props;
		const meterTypeId = match.params.id;
		history.push(`/buyer/meters/types/detail/${meterTypeId}/${key}`);
	};

	checkTabActive = (key) => {
		const { history } = this.props;
		const pathname = history.location.pathname;
		return pathname.indexOf(key) !== -1;
	};

	handleDelete = () => {
		const { history, deleteMeterType, meterType } = this.props;
		deleteMeterType(meterType).then(() => {
			history.push('/buyer/meters/overview/types/overview');
		});
	};

	render() {
		const { meterType, meterTypesFetching, companyConfig, match } = this.props;
		const meterTypeId = match.params.id;
		const tabs = [
			{
				key: 'details',
				name: 'Overview',
			},
		];
		return (
			<div>
				<DetailPageHeader
					exactPath={'/buyer/meters/types/detail/:id'}
					redirectPath={`/buyer/meters/types/detail/${meterTypeId}/details`}
					backLinkText={'Back to meter types'}
					backLinkTo={'/buyer/meters/overview/types/overview'}
					title={meterTypesFetching ? null : meterType.name}
					subtitle={null}
					actions={[
						// <Link
						//     key={1}
						//     to={`/buyer/meters/types/edit/${meterTypeId}`}
						//     style={{marginRight: 16}}
						// >
						//     <Button ghost={true}>Edit</Button>
						// </Link>,
						<Button key={2} ghost={true} onClick={this.handleDelete}>
							Delete
						</Button>,
					]}
					tabs={tabs}
					backgroundColor={nullSafeGet('detail.config.theme.navblock', companyConfig)}
					handleTabChange={this.handleTabChange}
					checkTabActive={this.checkTabActive}
					childRoutes={this.props.childRoutes}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	meterTypesFetching: state.meter_types.fetching,
	meterType: state.meter_types.detail,
	companyConfig: state.company_config,
	match: ownProps.match,
	history: ownProps.history,
});

const mapDispatchToProps = (dispatch) => ({
	getMeterType: (id) => dispatch(meterTypesRestCrudThunksForBuyer.readOne(id)),
	deleteMeterType: (entity) =>
		dispatch(meterTypesRestCrudThunksForBuyer.delete(entity, 'id', null, true)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MeterTypesDetailPage));

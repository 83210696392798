import { Form, Input, Select } from 'antd';
import React, { FC, useMemo } from 'react';
import OWAsyncSelect from '../ow_async_select/OWAsyncSelect';
import { getCurrency, nullSafeGet, nullSafeGetOrElse } from '../../utils/DataAccessUtils';
import { connect } from 'react-redux';
import { vendorsRestCrudThunksForSupplier } from '../../thunks/vendors_thunks';
import { PERMISSION_NAMES, isSupplierAllowedToAccess } from '../../utils/AuthUtils';

const NewPartFormFields: FC<any> = ({
	part,
	vendors,
	fetchVendors,
	fetchMultipleVendors,
	createVendor,
	currentUser,
	companyConfig,
}): React.ReactElement => {
	const currencyId = useMemo(
		() => nullSafeGetOrElse('id', getCurrency({ currentUser }), 'USD'),
		[currentUser]
	);

	const canCreateVendors = isSupplierAllowedToAccess(
		PERMISSION_NAMES.MODIFY_VENDORS,
		companyConfig,
		currentUser.roles
	);

	return (
		<>
			<Form.Item
				label="Part Number"
				name="partNumber"
				initialValue={nullSafeGet('name', part)}
				rules={[{ required: true, message: 'Please enter part number.' }]}
			>
				<Input style={{ width: '100%' }} />
			</Form.Item>
			<Form.Item
				name={'name'}
				label="Part Name"
				initialValue={nullSafeGet('name', part)}
				rules={[{ required: true, message: 'Please enter name of the part.' }]}
			>
				<Input style={{ width: '100%' }} />
			</Form.Item>
			<Form.Item
				name="defaultVendorId"
				label="Default Vendor"
				rules={[
					{
						required: true,
						message: 'Please select a Part!',
					},
				]}
			>
				<OWAsyncSelect
					style={{ maxWidth: 640 }}
					stateSlice={vendors}
					targetCollectionName="defaultPartEquipmentVendors"
					fetchMultiple={(ids, targetCollectionName) => {
						fetchMultipleVendors(ids, targetCollectionName);
					}}
					fetchData={(searchText, targetCollectionName) => {
						fetchVendors({ name: searchText }, targetCollectionName);
					}}
					placeholder={'Select Default Vendor'}
					createNewEntity={
						canCreateVendors ? (name) => createVendor({ name, currencyId }) : undefined
					}
					renderRecord={(vendor) => (
						<Select.Option key={vendor.id} value={vendor.id}>
							{vendor.name}
						</Select.Option>
					)}
				/>
			</Form.Item>
		</>
	);
};

const mapStateToProps = (state) => ({
	currentUser: state.session.currentUser,
	vendors: state.vendors,
	companyConfig: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	fetchVendors: (params, targetCollectionName) =>
		dispatch(
			vendorsRestCrudThunksForSupplier.read(
				{ ...(params || {}), isActive: true },
				targetCollectionName
			)
		),
	fetchMultipleVendors: (ids, targetCollectionName) =>
		dispatch(vendorsRestCrudThunksForSupplier.readMultiple(ids, targetCollectionName)),
	createVendor: (entity) => dispatch(vendorsRestCrudThunksForSupplier.create(entity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPartFormFields);

import React, { FC, useMemo, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Content } from 'antd/lib/layout/layout';
import { Card, Row, Button, Typography, Col } from 'antd';
import { getCurrency } from '../../utils/DataAccessUtils';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { budgetsRestCrudThunksForBuyer } from '../../thunks/budget_thunks';
import { BUDGETS_CRUD_ACTION_CREATORS } from '../../actions/budget_actions';
import { EmptyState } from '../empty_state/EmptyState';
import PaginatedReduxTable from '../paginated_redux_table/PaginatedReduxTable';
import AccessPermissionChecker from '../common/AccessPermissionChecker';
import { PERMISSION_NAMES } from '../../utils/AuthUtils';
import { PAGE_SIZE_OPTIONS } from '../../constants/pagination';
import moment from 'moment';
import { dateFormatter } from '../../utils/DataFormatterUtils';

const BUDGET_TARGET_COLLECTION = 'budgetsIndex';
const { Text } = Typography;

const BudgetIndexPage: FC<any> = ({
	history,
	location,
	updateBudgetFilters,
	budget,
	currentUser,

	createBudget,
}): React.ReactElement => {
	const [showModal, setShowModal] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);

	const style = require('../../styles/rowDisplay.less');

	const currency = getCurrency({ currentUser });
	useMemo(() => {
		updateBudgetFilters({}, BUDGET_TARGET_COLLECTION);
	}, []);

	const handleSubmit = (values) => {
		let budget = {
			id: values.id,
		};
		setButtonLoading(true);
		const submitRule = createBudget;
		setButtonLoading(true);
		submitRule(budget)
			.then(() => {
				setButtonLoading(false);
				setShowModal(false);
			})
			.catch((e) => {
				console.log(e);
				console.log('error');
			});
	};
	const budgetThresholdPill = (remaining, budget, consumed, threshold) => {
		if (budget < consumed) {
			return (
				<span
					style={{
						color: 'rgb(163, 40, 40)',
						backgroundColor: 'rgb(249, 230, 230)',
						padding: '4px 12px',
						borderRadius: 16,
					}}
				>
					{(remaining * -100).toFixed(0)}% Over Budget
				</span>
			);
		} else if (consumed > threshold) {
			return (
				<span
					style={{
						color: 'rgb(179, 98, 30)',
						backgroundColor: 'rgb(251, 239, 229)',
						padding: '4px 12px',
						borderRadius: 16,
					}}
				>
					{(remaining * 100).toFixed(0)}% Remaining
				</span>
			);
		} else {
			return <span className="rowGrayChip">{(remaining * 100).toFixed(0)}% Remaining</span>;
		}
	};
	const columns = [
		{
			title: 'title',
			dataIndex: 'title',
			defaultSortOrder: 'ascend',
			render: (text, record) => {
				let budget = {
					budget: record.sharedBudget ? record.sharedBudget : 0,
					budgetAccrued: record.budgetPerLocations ? 0 : parseFloat(record.budgetAccrued),
					budgetConsumed: record.budgetPerLocations ? 0 : parseFloat(record.budgetConsumed),
					budgetSpent: record.budgetPerLocations ? 0 : parseFloat(record.budgetSpent),
				};
				record.budgetPerLocations &&
					record.budgetPerLocations.map((item) => {
						if (item['budget']) {
							budget['budget'] = budget['budget'] + parseFloat(item['budget']);
						}
						budget['budgetAccrued'] = budget['budgetAccrued'] + parseFloat(item['budgetAccrued']);
						budget['budgetConsumed'] =
							budget['budgetConsumed'] + parseFloat(item['budgetConsumed']);
						budget['budgetSpent'] = budget['budgetSpent'] + parseFloat(item['budgetSpent']);
					});

				const remaining = 1 - budget.budgetConsumed / budget.budget;
				const isActive =
					(record.endDate
						? moment(record.endDate).startOf('day').valueOf() >= moment().startOf('day').valueOf()
						: true) && // no end date
					moment(record.startDate).startOf('day').valueOf() <= moment().startOf('day').valueOf();
				return (
					<Row>
						<Col className={'flex flex-grow'}>
							<Row className={'rowColumn'}>
								<div className="rowPrimary w-full">
									<Ellipsis
										tooltip={true}
										fullWidthRecognition={true}
										lines={1}
										style={{
											color: 'rgba(0,0,0,0.65)',
											paddingBottom: '1px',
											marginRight: '5%',
											overflow: 'hidden',
										}}
									>
										{record.title}
									</Ellipsis>
								</div>
								<div className="rowSecondary w-full">
									{isActive ? null : (
										<div>
											<Text type={'danger'}>Inactive</Text>
										</div>
									)}
									{remaining && !Number.isNaN(remaining) ? (
										<div className="rowInlineGroup">
											{budgetThresholdPill(
												remaining,
												budget.budget,
												budget.budgetConsumed,
												(parseFloat(record.thresholdPercentage) / 100) * budget.budget
											)}
										</div>
									) : null}
									{isActive ? (
										<div className="rowInlineGroup">
											<span className="rowGrayChip">
												Current Period: {dateFormatter(record.cycleStartDate, 'MMM D, YYYY')} -{' '}
												{dateFormatter(record.cycleEndDate, 'MMM D, YYYY')}
											</span>
										</div>
									) : null}
									<div className="rowInlineGroup">
										<span className="rowGrayChip">
											Created {moment(record.createdAt).fromNow()}
										</span>
									</div>
								</div>
							</Row>
						</Col>
						<Col>
							<Row className={'rowColumn'}>
								<div className="rowSecondary w-full justify-end">
									<div className="rowInlineGroup">
										<span>{currency.format(budget.budget)} Budget Total</span>
									</div>
								</div>
								{budget.budget - budget.budgetConsumed > 0 ? (
									<div className="rowSecondary w-full justify-end">
										<div className="rowInlineGroup">
											<span>
												{currency.format(budget.budget - budget.budgetConsumed)} Remaining
											</span>
										</div>
									</div>
								) : null}
							</Row>
						</Col>
					</Row>
				);
			},
		},
	];
	const onRow = (record) => ({
		onClick: () => {
			let searchString = location.search;
			if (searchString[0] === '?') {
				searchString = searchString.slice(1);
			}
			let backUrl = `/buyer/budgets/detail/${record.id}/details`;
			if (searchString && searchString.length > 0) {
				backUrl = backUrl.concat(`?backlinkParams=${encodeURIComponent(searchString)}`);
			}
			history.push(backUrl);
		},
	});

	return (
		<Content style={{ padding: '0 0.5em' }}>
			<Row className={'mb-2 justify-end'}>
				<Button type="primary" onClick={() => history.push('/buyer/budgets/new')}>
					Create Budget
				</Button>
			</Row>
			<Row className="rowSpacing">
				<Card className={'w-full'}>
					<PaginatedReduxTable
						updateQueryParams={true}
						emptyState={
							<EmptyState
								graphic={
									<img
										alt="Currently there are no assets!"
										style={{ marginBottom: 8 }}
										src="https://s3.amazonaws.com/mock-data-assets/categories/images/cactus.svg"
									/>
								}
								headline={"It's a little lonely in here."}
								body={
									<div style={{ textAlign: 'center' }}>
										<div style={{ maxWidth: 440, marginBottom: 16 }}>
											Looks like your team has not added any budgets yet.
										</div>
										<AccessPermissionChecker name={PERMISSION_NAMES.MODIFY_ASSETS}>
											<Button type="primary" onClick={() => history.push('/buyer/budgets/new')}>
												Add your first budget
											</Button>
										</AccessPermissionChecker>
									</div>
								}
							/>
						}
						collectionName="budgets"
						onRow={onRow}
						showHeader={false}
						targetCollectionName={BUDGET_TARGET_COLLECTION}
						columns={columns}
						keyAccessor={(el) => el.id}
						initialFilters={{}}
						initialPagination={{}}
						initialSorting={{ sort_by: 'createdAt', order: 'desc' }}
						hiddenPagination={{ showSizeChanger: true, pageSizeOptions: PAGE_SIZE_OPTIONS }}
						fetchData={budgetsRestCrudThunksForBuyer.read}
					/>
				</Card>
			</Row>
		</Content>
	);
};

const mapStateToProps = (state, ownProps) => ({
	history: ownProps.history,
	currentUser: state.session.currentUser,
	budgetSlice: state.budgets,
	budget: state.budgets.detail,

	companySettings: state.company_config.detail,
});

const mapDispatchToProps = (dispatch) => ({
	updateBudgetFilters: (filters, targetCollection) =>
		dispatch(BUDGETS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollection)),
});

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(BudgetIndexPage));

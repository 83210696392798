import { Button, Card, Col, Result, Row, Statistic, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	downtimeAnalyticsRestCrudThunksForBuyer,
	downtimeAnalyticsRestCrudThunksForSupplier,
} from '../../thunks/asset_analytics_thunks';
import { formatDateForParam, secondsToStr } from '../../utils/DataFormatterUtils';
import DowntimeAnalyticsControlBar from '../downtime_analytics_control_bar/DowntimeAnalyticsControlBar';
import moment from 'moment';
import { DOWNTIME_ANALYTICS_CRUD_ACTION_CREATORS } from '../../actions/downtime_analytics_actions';
import { ROLE_TYPES } from '../../utils/DataConstants';
const queryString = require('qs');

type DataState = {
	noOfAssetsOffline?: number;
	meanDownTime?: number;
	meanTimeToRespond?: number;
	meanTimeBetweenFailures?: number;
};
const DowntimeAnalyticsOverviewCurrentPage: React.FC<any> = ({
	getOverviewAnalaytics,
	analytics,
	saveFilters,
	clearFilters,
	userType,
}) => {
	const [data, setData] = useState<DataState>({});

	const { filters } = analytics.overview;

	const getLink = (extraParams?) => {
		const dateObj = filters.rightNow
			? { rightNow: true }
			: { startDate: filters.startDate, endDate: filters.endDate };

		const queryParams = queryString.stringify({
			...dateObj,
			locationIds: filters.locationIds && filters.locationIds.join(','),
			assetTypeIds: filters.assetTypeIds && filters.assetTypeIds.join(','),
			manufacturer: filters.manufacturer,
			sort_by: 'meanDownTime',
			order: 'descend',
			...extraParams,
		});
		return `/${userType}/assets/overview/downtime?${queryParams}`;
	};

	const startDate = filters.startDate && moment(filters.startDate);
	const endDate = filters.endDate && moment(filters.endDate);

	const fetchData = (params, pagination?, sorting?, filters?) =>
		getOverviewAnalaytics(params, 'overview', pagination, sorting, filters).then((data) =>
			setData(data[0])
		);
	useEffect(() => {
		fetchData({}, null, null, {
			rightNow: true,
		});
	}, []);

	const { noOfAssetsOffline, meanDownTime, meanTimeToRespond, meanTimeBetweenFailures } = data;

	return (
		<div className="p-4">
			<Row gutter={16} className="mx-8 mb-4 pl-2" align="middle">
				<DowntimeAnalyticsControlBar
					updateFilters={(filters) => {
						if (filters.startDate && filters.endDate) {
							fetchData({}, null, null, {
								...filters,
								startDate: formatDateForParam(filters.startDate),
								endDate: formatDateForParam(filters.endDate),
							});
						} else {
							fetchData({ rightNow: true }, null, null, filters);
						}
					}}
					clearFilters={() => clearFilters('overview')}
					startDate={startDate}
					endDate={endDate}
					filters={filters}
					filterConfig={{
						assetTypes: true,
						manufacturer: true,
					}}
				/>
			</Row>
			<Row gutter={16} className="mx-8 mb-4">
				<Col span={12}>
					<Card
						loading={analytics.fetching}
						title="No. of Assets Down"
						actions={[
							<Link
								target="_blank"
								to={getLink(startDate && endDate ? {} : { downtimeStatus: 'offline' })}
							>
								<Button type="link">
									See details <i className="icons8-font icons8-external-link ml-2 inline-block" />
								</Button>
							</Link>,
						]}
					>
						<div className="flex h-40 flex-col items-center justify-center text-center text-8xl font-normal ">
							{noOfAssetsOffline}
						</div>
					</Card>
				</Col>
				<Col span={12}>
					<Card
						loading={analytics.fetching}
						title=" Mean Downtime "
						actions={[
							<Link target="_blank" to={getLink()}>
								<Button type="link">
									See details <i className="icons8-font icons8-external-link ml-2 inline-block" />
								</Button>
							</Link>,
						]}
					>
						<div className="flex h-40 flex-col items-center justify-center">
							<div className="text-center text-8xl font-normal ">{secondsToStr(meanDownTime)}</div>
						</div>
					</Card>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={12}>
					<Card
						loading={analytics.fetching}
						title=" Mean time to respond"
						actions={[
							<Link target="_blank" to={getLink({ sort_by: 'meanTimeToRespond' })}>
								<Button type="link">
									See details <i className="icons8-font icons8-external-link ml-2 inline-block" />
								</Button>
							</Link>,
						]}
					>
						<div className="flex h-40 flex-col items-center justify-center">
							<div className="text-center text-8xl font-normal ">
								{secondsToStr(meanTimeToRespond)}
							</div>
						</div>
					</Card>
				</Col>
				<Col span={12}>
					<Card
						loading={analytics.fetching}
						title=" Mean time between failures"
						actions={
							startDate && endDate
								? [
										<Link
											target="_blank"
											to={getLink({ sort_by: 'meanTimeBetweenFailures', order: 'ascend' })}
										>
											<Button type="link">
												See details{' '}
												<i className="icons8-font icons8-external-link ml-2 inline-block" />
											</Button>
										</Link>,
								  ]
								: [<Button type="link"></Button>]
						}
					>
						<div className=" flex h-40 flex-col items-center justify-center  ">
							{startDate && endDate ? (
								<div className="text-center text-8xl font-normal ">
									{secondsToStr(meanTimeBetweenFailures)}
								</div>
							) : (
								<div className=" font-base text-center text-gray-500">
									Mean time between failures needs a time period to be calculated. Please select a
									time period.
								</div>
							)}
						</div>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

const mapStateToProps = (state) => ({
	analytics: state.downtime_analytics,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getOverviewAnalaytics: (
		params,
		targetCollectionName,
		pagination,
		sorting,
		filters,
		addToTargetCollection
	) =>
		dispatch(
			ownProps.userType === ROLE_TYPES.SUPPLIER
				? downtimeAnalyticsRestCrudThunksForSupplier.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
				: downtimeAnalyticsRestCrudThunksForBuyer.read(
						params,
						targetCollectionName,
						pagination,
						sorting,
						filters,
						addToTargetCollection
				  )
		),
	saveFilters: (filters, targetCollectionName) =>
		dispatch(DOWNTIME_ANALYTICS_CRUD_ACTION_CREATORS.updateFilters(filters, targetCollectionName)),
	clearFilters: (targetCollectionName) =>
		dispatch(DOWNTIME_ANALYTICS_CRUD_ACTION_CREATORS.clearAllFilters(targetCollectionName)),
});

const ComponentWithoutUserType = connect(
	mapStateToProps,
	mapDispatchToProps
)(DowntimeAnalyticsOverviewCurrentPage);

export default connect(
	(state) => ({
		userType: (state as any).session.userType,
	}),
	() => ({})
)(ComponentWithoutUserType);

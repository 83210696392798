import { AREAS_CRUD_ACTION_CONSTANTS as CRUD_ACTION_CONSTANTS } from '../actions/areas_actions';
import { standardInitialStateGenerator, standardReducerGenerator } from './standard_reducer_utils';

const initialState: any = standardInitialStateGenerator([
	'workOrdersAdvancedFilters',
	'locationAssociatedAreas',
	'SUPPLIER_WORK_ORDER_FORM_AREAS_AUTOCOMPLETE',
	'WORK_ORDER_FORM_AREAS_AUTOCOMPLETE',
	'WORK_ORDER_FORM_AREAS_TOP_LEVEL_AUTOCOMPLETE',
	'WORK_ORDER_FORM_AREAS_NEXT_LEVEL_AUTOCOMPLETE',
	'assetsForm',
	'assetTransferTargetCollection',
]);
export default standardReducerGenerator(CRUD_ACTION_CONSTANTS, initialState);
